import { Direction } from '@angular/cdk/bidi';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@ex/module/core/services/loader.service';
import { LocalService } from '@ex/module/core/services/local.service';
import { PlatformUserService } from '@ex/module/core/services/platform-user.service';
import { TenantService } from '@ex/module/core/services/tenant.service';
import { ChildrenAnimation } from '@ex/module/shared/animations/allAnimations';
import { RespondentsService } from '../../data-access/services/respondents.service';
import { SharedService } from '../../data-access/services/shared-service.service';
import { SurveysService } from '../../data-access/services/surveys.service';
import { StatusTypes } from '../../utils/enums/StatusTypes';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';



interface EmailTemplate{
  body:string;
  subject:string
}
@Component({
  selector: 'app-respondent-view',
  templateUrl: './respondent-view.component.html',
  styleUrls: ['./respondent-view.component.scss'],
  animations: [ChildrenAnimation]
})
export class RespondentViewComponent implements OnInit {
  language = 'EN';
  ModuleNames = ModuleName;
  respondentId!: string;
  respondent!: any;
  selectedRespondent: any;
  activeForms = []
  inactiveForms = []
  openEditGI: boolean = false;
  openEditCI: boolean = false;
  openEditCP: boolean = false;
  openEditAM: boolean = false;
  openManageSb: boolean = false;
  routeType!: any;
  routeResellerId : any;
  respondentKey: any;

  currentTenant : any

  respondentFormTable: any;

  respondentData : any;

  constructor(private activatedRoute: ActivatedRoute, private loader: LoaderService, private router: Router, private sharedService : SharedService,
    private tenantService : TenantService, private route: ActivatedRoute, private platformUserService : PlatformUserService,public datePipe: DatePipe,
    private surveysService: SurveysService, private respondentsService : RespondentsService,private localService : LocalService) { }

  async fetchRespondentData()
  {
    // this.respondentKey = this.route.snapshot.paramMap.get('id');
    var sub = this.respondentsService.getRespondent(this.respondentKey);
      this.respondentData = await sub;

    // await this.tenantService.fetchTenant(this.tenantKey).toPromise();
    await this.respondentData
  }

  async fetchRespondentForms()
  {
    var sub = this.respondentsService.getRespondentForm(this.respondentKey, this.currentTenant.key);
    this.respondentFormTable = sub;

    // await this.tenantService.fetchTenant(this.tenantKey).toPromise();
    await this.respondentFormTable

  }

  direction : Direction = "ltr"
  textPosition : any = "left";

  async ngOnInit() {

    this.language = this.localService.getJsonValue('language')
    this.textPosition = "left"
    if (this.language == "AR")
    {
      this.direction = "rtl"
      this.textPosition = "right"
    }
    else
    {
      this.direction = "ltr"
      this.textPosition = "left"
    }

    this.respondentKey = this.route.snapshot.paramMap.get('id');

    this.router.navigate([`survey-x/respondents/${this.respondentKey}`]);


    this.currentTenant = JSON.parse(localStorage.getItem("tenant"));

     await this.fetchRespondentData();
     await this.fetchRespondentForms();

     //--------------------get route reseller id-------------------------
     this.sharedService.selectedRouteResellerId.subscribe((value)=>
     {
      this.routeResellerId = value;
     })
     //-------------------------------------------------------------------
     this.routeType = this.router.url.split('/').pop();

     window.scroll({
       top: 0,
       left: 0,
       behavior: 'smooth'
     });
     this.loader.displayLoader();
     this.respondentId = this.activatedRoute.snapshot.params['id'];
     console.log('type', this.activatedRoute.snapshot.params['type'])
     this.getClient(this.respondentId);
  }

  getClient(respondentId: string) {
    // get client by key service
    setTimeout(() => {


      this.respondent={
        respondentGeneralInfo:{
          name: this.respondentData.name, email: this.respondentData.email, age:this.respondentData.age,
          nationality: this.respondentData.nationality, gender: this.respondentData.gender,
          jobTitle:this.respondentData.jobTitle, maritalStatus: this.respondentData.maritalStatus,
          jobType:this.respondentData.jobType, department:this.respondentData.department,
          contractType:this.respondentData.contractType, phoneNumber: this.respondentData.phoneNumber
        },
        respondentCompanyInfo:{
          companyName:this.respondentData.companyName, companyIndustry:this.respondentData.companyIndustry, companySize:this.respondentData.companySize,
        },
        subscriptions:[]
      };

      this.selectedRespondent = this.respondentData;
      if(this.respondentFormTable)
    {

    this.activeForms = this.respondentFormTable.__zone_symbol__value?.filter(x=>x.editedFormTable.statusType == StatusTypes.Live);
    this.inactiveForms = this.respondentFormTable.__zone_symbol__value?.filter(x=>x.editedFormTable.statusType != StatusTypes.Live);
    }
      // if (this.respondent.subscriptions) {
      //   this.activeForms = this.respondent.subscriptions?.filter(s => s.status == SubscriptionStatus.Active);
      //   this.inactiveForms = this.respondent.subscriptions?.filter(s => s.status != SubscriptionStatus.Active);
      // }
      this.loader.hideLoader();
    }, 500)
  }
  // splitName(name:string){
  //   if(name && name.length>40){
  //     name=name.substring(0,40)+"..."
  //   }
  //   return name;
  // }
}
