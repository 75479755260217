<z-modal [ngClass]="{'RTL':language == 'AR'}" [open]="open" [autoWidth]="true">
  <ng-container header>
    <h6 class="modal-title">
      {{'Add New Question'| translate:language:ModuleNames.SurveyX }}
    </h6>

    <button class="btn close text-center rounded close-button py-2 px-4" (click)="close()">
      <i class="fa-solid fa-xmark"></i>
    </button>
  </ng-container>

  <ng-container body>
    <div class="flex flex-row">
      <div class="flex flex-col items-stretch w-60">
        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('short-text')">
          <i class="fa-regular fa-comment-minus"></i>
          {{'Short Text'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('long-text')">
          <i class="fa-regular fa-comment-lines"></i>
          {{'Long Text'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('multiple-choices')">
          <i class="fa-regular fa-list"></i>
          {{'Multiple Choices'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('dropdown')">
          <i class="fa-regular fa-list-dropdown"></i>
          {{'Dropdown'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('start-rating')">
          <i class="fa-regular fa-star"></i>
          {{'Star Rating'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('rating-scale')">
          <i class="fa-regular fa-chart-simple"></i>
          {{'Rating Scale'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3" (click)="selectType('enps')">
          <i class="fa-regular fa-bars-progress"></i>
          {{'eNPS'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3" (click)="selectType('nps')">
          <i class="fa-regular fa-bars-progress"></i>
          {{'NPS'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('number-input')">
          <i class="fa-regular fa-hashtag"></i>
          {{'Number Input'| translate:language:ModuleNames.SurveyX }}
        </button>
      </div>

      <div class="flex flex-col items-stretch w-60">
        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('ranking')">
          <i class="fa-regular fa-list-ol"></i>
          {{'Ranking'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('image-choice')">
          <i class="fa-regular fa-image"></i>
          {{'Image Choice'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('icon-choice')">
          <i class="fa-regular fa-smile"></i>
          {{'Icon Choice'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('binary')">
          <i class="fa-regular fa-circle-half-stroke"></i>
          {{'Binary (Yes/No)'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('matrix')">
          <i class="fa-regular fa-table"></i>
          {{'Matrix (Single/Multiple)'| translate:language:ModuleNames.SurveyX }}
        </button>

        <!--
                <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
                    (click)="selectType('slider')">
                    <i class="fa-regular fa-sliders"></i>
                    {{'Slider'| translate:language:ModuleNames.SurveyX }}
                </button>
                -->

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('email-input')">
          <i class="fa-regular fa-at"></i>
          {{'Email Input'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('file-upload')">
          <i class="fa-regular fa-upload"></i>
          {{'File Upload'| translate:language:ModuleNames.SurveyX }}
        </button>

        <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('date-input')">
          <i class="fa-regular fa-calendar"></i>
          {{'Date Input'| translate:language:ModuleNames.SurveyX }}
        </button>
        <!-- <button class="question-type-button w-full mb-1 py-2 flex items-center gap-2 px-3"
          (click)="selectType('datepicker')">
          <i class="fa-regular fa-calendar"></i>
          {{'Date Picker'| translate:language:ModuleNames.SurveyX }}
        </button> -->
      </div>
    </div>
  </ng-container>

  <ng-container footer></ng-container>
</z-modal>
