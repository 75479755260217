<div class="flex flex-row justify-between items-center w-full  rounded-md px-5 pt-1 h-15 "
    [ngClass]="{'rtl': (language | language ),'bg-white': !dark,'bg-zen bg-opacity-10': dark  }">


    <ng-container *ngIf="selectionMode == 'Multi'">
        <ng-container *ngIf="extractionComplete">
            <div class="flex flex-row w-full items-center  h-15">
                <mat-form-field class="w-full -mb-2" appearance="none">
                    <mat-label>{{ txtBranches }}</mat-label>
                    <input required matInput autocomplete="off" autocorrect="off" autocapitalize="off"
                        spellcheck="false" #autoInputElement
                        class="truncate multi-autocomplete-form-field analyst-font-regular analyst-input"
                        aria-label="State" [placeholder]="placeholder" [matAutocomplete]="auto"
                        (blur)="restoreSelected($event)" name="autoComplete" id="autoComplete"
                        (keydown)="autoCompleteChange($event)" [formControl]="inputCtrl">


                    <mat-error *ngIf="inputCtrl.invalid && (inputCtrl.dirty || inputCtrl.touched)"
                        class="alert alert-danger transform translate-y-1">
                        <div class="text-red-600 text-xs" *ngIf="inputCtrl.errors.required">
                            {{ 'txtFieldIsRequired'|translate:language:ModuleNames.Shared }}
                        </div>

                    </mat-error>
                </mat-form-field>
                <i *ngIf="selectedList.length == 0" class="fas fa-sort-down px-1 font-medium opacity-80"></i>
                <a *ngIf="selectedList.length > 0" (click)="clearOptions()"
                    class="btn cancel h-10 w-10 rounded-sm leading-10 text-center">
                    <i class="fas fa-times"></i>
                </a>


            </div>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="openPanel()">
                <mat-option style="height:0;">
                </mat-option>
                <mat-selection-list #selectionList (selectionChange)="selectionChange($event)">
                    <ng-container *ngFor="let option of filteredOptions | async">
                        <mat-list-option class="mat-primary orange" [value]="option.key" [selected]="isSelected(option)"
                            checkboxPosition="before">
                            <p class="analyst-font-extra-bold">{{ extractNameLanguage(option.name)}}</p>
                        </mat-list-option>
                    </ng-container>
                </mat-selection-list>
            </mat-autocomplete>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="selectionMode == 'Single'">
        <div class="flex flex-row w-full items-center">
            <mat-form-field class="w-full -mb-2" appearance="none">
                <mat-label>{{ txtBranches }}</mat-label>
                <input required autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" matInput
                    #autoInputElement class="analyst-font-regular analyst-input multi-autocomplete-form-field"
                    [ngClass]="{ 'restaurant-search' :  hideClear === true, 'other-search' : hideClear === false }"
                    aria-label="State" [placeholder]="placeholder" [matAutocomplete]="auto"
                    (blur)="singleSelectionRestoreSelected($event)" name="autoComplete" id="autoComplete"
                    (click)="clearInput($event)" [formControl]="inputCtrl">
                <mat-error *ngIf="inputCtrl.invalid && (inputCtrl.dirty || inputCtrl.touched)"
                    class="alert alert-danger transform translate-y-1">
                    <div class="text-red-600 text-xs" *ngIf="inputCtrl.errors.required">
                        {{ 'txtFieldIsRequired'|translate:language:ModuleNames.Shared }}
                    </div>

                </mat-error>
            </mat-form-field>

            <i *ngIf="selectedOptions == ''" class="fas fa-sort-down px-1 font-medium opacity-80"></i>
            <a *ngIf="selectedOptions != ''" (click)="clearOptions()"
                class="btn cancel h-10 w-10 rounded-sm leading-10 text-center">
                <i class="fas fa-times"></i>
            </a>


        </div>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectionChange($event)">
            <mat-option style="width:100%; border-radius: 0;" *ngFor="let option of filteredOptions | async"
                [value]="option.key">
                {{extractNameLanguage(option.name)}}
            </mat-option>
        </mat-autocomplete>
    </ng-container>
</div>
