<zen-main-container>
  <sidebar-container>
    <app-dual-dropdown-selection (buttonClick)="onCreateNewTemplate()" [mode]="'full'" [data]="templateLookup" [(ngModel)]="selectedTemplate" (ngModelChange)="onTemplateChange($event)" [tours]="[
        {
          title:
            'txt_PREDEFINEDTEMPLATES' | translate : language : ModuleNames.Exit,
          text: 'txt_Clickhere_525' | translate : language : ModuleNames.Exit,
          order: 2,
          tourKey: 'exit-survey-templates',
          on: (language | language) ? 'left' : 'right'
        },
        {
          title: 'txt_NewTemplate' | translate : language : ModuleNames.Exit,
          text: 'txt_Doyouhave_406' | translate : language : ModuleNames.Exit,
          order: 8,
          tourKey: 'exit-survey-templates',
          on: (language | language) ? 'left' : 'right'
        }
      ]">
    </app-dual-dropdown-selection>
  </sidebar-container>
  <content-container class="flex flex-col items-center w-full">
    <div class="flex flex-col items-stretch h-28 relative" sticky [top]="topValue">
      <div class="flex flex-row h-28 z-30 items-center justify-between bg-white shadow-2x4 px-10 backdrop-filter backdrop-blur-xl bg-opacity-80">
        <div class="flex flex-row items-center gap-5">
          <div class="flex flex-row items-center gap-3">
            <i class="fa-regular text-zen-lite text-2xl" [class]="selectedTemplate?.icon"></i>
            <h5 class="text-zen font-semibold text-2xl truncate" (click)="logData(selectedTemplate)">
              {{ selectedTemplate?.name }}
            </h5>
          </div>
          <div class="flex flex-row items-center gap-1.5">
            <div *ngIf="currentTemplate?.isPredefined || currentTemplate?.isLocked" class="flex flex-row items-center px-3.5 text-zen-lite text-opacity-50 text-xs bg-zen bg-opacity-5 rounded-r-full rounded-l-full gap-2.5">
              <i class="fa-regular fa-lock-alt text-xs leading-8"></i>
              <h5 class="font-normal leading-8 text-xs">
                {{
                currentTemplate?.isPredefined
                ? ("txtNotEditTemplate"
                | translate : language : ModuleNames.Onboarding)
                : ("txtNotEditLockedTemplate"
                | translate : language : ModuleNames.Onboarding)
                }}
              </h5>
            </div>
          </div>
        </div>
        <div class="flex flex-row items-center gap-2.5">
          <div class="flex flex-row items-center" *ngIf="!selectedTemplate?.isPredefined">
            <lottie-player *ngIf="loading" src="/assets/lottie/circleLoader.json" background="transparent" speed="1" autoplay loop style="width: 2rem; height: 2rem" class="btn transform scale-75">
            </lottie-player>
            <ng-container *ngIf="!loading">
              <div *ngIf="!currentTemplate?.isDraft" class="flex flex-row items-center gap-2.5 text-zen-green text-sm">
                <i class="fa-regular fa-cloud-check"></i>
                <span>{{
                  "txtSaved" | translate : language : ModuleNames.Onboarding
                  }}</span>
              </div>
              <div *ngIf="currentTemplate?.isDraft" class="flex flex-row items-center gap-2 text-zen-red text-sm">
                <i class="fa-regular fa-cloud-exclamation"></i>
                <span>{{
                  "txtUnsaved" | translate : language : ModuleNames.Onboarding
                  }}</span>
              </div>
            </ng-container>
          </div>

          <!-- <a class="btn white flex flex-row items-center gap-2 leading-11 px-5 rounded-sm shadowNone text-sm">
              <i class="fa-regular fa-save text-zen"></i>
              <span>Export</span>
            </a> -->
          <!-- <a  class="btn cancel flex flex-row items-center gap-2 leading-11 px-5 rounded-sm shadowNone text-sm">
              <i class="fa-regular fa-eye"></i>
              <span>Preview</span>
            </a> -->
          <i [ZMenuTriggerFor]="menu1" [TourStep]="{
              title:
                'txt_MoreActio_765' | translate : language : ModuleNames.Exit,
              text:
                'txt_Clickhere_151' | translate : language : ModuleNames.Exit,
              order: 3,
              tourKey: 'exit-survey-custom-template',
              on: (language | language) ? 'right' : 'left'
            }" class="fa-regular fa-ellipsis-v w-8 h-8 leading-8 text-opacity-60 text-base btn text-center btn"></i>
          <z-menu #menu1>
            <menuItems>
              <a *ngIf="!currentTemplate?.isDraft" MenuItem (click)="openPreviewExport()">
                <i class="fal fa-save"></i>{{
                "txtExportTemplate" | translate : language : ModuleNames.Exit
                }}
              </a>
              <!-- <a MenuItem (click)="cloneTemplate(currentTemplate)">
                  <i class="fal fa-copy"></i>Clone Template
                </a> -->
              <a *ngIf="
                  !currentTemplate?.isPredefined && !currentTemplate?.isLocked
                " MenuItem (click)="onCreateNewTemplate(currentTemplate)">
                <i class="fal fa-pen"></i>{{
                "txtEditTemplate" | translate : language : ModuleNames.Exit
                }}
              </a>
              <a MenuItem delete (click)="deleteTemplate(currentTemplate)" *ngIf="
                  !currentTemplate?.isPredefined && !currentTemplate?.isLocked
                ">
                <i class="fal fa-trash"></i>{{
                "txtDeleteTemplate" | translate : language : ModuleNames.Exit
                }}
              </a>
            </menuItems>
          </z-menu>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-stretch p-10 h-full">
      <exit-template-editor class="h-full" *ngIf="currentTemplate" [template]="currentTemplate" (templateChange)="updateTemplate($event)" [language]="language" [contentLanguages]="selectedEntity?.ContentLanguages" [selectedEntity]="selectedEntity" [tourKey]="
          currentTemplate?.isPredefined || currentTemplate?.isLocked
            ? 'exit-survey-templates'
            : 'exit-survey-custom-template'
        "></exit-template-editor>
      <!-- <ext-template-section (templateChange)="updateTemplate($event)" [selectedEntity]="selectedEntity"
            [selectedTemplate]="selectedTemplate" [template]="currentTemplate" [language]="language">
        </ext-template-section> -->
    </div>
  </content-container>
  <ng-container>
    <product-tour *ngIf="currentTemplate?.isPredefined || currentTemplate?.isLocked" [key]="'exit-survey-templates'" [offset]="122"></product-tour>
    <product-tour *ngIf="!currentTemplate?.isPredefined && !currentTemplate?.isLocked" [key]="'exit-survey-custom-template'" [offset]="122"></product-tour>
  </ng-container>
</zen-main-container>
<z-modal *ngIf="isAddTemplate" [(open)]="isAddTemplate" [autoWidth]="true">
  <ng-container header>
    <h2 class="text-zen text-2xl font-semibold">
      {{
      (createNewTemplateForm.value.key
      ? "txt_EditTemplate"
      : "txt_NewTemplate"
      ) | translate : language : ModuleNames.Exit
      }}
    </h2>

    <i class="fa-regular fa-times btn text-center text-xl text-zen text-opacity-70" (click)="isAddTemplate = false; newTemplate = null"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col gap-7 items-stretch pb-2 w-96">
      <z-translation-input [maxCount]="avgCount" name="name" [maxCount]="mdCount" [required]="true" [label]="'txt_template_name' | translate : language : ModuleNames.Exit" [language]="language" [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="newTemplate.name">
      </z-translation-input>
    </div>
  </ng-container>
  <ng-container footer>
    <a (click)="isAddTemplate = false; newTemplate = null" class="btn cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1 z-50">
      <span>{{ "btn_cancel" | translate : language : ModuleNames.Exit }}</span>
    </a>
    <button [ngClass]="{
        'pointer-events-none opacity-50': !isValidName(newTemplate?.name)
      }" (click)="createTemplate(newTemplate)" class="btn exp text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
      <span>{{
        (newTemplate?.key ? "txtUpdateTemplate" : "txtCreateTemplate")
        | translate : language : ModuleNames.Exit
        }}</span>
    </button>
  </ng-container>
</z-modal>