import type { EmailTemplateDto, SurveyReminderDto } from '../../core/common/models';
import type { EntityHierarchyDto } from '../../core/entities/models';
import type { CustomPropertyDto } from '../../core/models';
import type { AuditedEntityDto } from '../../core/auditing/models';
import type { LeaverListFilterKeys } from './leaver-list-filter-keys.enum';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';

export interface CreateUpdateLeaverAnswerDto {
  leaverKey: string;
  userName: string;
  note: string;
}

export interface CreateUpdateLeaverDto {
  employeeId?: string;
  tenantKey?: string;
  companyKey?: string;
  surveyKey?: string;
  name?: string;
  email?: string;
  countryCode?: string;
  phoneNumber?: string;
  genderKey?: string;
  dateOfBirth?: string;
  nationalityKey?: string;
  jobTitle?: string;
  jobRole?: string;
  directManagerId?: string;
  directManagerName?: string;
  bandLevel?: string;
  jobGrade?: string;
  performanceRating?: string;
  joiningDate?: string;
  seperationType?: string;
  seperationDate?: string;
  startDate?: string;
  endDate?: string;
  invitationEmailTemplate: EmailTemplateDto;
  reminderEmailTemplate: EmailTemplateDto;
  entityHierarchy: EntityHierarchyDto[];
  reminders: SurveyReminderDto[];
  extraCriterias: CustomPropertyDto[];
}

export interface LeaverAnswerNoteDto extends AuditedEntityDto {
  key?: string;
  leaverKey?: string;
  userName?: string;
  note?: string;
}

export interface LeaverDto extends AuditedEntityDto {
  employeeId?: string;
  key?: string;
  tenantKey?: string;
  companyKey?: string;
  surveyKey?: string;
  name?: string;
  email?: string;
  countryCode?: string;
  phoneNumber?: string;
  genderKey?: string;
  dateOfBirth?: string;
  nationalityKey?: string;
  jobTitle?: string;
  directManagerId?: string;
  directManagerName?: string;
  bandLevel?: string;
  jobGrade?: string;
  performanceRating?: string;
  joiningDate?: string;
  seperationType?: string;
  seperationDate?: string;
  tenure: number;
  startDate?: string;
  endDate?: string;
  invitationEmailTemplate: EmailTemplateDto;
  reminderEmailTemplate: EmailTemplateDto;
  status?: string;
  entityHierarchy: EntityHierarchyDto[];
  reminders: SurveyReminderDto[];
  isDeleted: boolean;
  deletionDate?: string;
  tenureGroup?: string;
  generation?: string;
  ageGroup?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
  extraCriterias: CustomPropertyDto[];
}

export interface LeaverListFilterDto {
  key: LeaverListFilterKeys;
  value?: string;
}

export interface LeaverPagedRequestDto extends PagedResultRequestDto {
  surveyKey: string;
  tenantKey: string;
  companyKey: string;
  filters: LeaverListFilterDto[];
  isAllResults?: boolean;
}

export interface LeaverPagedResultDto extends PagedResultDto<LeaverDto> {
  allSendReminderCount: number;
  allDeleteCount: number;
}

export interface SendReminderDto {
  tenantKey: string;
  companyKey: string;
  surveyKey: string;
  leaverKey?: string;
  isSendAll: boolean;
}

export interface StatusCountDto {
  all: number;
  posted: number;
  pending: number;
  inProgress: number;
  completed: number;
  inActive: number;
  expired: number;
}
