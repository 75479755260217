import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map,take,first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  path: import("@angular/router").ActivatedRouteSnapshot[];
  route: import("@angular/router").ActivatedRouteSnapshot;

  constructor(private router: Router, public af: AngularFireAuth) {
  }

  // Get Current Authenticated User
  getUser(): Promise<any> {
    return this.af.authState.pipe(first()).toPromise();
   }
  // Guard Logic implementation
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const user = await this.getUser();
    const loggedIn = !!user;

    if (!loggedIn) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
    }

    return loggedIn;
  }

}
