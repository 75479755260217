import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";


@Injectable({
  providedIn: 'root'
})

export class SharedService {

  private handleTypeSelected = new Subject<any>();
  getSelectedType = this.handleTypeSelected.asObservable();


  private routeResellerId = new BehaviorSubject<any>({});
  selectedRouteResellerId = this.routeResellerId.asObservable();


  private respondents = new BehaviorSubject<any>({});
  selectedRespondents = this.respondents.asObservable();
  private formId = new BehaviorSubject<any>({});
  selectedFormId = this.formId.asObservable();
  private form = new BehaviorSubject<any>({});
  selectedForm = this.form.asObservable();

  ///////////////////////////////////////// For the questions builder edit mode /////////////////////////////////////////
  private selectedQuestion = new BehaviorSubject<any>({});
  selectedQuestionContent = this.selectedQuestion.asObservable();
  setSelectedQuestion(question: any) {

    this.selectedQuestion.next(question);
  }

  getSelectedQuestionContentValue() {
    return this.selectedQuestion.value;
  }

  private selectedQuestionParent = new BehaviorSubject<any>({});
  selectedQuestionParentContent = this.selectedQuestionParent.asObservable();
  setSelectedQuestionParent(parent: any) {
    this.selectedQuestionParent.next(parent);
  }

  getSelectedQuestionParentContentValue() {
    return this.selectedQuestionParent.value;
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  setRespondents(respondets: any) {
    this.respondents.next(respondets);
  }
  setFormId(formId: any) {
    this.formId.next(formId);
  }
  setForm(form: any) {
    this.form.next(form);
  }



  constructor() { }


  handleSelectedType() {
    this.handleTypeSelected.next('')
  }



  setRouteResellerId(Id: any) {
    this.routeResellerId.next(Id);
  }


  //
  private refreshRespondents = new BehaviorSubject<any>({});
  onRefreshRespondents = this.refreshRespondents.asObservable();
  setRefreshRespondents(respondets: any) {
    this.refreshRespondents.next(respondets);
  }

}
