<z-modal [open]="open">
  <ng-container header>
    <h6 class="text-zen-primarydefault text-lg font-medium">
      {{'Select category'| translate:language:ModuleNames.SurveyX }}
    </h6>

    <button class="btn cancel w-10 h-10 rounded bg-zen-littlegray !text-zen-primarylight" (click)="close()">
      <i class="fa-solid fa-xmark"></i>
    </button>
  </ng-container>

  <ng-container body>
    <div class="flex flex-wrap w-[27.8rem]">
      <p class="text-zen-primarylight text-sm mb-4">
        {{'Select a category from the list below:'| translate:language:ModuleNames.SurveyX }}
      </p>

      <div class="relative w-full mb-2 bg-white rounded border-[1px] border-zen-paleWhite border-solid">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <i class="far fa-search w-5 text-[#7D92B5]"></i>
        </div>

        <input type="text" [(ngModel)]="searchValue" (ngModelChange)="searchCategories($event)"
          class="text-[#7D92B5] text-sm border border-gray-300 rounded-lg block w-full pl-10 p-2.5"
          [placeholder]="'Search...'| translate:language:ModuleNames.SurveyX">
      </div>

      <ul class="w-full h14-5rem gap-1 overflow-y-auto border-b border-zen-paleWhite mb-4">
        <li *ngFor="let category of filteredCategoriesList; let i = index">
          <div class="flex px-2 py-1 items-center justify-between rounded hover:bg-zen-littlegray">
            <div class="flex p-2 gap-2 flex-row items-center btn text-zen-primarylight" (click)="selectedCategory = category">
              <i class="fa-regular text-2xl"
                [ngClass]="selectedCategory?.id == category.id?'fa-circle-dot text-exp':'fa-circle'"></i>

              <h5 class="text-sm text-zen-primarydark">
                {{ getCategoryName(category.name, language) }}
              </h5>
            </div>
            <div class="flex justify-center items-center w-8 h-8">
              <button class="w-5 h-5 text-zen-primarylight" [ZMenuTriggerFor]="menu">
                <i class="far fa-ellipsis-vertical"></i>
              </button>

              <z-menu #menu>
                <menuItems>
                  <a MenuItem (click)="editCategory(category, menu)">
                    <i class="fa-regular fa-pen mx-1"></i>
                    {{'Edit'| translate:language:ModuleNames.SurveyX }}
                  </a>

                  <a MenuItem (click)="deleteCategory(category, menu)">
                    <i class="fa-regular fa-trash mx-1"></i>
                    {{'Delete'| translate:language:ModuleNames.SurveyX }}
                  </a>
                </menuItems>
              </z-menu>
            </div>
          </div>
        </li>
      </ul>

      <div class="w-full flex justify-center items-center px-4 py-2">
        <button class="flex gap-3 items-center justify-center text-zen-primarylight text-sm font-medium h-4"
          (click)="newCategoryModalButtonClick()">
          <i class="far fa-plus"></i>

          {{'New Category'| translate:language:ModuleNames.SurveyX }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="flex flex-row justify-between w-full">
      <a class="btn close h-10 py-2 px-4 bg-zen-littlegray rounded text-zen-primarylight text-sm font-medium flex items-center"
        (click)="close()">
        {{ 'Cancel' | translate: language: ModuleNames.SurveyX }}
      </a>

      <a class="btn exp h-10 py-2 px-4 bg-exp rounded !text-white text-sm font-medium flex items-center"
        (click)="onSubmit()">
        {{'Apply'| translate:language:ModuleNames.SurveyX }}
      </a>
    </div>
  </ng-container>
</z-modal>
