import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { ICDKPortalComponent, DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as ExcelJS from 'exceljs';

@Component({
  selector: 'app-json-to-excel',
  template: '',
})
export class JsonToExcelComponent implements OnInit, ICDKPortalComponent {

  constructor(@Inject(DATA_TOKEN) private data: any,) { }
  close = new EventEmitter<any>();
  results = new EventEmitter<any>();

  fileName = '';
  arrayRecords = [];
  ngOnInit(): void {
    this.fileName = this.data.name;
    this.arrayRecords = this.data.data;
    this.export(this.arrayRecords, this.fileName);
  }

  export(records, name) {

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Main');
    let columns = [];

    if (records.length) {
      columns = Object.keys(records[0]).map((key, i) => {
        const result = key.replace(/([A-Z])/g, " $1");
        return {
          title: result.charAt(0).toUpperCase() + result.slice(1),
          col: String.fromCharCode(i + 65),
          id: key
        }
      })
    }

    //Add Header
    sheet.columns = columns.map(col => {
      return {
        header: col.title, key: col.id, width: 20,
      };
    })

    records.forEach(row => {
      sheet.addRow(row);
    });

    const headerRow = sheet.getRow(1);

    columns.forEach((x, i) => {
      headerRow.getCell(x.id).font = {
        bold: true,
        size: 12,
        color: { argb: 'FFFFFF' },
      }

      headerRow.getCell(x.id).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '0082C6' },
      }
    })

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${name}.xlsx`);

      this.close.emit(true);
    });
  }

}
