import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExitNewRoutingModule } from './exit-new-routing.module';
import { ExitNewComponent } from './exit-new.component';
import { SharedModule } from '../shared/shared.module';
import { ListComponent } from './features/list/list.component';
import { TemplatesComponent } from './features/templates/templates.component';
import { AnalysisComponent } from './features/analysis/analysis.component';
import { ExitBaseComponent } from './ui/exit-base/exit-base.component';
import { SidebarComponent } from './ui/sidebar/sidebar.component';
import { LeaversListComponent } from './ui/leavers-list/leavers-list.component';
import { EntityTreeModalComponent } from './ui/entity-tree-modal/entity-tree-modal.component';
import { TemplateEditorComponent } from './ui/template-editor/template-editor.component';
import { TRANSLATION_FILE_TOKEN, TranslationFileProvider } from 'projects/ex/theme-shared/src/lib/tokens/translation';
import { ModuleName } from '../shared/enums/ModuleName';
import { NewLeaverComponent } from './features/new-leaver/new-leaver.component';
import { ManualComponent } from './ui/new-leaver/manual/manual.component';
import { ExcelComponent } from './ui/new-leaver/excel/excel.component';
import { SeperationTypesService } from './data-access/services/seperation-types.service';
import { FIREBASE_UPDATE_TOKEN } from '../core/domain/firebase-update';
import { FirebaseUpdateService } from './data-access/services/firebase-update.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { LeaverDetailsComponent } from './features/leaver-details/leaver-details.component';
import { LeaverAnswersPreviewReportComponent } from './ui/leaver-answers-preview-report/leaver-answers-preview-report.component';
import { ExitUnitService } from './data-access/services/exit-unit.service';
import { TemplateNewQuestionsComponent } from './ui/template-new-questions/template-new-questions.component';
import { OverallAnalysisComponent } from './features/analysis/overall-analysis/overall-analysis.component';
import { LeaverAnalysisComponent } from './features/analysis/leaver-analysis/leaver-analysis.component';
import { QuestionAnalysisComponent } from './features/analysis/question-analysis/question-analysis.component';
import { CommentsAnalysisComponent } from './features/analysis/comments-analysis/comments-analysis.component';
import { ExitTreeOptionsComponent } from './ui/exit-tree-options/exit-tree-options.component';
import { ExitAnalysisReportComponent } from './features/analysis/exit-analysis-report/exit-analysis-report.component';
import { TemplateManagementService } from './data-access/services/template-management.service';
import { ExitTemplatePreviewComponent } from './ui/exit-template-preview/exit-template-preview.component';
import { KEY_RESOLVER_TOKEN } from '../core/services/key-resolver.service';
import { SeperationTypeResolver } from './utils/seperation-type-resolver';
import { LeaverStatusResolver } from './utils/leaver-status-resolver';
import { CommentListComponent } from './ui/comment-list/comment-list.component';
import { CommentsAiComponent } from './ui/comments-ai/comments-ai.component';
import { CommentComponent } from './ui/comments-ai/comment/comment.component';
import { AiCommentModule } from '../shared/ai-comments/ai-comment.module';
import { ExitAiCommentsService } from './data-access/services/exit-ai-comments.service';
import { AI_COMMENTS_SERVICE } from '../shared/ai-comments/components/main/main.component';
import { ProductTourModule } from '../shared/product-tour/product-tour.module';


@NgModule({
  declarations: [
    ExitNewComponent,
    ListComponent,
    TemplatesComponent,
    AnalysisComponent,
    ExitBaseComponent,
    SidebarComponent,
    LeaversListComponent,
    EntityTreeModalComponent,
    TemplateEditorComponent,
    NewLeaverComponent,
    ManualComponent,
    ExcelComponent,
    LeaverDetailsComponent,
    LeaverAnswersPreviewReportComponent,
    TemplateNewQuestionsComponent,
    OverallAnalysisComponent,
    LeaverAnalysisComponent,
    QuestionAnalysisComponent,
    CommentsAnalysisComponent,
    ExitTreeOptionsComponent,
    ExitAnalysisReportComponent,
    ExitTemplatePreviewComponent,
    CommentListComponent,
    CommentsAiComponent,
    CommentComponent,
  ],
  imports: [
    CommonModule,
    ExitNewRoutingModule,
    SharedModule,
    AiCommentModule,
    ProductTourModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    SeperationTypesService,
    TemplateManagementService,
    ExitUnitService,
    {
      provide: FIREBASE_UPDATE_TOKEN,
      useClass: FirebaseUpdateService,
      deps: [AngularFireDatabase]
    },
    { provide: AI_COMMENTS_SERVICE, useClass: ExitAiCommentsService }
  ]
})
export class ExitNewModule {
  static forRoot(): ModuleWithProviders<ExitNewModule> {

    return {
      ngModule: ExitNewModule,
      providers: [
        {
          provide: TRANSLATION_FILE_TOKEN,
          multi: true,
          useValue: {
            module: ModuleName.Exit,
            translations: JSON.parse(localStorage.getItem(ModuleName.Exit))
          } as TranslationFileProvider
        },
        {
          provide: KEY_RESOLVER_TOKEN,
          multi: true,
          useClass: SeperationTypeResolver
        },
        {
          provide: KEY_RESOLVER_TOKEN,
          multi: true,
          useClass: LeaverStatusResolver
        }

      ]
    }

  }

}
