import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ErrorHandler } from '../data-models/error-handler';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  version: string = "1.0 alpha";
  identifierCodes: any[] = [

    { key: 777, name: 'Unknown Error', componentName: 'Unknown', pageName: 'Unknown' },


    //------------------------------------------------------------------------------------------------------------
    //---------------------------------------------- NAVIGATION BAR ----------------------------------------------
    //------------------------------------------------------------------------------------------------------------

    // NAVIGATION BAR
    { key: 1, name: 'NavigationBarComponent.evaluateNotifications()', componentName: 'NavigationBarComponent', pageName: 'Navigation' },
    { key: 2, name: 'NavigationBarComponent.evaluatePopupNotification()', componentName: 'NavigationBarComponent', pageName: 'Navigation' },
    { key: 3, name: 'NavigationBarComponent.removeNotification()', componentName: 'NavigationBarComponent', pageName: 'Navigation' },
    { key: 4, name: 'NavigationBarComponent.resetPassword()', componentName: 'NavigationBarComponent', pageName: 'Navigation' },

    //------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------



    //------------------------------------------------------------------------------------------------------------
    //--------------------------------- EXIT ERROR CODES ---------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------

    // EXIT HOME COMPONENT
    { key: 101, name: 'HomeComponent.ngOnInit()', componentName: 'HomeComponent', pageName: 'Exit' },
    { key: 102, name: 'HomeComponent.fetchPlatformUser()', componentName: 'HomeComponent', pageName: 'Exit' },
    { key: 103, name: 'HomeComponent.fetchTenant()', componentName: 'HomeComponent', pageName: 'Exit' },
    { key: 104, name: 'HomeComponent.fetchQuestionnaires()', componentName: 'HomeComponent', pageName: 'Exit' },
    { key: 105, name: 'HomeComponent.fetchAllQuestionnaires()', componentName: 'HomeComponent', pageName: 'Exit' },
    { key: 106, name: 'HomeComponent.fetchAllEntities()', componentName: 'HomeComponent', pageName: 'Exit' },
    { key: 107, name: 'HomeComponent.fetchLeaversList()', componentName: 'HomeComponent', pageName: 'Exit' },
    { key: 108, name: 'HomeComponent.fetchNotifications()', componentName: 'HomeComponent', pageName: 'Exit' },
    { key: 109, name: 'HomeComponent.fetchAllTenantUsers()', componentName: 'HomeComponent', pageName: 'Exit' },


    // EXIT DASHBOARD COMPONENT
    { key: 201, name: 'DashboardComponent.startCalculations()', componentName: 'DashboardComponent', pageName: 'Exit' },
    { key: 202, name: 'DashboardComponent.calculateQuestionnaireStatuses()', componentName: 'DashboardComponent', pageName: 'Exit' },
    { key: 203, name: 'DashboardComponent.calculateSeperationTypes()', componentName: 'DashboardComponent', pageName: 'Exit' },
    { key: 204, name: 'DashboardComponent.calculateNoOfLeaversPerMonth()', componentName: 'DashboardComponent', pageName: 'Exit' },


    // EXIT LEAVERS COMPONENT
    { key: 301, name: 'LeaversListComponent.viewAnswers()', componentName: 'LeaversListComponent', pageName: 'Exit' },
    { key: 302, name: 'LeaversListComponent.addNewLeaver()', componentName: 'LeaversListComponent', pageName: 'Exit' },


    // EXIT REPORTS COMPONENT
    { key: 401, name: 'ReportsComponent.getQuestionnaires()', componentName: 'ReportsComponent', pageName: 'Exit' },
    { key: 402, name: 'ReportsComponent.fetchQuestionnaire()', componentName: 'ReportsComponent', pageName: 'Exit' },
    { key: 403, name: 'ReportsComponent.fetchLeaversInQuestionnaire()', componentName: 'ReportsComponent', pageName: 'Exit' },
    { key: 404, name: 'ReportsComponent.startDataAnalysisProcess()', componentName: 'ReportsComponent', pageName: 'Exit' },
    //------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------






    //------------------------------------------------------------------------------------------------------------
    //--------------------------------- ENGAGEMENT ERROR CODES ---------------------------------------------------
    //------------------------------------------------------------------------------------------------------------


    // ENGAGEMENT HOME COMPONENT
    { key: 501, name: 'EngagementHomeComponent.ngOnInit()', componentName: 'EngagementHomeComponent', pageName: 'Engagement' },
    { key: 502, name: 'EngagementHomeComponent.fetchPlatformUser()', componentName: 'EngagementHomeComponent', pageName: 'Engagement' },
    { key: 503, name: 'EngagementHomeComponent.fetchTenant()', componentName: 'EngagementHomeComponent', pageName: 'Engagement' },
    { key: 504, name: 'EngagementHomeComponent.fetchAllTenantUsers()', componentName: 'EngagementHomeComponent', pageName: 'Engagement' },
    { key: 505, name: 'EngagementHomeComponent.fetchAllEntities()', componentName: 'EngagementHomeComponent', pageName: 'Engagement' },
    { key: 506, name: 'EngagementHomeComponent.fetchNotifications()', componentName: 'EngagementHomeComponent', pageName: 'Engagement' },
    { key: 507, name: 'EngagementHomeComponent.fetchRespondents()', componentName: 'EngagementHomeComponent', pageName: 'Engagement' },


    // ENGAGEMENT LIST COMPONENT
    { key: 601, name: 'EngagementListComponent.fetchEngagements()', componentName: 'EngagementListComponent', pageName: 'Engagement' },
    { key: 602, name: 'EngagementListComponent.fetchRespondents()', componentName: 'EngagementListComponent', pageName: 'Engagement' },
    { key: 603, name: 'EngagementListComponent.updateRespondentPool()', componentName: 'EngagementListComponent', pageName: 'Engagement' },
    { key: 604, name: 'EngagementListComponent.renameEngagement()', componentName: 'EngagementListComponent', pageName: 'Engagement' },
    { key: 605, name: 'EngagementListComponent.newEngagement()', componentName: 'EngagementListComponent', pageName: 'Engagement' },
    { key: 606, name: 'EngagementListComponent.removeEngagement()', componentName: 'EngagementListComponent', pageName: 'Engagement' },
    { key: 607, name: 'EngagementListComponent.relaunchEngagement()', componentName: 'EngagementListComponent', pageName: 'Engagement' },
    { key: 608, name: 'EngagementListComponent.cloneEngagement()', componentName: 'EngagementListComponent', pageName: 'Engagement' },
    { key: 609, name: 'EngagementListComponent.changeListener()', componentName: 'EngagementListComponent', pageName: 'Engagement' },


    // ENGAGEMENT SURVEY SETUP COMPONENT
    { key: 701, name: 'EngagementSurveySetupComponent.openEngagement()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 702, name: 'EngagementSurveySetupComponent.fetchEngagementRespondents()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 703, name: 'EngagementSurveySetupComponent.updateExistingEngagement()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 704, name: 'EngagementSurveySetupComponent.fetchRespondents()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 705, name: 'EngagementSurveySetupComponent.changeListener()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 706, name: 'EngagementSurveySetupComponent.updateEngagementMetrics()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 707, name: 'EngagementSurveySetupComponent.updateEngagementRespondent()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 708, name: 'EngagementSurveySetupComponent.updateRespondentPool()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 709, name: 'EngagementSurveySetupComponent.ngOnInit()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 710, name: 'EngagementSurveySetupComponent.removeEnagagementRespondent()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 711, name: 'EngagementSurveySetupComponent.bulkRemoveRespondents()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 712, name: 'EngagementSurveySetupComponent.bulkUpdateRespondents()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 713, name: 'EngagementSurveySetupComponent.createEngagementReferences()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },
    { key: 714, name: 'EngagementSurveySetupComponent.sendEngagementEmails()', componentName: 'EngagementSurveySetupComponent', pageName: 'Engagement' },


    // ENGAGEMENT LIVE SETUP COMPONENT
    { key: 801, name: 'EngagementLiveSurveyComponent.openEngagement()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 802, name: 'EngagementLiveSurveyComponent.fetchRespondents()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 803, name: 'EngagementLiveSurveyComponent.updateExistingEngagement()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 804, name: 'EngagementLiveSurveyComponent.addEngagementRespondentToLive()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 805, name: 'EngagementLiveSurveyComponent.updateEngagementRespondent()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 806, name: 'EngagementLiveSurveyComponent.updateRespondentPool()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 807, name: 'EngagementLiveSurveyComponent.removeEnagagementRespondent()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 808, name: 'EngagementLiveSurveyComponent.bulkRemoveRespondents()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 809, name: 'EngagementLiveSurveyComponent.bulkUpdateRespondents()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 810, name: 'EngagementLiveSurveyComponent.changeListener()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 811, name: 'EngagementLiveSurveyComponent.createEngagementReferences()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 812, name: 'EngagementLiveSurveyComponent.sendEngagementReminders()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 813, name: 'EngagementLiveSurveyComponent.sendEngagementEmails()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },
    { key: 814, name: 'EngagementLiveSurveyComponent.closeSurvey()', componentName: 'EngagementLiveSurveyComponent', pageName: 'Engagement' },



    // ENGAGEMENT CLOSED SETUP COMPONENT
    { key: 901, name: 'EngagementClosedSurveyComponent.openEngagement()', componentName: 'EngagementClosedSurveyComponent', pageName: 'Engagement' },
    { key: 902, name: 'EngagementClosedSurveyComponent.fetchEngagementQuestionnaire()', componentName: 'EngagementClosedSurveyComponent', pageName: 'Engagement' },
    { key: 903, name: 'EngagementClosedSurveyComponent.fetchEngagementAnswers()', componentName: 'EngagementClosedSurveyComponent', pageName: 'Engagement' },
    { key: 904, name: 'EngagementClosedSurveyComponent.startAnalysisProcess()', componentName: 'EngagementClosedSurveyComponent', pageName: 'Engagement' },
    { key: 905, name: 'EngagementClosedSurveyComponent.updateModalFunc()', componentName: 'EngagementClosedSurveyComponent', pageName: 'Engagement' },
    { key: 906, name: 'EngagementClosedSurveyComponent.selectedHeatMapChanged()', componentName: 'EngagementClosedSurveyComponent', pageName: 'Engagement' },
    { key: 907, name: 'EngagementClosedSurveyComponent.selectedEngagementScaleByDriver()', componentName: 'EngagementClosedSurveyComponent', pageName: 'Engagement' },
    { key: 908, name: 'EngagementClosedSurveyComponent.selectedEngagementScaleCriteria()', componentName: 'EngagementClosedSurveyComponent', pageName: 'Engagement' },



    //------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------





    //------------------------------------------------------------------------------------------------------------
    //---------------------------------------------- SETTINGS ----------------------------------------------------
    //------------------------------------------------------------------------------------------------------------

    // NAVIGATION BAR
    { key: 1001, name: 'SettingsComponent.upload()', componentName: 'SettingsComponent', pageName: 'Settings' },
    { key: 1002, name: 'SettingsComponent.fetchTenantAccountBalances()', componentName: 'SettingsComponent', pageName: 'Settings' },
    { key: 1003, name: 'SettingsComponent.updateEntity()', componentName: 'SettingsComponent', pageName: 'Settings' },
    { key: 1004, name: 'SettingsComponent.updateCompanyEntity()', componentName: 'SettingsComponent', pageName: 'Settings' },
    { key: 1005, name: 'SettingsComponent.createNewUser()', componentName: 'SettingsComponent', pageName: 'Settings' },
    { key: 1006, name: 'SettingsComponent.resetPassword()', componentName: 'SettingsComponent', pageName: 'Settings' },
    { key: 1007, name: 'SettingsComponent.updateUserEntities()', componentName: 'SettingsComponent', pageName: 'Settings' },
    { key: 1008, name: 'SettingsComponent.updatePlatformUser()', componentName: 'SettingsComponent', pageName: 'Settings' },


    //------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------





    //------------------------------------------------------------------------------------------------------------
    //-------------------------------------- ASSESSMENT ERROR CODES ----------------------------------------------
    //------------------------------------------------------------------------------------------------------------

    // ASSESSMENT HOME COMPONENT
    { key: 1101, name: 'AssessmentHomeComponent.ngOnInit()', componentName: 'AssessmentHomeComponent', pageName: 'Assessment' },
    { key: 1102, name: 'AssessmentHomeComponent.fetchPlatformUser()', componentName: 'AssessmentHomeComponent', pageName: 'Assessment' },
    { key: 1103, name: 'AssessmentHomeComponent.fetchTenant()', componentName: 'AssessmentHomeComponent', pageName: 'Assessment' },
    { key: 1104, name: 'AssessmentHomeComponent.fetchAllTenantUsers()', componentName: 'AssessmentHomeComponent', pageName: 'Assessment' },
    { key: 1105, name: 'AssessmentHomeComponent.fetchAllEntities()', componentName: 'AssessmentHomeComponent', pageName: 'Assessment' },
    { key: 1106, name: 'AssessmentHomeComponent.fetchNotifications()', componentName: 'AssessmentHomeComponent', pageName: 'Assessment' },
    { key: 1107, name: 'AssessmentHomeComponent.fetchRespondents()', componentName: 'AssessmentHomeComponent', pageName: 'Assessment' },



    // ASSESSMENT SURVEY LIST COMPONENT
    { key: 1201, name: 'AssessmentListComponent.fetchEngagements()', componentName: 'AssessmentListComponent', pageName: 'Assessment' },
    { key: 1202, name: 'AssessmentListComponent.fetchRespondents()', componentName: 'AssessmentListComponent', pageName: 'Assessment' },
    { key: 1203, name: 'AssessmentListComponent.newEngagement()', componentName: 'AssessmentListComponent', pageName: 'Assessment' },
    { key: 1204, name: 'AssessmentListComponent.renameEngagement()', componentName: 'AssessmentListComponent', pageName: 'Assessment' },
    { key: 1205, name: 'AssessmentListComponent.removeEngagement()', componentName: 'AssessmentListComponent', pageName: 'Assessment' },
    { key: 1206, name: 'AssessmentListComponent.relaunchEngagement()', componentName: 'AssessmentListComponent', pageName: 'Assessment' },
    { key: 1207, name: 'AssessmentListComponent.cloneEngagement()', componentName: 'AssessmentListComponent', pageName: 'Assessment' },
    { key: 1208, name: 'AssessmentListComponent.updateRespondentPool()', componentName: 'AssessmentListComponent', pageName: 'Assessment' },
    { key: 1209, name: 'AssessmentListComponent.changeListener()', componentName: 'AssessmentListComponent', pageName: 'Assessment' },



    // ASSESSMENT SURVEY SETUP COMPONENT
    { key: 1301, name: 'AssessmentSurveySetupComponent.fetchRespondents()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1302, name: 'AssessmentSurveySetupComponent.changeListener()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1303, name: 'AssessmentSurveySetupComponent.getDataRecordsArrayFromCSVFile()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1304, name: 'AssessmentSurveySetupComponent.openEngagement()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1305, name: 'AssessmentSurveySetupComponent.fetchEngagements()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1306, name: 'AssessmentSurveySetupComponent.fetchAssessmentPredefinedTemplates()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1307, name: 'AssessmentSurveySetupComponent.removeAssessmentTemplate()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1308, name: 'AssessmentSurveySetupComponent.fetchCompanyPredefinedAssessmentTemplates()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1309, name: 'AssessmentSurveySetupComponent.fetchEngagementRespondents()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1310, name: 'AssessmentSurveySetupComponent.updateExistingEngagement()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1311, name: 'AssessmentSurveySetupComponent.updateEngagementMetrics()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1312, name: 'AssessmentSurveySetupComponent.evaluateMaxEvalutors()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1313, name: 'AssessmentSurveySetupComponent.updateEngagementRespondent()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1314, name: 'AssessmentSurveySetupComponent.updateRespondentPool()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1315, name: 'AssessmentSurveySetupComponent.ngOnInit()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1316, name: 'AssessmentSurveySetupComponent.createNewSurveyTemplate()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1317, name: 'AssessmentSurveySetupComponent.saveTemplate()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1318, name: 'AssessmentSurveySetupComponent.removeEnagagementRespondent()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1319, name: 'AssessmentSurveySetupComponent.weightCheck()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1320, name: 'AssessmentSurveySetupComponent.addStatement()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1321, name: 'AssessmentSurveySetupComponent.addCompetency()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1322, name: 'AssessmentSurveySetupComponent.statementAddFunction()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1323, name: 'AssessmentSurveySetupComponent.statementCount()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1324, name: 'AssessmentSurveySetupComponent.competencyCount()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1325, name: 'AssessmentSurveySetupComponent.compentencySelected()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1326, name: 'AssessmentSurveySetupComponent.getOverAllWeight()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1327, name: 'AssessmentSurveySetupComponent.bulkRemoveRespondents()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1328, name: 'AssessmentSurveySetupComponent.bulkUpdateRespondents()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1329, name: 'AssessmentSurveySetupComponent.getCurrentUsed()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1330, name: 'AssessmentSurveySetupComponent.createEngagementReferences()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },
    { key: 1331, name: 'AssessmentSurveySetupComponent.sendEngagementEmails()', componentName: 'AssessmentSurveySetupComponent', pageName: 'Assessment' },



    // ASSESSMENT LIVE SURVEY COMPONENT
    { key: 1401, name: 'AssessmentLiveSurveyComponent.fetchRespondents()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1402, name: 'AssessmentLiveSurveyComponent.changeListener()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1403, name: 'AssessmentLiveSurveyComponent.getDataRecordsArrayFromCSVFile()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1404, name: 'AssessmentLiveSurveyComponent.openEngagement()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1405, name: 'AssessmentLiveSurveyComponent.fetchEngagements()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1406, name: 'AssessmentLiveSurveyComponent.fetchAssessmentPredefinedTemplates()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1407, name: 'AssessmentLiveSurveyComponent.removeAssessmentTemplate()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1408, name: 'AssessmentLiveSurveyComponent.fetchCompanyPredefinedAssessmentTemplates()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1409, name: 'AssessmentLiveSurveyComponent.fetchEngagementRespondents()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1410, name: 'AssessmentLiveSurveyComponent.updateExistingEngagement()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1411, name: 'AssessmentLiveSurveyComponent.updateEngagementMetrics()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1412, name: 'AssessmentLiveSurveyComponent.evaluateMaxEvalutors()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1413, name: 'AssessmentLiveSurveyComponent.updateEngagementRespondent()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1414, name: 'AssessmentLiveSurveyComponent.updateRespondentPool()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1415, name: 'AssessmentLiveSurveyComponent.ngOnInit()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1416, name: 'AssessmentLiveSurveyComponent.createNewSurveyTemplate()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1417, name: 'AssessmentLiveSurveyComponent.saveTemplate()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1418, name: 'AssessmentLiveSurveyComponent.removeEnagagementRespondent()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1419, name: 'AssessmentLiveSurveyComponent.weightCheck()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1420, name: 'AssessmentLiveSurveyComponent.addStatement()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1421, name: 'AssessmentLiveSurveyComponent.addCompetency()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1422, name: 'AssessmentLiveSurveyComponent.statementAddFunction()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1423, name: 'AssessmentLiveSurveyComponent.statementCount()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1424, name: 'AssessmentLiveSurveyComponent.competencyCount()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1425, name: 'AssessmentLiveSurveyComponent.compentencySelected()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1426, name: 'AssessmentLiveSurveyComponent.getOverAllWeight()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1427, name: 'AssessmentLiveSurveyComponent.bulkRemoveRespondents()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1428, name: 'AssessmentLiveSurveyComponent.bulkUpdateRespondents()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1429, name: 'AssessmentLiveSurveyComponent.getCurrentUsed()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1430, name: 'AssessmentLiveSurveyComponent.createEngagementReferences()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1431, name: 'AssessmentLiveSurveyComponent.sendEngagementEmails()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1432, name: 'AssessmentLiveSurveyComponent.sendEngagementReminders()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1433, name: 'AssessmentLiveSurveyComponent.closeSurvey()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },
    { key: 1434, name: 'AssessmentLiveSurveyComponent.evaluateUpdateRespondent()', componentName: 'AssessmentLiveSurveyComponent', pageName: 'Assessment' },





    // ASSESSMENT CLOSED SURVEY COMPONENT
    { key: 1501, name: 'AssessmentClosedSurveyComponent.openEngagement()', componentName: 'AssessmentClosedSurveyComponent', pageName: 'Assessment' },
    { key: 1502, name: 'AssessmentClosedSurveyComponent.fetchEngagementAnswers()', componentName: 'AssessmentClosedSurveyComponent', pageName: 'Assessment' },
    { key: 1503, name: 'AssessmentClosedSurveyComponent.startCalculationProcess()', componentName: 'AssessmentClosedSurveyComponent', pageName: 'Assessment' },
    { key: 1504, name: 'AssessmentClosedSurveyComponent.candidateCriteriaSelectionChanged()', componentName: 'AssessmentClosedSurveyComponent', pageName: 'Assessment' },
    { key: 1505, name: 'AssessmentClosedSurveyComponent.candidateCompSelectionChanged()', componentName: 'AssessmentClosedSurveyComponent', pageName: 'Assessment' },

    //------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------






    //------------------------------------------------------------------------------------------------------------
    //----------------------------------------- ACCOUNT OVERVIEW--------------------------------------------------
    //------------------------------------------------------------------------------------------------------------

    // NAVIGATION BAR
    { key: 1601, name: 'AccountOverviewComponent.ngOnInit()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1602, name: 'AccountOverviewComponent.fetchPlatformUser()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1603, name: 'AccountOverviewComponent.fetchTenant()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1604, name: 'AccountOverviewComponent.fetchAllTenantUsers()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1605, name: 'AccountOverviewComponent.fetchQuestionnaires()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1606, name: 'AccountOverviewComponent.fetchAllQuestionnaires()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1607, name: 'AccountOverviewComponent.fetchAllEntities()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1608, name: 'AccountOverviewComponent.fetchTenantAccountBalances()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1609, name: 'AccountOverviewComponent.subscribeToTenant()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1610, name: 'AccountOverviewComponent.requestMoreUnits()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1611, name: 'AccountOverviewComponent.addUnits()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },
    { key: 1612, name: 'AccountOverviewComponent.confirmAllocation()', componentName: 'AccountOverviewComponent', pageName: 'Account Overview' },


    //------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------




    //------------------------------------------------------------------------------------------------------------
    //----------------------------------------- QUESTIONNAIRES ---------------------------------------------------
    //------------------------------------------------------------------------------------------------------------

    // EXIT QUESTIONNAIRE
    { key: 1701, name: 'LeaverQuestionnaireComponent.ngOnInit()', componentName: 'LeaverQuestionnaireComponent', pageName: 'Leaver Questionnaire' },
    { key: 1702, name: 'LeaverQuestionnaireComponent.fetchLeaverInformation()', componentName: 'LeaverQuestionnaireComponent', pageName: 'Leaver Questionnaire' },
    { key: 1703, name: 'LeaverQuestionnaireComponent.fetchQuestionnaire()', componentName: 'LeaverQuestionnaireComponent', pageName: 'Leaver Questionnaire' },
    { key: 1704, name: 'LeaverQuestionnaireComponent.fetchTenantUsers()', componentName: 'LeaverQuestionnaireComponent', pageName: 'Leaver Questionnaire' },
    { key: 1705, name: 'LeaverQuestionnaireComponent.submitQuestionnaireNotification()', componentName: 'LeaverQuestionnaireComponent', pageName: 'Leaver Questionnaire' },
    { key: 1706, name: 'LeaverQuestionnaireComponent.submitQuestionnaire()', componentName: 'LeaverQuestionnaireComponent', pageName: 'Leaver Questionnaire' },


    // ENGAGEMENT QUESTIONNAIRE
    { key: 1801, name: 'EngagementQuestionnaireComponent.ngOnInit()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1802, name: 'EngagementQuestionnaireComponent.fetchEngagementReference()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1803, name: 'EngagementQuestionnaireComponent.fetchEngagement()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1804, name: 'EngagementQuestionnaireComponent.fetchEngagementSurvey()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1805, name: 'EngagementQuestionnaireComponent.fetchSelectedEntity()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1806, name: 'EngagementQuestionnaireComponent.fetchRespondent()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1807, name: 'EngagementQuestionnaireComponent.fetchPredefinedMetrics()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1808, name: 'EngagementQuestionnaireComponent.setUpMetrics()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1809, name: 'EngagementQuestionnaireComponent.submitQuestionnaire()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1810, name: 'EngagementQuestionnaireComponent.dataCollected()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },
    { key: 1811, name: 'EngagementQuestionnaireComponent.fetchEngagementMetrics()', componentName: 'EngagementQuestionnaireComponent', pageName: 'Engagement Questionnaire' },


    // ASSESSMENT QUESTIONNAIRE
    { key: 1901, name: 'AssessmentQuestionnaireComponent.ngOnInit()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1902, name: 'AssessmentQuestionnaireComponent.fetchEngagementReference()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1903, name: 'AssessmentQuestionnaireComponent.fetchEngagement()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1904, name: 'AssessmentQuestionnaireComponent.fetchEngagementSurvey()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1905, name: 'AssessmentQuestionnaireComponent.fetchSelectedEntity()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1906, name: 'AssessmentQuestionnaireComponent.fetchRespondent()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1907, name: 'AssessmentQuestionnaireComponent.fetchPredefinedMetrics()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1908, name: 'AssessmentQuestionnaireComponent.setUpMetrics()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1909, name: 'AssessmentQuestionnaireComponent.submitQuestionnaire()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1910, name: 'AssessmentQuestionnaireComponent.dataCollected()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },
    { key: 1911, name: 'AssessmentQuestionnaireComponent.fetchEngagementMetrics()', componentName: 'AssessmentQuestionnaireComponent', pageName: 'Assessment Questionnaire' },


    //------------------------------------------------------------------------------------------------------------
    //------------------------------------------------------------------------------------------------------------


  ];

  fbFunctionURL: string = '';

  constructor(private db: AngularFireDatabase, private http: HttpClient, private globals: GlobalsService) {
    this.fbFunctionURL = this.globals.fbFunctionURL;

   }

  submitErrorMessage(identifierCode: number, ex: any, tenantKey: string,
    tenantName: string, companyKey: string, companyName: string, caughtBy: string) {
    console.log(ex);
    let errorHandler = new ErrorHandler();
    errorHandler.identifierCode = identifierCode;
    errorHandler.tenantKey = tenantKey;
    errorHandler.companyKey = companyKey;
    errorHandler.tenantName = tenantName;
    errorHandler.companyName = companyName;
    errorHandler = this.extractInfoFromIdentifierCode(errorHandler);

    if (errorHandler.identifierCode == 777)
      errorHandler.identifierCode = Math.ceil(this.getRandomArbitrary(770, 790));

    errorHandler.key = errorHandler.originIdentifier + '-' + errorHandler.identifierCode;
    errorHandler.timeStamp = Number((new Date().getTime() / 1000).toFixed(0));
    errorHandler.caughtBy = caughtBy;
    errorHandler.errorMessage = JSON.stringify(ex);
    errorHandler.clientVersion = this.version;
    let request = { errorHandler: errorHandler };
    this.http.post(this.fbFunctionURL + 'registerAndNotifyError', request).subscribe((response: any) => {

    });

  }

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }


  extractInfoFromIdentifierCode(errorHandler: ErrorHandler) {
    let x = this.identifierCodes.find(i => i.key == errorHandler.identifierCode);
    if (x) {
      errorHandler.name = x.name;
      errorHandler.componentName = x.componentName;
      errorHandler.pageName = x.pageName;
    } else {
      errorHandler.name = 'Undefined In Error Service';
      errorHandler.componentName = 'Undefined In Error Service';
      errorHandler.pageName = 'Undefined In Error Service';
    }
    return errorHandler;
  }

}
