import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { AnalyticsFilter, ChartFilter } from './analytics.service';
import { CriteriaResolverService } from './criteria-resolver.service';


export default interface RawDataExcel {
  category: string,
  group: string,
  percentage: number,
  count: number,
  exportData: any[]
}

@Injectable({
  providedIn: 'root'
})
export class ExcelDownloadService {
  currentRow: number = 0;

  constructor(private criteriaResolverService: CriteriaResolverService) { }

  generate(data: RawDataExcel[], fileName = "download", filters: ChartFilter[] = [], groupedChart: boolean = false, groupedBy: string = null) {
    this.currentRow = 1;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');


    // Add Question Title
    const questionTitle = ["Q: " + fileName]
    worksheet.insertRow(this.currentRow, questionTitle);

    const fileNameCell = worksheet.getCell(`A${this.currentRow}`);
    fileNameCell.font = { bold: true };
    fileNameCell.alignment = { wrapText: true };

    this.currentRow += 1;

    // Add Filtered By
if (filters.length) {
    const filteredBy = ["Filtered By"];
    const filterMap = new Map();

    filters.forEach(filter => {
        const valuesArray = JSON.parse(filter.value);
        const formattedValue = Array.isArray(valuesArray) ? valuesArray.join(', ') : valuesArray;

        if (filterMap.has(filter.key)) {
            // If key exists, append value with a comma
            filterMap.set(this.criteriaResolverService.extractTitle(filter.key, "EN"), `${filterMap.get(this.criteriaResolverService.extractTitle(filter.key, "EN"))}, ${formattedValue}`);
        } else {
            // If key doesn't exist, set the value
            filterMap.set(this.criteriaResolverService.extractTitle(filter.key, "EN"), formattedValue);
        }
    });

    const formattedFilters = Array.from(filterMap, ([key, value]) => `${this.criteriaResolverService.extractTitle(key, "EN")} : ${value}`);

    worksheet.insertRow(this.currentRow, filteredBy);
    worksheet.getCell(`A${this.currentRow}`).font = { bold: true };
    worksheet.getCell(`B${this.currentRow}`).alignment = { wrapText: true }; // Enable text wrapping
    worksheet.getCell(`B${this.currentRow}`).value = formattedFilters.join('\n\n'); // Use double newline for separation

    this.currentRow += formattedFilters.length; // Increment currentRow by the number of lines added
}



    // Add Grouped By
     
    if (groupedChart) {
      const groupBy = ["Grouped By"]
      worksheet.insertRow(this.currentRow, groupBy);
      worksheet.getCell(`A${this.currentRow}`).font = { bold: true };
      worksheet.getCell(`B${this.currentRow}`).value = groupedBy

      this.currentRow += 2;
    }
    else {
      this.currentRow += 1;
    }

    // Add headers
    const headers = ["", "Total Responses", "Percentage"]
    worksheet.insertRow(this.currentRow, headers);

    worksheet.getColumn('B').alignment = { horizontal: 'center' };
    worksheet.getColumn('C').alignment = { horizontal: 'center' };

    this.currentRow += 2;

    // Set column widths
    worksheet.getColumn('A').width = 100
    worksheet.getColumn('B').width = 25
    worksheet.getColumn('C').width = 25
    worksheet.getColumn('D').width = 100


    data.forEach(r => {
      var row: any[] = [];
      if (r.group) {
         
        if (groupedChart) {
          var groups = r.exportData.filter(x => x.category == r.category);

          if (groups.length)
            row = [r.category];

          groups.sort((a, b) => a.value1 - b.value1);
        }
        else {
          row = [r.category + "," + r.group, r.count, r.percentage] //>>> for the matrix and ranking
        }
      }
      else {
        row = [r.category, r.count, r.percentage]
      }

      if (row)
        worksheet.insertRow(this.currentRow, row);
      worksheet.getCell(`A${this.currentRow}`).font = { bold: true };


      if (groupedChart) {
        groups.forEach(element => {
          this.currentRow += 1;

          row = ["    " + element.group, element.value1, (element.value.toFixed(1) + "%")]
          worksheet.insertRow(this.currentRow, row);
        });
      }
      this.currentRow += 1;
    })

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      if(fileName.length > 207)
        {
          fileName = fileName.substring(0, 150);
        }

      saveAs(blob, `${fileName}.xlsx`);
    });
  }
}
