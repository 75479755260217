import { Injectable } from "@angular/core";
import { CriteriaResolverService } from "../data-access/services/criteria-resolver.service";
import { SurveyXKeyResolverNames } from "./survey-xkey-resolver-names";

@Injectable({
    providedIn: 'root',
})
export class SurveyCriteriaKeyResolver {
    name: string = SurveyXKeyResolverNames.SurveyCriterias;

    /**
     *
     */
    constructor(private criteriaResolverService: CriteriaResolverService) {

    }

    resolve(key: string, language: string, options?: any): string {
        return this.criteriaResolverService.extract(options, key, language);
    }
}
