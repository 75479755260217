import { EnvironmentService } from '@abp/ng.core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


export interface ExitUnitDeductionDto {
  noOfRespondees: number,
  tenantKey: string,
  entityKey: string,
  joinerKey: string,
  userKey: string
}

export interface ExitBalanceCheckRequestDto {
  tenantKey: string,
  entityKey: string,
  creditType?: string,
}

export interface ExitBalanceCheckDto {

}

@Injectable({
  providedIn: null
})
export class ExitUnitService {

  private baseUrl: string;

  private DebitUnitEndpoint = "createNewLeaverV2";
  private CheckUnitBalanceEndpoint = "unitBalanceCheck"
  private CreditType = "EXIT"

  constructor(private httpClient: HttpClient, private enviromentService: EnvironmentService) {

    const env = this.enviromentService.getEnvironment();
    this.baseUrl = env['cloudfunctionsUrl'];

  }

  deduct(request: ExitUnitDeductionDto) {
    return this.httpClient.post(`${this.baseUrl}/${this.DebitUnitEndpoint}`, request);
  }

  checkBalance(request: ExitBalanceCheckRequestDto): Observable<ExitBalanceCheckDto> {
    request.creditType = this.CreditType;

    return this.httpClient.post(`${this.baseUrl}/${this.CheckUnitBalanceEndpoint}`, request);
  }
}
