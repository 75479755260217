<div class="relative" (click)="open = !open" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
    class="flex flex-col gap-2 w-full relative">
    <div (click)="logData(list)" class="flex flex-col gap-2 w-full relative"
        [ngClass]="{'pointer-events-none opacity-70': isDisabled,'h-0 absolute invisible':!triggeAarea}">
        <h5 *ngIf="label && !labelPosition" class="text-xs text-zen font-medium truncate">{{ label }} <i
                *ngIf="required || ngControl.control.required"
                class="fa-regular fa-asterisk text-3xs transform -translate-y-1"></i></h5>
        <div #trigger (click)="initDrop($event)"
            [ngClass]="ngControl.control.errors && (ngControl.touched || submitted)?'border-zen-red text-zen-red':'border-zen border-opacity-25 text-zen'"
            class="text-sm w-full rounded  border group min-w-full bg-white px-2 h-9 gap-3 leading-9 cursor-pointer flex flex-row justify-between items-center relative">
            <div class="flex flex-row justify-start items-center w-11/12 whitespace-nowrap truncate px-2">
                <span *ngIf="!selected && placeholder; else displayValue"
                    class="text-sm text-zen text-opacity-50">{{placeholder}}</span>
                <ng-template #displayValue>
                    <ng-container *ngIf="!multiple && triggeAarea">
                        <span *ngIf="!valueTemplate && selected" class="text-sm text-zen truncate">{{selected}}</span>
                        <ng-container *ngTemplateOutlet="valueTemplate; context:{$implicit: getValue(selected)}">
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="multiple && triggeAarea">
                        <ng-container *ngIf="!valueTemplate">
                            <span *ngFor="let item of selected" class="text-sm text-zen">{{item?.name}}</span>
                        </ng-container>
                        <ng-container *ngIf="valueTemplate">
                            <h5 class="truncate w-full">
                                <span *ngFor="let item of selected; let n = index">
                                    <span *ngIf="n != 0">, </span>
                                    <ng-container
                                        *ngTemplateOutlet="valueTemplate; context:{$implicit: getValue(item)}">
                                    </ng-container>
                                </span>
                            </h5>
                        </ng-container>

                    </ng-container>
                </ng-template>
            </div>

            <div class="flex flex-col z-10 w-1/12 items-end">
                <i class="fa-regular text-xxs fa-chevron-up"></i>
                <i class="fa-regular text-xxs fa-chevron-down"></i>
            </div>
            <div *ngIf="selected?.length && nullable"
                class="flex flex-row absolute -top-1  -left-1 -right-1 justify-end h-0">
                <i (click)="clear($event)"
                    class="fal fa-times text-xxs btn w-3 h-3 text-center rounded-full bg-zen-red bg-opacity-20 text-zen-red leading-3 opacity-0 group-hover:opacity-100"></i>
            </div>
        </div>
    </div>




    <!-- <div #outerBody *ngIf="open"
        class="flex z-max flex-col justify-center items-center select-none top-0 left-0 h-0 w-0 fixed bg-zen bg-opacity-5 main-overlay"
        [ngClass]="{ 'h-full w-full': open, 'bg-opacity-20':mobileView  }" (click)="close()">
        <div #body class="top-0 left-0" *DemoAccount="'Default'; let isDemo=isDemo"
            [ngClass]="{'h-full justify-center items-center':mobileView,'fixed': !mobileView}">
            <div class="flex flex-col relative" [ngClass]="{'h-full justify-center items-center':mobileView}">
                <div [@fadeUpDown] *ngIf="innerOpen" class="flex flex-row  pt-1 "
                    [ngClass]="{'justify-end':oppositeRight || oppositeLeft,'left-0' : rtl,'absolute w-0' :!mobileView,'bottom-0':reverse} "
                    [style.marginLeft.px]="oppositeRight?triggerWidth:oppositeLeft? -triggerWidth:''">
                    <div (click)="$event.stopPropagation()" [ngClass]="mobileView?'min-w-80':'min-w-max'"
                        class="flex flex-col bg-white  rounded z-50 p-1 gap-1 innerListScroll max-h-80">
                        <div *ngIf="list?.length > 5 && !isDemo"
                            class="sticky -top-1 px-2 bg-white z-10 pt-2 pb-1 -mt-1 ">
                            <div
                                class="flex flex-row h-8 justify-between items-center bg-zen bg-opacity-10 rounded w-full px-2">
                                <input autocomplete="off" id="inputSearch" [(ngModel)]="searchValue"
                                    (keyup)="searchList(searchValue,list)"
                                    [placeholder]="'txt_search'| translate:language:ModuleNames.Shared"
                                    class="w-full leading-8 bg-transparent text-zen" />
                                <i *ngIf="searchValue" (click)="searchValue = null; tempList = list"
                                    class="fal fa-times w-4 h-4 block rounded-full text-center leading-4 bg-zen bg-opacity-10 shadow-sm text-zen-red cursor-pointer text-xs"></i>
                            </div>
                        </div>
                        <ng-container *ngFor="let option of tempList; let i = index">
                            <div *DemoAccount="i + 1 > DemoLimit && DemoLimit?'Button':'Default'"
                                [ngClass]="selectionIncluded(option[key])|| option[key] == selected?'bg-opacity-5 hover:bg-opacity-20 bg-'+color+' text-'+color:'hover:bg-zen hover:bg-opacity-5'"
                                class="flex flex-row items-center gap-2 px-4 text-sm leading-8 min-w-input rounded btn text-zen   cursor-pointer"
                                (click)="isDemo && DemoLimit <= i  && DemoLimit?'': handleSelection(option[key])">
                                <i *ngIf="multiple" class="text-xl"
                                    [ngClass]="selectionIncluded(option[key])|| option[key] == selected ?'fa-solid fa-square-check text-'+color : 'fa-regular fa-square text-zen'"></i>
                                <h5 *ngIf="!valueTemplate && !optionTemplate"> {{option }} </h5>

                                <ng-container
                                    *ngTemplateOutlet="optionTemplate?optionTemplate:valueTemplate; context:{$implicit: option}">
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

    </div> -->


    <div *ngIf="invalid && ((!selected && !multiple) || (!selected.length && multiple))"
        class="alert alert-danger transform translate-y-1 h-0">
        <validation-required [language]="language"></validation-required>
    </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="open"
    [cdkConnectedOverlayHasBackdrop]="open" (backdropClick)="open = false">
    <div [@fadeUpDown]
        class="inline-flex flex-col items-stretch bg-white text-sm text-zen-primaryLight border border-dark border-opacity-10 rounded p-1 mt-2 min-w-32">
        <div *DemoAccount="'Default'; let isDemo=isDemo" (click)="$event.stopPropagation()"
            class="flex flex-col bg-white  rounded z-50 p-1 gap-1 innerListScroll max-h-40">
            <div *ngIf="list?.length > 5" class="sticky -top-1 px-2 bg-white z-10 pt-2 pb-1 -mt-1 ">
                <div class="flex flex-row h-8 justify-between items-center bg-zen bg-opacity-10 rounded w-full px-2">
                    <input autocomplete="off" id="inputSearch" [(ngModel)]="searchValue"
                        (keyup)="searchList(searchValue,list)"
                        [placeholder]="'txt_search'| translate:language:ModuleNames.Shared"
                        class="w-full leading-8 bg-transparent text-zen" />
                    <i *ngIf="searchValue" (click)="searchValue = null; tempList = list"
                        class="fal fa-times w-4 h-4 block rounded-full text-center leading-4 bg-zen bg-opacity-10 shadow-sm text-zen-red cursor-pointer text-xs"></i>
                </div>
            </div>
            <ng-container *ngFor="let option of tempList; let i = index">
                <div *DemoAccount="i + 1 > DemoLimit && DemoLimit?'Button':'Default'"
                    [ngClass]="selectionIncluded(option[key])|| option[key] == selected?'bg-opacity-5 hover:bg-opacity-20 bg-'+color+' text-'+color:'hover:bg-zen hover:bg-opacity-5'"
                    class="flex flex-row items-center gap-2 px-4 text-sm leading-8 min-w-input rounded btn text-zen   cursor-pointer"
                    (click)="isDemo && DemoLimit <= i  && DemoLimit?'': handleSelection(option[key])">
                    <i *ngIf="multiple" class="text-xl"
                        [ngClass]="selectionIncluded(option[key])|| option[key] == selected ?'fa-solid fa-square-check text-'+color : 'fa-regular fa-square text-zen'"></i>
                    <h5 *ngIf="!valueTemplate && !optionTemplate"> {{option }} </h5>

                    <ng-container
                        *ngTemplateOutlet="optionTemplate?optionTemplate:valueTemplate; context:{$implicit: option}">
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

<!-- <z-select [color]="'exp'" label="label" [(ngModel)]="model"
(selectionChange)="function($event)" [key]="'value defautl is key'" [options]="list"
[multiple]="true or false">
<ng-template #valueTemplate let-value> //optianl in case of complex structure
  {{ value is the the selected array child }}
</ng-template>

</z-select> -->