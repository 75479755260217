<canvas class="fixed top-0 left-0 w-screen h-screen overflow-hidden" id="c"></canvas>
<div class=" w-screen h-screen flex flex-col z-10 items-center px-5 relative">
  <div class="flex flex-row justify-end items-center py-5 w-full top-0 left-0 absolute px-5">
    <div [@child15] appClickOutside (clickOutside)="closeDropdown('showLangage')"
      [ngClass]="(language | language)?'rtl':'ltr'">
      <i (click)="showLangage = !showLangage"
        class="fa-light fa-language leading-9 w-9 h-9 text-center bg-zen bg-opacity-10 rounded text-zen btn"></i>
      <div class="flex left-0 flex-row justify-end absolute w-full h-0 px-5">
        <div [@fadeUpDown] *ngIf="showLangage"
          class="flex flex-col gap-1 top-22 px-2 py-2 rounded-lg shadow-lg bg-white mt-5 topArrow relative innerListScroll max-h-52 w-32" style="height: max-content;">
          <ng-container *ngFor="let lang of supportedLanguages">
            <a *ngIf="lang.key != language" (click)="selectedLanguageChange(lang.key); closeDropdown('showLangage')"
              class="text-zen text-sm btn rounded-sm cancel py-2 px-2">
              {{lang.name}}</a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center h-full w-full gap-10">
    <div class="flex flex-row justify-center">
      <img *ngIf="(!logoLoading && tenantKeyExists)" style="width: 300px; " [src]="tenantLogo" />
      <img *ngIf="(!tenantKeyExists)" style="width: 300px;" [src]="logoURL" />
    </div>
    <div
      class="flex flex-col items-center  rounded-xl p-5 max-w-lg w-full backdrop-filter backdrop-blur bg-white bg-opacity-30">
      <form  #form="ngForm" class="flex flex-col w-full items-center gap-6" *ngIf="section != 'sent'">
        <span *ngIf="section == 'login'">
          <!-- <h5 class="is-size-55">{{txtWelcomeAboard}}</h5> -->
          <h5 class="text-center text-zen opacity-80">{{'txtEnterInformation'|
            translate:language:ModuleNames.Shared}}</h5>
        </span>
        <div class="flex flex-row gap-3 w-full">

          <div class="flex flex-col w-full">
            <mat-form-field [dir]="(language | language)?'rtl':'ltr'" appearance="outline" class="w-full -mt-5">
              <mat-label class="text-zen">{{'txt_full_name'| translate:language:ModuleNames.Shared}}
              </mat-label>
              <input  name="name" [(ngModel)]="form.name" required matInput #name="ngModel">
            </mat-form-field>
            <div *ngIf="name.invalid && (name.dirty || name.touched || !valid)" class="alert alert-danger">
              <div class="text-xxs text-zen-red -mt-5" *ngIf="name.errors.required">
                {{'txt_required_field'| translate:language:ModuleNames.Shared}}
              </div>
            </div>
          </div>
        </div>
        <div class="is-relative" style="width: 100%;">
          <mat-form-field [dir]="(language | language)?'rtl':'ltr'" appearance="outline" class="w-full -mt-5">
            <mat-label class="text-zen">{{'txt_user_email'| translate:language:ModuleNames.Shared}}
            </mat-label>
            <input type="email" name="email" [pattern]="emailPattern" [(ngModel)]="form.email" matInput #email="ngModel">
          </mat-form-field>
          <div *ngIf="email.invalid && (email.dirty || email.touched || !valid)" class="alert alert-danger">
            <div class="text-xxs text-zen-red -mt-5" *ngIf="email.errors.pattern">
              {{'txtWrongPattern'| translate:language:ModuleNames.Shared}}
            </div>
            <div class="text-xxs text-zen-red -mt-5" *ngIf="email.errors.required">
              {{'txt_required_field'| translate:language:ModuleNames.Shared}}
            </div>
          </div>
        </div>
        <a class="btn  uppercase leading-15 px-5 rounded-lg  w-1/3 text-center exp"
          (click)="onSubmit(form)">{{'txtContinue'| translate:language:ModuleNames.Shared}}</a>
      </form>
      <!-- </div> -->
      <div>
      </div>
    </div>
  </div>
</div>
<loader [language]="language" *ngIf="displayLoader"></loader>
