import { LocalService } from "@ex/module/core/services/local.service";
import { updateQuestionHeaderHTML, questionsBuilderTranslateText } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";


/**
 * Updates the HTML for a Radio Group question.
 * @param options - The options for updating the Radio Group question HTML.
 */
export function updateRadioGroupQuestionHTML(options: any) {
  updateQuestionHeaderHTML(options, "Multiple Choices", "fa-list");
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // body.className = body.className + " mt-5";

  // Remove the old Radio Group question content
  body.innerHTML = "";

  // Create a new Radio Group question content
  var newRadiogroupQuestionContent = document.createElement("div");
  newRadiogroupQuestionContent.className = "flex flex-col gap-3 py-4 w-full rank-items-container";

  body.appendChild(newRadiogroupQuestionContent);

  // Add the choices
  addRadioGroupChoices(options);

  // Create the footer with buttons
  var footer = document.createElement("div");
  footer.className = "flex flex-row items-center justify-between gap-5 w-full mt-3";

  var footerLeft = document.createElement("div");
  footerLeft.className = "flex flex-row items-center justify-between gap-5";

  var addNewItemButton = document.createElement("button");
  addNewItemButton.className = "btn text-center flex flex-row items-center rounded gap-2 text-zen-primarylight text-sm font-medium w-full bg-zen-littlegray px-4 py-2 rounded";
  addNewItemButton.innerHTML = '<i class="far fa-plus"></i> ' + questionsBuilderTranslateText("Add Choice");
  addNewItemButton.onclick = function () {
    options.question.choices = options.question.choices.concat([questionsBuilderTranslateText("txtItem") + (options.question.choices.length + 1)]);
    addRadioGroupChoices(options);
  };

  // Create the "Bulk Answers" button
  var bulkAddButton = document.createElement("button");
  bulkAddButton.className = "btn text-center flex flex-row items-center rounded px-2 py-2 gap-3 bulk-answers-button";
  bulkAddButton.innerHTML = '<i class="fa-light fa-layer-plus"></i> ' + questionsBuilderTranslateText("Bulk Answers");

  footerLeft.appendChild(addNewItemButton);
  // footerLeft.appendChild(bulkAddButton);

  footer.appendChild(footerLeft);

  body.appendChild(footer);
}

/**
 * Updates the HTML for a Checkboxes question.
 * @param options - The options for updating the Checkboxes question HTML.
 */
export function updateCheckBoxsQuestionHTML(options: any) {
  updateQuestionHeaderHTML(options, "Multiple Choices", "fa-list");
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // body.className = body.className + " mt-5";

  // Remove the old Checkboxes question content
  body.innerHTML = "";

  // Create a new Checkboxes question content
  var newCheckBoxesQuestionContent = document.createElement("div");
  newCheckBoxesQuestionContent.className = "flex flex-col gap-3 py-4 w-full rank-items-container";

  body.appendChild(newCheckBoxesQuestionContent);

  // Add the choices
  addCheckBoxsChoices(options);

  // Create the footer with buttons
  var footer = document.createElement("div");
  footer.className = "flex flex-row items-center justify-between gap-5 w-full mt-3";

  var footerLeft = document.createElement("div");
  footerLeft.className = "flex flex-row items-center justify-between gap-5";

  var addNewItemButton = document.createElement("button");
  addNewItemButton.className = "btn text-center flex flex-row items-center rounded gap-2 text-zen-primarylight text-sm font-medium w-full bg-zen-littlegray px-4 py-2 rounded";
  addNewItemButton.innerHTML = '<i class="far fa-plus"></i> ' + questionsBuilderTranslateText("Add Choice");
  addNewItemButton.onclick = function () {
    options.question.choices = options.question.choices.concat([questionsBuilderTranslateText("txtItem") + (options.question.choices.length + 1)]);
    addCheckBoxsChoices(options);
  };

  // Create the "Bulk Answers" button
  var bulkAddButton = document.createElement("button");
  bulkAddButton.className = "btn text-center flex flex-row items-center rounded px-2 py-2 gap-3 bulk-answers-button";
  bulkAddButton.innerHTML = '<i class="fa-light fa-layer-plus"></i> ' + questionsBuilderTranslateText("Bulk Answers");

  footerLeft.appendChild(addNewItemButton);
  // footerLeft.appendChild(bulkAddButton);

  footer.appendChild(footerLeft);

  body.appendChild(footer);
}

/**
 * Adds the choices to a RadioGroup question.
 * @param options - The options for adding the choices to the RadioGroup question.
 */
function addRadioGroupChoices(options: any) {
  var newRankingQuestionContent = options.htmlElement.querySelector(".rank-items-container");
  newRankingQuestionContent.innerHTML = "";

  let nextIndex = options.question.choices.length;

  for (let i = 0; i < options.question.choices.length; i++) {
    var item = document.createElement("div");
    item.className = "flex flex-row items-center justify-between w-full pl-4 pr-2 py-1 ranking-item";

    var itemLeft = document.createElement("div");
    itemLeft.className = "flex items-center px-4 gap-2 w-full border border-solid border-zen-paleWhite hover:border-[#7D92B5] focus:border-[#7D92B5] rounded";

    var radioButton = document.createElement("input");
    radioButton.setAttribute("type", "radio");
    radioButton.className = "radiogroup-item-radio-button w-6 h-6";
    radioButton.style.marginInlineEnd = "1.25rem";
    radioButton.disabled = true;

    var itemIndex = document.createElement("p");
    itemIndex.className = "ranking-item-index";
    itemIndex.innerHTML = getcharacterByIndex(i);

    var itemText = document.createElement("input");
    itemText.className = "px-3 w-full block py-2";
    itemText.setAttribute("type", "text");
    itemText.setAttribute("placeholder", questionsBuilderTranslateText("Enter a choice here..."));
    itemText.setAttribute("value", options.question.choices[i].title);

    (function (index) {
      // Add keydown event listener to the input and disable all other event listeners
      itemText.addEventListener("keydown", function (e) {
        e.stopPropagation();

        // if (this.value.length > 50) {
        //   this.value = this.value.substring(0, 50);
        // }
      });

      itemText.addEventListener("change", function () {
        options.question.choices[index].title = this.value;
        options.question.choices[index].value = this.value;
        options.question.choices[index].text = this.value;
      });
    })(i);

    var deleteButton = document.createElement("button");
    deleteButton.className = "text-center flex flex-row items-center rounded p-3 mx-2 leading-9 text-xs font-medium gap-3 ranking-item-delete-button";
    deleteButton.innerHTML = '<i class="fa-light fa-trash"></i>';
    deleteButton.onclick = function () {
      options.question.choices.splice(i, 1);

      // Update the question choices
      addRadioGroupChoices(options);
    };

    itemLeft.appendChild(itemIndex);
    itemLeft.appendChild(itemText);

    item.appendChild(radioButton);
    item.appendChild(itemLeft);
    item.appendChild(deleteButton);

    newRankingQuestionContent.appendChild(item);
  }

  if (options.question.hasOther) {
    var item = document.createElement("div");
    item.className = "flex flex-row items-center justify-between w-full pl-4 pr-2 py-1 ranking-item";

    var itemLeft = document.createElement("div");
    itemLeft.className = "flex items-center px-4 gap-2 w-full border border-solid border-zen-paleWhite hover:border-[#7D92B5] focus:border-[#7D92B5] rounded";

    var radioButton = document.createElement("input");
    radioButton.setAttribute("type", "radio");
    radioButton.className = "radiogroup-item-radio-button w-6 h-6";
    radioButton.style.marginInlineEnd = "1.25rem";
    radioButton.disabled = true;

    var itemIndex = document.createElement("p");
    itemIndex.className = "ranking-item-index";
    itemIndex.innerHTML = getcharacterByIndex(nextIndex);
    nextIndex++;

    var itemText = document.createElement("input");
    itemText.className = "px-3 w-full block py-2";
    itemText.setAttribute("type", "text");
    itemText.disabled = true;
    itemText.setAttribute("value", options.question.otherText);

    var deleteButton = document.createElement("button");
    deleteButton.className = "text-center flex flex-row items-center rounded p-3 mx-2 leading-9 text-xs font-medium gap-3 ranking-item-delete-button";
    deleteButton.innerHTML = '<i class="fa-light fa-trash"></i>';
    deleteButton.onclick = function () {
      console.log("Delete other addRadioGroupChoices");
      options.question.hasOther = false;
      // Update the question choices
      addRadioGroupChoices(options);
    };

    itemLeft.appendChild(itemIndex);
    itemLeft.appendChild(itemText);

    item.appendChild(radioButton);
    item.appendChild(itemLeft);
    item.appendChild(deleteButton);

    newRankingQuestionContent.appendChild(item);
  }

  if (options.question.hasNone) {
    var item = document.createElement("div");
    item.className = "flex flex-row items-center justify-between w-full pl-4 pr-2 py-1 ranking-item";

    var itemLeft = document.createElement("div");
    itemLeft.className = "flex items-center px-4 gap-2 w-full border border-solid border-zen-paleWhite hover:border-[#7D92B5] focus:border-[#7D92B5] rounded";

    var radioButton = document.createElement("input");
    radioButton.setAttribute("type", "radio");
    radioButton.className = "radiogroup-item-radio-button w-6 h-6";
    radioButton.style.marginInlineEnd = "1.25rem";
    radioButton.disabled = true;

    var itemIndex = document.createElement("p");
    itemIndex.className = "ranking-item-index";
    itemIndex.innerHTML = getcharacterByIndex(nextIndex);

    var itemText = document.createElement("input");
    itemText.className = "px-3 w-full block py-2";
    itemText.setAttribute("type", "text");
    itemText.setAttribute("value", options.question.noneText);

    itemText.addEventListener("keydown", function (e) {
      e.stopPropagation();
    });

    itemText.addEventListener("change", function () {
      options.question.noneText = this.value;
    });

    var deleteButton = document.createElement("button");
    deleteButton.className = "text-center flex flex-row items-center rounded p-3 mx-2 leading-9 text-xs font-medium gap-3 ranking-item-delete-button";
    deleteButton.innerHTML = '<i class="fa-light fa-trash"></i>';
    deleteButton.onclick = function () {
      console.log("Delete hasNone addRadioGroupChoices");
      options.question.hasNone = false;
      // Update the question choices
      addRadioGroupChoices(options);
    };

    itemLeft.appendChild(itemIndex);
    itemLeft.appendChild(itemText);

    item.appendChild(radioButton);
    item.appendChild(itemLeft);
    item.appendChild(deleteButton);

    newRankingQuestionContent.appendChild(item);
  }
}

/**
 * Adds the choices to a CheckBoxs question.
 * @param options - The options for adding the choices to the CheckBoxs question.
 */
function addCheckBoxsChoices(options: any) {
  var newRankingQuestionContent = options.htmlElement.querySelector(".rank-items-container");
  newRankingQuestionContent.innerHTML = "";

  let nextIndex = options.question.choices.length;

  for (let i = 0; i < options.question.choices.length; i++) {
    var item = document.createElement("div");
    item.className = "flex flex-row items-center justify-between w-full pl-4 pr-2 py-1 ranking-item";

    var itemLeft = document.createElement("div");
    itemLeft.className = "flex items-center px-4 gap-2 w-full border border-solid border-zen-paleWhite hover:border-[#7D92B5] focus:border-[#7D92B5] rounded";

    var checkbox = document.createElement("input");
    checkbox.setAttribute("type", "checkbox");
    checkbox.className = "checkbox-item-checkbox w-6 h-6";
    checkbox.style.marginInlineEnd = "1.25rem";
    checkbox.disabled = true;

    var itemIndex = document.createElement("p");
    itemIndex.className = "ranking-item-index";
    itemIndex.innerHTML = getcharacterByIndex(i);

    var itemText = document.createElement("input");
    itemText.className = "px-3 w-full block py-2";
    itemText.setAttribute("type", "text");
    itemText.setAttribute("placeholder", questionsBuilderTranslateText("Enter a choice here..."));
    itemText.setAttribute("value", options.question.choices[i].title);

    (function (index) {
      itemText.addEventListener("keydown", function (e) {
        e.stopPropagation();

        // if (this.value.length > 50) {
        //   this.value = this.value.substring(0, 50);
        // }
      });

      itemText.addEventListener("change", function () {
        options.question.choices[index].title = this.value;
        options.question.choices[index].value = this.value;
        options.question.choices[index].text = this.value;
      });
    })(i);

    var deleteButton = document.createElement("button");
    deleteButton.className = "text-center flex flex-row items-center rounded p-3 mx-2 leading-9 text-xs font-medium gap-3 ranking-item-delete-button";
    deleteButton.innerHTML = '<i class="fa-light fa-trash"></i>';
    deleteButton.onclick = function () {
      options.question.choices.splice(i, 1);

      // Update the question choices
      addCheckBoxsChoices(options);
    };

    itemLeft.appendChild(itemIndex);
    itemLeft.appendChild(itemText);

    item.appendChild(checkbox);
    item.appendChild(itemLeft);
    item.appendChild(deleteButton);

    newRankingQuestionContent.appendChild(item);
  }

  if (options.question.hasOther) {
    var item = document.createElement("div");
    item.className = "flex flex-row items-center justify-between w-full pl-4 pr-2 py-1 ranking-item";

    var itemLeft = document.createElement("div");
    itemLeft.className = "flex items-center px-4 gap-2 w-full border border-solid border-zen-paleWhite hover:border-[#7D92B5] focus:border-[#7D92B5] rounded";

    var checkbox = document.createElement("input");
    checkbox.setAttribute("type", "checkbox");
    checkbox.className = "checkbox-item-checkbox w-6 h-6";
    checkbox.style.marginInlineEnd = "1.25rem";
    checkbox.disabled = true;

    var itemIndex = document.createElement("p");
    itemIndex.className = "ranking-item-index";
    itemIndex.innerHTML = getcharacterByIndex(nextIndex);
    nextIndex++;

    var itemText = document.createElement("input");
    itemText.className = "px-3 w-full block py-2";
    itemText.setAttribute("type", "text");
    itemText.disabled = true;
    itemText.setAttribute("value", options.question.otherText);

    var deleteButton = document.createElement("button");
    deleteButton.className = "text-center flex flex-row items-center rounded p-3 mx-2 leading-9 text-xs font-medium gap-3 ranking-item-delete-button";
    deleteButton.innerHTML = '<i class="fa-light fa-trash"></i>';
    deleteButton.onclick = function () {
      console.log("Delete hasOther addCheckBoxsChoices");
      options.question.hasOther = false;
      // Update the question choices
      addCheckBoxsChoices(options);
    };

    itemLeft.appendChild(itemIndex);
    itemLeft.appendChild(itemText);

    item.appendChild(checkbox);
    item.appendChild(itemLeft);
    item.appendChild(deleteButton);

    newRankingQuestionContent.appendChild(item);
  }

  if (options.question.hasNone) {
    var item = document.createElement("div");
    item.className = "flex flex-row items-center justify-between w-full pl-4 pr-2 py-1 ranking-item";

    var itemLeft = document.createElement("div");
    itemLeft.className = "flex items-center px-4 gap-2 w-full border border-solid border-zen-paleWhite hover:border-[#7D92B5] focus:border-[#7D92B5] rounded";

    var checkbox = document.createElement("input");
    checkbox.setAttribute("type", "checkbox");
    checkbox.className = "checkbox-item-checkbox w-6 h-6";
    checkbox.style.marginInlineEnd = "1.25rem";
    checkbox.disabled = true;

    var itemIndex = document.createElement("p");
    itemIndex.className = "ranking-item-index";
    itemIndex.innerHTML = getcharacterByIndex(nextIndex);
    nextIndex++;

    var itemText = document.createElement("input");
    itemText.className = "px-3 w-full block py-2";
    itemText.setAttribute("type", "text");
    itemText.setAttribute("value", options.question.noneText);

    itemText.addEventListener("keydown", function (e) {
      e.stopPropagation();
    });

    itemText.addEventListener("change", function () {
      options.question.noneText = this.value;
    });

    var deleteButton = document.createElement("button");
    deleteButton.className = "text-center flex flex-row items-center rounded p-3 mx-2 leading-9 text-xs font-medium gap-3 ranking-item-delete-button";
    deleteButton.innerHTML = '<i class="fa-light fa-trash"></i>';
    deleteButton.onclick = function () {
      console.log("Delete hasNone addCheckBoxsChoices");
      options.question.hasNone = false;
      // Update the question choices
      addCheckBoxsChoices(options);
    };

    itemLeft.appendChild(itemIndex);
    itemLeft.appendChild(itemText);

    item.appendChild(checkbox);
    item.appendChild(itemLeft);
    item.appendChild(deleteButton);

    newRankingQuestionContent.appendChild(item);
  }

  if (options.question.hasSelectAll) {
    var item = document.createElement("div");
    item.className = "flex flex-row items-center justify-between w-full pl-4 pr-2 py-1 ranking-item";

    var itemLeft = document.createElement("div");
    itemLeft.className = "flex items-center px-4 gap-2 w-full border border-solid border-zen-paleWhite hover:border-[#7D92B5] focus:border-[#7D92B5] rounded";

    var checkbox = document.createElement("input");
    checkbox.setAttribute("type", "checkbox");
    checkbox.className = "checkbox-item-checkbox w-6 h-6";
    checkbox.style.marginInlineEnd = "1.25rem";
    checkbox.disabled = true;

    var itemIndex = document.createElement("p");
    itemIndex.className = "ranking-item-index";
    itemIndex.innerHTML = getcharacterByIndex(nextIndex);

    var itemText = document.createElement("input");
    itemText.className = "px-3 w-full block py-2";
    itemText.setAttribute("type", "text");
    itemText.disabled = true;
    itemText.setAttribute("value", options.question.selectAllText);

    var deleteButton = document.createElement("button");
    deleteButton.className = "text-center flex flex-row items-center rounded p-3 mx-2 leading-9 text-xs font-medium gap-3 ranking-item-delete-button";
    deleteButton.innerHTML = '<i class="fa-light fa-trash"></i>';
    deleteButton.onclick = function () {
      console.log("Delete hasSelectAll addCheckBoxsChoices");
      options.question.hasSelectAll = false;
      // Update the question choices
      addCheckBoxsChoices(options);
    };

    itemLeft.appendChild(itemIndex);
    itemLeft.appendChild(itemText);

    item.appendChild(checkbox);
    item.appendChild(itemLeft);
    item.appendChild(deleteButton);

    newRankingQuestionContent.appendChild(item);
  }
}

function getcharacterByIndex(index) {

  let localLanguage = getUserPreferredLanguage()
  const base = 26;
  let result = '';

if(localLanguage == "AR")
{
    return getMainArabicCharacterByIndex(index);
}
else
{
  while (index >= 0) {
    result = String.fromCharCode((index % base) + 65) + result;
    index = Math.floor(index / base) - 1;
  }
  return result
}
}

function getUserPreferredLanguage(): string {
  const localService = new LocalService();
   return localService.getJsonValue('language');
}

function getMainArabicCharacterByIndex(index) {
  const base = 28;
  let result = '';

  const mainArabicChars = [
    'أ', 'ب', 'ت', 'ث', 'ج', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ك', 'ل', 'م', 'ن', 'ه', 'و', 'ي',];

  if (index < 0) {
    return ''; // Invalid index
  }

  while (index >= 0) {
    result = mainArabicChars[index % base] + result;
    index = Math.floor(index / base) - 1;
  }

  return result;
}

