export enum AnalysisFilterKeys {
    TimeFrame = "Timeframe",
    EntityId = "Entity",
    Phase = "Phase",
    Gender = "Gender",
    PerformanceRating = "PerformanceRate",
    Nationality = "Nationality",
    Generation = "Generation",
    AgeGroups = "AgeGroup",
    TenureGroups = "TenureGroup",
    BandLevel = "BandLevel",
    JobGrade = "JobGrade",
    Criteria = "criteria",
    IncludeExtraDrivers = "ExtraDriver",
    JobRole = "JobRole",
    SurveyStatus = "SurveyStatus"
}
