<div *ngIf="availableQuestions.length > 0 && model.selectedElement['dateType'] != 'range'">
<div class="flex flex-col gap-4 px-10">
    <z-toggle class="w-full rounded bg-opacity-10" [disable]="false"
        [label]="'txtShow Choice(s)'|translate:language:ModuleNames.SurveyX" [(ngModel)]="showChoicesLogic"
        (ngModelChange)="showChoicesLogicChange.emit($event);">
    </z-toggle>

    <div class="flex flex-col gap-4" *ngIf="showChoicesLogic">
        <div class="flex flex-col gap-4" *ngFor="let item of showChoicesLogicConditions;let choiceIndex = index">
            <div class="flex items-center h-10 gap-2" *ngIf="!item.isEditMode;else editMode">
                <div class="flex items-center overflow-hidden gap-2 w-full">
                    <span class="fa text-zen-primarylight font-bold text-base w-5 h-5">{{getCharacter(choiceIndex)}} </span>

                    <span
                        class="text-sm overflow-hidden whitespace-nowrap text-zen-primarylight mt-1 overflow-ellipsis">
                        {{item.choice.title}}
                    </span>
                </div>

                <button class="w-8 h-8 rounded text-zen-primarylight hover:bg-[#0082C61a] hover:text-exp"
                    (click)="startEditMode(choiceIndex)">
                    <i class="far fa-gear text-base"></i>
                </button>
            </div>

            <ng-template #editMode>
                <div class="border border-zen-paleWhite bg-[#FFFFFF80] rounded py-4 !pt-2 flex flex-col gap-4">
                    <div class="flex items-center gap-2 px-4">
                        <div class="flex items-center overflow-hidden gap-2 w-full">
                            <span class="fa text-exp font-bold text-base w-5 h-5">{{getCharacter(choiceIndex)}}</span>

                            <span
                                class="text-sm overflow-hidden whitespace-nowrap text-zen-primarydark mt-1 overflow-ellipsis">
                                {{item.choice.title}}
                            </span>
                        </div>

                        <button
                            class="w-8 h-8 rounded bg-[#ff45001a] text-zen-redSelect hover:bg-zen-redSelect hover:text-white"
                            (click)="stopEditMode(choiceIndex)">
                            <i class="fas fa-xmark text-base"></i>
                        </button>
                    </div>

                    <div class="flex flex-col gap-4">
                        <div class="flex flex-col gap-4"
                            *ngFor="let condition of item.conditions;let conditionIndex = index">
                            <div class="flex gap-3 items-center px-4">
                                <ng-container [ngSwitch]="condition.type">
                                    <label class="text-zen-primarydark text-sm font-medium w-8 h-6 text-center"
                                        *ngSwitchCase="'Main'">{{"txtIF"| translate:language:ModuleNames.SurveyX}}
                                    </label>

                                    <label class="text-zen-primarydark text-sm font-medium w-8 h-6 text-center"
                                        *ngSwitchCase="'And'">{{"txtAND"| translate:language:ModuleNames.SurveyX}}
                                    </label>

                                    <label class="text-zen-primarydark text-sm font-medium w-8 h-6 text-center"
                                        *ngSwitchCase="'Or'">{{"txtOR"| translate:language:ModuleNames.SurveyX}}
                                    </label>
                                </ng-container>

                                <z-select [color]="'exp'" class="w-full" [(ngModel)]="condition.question"
                                    [options]="availableQuestions" [placeholder]="'Question' | translate:language:ModuleNames.SurveyX" [language]="language"
                                    (selectionChange)="selectQuestion($event,choiceIndex, conditionIndex)">
                                    <ng-template #valueTemplate let-value>
                                        {{value?.value.title }}
                                    </ng-template>

                                    <ng-template #optionTemplate let-option>
                                        {{option?.value.title }}
                                    </ng-template>
                                </z-select>
                                <!-- width: 1rem; -->

                                <div *ngIf="condition.type != 'Main'">
                                    <button
                                        class="w-8 h-8 rounded bg-[#ff45001a] text-zen-redSelect hover:bg-zen-redSelect hover:text-white"
                                        (click)="removeCondition(choiceIndex,conditionIndex)">
                                        <i class="fas fa-xmark text-base"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="flex flex-col gap-4" *ngIf="condition.question != null">
                                <div class="flex gap-3 px-4">
                                    <z-select [color]="'exp'" class="placeholder:fixed w-full ms-10"
                                        [(ngModel)]="condition.operation" [options]="operations" [language]="language"
                                        (selectionChange)="selectOperation($event,choiceIndex, conditionIndex, condition)">
                                        <ng-template #valueTemplate let-value>
                                            {{value?.value | translate:language:ModuleNames.SurveyX}}
                                        </ng-template>

                                        <ng-template #optionTemplate let-option>
                                            {{option?.value | translate:language:ModuleNames.SurveyX}}
                                        </ng-template>
                                    </z-select>
                                </div>

                                <div class="flex gap-3 px-4" *ngIf="showAnswerBox(choiceIndex, conditionIndex)">
                                    <input type="text" class="form-input block w-full p-2.5 ms-10"
                                        *ngIf="isTextAnswer(condition.question);else choiceAnswer"
                                        [(ngModel)]="condition.answer"
                                        (ngModelChange)="selectAnswer($event,choiceIndex, conditionIndex,true)">

                                    <ng-template #choiceAnswer>
                                        <z-select [color]="'exp'" class="placeholder:fixed w-full ms-10"
                                            [(ngModel)]="condition.answer" [options]="condition.options"
                                            [language]="language"
                                            (selectionChange)="selectAnswer($event,choiceIndex, conditionIndex,false)">
                                            <ng-template #valueTemplate let-value>
                                                {{value?.value }}
                                            </ng-template>

                                            <ng-template #optionTemplate let-option>
                                                {{option?.value }}
                                            </ng-template>
                                        </z-select>
                                    </ng-template>
                                </div>
                            </div>
                        </div>

                        <!-- AND/OR -->
                        <div class="flex justify-items-start gap-4">
                            <button class="rounded-md px-1 text-exp text-sm" (click)="addAndCondition(choiceIndex)">
                                <i class="fas fa-plus text-sm text-exp w-6 h-6"></i>
                                {{"txtAND"| translate:language:ModuleNames.SurveyX}}
                            </button>

                            <button class="rounded-md px-1 text-exp text-sm" (click)="addOrCondition(choiceIndex)">
                                <i class="fas fa-plus text-sm text-exp w-6 h-6"></i>
                                {{"txtOR"| translate:language:ModuleNames.SurveyX}}
                            </button>
                        </div>
                        <!-- AND/OR -->
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
</div>
