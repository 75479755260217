import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTopics'
})
export class FilterTopicsPipe implements PipeTransform {

  transform(items: any[], searchText: string, language: string): any[] {
    if (!items || !searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      const optionName = item.name.find(n => n.key === language).name.toLowerCase();
      return optionName.includes(searchText);
    });
  }

}
