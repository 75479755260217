import { Component, OnInit } from '@angular/core';
import { AnalysisService, ChartResponseDto, LeaversAnalysisService } from '@ex/exit/analysis';
import { AnalysisFilterKeys, AnalyticsService } from '@ex/module/exit/data-access/services/analytics.service';
import { ExitBaseComponent } from '@ex/module/exit/ui/exit-base/exit-base.component';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Entity } from 'src/app/core/data-models/Entity';
import { CHART_DATA_RESPONSE_STRATEGY, CommonChartFunctions } from 'src/app/core/services/chart-data.service';
import { GlobalStatusService, Status } from 'src/app/core/services/global-status.service';
import { KeyResolverService } from 'src/app/core/services/key-resolver.service';
import { ChartOptionsService } from 'src/app/shared/chart/chart-options.service';

enum ChartGroup {
  Gender = 'Gender',
  Nationality = 'Nationality',
  Generation = 'Generation',
  AgeGroup = 'AgeGroup',
  TenureGroup = 'TenureGroup',
  Performance = 'PerformanceRate',
  BandLevel = 'BandLevel',
  JobGrade = 'JobGrade',
  SurveyStatus = 'SurveyStatus',
  SeperationType = "SeperationType"
}

@Component({
  selector: 'app-leaver-analysis',
  templateUrl: './leaver-analysis.component.html',
  styleUrls: ['./leaver-analysis.component.scss']
})
export class LeaverAnalysisComponent extends ExitBaseComponent implements OnInit {

  tenantKey: string
  selectedEntity: Entity
  language;
  charts: {
    title: string,
    Title: string,
    DashletType: string,
    type: string,
    group: string,
    data$?: Observable<any>,
    data?: any,
    halfWidth?: boolean,
    DashletChartTypes?: any[]
  }[] = [

    ];

  entityChartColors: { key: any; color: any; }[] = [];

  constructor(private analyticsService: AnalyticsService
    , private keyResolverService: KeyResolverService
    , private leaversAnalysisService: LeaversAnalysisService
    , private globalStatusService: GlobalStatusService
    , private commonChartFunctions: CommonChartFunctions
    , private analysisService: AnalysisService, private chartOptionsService: ChartOptionsService) {
    super()
  }

  ngOnInit(): void {

    this.tenantKey = this.AnalysisComponentData.tenantKey
    this.language = this.AnalysisComponentData.language;
    this.selectedEntity = this.AnalysisComponentData.selectedEntity;

    this.prepareChartConfig();

    this.loadChartData();

    const subscription = this.analyticsService.filters$
      .pipe(
        filter(filters => filters != null),
      )
      .subscribe(filters => {
        this.filtersLoaded.next(filters);
      })

    this.addSubscription(subscription);



    this.entityChartColors = [
      { key: this.localize(`txt_${Status.Posted}`, this.language, this.ModuleNames.Shared), color: this.globalStatusService.returnHexColor(Status.Posted) },
      { key: this.localize(`txt_${Status.Pending}`, this.language, this.ModuleNames.Shared), color: this.globalStatusService.returnHexColor(Status.Pending) },
      { key: this.localize(`txt_${Status.Progress}`, this.language, this.ModuleNames.Shared), color: this.globalStatusService.returnHexColor(Status.Progress) },
      { key: this.localize(`txt_${Status.Expired}`, this.language, this.ModuleNames.Shared), color: this.globalStatusService.returnHexColor(Status.Expired) },
      { key: this.localize(`txt_${Status.Inactive}`, this.language, this.ModuleNames.Shared), color: this.globalStatusService.returnHexColor(Status.Inactive) },
      { key: this.localize(`txt_${Status.Completed}`, this.language, this.ModuleNames.Shared), color: this.globalStatusService.returnHexColor(Status.Completed) },
    ]
  }

  loadChartData() {

    this.LoaderService.displayLoader();

    var subscription = this.filtersLoaded$.pipe(
      switchMap(filters => {

        this.charts.forEach(chart => {

          switch (chart.group) {

            case 'overall':
              chart.data$ = this.analysisService.getOverallAnalysis({
                companyKey: filters.companyKey,
                tenantKey: filters.tenantKey,
                surveyKey: filters.survey.key,
                filters: [...this.analyticsService.mapGlobalFilters(filters)],
                type: chart.type,
              })
              break;
            case 'SurveyStatus':
              chart.data$ = this.leaversAnalysisService.getStatusCounts({
                companyKey: filters.companyKey,
                tenantKey: filters.tenantKey,
                surveyKey: filters.survey.key,
                filters: [...this.analyticsService.mapGlobalFilters(filters)],
                type: chart.type,
              })

              break;
            default:
              chart.data$ = this.leaversAnalysisService.getByGroup(chart.group, {
                companyKey: filters.companyKey,
                tenantKey: filters.tenantKey,
                surveyKey: filters.survey.key,
                filters: [...this.analyticsService.mapGlobalFilters(filters)],
                type: chart.type,
              })
          }



        })

        return combineLatest([... this.charts.map(x => x.data$)]).pipe(
          map(charts => ({ charts, filters }))
        )
      })
    ).subscribe(({ charts, filters }) => {

      this.charts.forEach((chart, i) => {
        if (chart.group == "overall") {
          chart.data = charts[i]
          return;
        }

        this.processChartData(chart, charts[i]);
        chart.DashletChartTypes = this.chartOptionsService.getSupportedChartTypes(chart, chart.data.categories.length, chart.data.groupedChart)
      })

      this.LoaderService.hideLoader();

    })

    this.addSubscription(subscription);

  }

  processChartData(chart: { type: string; group: string; data$?: Observable<any>; data?: any; }, data: ChartResponseDto) {

    switch (chart.type) {

      case "ec":

        const extraCriteriaResolver = this.keyResolverService.getResolver("EC");
        this.keyResolverService.setKeyResolver(extraCriteriaResolver);

        data.data = data.data.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language, chart.group)
          return x;
        })

        data.drillDown = data.drillDown.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language, chart.group)
          return x;
        })


        break;
      case "entity":

        const entityResolver = this.keyResolverService.getResolver("Entity");
        this.keyResolverService.setKeyResolver(entityResolver);

        data.data = data.data.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language)
          return x;
        })

        data.drillDown = data.drillDown.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language)
          return x;
        })


        break;
      default:
        const resolver = this.keyResolverService.getResolver(chart.group);
        this.keyResolverService.setKeyResolver(resolver);

        let module = this.ModuleNames.Exit;

        if (chart.group == "SurveyStatus") {
          module = this.ModuleNames.Shared;
          data.data = this.commonChartFunctions.reArrangeArray(data.data, [Status.Completed, Status.Progress, Status.Pending, Status.Expired, Status.Inactive], 'category');
        }

        data.data = data.data.map((x: any) => {
          x.key = x.category;
          x.category = this.keyResolverService.resolve(x.category, this.language, module)
          return x;
        })

        data.drillDown = data.drillDown.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language, module)
          return x;
        })


    }



    chart.data = CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data.data).process();
    chart.data.drillDown = data.drillDown.map(x => ({ "ID": x.id, "Name": x.name, "Category": x.category, "Group": x.group, }));;
  }


  private prepareChartConfig() {

    this.charts = [...this.charts,
    {
      title: "",
      Title: `${this.localize("txtOverallResponseRate", this.language, this.ModuleNames.Exit)}`,
      DashletType: "barV",
      group: "overall",
      type: "",
      halfWidth: true
    },
    {
      title: "",
      Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.localize("txt_status", this.language, this.ModuleNames.Exit)}`,
      DashletType: "pie",
      group: "SurveyStatus",
      type: "",
      halfWidth: true
    },
    {
      title: "",
      Title: `${this.localize("txt_resp_by_age_group", this.language, this.ModuleNames.Exit)}`,
      DashletType: "barV",
      group: AnalysisFilterKeys.AgeGroups,
      type: "",
      halfWidth: false
    },
    {
      title: "",
      Title: `${this.localize("txt_resp_by_tenure_group", this.language, this.ModuleNames.Exit)}`,
      DashletType: "barV",
      group: AnalysisFilterKeys.TenureGroups,
      type: "",
      halfWidth: false
    },
    {
      title: "",
      Title: `${this.localize("txt_resp_by_generation", this.language, this.ModuleNames.Exit)}`,
      DashletType: "barV",
      group: AnalysisFilterKeys.Generation,
      type: "",
      halfWidth: false
    },
    {
      title: "",
      Title: `${this.localize("txtRespbygender", this.language, this.ModuleNames.Exit)}`,
      DashletType: "barV",
      group: AnalysisFilterKeys.Gender,
      type: "",
      halfWidth: false
    },


    {
      title: "",
      Title: `${this.localize("txtRespbynationality", this.language, this.ModuleNames.Exit)}`,
      DashletType: "barV",
      group: AnalysisFilterKeys.Nationality,
      type: "",
      halfWidth: false
    },
    {
      title: "",
      Title: `${this.localize("txtRespBySepType", this.language, this.ModuleNames.Exit)}`,
      DashletType: "barV",
      group: AnalysisFilterKeys.SeperationType,
      type: "",
      halfWidth: false
    }
    ]

    if (this.selectedEntity.JobGrades?.length > 0)
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.localize("txt_leaver_job_grade", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.JobGrade,
        type: "",
        halfWidth: false
      });
    if (this.selectedEntity.BandLevels?.length > 0)
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.localize("txt_leaver_job_level", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.BandLevel,
        type: "",
        halfWidth: false
      });
    if (this.selectedEntity.PerformanceRates?.length > 0)
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.localize("txt_leaver_performance_rate", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.PerformanceRating,
        type: "",
        halfWidth: false
      });
    // Get all the Entity Groups
    this.selectedEntity.EntityGroups.filter(x => !x.isCompanyGroup).forEach(entityGroup => {
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.ExtractPipe.transform(entityGroup.name, this.language)}`,
        DashletType: "barV",
        type: "entity",
        group: entityGroup.groupKey,
        halfWidth: false
      });
    });


    // Get all the Metrics
    this.selectedEntity.metrics?.forEach(metric => {
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.ExtractPipe.transform(metric.name, this.language)}`,
        DashletType: "barV",
        type: "ec",
        group: metric.key,
        halfWidth: false
      });
    });
  }

}
