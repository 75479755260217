export enum QuestionTypes {
  SingleAnswerConfidence = "single-answer-confidence",
  SingleAnswerConfidenceWithNA = "single-answer-confidence-with-na",
  MultiOptionMultiAnswer = "multi-options-multi-answer",
  MultiOptionSingleAnswer = "multi-options-single-answer",
  RatingLiked = "rating-liked",
  SingleAnswerOutOf10 = "single-answer-out-10",
  SingleAnswerRating = "single-answer-rating",
  SubQuestionsConfidenceRating = "sub-questions-confidence-rating",
  SubQuestionsLikenessRating = "sub-questions-likeness-rating",
  SubQuestionsNaLikenessRating = "sub-questions-likeness-na-rating",
  SubQuestionsBinaryLikenessRating = "sub-questions-binary-likeness-rating",
  SingleAnswerConfidenceNoNeutral = "single-answer-confidence-no-neutral",
  OpenText = "text",
}
