import { Component, Input, OnInit } from '@angular/core';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { GeneralService } from '../../../../core/services/general.service';
import { GlobalsService } from '../../../../core/services/globals.service';
import { LanguageService } from '../../../../core/services/language.service';

@Component({
  selector: 'text-question',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {
  lgCount;
  @Input() question;
  @Input() comment = false;
  @Input() language;
  @Input() single = false;
  constructor( private _globals: GlobalsService,private languageService: LanguageService,  private generalService: GeneralService,) { 
    this.lgCount = _globals.xlCount;
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  ngOnInit(): void {
  }
  countWord(word, count) {
    if (word?.length <= count)
      return word.length;
    if (word?.length > count) {

      return word.length;
    }
  }
  ModuleNames=ModuleName
  getConfidence(subquestionKey: string, key: string) {
    if (subquestionKey == 'sub-questions-confidence-rating')
      return this.generalService.confidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-likeness-rating')
      return this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-likeness-na-rating')
      return this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-binary-likeness-rating')
      return this.generalService.binaryLikeness.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'single-answer-rating')
      return this.generalService.singleAnswerConfidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'single-answer-out-10')
      return this.generalService.singleAnswerOutof10.find(cf => { return (cf.key == key) }).name;
  }

}
