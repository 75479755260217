import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general.service';
import { HelpService } from 'src/app/core/services/help.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { ModuleName } from '../../enums/ModuleName';
import  { ZenSnackbarService, ZenSnackBarTypes } from '../../Theme/services/zen-snackbar.service';
import { Ticket } from '../models/ticket';
import { ticketLabels } from '../models/ticket-labels.enum';

@Component({
  selector: 'app-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss']
})
export class TicketDialogComponent extends ModalComponent implements OnInit {
  @Input() open: boolean = true;
  @Output() openChange = new EventEmitter();
  @Input() type;
  @Input() set essentialObject(essentialObject: any) {
    this._essentialObject = essentialObject;
    this.language = essentialObject.language;
    this.ticket.User.Id = essentialObject.user.uid;
    this.ticket.User.Name = essentialObject.user.firstName + " " + essentialObject.user.lastName;
    this.ticket.User.EmailAddress.push(essentialObject.user.email);
    this.ticket.User.CompanyName = this.extractNameLanguage(essentialObject.selectedEntity.name);
    this.ticket.User.TenantName = essentialObject.tenant.name;
  }
  _essentialObject;
  language;
  ModuleNames = ModuleName;
  activatedRoute: string = '';
  categories: string[] = [];
  valid: boolean = true;
  invalid: boolean = false;
  submitted: boolean = false;
  ticketForm = {
    product: { list: [], invalid: false },
    category: { list: [], invalid: false, visible: true },
    description: { invalid: false },
    file: { selectedFiles: [], uploadLink: null, maxSize: 5242880, src: null },
    mobile: { list: [], code: '', number: '' }
  };
  ticket: Ticket = {
    User: {
      Id: '',
      Name: '',
      EmailAddress: [],
      MobileNumber: '',
      CompanyName: '',
      TenantName: ''
    },
    ticketLabel: '',
    name: '',
    desc: '',
    callForUpdates: false,
    pos: 'top',
    product: '',
    due: new Date(),
    idMembers: [""],
    idLabels: [""],
    urlSource: ''
  };
  constructor(private zenSnackBarService: ZenSnackbarService, private generalService: GeneralService, private route: ActivatedRoute,
    private languageService: LanguageService, private helpService: HelpService) {
    super();
  }

  ngOnInit(): void {
    this.openModal('open');
    this.ticketForm.product.list = this.generalService.products;
    this.ticketForm.mobile.list = this.generalService.countries_new;
    this.ticketForm.mobile.code = this.ticketForm.mobile.list.find(k => k.countryCode == 1).countryCode;
    this.activatedRoute = this.route['_routerState'].snapshot.url;
    this.ticket.product = this.ticketForm.product.list.find(f => this.activatedRoute.includes(f.key))?.key;
    this.getCategories();
    this.ticketForm.file.uploadLink = {
      uploadType: 'help-center',
      file: null,
      tenatKey: this._essentialObject.tenant.key
    }
  }
  getCategories() {
    switch (this.type.value) {
      case ticketLabels.TechnicalIssue:
        this.ticketForm.category.list = this.generalService.TechnicalIssueCategories;
        break;
      case ticketLabels.HRAdvisory:
        this.ticketForm.category.list = this.generalService.HRAdvisoryCategories;
        break;
    }
  }
  selectFile(event: any) {
    this.ticketForm.file.selectedFiles = event.target.files;
    this.ticketForm.file.uploadLink.file = this.ticketForm.file.selectedFiles[0];
    if (this.ticketForm.file.selectedFiles[0].size > this.ticketForm.file.maxSize) {
      this.ticketForm.file.selectedFiles = null;
    };
    if (this.ticketForm.file.selectedFiles.length > 0) {
      var reader = new FileReader();
      var self = this;
      reader.onload = function (e) {
        self.ticketForm.file.src = e.target.result
      };
      reader.readAsDataURL(this.ticketForm.file.selectedFiles[0]);
    }
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  changeProduct(event) {
    if (event == 'others')
      this.ticketForm.category.visible = false;
    else
      this.ticketForm.category.visible = true;
  }
  validate(elements) {
    this.valid = true;
    elements.forEach(element => {
      if (!this.ticket[element]) {
        this.valid = false;
      }
    });
    return this.valid && !this.invalid;
  }
  submit() {
    this.submitted = true;
    this.ticket.ticketLabel = this.type.value;
    let validationsArr = ['product', 'name', 'desc']
    let valid = this.validate(validationsArr);
    if (this.ticket.callForUpdates) {
      this.ticket.User.MobileNumber = this.ticketForm.mobile.code + this.ticketForm.mobile.number;
      if (!this.ticket.User.MobileNumber)
        valid = false;
    }
    if (valid) {
      this.helpService.createTicket(this.ticket).subscribe((res) => {
        this.openChange.emit(false)
        this.submitted = false;
        this.zenSnackBarService.snackBar({message:'Ticket submitted successfully', type: ZenSnackBarTypes.Success});

      },
        (err) => {
          console.log('Ticket Created error')
        });
    }
  }
}
