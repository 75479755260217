<zen-main-container>
  <sidebar-container>
    <back-button [routerLink]="'/wellbeing/home'" (click)="updateSurvey(surveyData, tab)">
      <i [ngClass]="(language | language) ? 'fa-arrow-right' : 'fa-arrow-left'" class="fa-regular"></i>
      <span>{{
        "txt_BACKTOSUR_422" | translate : language : ModuleNames.Wellbeing
        }}</span>
    </back-button>
    <section-sidebar [title]="
        'txt_survey_setup' | translate : language : ModuleNames.Wellbeing
      ">
      <menu-button *ngFor="let button of menu; let i = index" (click)="updateSurvey(surveyData, button.index)" [TourStep]="{
          title: button.name | translate:language:ModuleNames.Wellbeing,
          text: button.name + '_desc'| translate : language : ModuleNames.Wellbeing,
          order: 1,
          tourKey: 'wellbeing-setup-' + i,
          on: (language | language) ? 'right' : 'left'
        }" [icon]="
          tab == button.index
            ? 'text-exp ' + button.icon
            : 'opacity-50 ' + button.icon
        " [active]="tab == button.index">
        {{ button.name | translate : language : ModuleNames.Wellbeing }}
      </menu-button>
    </section-sidebar>
  </sidebar-container>
  <content-container class="flex flex-col items-stretch w-full">
    <div class="flex flex-col items-stretch h-28 z-10" sticky [top]="topValue">
      <section-head>
        <ng-container left>
          <h5 class="text-zen font-semibold text-2xl">
            {{
            "txt_survey_setup" | translate : language : ModuleNames.Wellbeing
            }}
          </h5>
        </ng-container>
        <ng-container right>
          <a [@child4] [TourStep]="{
            title:
              'btn_preview_survey'
              | translate : language : ModuleNames.Wellbeing,
            text:
              'txt_Clickonb_749'
              | translate : language : ModuleNames.Wellbeing,
            order: 6,
            tourKey: 'wellbeing-setup-0',
            on: (language | language) ? 'right' : 'left'
          }"  (click)="openPreviewSurvey()" zenButton styles="secondary height-11">{{'btn_preview_survey'|
            translate:language:ModuleNames.Wellbeing }}</a>
          <span class="border-r border-zen border-opacity-10 h-10"></span>
          <a *ngIf="tab != 0" (click)="updateSurvey(surveyData, tab - 1)" matTooltip="{{
              'btn_previous' | translate : language : ModuleNames.Wellbeing
            }}" [@child5] zenButton styles="secondary icon-11">
            <i [ngClass]="
                (language | language) ? 'fa-angle-right' : 'fa-angle-left'
              " class="fa-regular"></i>
          </a>
          <a *ngIf="tab != 4" (click)="updateSurvey(surveyData, tab + 1)" [TourStep]="{
              title:
                'txt_Nextstep_435'
                | translate : language : ModuleNames.Wellbeing,
              text:
                'txt_step_' + (tab + 1)
                | translate : language : ModuleNames.Wellbeing,
              order: 10,
              tourKey: 'wellbeing-setup-' + tab,
              on: (language | language) ? 'right' : 'left'
            }" matTooltip="{{
              'btn_next' | translate : language : ModuleNames.Wellbeing
            }}" [@child5] zenButton styles="exp icon-11">
            <i [ngClass]="
                (language | language) ? 'fa-angle-left' : 'fa-angle-right'
              " class="fa-regular"></i>
          </a>
          <a *ngIf="tab == 4" (click)="sendInvitations()" [@child5] zenButton styles="height-11" class="bg-zen-green px-5 text-white" [TourStep]="{
              title:
                'txt_send_invitations'
                | translate : language : ModuleNames.Wellbeing,
              text:
                'txt_Onceyouse_314'
                | translate : language : ModuleNames.Wellbeing,
              order: 3,
              tourKey: 'wellbeing-setup-4',on: (language | language) ? 'right' : 'left'
            }">
            {{
            "txt_send_invitations"
            | translate : language : ModuleNames.Wellbeing
            }}
          </a>
        </ng-container>
      </section-head>
    </div>
    <div class="flex flex-col items-stretch p-10 overflow-x-hidden">
      <div class="flex flex-col gap-10" [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 0">
        <wb-survey-info [submitted]="submitted" [survey]="surveyData" [language]="language" [selectedEntity]="selectedEntity"></wb-survey-info>
      </div>

      <div [@slidesAniamtion]="resolveRoute(tab)" class="flex flex-col" *ngIf="tab == 1">
        <wb-criteria #criteria *ngIf="tab == 1" [language]="language" [selectedEntity]="selectedEntity" [(surveyData)]="surveyData">
        </wb-criteria>
      </div>
      <div [@slidesAniamtion]="resolveRoute(tab)" class="flex flex-col items-stretch" *ngIf="tab == 2">
        <div class="flex flex-row items-center justify-between">
          <h5 [@first] class="text-xl font-medium text-zen">{{'txt_respondents_list' |
            translate:language:ModuleNames.Wellbeing}}</h5>
          <a [TourStep]="{
            title:
              'btn_add_respondent'
              | translate : language : ModuleNames.Wellbeing,
            text:
              'txt_Tostartad_220'
              | translate : language : ModuleNames.Wellbeing,
            order: 2,
            tourKey: 'wellbeing-setup-2',on:(language|language)?'right':'left'
          }" [@child2] *DemoAccount="totalRecords < 10?'Default':'Button'; let isDemo = isDemo" (click)="isDemo && totalRecords >= 10 ?'':respondentAddFunction()" zenButton styles="secondary height-11 text-sm">
            {{'btn_add_respondent' | translate:language:ModuleNames.Wellbeing }}</a>
        </div>

        <div class="flex flex-col">
          <wb-respondent-pool [resetSelection]="resetSelection" [language]="language" [currentPage]="currentPage" (searchTextChange)="searchTextUpdate($event)" (pageChanged)="onPageChange($event)" [pageSize]="pageSize" [totalRecords]="totalRecords" [respondents]="respondents" [options]="options" (singleDelete)="deleteRespondent($event)" (bulkDelete)="deleteRespondents($event)" (allSelected)="selectedAll($event)"></wb-respondent-pool>
        </div>
      </div>
      <div [@slidesAniamtion]="resolveRoute(tab)" class="flex flex-col gap-10" *ngIf="tab == 3">
        <div class="flex flex-row items-center justify-between">
          <h5 [@first] class="text-xl font-medium text-zen">
            {{
            "txt_set_timeframe" | translate : language : ModuleNames.Wellbeing
            }}
          </h5>
        </div>

        <div class="flex flex-row gap-5">
          <div class="flex flex-col w-1/2 items-stretch">
            <z-datepicker [color]="'exp'" [label]="
                'txt_start_date' | translate : language : ModuleNames.Wellbeing
              " [language]="language" [min]="today" [max]="surveyData.endDate" [(ngModel)]="surveyData.startDate" [disabled]="true">
            </z-datepicker>
          </div>
          <div class="flex flex-col w-1/2 items-stretch" [TourStep]="{
              title:
                'txt_SurveyDea_220'
                | translate : language : ModuleNames.Wellbeing,
              text:
                'txt_Setthecom_342'
                | translate : language : ModuleNames.Wellbeing,
              order: 2,
              tourKey: 'wellbeing-setup-3'
            }">
            <z-datepicker (ngModelChange)="addUnixDate('unixEnd', $event)" [color]="'exp'" [label]="
                'txt_completion_date'
                  | translate : language : ModuleNames.Wellbeing
              " [language]="language" [min]="surveyData.startDate" [(ngModel)]="surveyData.endDate">
            </z-datepicker>
          </div>
        </div>

        <div class="flex flex-row bg-zen bg-opacity-5 rounded-b-md p-5 gap-5 items-start" [TourStep]="{
            title:
              'txt_Automated_437'
              | translate : language : ModuleNames.Wellbeing,
            text:
              'txt_Addupto3a_148'
              | translate : language : ModuleNames.Wellbeing,
            order: 3,
            tourKey: 'wellbeing-setup-3'
          }">
          <div class="flex flex-col w-full gap-5">
            <div class="flex flex-row items-center justify-between">
              <h3 [@child5] class="text-2xl text-zen font-medium uppercase">
                {{
                "txt_set_reminders"
                | translate : language : ModuleNames.Wellbeing
                }}
              </h3>
              <div class="">
                <a [@child8] *ngIf="surveyData.reminders?.length" (click)="
                    surveyData.reminders?.length == 3 ? '' : addReminder()
                  " [ngClass]="{
                    'opacity-50 pointer-events-none':
                      surveyData.reminders?.length == 3
                  }" class="btn cancel leading-10 h-10 block px-4 rounded">{{
                  "txt_add_reminder"
                  | translate : language : ModuleNames.Wellbeing
                  }}</a>
              </div>
            </div>
            <EmptySection *ngIf="!surveyData.reminders?.length || !surveyData.reminders">
              <content>
                <div class="flex flex-col justify-center items-center gap-2">
                  <h2 [@child6] class="text-zen">
                    {{
                    "txt_no_reminders"
                    | translate : language : ModuleNames.Wellbeing
                    }}
                  </h2>
                  <button [@child9] (click)="addReminder()" class="btn cancel leading-10 h-10 block px-4 rounded">
                    {{
                    "txt_add_reminder"
                    | translate : language : ModuleNames.Wellbeing
                    }}
                  </button>
                </div>
              </content>
            </EmptySection>

            <div *ngFor="
                let reminder of surveyData.reminders | slice : 0 : 3;
                let z = index
              " class="flex flex-row justify-between items-center">
              <div class="flex flex-row gap-2 items-center">
                <div class="leading-8 w-8 h-8 block rounded-full btn exp text-center pointer-events-none">
                  {{ z + 1 }}
                </div>
                <div class="w-60" [@child7]>
                  <z-datepicker [ignoreDays]="getReminders(surveyData.reminders, i)" [placeholder]="
                      'txt_reminder_date'
                        | translate : language : ModuleNames.Wellbeing
                    " [color]="'exp'" [language]="language" [min]="startReminderDate" [max]="surveyData.endDate" [(ngModel)]="reminder.date">
                  </z-datepicker>
                </div>
              </div>

              <lottie-player [@child8] (click)="removeReminder(reminder)" src="/assets/lottie/delete2.json" background="transparent" speed="1" style="width: 1.5rem; height: 1.5rem" class="btn" loop autoplay="false">
              </lottie-player>
            </div>
          </div>
          <div [@child5] class="flex flex-col w-5/12 bg-white rounded-md p-5 gap-5" *ngIf="reminder2">
            <div [@child7] class="flex flex-row gap-2">
              <i class="fal fa-bell text-exp w-8 h-8 leading-8 text-center"></i>

              <div class="flex flex-col gap-3">
                <div class="flex flex-row justify-between items-center">
                  <h4 [@child13] class="text-zen">
                    {{
                    "txt_multiple_reminders"
                    | translate : language : ModuleNames.Wellbeing
                    }}
                  </h4>
                  <!-- <i [@child14] (click)="reminder2 = false"
                                    class="fal fa-times text-zen-red w-8 h-8 leading-8 text-center btn"></i> -->
                </div>
                <p [@child15] class="opacity-80 text-sm text-justify" [innerHtml]="
                    'txt_multiple_reminders_description'
                      | translate : language : ModuleNames.Wellbeing
                  "></p>
              </div>
            </div>
            <a [@child16] (click)="reminderTemplate()" class="btn cancel leading-12 h-12 block px-4 rounded-md text-center text-base" [TourStep]="{
                title:
                  'txt_reminder_template'
                  | translate : language : ModuleNames.Wellbeing,
                text:
                  'txt_Customize_297'
                  | translate : language : ModuleNames.Wellbeing,
                order: 4,
                tourKey: 'wellbeing-setup-3'
              }">{{
              "txt_reminder_template"
              | translate : language : ModuleNames.Wellbeing
              }}</a>
          </div>
        </div>
      </div>
      <div [@slidesAniamtion]="resolveRoute(tab)" class="flex flex-col gap-10" *ngIf="tab == 4">
        <div class="flex flex-row items-center justify-between">
          <h5 [@first] class="text-xl font-medium text-zen">
            {{
            "txtEmailTemplate" | translate : language : ModuleNames.Wellbeing
            }}
          </h5>
        </div>
        <zen-editor [TourStep]="{
            title:
              'txt_Invitatio_215'
              | translate : language : ModuleNames.Wellbeing,
            text:
              'txt_Customize_416'
              | translate : language : ModuleNames.Wellbeing,
            order: 2,
            tourKey: 'wellbeing-setup-4'
          }" (reset)="resetTemplate()" [language]="language" [emailObject]="surveyData.invitationEmail"></zen-editor>
      </div>
    </div>
  </content-container>
  <ng-container *ngIf="language">
    <product-tour *ngIf="tab == 0 && surveyData?.survey?.sections?.length" [key]="'wellbeing-setup-0'" [offset]="60" [destroy]="true"></product-tour>
    <product-tour *ngIf="tab == 1" [key]="'wellbeing-setup-1'" [offset]="60" [destroy]="true"></product-tour>
    <product-tour *ngIf="tab == 2" [key]="'wellbeing-setup-2' " [offset]="60" [destroy]="true"></product-tour>
    <product-tour *ngIf="tab == 3" [key]="'wellbeing-setup-3'" [offset]="60" [destroy]="true"></product-tour>
    <product-tour *ngIf="tab == 4" [key]="'wellbeing-setup-4'" [offset]="60" [destroy]="true"></product-tour></ng-container>

</zen-main-container>

<respondents-modal (closeDialog)="respondentAdd = false" [essentialObject]="essentialObject" *ngIf="respondentAdd" (uploadRequest)="insertUsingExcel($event)" (multipleRespondents)="createMultipleRespondent($event)" [language]="language" (singleRespondent)="createSingleRespondent($event)" [(respondentAdd)]="respondentAdd" [newRespondent]="newRespondent" (downloadCSV)="downloadExcel($event)"></respondents-modal>

<!-- <respondents-modal [key]="'RESLIST'" [surveyType]="'ENG'" (closeDialog)="respondentAdd = false;"
  [surveyKey]="surveyData.key" [essentialObject]="essentialObject" [surveyInfo]="surveyData" *ngIf="respondentAdd"
  (uploadRequest)="uploadRequest($event)" (multipleRespondents)="bulkUpdateRespondents($event)" [language]="language"
  (singleRespondent)="updateEngagementRespondent($event)" [(respondentAdd)]="respondentAdd"
  [dataSource]="companyRespondentsList" [newRespondent]="newRespondent" (downloadCSV)="downloadCSV($event)"
  (uploadCSV)="changeListener($event)"></respondents-modal> -->