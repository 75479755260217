import { Injectable } from '@angular/core';
import { ExcelUploadColumns } from '../../utils/excel-upload-columns';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { EssentialObject, EssentialObjectService } from 'src/app/core/services/essential-object.service';
import { Entity } from 'src/app/core/data-models/Entity';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { ExtractPipe } from 'src/app/shared/pipes/extract.pipe';
import { ColumnConfigDto } from '@ex/exit/excel-uploader';
import { Observable, of } from 'rxjs';
import { ExcelColumnTypes } from '../../utils/excel-column-types';
import { EntityGroup } from 'src/app/core/data-models/entity-group';
import { ExcelDataTypes } from '@ex/excel-uploader';
import { SeperationTypes, SeperationTypesService } from './seperation-types.service';
import { products } from 'src/app/core/data-models/company-permission';

@Injectable({
  providedIn: 'root'
})
export class ExcelConfigService {

  private selelectedEntity: Entity;
  private allEntities: Entity[];
  private language = "EN";
  private hasCompanyPermission = false;

  constructor(private essentialObjectService: EssentialObjectService, private generalService: GeneralService, private extractPipe: ExtractPipe
    , private permissionService: PermissionsService
    , private seperationTypeService: SeperationTypesService) {

    this.essentialObjectService.essentialObject.pipe(
      filter(x => x.selectedEntity.key != null),
      distinctUntilChanged((a, b) => a == b, obj => obj.selectedEntity.key)
    ).subscribe((obj: EssentialObject) => {
      this.allEntities = obj.allEntities;
      this.selelectedEntity = obj.selectedEntity;
    })


  }

  generateConfigAsync(tenantKey: string, companyKey: string): Observable<ColumnConfigDto[]> {

    this.hasCompanyPermission = this.permissionService.isSuperAdmin() || this.permissionService.hasPermission(this.selelectedEntity.key, products.Exit);

    const entityHierarchy = [];

    const columnConfig: ColumnConfigDto[] = [
      {
        title: "Leaver Id",
        key: ExcelUploadColumns.EmployeeId,
        lookup: null,
      },
      {
        title: "Name",
        key: ExcelUploadColumns.Name,
        lookup: null,
        isRequired: true
      },
      {
        title: "Email",
        key: ExcelUploadColumns.Email,
        lookup: null,
        isRequired: true
      },
      {
        title: "Country Code",
        key: ExcelUploadColumns.CountryCode,
        lookup: null,

      },
      {
        title: "Phone Number",
        key: ExcelUploadColumns.PhoneNumber,
        lookup: null,

      },
      {
        title: "Gender",
        key: ExcelUploadColumns.Gender,
        lookup: {
          key: "genderLookup",
          title: "Gender Lookup",
          options: this.generalService.genders_new.map((x: any) => ({
            key: x.key,
            id: this.extractPipe.transform(x.name, this.language) as String,
            name: this.extractPipe.transform(x.name, this.language) as String,
            parentKey: null
          }))
        },
        isRequired: true
      },
      {
        title: "Date Of Birth (YYYY-MM-DD)",
        key: ExcelUploadColumns.DateOfBirth,
        dataType: ExcelDataTypes.Date,
        lookup: null,
        isRequired: true
      },
      {
        title: "Nationality",
        key: ExcelUploadColumns.Nationality,
        lookup: {
          key: "nationalityLookup",
          title: "nationality Lookup",
          options: this.generalService.countries_new.map(x => ({
            key: x.key,
            id: this.extractPipe.transform(x.name, this.language) as String,
            name: this.extractPipe.transform(x.name, this.language) as String,
            parentKey: null
          }))
        },
        isRequired: true
      },
      {
        title: "Joining Date (YYYY-MM-DD)",
        key: ExcelUploadColumns.JoiningDate,
        dataType: ExcelDataTypes.Date,
        lookup: null,
        isRequired: true
      },
      {
        title: "Seperation Date (YYYY-MM-DD)",
        key: ExcelUploadColumns.SeperationDate,
        dataType: ExcelDataTypes.Date,
        lookup: null,
        isRequired: true
      },
      {
        title: "Seperation Types",
        key: ExcelUploadColumns.SeperationType,
        lookup: {
          key: "SeperationTypesLookUp",
          title: "Seperation Types",
          options: [{
            key: SeperationTypes.Resignation,
            id: this.extractPipe.transform(this.seperationTypeService.getByKey(SeperationTypes.Resignation)?.name, this.language),
            name: this.extractPipe.transform(this.seperationTypeService.getByKey(SeperationTypes.Resignation)?.name, this.language),
            parentKey: null
          },
          {
            key: SeperationTypes.Termination,
            id: this.extractPipe.transform(this.seperationTypeService.getByKey(SeperationTypes.Termination)?.name, this.language),
            name: this.extractPipe.transform(this.seperationTypeService.getByKey(SeperationTypes.Termination)?.name, this.language),
            parentKey: null
          },
          {
            key: SeperationTypes.Retirement,
            id: this.extractPipe.transform(this.seperationTypeService.getByKey(SeperationTypes.Retirement)?.name, this.language),
            name: this.extractPipe.transform(this.seperationTypeService.getByKey(SeperationTypes.Retirement)?.name, this.language),
            parentKey: null
          }
          ]
        },
        isRequired: true
      },
      {
        key: ExcelUploadColumns.DirectManagerId,
        title: "Direct Manager Id",
        lookup: null,
      },
      {
        key: ExcelUploadColumns.DirectManagerName,
        title: "Direct Manager Name",
        lookup: null,
      },
      {
        key: ExcelUploadColumns.performanceRate,
        title: "Performance Rating",
        lookup: {
          key: "PerformanceRatingLookup",
          title: "Performance Rating Lookup",
          options: this.selelectedEntity.PerformanceRates ? this.selelectedEntity.PerformanceRates.map(x => {

            let j = Number.isNaN(Number(x.value));
            let jx = x.value;
            let performance = Number.isNaN(Number(x.value)) ? String(x.value) as String : Number(x.value) as Number;

            return {
              key: x.key,
              id: performance,
              name: performance,
              parentKey: null
            }

          }) : [],
        },
      },
      {
        key: ExcelUploadColumns.BandLevel,
        title: "Band Level",
        lookup: {
          key: "BandLevelsLookup",
          title: "Band Level Lookup",
          options: this.selelectedEntity.BandLevels ? this.selelectedEntity.BandLevels.map(x => {

            let j = Number.isNaN(Number(this.extractPipe.transform(x.name, this.language)));
            let jx = this.extractPipe.transform(x.name, this.language);
            let bandLevel = Number.isNaN(Number(this.extractPipe.transform(x.name, this.language))) ? String(this.extractPipe.transform(x.name, this.language)) as String : Number(this.extractPipe.transform(x.name, this.language)) as Number;

            return {
              key: x.key,
              id: bandLevel,
              name: bandLevel,
              parentKey: null
            }

          }) : [],
        },
      },
      {
        key: ExcelUploadColumns.JobGrade,
        title: "Job Grade",
        lookup: {
          key: "JobGradeLookup",
          title: "Job Grade Lookup",
          options: this.selelectedEntity.JobGrades ? this.selelectedEntity.JobGrades.map(x => {

            let val = Number.isNaN(Number(this.extractPipe.transform(x.name, this.language))) ? this.extractPipe.transform(x.name, this.language) as String : Number(this.extractPipe.transform(x.name, this.language)) as Number;

            return {
              key: x.key,
              id: val,
              name: val,
              parentKey: null
            }

          }) : [],
        }
      },
      ...this.getExtaCriteriaConfig(),
      ...this.getEntityGroupsConfig(entityHierarchy),
      ...this.getTimeframeColumns()
    ];

    return of(columnConfig);

  }

  private getExtaCriteriaConfig() {
    return this.generalService.extraCriterias.map(criteria => {

      return {
        key: criteria.key,
        title: this.extractPipe.transform(criteria.name, this.language),
        lookup: {
          key: `ec-${criteria.key}Lookup`,
          title: `ec-${this.extractPipe.transform(criteria.name, this.language)} Lookup`,
          parentColumnKey: null,
          options: criteria.options.map(x => {
            return {
              key: x.key,
              id: this.extractPipe.transform(x.name, this.language),
              name: this.extractPipe.transform(x.name, this.language),
              parentKey: null
            }
          })
        },
        type: ExcelColumnTypes.ExtraCriteria
      } as ColumnConfigDto;

    })

  }

  private getEntityGroupsInOrder(root: EntityGroup, allEntities: EntityGroup[]) {

    var children = this.selelectedEntity.EntityGroups.filter(x => x.parentKey == root.groupKey);

    if (children.length > 0) {

      children.forEach(child => {

        allEntities.push(child);
        this.getEntityGroupsInOrder(child, allEntities);

      })
    }

  }

  private getEntityGroupsConfig(entityHierarchy) {
    let i = 0;

    var root = this.selelectedEntity.EntityGroups.find(x => x.isCompanyGroup == true);

    var entityGroups = [];

    this.getEntityGroupsInOrder(root, entityGroups);

    // if (this.hasCompanyPermission) {
    entityHierarchy = entityGroups.map(group => ({
      key: group.groupKey,
      name: group.name,
      entities: this.allEntities.filter(x => x.entityGroupKey == group.groupKey),
      selectedEntityKey: "",
      readOnly: true,
      parentKey: group.parentKey
    }));
    // }


    return entityHierarchy.map(group => {
      i = i + 1;

      return {
        key: group.key,
        title: this.extractPipe.transform(group.name, this.language),
        isEntityHierarchy: true,
        type: ExcelColumnTypes.Entity,
        lookup: {
          key: `${group.key}Lookup`,
          title: `${this.extractPipe.transform(group.name, this.language)} Lookup`,
          // parentColumnKey: i == 1 ? null : group.parentKey,
          parentColumnKey: group.parentKey == root.groupKey ? null : group.parentKey,
          options: group.entities.filter(x => x.entityGroupKey == group.key).map(x => {
            return {
              key: x.key,
              id: this.extractPipe.transform(x.name, this.language),
              name: this.extractPipe.transform(x.name, this.language),
              parentKey: i == 1 ? null : this.extractPipe.transform(this.allEntities.find(p => p.key == x.parentKey)?.name, this.language)
            }
          })
        }
      } as ColumnConfigDto;


    })

  }

  private getTimeframeColumns() {

    let config: ColumnConfigDto[] = [
      {
        key: ExcelUploadColumns.StartDate,
        title: "Start Date (YYYY-MM-DD)",
        dataType: ExcelDataTypes.Date,
        lookup: null,
        isRequired: true
      },
      {
        key: ExcelUploadColumns.EndDate,
        title: "End Date (YYYY-MM-DD)",
        dataType: ExcelDataTypes.Date,
        lookup: null,
        isRequired: true
      },
      {
        key: ExcelUploadColumns.Reminder1,
        title: "Reminder 1 (YYYY-MM-DD)",
        dataType: ExcelDataTypes.Date,
        lookup: null
      }, {
        key: ExcelUploadColumns.Reminder2,
        title: "Reminder 2 (YYYY-MM-DD)",
        dataType: ExcelDataTypes.Date,
        lookup: null
      },
      {
        key: ExcelUploadColumns.Reminder3,
        title: "Reminder 3 (YYYY-MM-DD)",
        dataType: ExcelDataTypes.Date,
        lookup: null
      }

    ]

    return config;

  }
}
