import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EngagementRespondent } from 'src/app/core/data-models/engagement-respondent';
import { Respondent } from 'src/app/core/data-models/respondent';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { CrashReportingService } from 'src/app/core/services/crash-reporting.service';
import { EngagementService } from 'src/app/core/services/engagement.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { EssentialObjectService } from 'src/app/core/services/essential-object.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LocalService } from 'src/app/core/services/local.service';
import { RespondentService } from 'src/app/core/services/respondent.service';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { UnitBalanceService } from 'src/app/core/services/unit-balance.service';
import { ModuleName } from '../enums/ModuleName';
import { TranslatePipe } from '../pipes/translate.pipe';
import { ZenSnackbarService, ZenSnackBarTypes } from '../Theme/services/zen-snackbar.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {
  @Output() restableSelectionChanged = new EventEmitter();
  constructor(
    private engagementService: EngagementService,
    private zenSnackBarService: ZenSnackbarService,
    public globals: GlobalsService,
    private loader: LoaderService,
    private languageService: LanguageService,
    private generalService: GeneralService,
    private db: AngularFireDatabase,
    private unitBalanceSerive: UnitBalanceService,
    public matDialog: MatDialog,
    private responseDialogService: ResponseDialogService,
    private errorHandlerService: ErrorHandlerService,
    private crashReportingService: CrashReportingService,
    public matDDialog: MatDialog,
    public essentialObjectService: EssentialObjectService,
    private router: Router,
    private localService: LocalService,
    private respondentService: RespondentService, private translationPipe: TranslatePipe
  ) {
    this.subscriptions.add(this.essentialObjectService.essentialObject.subscribe(
      (object: any) => {
        this.language = object.language;
        this.user = object.user;
        this.tenant = object.tenant;
        this.selectedEntity = object.selectedEntity;
        if (object.selectedEntity && object.user) {
          this.essentialObject = object;
          //this.fetchRespondents();
          this.setUpLanguagePreference();
        }
      }
    ));
  }
  language;
  user;
  displayedColumns2;
  tenant;
  selectedEntity;
  filteredCompanyRespondentsList;
  // txtID: string;
  // txtRespondentName: string;
  // txtEmailAddress: string;
  // txtAddedSuccess: string;
  // txtRemovedSuccess: string;
  // txtUpdatedSuccess: string;
  // txtSearch: string;
  respondentAdd;
  options: TableOptions;
  newRespondent: EngagementRespondent;
  private subscriptions = new Subscription();
  essentialObject;
  ngOnInit() {
    console.log(this.language)
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  ModuleNames = ModuleName
  fetchRespondents() {
    try {
      this.subscriptions.add(this.engagementService
        .fetchRespondents(this.tenant.key, this.selectedEntity.key)
        .subscribe((respondentList: Respondent[]) => {
          this.filteredCompanyRespondentsList = respondentList;
          this.options = {
            data: this.filteredCompanyRespondentsList,
            columns: this.displayedColumns2,
            searchText: this.translationPipe.transform('txt_search', this.language, this.ModuleNames.Shared),
            colors: this.globals.basicColors,
            sticky: true,
            selection: true,
          }
        }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        602,
        ex.toString(),
        this.tenant.key,
        this.tenant.name,
        this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name),
        this.user?.firstName +
        ' ' +
        this.user?.lastName +
        ' (' +
        this.user?.email +
        ')'
      );
    }
  }
  setUpLanguagePreference() {
    this.setUpTextFields();
  }

  uploadRespondents(fileBase) {
    let request = {
      FileKey: 'RESPOOL',
      TenantKey: this.tenant.key,
      CompanyKey: this.selectedEntity.key,
      UserKey: this.user.key,
      FileBase: fileBase
    };
    this.subscriptions.add(
      this.respondentService
            .updloadRespondents(request)
            .subscribe((response: any) => {
              if(response.response == 'SUCCESS')
                this.respondentAdd = false;
            }));
  }

  downloadExcel() {
    window.open('/assets/downloads/Respondents Template.xlsx', '_blank');
  }

  setUpTextFields() {
    // this.txtID = this.languageService.getUILanguage('txt_ID', this.language);
    // this.txtRespondentName = this.languageService.getUILanguage(
    //   'txt_respondent_name',
    //   this.language
    // );
    // this.txtEmailAddress = this.languageService.getUILanguage(
    //   'txt_email_address',
    //   this.language
    // );
    // this.txtAddedSuccess = this.languageService.getUILanguage(
    //   'txt_added_success',
    //   this.language
    // );
    // this.txtRemovedSuccess = this.languageService.getUILanguage(
    //   'txt_removed_success',
    //   this.language
    // );
    // this.txtUpdatedSuccess = this.languageService.getUILanguage(
    //   'txt_updated_success',
    //   this.language
    // );
    // this.txtSearch = this.languageService.getUILanguage(
    //   'txt_search',
    //   this.language
    // );

    this.displayedColumns2 = [
      {
        field: 'employeeID',
        title: this.translationPipe.transform('txtID', this.language, this.ModuleNames.Shared),
      },
      {
        field: 'name',
        title: this.translationPipe.transform('txt_respondent_name', this.language, this.ModuleNames.Shared),
      },
      {
        field: 'email',
        title: this.translationPipe.transform('txt_email_address', this.language, this.ModuleNames.Shared),
      },
      {
        field: 'action',
        title: '',
      },
    ];

    this.options = {
      data: this.filteredCompanyRespondentsList,
      columns: this.displayedColumns2,
      searchText: this.translationPipe.transform('txt_search', this.language, this.ModuleNames.Shared),
      colors: this.globals.basicColors,
      sticky: true,
      selection: true,
    }
  }

  deleteRespondent(respondent) {
    this.engagementService
      .removeRespondentFromPool(
        this.tenant.key,
        this.selectedEntity.key,
        respondent.key
      )
      .then((_) => {
        this.zenSnackBarService.snackBar({
          message: this.translationPipe.transform('txt_removed_success', this.language, this.ModuleNames.Shared),
          type: ZenSnackBarTypes.Success
        }
        );
        // this._snackBar.open(this.txtRemovedSuccess, this.txtClose, {
        //   duration: 2000,
        // });
      });
  }
  respondentAddFunction() {
    this.respondentAdd = true;
    this.newRespondent = new EngagementRespondent();
    this.newRespondent.status = 'PENDING';
    this.newRespondent.actualStatus = 'PENDING';
    this.fetchRespondents();
  }

  updateRespondentPool(element) {
    let respondents: EngagementRespondent[] = [];
    respondents.push(element);
    let request = {
      TenantKey: this.tenant.key,
      CompanyKey: this.selectedEntity.key,
      UserKey: this.user.key,
      respondents: respondents
    }
    this.respondentService.updateRespondentPool(request).subscribe((response: any) => {
      if (response.response == 'SUCCESS') {
        this.fetchRespondents();
      }
    });

  }


  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }


}
