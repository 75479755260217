import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SxBaseComponent } from '../../sx-base/sx-base.component';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { AxisLabelVisualArgs } from '@progress/kendo-angular-charts';
import { IndividualResponsesModalComponent } from '../individual-responses-modal/individual-responses-modal.component';
import { truncateSync } from 'fs';
import { TranslatePipe } from '@ex/module/shared/pipes/translate.pipe';
import { LanguageService } from '@ex/module/core/services/language.service';
import { NativeExportBaseComponent } from '@ex/module/shared/native-export-base/native-export-base.component';



@Component({
  selector: 'sx-respondent-chart',
  templateUrl: './respondent-chart.component.html',
  styleUrls: ['./respondent-chart.component.scss']
})
export class RespondentChartComponent extends NativeExportBaseComponent implements OnInit {
  @ViewChild('popupTemplate') popupTemplate: TemplateRef<any>;
  public popupContent: string;
  customChart: boolean = false;
  private popupRef: PopupRef;
  data: any;
  @Input('data') set tdata(data: any) {
    this.data = data;
    if (this.data.categories.length > 10) {
      this.customChart = true;
      this.data.pie.sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
    }
  }

  @Input()
  chart;

  @Input()
  surveyCriterias;

  @Input()
  language = "EN";


  chartColor: any = "#39ABB4";
  chartType: any = "column"

  constructor(private popupService: PopupService) { super() }

  ngOnInit(): void {

    console.log("chart:", this.chart)
    console.log("surveyCriterias:", this.surveyCriterias)
  }

  getCriteriaOptions(key: string) {
    return this.surveyCriterias.find(x => x.key == key)?.options ?? [];
  }

  getCount(data: any, category: string, group: string = "") {
    if (!data?.groupedChart) {
      const item = group == "Generation" ? data.pie.find(x => x.key == category) : data.pie.find(x => x.name.toLowerCase() == category.toLowerCase());
      return {
        percentage: item?.value ?? 0,
        count: item?.value1 ?? 0,
      }
    }

    //let count = this.data.bar.series[0].counts[this.data.categories.indexOf(category)]
  }


  public onSeriesClick(e, group): void {
    this.openIndividualResponsesModal(e.category)
  }

  public content = (e) => {
    if (e.value.length > 8) {
      var truncateValue = e.value.substring(0, 8) + '...';
      return truncateValue;
    } else {
      return e.value;
    }
  }

  onTextContent(e) {
    return e.value.toFixed(1) + "%"
  }


  public attachSurfaceHandlers(e: any): void {
    e.sender.surface.bind('mouseenter', args => this.onMouseEnter(args));
    e.sender.surface.bind('mouseleave', args => this.onMouseLeave());
  }

  public onMouseEnter(e: any): void {
    const tooltip = e.element.parent.tooltip;
    if (!tooltip) {
      return;
    }

    if (this.popupRef) {
      this.popupRef.close();
    }

    this.popupContent = tooltip;
    this.popupRef = this.popupService.open({
      content: this.popupTemplate,
      offset: {
        left: e.originalEvent.pageX + 5,
        top: e.originalEvent.pageY + 10,
      },
    });
  }

  public onMouseLeave(): void {
    if (!this.popupRef) {
      return;
    }

    this.popupRef.close();
    this.popupRef = null;
  }


  public visual = (e: AxisLabelVisualArgs): any => {
    const visual = e.createVisual();

    if (e.value.length > 8) {   // Remove the if-condition if you want to show the tooltip for all the labels
      (<any>visual).tooltip = e.value;
    }

    return visual;
  }

  changeChartType(type: any) {
    this.chartType = type;
  }

  openIndividualResponsesModal(category, group?:any) {
    let drillDown = this.data.drillDown.filter(x => x.category == category);
    if (group)
      drillDown = drillDown.filter(x => x.group == group)
    const sub = this.ZenCDKService.openComponent(IndividualResponsesModalComponent, { question: { title: this.chart.Title }, category: category, drillDown, language: this.language }).subscribe(r => {

    });
    this.addSubscription(sub);
  }



  extract(item) {
    return this.ExtractPipe.transform(item, this.language);
  }

  exportDraft(language = this.language, white?) {
    if (this.languagePipe.transform(language))
      this.dir = 'rtl';
    else this.dir = 'ltr';

    let newElement = document.getElementById('pdf' + this.chart?.group);
    var mywindow = window.open('', ' ', 'left=0,top=0,width=950,height=600,toolbar=0,scrollbars=0,status=0,addressbar=0');
    const agent = window.navigator.userAgent.toLowerCase()
    mywindow.document.write(document.head.innerHTML);

    mywindow.document.write(`<body style='padding:40px' dir='${this.dir}' style='background-color:${white ? 'white' : 'transparent'}'>`);
    mywindow.document.write(newElement.innerHTML);

    mywindow.document.write('</body>');
    mywindow.document.close(); // necessary for IE >= 10 and necessary before onload for chrome
    if (this.title)
      mywindow.document.title = this.title;

    if (agent.indexOf('chrome') > -1) {
      mywindow.onload = function () { // wait until all resources loaded
        mywindow.focus(); // necessary for IE >= 10
        setTimeout(() => {
          mywindow.print();  // change window to mywindow
          mywindow.close();// change window to mywindow
        }, 2000);
      };

    }
    else {
      mywindow.document.close(); // necessary for IE >= 10
      setTimeout(() => {
        mywindow.focus(); // necessary for IE >= 10
        mywindow.print();
        mywindow.close();
      }, 2000);
    }
    return true;
  }

}
