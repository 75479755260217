import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ex/module/core/domain/base-component';

@Component({
  selector: 'app-demo-report-page',
  templateUrl: './demo-report-page.component.html',
  styleUrls: ['./demo-report-page.component.scss']
})
export class DemoReportPageComponent extends BaseComponent implements OnInit {
  language;
  ModuleNames: any;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(this.EssentialObject$.subscribe(x => {
      this.language = x.language;
    }));
  }

  upgrade() {
    this.SnackbarService.info(this.localize('txtUpgradeRequestSent', this.language, this.ModuleNames.Shared));
  }
  localize(arg0: string, language: any, Shared: any): any {
    throw new Error('Method not implemented.');
  }


}
