<div *DemoAccount="'Default'; let isDemo=isDemo">
  <!-- Table Actions -->
  <div class="flex flex-row justify-between items-center h-20 bg-white px-10">
    <div class="flex flex-row items-stretch gap-6">
      <div class="flex flex-row items-stretch">
        <div
          class="text-sm rounded text-zen bg-white px-3 h-9 gap-2 leading-9 truncate flex flex-row justify-start items-center">
          <i [ngClass]="{'rotate-h-45':(language|language)}" class="fal fa-search text-zen transform"></i>
          <input [(ngModel)]="filterText" (ngModelChange)="onFilterChange($event)"
            [placeholder]="'txt_search' | translate:language:ModuleNames.Shared"
            class="bg-transparent overflow-hidden h-12 leading-12 text-sm  w-80" type="text">
        </div>
      </div>
      <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
      <a *DemoAccount="'Button'; let isDemo=isDemo" (click)="isDemo?'':bulkSendReminder()" zenButton styles="ghost height-11"
        [ngClass]="{'pointer-events-none text-opacity-30': allSendReminderCount == 0, '': allSendReminderCount}"
        [TourStep]="tourKey?{title:'txt_send_reminders' | translate:language:ModuleNames.Exit,text: 'txt_Clickhere_644' | translate:language:ModuleNames.Exit,order: 5,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined">
        <i class="fa-regular fa-bell"></i>
        <h5>{{'txt_send_reminders'| translate:language:ModuleNames.Exit}} ({{allSendReminderCount}})</h5>
      </a>
      <ng-container  *ngIf="selectedLeavers?.length">
        <a *DemoAccount="'Button'; let isDemo=isDemo" (click)="isDemo?'':deleteLeaver()" zenButton styles="ghost height-11">
          <i class="fa-regular fa-trash text-zen-red"></i>
          <h5>{{ "txt_DeleteSel_329" | translate:language:ModuleNames.Exit }}</h5>
        </a>
      </ng-container>
    </div>
    <div class="flex flex-row items-stretch justify-end gap-2.5"
      [TourStep]="tourKey?{title:'txt_ExportLea_980' | translate:language:ModuleNames.Exit,text: 'txt_Youcandow_702' | translate:language:ModuleNames.Exit,order: 6,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined">
      <i (click)="downloadExcell()" class="fa-regular fa-download" zenButton styles="ghost icon-11"></i>
      <!-- <span class="w-0 h-auto border-r border-zen border-opacity-20"></span> -->
    </div>
  </div>
  <!-- Table Rows -->
  <div class="flex flex-col w-full max-w-full overflow-auto">
    <div class="flex flex-col justify-between items-stretch gap-5 min-w-full px-10 Page ">
      <t-table *ngIf="options?.columns?.length" (selectionChanges)="leaversSelected($event)" [options]="options"
        (columnSelection)="updateTable()"
        [customizeTour]="tourKey?{title:'txtCustomize' | translate:language:ModuleNames.Exit,text: 'txt_Clickhere_840' | translate:language:ModuleNames.Exit,order: 7,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined">
        <ng-template #cellTempate let-element let-column='column' let-colIndex='colIndex'>
          <ng-container [ngSwitch]="column.field">
            <ng-container *ngSwitchCase="'employeeId'">
              <h5 class="max-w-xs truncate text-sm text-zen ">
                {{element[column.field]?element[column.field]:'-'}}</h5>
            </ng-container>
            <ng-container *ngSwitchCase="'name'">
              <h5 class="max-w-xs truncate text-sm text-zen font-medium leading-20">
                {{element[column.field]?element[column.field]:'-'}}</h5>
            </ng-container>
            <ng-container *ngSwitchCase="'tenure'">
              <h5 class="max-w-xs truncate text-sm text-zen font-medium leading-20">
                {{returnTenureField(element[column.field])}}</h5>
            </ng-container>
            <ng-container *ngSwitchCase="'seperationDate'">
              <h5 class="max-w-xs truncate text-sm text-zen ">
                {{element[column.field] | date:'dd/MM/yyyy'}}</h5>
            </ng-container>
            <ng-container *ngSwitchCase="'seperationType'">
              <h5 class="max-w-xs truncate text-sm text-zen font-medium leading-20">
                {{element[column.field] | translate:language:ModuleNames.Exit}}</h5>
            </ng-container>
            <ng-container *ngSwitchCase="'status'">
              <div class=" truncate flex flex-row gap-2 items-center text-sm text-zen "><i class="far"
                  [ngClass]="globalStatus.returnClassColor(element.status)"></i>

                <span class="">{{globalStatus.returnClassName(element.status, language)}}</span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'empty'">
              <div class="flex flex-row justify-end items-center gap-2 h-full">
                <a (click)="viewAnswers(element)" *ngIf="isShowViewAnswers(element)"
                  class="btn openPath leading-10 px-4 rounded font-medium text-xs bg-zen bg-opacity-5 opacity-0 pointer-events-none"
                  [TourStep]="tourKey?{title:'btn_leaver_list_view_answers' | translate:language:ModuleNames.Exit,text: 'txt_Pointthem_625' | translate:language:ModuleNames.Exit,order: 9,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined">{{'txtOpen'|translate:language:ModuleNames.Exit}}</a>
                <i *ngIf="element.status != RespondentStatus.Inactive" [ZMenuTriggerFor]="menu"
                  class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full  btn text-center"
                  [TourStep]="tourKey?{title:'txt_MoreActio_765' | translate:language:ModuleNames.Exit,text: 'txt_Clickhere_319' | translate:language:ModuleNames.Exit,order: 8,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined"></i>
                <z-menu #menu>
                  <menuItems>
                    <a MenuItem *ngIf="isShowViewAnswers(element)" (click)="viewAnswers(element)">
                      <i
                        class="fal fa-file-alt mx-1"></i>{{'btn_leaver_list_view_answers'|translate:language:ModuleNames.Exit}}
                    </a>
                    <ng-container *ngIf="isShowSendReminder(element)">
                      <a MenuItem   (click)="sendReminder(element)">
                        <i class="fal fa-bell mx-1"></i>{{'txt_send_reminder' |
                        translate:language:ModuleNames.Exit}}
                      </a>
                    </ng-container>
                    <a MenuItem *DemoAccount="'Button'; let isDemo=isDemo" (click)="isDemo?'':editLeaver(element)">
                      <i class="fal fa-pen mx-1"></i> {{'txt_edit_leaver' |
                      translate:language:ModuleNames.Exit}}
                    </a>
                    <ng-container *ngIf="isShowDelete(element)">
                      <a MenuItem delete  *DemoAccount="'Button'; let isDemo=isDemo" (click)="isDemo?'':deleteLeaver(element)">
                        <i class="fal fa-trash mx-1 "></i> {{'btn_delete' |
                      translate:language:ModuleNames.Exit}}
                    </a>
                  </ng-container>
                  </menuItems>
                </z-menu>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>

              <ng-container *ngIf="column.grouped; else metric">
                <ng-container *ngFor="let eg of tableEntityGroups; let g = index">
                  <ng-container>
                    <ng-container *ngIf="eg.groupKey == column.groupkey">
                      <h5 class="text-sm whitespace-nowrap truncate text-zen font-normal"> {{
                        extractHierarchyEntityName(element.entityHierarchy, eg.groupKey) }} </h5>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-template #metric>
                <ng-container *ngIf="column.metric; else default">
                  <ng-container *ngFor="let metric of element.extraCriterias; let g = index">
                    <ng-container>
                      <ng-container *ngIf="metric.key == column.key">
                        <h5 class="text-sm whitespace-nowrap truncate text-zen font-normal">
                          {{returnMetricName(metric, selectedEntity.metrics) | extract:language}}</h5>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-template>
              <ng-template #default>
                <h5 class="max-w-xs truncate text-sm text-zen ">
                  {{element[column.field] | na:"-"}}</h5>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-template>
        <ng-container headerOptions>
        </ng-container>
        <ng-container customization>
          <div class="flex flex-row justify-between pb-2">
            <h5 class="text-zen text-xxs font-medium uppercase">{{'txtColumnName' |
              translate:language:ModuleNames.Exit}}</h5>
            <h5 class="text-zen text-xxs font-medium uppercase">{{'txt_status' |
              translate:language:ModuleNames.Exit}}
            </h5>
          </div>
          <div [@listAnimation]="allColumns?.length" class="innerListScroll max-h-screen-50 flex flex-col gap-1">

            <ng-container *ngFor="let column of allColumns; let n = index">
              <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm" *ngIf="!column.nonSelectable">
                <z-toggle [disable]="n <= 3" [label]="column.title | translate:language:ModuleNames.Exit"
                  [(ngModel)]="column.isSelected"></z-toggle>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </t-table>
      <t-paginator *ngIf="leavers?.length" [language]="language" [options]="options" [pageSize]="pageSize"
        [page]="currentPage" [totalRecords]="totalRecords || 0" (pageChanged)="onPageChange($event)"></t-paginator>
    </div>
  </div>
</div>
