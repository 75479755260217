import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageFilterService
{
  private surveyLanguage = new BehaviorSubject<any>({});
  selectedSurveyLanguage = this.surveyLanguage.asObservable();

  constructor(){}

  setSurveyLanguage(surveyLanguage:any){
    
    this.surveyLanguage.next(surveyLanguage);
  }

}
