import { Injectable } from '@angular/core';
export interface NativeChartExport {
  title: string,
  Title: string,
  group: string | any,
  DashletType: string,
  icon?: string,
  image?: string,
  key?: string,
  canGroup: boolean,
  language?: string,
  type?: string,
  isEntity?: boolean,
  data?: any,
  data$?: any,
  // export values
  breakBefore?: boolean,
  breakAfter?: boolean,
  fullWidth?: boolean,
  notExportable?: boolean
}
export interface AnalysisExportConfig {
  chartMax?: number,
  chartMin?: number,
  chartsPerPage?: number,
  duplicateData?: boolean
}
export interface ChartsExportConfigResult {
  charts?: NativeChartExport[],
  pages?: number
}



@Injectable({
  providedIn: 'root'
})



export abstract class exportConfiguration {
  abstract configure(exportChart)
}
export class ConfigureChartExport extends exportConfiguration {
  private charts: any = null;
  private config: AnalysisExportConfig;
  constructor(charts: NativeChartExport[], config: AnalysisExportConfig) {
    super();
    this.charts = charts;
    this.config = config;
  }
  configure(): ChartsExportConfigResult {

    let pageCount = 1, recordCount = 0, currentChart = 0, marginCount = 4;
    for (let index = 0; index < this.charts.length; index++) {
      let chart: NativeChartExport = this.charts[index];
      // escape if no rows
      if (chart.data?.categories?.length) {
        // make two columns if more than 10
        if (chart.data?.categories?.length >= 8 || chart.data?.categories.find(x => x.length > 20))
          chart.fullWidth = true;

        // row calculations if two rows or one
        if (chart.fullWidth)
          recordCount += chart.data?.categories?.length * 2 + marginCount;
        else
          recordCount += chart.data?.categories?.length + marginCount;

        currentChart++; //current chart count, escape acoording to the chartMax config
        if (chart)
          if ((recordCount >= this.config?.chartMax) || currentChart > this.config?.chartsPerPage || (chart.data?.categories?.length + recordCount > this.config.chartMax)) {
            recordCount = chart.data.categories.length;
            pageCount += chart.data.categories.length >= this.config.chartMax ? Math.ceil(chart.data.categories.length / this.config.chartMax) : 1;// total rows count
            chart.breakBefore = true;  // break chart a new page from here.
            currentChart = 1; //reset current chart count
          }

        // if(chart.group == 'Nationality')
        //
        console.log(chart);

      }
      else chart.notExportable = true;
    }
    let result: ChartsExportConfigResult = { charts: this.charts, pages: pageCount };
    return result;
  }

}
export class ConfigureExitChartExport extends exportConfiguration {
  private charts: any = null;
  private config: AnalysisExportConfig;
  constructor(charts: NativeChartExport[], config: AnalysisExportConfig) {
    super();
    this.charts = charts;
    this.config = config;
  }
  configure(): ChartsExportConfigResult {

    let pageCount = 1, recordCount = 0, currentChart = 0, marginCount = 4;
    for (let index = 0; index < this.charts.length; index++) {
      let chart: NativeChartExport = this.charts[index];
      // escape if no rows
      if (chart.data?.categories?.length) {
        if (chart.key != 'status' && this.config.duplicateData) { // duplicate values for testing
          chart.data.categories = [].concat(...Array(20).fill(chart.data?.categories))
          chart.data.pie = [].concat(...Array(20).fill(chart.data?.pie))
          chart.data.bar.series[0].data = [].concat(...Array(20).fill(chart.data.bar.series[0].data))
        }

        // make two columns if more than 10
        if (chart.data?.categories?.length >= 8 || chart.data?.categories.find(x => x.length > 20))
        chart.fullWidth = true;
        // row calculations if two rows or one
        if (chart.fullWidth)
          recordCount += chart.data?.categories?.length * 2 + marginCount;
        else
          recordCount += chart.data?.categories?.length + marginCount;

        currentChart++; //current chart count, escape acoording to the chartMax config
        if (chart)
          if ((recordCount >= this.config?.chartMax) || currentChart > this.config?.chartsPerPage || (chart.data?.categories?.length + recordCount > this.config.chartMax)) {
            recordCount = chart.data.categories.length;
            let recordsPerPage = this.config.chartMax;
            if (fullWidthChartCheck(chart, chart.data.categories) == 1)
              recordsPerPage = this.config.chartMin;
            pageCount += chart.data.categories.length >= recordsPerPage ? Math.ceil(chart.data.categories.length / recordsPerPage) : 1;// total rows count
            chart.breakBefore = true;  // break chart a new page from here.
            currentChart = 1; //reset current chart count
          }
        // if(chart.group == 'Nationality')
        //
        console.log(chart);

      }
      else chart.notExportable = true;
    }
    let result: ChartsExportConfigResult = { charts: this.charts, pages: pageCount };
    return result;
  }

}
export class ConfigureEngagementChartExport extends exportConfiguration {
  private charts: any = null;
  private config: AnalysisExportConfig;
  constructor(charts: NativeChartExport[], config: AnalysisExportConfig) {
    super();
    this.charts = charts;
    this.config = config;
  }
  configure(): ChartsExportConfigResult {

    let pageCount = 1, recordCount = 0, currentChart = 0, marginCount = 4;
    for (let index = 0; index < this.charts.length; index++) {
      let chart: NativeChartExport = this.charts[index];
      // escape if no rows
      if (chart.data?.categories?.length) {
        // make two columns if more than 10
        if (chart.data?.categories?.length >= 8 || chart.data?.categories.find(x => x.length > 20)) {
          chart.fullWidth = true;
          this.config.chartsPerPage = this.config.chartsPerPage - 1;
        }

        // row calculations if two rows or one
        if (chart.fullWidth)
          recordCount += chart.data?.categories?.length * 2 + marginCount;
        else
          recordCount += chart.data?.categories?.length + marginCount;

        currentChart++; //current chart count, escape acoording to the chartMax config
        if (chart)
          if ((recordCount >= this.config?.chartMax) || currentChart > this.config?.chartsPerPage || (chart.data?.categories?.length + recordCount > this.config.chartMax)) {
            recordCount = chart.data.categories.length;
            let recordsPerPage = this.config.chartMax;
            if (fullWidthChartCheck(chart, chart.data.categories) == 1)
              recordsPerPage = this.config.chartMin;
            pageCount += chart.data.categories.length >= recordsPerPage ? Math.ceil(chart.data.categories.length / recordsPerPage) : 1;// total rows count
            chart.breakBefore = true;  // break chart a new page from here.
            currentChart = 1; //reset current chart count
          }
        if (chart.breakBefore && chart.fullWidth) {
          chart.breakAfter = true
          pageCount++;
        }

      }
      else chart.notExportable = true;
    }
    let result: ChartsExportConfigResult = { charts: this.charts, pages: pageCount };
    return result;
  }

}

export class ConfigureWellbeingChartExport extends exportConfiguration {
  private charts: any = null;
  private config: AnalysisExportConfig;
  constructor(charts: NativeChartExport[], config: AnalysisExportConfig) {
    super();
    this.charts = charts;
    this.config = config;
  }
  configure(): ChartsExportConfigResult {

    let pageCount = 1, recordCount = 0, currentChart = 0, marginCount = 4;
    for (let index = 0; index < this.charts.length; index++) {
      let chart: NativeChartExport = this.charts[index];
      // escape if no rows
      if (chart.data?.categories?.length) {
        // make two columns if more than 10
        if (chart.data?.categories?.length >= 8 || chart.data?.categories.find(x => x.length > 20))
          chart.fullWidth = true;

        // row calculations if two rows or one
        if (chart.fullWidth)
          recordCount += chart.data?.categories?.length * 2 + marginCount;
        else
          recordCount += chart.data?.categories?.length + marginCount;

        currentChart++; //current chart count, escape acoording to the chartMax config
        if (chart)
          if (
            (recordCount >= this.config?.chartMax) ||
            currentChart > this.config?.chartsPerPage ||
            (chart.data?.categories?.length + recordCount > this.config.chartMax) ||
            (chart.data?.categories?.length + recordCount > 10 && chart.fullWidth)) {
            recordCount = chart.data.categories.length;
            pageCount += chart.data.categories.length >= this.config.chartMax ? Math.ceil(chart.data.categories.length / this.config.chartMax) : 1;// total rows count
            chart.breakBefore = true;  // break chart a new page from here.
            currentChart = 1; //reset current chart count
          }

        // if(chart.group == 'Nationality')
        //
        console.log(chart);

      }
      else chart.notExportable = true;
    }
    let result: ChartsExportConfigResult = { charts: this.charts, pages: pageCount };
    return result;
  }

}



export const CHART_EXPORT_CONFIGURATION = {
  configureOnBoardingCharts(charts, config: AnalysisExportConfig) {
    return new ConfigureChartExport(charts, config);
  },
  configureEngagementCharts(charts, config: AnalysisExportConfig) {
    return new ConfigureEngagementChartExport(charts, config);
  },
  configureWellbeingCharts(charts, config: AnalysisExportConfig) {
    return new ConfigureWellbeingChartExport(charts, config);
  },
  configureDefaultCharts(charts, config: AnalysisExportConfig) {
    return new ConfigureEngagementChartExport(charts, config);
  },
  configureExitCharts(charts, config: AnalysisExportConfig) {
    return new ConfigureExitChartExport(charts, config);
  },

}

function fullWidthChartCheck(chart, categories) {
  let rowsPerLine = 2;
  if (!chart.fullWidth || categories.find(x => x.length > 15))
    rowsPerLine = 1;
  return rowsPerLine;
}

