<sx-small-modal-header [(open)]="open">


    <ng-container header>

        <div class="flex flex-row w-full justify-between">
            <div class="flex flex-row items-start w-full justify-start gap-2 ">

                <div class="flex flex-col h-10 pr-0 justify-center items-start gap-1 ">
                    <h2 *ngIf="data.isRespondentAnalysis"
                        class="flex flex-col shrink-0 self-stretch text-zen-primarydefault text-lg font-medium tracking036">
                        {{"txtIndividual Respondents" |translate:language:ModuleNames.SurveyX}}
                    </h2>
                    <h2 *ngIf="!data.isRespondentAnalysis"
                        class="flex flex-col shrink-0 self-stretch text-zen-primarydefault text-lg font-medium tracking036">
                        {{"txtIndividual Responses" |translate:language:ModuleNames.SurveyX}}
                    </h2>
                </div>
            </div>

            <div class="flex items-start justify-end ">
                <div (click)="closeModal()"
                    class=" btn flex w-10 h-10 justify-center items-center rounded bg-zen-littlegray">
                    <div class="flex h-4 items-center gap-3">
                        <div class="flex w-5 h-5 justify-center items-center">
                            <i
                                class="flex flex-col w-4 justify-center shrink-0 text-zen-primarylight text-center text-base font-normal fa-regular fa-times"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container body>


        <div class="flex flex-col pt-4 pb-6 items-start gap-8 self-stretch">
            <div class="flex flex-col items-start gap-6">
                <div class="flex flex-col w436 items-start gap-6 border-c">

                    <div class="flex flex-col gap-4 w-full ">

                        <div *ngIf="data.isRespondentAnalysis"
                            class="flex flex-col gap-2 w-full ">

                            <div class="flex flex-row items-start gap-2 self-stretch">
                                <h5 class="w80 text-zen-primarydefault text-xs font-medium"> {{"txtChartType"|
                                    translate:language:ModuleNames.SurveyX }}</h5>
                                <h5 [matTooltip]="data.question.title | translate:language:ModuleNames.SurveyX"
                                    matTooltipPosition="above" matTooltipClass="zen-tooltip"
                                    class="c-layout truncate text-zen-primarydefault text-xs font-normal">{{data.question.title|
                                    translate:language:ModuleNames.SurveyX}}</h5>
                            </div>

                            <div class="flex flex-col gap-2">
                                <div class="flex flex-row items-start gap-2 self-stretch">
                                    <div class="flex gap-2">
                                        <h5 class="w80 text-zen-primarydefault text-xs font-medium"> {{"txtdata.category" |
                                            translate:language:ModuleNames.SurveyX}}</h5>
                                        <h5 class="c-layout truncate text-xs text-exp  font-normal">{{data.category |
                                            translate:language:ModuleNames.SurveyX}}</h5>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="!data.isRespondentAnalysis"
                            class="flex flex-col gap-2 w-full ">

                            <div class="flex flex-row items-start gap-2 self-stretch">
                                <h5 class="w80 text-zen-primarydefault text-xs font-medium"> {{"txtQuestion "|
                                    translate:language:ModuleNames.SurveyX }} {{ questionNo }} </h5>
                                <h5 [matTooltip]="data?.question.title | sjsTranslate" matTooltipPosition="above"
                                    matTooltipClass="zen-tooltip"
                                    class="c-layout truncate text-zen-primarydefault text-xs font-normal">
                                    {{data?.question.title | sjsTranslate:language}}</h5>
                            </div>

                            <div *ngIf="data.question.type != 'matrix'" class="flex flex-col gap-2">
                                <div class="flex flex-row items-start gap-2 self-stretch">
                                    <div class="flex gap-2">
                                        <h5 class="w80 text-zen-primarydefault text-xs font-medium"> {{"txtAnswer" |
                                            translate:language:ModuleNames.SurveyX}}</h5>
                                        <h5 *ngIf="data.question.type == 'imagepicker' && !data.question.isIconChoice "
                                            class="c-layout truncate text-xs text-exp  font-normal">
                                            {{data.category.text | sjsTranslate:language}}</h5>
                                        <h5 *ngIf="data.question.type != 'imagepicker' || (data.question.type == 'imagepicker' && data.question.isIconChoice)"
                                            class="c-layout truncate text-xs text-exp  font-normal">
                                            {{data.category| sjsTranslate:language}}</h5>
                                    </div>
                                </div>

                                <div *ngIf="data.question.type == 'ranking'" class="flex flex-row items-start gap-2 self-stretch">
                                    <div class="flex gap-2">
                                        <h5 class="w80 text-zen-primarydefault text-xs font-medium"> {{"txtRank" |
                                            translate:language:ModuleNames.SurveyX}}</h5>
                                        <h5 class="c-layout truncate text-xs text-exp font-normal">{{"txtRank" |
                                            translate:language:ModuleNames.SurveyX}}
                                            {{data.group}}</h5>
                                    </div>
                                </div>
                                <div *ngIf="data.question.type == 'rating' && data.group" class="flex flex-row items-start gap-2 self-stretch">
                                    <div class="flex gap-2">
                                        <h5 class="w80 text-zen-primarydefault text-xs font-medium"> {{"txtLabel" |
                                            translate:language:ModuleNames.SurveyX}}</h5>
                                        <h5 class="c-layout truncate text-xs text-exp font-normal">{{data.group | sjsTranslate:language}}
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="data.question.type == 'matrix'" class="flex flex-col gap-2">
                                <div class="flex flex-row items-start gap-2 self-stretch">
                                    <div class="flex gap-2">
                                        <h5 class="w80 text-zen-primarydefault text-xs font-medium"> {{"txtRow" |
                                            translate:language:ModuleNames.SurveyX}}</h5>
                                        <h5 class="c-layout truncate text-xs text-exp font-normal">
                                            {{data.category | sjsTranslate:language}}</h5>
                                    </div>
                                </div>

                                <div class="flex flex-row items-start gap-2 self-stretch">
                                    <div class="flex gap-2">
                                        <h5 class="w80 text-zen-primarydefault text-xs font-medium"> {{"txtColoumn" |
                                            translate:language:ModuleNames.SurveyX}}</h5>
                                        <h5 class="c-layout truncate text-xs text-exp  font-normal">
                                            {{data.group | sjsTranslate:language}}</h5>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="flex items-center self-stretch">
                            <div class="flex items-center gap-4 flex-1">
                                <div class="flex flex-col justify-center items-start gap-1 flex-1">
                                    <div
                                        class="flex flex-col h-9   justify-center items-start gap-2 self-stretch c-style-border">
                                        <z-input [search]="true" [(ngModel)]="searchTerm" (keyup)="onKeyUp()"
                                            class="w-full "
                                            [placeholder]="'Search...'| translate:language:ModuleNames.SurveyX"></z-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>




                    <div *ngIf="drillDown.length"
                        class="flex flex-col h184 innerListScroll items-start gap-4 self-stretch">
                        <div *ngFor="let respondent of drillDown; let i = index"
                            class="flex  items-center gap-10 self-stretch style-c-border">
                            <h5 class="flex-1 text-zen-primarydefault text-sm font-normal">{{ respondent.name }}</h5>
                            <!-- <h5 class="text-exp text-right text-sm font-normal">Strongly Agree</h5> -->
                        </div>

                    </div>


                </div>

            </div>

        </div>



    </ng-container>


    <ng-container footer>
        <div class="flex flex-row justify-center w-full">
            <a (click)="closeModal()"
                class="btn cancel w-full justify-center text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
                {{"txtClose"| translate:language:ModuleNames.SurveyX}}
            </a>

        </div>
    </ng-container>




</sx-small-modal-header>
