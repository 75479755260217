export class Dashlet {
  ID: number;
  Title: string;
  Description: string;
  DashletService: string;
  DashletType: string;
  DashletChartTypes: any[] = [];
  DashletID: number;
  ReportID: number;
  Datasources: string[] = [];
  Icon: string;
  HasBenchmark: boolean;
  X: number;
  Y: number;
  IsEntityGroup: boolean;
  SelectedGroupKey: string;
  DataType: string;
  SelectedEntityGroupID: string = '';
  Rows: number;
  Columns: number;
  ChartConfig: any;
  MinRows: number;
  MinColumns: number;
  MaxRows: number;
  MaxColumns: number;
  ItemID: string;
  CategoryAxisTitle: string;
  AdditionalSettings: any = { something: null };
  ValueAxisTitle: string;
  Value1Title: string;
  Value2Title: string;
  Value3Title: string;
  IsGov: boolean = false;
  dateFilter: any;
  canGroup: boolean = false;
  [key: string]: any;
}
