import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConditionsParser, Const, ItemValue, MatrixDropdownColumn, Operand } from 'survey-angular';
import { CreatorBase } from 'survey-creator-core';
import * as jsonFile from './../../../../../assets/icons.json';
import { AnswerCountValidator, ArrayOperand, BinaryOperand, TextValidator, UnaryOperand, Variable } from "survey-core";
import { AngularComponentFactory } from 'survey-angular-ui';
import { DatePipe } from '@angular/common';
import { LocalService } from '@ex/module/core/services/local.service';
import { ZenSnackbarService, ZenSnackBarTypes } from '@ex/module/shared/Theme/services/zen-snackbar.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { TranslatePipe } from '@ex/module/shared/pipes/translate.pipe';
import { SharedService } from '@ex/module/survey-x/data-access/services/shared-service.service';
import { GeneralService } from '@ex/module/core/services/general.service';

@Component({
  selector: 'app-question-properties-drawer',
  templateUrl: './question-properties-drawer.component.html',
  styleUrls: ['./question-properties-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionPropertiesDrawerComponent implements OnInit, OnChanges, AfterContentChecked {

  open: boolean = false;
  direction!: string;
  dir = 'ltr';

  @Input() language: string = "EN";
  ModuleNames = ModuleName;

  supportedLanguages = ['EN', 'AR'];
  newCriteriaTitleText = [{ key: 'EN', name: "" }, { key: 'AR', name: "" }];

  isContentExpanded: any = false;


  @Input() model!: CreatorBase;
  @Input('showBackDrop') showBackDrop = true;
  @Input('reverse') reverse: any;
  @Input('autoClose') autoClose = true;
  @Input('open') set givenState(state: any) {
    this.open = state;
    if (this.reverse)
      document.body.dir == "rtl" || this.direction == "rtl" ? this.dir = 'ltr' : this.dir = 'rtl';
    else if (document.body.dir == "rtl" || this.direction == "rtl")
      this.dir = 'rtl'
  }
  @Output() openChange: EventEmitter<any> = new EventEmitter<any>();

  ////////////////////////////////////////////////////////////////////

  questionType: string = null;

  ////////////////////////////////////////////////////////////////////
  // Shared Question Properties
  isQuestionRequired: boolean = false;
  placeholderText: string = "";
  textPlaceholder: string = "";

  allowSorting: boolean = false;
  sortingType: string = "random"; // random alphabetical
  allowLabels: boolean = false;
  allowColorCoding: boolean = false;

  addCommentBox: boolean = false;
  commentBoxLabel: string = "Add a comment";
  commentBoxPlaceholder: string = this.translationPipe.transform("Enter your comment here...", this.language, this.ModuleNames.SurveyX);

  // Logic Properties
  showQuestionLogic: boolean = false;
  showQuestionLogicConditions: { type: string, question: string, operation: string, answer?: string, isTextAnswer?: boolean }[] = [];

  showChoicesLogic: boolean = false;
  showChoicesLogicConditions: { choice: any, isEditMode?: boolean, conditions: { type: string, question: string, operation: string, answer?: string, isTextAnswer?: boolean }[] }[] = [];
  ////////////////////////////////////////////////////////////////////

  // Short Text
  shortTextMinCharacters: number = 0;
  shortTextMaxCharacters: number = 300;

  ////////////////////////////////////////////////////////////////////

  // Long Text
  longTextMinCharacters: number = 0;
  longTextMaxCharacters: number = 5000;

  ////////////////////////////////////////////////////////////////////

  // Multiple Choices
  allowMultipleSelection: boolean = false;
  multipleSelectionType: string = "select-only";
  multipleSelectionCount: number = 1;
  multipleMinSelectionCount: number = 1;
  multipleMaxSelectionCount: number = 2;
  addOtherAnswerOption: boolean = false;
  addNoneAnswerOption: boolean = false;
  addAllAnswerOption: boolean = false;
  columnsCount: number = 0; // 0 1 2 3
  randomizeAnswers: boolean = false;
  includeNoneOption: boolean = true;
  ////////////////////////////////////////////////////////////////////

  // Dropdown
  dropDownSelectionType: string = "single-selection"; // single-selection multiple-selection  multiple-selection-Search

  ////////////////////////////////////////////////////////////////////

  // Number Input
  setNumberInputMaxCharacters: boolean = false;
  numberInputMaxValue: number = 100;
  numberInputType: string = 'number'; // number percentage currency
  allowDecimalPlaces: boolean = false;
  decimalPlacesValue: number = 0;
  use1000Separator: boolean = false;
  numberInputCurrency: any = 'USD';

  ////////////////////////////////////////////////////////////////////

  // Boolean
  booleanPositiveValue: string;
  booleanNegativeValue: string;

  ////////////////////////////////////////////////////////////////////

  // Date Input
  dateFormat: string = "dd/mm/yyyy";
  datetype: string = "birthday"; // Birthday Range
  birthdayFromYear: Date = null;
  birthdayToYear: Date = null;
  forceTodayDate: boolean = false;
  setMaxDate: boolean = false;
  maxDateValue: Date = null;

  ////////////////////////////////////////////////////////////////////

  // File Upload
  allowedFileTypes: string = "jpg"; // jpg png xps pdf
  forceSpecificFileTypes: boolean = false;
  allowMultipleUplaods: boolean = false;

  ////////////////////////////////////////////////////////////////////

  // Start Rating
  starRatingIcon: string = "fa-star"; // fa-star fa-heart fa-thumbs-up fa-face-smile
  starRatingCount: number = 5; // min 2 max 10

  ////////////////////////////////////////////////////////////////////

  // Rating Scale
  scaleType: string = 'number';
  scaleStepValue: number = 1;
  scaleMinValue: number = 2;
  scaleMaxValue: number = 10;

  ratingLabels: { text: {}, color: string, max: number, min: number }[] = [];

  ////////////////////////////////////////////////////////////////////

  // Image Choice
  allowMultipleImageSelection: boolean = false;
  addOtherImageAnswerOption: boolean = false;

  ////////////////////////////////////////////////////////////////////

  // Matrix
  allowMultipleSelectionPerRow: boolean = false;
  availableQuestions: any[] = [];
  ngOnInit(): void {
    this.language = this.localService.getJsonValue('language')
    this.readIcons();

    this.sharedService.selectedQuestionContent.subscribe((value) => {
      if (value != null && value.htmlElement != null) {
        this.readProperties();

        //Edit mode
        var container = document.getElementById("question");
        container.innerHTML = "";
        container.appendChild(value.htmlElement.parentElement);
      } else {
        //Save if not null
        if (this.questionType != null) {
          this.close();
        }
      }

      this.changeDetectorRef.detectChanges();
    });

    // this.language = this.model.survey.locale.toUpperCase();
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
    }
}

  toggleContent(content: any): void {
    // Check if the content is currently expanded
    if (content.style.maxHeight) {
      // If it is expanded, collapse the content
      content.style.maxHeight = null;
      content.style.marginTop = "0px";

      this.isContentExpanded = false;

    } else {
      // If it is collapsed, expand the content
      content.style.maxHeight = content.scrollHeight + "px";
      content.style.marginTop = "24px";
      this.isContentExpanded = true;

    }
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private generalService: GeneralService,
    private sharedService: SharedService,
    private zenSnackBarService: ZenSnackbarService,
    public datepipe: DatePipe,
    private translationPipe: TranslatePipe,
    private localService: LocalService

  ) { }


  currenciesList: any = this.generalService.currencies;

  onCurrencyChanged(selectedValue: any) {
    this.numberInputCurrency = selectedValue;
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  onChanges(item: any) {
    console.log(item);
    this.saveChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('open: ', this.open);
    if (this.open) {
      if ((this.model != undefined || this.model != null) && (this.model.selectedElement != null || this.model.selectedElement != undefined)) {
        this.readProperties();
      }
    }
  }

  readProperties() {
    //Common Properties
    this.isQuestionRequired = this.model.selectedElement['isRequired'];
    this.placeholderText = this.translationPipe.transform(this.model.selectedElement['placeHolder'], this.language, this.ModuleNames.SurveyX) || this.translationPipe.transform("Enter a number here...", this.language, this.ModuleNames.SurveyX);
    this.textPlaceholder = this.translationPipe.transform(this.model.selectedElement['placeHolder'], this.language, this.ModuleNames.SurveyX) || this.translationPipe.transform("Type your comment here...", this.language, this.ModuleNames.SurveyX);
    this.textPlaceholder = this.translationPipe.transform(this.model.selectedElement['placeHolder'], this.language, this.ModuleNames.SurveyX) || this.translationPipe.transform("Type your comment here...", this.language, this.ModuleNames.SurveyX);
    this.addCommentBox = this.model.selectedElement['showCommentArea'] || false;
    this.commentBoxLabel = this.model.selectedElement['commentText'] || "Add a comment";
    this.commentBoxPlaceholder = this.translationPipe.transform(this.model.selectedElement['commentPlaceholder'], this.language, this.ModuleNames.SurveyX) || this.translationPipe.transform("Enter your comment here...", this.language, this.ModuleNames.SurveyX);

    switch (this.model.selectedElement.getType()) {
      case "text":
        if (this.model.selectedElement['inputType'] === "number") {
          this.questionType = "number-input";

          switch (this.model.selectedElement['numberInputType']) {
            case "currency":
              this.numberInputType = "currency";
              break;

            case "percentage":
              this.numberInputType = "percentage";
              break;

            case "phone-number":
              this.numberInputType = "phone-number";
              break;

            case "number":
              this.numberInputType = "number";
              break;

            default:
              this.numberInputType = "number";
              break;
          }

          //Set the number input properties
          this.setNumberInputMaxCharacters = this.model.selectedElement['maxLength'] == -1 || !this.model.selectedElement['maxLength'] ? false : true;

          this.numberInputMaxValue = this.model.selectedElement['maxLength'] == -1 ? 1 : this.model.selectedElement['maxLength'] ;
          this.numberInputCurrency = this.model.selectedElement['numberInputCurrency'];

        } else if (this.model.selectedElement['inputType'] === "email") {
          this.questionType = "email-input";
        } else if (this.model.selectedElement['inputType'] === "range") {
          this.questionType = "slider";
        } else {
          this.questionType = "short-text";

          //Set the short text properties
          //get the short text input max and min values from validation
          if (this.model.selectedElement['validators'].length > 0) {
            this.shortTextMinCharacters = this.model.selectedElement['validators'][0]['minLength'];
            this.shortTextMaxCharacters = this.model.selectedElement['validators'][0]['maxLength'];
          } else {
            this.shortTextMinCharacters = 0;
            this.shortTextMaxCharacters = 300;
          }
        }
        break;

      case "comment":
        this.questionType = "long-text";
        if (this.model.selectedElement['validators'].length > 0) {
          this.longTextMinCharacters = this.model.selectedElement['validators'][0]['minLength'];
          this.longTextMaxCharacters = this.model.selectedElement['validators'][0]['maxLength'];
        } else {
          this.shortTextMinCharacters = 0;
          this.shortTextMaxCharacters = 5000;
        }
        break;

      case "datepicker":
        this.questionType = "datepicker";

        //Set the date input properties
        this.dateFormat = this.model.selectedElement['dateFormat'];
        this.datetype = this.model.selectedElement['dateType'];

        this.setMaxDate = this.model.selectedElement['max'] ? true : false;
        this.maxDateValue = this.model.selectedElement['max'] || null;

        this.birthdayFromYear = this.model.selectedElement['min'] || null;
        this.birthdayToYear = this.model.selectedElement['max'] || null;

        this.forceTodayDate = this.model.selectedElement['forceTodayDate'];

        break;

      case "radiogroup":
        this.questionType = "multiple-choices";
        this.addOtherAnswerOption = this.model.selectedElement['showOtherItem'];
        this.addNoneAnswerOption = this.model.selectedElement['showNoneItem'];

        this.allowMultipleSelection = false;
        this.columnsCount = this.model.selectedElement['colCount'] || 1;

        this.randomizeAnswers = this.model.selectedElement['choicesOrder'] == "random";
        this.includeNoneOption = this.model.selectedElement['includeNoneOption'];
        break;

      case "checkbox":
        this.questionType = "multiple-choices";
        this.addOtherAnswerOption = this.model.selectedElement['showOtherItem'];
        this.addNoneAnswerOption = this.model.selectedElement['showNoneItem'];
        this.addAllAnswerOption = this.model.selectedElement['showSelectAllItem'];

        this.includeNoneOption = this.model.selectedElement['includeNoneOption'];

        this.allowMultipleSelection = true;

        this.multipleSelectionCount = this.model.selectedElement['maxSelectedChoices'] || this.model.selectedElement['choices'].length;
        this.multipleMinSelectionCount = this.multipleMinSelectionCount >= 1 ? this.multipleMinSelectionCount : 1;
        this.multipleMaxSelectionCount = this.multipleMaxSelectionCount >= 1 ? this.multipleMaxSelectionCount : this.model.selectedElement['choices'].length;

        if (this.model.selectedElement['validators'] != null && this.model.selectedElement['validators'].length > 0) {
          this.multipleSelectionType = "select-range";
          this.multipleMinSelectionCount = this.model.selectedElement['validators'][0]['minCount'];
          this.multipleMaxSelectionCount = this.model.selectedElement['validators'][0]['maxCount'];
        } else {
          this.multipleSelectionType = "select-only";
        }

        this.columnsCount = this.model.selectedElement['colCount'] || 1;
        this.randomizeAnswers = this.model.selectedElement['choicesOrder'] == "random";
        break;

      case "dropdown":
        this.questionType = "dropdown";
        this.dropDownSelectionType = 'single-selection';

        this.allowSorting = this.model.selectedElement['choicesOrder'] != null && this.model.selectedElement['choicesOrder'] != "";

        if (this.allowSorting) {
          switch (this.model.selectedElement['choicesOrder']) {
            case "random":
              this.sortingType = "random";
              break;

            case "asc":
              this.sortingType = "alphabetical";
              break;

            case "desc":
              this.sortingType = "alphabetical";
              break;
          }
        }
        break;

      case "tagbox":
        this.questionType = "dropdown";
        this.dropDownSelectionType = this.model.selectedElement['searchEnabled'] ? 'multiple-selection-Search' : 'multiple-selection';

        this.allowSorting = this.model.selectedElement['choicesOrder'] != null && this.model.selectedElement['choicesOrder'] != "";

        if (this.allowSorting) {
          switch (this.model.selectedElement['choicesOrder']) {
            case "random":
              this.sortingType = "random";
              break;

            case "asc":
              this.sortingType = "alphabetical";
              break;

            case "desc":
              this.sortingType = "alphabetical";
              break;
          }
        }
        break;

      case "rating":

        switch (this.model.selectedElement['rateType']) {
          case "labels":
            this.questionType = "rating-scale";

            //Set the rating scale properties
            this.scaleType = this.model.selectedElement['scaleType'] || "number";
            this.scaleStepValue = this.model.selectedElement['rateStep'] || 1;
            this.scaleMinValue = this.model.selectedElement['rateMin'] || 0;
            this.scaleMaxValue = this.model.selectedElement['rateMax'] || 10;

            this.ratingLabels = this.model.selectedElement['ratingLabels'] || [];
            this.allowLabels = (this.ratingLabels && this.ratingLabels.length > 0) ? true : false;
            break;

          case "enps":
            this.questionType = "enps";
            break;

          case "nps":
            this.questionType = "enps";
            break;

          default:
            this.questionType = "start-rating";

            // this.starRatingIcon = this.model.selectedElement['rateType'];
            this.starRatingCount = this.model.selectedElement['rateCount'];
            this.ratingLabels = this.model.selectedElement['ratingLabels'] || [];
            this.allowLabels = (this.ratingLabels && this.ratingLabels.length > 0) ? true : false;

            switch (this.model.selectedElement['rateType']) {
              case "stars":
                this.starRatingIcon = "fa-star";
                break;
              case "fa-heart":
                this.starRatingIcon = "fa-heart";
                break;
              case "fa-thumbs-up":
                this.starRatingIcon = "fa-thumbs-up";
                break;
              case "smileys":
                this.starRatingIcon = "fa-face-smile";
                break;
            }
            break;
        }
        break;

      case "ranking":
        this.questionType = "ranking";

        this.allowSorting = this.model.selectedElement['choicesOrder'] != null && this.model.selectedElement['choicesOrder'] != "";

        if (this.allowSorting) {
          switch (this.model.selectedElement['choicesOrder']) {
            case "random":
              this.sortingType = "random";
              break;

            case "asc":
              this.sortingType = "alphabetical";
              break;

            case "desc":
              this.sortingType = "alphabetical";
              break;
          }
        }
        break;

      case "imagepicker":
        if (this.model.selectedElement['isIconChoice'])
          this.questionType = "icon-choice";
        else
          this.questionType = "image-choice";

        this.allowMultipleImageSelection = this.model.selectedElement['multiSelect'];
        break;

      case "boolean":
        this.questionType = "binary";

        //Set the binary properties
        this.booleanPositiveValue = this.model.selectedElement['labelTrue'];
        this.booleanNegativeValue = this.model.selectedElement['labelFalse'];
        break;

      case "matrix":
        this.questionType = "matrix";
        this.allowMultipleSelectionPerRow = this.model.selectedElement["isMultiSelect"] || false;
        break;

      // case "matrixdropdown":
      //   this.questionType = "matrix";
      //   this.allowMultipleSelectionPerRow = true;
      //   break;

      case "slider":
        this.questionType = "slider";
        break;

      case "file":
        this.questionType = "file-upload";

        //Set the file upload properties
        this.allowedFileTypes = this.model.selectedElement['acceptedTypes'];
        this.allowMultipleUplaods = this.model.selectedElement['allowMultiple'];

        if (this.model.selectedElement['acceptedTypes'] != null && this.model.selectedElement['acceptedTypes'] != "")
          this.forceSpecificFileTypes = true;
        break;
    }

    this.readLogicProperties();
  }

  convertToDate(dateString: any): Date {
    let d = dateString.split("-");
    let date = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return date;
  }

  close() {
    this.saveChanges();

    this.changeDetectorRef.detectChanges();

    this.reset();
  }


  readLogicProperties() {
    this.showQuestionLogic = false;
    this.showQuestionLogicConditions = [];

    if (this.model.selectedElement['visibleIf'] != null && this.model.selectedElement['visibleIf'] != "") {
      var expression = this.model.selectedElement['visibleIf'];
      this.showQuestionLogicConditions = this.parseVisibleIfExpression(expression);
      this.showQuestionLogic = true;
    } else {
      this.showQuestionLogicConditions = [];
      this.showQuestionLogic = false;
    }

    ////////
    this.showChoicesLogic = false;
    this.showChoicesLogicConditions = [];

    var foundChoiceLogic = false;

    if (this.questionType == "multiple-choices") {
      var choices = this.model.selectedElement['choices'];
      for (var i = 0; i < choices.length; i++) {
        if (choices[i]['visibleIf'] != null && choices[i]['visibleIf'] != "") {
          foundChoiceLogic = true;

          var expression = choices[i]['visibleIf'];

          var conditions = this.parseVisibleIfExpression(expression);
          this.showChoicesLogicConditions.push({ choice: choices[i], isEditMode: false, conditions: conditions });
        } else {
          this.showChoicesLogicConditions.push({ choice: choices[i], isEditMode: false, conditions: [] });
        }
      }
    }

    if (foundChoiceLogic) {
      this.showChoicesLogic = true;
    }
  }

  operations = [
    { key: "empty", value: "Empty" },
    { key: "notempty", value: "Not empty" },
    { key: "=", value: "Equals" },
    { key: "<>", value: "Does not equal" },
    { key: "contains", value: "Contains" },
    { key: "notcontains", value: "Does not contain" },
    { key: ">", value: "Greater than" },
    { key: "<", value: "Less than" },
    { key: ">=", value: "Greater than or equal to" },
    { key: "<=", value: "Less than or equal to" }
  ];

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  private buildEditorItems(operand: Operand): any {
    var res = [];

    if (!this.buildEditorItemsCore(operand, res, "")) {
      res = [];
    }

    res[0].type = "Main";

    return res;
  }

  private buildEditorItemsCore(operand: Operand, res: any, parentConjunction: string): boolean {
    if (operand.getType() == "unary")
      return this.buildEditorItemsAddUnaryOperand(<UnaryOperand><unknown>operand, res);

    if (operand.getType() !== "binary")
      return false;

    var op = <BinaryOperand><unknown>operand;

    if (op.isArithmetic && !op.isConjunction)
      return false;

    if (op.isConjunction)
      return this.buildEditorItemsAddConjunction(op, res, parentConjunction);

    return this.buildEditorItemsAddBinaryOperand(op, res);
  }

  private buildEditorItemsAddUnaryOperand(op: UnaryOperand, res: any): boolean {
    var operator = op.operator;

    if (operator !== "empty" && operator != "notempty")
      return false;

    var operand = op.expression;

    if (operand == null || operand.getType() != "variable")
      return false;

    var questionName = (<Variable>operand).variable;

    var item: any = {};

    item.question = questionName;
    item.operation = operator;
    res.push(item);

    return true;
  }

  private buildEditorItemsAddConjunction(op: BinaryOperand, res: any, parentConjunction: string): boolean {
    var conjunction = op.conjunction;

    if (conjunction == "or" && !!parentConjunction && parentConjunction != conjunction)
      return false;

    if (!this.buildEditorItemsCore(op.leftOperand, res, conjunction))
      return false;

    var conjunctionIndex = res.length;
    if (!this.buildEditorItemsCore(op.rightOperand, res, conjunction))
      return false;

    res[conjunctionIndex].conjunction = op.conjunction;
    res[conjunctionIndex].type = op.conjunction == "and" ? "And" : "Or";

    return true;
  }

  private buildEditorItemsAddBinaryOperand(op: BinaryOperand, res: any): boolean {
    var variableOperand = <Variable><unknown>(this.getOperandByType(op, "variable"));

    var arrayValue = this.getArrayValueFromOperand(op);

    var constOperand = !arrayValue ? <Const>this.getOperandByType(op, "const") : null;

    var item: any = {};

    item.question = variableOperand.variable;
    item.operation = op.leftOperand !== variableOperand ? this.getOppositeOperator(op.operator) : op.operator;

    var operationPar = "";

    switch (item.operation) {
      case "empty":
        operationPar = "empty";
        break;

      case "notempty":
        operationPar = "notempty";
        break;

      case "equal":
        operationPar = "=";
        break;

      case "notequal":
        operationPar = "<>";
        break;

      case "contains":
        operationPar = "contains";
        break;

      case "notcontains":
        operationPar = "notcontains";
        break;

      case "greater":
        operationPar = ">";
        break;

      case "less":
        operationPar = "<";
        break;

      case "greaterorequal":
        operationPar = ">=";
        break;

      case "lessorequal":
        operationPar = "<=";
        break;
    }

    item.operation = operationPar;

    if (!!arrayValue) {
      item.answer = arrayValue;
      item.isTextAnswer = false;
    }

    if (!!constOperand) {
      item.answer = constOperand.correctValue;
      item.isTextAnswer = true;
    }

    res.push(item);

    return true;
  }

  private getArrayValueFromOperand(op: BinaryOperand): Array<any> {
    var arrayOperand = <ArrayOperand><unknown>this.getOperandByType(op, "array");

    if (!arrayOperand || !arrayOperand.values)
      return null;

    var valuesOperand = arrayOperand.values;

    if (!Array.isArray(valuesOperand) || valuesOperand.length == 0)
      return null;

    var res = [];

    for (var i = 0; i < valuesOperand.length; i++) {
      var opConst = valuesOperand[i];

      if (!opConst) continue;

      if (opConst.getType() != "const")
        return null;

      res.push((<Const><unknown>opConst).correctValue);
    }

    if (res.length == 0)
      return null;

    return res;
  }

  private getOppositeOperator(operator: string): string {
    if (operator == "less")
      return "greater";

    if (operator == "greater")
      return "less";

    if (operator == "lessorequal")
      return "greaterorequal";

    if (operator == "greaterorequal")
      return "lessorequal";

    return operator;
  }

  private getOperandByType(op: BinaryOperand, opType: string): Operand {
    if (!op.rightOperand)
      return null;

    if (op.leftOperand.getType() !== opType && op.rightOperand.getType() !== opType)
      return null;

    if (op.leftOperand.getType() == opType && op.rightOperand.getType() == opType)
      return null;

    return op.leftOperand.getType() == opType ? op.leftOperand : op.rightOperand;
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  parseVisibleIfExpression(expression: string) {
    var conditionsParser = new ConditionsParser();
    var operand = conditionsParser.parseExpression(expression);

    if (!operand) {
      this.showSnackBarMessage("Logic is invalid", ZenSnackBarTypes.Error);
      this.changeDetectorRef.detectChanges();

      return [];
    } else {
      return this.buildEditorItems(operand)
    }
  }

  saveLogicProperties() {
    if (this.showQuestionLogic) {
      var expression = "";

      for (var i = 0; i < this.showQuestionLogicConditions.length; i++) {
        var condition = this.showQuestionLogicConditions[i];
        var type = "";

        switch (condition.type) {
          case "Main":
            type = "";
            break;

          case "And":
            type = "and";
            break;

          case "Or":
            type = "or";
            break;
        }

        if (condition.answer == null) {
          expression += type + " {" + condition.question + "} " + condition.operation + " ";
        } else {

          if (condition.isTextAnswer == true) {
            expression += type + " {" + condition.question + "} " + condition.operation + " '" + condition.answer + "' ";
          } else {
            expression += type + " {" + condition.question + "} " + condition.operation + " ['" + condition.answer + "'] ";
          }
        }
      }

      expression = expression.trim();

      //Validate expression
      var conditionsParser = new ConditionsParser();
      var operand = conditionsParser.parseExpression(expression);

      if (!operand) {
        this.showSnackBarMessage("Logic is invalid", ZenSnackBarTypes.Error);
        this.changeDetectorRef.detectChanges();

        return;
      } else {
        this.model.selectedElement['visibleIf'] = expression;

        this.saveDependsOn(expression);
      }

    } else {
      this.model.selectedElement['visibleIf'] = null;
    }
  }

  saveChoicesLogicProperties() {
    if (this.model.selectedElement['choices'] == null)
      return;

    if (this.showChoicesLogic) {
      for (var choiceIndex = 0; choiceIndex < this.showChoicesLogicConditions.length; choiceIndex++) {

        var expression = "";

        for (var i = 0; i < this.showChoicesLogicConditions[choiceIndex].conditions.length; i++) {
          var condition = this.showChoicesLogicConditions[choiceIndex].conditions[i];
          var type = "";

          switch (condition.type) {
            case "Main":
              type = "";
              break;

            case "And":
              type = "and";
              break;

            case "Or":
              type = "or";
              break;
          }

          if (condition.answer == null) {
            expression += type + " {" + condition.question + "} " + condition.operation + " ";
          } else {
            if (condition.isTextAnswer == true) {
              expression += type + " {" + condition.question + "} " + condition.operation + " '" + condition.answer + "' ";
            } else {
              expression += type + " {" + condition.question + "} " + condition.operation + " ['" + condition.answer + "'] ";
            }
          }
        }

        if (this.showChoicesLogicConditions[choiceIndex].conditions.length > 0) {
          expression = expression.trim();

          //Validate expression
          var conditionsParser = new ConditionsParser();
          var operand = conditionsParser.parseExpression(expression);

          if (!operand) {
            this.showSnackBarMessage("Logic is invalid", ZenSnackBarTypes.Error);
            this.changeDetectorRef.detectChanges();
            return;
          } else {
            this.model.selectedElement['choices'][choiceIndex]['visibleIf'] = expression;
          }
        } else {
          this.model.selectedElement['choices'][choiceIndex]['visibleIf'] = null;
        }
      }
    } else {
      for (var i = 0; i < this.model.selectedElement['choices'].length; i++) {
        this.model.selectedElement['choices'][i]['visibleIf'] = null;
      }
    }
  }

  saveDependsOn(expression: string) {
    var conditions = this.parseVisibleIfExpression(expression);

    var parsedConditions = [];
    // depenencyQuestions: [{order: first ,key:Q1, operator: "equals ", answer: option1}, {order: and , key:Q2, operator: contains, answer: "whatsApp"}]
    console.log('conditions: ', conditions);

    for (var i = 0; i < conditions.length; i++) {
      var type = conditions[i].type;

      if (type == "Main") {
        type = "first";
      } else if (type == "And") {
        type = "and";
      } else if (type == "Or") {
        type = "or";
      }

      parsedConditions.push({
        order: type,
        key: conditions[i].question,
        operator: conditions[i].operation,
        answer: conditions[i].answer
      });
    }

    console.log('parsedConditions: ', parsedConditions);
    this.model.selectedElement['dependencyQuestions'] = parsedConditions;
  }

  hasChoices() {
    return this.questionType == "multiple-choices";
  }

  saveChanges() {
    //Save Common Properties
    this.model.selectedElement['isRequired'] = this.isQuestionRequired;
    this.model.selectedElement['placeHolder'] = this.placeholderText;

    this.model.selectedElement['placeHolder'] = this.textPlaceholder;
    this.model.selectedElement['showCommentArea'] = this.addCommentBox;
    this.model.selectedElement['commentText'] = this.commentBoxLabel;
    this.model.selectedElement['commentPlaceholder'] = this.commentBoxPlaceholder;

    switch (this.questionType) {
      case "short-text":
        this.saveShortTextProperties();
        break;

      case "long-text":
        this.saveLongTextProperties();
        break;

      case "multiple-choices":
        this.saveMultipleChoicesProperties();
        break;

      case "dropdown":
        this.saveDropdownProperties();
        break;

      case "number-input":
        this.saveNumberInputProperties();
        break;

      case "binary":
        this.saveBinaryProperties();
        break;

      case "datepicker":
        this.saveDateInputProperties();
        break;

      case "file-upload":
        this.saveFileUploadProperties();
        break;

      case "ranking":
        this.saveRankingProperties();
        break;

      case "matrix":
        this.saveMatrixProperties();
        break;

      case "slider":
        this.saveSliderProperties();
        break;

      case "start-rating":
        this.saveStartRatingProperties();
        break;

      case "rating-scale":
        this.saveRatingScaleProperties();
        break;

      case "image-choice":
        this.saveImageChoiceProperties();
        break;

      case "icon-choice":
        this.saveIconChoiceProperties();
        break;

      case "email-input":
        this.saveEmailInputProperties();
        break;
    }

    this.saveLogicProperties();
    this.saveChoicesLogicProperties();

    this.model.doSave();

    var x = { name: 'testPropName', newValue: 'newValue', oldValue: 'oldValue' }
    this.model.selectedElement.onPropertyChanged.fire(this.model.selectedElement, x);

    this.changeDetectorRef.detectChanges();
  }

  changeBirthdayToYearDate(event: any) {
    console.log('before event: ', event);
    console.log('before birthdayToYear: ', this.birthdayToYear);

    this.birthdayToYear = event;

    console.log('after event: ', event);
    console.log('after birthdayToYear: ', this.birthdayToYear);

    this.onChanges(this.birthdayToYear);
  }


  changeBirthdayFromYearDate(event: any) {
    console.log('before event: ', event);
    console.log('before birthdayFromYear: ', this.birthdayFromYear);

    this.birthdayFromYear = event;

    console.log('after event: ', event);
    console.log('after birthdayFromYear: ', this.birthdayFromYear);

    this.onChanges(this.birthdayFromYear);
  }

  reset(): void {
    this.questionType = null;

    this.isQuestionRequired = false;
    this.placeholderText = "";
    this.textPlaceholder = "";

    this.allowSorting = false;
    this.sortingType = "random";
    this.allowLabels = false;
    this.allowColorCoding = false;

    this.addCommentBox = false;
    this.commentBoxLabel = "Add a comment";
    this.commentBoxPlaceholder = this.translationPipe.transform("Enter your comment here...", this.language, this.ModuleNames.SurveyX);

    this.showQuestionLogic = false;
    this.showQuestionLogicConditions = [];

    this.showChoicesLogic = false;
    this.showChoicesLogicConditions = [];

    this.shortTextMinCharacters = 0;
    this.shortTextMaxCharacters = 300;

    this.longTextMinCharacters = 0;
    this.longTextMaxCharacters = 5000;

    this.allowMultipleSelection = false;
    this.multipleSelectionType = "select-only";
    this.multipleSelectionCount = 1;
    this.multipleMinSelectionCount = 1;
    this.multipleMaxSelectionCount = 2;
    this.addOtherAnswerOption = false;
    this.addNoneAnswerOption = false;
    this.addAllAnswerOption = false;
    this.includeNoneOption = true;
    this.columnsCount = 0;

    this.dropDownSelectionType = "single-selection";

    this.setNumberInputMaxCharacters = false;
    this.numberInputMaxValue = 100;
    this.numberInputType = 'number';
    this.allowDecimalPlaces = false;
    this.decimalPlacesValue = 0;
    this.use1000Separator = false;
    this.numberInputCurrency = 'USD';


    this.dateFormat = "dd/mm/yyyy";
    this.datetype = "birthday";
    this.birthdayFromYear = null;
    this.birthdayToYear = null;
    this.forceTodayDate = false;
    this.setMaxDate = false;
    this.maxDateValue = null;

    this.allowedFileTypes = "jpg";
    this.forceSpecificFileTypes = false;
    this.allowMultipleUplaods = false;

    this.starRatingIcon = "fa-star";
    this.starRatingCount = 5;

    this.scaleType = 'number';
    this.scaleStepValue = 1;
    this.scaleMinValue = 2;
    this.scaleMaxValue = 10;

    this.ratingLabels = [];

    this.allowMultipleImageSelection = false;
    this.addOtherImageAnswerOption = false;

    this.allowMultipleSelectionPerRow = false;
  }

  showSnackBarMessage(message: string, type: ZenSnackBarTypes) {
    this.zenSnackBarService.snackBar({
      message: message,
      type: ZenSnackBarTypes.Error
    });
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  addLabel() {
    if (this.ratingLabels.length >= 5) {
      return;
    }

    var lastLabel = this.ratingLabels[this.ratingLabels.length - 1] || { min: 0, max: 0 };

    if (lastLabel.max != this.scaleMaxValue) {
      var newLabel = {
        text: this.translationPipe.transform('txtLabel', this.language, this.ModuleNames.SurveyX) + ' ' + (this.ratingLabels.length + 1),
        color: "#ECEEF4",
        min: lastLabel.max + 1 > this.scaleMaxValue ? this.scaleMaxValue : lastLabel.max + 1,
        max: lastLabel.max + 2 > this.scaleMaxValue ? this.scaleMaxValue : lastLabel.max + 2
      }
      this.ratingLabels.push(newLabel);
      this.changeDetectorRef.detectChanges();
    }
    else {
      this.showSnackBarMessage("Not enough rate values to create a new label, please check the previous label", ZenSnackBarTypes.Error)
    }



  }

  addStarRatingLabel() {
    if (this.ratingLabels.length >= 10) {
      return;
    }

    if (this.ratingLabels.length >= this.starRatingCount) {
      return;
    }

    var lastLabel = this.ratingLabels[this.ratingLabels.length - 1] || { min: 0, max: 0 };

    if (lastLabel.max != this.starRatingCount) {
      var newLable = {
        text: this.translationPipe.transform("txtLabel", this.language, this.ModuleNames.SurveyX) + (this.ratingLabels.length + 1),
        color: "#AAB7CC",
        min: lastLabel.max + 1 > this.starRatingCount ? this.starRatingCount : lastLabel.max + 1,
        max: lastLabel.max + 2 > this.starRatingCount ? this.starRatingCount : lastLabel.max + 2
      }

      this.ratingLabels.push(newLable);
      this.changeDetectorRef.detectChanges();
    }
    else {
      this.showSnackBarMessage("Not enough rate values to create a new label, please check the previous label", ZenSnackBarTypes.Error)
    }
  }

  labelToBackgroundColor(labelColor: any) {
    switch (labelColor) {
      case "#ECEEF4":
        return "#ECEEF4";

      case "#AAB7CC":
        return "#ECEEF4";

      case "#FF4530":
        return "#FDEFF0";

      case "#FF9600":
        return "#FDF2E4";

      case "#FFC51C":
        return "#FDF9E4";

      case "#B3DD37":
        return "#EFF9EB";

      case "#3EB75B":
        return "#E6F7E4";

      default:
        return "#ECEEF4";
    }
  }

  deleteLabel(label: any) {
    var index = this.ratingLabels.indexOf(label);
    this.ratingLabels.splice(index, 1);
    this.changeDetectorRef.detectChanges();
  }

  addMatrixRow() {
    if (this.model.selectedElement['rows'].length >= 10)
      return;

    var title = this.translationPipe.transform("txtRow ", this.language, this.ModuleNames.SurveyX) + (this.model.selectedElement['rows'].length + 1);
    var vItemValue = new ItemValue(title, title);
    this.model.selectedElement['rows'].push(vItemValue);
    this.changeDetectorRef.detectChanges();
  }

  addMatrixColumn() {
    if (this.model.selectedElement['columns'].length >= 10)
      return;

    var title = this.translationPipe.transform('txtColumn ', this.language, this.ModuleNames.SurveyX) + (this.model.selectedElement['columns'].length + 1);

    if (this.model.selectedElement.getType() == "matrixdropdown") {
      var vMatrixDropdownColumn = new MatrixDropdownColumn(title, title);
      vMatrixDropdownColumn.cellType = "checkbox";
      vMatrixDropdownColumn['choices'] = [""]

      this.model.selectedElement['columns'].push(vMatrixDropdownColumn);
    } else {
      var vItemValue = new ItemValue(title, title);
      this.model.selectedElement['columns'].push(vItemValue);
    }
    this.changeDetectorRef.detectChanges();
  }

  deleteMatrixRow(row: any) {
    var index = this.model.selectedElement['rows'].indexOf(row);
    this.model.selectedElement['rows'].splice(index, 1);
    this.changeDetectorRef.detectChanges();
  }

  deleteMatrixColumn(column: any) {
    var index = this.model.selectedElement['columns'].indexOf(column);
    this.model.selectedElement['columns'].splice(index, 1);
    this.changeDetectorRef.detectChanges();
  }

  //
  deleteImageChoice(choice: any) {
    var index = this.model.selectedElement['choices'].indexOf(choice);
    this.model.selectedElement['choices'].splice(index, 1);
    this.changeDetectorRef.detectChanges();
  }

  addNewImageChoice() {
    if (this.model.selectedElement['choices'].length >= 10) {
      return;
    }

    var newIndex = this.model.selectedElement['choices'].length + 1;
    this.model.selectedElement['choices'] = [
      ...this.model.selectedElement['choices'],
      { value: this.translationPipe.transform('txtImage', this.language, this.ModuleNames.SurveyX) + ' ' + newIndex, imageLink: "" },
    ];

    this.changeDetectorRef.detectChanges();
  }

  //Image and icon choice drag and drop
  choiceDragNDrop(event: any) {
    var choice = this.model.selectedElement['choices'][event.previousIndex];

    this.model.selectedElement['choices'].splice(event.previousIndex, 1);
    this.model.selectedElement['choices'].splice(event.currentIndex, 0, choice);

    this.changeDetectorRef.detectChanges();
  }

  addNewIconChoice() {
    if (this.model.selectedElement['choices'].length >= 10) {
      return;
    }

    var newIndex = this.model.selectedElement['choices'].length + 1;
    this.model.selectedElement['choices'] = [
      ...this.model.selectedElement['choices'],
      { value: this.translationPipe.transform("txtIcon ", this.language, this.ModuleNames.SurveyX) + newIndex, imageLink: "face-laugh-beam" },
    ];

    this.changeDetectorRef.detectChanges();
  }

  handleUpload(files: FileList, choice: any) {
    const file = files.item(0);

    //max file size 500kb
    if (file.size > 500000) {
      this.showSnackBarMessage(this.translationPipe.transform("File size must be less than 500kb", this.language, this.ModuleNames.SurveyX), ZenSnackBarTypes.Error);
      this.changeDetectorRef.detectChanges();
      return;
    }

    //accept only image files
    if (!file.type.startsWith("image/")) {
      this.showSnackBarMessage(this.translationPipe.transform("Only image files are allowed", this.language, this.ModuleNames.SurveyX), ZenSnackBarTypes.Error);
      this.changeDetectorRef.detectChanges();
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      choice.imageLink = reader.result;

      // Fire the question property changed event
      this.model.selectedElement.onPropertyChanged.fire(this.model.selectedElement, "choices");
    };

    this.changeDetectorRef.detectChanges();
  }
  ///
  selectChoiceIcon(icon: string, choice: any) {
    choice.value = icon;
    choice.imageLink = icon;

    this.changeDetectorRef.detectChanges();
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //Done
  saveShortTextProperties() {
    this.model.selectedElement['validators'] = [];

    var validator = new TextValidator();
    validator.minLength = this.shortTextMinCharacters;
    validator.maxLength = this.shortTextMaxCharacters;

    this.model.selectedElement['validators'].push(validator);
  }

  //Done
  saveLongTextProperties() {
    this.model.selectedElement['validators'] = [];

    var validator = new TextValidator();
    validator.minLength = this.longTextMinCharacters;
    validator.maxLength = this.longTextMaxCharacters;

    this.model.selectedElement['validators'].push(validator);
  }

  saveMultipleChoicesProperties() {
    this.columnsCount = this.columnsCount > 0 ? this.columnsCount : 1;
    this.columnsCount = this.columnsCount <= 3 ? this.columnsCount : 3;

    this.model.selectedElement['showOtherItem'] = this.addOtherAnswerOption;
    this.model.selectedElement['showNoneItem'] = this.addNoneAnswerOption;
    this.model.selectedElement['showSelectAllItem'] = this.addAllAnswerOption;
    this.model.selectedElement['colCount'] = this.columnsCount;
    this.model.selectedElement['choicesOrder'] = this.randomizeAnswers ? "random" : "none";
    this.model.selectedElement['includeNoneOption'] = this.includeNoneOption;

    if (this.model.selectedElement.getType() == "radiogroup" && this.allowMultipleSelection) {
      this.model.selectedElement['updateView'] = true;
      // this.model.selectedElement['getType'] = () => 'checkbox';

      let currentquestion = this.model.selectedElement["name"];
      this.model.convertCurrentQuestion('checkbox');



      //This will force the survey to update the UI as soon as the property is changed
      var x = { name: 'testPropName', newValue: 'newValue', oldValue: 'oldValue' }
      this.model.selectedElement.onPropertyChanged.fire(this.model.selectedElement, x);

      this.model.selectElement(this.model.currentPage.getQuestionByName(currentquestion))
      // this.sharedService.setSelectedQuestion(currentquestion)

    }
    else if (this.model.selectedElement.getType() == "checkbox" && !this.allowMultipleSelection) {
      // this.model.selectedElement['getType'] = () => 'radiogroup';
      this.model.selectedElement['updateView'] = true;
      let currentquestion = this.model.selectedElement["name"];
      this.model.convertCurrentQuestion('radiogroup');


      //This will force the survey to update the UI as soon as the property is changed
      var x = { name: 'testPropName', newValue: 'newValue', oldValue: 'oldValue' }
      this.model.selectedElement.onPropertyChanged.fire(this.model.selectedElement, x);


      this.model.selectElement(this.model.currentPage.getQuestionByName(currentquestion))
      // this.sharedService.setSelectedQuestion(currentquestion)

    }

    if (this.model.selectedElement.getType() == "checkbox") {

      // this.model.selectedElement['itemComponent'] = 'survey-checkbox-item';

      // this.model.selectedElement['validators'] = [];

      if (this.multipleSelectionType == "select-range") {
        console.log('min: ', this.multipleMinSelectionCount);
        console.log('max: ', this.multipleMaxSelectionCount);


        if (this.model.selectedElement['validators'].length <= 0) {
          this.model.selectedElement['validators'].push(new AnswerCountValidator(this.multipleMinSelectionCount, this.multipleMaxSelectionCount));

        }
        else {
          this.model.selectedElement['validators'][0].maxCount = this.multipleMaxSelectionCount;
          this.model.selectedElement['validators'][0].minCount = this.multipleMinSelectionCount;
        }
        this.model.selectedElement['maxSelectedChoices'] = this.multipleMaxSelectionCount;
      }
      else {
        this.multipleSelectionCount = this.multipleSelectionCount >= 1 ? this.multipleSelectionCount : this.model.selectedElement['choices'].length;
        this.model.selectedElement['maxSelectedChoices'] = this.multipleSelectionCount;
        this.model.selectedElement['validators'] = [];
      }
      // AngularComponentFactory.Instance.registerComponent("survey-checkbox-item",QuestionPropertiesDrawerComponent );
    }
    else {
      // this.model.selectedElement['itemComponent'] = 'survey-radiogroup-item';
      // this.model.selectedElement['validators'] = [];

      this.model.selectedElement['maxSelectedChoices'] = this.model.selectedElement['choices'].length;
      // AngularComponentFactory.Instance.registerComponent("survey-radiogroup-item",QuestionPropertiesDrawerComponent );

    }

    this.model.selectedElement.getLocalizableString("otherText")['values'] = "Other";
    this.model.selectedElement.getLocalizableString("otherPlaceholder")['values'] = "Please describe";
    // this.model.selectedElement['otherPlaceholder'] = "Please describe";
  }

  saveDropdownProperties() {
    if (this.allowSorting) {
      switch (this.sortingType) {
        case "random":
          this.model.selectedElement['choicesOrder'] = "random";
          break;

        case "alphabetical":
          this.model.selectedElement['choicesOrder'] = "asc";
          break;

        case "reverse-alphabetical":
          this.model.selectedElement['choicesOrder'] = "desc";
          break;
      }
    } else {
      this.model.selectedElement['choicesOrder'] = "";
    }

    var type = this.dropDownSelectionType;

    if (this.model.selectedElement.getType() == "tagbox" && type == "single-selection") {
      this.model.selectedElement['getType'] = () => 'dropdown';

      //This will force the survey to update the UI as soon as the property is changed
      var x = { name: 'testPropName', newValue: 'newValue', oldValue: 'oldValue' }
      this.model.selectedElement.onPropertyChanged.fire(this.model.selectedElement, x);
    } else if (this.model.selectedElement.getType() == "dropdown" && (type == "multiple-selection" || type == "multiple-selection-Search")) {
      this.model.selectedElement['getType'] = () => 'tagbox';

      //This will force the survey to update the UI as soon as the property is changed
      var x = { name: 'testPropName', newValue: 'newValue', oldValue: 'oldValue' }
      this.model.selectedElement.onPropertyChanged.fire(this.model.selectedElement, x);
    }

    //select the new question
    this.model.selectedElement['searchEnabled'] = type == "multiple-selection-Search" ? true : false;
  }

  saveNumberInputProperties() {
    this.model.selectedElement['numberInputType'] = this.numberInputType || "number";
    this.model.selectedElement['maxLength'] = this.numberInputMaxValue;
    this.model.selectedElement['numberInputCurrency'] = this.numberInputCurrency;
  }

  //Done
  saveBinaryProperties() {

    this.model.selectedElement['labelTrue'] = this.booleanPositiveValue;
    this.model.selectedElement['labelFalse'] = this.booleanNegativeValue;
  }

  saveDateInputProperties() {
    this.model.selectedElement['dateFormat'] = this.dateFormat || "dd/mm/yyyy";
    this.model.selectedElement['dateType'] = this.datetype || "birthday";
    this.model.selectedElement['forceTodayDate'] = this.forceTodayDate || false;

    if (this.datetype == "birthday") {
      this.model.selectedElement['min'] = this.birthdayFromYear || null;
      this.model.selectedElement['max'] = this.birthdayToYear || null;
    }
    else {

      if (this.setMaxDate) {
        this.model.selectedElement['min'] = "";
        this.model.selectedElement['max'] = this.maxDateValue || null;
      } else if (this.model.selectedElement['max'] != null) {
        this.model.selectedElement['min'] = "";
        this.model.selectedElement['max'] = "";
      }
    }
  }

  //Done
  saveFileUploadProperties() {
    this.model.selectedElement['allowMultiple'] = this.allowMultipleUplaods;

    if (this.forceSpecificFileTypes)
      this.model.selectedElement['acceptedTypes'] = this.allowedFileTypes;
    else
      this.model.selectedElement['acceptedTypes'] = "";
  }

  //Done
  saveRankingProperties() {
    if (this.allowSorting) {
      switch (this.sortingType) {
        case "random":
          this.model.selectedElement['choicesOrder'] = "random";
          break;

        case "alphabetical":
          this.model.selectedElement['choicesOrder'] = "asc";
          break;

        case "reverse-alphabetical":
          this.model.selectedElement['choicesOrder'] = "desc";
          break;
      }
    } else {
      this.model.selectedElement['choicesOrder'] = "";
    }
  }

  saveMatrixProperties() {
    this.model.selectedElement["isMultiSelect"] = this.allowMultipleSelectionPerRow || false;
  }

  saveSliderProperties() { }

  saveStartRatingProperties() {
    if (this.allowColorCoding) {
      this.model.selectedElement['scaleColorMode'] = "colored";
    } else {
      this.model.selectedElement['scaleColorMode'] = "";
    }

    this.model.selectedElement['ratingLabels'] = this.ratingLabels || [];
    this.model.selectedElement['rateCount'] = this.starRatingCount > 10 ? 10 : this.starRatingCount;

    switch (this.starRatingIcon) {
      case "fa-star":
        this.model.selectedElement['rateType'] = "stars";
        break;
      case "fa-heart":
        this.model.selectedElement['rateType'] = "fa-heart";
        break;
      case "fa-thumbs-up":
        this.model.selectedElement['rateType'] = "fa-thumbs-up";
        break;
      case "fa-face-smile":
        this.model.selectedElement['rateType'] = "smileys";
        break;
    }
  }

  saveRatingScaleProperties() {
    this.model.selectedElement['ratingLabels'] = this.ratingLabels || [];
    this.model.selectedElement['rateType'] = "labels";
    this.model.selectedElement['rateStep'] = 1;
    this.model.selectedElement['rateMin'] = this.scaleMinValue < 0 ? 0 : this.scaleMinValue;
    this.model.selectedElement['rateMax'] = this.scaleMaxValue > 10 ? 10 : this.scaleMaxValue;
  }

  saveImageChoiceProperties() {
    this.model.selectedElement['multiSelect'] = this.allowMultipleImageSelection;
    this.model.selectedElement['isMultiSelect'] = this.allowMultipleImageSelection;
  }

  saveIconChoiceProperties() {
    this.model.selectedElement['multiSelect'] = this.allowMultipleImageSelection;
    this.model.selectedElement['isMultiSelect'] = this.allowMultipleImageSelection;
  }

  saveEmailInputProperties() { }
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  filterIcons(filter: string) {
    this.filteredIcons = this.faList.filter((icon) => {
      return icon.toLowerCase().includes(filter.toLowerCase());
    });
  }

  filteredIcons: string[] = [];

  faList = []

  readIcons() {
    const data = jsonFile.default;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key].styles.includes("regular"))
          this.faList.push(key);
      }
    }

    this.filteredIcons = this.faList;
  }

  getTranslatedTitle(title: any) {
    if (typeof title == 'string') {
      return title;
    }
    else {
      return title.default
    }
  }


  logData(x){
    console.log(x);
  }
}
