import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, pairwise, shareReplay, switchMap, tap } from 'rxjs/operators';
import { QuestionComment } from 'src/app/core/data-models/question-comments';
import { listAnimation } from 'src/app/shared/animations/allAnimations';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { CommentsService, QuestionCommentPagedDto } from '@ex/wellbeing/comments';
import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';

@Component({
  selector: 'wb-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  animations: [listAnimation]
})
export class CommentListComponent extends WbBaseComponent implements OnInit {

  @Input()
  questionKey;

  @Input()
  surveyKey;

  @Input()
  tenantKey;

  @Input()
  companyKey;

  @Input()
  language = "EN";

  @Input()
  fetch: boolean = false;

  @Input()
  filters: AnalyticsFilter

  offset = 0;
  maxResults = 10;

  totalRecords = 0;

  saved = {
    items: [],
    totalCount: 0
  }

  comments$: Observable<QuestionCommentPagedDto>;

  loadMoreAction = new BehaviorSubject<number>(this.offset);
  loadMoreAction$ = this.loadMoreAction.asObservable();

  constructor(private commentsService: CommentsService, private analyticsService: AnalyticsService) {
    super()
  }

  ngOnInit(): void {

    this.comments$ = this.loadMoreAction$.pipe(
      switchMap(offset => {

;
        return this.commentsService.getListAsycByRequest({
          tenantKey: this.tenantKey,
          companyKey: this.companyKey,
          surveyKey: this.surveyKey,
          questionKey: this.questionKey,
          skipCount: offset,
          maxResultCount: this.maxResults,
          filters: [... this.analyticsService.mapGlobalFilters(this.filters), ...this.filters.chartFilters?.map(x => x) || []],
        }).pipe(
          tap(() => this.LoaderService.displayLoader(500)),
          map(response => {

            this.saved.items = [...this.saved.items, ...response.items];
            this.saved.totalCount = response.totalCount;


            return this.saved;
          }),
          finalize(() => this.LoaderService.hideLoader())
        );

      }),
    )

  }

  showMoreComments(totalCount) {
    ;
    return totalCount > ((this.offset + 1) * this.maxResults);
  }

  fetchMoreComments() {
    this.offset = (this.offset + 1);
    this.loadMoreAction.next(this.offset);
  }

}
