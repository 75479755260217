<div class="bg-white flex flex-col p-5 rounded-lg">
  <cdk-tree [dataSource]="treeList" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node">

      <div *DemoAccount="'Button'" (click)="isDemo?'':filterEntity(node)"
        class="group rounded-md flex flex-row  items-center innerNode cursor-pointer hover:bg-opacity-10 hover:bg-exp leading-10 h-10 w-full"
        [ngClass]="{'bg-exp bg-opacity-20 hover:bg-opacity-20': node == selectedEntity,'justify-start' :row, 'justify-between' :!row }">
        <button mat-icon-button disabled></button>
        <ng-container *ngIf="!row">
          <div class="flex flex-row items-center justify-start">
            <span class="text-zen text-sm font-medium ">{{node.name}}</span>
          </div>
        </ng-container>
        <ng-container *ngTemplateOutlet="row; context:{$implicit: node}">
        </ng-container>
      </div>
      <!-- use a disabled button to provide padding for tree leaf -->
    </cdk-nested-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="example-tree-node">
      <div *DemoAccount="'Button'" (click)="isDemo?'':filterEntity(node)"
        class="group rounded-md flex flex-row items-center innerNode cursor-pointer hover:bg-opacity-10 hover:bg-exp leading-10 h-10 w-full"
        [ngClass]="{'bg-exp bg-opacity-20 hover:bg-opacity-20': node == selectedEntity ,'justify-start' :row, 'justify-between' :!row }">
        <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name" cdkTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'remove ' : 'add'}}
          </mat-icon>
        </button>
        <ng-container *ngIf="!row">
          <div class="flex flex-row justify-start items-center">
            <span class="text-zen text-sm font-medium ">{{node.name}}</span>
          </div>
        </ng-container>
        <ng-container *ngTemplateOutlet="row; context:{$implicit: node}">
        </ng-container>
      </div>
      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>
