import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Tenant } from '../data-models/tenant';
import { map, take } from 'rxjs/operators';
import { GlobalsService } from './globals.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  coreAPI: string = 'https://localhost:44385/api/';
  constructor(private db: AngularFireDatabase, public afAuth: AngularFireAuth, private globals: GlobalsService, private http: HttpClient) {
    this.coreAPI = this.globals.coreAPI;
   }

  // Create new Tenant
  createNewTenant(tenant: Tenant) {
    return this.db.list('/Tenants/').push(tenant).key;
  }

  updateTenant(tenant: Tenant) {
    return this.db.object('/Tenants/' + tenant.key).update(tenant);
  }

  // Fetch Tenant
  fetchTenant(tenantKey: string) {
    return this.db.object<Tenant>('/Tenants/' + tenantKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }

  // Fetch Tenant
  fetchTenantForAccOverview(tenantKey: string) {
    return this.db.object<Tenant>('/Tenants/' + tenantKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        )
      );
  }

  fetchAllTenants() {
    return this.db.list<Tenant>('/Tenants/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }


  createCoreTenant(tenant: Tenant){
    return this.http.post(this.coreAPI + 'Tenant/CreateTenant', tenant);
  }

  
  updateCoreTenant(tenant: Tenant){
    return this.http.post(this.coreAPI + 'Tenant/UpdateTenant', tenant);
  }


}
