import { updateQuestionHeaderHTML } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";

/**
 * Updates the HTML for a Rating Scale question.
 * @param options - The options for updating the Rating Scale question HTML.
 */
export function updateRatingScaleQuestionHTML(options: any) {
  updateQuestionHeaderHTML(options, "Rating Scale", "fa-chart-simple");
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;
  body.innerHTML = "";
  body.className = "question-body mt-5";

  var constraints = [];

  constraints = options.question['ratingLabels'] || [];

  var filteredConstraints = filterRange(constraints, options.question['rateMin'], options.question['rateMax']);
  var filledConstraints = fillMissingConstraints(filteredConstraints, options.question['rateMin'], options.question['rateMax']);
  var uniqueConstraints = getUniqueConstraints(filledConstraints);

  var container = document.createElement("div");
  container.className = "flex w-full gap-1 justify-center";

  for (var i = 0; i < uniqueConstraints.length; i++) {
    var constraint = uniqueConstraints[i];

    var constraintContainer = document.createElement("div");
    constraintContainer.className = "flex flex-col items-center gap-2";

    var itemsDiv = document.createElement("div");
    itemsDiv.className = "flex w-full gap-1 justify-center";

    for (var j = constraint.min; j <= constraint.max; j++) {
      var item = document.createElement("button");
      item.className = "px-1 w-auto px-4 py-3 nps-item";
      item.innerHTML = j.toString();
      item.style.backgroundColor = constraint.color;
      itemsDiv.appendChild(item);
    }

    var constraintText = document.createElement("label");
    constraintText.className = "label-gray whitespace-normal";
    constraintText.innerHTML = constraint.text.default? constraint.text.default : constraint.text;


    constraintContainer.appendChild(itemsDiv);
    constraintContainer.appendChild(constraintText);

    container.appendChild(constraintContainer);
    body.appendChild(container);
  }
}

/**
 * Filters the range of constraints based on the given minimum and maximum values.
 * @param constraints - The constraints to filter.
 * @param min - The minimum value.
 * @param max - The maximum value.
 * @returns The updated constraints within the range.
 */
export function filterRange(constraints: any, min: number, max: number) {
  const updatedConstraints = constraints.map(constraint => {
    // If the constraint's minimum value is less than the minimum range, update it to the minimum range
    if (constraint.min < min) {
      constraint.min = min;
    }

    // If the constraint's maximum value is greater than the maximum range, update it to the maximum range
    if (constraint.max > max) {
      constraint.max = max;
    }

    return constraint;
  });

  return updatedConstraints;
}

/**
 * Retrieves unique constraints from the given list of constraints.
 * @param constraints - The list of constraints.
 * @returns The unique constraints.
 */
export function getUniqueConstraints(constraints: any) {
  const uniqueConstraints = [];

  for (let i = 0; i < constraints.length; i++) {
    const foundConstraint = uniqueConstraints.find(c => c.min === constraints[i].min && c.max === constraints[i].max);

    // If the constraint is not found in the unique constraints list, add it
    if (!foundConstraint) {
      uniqueConstraints.push(constraints[i]);
    }
  }

  return uniqueConstraints;
}

/**
 * Fills in the missing constraints within the given range.
 * @param constraints - The list of constraints.
 * @param min - The minimum value of the range.
 * @param max - The maximum value of the range.
 * @returns The filled constraints.
 */
export function fillMissingConstraints(constraints: any, min: number, max: number) {
  const defaultColor = "#EFF1F6";
  const defaultLabel = "";

  const filledConstraints = [];
  let currentMin = null;
  let currentMax = null;
  let currentText = null;
  let currentColor = null;

  for (let i = min; i <= max; i++) {
    const foundConstraint = constraints.find(c => i >= c.min && i <= c.max);

    if (foundConstraint) {
      // If a constraint is found, push any pending current constraint and add the found constraint
      if (currentMin !== null && currentMax !== null) {
        filledConstraints.push({ min: currentMin, max: currentMax, text: currentText, color: currentColor });
        currentMin = null;
        currentMax = null;
        currentText = null;
        currentColor = null;
      }
      filledConstraints.push(foundConstraint);
    } else {
      // If no constraint is found, update the current constraint values
      if (currentMin === null) {
        currentMin = i;
        currentText = defaultLabel;
        currentColor = defaultColor;
      }
      currentMax = i;
    }
  }

  // Push the last pending current constraint if there is one
  if (currentMin !== null && currentMax !== null) {
    filledConstraints.push({ min: currentMin, max: currentMax, text: currentText, color: currentColor });
  }

  return filledConstraints;
}
