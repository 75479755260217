import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Questionnaire } from '../data-models/questionnaire';
import { map, take } from 'rxjs/operators';
import { LeaverAnalysis } from '../data-models/leaver-analysis';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  constructor(private db: AngularFireDatabase, public afAuth: AngularFireAuth) { }

  updateQuestionnaire(questionnaire: Questionnaire){
    return this.db.object('/Questionnaires/' + questionnaire.key).update(questionnaire);
  }

  fetchQuestionnaire(questionnaireKey: string) {
    return this.db.object<Questionnaire>('/Questionnaires/' + questionnaireKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }

  fetchQuestionnaires() {
    return this.db.list<Questionnaire>('/Questionnaires/', ref => ref.orderByChild('isExit').equalTo(true)).snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      )
    );
  }

  fetchAllQuestionnaires() {
    return this.db.list<Questionnaire>('/Questionnaires/').snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      )
    );
  }

  getQuestionnaire(questionnaireKey: string, tenantKey: string, selectedEntityKey: string, startTime?: string, endTime?: string) {
    if (startTime) {


      return this.db.list<any>('/QuestionnaireAnalysis/' + tenantKey + '/' + selectedEntityKey + '/' + questionnaireKey, ref => ref.orderByChild('submittedQueryDate')
        .startAt(startTime).endAt(endTime)).snapshotChanges()
        .pipe(
          map(actions =>
            actions.map(a => ({ key: a.key, ...a.payload.val() }))
          )
        );
    } else {
      return this.db.list<any>('/QuestionnaireAnalysis/' + tenantKey + '/' + selectedEntityKey + '/' + questionnaireKey + '/').snapshotChanges()
        .pipe(
          map(actions =>
            actions.map(a => ({ key: a.key, ...a.payload.val() }))
          )
        );
    }
  }

  getQuestionnaireTest(tenantKey: string, companyKey: string, questionnaireKey: string, leaverKey: string) {
    return this.db.object<Questionnaire>('/QuestionnaireAnalysis/' + tenantKey + '/' + companyKey + '/' + questionnaireKey + '/' + leaverKey).snapshotChanges()
    .pipe(
      map(a => ({ key: a.key, ...a.payload.val() })
      ), take(1)
    );
  }


  updateLeaverAnalysisTest(leaverAnalysis: LeaverAnalysis) {
    return this.db.object<LeaverAnalysis>('/QuestionnaireAnalysis/' + leaverAnalysis.tenantKey + '/' + leaverAnalysis.companyKey +
      '/' + leaverAnalysis.questionnaireKey + '/' + leaverAnalysis.key).update(leaverAnalysis);
  }


}
