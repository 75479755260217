import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUpload } from '@ex/module/core/data-models/file-upload';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { finalize } from 'rxjs/operators';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';


export enum FileFormat {
  File = 'file',
  Base64 = 'base64',
}

@Component({
  selector: 'zen-input-image',
  templateUrl: './zen-input-image.component.html',
  styleUrls: ['./zen-input-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZenInputImageComponent,
    },
  ],
  animations: [ChildrenAnimation]
})

export class ZenInputImageComponent extends BaseComponent implements ControlValueAccessor {
  currentFileUpload!: FileUpload;
  constructor(private sanitizer: DomSanitizer, private storage: AngularFireStorage) {
    super();
  }
  fileOver: boolean = false;

  @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;

    let files = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      files.push({ file });
    }
    if (files.length > 0) {
      console.log(files);
      this.selectLogo(null, files[0].file);
    }
  }


  @Input() storagePath;
  @Input() language = 'EN';
  @Input() accept;
  @Input() size = 2; // in MB
  @Input() invalid: boolean = false;
  @Input() format = FileFormat.File
  uploading: boolean = false;
  file: any;
  fileName: any;
  imgSrc: any;
  isDisabled: boolean = false;
  onChange: any = () => { };
  onTouch: any = () => { };
  writeValue(file: any) {
    if (file !== undefined && this.file !== file) {
      this.file = file;
    }
  }

  registerOnChange(fn: any) {

    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  handleChange(option) {
    this.file = option;
    this.onChange(option);
  }


  selectLogo(evt: any, files?) {
    let f = files ? files : evt.target.files[0]
    if (f.size > 1048576 * this.size)
      return this.SnackbarService.error(this.localize('txtImageSize', this.language, this.ModuleNames.Common).replace('#size', this.size));

    this.file = files ? files : evt.target.files[0]
    evt.target.value = null;
    if (this.accept.indexOf(this.file.type) < 0)
      return this.SnackbarService.error(this.localize('txtImageFormat', this.language, this.ModuleNames.Common));


    if (this.file) {
      if (this.storagePath)
        this.upload(this.file)
      else if (this.format == FileFormat.File)
        this.handleChange(this.file)
      else if (this.format == FileFormat.Base64)
        this.getBase64(this.file).then(
          (data: any) => {
            if (data.length) {
              this.imgSrc = data;
              this.uploading = true;
              this.handleChange(data)
            }
          }
        );
    }
  }
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  logData(e) {
    console.log(e);
  }


  upload(file: any): void {
    try {

      this.currentFileUpload = new FileUpload(file);
      let fileName = (file.name + ' - ' + Number((new Date().getTime() / 100).toFixed(0)));
      const filePath = `${this.storagePath}/${fileName}`;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, this.currentFileUpload.file);
      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          this.addSubscription(storageRef.getDownloadURL().subscribe(downloadURL => {
            this.handleChange(downloadURL)
          }));
        })
      ).subscribe();
    } catch (ex) {
    }
  }

}
