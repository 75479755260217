import { Component, OnInit } from '@angular/core';
import { AppInjector } from 'src/app/app.module';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { SeperationTypesService } from '../../data-access/services/seperation-types.service';
import { ChildrenAnimation, listAnimation } from 'src/app/shared/animations/allAnimations';
import { Entity } from 'src/app/core/data-models/Entity';
import { AnalyticsFilter } from '../../data-access/services/analytics.service';
import { Subject } from 'rxjs';
import { ExitExperienceCodesService } from '../../data-access/services/exit-experience-codes.service';

export interface AnalysisComponentModel {
  tenantKey: string,
  surveyKey: string,
  companyKey: string,
  language: string,
  selectedEntity: Entity
}

@Component({
  selector: 'app-exit-base',
  template: `
    <p>
      exit-base works!
    </p>
  `,
  styles: [
  ],
  animations: [listAnimation, ChildrenAnimation]
})
export class ExitBaseComponent extends BaseComponent {
  filtersLoaded = new Subject<AnalyticsFilter>();
  filtersLoaded$ = this.filtersLoaded.asObservable();
  AnalysisComponentData: AnalysisComponentModel;
  SeperationTypes = AppInjector.get(SeperationTypesService)
  ExperienceCodesSerivce = AppInjector.get(ExitExperienceCodesService);

  constructor() {
    super();
  }

  extractNameLanguage(eG: any) {
    return this.LanguageService.extractNameLanguage(eG, this.AnalysisComponentData.language).replaceAll('[CompanyName]', this.LanguageService.extractNameLanguage(this.AnalysisComponentData.selectedEntity?.name, this.AnalysisComponentData.language));
  }



  validateStringLength(text: string, maxLength: number, extract: boolean = false): boolean {
    let stringLength = 0;
    if (!extract) {
      stringLength = this.returnstringLength(text, maxLength);
    } else
      stringLength = this.returnstringLength(this.ExtractPipe.transform(text, this.AnalysisComponentData.language), maxLength);
    if (stringLength > maxLength) {
      this.SnackbarService.error(this.localize('txt_wrong_text_count', this.AnalysisComponentData.language, this.ModuleNames.Exit));
      return false;
    }
    if (stringLength <= maxLength) {
      return true;
    }
  }

  returnstringLength(text: string, maxLength: number): number {
    if (text?.length <= maxLength) return text.length;
    if (text?.length > maxLength) {
      return text.length;
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  replaceCompanyToken(text: string, entity: Entity) {
    return text.replace("[CompanyName]", this.ExtractPipe.transform(entity.name, this.AnalysisComponentData.language))
  }

}
