import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';
import { GeneralService } from '../../core/services/general.service';
import { LanguageService } from '../../core/services/language.service';
import { LocalService } from '../../core/services/local.service';
import { ModuleName } from '../enums/ModuleName';
import { LanguagePipe } from '../pipes/language.pipe';
declare var $: any;

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  language;


  constructor(private authenticationService: AuthenticationService,
    private languageService: LanguageService,private languagePipe:LanguagePipe,
    private router: Router, private localService: LocalService
  ) { }
ModuleNames=ModuleName
  ngOnInit(): void {
    let localLanguage = this.localService.getJsonValue('language');
    if (!localLanguage) {
      this.language = 'EN';
      this.localService.setJsonValue('language', this.language);

    } else {
      this.language = localLanguage;
      // this.language = 'FR';
    }
    this.languageService.language = this.language;
    // Fetch Tenant Info
    this.isArabic();


    setTimeout(() => {
      this.endSession();
    }, 5000);
  }
  isArabic() {
    if (this.languagePipe.transform(this.language)) {
           $("body").removeClass('ltr')
      $("body").addClass('rtl').attr("dir", "rtl");

    }
    else {
      $("body").removeClass('rtl')
      $("body").addClass('ltr').attr("dir", "ltr")

    }
  }

  endSession() {
    this.authenticationService.logout();

  }


}
