import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class FormLibraryFiltersService {

  private category = new BehaviorSubject<any>({});
selectedCategory= this.category.asObservable();

private type = new BehaviorSubject<any>({});
selectedType = this.type.asObservable();


constructor(){}

setCategory(Category:any){
  this.category.next(Category);
}
setType(Type:any){
  this.type.next(Type);
}

}
