import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { ModalComponent } from '@ex/module/shared/modal/modal.component';
import { environment } from 'src/environments/environment';
import { Direction } from '@angular/cdk/bidi';
import { LocalService } from '@ex/module/core/services/local.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { SendSurveyEmail } from '@ex/module/survey-x/data-access/models/send-survey-email';
import { SharedService } from '@ex/module/survey-x/data-access/services/shared-service.service';

@Component({
  selector: 'app-share-conformation-modal',
  templateUrl: './share-conformation-modal.component.html',
  styleUrls: ['./share-conformation-modal.component.scss']
})
export class ShareConformationModalComponent extends ModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Input() language: string = 'EN';

  @Output() openChange = new EventEmitter();
  @Output() shareSurvey = new EventEmitter();

  selectedRespondents: any;
  formId: any;
  form: any;
  sub: any;
  sendSurveyEmail: SendSurveyEmail;

  direction: Direction = "ltr"

  link: string = "";
  emailURL = environment.emailURL;

  constructor(private sharedService: SharedService, public datePipe: DatePipe, private surveysService: SurveysService, private localService: LocalService) {
    super();
  }

  ModuleNames = ModuleName;

   ngOnInit() {

    // this.language = this.localService.getJsonValue('language')

    // if (this.language == "AR")
    //   this.direction = "rtl"
    // else
    //   this.direction = "ltr"


    // let self = this;
    // this.sendSurveyEmail = {
    //   link: "",
    //   body: "",
    //   emails: [],
    //   subject: "",
    //   email: "",
    //   surveyId: "",
    //   tenantKey: "",
    //   tenantName: ""
    // };

    // this.sharedService.selectedFormId.subscribe((formId: any) => {

    //   this.formId = formId;
    // })

    // this.sub = await this.surveysService.getSurveyWithoutResponses(this.formId);
    // this.form = this.sub.query;

    // this.sharedService.selectedRespondents.subscribe((respondents: any) => {

    //   this.selectedRespondents = respondents;
    // })



    // if (self.formId || self.form) {
    //   self.link = self.emailURL + '/survey-x/language/' + self.formId;

    //   self.sendSurveyEmail.link = self.link;


    //   self.sendSurveyEmail.surveyId = self.formId;

    //   self.sendSurveyEmail.tenantKey = self.form.tenantKey;
    //   self.sendSurveyEmail.tenantName = self.form.tenantKey;

    //   self.sendSurveyEmail.subject = self.form.invitationSubject;
    //   self.sendSurveyEmail.body = self.form.invitationBody;

    //   self.sendSurveyEmail.body = self.sendSurveyEmail.body.replace("[FormName]", self.form.title);
    //   self.sendSurveyEmail.body = self.sendSurveyEmail.body.replace("[Deadline]", self.datePipe.transform(self.form.endDate, 'dd/MM/YYYY'));
    //   self.sendSurveyEmail.body = self.sendSurveyEmail.body.replace("[CompanyName]", self.form.tenantName);
    //   self.sendSurveyEmail.body = self.sendSurveyEmail.body.replace("[CompanyName]", self.form.tenantName);
    //   self.sendSurveyEmail.body = self.sendSurveyEmail.body.replace("[FormType]", self.form.typeName);
    //   self.sendSurveyEmail.body = self.sendSurveyEmail.body.replace("[FormType]", self.form.typeName);
    // }

  }

  onSubmit() {
    // try {
    //   this.selectedRespondents.forEach(async element => {
    //     if (this.formId) {
    //       this.sendSurveyEmail.emails = [];
    //       this.sendSurveyEmail.emails.push(element.email);
    //       this.surveysService.sendSurveyByEmail(this.sendSurveyEmail).subscribe((res) => { console.log('email sent') });
    //     }
    //   });
    // } catch (e) {
    //   console.log(e);
    // }

    this.shareSurvey.emit();
    this.close();
  }

  close() {
    this.openChange.emit(false);
  }
}
