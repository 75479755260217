<z-modal [open]="open" >
  <ng-container header>
    <div class="flex flex-row gap-2 items-center">
      <i class="fal fa-bell text-white bg-exp  shadow-xl rounded-full h-10 w-10 text-xl text-center leading-10 "></i>
      <h4 class="text-2xl font-bold text-zen">{{"txt_send_reminders"| translate:language:ModuleNames.Wellbeing }}
      </h4>
    </div>
  </ng-container>
  <ng-container body>
    <div *ngIf="reminderCount"
      class="flex flex-row items-center justify-center h-15 bg-zen bg-opacity-10 rounded-md mb-5">
      <h5  class="text-zen text-xs flex flex-row items-center gap-4 leading-15">{{replaceCount('txtRemindersBulkSend', reminderCount)}} </h5>
    </div>
    <zen-editor *ngIf="surveyData.reminderEmail"
      (reset)="resetTemplate()" [language]="language"
      [emailObject]="surveyData.reminderEmail"></zen-editor>
  </ng-container>
  <ng-container footer>
    <a (click)="close.emit(true)"
      class="btn cancel  text-center  rounded-md px-8 py-4 uppercase text-lg font-medium">{{'btn_close'|translate:language:ModuleNames.Wellbeing}}</a>
    <a *DemoAccount="'Button';let isDemo = isDemo; " (click)="isDemo?'': update()"
      class="btn exp  rounded-md px-8 py-4 uppercase text-lg font-medium">{{(isSendEmails?'txtSendEmails':'btn_save_reminder_template')|translate:language:ModuleNames.Wellbeing}}</a>
  </ng-container>
</z-modal>
