import { Injectable } from '@angular/core';
import { EntityHierarchyDto } from '@ex/core/entities';
import { Entity } from 'src/app/core/data-models/Entity';
import { EntityGroup } from 'src/app/core/data-models/entity-group';

/**
 * 
 *  Entity Hierarcy 
 *  EntityHierarchyDto -> Holds information of the entity group
 *  EntityHierarchyDto -> Entities -> Holds the information of the entities that are of type entity group.
 */

@Injectable({
  providedIn: null
})
export class GenerateEntityHierarchyService {

  constructor() { }
  getHierarchies(entities: Entity[]) {
    console.log(entities);
    let newEntities: EntityHierarchyDto[] = []
    for (let index = 0; index < entities.length; index++) {
      let childEntities = entities.filter(x => x.parentKey == entities[index].key)
      if (childEntities.length) {
        let element: EntityHierarchyDto = {
          key: entities[index].key,
          name: entities[index].name,
          selectedEntityKey: null,
          entities: childEntities,
        };
        newEntities.push(element);

      }
    }

    console.log(newEntities);

    return newEntities;
  }

  getEnityGroupWithEntities(entities: Entity[], entityGroups: EntityGroup[]) {

    const results = [];

    for (let group of entityGroups) {
      results.push({
        entities: entities.filter(x => x.entityGroupKey == group.groupKey),
        name: group.name,
        key: group.groupKey,
        parentKey: group.parentKey,
        selectedEntityKey: null,
      } as EntityHierarchyDto)
    }

    return results;
  }
}
