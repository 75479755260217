<div class="flex flex-row justify-center">
    <div class="flex flex-col items-stretch w-min">
        <div class="flex flex-row  items-center justify-center p-3 -ml-1 -mr-1">
            <div class="px-1" *ngFor="let step of steps; let n = index">
                <div class="rounded text-center text-lg" [ngClass]="{'bg-opacity-10 text-zen':question.answer != n, 'text-white':question.answer == n, 'leading-14 w-14 h-14':export,'leading-20 w-20 h-20':!export}" [class]="step.color">
                    {{n}}</div>
            </div>
        </div>
        <div *ngIf="legends || legends?.length" class="flex flex-row  items-center justify-center px-3 h-2.5 mb-5 -ml-1 -mr-1">
            <div  class="px-1" [style.width.%]="legend.steps / steps.length * 100"
                *ngFor="let legend of legends; let n = index">
                <div
                    class="h-2.5 border-r border-l border-b border-zen border-opacity-20 flex flex-row items-end justify-center">
                    <span
                        class="text-xs text-zen-lite bg-white transform translate-y-2 px-3 upperFirstLetter">{{legend.name}}</span>
                </div>
            </div>
        </div>
        <ng-content select="[legends]"></ng-content>
    </div>
</div>
