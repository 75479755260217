import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { ChildrenAnimation, routeInnerAnimations } from 'src/app/shared/animations/allAnimations';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';
import { Direction } from '@angular/cdk/bidi';
import { LocalService } from '@ex/module/core/services/local.service';
import { StatusTypes } from '@ex/module/survey-x/utils/enums/StatusTypes';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { CDKPortalComponent, DATA_TOKEN } from '@ex/module/shared/Theme/services/zen-cdk.service';

@Component({
  selector: 'app-close-now-view',
  templateUrl: './close-now-view.component.html',
  styleUrls: ['./close-now-view.component.scss'],
  animations: [ChildrenAnimation, routeInnerAnimations],

})
export class CloseNowViewComponent extends BaseComponent implements OnInit, CDKPortalComponent {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();
  selectedForm: any;
  language = 'EN';
  constructor(@Inject(DATA_TOKEN) private data: any) { super(); }
  ngOnInit(): void {
    this.selectedForm = this.data.selectedForm;
    this.language = this.data.language;
  }




  closeModal() {
    this.close.emit(true);
  }

  confirmClosure() {
    this.results.emit(true);
  }

}
