import { Component, OnInit } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';

@Component({
  selector: 'app-survey-closed',
  templateUrl: './survey-closed.component.html',
  styleUrls: ['./survey-closed.component.scss'],
  animations: [ChildrenAnimation]
})
export class SurveyClosedComponent implements OnInit {

  language = 'EN';
  ModuleNames = ModuleName;


  constructor() { }

  ngOnInit(): void {  
  }

}
