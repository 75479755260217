import { IKeyResolver } from "src/app/core/services/key-resolver.service";
import { WellbeingKeyResolverNames } from "./wellbeing-key-resolver-names";
import { CriteriaResolverService } from "../data-access/services/criteria-resolver.service";
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root',
})
export class SurveyCriteriaKeyResolver implements IKeyResolver {
    name: string = WellbeingKeyResolverNames.SurveyCriterias;

    /**
     *
     */
    constructor(private criteriaResolverService: CriteriaResolverService) {

    }

    resolve(key: string, language: string, options?: any): string {
        return this.criteriaResolverService.extract(options, key, language);
    }
}
