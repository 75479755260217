import { translateText } from "../../../features/survey/survey.component";

export function updateMatrixQuestionHTML(options: any, locale?: any) {
    var body = options.htmlElement.querySelector('[role="presentation"]');
    if (!body) body = options.htmlElement as HTMLDivElement;

    var commentArea = body.querySelector(".sd-question__comment-area");
    var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
    if (!commentBox)
        commentBox = body?.querySelector(".sd-comment.sd-input");

    body.innerHTML = "";
    body.className = "question-body mt-5 w-full";

    var inputTitle = document.createElement("p");
    inputTitle.className = "input-title mb-4 text-start";
    inputTitle.innerHTML = translateText("Select the appropriate answer for each option:", locale);

    body.appendChild(inputTitle, body.childNodes[0]);

    const question = options.question;
    question.value = question.value || {};

    var newMatrixQuestionContent = document.createElement("div");
    newMatrixQuestionContent.className = "flex flex-col gap-3 w-full bg-white rounded-md border border-zen-paleWhite mb-5 overflow-auto";

    var matrixQuestionTable = document.createElement("table");
    matrixQuestionTable.className = "table-auto overflow-scroll w-full";

    var matrixQuestionTableHeader = document.createElement("thead");
    var matrixQuestionTableHeaderRow = document.createElement("tr");

    matrixQuestionTableHeaderRow.appendChild(document.createElement("th"));

    for (var i = 0; i < options.question.columns.length; i++) {
        var matrixQuestionTableHeaderRowCell = document.createElement("th");
        matrixQuestionTableHeaderRowCell.className = "h-10 p-2 border-b border-r border-zen-paleWhite text-zen-primarydefault text-[0.6875rem] font-medium uppercase";

        if (i == 0)
            matrixQuestionTableHeaderRowCell.className += " border-l border-zen-paleWhite";

        matrixQuestionTableHeaderRowCell.innerHTML = options.question.columns[i].text;

        matrixQuestionTableHeaderRow.appendChild(matrixQuestionTableHeaderRowCell);
    }

    matrixQuestionTableHeader.appendChild(matrixQuestionTableHeaderRow);

    var matrixQuestionTableBody = document.createElement("tbody");

    for (let i = 0; i < options.question.rows.length; i++) {
        var matrixQuestionTableBodyRow = document.createElement("tr");
        matrixQuestionTableBodyRow.className = "border-t border-r border-zen-paleWhite";

        var matrixQuestionTableBodyRowCell = document.createElement("th");
        matrixQuestionTableBodyRowCell.className = "w-50 min-w-40 h-10 text-zen-primarydefault text-[0.6875rem] font-medium uppercase text-start";
        matrixQuestionTableBodyRowCell.style.paddingInlineStart = "0.5rem";
        matrixQuestionTableBodyRowCell.innerHTML = options.question.rows[i].text;

        matrixQuestionTableBodyRow.appendChild(matrixQuestionTableBodyRowCell);

        for (let j = 0; j < options.question.columns.length; j++) {
            var matrixQuestionTableBodyRowCell = document.createElement("td");
            matrixQuestionTableBodyRowCell.className = "h-10 border-b border-l border-r border-zen-paleWhite text-center pt-2";

            const matrixQuestionTableBodyRowCellInput = document.createElement("input");
            matrixQuestionTableBodyRowCellInput.className = "checkbox";
            matrixQuestionTableBodyRowCellInput.setAttribute("type", "radio");
            matrixQuestionTableBodyRowCellInput.setAttribute("name", "matrix-" + options.question.id + "-" + i);
            matrixQuestionTableBodyRowCellInput.setAttribute("value", options.question.columns[j].value);

            if (question.value[options.question.rows[i].value] != undefined && question.value[options.question.rows[i].value] == options.question.columns[j].value) {
                matrixQuestionTableBodyRowCellInput.checked = true;
            }

            matrixQuestionTableBodyRowCellInput.addEventListener("click", () => {
                question.value[options.question.rows[i].value] = options.question.columns[j].value;
                options.question.value = question.value;
            });

            matrixQuestionTableBodyRowCell.appendChild(matrixQuestionTableBodyRowCellInput);
            matrixQuestionTableBodyRow.appendChild(matrixQuestionTableBodyRowCell);
        }

        matrixQuestionTableBody.appendChild(matrixQuestionTableBodyRow);
    }

    matrixQuestionTable.appendChild(matrixQuestionTableHeader);
    matrixQuestionTable.appendChild(matrixQuestionTableBody);

    newMatrixQuestionContent.appendChild(matrixQuestionTable);

    body.appendChild(newMatrixQuestionContent);

    var max = 300;

    if (commentBox != null) {
        // Add character counter
        var counter = body?.querySelector('.comment-counter');
        if (!counter) {
            counter = document.createElement("span");
            counter.className = "text-end text-gray-500 text-sm comment-counter";
            counter.style.textAlign = "end";
            counter.innerHTML = commentBox.value.length + "/" + max;
            body.appendChild(counter);
        }

        // Insert commentBox before counter
        body.insertBefore(commentBox, counter);

        commentBox.addEventListener("input", function () {
            // Update character count on input
            counter.innerHTML = commentBox.value.length + "/" + max;
        });

        // Set maxLength for commentBox after inserting it into the DOM
        commentBox.setAttribute("maxLength", max);
    }
}

export function updateMatrixCheckboxsQuestionHTML(options: any, locale?: any) {
    var body = options.htmlElement.querySelector('[role="presentation"]');
    if (!body) body = options.htmlElement as HTMLDivElement;

    var commentArea = body.querySelector(".sd-question__comment-area");
    var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
    if (!commentBox)
        commentBox = body?.querySelector(".sd-comment.sd-input");

    body.innerHTML = "";
    body.className = "question-body mt-5 w-full";

    var inputTitle = document.createElement("p");
    inputTitle.className = "input-title mb-4 text-start";
    inputTitle.innerHTML = translateText("Check answers that apply for each option:", locale);

    body.appendChild(inputTitle, body.childNodes[0]);

    const question = options.question;
    question.value = question.value || {};

    var newMatrixQuestionContent = document.createElement("div");
    newMatrixQuestionContent.className = "flex flex-col gap-3 w-full bg-white rounded-md border border-zen-paleWhite overflow-auto mb-5";

    var matrixQuestionTable = document.createElement("table");
    matrixQuestionTable.className = "table-auto overflow-scroll w-full";

    var matrixQuestionTableHeader = document.createElement("thead");
    var matrixQuestionTableHeaderRow = document.createElement("tr");

    matrixQuestionTableHeaderRow.appendChild(document.createElement("th"));

    for (var i = 0; i < options.question.columns.length; i++) {
        var matrixQuestionTableHeaderRowCell = document.createElement("th");
        matrixQuestionTableHeaderRowCell.className = "h-10 p-2 border-b border-r border-zen-paleWhite text-zen-primarydefault text-[0.6875rem] font-medium uppercase";

        if (i == 0)
            matrixQuestionTableHeaderRowCell.className += " border-l border-zen-paleWhite";

        matrixQuestionTableHeaderRowCell.innerHTML = options.question.columns[i].text;

        matrixQuestionTableHeaderRow.appendChild(matrixQuestionTableHeaderRowCell);
    }

    matrixQuestionTableHeader.appendChild(matrixQuestionTableHeaderRow);

    var matrixQuestionTableBody = document.createElement("tbody");

    for (let i = 0; i < options.question.rows.length; i++) {
        var matrixQuestionTableBodyRow = document.createElement("tr");
        matrixQuestionTableBodyRow.className = "border-t border-r border-zen-paleWhite";

        var matrixQuestionTableBodyRowCell = document.createElement("th");
        matrixQuestionTableBodyRowCell.className = "w-48 min-w-40 h-10 text-zen-primarydefault text-[0.6875rem] font-medium uppercase text-start";
        matrixQuestionTableBodyRowCell.style.paddingInlineStart = "0.5rem";
        matrixQuestionTableBodyRowCell.innerHTML = options.question.rows[i].text;

        matrixQuestionTableBodyRow.appendChild(matrixQuestionTableBodyRowCell);

        for (let j = 0; j < options.question.columns.length; j++) {
            var matrixQuestionTableBodyRowCell = document.createElement("td");
            matrixQuestionTableBodyRowCell.className = "h-10 border-b border-l border-r border-zen-paleWhite text-center pt-2";

            const matrixQuestionTableBodyRowCellInput = document.createElement("input");
            matrixQuestionTableBodyRowCellInput.className = "checkbox";
            matrixQuestionTableBodyRowCellInput.setAttribute("type", "checkbox");
            matrixQuestionTableBodyRowCellInput.setAttribute("name", "matrix-" + options.question.id + "-" + i);
            matrixQuestionTableBodyRowCellInput.setAttribute("value", options.question.columns[j].value);

            if (question.value[options.question.rows[i].value] != undefined) {
                if (question.value[options.question.rows[i].value].includes(options.question.columns[j].value)) {
                    matrixQuestionTableBodyRowCellInput.checked = true;
                }
            }

            matrixQuestionTableBodyRowCellInput.addEventListener("click", () => {
                printValues(options.question.rows[i], options.question.columns[j], matrixQuestionTableBodyRowCellInput.checked, question);
                options.question.value = question.value;
            });

            matrixQuestionTableBodyRowCell.appendChild(matrixQuestionTableBodyRowCellInput);
            matrixQuestionTableBodyRow.appendChild(matrixQuestionTableBodyRowCell);
        }

        matrixQuestionTableBody.appendChild(matrixQuestionTableBodyRow);
    }

    matrixQuestionTable.appendChild(matrixQuestionTableHeader);
    matrixQuestionTable.appendChild(matrixQuestionTableBody);

    newMatrixQuestionContent.appendChild(matrixQuestionTable);

    body.appendChild(newMatrixQuestionContent);

    var max = 300;

    if (commentBox != null) {
        // Add character counter
        var counter = body?.querySelector('.comment-counter');
        if (!counter) {
            counter = document.createElement("span");
            counter.className = "text-end text-gray-500 text-sm comment-counter";
            counter.style.textAlign = "end";
            counter.innerHTML = commentBox.value.length + "/" + max;
            body.appendChild(counter);
        }

        // Insert commentBox before counter
        body.insertBefore(commentBox, counter);

        commentBox.addEventListener("input", function () {
            // Update character count on input
            counter.innerHTML = commentBox.value.length + "/" + max;
        });

        // Set maxLength for commentBox after inserting it into the DOM
        commentBox.setAttribute("maxLength", max);
    }
}

export function printValues(row: any, column: any, isChecked: any, options: any): void {
    if (options.value[row.value] != undefined) {
        if (options.value[row.value].includes(column.value)) {
            options.value[row.value].splice(options.value[row.value].indexOf(column.value), 1);
        } else {
            options.value[row.value].push(column.value);
        }
    } else {
        options.value[row.value] = [column.value];
    }
}
