import { Component, Input, OnInit } from '@angular/core';

export interface MenuButton {
  index?: number;
  icon?: string;
  name: string;
  description?: string;
}

@Component({
  selector: 'menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent implements OnInit {
  @Input() active: boolean = false;

  @Input() enabled: boolean = true;
  @Input() icon;
  @Input() tourKey = '';

  constructor() {}
  ngOnInit(): void {}
}
