import type { ExcelBulkUploadRequestDto, ExcelOptionsDto, ExcelSheetDownloadDto, ExcelSheetValidateRequestDto, ExcelValidationResponseDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExcelUploadService {
  apiName = 'Default';

  getExcelSheetDownload = (tenantKey: string, companyKey: string) =>
    this.restService.request<any, ExcelSheetDownloadDto>({
      method: 'GET',
      url: '/api/exit/excel-upload/download',
      params: { tenantKey, companyKey },
    },
    { apiName: this.apiName });

  getExcelSheetDownloadWithConfig = (tenantKey: string, companyKey: string, options: ExcelOptionsDto) =>
    this.restService.request<any, ExcelSheetDownloadDto>({
      method: 'POST',
      url: `/api/exit/excel-upload/download/${tenantKey}/${companyKey}`,
      body: options,
    },
    { apiName: this.apiName });

  initiateBulkUpload = (request: ExcelBulkUploadRequestDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/exit/excel-upload/bulk-upload',
      body: request,
    },
    { apiName: this.apiName });

  validateExcelSheet = (request: ExcelSheetValidateRequestDto) =>
    this.restService.request<any, ExcelValidationResponseDto>({
      method: 'POST',
      url: '/api/exit/excel-upload/validate',
      body: request,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
