import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SurveyxRoutingModule } from './surveyx-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SurveyxComponent } from './surveyx.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AnalyticalCriteriaPageComponent } from './features/builder/analytical-criteria-page/analytical-criteria-page.component';
import { AssignWeightsComponent } from './features/builder/assign-weights/assign-weights.component';
import { InitialSetupComponent } from './features/builder/initial-setup/initial-setup.component';
import { LanguagesPageComponent } from './features/builder/languages-page/languages-page.component';
import { QuestionsBuilderPageComponent } from './features/builder/questions-builder-page/questions-builder-page.component';
import { RespondentsPageComponent } from './features/builder/respondents-page/respondents-page.component';
import { SharePageComponent } from './features/builder/share-page/share-page.component';
import { FormAnalysisComponent } from './features/form-analysis/form-analysis.component';
import { FormBankComponent } from './features/form-bank/form-bank.component';
import { FormCreatorComponent } from './features/library/form-creator/form-creator.component';
import { RespondentsComponent } from './features/respondents/respondents.component';
import { LoaderInterceptor } from './utils/_interceptors/loader-interceptor.interceptor';
import { EmailTemplateComponent } from './ui/templates/email-template/email-template.component';
import { ReminderTemplateComponent } from './ui/templates/reminder-template/reminder-template.component';
import { FormLibraryTableComponent } from './features/library/form-library-table/form-library-table.component';
import { NewFormBtnComponent } from './ui/surveys/new-form-btn/new-form-btn.component';
import { ChangeCategoryModalComponent } from './ui/builder/change-category-modal/change-category-modal.component';
import { FormBuilderHeaderComponent } from './ui/builder/form-builder-header/form-builder-header.component';
import { CustomFormBuilderComponent } from './ui/builder/custom-form-builder/custom-form-builder.component';
import { StringEditorComponent, SurveyCreatorModule } from 'survey-creator-angular';
import { SurveyModule } from "survey-angular-ui";
import { QuestionsBuilderTabComponent } from './ui/builder/questions-builder-tab/questions-builder-tab.component';
import { QuestionPropertiesDrawerComponent } from './ui/builder/question-properties-drawer/question-properties-drawer.component';
import { LogicPropertiesComponent } from './ui/builder/question-properties-drawer/logic-properties/logic-properties.component';
import { ChoicesLogicPropertiesComponent } from './ui/builder/question-properties-drawer/choices-logic-properties/choices-logic-properties.component';
import { AddQuestionModalComponent } from './ui/builder/add-question-modal/add-question-modal.component';
import { ChangeQuestionTypeModalComponent } from './ui/builder/change-question-type-modal/change-question-type-modal.component';
import { CurrencyDropdownComponent } from './ui/builder/currency-dropdown/currency-dropdown.component';
import { QuestionsSidebarComponent } from './ui/builder/questions-sidebar/questions-sidebar.component';
import { ZenDesignerSurveyComponent } from './ui/builder/zen-designer-survey/zen-designer-survey.component';
import { ZenSvcPageComponent } from './ui/builder/zen-svc-page/zen-svc-page.component';
import { NewCategoryModalComponent } from './ui/builder/new-category-modal/new-category-modal.component';
import { EditCategoryModalComponent } from './ui/builder/edit-category-modal/edit-category-modal.component';
import { ZenXEditorComponent } from './ui/builder/zen-editor/zen-editor.component';
import { SxRespondentPoolComponent } from './ui/builder/respondent-pool/respondent-pool.component';
import { AddLocaleModalComponent } from './ui/builder/add-locale-modal/add-locale-modal.component';
import { LanguagesTabSidebarComponent } from './ui/builder/languages-tab-sidebar/languages-tab-sidebar.component';
import { LanguagesTabComponent } from './ui/builder/languages-tab/languages-tab.component';
import { TemplateLibraryModalComponent } from './ui/builder/template-library-modal/template-library-modal.component';
import { ShareConformationModalComponent } from './ui/builder/share-conformation-modal/share-conformation-modal.component';
import { LiveFormViewComponent } from './features/library/live-form-view/live-form-view.component';
import { MainInfoComponent } from './features/library/main-info/main-info.component';
import { RespondentAnswersViewComponent } from './features/respondent-answers-view/respondent-answers-view.component';
import { RespondentInformationPageComponent } from './features/respondent-information-page/respondent-information-page.component';
import { RespondentViewComponent } from './features/respondent-view/respondent-view.component';
import { CloseNowViewComponent } from './ui/library/close-now-view/close-now-view.component';
import { ShareFormByLinkComponent } from './ui/library/share-form-by-link/share-form-by-link.component';
import { ShareSurveyComponent } from './ui/library/share-survey/share-survey.component';
import { ShareSuccessModalComponent } from './ui/builder/share-success-modal/share-success-modal.component';
import { SurveyxLandingComponent } from './features/surveyx-landing/surveyx-landing.component';
import { FormClosedSuccessfullyViewComponent } from './ui/library/form-closed-successfully-view/form-closed-successfully-view.component';
import { ConfirmSubmissionModalComponent } from '../surveys/survey-x-questionnaire/ui/confirm-submission-modal/confirm-submission-modal.component';
import { SurveyPreviewExportComponent } from './ui/pdf-export/survey-preview-export/survey-preview-export.component';
import { SurveyXQuestionnaireModule } from '../surveys/survey-x-questionnaire/survey-x-questionnaire.module';
import { SurveyXSharedModule } from '../surveys/survey-x-questionnaire/survey-x-shared.module';
import { ResponseRateCardComponent } from './ui/library/response-rate-card/response-rate-card.component';
import { RespondentAnswersReportComponent } from './ui/pdf-export/respondent-answers-report/respondent-answers-report.component';
import { SXTreeOptionsComponent } from './ui/sx-tree-options/sx-tree-options.component';
import { QuestionAnalysisComponent } from './features/form-analysis/question-analysis/question-analysis.component';
import { RespondentAnalysisComponent } from './features/form-analysis/respondent-analysis/respondent-analysis.component';
import { CommentsAnalysisComponent } from './features/form-analysis/comments-analysis/comments-analysis.component';
import { FeedbackAnalysisComponent } from './features/form-analysis/feedback-analysis/feedback-analysis.component';
import { SxBaseComponent } from './ui/sx-base/sx-base.component';
import { SurveyCriteriaService } from './data-access/services/survey-criteria.service';
import { CriteriaResolverService } from './data-access/services/criteria-resolver.service';
import { KEY_RESOLVER_TOKEN } from '../core/services/key-resolver.service';
import { SurveyCriteriaKeyResolver } from './utils/survey-criteria-key-resolver';
import { SJQuestionFilterPipe, SJSectionFilterPipe, SurveyjsDataService } from './data-access/services/surveyjs-data.service';
import { SxDrawerComponent } from './ui/sx-drawer/sx-drawer.component';
import { IndividualResponsesModalComponent } from './ui/analysis/individual-responses-modal/individual-responses-modal.component';
import { DrilldownComponent } from './ui/analysis/drilldown/drilldown.component';
import { QuestionChartComponent } from './ui/analysis/question-chart/question-chart.component';
import { SpecialQuestionAnswersDrawerComponent } from './ui/analysis/special-question-answers-drawer/special-question-answers-drawer.component';
import { SxSmallModalHeaderComponent } from './ui/sx-small-modal-header/sx-small-modal-header.component';
import { RespondentChartComponent } from './ui/analysis/respondent-chart/respondent-chart.component';
import { QuestionCommentsComponent } from './ui/analysis/question-comments/question-comments.component';
import { FeedbackCardComponent } from './ui/analysis/feedback-card/feedback-card.component';
import { RespondentsTableComponent } from './features/form-analysis/respondents-table/respondents-table.component';
import { ExportReportComponent } from './features/form-analysis/export-report/export-report.component';
import { DependentQuestionComponent } from './ui/analysis/dependent-question/dependent-question.component';
import { AddEmailTemplateComponent } from './ui/builder/add-email-template/add-email-template.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SxFiltersComponent } from './ui/sx-filters/sx-filters.component';
import { NativeSxFiltersComponent } from './ui/sx-filters/native-sx-filters/native-sx-filters.component';
import { SxFiltersFormComponent } from './ui/sx-filters/sx-filters-form/sx-filters-form.component';
import { SjsTranslatePipe } from './utils/pipes/sjs-translate.pipe';
import { TextToNumberPipe } from './utils/pipes/text-to-number.pipe';
import { ChartColorsAssignComponent } from './ui/analysis/chart-colors-assign/chart-colors-assign.component';
import { MaxNumberDirective } from './utils/directives/max-number.directive';
import { CustomDatepickerComponent } from './ui/custom-datepicker/custom-datepicker.component';
import { MinNumberDirective } from './utils/directives/min-number.directive';


@NgModule({
  declarations: [
    SurveyxComponent,
    RespondentInformationPageComponent,
    RespondentAnswersViewComponent,
    LiveFormViewComponent,
    FormAnalysisComponent,
    InitialSetupComponent,
    AnalyticalCriteriaPageComponent,
    QuestionsBuilderPageComponent,
    RespondentsPageComponent,
    SharePageComponent,
    AssignWeightsComponent,
    LanguagesPageComponent,
    FormCreatorComponent,
    RespondentsComponent,
    MainInfoComponent,
    FormBankComponent,
    RespondentViewComponent,
    CloseNowViewComponent,
    ShareFormByLinkComponent,
    ReminderTemplateComponent,
    EmailTemplateComponent,
    FormLibraryTableComponent,
    NewFormBtnComponent,
    ChangeCategoryModalComponent,
    FormBuilderHeaderComponent,
    CustomFormBuilderComponent,
    QuestionsBuilderTabComponent,
    QuestionPropertiesDrawerComponent,
    LogicPropertiesComponent,
    ChoicesLogicPropertiesComponent,
    AddQuestionModalComponent,
    ChangeQuestionTypeModalComponent,
    CurrencyDropdownComponent,
    QuestionsSidebarComponent,
    ZenDesignerSurveyComponent,
    ZenSvcPageComponent,
    LanguagesTabSidebarComponent,
    LanguagesTabComponent,
    AddLocaleModalComponent,
    NewCategoryModalComponent,
    EditCategoryModalComponent,
    ZenXEditorComponent,
    SxRespondentPoolComponent,
    TemplateLibraryModalComponent,
    ShareConformationModalComponent,
    ShareSurveyComponent,
    ShareSuccessModalComponent,
    SurveyxLandingComponent,
    CloseNowViewComponent,
    FormClosedSuccessfullyViewComponent,
    SurveyPreviewExportComponent,
    ResponseRateCardComponent,
    RespondentAnswersReportComponent,
    SXTreeOptionsComponent,
    QuestionAnalysisComponent,
    RespondentAnalysisComponent,
    CommentsAnalysisComponent,
    FeedbackAnalysisComponent,
    SxBaseComponent,
    SJQuestionFilterPipe,
    QuestionChartComponent,
    SpecialQuestionAnswersDrawerComponent,
    SxDrawerComponent,
    IndividualResponsesModalComponent,
    DrilldownComponent,
    SxSmallModalHeaderComponent,
    RespondentChartComponent,
    QuestionCommentsComponent,
    FeedbackCardComponent,
    RespondentsTableComponent,
    ExportReportComponent,
    DependentQuestionComponent,
    AddEmailTemplateComponent,
    SJSectionFilterPipe,
    SxFiltersComponent,
    NativeSxFiltersComponent,
    SxFiltersFormComponent,
    TextToNumberPipe,
    ChartColorsAssignComponent,
    MaxNumberDirective,
    CustomDatepickerComponent,
    MinNumberDirective,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
    SurveyCreatorModule,
    SurveyModule,
    SurveyxRoutingModule,
    SurveyXSharedModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    SurveyCriteriaService,
    SurveyjsDataService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoaderInterceptor,
    //   multi: true,
    // },
    DatePipe,
    SjsTranslatePipe
  ]
})
export class SurveyxModule {

  static forRoot(): ModuleWithProviders<SurveyxModule> {

    return {
      ngModule: SurveyxModule,
      providers: [
        CriteriaResolverService,
        {
          provide: KEY_RESOLVER_TOKEN,
          useClass: SurveyCriteriaKeyResolver,
          multi: true
        },
      ]
    }

  }
}
