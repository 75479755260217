import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[zenButton]'
})
export class ButtonStyleDirective {
  constructor(private el: ElementRef) { }

  @Input('styles') set styles(styles) {
    let classNames = "btn rounded flex flex-row gap-2 items-center";
    if (styles?.includes('exp'))
      classNames += ' exp';
    else if (styles?.includes('secondary'))
      classNames += ' cancel';

    else if (styles?.includes('green'))
      classNames += ' green';

    else if (styles?.includes('red'))
      classNames += ' red';
    else if (styles?.includes('ghost'))
      classNames += ' ghost';

    else if (styles?.includes('aiIcon'))
      classNames += ' aiIcon';

    else if (styles?.includes('delete'))
      classNames += ' bg-zen-red bg-opacity-5 text-zen-red';


    if (styles?.includes('disabled'))
      classNames += ' pointer-events-none opacity-70';



    if (styles?.includes('icon-')) {
      let newStyles = styles.split("icon-")
      classNames += ` h-${newStyles[1]} w-${newStyles[1]} leading-${newStyles[1]} justify-center`;
    }
    else {
      classNames += ' px-5';
    }
    if (styles?.includes('height-')) {
      let newStyles = styles.split("height-")
      classNames += ` h-${newStyles[1]} leading-${newStyles[1]}`;
    }

    this.el.nativeElement.classList.add(...classNames.split(' '));
  };



}
