import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExitExperienceCodesService {

  NotApplicable = "NA";
  VeryPoorExperience = "VPOOREXP";
  PoorExperience = "POOREXP";
  FairExperience = "FAIREXP";
  GoodExperience = "GOODEXP";
  VeryGoodExperience = "VGOODEXP";

  config = [{
    key: this.NotApplicable,
    color: "#AFB0B4",
    className: 'bg-zen-grey',
    icon:'fa-ban'
  }, {
    key: this.VeryPoorExperience,
    color: "#FF4530",
    className: 'bg-zen-red',
    icon:'fa-face-pouting'
  },
  {
    key: this.PoorExperience,
    color: "#FF9600",
    className: 'bg-zen-orange',
    icon:'fa-face-worried '
  },
  {
    key: this.FairExperience,
    color: "#FFC51C",
    className: 'bg-zen-yellow',
    icon:'fa-meh'
  },
  {
    key: this.GoodExperience,
    color: "#B3DD37",
    className: 'bg-zen-lightgreen',
    icon:'fa-face-smile-relaxed'
  },
  {
    key: this.VeryGoodExperience,
    color: "#3EB75B",
    className: 'bg-zen-green',
    icon:'fa-face-laugh-beam'
  }]

  constructor() { }

  resolveChartColor(value: any) {

    if (value >= 20 && value < 40) {
      return this.getColorByCode(this.VeryPoorExperience);
    }
    else if (value >= 40 && value < 60) {
      return this.getColorByCode(this.PoorExperience);
    }
    else if (value >= 60 && value < 80) {
      return this.getColorByCode(this.FairExperience);
    }
    else if (value >= 80 && value < 90) {
      return this.getColorByCode(this.GoodExperience);
    }
    else if (value >= 90 && value <= 100) {
      return this.getColorByCode(this.VeryGoodExperience);
    }

    return "N/A";
  }

  getExperienceCode(percentage: number) {

    if (percentage >= 20 && percentage < 40) {
      return this.VeryPoorExperience;
    }
    else if (percentage >= 40 && percentage < 60) {
      return this.PoorExperience;
    }
    else if (percentage >= 60 && percentage < 80) {
      return this.FairExperience;
    }
    else if (percentage >= 80 && percentage < 90) {
      return this.GoodExperience;
    }
    else if (percentage >= 90 && percentage <= 100) {
      return this.VeryGoodExperience;
    }

    return "N/A";
  }

  getColorByCode(code: string) {

    switch (code) {
      case this.VeryPoorExperience:
        return this.config.find(x => x.key == this.VeryPoorExperience)?.color;
      case this.PoorExperience:
        return this.config.find(x => x.key == this.PoorExperience)?.color;
      case this.FairExperience:
        return this.config.find(x => x.key == this.FairExperience)?.color;
      case this.GoodExperience:
        return this.config.find(x => x.key == this.GoodExperience)?.color;
      case this.VeryGoodExperience:
        return this.config.find(x => x.key == this.VeryGoodExperience)?.color;
    }

  }
  getClassByCode(code: string) {

    switch (code) {
      case this.VeryPoorExperience:
        return this.config.find(x => x.key == this.VeryPoorExperience)?.className;
      case this.PoorExperience:
        return this.config.find(x => x.key == this.PoorExperience)?.className;
      case this.FairExperience:
        return this.config.find(x => x.key == this.FairExperience)?.className;
      case this.GoodExperience:
        return this.config.find(x => x.key == this.GoodExperience)?.className;
      case this.VeryGoodExperience:
        return this.config.find(x => x.key == this.VeryGoodExperience)?.className;
      default:
        return this.config.find(x => x.key == this.NotApplicable)?.className;
    }

  }


  resolveChartClass(value: any, icon:boolean = false) {
    if (value == 1) {
      return this.getClassByCode(this.VeryPoorExperience);
    }
    else if (value == 2) {
      return this.getClassByCode(this.PoorExperience);
    }
    else if (value == 3) {
      return this.getClassByCode(this.FairExperience);
    }
    else if (value == 4) {
      return this.getClassByCode(this.GoodExperience);
    }
    else if (value == 5) {
      return this.getClassByCode(this.VeryGoodExperience);
    }
    else
      return this.getClassByCode(this.NotApplicable);
  }


}
