import type { AnalysisFilterRequestDto, ChartResponseDto, EnpsResultDto, EntityLeaverCountDto, ResponsesRateDto, SectionAnalysisDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AnalysisService {
  apiName = 'Default';

  getCompletedEntityLeaverCounts = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, EntityLeaverCountDto[]>({
      method: 'POST',
      url: '/api/exit/analysis/completed-entity-counts',
      body: filters,
    },
    { apiName: this.apiName });

  getCompletedLeaverCount = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, number>({
      method: 'POST',
      url: '/api/exit/analysis/completed-count',
      body: filters,
    },
    { apiName: this.apiName });

  getEnps = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, EnpsResultDto[]>({
      method: 'POST',
      url: '/api/exit/analysis/enps',
      body: filters,
    },
    { apiName: this.apiName });

  getEntityLeaverCounts = (tenantKey: string, companyKey: string, surveyKey: string) =>
    this.restService.request<any, EntityLeaverCountDto[]>({
      method: 'GET',
      url: `/api/exit/analysis/entity-counts/${tenantKey}/${companyKey}/${surveyKey}`,
    },
    { apiName: this.apiName });

  getLeaverExperience = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, number>({
      method: 'POST',
      url: '/api/exit/analysis/leaver-experience',
      body: filters,
    },
    { apiName: this.apiName });

  getOverallAnalysis = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, ResponsesRateDto>({
      method: 'POST',
      url: '/api/exit/analysis/overall',
      body: filters,
    },
    { apiName: this.apiName });

  getQuestionAnalysis = (questionKey: string, questionType: string, filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, ChartResponseDto>({
      method: 'POST',
      url: `/api/exit/analysis/question-analysis/${questionKey}/${questionType}`,
      body: filters,
    },
    { apiName: this.apiName });

  getSectionAnalysis = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, SectionAnalysisDto[]>({
      method: 'POST',
      url: '/api/exit/analysis/section-analysis',
      body: filters,
    },
    { apiName: this.apiName });

  getTotalCommentsCount = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, number>({
      method: 'POST',
      url: '/api/exit/analysis/comments-count',
      body: filters,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
