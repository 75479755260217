import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExitBaseComponent } from '../../exit-base/exit-base.component';
import { CommentsAiService } from 'src/app/shared/comments-ai/comments-ai.service';

@Component({
  selector: 'exit-comment-ai',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent extends ExitBaseComponent implements OnInit {


  /**
   *
   */
  constructor() {
    super();

  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }





  // @Input() essentialObject;
  // @Input() language;
  // @Input() selectedEntity;
  // @Input() surveyType;
  // @Input() engagementAnalysis;
  // @Input() comment;
  // @Input() questionComment;
  // @Input() driver: EngagementDriver;
  // @Output() topicChanged = new EventEmitter();

  // @Output() recalculateDriver = new EventEmitter();

  // rtl: boolean = false;
  // sentimentAnalysislst = [];
  // topics: any[] = [];
  // topicsBk: string = '';

  // @Input('topics') set setTopics(topics: any[]) {
  //   if (topics) {
  //     this.topics = topics;
  //     this.topicsBk = JSON.stringify(this.topics)
  //   }

  // }

  // newTopic = {
  //   openSelectTopic: false,
  //   openNewTopic: false,
  //   comment: null,
  //   selected: undefined,
  //   invalidSelected: false,
  //   newTpc: undefined,
  //   invalidNewTpc: false,
  //   questionComment: null,
  //   filterText: '',
  // }
  // constructor(private commentsAiService: CommentsAiService) {
  //   super();
  //   this.sentimentAnalysislst = this.commentsAiService.sentimentAnalysislst;
  // }

  // ngOnInit(): void {
  //   if (document.body.dir == 'rtl') {
  //     this.rtl = true;
  //   }
  // }

  // async updateComment(comment, questionComment, re_fetchSentiment: boolean = false) {
  //   let request = {
  //     TenantKey: this.essentialObject.tenant.key,
  //     CompanyKey: this.essentialObject.selectedEntity.key,
  //     SurveyKey: this.essentialObject.engagement.key,
  //     SurveyType: this.surveyType,
  //     QuestionKey: questionComment.key,
  //     Comment: this.removeTempFields(comment)
  //   }
  //   let response: any = await this.engagementService.updateCommentSentiment(request).toPromise();
  //   if (response.response == 'SUCCESS') {
  //     if (re_fetchSentiment)
  //       this.fetchSentimentPerQuestion(questionComment);
  //   }
  // }
  // fetchSentimentPerQuestion(questionComment: QuestionComment) {
  //   let request = {
  //     TenantKey: this.essentialObject.tenant.key,
  //     CompanyKey: this.essentialObject.selectedEntity.key,
  //     SurveyKey: this.essentialObject.engagement.key,
  //     SurveyType: this.surveyType,
  //     QuestionKey: this.questionComment.key,
  //     FilterByMetrics: this.questionComment.filterByMetrics
  //   }
  //   this.questionComment.sentimentFetching = true;
  //   this.subscriptions.add(this.engagementService.fetchQuestionAnalysis(request).subscribe((response: any) => {
  //     if (response.response == 'SUCCESS') {
  //       if (response.Data != null) {
  //         this.questionComment.negative = response.Data.negative;
  //         this.questionComment.positive = response.Data.positive;
  //         this.questionComment.neutral = response.Data.neutral;
  //         this.questionComment.positiveCount = response.Data.positiveCount;
  //         this.questionComment.neutralCount = response.Data.neutralCount;
  //         this.questionComment.negativeCount = response.Data.negativeCount;
  //         this.recalculateDriver.emit(this.driver.key)
  //       }
  //     }
  //     this.questionComment.localFetching = false;
  //     this.questionComment.sentimentFetching = false;


  //   }));
  // }
  // removeTempFields(comment) {
  //   const { openSentimentMenu, openTopicMenu, ...newObject } = comment;
  //   return newObject;
  // }
  // convertToLowercase(str) {
  //   return str.toLowerCase();
  // }
  // public get SentimentAnalysis() {
  //   return SentimentAnalysis;
  // }
  // commentTopicChange(comment, topic, evt) {
  //   comment.comment_ai.topics = comment.comment_ai.topics.filter(f => f != topic.key)
  //   comment.comment_ai.topics.push(evt)
  //   topic.key = evt;
  //   topic.name = this.topics.find(f => f.key == evt).name;
  //   this.updateComment(comment, this.questionComment, false);
  //   this.topicChanged.emit();
  // }
  // removeTopicComment(comment, topic) {
  //   comment.comment_ai.topics = comment.comment_ai.topics.filter(f => f != topic.key);
  //   comment.comment_ai.topic = comment.comment_ai.topic.filter(f => f.key != topic.key);
  //   this.updateComment(comment, this.questionComment, false);
  //   this.topicChanged.emit();
  // }

  // getAnswerKey(answer) {
  //   if (!answer)
  //     return '';
  //   if (answer.toLowerCase().includes('dis')) {
  //     return 'red'
  //   } else {
  //     if (answer.toLowerCase().includes('agree'))
  //       return 'green'
  //     else
  //       return 'grey'
  //   }
  // }
  // excludeExistingTopics() {
  //   this.topics = JSON.parse(this.topicsBk);
  //   this.topics = this.topics.filter(f => !this.comment.comment_ai.topics.includes(f.key))
  // }
  // // Add Topic
  // handleSelection(option) {
  //   this.newTopic.selected = option["key"];
  // }
  // clear(e) {
  //   e.stopPropagation();
  //   this.newTopic.selected = '';
  // }

  // CreateNewTopic() {
  //   if (this.newTopic.newTpc && this.newTopic.newTpc.length > 0 && this.newTopic.newTpc.find(f => f.key == 'EN').name) {
  //     this.newTopic.invalidNewTpc = false;
  //     let key = this.newTopic.newTpc.find(f => f.key == 'EN').name;
  //     this.newTopic.comment.comment_ai.topics.push(key)
  //     this.newTopic.comment.comment_ai.topic.push({ key: key, name: this.newTopic.newTpc })
  //     this.updateComment(this.newTopic.comment, this.questionComment, false);
  //     this.newTopic.openNewTopic = false;
  //     this.newTopic.openSelectTopic = false;
  //     this.topicChanged.emit();
  //   } else
  //     this.newTopic.invalidNewTpc = true;
  // }
  // ApplySelectTopic() {
  //   if (!this.newTopic.selected) { this.newTopic.invalidSelected = true; return; }
  //   this.newTopic.invalidSelected = false;
  //   if (!this.newTopic.comment.comment_ai.topics.find(f => f == this.newTopic.selected)) {
  //     this.newTopic.comment.comment_ai.topics.push(this.newTopic.selected);
  //     this.newTopic.comment.comment_ai.topic.push({ key: this.newTopic.selected, name: this.topics.find(f => f.key == this.newTopic.selected).name })
  //     this.updateComment(this.newTopic.comment, this.questionComment, false);
  //     this.topicChanged.emit();
  //   }
  //   this.newTopic.openSelectTopic = false;
  // }
  // //end Add Topic
}
