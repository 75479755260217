import { Injectable } from '@angular/core';
import { EssentialObject, EssentialObjectService } from '@ex/module/core/services/essential-object.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DemoAccountService {
  isDemo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isDemo: boolean;
  constructor(private essentialObject: EssentialObjectService) {
    this.essentialObject.essentialObject.subscribe((x: EssentialObject) => {
      this.isDemo = x?.tenant?.accountType == "Demo";
      this.isDemo$.next(this.isDemo);
    })
  }
}
