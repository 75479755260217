import { Injectable } from '@angular/core';
import { StepOptions } from '../models/tour-step';

@Injectable({
  providedIn: 'root'
})
export class TourStepsService {
  private stepsDict: { [key: string]: StepOptions[] } = {};

  addStep(key, step: StepOptions) {
    if (key in this.stepsDict) {
      if (!step.isRows) {
        this.stepsDict[key] = this.stepsDict[key].filter(f => f.order != step.order);
        this.stepsDict[key].push(step);
      } else {
        if (!this.stepsDict[key].find(f => f.order == step.order))
          this.stepsDict[key].push(step);
      }
    }
    else {
      this.stepsDict[key] = [];
      this.stepsDict[key].push(step);
    }
  }
  getSteps(key): StepOptions[] {
    return this.stepsDict[key];
  }
  removeTour(key) {
    delete this.stepsDict[key]
  }
}
