import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class EmailNotificationService {
  sEndpoint: string = 'https://apis.zenithr.net/api/';
  fbFunctionURL: string = '';

  constructor(private http: HttpClient, private globals: GlobalsService) { 
    this.sEndpoint = this.globals.sEndpoint;
    this.fbFunctionURL = this.globals.fbFunctionURL;

  }

  sendNotificationEmail(body) {
    return this.http.post(this.sEndpoint + 'Leaver/SendEmailNotification', body);
  }

  sendEngagementNotification(email) {
    return this.http.post(this.fbFunctionURL + 'engagementSendInvitationsV2', email);
  }

  sendHappinessNotification(email) {
    return this.http.post(this.fbFunctionURL + 'happinessSendInvitationsV2', email);
  }

  sendAssessmentNotification(email) {
    return this.http.post(this.fbFunctionURL + 'assessmentSendInvitations', email);
  }

  sendAssessmentReminder(email) {
    return this.http.post(this.sEndpoint + 'Assessment/SendAssessmentReminders', email);
  }

  sendAssessmentReminderForSurvey(email) {
    return this.http.post(this.sEndpoint + 'Assessment/SendAssessmentRemindersForSurvey', email);
  }

  sendAssessment180Reminder(email) {
    return this.http.post(this.sEndpoint + 'Assessment180/SendAssessmentReminders', email);
  }

  sendAssessment180ReminderForSurvey(email) {
    return this.http.post(this.sEndpoint + 'Assessment180/SendAssessmentRemindersForSurvey', email);
  }
  
}
