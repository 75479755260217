<div *ngIf="selected" (click)="logData(translations)" class="flex flex-col gap-2 w-full relative minw-44">
  <h5 *ngIf="label || labelTemplate" class="text-xs text-zen font-medium uppercaseLetter">
    <span *ngIf="!labelTemplate">{{ label }}</span>
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>

    <i *ngIf="required" class="fa-regular fa-asterisk text-3xs transform -translate-y-1"></i>
  </h5>
  <div class="flex flex-row gap-px">
    <div [class]="classList">
      <input [hidden]="disable" [maxlength]="maxCount" #field="ngModel" [required]="required" type="text"
        [placeholder]="placeholder?placeholder:('txtDescription'| translate:language:ModuleNames.Shared)"
        class="text-sm  w-full h-full border-none bg-transparent" [(ngModel)]="selected.name"
        (ngModelChange)="handleChange($event)">
      <ng-content select="[extra]"></ng-content>
      <i [hidden]="disable" (click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
        class="fa-light fa-globe w-8 h-8 leading-8  text-opacity-60 text-base  rounded btn  text-center btn"></i>
      <h5 class="leading-7" *ngIf="disable">{{translations|extract:language}}</h5>
    </div>

    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayHasBackdrop]="isOpen" (backdropClick)="isOpen = false">
      <div *DemoAccount="'Default'; let isDemo=isDemo" [@fadeUpDown] class="inline-flex flex-col items-stretch bg-white text-sm text-zen-primaryLight border border-dark
         border-opacity-10 rounded p-1 mt-2 min-w-32">
        <div *ngFor="let field of translations; let n = index">
          <div (click)="$event.stopPropagation();" class="flex flex-col p-3 gap-1" *ngIf="field?.key != language">
            <h5 class="text-xs text-zen font-medium">{{('txt_custom_input_translation'|
              translate:language:ModuleNames.Shared) + '
              ' + getLanguage(field?.key) }}</h5>
            <div
              class="w-full text-sm rounded text-zen border bg-white border-zen border-opacity-25 px-3 h-9 gap-3 leading-9 truncate flex flex-row justify-between items-center">
              <input type="text" [placeholder]="'txtDescription'| translate:language:ModuleNames.Shared"
                class="text-sm text-zen w-full h-full border-none bg-transparent" (ngModelChange)="handleChange()"
                [(ngModel)]="field.name">
              <a *ngIf="selected?.name?.length" zenButton (click)="inTranslation?'':aiTranslate(field.key, n)"
                [matTooltip]="'txtGenerateWithAI'|translate:language:ModuleNames.Shared" styles="icon-8"
                class="transition-all ease-expo" [ngClass]="{'pointer-events-none':inTranslation}">
                <img *ngIf="!inTranslation || isDemo" class="object-fill" src="/assets/img/svg/ZenAi-icon.svg" />
                <lottie-player *ngIf="inTranslation && !isDemo" src="/assets/lottie/circleLoader.json" background="transparent"
                  speed="2" style="width: 3rem; height: 3rem;" loop autoplay="false"></lottie-player>
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-template>


  </div>


</div>
<div class="h-0" *ngIf="!disable">
  <div *ngIf="invalid || selected?.name.length > maxCount -5" class="flex flex-row justify-between items-center">
    <div class="alert alert-danger transform translate-y-1">
      <validation-required [language]="language" *ngIf="validateName(translations) && required && invalid">
      </validation-required>
    </div>
    <div *ngIf="selected?.name.length > maxCount -5"
      class="text-red-600 text-xxs flex flex-row items-center gap-1 leading-5">
      {{maxCount}}/{{selected.name.length}}</div>
  </div>
</div>
