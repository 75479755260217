

<div   class="w-screen h-screen bg-zen bg-opacity-50 flex flex-row items-center justify-center fixed top-0 left-0 z-50 py-10 px-5" [ngClass]="{'dirRTL':rtl}">
  <div class="max-w-4xl w-full flex flex-col bg-white bg-opacity-95 py-10 rounded max-h-screen-90">
      <div class="flex flex-row items-center justify-start gap-3 px-10 pb-5">
        <i *ngIf="type == 'warning'" class=" text-white bg-exp shadow-xl rounded-full h-110 w-10 text-xl text-center leading-10  fal fa-exclamation"></i>
        <i *ngIf="type == 'error'" class="text-white bg-zen-red shadow-xl rounded-full h-110 w-10 text-xl text-center leading-10  fal fa-times"></i>
        <i *ngIf="type == 'success'" class="text-white bg-zen-green shadow-xl rounded-full h-110 w-10 text-xl text-center leading-10  fal fa-question"></i>
        <h4 class="text-2xl font-bold text-zen">{{subject}}</h4>
      </div>
      <div class="flex flex-col gap-1 px-10 py-10 innerListScroll  min-w-80">
        <h6 class="text-zen text-center text-lg"><div [innerHTML]="message"></div></h6>
      </div>
      <div class="flex flex-row justify-center items-center px-10 pt-5">
        <button  class="btn cancel  text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium" type="button" [mat-dialog-close]="true">{{action}}</button>
      </div>
  </div>
</div>


