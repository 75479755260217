import { Component, Input, OnInit } from '@angular/core';
import { SxBaseComponent } from '../../sx-base/sx-base.component';

@Component({
  selector: 'sx-feedback-card',
  templateUrl: './feedback-card.component.html',
  styleUrls: ['./feedback-card.component.scss']
})
export class FeedbackCardComponent extends SxBaseComponent implements OnInit {

  @Input()
  feedback;

  constructor() { super() }

  ngOnInit(): void {
  }


  getInitials(userName: any): string {
    if (!userName || userName.trim().length === 0) return '';

    const names = userName.split(' ').filter(name => name.trim().length > 0);
    const initials = names.map(name => name[0].toUpperCase()).join('');

    return initials;
  }

  isNumber(res: string) {
    return Number(res);
  }

  getRange(min = 0, max, reverse = false) {
    let values = [...Array(max + 1).keys()].filter(x => x >= min);

    if (reverse)
      return values.reverse();

    return values;
  }


  getJobTitle() {
    return this.feedback.customProperties.find(x => x.key == "job-title")?.value ?? "";
  }
}
