import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Entity } from 'src/app/core/data-models/Entity';
import { GeneralService } from '@ex/module/core/services/general.service';
import { LanguageService } from '@ex/module/core/services/language.service';
import { filter } from 'rxjs/operators';
import { groupBy } from '@progress/kendo-data-query';

import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';
import { CriteriaResolverService, SurveyCriteriaDropdown } from '../../data-access/services/criteria-resolver.service';
import { SxBaseComponent } from '../sx-base/sx-base.component';
import { FilterDropdownLookupDto } from '@ex/survey-x/index';

@Component({
  selector: 'sx-filters',
  templateUrl: './sx-filters.component.html',
  styleUrls: ['./sx-filters.component.scss']
})
export class SxFiltersComponent extends SxBaseComponent implements OnInit {
  @Input() language;
  @Input() exclude = [];
  @Input() filterOptions: FilterDropdownLookupDto[];
  @Output() filtersItems = new EventEmitter<any>();
  @Output() displayedFilters = new EventEmitter<any>();
  openFilters: boolean = false;
  entities: Entity[] = [];
  Company: Entity;
  filters: any[] = [];
  selectedFilters = {
    BusinessLevel: { list: [], selectedValues: [], exclude: false, displayF: 'name', category: 'Business Level' },
    Others: []
  };
  rtl: boolean = false;
  _essentialObj;
  ItemsBK: string = "";
  surveyCriterias: SurveyCriteriaDropdown[] = [];
  constructor(private languageService: LanguageService, private analyticsService: AnalyticsService, private surveyCriteriaResolver: CriteriaResolverService) {
    super();
  }

  ngOnInit(): void {
    this.EssentialObject$.pipe(
      filter(obj => obj?.selectedEntity?.key != null),
    ).subscribe(obj => {
      this._essentialObj = obj;
      if (obj.selectedEntity) {
        if (obj.companies) {
          this.Company = obj.companies.find(c => c.key == obj.selectedEntity.key);
        }
        if (obj.allEntities) {
          this.entities = obj.allEntities;
        }
        this.resetFilters();
      }
    })
    if (document.body.dir == 'rtl') {
      this.rtl = true;
    }
    this.analyticsService.filters$.subscribe((res: AnalyticsFilter) => {
      if (res && res.survey && res.survey.surveyCriterias?.length > 0) {
        this.surveyCriterias = res.survey.surveyCriterias;
        this.selectedFilters.Others.forEach(f => f.name = this.extractCtegoryName(f.key))

      }
    })
  }
  clearFilters() {
    this.selectedFilters = {
      BusinessLevel: { list: [], selectedValues: [], exclude: false, displayF: 'name', category: 'Business Level' },
      Others: []
    };
    this.filters = [];
    this.companySelectedChanged();
    this.filterOptions.forEach(filterOption => {
      let obj: OtherCriteria = {
        key: filterOption.key,
        name: filterOption.key,
        list: filterOption.options,
        selectedValues: [],
        exclude: false
      }
      this.selectedFilters.Others.push(obj)
    })
  }
  selectFilters(Items: any) {
    if (Items) {
      this.selectedFilters = Items;
      this.ItemsBK = JSON.stringify(Items);
      this.showFilters(Items)
      new Promise((resolve) => {
        let filtersFields = []
        if (Items['BusinessLevel'].selectedValues.length > 0) {
          let selectedEntities = []
          Items['BusinessLevel'].selectedValues.forEach(f => {
            if (f.selectedKeys.length > 0) {
              f.selectedKeys.forEach(s => selectedEntities.push(s))
            }
          })
          filtersFields.push({ key: "Entity", value: selectedEntities })
        }
        //extra criterias and others
        Items['Others'].forEach(f => {
          if (f.selectedValues.length > 0)
            filtersFields.push({ key: f.key, value: f.selectedValues })
        })
        resolve(filtersFields)
      }).then((res: any) => {
        this.filtersItems.emit(res);
      })
    } else {
      this.filters = null;
      this.filtersItems.emit([]);
      this.displayedFilters.emit({})
    }
  }
  showFilters(Items) {
    this.filters = [];
    Object.keys(Items).forEach((filterItem) => {
      if (filterItem == 'BusinessLevel') {
        Items[filterItem].selectedValues.forEach(entity => {
          this.filters.push({
            key: this.extractNameLanguage(this.Company?.EntityGroups.find(f => f.groupKey == entity.key)[Items[filterItem]['displayF']]),
            name: this.extractNameLanguage(this.Company?.EntityGroups.find(f => f.groupKey == entity.key)[Items[filterItem]['displayF']]),
            values: Items[filterItem].list.filter(f => entity.selectedKeys.indexOf(f.key) > -1).map(i => this.extractNameLanguage(i[Items[filterItem]['displayF']]))
          });
        });
        return;
      }
      if (filterItem == 'Others') {
        Items[filterItem].forEach(category => {
          if (category.selectedValues.length > 0) {
            this.filters.push({
              key: category.key,
              name: category.name,
              values: category.selectedValues.map(m => this.resolver(category.key, m))
            });
          }
        });
        return;
      }
    })
  }
  resetFilters() {
    this.clearFilters()
    this.selectFilters(null)
  }
  companySelectedChanged() {
    this.selectedFilters.BusinessLevel.list = [];
    if (this.entities) {
      this.selectedFilters.BusinessLevel.list = this.entities.map(f => { f["checked"] = false; return f; });
    }
  }
  removeFilter(key) {
    let Items = JSON.parse(this.ItemsBK);
    if (this.selectedFilters.Others.find(f => f.key == key)) {
      Items.Others.find(f => f.key == key).selectedValues = []
    } else {
      // is business
      if (this.Company?.EntityGroups.find(f => f.name.find(f => f.name == key))) {
        let ItemKey = this.selectedFilters['BusinessLevel'].selectedValues.find(f => f.key == this.Company?.EntityGroups.find(f => f.name.find(f => f.name == key)).groupKey).key;
        Items['BusinessLevel'].selectedValues = this.selectedFilters['BusinessLevel'].selectedValues.filter(f => f.key != ItemKey);
        Items['BusinessLevel'].list = Items['BusinessLevel'].list.map((f) => { if (f.entityGroupKey == ItemKey) { f["checked"] = false; } return f; });

      }
    }
    this.selectFilters(Items)
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  extractCtegoryName(key) {
    let category = this.surveyCriterias.find(f => f.key == key);
    return this.ExtractPipe.transform(category?.name, this.language) || key;
  }
  resolver(criteria: string, translationKey: string) {
    return this.surveyCriteriaResolver.extract(criteria, translationKey, this.language);
  }
}
export interface OtherCriteria {
  key: string;
  name: string;
  list: string[];
  selectedValues: string[];
  exclude: boolean;
}