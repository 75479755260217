<z-modal [open]="true" [autoWidth]="true">
  <ng-container header>
    <div class="flex flex-row justify-between w-full">
      <div class="flex flex-row ">
        <h2 class="text-zen text-2xl font-semibold">
          {{"txtConfirm Close"| translate:language:ModuleNames.SurveyX}}</h2>
      </div>

      <div class="flex flex-row w-10 h-10 bg-zen-littlegray rounded items-center justify-center">
        <i (click)="closeModal()"
          class=" btn fa-regular fa-times text-zen-primarylight text-base text-center items-center font-normal "></i>
      </div>

    </div>
  </ng-container>

  <ng-container body>

    <div class="flex flex-row justify-center min-w-500">
      <h5 class="text-sm font-medium text-center text-zen-primarydefault">
        {{"txtAre you sure you want to close the"| translate:language:ModuleNames.SurveyX}} {{selectedForm.typeName |
        translate:language:ModuleNames.SurveyX}}{{"txt?"| translate:language:ModuleNames.SurveyX }}
      </h5>
    </div>
    <div class="flex flex-row pt-4 justify-center">
      <h5 class="text-xs font-normal text-center text-zen-primarylight">
        {{"txtOnce Closed, the Form will no longer be accessible by respondents, and will" |
        translate:language:ModuleNames.SurveyX}} <br>
        {{"txtbe marked as a Closed Form" | translate:language:ModuleNames.SurveyX}}
      </h5>
    </div>

  </ng-container>

  <ng-container footer>
    <div class="flex flex-row justify-between w-full">
      <a (click)="closeModal()"
        class="btn cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
        {{"txtGo Back"| translate:language:ModuleNames.SurveyX}}
      </a>
      <a class="btn exp text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1"
        (click)="confirmClosure()">
        {{"txtYes, close now"| translate:language:ModuleNames.SurveyX}}
      </a>
    </div>
  </ng-container>


</z-modal>
