import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';
import { products } from '../data-models/company-permission';
import { Entity } from '../data-models/Entity';
import { EntityGroup } from '../data-models/entity-group';
import { EntityHierarchy } from '../data-models/entity-hierarchy';
import { PlatformUser } from '../data-models/platform-user';
import { UserRoles } from '../user-roles';
import { EssentialObject, EssentialObjectService } from './essential-object.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private platformUserSubject = new BehaviorSubject<PlatformUser>(null);
  private platformUser$ = this.platformUserSubject.asObservable();
  private SUPER_ADMIN: string = 'Super Admin';
  private platformUser: PlatformUser;

  constructor(private essentialObjectService: EssentialObjectService) {

    this.essentialObjectService.essentialObject.pipe(
      filter((x: EssentialObject) => x.user != null),
      pluck('user'),
    ).subscribe(user => {
      this.platformUser = user;
      this.platformUserSubject.next(user);
    })

  }

  hasPermission$(entityId: string, product: products): Observable<boolean> {

    return this.platformUser$.pipe(
      map(user => {
        if (user == null)
          return false;


        if (user.role == this.SUPER_ADMIN)
          return true;

        var permission = user.ModulePermissions.find(x => x.key == entityId && x.products.includes(product));

        if (permission != null)
          return true;


        return false;
      })
    )

  }

  hasPermission(entityId: string, product: products) {

    if (this.platformUser.role == UserRoles.SuperAdmin)
      return true;

    var permission = this.platformUser.ModulePermissions.find(x => x.key == entityId && x.products.includes(product));

    return permission != null;
  }

  getEntityIdsWithPermission(product: products) {
    var permissions = this.platformUser.ModulePermissions.filter(x => x.products.includes(product));
    return permissions.map(x => x.key);
  }

  isSuperAdmin() {
    return this.platformUser.role == this.SUPER_ADMIN;
  }

  constructEntityHierarchy(entities: Entity[], entityKey: string) {
    let entity: Entity = entities.find(e => e.key == entityKey);
    let selectedCompany: Entity = entities.find(e => e.key == entity.companyEntityKey);
    let entityHierarchy: any[] = [];
    let entityGroup: EntityGroup = selectedCompany.EntityGroups.find(g => g.groupKey == entity.entityGroupKey);
    let selectionList: Entity[] = [];
    selectionList.push(entity);
    if (entity.isCompany == false) {
      let eg: any = { key: entity.entityGroupKey, name: entityGroup.name, entities: selectionList, selectedEntityKey: entity.key, readOnly: true, parentKey: entityGroup.parentKey };
      entityHierarchy.push(eg);
      if (entity.parentKey != entity.companyEntityKey) {
        entityHierarchy = this.drillUp(entities, entityHierarchy, selectedCompany);
      }
    }
    return entityHierarchy;
  }

  drillUp(entities: Entity[], entityHierarchy: any[], selectedCompany: Entity) {
    let entity: Entity = entityHierarchy[0].entities[0];

    if (entity.parentKey != entity.companyEntityKey) {
      let parentEntity = entities.find(e => e.key == entity.parentKey);
      let entityGroup: EntityGroup = selectedCompany.EntityGroups.find(g => g.groupKey == parentEntity.entityGroupKey);
      let selectionList: Entity[] = [];
      selectionList.push(parentEntity);
      let eg: any = { key: parentEntity.entityGroupKey, name: entityGroup.name, entities: selectionList, selectedEntityKey: parentEntity.key, readOnly: true };
      entityHierarchy.unshift(eg);
      this.drillUp(entities, entityHierarchy, selectedCompany);
    }
    return entityHierarchy;
  }

}
