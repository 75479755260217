import { translateText } from "../../../features/survey/survey.component";

export function updateRatingStarsQuestionHTML(options: any, locale: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  body.className = "question-body";

  var commentArea = body.querySelector(".sd-question__comment-area");
  var commentBox = commentArea?.querySelector(".sd-comment.sd-input");

  if (!commentBox)
    commentBox = body?.querySelector(".sd-comment.sd-input");
  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-5 text-start";
  inputTitle.innerHTML = translateText('Select an option:', locale);

  body.innerHTML = "";
  body.appendChild(inputTitle);

  var newRatingQuestionContent = document.createElement("div");
  newRatingQuestionContent.className = "flex flex-col gap-3 py-4 w-full rank-items-container";

  body.appendChild(newRatingQuestionContent);
  var max = 300;

  if (commentBox != null) {
    commentBox.setAttribute("maxLength", max);
    //Add character counter

   var counter = body?.querySelector('.comment-counter');
    if (!counter) {
      counter = document.createElement("span");
      counter.className = "text-end text-gray-500 text-sm comment-counter";
      counter.style.textAlign = "end";
      counter.innerHTML = commentBox.value.length + "/" + max;
      body.appendChild(counter);
    }

    body.insertBefore(commentBox,counter);
    commentBox.addEventListener("input", function () {
      // Update character count on input
      counter.innerHTML = commentBox.value.length + "/" + max;
    });
  }

  var label = document.createElement("label");
  label.className = "label-gray w-[60px] sm:w-min text-start";
  label.innerHTML = "";

  var starsContainer = document.createElement("div");
  starsContainer.className = "flex flex-row flex-wrap items-center gap-3";

  for (var i = 0; i < options.question.rateCount; i++) {
    var star = document.createElement("i");
    var type = "";

    switch (options.question['rateType']) {
      case "stars":
        star.className = "fa-star";
        type = "fa-star";
        break;
      case "fa-heart":
        star.className = "fa-heart";
        type = "fa-heart";
        break;
      case "fa-thumbs-up":
        star.className = "fa-thumbs-up";
        type = "fa-thumbs-up";
        break;
      case "smileys":
        star.className = "fa-face-smile";
        type = "fa-face-smile";
        break;
    }

    star.className += " fa-light";

    star.style.color = "#AAB7CC";
    star.style.fontSize = "38px";

    //onclick  Color the selected star and all the stars before it
    star.onclick = function () {
      var stars = starsContainer.querySelectorAll('i');
      var color = "var(--main-primary-color)";

      if (options.question['ratingLabels'] != null && options.question['ratingLabels'].length > 0) {
        for (var m = 0; m < stars.length; m++) {
          if (stars[m] == this) {
            for (var j = 0; j <= m; j++) {
              stars[j].className = type + " fa-solid";
              stars[j].style.color = color;
            }

            options.question.value = m + 1;
          } else {
            stars[m].className = type + " fa-light";
            stars[m].style.color = "#AAB7CC";
          }
        }

        if (locale == "en" || locale == "EN") {
          locale = "default"
        }

        var labelText = options.question['ratingLabels'].find(x => x.min <= options.question.value && x.max >= options.question.value) || null;

        if (labelText != null) {
          color = labelText.color;

          label.innerHTML = labelText.text.default ? labelText.text[locale] : labelText.text;
          ;
        } else {
          label.innerHTML = "";
        }
      }

      if (color == "#ECEEF4") color = "var(--main-primary-color)";

      for (var m = 0; m < stars.length; m++) {
        if (stars[m] == this) {
          for (var j = 0; j <= m; j++) {
            stars[j].className = type + " fa-solid";
            stars[j].style.color = color;
          }

          options.question.value = m + 1;
        } else {
          stars[m].className = type + " fa-light";
          stars[m].style.color = "#AAB7CC";
        }
      }
    }

    starsContainer.appendChild(star);
  }

  newRatingQuestionContent.appendChild(starsContainer);
  newRatingQuestionContent.appendChild(label);

  if (options.question.value != null && options.question.value != "") {
    var stars = starsContainer.querySelectorAll('i');
    stars[options.question.value - 1].click();
  }
}
