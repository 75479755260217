export enum SentimentAnalysis {
  negative = "negative",
  neutral = "neutral",
  positive = "positive",
  noSentiment = "noSentiment"
}
export enum SentimentColors {
    positive = "#5CCBAD",
    neutral = "#BEC8DA",
    negative = "#E1707D",
    no_sentiment = "#F6F8FA",
    default = "#000000"
}
