import type { GetCompanySurveysDto, SendBulkRemindersDto, SurveyBulkDeleteDto, SurveyCreateDto, SurveyDto, SurveyListPagedDto, SurveyRenameDto, SurveyUpdateDto, UpdateSurveyDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { SurveyTemplateDto } from '../survey-templates/models';

@Injectable({
  providedIn: 'root',
})
export class SurveysService {
  apiName = 'Default';

  bulkDelete = (input: SurveyBulkDeleteDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/wellbeing/surveys/survey-bulk-delete',
      body: input,
    },
    { apiName: this.apiName });

  closeSurvey = (key: string) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/wellbeing/surveys/close-survey/${key}`,
    },
    { apiName: this.apiName });

  create = (input: SurveyCreateDto) =>
    this.restService.request<any, SurveyDto>({
      method: 'POST',
      url: '/api/wellbeing/surveys',
      body: input,
    },
    { apiName: this.apiName });

  delete = (key: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/wellbeing/surveys/${key}`,
    },
    { apiName: this.apiName });

  get = (key: string) =>
    this.restService.request<any, SurveyDto>({
      method: 'GET',
      url: `/api/wellbeing/surveys/${key}`,
    },
    { apiName: this.apiName });

  getList = (input: GetCompanySurveysDto) =>
    this.restService.request<any, SurveyListPagedDto>({
      method: 'POST',
      url: '/api/wellbeing/surveys/survey-list',
      body: input,
    },
    { apiName: this.apiName });

  getQuestionnaire = (key: string) =>
    this.restService.request<any, SurveyTemplateDto>({
      method: 'GET',
      url: `/api/wellbeing/surveys/get-questionnaire/${key}`,
    },
    { apiName: this.apiName });

  rename = (input: SurveyRenameDto) =>
    this.restService.request<any, void>({
      method: 'PUT',
      url: '/api/wellbeing/surveys/survey-rename',
      body: input,
    },
    { apiName: this.apiName });

  sendBulkReminders = (input: SendBulkRemindersDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/wellbeing/surveys/send-reminders-bulk',
      body: input,
    },
    { apiName: this.apiName });

  sendInvitations = (key: string, request: SurveyUpdateDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/wellbeing/surveys/send-invitations',
      params: { key },
      body: request,
    },
    { apiName: this.apiName });

  update = (key: string, input: SurveyUpdateDto) =>
    this.restService.request<any, SurveyDto>({
      method: 'PUT',
      url: `/api/wellbeing/surveys/${key}`,
      body: input,
    },
    { apiName: this.apiName });

  updateSurveyDateWithReminders = (input: UpdateSurveyDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/wellbeing/surveys/update-with-reminders',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
