import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NativeExportBaseComponent } from '@ex/module/shared/native-export-base/native-export-base.component';
import { Question } from 'survey-angular';


@Component({
  selector: 'respondent-answers-report',
  templateUrl: './respondent-answers-report.component.html',
  styleUrls: ['./respondent-answers-report.component.scss']
})
export class RespondentAnswersReportComponent extends NativeExportBaseComponent implements OnInit {
  filteredGroups: { groupText: string, items: any[] }[] = [];
  activeRating: any = '';
  label: any;

  @Input() headerTitle: any;
  @Input() respondentName: any;
  @Input() sections: any;
  @Input() questions: any;
  @Input() answers: any;
  @Input() companyName: any;
  @Input() open;
  @Output() openChange = new EventEmitter;
  @Input() essentialObject;

  @Input() language: any;
  date: Date = new Date();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  closeModal() {
    setTimeout(() => {
      this.openChange.emit(false)
    }, 1000);
  }

  filterQuestionOfSection(section) {
    return this.questions.filter(x => x.section == section);
  }

  getPreviousSectionCount(previousSection, sections) {
    let count = 0;
    for (let i = 0; i <= previousSection; i++) {
      count += this.filterQuestionOfSection(i).length;
    }
    return count;

    // let count = this.questions.filter(x => x.section == previousSection).length +
    // this.questions.filter(x => x.section == previousSection - 1).length +
    // this.questions.filter(x => x.section == previousSection - 2).length +
    // this.questions.filter(x => x.section == previousSection - 3).length +
    // this.questions.filter(x => x.section == previousSection - 4).length  ;
    // return count
  }

  setActiveRate(rateValue: any, questionKey: string, question: any) {
    //
    this.label = "";
    const response = this.answers.find(answer => answer?.question === questionKey);
    this.activeRating = response?.answer[0];
    this.label = this.getLabel(rateValue.value, question);
    return this.activeRating;
  }
  getLabel(rateValue: any, question: any) {

    let label = question.ratingLabels?.find(x => x.min <= this.isNumber(rateValue) && x.max >= this.isNumber(rateValue))?.text
    if (label)
      return label;
    else
      return
  }
  getColor(rateValue: any, question: any) {
    let color = question.ratingLabels?.find(x => x.min <= this.isNumber(rateValue) && x.max >= this.isNumber(rateValue))?.color
    if (color)
      return color;
    else
      return
  }
  filterLabels(question: any) {

    this.filteredGroups = question.ratingLabels.map(x => ({
      groupText: this.getTranslatedTitle(x.text),
      groupColor: x.color,
      groupMin: x.min,
      groupMax: x.max,
      questionKey: question.key,
      items: question.rateValues.filter(item => {
        const value = parseInt(item.value, 10);
        return value >= x.min && value <= x.max;
      })
    }));
  }
  isNumber(res: string) {
    return Number(res);
  }

  setActiveIcon(choice: any, question: string) {

    const previousAnswer = this.answers?.find(answer => answer.question === question);
    this.activeRating = previousAnswer?.answer[0] || [];
    const checkAvailable = this.activeRating === choice.key;
    return checkAvailable;
  }
  setActiveImage(choice: string, ans: any) {
    const previousAnswer = this.answers.find(answer => answer.question === ans);
    if (previousAnswer) {
      const checkAvailable = previousAnswer.answer[0] === choice;
      if (checkAvailable) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  setActiveImages(choice: string, ans: any) {
    const previousAnswer = this.answers.find(answer => answer.question === ans);
    const sk = JSON.parse(previousAnswer?.answer[0]);
    const checkAvailable = sk === choice;
    if (checkAvailable) {
      return true;
    } else {
      return false;
    }
  }



  isChoiceDisabled(questionKey: string, choiceKey: string): boolean {
    const previousAnswer = this.answers.find(answer => answer.question === questionKey);

    if (previousAnswer) {
      const correctAns = previousAnswer?.answer[0]
      // const correctAns = JSON.parse(previousAnswer?.answer[0]);
      // console.log(correctAns);

      if (correctAns.includes(choiceKey)) {
        return false;
      }
      else {
        return true
      }
    }
    return true
  }

  getSortedChoices(question: any): any[] {
    const previousAnswer = this.answers.find(answer => answer.question === question.key);
    const userAnswer = previousAnswer?.answer[0];
    if (userAnswer) {
      const correctAns = JSON.parse(userAnswer);


      const sortedChoices = question.choices.slice().sort((a, b) => {
        const aIndex = correctAns.indexOf(a.key);
        const bIndex = correctAns.indexOf(b.key);

        return aIndex - bIndex;
      });

      return sortedChoices;
    }
    return null
  }


  validateAns(rowKey: any, qKey: any, column: any) {
    //
    const previousAnswer = this.answers.find(answer => answer.question === qKey);
    const correctAns = previousAnswer ? JSON.parse(previousAnswer.answer[0]) : null;
    if (correctAns) {
      for (const ans in correctAns) {
        if (ans == rowKey) {
          if (correctAns.hasOwnProperty(ans)) {
            const columns = correctAns[ans];

            if (Array.isArray(columns)) {
              // Handle "Matrix (Multiple with comment)" question type
              if (columns.includes(column) && correctAns[rowKey].includes(column)) {
                const checkAns = column;
                return checkAns ? 'fa-solid fa-square-check' : '';
              }
            }
            else {
              // Handle "matrix (single)" question type
              const checkAns = correctAns[rowKey];
              return checkAns === column ? true : false;
            }
          }
        }
      }
    }
    return '';
  }

  getTextAreaVal(key: string) {
    const previousAnswer = this.answers.find(answer => answer.question === key);
    if (previousAnswer) {
      return previousAnswer.answer;
    } else {
      return "";
    }
  }

  getDate(qKey: string, index: string) {
    const previousAnswer = this.answers.find(answer => answer.question === qKey);
    const ans = JSON.parse(previousAnswer?.answer[0]);
    if (previousAnswer) {
      if (index === 'index1') {
        return ans[0];
      } else {
        return ans[1];
      }
    } else {
      return '';
    }
  }

  isValueChecked(questionKey: string, choiceKey: string): boolean {
    const previousAnswer = this.answers.find(answer => answer.question === questionKey);

    if (previousAnswer) {
      return previousAnswer.answer.includes(choiceKey);
    }
    return false;
  }

  getValue(questionKey: string, fieldName: string) {
    const previousAnswer = this.answers.find(answer => answer.question === questionKey);
    const ansData = JSON.parse(previousAnswer.answer);
    const matchedKey = Object.keys(ansData).find(key => key === fieldName);
    // console.log(matchedKey, matchedKey)
    if (matchedKey) {
      return ansData[matchedKey];
    }
    return '';
  }

  getFileDetails(key: string) {
    const previousAnswer = this.answers.find(answer => answer.question === key);
    if (!previousAnswer)
      return;
    const correctAns = JSON.parse(previousAnswer.answer);
    return correctAns[0] || null;
  }

  downloadImage(base64Url: string, fileName: string) {
    let name;
    const byteString = atob(base64Url.split(',')[1]);
    const mimeString = base64Url.split(',')[0].split(':')[1].split(';')[0];
    // console.log(mimeString);
    if (mimeString === 'application/pdf') {
      name = 'file.pdf';
    } else {
      name = 'image.png';
    }
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeString });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = name;
    anchor.click();
  }


  getTranslatedTitle(title: any) {
    if (typeof title == 'string') {

      return title;

    }
    else {
      return title[this.language]
    }
  }

  logData(e) {
    console.log(e);
  }

  groupedSection(rateValues: any) {
    rateValues = rateValues.map(x => {
      x.key = parseInt(x.key)
      x.value = parseInt(x.value)
      return x;
    })
    let values = [{color:'#ff4530', rates: [], name: 'Unlikely', min: 0, max: 6 }, {color:'#FF9600', rates: [], name: 'Neutral', min: 7, max: 8 }, {color:'#3eb75b', rates: [], name: 'Likely', min: 9, max: 10 }]
    for (let index = 0; index < values.length; index++) {
      values[index].rates = rateValues.filter(x => x.key >= values[index].min && x.key <= values[index].max)

    }
    return values;
  }


  returnQuestionAnswer(questionKey: string) {
    return this.answers.find(answer => answer?.question === questionKey).answer[0];
  }
  sliceText(text, start, end){

    let tex1t = text.slice(start, end)
    return tex1t
  }

}
