import { Component, OnInit, Inject } from '@angular/core';
import { faTimes, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../../core/services/language.service';
import { GeneralService } from '../../core/services/general.service';
import { LocalService } from '../../core/services/local.service';
import { inOutAnimation } from '../animations/allAnimations';
import { ModuleName } from '../enums/ModuleName';
import { TranslatePipe } from '../pipes/translate.pipe';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  animations:[inOutAnimation]
})
export class ConfirmationDialogComponent implements OnInit {
  subject: string;
  message: string;
  action: string;
  cancel: string;
  type: string;
  checkboxText: string;
  txtalert: string;
  txtsuccess: string;
  txtwarning: string;
  txterror: string;
  faCheckCircle = faCheckCircle;
  faTimes = faTimes;
  faExclamationTriangle = faExclamationTriangle;
  checkboxChecked: boolean = false;
  language = this.localService.getJsonValue('language');
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>, private languageService: LanguageService,
    private generalService: GeneralService, private localService: LocalService,private translationPipe:TranslatePipe) { }

  ngOnInit(): void {
    this.subject = this.data.subject;
    this.message = this.data.message;
    this.checkboxText = this.data.checkboxText;
    this.action = this.data.actionButtonText;
    this.cancel = this.data.cancelButtonText;
    this.type = this.data.dialogType;
    this.setHeaderText();
  }
  ModuleNames=ModuleName
  setHeaderText() {
    this.txterror =this.translationPipe.transform('txterror',this.language,this.ModuleNames.Shared) 
    this.txtalert =this.translationPipe.transform('txtalert',this.language,this.ModuleNames.Shared) 
    this.txtwarning =this.translationPipe.transform('txtwarning',this.language,this.ModuleNames.Shared) 
    this.txtsuccess =this.translationPipe.transform('txtsuccess',this.language,this.ModuleNames.Shared) 
  }

  checkboxChanged(event) {
    this.checkboxChecked = event.checked;
  }

}
