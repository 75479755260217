<div class="flex flex-col items-center absolute w-0 h-0" [ngClass]="{'rtl': (language | language)}">
  <div class="overflow-x-hidden" id="pdf">
      <div class="block relative bg-white innerListScroll w-export overflow-x-hidden">

          <div class="innerPage overflow-x-hidden exportContainer pt-20 px-10" *DemoAccount="'Default'; let isDemo=isDemo">
              <h2 class="font-bold text-zen pb-3" style="font-size: 18px;" >
                  {{ "txt_survey_questions"| translate:language:ModuleNames.Wellbeing }}</h2>

              <div [ngClass]="" class="avoidBreak  overflow-x-hidden"
                  *ngFor="let section of (isDemo?(sections | slice:0:2):sections); let n = index" [ngClass]="{'break-before pt-20':n % 2 == 0 && n != 0}">
                  <ng-container>
                      <div class="inner-content pt15">
                          <h2 class="headTitle px15 text-zen">{{ extractNameLanguage(section.name)}}</h2>
                          <h5 class="py-1"></h5>
                          <ng-container *ngFor="let question of section.questions">
                              <div class="list py5">
                                  <div class="exportRow justify-start">
                                      <div class="icon">
                                          <i></i>
                                      </div>
                                      <p class="px10 text-zen-lite"> {{
                                          extractNameLanguage( question.name) }}</p>
                                  </div>
                              </div>
                          </ng-container>
                      </div>
                  </ng-container>
              </div>
          </div>
      </div>
      <div class="header w-full px-10 flex flex-row items-center justify-between overflow-x-hidden">
          <h5 class="text-xs text-zen text-opacity-60">
              {{'txtWellbeingSurveyPreview' | translate:language:ModuleNames.Wellbeing}}</h5>
          <h5 class="text-xs text-zen "><span class="text-opacity-60">{{'txt_questionnaire_name' | translate:language:ModuleNames.Wellbeing}} </span> <span
                  class="text-zen font-medium">{{surveyData.title | extract:language}} </span> </h5>
      </div>
      <div class="footer w-full px-10 flex flex-row items-center justify-between overflow-x-hidden">
          <div class="flex flex-col gap-1.5">
              <p class="text-opacity-50 text-zen text-3xs">{{'txtPoweredby' | translate:language:ModuleNames.Wellbeing}}:</p>
              <img class="w-25 object-cover" src="assets/img/logo.png" alt="">
          </div>

          <div class="flex flex-col items-end gap-1">
              <h5 class="text-xs text-zen text-opacity-60">
                  {{"txtGenerated"|translate:language:ModuleNames.Wellbeing}}: {{date | date: 'dd/MM/yyyy'}}
              </h5>
              <h5 class="text-xs text-zen text-opacity-60">
                  {{"txtAllRights"|translate:language:ModuleNames.Wellbeing}} © {{date | date: 'yyyy'}} -
                  www.zenithr.com</h5>

          </div>
      </div>
  </div>
</div>
