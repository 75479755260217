<z-modal [open]="true" *DemoAccount="'Default'; let isDemo=isDemo">
  <ng-container header>
    <div>
      <!-- <p class="text-sm text-zen">Please reupload the data file after fixing the validation errors.</p> -->
      <h3 *ngIf="!addRespondentType" class="text-2xl font-bold text-zen">{{'btn_add_respondent'|
        translate:language:ModuleNames.Shared}}</h3>
      <h3 *ngIf="addRespondentType" class="text-2xl font-bold text-zen">{{( addRespondentType === 'CANDIDATE' ?
        'btn_add_candidate' : 'btn_add_evaluator')| translate:language:ModuleNames.Shared}}</h3>
    </div>
    <i (click)="respondentAddChange.emit(false)"
      class="fa-regular fa-times leading-12  btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col items-stretch justify-center">
      <div class="flex flex-row justify-between items-center  gap-3 pb-5">
        <a class="btn text-center  leading-12 px-5  w-full rounded text-base whitespace-nowrap" (click)="tab = 0"
          [ngClass]="tab == 0?'exp':'cancel'">
          <i class="fas fa-pen"></i> {{(!addRespondentType?'btn_add_respondent': addRespondentType === 'CANDIDATE' ?
          'btn_add_candidate' : 'btn_add_evaluator')| translate:language:ModuleNames.Shared}}</a>
        <a class="btn text-center  leading-12 px-5 w-full rounded text-base whitespace-nowrap" (click)="tab = 1"
          [ngClass]="tab == 1?'exp':'cancel'">
          <i class="fas fa-file-spreadsheet"></i> {{'btn_import_file'|
          translate:language:ModuleNames.Shared}}</a>
        <a *ngIf="!noTable" class="btn text-center  leading-12 px-5 w-full rounded text-base whitespace-nowrap"
          (click)="prepareTable(2)" [ngClass]="tab == 2?'exp':'cancel'">
          <i class="fas fa-users"></i> {{( !UploadCandidates? 'txt_respondents_pool': '' + addRespondentType ===
          'CANDIDATE' ?"txtCandidates" :"txtEvaluators")| translate:language:ModuleNames.Shared}}</a>
      </div>
      <div *ngIf="tab == 0" class="flex flex-col gap-5 transition-all ease-expo duration-500 overflow-hidden w-full pb-6">
        <!-- <h4 class="text-zen text-2xl">
                    {{txtBasicInfo}}
                </h4> -->
        <div class="flex flex-row gap-5">
          <div class="w-2/5">
            <z-input [language]="language" [label]="'txt_employee_ID'|translate:language:ModuleNames.Shared"
              [maxlength]="smCount" [(ngModel)]="newRespondent.employeeID"></z-input>
            <!-- <span [matTooltip]="txtFieldIsRequired" [matTooltipClass]="'toolTipAlert'"
                            [matTooltipDisabled]="!vRespondentID" [className]="vRespondentID? ' alertLine' : '' ">
                            <mat-form-field class="w-full  -mb-3" appearance="none">
                                <mat-label>{{txt_employee_ID}}</mat-label>
                                <input [maxlength]="smCount" [disabled]="countWord(newRespondent.employeeID,
                                        smCount) > smCount " [maxlength]="mdCount" [(ngModel)]="newRespondent.employeeID"
                                    matInput>
                            </mat-form-field>
                            <h4 *ngIf="countWord(newRespondent.employeeID, smCount)"
                                [ngClass]="{'text-zen-red': countWord(newRespondent.employeeID, smCount)>= smCount}"
                                class="text-sm o-80 charCounter">
                                {{countWord(newRespondent.employeeID, smCount)}} {{txtof}} {{smCount}}</h4>
                        </span> -->
          </div>
          <div class="w-3/5">
            <z-input [invalid]="!valid" [required]="true" [maxlength]="mdCount" [language]="language"
              [label]="(!addRespondentType? 'txt_respondent_name': '' + addRespondentType === 'CANDIDATE' ? 'txtCandidateName': 'txt_evaluator_name')| translate:language:ModuleNames.Shared"
              [(ngModel)]="newRespondent.name"></z-input>
            <!-- <span [matTooltip]="txtFieldIsRequired" [matTooltipClass]="'toolTipAlert'"
                        [matTooltipDisabled]="!vRespondentName" [className]="vRespondentName? ' alertLine' : '' ">
                        <mat-form-field class="w-full  -mb-3" appearance="none">
                            <mat-label>{{!addRespondentType? txt_respondent_name: '' + addRespondentType === 'CANDIDATE' ?
                                txtCandidateName: txt_evaluator_name}}</mat-label>
                                <input [disabled]="countWord(newRespondent.name,
                                        mdCount) > mdCount " [maxlength]="mdCount" [(ngModel)]="newRespondent.name"
                                    matInput>
                            </mat-form-field>
                            <h4 *ngIf="countWord(newRespondent.name, mdCount)> 0"
                                [ngClass]="{'text-zen-red': countWord(newRespondent.name, mdCount)>= mdCount}"
                                class="text-sm o-80 charCounter">
                                {{countWord(newRespondent.name, mdCount)}} {{txtof}} {{mdCount}}</h4>
                        </span> -->
          </div>

        </div>
        <div class="flex flex-row gap-5">
          <div class="w-full">
            <z-input [email]="true" [invalid]="!valid" [maxlength]="mdCount" [required]="true" [language]="language"
              [label]="'txt_email_address'|translate:language:ModuleNames.Shared"
              [(ngModel)]="newRespondent.email"></z-input>
            <!-- <span [matTooltip]="txtFieldIsRequired" [matTooltipClass]="'toolTipAlert'"
                            [matTooltipDisabled]="!vRespondentEmail" [className]="vRespondentEmail? ' alertLine' : '' ">
                            <mat-form-field class="w-full  -mb-3" appearance="none">
                                <mat-label>{{txt_email_address}}</mat-label>
                                <input [disabled]="countWord(newRespondent.name,
                                        mdCount) > mdCount " [maxlength]="mdCount" [(ngModel)]="newRespondent.email"
                                    matInput>
                            </mat-form-field>
                            <h4 *ngIf="countWord(newRespondent.email, mdCount)> 0"
                                [ngClass]="{'text-zen-red': countWord(newRespondent.email, mdCount)>= mdCount}"
                                class="text-sm o-80 charCounter">
                                {{countWord(newRespondent.email, mdCount)}} {{txtof}} {{mdCount}}</h4>
                        </span> -->
          </div>
          <div class="w-2/4" *ngIf="(addRespondentType === 'EVALUATOR')">
            <z-select [color]="'exp'" [label]="'txtRelationshipType'|translate:language:ModuleNames.Shared"
              [required]="true" [options]="evaluatorTypes" [language]="language"
              [name]="'txtRelationshipType'|translate:language:ModuleNames.Shared" [(ngModel)]="relationshipToCandidate"
              (selectionChange)="relationshipToCandidateChange.emit(relationshipToCandidate)">
              <ng-template #valueTemplate let-value>
                {{value?.name| extract:language}}
              </ng-template>

            </z-select>
            <!-- <span [matTooltip]="txtFieldIsRequired" [matTooltipClass]="'toolTipAlert'"
                            [matTooltipDisabled]="!vRelationshipToCandidate"
                            [className]="vRelationshipToCandidate? ' alertLine' : '' ">
                            <mat-form-field class="w-full  -mb-3" appearance="none">
                                <mat-label>{{txtRelationshipType}}</mat-label>
                                <mat-select disableRipple [(ngModel)]="relationshipToCandidate"
                                    (ngModelChange)="relationshipToCandidateChange.emit(relationshipToCandidate)">
                                    <mat-option disableRipple *ngFor="let e of evaluatorTypes" [value]="e.key">
                                        {{ this.extractNameLanguage(e.name) }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span> -->
          </div>
        </div>
        <!-- <div class="flex flex-row items-center justify-between gap-2 bg-zen bg-opacity-10 rounded px-5 py-2"
                        *ngIf="!hideInfo">
                        <div class="flex flex-row items-center gap-1 text-zen  ">
                            <i class="text-zen fal fa-info-circle"></i>
                            {{txt_auto_fill}}
                        </div>
                        <i (click)="hideInfo = true"
                            class="fal fa-times btn cancel text-center leading-7 w-7 rounded"></i>
                    </div> -->
      </div>
      <div *ngIf="tab == 1" class="flex flex-col gap-5 transition-all ease-expo duration-500 overflow-hidden w-full">
        <div class="flex flex-col gap-5 transition-all ease-expo duration-500 overflow-hidden w-full" *DemoAccount="'Section'">
          <div class="flex flex-row">
            <div class="flex flex-col w-2/3 gap-2">
              <h4 class="max-w-xs truncate text-sm text-zen">{{'txt_step'|
                translate:language:ModuleNames.Shared}} 1:
              </h4>
              <p class="text-base">{{'txt_step1' | translate:language:ModuleNames.Shared}}</p>
            </div>
            <div class="flex flex-col w-1/3 gap-2">
              <a (click)="downloadFile()" class="btn cancel px-5 rounded-md leading-12 text-center">
                <i class="fal leading-12 px-1 fa-download"></i>{{'btn_download'|
                translate:language:ModuleNames.Shared}}</a>
            </div>

          </div>
          <div class="flex flex-row">
            <div class="flex flex-col w-2/3 gap-2">
              <h4 class="max-w-xs truncate text-sm text-zen">{{'txt_step'|
                translate:language:ModuleNames.Shared}} 2:
              </h4>
              <p class="text-base">{{'txt_step2'| translate:language:ModuleNames.Shared}}</p>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="flex flex-col w-2/3 gap-2">
              <h4 class="max-w-xs truncate text-sm text-zen">{{'txt_step'|
                translate:language:ModuleNames.Shared}} 3:
              </h4>
              <p class="text-base">{{'txt_step3'| translate:language:ModuleNames.Shared}}</p>
            </div>
            <div class="flex flex-col w-1/3 gap-2">
              <a class="btn exp px-5 rounded-md leading-12 text-center">
                <i class="fal leading-12 px-1 fa-upload"></i>{{'btn_upload_file'|
                translate:language:ModuleNames.Shared}}<input (change)="uploadRespondentFile($event)" type="file"></a>
            </div>
          </div>

          <div class="flex flex-row items-center justify-between gap-2 bg-zen bg-opacity-10 rounded px-5 py-2">
            <div class="flex flex-row items-center gap-1 text-zen  ">
              <i class="text-zen fal fa-info-circle"></i>
              {{'txt_auto_return'| translate:language:ModuleNames.Shared}}
            </div>

          </div>
        </div>
      </div>
      <div [hidden]="tab != 2" class="flex flex-col gap-5 transition-all ease-expo duration-500 overflow-hidden w-full">
        <respondents-pool #pool [essentialObject]="essentialObject"
          (restableSelectionChanged)="tableSelectionChanged($event)" [language]="language" [hasSingleDelete]="false"
          [hasMassDelete]="false" [hasAdd]="false" [hasDownload]="false">
          <ng-container innerHeaderOptions>
            <div class="flex flex-row justify-end items-center w-full">
              <div *ngIf="(addRespondentType === 'EVALUATOR')" class="">
                <z-select [color]="'exp'" [placeholder]="'txtChooseType'|translate:language:ModuleNames.Shared"
                  [required]="true" [options]="evaluatorTypes" [language]="language"
                  [name]="'txtRelationshipType'| translate:language:ModuleNames.Shared"
                  [(ngModel)]="relationshipToCandidate" (selectionChange)="updateRelation($event)">
                  <ng-template #valueTemplate let-value>
                    {{value?.name | extract:language}}
                  </ng-template>
                </z-select>

              </div>
            </div>
          </ng-container>

        </respondents-pool>
        <!-- <t-table  [options]="options"
                    (selectionChanges)="selectionChanged($event)">
                    <ng-template #cellTempate let-element let-column='column'>
                        <ng-container *ngIf="column.field == 'employeeID'">
                            <h5 class="max-w-xs truncate text-sm text-zen">{{ element.employeeID?element.employeeID:'-'
                                }}
                            </h5>
                        </ng-container>
                        <ng-container *ngIf="column.field == 'name'">
                            <h5 class="max-w-xs truncate text-sm text-zen">{{ element.name }}</h5>
                        </ng-container>
                        <ng-container *ngIf="column.field == 'email'">
                            <h5 class="max-w-xs truncate text-sm text-zen">{{ element.email }}</h5>
                        </ng-container>


                    </ng-template>
                    <ng-container headerOptions>
                        <div class="flex flex-row justify-end items-center w-full">
                            <div *ngIf="(addRespondentType === 'EVALUATOR')" class="">
                                <z-select [color]="'exp'" [placeholder]="'txtChooseType'|translate:language:ModuleNames.Shared" [required]="true" [options]="evaluatorTypes"
                                [language]="language" [name]="'txtRelationshipType'| translate:language:ModuleNames.Shared" [(ngModel)]="relationshipToCandidate"
                                (selectionChange)="relationshipToCandidateChange.emit(relationshipToCandidate)">
                                <ng-template #valueTemplate let-value>
                                    {{value?.name | extract:language}}
                                  </ng-template>
                            </z-select>

                            </div>
                        </div>
                    </ng-container>
                </t-table> -->
      </div>
    </div>

  </ng-container>
  <ng-container footer *ngIf="tab != 2" class="flex w-full flex-row justify-between">
    <a (click)="respondentAddChange.emit(false)"
      class="btn cancel  text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{'btn_close'|
      translate:language:ModuleNames.Shared}}</a>
    <a *ngIf="tab == 0" (click)="addSingleRespondent(isDemo)"
      class="btn exp  w-auto text-center  align-middle rounded-md px-8 py-4">{{(!addRespondentType?
      'btn_add_respondent': '' + addRespondentType === 'CANDIDATE' ? 'btn_add_candidate' : 'btn_add_evaluator')|
      translate:language:ModuleNames.Shared}}</a>

  </ng-container>
  <ng-container footer *ngIf="tab == 2" class="flex w-full flex-row justify-between">
    <a (click)="respondentAddChange.emit(false)"
      class="btn cancel  text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{'btn_close'|
      translate:language:ModuleNames.Shared}}</a>
    <a (click)="bulkRespondent()"
      class="btn exp  w-auto text-center  align-middle rounded-md px-8 py-4">{{(!addRespondentType?
      "txtAddRespondent_s": '' + addRespondentType === 'CANDIDATE' ?"txtAddCandidate_s" :"txtAddEvaluator_s")|
      translate:language:ModuleNames.Shared}}</a>
  </ng-container>

</z-modal>