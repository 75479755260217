import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixed'
})
export class ToFixedPipe implements PipeTransform {

  transform(value: any, digits: number): any {
      return parseFloat(value).toFixed(digits);
    }
}
