<div [@listAnimation]="data?.items.length" class="bg-white w-full rounded " *ngIf="fetch && comments$ | async as data">
  <ng-container *ngFor="let answer of data?.items; let j = index">
    <mat-divider *ngIf="j != 0"></mat-divider>
    <div class="break-all">
      <div class="flex flex-col gap-1 px-3 py-2 breakAll">
        <h5 class="text-zen font-normal text-sm breakAll">{{answer.comment}}</h5>
      </div>
    </div>
  </ng-container>
  <div class="flex flex-row justify-center pb-2" *ngIf="showMoreComments(data.totalCount)"
    (click)="fetchMoreComments()">
    <span class="btn cancel leading-10 px-5 rounded-sm">{{'txtShowMoreComment' |
      translate:language:ModuleNames.Wellbeing }} ({{ data?.items.length }} of {{ data.totalCount }})</span>
  </div>
</div>
<ng-container *ngIf="!fetch">{{ 'txtExportCommentsUnavailable' | translate:language:ModuleNames.Wellbeing }}
</ng-container>
