<zen-accordions *ngIf="dimensions.length">
  <ng-container *ngFor="let dimension of dimensions; let s = index">
    <zen-accordion #accordion extraClasses="rounded mt-2" *DemoAccount="'Default'; let isDemo=isDemo">
      <ng-container head>
        <div class="flex flex-row justify-between items-center px-5 cursor-pointer py-2 relative" *DemoAccount="s > 1?'Button':'Default'">
          <h4 class="text-zen text-lg font-medium uppercase ">{{dimension.name | extract:language}}</h4>
          <div class="flex flex-row justify-end items-center gap-2">
            <div
              class="flex flex-row gap-1 px-3 rounded-md border-zen border-opacity-10 border h-12"
            >
              <h5 class="text-zen font-medium uppercase leading-12">
                {{ "txtMean" | translate : language : ModuleNames.Wellbeing }}:
              </h5>
              <span
                class="leading-12"
                [style.color]="
                  wellbeingScalesService.resolveChartColor(dimension.mean)
                "
              >
                <i
                  [ngClass]="
                    dimension.mean > 80 ? 'fa-caret-up' : 'fa-caret-down'
                  "
                  class="fa text-base px-1 leading-12"
                ></i>
                {{ toFixed(dimension.mean, 1) + "%" }}
              </span>
            </div>

            <div class="btn cancel leading-12 w-12 rounded-md relative h-12">
              <span
                class="absolute h-0 border-b border-zen w-6 right-3 left-3 top-2/4"
              ></span>
              <span
                class="absolute h-0 border-b border-zen w-6 right-3 left-3 top-2/4 transform transition ease-expo"
                [TourStep]="{
                  title:
                    'txt_Statement_817'
                    | translate : language : ModuleNames.Wellbeing,
                  text:
                    'txt_Expandthe_792'
                    | translate : language : ModuleNames.Wellbeing,
                  order: 2,
                  tourKey: 'wellbeing-closed-2',
                  on: (language | language) ? 'right' : 'left',
                  isRows: true
                }"
                [ngClass]="{ 'rotate-90': !accordion.active }"
              ></span>
            </div>
            <span> </span>
          </div>
        </div>
      </ng-container>
      <ng-container body>
        <div  *ngIf="!isDemo || isDemo && s < 2" class="flex flex-col items-stretch gap-2.5 px-5">
          <!-- <ng-container *ngFor="let sub of driver.engagementSubDrivers">
            <h4 *ngIf="!driver?.isCustomDriver" class="text-zen font-medium">{{extractNameLanguage(sub.name)}}
            </h4>

          </ng-container> -->
          <div class="flex flex-col">
            <div class="flex flex-row gap-1 items-center">
              <div class="w-8/12">
                <h5 class="text-zen font-medium text-opacity-50 text-sm">
                  {{
                    "txtStatement"
                      | translate : language : ModuleNames.Wellbeing
                  }}
                </h5>
              </div>
              <div
                class="w-4/12 flex flex-row items-center justify-between gap-2"
              >
                <h5 class="text-zen font-medium text-opacity-50 text-sm w-20">
                  {{ "txtMean" | translate : language : ModuleNames.Wellbeing }}
                </h5>
                <h5 class="text-zen font-medium text-opacity-50 text-sm">
                  {{
                    "txtDistribution"
                      | translate : language : ModuleNames.Wellbeing
                  }}
                  (%)
                </h5>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-stretch">
            <ng-container
              *ngFor="let question of dimension.questions; let n = index"
            >
              <mat-divider *ngIf="n != 0"></mat-divider>
              <analysis-question-template
                [answers]="returnAnswers(question.answers)"
                [name]="extractNameLanguage(question.name)"
                [score]="question.mean"
              ></analysis-question-template>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </zen-accordion>
  </ng-container>
</zen-accordions>
