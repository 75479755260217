import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalService } from '@ex/module/core/services/local.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { CreatorBase } from 'survey-creator-core';

@Component({
  selector: 'app-choices-logic-properties',
  templateUrl: './choices-logic-properties.component.html',
  styleUrls: ['./choices-logic-properties.component.scss']
})
export class ChoicesLogicPropertiesComponent implements OnInit {

  ModuleNames = ModuleName;
  @Input() language: string = "EN";

  @Input() model!: CreatorBase;

  @Input() showChoicesLogic: boolean = false;
  @Input() showChoicesLogicConditions: { choice: any, isEditMode?: boolean, conditions: { type: string, question: string, operation: string, answer?: string, isTextAnswer?: boolean }[] }[] = [];

  @Output() showChoicesLogicChange = new EventEmitter<boolean>();
  @Output() showChoicesLogicConditionsChange = new EventEmitter<any>();

  availableQuestions: any[] = [];

  operations: any[] = [
    { key: "empty", value: "Empty" },
    { key: "notempty", value: "Not empty" },
    { key: "=", value: "Equals" },
    { key: "<>", value: "Does not equal" },
    { key: "contains", value: "Contains" },
    { key: "notcontains", value: "Does not contain" },
    { key: ">", value: "Greater than" },
    { key: "<", value: "Less than" },
    { key: ">=", value: "Greater than or equal to" },
    { key: "<=", value: "Less than or equal to" }
  ];

  constructor() { }

  ngOnInit(): void {
    const questions = this.model.survey.getAllQuestions();
    const selectedElementNumber = questions.findIndex(x=>x.name == this.model.selectedElement["name"]) + 1;
    debugger
    questions.forEach((question: any, index) => {
      var questionNumber = index +1;

      if (question.name != this.model.selectedElement['name'] && questionNumber < selectedElementNumber) {
        //Hide file, matrix and ranking questions because they don't have any available operations
        if (question.getType() != "file" && question.getType() != "matrix" && question.getType() != "ranking" && question.dateType!= "range")
          this.availableQuestions.push({ key: question.name, value: question.name });
      }
    });
  }

  startEditMode(choiceIndex: number) {
    if (this.showChoicesLogicConditions[choiceIndex].conditions.length == 0)
      this.addMainCondition(choiceIndex);

    this.showChoicesLogicConditions[choiceIndex].isEditMode = true;
  }

  stopEditMode(choiceIndex: number) {
    this.showChoicesLogicConditions[choiceIndex].isEditMode = false;
  }

  addMainCondition(choiceIndex: number) {
    this.showChoicesLogicConditions[choiceIndex].conditions = [];
    this.showChoicesLogicConditions[choiceIndex].conditions.push({ type: "Main", question: null, operation: null, answer: null, isTextAnswer: null });
  }

  addAndCondition(choiceIndex: number) {
    this.showChoicesLogicConditions[choiceIndex].conditions.push({ type: "And", question: null, operation: null, answer: null, isTextAnswer: null });
  }

  addOrCondition(choiceIndex: number) {
    this.showChoicesLogicConditions[choiceIndex].conditions.push({ type: "Or", question: null, operation: null, answer: null, isTextAnswer: null });
  }

  removeCondition(choiceIndex: number, conditionIndex: number) {
    this.showChoicesLogicConditions[choiceIndex].conditions.splice(conditionIndex, 1);
  }

  //////
  isTextAnswer(questionName: any) {
    var q = this.model.survey.getQuestionByName(questionName);
    return q.getType() == "text";
  }

  getOptions(questionName: any) {
    var q = this.model.survey.getQuestionByName(questionName);

    var choices = [];
    q['choices'].forEach((choice: any) => {
      choices.push({ key: choice.id, value: choice.id });
    });

    return choices;
  }

  selectQuestion(event: any, choiceIndex: number, conditionIndex: number) {
    this.showChoicesLogicConditions[choiceIndex].conditions[conditionIndex].question = event;
  }

  selectOperation(event: any, choiceIndex: number, conditionIndex: number, condition:any) {
    this.showChoicesLogicConditions[choiceIndex].conditions[conditionIndex].operation = event;
    condition.options =  this.getOptions(condition.question);
  }

  selectAnswer(event: any, choiceIndex: number, conditionIndex: number, isTextAnswer: boolean) {
    this.showChoicesLogicConditions[choiceIndex].conditions[conditionIndex].isTextAnswer = isTextAnswer;
    this.showChoicesLogicConditions[choiceIndex].conditions[conditionIndex].answer = event;
  }

  getCharacter(index){
    return getcharacterByIndex(index);
  }

  showAnswerBox(choiceIndex: number, conditionIndex: number) {
    var condition = this.showChoicesLogicConditions[choiceIndex].conditions[conditionIndex];

    return condition.operation != null &&
      condition.operation != "" &&
      condition.operation != "empty" &&
      condition.operation != "notempty";
  }
}

function getcharacterByIndex(index) {

  let localLanguage = getUserPreferredLanguage()


if(localLanguage == "AR")
{
    return getMainArabicCharacterByIndex(index);
}
else
{
  return String.fromCharCode(65 + index);
}
}

function getUserPreferredLanguage(): string {
  const localService = new LocalService();
   return localService.getJsonValue('language');
}

function getMainArabicCharacterByIndex(index) {
  const mainArabicChars = [
    'أ', 'ب', 'ت', 'ث', 'ج', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ك', 'ل', 'م', 'ن', 'ه', 'و', 'ي',];

  if (index < 0 || index >= mainArabicChars.length) {
    return ''; // Invalid index
  }

  return mainArabicChars[index];
}
