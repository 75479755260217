import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SurveyModel } from "survey-core";
import { TabDesignerViewModel } from "survey-creator-core";
import { BaseAngular } from "survey-angular-ui";

@Component({
  selector: 'app-zen-designer-survey',
  templateUrl: './zen-designer-survey.component.html',
  styleUrls: ['./zen-designer-survey.component.scss'],
  // styles: [":host { display: none; }"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ZenDesignerSurveyComponent extends BaseAngular<SurveyModel> {

  @Input() model!: TabDesignerViewModel;

  protected getModel(): SurveyModel {
    return this.model.survey;
  }

  public get creator() {
    return this.model.creator;
  }

  public get survey()
  {
    return this.creator.survey;
  }

  render() {
    console.log("Zen Desiger component");
  }
}
