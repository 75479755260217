import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CDKPortalComponent, DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';

@Component({
  selector: 'app-rename-survey',
  templateUrl: './rename-survey.component.html',
  styleUrls: ['./rename-survey.component.scss']
})
export class RenameSurveyComponent extends WbBaseComponent implements OnInit, CDKPortalComponent {

  constructor(@Inject(DATA_TOKEN) private data: any) {
    super();
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.selectedEntity = this.data.selectedEntity;
    this.selectedToEdit = JSON.parse(JSON.stringify(this.data.selectedToEdit)) ;
    this.language = this.data.language;
    this.open = true;
  }
  selectedEntity;
  selectedToEdit;
  language;
  open:boolean;
  closeModal() {
    this.open = false;
    this.close.emit(true);
  }
}
