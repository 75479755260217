<z-modal [open]="open" [autoWidth]="true">
    <ng-container header>
        <div class="flex flex-row items-center gap-5 w-screen max-w-xl">
            <i
                class="fal fa-border-all text-white bg-exp shadow-xl rounded-full h-18 w-18 text-xl text-center leading-18 "></i>
            <h4 class="text-2xl font-bold text-zen">{{'txtViewBy'| translate:language:ModuleNames.Exit }}</h4>
            <p class="text-sm text-zen">{{ 'txtSelectEntity'| translate:language:ModuleNames.Exit }}</p>

        </div>
    </ng-container>
    <ng-container body>
        <zen-tree [data]="treeList" (selectedEntityChange)="tselectedChildEntityChanged($event)"
            [(selectedEntity)]="tselectedChildEntity">
            <ng-template let-row>
                <div class="flex flex-row justify-between w-full" (click)="logData(row)">
                    <span class="text-zen text-sm font-medium ">{{row.entity.name| extract:language}} <span
                            *ngIf="showLeaverCount">- ({{
                            row.entity.count || 0 }} {{ 'txtPeople' | translate:language:ModuleNames.Exit }})</span> <i
                            class="fa-solid fa-lock opacity-60" *ngIf="!row.hasPermission"></i></span>
                    <a [ngClass]="row.entity.key == tselectedChildEntity.entity.key ?'block':'hidden'"
                        class="btn exp rounded-r-full rounded-l-full leading-5 px-2 text-xs mx-5">
                        {{'txtSelected'| translate:language:ModuleNames.Exit }}
                    </a>
                </div>
            </ng-template>
        </zen-tree>
    </ng-container>
    <ng-container footer>
        <div class="flex flex-col items-stretch w-full gap-5">
            <div class="flex w-full flex-row justify-between">
                <a (click)="close.emit(true)"
                    class="btn cancel  text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{'btn_cancel'|
                    translate:language:ModuleNames.Exit }}</a>
                <button  [ngClass]="{'opacity-70 pointer-events-none': disableApplyButton}"
                    class="btn exp  w-auto text-center  align-middle rounded-md px-8 py-4" *DemoAccount="'Button'; let isDemo=isDemo" (click)="isDemo?'':entitySelected()">
                    <span class="mx-1">{{'txtApply'| translate:language:ModuleNames.Exit }}</span>
                </button>
            </div>
        </div>
    </ng-container>
</z-modal>
