import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ModuleName } from '@ex/module/shared/enums/ModuleName';

import { ChildrenAnimation, routeInnerAnimations } from 'src/app/shared/animations/allAnimations';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';
import { Direction } from '@angular/cdk/bidi';
import { LocalService } from '@ex/module/core/services/local.service';
import { BaseComponent } from '@ex/module/core/domain/base-component';

@Component({
  selector: 'app-form-closed-successfully-view',
  templateUrl: './form-closed-successfully-view.component.html',
  styleUrls: ['./form-closed-successfully-view.component.scss'],
  animations: [ChildrenAnimation, routeInnerAnimations],
})
export class FormClosedSuccessfullyViewComponent extends ModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Input() selectedForm: any;
  @Output() openChange = new EventEmitter();

  language = 'EN';
  ModuleNames = ModuleName;
  id: any;
  direction: Direction = "ltr";

  constructor(private router: Router, private localService: LocalService) { super(); }

  ngOnInit(): void {
    this.id = this.selectedForm.id;
    this.open = true

    this.language = this.localService.getJsonValue('language')
    if (this.language == "AR") {
      this.direction = "rtl"
    }
    else {
      this.direction = "ltr"
    }
  }



}
