import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsFilter, AnalyticsService } from '../../../data-access/services/analytics.service';
import { Subject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { WellbeingScalesService } from '../../../utils/wellbeing-scales.service';
import { CHART_DATA_RESPONSE_STRATEGY, CommonChartFunctions } from 'src/app/core/services/chart-data.service';
import { DashletData } from 'src/app/onboarding/services/chart-data.service';
import { SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { CriteriaResolverService } from '../../../data-access/services/criteria-resolver.service';
import { WbBaseComponent } from '../../../utils/wellbeing-base.component';
import { KeyResolverService } from 'src/app/core/services/key-resolver.service';
import { WellbeingKeyResolverNames } from '../../../utils/wellbeing-key-resolver-names';
import { ChartOptionsService } from 'src/app/shared/chart/chart-options.service';

@Component({
  selector: 'wellbeing-by-criteria',
  templateUrl: './wellbeing-by-criteria.component.html',
  styleUrls: ['./wellbeing-by-criteria.component.scss']
})
export class WellbeingByCriteriaComponent extends WbBaseComponent implements OnInit {

  @Input()
  language = "EN"

  filters: AnalyticsFilter

  surveyCriterias = [];

  selectedCriteriaKey;

  criteriaChanged = new Subject();
  criteriaChanged$ = this.criteriaChanged.asObservable();

  chartColors = [];

  colors = ['#FF4530', '#FF9600', '#FFC107', "#68DD37", '#36B37E'];

  criteriaChart = {
    DashletType: 'stackV',
    canGroup: false,
    question: {
      canGroup: true
    },
    DashletChartTypes: [],
  }

  criteriaChartData: DashletData;
  selectedEntity;
  constructor(private keyResolverService: KeyResolverService
    , private analysisService: AnalyticsService, private chartOptionsService: ChartOptionsService
    , private surveyAnalysisService: SurveyAnalysisService
    , private criteriaResolverService: CriteriaResolverService
    , private wellbeingScalesService: WellbeingScalesService
    , private commonChartFunctions: CommonChartFunctions
  ) {
    super()
  }

  ngOnInit(): void {

    this.fetchAnalysisByCriteria();

    this.addSubscription(this.analysisService.filters$.pipe(
      switchMap(filters => {
        // Fetch the List of Dimensions for the dropdown based on the filter

        return of({ filters, criterias: filters.survey.surveyCriterias });

      })
    ).subscribe(({ filters, criterias }) => {
      this.filters = filters;
      this.surveyCriterias = criterias;
      this.setupChartColors();
      this.selectedCriteriaKey = criterias[0]?.key;
      this.onCriteriaChange(this.selectedCriteriaKey);
    }));
  }

  private fetchAnalysisByCriteria() {
    this.addSubscription(this.criteriaChanged$
      .pipe(
        switchMap((key: any) => {
          //Fetch dimension analysis
          return this.surveyAnalysisService.getWellbeingExperienceByCriteria(key, {
            companyKey: this.filters.companyKey,
            surveyKey: this.filters.survey.key,
            tenantKey: this.filters.tenantKey,
            filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []],
            type: 'ec'
          }).pipe(map(data => {
            let tdata = this.commonChartFunctions.reArrangeArray(data, this.wellbeingScales.map(x => x.scale), 'group');
            return tdata

          }))
        })
      )
      .subscribe((data: any) => {

        const scaleResolver = this.keyResolverService.getResolver(WellbeingKeyResolverNames.ScaleResolver);
        this.keyResolverService.setKeyResolver(scaleResolver);

        data.forEach(x => {
          x.key = x.group;
          x.group = this.keyResolverService.resolve(x.group, this.language, this.ModuleNames.Wellbeing);
          x.category = this.criteriaResolverService.extract(this.selectedCriteriaKey, x.category, this.language);
        });


        this.criteriaChartData = CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data).process();
      }));
  }

  returnIndexColor(data) {
    return data.bar.series.map(x => {
      return this.wellbeingScalesService.config.find(n => n.key == x.key).color
    })
  }

  onCriteriaChange(criteria) {
    this.criteriaChanged.next(criteria);
  }

  setupChartColors() {
    this.chartColors = [
      { key: this.localize(WellbeingScalesService.VeryPoorExperience, this.language, this.ModuleNames.Wellbeing), color: '#FF5252' },
      { key: this.localize(WellbeingScalesService.PoorExperience, this.language, this.ModuleNames.Wellbeing), color: '#FF9000' },
      { key: this.localize(WellbeingScalesService.FairExperience, this.language, this.ModuleNames.Wellbeing), color: '#F7D323' },
      { key: this.localize(WellbeingScalesService.GoodExperience, this.language, this.ModuleNames.Wellbeing), color: '#68DD37' },
      { key: this.localize(WellbeingScalesService.VeryGoodExperience, this.language, this.ModuleNames.Wellbeing), color: '#00CD3D' },
    ];
  }

}
