import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-answer-ten',
  templateUrl: './single-answer-ten.component.html',
  styleUrls: ['./single-answer-ten.component.scss']
})
export class SingleAnswerTenComponent implements OnInit {

  constructor() { }
  @Input('steps')steps = [
    {key:0, color:'bg-zen-red'},
    {key:1, color:'bg-zen-red'},
    {key:2, color:'bg-zen-red'},
    {key:3, color:'bg-zen-red'},
    {key:4, color:'bg-zen-red'},
    {key:5, color:'bg-zen-red'},
    {key:6, color:'bg-zen-red'},
    {key:7, color:'bg-zen-orange'},
    {key:8, color:'bg-zen-orange'},
    {key:9, color:'bg-zen-green'},
    {key:10, color:'bg-zen-green'},
  ]
  @Input() question;
  @Input() export: boolean = false;;
  @Input() legends: boolean |any = [
    {steps:7, name:'Unsatisfied'},
    {steps:2, name:'Neutral'},
    {steps:2, name:'Satisfied'},
  ];
  ngOnInit(): void {
  }

}
