<div class="flex flex-row items-center justify-between px-7 h-20 bg-zen-lite text-white">
  <div class="flex flex-row gap-3 items-center">
    <i class="fa-regular fa-reply text-2xl"></i>
    <div class="flex flex-col gap-1">
      <h5 class="text-sm font-medium">{{ "txt_response_rate" | translate:language:ModuleNames.Wellbeing }}</h5>
      <h5 class="text-xs opacity-50" [ngClass]="{'dirLTR': language|language}">{{ completed }} {{'txtof'| translate:language:ModuleNames.Shared}} {{total}} {{'txtResponded'|
        translate:language:ModuleNames.Shared}}</h5>
    </div>
  </div>
  <h5 class="text-lg font-medium">{{precentage | number:'1.1-2'}}%</h5>
</div>
