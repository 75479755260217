<div (click)="!disable?toggleControl():''" [ngClass]="{'pointer-events-none opacity-80':disable}" class="flex flex-row items-center justify-between  rounded btn gap-5">
    <h5 *ngIf="label" class="text-zen text-sm font-normal">{{label}}</h5>
    <div  class="flex flex-row h-auto w-11 minW11 rounded items-center p-px" [class]="toggle?'bg-'+ color:'bg-zen'"
        [ngClass]="{'bg-opacity-100':toggle && !disable, 'bg-opacity-50':!toggle, 'bg-opacity-70':disable}" >
        <div class="flex flex-row justify-end items-center transition-all ease-expo  duration-500 p-px"  [ngClass]="toggle?'w-full':'inactiveWidth'">
            <span class="block bg-white rounded h-5 w-5 border border-inset transition-all ease-expo"
                [ngClass]="toggle && !disable?'border-opacity-100':'border-opacity-50'"></span>

        </div>
    </div>
</div>