import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Entity } from '../../core/data-models/Entity';
import { Notification } from '../../core/data-models/notification';
import { Tenant } from '../../core/data-models/tenant';
import { LanguageService } from '../../core/services/language.service';
import { UnitBalanceService } from '../../core/services/unit-balance.service';
import { ModuleName } from '../enums/ModuleName';
import { TranslatePipe } from '../pipes/translate.pipe';

@Component({
  selector: 'app-units-notification-dialog',
  templateUrl: './units-notification-dialog.component.html',
  styleUrls: ['./units-notification-dialog.component.scss']
})
export class UnitsNotificationDialogComponent implements OnInit {
  tenant: Tenant;
  language: string;
  entityAccountBalances: any;
  title: string;
  message: string;
  units: number;
  notificationType: string;
  notification: Notification;
  companies: Entity[] = [];
  ModuleNames = ModuleName
  // txt_unitsAdded: string;
  // txt_totalUnits: string;
  // txt_units: string;
  // txt_close: string;
  // txt_Exit_Questionnaire: string;
  // txt_Employee_Engagement_Survey: string;
  // txt360DegreeFeedback: string;
  surveyTotalUnits: number = 0;
  surveyName: string = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<UnitsNotificationDialogComponent>,
    private unitBalanceService: UnitBalanceService, private languageService: LanguageService, private TranslationPipe: TranslatePipe) { }
  ngOnInit(): void {
    this.tenant = this.data.tenant;
    this.language = this.data.language;
    this.title = this.data.title;
    this.units = this.data.units;
    this.message = this.data.message;
    this.notificationType = this.data.notificationType;
    this.notification = this.data.notification;
    this.companies = this.data.companies;
    // this.setUpTextFields();
    this.fetchData();

    if (this.notification?.units) {

    } else {
      this.notification.units = 0;
    }

  }

  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }



  companyName: string;
  fetchData() {
    let request = { tenantKey: this.tenant.key };


    this.message = this.message.replace('#UNITS#', this.notification.units.toString());
    this.unitBalanceService.fetchTenantCompanyBalance(request).subscribe((response: any) => {
      if (response.response == 'SUCCESS') {
        this.entityAccountBalances = response.data;
        this.message = this.message.replace('#UNITS#', this.notification.units.toString());



        if (this.notificationType != 'UNIT_CREDIT_MAIN_BALANCE')
          this.companyName = this.extractNameLanguage(this.companies.find(c => c.key == this.notification.companyKey).name);


        if (this.notificationType == 'UNIT_CREDIT_MAIN_BALANCE') {
          if (this.notification.surveyName == 'EXIT') {
            this.surveyName = this.TranslationPipe.transform("txt_Exit_Questionnaire", this.language, this.ModuleNames.Shared);
            this.surveyTotalUnits = this.entityAccountBalances.exitBalance;
          } else if (this.notification.surveyName == 'ENGAGEMENT') {
            this.surveyName = this.TranslationPipe.transform("txt_Employee_Engagement_Survey", this.language, this.ModuleNames.Shared);
            this.surveyTotalUnits = this.entityAccountBalances.engagementBalance;
          } else if (this.notification.surveyName == 'ASSESSMENT') {
            this.surveyName = this.TranslationPipe.transform("txt360DegreeFeedback", this.language, this.ModuleNames.Shared);
            this.surveyTotalUnits = this.entityAccountBalances.assessmentBalance;
          }
        } else {
          let entityBalanceDistribution = this.entityAccountBalances.entities.find(e => e.key == this.notification.companyKey);
          if (this.notification.surveyName == 'EXIT') {
            this.surveyName = this.TranslationPipe.transform("txt_Exit_Questionnaire", this.language, this.ModuleNames.Shared);
            this.surveyTotalUnits = entityBalanceDistribution.balanceDistribution.ExitAllocation;
          } else if (this.notification.surveyName == 'ENGAGEMENT') {
            this.surveyName = this.TranslationPipe.transform("txt_Employee_Engagement_Survey", this.language, this.ModuleNames.Shared);
            this.surveyTotalUnits = entityBalanceDistribution.balanceDistribution.EngagementAllocation;
          } else if (this.notification.surveyName == 'ASSESSMENT') {
            this.surveyName = this.TranslationPipe.transform("txt360DegreeFeedback", this.language, this.ModuleNames.Shared);
            this.surveyTotalUnits = entityBalanceDistribution.balanceDistribution.AssessmentAllocation;
          }
        }
        this.message = this.message.replace('#UNITS#', this.notification.units.toString());
        this.message = this.message.replace('#COMPANYNAME#', this.companyName);
        this.message = this.message.replace('#SURVEYNAME#', this.surveyName);
      }
    });
  }
}