export class EntityGroup {
  key: string;
  groupKey: string;
  name: any[] = [];
  parentKey: string = '';
  isCompanyGroup: boolean = false;
  usedByExit: boolean = false;
  usedByEngagement: boolean = false;
  usedByAssessment: boolean = false;

}
