import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zen-main-container',
  templateUrl: './zen-main-container.component.html',
  styleUrls: ['./zen-main-container.component.scss']
})
export class ZenMainContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
