<div class="flex flex-col gap-2 w-full relative minw-44">
    <h5 *ngIf="label" class="text-xs text-zen font-medium truncate whitespace-nowrap">{{ label }} <i *ngIf="required" class="fa-regular fa-asterisk text-3xs transform -translate-y-1"></i></h5>
    <div [ngClass]="isDisabled?'bg-zen bg-opacity-20':'bg-white'" class="text-sm rounded text-zen border  border-zen border-opacity-25 px-3 h-9 gap-3 leading-9 truncate flex flex-row justify-between items-center">
        <div class="flex flex-row items-center w-full">
            <input (keydown)="change($event)" [maxlength]="maxCount" [disabled]="isDisabled || (selected?.length > maxCount +1 && maxCount)" [required]="required"
            [pattern]="email?emailPattern:false" #field="ngModel" type="text" [placeholder]="placeholder?placeholder:''"
            class="text-sm text-zen w-full h-full border-none bg-transparent" [ngModel]="selected"
            (ngModelChange)="handleChange($event)" [email]="email && !number"
            [type]="email?'email':(number?'number':password && hide?'password':'text')"
            [ngClass]="{'border-zen-red':field.invalid && (field.dirty || field.touched)|| (field.invalid && invalid)}">
        </div>
     
        <button *ngIf="password" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <i [className]="hide? 'is-size-5 iconBlackBack fas fa-eye-slash' : 'is-size-5 fas fa-eye' "></i>
      </button>
    </div>

</div>

<div *ngIf="invalid || selected?.length > maxCount -5" class="flex flex-row justify-between h-0 transform translate-y-1">
    <div class="alert alert-danger ">
        <validation-required [language]="language" *ngIf="field.errors?.required "></validation-required>
        <validation-email [language]="language" *ngIf="field.errors?.pattern"></validation-email>
    </div>
    <div *ngIf="selected?.length > maxCount -5 && maxCount"
        class="text-red-600 text-xxs flex flex-row items-center gap-1 leading-5">
        {{maxCount}}/{{selected?.length}}</div>
</div>