import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Checklist } from '../../core/data-models/checklist';
import { ChecklistGroup } from '../../core/data-models/checklist-group';
import { ChecklistItem } from '../../core/data-models/checklist-item';
import { LanguageService } from '../../core/services/language.service';
import { ModuleName } from '../enums/ModuleName';
import {
  ExcludeFromAverageDto,
  ExcludedCompanyFilterDto,
} from '@ex/wellbeing/analysis';

@Component({
  selector: 'custom-tree-checklist',
  templateUrl: './custom-tree-checklist.component.html',
  styleUrls: ['./custom-tree-checklist.component.scss'],
})
export class CustomTreeChecklistComponent implements OnInit {
  checklists;
  @Input('checklist') set fullCheckList(list: Checklist) {
    this.checklists = list;
  }
  @Input('isOptionalAveraged') isOptionalAveraged: boolean;
  @Input() tourStep: any;
  @Input('language') language: string; //ExcludedCompanyFilterDto
  @Output() checklistChanged = new EventEmitter();
  @Output() getExcludedCompaniesResults = new EventEmitter();
  excludedCompaniesList: ExcludedCompanyFilterDto[] = [];
  panelOpenState = false;
  constructor(private languageService: LanguageService) {}
  ngOnInit(): void {}
  ModuleNames = ModuleName;

  extractNameLanguage(eG: any, isInner?) {
    if (isInner) {
      return this.languageService.extractNameLanguage(eG, 'EN');
    } else {
      return this.languageService.extractNameLanguage(eG, this.language);
    }
  }

  checklistCheckedChanged(event, checklist: Checklist) {
    checklist.checklistGroups = checklist.checklistGroups.filter((cG) => {
      cG.checklistItems.filter((cI) => {
        cI.isChecked = event.checked;
        return true;
      });
      return true;
    });
    this.checklistChanged.emit(this.checklists);
  }

  checklistItemsCheckedChanged(
    event,
    checklist: Checklist,
    checklistGroup?: ChecklistGroup,
    checklistItem?: ChecklistItem
  ) {
    if (event.checked) {
      if (!checklist.isChecked) checklist.isChecked = true;
    } else {
      let itemChecked = checklist.checklistGroups.find((cG) => {
        if (cG.checklistItems.find((cI) => cI.isChecked == true)) return true;

        return false;
      });
      if (!itemChecked) checklist.isChecked = false;
    }
    checklist.checklistGroups = checklist.checklistGroups.filter((cG) => {
      let checkItems = cG.checklistItems.filter(
        (cI) => cI.isChecked == true
      ).length;
      if (checkItems > 1) {
        cG.isAveraged = true;
      } else {
        cG.isAveraged = false;
      }
      return true;
    });
    this.checklistChanged.emit(this.checklists);
  }
  isAveragedChanged(
    event,
    checklist: Checklist,
    checklistGroup?: ChecklistGroup
  ) {
    let results: ExcludedCompanyFilterDto = { year: '', companyKey: '' };
    if (!event.checked) {
      if (
        !this.excludedCompaniesList.some(
          (el) =>
            el.companyKey === checklist.key && el.year === checklistGroup.key
        )
      ) {
        results.year = checklistGroup.key;
        results.companyKey = checklist.key;
        this.excludedCompaniesList.push(results);
      }
    } else {
      const index = this.excludedCompaniesList.findIndex((object) => {
        return (
          object.companyKey === checklist.key &&
          object.year === checklistGroup.key
        );
      });
      this.excludedCompaniesList.splice(index);
    }
    this.getExcludedCompaniesResults.emit(this.excludedCompaniesList);
  }
}
