import { Injectable } from '@angular/core';
import { KeyNameDto } from '@ex/core/common';
import { SurveyCriteriaDto } from '@ex/wellbeing/surveys';
import { ExtractPipe } from 'src/app/shared/pipes/extract.pipe';

export interface SurveyCriteriaDropdown {
  key: string,
  name: KeyNameDto[],
  options: any[],
  isCustom: boolean,
  isEntity?: boolean,
}

@Injectable({
  providedIn: null
})
export class CriteriaResolverService {

  constructor(private extractPipe: ExtractPipe) {
  }
  private surveyCriterias: SurveyCriteriaDropdown[];


  setSurveyCriterias(surveyCriterias: SurveyCriteriaDropdown[]) {
    this.surveyCriterias = surveyCriterias;
  }

  extractTranslation(data, criteriaKey, values, language) {
    let currentCriteria = this.surveyCriterias.find(cr => cr.key == criteriaKey);
    console.log(data);
    for (let index = 0; index < data.length; index++) {
      for (let ind = 0; ind < values.length; ind++) {
        let name = currentCriteria.options.find(x => x.key == data[index][values[ind]]).name;
        if (name)
          data[index][values[ind]] = this.extractPipe.transform(name, language);
      }
    }
    return data;
  }

  extract(criteria: string, key: string, language: string) {

    const surveyCriteria = this.surveyCriterias.find(x => x.key == criteria);

    if (!surveyCriteria) return key;

    const option: any = surveyCriteria.options.find(x => x.key == key);

    return this.extractPipe.transform(option?.name, language) || key;
  }
}
