import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'new-modal',
  templateUrl: './new-modal.component.html',
  styleUrls: ['./new-modal.component.scss'],
  animations: [ trigger('inOutAnimation', [
    transition(':enter', [
      style({ transform: 'scale(1.05)', opacity: 0, filter: 'blur(6px)' }),
      animate('.2s ease-in', style({ transform: 'scale(1)', opacity: 1, filter: 'blur(0px)'})),
    ]),
    transition(':leave', [
      style({
        transform: 'scale(1) translateY(0px)',
        opacity: 1,
        filter: 'blur(0px)',
      }),
      animate(
        '.2s ease-in',
        style({ transform: 'scale(.95)', opacity: 0, filter: 'blur(6px)' })
      ),
    ]),
  ])]
})
export class NewModalComponent implements OnInit {

  constructor() { }

  @Input() open: boolean = false;
  @Input() color;
  @Input() centerIcon = null;
  
    ngOnInit(): void {
    }
  
  }
   