import type { CreateUpdateLeaverAnswerDto, LeaverAnswerNoteDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LeaverAnswerNotesService {
  apiName = 'Default';

  create = (request: CreateUpdateLeaverAnswerDto) =>
    this.restService.request<any, LeaverAnswerNoteDto>({
      method: 'POST',
      url: '/api/exit/leavers/notes',
      body: request,
    },
    { apiName: this.apiName });

  delete = (key: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/exit/leavers/notes/${key}`,
    },
    { apiName: this.apiName });

  get = (key: string) =>
    this.restService.request<any, LeaverAnswerNoteDto>({
      method: 'GET',
      url: `/api/exit/leavers/notes/${key}`,
    },
    { apiName: this.apiName });

  getList = (leaverKey: string) =>
    this.restService.request<any, LeaverAnswerNoteDto[]>({
      method: 'GET',
      url: '/api/exit/leavers/notes/all',
      params: { leaverKey },
    },
    { apiName: this.apiName });

  update = (key: string, request: CreateUpdateLeaverAnswerDto) =>
    this.restService.request<any, LeaverAnswerNoteDto>({
      method: 'PUT',
      url: '/api/exit/leavers/notes',
      params: { key },
      body: request,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
