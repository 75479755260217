/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { FormType } from '../models/FormType';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class FormTypeService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns FormType Success
     * @throws ApiError
     */
    public getAllFormTypes(): Observable<Array<FormType>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-type/get-all-form-types',
        });
    }

    /**
     * @param id 
     * @returns FormType Success
     * @throws ApiError
     */
    public getFormType(
id: string,
): Observable<FormType> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-type/get-form-type/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FormType Success
     * @throws ApiError
     */
    public addFormType(
requestBody?: FormType,
): Observable<FormType> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-type/add-form-type',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns FormType Success
     * @throws ApiError
     */
    public updateFormType(
id: string,
requestBody?: FormType,
): Observable<FormType> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/form-type/update-form-type/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteFormType(
id: string,
): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/form-type/delete-form-type/{id}',
            path: {
                'id': id,
            },
        });
    }

}
