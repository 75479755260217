/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EmailTemplate } from '../models/EmailTemplate';
import type { EmailTemplateTypes } from '../models/EmailTemplateTypes';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param tenantId 
     * @param companyId 
     * @param type 
     * @returns EmailTemplate Success
     * @throws ApiError
     */
    public getByType(
tenantId: string,
companyId: string,
type: EmailTemplateTypes,
): Observable<Array<EmailTemplate>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/email-template/get-by-Type/{tenantId}/{companyId}/{type}',
            path: {
                'tenantId': tenantId,
                'companyId': companyId,
                'type': type,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EmailTemplate Success
     * @throws ApiError
     */
    public addEmailTemplate(
requestBody?: EmailTemplate,
): Observable<EmailTemplate> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/email-template/add-template',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @returns EmailTemplate Success
     * @throws ApiError
     */
    public getEmailTemplate(
id: string,
): Observable<EmailTemplate> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/email-template/get-template/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns EmailTemplate Success
     * @throws ApiError
     */
    public updateEmailTemplate(
id: string,
requestBody?: EmailTemplate,
): Observable<EmailTemplate> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/email-template/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
