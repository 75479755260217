import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { SpecialQuestionExcelDownloadService } from '@ex/module/survey-x/data-access/services/special-question-excel-download.service';
import { SjsTranslatePipe } from '@ex/module/survey-x/utils/pipes/sjs-translate.pipe';
import { CDKPortalComponent, DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
import { getCurrencySymbol } from "@ex/module/survey-x/ui/builder/currency-dropdown/currency-dropdown.component";
import { FormControl } from '@angular/forms';
import { debounce, debounceTime, distinctUntilChanged, distinctUntilKeyChanged, filter } from 'rxjs/operators';
import { ZipDownloadService } from '@ex/module/survey-x/data-access/services/zip-download.service';


@Component({
  selector: 'app-special-question-answers-drawer',
  templateUrl: './special-question-answers-drawer.component.html',
  styleUrls: ['./special-question-answers-drawer.component.scss'],
  providers: [SjsTranslatePipe],
})
export class SpecialQuestionAnswersDrawerComponent extends BaseComponent implements OnInit, CDKPortalComponent {
  open: boolean;

  filteredRespondents: any = [];
  showPrint = false;
  language = "EN"

  isImageFile: any;
  isPDFFile: any;
  modifiedAnswer: any;
  selectedRespondent: any;
  activeTabs: any;

  fileStates: { [key: string]: boolean } = {};

  totalCount = 0

  searchTerm = new FormControl("");

  constructor(@Inject(DATA_TOKEN) public data: { question: any, language: string, data: any, questionNo: any }
    , private specialQuestionExcelDownloadSerivce: SpecialQuestionExcelDownloadService
    , private sanitizer: DomSanitizer, private sjsTranslatePipe: SjsTranslatePipe
    , private zipDownloadService: ZipDownloadService
    , private cdr: ChangeDetectorRef) {
    super()

    this.language = this.data.language;

  }

  close: EventEmitter<any> = new EventEmitter();
  results: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.open = true;

    this.addSubscription(
      this.searchTerm.valueChanges.pipe(
        filter(term => term.length > 2 || term.length < 1),
        debounceTime(100),
        distinctUntilChanged()
      ).subscribe(term => {
        this.onKeyUp(term);
      })
    );



    const sub = this.data.question['data$'].subscribe(response => {

      if (response?.data?.length == 0 && this.data?.question?.exportData?.length)
        return;
      if (!response.data?.length)
        response.data = this.data.question.exportData;
      if (this.filteredRespondents.length == 0) {
        this.selectedRespondent = response.data? response.data[0] : {} ;
        this.changeTabs(this.selectedRespondent);
      }

      this.totalCount = this.selectedRespondent?.value2;

      this.filteredRespondents = this.filteredRespondents.concat(response.data);
    })

    this.addSubscription(sub);

    // if (this.data.data.data.length) {
    //   this.filteredRespondents = this.data.data.data;
    //   this.selectedRespondent = this.filteredRespondents[0];
    //   this.changeTabs(this.selectedRespondent);
    // }
  }

  closeModal() {
    this.open = false;
    this.close.emit(true);
  }

  onExport() {
    var data: any[] = [];
    switch (this.data.question.numberInputType) {
      case "percentage":
        this.filteredRespondents.forEach(element => {
          data.push({
            category: element.category,
            value: element.value + "%"
          })
        });
        break;

      case "currency":
        this.filteredRespondents.forEach(element => {
          data.push({
            category: element.category,
            value: element.value + getCurrencySymbol(this.data.question.numberInputCurrency || "USD")
          })
        });
        break;

      default:
        this.filteredRespondents.forEach(element => {
          data.push({
            category: element.category,
            value: element.value
          })
        });
        break;
    }

    this.specialQuestionExcelDownloadSerivce.generate(data, this.sjsTranslatePipe.transform(this.data.question.title ?? this.data.question.name, this.language));
  }

  onKeyUp(term) {
    this.selectedRespondent = null;

    this.data.question['search$'].next(term);
    this.data.question['page$'].next(0);

    this.filteredRespondents = [];

    // this.filteredRespondents = this.data.data.data.filter(
    //   respondent =>
    //     respondent.category.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
    //     respondent.value1.toLowerCase().includes(this.searchTerm.toLowerCase())
    // );
  }


  setSelectedRespondent(respondent: any) {
    this.selectedRespondent = respondent;
  }

  changeTabs(tabs: any) {
    this.activeTabs = tabs;
  }


  checkFile(data: any) {
    if (!data) {
      return false;
    }

    let dataArray;

    if (typeof data === 'string') {
      try {
        // Check if it's valid JSON, otherwise, treat it as a single file
        dataArray = JSON.parse(data);
        if (!Array.isArray(dataArray)) {
          dataArray = [{ content: data }];
        }
      } catch (error) {
        // Treat it as a single file
        dataArray = [{ content: data }];
      }
    } else if (Array.isArray(data)) {
      dataArray = data;
    } else {
      console.error('Invalid data type:', data);
      return false;
    }

    this.modifiedAnswer = dataArray.map((file, index) => { // Added index parameter
      const content = file.content;
      const isImageFile = content.includes('data:image');
      const isPDFFile = content.includes('data:application/pdf');

      return {
        isImageFile: isImageFile,
        isPDFFile: isPDFFile,
        url: this.sanitizer.bypassSecurityTrustResourceUrl(content),
        name: `file_${index}`, // Set a unique name for each file
        showPreview: false
      };

    });

    return true;
  }

  get isShowMore() {
    return this.filteredRespondents.length > 0 && this.totalCount > this.filteredRespondents.length ? true : false;
  }

  showMore() {
    this.data.question['page$'].next(this.data.question['page$'].value + 1);
  }

  togglePreview(file: any) {
    console.log('Toggling preview for:', file);
    const fileId = `file_${file.name}`;
    this.fileStates[fileId] = !this.fileStates[fileId];

  }


  isFileExpanded(file: any): boolean {
    const fileId = `file_${file.name}`;
    return this.fileStates[fileId];
  }


  async download(file) {
    const files = JSON.parse(file);

    const zipRequest = {};

    files.forEach(f => {
      zipRequest[f.name] = f.content.split(",")[1];
    });

    await this.zipDownloadService.generateZipContent(zipRequest);
  }
}
