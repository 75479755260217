import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[MenuItem]'
})
export class MenuItemDirective {
  @HostBinding('class') className = this.returnClass(this.el);
  dir;
  constructor(private el: ElementRef) {

  }
  @HostListener('click') onClick() {
    setTimeout(() => {
      if (document.querySelector(".main-overlay") != null)
        document.body.removeChild(document.querySelector(".main-overlay"));
    }, 400);
  }

  returnClass(el: ElementRef) {
    if (document.body.dir == 'rtl') {
      this.dir = 'rtl';
    }
    let className: string = "flex flex-row items-center gap-2 text-sm leading-8 btn rounded hover:bg-opacity-20 cursor-pointer whitespace-nowrap", extendedClass;
    if (el.nativeElement.getAttribute('delete') != null)
      extendedClass = ' text-zen-red hover:bg-zen-red';
    else if (el.nativeElement.getAttribute('success') != null)
      extendedClass = ' text-zen-green hover:bg-zen-green';
    else extendedClass = ' text-zen hover:bg-exp';

    if (extendedClass)
      return className.concat(extendedClass.concat(this.dir == 'rtl' ? ' pl-6 pr-4' : ' pr-6 pl-4'));
  }
}



