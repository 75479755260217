import { Entity } from './Entity';
import { TenantBenchmark } from './tenant-benchmark';

export class Tenant {
  key: string;
  name: string;
  units: number;
  setup: boolean;
  adminEmail: string;
  Entities: Entity[] = [];
  reseller: boolean = false;
  thomasIntegrated: boolean = false;
  Benchmark: TenantBenchmark;
  HappinessBenchmark: TenantBenchmark;
  accountManagerEmail: string;
  accountManagerName: string;
  accountManagerPhoneNumber: string;
  exitQuestionnaireKey: string;
  logoURL: string;
  entities: Entity[] = [];
  collapsed: boolean = true;

  //New Fields
  clientType: string;
  status: string;
  accountType: string;
  clientLabel: string;
  isSetup: boolean = true;
  hasBenchmark: boolean = true;
  contactPersonKey: string;
  contactInfo: any;
  byReseller: boolean = false;
  resellerKey: string;
  actionBy: string;
  dateCreated: string;
  dateModified: string;



}
