// DimensionKeys are used in translation, keys should not be altered
// the english translation trimmed without any changes are used accordingly.

export enum DimensionKeys {
  SocialWellbeing = '-NQt3r2S6m1sxwhqi2ki',
  PsychologicalWellbeing = '-NQt3r2SAgJxrMbgigHQ',
  FinancialWellbeing = '-NQt3r2SSOQbVmwf9CB2',
  OccupationalWellbeing = '-NQt3r2SvIPn3oY3XqdQ',
  PhysicalWellbeing = '-NQt3UB5BE9E3LIXRS_n',
}
