import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoaderService } from 'src/app/core/services/loader.service';
import { routeOuterAnimations } from 'src/app/shared/animations/allAnimations';
import { ModuleName } from 'src/app/shared/enums/ModuleName';

@Component({
  selector: 'app-main-frame',
  templateUrl: './main-frame.component.html',
  styleUrls: ['./main-frame.component.scss'],
  animations:[routeOuterAnimations]
})
export class MainFrameComponent implements OnInit {

  constructor(public loaderService:LoaderService) { }
  ModuleNames=ModuleName



  ngOnInit() {}
  currentIndex: number = -3;
  value
  resolveRoute(o: RouterOutlet) {
    let path = o.activatedRouteData['animation'];
     if (o.isActivated) {
      if (path > this.currentIndex) {
        this.value = 'down';
      } else if (path < this.currentIndex) {
        this.value = 'up';
      }
      
      this.currentIndex = path;
      return this.value;
    }
  }
}
