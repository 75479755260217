<div class="flex flex-wrap  m-0 vh-100 vw-100 overflow-auto pb-5">
    <div class="md:w-1/4 pr-4 pl-4 g-0 sidebar pt-10">
        <app-languages-tab-sidebar [model]="model" [language]="language"
            (targetLocaleChange)="onTargetLocaleChanged($event)">
        </app-languages-tab-sidebar>
    </div>

    <div class="main md:w-3/4 pr-4 pl-4 g-0 px-5 flex justify-center pt-10">
        <div class="w-full flex pt-5 px-8">
            <div [dir]="language == 'AR'? 'rtl' : 'ltr'" class="md:w-1/2">
                <div class="language-panel language-panel-default overflow-auto rounded-start pt-4 pb-24 mb-4"
                    (scroll)="onScroll(scrollOne, scrollTwo)" #scrollOne>
                    <div class="language-panel-header flex justify-between items-center px-8 mt-3 mb-5">
                        <h6 class="language-panel-card-title">
                            {{'Default Language'| translate:language:ModuleNames.SurveyX }}
                            ({{getLocalName(sourceLocale)}})
                        </h6>

                    </div>

                    <div class="survey-information px-8 pb-8">

                        <div class="question-input mb-4">
                            <label for="exampleInputEmail1" class="form-label">{{'Form Title'|
                                translate:language:ModuleNames.SurveyX }}</label>
                            <input [maxLength]="maxCharactersTitle" type="text"
                                class="form-input w-full py-1 px-2 mb-1 mt-2"
                                [value]="model.survey.locTitle['values'][sourceLocale] || ''"
                                (input)="onSurveyLocaleChanged(model.survey.locTitle,sourceLocale, $event.target.value)">
                            <div class="text-xxs">
                                {{ model.survey.locTitle.text.length}} / {{ maxCharactersTitle }}
                            </div>
                        </div>

                        <div class="question-input mb-4">
                            <label for="exampleInputEmail1" class="form-label">{{'Description (Optional)'|
                                translate:language:ModuleNames.SurveyX }}</label>
                            <textarea [maxLength]="maxCharactersDescription" rows="4"
                                class="form-input w-full py-1 px-2 mb-1 mt-2"
                                [value]="model.survey.locDescription['values'][sourceLocale] || ''"
                                (input)="onSurveyLocaleChanged(model.survey.locDescription,sourceLocale, $event.target.value)"></textarea>
                            <div class="text-xxs">
                                {{ model.survey.locDescription.text.length}} / {{ maxCharactersDescription }}
                            </div>
                        </div>
                    </div>

                    <div class="survey-sections"
                        *ngFor="let section of this.model.survey.pages;let section_index = index">
                        <div class="px-8 mb-8">
                            <div class="flex items-center justify-between section-input px-4 mb-4">
                                <span class="whitespace-nowrap">{{"Section"| translate:language:ModuleNames.SurveyX}}
                                    {{section_index+1}}</span>

                                <input type="text" class="form-input w-full px-1"
                                    [value]="section.locTitle['values'][sourceLocale] || ''"
                                    [placeholder]="section.name"
                                    (input)="onSurveyLocaleChanged(section.locTitle,sourceLocale, $event.target.value)">
                            </div>
                        </div>

                        <div class="section-questions mb-8 pb-10">
                            <div class="question"
                                *ngFor="let question of returnQuestionsAfterNPSCheck(section.questions) ; let question_index = index">

                                <div [ngClass]="{'question-input-active': question_index == 0}"
                                    class="question-title-input flex items-center justify-between gap-8 py-3 px-7">
                                    <span class="question-index">{{"Q"|
                                        translate:language:ModuleNames.SurveyX}}{{getQuestionNumber(question)}}</span>

                                    <!-- <input type="text"
                                        [disabled]="question.rateType == 'enps' || question.rateType == 'nps'"
                                        (input)="onItemValueChange(question,sourceLocale, $event.target.value)"
                                        class="form-input w-full py-1 px-2 mb-1 mt-2" [placeholder]="question.title"
                                        [value]="question.locTitle['values'][sourceLocale] ||''"> -->

                                        <ng-container *ngIf="question.rateType != 'enps'">
                                            <input type="text" [disabled]="question.rateType == 'nps'"
                                                (input)="onItemValueChange(question,sourceLocale, $event.target.value)"
                                                class="form-input w-full py-1 px-2 mb-1 mt-2"
                                                [placeholder]="question.title" [value]="question.locTitle['values'][sourceLocale] ||''">
                                        </ng-container>

                                        <div class="flex flex-col" *ngIf="question.rateType == 'enps'">
                                            <z-input [label]="'txtCompanyName:'| translate:sourceLocale.toUpperCase():ModuleNames.SurveyX" [ngModel]="question.uniqueNPSText | extract:sourceLocale.toUpperCase()" (ngModelChange)="updateNPSText($event, question, sourceLocale)"></z-input>
                                            <h5 class="text-zen-primaryLight">
                                                {{returnNPSValue(question, sourceLocale)}}
                                            </h5>
                                        </div>

                                    <span class="question-index">
                                        <!-- <svg width="12" height="7" viewBox="0 0 12 7" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.46875 0.46875C5.75 0.1875 6.21875 0.1875 6.5 0.46875L11.5312 5.46875C11.8125 5.78125 11.8125 6.25 11.5312 6.53125C11.2188 6.84375 10.75 6.84375 10.4688 6.53125L6 2.0625L1.53125 6.53125C1.21875 6.84375 0.75 6.84375 0.46875 6.53125C0.15625 6.25 0.15625 5.78125 0.46875 5.5L5.46875 0.46875Z"
                                                fill="#0082C6" />
                                        </svg> -->
                                    </span>
                                </div>

                                <div *ngIf="question['description']" class="question-answers">
                                    <div class="question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                        <span class="answer-index whitespace-nowrap">{{'Description'|
                                            translate:language:ModuleNames.SurveyX}}</span>

                                        <input type="text"
                                            (input)="onLocalizableStringTextChange(question,sourceLocale, $event.target.value,'description')"
                                            class="form-input w-full py-1 px-2"
                                            [value]="question.localizableStrings.description['values'][sourceLocale] || ''"
                                            [placeholder]="question['description'].default? question['description'].default : question['description']">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>

                                <div class="question-answers">
                                    <div *ngFor="let answer of question['choices']; let answer_index = index"
                                        class="question-title-input flex items-center justify-between gap-8 py-1 px-7"
                                        [ngClass]="{'mt-4': answer_index == 0}">
                                        <span class="answer-index whitespace-nowrap">{{"A"|
                                            translate:language:ModuleNames.SurveyX}}{{answer_index+1}}</span>

                                        <input (click)="logData(answer)" type="text"
                                            (input)="onItemValueChange(answer,sourceLocale, $event.target.value)"
                                            class="form-input w-full py-1 px-2"
                                            [value]="answer.locTitle['values'][sourceLocale] || answer.title"
                                            [placeholder]="answer.title">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>


                                <div *ngIf="question['labelTrue']" class="question-answers">
                                    <div class="question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                        <span class="answer-index1 whitespace-nowrap">True Label</span>

                                        <input type="text"
                                            (input)="onBinaryLabelTextChange(question,sourceLocale, $event.target.value,true)"
                                            class="form-input w-full py-1 px-2"
                                            [value]="question.locLabelTrue['values'][sourceLocale] || ''"
                                            [placeholder]="question['labelTrue'].default?question['labelTrue'].default : question['labelTrue'] ">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>
                                <div *ngIf="question['labelFalse']" class="question-answers">
                                    <div class="question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                        <span class="answer-index1 whitespace-nowrap">False Label</span>

                                        <input type="text"
                                            (input)="onBinaryLabelTextChange(question,sourceLocale, $event.target.value,false)"
                                            class="form-input w-full py-1 px-2"
                                            [value]="question.locLabelFalse['values'][sourceLocale] || ''"
                                            [placeholder]="question['labelFalse'].default? question['labelFalse'].default : question['labelFalse']">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>

                                <div class="question-answers">
                                    <div *ngFor="let label of question['ratingLabels']; let label_index = index"
                                        class="question-title-input flex items-center justify-between gap-8 py-1 px-7"
                                        [ngClass]="{'mt-4': label_index == 0}">
                                        <span class="answer-index whitespace-nowrap">{{"A"|
                                            translate:language:ModuleNames.SurveyX}}{{label_index+1}}</span>

                                        <input type="text"
                                            (input)="onLabelTextChange(label, sourceLocale, $event.target.value)"
                                            class="form-input w-full py-1 px-2"
                                            [value]="label.text[sourceLocale]  || ''"
                                            [placeholder]="label.text.default? label.text.default : label.text">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>

                                <div class="question-answers"
                                    *ngFor="let column of question['columns']; let column_index = index">
                                    <div class="question-title-input flex items-center justify-between gap-8 py-1 px-7"
                                        [ngClass]="{'mt-4': column_index == 0}" *ngIf="column.title != null">
                                        <span class="answer-index whitespace-nowrap">{{"C"|
                                            translate:language:ModuleNames.SurveyX}}{{column_index+1}}</span>

                                        <input type="text"
                                            (input)="onItemValueChange(column,sourceLocale, $event.target.value)"
                                            class="form-input w-full py-1 px-2"
                                            [value]="column.locTitle['values'][sourceLocale] || ''"
                                            [placeholder]="column.title">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>

                                <div class="question-answers"
                                    *ngFor="let row of question['rows']; let row_index = index">
                                    <div class="question-title-input flex items-center justify-between gap-8 py-1 px-7"
                                        [ngClass]="{'mt-4': row_index == 0}" *ngIf="row.title != null">
                                        <span class="answer-index whitespace-nowrap">{{"R"|
                                            translate:language:ModuleNames.SurveyX}}{{row_index+1}}</span>

                                        <input type="text"
                                            (input)="onItemValueChange(row,sourceLocale, $event.target.value)"
                                            class="form-input w-full py-1 px-2"
                                            [value]="row.locTitle['values'][sourceLocale] || ''"
                                            [placeholder]="row.title">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>

                                <div *ngIf="question['showCommentArea'] && question['commentPlaceholder'] "
                                    class="question-answers">
                                    <div class="question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                        <span class="answer-index1 whitespace-nowrap">{{'Comment Placeholder'|
                                            translate:language:ModuleNames.SurveyX}}</span>

                                        <input type="text"
                                            (input)="onLocalizableStringTextChange(question,sourceLocale, $event.target.value,'commentPlaceholder')"
                                            class="form-input w-full py-1 px-2"
                                            [value]="question.localizableStrings.commentPlaceholder['values'][sourceLocale] || ''"
                                            [placeholder]="question['commentPlaceholder'].default? question['commentPlaceholder'].default : question['commentPlaceholder']">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>

                                <div *ngIf="question['placeholder'] && question.jsonObj.type != 'rating'"
                                    class="question-answers">
                                    <div class="question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                        <span class="answer-index1 whitespace-nowrap">{{'placeholder'|
                                            translate:language:ModuleNames.SurveyX}}</span>

                                        <input type="text"
                                            (input)="onLocalizableStringTextChange(question,sourceLocale, $event.target.value,'placeholder')"
                                            class="form-input w-full py-1 px-2"
                                            [value]="question.localizableStrings.placeholder['values'][sourceLocale] || ''"
                                            [placeholder]="question['placeholder'].default? question['placeholder'].default : question['placeholder']">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>

                                <div *ngIf="question['commentText']  && question['showCommentArea']"
                                    class="question-answers">
                                    <div class="question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                        <span class="answer-index1 whitespace-nowrap">{{'Comment Box Label'|
                                            translate:language:ModuleNames.SurveyX}}</span>

                                        <input type="text"
                                            (input)="onLocalizableStringTextChange(question,sourceLocale, $event.target.value,'commentText')"
                                            class="form-input w-full py-1 px-2"
                                            [value]="question.localizableStrings.commentText['values'][sourceLocale] || ''"
                                            [placeholder]="question['commentText'].default? question['commentText'].default : question['commentText']">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>

                                <div *ngIf="question['showOtherItem']  && question['otherPlaceholder']"
                                    class="question-answers">
                                    <div class="question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                        <span class="answer-index1 whitespace-nowrap">{{'Other Placeholder'|
                                            translate:language:ModuleNames.SurveyX}}</span>

                                        <input type="text"
                                            (input)="onLocalizableStringTextChange(question,sourceLocale, $event.target.value,'otherPlaceholder')"
                                            class="form-input w-full py-1 px-2"
                                            [value]="question.localizableStrings.otherPlaceholder['values'][sourceLocale] || ''"
                                            [placeholder]="question['otherPlaceholder'].default? question['otherPlaceholder'].default : question['otherPlaceholder']">

                                        <span>
                                            <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                        </span>
                                    </div>
                                </div>





                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="md:w-1/2">
                <div class="language-panel overflow-auto rounded-start pt-4 pb-24 mb-4"
                    (scroll)="onScroll(scrollTwo, scrollOne)" #scrollTwo>
                    <div>
                    </div>
                    <ng-template #selectTargetLanguage>
                        <div class="language-panel-header flex justify-between items-center px-8 mt-3 mb-5">
                            <h6 class="language-panel-card-title">
                                {{'Target Language'| translate:language:ModuleNames.SurveyX}}
                            </h6>
                        </div>

                        <div class="pt-28">
                            <div class="flex justify-center items-center mb-8">
                                <svg width="81" height="48" viewBox="0 0 81 48" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.66675 0H34.6667H38.6667H40.6667H72.6667C77.0417 0 80.6667 3.625 80.6667 8V40C80.6667 44.5 77.0417 48 72.6667 48H40.6667H38.6667H34.6667H8.66675C4.16675 48 0.666748 44.5 0.666748 40V8C0.666748 3.625 4.16675 0 8.66675 0ZM72.6667 6H40.6667V42H72.6667C73.6667 42 74.6667 41.125 74.6667 40V8C74.6667 7 73.6667 6 72.6667 6ZM22.9167 14C22.5417 13.125 21.5417 12.5 20.6667 12.5C19.6667 12.5 18.6667 13.125 18.2917 14L10.2917 32C9.79175 33.25 10.2917 34.75 11.5417 35.375C12.9167 35.875 14.2917 35.375 14.9167 34.125L16.0417 31.5H25.1667L26.2917 34.125C26.9167 35.375 28.4167 35.875 29.6667 35.375C30.9167 34.75 31.4167 33.25 30.9167 32L22.9167 14ZM23.0417 26.5H18.2917L20.6667 21.25L23.0417 26.5ZM57.6667 12.5C59.0417 12.5 60.1667 13.625 60.1667 15V15.5H65.6667H67.6667C69.0417 15.5 70.1667 16.625 70.1667 18C70.1667 19.375 69.0417 20.5 67.6667 20.5H67.4167L67.1667 21.125C66.0417 24.125 64.2917 27 62.1667 29.25C62.2917 29.375 62.4167 29.375 62.5417 29.5L64.9167 30.875C66.0417 31.625 66.4167 33.125 65.7917 34.375C65.0417 35.5 63.5417 35.875 62.2917 35.25L59.9167 33.75C59.4167 33.5 58.9167 33.125 58.2917 32.75C57.0417 33.625 55.5417 34.5 54.0417 35.125L53.6667 35.375C52.4167 35.875 50.9167 35.375 50.2917 34.125C49.7917 32.75 50.2917 31.375 51.5417 30.75L52.0417 30.625C52.7917 30.25 53.6667 29.75 54.4167 29.375L52.7917 27.875C51.9167 26.875 51.9167 25.25 52.7917 24.25C53.7917 23.375 55.4167 23.375 56.4167 24.25L58.1667 26.125H58.2917C59.7917 24.5 61.0417 22.625 62.0417 20.5H57.6667H48.6667C47.2917 20.5 46.1667 19.375 46.1667 18C46.1667 16.625 47.2917 15.5 48.6667 15.5H55.1667V15C55.1667 13.625 56.2917 12.5 57.6667 12.5Z"
                                        fill="#CED6E3" />
                                </svg>
                            </div>

                            <div class="flex justify-center items-center gap-3">
                                <svg class="select-target-arrow" width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.885498 7.5625C0.729248 7.40625 0.666748 7.21875 0.666748 7C0.666748 6.8125 0.729248 6.625 0.885498 6.46875L6.3855 1.21875C6.698 0.9375 7.16675 0.9375 7.448 1.25C7.72925 1.53125 7.72925 2.03125 7.41675 2.3125L3.2605 6.25H13.9167C14.323 6.25 14.6667 6.59375 14.6667 7C14.6667 7.4375 14.323 7.75 13.9167 7.75H3.2605L7.41675 11.7188C7.72925 12 7.72925 12.4688 7.448 12.7812C7.16675 13.0938 6.698 13.0938 6.3855 12.8125L0.885498 7.5625Z"
                                        fill="#AEBBD1" />
                                </svg>

                                <p class="select-target-text">
                                    {{'Select target language from the Languages list'|
                                    translate:language:ModuleNames.SurveyX }}
                                </p>
                            </div>
                        </div>

                    </ng-template>

                    <div [dir]="targetLocale.toUpperCase() == 'AR'? 'rtl' : 'ltr'"
                        *ngIf="targetLocale != null && targetLocale != '';else selectTargetLanguage">
                        <div class="language-panel-header flex justify-between items-center px-8 mt-3 mb-5">
                            <h6 class="language-panel-card-title">
                                {{'Target Language'| translate:targetLocale.toUpperCase():ModuleNames.SurveyX }}
                                ({{getLocalName(targetLocale)}})
                            </h6>
                        </div>

                        <div class="survey-information px-8 pb-8">
                            <div class="question-input mb-4">
                                <label for="exampleInputEmail1" class="form-label">
                                    {{'Form Title'| translate:targetLocale.toUpperCase():ModuleNames.SurveyX }}
                                </label>

                                <input [maxLength]="maxCharactersTitle" type="text"
                                    class="form-input w-full py-1 px-2 mb-1 mt-2"
                                    [value]="model.survey.locTitle['values'][targetLocale]  ||''"
                                    [placeholder]="model.survey.title"
                                    (input)="onSurveyLocaleChanged(model.survey.locTitle,targetLocale, $event.target.value)">
                                <div class="text-xxs">
                                    {{ model.survey.locTitle.values[targetLocale]?.length || 0}} / {{ maxCharactersTitle
                                    }}
                                </div>
                            </div>

                            <div class="question-input mb-4">
                                <label for="exampleInputEmail1" class="form-label">
                                    {{'Description (Optional)'| translate:targetLocale.toUpperCase():ModuleNames.SurveyX
                                    }}
                                </label>

                                <textarea [maxLength]="maxCharactersDescription" rows="4"
                                    class="form-input w-full py-1 px-2 mb-1 mt-2"
                                    [value]="model.survey.locDescription['values'][targetLocale]  ||''"
                                    [placeholder]="model.survey.description"
                                    (input)="onSurveyLocaleChanged(model.survey.locDescription,targetLocale, $event.target.value)"></textarea>
                                <div class="text-xxs">
                                    {{ model.survey.locDescription.values[targetLocale]?.length || 0}} / {{
                                    maxCharactersDescription }}
                                </div>
                            </div>
                        </div>

                        <div class="survey-sections"
                            *ngFor="let section of this.model.survey.pages;let section_index = index">
                            <div class="px-8 mb-8">
                                <div class="flex items-center justify-between section-input px-4 mb-4">
                                    <span class="whitespace-nowrap">
                                        {{'Section'| translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}
                                        {{section_index+1}}
                                    </span>

                                    <input type="text" class="form-input w-full px-1"
                                        [value]="section.locTitle['values'][targetLocale] || ''"
                                        [placeholder]="section.title"
                                        (input)="onSurveyLocaleChanged(section.locTitle,targetLocale, $event.target.value)">
                                </div>
                            </div>

                            <div class="section-questions mb-8 pb-10">
                                <div class="target-question"
                                    *ngFor="let question of section.questions; let question_index = index">
                                    <div (click)="logData(question)"
                                        [ngClass]="{'question-input-active': question_index == 0}"
                                        class="target-question-title-input flex items-center justify-between gap-8 py-3 px-7">
                                        <span class="question-index">{{'Q'|
                                            translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}{{getQuestionNumber(question)}}</span>
                                        <ng-container *ngIf="question.rateType != 'enps'">
                                            <input type="text" [disabled]="question.rateType == 'nps'"
                                                (input)="onItemValueChange(question,targetLocale, $event.target.value)"
                                                class="form-input w-full py-1 px-2 mb-1 mt-2"
                                                [placeholder]="question.title" [value]="returnNPSValue(question, targetLocale)">
                                        </ng-container>

                                        <div class="flex flex-col" *ngIf="question.rateType == 'enps'">
                                            <z-input [label]="'txtCompanyName:'| translate:targetLocale.toUpperCase():ModuleNames.SurveyX" [ngModel]="question.uniqueNPSText | extract:targetLocale.toUpperCase()" (ngModelChange)="updateNPSText($event, question, targetLocale)"></z-input>
                                            <h5 class="text-zen-primaryLight">
                                                {{returnNPSValue(question , targetLocale)}}
                                            </h5>
                                        </div>
                                        <span class="question-index">
                                            <!-- <svg width="12" height="7" viewBox="0 0 12 7" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.46875 0.46875C5.75 0.1875 6.21875 0.1875 6.5 0.46875L11.5312 5.46875C11.8125 5.78125 11.8125 6.25 11.5312 6.53125C11.2188 6.84375 10.75 6.84375 10.4688 6.53125L6 2.0625L1.53125 6.53125C1.21875 6.84375 0.75 6.84375 0.46875 6.53125C0.15625 6.25 0.15625 5.78125 0.46875 5.5L5.46875 0.46875Z"
                                                    fill="#0082C6" />
                                            </svg> -->
                                        </span>
                                    </div>


                                    <div *ngIf="question['description']" class="question-answers">
                                        <div
                                            class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                            <span class="answer-index whitespace-nowrap">{{'Description'
                                                |translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}</span>

                                            <input type="text"
                                                (input)="onLocalizableStringTextChange(question,targetLocale, $event.target.value,'description')"
                                                class="form-input w-full py-1 px-2"
                                                [placeholder]="question['description'].default? question['description'].default : question['description']"
                                                [value]="question.localizableStrings.description['values'][targetLocale] || ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>


                                    <div class="question-answers">
                                        <div *ngFor="let answer of question['choices']; let answer_index = index"
                                            class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7"
                                            [ngClass]="{'mt-4': answer_index == 0}">
                                            <span class="answer-index whitespace-nowrap">{{'A'|
                                                translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}{{answer_index+1}}</span>

                                            <input type="text"
                                                (input)="onItemValueChange(answer,targetLocale, $event.target.value)"
                                                class="form-input w-full py-1 px-2" [placeholder]="answer.title"
                                                [value]="answer.locTitle['values'][targetLocale] || ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>

                                    <div *ngIf="question['labelTrue']" class="question-answers">
                                        <div
                                            class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                            <span class="answer-index1 whitespace-nowrap">{{ "True Label" |
                                                translate:language:ModuleNames.Survey-X }}</span>

                                            <input type="text"
                                                (input)="onBinaryTargetLabelTextChange(question,targetLocale, $event.target.value, true)"
                                                class="form-input w-full py-1 px-2"
                                                [placeholder]="question['labelTrue'].default? question['labelTrue'].default : question['labelTrue']"
                                                [value]="question.locLabelTrue['values'][targetLocale]|| ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="question['labelFalse']" class="question-answers">
                                        <div
                                            class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                            <span class="answer-index1 whitespace-nowrap">{{ "False Label" |
                                                translate:language:ModuleNames.Survey-X }}</span>

                                            <input type="text"
                                                (input)="onBinaryTargetLabelTextChange(question,targetLocale, $event.target.value, false)"
                                                class="form-input w-full py-1 px-2"
                                                [placeholder]="question['labelFalse'].default? question['labelFalse'].default : question['labelFalse']"
                                                [value]="question.locLabelFalse['values'][targetLocale] || ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>



                                    <div class="question-answers">
                                        <div *ngFor="let label of question['ratingLabels']; let label_index = index"
                                            class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7"
                                            [ngClass]="{'mt-4': label_index == 0}">
                                            <span class="answer-index whitespace-nowrap">{{'A'|
                                                translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}{{label_index+1}}</span>

                                            <input type="text"
                                                (input)="onTargetLabelTextChange(label,targetLocale, $event.target.value)"
                                                class="form-input w-full py-1 px-2"
                                                [placeholder]="label.text.default? label.text.default : label.text"
                                                [value]="label.text[targetLocale] || ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>



                                    <div class="question-answers"
                                        *ngFor="let column of question['columns']; let column_index = index">
                                        <div class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7"
                                            [ngClass]="{'mt-4': column_index == 0}" *ngIf="column.title != null">
                                            <span class="answer-index whitespace-nowrap">{{'C'|
                                                translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}{{column_index+1}}</span>

                                            <input type="text"
                                                (input)="onItemValueChange(column,targetLocale, $event.target.value)"
                                                class="form-input w-full py-1 px-2" [placeholder]="column.title"
                                                [value]="column.locTitle['values'][targetLocale] || ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="question-answers"
                                        *ngFor="let row of question['rows']; let row_index = index">
                                        <div class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7"
                                            [ngClass]="{'mt-4': row_index == 0}" *ngIf="row.title != null">
                                            <span class="answer-index whitespace-nowrap">{{'R'|
                                                translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}{{row_index+1}}</span>

                                            <input type="text"
                                                (input)="onItemValueChange(row,targetLocale, $event.target.value)"
                                                class="form-input w-full py-1 px-2" [placeholder]="row.title"
                                                [value]="row.locTitle['values'][targetLocale] || ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>



                                    <div *ngIf="question['showCommentArea'] && question['commentPlaceholder']"
                                        class="question-answers">
                                        <div
                                            class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                            <span class="answer-index1 whitespace-nowrap">{{'Comment
                                                Placeholder'|translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}</span>

                                            <input type="text"
                                                (input)="onLocalizableStringTextChange(question,targetLocale, $event.target.value,'commentPlaceholder')"
                                                class="form-input w-full py-1 px-2"
                                                [placeholder]="question['commentPlaceholder'].default? question['commentPlaceholder'].default : question['commentPlaceholder']"
                                                [value]="question.localizableStrings.commentPlaceholder['values'][targetLocale] || ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>

                                    <div *ngIf="question['placeholder'] && question.jsonObj.type != 'rating'"
                                        class="question-answers">
                                        <div
                                            class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                            <span
                                                class="answer-index1 whitespace-nowrap ">{{'placeholder'|translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}</span>

                                            <input type="text"
                                                (input)="onLocalizableStringTextChange(question,targetLocale, $event.target.value,'placeholder')"
                                                class="form-input w-full py-1 px-2"
                                                [placeholder]="question['placeholder'].default? question['placeholder'].default : question['placeholder']"
                                                [value]="question.localizableStrings.placeholder['values'][targetLocale] || ''">
                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>

                                    <div *ngIf="question['commentText'] && question['showCommentArea']"
                                        class="question-answers">
                                        <div
                                            class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                            <span class="answer-index1 whitespace-nowrap">{{'Comment Box
                                                Label'|translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}</span>

                                            <input type="text"
                                                (input)="onLocalizableStringTextChange(question,targetLocale, $event.target.value,'commentText')"
                                                class="form-input w-full py-1 px-2"
                                                [placeholder]="question['commentText'].default? question['commentText'].default : question['commentText']"
                                                [value]="question.localizableStrings.commentText['values'][targetLocale] || ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>

                                    <div *ngIf="question['showOtherItem'] && question['otherPlaceholder']"
                                        class="question-answers">
                                        <div
                                            class="target-question-title-input flex items-center justify-between gap-8 py-1 px-7">
                                            <span class="answer-index1 whitespace-nowrap">{{'Other
                                                Placeholder'|translate:targetLocale.toUpperCase():ModuleNames.SurveyX}}</span>

                                            <input type="text"
                                                (input)="onLocalizableStringTextChange(question,targetLocale, $event.target.value,'otherPlaceholder')"
                                                class="form-input w-full py-1 px-2"
                                                [placeholder]="question['otherPlaceholder'].default? question['otherPlaceholder'].default : question['otherPlaceholder']"
                                                [value]="question.localizableStrings.otherPlaceholder['values'][targetLocale] || ''">

                                            <span>
                                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"></svg>
                                            </span>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>