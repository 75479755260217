import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ExportPreviewDialogComponent } from './shared/export-preview-dialog/export-preview-dialog.component';
import { NumberDirective } from './core/services/numbers-only.directive'
import { GlobalsService } from './core/services/globals.service';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import 'hammerjs';
import "@lottiefiles/lottie-player";
import { LoginComponent } from './common/authentication/login/login.component';
import { EngagementLandingPageComponent } from './engagement/components/engagement-landing-page/engagement-landing-page.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { HappinessLandingPageComponent } from './happiness/components/happiness-landing-page/happiness-landing-page.component';
import { TenantSetupComponent } from './common/tenant-setup/tenant-setup.component';
import { BidiModule } from '@angular/cdk/bidi';
import { MainFrameComponent } from './common/main-frame/main-frame.component';
import { LanguagePipe } from './shared/pipes/language.pipe';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlLocalize } from './core/services/CustomPaginatorConfiguration';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, skip, take } from 'rxjs/operators';
import { AbpProviders } from './core/services/abp/providers';
import { SnackBarService } from './core/services/snack-bar.service';
import { ExtractPipe } from './shared/pipes/extract.pipe';
import { BandLevelResolver, EntityGroupResolver, ExtraCriteriaResolver, FixedGroupingOptionsResolver, JobGradeResolver, JobRoleResolver, KEY_RESOLVER_TOKEN, OnboardingExperienceScaleResolver, OnboardingPhaseResolver, SurveyStatusResolver } from './onboarding/services/key-resolver.service';
import { MatTreeModule } from '@angular/material/tree';
import { ExcelUploadModule } from './excel-upload/excel-upload.module';
import { AuthTokenInterceptor } from './auth-token.interceptor';
import { TokenService } from './core/services/token.service';
import { MetaService } from './onboarding/services/meta.service';
import { NavigationEnd, Router } from '@angular/router';
import { WellbeingLandingPageComponent } from './wellbeing/features/wellbeing-landing-page/wellbeing-landing-page.component';
import { WellbeingModule } from './wellbeing/wellbeing.module';
import { ExitNewModule } from './exit-new/exit-new.module';
import { TRANSLATION_FILE_TOKEN } from 'projects/ex/theme-shared/src/lib/tokens/translation';
import { ModuleName } from './shared/enums/ModuleName';
import { AssessmentLandingPageComponent } from './assessment/components/assessment-landing-page/assessment-landing-page.component';
import { SurveyxModule } from './survey-x/surveyx.module';
import { RTL } from '@progress/kendo-angular-l10n';
import { EntityStructureDialogComponent } from './exit/components/entity-structure-dialog/entity-structure-dialog.component';



export let AppInjector: Injector;

class GlobalErrorHandler implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var snackbar = this.injector.get(SnackBarService);

    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      // snackbar.openSnackBar(err.error?.message || err.error?.error?.message, err.statusText)
      throw err;
    }))
  }
}
@NgModule({
  declarations: [
    AppComponent,
    ExportPreviewDialogComponent,
    NumberDirective,
    LoginComponent,
    EngagementLandingPageComponent,
    HappinessLandingPageComponent,
    WellbeingLandingPageComponent,
    AssessmentLandingPageComponent,
    TenantSetupComponent,
    EntityStructureDialogComponent,
    MainFrameComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    GridModule,
    DialogsModule,
    HammerModule,
    BidiModule,
    MatTreeModule,
    ExcelUploadModule,
    WellbeingModule.forRoot(),
    ExitNewModule.forRoot(),
    SurveyxModule.forRoot(),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [GlobalsService, LanguagePipe, {
    provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true },
  }, { provide: MatPaginatorIntl, useClass: MatPaginatorIntlLocalize },
    ...AbpProviders,
    // { provide: ErrorHandler, useClass: CustomErrorHandlerService }
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: GlobalErrorHandler, deps: [Injector] },
    ExtractPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
      deps: [TokenService]
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: JobGradeResolver,
      multi: true
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: EntityGroupResolver,
      multi: true
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: FixedGroupingOptionsResolver,
      multi: true
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: OnboardingExperienceScaleResolver,
      multi: true
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: OnboardingPhaseResolver,
      multi: true
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: SurveyStatusResolver,
      multi: true
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: JobRoleResolver,
      multi: true
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: ExtraCriteriaResolver,
      multi: true
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: BandLevelResolver,
      multi: true
    },
    {
      provide: TRANSLATION_FILE_TOKEN,
      multi: true,
      useValue: {
        module: ModuleName.Shared,
        translations: JSON.parse(localStorage.getItem(ModuleName.Shared))
      }
    },
    {
      provide: KEY_RESOLVER_TOKEN,
      useClass: BandLevelResolver,
      multi: true
    },
    {
      provide: RTL, useFactory: function getLanguage() {
        return localStorage.getItem('language') == 'AR' ? true : false
      }
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector, private router: Router, private metaService: MetaService) {
    AppInjector = this.injector;
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        console.log(event);
        const title = this.metaService.getRouteTitle();
        this.metaService.setTitle(title);
      });
  }
}
