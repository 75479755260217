import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberComma'
})
export class NumberCommaPipe implements PipeTransform {

  transform(x): any {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

}
