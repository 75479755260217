import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AnalysisComponentModel, ExitBaseComponent } from '../../ui/exit-base/exit-base.component';
import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';
import { delay, distinctUntilChanged, filter, map, skip, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { EntityTreeService } from 'src/app/core/services/entity-tree.service';
import { ExtractPipe } from 'src/app/shared/pipes/extract.pipe';
import { ActivatedRoute } from '@angular/router';
import { SurveyTemplateDto, SurveyTemplatesService } from '@ex/exit/survey-templates';
import { Entity } from 'src/app/core/data-models/Entity';
import { CompanyPermission, products } from 'src/app/core/data-models/company-permission';
import { OverallAnalysisComponent } from './overall-analysis/overall-analysis.component';
import { LeaverAnalysisComponent } from './leaver-analysis/leaver-analysis.component';
import { QuestionAnalysisComponent } from './question-analysis/question-analysis.component';
import { CommentsAnalysisComponent } from './comments-analysis/comments-analysis.component';
import { Hierarchy } from 'src/app/core/data-models/hierarchy';
import { HierarchyEntity } from 'src/app/core/data-models/hierarcy-entity';
import { ExitTreeOptionsComponent } from '../../ui/exit-tree-options/exit-tree-options.component';
import { ExitAnalysisReportComponent, ExitReportExportData } from './exit-analysis-report/exit-analysis-report.component';
import { DualSelectItem } from 'src/app/shared/Theme/controls/dual-dropdown-selection/dual-dropdown-selection.component';
import { SeperationTypes, SeperationTypesService } from '../../data-access/services/seperation-types.service';
import { TemplateManagementService } from '../../data-access/services/template-management.service';
import { AnalysisService } from '@ex/exit/analysis';
import { EntityTreeModalComponent } from '../../ui/entity-tree-modal/entity-tree-modal.component';
import { JsonToExcelComponent } from 'src/app/shared/export-components/json-to-excel/json-to-excel.component';
import { CommentsService } from '@ex/exit/comments';
import { AI_COMMENTS_SERVICE } from 'src/app/shared/ai-comments/components/main/main.component';
import { IAiCommentsApi } from 'src/app/shared/ai-comments/interfaces/ai-comments-api';
import { FIREBASE_UPDATE_TOKEN, IFirebaseUpdate } from 'src/app/core/domain/firebase-update';

export interface SidebarNavigationModel {
  id: number,
  title: string,
  icon: string,
  selected: boolean,
  component: any
}

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent extends ExitBaseComponent implements OnInit {

  tenantKey: string
  companyKey: string
  surveyKey: string

  filters: AnalyticsFilter

  firebaseUpdate = new BehaviorSubject(true);
  firebaseUpdate$ = this.firebaseUpdate.asObservable();

  settingsLoaded = new Subject();
  settingsLoaded$ = this.settingsLoaded.asObservable();
  topValue: number;
  selectedEntity: Entity;
  hasCompanyPermission: boolean = true;
  permissionList: CompanyPermission[] = [];
  selectedChildEntity: any;
  viewByOption: boolean = false;
  entityCounts: any = [];
  allEntities: any = [];
  treeList: any = [];
  showExtraFactors: boolean = false;

  @ViewChild('tabContentHolder', { read: ViewContainerRef })
  tabContent: ViewContainerRef
  survey: SurveyTemplateDto;

  templates;
  templateMenu;
  selectedTemplate;
  language: string = 'EN';
  timeframeEndDate = null;
  timeframeStartDate = null;

  seperationTypesButtons = [];

  viewDisabled = false;

  sideBarNavigationTabs = [
    {
      id: 1,
      title: 'txtOverview',
      icon: 'fas fa-tachometer-alt',
      selected: true,
      component: OverallAnalysisComponent,
      tourKey: 'exit-analysis-overview',
      description: 'txtOverview_desc'
    },
    {
      id: 2,
      title: 'txtLeaversAnalysis',
      icon: 'fas fa-analytics',
      selected: false,
      component: LeaverAnalysisComponent,
      tourKey: 'exit-analysis-leaver',
      description: 'txtLeaversAnalysis_desc'
    },
    {
      id: 3,
      title: 'txt_questions',
      icon: 'fas fa-question',
      selected: false,
      component: QuestionAnalysisComponent,
      tourKey: 'exit-analysis-statements',
      description: 'txt_questions_desc'
    },
    {
      id: 4,
      title: 'txtComments',
      icon: 'fas fa-comment-lines',
      selected: false,
      component: CommentsAnalysisComponent,
      tourKey: 'exit-analysis-comments',
      description: 'txtComments_desc'
    }
  ]

  constructor(
    private analyticsService: AnalyticsService
    , private analysisService: AnalysisService
    , private extractPipe: ExtractPipe
    , @Inject(FIREBASE_UPDATE_TOKEN) private firebaseUpdateSerivce: IFirebaseUpdate
    , private entityTreeService: EntityTreeService
    , private permissionService: PermissionsService
    , private surveyTemplateService: SurveyTemplatesService
    , private templateManagementService: TemplateManagementService
    , private commentsService: CommentsService
    , private cdr: ChangeDetectorRef,
    @Inject(AI_COMMENTS_SERVICE) public commentsAiService: IAiCommentsApi) {
    super()
    // this.initSidebarNavigationTabs();

    this.seperationTypesButtons = [
      {
        selected: true,
        key: SeperationTypes.Resignation,
        border: 'border-zen-orange',
        text: 'text-zen-orange',
        name: this.SeperationTypes.getByKey(SeperationTypes.Resignation)?.name
      },
      {
        selected: true,
        key: SeperationTypes.Termination,
        border: 'border-zen-red',
        text: 'text-zen-red',
        name: this.SeperationTypes.getByKey(SeperationTypes.Termination)?.name
      },
      {
        selected: true,
        key: SeperationTypes.Retirement,
        border: 'border-zen-blue',
        text: 'text-zen-blue',
        name: this.SeperationTypes.getByKey(SeperationTypes.Retirement)?.name
      }
    ]
  }


  ngOnInit(): void {
    this.topValue = document.querySelector('.h-header')?.clientHeight / 16;

    this.LoaderService.displayLoader();

    this.validateReportVisibility();

    this.initializeFirebaseUpdate();

    this.fetchTemplateLookup();

    const subscription = this.EssentialObject$.pipe(
      filter(x => x?.selectedEntity?.key != null),
      distinctUntilChanged((a, b) => a == b, c => c.selectedEntity.key),
    ).subscribe(obj => {



      this.language = obj.language;
      this.tenantKey = obj.selectedEntity.tenantKey;
      this.companyKey = obj.selectedEntity.key;
      this.selectedEntity = obj.selectedEntity;
      this.allEntities = obj.allEntities.filter(x => x.companyEntityKey == this.selectedEntity.key);

      this.hasCompanyPermission = this.permissionService.isSuperAdmin() || this.permissionService.hasPermission(this.companyKey, products.Exit);
      this.permissionList = this.permissionService.isSuperAdmin() ? [] : obj.user?.ModulePermissions?.filter(x => x.products.includes(products.Exit));

      this.filters = {
        companyKey: this.companyKey,
        tenantKey: this.tenantKey,
        survey: null,
        seperationType: this.seperationTypesButtons.filter(x => x.selected).map(y => y.key),
        timeframe: { startDate: this.timeframeStartDate, endDate: this.timeframeEndDate },
        showExtraFactors: false,
      }

      this.settingsLoaded.next(true);
    })

    this.addSubscription(subscription);

  }

  initializeFirebaseUpdate() {
    const subscription = combineLatest([this.templateManagementService.templateChangeAction$, this.settingsLoaded$]).pipe(
      tap(_ => { }),
      map(data => data[0]),
      filter(template => template != ""),
      switchMap(templateKey => {

        return this.firebaseUpdateSerivce.getListUpdate(this.tenantKey, this.companyKey, templateKey).pipe(
          skip(1),
          delay(3000)
        )
      }),
    ).subscribe(_ => {

      this.analyticsService.setFilters(this.filters);
    })

    this.addSubscription(subscription);
  }


  validateReportVisibility() {
    this.analyticsService.dummyFilters$.pipe(
      filter(filters => filters != null),
      switchMap(filters => {
        return this.analysisService.getCompletedLeaverCount({
          tenantKey: filters.tenantKey,
          companyKey: filters.companyKey,
          surveyKey: filters.survey.key,
          filters: [... this.analyticsService.mapGlobalFilters(filters)],
        }).pipe(
          map(counts => ({ show: !!counts, filters }))
        )
      }),
    ).subscribe(({ show, filters }) => {

      // to not load the component twice if the viewdisabled == false
      let isLoadComponent = this.viewDisabled;


      if (show == true) {
        this.viewDisabled = false;
        this.cdr.detectChanges();
        this.analyticsService.setFilters(filters, false);

        if (isLoadComponent)
          this.loadTabComponent(this.activeTab);

        return;
      }

      return this.viewDisabled = true;
    })
  }

  fetchTemplateLookup() {

    const subscription = this.settingsLoaded$.pipe(
      switchMap(_ => {
        return this.templateManagementService.fetchTemplates(this.tenantKey, this.companyKey, this.language, false)
      }),
      switchMap(templates => {

        return this.analysisService.getCompletedEntityLeaverCounts({
          tenantKey: this.tenantKey,
          companyKey: this.companyKey,
          surveyKey: templates.selectedTemplate.key,
          filters: this.analyticsService.mapGlobalFilters(this.filters)
        }).pipe(
          map(counts => ({ counts, templates }))
        )

      })
    ).subscribe(({ counts, templates }) => {
      this.templateMenu = templates.templateMenu;
      this.templates = templates.templates;
      this.selectedTemplate = templates.selectedTemplate;

      this.filters.survey = {
        key: templates.selectedTemplate.key,
        name: this.selectedTemplate.name,
        sections: templates.templates.find(x => x.key == this.selectedTemplate.key)?.sections
      }

      // Load the permissions
      this.treeList = this.entityTreeService.get(this.allEntities, this.selectedEntity, counts as any);

      if (this.permissionList && this.treeList) {
        this.populateEntityHierarchyWithPermissions(this.treeList, this.permissionList);
      }

      this.assignEntityIdsFilter();

      this.analyticsService.setFilters(this.filters);

      this.tabClick(this.activeTab);

      this.LoaderService.hideLoader();
    })

    this.addSubscription(subscription);
  }


  clearFilters() {
    this.timeframeStartDate = null;
    this.timeframeEndDate = null;
    this.filters.timeframe = { startDate: this.timeframeStartDate, endDate: this.timeframeEndDate };
    this.analyticsService.setFilters(this.filters);
    // this.selectedSeperationType = [];
  }


  openTree() {
    this.ZenCDKService.openComponent(EntityTreeModalComponent, { componentData: { tselectedChildEntity: this.selectedChildEntity, treeList: this.treeList }, language: this.language, showLeaverCount: true })
      .subscribe((tselectedChildEntity: Hierarchy) => {
        this.selectedChildEntity = tselectedChildEntity;
        this.assignEntityIdsFilter()
        this.analyticsService.setFilters(this.filters);
      })
  }

  getChildren(hierarchy: Hierarchy) {
    let children = this.allEntities.filter(e => e.parentKey == hierarchy.entity.key);
    let hierarchyChildren: Hierarchy[] = [];
    for (let i = 0; i < children.length; i++) {
      let child = JSON.parse(JSON.stringify(children[i]));
      let h = new Hierarchy;
      h.entity = new HierarchyEntity();
      h.entity.key = child.key;
      h.entity.parentKey = child.parentKey;
      h.entity.name = child.name;
      h.entity.count = this.entityCounts.find(x => x.entityKey == child.key)?.totalCount || 0;
      h.entity.groupKey = child.entityGroupKey;
      h.children = this.getChildren(h);
      hierarchyChildren.push(h);

      hierarchy.entity.count += h.entity.count;
    }
    return hierarchyChildren;
  }

  tabClick(tab) {
    this.sideBarNavigationTabs.forEach(x => x.selected = false);
    tab.selected = true;

    this.loadTabComponent(tab);
  }


  openViewByModal() {
    this.viewByOption = true;
  }

  export(isDemo?) {
    if (isDemo && this.activeTab?.id == 4)
      return;
    switch (this.activeTab?.id) {

      // When clicked on comments - download an excel sheet
      case 4:
        this.downloadComments()
        break;

      default:
        this.ZenCDKService.openComponent(ExitAnalysisReportComponent, {
          langauge: this.language,
          entity: this.selectedEntity,
          currentViewdEntity: this.selectedChildEntity,
          filters: this.filters,
          template: this.templates.find(x => x.key == this.selectedTemplate.key)
        } as ExitReportExportData);

    }

  }

  downloadComments() {
    this.LoaderService.displayLoader();
    this.commentsAiService.downloadComments();

  }


  loadTabComponent(tab: SidebarNavigationModel) {

    if (!this.tabContent)
      return;

    this.tabContent.clear();
    const component = this.tabContent.createComponent<any>(tab.component);

    component.instance.AnalysisComponentData = {
      companyKey: this.filters.companyKey,
      tenantKey: this.filters.tenantKey,
      language: this.language,
      surveyKey: this.surveyKey,
      selectedEntity: this.selectedEntity
    } as AnalysisComponentModel

    this.tabContent.insert(component.hostView);
  }

  get hasExtraFactors() {
    if (this.filters.survey) {
      const index = this.filters.survey.sections?.findIndex(x => x.isPredefined == false && x.isFactor);
      return index > -1 ? true : false;
    }

    return false;
  }


  get activeTab() {
    return this.sideBarNavigationTabs.find(x => x.selected == true) || this.sideBarNavigationTabs[0];
  }

  toggleHasExtraFactors() {
    this.filters.showExtraFactors = this.filters.showExtraFactors;
    this.analyticsService.setFilters(this.filters);
  }


  getChildKeys(root, keys = []) {

    keys.push(root.entity.key);

    if (root.children.length) {

      root.children.forEach(x => {
        keys = this.getChildKeys(x, keys);
      });

    }

    return keys;

  }

  private assignEntityIdsFilter() {
    if ((this.companyKey == this.selectedChildEntity.entity.key) && this.hasCompanyPermission) {
      this.filters.entityId = [];
    } else {
      this.filters.entityId = this.getChildKeys(this.selectedChildEntity, []);
    }

    this.analyticsService.setFilters(this.filters);
  }

  populateEntityHierarchyWithPermissions(treeList: Hierarchy[], permissionList: CompanyPermission[]) {

    for (var treeEntity of treeList) {
      treeEntity['hasPermission'] = this.permissionService.isSuperAdmin() ? true : permissionList.find(x => x.key == treeEntity.entity.key) ? true : false;

      if (this.selectedChildEntity == null && treeEntity['hasPermission'] == true) {
        this.selectedChildEntity = treeEntity;
      }

      if (treeEntity.children.length) {
        this.populateEntityHierarchyWithPermissions(treeEntity.children, permissionList);
      }
    }

  }

  onTemplateChange(template: DualSelectItem) {

    this.selectedTemplate = template;
    this.filters.survey = {
      key: template.key,
      name: template.name,
      sections: this.templates.find(x => x.key == template.key)?.sections
    }

    this.analyticsService.setFilters(this.filters);

    this.templateManagementService.templateChangeAction.next(template.key);
  }

  onTimeframeChanged() {
    if (this.timeframeStartDate > this.timeframeEndDate)
      return;

    this.filters.timeframe = { startDate: this.timeframeStartDate, endDate: this.timeframeEndDate };

    this.analyticsService.setFilters(this.filters);
  }

  onSeperationChange(button) {
    var selected = this.seperationTypesButtons.filter(x => x.selected).length;

    if (button.selected && selected < 2)
      return;

    button.selected = !button.selected

    this.filters.seperationType = this.seperationTypesButtons.filter(x => x.selected).map(x => x.key);
    this.analyticsService.setFilters(this.filters);
  }
}
