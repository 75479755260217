import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { environment } from 'src/environments/environment';
import { SurveyCreatorModel, CreatorBase, SurveyLogic, localization, editorLocalization } from "survey-creator-core";
import { Action, PageModel, Question, Serializer } from "survey-core";

import { AppInjector } from 'src/app/app.module';
import { Direction } from '@angular/cdk/bidi';
import { LoaderService } from '@ex/module/core/services/loader.service';
import { LocalService } from '@ex/module/core/services/local.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { TranslatePipe } from '@ex/module/shared/pipes/translate.pipe';
import { SharedService } from '@ex/module/survey-x/data-access/services/shared-service.service';

import { updateENPSQuestionHTML, updateNPSQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/nps-operations/nps-questions-operations';
import { updateDropdownQuestionHTML, updateTagboxQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/dropdown-operations/dropdown-question-operations';
import { updateFileUploadQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/file-upload-operations/file-upload-question-operations';
import { updateIconChoiceQuestionHTML, updateImagePickerQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/image-choice-operations/image-choice-question-operations';
import { updateMatrixQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/matrix-operations/matrix-question-operations';
import { updateRadioGroupQuestionHTML, updateCheckBoxsQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/multiple-choices-operations/multiple-choices-questions-operations';
import { updateRankingQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/ranking-operations/ranking-question-operations';
import { updateRatingScaleQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/rating-scale-operations/rating-scale-question-operations';
import { updateLongTextQuestionHTML, updateBooleanQuestionHTML, updateNumberInputQuestionHTML, updateEmailInputQuestionHTML, updateDateInputQuestionHTML, updateSliderQuestionHTML, updateShortTextQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/simple-questions-operations/simple-questions-operations';
import { updateRatingStarsQuestionHTML } from '@ex/module/survey-x/ui/builder/questions/start-rating-operations/star-rating-question-operations';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { filter } from 'rxjs/operators';
import { FormService } from '@ex/survey-x/index';
import { CustomDatepickerComponent } from '@ex/module/survey-x/ui/custom-datepicker/custom-datepicker.component';

const creatorOptions = {
  showLogicTab: true,
  showTranslationTab: true,
  isAutoSave: true
};

let creatorClone: CreatorBase;
let changeDetectorRefClone: ChangeDetectorRef;
let sharedService: SharedService;

var translationPipe: TranslatePipe;
var moduleNames: any;
var currentLocale;




let usedQuestionsInLogic = undefined;

function getUsedQuestonsInLogic(creator) {
  if (!usedQuestionsInLogic) {
    const logic = new SurveyLogic(creator.survey, creator);
    usedQuestionsInLogic = logic.getUsedQuestions();
  }
  return usedQuestionsInLogic;
}
function clearUsedQuestionsInLogic() {
  usedQuestionsInLogic = undefined;
}

function isObjectUsedInExpressions(creator, question) {
  const questions = getUsedQuestonsInLogic(creator);
  return questions.indexOf(question) > -1;
}

@Component({
  selector: 'app-questions-builder-page',
  templateUrl: './questions-builder-page.component.html',
  styleUrls: ['./questions-builder-page.component.scss'],
})
export class QuestionsBuilderPageComponent extends BaseComponent implements OnInit, AfterContentChecked {
  static declaration = [CustomDatepickerComponent];

  showAssignWeights = false;
  updatedCreator: any;
  survey: any = {}
  surveyId: string = "";
  dLanguage: any;
  form: any;
  tenant: any;
  tenantName: any;
  tenantKey: any;
  currentUser: any;
  currentUserName: any;

  isAddQuestionModalOpen: boolean = false;

  endPoint = environment.endPoint
  direction: Direction = 'ltr';
  currentLocale: any;

  ModuleNames = ModuleName;

  language = "EN"
  textPosition = "left"
  companyKey: string;
  companyName: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private surveysService: FormService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService,
    private translationPipe: TranslatePipe,
    public loader: LoaderService,
    private localService: LocalService,
  ) {
    super();

    this.addSubscription(this.EssentialObject$.pipe(filter(x => x.selectedEntity?.key != null)).subscribe(obj => {
      this.language = obj.language;
      this.companyKey = obj.selectedEntity.key;
      this.tenantKey = obj.tenant.key
      this.companyName = obj.selectedEntity.name;

      this.currentLocale = this.language.toLowerCase();
      currentLocale = this.currentLocale;
      this.language = this.localService.getJsonValue('language')
      this.textPosition = "left"
      if (this.language == "AR") {
        this.direction = "rtl"
        this.textPosition = "right"
      }
      else {
        this.direction = "ltr"
        this.textPosition = "left"
      }

      if (this.language == "EN") {
        this.dLanguage = "default"
      }
      else {
        this.dLanguage = this.language.toLowerCase();
      }
    }))

  }

  ngAfterContentChecked() {
    // this.cdr.detectChanges();
  }



  /**
   * Initializes the component and performs necessary setup tasks.
   */
  async ngOnInit() {
    this.loader.displayLoader();







    translationPipe = this.translationPipe;
    moduleNames = this.ModuleNames;

    sharedService = this.sharedService;

    // Retrieve and assign the tenant information from local storage
    this.tenant = JSON.parse(localStorage.getItem("tenant")!);
    this.tenantName = this.tenant.name;
    this.tenantKey = this.tenant.key;

    // Retrieve and assign the current user information from local storage
    this.currentUser = JSON.parse(localStorage.getItem("user")!);
    this.currentUserName = this.currentUser.firstName + " " + this.currentUser.lastName;

    // Get the survey ID from the route parameters
    this.surveyId = this.route.snapshot.paramMap.get('id');

    // Fetch the survey data from the service
    this.form = await this.surveysService.getFormWithoutResponses(this.surveyId).toPromise();
    this.survey = this.form.query;
    // Determine whether to show the assign weights option based on the survey type
    // this.showAssignWeights = this.survey.typeName !== "Survey";

    // Create a new SurveyCreatorModel instance and configure it
    const creator = new SurveyCreatorModel(creatorOptions);


    creator.text = this.survey.jsonText;
    // creator.survey.locale = this.survey.locale;
    creator.survey.locale = this.language.toLowerCase();


    creator.onGetObjectDisplayName.add((sender, options) => {
      if (options.area === "page-selector" && !!options.obj.title) {
        options.displayName = options.obj.title;
      }

    });


    creator.onDefineElementMenuItems.add((sender, options) => {
      if (options.obj.getType() === "page") {
        const page = options.obj;
        options.items.unshift({
          id: "new-question",
          title: "New Question",
          iconName: "icon-expanddetail",
          css: "sv-action-bar-item--secondary",
          action: () => {
            this.sharedService.handleSelectedType();
          },
        });
      }
    });



    //-----------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------

    // creatorClone.onElementAllowOperations.add((creator, options) => {
    //   var obj = options.obj;
    //   if (obj.isQuestion) {
    //     options.allowDragging = !isObjectUsedInExpressions(creator, obj);

    //   }
    // });
    // creatorClone.onSurveyPropertyValueChanged.add((creator, options) => {
    //   if (!options.obj.isQuestion) return;
    //   const prop = Serializer.findProperty(
    //     options.obj.getType(),
    //     options.propertyName
    //   );
    //   if (prop && (prop.type === "expression" || prop.type === "condition")) {
    //     clearUsedQuestionsInLogic();
    //   }
    // });
    // creatorClone.onElementDeleting.add((sender, options) => {
    //   clearUsedQuestionsInLogic();
    // });

    //-----------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------------------------------


    // this.currentLocale = this.survey.locale;
    // currentLocale = this.survey.locale;


    // Render the survey using the survey creator's render method
    creator.survey.render();


    // Define the saveSurveyFunc callback function to handle saving the survey
    creator.saveSurveyFunc = (saveNo: number, callback: Function) => {
      callback(saveNo, true);
      this.survey.jsonText = creator.text;
      if (!creator.JSON.pages?.length) {
        var page = new PageModel();
        page.name = this.translationPipe.transform("txtSection", this.language, this.ModuleNames.SurveyX);
        page.title = this.translationPipe.transform("txtSection", this.language, this.ModuleNames.SurveyX);
        creator.survey.addPage(page, 0);
        return this.SnackbarService.error(this.localize('txtAtLeastOneSection', this.language, this.ModuleNames.SurveyX));
      }
      // Convert the JSON text to key-value pairs
      const jsonObject = JSON.parse(this.survey.jsonText);
      const arrData: { key: string; value: any }[] = [];
      Object.keys(jsonObject).forEach((itemK) => {
        arrData.push({ key: itemK, value: jsonObject[itemK] });
      });

      this.survey.jsonTextPair = JSON.stringify(arrData);
      this.survey.modifiedBy = this.currentUserName;

      this.addSubscription(this.surveysService.updateForm(this.survey.id, this.survey).subscribe(x => {
        callback(saveNo, false);
      }));
      // saveSurveyJson(
      //   this.endPoint + "form-table/update-form/" + this.survey.id,
      //   this.survey,
      //   saveNo,
      //   callback
      // );
    };

    // Assign the survey creator model to the component property
    creatorClone = creator;

    // Hide the sidebar
    creatorClone.showSidebar = false;


    // Clear the toolbar items and only keep the "Show Panel" action
    let showPanelAction: Action = creatorClone.toolbarItems.find(item => item.title === "Show Panel") as Action;
    creatorClone.toolbar.actions = [showPanelAction];

    // Set the change detector reference and survey creator clone for future use
    changeDetectorRefClone = this.cdr;

    // Add event handlers for question rendering and property changes

    creatorClone!.survey.onAfterRenderQuestion.add((survey, options) => {
      //remove the listeners
      options.question.onPropertyChanged.clear();

      options.question.onPropertyChanged.add((sender, propertyOptions) => {
        if (sender instanceof Question) {
          // Update the question body and comment box when a property changes
          updateQuestionBodyHTML(options);
          addCommentBox(options);
        }
      });

      // Update the question body and comment box
      updateQuestionBodyHTML(options);
      addCommentBox(options);

      // Trigger change detection
      // this.cdr.detectChanges();
    });

    // Add event handlers for question drag and drop
    creatorClone!.dragDropSurveyElements.onDragEnd.add((survey, options) => {
      console.log("options.toElement: ", options.toElement);
      if (options.toElement instanceof PageModel && options.toElement.questions.length == 0) {
        options.toElement.addQuestion(options.draggedElement);
      }

      //update the question name
      creatorClone.survey.getAllQuestions().forEach((question: any) => {
        var oldName = question.name;
        var newName = 'question' + question["no"].split(".")[0];

        creatorClone.survey.getAllQuestions().forEach((q: any) => {
          if (q.name == oldName && q.visibleIf != null) {
            console.log("oldName q.visibleIf: ", q.visibleIf);
            console.log("indexOf oldName q.visibleIf: ", q.visibleIf.indexOf(oldName) != -1);
            console.log("indexOf newName q.visibleIf: ", q.visibleIf.indexOf(newName) != -1);
          }

          //update visableIf questionName
          if (q.visibleIf != null && q.visibleIf.indexOf(oldName) != -1) {
            q.visibleIf = q.visibleIf.replace(oldName, newName);
          }

          if (q['dependencyQuestions'] != null && q['dependencyQuestions'].length > 0) {
            q['dependencyQuestions'].forEach((depQuestion: any) => {
              if (depQuestion.questionName == oldName) {
                depQuestion.questionName = newName;
              }
            });
          }
        });

        question.name = newName;
      });
      // this.cdr.detectChanges();
    });

    // Trigger change detection
    // changeDetectorRefClone.detectChanges();
    this.loader.hideLoader();
  }





  getCreator() {
    return creatorClone;
  }

  /**
   * Navigate to the next step based on the current state.
   */
  nextStep() {
    // Save the survey JSON data
    //saveSurveyJson(this.endPoint + "form-table/update-form/" + this.survey.id, this.survey, null, null);

    const route = this.showAssignWeights ? '/survey-x/form-builder/assign-weights/' : '/survey-x/form-builder/languages/';
    this.router.navigateByUrl(route + this.surveyId);
  }

  /**
   * Navigate to the previous step.
   */
  previousStep() {
    // Save the survey JSON data
    //saveSurveyJson(this.endPoint + "form-table/update-form/" + this.survey.id, this.survey, null, null);

    this.router.navigateByUrl('/survey-x/form-builder/initial-setup/' + this.surveyId);
  }

  pageChanged(event: any) {
    this.router.navigateByUrl(`/survey-x/form-builder/${event}/${this.surveyId}`);
  }




  getTranslatedTitle(title: any) {

    let title1 = JSON.parse(title)
    if (typeof title1 == 'string') {
      return title1;
    }
    else {
      return title1[this.dLanguage]
    }
  }








}

function saveSurveyJson(url: string | URL, json: object, saveNo: number, callback: Function) {
  const request = new XMLHttpRequest();
  request.open('PUT', url);
  request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  request.addEventListener('load', () => {
    callback(saveNo, true);
  });
  request.addEventListener('error', () => {
    callback(saveNo, false);
  });
  request.send(JSON.stringify(json));
}

export function updateCurrentHeader(options: any, type: string, icon: string) {
  //find question-header-type
  var questionTypeButton = options.htmlElement.querySelector(".question-header-type");

  if (!questionTypeButton) {
    return;
  }


  questionTypeButton.innerHTML = '<i class="fa-regular ' + icon + '"></i> ' + questionsBuilderTranslateText(type);
}

/**
 * Updates the question header HTML based on the provided options.
 * @param options - The options for updating the question header HTML.
 * @param type - The type of the question.
 * @param icon - The icon associated with the question type.
 */
export function updateQuestionHeaderHTML(options: any, type: string, icon: string) {

  var header = options.htmlElement.querySelector("h5");
  if (!header) header = options.htmlElement as HTMLHeadingElement;

  if (header.children[3] == null) {
    updateCurrentHeader(options, type, icon);
    return;
  }

  header.children[3].className = header.children[3].className + " w-full block title-input p-2";
  header.children[3].children[1].className = header.children[3].children[1].className + " w-full block";
  header.children[3].children[1].children[0].className = header.children[3].children[1].children[0].className + " w-full block";
  header.children[3].children[1].children[0].children[0].className = "";
  header.children[3].children[1].children[0].children[1].children[0].className = header.children[3].children[1].children[0].children[1].children[0].className + " w-full block px-2";

  //Create the new header
  var headerContainer = document.createElement("div");
  headerContainer.className = "w-full block";

  var headerToolbar = document.createElement("div");
  headerToolbar.className = "question-header flex justify-between items-center w-full mt-3 mb-4";

  var questionIndex = document.createElement("h6");
  questionIndex.className = "question-card-title";

  questionIndex.innerHTML = questionsBuilderTranslateText("Question") + " " + getQuestionNumber(options.question);

  function getQuestionNumber(question: any) {
    // const match = question.name.match(/([A-Za-z]+)([0-9]+)/);

    // let word = match[1];
    // let number = match[2];

    let number = question["no"].split(".")[0]

    return number;
  }


  var questionTypeButton = document.createElement("button");
  questionTypeButton.className = "cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-3 question-header-type";
  //Insert the question type into the header button
  questionTypeButton.innerHTML = '<i class="fa-regular ' + icon + '"></i> ' + questionsBuilderTranslateText(type);
  // questionTypeButton.disabled = true;

  questionTypeButton.onclick = () => {
    creatorClone!.selectElement(options.question);
    var x = { name: 'showQuestionTypeModal', newValue: true, oldValue: false }
    creatorClone!.survey.onPropertyChanged.fire(creatorClone!.survey, x);
  };

  var questionIndexContainer = document.createElement("div");
  questionIndexContainer.className = "flex items-center gap-12 mb-2";

  var headerButtonsContainer = document.createElement("div");
  headerButtonsContainer.className = "flex items-center gap-5 mb-2";

  //Create the description box (Hint)
  var descriptionBox = document.createElement("div");
  descriptionBox.className = "flex gap-1 items-center mt-1 mb-2 description-box";
  descriptionBox.innerHTML = options.question["description"];

  //icon
  var lightbulbIcon = document.createElement("i");
  lightbulbIcon.className = "fa-regular fa-lightbulb font-normal text-lg text-zen-lite";
  var firstEdit = false;
  var descriptionInput = document.createElement("span");
  descriptionInput.className = "text-xs text-exp font-normal placeholder-exp p-1 mx-1";
  descriptionInput.style.fontSize = '12px'
  descriptionInput.textContent = options.question["description"] ? options.question["description"] : questionsBuilderTranslateText("txtAdd Question Hint");
  descriptionInput.setAttribute("contenteditable", "true");
  descriptionInput.oninput = (event) => {
    if (!firstEdit) {
      descriptionInput.textContent = event['data']
      firstEdit = true
    }
    options.question["description"] = descriptionInput.textContent;

    if (descriptionInput.textContent == "") {
      descriptionInput.textContent = questionsBuilderTranslateText("txtAdd Question Hint");
    }
  };

  var optionalText = document.createElement("p");
  optionalText.className = "text-zen-lite text-xs font-normal";
  optionalText.innerHTML = questionsBuilderTranslateText("(txtoptional)");

  descriptionBox.appendChild(lightbulbIcon);
  descriptionBox.appendChild(descriptionInput);
  descriptionBox.appendChild(optionalText);

  questionIndexContainer.appendChild(questionIndex);
  questionIndexContainer.appendChild(questionTypeButton);

  headerToolbar.appendChild(questionIndexContainer);
  headerToolbar.appendChild(headerButtonsContainer);

  headerContainer.appendChild(headerToolbar);
  headerContainer.appendChild(header.children[3]);
  headerContainer.appendChild(descriptionBox);
  //

  while (header.firstChild)
    header.removeChild(header.firstChild);

  header.appendChild(headerContainer);

  viewMode(options, headerButtonsContainer);
}

function viewMode(options: any, headerButtonsContainer: any) {
  headerButtonsContainer.innerHTML = "";

  //hide description-box
  options.htmlElement.querySelector(".description-box").style.display = "flex";

  //show question-header-type
  options.htmlElement.querySelector(".question-header-type").style.display = "flex";

  //show drag and drop handle svc-question__drag-area
  options.htmlElement.parentElement.querySelector(".svc-question__drag-area").style.display = "flex";

  //Edit Button
  var editButton = document.createElement("button");
  editButton.className = "cancel text-center flex flex-row items-center rounded px-4 py-2 leading-9 text-xs font-medium gap-3 question-edit-button";
  //Insert the question type into the header button
  editButton.innerHTML = '<i class="fa-regular fa-pen"></i>' + questionsBuilderTranslateText("Edit");
  editButton.onclick = function () {

    editMode(options, headerButtonsContainer);

    // Select the question in the creator
    creatorClone!.selectElement(options.question);
    // changeDetectorRefClone.detectChanges();

    sharedService.setSelectedQuestionParent(options.htmlElement.parentElement.parentElement);
    sharedService.setSelectedQuestion(options);

    creatorClone!.showSidebar = true;
    // changeDetectorRefClone.detectChanges();
  }




  //Ellipsis Button
  var ellipsisButton = document.createElement("button");
  ellipsisButton.className = "text-center flex flex-row items-center rounded px-4 py-4 gap-3 ellipsis-button header-ellipsis-button";
  ellipsisButton.innerHTML = '<i class="fa-solid fa-ellipsis-vertical"></i>';

  //dropdown menu for ellipsis button
  var ellipsisDropdownMenu = document.createElement("div");

  if (currentLocale.toUpperCase() != 'AR') {
    ellipsisDropdownMenu.className = "absolute right-0 mt-2 w-max p-1 rounded-md  shadow-lg bg-white ring-1 ring-black ring-opacity-5 hidden header-dropdown-menu";
  }
  else {
    ellipsisDropdownMenu.className = "absolute left-0 mt-2 w-max p-1 rounded-md  shadow-lg bg-white ring-1 ring-black ring-opacity-5 hidden header-dropdown-menu";

  }
  var ellipsisDropdownMenuList = document.createElement("ul");
  ellipsisDropdownMenuList.className = "py-1";

  var ellipsisDropdownMenuDeleteItem = document.createElement("li");
  ellipsisDropdownMenuDeleteItem.className = "flex flex-row btn cursor-pointer h-10 w-28 p-2 text-sm text-zen hover:bg-exp hover:bg-opacity-20 leading-8 items-center whitespace-nowrap gap-2";

  var ellipsisDropdownMenuDuplicateItem = document.createElement("li");
  ellipsisDropdownMenuDuplicateItem.className = "flex flex-row btn cursor-pointer h-10 w-28 p-2 text-sm text-zen hover:bg-exp hover:bg-opacity-20 leading-8 items-center whitespace-nowrap gap-2";

  var deleteButton = document.createElement("button");

  if (currentLocale.toUpperCase() != 'AR') {
    deleteButton.className = "w-full text-left";
  }
  else {
    deleteButton.className = "w-full text-right";
  }

  deleteButton.innerHTML = '<i class="fa-regular fa-trash mx-1"></i> ' + questionsBuilderTranslateText("Delete");
  deleteButton.style.color = "red";
  deleteButton.onclick = function () {
    //Delete the question
    creatorClone!.deleteCurrentElement();
    creatorClone.survey.currentPage.removeElement(options.question);



    creatorClone.survey.getAllQuestions().forEach((question: any) => {
      var oldName = question.name;
      var newName = 'question' + question["no"].split(".")[0];

      creatorClone.survey.getAllQuestions().forEach((q: any) => {
        if (q.name == oldName && q.visibleIf != null) {
          console.log("oldName q.visibleIf: ", q.visibleIf);
          console.log("indexOf oldName q.visibleIf: ", q.visibleIf.indexOf(oldName) != -1);
          console.log("indexOf newName q.visibleIf: ", q.visibleIf.indexOf(newName) != -1);
        }

        //update visableIf questionName
        if (q.visibleIf != null && q.visibleIf.indexOf(oldName) != -1) {
          q.visibleIf = q.visibleIf.replace(oldName, newName);
        }

        if (q['dependencyQuestions'] != null && q['dependencyQuestions'].length > 0) {
          q['dependencyQuestions'].forEach((depQuestion: any) => {
            if (depQuestion.questionName == oldName) {
              depQuestion.questionName = newName;
            }
          });
        }
      });

      question.name = newName;
    });


    // changeDetectorRefClone.detectChanges();
  }

  var duplicateButton = document.createElement("button");


  if (currentLocale.toUpperCase() != 'AR') {
    duplicateButton.className = "w-full text-left";
  }
  else {
    duplicateButton.className = "w-full text-right";
  }

  duplicateButton.innerHTML = '<i class="fa-regular fa-copy"></i> ' + questionsBuilderTranslateText("Duplicate");

  duplicateButton.onclick = () => {
    creatorClone!.fastCopyQuestion(options.question);

    if (options.question?.ratingLabels) {
      const mapped = options.question.ratingLabels.map(x => JSON.parse(JSON.stringify(x)));

      options.question.setPropertyValue("ratingLabels", []);
      options.question.setPropertyValue("ratingLabels", mapped);
    }

  }

  ellipsisDropdownMenuDeleteItem.appendChild(deleteButton);
  ellipsisDropdownMenuDuplicateItem.appendChild(duplicateButton);

  ellipsisDropdownMenuList.appendChild(ellipsisDropdownMenuDeleteItem);
  ellipsisDropdownMenuList.appendChild(ellipsisDropdownMenuDuplicateItem);

  ellipsisDropdownMenu.appendChild(ellipsisDropdownMenuList);
  ellipsisButton.appendChild(ellipsisDropdownMenu);

  // Flag to track whether the dropdown is currently open
  let isDropdownOpen = false;

  ellipsisButton.addEventListener('pointerdown', function (event) {

    // Close all other open dropdowns
    var dropdowns = document.querySelectorAll('.header-dropdown-menu:not(.hidden)');
    dropdowns.forEach(function (openDropdown) {
      openDropdown.classList.add('hidden');
    });

    // Open the clicked dropdown
    isDropdownOpen = true;
    ellipsisDropdownMenu.classList.remove('hidden');
    // changeDetectorRefClone.detectChanges();
  });

  document.addEventListener('mousedown', function (event) {
    const target = event.target as HTMLElement;
    const isEllipsisButton = target instanceof HTMLElement && target.closest('.header-ellipsis-button');
    const isDropdown = target instanceof HTMLElement && target.closest('.header-dropdown-menu');

    if (!isEllipsisButton && !isDropdown) {
      setTimeout(function () {
        if (!(target instanceof HTMLElement && target.closest('.header-ellipsis-button')) &&
          !(target instanceof HTMLElement && target.closest('.header-dropdown-menu'))) {
          isDropdownOpen = false;
          ellipsisDropdownMenu.classList.add('hidden');
        }
      }, 50);
    }
  });

  // Additional code to close other dropdowns when clicking outside
  document.addEventListener('click', function (event) {
    const target = event.target as HTMLElement;

    // Close other dropdowns when clicking outside any ellipsis button
    if (!(target instanceof HTMLElement && target.matches('.header-ellipsis-button'))) {
      var dropdowns = document.querySelectorAll('.header-dropdown-menu:not(.hidden)');

      dropdowns.forEach(function (openDropdown) {
        openDropdown.classList.add('hidden');
      });
    }
  });

  // Additional code to close other dropdowns when clicking outside
  options.htmlElement.onclick = function (event) {
    if (!event.target.matches('.header-ellipsis-button')) {
      var dropdowns = document.getElementsByClassName('header-dropdown-menu');

      for (var i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (!openDropdown.classList.contains('hidden')) {
          openDropdown.classList.add('hidden');
        }
      }
    }
  };

  headerButtonsContainer.appendChild(editButton);
  headerButtonsContainer.appendChild(ellipsisButton);

  options.htmlElement.classList.remove("question-edit-mode");
  // changeDetectorRefClone.detectChanges();
  if (options.question.updateView) {

    editMode(options, headerButtonsContainer);

    // Select the question in the creator
    creatorClone!.selectElement(options.question);
    // changeDetectorRefClone.detectChanges();

    sharedService.setSelectedQuestionParent(options.htmlElement.parentElement.parentElement);
    sharedService.setSelectedQuestion(options);
    options.question.updateView = false;
  }

}

function editMode(options: any, headerButtonsContainer: any) {
  var randomTransactionId = Math.floor(Math.random() * 1000000000000000000);
  var transactionName = "Edit Mode " + randomTransactionId;
  creatorClone.undoRedoManager.startTransaction(transactionName);

  headerButtonsContainer.innerHTML = "";

  //show description-box
  options.htmlElement.querySelector(".description-box").style.display = "flex";

  //hide question-header-type
  options.htmlElement.querySelector(".question-header-type").style.display = "none";

  //hide drag and drop handle svc-question__drag-area
  options.htmlElement.parentElement.querySelector(".svc-question__drag-area").style.display = "none";

  var cancelButton = document.createElement("button");
  cancelButton.className = "btn text-zen-primarylight text-sm font-medium py-2 px-4";
  cancelButton.innerHTML = questionsBuilderTranslateText("Cancel");

  cancelButton.onclick = function () {
    creatorClone!.showSidebar = false;

    creatorClone!.stopUndoRedoTransaction();

    //get the last transaction name
    if (creatorClone.undoRedoManager['_transactions'] != null && creatorClone.undoRedoManager['_transactions'].length > 0) {
      var lastTransactionName = creatorClone.undoRedoManager['_transactions'][creatorClone.undoRedoManager['_transactions'].length - 1]['_name'];
      console.log('_transactions: ', creatorClone.undoRedoManager['_transactions']);

      console.log("lastTransactionName: ", lastTransactionName);
      console.log("transactionName: ", transactionName);

      if (lastTransactionName == transactionName) {
        console.log("undo");
        creatorClone!.undo();
      }
    }

    sharedService.getSelectedQuestionParentContentValue()
      .appendChild(sharedService.getSelectedQuestionContentValue().htmlElement.parentElement);

    sharedService.setSelectedQuestion(null);

    viewMode(options, headerButtonsContainer);
  }

  //add save button
  var saveButton = document.createElement("button");
  saveButton.className = "btn h-10 py-2 px-4 rounded bg-exp text-white text-sm font-medium";
  saveButton.innerHTML = questionsBuilderTranslateText("Save Changes");

  saveButton.onclick = function () {
    creatorClone!.stopUndoRedoTransaction();

    sharedService.getSelectedQuestionParentContentValue()
      .appendChild(sharedService.getSelectedQuestionContentValue().htmlElement.parentElement);

    creatorClone!.showSidebar = false;

    sharedService.setSelectedQuestion(null);
    viewMode(options, headerButtonsContainer);
  }

  //add the buttons to the header
  headerButtonsContainer.appendChild(cancelButton);
  headerButtonsContainer.appendChild(saveButton);

  options.htmlElement.classList.add("question-edit-mode");
  // changeDetectorRefClone.detectChanges();


}

/**
 * Updates the question header HTML based on the provided options.
 * @param options - The options for updating the question header HTML.
 * @param type - The type of the question.
 * @param icon - The icon associated with the question type.
 */
export function updateNPSQuestionHeaderHTML(options: any, type: string, icon: string) {
  updateQuestionHeaderHTML(options, type, icon);

  const input = options.question.locTitle['values']['default'];

  var part1 = "";
  if (options.question.rateType === "enps")
    part1 = questionsBuilderTranslateText("How likely are you to recommend");
  else
    part1 = questionsBuilderTranslateText("How likely are you to recommend our");

  const part2 = questionsBuilderTranslateText("to your friends and relatives?");

  const index1 = input.indexOf(part1);
  const index2 = input.indexOf(part2);

  if (index1 !== -1 && index2 !== -1) {
    const companyName = input.substring(index1 + part1.length, index2).trim();

    //Get the parent element of the question title
    var questionTitleParent = options.htmlElement.getElementsByClassName("svc-string-editor__input")[0];

    // Create a new span element to hold the non-editable part
    const nonEditableSpan = document.createElement("span");
    nonEditableSpan.className = "sv-string-editor";
    nonEditableSpan.textContent = questionsBuilderTranslateText(part1);

    // Create a new input element to hold the editable part
    const companyNameSpan = document.createElement("span");
    companyNameSpan.className = "sv-string-editor string-editor-border mx-1";
    companyNameSpan.textContent = questionsBuilderTranslateText(companyName);
    companyNameSpan.setAttribute("contenteditable", "true");

    // Create another span element for the remaining non-editable part
    const remainingSpan = document.createElement("span");
    remainingSpan.className = "sv-string-editor";
    remainingSpan.textContent = questionsBuilderTranslateText(part2);
    let language = '';
    if (options.question.survey['locale'] == 'default')
      language = 'EN';
    language = options.question.survey['locale'].toUpperCase() || 'EN'

    companyNameSpan.oninput = function () {
      options.question.title = part1 + ' ' + companyNameSpan.textContent + ' ' + part2;
      options.question.locTitle['values']['default'] = part1 + ' ' + companyNameSpan.textContent + ' ' + part2;
      if (!options.question.uniqueNPSText?.length) {
        options.question.uniqueNPSText = [];
        options.question.uniqueNPSText.push({ key: language, name: companyNameSpan.textContent });
      }
      else {
        let currValue = options.question.uniqueNPSText?.find(x => x.key == language);
        if (currValue)
          options.question.uniqueNPSText[options.question.uniqueNPSText.indexOf(currValue)].name = companyNameSpan.textContent;
        else options.question.uniqueNPSText.push({ key: language, name: companyNameSpan.textContent });
      }



    }

    var questionTitle = options.htmlElement.getElementsByClassName("sv-string-editor w-full block px-2")[0];

    if (questionTitle != null) {
      //Remove the question title from the parent element
      questionTitleParent.innerHTML = "";

      // Append the elements to the container
      questionTitleParent.appendChild(nonEditableSpan);
      questionTitleParent.appendChild(companyNameSpan);
      questionTitleParent.appendChild(remainingSpan);
    }
  }
}

////////////////////////////////////////////////////////////////////
/**
 * Updates the HTML body of the question based on the provided options.
 * @param options - The options for updating the question HTML body.
 */
function updateQuestionBodyHTML(options: any) {
  const questionType = options.question.getType();

  switch (questionType) {
    case 'text':
      handleTextQuestion(options); // Update HTML for text question
      break;

    case 'comment':
      updateLongTextQuestionHTML(options); // Update HTML for comment question
      break;

    case 'radiogroup':
      updateRadioGroupQuestionHTML(options); // Update HTML for radio group question
      break;

    case 'checkbox':
      updateCheckBoxsQuestionHTML(options); // Update HTML for checkbox question
      break;

    case 'dropdown':
      updateDropdownQuestionHTML(options); // Update HTML for dropdown question
      break;

    case 'tagbox':
      updateTagboxQuestionHTML(options); // Update HTML for tagbox question
      break;

    case 'rating':
      handleRatingQuestion(options); // Handle different types of rating questions
      break;

    case 'ranking':
      updateRankingQuestionHTML(options); // Update HTML for ranking question
      break;

    case 'imagepicker':
      handleImagePickerQuestion(options); // Handle different types of image picker questions
      break;

    case 'boolean':
      updateBooleanQuestionHTML(options); // Update HTML for boolean question
      break;

    case 'matrix':
      updateMatrixQuestionHTML(options); // Update HTML for matrix question
      break;

    case 'file':
      updateFileUploadQuestionHTML(options); // Update HTML for file upload question
      break;

    case 'datepicker':
      // updateFileUploadQuestionHTML(options); // Update HTML for file upload question
      updateQuestionHeaderHTML(options, "Date Picker", "fa-message-minus");
      break;

    default:
      throw new Error('Invalid question type'); // Throw error for invalid question type
  }
}

/**
 * Handles the HTML update for text-based questions based on the input type.
 * @param options - The options for updating the question HTML body.
 */
function handleTextQuestion(options: any) {
  const inputType = options.question['inputType'];

  switch (inputType) {
    case 'number':
      updateNumberInputQuestionHTML(options); // Update HTML for number input question
      break;

    case 'email':
      updateEmailInputQuestionHTML(options); // Update HTML for email input question
      break;

    case 'range':
      updateSliderQuestionHTML(options); // Update HTML for range slider question
      break;

    default:
      updateShortTextQuestionHTML(options); // Update HTML for short text question
      break;
  }
}

/**
 * Handles the HTML update for rating questions based on the rate type.
 * @param options - The options for updating the question HTML body.
 */
function handleRatingQuestion(options: any) {
  const rateType = options.question['rateType'];

  switch (rateType) {
    case 'labels':
      updateRatingScaleQuestionHTML(options); // Update HTML for rating scale question with labels
      break;

    case 'enps':
      updateENPSQuestionHTML(options); // Update HTML for eNPS (Employee Net Promoter Score) question
      break;

    case 'nps':
      updateNPSQuestionHTML(options); // Update HTML for NPS (Net Promoter Score) question
      break;

    default:
      updateRatingStarsQuestionHTML(options); // Update HTML for rating stars question
      break;
  }
}

/**
 * Handles the HTML update for image picker questions based on the isIconChoice property.
 * @param options - The options for updating the question HTML body.
 */
function handleImagePickerQuestion(options: any) {
  if (options.question['isIconChoice']) {
    updateIconChoiceQuestionHTML(options); // Update HTML for image picker question with icon choices
  } else {
    updateImagePickerQuestionHTML(options); // Update HTML for image picker question
  }
}

////////////////////////////////////////////////////////////////////
/**
 * Adds a comment box to the HTML element based on the provided options.
 * @param options - The options for adding the comment box.
 */
function addCommentBox(options: any) {
  // Find the body element where the comment box will be added
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // Check if the question has a comment or should show the comment area
  if (options.question['hasComment'] || options.question['showCommentArea']) {
    // Create the label for the comment box
    // var commentBoxLabel = document.createElement("label");
    // commentBoxLabel.className = "block text-sm font-medium text-gray-700 mt-2 mb-3";
    // commentBoxLabel.innerHTML = questionsBuilderTranslateText(options.question['commentText']);
    // Create the textarea for the comment box
    var commentBox = document.createElement("textarea");
    commentBox.className = "w-full block p-2 border border-gray-300 rounded-md shadow-sm mt-3";
    commentBox.placeholder = options.question['commentPlaceholder'];
    commentBox.rows = 3;
    commentBox.disabled = true;

    // Append the label and comment box to the body element
    // body.appendChild(commentBoxLabel);
    body.appendChild(commentBox);
  }
}

export function questionsBuilderTranslateText(text: string, Locale?) {
  let loc = Locale
  if (!Locale)
    loc = currentLocale || 'EN';
  translationPipe = AppInjector.get(TranslatePipe);
  return translationPipe.transform(text, loc.toUpperCase(), ModuleName.SurveyX);
}


