import { Component, Input, OnInit } from '@angular/core';
import { ChildrenAnimation, fadeUpDown } from '../../animations/allAnimations';
import { ModuleName } from '../../enums/ModuleName';

@Component({
  selector: 'validation-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  animations:[ChildrenAnimation]
})
export class EmailComponent implements OnInit {

  @Input() language;
  constructor() { }

  ngOnInit(): void {
  }
  ModuleNames=ModuleName
}
