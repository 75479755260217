/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { FeedbackRequestDto } from '../models/FeedbackRequestDto';
import type { FeedbackResponseDto } from '../models/FeedbackResponseDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class FeedbackAnalysisService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody 
     * @returns FeedbackResponseDto Success
     * @throws ApiError
     */
    public getList(
requestBody?: FeedbackRequestDto,
): Observable<FeedbackResponseDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/FeedbackAnalysis/all',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
