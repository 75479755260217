import {
  Component, HostListener, OnInit, ViewChild, Input, EventEmitter, Output, AfterViewInit, TemplateRef, ContentChild
} from '@angular/core';
import { animate, query, sequence, stagger, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers:[]
})
export class TableComponent implements OnInit {
  @ContentChild(TemplateRef) mytemplate: TemplateRef<any>;
  @Input('data') set givendata(data: any) {
    this.data = data;
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.updateGoto();
  }
  @Input('searchText') set searchText(text: string) {
    this.applyFilter(text,'all')
  }; 
  @Input('columns') set givenColumns (data: any){
    this.displayedColumns = data;
  }

  data;
  displayedColumns: any[];
  dataSource: any;
  newDate: any;
  pageOfItems =[];
  pageIndex = 0;
  pageNumbers;
  pageSize= 5;
  @ViewChild('paginator', { static: false }) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //displayedColumns: string[] = ['position', 'name', 'Employee Quota', 'Date Added', ' '];


  getRows(columns: any[]) {
    return columns.map(x => x['field']);
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  onPageEvent(event: PageEvent) {
    console.log(event);
 }
  displayYear() {
    
    return new Date().getFullYear();
  }


  applyFilter(filterValue, type?) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  
  updateGoto() {
    this.pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.data?.length / this.pageSize); i++) {
      this.pageNumbers.push(i);
    }
  }


  goToChange(number) {

    this.paginator.pageIndex = number;
    const event: PageEvent = {
      length: this.paginator.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize
    };
    this.paginator.page.next(event);
    this.pageIndex = number;
  }

}
