import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { AppInjector } from "src/app/app.module";
import { WbUtilitiesService } from '../data-access/services/wb-utilities.service';
import { WbStatus } from './wb-status';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { WellbeingScalesService } from './wellbeing-scales.service';
@Component({
  selector: 'wb-base',
  template: ``
})
export abstract class WbBaseComponent extends BaseComponent implements OnDestroy {

  constructor() {
    super();
  }
  public WbUtilities = AppInjector.get(WbUtilitiesService);

  public status = WbStatus;
  logData(e) {
    console.log(e);
  }

  wellbeingScales = [
    {
      scale: WellbeingScalesService.VeryPoorExperience
    },
    {
      scale: WellbeingScalesService.PoorExperience
    },
    {
      scale: WellbeingScalesService.FairExperience
    },
    {
      scale: WellbeingScalesService.GoodExperience
    },
    {
      scale: WellbeingScalesService.VeryGoodExperience
    }
  ]

}
