import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CHART_DATA_RESPONSE_STRATEGY } from '@ex/module/core/services/chart-data.service';
import { DATA_TOKEN, ICDKPortalComponent } from '@ex/module/shared/Theme/services/zen-cdk.service';
import { ChartOptionsService } from '@ex/module/shared/chart/chart-options.service';
import { CHART_EXPORT_CONFIGURATION, ChartsExportConfigResult } from '@ex/module/shared/export-components/chart-export-helpers.service';
import { NativeExportBaseComponent } from '@ex/module/shared/native-export-base/native-export-base.component';
import { AnalyticsFilter, AnalyticsService } from '@ex/module/survey-x/data-access/services/analytics.service';
import { CriteriaResolverService } from '@ex/module/survey-x/data-access/services/criteria-resolver.service';
import { ReportDataService } from '@ex/module/survey-x/data-access/services/report-data.service';
import { ResponseRateModel, SurveyAnalysisService } from '@ex/survey-x/index';
import { Subject, combineLatest, forkJoin } from 'rxjs';
import { filter, map } from 'rxjs/operators';
enum ChartGroup {
  Gender = 'Gender',
  Nationality = 'Nationality',
  Generation = 'Generation',
  AgeGroup = 'AgeGroup',
  TenureGroup = 'TenureGroup',
  Performance = 'PerformanceRate',
  BandLevel = 'BandLevel',
  JobGrade = 'JobGrade',
  SurveyStatus = 'SurveyStatus',
  SeperationType = "SeperationType"
}
@Component({
  selector: 'app-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss']
})
export class ExportReportComponent extends NativeExportBaseComponent implements OnInit, ICDKPortalComponent {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();
  breakIndex = 32;

  filtersLoaded$ = new Subject();

  filters: AnalyticsFilter
  language: any;
  selectedEntity: any;
  request: { companyKey: string; surveyKey: string; tenantKey: string; filters: any[]; };

  questionAnalysis;

  exportReport = true;
  exportAnalysis = false;

  colors = ['#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be'];
  tableOfContent: any[];
  filteredTableOfContent: any[];

  date: Date = new Date();
  calculationProcess: any[] = new Array(4);
  downloadReport = true;
  exporting:boolean = false;
  respondentCharts = [];
  overall:ResponseRateModel;
  surveyCriterias = [];
  exportableCharts: ChartsExportConfigResult;

  constructor(@Inject(DATA_TOKEN) private data: any
    , private analyticsService: AnalyticsService
    , private reportDataService: ReportDataService
    , private criteriaResolverService: CriteriaResolverService
    , private surveyAnalysisService: SurveyAnalysisService
    , private chartOptionsService: ChartOptionsService) {
    super()

    this.selectedEntity = data?.selectedEntity;
  }

  ngOnInit(): void {
    this.overall = this.data.overall;
    this.getQuestionAnalysis();
    this.getRespondentAnalysis();

    this.analyticsService.filters$.pipe(
      filter(x => x != null)
    ).subscribe(filters => {
      this.filters = filters;

      this.language = filters.language;
      this.surveyCriterias = filters.survey.surveyCriterias;
      this.request = {
        companyKey: filters.companyKey,
        surveyKey: filters.survey.key,
        tenantKey: filters.tenantKey,
        filters: filters.chartFilters || []
      }

      this.filtersLoaded$.next(filters);
    })



  }
  getRespondentAnalysis() {

    const sub = this.filtersLoaded$.subscribe(filters => {
      this.prepareCriteriaCharts();
      this.filterTableOfContent();
    })

    this.addSubscription(sub);

  }

  getQuestionAnalysis() {


    const sub = combineLatest([this.filtersLoaded$, this.reportDataService.questionCharts$]).pipe(
      filter(([filters, charts]) => charts.length > 0)
    ).subscribe(([filters, questions]) => {
      this.questionAnalysis = questions;
    })

    this.addSubscription(sub);
  }

  filterTableOfContent(event?) {
    this.tableOfContent = [];
    this.filteredTableOfContent = [];
    setTimeout(() => {
      this.tableOfContent = [

        {
          id: 'questionAnalysis',
          dispaly: this.exportReport,
          title: this.localize('txtQuestionAnalysis', this.language, this.ModuleNames.SurveyX),
          content: 0,
          children: [],
          hideChildren: true
        },
        {
          id: 'analysis',
          dispaly: this.exportAnalysis,
          title: this.localize('txtRespondentAnalysis', this.language, this.ModuleNames.SurveyX),
          content: 1,
          children: [],
        },
      ];



      let questionAnalysis = this.tableOfContent.indexOf(this.tableOfContent.find(x => x.id == "questionAnalysis"));
      this.tableOfContent[questionAnalysis].children = this.questionAnalysis.filter(x => x.page).map(x => {
        return {
          content: this.returnPagesPerChart(x)
        }
      })
      this.filteredTableOfContent = this.tableOfContent.filter(row => row.dispaly);
      let page = 2;
      for (let index = 0; index < this.filteredTableOfContent.length; index++) {
        if (this.filteredTableOfContent[index].content) {
          page += this.filteredTableOfContent[index].content;
          this.filteredTableOfContent[index].page = page;

        }
        else {
          page += 1;
          this.filteredTableOfContent[index].page = page--;
        }
        if (this.filteredTableOfContent[index]?.children?.length) {
          for (let num = 0; num < this.filteredTableOfContent[index].children.length; num++) {
            page = page + this.filteredTableOfContent[index].children[num].content;
            this.filteredTableOfContent[index].children[num].page = page;
          }
        }

      }
      console.log(this.filteredTableOfContent);

    }, 1000);
  }

  returnPagesPerChart(question: any) {
    if (question.type == 'ranking' || question.type == 'matrix')
      return 1;
    if (question?.choices?.length >= 2)
      return Math.ceil(question.choices.length / this.pageLines(question));
    return 1;
  }

  returnChartColors(series: any[]) {
    return series.map((x, index) => {
      return { key: x.name, color: this.colors[index % this.colors.length] }
    })
  }

  fullWdithChartCheck(chart, categories) {
    let rowsPerLine = 2;
    if (!chart.fullWidth || categories.find(x => x.length > 15))
      rowsPerLine = 1;
    return rowsPerLine;
  }

  isFixedHeight(chart: any) {
    return chart.DashletType == "stackH" || chart.DashletType == "barH" || chart?.page > 1 ? true : false;
  }

  assignPage(question) {
    if (!question.page)
      question.page = 1;
    return true;
  }

  sectionIndex(e) {
    let index = this.filteredTableOfContent?.find(s => s.id == e);
    return this.filteredTableOfContent?.indexOf(index) + 1;
  }


  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /** Respondent Analysis */

  prepareCriteriaCharts() {
    this.respondentCharts = []
    let criteriaCharts = this.surveyCriterias.filter(x => x.options.length > 0).map(criteria => {
      return {
        Title: this.localize('txtRespby', this.language, this.ModuleNames.SurveyX) + ' ' + this.ExtractPipe.transform(criteria.name, this.language),
        DashletType: 'barV',
        group: criteria.key,
        canGroup: false,
        language: this.language,
        halfWidth: false,
        isEntity: criteria.isEntity,
      }
    })

    this.respondentCharts = this.respondentCharts.concat(criteriaCharts);

    this.respondentCharts.forEach(chart => {
      chart.data$ = this.chartData(chart)
    })

    forkJoin(this.respondentCharts.map(x => x.data$)).subscribe(chartsData => {
      for (let index = 0; index < this.respondentCharts.length; index++) {
        this.respondentCharts[index].data = chartsData[index];
      }
      this.exportableCharts = CHART_EXPORT_CONFIGURATION.configureExitCharts(this.respondentCharts, { chartMax: this.breakIndex, chartsPerPage: 5 }).configure();
    })

  }

  chartData(chart) {
    return this.surveyAnalysisService.getGroupedRespondentsByCriteria(chart.group,
      {
        tenantKey: this.filters.tenantKey,
        companyKey: this.filters.companyKey,
        formId: this.filters.survey.key,
        type: chart.group != 'status' ? 'ec' : '',
        filters: [...this.analyticsService.mapGlobalFilters(this.filters) || []]
      }
    ).pipe(map((data: any) => {

      data.data.forEach(x => {
        x.key = x.category;
        x.category = this.criteriaResolverService.extract(chart.group, x.category, this.language);
      });

      let chartData = CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data.data).process();

      chartData.drillDown = data.drillDown.map(x => {
        x.category = this.criteriaResolverService.extract(chart.group, x.category, this.language)
        return x;
      });

      chart.DashletChartTypes = this.chartOptionsService.getSupportedChartTypes(chart, chartData.categories.length, chartData.groupedChart);

      console.log(chart);
      console.log(chartData);

      return chartData;
    }))
  }

  /** Respondent Analysis End */
  pageLines(question) {
    return question.type == 'imagepicker' ? 6 : 18;
  }
  getGenderKey(value) {
    return this.GeneralService.genders_new.find(s => s.name.find(x => x.name == value)).key;
  }
  fullWidthChartCheck(chart, categories) {
    let rowsPerLine = 2;
    if (!chart.fullWidth || categories.find(x => x.length > 15))
      rowsPerLine = 1;
    return rowsPerLine;
  }
  returnIcon(chart) {
    switch (chart.group) {
      case ChartGroup.Gender:
        return chart.icon = 'fa-regular fa-venus-mars';
      case ChartGroup.Nationality:
        return chart.icon = 'fa-regular fa-flag';
      case ChartGroup.Generation:
        return chart.icon = 'fa-regular fa-people-group';
      case ChartGroup.AgeGroup:
        return chart.image = '/assets/img/svg/age-group.svg';
      case ChartGroup.TenureGroup:
        return chart.image = '/assets/img/svg/tenure.svg';
      case ChartGroup.Performance:
        return chart.icon = 'fa-regular fa-gauge-high';
      case ChartGroup.BandLevel:
        return chart.icon = 'fa-regular fa-sitemap';
      case ChartGroup.JobGrade:
        return chart.image = '/assets/img/svg/Rectangle43.svg';
      case ChartGroup.SurveyStatus:
        return chart.icon = 'fa-regular fa-wave-pulse';

      default:
        return '';
    }

  }

  export(){
     this.exporting = true;
     setTimeout(() => {
      this.exportDraft(this.language,true);
     }, 200);
  }
}
