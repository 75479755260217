import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  ChildrenAnimation,
  listAnimation,
} from 'src/app/shared/animations/allAnimations';
export interface DualSelectList {
  title?: string;
  list: DualSelectItem[];
  button?: string;
}
export interface DualSelectItem {
  key: string;
  icon: string;
  name: string;
}

@Component({
  selector: 'app-dual-dropdown-selection',
  templateUrl: './dual-dropdown-selection.component.html',
  styleUrls: ['./dual-dropdown-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DualDropdownSelectionComponent,
    },
  ],
  animations: [ChildrenAnimation, listAnimation],
})
export class DualDropdownSelectionComponent implements ControlValueAccessor {
  @Input() tours;
  constructor() {}
  selected;
  open: boolean = false;
  @Output('buttonClick') buttonClick = new EventEmitter();
  @Input() mode;
  @Input() data: Array<DualSelectList>;
  disable: boolean = false;
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(selected: any) {
    if (selected) {
      this.selected = selected;
    }
  }
  ngOnInit(): void {
    console.log('hellllllllllllo');
    console.log(this.tours);
    console.log(this.data);
  }
  handleSelection(option) {
    this.open = false;
    this.selected = option;

    this.onChange(option);
  }
  clear(e) {
    e.stopPropagation();
    this.selected = '';
    this.onChange(this.selected);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
}
