<div *ngIf="open" [@inOutAnimation] [className]="open ? 'modal is-active ' : 'modal'" >
<!-- <div class="modal is-active"> -->
    <div (click)="log('txt_answers')" class="modal-background"></div>
    <div class="modal-content review-survey  box modalGrey">
        <div class="level is-mobile stickyTop">
            <div class="level-item level-left">
                <i class="icon rounded mx-2 iconOrangeBack icon-50 text-2xl fal fa-plus"></i>
                <h3 class="is-size-5">{{'txttableofcontents'| translate:language:ModuleNames.Shared}}</h3>
            </div>
            <div class="level-item level-right">
                <a (click)="openChange.emit(false)">
                    <i class="icon squarish iconBlackBack cursor icon-50 text-2xl fal fa-times"></i>
                </a>
            </div>
        </div>
        <mat-divider></mat-divider>
   
        <div class="level mb-1">
            <div class="level-item level-right">
                <button class="py-2 mt-2 cursor" (click)="exportTable()" >
                    {{'txtDownloadExcel'| translate:language:ModuleNames.Shared}}
                </button>
            </div>
        </div>
        <table mat-table [dataSource]="dataSource"  style="width: 100%; padding-bottom: 2rem;" #tableList>
            <ng-container [matColumnDef]="column" *ngFor="let column of columns; let n = index">
              <th [ngClass]="n == 0 ? 'firstCell':'normalCell'" mat-header-cell *matHeaderCellDef> {{column}} </th>
              <td [ngClass]="n == 0 ? 'firstCell':'normalCell'" mat-cell *matCellDef="let element"> 
                    <div *ngIf="n == 0">
                        {{element}}
                    </div>
                    <div *ngIf="n > 0 && n < columns.length - 2 ">
                        <div *ngFor="let type of data.bar.series; let m = index">
                           <span *ngIf= "m + 1 == n">{{ toFixed(getPercentage(getGroup(element, data.drillDown), type.name), 1)}}</span>
                        </div>
                    </div>

                    <div *ngIf=" n == columns.length - 2">
                        {{getQuantityValue(element ,
                            data.drillDown)}}
                    </div>
                    <div *ngIf=" n == columns.length -1">
                        {{toFixed(percentageValue(element ,
                            data.drillDown), 1)}}%
                    </div>
                </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>
          </table>

        <mat-divider></mat-divider>

        <!-- <div class="level is-mobile mt-6 stickyBottom">
            <div class="level-item level-left">
                <a (click)="openChange.emit(false)" class="btn font-medium is-size-6 uppercase cancel-btn cursor">{{btn_cancel}}</a>
            </div>
            <div class="level-item level-right">
                <a (click)="exportTable()"  class="btn font-medium is-size-6 orange-grd  cursor uppercase">{{btnSave}}</a>
            </div>
        </div> -->

    </div>
</div>