import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SectionDto } from '@ex/wellbeing/survey-templates';
import { SurveyDto } from '@ex/wellbeing/surveys';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { NativeExportBaseComponent } from 'src/app/shared/native-export-base/native-export-base.component';
import { CDKPortalComponent, DATA_TOKEN, PortalDataResult } from 'src/app/shared/Theme/services/zen-cdk.service';

@Component({
  selector: 'app-wb-survey-preview',
  templateUrl: './wb-survey-preview.component.html',
  styleUrls: ['./wb-survey-preview.component.scss']
})
export class WbSurveyPreviewComponent extends NativeExportBaseComponent implements OnInit, CDKPortalComponent {
  constructor(@Inject(DATA_TOKEN) private data: any) {
    super();
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  isArabic: boolean = false;
  reportTitle: string;
  exportMargin: any;
  language: string;
  currentUser: any;
  companyName: string;
  zenithrSmallLogoUrl: string;
  zenithrLogo: string;
  Wellbeing;
  date = new Date();
  currentDate: string = `${new Date().getDate().toString()}/${new Date().getMonth() + 1}/${new Date().getFullYear().toString()}`;
  currentYear: string = `${new Date().getFullYear().toString()}`;
  previewSurvey: any[] = [];
  surveyName: any[];
  groupedPreviewSurvey: any[][] = [];
  displaySpinner: boolean = false;
  hasCustomDriver: boolean = false;
  essentialObject: EssentialObject
  surveyData: SurveyDto
  sections: SectionDto[];

  ngOnInit(): void {
    
    this.essentialObject = this.data.essentialObject;
    this.language = this.essentialObject.language;
    this.currentUser = this.essentialObject.user;
    this.surveyData = this.data.survey;
    this.sections = this.surveyData.survey.sections.filter(x => x.isSelected);
    this.companyName = this.extractNameLanguage(this.essentialObject.selectedEntity.name);
    this.reportTitle = this.extractNameLanguage(this.data.survey.survey.name) + ' ' + this.localize('txtSurvey', this.language, this.ModuleNames.Wellbeing);
    this.isArabic = this.language === 'AR';
    this.zenithrSmallLogoUrl = 'assets/img/logo.png';
    this.zenithrLogo = 'assets/img/logo.png';
  }

  ngOnDestroy() {
    this.isArabic = false;
  }

  extractNameLanguage(eG: any) {
    return this.LanguageService.extractNameLanguage(eG, this.language);
  }
  checkIfDriverExists(value) {
    let enName = value.find(e => e.key == 'EN').name;
    return this.Wellbeing.Drivers.includes(enName) || enName == 'GENERAL QUESTIONS';
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.exportDraft(this.language);
    }, 2000)
    setTimeout(() => {
      this.close.emit(true);
    }, 5000)
  }

}
