import { PerformanceScale } from './performance-scale';
import { EntityGroup } from './entity-group';
import { PerformanceRate } from './performance-rate';
import { BandLevel } from './band-level';
import { JobGrade } from './job-grade';
import { EngagementMetric } from './engagement-metric';

export class Entity {
  key: string;
  name: any[] = [];
  shortName: any[] = [];
  isRoot: boolean;
  isCompany: boolean;
  parentKey: string = '';
  email: string;
  RespondentCount: number = 0;
  companySize: string;
  headcount: number;
  Branches: any[] = [];
  headQuarter: string;
  industry: string;
  contactCode: string;
  contactNumber: string;
  tenantKey: string;
  companyEntityKey: string;
  entityGroupKey: string;
  nativeName: string;
  ContentLanguages: any[] = [];
  EntityGroups: EntityGroup[] = [];
  PerformanceRates: PerformanceRate[] = [];
  BandLevels: BandLevel[] = [];
  JobGrades: JobGrade[] = [];
  requiresNameValidation: boolean = false;
  requiresReportingValidation: boolean = false;
  usedByExit: boolean = false;
  usedByEngagement: boolean = false;
  usedByAssessment: boolean = false;
  DataIntegrated: boolean = false;
  lastUpdated: number;
  logoURL: string;
  website: string;
  metrics?: EngagementMetric[];


  //New Fields
  exitQuestionnaireKey: string;
  isActive: boolean = true;
}
