import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { CDKPortalComponent, DATA_TOKEN } from '@ex/module/shared/Theme/services/zen-cdk.service';
import { ChartColorsGenerationService } from '@ex/module/survey-x/data-access/services/chart-colors-generation.service';
export enum ChartColorType {
  Solid = 'Solid',
  Dynamic = 'Dynamic',
  Linear = 'Linear',
  LinearPositive = 'LinearPositive',
  LinearNegative = 'LinearNegative',
}
@Component({
  selector: 'app-chart-colors-assign',
  templateUrl: './chart-colors-assign.component.html',
  styleUrls: ['./chart-colors-assign.component.scss'],
  providers: [ChartColorsGenerationService]
})
export class ChartColorsAssignComponent extends BaseComponent implements OnInit, CDKPortalComponent {
  ChartColorType = ChartColorType;
  language: string = 'EN';
  chartColors: { color: string, dynamicColors: any[], linearColors: any[], colorType:ChartColorType, };
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();
  constructor(@Inject(DATA_TOKEN) public data: { language: string, colors: { color: string, colors: any[] } }, private chartColorsGenerationService: ChartColorsGenerationService) {
    super();
  }

  ngOnInit(): void {
    this.chartColors = JSON.parse(JSON.stringify(this.data.colors));
    this.chartColors.dynamicColors = this.chartColors.dynamicColors.map(x => {
      return { color: x }
    })
    this.language = this.data.language;


  }

  applyChanges() {
      this.chartColors.dynamicColors = this.chartColors.dynamicColors.map(x => x.color);

    this.results.emit(this.chartColors)
  }

  get linearColors() {
    return this.chartColorsGenerationService.generateColorsByNumberAndHex(this.chartColors.linearColors[0], this.chartColors.linearColors[1], 10)

  }
  get negativeColors() {
    return this.chartColorsGenerationService.generateNegativeColorsByHex(this.chartColors.linearColors[0], 10)
  }
  get positiveColors() {
    return this.chartColorsGenerationService.generatePositiveColorsByHex(this.chartColors.linearColors[1], 10)
  }

}
