import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DrillDownInformation } from '../../core/data-models/drill-down-information';
import { LanguageService } from '../../core/services/language.service';
import { ModuleName } from '../enums/ModuleName';

@Component({
  selector: 'app-drill-down-dialog',
  templateUrl: './drill-down-dialog.component.html',
  styleUrls: ['./drill-down-dialog.component.scss']
})
export class DrillDownDialogComponent implements OnInit {

  drillDownInfo: DrillDownInformation;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DrillDownDialogComponent>, private languageService: LanguageService) { }

  language;
  // btn_close: string;
  ModuleNames=ModuleName
  ngOnInit() {
    this.drillDownInfo = this.data.data;
    this.language = this.data.language;
    // this.btn_close = this.languageService.getUILanguage('btn_close', this.language);
  }

}
