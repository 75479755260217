import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Leaver } from '../data-models/leaver';
import { LeaverReference } from '../data-models/leaver-reference';
import { Entity } from '../data-models/Entity';
import { map, take } from 'rxjs/operators';
import { LeaverQuestionnaire } from '../data-models/leaver-questionnaire';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class LeaverService {
  sEndpoint: string = 'https://apis.zenithr.net/api/';//'http://localhost:52619/api/';https://apis.zenithr.net/api/
  fbFunctionURL: string = '';

  constructor(private db: AngularFireDatabase, public afAuth: AngularFireAuth, private http: HttpClient, private globals: GlobalsService) { 
    this.sEndpoint = this.globals.sEndpoint;
    this.fbFunctionURL = this.globals.fbFunctionURL;

  }


  updateLeaver(request) {
    return this.http.post(this.sEndpoint + 'Leaver/UpdateLeaver', request);
  }

  fetchEntityLeaversTest(tenantKey: string, companyKey: string){
    return this.db.list<Leaver>('/TenantData/' + tenantKey + '/LeaversList/' + companyKey).snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      ), take(1)
    );
  }


  updateEntityLeaver(leaver: Leaver) {
    return this.db.object('/TenantData/' + leaver.tenantKey + '/LeaversList/' + leaver.companyKey + '/' + leaver.key).update(leaver);
  }

  updateLeaverReference(leaverReference: LeaverReference) {
    return this.db.object('/LeaverReferences/' + leaverReference.key).update(leaverReference);
  }

  fetchEntityLeavers(selectedEntity: Entity){
    return this.db.list<Leaver>('/TenantData/' + selectedEntity.tenantKey + '/LeaversList/' + selectedEntity.key).snapshotChanges()
    .pipe(
      map(actions =>
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      )
    );
  }

  fetchLeaverReference(referenceKey: string) {
    return this.db.object<LeaverReference>('/LeaverReferences/' + referenceKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }

  fetchLeaver(tenantKey: string, entityKey: string, referenceKey: string) {
    return this.db.object<Leaver>('/TenantData/' + tenantKey + '/LeaversList/' + entityKey + '/' + referenceKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }

  updateLeaverQuestionnaire(leaverQuestionnaire: LeaverQuestionnaire){
    return this.db.object('/TenantData/' + leaverQuestionnaire.tenantKey + '/LeaverQuestionnaire/' +
    leaverQuestionnaire.companyKey + '/' + leaverQuestionnaire.key).update(leaverQuestionnaire);
  }


  fetchLeaverQuestionnaire(leaver: Leaver) {
    return this.db.object<LeaverQuestionnaire>('/TenantData/' + leaver.tenantKey + '/LeaverQuestionnaire/' +
    leaver.companyKey + '/' + leaver.key).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }

  checkBalanceAndAddLeaver(request) {
    return this.http.post(this.fbFunctionURL + 'createNewLeaver',  request );
  }

}
