import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { LogService } from './log.service';
import { take, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Leaver } from '../data-models/leaver';
import { ThomasCandidate } from '../data-models/thomas-candidate';
import { GlobalsService } from './globals.service';
import { Entity } from '../data-models/Entity';


@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  thomasAuthorizationURL: string = 'https://identity.thomasinternational.net/connect/token';
  thomasAssessmentOrder: string = 'https://partner.thomasinternational.net/hr/v1/%CLIENTID%/AssessmentOrders/'
  sEndpoint: string = 'https://apis.zenithr.net/api/';//'http://localhost:52619/api/';

  constructor(private db: AngularFireDatabase, private logService: LogService, private http: HttpClient, private globals: GlobalsService) {
    this.sEndpoint = this.globals.sEndpoint;
  }


  fetchIntegrations(tenantKey) {
    return this.db.list<any>('/Integrations/' + tenantKey).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  createThomasIntegration(tenantKey, clientID, clientSecret) {
    let thomasNode = { key: 'THOMAS', thomasClientID: clientID, thomasClientSecret: clientSecret, initialIntegration: true };
    return this.db.object('/Integrations/' + tenantKey + '/' + thomasNode.key).update(thomasNode);
  }



  updateThomasIntegrationTable(request) {
    return this.http.post('http://localhost:5000/exitanalysis-a6325/us-central1/UpdateThomasIntegrationTable', request);
  }

  authorize(clientID: string, clientSecret: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(clientID + ':' + clientSecret),
      }),

    };
    let body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    //
    return this.http.post<any>(this.thomasAuthorizationURL, body.toString(), httpOptions);

  }

  createNewAssessment(token: string, clientID: string, assessmentOrder: any, candidate: ThomasCandidate) {

    return this.http.post<any>('http://localhost:5000/exitanalysis-a6325/us-central1/createThomasAssessment',
      { assessmentOrder: assessmentOrder, token: token, url: this.thomasAssessmentOrder.replace('%CLIENTID%', clientID), candidate: candidate });
  }



  downloadIntegrationExcelFile(request: any) {
    return this.http.post(this.sEndpoint + 'Integration/DownloadExcelIntgration', request);
  }

  integrate(request: any) {
    return this.http.post(this.sEndpoint + 'Integration/IntegrateExcel', request);
  }



}
