<zen-main-container>
  <sidebar-container>
    <back-button [routerLink]="['/survey-x/form-library/']">
      <i [ngClass]="(language | language )?'fa-arrow-right':'fa-arrow-left'" class="fa-regular"></i>
      <span> {{"txtBack"| translate:language:ModuleNames.SurveyX}}</span>
    </back-button>
    <section-sidebar>

      <menu-button *ngFor="let button of sideBarNavigationTabs" (click)="tabClick(button)" [enabled]="button.enabled"
        [ngClass]="{'pointer-events-none opacity-50': !button.enabled}"
        [icon]="button.selected ? 'text-exp ' + button.icon: 'opacity-50 '+ button.icon" [active]="button.selected">
        {{ button.title | translate:language:ModuleNames.SurveyX }}
      </menu-button>
    </section-sidebar>
  </sidebar-container>
  <content-container class="flex flex-col items-stretch w-full">
    <div class="flex flex-col items-stretch h-28 z-10">
      <section-head>
        <ng-container left>
          <div class="flex flex-col gap-1">
            <h5 class="text-zen-lite text-xxs font-medium">{{ form?.title | sjsTranslate:language}}</h5>
            <h4 [@first] class="text-zen font-semibold text-2xl">{{
              'txtAnalysisSection'|translate:language:ModuleNames.SurveyX}} - {{ activeTab?.title |
              translate:language:ModuleNames.SurveyX }}
            </h4>
          </div>
        </ng-container>
        <ng-container right>
          <div class="flex flex-row justify-end items-center gap-5">
            <a [@child4] (click)="openTree()" *ngIf="selectedChildEntity?.entity.name"
              class="btn cancel uppercase px-5 leading-12 rounded-md">{{'txtViewBy'|
              translate:language:ModuleNames.SurveyX}}: {{ selectedChildEntity?.entity.name |
              extract:language }}</a>

            <a (click)="export()"
              class="btn exp uppercase px-5 leading-12 rounded-md flex flex-row gap-2 items-center">
              <i class="fa-regular fa-file-alt"></i>
              <span>

                <ng-container [ngSwitch]="activeTab.id">

                  <!-- Comments -->
                  <ng-container *ngSwitchCase="3">
                    {{'txt_download_comments'| translate:language:ModuleNames.SurveyX}}
                  </ng-container>

                  <!-- Feedback -->
                  <ng-container *ngSwitchCase="4">
                    {{'txt_download_feedback'| translate:language:ModuleNames.SurveyX}}
                  </ng-container>

                  <!-- Report -->
                  <ng-container *ngSwitchDefault>
                    {{'btnExport'| translate:language:ModuleNames.SurveyX}}
                  </ng-container>

                </ng-container>

              </span>
            </a>

          </div>
        </ng-container>
      </section-head>
    </div>


    <div class="flex-auto custom-container pl-8 pr-12 py-12 pt144px print-area">





      <div *ngIf="overall" class="flex space-x-4 test-1">
        <div class="flex flex-col flex-wrap w-full bg-white items-center justify-center rounded-md main-box mb-8 p-6">
          <h2 class="text-2xl text-zen-primarydark"><i [ngClass]="language == 'AR'? 'ml-2': 'mr-2' "
              class="fa-regular fa-reply text-exp mr-2"></i>{{ overall?.responseRate | number:'1.0-1' ?? 0 }}%</h2>
          <p class="uppercase text-xs tracking-wide text-zen-primarylight">{{"txtResponseRate" |
            translate:language:ModuleNames.SurveyX}}</p>
        </div>
        <div class="flex flex-col flex-wrap w-full bg-white items-center justify-center rounded-md main-box mb-8 p-6">
          <h2 class="text-2xl text-zen-primarydark"><i [ngClass]="language == 'AR'? 'ml-2': 'mr-2' "
              class="fa-regular fa-check text-exp mr-2"></i>{{ overall?.completedCount ?? 0 }}</h2>
          <p class="uppercase text-xs tracking-wide text-zen-primarylight">{{"txtCompleted" |
            translate:language:ModuleNames.SurveyX}} ({{"txtoutof" | translate:language:ModuleNames.SurveyX}}
            {{ overall?.totalCount }})
          </p>
        </div>
        <div class="flex flex-col flex-wrap w-full bg-white items-center justify-center rounded-md main-box mb-8 p-6">
          <h2 class="text-2xl text-zen-primarydark"><i [ngClass]="language == 'AR'? 'ml-2': 'mr-2' "
              class="fa-regular fa-comment-lines text-exp mr-2"></i>{{ overall?.totalComments ?? 0 }}</h2>
          <p class="uppercase text-xs tracking-wide text-zen-primarylight">{{"txtComments"|
            translate:language:ModuleNames.SurveyX}}</p>
        </div>
      </div>

      <!-- Main Content Area -->
      <div class="flex flex-col items-stretch py-10 overflow-x-hidden" *ngIf="!viewDisabled">
        <div class="flex flex-col justify-between items-stretch gap-5">
          <sx-filters *ngIf="filterOptions.length>0" [language]="language" [filterOptions]="filterOptions"
            (filtersItems)="getFilterItems($event)" (displayedFilters)="getDisplayedFilters($event)"></sx-filters>
          <ng-template #tabContentHolder></ng-template>
        </div>
      </div>
      <EmptySection *ngIf="viewDisabled"></EmptySection>

      <!-- <ng-container *ngIf="activeTabs === 'question' && qAnalysis && completedRespondents">
        <question-dashboard (onOpenSpecialQuestion)="onOpenSpecialQuestion($event)"
          (onOpenIndividualRatingResponsesModal)="onOpenIndividualRatingResponsesModal($event)"
          (onOpenIndividualMatrixResponsesModal)="onOpenIndividualMatrixResponsesModal($event)"
          (onOpenIndividualRankingResponsesModal)="onOpenIndividualRankingResponsesModal($event)"
          (onOpenIndividualResponsesModal)="onOpenIndividualResponsesModal($event)" [questionAnalysis]="qAnalysis"
          [formLocale]="formLocale" [systemLocale]="systemLocale"
          [totalCompletedRespondents]="totalCompletedRespondents"></question-dashboard>
      </ng-container>
      <ng-container *ngIf="activeTabs === 'respondent'">
        <respondent-dashboard (onOpenIndividualsForCategoryModal)="onOpenIndividualsForCategoryModal($event)"
          [respondentAnalysis]="rCAnalysis"></respondent-dashboard>
      </ng-container>
      <ng-container *ngIf="activeTabs === 'comment' && qAnalysis">
        <comment-dashboard [commentAnalysis]="qAnalysis" [formLocale]="formLocale"
          [systemLocale]="systemLocale"></comment-dashboard>
      </ng-container>
      <ng-container *ngIf="activeTabs === 'feedback'">
        <respondent-feedback-dashboard [formLocale]="formLocale"
          [systemLocale]="systemLocale"></respondent-feedback-dashboard>
      </ng-container> -->
    </div>

  </content-container>
</zen-main-container>



<app-special-question-answers-view [dir]="direction" *ngIf="specialQuestion.open && specialQuestion.selectedQuestion"
  [(open)]="specialQuestion.open" [selectedQuestion]="specialQuestion.selectedQuestion"
  [respondents]="specialQuestion.respondents" [selectedformId]="formId">
</app-special-question-answers-view>

<individual-responses-modal [dir]="direction" *ngIf="individualsForCategory.open && individualsForCategory.category"
  [(open)]="individualsForCategory.open" [category]="individualsForCategory.category"
  [chartType]="individualsForCategory.chartType" [chartName]="individualsForCategory.chartName">
</individual-responses-modal>

<individual-responses-modal [dir]="direction" *ngIf="individualResponses.open && individualResponses.selectedQuestion"
  [(open)]="individualResponses.open" [selectedQuestion]="individualResponses.selectedQuestion"
  [selectedChoice]="individualResponses.selectedChoice" [respondents]="specialQuestion.respondents"
  [selectedQuestionNumber]="individualResponses.selectedQuestionNumber">
</individual-responses-modal>

<individual-responses-modal [dir]="direction"
  *ngIf="individualRatingResponses.open && individualRatingResponses.selectedQuestion"
  [(open)]="individualRatingResponses.open" [selectedQuestion]="individualRatingResponses.selectedQuestion"
  [selectedChoice]="individualRatingResponses.selectedChoice" [respondents]="specialQuestion.respondents"
  [selectedQuestionNumber]="individualRatingResponses.selectedQuestionNumber"
  [selectedLabel]="individualRatingResponses.selectedLabel">
</individual-responses-modal>

<individual-responses-modal [dir]="direction"
  *ngIf="individualRankingResponses.open && individualRankingResponses.selectedQuestion"
  [(open)]="individualRankingResponses.open" [selectedQuestion]="individualRankingResponses.selectedQuestion"
  [selectedChoice]="individualRankingResponses.selectedChoice" [respondents]="specialQuestion.respondents"
  [selectedQuestionNumber]="individualRankingResponses.selectedQuestionNumber"
  [selectedRank]="individualRankingResponses.selectedRank">
</individual-responses-modal>

<individual-responses-modal [dir]="direction"
  *ngIf="individualMatrixResponses.open && individualMatrixResponses.selectedQuestion"
  [(open)]="individualMatrixResponses.open" [selectedQuestion]="individualMatrixResponses.selectedQuestion"
  [respondents]="specialQuestion.respondents"
  [selectedQuestionNumber]="individualMatrixResponses.selectedQuestionNumber"
  [selectedCol]="individualMatrixResponses.selectedCol" [selectedRow]="individualMatrixResponses.selectedRow">
</individual-responses-modal>


<app-form-analysis-pdf [dir]="direction" *ngIf="showPrint" [(open)]="showPrint" (close)="showPrint = false;"
  [pdfData]="pdfData"></app-form-analysis-pdf>
