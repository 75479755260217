import { Component, Input, OnInit } from '@angular/core';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';

@Component({
  selector: 'single-answer-out-ten',
  templateUrl: './single-answer-out-ten.component.html',
  styleUrls: ['./single-answer-out-ten.component.scss'],
  providers: [TranslatePipe]
})
export class SingleAnswerOutTenComponent implements OnInit {
  constructor(private translationPipe: TranslatePipe) { }
  lgCount;
  moduleName = ModuleName
  steps: any[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  @Input() mainLegends;
  @Input() legendDetail: boolean = true;
  @Input() question;
  @Input() language = 'EN';
  @Input() legends = [
    { steps: 7, name: 'txtUnLikely' },
    { steps: 2, name: 'txtNeutralLikely' },
    { steps: 2, name: 'txtLikely' },
  ];
  value = 0;

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 10) + 'k';
    }

    return value;
  }
  ngOnInit(): void { }
  onInputChange(event: any) {
    this.value = event.value;
  }
}
