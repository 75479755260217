<div [@inOutAnimation] [ngClass]="(language | language )?'rtl':''"
  class="w-screen h-screen bg-zen bg-opacity-50 flex flex-row items-center justify-center fixed top-0 left-0 z-50 py-10">
  <div class="max-w-4xl w-full flex flex-col bg-white bg-opacity-95 py-10 rounded max-h-screen-90">
    <div class="flex flex-row items-center justify-start gap-2 px-10">
      <i [ngClass]="{'fas fa-exclamation bg-red-400':type == 'warning', 'fal fa-times bg-zen-red':type == 'error', 'fal fa-question bg-zen-green':type == 'success', 'fal fa-info-circle bg-exp ':type == 'info-with-check' || type == 'info'}"
        class="text-white shadow-xl rounded-full h-10 w-10 text-xl text-center leading-10"></i>
      <h4 class="text-2xl font-bold text-zen"> {{(type == 'warning'?"txtwarning":'' + type == 'error'?"txterror":''
        + type == 'success'?"txtsuccess":'' + type == 'info'|| type == 'info-with-check' ?"txtalert":'')
        |translate:language:ModuleNames.Shared}}</h4>
    </div>
    <div class="flex flex-col gap-1 px-10 py-10 innerListScroll  min-w-80">
      <div *ngIf="type == 'info' || type == 'info-with-check'" class="level">
        <h6 class="is-size-6 text-center ">{{ message }}</h6>
      </div>
      <h6 *ngIf="type != 'info' && type != 'info-with-check' " class="text-zen">{{ message }}</h6>

      <mat-checkbox *ngIf="type == 'info-with-check'" (change)="checkboxChanged($event)">
        <h5 class="text-sm px-1 m-0"> {{checkboxText}}</h5>
      </mat-checkbox>

    </div>
    <div class="flex flex-row justify-between items-center px-10 gap-10">
      <div >
        <a *ngIf="type != 'info-with-check' && cancel != ''" [mat-dialog-close]="true"
          class="btn cancel  text-center  rounded-md px-8 py-4 uppercase text-lg font-medium">{{cancel}}</a>
      </div>



      <div class="" [ngClass]="{'':type != 'info-with-check'}">
        <button *ngIf="type != 'info-with-check'"
          [ngClass]="type == 'warning' || type == 'info'? 'orange-grd': 'sheer-blue'"
          class="btn exp  rounded-md px-8 py-4 uppercase text-lg font-medium" type="button"
          (click)="dialogRef.close('YES')">{{action}}</button>
        <button *ngIf="type == 'info-with-check'" class="btn exp  rounded-md px-8 py-4 uppercase text-lg font-medium"
          type="button" (click)="dialogRef.close({ reply: 'YES', isChecked: checkboxChecked})">{{action}}</button>
      </div>

    </div>
  </div>
</div>
