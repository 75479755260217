import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'section-sidebar',
  templateUrl: './section-sidebar.component.html',
  styleUrls: ['./section-sidebar.component.scss']
})
export class SectionSidebarComponent implements OnInit {

  constructor() { }
  @Input() title;
  ngOnInit(): void {
  }

}
