<div class="flex flex-row gap-4 pb-5">
  <div class="flex flex-row items-start w-1/2 gap-1.5">
    <i class="fa-regular fa-circle-question text-exp text-sm"></i>
    <h6 class="text-zen text-xs font-normal">{{name}}</h6>
  </div>
  <div class="flex flex-row items-center w-1/2 gap-4">


    <span class="leading-6 px-4 text-xs text-zen bg-zen bg-opacity-5 font-medium">{{(score | number:'1.1-1') + '%'}}</span>
    <div class="flex flex-row gap-px w-full">
      <div *ngFor="let answer of answers;let i = index" [style.width.%]="answer.percentage"
        class="flex flex-col items-stretch gap-px chunk">
        <span [ngClass]="answer.color" class="h-2  rounded-sm"></span>
        <p class="text-center text-3xs font-medium text-zen-lite">
          {{answer.percentage | number:'1.0-0'}} {{
          answer.percentage?'%':''}}</p>
      </div>

    </div>
  </div>
</div>
