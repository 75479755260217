import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Engagement } from '../data-models/engagement';
import { map } from 'rxjs/operators';
import { EngagementRespondent } from '../data-models/engagement-respondent';
import { EngagementMetric } from '../data-models/engagement-metric';
import { Respondent } from '../data-models/respondent';
import { Questionnaire } from '../data-models/questionnaire';
import { take } from 'rxjs/operators';
import { RespondentReference } from '../data-models/respondent-reference';
import { EngagementEmailTemplate } from '../data-models/engagement-email-template';
import { EngagementReference } from '../data-models/engagement-reference';
import { LeaverAnalysis } from '../data-models/leaver-analysis';
import { HttpClient } from '@angular/common/http';
import { TenantEngagementData } from '../data-models/tenant-engagement-reference';
import { EngagementAnalysis } from '../data-models/engagement-analysis';
import { EngagementAnalysisTemplate } from '../data-models/engagement-analysis-template';
import { EngagementAnalysisQuestions } from '../data-models/engagement-analysis-questions';
import { EngagementAnswer } from '../data-models/engagement-answer';
import { EngagementComment } from '../data-models/engagement-comment';
import { ResListRespondent } from '../data-models/res-list-respondent';
import { GlobalsService } from './globals.service';
import { BehaviorSubject } from 'rxjs';
import { Hierarchy } from '../data-models/hierarchy';

@Injectable({
  providedIn: 'root'
})
export class EngagementService {

  sEndpoint: string = 'https://apis.zenithr.net/api/';//'https://apis.zenithr.net/api/';//
  sDevEndpoint: string = 'http://localhost:52619/api/';
  fbFunctionURL: string = '';

  constructor(private db: AngularFireDatabase, public afAuth: AngularFireAuth, private http: HttpClient, private globals: GlobalsService) {
    this.sEndpoint = this.globals.sEndpoint;
    this.fbFunctionURL = this.globals.fbFunctionURL;

  }

  updateEngagement(tenantKey: string, companyKey: string, engagement: Engagement) {
    return this.db.object('/TenantData/' + tenantKey + '/Engagements/' + companyKey + '/' + engagement.key).update(engagement);
  }


  fetchEngagements(tenantKey: string, companyKey: string) {
    return this.db.list<Engagement>('/TenantData/' + tenantKey + '/Engagements/' + companyKey + '/', ref => ref.orderByChild('isDeleted').equalTo(false)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }



  fetchEngagementsDraft(tenantKey: string, companyKey: string) {
    return this.db.list<Engagement>('/TenantData/' + tenantKey + '/Engagements/' + companyKey + '/', ref => ref.orderByChild('status').equalTo('DRAFT')).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchEngagementsOnce(tenantKey: string, companyKey: string) {
    return this.db.list<Engagement>('/TenantData/' + tenantKey + '/Engagements/' + companyKey + '/', ref => ref.orderByChild('isDeleted').equalTo(false)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }
  fetchEngagement(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object<Engagement>('/TenantData/' + tenantKey + '/Engagements/' + companyKey + '/' + engagementKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }

  fetchEngagementRespondentList(tenantKey: string, companyKey: string, engagementKey: string, filterByMetrics?: EngagementMetric[]) {
    let request = {
      SurveyType: 'ENG',
      TenantKey: tenantKey,
      CompanyKey: companyKey,
      SurveyKey: engagementKey,
      FilterByMetrics: filterByMetrics
    }
    return this.http.post(this.sEndpoint + 'Engagement/FetchRespondentList', request);
  }

  createEngagementResList(request) {
    return this.http.post(this.sEndpoint + 'Engagement/CreateEngagementResList', request);
  }

  updateRespondentDetails(request) {
    return this.http.post(this.sEndpoint + 'Engagement/UpdateRespondent', request);
  }

  fetchEngagementAnswersSDB(request) {
    return this.http.post(this.sEndpoint + 'Engagement/ClosedEngagement', request);
  }

  getAppliedEntity(request) {
    return this.http.post(this.sEndpoint + 'Engagement/GetAppliedEntity', request);
  }

  GetEngagementCommentsForSection(request) {
    return this.http.post(this.sEndpoint + 'Engagement/GetEngagementCommentsForSection', request);
  }

  GetAllEngagementCommentsForSection(request) {
    return this.http.post(this.sEndpoint + 'Engagement/GetAllEngagementCommentsForSection', request);
  }

  GetEngagementCommentsForQuestion(request) {
    return this.http.post(this.sEndpoint + 'Engagement/GetEngagementCommentsForQuestion', request);
  }

  populateResponseCriteriaChart(request) {
    return this.http.post(this.sEndpoint + 'Engagement/PopulateResponseCriteriaChart', request);
  }

  populateResponseCriteria(request) {
    return this.http.post(this.sEndpoint + 'Engagement/PopulateResponseCriteria', request);
  }

  populateResponseCharts(request) {
    return this.http.post(this.sEndpoint + 'Engagement/PopulateResponseCharts', request);
  }

  fetchPreviousScores(request) {
    return this.http.post(this.sEndpoint + 'Engagement/GetPreviousData', request);
  }

  getTreeList(request) {
    return this.http.post(this.sEndpoint + 'Engagement/GetEntityTreeList', request);
  }

  fetchIndexAndQuestionAnalysis(request) {
    return this.http.post(this.sEndpoint + 'Engagement/IndexAndQuestionAnalysis', request);
  }

  insertSurveyAnswers(request) {
    return this.http.post(this.sEndpoint + 'Engagement/SubmitAnswers', request);
  }

  updateFinalSurvey(request) {
    return this.http.post(this.sEndpoint + 'UpdateSurveyDetails', request);
  }

  sendEngagementReminders(request) {
    return this.http.post(this.sEndpoint + 'Engagement/SendEngagementReminders', request);
  }
  updateCommentSentiment(request) {
    return this.http.post(this.sEndpoint + 'Engagement/UpdateCommentSentiment', request);
  }
  fetchQuestionAnalysis(request) {
    return this.http.post(this.sEndpoint + 'Engagement/FetchQuestionAISentiment', request);
  }
  fetchEngagementTopics(request) {
    return this.http.post(this.sEndpoint + 'Engagement/FetchTopics', request);
  }
  fetchRespondentsFromSDB(request) {
    return this.http.post(this.fbFunctionURL + 'fetchRespondentsFromDB', request);
  }

  updateEngagementRespondentInDB(request) {
    return this.http.put(this.fbFunctionURL + 'updateEngagementRespondentInDB', request);
  }

  fetchEngagementRespondentListOnce(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.list<EngagementRespondent>('/TenantData/' + tenantKey + '/EngagementDetails/' + companyKey + '/' + engagementKey + '/EngagementRespondentList/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchEngagementRespondent(tenantKey: string, companyKey: string, engagementKey: string, respondentKey: string) {
    return this.db.object<EngagementRespondent>('/TenantData/' + tenantKey + '/EngagementDetails/' + companyKey + '/' + engagementKey + '/EngagementRespondentList/' + respondentKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }

  fetchRespondents(tenantKey: string, companyKey: string) {
    return this.db.list<Respondent>('/TenantData/' + tenantKey + '/RespondentList/' + companyKey + '/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }

  removeEngagementRespondent(tenantKey: string, companyKey: string, engagementKey: string, respondentKey: string) {
    this.db.object<any>('/TenantData/' + tenantKey + '/EngagementDetails/' + companyKey + '/' + engagementKey + '/EngagementRespondentList/' + respondentKey + '/status').set('DELETED');
    return this.db.object<any>('/TenantData/' + tenantKey + '/EngagementDetails/' + companyKey + '/' + engagementKey + '/EngagementRespondentList/' + respondentKey + '/actualStatus').set('DELETED');
  }

  deleteEngagementRespondent(tenantKey: string, companyKey: string, engagementKey: string, respondentKey: string) {
    return this.db.object<any>('/TenantData/' + tenantKey + '/EngagementDetails/' + companyKey + '/' + engagementKey + '/EngagementRespondentList/' + respondentKey).remove();
  }

  fetchEngagementMetrics(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.list<EngagementMetric>('/TenantData/' + tenantKey + '/EngagementDetails/' + companyKey + '/' + engagementKey + '/EngagementRespondentMetrics/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }


  fetchSurveySnapshot(request) {
    return this.http.post(this.sEndpoint + 'Engagement/FetchSurveySnapshot', request);
  }



  fetchEngagementComparisonData(request) {
    return this.http.post(this.fbFunctionURL + 'fetchTenantEngagements', request);

  }

  updateClosedEngagementData(tenantKey: string, companyKey: string, companyName: any[], engagement: Engagement, engagementAnalysis: EngagementAnalysis) {
    let tenantEngagementData = new TenantEngagementData;
    tenantEngagementData.key = engagement.key;
    tenantEngagementData.title = engagement.title;
    tenantEngagementData.description = engagement.description;
    tenantEngagementData.unixStart = engagement.unixStart;
    tenantEngagementData.companyKey = companyKey;
    tenantEngagementData.workplaceEngagementScore = engagementAnalysis.workPlaceEngagementScore;
    tenantEngagementData.companyName = companyName;
    for (let i = 0; i < engagementAnalysis.engagementDrivers.length; i++) {
      let engDataDriver = { key: null, name: null, score: null };
      let engDriver = engagementAnalysis.engagementDrivers[i];
      let driverName = engDriver.name.find(n => n.key == 'EN');
      engDataDriver.key = driverName.name;
      engDataDriver.name = engDriver.name;
      engDataDriver.score = engDriver.meanScore;
      tenantEngagementData.engagementDrivers.push(engDataDriver);
    }
    tenantEngagementData.lowlyEngaged = engagementAnalysis.lowlyEngaged;
    tenantEngagementData.moderatlyEngaged = engagementAnalysis.moderatlyEngaged;
    tenantEngagementData.highlyEngaged = engagementAnalysis.highlyEngaged;
    return this.db.object('/TenantData/' + tenantKey + '/EngagementDataAnalysis/' + tenantEngagementData.key).update(tenantEngagementData);
  }

  fetchPredefinedMetrics(tenantKey: string, companyKey: string, respondentKey: string) {
    return this.db.list<any>('/TenantData/' + tenantKey + '/RespondentDetails/' + companyKey + '/' + respondentKey).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchRespondentFromPool(tenantKey: string, companyKey: string, respondentEmail: string) {
    return this.db.list<Respondent>('/TenantData/' + tenantKey + '/RespondentList/' + companyKey + '/', ref => ref.orderByChild('email').equalTo(respondentEmail)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchPoolRespondent(request) {
    return this.http.post(this.sEndpoint + 'Respondent/FetchPoolRespondent', request);

  }


  updatePredefinedMetrics(tenantKey: string, companyKey: string, respondentKey: string, metrics: any[]) {
    return this.db.object<any>('/TenantData/' + tenantKey + '/RespondentDetails/' + companyKey + '/' + respondentKey).set(metrics);
  }

  updateEngagementRespondent(tenantKey: string, companyKey: string, engagementKey: string, engagementRespondents: EngagementRespondent[], userKey?, sendEmail: boolean = false, companyName?: any[], engagement?: Engagement) {
    for (let i = 0; i < engagementRespondents.length; i++) {
      let engagementRespondent: EngagementRespondent = engagementRespondents[i];
      if (!engagementRespondent.key) {
        engagementRespondent.key = this.db.createPushId();
        engagementRespondent.referenceKey = this.db.createPushId();
        engagementRespondent.companyKey = companyKey;
        engagementRespondent.tenantKey = tenantKey;
      }
      engagementRespondent.createdBy = 'SELF';
      engagementRespondent.referenceKey = engagementRespondent.referenceKey;
    }
    let request = {
      SurveyType: 'ENG',
      TenantKey: tenantKey,
      CompanyKey: companyKey,
      SurveyKey: engagementKey,
      respondents: engagementRespondents,
      sendEmail: sendEmail,
      CompanyName: companyName,
      surveyInfo: engagement
    }
    return this.http.post(this.sEndpoint + 'Respondent/UpdateSurveyRespondent', request);
  }

  updateWellbeingRespondent(tenantKey: string, companyKey: string, engagementKey: string, engagementRespondents: EngagementRespondent[], userKey?, sendEmail: boolean = false, companyName?: any[], engagement?: Engagement) {
    for (let i = 0; i < engagementRespondents.length; i++) {
      let engagementRespondent: EngagementRespondent = engagementRespondents[i];
      if (!engagementRespondent.key) {
        engagementRespondent.key = this.db.createPushId();
        engagementRespondent.referenceKey = this.db.createPushId();
        engagementRespondent.companyKey = companyKey;
        engagementRespondent.tenantKey = tenantKey;
      }
      engagementRespondent.createdBy = 'SELF';
      engagementRespondent.referenceKey = engagementRespondent.referenceKey;
    }
    let request = {
      SurveyType: 'WLB',
      TenantKey: tenantKey,
      CompanyKey: companyKey,
      SurveyKey: engagementKey,
      respondents: engagementRespondents,
      sendEmail: sendEmail,
      CompanyName: companyName,
      surveyInfo: engagement
    }
    return this.http.post(this.sEndpoint + 'Respondent/UpdateSurveyRespondent', request);
  }

  dummy(tenantKey: string, companyKey: string, engagementKey: string, engagementRespondent: EngagementRespondent) {
    if (!engagementRespondent.key) {
      engagementRespondent.key = this.db.createPushId();
      engagementRespondent.referenceKey = this.db.createPushId();
      engagementRespondent.companyKey = companyKey;
      engagementRespondent.tenantKey = tenantKey;
    }
    return this.db.object<EngagementRespondent>('/TenantData/' + tenantKey + '/EngagementDetails/' +
      companyKey + '/' + engagementKey + '/EngagementRespondentList/').set(engagementRespondent);
  }

  forceRemoveRespondents(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object<EngagementRespondent>('/TenantData/' + tenantKey + '/EngagementDetails/' +
      companyKey + '/' + engagementKey + '/EngagementRespondentList/').remove();
  }
  updateEngagementReference(respondentReference: RespondentReference) {
    return this.db.object('/EngagementReferences/' + respondentReference.key).update(respondentReference);
  }

  updateEngagementMetrics(tenantKey: string, companyKey: string, engagementKey: string, metrics: EngagementMetric[]) {
    for (let i = 0; i < metrics.length; i++) {
      let metric = metrics[i];
      if (!metric.key)
        metric.key = this.db.createPushId();
    }
    return this.db.object<EngagementMetric[]>('/TenantData/' + tenantKey + '/EngagementDetails/' +
      companyKey + '/' + engagementKey + '/EngagementRespondentMetrics/').set(metrics);
  }

  updateRespondentList(tenantKey: string, companyKey: string, respondent: Respondent) {
    return this.db.object<Respondent>('/TenantData/' + tenantKey + '/RespondentList/' + companyKey + '/' + respondent.key).update(respondent)
  }

  removeRespondentFromPool(tenantKey: string, companyKey: string, respondentKey: string) {
    return this.db.object<Respondent>('/TenantData/' + tenantKey + '/RespondentList/' + companyKey + '/' + respondentKey).remove();
  }

  fetchEngagementQuestionnaire() {
    return this.db.list<Questionnaire>('/Questionnaires/', ref => ref.orderByChild('isEngagement').equalTo(true)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }
  fetchEngagementQuestionnaireObject() {
    return this.http.post(this.fbFunctionURL + 'fetchQuestionnaire', { questionnaireType: 'ENGAGEMENT' });
  }

  bulkAddRespondents(request) {
    return this.http.post(this.fbFunctionURL + 'bulkAddRespondents', request);
  }
  bulkUpdateEngagementSimulationAnswers(request) {
    return this.http.post(this.fbFunctionURL + 'bulkUpdateEngagementSimulationAnswers', request);
  }

  openClosedEngagement(request) {
    return this.http.post(this.fbFunctionURL + 'openClosedEngagement', request);
  }

  updateEngagementRespondentSurvey(tenantKey: string, companyKey: string, engagementKey: string, analysis: LeaverAnalysis) {
    return this.db.object<any>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/' + analysis.key).update(analysis);
  }


  fetchEngagementTemplates(tenantKey: string, companyKey: string) {
    return this.db.list<EngagementEmailTemplate>('/TenantData/' + tenantKey + '/EngagementTemplates/' + companyKey + '/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }



  fetchEngagementReference(engagementReference: string) {
    return this.db.object<EngagementReference>('/EngagementReferences/' + engagementReference).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }
  fetchEngagementReferences(companyKey: string) {
    return this.db.list<EngagementReference>('/EngagementReferences/', ref => ref.orderByChild('companyKey').equalTo(companyKey)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }


  removeEngagement(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object<any>('/TenantData/' + tenantKey + '/Engagements/' + companyKey + '/' + engagementKey + '/isDeleted/').set(true);
  }

  removeEngagementRespondentList(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object('/TenantData/' + tenantKey + '/EngagementDetails/' + companyKey + '/' + engagementKey).remove();
  }

  removeEngagementRespondentAnalysis(tenantKey: string, companyKey: string,
    engagementKey: string, respondentKey: string) {
    return this.db.object<any>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/' + respondentKey).remove();
  }

  removeEngagementAnalysis(tenantKey: string,
    engagementKey: string) {
    return this.db.object<any>('/TenantData/' + tenantKey + '/EngagementDataAnalysis/' + engagementKey).remove();
  }

  removeEngagementReference(refKey: string) {
    return this.db.object<any>('/EngagementReferences/' + refKey).remove();
  }

  fetchEngagementReferencesByEngKey(engagementKey: string) {
    return this.db.list<any>('/EngagementReferences/', ref => ref.orderByChild('engagementKey').equalTo(engagementKey)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }



  fetchEngagementAnalysis(tenantKey: string, companyKey: string,
    engagementKey: string) {
    return this.db.list<LeaverAnalysis>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  // registerEngagementAnalysisTemplate() {
  //   return this.db.object<Respondent>('/TenantData/' + tenantKey + '/RespondentList/' + companyKey + '/' + respondent.key).update(respondent)
  // }

  checkBalanceAndCreditEngagement(request) {
    return this.http.post(this.fbFunctionURL + 'creditEngagement', request);
  }


  fetchBenchmark(request) {
    return this.http.post(this.fbFunctionURL + 'fetchAllEngagementBenchmark', request);
  }

  insertEngagementAnalysisTemplate(engagementAnalysisTemplate: EngagementAnalysisTemplate) {
    return this.db.object<any>('/EngagementAnalysis/' + engagementAnalysisTemplate.tenantKey + '/' + engagementAnalysisTemplate.companyKey + '/' + engagementAnalysisTemplate.engagementKey).update(engagementAnalysisTemplate);
  }

  insertEngagementTemplateQuestions(tenantKey: string, companyKey: string, engagementKey: string, engagementAnalysisQuestion: EngagementAnalysisQuestions) {
    return this.db.object<any>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/Questions/' + engagementAnalysisQuestion.key).update(engagementAnalysisQuestion);
  }

  forceSetEngagementTemplate(engagementAnalysisTemplate: EngagementAnalysisTemplate) {
    let request = { engagementTemplate: engagementAnalysisTemplate };
    return this.http.post('http://localhost:5001/exitanalysis-a6325/us-central1/forceSetEngagementTemplate', request);
  }


  migrateAnswers(rAnalysis: any, engagementKey) {
    let request = { rAnalysis: rAnalysis, engagementKey: engagementKey };
    return this.http.post('http://localhost:5001/exitanalysis-a6325/us-central1/migrateAnswers', request);
  }

  engagementGoLive(request) {
    return this.http.post(this.fbFunctionURL + 'engagementGoLive', request);
  }


  insertEngagementTemplate(request) {
    return this.http.post(this.sEndpoint + 'SurveyDetails/SurveyGoLive', request);
  }

  forceDeleteAnswers(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object<any>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/Answers/').remove();
  }
  forceDeleteComments(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object<any>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/Comments/').remove();
  }

  insertEngagementAnswers(tenantKey: string, companyKey: string, engagementKey: string, answerKey: string, engagementAnswer: EngagementAnswer) {
    return this.db.object<any>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/Answers/' + answerKey).update(engagementAnswer);
  }
  insertEngagementComment(tenantKey: string, companyKey: string, engagementKey: string, answerKey: string, engagementComment: EngagementComment) {
    return this.db.object<any>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/Comments/' + answerKey).update(engagementComment);
  }

  insertMessUp() {
    return this.db.object<any>('/EngagementAnalysis/-MbVraWsn_BxD8DeAgaM/').update({ key: '' });
  }

  fetchEngagementAnswers(tenantKey, companyKey, engagementKey) {
    return this.db.list<EngagementAnswer>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/Answers/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchEngagementAnswersOnce(request) {
    return this.http.post(this.fbFunctionURL + 'fetchEngagementAnswers', request);
  }

  removeLocalEngagementAnswers(request) {
    return this.http.post(this.fbFunctionURL + 'removeLocalEngagementAnswers', request);
  }


  fetchEngagementComments(tenantKey, companyKey, engagementKey) {
    return this.db.list<EngagementComment>('/EngagementAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/Comments/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchEngagementCommentsFromSDB(request) {
    return this.http.post(this.fbFunctionURL + 'fetchCommentsFromSDB', request);
  }
  private analyticsSurvey = new BehaviorSubject<AnalyticsEngagement>(null);
  analyticsSurvey$ = this.analyticsSurvey.asObservable();

  setAnalyticsSurvey(filters: AnalyticsEngagement) {
    this.analyticsSurvey.next(filters);
  }
}
export class AnalyticsEngagement {
  tenantKey: string;
  companyKey: string;
  questions: any;
  survey: {
    name: string,
    key: string,
  };
  engagementAnalysis: any
}