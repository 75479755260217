import { LocalService } from "@ex/module/core/services/local.service";
import { updateQuestionHeaderHTML, questionsBuilderTranslateText } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";
import { TranslatePipe } from "src/app/shared/pipes/translate.pipe";


/**
 * Updates the HTML representation of a short text question.
 * @param options - The options object.
 */
export function updateShortTextQuestionHTML(options) {

  updateQuestionHeaderHTML(options, "Short Text", "fa-message-minus");

  if (options.question.placeholder == "" || options.question.placeholder == null) {
    options.question.placeholder = questionsBuilderTranslateText("Type your comment here...");
    // options.question.placeholder = translationPipe.transform("Type your comment here...",language, "Survey X");
  }
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  //body.className = body.className + " mt-5";
}

/**
 * Updates the HTML representation of a long text question.
 * @param options - The options object.
 */
export function updateLongTextQuestionHTML(options: any) {
  // var translationPipe = new TranslatePipe();
  // var localService = new LocalService();


  updateQuestionHeaderHTML(options, "Long Text", "fa-message-lines");
  if (options.question.placeholder == "" || options.question.placeholder == null) {
    options.question.placeholder = questionsBuilderTranslateText("Type your comment here...");
  }

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  //body.className = body.className + " mt-5";
}

/**
 * Updates the HTML representation of a date input question.
 * @param options - The options object.
 */
export function updateDateInputQuestionHTML(options: any) {
  updateQuestionHeaderHTML(options, "Date Input", "fa-calendar");
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  //body.className = body.className + " mt-5";

  body.innerHTML = "";

  // Add 2 date inputs side by side
  var newDateInputQuestionContent = document.createElement("div");
  newDateInputQuestionContent.className = "flex flex-row items-center justify-center gap-5 py-4 w-full rank-items-container";

  var dateInput1 = document.createElement("input");
  dateInput1.className = "date-input p-2 w-1/2";
  dateInput1.setAttribute("type", "date");
  dateInput1.disabled = true;
  dateInput1.style.textTransform = "uppercase";

  var dateInput2 = document.createElement("input");
  dateInput2.className = "date-input p-2 w-1/2";
  dateInput2.setAttribute("type", "date");
  dateInput2.disabled = true;
  dateInput2.style.textTransform = "uppercase";

  if (options.question['dateType'] == "range") {
    newDateInputQuestionContent.appendChild(dateInput1);
    newDateInputQuestionContent.appendChild(dateInput2);
  } else {
    newDateInputQuestionContent.appendChild(dateInput1);
  }

  body.appendChild(newDateInputQuestionContent);
}

/**
 * Updates the HTML representation of an email input question.
 * @param options - The options object.
 */
export function updateEmailInputQuestionHTML(options: any, locale?) {
  updateQuestionHeaderHTML(options, "Email Input", "fa-at");
  options.question.placeholder = questionsBuilderTranslateText("txtType your email here...");

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  //body.className = body.className + " mt-5";
}

/**
 * Updates the HTML representation of a slider question.
 * @param options - The options object.
 */
export function updateSliderQuestionHTML(options: any) {
  updateQuestionHeaderHTML(options, "Slider", "fa-sliders");
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  //body.className = body.className + " mt-5";
}

/**
 * Updates the HTML representation of a boolean question.
 * @param options - The options object.
 */
export function updateBooleanQuestionHTML(options: any) {
  updateQuestionHeaderHTML(options, "Binary", "fa-circle-half-stroke");
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  //body.className = body.className + " mt-5";

  // Remove the current body content
  body.innerHTML = "";

  // Create a new boolean question content with 2 items (True and False) with thumbs up and thumbs down icons
  var newBooleanQuestionContent = document.createElement("div");
  newBooleanQuestionContent.className = "flex justify-center items-center gap-5 py-4";

  var trueItem = document.createElement("div");
  trueItem.className = "flex flex-col items-center justify-center gap-2 boolean-question-true cursor-pointer";
  var trueIcon = document.createElement("i");
  trueIcon.className = "fa-light fa-thumbs-up text-4xl";
  var trueText = document.createElement("p");
  trueText.innerHTML = options.question['labelTrue'] || "True";

  var falseItem = document.createElement("div");
  falseItem.className = "flex flex-col items-center justify-center gap-2 boolean-question-false cursor-pointer";
  var falseIcon = document.createElement("i");
  falseIcon.className = "fa-light fa-thumbs-down text-4xl";
  var falseText = document.createElement("p");
  falseText.innerHTML = options.question['labelFalse'] || "False";

  trueItem.appendChild(trueIcon);
  trueItem.appendChild(trueText);

  falseItem.appendChild(falseIcon);
  falseItem.appendChild(falseText);

  newBooleanQuestionContent.appendChild(trueItem);
  newBooleanQuestionContent.appendChild(falseItem);

  body.appendChild(newBooleanQuestionContent);
}

/**
 * Updates the HTML representation of a number input question.
 * @param options - The options object.
 */
export function updateNumberInputQuestionHTML(options: any) {
  updateQuestionHeaderHTML(options, "Number Input", "fa-hashtag");
  if (options.question.placeholder == "" || options.question.placeholder == null) {
    options.question.placeholder = questionsBuilderTranslateText("Enter a number here...");
  }

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  //body.className = body.className + " mt-5";
}
