import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { ModuleName } from 'src/app/shared/enums/ModuleName';

@Component({
  selector: 'z-fileupload',
  templateUrl: './zen-fileupload.component.html',
  styleUrls: ['./zen-fileupload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZenFileuploadComponent,
    },
  ],
  animations: [ChildrenAnimation]
})
export class ZenFileuploadComponent extends BaseComponent implements OnInit {
  fileOver: boolean = false;
  ModuleName = ModuleName;
  @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;

    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped(files);
    }
  }

  @Output()
  fileUploadChange = new EventEmitter<{ base64: string, file: File }>();

  @Input('URL') url: string;
  @Input() noURL = false;
  @Input() language;
  @Input() accept;
  @Input() columns;
  @Input() fileName;
  @Input() mode: string = 'download'
  @Input('reset') set reset(reset: boolean) {
    if (reset) {
      this.cancelRequest();
    }
  };
  @Output() uploadedFilesChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() response: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {
    this.form = this.formBuilder.group({
      file: [''],
    });
    console.log('file uploader initiated!');
  }

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient
  ) {
    super()
    this.addSubscription(this.EssentialObject$.pipe().subscribe(obj => {

      if (obj.language) {
        this.language = obj.language;
      }
    }));
  }

  translation = {
    "EN": {
      txtCancel: 'Cancel',
      txtFileUploaded: 'File Uploaded!',
      txtUploading: 'Uploading...',
      txtDragAndDrop: 'Drag & Drop the file here!',
      txtOr: 'Or',
      txtUploadFile: 'Upload file',
      txtMaxFileSize: 'XLSX or CSV files are allowed - max file size 100MB',
    },
    "AR": {
      txtCancel: 'إلغاء',
      txtFileUploaded: 'ملف مرفوع !',
      txtUploading: 'جارٍ التحميل ...',
      txtDragAndDrop: 'اسحب الملف وأفلته هنا!',
      txtOr: 'أو',
      txtUploadFile: 'رفع ملف',
      txtMaxFileSize: 'يُسمح بملفات XLSX أو CSV - أقصى حجم للملف 100 ميجا بايت',
    },
    "FR": {
      txtCancel: 'Alluner',
      txtFileUploaded: 'File Uploaded!',
      txtUploading: 'Uploading...',
      txtDragAndDrop: 'Drag & Drop the file here!',
      txtOr: 'Or',
      txtUploadFile: 'Upload file',
      txtMaxFileSize: 'XLSX or CSV files are allowed - max file size 100MB',
    },
  };


  getTranslation(txt) {
    return this.translation[this.language][txt];
  }
  files: any[] = [];
  uploadedFiles: any[] = [];
  form: FormGroup;
  newFile;
  uploadResponse;
  uploadStarted: boolean = false;
  percentage;
  timeLeft: number;
  uploadEnded: boolean = false;
  request;
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  onFileDropped(files) {
    this.prepareFilesList(files);
  }
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      this.onFileSelect(item);
    }
  }
  onFileSelect(file, event?) {
    this.uploadedFiles = [];
    console.log(file);
    if (!file)
      return
    this.percentage = 0;
    this.uploadStarted = true;
    this.uploadEnded = false;
      if(event)
    event.target.value = '';

    let startTime = new Date().getTime();

    this.getBase64(file).then((data) => {
      this.newFile = file.name;

      if (this.noURL) {
        this.fileUploadChange.emit({
          base64: data as string,
          file: file
        })

        for (let index = 0; index < 100; index++) {
          this.percentage++;
        }
        return this.uploadEnded = true;
      }

      const formData = new FormData();
      this.uploadedFiles.push(this.newFile);
      this.uploadedFilesChange.emit(this.uploadedFiles);
      formData.append('file', data as Blob);
      this.request = this.httpClient
        .post(this.url, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((resp) => {
          if (resp.type === HttpEventType.Response) {
            this.uploadEnded = true;
            setTimeout(() => {
              this.response.emit(resp);
            }, 500);
          }
          if (resp.type === HttpEventType.UploadProgress) {
            this.percentage = Math.round((100 * resp.loaded) / resp.total);
            let dateDifference = new Date().getTime() - startTime;
            this.timeLeft = Math.round((((dateDifference * 100) / this.percentage) - dateDifference) / 1000);
          }


        });
    });
  }
  // reduceSeconds(date) {
  //   return setInterval(() => {
  //     date - 1
  //   }, 1000);

  // }

  cancelRequest() {
    if (this.request)
      this.request?.unsubscribe();
    this.uploadStarted = false;
    this.uploadEnded = false
    this.newFile = null;
  }


  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  totalText;
  progressValue;
  progressStatus;
  progressHandler(event) {
    this.totalText = 'Uploaded ' + event.loaded + ' bytes of ' + event.total;
    var percent = (event.loaded / event.total) * 100;
    this.progressValue = Math.round(percent);
    this.progressStatus = Math.round(percent) + '% uploaded... please wait';
  }


  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        line += array[i][head] + ',';
      }
      str += line + '\r\n';
    }
    return str;
  }
  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, this.columns);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

}

