<div class="zen-main-container flex flex-col items-stretch justify-between">
  <div class="flex flex-row relative items-stretch">
    <div class="flex flex-col relative bg-white gap-2 z-20 transition-all ease-expo duration-1000 overflow-hidden" [ngClass]="{
        'w-20': collapse && !global.innerRoute,
        'w-80': !collapse && !global.innerRoute,
        'w-0 overflow-hidden': global.innerRoute
      }">
      <div class="fixed pt-14 w-inherit transition-all duration-200 ease-expo h-screen bg-white top-20" [ngClass]="{ 'w-0 overflow-auto': global.innerRoute }">
        <div class="flex flex-row items-center pt-1 leading-10" [ngClass]="collapse ? 'justify-center' : 'justify-between'">
          <h5 *ngIf="!collapse" class="uppercase text-xxs text-zen font-medium px-10" (click)="logDate(link.isActive)">
            {{ "txtNavigation" | translate : language : ModuleNames.Shared }}
          </h5>
          <i (click)="collapseColumn()" [ngClass]="{
              rotateY180: collapse,
              'rotate-0': !collapse,
              'fa-arrow-left-from-line': !(language | language),
              'fa-arrow-right-from-line': (language | language)
            }" class="fa-regular px-5 leading-12 btn transition-all transform ease-expo"></i>
        </div>
        <span></span>
        <div class="flex flex-row">
          <div class="flex flex-col">
            <ng-container *ngFor="let route of routes; let r = index">
              <span [ngClass]="link.isActive ? 'h-' + r * 20 : 'h-0'" [routerLink]="route.link" routerLinkActive #link="routerLinkActive" class="w-2 bg-transparent transform transition-all ease-expo duration-500"></span>
            </ng-container>
            <span class="w-2 bg-exp h-20 transform transition-all ease-expo duration-500"></span>
          </div>
          <div class="flex flex-col px-6" [@listAnimation]="routes.length">
            <a *ngFor="let route of routes; let r = index" [matTooltip]="
                collapse
                  ? (route.name | translate : language : ModuleNames.Shared)
                  : ''
              " class="btn h-20 leading-20 flex flex-row items-center gap-4 text-zen transition-all ease-expo duration-500" [routerLink]="route.link" routerLinkActive #link="routerLinkActive" [ngClass]="
                link.isActive ? 'text-exp' : 'text-zen text-opacity-90'
              " [TourStep]="
                tourKey && (r == index || !index) ? {
                      title: route.name | translate : language : ModuleNames.Shared,
                      text:route.name + '_' + tourKey| translate : language : ModuleNames.Shared,
                      order: 0,
                      tourKey: tourKey + '-' + route.title,
                      on: (language | language) ? 'left' : 'right'
                    }
                  : undefined
              ">
              <i *ngIf="!route.img" class="fa-regular text-2xl w-7 text-center" [ngClass]="'fa-' + route.icon"></i>
              <img *ngIf="route.img" class="w-8 text-center" [src]="
                  link.isActive && route.imgActive ? route.imgActive : route.img
                " alt="" />
              <span [@child3] *ngIf="!collapse">{{
                route.name | translate : language : ModuleNames.Shared
                }}</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col relative transition-all ease-expo duration-500" [ngClass]="{
        'w-20-right': collapse && !global.innerRoute,
        'w-80-right': !collapse && !global.innerRoute,
        'w-full': global.innerRoute
      }" [@routeInnerAnimations]="resolveRoute(o)">
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </div>
  <z-footer></z-footer>
</div>