import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { ZenEditContainerComponent } from './zen-edit-container.component';
export enum EditContainerStyle {
  Basic = 'basic',
  Dark = 'dark',
  Injected = 'injected'
}
@Directive({
  selector: '[EditContainer]'
})
export class EditContainerDirective {
  constructor(private el: ElementRef, private host: ZenEditContainerComponent) { }
  inputStyle = EditContainerStyle;
  editMode: boolean = false;
  @Input('styles') style = this.inputStyle.Basic;
  @Input() auto: boolean = true;
  @Input() autoClose: boolean = true;
  @HostBinding('class')
  get themeClass() {
    return this.returnClass(this.el)
  };
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {

    const clickedInside = this.el.nativeElement.contains(target);
    if (clickedInside && this.auto) {
      this.editMode = true;
      this.host.active = true;

    }
    else if (this.editMode && this.autoClose) {
      this.editMode = false;
      this.host.active = false;
    }
  }

  returnClass(elem) {
    let basicClasses = 'flex flex-col items-stretch  text-sm',
      inactiveClasses = 'bg-white',
      activeClasses = '',
      tClass = '';
    switch (this.style) {
      case EditContainerStyle.Basic:
        tClass = 'min-h-15 text-zen';
        activeClasses = 'bg-zen bg-opacity-10'
        break;
      case EditContainerStyle.Dark:
        tClass = 'bg-zen-yellow';
        activeClasses = 'bg-zen bg-opacity-10'
        break;
      case EditContainerStyle.Injected:
        tClass = 'min-h-15 text-zen';
        break;
      default:
        tClass = 'h-15 text-zen-red bg-zen-red';
        break;
    }
    return tClass + ' ' + basicClasses + ' ' + (this.host.active ? activeClasses : inactiveClasses);
  }
}

