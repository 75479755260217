<div *ngIf="language" class="fixed flex flex-col items-stretch top-0 left-0 Page h-screen w-screen z-50 bg-zen-background">

  <app-form-builder-header
      [surveyId]="formId"
      [surveyTitle]="surveyData?.title  | sjsTranslate:language"
      [currentPage]="'respondents'"
      [showAssignWeights]="showAssignWeights"
      [language]="language"
      (nextStep)="nextStep()"
      (previousStep)="previousStep()"
      (pageChanged)="pageChanged($event)">
  </app-form-builder-header>

  <div  class="flex flex-col items-center w-full h-100vh-110px pt-10 overflow-scroll">
    <div  class="max-w-5xl w-full flex flex-col items-stretch">
      <div class="flex justify-between mb-4 w-full">
        <div class="w-max">
          <h3 class="page-title mb-1">{{'Respondents' | translate:language:ModuleNames.SurveyX }}</h3>

          <p class="page-sub-title">
            {{'Select who are the respondents for the assessment from the respondents pool or add new ones'
            | translate:language:ModuleNames.SurveyX }}
          </p>
        </div>

        <div class="flex justify-center items-center">
          <button class="btn new-respondent-button flex items-center justify-between gap-2 px-3 py-2"
            (click)="respondentAdd = true">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.75 1.25V6.25H12.75C13.1562 6.25 13.5 6.59375 13.5 7C13.5 7.4375 13.1562 7.75 12.75 7.75H7.75V12.75C7.75 13.1875 7.40625 13.5 7 13.5C6.5625 13.5 6.25 13.1875 6.25 12.75V7.75H1.25C0.8125 7.75 0.5 7.4375 0.5 7C0.5 6.59375 0.8125 6.25 1.25 6.25H6.25V1.25C6.25 0.84375 6.5625 0.5 7 0.5C7.40625 0.5 7.75 0.84375 7.75 1.25Z"
                fill="#5D75A2" />
            </svg>
            {{'Add Respondent(s)' | translate:language:ModuleNames.SurveyX }}
          </button>
        </div>
      </div>

      <div class="flex flex-col">
        <sx-respondent-pool  [language]="language" [currentPage]="currentPage" (allSelected)="selectedAll($event)" (searchTextChange)="searchTextUpdate($event)" (pageChanged)="onPageChange($event)" [pageSize]="pageSize" [totalRecords]="totalRecords"  [respondents]="respondents" [options]="options" (singleDelete)="deleteRespondent($event)" (bulkDelete)="deleteRespondents($event)" ></sx-respondent-pool>
     </div>
    </div>
  </div>
</div>

  <respondents-modal (closeDialog)="respondentAdd = false;" [essentialObject]="essentialObject" *ngIf="respondentAdd"
  (uploadRequest)="insertUsingExcel($event)" (multipleRespondents)="createMultipleRespondent($event)"
  [language]="language" (singleRespondent)="createSingleRespondent($event)" [(respondentAdd)]="respondentAdd"
  [newRespondent]="newRespondent" (downloadCSV)="downloadExcel($event)"></respondents-modal>


<!-- <loader *ngIf="loader.showLoader"></loader> -->
