<zen-main-container *ngIf="formStatus == 'Live'">
  <sidebar-container>

    <back-button [routerLink]="'/survey-x/form-library'">
      <i [ngClass]="(language | language )?'fa-arrow-right':'fa-arrow-left'" class="fa-regular"></i>
      <span>{{"Back to Forms"| translate:language:ModuleNames.SurveyX}}</span>
    </back-button>
    <div class="flex flex-col items-stretch gap-12">
      <response-rate-card [language]="language" [completed]="totalCompleted" [total]="totalCount"
        [precentage]="responseRatePercentage"></response-rate-card>
      <section-sidebar [title]="'txtRespondentsByStatus'| translate:language:ModuleNames.SurveyX">
        <status-button *ngFor="let item of buttonList" (click)="applyFilter2(item)"
          [active]="(currentStatus.key | uppercase) == (item?.key | uppercase)" [status]="item?.key"
          [data]="item?.value">
          <ng-container text>{{'txt_' + item?.key | translate:language:ModuleNames.Shared}}</ng-container>
        </status-button>
      </section-sidebar>
    </div>





  </sidebar-container>
  <content-container class="flex flex-col items-stretch w-full">

    <div class="flex flex-col items-stretch h-28 z-10" sticky>
      <section-head>
        <ng-container left>
          <div class="flex flex-col">
            <div class="flex flex-row items-center gap-2">
              <h5 class="text-zen font-medium text-2xl"> {{form.title | sjsTranslate:language}}</h5>
              <h5 [@first]
                class="text-white bg-zen-redSelect uppercase font-medium text-xxs rounded-r-full rounded-l-full px-2 py-1">
                <i class="fa-solid fa-circle text-xxxs"></i>
                {{"txt"+ formStatus | translate:language:ModuleNames.SurveyX}}
              </h5>
            </div>
            <h5 class="text-zen-primarylight text-xs">{{form.description | sjsTranslate:language}}</h5>
          </div>

        </ng-container>
        <ng-container right>
          <div class="flex flex-row items-stretch gap-5">
            <ng-container *ngIf="form.isDefeniteForm">
              <a [matTooltip]="'txtUpdateSurveyEnddateAndReminders' | translate:language:ModuleNames.Shared " zenButton
                styles="secondary icon-11" (click)="openLiveDateChanges()">
                <i class="fa-regular fa-bell"></i>
              </a>
              <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
            </ng-container>


            <a zenButton styles="secondary height-11" (click)="shareSurveyLink()">{{ "txtShare" |
              translate:language:ModuleNames.SurveyX}}</a>

            <a (click)="onCloseNow()" [@child4] zenButton styles="red height-12" class="whitespace-nowrap">
              <i class="fa-regular fa-do-not-enter text-base "></i>
              {{ 'txtCloseNow'| translate:language:ModuleNames.SurveyX}}
            </a>



            <!-- <app-close-now-btn [selectedForm]="form" (onCloseSurvey)="closeSurvey()"></app-close-now-btn> -->
          </div>
        </ng-container>
      </section-head>
    </div>
    <div class="flex flex-row justify-between items-center h-20 bg-white px-10">
      <div class="flex flex-row items-stretch gap-6">
        <div class="flex flex-row items-stretch">
          <div
            class="text-sm rounded text-zen bg-white px-3 h-9 gap-2 leading-9 truncate flex flex-row justify-start items-center">
            <i [ngClass]="{'rotate-h-45':(language|language)}" class="fal fa-search text-zen transform"></i>
            <input [(ngModel)]="searchText" (ngModelChange)="searchTextUpdate($event)"
              [placeholder]="'txt_search' | translate:language:ModuleNames.Shared"
              class="bg-transparent overflow-hidden h-12 leading-12 text-sm  w-80" type="text">
          </div>
        </div>
        <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
        <a (click)="onSendReminder(selectedRespondents)" zenButton styles="ghost height-11">
          <i class="fa-regular fa-bell"></i>
          <h5>{{'txt_send_reminders'| translate:language:ModuleNames.Engagement}}</h5>
        </a>
        <a (click)="deleteRespondents(selectedRespondents)" *ngIf="selectedRespondents?.length" zenButton
          styles="ghost height-11">
          <i class="fa-regular fa-trash text-zen-red"></i>
          <h5>{{ "btn_delete" | translate:language:ModuleNames.Engagement }}</h5>
        </a>
      </div>
      <div class="flex flex-row items-stretch justify-end gap-2.5">
        <i (click)="downloadRespondents()" class="fa-regular fa-download" zenButton styles="ghost icon-11"></i>
        <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
        <a (click)="respondentAddFunction()" zenButton styles="ghost height-11"><i
            class="fa-regular fa-plus"></i>{{'btn_add_respondent'| translate:language:ModuleNames.Engagement }}</a>
      </div>

    </div>



    <!-- tablehere -->
    <div class="flex flex-col px-7">
      <sx-respondent-pool (selectedRespondents)=" selectedRespondents = $event" [customHeader]="true"
        [language]="language" [currentPage]="currentPage" (pageChanged)="onPageChange($event)" [pageSize]="pageSize"
        [totalRecords]="totalRecords" [respondents]="respondents" [options]="options"
        (allSelected)="selectedAll($event)">
        <ng-template let-element>
          <span *ngIf="displayResOptions(element)">
            <i [ZMenuTriggerFor]="menu"
              class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full  btn text-center"></i>
            <z-menu #menu>
              <menuItems>
                <a MenuItem (click)="onSendReminder([element])"><i class="fa-regular fa-envelope mx-1"></i>
                <span>{{'txtSend Reminder'|
                  translate:language:ModuleNames.SurveyX}}</span></a>
                <!-- <a *ngIf="element.status == 'Completed' && !form.isAnonymous" MenuItem
                  [routerLink]="'/survey-x/form-library/answers/'+formId+'/'+element.key+'/lf'"><i
                    class="fa-regular fa-file-alt mx-1"></i>{{'txtView Answers'|
                  translate:language:ModuleNames.SurveyX}}</a> -->
                <!-- <a *ngIf="!form.isAnonymous" MenuItem (click)="onEditRespondent(element)"><i
                    class="fa-regular fa-edit mx-1"></i>{{'Edit'| translate:language:ModuleNames.SurveyX}}</a> -->
                <a *ngIf="element.status != status.Progress" MenuItem (click)="deleteRespondent(element)"
                  class="text-red-500"><i class="fa-regular fa-trash mx-1 text-red-500"></i>{{'Delete'|
                  translate:language:ModuleNames.SurveyX}}</a>
              </menuItems>
            </z-menu>
          </span>
        </ng-template>
      </sx-respondent-pool>

    </div>

  </content-container>
</zen-main-container>


<respondents-modal (closeDialog)="respondentAdd = false;" [essentialObject]="essentialObject" *ngIf="respondentAdd"
  (uploadRequest)="insertUsingExcel($event)" (multipleRespondents)="createMultipleRespondent($event)"
  [language]="language" (singleRespondent)="createSingleRespondent($event)" [(respondentAdd)]="respondentAdd"
  (downloadCSV)="downloadExcel($event)"></respondents-modal>


<app-form-closed-successfully-view *ngIf="openClosedSuccessModal" [(open)]="openClosedSuccessModal"
  [selectedForm]="form"> </app-form-closed-successfully-view>
