import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { TestService } from './test.service';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
  TOOLTIP_PANEL_CLASS,
} from '@angular/material/tooltip';
import { DOCUMENT } from '@angular/common';
import { GeneralService } from './core/services/general.service';
import { LocalService } from './core/services/local.service';
import { routeOuterAnimations } from './shared/animations/allAnimations';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { RouterOutlet } from '@angular/router';
import { TranslationsService } from './core/services/translations.service';
/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  position: 'above',
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    { provide: TOOLTIP_PANEL_CLASS, useValue: 'mat-tooltip' },
  ],
  animations: [routeOuterAnimations],
})
export class AppComponent implements OnInit {
  title = 'ExitAnalysis';
  constructor() { }
  ngOnInit() { }
  currentIndex: number = -3;
  value
  resolveRoute(o: RouterOutlet) {
    let path = o.activatedRouteData['animation'];
    if (o.isActivated) {
      if (path > this.currentIndex) {
        this.value = 'down';
      } else if (path < this.currentIndex) {
        this.value = 'up';
      }

      this.currentIndex = path;
      return this.value;
    }
  }
}
