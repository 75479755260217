import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { WellbeingScalesService } from '../../../utils/wellbeing-scales.service';
import wellbeingClosedSurveyAnimation from '../../../utils/closed-survey-animations';
import { AnalyticsFilter, AnalyticsService } from '../../../data-access/services/analytics.service';
import { map, switchMap } from 'rxjs/operators';
import { DimensionOverallAnalysisDto, SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { SectionDto } from '@ex/wellbeing/survey-templates';

@Component({
  selector: 'overall-dimension-analysis',
  templateUrl: './overall-dimension-analysis.component.html',
  styleUrls: ['./overall-dimension-analysis.component.scss'],
  animations: wellbeingClosedSurveyAnimation,
})
export class OverallDimensionAnalysisComponent extends BaseComponent implements OnInit {

  @Input()
  language = "EN";
  @Output() currentFactors: EventEmitter<DimensionOverallAnalysisDto[]> = new EventEmitter();

  showBenchmark = false

  hasExtraDimensions = false;

  factors = [];
  overallFactorScore = 0;

  filters: AnalyticsFilter
  constructor(public wellbeingScalesService: WellbeingScalesService, private analysisService: AnalyticsService, private surveyAnalysisService: SurveyAnalysisService) { super(); }

  ngOnInit(): void {

    this.addSubscription(this.analysisService.filters$
      .pipe(
        switchMap(filters => {

          // TODO: Fetch factor analysis
          return this.surveyAnalysisService.getOverallDimensionAnalysis({
            companyKey: filters.companyKey,
            tenantKey: filters.tenantKey,
            surveyKey: filters.survey.key,
            filters: [...this.analysisService.mapGlobalFilters(filters), ...filters.chartFilters || []]
          }).pipe(
            map(factors => ({ factors, filters }))
          )

        })
      )
      .subscribe(({ filters, factors }) => { 
        this.filters = filters;
        this.factors = this.filters.survey.dimensions.filter(dim => factors.find(x => x.key == dim.key)).map(dim => { return factors.find(x => x.key == dim.key) })
        this.overallFactorScore = this.factors.reduce((p, c) => p + c.score, 0) / this.factors.length;
      }));
  }

}
