import { formatPercent } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { CHART_DATA_RESPONSE_STRATEGY } from '@ex/module/core/services/chart-data.service';
import { listAnimation } from '@ex/module/shared/animations/allAnimations';
import { AnalysisFilterKeys, AnalyticsFilter, AnalyticsService } from '@ex/module/survey-x/data-access/services/analytics.service';
import { CriteriaResolverService } from '@ex/module/survey-x/data-access/services/criteria-resolver.service';
import { ReportDataService } from '@ex/module/survey-x/data-access/services/report-data.service';
import { SurveyjsDataService } from '@ex/module/survey-x/data-access/services/surveyjs-data.service';
import { SxBaseComponent } from '@ex/module/survey-x/ui/sx-base/sx-base.component';
import { QuestionTypes } from '@ex/module/survey-x/utils/question-types';
import { AnalysisService, ChartResponseResult, QuestionAnalysisData, SurveyAnalysisService } from '@ex/survey-x/index';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-question-analysis',
  templateUrl: './question-analysis.component.html',
  styleUrls: ['./question-analysis.component.scss'],
  animations: [listAnimation]
})
export class QuestionAnalysisComponent extends SxBaseComponent implements OnInit {

  formId: string
  sections: { key: string, title: any }[] = [];
  questions = [];

  language: string = "EN";

  selectedSectionKey: string = "ALL";

  sameDependencyQuestionMultiOperations: any;

  surveyCriterias;

  constructor(private route: ActivatedRoute
    , private analysisService: SurveyAnalysisService
    , private surveyjsDataService: SurveyjsDataService
    , private analyticsService: AnalyticsService
    , private reportDataService: ReportDataService
    , private criteriaResolverService: CriteriaResolverService) {
    super()
  }


  operations: any[] = [
    { key: "empty", value: "Empty" },
    { key: "notempty", value: "Not empty" },
    { key: "=", value: "Equal to" },
    { key: "<>", value: "Does not equal to" },
    { key: "contains", value: "Contains" },
    { key: "notcontains", value: "Does not contain" },
    { key: ">", value: "Greater than" },
    { key: "<", value: "Less than" },
    { key: ">=", value: "Greater than or equal to" },
    { key: "<=", value: "Less than or equal to" }
  ];


  ngOnInit(): void {
    this.language = this.AnalysisComponentData.language;

    const sub = this.analyticsService.filters$.pipe(
      filter(x => x != null),
      tap(filters => {

        if (this.questions.length == 0 && this.sections.length == 0) {
          this.formId = filters.survey.key;
          this.surveyCriterias = filters.survey.surveyCriterias;
          this.sections = this.surveyjsDataService.getSections();
          this.questions = this.surveyjsDataService.getQuestions();
          this.fetchQuestionData(filters);
        }

      })
    ).subscribe(filters => {

      console.log("Questions:", this.questions);


      // if(this.questions)
      // this.questions.forEach(element => {
      //   if(element.dependencyQuestions?.length > 1)
      //   {

      //     this.sameDependencyQuestionMultiOperations = this.findMutualQuestionDependency(element.dependencyQuestions, 'key')
      //     console.log("sameDependencyQuestionMultipleOperations", this.sameDependencyQuestionMultiOperations)
      //   }

      // })

      this.filtersLoaded.next(filters);
    });

    this.addSubscription(sub);

  }

  getOperation(operator: any) {
    return this.operations.find(x => x.key == operator).value
  }

  getQuestionNumber(questionKey: any) {
    return this.questions.indexOf(this.questions.find(x => x.name == questionKey)) + 1
  }

  // findMutualQuestionDependency(array:any[], key:string)
  // {
  //   const seenValues: Map<any, number[]> = new Map();

  //   for (let i = 0; i < array.length; i++) {
  //     const obj = array[i];
  //     const value = obj[key];

  //     if (seenValues.has(value)) {
  //       seenValues.get(value)!.push(i); // Add the index of the object with duplicate value
  //     } else {
  //       seenValues.set(value, [i]);
  //     }
  //   }

  //   const duplicatesArray: any[] = [];
  //   seenValues.forEach((indexes) => {
  //     if (indexes.length > 1) {
  //       duplicatesArray.push(...indexes.map((index) => array[index]));
  //     }
  //   });

  //   return duplicatesArray;
  // }


  fetchQuestionData(filterOptions) {

    for (let question of this.questions) {

      let breakOutChange = new BehaviorSubject<any>(null);

      question['disableGrouping'] = false;

      if ([QuestionTypes.Ranking, QuestionTypes.Matrix, QuestionTypes.Text, QuestionTypes.FileUpload, QuestionTypes.Comment].includes(question.type)) {
        question['disableGrouping'] = true;
      }

      question['breakOutChange'] = breakOutChange;

      question['data$'] = combineLatest([breakOutChange.asObservable(), this.analyticsService.filters$]).pipe(
        switchMap(([breakout, filters]) => {

          const filterList = [...this.analyticsService.mapGlobalFilters(filters)];
          question['criteria'] = null;

          if (breakout) {
            filterList.push({
              key: "breakout",
              value: breakout
            })

            question['criteria'] = breakout;
          }

          return this.analysisService.getQuestionAnalysis(question.name, question.type, {
            tenantKey: filters.tenantKey,
            companyKey: filters.companyKey,
            formId: filters.survey.key,
            filters: filterList
          })
        })
      )

      if ([QuestionTypes.Text, QuestionTypes.Comment, QuestionTypes.FileUpload].includes(question.type)) {
        this.handlePaginationAndSearch(question, filterOptions);
      }

      question['data$'] = question['data$'].pipe(map(response => this.mapResponseToQuestionType(response, question)));

    }

    this.reportDataService.setQuestionCharts(this.questions);
  }

  handlePaginationAndSearch(question: any, filters: AnalyticsFilter) {
    question['page$'] = new BehaviorSubject(0);
    question['maxResultCount'] = 10;
    question['search$'] = new BehaviorSubject("");

    question['data$'] = combineLatest([question['page$'], question['search$'], this.analyticsService.filters$]).pipe(
      switchMap(([page, search, filters]: [any, any, any]) => {

        const f = [...this.analyticsService.mapGlobalFilters(filters), { key: AnalysisFilterKeys.Page, value: JSON.stringify({ offset: page, maxResultCount: question['maxResultCount'] }) }];

        if (search.length > 0) {
          f.push({
            key: AnalysisFilterKeys.Search,
            value: search
          })
        }

        return this.analysisService.getQuestionAnalysis(question.name, question.type, {
          tenantKey: filters.tenantKey,
          companyKey: filters.companyKey,
          formId: filters.survey.key,
          filters: f
        })
      })
    )
  }

  mapResponseToQuestionType(response: ChartResponseResult, question: any): any {

    if ([QuestionTypes.Text, QuestionTypes.Comment, QuestionTypes.FileUpload].includes(question.type))
      return response;

    response.data.forEach(item => {

      item['categoryKey'] = item.category;
      item['groupKey'] = item.group;

      if (question['criteria']) {
        item.group = this.criteriaResolverService.extract(question['criteria'], item.group, this.language);
        item.category = this.surveyjsDataService.getChoiceTranslation(question, item.category, this.language);

      }
      else
        item.category = this.surveyjsDataService.getChoiceTranslation(question, item.category, this.language);
    })

    // Passing the data without chart response stratergy for the purpose of export.
    question['exportData'] = response.data;

    let data = CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(response.data).process();
    data.drillDown = response.drillDown;
    data.drillDown = data.drillDown.map(item => {
      item['categoryKey'] = item.category;
      item['groupKey'] = item.group;

      if (question['criteria']) {
        item.group = this.criteriaResolverService.extract(question['criteria'], item.group, this.language);
        item.category = this.surveyjsDataService.getChoiceTranslation(question, item.category, this.language);

      }
      else
        item.category = this.surveyjsDataService.getChoiceTranslation(question, item.category, this.language);
      return item;
    });

    return data;
  }

}
