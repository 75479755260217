<div class="flex flex-colitems-center  w-full  rounded-xl gap-5  transition-all min-h-full zen-main-container justify-center relative">
  <div class="flex flex-col justify-center items-center gap-10 w-full" *ngIf="status == 'expired'">
      <h5 [@child3] class="text-zen text-2xl md:text-3xl font-semibold">{{'txtSurveyClosedTitle'| translate:language:ModuleNames.Shared}}</h5>
         <i [@child6]  class="fas fa-ban text-9xl  text-zen-red text-center "></i>
      <h5 [@child9] class="text-zen text-sm text-center md:text-lg"> {{'txtSurveyClosed'| translate:language:ModuleNames.Shared}}<br>{{'txtThankYou'|
        translate:language:ModuleNames.Shared}}</h5>
  </div>
  <div class="flex flex-col justify-center items-center gap-10 w-full"
    *ngIf="status == 'completed' || (stage == 'done' && status != 'expired')">
    <h5 [@child3] class="text-zen text-2xl md:text-3xl font-semibold">{{endTitle}}</h5>
    <img [@child6] class="h-64 md:h-96 w-auto" src="../../../assets/img/svg/end-visual.svg" alt="Employee Experience Logo" />
    <h5 [@child9] class="text-zen text-sm text-center md:text-lg"> {{endText}}<br>{{'txtThankYou'|
      translate:language:ModuleNames.Shared}}</h5>
  </div>
</div>