import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Engagement } from '../data-models/engagement';
import { GeneralService } from './general.service';
import { LocalService } from './local.service';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class CrashReportingService {
  ip: string = '';
  coreAPI: string = 'https://apis.zenithr.net/api/'; //'http://localhost:52619/api/';

  constructor(private db: AngularFireDatabase, private http: HttpClient, private localService: LocalService,
    private globals: GlobalsService
    ) { 
    this.coreAPI = this.globals.coreAPI;
  }

  updateLastActivity(userKey: string) {
    let activityLog = Number(new Date().getTime().toFixed(0));
    return this.db.object('PlatformUsersLog/' + userKey + '/MoreInformation/LastActivity/').set(activityLog);
  }

  updateCoreUserLog(log: any){
    return this.http.post(
      this.coreAPI + 'PlatformUser/UpdateUserLog',
      log
    );
  }

  submitLogCode(code: number) {
    let userKey = this.localService.getJsonValue('UserKey');
    let userEmail = this.localService.getJsonValue('UserEmail');

    if (userKey) {
      let activityTimeStamp = Number(new Date().getTime().toFixed(0));
      let key = this.db.createPushId();
      let myIp = '';
      let access = this.localService.getJsonValue('Access');
      if (access)
        myIp = access;
      let log = { key: key, logCode: code, timeStamp: activityTimeStamp, ip: myIp, email: userEmail, DateCreated: activityTimeStamp };
      this.db.object('PlatformUsersLog/' + userKey + '/Logs/' + log.key).update(log);
      this.updateCoreUserLog(log).toPromise();
      // this.updateLastActivity(userKey);
    }
  }

  updateLastLogginInInformation(userKey: string) {
    this.http
      .get('https://api.ipify.org/?format=json').subscribe((response: any) => {
        let url = "https://api.ipgeolocation.io/ipgeo?apiKey=d8238b326e1646b3a5202391e9a66a6f&ip=" + response.ip;
        return this.http
          .get(url).subscribe((geoResponse: any) => {
            this.ip = geoResponse.ip;
            this.db.object('PlatformUsersLog/' + userKey + '/MoreInformation/SecurityInformation/').set({
              ip: geoResponse.ip,
              country: geoResponse.country_name,
              city: geoResponse.city,
              district: geoResponse.district,
              latitude: geoResponse.latitude,
              longitude: geoResponse.longitude
            });

            var stopRE = /\./gi;
            let replacedIP = this.ip.replace(stopRE, '-');
            this.localService.setJsonValue('Access', this.ip);

            this.db.object('PlatformUsersLog/' + userKey + '/MoreInformation/IPs/' + replacedIP).set(this.ip);
            this.submitLogCode(1);

          });
      })
  }

}
