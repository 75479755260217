import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Tenant } from '../../core/data-models/tenant';
import { Entity } from '../../core/data-models/Entity';
import { LanguageService } from '../../core/services/language.service';
import { faBell, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Notification } from '../../core/data-models/notification';
import { NotificationMessage } from '../../core/data-models/notification-message';
import { NotificationsService } from '../../core/services/notifications.service';
import { PlatformUser } from '../../core/data-models/platform-user';
import { MatDialog } from '@angular/material/dialog';
import { UnitsNotificationDialogComponent } from '../units-notification-dialog/units-notification-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthenticationService } from '../../core/services/authentication.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ZenSnackbarService, ZenSnackBarTypes } from '../Theme/services/zen-snackbar.service';

import { Router } from '@angular/router';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { CrashReportingService } from '../../core/services/crash-reporting.service';
import { GeneralService } from '../../core/services/general.service';
import { LocalService } from '../../core/services/local.service';
import { ModuleName } from '../enums/ModuleName';
import { LanguagePipe } from '../pipes/language.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
declare var $: any;

@Component({
  selector: 'navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  ModuleNames = ModuleName
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input('tenant') tenant: Tenant;
  @Input('user') user: PlatformUser;
  @Input('pageID') pageID: number;
  @Input('companies') companies: Entity[] = [];
  @Input('identifier') identifier: string;
  @Input('lg') lg: string;

  notifications: Notification[] = [];
  notificationMessages: NotificationMessage[] = [];
  menuOpened: number = 0;
  @Input('notifications') set notificationsChanged(notifications: Notification[]) {
    this.notifications = notifications;
    this.evaluateNotifications();
  }
  logout() {
    this.authenticationService.logout();
  }

  reloadSelectedEntity: boolean = false;
  @Input('selectedEntity') set sEntity(selectedEntity) {
    if (selectedEntity) {
      this.selectedEntity = JSON.parse(JSON.stringify(selectedEntity));
      if (this.selectedEntity) {
        this.reloadSelectedEntity = true;
        setTimeout(() => {
          this.selectedEntityKey = this.selectedEntity.key;
          this.reloadSelectedEntity = false;
        }, 10);
      }
    }
  }
  selectedEntity: Entity;
  selectedEntityKey: string;
  supportedLanguages: any[] = [];
  numberOfNotifications: number = 0;
  lang: string;

  componentTextList: any[] = [];


  faBell = faBell;
  faTimesCircle = faTimesCircle;

  // txt_Exit_Questionnaire: string;
  // txt_Employee_Engagement_Survey: string;
  // txt_Employee_Assessment_360_Survey: string;
  @Input('language') set selectedLanguaged(lang: string) {
    this.language = lang;
    if (this.language)
      this.lang = this.language;
    this.setUpLanguagePreference();

  }
  language: string;
  // txt_unitsPopupTile: string;
  // txt_units_main_balance: string;
  // txt_units_survey_balance: string;
  @Output() selectedEntityChanged = new EventEmitter();
  @Output() selectedLanguageChanged = new EventEmitter();
  @Output() selectedPage = new EventEmitter();

  constructor(private languageService: LanguageService, private notificationService: NotificationsService, private _router: Router,
    public matDialog: MatDialog, private authenticationService: AuthenticationService, public matDDialog: MatDialog,
    private zenSnackBarService: ZenSnackbarService, private errorHandlerService: ErrorHandlerService, private languagePipe: LanguagePipe,
    private crashReportingService: CrashReportingService, private localService: LocalService, private translationPipe: TranslatePipe) {
  }



  resetPassword() {
    try {
      let message = this.translationPipe.transform('txt_Password_resetConfirmation', this.language, this.ModuleNames.Shared);
      let subject = this.translationPipe.transform('txt_subject_password_resetConfirmation', this.language, this.ModuleNames.Shared);
      message = message.replace('#EMAIL#', this.user.email);
      let actionButtonText = this.translationPipe.transform('txt_yes', this.language, this.ModuleNames.Shared);
      let cancelButtonText = this.translationPipe.transform('btn_cancel', this.language, this.ModuleNames.Shared);

      let confirmationDialog = this.matDDialog.open(ConfirmationDialogComponent, {
        data:
          { message: message, subject: subject, dialogType: 'warning', actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false
      });
      confirmationDialog.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 'YES') {
            this.authenticationService.sendResetPassword(this.user.email).then((_) => {
              this.crashReportingService.submitLogCode(19);
              this.zenSnackBarService.snackBar({ message: this.translationPipe.transform('txtResetEmail', this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Success });
            })
          }
        }
      })
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(4, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  selectEntityChange(event) {
    let company = this.companies.find(c => c.key == this.selectedEntityKey);
    this.selectedEntityChanged.emit(company);
  }

  isArabic() {
    if (this.languagePipe.transform(this.language)) {
      $("body").removeClass('ltr')
      $("body").addClass('rtl').attr("dir", "rtl");

    }
    else {
      $("body").removeClass('rtl')
      $("body").addClass('ltr').attr("dir", "ltr")

    }
  }

  getLongLanguageName(eG: any) {
    return this.languageService.getLanguageName(eG);
  }

  // txtLoggedinas: string;
  // txtControlPanel: string;
  // txtLockAccount: string;
  // txtLogout: string;
  // txtAccountOverview: string;
  // txtResetPassword: string;


  customLogo: boolean = false;
  customLogoURL: string;
  ngOnInit(): void {
    this.isArabic();
    if (this.tenant?.logoURL) {
      let localLogoURL = this.localService.getJsonValue('logoURL');
      if (this.tenant?.logoURL != localLogoURL) {
        this.localService.setJsonValue('logoURL', this.tenant?.logoURL);

      }
      this.customLogoURL = this.tenant?.logoURL;
      this.customLogo = true;
    } else {
      this.localService.removeItem('logoURL');
    }
    this.supportedLanguages = this.languageService.supportedLanguages;
  }

  removeAllNotifications() {
    for (let i = 0; i < this.notificationMessages.length; i++) {
      this.removeNotification(this.notificationMessages[i].key);
    }
  }

  setUpLanguagePreference() {
    // this.componentTextList = this.languageService.componentText.filter(country => country.language == this.language);

    // this.setUpTextFields();
  }
  // txtSurveys: string;
  // txtEngagement: string;
  // txtAssessmentSurvey: string;
  // txtAssessmentSurvey2: string;
  // txt_employee_exit: string;
  // txt_employee_exit2: string;
  // txtEmployeeExperience: string;
  // txtResetEmail: string;
  // txtEmployeeHappiness: string;
  // setUpTextFields() {
  //   this.txtResetEmail = this.languageService.getUILanguage('txtResetEmail', this.language);
  //   this.txtPageSettings = this.languageService.getUILanguage('txt_page_settings', this.language);
  //   this.txtPageLeaversList = this.languageService.getUILanguage('txt_page_leavers', this.language);
  //   this.txtReports = this.languageService.getUILanguage('txt_reports', this.language);
  //   this.txtNotifications = this.languageService.getUILanguage('txt_notifications', this.language);
  //   this.txt_unitsPopupTile = this.languageService.getUILanguage('txt_unitsPopupTile', this.language);
  //   this.txt_units_main_balance = this.languageService.getUILanguage('txt_units_main_balance', this.language);
  //   this.txt_units_survey_balance = this.languageService.getUILanguage('txt_units_survey_balance', this.language);

  //   this.txtDashboard = this.languageService.getUILanguage('txt_dashboard', this.language);
  //   this.txtLeavers = this.languageService.getUILanguage('txt_leavers', this.language);
  //   this.txtQuestionnaireBuilder = this.languageService.getUILanguage('txt_questionnaire_builder', this.language);
  //   this.txtResetPassword = this.languageService.getUILanguage('txtResetPassword', this.language);

  //   this.txtAccountOverview = this.languageService.getUILanguage("txtAccountOverview", this.language);
  //   this.txtEngagement = this.languageService.getUILanguage("txtEngagement", this.language);
  //   this.txtAssessmentSurvey = this.languageService.getUILanguage("txt360DegreeFeedback", this.language);
  //   this.txtAssessmentSurvey2 = this.languageService.getUILanguage("txt360DegreeFeedback", this.language);
  //   this.txt_employee_exit = this.languageService.getUILanguage("txtEmployeeExitAnalysis", this.language);
  //   this.txt_employee_exit2 = this.languageService.getUILanguage("txt_employee_exit", this.language);

  //   this.txtSurveys = this.languageService.getUILanguage('txtSurveys', this.language);
  //   this.txt_Exit_Questionnaire = this.languageService.getUILanguage('txt_Exit_Questionnaire', this.language);
  //   this.txt_Employee_Engagement_Survey = this.languageService.getUILanguage('txt_Employee_Engagement_Survey', this.language);
  //   this.txt_Employee_Assessment_360_Survey = this.languageService.getUILanguage('txt360DegreeFeedback', this.language);
  //   this.txtLoggedinas = this.languageService.getUILanguage('txtLoggedinas', this.language);
  //   this.txtControlPanel = this.languageService.getUILanguage('txtControlPanel', this.language);
  //   this.txtLockAccount = this.languageService.getUILanguage('txtLockAccount', this.language);
  //   this.txtLogout = this.languageService.getUILanguage('txtLogout', this.language);
  //   this.txtEmployeeExperience = this.languageService.getUILanguage('txtEmployeeExperience', this.language);
  //   this.txtEmployeeHappiness = this.languageService.getUILanguage('txtEmployeeHappiness', this.language);
  // }

  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }

  selectedLanguageChange(event) {
    this.language = event;
    this.localService.setJsonValue('language', this.language);

    location.reload();
    // this.isArabic();
    // this.selectedLanguageChanged.emit(this.language);
    // this.isArabic();
  }



  pageSelected(pageID: number) {
    this.localService.setJsonValue('localSettings', pageID.toString());

    this.selectedPage.emit(pageID);
  }

  multiTimer;
  navigateToNotificationSettings() {

    this.localService.setJsonValue('localSettings', 'notifications');

    this.selectedPage.emit(0);
    this._router.navigate(['/home']);

  }
  evaluateNotifications() {
    try {
      this.notificationMessages = [];

      this.notifications.sort((a, b) => (a.notificationDate < b.notificationDate) ? 1 : -1);

      for (let i = 0; i < this.notifications.length; i++) {
        let notification = this.notifications[i];


        if (notification.notiificationType != 'UNIT_CREDIT_MAIN_BALANCE' && notification.notiificationType != 'UNIT_CREDIT_COMPANY_SURVEY_BALANCE') {
          let message = this.notificationService.evaluateNotification(notification, this.language);
          this.notificationMessages.push(message);
        } else {
          this.evaluatePopupNotification(notification);
        }
      }
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }

  }

  evaluatePopupNotification(notification: Notification) {
    try {

      if (notification.notiificationType == 'UNIT_CREDIT_MAIN_BALANCE') {
        if (notification?.units) {

        } else {
          notification.units = 0;
        }
        let data = JSON.parse(JSON.stringify({
          title: this.translationPipe.transform('txt_unitsPopupTile', this.language, this.ModuleNames.Shared), units: notification.units, message: this.translationPipe.transform('txt_units_main_balance', this.language, this.ModuleNames.Shared),
          language: this.language, tenant: this.tenant, notification: notification, company: this.selectedEntity, notificationType: notification.notiificationType
        }));
        // this.txt_units_main_balance = this.txt_units_main_balance.replace('#UNITS#', notification.units.toString());
        let confirmationDialog = this.matDialog.open(UnitsNotificationDialogComponent, {
          data: data
          , autoFocus: false,
          panelClass: 'hideInnerContainer'
        });
        this.notificationService.removeNotification(notification.key, this.user).then((_) => {

        });

      } else if (notification.notiificationType == 'UNIT_CREDIT_COMPANY_SURVEY_BALANCE') {
        // let companyName = this.extractNameLanguage(this.companies.find(c => c.key == notification.companyKey).name);
        // let surveyName: string = '';
        // if(notification.surveyName == 'EXIT') {
        //   surveyName = this.txt_Exit_Questionnaire;
        // } else if(notification.surveyName == 'ENGAGEMENT') {
        //   surveyName = this.txt_Employee_Engagement_Survey;
        // } else if(notification.surveyName == 'ASSESSMENT') {
        //   surveyName = this.txt_Employee_Assessment_360_Survey;
        // }
        if (notification?.units) {

        } else {
          notification.units = 0;
        }
        // this.txt_units_survey_balance = this.txt_units_main_balance.replace('#UNITS#', notification.units.toString());
        // this.txt_units_survey_balance = this.txt_units_main_balance.replace('#COMPANYNAME#', companyName);
        // this.txt_units_survey_balance = this.txt_units_main_balance.replace('#SURVEYNAME#', surveyName);
        let data = JSON.parse(JSON.stringify({
          title: this.translationPipe.transform('txt_unitsPopupTile', this.language, this.ModuleNames.Shared), notification: notification, units: notification.units,
          message: this.translationPipe.transform('txt_units_survey_balance', this.language, this.ModuleNames.Shared), language: this.language, tenant: this.tenant,
          companies: this.companies, company: this.selectedEntity,
          notificationType: notification.notiificationType
        }));
        let confirmationDialog = this.matDialog.open(UnitsNotificationDialogComponent, {
          data: data
          , autoFocus: false,
          panelClass: 'hideInnerContainer'
        });
        this.notificationService.removeNotification(notification.key, this.user).then((_) => {

        });

      }
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(2, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  removeNotification(key: string) {
    try {
      this.notificationService.removeNotification(key, this.user).then((_) => {

      });
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(3, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }
}
