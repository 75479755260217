import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Checklist } from 'src/app/core/data-models/checklist';
import { Tenant } from 'src/app/core/data-models/tenant';
import { DashletDataService } from 'src/app/core/services/dashlet-data.service';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { MatDialog } from '@angular/material/dialog';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import * as moment from 'moment';
import { CompaniesListWithSurveysResponseDto, ComparisonService, TimeFrameDto } from '@ex/wellbeing/reports';
import { WellbeingService } from '../../data-access/services/wellbeing.service';
import { ChecklistGroup } from 'src/app/core/data-models/checklist-group';
import { ChecklistItem } from 'src/app/core/data-models/checklist-item';
import { Dashlet } from 'src/app/core/data-models/dashlet';
import { PreviewComponents } from 'src/app/core/data-models/preview-components';
import { CHART_DATA_RESPONSE_STRATEGY } from 'src/app/onboarding/services/chart-data.service';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ExcludedCompanyFilterDto, OverallComparisonDto } from '@ex/wellbeing/analysis';
import { ZenSnackBarTypes, ZenSnackbarService } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { ComparisonExportComponent } from './comparison-export/comparison-export.component';

@Component({
  selector: 'app-wellbeing-comparison',
  templateUrl: './wellbeing-comparison.component.html',
  styleUrls: ['./wellbeing-comparison.component.scss'],
  providers: [WellbeingService]
})
export class WellbeingComparisonComponent extends WbBaseComponent implements OnInit {
  language: string;
  excludedCompanies: ExcludedCompanyFilterDto[] = [];
  selectedEntity;
  ModuleNames = ModuleName;
  minDate: any;
  maxDate: any; load
  timeFrameStart: any;
  timeFrameEnd: any;
  sameYear: boolean = false;
  unixTimeStart: number;
  unixTimeEnd: number;
  checklists: Checklist[] = [];
  tenant: Tenant;
  selectedDriver: string;
  drivers: any[] = [];
  driverList: any[] = [];
  private subscriptions = new Subscription();
  chartColors: any[] = [];
  uniqueCompanies: any[] = [];
  wellbeingData: CompaniesListWithSurveysResponseDto[] = [];
  overAllDashlet: Dashlet;
  overAllDashletData: any;
  @Output() displayLoader = new EventEmitter();
  @Output() hideLoader = new EventEmitter();
  isAveragedResults: boolean = true;

  byDriverDashlet: Dashlet;

  byCompanyDashlet: Dashlet;
  overAllChartType = 'barV';
  overAllUserOverride = false;

  byCompanyChartType = 'line';
  byCompanyUserOverride = false;

  byDriverChartType = 'barV';
  byDriverUserOverride = false;
  exportInitiated: boolean = false;
  export = true;

  companyDashlets: any[] = [];
  preview: PreviewComponents[] = [];

  yearsGrouping: number[] = [];
  sameYearYear: string;
  comparisonByDimension: any[] = [];
  comparisonByDimensionWithoutAverage: any[] = [];
  comparisonByCompany: any[] = [];
  comparisonOverall: any[] = [];
  comparisonOverallWithoutAverage: any[] = [];
  obTermCharts = [];
  dimensionChart = [];
  companyChart = [];
  filters: OverallComparisonDto = { filters: [], selectedCompanies: '', timeframe: {} };
  isStart: boolean = false;

  supportedChartTypes: any[] = [
    {
      DashletType: 'barV',
      name: [
        { key: 'EN', name: 'Bar Horizontal' },
        { key: 'AR', name: 'رسم بياني بالأعمدة (أفقي)' },
      ],
    },
    {
      DashletType: 'line',
      name: [
        { key: 'EN', name: 'Bar Vertical' },
        { key: 'AR', name: 'رسم بياني بالأعمدة (عمودي)' },
      ],
    },
  ];

  dashlet;

  allCompanies = [];
  allDimensions = [];

  settingsLoaded = new Subject();
  settingsLoaded$ = this.settingsLoaded.asObservable();

  comparisonSettingsChanged = new Subject();
  comparisonSettingsChanged$ = this.comparisonSettingsChanged.asObservable();

  timeframeChanged = new BehaviorSubject({ startDate: new Date(new Date().setFullYear(new Date().getFullYear(), 0, 1,)), endDate: new Date() });
  timeframeChanged$ = this.timeframeChanged.asObservable();

  companyChanged = new Subject();
  companyChanged$ = this.companyChanged.asObservable();

  dimensionChanged = new Subject();
  dimensionChanged$ = this.dimensionChanged.asObservable();

  isAveragedChanged = new Subject();
  isAveragedChanged$ = this.isAveragedChanged.asObservable();
  firstLoad: boolean = true;

  constructor(
    private languageService: LanguageService,
    private generalService: GeneralService,
    private changeDetectorRef: ChangeDetectorRef,
    private loader: LoaderService,
    public dialog: MatDialog,
    private comparisonService: ComparisonService,
    private translationPipe: TranslatePipe,
    private zenSnackBarService: ZenSnackbarService
  ) {
    super()
  }
  ngOnInit(): void {

    this.loadComparisonSettings();
    this.loadOverallComparison();
    this.loadOverallComparisonWithoutAverage();

    this.loadComparisonByDimensions();
    this.loadComparisonByDimensionsWithoutAverage();
    this.loadComparisonByCompany();

    this.addSubscription(this.EssentialObject$
      .pipe(
        filter(x => x?.selectedEntity?.key != null),
        distinctUntilChanged((a, b) => a == b, c => c.selectedEntity.key)
      )
      .subscribe(
        (object: EssentialObject) => {
          if (object.language) {
            this.language = object.language;
          }
          this.allCompanies = object.allEntities.filter(x => x.isCompany).map(x => {
            return {
              name: x.name,
              key: x.key
            }
          });

          if (object.tenant) {
            this.tenant = object.tenant;
            this.selectedEntity = object.selectedEntity;

          }

          this.settingsLoaded.next(true);
        }
      ));
  }

  loadComparisonSettings() {
    const subscription = this.settingsLoaded$.pipe(

      switchMap(_ => {
        const filters: any = { filters: [], selectedCompanies: '', timeframe: {} };
        const time: TimeFrameDto = {
          startDate: this.timeFrameStart,
          endDate: this.timeFrameEnd
        }
        filters.timeframe = time;
        filters.tenantKey = this.tenant.key;
        return this.comparisonService.getCompaniesWithSuryes(filters)
      }),

    ).subscribe(response => {
      // TODO: Prepare the data

      this.wellbeingData = response.surveys;

      this.loader.hideLoader();
      if (this.firstLoad) {
        this.getTimeFrame();
        this.allDimensions = response.dimesnions;

        this.selectedDriver = this.allDimensions[0].key;
        this.drivers = this.allDimensions;
        this.driverList = JSON.parse(JSON.stringify(this.drivers));
        this.dimensionChanged.next(this.selectedDriver);

        this.constructCompanySurveyFilters(true);
        this.firstLoad = false;
      }

      this.comparisonSettingsChanged.next(true);
      this.isAveragedChanged.next();
    })

    this.addSubscription(subscription);
  }

  loadOverallComparison() {
    const obs$ = combineLatest([this.comparisonSettingsChanged$, this.timeframeChanged$, this.isAveragedChanged$])

    const subscription = obs$.pipe(
      switchMap(([comparison, timeframe, averaged]) => {
        const filters = this.getFilters();
        filters.tenantKey = this.tenant.key;
        if (this.excludedCompanies != null && this.excludedCompanies.length > 0)
          filters.excludedCompanyFilter = this.excludedCompanies;
        return this.comparisonService.getOverallComparison(filters)
      })
    ).subscribe(g => {

      this.comparisonOverall = g;
      if (this.excludedCompanies == null || this.excludedCompanies.length == 0) {
        this.createOverallChart();
      }
    })

    this.addSubscription(subscription);
  }
  loadOverallComparisonWithoutAverage() {

    const obs$ = combineLatest([this.comparisonSettingsChanged$, this.timeframeChanged$, this.isAveragedChanged$])

    const subscription = obs$.pipe(
      switchMap(([comparison, timeframe, averaged]) => {
        const filters = this.getFilters();
        var lg: any = this.language;

        filters.filters.push({ key: 'Language', value: lg });
        filters.tenantKey = this.tenant.key
        if (this.excludedCompanies != null && this.excludedCompanies.length > 0)
          filters.excludedCompanyFilter = this.excludedCompanies;
        return this.comparisonService.getOverallComparisonWithoutAverage(filters)
      })
    ).subscribe(f => {

      if (this.excludedCompanies != null && this.excludedCompanies.length > 0) {
        this.comparisonOverallWithoutAverage = f;

        this.createOverallChart();
      }
    })

    this.addSubscription(subscription);
  }
  loadComparisonByDimensions() {
    const obs$ = combineLatest([this.comparisonSettingsChanged$, this.timeframeChanged$, this.dimensionChanged$, this.isAveragedChanged$])

    const subscription = obs$.pipe(
      switchMap(([comparison, timeframe, dimension, averaged]) => {

        const filters = this.getFilters()
        filters.dimensionKey = dimension;
        filters.tenantKey = this.tenant.key;
        if (this.excludedCompanies != null && this.excludedCompanies.length > 0)
          filters.excludedCompanyFilter = this.excludedCompanies;
        return this.comparisonService.getOverallComparisonByDimensionKey(filters)
      })
    ).subscribe(res => {
      // TODO: assign this response to the chart
      this.comparisonByDimension = res;
      if (this.excludedCompanies == null || this.excludedCompanies.length == 0) {
        this.createDimensionCharts();
      }
    })

    this.addSubscription(subscription);
  }
  loadComparisonByDimensionsWithoutAverage() {
    const obs$ = combineLatest([this.comparisonSettingsChanged$, this.timeframeChanged$, this.dimensionChanged$, this.isAveragedChanged$])

    const subscription = obs$.pipe(
      switchMap(([comparison, timeframe, dimension, averaged]) => {
        const filters = this.getFilters()
        filters.dimensionKey = dimension;
        var lg: any = this.language;
        filters.filters.push({ key: 'Language', value: lg });
        filters.tenantKey = this.tenant.key;
        if (this.excludedCompanies != null && this.excludedCompanies.length > 0)
          filters.excludedCompanyFilter = this.excludedCompanies;
        return this.comparisonService.getComparisonByDimensionKeyWithoutAverage(filters)
      })
    ).subscribe(res => {
      if (this.excludedCompanies != null && this.excludedCompanies.length > 0) {
        this.comparisonByDimensionWithoutAverage = res;
        this.createDimensionCharts();
      }

    })
    this.addSubscription(subscription);
  }
  loadComparisonByCompany() {
    const obs$ = combineLatest([this.comparisonSettingsChanged$, this.timeframeChanged$, this.isAveragedChanged$])

    const subscription = obs$.pipe(
      switchMap(([comparison, timeframe, avg]) => {
        const filters = this.getFilters()
        filters.tenantKey = this.tenant.key;

        filters.tenantKey = this.tenant.key;
        return this.comparisonService.getOverallComparisonByCompanyKey(filters)
      })
    ).subscribe(res => {

      this.comparisonByCompany = res;
      this.createCompanyCharts();
    })
    this.addSubscription(subscription);

  }
  constructCompanySurveyFilters(isStart?) {
    this.uniqueCompanies = [];
    for (let i = 0; i < this.wellbeingData.length; i++) {
      let data = this.wellbeingData[i];
      if (
        !this.uniqueCompanies.find((uC) => uC.key == data.companyKey)
      ) {
        this.uniqueCompanies.push({
          key: data.companyKey,
          name: [{ key: this.language, name: data.companyName }],
          used: true,
        });
      }
    }
    this.checklists = [];
    for (let i = 0; i < this.uniqueCompanies.length; i++) {
      let checklist = new Checklist();
      let uC = this.uniqueCompanies[i];
      checklist.key = uC.key;
      checklist.name = uC.name;

      let wellbeingsInCompany = this.wellbeingData.filter((e) => {
        if (
          e.companyKey == uC.key
        ) {
          return true;
        }
        return false;
      });
      if (wellbeingsInCompany?.length > 0) {
        for (let x = 0; x < wellbeingsInCompany.length; x++) {
          let wellbeing = wellbeingsInCompany[x];
          let checkListGroup: ChecklistGroup = checklist.checklistGroups.find(
            (cG) => cG.key == wellbeing.year
          );
          if (!checkListGroup) {
            checkListGroup = new ChecklistGroup();
            checkListGroup.key = wellbeing.year;
            checkListGroup.name = [{ key: 'EN', name: wellbeing.year }];
            checklist.checklistGroups.push(checkListGroup);
          } else {
            checkListGroup.isAveraged = true;
          }
          let checklistItem = new ChecklistItem();
          checklistItem.key = wellbeing.surveyKey;
          checklistItem.name = wellbeing.surveyName;
          checklistItem.isChecked = true;
          checkListGroup.checklistItems.push(checklistItem);
        }
        checklist.isChecked = true;
        checklist.checklistGroups.sort((a, b) =>
          Number(a.key) > Number(b.key) ? 1 : -1
        );
        this.checklists.push(checklist);
      } else {
        uC.used = false;
      }
    }

    this.uniqueCompanies = this.uniqueCompanies.filter((uC) => uC.used == true);

    this.calculateDataForCharts();
    if (this.changeDetectorRef) this.changeDetectorRef.detectChanges();
  }
  ValidateStartEndDates(start, end) {

    var error = false;
    var d1 = new Date(start);
    var d2 = new Date(end);
    if (d1 >= d2) {
      error = true;
    }
    return error;
  }
  getExcludedSurveys() {
    let excludedSurveys = [];
    let list = [];
    this.checklists.forEach(element => {
      element.checklistGroups.filter(obj => {
        var filterd = obj.checklistItems.filter(x => !x.isChecked);
        if (filterd.length > 0) {
          filterd.forEach(val => {
            list.push(val);
          });
        }
      });
    });

    excludedSurveys = list.map(x => {
      return {
        key: x.key
      }
    });
    return excludedSurveys;
  }
  checklistChanged(event) {
    this.checklists = event;

    this.uniqueCompanies = [];
    for (let i = 0; i < this.checklists.length; i++) {
      let company = this.checklists[i];
      if (company.isChecked) {
        if (!this.uniqueCompanies.find((uC) => uC.key == company.key)) {
          this.uniqueCompanies.push({ key: company.key, name: company.name, used: true });
        }
      }
    }
    this.comparisonSettingsChanged.next(true);
  }

  startDateChanged(event) {

    this.timeFrameStart = event.toISOString();
    var erorr = this.ValidateStartEndDates(this.timeFrameStart, this.timeFrameEnd);
    if (erorr)
      this.zenSnackBarService.snackBar({
        message:
          this.translationPipe.transform('txt_dates_error', this.language, this.ModuleNames.Wellbeing), type: ZenSnackBarTypes.Error
      })
    else {
      this.settingsLoaded.next(true);
      //   this.timeframeChanged.next({ startDate: this.timeFrameStart, endDate: this.timeFrameEnd });
    }
  }

  endDateChanged(event) {
    this.timeFrameEnd = event.toISOString();
    var erorr = this.ValidateStartEndDates(this.timeFrameStart, this.timeFrameEnd);
    if (erorr)
      this.zenSnackBarService.snackBar({
        message:
          this.translationPipe.transform('txt_dates_error', this.language, this.ModuleNames.Wellbeing), type: ZenSnackBarTypes.Error
      })
    else {
      this.settingsLoaded.next(true);
      //this.timeframeChanged.next({ startDate: this.timeFrameStart, endDate: this.timeFrameEnd });
    }

  }
  countList() {
    let checked = this.checklists.filter((c) => c.isChecked == true);
    let count = 0;
    for (let index = 0; index < checked.length; index++) {
      for (let i = 0; i < checked[index].checklistGroups.length; i++) {
        let currentCount = checked[index].checklistGroups[
          i
        ].checklistItems.filter((c) => c.isChecked == true).length;
        count = count + currentCount;
      }
    }
    return count ? count : 0;
  }

  onIsAverageChanged(event) {
    this.isAveragedChanged.next(event);
  }

  selectedDriverChanged(event) {
    if (event != null && event != '') {
      this.byDriverDashlet.Title = this.getDimensionName(this.selectedDriver);

      this.dimensionChanged.next(event);
      // this.getOverallComparisonByDimension();
    }

  }
  getSelectedCompanies() {
    let selectedCompanies: string = '';
    var checkedList = this.checklists.filter(x => x.isChecked == true);
    checkedList.forEach(x => {
      if (selectedCompanies == '')
        selectedCompanies = x.key;
      else
        selectedCompanies += ',' + x.key;
    });

    return selectedCompanies || '';
  }
  getFilters() {
    let filter: any = { filters: [], selectedCompanies: '', timeframe: {}, tenantKey: this.tenant.key };
    const time: TimeFrameDto = {
      startDate: this.timeFrameStart,
      endDate: this.timeFrameEnd
    }

    filter.selectedCompanies = this.getSelectedCompanies();
    filter.timeframe = time;

    let excluded = this.getExcludedSurveys();
    excluded.forEach(element => {
      filter.filters.push({ key: 'SurveyKey', value: element.key });
    });
    return filter;
  }

  createOverallChart() {

    this.obTermCharts = [];
    let list = this.comparisonOverallWithoutAverage != null && this.comparisonOverallWithoutAverage.length > 0 ?
      Object.assign([], [...this.comparisonOverall, ...this.comparisonOverallWithoutAverage]) : Object.assign([], this.comparisonOverall);
    list.forEach(x => {
      if (x.group.includes(','))
        x.group = this.getCompanyName(x.group.split(',')[0]) + '-' + x.group.split(',')[1];
      else
        x.group = this.getCompanyName(x.group)
    })


    const chart = {
      Title: "Overall",
      DashletType: 'line',
      canGroup: false,
      question: {
        canGroup: true
      },
      DashletChartTypes: [],
      data$: CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(list).process()
    };

    this.obTermCharts.push(chart);
    this.comparisonOverallWithoutAverage = [];
  }
  createDimensionCharts() {
    this.dimensionChart = [];
    let list = this.comparisonByDimensionWithoutAverage != null && this.comparisonByDimensionWithoutAverage.length > 0 ?
      Object.assign([], [...this.comparisonByDimension, ...this.comparisonByDimensionWithoutAverage]) : Object.assign([], this.comparisonByDimension);
    list.forEach(x => {
      if (x.group.includes(','))
        x.group = this.getCompanyName(x.group.split(',')[0]) + '-' + x.group.split(',')[1];
      else
        x.group = this.getCompanyName(x.group)
    })
    const chart = {
      Title: "By Dimension",
      DashletType: 'line',
      canGroup: false,
      question: {
        canGroup: true
      },
      DashletChartTypes: this.supportedChartTypes,
      data$: CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(list).process()
    };

    this.dimensionChart.push(chart);

    this.comparisonByDimensionWithoutAverage = [];
  }
  createCompanyCharts() {
    this.companyChart = [];
    let selectedComapnies = this.getSelectedCompanies();

    let companyKeysList = Object.assign([], [...new Map(this.comparisonByCompany.filter(f => selectedComapnies.includes(f.value2)).map(item =>
      [item['value2'], item])).values()]);

    companyKeysList.forEach(x => {
      let chartType = this.checklists.filter(f => f.key == x.value2)[0].checklistGroups != null &&
        this.checklists.filter(f => f.key == x.value2)[0].checklistGroups.length > 1
        && this.checklists.filter(f => f.key == x.value2)[0].checklistGroups.length > 1 ? 'line' : 'barV';

      let result = Object.assign([], this.comparisonByCompany.filter(f => selectedComapnies.includes(f.value2) && f.value2 == x.value2));

      result.forEach(r => {
        if(r.group== 'overall')
           r.group=  this.translationPipe.transform('txtWorkplaceWellbeingScore', this.language, this.ModuleNames.Wellbeing);

        else
            r.group = this.getDimensionName(r.group);
      })
     var title=this.getCompanyChartTitle(x.value2);

      const chart = {
        Title: this.getCompanyName(x.value2) + ' - ' + title,
        DashletType: chartType,
        canGroup: false,
        question: {
          canGroup: true
        },
        DashletChartTypes: this.supportedChartTypes,
        data$: CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(result).process()
      };
      this.companyChart.push(chart);
    });

  }
  getCompanyChartTitle(companyKey)
  {
    var title='';
    var currCompany=this.checklists.filter(x=> x.key ==companyKey)[0];

    var selectedSurveys=[];
      currCompany.checklistGroups.filter(obj => {
        var filterd = obj.checklistItems.filter(x => x.isChecked);
        if (filterd.length > 0) {
          filterd.forEach(val => {
            selectedSurveys.push(val);
          });
        }
      });

    if(selectedSurveys && selectedSurveys.length ==1)
        title= this.extractNameLanguage(selectedSurveys[0].name);
    else
        title= this.translationPipe.transform('txtAveraged', this.language, this.ModuleNames.Wellbeing);
        return title;
  }
  getExcludedCompanies($event) {

    this.excludedCompanies = $event;
    this.isAveragedChanged.next();
  }

  constructDashlets() {
    let chartType = 'barV';
    if (this.sameYear) {
      chartType = 'barV';
    } else {
      if (this.byDriverUserOverride) chartType = this.byDriverChartType;
      else chartType = 'line';
    }

    let chartType2 = 'barV';
    if (this.sameYear) {
      chartType2 = 'barV';
    } else {
      if (this.overAllUserOverride) chartType2 = this.overAllChartType;
      else chartType2 = 'line';
    }

    this.overAllDashlet = new Dashlet();
    this.overAllDashlet.Title = this.translationPipe.transform('txtWorkplaceWellbeingScore', this.language, this.ModuleNames.Wellbeing)

    this.overAllDashlet.DashletType = chartType2;
    this.overAllDashlet.CategoryAxisTitle = this.translationPipe.transform('txtYear', this.language, this.ModuleNames.Engagement)

    this.overAllDashlet.ValueAxisTitle = this.translationPipe.transform('txtScore', this.language, this.ModuleNames.Engagement) + " (%)"

    this.overAllDashlet.DashletChartTypes = this.supportedChartTypes;
    this.byDriverDashlet = new Dashlet();
    this.byDriverDashlet.DashletType = chartType
    this.byDriverDashlet.Title = this.getDimensionName(this.selectedDriver);// this.translationPipe.transform('txtTransformationalLeadership2', this.language, this.ModuleNames.Engagement)
    this.byDriverDashlet.CategoryAxisTitle = this.translationPipe.transform('txtYear', this.language, this.ModuleNames.Engagement)

    this.byDriverDashlet.ValueAxisTitle = this.translationPipe.transform('txtScore', this.language, this.ModuleNames.Engagement) + " (%)"

    this.byDriverDashlet.DashletChartTypes = this.supportedChartTypes;

    this.byCompanyDashlet = new Dashlet();
    //this.byDriverDashlet.Title = this.getDimensionName(this.selectedDriver);// this.translationPipe.transform('txtTransformationalLeadership2', this.language, this.ModuleNames.Engagement)
    this.byCompanyDashlet.DashletType = chartType;
    this.byCompanyDashlet.CategoryAxisTitle = this.translationPipe.transform('txtYear', this.language, this.ModuleNames.Engagement)

    this.byCompanyDashlet.ValueAxisTitle = this.translationPipe.transform('txtScore', this.language, this.ModuleNames.Engagement) + " (%)"

    this.byCompanyDashlet.DashletChartTypes = this.supportedChartTypes;

  }
  getTimeFrame() {
    var startDates = [];
    var endDates = [];
    this.wellbeingData.forEach(element => {
      startDates.push(new Date(element.startDate));
    });
    this.wellbeingData.forEach(element => {
      endDates.push(new Date(element.endDate));
    });

    this.timeFrameStart = new Date(Math.min.apply(null, startDates)).toISOString();
    this.timeFrameEnd = new Date(Math.max.apply(null, endDates)).toISOString();

    this.timeframeChanged.next({ startDate: this.timeFrameStart, endDate: this.timeFrameEnd });
  }
  calculateDataForCharts() {
    this.yearsGrouping = [];
    let allWellbeings = [];
    for (let i = 0; i < this.uniqueCompanies.length; i++) {
      let uC = this.uniqueCompanies[i];
      let wellbeingInCompany = this.wellbeingData.filter((e) => {
        if (
          e.companyKey == uC.key
        ) {
          return true;
        }
        return false;
      });
      for (let x = 0; x < wellbeingInCompany.length; x++) {
        let eg = wellbeingInCompany[x];
        allWellbeings.push(eg);
      }
    }
    for (let i = 0; i < allWellbeings.length; i++) {
      let wellbeing = allWellbeings[i];
      let engFullYear = wellbeing.year;
      if (!this.yearsGrouping.find((y) => y == engFullYear)) {
        this.yearsGrouping.push(engFullYear);
      }
    }

    if (this.yearsGrouping.length == 1) {
      this.sameYear = true;
      this.sameYearYear = this.yearsGrouping[0].toString();
    } else {
      this.sameYear = false;
    }
    this.constructDashlets();
  }

  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  getCompanyName(key: string) {
    const company = this.allCompanies.find(x => x.key == key);

    return this.ExtractPipe.transform(company?.name || [], this.language) || 'N/A';
  }
  getDimensionName(key: string) {
    const dimension = this.allDimensions.find(x => x.key == key);
    // 
    return this.extractNameLanguage(dimension.name) || 'N/A';
  }
  getDimensionKey(name: any[]) {
    var nameVal = this.extractNameLanguage(name)
    const dimension = this.allDimensions.find(x => this.extractNameLanguageLowerCase(x.name).includes(nameVal.toLowerCase()));

    return dimension.key || 'N/A';
  }
  extractNameLanguageLowerCase(eG: any) {
    var result = this.languageService.extractNameLanguage(eG, this.language);
    return result.toLowerCase();
  }
  openExportPreviewDialog() {
    this.ZenCDKService.openComponent(ComparisonExportComponent, {
      filters: this.getFilters(),
      dimensions: this.allDimensions,
      language: this.language,
      selectedEntity: this.selectedEntity,
      companyChart: this.companyChart,
      timeFrameStart: this.timeFrameStart,
      timeFrameEnd: this.timeFrameEnd,
      obTermCharts: this.obTermCharts,
      allCompanies: this.allCompanies,
      overAllDashlet: this.overAllDashlet
    });
  }
}
