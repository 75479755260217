<canvas class="fixed top-0 left-0 w-screen h-screen overflow-hidden" id="c"></canvas>

<div class=" fixed top-0 left-0 w-screen h-screen flex flex-col z-10 items-center px-5 ">

  <div class="flex flex-row justify-end items-center py-5 w-full top-0 left-0 absolute px-5">
    <div [@child15] appClickOutside (clickOutside)="closeDropdown('showLangage')"
    [ngClass]="(language | language )?'rtl':'ltr'">
    <i (click)="showLangage = !showLangage"
      class="fa-light fa-earth-americas leading-9 w-9 h-9 text-center bg-zen bg-opacity-10 rounded text-zen btn"></i>
    <div class="flex left-0 flex-row justify-end absolute w-full h-0 px-5">
      <div [@fadeUpDown] *ngIf="showLangage"
        class="flex flex-col gap-1 top-22 px-2 py-2 rounded-lg shadow-lg bg-white  mt-5 topArrow relative h-max w-32">
        <ng-container *ngFor="let lang of supportedLanguages">
          <a *ngIf="lang.key != language"
          (click)="selectedLanguageChange(lang.key); closeDropdown('showLangage')"
            class="text-zen text-sm btn rounded-sm cancel py-2 px-2">
            {{lang.name}}</a>
        </ng-container>
      </div>
    </div>
  </div>
  </div>
  <div class="flex flex-col items-center justify-center h-full w-full gap-10">
    <div class="flex flex-row justify-center">
      <img *ngIf="(!logoLoading && tenantKeyExists)" style="width: 300px; " [src]="tenantLogo" />
      <img *ngIf="(!tenantKeyExists)" style="width: 300px;" [src]="logoURL" />
    </div>
    <div class="flex flex-col items-center  rounded-xl p-5 max-w-lg w-full backdrop-filter backdrop-blur bg-white bg-opacity-30">
      <div [@inOutAnimation] class="flex flex-col items-center w-full gap-5" *ngIf="section != 'sent'">
        <span [@inOutAnimation] class="flex flex-col items-center" *ngIf="section == 'login'">
          <h5 class="text-lg text-zen">{{'txtWelcomeAboard'| translate:language:ModuleNames.Common}}</h5>
          <h5 *ngIf="!isThomas" class="text- text-zen font-light">{{'txtwelcomedesc'| translate:language:ModuleNames.Common}}</h5>
          <h5 *ngIf="isThomas" class="text- text-zen font-light">{{'Log in to Thomas-Hub to continue'| translate:language:ModuleNames.Common}}</h5>

        </span>
        <span [@inOutAnimation] class="flex flex-col items-center" *ngIf="section == 'forgot'">
          <h5 class="text-zen text-lg">{{'txtResetyourpassword'| translate:language:ModuleNames.Common}}</h5>
          <h5 class="text-zen text-opacity-80">{{'txtResetPassworddesc'| translate:language:ModuleNames.Common}}</h5>
        </span>

        <mat-form-field [dir]="(language | language )?'rtl':'ltr'" appearance="outline" class="w-full">
          <mat-label class="text-zen">{{'txtUsername'| translate:language:ModuleNames.Common}}</mat-label>
          <input [(ngModel)]="email" matInput>
        </mat-form-field>



        <div class="flex flex-col items-center w-full " [@inOutAnimation] *ngIf="section == 'login'">
          <mat-form-field [dir]="(language | language )?'rtl':'ltr'" appearance="outline" class="w-full -mt-5">
            <mat-label class="text-zen">{{'txtPassword'| translate:language:ModuleNames.Common}}</mat-label>
            <input (keydown.enter)="signIn($event)" matInput [(ngModel)]="password" [type]="hide ? 'password' : 'text'">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'txtHidepassword'| translate:language:ModuleNames.Common"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          <!-- <button (click)="createPlatformUser()">Create User</button> -->
          <a class="text-exp btn mb-5" (click)="forgotPassword()">{{'txtForgotYourPassword'| translate:language:ModuleNames.Common}}</a>

          <a class="btn exp uppercase leading-15 px-5 rounded-lg" (click)="signIn()">{{'txtContinue'| translate:language:ModuleNames.Common}}</a>
        </div>


      </div>
      <div [@inOutAnimation] class="flex flex-col items-center w-full gap-5" *ngIf="section == 'sent'">
          <i class="fal fa-envelope-open text-exp text-7xl"></i>

        <h5 class="text-zen text-lg">{{'txtCheckyourEmail'| translate:language:ModuleNames.Common}}</h5>
        <h5 class="text-zen text-base text-opacity-80 text-justify">{{'txtCheckyourEmaildesc'| translate:language:ModuleNames.Common}}</h5>
          <a class="btn cancel text-lg leading-15 px-5 rounded-lg"
            (click)="section = 'login' ">{{'txtBacktoLogin'| translate:language:ModuleNames.Common}}</a>
      </div>
      <div [@inOutAnimation]  class="flex flex-col items-center w-full gap-5" *ngIf="section == 'forgot'">
        <a class="btn exp uppercase leading-15 px-5 rounded-lg w-1/3 text-center"
          (click)="sendResetPasword()">{{'txtContinue'| translate:language:ModuleNames.Common}}</a>
          <!-- <span class="w-1/3">{{'txt_or'| translate:language:ModuleNames.Common}}</span> -->
        <a class="btn text-sm uppercase  rounded-lg  w-1/3 text-center text-exp"
          (click)="section = 'login' ">{{'txtLogin'| translate:language:ModuleNames.Common}}</a>
    </div>
    </div>
  </div>


</div>
