<div class="flex flex-col items-stretch bg-white rounded" *ngIf="groupedData?.length">
  <h5 class="h-20 leading-20 px-7 text-zen text-lg font-semibold">{{'txtCommentAiChartTitle'|
    translate:language:module}}</h5>
  <div class="flex flex-col items-stretch" #chartContainer>
    <div class="flex flex-col items-stretch -mr-10 -ml-10">
      <!-- Scale -->
      <div class="flex flex-col items-stretch h-20 relative z-10 " sticky
        (sticky)="container.active = $event">
        <div #container
          [ngClass]="{'shadow-2x2 backdrop-filter backdrop-blur-3xl bg-white bg-opacity-10':container.active}"
          class="flex flex-row items-center justify-center h-20  gap-16 uppercase z-50">
          <div [ngClass]=" (language|language)?'flex-row-reverse':'flex-row'"
          class=" flex w-full rounded  justify-between p-5">
          <div *ngFor="let sentiment of sentiments" class="items-center flex flex-row justify-center w-1/3 text-xs">
            <i class="mx-1.5 h-4 w-4 inline-block rounded-full"
              [ngStyle]="{'background-color': getLegendColor(sentiment.key)}">
            </i>
            <h5 class="firstUpper text-zen">{{sentiment.name | translate:language:module}} </h5>
          </div>
        </div>
        </div>
      </div>
    </div>

    <kendo-chart (seriesClick)="onBubbleClick($event)" class="h-99">
      <kendo-chart-axis-defaults [line]="{visible: false}"
        [majorGridLines]="{color: '#ECECEC'}"></kendo-chart-axis-defaults>
      <kendo-chart-y-axis>
        <kendo-chart-y-axis-item [majorUnit]="33" [max]="100" [labels]="{visible: false}">
        </kendo-chart-y-axis-item>
      </kendo-chart-y-axis>
      <kendo-chart-x-axis>
        <kendo-chart-x-axis-item [majorUnit]="33" [max]="100" [labels]="{visible: false}">
        </kendo-chart-x-axis-item>
      </kendo-chart-x-axis>
      <kendo-chart-legend [visible]="false">
      </kendo-chart-legend>
      <kendo-chart-series>
        <kendo-chart-series-item *ngFor="let group of groupedData" [data]="group.data" [name]="group.name" type="bubble"
          [xField]="'x'" [yField]="'y'" [sizeField]="'size'" [opacity]="0.7" [marker]="{ type: 'circle' }"
          [color]="group.color" [opacity]="10">
          <kendo-chart-series-item-labels [content]="labelContent">
          </kendo-chart-series-item-labels>
          <kendo-chart-series-item-tooltip background="#EEF0F3" [border]="{color:white,width:0}">
            <ng-template let-value="value" let-category="category">
              <div class="flex flex-col items-stretch w-42 -ml-3.5 -mr-3.5 gap-2 py-2">
                <div class="flex flex-row items-center justify-between gap-1 px-2">
                  <h2 class="text-zen text-xs font-medium leading-4.5 h-4.5 text-center uppercase truncate">
                    {{category.name | extract:language}}</h2>
                  <div class="flex flex-row items-center justify-end gap-1">
                    <span class="font-normal text-3xs text-zen-lite">({{category.totalMentionPercentage | number:'1.1-1'
                      }} %)</span>
                    <div
                      class="flex flex-row text-zen text-xxs text-center justify-center bg-zen bg-opacity-10 rounded-l-full rounded-r-full px-2">
                      <span class="font-medium">{{category.totalMentionCount }}</span>
                      <!-- <span class="text-opacity-40 leading-3">{{'txtComments2'|
                          translate:language:ModuleNames.Engagement}}</span> -->
                    </div>

                  </div>

                </div>
                <span class="border-t border-zen border-opacity-20"></span>
                <div class="flex flex-col px-2" (click)="logData(category)">
                  <div class="flex flex-row h-4 bg-white w-full text-zen font-medium gap-px">
                    <a *ngIf="category.positiveMentionPercentage > 0"
                      [style.width.%]="category.positiveMentionPercentage"
                      class="btn bg-zen-lmngreen  leading-4 h-4 text-xxs items-center text-center">
                      {{category.positiveMentionPercentage | number:'1.1-1'}}%</a>
                    <a *ngIf="category.negativeMentionPercentage > 0"
                      [style.width.%]="category.negativeMentionPercentage"
                      class="btn bg-zen-watermelon  leading-4 h-4 text-xxs items-center text-center">
                      {{category.negativeMentionPercentage | number:'1.1-1'}}% </a>
                    <a *ngIf="category.neutralMentionPercentage > 0" [style.width.%]="category.neutralMentionPercentage"
                      class="btn bg-zen-titaniumwhite  leading-4 h-4 text-xxs items-center text-center">
                      {{category.neutralMentionPercentage | number:'1.1-1'}}%</a>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-chart-series-item-tooltip>
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
  </div>
</div>
