import { Direction } from "@angular/cdk/bidi";
import { Component, ViewEncapsulation, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoaderService } from "@ex/module/core/services/loader.service";
import { ModuleName } from "@ex/module/shared/enums/ModuleName";
import { LanguageFilterService } from "@ex/module/survey-x/data-access/services/language-filter.service";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { Model } from "survey-core";
import { FormService } from "@ex/survey-x/index";
import { BaseComponent } from "@ex/module/core/domain/base-component";
import { updateBooleanQuestionHTML, updateDateInputQuestionHTML, updateEmailInputQuestionHTML, updateLongTextQuestionHTML, updateNumberInputQuestionHTML, updateShortTextQuestionHTML, updateSliderQuestionHTML } from "../../ui/questions/simple-questions-operations/simple-questions-operations";
import { updateCheckBoxsQuestionHTML, updateRadioGroupQuestionHTML } from "../../ui/questions/multiple-choices-operations/multiple-choices-questions-operations";
import { updateDropdownQuestionHTML, updateTagboxQuestionHTML } from "../../ui/questions/dropdown-operations/dropdown-question-operations";
import { updateFileUploadQuestionHTML } from "../../ui/questions/file-upload-operations/file-upload-question-operations";
import { updateIconChoiceQuestionHTML, updateImagePickerQuestionHTML } from "../../ui/questions/image-choice-operations/image-choice-question-operations";
import { updateMatrixQuestionHTML, updateMatrixCheckboxsQuestionHTML } from "../../ui/questions/matrix-operations/matrix-question-operations";
import { updateENPSQuestionHTML } from "../../ui/questions/nps-operations/nps-questions-operations";
import { updateRankingQuestionHTML } from "../../ui/questions/ranking-operations/ranking-question-operations";
import { updateRatingScaleQuestionHTML } from "../../ui/questions/rating-scale-operations/rating-scale-question-operations";
import { updateRatingStarsQuestionHTML } from "../../ui/questions/start-rating-operations/star-rating-question-operations";
import { filter, map, switchMap } from "rxjs/operators";
import { TranslatePipe } from "@ex/module/shared/pipes/translate.pipe";
import { PageModel, Question } from "survey-angular";
import { surveyLocalization } from "survey-core";
import "survey-core/survey.i18n";


const translationPipe: TranslatePipe = new TranslatePipe();
const arLocale = surveyLocalization.locales["ar"];
arLocale.invalidEmail = "سسسسسسسسس";
var locale;
var ogPages = [];
var ogPagesForExport = [];
var globalExportMode: boolean = false;
var globalSurveyModel: Model;

@Component({
  selector: 'app-survey-preview',
  templateUrl: './survey-preview.component.html',
  styleUrls: ['./survey-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SurveyPreviewComponent extends BaseComponent implements OnInit {

  @Input() exportMode: boolean = false;
  @Input() language = "EN";

  survey: any = {};
  surveyModel: Model;
  form: any;
  sections: any;
  tenant: any;
  tenantKey: any;
  companyName: any;
  currentOptions: any;

  isConfirmSubmissionModalOpen: boolean = false;
  loading: boolean = false;

  subscriptions = new Subscription();
  surveyLanguage !: string;
  localesList: any[] = [{ key: "ar", name: "العربية" }, { key: "default", name: "English" }];
  selectedLocaleFilter = "";
  selectedLocaleFilterInStorage: string | null = "";
  ar = "ar";
  en = "default";
  direction: Direction = 'ltr';
  textPosition = "";
  ModuleNames = ModuleName;
  endPoint = environment.endPoint;
  currentAnswers: any
  currentPage: any;
  pages: any;

  constructor(
    private languageFilterService: LanguageFilterService,
    private surveysService: FormService,
    private route: ActivatedRoute,
    private router: Router,
    public loader: LoaderService,
  ) {
    super()
  }


  async ngOnInit() {
    this.loader.displayLoader();


    globalExportMode = this.exportMode;
    let surveyId = this.route.snapshot.paramMap.get('id');
    if (!this.exportMode) {
      let sessionLanguage = sessionStorage.getItem('sessionLanguage');
      if (sessionLanguage)
        this.language = sessionLanguage;
      else this.language = 'EN';
    }
    if (this.language != sessionStorage.getItem('sessionLanguage'))
      sessionStorage.setItem('sessionLanguage', this.language);

    this.form = await this.surveysService.getFormWithoutResponses(surveyId).toPromise();
    this.survey = this.form.query;
    let model = new Model(this.form.query.jsonText)
    if (model['shuffleSections']) {
      let json = JSON.parse(this.form.query.jsonText)
      json.pages = this.GeneralService.shuffle(json.pages);
      this.form.query.jsonText = JSON.stringify(json);
    }
    if (model['shuffleQuestions']) {
      let json = JSON.parse(this.form.query.jsonText)
      for (let index = 0; index < json.pages.length; index++) {
        json.pages[index] = this.GeneralService.shuffle(json.pages[index])
      }
      this.form.query.jsonText = JSON.stringify(json);
    }

    globalSurveyModel = new Model(this.form.query.jsonText);

    let modelCopy = new Model(this.form.query.jsonText)
    modelCopy.questionsOnPageMode = "standard";
    this.pages = modelCopy.pages;

    this.surveyModel = new Model(this.survey.jsonText);
    this.tenant = this.survey.tenant;
    this.tenantKey = this.survey.tenantKey;
    let companyName = this.survey.companyName ? JSON.parse(this.survey.companyName) : this.survey.tenantName;
    this.companyName = this.ExtractPipe.transform(companyName, this.language);
    locale = this.language.toLowerCase();
    this.changeColor(this.survey.colorTheme);
    this.surveyModel.showCompletedPage = false;
    this.surveyModel.questionsOnPageMode = "standard";
    this.surveyModel.showNavigationButtons = false;
    this.surveyModel.showTitle = false;
    this.surveyModel.logoPosition = "none"
    this.surveyModel.locale = locale;
    this.surveyModel.showPageNumbers = false;
    this.surveyModel.showPageTitles = false;
    this.surveyModel.storeOthersAsComment = false;
    this.surveyModel.questionsOnPageMode = this.survey.questionsOnPageMode;
    this.surveyModel.questionsOnPageMode = this.survey.isQuestionPerPage && !this.exportMode ? "questionPerPage" : "standard";
    // if (this.surveyModel['shuffleQuestions']) {
    //   this.surveyModel.questionsOrder = "random";
    // }
    // if (this.surveyModel['shuffleSections']) {
    //   this.surveyModel.pages.forEach((page) => {
    //     page.questionsOrder = "random";
    //   });
    // }


    console.log(this.surveyModel);
    console.log(this.pages);
    this.setupSurveyModel(this.currentAnswers, this.currentPage, this.pages, this.language.toLowerCase());

    this.surveyModel.onValidatedErrorsOnCurrentPage.add((sender, options) => {
      if (options['errors'][0]) {
        let textError = options['errors'][0]?.text || options['errors'][0]['locTextValue'].text;
        if (textError.slice(0, 1) === '.')
          textError = textError.slice(1);
        if (textError.slice(textError.length - 1, textError.length) === '.')
          textError = textError.slice(0, textError.length - 1);
        if ((locale == 'en' && options['errors'][0]['locTextValue'].locale != 'default') || (options['errors'][0]['locTextValue'].locale != locale))
          return this.SnackbarService.error(this.localize(textError, this.language, this.ModuleNames.SurveyX));
        return this.SnackbarService.error(textError);
      }
    })


    // this.languageFilterService.selectedSurveyLanguage.subscribe((value) => {
    //   this.selectedLocaleFilter = value;
    //   this.survey.locale = value;

    //   this.surveyLanguage = this.selectedLocaleFilter;
    //   this.survey.locale = this.selectedLocaleFilter;

    //   this.textPosition = "left"
    //   if (this.surveyLanguage == "ar") {
    //     this.textPosition = "right"
    //   }
    //   else {
    //     this.textPosition = "left"
    //     this.survey.jsonText = this.survey.jsonText.replace('"locale": "ar",', '"locale": "default",');
    //   }

    //   var currentAnswers = this.surveyModel.data;
    //   var currentPage = this.surveyModel.currentPageNo;
    //   this.setupSurveyModel(currentAnswers, currentPage);
    // });






    if (!this.exportMode) {
      this.changeBackgroundColor("");
    }

    this.loader.hideLoader();
  }

  changeBackgroundColor(color: string) {
    document.documentElement.style.setProperty('--main-bg-color', color);
  }


  replaceQuesiton(exportModel, question, questionType) {
     
    const questionName = question.name;
    const oldQuestion = exportModel.getQuestionByName(questionName);
    const index = oldQuestion.parent.elements.indexOf(oldQuestion);
    let newtype = '';
    if (questionType == 'tagbox') {
      newtype = "checkbox";
    }
    if (questionType == 'dropdown') {
      newtype = 'radiogroup';
    }
    const newQuestion = oldQuestion.parent.addNewQuestion(newtype, questionName, index);
    newQuestion.fromJSON(oldQuestion.toJSON());
    oldQuestion.delete();
  }


  currentQuestion: any;
  currentQuestionOptions: any;
  setupSurveyModel(currentAnswers: any = null, currentPage: any = null, sections: any = null, currentLocale: any = null) {
    const survey = new Model(this.survey.jsonText);


    // survey.locale = this.selectedLocaleFilter

    if (currentAnswers)
      this.surveyModel.data = currentAnswers;

    if (currentPage)
      this.surveyModel.currentPageNo = currentPage;

    this.surveyModel.getAllQuestions().forEach((question) => {

      if (this.exportMode) {
        if (question.visibleIf != '') {
          question.visibleIf = 'true';
        }
        let questionType = question.getType();
        var exportSurveyModel: Model;
        if (questionType == 'dropdown' || questionType == 'tagbox') {
          exportSurveyModel = this.surveyModel;
          this.replaceQuesiton(exportSurveyModel, question, questionType);
        }
      }
    });

    this.surveyModel.locale = currentLocale;

    this.surveyModel.localeChanged()
    let self = this;
    this.surveyModel.onAfterRenderQuestion.add((survey, options) => {

      self.currentQuestionOptions = options;
      self.currentQuestion = options.question;
      self.surveyModel.focusQuestion(self.currentQuestion.name);


      updateQuestionHeaderHTML(options, sections, this.language.toLowerCase());
      updateQuestionBodyHTML(options, this.language.toLowerCase());
      validateCommentBoxLength(options, this.language.toLowerCase());
    });


    this.surveyModel.onComplete.add((sender, options) => {
      console.log(JSON.stringify(sender.data, null, 3));
      this.router.navigate(['/survey-x-questionnaire/survey-finished', this.survey.id], { queryParams: { preview: true } });
    });


    if (this.exportMode) {
      this.surveyModel.questionsOnPageMode = "singlePage";
      return;
    }






  }

  submitResults(sender: any, surveyId: string) {
    this.loader.displayLoader();

    let responseId = window.localStorage.getItem("responseId");

    var arrData: { key: string; value: any; }[] = [];
    Object.keys(sender.data).forEach((itemK) => {
      arrData.push({ key: itemK, value: sender.data[itemK] })
    })

    const results = JSON.stringify(sender.data);
    const resultsPair = JSON.stringify(arrData);

    var answers = {
      jsonText: results, jsonTextPair: resultsPair, formId: window.localStorage.getItem("surveyId"),
      tenantKey: this.tenantKey, tenantName: this.companyName
    }

    if (responseId) {
      this.updateSurveyResults(environment.endPoint + `form-response/update-form-response/${responseId}`, answers, surveyId)
    }
    else {
      this.saveSurveyResults(environment.endPoint + `form-response/add-response`, answers, surveyId)
    }

    window.localStorage.clear()
  }

  onSubmitSurvey() {
    this.isConfirmSubmissionModalOpen = false;

    this.surveyModel.completeLastPage();
  }

  showConfirmSubmissionModal() {
    this.surveyModel.validateCurrentPage();

    if (this.surveyModel.isLastPage && !this.surveyModel.isCurrentPageHasErrors) {
      this.isConfirmSubmissionModalOpen = true;
    }
  }

  changeColor(color: string) {
    document.documentElement.style.setProperty('--main-primary-color', color);
    document.documentElement.style.setProperty('--sjs-primary-backcolor', color);
    document.documentElement.style.setProperty('--primary', color);
    document.documentElement.style.setProperty('--background-dim-light', "#FFF");
  }

  updateSurveyResults(url: string, json: Object, surveyId: string) {
    let responseId = window.localStorage.getItem("responseId");

    const request = new XMLHttpRequest();
    request.open('PUT', url);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.addEventListener('load', () => {
      // Handle "load"
      //console.log('load: ', request.responseText);

      this.loader.hideLoader();
      this.router.navigate(['/surveyx/survey-finished/' + surveyId + '/' + responseId]);
    });
    request.addEventListener('error', () => {
      // Handle "error"
      //console.log('error: ', request.responseText);

      this.loader.hideLoader();
      this.router.navigate(['/surveyx/survey-finished', surveyId]);
    });
    request.send(JSON.stringify(json));
  }

  saveSurveyResults(url: string, json: Object, surveyId: string) {
    const request = new XMLHttpRequest();
    request.open('POST', url);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.addEventListener('load', () => {
      // Handle "load"
      //console.log('load: ', request.responseText);

      this.loader.hideLoader();
      this.router.navigate(['/surveyx/survey-finished', surveyId]);
    });
    request.addEventListener('error', () => {
      // Handle "error"
      //console.log('error: ', request.responseText);

      this.loader.hideLoader();
      this.router.navigate(['/surveyx/survey-finished', surveyId]);
    });
    request.send(JSON.stringify(json));
  }

  onlanguageChanged(event) {
    this.language = event.toUpperCase();
    sessionStorage.setItem('sessionLanguage', this.language);
    locale = this.language.toLowerCase();
    this.surveyModel.locale = event.toLowerCase();
    updateQuestionHeaderHTML(this.currentQuestionOptions, this.pages, event.toLowerCase());
    updateQuestionBodyHTML(this.currentQuestionOptions, event.toLowerCase());

  }


}



// export function getSectionByQuestion(question: any) {
//   var result = { section: null, index: 0 };
//

//   for (var i = 0; i < ogPages.length; i++) {
//     var page = ogPages[i];
//     page.questions.forEach((q) => {
//       if (q.name == question.name) {
//         result.section = page;
//         result.index = i;
//       }
//     });
//   }

//   return result;
// }

export function getSectionByQuestion(question: Question, sections: any, currentLocale: any): { section: any, sectionNumber: number } {
  const section = sections.find(q=> q.jsonObj?.elements?.find(x=> x?.name == question['jsonObj']?.name))
  const result = { section: null, sectionNumber: -1 }; // Initialize with section as null and index as -1
  result.section = question.parent['jsonObj'].title[currentLocale] || question.parent['jsonObj'].title['default'] || question.parent['jsonObj'].title;
  result.sectionNumber = sections.indexOf(section) || 0;
  return result;
}



export function validateCommentBoxLength(options: any, currentLocale) {
  var question = options.question;

  //Validation for comment length (Max 300 characters)
  if (question['hasComment'] || question['showCommentArea']) {
    question.surveyValue.onValidateQuestion.add(function (sender, options) {
      if (options.name == question.name) {
        if (options.question.comment && options.question.comment.length > 300) {
          options.error = translateText("Please enter less than 300 characters in the comment area", currentLocale);
        }
      }
    });
  }

  //Validation for other item length (Max 300 characters)
  if (question['showOtherItem'] || question['hasOther']) {
    question.surveyValue.onValidateQuestion.add(function (sender, options) {
      if (options.name == question.name) {
        var isValueInChoices = false;

        options.question.choices.forEach((choice) => {
          if (choice.value == options.value) {
            isValueInChoices = true;
          }
        });

        if (!isValueInChoices) {
          if (options.question.value[0].length > 300) {
            options.error = translateText("Other item should be less than 300 characters", currentLocale);
          }
        }
      }
    });
  }
}

export function updateQuestionHeaderHTML(options: any, sections: any, currentLocale: any) {
  var question = options.question as Question;

  //Question Header
  var panel = options.htmlElement.offsetParent.querySelector(".sd-element__header.sd-element__header--location-top.sd-panel__header");
  if (panel) {
    panel.innerHTML = "";
  }
  var header = options.htmlElement.querySelector(".sd-element__title.sd-question__title.sd-title");
  if (header == null) {
    header = options.htmlElement.querySelector(".question-header");
    header.innerHTML = "";

    var sectionHeader = options.htmlElement.querySelector(".section-header");
    sectionHeader.innerHTML = "";

    var body = options.htmlElement.querySelector(".input-title");
    if (body)
      body.innerHTML = "";

  }
  header.innerHTML = "";
  header.className = "question-header flex gap-2 mb-3";

  var index = document.createElement("div");
  index.className = "question-index";
  index.innerHTML = translateText("Q", currentLocale) + question["no"].split(".")[0];

  var title = document.createElement("p");
  title.className = "question-title whitespace-normal	break-words";
  title.innerHTML = question.title;

  header.appendChild(index);
  header.appendChild(title);


  //Page Header
  var result = getSectionByQuestion(question, sections, currentLocale);

  if (!sectionHeader) {
    sectionHeader = document.createElement("div");
  }
  sectionHeader.className = "section-header flex gap-3 mb-5";

  var sectionIndex = document.createElement("div");
  sectionIndex.className = "section-index flex gap-1";
  sectionIndex.innerHTML = "<span>" + translateText("Section", currentLocale) + "</span> <span>" + (result.sectionNumber + 1) + "</span>";

  var sectionTitle = document.createElement("div");
  sectionTitle.className = "section-title";
  sectionTitle.innerHTML = result.section;

  sectionHeader.appendChild(sectionIndex);
  sectionHeader.appendChild(sectionTitle);

  //inset before header
  header.parentNode.insertBefore(sectionHeader, header);
}

/**
 * Updates the HTML body of the question based on the provided options.
 * @param options - The options for updating the question HTML body.
 */
export function updateQuestionBodyHTML(options: any, currentLocale) {
  let questionType = options.question.getType();

  if (globalExportMode) {


    if (questionType == 'dropdown') {
      questionType = 'checkbox';
    }


    //  
    //     const questionName = options.question.name;
    //     const oldQuestion = globalSurveyModel.getQuestionByName(questionName);
    //     const index = oldQuestion.parent.elements.indexOf(oldQuestion);
    //     let newtype = "checkbox";
    //     const newQuestion = oldQuestion.parent.addNewQuestion(newtype,questionName,newtype);
    //     newQuestion.fromJSON(oldQuestion.toJSON());
    //     oldQuestion.delete();

  }

  switch (questionType) {
    case 'text':
      handleTextQuestion(options, currentLocale); // Update HTML for text question
      break;

    case 'comment':
      updateLongTextQuestionHTML(options, currentLocale); // Update HTML for comment question
      break;

    case 'radiogroup':
      updateRadioGroupQuestionHTML(options, currentLocale); // Update HTML for radio group question
      break;

    case 'checkbox':
      updateCheckBoxsQuestionHTML(options, currentLocale); // Update HTML for checkbox question
      break;

    case 'dropdown':
      updateDropdownQuestionHTML(options, currentLocale); // Update HTML for dropdown question
      break;

    case 'tagbox':
      updateTagboxQuestionHTML(options, currentLocale); // Update HTML for tagbox question
      break;

    case 'rating':
      handleRatingQuestion(options, currentLocale); // Handle different types of rating questions
      break;

    case 'ranking':
      updateRankingQuestionHTML(options, currentLocale); // Update HTML for ranking question
      break;

    case 'imagepicker':
      if (options.question['isIconChoice'])
        updateIconChoiceQuestionHTML(options, currentLocale); // Update HTML for image picker question with icon choices
      else
        updateImagePickerQuestionHTML(options, currentLocale); // Update HTML for image picker question

      break;

    case 'boolean':
      updateBooleanQuestionHTML(options, currentLocale); // Update HTML for boolean question
      break;

    case 'matrix': // Update HTML for Matrix questions
      if (!options.question['isMultiSelect'])
        updateMatrixQuestionHTML(options, currentLocale);
      else
        updateMatrixCheckboxsQuestionHTML(options, currentLocale);
      break;

    case 'file':
      updateFileUploadQuestionHTML(options, currentLocale); // Update HTML for file upload question
      break;

    case 'datepicker':
      // updateFileUploadQuestionHTML(options); // Update HTML for file upload question
      updateQuestionHeaderHTML(options, "Date Picker", "fa-message-minus");
      break;

    default:
      throw new Error('Invalid question type'); // Throw error for invalid question type
  }
}

/**
 * Handles the HTML update for text-based questions based on the input type.
 * @param options - The options for updating the question HTML body.
 */
function handleTextQuestion(options: any, locale?) {
  const inputType = options.question['inputType'];

  switch (inputType) {
    case 'number':
      updateNumberInputQuestionHTML(options, locale); // Update HTML for number input question
      break;

    case 'email':
      updateEmailInputQuestionHTML(options, locale); // Update HTML for email input question
      break;

    case 'date':
      updateDateInputQuestionHTML(options, locale); // Update HTML for date input question
      break;

    case 'range':
      updateSliderQuestionHTML(options, locale); // Update HTML for range slider question
      break;

    default:
      updateShortTextQuestionHTML(options, locale); // Update HTML for short text question
      break;
  }
}

/**
 * Handles the HTML update for rating questions based on the rate type.
 * @param options - The options for updating the question HTML body.
 */
function handleRatingQuestion(options: any, locale) {
  const rateType = options.question['rateType'];

  switch (rateType) {
    case 'labels':
      updateRatingScaleQuestionHTML(options, locale); // Update HTML for rating scale question with labels
      break;

    case 'enps':
      updateENPSQuestionHTML(options, locale); // Update HTML for eNPS (Employee Net Promoter Score) question
      break;

    case 'nps':
      updateENPSQuestionHTML(options, locale); // Update HTML for NPS (Net Promoter Score) question
      break;

    default:
      updateRatingStarsQuestionHTML(options, locale); // Update HTML for rating stars question
      break;
  }
}
export function translateText(text: string, loc: string) {
  return translationPipe.transform(text, locale.toUpperCase(), "Survey-X");
}
