// import { MatPaginatorIntl } from '@angular/material/paginator';

// export function CustomPaginator() {
//   const customPaginatorIntl = new MatPaginatorIntl();
//   let val = localStorage.getItem('language');
//   var localLanguage = '';
//   if (val)
//     localLanguage = atob(val);
//   else
//     localLanguage = 'EN'
//   if (localLanguage == "AR") {
//     customPaginatorIntl.itemsPerPageLabel = 'عدد المدخلات لكل صفحة:';
//     customPaginatorIntl.nextPageLabel = 'الصفحة التالية';
//     customPaginatorIntl.previousPageLabel = 'الصفحة السابقة';
//     customPaginatorIntl.firstPageLabel = 'الصفحة الأولى';
//     customPaginatorIntl.lastPageLabel = 'الصفحة الأخيرة';
//   }
//   return customPaginatorIntl;
// }

import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { LocalService } from './local.service';

@Injectable()
export class MatPaginatorIntlLocalize extends MatPaginatorIntl {
  constructor(private translationPipe: TranslatePipe, private localService: LocalService) { super() }

  // itemsPerPageLabel = 'عدد المدخلات لكل صفحة:';
  // nextPageLabel = 'الصفحة التالية';
  // previousPageLabel = 'الصفحة السابقة';
  // firstPageLabel = 'الصفحة الأولى';
  // lastPageLabel = 'الصفحة الأخيرة';

  language

  ModuleNames = ModuleName
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    let localLanguage = this.localService.getJsonValue('language');
    let sessionLanguage = this.localService.getJsonValue('language');
    if (!localLanguage && !sessionLanguage) {
      this.language = 'EN';
    }
    else if (localLanguage) {
      this.language = localLanguage
    }
    else if (sessionLanguage) {
      this.language = sessionLanguage;
    }

    if (length === 0 || pageSize === 0) {
      return '0 ' + this.translationPipe.transform('txtof', this.language, this.ModuleNames.Shared) + ' ' + length;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + this.translationPipe.transform('txtof', this.language, this.ModuleNames.Shared) + ' ' + length;
  };
}