<div class="fab-dismiss" *ngIf="fabTogglerState==='active'" (click)="onToggleFab()">
</div>
<div class="fab-container">
    <button class="fab-toggler  rounded-full" (click)="onToggleFab()"
        [ngClass]="{'question-icon':!helpcenter,'x-icon':helpcenter,'pointer-events-none':openTicket}">
        <span *ngIf="!helpcenter" [@fabToggler]="{value:fabTogglerState}" class="text-3xl text-white">
            {{'?' | translate:language:ModuleNames.Psychometric}}</span>
        <i *ngIf="helpcenter" [@fabToggler]="{value:fabTogglerState}"
            class="text-3xl fa-solid fa-times  text-xmark"></i>
    </button>
    <div [@speedDialStagger]="buttons.length">
        <button *ngFor="let button of buttons" (click)="openDialog(button)"
            class="btn white rounded px-4 leading-12 w-max text-sm question-btns">
            <span>{{button.text | translate:language:ModuleNames.Psychometric}}</span>
        </button>
    </div>
</div>
<app-ticket-dialog *ngIf="openTicket" [(open)]="openTicket" [essentialObject]="essentialObject" [type]="ticketType">
</app-ticket-dialog>