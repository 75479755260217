import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { faSortDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { LanguageService } from '../../core/services/language.service';
import { GeneralService } from '../../core/services/general.service';
import { LocalService } from '../../core/services/local.service';
import { ModuleName } from '../enums/ModuleName';

@Component({
  selector: 'custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger, { static: false }) autoTrigger: MatAutocompleteTrigger;
  @ViewChild('autoInputElement', { static: false }) autoInput: ElementRef;
  @Input('dropDownData') dropDownOptions: any[] = [];
  @Input('selectedOptions') selectedList: any[] = [];
  @Input('selectionMode') selectionMode: string = 'Multi';
  @Input('hideClear') hideClear: boolean = false;
  @Input('language') language: string;
  @Input('dark') dark: boolean = false;
  selectedKey: string;
  @Input('selectedKey') set keySelected(key: string) {
    if (key) {
      this.selectedKey = key;
      this.extractSingle();
    } else {
      this.selectedKey = '';
      this.selectedOptions = '';
      this.inputCtrl.setValue('');
    }
  }
  @Input('color') color: string = 'normal';
  @Input('placeholder') placeholder: string = '';
  @Output() selectionChanged = new EventEmitter();
  inputCtrl = new FormControl();
  filteredOptions: Observable<any[]>;
  selectedOptions = '';
  extractionComplete: boolean = false;

  // Font Awesome
  faChevronDown = faSortDown;
  faTimesCircle = faTimesCircle;
  @Input('txtBranches') txtBranches: string;

  constructor(private languageService: LanguageService, private localService: LocalService) { }

  ngOnInit() {
    if (!this.language)
      this.language = this.localService.getJsonValue('language');
    this.filteredOptions = this.inputCtrl.valueChanges
      .pipe(
        startWith(''),
        map(menu => menu ? this._filterMenus(menu) : this.dropDownOptions.slice())
      );
    if (this.selectionMode == 'Multi') {
      this.extractSelectedMenus(true);
      this.extractionComplete = true;
    } else {
      this.extractSingle();
    }
  }
  ModuleNames=ModuleName
  extractSingle() {
    if (this.selectedKey && this.selectedKey != '') {
      let optionName = this.extractNameLanguage(this.dropDownOptions.find(option => option.key == this.selectedKey).name);
      this.selectedOptions = optionName;
      this.inputCtrl.setValue(optionName);
    }
  }

  openPanel(): void {
    const self = this;
    setTimeout(function () {
      self.autoTrigger.openPanel();
    }, 1);
  }


  private _filterMenus(value: string): any[] {
    if (this.selectionMode == 'Multi') {
      let filter = value.replace(this.selectedOptions, '');
      const filterValue = filter.toLowerCase();
      return this.dropDownOptions.filter(option => this.extractNameLanguage(option.name).toLowerCase().indexOf(filterValue) === 0);
    } else {
      const filterValue = value.toLowerCase();
      return this.dropDownOptions.filter(option => this.extractNameLanguage(option.name).toLowerCase().indexOf(filterValue) === 0);
    }
  }

  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }

  // Auto complete drop down check changed
  selectionChange(event) {
    if (this.selectionMode == 'Multi') {
      if (event.option._selected) {
        this.selectedList.push(event.option._value);
      } else {
        let optionRef = this.selectedList.find(option => option == event.option._value);
        if (optionRef) {
          this.selectedList.splice(this.selectedList.indexOf(optionRef), 1);
        }
      }
      this.selectionChanged.emit(this.selectedList);
      this.extractSelectedMenus();
    } else {

      let optionRef = this.dropDownOptions.find(option => option.key == event.option.value);
      this.selectedOptions = this.extractNameLanguage(optionRef.name);
      this.inputCtrl.setValue(this.extractNameLanguage(optionRef.name));
      const self = this;
      self.autoTrigger.closePanel();
      this.autoInput.nativeElement.blur();
      this.selectionChanged.emit(event.option.value);
    }
  }



  extractSelectedMenus(firstLoad?: boolean) {
    if (this.dropDownOptions.length > 0) {
      this.selectedOptions = '';
      if (!this.selectedList)
        this.selectedList = [];
      for (let i = 0; i < this.selectedList?.length; i++) {
        let optionRef = this.selectedList[i];
        let optionName = '';
        if (this.dropDownOptions.find(option => option.key == optionRef)) {

          optionName = this.extractNameLanguage(this.dropDownOptions.find(option => option.key == optionRef).name);

        }
        if (this.selectedOptions == '') {
          this.selectedOptions = optionName;
        } else {
          this.selectedOptions = this.selectedOptions + ', ' + optionName;
        }
        if (i == (this.selectedList.length - 1)) {
          this.selectedOptions = this.selectedOptions + ', '
        }
      }
      this.inputCtrl.setValue(this.selectedOptions);
    }
  }


  autoCompleteChange(event) {
    if (this.inputCtrl.value.length <= this.selectedOptions.length) {
      this.inputCtrl.setValue(this.selectedOptions);
    }
  }

  singleSelectionRestoreSelected($event) {
    setTimeout(() => {
      this.inputCtrl.setValue(this.selectedOptions);
    }, 200);

  }

  clearInput(event) {
    this.inputCtrl.setValue('');
    this.openPanel();
  }

  clearOptions() {
    this.inputCtrl.setValue('');
    if (this.selectionMode == 'Multi') {
      this.selectedList = [];
      this.selectionChanged.emit(this.selectedList);
    } else {
      this.selectedOptions = '';
      this.selectionChanged.emit('');
    }
  }


  restoreSelected(event) {
    setTimeout(() => {
      if (!this.autoTrigger.panelOpen) {

        this.inputCtrl.setValue(this.selectedOptions);
      } else {
        this.autoInput.nativeElement.focus();
      }
    }, 200);

  }

  isSelected(option) {
    for (let i = 0; i < this.selectedList.length; i++) {
      let optionRef = this.selectedList[i];
      if (optionRef == option.key) {
        return true;
      }
    }
    return false;
  }



}
