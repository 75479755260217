import { Component, OnInit, OnDestroy } from '@angular/core';
import { TenantService } from '../../core/services/tenant.service';
import { Tenant } from '../../core/data-models/tenant';
import { LogService } from '../../core/services/log.service';
import { PlatformUserService } from '../../core/services/platform-user.service';
import { PlatformUser } from '../../core/data-models/platform-user';
import { Entity } from '../../core/data-models/Entity';
import { EntityService } from '../../core/services/entity.service';
import { LanguageService } from '../../core/services/language.service';
import { Leaver } from '../../core/data-models/leaver';
import { LeaverService } from '../../core/services/leaver.service';
import { NotificationsService } from '../../core/services/notifications.service';
import { Notification } from '../../core/data-models/notification';
import { Subscription } from 'rxjs';
import { QuestionnaireService } from '../../core/services/questionnaire.service';
import { Questionnaire } from '../../core/data-models/questionnaire';
import { pageAnimation } from '../../shared/animations/allAnimations';
import { UnitBalanceService } from '../../core/services/unit-balance.service';
import { MatDialog } from '@angular/material/dialog';
import { CreditBalanceDialogComponent } from '../../shared/credit-balance-dialog/credit-balance-dialog.component';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { CrashReportingService } from '../../core/services/crash-reporting.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { GeneralService } from '../../core/services/general.service';
import { LocalService } from '../../core/services/local.service';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';

import { ZenSnackbarService, ZenSnackBarTypes } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { EssentialObjectService } from 'src/app/core/services/essential-object.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { products } from 'src/app/core/data-models/company-permission';
import { Router } from '@angular/router';
interface ProductPreview {
  image?: string,
  name: string,
  key: string,
  unitsName: string,
}
interface Product {
  image: string,
  name: string,
  key: string,
  currentBalance: string,
  usedBalance: string,
  unitAddCount: string,
  surveyAddCount: string
}
@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.scss']
})
export class AccountOverviewComponent implements OnInit {
  ModuleNames = ModuleName
  private subscriptions = new Subscription();
  selectedEntity: Entity;
  loader: boolean = false;
  tenantKey: string;
  tenant: Tenant;
  selectedCompany = 0;
  questionnaires: Questionnaire[] = [];
  allQuestionnaires: Questionnaire[] = [];
  // txtEmployeeHappiness: string
  entities: Entity[] = [];
  companyEntities: Entity[] = [];
  companies: Entity[] = [];
  user: PlatformUser;
  users: PlatformUser[] = [];
  pageID: number;

  leavers: Leaver[] = [];
  activeLeavers: Leaver[] = [];
  expiredLeavers: Leaver[] = [];
  inactiveLeavers: Leaver[] = [];
  notifications: Notification[] = [];
  selectedDrop = 0;
  userSubscription: Subscription;
  tenantUsersSubscription: Subscription;
  tenantSubscription: Subscription;
  questionnairesSubscription: Subscription;
  leaversSubscription: Subscription;
  allEntitiesSubscription: Subscription;
  notificationsSubscription: Subscription;
  historyModal = false;
  essentialsObject: any;
  entityAccountBalances: any;
  selected: any;
  supportedLanguages: any[] = [{ key: 'EN', name: 'English' }, { key: 'AR', name: 'Arabic' }];
  language: string = 'EN';
  surveyUnits: any;
  dummyTable = [' ', ' ', ' '];
  options: TableOptions;
  dummyColumns = [{
    field: 'first',
    title: '',
  },
  {
    field: 'second',
    title: '',
  },
  {
    field: 'third',
    title: '',
  }];
  constructor(private tenantService: TenantService, private logService: LogService, public matConfirmDialog: MatDialog, private zenSnackBarService: ZenSnackbarService,
    private platformUserService: PlatformUserService, private entitiesService: EntityService, public loaderService: LoaderService,
    private languageService: LanguageService, private leaverService: LeaverService, public essentialObjectService: EssentialObjectService,
    private notificationsService: NotificationsService, private questionnaireService: QuestionnaireService,
    private unitBalanceService: UnitBalanceService, public matDDialog: MatDialog, private globals: GlobalsService,
    private errorHandlerService: ErrorHandlerService, private crashReportingService: CrashReportingService
    , private authenticationService: AuthenticationService, private localService: LocalService, private translationPipe: TranslatePipe, private generalService: GeneralService, private router: Router) {
    this.colors = globals.basicColors;
    this.loaderService.displayLoader();
  }
  colors;
  ngOnInit(): void {
    this.authenticationService.getTimoutAndEvaluate();
    try {
      this.subscriptions.add(this.essentialObjectService.essentialObject
        .subscribe(
          async (object: any) => {
            if (object.language)
              this.language = object.language;

            if (object.user) {
              this.user = object.user;
              let permissionResponse: any = await this.platformUserService
                .fetchUserPermissions(object.user)
                .toPromise();
              if (permissionResponse.success == true) {
                let hasPermission = this.generalService.hasPermission(
                  object.selectedEntity.key,
                  products.Exit,
                  permissionResponse?.Data,
                  permissionResponse?.Data == 'Super Admin'
                );
                if (hasPermission) {
                  this.generalService.resolvePermissionAlert(this.language);
                } else {
                  let route = this.generalService.resolveRoute(
                    object.selectedEntity.key,
                    permissionResponse?.Data
                  );
                  this.router.navigateByUrl(route);
                }
              } else {
                this.router.navigateByUrl('/login');
              }
            }
            if (object.tenant)
              this.tenant = object.tenant;

            if (object.selectedEntity) {

              this.selectedEntity = object.selectedEntity;
              this.fetchTenant();
              this.changeSelected(this.selectedEntity.key);
            }
          }
        ));


      this.subscriptions.add(this.unitBalanceService.fetchSurveys().subscribe((surveys: any) => {
        // if (this.creditType == 'EXIT')
        //   this.multiplier = (surveys.Exit);
        // if (this.creditType == 'ENGAGEMENT')
        //   this.multiplier = (surveys.Engagement);
        // if (this.creditType == 'ASSESSMENT')
        //   this.multiplier = (surveys.Assessment360);
        this.surveyUnits = surveys;
        // this.units = this.data.minUnits;
        // this.unitsChanged('');
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1601, ex.toString(), 'NOT YET INTIATED', 'NOT YET INTIATED', 'NOT YET INTIATED',
        'NOT YET INTIATED', 'NOT YET INTIATED');
    }

    this.globals.innerRoute = true;
  }

  productsPreview: ProductPreview[] = [
    { key: 'Onboarding', name: 'txtOnBoarding', unitsName: 'onboardingBalance', image: '/assets/img/sub/onboarding.svg' },
    { key: 'Engagement', name: 'txtEngagement', unitsName: 'engagementBalance', image: '/assets/img/sub/Engagement.svg' },
    { key: 'Happiness', name: 'txtEmployeeHappiness', unitsName: 'happinessBalance', image: '/assets/img/sub/Happiness.svg' },
    { key: 'Wellbeing', name: 'txtWellbeing', unitsName: 'wellbeingBalance', image: '/assets/img/sub/Wellbeing.svg' },
    { key: 'Assessment', name: 'txt360DegreeFeedback', unitsName: 'assessmentBalance', image: '/assets/img/sub/360.svg' },
    { key: 'Assessment180', name: 'txt180FeedbackAssessment', unitsName: 'assessment180Balance', image: '/assets/img/sub/180.svg' },
    { key: 'Exit', name: 'txt_employee_exit', unitsName: 'exitBalance', image: '/assets/img/sub/Exit.svg' },
  ];
  productTable: Product[] = [
    { key: 'Onboarding', name: 'txtOnBoarding', image: '/assets/img/sub/onboarding.svg', currentBalance: 'OnboardingAllocation', usedBalance: 'onboardingUsedUnits', unitAddCount: 'onboardingUnitAddCount', surveyAddCount: 'onboardingSurveyAddCount' },
    { key: 'Engagement', name: 'txtEngagement', image: '/assets/img/sub/Engagement.svg', currentBalance: 'EngagementAllocation', usedBalance: 'engagementUsedUnits', unitAddCount: 'engUnitAddCount', surveyAddCount: 'engSurveyAddCount' },
    { key: 'Happiness', name: 'txtEmployeeHappiness', image: '/assets/img/sub/Happiness.svg', currentBalance: 'HappinessAllocation', usedBalance: 'happinessUsedUnits', unitAddCount: 'hapSurveyAddCount', surveyAddCount: 'hapSurveyAddCount' },
    { key: 'Wellbeing', name: 'txtWellbeing', image: '/assets/img/sub/Wellbeing.svg', currentBalance: 'WellBeingAllocation', usedBalance: 'wellbeingUsedUnits', unitAddCount: 'wellbeingUnitAddCount', surveyAddCount: 'wellbeingSurveyAddCount' },
    { key: 'Assessment', name: 'txt360DegreeFeedback', image: '/assets/img/sub/360.svg', currentBalance: 'AssessmentAllocation', usedBalance: 'assessmentUsedUnits', unitAddCount: 'assessUnitAddCount', surveyAddCount: 'assessSurveyAddCount' },
    { key: 'Assessment180', name: 'txt180FeedbackAssessment', image: '/assets/img/sub/180.svg', currentBalance: 'Assessment180Allocation', usedBalance: 'assessment180UsedUnits', unitAddCount: 'assess180UnitAddCount', surveyAddCount: 'assess180SurveyAddCount' },
    { key: 'Exit', name: 'txt_employee_exit', image: '/assets/img/sub/Exit.svg', currentBalance: 'ExitAllocation', usedBalance: 'exitUsedUnits', unitAddCount: 'exitUnitAddCount', surveyAddCount: 'exitSurveyAddCount' },
  ];

  products: any[] = [
    {}
  ];
  // txtAccountOverview: string;
  // txt_companies: string;
  // txtEngagement: string;
  // txt_units: string;
  // txtAssessmentSurvey: string;
  // txt_employee_exit: string;
  // txtof: string;
  // txtAccountOverviewDesc: string;
  // txtRemainingBalance: string;
  // btnRequestUnits: string;
  // txtUnitsperModule: string;
  // txtCurrentunits: string;
  // txtAllocatedUnits: string;
  // btn_add: string;
  // txtLoader: string;
  // txt_survey: string;
  // noUnits: string;
  // txtClose: string;
  // txt_unit: string;
  // txtOverallUNITSBalance: string;
  // txtUNITSPERPRODUCT: string;
  // txtProductName: string;
  // txtRemainingUnits: string;
  // txtTotalUnits: string;
  // txtUsedUnits: string;
  // txtExpiryDate: string;
  // txtAssignUnits: string;
  // txtAssign: string;
  // txtPurchaseHistory: string;
  // txt_company_name: string;
  // txtProduct: string;
  // txtDateofPurchase: string;
  // txtPurchasedUnits: string;
  historyType: string;
  historyProductName: string;
  selectedProduct;
  // txtCurrentBalance: string;
  // txtAllocationHistory: string;
  // txtAllocationDate: string;
  // txtAccountManager: string;

  historyToDisplay: any[] = [];
  changeSelected(key) {
    this.selected = this.entityAccountBalances?.entities.find(k => k.key == key);
    if (this.selected?.key) {
      this.loaderService.hideLoader();
    }
  }

  getReadableDate(date: number) {
    if (date == -1)
      return this.translationPipe.transform("txtNoExpiry", this.language, this.ModuleNames.Common);

    let theDate = new Date(date * 1000);
    return theDate.getDate() + '/' + (theDate.getMonth() + 1) + '/' + theDate.getFullYear();
  }

  getProductSrc() {
    if (this.historyProductName == 'Exit') {
      return '/assets/img/sub/Exit.svg';
    } else if (this.historyProductName == 'Engagement') {
      return '/assets/img/sub/Engagement.svg';
    } else if (this.historyProductName == 'Assessment') {
      return '/assets/img/sub/360.svg';
    } else if (this.historyProductName == 'Happiness') {
      return '/assets/img/sub/Happiness.svg';
    } else if (this.historyProductName == 'Assessment180') {
      return '/assets/img/sub/180.svg';
    } else if (this.historyProductName == 'Onboarding') {
      return '/assets/img/sub/onboarding.svg';
    }


  }


  purchaseHistory(historyType: string, product: any) {
    this.selectedProduct = product;
    this.historyType = historyType;
    this.historyProductName = product.key;
    if (historyType == 'Entity') {
      this.historyToDisplay = this.selected.balanceDistribution[product.key.toLowerCase() + 'transactionHistory'];
    } else {
      this.historyToDisplay = this.entityAccountBalances[product.key.toLowerCase() + 'transactionHistory'];
    }
    this.dummyColumns = [
      {
        field: 'first',
        title: this.translationPipe.transform('txtPurchasedUnits', this.language, this.ModuleNames.Common)
      },
      {
        field: 'second',
        title: this.translationPipe.transform((this.historyType === 'Entity' ? "txtAllocationDate" :
          "txtDateofPurchase"), this.language, this.ModuleNames.Common)
      },
      {
        field: 'third',
        title: this.translationPipe.transform('txtExpiryDate', this.language, this.ModuleNames.Common)
      },
      {
        field: 'fourth',
        title: ''
      },
    ]
    this.historyModal = true;

    this.options = {
      data: this.historyToDisplay,
      columns: this.dummyColumns,
      selectionKey: '',
      colors: this.colors,
      searchText: this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Common),
      whiteBackground: true
    }

  }





  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  fetchPlatformUser() {
    try {
      this.logService.submitLog(0, 'HomeComponent.fetchPlatformUser()', 'Task Assigned', {});
      this.subscriptions.add(this.platformUserService.fetchPlatformUser(this.localService.getJsonValue('UserKey')).subscribe((user: PlatformUser) => {
        this.logService.submitLog(0, 'HomeComponent.fetchPlatformUser()', 'Task Completed', { user });
        this.user = user;
        if (!this.tenant.key) {
          this.tenant.key = this.user.tenantKey;
          this.fetchTenant();
          // this.platformUserService.fetchRelease();
        }
        //this.saveUserPreferredLanguage();

      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1602, ex.toString(), 'NOT YET INTIATED', 'NOT YET INTIATED', 'NOT YET INTIATED',
        'NOT YET INTIATED', 'NOT YET INTIATED');
    }
  }

  fetchAllTenantUsers() {
    try {

      this.subscriptions.add(this.platformUserService.fetchAllTenantUsers(this.tenant.key).subscribe((users: PlatformUser[]) => {
        this.users = users;
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1604, ex.toString(), this.tenant.key, this.tenant.name, 'NOT YET INTIATED',
        'NOT YET INTIATED', this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  fetchTenant() {
    try {
      this.crashReportingService.submitLogCode(14);
      this.tenant.key = this.languageService.getTenant(this.tenant.key);


      this.logService.submitLog(0, 'HomeComponent.fetchTenant()', 'Task Assigned', {});
      this.subscriptions.add(this.tenantService.fetchTenant(this.tenant.key).subscribe((tenant: Tenant) => {
        if (tenant) {
          this.tenant = tenant;
          if (this.user.role == 'Super Admin') {
            this.fetchAllTenantUsers();
          }
          this.evaluateTenant();
          this.logService.submitLog(0, 'HomeComponent.fetchTenant()', 'Task Completed', { tenant });
        } else {
          this.logService.submitLog(0, 'HomeComponent.fetchTenant()', 'Task Failed', {});
        }
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1603, ex.toString(), 'NOT YET INTIATED', 'NOT YET INTIATED',
        'NOT YET INTIATED', 'NOT YET INTIATED', this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  fetchQuestionnaires() {
    try {

      //this.subscriptions.add( this.questionnaireService.fetchQuestionnaires().subscribe((questionnaires: Questionnaire[]) => {

      //   this.questionnaires = questionnaires;

      // }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1605, ex.toString(), this.tenant.key, this.tenant.name, 'NOT YET INTIATED',
        'NOT YET INTIATED', this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  evaluateTenant() {
    if (!this.tenant.setup) {
      this.tenantSetup();
    } else {
      this.moveToDashboard();
      this.fetchQuestionnaires();
      this.fetchAllQuestionnaires();
      this.fetchAllEntities();
    }
  }

  fetchAllQuestionnaires() {
    try {
      // this.questionnaireService.fetchAllQuestionnaires().subscribe((allQuestionnaires: Questionnaire[]) => {
      //   this.allQuestionnaires = allQuestionnaires;
      // });
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1606, ex.toString(), this.tenant.key, this.tenant.name, 'NOT YET INTIATED',
        'NOT YET INTIATED', this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  tenantSetup() {
    this.pageID = -1;
    //
  }

  moveToDashboard() {
    this.pageID = 0;

  }

  entitySetupCompleted() {
    window.location.reload();
  }


  selectedLanguageChanged(event) {
    this.language = event;
    this.localService.setJsonValue('language', this.language);

    this.languageService.language = this.language;
    this.saveUserPreferredLanguage(JSON.parse(JSON.stringify(this.user)));
    this.activeLeavers = JSON.parse(JSON.stringify(this.activeLeavers));
    this.expiredLeavers = JSON.parse(JSON.stringify(this.expiredLeavers));
    this.inactiveLeavers = JSON.parse(JSON.stringify(this.inactiveLeavers));
    this.essentialsObject = {
      leavers: this.leavers, activeLeavers: this.activeLeavers, inactiveLeavers: this.inactiveLeavers,
      expiredLeavers: this.expiredLeavers, language: this.language, selectedEntity: this.selectedEntity
    };
    this.essentialsObject = JSON.parse(JSON.stringify(this.essentialsObject));
  }

  selectedEntityChanged(selectedEntity) {
    this.selectedEntity = selectedEntity;
    this.selectedEntity = JSON.parse(JSON.stringify(this.selectedEntity));
    let localStorageEntity = { entityKey: this.selectedEntity.key, userKey: this.user.key, tenantKey: this.tenant?.key };

  }

  // SECTION FOR APP SHARED PROPERTIES
  fetchAllEntities() {
    try {

      this.logService.submitLog(0, 'HomeComponent.fetchAllEntities()', 'Task Assigned', {});
      this.subscriptions.add(this.entitiesService.fetchEntities(this.tenant.key).subscribe((entities: Entity[]) => {
        if (entities) {
          this.logService.submitLog(0, 'HomeComponent.fetchAllEntities()', 'Task Completed', { entities });
          if (this.user.role == 'Super Admin') {
            this.entities = entities;
          } else {
            for (let i = 0; i < entities.length; i++) {
              let entity = entities[i];
              if (this.user.EntityReferences.find(eR => eR == entity.key)) {
                this.entities.push(entity);
              } else {
                if (!entity.isCompany)
                  this.entities.push(entity);
              }
            }
          }
          if (!this.selectedEntity) {

            let localEntity: any = JSON.parse(this.localService.getJsonValue('selectedEntity'));
            if (localEntity) {
              if (localEntity.userKey != this.user.key) {

                localEntity = null;
              }
            }
            if (!localEntity) {
              if (this.entities.length > 0) {
                let companyEntity = this.entities.find(e => e.isCompany == true);
                this.selectedEntity = companyEntity;
                let localStorageEntity = { entityKey: this.selectedEntity.key, userKey: this.user.key, tenantKey: this.tenant?.key };


              }
            } else {
              if (this.entities.length > 0) {
                this.selectedEntity = this.entities.find(et => et.key == localEntity.entityKey);
                if (!this.selectedEntity) {
                  if (this.entities.length > 0) {
                    let companyEntity = this.entities.find(e => e.isCompany == true);
                    this.selectedEntity = companyEntity;
                    let localStorageEntity = { entityKey: this.selectedEntity.key, userKey: this.user.key, tenantKey: this.tenant?.key };



                  }
                }
              }
            }
          } else {

            this.selectedEntity = this.entities.find(e => e.key == this.selectedEntity.key);
          }
          this.companyEntities = this.entitiesService.structureEntities(this.entities, this.selectedEntity.key);
          this.companies = this.entities.filter(e => e.isCompany == true);
          this.fetchTenantAccountBalances();
          this.subscribeToTenant();
        } else {
          this.logService.submitLog(0, 'HomeComponent.fetchAllEntities()', 'Task Failed', {});
        }
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1607, ex.toString(), this.tenant.key, this.tenant.name, 'NOT YET INTIATED',
        'NOT YET INTIATED', this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  subscribeToTenant() {
    try {
      this.subscriptions.add(this.tenantService.fetchTenantForAccOverview(this.tenant.key).subscribe((tenant: Tenant) => {
        if (tenant) {
          this.fetchTenantAccountBalances();
        }
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1609, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  fetchTenantAccountBalances() {
    try {
      let request = { tenantKey: this.tenant.key };
      this.subscriptions.add(this.unitBalanceService.fetchTenantCompanyBalance(request).subscribe((response: any) => {
        if (response.response == 'SUCCESS') {
          this.loader = false;
          this.entityAccountBalances = response.data;
          this.entityAccountBalances.entities = this.entityAccountBalances.entities.filter(e => {
            this.changeSelected(this.selectedEntity.key);
            if (this.companies.find(c => c.key == e.key))
              return true;

            return false;
          });
          if (this.entityAccountBalances.entities.length > 0)
            this.entityAccountBalances.entities = JSON.parse(JSON.stringify(this.entityAccountBalances.entities));
          if (this.selectedDrop == 0) {
            this.selectedDrop = 1
          }
        }
        this.changeSelected(this.selectedEntity.key);
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1608, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  requestMoreUnits(survey) {
    try {
      let request = { tenantKey: this.tenant.key };
      this.subscriptions.add(this.unitBalanceService.fetchTenantCompanyBalance(request).subscribe((response: any) => {
        if (response.response == 'SUCCESS') {
          this.entityAccountBalances = response.data;
          let balance: number = 0;
          if (survey == 'EXIT') {
            balance = this.entityAccountBalances.exitBalance;
            this.crashReportingService.submitLogCode(16);
          } else if (survey == 'ENGAGEMENT') {
            balance = this.entityAccountBalances.engagementBalance;
            this.crashReportingService.submitLogCode(17);
          } else if (survey == 'ASSESSMENT') {
            balance = this.entityAccountBalances.assessmentBalance;
            this.crashReportingService.submitLogCode(18);
          }
          const searchDialogRef = this.matDDialog.open(CreditBalanceDialogComponent, {
            data: {
              tenantKey: this.tenant.key, entity: this.selectedEntity, creditType: survey, language: this.language, currentBalance: this.entityAccountBalances
              , user: this.user, tenant: this.tenant, submittedFrom: 'ACCOUNTOVERVIEW', requestType: 'MAINREQUEST'
            }, autoFocus: false,
          });

        }
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1610, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  async addUnits(entity: Entity, unitsType: string, units: number, x?, y?) {
    try {
      if (units < 1)
        return this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("noUnits", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
      let allocationConfirmation: boolean = false;
      if (this.user?.doNotShowAllocationConfirmation) {
        allocationConfirmation = true;
      } else {
        allocationConfirmation = await this.confirmAllocation(units);
      }

      if (allocationConfirmation) {
        let request = {
          entityKey: entity.key, entity: entity, user: this.user, tenantKey: this.tenant.key,
          creditType: unitsType.toUpperCase(), creditUnits: units
        };
        this.loader = true;
        this.subscriptions.add(this.unitBalanceService.distributeUnitsToCompany(request).subscribe((response: any) => {
          if (response.response == 'SUCCESS') {
            this.crashReportingService.submitLogCode(15);
            this.fetchTenantAccountBalances();
            units = 0;
          } else {
            if (response.message == 'Insufficient Credits') {
              const searchDialogRef = this.matDDialog.open(CreditBalanceDialogComponent, {
                data: {
                  tenantKey: this.tenant.key, entity: this.selectedEntity, creditType: 'MAINBALANCE', language: this.language, currentBalance: response.mainBalance
                  , user: this.user, tenant: this.tenant, submittedFrom: 'ACCOUNTOVERVIEW'
                }, autoFocus: false,
              });
              this.loader = false;
            }
          }
          x = null;
          y = null;
        }));
      }
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1611, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  async confirmAllocation(units): Promise<boolean> {
    try {
      let message = this.translationPipe.transform("txt_unit_allocation_confirmation", this.language, this.ModuleNames.Common);
      let subject = this.translationPipe.transform("txt_allocation_confirmation", this.language, this.ModuleNames.Common);
      let actionButtonText = this.translationPipe.transform("txt_okay_action", this.language, this.ModuleNames.Common);
      let cancelButtonText = this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common);
      message = message.replace('#UNITS#', units);
      let confirmationDialog = this.matConfirmDialog.open(ConfirmationDialogComponent, {
        data:
          { message: message, subject: subject, dialogType: 'info-with-check', checkboxText: "Don't show this message", actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false
      });
      let res: boolean = false;
      this.subscriptions.add(confirmationDialog.afterClosed().subscribe((result) => {
        if (result) {
          if (result.reply == 'YES') {
            if (result.isChecked) {
              this.user.doNotShowAllocationConfirmation = true;
              this.platformUserService.updatePlatformUser(this.user).then((_) => {
              }).catch((error) => {

              });
            }
            res = true;
          } else {
            res = false;
          }
        } else {
          res = false;
        }
      }));
      await confirmationDialog.afterClosed().toPromise();
      return res;
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1612, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }




  calculateSurveyCount(creditType, bindTo, count, entity) {
    if (creditType == 'EXIT') {
      if (count) {

        entity.balanceDistribution.exitSurveyAddCount = Number((count / this.surveyUnits.Exit).toFixed(0));
      } else {
        entity.balanceDistribution.exitSurveyAddCount = null;
      }
    }

    if (creditType == 'ENGAGEMENT') {
      if (count) {
        entity.balanceDistribution.engSurveyAddCount = Number((count / this.surveyUnits.Engagement).toFixed(0));
      } else {
        entity.balanceDistribution.engSurveyAddCount = null;
      }
    }

    if (creditType == 'ASSESSMENT') {
      if (count) {
        entity.balanceDistribution.assessSurveyAddCount = Number((count / this.surveyUnits.Assessment360).toFixed(0));
      } else {
        entity.balanceDistribution.assessSurveyAddCount = null;
      }
    }


  }

  calculateUnitCount(creditType, bindTo, count, entity) {
    if (creditType == 'EXIT') {
      if (count) {

        entity.balanceDistribution.exitUnitAddCount = Number((count * this.surveyUnits.Exit).toFixed(0));
      } else {
        entity.balanceDistribution.exitUnitAddCount = null;
      }
    }

    if (creditType == 'ENGAGEMENT') {
      if (count) {
        entity.balanceDistribution.engUnitAddCount = Number((count * this.surveyUnits.Engagement).toFixed(0));
      } else {
        entity.balanceDistribution.engUnitAddCount = null;
      }
    }

    if (creditType == 'ASSESSMENT') {
      if (count) {
        entity.balanceDistribution.assessUnitAddCount = Number((count * this.surveyUnits.Assessment360).toFixed(0));
      } else {
        entity.balanceDistribution.assessUnitAddCount = null;
      }
    }
  }

  increase(value, stop?) {

    value += value;

  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }

  saveUserPreferredLanguage(user: PlatformUser) {

    // this.user.preferredLanguage = this.language;
    user.selectedLang = this.language;
    this.platformUserService.updatePlatformUser(user).then((_) => {
    }).catch((error) => {

    });
  }


  setSelectedPage(pageID: number) {
    this.pageID = pageID;
  }



  fetchNotifications() {

    this.subscriptions.add(this.notificationsService.fetchUserNotifications(this.user).subscribe((notificatins: Notification[]) => {
      this.notifications = notificatins;

    }));
  }

  close() {
    this.historyModal = false;
  }

}
