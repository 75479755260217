<div *ngIf="model && adorner && (isGhost === undefined || isGhost === false)" class="svc-page__content"
    [class]="adorner.css" [key2click] (click)="adorner.select(adorner, $event)"
    (mouseover)="adorner.hover($event, $event.currentTarget)" (mouseleave)="adorner.hover($event, $event.target)"
    [id]="adorner.page.id" data-bind="clickBubble: false">

    <div class="svc-page__content-actions">
      <sv-action-bar [model]="adorner.actionContainer"></sv-action-bar>
  </div>


    <page [model]="model" [survey]="survey"></page>

    <sv-action-bar [model]="adorner.footerActionsBar"></sv-action-bar>
</div>
