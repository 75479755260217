import { Direction } from "@angular/cdk/bidi";
import { DatePipe, formatPercent } from "@angular/common";
import { Component, OnInit, AfterContentChecked, Inject, LOCALE_ID, ChangeDetectorRef, Input } from "@angular/core";
import { Router, ActivatedRoute, RouterOutlet } from "@angular/router";
import { EngagementRespondent } from "@ex/module/core/data-models/engagement-respondent";
import { TableOptions } from "@ex/module/core/data-models/table-options";
import { BaseComponent } from "@ex/module/core/domain/base-component";
import { EssentialObjectService } from "@ex/module/core/services/essential-object.service";
import { Status } from "@ex/module/core/services/global-status.service";
import { GlobalsService } from "@ex/module/core/services/globals.service";
import { LanguageService } from "@ex/module/core/services/language.service";
import { LoaderService } from "@ex/module/core/services/loader.service";
import { LocalService } from "@ex/module/core/services/local.service";
import { ZenCDKService } from "@ex/module/shared/Theme/services/zen-cdk.service";
import { ChildrenAnimation, routeInnerAnimations, listAnimation, fadeUpDown } from "@ex/module/shared/animations/allAnimations";
import { ModuleName } from "@ex/module/shared/enums/ModuleName";
import { RespondentDto } from "@ex/module/survey-x/data-access/models/models";
import { RespondentTableFetchObject, RespondentsTableService } from "@ex/module/survey-x/data-access/services/respondents-table.service";
import { RespondentsService } from "@ex/module/survey-x/data-access/services/respondents.service";
import { SharedService } from "@ex/module/survey-x/data-access/services/shared-service.service";
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { ShareSurveyComponent } from "@ex/module/survey-x/ui/library/share-survey/share-survey.component";
import { ReminderTemplateComponent } from "@ex/module/survey-x/ui/templates/reminder-template/reminder-template.component";
import { AnalysisService, BulkAssignRequest, FormResponseService, FormService, GenerateLinkService, RespondentService, SendBulkRemindersDto, StatusTypes } from "@ex/survey-x/index";
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from "rxjs";
import { filter, map, skip, switchMap } from "rxjs/operators";
import * as XLSX from 'xlsx';
import { RespondentService as RespondentPoolService } from 'src/app/core/services/respondent.service';
import { ResponseDialogService } from "@ex/module/core/services/response-dialog.service";
import { Tenant } from "@ex/module/core/data-models/tenant";
import { UnitsService } from "@ex/module/survey-x/data-access/services/units.service";
import { CreditBalanceDialogComponent } from "@ex/module/shared/credit-balance-dialog/credit-balance-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { FirebaseUpdateService } from "@ex/module/survey-x/data-access/services/firebase-update.service";
import { SjsTranslatePipe } from "@ex/module/survey-x/utils/pipes/sjs-translate.pipe";
import { CloseNowViewComponent } from "@ex/module/survey-x/ui/library/close-now-view/close-now-view.component";
import { JsonToExcelComponent } from "@ex/module/shared/export-components/json-to-excel/json-to-excel.component";
import { LiveSurveyDates, LiveSurveyTimeChangesComponent } from "@ex/module/shared/components/live-survey-time-changes/live-survey-time-changes.component";


@Component({
  selector: 'app-live-form-view',
  templateUrl: './live-form-view.component.html',
  styleUrls: ['./live-form-view.component.scss'],
  animations: [ChildrenAnimation, listAnimation, fadeUpDown],
  providers: [DatePipe, RespondentsTableService, SjsTranslatePipe]
})
export class LiveFormViewComponent extends BaseComponent implements OnInit, AfterContentChecked {
  @Input()
  tableData: any[] = [];
  virtualTableData: any[] = [];
  tableDataBK!: string;
  txtSearch!: string;
  tableOptions!: any;
  selectedTable: any;
  formTypeId = null;
  rtl: boolean = false;
  hoveredRow: any;
  currentTenant: any;
  currentTenantKey: any;
  tableLength: any;
  completedTableLength: any;
  responseRate: any;

  sub: any;

  inactiveRespondents: any;

  formParams: any = {
    searchText: "",
    status: ""
  }


  shareByLink = { key: '', open: false };
  sendReminder = { key: '', open: false };

  selectedKeys: any = [];

  completedIcon = 'check-circle';
  completedColor = 'text-zen-green';
  inProgressIcon = 'loader';
  inProgressColor = 'text-zen-blueSelect';
  notStartedIcon = 'circle-exclamation';
  notStartedColor = 'text-zen-orange';
  inactiveIcon = 'circle-xmark';
  inactiveColor = 'text-zen-neutral';

  addRespondent = { tenantKey: '', open: false };


  colors: any;


  @Input() routes: any;

  selectedFilter: any;

  filters = []

  subscriptions = new Subscription();
  activatedLink: any = 0;

  form: any;
  formId: any;
  formName: any;
  formType: any;
  formStatus: any;
  selectedEntity;
  companyKey;
  tenantKey;
  language;
  user;
  essentialObject;
  settingsLoaded$ = new Subject();
  pageChange$ = new BehaviorSubject(0);
  maxResultCount = 10;
  totalItems = 0;
  options: TableOptions;
  selectedRespondents;
  totalRecords;
  currentPage;
  status = Status
  respondentAdd: boolean = false;
  buttonList: any[] = [{
    key: this.status.All,
    value: 0,
  },
  {
    key: this.status.Completed,
    value: 0,
  },
  {
    key: this.status.Progress,
    value: 0,
  },
  {
    key: this.status.Pending,
    value: 0,
  },
  {
    key: this.status.Inactive,
    value: 0,
  },

  ];
  currentStatus = this.buttonList[0];
  tenant: Tenant;

  firebaseUpdated$ = new BehaviorSubject(null);
  openClosedSuccessModal: boolean = false;
  pageSize: number;

  totalCompleted = 0;
  totalCount = 0;
  responseRatePercentage = 0;

  constructor(@Inject(LOCALE_ID) public locale: string
    , public loaderService: LoaderService
    , public global: GlobalsService
    , private respondentsTableService: RespondentsTableService
    , private cdr: ChangeDetectorRef
    , public essentialObjectService: EssentialObjectService
    , private surveysService: FormService
    , private route: ActivatedRoute
    , private formResponseService: FormResponseService
    , public datePipe: DatePipe
    , private zenCdk: ZenCDKService
    , private sharedService: SharedService
    , private analysisService: AnalysisService
    , private respondentPoolService: RespondentPoolService
    , private responseDialogService: ResponseDialogService
    , private unitsService: UnitsService
    , private matDialog: MatDialog
    , private firebaseUpdateService: FirebaseUpdateService
    , private generateLinkService: GenerateLinkService
    , private sjPipe: SjsTranslatePipe) {
    super();
    this.colors = this.global.basicColors;
    this.global.innerRoute = true;


  }





  ngOnInit() {
    this.onFirebaseUpdate();
    this.formId = this.route.snapshot.paramMap.get('id');
    this.loadStatusCounts();
    this.sharedService.onRefreshRespondents.subscribe((response: any) => {
      console.log("Refresh Respondents");

    });
    this.addSubscription(this.EssentialObject$.pipe(filter(x => x.selectedEntity?.key != null)).subscribe(obj => {

      this.tenant = obj.tenant

      this.language = obj.language;
      this.selectedEntity = obj.selectedEntity;
      this.companyKey = obj.selectedEntity.key;
      this.tenantKey = obj.tenant.key;
      this.user = obj.user;
      this.essentialObject = obj;
      this.initTable();
      this.settingsLoaded$.next();
    }))
  }

  onFirebaseUpdate() {
    const sub = this.settingsLoaded$.pipe(
      switchMap(_ => this.firebaseUpdateService.getListUpdate(this.tenantKey, this.companyKey, this.formId).pipe(skip(1)))
    ).subscribe(_ => {
      this.firebaseUpdated$.next(true);
      this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++);
    })

    this.addSubscription(sub);
  }



  async fetchForm() {

    this.sub = await this.surveysService.getFormWithoutResponses(this.formId).toPromise();

    this.form = this.sub.query;

    this.formName = this.form.title
    this.formType = this.form.typeName
    this.formStatus = this.form.statusName

  }


  initTable() {
    let displayedColumns = [
      {
        field: 'employeeId',
        title: this.localize('txt_employee_ID', this.language, this.ModuleNames.Shared)
        ,
      },
      {
        field: 'respondentName',
        title: this.localize('txt_full_name', this.language, this.ModuleNames.Shared)
        ,
      },
      {
        field: 'respondentEmail',
        title: this.localize('txt_user_email', this.language, this.ModuleNames.Shared)
        ,
      },
      {
        field: 'status',
        title: this.localize('txt_progress', this.language, this.ModuleNames.Shared)
      },
      {
        field: 'view',
        title: '',
      },
      {
        field: 'action',
        title: '',
      }
    ];

    this.addSubscription(this.settingsLoaded$.pipe(switchMap(_ => {
      return this.surveysService.getFormWithoutResponses(this.formId).pipe(switchMap(form => {
        return this.firebaseUpdated$.pipe(
          switchMap(_ => {

            return this.respondentsTableService.init({
              language: this.language,
              companyKey: this.selectedEntity.key,
              tenantKey: this.tenantKey,
              options: { columns: displayedColumns, disableSearch: true },
              survey: { key: this.formId }
            }).pipe(map(obj => ({ obj, form })))

          })

        )

      }))

    })).subscribe(({ obj, form }) => {
      this.form = form.query;
      this.formType = this.form.typeName;
      this.formStatus = this.form.statusName;
      this.options = obj.options;
      this.options.disableSearch = true;
      this.totalRecords = obj.totalRecords;
      this.currentPage = obj.pageNumber;
      this.pageSize = obj.pageSize;
      this.LoaderService.hideLoader();
    }))

  }

  applyFilter2(rStatus: any) {
    this.currentStatus = rStatus;
    let stateKey = rStatus.key;
    if (stateKey == this.status.All)
      stateKey = '';
    this.respondentsTableService.currentStatus.next(stateKey);
    this.respondentsTableService.pageNumber.next(0);
  }
  loadStatusCounts() {
    const sub = this.settingsLoaded$.pipe(switchMap(x => {

      return this.firebaseUpdated$.pipe(
        switchMap(_ => {
          return this.analysisService.getStatusCounts(this.tenantKey, this.companyKey, this.formId);
        })
      )

    })).subscribe(counts => {

      this.buttonList.forEach(button => {
        if (button.key == this.status.All) {
          button.value = counts[this.status.All.toLowerCase()];
          return;
        }

        button.value = counts[Object.keys(counts).find((x, i) => x.toLowerCase() == button.key.toLowerCase())];
      })

      this.totalCompleted = counts[Status.Completed.toLowerCase()];
      this.totalCount = this.buttonList.filter(x => ![Status.All, Status.Inactive].includes(x.key)).reduce((a, c) => a + c.value, 0);
      this.responseRatePercentage = Number(this.toFixed((this.totalCompleted / this.totalCount) * 100, 1));

    })
    this.addSubscription(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.global.innerRoute = false;
  }


  displayResOptions(element: any) {
    return element.status != this.status.Completed && element.status != this.status.Inactive && (element.respondentEmail.indexOf("_no_email@") !== 1);
  }

  async deleteRespondent(respondent: any) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondent_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.SurveyX,
        this.language
      );
      if (confirmed) {
        this.LoaderService.displayLoader();
        this.addSubscription(this.formResponseService.deleteFormResponse(respondent.id).subscribe(x => {
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.SurveyX));
          this.options = {};
          this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
          // this.totalRecords--;
          this.LoaderService.hideLoader();

        }))
      }
    } catch (ex) {

    }
  }
  async deleteRespondents(respondents: any[]) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondents_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.SurveyX,
        this.language
      );
      if (confirmed) {
        this.LoaderService.displayLoader();
        let emails = respondents.map(x => x.respondentEmail);
        this.formResponseService.bulkDelete({ formId: this.formId, emails: emails, isDeleteAll: this.respondentsTableService.selectAll }).subscribe(x => {
          this.options = {};
          this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
          this.LoaderService.hideLoader();
        });
      }
    } catch (ex) {

    }
  }

  respondentAddFunction() {
    this.respondentAdd = true;
  }

  shareSurveyLink() {

    const sub = this.generateLinkService.generateLink(this.formId, this.tenantKey, this.companyKey).subscribe(response => {
      this.ZenCDKService.openComponent(ShareSurveyComponent, { link: response, language: this.language });
    });

    this.addSubscription(sub);

  }

  private async openUnitsConfirmation(units: number = 1) {
    let message = this.localize("txt_unit_deduction", this.language, this.ModuleNames.Shared);
    let subject = this.localize("txt_allocation_confirmation", this.language, this.ModuleNames.Shared);
    let actionButtonText = this.localize("txt_okay_action", this.language, this.ModuleNames.Shared);
    let cancelButtonText = this.localize("btn_cancel", this.language, this.ModuleNames.Shared);
    message = message.replace('#UNITS#', units);

    let confirmationResult = await this.responseDialogService.confirmAllocation(message, subject, actionButtonText, cancelButtonText);

    return confirmationResult;
  }

  validateFileRequest(data) {
    return this.formResponseService.excelUploadValidation({
      formId: this.formId,
      companyKey: this.companyKey,
      tenantKey: this.tenantKey, excelFile: data.split(",")[1]
    })
  }

  deductUnits(number: number): Observable<any> {
    let request = { noOfRespondees: number, userKey: this.user.key, tenantKey: this.tenantKey, entityKey: this.selectedEntity.key, engagementKey: this.formId };

    return this.unitsService.deduct(request).pipe(map((x: any) => {
      if (x.message == 'Insufficient Credits') {
        this.LoaderService.hideLoader();
        this.matDialog.open(CreditBalanceDialogComponent, {
          data: {
            tenantKey: this.tenantKey, entity: this.selectedEntity, creditType: 'ENGAGEMENT',
            language: this.language, minUnits: x.data.requiredCredits,
            currentBalance: x.data.currentUnits, surveyRequires: x.data.minUnits, user: this.user, tenant: this.tenant
          },
          autoFocus: false,
        });
        throw new Error('Valid token not returned');
      }
      return x;
    }))

  }



  async insertUsingExcel(data?: any) {

    this.LoaderService.displayLoader();

    // TODO: Validate the excel to get the count
    let excelValidation = await this.validateFileRequest(data).toPromise();

    if (excelValidation.totalRecords == 0) {
      this.SnackbarService.error("");

      return;
    }



    // Upload the excel to form responses
    var request = {
      formId: this.formId,
      companyKey: this.companyKey,
      tenantKey: this.tenantKey,
      excelFile: data.split(",")[1]
    }

    // Upload the excel to Res Pool
    let requestToPool = {
      FileKey: 'RESPOOL',
      TenantKey: this.tenant.key,
      CompanyKey: this.selectedEntity.key,
      UserKey: this.user.key,
      FileBase: data
    };

    const confim = await this.openUnitsConfirmation(excelValidation.totalRecords);
    if (confim) {

      this.LoaderService.displayLoader();

      const sub = this.deductUnits(excelValidation.totalRecords).pipe(
        switchMap(_ => {
          return this.respondentPoolService
            .updloadRespondents(requestToPool)
        }),
        switchMap(res => {
          return this.formResponseService.excelUpload(request)
        })
      ).subscribe(respondentData => {

        if (!respondentData) {
          this.LoaderService.hideLoader();
          return;
        }

        this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
        this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
        this.ZenCDKService.closeAll();
        this.LoaderService.hideLoader();
        this.respondentAdd = false;
      })

      this.addSubscription(sub);

    }

  }


  async createMultipleRespondent(data?: any) {
    this.LoaderService.displayLoader();

    var totalRespondents = await this.formResponseService.getUnitsToDeduct(this.formId, {
      companyKey: this.companyKey,
      tenantKey: this.tenantKey,
      formId: this.formId,
      emails: data.type == "ALL" ? data.exclude.map(x => x.email) : data.respondents.map(x => x.email),
      isSelectAll: data.type == "ALL" ? true : false
    }).toPromise();

    let confirmationResult = await this.openUnitsConfirmation(totalRespondents);

    if (confirmationResult) {

      let reData: BulkAssignRequest = {
        companyKey: this.companyKey,
        tenantKey: this.tenantKey,
        formId: this.formId,
        emails: data.type == "ALL" ? data.exclude.map(x => x.email) : data.respondents.map(x => x.email),
        isSelectAll: data.type == "ALL" ? true : false
      }

      // let reData: BulkAssignToSurveyDto = {
      //   emails: data.respondents.map(x => x.email),
      //   isSelectAll: data.allSelected
      // };
      this.deductUnits(totalRespondents).pipe(
        switchMap(x => {
          return this.formResponseService.bulkAssignToForm(reData)
        }))
        .subscribe(respondentData => {
          this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
          this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
          this.ZenCDKService.closeAll();
          this.LoaderService.hideLoader();
          this.respondentAdd = false;
        })
    }

  }

  async createSingleRespondent(data?: any) {
    this.LoaderService.displayLoader()

    let units = 1;

    let confirmationResult = await this.openUnitsConfirmation(units);

    if (confirmationResult) {
      this.LoaderService.displayLoader();

      let respondentData = {
        respondentName: data.name,
        respondentEmail: data.email,
        formId: this.formId,
        companyKey: this.companyKey,
        tenantKey: this.tenantKey,
      };

      // TODO: Initiate the unit deduction api here

      const sub = this.deductUnits(units).pipe(
        switchMap(_ => {
          return this.getUploadToRespondentPoolRequest(data);
        }),
        switchMap(_ => {
          return this.formResponseService.addFormResponse(respondentData)
        })
      )
        .subscribe(respondentData => {
          this.respondentsTableService.addRespondent(respondentData);
          this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
          this.ZenCDKService.closeAll();
          this.LoaderService.hideLoader();
          this.respondentAdd = false;
        })

      this.addSubscription(sub);
    }
  }

  downloadExcel(data?: any) {
    window.open('/assets/downloads/Respondents Template.xlsx', '_blank');
  }


  getUploadToRespondentPoolRequest(data: any) {
    let respondents: EngagementRespondent[] = []
    respondents.push(data);

    let request = {
      TenantKey: this.tenantKey,
      CompanyKey: this.companyKey,
      UserKey: this.user.key,
      respondents: respondents
    }

    return this.respondentPoolService.updateRespondentPool(request)
  }

  logData(e: any) {
    console.log(e);
  }
  currentIndex: number = 0;
  value!: string;
  resolveRoute(o: RouterOutlet) {
    let path = o.activatedRouteData['animation'];
    if (o.isActivated) {
      if (path > this.currentIndex) {
        this.language == 'AR' ? this.value = 'back' : this.value = 'forward';

      } else if (path < this.currentIndex) {
        this.language == 'AR' ? this.value = 'forward' : this.value = 'back';
      }

      this.currentIndex = path;
      return this.value;
    }
    return this.value;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  selectionChanges(values: any) {
    if (values) {

      this.selectedKeys = values.filter(x => x.status != "Completed");
    }
  }

  onPageChange(value: any) {
    this.respondentsTableService.pageNumber.next(value);
  }


  searchTextUpdate(text: string) {
    this.respondentsTableService.searchText.next(text);
    this.respondentsTableService.pageNumber.next(0);
  }

  onExportFromServer(value: any) {
    this.formResponseService.getAllFormResponsesByForm(this.formId).subscribe((response: any) => {
      let data = [];
      response.forEach(element => {
        if (!this.form.isAnonymous) {
          data.push({
            name: element.name ? element.name : "N/A",
            email: element.email,
            companyName: element.companyName ? element.companyName : "N/A",
            status: element.status,
            invited: this.datePipe.transform(element.invitaionDate, 'dd/MM/YYYY'),
            completed: element.completionDate ? this.datePipe.transform(element.completionDate, 'dd/MM/YYYY') : "N/A",

          })
        }
        else {
          data.push({
            name: "Anonymous",
            email: "Anonymous",
            companyName: element.companyName ? element.companyName : "N/A",
            status: element.status,
            invited: this.datePipe.transform(element.invitaionDate, 'dd/MM/YYYY'),
            completed: element.completionDate ? this.datePipe.transform(element.completionDate, 'dd/MM/YYYY') : "N/A",
          })
        }
      });
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, this.sjPipe.transform(this.form.title, this.language) + '.xlsx');
    })
  }



  onSendReminder(respondents?: any) {
    if (respondents?.length)
      respondents = respondents.map(x => x.id);
    const sub = this.zenCdk.openComponent(ReminderTemplateComponent, { form: this.form, selectedEntity: this.selectedEntity, language: this.language }).pipe(switchMap((x: any) => {
      let sendBulkRemindersDto = {} as SendBulkRemindersDto;
      sendBulkRemindersDto.responseIds = respondents ?? [];
      sendBulkRemindersDto.formId = this.formId;
      sendBulkRemindersDto.emailTemplate = { body: x.reminderBody, subject: x.reminderSubject }

      return this.surveysService.sendBulkReminders(sendBulkRemindersDto)
    })).subscribe(x => {
      this.SnackbarService.success(this.localize('txtReminderSent', this.language, this.ModuleNames.Wellbeing));
    });
    this.addSubscription(sub);
  }






  async closeSurvey() {

    if (this.buttonList.find(x => x.key == this.status.Completed).value < 3) {
      return this.SnackbarService.error(this.localize('txt_Atleast3r_677', this.language, this.ModuleNames.Wellbeing));
    }

    const confirm = await this.responseDialogService.confirmation(
      "txt_go_closed",
      "",
      "txt_okay_action",
      "btn_cancel",
      this.ModuleNames.SurveyX,
      this.language
    )

    if (confirm) {
      // TODO: Close the survey
      const sub = this.surveysService.closeSurvey(this.formId).subscribe(_ => {
        // this.openClosedSuccessModal = true;
      })

      this.addSubscription(sub);


      // TODO: On Sucess redirect the user to closed survey page with surveyid
    }

  }

  downloadRespondents() {
    // this.respondentsTableService.fetchFullList().pipe(filter)
    this.addSubscription(this.formResponseService.getPagedList(this.formId, this.tenantKey, this.companyKey, true, 0, 1, '', '').subscribe(x => {
      let data = x.items.map(x => {

        let customProps = {};
        this.form.selectedCriterias.forEach(sc => {
          var answer = x.customProperties.find(c => c.key == sc.key);

          if (answer) {
            const key = this.ExtractPipe.transform(sc.name, this.language);

            customProps[key] = answer.value;

            if (x['type'] == "DROPDOWN") {
              const option = sc.options.find(o => o.key == answer.value);
              const value = this.ExtractPipe.transform(option?.name, this.language)
              customProps[key] = value;
            }

          }

        });

        let item = {
          name: x.respondentName,
          email: x.respondentEmail.includes("no_email@zenithr.com") ? "Generated Link" : x.respondentEmail,
          status: this.localize(`txt_${x.status?.toUpperCase()}`, "EN", ModuleName.Shared),
          ...customProps
        }

        return item;
      })

      this.ZenCDKService.openComponent(JsonToExcelComponent, {
        data: data,
        name: this.localize('txt_respondents_list', this.language, this.ModuleNames.SurveyX)
      })

    }))
  }

  onCloseNow() {
    if (this.totalCompleted < 3)
      return this.SnackbarService.error(this.localize('txt_Atleast3r_677', this.language, this.ModuleNames.SurveyX));
    this.addSubscription(this.zenCdk.openComponent(CloseNowViewComponent, { selectedForm: this.form, language: this.language }).pipe(switchMap(x => {
      return this.surveysService.closeSurvey(this.formId)
    })).subscribe(x => {
      this.openClosedSuccessModal = true;
    }))
  }

  openLiveDateChanges() {
    let surveyDates: LiveSurveyDates = {
      endDate: new Date(this.form.endDate),
      startDate: new Date(this.form.startDate),
      reminders: JSON.parse(JSON.stringify(this.form.reminders)) || []
    }
    this.ZenCDKService.openComponent(LiveSurveyTimeChangesComponent, {
      surveyDates: surveyDates,
      language: this.language
    }).pipe(switchMap((data: LiveSurveyDates) => {
      this.form.reminders = data.reminders;
      this.form.startDate = data.startDate;
      this.form.endDate = data.endDate;
      return this.surveysService.updateForm(this.form.id, this.form).pipe(map(x => data))
    })).subscribe((data: LiveSurveyDates) => {
      this.form.endDate = data.endDate.toDateString();
      this.form.reminders = data.reminders;
      this.SnackbarService.success(this.localize('txt_updated_success', this.language, this.ModuleNames.Wellbeing));
    })
  }


  emailCheck(value: string) {
    if (value.indexOf('sl_') != -1 && value.indexOf('_no_email@zenithr.com') != -1)
      return this.localize('txt_Generated_142', this.language, this.ModuleNames.Wellbeing);
    if (value.indexOf('sl_') === -1 && value.indexOf('_no_email@zenithr.com') != -1)
      return value.split('_')[0];
    return value;
  }

}
