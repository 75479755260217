import { Injectable, Input } from '@angular/core';

import { ModuleName } from 'src/app/shared/enums/ModuleName';

import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';



export enum Status {

  Completed = 'COMPLETED',

  Progress = 'INPROGRESS',

  Posted = 'POSTED',

  Pending = 'PENDING',
  Started = 'STARTED',
  Expired = 'EXPIRED',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
 Closed= 'CLOSED',
  All = 'ALL',

}




@Injectable({

  providedIn: 'root'

})

export class GlobalStatusService {

  status = Status;

  ModuleNames = ModuleName;

  constructor(private translate: TranslatePipe) { }

  returnClassColor(status, background?) {

    let className = 'PENDING';

    let backgroundColor = 'zen';

    if (!background) {

      switch (status?.toUpperCase()) {

        case 'ALL':

          className = 'text-zen-lite fa-user-group';

          break;

        case 'PENDING':

          className = 'text-orange fa-circle-exclamation';

          break;

        case 'INPROGRESS':

          className = 'text-zen-blue fa-loader';

          break;

        case 'STARTED':
          className = 'text-zen-blue fa-loader';
          break;

        case 'EXPIRED':

          className = 'text-zen-red fa-circle-xmark';

          break;

        case 'POSTED':

          className = 'text-zen fa-clock';

          break;

        case 'COMPLETED':

          className = 'text-zen-green fa-check-circle';

          break;

        case 'INACTIVE':

          className = 'text-zen text-opacity-50 fa-ban';

          break;
        case 'DELETED':

          className = 'text-zen text-opacity-50 fa-ban';

          break;

        default:

          className = 'text-zen-lite';

          break;

      }

      return className;

    }

    else {

      switch (status?.toUpperCase()) {

        case 'ALL':

          backgroundColor = 'zen';

          break;

        case 'PENDING':

          backgroundColor = 'orange';

          break;

        case 'INPROGRESS':

          backgroundColor = 'zen-blue';

          break;
        case 'STARTED':

          backgroundColor = 'zen-blue';

          break;

        case 'EXPIRED':

          backgroundColor = 'zen-red';

          break;

        case 'POSTED':

          backgroundColor = 'zen';

          break;

        case 'COMPLETED':

          backgroundColor = 'zen-green';

          break;

        case 'INACTIVE':

          backgroundColor = 'zen text-opacity-50';

          break;
          case 'DELETED':

          backgroundColor = 'zen text-opacity-50';

          break;

        default:

          backgroundColor = 'zen-lite';

          break;

      }

      return backgroundColor;

    }

  }



  returnClassName(status, language) {
    let name = '';

    switch (status?.toUpperCase()) {

      case 'ALL':

        name = this.translate.transform('txt_ALL', language, this.ModuleNames.Shared);

        break;

      case 'PENDING':

        name = this.translate.transform('txt_PENDING', language, this.ModuleNames.Shared);

        break;

      case 'INPROGRESS':

        name = this.translate.transform('txt_INPROGRESS', language, this.ModuleNames.Shared);

        break;
      case 'STARTED':
        name = this.translate.transform('txt_INPROGRESS', language, this.ModuleNames.Shared);

        break;

      case 'EXPIRED':

        name = this.translate.transform('txt_EXPIRED', language, this.ModuleNames.Shared);

        break;

      case 'POSTED':

        name = this.translate.transform('txt_POSTED', language, this.ModuleNames.Shared);

        break;

      case 'COMPLETED':

        name = this.translate.transform('txt_COMPLETED', language, this.ModuleNames.Shared);

        break;

      case 'INACTIVE':

        name = this.translate.transform('txt_INACTIVE', language, this.ModuleNames.Shared);

        break;
        case 'DELETED':

        name = this.translate.transform('txt_DELETED', language, this.ModuleNames.Shared);

        break;

      default:

        name = '';

        break;

    }



    return name;

  }
  returnHexColor(status) {
    let color = '';

    switch (status?.toUpperCase()) {

      case 'ALL':

        color = '#747884';

        break;

      case 'PENDING':

        color = '#FF9900';

        break;

      case 'INPROGRESS':

        color = '#007CDD';

        break;
      case 'STARTED':

        color = '#007CDD';

        break;

      case 'EXPIRED':

        color = '#FF4530';

        break;

      case 'POSTED':

        color = '#46597A';

        break;

      case 'COMPLETED':

        color = '#36B37E';

        break;

      case 'INACTIVE':

        color = '#46597a77'

        break;

      default:

        color = '';

        break;

    }



    return color;

  }


}

