import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseComponent } from "@ex/module/core/domain/base-component";
import { ModuleName } from "@ex/module/shared/enums/ModuleName";


interface Step {
  route: string;
}

@Component({
  selector: 'app-form-builder-header',
  templateUrl: './form-builder-header.component.html',
  styleUrls: ['./form-builder-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormBuilderHeaderComponent extends BaseComponent implements OnInit {

  @Input() language;
  ModuleNames = ModuleName;
  dLanguage: any;


  @Input() isUpdating: boolean;
  @Input() surveyId = '';
  @Input() surveyTitle = 'New Form';
  @Input() currentPage = 'initial-setup';
  @Input() showAssignWeights = false;
  @Input() disableLinks = false;

  @Output() nextStep = new EventEmitter<void>();
  @Output() previousStep = new EventEmitter<void>();
  @Output() pageChanged = new EventEmitter<string>();
  @Output() share = new EventEmitter<void>();

  steps: Step[] = [
    { route: 'initial-setup' },
    { route: 'questions-builder' },
    { route: 'assign-weights' },
    { route: 'languages' },
    { route: 'analytical-criteria' },
    { route: 'respondents' },
    { route: 'share' },
  ];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.GlobalService.innerRoute = true;
    if (this.language == "EN") {
      this.dLanguage = "default"
    }
    else {
      this.dLanguage = this.language?.toLowerCase();
    }
  }
  ngOnDestroy(): void {
    this.GlobalService.innerRoute = false;
  }

  /**
   * Returns the CSS class for the router link based on the current page and the page being checked.
   * @param page - The page being checked.
   * @returns The CSS class for the router link.
   */
  getRouterLinkCssClass(page: string): string {
    if (this.currentPage === page) {
      return 'text-zen-primarydark';
    } else if (this.steps.findIndex(x => x.route === this.currentPage) > this.steps.findIndex(x => x.route === page)) {
      return 'text-exp';
    } else {
      return 'text-zen-primarylight';
    }
  }

  redirctTo(page: string): void {
    if (this.disableLinks)
      return;

    if (this.currentPage !== page) {
      this.pageChanged.emit(page);
    }
  }
  getTranslatedTitle(title: any) {
    if (title)
      if (title.includes(",") && title.includes("{")) {
        let title1 = JSON.parse(title)
        if (typeof title1 == 'string') {
          return title1;
        }
        else {
          return title1[this.dLanguage]
        }
      }
      else {
        return title
      }
  }
}
