<div class="flex flex-col mt-10">
  <div class="flex flex-row items-stretch flex-wrap -mr-3 -ml-3">
    <ng-container *ngFor="let chart of charts; let i = index">
      <div (click)="logData(charts, 'chart')" [ngClass]="chart?.halfWidth ? 'w-1/2' : 'w-full'"
        class="flex flex-col items-stretch px-3 pb-6" *ngIf="chart?.data$ | async as data">
        <!-- <ob-chart-wrapper [title]="chart.title"> -->

        <!-- <ng-container body> -->
        <ng-container [ngSwitch]="chart.group">
          <ng-container *ngSwitchCase="'overall'">
            <div
              class="flex flex-col items-center justify-between h-full py-10 gap-10 w-full rounded-sm border border-zen border-opacity-10 shadow-sm bg-white">
              <h5 class="text-zen text-lg font-medium px-5">
                {{ chart.Title }}
              </h5>

              <kendo-arcgauge class="w-56" [color]="'#0082C6'" [value]="toFixed(data.responseRate, 0)"
                renderAs="canvas">
                <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450" [rangeSize]="25" [rangeLineCap]="'butt'">
                  <ng-template kendoArcGaugeCenterTemplate let-value="value">
                    <span class="text-4xl font-medium text-zen">{{ toFixed(data.responseRate, 0) }}%</span>
                  </ng-template>
                </kendo-arcgauge-scale>
              </kendo-arcgauge>
              <span></span>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault>

            <chart *ngIf="language" (click)="logData(data)" [wrapRows]="true" [dashlet]="chart" [dashletData]="data"
              [currencySymbol]="'$'" [language]="language" [dataSymbol]="' %'" [disableGrouping]="true"
              [showDrillDown]="true" [reportsPage]="false" [chartTypeTour]="
                i == 1
                  ? {
                      title:
                        'txt_ChartType_839' | translate : language : ModuleNames.Wellbeing,
                      text:
                        'txt_Changethe_415'| translate : language : ModuleNames.Wellbeing,
                      order: 2,
                      tourKey: 'wellbeing-closed-1',
                      on: (language | language) ? 'right' : 'left'
                    }
                  : undefined
              ">
            </chart>
          </ng-container>
        </ng-container>

        <!-- </ng-container> -->

        <!-- </ob-chart-wrapper> -->
      </div>
    </ng-container>
  </div>
  <wb-respondents-table *ngIf="respondentsData && surveyCriterias" [language]="language" [data]="respondentsData"
    [surveyCriterias]="surveyCriterias" [tenantKey]="filters?.tenantKey" [companyKey]="filters?.companyKey"
    [surveyKey]="filters?.survey?.key" (pageChanged)="onPageChange($event)" [pageSize]="maxResultCount"
    (searchFilterChanged)="searchFilterChanged($event)">

    <div class="btn flex flex-row gap-2 items-center cancel leading-10 px-3 rounded" (click)="exportRespondents()">
      <i class="fal fa-download"></i>
      <h5 class="">{{'btnExport' | translate:language:ModuleNames.Wellbeing}}</h5>
    </div>

  </wb-respondents-table>
</div>