import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { inject } from '@angular/core/testing';
import { Subscription } from 'rxjs';
import { EssentialObjectService } from 'src/app/core/services/essential-object.service';
import { LocalService } from 'src/app/core/services/local.service';
import { DATA_TOKEN, ICDKPortalComponent } from 'src/app/shared/Theme/services/zen-cdk.service';
import { SnackbarInstanceComponent } from './snackbar-instance/snackbar-instance.component';
import { ZenSnackbarService } from '../../services/zen-snackbar.service';

@Component({
  selector: 'zen-snackbar',
  templateUrl: './zen-snackbar.component.html',
  styleUrls: ['./zen-snackbar.component.scss']
})
export class ZenSnackbarComponent implements OnInit, ICDKPortalComponent {
  @ViewChild('vc', { read: ViewContainerRef, static: true },) vc: ViewContainerRef;
  constructor(@Inject(DATA_TOKEN) private data: any, private localService: LocalService, private zenSnackBarService: ZenSnackbarService, private cdr: ChangeDetectorRef, public essentialObjectService: EssentialObjectService) {
    // this.subscriptions.add(this.essentialObjectService.essentialObject.subscribe(
    //   (object: any) => {
    //     this.language = object.language;
    //     console.log(this.language)
    //   }
    // ));
  }
  @Output() close = new EventEmitter<any>();
  @Output() results = new EventEmitter<any>();
  language: string
  delay: number = 4000;
  show: boolean = false;
  ngOnInit(): void {

    this.loadDynamicComponent(this.data);
    let localLanguage = this.localService.getJsonValue('language');
    let sessionLanguage = sessionStorage.getItem('language');
    if (!localLanguage && !sessionLanguage) {
      this.language = 'EN';
    }
    else if (localLanguage) {
      this.language = localLanguage
    }
    else if (sessionLanguage) {
      this.language = sessionLanguage;

    }
  }
  loadDynamicComponent(data) {
    let component = this.vc.createComponent(SnackbarInstanceComponent);
    component.instance.message = data.message;
    component.instance.type = data.type;
    component.instance.language = this.language;
    component.instance.delay = (data.delay ? data.delay : this.delay);
    setTimeout(() => {
      this.vc.remove();
      this.close.emit(true);
    }, component.instance.delay + 300);
  }
}
