export class EngagementMetric {
  key: string;
  name: any[] = [];
  type: string;
  isCustom: boolean = false;
  isEntity: boolean = false;
  options: any[] = [];
  required: boolean = false;
  value: any;
  placeholderValue: any;
  lowest: string;
  highest: string;
  isMulti: boolean = false;
  metrics: EngagementMetric[] = [];
}
