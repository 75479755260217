import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from '../../../../core/services/general.service';
import { GlobalsService } from '../../../../core/services/globals.service';
import { LanguageService } from '../../../../core/services/language.service';

@Component({
  selector: 'rating-liked',
  templateUrl: './rating-liked.component.html',
  styleUrls: ['./rating-liked.component.scss']
})
export class RatingLikedComponent implements OnInit {

  lgCount;
  @Input() question;

  @Input() single = false;


  changingLanguage: boolean = false;
  language: string;
  @Input('language') set currentLanguage(lang: string){
    if(lang != this.language){
      this.changingLanguage = true;
      setTimeout(() => {
        this.language = lang;  
        this.changingLanguage = false;
      }, 50);      
    }
  }

  constructor( private _globals: GlobalsService,private languageService: LanguageService,  private generalService: GeneralService,) { 
    this.lgCount = _globals.lgCount;
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  ngOnInit(): void {
  }
  countWord(word, count) {
    if (word?.length <= count)
      return word.length;
    if (word?.length > count) {

      return word.length;
    }
  }

  getConfidence(subquestionKey: string, key: string) {
    return this.generalService.getQuestionOptions(subquestionKey, key, this.language);

    if (subquestionKey == 'sub-questions-confidence-rating')
      return this.generalService.confidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-likeness-rating')
      return this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-likeness-na-rating')
      return this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-binary-likeness-rating')
      return this.generalService.binaryLikeness.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'single-answer-rating')
      return this.generalService.singleAnswerConfidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'single-answer-out-10')
      return this.generalService.singleAnswerOutof10.find(cf => { return (cf.key == key) }).name;
  }

}
