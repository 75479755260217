import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { CustomDatepickerComponent } from '@ex/module/survey-x/ui/custom-datepicker/custom-datepicker.component';
import { FormService } from '@ex/survey-x/index';
import { environment } from 'src/environments/environment';
import { SurveyCreatorModel } from 'survey-creator-core';

/**
 * Options for Survey Creator Model
 */
const creatorOptions = {
  showLogicTab: true,
  showTranslationTab: true,
  isAutoSave: true
};

@Component({
  selector: 'app-assign-weights',
  templateUrl: './assign-weights.component.html',
  styleUrls: ['./assign-weights.component.scss']
})
export class AssignWeightsComponent implements OnInit {

  language;
  ModuleNames = ModuleName;
  static declaration = [CustomDatepickerComponent];
  surveyId: string;
  surveyCreatorModel: SurveyCreatorModel;
  endPoint = environment.endPoint;
  survey: any = {};
  direction: Direction = 'ltr';
  currentLocale: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private surveysService: FormService
  ) { }

  async ngOnInit() {
    this.surveyId = this.route.snapshot.paramMap.get('id');

    var sub: any = await this.surveysService.getFormWithoutResponses(this.surveyId).toPromise();
    this.survey = sub.query;

    this.surveyCreatorModel = new SurveyCreatorModel(creatorOptions);
    this.surveyCreatorModel.text = this.survey.jsonText;
  }

  /**
   * Navigates to the languages page.
   */
  nextStep(): void {
    this.router.navigateByUrl(`/survey-x/form-builder/languages/${this.surveyId}`);
  }

  /**
   * Navigates to the questions builder page.
   */
  previousStep(): void {
    this.router.navigateByUrl(`/survey-x/form-builder/questions-builder/${this.surveyId}`);
  }
}
