<div class="flex flex-col gap-5 py-20 mx-6 select-none" (touchmove)="selected? mouseMove($event):''"
    (mousemove)="selected? mouseMove($event):''" (mouseleave)="selected = false; roundValue()">
    <div #parentContainer (click)="handleClick($event)"
        class="flex flex-row bg-zen bg-opacity-10 rounded w-full h-5 justify-start cursor-pointer">
        <div #innerContainer class="flex flex-row  rounded w-1/3 h-5 justify-end items-center"
            [style.width.px]="innerWidthValue" [ngClass]="rating?'bg-'+ resolveColor():'bg-zen'">
            <div class="w-6">
                <div #dragButton (click)="$event.stopPropagation()" (mouseleave)="selected = false;"
                    (touchend)="roundValue();selected = false;" (mouseup)="roundValue();selected = false;"
                    (mousedown)=" $event.stopPropagation();selected = true;"
                    (touchstart)="selected = true; logData($event)"
                    class="w-12 h-12 bg-white shadow-2x2x4 rounded-full cursor-grab flex justify-center items-center gap-2"
                    [ngClass]="(language | language )?'flex-row-reverse':'flex-row'">
                    <i class="fa-solid fa-chevron-left text-zen text-opacity-10"></i>
                    <i class="fa-solid fa-chevron-right text-zen text-opacity-10"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-row justify-between">
        <div class="flex flex-col items-center gap-1 w-0" *ngFor="let step of steps; let s = index">
            <span class="rounded-md h-3 w-0 border-r select-none transition-all"
                [ngClass]="rating && value  == step?'border-'+ resolveColor():'border-zen opacity-70'"></span>
            <h5 class="text-xs  font-normal select-none transition-all"
                [ngClass]="rating && value  == step?'font-semibold text-'+ resolveColor():'text-zen opacity-70'">{{s}}
            </h5>
        </div>
    </div>
    <ng-content select="[legend]"></ng-content>

</div>
