<z-modal [open]="open">
  <ng-container header>
    <div>
      <!-- <p class="text-sm text-zen">Please reupload the data file after fixing the validation errors.</p> -->
      <h3 class="text-2xl font-bold text-zen">{{'btn_add_respondent'|
        translate:language:ModuleNames.Shared}}</h3>

    </div>
    <i (click)="close.emit(true)"
      class="fa-regular fa-times leading-12  btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col items-stretch justify-center">
      <div class="flex flex-row justify-between items-center  gap-3 pb-5">
        <a class="btn text-center  leading-12 px-5  w-full rounded text-base whitespace-nowrap" (click)="tab = 0"
          [ngClass]="tab == 0?'exp':'cancel'">
          <i class="fas fa-pen"></i> {{'btn_add_respondent'| translate:language:ModuleNames.Shared}}</a>
        <a class="btn text-center  leading-12 px-5 w-full rounded text-base whitespace-nowrap" (click)="tab = 1"
          [ngClass]="tab == 1?'exp':'cancel'">
          <i class="fas fa-file-spreadsheet"></i> {{'btn_import_file'|
          translate:language:ModuleNames.Shared}}</a>
        <a *ngIf="multiSelect" class="btn text-center  leading-12 px-5 w-full rounded text-base whitespace-nowrap" (click)="tab = 2"
          [ngClass]="tab == 2?'exp':'cancel'">
          <i class="fas fa-users"></i> {{'txt_respondents_pool'|
          translate:language:ModuleNames.Shared}}</a>
      </div>
      <form *ngIf="tab == 0" [formGroup]="employeeForm" (ngSubmit)="addSingleRespondent()"
        class="flex flex-col gap-5 transition-all ease-expo duration-500 overflow-hidden w-full pb-6">
        <!-- <h4 class="text-zen text-2xl">
                  {{txtBasicInfo}}
              </h4> -->
        <ng-container>
          <div class="flex flex-row gap-5">
            <div class="w-2/5">
              <zen-new-input [language]="language" [label]="'txt_employee_ID'|translate:language:ModuleNames.Shared"
                formControlName="employeeId"></zen-new-input>
            </div>
            <div class="w-3/5">
              <zen-new-input [language]="language"
                [label]="'txt_respondent_name'| translate:language:ModuleNames.Shared"
                formControlName="name"></zen-new-input>

            </div>
          </div>
          <div class="flex flex-row gap-5">
            <div class="w-full">
              <zen-new-input type="email" [language]="language"
                [label]="'txt_email_address'|translate:language:ModuleNames.Shared"
                formControlName="email"></zen-new-input>
            </div>

          </div>

        </ng-container>
      </form>
      <div *ngIf="tab == 1" class="flex flex-col gap-5 transition-all ease-expo duration-500 overflow-hidden w-full">
        <ng-container>
          <div class="flex flex-row">
            <div class="flex flex-col w-2/3 gap-2">
              <h4 class="max-w-xs truncate text-sm text-zen">{{'txt_step'|
                translate:language:ModuleNames.Shared}} 1:
              </h4>
              <p class="text-base">{{'txt_step1' | translate:language:ModuleNames.Shared}}</p>
            </div>
            <div class="flex flex-col w-1/3 gap-2">
              <a (click)="downloadExcel()" class="btn cancel px-5 rounded-md leading-12 text-center">
                <i class="fal leading-12 px-1 fa-download"></i>{{'btn_download'|
                translate:language:ModuleNames.Shared}}</a>
            </div>

          </div>
          <div class="flex flex-row">
            <div class="flex flex-col w-2/3 gap-2">
              <h4 class="max-w-xs truncate text-sm text-zen">{{'txt_step'|
                translate:language:ModuleNames.Shared}} 2:
              </h4>
              <p class="text-base">{{'txt_step2'| translate:language:ModuleNames.Shared}}</p>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="flex flex-col w-2/3 gap-2">
              <h4 class="max-w-xs truncate text-sm text-zen">{{'txt_step'|
                translate:language:ModuleNames.Shared}} 3:
              </h4>
              <p class="text-base">{{'txt_step3'| translate:language:ModuleNames.Shared}}</p>
            </div>
            <div class="flex flex-col w-1/3 gap-2">
              <a class="btn exp px-5 rounded-md leading-12 text-center" (click)="input.click()">
                <i class="fal leading-12 px-1 fa-upload"></i>{{(errors?.length?'txtReupload':'btn_upload_file')|
                translate:language:ModuleNames.Shared}}</a>
              <input #input class="none z-0 w-0 h-0" (change)="uploadRespondentFile($event)" type="file">
            </div>

          </div>

          <div class="flex flex-row items-center justify-between gap-2 bg-zen bg-opacity-10 rounded px-5 py-2">
            <div class="flex flex-row items-center gap-1 text-zen  ">
              <i class="text-zen fal fa-info-circle"></i>
              {{'txt_auto_return'| translate:language:ModuleNames.Shared}}
            </div>

          </div>
        </ng-container>
        <div class="flex flex-col items-stretch gap-1" *ngIf="errors?.length">
          <div class="flex flex-row py-2 text-zen-red bg-zen-red bg-opacity-5 text-xs rounded-sm px-5" *ngFor="let error of errors">
            <h5>{{error.key | translate:language:ModuleNames.Wellbeing}}: <span class="font-medium">{{error.value}}</span></h5>
          </div>
        </div>
      </div>
      <div *ngIf="tab == 2 && multiSelect"
        class="flex flex-col gap-5 transition-all ease-expo duration-500 overflow-hidden w-full">
         <wb-respondent-pool rowHeight="12" [currentPage]="currentPage" [language]="language" (allSelected)="selectedAll($event)" (searchTextChange)="searchTextUpdate($event)" [(searchText)]="searchText" [canDelete]="false" (pageChanged)="onPageChange($event)" [pageSize]="pageSize" [totalRecords]="totalRecords"
          [options]="options" (selectedRespondents)="selectedRespondents = $event"></wb-respondent-pool>

      </div>
    </div>

  </ng-container>
  <ng-container footer *ngIf="tab != 2" class="flex w-full flex-row justify-between">
    <a (click)="close.emit(true)"
      class="btn cancel  text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{'btn_close'|
      translate:language:ModuleNames.Shared}}</a>
    <a *ngIf="tab == 0" (click)="addSingleRespondent()"
      class="btn exp  w-auto text-center  align-middle rounded-md px-8 py-4">{{(!addRespondentType?
      'btn_add_respondent': '' + addRespondentType === 'CANDIDATE' ? 'btn_add_candidate' : 'btn_add_evaluator')|
      translate:language:ModuleNames.Shared}}</a>

  </ng-container>
  <ng-container footer *ngIf="tab == 2" class="flex w-full flex-row justify-between">
    <a (click)="close.emit(true)"
      class="btn cancel  text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{'btn_close'|
      translate:language:ModuleNames.Shared}}</a>
    <a (click)="bulkRespondent()"
      class="btn exp  w-auto text-center  align-middle rounded-md px-8 py-4">{{(!addRespondentType?
      "txtAddRespondent_s": '' + addRespondentType === 'CANDIDATE' ?"txtAddCandidate_s" :"txtAddEvaluator_s")|
      translate:language:ModuleNames.Shared}}</a>
  </ng-container>

</z-modal>
