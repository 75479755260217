<div class="flex flex-col gap-5">
  <div class="py-5">
    <mat-divider></mat-divider>
  </div>
  <ng-container>
    <div class="flex flex-row justify-between items-center" *DemoAccount="'Default'; let isDemo=isDemo">
      <h5 class="text-zen text-2xl font-medium">
        {{ "txtHeatmap" | translate : language : ModuleNames.Wellbeing }}
        <i [matTooltip]="
            'txtheatmaptooltip' | translate : language : ModuleNames.Wellbeing
          " class="fas fa-info text-sm info-circle cursor-pointer"></i>
      </h5>

      <div class="flex flex-row justify-end items-center">
        <a *ngIf="showHeatMap" zenButton styles="secondary height-10" (click)="isDemo?'':downloadHeatmap()" [TourStep]="{
            title: 'txtExport' | translate : language : ModuleNames.Wellbeing,
            text:
              'txt_Elevateyo_771'
              | translate : language : ModuleNames.Wellbeing,
            order: 7,
            tourKey: 'wellbeing-closed-0',
            on: (language | language) ? 'right' : 'left'
          }">
          <i class="fa-regular fa-file-alt"></i>
          <span>{{
            "txtDownloadHeatmap" | translate : language : ModuleNames.Wellbeing
            }}</span></a>
        <a *ngIf="!showHeatMap" zenButton styles="secondary height-10 disabled">
          {{
          "txtPreparingHeatmap" | translate : language : ModuleNames.Wellbeing
          }}
          <lottie-player src="/assets/lottie/circleLoader.json" background="transparent" speed="2" style="width: 3rem; height: 3rem" loop autoplay="false"></lottie-player>
        </a>
      </div>
    </div>

    <div class="flex flex-row justify-between items-center  relative" *DemoAccount="'Button'; let isDemo=isDemo">
      <h6 class="text-opacity-80 text-zen uppercase">
        {{
        "txtComparisonOverall" | translate : language : ModuleNames.Wellbeing
        }}
      </h6>

      <z-select [DemoLimit]="2" *ngIf="surveyCriterias?.length" [color]="'exp'" [label]="'txtBreakout'| translate:language:ModuleNames.Wellbeing" [(ngModel)]="selectedCriteriaKey" [options]="surveyCriterias" [language]="language" (ngModelChange)="onCriteriaChange($event)">
        <ng-template #valueTemplate let-value>
          {{value?.name | extract:language }}
        </ng-template>
      </z-select>
    </div>
    <heatmap *ngIf="showHeatMap" [heatColumns]="heatmapData?.heatColumns" [heatSource]="heatmapData?.heatSource | heatmapFilter : selectedKey" [language]="language" (filter)="filterHeatMap($event)" [TourStep]="{
        title: 'txtHeatmap' | translate : language : ModuleNames.Wellbeing,
        text:
          'txt_Discovert_892' | translate : language : ModuleNames.Wellbeing,
        order: 5,
        tourKey: 'wellbeing-closed-0',
        on:'top-end'
      }" [TourStep]="{
        title:
          'txt_ExpandDim_862' | translate : language : ModuleNames.Wellbeing,
        text:
          'txt_Clickonea_896' | translate : language : ModuleNames.Wellbeing,
        order: 6,
        tourKey: 'wellbeing-closed-0',
        on:'top-start'
      }"></heatmap>
    <div class="flex flex-row justify-between">
      <h5 class="text-zen text-opacity-80">
        {{ "txtMinimum" | translate : language : ModuleNames.Wellbeing }}
      </h5>
      <h5 class="text-zen text-opacity-80">
        {{ "txtMedian" | translate : language : ModuleNames.Wellbeing }}
      </h5>
      <h5 class="text-zen text-opacity-80">
        {{ "txtMaximum" | translate : language : ModuleNames.Wellbeing }}
      </h5>
    </div>
  </ng-container>
</div>