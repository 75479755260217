import { Component, Input, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { QuestionDto, SectionDto } from '@ex/wellbeing/survey-templates';
import { SurveyDto } from '@ex/wellbeing/surveys';
import { Entity } from 'src/app/core/data-models/Entity';
import { Question } from 'src/app/core/data-models/question';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { ChildrenAnimation, listAnimation } from 'src/app/shared/animations/allAnimations';
import { QuestionTypes } from 'src/app/shared/questionnaire/questions/question-types';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
interface SectionSetup {
  key?: string,
  name: string,
  sections: SectionDto[]
}


@Component({
  selector: 'wb-survey-info',
  templateUrl: './wb-survey-info.component.html',
  styleUrls: ['./wb-survey-info.component.scss'],
  animations: [listAnimation, ChildrenAnimation]
})
export class WbSurveyInfoComponent extends WbBaseComponent implements OnInit {

  constructor(private db: AngularFireDatabase, private responseDialogService: ResponseDialogService) {
    super();
  }
  sectionsSetup: SectionSetup[];
  currentSelectedSection: SectionDto;
  sampleQuestion: boolean = false;
  surveyData: SurveyDto;
  mdCount = this.GlobalService.mdCount;
  lgCount = this.GlobalService.lgCount;
  private translationPipe: TranslatePipe
  @Input() selectedEntity: Entity
  @Input() submitted;
  @Input() language;
  @Input('survey') set surv(survey: SurveyDto) {
    this.surveyData = survey;
    if (this.surveyData?.survey?.sections?.length)
      this.sectionDistribution(this.surveyData.survey.sections);

    // this.surveyData.title == '-' ? this.surveyData.title = [] : '';
    // !this.surveyData.description ? this.surveyData.description = [] : '';
  };

  ngOnInit(): void {
  }
  sectionDistribution(tSections: SectionDto[]) {

    if (!tSections?.length)
      return
    this.sectionsSetup = [
      { name: 'txtDimensions', sections: tSections?.filter((x: SectionDto) => x.isPredefined) },
      { key: 'extraFactors', name: 'txtCustomDimensions', sections: tSections?.filter((x: SectionDto) => !x.isPredefined) },
    ];
    if (!this.currentSelectedSection)
      this.currentSelectedSection = this.sectionsSetup[0].sections[1];

  }
  sectionClicked(section: SectionDto, e?) {
    e.stopImmediatePropagation();
    this.currentSelectedSection = section;
  }

  addNewFactor() {
    if (this.sectionsSetup.find(s => s.key == 'extraFactors').sections.length== 5)
      return;
    let newFactor = {} as SectionDto;
    newFactor.key = this.db.createPushId();
    newFactor.name = [{ key: this.language, name: this.localize('txtDriverName', this.language, this.ModuleNames.Wellbeing) }];
    newFactor.isPredefined = false;
    newFactor.isSelected = false;
    newFactor.isFactor = true;
    newFactor.questions = [];
    let indexOfCustom = this.sectionsSetup.indexOf(this.sectionsSetup.find(s => s.key == 'extraFactors'));
    this.sectionsSetup[indexOfCustom].sections.push(newFactor);
    this.currentSelectedSection = newFactor;
    this.updateSource();
  }
  addNewQuestion(name?:any) {
    if (this.currentSelectedSection.questions.length > 3)
      return;

    let newQuestion = {} as QuestionDto;
    newQuestion.key = this.db.createPushId();
    newQuestion.questionType = QuestionTypes.SingleAnswerConfidence;
    newQuestion.name = name? name: [{ key: this.language, name: this.localize('txt_NewQuesti_189', this.language, this.ModuleNames.Wellbeing) + '#' + (this.currentSelectedSection.questions.length + 1) }];
    this.currentSelectedSection.questions.push(newQuestion);

    this.updateSource();
  }
  extraDriverValidation: boolean = false;
  extraDriverErrorCode: string;


  selectionClick(section: SectionDto) {
    if (section.isSelected) {
      if (this.sectionsSetup.map(x => x.sections.filter(n => n.isSelected && n.isPredefined)).flat().length < 4 && section.isPredefined)
        return this.SnackbarService.error(this.localize('txt_Dimension_415', this.language, this.ModuleNames.Wellbeing));
      section.isSelected = false;
    }
    else {
      if (this.sectionsSetup.map(x => x.sections.filter(n => n.isSelected)).flat().length > 7)
        return this.SnackbarService.error(this.localize('txt_Dimension_242', this.language, this.ModuleNames.Wellbeing));
      if (!this.validNameClass(section.name, section))
        return this.SnackbarService.error(this.localize('txt_Atleaston_223', this.language, this.ModuleNames.Wellbeing));
      section.isSelected = true;
    }

    this.updateSource();
  }

  async removeSection(factor) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_delete_item_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {
        let indexOfCustom = this.sectionsSetup.indexOf(this.sectionsSetup.find(s => s.key == 'extraFactors'));
        let indexOfTxtDimensions = this.sectionsSetup.indexOf(this.sectionsSetup.find(s => s.name == 'txtDimensions'));
        this.sectionsSetup[indexOfCustom].sections.splice(this.sectionsSetup[indexOfCustom].sections.indexOf(factor), 1);

        this.currentSelectedSection= this.sectionsSetup[indexOfCustom] !=null && this.sectionsSetup[indexOfCustom].sections != null&&
        this.sectionsSetup[indexOfCustom].sections.length >0 ? this.sectionsSetup[indexOfCustom].sections[0]: this.sectionsSetup[indexOfTxtDimensions].sections[0]
        this.updateSource();
      }
    } catch {

    }
  }
  async removeQuestion(question, section: SectionDto) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_delete_item_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {
        section.questions.splice(section.questions.indexOf(question), 1);
        this.updateSource();
        if (!section.questions.length)
          this.selectionClick(section);
      }
    } catch {

    }
  }

  updateSource() {
    this.surveyData.survey.sections = this.sectionsSetup.map(x => x.sections).flat();
  }

  extractNameLanguage(eG: any) {
    return this.LanguageService
      .extractNameLanguage(eG, this.language)
      .replaceAll('[Company Name]', this.selectedEntity.name)
  }

  getConfidence(subquestionKey: string, key: string) {
    if (subquestionKey == 'sub-questions-confidence-rating')
      return this.GeneralService.confidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) })?.name;
    if (subquestionKey == 'sub-questions-likeness-rating')
      return this.GeneralService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) })?.name;
    if (subquestionKey == 'sub-questions-likeness-na-rating')
      return this.GeneralService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) })?.name;
    if (subquestionKey == 'sub-questions-binary-likeness-rating')
      return this.GeneralService.binaryLikeness.find(cf => { return (cf.key == key && cf.lang == this.language) })?.name;
    if (subquestionKey == 'single-answer-rating')
      return this.GeneralService.singleAnswerConfidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) })?.name;
    if (subquestionKey == 'single-answer-out-10')
      return this.GeneralService.singleAnswerOutof10.find(cf => { return (cf.key == key) })?.name;
  }

  ngOnDestroy(): void {
    this.updateSource();
  }


  validNameClass(name, factor?: SectionDto) {
    if (factor) {

      if (!factor.name?.find(s => s.name.length > 3) || factor.questions.length == 0 || !factor.questions.every(x => x.name?.find(s => s.name.length > 3)))
        return false;
      else return true;
    }
    else {
      return name?.find(s => s.name.length > 3) ? true : false;
    }
  }

  returnSelectedFactors(sections) {
    return sections.filter((s: SectionDto) => s.isSelected)?.length;
  }
  canAddNewFactor() {
    if (this.sectionsSetup.map(x => x.sections.filter(n => n.isSelected)).flat().length > 7)
      return false;
    else
      return true;
  }

    showDemoSection(selectedSection) {
    if (!this.sectionsSetup)
      return;
    let indexParent = this.sectionsSetup?.indexOf(this.sectionsSetup.find(x => x.sections.find(m => m == selectedSection)));
    let index = this.sectionsSetup[indexParent]?.sections.indexOf(selectedSection);
    if (!indexParent && index > 1)
      return "Section";
    else if (indexParent && index)
      return "Section";
    return "Default";
  }

}
