/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DataForExcelUploaded } from '../models/DataForExcelUploaded';
import type { Respondent } from '../models/Respondent';
import type { SendEmail } from '../models/SendEmail';
import type { SendSurveyEmail } from '../models/SendSurveyEmail';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class SendEmailService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody 
     * @returns SendEmail Success
     * @throws ApiError
     */
    public sendEmail(
requestBody?: SendEmail,
): Observable<SendEmail> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/send-survey-email',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns SendSurveyEmail Success
     * @throws ApiError
     */
    public sendSurveyEmail(
requestBody?: SendSurveyEmail,
): Observable<SendSurveyEmail> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/send-survey-email/send-email',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns SendSurveyEmail Success
     * @throws ApiError
     */
    public sendSurveyReminderEmail(
requestBody?: SendSurveyEmail,
): Observable<SendSurveyEmail> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/send-survey-email/send-reminder-email',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param tenantId 
     * @param tenantName 
     * @param formId 
     * @param requestBody 
     * @returns DataForExcelUploaded Success
     * @throws ApiError
     */
    public excelBase64UploadedWithFormId(
tenantId: string,
tenantName: string,
formId: string,
requestBody?: DataForExcelUploaded,
): Observable<DataForExcelUploaded> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/send-survey-email/base64-file-with-form-id/{tenantId}/{tenantName}/{formId}',
            path: {
                'tenantId': tenantId,
                'tenantName': tenantName,
                'formId': formId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns Respondent Success
     * @throws ApiError
     */
    public addRespondentByEmails(
requestBody?: SendSurveyEmail,
): Observable<Respondent> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/send-survey-email/add-respondent-by-emails',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
