import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
// import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcludedCompanyFilterDto, OverallComparisonDto } from '@ex/wellbeing/analysis';
import { CompaniesListWithSurveysResponseDto, ComparisonService, TimeFrameDto } from '@ex/wellbeing/reports';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { filter, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Checklist } from 'src/app/core/data-models/checklist';
import { ChecklistGroup } from 'src/app/core/data-models/checklist-group';
import { ChecklistItem } from 'src/app/core/data-models/checklist-item';
import { Dashlet } from 'src/app/core/data-models/dashlet';
import { Entity } from 'src/app/core/data-models/Entity';
import { PreviewComponents } from 'src/app/core/data-models/preview-components';
import { Tenant } from 'src/app/core/data-models/tenant';
import { CHART_DATA_RESPONSE_STRATEGY } from 'src/app/core/services/chart-data.service';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { NativeExportBaseComponent } from 'src/app/shared/native-export-base/native-export-base.component';
import { DATA_TOKEN, ICDKPortalComponent } from 'src/app/shared/Theme/services/zen-cdk.service';
import { ZenSnackbarService, ZenSnackBarTypes } from 'src/app/shared/Theme/services/zen-snackbar.service';


@Component({
  selector: 'app-comparison-export',
  templateUrl: './comparison-export.component.html',
  styleUrls: ['./comparison-export.component.scss']
})
export class ComparisonExportComponent extends NativeExportBaseComponent implements OnInit, ICDKPortalComponent {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();
  language: string;
  ModuleNames = ModuleName;
  minDate: any;
  maxDate: any;
  timeFrameStart: any;
  timeFrameEnd: any;
  date: Date = new Date();
  chartColors: any[] = [];
  dimensionChart = [];
  companyChart = [];
  filters: any;
  isStart: boolean = false;

  supportedChartTypes: any[] = [
    {
      DashletType: 'barV',
      name: [
        { key: 'EN', name: 'Bar Horizontal' },
        { key: 'AR', name: 'رسم بياني بالأعمدة (أفقي)' },
      ],
    },
    {
      DashletType: 'line',
      name: [
        { key: 'EN', name: 'Bar Vertical' },
        { key: 'AR', name: 'رسم بياني بالأعمدة (عمودي)' },
      ],
    },
  ];

  overAllDashlet
  companyName: string
  firstLoad: boolean = true;
  selectedCompanies;
  obTermCharts: any;
  allCompanies: any;
  selectedEntity: any;
  constructor(
    @Inject(DATA_TOKEN) private data: any,
    // @Inject(MAT_DIALOG_DATA) public currentData: any,
    private changeDetectorRef: ChangeDetectorRef,
    private loader: LoaderService,
    private comparisonService: ComparisonService,
    private zenSnackBarService: ZenSnackbarService
  ) {
    super()
  }
  ngOnInit(): void {
    this.language = this.data.language;
    this.filters = this.data.filters;
    this.selectedEntity = this.data.selectedEntity;
    this.selectedCompanies = this.data.selectedCompanies;
    this.companyChart = this.data.companyChart;
    this.timeFrameStart = this.data.timeFrameStart;
    this.timeFrameEnd = this.data.timeFrameEnd;
    this.obTermCharts = this.data.obTermCharts;
    this.allCompanies = this.data.allCompanies;
    this.overAllDashlet = this.data.overAllDashlet;
    this.createDimensionCharts(this.data.dimensions);
  }

  createDimensionCharts(dimensions) {
    this.dimensionChart = [];
    for (let index = 0; index < dimensions.length; index++) {
      let dimension = JSON.parse(JSON.stringify(dimensions[index]));
      let tfilters = JSON.parse(JSON.stringify(this.filters));
      tfilters.dimensionKey = dimension.key;
      this.comparisonService.getOverallComparisonByDimensionKey(tfilters).subscribe(data => {
        data.forEach(x => {
          if (x.group.includes(','))
            x.group = this.getCompanyName(x.group.split(',')[0]) + '-' + x.group.split(',')[1];
          else
            x.group = this.getCompanyName(x.group)
        })

        const chartData = CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data).process();

        let defaultChartType = chartData.categories.length == 1 ? 'barV' : 'line';

        const chart = {
          Title: this.ExtractPipe.transform(dimension.name, this.language),
          DashletType: defaultChartType,
          canGroup: false,
          isDimension: true,
          question: {
            canGroup: true
          },
          DashletChartTypes: this.supportedChartTypes,
          data$: chartData
        };

        this.dimensionChart.push(chart);
      })

    }
  }

  getCompanyName(key: string) {
    const company = this.allCompanies.find(x => x.key == key);

    return this.ExtractPipe.transform(company?.name || [], this.language) || 'N/A';
  }
  returnCombined(arr: any[]) {
    let nArray = [];
    for (let index = 0; index < arr.length; index++) {
      nArray = nArray.concat(arr[index]);
    }
    console.log(nArray);

    return nArray;
  }
}
