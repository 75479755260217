import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';

@Component({
  selector: 'zen-tree',
  templateUrl: './zen-tree.component.html',
  styleUrls: ['./zen-tree.component.scss']
})
export class ZenTreeComponent extends BaseComponent implements OnInit {
  constructor() { super() }
  @ContentChild(TemplateRef) row: TemplateRef<any>;
  @Input('data') set tree(data: any) {
    this.treeList = new ArrayDataSource(data);
  }
  @Input() autoSelect = true;
  @Input() selectedEntity;
  @Output() selectedEntityChange = new EventEmitter<any>();
  treeList;
  treeControl = new NestedTreeControl<any>(node => node.children);
  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
  }
  filterEntity(entity) {
    console.log(this.treeControl);
    if (this.autoSelect)
      this.selectedEntity = entity;
    this.selectedEntityChange.emit(entity);
  }






}
