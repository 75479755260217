<div class="flex flex-row h-28 items-center justify-between bg-white shadow-2x4 gap-5 px-10 z-10 backdrop-filter backdrop-blur-xl bg-opacity-80">
    <div class="flex flex-col gap-1 whitespace-nowrap" #left [hidden]="!left.hasChildNodes()">
        <ng-content select="[left]"></ng-content>

    </div>
    <ng-content select="[full]"></ng-content>
    <div class="flex flex-row justify-end items-center gap-5 w-full" #right [hidden]="!right.hasChildNodes()">
        <ng-content select="[right]"></ng-content>
    </div>
</div>
