import { BehaviorSubject, Subject } from 'rxjs';
import { SurveyCriteriaDropdown } from './criteria-resolver.service'
import { Injectable } from '@angular/core';

export interface AnalyticsFilter {
  survey: {
    name: string,
    key: string,
    surveyCriterias?: SurveyCriteriaDropdown[],
    json: any,
    isAnonymous?:boolean
  };
  tenantKey: string;
  companyKey: string;
  language?: string;
  showExtraFactors?: boolean,
  timeframe?: TimeFrameFilter,
  chartFilters?: ChartFilter[];
  entityId?: string[];
}

export interface ChartFilter {
  key: string;
  value: any;
}

export interface TimeFrameFilter {
  startDate: Date;
  endDate: Date;
}


export enum AnalysisFilterKeys {
  TimeFrame = "Timeframe",
  EntityId = "Entity",
  Gender = "Gender",
  PerformanceRating = "PerformanceRate",
  Nationality = "Nationality",
  Generation = "Generation",
  AgeGroups = "AgeGroup",
  TenureGroups = "TenureGroup",
  BandLevel = "BandLevel",
  JobGrade = "JobGrade",
  Criteria = "criteria",
  IncludeExtraDrivers = "ExtraDriver",
  DateOfBirth = "DOB",
  DateOfJoining = "JOD",
  Page = "Page",
  Search = "Search"
}


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private filters = new BehaviorSubject<AnalyticsFilter>(null);
  filters$ = this.filters.asObservable();

  private filtersForReport = new BehaviorSubject<any>(null);
  filtersForReport$ = this.filtersForReport.asObservable();

  private dymmyFilters = new Subject<AnalyticsFilter>();
  dummyFilters$ = this.dymmyFilters.asObservable();

  private downloadComments = new Subject();
  downloadComments$ = this.downloadComments.asObservable();

  private downloadFeedback = new Subject();
  downloadFeedback$ = this.downloadFeedback.asObservable();

  private breakoutCriterias = new Map();

  constructor() { }

  setFilters(filters: AnalyticsFilter, isDummy = true) {

    if (isDummy)
      this.dymmyFilters.next(filters);
    else
      this.filters.next(filters);
  }

  mapGlobalFilters(filters: AnalyticsFilter) {
    let globalFilters: ChartFilter[] = [...filters?.chartFilters?.map(x => ({ key: x.key, value: JSON.stringify(x.value) })) || []];




    return globalFilters;
  }

  setFiltersForReport(evt) { this.filtersForReport.next(evt); }


  setDownloadComments() {
    this.downloadComments.next(true);
  }

  setDownloadFeedback() {
    this.downloadFeedback.next(true);
  }

}
