import { Component, OnInit } from '@angular/core';
import { AnswerScaleDto, QuestionAnalysisService, SectionMeanDto } from '@ex/exit/analysis';
import { AnalyticsFilter, AnalyticsService } from '@ex/module/exit/data-access/services/analytics.service';
import { ExitExperienceCodesService } from '@ex/module/exit/data-access/services/exit-experience-codes.service';
import { ExitBaseComponent } from '@ex/module/exit/ui/exit-base/exit-base.component';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-question-analysis',
  templateUrl: './question-analysis.component.html',
  styleUrls: ['./question-analysis.component.scss']
})
export class QuestionAnalysisComponent extends ExitBaseComponent implements OnInit {
  sections: SectionMeanDto[];
  filters: AnalyticsFilter;
  language;
  constructor(private questionAnalysis: QuestionAnalysisService, private analyticsService: AnalyticsService, public exitExperienceCodesService: ExitExperienceCodesService) {
    super();
  }


  ngOnInit(): void {
    this.fetchQuestions()
    const subscription = this.analyticsService.filters$
      .pipe(
        filter(filters => filters != null),
      )
      .subscribe(filters => {
        this.filtersLoaded.next(filters);
      })
    this.addSubscription(subscription);
    this.language = this.AnalysisComponentData.language;
  }

  fetchQuestions() {
    this.LoaderService.displayLoader();

    const subscription = this.filtersLoaded$.pipe(switchMap(filters => {
      return this.questionAnalysis.get({ companyKey: filters.companyKey, surveyKey: filters.survey.key, tenantKey: filters.tenantKey, filters: [...this.analyticsService.mapGlobalFilters(filters)] }).pipe(map(sections => ({ sections, filters })))
    })).subscribe(({ sections, filters }) => {
      this.sections = sections
      this.LoaderService.hideLoader();
    })

    this.addSubscription(subscription);
  }


  returnAnswers(answers: AnswerScaleDto[]) {
    const r = answers.map(x => ({ percentage: x.percentage, color: this.exitExperienceCodesService.resolveChartClass(x.value) }))
    return r;
  }
}
