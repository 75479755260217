import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseUpdateService {

  constructor(private afd: AngularFireDatabase) { }
  getListUpdate(tenantKey: string, companyKey: string, formId: string): Observable<any> {
    let trackValue = 1;
    const subject = new Subject();

    this.afd.database.ref(`TenantData/${tenantKey}/Survey-X/${companyKey}/SurveyStatus/${formId}/lastUpdated`).on("value", (snapshot) => {
      subject.next(++trackValue);
    })

    return subject.asObservable();

  }
}
