<zen-main-container>
  <sidebar-container>
    <back-button [routerLink]="['/exit-v2/home']">
      <i [ngClass]="(language | language )?'fa-arrow-right':'fa-arrow-left'" class="fa-regular"></i>
      <span>{{'btn_back'| translate:language:ModuleNames.Exit}}</span>
    </back-button>
    <section-sidebar [title]="'LEAVER DETAILS' | translate:language:ModuleNames.Exit ">
      <div class="flex flex-col gap-5 px-8">
        <div class="flex flex-row rounded ">
          <a (click)="personal = true"
            [ngClass]="personal?'bg-white text-exp font-medium':'bg-zen text-zen bg-opacity-10'"
            class="w-1/2 text-sm leading-10 text-center btn">{{'Personal'| translate:language:ModuleNames.Exit}}</a>
          <a (click)="personal = false"
            [ngClass]="!personal?'bg-white text-exp font-medium':'bg-zen text-zen bg-opacity-10'"
            class="w-1/2 text-sm leading-10 text-center btn">{{'Positional'| translate:language:ModuleNames.Exit}}</a>
        </div>
        <div *ngIf="leaverAnswers" class="flex flex-col">
          <div *ngIf="personal" class="flex flex-col">
            <div class="pb-3" *ngIf="leaver.employeeID">
              <h6 class="text-xs text-zen">{{'txt_leaver_employee_ID'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.employeeID}}</h6>
            </div>
            <div class="py-3">
              <h6 class="text-xs text-zen">{{'txt_leaver_employee_name'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.name}}</h6>
            </div>

            <div class="py-3">
              <h6 class="text-xs text-zen">{{'txt_leaver_gender'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{ extractGender(leaver.genderKey)|extract:language}}</h6>
            </div>
            <div class="py-3">
              <h6 class="text-xs text-zen">{{'txt_leaver_date_of_birth'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.dateOfBirth | date:'dd/MM/yyyy'}}</h6>
            </div>
            <div class="py-3">
              <h6 class="text-xs text-zen">{{'txt_leaver_nationality'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{extractCountry(leaver.nationalityKey)|extract:language}}</h6>
            </div>

            <div class="py-3 -mr-10 -ml-10">
              <mat-divider></mat-divider>
            </div>
            <div class="py-3">
              <h6 class="text-xs text-zen">{{'txt_leaver_email'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.email}}</h6>
            </div>
            <div class="py-3" *ngIf="leaver.mobileNumber">
              <h6 class="text-xs text-zen">{{'txt_leaver_mobile_number'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen" (click)="logData(extractCountryCode(leaver.countryCode))">
                {{'+'+extractCountryCode(leaver.countryCode)}} {{leaver.mobileNumber}}</h6>
            </div>
          </div>
          <div *ngIf="!personal" class="flex flex-col">
            <div class="pb-3">
              <h6 class="text-xs text-zen">{{'txt_leaver_seperation_type'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen-red">{{extractType(leaver.seperationType)}}</h6>
            </div>
            <div class="py-3" *ngIf="leaver.dateOfBirth">
              <h6 class="text-xs text-zen">{{'txt_leaver_joining_date'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.dateOfBirth | date:'dd/MM/yyyy'}}</h6>
            </div>
            <div class="py-3" *ngIf="leaver.seperationDate">
              <h6 class="text-xs text-zen">{{'txt_leaver_seperation_date'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.seperationDate | date:'dd/MM/yyyy'}}</h6>
            </div>
            <div class="py-3" *ngIf="leaver.timeFrameEnd">
              <h6 class="text-xs text-zen">{{'txt_leaver_end_date'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.timeFrameEnd | date:'dd/MM/yyyy'}}</h6>
            </div>

            <div class="py-3" *ngIf="leaver.jobTitle">
              <h6 class="text-xs text-zen">{{'txt_leaver_job_title'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.jobTitle }}</h6>
            </div>
            <div class="py-3" *ngIf="leaver.bandLevel">
              <h6 class="text-xs text-zen">{{'txt_leaver_job_level'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{extractJobLevel(leaver.bandLevel) | extract:language}}</h6>
            </div>
            <div class="py-3" *ngIf="leaver.jobGrade">
              <h6 class="text-xs text-zen">{{'txt_leaver_job_grade'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{extractJobGrade(leaver.jobGrade) | extract:language}}</h6>
            </div>
            <div class="py-3" *ngIf="leaver.performanceRating">
              <h6 class="text-xs text-zen">{{'txt_leaver_performance_rate'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{extractPerformanceRate(leaver.performanceRating)}}</h6>
            </div>

            <div class="py-3" *ngIf="leaver.directManagerId">
              <h6 class="text-xs text-zen">{{'txt_direct_manager_ID'| translate:language:ModuleNames.Exit}}</h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.directManagerId}}</h6>
            </div>
            <div class="py-3" *ngIf="leaver.directManagerName">
              <h6 class="text-xs text-zen">{{'txt_leaver_direct_manager_name'| translate:language:ModuleNames.Exit}}
              </h6>
              <h6 class="text-sm font-medium text-zen">{{leaver.directManagerName}}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="-mr-10 -ml-10 pb-5">
        <mat-divider></mat-divider>
      </div>
      <div class="flex flex-col gap-5 px-8">
        <div class="flex flex-row justify-between items-center">
          <h6 [@child3] class="text-xxs font-medium text-zen uppercase">{{"Notes" |translate:language:ModuleNames.Exit}}
          </h6>
          <i *DemoAccount="'Button'; let isDemo=isDemo"
            class="fa-regular fa-plus w-9 h-9 text-center rounded leading-9 text-sm btn cancel"
            (click)="isDemo?'':addNewNote()"></i>
        </div>
        <div class="flex flex-col gap-2.5">
          <div class="flex flex-col items-stretch bg-white rounded p-3" *ngFor="let note of userNotes; let n = index">
            <div class="flex flex-row justify-between">
              <h6 class="text-xs font-medium text-zen-lite">{{note.userName}}</h6>
              <i *ngIf="user.key == note.createdByUser"
                class="fa-regular text-xs fa-ellipsis w-5 h-5 text-center leading-5 btn cancel rounded-sm"
                [ZMenuTriggerFor]="menu"></i>
            </div>
            <p class="text-xxs font-normal text-zen-lite py-2.5 break-words">{{note.note}}</p>
            <div class="flex flex-row justify-end">
              <p class="text-xxs font-normal text-zen-grey">{{note.lastModifiedTime |date:'dd/MM/yyyy'}}</p>
            </div>
            <z-menu #menu>
              <menuItems>
                <a MenuItem (click)="addNewNote(note)"><i class="fal fa-pen mx-1"></i> {{"Edit Note"
                  |translate:language:ModuleNames.Exit}}</a>
                <a MenuItem delete (click)="deleteNote(note)"><i class="fal fa-trash mx-1 "> </i> {{"Delete Note"
                  |translate:language:ModuleNames.Exit}}</a>
              </menuItems>
            </z-menu>
          </div>

        </div>
      </div>
    </section-sidebar>
  </sidebar-container>
  <content-container class="flex flex-col items-center w-full">
    <div class="flex flex-col items-stretch h-28 z-10 sticky" sticky [top]="topValue">
      <section-head>
          <ng-container full>
            <div class="flex flex-row justify-between w-full">
              <div class="flex flex-row  justify-end w-2/12"></div>
              <div class="flex flex-col items-center gap-1 max-w-screen-md w-full relative">
                <h2 class="text-2xl font-semibold text-zen"> {{ leaver?.name }}</h2>
                <h2 class="text-xxs font-medium text-zen"> {{ 'txtLeaversAnswers'| translate:language:ModuleNames.Exit }}
                </h2>
                <div class="customShadowBottom absolute -bottom-8 h-8 -left-10 -right-10"></div>
              </div>
              <div class="flex flex-row justify-end w-2/12">
                  <a *DemoAccount="'Button'; let isDemo=isDemo"
                    class="btn leading-12 rounded text-center text-sm cancel flex flex-row items-center gap-2 px-5"
                    (click)="isDemo?'':openExportPreviewDialog()">
                    <i class="text-sm text-zen fal fa-download"></i>
                    <span>{{'btnExport'| translate:language:ModuleNames.Exit}}</span>
                  </a>
              </div>
            </div>
          </ng-container>
      </section-head>
    </div>
    <div class="flex flex-col items-center w-full" *DemoAccount="'Default'; let isDemo=isDemo">
      <ng-contianer *ngIf="leaverAnswers && leaverAnswers?.sections?.length > 0">
        <ng-container *ngFor="let section of leaverAnswers.sections; let sI = index">
          <ng-container *ngIf="!isDemo || isDemo && sI < 2">
            <div class="flex flex-col my-10 px-10 gap-10">
              <h3 class="text-zen text-xl font-medium">
                {{'txtSection'|translate:language:ModuleNames.Exit}} {{sI+1}} {{section.name| extract:language}}
              </h3>
              <ng-container class="flex flex-col" *ngFor="let question of section?.questions; let qI = index">
                <ng-container
                  *ngIf="(resignationCheck(question) && !question.dependsOnQuestionKey) || dependsOnQuestionCheck(question, section) || !question.conditionallySkippable">
                  <div id="leaver-answer-{{ sI }}-{{ qI }}"
                    class="flex flex-col rounded border border-zen border-opacity-10 shadow-sm bg-white max-w-5xl">
                    <div
                      class="flex gap-4 min-h-18 px-3 flex-row justify-start items-center border-b border-zen border-opacity-20">
                      <span
                        class="bg-zen bg-opacity-5 text-zen leading-7 font-medium w-7 max-h-7 text-center text-lg rounded-full">{{'txtQ'|
                        translate:language:ModuleNames.Exit}}</span>
                      <h3 (click)="logData(question)" class="text-zen text-lg font-medium">
                        {{extractNameLanguage(question.name)}}
                      </h3>
                    </div>

                    <!-- <h4>{{extractNameLanguage(question.name)}}</h4> -->

                    <!---------------------------------- SUB QUESTIONS ---------------------------------------->
                    <!----------------------------------------------------------------------------------------->
                    <!----------------------------------------------------------------------------------------->
                    <!----------------------------------------------------------------------------------------->

                    <ng-container *ngIf="question.questionType == 'sub-questions-confidence-rating' ||
              question.questionType == 'sub-questions-likeness-rating' || question.questionType == 'single-answer-out-10'||
              question.questionType == 'sub-questions-likeness-na-rating' || question.questionType == 'sub-questions-binary-rating'
              || question.questionType == 'sub-questions-binary-likeness-rating' || question.questionType == 'single-answer-confidence-with-na' ||
              question.questionType == 'sub-questions-binary-likeness-na-rating'">

                      <!---------------------------------- sub-questions-confidence-rating ---------------------------------------->
                      <!----------------------------------------------------------------------------------------------------------->

                      <div class="fullWidthflex flex-col"
                        *ngIf="question.questionType == 'sub-questions-confidence-rating'" style="width: 100%;">
                        <ng-container *ngTemplateOutlet="ratingTemplate;context:{ $implicit: question }">
                        </ng-container>
                      </div>
                      <!---------------------------------- sub-questions-likeness-rating ---------------------------------------->
                      <!----------------------------------------------------------------------------------------------------------->

                      <div class="fullWidthflex flex-col"
                        *ngIf="question.questionType == 'sub-questions-likeness-rating'" style="width: 100%;">
                        <ng-container *ngTemplateOutlet="ratingTemplate;context:{ $implicit: question }">
                        </ng-container>
                      </div>
                      <!---------------------------------- sub-questions-likeness-na--rating ---------------------------------------->
                      <!----------------------------------------------------------------------------------------------------------->

                      <div class="fullWidthflex flex-col"
                        *ngIf="question.questionType == 'sub-questions-likeness-na-rating'" style="width: 100%;">
                        <ng-container *ngTemplateOutlet="ratingTemplate;context:{ $implicit: question }">
                        </ng-container>

                      </div>

                      <!---------------------------------- sub-questions-binary-likeness-rating ---------------------------------------->
                      <!----------------------------------------------------------------------------------------------------------->

                      <div class="fullWidthflex flex-col p-3"
                        *ngIf="question.questionType == 'sub-questions-binary-likeness-rating'" style="width: 100%;">
                        <div class="flex flex-col gap-5  w-full">
                          <div class="md:flex flex-row justify-between items-center gap-3 hidden">
                            <h4 class="text-zen  text-xxs font-normal">
                              {{extractNameLanguage(question.headingName)}}</h4>

                            <div class="flex flex-row w-2/12 justify-evenly">

                              <h5 class="text-zen  text-xxs font-normal text-center">{{
                                getConfidence('sub-questions-binary-likeness-rating',
                                'LL')
                                }}</h5>
                              <h5 class="text-zen  text-xxs font-normal text-center">
                                {{ getConfidence('sub-questions-binary-likeness-rating', 'ML') }}</h5>
                            </div>

                          </div>

                          <div class="flex flex-row flex-wrap">
                            <div class="md:flex flex-row justify-between items-center w-full py-1"
                              *ngFor="let subQuestion of question.subQuestions; let ssq = index">
                              <div class="flex flex-col w-full  rounded">
                                <div
                                  class="flex flex-row w-full gap-0 md:gap-3 flex-wrap md:flex-nowrap   rounded p-4 md:p-0">
                                  <div
                                    *ngIf="!subQuestion.otherAnswer || (subQuestion.otherAnswer && ssq == question.subQuestions.length)"
                                    class="flex  bg-zen bg-opacity-5 rounded px-5 leading-12 exp w-full"
                                    [ngClass]="subQuestion.otherAnswer?'w-full':'md:w-10/12'">
                                    <mat-checkbox disabled *ngIf="subQuestion.otherAnswer"
                                      [ngModel]="subQuestion.otherAnswerChecked">
                                      <h5 class="text-zen text-base leading-12">
                                        {{extractNameLanguage(subQuestion.name)}}
                                      </h5>
                                    </mat-checkbox>
                                    <p class="text-zen text-base leading-12" *ngIf="!subQuestion.otherAnswer">
                                      {{extractNameLanguage(subQuestion.name)}}</p>
                                  </div>
                                  <div *ngIf="!subQuestion.otherAnswer"
                                    class="flex flex-row md:w-2/12 w-full gap-1 md:pt-0 pt-2">

                                    <div class="flex flex-row items-center justify-center w-6/12 bg-white rounded"
                                      [ngClass]="subQuestion.answer == 'LL'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">
                                      <i
                                        class="fal fa-thumbs-down md:text-2xl text-4xl md:leading-12 leading-18 rounded w-full text-center"></i>
                                    </div>

                                    <div class="flex flex-row items-center justify-center w-6/12 bg-white rounded">
                                      <i [ngClass]="subQuestion.answer == 'ML'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'"
                                        class="fal fa-thumbs-up md:text-2xl text-4xl md:leading-12 leading-18 rounded w-full text-center"></i>
                                    </div>
                                  </div>

                                </div>
                                <div class="flex flex-row flex-wrap md:flex-nowrap gap-2 mt-2 w-full"
                                  *ngIf="subQuestion.otherAnswerChecked && subQuestion.otherAnswer">
                                  <div class="flex flex-col md:w-1/2 w-full">
                                    <div class="bg-zen-red bg-opacity-5 rounded-md px-5 pt-1 overflow-hidden ">
                                      <mat-form-field class="w-full -mt-3" appearance="none">
                                        <textarea disabled rows="3" maxlength="300"
                                          [placeholder]="'txtLeastLiked'| translate:language:ModuleNames.Exit"
                                          [ngModel]="question.otherAnswer2" matInput></textarea>
                                      </mat-form-field>

                                    </div>

                                  </div>
                                  <div class="flex flex-col md:w-1/2 w-full">
                                    <div class="bg-zen-green bg-opacity-5 rounded-md px-5 pt-1 overflow-hidden ">
                                      <mat-form-field class="w-full -mt-3" appearance="none">
                                        <textarea disabled rows="3" maxlength="300"
                                          [placeholder]="'txtMostLiked'| translate:language:ModuleNames.Exit"
                                          [ngModel]="question.otherAnswer1" matInput></textarea>
                                      </mat-form-field>
                                    </div>

                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex flex-col gap-2 items-stretch"
                        *ngIf="question.questionType == 'single-answer-confidence-with-na'">
                        <div class="flex flex-row w-full gap-1 items-stretch p-3">
                          <div class="flex flex-row items-center justify-center w-1/5">
                            <div
                              [ngClass]="question.answer == '0'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'"
                              class="flex flex-col items-center justify-start py-3 w-full   bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                              <i
                                class="fa-light fa-ban text-4xl md:text-5xl leading-18 rounded-lg w-full text-center"></i>
                              <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                                getConfidence('single-answer-confidence-with-na', '0')}}</h5>
                            </div>
                          </div>
                          <div class="flex flex-row  w-full gap-1  items-stretch">
                            <div class="flex flex-row items-center justify-center w-1/4">
                              <div
                                [ngClass]="question.answer == '1'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'"
                                class="flex flex-col items-center justify-start py-3 w-full   bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                                <i
                                  class="fa-light fa-face-pouting text-4xl md:text-5xl leading-18 rounded-lg w-full text-center"></i>
                                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                                  getConfidence('single-answer-confidence-with-na', '1')}}</h5>
                              </div>
                            </div>

                            <div class="flex flex-row items-center justify-center w-1/4">
                              <div
                                [ngClass]="question.answer == '2'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'"
                                class="flex flex-col items-center justify-start py-3 w-full   bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                                <i
                                  class="fa-light fa-face-worried text-4xl md:text-5xl leading-18 rounded-lg w-full text-center"></i>
                                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                                  getConfidence('single-answer-confidence-with-na', '2')}}</h5>
                              </div>
                            </div>
                            <div class="flex flex-row items-center justify-center w-1/4">
                              <div
                                [ngClass]="question.answer == '3'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'"
                                class="flex flex-col items-center justify-start py-3 w-full   bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                                <i
                                  class="fa-light  text-4xl md:text-5xl leading-18 rounded-lg w-full text-center fa-meh"></i>
                                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                                  getConfidence('single-answer-confidence-with-na','3')}}</h5>
                              </div>
                            </div>

                            <div class="flex flex-row items-center justify-center w-1/4">
                              <div
                                [ngClass]="question.answer == '4'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'"
                                class="flex flex-col items-center justify-start py-3 w-full   bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                                <i
                                  class="fa-light  text-4xl md:text-5xl leading-18 rounded-lg w-full text-center fa-face-smile-relaxed"></i>
                                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                                  getConfidence('single-answer-confidence-with-na','4')}}</h5>
                              </div>
                            </div>
                            <div class="flex flex-row items-center justify-center w-1/4">
                              <div
                                [ngClass]="question.answer == '5'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'"
                                class="flex flex-col items-center justify-start py-3 w-full   bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                                <i
                                  class="fa-light  text-4xl md:text-5xl leading-18 rounded-lg w-full text-center fa-face-laugh-beam"></i>
                                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                                  getConfidence('single-answer-confidence-with-na','5')}}</h5>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div *ngIf="question?.comment?.length" class="px-3 pb-3 fullWidthflex flex-row container ">
                          <div
                            class="rounded border text-zen border-zen border-opacity-10 p-3 overflow-hidden bg-white">
                            <span>{{question.comment}}</span>
                          </div>
                        </div>

                      </div>

                      <app-single-answer-ten *ngIf="question.questionType == 'single-answer-out-10'"
                        [question]="question" [legends]="false">
                        <ng-container legends>
                          <div class="flex flex-row justify-between px-3 pb-3"
                            *ngIf="question.customKey =='satisfaction'">
                            <span class="text-xs text-zen-lite transform  upperFirstLetter">{{'txtVeryPoor'|
                              translate:language:ModuleNames.Exit}}</span>
                            <span class="text-xs text-zen-lite transform  upperFirstLetter">{{'txtVeryGood'|
                              translate:language:ModuleNames.Exit}}</span>
                          </div>
                          <div *ngIf="question.customKey =='enps'"
                            class="flex flex-row  items-center justify-between  h-2.5 mb-5 px-2">
                            <div class="px-1 " [style.width.%]="legend.steps / 11 * 100"
                              *ngFor="let legend of legends; let n = index">
                              <div
                                class="h-2.5 border-r border-l border-b border-zen border-opacity-20 flex flex-row items-end justify-center">
                                <span
                                  class="text-xs text-zen-lite transform translate-y-2 px-3 upperFirstLetter bg-white">{{legend.name
                                  | translate:language:ModuleNames.Exit
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </app-single-answer-ten>
                    </ng-container>

                    <!-------------------------------------------------------------------------------------------->
                    <!-------------------------------------------------------------------------------------------->
                    <!-------------------------------------------------------------------------------------------->
                    <!-------------------------------------------------------------------------------------------->
                    <!-------------------------------------------------------------------------------------------->

                    <div class="question-answers fullWidthflex flex-col p-3"
                      *ngIf="question.questionType == 'rating-liked'" style="width: 100%;">
                      <div class="flex flex-col gap-5  w-full">
                        <div class="flex flex-row flex-wrap">
                          <div class="md:flex flex-row justify-between items-center w-full py-1">
                            <div class="flex flex-col w-full gap-5">
                              <div class="flex flex-row  w-full gap-1 md:pt-0 pt-2">
                                <div class="flex flex-row items-center justify-center w-1/4">
                                  <div class="flex flex-col items-center justify-center w-full rounded h-15"
                                    [ngClass]="question.answer == 'P'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">
                                    <i class="fa-light fa-face-disappointed text-3xl  text-center"></i>
                                    <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"> {{
                                      getConfidence('sub-questions-likeness-rating', 'P') }}</h5>
                                  </div>
                                </div>
                                <div class="flex flex-row items-center justify-center w-1/4">
                                  <div class="flex flex-col items-center justify-center w-full rounded h-15"
                                    [ngClass]="question.answer == 'F'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">
                                    <i class="fa-light fa-face-thinking text-3xl  text-center"></i>
                                    <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"> {{
                                      getConfidence('sub-questions-likeness-rating', 'F') }}</h5>
                                  </div>
                                </div>
                                <div class="flex flex-row items-center justify-center w-1/4">
                                  <div class="flex flex-col items-center justify-center w-full rounded h-15"
                                    [ngClass]="question.answer == 'G'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">
                                    <i class="fal fa-smile text-3xl  text-center"></i>
                                    <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"> {{
                                      getConfidence('sub-questions-likeness-rating', 'G') }}</h5>
                                  </div>
                                </div>
                                <div class="flex flex-row items-center justify-center w-1/4">
                                  <div class="flex flex-col items-center justify-center w-full rounded h-15"
                                    [ngClass]="question.answer == 'EX'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">

                                    <i class="fal fa-smile-beam text-3xl  text-center"></i>
                                    <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"> {{
                                      getConfidence('sub-questions-likeness-rating', 'EX') }}</h5>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container class="fullWidthflex flex-col" *ngIf="question.questionType == 'multi-options-multi-answer' ||
                               question.questionType == 'multi-options-single-answer'">
                      <div *ngIf="question.questionType == 'multi-options-multi-answer'" class="p-3">
                        <div class="flex flex-row flex-wrap items-stretch">
                          <div [ngClass]="option.otherAnswer?'w-full':'w-1/2'" class="flex flex-col p-1 "
                            *ngFor="let option of question.questionOptions; let n = index">
                            <div class="  rounded  px-5 h-full"
                              [ngClass]="checkValue(question.answer, option.key)?'bg-exp bg-opacity-20':'bg-zen bg-opacity-5'">
                              <div class="flex flex-row gap-3 items-center">
                                <i [ngClass]="checkValue(question.answer, option.key)?'fa-solid fa-check-square text-exp':'fas fa-square opacity-20 text-zen'"
                                  class="text-2xl leading-12"></i>
                                <h5 class="text-zen leading-5">
                                  <span
                                    class="text-base font-normal  leading-5">{{extractNameLanguage(option.name)}}</span>
                                </h5>
                              </div>

                              <p class="text-zen font-normal"
                                *ngIf="question.otherAnswer1 && option?.key=='-N-EjKa2N_T-HTsI5l3j'">
                                {{question.otherAnswer1}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col gap-2 p-5"
                        *ngIf="question.questionType == 'multi-options-single-answer' && question.answer"
                        style="width: 100%;">
                        <ng-container *ngIf="!question?.hideOptionsInPreview">
                          <ng-container *ngFor="let option of question.questionOptions; let i = index">
                            <!-- <mat-checkbox disabled [value]="option.key" (change)="question.answer = option.key"
                          [checked]="question.answer == option.key">
                          <h5 class="text-zen text-lg leading-12 whitespace-normal">{{extractNameLanguage(option.name)}}
                          </h5>
                        </mat-checkbox> -->

                            <div class="rounded  px-5 h-full"
                              [ngClass]="question.answer == option.key?'bg-exp bg-opacity-20':'bg-zen bg-opacity-5'">
                              <div class="flex flex-row gap-3 items-center">
                                <i [ngClass]="question.answer == option.key?'fa-solid fa-check-square text-exp':'fas fa-square opacity-20 text-zen'"
                                  class="text-2xl leading-12"></i>
                                <h5 class="text-zen leading-12">
                                  <span class="text-base font-normal">{{extractNameLanguage(option.name)}}</span>
                                </h5>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="question?.hideOptionsInPreview">
                          <ng-container *ngFor="let option of question.questionOptions; let i = index">
                            <!-- <mat-checkbox disabled [value]="option.key" (change)="question.answer = option.key"
                          [checked]="question.answer == option.key">
                          <h5 class="text-zen text-lg leading-12 whitespace-normal">{{extractNameLanguage(option.name)}}
                          </h5>
                        </mat-checkbox> -->

                            <div *ngIf="question.answer == option.key" class="rounded  px-5 h-full"
                              [ngClass]="question.answer == option.key?'bg-exp bg-opacity-20':'bg-zen bg-opacity-5'">
                              <div class="flex flex-row gap-3 items-center">
                                <i [ngClass]="question.answer == option.key?'fa-solid fa-check-square text-exp':'fas fa-square opacity-20 text-zen'"
                                  class="text-2xl leading-12"></i>
                                <h5 class="text-zen leading-12">
                                  <span class="text-base font-normal">{{extractNameLanguage(option.name)}}</span>
                                </h5>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                        <div *ngIf="checkOther(question)" class="px-3 w-full">
                          <div class="bg-zen bg-opacity-10 rounded-md px-5 pt-1 overflow-hidden w-full">
                            <mat-form-field class="w-full -mt-3" appearance="none">
                              <textarea disabled rows="3" maxlength="300"
                                [placeholder]="'txtSpecifyHere'| translate:language:ModuleNames.Exit"
                                [ngModel]="question.otherAnswer1" matInput></textarea>
                            </mat-form-field>

                          </div>

                        </div>

                        <!-- <mat-radio-group class="flex flex-col is-offset-one-quarter is-half is-offset-one-quarter"
                          *ngFor="let option of question.questionOptions; let n = index">
                          <mat-radio-button class="answer-option" [checked]="option.key == question.answer" disabled=true
                            [value]="option.key">
                            <h5 class="font-bold text-zen">{{extractNameLanguage(option.name)}}</h5>
                          </mat-radio-button>
                        </mat-radio-group> -->


                        <!-- <div *ngIf="checkOther(question)">
                        <div class="fullWidthflex flex-col container px-6 ">
                          <mat-form-field class=" px-0 py-0" appearance="fill">
                            <textarea disabled maxlength="300" disabled=true [ngModel]="question.otherAnswer1" matInput></textarea>
                          </mat-form-field>

                        </div>
                      </div> -->
                      </div>
                    </ng-container>


                    <!---------------------------------- Single QUESTIONS ---------------------------------------->
                    <!-------------------------------------------------------------------------------------------->



                    <div class="fullWidthflex flex-row container p-5" *ngIf="question.questionType == 'text'">
                      <div class="rounded border text-zen border-zen border-opacity-10 p-3 overflow-hidden bg-white">
                        <span>{{question.comment?question.comment: '-'}}</span>
                      </div>
                    </div>

                  </div>
                </ng-container>
              </ng-container>

            </div>
          </ng-container>
        </ng-container>
        <div class="flex flex-col my-10 px-10 gap-10 pt-5" *ngIf="userNotes?.length">
          <h3 class="text-zen text-xl font-medium">
            {{"Leaver Notes" |translate:language:ModuleNames.Exit}}
          </h3>
          <div class="flex flex-col rounded border border-zen border-opacity-10 shadow-sm bg-white max-w-5xl">
            <ng-container *ngFor="let note of userNotes; let n = index">
              <mat-divider *ngIf="n != 0"></mat-divider>
              <div class="flex flex-col gap-2.5 p-10">
                <div class="flex flex-row justify-between items-center text-xxs">
                  <h5 class="font-medium text-zen-lite uppercase">{{note.userName}}</h5>
                  <h5 class="font-normal text-zen-grey">{{note.creationTime|date:'dd/MM/yyyy, h:mm a'}}</h5>
                </div>
                <h5 class="font-normal text-zen text-sm breakWords">{{note.note}}</h5>
              </div>
            </ng-container>
          </div>
        </div>

      </ng-contianer>
    </div>
  </content-container>
</zen-main-container>






<ng-template #ratingTemplate let-question>
  <div class="flex flex-col gap-5 p-5 w-full">
    <div class="md:flex flex-row justify-between items-center gap-3 hidden">
      <div class="flex flex-row md:w-8/12">
        <h4 class="text-zen text-xxs font-normal ">
          {{extractNameLanguage(question.headingName)}}</h4>
      </div>


      <div *ngIf="question.questionType == 'sub-questions-confidence-rating'"
        class="flex flex-row w-5/12 justify-evenly">
        <h5 class="text-zen text-xxs font-normal w-1/4 text-center">{{
          getConfidence('sub-questions-confidence-rating', 'SDA')
          }}</h5>
        <h5 class="text-zen text-xxs font-normal w-1/4 text-center">{{
          getConfidence('sub-questions-confidence-rating', 'DA')
          }}</h5>
        <h5 class="text-zen text-xxs font-normal w-1/4 text-center">{{
          getConfidence('sub-questions-confidence-rating', 'A')
          }}</h5>

        <h5 class="text-zen text-xxs font-normal w-1/4 text-center">
          {{ getConfidence('sub-questions-confidence-rating', 'STA') }}</h5>


      </div>
      <div
        *ngIf="question.questionType == 'sub-questions-likeness-rating' || question.questionType == 'sub-questions-likeness-na-rating'"
        class="flex flex-row  justify-evenly md:w-5/12">
        <h5 *ngIf="question.questionType == 'sub-questions-likeness-na-rating'"
          class="text-zen text-xxs font-normal w-1/5 text-center">{{
          getConfidence('sub-questions-likeness-rating', 'NA')
          }}</h5>
        <h5 class="text-zen text-xxs font-normal text-center"
          [ngClass]="{'w-1/5':question.questionType == 'sub-questions-likeness-na-rating', 'w-1/4':question.questionType != 'sub-questions-likeness-na-rating'}">
          {{
          getConfidence('sub-questions-likeness-rating', 'P')
          }}</h5>
        <h5 class="text-zen text-xxs font-normal text-center"
          [ngClass]="{'w-1/5':question.questionType == 'sub-questions-likeness-na-rating', 'w-1/4':question.questionType != 'sub-questions-likeness-na-rating'}">
          {{
          getConfidence('sub-questions-likeness-rating', 'F')
          }}</h5>

        <h5 class="text-zen text-xxs font-normal text-center"
          [ngClass]="{'w-1/5':question.questionType == 'sub-questions-likeness-na-rating', 'w-1/4':question.questionType != 'sub-questions-likeness-na-rating'}">
          {{
          getConfidence('sub-questions-likeness-rating', 'G')
          }}</h5>

        <h5 class="text-zen text-xxs font-normal text-center"
          [ngClass]="{'w-1/5':question.questionType == 'sub-questions-likeness-na-rating', 'w-1/4':question.questionType != 'sub-questions-likeness-na-rating'}">
          {{ getConfidence('sub-questions-likeness-rating', 'EX') }}</h5>


      </div>


    </div>

    <div class="flex flex-row flex-wrap">
      <div class="md:flex flex-row justify-between items-center w-full py-1"
        *ngFor="let subQuestion of question.subQuestions; let ssq = index">
        <div class="flex flex-col w-full">
          <div class="flex flex-row w-full gap-0 md:gap-3 flex-wrap md:flex-nowrap rounded p-4 md:p-0">
            <div class="flex  bg-zen bg-opacity-5 items-center rounded px-5 leading-12 exp w-full md:w-8/12">
              <p class="text-zen text-base leading-5"> {{extractNameLanguage(subQuestion.name)}}</p>
            </div>
            <div *ngIf="question.questionType == 'sub-questions-confidence-rating'"
              class="flex flex-row md:w-5/12 w-full gap-1 md:pt-0 pt-2">
              <div class="flex flex-row items-center justify-center w-1/4 bg-white rounded"
                [ngClass]="subQuestion.answer == 'SDA'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">
                <i
                  class="fal fa-face-disappointed md:text-2xl text-4xl btn md:leading-12 leading-18 rounded w-full text-center"></i>
              </div>
              <div class="flex flex-row items-center justify-center w-1/4 bg-white rounded"
                [ngClass]="subQuestion.answer == 'DA'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">
                <i
                  class="fal fa-face-frown-slight md:text-2xl text-4xl btn md:leading-12 leading-18 rounded w-full text-center"></i>
              </div>
              <div class="flex flex-row items-center justify-center w-1/4 bg-white rounded"
                [ngClass]="subQuestion.answer == 'A'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">
                <i
                  class="fal fa-smile md:text-2xl text-4xl btn md:leading-12 leading-18 rounded w-full text-center"></i>
              </div>

              <div class="flex flex-row items-center justify-center w-1/4 "
                [ngClass]="subQuestion.answer == 'STA'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">
                <i
                  class="fal fa-smile-beam md:text-2xl text-4xl btn md:leading-12 leading-18 rounded w-full text-center"></i>
              </div>
            </div>
            <div
              *ngIf="question.questionType == 'sub-questions-likeness-rating' || question.questionType == 'sub-questions-likeness-na-rating'"
              class="flex flex-row  w-full gap-1 md:pt-0 pt-2 md:w-5/12">
              <div *ngIf="question.questionType == 'sub-questions-likeness-na-rating'"
                class="flex flex-row items-center justify-center w-1/5 bg-white rounded"
                [ngClass]="subQuestion.answer == 'NA'?'text-exp bg-exp bg-opacity-20':'text-zen bg-zen bg-opacity-5'">
                <i class="fal fa-ban md:text-2xl text-4xl btn md:leading-12 leading-18 rounded w-full text-center"></i>
              </div>
              <div class="flex flex-row items-center justify-center bg-white rounded"
                [ngClass]="{'w-1/5':question.questionType == 'sub-questions-likeness-na-rating', 'w-1/4':question.questionType != 'sub-questions-likeness-na-rating', 'text-exp bg-exp bg-opacity-20':subQuestion.answer == 'P', 'text-zen bg-zen bg-opacity-5':subQuestion.answer != 'P'}">
                <i
                  class="fal fa-face-disappointed md:text-2xl text-4xl btn md:leading-12 leading-18 rounded w-full text-center"></i>
              </div>
              <div class="flex flex-row items-center justify-center bg-white rounded"
                [ngClass]="{'w-1/5':question.questionType == 'sub-questions-likeness-na-rating', 'w-1/4':question.questionType != 'sub-questions-likeness-na-rating', 'text-exp bg-exp bg-opacity-20':subQuestion.answer == 'F', 'text-zen bg-zen bg-opacity-5':subQuestion.answer != 'F'}">
                <i
                  class="fal fa-face-frown-slight md:text-2xl text-4xl btn md:leading-12 leading-18 rounded w-full text-center"></i>
              </div>
              <div class="flex flex-row items-center justify-center bg-white rounded"
                [ngClass]="{'w-1/5':question.questionType == 'sub-questions-likeness-na-rating', 'w-1/4':question.questionType != 'sub-questions-likeness-na-rating', 'text-exp bg-exp bg-opacity-20':subQuestion.answer == 'G', 'text-zen bg-zen bg-opacity-5':subQuestion.answer != 'G'}">
                <i
                  class="fal fa-smile md:text-2xl text-4xl btn md:leading-12 leading-18 rounded w-full text-center"></i>
              </div>
              <div class="flex flex-row items-center justify-center bg-white rounded"
                [ngClass]="{'w-1/5':question.questionType == 'sub-questions-likeness-na-rating', 'w-1/4':question.questionType != 'sub-questions-likeness-na-rating', 'text-exp bg-exp bg-opacity-20':subQuestion.answer == 'EX', 'text-zen bg-zen bg-opacity-5':subQuestion.answer != 'EX'}">
                <i
                  class="fal fa-smile-beam md:text-2xl text-4xl btn md:leading-12 leading-18 rounded w-full text-center"></i>
              </div>



            </div>
          </div>
          <div class="flex flex-row flex-wrap md:flex-nowrap gap-2 mt-2 w-full"
            *ngIf="subQuestion.otherAnswerChecked && subQuestion.otherAnswer">
            <div class="flex flex-col md:w-1/2 w-full">
              <div class="bg-zen-green bg-opacity-5 rounded-md px-5 pt-1 overflow-hidden ">
                <mat-form-field class="w-full -mt-3" appearance="none">
                  <textarea disabled rows="3" maxlength="300"
                    [placeholder]="'txtMostLiked'| translate:language:ModuleNames.Exit"
                    [ngModel]="question.otherAnswer1" matInput></textarea>
                </mat-form-field>
              </div>

            </div>
            <div class="flex flex-col md:w-1/2 w-full">
              <div class="bg-zen-red bg-opacity-5 rounded-md px-5 pt-1 overflow-hidden ">
                <mat-form-field class="w-full -mt-3" appearance="none">
                  <textarea disabled rows="3" maxlength="300"
                    [placeholder]="'txtLeastLiked'| translate:language:ModuleNames.Exit"
                    [ngModel]="question.otherAnswer2" matInput></textarea>
                </mat-form-field>

              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>

<z-modal *ngIf="addNote" [open]="addNote" [autoWidth]="true">
  <ng-container header>
    <h2 class="text-zen text-2xl font-semibold">{{"New Note"| translate:language:ModuleNames.Exit}}</h2>
    <i (click)="addNote = false"
      class="fa-regular fa-times leading-12 btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col w-screen max-w-lg gap-7">
      <z-input [label]="'Name'| translate:language:ModuleNames.Exit" [ngModel]="newNote.userName"
        [disabled]="true"></z-input>
      <z-textarea [maxlength]="lgCount" [label]="'Note'| translate:language:ModuleNames.Exit"
        [placeholder]="'Leave a note here.'| translate:language:ModuleNames.Exit" [(ngModel)]="newNote.note">
      </z-textarea>
    </div>

  </ng-container>

  <ng-container footer class="flex w-full flex-row justify-between">
    <a (click)="addNote = false; newNote = null"
      class="btn leading-10 rounded-sm cancel px-5 text-sm font-medium">{{'btn_cancel'|
      translate:language:ModuleNames.Exit}}</a>
    <a (click)="createUpdateNote(newNote)" class="btn leading-10 rounded-sm exp px-5 text-sm font-medium"> {{"Add Note"|
      translate:language:ModuleNames.Exit}} </a>
  </ng-container>
</z-modal>
