import { ChecklistGroup } from "./checklist-group";
import { EngagementData } from "./engagement-data";

export class Checklist {
  key: string;
  name: any[] = [];
  checklistGroups: ChecklistGroup[] = [];
  isChecked: boolean;
  expanded: boolean = true;
}
