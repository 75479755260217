import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExitBaseComponent } from '../exit-base/exit-base.component';
import { QuestionDto, SectionDto, SurveyTemplateDto } from '@ex/exit/survey-templates';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { QuestionTypes } from 'src/app/shared/questionnaire/questions/question-types';
import { SurveyEditorOptions } from '@ex/theme-shared';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Entity } from 'src/app/core/data-models/Entity';
import { SeperationTypes, SeperationTypesService } from '../../data-access/services/seperation-types.service';

@Component({
  selector: 'exit-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent extends ExitBaseComponent implements OnInit {
  constructor(private db: AngularFireDatabase, private responseDialogService: ResponseDialogService, private seperationTypesService: SeperationTypesService) {
    super()
    this.mdCount = this.GlobalService.mdCount;
  }
  mdCount;
  currentSelectedSection: SectionDto = {} as SectionDto;
  sampleQuestion: boolean = false;
  surveySections;
  options: SurveyEditorOptions = {
    maxFactors: 8,
    minFactors: 4,
    minQuestions: 1,
    maxQuestions: 4
  } as SurveyEditorOptions

  seperationTypes = SeperationTypes
  seperationLegend;
  questionTypes = QuestionTypes
  template: SurveyTemplateDto;
  @Output() templateChange = new EventEmitter();
  @Input() selectedEntity: Entity
  @Input() language;
  @Input('template') set stemplate(template) {
    this.template = template;
    if (this.template.sections?.length)
      this.sectionDistribution(this.surveySections);

  };
  @Input() tourKey;
  ngOnInit(): void {
    this.seperationLegend = [
      { key: 'all', name: 'txtAll', bgColor: 'bg-zen', color: "text-zen" },
      { key: this.seperationTypes.Termination, name: this.returnSeperationType(this.seperationTypes.Termination, this.language), bgColor: 'bg-zen-red', color: "text-zen-red" },
      { key: this.seperationTypes.Retirement, name: this.returnSeperationType(this.seperationTypes.Retirement, this.language), bgColor: 'bg-zen-blue', color: "text-zen-blue" },
      { key: this.seperationTypes.Resignation, name: this.returnSeperationType(this.seperationTypes.Resignation, this.language), bgColor: 'bg-zen-orange', color: "text-zen-orange" },
    ]
  }
  sectionsSetup: {
    key?: string,
    name: string,
    sections: any[],
    isSelectable: boolean,
    isCustomFactors?: boolean,
    description?: string,
    tourOrder?: number
  }[] = []

  sectionDistribution(tSections: SectionDto[]) {
    this.sectionsSetup = [];
    // first load populate
    this.sectionsSetup.push({
      name: 'txtGeneralQuestions',
      sections: [this.template.sections[0]],
      isSelectable: false,
      description: this.template.isPredefined || this.template.isLocked ? 'txt_Clickhere_232' : 'txt_Inthegeneral_394',
      tourOrder: this.template.isPredefined || this.template.isLocked ? 3 : 1
    });

    // Add the predefined factors
    this.sectionsSetup.push({
      name: 'txtFactors',
      sections: this.template.sections.filter(x => x.isPredefined && x.isFactor),
      isSelectable: this.template.isPredefined || this.template.isLocked ? false : true,
      description: this.template.isPredefined || this.template.isLocked ? 'txt_Clickhere_871' : 'txt_Maximizey_717',
      tourOrder: this.template.isPredefined || this.template.isLocked ? 6 : 4
    });

    if (!this.template.isPredefined)
      this.sectionsSetup.push({
        key: "extraFactors",
        name: 'txtCustomFactors',
        sections: this.template.sections.filter(x => !x.isPredefined && x.isFactor),
        isSelectable: this.template.isLocked ? false : true,
        isCustomFactors: true,
        description: this.template.isPredefined || this.template.isLocked ? undefined : 'txt_Ifyouwant_739',
        tourOrder: this.template.isPredefined || this.template.isLocked ? undefined : 5,
      });

    this.sectionsSetup.push({
      name: 'txtGeneralRecommendations',
      sections: [this.template.sections[this.template.sections.length - 1]],
      isSelectable: false,
      description: this.template.isPredefined || this.template.isLocked ? 'txt_Clickhere_627' : 'txt_Similarto_253',
      tourOrder: this.template.isPredefined || this.template.isLocked ? 7 : 6
    });
    this.currentSelectedSection = this.sectionsSetup[0].sections[0];
  }
  sectionClicked(section: SectionDto, e?) {
    e.stopImmediatePropagation();
    this.currentSelectedSection = section;
  }

  addNewFactor() {
    if (this.sectionsSetup.find(s => s.isCustomFactors).sections.length == 5)
      return;
    let newFactor = {} as any;
    newFactor.key = this.db.createPushId();
    newFactor.name = [{ key: this.language, name: this.localize('txtDriverName', this.language, this.ModuleNames.Exit) }];
    newFactor.isPredefined = false;
    newFactor.isSelected = false;
    newFactor.isFactor = true;
    newFactor.questions = [];

    const customFactors = this.sectionsSetup.find(x => x.isCustomFactors);
    customFactors.sections.push(newFactor);

    this.currentSelectedSection = newFactor;
    // let indexOfCustom = this.sectionsSetup.indexOf(this.sectionsSetup.find(s => s.isCustomFactors));
    // this.sectionsSetup[indexOfCustom].sections.push(newFactor);
    // this.currentSelectedSection = newFactor;

    this.updateSource();
  }
  addNewQuestion(name?: any, isDemo?: any) {

    if (this.currentSelectedSection.questions.length > 2 && !this.currentSelectedSection.isAllowNewQuestions)
      return;
    if (isDemo) {
      let index = this.sectionsSetup.find(x => x.sections.find(m => m.key == this.currentSelectedSection.key))
      let currentIndex = this.sectionsSetup.indexOf(index);
      if ((!currentIndex? this.currentSelectedSection.questions.length > 5 : this.currentSelectedSection.questions.length > 1) && this.currentSelectedSection.isAllowNewQuestions)
        return;

    }

    let newQuestion: QuestionDto = {} as any;
    newQuestion.key = this.db.createPushId();
    newQuestion.skippable = false;
    newQuestion.sectionKey = this.currentSelectedSection.key;
    if (this.currentSelectedSection.isFactor && !this.currentSelectedSection.isAllowNewQuestions)
      newQuestion.questionType = QuestionTypes.SingleAnswerConfidenceWithNA;
    else newQuestion.questionType = QuestionTypes.MultiOptionMultiAnswer;

    newQuestion.name = name ? name : [{ key: this.language, name: this.localize((this.currentSelectedSection.isFactor ? "txt_editor_newstatement" : 'txt_editor_NewQuestion'), this.language, this.ModuleNames.Exit) + '#' + (this.currentSelectedSection.questions.length + 1) }];
    this.template.sections[this.template.sections.indexOf(this.currentSelectedSection)].questions.push(newQuestion);
    this.updateSource();
  }

  extraDriverValidation: boolean = false;
  extraDriverErrorCode: string;


  selectionClick(section: SectionDto) {
    if (section.isSelected) {
      if (this.sectionsSetup.map(x => x.sections.filter(n => n.isSelected && n.isPredefined && n.isFactor)).flat().length <= 5 && section.isPredefined)
        return this.SnackbarService.error(this.localize('txt_C-Factors_788', this.language, this.ModuleNames.Exit));
      section.isSelected = false;
    }
    else {
      if (this.sectionsSetup.map(x => x.sections.filter(n => n.isSelected && n.isFactor)).flat().length > 7)
        return this.SnackbarService.error(this.localize('txt_Allfactor_914', this.language, this.ModuleNames.Exit));
      if (!this.isValidSection(section))
        return this.SnackbarService.error(this.localize('txt_Factornee_401', this.language, this.ModuleNames.Exit));
      section.isSelected = true;
    }

    this.updateSource();
  }

  async removeSection(factor) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_delete_item_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Shared,
        this.language
      );
      if (confirmed) {
        let indexOfCustom = this.sectionsSetup.indexOf(this.sectionsSetup.find(s => s.key == 'extraFactors'));
        this.sectionsSetup[indexOfCustom].sections.splice(this.sectionsSetup[indexOfCustom].sections.indexOf(factor), 1);
        this.currentSelectedSection = this.sectionsSetup[0].sections[0];
        this.updateSource();
      }
    } catch {

    }
  }
  async removeQuestion(question, section: SectionDto) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_delete_item_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Shared,
        this.language
      );
      if (confirmed) {
        section.questions.splice(section.questions.indexOf(question), 1);
        this.updateSource();
        if (!section.questions.length)
          this.selectionClick(section);
      }
    } catch {

    }
  }

  updateQuestion(question: QuestionDto) {

    let tSectionIndex = this.template.sections.indexOf(this.template.sections.find(section => section.questions.find(q => q.key == question.key)));
    let tQuestionIndex = this.template.sections[tSectionIndex].questions.indexOf(this.template.sections[tSectionIndex].questions.find(q => q.key == question.key));
    this.template.sections[tSectionIndex].questions[tQuestionIndex] = question;
    this.updateForm();
  }
  updateSource() {
    this.template.sections = this.sectionsSetup.map(x => x.sections).flat();
    this.updateForm();
  }

  extractNameLanguage(eG: any) {
    return this.LanguageService
      .extractNameLanguage(eG, this.language)
      .replaceAll('[CompanyName]', this.ExtractPipe.transform(this.selectedEntity.name, this.language))
  }

  getConfidence(subquestionKey: string, key: string) {
    return this.GeneralService.getQuestionOptions(subquestionKey, key, this.language);
  }

  updateCurrentSectionName(event, section) {
    // let sectionIndex = this.sectionsSetup[2].sections.indexOf(this.sectionsSetup[2].sections.find(x => x.key == section.key));
    // this.sectionsSetup[2].sections[sectionIndex] = JSON.parse(JSON.stringify(section));
    // this.currentSelectedSection = section;
  }
  ngOnDestroy(): void {
    this.updateSource();
  }

  returnSelectedFactors(sections) {
    return sections.filter((s: SectionDto) => s.isSelected)?.length;
  }


  isValidSection(factor: SectionDto) {
    return this.isValidName(factor.name) && factor.questions.length && factor.questions.every(question => this.isValidQuestion(question, factor));
  }
  isValidQuestion(question: QuestionDto, factor: SectionDto) {
    if (!factor?.isAllowNewQuestions || (factor?.isAllowNewQuestions && question.isPredefined))
      return this.isValidName(question.name);
    if (question.questionType == QuestionTypes.OpenText)
      return true;
    let isValid = true;
    if (!question.questionOptions?.length || question.questionOptions?.some(option => !this.isValidName(option.name)))
      isValid = false;
    else if (((question.max && question.max > question.questionOptions.length) || (question.min && (question.min < 1 || question.min > question.max))))
      isValid = false;

    return isValid
  }

  isValidName(name) {
    return name?.find(s => s.name.length >= 2) ? true : false;
  }

  updateForm() {
    this.templateChange.emit(this.template);
  }

  returnSeperationType(key, language) {
    let type: any = this.seperationTypesService.getByKey(key);
    return type.name.find(x => x.key == language).name;
  }
  returnTypeIcon(questionType: string) {
    switch (questionType) {
      case QuestionTypes.OpenText:
        return 'fa-message-lines'
      case QuestionTypes.MultiOptionMultiAnswer:
        return 'fa-list'
      case QuestionTypes.MultiOptionSingleAnswer:
        return 'fa-list'
      case QuestionTypes.SingleAnswerConfidenceWithNA || QuestionTypes.SingleAnswerConfidence:
        return 'fa-chart-simple'
      case QuestionTypes.SingleAnswerOutOf10:
        return 'fa-slider'

    }
  }
  isQuestionEditable(currentSelectedSection, factor, question) {
    return (!currentSelectedSection?.isPredefined || (factor?.isAllowNewQuestions && !question.isPredefined)) && !this.template.isLocked;
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

    showDemoSection(selectedSection) {
    if (!this.sectionsSetup)
      return;
    let indexParent = this.sectionsSetup?.indexOf(this.sectionsSetup.find(x => x.sections.find(m => m == selectedSection)));
    let index = this.sectionsSetup[indexParent]?.sections.indexOf(selectedSection);
    if (index > 1)
      return "Section";

    return "Default";
  }

}
