<z-modal [open]="true" autoWidth="true" (click)="logData(exportableCharts)">
  <ng-container body>
    <div class="flex flex-row gap-5 -mb-10 -mt-10 max-h-screen-90 h-screen overflow-hidden max-w-7xl">
      <div class="flex flex-col items-stretch  w-4/5">
        <div class="max-h-screen-90 block  innerListScroll  portrait" id="pdf">
          <!-- front page -->
          <div class="fixedHeight relative w-screen">
            <div class="absolute bottom-0 top-0 left-0 z-50  flex flex-col w-full items-stretch justify-between bg-white p-10">
              <div class="flex flex-col items-stretch justify-between">
                <div class="flex flex-row justify-between gap-10">
                  <h6 class="text-zen text-sm uppercase font-semibold text-opacity-50">
                    {{"txtConfidential" | translate:language:ModuleNames.Exit}}
                  </h6>
                  <img class="h-28 max-w-sm" [src]="entity?.logoURL? entity?.logoURL: 'assets/img/no-logo.png'" alt="">
                </div>
                <div class="flex flex-col gap-20">
                  <div class="mt-12 flex flex-col gap-2 uppercase">
                    <h1 class="text-zen text-5xl font-medium">{{"txtEmployeeExitAnalysis" |
                      translate:language:ModuleNames.Exit}}</h1>
                    <h5 class="text-zen text-5xl">{{"txt_report" |
                      translate:language:ModuleNames.Exit}} </h5>
                  </div>
                  <div class="flex flex-col gap-2">
                    <h6 class="text-zen-lite text-xl">{{"txtfor" |
                      translate:language:ModuleNames.Exit}}</h6>
                    <h1 class="text-zen text-xl font-semibold">{{entity?.name | extract: language}}
                    </h1>
                    <h5 class="text-zen-lite text-xl">{{date | date: 'dd/MM/yyyy'}}</h5>
                  </div>
                  <div class="flex flex-col gap-2" *ngIf="dashboardStartDate && dashboardEndDate">
                    <h6 class="text-zen-lite text-xl">{{"txt_timeframe" |
                      translate:language:ModuleNames.Exit}}:</h6>
                    <h6 class="text-zen-lite text-xl">{{"txtfrom" |
                      translate:language:ModuleNames.Exit}} <span class="text-exp font-medium">{{dashboardStartDate |
                        date:'dd/MM/yyyy'}}</span> {{"txtTo" |
                      translate:language:ModuleNames.Exit}}
                      <span class="text-exp font-medium">{{dashboardEndDate |
                        date:'dd/MM/yyyy'}}</span>
                    </h6>
                  </div>

                  <div class="flex flex-col gap-2" *ngIf="selectedSeperationType?.length">
                    <h6 class="text-zen-lite text-xl">{{"txt_leaver_seperation_types" |
                      translate:language:ModuleNames.Exit}}:</h6>
                    <h6 class="text-zen-lite text-xl" *ngFor="let item of selectedSeperationType">
                      <span class="text-exp font-medium">{{item |
                        translate:language:ModuleNames.Exit}}</span>
                    </h6>
                  </div>

                </div>

              </div>

              <div class="flex flex-col gap-1">
                <p class="text-xs text-zen text-opacity-50">{{"txtPoweredby" |
                  translate:language:ModuleNames.Exit}}</p>
                <img class="h-10 w-36 object-contain" src="assets/img/logo.png" alt="">
              </div>
            </div>
          </div>
          <!-- 3 -->
          <!-- how to use this report -->
          <ng-container *ngIf="exportReport">
            <!-- table of content -->
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-5 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl">{{"txttableofcontents" |
                  translate:language:ModuleNames.Exit}}
                </h1>
                <!-- content -->
                <div class="flex flex-col items-stretch gap-1" *ngIf="filteredTableOfContent?.length">
                  <ng-container *ngFor="let content of filteredTableOfContent; let index = index">

                    <div class="flex flex-col items-stretch rounded bg-zen bg-opacity-5" *ngIf="content.dispaly">
                      <div
                        class="flex flex-row items-center justify-between px-5 font-medium text-sm text-zen leading-10">
                        <div class="flex flex-row items-center gap-3">
                          <span class="text-exp">{{index + 1}}. </span>
                          <span>{{content.title | translate:language:ModuleNames.Exit}}</span>
                        </div>
                        <span>{{content.content > 1?content.page - content.content + 1:
                          content.page}}</span>
                      </div>
                      <div class="flex flex-col pb-2" *ngIf="content?.children?.length">
                        <ng-container *ngFor="let childContent of content.children; let childIndex = index">
                          <div [hidden]="childContent.hideContent"
                            class="flex flex-row items-center justify-between px-5 font-medium text-xs text-zen-lite leading-9 ">
                            <div class="flex flex-row items-center gap-3 px-6">
                              <span>{{index + 1}}.{{childIndex + 1}} </span>
                              <span>{{childContent.title |
                                translate:language:ModuleNames.Exit}}</span>
                            </div>
                            <span class="text-sm text-zen">{{childContent.content >
                              1?childContent.page - childContent.content + 1:
                              childContent.page}}</span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>


                </div>
              </div>
            </div>
            <!-- introduction -->
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-10 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">1.
                  </span>{{"txtIntroduction" | translate:language:ModuleNames.Exit}}
                </h1>
                <!-- content -->
                <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-col gap-2">
                    <div class="text-zen text-sm leading-5"
                      [innerHtml]="'txtEmployeeExitIntroductionpara' | translate:language:ModuleNames.Exit">
                    </div>

                    <div class="text-zen text-sm leading-5"
                      [innerHtml]="'txtExitObjectives' | translate:language:ModuleNames.Exit">
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-10 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">{{sectionIndex('reportReading')}}.
                  </span>{{'txtHowtousethisreport' | translate:language:ModuleNames.Exit}}</h1>
                <!-- content -->
                <div class="text-sm text-zen-lite"
                  [innerHtml]="'txtExitHowtousethisreportpara' | translate:language:ModuleNames.Exit">
                </div>
                <div class="flex flex-col gap-6">
                  <h5 class="text-lg text-zen">{{'txtResultsRating' |
                    translate:language:ModuleNames.Exit}}
                  </h5>
                  <div class="flex flex-col gap-2 text-zen">
                    <div class="flex flex-row items-center gap-2">
                      <span class="h-3 w-4 rounded-sm bg-zen-red"></span>
                      <h4 class="text-sm" [innerHtml]='"VERYPOOREXP" | translate:language:ModuleNames.Exit'>
                      </h4>
                    </div>
                    <div class="flex flex-row items-center gap-2">
                      <span class="h-3 w-4 rounded-sm bg-orange"></span>
                      <h4 class="text-sm" [innerHtml]='"POOREXP" | translate:language:ModuleNames.Exit'>
                      </h4>
                    </div>
                    <div class="flex flex-row items-center gap-2">
                      <span class="h-3 w-4 rounded-sm bg-yellow-300"></span>
                      <h4 class="text-sm" [innerHtml]='"FAIREXP" | translate:language:ModuleNames.Exit'>
                      </h4>
                    </div>
                    <div class="flex flex-row items-center gap-2">
                      <span class="h-3 w-4 rounded-sm bg-zen-lightgreen"></span>
                      <h4 class="text-sm" [innerHtml]='"GOODEXP" | translate:language:ModuleNames.Exit'>
                      </h4>
                    </div>
                    <div class="flex flex-row items-center gap-2">
                      <span class="h-3 w-4 rounded-sm bg-zen-green"></span>
                      <h4 class="text-sm" [innerHtml]='"VERYGOODEXP" | translate:language:ModuleNames.Exit'>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col gap-6">
                  <h5 class="text-lg text-zen">{{'txtCalculationProcess' |
                    translate:language:ModuleNames.Exit}}</h5>
                  <div class="block  text-zen">
                    <div class="flex flex-row items-center gap-3">
                      <span
                        class="text-white bg-exp leading-5 text-center rounded-full w-5 h-5 font-semibold text-xs">1</span>
                      <p>{{'txtCalculationProcess1' | translate:language:ModuleNames.Exit}}
                      </p>
                    </div>
                    <div class="flex flex-row items-center gap-3 pt-2">
                      <span
                        class="text-white bg-exp leading-5 text-center rounded-full w-5 h-5 font-semibold text-xs">2</span>
                      <p>{{'txtHappinessCalculationProcess2' |
                        translate:language:ModuleNames.Exit}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ng-container>
          <ng-container *ngIf="exportOverview">
            <div class="innerPage break-before gap-10 bg-white">
              <div class="exportContainer pt-20 px-10 gap-10 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">{{sectionIndex('overview')}}.
                  </span>{{'txtEmployeeExitGeneralAnalysis' |
                  translate:language:ModuleNames.Exit}}
                </h1>
                <p class="text-sm text-zen-lite"
                  [innerHtml]="'txtEmployeeExitMeasures' | translate:language:ModuleNames.Exit">
                </p>

                <div class="flex flex-col gap-10 pb-5">
                  <h1 class="text-zen font-semibold text-lg"><span class="text-exp">{{sectionIndex('overview')}}.1.
                    </span>
                    {{"Leavers' satisfaction"| translate:language:ModuleNames.Exit}}
                  </h1>
                  <div class="flex flex-row gap-14 items-center">
                    <kendo-arcgauge [color]="resolveChartColor(leaverExperienceScore)" [value]="leaverExperienceScore"
                      style="display: block; width: 180px; height:180px" renderAs="svg">
                      <kendo-arcgauge-scale [rangePlaceholderColor]="resolveChartColor( leaverExperienceScore) +'19'"
                        [startAngle]="90" [endAngle]="450" [rangeSize]="23" [rangeLineCap]="'butt'">
                        <ng-template kendoArcGaugeCenterTemplate let-value="value">
                          <span class="text-2xl font-medium text-zen">
                            {{ toFixed(leaverExperienceScore,1) +
                            "%"}}</span>
                        </ng-template>
                      </kendo-arcgauge-scale>
                    </kendo-arcgauge>
                    <div class="flex flex-col justify-center gap-2">
                      <div class="flex flex-col gap-2 text-zen">
                        <div class="flex flex-row items-center gap-2">
                          <span class="h-3 w-4 rounded-sm bg-zen-red"></span>
                          <h4 class="text-sm" [innerHtml]='"VERYPOOREXP" | translate:language:ModuleNames.Exit'>
                          </h4>
                        </div>
                        <div class="flex flex-row items-center gap-2">
                          <span class="h-3 w-4 rounded-sm bg-orange"></span>
                          <h4 class="text-sm" [innerHtml]='"POOREXP" | translate:language:ModuleNames.Exit'>
                          </h4>
                        </div>
                        <div class="flex flex-row items-center gap-2">
                          <span class="h-3 w-4 rounded-sm bg-yellow-300"></span>
                          <h4 class="text-sm" [innerHtml]='"FAIREXP" | translate:language:ModuleNames.Exit'>
                          </h4>
                        </div>
                        <div class="flex flex-row items-center gap-2">
                          <span class="h-3 w-4 rounded-sm bg-zen-lightgreen"></span>
                          <h4 class="text-sm" [innerHtml]='"GOODEXP" | translate:language:ModuleNames.Exit'>
                          </h4>
                        </div>
                        <div class="flex flex-row items-center gap-2">
                          <span class="h-3 w-4 rounded-sm bg-zen-green"></span>
                          <h4 class="text-sm" [innerHtml]='"VERYGOODEXP" | translate:language:ModuleNames.Exit'>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col gap-10">
                  <h1 class="text-zen font-semibold text-lg"><span class="text-exp">{{sectionIndex('overview')}}.2.
                    </span>
                    {{
                    'txtENPS'
                    | translate:language:ModuleNames.Exit }}
                  </h1>
                  <div class="flex flex-row gap-14 items-center">
                    <kendo-arcgauge color="#0082C6" [value]="netEnpsScore" style="display: block; min-width: 180px; height:180px" renderAs="svg">
                      <kendo-arcgauge-scale [rangePlaceholderColor]="'#0082C6' +'19'" [startAngle]="90" [endAngle]="450" [rangeSize]="23" [rangeLineCap]="'butt'">
                        <ng-template kendoArcGaugeCenterTemplate let-value="value">
                          <span class="text-2xl font-medium text-zen">
                            {{ toFixed(netEnpsScore,1) + "%"}}</span>
                        </ng-template>
                      </kendo-arcgauge-scale>
                    </kendo-arcgauge>
                    <div class="flex flex-col justify-center items-stretch gap-2 w-full">
                      <div class="flex flex-col items-stretch gap-1.5" *ngFor="let enpsScore of enpsScores">
                        <div class="flex flex-row justify-between items-center">
                          <h5 class="text-zen text-sm">{{ 'txt' + capitalizeFirstLetter(enpsScore.category) |
                            translate:language:ModuleNames.Exit }} <span class="font-normal">[30% -
                              100%]</span> </h5>
                          <h5 class="text-sm  text-zen-lite"> {{ enpsScore.count }}({{ enpsScore.percentage |
                            number:'1.0-0'
                            }}%) </h5>
                        </div>
                        <div
                          class="flex flex-row justify-start h-5 rounded-md bg-zen-green bg-opacity-10 items-stretch overflow-hidden">
                          <div
                            [ngClass]="{'bg-zen-green': enpsScore.category == EnpsTypes.Promoters,'bg-yellow-500': enpsScore.category == EnpsTypes.Passives,'bg-zen-red': enpsScore.category == EnpsTypes.Detractors }"
                            class="bg-zen-green" [style.width.%]="enpsScore.percentage | number:'1.0-0'">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col gap-2">
                  <h4 class="text-exp">{{"txtDefinitions"| translate:language:ModuleNames.Exit}}:</h4>
                  <div class="flex flex-row gap-1.5">
                    <div [ngClass]="(language | language)? 'fixedw20AR':'fixedw20EN'"
                      class="flex flex-col items-end w-20 fixedw20">
                      <h5 class="text-zen text-xs font-medium">{{ 'txtExitCalculationProcess3'
                        |
                        translate:language:ModuleNames.Exit }}</h5>
                    </div>
                    <div class="flex flex-col items-start">
                      <h5 class="text-zen text-xs">{{ 'txtENPSDesc' |
                        translate:language:ModuleNames.Exit
                        }}</h5>
                    </div>
                  </div>
                  <div class="flex flex-row gap-1.5">
                    <div [ngClass]="(language | language)? 'fixedw20AR':'fixedw20EN'"
                      class="flex flex-col items-end w-20 fixedw20">
                      <h5 class="text-zen text-xs font-medium">{{'txtPromoters' |
                        translate:language:ModuleNames.Exit }}</h5>
                    </div>
                    <div class="flex flex-col items-start">
                      <h5 class="text-zen text-xs">{{ 'txtPromotersDef' |
                        translate:language:ModuleNames.Exit }}</h5>
                    </div>
                  </div>
                  <div class="flex flex-row gap-1.5">
                    <div [ngClass]="(language | language)? 'fixedw20AR':'fixedw20EN'"
                      class="flex flex-col items-end w-20 fixedw20">
                      <h5 class="text-zen text-xs font-medium">{{ 'txtPassives' |
                        translate:language:ModuleNames.Exit }}</h5>
                    </div>
                    <div class="flex flex-col items-start">
                      <h5 class="text-zen text-xs">{{ 'txtPassivesDef' |
                        translate:language:ModuleNames.Exit }}</h5>
                    </div>
                  </div>
                  <div class="flex flex-row gap-1.5">
                    <div [ngClass]="(language | language)? 'fixedw20AR':'fixedw20EN'"
                      class="flex flex-col items-end w-20 fixedw20">
                      <h5 class="text-zen text-xs font-medium">{{ 'txtDetractors' |
                        translate:language:ModuleNames.Exit }}</h5>
                    </div>
                    <div class="flex flex-col items-start">
                      <h5 class="text-zen text-xs">{{ 'txtDetractorsDef' |
                        translate:language:ModuleNames.Exit }}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <!--  Reasons Behind Why Employees are Leaving the Workplace -->
              <div class="innerPage break-before bg-white">
                <div class="exportContainer pt-20 px-10 gap-10 flex flex-col">
                  <h1 class="text-zen text-lg font-semibold"><span class="text-exp">{{sectionIndex('overview')}}.3.
                    </span>{{'txtFactorsInfluenced' | translate:language:ModuleNames.Exit}}</h1>
                  <!-- content -->
                  <div class="text-sm text-zen-lite"
                    [innerHtml]="('txtFactorsInfluencedDesc' | translate:language:ModuleNames.Exit).replaceAll('[Company Name]', entity?.name | extract: language)">
                  </div>
                  <ng-container *ngIf="questionAllAnalysis[0] as question">
                    <div class="flex flex-col items-stretch gap-6">
                      <div class="flex flex-row gap-4 text-sm"
                        *ngFor="let category of questionAllAnalysis[0].data.categories; let c = index ">
                        <div class="flex flex-col w-4/12">
                          <h5 class="text-zen-lite">{{category}}</h5>
                        </div>
                        <div class="flex flex-row items-center w-8/12 gap-4">
                          <div
                            class="flex flex-row justify-start h-5 w-full rounded bg-exp bg-opacity-10 items-stretch overflow-hidden">
                            <div class="bg-exp" [style.width.%]="questionAllAnalysis[0].data.bar.series[0].data[c]">
                            </div>
                          </div>
                          <div class="flex flex-row justify-end w-11">
                            <h5 class="text-zen font-medium">
                              {{questionAllAnalysis[0].data.bar.series[0].data[c] | number:'1.1-1'}}%
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <!--  What industry are you leaving for -->
              <div class="innerPage break-before bg-white" *ngIf="questionAllAnalysis[1]?.data.categories?.length">
                <div class="exportContainer pt-20 px-10">
                  <h1 class="text-zen text-lg font-semibold"><span class="text-exp">{{sectionIndex('overview')}}.4.
                    </span>{{'txtIndustriesLeftFor' | translate:language:ModuleNames.Exit}}</h1>
                  <!-- content -->
                  <div class="text-sm text-zen-lite pt-12"
                    [innerHtml]="('txtIndustriesLeftForDesc' | translate:language:ModuleNames.Exit)">
                  </div>
                  <ng-container *ngIf="questionAllAnalysis[1]?.data.categories?.length">
                    <div class="flex flex-col items-stretch gap-6 pt-12">
                      <div class="flex flex-row gap-4 text-sm"
                        *ngFor="let category of questionAllAnalysis[1]?.data.categories; let c = index ">
                        <div class="flex flex-col w-4/12">
                          <h5 class="text-zen-lite">{{category}}</h5>
                        </div>
                        <div class="flex flex-row items-center w-8/12 gap-4">
                          <div [ngClass]="{'bg-exp':c % 3 == 0, 'bg-int':c % 3 == 1, 'bg-zen-grey':c % 3 == 2 }"
                            class="flex flex-row justify-start h-5 w-full rounded bg-opacity-10 items-stretch overflow-hidden">
                            <div [ngClass]="{'bg-exp':c % 3 == 0, 'bg-int':c % 3 == 1, 'bg-zen-grey':c % 3 == 2 }"
                              [style.width.%]="questionAllAnalysis[1].data.bar.series[0].data[c]">
                            </div>
                          </div>
                          <div class="flex flex-row justify-end w-11">
                            <h5 class="text-zen font-medium">
                              {{questionAllAnalysis[1].data.bar.series[0].data[c]| number:'1.1-1'}}%
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="questionAllAnalysis[2]?.data.categories?.length as data">
                    <h5 class="text-sm text-int font-medium pt-12 pb-8">{{"Selected Different Industries"|
                      translate:language:ModuleNames.Exit}}</h5>
                    <div class="block" *ngFor="let category of questionAllAnalysis[2]?.data.categories ; let c = index "
                      [ngClass]="industriesSectionBreak(c)">
                      <div class="flex flex-row gap-4 text-sm pb-6">
                        <div class="flex flex-col w-4/12 truncate">
                          <h5 class="text-zen-lite">{{category}}</h5>
                        </div>
                        <div class="flex flex-row items-center w-8/12 gap-4">
                          <div [ngClass]="{'bg-exp':c % 3 == 0, 'bg-int':c % 3 == 1, 'bg-zen-grey':c % 3 == 2  }"
                            class="flex flex-row justify-start h-5 w-full rounded bg-opacity-10 items-stretch overflow-hidden">
                            <div [ngClass]="{'bg-exp':c % 3 == 0, 'bg-int':c % 3 == 1, 'bg-zen-grey':c % 3 == 2 }"
                              [style.width.%]="questionAllAnalysis[2].data.bar.series[0].data[c]">
                            </div>
                          </div>
                          <div class="flex flex-row justify-end w-11">
                            <h5 class="text-zen font-medium">
                              {{questionAllAnalysis[2].data.bar.series[0].data[c]| number:'1.1-1'}}%
                            </h5>
                          </div>
                        </div>
                      </div>


                    </div>
                  </ng-container>

                </div>
              </div>


              <!-- 5 -->

            </div>
          </ng-container>
          <ng-container *ngIf="exportReport">
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-10 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">{{sectionIndex('factors')}}.
                  </span>{{'txtEmployeeExitFactors' | translate:language:ModuleNames.Exit}}</h1>
                <!-- content -->
                <div class="text-sm text-zen-lite"
                  [innerHtml]="('txtFactorsofWorkplaceExitDesc' | translate:language:ModuleNames.Exit).replaceAll('[Drivers Count]', factors?.length).replaceAll('[Company Name]', entity?.name | extract: language)">
                </div>
                  <div class="block relative -mr-1 -ml-1">
                  <ng-container *ngFor="let factor of factors; let i = index">
                      <div class="inline-block px-1 pt-2 w-1/3">
                      <div
                        class="flex flex-col items-center justify-center border border-zen border-opacity-10 rounded h-44 gap-2">
                        <kendo-arcgauge [value]="factor.score" [color]="resolveChartColor(factor.score)"
                          [transitions]="true" style="height: 85px;" renderAs="svg">
                          <kendo-arcgauge-scale [rangePlaceholderColor]="resolveChartColor( factor.score) +'19'"
                            [startAngle]="0" [endAngle]="180" [rangeSize]="20" [rangeLineCap]="'butt'">
                            <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                              <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                <p class="text-xl text-zen font-medium">{{
                                  toFixed(factor.score, 1) }}%</p>
                              </ng-template>
                            </kendo-arcgauge-scale-labels>
                          </kendo-arcgauge-scale>
                        </kendo-arcgauge>
                        <div class="flex flex-col items-center transform -translate-y-5">
                          <p class="text-center text-zen text-sm font-semibold">{{
                            factor.name
                            | extract:language }}
                          </p>
                          <p class="text-center text-xxs font-semibold"
                            [ngStyle]="{ 'color': resolveChartColor(factor.score) }"
                            [innerHtml]='resolveEngagementDriverScoreCategoryName(toFixed(factor.score, 1)) '>
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <!-- factors -->
            <ng-container *ngFor="let driver of factors; let i = index">
              <div class="innerPage break-before bg-white" *ngIf="driver.driverLabel">
                <div class="exportContainer pt-20 px-10" *DemoAccount="i ? 'Report':'Default'">
                  <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">{{sectionIndex('factors')}}.{{i
                      +
                      1}}.
                    </span>{{driver.name | extract:language }}</h1>
                  <!-- content -->
                    <div class="block -mr-5 -ml-5 my-10">
                  <div class="w-2/3 inline-block px-5">
                      <h5 class="text-zen text-sm font-normal">{{
                        driver.driverLabel.driverDesc |
                        translate:language:ModuleNames.Exit }} </h5>
                    </div>
                    <div class="w-1/3 inline-block px-5 align-top ">
                      <div
                        class="flex flex-col items-center justify-center border border-zen border-opacity-10 rounded h-44 gap-2">
                        <kendo-arcgauge [value]="driver.score" [color]="resolveChartColor(driver.score)"
                          [transitions]="true" style="height: 85px;" renderAs="svg">
                          <kendo-arcgauge-scale [rangePlaceholderColor]="resolveChartColor( driver.score) +'19'"
                            [startAngle]="0" [endAngle]="180" [rangeSize]="20" [rangeLineCap]="'butt'">
                            <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                              <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                <p class="text-xl text-zen font-medium">{{
                                  toFixed(driver.score, 1) }}%</p>
                              </ng-template>
                            </kendo-arcgauge-scale-labels>
                          </kendo-arcgauge-scale>
                        </kendo-arcgauge>
                        <p class="text-center text-zen text-sm font-semibold">{{
                          driver.name
                          |
                          extract:language}}
                        </p>
                        <p class="text-center text-xxs font-semibold"
                          [ngStyle]="{ 'color': resolveChartColor(driver.score) }"
                          [innerHtml]='resolveEngagementDriverScoreCategoryName(toFixed(driver.score, 1)) '>
                        </p>
                      </div>
                    </div>

                  </div>
                  <div class="text-zen ">
                    <h2 class=" text-lg font-medium mb-6">{{
                      driver.driverLabel.driverRecommendationDesc
                      | translate:language:ModuleNames.Exit }}</h2>
                    <div class="flex flex-row items-start gap-3 py-1"
                      *ngFor="let point of driver.driverLabel.driverRecommendations; let i = index">
                      <span
                        class="text-white bg-exp leading-5 text-center rounded-full w-5 h-5 maxMin5 font-semibold text-xs">{{
                        i + 1 }}</span>
                      <p class="text-sm">
                        {{ point.description | translate:language:ModuleNames.Exit }}
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </ng-container>
          </ng-container>
          <!--  5.  Leavers' Analysis-->
          <div *ngIf="exportAnalysis" class="block break-before bg-white pt-20 px-10 innerPage">
            <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">{{sectionIndex('analysis')}}.
              </span>{{"txtLeaversAnalysis" | translate:language:ModuleNames.Exit}}</h1>
            <!-- content -->
            <div class="block relative">
              <div class="block -ml-3 -mr-3 pt-7">
                <div class="inline-block relative w-1/2 px-3 mb-6">
                  <div class="flex flex-col items-center border border-zen border-opacity-10 rounded gap-6 pt-4 pb-6">
                    <h4 class="text-center text-sm font-medium text-zen">
                      {{ 'txtOverallResponseRate' | translate:language:ModuleNames.Shared
                      }}
                    </h4>
                    <kendo-arcgauge [value]="leaverExperienceScore" [color]="'#0082C6'" [transitions]="true" style="height: 137px;" renderAs="svg">
                      <kendo-arcgauge-scale [rangePlaceholderColor]="'#0082C6'+'19'" [startAngle]="90" [endAngle]="450" [rangeSize]="17" [rangeLineCap]="'butt'">

                        <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                          <ng-template kendoArcGaugeCenterTemplate let-value="value">
                            <p class="text-xl text-zen font-medium"> {{
                              toFixed(leaverExperienceScore, 1) }}%</p>
                          </ng-template>
                        </kendo-arcgauge-scale-labels>
                      </kendo-arcgauge-scale>
                    </kendo-arcgauge>




                  </div>
                </div>
                <ng-container *ngFor="let chart of exportableCharts?.charts">
                  <ng-container *ngIf="!chart.notExportable">
                    <span *ngIf="chart.breakBefore" class="block break-before w-full h-20"></span>
                    <div class="relative px-3 mb-6 align-top"
                      [ngClass]="{'w-1/2 inline-block avoidBreak':!chart.fullWidth , 'w-full block':chart.fullWidth}"
                      *ngIf="chart.data as data" (click)="logData(chart)">
                      <export-chart-vertical [language]="language" [rows]="chart.fullWidth?2: 1"
                        [categoryTemplate]="chart.key == 'GENDER'? categoryTemplate:''" [data]="data" [chart]="chart"
                        [rows]="fullWidthChartCheck(chart, data.categories)"
                        [breakIndex]="fullWidthChartCheck(chart, data.categories) == 1? 34: 42">
                        <ng-container header>
                          <div class="flex flex-row items-center gap-2">
                            <ng-container *ngIf="returnIcon(chart)">
                              <i *ngIf="chart?.icon;" [class]="'fa-'+ chart.icon"
                                class="fa-regular text-exp text-xl"></i>
                              <img *ngIf="chart?.image" [src]="chart.image" alt="">
                            </ng-container>
                            <h4 class="text-sm font-normal text-zen">
                              {{chart.Title}}
                            </h4>
                          </div>
                        </ng-container>
                      </export-chart-vertical>
                      <ng-template #categoryTemplate let-category let-value="value">
                        <div class="flex flex-row justify-between items-center pt-1">
                          <h5 class="text-zen text-sm">{{category}}</h5>
                          <h5
                            [ngClass]="getGenderKey(category) == 'male'?'text-int': getGenderKey(category) == 'female'?'text-zen-pink':'text-exp'"
                            class="text-sm font-semibold">
                            {{value | number:'1.1-1'}}%
                          </h5>
                        </div>
                        <div
                          [ngClass]="getGenderKey(category) == 'male'?'bg-int': getGenderKey(category) == 'female'?'bg-zen-pink':'bg-exp'"
                          class="flex flex-row justify-start h-2.5 rounded-sm bg-opacity-10 items-stretch overflow-hidden">
                          <div
                            [ngClass]="getGenderKey(category) == 'male'?'bg-int': getGenderKey(category) == 'female'?'bg-zen-pink':'bg-exp'"
                            [style.width.%]="value">
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>

          </div>

          <!-- 6 questions-->
          <div *ngIf="exportQuestions" class="block break-before bg-white innerPage">
            <div class="exportContainer pt-20 px-10">
              <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">{{sectionIndex('questions')}}.
                </span>{{ "txt_questions" | translate:language:ModuleNames.Exit }}</h1>
              <!-- content -->
              <div class="block relative pt-7">
                <div class="flex flex-row justify-between items-center uppercase pb-7">
                  <h5 class="text-xxs text-zen-lite font-medium">{{ "Driver / Question" |
                    translate:language:ModuleNames.Exit }}</h5>
                  <h5 class="text-xxs text-zen-lite font-medium">{{ "txtMean" |
                    translate:language:ModuleNames.Exit }}</h5>
                </div>
                <div class="block pb-7 avoidBreak"
                  [ngClass]="{'pt-10': i != 0, 'break-before pt-20':i != 0 && i%3 == 0 }"
                  *ngFor="let driver of questions; let i = index">
                  <div class="flex flex-row justify-between items-center pb-5">
                    <h5 class="text-lg text-zen font-semibold">{{driver.name |
                      extract:language}}</h5>
                    <div class="flex flex-row items-center py-1 px-2 rounded-sm gap-1 text-sm text-white"
                      [style.background]="resolveChartColor(driver.mean)">
                      <i class="fa fas" [ngClass]="driver.mean > 40 ? 'fa-caret-up' : 'fa-caret-down'"></i>
                      {{toFixed(driver.mean,1)}}%
                    </div>
                  </div>
                  <ng-container *ngFor="let question of driver.questions">
                    <ng-container *ngIf="!driver.isCustomDriver">
                      <div class="flex flex-row gap-4 pb-5">
                        <div class="flex flex-row items-start w-1/2 gap-1.5">
                          <i class="fa-regular fa-circle-question text-exp  text-sm"></i>
                          <h6 class="text-zen text-xs font-normal">
                            {{extractNameLanguage(question.name) |
                            translate:language:ModuleNames.Exit}}</h6>
                        </div>
                        <div class="flex flex-row items-center w-1/2 gap-4">
                          <span
                            class="leading-6 px-4 text-xs text-zen bg-zen bg-opacity-5 font-medium">{{toFixed(question.mean,1)}}%</span>
                          <div class="flex flex-row gap-px w-full">
                            <div *ngFor="let answer of question.answers" [style.width.%]="answer.percentage"
                              class="flex flex-col items-stretch gap-px chunk">
                              <span [class]="returnColor(answer.value)" class="h-2  rounded-sm"></span>
                              <p class="text-center text-3xs font-medium text-zen-lite">
                                {{toFixed(answer.percentage, 0)}} {{
                                answer.percentage?'%':''}}</p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <!-- end page -->
          <div class="block break-before bg-white relative">
            <div class="innerPage overflow-hidden relative">
              <div class="flex flex-col h-full w-full items-stretch justify-between bg-white p-10" [style.direction]="'ltr'">
                <div class="flex flex-col items-center gap-5">
                  <img class="h-20 w-auto object-contain mt-40" src="assets/img/logo.png" alt="">
                  <img class="h-10 w-auto object-contain" src="assets/img/tagline.png" alt="">
                  <div class="flex flex-row items-center justify-center gap-1 mt-10">
                    <i class="fa-regular fa-globe text-zen"></i>
                    <h6 class="text-zen text-sm">zenithr.com</h6>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div class="header w-full px-10 flex flex-row items-start justify-between">
            <h5 class="text-3xs text-zen text-opacity-60">{{ "txtEmployeeExitReport"
              |translate:language:ModuleNames.Exit }}</h5>
            <h5 class="text-3xs text-zen "><span class="text-opacity-60">{{
                "txtPreparedFor"|translate:language:ModuleNames.Exit }}
              </span> <span class="text-zen font-medium">{{entity.name | extract:language}}</span> </h5>
          </div>
          <div class="footer w-full px-10 flex flex-row items-end justify-between">
            <div class="flex flex-col gap-1">
              <p class="text-xxs text-zen text-opacity-50">{{ "txtPoweredby" |
                translate:language:ModuleNames.Shared }}</p>
              <img class="h-8 w-auto object-cover" src="assets/img/logo.png" alt="">
            </div>
            <div class="flex flex-col items-end gap-1">
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtGenerated"|translate:language:ModuleNames.Shared }}: {{date | date:
                'dd/MM/yyyy'}}
              </h5>
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtAllRights"|translate:language:ModuleNames.Shared }} © {{date | date: 'yyyy'}}
                -
                www.zenithr.com</h5>

            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-stretch justify-between w-1/5">
        <div class="exp flex flex-col gap-3 px-3">
          <div class="flex flex-row items-start gap-2 justify-start pb-3">
            <i class="far fa-file-export text-exp text-lg"></i>
            <h4 class="text-zen text-2xl">{{ 'btnExport' | translate:language:ModuleNames.Exit }}
            </h4>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm relative">
            <z-toggle *DemoAccount="'Button'; let isDemo=isDemo" [disable]="isDemo"
              (ngModelChange)="isDemo?'': exportReport = $event;filterTableOfContent()"
              [label]="'txt_report' | translate:language:ModuleNames.Exit " [ngModel]="exportReport"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm relative">
            <z-toggle *DemoAccount="'Button'; let isDemo=isDemo" [disable]="isDemo"
              (ngModelChange)="isDemo?'': exportOverview = $event;filterTableOfContent()"
              [label]="'txtOverview' | translate:language:ModuleNames.Exit " [ngModel]="exportOverview"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm relative">
            <z-toggle *DemoAccount="'Button'; let isDemo=isDemo" [disable]="isDemo"
              (ngModelChange)="isDemo?'': exportAnalysis = $event;filterTableOfContent()"
              [label]="'txtLeaversAnalysis' | translate:language:ModuleNames.Exit"
              [ngModel]="exportAnalysis"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm relative">
            <z-toggle *DemoAccount="'Button'; let isDemo=isDemo" [disable]="isDemo"
              (ngModelChange)="isDemo?'': exportQuestions = $event;filterTableOfContent()"
              [label]="'txt_questions' | translate:language:ModuleNames.Exit " [ngModel]="exportQuestions"></z-toggle>
          </div>

        </div>
        <div class="flex flex-row justify-between gap-5">
          <a (click)="close.emit(true)" class="btn cancel leading-12 rounded text-sm text-center px-5">{{"btn_cancel"|
            translate:language:ModuleNames.Exit}}</a>

          <a (click)="exportDraft(language,true)"
            class="btn exp leading-12 rounded text-sm text-center px-5">{{"btnExport"|
            translate:language:ModuleNames.Exit}}</a>

        </div>
      </div>
    </div>

  </ng-container>
</z-modal>
