import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { CDKPortalComponent, DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';

@Component({
  selector: 'app-drilldown',
  templateUrl: './drilldown.component.html',
  styleUrls: ['./drilldown.component.scss']
})
export class DrilldownComponent extends BaseComponent implements OnInit, CDKPortalComponent {

  close: EventEmitter<any> = new EventEmitter();
  results: EventEmitter<any> = new EventEmitter();

  open = false;

  language = "EN";

  drillDown = [];

  searchTerm;

  selectedCol;
  selectedRow;

  category;

  chartName;

  questionNo;

  constructor(@Inject(DATA_TOKEN) public data: { question: any, category: any, data: any, language: string, questionNo: number, group:any,isRespondentAnalysis:boolean }) {
    super()

    this.language = data?.language;

    this.populateDrillDown();
  }

  populateDrillDown() {

    switch(this.data.question.type)
    {
      case 'matrix':
      case 'ranking':
        this.drillDown = this.data.data.drillDown.filter(x => x.category == (this.data.category?.value ?? this.data.category) && x.group == (this.data.group?.value ?? this.data.group));
        break;

      case 'boolean':
        this.drillDown = this.data.data.drillDown.filter(x => x.category == (this.data.group?.value ?? this.data.group));
        break;

      default:
        this.drillDown = this.data.data.drillDown.filter(x => x.category == (this.data.category?.value ?? this.data.category));
        break;
    }
  }

  ngOnInit(): void {
    this.open = true;

    console.log("Data", this.data)
  }

  closeModal() {
    this.open = false;
    this.close.emit(true);
  }

  onKeyUp() { }

}
