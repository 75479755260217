import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Model } from 'survey-core';
import { Direction } from '@angular/cdk/bidi';
import { LoaderService } from '@ex/module/core/services/loader.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { LanguageFilterService } from '@ex/module/survey-x/data-access/services/language-filter.service';
import { ResponsesService } from '@ex/module/survey-x/data-access/services/responses.service';
import { FeedbackRatingDto, FormService, QuestionnaireDto, QuestionnaireService } from '@ex/survey-x/index';
import { BaseComponent } from '@ex/module/core/domain/base-component';


@Component({
  selector: 'app-survey-finish-page',
  templateUrl: './survey-finish-page.component.html',
  styleUrls: ['./survey-finish-page.component.scss']
})
export class SurveyFinishPageComponent extends BaseComponent implements OnInit {

  showFeedbackForm: boolean = true;

  direction: Direction = "ltr"
  ar = "ar";
  en = "default";

  language;
  ModuleNames = ModuleName;

  token: any;
  responseId: any;
  sub: QuestionnaireDto

  survey: QuestionnaireDto;
  surveyModel: Model;

  tenant: any;
  tenantKey: any;
  tenantName: any;

  selectedStar: number = 0;
  feedback: string = "";

  selectedStarLabelAndColor: any = { label: "", color: "" };

  isPreview = false;
  constructor(
    private responsesService: ResponsesService,
    private surveysService: FormService,
    private route: ActivatedRoute,
    private router: Router,
    public loader: LoaderService,
    private languageFilterService: LanguageFilterService,
    private questionnaireService: QuestionnaireService

  ) {
    super();
  }

  async ngOnInit() {
    this.loader.displayLoader();


    let surveyId = this.route.snapshot.paramMap.get('id');
    let localLanguage = this.LocalService.getJsonValue('language');
    let sessionLanguage = sessionStorage.getItem('sessionLanguage');
    if (!sessionLanguage)
      if (localLanguage) {
        this.language = localLanguage;
      } else {
        this.language = 'EN';
        sessionStorage.setItem('sessionLanguage', this.language);

      }
    else this.language = sessionLanguage;

    this.token = this.route.snapshot.paramMap.get('id');

    this.isPreview = this.route.snapshot.queryParamMap.get("preview") ? true : false;

    if (this.isPreview) {
      this.survey = (await this.surveysService.getFormWithoutResponses(this.token).toPromise())?.query;
    } else {
      this.survey = await this.questionnaireService.getQuestionnaire(this.token).toPromise();
    }

    const survey = new Model(JSON.parse(this.survey.jsonText));
    this.surveyModel = survey;
    this.surveyModel.locale = this.language;
    this.surveyModel.logo = this.survey.logo;

    this.survey.colorTheme = this.survey.colorTheme || '#0082C6';
    this.changeColor(this.survey.colorTheme);

    this.loader.hideLoader();
  }

  changeColor(color: string) {
    document.documentElement.style.setProperty('--main-primary-color', color);
    document.documentElement.style.setProperty('--sjs-primary-backcolor', color);
    document.documentElement.style.setProperty('--primary', color);
  }

  starClicked(index: number, starsContainer: HTMLDivElement) {
    this.selectedStar = index + 1;

    switch (this.selectedStar) {
      case 1:
        this.selectedStarLabelAndColor = { label: "txtPoor", color: "text-[#ff0012]" };
        break;
      case 2:
        this.selectedStarLabelAndColor = { label: "txtNot Bad", color: "text-[#ff8f00]" };
        break;
      case 3:
        this.selectedStarLabelAndColor = { label: "txtGood", color: "text-[#c5c500]" };
        break;
      case 4:
        this.selectedStarLabelAndColor = { label: "txtVery Good", color: "text-[#3fdd00]" };
        break;
      case 5:
        this.selectedStarLabelAndColor = { label: "txtExcellent", color: "text-[#119c00]" };
        break;

    }


    for (let i = 0; i < starsContainer.children.length; i++) {
      var star = starsContainer.children[i] as HTMLElement;

      if (i <= index) {
        star.style.color = this.survey.colorTheme || '#0082C6';
      } else {
        star.style.color = '#AEBBD1';
      }
    }
  }

  async submitFeedback() {
    this.loader.displayLoader();

    if (this.isPreview)
      return;

    if (this.token) {

      let formTableRespondentFeedback: FeedbackRatingDto = {
        rating: this.selectedStar.toString() || '1',
        comment: this.feedback || '',
      };

      // let response: any = await this.responsesService.getFormResponse(this.responseId);
      // var respondentId = response.respondentId

      this.questionnaireService.submitFeedback(this.token, formTableRespondentFeedback)
        .subscribe((response: any) => {
          this.loader.hideLoader();
          this.showFeedbackForm = false;
        });
    } else {
      this.loader.hideLoader();
      this.showFeedbackForm = false;
    }
  }

  closeWindow() {
    // this.router.navigate(['close-tab'], { skipLocationChange: true });
    window.close();
  }

  onlanguageChanged(event) {
    this.language = event.toUpperCase();
    sessionStorage.setItem('sessionLanguage', this.language);
    this.surveyModel.locale = event.toLowerCase();

  }
}
