/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { FormCategory } from '../models/FormCategory';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class FormCategoryService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param formTypeId 
     * @param tenantId 
     * @param companyId 
     * @returns FormCategory Success
     * @throws ApiError
     */
    public getAllCategoriesByFormTypeAndTenant(
formTypeId: string,
tenantId: string,
companyId: string,
): Observable<Array<FormCategory>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-category/get-all-category/{tenantId}/{companyId}/{formTypeId}',
            path: {
                'formTypeId': formTypeId,
                'tenantId': tenantId,
                'companyId': companyId,
            },
        });
    }

    /**
     * @param id 
     * @returns FormCategory Success
     * @throws ApiError
     */
    public getFormCategory(
id: string,
): Observable<FormCategory> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-category/get-form-category/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FormCategory Success
     * @throws ApiError
     */
    public addCategory(
requestBody?: FormCategory,
): Observable<FormCategory> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-category/add-category',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns FormCategory Success
     * @throws ApiError
     */
    public updateFormCategory(
id: string,
requestBody?: FormCategory,
): Observable<FormCategory> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/form-category/update-category/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteFormCategory(
id: string,
): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/form-category/delete-category/{id}',
            path: {
                'id': id,
            },
        });
    }

}
