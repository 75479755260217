import type { CreateUpdateLeaverDto, LeaverDto, LeaverListFilterDto, LeaverPagedRequestDto, LeaverPagedResultDto, SendReminderDto, StatusCountDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LeaversService {
  apiName = 'Default';

  create = (request: CreateUpdateLeaverDto) =>
    this.restService.request<any, LeaverDto>({
      method: 'POST',
      url: '/api/exit/leavers',
      body: request,
    },
    { apiName: this.apiName });

  delete = (key: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/exit/leavers/${key}`,
    },
    { apiName: this.apiName });

  get = (key: string) =>
    this.restService.request<any, LeaverDto>({
      method: 'GET',
      url: `/api/exit/leavers/${key}`,
    },
    { apiName: this.apiName });

  getList = (request: LeaverPagedRequestDto) =>
    this.restService.request<any, LeaverPagedResultDto>({
      method: 'POST',
      url: '/api/exit/leavers/all',
      body: request,
    },
    { apiName: this.apiName });

  getStatusCounts = (tenantKey: string, companyKey: string, surveyKey: string, filters: LeaverListFilterDto[]) =>
    this.restService.request<any, StatusCountDto>({
      method: 'POST',
      url: `/api/exit/leavers/status-counts/${tenantKey}/${companyKey}/${surveyKey}`,
      body: filters,
    },
    { apiName: this.apiName });

  sendReminders = (request: SendReminderDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/exit/leavers/send-reminders',
      body: request,
    },
    { apiName: this.apiName });

  update = (key: string, request: CreateUpdateLeaverDto) =>
    this.restService.request<any, LeaverDto>({
      method: 'PUT',
      url: `/api/exit/leavers/${key}`,
      body: request,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
