import { translateText } from "../../../features/survey/survey.component";

/**
 * Updates the HTML for a dropdown question.
 * @param options The options object containing the HTML element.
 */
export function updateDropdownQuestionHTML(options: any, locale?: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  var commentArea = body.querySelector(".sd-question__comment-area");
  var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
  if (!commentBox)
    commentBox = body?.querySelector(".sd-comment.sd-input");

  body.className = "question-body";
  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-5 text-start";
  inputTitle.innerHTML = translateText("Select a value:", locale);

  // Insert the input title at the beginning of the question body
  body.insertBefore(inputTitle, body.childNodes[0]);

  var max = 300;


  if (commentBox != null) {
    commentBox.setAttribute("maxLength", max);
    var counter = body?.querySelector('.comment-counter');
    if (!counter) {
      counter = document.createElement("span");
      counter.className = "text-end text-gray-500 text-sm comment-counter";
      counter.style.textAlign = "end";
      counter.innerHTML = commentBox.value.length + "/" + max;
      body.appendChild(counter);
    }
    body.insertBefore(commentBox, counter);
    commentBox.addEventListener("input", function () {
      // Update character count on input
      counter.innerHTML = commentBox.value.length + "/" + max;
    });
  }
}

/**
 * Updates the HTML for a tagbox question.
 * @param options The options object containing the HTML element.
 */
export function updateTagboxQuestionHTML(options: any, locale?: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  var commentArea = body.querySelector(".sd-question__comment-area");
  var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
  if (!commentBox)
    commentBox = body?.querySelector(".sd-comment.sd-input");

  body.className = "question-body";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-5 text-start";
  inputTitle.innerHTML = translateText("Select all that apply", locale) + ':';

  // Insert the input title at the beginning of the question body
  body.insertBefore(inputTitle, body.childNodes[0]);

  var max = 300;


  if (commentBox != null) {
    commentBox.setAttribute("maxLength", max);
    var counter = body?.querySelector('.comment-counter');
    if (!counter) {
      counter = document.createElement("span");
      counter.className = "text-end text-gray-500 text-sm comment-counter";
      counter.style.textAlign = "end";
      counter.innerHTML = commentBox.value.length + "/" + max;
      body.appendChild(counter);
    }
    body.insertBefore(commentBox, counter);
    commentBox.addEventListener("input", function () {
      // Update character count on input
      counter.innerHTML = commentBox.value.length + "/" + max;
    });
  }
}
