import { Component, OnInit } from '@angular/core';
import { ExitBaseComponent } from '../../ui/exit-base/exit-base.component';
import { combineLatest } from 'rxjs';
import { Tenant } from 'src/app/core/data-models/tenant';
import { Entity } from 'src/app/core/data-models/Entity';
import { PlatformUser } from 'src/app/core/data-models/platform-user';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CreateUpdateLeaverAnswerDto, LeaverAnswerNoteDto, LeaverAnswerNotesService, LeaverAnswersService, LeaverDto, LeaversService } from '@ex/exit/leavers';
import { UserNote } from 'src/app/core/data-models/user-note';
import { LeaverAnswersPreviewReportComponent } from '../../ui/leaver-answers-preview-report/leaver-answers-preview-report.component';
import { SurveyTemplateDto } from '@ex/exit/survey-templates';

@Component({
  selector: 'app-leaver-details',
  templateUrl: './leaver-details.component.html',
  styleUrls: ['./leaver-details.component.scss']
})
export class LeaverDetailsComponent extends ExitBaseComponent implements OnInit {

  constructor(private route: ActivatedRoute, private leaverService: LeaversService, private leaverAnswersService: LeaverAnswersService, private leaverUserNotesService: LeaverAnswerNotesService) {
    super(); LeaverAnswersService
  }

  ngOnInit(): void {
    this.GlobalService.innerRoute = true;
    this.lgCount = this.GlobalService.lgCount;
    this.LoaderService.displayLoader();
    this.addSubscription(this.EssentialObject$.subscribe(
      (object: any) => {
        this.tenant = object.tenant;
        this.entity = object.selectedEntity;
        this.language = object.language;
        this.user = object.user;
        if (this.entity?.key && this.tenant?.key) {
          this.viewAnswers(this.route?.snapshot?.params?.id)
        }
      }
    ));
    console.log('check language now', this.language)
    this.topValue = document.querySelector('.h-header')?.clientHeight / 16;
  }
  lgCount;
  personal: boolean = true;
  leaver: LeaverDto;
  leaverAnswers: SurveyTemplateDto;
  tenant: Tenant;
  entity: Entity;
  language
  user: PlatformUser
  addNote: boolean = false;
  newNote: Partial<LeaverAnswerNoteDto>;
  userNotes: LeaverAnswerNoteDto[];
  ten = Array(11);
  legendsTnps;
  steps = [
    { key: 0, color: 'bg-zen-red' },
    { key: 1, color: 'bg-zen-red' },
    { key: 2, color: 'bg-zen-red' },
    { key: 3, color: 'bg-zen-red' },
    { key: 4, color: 'bg-zen-red' },
    { key: 5, color: 'bg-zen-red' },
    { key: 6, color: 'bg-zen-red' },
    { key: 7, color: 'bg-zen-orange' },
    { key: 8, color: 'bg-zen-orange' },
    { key: 9, color: 'bg-zen-green' },
    { key: 10, color: 'bg-zen-green' },
  ]
  legends = [
    { steps: 7, name: 'txtUnLikely' },
    { steps: 2, name: 'txtNeutralLikely' },
    { steps: 2, name: 'txtLikely' },
  ];
  currentLeaverKey;
  topValue: number = 0;







  viewAnswers(key) {
    this.addSubscription(this.leaverService.get(key).pipe(switchMap(leaver => {
      return this.leaverAnswersService.get(key).pipe(switchMap(answers => {
        return this.leaverUserNotesService.getList(key).pipe(map(notes => ({ leaver, answers, notes })))
      }))
    })).subscribe(({ leaver, answers, notes }) => { 
      this.leaverAnswers = answers;
      for (let index = 0; index < this.leaverAnswers.sections.length; index++) {
        this.leaverAnswers.sections[index].questions = this.leaverAnswers.sections[index].questions.filter(x => !x.seperationType || x.seperationType == leaver.seperationType)
      }


      this.leaver = leaver;
      this.userNotes = notes;
      this.LoaderService.hideLoader();
      // this.openExportPreviewDialog();
    }));

  }
  logData(e) {
    console.log(e);
  }



  checkOther(q) { 
    if (q.answer) {
      let optionKey = q.questionOptions?.find(option => option.otherAnswer == true)?.key;
      if (optionKey)
        return (q.answer.indexOf(optionKey) > -1)
    }
    if (q.subQuestions) {
      let subKey = q.subQuestions?.find(option => option.otherAnswer == true)?.key;
      return subKey ? true : false;
    }

  }
  extractNameLanguage(eG: any) {
    return this.LanguageService.extractNameLanguage(eG, this.language).replaceAll('[CompanyName]', this.LanguageService.extractNameLanguage(this.entity.name, this.language));
  }

  getConfidence(subquestionKey: string, key: string) { 
    return this.GeneralService.getQuestionOptions(subquestionKey, key, this.language);
  }

  openExportPreviewDialog() { 
    this.LoaderService.displayLoader();
    this.ZenCDKService.openComponent(LeaverAnswersPreviewReportComponent, {
      language: this.language,
      currentUser: this.user,
      leaver: this.leaver,
      leaverAnswers: this.leaverAnswers,
      leaverName: this.leaver.name,
      companyName:this.entity.name,
      notes: this.userNotes
    });
  }


  checkValue(array, value) {
    return array?.find(v => v == value) ? true : false
  }
  ngOnDestroy() {
    this.GlobalService.innerRoute = false;
  }
  extractGender(gender) {
    return this.GeneralService.genders_new.find(g => g.key == gender)?.name == undefined ? '- ' : this.GeneralService.genders_new.find(g => g.key == gender)?.name;
  }
  extractCountry(country) {
    return this.GeneralService.countries_new.find(c => c.key == country)?.name == undefined ? '- ' : this.GeneralService.countries_new.find(c => c.key == country)?.name;
  }
  extractType(key) {
    return this.GeneralService.seperationTypes.find(c => c.key == key && this.language == c.lang)?.name == undefined ? '- ' : this.GeneralService.seperationTypes.find(c => c.key == key && this.language == c.lang)?.name;
  }
  extractCountryCode(key) {
    return this.GeneralService.countries_new.find(f => f.key == key)?.name == undefined ? '- ' : this.GeneralService.countries_new.find(f => f.key == key)?.countryCode;
  }

  extractJobLevel(key) {
    return this.entity.BandLevels.find(s => s.key == key)?.name == undefined ? '- ' : this.entity.BandLevels.find(s => s.key == key)?.name;
  }
  extractJobGrade(key) {
    return this.entity.JobGrades.find(s => s.key == key)?.name == undefined ? '- ' : this.entity.JobGrades.find(s => s.key == key)?.name;
  }
  extractPerformanceRate(key) {
    return this.entity.PerformanceRates.find(s => s.key == key)?.value == undefined ? '- ' : this.entity.PerformanceRates.find(s => s.key == key)?.value;
  }
  resignationCheck(question) {
    return this.leaver.seperationType == 'resignation' && !question.skipped;
  }
  dependsOnQuestionCheck(question, section) {
    let dependOnQuestionAnswers = section.questions.find(q => q.key == question.dependsOnQuestionKey)?.answer;
    if (!question.dependsOnQuestionKey || !dependOnQuestionAnswers)
      return false;

    if (dependOnQuestionAnswers || dependOnQuestionAnswers?.length) {
      if (Array.isArray(dependOnQuestionAnswers))
        return dependOnQuestionAnswers?.find(key => question.skipIfConditionIsKeys.find(x => x == key)) && this.resignationCheck(section.questions.find(q => q.key == question.dependsOnQuestionKey))
      else if (typeof dependOnQuestionAnswers === 'string' || dependOnQuestionAnswers instanceof String) {
        return question.skipIfConditionIsKeys.find(x => x == dependOnQuestionAnswers)
      }
    }
    else
      return false;
  }

  createUpdateNote(newNote: Partial<LeaverAnswerNoteDto>) {
    let note: CreateUpdateLeaverAnswerDto = {
      leaverKey: newNote.leaverKey,
      userName: newNote.userName,
      note: newNote.note
    }
    if (newNote.note.length > this.lgCount)
      return;
    if (!newNote.key) {
      this.leaverUserNotesService.create(note).subscribe((x: LeaverAnswerNoteDto) => {
        this.addNote = false;
        x.createdByUser = this.user.key;
        this.userNotes.push(x);
        this.SnackbarService.success(this.localize("Note added successuflly", this.language, this.ModuleNames.Exit));
      })
    }
    else this.leaverUserNotesService.update(newNote.key, note).subscribe(note => {
      this.addNote = false;
      note.createdByUser = this.user.key;
      let updatedNote = this.userNotes.find(x => x.key == note.key);
      this.SnackbarService.success(this.localize("Note updated successfully", this.language, this.ModuleNames.Exit));
      updatedNote.note = note.note;
    })
  }
  addNewNote(requestedNote?: Partial<LeaverAnswerNoteDto>) {
    if (!requestedNote) {
      this.newNote = {
        leaverKey: this.leaver.key,
        userName: this.user.firstName + ' ' + this.user.lastName,
        note: ''
      }
    }
    else {
      let newNote = JSON.parse(JSON.stringify(requestedNote));
      this.newNote = newNote
    }
    this.addNote = true;
  }

  deleteNote(note: LeaverAnswerNoteDto) {
    this.leaverUserNotesService.delete(note.key).subscribe(x => {
      this.SnackbarService.success(this.localize("Note deleted successuflly", this.language, this.ModuleNames.Exit));
      this.userNotes.splice(this.userNotes.indexOf(note), 1);
    });
  }
  // updateNewNote(note: UserNote) {
  //   if (note.note.length < 3)
  //     return this.snackbar.error(this.translate.transform("A note can't be empty!", this.language, this.ModuleNames.Exit));
  //   !this.leaverAnswers.userNotes ? this.leaverAnswers.userNotes = [] : '';
  //   note.createdDate = new Date();
  //   console.log(this.noteIndex);
  //   if (this.noteIndex != null)
  //     this.leaverAnswers.userNotes[this.noteIndex] = note;
  //   else
  //     this.leaverAnswers.userNotes.push(note);
  //   this.noteIndex = null;
  //   this.leaverService.updateLeaverQuestionnaire(this.leaverAnswers).then((questionnaire) => {
  //     this.addNote = false;
  //     this.snackbar.success(this.translate.transform("Note added successuflly", this.language, this.ModuleNames.Exit));
  //     this.newNote = null;
  //   }).catch((err) => {
  //     console.log(err);
  //     this.viewAnswers(this.currentLeaverKey);
  //   });

  // }
  // deleteNote(note: UserNote) {
  //   this.leaverAnswers.userNotes.splice(this.leaverAnswers.userNotes.indexOf(note), 1);
  //   this.leaverService.updateLeaverQuestionnaire(this.leaverAnswers).then((questionnaire) => {
  //     this.addNote = false;
  //     this.newNote = null;
  //     this.snackbar.success(this.translate.transform("Note deleted successuflly", this.language, this.ModuleNames.Exit));
  //   }).catch((err) => {
  //     console.log(err);
  //     this.viewAnswers(this.currentLeaverKey);
  //   });
  // }
}
