import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  TemplateRef,
  ContentChild,
  ElementRef,
  ChangeDetectorRef,
  AfterContentChecked,
  OnDestroy,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, Subscription, of } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language.service';
import { ActivatedRoute } from '@angular/router';
import { EssentialObjectService } from 'src/app/core/services/essential-object.service';
import { LocalService } from 'src/app/core/services/local.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { HttpClient } from '@angular/common/http';
import { EngagementData } from 'src/app/core/data-models/engagement-data';
import { MiscellaneousService } from 'src/app/core/services/miscellaneous.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { EngagementMetric } from 'src/app/core/data-models/engagement-metric';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { TableServerOptionsComponent } from './table-server-options/table-server-options.component';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, switchMap, takeWhile } from 'rxjs/operators';

export enum TableModule {
  Experience = "Experience",
  Thomas = "Thomas",
}
export const TableRequests = {
  Experience: [
    'searchText',
    'TenantKey',
    'CompanyKey',
    'SurveyKey',
    'pageNumber',
    'pageSize',
    'FilterByMetrics'],
  Thomas: [
    'TenantKey',
    'CompanyKey',
    'AssessmentTypes',
    'Filter',
    'searchText',
    'pageNumber',
    'pageSize']
};

export interface TableHttpRequest {
  module?: TableModule;
  operation?: string;
  dataApi?: string;
  removeApi?: string;
  searchText?: string;
  TenantKey?: string;
  CompanyKey?: string;
  SurveyKey?: string;
  AssessmentTypes?: any; //Thomas attribute
  Filter?: any; //Thomas attribute
  pageNumber?: number;
  pageSize?: number;
  FilterByMetrics?: EngagementMetric[];
}

@Component({
  selector: 't-table',
  templateUrl: './temp-table.component.html',
  styleUrls: ['./temp-table.component.scss'],
})
export class TempTableComponent extends TableServerOptionsComponent implements OnInit, OnDestroy {
  @Input() exportTour;
  @Input() customizeTour;
  private subscriptions = new Subscription();
  constructor(private localService: LocalService, private essentialObjectService: EssentialObjectService, private cdr: ChangeDetectorRef, private route: ActivatedRoute, private languageService: LanguageService, private responseDialogService: ResponseDialogService, private miscellaneousService: MiscellaneousService, private loader: LoaderService, private translationPipe: TranslatePipe
  ) {
    super();
    this.subscriptions.add(this.essentialObjectService.essentialObject.subscribe(
      (object: any) => {
        this.language = object.language;
      }
    ));
  };
  ModuleNames = ModuleName
  txt_search: string
  showLoader: boolean = false;
  @ContentChild(TemplateRef) mytemplate: TemplateRef<any>;

  @ViewChild('paginator', { static: false }) set paginator(
    paginator: MatPaginator
  ) {
    if (this.dataSource) this.dataSource.paginator = paginator;
  }
  @ViewChild(MatSort) sort!: MatSort;
  @Input('reload') set reload(r: boolean) {
    if (r && this.options?.request != null)
      this.fetchFromServer(this.options.request);
  }
  @Input() hasMassDelete: boolean = true;
  @Input() hasAdd: boolean = true;
  @Output() serverExportClicked = new EventEmitter();
  options: TableOptions;
  defaultOptions: TableOptions = {
    sticky: false,
    selection: false,
    export: false,
    exportName: 'Data Sheet',
    exportText: '',
    searchText: "",
    selectionKey: 'status',
    language: 'EN',
    whiteBackground: false,
    hidePagination: false,
    selectionRemove: ['COMPLETED', 'DELETED'],
    colors: {
      button: 'exp',
      default: 'bg-white',
      STARTED: 'bg-orange',
      COMPLETED: 'bg-zen-green',
      DELETED: 'bg-zen-red',
      PENDING: 'bg-zen',
      CLOSED: 'bg-zen-green',
      DRAFT: 'bg-zen',
      LIVE: 'bg-zen-blueaccent',
    },
    header: true,
    customize: false,
    customizeText: 'Customize',
    disableSearch: false,
    serverSide: false,
    request: {
      pageNumber: 1,
      pageSize: 5,
    },
    selectAllReverse:false
  };

  firstLoad = true;
  language;
  tLanguage;
  searchText: FormControl = new FormControl();
  @Input() selectionKey = 'key';
  @Input('search') set setSearch(value: string) {
    console.log(value);

    this.searchText.setValue(value);
  }
  selectedLanguageChange(key) {
    this.language = key;
    this.localService.setJsonValue('language', this.language);
    location.reload();
  }
  openOptions() {
    this.tLanguage = this.language;
  }
  displayChanges() {
    if (this.tLanguage != this.language)
      setTimeout(() => {
        this.language = this.tLanguage;
        this.selectedLanguageChange(this.language);
      }, 500);
  }
  @Input('options') set tOptions(ops: TableOptions) {

    if (!this.defaultOptions?.request.module)
      this.defaultOptions.request.module = TableModule.Experience;

    this.displayedColumns = [];
    this.options = { ...this.defaultOptions, ...ops };
    this.options.request = { ...this.defaultOptions?.request, ...ops?.request };
    this.options.searchText = this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Shared)
    this.options.exportText = this.translationPipe.transform("btnExport", this.language, this.ModuleNames.Shared)
    if (ops?.columns) {
      this.displayedColumns = ops.columns;
      if (ops.selection && this.displayedColumns?.[0].field != 'select')
        this.displayedColumns.unshift({ field: 'select', title: '' });
    }
    if (ops?.selection && this.displayedColumns) {
      if (ops.selection && this.displayedColumns?.[0].field != 'select')
        this.displayedColumns?.unshift({ field: 'select', title: '' });

      if (this.firstLoad) {
        this.selectedTable = new SelectionModel<any>(true, []);
        this.firstLoad = false;
      }

      this.change = this.selectedTable.changed.asObservable();
      this.subscription = this.change.subscribe((s) => {
        this.currentSelection = s.source.selected
        this.selectionChanges.emit(s.source.selected);
        if (this.options.serverSide) this.selectionChange();
      });
    }
    if (!ops?.serverSide) {
      this.initiateTable(ops?.data);
      this.updateGoto();
    } else {
      this.fetchFromServer(this.options.request);
    }
    console.log(this.options);
  }

  initiateTable(tableData) {
    //this.selectionChanges.emit(0);
    this.data = tableData;
    this.dataSource = new MatTableDataSource(tableData);
    this.dataSource.filterPredicate = (data: Element, filter: string) => {
      let dataStr: string;
      dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) != -1;
    };

    setTimeout(() => {
      this.showLoader = false;
      this.cdr.detectChanges();
    }, 600);

    if (this.allSelected || this.innerAllSelect) {
      console.log(this.unselectedRecords);

      this.selectedTable = new SelectionModel<any>(true, []);
      this.change = this.selectedTable.changed.asObservable();
      this.subscription = this.change.subscribe((s) => {
        this.currentSelection = s.source.selected
        this.selectionChanges.emit(s.source.selected);
        if (this.options.serverSide) this.selectionChange();
      });
      this.dataSource.filteredData.forEach((row) => {
        if (this.options.selectionRemove.every(key => key != row[this.options.selectionKey]) && !this.unselectedRecords.find(i => i[this.selectionKey] == row[this.selectionKey])) {
          this.selectedTable.select(row);
        }
      });
    }


    this.showLoader = false;
  }

  logData(e) {
    console.log(e);
  }

  selectedTable = new SelectionModel<any>(true, []);
  @Output() tableSelectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadTable: EventEmitter<any> = new EventEmitter<any>();
  data;
  innerAllSelect = false;
  displayedColumns: any[];
  dataSource: MatTableDataSource<any>;
  newDate: any;
  @Input() totalRecords;
  pageOfItems = [];
  customization: boolean = false;
  pageIndex = 0;
  pageNumbers;
  pageSize = 10;
  allSelected: boolean = false;
  dir = 'ltr';
  unselectedRecords = [];
  subscription: Subscription;
  change: Observable<any>;
  currentSelection = [];
  ngOnInit() {
    if (document.body.dir == 'rtl') {
      this.dir = 'rtl';
    }

    this.searchText.valueChanges.pipe(filter((x: string) => x.length >= 3 || x.length == 0), debounceTime(300), switchMap(x => {
      return of(x)
    })).subscribe(text => {
      if (!this.options.serverSide)
        this.applyFilter(text)
      else
        this.fetchSearch(text);
    })
  }
  fetchIndex(index) {
    if (index == 'last')
      index = Math.ceil(this.totalRecords / this.options.request.pageSize);
    this.options.request.pageNumber = index;
    this.fetchFromServer(this.options.request);
  }
  fetchNewSize(value) {
    this.options.request.pageSize = value;
    this.fetchFromServer(this.options.request);
  }

  fetchSearch(value) {
    this.options.request.searchText = value;
    this.options.request.pageNumber = 1;
    this.fetchFromServer(this.options.request);
  }
  pageLength;
  pageLengths = [
    { key: 5, name: 5 },
    { key: 10, name: 10 },
    { key: 20, name: 20 },
  ];

  fetchFromServer(request: TableHttpRequest) {
    this.showLoader = true;
    let req: any = {};
    for (let index = 0; index < this.requests[request.module].length; index++) {
      let moduleReq = this.requests[request.module][index];
      req[moduleReq] = request[moduleReq];
    }
    console.log('Fetching Page Number: ' + req.pageNumber);
    console.log('Page INDEX: ' + this.pageIndex);
    this.miscellaneousService[this.APIFetch[request.module]](request.dataApi, req).subscribe((response: any) => {
      if (response.response == 'SUCCESS') {
        this.totalRecords = response.Data.totalRecords;
        this.initiateTable(response.Data.records);
        if (response?.Data?.records?.length == 0 && this.totalRecords > 0) {
          this.reloadTable.emit();
        }
      }
    });
  }

  selectionChange() {
    let exemptMetrics = [];
    if (this.options.selectionRemove.length)
      for (let index = 0; index < this.options.selectionRemove.length; index++) {
        exemptMetrics.push({ key: this.options.selectionKey, value: this.options.selectionRemove[index] });
      }
    // this.showLoader = true;

    let exMetrics: string = "";
    for (let i = 0; i < exemptMetrics.length; i++) {
      let ex = exemptMetrics[i];
      let delim = "";
      if (i > 0) delim = ", ";
      exMetrics = exMetrics + delim + "'" + ex.value + "'";
    }



    let req = {
      SurveyType: "",
      UserKey: "",
      TenantKey: "",
      CompanyKey: "",
      SurveyKey: "",
      type: this.allSelected ? "ALL" : "SELECTED",
      exclude: this.unselectedRecords,
      exemptions: exMetrics,
      isCandidates: null,
      evaluatorType: null,
      candidateKey: null,
      exemptionKey: (this.options.selectionKey === 'status' ? 'ActualStatus' : this.options.selectionKey),
      respondents: this.allSelected ? [] : this.currentSelection
    };

    this.tableSelectionChanged.emit(req);
  }


  async deleteFromServer(request: TableHttpRequest) {


    let confirmed = await this.responseDialogService.confirmation(
      this.allSelected ? 'txt_remove_respondents_confirmation_message' : 'txt_remove_respondent_confirmation_message',
      'txt_delete_confirmation_subject',
      'txt_okay_action',
      'btn_cancel',
      'ENGAGEMENT',
      this.language
    );
    if (confirmed) {

      let exemptMetrics = [];
      if (this.options.selectionRemove.length)
        for (let index = 0; index < this.options.selectionRemove.length; index++) {
          exemptMetrics.push({ key: this.options.selectionKey, value: this.options.selectionRemove[index] });
        }
      // this.showLoader = true;

      let exMetrics: string = "";
      for (let i = 0; i < exemptMetrics.length; i++) {
        let ex = exemptMetrics[i];
        let delim = "";
        if (i > 0) delim = ", ";
        exMetrics = exMetrics + delim + "'" + ex.value + "'";
      }



      let req = {
        SurveyType: "",
        UserKey: "",
        TenantKey: request.TenantKey,
        CompanyKey: request.CompanyKey,
        SurveyKey: request.SurveyKey,
        type: this.allSelected ? "ALL" : "SELECTED",
        exclude: this.unselectedRecords,
        exemptions: exMetrics,
        exemptionKey: (this.options.selectionKey === 'status' ? 'ActualStatus' : this.options.selectionKey),
        respondents: this.allSelected ? [] : this.currentSelection
      };

      this.actionClicked.emit({ request: req, action: (req.type == 'ALL' ? 'BULK_REMOVE' : 'SELECTION') });
      this.currentSelection = [];
      // this.miscellaneousService.dynamicTableQuery(request.dataApi, req).subscribe((response: any) => {
      //   if (response.response == 'SUCCESS') {
      //     this.initiateTable(response.Data.records);
      //     this.totalRecords = response.Data.totalRecords
      //   }
      // });
    }
  }

  generatePaginationValues(index, total) { }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  getRows(columns: any[]) {
    return columns?.map((x) => x['field']);
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.subscriptions) this.subscriptions.unsubscribe();
  }

  onPageEvent(event: PageEvent) { }
  displayYear() {
    return new Date().getFullYear();
  }

  applyFilter(filterValue, type?) {
    console.log(filterValue);
    if (!this.options.serverSide)
      this.dataSource.filter = filterValue?.trim().toLowerCase();
    console.log(this.dataSource.filter);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
      this.updateGoto();
    }
  }

  updateGoto() {
    this.pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(this.dataSource?.filteredData?.length / this.pageSize);
      i++
    ) {
      this.pageNumbers.push(i);
    }
  }

  goToChange(number) {
    this.dataSource.paginator.pageIndex = number;
    const event: PageEvent = {
      length: this.dataSource.paginator.length,
      pageIndex: this.dataSource.paginator.pageIndex,
      pageSize: this.dataSource.paginator.pageSize,
    };
    this.dataSource.paginator.page.next(event);
    this.pageIndex = number;
  }

  handleSingleClick(element, selected) {
    this.selectedTable.toggle(element);

      if ((this.options.serverSide && this.allSelected ) || (this.options.selectAllReverse && this.innerAllSelect)) {
        if (!this.selectedTable.isSelected(element))
          this.unselectedRecords.push(element);
        else this.unselectedRecords.splice(this.unselectedRecords.indexOf(element), 1);
        this.selectionChanges.emit(this.unselectedRecords);
      }
      else {
        this.unselectedRecords = [];
      }
  }
  isAllSelected(data, dataSource?) {

    const numSelected = data.selected.filter(s => this.options.selectionRemove.every(key => key != s[this.options.selectionKey])).length;
    // if(dataSource)
    const numRows = dataSource?.data.filter(s => this.options.selectionRemove.every(key => key != s[this.options.selectionKey])).length;
    // if (this.options.serverSide)
    //   return this.allSelected && numSelected === numRows;
    // else
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(data, dataSource) {
    if (this.isAllSelected(data, dataSource) || (this.allSelected && !this.unselectedRecords.length)) {
      data.clear();
      if (this.options.serverSide) {
        this.unselectedRecords = [];

        this.allSelected = false;
        this.selectedTable = new SelectionModel<any>(true, []);
        this.change = this.selectedTable.changed.asObservable();
        this.subscription = this.change.subscribe((s) => {
          this.currentSelection = s.source.selected
          this.selectionChanges.emit(s.source.selected);
          if (this.options.serverSide) this.selectionChange();

        });
      }
    } else {
      this.unselectedRecords = [];
      dataSource.data.forEach((row) => this.options.selectionRemove.every(key => key != row[this.options.selectionKey]) ? data.select(row) : '');
      if (this.options.serverSide) this.allSelected = true;
    }

  }
  allSelectedUpdate(val: boolean) {
    this.innerAllSelect = val;
    this.selectAll.emit(val);
    if (!val)
      this.selectedTable.clear();
  }

  checkboxLabel(data, row?): string {
    if (!row) {
      return `${this.isAllSelected(data) ? 'select' : 'deselect'} all`;
    }
    return `${data.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1
      }`;
  }
  checkCondition(element) {
    return this.options.selectionRemove.every(
      (key) => key != element[this.options.selectionKey]
    )
      ? true
      : false;
  }

  manualSingleCheck(item: any) {
    return this.selectedTable.selected.find(x => JSON.stringify(x) === JSON.stringify(item)) ? true : false;
  }
  masterChecked() {
    return (this.selectedTable.hasValue() && this.isAllSelected(this.selectedTable, this.dataSource) && !this.options.serverSide) || (this.options.serverSide && this.unselectedRecords.length == 0 && this.allSelected)
  }
  indeterminateChecked() {
    return (this.selectedTable.hasValue() && !this.isAllSelected(this.selectedTable, this.dataSource) && !this.options.serverSide) || (this.options.serverSide && ((this.unselectedRecords.length > 0 && this.allSelected) || (this.currentSelection?.length > 0 && this.unselectedRecords?.length == 0 && !this.allSelected)))
  }
  customAllCheck(element){
    return !this.options.serverSide &&  this.innerAllSelect && !this.unselectedRecords.find(x => JSON.stringify(x) === JSON.stringify(element))
  }

  search = {
    v: '5.4.4',
    fr: 60,
    ip: 0,
    op: 316,
    w: 642,
    h: 642,
    nm: 'NEW sin movs',
    ddd: 0,
    assets: [
      {
        id: 'comp_0',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 3,
            nm: 'parent sombra',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.3], y: [1] },
                    o: { x: [0.3], y: [0] },
                    t: 34,
                    s: [64],
                    e: [23],
                  },
                  {
                    i: { x: [0.437], y: [0.995] },
                    o: { x: [0.7], y: [0] },
                    t: 92,
                    s: [23],
                    e: [-21],
                  },
                  {
                    i: { x: [0.358], y: [1] },
                    o: { x: [0.544], y: [0] },
                    t: 133,
                    s: [-21],
                    e: [16],
                  },
                  {
                    i: { x: [0.326], y: [0.985] },
                    o: { x: [0.595], y: [0] },
                    t: 183,
                    s: [16],
                    e: [0],
                  },
                  { t: 220 },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.222, y: 1 },
                    o: { x: 0, y: 0 },
                    t: 34,
                    s: [240.252, 377.045, 0],
                    e: [323.752, 265.045, 0],
                    to: [13.917, -18.667, 0],
                    ti: [-5.248, 0.545, 0],
                  },
                  {
                    i: { x: 0.453, y: 0.528 },
                    o: { x: 0.785, y: 0 },
                    t: 88,
                    s: [323.752, 265.045, 0],
                    e: [334.252, 403.045, 0],
                    to: [5.248, -0.545, 0],
                    ti: [-24.248, -17.455, 0],
                  },
                  {
                    i: { x: 0.156, y: 1 },
                    o: { x: 0.423, y: 0.345 },
                    t: 133,
                    s: [334.252, 403.045, 0],
                    e: [499.252, 415.045, 0],
                    to: [24.248, 17.455, 0],
                    ti: [-27.5, -2, 0],
                  },
                  { t: 183.03515625 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [47.206, 48.054, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                    o: { x: [0.15, 0.15, 0.15], y: [0, 0, 0] },
                    t: 34,
                    s: [0, 0, 100],
                    e: [240, 240, 100],
                  },
                  {
                    i: { x: [0.53, 0.53, 0.53], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    t: 60,
                    s: [240, 240, 100],
                    e: [240, 240, 100],
                  },
                  {
                    i: { x: [0.16, 0.16, 0.16], y: [1, 1, 1] },
                    o: { x: [0.42, 0.42, 0.42], y: [0, 0, 0] },
                    t: 133,
                    s: [240, 240, 100],
                    e: [400, 400, 100],
                  },
                  { t: 183 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            ip: 34,
            op: 12647,
            st: 18,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'LUPA sombra',
            parent: 1,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [26.162, 29.375, 0], ix: 2 },
              a: {
                a: 1,
                k: [
                  {
                    i: { x: 0.67, y: 1 },
                    o: { x: 0.33, y: 0 },
                    t: 133,
                    s: [59.528, 35.656, 0],
                    e: [55.428, 43.556, 0],
                    to: [-0.683, 1.317, 0],
                    ti: [0.683, -1.317, 0],
                  },
                  { t: 183 },
                ],
                ix: 1,
              },
              s: { a: 0, k: [50, 50, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 1,
                      k: [
                        {
                          i: { x: 0.2, y: 1 },
                          o: { x: 0.167, y: 0.167 },
                          t: 35,
                          s: [
                            {
                              i: [
                                [-2.46, 0.083],
                                [-0.259, 19.058],
                                [1.377, 0.116],
                                [0, -19.883],
                              ],
                              o: [
                                [3.106, -0.105],
                                [0.271, -19.881],
                                [-1.276, -0.107],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [2.204, 2.371],
                                [0.001, -36],
                                [-3.43, 1.971],
                              ],
                              c: true,
                            },
                          ],
                          e: [
                            {
                              i: [
                                [-19.923, 0],
                                [0, 19.882],
                                [19.923, 0],
                                [0, -19.883],
                              ],
                              o: [
                                [19.923, 0],
                                [0, -19.883],
                                [-19.923, 0],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [36.074, 0],
                                [0.001, -36],
                                [-36.074, 0],
                              ],
                              c: true,
                            },
                          ],
                        },
                        {
                          i: { x: 0.765, y: 0.384 },
                          o: { x: 0.8, y: 0 },
                          t: 60,
                          s: [
                            {
                              i: [
                                [-19.923, 0],
                                [0, 19.882],
                                [19.923, 0],
                                [0, -19.883],
                              ],
                              o: [
                                [19.923, 0],
                                [0, -19.883],
                                [-19.923, 0],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [36.074, 0],
                                [0.001, -36],
                                [-36.074, 0],
                              ],
                              c: true,
                            },
                          ],
                          e: [
                            {
                              i: [
                                [-2.46, 0.083],
                                [-0.259, 19.058],
                                [1.377, 0.116],
                                [0, -19.883],
                              ],
                              o: [
                                [3.106, -0.105],
                                [0.271, -19.881],
                                [-1.276, -0.107],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [2.204, 2.371],
                                [0.001, -36],
                                [-3.43, 1.971],
                              ],
                              c: true,
                            },
                          ],
                        },
                        {
                          i: { x: 0.2, y: 1 },
                          o: { x: 0.21, y: 0.549 },
                          t: 84,
                          s: [
                            {
                              i: [
                                [-2.46, 0.083],
                                [-0.259, 19.058],
                                [1.377, 0.116],
                                [0, -19.883],
                              ],
                              o: [
                                [3.106, -0.105],
                                [0.271, -19.881],
                                [-1.276, -0.107],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [2.204, 2.371],
                                [0.001, -36],
                                [-3.43, 1.971],
                              ],
                              c: true,
                            },
                          ],
                          e: [
                            {
                              i: [
                                [-19.923, 0],
                                [0, 19.882],
                                [19.923, 0],
                                [0, -19.883],
                              ],
                              o: [
                                [19.923, 0],
                                [0, -19.883],
                                [-19.923, 0],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [36.074, 0],
                                [0.001, -36],
                                [-36.074, 0],
                              ],
                              c: true,
                            },
                          ],
                        },
                        {
                          i: { x: 0.2, y: 1 },
                          o: { x: 0.167, y: 0 },
                          t: 110,
                          s: [
                            {
                              i: [
                                [-19.923, 0],
                                [0, 19.882],
                                [19.923, 0],
                                [0, -19.883],
                              ],
                              o: [
                                [19.923, 0],
                                [0, -19.883],
                                [-19.923, 0],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [36.074, 0],
                                [0.001, -36],
                                [-36.074, 0],
                              ],
                              c: true,
                            },
                          ],
                          e: [
                            {
                              i: [
                                [-19.923, 0],
                                [0, 19.882],
                                [19.923, 0],
                                [0, -19.883],
                              ],
                              o: [
                                [19.923, 0],
                                [0, -19.883],
                                [-19.923, 0],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [36.074, 0],
                                [0.001, -36],
                                [-36.074, 0],
                              ],
                              c: true,
                            },
                          ],
                        },
                        {
                          i: { x: 0.765, y: 0.384 },
                          o: { x: 0.167, y: 0 },
                          t: 126,
                          s: [
                            {
                              i: [
                                [-19.923, 0],
                                [0, 19.882],
                                [19.923, 0],
                                [0, -19.883],
                              ],
                              o: [
                                [19.923, 0],
                                [0, -19.883],
                                [-19.923, 0],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [36.074, 0],
                                [0.001, -36],
                                [-36.074, 0],
                              ],
                              c: true,
                            },
                          ],
                          e: [
                            {
                              i: [
                                [-2.46, 0.083],
                                [-0.259, 19.058],
                                [1.377, 0.116],
                                [0, -19.883],
                              ],
                              o: [
                                [3.106, -0.105],
                                [0.271, -19.881],
                                [-1.276, -0.107],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [2.204, 2.371],
                                [0.001, -36],
                                [-3.43, 1.971],
                              ],
                              c: true,
                            },
                          ],
                        },
                        {
                          i: { x: 0.2, y: 1 },
                          o: { x: 0.21, y: 0.549 },
                          t: 147,
                          s: [
                            {
                              i: [
                                [-2.46, 0.083],
                                [-0.259, 19.058],
                                [1.377, 0.116],
                                [0, -19.883],
                              ],
                              o: [
                                [3.106, -0.105],
                                [0.271, -19.881],
                                [-1.276, -0.107],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [2.204, 2.371],
                                [0.001, -36],
                                [-3.43, 1.971],
                              ],
                              c: true,
                            },
                          ],
                          e: [
                            {
                              i: [
                                [-19.923, 0],
                                [0, 19.882],
                                [19.923, 0],
                                [0, -19.883],
                              ],
                              o: [
                                [19.923, 0],
                                [0, -19.883],
                                [-19.923, 0],
                                [0, 19.882],
                              ],
                              v: [
                                [0.001, 36],
                                [36.074, 0],
                                [0.001, -36],
                                [-36.074, 0],
                              ],
                              c: true,
                            },
                          ],
                        },
                        { t: 173 },
                      ],
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.9686274509803922, 0.9529411764705882,
                        0.9529411764705882, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.2], y: [1] },
                          o: { x: [0.167], y: [0.167] },
                          t: 35,
                          s: [5.6],
                          e: [3.6],
                        },
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.8], y: [0] },
                          t: 60,
                          s: [3.6],
                          e: [5.6],
                        },
                        {
                          i: { x: [0.2], y: [1] },
                          o: { x: [0.167], y: [0.167] },
                          t: 84,
                          s: [5.6],
                          e: [3.6],
                        },
                        {
                          i: { x: [0.2], y: [1] },
                          o: { x: [0.167], y: [0] },
                          t: 110,
                          s: [3.6],
                          e: [3.6],
                        },
                        {
                          i: { x: [0.833], y: [0.833] },
                          o: { x: [0.167], y: [0] },
                          t: 126,
                          s: [3.6],
                          e: [5.6],
                        },
                        {
                          i: { x: [0.2], y: [1] },
                          o: { x: [0.167], y: [0.167] },
                          t: 147,
                          s: [5.6],
                          e: [3.6],
                        },
                        { t: 173 },
                      ],
                      ix: 5,
                    },
                    lc: 1,
                    lj: 1,
                    ml: 10,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9686274509803922, 0.9529411764705882,
                        0.9529411764705882, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [45.074, 45], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: -42.5, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-1.883, -1.88],
                          [0, 0],
                          [1.874, -1.878],
                          [0, 0],
                          [1.884, 1.881],
                          [0, 0],
                          [-1.874, 1.877],
                        ],
                        o: [
                          [1.885, -1.879],
                          [0, 0],
                          [1.877, 1.874],
                          [0, 0],
                          [-1.885, 1.878],
                          [0, 0],
                          [-1.877, -1.875],
                          [0, 0],
                        ],
                        v: [
                          [-15.301, -15.277],
                          [-8.483, -15.273],
                          [15.305, 8.476],
                          [15.31, 15.269],
                          [15.302, 15.277],
                          [8.483, 15.273],
                          [-15.305, -8.475],
                          [-15.31, -15.268],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9686274509803922, 0.9529411764705882,
                        0.9529411764705882, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [79.622, 81.906], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 34,
            op: 421,
            st: 0,
            bm: 0,
          },
        ],
      },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 3,
        nm: 'escalador papel',
        sr: 1,
        ks: {
          o: { a: 0, k: 0, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [321, 161.5, 0], ix: 2 },
          a: { a: 0, k: [60, 60, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        ip: 0,
        op: 421,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 3,
        nm: 'lupa Outlines 3',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                i: { x: [0.3], y: [1] },
                o: { x: [0.3], y: [0] },
                t: 34,
                s: [64],
                e: [23],
              },
              {
                i: { x: [0.437], y: [0.995] },
                o: { x: [0.7], y: [0] },
                t: 92,
                s: [23],
                e: [-21],
              },
              {
                i: { x: [0.358], y: [1] },
                o: { x: [0.544], y: [0] },
                t: 133,
                s: [-21],
                e: [16],
              },
              {
                i: { x: [0.326], y: [0.985] },
                o: { x: [0.595], y: [0] },
                t: 183,
                s: [16],
                e: [0],
              },
              { t: 220 },
            ],
            ix: 10,
          },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.222, y: 1 },
                o: { x: 0, y: 0 },
                t: 34,
                s: [240.252, 377.045, 0],
                e: [323.752, 265.045, 0],
                to: [13.917, -18.667, 0],
                ti: [-5.248, 0.545, 0],
              },
              {
                i: { x: 0.453, y: 0.528 },
                o: { x: 0.785, y: 0 },
                t: 88,
                s: [323.752, 265.045, 0],
                e: [334.252, 403.045, 0],
                to: [5.248, -0.545, 0],
                ti: [-24.248, -17.455, 0],
              },
              {
                i: { x: 0.156, y: 1 },
                o: { x: 0.423, y: 0.345 },
                t: 133,
                s: [334.252, 403.045, 0],
                e: [499.252, 415.045, 0],
                to: [24.248, 17.455, 0],
                ti: [-27.5, -2, 0],
              },
              { t: 183.03515625 },
            ],
            ix: 2,
          },
          a: { a: 0, k: [47.206, 48.054, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.2, 0.2, 0.2], y: [1, 1, 1] },
                o: { x: [0.15, 0.15, 0.15], y: [0, 0, 0] },
                t: 34,
                s: [0, 0, 100],
                e: [240, 240, 100],
              },
              {
                i: { x: [0.53, 0.53, 0.53], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                t: 60,
                s: [240, 240, 100],
                e: [240, 240, 100],
              },
              {
                i: { x: [0.16, 0.16, 0.16], y: [1, 1, 1] },
                o: { x: [0.42, 0.42, 0.42], y: [0, 0, 0] },
                t: 133,
                s: [240, 240, 100],
                e: [400, 400, 100],
              },
              { t: 183 },
            ],
            ix: 6,
          },
        },
        ao: 0,
        ip: 34,
        op: 12647,
        st: 18,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'LUPA rotacion 3D',
        parent: 2,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [26.162, 29.375, 0], ix: 2 },
          a: { a: 0, k: [48.528, 49.656, 0], ix: 1 },
          s: { a: 0, k: [50, 50, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.25, y: 1 },
                      o: { x: 0.167, y: 0.167 },
                      t: 202,
                      s: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.438, -0.004],
                            [0.027, 3.406],
                            [0.01, 3.421],
                            [0.056, 3.461],
                            [-3.375, 0.031],
                            [-3.421, -0.01],
                            [-3.397, -0.021],
                            [0.014, -3.432],
                            [-0.01, -3.421],
                            [0.024, -3.444],
                            [3.454, -0.013],
                            [3.421, 0.011],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [12.204, 8.794],
                            [8.792, 12.204],
                            [0.01, 3.422],
                            [-8.706, 12.137],
                            [-12.137, 8.707],
                            [-3.421, -0.01],
                            [-12.204, -8.793],
                            [-8.792, -12.204],
                            [-0.01, -3.421],
                            [8.706, -12.137],
                            [12.137, -8.706],
                            [3.421, 0.011],
                          ],
                          c: true,
                        },
                      ],
                    },
                    { t: 232 },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.4549019607843137, 0.4549019607843137, 0.4549019607843137,
                    1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [46.174, 44.297], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0.167] },
                      t: 202,
                      s: [0],
                      e: [100],
                    },
                    { t: 205 },
                  ],
                  ix: 7,
                },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.2, y: 1 },
                      o: { x: 0.167, y: 0.167 },
                      t: 35,
                      s: [
                        {
                          i: [
                            [-2.46, 0.083],
                            [-0.259, 19.058],
                            [1.377, 0.116],
                            [0, -19.883],
                          ],
                          o: [
                            [3.106, -0.105],
                            [0.271, -19.881],
                            [-1.276, -0.107],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [2.204, 2.371],
                            [0.001, -36],
                            [-3.43, 1.971],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [-19.923, 0],
                            [0, 19.882],
                            [19.923, 0],
                            [0, -19.883],
                          ],
                          o: [
                            [19.923, 0],
                            [0, -19.883],
                            [-19.923, 0],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [36.074, 0],
                            [0.001, -36],
                            [-36.074, 0],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.765, y: 0.384 },
                      o: { x: 0.8, y: 0 },
                      t: 60,
                      s: [
                        {
                          i: [
                            [-19.923, 0],
                            [0, 19.882],
                            [19.923, 0],
                            [0, -19.883],
                          ],
                          o: [
                            [19.923, 0],
                            [0, -19.883],
                            [-19.923, 0],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [36.074, 0],
                            [0.001, -36],
                            [-36.074, 0],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [-2.46, 0.083],
                            [-0.259, 19.058],
                            [1.377, 0.116],
                            [0, -19.883],
                          ],
                          o: [
                            [3.106, -0.105],
                            [0.271, -19.881],
                            [-1.276, -0.107],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [2.204, 2.371],
                            [0.001, -36],
                            [-3.43, 1.971],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.2, y: 1 },
                      o: { x: 0.21, y: 0.549 },
                      t: 84,
                      s: [
                        {
                          i: [
                            [-2.46, 0.083],
                            [-0.259, 19.058],
                            [1.377, 0.116],
                            [0, -19.883],
                          ],
                          o: [
                            [3.106, -0.105],
                            [0.271, -19.881],
                            [-1.276, -0.107],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [2.204, 2.371],
                            [0.001, -36],
                            [-3.43, 1.971],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [-19.923, 0],
                            [0, 19.882],
                            [19.923, 0],
                            [0, -19.883],
                          ],
                          o: [
                            [19.923, 0],
                            [0, -19.883],
                            [-19.923, 0],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [36.074, 0],
                            [0.001, -36],
                            [-36.074, 0],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.2, y: 1 },
                      o: { x: 0.167, y: 0 },
                      t: 110,
                      s: [
                        {
                          i: [
                            [-19.923, 0],
                            [0, 19.882],
                            [19.923, 0],
                            [0, -19.883],
                          ],
                          o: [
                            [19.923, 0],
                            [0, -19.883],
                            [-19.923, 0],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [36.074, 0],
                            [0.001, -36],
                            [-36.074, 0],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [-19.923, 0],
                            [0, 19.882],
                            [19.923, 0],
                            [0, -19.883],
                          ],
                          o: [
                            [19.923, 0],
                            [0, -19.883],
                            [-19.923, 0],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [36.074, 0],
                            [0.001, -36],
                            [-36.074, 0],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.765, y: 0.384 },
                      o: { x: 0.167, y: 0 },
                      t: 126,
                      s: [
                        {
                          i: [
                            [-19.923, 0],
                            [0, 19.882],
                            [19.923, 0],
                            [0, -19.883],
                          ],
                          o: [
                            [19.923, 0],
                            [0, -19.883],
                            [-19.923, 0],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [36.074, 0],
                            [0.001, -36],
                            [-36.074, 0],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [-2.46, 0.083],
                            [-0.259, 19.058],
                            [1.377, 0.116],
                            [0, -19.883],
                          ],
                          o: [
                            [3.106, -0.105],
                            [0.271, -19.881],
                            [-1.276, -0.107],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [2.204, 2.371],
                            [0.001, -36],
                            [-3.43, 1.971],
                          ],
                          c: true,
                        },
                      ],
                    },
                    {
                      i: { x: 0.2, y: 1 },
                      o: { x: 0.21, y: 0.549 },
                      t: 147,
                      s: [
                        {
                          i: [
                            [-2.46, 0.083],
                            [-0.259, 19.058],
                            [1.377, 0.116],
                            [0, -19.883],
                          ],
                          o: [
                            [3.106, -0.105],
                            [0.271, -19.881],
                            [-1.276, -0.107],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [2.204, 2.371],
                            [0.001, -36],
                            [-3.43, 1.971],
                          ],
                          c: true,
                        },
                      ],
                      e: [
                        {
                          i: [
                            [-19.923, 0],
                            [0, 19.882],
                            [19.923, 0],
                            [0, -19.883],
                          ],
                          o: [
                            [19.923, 0],
                            [0, -19.883],
                            [-19.923, 0],
                            [0, 19.882],
                          ],
                          v: [
                            [0.001, 36],
                            [36.074, 0],
                            [0.001, -36],
                            [-36.074, 0],
                          ],
                          c: true,
                        },
                      ],
                    },
                    { t: 173 },
                  ],
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [
                    0.4549019607843137, 0.4549019607843137, 0.4549019607843137,
                    1,
                  ],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.2], y: [1] },
                      o: { x: [0.167], y: [0.167] },
                      t: 35,
                      s: [5.6],
                      e: [3.6],
                    },
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.8], y: [0] },
                      t: 60,
                      s: [3.6],
                      e: [5.6],
                    },
                    {
                      i: { x: [0.2], y: [1] },
                      o: { x: [0.167], y: [0.167] },
                      t: 84,
                      s: [5.6],
                      e: [3.6],
                    },
                    {
                      i: { x: [0.2], y: [1] },
                      o: { x: [0.167], y: [0] },
                      t: 110,
                      s: [3.6],
                      e: [3.6],
                    },
                    {
                      i: { x: [0.833], y: [0.833] },
                      o: { x: [0.167], y: [0] },
                      t: 126,
                      s: [3.6],
                      e: [5.6],
                    },
                    {
                      i: { x: [0.2], y: [1] },
                      o: { x: [0.167], y: [0.167] },
                      t: 147,
                      s: [5.6],
                      e: [3.6],
                    },
                    { t: 173 },
                  ],
                  ix: 5,
                },
                lc: 1,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [45.074, 45], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: -42.5, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 2',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-1.883, -1.88],
                      [0, 0],
                      [1.874, -1.878],
                      [0, 0],
                      [1.884, 1.881],
                      [0, 0],
                      [-1.874, 1.877],
                    ],
                    o: [
                      [1.885, -1.879],
                      [0, 0],
                      [1.877, 1.874],
                      [0, 0],
                      [-1.885, 1.878],
                      [0, 0],
                      [-1.877, -1.875],
                      [0, 0],
                    ],
                    v: [
                      [-15.301, -15.277],
                      [-8.483, -15.273],
                      [15.305, 8.476],
                      [15.31, 15.269],
                      [15.302, 15.277],
                      [8.483, 15.273],
                      [-15.305, -8.475],
                      [-15.31, -15.268],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.4549019607843137, 0.4549019607843137, 0.4549019607843137,
                    1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [79.622, 81.906], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 3',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 3,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 34,
        op: 421,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'line 1 Outlines',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [299.82, 224.817, 0], ix: 2 },
          a: { a: 0, k: [36.006, 1.8, 0], ix: 1 },
          s: { a: 0, k: [200, 200, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.8, 1.8],
                      [70.211, 1.8],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [
                    0.8431372549019608, 0.8431372549019608, 0.8431372549019608,
                    1,
                  ],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3.6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: { a: 0, k: 0, ix: 1 },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.2], y: [1] },
                  o: { x: [0.15], y: [0] },
                  t: 17,
                  s: [0],
                  e: [100],
                },
                { t: 39 },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 17,
        op: 421,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: 'line 2 Outlines',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [292.734, 278.817, 0], ix: 2 },
          a: { a: 0, k: [32.463, 1.8, 0], ix: 1 },
          s: { a: 0, k: [200, 200, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.8, 1.8],
                      [63.125, 1.8],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [
                    0.8431372549019608, 0.8431372549019608, 0.8431372549019608,
                    1,
                  ],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3.6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: { a: 0, k: 0, ix: 1 },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.2], y: [1] },
                  o: { x: [0.15], y: [0] },
                  t: 24,
                  s: [0],
                  e: [100],
                },
                { t: 46 },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 24,
        op: 428,
        st: 7,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 4,
        nm: 'line 3 Outlines',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [258.464, 329.218, 0], ix: 2 },
          a: { a: 0, k: [15.327, 1.8, 0], ix: 1 },
          s: { a: 0, k: [200, 200, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.8, 1.8],
                      [28.855, 1.8],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [
                    0.9294117647058824, 0.8784313725490196, 0.8784313725490196,
                    1,
                  ],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3.6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: { a: 0, k: 0, ix: 1 },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.2], y: [1] },
                  o: { x: [0.15], y: [0] },
                  t: 31,
                  s: [0],
                  e: [100],
                },
                { t: 53 },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 31,
        op: 435,
        st: 14,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 4,
        nm: 'line 4 Outlines',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [258.464, 379.618, 0], ix: 2 },
          a: { a: 0, k: [15.327, 1.8, 0], ix: 1 },
          s: { a: 0, k: [200, 200, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.8, 1.8],
                      [28.855, 1.8],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [
                    0.9294117647058824, 0.8784313725490196, 0.8784313725490196,
                    1,
                  ],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3.6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: { a: 0, k: 0, ix: 1 },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.2], y: [1] },
                  o: { x: [0.15], y: [0] },
                  t: 38,
                  s: [0],
                  e: [100],
                },
                { t: 60 },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 38,
        op: 442,
        st: 21,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 8,
        ty: 0,
        nm: 'Sombra lupa',
        refId: 'comp_0',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [321, 321, 0], ix: 2 },
          a: { a: 0, k: [321, 321, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        hasMask: true,
        masksProperties: [
          {
            inv: false,
            mode: 'a',
            pt: {
              a: 0,
              k: {
                i: [
                  [-7.5, 3.5],
                  [0, 0],
                  [0, 0],
                  [0, 0],
                  [0, 0],
                  [-4, 0],
                  [0, -2.5],
                  [-29.5, 0],
                  [0, 15],
                  [0, 5],
                ],
                o: [
                  [-13, 0],
                  [0, 0],
                  [0, 0],
                  [0, 0],
                  [0, 0],
                  [4, 0],
                  [0, 2.5],
                  [29.5, 0],
                  [0, -15],
                  [0, -5],
                ],
                v: [
                  [412.5, 169.5],
                  [231.5, 169.5],
                  [202, 190.5],
                  [191, 254],
                  [191, 428.5],
                  [329, 428.5],
                  [333.5, 434.5],
                  [365.5, 478.5],
                  [399, 436.5],
                  [399, 193],
                ],
                c: true,
              },
              ix: 1,
            },
            o: { a: 0, k: 100, ix: 3 },
            x: { a: 0, k: 0, ix: 4 },
            nm: 'Mask 1',
          },
        ],
        w: 642,
        h: 642,
        ip: 0,
        op: 421,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 13,
        ty: 4,
        nm: 'papel bot Outlines',
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [-8.861, 353.102, 0], ix: 2 },
          a: { a: 0, k: [57.389, 18.529, 0], ix: 1 },
          s: { a: 0, k: [200, 200, 100], ix: 6 },
        },
        ao: 0,
        hasMask: true,
        masksProperties: [
          {
            inv: false,
            mode: 'a',
            pt: {
              a: 1,
              k: [
                {
                  i: { x: 0.35, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 25,
                  s: [
                    {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [114.817, 36.647],
                        [1.381, 36.647],
                        [1.32, 36.478],
                        [114.756, 36.478],
                      ],
                      c: true,
                    },
                  ],
                  e: [
                    {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [114.756, 5.022],
                        [1.32, 5.022],
                        [1.32, 36.478],
                        [114.756, 36.478],
                      ],
                      c: true,
                    },
                  ],
                },
                { t: 45 },
              ],
              ix: 1,
            },
            o: { a: 0, k: 100, ix: 3 },
            x: { a: 0, k: 0, ix: 4 },
            nm: 'Mask 1',
          },
        ],
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0.013, -9.145],
                      [0, 0],
                      [0, 0],
                      [7.994, -2.105],
                      [1.814, 0.473],
                      [-0.022, 8.366],
                      [0, 0],
                      [1.528, 0.009],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -1.541],
                      [0, 0],
                      [-13.061, -0.011],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.609, 0.093],
                      [-1.049, 0.261],
                      [-0.013, 11.099],
                      [0, 0],
                      [0, 0],
                      [-5.938, 0.542],
                      [-0.345, -0.019],
                      [-0.011, -6.47],
                      [0, 0],
                      [0, 0],
                      [0, 1.541],
                      [0, 0],
                      [9.485, 0.042],
                    ],
                    o: [
                      [0, 0],
                      [-9.048, 0.552],
                      [0, 0],
                      [0, 0],
                      [-0.017, 8.335],
                      [-1.815, 0.473],
                      [-8.019, -2.135],
                      [0, 0],
                      [0.011, -1.541],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.527, 0],
                      [0, 0],
                      [-0.012, 13.175],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.609, 0],
                      [1.076, -0.099],
                      [10.716, -2.516],
                      [0, 0],
                      [0, 0],
                      [0.079, -6.014],
                      [0.344, -0.019],
                      [6.414, 0.01],
                      [0, 0],
                      [0, 0],
                      [1.528, 0],
                      [0, 0],
                      [0.04, -9.569],
                      [0, 0],
                    ],
                    v: [
                      [89.342, -145.931],
                      [88.309, -145.931],
                      [72.188, -128.68],
                      [72.188, -128.419],
                      [72.188, -6.1],
                      [58.594, 11.617],
                      [53.059, 11.617],
                      [39.481, -6.211],
                      [39.481, -9.933],
                      [36.734, -12.743],
                      [19.195, -12.743],
                      [5.094, -12.743],
                      [-16.645, -12.743],
                      [-39.406, -12.743],
                      [-53.01, -12.743],
                      [-55.777, -9.951],
                      [-55.777, -6.006],
                      [-32.146, 17.869],
                      [-32.111, 17.869],
                      [-2.276, 17.869],
                      [54.109, 17.869],
                      [56.268, 17.776],
                      [59.46, 17.237],
                      [77.777, -6.006],
                      [77.777, -128.586],
                      [77.777, -128.717],
                      [88.365, -140.254],
                      [89.398, -140.254],
                      [101.02, -128.531],
                      [101.02, -115.262],
                      [103.787, -115.262],
                      [106.555, -118.053],
                      [106.555, -128.531],
                      [89.454, -145.931],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.8431372549019608, 0.8431372549019608, 0.8431372549019608,
                    1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [58.74, 18.119], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 25,
        op: 421,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 14,
        ty: 4,
        nm: 'Papel front Outlines',
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [42.09, 219.148, 0], ix: 2 },
          a: { a: 0, k: [74.219, 89.605, 0], ix: 1 },
          s: { a: 0, k: [200, 200, 100], ix: 6 },
        },
        ao: 0,
        hasMask: true,
        masksProperties: [
          {
            inv: false,
            mode: 'a',
            pt: {
              a: 1,
              k: [
                {
                  i: { x: 0.99, y: 1 },
                  o: { x: 0.28, y: 0 },
                  t: 10,
                  s: [
                    {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [152.276, 7.781],
                        [-1.326, 7.781],
                        [-1.326, 9.031],
                        [152.276, 9.031],
                      ],
                      c: true,
                    },
                  ],
                  e: [
                    {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [152.276, 7.781],
                        [-1.326, 7.781],
                        [-1.326, 175.031],
                        [152.276, 175.031],
                      ],
                      c: true,
                    },
                  ],
                },
                { t: 25 },
              ],
              ix: 1,
            },
            o: { a: 0, k: 100, ix: 3 },
            x: { a: 0, k: 0, ix: 4 },
            nm: 'Mask 1',
          },
        ],
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0.012, -9.145],
                      [0, 0],
                      [0, 0],
                      [7.994, -2.106],
                      [0.894, -0.009],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.953, -0.005],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.61, 0.093],
                      [-1.049, 0.26],
                      [-0.014, 11.099],
                      [0, 0],
                      [0, 0],
                      [-5.937, 0.544],
                      [-0.326, -0.016],
                    ],
                    o: [
                      [0, 0],
                      [-9.048, 0.551],
                      [0, 0],
                      [0, 0],
                      [-0.017, 8.335],
                      [-0.874, 0.227],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.154, 3.667],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.608, 0],
                      [1.075, -0.099],
                      [10.716, -2.517],
                      [0, 0],
                      [0, 0],
                      [0.079, -6.015],
                      [0.328, -0.018],
                      [0, 0],
                    ],
                    v: [
                      [68.364, -81.9],
                      [67.331, -81.9],
                      [51.208, -64.649],
                      [51.208, -64.388],
                      [51.208, 57.931],
                      [37.614, 75.648],
                      [34.951, 75.996],
                      [34.951, 75.999],
                      [34.882, 75.999],
                      [34.811, 75.999],
                      [-68.667, 75.999],
                      [-53.126, 81.9],
                      [-53.089, 81.9],
                      [-23.254, 81.9],
                      [33.132, 81.9],
                      [35.29, 81.807],
                      [38.48, 81.268],
                      [56.799, 58.024],
                      [56.799, -64.556],
                      [56.799, -64.686],
                      [67.385, -76.224],
                      [68.667, -76.224],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.8431372549019608, 0.8431372549019608, 0.8431372549019608,
                    1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [71.074, 92.141], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-57.614, -15.262],
                      [-57.614, -11.587],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 3.997],
                      [0, 8.289],
                    ],
                    o: [
                      [5.953, 0],
                      [0, -32.687],
                      [0, 0],
                    ],
                    v: [
                      [-65.219, 79.059],
                      [-58.189, 68.737],
                      [-58.135, 4.655],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 2,
                ty: 'sh',
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-13.063, 0.021],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.021, -13.171],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-58.135, -24.607],
                      [-58.135, -55.195],
                      [-34.467, -79.059],
                      [65.219, -79.059],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 3',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'mm',
                mm: 1,
                nm: 'Merge Paths 1',
                mn: 'ADBE Vector Filter - Merge',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [
                    0.8431372549019608, 0.8431372549019608, 0.8431372549019608,
                    1,
                  ],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 3.6, ix: 5 },
                lc: 2,
                lj: 1,
                ml: 10,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [74.219, 91.151], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 2',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0.02, -13.17],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-13.063, 0.021],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-6.133, -52.006],
                      [-29.801, -28.142],
                      [-29.801, 52.08],
                      [29.801, -52.08],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.8901960784313725, 0.8901960784313725, 0.8901960784313725,
                    1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [45.83, 62.33], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 3',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 3,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.02, -13.17],
                      [0, 0],
                      [-1.527, 0],
                      [0, 0],
                      [-0.009, 13.153],
                      [0, 0],
                      [0, 0],
                      [-6.363, 0.009],
                      [-0.025, 0.001],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-13.062, 0.02],
                      [0, 0],
                      [0, 1.54],
                      [0, 0],
                      [13.045, -0.041],
                      [0, 0],
                      [0, 0],
                      [0.081, -6.417],
                      [0.026, 0],
                      [0, 0],
                    ],
                    v: [
                      [61.639, -76.59],
                      [61.639, -81.84],
                      [-38.048, -81.84],
                      [-61.714, -57.977],
                      [-61.714, 79.05],
                      [-58.947, 81.84],
                      [26.406, 81.84],
                      [50.018, 57.977],
                      [50.018, -64.542],
                      [50.018, -64.673],
                      [61.639, -76.581],
                      [61.714, -76.59],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [77.799, 92.164], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 4',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 4,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 10,
        op: 421,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 15,
        ty: 4,
        nm: 'Papel top Outlines',
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [89.026, 94.609, 0], ix: 2 },
          a: { a: 0, k: [69.157, 27.261, 0], ix: 1 },
          s: { a: 0, k: [200, 200, 100], ix: 6 },
        },
        ao: 0,
        hasMask: true,
        masksProperties: [
          {
            inv: false,
            mode: 'a',
            pt: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.4, y: 0 },
                  t: 0,
                  s: [
                    {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [133.251, 43.755],
                        [8.394, 43.755],
                        [8.394, 45.207],
                        [133.251, 45.207],
                      ],
                      c: true,
                    },
                  ],
                  e: [
                    {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [133.324, 9.505],
                        [8.466, 9.505],
                        [8.394, 45.207],
                        [133.251, 45.207],
                      ],
                      c: true,
                    },
                  ],
                },
                { t: 10 },
              ],
              ix: 1,
            },
            o: { a: 0, k: 100, ix: 3 },
            x: { a: 0, k: 0, ix: 4 },
            nm: 'Mask 1',
          },
        ],
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -1.544],
                      [-1.545, 0],
                      [-0.018, -0.001],
                      [-0.01, -6.471],
                      [0, 0],
                      [0, 0],
                      [0, 1.541],
                      [0, 0],
                      [9.485, 0.041],
                      [0, 0],
                      [0, 0],
                      [0.251, -0.027],
                      [0, 0],
                    ],
                    o: [
                      [0, 1.544],
                      [97.877, 0],
                      [6.414, 0.011],
                      [0, 0],
                      [0, 0],
                      [1.527, 0],
                      [0, 0],
                      [0.041, -9.569],
                      [0, 0],
                      [0, 0],
                      [-0.254, 0.015],
                      [0, 0],
                      [-1.544, 0],
                    ],
                    v: [
                      [-58.955, -12.455],
                      [-56.155, -9.658],
                      [41.758, -9.658],
                      [53.379, 2.066],
                      [53.379, 15.334],
                      [56.147, 15.334],
                      [58.914, 12.543],
                      [58.914, 2.066],
                      [41.814, -15.334],
                      [41.703, -15.334],
                      [40.67, -15.334],
                      [39.918, -15.251],
                      [-56.159, -15.251],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.8431372549019608, 0.8431372549019608, 0.8431372549019608,
                    1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [69.205, 25.502], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.67, 0],
                      [0, -9.44],
                      [0, 0],
                      [-1.527, 0],
                      [0, 0],
                      [0, 1.632],
                      [0, 0],
                      [9.475, 0],
                    ],
                    o: [
                      [16, 0],
                      [0, 0],
                      [0, 1.632],
                      [0, 0],
                      [1.528, 0],
                      [0, 0],
                      [0, -10.128],
                      [-0.642, 0],
                    ],
                    v: [
                      [-58.571, -15.544],
                      [-40.571, 3.353],
                      [-40.571, 13.652],
                      [-37.803, 16.61],
                      [56.472, 15.79],
                      [59.24, 12.833],
                      [59.24, 1.73],
                      [42.083, -16.61],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.8431372549019608, 0.8431372549019608, 0.8431372549019608,
                    1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [68.824, 26.86], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 2',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 2,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 421,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 16,
        ty: 4,
        nm: 'circulito Outlines',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [516.83, 232.086, 0], ix: 2 },
          a: { a: 0, k: [7.194, 7.198, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.434, 0.434, 0.67], y: [1, 1, 1] },
                o: { x: [0.233, 0.233, 0.33], y: [0, 0, 0] },
                t: 232,
                s: [0, 0, 100],
                e: [240, 240, 100],
              },
              {
                i: { x: [0.196, 0.196, 0.67], y: [1, 1, 1] },
                o: { x: [0.172, 0.172, 0.33], y: [0, 0, 0] },
                t: 252,
                s: [240, 240, 100],
                e: [200, 200, 100],
              },
              { t: 284 },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.643, 0],
                      [0, 1.652],
                      [1.642, 0],
                      [0, -1.652],
                    ],
                    o: [
                      [1.642, 0],
                      [0, -1.652],
                      [-1.643, 0],
                      [0, 1.652],
                    ],
                    v: [
                      [0, 2.988],
                      [2.976, 0],
                      [0, -2.988],
                      [-2.977, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [3.837, 0],
                      [0, 3.836],
                      [-3.837, 0],
                      [0, -3.836],
                    ],
                    o: [
                      [-3.837, 0],
                      [0, -3.836],
                      [3.837, 0],
                      [0, 3.836],
                    ],
                    v: [
                      [0, 6.948],
                      [-6.944, 0],
                      [0, -6.948],
                      [6.944, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 2',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'mm',
                mm: 1,
                nm: 'Merge Paths 1',
                mn: 'ADBE Vector Filter - Merge',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.8156862745098039, 0.8117647058823529, 0.8117647058823529,
                    1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [7.194, 7.198], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 4,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 232,
        op: 566,
        st: 145,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 17,
        ty: 4,
        nm: 'x 2 Outlines',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [96.613, 416.097, 0], ix: 2 },
          a: { a: 0, k: [6.515, 6.515, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.434, 0.434, 0.67], y: [1, 1, 1] },
                o: { x: [0.233, 0.233, 0.33], y: [0, 0, 0] },
                t: 252,
                s: [0, 0, 100],
                e: [240, 240, 100],
              },
              {
                i: { x: [0.196, 0.196, 0.67], y: [1, 1, 1] },
                o: { x: [0.172, 0.172, 0.33], y: [0, 0, 0] },
                t: 272,
                s: [240, 240, 100],
                e: [200, 200, 100],
              },
              { t: 304 },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [6.258, 3.885],
                      [3.885, 6.258],
                      [0.001, 2.372],
                      [-3.891, 6.264],
                      [-6.264, 3.891],
                      [-2.374, -0.001],
                      [-6.258, -3.885],
                      [-3.885, -6.26],
                      [-0.001, -2.374],
                      [3.891, -6.264],
                      [6.264, -3.891],
                      [2.374, 0.001],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.8352941176470589, 0.8156862745098039, 0.8156862745098039,
                    1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [6.515, 6.515], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 252,
        op: 441,
        st: 20,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 18,
        ty: 4,
        nm: 'bg Outlines',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.5], y: [1] },
                o: { x: [0.44], y: [0] },
                t: 7,
                s: [0],
                e: [100],
              },
              { t: 49 },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [318.455, 325.042, 0], ix: 2 },
          a: { a: 0, k: [119.513, 102.602, 0], ix: 1 },
          s: { a: 0, k: [200, 200, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-34.357, -7.157],
                      [-2.441, 47.062],
                      [17.957, 18.605],
                      [34.584, 38.554],
                      [38.389, -25.44],
                      [-24.266, -40.899],
                    ],
                    o: [
                      [79.337, 8.029],
                      [3.66, -70.593],
                      [-17.958, -18.605],
                      [-23.057, -25.703],
                      [-30.965, 25.202],
                      [24.266, 40.898],
                    ],
                    v: [
                      [-7.063, 94.323],
                      [115.603, 35.774],
                      [39.645, -45.798],
                      [7.22, -76.517],
                      [-84.949, -76.912],
                      [-94.997, 22.24],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [0.898000021542, 0.898000021542, 0.898000021542, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [119.513, 102.602], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 421,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  };
}
