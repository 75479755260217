<div *ngIf="!comment" class="flex flex-col w-full text-zen">
    <div class="bg-white rounded border border-exp px-5 pt-1 overflow-hidden ">
        <mat-form-field class="w-full -mt-3 text-zen text-sm" appearance="none">
            <textarea rows="3" [attr.disabled]="countWord(question.answer, lgCount) > lgCount ? true : null"
                [maxlength]="lgCount" (keyup)="countWord(question.answer, lgCount)"
                [placeholder]="'txtSpecifyHere'| translate:language:ModuleNames.Shared" [(ngModel)]="question.answer" matInput></textarea>
        </mat-form-field>
    </div>
    <h4 *ngIf="question?.answer?.length >= lgCount -20"
        [ngClass]="{'text-zen-red': countWord(question.answer, lgCount)>= lgCount}" class="text-xxs ">
        {{countWord(question.answer, lgCount)}} {{'txtof'| translate:language:ModuleNames.Shared}} {{lgCount}}</h4>

</div>

<div *ngIf="comment" class="flex flex-col w-full text-zen">
    <div [ngClass]="countWord(question.comment, lgCount)> 0?'bg-white max-h-52':'bg-transparent max-h-12'" class="transition-all duration-300 ease-expo rounded border border-zen border-opacity-10 px-5 pt-1 overflow-hidden ">
        <mat-form-field class="w-full -mt-3 text-zen text-sm" appearance="none">
            <textarea rows="3" [attr.disabled]="countWord(question.comment, lgCount) > lgCount ? true : null"
                [maxlength]="lgCount" (keyup)="countWord(question.comment, lgCount)"
                [placeholder]="'txtTypeComment'| translate:language:ModuleNames.Shared" [(ngModel)]="question.comment" matInput></textarea>
        </mat-form-field>
    </div>
    <h4 *ngIf="question?.comment?.length >= lgCount - 20"
        [ngClass]="{'text-zen-red': countWord(question.comment, lgCount)>= lgCount}" class="text-xxs ">
        {{countWord(question.comment, lgCount)}} {{'txtof'| translate:language:ModuleNames.Shared}} {{lgCount}}</h4>

</div>