<div *ngIf="availableQuestions.length > 0 && model.selectedElement['dateType'] != 'range'">
<div class="section-header py-3 mt-8 mb-8 w-full" (click)="logData(showQuestionLogicConditions)">
    <label class="w-full px-10">{{'txtLogic'| translate:language:ModuleNames.SurveyX }}</label>
</div>

<div class="flex flex-col gap-4 px-10">
    <z-toggle class="w-full rounded bg-opacity-10" [disable]="false"
        [label]="'txtShow this question'|translate:language:ModuleNames.SurveyX" [(ngModel)]="showQuestionLogic"
        (ngModelChange)="showQuestionLogicChange.emit($event); addMainCondition()">
    </z-toggle>

    <div class="flex flex-col gap-4" *ngIf="showQuestionLogic">
        <div class="flex flex-col gap-4" *ngFor="let condition of showQuestionLogicConditions;let i = index">
            <div class="flex gap-3 items-center">

                <ng-container [ngSwitch]="condition.type">
                    <label class="text-zen-primarydark text-sm font-medium w-8 h-6 text-center"
                        *ngSwitchCase="'Main'">{{"txtIF" |translate:language:ModuleNames.SurveyX}}
                    </label>

                    <label class="text-zen-primarydark text-sm font-medium w-8 h-6 text-center"
                        *ngSwitchCase="'And'">{{"txtAND"|translate:language:ModuleNames.SurveyX}}
                    </label>

                    <label class="text-zen-primarydark text-sm font-medium w-8 h-6 text-center" *ngSwitchCase="'Or'">{{"txtOR"|translate:language:ModuleNames.SurveyX}}
                    </label>
                </ng-container>

                <z-select [color]="'exp'" class="w-full" [(ngModel)]="condition.question"
                    [options]="availableQuestions" [placeholder]="'Question' | translate:language:ModuleNames.SurveyX" [language]="language"
                    (selectionChange)="selectQuestion($event,i, condition)">
                    <ng-template #valueTemplate let-value>
                        {{value?.value.title }}
                    </ng-template>

                    <ng-template #optionTemplate let-option>
                        {{option?.value.title }}
                    </ng-template>
                </z-select>
                <!-- width: 1rem; -->

                <div *ngIf="condition.type != 'Main'">
                    <button class="w-8 h-8 rounded bg-[#ff45001a] text-zen-redSelect hover:bg-zen-redSelect hover:text-white"
                        (click)="removeCondition(i)">
                        <i class="fas fa-xmark text-base"></i>
                    </button>
                </div>
            </div>

            <div class="flex flex-col gap-4" *ngIf="condition.question">
                <div class="flex gap-3">
                    <z-select [color]="'exp'" class="placeholder:fixed w-full ms-10"
                        [(ngModel)]="condition.operation" [options]="condition.options" [language]="language"
                        (selectionChange)="selectOperation($event,i, condition)">
                        <ng-template #valueTemplate let-value>
                            {{value?.value | translate:language:ModuleNames.SurveyX }}
                        </ng-template>

                        <ng-template #optionTemplate let-option>
                            {{option?.value | translate:language:ModuleNames.SurveyX}}
                        </ng-template>
                    </z-select>
                </div>

                <div class="flex gap-3" *ngIf="showAnswerBox(i)">
                    <input type="text" class="form-input block w-full p-2.5 ms-10"
                        *ngIf="isTextAnswer(condition.question);else choiceAnswer" [(ngModel)]="condition.answer"
                        (ngModelChange)="selectAnswer($event,i,condition.question)">

                    <ng-template #choiceAnswer>
                        <z-select *ngIf="condition?.answerOptions?.length" [color]="'exp'" class="placeholder:fixed w-full ms-10"
                            [(ngModel)]="condition.answer" [options]="condition.answerOptions"
                            [language]="language" (selectionChange)="selectAnswer($event,i,condition.question)">
                            <ng-template #valueTemplate let-value>
                                {{value?.value }}
                            </ng-template>

                            <ng-template #optionTemplate let-option>
                                {{option?.value }}
                            </ng-template>
                        </z-select>
                    </ng-template>
                </div>
            </div>
        </div>

        <!-- AND/OR -->
        <div class="flex justify-items-start gap-4">
            <button class="rounded-md px-1 text-exp text-sm" (click)="addAndCondition()">
                <i class="fas fa-plus text-sm text-exp w-6 h-6"></i>
                {{"txtAND"| translate:language:ModuleNames.SurveyX}}
            </button>

            <button class="rounded-md px-1 text-exp text-sm" (click)="addOrCondition()">
                <i class="fas fa-plus text-sm text-exp w-6 h-6"></i>
                {{"txtOR"| translate:language:ModuleNames.SurveyX}}
            </button>
        </div>
        <!-- AND/OR -->
    </div>
</div>
</div>
