<div class="flex flex-row bg-transparent items-center rounded-md h-14 self-center relative w-full">
    <div class="flex flex-row text-zen text-xs whitespace-nowrap">
        {{ 'txtPage' | translate:language:ModuleNames.Shared}} {{ (pageIndex + 1) }} {{ 'txtof' |
        translate:language:ModuleNames.Shared
        }} {{ totalPages.length }}
    </div>
    <div class=" h-14 flex flex-row bg-transparent justify-center w-full pag-buttons">
        <div (click)="goToChange(0)" class="h-full flex flex-col  justify-center cursor-pointer" *ngIf="pageIndex > 2">
            <a class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
                    [ngClass]="!(language | language )?'fa-angle-double-left ':'fa-angle-double-right'"
                    class="fal text-zen  text-xl  leading-10 "></i></a>
        </div>
        <div (click)="goToChange(pageIndex - 1)" class="h-full flex flex-col  justify-center cursor-pointer"
            *ngIf="pageIndex > 1">
            <a class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
                    [ngClass]="!(language | language )?'fa-angle-left':'fa-angle-right'"
                    class="fal  text-zen  text-xl  leading-10 "></i></a>
        </div>
        <div class="h-full flex flex-col  justify-center" *ngFor="let pageNumber of totalPages; let i = index">

            <a *ngIf="i == pageIndex ||i+1 == pageIndex ||i-1 == pageIndex  " (click)=" goToChange(i)"
                [ngClass]="i == pageIndex? options?.colors?.button:'cancel'"
                class="btn  leading-10 w-10 text-center mx-1 rounded-md cursor-pointer text-white">{{pageNumber}}
            </a>

        </div>
        <div (click)="goToChange(pageIndex + 1)" class="h-full flex flex-col  justify-center cursor-pointer"
            *ngIf="pageIndex < totalPages.length-2 ">
            <a class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
                    [ngClass]="!(language | language )?'fa-angle-right':'fa-angle-left'"
                    class="fal  text-zen  text-xl  leading-10 "></i></a>
        </div>
        <div (click)="goToChange( totalPages.length - 1)" class="h-full flex flex-col  justify-center cursor-pointer"
            *ngIf="pageIndex < totalPages.length -3">
            <a class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
                    [ngClass]="!(language | language )?'fa-angle-double-right':'fa-angle-double-left'"
                    class="fal  text-zen  text-xl  leading-10 "></i></a>
        </div>
    </div>
    <h5 class=" text-zen text-xs whitespace-nowrap" *ngIf="totalRecords">{{'txtTotal' |
        translate:language:ModuleNames.Shared}}: {{totalRecords}}</h5>
</div>