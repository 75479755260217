import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { LoaderService } from '@ex/module/core/services/loader.service';
import { LocalService } from '@ex/module/core/services/local.service';
import { ZenSnackbarService, ZenSnackBarTypes } from '@ex/module/shared/Theme/services/zen-snackbar.service';
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { CustomDatepickerComponent } from '@ex/module/survey-x/ui/custom-datepicker/custom-datepicker.component';
import { FormService } from '@ex/survey-x/index';
import { environment } from 'src/environments/environment';
import { SurveyCreatorModel } from "survey-creator-core";

// Options for the survey creator
const creatorOptions = {
  showLogicTab: true,
  showTranslationTab: true,
  isAutoSave: true
};

@Component({
  selector: 'app-languages-page',
  templateUrl: './languages-page.component.html',
  styleUrls: ['./languages-page.component.scss']
})
export class LanguagesPageComponent extends BaseComponent implements OnInit {
  static declaration = [CustomDatepickerComponent];

  showAssignWeights = false;
  updatedCreator: any;
  surveyCreatorModel!: SurveyCreatorModel;
  surveyId: string = "";
  survey: any = {};

  form: any;
  endPoint = environment.endPoint;

  language: string = 'EN';
  direction: Direction = 'ltr';
  currentLocale: any;

  tenant: any;
  tenantName: any;
  tenantKey: any;
  currentUser: any;
  currentUserName: any;
  companyName: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private surveysService: FormService,
    private localService: LocalService,
    public loader: LoaderService,
    private zenSnackBarService: ZenSnackbarService
  ) { super() }

  async ngOnInit() {
    this.loader.displayLoader();

    this.language = this.localService.getJsonValue('language')

    if (this.language == "AR")
      this.direction = "rtl"
    else
      this.direction = "ltr"

    // Get tenant information from local storage
    this.tenant = JSON.parse(localStorage.getItem("tenant")!);
    this.tenantName = this.tenant.name;
    this.tenantKey = this.tenant.key;
    // Get current user information from local storage
    this.currentUser = JSON.parse(localStorage.getItem("user")!);
    this.currentUserName = `${this.currentUser.firstName} ${this.currentUser.lastName}`;

    // Get survey ID from route parameters
    this.surveyId = this.route.snapshot.paramMap.get('id');

    // Get survey object from the server
    this.form = await this.surveysService.getFormWithoutResponses(this.surveyId).toPromise();
    this.survey = this.form.query;

    this.companyName = this.survey.companyName ? JSON.parse(this.survey.companyName) : this.survey.tenantName;
    // Show/hide the assign weights section based on the survey type
    // this.showAssignWeights = this.survey.typeName !== "Survey";

    // Create a new survey model
    const creator = new SurveyCreatorModel(creatorOptions);
    creator.text = this.survey.jsonText;

    // this.language = creator.survey.locale.toUpperCase();

    // if (creator.survey.locale == "ar")
    //   this.direction = "rtl"
    // else
    //   this.direction = "ltr"

    // Set the survey creator save function
    creator.saveSurveyFunc = (saveNo: number, callback: Function) => {

      callback(saveNo, true);
      this.survey.jsonText = creator.text;
      this.survey.title = JSON.stringify(creator.survey.locTitle['values']);
      this.survey.description = JSON.stringify(creator.survey.locDescription['values']);
      const jsonObject = JSON.parse(this.survey.jsonText);
      const arrData = Object.keys(jsonObject).map((key) => ({ key, value: jsonObject[key] }));
      this.survey.jsonTextPair = JSON.stringify(arrData);
      this.survey.modifiedBy = this.currentUserName;

      const sub = this.surveysService.updateForm(this.survey.id, this.survey).subscribe(r => {
        callback(saveNo);
      });

      this.addSubscription(sub);

      // saveSurveyJson(
      //   `${this.endPoint}form-table/update-form/${this.survey.id}`,
      //   this.survey,
      //   saveNo,
      //   callback
      // );
    };

    // Set the survey creator model and active tab
    this.surveyCreatorModel = creator;
    this.surveyCreatorModel.activeTab = "translation";

    this.surveyCreatorModel.onActiveTabChanged.add((sender, options) => {
      if (options.tabName === "translation") {
        const trans = options.model;
        trans.showAllStrings = true;
      }
    });
    this.loader.hideLoader();
  }

  // Navigate to the next step
  nextStep() {
    this.router.navigateByUrl(`/survey-x/form-builder/analytical-criteria/${this.surveyId}`);
  }

  // Navigate to the previous step
  previousStep() {
    const url = this.showAssignWeights
      ? `/survey-x/form-builder/assign-weights/${this.surveyId}`
      : `/survey-x/form-builder/questions-builder/${this.surveyId}`;

    this.router.navigateByUrl(url);
  }

  pageChanged(event: any) {
    this.router.navigateByUrl(`/survey-x/form-builder/${event}/${this.surveyId}`);
  }



  showSnackBarMessage(message: string, type: ZenSnackBarTypes) {
    this.zenSnackBarService.snackBar({
      message: message,
      type: ZenSnackBarTypes.Error
    });
  }
}

// Function to save the survey JSON to the server
function saveSurveyJson(url: string | URL, json: object, saveNo: number, callback: Function) {
  const request = new XMLHttpRequest();
  request.open('PUT', url);
  request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  request.addEventListener('load', () => {
    callback(saveNo, true);
  });
  request.addEventListener('error', () => {
    callback(saveNo, false);
  });
  request.send(JSON.stringify(json));
}
