import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})


export class IndustriesService
{
    URL = environment.endPoint;
    httpOptions={headers: new HttpHeaders({
        'Access-Control-Allow-Origin': 'https://assessments.zenithr.net',
        'Content-Type': 'application/json;'
      })}

      constructor(private httpClient: HttpClient) { }

      getAllIndustries(){
        return this.httpClient.get(this.URL+'industry/get-all-industries');
      }


}
