import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'na'
})
export class NaPipe implements PipeTransform {

  transform(value: any, symbol: string = "-"): string {
    if (!value)
      return symbol;

    return value;
  }

}
