import { Directive, EventEmitter, HostListener, Output, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[CloseCdk]'
})
export class ZenCdkDetachDirective {
  @HostListener('click', ['$event']) onClick($event) {
    this.close.emit(true);
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  constructor(private viewContainerRef: ViewContainerRef) {
  }

}
