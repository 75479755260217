import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThomasRequest } from '../models/thomas/thomas-request';
import { PsychometricConfig } from '../PsychometricConfig';

@Injectable({
  providedIn: 'root',
})
export class ThomasService {
  thomasAuthorizationURL: string = PsychometricConfig.thomasAuthorizationAPI;
  thomasAssessmentOrderUrl: string = PsychometricConfig.thomasAssessmentOrderAPI;
  constructor(private http: HttpClient) { }

  thomasAssessmentOrder(
    clientId: string,
    token: string,
    request: ThomasRequest
  ) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.post(
      this.thomasAssessmentOrderUrl.replace('%CLIENTID%', clientId),
      request,
      { headers: headers }
    );
  }

  authorize(clientID: string, clientSecret: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + btoa(clientID + ':' + clientSecret),
      }),
    };
    let body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    return this.http.post<any>(
      this.thomasAuthorizationURL,
      body.toString(),
      httpOptions
    );
  }
  // Because policy issue
  createNewAssessment(token: string, clientID: string, assessmentOrder: any) {
    return this.http.post<any>(PsychometricConfig.zenithrThomasAssessmentOrderAPI, {
      assessmentOrder: assessmentOrder,
      token: token,
      url: this.thomasAssessmentOrderUrl.replace('%CLIENTID%', clientID),
      candidate: {
        token: token,
        url: this.thomasAssessmentOrderUrl.replace('%CLIENTID%', clientID),
      },
    }
    );
  }



}
