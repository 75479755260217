import type { KeyNameDto } from '../../core/common/models';

export interface AnalysisFilterDto {
  key?: string;
  value: object;
}

export interface AnalysisFilterRequestDto {
  tenantKey: string;
  companyKey: string;
  surveyKey: string;
  type?: string;
  filters: AnalysisFilterDto[];
}

export interface AnswerScaleDto {
  value: number;
  percentage: number;
}

export interface ChartResponseDto {
  data: CriteriaAnalysisResultDto[];
  drillDown: DrillDownDataDto[];
}

export interface CriteriaAnalysisResultDto {
  category?: string;
  value: object;
  group?: string;
  value1: object;
  value2: object;
}

export interface DrillDownDataDto {
  id?: string;
  name?: string;
  category?: string;
  group?: string;
}

export interface EnpsResultDto {
  category?: string;
  percentage: number;
  count: number;
}

export interface EntityLeaverCountDto {
  entityKey?: string;
  totalCount: number;
}

export interface QuestionMeanDto {
  name: KeyNameDto[];
  key?: string;
  mean: number;
  answers: AnswerScaleDto[];
}

export interface ResponsesRateDto {
  completedCount: number;
  totalCount: number;
  responseRate: number;
}

export interface SectionAnalysisDto {
  sectionKey?: string;
  questionKey?: string;
  score: number;
}

export interface SectionMeanDto {
  name: KeyNameDto[];
  key?: string;
  experience?: string;
  mean: number;
  factorKey?: string;
  isFactor: boolean;
  isPredefined: boolean;
  scale?: string;
  questions: QuestionMeanDto[];
}
