import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GetCompanySurveysDto, SurveyCreateDto, SurveyDto, SurveyListDto, SurveyRenameDto, SurveysService } from '@ex/wellbeing/surveys';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { CreditType } from 'src/app/core/data-models/credit-type';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { CrashReportingService } from 'src/app/core/services/crash-reporting.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { UnitBalanceService } from 'src/app/core/services/unit-balance.service';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { CreditBalanceDialogComponent } from 'src/app/shared/credit-balance-dialog/credit-balance-dialog.component';
import { RenameSurveyComponent } from '../../ui/rename-survey/rename-survey.component';
import { WbSurveyPreviewComponent } from '../../ui/wb-survey-preview/wb-survey-preview.component';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { PlatformUserService } from '@ex/module/core/services/platform-user.service';
import { GeneralService } from '@ex/module/core/services/general.service';
import { products } from 'src/app/core/data-models/company-permission';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [ChildrenAnimation]
})
export class WbListComponent extends WbBaseComponent implements OnInit {

  currentPage = 1;
  page: number;
  essentialObject;
  constructor(private UnitBalanceService: UnitBalanceService, private surveysService: SurveysService,
    public matDialog: MatDialog, private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private languageService: LanguageService, private responseDialogService: ResponseDialogService,
    private crashReportingService: CrashReportingService, private platformUserService: PlatformUserService, private generalService: GeneralService) {
    super()
    this.addSubscription(this.EssentialObject$.pipe(filter((x: EssentialObject) => x.selectedEntity != null), switchMap((obj: EssentialObject) => {
      this.resolvePermissions(obj);


      this.onPageChange(0);
      this.tenantKey = obj.selectedEntity.tenantKey;
      this.companykey = obj.selectedEntity.key;
      let companyData: any = { companyKey: obj.selectedEntity.key, tenantKey: obj.tenant.key };
      return this.fetchList().pipe(map(surveyList => { return { obj, surveyList } }));
    })).subscribe(({ obj, surveyList }) => {
      this.essentialObject = obj;
      this.surveyList = surveyList.items;
      this.selectedEntity = obj.selectedEntity
      this.totalRecords = surveyList.totalCount
      this.canDeleteCount = surveyList.allDeleteCount;
      this.tenantKey = obj.selectedEntity.tenantKey;
      this.companykey = obj.selectedEntity.key;
      this.language = obj.language;
      this.tenant = obj.tenant;
      this.user = obj.user;
      this.essentialObject = obj
      this.prepareTable();
    }));
  }

  async resolvePermissions(essentialObject) {
    let permissionResponse: any = await this.platformUserService.fetchUserPermissions(essentialObject.user).toPromise();
    if (permissionResponse.success == true) {
      let hasPermission = this.generalService.hasPermission(essentialObject.selectedEntity.key, products.Wellbeing, permissionResponse?.Data, permissionResponse?.Data == 'Super Admin');
      if (hasPermission) {      
        this.generalService.resolvePermissionAlert(this.language);
      } else {
        let route = this.generalService.resolveRoute(essentialObject.selectedEntity.key, permissionResponse?.Data);
        this.router.navigateByUrl(route);
      }
    } else {
      this.router.navigateByUrl('/login');
    }
    this.LoaderService.hideLoader();
  }

  ngOnInit(): void {
    this.LoaderService.displayLoader();
  }

  pageNumber = new BehaviorSubject<number>(1);
  pageNumber$ = this.pageNumber.asObservable();
  refetchSurveys = new BehaviorSubject(false);
  refetchSurveys$ = this.refetchSurveys.asObservable();

  filterText: FormControl = new FormControl();

  surveyList = [];
  pageSize = 5;
  tenantKey: string;
  companykey: string
  totalRecords;
  canDeleteCount;
  selectedToEdit: any = {};
  selectedEntity;
  tenant;
  user;
  selectedSurveys;
  language: string = "EN";
  statusList = [];
  statusFilter: FormControl = new FormControl('ALL');
  displayedColumn;
  options: TableOptions;


  fetchList() {
    return combineLatest([this.statusFilter.valueChanges.pipe(startWith('ALL')), this.filterText.valueChanges.pipe(startWith('')).pipe(debounceTime(300),
      distinctUntilChanged())]).pipe(switchMap(([statusFilter, filterText]) => {
        this.page = 0;
        this.pageNumber.next(0);
        return combineLatest([this.pageNumber$, this.refetchSurveys$]).pipe(switchMap(([pageNumber, refetchSurveys]) => {
          return this.surveysService.getList({
            maxResultCount: this.pageSize,
            companyKey: this.companykey,
            tenantKey: this.tenantKey,
            skipCount: pageNumber,
            filter: filterText,
            status: statusFilter
          })
        }))
      }))
  }
  prepareTable() {
    this.statusList = [
      { key: 'ALL', name: this.localize('txtAll', this.language, this.ModuleNames.Shared) },
      { key: this.status.Draft, name: this.getWellbeingStatus(this.status.Draft) },
      { key: this.status.Live, name: this.getWellbeingStatus(this.status.Live) },
      { key: this.status.Closed, name: this.getWellbeingStatus(this.status.Closed) },
    ];

    this.displayedColumn = [
      { field: 'status', title: this.localize("txt_Status_579", this.language, this.ModuleNames.Wellbeing) },
      { field: 'title', title: this.localize("txt_SurveyTit_804", this.language, this.ModuleNames.Wellbeing) },
      { field: 'score', title: this.localize("txt_Workplace_928", this.language, this.ModuleNames.Wellbeing) },
      { field: 'creationTime', title: this.localize("txt_DateCreat_677", this.language, this.ModuleNames.Wellbeing) },
      { field: 'options', title: '', }]
    this.options = {
      data: this.surveyList,
      columns: this.displayedColumn,
      searchText: this.localize("txt_Search_185", this.language, this.ModuleNames.Wellbeing),
      disableSearch: true,
      colors: this.GlobalService.basicColors,
      whiteBackground: true,
      selectionRemove: ['CLOSED', 'LIVE'],
      sticky: true,
      selection: true,
      hidePagination: true
    }
    this.LoaderService.hideLoader();
  }

  onPageChange(e) {
    this.page = e;
    this.currentPage = e + 1;
    this.pageNumber.next(this.currentPage);
  }

  createSurvey() {
    try {
      this.LoaderService.displayLoader();
      let request = {
        entityKey: this.companykey,
        tenantKey: this.tenantKey,
        creditType: CreditType.Wellbeing,
      };
      this.addSubscription(this.UnitBalanceService.creditBalanceCheck(request).subscribe((response: any) => {
        let data: SurveyCreateDto = {
          companyKey: this.companykey,
          tenantKey: this.tenantKey
        };
        if (response.response == 'SUCCESS') {
          this.surveysService.create(data).subscribe((x: SurveyDto) => {
            this.router.navigate(['/wellbeing/set-up/', x.key]);
          })
          // if(!engagement.key)
          //   engagement.key = this.db.createPushId();

          this.crashReportingService.submitLogCode(21);
          // this.selectedEngagement.emit(engagement);
        } else {

          const searchDialogRef = this.matDialog.open(
            CreditBalanceDialogComponent,
            {
              data: {
                tenantKey: this.tenantKey,
                entity: this.selectedEntity,
                creditType: 'WELLBEING',
                language: this.language,
                minUnits: response.data.requiredCredits,
                currentBalance: response.data.currentUnits,
                surveyRequires: response.data.minUnits,
                user: this.user,
                tenant: this.tenant,
              },
              autoFocus: false,
            }
          );
          this.LoaderService.hideLoader();
        }


      }));


    } catch (ex) {
      this.LoaderService.hideLoader();

    }
  }

  async deleteSurvey(survey: SurveyDto) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_delete_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {
        this.surveysService.delete(survey.key).subscribe(x => {
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.Wellbeing));
          this.options.data.splice(this.options.data.indexOf(survey), 1);
          this.options = JSON.parse(JSON.stringify(this.options));
          if (!this.options.data?.length && this.totalRecords)
            this.onPageChange(this.currentPage - 2);
          else this.onPageChange(this.currentPage - 1);
        })
      }
    }
    catch (ex) {

    }
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }

  getWellbeingActionButton(status) {
    return this.WbUtilities.wellbeingActionButton.find((st) => st.key == status)?.name
  }
  getWellbeingStatus(status) {
    return this.WbUtilities.wellbeingStatuses.find((st) => st.key == status)?.name;
  }
  returnRoute(status) {
    switch (status) {
      case this.status.Draft:
        return '/wellbeing/set-up/'
      case this.status.Live:
        return '/wellbeing/live/'
      case this.status.Closed:
        return '/wellbeing/closed/'

    }
  }

  selectAllSurveys() {
    this.allSelected = true;
  }
  openWellbeingSetupPreviewDialog(element: SurveyDto) {
    this.LoaderService.displayLoader();
    this.addSubscription(this.surveysService.get(element.key).subscribe(surveyData => {
      this.ZenCDKService.openComponent(WbSurveyPreviewComponent,
        { survey: surveyData, essentialObject: this.essentialObject });
    }));
  }
  @ViewChild('template') template: TemplateRef<any>;
  renameSurvey;
  editSurvey(selectedToEdit) {
    this.renameSurvey = true;
    this.ZenCDKService.openComponent(RenameSurveyComponent, { selectedEntity: this.selectedEntity, language: this.language, selectedToEdit: selectedToEdit }).pipe(switchMap((newSurvey: any) => {
      let data: SurveyRenameDto = {
        title: newSurvey.title,
        key: newSurvey.key
      }
      return this.surveysService.rename(data).pipe(map(x => { return newSurvey }))

    })).subscribe(x => {
      let surveyIndex = this.options.data.indexOf(this.options.data.find(m => m.key == x.key));
      this.options.data[surveyIndex] = x;
      this.SnackbarService.success(this.localize('txt_updated_success', this.language, this.ModuleNames.Wellbeing));
      this.refreshTable();
    })
  }

  replaceCount(text, count) {
    return this.localize(text, this.language, this.ModuleNames.Wellbeing).replace('#count', (count).toString());
  }

  async bulkRemove(selectedSurveys: SurveyListDto[]) {
    const keys = selectedSurveys.map(x => x.key);
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_delete_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {
        this.surveysService.bulkDelete({
          companyKey: this.companykey,
          isDeleteAll: this.allSelected,
          surveyKeys: keys,
          tenantKey: this.tenantKey
        }).subscribe(x => {
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.Wellbeing));
          this.refetchSurveys.next(true);
        })
      }
    }
    catch (ex) {

    }

  }
  selectionChange(e) {
    this.selectedSurveys = e;
    this.allSelected = false;
    this.tableSelectAll = false;
  }

  allSelected;
  customSelectedAll;
  tableSelectAll;

  selectAllFromTable(event) {
    this.tableSelectAll = event;
    if (!event) {
      this.allSelected = false;
    }
  }

  clear() {
    this.customSelectedAll = false;
    this.tableSelectAll = false;
    this.refreshTable();
  }
  refreshTable() {
    this.options = JSON.parse(JSON.stringify(this.options));
  }
}
