import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SurveyTemplateDto } from '@ex/exit/survey-templates';
import { DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
import { NativeExportBaseComponent } from 'src/app/shared/native-export-base/native-export-base.component';
import { ExtractNameLanguagePipe } from 'src/app/shared/pipes/extract-name-language.pipe';

@Component({
  selector: 'app-exit-template-preview',
  templateUrl: './exit-template-preview.component.html',
  styleUrls: ['./exit-template-preview.component.scss'],
  providers: [ExtractNameLanguagePipe]

})
export class ExitTemplatePreviewComponent extends NativeExportBaseComponent implements OnInit {

  constructor(@Inject(DATA_TOKEN) private data: any, private extractLanguage: ExtractNameLanguagePipe) {
    super();
  }
  phases;
  ngOnInit(): void {
    this.language = this.data.language;
    this.report = this.data.template;
    console.log(this.report);
    this.report.sections = this.report.sections.filter(x => x.isSelected);
    this.entity = this.data.selectedEntity;
    this.title = this.localize('txt_Exit_Questionnaire', this.language, this.ModuleNames.Exit) + ' - ' + this.extractNameLanguage(this.report.name)
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.exportDraft(this.language);
    }, 2000)
    setTimeout(() => {
      this.close.emit(true);
    }, 5000)
  }
  report: SurveyTemplateDto;
  language;
  entity;
  @Output() close = new EventEmitter()
  @Output() results = new EventEmitter()
  date = new Date();
  dir = 'ltr';
  logData(e) {
    console.log(e);
  }

  extractNameLanguage(eG: any) {

    return this.LanguageService.extractNameLanguage(eG, this.language).replaceAll('[CompanyName]', this.extractLanguage.transform(this.data.entity.name, this.language))
  }

}
