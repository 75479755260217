<div class="flex flex-col items-stretch rounded bg-white bg-opacity-50 px-7">
  <div class="flex flex-row justify-between items-center h-20 gap-4 bg-white -mr-7 -ml-7 px-7 btn" (click)="!disableClick && isDemo? '':open = !open" [id]="currentSection.key" *DemoAccount="!disableClick?'Button':'Default'; let isDemo=isDemo">
    <div class="flex flex-col w-full items-stretch">
      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-col justify-center gap-2">
          <h5 class="text-lg text-zen font-medium">{{currentSection.name | extract:language}}</h5>
          <h5 class="text-xxs text-zen-lite font-normal">{{filteredCount() || currentSection.totalComments}} {{
            "txtComments" |
            translate:language:ModuleNames.Shared}}</h5>
        </div>
        <div class="flex flex-row justify-end gap-4 items-center h-full" [ngClass]="{'cursor-auto':!open}" StopPropagation [TourStep]="tourKey?{title:'txt_DriverSen_545' | translate:language:ModuleNames.Shared,text: isFactor?('txt_Clickonth_957' | translate:language:ModuleNames.Shared): isDimension?('txt_Clickonth_446' | translate:language:ModuleNames.Shared) :('txt_Clickonth_574' | translate:language:ModuleNames.Shared),order:6,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined">
          <i class="fa-regular fa-times text-zen-red w-5 leading-5" *ngIf="currentSentiment && !parentSentiment" (click)="filterSection(null)"></i>
          <span class="text-xxs text-zen-lite font-medium">{{currentSection.commentsPercentage |
            number:'1.1-1'}}%</span>
          <div class="flex flex-row justify-between items-center rounded gap-px overflow-hidden w-44 text-xxs text-zen">
            <ng-container *ngFor="let percentage of percentageColors">
              <h5 [matTooltip]="returnSentimentName(percentage.key) | translate:language:module" *ngIf="currentSection[percentage.value]" (click)="open?filterSection(percentage.key):''" class="leading-5 text-center" [class]="percentage.color" [ngClass]="{'bg-opacity-30': percentage.key != currentSentiment && currentSentiment}" [style.width.%]="currentSection[percentage.value]">
                {{currentSection[percentage.value] |
                number:'1.0-0'}}%</h5>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <i [ngClass]="{'rotate-180':open}" class="fa-regular fa-angle-up transform transition-all ease-expo" [TourStep]="tourKey?{title:'txt_ViewComme_499' | translate:language:ModuleNames.Shared,text: 'txt_Clickhere_441' | translate:language:ModuleNames.Shared,order:5,tourKey:tourKey,on:(language | language )?'right':'left',triggerId:open?undefined:currentSection.key}:undefined"></i>
  </div>
  <div [ngClass]="{'pb-4 pt-4':open}" [TourStep]="tourKey ?{title:'txt_AddDelet_480' | translate:language:ModuleNames.Shared,text: 'txt_Youcandel_805' | translate:language:ModuleNames.Shared,order:7,tourKey:tourKey,triggerId:open?undefined:currentSection.questions[0].key}:undefined">
    <div *ngIf="open" class="flex flex-col items-stretch rounded-sm overflow-hidden  transition-all ease-expo gap-px">
      <ng-container *ngFor="let question of currentSection.questions">
        <comments-question (commentCount)="assignCommentCount($event, question.key)" [tourKey]="tourKey" [language]="language" [question]="question" [sectionKey]="currentSection.key" [currentSentiment]="currentSentiment" [request]="request" [topics]="topics" (sectionAiUpdated)="updateSectionAI($event)"></comments-question>
      </ng-container>
    </div>
  </div>
</div>