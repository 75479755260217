import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RespondentPoolDto } from '@ex/core/respondent-pools';
import { ExcelUploadValidationDto, RespondentDto, RespondentService } from '@ex/wellbeing/respondents';
import { combineLatest } from 'rxjs';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { EmailNotificationService } from 'src/app/core/services/email-notification.service';
import { CDKPortalComponent, DATA_TOKEN, PortalDataResult } from 'src/app/shared/Theme/services/zen-cdk.service';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { RespondentTableFetchObject, RespondentsTableService } from '../../data-access/services/respondents-table.service';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

export interface WbRespondentsModelData { }

@Component({
  selector: 'app-wb-respondents-modal',
  templateUrl: './wb-respondents-modal.component.html',
  styleUrls: ['./wb-respondents-modal.component.scss'],
  animations: [ChildrenAnimation],
  providers: [RespondentsTableService]
})
export class WbRespondentsModalComponent extends WbBaseComponent implements OnInit, CDKPortalComponent {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<PortalDataResult> = new EventEmitter();
  columns = ['employeeId', 'name', 'email'];
  errors;
  constructor(@Inject(DATA_TOKEN) private data: any, private fb: FormBuilder,
    private respondentsTableService: RespondentsTableService, private respondentService: RespondentService) {
    super();
    this.hugeCount = this.GlobalService.hugeCount;
    this.lgCount = this.GlobalService.lgCount;
    this.mdCount = this.GlobalService.mdCount;
    this.smCount = this.GlobalService.smCount;
  }


  ngOnInit(): void {


    this.multiSelect = this.data?.multiSelect;

    if (this.data?.surveyPool)
      this.surveyPool = this.data?.surveyPool;
    if (this.data?.surveyKey)
      this.surveyKey = this.data?.surveyKey;
    this.open = true
    this.employeeForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(this.mdCount)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(this.mdCount)]],
      employeeId: ['', [Validators.maxLength(this.mdCount)]],
    });
    this.addSubscription(this.EssentialObject$.pipe(
      filter(obj => obj?.selectedEntity?.key != null),
      distinctUntilChanged((x, y) => x != y, x => x.selectedEntity.key),
      switchMap(obj => {
        this.language = obj.language;
        this.tenantKey = obj.tenant.key;
        this.companyKey = obj.selectedEntity.key;
        return this.respondentsTableService.init({
          language: this.language,
          companyKey: this.companyKey,
          tenantKey: this.tenantKey,
          options: { disableSearch: true },
          survey: { key: this.surveyKey, excludeExisting: true }
        })
      })).subscribe((obj: RespondentTableFetchObject) => {
        this.options = obj.options;
        this.totalRecords = obj.totalRecords;
        this.currentPage = obj.pageNumber;
        this.pageSize = obj.pageSize;

        this.LoaderService.hideLoader();
      }));

    // this.addSubscription(this.respondentsTableService.tableOptions$.subscribe(options => {
    //   this.options = options;
    //   this.totalRecords = this.respondentsTableService.totalRecords;
    //   this.language = this.respondentsTableService.language;
    //   this.LoaderService.hideLoader();
    // }));
    // this.addSubscription(combineLatest([this.respondentsTableService.pageNumber$, this.respondentsTableService.pageSize$]).subscribe(data => {
    //   this.currentPage = data[0];
    //   this.pageSize = data[1];
    // }))

  }
  searchText;
  tenantKey;
  companyKey;
  surveyKey;
  surveyPool: boolean = false;
  totalRecords
  currentPage
  pageSize
  open: boolean = false;
  multiSelect: boolean = false;
  hugeCount = 0;
  lgCount = 0;
  mdCount = 0;
  smCount = 0;
  employeeForm!: FormGroup;
  tab = 0;
  selectedRespondents;
  language;
  options: TableOptions;
  addSingleRespondent() {
    if (this.employeeForm.invalid)
      return;
    console.log(this.employeeForm.value);
    this.results.emit({ type: 'single', data: this.employeeForm.value, manualClose: true });
  }
  bulkRespondent() {
    this.results.emit({ type: 'bulk', manualClose: true, data: { respondents: this.selectedRespondents, allSelected: this.respondentsTableService.selectAll } })
  }
  searchTextUpdate(text) {
    this.respondentsTableService.searchText.next(text);
  }
  onPageChange(e) {
    this.currentPage = e + 1;
    this.respondentsTableService.pageNumber.next(this.currentPage);
  }

  uploadFile(event) {
    // this.relationshipToCandidateChange.emit(this.relationshipToCandidate);
    // this.uploadCSV.emit(event);

    let files = event.srcElement.files;
    if (this.isValidxlsxFile(files[0])) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadRespondentFile(reader.result);

      };
      let input = event.target;

    } else {
      this.SnackbarService.error(this.localize('txt_Pleaseimp_478', this.language, this.ModuleNames.Wellbeing));
    }

  }



  uploadRespondentFile(event) {
    let files = event.srcElement.files;
    if (this.isValidxlsxFile(files[0])) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // this.results.emit({ type: 'file', data: reader.result });
        this.validateFile(reader.result);
        event.target.value = '';
      };

    } else {
      alert("Please import valid .csv file.");

    }
    // let request = {
    //   FileKey: this.key,
    //   TenantKey: this.essentialObject.tenant.key,
    //   CompanyKey: this.essentialObject.selectedEntity.key,
    //   SurveyKey: this.surveyKey,
    //   UserKey: this.essentialObject.user.key,
    //   SurveyType: this.surveyType,
    //   isCandidate: (this.addRespondentType === 'CANDIDATE' ? true : false),
    //   candidateKey: this.candidateKey,
    //   FileBase: fileBase
    // }
    // this.respondentService
    //   .updloadRespondents(request)
    //   .subscribe((response: any) => {
    //     this.closeDialog.emit();
    //    });
  }

  validateFile(data) {
    this.errors = [];
    this.respondentService.excelUploadValidation({
      surveyKey: this.surveyKey,
      companyKey: this.companyKey,
      tenantKey: this.tenantKey, excelFile: data.split(",")[1]
    }).subscribe((x: ExcelUploadValidationDto | any) => {
      if (x.errors?.length)
        this.errors = x.errors;
      else {
        this.results.emit({ type: 'file', data: {file: data, totalRecords: x.totalRecords}});
      }
      this.LoaderService.hideLoader();
    })
  }



  isValidxlsxFile(file: any) {
    return file?.name?.endsWith(".xlsx");
  }

  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";


  extractNameLanguage(eG: any) {
    return this.LanguageService.extractNameLanguage(eG, this.language);
  }


  downloadExcel() {
    window.open('/assets/downloads/Respondents Template.xlsx', '_blank');
  }


  selectedAll(event) {
    this.respondentsTableService.selectAll = event;
  }

}
