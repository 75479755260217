import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeyNameDto } from '@ex/core/common';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { AiTranslationService } from '@ex/module/core/services/ai-translation.service';
import { LanguageService } from '@ex/module/core/services/language.service';

@Component({
  selector: 'zen-translation-modal',
  templateUrl: './zen-translation-modal.component.html',
  styleUrls: ['./zen-translation-modal.component.scss']
})
export class ZenTranslationModalComponent extends BaseComponent{
  @Input() classList: string = "text-sm text-zen truncate flex flex-row justify-between items-center  whitespace-normal w-full relative";
  constructor(private languageService: LanguageService, private aiTranslation: AiTranslationService) {
    super();
  }
  _translations = [];
  defaultLanguage = { key: 'EN', name: '' };
  modalSelected = { key: '', name: '' };
  translationBK = '';
  inTranslation: boolean = false;
  @Input() language = 'EN';
  @Input() open: boolean = false;
  @Output() openChange: EventEmitter<any> = new EventEmitter();
  @Input('translations') set translations(translations: string) {
    if (translations) {
      this.translationBK = translations;
      this._translations = JSON.parse(translations);
      this.modalSelected = this._translations?.find(s => s.key != this.language);
      this.defaultLanguage = this._translations.find(f => f.key == this.language);
    }
  }
  @Output() translationsChanges: EventEmitter<any> = new EventEmitter();
  @Input() editor: boolean = false;
  @Input() editorConfig;
  close() {
    this._translations = JSON.parse(this.translationBK);
    this.modalSelected = this._translations?.find(s => s.key != this.language);
    this.open = false;
    this.openChange.emit(false);
  }
  getLanguage(langISO: string) {
    return this.languageService.getLongLanguageName(langISO, this.language);
  }
  save() {
    this.translationsChanges.emit(this._translations);
    this.open = false;
    this.openChange.emit(false);
  }
  aiTranslate(key: string) {
    this.inTranslation = true;
    let sourceTranslation: KeyNameDto = this.defaultLanguage;
    if (!sourceTranslation?.name?.length)
      sourceTranslation = this._translations.find(translation => translation.name.length);
    this.aiTranslation.translate(sourceTranslation.name, sourceTranslation.key, key).subscribe((res: any) => {
      this.inTranslation = false;

      if (res)
        this.modalSelected = res;
    }, err => {
      this.inTranslation = false;
    })
  }
}
