import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { GlobalsService } from '@ex/module/core/services/globals.service';

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  sEndpoint: string = 'https://apis.zenithr.net/api/';//'https://apis.zenithr.net/api/';//
  sDevEndpoint: string = 'http://localhost:52619/api/';
  fbFunctionURL: string = '';

  constructor(private http: HttpClient, private globals: GlobalsService) {
    this.sEndpoint = this.globals.sEndpoint;
    this.fbFunctionURL = this.globals.fbFunctionURL;
  }

  deduct(request: { noOfRespondees: number, userKey: string, tenantKey: string, entityKey: string, engagementKey: string }) {
    return this.http.post(this.fbFunctionURL + 'creditWellbeing', request);
  }
}
