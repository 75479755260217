import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TableOptions } from '@ex/module/core/data-models/table-options';
import { Status } from '@ex/module/core/services/global-status.service';
import { AnalyticsService } from '@ex/module/survey-x/data-access/services/analytics.service';
import { CriteriaResolverService } from '@ex/module/survey-x/data-access/services/criteria-resolver.service';
import { SxBaseComponent } from '@ex/module/survey-x/ui/sx-base/sx-base.component';
import { SurveyAnalysisService } from '@ex/survey-x/index';
import { ChildrenAnimation } from 'projects/ex/theme-shared/src/lib/animations';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'sx-respondents-table',
  templateUrl: './respondents-table.component.html',
  styleUrls: ['./respondents-table.component.scss'],
  animations:[ChildrenAnimation]
})
export class RespondentsTableComponent extends SxBaseComponent implements OnInit {

  page$ = new BehaviorSubject(0)
  search$ = new BehaviorSubject("");
  status$ = new BehaviorSubject("ALL");
  maxResultCount = 10;

  respondents = [];
  totalCount = 0;

  options: TableOptions


  displayedColumns = [];

  dir = 'ltr';

  language = "EN"
  surveyCriterias: any;

  searchBox = new FormControl("");

  statusList: any[] = [
    {
      key: Status.All,
      name: 'All'
    },
    {
      key: Status.Progress,
      name: 'In Progress',
    },
    {
      key: Status.Completed,
      name: 'Completed',
    },
  ];

  completedIcon = 'check-circle';
  completedColor = 'text-zen-green';
  inProgressIcon = 'loader';
  inProgressColor = 'text-zen-blueSelect';
  notStartedIcon = 'circle-exclamation';
  notStartedColor = 'text-zen-orange';
  inactiveIcon = 'circle-xmark';
  inactiveColor = 'text-zen-redSelect';


  constructor(private surveyAnalysisService: SurveyAnalysisService
    , private analyticsService: AnalyticsService
    , private criteriaResolverService: CriteriaResolverService) { super() }

  ngOnInit(): void {

    if (document.body.dir == 'rtl') {
      this.dir = 'rtl';
    }

    this.searchBox.valueChanges
      .pipe(
        map(searchTerm => {
          if (searchTerm.length > 2)
            return searchTerm;

          return "";

        }),
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.search$.next(value);
      })



    this.getRespondents()

    this.analyticsService.filters$.pipe(
      filter(x => x != null)
    ).subscribe(filters => {

      this.language = filters.language;
      this.surveyCriterias = filters.survey.surveyCriterias

      this.filtersLoaded.next(filters);
    })

  }

  getStatusIconClasses(element: any): string {
    switch (element.status) {
      case 'PENDING':
      case 'NOTSTARTED':
        return 'fa-circle-exclamation text-zen-orange1 opacity-50 text-sm';
      case 'INPROGRESS':
        return 'fa-loader text-sm text-zen-blueSelect';
      case 'COMPLETED':
        return 'fa-circle-check text-sm text-zen-green';
      case 'INACTIVE':
        return 'fa-circle-xmark text-sm text-zen-red1';
      default:
        return ''; // Handle default case or add additional statuses as needed
    }
  }

  getRespondents() {

    const sub = combineLatest([this.filtersLoaded$, this.page$, this.search$, this.status$]).pipe(
      switchMap(([filters, page, search, status]) => {


        const f = [... this.analyticsService.mapGlobalFilters(filters)];

        if (status != "ALL") {
          f.push({ key: "Status", value: status });
        }

        return this.surveyAnalysisService.getRespondentsListWithCriteria(
          {
            tenantKey: filters.tenantKey,
            companyKey: filters.companyKey,
            formId: filters.survey.key,
            searchKey: search,
            skipCount: page,
            maxResultCount: this.maxResultCount,
            type: "",
            filters: f
          }
        )
      })
    ).subscribe(response => {

      this.totalCount = response.totalCount;
      this.respondents = response.items;

      this.renderTable();

    });

    this.addSubscription(sub);

  }

  renderTable() {
    this.displayedColumns = [
      // {
      //   field: 'employeeId',
      //   title: this.localize('txt_employee_ID', this.language, this.ModuleNames.SurveyX)
      //   ,
      // },
      {
        field: 'name',
        title: this.localize('txt_full_name', this.language, this.ModuleNames.SurveyX)
        ,
      },
      {
        field: 'email',
        title: this.localize('txt_user_email', this.language, this.ModuleNames.SurveyX)
        ,
      },
      // {
      //   field: 'status',
      //   title: this.localize('txt_progress', this.language, this.ModuleNames.SurveyX)
      //   ,
      // },
      {
        field: 'invitationDate',
        title: this.localize('txt_invited', this.language, this.ModuleNames.SurveyX)
        ,
      },
      {
        field: 'completionDate',
        title: this.localize('txt_completed', this.language, this.ModuleNames.SurveyX)
        ,
      },
    ];

    // this.surveyCriterias?.forEach(criteria => {
    //   this.displayedColumns.push({
    //     field: `criteria_${criteria.key}`,
    //     title: this.ExtractPipe.transform(criteria.name, this.language),
    //     type: 'criteria'
    //   })
    // })

    this.displayedColumns.push({
      field: 'action',
      title: '',
    });

    this.options = {
      columns: this.displayedColumns,
      searchText: this.localize('txt_search', this.language, this.ModuleNames.SurveyX),
      exportText: this.localize('btnExport', this.language, this.ModuleNames.SurveyX),
      exportName: this.localize('txtSurveyRespondentList', this.language, this.ModuleNames.SurveyX),
      whiteBackground: true,
      colors: this.GlobalService.basicColors,
      selection: false,
      selectionKey: '',
      selectionRemove: [],
      sticky: true,
      serverSide: false,
      data: this.respondents,
      hidePagination: true,
      disableSearch: true,
    }
  }

  validEmail(e) {
    return e?.indexOf('@') < 1 ? '' : e;
  }

  onPageChange(page) {
    this.page$.next(page);
  }

  extractCustomProps(record: any, key: string) {
    let propKey = key.replace("criteria_", "");

    let prop = record?.customProperties?.find(x => x.key == propKey)

    return this.criteriaResolverService.extract(propKey, prop?.value, this.language)
  }

  onStatusChange(event) {
    this.status$.next(event);
  }

}
