/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EmailTemplateTypes {
    All = 0,
    Invitation = 1,
    Reminder = 2,
}
