import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { AnalysisComponentModel } from '../../utils/analysis-component-model';
import { AnalyticsFilter } from '../../data-access/services/analytics.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sx-base',
  templateUrl: './sx-base.component.html',
  styleUrls: ['./sx-base.component.scss']
})
export class SxBaseComponent extends BaseComponent implements OnInit {

  filtersLoaded = new Subject<AnalyticsFilter>();
  filtersLoaded$ = this.filtersLoaded.asObservable();
  AnalysisComponentData: AnalysisComponentModel;

  constructor() { super() }

  ngOnInit(): void {
  }

}
