import { CompanyPermission } from './company-permission';
import { Entity } from './Entity';

export class PlatformUser {
  key: string;
  email: string;
  tenantKey: string;
  firstName: string;
  lastName: string;
  imageURL: string;
  role: string;
  isActive: boolean;
  uid: string;
  EntityReferences: any[] = [];
  password: string;
  companyReferences: any[] = [];
  selectedLang: string;
  expiredNotification: boolean = true;
  startedNotification: boolean = true;
  expiringNotification: boolean = true;
  completedNotification: boolean = true;
  remainingUnits: boolean = true;
  subscriptionExpiring: boolean = true;
  subscriptionExpired: boolean = true;
  doNotShowAllocationConfirmation: boolean = false;
  CompanyPermissions: CompanyPermission[] = [];
  ModulePermissions: CompanyPermission[] = [];

  CreatedBy: string;
  UserKey: string;
  TenantKey: string;
}
