import { Component, OnInit } from '@angular/core';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { RespondentTableFetchObject, RespondentsTableService } from '../../data-access/services/respondents-table.service';
import { WellbeingService } from '../../data-access/services/wellbeing.service';
import { GenerateEntityHierarchyService } from '../../data-access/services/generate-entity-hierarchy.service';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { GenerateSurveyLinkService, SendBulkRemindersDto, SurveyDto, SurveysService } from '@ex/wellbeing/surveys';
import { BulkAssignToSurveyDto, ExcelUploadValidationDto, RespondentDto, RespondentListPagedDto, RespondentService } from '@ex/wellbeing/respondents';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { KeyResolverService } from 'src/app/onboarding/services/key-resolver.service';
import { FormControl } from '@angular/forms';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { StatusCountDto, SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { WbRespondentsModalComponent } from '../../ui/wb-respondents-modal/wb-respondents-modal.component';
import { PortalDataResult } from 'src/app/shared/Theme/services/zen-cdk.service';
import { ShareSurveyComponent } from '../../ui/share-survey/share-survey.component';
import { JsonToExcelComponent } from 'src/app/shared/export-components/json-to-excel/json-to-excel.component';
import { WbReminderModalComponent } from '../../ui/wb-reminder-modal/wb-reminder-modal.component';
import { WellbeingFirebaseUpdateService } from '../../data-access/services/wellbeing-firebase-update.service';
import { LiveSurveyDates, LiveSurveyTimeChangesComponent } from 'src/app/shared/components/live-survey-time-changes/live-survey-time-changes.component';
import { Status } from 'src/app/core/services/global-status.service';
import { EngagementRespondent } from '@ex/module/core/data-models/engagement-respondent';
import { RespondentService as RespondentPoolService } from 'src/app/core/services/respondent.service';
import { CreditBalanceDialogComponent } from '@ex/module/shared/credit-balance-dialog/credit-balance-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-live-survey',
  templateUrl: './live-survey.component.html',
  styleUrls: ['./live-survey.component.scss'],
  providers: [RespondentsTableService, WellbeingService, GenerateEntityHierarchyService, WellbeingFirebaseUpdateService]
})
export class WbLiveSurveyComponent extends WbBaseComponent implements OnInit {
  resetSelection;
  essentialObject
  allEntities
  selectedEntity
  surveyData: SurveyDto;
  user
  tenant
  topValue;
  options: TableOptions;
  totalRecords;
  language
  tenantKey
  companyKey
  currentPage
  pageSize;
  completed = 0;
  total = 0;
  precentage = 0;
  wbStatus = Status;
  selectedRespondents;
  buttonList: any[] = [{
    key: this.wbStatus.All,
    value: 0,
  },
  {
    key: this.wbStatus.Completed,
    value: 0,
  },
  {
    key: this.wbStatus.Progress,
    value: 0,
  },
  {
    key: this.wbStatus.Pending,
    value: 0,
  },
  {
    key: this.wbStatus.Inactive,
    value: 0,
  },

  ];
  currentStatus = this.buttonList[0];
  filterText;
  respondentsPagedResult: RespondentListPagedDto;
  respondentAdd: boolean;

  constructor(private keyResolverService: KeyResolverService
    , private responseDialogService: ResponseDialogService
    , public respondentsTableService: RespondentsTableService
    , private surveysService: SurveysService, private wellbeingService: WellbeingService
    , private respondentService: RespondentService, private matDialog: MatDialog
    , private route: ActivatedRoute, private wellbeingFirebaseUpdateService: WellbeingFirebaseUpdateService
    , private surveyAnalysisService: SurveyAnalysisService
    , private generateSurveyLinkService: GenerateSurveyLinkService
    , private router: Router
    , private respondentPoolService: RespondentPoolService) {
    super();
    const resolver = this.keyResolverService.getResolver("SurveyStatus");
    this.keyResolverService.setKeyResolver(resolver);



    this.addSubscription(this.EssentialObject$.pipe(
      filter((x: EssentialObject) => x.selectedEntity != null),
      switchMap((essentialObject: EssentialObject) => {
        return this.surveysService.get(this.route.snapshot.params?.id).pipe(
          map(survey => ({ essentialObject, survey }))
        )
      })
    ).subscribe(({ essentialObject, survey }) => {
      this.tenantKey = essentialObject.selectedEntity.tenantKey;
      this.companyKey = essentialObject.selectedEntity.key;

      console.log(essentialObject, survey);
      this.essentialObject = essentialObject;
      this.allEntities = essentialObject.allEntities;
      this.selectedEntity = essentialObject.selectedEntity;
      this.language = essentialObject.language;
      this.surveyData = survey;
      this.user = essentialObject.user;
      this.tenant = essentialObject.tenant;
      this.LoaderService.hideLoader();
      this.initTable();


    }));
  }




  updateStatusCounts(counts: StatusCountDto) {

    this.completed = counts.completed;
    this.total = counts.total;
    this.precentage = counts.responseRate;

    this.buttonList.forEach(button => {
      if (button.key == this.wbStatus.All) {
        button.value = counts[this.wbStatus.All.toLowerCase()];
        return;
      }

      button.value = counts[Object.keys(counts).find((x, i) => x.toLowerCase() == button.key.toLowerCase())];
    })
  }

  ngOnInit(): void {
    this.LoaderService.displayLoader();
    this.GlobalService.innerRoute = true;
    this.topValue = document.querySelector('.h-header')?.clientHeight / 16;
  }





  initTable() {
    let displayedColumns = [
      {
        field: 'employeeId',
        title: this.localize('txt_employee_ID', this.language, this.ModuleNames.Shared)
        ,
      },
      {
        field: 'name',
        title: this.localize('txt_full_name', this.language, this.ModuleNames.Shared)
        ,
      },
      {
        field: 'email',
        title: this.localize('txt_user_email', this.language, this.ModuleNames.Shared)
        ,
      },
      {
        field: 'status',
        title: this.localize('txt_progress', this.language, this.ModuleNames.Shared)
        ,
      },
      {
        field: 'action',
        title: '',
      }
    ];

    this.addSubscription(this.wellbeingFirebaseUpdateService.getListUpdate(this.tenantKey, this.companyKey, this.surveyData.key).pipe(switchMap(x => {


      return this.respondentsTableService.init({
        language: this.language,
        companyKey: this.companyKey,
        tenantKey: this.tenantKey,
        options: { columns: displayedColumns, disableSearch: true },
        survey: { key: this.surveyData.key, title: this.surveyData.title }
      }).pipe(switchMap((obj: RespondentTableFetchObject) => {
        return this.surveyAnalysisService.getStatusCounts(this.surveyData.key).pipe(map(count => ({ obj, count })))
      }))
    }))
      .subscribe(({ obj, count }) => {
        this.options = obj.options;
        this.totalRecords = obj.totalRecords;
        this.currentPage = obj.pageNumber;
        this.respondentsPagedResult = obj.result;
        this.pageSize = obj.pageSize;
        this.updateStatusCounts(count);
      }));



  }

  bulkSendReminder() {
    this.ZenCDKService.openComponent(WbReminderModalComponent, { survey: this.surveyData, language: this.language, isSendEmails: true, reminderCount: this.respondentsPagedResult?.allSendReminderCount }).subscribe((result: any) => {
      this.surveyData = result;
      this.sendReminders([]);
    })
  }
  sendSingleReminder(respondent: RespondentDto) {
    this.ZenCDKService.openComponent(WbReminderModalComponent, { survey: this.surveyData, language: this.language, isSendEmails: true }).subscribe((result: any) => {
      this.surveyData = result;
      this.sendReminders([respondent.email]);
    })
  }
  sendReminders(emails) {
    this.surveysService.sendBulkReminders({
      emails: emails,
      emailTemplate: this.surveyData.reminderEmail,
      surveyKey: this.surveyData.key
    }).subscribe(x => {
      this.SnackbarService.success(this.localize('txtReminderSent', this.language, this.ModuleNames.Wellbeing));
    });
  }


  resolveSurveyStatus(key: string) {
    return this.keyResolverService.resolve(`${key?.toUpperCase()}`, this.language, this.ModuleNames.Shared);
  }
  onStatusChange(e) {
    this.currentStatus;
    this.currentStatus = e;
    // this.respondentsTableService.changeStatus(e);
    this.respondentsTableService.currentStatus.next(e.key);
    this.respondentsTableService.pageNumber.next(1);
  }

  selectRespondents(e) {
    this.selectedRespondents = e
  }

  searchTextUpdate(text) {
    this.respondentsTableService.searchText.next(text);
  }

  onPageChange(e) {
    this.currentPage = e + 1;
    this.respondentsTableService.pageNumber.next(this.currentPage);
  }

  async deleteRespondent(respondent: RespondentDto) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondent_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {

        this.addSubscription(this.respondentService.delete(respondent.email, this.surveyData.key).subscribe(x => {
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.Wellbeing));
          this.respondentsTableService.pageNumber.next(1);
        }))
      }
    } catch (ex) {

    }
  }
  async deleteRespondents(respondents: RespondentDto[]) {
    this.resetSelection = false;
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondents_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {
        let emails = respondents.map(x => x.email);
        this.addSubscription(this.respondentService.bulkDelete({
          emails: emails,
          surveyKey: this.surveyData.key,
          isDeleteAll: this.respondentsTableService.selectAll
        }).subscribe(x => {
          this.resetSelection = true;
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.Wellbeing));
          this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
        }))
      }
    } catch (ex) {

    }
  }
  selectedAll(event) {
    this.respondentsTableService.selectAll = event;
  }

  async shareSurveyLink() {
    const link = await this.generateSurveyLinkService.generateLink(this.tenantKey, this.companyKey, this.surveyData.key).toPromise();
    this.ZenCDKService.openComponent(ShareSurveyComponent, { link: link, language: this.language });
  }

  removeRespondent(respondent: RespondentDto) {

  }


  SendReminder(respondent: RespondentDto) { }
  showRespondentMenu(respondent: RespondentDto) {
    if (respondent.status != this.wbStatus.Pending && respondent.status != this.wbStatus.Progress)
      return false;
    return true;
  }

  openAddRespondentModal() {
    this.respondentAdd = true; return;
    // this.ZenCDKService.openComponent(WbRespondentsModalComponent, { multiSelect: true, manualClose: true, surveyPool: true, surveyKey: this.surveyData.key }).subscribe((result: PortalDataResult) => {
    //   switch (result.type) {
    //     case 'single':
    //       this.createSingleRespondent(result.data);
    //       break;
    //     case 'bulk':
    //       this.createMultipleRespondent(result.data);
    //       break;
    //     case 'file':
    //       this.insertUsingExcel(result.data)
    //       break;
    //   }
    // })
  }

  async createMultipleRespondent(data: any) {


    var totalRespondents = await this.respondentService.getUnitsToDeduct(this.surveyData.key, {
      emails: data.type == "ALL" ? data.exclude.map(x => x.email) : data.respondents.map(x => x.email),
      isSelectAll: data.type == "ALL" ? true : false
    }).toPromise();


    let message = this.localize("txt_unit_deduction", this.language, this.ModuleNames.Shared);
    let subject = this.localize("txt_allocation_confirmation", this.language, this.ModuleNames.Shared);
    let actionButtonText = this.localize("txt_okay_action", this.language, this.ModuleNames.Shared);
    let cancelButtonText = this.localize("btn_cancel", this.language, this.ModuleNames.Shared);
    message = message.replace('#UNITS#', totalRespondents);

    let confirmationResult = await this.responseDialogService.confirmAllocation(message, subject, actionButtonText, cancelButtonText);
    if (confirmationResult) {

      let reData: BulkAssignToSurveyDto = {
        emails: data.type == "ALL" ? data.exclude.map(x => x.email) : data.respondents.map(x => x.email),
        isSelectAll: data.type == "ALL" ? true : false
      }

      // let reData: BulkAssignToSurveyDto = {
      //   emails: data.respondents.map(x => x.email),
      //   isSelectAll: data.allSelected
      // };
      this.deductUnits(totalRespondents).pipe(switchMap(x => {
          return this.respondentService.bulkAssignToSurvey(this.surveyData.key, reData)
      }))
        .subscribe(respondentData => {
          this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
          this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
          this.respondentAdd = false;
          this.LoaderService.hideLoader();
        })
    }
  }

  validateFileRequest(data) {
    return this.respondentService.excelUploadValidation({
      surveyKey: this.surveyData.key,
      companyKey: this.companyKey,
      tenantKey: this.tenantKey, excelFile: data.split(",")[1]
    })
  }

  async insertUsingExcel(data: any) {

    // TODO: Validate the excel to get the count
    let excelValidation = await this.validateFileRequest(data).toPromise();

    if (excelValidation.totalRecords == 0) {
      this.SnackbarService.error("");
      // TODO: Show a message that the respondents already exists and their data will be updated and send the request to upload the excel
      return this.respondentService.excelUpload(request).toPromise();
    }

    // Upload the excel to Res Pool

    // Upload the excel to wellbeing pool


    var request = {
      surveyKey: this.surveyData.key,
      companyKey: this.companyKey,
      tenantKey: this.tenantKey,
      excelFile: data.split(",")[1]
    }

    let requestToPool = {
      FileKey: 'RESPOOL',
      TenantKey: this.tenant.key,
      CompanyKey: this.selectedEntity.key,
      UserKey: this.user.key,
      FileBase: data
    };







    const confim = await this.openUnitsConfirmation(excelValidation.totalRecords);
    if (confim) {
      this.LoaderService.displayLoader();
      this.deductUnits(excelValidation.totalRecords).pipe(switchMap(x=> {
        return  this.respondentPoolService
          .updloadRespondents(requestToPool).pipe(
            switchMap(res => {
              return this.respondentService.excelUpload(request)
            }))})).subscribe(respondentData => {

          if (!respondentData) {
            this.LoaderService.hideLoader();
            return;
          }

          this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
          this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
          this.respondentAdd = false;
          this.LoaderService.hideLoader();
        })

    }

  }
  async createSingleRespondent(data: any) {
    let message = this.localize("txt_unit_deduction", this.language, this.ModuleNames.Shared);
    let subject = this.localize("txt_allocation_confirmation", this.language, this.ModuleNames.Shared);
    let actionButtonText = this.localize("txt_okay_action", this.language, this.ModuleNames.Shared);
    let cancelButtonText = this.localize("btn_cancel", this.language, this.ModuleNames.Shared);
    message = message.replace('#UNITS#', 1);

    let confirmationResult = await this.responseDialogService.confirmAllocation(message, subject, actionButtonText, cancelButtonText);
    if (confirmationResult) {
      this.LoaderService.displayLoader();

      let respondentData = {
        name: data.name,
        email: data.email,
        surveyKey: this.surveyData.key,
        companyKey: this.surveyData.companyKey,
        tenantKey: this.surveyData.tenantKey,
        employeeId: data.employeeID,
      } as RespondentDto;

      this.deductUnits(1).pipe(switchMap(x => {
        return this.getUploadToRespondentPoolRequest(data).pipe(
          switchMap(response => {
            return this.respondentService.create(respondentData)
          })
        )

      }))
        .subscribe(respondentData => {

          this.respondentsTableService.addRespondent(respondentData);
          this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
          this.ZenCDKService.closeAll();
          this.LoaderService.hideLoader();
        })
    }
  }

  deductUnits(number: number): Observable<any> {
    let request = { noOfRespondees: number, userKey: this.user.key, tenantKey: this.tenantKey, entityKey: this.selectedEntity.key, engagementKey: this.surveyData.key };
    return this.wellbeingService.checkBalanceAndCreditWellbeing(request).pipe(map((x: any) => {
      if (x.message == 'Insufficient Credits') {
        this.LoaderService.hideLoader();
        this.matDialog.open(CreditBalanceDialogComponent, {
          data: {
            tenantKey: this.tenantKey, entity: this.selectedEntity, creditType: 'ENGAGEMENT',
            language: this.language, minUnits: x.data.requiredCredits,
            currentBalance: x.data.currentUnits, surveyRequires: x.data.minUnits, user: this.user, tenant: this.tenant
          },
          autoFocus: false,
        });
        throw new Error('Valid token not returned');
      }
      return x;
    }))
  }

  getUploadToRespondentPoolRequest(data: any) {
    let respondents: EngagementRespondent[] = []
    respondents.push(data);

    let request = {
      TenantKey: this.tenant.key,
      CompanyKey: this.selectedEntity.key,
      UserKey: this.user.key,
      respondents: respondents
    }

    return this.respondentPoolService.updateRespondentPool(request)
  }

  private async openUnitsConfirmation(units: number = 1) {
    let message = this.localize("txt_unit_deduction", this.language, this.ModuleNames.Shared);
    let subject = this.localize("txt_allocation_confirmation", this.language, this.ModuleNames.Shared);
    let actionButtonText = this.localize("txt_okay_action", this.language, this.ModuleNames.Shared);
    let cancelButtonText = this.localize("btn_cancel", this.language, this.ModuleNames.Shared);
    message = message.replace('#UNITS#', units);

    let confirmationResult = await this.responseDialogService.confirmAllocation(message, subject, actionButtonText, cancelButtonText);

    return confirmationResult;
  }

  downloadExcel() {
    window.open('/assets/downloads/Respondents Template.xlsx', '_blank');
  }

  downloadExcell() {
    // this.respondentsTableService.fetchFullList().pipe(filter)
    this.addSubscription(this.respondentService.getList({
      surveyKey: this.surveyData.key,
      maxResultCount: 5,
      skipCount: 5,
      filterText: '',
      isAllResults: true
    }).subscribe(x => {
      let data = x.items.map(x => {
        let item = {
          employeeId: x.employeeId,
          name: x.name,
          email: x.email,
          status: this.keyResolverService.resolve(`${x.status?.toUpperCase()}`, 'EN', this.ModuleNames.Shared),
          link: x.surveyLink
        }
        return item;
      })

      this.ZenCDKService.openComponent(JsonToExcelComponent, {
        data: data,
        name: this.localize('txt_respondents_list', this.language, this.ModuleNames.Wellbeing)
      })

    }))
  }

  async closeSurvey() {

    if (this.completed < 3) {
      return this.SnackbarService.error(this.localize('txt_Atleast3r_677', this.language, this.ModuleNames.Wellbeing));
    }

    const confirm = await this.responseDialogService.confirmation(
      "txt_go_closed",
      "",
      "txt_okay_action",
      "btn_cancel",
      this.ModuleNames.Wellbeing,
      this.language
    )

    if (confirm) {
      // TODO: Close the survey
      this.surveysService.closeSurvey(this.surveyData.key)
        .subscribe(response => {
          this.router.navigate(['/wellbeing/closed/', this.surveyData.key])
        })

      // TODO: On Sucess redirect the user to closed survey page with surveyid
    }

  }

  ngOnDestroy() {
    this.GlobalService.innerRoute = false;
  }


  openLiveDateChanges() {
    let surveyDates: LiveSurveyDates = {
      endDate: new Date(this.surveyData.endDate),
      startDate: new Date(this.surveyData.startDate),
      reminders: JSON.parse(JSON.stringify(this.surveyData.reminders)) || []
    }
    this.ZenCDKService.openComponent(LiveSurveyTimeChangesComponent, {
      surveyDates: surveyDates,
      language: this.language
    }).pipe(switchMap((data: LiveSurveyDates) => {
      return this.surveysService.updateSurveyDateWithReminders({ reminders: data.reminders, endDate: new Date(data.endDate).toISOString(), key: this.surveyData.key }).pipe(map(x => data))
    })).subscribe((data: LiveSurveyDates) => {
      this.surveyData.endDate = data.endDate.toDateString();
      this.surveyData.reminders = data.reminders;
      this.SnackbarService.success(this.localize('txt_updated_success', this.language, this.ModuleNames.Wellbeing));
    })
  }
}
