import type { AnalysisFilterRequestDto, CriteriaAnalysisResultDto, DimensionOverallAnalysisDto, EntityCountDto, HeatmapDto, RespondentAnalysisFilterRequestDto, RespondentsListWithCriteriaDto, SectionRespondentsIndexDto, StatusCountDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SurveyAnalysisService {
  apiName = 'Default';

  getCompletedCount = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, object>({
      method: 'POST',
      url: '/api/wellbeing/survey-analysis/completed-count-analysis',
      body: filters,
    },
    { apiName: this.apiName });

  getGroupedRespondentsByCriteria = (criteria: string, filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, CriteriaAnalysisResultDto[]>({
      method: 'POST',
      url: `/api/wellbeing/survey-analysis/respondents-by-criteria/${criteria}`,
      body: filters,
    },
    { apiName: this.apiName });

  getHeatmapAnalysis = (criteria: string, filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, HeatmapDto>({
      method: 'POST',
      url: `/api/wellbeing/survey-analysis/heatmap-by-criteria/${criteria}`,
      body: filters,
    },
    { apiName: this.apiName });

  getOverallAnalysis = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, object>({
      method: 'POST',
      url: '/api/wellbeing/survey-analysis/response-rate-analysis',
      body: filters,
    },
    { apiName: this.apiName });

  getOverallDimensionAnalysis = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, DimensionOverallAnalysisDto[]>({
      method: 'POST',
      url: '/api/wellbeing/survey-analysis/overall-dimension-score-analysis',
      body: filters,
    },
    { apiName: this.apiName });

  getRespondentEntityCounts = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, EntityCountDto[]>({
      method: 'POST',
      url: '/api/wellbeing/survey-analysis/entity-count',
      body: filters,
    },
    { apiName: this.apiName });

  getRespondentsListWithCriteria = (filters: RespondentAnalysisFilterRequestDto) =>
    this.restService.request<any, PagedResultDto<RespondentsListWithCriteriaDto>>({
      method: 'POST',
      url: '/api/wellbeing/survey-analysis/respondents-list-with-criteria',
      body: filters,
    },
    { apiName: this.apiName });

  getSectionRespondentsIndex = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, SectionRespondentsIndexDto[]>({
      method: 'POST',
      url: '/api/wellbeing/survey-analysis/wellbeing-index-analysis',
      body: filters,
    },
    { apiName: this.apiName });

  getStatusCounts = (surveyKey: string) =>
    this.restService.request<any, StatusCountDto>({
      method: 'GET',
      url: '/api/wellbeing/survey-analysis/status-analysis',
      params: { surveyKey },
    },
    { apiName: this.apiName });

  getTotalCommentsCount = (filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, object>({
      method: 'POST',
      url: '/api/wellbeing/survey-analysis/total-comments-count-analysis',
      body: filters,
    },
    { apiName: this.apiName });

  getWellbeingExperienceByCriteria = (criteria: string, filters: AnalysisFilterRequestDto) =>
    this.restService.request<any, CriteriaAnalysisResultDto[]>({
      method: 'POST',
      url: `/api/wellbeing/survey-analysis/experiece-by-criteria/${criteria}`,
      body: filters,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
