import type { KeyNameDto } from '../../core/common/models';

export interface CreateSurveyTemplateDto {
  tenantKey: string;
  companyKey: string;
  name: KeyNameDto[];
}

export interface QuestionDto {
  key?: string;
  name: KeyNameDto[];
  sectionKey?: string;
  questionType?: string;
  isSubQuestion: boolean;
  isPredefined: boolean;
  sortOrder: number;
  comment?: string;
  dependsOnSectionKey?: string;
  dependsOnQuestionKey?: string;
  isManagerLevel: boolean;
  skippable?: boolean;
  conditionallySkippable?: boolean;
  skipIfConditionIsKeys: string[];
  resetField?: boolean;
  questionOptions: QuestionOptionDto[];
  otherAnswer?: string;
  otherAnswer1?: string;
  otherAnswer2?: string;
  answer: object;
  subQuestions: QuestionDto[];
  skipIfSubQuestionConditionIsKeys: string[];
  skipped?: boolean;
  customKey?: string;
  seperationType?: string;
  hasRange: boolean;
  max: number;
  min: number;
}

export interface QuestionOptionDto {
  key?: string;
  name: KeyNameDto[];
  otherAnswer: boolean;
  answer: string[];
}

export interface SectionDto {
  key?: string;
  name: KeyNameDto[];
  questions: QuestionDto[];
  isFactor: boolean;
  isSelected: boolean;
  isPredefined: boolean;
  sortOrder: number;
  isAllowNewQuestions: boolean;
}

export interface SurveyTemplateDto {
  key?: string;
  name: KeyNameDto[];
  isLocked: boolean;
  isPredefined: boolean;
  isDefault: boolean;
  isDraft: boolean;
  sections: SectionDto[];
}

export interface SurveyTemplateLookupDto {
  key?: string;
  name: KeyNameDto[];
  isDefault?: boolean;
  isPredefined?: boolean;
  isSelected?: boolean;
  isDraft: boolean;
}
