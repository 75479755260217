import { translateText } from "../../../features/survey/survey.component";

/**
 * Updates the HTML representation of an icon choice question.
 * @param options - The options for updating the question HTML.
 */
export function updateIconChoiceQuestionHTML(options: any, locale: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  var values = options.question.value;

  var commentArea = body.querySelector(".sd-question__comment-area");
  var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
  if (!commentBox)
    commentBox = body?.querySelector(".sd-comment.sd-input");

  body.className = "question-body";
  body.innerHTML = "";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-3 text-start";
  inputTitle.innerHTML = translateText("Select an option:", locale);

  body.appendChild(inputTitle); // Append the input title to the body

  var iconsContainer = document.createElement("div");
  iconsContainer.className = "flex flex-wrap sm:flex-nowrap justify-center gap-3";

  for (var i = 0; i < options.question.choices.length; i++) {
    const choice = options.question.choices[i];

    const itemContainer = document.createElement("div");
    itemContainer.className = "flex flex-col w-1/2 items-center justify-center gap-1 mb-4 icon-choice-container cursor-pointer";

    var iconContainer = document.createElement("div");
    iconContainer.className = "flex items-center w-full justify-center choice-icon px-8 py-4";

    var icon = document.createElement("i");
    icon.className = "fa-light fa-" + choice.imageLink;

    iconContainer.appendChild(icon);

    var iconText = document.createElement("label");
    iconText.className = "icon-choice-text";
    iconText.innerHTML = choice.text;

    itemContainer.appendChild(iconContainer);
    itemContainer.appendChild(iconText);

    iconsContainer.appendChild(itemContainer);

    itemContainer.onclick = function () {
      if (options.question.multiSelect) {
        if (itemContainer.className.includes("icon-choice-selected")) {
          // If the item is already selected, remove it from the values
          itemContainer.className = "flex flex-col w-1/2 items-center justify-center gap-1 mb-4 icon-choice-container cursor-pointer";
          values.splice(values.indexOf(choice.value), 1);
        } else {
          // If the item is not selected, add it to the values
          itemContainer.className = "flex flex-col w-1/2 items-center justify-center gap-1 mb-4 icon-choice-container cursor-pointer icon-choice-selected";

          if (!values || values.length === 0)
            values = [choice.value];
          else
            values.push(choice.value);
        }
      } else {
        var choices = iconsContainer.querySelectorAll('.icon-choice-container');

        for (var j = 0; j < choices.length; j++) {
          if (choices[j] === itemContainer) {
            // If the item is selected, update the values
            choices[j].className = "flex flex-col w-1/2 items-center justify-center gap-1 mb-4 icon-choice-container cursor-pointer icon-choice-selected";
            values = choice.value;
          } else {
            choices[j].className = "flex flex-col w-1/2 items-center justify-center gap-1 mb-4 icon-choice-container cursor-pointer";
          }
        }
      }

      options.question.value = values; // Update the question's value
    }
  }

  body.appendChild(iconsContainer); // Append the icons container to the body

  var max = 300;

  if (commentBox != null) {
    commentBox.setAttribute("maxLength", max);
    //Add character counter

    var counter = body?.querySelector('.comment-counter');
    if (!counter) {
      counter = document.createElement("span");
      counter.className = "text-end text-gray-500 text-sm comment-counter";
      counter.style.textAlign = "end";
      counter.innerHTML = commentBox.value.length + "/" + max;
      body.appendChild(counter);
    }
    body.insertBefore(commentBox, counter);

    commentBox.addEventListener("input", function () {
      // Update character count on input
      counter.innerHTML = commentBox.value.length + "/" + max;
    });
  }

  // Highlight the selected item if a value is already set
  if (options.question.value != null && options.question.value !== "") {
    var items = iconsContainer.querySelectorAll('.icon-choice-container');

    for (var i = 0; i < items.length; i++) {
      if (options.question.multiSelect) {
        if (options.question.value.includes(options.question.choices[i].value)) {
          items[i].className = "flex flex-col w-1/2 items-center justify-center gap-1 mb-4 icon-choice-container cursor-pointer icon-choice-selected";
        }
      } else {
        if (options.question.value === options.question.choices[i].value) {
          items[i].className = "flex flex-col w-1/2 items-center justify-center gap-1 mb-4 icon-choice-container cursor-pointer icon-choice-selected";
        }
      }
    }
  }
}

/**
 * Updates the HTML representation of an image picker question.
 * @param options - The options for updating the question HTML.
 */
export function updateImagePickerQuestionHTML(options: any, locale?: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  var values = options.question.value;

  var commentArea = body.querySelector(".sd-question__comment-area");
  var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
  if (!commentBox)
    commentBox = body?.querySelector(".sd-comment.sd-input");

  body.className = "question-body";
  body.innerHTML = "";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-5 text-start";

  if (options.question.multiSelect) {
    inputTitle.innerHTML = translateText("Check all that apply:", locale);
  } else {
    inputTitle.innerHTML = translateText("Select an option:", locale);
  }

  var answersContainer = document.createElement("div");
  answersContainer.className = "flex flex-wrap w-full";

  for (var i = 0; i < options.question.choices.length; i++) {
    const choice = options.question.choices[i];

    const itemContainer = document.createElement("div");
    itemContainer.className = "flex items-center gap-4 cursor-pointer image-choice-container w-full sm:w-1/2  px-4 pb-4";

    var image = document.createElement("img");
    image.className = "choice-image rounded-lg w-full max-h-40";
    image.style.objectFit = "contain";
    image.style.width = "180px";
    image.src = choice.imageLink;

    var answerLableContainer = document.createElement("div");
    answerLableContainer.className = "flex items-center gap-2";

    var answerButton = document.createElement("input");
    answerButton.className = "radio-button w-6 h-6";
    answerButton.type = options.question.multiSelect ? "checkbox" : "radio";
    answerButton.name = "image-choice-" + options.question.name;

    var answerLable = document.createElement("label");
    answerLable.className = "text-sm text-zen-primarydark";
    answerLable.innerHTML = choice.text;
    answerLable.htmlFor = answerButton.name;

    answerLableContainer.appendChild(answerButton);
    answerLableContainer.appendChild(answerLable);

    itemContainer.appendChild(image);
    itemContainer.appendChild(answerLableContainer);

    itemContainer.onclick = function () {
      if (options.question.multiSelect) {
        if (itemContainer.className.includes("image-choice-selected")) {
          // If the item is already selected, remove it from the values
          itemContainer.className = "flex items-center gap-4 cursor-pointer image-choice-container w-full sm:w-1/2  px-4 pb-4";
          values.splice(values.indexOf(choice.value), 1);

          var radioButton = itemContainer.querySelector('.radio-button') as HTMLInputElement;
          radioButton.checked = false;
        } else {
          // If the item is not selected, add it to the values
          itemContainer.className = "flex items-center gap-4 cursor-pointer image-choice-container w-full sm:w-1/2  px-4 pb-4 image-choice-selected";

          if (!values || values.length === 0)
            values = [choice.value];
          else
            values.push(choice.value);

          var radioButton = itemContainer.querySelector('.radio-button') as HTMLInputElement;
          radioButton.checked = true;
        }
      } else {
        var choices = answersContainer.querySelectorAll('.image-choice-container');

        for (var j = 0; j < choices.length; j++) {
          if (choices[j] === itemContainer) {
            // If the item is selected, update the values
            choices[j].className = "flex items-center gap-4 cursor-pointer image-choice-container w-full sm:w-1/2  px-4 pb-4 image-choice-selected";
            values = choice.value;

            var radioButton = choices[j].querySelector('.radio-button') as HTMLInputElement;
            radioButton.checked = true;
          } else {
            choices[j].className = "flex items-center gap-4 cursor-pointer image-choice-container w-full sm:w-1/2  px-4 pb-4";

            var radioButton = choices[j].querySelector('.radio-button') as HTMLInputElement;
            radioButton.checked = false;
          }
        }
      }

      options.question.value = values; // Update the question's value
    };

    answersContainer.appendChild(itemContainer);
  }

  body.appendChild(answersContainer);
  var max = 300;

  if (commentBox != null) {
    commentBox.setAttribute("maxLength", max);
    //Add character counter
    var counter = body?.querySelector('.comment-counter');
    if (!counter) {
      counter = document.createElement("span");
      counter.className = "text-end text-gray-500 text-sm comment-counter";
      counter.style.textAlign = "end";
      counter.innerHTML = commentBox.value.length + "/" + max;
      body.appendChild(counter);
    }
    body.insertBefore(commentBox, counter);
    commentBox.addEventListener("input", function () {
      // Update character count on input
      counter.innerHTML = commentBox.value.length + "/" + max;
    });
  }

  // Highlight the selected item if a value is already set
  if (options.question.value != null && options.question.value !== "") {
    var items = answersContainer.querySelectorAll('.image-choice-container');

    for (var i = 0; i < items.length; i++) {
      if (options.question.multiSelect) {
        if (options.question.value.includes(options.question.choices[i].value)) {
          items[i].className = "flex items-center gap-4 cursor-pointer image-choice-container w-full sm:w-1/2  px-4 pb-4 image-choice-selected";

          var radioButton = items[i].querySelector('.radio-button') as HTMLInputElement;
          radioButton.checked = true;
        }
      } else {
        if (options.question.value === options.question.choices[i].value) {
          items[i].className = "flex items-center gap-4 cursor-pointer image-choice-container w-full sm:w-1/2  px-4 pb-4 image-choice-selected";

          var radioButton = items[i].querySelector('.radio-button') as HTMLInputElement;
          radioButton.checked = true;
        }
      }
    }
  }
}
