import { Component, OnInit } from '@angular/core';
import { AnalysisBaseComponentComponent } from '../../ui/closed-survey/analysis-base-component/analysis-base-component.component';
import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { QuestionAnalysisService, SectionMeanDto } from '@ex/wellbeing/analysis';
import { WellbeingScalesService } from '../../utils/wellbeing-scales.service';
import { AnswerScaleDto, QuestionMeanDto } from '@ex/onboarding/analysis';

@Component({
  selector: 'app-question-analysis',
  templateUrl: './question-analysis.component.html',
  styleUrls: ['./question-analysis.component.scss']
})
export class QuestionAnalysisComponent extends AnalysisBaseComponentComponent implements OnInit {

  filters: AnalyticsFilter;

  filtersLoaded = new Subject();
  filtersLoaded$ = this.filtersLoaded.asObservable();

  dimensions = [];
  language;
  constructor(private analysisService: AnalyticsService, private questionAnalysisService: QuestionAnalysisService, public wellbeingScalesService: WellbeingScalesService) { super() }

  ngOnInit(): void {

    this.setupQuestionAnalysis();


    this.analysisService.filters$.subscribe(filters => {
      this.filters = filters;
      this.filtersLoaded.next(true);
    })
    this.language =   this.data.language;
  }


  setupQuestionAnalysis() {
    this.addSubscription(
      this.filtersLoaded$
        .pipe(
          switchMap(_ => {

            return this.questionAnalysisService.get({
              companyKey: this.filters.companyKey,
              surveyKey: this.filters.survey.key,
              tenantKey: this.filters.tenantKey,
              filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []]
            })

          })
        )
        .subscribe(dimensions => {
          this.dimensions = dimensions
        })
    )
  }

  returnAnswers(answers: AnswerScaleDto[]) {
    const r = answers.map(x => ({ percentage: x.percentage, color: this.wellbeingScalesService.resolveAnswerColor(x.value) }))
    return r;
  }

}
