import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Entity } from 'src/app/core/data-models/Entity';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SxBaseComponent } from '../../sx-base/sx-base.component';
import { CriteriaResolverService } from '@ex/module/survey-x/data-access/services/criteria-resolver.service';

@Component({
  selector: 'sx-filters-form',
  templateUrl: './sx-filters-form.component.html',
  styleUrls: ['./sx-filters-form.component.scss']
})
export class SxFiltersFormComponent extends SxBaseComponent implements OnInit {
  @Input() exclude: string[] = [];
  @Input() language;
  @Input() open = false;
  @Output() openChange = new EventEmitter();
  @Output() filtersItems = new EventEmitter<any>();
  @Input() get selectedFilters() {
    return this._selectedFilters;
  };
  set selectedFilters(filters: any) {
    let filtersBK = JSON.stringify(filters);
    this._selectedFilters = JSON.parse(filtersBK);
  }
  private _selectedFilters;
  Company: Entity;
  _essentialOb;

  constructor(private surveyCriteriaResolver: CriteriaResolverService) {
    super();
  }
  ngOnInit(): void {
    this.EssentialObject$.pipe(
      filter(obj => obj?.selectedEntity?.key != null),
    ).subscribe(obj => {
      this._essentialOb = obj;
      this.language = obj.language;
      if (obj.selectedEntity) {
        if (obj.companies) {
          this.Company = obj.companies.find(c => c.key == obj.selectedEntity.key);
        }
      }
    })
    if (this.exclude.length > 0) {
      this.exclude.forEach(x => {
        this.selectedFilters[x].exclude = true;
      })
    }
    if (this.selectedFilters.BusinessLevel.selectedValues.length == 0)
      this.selectedFilters.BusinessLevel.list = this.selectedFilters.BusinessLevel.list.map((f) => { f["checked"] = false; return f; });
    let nodes = this.selectedFilters.BusinessLevel.list.filter(x => x.parentKey == this._essentialOb.selectedEntity.key);
    this.BusinessLevelDataSource.data = nodes;
    this.BusinessLevelTreeControl.dataNodes = nodes;
    this.BusinessLevelTreeControl.collapseAll();
  }
  resetAll() {
    if (!this.selectedFilters) { return; }
    this.selectedFilters.Others.forEach((filterItem) => {
      filterItem.selectedValues = [];
    })
    this.resetBandLevels();
    this.filtersItems.emit(this.selectedFilters)
  }
  resetBandLevels() {
    this.selectedFilters.BusinessLevel.list = this.selectedFilters.BusinessLevel.list.map(f => { f["checked"] = false; return f; });
  }
  apply() {
    this.getBandLevelsSelectedValuses();
    this.filtersItems.emit(this.selectedFilters)
    this.openChange.emit(false);
  }
  resolver(criteria: string, translationKey: string) {
    return this.surveyCriteriaResolver.extract(criteria, translationKey, this.language);
  }
  ConfigSelectList(list: string[]) {
    return list.map(it => { return { key: it, name: it } });
  }
  //BAND LEVELS TREE 
  entitiesNavigation$ = new BehaviorSubject<Entity[]>([]);
  BusinessLevelDataSource = new MatTreeNestedDataSource<Entity>();
  BusinessLevelTreeControl = new NestedTreeControl<Entity>(node => this.selectedFilters.BusinessLevel.list.filter(x => x.parentKey == node.key));
  entityHierarchyFilters = []
  @ViewChild('tree') tree;
  hasChild = (_: number, node: Entity) => {
    return this.selectedFilters.BusinessLevel.list.filter(x => x.parentKey == node.key).length > 0;
  }
  handleClickAll(node, key) {
    node.checked = !node.checked
  }
  handleClickAllNested(parent, key) {
    let childrens = this.selectedFilters.BusinessLevel.list.filter(x => x.parentKey == key);
    childrens.forEach((child: any) => {
      if (!child.restricted)
        child.checked = parent.checked;
      if (this.selectedFilters.BusinessLevel.list?.filter(x => x.parentKey == child.key).length > 0)
        this.handleClickAllNested(child, child.key)
    })
  }
  getBandLevelsSelectedValuses() {
    this.selectedFilters.BusinessLevel.selectedValues = [];
    let selectedEntities = this.selectedFilters.BusinessLevel.list.filter(f => f["checked"]);
    selectedEntities.forEach(entity => {
      if (entity["checked"]) {
        let entityGroup = this.selectedFilters.BusinessLevel.selectedValues.find(s => s.key == entity.entityGroupKey);
        if (entityGroup)
          entityGroup.selectedKeys.push(entity.key)
        else
          this.selectedFilters.BusinessLevel.selectedValues.push({ key: entity.entityGroupKey, selectedKeys: [entity.key] })
      }
    })
  }
  //end
}
