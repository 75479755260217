import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { GlobalsService } from 'src/app/core/services/globals.service';

@Injectable({
  providedIn: null
})
export class WellbeingService {
  sEndpoint: string = 'https://apis.zenithr.net/api/';//'https://apis.zenithr.net/api/';//
  sDevEndpoint: string = 'http://localhost:52619/api/';
  fbFunctionURL: string = '';

  constructor(private db: AngularFireDatabase, public afAuth: AngularFireAuth, private http: HttpClient, private globals: GlobalsService) {
    this.sEndpoint = this.globals.sEndpoint;
    this.fbFunctionURL = this.globals.fbFunctionURL;

   }


  checkBalanceAndCreditWellbeing(request) {
    return this.http.post(this.fbFunctionURL + 'creditWellbeing', request);
  }


}
