import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { PlatformUser } from '../data-models/platform-user';
import { LogService } from './log.service';
import { Observable } from 'rxjs';
import { PlatformUserService } from './platform-user.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CrashReportingService } from './crash-reporting.service';
import { GeneralService } from './general.service';
import { LocalService } from './local.service';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isSignedIn: boolean = false;
  platformUserKey: string;
  user: Observable<PlatformUser>;
  fbFunctionURL: string = '';

  constructor(private db: AngularFireDatabase, public afAuth: AngularFireAuth, private logService: LogService,
    private platformUserService: PlatformUserService, private router: Router, private http: HttpClient,
    private crashReportingService: CrashReportingService, private localService: LocalService, private globals: GlobalsService) {
    this.fbFunctionURL = this.globals.fbFunctionURL;

  }

  sendResetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  setTimoutOnSignIn() {
    this.localService.setJsonValue('timeout', new Date().getTime().toFixed(0));

  }


  getTimoutAndEvaluate() {
    let current = Number(new Date().getTime().toFixed(0));
    let timout = this.localService.getJsonValue('timeout');
    if (timout) {
      let timeoutNum = Number(timout);

      //129600000 ---- 36 Hours
      // Test: 10000, 10 Second
      if ((current - timeoutNum) >= 129600000) {
        this.logout();
      }
    } else {
      this.logout();
    }
  }

  deleteUser(request) {
    return this.http.post(this.fbFunctionURL + 'deleteUser', request);
  }

  // Register Firebase User
  registerFirebaseUser(user: PlatformUser, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(user.email, password);
  }

  logout() {
    
    this.crashReportingService.submitLogCode(30);
    // this.afAuth
    //   // You only want unathenticated states:
    //   .filter((authState) => !authState)
    //   // You only want the first unathenticated state:
    //   .first()
    //   // You should now be able to navigate:
    //   .subscribe(() => );
    //   // The composed observable completes, so there's no need to unsubscribe.

    this.afAuth.signOut();
    this.router.navigate(['/login']);
  }




  // Signs User using FireAuth, then proceeds with fetching User Data Model
  signInUser(email, password): Promise<void> {
    this.logService.submitLog(0, 'AuthenticationService.signInUser()', 'Task Assigned', email + ',' + password);
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.isSignedIn = true;
        this.platformUserKey = result.user.displayName;
        this.logService.submitLog(4, 'AuthenticationService.signInUser()', 'Task Completed', { result });
      }).catch((error) => {
        this.isSignedIn = false;
        this.logService.submitLog(4, 'AuthenticationService.signInUser()', 'Task Failed', { error });
      })
  }





}
