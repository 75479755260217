<div class="flex flex-col Page h-screen" *ngIf="surveyModel">
  <app-survey-navbar  [language]="language" [surveyModel]="surveyModel" [survey]="survey" (languageChanged)="onlanguageChanged($event)"></app-survey-navbar>

  <div class="flex-grow">
    <div class="flex justify-center px-4">
      <app-survey-start-page *ngIf="stage == SurveyStages.Initial" [language]="language" [surveyModel]="surveyModel" [surveyData]="survey" (startSurvey)="startSurvey()" ></app-survey-start-page>

      <q-metrics *ngIf="stage == SurveyStages.CollectData" [colorTheme]="survey.colorTheme"
        class="flex flex-col max-w-7xl w-full justify-center rounded-xl" (proceed)="dataCollected();"
        [predefinedMetricsDone]="true" [language]="language" [resetDropdown]="resetDropdown"
        [title]="'txtEngagementSurvey' | translate:language:ModuleNames.Surveys" [metricsList]="surveyCriterias"
        [companyKey]="companyKey" [entities]="entities"></q-metrics>



      <div class="w-full sm:max-w-3xl pt-8" *ngIf="stage == SurveyStages.Started">
        <survey [model]="surveyModel"></survey>
      </div>


    </div>
  </div>

  <app-survey-actionsbar *ngIf="stage == SurveyStages.Started" [surveyModel]="surveyModel"
    [language]="language" (showConfirmSubmissionModal)="showConfirmSubmissionModal()"></app-survey-actionsbar>
</div>

<app-confirm-submission-modal *ngIf="isConfirmSubmissionModalOpen" (submitSurvey)="onSubmitSurvey($event)"
  [(open)]="isConfirmSubmissionModalOpen" [language]="language"></app-confirm-submission-modal>

<loader *ngIf="loader.showLoader"></loader>
