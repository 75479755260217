import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';

@Pipe({
  name: 'extractNameLanguage'
})
export class ExtractNameLanguagePipe implements PipeTransform {
  constructor(private languageService: LanguageService){}
  transform(value: string, language: string): any {
    return this.languageService.extractNameLanguage(value, language);
  }

}
