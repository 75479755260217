<div class="print-preview">
  <left-preview>
    <kendo-pdf-export #pdf>
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">

        <div class="page-template">
          <div class="rp-header">
            <img [src]="logoURL" alt="Client Logo" />
            <div class="rp-names">
              <h6 class="rp-created-by">{{'txtCreatedBy'|translate:language:ModuleNames.Shared}} <span>{{ currentUser.firstName }} {{ currentUser.lastName
                  }}</span></h6>
            </div>
          </div>

          <div class="rp-footer">
            <div class="rp-date">
              <p>
                <span><i class="fas fa-clock"></i>{{'txtGeneratedon'|translate:language:ModuleNames.Shared}} </span>
                {{ getDateTime() }}
              </p>
            </div>
            <div class="rp-copyrights">
              <img [src]="smallLogoUrl" alt="Zenithr" />
              <p>{{'txtPoweredby'|translate:language:ModuleNames.Shared}} <strong>ZENITHR</strong></p>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="page-template" style="overflow-x: hidden;">

        <div class="level" id="export-header" style="transform: translateX(-1000px);">
          <div class="level-item level-left">
            <img [src]="logoURL" alt="Client Logo" style="height: 40px;" />
          </div>
          <div class="level-item level-right">
            <h6 class="rp-created-by">{{'txtCreatedBy'|translate:language:ModuleNames.Shared}} <span>{{ currentUser.firstName }}
                {{ currentUser.lastName }}</span></h6>
          </div>
        </div>

        <div id="chartCanvas" class="rp-body"></div>

        <div class='level' id="export-footer" style="transform: translateX(-1000px);">
          <div class="level-item level-left">
            <i class="fas text-orange fa-clock"> </i>
            <p class="pb-3 px-2">{{'txtGeneratedon'|translate:language:ModuleNames.Shared}}</p>
            <p>{{ getDateTime()}}</p>
          </div>
          <div class="level-item level-right">
            <p class="pb-3 px-2" style="text-align: right; direction: rtl;"> {{"txtPoweredby"| translate:language:ModuleNames.Shared }} </p>
            <p class="px-2">ZENITHR</p>
            <img [src]="smallLogoUrl" alt="Zenithr" />
          </div>
        </div>

      </div>

    </kendo-pdf-export>
  </left-preview>

  <div class="print-preview-right">
    <div class="print-preview-right-header">
      <h4><i class="far fa-file-export"></i>{{'txtExportReport'|translate:language:ModuleNames.Shared}}</h4>
    </div>
    <div class="print-preview-right-body">
    </div>
    <div class="print-preview-right-footer">
      <button class="btn btn-success" type="button" (click)="export()"><i class="fas fa-download"></i>
        {{"btnExport"| translate:language:ModuleNames.Shared }}</button>
      <button class="btn btn-secondary" type="button" [mat-dialog-close]>{{"btn_cancel"| translate:language:ModuleNames.Shared }}</button>
    </div>
  </div>
</div>
