import { Injectable } from '@angular/core';
import { QuestionTypes } from '../../utils/question-types';
import { SjsTranslatePipe } from '../../utils/pipes/sjs-translate.pipe';



@Injectable({
  providedIn: 'root'
})
export class AnswersParsingService {
  currentRow: number = 0;

  language = "EN";

  constructor(private sjsTranslatePipe:SjsTranslatePipe) { }

  getAnswer(answer : any, question:any, language:any) {
    this.language = language;

    switch (question.type) {
      case QuestionTypes.Checkbox:
      case QuestionTypes.TagBox:
        return this.parseCheckboxAnswer(answer, question);


        case QuestionTypes.ImagePicker:
        case QuestionTypes.Dropdown:
          switch(question.isMultiSelect)
          {
            case true:
              return this.parseCheckboxAnswer(answer,question);

            case false:
              return this.parseSingleSelection(answer,question);

            case undefined:
              return answer;
          }
          break;

        case QuestionTypes.Matrix:
          switch(question.isMultiSelect)
          {
            case true:
              return this.parseMatrixMultipleSelection(answer,question);

            case false:
              return this.parseMatrixSingleSelection(answer,question);

            case undefined:
              return answer;
          }
          break;

        case QuestionTypes.FileUpload:
          return this.parseFileUpload(answer);

      case QuestionTypes.Boolean:
      case QuestionTypes.RadioGroup:
        return this.parseSingleSelection(answer,question);

      default:
        return answer;
    }
  }


  parseSingleSelection(answer: any, question:any) {
    let choices = question.choices;
    choices.forEach(choice => {
      if (answer == ((choice.value ?? choice))) {
        return this.sjsTranslatePipe.transform(choice.text ?? choice, this.language);
      }
    })
    return answer;
  }

  parseCheckboxAnswer(answer: string, question:any): string {
    if (!answer) {
      return '';
    }

    const parsed = JSON.parse(answer);
    const ans = question.choices
      .filter(choice => parsed.includes(choice.value ?? choice))
      .map(choice => this.sjsTranslatePipe.transform(choice.text ?? choice, this.language));

    return ans.length ? ans.join(', ') : answer;
  }

  parseMatrixSingleSelection(answer: any,question:any): string {
    const rows = question.rows;

    for (const [row, column] of Object.entries(answer)) {
      const currentRow = rows.find(r => r.rowName === row);

      if (currentRow) {
        const choice = currentRow.cols.find(c => c.value === column);

        if (choice) {
          return this.sjsTranslatePipe.transform(`${row} = ${choice.text ?? column}`, this.language);
        }
      }
    }

    return answer;
  }

  parseMatrixMultipleSelection(answer: string, question: any): string {
    if (!answer) {
      return '';
    }

    const parsed = JSON.parse(answer) as Record<string, string[]>;
    const rows = question.rows;

    const ans = Object.entries(parsed)
      .map(([row, columns]) => {
        const currentRow = rows.find(r => r.rowName === row);

        if (currentRow) {
          const choiceTexts = columns.map(col => {
            const choice = currentRow.cols.find(c => c.value === col);
            return choice ? this.sjsTranslatePipe.transform(choice.text ?? col, this.language) : col;
          });
          return `${row} = (${choiceTexts.join(', ')})`;
        } else {
          return `${row} = (${columns.join(', ')})`;
        }
      });

    return ans.length ? ans.join(', ') : answer;
  }

  parseFileUpload(answer: any) {
    return JSON.parse(answer).map(file => {

      return {
        name: file.name,
        content: file.content,
      }

    })
  }
}
