<div *ngIf="!changingLanguage" class="flex flex-col gap-5  w-full">
  <div class="flex flex-row flex-wrap">
    <div class="md:flex flex-row justify-between items-center w-full py-1">
      <div class="flex flex-col w-full gap-5">
        <div class="flex flex-row  w-full gap-1 md:pt-0 pt-2">
          <div class="flex flex-row items-center justify-center w-1/4">
            <div class="flex flex-col items-center justify-center w-full btn h-18 md:h-28 bg-white border-2 border-inset rounded"
              (click)="question.answer = 'P'"
              [ngClass]="question.answer == 'P'?'border-exp text-exp':'border-transparent'">
              <i class="fa-light fa-face-disappointed text-4xl md:text-5xl  text-center"></i>
              <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"> {{
                getConfidence('sub-questions-likeness-rating', 'P') }}</h5>
            </div>
          </div>
          <div class="flex flex-row items-center justify-center w-1/4">
            <div class="flex flex-col items-center justify-center w-full btn h-18 md:h-28 bg-white border-2 border-inset rounded"
              (click)="question.answer = 'F'"
              [ngClass]="question.answer == 'F'?'border-exp text-exp':'border-transparent'">
              <i class="fa-light fa-face-thinking text-4xl md:text-5xl  text-center"></i>
              <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"> {{
                getConfidence('sub-questions-likeness-rating', 'F') }}</h5>
            </div>
          </div>
          <div class="flex flex-row items-center justify-center w-1/4">
            <div class="flex flex-col items-center justify-center w-full btn h-18 md:h-28 bg-white border-2 border-inset rounded"
              (click)="question.answer = 'G'"
              [ngClass]="question.answer == 'G'?'border-exp text-exp':'border-transparent'">
              <i class="fal fa-smile text-4xl md:text-5xl  text-center"></i>
              <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"> {{
                getConfidence('sub-questions-likeness-rating', 'G') }}</h5>
            </div>
          </div>
          <div class="flex flex-row items-center justify-center w-1/4">
            <div class="flex flex-col items-center justify-center w-full btn h-18 md:h-28 bg-white border-2 border-inset rounded"
              (click)="question.answer = 'EX'"
              [ngClass]="question.answer == 'EX'?'border-exp text-exp':'border-transparent'">

              <i class="fal fa-smile-beam text-4xl md:text-5xl  text-center"></i>
              <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"> {{
                getConfidence('sub-questions-likeness-rating', 'EX') }}</h5>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>