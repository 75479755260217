<div class="chartTable">
    <table  mat-table [dataSource]="dataSource">
        <ng-container [matColumnDef]="column.field" *ngFor="let column of displayedColumns; let n = index">
            <th   mat-header-cell *matHeaderCellDef>
                <h5  [ngClass]="n == 0? 'text-left':'text-center'" >{{column.title }}</h5>
            </th>

            <td  [ngClass]="n == 0? 'text-start':'text-center'"   mat-cell *matCellDef=" let element; let i = index">
                <ng-container  *ngTemplateOutlet="mytemplate; context:{$implicit: element, column: column}">
                </ng-container >
                
            </td>
        </ng-container>

        <tr class="bg-transparent" mat-header-row *matHeaderRowDef="getRows(displayedColumns)"></tr>
        <tr class=" " mat-row *matRowDef="let row; columns: getRows(displayedColumns);"></tr>
    </table>
</div>
<div class="flexRowCentered bg-transparent rounded-md relative">
    <mat-paginator class="newPagination bg-transparent absolute left-0" #paginator   [hidePageSize]="true" [pageSizeOptions]="[5]" >
    </mat-paginator>
    <div class="innerPagination " >
        <div (click)="goToChange(0)" class="buttonContainer" *ngIf="pageIndex > 2" >
            <a  class="btn  orange-grd"> <i class="fal fa-angle-double-left  "></i></a>
        </div>
        <div (click)="goToChange(pageIndex - 1)"  *ngIf="pageIndex > 1" >
            <a   class="btn orange-grd"> <i class="fal fa-angle-left "></i></a>
        </div>
        <div class="centeredButtons"    *ngFor="let pageNumber of pageNumbers; let i = index">

            <a *ngIf="i == pageIndex ||i+1 == pageIndex ||i-1 == pageIndex  " (click)=" goToChange(i)" [ngClass]="i == pageIndex? 'orange-grd':'cancel'" class="btn mx-1">{{pageNumber}} </a>
          
        </div>
        <div (click)="goToChange(pageIndex + 1)" class="buttonContainer" *ngIf="pageIndex < pageNumbers?.length-2 " >
            <a  class="btn orange-grd"> <i class="fal fa-angle-right  "></i></a>
        </div>
        <div (click)="goToChange(pageNumbers?.length-1)" class="buttonContainer" *ngIf="pageIndex < pageNumbers?.length -3" >
            <a class="btn orange-grd">> <i class="fal fa-angle-double-right  "></i></a>
        </div>
        
       
    </div>
</div>