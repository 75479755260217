<div [style.direction]="dir" *ngIf="open" class="w-screen h-screen bg-zen bg-opacity-50 flex flex-row items-center justify-center fixed top-0 left-0 z-50 py-10">
  <div class="max-w-max w-full flex flex-col bg-white bg-opacity-90 py-6 rounded">
      <div class="flex flex-row items-start justify-start text-center px-8">
          <ng-content select="[header]"></ng-content>
      </div>
      <div class="flex flex-col gap-1 px-8 innerListScroll">
          <ng-content select="[body]"></ng-content>
      </div>
      <div class="flex flex-row justify-between items-center px-8">
          <ng-content select="[footer]"></ng-content>
      </div>
  </div>
</div>
