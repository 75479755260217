import { translateText } from "../../../features/survey/survey.component";

export function filterRange(constrains: any, min: number, max: number) {
  const updatedConstraints = constrains.map(constraint => {
    if (constraint.min < min) {
      constraint.min = min;
    }
    if (constraint.max > max) {
      constraint.max = max;
    }
    return constraint;
  });

  return updatedConstraints;
}
/////////////////////////////////////////////////////////////////////////////////////
export function getUniqueConstraints(constrains: any) {
  const uniqueConstraints = [];

  for (let i = 0; i < constrains.length; i++) {
    const foundConstraint = uniqueConstraints.find(c => c.min === constrains[i].min && c.max === constrains[i].max);
    if (!foundConstraint) {
      uniqueConstraints.push(constrains[i]);
    }
  }

  return uniqueConstraints;
}
/////////////////////////////////////////////////////////////////////////////////////
export function fillMissingConstraints(constrains: any, min: number, max: number) {
  const defaultColor = "#EFF1F6";
  const defaultLabel = "";

  const filledConstraints = [];
  let currentMin = null;
  let currentMax = null;
  let currentText = null;
  let currentColor = null;

  for (let i = min; i <= max; i++) {
    const foundConstraint = constrains.find(c => i >= c.min && i <= c.max);

    if (foundConstraint) {
      if (currentMin !== null && currentMax !== null) {
        filledConstraints.push({ min: currentMin, max: currentMax, text: currentText, color: currentColor });
        currentMin = null;
        currentMax = null;
        currentText = null;
        currentColor = null;
      }
      filledConstraints.push(foundConstraint);
    } else {
      if (currentMin === null) {
        currentMin = i;
        currentText = defaultLabel;
        currentColor = defaultColor;
      }
      currentMax = i;
    }
  }

  if (currentMin !== null && currentMax !== null) {
    filledConstraints.push({ min: currentMin, max: currentMax, text: currentText, color: currentColor });
  }

  return filledConstraints;
}
/////////////////////////////////////////////////////////////////////////////////////
export function updateRatingScaleQuestionHTML(options: any, locale:any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;
  body.innerHTML = "";
  body.className = "question-body";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-5 text-start";
  inputTitle.innerHTML = translateText('Select an option:',locale);

  body.appendChild(inputTitle);

  var constrains = [];
  // if (options.question['ratingLabels'] != null && options.question['ratingLabels'] != "") {
  //   constrains = JSON.parse(options.question['ratingLabels']) || [];
  // } else {
  //   constrains = [];
  // }

  constrains = options.question['ratingLabels'] || [];


  var filtered = filterRange(constrains, options.question['rateMin'], options.question['rateMax']);
  var filled = fillMissingConstraints(filtered, options.question['rateMin'], options.question['rateMax']);
  var unique = getUniqueConstraints(filled);

  var container = document.createElement("div");
  container.className = "flex w-full gap-2 justify-center";

  for (var i = 0; i < unique.length; i++) {
    var constrain = unique[i];

    var constrainContainer = document.createElement("div");
    constrainContainer.className = "flex flex-col items-center gap-2";

    var itemsDiv = document.createElement("div");
    itemsDiv.className = "flex w-full gap-2 justify-center";

    for (var j = constrain.min; j <= constrain.max; j++) {
      var item = document.createElement("button");
      item.className = "px-1 w-7 sm:w-12 md:w-16 py-3 nps-item";
      item.innerHTML = j.toString();
      item.style.backgroundColor = constrain.color;
      item.onclick = function () {
        var items = container.querySelectorAll('button');

        for (var m = 0; m < items.length; m++) {
          if (items[m] == this) {
            items[m].classList.add("nps-item-selected");
            options.question.value = items[m].innerHTML;
          } else {
            items[m].classList.remove("nps-item-selected");
          }
        }
      }

      itemsDiv.appendChild(item);
    }



    if(locale == "en" || locale == "EN")
    {
      locale = "default"
    }

    var constrainText = document.createElement("label");
    constrainText.className = "label-gray max-w-[62px] whitespace-normal";

    constrainText.innerHTML = constrain.text.default? constrain.text[locale] : constrain.text;

    constrainContainer.appendChild(itemsDiv);
    constrainContainer.appendChild(constrainText);

    container.appendChild(constrainContainer);
    body.appendChild(container);
  }

  if (options.question.value != null && options.question.value != "") {
    var items = container.querySelectorAll('button');

    for (var m = 0; m < items.length; m++) {
      if (items[m].innerHTML == options.question.value) {
        items[m].classList.add("nps-item-selected");
      } else {
        items[m].classList.remove("nps-item-selected");
      }
    }
  }
}
