import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sjsTranslate'
})
export class SjsTranslatePipe implements PipeTransform {

  transform(value: any, language: string) {
    console.log((value instanceof Object));

    if ((value instanceof Object ||  (typeof (value) == 'string' && value.indexOf("{") !== -1))) {
      let parsedValue = typeof (value) == 'object' ? value : JSON.parse(value)
      if (typeof parsedValue == 'string') {
        return parsedValue;
      }
      else {
        return parsedValue[language.toLowerCase()] || parsedValue['default'] || parsedValue[Object.keys(parsedValue)[0]];
      }
    }
    else {
      return value || '-';
    }
  }

}
