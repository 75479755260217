<!-- Scale -->
<ng-container>
  <div class="flex flex-col items-stretch h-20 relative z-10 " sticky (sticky)="container.active = $event">
    <div #container [ngClass]="{'shadow-2x2 backdrop-filter backdrop-blur-3xl bg-white bg-opacity-10':container.active}"
      class="flex flex-row items-center justify-center h-20  gap-16 uppercase z-50">
      <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
        <span class="w-4 h-3 rounded-sm bg-zen-red"></span>
        <span>{{ (analysis? "txtStronglyDisagree":Scales.VeryPoorExperience) | translate:language:ModuleNames.Wellbeing }}</span>
      </div>
      <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
        <span class="w-4 h-3 rounded-sm bg-zen-orange"></span>
        <span>{{(analysis? "txtDisagree":Scales.PoorExperience) | translate:language:ModuleNames.Wellbeing}}</span>
      </div>
      <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
        <span class="w-4 h-3 rounded-sm bg-zen-yellow"></span>
        <span>{{ (analysis? "txtNeutral":Scales.FairExperience)| translate:language:ModuleNames.Wellbeing }}</span>
      </div>
      <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
        <span class="w-4 h-3 rounded-sm bg-zen-lightgreen"></span>
        <span>{{ (analysis? "txtAgree":Scales.GoodExperience)| translate:language:ModuleNames.Wellbeing }}</span>
      </div>
      <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
        <span class="w-4 h-3 rounded-sm bg-zen-green"></span>
        <span>{{ (analysis? "txtStronglyAgree":Scales.VeryGoodExperience)| translate:language:ModuleNames.Wellbeing }}</span>
      </div>
    </div>
  </div>
</ng-container>
