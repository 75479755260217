<div class="flex flex-col gap-10">
    <h1 class="text-zen text-2xl font-medium tracking-tight	">{{ "Applied Filters" | translate:language:ModuleNames.SurveyX }}</h1>
    <div class="flex flex-col gap-6">
        <div *ngFor="let category of groupedFilters" class="flex flex-col gap-6">
            <ng-container *ngIf="anyExistsValues(category)">
                <h5 class="text-zen-thomas text-xs leading-5 uppercase tracking-wide">
                    {{ category.value | translate:language:ModuleNames.SurveyX}} </h5>
                <ng-container *ngFor="let filter of category.items">
                    <div *ngIf="filter.values.length>0" class="flex flex-row">
                        <div class="flex flex-col w-1/4">
                            <h5 class="text-zen text-xs leading-5 uppercase tracking-wide">
                                {{filter.key | translate:language:ModuleNames.SurveyX}} </h5>
                        </div>
                        <div class=" flex flex-wrap w-3/4  gap-2">
                            <div *ngFor="let item of filter.values" class="bg-zen bg-opacity-10 text-zen font-medium px-2 py-0.5 text-xs rounded">
                                {{item | extract:language}}
                            </div>
                        </div>
                    </div>
                </ng-container>
                <hr />
            </ng-container>
        </div>
    </div>
</div>