import { Injectable } from '@angular/core';

@Injectable({
  providedIn: null
})
export class ChartColorsGenerationService {

  constructor() { }

  generateColorsByNumberAndHex(hex1, hex2, number) {
    if (number <= 2) {
      return [hex1, hex2]
    }
    let colorArray: string[] = [];

    // Generate colors for the first half
    colorArray = colorArray.concat(this.generateNegativeColorsByHex(hex1, Math.ceil(number / 2)))
    colorArray = colorArray.concat(this.generatePositiveColorsByHex(hex2, Math.floor(number / 2)))
    return colorArray;

  }

  generateNegativeColorsByHex(hex, length) {
    if (length == 1)
      return [hex]
    let colorArray: string[] = [];
    const opacityStep = 50 / (length - 1); // Total range is 60% (100 - 40)

    // Generate colors for the first half
    for (let i = 0; i < length; i++) {
      const opacity = 40 + i * opacityStep;
      const alpha = Math.round(opacity * 255 / 100);
      const hexColor = `${hex}${alpha.toString(16).padStart(2, '0')}`;
      colorArray.push(hexColor);
    }
    return colorArray;
  }


  generatePositiveColorsByHex(hex, length) {
    if (length == 1)
      return [hex]
    let colorArray: string[] = [];
    const opacityStep = 50 / (length - 1); // Total range is 60% (100 - 40)

    // Generate colors for the first half
    for (let i = 0; i < length; i++) {
      const opacity = 100 - i * opacityStep;
      const alpha = Math.round(opacity * 255 / 100);
      const hexColor = `${hex}${alpha.toString(16).padStart(2, '0')}`;
      colorArray.push(hexColor);
    }
    return colorArray;
  }

}
