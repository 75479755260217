import { Injectable } from '@angular/core';
import { GeneralService } from '@ex/module/core/services/general.service';
import { KeyName } from '@ex/survey-x/index';
import { AnalysisFilterKeys } from './analytics.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { TranslatePipe } from '@ex/module/shared/pipes/translate.pipe';

@Injectable({
  providedIn: null
})
export class SurveyCriteriaService {
  constructor(private generalService: GeneralService, private translatePipe:TranslatePipe) { }

  process(criterias: { key: string, name: KeyName[], isCustom: boolean }[], languages) {
    const criteriaList = [];
    for (let criteria of criterias) {

      switch (criteria.key) {

        case AnalysisFilterKeys.DateOfBirth:

          const ageGroups = this.generalService.ageGroups_new;
          const generation = this.generalService.generations_new

          criteriaList.push({
            key: AnalysisFilterKeys.AgeGroups,
            name: languages.map(x => {
              return {
                key: x,
                name: this.translatePipe.transform(AnalysisFilterKeys.AgeGroups, x, ModuleName.SurveyX)
              }
            }),
            options: ageGroups
          });
          criteriaList.push({
            key: AnalysisFilterKeys.Generation,
            name: languages.map(x => {
              return {
                key: x,
                name: this.translatePipe.transform(AnalysisFilterKeys.Generation, x, ModuleName.SurveyX)
              }
            }),
            options: generation
          });
          break;

        case AnalysisFilterKeys.DateOfJoining:

          const tenureGroups = this.generalService.tenureGroups_new;

          criteriaList.push({
            key: AnalysisFilterKeys.TenureGroups,
            name: languages.map(x => {
              return {
                key: x,
                name: this.translatePipe.transform(AnalysisFilterKeys.TenureGroups, x, ModuleName.SurveyX)
              }
            }),
            options: tenureGroups
          });
          break;

        default:
          criteriaList.push(criteria);
      }

    }

    return criteriaList;
  }
}
