import { Direction } from '@angular/cdk/bidi';
import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from '@ex/module/core/services/local.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { Location } from '@angular/common';
import { ChildrenAnimation, fadeUpDown } from 'src/app/shared/animations/allAnimations';
import { RespondentsService } from '../../data-access/services/respondents.service';
import { SurveysService } from '../../data-access/services/surveys.service';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { FormResponseService, FormData } from '@ex/survey-x/index';
import { CriteriaType } from '@ex/module/shared/enums/CriteriaType.enum';
import { EssentialObject } from '@ex/module/core/services/essential-object.service';

@Component({
  selector: 'app-respondent-answers-view',
  templateUrl: './respondent-answers-view.component.html',
  styleUrls: ['./respondent-answers-view.component.scss'],
  animations: [fadeUpDown, ChildrenAnimation]
})

export class RespondentAnswersViewComponent extends BaseComponent implements OnInit, AfterViewChecked {

  firstDivWidth: any
  essentialObject: EssentialObject;
  criterias: any[];
  ngAfterViewChecked() {
    this.filteredGroups.forEach((item: any) => {
      const firstDiv = document.getElementById('firstDiv_' + item.groupText);
      const secondDiv = document.getElementById('secondDiv_' + item.groupText);

      if (firstDiv && secondDiv) {
        const firstDivWidth = firstDiv.clientWidth;
        secondDiv.style.width = firstDivWidth + 'px';
      }

    })
  }

  //   getFirstDivWidth(): number {
  //     const firstDiv = document.getElementById('firstDiv');
  //
  //     const firstDivWidth = firstDiv.clientWidth;
  // return firstDivWidth;

  //   }

  @Input() selectedKey: any;
  @Input() selectedRespondet: any;

  direction: Direction = 'ltr';
  textPosition = "left";
  formLocale: any;
  systemLocale: any;

  language = 'EN';
  ModuleNames = ModuleName;

  formId: any;
  respondentId: any;
  respondent: any;

  currentTenant: any;
  currentTenantKey: any;

  currentUser: any;
  currentUserName: any;

  form: any;
  formAnswers: any;
  data: FormData;
  answers: any = [];
  questions: any = [];
  sections: any = []
  activeRating: any = '';
  pageType: any;

  dLanguage: any;

  filteredGroups: { groupText: string, items: any[] }[] = [];

  tenantKey: string
  companyKey: string

  settingsLoaded$ = new Subject();

  constructor(private surveysService: SurveysService
    , private respondentsService: RespondentsService
    , private router: Router
    , private route: ActivatedRoute
    , public location: Location
    , private localService: LocalService
    , private formResponseService: FormResponseService) {
    super();

    this.respondentId = this.route.snapshot.paramMap.get('respondentid');



  }

  ngOnInit() {
    this.GlobalService.innerRoute = true;

    this.fetchData();

    const sub = this.EssentialObject$.pipe(
      filter(x => x.selectedEntity?.key != null)
    ).subscribe(obj => {
      this.essentialObject = obj
      this.language = obj.language;
      this.tenantKey = obj.tenant.key;
      this.companyKey = obj.selectedEntity.key

      this.settingsLoaded$.next();
    })

    this.addSubscription(sub);

  }


  filterQuestionOfSection(section) {
    return this.questions.filter(x => x.section == section);
  }
  getPreviousSectionCount(previousSection, sections) {
    let count = 0;
    for (let i = 0; i <= previousSection; i++) {
      count += this.filterQuestionOfSection(i).length;
    }
    return count;

    // let count = this.questions.filter(x => x.section == previousSection).length +
    // this.questions.filter(x => x.section == previousSection - 1).length +
    // this.questions.filter(x => x.section == previousSection - 2).length +
    // this.questions.filter(x => x.section == previousSection - 3).length +
    // this.questions.filter(x => x.section == previousSection - 4).length  ;
    // return count
  }

  async fetchRespondent() {
    this.respondent = await this.respondentsService.getRespondent(this.respondentId);
    if (!this.respondent.name) {
      this.respondent.name = "N/A"
    }
  }
  fetchData() {

    const sub = this.settingsLoaded$.pipe(
      switchMap(_ => {
        return this.formResponseService.getAnswers(this.respondentId, this.tenantKey, this.companyKey);
      })
    ).subscribe((response: FormData) => {
      this.data = response;
      this.criterias = this.data.customProperties.filter(x => this.data.selectedCriterias.map(x => x.key).includes(x.key)).map((x: any) => {
        let val = x.value;
        x = this.data.selectedCriterias.find(m => m.key == x.key)

        if (!x)
          return;

        if (x?.options.length && ( x.type == "DROPDOWN" || !x.type) ) {
          let foundValue = x.options.find(o => o.key == val).name;
          x.value = this.ExtractPipe.transform(foundValue, this.language);
        }
        else x.value = val;

        return x;
      })
      // this.data.selectedCriterias.map(x => {
      //   if (x.type == CriteriaType.DropDown || !x.type) {
      //     x.value = this.data.customProperties.find(m => x.key == m.key).value;
      //     let foundValue = x.options.find(o => o.key == x.value).name;
      //     x.value = this.ExtractPipe.transform(foundValue, this.language);
      //     return x;
      //   }
      //   x.value = this.data.customProperties.find(m => x.key == m.key).value
      //   return x;
      // })
      this.sections = this.data.sections;
      this.questions = this.data.questions;
      this.answers = this.data.answers;
      this.formLocale = this.data.formLocale;
      this.systemLocale = this.data.systemLocale;
    })

    this.addSubscription(sub);

    // this.data = await this.respondentsService.getRespondentAnswers(this.formId, this.respondentId, this.currentTenantKey);
    // console.log(this.data)
  }



  exportAnswers() {

  }

  onBack() {
  }
  label: any;
  setActiveRate(rateValue: any, questionKey: string, question: any) {
    //
    this.label = "";
    const response = this.answers.find(answer => answer?.question === questionKey);
    this.activeRating = response?.answer[0];
    this.label = this.getLabel(rateValue.value, question);
    return this.activeRating;
  }
  getLabel(rateValue: any, question: any) {

    let label = question.ratingLabels?.find(x => x.min <= this.isNumber(rateValue) && x.max >= this.isNumber(rateValue))?.text
    if (label)
      return label;
    else
      return
  }
  getColor(rateValue: any, question: any) {
    let color = question.ratingLabels?.find(x => x.min <= this.isNumber(rateValue) && x.max >= this.isNumber(rateValue))?.color
    if (color)
      return color;
    else
      return
  }
  filterLabels(question: any) {

    this.filteredGroups = question.ratingLabels.map(x => ({
      groupText: this.getTranslatedTitle(x.text),
      groupColor: x.color,
      groupMin: x.min,
      groupMax: x.max,
      questionKey: question.key,
      items: question.rateValues.filter(item => {
        const value = parseInt(item.value, 10);
        return value >= x.min && value <= x.max;
      })
    }));
  }
  isNumber(res: string) {
    return Number(res);
  }

  setActiveIcon(choice: any, question: string, multi?: boolean) {

    const previousAnswer = this.answers?.find(answer => answer.question === question);
    this.activeRating = previousAnswer?.answer[0];
    let checkAvailable;
    if (!multi)
      checkAvailable = this.activeRating === choice.key;
    else {
      let sk = JSON.parse(previousAnswer?.answer[0]);
      checkAvailable = sk === choice.key || sk?.find(x => x == choice.key);
    }
    return checkAvailable;
  }
  setActiveImage(choice: string, ans: any) {
    const previousAnswer = this.answers.find(answer => answer.question === ans);
    if (previousAnswer) {
      const checkAvailable = previousAnswer.answer[0] === choice
      if (checkAvailable) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  setActiveImages(choice: string, ans: any) {

    const previousAnswer = this.answers.find(answer => answer.question === ans);
    const sk = JSON.parse(previousAnswer?.answer[0]);
    console.log('sk', sk);
    const checkAvailable = sk === choice || sk.find(x => x == choice);
    if (checkAvailable) {
      return true;
    } else {
      return false;
    }
  }



  isChoiceDisabled(questionKey: string, choiceKey: string): boolean {
    const previousAnswer = this.answers.find(answer => answer.question === questionKey);

    if (previousAnswer) {
      const correctAns = previousAnswer?.answer[0]
      // const correctAns = JSON.parse(previousAnswer?.answer[0]);
      // console.log(correctAns);

      if (correctAns.includes(choiceKey)) {
        return false;
      }
      else {
        return true
      }
    }
    return true
  }

  getSortedChoices(question: any): any[] {
    const previousAnswer = this.answers.find(answer => answer.question === question.key);
    const userAnswer = previousAnswer?.answer[0];
    if (userAnswer) {
      const correctAns = JSON.parse(userAnswer);


      const sortedChoices = question.choices.slice().sort((a, b) => {
        const aIndex = correctAns.indexOf(a.key);
        const bIndex = correctAns.indexOf(b.key);

        return aIndex - bIndex;
      });

      return sortedChoices;
    }
    return null
  }


  validateAns(rowKey: any, qKey: any, column: any) {
    //
    const previousAnswer = this.answers.find(answer => answer.question === qKey);
    const correctAns = previousAnswer ? JSON.parse(previousAnswer.answer[0]) : null;
    if (correctAns) {
      for (const ans in correctAns) {
        if (ans == rowKey) {
          if (correctAns.hasOwnProperty(ans)) {
            const columns = correctAns[ans];

            if (Array.isArray(columns)) {
              // Handle "Matrix (Multiple with comment)" question type
              if (columns.includes(column) && correctAns[rowKey].includes(column)) {
                const checkAns = column;
                return checkAns ? 'fa-solid fa-square-check' : '';
              }
            }
            else {
              // Handle "matrix (single)" question type
              const checkAns = correctAns[rowKey];
              return checkAns === column ? true : false;
            }
          }
        }
      }
    }
    return '';
  }

  getTextAreaVal(key: string) {
    const previousAnswer = this.answers.find(answer => answer.question === key);
    if (previousAnswer) {
      return previousAnswer.answer;
    } else {
      return "";
    }
  }

  getDate(qKey: string, index: string) {
    const previousAnswer = this.answers.find(answer => answer.question === qKey);
    const ans = JSON.parse(previousAnswer?.answer[0]);
    if (previousAnswer) {
      if (index === 'index1') {
        return ans[0];
      } else {
        return ans[1];
      }
    } else {
      return '';
    }
  }

  isValueChecked(questionKey: string, choiceKey: string): boolean {
    const previousAnswer = this.answers.find(answer => answer.question === questionKey);

    if (previousAnswer) {
      return previousAnswer.answer.includes(choiceKey);
    }
    return false;
  }

  getValue(questionKey: string, fieldName: string) {
    const previousAnswer = this.answers.find(answer => answer.question === questionKey);
    const ansData = JSON.parse(previousAnswer.answer);
    const matchedKey = Object.keys(ansData).find(key => key === fieldName);
    // console.log(matchedKey, matchedKey)
    if (matchedKey) {
      return ansData[matchedKey];
    }
    return '';
  }

  getFileDetails(key: string) {
    const previousAnswer = this.answers.find(answer => answer.question === key);
    if (!previousAnswer)
      return;
    const correctAns = JSON.parse(previousAnswer.answer);
    return correctAns[0] || null;
  }

  downloadImage(base64Url: string, fileName: string) {
    let name;
    const byteString = atob(base64Url.split(',')[1]);
    const mimeString = base64Url.split(',')[0].split(':')[1].split(';')[0];
    // console.log(mimeString);
    if (mimeString === 'application/pdf') {
      name = 'file.pdf';
    } else {
      name = 'image.png';
    }
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeString });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = name;
    anchor.click();
  }


  getTranslatedTitle(title: any) {
    if (typeof title == 'string') {

      return title;

    }
    else {
      return title[this.dLanguage]
    }
  }


  openReport = false;

  export() {
    this.openReport = true;
  }

  groupedSection(rateValues: any) {
    rateValues = rateValues.map(x => {
      x.key = parseInt(x.key)
      x.value = parseInt(x.value)
      return x;
    })
    let values = [{color:'#ff4530', rates: [], name: 'Unlikely', min: 0, max: 6 }, {color:'#FF9600', rates: [], name: 'Neutral', min: 7, max: 8 }, {color:'#3eb75b', rates: [], name: 'Likely', min: 9, max: 10 }]
    for (let index = 0; index < values.length; index++) {
      values[index].rates = rateValues.filter(x => x.key >= values[index].min && x.key <= values[index].max)

    }
    return values;
  }


  returnQuestionAnswer(questionKey: string) {
    return this.answers.find(answer => answer?.question === questionKey).answer[0];
  }
}
