import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ticket } from 'src/app/shared/help-center/models/ticket';
import { UploadLink } from 'src/app/shared/help-center/models/uploadLink';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  URL = 'https://help.apis.zenithr.net';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;'
    })
  }
  constructor(private httpClient: HttpClient) { }
  createTicket(ticket: Ticket) {
    return this.httpClient.post('https://help.apis.zenithr.net/api/customer-support/tickets/create', ticket, this.httpOptions)
  }
  getUploadLink(uploadLink: UploadLink) {
    return this.httpClient.post(this.URL + 'customer-support/tickets/create', uploadLink, this.httpOptions)
  }
}
