import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'analysis-question-template',
  templateUrl: './analysis-question.component.html',
  styleUrls: ['./analysis-question.component.scss']
})
export class AnalysisQuestionComponent implements OnInit {
  constructor() { }
  @Input() answers: { percentage: number, color: string };
  @Input() name;
  @Input() score: number;

  ngOnInit(): void {
  }

}
