import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlatformUser } from '../../core/data-models/platform-user';
import { Tenant } from '../../core/data-models/tenant';
import { LanguageService } from '../../core/services/language.service';
import { UnitBalanceService } from '../../core/services/unit-balance.service';
import { ModuleName } from '../enums/ModuleName';
import { TranslatePipe } from '../pipes/translate.pipe';
import { inOutAnimation } from '../animations/allAnimations';
import { ZenSnackbarService, ZenSnackBarTypes } from '../Theme/services/zen-snackbar.service';

interface ProductRequestInfo {
  image?: string,
  name: string,
  key: string,
  balance: string,
  allUnitsName: string
}
@Component({
  selector: 'app-credit-balance-dialog',
  templateUrl: './credit-balance-dialog.component.html',
  styleUrls: ['./credit-balance-dialog.component.scss'],
  animations: [inOutAnimation]
})
export class CreditBalanceDialogComponent implements OnInit {
  units: number = 0;
  allUnits = {
    ENGAGEMENT: '',
    HAPPINESS: '',
    ASSESSMENT: '',
    EXIT: '',
    ONBOARDING: '',
    ASSESSMENT180: '',
    WELLBEING: '',
  };
  ModuleNames = ModuleName
  currentBalance;
  surveyRequires: number;
  creditType: string;
  entity: any;
  tenantKey: string;
  language: string;
  multiplier: number = 1;
  equivelantSurveys: number = 0;
  user: PlatformUser;
  tenant: Tenant;
  submittedFrom: string;
  requestType: string;
  // txtAccountOverview: string;
  // btnRequestUnits: string;
  // txtForSurveyName: string;
  requestSent: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<CreditBalanceDialogComponent>,
    private zenSnackBarService: ZenSnackbarService,
    private unitBalanceService: UnitBalanceService, private db: AngularFireDatabase, private languageService: LanguageService, private translationPipe: TranslatePipe) { }

  ngOnInit(): void {
    this.creditType = this.data.creditType;
    this.currentBalance = this.data.currentBalance;
    this.surveyRequires = this.data.surveyRequires;
    this.user = this.data.user;
    this.entity = this.data.entity;
    this.tenantKey = this.data.tenantKey;
    this.tenant = this.data.tenant;
    this.language = this.data.language;
    this.submittedFrom = this.data.submittedFrom;
    this.requestType = this.data.requestType;


    if (!this.requestType)
      this.requestType = '';

    if (!this.submittedFrom)
      this.submittedFrom = 'OTHER';
    this.unitBalanceService.fetchSurveys().subscribe((surveys: any) => {
      if (this.creditType == 'EXIT')
        this.multiplier = (surveys.Exit);
      if (this.creditType == 'ENGAGEMENT')
        this.multiplier = (surveys.Engagement);
      if (this.creditType == 'HAPPINESS')
        this.multiplier = (surveys.Happiness);
      if (this.creditType == 'ASSESSMENT')
        this.multiplier = (surveys.Assessment360);

      this.units = this.data.minUnits;
      this.unitsChanged('');
      if (!this.units)
        this.units = 0;
    });
    this.setUpLanguagePreference();
  }
  setUpLanguagePreference() {
    this.setUpTextFields();
  }

  productTable: ProductRequestInfo[] = [
    { key: 'Onboarding', name: 'txtOnBoarding', image: '/assets/img/sub/onboarding.svg', balance: 'onboardingBalance', allUnitsName: 'ONBOARDING' },
    { key: 'Engagement', name: 'txtEngagement', image: '/assets/img/sub/Engagement.svg', balance: 'engagementBalance', allUnitsName: 'ENGAGEMENT' },
    { key: 'Happiness', name: 'txtEmployeeHappiness', image: '/assets/img/sub/Happiness.svg', balance: 'happinessBalance', allUnitsName: 'HAPPINESS' },
    { key: 'Wellbeing', name: 'txtWellbeing', image: '/assets/img/sub/Wellbeing.svg', balance: 'wellbeingBalance', allUnitsName: 'WELLBEING' },
    { key: 'Assessment', name: 'txt360DegreeFeedback', image: '/assets/img/sub/360.svg', balance: 'assessmentBalance', allUnitsName: 'ASSESSMENT' },
    { key: 'Assessment180', name: 'txt180FeedbackAssessment', image: '/assets/img/sub/180.svg', balance: 'assessment180Balance', allUnitsName: 'ASSESSMENT180' },
    { key: 'Exit', name: 'txt_employee_exit', image: '/assets/img/sub/Exit.svg', balance: 'exitBalance', allUnitsName: 'EXIT' },
  ];
  // txtInsufficientUnits: string;
  // txtYourcurrentbalance: string;
  // txtunits: string;
  // txtIncreaseUnits: string;
  // txtRequest: string;
  // txtYourcurrentbalanceforsurvey: string;
  // txtthissurveyrequires: string;
  // txt_units: string;
  // txt_close: string;
  // noUnits: string;
  // txtclicktogotoaccount: string;
  // txtEngagement: string;
  // txtAssessmentSurvey: string;
  // txt_employee_exit: string;
  // txtProduct: string;
  // txtCurrentBalance: string;
  // txtRequestAmount: string;
  // txtNewBalance: string;
  // txtRequestSent: string;
  // txtContactSoon: string;
  // txtTotal: string;
  // txtEmployeeHappiness: string;
  setUpTextFields() {

  }

  unitsChanged(event) {

    if (this.units)
      this.equivelantSurveys = Number((this.units / this.multiplier).toFixed(0));
    else
      this.equivelantSurveys = 0;
  }

  requestAllUnits() {
    for (let key in this.allUnits) {
      setTimeout(() => {
        let value = this.allUnits[key];
        if (value > 0) {
          this.requestUnits(key, value);
        }
      }, 1000);

    }
  }

  requestUnits(creditType, units) {
    this.creditType = creditType;
    if (units < 1)
      return this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("noUnits", this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error });

    let creditTypeName: string = '';
    if (this.creditType == 'EXIT')
      creditTypeName = 'Exit Questionnaire';
    if (this.creditType == 'ENGAGEMENT')
      creditTypeName = 'Engagement Survey';
    if (this.creditType == 'HAPPINESS')
      creditTypeName = 'Happiness Survey';
    if (this.creditType == 'ASSESSMENT')
      creditTypeName = 'Assessment Survey';
    let unitRequest = { key: this.db.createPushId(), tenantKey: this.tenantKey, entityName: this.entity.name, units: units, creditType: creditTypeName };
    if (this.user.role == 'User') {
      let request = { user: this.user, tenant: this.tenant, entityName: this.entity.name, units: this.units, creditType: this.creditType };
      this.unitBalanceService.requestMoreUnitsFromSuperAdmin(request).subscribe((response: any) => {

      });
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtRequestSent", this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Success });

      this.dialogRef.close();

    } else {
      this.unitBalanceService.updateUnitBalance(unitRequest).then((_) => {
        let request = { user: this.user, tenant: this.tenant, entityName: this.entity.name, units: units, creditType: this.creditType, surveyName: creditTypeName };
        this.unitBalanceService.requestMoreUnitsFromTenantAdmin(request).subscribe((response: any) => {

        });
        this.requestSent = true;
        // this.dialogRef.close();
      });
    }
  }

  returnNumber() {
    let allUnits = 0;
    for (let index = 0; index < this.productTable.length; index++) {
      allUnits+= Number(this.allUnits[this.productTable[index].allUnitsName])

    }
    return allUnits;
  }

}
