<canvas class="fixed top-0 left-0 w-screen h-screen overflow-hidden" id="c"></canvas>

<div *ngIf="tenant.setup == false" class=" w-screen h-screen flex flex-col z-10 items-center px-5 relative py-10" (click)="logData(entity)">

  <div class="flex flex-row justify-end items-center py-5 w-full top-0 left-0 fixed px-5">
    <div [@child15] appClickOutside (clickOutside)="showLangage = false" [ngClass]="(language | language )?'rtl':'ltr'">
      <i (click)="showLangage = !showLangage"
        class="fa-light fa-earth-americas leading-9 w-9 h-9 text-center bg-zen bg-opacity-10 rounded text-zen btn"></i>
      <div class="flex left-0 flex-row justify-end absolute w-full h-0 px-5">
        <div [@fadeUpDown] *ngIf="showLangage"
          class="flex flex-col gap-1 top-22 px-2 py-2 rounded-lg shadow-lg bg-white  mt-5 topArrow relative h-max w-32">
          <ng-container *ngFor="let lang of supportedLanguages">
            <a *ngIf="lang.key != language" (click)="selectedLanguageChange(lang.key); closeDropdown('showLangage')"
              class="text-zen text-sm btn rounded-sm cancel py-2 px-2">
              {{lang.name}}</a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center w-full gap-10 pt-12 py-5">
    <div class="flex flex-row justify-center">
      <h5 class="text-lg font-medium text-zen"> {{'txtCompanySetup'| translate:language:ModuleNames.Common}}</h5>
    </div>
    <div
      class="flex flex-col items-stretch  rounded-xl p-5 max-w-lg w-full backdrop-filter backdrop-blur bg-white bg-opacity-30 gap-7">
      <div>
        <h5 class="text-zen">{{ entity.nativeName || 'txtNewCompany'| translate:language:ModuleNames.Common }}</h5>
        <h2 class="text-sm text-zen text-opacity-60">{{ 'tenant_intro'| translate:language:ModuleNames.Common }}</h2>
      </div>
      <!-- ----------------- COMPANY SETUP -------------------------------- -->
      <!-- <button color="primary" mat-raised-button (click)="addNewCompany()">{{ btnNewCompany }}</button> -->
      <!-- <ng-container *ngFor="let entity of entities"> -->
      <div class="flex flex-col items-stretch gap-7">

        <z-translation-input [invalid]="companyInvalid" [maxCount]="mdCount" [required]="true"
          [label]="'txt_company_name'| translate:language:ModuleNames.Common " [language]="language"
          [supportedLanguages]="contentLanguages" [(ngModel)]="entity.name" (ngModelChange)="entityNameChanged(entity)"></z-translation-input>


        <!-- <translation-input [maxCount]="mdCount" [language]="language" [inputName]="'txt_company_name'| translate:language:ModuleNames.Common"
          [translationField]="entity.name" [supportedLanguages]="contentLanguages" [showTranslation]="false"
          (translationChange)="entityNameChanged(entity)">
        </translation-input> -->
        <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
          <mat-form-field class="w-full" appearance="none">
            <mat-label>{{ 'txt_company_industry'| translate:language:ModuleNames.Common }}</mat-label>
            <mat-select disableRipple [(ngModel)]="entity.industry">
              <mat-option *ngFor="let industry of industriesList" [value]="industry.key">
                {{ industry.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [(ngModel)]="entity.industry"
          [label]="'txt_company_industry'| translate:language:ModuleNames.Common" [options]="industriesList"
          [language]="language">
          <ng-template #valueTemplate let-value>
            {{value?.name}}
          </ng-template>
        </z-select>

        <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
          <mat-form-field class="w-full" appearance="none">
            <mat-label>{{ 'txt_company_size'| translate:language:ModuleNames.Common }}</mat-label>
            <mat-select disableRipple [(ngModel)]="entity.companySize">
              <mat-option *ngFor="let compSize of companySizesList" [value]="compSize.key">
                {{ compSize.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [(ngModel)]="entity.companySize"
          [label]="'txt_company_size'| translate:language:ModuleNames.Common" [options]="companySizesList"
          [language]="language">
          <ng-template #valueTemplate let-value>
            {{value?.name}}
          </ng-template>
        </z-select>

        <!-- <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>{{ txtCurrentHeadcount }}</mat-label>
                      <input matInput type="number" [min]="1" [(ngModel)]="entity.headcount">
                    </mat-form-field> -->
        <!-- <custom-dropdown [selectionMode]="'Multi'" [dropDownData]="countries" [selectedOptions]="entity.Branches"
                        (selectionChanged)="branchesSelectionChanged($event, entity)"></custom-dropdown> -->
        <!-- <ng-container *ngIf="!resetDropdown">
          <custom-dropdown class="customDropDown"
            [txtBranches]="'txtHeadQuarter'| translate:language:ModuleNames.Common" [selectionMode]="'Single'"
            [dropDownData]="countriesList" [selectedKey]="entity.headQuarter"
            (selectionChanged)="dropDownChanged($event, entity)">
          </custom-dropdown>
        </ng-container> -->
        <z-select [color]="'exp'" [(ngModel)]="entity.headQuarter"
        (selectionChanged)="dropDownChanged($event, entity)"
        [label]="'txtHeadQuarter'| translate:language:ModuleNames.Common" [options]="countriesList"
        [language]="language">
        <ng-template #valueTemplate let-value>
          {{value?.name | extract:language}}
        </ng-template>
      </z-select>

      <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [multiple]="true"
      [(ngModel)]="entity.Branches" (selectionChanged)="branchesSelectionChanged($event, entity)"
      [label]="'txt_branches'| translate:language:ModuleNames.Common" [options]="countriesList"
      [language]="language">
      <ng-template #valueTemplate let-value>
        {{value?.name | extract:language}}
      </ng-template>
    </z-select>


        <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [multiple]="true"
        [(ngModel)]="entity.ContentLanguages"
        [label]="'txt_content_languages'| translate:language:ModuleNames.Common" [options]="supportedLanguages"
        [language]="language">
        <ng-template #valueTemplate let-value>
          {{value?.name}}
        </ng-template>
      </z-select>
        <!-- <custom-dropdown class="customDropDown" [txtBranches]="'txtBranches'| translate:language:ModuleNames.Common"
          [selectionMode]="'Multi'" [dropDownData]="countriesList"
          (selectionChanged)="branchesSelectionChanged($event, entity)">
        </custom-dropdown> -->
        <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
          <mat-form-field class="w-full" appearance="none">
            <mat-label>{{ 'txt_content_languages'| translate:language:ModuleNames.Common }}</mat-label>
            <mat-select disableRipple [(ngModel)]="entity.ContentLanguages" multiple>
              <mat-option *ngFor="let supportedLanguage of supportedLanguages" [value]="supportedLanguage.key">
                {{ supportedLanguage.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [(ngModel)]="entity.contactCode"
          [label]="'txt_country_code'| translate:language:ModuleNames.Common" [options]="countriesList"
          [language]="language">
          <ng-template #valueTemplate let-value>
            {{value?.countryCode }}
          </ng-template>
          <ng-template #optionTemplate let-option>
            {{option?.name | extract:language}} {{'('+ option?.countryCode +')' }}
          </ng-template>
        </z-select>
<!--
        <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
          <mat-form-field class="w-full" appearance="none">
            <mat-label>{{ 'txtCountryCode'| translate:language:ModuleNames.Common }}</mat-label>
            <mat-select disableRipple [(ngModel)]="entity.contactCode">
              <mat-option *ngFor="let country of countriesList" [value]="country.key">
                {{ extractNameLanguage(country.name) }} ({{ country.countryCode }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <z-input [invalid]="companyInvalid" [required]="true" [language]="language"
        [label]="'txt_contact_number'| translate:language:ModuleNames.Common" [disabled]="countWord(entity.contactNumber,
      mdCount) > mdCount " [maxlength]="mdCount" [number]="true" [(ngModel)]="entity.contactNumber"></z-input>



        <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
          <mat-form-field class="w-full" appearance="none">
            <mat-label>{{ 'txtContactNumber'| translate:language:ModuleNames.Common }}</mat-label>
            <input matInput [maxlength]="mdCount" [disabled]="countWord(entity.contactNumber,
                  mdCount) > mdCount " numbersOnly [(ngModel)]="entity.contactNumber" type="text">
          </mat-form-field>
        </div> -->
        <z-input [language]="language" [label]="'txtWebsite'| translate:language:ModuleNames.Common"
        [maxlength]="mdCount" [(ngModel)]="entity.website"></z-input>

        <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
          <mat-form-field class="w-full" appearance="none">

            <mat-label>{{"txtWebsite"| translate:language:ModuleNames.Common }}</mat-label>
            <input [maxlength]="mdCount" [disabled]="countWord(entity.contactNumber,
                  mdCount) > mdCount " matInput [(ngModel)]="entity.website">
          </mat-form-field>
        </div> -->
<!--
        <div class="flex flex-row items-center justify-between py-4 px-5 bg-white rounded-md">
          <div class="w-2/3 flex flex-row  items-center">
            <img *ngIf="companyLogoURL" [src]="companyLogoURL" class="h-12 object-center" />
            <h5 *ngIf="!companyLogoURL" class="c-40 is-size-5 uppercase mb-0">{{'txtLogo'|
              translate:language:ModuleNames.Common}}</h5>
          </div>
          <div class="w-1/3 flex flex-row  items-center justify-end">
            <a (click)="file.click()" class="leading-10 btn px-5 cancel font-medium rounded">
              {{'btn_upload'| translate:language:ModuleNames.Common}}</a>
          </div>

        </div> -->
        <zen-input-image [invalid]="invalidImage" [accept]="'image/x-png,image/gif,image/jpeg'" [(ngModel)]="entity.logoURL" (ngModelChange)="upload(entity, $event)"></zen-input-image>
<!--
        <input #file class="hidden" id="fileselect" accept="image/x-png,image/gif,image/jpeg"
          (change)="selectFile($event, entity)" type="file"> -->
      </div>

      <!-- </ng-container> -->
      <div class="flex flex-row justify-center">
        <a [disabled]="uploading" class="btn exp px-5 rounded leading-12 uppercase block" (click)="createCompanies()">
          {{'txtCreateCompany'| translate:language:ModuleNames.Common}}</a>
      </div>

    </div>
    <div class="column" *ngIf="setupStep == 'users' && !loading">
      <!-- ----------------- COMPANY USERS -------------------------------- -->
      <ng-container>
        {{ 'txtUsers'| translate:language:ModuleNames.Common }}
        <button color="primary" mat-raised-button (click)="addNewUser()">{{ 'btnNewUser'|
          translate:language:ModuleNames.Common }}</button>


        <mat-accordion>


          <ng-container *ngFor="let platformUser of users">

            <mat-expansion-panel *ngIf="platformUser.role != 'Super Admin'" [expanded]="true"
              (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ (platformUser.firstName + ' ' + platformUser.lastName) == ' ' ? ('txtNewUser'|
                  translate:language:ModuleNames.Common) :
                  (platformUser.firstName +
                  ' ' + platformUser.lastName) }}
                </mat-panel-title>
                <!-- <mat-panel-description>
                Currently I am {{panelOpenState ? 'open' : 'closed'}}
              </mat-panel-description> -->
              </mat-expansion-panel-header>

              <div>
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ 'txtUserFirstName'| translate:language:ModuleNames.Common }}</mat-label>
                  <input matInput [(ngModel)]="platformUser.firstName">
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ 'txtUserLastName'| translate:language:ModuleNames.Common }}</mat-label>
                  <input matInput [(ngModel)]="platformUser.lastName">
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ 'txt_user_email'| translate:language:ModuleNames.Common }}</mat-label>
                  <input matInput [min]="1" [(ngModel)]="platformUser.email">
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>{{ 'txtUserPassword'| translate:language:ModuleNames.Common }}</mat-label>
                  <input matInput [(ngModel)]="platformUser.password">
                </mat-form-field>


                <mat-form-field appearance="fill">
                  <mat-label>{{'txtUserCompanies'| translate:language:ModuleNames.Common}}</mat-label>
                  <mat-select [(ngModel)]="platformUser.EntityReferences" multiple>
                    <mat-option *ngFor="let entity of entitiesList" [value]="entity.key">{{ getEntityName(entity.name)
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>


              </div>

            </mat-expansion-panel>

          </ng-container>
        </mat-accordion>


        <button mat-raised-button color="primary" (click)="createUsers()">{{ 'btnNext'|
          translate:language:ModuleNames.Common }}</button>

      </ng-container>
    </div>


  </div>
</div>
<div *ngIf="tenant.setup || !tenant" class="zen-main-container flex flex-col justify-center items-center">
  <mini-loader></mini-loader>
</div>
