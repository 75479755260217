/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ShareType {
    Anyone = 1,
    AllPeople = 2,
    SpecificPeople = 3,
}
