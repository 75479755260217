import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export interface Feedback {
  name?: string;
  email?: string;
  respondentImageURL?: string;
  rating?: string;
  comment?: string;
  customProperties?: { key: string; value: string }[];
}

@Injectable({
  providedIn: 'root'
})
export class ExcelFeedbackDownloadService {

  private readonly mainColumn = 'A';
  private readonly ratingColumn = 'B';
  private readonly commentColumn = 'C';
  private readonly jobTitleColumn = 'D';

  exportToExcel(feedbackList: Feedback[], filename: string = 'feedback'): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Feedback Sheet');

    // Add headers
    const headers = ['Name/Email', 'Rating', 'Comment', 'Job Title'];
    worksheet.insertRow(1, headers);

    // Set column widths
    worksheet.getColumn(this.mainColumn).width = 30;
    worksheet.getColumn(this.ratingColumn).width = 10;
    worksheet.getColumn(this.commentColumn).width = 50;
    worksheet.getColumn(this.jobTitleColumn).width = 20;

    // Loop over feedback list
    for (let i = 0; i < feedbackList.length; i++) {
      const feedback = feedbackList[i];

      // Add Feedback Data
      this.addFeedbackData(worksheet, feedback, i + 2);
    }

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${filename}.xlsx`);
    });
  }

  private addFeedbackData(worksheet, feedback: Feedback, row: number) {
    // Add Name/Email column
    this.addCell(worksheet, this.mainColumn, feedback.name || feedback.email || '', false, row);
  
    // Add Rating column
    this.addCell(worksheet, this.ratingColumn, feedback.rating || '', false, row);
  
    // Add Comment column
    this.addCell(worksheet, this.commentColumn, feedback.comment || '', false, row);
  
    // Add Job Title column
    this.addCell(worksheet, this.jobTitleColumn, this.getJobTitle(feedback.customProperties), false, row);
  }

  private addCell(worksheet, column, value, isHeader, row) {
    let cell = worksheet.getCell(`${column}${row}`);
    cell.value = value;
  
    // Styling for all cells
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  
    // Apply specific styling for header cells
    if (isHeader) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFCCCCCC' },
      };
      cell.font = {
        bold: true,
      };
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
  
      // Enable filtering for each header cell
      worksheet.getCell(`${column}1`).dataValidation = {
        type: 'whole',
        operator: 'between',
        formula1: 0,
        formula2: 1000,
      };
    } else {
      // Center-align content in rating cells
      if (column === this.ratingColumn) {
        cell.alignment = {
          horizontal: 'center',
          vertical: 'middle',
        };
      }
    }
  }

  private getJobTitle(customProperties: { key: string; value: string }[]): string {
    return customProperties?.find(x => x.key === 'job-title')?.value || '';
  }
}
