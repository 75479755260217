<!-- <div class="flex flex-row bg-zen-bluegray items-center border-y-1 h-90 w-full pt-28 px-10 ">


    <div [dir]="direction" id="surveyCreator"></div> -->

    <div class="flex flex-col justify-between h-screen">
      <survey-creator-container>
    <div id="surveyCreator" class="flex flex-row bg-zen-bluegray items-center border-y-1 w-full pt-2 px-10 ">

    <survey-creator [dir]="direction" [model]="surveyCreatorModel"></survey-creator>

    </div>
    </survey-creator-container>

    <div class="flex flex-col">
      <z-footer [dir]="direction"></z-footer>
    </div>
    </div>
