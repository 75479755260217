import { Direction } from '@angular/cdk/bidi';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AnyForUntypedForms, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Entity } from '@ex/module/core/data-models/Entity';
import { FileUpload } from '@ex/module/core/data-models/file-upload';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { LoaderService } from '@ex/module/core/services/loader.service';
import { LocalService } from '@ex/module/core/services/local.service';
import { ZenSnackbarService, ZenSnackBarTypes } from '@ex/module/shared/Theme/services/zen-snackbar.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { TranslatePipe } from '@ex/module/shared/pipes/translate.pipe';
import { SurveysService } from '@ex/module/survey-x/data-access/services/surveys.service';
import { CustomDatepickerComponent } from '@ex/module/survey-x/ui/custom-datepicker/custom-datepicker.component';
import { FormCategory, FormCategoryService, FormService, FormTable, FormTypeService } from '@ex/survey-x/index';
import { Observable, Subject, of } from 'rxjs';
import { delay, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { LocalizableString, SurveyModel, surveyLocalization } from 'survey-core';
import { SurveyCreatorModel, localization } from 'survey-creator-core';

const creatorOptions = {
  isAutoSave: true
};

surveyLocalization.supportedLocales = ["ar"];


@Component({
  selector: 'app-initial-setup',
  templateUrl: './initial-setup.component.html',
  styleUrls: ['./initial-setup.component.scss']
})
export class InitialSetupComponent extends BaseComponent implements OnInit {

  isEditing: boolean = false;
  static declaration = [CustomDatepickerComponent];

  form: any;

  isChangeCategoryModalVisible: boolean = false;
  isNewCategoryModalVisible: boolean = false;
  editCategoryModal: { open: boolean, category: any } = { open: false, category: null };
  companyKey: any
  tenantKey: any;
  selectedEntity: Entity;
  companyName: string;
  tenant: any;
  tenantName: any
  createdBy: string = "";
  currentUser: any;
  currentUserName: any;

  maxCharactersDescription: any = 300;
  maxCharactersTitle: any = 100;
  descriptionCharacterCount: any = 0;
  titleCharacterCount: any = 0;
  descriptionValue: any;
  titleValue: any;

  respondentInformationPage: boolean = true;
  supportedLanguagesList: { locale: string, title: string }[] = [];
  supportedLanguages = ['EN', 'AR'];
  language;
  dLanguage: any;
  direction: Direction = 'ltr';
  ModuleNames = ModuleName;
  result: any;

  categoriesList: any[] = [];
  typesList: any[] = [];

  surveyId!: string;
  survey: any = {};
  surveyForm: FormTable;
  surveyJson: SurveyModel;
  newCategory: FormCategory;

  invalidImage: boolean = false;
  currentFileUpload!: FileUpload;
  selectedDefaultLanguage: any;
  typeName: any;
  surveyCreatorModel: SurveyCreatorModel;
  settingsLoaded$ = new Subject();
  manualRefresh = true;

  sourceLocale: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private surveysService: FormService,
    private zenSnackBarService: ZenSnackbarService,
    private translationPipe: TranslatePipe,
    public loader: LoaderService,
    private localService: LocalService,
    private formCategoryService: FormCategoryService,
    private formTypeService: FormTypeService,
    private cdr: ChangeDetectorRef,

  ) {
    super();


  }

  ngOnInit() {
    this.surveyId = this.route.snapshot.paramMap.get('id');
    this.fetchSurveyData();

    if(this.surveyId)
    this.isEditing = true;


    const sub = this.EssentialObject$.pipe(
      filter(x => x.selectedEntity?.key != null),
    ).subscribe(obj => {
      this.selectedEntity = obj.selectedEntity;
      this.tenantKey = obj.tenant.key;
      this.tenantName = obj.tenant.name
      this.companyKey = obj.selectedEntity.key;
      this.language = obj.language;
      this.companyName = JSON.stringify(obj.selectedEntity.name);
      this.settingsLoaded$.next();
    })


    this.addSubscription(sub);


    this.loadSupportedLanguages();
  }

  onSurveyLocaleChanged(
    localizableString: LocalizableString,
    locale: any,
    value: any
  ) {
    localizableString.setLocaleText(locale, value);
  }

  fetchSurveyData() {

    this.settingsLoaded$.pipe(switchMap(x => {
      return this.formTypeService.getAllFormTypes().pipe(switchMap((types: any) => {
        if (this.surveyId)
          return this.surveysService.getFormWithoutResponses(this.surveyId).pipe(map(x => x.query)).pipe(switchMap(survey => {
            return this.formCategoryService.getAllCategoriesByFormTypeAndTenant(survey?.typeId, this.tenantKey, this.companyKey).pipe(map((categories: any) => ({ survey, types, categories })))
          }));
        return this.formCategoryService.getAllCategoriesByFormTypeAndTenant(types[0]?.id, this.tenantKey, this.companyKey).pipe(map(categories => {
          let survey = {} as FormTable;
          survey.tenantKey = this.tenantKey;
          survey.companyKey = this.companyKey;
          survey.title = '';
          survey.description = '';
          survey.tenantName = this.tenantName;
          survey.createdBy = this.currentUserName;
          survey.systemLocale = this.language;
          survey['formTypeId'] = types[0].id;
          survey.typeId = types[0].id;
          survey.colorTheme = '#0082C6';
          survey.jsonText = JSON.stringify({ title: '', description: '', logoPosition: 'right', pages: [{ name: this.translationPipe.transform("txtSection", this.language, this.ModuleNames.SurveyX), title: this.translationPipe.transform("txtSection", this.language, this.ModuleNames.SurveyX) }] });
          survey.locale = this.language.toLowerCase();
          survey.logo = this.selectedEntity.logoURL || this.GeneralService.zenLogo;
          survey.categoryId = categories[0]?.id ?? null
          survey.isQuestionPerPage = true;
          survey.companyName = this.companyName;
          return ({ survey, types, categories });
        }))
      }))
    })).subscribe(({ survey, types, categories }) => {
      this.surveyForm = survey;
      this.typesList = types;
      this.categoriesList = categories;

      this.surveyCreatorModel = new SurveyCreatorModel(creatorOptions);
      this.surveyCreatorModel.text = this.surveyForm.jsonText;
      this.surveyCreatorModel.saveSurveyFunc = (saveNo: number, callback: Function) => {
        callback(saveNo, true);
        this.surveyForm.jsonText = this.surveyCreatorModel.text;
        const jsonObject = JSON.parse(this.surveyForm.jsonText);
        this.surveyForm.title = JSON.stringify(this.surveyCreatorModel?.survey.locTitle['values']);
        this.surveyForm.description = JSON.stringify(this.surveyCreatorModel?.survey.locDescription['values']);
        const arrData = Object.keys(jsonObject).map((key) => ({ key, value: jsonObject[key] }));
        this.surveyForm.jsonTextPair = JSON.stringify(arrData);
        this.surveyForm.modifiedBy = this.currentUserName;
        if (!this.validate(survey))
          return;
        (this.surveyId ? this.surveysService.updateFormFromInitial(survey.id, this.surveyForm) : this.surveysService.addForm(this.surveyForm)).subscribe(x => {

          if (!this.surveyId) {
            this.surveyId = survey.id = this.surveyForm.id = x.id;
            //this.surveyCreatorModel.text = x.jsonText;
            this.surveyForm = x;
          }


          callback(saveNo);
        });


      };
      let lan = this.surveyCreatorModel.survey.locale;

      if (lan == "en" || lan == "") {
        this.sourceLocale = "default"
      }
      else {
        this.sourceLocale = lan;
      }
    })


  }








  validate(survey?: any): boolean {
    console.log(this.surveyCreatorModel.survey);
    if (!this.surveyCreatorModel?.survey.locTitle?.text.length) {
      this.zenSnackBarService.error(this.localize("Please enter a title", this.language, this.ModuleNames.SurveyX));
      return false
    }
    if (this.surveyCreatorModel?.survey.locTitle?.text.length > this.mdCount || this.surveyCreatorModel?.survey.locTitle?.text.length < 3) {
      this.zenSnackBarService.error(this.localize("Title should be between 3 and 100 characters", this.language, this.ModuleNames.SurveyX));
      this.surveyCreatorModel.survey.locTitle.text = this.valueAfterLimit(this.surveyCreatorModel?.survey.locTitle?.text, this.mdCount);
      return false
    }
    if (this.surveyCreatorModel?.survey.locDescription?.text.length > this.lgCount) {
      this.zenSnackBarService.error(this.localize("Description should be less than 300 characters", this.language, this.ModuleNames.SurveyX));
      this.surveyCreatorModel.survey.locDescription.text = this.valueAfterLimit(this.surveyCreatorModel?.survey.locDescription?.text, this.lgCount);

      return false
    }

    if (!this.surveyForm.categoryId) {
      this.zenSnackBarService.error(this.localize("form_category_required", this.language, this.ModuleNames.SurveyX));
      return false
    }


    return true;
  }


  pageChanged(event: any) {
    this.router.navigateByUrl(`/survey-x/form-builder/${event}/${this.surveyId}`);
  }



  onNext() {
    if (!this.validate(this.surveyForm))
      return;

    (!this.surveyId ? this.surveysService.addForm(this.surveyForm) : this.surveysService.updateFormFromInitial(this.surveyForm.id, this.surveyForm)).subscribe(x => {
      this.router.navigate(["survey-x/form-builder/questions-builder/" + x.id]);
    })
  }

  loadSupportedLanguages() {
    let locales = surveyLocalization.getLocales();

    for (let index = 0; index < locales.length; index++) {
      this.supportedLanguagesList.push({
        locale: locales[index] || 'en',
        title: localization.getLocaleName(locales[index])
      })
    }
  }



  getCategories(idToSelect?: any) {
    this.categoriesList = [];

    this.formCategoryService.getAllCategoriesByFormTypeAndTenant(this.surveyForm.typeId, this.tenantKey, this.companyKey).subscribe((response: any) => {
      response.forEach((element: any) => { this.categoriesList.push({ key: element.id, name: element.name, formType: element.formTypeId }) });

      if (idToSelect == null) {
        if (!this.isEditing) {
          this.surveyForm.categoryId = this.categoriesList[0].id;
        } else {
          this.surveyForm.categoryId = this.categoriesList.find((x) => x.key == this.surveyForm.categoryId).id;
        }
      } else {
        this.surveyForm.categoryId = this.categoriesList.find((x) => x.key == idToSelect);
      }
    })
  }


  async onDeleteCategoryClicked(event: any) {
    await (await this.surveysService.getAllFormsByCategory(event.key, this.tenantKey, this.companyKey)).subscribe(async (response: any) => {
      var forms = await response;
      if (forms.length > 0) {
        this.zenSnackBarService.snackBar({
          message: this.translationPipe.transform("This Category is being used by one of the forms and can't be deleted", "EN",
            this.ModuleNames.Common),
          type: ZenSnackBarTypes.Error
        });
      }
      else {
        this.formCategoryService.deleteFormCategory(event.key).subscribe((response: any) => {
          this.getCategories();
        })
      }
    });

    this.isChangeCategoryModalVisible = false;
  }

  onEditCategoryClicked(event: any) {
    this.isChangeCategoryModalVisible = false;

    this.editCategoryModal.open = true;
    this.editCategoryModal.category = event;
  }

  async onEditCategory(event: any) {
    if (event.name) {
      await this.formCategoryService.getFormCategory(event.key).subscribe((response: any) => {
        response.name = event.name
        this.formCategoryService.updateFormCategory(event.key, response).subscribe((res: any) => {
          this.getCategories();
        });
      })
    }

    this.editCategoryModal.open = false;
  }

  onSelectedCategoryChange(event: any) {
    this.surveyForm.categoryId = event.id;
  }

  onCreateNewCategory(event: any) {
    this.newCategory = { name: event, tenantKey: this.tenantKey, companyKey: this.companyKey, tenantName: this.tenantName, formTypeId: this.surveyForm.typeId }
    this.formCategoryService.addCategory(this.newCategory).subscribe((response: any) => {
      this.categoriesList.push(response);
      this.surveyForm.categoryId = response.id;
    }, error => {
      this.SnackbarService.snackBar({
        message: this.localize("category_already_exists", this.language, this.ModuleNames.SurveyX), type: ZenSnackBarTypes.Error
      })
    });
  }

  showChangeCategoryModal() {
    this.isChangeCategoryModalVisible = true;
  }

  showNewCategoryModal() {

    if (this.categoriesList.length)
      this.isChangeCategoryModalVisible = true;
    else
      this.isNewCategoryModalVisible = true;


    // this.isNewCategoryModalVisible = true;
  }

  onOpenNewCategoryModal(event: any) {
    this.isNewCategoryModalVisible = true;
  }

  getCurrentColor() {
    return this.surveyForm?.colorTheme ? this.surveyForm.colorTheme : '#0082C6';
  }

  resetColorPicker() {
    this.surveyForm.colorTheme = '#0082C6';
  }

  getCategoryName(id: any) {
    if (!id) return;
    return JSON.parse(this.categoriesList.find(x => x.id == id).name);
  }

  resetLogo() {
    this.surveyForm.logo = this.selectedEntity.logoURL || this.GeneralService.zenLogo;
  }
  valueAfterLimit(value: string, limit: number) {
    if (value.length >= limit)
      value = value.substring(0, limit);
    return value;
  }

}

