import type { KeyNameDto } from '../../core/common/models';

export interface CompaniesListWithSurveysDto {
  surveys: CompaniesListWithSurveysResponseDto[];
  dimesnions: DimesnionsListResponseDto[];
}

export interface CompaniesListWithSurveysResponseDto {
  companyKey?: string;
  surveyKey?: string;
  year?: string;
  companyName?: string;
  startDate?: string;
  endDate?: string;
  surveyName: KeyNameDto[];
}

export interface DimesnionsListResponseDto {
  key?: string;
  name: KeyNameDto[];
}

export interface TimeFrameDto {
  startDate?: string;
  endDate?: string;
}
