import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SjsTranslatePipe } from '../../utils/pipes/sjs-translate.pipe';
import { TranslatePipe } from '@ex/module/shared/pipes/translate.pipe';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';


export interface SJSection {
  key: string,
  title: any;
}

@Pipe({
  name: 'sjQuestionFilter',
})
export class SJQuestionFilterPipe implements PipeTransform {
  transform(questions: any, sectionKey: string) {
    if (sectionKey == "ALL")
      return questions;

    return questions.filter(x => x.sectionKey == sectionKey);
  }
}

@Pipe({
  name: 'sjSectionFilter',
})
export class SJSectionFilterPipe implements PipeTransform {
  transform(sections: any, sectionKey: string) {
    if (sectionKey == "ALL")
      return sections.filter(x => x.key != "ALL");

    return sections.filter(x => x.key == sectionKey);
  }
}


@Injectable({
  providedIn: null
})
export class SurveyjsDataService {

  private json;

  private sections: SJSection[] = [];
  private questions: any[] = []

  constructor(private sjsTranslate: SjsTranslatePipe, private translatePipe: TranslatePipe) { }

  setJson(json, language) {
    this.json = json;

    this.sections = [];
    this.questions = [];

    this.initialize(language);
  }

  private initialize(language) {

    let index = 0;

    this.json.pages.forEach(page => {
      if (!page.elements?.length)
        return;
      this.sections.push({
        key: page.name,
        title: page.title ?? page.name,
      })

      page.elements.forEach((question) => {
        question['sectionKey'] = page.name;
        question['questionNo'] = ++index;

        if (question.showOtherItem == true) {
          question['choices'].push(question.otherText == null ? { text: 'Other', value: 'other', key: 'other' } : question.otherText);
        }
        if (question.showNoneItem == true) {
          question['choices'].push(question.noneText == null ? { text: 'None', value: 'none', key: 'none', isNoneItem: true } : question.noneText);
        }

        if (question.type == 'boolean') {
          question['choices'] = [{ value: 'true' }, { value: 'false' }]
        }

        this.questions.push(question);
      })

    })


    this.sections.unshift({
      key: "ALL",
      title: this.translatePipe.transform('txtAll', language, ModuleName.SurveyX)
    })

  }


  getSections() {

    if (!this.json)
      throw new Error("please set the survey template json using setJson function");

    return this.sections;
  }

  getQuestions() {
    if (!this.json)
      throw new Error("please set the survey template json using setJson function");

    return this.questions;
  }

  getChoiceTranslation(question, key, language) {
    var choice = question?.choices?.find(x => (x.value ?? x) == key);

    if (choice)
      return this.sjsTranslate.transform((choice.text ?? choice), language) || this.translatePipe.transform(choice, language, ModuleName.SurveyX);

    return key;
  }
}
