import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalService } from '@ex/module/core/services/local.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';

import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';

@Component({
  selector: 'app-new-form-btn',
  templateUrl: './new-form-btn.component.html',
  styleUrls: ['./new-form-btn.component.scss'],
  animations: [ChildrenAnimation]
})
export class NewFormBtnComponent implements OnInit {

  open: boolean | any = false;
  language: any;
  ModuleNames = ModuleName;

  direction : Direction = "ltr"
  textPosition : any = "left";

  constructor(private router :Router, private localService: LocalService, ) { }

  ngOnInit(): void {

    let localLanguage = this.localService.getJsonValue('language');
    if (!localLanguage) {
      this.language = 'EN';
      this.localService.setJsonValue('language', this.language);
    } else {
      this.language = localLanguage;
      // this.language = 'FR';
    }


    this.textPosition = "left"
    if (this.language == "AR")
    {
      this.direction = "rtl"
      this.textPosition = "right"
    }
    else
    {
      this.direction = "ltr"
      this.textPosition = "left"
    }

  }

  openChange(evt: any) {
    this.open = evt;
  }

  openSurveyCreator() {
    this.router.navigate(["survey-x/form-builder/initial-setup"]);
    // this.router.navigate(["survey-x/form-builder/initial-setup"]);

  }


}
