import { Injectable } from '@angular/core';
import { AppInjector } from 'src/app/app.module';
import { GeneralService } from './general.service';
import { GlobalStatusService } from './global-status.service';

@Injectable({
  providedIn: 'root'
})
export class CommonChartFunctions {
  constructor() { }
  arrangeFactorOrder(sections) {
    let newSections = sections;
    let keys = ['E', 'C', 'A', 'R', 'B'];
    for (let index = 0; index < keys.length; index++) {
      let factor = newSections.find(factor => factor?.factorKey == keys[index]);
      if (factor) {
        newSections.splice(newSections.indexOf(factor), 1);
        newSections.unshift(factor);
      }
    }
    let nFactors = newSections.filter((factor: any) => factor?.isFactor && !factor.isPredefined);
    if (nFactors.length) {
      nFactors.forEach(factor => {
        newSections.push(newSections.splice(newSections.indexOf(factor), 1)[0]);
      });
    }
    let gFactor = newSections.find((factor: any) => !factor.isFactor && factor.isPredefined);
    if (gFactor) {
      newSections.push(newSections.splice(newSections.indexOf(gFactor), 1)[0]);
    }

    return newSections;
  }

  reArrangeArray(array: any[], orderBy: any[], orderProperty: string) {
    let newArray = [];
    let tempArray = JSON.parse(JSON.stringify(array));
    for (let index = 0; index < orderBy.length; index++) {
      let currentKeySelections = tempArray.filter(x => x[orderProperty] == orderBy[index]);
      newArray.push(currentKeySelections);
    }
    return newArray.flat();
  }

}

@Injectable({
  providedIn: 'root'
})
export class ChartDataService {

  constructor() { }
}

export class PieData {
  name: string;
  value: any;
}

export class DashletData {
  categories: string[] = [];
  pie: PieData[] = [];
  bar: any = {};
  radar: any;
  scatter: any;
  groupedChart: boolean;
  drillDown: any = {};
  result: string;
}

export abstract class ChartData {
  abstract process(data);
}

export class NormalChartData extends ChartData {
  private data: any = null;

  constructor(data: any) {
    super();
    this.data = data;
  }
  globalStatusService = AppInjector.get(GlobalStatusService);
  process() {
    let result: DashletData = new DashletData();

    result.categories = this.getUniqueCategory(this.data);
    let groups: any[] = [];
    groups = this.getUniqueGroup(this.data);
    result.bar.series = [];
    if (groups.length > 0) {
      this.populateGroupedDashlet(result, groups, this.data);
    } else {
      this.populateUnGroupedDashlet(result, this.data);
    }
    result.drillDown = this.data.drillDown;
    //result.categories = this.addNewLineToCategoryByWordCount(result.categories, 3);
    return result;
  }

  private getUniqueCategory(data) {
    let categories: any[] = [];
    for (let i = 0; i < data.length; i++) {
      if (!categories.find(c => c == data[i].category)) categories.push(data[i].category);
    }



    return categories;
  }

  private addNewLineToCategoryByWordCount(categories, words = 3) {
    return categories.map(category => {

      if (!category)
        return category;

      let split = category.split(' ');

      if (split.length > words) {

        const res = split.reduce((list, elem, i) => {
          list.push(elem);
          if ((i + 1) % words === 0) list.push("\n");
          return list;
        }, []);

        category = res.join(" ");
      }

      return category;
    })
  }

  private getUniqueGroup(data) {
    let groups: any[] = [];
    if (data.length > 0) {
      if (data[0].group) {
        for (let i = 0; i < data.length; i++) {
          if (!groups.find(g => g == data[i].group)) groups.push(data[i].group);
        }
      }
    }
    return groups;
  }

  private populateGroupedDashlet(dashletData, groups, data) {
    dashletData.groupedChart = true;
    for (let i = 0; i < groups.length; i++) {
      let item = data.find(s => s.group === groups[i]);
      let seriesItem = {
        key: item.key,
        name: groups[i],
        data: [],
        counts: []
      };

      if (item?.seriesColor) {
        seriesItem['seriesColor'] = item?.seriesColor;
      }

      dashletData.bar.series.push(seriesItem);
    }

    for (let c = 0; c < dashletData.categories.length; c++) {
      for (let i = 0; i < groups.length; i++) {
        let series = dashletData.bar.series.find(s => s.name == groups[i]);
        let groupCategoryData = data.find(d => {
          if (d.category == dashletData.categories[c] && d.group == groups[i])
            return true;
          else return false;
        });
        if (groupCategoryData) { series.data.push(groupCategoryData.value); series.counts.push(groupCategoryData.value1) }
        else { series.data.push(0); series.counts.push(0) }
      }
    }

    dashletData.radar = { abbrvSeries: [], data: [] };

    for (let x = 0; x < dashletData.bar.series.length; x++) {
      let seriesName: string = dashletData.bar.series[x].name?.replace(' ', '_');
      dashletData.radar.abbrvSeries.push(seriesName);
    }

    for (let i = 0; i < dashletData.categories.length; i++) {
      let cat: { [k: string]: any } = {};
      cat.name = dashletData.categories[i];
      for (let x = 0; x < dashletData.bar.series.length; x++) {
        let seriesName: string = dashletData.bar.series[x].name?.replace(' ', '_');
        cat[seriesName] = dashletData.bar.series[x].data[i];
      }
      dashletData.radar.data.push(cat);
    }
  }

  private populateUnGroupedDashlet(dashletData, data) {
    dashletData.groupedChart = false;
    dashletData.bar.series = [{ data: [] }];
    dashletData.pie = [];
    let series = dashletData.bar.series;
    for (let c = 0; c < dashletData.categories.length; c++) {
      let groupCategoryData = data.find(d => d.category == dashletData.categories[c]);
      if (groupCategoryData) {
        series[0].data.push(groupCategoryData.value);
        dashletData.pie.push({
          name: dashletData.categories[c],
          color: this.globalStatusService.returnHexColor(groupCategoryData.key),
          key: groupCategoryData.key,
          value: groupCategoryData.value,
          value1: groupCategoryData.value1,
        });
      }
    }
  }
}

export class MDChartData extends ChartData {
  private data: DashletData = new DashletData();

  constructor(data) {
    super();
    this.data = data;
  }

  process() {
    return this.data;
  }
}

// privide data, the sort key, array order
export class SortChartData extends ChartData {
  private data;
  private key;
  private values;
  constructor(data, key, values) {
    super();
    this.data = data;
    this.key = key;
    this.values = values;
  }

  process() {
    let newData = JSON.parse(JSON.stringify(this.data))
    for (let index = this.values.length; index >= 0; index--) {
      let current = newData.find(x => x[this.key] == this.values[index]);
      if (current) {
        newData.splice(newData.indexOf(current), 1);
        newData.unshift(current);
      }
    }

    return newData;
  }
}

// privide , the sort key, array order
export class FormatCategoryData extends ChartData {
  private data;
  private language;
  constructor(data, language) {
    super();
    this.data = data;
    this.language = language;
  }
  private _generalService = AppInjector.get(GeneralService);
  process() {
    let categories = JSON.parse(JSON.stringify(this.data.categories));
    for (let index = 0; index < categories.length; index++) {
      let value = categories[index].split(' ');
      value[0] = this._generalService.monthNames.find(month => month.name.find(s => s.name.toLowerCase().indexOf(value[0].toLowerCase()) !== -1)).name.find(s => s.key == this.language).name;
      categories[index] = value.join(" ");
    }
    this.data.categories = categories;
    return this.data;
  }
}

export class SalaryAnalysisChartData extends ChartData {
  private data: any;

  constructor(data) {
    super();
    this.data = data;
  }

  process() {
    return this.data;
  }
}

export const CHART_DATA_RESPONSE_STRATEGY = {
  NormalDashlet(data) {
    return new NormalChartData(data);
  },
  MDDashlet(data) {
    return new MDChartData(data);
  },
  SortChart(data, key, values) {
    return new SortChartData(data, key, values);
  },
  FormatCategory(data, language) {
    return new FormatCategoryData(data, language);
  },
};
