import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { DATA_TOKEN, ICDKPortalComponent } from 'src/app/shared/Theme/services/zen-cdk.service';
import { ChildrenAnimation } from '../../animations/allAnimations';

export interface LiveSurveyDates {
  startDate: Date;
  endDate: Date;
  reminders: any[];
}

@Component({
  selector: 'app-live-survey-time-changes',
  templateUrl: './live-survey-time-changes.component.html',
  styleUrls: ['./live-survey-time-changes.component.scss'],
  animations: [ChildrenAnimation]
})
export class LiveSurveyTimeChangesComponent extends BaseComponent implements OnInit, ICDKPortalComponent {
  language: string;
  open: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  constructor(@Inject(DATA_TOKEN) private data: { surveyDates: LiveSurveyDates, language: string }) {
    super();
  }
  ngOnInit(): void {
    this.open = true;
    this.language = this.data.language;
    this.surveyDates = this.data.surveyDates;
  }
  surveyDates: LiveSurveyDates;
  today: Date = new Date();

  closeModal() {
    this.open = false;
    this.close.emit(true);
  }
  addReminder() {
    if (this.surveyDates.reminders?.length == 3)
      return;
    if (!this.surveyDates.startDate || !this.surveyDates.endDate)
      return this.SnackbarService.error(this.localize("txt_start_and_endDate", this.language, this.ModuleNames.Shared));


    this.surveyDates.reminders.push({ date: '' });
    console.log(this.surveyDates.reminders);

  }

  plusOneDay(tDate: Date, minToday?) {
    let date = new Date(tDate);
    if (minToday) {
      if (new Date(date?.setHours(0, 0, 0, 0)).getTime() < new Date(new Date().setHours(0, 0, 0, 0)).getTime())
        date = new Date()
    }
    return date.setDate(date.getDate() + 1)
  }
  minusOneDay(tDate: Date) {
    let date = new Date(tDate);
    return date.setDate(date.getDate() - 1)
  }
  getReminders(reminders, ignoreIndex) {
    if (reminders?.length) {
      let newReminders = reminders.filter((reminder, index) => index != ignoreIndex);
      if (newReminders?.length)
        return newReminders.map(date => date.date);
    }

  }

  removeReminder(reminder) {
    const index = this.surveyDates.reminders.indexOf(reminder);
    this.surveyDates.reminders.splice(index, 1);

    this.SnackbarService.success(this.localize("txt_removed_success", this.language, this.ModuleNames.Shared));
  }
  disabledDayCheck(date:Date){
    return new Date(date).getTime() < new Date(this.today).getTime()
  }

  endDateChange() {
    for (let index = 0; index < this.surveyDates.reminders.length; index++) {
      if (new Date(this.surveyDates.reminders[index].date).getTime() > new Date(this.surveyDates.endDate).getTime())
        this.surveyDates.reminders[index].date = '';
    }
    this.surveyDates.reminders = this.surveyDates.reminders.filter(reminder => new Date(reminder.date).getTime());
  }


  submitDates() {
    if (!this.surveyDates.endDate.getTime())
      return this.SnackbarService.error("txtProvideEndDate");
    this.surveyDates.reminders = this.surveyDates.reminders.filter(x => new Date(x.date).getTime());
    this.results.emit(this.surveyDates);
  }
}
