import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LocalService } from '../../../core/services/local.service';
import { ChildrenAnimation, fadeUpDown } from '../../animations/allAnimations';
import { ModuleName } from '../../enums/ModuleName';
import { LanguagePipe } from '../../pipes/language.pipe';
declare var $: any;

@Component({
  selector: 'q-header',
  templateUrl: './q-header.component.html',
  styleUrls: ['./q-header.component.scss'],
  animations: [ChildrenAnimation, fadeUpDown]
})
export class QHeaderComponent implements OnInit {
  @Input('language') set lang(language: any) {
    if (language) {
      this.language = language
      this.localService.setJsonValue('language', this.language);
      this.isArabic(language);
    }
  };
  allQuestions = [];
  currentQuestion;
  currentSection;
  question;
  section;
  @Input() supportedLanguages;
  @Input() questionnaire;
  @Input() language;
  @Input() title;
  @Input() subtitle;
  @Input() stage: string;
  @Input() flatted;
  @Input('question') set que(q) {
    if (q)
      this.question = q;
    if (!this.flatted) {
      this.allQuestions = this.questionnaire.sections.flatMap(x => x.questions).filter(f => !f.skipped);
      this.section = this.questionnaire.sections.find(s => s.questions.find(qu => qu.key == q.key));
      this.currentSection = this.questionnaire.sections.indexOf(this.section);
    }
    else if (this.flatted)
      this.allQuestions = this.questionnaire?.questions;

    this.currentQuestion = this.allQuestions?.indexOf(q);

    this.loader.hideLoader();

  };
  @Output() languageChanged = new EventEmitter();
  showLangage: boolean = false;
  constructor(private localService: LocalService, private languageService: LanguageService, public loader: LoaderService, private languagePipe: LanguagePipe,) { }

  ngOnInit(): void {
    if(!this.question)
    this.loader.displayLoader();
  }
  ModuleNames = ModuleName
  isArabic(language) {
    if (this.languagePipe.transform(language)) {
      $("body").removeClass('ltr')
      $("body").addClass('rtl').attr("dir", "rtl");
    }
    else {
      $("body").removeClass('rtl')
      $("body").addClass('ltr').attr("dir", "ltr")

    }
  }
  getLongLanguageName(langISO: string) {
    // console.log(langISO);

    if(langISO)
   return this.languageService.getLongLanguageName(langISO, langISO);
  }

  closeDropdown(menu) {
    if (this[menu]) this[menu] = false;
  }
}
