import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Engagement } from '../data-models/engagement';
import { map } from 'rxjs/operators';
import { EngagementRespondent } from '../data-models/engagement-respondent';
import { EngagementMetric } from '../data-models/engagement-metric';
import { Respondent } from '../data-models/respondent';
import { Questionnaire } from '../data-models/questionnaire';
import { take } from 'rxjs/operators';
import { RespondentReference } from '../data-models/respondent-reference';
import { EngagementEmailTemplate } from '../data-models/engagement-email-template';
import { EngagementReference } from '../data-models/engagement-reference';
import { LeaverAnalysis } from '../data-models/leaver-analysis';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {


  sEndpoint: string = 'https://apis.zenithr.net/api/';//'https://apis.zenithr.net/api/';//
  sDevEndpoint: string = 'http://localhost:52619/api/';
  fbFunctionURL: string = '';


  constructor(private db: AngularFireDatabase, public afAuth: AngularFireAuth, private http: HttpClient, private globals: GlobalsService) {
    this.sEndpoint = this.globals.sEndpoint;
    this.fbFunctionURL = this.globals.fbFunctionURL;
   }




  createEngagementResList(request) {
    return this.http.post(this.sEndpoint + 'Assessment/CreateAssessmentResList', request);
  }

  updateEngagement(tenantKey: string, companyKey: string, engagement: Engagement) {
    return this.db.object('/TenantData/' + tenantKey + '/Assessments/' + companyKey + '/' + engagement.key).update(engagement);
  }


  fetchEngagements(tenantKey: string, companyKey: string) {
    return this.db.list<Engagement>('/TenantData/' + tenantKey + '/Assessments/' + companyKey + '/', ref => ref.orderByChild('isDeleted').equalTo(false)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }
  fetchEngagementsOnce(tenantKey: string, companyKey: string) {
    return this.db.list<Engagement>('/TenantData/' + tenantKey + '/Assessments/' + companyKey + '/', ref => ref.orderByChild('isDeleted').equalTo(false)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchEngagement(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object<Engagement>('/TenantData/' + tenantKey + '/Assessments/' + companyKey + '/' + engagementKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }

  fetchEngagementRespondentList(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.list<EngagementRespondent>('/TenantData/' + tenantKey + '/AssessmentDetails/' + companyKey + '/' + engagementKey + '/AssessmentRespondentList/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchEngagementRespondent(tenantKey: string, companyKey: string, engagementKey: string, respondentKey: string) {
    return this.db.object<EngagementRespondent>('/TenantData/' + tenantKey + '/AssessmentDetails/' + companyKey + '/' + engagementKey + '/AssessmentRespondentList/' + respondentKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }



  fetchRespondents(tenantKey: string, companyKey: string) {
    return this.db.list<Respondent>('/TenantData/' + tenantKey + '/RespondentList/' + companyKey + '/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }

  removeEngagementRespondent(tenantKey: string, companyKey: string, engagementKey: string, respondentKey: string) {
    this.db.object<any>('/TenantData/' + tenantKey + '/AssessmentDetails/' + companyKey + '/' + engagementKey + '/AssessmentRespondentList/' + respondentKey + '/status').set('DELETED');
    return this.db.object<any>('/TenantData/' + tenantKey + '/AssessmentDetails/' + companyKey + '/' + engagementKey + '/AssessmentRespondentList/' + respondentKey + '/actualStatus').set('DELETED');
  }

  deleteEngagementRespondent(tenantKey: string, companyKey: string, engagementKey: string, respondentKey: string) {
    return this.db.object<any>('/TenantData/' + tenantKey + '/AssessmentDetails/' + companyKey + '/' + engagementKey + '/AssessmentRespondentList/' + respondentKey).remove();
  }

  fetchEngagementMetrics(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.list<EngagementMetric>('/TenantData/' + tenantKey + '/AssessmentDetails/' + companyKey + '/' + engagementKey + '/AssessmentRespondentMetrics/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }

  fetchPredefinedMetrics(tenantKey: string, companyKey: string, respondentKey: string) {
    return this.db.list<any>('/TenantData/' + tenantKey + '/RespondentDetails/' + companyKey + '/' + respondentKey).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchRespondentFromPool(tenantKey: string, companyKey: string, respondentEmail: string) {
    return this.db.list<Respondent>('/TenantData/' + tenantKey + '/RespondentList/' + companyKey + '/', ref => ref.orderByChild('email').equalTo(respondentEmail)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  updatePredefinedMetrics(tenantKey: string, companyKey: string, respondentKey: string, metrics: any[]) {
    return this.db.object<any>('/TenantData/' + tenantKey + '/RespondentDetails/' + companyKey + '/' + respondentKey).update(metrics);
  }

  updateEngagementRespondent(tenantKey: string, companyKey: string, engagementKey: string, engagementRespondent: EngagementRespondent) {
    if (!engagementRespondent.key) {
      engagementRespondent.key = this.db.createPushId();
      engagementRespondent.referenceKey = this.db.createPushId();
      engagementRespondent.companyKey = companyKey;
      engagementRespondent.tenantKey = tenantKey;
    }
    return this.db.object<EngagementRespondent>('/TenantData/' + tenantKey + '/AssessmentDetails/' +
      companyKey + '/' + engagementKey + '/AssessmentRespondentList/' + engagementRespondent.key).update(engagementRespondent);
  }



  updateEngagementReference(respondentReference: RespondentReference) {
    return this.db.object('/AssessmentReferences/' + respondentReference.key).update(respondentReference);
  }

  updateEngagementMetrics(tenantKey: string, companyKey: string, engagementKey: string, metrics: EngagementMetric[]) {
    for (let i = 0; i < metrics.length; i++) {
      let metric = metrics[i];
      if (!metric.key)
        metric.key = this.db.createPushId();
    }
    return this.db.object<EngagementMetric[]>('/TenantData/' + tenantKey + '/AssessmentDetails/' +
      companyKey + '/' + engagementKey + '/AssessmentRespondentMetrics/').set(metrics);
  }

  updateRespondentList(tenantKey: string, companyKey: string, respondent: Respondent) {
    return this.db.object<Respondent>('/TenantData/' + tenantKey + '/RespondentList/' + companyKey + '/' + respondent.key).update(respondent)
  }

  removeRespondentFromPool(tenantKey: string, companyKey: string, respondentKey: string) {
    return this.db.object<Respondent>('/TenantData/' + tenantKey + '/RespondentList/' + companyKey + '/' + respondentKey).remove();
  }

  fetchAssessmentQuestionnaires() {
    return this.db.list<Questionnaire>('/Questionnaires/', ref => ref.orderByChild('isAssessment').equalTo(true)).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  updateEngagementRespondentSurvey(tenantKey: string, companyKey: string, engagementKey: string, analysis: LeaverAnalysis) {
    return this.db.object<any>('/AssessmentAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/' + analysis.key).update(analysis);
  }


  fetchAssessmentTemplates(tenantKey: string, companyKey: string) {
    return this.db.list<Questionnaire>('/TenantData/' + tenantKey + '/AssessmentTemplates/' + companyKey + '/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }

  removeAssessmentTemplate(tenantKey: string, companyKey: string, surveyTemplate: Questionnaire) {
    return this.db.object<Questionnaire>('/TenantData/' + tenantKey + '/AssessmentTemplates/' + companyKey + '/' + surveyTemplate.key).remove();
  }

  updateAssessmentTemplate(tenantKey: string, companyKey: string, surveyTemplate: Questionnaire) {
    return this.db.object<Questionnaire>('/TenantData/' + tenantKey + '/AssessmentTemplates/' + companyKey + '/' + surveyTemplate.key).update(surveyTemplate);
  }



  fetchEngagementReference(engagementReference: string) {
    return this.db.object<EngagementReference>('/AssessmentReferences/' + engagementReference).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }


  removeEngagement(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object<any>('/TenantData/' + tenantKey + '/Assessments/' + companyKey + '/' + engagementKey + '/isDeleted/').set(true);
  }

  removeEngagementRespondentList(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object('/TenantData/' + tenantKey + '/AssessmentDetails/' + companyKey + '/' + engagementKey).remove();
  }

  removeEngagementRespondentAnalysis(tenantKey: string, companyKey: string,
    engagementKey: string, respondentKey: string) {
    return this.db.object<any>('/AssessmentAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/' + respondentKey).remove();
  }


  addRespondent(request) {
    return this.http.post(this.sEndpoint + 'Assessment/AddRespondent', request);
  }

  addRespondentFromLandingPage(request) {
    return this.http.post(this.sEndpoint + 'Assessment/AddRespondentFromLandingPage', request);
  }

  fetchOverviewAnalysis(request) {
    return this.http.post(this.sEndpoint + 'Assessment/GetOverviewAnalysis', request);
  }

  migrationOverviewAnalysis(request) {
    return this.http.post(this.sEndpoint + 'Assessment/MigrationGetOverviewAnalysis', request);
  }

  fetchAssessmentRespondents(request) {
    return this.http.post(this.sEndpoint + 'Assessment/FetchAssessmentRespondents', request);
  }

  fetchAllRespondents(request){
    return this.http.post(this.sEndpoint + 'Assessment/FetchAllRespondents', request);
  }

  
  fetchSummaryAnalysis(request){
    return this.http.post(this.sEndpoint + 'Assessment/FetchOverallSummary', request);
  }

  
  fetchHierarchyCount(request) {
    return this.http.post(this.sEndpoint + 'Assessment/GetCountInHirarchy', request);
  }

  fetchCommentAnalysis(request) {
    return this.http.post(this.sEndpoint + 'Assessment/GetCommentAnalysis', request);
  }

  insertEngagementTemplate(request) {
    return this.http.post(this.sEndpoint + 'SurveyDetails/SurveyGoLive', request);
  }

  updateEvaluatorLimit(request) {
    return this.http.post(this.sEndpoint + 'Assessment/UpdateEvaluator', request);
  }


  fetchSurveyRespondent(request) {
    return this.http.post(this.sEndpoint + 'Assessment/FetchSurveyRespondets', request);
  }

  updateFinalSurvey(request) {
    return this.http.post(this.sEndpoint + 'UpdateSurveyDetails', request);
  }


  removeRespondents(request) {
    return this.http.post(this.sEndpoint + 'Assessment/RemoveRespondents', request);  
  }


  fetchAssessmentList(request) {
    return this.http.post(this.sEndpoint + 'Assessment/FetchAllRespondents', request);
  }


  insertSurveyAnswers(request) {
    return this.http.post(this.sEndpoint + 'Assessment/SubmitAnswers', request);
  }

  updateRespondent(request){
    return this.http.post(this.sEndpoint + 'Assessment/UpdateRespondent', request);
  }

  fetchEngagementAnalysis(tenantKey: string, companyKey: string,
    engagementKey: string) {
    return this.db.list<LeaverAnalysis>('/AssessmentAnalysis/' + tenantKey + '/' + companyKey + '/' + engagementKey + '/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  checkBalanceAndCreditAssessment(request) {
    return this.http.post(this.fbFunctionURL + 'creditAssessment', request);
  }

  getPreviousScores(request){
    return this.http.post(this.sEndpoint + 'Assessment/GetPreviousScores', request);
  }
  closeCandidate(request){
    return this.http.post(this.sEndpoint + 'Assessment/CloseCandidate', request);
  }
  getComparisonExcel(request){
    return this.http.post(this.sEndpoint + 'Assessment/GetExcelComparison', request);
  }
  getProgressExcel(request){
    return this.http.post(this.sEndpoint + 'Assessment/GetExcelProgress', request);
  }
  registerAndFetchCandidateHistory(request) {
    return this.http.post(this.fbFunctionURL + 'registerAndFetchCandidateHistory', request);
  }

}
