import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { BYPASS_LOG } from '../../utils/_interceptors/loader-interceptor.interceptor';
import { SendSurveyEmail } from '../models/send-survey-email';




@Injectable({
  providedIn: 'root'
})
export class SurveysService {
  errorMessage: any;
  postId: any;

  URL = environment.endPoint;

  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'https://assessments.zenithr.net',
      'Content-Type': 'application/json;'
    })
  }

  constructor(private httpClient: HttpClient) { }

  getAllSurveys(filters: any) {

    return this.httpClient.post(this.URL + 'form-table/get-all-form', filters);
  }

  getAllSurveysByTenant(filters: any, tenantId: any, companyId: any, pageNumber: any, pageSize: any) {
    return this.httpClient.post(this.URL + `form-table/get-all-forms-by-tenant/${tenantId}/${companyId}/${pageNumber}/${pageSize}`, filters);
  }
  getAllSurveysByTenant2(filters: any, tenantId: any, pageNumber: any, pageSize: any) {
    return this.httpClient.post(this.URL + `form-table/get-all-forms-by-tenant/${tenantId}/${pageNumber}/${pageSize}`, filters, { context: new HttpContext().set(BYPASS_LOG, true) });
  }


  async getAllSurveysByCategory(categoryId: any, tenantId: any) {
    return await this.httpClient.get(this.URL + `form-table/get-all-forms-by-category/${categoryId}/${tenantId}`);
  }

  getAllCategories(formTypeId: any, tenantId: any, companyId: string) {

    return this.httpClient.get(this.URL + `form-category/get-all-category/${tenantId}/${companyId}/${formTypeId}`);
  }
  getCategory(categoryId: any) {

    return this.httpClient.get(this.URL + 'form-category/get-form-category/' + categoryId);
  }
  updateCategory(categoryId: any, categoryName: any) {

    return this.httpClient.put(this.URL + 'form-category/update-category/' + categoryId, categoryName);
  }

  createCategory(body: any) {
    return this.httpClient.post(this.URL + 'form-category/add-category', body);
  }
  deleteCategory(id: any) {
    return this.httpClient.delete(this.URL + `form-category/delete-category/${id}`);
  }

  getAllFormTypes() {
    return this.httpClient.get(this.URL + 'form-type/get-all-form-types');
  }

  async getFormType(formTypeId: any) {
    return await this.httpClient.get(this.URL + `form-type/get-form-type/${formTypeId}`).toPromise();
  }

  async getSurvey(id: any) {
    return await this.httpClient.get(this.URL + `form-table/get-form/${id}`).toPromise();
  }

  async getSurveyWithoutResponses(id: any) {
    return await this.httpClient.get(this.URL + `form-table/get-form-no-responses/${id}`).toPromise();
  }

  async createSurvey(surveyForm: {}) {
    return await this.httpClient.post<any>(this.URL + "form-table/add-form", surveyForm).toPromise();
  }

  async updateSurvey(surveyForm: {}, id: any) {
    return await this.httpClient.put(this.URL + `form-table/update-form/${id}`, surveyForm, this.httpOptions).toPromise();
  }

  async updateSurveyInitial(surveyForm: {}, id: any) {
    return await this.httpClient.put(this.URL + `form-table/update-form-initial/${id}`, surveyForm, this.httpOptions).toPromise();
  }

  async updateSurveyStatus(surveyForm: {}, id: any) {
    return await this.httpClient.put(this.URL + `form-table/update-form-status/${id}`, surveyForm, this.httpOptions).toPromise();
  }
  async updateSurveyTitle(id: any, formTitle: any) {
    return await this.httpClient.put(this.URL + `form-table/update-form-title/${id}/${formTitle}`, this.httpOptions).toPromise();
  }

  deleteSurvey(id: any) {
    return this.httpClient.delete(this.URL + `form-table/delete-form/${id}`, this.httpOptions);
  }

  cloneSurvey(id: any) {
    return this.httpClient.post(this.URL + `form-table/clone-form/${id}`, this.httpOptions);
  }

  relaunchSurvey(id: any) {
    return this.httpClient.post(this.URL + `form-table/relaunch-form/${id}`, this.httpOptions);
  }

  sendSurveyByEmail(sendSurveyEmail: SendSurveyEmail) {
    return this.httpClient.post(this.URL + 'send-survey-email/send-email', sendSurveyEmail, this.httpOptions);
  }
  sendReminderByEmail(sendReminderEmail: SendSurveyEmail) {
    return this.httpClient.post(this.URL + 'send-survey-email/send-reminder-email', sendReminderEmail, this.httpOptions);
  }

  async getSurveyResponseByFormIdAndRespondentId(formId: any, respondentId: any, tenantId: any) {
    return await this.httpClient.get(this.URL + `form-response/get-form-response-by-formid-and-respondentid/${formId}/${respondentId}/${tenantId}`).toPromise();
  }

  getAllEmailTemplatesByTenant(tenantId: any) {
    return this.httpClient.get(this.URL + `email-template/get-all-templates/${tenantId}`);
  }
  async createEmailTemplate(emailTemplate: {}) {
    return await this.httpClient.post<any>(this.URL + "email-template/add-template", emailTemplate).toPromise();
  }
  getEmailTemplate(id: any) {
    return this.httpClient.get(this.URL + `email-template/get-template/${id}`);
  }


  updateFormTableRespondentFeedback(formId: any, respondentId: any, tenantId: any, formTableRespondentFeedback: any) {
    return this.httpClient.put(this.URL + `form-table-respondents/update-form-table-respondent-feedback/${formId}/${respondentId}/${tenantId}`, formTableRespondentFeedback);
  }




  private StatusChanged = new BehaviorSubject<any>(null);

  StatusChanged$ = this.StatusChanged.asObservable();

  setStatusChanged(status: any) {

    this.StatusChanged.next(status);

  }

}
