import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss']
})
export class CurrencyDropdownComponent implements OnInit {

  @Input() selectedCurrency: string = 'USD';
  @Output() selectedCurrencyChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}

/**
 * Returns the currency symbol based on the provided currency code.
 * @param value The currency code.
 * @returns The currency symbol or undefined if not found.
 */
export function getCurrencySymbol(value: string): string | undefined {
  const currencies: { [key: string]: string } = {
    // Afghan Afghani
    AFN: "؋",
    // Albanian Lek
    ALL: "Lek",
    // Algerian Dinar
    DZD: "دج",
    // Angolan Kwanza
    AOA: "Kz",
    // Argentine Peso
    ARS: "$",
    // Armenian Dram
    AMD: "֏",
    // Aruban Florin
    AWG: "ƒ",
    // Australian Dollar
    AUD: "$",
    // Azerbaijani Manat
    AZN: "m",
    // Bahamian Dollar
    BSD: "B$",
    // Bahraini Dinar
    BHD: ".د.ب",
    // Bangladeshi Taka
    BDT: "৳",
    // Barbadian Dollar
    BBD: "Bds$",
    // Belarusian Ruble
    BYR: "Br",
    // Belgian Franc
    BEF: "fr",
    // Belize Dollar
    BZD: "$",
    // Bermudan Dollar
    BMD: "$",
    // Bhutanese Ngultrum
    BTN: "Nu.",
    // Bitcoin
    BTC: "฿",
    // Bolivian Boliviano
    BOB: "Bs.",
    // Bosnia-Herzegovina Convertible Mark
    BAM: "KM",
    // Botswanan Pula
    BWP: "P",
    // Brazilian Real
    BRL: "R$",
    // British Pound Sterling
    GBP: "£",
    // Brunei Dollar
    BND: "B$",
    // Bulgarian Lev
    BGN: "Лв.",
    // Burundian Franc
    BIF: "FBu",
    // Cambodian Riel
    KHR: "KHR",
    // Canadian Dollar
    CAD: "$",
    // Cape Verdean Escudo
    CVE: "$",
    // Cayman Islands Dollar
    KYD: "$",
    // West African CFA Franc
    XOF: "CFA",
    // Central African CFA Franc
    XAF: "FCFA",
    // CFP Franc
    XPF: "₣",
    // Chilean Peso
    CLP: "$",
    // Chinese Yuan
    CNY: "¥",
    // Colombian Peso
    COP: "$",
    // Comorian Franc
    KMF: "CF",
    // Congolese Franc
    CDF: "FC",
    // Costa Rican Colón
    CRC: "₡",
    // Croatian Kuna
    HRK: "kn",
    // Cuban Convertible Peso
    CUC: "CUC",
    // Czech Republic Koruna
    CZK: "Kč",
    // Danish Krone
    DKK: "Kr.",
    // Djiboutian Franc
    DJF: "Fdj",
    // Dominican Peso
    DOP: "$",
    // East Caribbean Dollar
    XCD: "$",
    // Egyptian Pound
    EGP: "ج.م",
    // Eritrean Nakfa
    ERN: "Nfk",
    // Estonian Kroon
    EEK: "kr",
    // Ethiopian Birr
    ETB: "Nkf",
    // Euro
    EUR: "€",
    // Falkland Islands Pound
    FKP: "£",
    // Fijian Dollar
    FJD: "FJ$",
    // Gambian Dalasi
    GMD: "D",
    // Georgian Lari
    GEL: "ლ",
    // German Mark
    DEM: "DM",
    // Ghanaian Cedi
    GHS: "GH₵",
    // Gibraltar Pound
    GIP: "£",
    // Greek Drachma
    GRD: "₯",
    // Guatemalan Quetzal
    GTQ: "Q",
    // Guinean Franc
    GNF: "FG",
    // Guyanaese Dollar
    GYD: "$",
    // Haitian Gourde
    HTG: "G",
    // Honduran Lempira
    HNL: "L",
    // Hong Kong Dollar
    HKD: "$",
    // Hungarian Forint
    HUF: "Ft",
    // Icelandic Króna
    ISK: "kr",
    // Indian Rupee
    INR: "₹",
    // Indonesian Rupiah
    IDR: "Rp",
    // Iranian Rial
    IRR: "﷼",
    // Iraqi Dinar
    IQD: "د.ع",
    // Israeli New Shekel
    ILS: "₪",
    // Italian Lira
    ITL: "L,£",
    // Jamaican Dollar
    JMD: "J$",
    // Japanese Yen
    JPY: "¥",
    // Jordanian Dinar
    JOD: "ا.د",
    // Kazakhstani Tenge
    KZT: "лв",
    // Kenyan Shilling
    KES: "KSh",
    // Kuwaiti Dinar
    KWD: "ك.د",
    // Kyrgystani Som
    KGS: "лв",
    // Laotian Kip
    LAK: "₭",
    // Latvian Lats
    LVL: "Ls",
    // Lebanese Pound
    LBP: "£",
    // Lesotho Loti
    LSL: "L",
    // Liberian Dollar
    LRD: "$",
    // Libyan Dinar
    LYD: "د.ل",
    // Lithuanian Litas
    LTL: "Lt",
    // Macanese Pataca
    MOP: "$",
    // Macedonian Denar
    MKD: "ден",
    // Malagasy Ariary
    MGA: "Ar",
    // Malawian Kwacha
    MWK: "MK",
    // Malaysian Ringgit
    MYR: "RM",
    // Maldivian Rufiyaa
    MVR: "Rf",
    // Mauritanian Ouguiya
    MRO: "MRU",
    // Mauritian Rupee
    MUR: "₨",
    // Mexican Peso
    MXN: "$",
    // Moldovan Leu
    MDL: "L",
    // Mongolian Tögrög
    MNT: "₮",
    // Moroccan Dirham
    MAD: "د.م.",
    // Mozambican Metical
    MZN: "MT",
    // Myanmar Kyat
    MMK: "K",
    // Namibian Dollar
    NAD: "$",
    // Nepalese Rupee
    NPR: "₨",
    // Netherlands Antillean Guilder
    ANG: "ƒ",
    // New Taiwan Dollar
    TWD: "NT$",
    // New Zealand Dollar
    NZD: "$",
    // Nicaraguan Córdoba
    NIO: "C$",
    // Nigerian Naira
    NGN: "₦",
    // North Korean Won
    KPW: "₩",
    // Norwegian Krone
    NOK: "kr",
    // Omani Rial
    OMR: "﷼",
    // Pakistani Rupee
    PKR: "₨",
    // Panamanian Balboa
    PAB: "B/.",
    // Papua New Guinean Kina
    PGK: "K",
    // Paraguayan Guarani
    PYG: "₲",
    // Peruvian Sol
    PEN: "S/.",
    // Philippine Peso
    PHP: "₱",
    // Polish Złoty
    PLN: "zł",
    // Portuguese Escudo
    PTE: "Esc",
    // Qatari Rial
    QAR: "﷼",
    // Romanian Leu
    RON: "lei",
    // Russian Ruble
    RUB: "₽",
    // Rwandan Franc
    RWF: "FRw",
    // Saint Helena Pound
    SHP: "£",
    // Salvadoran Colón
    SVC: "$",
    // Samoan Tala
    WST: "WS$",
    // Saudi Riyal
    SAR: "﷼",
    // Serbian Dinar
    RSD: "дин.",
    // Seychellois Rupee
    SCR: "₨",
    // Sierra Leonean Leone
    SLL: "Le",
    // Singapore Dollar
    SGD: "$",
    // Solomon Islands Dollar
    SBD: "SI$",
    // Somali Shilling
    SOS: "S",
    // South African Rand
    ZAR: "R",
    // South Korean Won
    KRW: "₩",
    // South Sudanese Pound
    SSP: "£",
    // Spanish Peseta
    ESP: "₧",
    // Sri Lankan Rupee
    LKR: "₨",
    // Sudanese Pound
    SDG: "£",
    // Surinamese Dollar
    SRD: "$",
    // Swazi Lilangeni
    SZL: "L",
    // Swedish Krona
    SEK: "kr",
    // Swiss Franc
    CHF: "Fr.",
    // Syrian Pound
    SYP: "£",
    // Tajikistani Somoni
    TJS: "ЅМ",
    // Tanzanian Shilling
    TZS: "TSh",
    // Thai Baht
    THB: "฿",
    // Tongan Pa'anga
    TOP: "T$",
    // Trinidad and Tobago Dollar
    TTD: "TT$",
    // Tunisian Dinar
    TND: "د.ت",
    // Turkish Lira
    TRY: "₺",
    // Turkmenistani Manat
    TMT: "m",
    // Ugandan Shilling
    UGX: "USh",
    // Ukrainian Hryvnia
    UAH: "₴",
    // United Arab Emirates Dirham
    AED: "د.إ",
    // United States Dollar
    USD: "$",
    // Uruguayan Peso
    UYU: "$U",
    // Uzbekistan Som
    UZS: "лв",
    // Vanuatu Vatu
    VUV: "VT",
    // Venezuelan Bolívar
    VES: "Bs.",
    // Vietnamese Đồng
    VND: "₫",
    // Yemeni Rial
    YER: "﷼",
    // Zambian Kwacha
    ZMW: "ZK",
    // Zimbabwean Dollar
    ZWL: "Z$",
  };

  return currencies[value];
}
