<div *ngIf="!changingLanguage" class="flex flex-col gap-5  w-full">
  <div class="md:flex flex-row justify-between items-center gap-3 hidden">
    <div class="w-full"></div>

    <div *ngIf="question.questionType == 'sub-questions-confidence-rating'" class="flex flex-row md:w-5/12 justify-evenly">
      <h5 class="text-zen text-opacity-80 text-xs font-medium w-1/4 text-center">{{
        getConfidence('sub-questions-confidence-rating', 'SDA')
        }}</h5>
      <h5 class="text-zen text-opacity-80 text-xs font-medium w-1/4 text-center">{{
        getConfidence('sub-questions-confidence-rating', 'DA')
        }}</h5>
      <h5 class="text-zen text-opacity-80 text-xs font-medium w-1/4 text-center">{{
        getConfidence('sub-questions-confidence-rating', 'A')
        }}</h5>

      <h5 class="text-zen text-opacity-80 text-xs font-medium w-1/4 text-center">
        {{ getConfidence('sub-questions-confidence-rating', 'STA') }}</h5>
    </div>
    <div  *ngIf="question.questionType == 'sub-questions-likeness-rating' || question.questionType == 'sub-questions-likeness-na-rating'"  class="flex flex-row  justify-evenly md:w-5/12">
      <h5 *ngIf="question.questionType == 'sub-questions-likeness-na-rating'"
      class="text-zen text-opacity-80 text-xs font-medium w-1/5 text-center">{{
      getConfidence('sub-questions-likeness-rating', 'NA')
      }}</h5>
      <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"
        [ngClass]="question.questionType == 'sub-questions-likeness-na-rating'?'w-1/5':'w-1/4'">{{
        getConfidence('sub-questions-likeness-rating', 'P')
        }}</h5>
        <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"
        [ngClass]="question.questionType == 'sub-questions-likeness-na-rating'?'w-1/5':'w-1/4'">{{
        getConfidence('sub-questions-likeness-rating', 'F')
        }}</h5>
        <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"
        [ngClass]="question.questionType == 'sub-questions-likeness-na-rating'?'w-1/5':'w-1/4'">{{
        getConfidence('sub-questions-likeness-rating', 'G')
        }}</h5>
  
      <h5 class="text-zen text-opacity-80 text-xs font-medium  text-center"
        [ngClass]="question.questionType == 'sub-questions-likeness-na-rating'?'w-1/5':'w-1/4'">
        {{ getConfidence('sub-questions-likeness-rating', 'EX') }}</h5>
    </div>
  </div>

  <div class="flex flex-row flex-wrap">
    <div class="md:flex flex-row justify-between items-center w-full py-1"
      *ngFor="let subQuestion of question.subQuestions; let ssq = index">
      <div class="flex flex-col w-full">
        <div class="flex flex-row w-full gap-0 md:gap-1 flex-wrap md:flex-nowrap  p-4 md:p-0">
          <div class="flex  md:bg-white  rounded px-5 md:leading-12 exp w-full">
            <p class="text-zen text-base md:leading-7"> {{extractNameLanguage(subQuestion.name)}}</p>
          </div>
          <div *ngIf="question.questionType == 'sub-questions-confidence-rating'"
            class="flex flex-row md:w-5/12 w-full gap-2 md:gap-1 md:pt-0 pt-2">
            <div class="flex flex-col items-center gap-2 justify-center w-1/4 h-full">
              <div class="flex flex-col items-center justify-center w-full btn h-full  bg-white border-2 border-inset rounded"  (click)="subQuestion.answer = 'SDA'"  [ngClass]="subQuestion.answer == 'SDA'?'border-exp text-exp':'border-transparent'">
                <i class="fa-light fa-face-disappointed text-2xl rounded w-full text-center"></i>
              </div>
                <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
                  {{ getConfidence('sub-questions-confidence-rating', 'SDA') }}</h5>
            </div>
            <div class="flex flex-col items-center gap-2 justify-center w-1/4 h-full">
              <div class="flex flex-col items-center justify-center w-full btn h-full  bg-white border-2 border-inset rounded"  (click)="subQuestion.answer = 'DA'"  [ngClass]="subQuestion.answer == 'DA'?'border-exp text-exp':'border-transparent'">
                <i class="fa-light fa-face-thinking text-2xl rounded w-full text-center"></i>
              </div>
                <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
                  {{ getConfidence('sub-questions-confidence-rating', 'DA') }}</h5>
            </div>
            <div class="flex flex-col items-center gap-2 justify-center w-1/4 h-full">
              <div class="flex flex-col items-center justify-center w-full btn h-full  bg-white border-2 border-inset rounded"  (click)="subQuestion.answer = 'A'"  [ngClass]="subQuestion.answer == 'A'?'border-exp text-exp':'border-transparent'">
                <i class="fal fa-smile text-2xl rounded w-full text-center"></i>
              </div>
                <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
                  {{ getConfidence('sub-questions-confidence-rating', 'A') }}</h5>
            </div>
            <div class="flex flex-col items-center gap-2 justify-center w-1/4 h-full">
              <div class="flex flex-col items-center justify-center w-full btn h-full  bg-white border-2 border-inset rounded"  (click)="subQuestion.answer = 'STA'"  [ngClass]="subQuestion.answer == 'STA'?'border-exp text-exp':'border-transparent'">
                <i class="fal fa-smile-beam text-2xl rounded w-full text-center"></i>
              </div>
                <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
                  {{ getConfidence('sub-questions-confidence-rating', 'STA') }}</h5>
            </div>
          </div>
          <div  *ngIf="question.questionType == 'sub-questions-likeness-rating' || question.questionType == 'sub-questions-likeness-na-rating'" class="flex flex-row md:w-5/12 w-full gap-2 md:gap-1 md:pt-0 pt-2">
            <div *ngIf="question.questionType == 'sub-questions-likeness-na-rating'" class="flex flex-col items-center gap-2 justify-center w-1/5">
              <div  class="flex flex-col items-center justify-center w-full btn h-full bg-white border-2 border-inset rounded" (click)="subQuestion.answer = 'NA'"  [ngClass]="subQuestion.answer == 'NA'?'border-exp text-exp':'border-transparent'">
              <i   class="fal fa-ban text-2xl rounded w-full text-center"></i>
            </div>
            <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
              {{ getConfidence('sub-questions-likeness-rating', 'NA') }}</h5>
            </div>
         

            <div class="flex flex-col items-center gap-2 justify-center " [ngClass]="question.questionType == 'sub-questions-likeness-na-rating'?'w-1/5':'w-1/4'">
              <div class="flex flex-col items-center justify-center w-full btn h-full bg-white border-2 border-inset rounded"  (click)="subQuestion.answer = 'P'"  [ngClass]="subQuestion.answer == 'P'?'border-exp text-exp':'border-transparent'">
                <i class="fa-light fa-face-disappointed text-2xl rounded w-full text-center"></i>
              </div>
                <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
                  {{ getConfidence('sub-questions-likeness-rating', 'P') }}</h5>
            </div>
            <div class="flex flex-col items-center gap-2 justify-center " [ngClass]="question.questionType == 'sub-questions-likeness-na-rating'?'w-1/5':'w-1/4'">
              <div class="flex flex-col items-center justify-center w-full btn h-full bg-white border-2 border-inset rounded"  (click)="subQuestion.answer = 'F'"  [ngClass]="subQuestion.answer == 'F'?'border-exp text-exp':'border-transparent'">
                <i class="fa-light fa-face-thinking text-2xl rounded w-full text-center"></i>
              </div>
                <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
                  {{ getConfidence('sub-questions-likeness-rating', 'F') }}</h5>
            </div>
            <div class="flex flex-col items-center gap-2 justify-center " [ngClass]="question.questionType == 'sub-questions-likeness-na-rating'?'w-1/5':'w-1/4'">
              <div class="flex flex-col items-center justify-center w-full btn h-full bg-white border-2 border-inset rounded"  (click)="subQuestion.answer = 'G'"  [ngClass]="subQuestion.answer == 'G'?'border-exp text-exp':'border-transparent'">
                <i class="fal fa-smile text-2xl rounded w-full text-center"></i>
              </div>
                <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
                  {{ getConfidence('sub-questions-likeness-rating', 'G') }}</h5>
            </div>
            <div class="flex flex-col items-center gap-2 justify-center " [ngClass]="question.questionType == 'sub-questions-likeness-na-rating'?'w-1/5':'w-1/4'">
              <div class="flex flex-col items-center justify-center w-full btn h-full bg-white border-2 border-inset rounded"  (click)="subQuestion.answer = 'EX'"  [ngClass]="subQuestion.answer == 'EX'?'border-exp text-exp':'border-transparent'">
                <i class="fal fa-smile-beam text-2xl rounded w-full text-center"></i>
              </div>
                <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
                  {{ getConfidence('sub-questions-likeness-rating', 'EX') }}</h5>
            </div>
          </div>
        </div>
        <div class="flex flex-row flex-wrap md:flex-nowrap gap-2 mt-2 w-full"
          *ngIf="subQuestion.otherAnswerChecked && subQuestion.otherAnswer">
          <div class="flex flex-col md:w-1/2 w-full">
            <div class="bg-zen-green bg-opacity-5 rounded-md px-5 pt-1 overflow-hidden ">
              <mat-form-field class="w-full -mt-3" appearance="none">
                <textarea rows="3" [attr.disabled]="countWord(question.otherAnswer1, lgCount) > lgCount ? true : null"
                  maxlength="300" (keyup)="countWord(question.otherAnswer1, lgCount)"
                  [placeholder]="'txtMostLiked'| translate:language:ModuleNames.Shared" [(ngModel)]="question.otherAnswer1"
                  matInput></textarea>
              </mat-form-field>
            </div>
            <h4 *ngIf=" countWord(question.otherAnswer1, lgCount)> 0"
              [ngClass]="{'text-zen-red': countWord(question.otherAnswer1, lgCount)>= lgCount}" class="text-xxs">
              {{countWord(question.otherAnswer1, lgCount)}}
              {{'txtof'| translate:language:ModuleNames.Shared}} {{lgCount}}</h4>
          </div>
          <div class="flex flex-col md:w-1/2 w-full">
            <div class="bg-zen-red bg-opacity-5 rounded-md px-5 pt-1 overflow-hidden ">
              <mat-form-field class="w-full -mt-3" appearance="none">
                <textarea rows="3" [attr.disabled]="countWord(question.otherAnswer2, lgCount) > lgCount ? true : null"
                  maxlength="300" (keyup)="countWord(question.otherAnswer2, lgCount)"
                  [placeholder]="'txtLeastLiked'| translate:language:ModuleNames.Shared" [(ngModel)]="question.otherAnswer2"
                  matInput></textarea>
              </mat-form-field>

            </div>
            <h4 *ngIf=" countWord(question.otherAnswer2, lgCount)> 0"
              [ngClass]="{'text-zen-red': countWord(question.otherAnswer2, lgCount)>= lgCount}" class="text-xxs">
              {{countWord(question.otherAnswer2, lgCount)}}
              {{'txtof'| translate:language:ModuleNames.Shared}} {{lgCount}}</h4>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>