<zen-slider [(ngModel)]="question.answer" [steps]="steps" [language]="language">

    <ng-container legend>
        <div class="flex flex-row justify-between" *ngIf="mainLegends?.length">
            <span *ngFor="let value of mainLegends"
                class="text-xs text-zen-lite transform translate-y-2 upperFirstLetter">{{value}}</span>
        </div>
        <div *ngIf="legendDetail" class="flex flex-row  items-center justify-between  h-2.5 mb-5 -ml-1 -mr-1">
            <div class="px-1 " [style.width.%]="legend.steps / steps.length * 100"
                *ngFor="let legend of legends; let n = index">
                <div
                    class="h-2.5 border-r border-l border-b border-zen border-opacity-20 flex flex-row items-end justify-center">
                    <span class="text-xs text-zen-lite transform translate-y-2 px-3 upperFirstLetter bg-zen-shaded">{{legend.name | translate:language:moduleName.Exit
                        }}</span>
                </div>
            </div>
        </div>
    </ng-container>

</zen-slider>