import { Component, ElementRef, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';

@Component({
  selector: 'z-textarea',
  templateUrl: './zen-textarea.component.html',
  styleUrls: ['./zen-textarea.component.scss'],

  animations: [ChildrenAnimation]
})
export class ZenTextareaComponent implements ControlValueAccessor {

  constructor(
    private el: ElementRef
    , @Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label;
  @Input() placeholder;
  @Input() maxCount
  @Input() set submitted(sub) {
    if (sub)
      this.ngControl.control.markAsTouched();
  }
  @Input() language
  selected;
  hide: boolean = true;
  isDisabled: boolean = false;
  disable: boolean = false;
  onChange: any = () => { };
  onTouch: any = () => { };
  Validators = Validators;
  writeValue(selected: any) {
    if (selected !== undefined && this.selected !== selected) {
      this.selected = selected;
    }
  }

  registerOnChange(fn: any) {

    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  handleChange(option) {
    this.selected = option;
    this.onChange(option);
  }
}

