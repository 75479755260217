<!-- Category Tooltip https://stackoverflow.com/questions/45753351/kendo-ui-angular-2-chart-series-tooltip-template-access-to-category-value-a -->
<!-- <div id='emptyanchor' style="display: none;"></div> -->
<div [id]="dashlet?.Title + '-chart'" *ngIf="dashlet" (click)="logData(dashlet)" class="block w-full" #divToMeasure [ngClass]="{'exportView': export}" [class]="exportMode">
  <ng-container>
    <ng-container *ngIf="dashlet.Title != '' && !export && !hideHeader">
      <ng-container *ngTemplateOutlet="header; context: { $implicit: dashlet }">
      </ng-container>
    </ng-container>
    <div [NoDataOverlay]="data?.categories?.length" *ngIf="(dashlet.DashletType != 'barH' && dashlet.DashletType != 'stackH') || !customChart" [ngClass]="{'exportHeight': export}" class="w-full rounded border border-zen border-opacity-10 shadow-sm bg-white">
      <ng-container *ngIf="dashlet.DashletType == 'pie' && data">
        <div class="chart-wrapper">
          <div class="zen-chart zen-pie-chart" [id]="dashlet.ItemID + 'canvas'">
            <kendo-chart (seriesClick)="pieSeriesClick($event)" [renderAs]="export ? 'svg' : 'canvas'">
              <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
              </kendo-chart-legend>
              <kendo-chart-tooltip>
                <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem" let-value="value">
                  {{ value.toFixed(1) + dataSymbol }}
                </ng-template>
              </kendo-chart-tooltip>
              <kendo-chart-series>
                <kendo-chart-series-item [color]="getSerieColor(series?.name, n)" colorField="color" [gap]="1" [spacing]="0.25" autoFit="true" type="pie" [data]="data.pie" field="value" categoryField="name">
                  <kendo-chart-series-item-labels font="12px sans-serif" color="#4a4a4a" position="outsideEnd" color="#4a4a4a" [content]="pieLabelContent">
                  </kendo-chart-series-item-labels>
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </div>
          <!-- <span class="weightedFTE"><i class="far fa-info-circle"></i>Weighted by FTE</span> -->
        </div>
      </ng-container>
      <ng-container *ngIf="dashlet.DashletType == 'radarArea' && data">
        <kendo-chart (seriesClick)="getRadarSeriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'">
          <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
          </kendo-chart-legend>
          <kendo-chart-series>
            <kendo-chart-tooltip>
              <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem" let-category="category" let-value="value">
                {{ value.toFixed(decimalPlaces) }}
              </ng-template>
            </kendo-chart-tooltip>
            <!-- <kendo-chart-tooltip [shared]="false">
            <ng-template kendoChartSharedTooltipTemplate let-category="category" let-points="points">
                <div> {{ getRadarSeriesName(category) + ' ' + category }} </div>
                <div *ngFor="let point of points">
                    {{ point.series.name }} : {{ point.value }}
                </div>
            </ng-template>
          </kendo-chart-tooltip> -->
            <ng-container *ngFor="
                let series of data.bar.series;
                let n = index;
                let i = index
              ">
              <!-- <kendo-chart-series-item-tooltip [format]="getRadarSeriesName(series, i)">
            </kendo-chart-series-item-tooltip> -->
              <kendo-chart-series-item font="12px sans-serif" [gap]="1" [spacing]="0.25" type="radarArea" [name]="series.name" [data]="data.radar.data" [field]="data.radar.abbrvSeries[i]" categoryField="name">
              </kendo-chart-series-item>
            </ng-container>
          </kendo-chart-series>
          <kendo-chart-value-axis>
            <kendo-chart-value-axis-item [visible]="false" [labels]="{ format: 'C0' }">
            </kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
        </kendo-chart>
      </ng-container>
      <ng-container *ngIf="dashlet.DashletType == 'radarColumn' && data">
        <kendo-chart (seriesClick)="getRadarSeriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'">
          <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
          </kendo-chart-legend>
          <kendo-chart-series>
            <kendo-chart-tooltip>
              <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem" let-category="category" let-value="value">
                {{ value.toFixed(decimalPlaces) }}
              </ng-template>
            </kendo-chart-tooltip>
            <ng-container *ngFor="
                let series of data.bar.series;
                let n = index;
                let i = index
              ">
              <kendo-chart-series-item font="12px sans-serif" [gap]="1" [spacing]="0.25" type="radarColumn" [name]="series.name" [data]="data.radar.data" [field]="data.radar.abbrvSeries[i]" categoryField="name">
              </kendo-chart-series-item>
            </ng-container>
          </kendo-chart-series>
          <kendo-chart-value-axis visible="false">
            <kendo-chart-value-axis-item [visible]="false" [labels]="{ format: 'C0' }">
            </kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
        </kendo-chart>
      </ng-container>
      <ng-container *ngIf="dashlet.DashletType == 'barV' && data">
        <div class="chart-wrapper">
          <div class="zen-chart zen-bar-chart">
            <div style="height: 100%; overflow-x: auto; overflow-y: hidden" [ngClass]="{
                'move-left': transitionUp == 1,
                'move-right': transitionLeft == 0
              }">
              <kendo-chart (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [ngStyle]="{
                  width:
                    splitChart === true
                      ? chartWidth < cardWidth
                        ? cardWidth + 'px'
                        : chartWidth + 'px'
                      : '100%'
                }" [id]="dashlet.ItemID + 'canvas'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [majorUnit]="majorUnit" [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels font="12px sans-serif" color="#4a4a4a" format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="data.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of data.bar.series; let n = index">
                    <kendo-chart-series-item [color]="getSerieColor(series.name, n)" [gap]="1" [spacing]="0.25" type="column" [name]="series.name" [data]="series.data">
                      <ng-container *ngIf="exportInitiated || showValues">
                        <ng-container *ngIf="showValues">
                          <kendo-chart-series-item-labels font="12px sans-serif" color="#4a4a4a" visible="true" format="{0:n2}{{ dataSymbol }}">
                          </kendo-chart-series-item-labels>
                        </ng-container>
                        <ng-container *ngIf="!showValues">
                          <kendo-chart-series-item-labels font="12px sans-serif" color="#4a4a4a" visible="true" format="{0:n1}{{ dataSymbol }}">
                          </kendo-chart-series-item-labels>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!showValues">
                        <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                        </kendo-chart-series-item-tooltip>
                      </ng-container>
                      <ng-container *ngIf="showValues">
                        <kendo-chart-series-item-tooltip format="{0:n2}{{ dataSymbol }}">
                        </kendo-chart-series-item-tooltip>
                      </ng-container>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%" class="move-down-down">
              <kendo-chart (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas-1'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels font="12px sans-serif" color="#4a4a4a" format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataCopy.categories">
                    <kendo-chart-category-axis-item-labels font="12px sans-serif" color="#4a4a4a" rotation="auto">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataCopy.bar.series">
                    <kendo-chart-series-item font="12px sans-serif" color="#4a4a4a" [gap]="1" [spacing]="0.25" type="column" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%" class="move-down">
              <kendo-chart style="margin-top: 100px" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas-2'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels font="12px sans-serif" color="#4a4a4a" format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataSecond.categories">
                    <kendo-chart-category-axis-item-labels font="12px sans-serif" color="#4a4a4a" rotation="auto">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataSecond.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]="0.25" type="column" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
          </div>
          <!-- <span class="weightedFTE"><i class="far fa-info-circle"></i>Weighted by FTE</span> -->
        </div>
      </ng-container>
      <ng-container *ngIf="dashlet.DashletType == 'barH' && data && !customChart">
        <div class="chart-wrapper">
          <div class="zen-chart zen-bar-chart">
            <div style="height: 0%; overflow-x: auto; overflow-y: hidden" [ngClass]="{
                'move-left': transitionUp == 1,
                'move-right': transitionLeft == 0
              }">
              <kendo-chart [ngStyle]="{
                  width:
                    splitChart === true
                      ? chartWidth < cardWidth
                        ? cardWidth + 'px'
                        : chartWidth + 'px'
                      : '100%'
                }" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [majorUnit]="majorUnit" [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels font="12px sans-serif" color="#4a4a4a" format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="data.categories">
                    <kendo-chart-category-axis-item-labels font="12px sans-serif" color="#4a4a4a" rotation="auto">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of data.bar.series; let n = index">
                    <kendo-chart-series-item [color]="getSerieColor(series.name, n)" [gap]="1" [spacing]="0.25" type="bar" [name]="series.name" [data]="series.data">
                      <ng-container *ngIf="exportInitiated">
                        <kendo-chart-series-item-labels font="12px sans-serif" color="#4a4a4a" visible="true" format="{0:n1}{{ dataSymbol }}">
                        </kendo-chart-series-item-labels>
                      </ng-container>
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <!-- <div *ngIf="splitChart" style="height: 100%;" class="move-down-down">
              <kendo-chart style="margin-top: 100px;" (seriesClick)="seriesClicked($event)" [renderAs]="export?'svg': 'canvas'" [id]="dashlet.ItemID + 'canvas-1'">
                <kendo-chart-legend [labels]="{font:'10px sans-serif', color:'#4a4a4a'}" position="top">
              </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{dataSymbol}}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataCopy.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataCopy.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]=".25" type="bar" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{dataSymbol}}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%;" class="move-down">
              <kendo-chart style="margin-top: 100px;" (seriesClick)="seriesClicked($event)" [renderAs]="export?'svg': 'canvas'" [id]="dashlet.ItemID + 'canvas-2'">
                <kendo-chart-legend [labels]="{font:'10px sans-serif', color:'#4a4a4a'}" position="top">
              </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{dataSymbol}}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataSecond.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataSecond.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]=".25" type="bar" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{dataSymbol}}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div> -->
          </div>
          <!-- <span class="weightedFTE"><i class="far fa-info-circle"></i>Weighted by FTE</span> -->
        </div>
      </ng-container>
      <ng-container *ngIf="dashlet.DashletType == 'stackV' && data">
        <div class="chart-wrapper">
          <div class="zen-chart zen-staczen-chart">
            <div style="height: 100%; overflow-x: auto; overflow-y: hidden" [ngClass]="{
                'move-left': transitionUp == 1,
                'move-right': transitionLeft == 0
              }">
              <kendo-chart [ngStyle]="{
                  width:
                    splitChart === true
                      ? chartWidth < cardWidth
                        ? cardWidth + 'px'
                        : chartWidth + 'px'
                      : '100%'
                }" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [majorUnit]="majorUnit" [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="data.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of data.bar?.series; let n = index">
                    <kendo-chart-series-item [color]="getSerieColor(series.name, n)" [gap]="1" [spacing]="0.25" [stack]="true" type="column" [name]="series.name" [data]="series.data">
                      <ng-container *ngIf="exportInitiated">
                        <kendo-chart-series-item-labels visible="true" format="{0:n1}{{ dataSymbol }}">
                        </kendo-chart-series-item-labels>
                      </ng-container>
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%" class="move-down-down">
              <kendo-chart style="margin-top: 100px" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas-1'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataCopy.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataCopy.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]="0.25" [stack]="true" type="column" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%" class="move-down">
              <kendo-chart style="margin-top: 100px" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas-2'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataSecond.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataSecond.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]="0.25" [stack]="true" type="column" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
          </div>
          <!-- <span class="weightedFTE"><i class="far fa-info-circle"></i>Weighted by FTE</span> -->
        </div>
      </ng-container>
      <ng-container *ngIf="dashlet.DashletType == 'stackH' && data && !customChart">
        <div class="chart-wrapper" (click)="logData(data)">
          <div class="zen-chart zen-staczen-chart">
            <div style="height: 100%; overflow-x: auto; overflow-y: hidden" [ngClass]="{
                'move-left': transitionUp == 1,
                'move-right': transitionLeft == 0
              }">
              <kendo-chart [ngStyle]="{
                  width:
                    splitChart === true
                      ? chartWidth < cardWidth
                        ? cardWidth + 'px'
                        : chartWidth + 'px'
                      : '100%'
                }" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [majorUnit]="majorUnit" [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="data.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of data.bar.series; let n = index">
                    <kendo-chart-series-item [color]="getSerieColor(series.name, n)" [gap]="1" [spacing]="0.25" [stack]="true" type="bar" [name]="series.name" [data]="series.data">
                      <ng-container *ngIf="exportInitiated">
                        <kendo-chart-series-item-labels visible="true" format="{0:n1}{{ dataSymbol }}">
                        </kendo-chart-series-item-labels>
                      </ng-container>
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%" class="move-down-down">
              <kendo-chart style="margin-top: 100px" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas-1'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataCopy.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataCopy.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]="0.25" [stack]="true" type="bar" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%" class="move-down">
              <kendo-chart style="margin-top: 100px" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas-2'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataSecond.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataSecond.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]="0.25" [stack]="true" type="bar" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
          </div>
          <!-- <span class="weightedFTE"><i class="far fa-info-circle"></i>Weighted by FTE</span> -->
        </div>
      </ng-container>
      <!-- <ng-container  *ngIf="dashlet.DashletType == 'bubble' && data">
      <div class="chart-wrapper">
        <div class="zen-chart zen-line-chart">
          <div style="height: 100%; overflow-x: auto; overflow-y: hidden;" [ngClass]="{ 'move-left' : transitionUp == 1, 'move-right' : transitionLeft == 0 }">
            <kendo-chart [ngStyle]="{ 'width' : splitChart === true ? ((chartWidth < cardWidth) ? cardWidth + 'px' : chartWidth + 'px') : '100%' }" (seriesClick)="seriesClicked($event)" [renderAs]="export?'svg': 'canvas'" [id]="dashlet.ItemID + 'canvas'">
              <kendo-chart-legend [labels]="{font:'10px sans-serif', color:'#4a4a4a'}" position="top">
            </kendo-chart-legend>
              <kendo-chart-series>
                <ng-container *ngFor="let series of data.bar.series; let n = index">
                  <kendo-chart-series-item [color]="getSerieColor(series.name, n)" [gap]="1" [spacing]=".25" type="bubble" [name]="series.name" [data]="series.data">
                    <ng-container *ngIf="exportInitiated">
                      <kendo-chart-series-item-labels visible="true" format="{0:n1}{{dataSymbol}}">
                      </kendo-chart-series-item-labels>
                    </ng-container>
                    <kendo-chart-series-item-tooltip format="{0:n1}{{dataSymbol}}">
                    </kendo-chart-series-item-tooltip>
                  </kendo-chart-series-item>
                </ng-container>
              </kendo-chart-series>
            </kendo-chart>
          </div>
          </div>
          </div>
    </ng-container> -->
      <ng-container *ngIf="dashlet.DashletType == 'line' && data">
        <div class="chart-wrapper">
          <div class="zen-chart zen-line-chart">
            <div style="height: 100%; overflow-x: auto; overflow-y: hidden" [ngClass]="{
                'move-left': transitionUp == 1,
                'move-right': transitionLeft == 0
              }">
              <kendo-chart [ngStyle]="{
                  width:
                    splitChart === true
                      ? chartWidth < cardWidth
                        ? cardWidth + 'px'
                        : chartWidth + 'px'
                      : '100%'
                }" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [majorUnit]="majorUnit" [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="data.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of data.bar.series; let n = index">
                    <kendo-chart-series-item [color]="getSerieColor(series.name, n)" [gap]="1" [spacing]="0.25" type="line" [name]="series.name" [data]="series.data">
                      <ng-container *ngIf="exportInitiated">
                        <kendo-chart-series-item-labels visible="true" format="{0:n1}{{ dataSymbol }}">
                        </kendo-chart-series-item-labels>
                      </ng-container>
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%" class="move-down-down">
              <kendo-chart style="margin-top: 100px" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas-1'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataCopy.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataCopy.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]="0.25" type="line" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%" class="move-down">
              <kendo-chart style="margin-top: 100px" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas-2'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataSecond.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataSecond.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]="0.25" type="line" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
          </div>
          <!-- <span class="weightedFTE"><i class="far fa-info-circle"></i>Weighted by FTE</span> -->
        </div>
      </ng-container>
      <ng-container *ngIf="dashlet.DashletType == 'area' && data">
        <div class="chart-wrapper">
          <div class="zen-chart zen-area-chart">
            <div style="height: 100%; overflow-x: auto; overflow-y: hidden" [ngClass]="{
                'move-left': transitionUp == 1,
                'move-right': transitionLeft == 0
              }">
              <kendo-chart [ngStyle]="{
                  width:
                    splitChart === true
                      ? chartWidth < cardWidth
                        ? cardWidth + 'px'
                        : chartWidth + 'px'
                      : '100%'
                }" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [majorUnit]="majorUnit" [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="data.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of data.bar.series; let n = index">
                    <kendo-chart-series-item [gap]="1" [spacing]="0.25" type="area" [name]="series.name" [data]="series.data">
                      <ng-container *ngIf="exportInitiated">
                        <kendo-chart-series-item-labels visible="true" format="{0:n1}{{ dataSymbol }}">
                        </kendo-chart-series-item-labels>
                      </ng-container>
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
            <div *ngIf="splitChart" style="height: 100%" class="move-down-down">
              <kendo-chart style="margin-top: 100px" (seriesClick)="seriesClicked($event)" [renderAs]="export ? 'svg' : 'canvas'" [id]="dashlet.ItemID + 'canvas-1'">
                <kendo-chart-legend [labels]="{ font: '0.8rem sans-serif', color: '#4a4a4a' }" position="top">
                </kendo-chart-legend>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [title]="{ text: dashlet.ValueAxisTitle }">
                    <kendo-chart-value-axis-item-labels format="{0:n0}{{ dataSymbol }}">
                    </kendo-chart-value-axis-item-labels>
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [title]="{ text: dashlet.CategoryAxisTitle }" [categories]="dataCopy.categories">
                    <kendo-chart-category-axis-item-labels rotation="auto" font="12px sans-serif" color="#4a4a4a">
                    </kendo-chart-category-axis-item-labels>
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <ng-container *ngFor="let series of dataCopy.bar.series">
                    <kendo-chart-series-item [gap]="1" [spacing]="0.25" type="area" [name]="series.name" [data]="series.data">
                      <kendo-chart-series-item-tooltip format="{0:n1}{{ dataSymbol }}">
                      </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                  </ng-container>
                </kendo-chart-series>
              </kendo-chart>
            </div>
          </div>
          <!-- <span class="weightedFTE"><i class="far fa-info-circle"></i>Weighted by FTE</span> -->
        </div>
      </ng-container>
      <ng-container *ngIf="
          (dashlet.DashletType == 'md' ||
            dashlet.DashletType == 'md-allowance' ||
            dashlet.DashletType == 'md-benefit') &&
          data
        ">
        <div class="chart-wrapper" [id]="dashlet.ItemID + 'canvas'">
          <ng-container *ngIf="data.data.Value1 && !data.data.Value2">
            <div class="zen-chart zen-radial-gauge-wrap">
              <!-- <kendo-radialgauge [pointer]="{ value: data.data.Value1, color: '#666' }"
              [scale]="{ max: data.data.Value1 * 2.5 }" class="zen-radial-gauge">
              <kendo-radialgauge-scale>
                <kendo-radialgauge-scale-ranges rangePlaceholderColor="#888">
                  <kendo-radialgauge-scale-range [from]="0" [to]="data.data.Value1" color="#0082C6">
                  </kendo-radialgauge-scale-range>
                </kendo-radialgauge-scale-ranges>
              </kendo-radialgauge-scale>
            </kendo-radialgauge> -->
              <div class="multi-dashlet">
                <div class="md-value">
                  <i [ngClass]="dashlet.Icon" aria-hidden="true"></i>
                  <div class="md-current-value">
                    <!--<p>for 2020</p>-->
                    <h2>
                      {{ dataType === "currency" ? currencySymbol : "" }}
                      {{
                      dataType === "currency" || dataType === "percentage"
                      ? setupDecimalPlaces(data.data.Value1)
                      : dataType === "number"
                      ? setupDecimalPlaces(data.data.Value1, true)
                      : setupDecimalPlaces(data.data.Value1)
                      }}
                      {{
                      dataType === "number"
                      ? ""
                      : dataType === "percentage"
                      ? " %"
                      : ""
                      }}
                    </h2>
                  </div>
                </div>
                <!--<div class="md-details">
                <h4></h4>
                <div id="chartID-goes-here" class="md-details-chart md-details-chart-type"></div>
              </div>-->
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="
              (data.data.Value1 || data.data.Value1 == 0) && data.data.Value2
            ">
            <div class="multi-dashlet">
              <div class="md-main">
                <div class="md-value">
                  <div class="md-value-left">
                    <i [ngClass]="dashlet.Icon" aria-hidden="true"></i>
                    <div class="md-current-value">
                      <p>Total</p>
                      <h2>
                        {{ dataType === "currency" ? currencySymbol : "" }}
                        {{
                        dataType === "currency" || dataType === "percentage"
                        ? setupDecimalPlaces(data.data.Value1)
                        : dataType === "number"
                        ? setupDecimalPlaces(data.data.Value1, true)
                        : setupDecimalPlaces(data.data.Value1, true)
                        }}
                        {{
                        dataType === "number"
                        ? ""
                        : dataType === "percentage"
                        ? " %"
                        : ""
                        }}
                      </h2>
                    </div>
                  </div>
                  <div class="md-value-right">
                    <div class="row">
                      <div class="col-6">
                        <p>Previous</p>
                        <h2>
                          {{ dataType === "currency" ? currencySymbol : "" }}
                          {{
                          dataType === "currency" || dataType === "percentage"
                          ? setupDecimalPlaces(data.data.Value2)
                          : dataType === "number"
                          ? setupDecimalPlaces(data.data.Value2, true)
                          : setupDecimalPlaces(data.data.Value2, true)
                          }}
                          {{
                          dataType === "number"
                          ? ""
                          : dataType === "percentage"
                          ? " %"
                          : ""
                          }}
                        </h2>
                      </div>
                      <div class="col-6">
                        <p>Differnce</p>
                        <h2>
                          {{
                          dataType === "currency" || dataType === "percentage"
                          ? setupDecimalPlaces(data.data.Value3)
                          : dataType === "number"
                          ? setupDecimalPlaces(data.data.Value3, true)
                          : setupDecimalPlaces(data.data.Value3, true)
                          }}
                          {{
                          dataType === "number"
                          ? ""
                          : dataType === "percentage"
                          ? " %"
                          : ""
                          }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="(data.data.Value2 || data.data.Value3) && !data.Groups" class="md-footer">
                <div *ngIf="data.data.Value2" class="md-footer-left">
                  <p>{{ dashlet.Value2Title }}</p>
                  <h3>{{ data.data.Value2 }}</h3>
                </div>
                <div *ngIf="data.data.Value3" class="md-footer-right">
                  <p>{{ dashlet.Value3Title }}</p>
                  <h3>{{ data.data.Value3 }}</h3>
                </div>
              </div>
              <!-- <ng-container *ngIf= drillDown">
              <div *ngIf= drillDown.length > 0" class="md-data-group">
                <ng-container *ngIf="data.Groups[0].Value1 != '0'">
                  <ng-container *ngFor="let group of data.Groups">
                    <div class="row">
                      <div class="col-6 md-data-group-left">{{ group.Value1}}</div>
                      <div class="col-6 md-data-group-right">{{ group.Value2 | number : '1.2-2' }}</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container> -->
            </div>
          </ng-container>
          <!-- <span class="weightedFTE"><i class="far fa-info-circle"></i>Weighted by FTE</span> -->
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="dashlet.DashletType == 'barH' && data && customChart">
      <div class="block" (click)="logData(filteredCategories)" [ngClass]="{ rtl: (language | language), '': reportsPage }">
        <!-- MAIN CHART LINES -->
        <div *ngIf="!reportsPage && !fullView && data.bar.series.length > 1" class="level px-3 mb-4">
          <div class="level-item level-right">
            <div class="topLegend mb-0 p-2">
              <div class="level-item" *ngFor="let type of data.bar.series; let n = index">
                <h5 class="text-sm text-zen-lite" (click)="logData(type)">
                  <i [style.background]="
                      getConfidenceUnresolved(type.name, 'color')
                        ? getConfidenceUnresolved(type.name, 'color')
                        : colors[n]
                    " class="mx-1">
                  </i>{{ type.name }}
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div class="block" [ngClass]="
            dashlet.SelectedGroupKey && !selectionDetailView
              ? 'detailedChart'
              : ''
          ">
          <!-- header -->
          <div class="myRow apart items-center px-6 justify-between border border-zen border-opacity-10 bg-white">
            <div class="flex flex-row justify-between items-center">
              <span *ngIf="reportsPage && !disableChartTitle" class="bg-exp bg-opacity-5 text-exp leading-7 w-7 max-h-7 text-center text-lg rounded-full">A</span>
              <p class="font-medium text-xxs text-zen uppercase px-2">
                <span *ngIf="reportsPage && !disableChartTitle">{{
                  "txt_answers" | translate : language : ModuleNames.Shared
                  }}</span>
                <span [@child1] *ngIf="dashlet.SelectedGroupKey">
                  <span class="opacity-60">({{ "by" | translate : language : ModuleNames.Common }}
                    {{ returnGroupName(dashlet.SelectedGroupKey) }}</span>
                  <span *ngIf="selectionDetailView">:
                    <span class="text-zen uppercase opacity-100">{{
                      selectionValue
                      }}</span> </span>)
                  <span (click)="removeSelectionDetailView(drillDown)" *ngIf="selectionDetailView" class="text-zen-red mx-1 cursor-pointer"><i class="fas fa-times"></i>
                    {{
                    "txtRemoveSelection"
                    | translate : language : ModuleNames.Shared
                    }}</span></span>
              </p>
            </div>
            <div class="myRow justify-end">
              <div class="chart400">
                <p [@child1] *ngIf="dashlet.SelectedGroupKey && !selectionDetailView" class="font-medium text-xs text-zen opacity-60 uppercase">
                  {{
                  "txtOverallScore"
                  | translate : language : ModuleNames.Shared
                  }}
                </p>
              </div>
              <div class="chart40 mx-3">
                <p class="font-medium text-xs text-zen opacity-60 uppercase" *ngIf="hasCustomQuantity">
                  {{ "txtValue" | translate : language : ModuleNames.Shared }}
                </p>
              </div>
              <div class="chart40">
                <p class="font-medium text-xs text-zen opacity-60 uppercase">
                  %
                </p>
              </div>
            </div>
          </div>
          <div class="block" [@listAnimationFast]="filteredCategories.length">
            <ng-container *ngFor="let category of filteredCategories; let c = index">
              <div [ngClass]="{
                  'break-before mt-20 border-t border-zen border-opacity-10':
                    export ? returnCommentBreak(c) : ''
                }">
                <div class="chart-row px-6 border-b border-r border-l border-zen border-opacity-10 bg-white" [@list]>
                  <div (click)="
                      (reportsPage || fullView) &&
                      !disableChartTitle &&
                      dashlet.SelectedGroupKey && !selectionDetailView
                        ? showRowDetails(
                            firstOfKind(getGroup(category.value, drillDown)),
                            c
                          )
                        : ''
                    " class="chart-head" [ngClass]="{
                      'cursor-pointer':
                        (reportsPage || fullView) &&
                        dashlet.SelectedGroupKey &&
                        !selectionDetailView
                    }">
                    <div class="myRow middle">
                      <p class="font-normal text-sm text-zen">
                        <i [@child1] *ngIf="
                            (reportsPage || fullView) &&
                            dashlet.SelectedGroupKey &&
                            !selectionDetailView
                          " [ngClass]="{ turn90: showDetails == c }" class="fa fa-chevron-right text-sm icon40"></i>
                        {{ category.value ? category.value : dashlet.Title }}
                      </p>
                    </div>
                    <div class="myRow middle justify-end" (click)="
                        (!reportsPage && !fullView && !disableChartTitle) ||
                        !dashlet.SelectedGroupKey ||
                        selectionDetailView
                          ? showDrill(
                              'item',
                              getGroup(category.value, drillDown)
                            )
                          : ''
                      " [ngClass]="{
                        'cursor-pointer':
                          !dashlet.SelectedGroupKey || selectionDetailView
                      }">
                      <div class="chart400">
                        <div [ngClass]="{
                            'cursor-pointer':
                              !reportsPage && !fullView && !disableChartTitle
                          }" class="chartLine bg-exp bg-opacity-5 rounded-sm">
                          <div [style.width.%]="
                              percentageValue(category.value, drillDown)
                            " class="percentage bg-exp rounded-sm"></div>
                        </div>
                      </div>
                      <div class="chart40 mx-3">
                        <p class="font-normal text-sm text-zen" *ngIf="hasCustomQuantity">
                          {{ getQuantityValue(category.value, drillDown) }}
                        </p>
                      </div>
                      <div class="chart40">
                        <p class="font-normal text-sm text-zen">
                          {{ toFixed(category.data.value[0], 2) }}%
                        </p>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="showDetails == c && items.length" class="chart-details" [ngClass]="{ show: showDetails == c }">
                    <div class="wrappedContainer" [@list]="items.length">
                      <div class="item-container" *ngFor="let item of items">
                        <!-- <div class="item-detail" > -->
                        <div class="item-detail" (click)="
                            filterData(
                              item,
                              returnGroupName(dashlet.SelectedGroupKey)
                            )
                          ">
                          <div class="tooltip">
                            <div class="innerText">
                              <h6>{{ item }}</h6>
                              <h6>
                                {{
                                itemNumber(
                                item,
                                getGroup(category.value, drillDown)
                                )
                                }}
                                ({{
                                toFixed(
                                itemNumber(
                                item,
                                getGroup(category.value, drillDown),
                                true
                                ),
                                1
                                )
                                }}
                                %)
                              </h6>
                            </div>
                          </div>
                          <div class="data">
                            <h5>
                              {{ item }} <br />
                              <span class="pt-2">{{
                                itemNumber(
                                item,
                                getGroup(category.value, drillDown)
                                )
                                }}
                                ({{
                                toFixed(
                                itemNumber(
                                item,
                                getGroup(category.value, drillDown),
                                true
                                ),
                                1
                                )
                                }}
                                %)</span>
                            </h5>
                          </div>
                          <div [style.height.%]="
                              itemNumber(
                                item,
                                getGroup(category.value, drillDown),
                                true
                              )
                            " class="percentage"></div>
                        </div>
                        <a class="drillButton btn" (click)="
                            showDrill(item, getGroup(category.value, drillDown))
                          "><i class="far fa-search-plus"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="dashlet.DashletType == 'stackH' && data && customChart">
      <div class="custom-chart box block" [ngClass]="{ rtl: (language | language), '': reportsPage }">
        <!-- MAIN CHART LINES -->
        <div class="h-11 flex flex-row justify-end items-center gap-4 topLegend px-6 border border-zen border-opacity-10 bg-white">
          <ng-container *ngFor="let type of data.bar.series; let m = index">
            <h5 class="text-xs text-zen" (click)="logData(type)">
              <i [style.background]="returnBackgroundColor(type.name, index)" class="mx-1">
              </i>{{ type.name }}
            </h5>
          </ng-container>
        </div>
        <div class="block">
          <!-- header -->
          <div class="block">
            <div class="flex flex-row gap-2 items-center px-6 py-1 border border-zen border-opacity-10 bg-white">
              <span class="bg-zen bg-opacity-5 text-zen leading-7 w-7 max-h-7 text-center text-lg rounded-full">A</span>
              <p class="font-medium text-xxs text-zen uppercase">
                {{ "txt_answers" | translate : language : ModuleNames.Shared }}
                <span [@child1] *ngIf="dashlet.SelectedGroupKey">
                  <span class="opacity-60">({{ "by" | translate : language : ModuleNames.Common }}
                    {{ returnGroupName(dashlet.SelectedGroupKey) }}</span>
                  <span *ngIf="selectionDetailView">:
                    <span class="text-zen uppercase opacity-100">{{
                      selectionValue
                      }}</span> </span>)
                  <span *ngIf="selectionDetailView" class="text-zen-red mx-1 cursor-pointer"><i class="fas fa-times"></i>
                    {{
                    "txtRemoveSelection"
                    | translate : language : ModuleNames.Shared
                    }}</span></span>
              </p>
            </div>
            <div class="myRow">
              <div class="chart600">
                <!-- <p class="font-normal text-xs text-zen opacity-60 uppercase">
                  0%
                </p>
                <p class="font-normal text-xs text-zen opacity-60 uppercase">
                  20%
                </p>
                <p class="font-normal text-xs text-zen opacity-60 uppercase">
                  40%
                </p>
                <p class="font-normal text-xs text-zen opacity-60 uppercase">
                  60%
                </p>
                <p class="font-normal text-xs text-zen opacity-60 uppercase">
                  80%
                </p>
                <p class="font-normal text-xs text-zen opacity-60 uppercase">
                  100%
                </p> -->
              </div>
            </div>
          </div>
          <div class="block" [@listAnimationFast]="filteredCategories.length">
            <ng-container *ngFor="let category of filteredCategories; let c = index">
              <div (click)="logData(category)" [ngClass]="{
                  'break-before mt-20 border-t border-zen border-opacity-10':
                    export ? returnCommentBreak(c) : ''
                }">
                <div class="chart-row px-6 border-b border-r border-l border-zen border-opacity-10 bg-white">
                  <div class="chart-head">
                    <div class="myRow middle">
                      <p class="font-normal text-sm text-zen">
                        {{ category.value }}
                      </p>
                    </div>
                    <div class="myRow middle">
                      <div class="chart600 rounded-sm">
                        <div class="chartLine bg-opacity-5 rounded-sm flex flex-row justify-start">
                          <div *ngFor="
                              let val of category.data.value;
                              let s = index
                            " (click)="
                              showDrill(
                                category.data?.name[s]?.name,
                                getGroup(category.value, drillDown)
                              )
                            " [matTooltip]="toFixed(val, 1) + '%'" class="value text-white text-xxs text-center" [style.background]="
                              returnBackgroundColor(category.data?.name[s], s)
                            " [style.width.%]="val">
                            {{ val ? (val | number : "1.0-0") + "%" : "" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<data-grid *ngIf="reportsPage && !disableChartTitle" [data]="data" [currentSelection]="currentSelection" [language]="language" [(open)]="tableView">
</data-grid>

<ng-template #header let-dashlet>
  <div class="flex gap-4 h-24 px-7 flex-row justify-between items-center border border-zen border-opacity-10 bg-white">
    <div class="flex flex-row gap-3 items-center justify-start">
      <span *ngIf="!disableChartTitle && reportsPage" class="bg-zen bg-opacity-5 text-zen leading-7 w-7 max-h-7 text-center text-lg rounded-full">{{ "txtQ" | translate : language : ModuleNames.Shared }}</span>
      <h4 class="text-zen">{{ dashlet.Title }}</h4>
    </div>
    <div class="flex flex-row gap-4 items-center justify-end">
      <div *ngIf="!disableGrouping" class="flex flex-row items-center justify-end gap-1">
        <i [@child1] *ngIf='dashlet.SelectedGroupKey' [matTooltip]="'txt_clear_grouping'|translate:language:ModuleNames.Shared" (click)="clearGrouping()" class="fal fa-times btn cancel leading-9 rounded w-9 text-center text-sm"></i>
        <z-select *ngIf="question.canGroup" [DemoLimit]="DemoGroupingLimit" [options]="groupingOptions" [language]="language" [placeholder]="('txtSelectOption'| translate:language:ModuleNames.Shared)" [(ngModel)]="dashlet.SelectedGroupKey" (selectionChange)="selectedGroupingChanged($event)">
          <ng-template #valueTemplate let-value>
            {{ value?.name | extract : language }}
          </ng-template>
        </z-select>
      </div>

      <div appClickOutside (clickOutside)="closeDropdown('chartType')" *ngIf="!disableChartTypes && !disableChart" class="flex flex-row justify-end items-center">
        <div class="relative">
          <img (click)="chartType = !chartType" class="w-9 h-9 p-3 minw-9 rounded btn cancel" [TourStep]="chartTypeTour" [src]="'assets/img/svg/charts/' + dashlet.DashletType + '.svg'" alt="" />

          <div [@fadeUpDown] *ngIf="chartType" class="flex flex-col gap-2 absolute -right-2 top-14 z-10 bg-white rounded-md p-2 shadow-sm w-max border border-zen border-opacity-25">
            <ng-container *ngFor="let chart of dashlet.DashletChartTypes">
              <img *ngIf="chart.DashletType != dashlet.DashletType" (click)="
                  dashlet.DashletType = chart.DashletType; chartType = false
                " class="w-9 h-9 p-3 rounded btn cancel" [src]="'assets/img/svg/charts/' + chart.DashletType + '.svg'" alt="" />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
