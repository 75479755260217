import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuleName } from '../../enums/ModuleName';

@Component({
  selector: 'q-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {
  @Input() language;
  @Input() title;
  @Input() startButton;
  value = 4;
  @Output() startSurvey = new EventEmitter();
  constructor() { }
  ModuleNames = ModuleName
  ngOnInit(): void {
  }

}
