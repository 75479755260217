import { Injectable } from '@angular/core';
import { CompanyPermission } from '../data-models/company-permission';
import { Entity } from '../data-models/Entity';
import { Hierarchy } from '../data-models/hierarchy';
import { HierarchyEntity } from '../data-models/hierarcy-entity';


export interface EntityCount {
  entityKey: string,
  totalCount: number,
}

@Injectable({
  providedIn: 'root'
})
export class EntityTreeService {

  constructor() { }

  get(entities: Entity[], root: Entity, entityCounts: EntityCount[]): Hierarchy[] {

    const results = [];

    var hierarchy = new Hierarchy()

    hierarchy.entity = this.getHierarchyEntity(root)
    hierarchy.entity.count = entityCounts.find(x => x.entityKey == hierarchy.entity.key)?.totalCount || 0;
    hierarchy.checked = false

    hierarchy.children = this.getChildren(entities, hierarchy, entityCounts)

    results.push(hierarchy);

    return results;
  }

  private getHierarchyEntity(entity: Entity): HierarchyEntity {

    const hierarchyEntity = new HierarchyEntity();

    hierarchyEntity.key = entity.key;
    hierarchyEntity.name = entity.name;
    hierarchyEntity.count = 0
    hierarchyEntity.parentKey = entity.parentKey;
    hierarchyEntity.groupKey = entity.entityGroupKey;

    return hierarchyEntity;
  }

  private getChildren(entities: Entity[], parent: Hierarchy, entityCounts: EntityCount[]): Hierarchy[] {
    let children = entities?.filter(e => e.parentKey == parent.entity.key);

    let hierarchyChildren: Hierarchy[] = [];

    for (let i = 0; i < children?.length; i++) {

      let child = JSON.parse(JSON.stringify(children[i]));

      let hierarchy = new Hierarchy;

      hierarchy.entity = this.getHierarchyEntity(child);
      hierarchy.entity.key = child.key;
      hierarchy.entity.parentKey = child.parentKey;
      hierarchy.entity.name = child.name;
      hierarchy.entity.count += entityCounts.find(x => x.entityKey == hierarchy.entity.key)?.totalCount || 0;
      hierarchy.entity.groupKey = child.entityGroupKey;
      
      hierarchy.children = this.getChildren(entities, hierarchy, entityCounts);

      hierarchyChildren.push(hierarchy);

      parent.entity.count += hierarchy.entity.count;
    }

    return hierarchyChildren;
  }

}
