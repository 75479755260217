import { Injectable } from '@angular/core';
import { AppInjector } from '@ex/module/app.module';
import { KeyResolverService } from '@ex/module/core/services/key-resolver.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ExtractPipe } from '@ex/module/shared/pipes/extract.pipe';
import { TranslatePipe } from '@ex/module/shared/pipes/translate.pipe';
import { KeyName } from '@ex/survey-x/index';

export interface SurveyCriteriaDropdown {
  key: string,
  name: KeyName[],
  options: any[],
  isCustom: boolean,
  isEntity?: boolean,
}

@Injectable({
  providedIn: null
})
export class CriteriaResolverService {

  constructor(private extractPipe: ExtractPipe, private translatePipe: TranslatePipe) {
  }
  private surveyCriterias: SurveyCriteriaDropdown[];


  setSurveyCriterias(surveyCriterias: SurveyCriteriaDropdown[]) {
    this.surveyCriterias = surveyCriterias;
  }

  extractTranslation(data, criteriaKey, values, language) {
    let currentCriteria = this.surveyCriterias.find(cr => cr.key == criteriaKey);
    console.log(data);
    for (let index = 0; index < data.length; index++) {
      for (let ind = 0; ind < values.length; ind++) {
        let name = currentCriteria.options.find(x => x.key == data[index][values[ind]]).name;
        if (name)
          data[index][values[ind]] = this.extractPipe.transform(name, language);
      }
    }
    return data;
  }

  extract(criteria: string, key: string, language: string) {
    let keyResolverService = AppInjector.get(KeyResolverService)

    const surveyCriteria = this.surveyCriterias.find(x => x.key == criteria);

    if (!surveyCriteria) return key;

    let option = surveyCriteria.options.find(x => x.key == key);

    if (['AgeGroup', 'TenureGroup', 'Generation'].includes(criteria)) {

      let resolver = keyResolverService.getResolver(criteria);
      if (resolver) {
        keyResolverService.setKeyResolver(resolver);

        return keyResolverService.resolve(key, language);
      }

      return key;
    }

    return this.extractPipe.transform(option?.name, language) || this.translatePipe.transform(option?.name, language, ModuleName.SurveyX) || key;
  }

  extractTitle(key: string, language: string) {
    const surveyCriteria = this.surveyCriterias.find(x => x.key == key);
    if (!surveyCriteria) return key;
    if (surveyCriteria.name.length)
      return this.extractPipe.transform(surveyCriteria?.name, language) || key;
    return this.translatePipe.transform(key, language, ModuleName.Shared)
  }
}
