<div class="pl-7 pr-4 py-5 flex flex-col gap-5 h-full justify-between" [ngClass]="language=='AR' ? 'rtl': ''">
  <div class="flex flex-row items-center gap-2 justify-start">
    <i class="far fa-file-export text-exp text-lg"></i>
    <h4 class="text-zen text-2xl">{{ title }}</h4>
  </div>
  <div class="h-full py-4 flex flex-col gap-3 text-zen exp">
    <ng-content></ng-content>
  </div>
  <div class="flex flex-row justify-between items-center gap-5">
    <a class="btn cancel leading-15 uppercase px-5 rounded flex flex-row items-center"
      [mat-dialog-close]>{{ 'btn_leaver_list_cancel'|translate:language:ModuleNames.Shared  }}</a>
    <a class="btn exp leading-15 uppercase px-5 rounded flex flex-row items-center gap-2" (click)="export.emit()"
      *ngIf="displayExportButton"><i
        class="fas fa-download"></i>{{ 'btnExport'| translate:language:ModuleNames.Shared }}</a>
  </div>
</div>
