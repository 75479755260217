import { AppInjector } from 'src/app/app.module';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { OverallAnalysisComponent } from '../overall-analysis/overall-analysis.component';
import { RespondentsAnalysisComponent } from '../respondents-analysis/respondents-analysis.component';
import { QuestionAnalysisComponent } from '../question-analysis/question-analysis.component';
import { CommentAnalysisComponent } from '../comment-analysis/comment-analysis.component';
import { ComponentRef } from '@angular/core';

export interface SidebarNavigationModel {
  id: number;
  title: string;
  icon: string;
  selected: boolean;
  component: any;
}

const getSidebarNavigationTabs = (
  language: string
): SidebarNavigationModel[] => {
  const localize = AppInjector.get(TranslatePipe);

  return [
    {
      id: 1,
      title: 'txtOverview',
      icon: 'fas fa-tachometer-alt',
      selected: true,
      component: OverallAnalysisComponent,
    },
    {
      id: 2,
      title: 'txtResponses',
      icon: 'fas fa-analytics',
      selected: false,
      component: RespondentsAnalysisComponent,
    },
    {
      id: 3,
      title: 'txt_questions',
      icon: 'fas fa-question',
      selected: false,
      component: QuestionAnalysisComponent,
    },
    {
      id: 4,
      title: 'txtComments',
      icon: 'fas fa-comment-lines',
      selected: false,
      component: CommentAnalysisComponent,
    },
  ];
};

export default getSidebarNavigationTabs;
