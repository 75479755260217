import { Component, OnInit } from '@angular/core';
import { AnalysisBaseComponentComponent } from '../../ui/closed-survey/analysis-base-component/analysis-base-component.component';
import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { SurveyCriteriaDto } from '@ex/wellbeing/surveys';
import { RespondentsListWithCriteriaDto, SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { CHART_DATA_RESPONSE_STRATEGY, CommonChartFunctions } from 'src/app/core/services/chart-data.service';
import { ChartOptionsService } from 'src/app/shared/chart/chart-options.service';
import { KeyResolverService } from 'src/app/core/services/key-resolver.service';
import { WellbeingKeyResolverNames } from '../../utils/wellbeing-key-resolver-names';
import { GlobalStatusService, Status } from 'src/app/core/services/global-status.service';
import { PagedResultDto } from '@abp/ng.core';
import { RespondentService } from '@ex/wellbeing/respondents';
import { JsonToExcelComponent } from '@ex/module/shared/export-components/json-to-excel/json-to-excel.component';
import { CriteriaResolverService } from '../../data-access/services/criteria-resolver.service';
interface chart {
  title: string,
  Title: string,
  DashletType: string,
  group: string,
  canGroup: boolean,
  language: string
  halfWidth: boolean,
  isEntity: boolean,
  type: string,
  data?: any,
  data$: any
}
@Component({
  selector: 'app-respondents-analysis',
  templateUrl: './respondents-analysis.component.html',
  styleUrls: ['./respondents-analysis.component.scss']
})
export class RespondentsAnalysisComponent extends AnalysisBaseComponentComponent implements OnInit {

  surveyCriterias: Partial<SurveyCriteriaDto>[];
  filters: AnalyticsFilter;
  entityChartColors;
  Status = Status;
  charts: Partial<chart>[];

  filtersLoaded = new Subject();
  filtersLoaded$ = this.filtersLoaded.asObservable();


  pageChanged = new BehaviorSubject(0);
  pageChanged$ = this.pageChanged.asObservable();

  maxResultCount = 5;
  language: string;
  respondentsData: PagedResultDto<RespondentsListWithCriteriaDto>;
  searchFilter = new BehaviorSubject("");
  totalRecords: number;

  constructor(private chartOptionsService: ChartOptionsService
    , private globalStatusService: GlobalStatusService
    , private keyResolverService: KeyResolverService
    , private analyticsService: AnalyticsService
    , private surveyAnalysisService: SurveyAnalysisService
    , private commonChartFunctions: CommonChartFunctions
    , private respondentService:RespondentService
    , private criteriaResolverService: CriteriaResolverService,
  ) { super(); }

  ngOnInit(): void {
    console.log(this.data);
    console.log('hi');
    this.language = this.data.language
    this.fetchRespondents();

    this.analyticsService.filters$.pipe(
      switchMap(filters => {
        // Fetch the List of Dimensions for the dropdown based on the filter

        return of({ filters, criterias: filters.survey.surveyCriterias || [] });

      })
    ).subscribe(({ filters, criterias }) => {
      this.filters = filters;
      this.surveyCriterias = criterias;
      this.language = this.filters.language
      this.prepareCriteria();

      this.filtersLoaded.next(filters);
    });
    this.setChartColors();
    console.log(this.data);

  }

  fetchRespondents() {

    const subscription = combineLatest([this.filtersLoaded$, this.pageChanged$, this.searchFilter.asObservable()]).pipe(
      switchMap(([_, page, search]) => {
        return this.surveyAnalysisService.getRespondentsListWithCriteria({
          companyKey: this.filters.companyKey,
          tenantKey: this.filters.tenantKey,
          surveyKey: this.filters.survey.key,
          filters: [...this.analyticsService.mapGlobalFilters(this.filters)],
          searchKey: search,
          skipCount: page,
          maxResultCount: this.maxResultCount,
        })
      })
    ).subscribe(respondents => {
      this.totalRecords = respondents.totalCount;
      this.respondentsData = respondents;
    })

    this.addSubscription(subscription);
  }

  prepareCriteria() {
    this.charts = [{
      Title: this.localize('txtOverallResponseRate', this.data.language, this.ModuleNames.Wellbeing),
      DashletType: 'md',
      group: "overall",
      halfWidth: true
    },
    {
      Title: this.localize('txtStatusAnalysis', this.data.language, this.ModuleNames.Wellbeing),
      DashletType: 'pie',
      group: "status",
      halfWidth: true
    }]
    let criteriaCharts = this.surveyCriterias.map(criteria => {
      return {
        Title: this.localize('txtRespby', this.data.language, this.ModuleNames.Wellbeing) + ' ' + this.ExtractPipe.transform(criteria.name, this.data.language),
        DashletType: 'barV',
        group: criteria.key,
        language: this.data.language,
        halfWidth: false,
        isEntity: criteria.isEntity,
      }
    })
    this.charts = this.charts.concat(criteriaCharts);
    this.charts.forEach(chart => {
      console.log(chart);

      chart.data$ = this.chartData(chart)
    })
  }

  chartData(chart) {
    if (chart.group == 'overall') {
      return this.surveyAnalysisService.getOverallAnalysis({
        companyKey: this.filters.companyKey,
        surveyKey: this.filters.survey.key,
        tenantKey: this.filters.tenantKey,
        filters: [...this.analyticsService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []],
      })
    }

    return this.surveyAnalysisService.getGroupedRespondentsByCriteria(chart.group, {
      companyKey: this.filters.companyKey,
      surveyKey: this.filters.survey.key,
      tenantKey: this.filters.tenantKey,
      type: chart.group != 'status' ? 'ec' : '',
      filters: [...this.analyticsService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []],
    }).pipe(map((data: any) => {
      var removeElement = false;
      var index = "";
      if (chart.group == 'status') {
        data = this.commonChartFunctions.reArrangeArray(data, [Status.Completed, Status.Progress, Status.Pending, Status.Expired, Status.Inactive], 'category');
        const resolver = this.keyResolverService.getResolver('SurveyStatus');
        this.keyResolverService.setKeyResolver(resolver);
        data.forEach(x => {
          if (!this.keyResolverService.resolve(x.category, this.data.language, chart.group)) {
            removeElement = true;
            index = data.indexOf(x);
          }
          x.key = x.category;
          x.category = this.keyResolverService.resolve(x.category, this.data.language, this.ModuleNames.Shared);
        })
      }
      else {
        const resolver = this.keyResolverService.getResolver(WellbeingKeyResolverNames.SurveyCriterias);

        this.keyResolverService.setKeyResolver(resolver);
        data.forEach(x => {
          if (!this.keyResolverService.resolve(x.category, this.data.language, chart.group)) {
            removeElement = true;
            index = data.indexOf(x);
          }
          x.category = this.keyResolverService.resolve(x.category, this.data.language, chart.group);
          x.key = x.group;

        });

        if (removeElement) {
          data.splice(index, 1);
        }
      }
      let chartData = CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data).process();

      chart.DashletChartTypes = this.chartOptionsService.getSupportedChartTypes(chart, chartData.categories.length, chartData.groupedChart);

      return chartData;
    }))
  }

  statusColors(data) {
    return data.map(x => { return this.globalStatusService.returnHexColor(x) });
  }
  setChartColors() {
    this.entityChartColors = [
      { key: this.localize(`txt_${Status.Posted}`, this.data.language, this.ModuleNames.Onboarding), color: this.globalStatusService.returnClassColor(Status.Posted) },
      { key: this.localize(`txt_${Status.Pending}`, this.data.language, this.ModuleNames.Onboarding), color: this.globalStatusService.returnClassColor(Status.Pending) },
      { key: this.localize(`txt_${Status.Progress}`, this.data.language, this.ModuleNames.Onboarding), color: this.globalStatusService.returnClassColor(Status.Progress) },
      { key: this.localize(`txt_${Status.Expired}`, this.data.language, this.ModuleNames.Onboarding), color: this.globalStatusService.returnClassColor(Status.Expired) },
      { key: this.localize(`txt_${Status.Inactive}`, this.data.language, this.ModuleNames.Onboarding), color: this.globalStatusService.returnClassColor(Status.Inactive) },
      { key: this.localize(`txt_${Status.Completed}`, this.data.language, this.ModuleNames.Onboarding), color: this.globalStatusService.returnClassColor(Status.Completed) },
    ]
  }

  onPageChange(page) {
    this.pageChanged.next(page);
  }

  exportRespondents() {
      // this.respondentsTableService.fetchFullList().pipe(filter)
    this.addSubscription(this.surveyAnalysisService.getRespondentsListWithCriteria({
      companyKey: this.filters.companyKey,
      tenantKey: this.filters.tenantKey,
      surveyKey: this.filters.survey.key,
      filters: [...this.analyticsService.mapGlobalFilters(this.filters)],
      skipCount: 0,
      maxResultCount: this.totalRecords,
    }).subscribe(x => {
        
        let data = x.items.map(x => {
          let item = {
            employeeId: x.employeeId,
            name: x.name,
            email: x.email,
            status: this.keyResolverService.resolve(`${x.status?.toUpperCase()}`, 'EN', this.ModuleNames.Shared),
          }
          this.surveyCriterias?.forEach(criteria => {
            item[this.ExtractPipe.transform(criteria.name, this.language)] = this.extractCustomProps(x, criteria.key)
          })

          return item;
        })
  
        this.ZenCDKService.openComponent(JsonToExcelComponent, {
          data: data,
          name: this.localize('txt_respondents_list', this.filters.language, this.ModuleNames.Wellbeing)
        })
  
      }))
    }

  extractCustomProps(record: any, key: string) {
    let propKey = key.replace("criteria_", "");

    let prop = record?.customProperties?.find(x => x.key == propKey)

    return prop ? this.criteriaResolverService.extract(propKey, prop.value, this.language) : ""
  }


}
