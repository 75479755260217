<q-progress *ngIf="stage == 'started'" [language]="language" [currentQuestion]='currentQuestion'  [allQuestions]="allQuestions">
</q-progress>
<div class="flex flex-row justify-center md:h-20 h-15 md:bg-transparent bg-white sticky top-0 items-center md:relative z-50">
  <div class="flex flex-row w-full justify-between items-center px-7 lg:px-14">
    <div class="w-1/3">
      <h5 class="text-sm  text-zen font-semibold z-50 whitespace-nowrap" *ngIf="stage != 'initial'">{{title}} <br> <span class="font-normal" *ngIf="subtitle && stage" [innerHTML]="subtitle"></span></h5>
    </div>
    <img *ngIf="stage == 'initial'" class="h-8" src="assets/img/svg/logo.svg" alt="ZENITHR Logo" />
    <div *ngIf="stage == 'started'" [ngClass]="!flatted?'justify-between':'justify-center'" class="flex flex-row gap-7 items-center md:static absolute w-screen md:w-auto left-0 px-7 md:justify-center  md:h-20 h-10 md:bg-transparent bg-zen bg-opacity-10 top-15 lg:top-0 backdrop-filter backdrop-blur-2xl md:backdrop-blur-0">
      <h5 *ngIf="!flatted" class="text-sm font-normal"> <span class="text-exp">{{'txtSection'| translate:language:ModuleNames.Shared}} {{currentSection +  1}} : </span> <span class="text-zen"> {{section.name| extract:language}}</span></h5>

      <h5 class="text-sm font-normal"> <span class="text-exp md:inline-block hidden ">{{'txtQuestion'|
          translate:language:ModuleNames.Shared}}:</span> <span class="md:text-zen text-exp"> {{currentQuestion + 1}} {{'txtof'|translate:language:ModuleNames.Shared}}
          {{allQuestions.length}}</span></h5>
    </div>
    <div class="flex flex-row items-center justify-end gap-2 w-1/3">
      <!-- <div [@child11] appClickOutside (clickOutside)="closeDropdown('showMenu')"
        [ngClass]="(language | language )?'rtl':'ltr'">
        <i
          class="fa-light fa-moon leading-9 w-9 h-9 text-center bg-zen bg-opacity-10 rounded text-zen btn pointer-events-none"></i>
      </div> -->
      <div [@child15] appClickOutside (clickOutside)="closeDropdown('showLangage')"
        [ngClass]="(language | language)?'rtl':'ltr'">
        <div (click)="showLangage = !showLangage"  class="flex flex-row items-center px-2 text-sm text-center bg-zen bg-opacity-10 rounded text-zen btn h-9">
          <i class="fa-light fa-earth-americas px-1"></i>
          <span class="leading-9">{{getLongLanguageName(language)}}</span>
        </div>
        <div class="flex left-0 flex-row justify-end absolute w-full h-0 px-5">
          <div [@fadeUpDown] *ngIf="showLangage"
            class="flex flex-col gap-1 top-22 px-2 py-2 rounded-lg shadow-lg bg-white  mt-5 topArrow relative h-max min-w-32 max-h-96 innerListScroll">
            <ng-container *ngFor="let lang of supportedLanguages">
              <a [ngClass]="lang.key == language?'bg-exp bg-opacity-5 text-exp':'bg-zen bg-opacity-5 text-zen'" (click)="languageChanged.emit(lang.key); isArabic(lang.key); closeDropdown('showLangage')"
                class="text-sm btn rounded-sm py-2 px-2 hover:bg-opacity-20 capitalize">
                {{lang.nativeName}}</a>
            </ng-container>
          </div>
        </div>
      </div>

    </div>
    <!-- <div class="bg-zen bg-opacity-10 rounded-md px-5 pt-1 overflow-hidden h-12 w-32">
      <mat-form-field *ngIf="language" class="w-full -mt-3" appearance="none">
        <mat-select disableRipple [(ngModel)]="language"
          (selectionChange)="languageChanged.emit($event.value); isArabic()">
          <mat-option disableRipple *ngFor="let ln of supportedLanguages" [value]="ln.key">
            {{ ln.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
  </div>
</div>
<loader [language]="language" *ngIf="loader.showLoader || stage ==''"></loader>

