export enum QuestionTypes {
    Rating = "rating",
    Ranking = "ranking",
    Checkbox = "checkbox",
    TagBox = "tagbox",
    RadioGroup = "radiogroup",
    Enps = "enps",
    Comment = "comment",
    FileUpload = "file",
    Text = "text",
    ImagePicker = "imagepicker",
    Boolean = "boolean",
    Dropdown = "dropdown",
    Matrix = "matrix",
}
