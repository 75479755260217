<div class="flex flex-col w-full items-center gap-5">
  <div class="flex flex-row justify-between items-center w-full">
    <h2 *ngIf="headingName != ''" [@child5] class="text-zen font-normal text-3xl" [TourStep]="tourKey?{title:'txt_respondents_pool' | translate:language:ModuleNames.Shared,text: 'txt_Thispages_698' | translate:language:ModuleNames.Shared,order:1,tourKey:tourKey}:undefined">{{
      headingName |translate:language:ModuleNames.Shared }}</h2>
    <div class="flex flex-row gap-3 items-center">

      <a *ngIf="hasAdd" [@child7] (click)="respondentAddFunction.emit()" class="btn exp uppercase leading-12 rounded px-5" [TourStep]="tourKey?{title:'btn_add_respondent' | translate:language:ModuleNames.Shared,text: 'txt_Clickhere_692' | translate:language:ModuleNames.Shared,order:2,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined">
        {{ 'txt_new_respondent'| translate:language:ModuleNames.Shared }}
      </a>
    </div>
  </div>
  <div class="flex flex-col w-full" *ngIf="options?.data?.length >= 0 ">
    <div class="flex flex-col w-full gap-5">
      <t-table #table [@child9] [options]="options" [reload]="reloadResList" (reloadTable)="reloadTable()" (actionClicked)="actionClicked($event)" (tableSelectionChanged)="restableSelectionChanged.emit($event)" [hasMassDelete]="hasMassDelete" (selectionChanges)="selectedRespondents = $event;" [exportTour]="tourKey?{title:'btnExport' | translate:language:ModuleNames.Shared,text: 'txt_Clickhere_794' | translate:language:ModuleNames.Shared,order:3,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined">
        <ng-template #cellTempate let-element let-column='column' let-colIndex='colIndex'>
          <div *ngIf="column.field == 'employeeId'">
            <h5 class="max-w-xs truncate text-sm text-zen">{{ element.employeeId == '' || !element.employeeId? ("txtNA"| translate:language:ModuleNames.Shared) : element.employeeId}}
            </h5>
          </div>
          <div *ngIf="column.field == 'name'">
            <h5 class="max-w-xs truncate text-sm text-zen leading-20">{{ element.name }}</h5>
          </div>
          <div *ngIf="column.field == 'email'">

            <h5 class="max-w-xs truncate text-sm text-zen">{{ element.email }}</h5>

          </div>
          <div class="flex flex-row justify-end items-center" *ngIf="column.field == 'action'">
            <div [TourStep]="tourKey?{title:'txt_DeleteRes_550' | translate:language:ModuleNames.Shared,text: 'txt_Deleteres_201' | translate:language:ModuleNames.Shared,order:4,tourKey:tourKey,on:(language | language )?'right':'left',isRows:true}:undefined">
              <lottie-player (click)="actionClicked({ request: element, action: 'REMOVE' })" src="/assets/lottie/delete2.json" background="transparent" speed="1" style="width: 2rem; height: 2rem;" class="btn transform scale-75" hover="false" autoplay="false"></lottie-player>
            </div>
          </div>
        </ng-template>

        <ng-container headerOptions>
          <ng-content select="[innerHeaderOptions]"></ng-content>
        </ng-container>
      </t-table>
    </div>
    <!-- <EmptySection [@child5] *ngIf="options?.data?.length == 0"></EmptySection> -->
  </div>
  <!-- <mini-loader *ngIf="!options?.data || (!options?.data?.length && options?.data?.length != 0)"></mini-loader> -->

</div>
<product-tour *ngIf="tourKey" [key]="tourKey" [offset]="60" [destroy]="true"></product-tour>
