import type { ExcelDataTypes } from '../../excel-uploader/excel-data-types.enum';
import type { EmailTemplateDto } from '../../core/common/models';
import type { EntityHierarchyDto } from '../../core/entities/models';

export interface ColumnConfigDto {
  originalKey?: string;
  key?: string;
  isEntityHierarchy?: boolean;
  type?: string;
  title?: string;
  lookup: LookupConfigDto;
  dataType?: ExcelDataTypes;
  format?: string;
  isRequired?: boolean;
}

export interface ExcelBulkUploadRequestDto {
  tenantKey: string;
  companyKey: string;
  excelFile: string;
  surveyKey: string;
  options: ExcelOptionsDto;
  invitationEmailTemplate: EmailTemplateDto;
  reminderEmailTemplate: EmailTemplateDto;
}

export interface ExcelOptionsDto {
  fileName?: string;
  columns: ColumnConfigDto[];
  entityHierarchy: EntityHierarchyDto[];
}

export interface ExcelSheetDownloadDto {
  excelFileBase64?: string;
}

export interface ExcelSheetValidateRequestDto {
  tenantKey: string;
  companyKey: string;
  excelFile: string;
  surveyKey: string;
  options: ExcelOptionsDto;
}

export interface ExcelValidationResponseDto {
  jobKey?: string;
  totalRecords: number;
  hasValidationErrors: boolean;
}

export interface LookupConfigDto {
  parentColumnKey?: string;
  key?: string;
  title?: string;
  options: LookupOptionDto[];
}

export interface LookupOptionDto {
  key?: string;
  id: object;
  name: object;
  parentKey: object;
}
