<div class="flex flex-col items-stretch bg-zen-lite" [ngClass]="{'h-full min-h-screen-70  pt-12': mode == 'full'}"
  *DemoAccount="'Default'; let isDemo=isDemo">
  <div [hidden]="mode == 'full'" class="flex flex-row justify-between items-center h-20 leading-20 px-7 text-white btn"
    (click)="open = !open">
    <div class="flex flex-row items-center gap-2">
      <i class="fa-regular" [ngClass]="selected?.icon"></i>
      <p class="text-sm truncate">{{selected?.name}}</p>
    </div>
    <i class="fa-regular transition-all ease-expo transform fa-angle-up duration-500"
      [ngClass]="open?'rotate-0':'rotate-180'"></i>
  </div>
  <div class="flex flex-col transition-all overflow-hidden ease-expo duration-500 o"
    [ngClass]="{'max-h-60':open && mode != 'full','max-h-0':!open && mode != 'full','h-full':mode == 'full'}">
    <div [@listAnimation]="data?.length" class="flex flex-col items-stretch pt-1"
      [ngClass]="{'max-h-52 innerListScroll':open && mode != 'full'}">
      <ng-container *ngFor="let items of data; let n = index">
        <div [TourStep]="tours && tours[n]?tours[n]:undefined">
          <div *ngIf="items?.title || items?.button" class="flex flex-row justify-between items-center px-7 pt-7 pb-4">
            <h6 [@zero] *ngIf="items?.title" class="uppercase text-xxs text-white text-opacity-50 font-normal ">
              {{items.title}}
            </h6>
            <ng-container *ngIf="items.button">
              <i *DemoAccount="isDemo && items?.list?.length?'Button':'Default'" [TourStep]="tours && n == 1 ? tours[n] : undefined" (click)="isDemo && items?.list?.length?'':buttonClick.emit(n)"
                class="fa-regular text-white btn  h-9 w-9 leading-9 text-center bg-exp rounded-sm"
                [ngClass]="items.button"></i>
            </ng-container>
          </div>
          <div [@listAnimation]="items?.list?.length">
            <ng-container *ngFor="let item of items?.list; let l=index">
              <ng-container *ngIf="(selected?.key != item.key && mode != 'full') || mode == 'full'">
                <div *DemoAccount="l && mode == 'full'?'Button':'Default'" (click)="isDemo && l &&  mode == 'full'?'': handleSelection(item)"
                  class="flex flex-row items-stretch gap-2 px-7 text-white btn hover:bg-zen hover:bg-opacity-80 overflow-x-clip"
                  [ngClass]="mode == 'full' && selected?.key == item.key?'bg-zen':''">
                  <i class="fa-regular leading-15" [ngClass]="item.icon"></i>
                  <p class="text-sm h-15 leading-15 truncate">{{item.name}}</p>
                </div>
                <span *ngIf="(selected?.key != item.key && mode != 'full')"
                  class="h-px border-t border-white border-opacity-10"></span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
