<div class="flex  flex-row items-center justify-between px-7 rounded-md bg-zen-lite bg-opacity-5 h-20">
    <div class="flex  flex-row items-center w-full">
        <div class="flex flex-row items-center exp gap-3.5">
            <div #trigger class="h-10 relative">
                <a #button (click)="openFilters = true" class="btn white leading-10 h-10  px-4 rounded-3xl block maxFBox gap-1"><i class="far fa-plus"></i>
                    {{ "txt_AddFilter_176" | translate:language:ModuleNames.Common }}</a>
            </div>
            <div class="w-8	h-20" [ngClass]="rtl ? 'divide-rbar':'divide-lbar'"></div>
        </div>
        <div class="flex flex-row  items-center gap-2.5 h-18 w-full overflow-x-overlay diV-bar">
            <ng-container *ngFor="let filter of filters let i=index">
                <div *ngIf="filter.values && filter.values.length>0" class="flex flex-row text-sm group relative">
                    <a *ngIf="filter.values.length==1" class="btn white leading-10 px-4 rounded-3xl maxFBox">
                        <span>{{filter.name| translate:language:ModuleNames.SurveyX}}</span>
                        <span class="py-0.5 px-1 mx-1 bg-exp rounded text-white text-xs truncate ...">
                            {{filter.values[0] }}
                        </span>
                    </a>
                    <a *ngIf="filter.values.length>1" class="btn white leading-10 px-4 rounded-3xl maxFBox" [ZMenuTriggerFor]="menu">
                        {{filter.name| translate:language:ModuleNames.SurveyX}}
                        <span class=" py-0.5 px-1 mx-1 bg-exp rounded text-white text-xs ">
                            {{filter.values.length}}
                        </span>
                    </a>
                    <z-menu #menu>
                        <menuItems>
                            <a *ngFor="let subf of filter.values let z=index" class="flex flex-row bg-zen bg-opacity-5 text-zen  leading-8 px-2.5 items-center rounded pointer-events-none" [ngClass]="z==filter.values.length-1 ? '': 'mb-1'" MenuItem>
                                {{subf}}
                            </a>
                        </menuItems>
                    </z-menu>
                    <div class="flex flex-row absolute -top-1  -left-1 -right-1 justify-end h-0">
                        <i (click)="removeFilter(filter.key)" class="fal fa-times text-xxs btn w-3 h-3 min-w-max text-center rounded-full bg-zen-red bg-opacity-20 text-zen-red leading-3 opacity-0 group-hover:opacity-100"></i>
                    </div>
                </div>
            </ng-container>

        </div>
        <div class="flex flex-row items-center gap-3.5" [ngClass]="rtl ?'mr-auto':'ml-auto'">
            <div class="w-8	h-20" [ngClass]="rtl ? 'divide-lbar':'divide-rbar'"></div>
            <a (click)="resetFilters()" class="btn bg-zen-red bg-opacity-5 text-zen-red leading-10 h-10  px-4 rounded-3xl block maxFBox">{{ "txt_ResetFilt_877" | translate:language:ModuleNames.Common }}</a>
        </div>
    </div>
</div>
<sx-filters-form *ngIf="openFilters" [(open)]="openFilters" [language]="language" [selectedFilters]="selectedFilters" (filtersItems)="selectFilters($event)" [exclude]="exclude"></sx-filters-form>