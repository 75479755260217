import { Component, Input, OnInit } from '@angular/core';
import { ModuleName } from '../../enums/ModuleName';

@Component({
  selector: 'report-header',
  templateUrl: './report-header.component.html',
  styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnInit {

  @Input()
  headerData: any = {};

  ModuleNames = ModuleName
  constructor() { }
  ngOnInit(): void {
  }
}
