import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DatePipe } from '@angular/common';
import { LanguageService } from "../../core/services/language.service";
import { LoaderService } from "../../core/services/loader.service";
import * as html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import { ModuleName } from "../enums/ModuleName";

@Component({
  selector: 'export-arabic-preview-dialog',
  templateUrl: './export-arabic-preview-dialog.component.html',
  styleUrls: ['./export-arabic-preview-dialog.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
})
export class ExportArabicPreviewDialogComponent implements OnInit {
  exportOption: string;
  fileType: string;
  searchText: string = '';
  subject: string;
  message: string;
  reportTitle: string;
  currentUser: any;
  logoURL: string;
  smallLogoUrl: string;
  language: string;
  // txtCreatedBy: string;
  // txtGeneratedon: string;
  // txtPoweredby: string;
  exportMargin: any;
  // txtExportReport: string;
  // btn_cancel: string;
  // btnExport: string;
  applyPreviewCanvas: boolean = true;
  ModuleNames=ModuleName
  constructor(
    private languageService: LanguageService,
    public loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any, private datePipe: DatePipe
  ) { }

  getDateTime() {
    return this.datePipe.transform(new Date(), 'HH:mm dd-MM-yyyy');
  }

  ngOnInit() {
    this.language = this.data.language;
    this.reportTitle = this.data.reportTitle;
    this.currentUser = this.data.currentUser;
    this.exportMargin = this.data.exportMargin;
    this.applyPreviewCanvas = this.data.applyPreviewCanvas;
    this.logoURL = 'assets/img/logo.png'; // to be resolved to client's logo
    this.smallLogoUrl = 'assets/img/logo-small.png';
    this.data.dashlets.forEach(dashlet => {
      var div = document.createElement("div");
      var title = document.createElement("h4");
      var caption = document.createElement("P");
      var linebreak = document.createElement("br");
      title.innerText = dashlet.title;
      div.appendChild(title);
      div.appendChild(linebreak);
      if (this.applyPreviewCanvas) { // disable the css for engagement dashboards
        div.classList.add('preview-canvas');
      }
      div.appendChild(dashlet.URI);
      caption.innerText = dashlet.caption;
      div.appendChild(caption);
      document.getElementById("chartCanvas").appendChild(div);
      div.appendChild(linebreak);
    });
    // this.setUpLanguagePreference();
  }

  // setUpLanguagePreference() {
  //   this.setUpTextFields();
  // }

  // setUpTextFields() {
  //   this.txtExportReport = this.languageService.getUILanguage('txtExportReport', this.language);
  //   this.btn_cancel = this.languageService.getUILanguage('btn_leaver_list_cancel', this.language);
  //   this.btnExport = this.languageService.getUILanguage('btnExport', this.language);
  //   this.txtCreatedBy = this.languageService.getUILanguage('txtCreatedBy', this.language);
  //   this.txtGeneratedon = this.languageService.getUILanguage('txtGeneratedon', this.language);
  //   this.txtPoweredby = this.languageService.getUILanguage('txtPoweredby', this.language);


  // }

  async export() {
    this.loaderService.displayLoader();
    let temp = await this.imageExport();
    this.loaderService.hideLoader();
  }

  async setHeaderFooter(pdfObject) {
    let totalPages = pdfObject.internal.getNumberOfPages();
    var exportHeader = document.getElementById('export-header');
    var exportFooter = document.getElementById('export-footer');

    for (let i = 1; i <= totalPages; i++) {
      if (exportHeader) {
        pdfObject.setPage(i);
        let headerCanvas: HTMLCanvasElement = await new Promise(resolve => {
          html2canvas(exportHeader, { width: 1754, height: 80 }).then(function (dataUrl) {
            resolve(dataUrl);
          })
            .catch(function (error) {
              console.error('oops, something went wrong!', error);
            });
        });

        let footerCanvas: HTMLCanvasElement = await new Promise(resolve => {
          html2canvas(exportFooter, { width: 1754, height: 80 }).then(function (dataUrl) {
            resolve(dataUrl);
          })
            .catch(function (error) {
              console.error('oops, something went wrong!', error);
            });
        });

        pdfObject.addImage(headerCanvas, "PNG", 0, 0, 0, 0, 'export-header');
        pdfObject.addImage(footerCanvas, "PNG", 0, 700, 0, 0, 'export-footer');
      }
    }
  }

  async imageExport() {
    var data = document.getElementById('chartCanvas');
    var opt = {
      margin: [100, 60, 100, 60],
      filename: this.reportTitle + '.pdf',
      image: { type: 'jpeg', quality: 1 },
      enableLinks: true,
      html2canvas: { dpi: 150, letterRendering: true, scale: 2 },
      pagebreak: { mode: 'avoid-all', before: '#page2el' },
      jsPDF: { unit: 'px', format: 'a4', orientation: 'landscape', hotfixes: ['px_scaling'] }
    };
    return html2pdf().from(data).set(opt).toPdf().get('pdf').then(this.setHeaderFooter).save();
  }
}
