import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wellbeing',
  templateUrl: './wellbeing.component.html',
  styleUrls: ['./wellbeing.component.scss'],
})
export class WellbeingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  routes = [
    {
      name: 'txtSurveys',
      link: '/wellbeing/home',
      img: null,
      icon: 'clipboard-list',
      title: '',
    },
    {
      name: 'txtPeople',
      link: '/wellbeing/people',
      img: null,
      icon: 'users',
      title: '',
    },
    {
      name: 'txtComparison',
      link: '/wellbeing/comparison',
      img: null,
      icon: 'table-columns',
      title: 'comparison',
    },
  ];
}
