import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ZenSnackbarComponent } from '../components/zen-snackbar/zen-snackbar.component';
import { ZenCDKService } from 'src/app/shared/Theme/services/zen-cdk.service';

@Injectable({
  providedIn: 'root'
})
export class ZenSnackbarService {
  constructor(private zenCdk: ZenCDKService) { }

  private subject = new Subject<any>();

  snackBar(message: ZenSnackBarOptions) {
    this.openSnackBar(message);
  }

  error(message: string, delay?: number) {
    this.openSnackBar({ message: message, delay: delay, type: ZenSnackBarTypes.Error });
  }
  success(message: string, delay?: number) {
    this.openSnackBar({ message: message, delay: delay, type: ZenSnackBarTypes.Success });
  }
  info(message: string, delay?: number) {
    this.openSnackBar({ message: message, delay: delay, type: ZenSnackBarTypes.Info });
  }
  warning(message: string, delay?: number) {
    this.openSnackBar({ message: message, delay: delay, type: ZenSnackBarTypes.Warning });
  }

  clearSnackBars() {
  }

  getSnackBar$ = this.subject.asObservable();
  openSnackBar(data) {
    this.zenCdk.openComponent(ZenSnackbarComponent, data);
  }
}

export interface ZenSnackBarOptions {
  message: string;
  delay?: number;
  type?: ZenSnackBarTypes;
}

export enum ZenSnackBarTypes {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}
