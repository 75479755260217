import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { LanguageService } from '../../core/services/language.service';
import { Tenant } from '../../core/data-models/tenant';
import { Entity } from '../../core/data-models/Entity';
import { AuthenticationService } from '../../core/services/authentication.service';
import { LocalService } from '../../core/services/local.service';
import { ChildrenAnimation, fadeUpDown } from '../animations/allAnimations';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivationEnd, Router } from '@angular/router';
import { ZenSnackbarService, ZenSnackBarTypes } from '../Theme/services/zen-snackbar.service';
import { CrashReportingService } from '../../core/services/crash-reporting.service';
import { Subscription } from 'rxjs';
import { Notification } from '../../core/data-models/notification';
import { NotificationMessage } from '../../core/data-models/notification-message';
import { NotificationsService } from '../../core/services/notifications.service';
declare var $: any;
import { UnitsNotificationDialogComponent } from '../units-notification-dialog/units-notification-dialog.component';
import { TenantService } from '../../core/services/tenant.service';
import { LogService } from '../../core/services/log.service';
import { EntityService } from '../../core/services/entity.service';
import { PlatformUserService } from '../../core/services/platform-user.service';
import { EssentialObjectService } from '../../core/services/essential-object.service';
import { PlatformUser } from '../../core/data-models/platform-user';
import { UnitBalanceService } from '../../core/services/unit-balance.service';
import { QuestionnaireService } from 'src/app/core/services/questionnaire.service';
import { Questionnaire } from 'src/app/core/data-models/questionnaire';
import { filter } from 'rxjs/operators';
import { ModuleName } from '../enums/ModuleName';
import { LanguagePipe } from '../pipes/language.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { LoaderService } from 'src/app/core/services/loader.service';
import { CompanyPermission, products, productsList } from 'src/app/core/data-models/company-permission';
import { GeneralService } from 'src/app/core/services/general.service';
import { DemoAccountService } from '../demo-account/demo-account.service';
interface MainRoute{
  key?:string,
  url:string,
  name:string,
  image:string,
}
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeUpDown, ChildrenAnimation],
})
export class HeaderComponent implements OnInit {
  user: PlatformUser;
  users: PlatformUser[] = [];
  selectedEntity;
  essentialObject: any = {};
  entities: Entity[] = [];
  allEntities: Entity[] = [];
  companyEntities: Entity[] = [];
  private subscriptions = new Subscription();
  questionnaires: Questionnaire[] = [];
  allQuestionnaires: Questionnaire[] = [];
  reloadSelectedEntity: boolean = false;
  @Input() section: boolean = false;
  @Input() customLogo: boolean = false;
  @Input() showCompanies: boolean = true;
  ModuleNames = ModuleName
  selectedEntityKey: string;
  supportedLanguages: any[] = [];
  numberOfNotifications: number = 0;
  lang: string;
  language;
  showMenu: boolean = false;
  showUser: boolean = false;
  showNotifications: boolean = false;
  constructor(
    private tenantService: TenantService,
    private logService: LogService,
    private platformUserService: PlatformUserService,
    private entitiesService: EntityService,
    private languageService: LanguageService,
    private notificationsService: NotificationsService,
    private router: Router, private questionnaireService: QuestionnaireService,
    public matDialog: MatDialog, public loader:LoaderService,
    private authenticationService: AuthenticationService,
    public matDDialog: MatDialog,private languagePipe:LanguagePipe,
    private zenSnackBarService: ZenSnackbarService,
    private errorHandlerService: ErrorHandlerService,
    private crashReportingService: CrashReportingService,
    private localService: LocalService, private cdr: ChangeDetectorRef,
    private unitBalanceService: UnitBalanceService,
    private essentialDataService: EssentialObjectService,private translationPipe:TranslatePipe,
    private generalService: GeneralService,
    private demoAccountService: DemoAccountService) {
    router.events.pipe(filter(event => event instanceof ActivationEnd))
      .subscribe((event: ActivationEnd) => {
        if (event.snapshot.data.name)
          this.title = event.snapshot.data.name;
      });

  }
  title;
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  module;
  @Output() selectedEChanged = new EventEmitter();
  @Output() languageOutput = new EventEmitter();
  companies: Entity[] = [];
  tenant: Tenant;
  @Input('nav') nav: boolean = true;
  selectedLanguageChange(key) {
    this.language = key;
    this.localService.setJsonValue('language', this.language);
    location.reload();
    this.isArabic();
  }
  tempList;
  surveys: any;
  displayOptions: boolean = false;
  notifications: Notification[] = [];
  notificationMessages: NotificationMessage[] = [];
  // @Input('language') set selectedLanguaged(lang: string) {
  //   this.language = lang;
  //   if (this.language)
  //     this.lang = this.language;
  //   this.setUpLanguagePreference();

  // }
  routes:MainRoute[] = [
    {key:'onboarding', name:'txtOnBoarding', url:'/onboarding/home', image:'/assets/img/sub/onboarding.svg'},
    {key:'engagement', name:'txtEngagement', url:'/engagement/home', image:'/assets/img/sub/Engagement.svg'},
    {key:'happiness', name:'txtEmployeeHappiness', url:'/happiness/home', image:'/assets/img/sub/Happiness.svg'},
    {key:'wellbeing', name:'txtWellbeing', url:'/wellbeing/home', image:'/assets/img/sub/Wellbeing.svg'},
    {key:'assessment360', name:'txt360DegreeFeedback', url:'/assessment360/home', image:'/assets/img/sub/360.svg'},
    {key:'assessment180', name:'txt180FeedbackAssessment', url:'/assessment180/home', image:'/assets/img/sub/180.svg'},
    {key:'surveyx', name:'txtSurveyx', url:'/survey-x', image:'/assets/img/sub/SurveyX.svg'},
    {key:'exit', name:'txt_employee_exit', url:'/exit-v2/home', image:'/assets/img/sub/Exit.svg'},
  ];
  logout() {
    this.authenticationService.logout();
    this.cdr.detectChanges();
  }
  selectEntityChange(event) {
    let company = this.companies.find((c) => c.key == this.selectedEntityKey);
    this.selectedEntity = company;
    this.selectedEntity = JSON.parse(JSON.stringify(this.selectedEntity));
    let localStorageEntity = {
      entityKey: this.selectedEntity.key,
      userKey: this.user.key,
    };
    this.localService.setJsonValue(
      'selectedEntity',
      JSON.stringify(localStorageEntity)
    );
    this.essentialObject.selectedEntity = company;
    let tObject = {};
    this.subscriptions.add(this.essentialDataService.essentialObject.subscribe((s) => {
      tObject = Object.assign({}, s, this.essentialObject);
    }));
    this.essentialDataService.updateObject(tObject);

  }
  logData(e) {
    console.log(e);

  }
  isArabic() {
    if (this.languagePipe.transform(this.language)) {
      $('body').removeClass('ltr');
      $('body').addClass('rtl').attr('dir', 'rtl');
    } else {
      $('body').removeClass('rtl');
      $('body').addClass('ltr').attr('dir', 'ltr');
    }
    this.languageOutput.emit(this.language);
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  getLongLanguageName(eG: any) {
    return this.languageService.getLanguageName(eG);
  }

  fetchNotifications() {
    try {


      this.subscriptions.add(this.notificationsService
        .fetchUserNotifications(this.user)
        .subscribe((notificatins: any[]) => {
          this.notifications = notificatins;
          this.evaluateNotifications();
        }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        108,
        ex.toString(),
        this.tenant.key,
        this.tenant.name,
        this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name),
        this.user?.firstName +
        ' ' +
        this.user?.lastName +
        ' (' +
        this.user?.email +
        ')'
      );
    }
  }

  customLogoURL: string;
  resetPageID() {
    this.localService.removeItem('selectedEngagement');
    this.localService.removeItem('selectedAssessment');
  }
  isThomas: boolean = false;
  ngOnInit() {

    if(window.location.href.includes("thomas-hub"))
      this.isThomas = true;

    this.essentialDataService.headerForceUpdate.subscribe(s=> {
      if(s.update){
        this.essentialDataService.updateHeader({update:false});
        setTimeout(() => {
          this.ngOnInit();
        }, 400);
      }
    });
    this.authenticationService.getTimoutAndEvaluate();
    this.fetchPlatformUser();
    try {
      this.crashReportingService.submitLogCode(20);
      this.supportedLanguages = this.languageService.supportedLanguages.filter(s => (s.key == 'EN' || s.key == 'AR'));;
      this.user = new PlatformUser();
      this.tenant = new Tenant();

      let localLanguage = this.localService.getJsonValue('language');
      if (!localLanguage) {
        this.language = 'EN';
        this.localService.setJsonValue('language', this.language);
      } else {
        this.language = localLanguage;
        // this.language = 'FR';
      }
      this.isArabic();
      this.languageService.language = this.language;
      this.essentialObject.language = this.language;

      // Fetch Tenant Info

      this.subscriptions.add(this.unitBalanceService.fetchSurveys().subscribe((surveys: any) => {
        this.surveys = surveys;
        this.surveys = JSON.parse(JSON.stringify(this.surveys));
        this.essentialObject.surveys = surveys;
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        501,
        ex.toString(),
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        'NOT YET INITIATED'
      );
    }


  }

  openOptions() {
    this.tempList = this.companies;
    this.tLanguage = this.language;
    this.tSelectedEntityKey = this.selectedEntityKey;
    this.displayOptions = true;
    this.scroll(this.tSelectedEntityKey);
  }

  closeDropdown(menu) {
    if (this[menu]) this[menu] = false;
  }

  resetPassword() {
    try {
      let message =this.translationPipe.transform('txt_Password_resetConfirmation',this.language,this.ModuleNames.Shared)
      let subject =this.translationPipe.transform('txt_subject_password_resetConfirmation',this.language,this.ModuleNames.Shared)
      message = message.replace('#EMAIL#', this.user.email);
      let actionButtonText =this.translationPipe.transform('txt_yes',this.language,this.ModuleNames.Shared)
      let cancelButtonText =this.translationPipe.transform('btn_cancel',this.language,this.ModuleNames.Shared)

      let confirmationDialog = this.matDDialog.open(
        ConfirmationDialogComponent,
        {
          data: {
            message: message,
            subject: subject,
            dialogType: 'warning',
            actionButtonText: actionButtonText,
            cancelButtonText: cancelButtonText,
          },
          autoFocus: false,
        }
      );
      this.subscriptions.add(confirmationDialog.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 'YES') {
            this.authenticationService
              .sendResetPassword(this.user.email)
              .then((_) => {
                this.crashReportingService.submitLogCode(19);
                this.zenSnackBarService.snackBar({ message:this.translationPipe.transform('txtResetEmail',this.language,this.ModuleNames.Shared)  , type: ZenSnackBarTypes.Success });
              });
          }
        }
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        4,
        ex.toString(),
        this.tenant.key,
        this.tenant.name,
        this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name),
        this.user?.firstName +
        ' ' +
        this.user?.lastName +
        ' (' +
        this.user?.email +
        ')'
      );
    }
  }
  async fetchPlatformUser() {
    try {


      this.logService.submitLog(
        0,
        'HomeComponent.fetchPlatformUser()',
        'Task Assigned',
        {}
      );
      this.subscriptions.add(this.platformUserService
        .fetchPlatformUser(this.localService.getJsonValue('UserKey'))
        .subscribe(async (user: PlatformUser) => {
          this.logService.submitLog(
            0,
            'HomeComponent.fetchPlatformUser()',
            'Task Completed',
            { user }
          );
          this.user = user;
          this.fetchNotifications();


          let permissionResponse: any = await this.platformUserService
            .fetchUserPermissions(this.user)
            .toPromise();
          if (permissionResponse.response == 'SUCCESS') {
            if (permissionResponse?.Data != 'Super Admin') {
              let permissions: CompanyPermission[] = permissionResponse?.Data;
              this.user.CompanyPermissions = permissions;
              this.user.EntityReferences = [];
              for (let i = 0; i < permissions.length; i++) {
                let permission = permissions[i];
                this.user.EntityReferences.push(permission.key);
              }
            } else if (permissionResponse?.Data == 'Super Admin'){
              this.user.role = permissionResponse?.Data;
              this.essentialObject.user = this.user
            }
          } else {

          }
          this.essentialObject.user = this.user;
          console.log(this.essentialObject.user);

          let sectionNumber = this.localService.getJsonValue('sectionID');
          if (!sectionNumber) {
          } else {
          }

          if (!this.tenant?.key) {
            this.tenant.key = this.user.tenantKey;
            this.fetchTenant();
            //this.platformUserService.fetchRelease();
          }
        }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        502,
        ex.toString(),
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        'NOT YET INITIATED'
      );
    }
  }


  fetchTenant() {
    try {
      this.tenant.key = this.languageService.getTenant(this.tenant.key);

      this.logService.submitLog(
        0,
        'HomeComponent.fetchTenant()',
        'Task Assigned',
        {}
      );
      this.subscriptions.add(this.tenantService
        .fetchTenant(this.tenant.key)
        .subscribe((tenant: Tenant) => {
          if (tenant) {
            this.tenant = tenant;

            this.evaluateTenant();
            // this.essentialDataService.essentialObject.tenant = tenant;
            this.essentialObject.tenant = tenant;

            if (this.user.role == 'Super Admin') {
              this.fetchAllTenantUsers();
            }

            this.logService.submitLog(
              0,
              'HomeComponent.fetchTenant()',
              'Task Completed',
              { tenant }
            );
          } else {
            this.logService.submitLog(
              0,
              'HomeComponent.fetchTenant()',
              'Task Failed',
              {}
            );
          }
        }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        503,
        ex.toString(),
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        this.user?.firstName +
        ' ' +
        this.user?.lastName +
        ' (' +
        this.user?.email +
        ')'
      );
    }
  }
  evaluateTenant() {
    if (!this.tenant.setup) {
      this.router.navigate(['/setup']);
    } else {
      this.fetchAllEntities();
    }
  }
  fetchAllTenantUsers() {
    try {
      this.subscriptions.add(this.platformUserService
        .fetchAllTenantUsers(this.tenant.key)
        .subscribe((users: PlatformUser[]) => {
          this.users = users;
        }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        504,
        ex.toString(),
        this.tenant.key,
        this.tenant.name,
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        this.user.firstName +
        ' ' +
        this.user?.lastName +
        ' (' +
        this.user.email +
        ')'
      );
    }
  }

  async fetchAllEntities() {
    try {
      this.logService.submitLog(
        0,
        'HomeComponent.fetchAllEntities()',
        'Task Assigned',
        {}
      );
      this.subscriptions.add(this.entitiesService
        .fetchEntities(this.tenant.key)
        .subscribe(async (entities: Entity[]) => {
          if (entities) {
            this.logService.submitLog(
              0,
              'HomeComponent.fetchAllEntities()',
              'Task Completed',
              { entities }
            );

            this.allEntities = entities;
            this.essentialObject.allEntities = entities;

            if(this.essentialObject.user.role != 'Super Admin'){
              let entityPermissions: any = await this.platformUserService.fetchUserPermissions(this.essentialObject.user).toPromise();
              if(!entityPermissions.success)
                return;
              this.essentialObject.user.CompanyPermissions = entityPermissions.Data;
              console.log('From ERROR', this.essentialObject.user);
              this.allEntities = this.allEntities.filter((e) => this.essentialObject.user.CompanyPermissions.find((p) => p.key == e.key));
              if (this.essentialObject.user?.CompanyPermissions?.length > 0) {
                this.essentialObject.user.ModulePermissions = JSON.parse(JSON.stringify(this.essentialObject.user.CompanyPermissions));
                for(let i = 0; i < this.essentialObject.user.ModulePermissions.length; i++){
                  let permission = this.essentialObject.user.ModulePermissions[i];
                  let entity = entities.find(e => e.key == permission.key);
                  if(entity?.isCompany){
                    let subEntities = entities.filter(e => e.parentKey == entity.key);
                    let subEntityPermittedList = subEntities.filter(e => this.essentialObject.user.ModulePermissions.find(p => p.key == e.key));
                    let subEntityPermissions = this.essentialObject.user.ModulePermissions.filter(p => subEntityPermittedList.find(ep => ep.key == p.key));
                    if(subEntities.length == subEntityPermittedList.length){
                      permission.products = [];
                      for(let x = 0; x < productsList.length; x++){
                        let product = productsList[x];
                        let productPermissionEntities = subEntityPermissions.filter(ep => ep?.products?.find(p=> p == product.value));
                        if(productPermissionEntities.length == subEntityPermittedList.length){
                          permission.products.push(product.value);
                        }
                      }
                    } else {
                      permission.products = [];
                    }
                  }
                }
              }

            }
            console.log('Entity Permissions ====> ', this.user.ModulePermissions);
            if (this.user.role == 'Super Admin') {
              this.entities = entities;
            } else {
              for (let i = 0; i < entities.length; i++) {
                let entity = entities[i];
                if(this.essentialObject.user.CompanyPermissions.find(e => e.key == entity.key))
                  this.entities.push(entity);
                // if (this.user.EntityReferences.find((eR) => eR == entity.key)) {
                //   this.entities.push(entity);
                // } else {
                //   if (!entity.isCompany) this.entities.push(entity);
                // }
              }
            }








            if (!this.selectedEntity) {
              let localEntity: any = JSON.parse(
                this.localService.getJsonValue('selectedEntity')
              );
              if (localEntity) {
                if (localEntity.userKey != this.user.key) {
                  localEntity = null;
                }
              }
              if (!localEntity) {
                if (this.entities.length > 0) {
                  let companyEntity = this.entities.find(
                    (e) => e.isCompany == true
                  );
                  this.selectedEntity = companyEntity;
                  // this.essentialDataService.essentialObject.selectedEntity = companyEntity;
                  this.essentialObject.selectedEntity = companyEntity;
                  console.log('companyEntity');
                }
              } else {
                if (this.entities.length > 0) {
                  this.selectedEntity = this.entities.find(
                    (et) => et.key == localEntity.entityKey
                  );
                  if (!this.selectedEntity) {
                    if (this.entities.length > 0) {
                      let companyEntity = this.entities.find(
                        (e) => e.isCompany == true
                      );
                      this.selectedEntity = companyEntity;
                      let localStorageEntity = {
                        entityKey: this.selectedEntity.key,
                        userKey: this.user.key,
                      };
                    }
                  }
                  // this.essentialDataService.essentialObject.selectedEntity =  this.selectedEntity;
                }
              }
            } else {
              this.selectedEntity = this.entities.find(
                (e) => e.key == this.selectedEntity.key
              );
            }

            this.companyEntities = this.entitiesService.structureEntities(
              this.entities,
              this.selectedEntity.key
            );
            this.companies = this.entities.filter((e) => e.isCompany == true);
            this.essentialObject.companies = this.companies;
          } else {
            this.logService.submitLog(
              0,
              'HomeComponent.fetchAllEntities()',
              'Task Failed',
              {}
            );
          }

          if(this.generalService.hasPermission(this.selectedEntity.key, products.Exit, this.user?.CompanyPermissions, this.user.role == 'Super Admin'))

          this.reloadSelectedEntity = true;
          setTimeout(() => {
            this.selectedEntityKey = this.selectedEntity.key;
            this.reloadSelectedEntity = false;
          }, 10);

          this.essentialObject.selectedEntity = this.selectedEntity;
          let tObject = {};
          this.subscriptions.add(this.essentialDataService.essentialObject.subscribe((s) => {
            tObject = Object.assign({}, s, this.essentialObject);
          }));
          this.essentialDataService.updateObject(tObject);
          console.log(tObject);
        }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        505,
        ex.toString(),
        this.tenant?.key,
        this.tenant?.name,
        'NOT YET INTIATED',
        'NOT YET INTIATED',
        this.user?.firstName +
        ' ' +
        this.user?.lastName +
        ' (' +
        this.user?.email +
        ')'
      );
    }
  }

  evaluateNotifications() {
    try {
      this.notificationMessages = [];

      this.notifications.sort((a, b) =>
        a.notificationDate < b.notificationDate ? 1 : -1
      );

      for (let i = 0; i < this.notifications.length; i++) {
        let notification = this.notifications[i];

        if (
          notification.notiificationType != 'UNIT_CREDIT_MAIN_BALANCE' &&
          notification.notiificationType != 'UNIT_CREDIT_COMPANY_SURVEY_BALANCE'
        ) {
          let message = this.notificationsService.evaluateNotification(
            notification,
            this.language
          );
          this.notificationMessages.push(message);
        } else {
          //this.evaluatePopupNotification(notification);
        }
      }
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        1,
        ex.toString(),
        this.tenant.key,
        this.tenant.name,
        this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name),
        this.user?.firstName +
        ' ' +
        this.user?.lastName +
        ' (' +
        this.user?.email +
        ')'
      );
    }
  }

  evaluatePopupNotification(notification: Notification) {
    try {
      if (notification.notiificationType == 'UNIT_CREDIT_MAIN_BALANCE') {
        if (notification?.units) {
        } else {
          notification.units = 0;
        }
        let data = JSON.parse(
          JSON.stringify({
            title:this.translationPipe.transform('txt_unitsPopupTile',this.language,this.ModuleNames.Shared)  ,
            units: notification.units,
            message:this.translationPipe.transform('txt_units_main_balance',this.language,this.ModuleNames.Shared)  ,
            language: this.language,
            tenant: this.tenant,
            notification: notification,
            company: this.selectedEntity,
            notificationType: notification.notiificationType,
          })
        );
        // this.txt_units_main_balance = this.txt_units_main_balance.replace('#UNITS#', notification.units.toString());
        let confirmationDialog = this.matDialog.open(
          UnitsNotificationDialogComponent,
          {
            data: data,
            autoFocus: false,
          }
        );
        this.notificationsService
          .removeNotification(notification.key, this.user)
          .then((_) => { });
      } else if (
        notification.notiificationType == 'UNIT_CREDIT_COMPANY_SURVEY_BALANCE'
      ) {
        // let companyName = this.extractNameLanguage(this.companies.find(c => c.key == notification.companyKey).name);
        // let surveyName: string = '';
        // if(notification.surveyName == 'EXIT') {
        //   surveyName = this.txt_Exit_Questionnaire;
        // } else if(notification.surveyName == 'ENGAGEMENT') {
        //   surveyName = this.txt_Employee_Engagement_Survey;
        // } else if(notification.surveyName == 'ASSESSMENT') {
        //   surveyName = this.txt_Employee_Assessment_360_Survey;
        // }
        if (notification?.units) {
        } else {
          notification.units = 0;
        }
        // this.txt_units_survey_balance = this.txt_units_main_balance.replace('#UNITS#', notification.units.toString());
        // this.txt_units_survey_balance = this.txt_units_main_balance.replace('#COMPANYNAME#', companyName);
        // this.txt_units_survey_balance = this.txt_units_main_balance.replace('#SURVEYNAME#', surveyName);
        let data = JSON.parse(
          JSON.stringify({
            title:this.translationPipe.transform('txt_unitsPopupTile',this.language,this.ModuleNames.Shared)  ,
            notification: notification,
            units: notification.units,
            message:this.translationPipe.transform('txt_units_survey_balance',this.language,this.ModuleNames.Shared)  ,
            language: this.language,
            tenant: this.tenant,
            companies: this.companies,
            company: this.selectedEntity,
            notificationType: notification.notiificationType,
          })
        );
        let confirmationDialog = this.matDialog.open(
          UnitsNotificationDialogComponent,
          {
            data: data,
            autoFocus: false,
          }
        );
        this.notificationsService
          .removeNotification(notification.key, this.user)
          .then((_) => { });
      }
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        2,
        ex.toString(),
        this.tenant.key,
        this.tenant.name,
        this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name),
        this.user?.firstName +
        ' ' +
        this.user?.lastName +
        ' (' +
        this.user?.email +
        ')'
      );
    }
  }

  removeNotification(key: string) {
    try {
      this.notificationsService
        .removeNotification(key, this.user)
        .then((_) => { });
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(
        3,
        ex.toString(),
        this.tenant.key,
        this.tenant.name,
        this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name),
        this.user?.firstName +
        ' ' +
        this.user?.lastName +
        ' (' +
        this.user?.email +
        ')'
      );
    }
  }
  removeAllNotifications() {
    for (let i = 0; i < this.notificationMessages.length; i++) {
      this.removeNotification(this.notificationMessages[i].key);
    }
  }

  ngOnDestroy() {
    console.log(this.subscriptions);

    this.subscriptions.unsubscribe();
    console.log(this.subscriptions);

  }

  tLanguage;
  tSelectedEntityKey;

  displayChanges() {
    this.displayOptions = false;
    if (this.selectedEntityKey != this.tSelectedEntityKey) {
      this.selectedEntityKey = this.tSelectedEntityKey;
      this.selectEntityChange(this.selectedEntityKey);
      this.router.navigate([this.router.url]);
      let currentModule = (this.router.routerState.snapshot.url).split('/');
      this.router.navigate(['/' + currentModule[1]]);
    }
    if (this.tLanguage != this.language)
      setTimeout(() => {
        this.language = this.tLanguage;
        this.selectedLanguageChange(this.language);
      }, 500);
  }
  searchValue;
  searchList(e, array) {
    this.tempList = array.filter(s => s.name.find(n => n.name.toLowerCase().indexOf(e.toLowerCase()) > -1));
  }

  returnHeaderCheck(innerHTML) {
    if (innerHTML?.length > 0)
      return true;
    else return false;
  }
  // localize(key: string) {
  //   return this.languageService.getUILanguage(key, this.language);
  // }
  scroll(id) {
    setTimeout(() => {
      document.getElementById(id).scrollIntoView({ behavior: 'auto', block: 'center' });
    }, 100);
  }

}
