import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { CommentSentimentDto } from '../../interfaces/ai-models';
import { IAiCommentsApi } from '../../interfaces/ai-comments-api';
import { AI_COMMENTS_SERVICE } from '../main/main.component';

@Component({
  selector: 'comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent extends BaseComponent implements OnInit {
  @Input() sentiments;
  @Input() language = 'EN';
  @Input() module;
  @Input() comment: CommentSentimentDto;
  @Output() addTopic = new EventEmitter();
  constructor(@Inject(AI_COMMENTS_SERVICE) public commentsService: IAiCommentsApi) {
    super();
  }
  ngOnInit(): void {
  }



  returnColor(key){
   return this.commentsService.commentAnswers.find(x=> x.key == key)?.color || null;
  }

  getConfidence(subquestionKey: string, key: string) {
    return this.GeneralService.getQuestionOptions(subquestionKey, key, this.language);
  }
}
