<new-modal [ngClass]="{'RTL': language === 'AR'}" [open]="open">
  <ng-container header>
    <div class="flex gap-2">
      <i class="fas fa-plus w-5 text-xl font-light text-[#7D92B5]"></i>

      <h6 class="text-zen-primarydefault text-lg font-medium">
        {{'New Language'| translate:language:ModuleNames.SurveyX }}
      </h6>
    </div>

    <button class="btn cancel w-10 h-10 rounded bg-zen-littlegray !text-zen-primarylight" (click)="close()">
      <i class="fa-solid fa-xmark"></i>
    </button>
  </ng-container>

  <ng-container body>
    <div class="flex flex-wrap w-[27.8rem]">
      <div class="relative w-full mb-4 bg-white rounded border-[1px] border-zen-paleWhite border-solid">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <i class="far fa-search w-5 text-[#7D92B5]"></i>
        </div>

        <input type="text" [(ngModel)]="searchText"
          class="text-[#7D92B5] text-sm border border-gray-300 rounded-lg block w-full pl-10 p-2.5"
          [placeholder]="'Search'| translate:language:ModuleNames.SurveyX">
      </div>

      <ul class="h11-3rem pb-3 overflow-y-auto text-sm text-gray-700 w-full">
        <div *ngFor="let language of avilableLocales; let i = index">
          <li *ngIf="language.title.toLowerCase().includes(searchText.toLowerCase())">
            <div class="flex flex-row items-center p-2 gap-2 rounded hover:bg-gray-100 text-zen btn" (click)="!isChecked(language.id)? language.selected = !language.selected:''">
              <i [ngClass]="language.selected?' text-exp fa-solid fa-square-check':'text-zen-lite text-opacity-50 fa-regular fa-square'"
                class="text-xl "></i>
              {{language.title}}
            </div>
          </li>
        </div>
      </ul>

      <div class="bottom-0 w-full h-4"
        style="background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(74, 95, 130, 0.10) 100%);">
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="flex flex-row justify-between w-full">
      <a class="btn close h-10 py-2 px-4 bg-zen-littlegray rounded !text-zen-primarylight text-sm font-medium flex items-center"
        (click)="close()">
        {{ 'Cancel' | translate: language: ModuleNames.SurveyX }}
      </a>

      <a class="btn exp h-10 py-2 px-4 bg-exp rounded !text-white text-sm font-medium flex items-center"
        (click)="onSubmit()">
        {{'Add Language'| translate:language:ModuleNames.SurveyX }}
      </a>
    </div>
  </ng-container>
</new-modal>
