<div class="flex flex-col gap-2 w-full"  (focus)="defaultPick()" tabindex="0">
    <h5 *ngIf="label" class="text-xs text-zen font-medium">{{label}} <i *ngIf="required" class="fa-regular fa-asterisk text-3xs transform -translate-y-1"></i></h5>
    <div class="flex flex-row gap-2 w-full">
        <div [ngClass]="invalid && !selected?'border-zen-red':''" (click)="handleSelection(option)"
            *ngFor="let option of options"
            class="flex flex-row items-center gap-2 cursor-pointer w-1/2 text-sm rounded text-zen border border-zen border-opacity-25 px-3 h-9 bg-white relative group">
            <i [ngClass]="selected && selected == option[key]?'fa-solid fa-circle-o text-'+color:'fa-light fa-circle'"></i>
            <ng-container *ngIf="!optionTemplate">{{option.name}}</ng-container>
            <ng-container *ngTemplateOutlet="optionTemplate; context:{$implicit: option}">
            </ng-container>
            <div  *ngIf="selected && selected == option[key]" class="flex flex-row absolute -top-1  -left-1 -right-1 justify-end h-0">
                <i (click)="clear($event)" class="fal fa-times text-xxs btn w-3 h-3 text-center rounded-full bg-zen-red bg-opacity-20 text-zen-red leading-3 opacity-0 group-hover:opacity-100"></i>
            </div>
        </div>
    </div>
</div>

<div *ngIf="invalid && !selected"  class="alert alert-danger transform translate-y-1 h-0">
    <validation-required [language]="language" ></validation-required>
</div>