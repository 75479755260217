export enum ModuleName {
    Engagement = "Engagement",
    Happiness = "Happiness",
    Exit = "Exit",
    Assessment = "Assessment",
    Assessment180 = "Assessment180",
    Common = "Common",
    Shared = "Shared",
    Psychometric = "Psychometric",
    Surveys = "Surveys",
    Onboarding = "Onboarding",
    Wellbeing = "Wellbeing",
    Tooltips = "Tooltips",
    SurveyX = "Survey-X",
    Circle = "Circle",
}
