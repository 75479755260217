import { Component, Input, OnInit } from '@angular/core';
import { SxBaseComponent } from '../../sx-base/sx-base.component';


@Component({
  selector: 'native-sx-filters',
  templateUrl: './native-sx-filters.component.html',
  styleUrls: ['./native-sx-filters.component.scss']
})
export class NativeSxFiltersComponent extends SxBaseComponent implements OnInit {
  @Input() language;
  @Input() groupedFilters;
  constructor() {
    super();
  }

  ngOnInit(): void {
  }
  anyExistsValues(category) {
    if (category.items.filter(f => f.values.length > 0).length > 0)
      return true;
    return false;
  }
}
