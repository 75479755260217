import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'zen-input-v2',
  templateUrl: './zen-new-input.component.html',
  styleUrls: ['./zen-new-input.component.scss']
})
export class ZenInputV2Component {

  constructor(@Self() public ngControl:NgControl) {
    this.ngControl.valueAccessor = this;
  }
  @Input() label: any;
  @Input() language: any = "EN";
  @Input() type: any = 'text';
  writeValue(obj: any): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}
