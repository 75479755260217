import { PieData } from './pie-data';

export class DashletData {
    categories: string[] = [];
    pie: PieData[] = [];
    bar: any = {};
    radar: any;
    scatter: any;
    groupedChart: boolean;
    drillDown: any;
    Result: string;
}
