import type { CreateSurveyTemplateDto, SurveyTemplateDto, SurveyTemplateLookupDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SurveyTemplatesService {
  apiName = 'Default';

  create = (input: CreateSurveyTemplateDto) =>
    this.restService.request<any, SurveyTemplateDto>({
      method: 'POST',
      url: '/api/exit/survey-templates',
      body: input,
    },
    { apiName: this.apiName });

  delete = (tenantKey: string, companyKey: string, surveyKey: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/exit/survey-templates/${tenantKey}/${companyKey}/${surveyKey}`,
    },
    { apiName: this.apiName });

  get = (tenantKey: string, companyKey: string, key: string) =>
    this.restService.request<any, SurveyTemplateDto>({
      method: 'GET',
      url: `/api/exit/survey-templates/${tenantKey}/${companyKey}/${key}`,
    },
    { apiName: this.apiName });

  getAll = (tenantKey: string, companyKey: string) =>
    this.restService.request<any, SurveyTemplateDto[]>({
      method: 'GET',
      url: `/api/exit/survey-templates/all/${tenantKey}/${companyKey}`,
    },
    { apiName: this.apiName });

  getLookup = (tenantKey: string, companyKey: string) =>
    this.restService.request<any, SurveyTemplateLookupDto[]>({
      method: 'GET',
      url: `/api/exit/survey-templates/lookup/${tenantKey}/${companyKey}`,
    },
    { apiName: this.apiName });

  update = (tenantKey: string, companyKey: string, input: SurveyTemplateDto) =>
    this.restService.request<any, SurveyTemplateDto>({
      method: 'PUT',
      url: `/api/exit/survey-templates/${tenantKey}/${companyKey}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
