import { Component, OnInit } from '@angular/core';
import { LocalService } from 'src/app/core/services/local.service';
import { fadeUpDown } from 'src/app/shared/animations/allAnimations';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';

@Component({
  selector: 'zen-snackbar-instance',
  templateUrl: './snackbar-instance.component.html',
  styleUrls: ['./snackbar-instance.component.scss'],
  animations: [fadeUpDown]
})
export class SnackbarInstanceComponent implements OnInit {
  message: string = 'success';
  type: string = 'info'
  delay: number;
  show: boolean = false;
  language: string
  ModuleNames = ModuleName
  constructor(private localService: LocalService,) { }

  ngOnInit(): void {
    let localLanguage = this.localService.getJsonValue('language');
    let sessionLanguage = sessionStorage.getItem('language') || sessionStorage.getItem('sessionLanguage');
    if (!localLanguage && !sessionLanguage) {
      this.language = 'EN';
    }
    else if (localLanguage) {
      this.language = localLanguage
    }
    if (sessionLanguage) {
      this.language = sessionLanguage;
    }
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, this.delay);
  }

}
