<div class="flex flex-col items-stretch gap-4">
  <comment-chart [language]="language" [bubble]="currentBubble" (bubbleChange)="onBubbleClick($event)" [module]="module" [sentiments]="chartSentiments" [data]="topicsList" [TourStep]="tourKey?{title:'txt_TopicFilt_926' | translate:language:ModuleNames.Shared,text: 'txt_Clickonth_970' | translate:language:ModuleNames.Shared,order:3,tourKey:tourKey}:undefined"></comment-chart>
  <div class="flex flex-col items-stretch gap-1.5">
    <div class="flex flex-row justify-between items-center rounded-sm h-12 gap-4 bg-white  px-7" *ngIf="currentBubble">
      <div class="flex flex-col w-full items-stretch">
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row items-center gap-2">
            <z-select [(ngModel)]="currentBubble.key" (ngModelChange)="updateFilter('Topic', currentBubble.key)" [options]="topicsList">
              <ng-template #valueTemplate let-option>
                <h5 class="firstUpper">{{option?.name | extract:language}}</h5>
              </ng-template>
            </z-select>
            <i zenButton styles="icon-9 secondary" class="fa-regular fa-times " (click)="resetBubble()"></i>
          </div>
          <div class="flex flex-row justify-end gap-4 items-center h-full" StopPropagation>
            <i *ngIf="currentSentiment" class="fa-regular fa-times text-zen-red w-5 leading-5 btn" (click)="filterSection(null)"></i>
            <span class="text-xxs text-zen-lite font-medium">{{currentBubble.totalMentionPercentage |
              number:'1.1-1'}}%</span>
            <div class="flex flex-row justify-between items-center rounded-sm gap-px overflow-hidden w-44 text-xxs text-zen">

              <ng-container *ngFor="let percentage of percentageColors">
                <h5 [matTooltip]="returnSentimentName(percentage.key) | translate:language:module" *ngIf="currentBubble[percentage.key + 'MentionPercentage']" (click)="filterSection(percentage.key)" class="leading-5 text-center btn" [class]="percentage.color" [ngClass]="{'bg-opacity-30': percentage.key != currentSentiment && currentSentiment}" [style.width.%]="currentBubble[percentage.key + 'MentionPercentage']">
                  {{currentBubble[percentage.key + 'MentionPercentage'] |
                  number:'1.0-0'}}%</h5>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-end items-center rounded-sm h-12" *ngIf="!currentBubble">
      <div class="flex flex-col  items-stretch min-w-input" [TourStep]="tourKey?{title:'txt_Sentiment_431' | translate:language:ModuleNames.Shared,text: 'txt_Clickhere_158' | translate:language:ModuleNames.Shared,order:4,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined">
        <z-select [formControl]="selectedSentiment" [options]="sentiments">
          <ng-template #valueTemplate let-option>
            <h5 class="firstUpper">{{option?.name | translate:language:(option?.module || module)}}</h5>
          </ng-template>
        </z-select>
      </div>
    </div>
    <comments-section [disableClick]="i <= 1" *ngFor="let section of sections let i=index" [section]="section" [isFactor]="isFactor" [isDimension]="isDimension" [language]="language" [currentSentiment]="currentSentiment" [topics]="topicsList" [module]="module" [sentiments]="sentiments" [request]="basicRequest" [tourKey]="i==0?tourKey:undefined">
    </comments-section>
  </div>
</div>

<EmptySection *ngIf="!this.counts?.sectionCount?.length"> </EmptySection>