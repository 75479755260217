import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { TranslationsService } from './app/core/services/translations.service';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => {
    const translationService = ref.injector.get(TranslationsService);
    return translationService.getTranslations();

  })

  .catch(err => console.error(err));
