export enum ExcelUploadColumns {
    EmployeeId = "employeeId",
    Name = "name",
    Email = "email",
    CountryCode = "countryCode",
    PhoneNumber = "phoneNumber",
    Gender = "gender",
    DateOfBirth = "dateOfBirth",
    Nationality = "nationality",
    JoiningDate = "joiningDate",
    SeperationType = "seperationType",
    SeperationDate = "seperationDate",
    DirectManagerId = "directManagerId",
    DirectManagerName = "directManagerName",
    BandLevel = "bandLevel",
    performanceRate = "performanceRate",
    JobGrade = "jobGrade",
    EntityHierarchy = "EntityHierarchy",
    StartDate = "startDate",
    EndDate = "EndDate",
    Reminder1 = "reminder1",
    Reminder2 = "reminder2",
    Reminder3 = "reminder3"
}
