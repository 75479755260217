import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { map } from 'rxjs/operators';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class UnitBalanceService {
  fbFunctionURL: string = '';

  constructor(private db: AngularFireDatabase, private http: HttpClient, private globals: GlobalsService) {
    this.fbFunctionURL = this.globals.fbFunctionURL;

   }


  createCompanyBalance(request) {
    return this.http.post(this.fbFunctionURL + 'createCompaniesBalance', request);
  }

  creditBalanceCheck(request) {
    return this.http.post(this.fbFunctionURL + 'unitBalanceCheck', request);

  }

  fetchSurveys() {
    return this.db.object<any>('/SurveyUnitCount/').snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        )
      );
  }

  updateUnitBalance(unitRequest) {
    return this.db.object('/TenantUnitRequests/' + unitRequest.tenantKey + '/' + unitRequest.key).update(unitRequest);
  }

  fetchTenantCompanyBalance(request) {
    return this.http.post(this.fbFunctionURL + 'tenantCompanyBalance', request);
  }


  requestMoreUnitsFromSuperAdmin(request) {
    return this.http.post(this.fbFunctionURL + 'requestMoreUnitsFromSuperAdmin', request);
  }
  requestMoreUnitsFromTenantAdmin(request) {
    return this.http.post(this.fbFunctionURL + 'requestMoreUnitsFromTenantAdmin', request);
  }


  distributeUnitsToCompany(request) {
    return this.http.post(this.fbFunctionURL + 'distributeUnitsToCompany', request);
  }
}
