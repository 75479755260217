<div #head [hidden]="!head.hasChildNodes()">
  <ng-content select="[head]"></ng-content>
</div>
<div
  *ngIf="!head.hasChildNodes()"
  (click)="logData(button.hasChildNodes())"
  class="flex flex-row items-center justify-between w-full"
>
  <div>
    <h3 [@first] class="text-2xl text-zen font-medium uppercase">
      {{ "txtSelectCriteria" | translate : language : ModuleNames.Shared }}
    </h3>
    <p [@child3] class="text-base text-zen text-opacity-60">
      {{
        "txt_optional_criteria_description"
          | translate : language : ModuleNames.Shared
      }}
    </p>
  </div>
  <div  [TourStep]="tourKey?{title:'txt_Predefine_865' | translate:language:ModuleNames.Shared,text: 'txt_Selectfro_459' | translate:language:ModuleNames.Shared,order:2,tourKey:tourKey,on:(language | language )?'right':'left'}:undefined">
    <a *DemoAccount="'Button'; let isDemo=isDemo" [@child2] (click)="isDemo?'':criteriaAddFunction()" class="btn block cancel rounded leading-12 px-10 text-center">
      {{"txt_new_criteria"| translate:language:ModuleNames.Shared}}</a>
  </div>
</div>

<div class="min-h-screen-40" (click)="logData(survey)">
  <div *ngIf="!loading" class="flex flex-row flex-wrap -mr-2 -ml-2 w-full">
    <div
      *ngFor="let criteria of criteriaList; let x = index"
      class="flex flex-col p-2 w-1/4"
    >
      <div
        class="flex flex-col justify-between gap-9 p-5 border-2 rounded-md relative group"
        [ngClass]="{
          'border-exp': criteriaIsSelected(criteria.key),
          'border-zen opacity-90': !criteriaIsSelected(criteria.key),
          'cursor-pointer': selection
        }"
        (click)="selection ? updateSelectedMetrics(criteria, x) : ''"
      >
        <div class="flex flex-row justify-between items-center">
          <i class="text-zen fal text-3xl fa-sitemap"></i>
          <div class="flex flex-row justify-end gap-1">
            <i
              *ngIf="criteria.isCustom"
              (click)="criteriaAddFunction(criteria); $event.stopPropagation()"
              class="far fa-pen btn cancel w-10 leading-10 text-center rounded opacity-0 group-hover:opacity-100"
            ></i>
            <i
              *ngIf="criteria.isCustom"
              (click)="deleteCriteria(criteria); $event.stopPropagation()"
              class="far fa-trash btn delete w-10 leading-10 text-center rounded opacity-0 group-hover:opacity-100"
            ></i>
            <i
              *ngIf="selection"
              [ngClass]="
                criteriaIsSelected(criteria.key)
                  ? 'far fa-check-circle text-exp'
                  : 'fal fa-circle text-zen'
              "
              class="text-3xl"
            ></i>
          </div>
        </div>
        <div class="flex flex-row justify-between items-center">
          <h4
            class="text-zen text-lg leading-10 truncate"
            [matTooltip]="extractNameLanguage(criteria.name)"
          >
            {{ extractNameLanguage(criteria.name) }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  [@child4]
  class="flex flex-row justify-between bg-zen-blueaccent bg-opacity-5 px-5 rounded items-center h-12 my-3"
  *ngIf="survey"
  [TourStep]="tourKey ? tourRespondednt : undefined"
>
  <div class="flex flex-row text-zen-blueaccent items-center gap-2">
    <z-toggle
      [opposite]="true"
      [(ngModel)]="survey.predefinedMetrics"
      [label]="
        'txtRespondentsFillCriteria' | translate : language : ModuleNames.Shared
      "
    ></z-toggle>
  </div>
  <!-- <i (click)="criteria = false"
    class="fal fa-times  text-zen-blueaccent w-10 leading-10 text-center cursor-pointer"></i> -->
</div>

<z-modal [(open)]="criteriaAdd">
  <ng-container header>
    <div class="flex flex-row gap-2 items-center w-full">
      <i
        class="fal fa-clipboard-list text-white bg-exp shadow-xl rounded-full minw10 h-10 w-10 text-xl text-center leading-10"
      ></i>
      <h4 class="text-2xl font-bold text-zen truncate">
        {{
          edited
            ? extractNameLanguage(newMetric.name)
            : ("txt_new_criteria" | translate : language : ModuleNames.Shared)
        }}
      </h4>
      <!-- <p class="text-sm text-zen">Please reupload the data file after fixing the validation errors.</p> -->
    </div>
  </ng-container>
  <ng-container body *ngIf="criteriaAdd">
    <z-translation-input
      [invalid]="invalid"
      [label]="'txtCriteriaName' | translate : language : ModuleNames.Shared"
      [maxCount]="mdCount"
      [required]="true"
      [language]="language"
      [supportedLanguages]="selectedEntity.ContentLanguages"
      [(ngModel)]="newMetric.name"
    >
    </z-translation-input>
    <!-- <h5 class="is-size-6 uppercase">Criteria Name</h5>
                <input class="custom-input  box mt-2" type="text" placeholder="i.e. Job Grade"> -->

    <h4 class="text-zen uppercase mt-10">
      {{ "txt_answers" | translate : language : ModuleNames.Shared }}
    </h4>
    <h4 class="text-zen opacity-70 text-sm mb-2">
      {{
        "txt_answers_description" | translate : language : ModuleNames.Shared
      }}
    </h4>
    <!-- <input  *ngFor="let answer of answers" class="custom-input box mt-4" type="text" placeholder="Type an answer here..."> -->
    <div
      *ngFor="let option of newMetric.options; let i = index"
      class="w-full flex flex-col"
    >
      <div class="flex flex-row relative w-full pb-6">
        <!-- <div class="flex flex-col w-full" [matTooltip]="localize('txtFieldIsRequired')"
          [matTooltipClass]="'toolTipAlert'"
          [matTooltipDisabled]="extractNameLanguage(option.name) != '' || !vMetricOptions"
          [ngClass]="extractNameLanguage(option.name) == '' && vMetricOptions? ' alertLine' : '' ">
          <translation-input [maxCount]="mdCount" class="w-full" [language]="language"
            [inputName]=""txtQAnswer"| translate:language:ModuleNames.Shared" [translationField]="option.name" [showHeading]="false"
            [supportedLanguages]="this.selectedEntity.ContentLanguages">
          </translation-input>
          <h4 *ngIf="countWord(extractNameLanguage(option.name), mdCount)> 0"
            [ngClass]="countWord(extractNameLanguage(option.name), mdCount)>= mdCount?'text-zen-red': ''"
            class="text-xxs">
            {{countWord(extractNameLanguage(option.name), mdCount)}} {{"txtof"| translate:language:ModuleNames.Shared}} {{mdCount}}</h4>
        </div> -->
        <div class="flex flex-col w-full items-stretch">
          <z-translation-input
            [invalid]="invalid"
            [placeholder]="
              'txtQAnswer' | translate : language : ModuleNames.Shared
            "
            [maxCount]="mdCount"
            [required]="true"
            [language]="language"
            [supportedLanguages]="selectedEntity.ContentLanguages"
            [(ngModel)]="option.name"
          >
          </z-translation-input>
        </div>

        <div class="absolute w-full -top-4 flex h-0 flex-row justify-end">
          <i
            [matTooltip]="
              'btn_delete' | translate : language : ModuleNames.Shared
            "
            (click)="deleteOption(option)"
            class="fal fa-times w-4 h-4 block rounded-full text-center leading-4 bg-zen bg-opacity-10 shadow-sm text-zen-red cursor-pointer text-xs"
          ></i>
        </div>
      </div>
    </div>
    <br />
    <div class="flex flex-row justify-end">
      <a
        (click)="answerIncreaseClick()"
        class="btn cancel rounded-md px-8 py-4 uppercase"
        >{{ "btn_add_option" | translate : language : ModuleNames.Shared }}</a
      >
    </div>
  </ng-container>
  <ng-container footer class="flex w-full flex-row justify-between">
    <a
      (click)="closeModal('criteriaAdd'); modalChange.emit(false)"
      class="btn cancel text-center rounded-md px-8 py-4 uppercase text-lg font-medium"
      >{{ "btn_close" | translate : language : ModuleNames.Shared }}</a
    >
    <a
      (click)="updateMetric(newMetric)"
      class="btn exp rounded-md px-8 py-4 uppercase text-lg font-medium"
      >{{ "btn_save_criteria" | translate : language : ModuleNames.Shared }}</a
    >
  </ng-container>
</z-modal>
