<div class="flex flex-row items-center gap-2 py-1 minh10 w-full">
  <div class="w-8/12">
    <h5 class="text-zen font-normal text-sm">{{name}}
    </h5>
  </div>

  <div class="w-4/12 flex flex-row items-center gap-2">
    <h5 class="text-zen font-normal text-sm w-12 max-w-12">
      {{ score >0 ? (score | number:'1.1-1') + '%': ("N/A") }} 
    </h5>
    <div class="flex flex-row w-full gap-px rounded-l-full rounded-r-full overflow-hidden justify-around">
      <div *ngFor="let answer of answers;let i = index" [style.width.%]="answer.percentage"
        class="flex flex-row justify-center  h-4 btn pointer-events-none chunk" [ngClass]="answer.color">
        {{answer.percentage | number:'1.0-0' }} <div class="w-0 flex flex-row justify-center">
        </div>
      </div>
    </div>
  </div>
</div>
