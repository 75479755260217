import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';
import { environment } from 'src/environments/environment';
import { Model } from 'survey-core';

import { localization } from 'survey-creator-core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { SurveysService } from '@ex/module/survey-x/data-access/services/surveys.service';
import { LanguageFilterService } from '@ex/module/survey-x/data-access/services/language-filter.service';
import { Direction } from '@angular/cdk/bidi';
import { take } from 'rxjs/operators';
import { QuestionnaireDto, QuestionnaireService } from '@ex/survey-x/index';
import { BaseComponent } from '@ex/module/core/domain/base-component';

@Component({
  selector: 'app-survey-start-page',
  templateUrl: './survey-start-page.component.html',
  styleUrls: ['./survey-start-page.component.scss'],
  animations: [ChildrenAnimation]
})
export class SurveyStartPageComponent extends BaseComponent implements AfterViewInit {
  @Input() language;
  @Input() surveyData: QuestionnaireDto | any
  @Input() surveyModel: Model;
  @Output() startSurvey = new EventEmitter();
  questions: number;
  minute: number;
  constructor() { super(); }
  ngAfterViewInit(): void {
    this.questions = this.surveyModel.getAllQuestions().length;

    this.minute = this.surveyData.duration || this.questions * 0.5;
  }
  onStart() {
    this.startSurvey.emit()
  }
  parseMessage(stringfied) {
    return JSON.parse(stringfied)
  }
}
