import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class MiscellaneousService {

  localURL: string = 'http://localhost:5000/exitanalysis-a6325/us-central1/';
  fbFunctionURL: string = '';

  constructor(private db: AngularFireDatabase, private http: HttpClient, private globals: GlobalsService) {
    this.fbFunctionURL = this.globals.fbFunctionURL;

   }

  createRespondentForLandingPage(request) {
    return this.http.post(this.fbFunctionURL + 'createRespondentFromLandingPage', request);
  }
  createHappinessRespondentFromLandingPage(request) {
    return this.http.post(this.fbFunctionURL + 'createHappinessRespondentFromLandingPage', request);
  }

  createAssessmentRespondentFromLandingPage(request: any) {
    return this.http.post(this.fbFunctionURL + 'createHappinessRespondentFromLandingPage', request);
  }

  dynamicTableQuery(url, request) {
    return this.http.post(url, request);
  }
}
