<div [@animation] *ngIf="open" class="fixed z-50 flex flex-row justify-end h-0 left-0  top-0  bottom-0 right-0">
    <div (click)="aClose()" class="drawer fixed zen-inner-container z-50 bottom-0 flex flex-row justify-end" [ngClass]="{'bg-zenMain bg-opacity-30 w-screen left-0':showBackDrop, 'right-0':dir == 'ltr' && !showBackDrop, 'left-0':dir == 'rtl' && !showBackDrop}">
        <div (click)="$event.stopPropagation()"
            class="w-auto min-h-full bg-white overflow-overlay overflow-x-hidden shadow-sm"
            [ngClass]="dir == 'rtl'?'rtl':'ltr'">
            <div class="flex flex-row justify-between items-center px-10 py-8 sticky top-0 bg-white  z-50">
                <ng-content select="[header]"></ng-content>
            </div>
            <div class="flex flex-col justify-start">
                <ng-content select="[body]"></ng-content>
            </div>
            <div class="flex flex-row justify-between items-center px-10 pt-3">
                <ng-content select="[footer]"></ng-content>
            </div>
        </div>
    </div>
</div>