<div class="overflow-scroll">


  <div class="flex flex-row justify-between">
    <div class="flex flex-col  w-5/12">
      <div
        class="text-sm rounded text-zen border bg-white border-zen border-opacity-25 px-3 h-9 gap-2 leading-9 truncate flex flex-row justify-start items-center">
        <div class="flex flex-row w-full items-center gap-2">
          <i [ngClass]="{'rotate-h-45':dir == 'rtl'}" class="fal fa-search text-zen transform"></i>
          <input [placeholder]="options?.searchText"
            class="bg-transparent overflow-hidden h-12 leading-12 text-sm w-full " [formControl]="searchBox"
            type="text">
        </div>
      </div>
    </div>

    <ng-content></ng-content>
  </div>

  <t-table *ngIf="options?.data?.length" [options]="options">
    <ng-template #cellTempate let-element let-column='column' let-colIndex='colIndex'>

      <div>
        <ng-container [ngSwitch]="column.field">
          <ng-container *ngSwitchCase="true">

          </ng-container>
          <ng-container *ngSwitchCase="'email'">
            <h5 class="text-sm text-zen font-normal">
              {{validEmail(element[column.field]) }}
            </h5>
          </ng-container>
          <ng-container *ngSwitchCase="'status'">
            <h4 *ngIf="element[column.field] === 'PENDING'" class=" whitespace-nowrap ">
              <i class="far opacity-50 text-sm  fa-circle    "></i>
              <span class="px-1 font-normal text-sm">
                {{'txt_not_started'|
                translate:language:ModuleNames.Wellbeing}}
              </span>
            </h4>
            <h4 *ngIf="element[column.field] === 'STARTED'" class="uppercase  whitespace-nowrap text-orange ">
              <i class="far fa-adjust text-sm  text-orange"></i>
              <span class="px-1 font-normal text-sm">
                {{'txt_in_progress'|
                translate:language:ModuleNames.Wellbeing}}
              </span>
            </h4>
            <h4 *ngIf="element[column.field] === 'COMPLETED'" class="uppercase  whitespace-nowrap text-zen-red  ">
              <i class="far fa-circle-check text-sm  text-zen-green"></i>
              <span class="px-1 font-normal text-sm text-zen-green">
                {{'txt_completed'|
                translate:language:ModuleNames.Wellbeing}}
              </span>
            </h4>
            <h4 *ngIf="element[column.field] === 'DELETED'" class="uppercase  whitespace-nowrap text-zen-red  ">
              <i class="far fa-circle  text-sm  text-zen-red"></i>
              <span class="px-1 font-normal text-sm text-zen-red">
                {{'txt_deleted'|
                translate:language:ModuleNames.Wellbeing}}
              </span>
            </h4>
          </ng-container>


          <ng-container *ngSwitchCase="'action'">
            <i [matMenuTriggerFor]="menu2"
              class="fal openPath font-medium fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full  btn text-center"></i>
            <mat-menu #menu2="matMenu" class="custom-table-menu">
              <div class="flex flex-col items-stretch px-2">
                <a (click)="editRespondent(element)" color=warn
                  class="text-sm font-medium btn cancel leading-10 rounded px-5 w-full">
                  <i class="fal fa-pen mx-1"></i>
                  {{'btn_edit'|
                  translate:language:ModuleNames.Wellbeing}}
                </a>
              </div>
            </mat-menu>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <div class="text-sm text-zen font-normal">
              <ng-container *ngIf="column?.type == 'criteria' else default">
                {{ element[column.field] == ''? ("txtNA"|
                translate:language:ModuleNames.Wellbeing) : extractCustomProps(element,column.field)}}
              </ng-container>


              <ng-template #default class="text-sm text-zen font-normal">
                {{ element[column.field] == ''? ("txtNA"|
                translate:language:ModuleNames.Wellbeing) : element[column.field]}}
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </div>




    </ng-template>
  </t-table>

  <t-paginator *ngIf="options?.data?.length" [language]="language" [options]="options" [pageSize]="pageSize"
    [totalRecords]="data?.totalCount || 0" (pageChanged)="onPageChange($event)"></t-paginator>
</div>

<app-respondent-edit [entities]="allEntitiesSnapshot" [selectedEntity]="selectedEntity" [respondent]="currentRespondent"
  (updateModal)="updateModalFunc($event)" [(respondentEdit)]="respondentEdit" *ngIf="respondentEdit">
</app-respondent-edit>

<app-respondent-edit [entities]="allEntitiesSnapshot" [selectedEntity]="surveySelectedEntity" [respondent]="currentRespondent" (updateModal)="updateModalFunc($event)" [@inOutAnimation] [(respondentEdit)]="respondentEdit" *ngIf="respondentEdit">
</app-respondent-edit>


