import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MasterSnackBarComponent } from '../../shared/master-snack-bar/master-snack-bar.component';

@Injectable({
	providedIn: 'root'
})
export class SnackBarService {

	constructor(
		public snackbar: MatSnackBar,
		private zone: NgZone,
	) { }


	openSnackBar(message: string, action: string) {
		this.zone.run(() => {
			this.snackbar.open(message, action, {
				duration: 2000,
			});
		});
	}
	simpleSnackBar(message: string, action, language) {
		this.zone.run(() => {
			this.snackbar.openFromComponent(MasterSnackBarComponent, {
				duration: 3000,
				data: { message: message, action: action, language: language }
			});
		});
	}


}
