<div class=" flex flex-row box-border bg-white items-center w-full h-110 pt-2 px-10 justify-between">
    <div class="flex flex-col w-max">
        <h5 [@first] class="text-zen font-medium text-2xl">{{"Basic Information"| translate:language:ModuleNames.SurveyX}}
        </h5>
    </div>
    <div class="flex flex-col w-max items-center text-center  ">
        <button class="flex bg-exp  hover:bg-exp text-white text-sm font-medium py-2 px-4 rounded" (click)="onNext()">

          {{"Create Form"| translate:language:ModuleNames.SurveyX}}
          <i class="fa-regular fa-chevron-right w-4 pt-3px fill-current" fill="currentColor"></i>
      </button>
    </div>
</div>

<div class="flex flex-row pt-20 w-full justify-center">
    <div class="flex flex-row flex-wrap w-660  bg-white items-center py-8 gap-5 justify-center rounded-md ">
      <div *ngIf="validateFields" class="alert">
        <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
        <strong>{{"Please Fill All Mandatory Fields."| translate:language:ModuleNames.SurveyX}}</strong>
    </div>
        <div class="flex flex-row px-10 pt-5 w-full">
            <z-input-custom [required]="true" [language]="language" [label]="'Title'| translate:language:ModuleNames.SurveyX" [placeholder]="'Type a Title for the form...'| translate:language:ModuleNames.SurveyX" class="w-full" [(ngModel)]="title">

            </z-input-custom>
        </div>

        <div class="flex flex-row px-10  w-full justify-center">
          <div class="flex flex-row w-full rounded h-11 bg-exp bg-opacity-10">
              <div class="flex flex-row pt-3 px-2 h-full w-full">
                  <z-toggle [color]="'exp'" [language]="language" [label]="'Time Frame'| translate:language:ModuleNames.SurveyX" class="w-full" [(ngModel)]="timeFrame">

                  </z-toggle>
              </div>
          </div>
      </div>


      <div *ngIf="timeFrame" class="flex flex-row px-10 gap-5 w-full">
        <div class="flex flex-col  w-72">
            <z-datepicker [direction]="direction" [min]="minimumDate" [max]="selectedEndDate" [color]="'exp'" [required]="true" [label]="'Form Start Date'| translate:language:ModuleNames.SurveyX" [language]="language"  [placeholder]="'Start Date'| translate:language:ModuleNames.SurveyX" [(ngModel)]="selectedStartDate" (ngModelChange)="onFormStartDateChanged($event)">

            </z-datepicker>
        </div>
        <div class="flex flex-col w-72">
            <z-datepicker [direction]="direction" [min]="selectedStartDate" [color]="'exp'" [label]="'Form End Date'| translate:language:ModuleNames.SurveyX" [language]="language"  [placeholder]="'End Date'| translate:language:ModuleNames.SurveyX" [(ngModel)]="selectedEndDate" (ngModelChange)="onFormEndDateChanged($event)">

            </z-datepicker>
        </div>
    </div>






        <div class="flex flex-row px-10  w-full justify-center">
            <div class="flex flex-row w-full rounded h-11 bg-exp bg-opacity-10">
                <div class="flex flex-row pt-3 px-2 h-full w-full">
                    <z-toggle [color]="'exp'" [language]="language" [label]="'Activate Weights / Scoring for this form'| translate:language:ModuleNames.SurveyX" class="w-full" [(ngModel)]="activateWeights">

                    </z-toggle>
                </div>
            </div>
        </div>

        <div class="flex flex-row px-10 w-full justify-center">
            <div class="flex flex-row w-full rounded h-11 bg-exp bg-opacity-10">
                <div class="flex flex-row pt-3 px-2 h-full w-full">
                    <z-toggle [color]="'exp'" [language]="language" [label]="'Welcome Message'| translate:language:ModuleNames.SurveyX" class="w-full" [(ngModel)]="isWelcomeMessage">

                    </z-toggle>
                </div>
            </div>
        </div>

        <div *ngIf="isWelcomeMessage" class="flex flex-row px-10 w-full justify-center">
            <div class="flex flex-row w-full rounded  bg-exp bg-opacity-10">
                <div class="flex flex-row py-1 px-2 w-full">
                    <z-translation-input-textarea class="pre w-full" [placeholder]="'Please Enter Your Welcome Message'" [language]="language" [supportedLanguages]="supportedLanguages" [(ngModel)]="welcomeMessageText">
                        <ng-template #valueTemplate let-value>

                            {{value?.name | translate:language:ModuleNames.SurveyX }}

                        </ng-template>

                        <ng-template #optionTemplate let-option>

                            {{option?.name | translate:language:ModuleNames.SurveyX}}

                        </ng-template>

                    </z-translation-input-textarea>
                </div>
            </div>
        </div>

        <div class="flex flex-row px-10 w-full justify-center">
            <div class="flex flex-row w-full rounded h-11 bg-exp bg-opacity-10">
                <div class="flex flex-row pt-3  px-2 h-full w-full">
                    <z-toggle [disable]="true" [color]="'exp'" [language]="language" [label]="'Respondent Information Page'| translate:language:ModuleNames.SurveyX" class="w-full" [(ngModel)]="respondentInformationPage">

                    </z-toggle>
                </div>
            </div>
        </div>

        <div *ngIf="respondentInformationPage" class="px-10 text-zen text-sm font-normal">

            <div class="flex flex-row">
                <h3 class="font-semibold">Please Choose The Information You Need:</h3>
            </div>

            <div class="grid grid-cols-4 gap-2 py-3">

                <mat-checkbox [(ngModel)]="isName">Name</mat-checkbox>
                <mat-checkbox disabled [(ngModel)]="isEmail">Email</mat-checkbox>
                <mat-checkbox [(ngModel)]="isJobTitle">Job Title</mat-checkbox>
                <mat-checkbox [(ngModel)]="isPhoneNumber">Phone Number</mat-checkbox>
                <mat-checkbox [(ngModel)]="isCompanyName">Company Name</mat-checkbox>
                <mat-checkbox [(ngModel)]="isCompanySize">Company Size</mat-checkbox>
                <mat-checkbox [(ngModel)]="isCompanyIndustry">Company Industry</mat-checkbox>
                <mat-checkbox [(ngModel)]="isLocation">Location</mat-checkbox>
                <mat-checkbox [(ngModel)]="isGender">Gender</mat-checkbox>
                <mat-checkbox [(ngModel)]="isBirthDate">Birth Date</mat-checkbox>
                <mat-checkbox [(ngModel)]="isNationality">Nationality</mat-checkbox>
                <mat-checkbox [(ngModel)]="isReligion">Religion</mat-checkbox>
                <mat-checkbox [(ngModel)]="isMaritalStatus">Marital Status</mat-checkbox>
                <mat-checkbox [(ngModel)]="isJobType">Job Type</mat-checkbox>
                <mat-checkbox [(ngModel)]="isContractType">Contract Type</mat-checkbox>
            </div>
            <div *ngIf="isPhoneNumber || isLocation" class="flex flex-row">

                <z-toggle [color]="'exp'" [language]="language" [label]="'GCC Countries Only'| translate:language:ModuleNames.SurveyX" class="w-full" [(ngModel)]="isGccCountries">

                </z-toggle>

            </div>
        </div>

        <div class="flex flex-row px-10 gap-5 w-full">
            <div class="flex flex-col  w-72">
                <z-select [color]="'exp'" [required]="true" [label]="'Type'| translate:language:ModuleNames.SurveyX" [language]="language" [options]="typesList" [placeholder]="'Type'| translate:language:ModuleNames.SurveyX" [(ngModel)]="selectedType" (ngModelChange)="onTypeChanged($event)">
                    <ng-template #valueTemplate let-value>

                        {{value?.name }}

                    </ng-template>

                    <ng-template #optionTemplate let-option>

                        {{option?.name }}

                    </ng-template>
                </z-select>
            </div>
            <div class="flex flex-col w-72">
                <z-select [color]="'exp'" [label]="'Category (optional)'| translate:language:ModuleNames.SurveyX" [language]="language" [options]="categoriesList" [placeholder]="'Category'| translate:language:ModuleNames.SurveyX" [(ngModel)]="selectedCategory" (ngModelChange)="onCategoryChanged($event)">
                    <ng-template #valueTemplate let-value>

                        {{value?.name }}

                    </ng-template>

                    <ng-template #optionTemplate let-option>

                        {{option?.name }}

                    </ng-template>
                </z-select>
            </div>
        </div>


        <div class="flex flex-row px-10 w-full">
            <z-input [language]="language" [label]="'Description (Optional)'| translate:language:ModuleNames.SurveyX" [placeholder]="'Type a Description for your form...'| translate:language:ModuleNames.SurveyX" class="w-full" [(ngModel)]="description">

            </z-input>
        </div>

        <!-- <div class="flex flex-row w-full px-10">
          <a class="btn w-full bg-exp text-center  hover:bg-exp text-white text-sm font-medium py-2 px-4 rounded" (click)="editEmailTemplate()">
            {{"Edit Email Template"| translate:language:ModuleNames.SurveyX}}
          </a>
      </div> -->



    </div>
</div>
