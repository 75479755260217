<z-modal [open]="true" autoWidth="true" (click)="logData(questions)">
  <ng-container header>
    <div class="flex flex-row items-start gap-2 justify-start pb-3">
      <i class="far fa-file-export text-exp text-lg"></i>
      <h4 class="text-zen text-2xl">{{ 'btnExport' | translate:language:ModuleNames.SurveyX }}
      </h4>
    </div>
  </ng-container>
  <ng-container body>
    <div class="flex flex-row gap-5 -mb-10 -mt-10 max-h-screen-90 h-screen overflow-hidden max-w-7xl">
      <div class="flex flex-col items-stretch">
        <div class="max-h-screen-90 block bg-white innerListScroll w-export portrait" id="pdf">

          <!-- First Page -->

          <div class="innerPage break-before bg-white pt-20 px-10 ">
            <div class="flex justify-between">
              <h4 class="text-zen-primarylight font-semibold text-sm opacity-50 uppercase">
                {{"CONFIDENTIAL"| translate:language:ModuleNames.SurveyX}}
              </h4>

              <img class="h-5 w-32 flex-shrink-0" src="assets/img/logo.png" alt="">
            </div>

            <div class="flex flex-col gap-4 pt-32">
              <div>
                <h1 class="text-zen-primarydefault text-5xl	font-semibold uppercase">
                  {{headerTitle | translate:language:ModuleNames.SurveyX}}

                </h1>

                <h1 class="text-zen-primarydefault text-5xl  uppercase">
                  {{"txt_Report" | translate:language:ModuleNames.SurveyX}}
                </h1>
              </div>

              <p class="text-zen-primarylight text-sm">
                {{respondentName}}
              </p>
            </div>

            <div class="pt-16">
              <h4 class="text-zen-primarylight text-lg">{{"For:"| translate:language:ModuleNames.SurveyX}}</h4>

              <h4 class="text-zen-primarydefault font-semibold text-2xl">
                {{essentialObject?.selectedEntity?.name | extract:language}}
              </h4>

              <h4 class="text-zen-primarydefault text-lg">
                {{date | date: 'dd/MM/yyyy'}}
              </h4>
            </div>

          </div>

          <!-- First Page -->
          <ng-container *ngFor="let section of sections; let s = index">
            <div class="innerPage break-before px-10 pt-20">
              <div class=" flex flex-col  items-stretch">
                <div class="flex flex-row text-zen-primarydefault pb-10">
                  <div class="text-lg-custom font-normal tracking-tight">{{"txtSection" |
                    translate:language:ModuleNames.SurveyX}} {{s+1}} : <span class="font-semibold">{{section.name|
                      sjsTranslate:language}}</span>
                  </div>
                </div>


                <div *ngFor="let question of filterQuestionOfSection(section.key); let q = index;"
                  [ngClass]="q?'pt-20':'pt-0'" class="block pb-10 avoidBreak">
                  <div class="inline-block text-sm-custom text-zen-primarydefault font-medium gap-2 pb-5"
                    (click)="logData(question)">
                    {{"Q" | translate:language:ModuleNames.SurveyX}}{{getPreviousSectionCount(section.key - 1 ,
                    sections)
                    + q + 1}}
                    {{question.name | sjsTranslate : language}}
                  </div>

                  <!-- -----------------------------------------Choices-------------------------------------------------------------------------------------------------- -->



                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------DROP DOWN (SINGLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'dropdown'">
                    <div class="inline-block rank-item" [ngClass]="{'break-before mt-20':n % 15 == 0 && n}"
                      *ngFor="let c of question.choices; let n = index">
                      <!-- <i  [ngClass]="!isChoiceDisabled(question.key, c.key)?' text-exp':''" class="fa-solid fa-square text-xl align-middle"></i> -->
                      <i [ngClass]="!isChoiceDisabled(question.key, c.key)?' text-exp fa-circle-dot':' fa-circle'"
                        class="fa-regular text-xl align-middle"></i>
                      {{c.value}}
                    </div>
                  </div>

                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------DROP DOWN (MULTIPLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'tagbox'">
                    <!-- <mat-checkbox-group aria-label="Select an option" class="custom-checkbox-group">
                      <div class="grid grid-cols-1 gap-4">
                        <mat-checkbox *ngFor="let c of question.choices"
                          [checked]="!isChoiceDisabled(question.key, c.key)" [disabled]="true" [value]="c.key"
                          class="no-margin">{{c.value}}</mat-checkbox>
                      </div>
                    </mat-checkbox-group> -->

                    <div class="inline-block rank-item" [ngClass]="{'break-before mt-20':n % 15 == 0 && n}"
                      *ngFor="let c of question.choices; let n = index">
                      <i [ngClass]="!isChoiceDisabled(question.key, c.key)?' text-exp fa-square-check':'fa-square'"
                        class="fa-regular text-xl align-middle"></i>
                      <!-- <i
                      [ngClass]="!isChoiceDisabled(question.key, c.key)?' text-exp fa-circle-dot':' fa-circle'"
                      class="fa-regular text-xl align-middle"></i> -->
                      {{c.value}}
                    </div>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MULTIPLE CHOICE (MULTIPLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'checkbox'">
                    <!-- <mat-checkbox-group aria-label="Select an option" class="custom-checkbox-group">
                      <div class="grid grid-cols-1 gap-4">
                        <mat-checkbox *ngFor="let c of question.choices"
                          [checked]="!isChoiceDisabled(question.key, c.key)" [disabled]="true" [value]="c.key"
                          class="no-margin"><span class="whitespace-pre-line">{{c.value}}</span></mat-checkbox>
                      </div>
                    </mat-checkbox-group> -->

                    <div class="inline-block rank-item" [ngClass]="{'break-before mt-20':n % 15 == 0 && n}"
                      *ngFor="let c of question.choices; let n = index">
                      <i [ngClass]="!isChoiceDisabled(question.key, c.key)?' text-exp fa-square-check':'fa-square'"
                        class="fa-regular text-xl align-middle"></i>
                      <!-- <i
                      [ngClass]="!isChoiceDisabled(question.key, c.key)?' text-exp fa-circle-dot':' fa-circle'"
                      class="fa-regular text-xl align-middle"></i> -->
                      {{c.value}}
                    </div>

                  </div>

                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MULTIPLE CHOICE (SINGLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'radiogroup'">
                    <div class="inline-block rank-item" [ngClass]="{'break-before mt-20':n % 15 == 0 && n}"
                      *ngFor="let c of question.choices; let n = index">
                      <!-- <i  [ngClass]="!isChoiceDisabled(question.key, c.key)?' text-exp':''" class="fa-solid fa-square text-xl align-middle"></i> -->
                      <i [ngClass]="!isChoiceDisabled(question.key, c.key)?' text-exp fa-circle-dot':' fa-circle'"
                        class="fa-regular text-xl align-middle"></i>
                      {{c.value}}
                    </div>

                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------UNKNOWN------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'Single Selection (2 in a row)'">
                    <mat-radio-group aria-label="Select an option" class="custom-radio-group flex space-x-4">
                      <mat-radio-button *ngFor="let c of question.choices"
                        [checked]="isChoiceDisabled(question.key, c.key)" [disabled]="true" [value]="c.key"
                        class="flex-1 flex-wrap"><span class="whitespace-pre-line">{{c.value}}</span></mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------TEXT INPUT------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'datepicker'">
                    <z-datepicker [language]="language" [disabled]="true"
                      [ngModel]="getTextAreaVal(question.key)"></z-datepicker>
                    <!-- {{getTextAreaVal(question.key)}} -->
                  </div>
                  <div *ngIf="question.type == 'text'">
                    <ng-container *ngIf="question.dateType != 'range'">
                      <div class="w-full flex flex-row">
                        <h5
                          class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20  w-full rounded-lg px-4 py-2 break-words">
                          {{getTextAreaVal(question.key)}}</h5>
                      </div>
                    </ng-container>



                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------DATE RANGE------------------------------------------------------------------------------------------------------------ -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <ng-container *ngIf="question.dateType == 'range'">
                      <div class="flex space-x-4">

                        <div class="w-full flex flex-row">
                          <div class="relative">
                            <i
                              class="fa-regular fa-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-zen-primarydefault text-base"></i>
                          </div>
                          <h5
                            class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20  w-full rounded-lg px-4 py-2 break-words">
                            {{getDate(question.key,'index1')}}</h5>
                        </div>

                        <div class="w-full flex flex-row">
                          <div class="relative">
                            <i
                              class="fa-regular fa-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-zen-primarydefault text-base"></i>
                          </div>
                          <h5
                            class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20  w-full rounded-lg px-4 py-2 break-words">
                            {{getTextAreaVal(question.key)}}</h5>
                        </div>
                      </div>

                    </ng-container>



                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------UNKNOWN------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <ng-container *ngIf="question.name == 'single line input Date - time'">
                      <div class="w-full flex flex-row">
                        <div class="relative">
                          <i
                            class="fa-regular fa-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-zen-primarydefault text-base"></i>
                        </div>

                        <h5
                          class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20  w-full rounded-lg px-4 py-2 break-words">
                          {{getTextAreaVal(question.key)|date:'medium'}}</h5>
                      </div>
                    </ng-container>



                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------PHONE NUMBER------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <ng-container *ngIf="question.name == 'Phone number'">
                      <div class="w-full flex flex-row">
                        <h5
                          class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20  w-full rounded-lg px-4 py-2 break-words">
                          {{getTextAreaVal(question.key)}}</h5>
                      </div>
                    </ng-container>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------RANKING------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'ranking'">
                    <div class="w-full flex flex-col">
                      <div class="rank-item" *ngFor="let c of getSortedChoices(question); let i = index"
                        [ngClass]="!isChoiceDisabled(question?.key, c?.key) ? 'rank-active' : ''">
                        <span
                          class="whl26 inline-block bg-white font-medium text-zen-primarylight text-sm text-center rounded-full mr-2 align-middle">
                          {{i+1}}
                        </span>
                        <span class="whitespace-pre-line">{{c?.value}}</span>
                      </div>

                    </div>
                  </div>




                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------RATING------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'rating'">
                    <div class="w-full flex flex-row">
                      <div class="flex space-x-4 w-full">


                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------STARS RATING------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container *ngIf="question.rateType === 'stars'">
                          <div
                            class="rate_box flex flex-row w-full rounded-md px-4 h-14 justify-center items-center gap-2">
                            <div *ngFor="let rateValue of question.rateValues; let i = index"
                              class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                              [ngClass]="setActiveRate(rateValue, question.key, question)">

                              <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                                [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-star text-3xl text-exp' :
                    'fa-light fa-star text-3xl text-zen-neutral'"></i>
                            </div>

                            <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                              <span
                                class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                            </div>
                          </div>
                        </ng-container>


                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------HEARTS RAITNG------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container *ngIf="question.rateType === 'fa-heart'">
                          <div
                            class="rate_box flex flex-row w-full rounded-md px-4 h-14 justify-center items-center gap-2">
                            <div *ngFor="let rateValue of question.rateValues; let i = index"
                              class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                              [ngClass]="setActiveRate(rateValue, question.key, question)">

                              <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                                [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-heart text-3xl text-exp' :
                  'fa-light fa-heart text-3xl text-zen-neutral'"></i>
                            </div>

                            <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                              <span
                                class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                            </div>
                          </div>
                        </ng-container>




                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------THUMBS UP RATING------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container *ngIf="question.rateType === 'fa-thumbs-up'">
                          <div
                            class="rate_box flex flex-row w-full rounded-md px-4 h-14 justify-center items-center gap-2">
                            <div *ngFor="let rateValue of question.rateValues; let i = index"
                              class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                              [ngClass]="setActiveRate(rateValue, question.key, question)">

                              <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                                [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-thumbs-up text-3xl text-exp' :
        'fa-light fa-thumbs-up text-3xl text-zen-neutral'"></i>
                            </div>

                            <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                              <span
                                class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                            </div>
                          </div>
                        </ng-container>




                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------SMILEYS RATING------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container *ngIf="question.rateType === 'smileys'">
                          <div
                            class="rate_box flex flex-row w-full rounded-md px-4 h-14 justify-center items-center gap-2">
                            <div *ngFor="let rateValue of question.rateValues; let i = index"
                              class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                              [ngClass]="setActiveRate(rateValue, question.key, question)">

                              <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                                [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-face-smile text-3xl text-exp' :
                  'fa-light fa-face-smile text-3xl text-zen-neutral'"></i>
                            </div>

                            <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                              <span
                                class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                            </div>
                          </div>
                        </ng-container>


                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------NPS AND ENPS RATING------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->

                        <ng-container *ngIf="question.rateType == 'enps' || question.rateType == 'nps'">
                          <div class="rate_box enps_clr_scale flex flex-col w-full rounded-md px-2 py-2 items-center">
                            <div class="flex flex-row w-full">
                              <div class="gap-2  grid-cols-11 flex flex-row max-w-[600px] m-auto all_colrs">
                                <div class="flex flex-col items-center"
                                  *ngFor="let groupedValues of groupedSection(question.rateValues)">
                                  <div class="flex flex-row gap-2">
                                    <div
                                      [style.backgroundColor]="rateValue.value == isNumber(returnQuestionAnswer(question.key))?groupedValues.color: (groupedValues.color + '22')"
                                      *ngFor="let rateValue of groupedValues.rates; let i = index"
                                      [ngClass]="rateValue.value == isNumber(returnQuestionAnswer(question.key))?'text-white':'text-zen-primarydefault'"
                                      class="flex flex-1 flex-row flex-wrap rounded-md justify-center items-center px-2 py-2 font-medium text-xs ratinglabel bg-zen-littlegray w-12 h-12">
                                      {{ rateValue.value }}
                                    </div>
                                  </div>
                                  <span
                                    class="text-zen-primarydefault font-normal text-xs text-center">{{groupedValues.name
                                    | translate:language:ModuleNames.SurveyX}}</span>
                                </div>
                              </div>
                            </div>

                          </div>
                        </ng-container>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------RATING SCALE WITH LABELS AND COLORS------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container
                          *ngIf="question.type == 'rating' && !question.rateType && question.rateType == null && question.ratingLabels "
                          [ngClass]="filterLabels(question)">
                          <div class="w-full flex justify-center padding-custom rounded gap-1">
                            <div class="py-2 gap-2 px-4 flex">
                              <div *ngFor="let group of filteredGroups; let i = index"
                                class="flex flex-col rounded gap-2 self-stretch items-center ">
                                <div class="flex justify-center items-center gap-2 rounded"
                                  [id]="'firstDiv_' + group.groupText">
                                  <div class="flex flex-row items-start gap-2">
                                    <div *ngFor="let rateValue of group.items; let i = index"
                                      class="flex flex-row rounded justify-center items-center w-12 h-12"
                                      [ngClass]="setActiveRate(rateValue, question.key, question)" [ngStyle]="{'background-color': isNumber(rateValue.value) == isNumber(activeRating)  ? '#0082C6' :
                            getColor(rateValue.value, question)? getColor(rateValue.value, question):'#EFF1F6'}">
                                      <h5
                                        [ngClass]="isNumber(rateValue.value) == isNumber(activeRating) ? 'text-white' : 'text-zen-primarylight'"
                                        class="text-sm text-center">
                                        {{ rateValue.value }}
                                      </h5>
                                    </div>

                                  </div>
                                </div>
                                <div class="flex flex-row gap-1 justify-center" [id]="'secondDiv_' + group.groupText">
                                  <h5 class="label-gray whitespace-normal">{{group.groupText}}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------RATING SCALE WITHOUT LABELS OR COLORS------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container
                          *ngIf="question.type == 'rating' && !question.rateType && question.rateType == null && !question.ratingLabels ">
                          <div class="flex flex-col w-full rounded padding-custom gap-2 self-stretch items-center ">
                            <div class="py-2 gap-2 px-4 flex">
                              <div class="flex justify-center items-center gap-2 rounded">
                                <div class="flex flex-row items-start gap-2">
                                  <div *ngFor="let rateValue of question.rateValues; let i = index"
                                    class="flex flex-row rounded justify-center items-center w-12 h-12"
                                    [ngClass]="setActiveRate(rateValue, question.key, question)"
                                    [ngClass]="isNumber(rateValue.value) == isNumber(activeRating)  ? 'bg-exp' : 'bg-zen-littlegray'"
                                    [ngStyle]="{'background-color':getColor(rateValue.value, question) }">
                                    <h5
                                      [ngClass]="isNumber(rateValue.value) == isNumber(activeRating) ? 'text-white' : 'text-zen-primarylight'"
                                      class="text-sm text-center">
                                      {{ rateValue.value }}
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              <div *ngFor="let rateLabel of question.ratingLabels; let i = index"
                                class="flex flex-row gap-1">
                                <h5 class="label-gray whitespace-normal"> {{rateLabel.text}}</h5>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                      </div>
                    </div>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------BOOLEAN------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'boolean'">
                    <mat-radio-group aria-label="Select an option" class="custom-radio-group flex space-x-4">
                      <mat-radio-button class="gap-1 items-center" *ngFor="let c of question.choices"
                        [checked]="isValueChecked(question.key, c.key)"
                        [disabled]="!isValueChecked(question.key, c.key)" [value]="c.key">
                        <i *ngIf="c.key == 'True'" class="fa-regular fa-thumbs-up"></i>
                        <i *ngIf="c.key == 'False'" class="fa-regular fa-thumbs-down"></i>
                        {{c.value}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>



                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------FILE UPLOAD------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'file'">
                    <div class="w-full flex flex-row">
                      <div class="rate_box flex flex-row w-full rounded-md px-2 py-2 items-center">
                        <div class="flex-1">
                          <i class="fa-regular fa-image text-base mr-2 ml-2"></i>
                          {{getFileDetails(question.key)?.name}}
                        </div>

                        <div class="flex-auto justify-end flex pr-2">
                          <button (click)="downloadImage(getFileDetails(question.key)?.content, 'image.png')"><i
                              class="fa-regular fa-download text-[#7D92B5]"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MULTIPLE TEXT------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'multipletext'">
                    <div class="w-full flex flex-row space-x-4">
                      <h5 *ngFor="let c of question.items"
                        class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20  w-full rounded-lg px-4 py-2 break-words">
                        {{getValue(question.key, c.name)}}</h5>
                    </div>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------LONG TEXT------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'comment'">
                    <h5
                      class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20  w-full rounded-lg px-4 py-2 break-words min-h-20">
                      {{getTextAreaVal(question?.key)[0] | slice:0:3000}} -</h5>

                    <div *ngIf="getTextAreaVal(question.key)[0]?.length > 3000">
                      <span class="w-full h-20 block break-before"></span>

                      <h5
                      class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20  w-full rounded-lg px-4 py-2 break-words min-h-20">
                      {{getTextAreaVal(question?.key)[0] | slice:3000:getTextAreaVal(question?.key)[0]?.length}}</h5>
                    </div>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------IMAGE PICKER (ICON CHOICE)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'imagepicker'">
                    <ng-container *ngIf="question.isIconChoice">
                      <div class="w-full flex flex-row">
                        <div class="flex space-x-4 w-full">
                          <div *ngFor="let choice of question.choices; let i = index"
                            class="flex flex-1 flex-col flex-wrap justify-center items-center text-xs font-normal emogy-box"
                            [ngClass]="setActiveIcon(choice, question.key) ? 'active-emgy' : ''">
                            <div
                              class="flex flex-1 justify-center items-center mb-2 w-full rounded-md px-2 py-2 bg-white emj-box-inside">
                              <i class="fa-light fa-{{choice.value}} text-3xl"></i>
                            </div>
                            {{choice.key}}
                          </div>
                        </div>
                      </div>
                    </ng-container>


                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------IMAGE PICKER(IMAGE CHOICE)------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->

                    <ng-container *ngIf="!question.isIconChoice && !question.multiSelect">
                      <div class="block relative">
                        <ng-container *ngFor="let choice of question.choices; let i = index">
                          <span class="w-full h-20 block break-before" *ngIf="i % 8 == 0 && i"></span>
                          <div class="inline-block w-1/2 text-zen-primarydefault font-medium text-xs bg-white mb-2"
                            [ngClass]="setActiveImage(choice.key, question.key) ? 'active-ImgSel' : ''">

                            <div
                              class="flex flex-row items-center gap-2 text-sm text-zen-primarylight font-normal innerT">
                              <img [src]="choice.imageLink"
                                class="rounded-md p-[0.1rem] max-h-52 h-52 w-64 object-contain">
                              <i
                                [class]="setActiveImage(choice.key, question.key) ? 'fa-regular fa-circle-dot text-2xl mr-2 text-zen-paleWhite' : 'fa-light fa-circle text-2xl mr-2 text-zen-paleWhite'"></i>
                              {{ choice.key }}
                            </div>
                          </div>
                        </ng-container>

                      </div>
                    </ng-container>

                    <ng-container *ngIf="!question.isIconChoice && question.multiSelect">
                      <div class="block relative">
                        <ng-container *ngFor="let choice of question.choices; let i = index">
                          <span class="w-full h-20 block break-before" *ngIf="i % 8 == 0 && i"></span>
                          <div class="inline-block w-1/2 text-zen-primarydefault font-medium text-xs bg-white mb-2"
                            [ngClass]="setActiveImages(choice.key, question.key) ? 'active-ImgSel' : ''">

                            <div class="flex items-center gap-2 text-sm text-zen-primarylight font-normal innerT">
                              <img [src]="choice.imageLink"
                                class="rounded-md p-[0.1rem] max-h-52 h-52 object-cover w-64">
                              <i
                                [class]="setActiveImages(choice.key, question.key) ? 'fa-solid fa-square-check text-2xl mr-2 text-zen-paleWhite' : 'fa-light fa-square text-2xl mr-2 text-zen-paleWhite'"></i>
                              {{ choice.key }}
                            </div>
                          </div>
                        </ng-container>

                      </div>

                    </ng-container>

                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MATRIX (SINGLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'matrix' && !question.isMultiSelect">
                    <div class="w-full flex flex-row">
                      <table class="bordered w-full table-matrix">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4 text-center"
                              *ngFor="let column of question.columns">
                              {{ column.text | sjsTranslate:language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of question.rows">
                            <td class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4">{{ row.text |
                              sjsTranslate:language}}
                            </td>
                            <td class="text-center px-2 py-4" *ngFor="let column of question.columns">
                              <i
                                [ngClass]="validateAns(row.key,question.key, column.key)? 'fa-regular fa-circle-dot' : 'fa-regular fa-circle'"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MATRIX (MULTIPLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'matrix' && question.isMultiSelect">
                    <div class="w-full flex flex-row">
                      <table class="bordered w-full table-matrix">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4 text-center"
                              *ngFor="let column of question.columns">
                              {{ column.text | sjsTranslate:language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of question.rows">
                            <td class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4">{{ row.text |
                              sjsTranslate:language}}
                            </td>
                            <td class="text-center px-2 py-4" *ngFor="let column of question.columns">
                              <i class="fa-regular fa-square"
                                [ngClass]="validateAns(row.key,question.key, column.key)"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>




                  <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                </div>



              </div>
            </div>
          </ng-container>



          <div class="header w-full px-10 flex flex-row items-start justify-between">
            <h5 class="text-3xs text-zen text-opacity-60"> {{headerTitle | translate:language:ModuleNames.SurveyX}}</h5>
            <h5 class="text-3xs text-zen "><span class="text-opacity-60">{{
                "txtPreparedFor"|translate:language:ModuleNames.SurveyX }}
              </span> <span class="text-zen font-medium">{{essentialObject?.selectedEntity?.name |
                extract:language}}</span> </h5>
          </div>
          <div class="footer w-full px-10 flex flex-row items-end justify-between">
            <div class="flex flex-col gap-1">
              <p class="text-xxs text-zen text-opacity-50">{{ "txtPoweredby" |
                translate:language:ModuleNames.SurveyX }}</p>
              <img class="h-8 w-auto object-cover" src="assets/img/logo.png" alt="">
            </div>
            <div class="flex flex-col items-end gap-1">
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtGenerated"|translate:language:ModuleNames.SurveyX }}: {{date | date:
                'dd/MM/yyyy'}}
              </h5>
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtAllRights"|translate:language:ModuleNames.SurveyX }} © {{date | date: 'yyyy'}}
                -
                ex.zenithr.com</h5>

            </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
  <ng-container footer>
    <a (click)="openChange.emit(false)" class="btn cancel leading-12 rounded text-sm text-center px-5">{{"btn_cancel"|
      translate:language:ModuleNames.SurveyX}}</a>

    <a (click)="exportDraft(language,true); closeModal()"
      class="btn exp leading-12 rounded text-sm text-center px-5">{{"btnExport"|
      translate:language:ModuleNames.SurveyX}}</a>
  </ng-container>
</z-modal>
