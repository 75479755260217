import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debug } from 'console';
import { ModuleName } from '../../enums/ModuleName';


@Component({
  selector: 't-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input()
  dir: string;

  @Input()
  pageSize: number = 5;

  @Input()
  totalRecords: number = 0;
  @Input()
  language: string = 'EN';

  @Input('page') set page(page){
    this.pageIndex = page;
  }



  pageIndex: number = 0;
  pageNumbers: [];

  ModuleNames = ModuleName

  @Input()
  options: {
    colors: {
      button: 'exp',
      default: 'bg-white',
      STARTED: 'bg-orange',
      COMPLETED: 'bg-zen-green',
      DELETED: 'bg-zen-red',
      PENDING: 'bg-zen',
      CLOSED: 'bg-zen-green',
      DRAFT: 'bg-zen',
      LIVE: 'bg-zen-blueaccent',
    },
  };

  @Output()
  pageChanged = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {



  }

  goToChange(number) {
    this.pageIndex = number;
    this.pageChanged.emit(number);
  }

  get totalPages() {
    if (this.totalRecords == 0)
      return [];

    const pages = Math.ceil(this.totalRecords / this.pageSize);

    return Array(pages).fill(4).map((x, i) => i + 1);
  }

}
