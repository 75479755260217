import type { CommentCountDto, CommentsAnalysisRequest, CommentsTopicsDto, QuestionCommentFilterPagedDto, QuestionCommentPagedDto, QuestionCommentPagedRequest, QuestionCommentPagedRequest2, SectionSentimentDto, UpdateSentimentDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AnalysisFilterDto } from '../analysis/models';

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  apiName = 'Default';

  getCommentsAIAnalysis = (request: CommentsAnalysisRequest) =>
    this.restService.request<any, SectionSentimentDto[]>({
      method: 'POST',
      url: '/api/wellbeing/comments-analysis/comments-ai-analysis',
      body: request,
    },
    { apiName: this.apiName });

  getCommentsTopicsList = (surveyKey: string, Filters: AnalysisFilterDto[]) =>
    this.restService.request<any, CommentsTopicsDto[]>({
      method: 'POST',
      url: `/api/wellbeing/comments-analysis/comments-ai-topics/${surveyKey}`,
      body: Filters,
    },
    { apiName: this.apiName });

  getListAsycByRequest = (request: QuestionCommentPagedRequest) =>
    this.restService.request<any, QuestionCommentPagedDto>({
      method: 'POST',
      url: '/api/wellbeing/comments-analysis/all',
      body: request,
    },
    { apiName: this.apiName });

  getPaginatedCommentsList = (request: QuestionCommentPagedRequest2) =>
    this.restService.request<any, QuestionCommentFilterPagedDto>({
      method: 'POST',
      url: '/api/wellbeing/comments-analysis/comments-ai-filter',
      body: request,
    },
    { apiName: this.apiName });

  getSectionsCommentCountBySurveyKeyAndFilters = (surveyKey: string, filters: AnalysisFilterDto[]) =>
    this.restService.request<any, CommentCountDto>({
      method: 'POST',
      url: `/api/wellbeing/comments-analysis/count-by-section/${surveyKey}`,
      body: filters,
    },
    { apiName: this.apiName });

  updateCommentsAI = (request: UpdateSentimentDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/wellbeing/comments-analysis/comments-update-sentiment',
      body: request,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
