/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { FormFilter } from '../models/FormFilter';
import type { FormTable } from '../models/FormTable';
import type { SendBulkRemindersDto } from '../models/SendBulkRemindersDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class FormService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public getAllForms(
requestBody?: FormFilter,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-table/get-all-form',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param tenantId 
     * @param companyId 
     * @param pageNumber 
     * @param pageSize 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public getAllFormsByTenant(
tenantId: string,
companyId: string,
pageNumber: number,
pageSize: number,
requestBody?: FormFilter,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-table/get-all-forms-by-tenant/{tenantId}/{companyId}/{pageNumber}/{pageSize}',
            path: {
                'tenantId': tenantId,
                'companyId': companyId,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public getFormWithoutResponses(
id: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-table/get-form-no-responses/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public getForm(
id: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-table/get-form/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public addForm(
requestBody?: FormTable,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-table/add-form',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public cloneForm(
id: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-table/clone-form/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public relaunchForm(
id: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-table/relaunch-form/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns FormTable Success
     * @throws ApiError
     */
    public updateForm(
id: string,
requestBody?: FormTable,
): Observable<FormTable> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/form-table/update-form/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns FormTable Success
     * @throws ApiError
     */
    public updateFormFromInitial(
id: string,
requestBody?: FormTable,
): Observable<FormTable> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/form-table/update-form-initial/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns FormTable Success
     * @throws ApiError
     */
    public updateFormStatus(
id: string,
requestBody?: FormTable,
): Observable<FormTable> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/form-table/update-form-status/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @param formTitle 
     * @returns FormTable Success
     * @throws ApiError
     */
    public updateFormTitle(
id: string,
formTitle: string,
): Observable<FormTable> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/form-table/update-form-title/{id}/{formTitle}',
            path: {
                'id': id,
                'formTitle': formTitle,
            },
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteForm(
id: string,
): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/form-table/delete-form/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param categoryId 
     * @param tenantId 
     * @param companyId 
     * @returns any Success
     * @throws ApiError
     */
    public getAllFormsByCategory(
categoryId: string,
tenantId: string,
companyId: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-table/get-all-forms-by-category/{categoryId}/{tenantId}/{companyId}',
            path: {
                'categoryId': categoryId,
                'tenantId': tenantId,
                'companyId': companyId,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public share(
id: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-table/share/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public closeSurvey(
id: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/form-table/close/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public sendBulkReminders(
requestBody?: SendBulkRemindersDto,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-table/send-bulk-reminders',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
