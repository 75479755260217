<nav class="flex flex-col justify-end items-center gap-4 box-border w-full sticky shadow-sm bg-white py-6 pe-10">
    <div class="flex flex-wrap items-center justify-between mx-auto px-4 w-full">
        <div class="flex w-max gap-4 items-center">
            <img [src]="survey?.logo || '/assets/img/svg/logo.svg'" class="customlogo-sizing">
            <div class="divider sm:block hidden "></div>
            <h5 class="text-zen text-sm font-medium my-auto sm:block hidden ">{{survey?.title | sjsTranslate:language}}</h5>
        </div>

        <div class="flex md:order-2">
            <div class="flex items-center gap-2">
                <button [ZMenuTriggerFor]="menu"
                    class="btn flex text-sm font-medium py-4 px-4 rounded previous-step-button bg-zen-littlegray text-zenMain">
                    <i class="fa-regular fa-language"></i>
                </button>

                <z-menu #menu>
                        <a MenuItem class="py-2 flex gap-3" *ngFor="let language of surveyLanguages"
                            (click)="onSelectLanguage(language.locale)">
                            <span class="icon-label">{{language.title}}</span>
                        </a>
                </z-menu>
            </div>
        </div>
    </div>
</nav>
