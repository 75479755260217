<ng-container *ngIf="translationInput">
  <div  class="flex flex-row justify-between items-center w-full bg-white rounded-md px-5 pt-1 h-15">
    <mat-form-field class="w-full -mb-3 overflow-hidden" appearance="none">
      <mat-label class="-mb-2" *ngIf="showHeading">{{ txtInputName }}</mat-label>
      <input class="transform -translate-y-0.5" [ngClass]="{' -translate-y-2':!showHeading}" [required]='required' matInput [disabled]='disabled || countWord(translationInput?.name,
      maxCount) > maxCount' [maxlength]="maxCount" [placeholder]="(placeholder?'txtDescription' : 'txtInputName')|translate:language:ModuleNames.Shared"
        #translation="ngModel" type="text" [(ngModel)]="translationInput.name" (keyup)="translationChanged()">
        <mat-error *ngIf="translation.invalid && (translation.dirty || translation.touched)">
          <div class="text-red-600 text-xs" *ngIf="translation.errors.required">
            {{ 'txtFieldIsRequired'|translate:language:ModuleNames.Shared }}
          </div>
          <div class="text-red-600 text-xs" *ngIf="translation.errors.minlength ">
            {{"txt_validation_min"| translate:language:ModuleNames.Shared }}
          </div>
          <div class="text-red-600 text-xs" *ngIf=" translation.errors.maxlength">
            {{"txt_validation_max"| translate:language:ModuleNames.Shared }}
          </div>
        </mat-error>
    </mat-form-field>
  
  
      <fa-icon *ngIf="supportedLanguages.length > 1 && showTranslation"
        [matMenuTriggerFor]="menu" class="btn cancel h-10 w-10 leading-10 text-center rounded mb-1" [icon]="faGlobe"></fa-icon>
    <mat-menu #menu="matMenu">
      <div (click)="$event.stopPropagation();" class="px-4 py-2">
        <ng-container *ngFor="let field of translationField">
          <div *ngIf="field.key != language" class="container py-3">
            <h5 class="font-normal mb-1">{{('txtTranslation'|translate:language:ModuleNames.Shared) + ' ' + getLanguage(field.key) }}</h5>
            <input class="language py-2 px-2" type="text" [placeholder]="'txtDescription'| translate:language:ModuleNames.Shared" [(ngModel)]="field.name">
          </div>
        </ng-container>
      </div>
    </mat-menu>
  </div>

</ng-container>
