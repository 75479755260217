import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Entity } from 'src/app/core/data-models/Entity';
import { CDKPortalComponent, DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
import { ExitBaseComponent } from '../../ui/exit-base/exit-base.component';
import animations from '../../utils/animations';
import { biDirectionalState300 } from 'src/app/shared/animations/allAnimations';
import { SurveyTemplatesService } from '@ex/exit/survey-templates';
import { CreateUpdateLeaverDto, LeaverDto, LeaversService } from '@ex/exit/leavers';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { ExitUnitDeductionDto, ExitUnitService } from '../../data-access/services/exit-unit.service';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface NewLeaverModalData {
  tenantKey: string,
  companyKey: string,
  surveyKey: string,
  language: string,
  essentialObject: EssentialObject,
  selectedEntity: Entity,
  leaver?: any,
  allEntities: Entity[],
  manualClose?: boolean
}

export enum Mode {
  Manual = "manual",
  Excel = "Excel",
  None = ""
}

@Component({
  selector: 'new-leaver',
  templateUrl: './new-leaver.component.html',
  styleUrls: ['./new-leaver.component.scss'],
  animations: [biDirectionalState300]
})
export class NewLeaverComponent extends ExitBaseComponent implements OnInit, CDKPortalComponent {

  constructor(@Inject(DATA_TOKEN) private data: NewLeaverModalData
    , private surveyTemplateService: SurveyTemplatesService
    , private leaversService: LeaversService, private responseDialogService: ResponseDialogService, private exitUnitService: ExitUnitService) {
    super()
    this.initialize();
  }

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  mode = Mode.Manual
  modes = Mode

  step = 0
  tab = 0

  language: string
  selectedEntity: Entity
  leaver: LeaverDto = {} as LeaverDto
  tenantKey: string
  companyKey: string
  surveyKey: string

  allEntities = []

  isEdit = false

  open = true;

  templates = [];

  ngOnInit(): void {
    this.fetchTemplates()
  }

  fetchTemplates() {
    this.LoaderService.displayLoader();
    this.surveyTemplateService.getLookup(this.tenantKey, this.companyKey).subscribe(
      templates => {
        let predefinedTemplate = templates.find(x=> x.isDefault);
        this.templates = templates.filter(x=> !x.isDefault);
        this.templates.unshift(predefinedTemplate);
        
        this.LoaderService.hideLoader()
      }
    )
  }

  private initialize() {
    this.language = this.data.language;
    this.selectedEntity = this.data.selectedEntity;
    this.tenantKey = this.data.tenantKey
    this.companyKey = this.data.companyKey
    this.surveyKey = this.data.surveyKey
    this.allEntities = this.data.allEntities


    if (this.data.leaver?.key) {
      this.isEdit = true;
      this.leaver = this.data.leaver;
      this.mode = this.modes.Manual
      this.tab = 1
      this.step = 1
    } else {
      this.leaver.surveyKey = this.surveyKey;
      this.leaver.companyKey = this.companyKey;
      this.leaver.tenantKey = this.tenantKey;
    }
  }

  closeModal() {
    this.close.emit(true);
  }

  openManualForm() {
    this.mode = Mode.Manual
    this.tab = 1
    this.step = 1
  }

  openExcelUploadForm() {
    this.mode = Mode.Excel
    this.tab = 1
    this.step = 1
  }

  reset() {
    this.mode = Mode.None
    this.tab = 0
    this.step = 0
  }

  closeHandler(closeAll?) {
    if (!this.leaver?.key)
      this.tab = 0;
    this.step = 0;

    if (closeAll) {
      this.tab = 0;
      this.open = false;
      this.mode = Mode.None;

      this.close.emit(true);
    }
  }


  // leaverUpdated(leaver) {

  //   let subscription;

  //   this.LoaderService.displayLoader();

  //   if (leaver.key) {
  //     subscription = this.leaversService.update(leaver.key, leaver as CreateUpdateLeaverDto).subscribe(
  //       response => {
  //         this.SnackbarService.success(leaver.name + ' ' + this.localize("txt_updated_success", this.language, this.ModuleNames.Exit));
  //         this.results.emit(response);

  //         // this.LoaderService.hideLoader();
  //       }
  //     )
  //   } else {
  //     subscription = this.leaversService.create(leaver as CreateUpdateLeaverDto).subscribe(
  //       response => {
  //         this.SnackbarService.success(leaver.name + ' ' + this.localize("txt_SuccessfullyAdded", this.language, this.ModuleNames.Exit));
  //         this.results.emit(response);

  //         // this.LoaderService.hideLoader();
  //       }
  //     )
  //   }

  //   this.addSubscription(subscription);
  // }


  async leaverUpdated(leaver) {

    let subscription;

    if (leaver.key) {
      this.LoaderService.displayLoader();
      subscription = this.leaversService.update(leaver.key, leaver as CreateUpdateLeaverDto).subscribe(
        response => {
          this.SnackbarService.success(leaver.name + ' ' + this.localize("txt_updated_success", this.language, this.ModuleNames.Exit));
          this.results.emit(response);

        }
      )
    } else {
      let confirmationResult: boolean = true;
      let message = this.localize("txt_unit_deduction", this.language, this.ModuleNames.Onboarding);
      let subject = this.localize("txt_allocation_confirmation", this.language, this.ModuleNames.Onboarding);
      let actionButtonText = this.localize("txt_okay_action", this.language, this.ModuleNames.Onboarding);
      let cancelButtonText = this.localize("btn_cancel", this.language, this.ModuleNames.Onboarding);

      message = message.replace('#UNITS#', 1);
      confirmationResult = await this.responseDialogService.confirmAllocation(message, subject, actionButtonText, cancelButtonText);

      if (confirmationResult) {
        this.LoaderService.displayLoader();
        const request: any = {
          noOfRespondees: 1,
          userKey: this.data.essentialObject.user.key,
          tenantKey: this.selectedEntity.tenantKey,
          companyKey: this.selectedEntity.key,
        };
        subscription = this.exitUnitService.deduct(request).pipe(switchMap((response: any) => {
          if (response.response == "ERROR") {

            return of(response);
          }

          return this.leaversService.create(leaver as CreateUpdateLeaverDto);

        })).subscribe((response: any) => {
          if (response?.response == "ERROR") {
            this.SnackbarService.warning(this.localize(response?.message, this.language, this.ModuleNames.Onboarding));
            this.LoaderService.hideLoader();
            return;
          }
          this.results.emit(response);
        });
      }



    }

    this.addSubscription(subscription);
  }

  bulkUpdateCompleted(event) {
    this.results.emit(event);
  }



}
