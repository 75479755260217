import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AppInjector } from 'src/app/app.module';
import { AnalysisFilterKeys } from '../domain/analysis-filter-keys';
import { GeneralService } from './general.service';
import { ExtractPipe } from 'src/app/shared/pipes/extract.pipe';
import { EssentialObject, EssentialObjectService } from './essential-object.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';

export const KEY_RESOLVER_TOKEN = new InjectionToken<IKeyResolver>("KEY_RESOLVER_TOKEN", {
  providedIn: 'root',
  factory: () => { return AppInjector.get(NullResolver) },
});

@Injectable({
  providedIn: 'root'
})
export class KeyResolverService {

  keyResolver: IKeyResolver;

  /**
   * Default Resolvers
   */
  resolvers: IKeyResolver[] = [
    AppInjector.get(GenderResolver),
    AppInjector.get(AgeGroupResolver),
    AppInjector.get(TenureGroupResolver),
    AppInjector.get(GenerationGroupResolver),
    AppInjector.get(NationalityResolver),
    AppInjector.get(SurveyStatusResolver),
    AppInjector.get(EntityGroupResolver),
    AppInjector.get(ExtraCriteriaResolver),
    AppInjector.get(BandLevelResolver),
    AppInjector.get(JobGradeResolver),
    AppInjector.get(PerformanceRatingResolver),
  ];

  constructor(@Inject(KEY_RESOLVER_TOKEN) resolvers) {

    if (resolvers && Array.isArray(resolvers)) {
      resolvers.forEach(resolver => this.resolvers.push(resolver));
    }

  }

  setKeyResolver(resolver: IKeyResolver) {
    this.keyResolver = resolver;
  }

  resolve(key: string, language: string, options?: any) {
    return this.keyResolver.resolve(key, language, options);
  }

  getResolver(name: string) {
    return this.resolvers.find(x => x.name == name);
  }

}

export interface IKeyResolver {
  name: string;
  resolve(key: string, language: string, options?: any): string
}

@Injectable({
  providedIn: 'root',
})
export class NullResolver implements IKeyResolver {
  name: string = "null";
  resolve(key: string, language: string): string {
    return null;
  }

}

@Injectable({
  providedIn: 'root',
})
export class GenderResolver implements IKeyResolver {

  name: string = AnalysisFilterKeys.Gender;

  /**
   *
   */
  constructor(private generalService: GeneralService, private extractPipe: ExtractPipe) { }

  resolve(key: string, language: string): string {
    let translation = this.generalService.genders_new.find(x => x.key == key);

    return this.extractPipe.transform(translation.name, language) || key;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AgeGroupResolver implements IKeyResolver {

  name: string = AnalysisFilterKeys.AgeGroups;

  /**
   *
   */
  constructor(private generalService: GeneralService, private extractPipe: ExtractPipe) { }

  resolve(key: string, language: string): string {
    let translation = this.generalService.ageGroups_new.find(x => x.key == key);

    return this.extractPipe.transform(translation?.name || key, language);
  }
}

@Injectable({
  providedIn: 'root',
})
export class TenureGroupResolver implements IKeyResolver {

  name: string = AnalysisFilterKeys.TenureGroups;

  /**
   *
   */
  constructor(private generalService: GeneralService, private extractPipe: ExtractPipe) { }

  resolve(key: string, language: string): string {
    let translation = this.generalService.tenureGroups_new.find(x => x.key == key);

    return this.extractPipe.transform(translation?.name, language) || key;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GenerationGroupResolver implements IKeyResolver {

  name: string = AnalysisFilterKeys.Generation;

  /**
   *
   */
  constructor(private generalService: GeneralService, private extractPipe: ExtractPipe) { }

  resolve(key: string, language: string): string {
    let translation = this.generalService.generations_new.find(x => x.key == key);

    return this.extractPipe.transform(translation.name, language);
  }
}

@Injectable({
  providedIn: 'root',
})
export class NationalityResolver implements IKeyResolver {

  name: string = AnalysisFilterKeys.Nationality;

  /**
   *
   */
  constructor(private generalService: GeneralService, private extractPipe: ExtractPipe) { }

  resolve(key: string, language: string): string {
    let translation = this.generalService.countries_new.find(x => x.key == key);

    return this.extractPipe.transform(translation.name, language);
  }
}

@Injectable({
  providedIn: 'root',
})
export class JobGradeResolver implements IKeyResolver {

  name: string = AnalysisFilterKeys.JobGrade;
  private translations = [];

  /**
   *
   */
  constructor(private essentialObjectService: EssentialObjectService, private extractPipe: ExtractPipe) {
    this.essentialObjectService.essentialObject
      .pipe(
        filter(obj => obj?.selectedEntity?.key != null),
        distinctUntilChanged((a, b) => a == b, obj => obj.selectedEntity.key)
      )
      .subscribe(obj => {
        console.log("in resolver", obj);
        if (obj?.selectedEntity?.key) {
          this.translations = obj?.selectedEntity?.JobGrades;
        }

      })
  }

  resolve(key: string, language: string): string {

    let translation = this.translations?.find(x => x.key == key);

    return translation?.name ? this.extractPipe.transform(translation?.name || key, language) : key;
  }
}

@Injectable({
  providedIn: 'root',
})
export class BandLevelResolver implements IKeyResolver {

  name: string = AnalysisFilterKeys.BandLevel;
  private translations = [];

  /**
   *
   */
  constructor(private essentialObjectService: EssentialObjectService, private extractPipe: ExtractPipe) {
    this.essentialObjectService.essentialObject
      .pipe(
        filter(obj => obj?.selectedEntity?.key != null),
        distinctUntilChanged((a, b) => a == b, obj => obj.selectedEntity.key)
      )
      .subscribe((obj: EssentialObject) => {
        console.log("in resolver", obj);
        if (obj?.selectedEntity?.key) {
          this.translations = obj?.selectedEntity?.BandLevels;
        }

      })
  }

  resolve(key: string, language: string): string {

    let translation = this.translations?.find(x => x.key == key);

    return translation?.name ? this.extractPipe.transform(translation?.name || key, language) : key;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PerformanceRatingResolver implements IKeyResolver {

  name: string = AnalysisFilterKeys.PerformanceRating;
  private translations = [];

  /**
   *
   */
  constructor(private essentialObjectService: EssentialObjectService, private extractPipe: ExtractPipe) {
    this.essentialObjectService.essentialObject
      .pipe(
        filter(obj => obj?.selectedEntity?.key != null),
        distinctUntilChanged((a, b) => a == b, obj => obj.selectedEntity.key)
      )
      .subscribe((obj: EssentialObject) => {
        if (obj?.selectedEntity?.key) {
          this.translations = obj?.selectedEntity?.PerformanceRates;
        }
      })
  }

  resolve(key: string, language: string): string {

    let translation = this.translations?.find(x => x.key == key);

    return translation?.name ? this.extractPipe.transform(translation?.name || key, language) : translation?.value? translation.value : key;
  }
}


@Injectable({
  providedIn: 'root',
})
export class ExtraCriteriaResolver implements IKeyResolver {
  name: string = "EC";

  constructor(private generalService: GeneralService, private extractPipe: ExtractPipe) {

  }

  resolve(key: string, language: string, options?: any): string {
    var extraCriteria = this.generalService.extraCriterias.find(x => x.key == options);

    if (!extraCriteria) {
      return "";
    }

    var translation = extraCriteria.options.find(x => x.key == key);

    return this.extractPipe.transform(translation?.name || key, language);
  }

}


@Injectable({
  providedIn: 'root',
})
export class EntityGroupResolver implements IKeyResolver {

  name: string = 'Entity';
  private translations = [];

  constructor(private essentialObjectService: EssentialObjectService, private extractPipe: ExtractPipe) {

    this.essentialObjectService.essentialObject
      .pipe(
        filter(obj => obj?.selectedEntity?.key != null),
        distinctUntilChanged((a, b) => a == b, obj => obj.selectedEntity.key)
      )
      .subscribe(obj => {

        if (obj?.selectedEntity?.key) {
          this.translations = obj?.allEntities;
        }


      })

  }

  resolve(key: string, language: string): string {
    let entityName = "";
    let parentName = "";

    let translation = this.translations.find(x => x.key == key);
    let parent = this.translations.find(x => x.key == translation?.parentKey);

    entityName = translation ? this.extractPipe.transform(translation.name, language) : key;
    parentName = parent ? this.extractPipe.transform(parent.name, language) : "";

    return parentName == "" ? `${entityName}` : `${parentName} - ${entityName}`;
  }

}

@Injectable({
  providedIn: 'root',
})
export class FixedGroupingOptionsResolver implements IKeyResolver {

  constructor(private generalService: GeneralService, private extractPipe: ExtractPipe) {

  }

  name: string = "FixedGroupingOptions";
  resolve(key: string, language: string, options?: any): string {
    var translation = this.generalService.fixedGroupingOptions.find(x => x.key == key);

    return this.extractPipe.transform(translation?.name || key, language);
  }

}

@Injectable({
  providedIn: 'root',
})
export class OnboardingExperienceScaleResolver implements IKeyResolver {
  name: string = "ExpScale";

  constructor(private translatePipe: TranslatePipe) { }

  resolve(key: string, language: string, options?: any): string {
    return this.translatePipe.transform(key, language, options);
  }

}

@Injectable({
  providedIn: 'root',
})
export class SurveyStatusResolver implements IKeyResolver {
  name: string = "SurveyStatus";

  constructor(private translatePipe: TranslatePipe) { }

  resolve(key: string, language: string, options?: any): string {

    if (key == null)
      return;

    const txtKey = `txt_${key.toUpperCase()}`

    return this.translatePipe.transform(txtKey, language, options);
  }

}


@Injectable({
  providedIn: 'root',
})
export class JobRoleResolver implements IKeyResolver {
  name: string = AnalysisFilterKeys.JobRole;

  constructor(private translatePipe: TranslatePipe) { }

  resolve(key: string, language: string, options?: any): string {

    if (key == null)
      return;

    const txtKey = `txt_${key.toUpperCase()}`

    return this.translatePipe.transform(txtKey, language, options);
  }

}

export { AnalysisFilterKeys };
/**
 *
    Problem Statement: Resolve the keys to their equivalent localized text based
    on the current selected system language

    key types that need to be resolved to their equivalent localized text
    1. Gender
    2. Performance Ratings
    3. Age Groups
    4. Tenure Groups
    5. Generation Groups
    6. Entity Hierarchy
    7. Nationality
    8. ..


 */
