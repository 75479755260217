import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'export-chart-vertical',
  templateUrl: './export-chart-vertical.component.html',
  styleUrls: ['./export-chart-vertical.component.scss']
})
export class ExportChartVerticalComponent implements OnInit {

  constructor() { }
  @Input('categoryTemplate') categoryTemplate: TemplateRef<any>;
  @Input('categoriesTemplate') categoriesTemplate: TemplateRef<any>;
  @Input() breakIndex: number = 30;
  @Input() chart: any;
  @Input() data;
  @Input() language;
  @Input() rows: number = 1;
  ngOnInit(): void {
  }

  breakCondition(index) {
    let indexCalc = this.breakIndex * (this.rows / 2);
    return (index % indexCalc == 0 && index >= indexCalc) ? true : false;
  }
  fullWidthChartCheck(categories) {
    if (categories.find(x => x.length > 15))
      return true;
    else return false;
  }
}
