import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@ex/module/core/domain/base-component';

@Component({
  selector: 'app-demo-section',
  templateUrl: './demo-section.component.html',
  styleUrls: ['./demo-section.component.scss']
})
export class DemoSectionComponent extends BaseComponent implements OnInit {
  language;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(this.EssentialObject$.subscribe(x => {
      this.language = x.language;
    }));
  }


  upgrade() {
    this.SnackbarService.info(this.localize('txtUpgradeRequestSent', this.language, this.ModuleNames.Shared));
  }

}
