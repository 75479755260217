import { Component, OnInit } from '@angular/core';
import { GlobalsService } from 'src/app/core/services/globals.service';

@Component({
  selector: 'content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent implements OnInit {

  constructor(public globalService:GlobalsService) { }

  ngOnInit(): void {
  }

}
