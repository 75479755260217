import { Component, Input, OnInit } from '@angular/core';
import wellbeingClosedSurveyAnimation from '../../../utils/closed-survey-animations';
import { WellbeingScalesService } from '../../../utils/wellbeing-scales.service';
import { AnalyticsFilter, AnalyticsService } from '../../../data-access/services/analytics.service';
import { map, switchMap } from 'rxjs/operators';
import { SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { WbBaseComponent } from '../../../utils/wellbeing-base.component';

@Component({
  selector: 'wellbeing-index-by-scales',
  templateUrl: './wellbeing-index-by-scales.component.html',
  styleUrls: ['./wellbeing-index-by-scales.component.scss'],
  animations: wellbeingClosedSurveyAnimation,
})
export class WellbeingIndexByScalesComponent extends WbBaseComponent implements OnInit {

  @Input()
  language

  filters: AnalyticsFilter

  wellbeingIndexByScales = []

  constructor(public wellbeingScalesService: WellbeingScalesService, private analysisService: AnalyticsService, private surveyAnalysisService: SurveyAnalysisService) { super() }

  ngOnInit(): void {
    this.analysisService.filters$.pipe(
      switchMap(filters => {

        return this.surveyAnalysisService.getSectionRespondentsIndex({
          companyKey: filters.companyKey,
          surveyKey: filters.survey.key,
          tenantKey: filters.tenantKey,
          filters: [...this.analysisService.mapGlobalFilters(filters), ...filters.chartFilters || []]
        }).pipe(map(scales => {
          return {
            scales: this.wellbeingScales.map(x => ({ mean: scales.find(y => y.scale == x.scale)?.mean || 0, scale: x.scale })),
            filters
          }
        }))


      })
    ).subscribe(({ scales, filters }) => {
      this.filters = filters;
      this.wellbeingIndexByScales = scales;

      // return of(filters);
    })
  }

}
