<z-modal [open]="true" autoWidth="true" (click)="logData(questionAnalysis)">
  <ng-container body>
    <div class="flex flex-row gap-5 -mb-10 -mt-10 max-h-screen-90 h-screen overflow-hidden max-w-7xl">
      <div class="flex flex-col items-stretch  w-4/5">
        <div class="max-h-screen-90 block  surveyx-report innerListScroll w-export portrait" id="pdf">
          <!-- front page -->
          <ng-container *ngIf="exportReport">
            <div class="innerPage relative overflow-hidden">
              <div
                class="absolute bottom-0 top-0 left-0 z-50  flex flex-col w-full items-stretch justify-between bg-white p-10">
                <div class="flex flex-col items-stretch justify-between">
                  <div class="flex flex-row justify-between gap-10">
                    <h6 class="text-zen text-sm uppercase font-semibold text-opacity-50">
                      {{ "txtCONFIDENTIAL" | translate:language:ModuleNames.SurveyX }}
                    </h6>
                    <img class="h-28" [src]="selectedEntity?.logoURL? selectedEntity?.logoURL: 'assets/img/no-logo.png'" alt="">
                  </div>
                  <div class="flex flex-col gap-20">
                    <div class="mt-12 flex  gap-2 uppercase"  [ngClass]="(language|language)?'flex-col-reverse':'flex-col'">
                      <h1 class="text-zen text-5xl font-medium">{{filters.survey.name}}</h1>
                      <h5 class="text-zen text-5xl">{{ "txt_Report" |
                        translate:language:ModuleNames.SurveyX }} </h5>
                    </div>
                    <div class="flex flex-col gap-2">
                      <h6 class="text-zen-lite text-xl">{{ "txtFor" |
                        translate:language:ModuleNames.SurveyX }}:</h6>
                      <h1 class="text-zen text-xl font-semibold">{{ selectedEntity.name|
                        extract:language }}
                      </h1>
                      <h5 class="text-zen-lite text-xl">{{date | date: 'dd/MM/yyyy'}}</h5>
                    </div>

                  </div>

                </div>

                <div class="flex flex-col gap-1">
                  <p class="text-xs text-zen text-opacity-50">{{ "txtPoweredby" |
                    translate:language:ModuleNames.SurveyX }}</p>
                  <img class="h-10 w-36 object-contain" src="assets/img/logo.png" alt="">
                </div>
              </div>
            </div>
            <!-- table of content -->
            <div class="innerPage break-before bg-white">
              <div class="py-20 px-10">
                <h1 class="text-zen font-semibold text-2xl">{{ "txttableofcontents" |
                  translate:language:ModuleNames.SurveyX }}
                </h1>
                <!-- content -->
                <div class="flex flex-col gap-1 pt-5" *ngIf="filteredTableOfContent?.length">
                  <div class="flex flex-col" *ngFor="let content of filteredTableOfContent; let index = index">

                    <div class="flex flex-col items-stretch rounded bg-zen bg-opacity-5" *ngIf="content.dispaly">
                      <div
                        class="flex flex-row items-center justify-between px-5 font-medium text-sm text-zen leading-10">
                        <div class="flex flex-row items-center gap-3">
                          <span class="text-exp">{{index + 1}}. </span>
                          <span>{{!content.titleExtract? (content.title |
                            translate:language:ModuleNames.SurveyX):(content.title |
                            extract:language) }}</span>
                        </div>
                        <span>{{content.content > 1?content.page - content.content + 1:
                          content.page}}</span>
                      </div>
                      <ng-container *ngIf="content?.hideChildren != true">
                        <div class="flex flex-col pb-2" *ngIf="content?.children?.length">
                          <ng-container *ngFor="let childContent of content.children; let childIndex = index">
                            <div *ngIf="!childContent.hide"
                              class="flex flex-row items-center justify-between px-5 font-medium text-xs text-zen-lite leading-9 ">
                              <div class="flex flex-row items-center gap-3 px-6">
                                <span>{{index + 1}}.{{childIndex + 1}} </span>
                                <span>{{!childContent.titleExtract?
                                  (childContent.title
                                  |translate:language:ModuleNames.SurveyX):(childContent.title
                                  | extract:language) }}</span>
                              </div>
                              <span class="text-sm text-zen">{{childContent.content >
                                1?childContent.page - childContent.content + 1:
                                childContent.page}}</span>
                            </div>
                          </ng-container>

                        </div>
                      </ng-container>

                    </div>
                  </div>


                </div>
              </div>
            </div>

            <div class="break-before bg-white pt-20">
              <h1 class="text-zen font-semibold text-2xl px-10"><span class="text-exp">1.
                </span>{{ "txtQuestionAnalysis" | translate:language:ModuleNames.SurveyX }}
              </h1>
              <ng-container *ngFor="let question of questionAnalysis; let i = index">
                <div [ngClass]="{'break-before': i != 0}" class=" mt-20" id="analysis-report-{{ i }}">

                  <div class="maxwInherent mx-10 px-px" *ngIf="assignPage(question);">

                    <ng-container [ngSwitch]="question.type">
                      <ng-container *ngSwitchDefault>

                        <sx-dependent-question [pageLines]="pageLines(question)" [export]="true"
                          *ngIf="question.dependencyQuestions" [question]="question" [questions]="questionAnalysis"
                          [language]="language"></sx-dependent-question>

                        <sx-question-chart [exporting]="exporting"  [surveyCriterias]="surveyCriterias" [pageLines]="pageLines(question)" [export]="true" class="flex flex-row gap-4"
                          [data]="question?.data$ | async" [question]="question"
                          [questionNo]="(questionAnalysis.indexOf(question) + 1)"
                          [language]="language"></sx-question-chart>

                      </ng-container>

                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div *ngIf="exportAnalysis && surveyCriterias?.length" class="block break-before bg-white pt-20 px-10">
            <h1 class="text-zen font-semibold text-2xl "><span class="text-exp">{{sectionIndex('analysis')}}.
              </span>{{ "txtRespondentAnalysis" | translate:language:ModuleNames.SurveyX }}</h1>
            <!-- content -->
            <div class="block relative">
              <div class="block -ml-3 -mr-3 pt-7">
                <div class="inline-block relative w-1/2 px-3 mb-6">
                  <div class="flex flex-col items-center border border-zen border-opacity-10 rounded gap-6 pt-4 pb-6">
                    <h4 class="text-center text-sm font-medium text-zen">
                      {{ "txt_response_rate"| translate:language:ModuleNames.SurveyX }}
                    </h4>
                    <kendo-arcgauge [value]="overall.responseRate" [color]="'#0082C6'"
                      [transitions]="true" style="height: 137px;width: 137px;" renderAs="svg">
                      <kendo-arcgauge-scale [rangePlaceholderColor]="'#0082C6'+'19'" [startAngle]="90" [endAngle]="450"
                        [rangeSize]="17" [rangeLineCap]="'butt'">

                        <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                          <ng-template kendoArcGaugeCenterTemplate let-value="value">
                            <p class="text-xl text-zen font-medium"> {{
                              overall.responseRate | number:'1.1-1' }}%</p>
                          </ng-template>
                        </kendo-arcgauge-scale-labels>
                      </kendo-arcgauge-scale>
                    </kendo-arcgauge>
                  </div>
                </div>

                <!-- <sx-respondent-chart [chart]="chart" [data]="chart.data$ | async"
                    [surveyCriterias]="surveyCriterias"></sx-respondent-chart> -->
                <ng-container *ngFor="let chart of exportableCharts?.charts">
                  <ng-container *ngIf="!chart.notExportable">
                    <span *ngIf="chart.breakBefore" class="block break-before w-full h-20"></span>
                    <div class="relative px-3 mb-6 align-top"
                      [ngClass]="{'w-1/2 inline-block avoidBreak':!chart.fullWidth , 'w-full block':chart.fullWidth}"
                      *ngIf="chart.data as data" (click)="logData(chart)">
                      <export-chart-vertical [language]="language" [rows]="chart.fullWidth?2: 1"
                        [categoryTemplate]="chart.key == 'GENDER'? categoryTemplate:''" [data]="data" [chart]="chart"
                        [rows]="fullWidthChartCheck(chart, data.categories)"
                        [breakIndex]="fullWidthChartCheck(chart, data.categories) == 1? 34: 42">
                        <ng-container header>
                          <div class="flex flex-row items-center gap-2">
                            <ng-container *ngIf="returnIcon(chart)">
                              <i *ngIf="chart?.icon;" [class]="'fa-'+ chart.icon"
                                class="fa-regular text-exp text-xl"></i>
                              <img *ngIf="chart?.image" [src]="chart.image" alt="">
                            </ng-container>
                            <h4 class="text-sm font-normal text-zen">
                              {{chart.Title}}
                            </h4>
                          </div>
                        </ng-container>
                      </export-chart-vertical>
                      <ng-template #categoryTemplate let-category let-value="value">
                        <div class="flex flex-row justify-between items-center pt-1">
                          <h5 class="text-zen text-sm">{{category}}</h5>
                          <h5
                            [ngClass]="getGenderKey(category) == 'male'?'text-int': getGenderKey(category) == 'female'?'text-zen-pink':'text-exp'"
                            class="text-sm font-semibold">
                            {{value | number:'1.1-1'}}%
                          </h5>
                        </div>
                        <div
                          [ngClass]="getGenderKey(category) == 'male'?'bg-int': getGenderKey(category) == 'female'?'bg-zen-pink':'bg-exp'"
                          class="flex flex-row justify-start h-2.5 rounded-sm bg-opacity-10 items-stretch overflow-hidden">
                          <div
                            [ngClass]="getGenderKey(category) == 'male'?'bg-int': getGenderKey(category) == 'female'?'bg-zen-pink':'bg-exp'"
                            [style.width.%]="value">
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </ng-container>

                </ng-container>
              </div>
            </div>
          </div>
          <!-- end page -->
          <div class="block break-before bg-white relative">
            <div class="innerPage break-before relative">
              <div
                class="absolute bottom-0 top-0 left-0 z-30 flex flex-col h-full w-full items-stretch justify-between bg-white p-10">
                <div class="flex flex-col items-center">
                  <img class="h-20 w-auto object-contain mt-40" src="assets/img/logo.png" alt="">
                  <img class="h-10 w-auto object-contain" src="assets/img/tagline.png" alt="">
                  <div class="flex flex-row items-start justify-center gap-1 mt-10">
                    <i class="fa-regular fa-globe text-zen"></i>
                    <h6 class="text-zen text-sm"> ex.zenithr.com</h6>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="header w-full px-10 flex flex-row items-start justify-between">
            <h5 class="text-3xs text-zen text-opacity-60">{{filters.survey.name}}</h5>
            <h5 class="text-3xs text-zen "><span class="text-opacity-60">{{
                "txtPreparedFor"|translate:language:ModuleNames.Shared }}
              </span> <span class="text-zen font-medium">{{selectedEntity.name | extract:language}}</span>
            </h5>
          </div>
          <div class="footer w-full px-10 flex flex-row items-end justify-between">
            <div class="flex flex-col gap-1">
              <p class="text-xxs text-zen text-opacity-50">{{ "txtPoweredby" |
                translate:language:ModuleNames.Shared }}</p>
              <img class="h-8 w-auto object-cover" src="assets/img/logo.png" alt="">
            </div>
            <div class="flex flex-col items-end gap-1">
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtGenerated"|translate:language:ModuleNames.Shared }}: {{date | date:
                'dd/MM/yyyy'}}
              </h5>
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtAllRights"|translate:language:ModuleNames.Shared }} © {{date | date: 'yyyy'}}
                -
                ex.zenithr.com</h5>

            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-stretch justify-between w-1/5">
        <div class="exp flex flex-col gap-3">
          <div class="flex flex-row items-start gap-2 justify-start pb-3">
            <i class="far fa-file-export text-exp text-lg"></i>
            <h4 class="text-zen text-2xl">{{ 'btnExport' | translate:language:ModuleNames.SurveyX }}
            </h4>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
            <z-toggle (ngModelChange)="filterTableOfContent($event)"
              [label]="'txtQuestionAnalysis' | translate:language:ModuleNames.SurveyX "
              [(ngModel)]="exportReport"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
            <z-toggle [disable]="!surveyCriterias?.length" (ngModelChange)="filterTableOfContent($event)"
              [label]="'txtRespondentAnalysis' | translate:language:ModuleNames.SurveyX" [(ngModel)]="exportAnalysis"></z-toggle>
          </div>
          <!-- <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
              <z-toggle (ngModelChange)="filterTableOfContent($event)"
                [label]="'txt_questions' | translate:language:ModuleNames.SurveyX "
                [(ngModel)]="exportQuestions"></z-toggle>
            </div> -->
        </div>
        <div class="flex flex-row justify-between gap-5">
          <a (click)="close.emit(true)" class="btn cancel leading-12 rounded text-sm text-center px-5">{{"btn_cancel"|
            translate:language:ModuleNames.SurveyX}}</a>

          <a (click)="export()" [ngClass]="!downloadReport?'opacity-60 pointer-events-none':''"
            class="btn exp leading-12 rounded text-sm text-center px-5">{{"btnExport"|translate:language:ModuleNames.SurveyX}}</a>

        </div>
      </div>
    </div>

  </ng-container>
</z-modal>
