import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LogService } from './log.service';
import { PlatformUser } from '../data-models/platform-user';
import { map, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { CrashReportingService } from './crash-reporting.service';
import { Router } from '@angular/router';
import { GeneralService } from './general.service';
import { LocalService } from './local.service';
import { UserProgress } from '../data-models/user-progress';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformUserService {
  sEndpoint: string = 'https://apis.zenithr.net/api/'; //'http://localhost:52619/api/';.
  coreAPI: string = 'https://apis.zenithr.net/api/'; //'http://localhost:52619/api/';

  constructor(
    private db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    private logService: LogService,
    private crashReportingService: CrashReportingService,
    private router: Router,
    private localService: LocalService,
    private http: HttpClient,
    private globals: GlobalsService
  ) {
    this.sEndpoint = this.globals.sEndpoint;
    this.coreAPI = this.globals.coreAPI;
  }

  

  updateUserPermissions(request: any) {
    return this.http.post(
      this.coreAPI + 'PlatformUser/AddPlatformUser',
      request
    );
  }

  fetchUserPermissions(request: any) {
    return this.http.post(
      this.coreAPI + 'PlatformUser/GetUserPermissions',
      request
    );
  }

  updateUserInfo(request: any) {
    return this.http.post(
      this.coreAPI + 'PlatformUser/UpdateUserInfo',
      request
    );
  }

  fetchRelease() {
    this.db
      .object<any>('/ReleaseInfo/')
      .snapshotChanges()
      .pipe(map((a) => ({ key: a.key, ...a.payload.val() })))
      .subscribe((releaseInfo: any) => {
        let releaseDate = this.localService.getJsonValue('ReleaseDate');
        if (!releaseDate) {
          this.localService.setJsonValue(
            'ReleaseDate',
            releaseInfo.releaseDate + ''
          );

          this.logoutForReleaseChange();
        } else {
          let compare = Number(releaseDate);
          if (compare != releaseInfo.releaseDate) {
            this.localService.setJsonValue(
              'ReleaseDate',
              releaseInfo.releaseDate + ''
            );

            this.logoutForReleaseChange();
          }
        }
      });
  }

  fetchProgress(userKey: string) {
    return this.db
      .list<UserProgress>('/Progress/', (ref) =>
        ref.orderByChild('userKey').equalTo(userKey)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }

  logoutForReleaseChange() {
    this.crashReportingService.submitLogCode(33);
    // this.afAuth
    //   // You only want unathenticated states:
    //   .filter((authState) => !authState)
    //   // You only want the first unathenticated state:
    //   .first()
    //   // You should now be able to navigate:
    //   .subscribe(() => );
    //   // The composed observable completes, so there's no need to unsubscribe.
    this.afAuth.signOut();
    this.router.navigate(['/login']);
  }

  createPlatformUser(user: PlatformUser) {
    this.db
      .object<PlatformUser>('/PlatformUsers/' + user.key)
      .set(user)
      .then((_) => {
        this.logService.submitLog(
          4,
          'AuthenticationService.createPlatformUser()',
          'Task Completed',
          { user }
        );
      })
      .catch((error) => {
        this.logService.submitLog(
          4,
          'AuthenticationService.createPlatformUser()',
          'Task Failed',
          { user }
        );
      });
  }

  updatePlatformUser(user: PlatformUser) {
    return this.db.object('/PlatformUsers/' + user.key).update(user);
  }

  fetchTenantPlatformUsersOnce(tenantKey: string) {
    return this.db
      .list<PlatformUser>('/PlatformUsers/', (ref) =>
        ref.orderByChild('tenantKey').equalTo(tenantKey)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => ({ key: a.key, ...a.payload.val() }))
        ),
        take(1)
      );
  }

  // TAKE (1)
  // fetchTenantPlatformUsers(tenantKey: string){
  //   return this.db.list<PlatformUser>('/PlatformUsers/', ref => ref.orderByChild('tenantKey').equalTo(tenantKey)).snapshotChanges()
  //   .pipe(
  //     map(actions =>
  //       actions.map(a => ({ key: a.key, ...a.payload.val() }))
  //     ), take(1)
  //   );
  // }

  fetchPlatformUser(userKey: string) {
    return this.db
      .object<PlatformUser>('/PlatformUsers/' + userKey)
      .snapshotChanges()
      .pipe(map((a) => ({ key: a.key, ...a.payload.val() })));
  }

  fetchAllTenantUsers(tenantKey: string) {
    return this.db
      .list<PlatformUser>('/PlatformUsers/', (ref) =>
        ref.orderByChild('tenantKey').equalTo(tenantKey)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }


  fetchAllUsers() {
    return this.db
      .list<PlatformUser>('/PlatformUsers/')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

}
