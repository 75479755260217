import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { AnalyticsFilter, AnalyticsService } from '../../../data-access/services/analytics.service';
import { Subject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HeatMapDataModel, HeatMapSourceModel } from 'src/app/shared/heatmap/heatmap.component';
import { HeatSource, SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { P } from '@angular/cdk/keycodes';
import { KeyNameDto } from '@ex/core/common';
import { KeyResolverService } from 'src/app/core/services/key-resolver.service';
import { WellbeingKeyResolverNames } from '../../../utils/wellbeing-key-resolver-names';
import { WbBaseComponent } from '@ex/module/wellbeing/utils/wellbeing-base.component';

import { RespondentService } from '@ex/module/core/services/respondent.service';
@Component({
  selector: 'heatmap-analysis',
  templateUrl: './heatmap-analysis.component.html',
  styleUrls: ['./heatmap-analysis.component.scss']
})
export class HeatmapAnalysisComponent extends WbBaseComponent implements OnInit {

  @Input()
  language = "EN"

  filtersLoaded = new Subject();
  filtersLoaded$ = this.filtersLoaded.asObservable();

  filters: AnalyticsFilter

  showHeatMap: boolean = true;
  surveyCriterias: any;

  selectedCriteriaKey;

  criteriaChanged = new Subject();
  criteriaChanged$ = this.criteriaChanged.asObservable();

  selectedKey = null;

  heatmapData: HeatMapDataModel = {
    heatColumns: [],

    heatSource: [
      // {
      //   key: '1',
      //   parentKey: null,
      //   isDriver: true,
      //   name: 'Physical Wellbeing',
      //   overAll: 80,
      //   options: [-2, 3]
      // },
      // {
      //   key: '3',
      //   parentKey: '1',
      //   isDriver: false,
      //   name: 'Q. Lorem Ispusm',
      //   overAll: 80,
      //   options: [-4, 2]
      // },
      // {
      //   key: '2',
      //   parentKey: null,
      //   isDriver: true,
      //   name: 'Financial Wellbeing',
      //   overAll: 80,
      //   options: [-2, 3]
      // }
    ]
  } as HeatMapDataModel

  constructor(private respondentService: RespondentService,private analyticsService: AnalyticsService, private surveyAnalysisService: SurveyAnalysisService, private keyResolverService: KeyResolverService) {
    super()


  }

  ngOnInit(): void {

    this.fetchAnalysis()



    const subscription = this.analyticsService.filters$
      .pipe(
        switchMap(filters => {
          return of({ filters, criterias: filters.survey.surveyCriterias?.map(x => ({ key: x.key, name: x.name })) || [] });
        })
      )
      .subscribe(({ filters, criterias }) => {
        this.filters = filters;
        this.surveyCriterias = criterias;

        this.onCriteriaChange(this.surveyCriterias[0]?.key);
        this.filtersLoaded.next(true);
      });

    this.addSubscription(subscription);

  }
  fetchAnalysis() {

    const subscription = this.criteriaChanged$
      .pipe(
        switchMap((key: any) => {

          return this.surveyAnalysisService.getHeatmapAnalysis(key, {
            companyKey: this.filters.companyKey,
            tenantKey: this.filters.tenantKey,
            surveyKey: this.filters.survey.key,
            filters: [...this.analyticsService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []]
          }).pipe(
            map(data => {
              return {
                key,
                data
              }
            })
          )


          // return of({ key, data: [] });
        })
      )
      .subscribe(({ key, data }) => {
        this.selectedCriteriaKey = key;

        const resolver = this.keyResolverService.getResolver(WellbeingKeyResolverNames.SurveyCriterias);
        this.keyResolverService.setKeyResolver(resolver);
        let keys = this.filters.survey.dimensions.map(x => x.key);
        data.heatSource = keys.map(x => { return data.heatSource.filter(f => f.key == x || f.parentKey == x) }).flat();
        data.heatColumns = data.heatColumns.map(x => {
          if (x == 'overall')
            x = this.localize('txtOverall', this.language, this.ModuleNames.Wellbeing)
          else
            x = this.keyResolverService.resolve(x, this.language, this.selectedCriteriaKey);

          return x;
        });


        this.heatmapData = {
          heatColumns: data.heatColumns,
          heatSource: this.processHeatMapData(data.heatSource)
        }

      });

    this.addSubscription(subscription);
  }

  processHeatMapData(heatSource: HeatSource[]): HeatMapSourceModel[] {

    let arranged = [];

    if (!heatSource.length)
      return arranged;

    // Calculate the 1st overall row of the heatmap
    const sections = heatSource.filter(x => x.isDriver);

    const sectionOverall = {
      key: 'overall',
      name: [{ key: this.language, name: this.localize('txtOverall', this.language, this.ModuleNames.Wellbeing) }] as KeyNameDto[],
      isDriver: true,
      overall: sections.reduce((a, b) => a + b.overall, 0) / sections.length,
      options: []
    } as HeatSource;

    let columnOptions = sections.map(x => x.options);

    for (let i = 0; i < heatSource[0].options.length; i++) {
      sectionOverall.options.push(columnOptions.reduce((a, b) => a + b[i], 0) / sections.length);
    }

    arranged.push(sectionOverall);

    for (let item of heatSource.filter(x => x.isDriver)) {
      arranged.push(item);
      arranged = arranged.concat(heatSource.filter(x => x.parentKey === item.key));
    }

    return arranged.map(x => {
      return {
        isDriver: x.isDriver,
        key: x.key,
        parentKey: x.parentKey,
        name: this.ExtractPipe.transform(x.name, this.language),
        overAll: x.overall,
        options: x.options.map(y => { return this.toFixed(y - x.overall, 1) })
      }
    })

  }

  onCriteriaChange(criteria) {
    this.selectedKey = null;
    this.criteriaChanged.next(criteria);
  }

  filterHeatMap(e) {
    if (this.selectedKey != e)
      this.selectedKey = e;
  }

  downloadHeatmap() {
    let request: any = {
      columns: this.heatmapData.heatColumns,
      rows: this.heatmapData.heatSource
    }

    this.addSubscription(this.respondentService.downloadHeatmap(request).subscribe((response: any) => {
      if (response.response == 'SUCCESS') {
        this.saveAsXlsxFile(response.Data);
      }
    }));
  }
  saveAsXlsxFile(data: string) {
    var mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var a = document.createElement('a');
    a.href = mediaType + data;
    a.download = 'Heatmap Comparison.xlsx';
    a.click();
  }
  

}
