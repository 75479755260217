import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, AfterContentChecked, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { GlobalsService } from "@ex/module/core/services/globals.service";
import { LanguageService } from "@ex/module/core/services/language.service";
import { LoaderService } from "@ex/module/core/services/loader.service";
import { LocalService } from "@ex/module/core/services/local.service";
import { ZenCDKService } from "@ex/module/shared/Theme/services/zen-cdk.service";
import { fadeUpDown, ChildrenAnimation, listAnimation } from "@ex/module/shared/animations/allAnimations";
import { ConfirmationDialogComponent } from "@ex/module/shared/confirmation-dialog/confirmation-dialog.component";
import { ModuleName } from "@ex/module/shared/enums/ModuleName";
import { BehaviorSubject, Subject, Subscription, combineLatest } from "rxjs";
import { environment } from "src/environments/environment";
import { EmailTemplateComponent } from "../../../ui/templates/email-template/email-template.component";
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { FormLibraryFiltersService } from "@ex/module/survey-x/data-access/services/form-library-filters.service";
import { BaseComponent } from "@ex/module/core/domain/base-component";
import { FormParams } from "@ex/module/survey-x/data-access/models/form-params";
import { filter, switchMap } from "rxjs/operators";
import { FormService } from "@ex/survey-x/services/FormService";
import { FormCategoryService, FormTypeService } from "@ex/survey-x/index";
import { TableOptions } from "@ex/module/core/data-models/table-options";


@Component({
  selector: 'app-form-library-table',
  templateUrl: './form-library-table.component.html',
  styleUrls: ['./form-library-table.component.scss'],
  animations: [fadeUpDown, ChildrenAnimation, listAnimation],
  providers: [DatePipe]
})
export class FormLibraryTableComponent extends BaseComponent implements OnInit, AfterContentChecked {

  searchText = '';

  endPoint = environment.endPoint;
  surveyForm!: any;
  titleRename!: string;

  allColumns;
  activeColumns: any[];

  dLanguage: any;

  private subscriptions = new Subscription();

  selectedCategoryFilter = "All";
  selectedTypeFilter = "All"
  categoryFilterNameColor = "";
  categoryFilterBorderColor = "";
  typeFilterNameColor = "";
  typeFilterBorderColor = "";

  currentTenant: any;

  currentTenantKey: any;

  companyKey: string
  tenantKey: string

  isSureDelete: boolean = false;

  formParams: FormParams = {
    category: "All",
    type: "All",
    searchText: ""
  }

  share = { key: '', open: false };
  exportData = { key: '', open: false };
  newFormCategory = { key: '', open: false };
  editFormDetails = { key: '', open: false };

  categoriesList: any[] = [];
  typesList: any[] = [];
  all =
    {
      key: 'All',
      name: 'All'
    }

  allForms: any;


  @Input()
  tableData: any[] = [];
  tableDataBK!: string;
  txtSearch!: string;
  tableOptions!: TableOptions;
  selectedTable: any;
  formTypeId = null;
  language = 'EN';
  ModuleNames = ModuleName;
  rtl: boolean = false;
  hoveredRow: any;
  filter: { table: string } = {
    table: 'All',
  }
  columns = [
    // {
    //   field: 'key',
    //   title: 'ID',
    // },
    {
      field: 'status',
      title: 'STATUS',
    },
    {
      field: 'title',
      title: 'TITLE',
    },
    {
      field: 'formType',
      title: 'FORM TYPE',
    },


    {
      field: 'category',
      title: 'CATEGORY',
    },
    {
      field: 'dateCreated',
      title: 'DATE CREATED',
    },
    {
      field: 'dateModified',
      title: 'DATE MODIFIED',
    },
    {
      field: 'action',
      title: '',
    }
  ];
  colors: any;

  settingsLoaded$ = new Subject();

  numberFormatter = Intl.NumberFormat('en-US');

  totalCount: any;
  totalPages: any;
  pageNumber: any = 0;
  pageSize: any = 10
  paginationOptions: any;

  searchFilterText = "";

  constructor(private localService: LocalService, private languageService: LanguageService, private client: HttpClient,
    public datePipe: DatePipe, private global: GlobalsService, private router: Router, private loader: LoaderService,
    private formLibraryFiltersService: FormLibraryFiltersService, private surveysService: SurveysService, public matDDialog: MatDialog,
    private zenCdk: ZenCDKService,
    private formTableService: FormService,
    private formCategoryService: FormCategoryService,
    private formTypeService: FormTypeService) {
    super();
    this.colors = this.global.basicColors;
  }


  ngOnInit(): void {
    this.GlobalService.innerRoute = false;

    this.categoriesList.unshift(this.all);
    this.typesList.unshift(this.all);

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    if (document.body.dir == 'rtl') {
      this.rtl = true;
    }

    this.fetchCategories();

    this.fetchFormTypes();

    combineLatest([this.settingsLoaded$, this.surveysService.StatusChanged$]).subscribe((resp) => {
      this.fetchTableData(0);
    });

    const subscription = this.EssentialObject$.pipe(
      filter(x => x.selectedEntity?.key != null),
    ).subscribe(obj => {

      this.tenantKey = obj.tenant.key;
      this.language = obj.language;
      this.companyKey = obj.selectedEntity.key

      this.languageService.language = this.language;
      this.columns = this.columns.map(x => {
        x.title = this.localize(x.title, this.language, this.ModuleNames.SurveyX);
        return x;
      })
      this.settingsLoaded$.next();
    })

    this.addSubscription(subscription);



    // this.currentTenant = JSON.parse(localStorage.getItem("tenant"));
    // this.currentTenantKey = localStorage.getItem("TenantKey")!;



    // let localLanguage = this.localService.getJsonValue('language');
    // if (!localLanguage) {
    //   this.language = 'EN';
    //   this.localService.setJsonValue('language', this.language);
    // } else {
    //   this.language = localLanguage;
    //   // this.language = 'FR';
    // }
    // console.log(this.language)
    // this.languageService.language = this.language;
    if (this.language == "EN") {
      this.dLanguage = "default"
    }
    else {
      this.dLanguage = this.language.toLowerCase();
    }

    // let currentUser = localStorage.getItem('User');
    // if (currentUser) {
    //   let request = JSON.parse(currentUser)

    // }
    //----------------------------------------------------------------------------

    //this.fetchTableData(0);

    //-------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------

    // if (this.currentTenant) {
    //   this.formCategoryService.getAllCategoriesByFormTypeAndTenant(this.tenantKey, this.companyKey, this.formTypeId).subscribe((response: any) => {

    //     response.forEach((element: any) => {

    //       this.categoriesList.push({
    //         key: element.id,
    //         name: element.name
    //       })
    //     })
    //   })
    // }

    // else if (this.currentTenantKey) {
    //   this.formCategoryService.getAllCategoriesByFormTypeAndTenant(this.tenantKey, this.companyKey, this.formTypeId).subscribe((response: any) => {

    //     response.forEach((element: any) => {

    //       this.categoriesList.push({
    //         key: element.id,
    //         name: element.name
    //       })
    //     })
    //   })
    // }



    //------------------------------------------------------------------------
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    //------------------------------------------------------------------------

  }
  fetchFormTypes() {
    const sub = this.formTypeService.getAllFormTypes().subscribe((response: any) => {
      response.forEach((element: any) => {

        this.typesList.push({
          key: element.id,
          name: element.name
        })
      })
    })

    this.addSubscription(sub);
  }

  onPageChange(value: any) {
    this.fetchTableData(value);
  }

  fetchTableData(pageNumber) {
    this.tableData = [];

    this.pageNumber = pageNumber;

    // if (this.currentTenant) {
    this.LoaderService.displayLoader();
    const sub = this.formTableService.getAllFormsByTenant(this.tenantKey, this.companyKey, this.pageNumber, this.pageSize, this.formParams)
      .subscribe((response: any) => {
        this.totalCount = response.totalCount;
        this.totalPages = response.totalPages;
        this.pageSize = response.pageSize;

        response.forms.forEach((element: any) => {
          if (!element.categoryName) {
            element.categoryName = "N/A";
          }
          this.tableData.push({
            key: element.id,
            status: element.statusName,
            title: element.title,
            category: element.categoryName,
            formType: element.typeName,
            dateCreated: this.datePipe.transform(element.createdDate, 'dd/MM/YYYY'),
            dateModified: this.datePipe.transform(element.modificationDate, 'dd/MM/YYYY'),
            tenantKey: element.tenantKey
          });
        });

        console.log("Form Table Data", this.tableData);

        this.tableDataBK = JSON.stringify(this.tableData);
        this.tableOptions = {
          sticky: true,
          export: false,
          disableSearch: true,
          data: this.tableData,
          columns: this.columns,
          selection: false,
          selectionKey: 'key',
          colors: this.colors,
          selectionRemove: [],
          whiteBackground: true,
          hidePagination: true,
        };

        this.LoaderService.hideLoader();

      });

    // }

    // else if (this.currentTenantKey) {
    //   this.surveysService.getAllSurveysByTenant(this.formParams, this.tenantKey, this.companyKey, this.pageNumber, this.pageSize)
    //     .subscribe((response: any) => {
    //       this.totalCount = response.totalCount;
    //       this.totalPages = response.totalPages;
    //       this.pageSize = response.pageSize;

    //       response.forms.forEach((element: any) => {
    //         if (!element.categoryName) {
    //           element.categoryName = "N/A";
    //         }

    //         this.tableData.push({
    //           key: element.id,
    //           status: element.statusName,
    //           title: element.title,
    //           category: element.categoryName,
    //           formType: element.typeName,
    //           dateCreated: this.datePipe.transform(element.createdDate, 'dd/MM/YYYY'),
    //           dateModified: this.datePipe.transform(element.modificationDate, 'dd/MM/YYYY'),
    //           tenantKey: element.tenantKey
    //         });
    //       });

    //       console.log("Form Table Data", this.tableData);

    //       this.tableDataBK = JSON.stringify(this.tableData);
    //       this.tableOptions = {
    //         sticky: true,
    //         export: false,
    //         data: this.tableData,
    //         columns: this.columns,
    //         selection: false,
    //         selectionKey: 'key',
    //         colors: this.colors,
    //         selectionRemove: [],
    //         whiteBackground: true,
    //         search: true,
    //         hidePagination: true,
    //         searchText: true,
    //         serverSearch: true,
    //         // customize : true,
    //         // customizeText : "Customize"
    //       };
    //       this.paginationOptions = {
    //         colors: this.colors
    //       };
    //     });
    // }

    this.addSubscription(sub);

    return this.tableData;
  }





  fetchCategories() {

    const sub = this.settingsLoaded$.pipe(
      switchMap(_ => {
        return this.formCategoryService.getAllCategoriesByFormTypeAndTenant(this.formTypeId, this.tenantKey, this.companyKey)
      })
    ).subscribe((response: any) => {

      response.forEach((element: any) => {

        this.categoriesList.push({
          key: element.id,
          name: element.name
        })
      })
    })

    this.addSubscription(sub);
  }


  hoveredChange(row: any) {
    if (row)
      this.hoveredRow = row.key;
    else
      this.hoveredRow = undefined;
  }

  onServerSearchText(value: any) {
    this.selectedTypeFilter = "All";
    this.formLibraryFiltersService.setType(this.selectedTypeFilter);

    this.selectedCategoryFilter = "All";
    this.formLibraryFiltersService.setCategory(this.selectedCategoryFilter);

    this.formParams.type = this.selectedTypeFilter;
    this.formParams.category = this.selectedCategoryFilter;

    this.formParams.searchText = value;
    this.fetchTableData(0);

  }


  ngAfterContentChecked(): void {
    //-----------------------------------------------------------------------------------------
    //---------------filter colors on select---------------------------------------------------
    if (this.selectedCategoryFilter != "All") {
      this.categoryFilterNameColor = "text-exp"
      this.categoryFilterBorderColor = "border-2 rounded border-exp"
    }
    else {
      this.categoryFilterNameColor = "text-zenMain"
      this.categoryFilterBorderColor = ""
    }

    if (this.selectedTypeFilter != "All") {

      this.typeFilterNameColor = "text-exp"
      this.typeFilterBorderColor = "border-2 rounded border-exp"
    }
    else {
      this.typeFilterNameColor = "text-zenMain"
      this.typeFilterBorderColor = ""
    }
    //-----------------------------------------------------------------------------------------
    //-----------------------------------------------------------------------------------------
  }


  //-----------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------
  //+++++++++++++++++++++++++++++ Filters +++++++++++++++++++++++++++++++++++++++++++++++++++
  //=========================================================================================
  //=========================================================================================

  onCategoryFilterChanged(selectedValue: any) {
    this.selectedCategoryFilter = selectedValue;
    this.formLibraryFiltersService.setCategory(this.selectedCategoryFilter);

    this.formParams.category = this.selectedCategoryFilter;
    this.formParams.searchText = "";

    this.fetchTableData(0);
  }

  onTypeFilterChanged(selectedValue: any) {
    this.selectedTypeFilter = selectedValue;
    this.formLibraryFiltersService.setType(this.selectedTypeFilter);

    this.selectedCategoryFilter = "All";
    this.formLibraryFiltersService.setCategory(this.selectedCategoryFilter);

    this.formParams.searchText = "";
    this.formParams.type = this.selectedTypeFilter;
    this.formParams.category = this.selectedCategoryFilter;

    this.formTypeId = selectedValue;
    this.categoriesList = [];
    this.categoriesList.unshift(this.all);

    this.formCategoryService.getAllCategoriesByFormTypeAndTenant(this.formTypeId, this.tenantKey, this.companyKey).subscribe((response: any) => {
      response.forEach((element: any) => {

        this.categoriesList.push({
          key: element.id,
          name: element.name
        })
      })
    })

    this.fetchTableData(0);
  }

  //-----------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------
  //+++++++++++++++++++++++++++++ +++++++++++++++++++++++++++++++++++++++++++++++++++
  //=========================================================================================
  //=========================================================================================

  openSurveyCreator() {
    this.router.navigate(["survey-x/form-library/main-info"]);
  }

  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------
  //++++++++++++++++++++++++++++ Table Actions ++++++++++++++++++++++++++++++++++++++++++
  //=====================================================================================
  //=====================================================================================
  openShare(element: any) {
    this.share.open = true;
    this.share.key = element.key;
  }

  openEditFormDetails(element: any) {
    this.editFormDetails.open = true;
    this.editFormDetails.key = element.key;

  }

  onEditEmailTemplate(element: any) {
    this.zenCdk.openComponent(EmailTemplateComponent, element.key);
  }

  openNewFormCategory() {
    this.newFormCategory.open = true;
  }

  async onRename(element: any) {

  }

  onExportData(element: any) {
    this.exportData.open = true;
    this.exportData.key = element.key;
  }

  onPreviewForm(element: any) {
    window.open('/survey-x/preview/' + element.key, '_blank');
  }

  async onLaunchForm(element: any) {
    var result: any = await this.formTableService.getFormWithoutResponses(element.key);
    this.surveyForm = result.query;
    this.surveyForm.statusType = 2;

    // (await this.surveysService.updateSurvey(this.surveyForm, element.key)).subscribe((response) => {
    //   this.fetchTableData();

    // });
    await this.formTableService.updateFormStatus(this.surveyForm, element.key).toPromise().finally(() => {
      this.fetchTableData(0);
    })



  }

  async onRelaunchForm(element: any) {
    this.formTableService.relaunchForm(element.key).subscribe((response) => {
      this.fetchTableData(0);
    })
  }

  async onCloneForm(element: any) {
    this.formTableService.cloneForm(element.key).subscribe((response) => {
      this.fetchTableData(0);
    })

  }

  async onCloseForm(element: any) {
    var result: any = await this.formTableService.getFormWithoutResponses(element.key);
    this.surveyForm = result.query;
    this.surveyForm.statusType = 3;

    // (await this.surveysService.updateSurvey(this.surveyForm, element.key)).subscribe((response) => {
    //   this.fetchTableData();
    // });
    await this.formTableService.updateFormStatus(this.surveyForm, element.key).toPromise().finally(() => {

      this.fetchTableData(0);

    })

  }

  async onDraftForm(element: any) {
    var result: any = await this.formTableService.getFormWithoutResponses(element.key);
    this.surveyForm = result.query;
    this.surveyForm.statusType = 1;

    // (await this.surveysService.updateSurvey(this.surveyForm, element.key)).subscribe((response) => {

    //   this.fetchTableData();
    // });

    await this.formTableService.updateFormStatus(this.surveyForm, element.key).toPromise().finally(() => {

      this.fetchTableData(0);

    })


  }



  async onDeleteForm(element: any) {

    let message = this.localize(
      'txtDeleteSurvey',
      this.language, this.ModuleNames.SurveyX)

    let subject = this.localize('Delete Form', this.language, this.ModuleNames.SurveyX)

    let actionButtonText = this.localize('txtYes', this.language, this.ModuleNames.SurveyX)
    let cancelButtonText = this.localize('txtCancel', this.language, this.ModuleNames.SurveyX)

    let confirmationDialog = this.matDDialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          message: message,
          subject: subject,
          dialogType: 'warning',
          actionButtonText: actionButtonText,
          cancelButtonText: cancelButtonText,
        },
        autoFocus: false,
      }
    );

    this.subscriptions.add(confirmationDialog.afterClosed().subscribe((result) => {

      if (result) {
        if (result == 'YES') {

          this.formTableService.deleteForm(element.key).subscribe((response) => {

            this.fetchTableData(0);
          });


        }
      }
    }));

  }


  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------
  //++++++++++++++++++++++++++++  ++++++++++++++++++++++++++++++++++++++++++
  //=====================================================================================
  //=====================================================================================
  onRemoveFilters() {
    this.selectedTypeFilter = "All";
    this.formLibraryFiltersService.setType(this.selectedTypeFilter);

    this.formParams.type = this.selectedTypeFilter;
    this.formTypeId = "All";
    this.categoriesList = [];
    this.categoriesList.unshift(this.all);

    this.formCategoryService.getAllCategoriesByFormTypeAndTenant(this.formTypeId, this.tenantKey, this.companyKey).subscribe((response: any) => {
      response.forEach((element: any) => {

        this.categoriesList.push({
          key: element.id,
          name: element.name
        })
      })
    })

    this.selectedCategoryFilter = "All";
    this.formLibraryFiltersService.setCategory(this.selectedCategoryFilter);

    this.formParams.category = this.selectedCategoryFilter;

    this.fetchTableData(0);
  }

  getCategoryName(categoryTitle: any, language: string) {
    if (!categoryTitle) return;

    try {
      let title = JSON.parse(categoryTitle);
      return title.find((f: any) => f.key == language).name
    } catch (e) {
      return categoryTitle;
    }
  }

}



