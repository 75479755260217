<div #outerBody *ngIf="open"
    class="flex z-max flex-col justify-center items-center select-none top-0 left-0 h-0 w-0 fixed bg-zen main-overlay transition-all"
    [ngClass]="{ 'h-full w-full': open, ' bg-opacity-20':mobileView,' bg-opacity-5': !mobileView }" (click)="close()">
    <div #body class="top-0 left-0" [ngClass]="{'h-full justify-center items-center':mobileView,'fixed': !mobileView}">
        <div  class="flex flex-col relative "
            [ngClass]="{'h-full justify-center items-center':mobileView}">
            <div [@fadeUpDown] *ngIf="innerOpen" class="flex flex-row  pt-1"
                [ngClass]="{'justify-end':oppositeRight || oppositeLeft,'left-0' : rtl,'absolute w-0' :!mobileView,'bottom-0':reverse} "
                [style.marginLeft.px]="oppositeRight?triggerWidth:oppositeLeft? -triggerWidth:''">
                <div class="flex flex-col bg-white rounded p-1 w-inherit innerListScroll z-1000 max-h-44 min-w-max">
                    <ng-content ></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>
