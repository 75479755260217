<z-modal [open]="true" [autoWidth]="true">
  <ng-container header>
    <div class="flex flex-row gap-2 items-center">
      <i class="fal fa-bell text-white bg-exp  shadow-xl rounded-full h-10 w-10 text-xl text-center leading-10 "></i>
      <h4 class="text-2xl font-bold text-zen">{{ "txtSend Reminder"|translate:language:ModuleNames.SurveyX}}</h4>
    </div>
  </ng-container>
  <ng-container body>
    <!-- <div class="flex flex-row gap-2 items-center mb-5">
      <a (click)="invitationTemplate = false" class="btn leading-12 w-1/2 rounded-lg text-center uppercase" [ngClass]="invitationTemplate?'cancel':'exp'">Reminder Template</a>
    </div> -->

    <zen-editor  (reset)="resetTemplate()" [language]="language" [emailObject]="reminder"></zen-editor>

  </ng-container>
  <ng-container footer>
    <a (click)="close.emit(true)" class="btn cancel  text-center  rounded-md px-8 py-4 uppercase text-lg font-medium">{{ "txtCancel"|translate:language:ModuleNames.SurveyX}}</a>
    <a (click)="update()"  class="btn exp  rounded-md px-8 py-4 uppercase text-lg font-medium">{{ "txtSend Reminder"|translate:language:ModuleNames.SurveyX}}</a>
  </ng-container>
</z-modal>
