import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../core/services/language.service';
import { ZenSnackbarService } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { LocalService } from '../../../core/services/local.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { ChildrenAnimation, fadeUpDown } from 'src/app/shared/animations/allAnimations';
import { GenerateSurveyLinkService } from '@ex/wellbeing/surveys';
declare var $: any;
@Component({
  selector: 'app-wellbeing-landing-page',
  templateUrl: './wellbeing-landing-page.component.html',
  styleUrls: ['./wellbeing-landing-page.component.scss'],
  animations: [fadeUpDown, ChildrenAnimation]
})
export class WellbeingLandingPageComponent implements OnInit {

  userSubscription: Subscription;
  language: string = 'EN';
  logoLoading: boolean = false;
  tenantLogo: string;
  base64Image: string;
  logoURL: string = 'assets/img/svg/logo.svg';
  tenantKeyExists: boolean = false;
  section: string = 'login';
  hide = true;
  showLangage: boolean = false;
  returnUrl: string;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  ModuleNames = ModuleName

  form = {
    id: '',
    name: '',
    email: '',
  };
  valid = true;

  supportedLanguages: any = [];
  tenantKey: string;
  engagementKey: string;
  referenceKey: string;

  displayLoader: boolean = false;


  private subscriptions = new Subscription();

  constructor(private route: ActivatedRoute, private translationPipe: TranslatePipe, private languagePipe: LanguagePipe,
    private languageService: LanguageService,
    private localService: LocalService, private generateSurveyLinkService: GenerateSurveyLinkService,
    private router: Router, private zenSnackBarService: ZenSnackbarService) {
  }




  ngOnInit(): void {
    this.canvasStart()

    this.referenceKey = this.route.snapshot.paramMap.get('refKey');

    if (this.referenceKey) {
      localStorage.setItem('LandingKey', this.referenceKey);

      this.supportedLanguages = this.languageService.supportedLanguages.filter(s => (s.key != 'FR'));

      this.logoURL = 'assets/img/svg/logo.svg'; // to be resolved to client's logo

      this.SetupLanguage();

      this.isArabic();

      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    }
  }



  onSubmit(event?) {

    if (this.form.id) {
      if (!this.form.name)
        this.form.name = this.form.id;
      this.displayLoader = true;
      this.subscriptions.add(this.generateSurveyLinkService.createSurveyRespondent(this.referenceKey, {
        employeeId: this.form.id,
        email: this.form.email,
        name: this.form.name
      }).subscribe((response: any) => {
        if (response.url) {
          this.router.navigate(['/wellbeing-questionnaire/' + response.token]);
          this.displayLoader = false;
        }
      }, (error) => {
        this.zenSnackBarService.error(error.error.error.message);
        this.displayLoader = false;
      }));
    }
    else {
      this.valid = false;
      this.zenSnackBarService.error(this.translationPipe.transform('txtEnterInformation', this.language, this.ModuleNames.Engagement));
    }
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  canvasStart() {
    var canvas = <HTMLCanvasElement>document.getElementById('c');
    var ctx = canvas.getContext('2d');
    let colors = ['#fbfbfb', '#f7f7f8', '#f4f4f4', '#f0f0f1', '#ededee', '#e9e9ea']
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
    let speed = 1;

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let color = 0; color < colors.length; color++) {
        ctx.beginPath();
        ctx.moveTo(-200, (canvas.height / 10 + Math.sin(speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.bezierCurveTo(
          canvas.width / 2,
          (canvas.height / 4 + Math.cos(speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width / 4,
          (canvas.height / 4 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width + 200,
          (canvas.height / 3 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.lineWidth = 130 + color * 100 / 2;
        ctx.strokeStyle = colors[color];
        ctx.stroke();
      }
      speed += .5;
    }

    animate();
  }

  closeDropdown(menu) {
    if (this[menu]) this[menu] = false;
  }

  selectedLanguageChange(event) {
    this.language = event;
    this.localService.setJsonValue('language', this.language);
    this.isArabic()
  }

  isArabic() {
    if (this.languagePipe.transform(this.language)) {
      $("body").removeClass('ltr')
      $("body").addClass('rtl').attr("dir", "rtl")

    }
    else {
      $("body").removeClass('rtl')
      $("body").addClass('ltr').attr("dir", "ltr")

    }
  }

  private SetupLanguage() {
    let localLanguage = this.localService.getJsonValue('language');
    let sessionLanguage = this.localService.getJsonValue('language');

    if (!localLanguage && !sessionLanguage) {
      this.language = 'EN';
    }
    else if (localLanguage) {
      this.language = localLanguage;
    }
    else if (sessionLanguage) {
      this.language = sessionLanguage;
    }

    this.languageService.language = this.language;
  }

}
