import { translateText } from "../../../features/survey/survey.component";

/**
 * Updates the HTML representation of a ranking question.
 * @param options - The options for updating the question HTML.
 */
export function updateRankingQuestionHTML(options: any, locale?: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  var commentArea = body.querySelector(".sd-question__comment-area");
  var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
  if (!commentBox)
    commentBox = body?.querySelector(".sd-comment.sd-input");



  body.className = "question-body";


  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-1 text-start";
  inputTitle.innerHTML = translateText('Reorder the following options:', locale);

  body.innerHTML = "";


  if (options.question.value == null || options.question.value.length == 0) {
    options.question.setValue();
  }

  // var max = 300;
  renderChoices(options, commentBox);
  body.insertAdjacentElement('afterbegin', inputTitle);
  // if (commentBox != null)
  // {
  //     //Add character counter
  // var counter = document.createElement("span");
  // counter.className = "text-end text-gray-500 text-sm";
  // counter.style.textAlign = "end";
  // counter.innerHTML = commentBox.value.length + "/" + max;

  // body.appendChild(commentBox);
  // body.appendChild(counter);
  // commentBox.addEventListener("input", function () {
  //   // Update character count on input
  //   counter.innerHTML = commentBox.value.length + "/" + max;
  // });
  // }


}

function renderChoices(options: any, commentBox: any) {
  options.question.value = [...options.question.value];

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  //find sortlist
  var sortlist = document.getElementById("sortlist");
  if (sortlist) {
    document.getElementById("sortlist").remove();
  }

  var ul = document.createElement("ul");
  ul.id = "sortlist";
  ul.innerHTML = "";

  var choices = options.question.value;

  for (let i = 0; i < choices.length; i++) {
    var item = document.createElement("li");
    item.className = "p-2 rounded-md bg-zen-littlegray mb-4";
    item.style.cursor = "pointer";

    //
    var itemContent = document.createElement("div");
    itemContent.className = "flex justify-between px-1";

    item.appendChild(itemContent);

    //
    var contentLeft = document.createElement("div");
    contentLeft.className = "flex items-center text-center";

    var dragIcon = document.createElement("i");
    dragIcon.className = "far fa-grip-dots-vertical w-5 h-5 text-zen-primarydark text-base mb-1";

    var itemIndex = document.createElement("span");
    itemIndex.className = "w-6 text-zen-primarydark font-medium text-sm text-center";
    itemIndex.innerHTML = i + 1 + "";

    var itemText = document.createElement("span");
    itemText.className = "item-text text-sm text-zen-primarydark";
    itemText.style.marginInlineStart = "0.5rem";
    itemText.innerHTML = options.question.visibleChoices.find((c: any) => c.value == choices[i]).text;
    itemText.id = options.question.visibleChoices.find((c: any) => c.value == choices[i]).id;

    contentLeft.appendChild(dragIcon);
    contentLeft.appendChild(itemIndex);
    contentLeft.appendChild(itemText);

    //
    var contentRight = document.createElement("div");
    contentRight.className = "flex items-center gap-2";

    var upIcon = document.createElement("i");
    upIcon.className = "far fa-circle-chevron-up w-5 h-5 text-zen text-xl";
    upIcon.onclick = function () {
      //move the item up
      var temp = options.question.value[i];
      options.question.value[i] = options.question.value[i - 1];
      options.question.value[i - 1] = temp;

      //update the UI
      // let items = ul.getElementsByTagName("li"), current = null;
      // console.log(items);
      // var order = [];
      // for (var it = 0; it < items.length; it++) {
      //   var itemText = items[it].getElementsByClassName("item-text")[0];
      //   order.push(itemText.id);
      // }
      // console.log(order);
      // options.question.value = order;
      // console.log(options.question.value);
      renderChoices(options, commentBox);
    };

    var downIcon = document.createElement("i");
    downIcon.className = "far fa-circle-chevron-down w-5 h-5 text-zen text-xl";
    downIcon.onclick = function () {
      //move the item down
      var temp = options.question.value[i];
      options.question.value[i] = options.question.value[i + 1];
      options.question.value[i + 1] = temp;

      //print the current order
      // let items = ul.getElementsByTagName("li"), current = null;
      // var order = [];
      // for (var it = 0; it < items.length; it++) {
      //   var itemText = items[it].getElementsByClassName("item-text")[0];
      //   order.push(itemText.id);
      // }
      // options.question.value = order;
      renderChoices(options, commentBox);
    };

    //
    if (i == 0) {
      contentRight.appendChild(downIcon);
    } else if (i == options.question.visibleChoices.length - 1) {
      contentRight.appendChild(upIcon);
    } else {
      contentRight.appendChild(upIcon);
      contentRight.appendChild(downIcon);
    }

    //
    itemContent.appendChild(contentLeft);
    itemContent.appendChild(contentRight);

    ul.appendChild(item);
  }

  var textArea = body.querySelector('textarea')
  if (textArea)
    body.insertBefore(ul, textArea);
  else body.appendChild(ul);

  slist(document.getElementById("sortlist"), options, commentBox);

  var max = 300;

  if (commentBox != null) {
    commentBox.setAttribute("maxLength", max);

    //Add character counter
    body.insertAdjacentElement('beforeend', commentBox)
    var counter = body?.querySelector('.comment-counter');
    if (!counter) {
      counter = document.createElement("span");
      counter.className = "text-end text-gray-500 text-sm comment-counter";
      counter.style.textAlign = "end";
      counter.innerHTML = commentBox.value.length + "/" + max;
      body.appendChild(counter);
    }
    body.insertBefore(commentBox, counter);
    commentBox.addEventListener("input", function () {
      // Update character count on input
      counter.innerHTML = commentBox.value.length + "/" + max;
    });
  }
}

function slist(target, options, commentBox) {
  // (A) SET CSS + GET ALL LIST ITEMS
  target.classList.add("slist");
  let items = target.getElementsByTagName("li"), current = null;

  // (B) MAKE ITEMS DRAGGABLE + SORTABLE
  for (let i of items) {
    // (B1) ATTACH DRAGGABLE
    i.draggable = true;

    // (B2) DRAG START - YELLOW HIGHLIGHT DROPZONES
    i.ondragstart = e => {
      current = i;
      for (let it of items) {
        if (it != current) { it.classList.add("hint"); }
      }
    };

    // (B3) DRAG ENTER - RED HIGHLIGHT DROPZONE
    i.ondragenter = e => {
      if (i != current) { i.classList.add("active"); }
    };

    // (B4) DRAG LEAVE - REMOVE RED HIGHLIGHT
    i.ondragleave = () => i.classList.remove("active");

    // (B5) DRAG END - REMOVE ALL HIGHLIGHTS
    i.ondragend = () => {
      for (let it of items) {
        it.classList.remove("hint");
        it.classList.remove("active");
      }
    };

    // (B6) DRAG OVER - PREVENT THE DEFAULT "DROP", SO WE CAN DO OUR OWN
    i.ondragover = e => e.preventDefault();

    // (B7) ON DROP - DO SOMETHING
    i.ondrop = e => {
      e.preventDefault();
      if (i != current) {
        let currentpos = 0, droppedpos = 0;
        for (let it = 0; it < items.length; it++) {
          if (current == items[it]) { currentpos = it; }
          if (i == items[it]) { droppedpos = it; }
        }
        if (currentpos < droppedpos) {
          i.parentNode.insertBefore(current, i.nextSibling);
        } else {
          i.parentNode.insertBefore(current, i);
        }
      }

      //print the current order
      var order = [];
      for (var it = 0; it < items.length; it++) {
        var itemText = items[it].getElementsByClassName("item-text")[0];
        order.push(itemText.id);
      }
      options.question.value = order;
      renderChoices(options, commentBox);
    };
  }
}
