import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { CreatorBase } from 'survey-creator-core';

@Component({
  selector: 'app-logic-properties',
  templateUrl: './logic-properties.component.html',
  styleUrls: ['./logic-properties.component.scss']
})
export class LogicPropertiesComponent implements OnInit {

  ModuleNames = ModuleName;
  @Input() language: string = "EN";

  @Input() model!: CreatorBase;

  @Input() showQuestionLogic: boolean = false;
  showQuestionLogicConditions: { type: string, question: string, operation: string, answer?: string, isTextAnswer?: boolean }[] = [];
  @Input('showQuestionLogicConditions') set logicConditions(conditions) {
    this.showQuestionLogicConditions = conditions.map(x => {
      if (x.question)
        x.options = this.getAvailableOperations(x.question)
      if (x.operation)
        x.answerOptions = this.getOptions(x.question);

      return x;
    });

  }

  @Output() showQuestionLogicChange = new EventEmitter<boolean>();
  @Output() showQuestionLogicConditionsChange = new EventEmitter<any>();

  availableQuestions: any[] = [];

  operations: any[] = [
    { key: "empty", value: "Empty" },
    { key: "notempty", value: "Not empty" },
    { key: "=", value: "Equals" },
    { key: "<>", value: "Does not equal" },
    { key: "contains", value: "Contains" },
    { key: "notcontains", value: "Does not contain" },
    { key: ">", value: "Greater than" },
    { key: "<", value: "Less than" },
    { key: ">=", value: "Greater than or equal to" },
    { key: "<=", value: "Less than or equal to" }
  ];

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    const questions = this.model.survey.getAllQuestions();
    const selectedElementNumber = questions.findIndex(x => x.name == this.model.selectedElement["name"]) + 1;

    questions.forEach((question: any, index) => {
      var questionNumber = index + 1;

      if (question.name != this.model.selectedElement['name'] && questionNumber < selectedElementNumber) {
        //Hide file, matrix and ranking questions because they don't have any available operations
        if (question.getType() != "file" && question.getType() != "matrix" && question.getType() != "ranking" && question.dateType != "range")
          this.availableQuestions.push({ key: question.name, value: question });
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  isTextAnswer(questionName: any) {
    var q = this.model.survey.getQuestionByName(questionName);
    return q.getType() == "text" || q.getType() == "comment";
  }

  getOptions(questionName: any) {
    var q = this.model.survey.getQuestionByName(questionName);

    var choices = [];

    switch (q?.getType()) {
      case "rating":
        for (var i = q['rateMin']; i <= q['rateMax']; i++) {
          choices.push({ key: i, value: i });
        }
        break;

      case "radiogroup":
      case "checkbox":
      case "dropdown":
      case "tagbox":
      case "ranking":
      case "imagepicker":
        q['choices'].forEach((choice: any) => {
          choices.push({ key: choice.id, value: choice.id });
        });
        break;

      case "boolean":
        choices = [
          { key: "true", value: q['labelTrue'] },
          { key: "false", value: q['labelFalse'] }
        ];
        break;
    }

    return choices;
  }

  selectQuestion(event: any, index: number, condition: any) {

    this.showQuestionLogicConditions[index].question = event;
    condition.options = this.getAvailableOperations(event)
    this.showQuestionLogicConditionsChange.emit(this.showQuestionLogicConditions);
  }

  selectOperation(event: any, index: number, condition: any) {
    this.showQuestionLogicConditions[index].operation = event;
    condition.answerOptions = this.getOptions(condition.question);
    this.showQuestionLogicConditionsChange.emit(this.showQuestionLogicConditions);
  }

  selectAnswer(event: any, index: number, questionName: any) {
    var q = this.model.survey.getQuestionByName(questionName);

    var isTextAnswer = q.getType() == "text" || q.getType() == "comment";

    switch (q.getType()) {
      case "text":
      case "comment":
      case "rating":
      case "radiogroup":
      case "dropdown":
      case "boolean":
        isTextAnswer = true;
        break;

      case "checkbox":
      case "tagbox":
      case "imagepicker":
        isTextAnswer = false;
        break;
    }

    this.showQuestionLogicConditions[index].isTextAnswer = isTextAnswer;
    this.showQuestionLogicConditions[index].answer = event;

    this.showQuestionLogicConditionsChange.emit(this.showQuestionLogicConditions);
  }

  showAnswerBox(index: number) {
    var condition = this.showQuestionLogicConditions[index];

    return condition.operation != null &&
      condition.operation != "" &&
      condition.operation != "empty" &&
      condition.operation != "notempty";
  }

  addMainCondition() {
    this.showQuestionLogicConditions = [];
    this.showQuestionLogicConditions.push({ type: "Main", question: null, operation: null, answer: null, isTextAnswer: null });

    this.showQuestionLogicConditionsChange.emit(this.showQuestionLogicConditions);
  }

  addAndCondition() {
    this.showQuestionLogicConditions.push({ type: "And", question: null, operation: null, answer: null, isTextAnswer: null });

    this.showQuestionLogicConditionsChange.emit(this.showQuestionLogicConditions);
  }

  addOrCondition() {
    this.showQuestionLogicConditions.push({ type: "Or", question: null, operation: null, answer: null, isTextAnswer: null });

    this.showQuestionLogicConditionsChange.emit(this.showQuestionLogicConditions);
  }

  removeCondition(index: number) {
    this.showQuestionLogicConditions.splice(index, 1);

    this.showQuestionLogicConditionsChange.emit(this.showQuestionLogicConditions);
  }

  getAvailableOperations(questionName: string) {
    console.log("questionName: ", questionName);
    var q = this.model.survey.getQuestionByName(questionName);
    console.log(q);
    var type = q.getType();

    var availableOperations = [];

    switch (type) {
      case "text":
      case "comment":
        availableOperations = this.operations;
        break;

      case "rating":
        availableOperations = [
          { key: "=", value: "Equals" },
          { key: "<>", value: "Does not equal" },
          { key: ">", value: "Greater than" },
          { key: "<", value: "Less than" },
          { key: ">=", value: "Greater than or equal to" },
          { key: "<=", value: "Less than or equal to" }
        ];
        break;

      case "radiogroup":
      case "checkbox":
      case "dropdown":
      case "tagbox":

        if (!q.isMultiSelect)
          availableOperations = [{ key: "=", value: "Equals" },
          { key: "<>", value: "Does not equal" }];
        else
          availableOperations = [
            { key: "contains", value: "Contains" },
            { key: "notcontains", value: "Does not contain" }
          ];

        break;

      case "imagepicker":

        switch (q.isMultiSelect) {
          case true:
            availableOperations = [
              { key: "contains", value: "Contains" },
              { key: "notcontains", value: "Does not contain" }
            ];
            break;

          case false:
            availableOperations = [
              { key: "=", value: "Equals" },
              { key: "<>", value: "Does not equal" },
            ];
            break;
        }
        break;

      case "boolean":
        availableOperations = [
          { key: "empty", value: "Empty" },
          { key: "notempty", value: "Not empty" },
          { key: "=", value: "Equals" },
          { key: "<>", value: "Does not equal" }
        ]
        break;

      case "file":
      case "matrix":
      case "ranking":
        availableOperations = []
        break;
    }

    return availableOperations;
  }

  logData(x) {
    console.log(x);
  }
}
