<div *ngIf="selected" (click)="logData(translations)" class="flex flex-col gap-2 w-full relative minw-44">
    <h5 *ngIf="label || labelTemplate" class="text-xs text-zen font-medium uppercaseLetter">
        <span *ngIf="!labelTemplate">{{ label }}</span>
        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>

        <i *ngIf="required" class="fa-regular fa-asterisk text-3xs transform -translate-y-1"></i>
    </h5>
    <div class="flex flex-row gap-px whitespace-normal">
        <div [class]="classList">
            <angular-editor #field="ngModel" [config]="editorConfig" [placeholder]="placeholder?placeholder:'text here…'" name="translationEditor" class=" w-full px-5 ModifiedClass" [(ngModel)]="selected.name" (ngModelChange)="handleChange($event)">
            </angular-editor>
            <div class="flex flex-row items-center justify-end px-5 absolute w-full h-0 top-20">
              <i (click)="translationsBK=getJsonSting(translations);openModal=true"  class="fa-light z-10 fa-globe w-8 h-8 leading-8  text-opacity-60 text-base  rounded btn  text-center btn"></i>
            </div>
        </div>
    </div>
</div>
<div class="h-0">
    <div *ngIf="invalid" class="flex flex-row justify-between items-center">
        <div class="alert alert-danger transform translate-y-1">
            <validation-required [language]="language" *ngIf="validateName(translations) && required && invalid">
            </validation-required>
        </div>
    </div>
</div>
<zen-translation-modal [(open)]="openModal" [editor]="true" [editorConfig]="editorConfig" [language]="language" [translations]="translationsBK"  (translationsChanges)="getTranslationsChanges($event)"></zen-translation-modal>
