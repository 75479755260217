import { translateText } from "../../../features/survey/survey.component";

/**
 * Updates the HTML for a file upload question.
 * @param options - The options object containing the HTML element.
 */
export function updateFileUploadQuestionHTML(options: any, locale: any) {

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  body.className = "question-body pb-8 mb-8";

  var commentArea = body?.querySelector(".sd-question__comment-area");
  var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
  if (!commentBox)
    commentBox = body?.querySelector(".sd-comment.sd-input");
  // Create input title element
  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-3 text-start";

  if (options.question.allowMultiple) {
    inputTitle.innerHTML = translateText("Upload files:", locale);
  } else {
    inputTitle.innerHTML = translateText("Upload file:", locale);
  }

  const originalFileInput = body.querySelector('input[type="file"]');
  if (options.question.jsonObj?.acceptedTypes?.length)
    originalFileInput.accept = options.question.jsonObj.acceptedTypes[0];
  originalFileInput.style.display = "none";
  body.innerHTML = "";
  body.appendChild(inputTitle);
  body.appendChild(originalFileInput);

  // Create main container for file upload area
  var mainContainer = document.createElement("div");
  mainContainer.className = "flex flex-col items-center justify-between h-auto py-3 px-2 w-full border-2 border-dashed border-gray-300 rounded-lg";

  // Create placeholder container
  var placeholderContainer = document.createElement("div");
  placeholderContainer.className = "flex items-center justify-center gap-2";

  // Create placeholder text element
  var placeholderText = document.createElement("p");
  placeholderText.className = "placeholder-text";
  placeholderText.innerHTML = translateText("Drag and drop a file here...", locale);

  // Create select file button
  var selectFileButton = document.createElement("button");
  selectFileButton.className = "btn btn-primary select-file-button px-3 py-2";
  selectFileButton.innerHTML = translateText("Select File", locale);
  selectFileButton.onclick = function () {
    originalFileInput.click();
  };

  placeholderContainer.appendChild(placeholderText);

  // Create container for upload file section
  var uploadFileContainer = document.createElement("div");
  uploadFileContainer.className = "flex items-center gap-2 justify-between w-full";

  uploadFileContainer.appendChild(placeholderContainer);
  uploadFileContainer.appendChild(selectFileButton);

  mainContainer.appendChild(uploadFileContainer);

  body.appendChild(mainContainer);
  var max = 300;

  if (commentBox != null) {
    commentBox.setAttribute("maxLength", max);
    commentBox.className = 'sd-comment sd-input mt-5';
    var counter = body?.querySelector('.comment-counter');
    if (!counter) {
      counter = document.createElement("span");
      counter.className = "text-end text-gray-500 text-sm comment-counter";
      counter.style.textAlign = "end";
      counter.innerHTML = commentBox.value.length + "/" + max;
      body.appendChild(counter);
    }
    body.insertBefore(commentBox, counter);
    commentBox.addEventListener("input", function () {
      // Update character count on input
      counter.innerHTML = commentBox.value.length + "/" + max;
    });
  }

  options.question.onValueChanged = function () {
    onFilesValueChange(options, mainContainer, originalFileInput, uploadFileContainer, locale);
  };

  onFilesValueChange(options, mainContainer, originalFileInput, uploadFileContainer, locale);
}

function onFilesValueChange(options: any, mainContainer: any, originalFileInput: any, uploadFileContainer: any, locale) {
  if (options.question.value == null) {
    return;
  }

  var files = options.question.value;

  if (files.length > 0) {
    mainContainer.innerHTML = "";
  }

  var filesContainer = document.createElement("div");
  filesContainer.className = "flex flex-col gap-2 w-full";

  for (var i = 0; i < files.length; i++) {
    var fileContainer = document.createElement("div");
    fileContainer.className = "flex flex-row justify-between items-center gap-2 w-full";

    // Log file information
    var file = files[i];

    var fileInfoContainer = document.createElement("div");
    fileInfoContainer.className = "flex items-start gap-3 items-center mb-2 sm:mb-0";

    var fileImg: any = document.createElement("i");
    fileImg.className = "fa-light fa-file file-icon";
    if (file.type.indexOf('image') !== -1) {
      fileImg = document.createElement("img");
      fileImg.className = "w-20 h-20 object-center object-cover"
      fileImg.src = file.content;
    }

    var fileName = document.createElement("p");
    fileName.className = "uploaded-file-text w-20 sm:w-auto truncate";
    fileName.innerHTML = file.name;


    var actionsContainer = document.createElement("div");
    actionsContainer.className = "flex items-center gap-3";

    var reuploadFileButton = document.createElement("button");
    reuploadFileButton.className = "btn btn-primary select-file-button px-3 py-2";
    reuploadFileButton.innerHTML = translateText("Reupload File", locale);
    reuploadFileButton.onclick = function () {
      originalFileInput.click();
    };

    var trashIcon = document.createElement("i");
    trashIcon.className = "fa-light fa-trash cursor-pointer delete-file-icon";

    (function (i) {
      trashIcon.onclick = function () {
        if (options.question.allowMultiple) {
          if (options.question.value.length == 1) {
            options.question.clearValue();
            updateFileUploadQuestionHTML(options, locale);
          } else {
            files.splice(i, 1);
            options.question.value = files;
            onFilesValueChange(options, mainContainer, originalFileInput, uploadFileContainer, locale);
          }
        } else {
          options.question.clearValue();
          updateFileUploadQuestionHTML(options, locale);
        }
      }
    })(i);

    fileInfoContainer.appendChild(fileImg);
    fileInfoContainer.appendChild(fileName);
    if (!options.question.allowMultiple)
      actionsContainer.appendChild(reuploadFileButton);
    actionsContainer.appendChild(trashIcon);

    fileContainer.appendChild(fileInfoContainer);
    fileContainer.appendChild(actionsContainer);

    filesContainer.appendChild(fileContainer);
  }

  mainContainer.appendChild(filesContainer);

  if (options.question.allowMultiple) {
    mainContainer.appendChild(uploadFileContainer);
  }
}
