<z-modal [open]="true" autoWidth="true" (click)="logData(exportableCharts)">
  <ng-container body>
    <div class="flex flex-row gap-5 -mb-10 -mt-10 max-h-screen-90 h-screen overflow-hidden max-w-7xl">
      <div class="flex flex-col items-stretch w-export w-4/5">
        <div class="max-h-screen-90 block  innerListScroll  portrait" id="pdf">
          <!-- front page -->
          <ng-container *ngIf="exportReport">
            <div class="innerPage relative overflow-hidden">
              <div
                class="absolute bottom-0 top-0 left-0 right-0 z-50  flex flex-col w-full items-stretch justify-between bg-white p-10">
                <div class="flex flex-col items-stretch justify-between">
                  <div class="flex flex-row justify-between gap-10">
                    <h6 class="text-zen text-sm uppercase font-semibold text-opacity-50">
                      {{ "txtConfidential" | translate:language:ModuleNames.Wellbeing }}
                    </h6>
                    <img class="h-28 max-w-xs"
                      [src]="selectedEntity?.logoURL? selectedEntity?.logoURL: 'assets/img/no-logo.png'" alt="">
                  </div>
                  <div class="flex flex-col gap-20">
                    <div class="mt-12 flex flex-col gap-2 uppercase" [ngClass]="{'flex-col-reverse': language == 'AR'}">
                      <h1 class="text-zen text-5xl font-medium">{{ "txtEmployeeWellbeing" |
                        translate:language:ModuleNames.Wellbeing }}</h1>
                      <h5 class="text-zen text-5xl">{{ "txt_report" | translate:language:ModuleNames.Wellbeing }} </h5>
                    </div>
                    <div class="flex flex-col gap-2">
                      <h6 class="text-zen-lite text-xl">{{ "txtfor" |
                        translate:language:ModuleNames.Wellbeing }}</h6>
                      <h1 class="text-zen text-xl font-semibold">{{ selectedEntity.name| extract:language }}
                      </h1>
                      <h5 class="text-zen-lite text-xl">{{date | date: 'dd/MM/yyyy'}}</h5>
                    </div>

                  </div>

                </div>

                <div class="flex flex-col gap-1">
                  <p class="text-xs text-zen text-opacity-50">{{ "txtPoweredby" |
                    translate:language:ModuleNames.Wellbeing }}</p>
                  <img class="h-10 w-36 object-contain" src="assets/img/logo.png" alt="">
                </div>
              </div>
            </div>
            <!-- table of content -->
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-5 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl">{{ "txttableofcontents" |
                  translate:language:ModuleNames.Wellbeing }}
                </h1>
                <!-- content -->
                <div class="flex flex-col gap-1" *ngIf="filteredTableOfContent?.length">
                  <div class="flex flex-col" *ngFor="let content of filteredTableOfContent; let index = index">

                    <div class="flex flex-col items-stretch rounded bg-zen bg-opacity-5" *ngIf="content.dispaly">
                      <div
                        class="flex flex-row items-center justify-between px-5 font-medium text-sm text-zen leading-10">
                        <div class="flex flex-row items-center gap-3">
                          <span class="text-exp">{{index + 1}}. </span>
                          <span>{{!content.titleExtract? (content.title |
                            translate:language:ModuleNames.Wellbeing):(content.title |
                            extract:language) }}</span>
                        </div>
                        <span>{{content.content > 1?content.page - content.content + 1:
                          content.page}}</span>
                      </div>
                      <ng-container *ngIf="content?.hideChildren != true">
                        <div class="flex flex-col pb-2" *ngIf="content?.children?.length">
                          <ng-container *ngFor="let childContent of content.children; let childIndex = index">
                            <div *ngIf="!childContent.hide"
                              class="flex flex-row items-center justify-between px-5 font-medium text-xs text-zen-lite leading-9 ">
                              <div class="flex flex-row items-center gap-3 px-6">
                                <span>{{index + 1}}.{{childIndex + 1}} </span>
                                <span>{{!childContent.titleExtract?
                                  (childContent.title
                                  |translate:language:ModuleNames.Wellbeing):(childContent.title
                                  | extract:language) }}</span>
                              </div>
                              <span class="text-sm text-zen">{{childContent.content >
                                1?childContent.page - childContent.content + 1:
                                childContent.page}}</span>
                            </div>
                          </ng-container>

                        </div>
                      </ng-container>

                    </div>
                  </div>


                </div>
              </div>
            </div>
            <!-- introduction -->
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-10 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">1.
                  </span>{{ "txtIntroduction" | translate:language:ModuleNames.Wellbeing }}
                </h1>
                <!-- content -->
                <div class="flex flex-col items-start justify-between">
                  <div class="flex flex-col gap-2">
                    <div class="text-zen text-sm leading-5"
                      [innerHtml]="'txtIntroductionpara' | translate:language:ModuleNames.Wellbeing">
                    </div>
                    <div class="text-zen text-sm leading-5"
                      [innerHtml]="'txtobjectives' | translate:language:ModuleNames.Wellbeing">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-8 flex flex-col">
                <h4 class="text-zen text-xl font-medium">
                  {{ 'txtZenWellbeingDimensions' | translate:language:ModuleNames.Wellbeing }}
                </h4>
                <div class="flex flex-col">
                  <div class="text-zen text-sm leading-5"
                    [innerHTML]="'txtZenWellbeingDimensionsPara' | translate:language:ModuleNames.Wellbeing">
                  </div>
                  <div>
                    <img class="svgWidth pt-10 border border-white"
                      [src]="'assets/img/employee-wellbeing/dimensions_'+ this.language +'.png'" alt="">
                  </div>


                </div>
              </div>
            </div>
            <!-- 2 -->
            <!-- how to use this report -->
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-12 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">2.
                  </span>{{ 'txtHowtousethisreport' | translate:language:ModuleNames.Wellbeing }}
                </h1>
                <!-- content -->
                <div class="text-sm text-zen-lite"
                  [innerHtml]="('txtHowtousethisreportpara'| translate:language:ModuleNames.Wellbeing)">
                </div>
                <div class="flex flex-col gap-6">
                  <h5 class="text-lg text-zen">{{ 'txtResultsRating' |
                    translate:language:ModuleNames.Wellbeing }}
                  </h5>
                  <div class="flex flex-col gap-2 text-zen">
                    <div class="flex flex-row items-center gap-2" *ngFor="let scale of scaleList">
                      <span class="h-3 w-4 rounded-sm" [style.background]="scale.color"></span>
                      <h4 class="text-sm" [innerHtml]='scale.key | translate:language:ModuleNames.Wellbeing'>
                      </h4>
                    </div>
                  </div>



                </div>
                <div class="flex flex-col gap-6">
                  <h5 class="text-lg text-zen">{{ 'txtCalculationProcess' |
                    translate:language:ModuleNames.Wellbeing }}</h5>
                  <div class="flex flex-col gap-2 text-zen">
                    <div class="flex flex-row items-start gap-2"
                      *ngFor="let process of calculationProcess; let n = index">
                      <span
                        class="minw5 text-white bg-exp leading-5 text-center rounded-full w-5 h-5 font-semibold text-xs">{{n+
                        1}}</span>
                      <p class="text-sm">{{ ('txtCalculationProcess'+ (n+ 1)) |
                        translate:language:ModuleNames.Wellbeing }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 3 -->
            <!-- overall workplace  -->
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-8 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">3.
                  </span>{{ 'txtOverallWorkplaceWellbeing' | translate:language:ModuleNames.Wellbeing }}
                </h1>
                <!-- content -->
                <div class="text-sm text-zen"
                  [innerHtml]="('txtOverallWorkplaceWellbeingpara1'| translate:language:ModuleNames.Wellbeing).replaceAll('[Company Name]',selectedEntity.name| extract:language)">
                </div>
                <div class="flex flex-col gap-6">
                  <div class="flex flex-row gap-2 text-zen justify-between text-xs">
                    <div class="flex flex-row items-center text-xxs text-zen font-medium gap-2.5"
                      *ngFor="let scale of scaleList">
                      <span class="w-4 h-3 rounded-sm" [style.background]="scale.color"></span>
                      <span [innerHtml]='scale.key | translate:language:ModuleNames.Wellbeing'></span>
                    </div>
                  </div>

                  <div class="flex flex-col items-stretch">
                    <div class="flex flex-row justify-center py-6 border border-zen border-opacity-10 rounded"
                      style="margin: auto; width: 100%;">
                      <kendo-arcgauge [color]="wellbeingScalesService.resolveChartColor(overallFactorScore)"
                        [value]="overallFactorScore" style="display:block;height:180px; width:180px">
                        <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450" [rangeSize]="25"
                          [rangeLineCap]="'butt'">
                          <ng-template kendoArcGaugeCenterTemplate let-value="value">
                            <p class="text-zen font-bold text-xl">{{overallFactorScore |
                              number:"1.1-1"}}%</p>
                            <p class="text-zen font-medium text-sm">{{'txtOverall'|
                              translate:language:ModuleNames.Wellbeing}}</p>
                          </ng-template>
                        </kendo-arcgauge-scale>
                      </kendo-arcgauge>
                    </div>
                  </div>
                  <h5 class="text-zen font-semibold text-sm">{{ "txt_dimensions_score" |
                    translate:language:ModuleNames.Wellbeing }}</h5>
                  <div class="block relative  -mr-2 -ml-2">
                    <div class="inline-block pt-2 w-1/3 px-1 pb-2"
                      *ngFor="let dimension of factors | slice:0:6; let i = index">
                      <div class="flex flex-col items-center  border border-zen border-opacity-10 rounded py-8">
                        <kendo-arcgauge [value]="dimension.factor.score"
                          [color]="wellbeingScalesService.resolveChartColor(dimension.factor.score)"
                          class="driver-gauge-chart" [transitions]="true" style="height: 80px;">
                          <kendo-arcgauge-scale [startAngle]="0" [endAngle]="180" [rangeSize]="18"
                            [rangeLineCap]="'butt'">
                            <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                              <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                <p class="text-zen font-bold text-xl">{{
                                  dimension.factor.score | number:'1.1-1' }}%</p>
                              </ng-template>
                            </kendo-arcgauge-scale-labels>
                          </kendo-arcgauge-scale>
                        </kendo-arcgauge>
                        <p class="text-zen font-semibold text-sm text-center"> {{dimension.factor.name |
                          extract:language}}</p>
                        <p class="font-semibold text-xxs"
                          [ngStyle]="{ 'color': wellbeingScalesService.resolveChartColor(dimension.factor.score) }"
                          [innerHtml]='wellbeingScalesService.getExperienceCode(dimension.factor.score) | translate:language:ModuleNames.Wellbeing'>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- extra -->
            <div class="innerPage break-before bg-white" *ngIf="factors?.length > 6">
              <div class="exportContainer py-20 px-10 gap-8 flex flex-col">
                <div class="flex flex-col gap-6">
                  <div class="block relative  -mr-2 -ml-2">
                    <div class="inline-block pt-2 w-1/3 px-1 pb-2"
                      *ngFor="let dimension of factors| slice:6; let i = index">
                      <div class="flex flex-col items-center  border border-zen border-opacity-10 rounded py-8">
                        <kendo-arcgauge [value]="dimension.factor.score"
                          [color]="wellbeingScalesService.resolveChartColor(dimension.factor.score)"
                          class="driver-gauge-chart" [transitions]="true" style="height: 80px;">
                          <kendo-arcgauge-scale [startAngle]="0" [endAngle]="180" [rangeSize]="18"
                            [rangeLineCap]="'butt'">
                            <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                              <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                <p class="text-zen font-bold text-xl">{{
                                  dimension.factor.score | number:'1.1-1' }}%</p>
                              </ng-template>
                            </kendo-arcgauge-scale-labels>
                          </kendo-arcgauge-scale>
                        </kendo-arcgauge>
                        <p class="text-zen font-semibold text-sm text-center"> {{dimension.factor.name |
                          extract:language}}</p>
                        <p class="font-semibold text-xxs"
                          [ngStyle]="{ 'color': wellbeingScalesService.resolveChartColor(dimension.factor.score) }"
                          [innerHtml]='wellbeingScalesService.getExperienceCode(dimension.factor.score) | translate:language:ModuleNames.Wellbeing'>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- 3.2. Employee Wellbeing Index  -->
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-8 flex flex-col">
                <h1 class="text-zen font-semibold text-lg"><span class="text-exp">3.2
                  </span>{{ 'txtEmployeesWellbeingIndex' | translate:language:ModuleNames.Wellbeing }}
                </h1>
                <!-- content -->

                <div class="text-sm text-zen"
                  [innerHtml]="('txtEmployeesWellbeingIndexpara'| translate:language:ModuleNames.Wellbeing).replaceAll('[Company Name]',selectedEntity.name| extract:language)">
                </div>
                <div class="block relative  -mr-2 -ml-2">
                  <div class="inline-block pt-2 w-1/3 px-1 pb-8"
                    *ngFor="let wellbeingOverallIndexByScale of wellbeingOverallIndexByScales">
                    <div class="flex flex-col items-center  border border-zen border-opacity-10 rounded py-5">
                      <div class="svg-container">
                        <kendo-arcgauge
                          [color]="wellbeingScalesService.getColorByCode(wellbeingOverallIndexByScale.scale)"
                          [value]="wellbeingOverallIndexByScale.mean"
                          style="display:block; height:140px; width: 140px;">
                          <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450"
                            [rangePlaceholderColor]="wellbeingScalesService.getColorByCode(wellbeingOverallIndexByScale.scale)+ '60'"
                            [rangeSize]="18" [rangeLineCap]="'butt'">
                            <ng-template kendoArcGaugeCenterTemplate let-value="value">
                              <p class="text-xl text-zen font-medium">{{wellbeingOverallIndexByScale.mean |
                                number:'1.1-1'}}%
                              </p>
                            </ng-template>
                          </kendo-arcgauge-scale>
                        </kendo-arcgauge>
                      </div>
                    </div>
                    <h6 class="uppercase text-center leading-5 pt-6 text-xs font-semibold"
                      [style.color]="wellbeingScalesService.getColorByCode(wellbeingOverallIndexByScale.scale)"
                      [innerHtml]='wellbeingOverallIndexByScale.scale | translate:language:ModuleNames.Wellbeing'>
                    </h6>
                  </div>
                </div>
              </div>
            </div>


            <!-- 4.  Workplace Wellbeing dimensions  -->
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-8 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl">
                  <span class="text-exp">4. </span>
                  {{ 'txtDimensionsOfWorkplaceWellbeing' |translate:language:ModuleNames.Wellbeing }}
                </h1>
                <!-- content -->
                <div class="flex flex-col gap-10">
                  <div class="text-sm text-zen"
                    [innerHtml]="('txtDimensionsofWorkplaceWellbeingDesc' | translate:language:ModuleNames.Wellbeing).replaceAll('[Dimensions Count]', wellbeingIndexByDimensions?.length).replaceAll('[Company Name]', selectedEntity.name | extract: language)">
                  </div>

                  <div class="block relative  -mr-2 -ml-2">
                    <div class="inline-block pt-2 w-1/3 px-1 pb-2" *ngFor="let factor of factors; let i = index">
                      <div class="flex flex-col items-center  border border-zen border-opacity-10 rounded py-8">
                        <kendo-arcgauge [value]="factor.factor.score"
                          [color]="wellbeingScalesService.resolveChartColor(factor.factor.score)"
                          class="driver-gauge-chart" [transitions]="true" style="height: 80px;">
                          <kendo-arcgauge-scale [startAngle]="0" [endAngle]="180" [rangeSize]="18"
                            [rangeLineCap]="'butt'">
                            <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                              <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                <p class="text-zen font-bold text-xl">{{factor.factor.score | number:'1.1-1' }}%</p>
                              </ng-template>
                            </kendo-arcgauge-scale-labels>
                          </kendo-arcgauge-scale>
                        </kendo-arcgauge>
                        <p class="text-zen font-semibold text-sm text-center"> {{factor.factor.name | extract:language}}
                        </p>
                        <p class="font-semibold text-xxs"
                          [ngStyle]="{ 'color': wellbeingScalesService.resolveChartColor(factor.factor.score) }"
                          [innerHtml]='wellbeingScalesService.getExperienceCode(factor.factor.score) | translate:language:ModuleNames.Wellbeing'>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>


            <ng-container *ngFor="let dimension of factors; let i = index">
              <ng-container *ngIf="dimension.isPredefined">
                <div *DemoAccount="i > 0?'Report':'Default'">
                  <div class="innerPage break-before bg-white">
                    <div class="exportContainer pt-20 px-10 gap-8 flex flex-col">
                      <h1 class="text-zen font-semibold text-2xl">
                        <span class="text-exp">4.{{i + 1}} </span> {{ dimension.factor.name |extract:language}}
                      </h1>
                      <!-- content -->
                      <div class="flex flex-col gap-10">
                         <div class="block -mr-2 -ml-2">
                            <div class="w-2/3 inline-block px-2">
                            <div class="flex flex-col gap-4">
                              <h5 class="text-zen text-sm"
                                [innerHtml]="dimension.textData.translationKeys.description | translate:language:ModuleNames.Wellbeing">
                              </h5>

                            </div>

                          </div>
                        <div class="w-1/3 inline-block px-5 align-top border border-zen border-opacity-10 rounded py-8">
                            <div class="flex flex-col items-center py-2">
                              <kendo-arcgauge [value]="dimension.factor.score"
                                [color]="wellbeingScalesService.resolveChartColor(dimension.factor.score)"
                                class="driver-gauge-chart" [transitions]="true" style="height: 80px;">
                                <kendo-arcgauge-scale [startAngle]="0" [endAngle]="180" [rangeSize]="18"
                                  [rangeLineCap]="'butt'">
                                  <kendo-arcgauge-scale-labels class="custom-centered-label" format="c"
                                    [visible]="false">
                                    <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                      <p class="text-zen font-bold text-xl">{{
                                        dimension.factor.score | number:'1.1-1' }}%</p>
                                    </ng-template>
                                  </kendo-arcgauge-scale-labels>
                                </kendo-arcgauge-scale>
                              </kendo-arcgauge>
                              <p class="text-zen font-semibold text-sm text-center"> {{dimension.factor.name |
                                extract:language}}</p>
                              <p class="font-semibold text-xxs"
                                [ngStyle]="{ 'color': wellbeingScalesService.resolveChartColor(dimension.factor.score) }"
                                [innerHtml]='wellbeingScalesService.getExperienceCode(dimension.factor.score) | translate:language:ModuleNames.Wellbeing'>
                              </p>
                            </div>

                          </div>
                        </div>
                        <div class="flex flex-col items-stretch">
                          <div [ngClass]="(language| language)?'flex-row-reverse justify-end':'flex-row'"
                            class="flex flex-row items-center gap-1 text-zen font-semibold text-lg pb-5">
                            <h1 class=> {{dimension.factor.name| extract:language}}
                            </h1>
                            <span>{{ "txtIndex" |
                              translate:language:ModuleNames.Wellbeing }}</span>
                          </div>

                          <div class="block">
                            <div class="inline-block w-1/5" *ngFor="let scale of dimension.scales">
                              <kendo-arcgauge [color]="wellbeingScalesService.getColorByCode(scale.scale)"
                                [value]="scale.mean" style="display:block; width:135px; height:135px" renderAs="svg">
                                <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450"
                                  [rangePlaceholderColor]="wellbeingScalesService.getColorByCode(scale.scale)+ '60'"
                                  [rangeSize]="18" [rangeLineCap]="'butt'">
                                  <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                    <span class="font-medium text-zen text-sm">{{scale.mean | number:'1.1-1'}}%</span>
                                  </ng-template>
                                </kendo-arcgauge-scale>
                              </kendo-arcgauge>
                              <h6 class="uppercase text-center leading-5 pt-6 text-xxs font-semibold"
                                [style.color]="wellbeingScalesService.getColorByCode(scale.scale)"
                                [innerHtml]='scale.scale | translate:language:ModuleNames.Wellbeing'>
                              </h6>
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="innerPage break-before bg-white">
                    <div class="exportContainer pt-20 px-10 gap-8 flex flex-col">
                      <h1 class="text-zen font-semibold text-2xl">
                        <span class="text-exp">4.{{i + 1}} </span> {{ dimension.factor.name |extract:language}}
                      </h1>
                      <!-- content -->

                      <div class="flex flex-col items-start gap-4">
                        <h5 class="text-zen text-lg">{{ "txtRecommendations" |
                          translate:language:ModuleNames.Wellbeing }}:</h5>
                        <div class="flex flex-col gap-4">
                          <ng-container
                            *ngFor="let recommendation of dimension.textData.translationKeys.recommendations; let i = index">
                            <div
                              *ngIf="(recommendation| translate:language:ModuleNames.Wellbeing)?.length != recommendation.length"
                              class="flex flex-row items-start text-zen text-sm font-normal gap-1.5">
                              <span
                                class="leading-5 w-5 h-5 text-xs font-semibold rounded-full text-center text-white bg-exp maxMin5">{{i
                                + 1}}</span>
                              <p> {{recommendation| translate:language:ModuleNames.Wellbeing}}</p>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>


          </ng-container>

          <ng-container *ngIf="exportOverview">
            <div class="innerPage break-before bg-white">
              <div class="exportContainer pt-20 px-10 gap-8 flex flex-col">
                <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">{{sectionIndex('overview')}}.
                  </span>{{ 'txtOverview' | translate:language:ModuleNames.Wellbeing }}
                </h1>
                <!-- content -->
                <div class="flex flex-row items-stretch gap-1 text-zen">

                  <div
                    class="flex flex-row items-center justify-between px-5 w-1/3 border border-zen border-opacity-10 rounded-sm h-20 gap-2">
                    <div class="flex flex-row gap-2.5 items-center">
                      <i class="fa-regular fa-reply text-2xl"></i>
                      <h5 class="text-sm font-semibold">{{'txtOverallResponseRate'|
                        translate:language:ModuleNames.Wellbeing}}</h5>
                    </div>
                    <h5 class="text-lg font-semibold">
                      {{responseRate| number:'1.1-1'}}%</h5>
                  </div>

                  <div
                    class="flex flex-row items-center justify-between px-5 w-1/3 border border-zen border-opacity-10 rounded-sm h-20 gap-2">
                    <div class="flex flex-row gap-2.5 items-center">
                      <i class="fa-regular fa-reply text-2xl"></i>
                      <h5 class="text-sm font-semibold">{{'txt_completed'|
                        translate:language:ModuleNames.Wellbeing}}</h5>
                    </div>
                    <h5 class="text-lg font-semibold"> {{totalCompleted}}</h5>
                  </div>

                  <div
                    class="flex flex-row items-center justify-between px-5 w-1/3 border border-zen border-opacity-10 rounded-sm h-20 gap-2">
                    <div class="flex flex-row gap-2.5 items-center">
                      <i class="fa-regular fa-comment-dots text-2xl"></i>
                      <h5 class="text-sm font-semibold">{{'txtComments'|
                        translate:language:ModuleNames.Wellbeing}}</h5>
                    </div>
                    <h5 class="text-lg font-semibold"> {{totalComments}}</h5>
                  </div>

                </div>
                <div class="flex flex-col gap-6">
                  <h1 class="text-zen font-semibold text-lg">{{ 'txt_Workplace_Wellbeing_Score' |
                    translate:language:ModuleNames.Wellbeing }}
                  </h1>
                  <div class="flex flex-col gap-4">
                    <div class="flex flex-row gap-2 text-zen justify-between text-xs">
                      <div class="flex flex-row items-center text-xxs text-zen font-medium gap-2.5"
                        *ngFor="let scale of scaleList">
                        <span class="w-4 h-3 rounded-sm" [style.background]="scale.color"></span>
                        <span [innerHtml]='scale.key | translate:language:ModuleNames.Wellbeing'></span>
                      </div>
                    </div>
                    <div class="flex flex-col items-stretch">
                      <div class="flex flex-row justify-center py-6 border border-zen border-opacity-10 rounded"
                        style="margin: auto; width: 100%;">
                        <kendo-arcgauge [color]="wellbeingScalesService.resolveChartColor(overallFactorScore)"
                          [value]="overallFactorScore" style="display:block;height:180px; width:180px">
                          <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450" [rangeSize]="25"
                            [rangeLineCap]="'butt'">
                            <ng-template kendoArcGaugeCenterTemplate let-value="value">
                              <p class="text-zen font-bold text-xl">{{overallFactorScore |
                                number:"1.1-1"}}%</p>
                              <p class="text-zen font-medium text-sm">{{'txtOverall'|
                                translate:language:ModuleNames.Wellbeing}}</p>
                            </ng-template>
                          </kendo-arcgauge-scale>
                        </kendo-arcgauge>
                      </div>
                    </div>
                    <h5 class="text-zen font-semibold text-sm">{{ "txt_dimensions_score" |
                      translate:language:ModuleNames.Wellbeing }}</h5>
                    <div class="block relative  -mr-2 -ml-2">
                      <div class="inline-block pt-2 w-1/3 px-1 pb-2"
                        *ngFor="let dimension of factors| slice:0:6; let i = index">
                        <div class="flex flex-col items-center  border border-zen border-opacity-10 rounded py-8">
                          <kendo-arcgauge [value]="dimension.factor.score"
                            [color]="wellbeingScalesService.resolveChartColor(dimension.factor.score)"
                            class="driver-gauge-chart" [transitions]="true" style="height: 80px;">
                            <kendo-arcgauge-scale [startAngle]="0" [endAngle]="180" [rangeSize]="18"
                              [rangeLineCap]="'butt'">
                              <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                                <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                  <p class="text-zen font-bold text-xl">{{
                                    dimension.factor.score | number:'1.1-1' }}%</p>
                                </ng-template>
                              </kendo-arcgauge-scale-labels>
                            </kendo-arcgauge-scale>
                          </kendo-arcgauge>
                          <p class="text-zen font-semibold text-sm text-center"> {{dimension.factor.name |
                            extract:language}}</p>
                          <p class="font-semibold text-xxs"
                            [ngStyle]="{ 'color': wellbeingScalesService.resolveChartColor(dimension.factor.score) }"
                            [innerHtml]='wellbeingScalesService.getExperienceCode(dimension.factor.score) | translate:language:ModuleNames.Wellbeing'>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Employee Wellbeing Index  -->
            <div class="innerPage break-before bg-white" *ngIf="factors?.length > 6">
              <div class="exportContainer py-20 px-10 gap-8 flex flex-col">
                <div class="block relative  -mr-2 -ml-2">
                  <div class="inline-block pt-2 w-1/3 px-1 pb-2"
                    *ngFor="let factor of factors | slice:6; let i = index">
                    <div class="flex flex-col items-center  border border-zen border-opacity-10 rounded py-8"
                      *ngIf="factor.factor as dimension">
                      <kendo-arcgauge [value]="dimension.score"
                        [color]="wellbeingScalesService.resolveChartColor(dimension.score)" class="driver-gauge-chart"
                        [transitions]="true" style="height: 80px;">
                        <kendo-arcgauge-scale [startAngle]="0" [endAngle]="180" [rangeSize]="18"
                          [rangeLineCap]="'butt'">
                          <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                            <ng-template kendoArcGaugeCenterTemplate let-value="value">
                              <p class="text-zen font-bold text-xl">{{
                                toFixed(dimension.score,1) }}%</p>
                            </ng-template>
                          </kendo-arcgauge-scale-labels>
                        </kendo-arcgauge-scale>
                      </kendo-arcgauge>
                      <p class="text-zen font-medium text-sm"> {{(dimension.score|number:'1.1-1')+'%'}}</p>
                      <p class="font-medium text-sm"> {{dimension.name | extract:language }} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Employee Wellbeing Index  -->
            <div class="innerPage break-before bg-white">
              <div (click)="logData(wellbeingOverallIndexByScales)"
                class="exportContainer pt-20 px-10 gap-8 flex flex-col">
                <h1 class="text-zen font-semibold text-lg"><span class="text-exp">{{sectionIndex('overview')}}.1
                  </span>{{ 'txtEmployeesWellbeingIndex' | translate:language:ModuleNames.Wellbeing }}
                </h1>
                <!-- content -->


                <div class="block relative  -mr-2 -ml-2">
                  <div class="inline-block pt-2 w-1/3 px-1 pb-8"
                    *ngFor="let wellbeingOverallIndexByScale of wellbeingOverallIndexByScales">
                    <div class="flex flex-col items-center  border border-zen border-opacity-10 rounded py-5">
                      <div class="svg-container">
                        <kendo-arcgauge
                          [color]="wellbeingScalesService.getColorByCode(wellbeingOverallIndexByScale.scale)"
                          [value]="wellbeingOverallIndexByScale.mean"
                          style="display:block; height:140px; width: 140px;">
                          <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450"
                            [rangePlaceholderColor]="wellbeingScalesService.getColorByCode(wellbeingOverallIndexByScale.scale)+ '60'"
                            [rangeSize]="18" [rangeLineCap]="'butt'">
                            <ng-template kendoArcGaugeCenterTemplate let-value="value">
                              <p class="text-xl text-zen font-medium">{{wellbeingOverallIndexByScale.mean |
                                number:'1.1-1'}}%
                              </p>
                            </ng-template>
                          </kendo-arcgauge-scale>
                        </kendo-arcgauge>
                      </div>
                    </div>
                    <h6 class="uppercase text-center leading-5 pt-6 text-xs font-semibold"
                      [style.color]="wellbeingScalesService.getColorByCode(wellbeingOverallIndexByScale.scale)"
                      [innerHtml]='wellbeingOverallIndexByScale.scale | translate:language:ModuleNames.Wellbeing'>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <!--Employee Wellbeing factors  -->
            <ng-container *ngFor="let dimension of factors; let i = index">
              <div class="innerPage break-before bg-white">
                <div class="exportContainer pt-20 px-10 gap-8 flex flex-col">
                  <h1 class="text-zen font-semibold text-lg"><span class="text-exp">{{sectionIndex('overview')}}.{{i
                      +
                      2}}
                    </span>
                    {{'txtEmployeeIndexByDimension' | translate:language:ModuleNames.Wellbeing}} -
                    {{dimension.factor.name | extract:language}}
                  </h1>
                  <!-- content -->


                  <div class="block relative  -mr-2 -ml-2">
                    <div class="inline-block w-1/3 px-1 pb-6" *ngFor="let scale of dimension.scales">
                      <div class="flex flex-col items-center  border border-zen border-opacity-10 rounded py-5">
                        <div class="svg-container">
                          <kendo-arcgauge [color]="wellbeingScalesService.getColorByCode(scale.scale)"
                            [value]="scale.mean" style="display:block; height:140px; width: 140px;">
                            <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450"
                              [rangePlaceholderColor]="wellbeingScalesService.getColorByCode(scale.scale)+ '60'"
                              [rangeSize]="18" [rangeLineCap]="'butt'">
                              <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                <p class="text-xl text-zen font-medium">{{scale.mean |
                                  number:'1.1-1'}}%
                                </p>
                              </ng-template>
                            </kendo-arcgauge-scale>
                          </kendo-arcgauge>
                        </div>
                      </div>
                      <h6 class="uppercase text-center leading-5 pt-6 text-xs font-semibold"
                        [style.color]="wellbeingScalesService.getColorByCode(scale.scale)"
                        [innerHtml]='scale.scale | translate:language:ModuleNames.Wellbeing'>
                      </h6>

                    </div>

                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Employee criteria  -->
            <ng-container *ngFor="let chart of overviewCharts; let n = index">
              <div class="innerPage break-before bg-white" *ngIf="chart?.data$ | async as data">
                <div class="exportContainer pt-20 px-10 maxwInherent">

                  <h1 class="text-zen font-semibold text-lg pb-10"><span
                      class="text-exp">{{sectionIndex('overview')}}.{{n +
                      2 + factors?.length}}
                    </span>
                    {{"txtWokplaceWellbeingEx"
                    | translate:language:ModuleNames.Wellbeing}} {{chart.Title}}
                  </h1>
                  <chart exportMode="portrait" [colors]="returnIndexColor(data)"
                    (exportPageLength)="chart.page = $event" [hasCustomQuantity]="false" [dashlet]="chart"
                    [chartColors]="chartColors" [dashletData]="data" [currencySymbol]="'$'" [language]="language"
                    [dataSymbol]="' %'" [disableGrouping]="true" [disableChartTypes]="true" [export]="true">
                  </chart>

                </div>
              </div>
            </ng-container>

          </ng-container>
          <!--  5.  Wellbeing Analysis-->
          <div *ngIf="exportAnalysis" class="innerPage block break-before bg-white pt-20 px-10">
            <h1 class="text-zen font-semibold text-2xl "><span class="text-exp">{{sectionIndex('analysis')}}.
              </span>{{ "txtResponses" | translate:language:ModuleNames.Wellbeing }}</h1>
            <!-- content -->
            <div class="block relative">
              <div class="block -ml-3 -mr-3 pt-7">
                <div class="relative inline-block w-1/2 px-3 mb-6">
                  <div class="flex flex-col items-center border border-zen border-opacity-10 rounded gap-6 pt-4 pb-6">
                    <h4 class="text-center text-sm font-medium text-zen">
                      {{ "txtOverall"| translate:language:ModuleNames.Wellbeing }}
                    </h4>
                    <kendo-arcgauge [value]="responseRate" [color]="'#0082C6'" [transitions]="true"
                      style="height: 137px;width: 137px;" renderAs="svg">
                      <kendo-arcgauge-scale [rangePlaceholderColor]="'#0082C6'+'19'" [startAngle]="90" [endAngle]="450"
                        [rangeSize]="17" [rangeLineCap]="'butt'">

                        <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                          <ng-template kendoArcGaugeCenterTemplate let-value="value">
                            <p class="text-zen font-bold text-xl">{{responseRate | number:"1.1-1"}}%</p>
                          </ng-template>
                        </kendo-arcgauge-scale-labels>
                      </kendo-arcgauge-scale>
                    </kendo-arcgauge>
                  </div>
                </div>
                <ng-container *ngFor="let chart of exportableCharts?.charts; let n = index">
                  <ng-container *ngIf="!chart.notExportable || chart.key == 'status'">
                    <span *ngIf="chart.breakBefore || exportableCharts?.charts[n -1 ]?.breakAfter"
                      class="block break-before w-full h-20"></span>
                    <div class="relative px-3 mb-6 align-top" (click)="logData(chart)"
                      [ngClass]="{'w-1/2 inline-block':!chart.fullWidth , 'w-full block':chart.fullWidth}"
                      *ngIf="chart.data as data">
                      <export-chart-vertical [language]="language" *ngIf="chart.key != 'status'; else statusTemplate"
                        [rows]="fullWdithChartCheck(chart, data.categories)"
                        [breakIndex]="fullWdithChartCheck(chart, data.categories) == 1? 34: 42"
                        [categoryTemplate]="chart.key == 'GENDER'? categoryTemplate:''" [data]="data" [chart]="chart">
                        <ng-container header>
                          <div class="flex flex-row items-center gap-2">
                            <ng-container *ngIf="returnIcon(chart)">
                              <i *ngIf="chart?.icon;" [class]="'fa-'+ chart.icon"
                                class="fa-regular text-exp text-xl"></i>
                              <img *ngIf="chart?.image" [src]="chart.image" alt="">
                            </ng-container>
                            <h4 class="text-sm font-normal text-zen">
                              {{ 'txtRespby'|translate:language:ModuleNames.Wellbeing}} {{ chart.name |
                              extract:language}}
                            </h4>
                          </div>
                        </ng-container>
                      </export-chart-vertical>
                      <ng-template #categoryTemplate let-category let-value="value">
                        <div class="flex flex-row justify-between items-center py-2">
                          <h5 class="text-zen text-sm">{{category}}</h5>
                          <h5
                            [ngClass]="getGenderKey(category) == 'male'?'text-int': getGenderKey(category) == 'female'?'text-zen-pink':'text-exp'"
                            class="text-sm font-semibold">
                            {{value | number:'1.1-1'}}%
                          </h5>
                        </div>
                        <div
                          [ngClass]="getGenderKey(category) == 'male'?'bg-int': getGenderKey(category) == 'female'?'bg-zen-pink':'bg-exp'"
                          class="flex flex-row justify-start h-2.5 rounded-sm bg-opacity-10 items-stretch overflow-hidden">
                          <div
                            [ngClass]="getGenderKey(category) == 'male'?'bg-int': getGenderKey(category) == 'female'?'bg-zen-pink':'bg-exp'"
                            [style.width.%]="value">
                          </div>
                        </div>
                      </ng-template>



                      <ng-template #statusTemplate>

                        <div (click)="logData(data)" class="block relative align-top  mb-6 w-full"
                          [ngClass]="{'break-before pt-20': n == 1 && data.categories.length}"
                          *ngIf="data.categories.length">
                          <export-chart-vertical [language]="language" [breakIndex]="42" (click)="logData(chartData)"
                            [categoryTemplate]="statusSingleTemplate" [data]="data">
                            <ng-container header>
                              <div class="flex flex-row items-center gap-2">
                                <i class="fa-regular fa-users text-exp text-xl"></i>
                                <h4 class="text-sm font-normal text-zen">
                                  {{ chart.name }}
                                </h4>
                              </div>
                            </ng-container>

                          </export-chart-vertical>
                          <ng-template #statusSingleTemplate let-category let-value="value" let-index="index">
                            <div class="inline-block align-top w-full pb-1">
                              <div class="flex flex-row justify-between items-center pt-3 pb-1">
                                <h5 class="text-zen text-sm">
                                  {{data?.bar.series[index].name}}</h5>
                                <h5 [ngClass]="'text-'+ returnStatusColor(data?.bar.series[index].key)"
                                  class="text-sm font-semibold">
                                  {{data?.bar.series[index].data[index] |
                                  number:'1.1-1'}}%
                                </h5>
                              </div>
                              <div [ngClass]="'bg-'+ returnStatusColor(data?.bar.series[index].key)"
                                class="flex flex-row justify-start h-2.5 rounded-sm bg-opacity-10 items-stretch overflow-hidden">
                                <div [ngClass]="'bg-'+ returnStatusColor(data?.bar.series[index].key)"
                                  [style.width.%]="data?.bar.series[index].data[index]">
                                </div>
                              </div>
                            </div>

                          </ng-template>
                        </div>

                      </ng-template>

                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>

          <div *ngIf="exportQuestions" class="innerPage block break-before bg-white">
            <div class="exportContainer py-20 px-10">
              <h1 class="text-zen font-semibold text-2xl"><span class="text-exp">{{sectionIndex('questions')}}.
                </span>{{ "txtStatements" | translate:language:ModuleNames.Wellbeing }}</h1>
              <!-- content -->
              <div class="block relative pt-7">
                <div class="flex flex-row justify-between items-center uppercase pb-7">
                  <h5 class="text-xxs text-zen-lite font-medium">{{ "txtDriver/Question" |
                    translate:language:ModuleNames.Wellbeing }}</h5>
                  <h5 class="text-xxs text-zen-lite font-medium">{{ "txtMean" |
                    translate:language:ModuleNames.Wellbeing }}</h5>
                </div>
                <div class="block pb-14" [ngClass]="{'pt-20 break-before': i % 2 == 0 && i != 0}"
                  *ngFor="let dimension of dimensionQuestions; let i = index">
                  <div class="flex flex-row justify-between items-center pb-5">
                    <h5 class="text-lg text-zen font-semibold">{{dimension.name | extract:language}}</h5>
                    <div class="flex flex-row items-center py-1 px-2 rounded-sm gap-1 text-sm text-white"
                      [style.background]="wellbeingScalesService.resolveChartColor(dimension.mean)">
                      <i class="fa fas" [ngClass]="dimension.mean > 40 ? 'fa-caret-up' : 'fa-caret-down'"></i>
                      {{dimension.mean | number:'1.1-1'}}%
                    </div>
                  </div>
                  <ng-container *ngFor="let question of dimension.questions">
                    <export-question-template [answers]="returnAnswers(question.answers)"
                      [name]="extractNameLanguage(question.name)" [score]="question.mean"></export-question-template>

                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <!-- end page -->
          <div class="block break-before bg-white relative">
            <div class="innerPage break-before relative">
              <div
                class="absolute bottom-0 top-0 left-0 z-30 flex flex-col h-full w-full items-stretch justify-between bg-white p-10"
                [style.direction]="'ltr'">
                <div class="flex flex-col items-center gap-5">
                  <img class="h-20 w-auto object-contain mt-40" src="assets/img/logo.png" alt="">
                  <img class="h-10 w-auto object-contain" src="assets/img/tagline.png" alt="">
                  <div class="flex flex-row items-center justify-center gap-1 mt-10">
                    <i class="fa-regular fa-globe text-zen"></i>
                    <h6 class="text-zen text-sm">zenithr.com</h6>
                  </div>

                </div>

              </div>
            </div>
          </div>
          <div class="header w-full px-10 flex flex-row items-start justify-between">
            <h5 class="text-3xs text-zen text-opacity-60">{{ "txtWellbeingReport"
              |translate:language:ModuleNames.Wellbeing }}</h5>
            <h5 class="text-3xs text-zen "><span class="text-opacity-60">{{
                "txtPreparedFor"|translate:language:ModuleNames.Shared }}
              </span> <span class="text-zen font-medium">{{selectedEntity.name | extract:language}}</span> </h5>
          </div>
          <div class="footer w-full px-10 flex flex-row items-end justify-between">
            <div class="flex flex-col gap-1">
              <p class="text-xxs text-zen text-opacity-50">{{ "txtPoweredby" |
                translate:language:ModuleNames.Shared }}</p>
              <img class="h-8 w-auto object-cover" src="assets/img/logo.png" alt="">
            </div>
            <div class="flex flex-col items-end gap-1">
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtGenerated"|translate:language:ModuleNames.Shared }}: {{date | date:
                'dd/MM/yyyy'}}
              </h5>
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtAllRights"|translate:language:ModuleNames.Shared }} © {{date | date: 'yyyy'}}
                -
                www.zenithr.com</h5>

            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-stretch justify-between w-1/5">
        <div class="exp flex flex-col gap-3 px-3">
          <div class="flex flex-row items-start gap-2 justify-start pb-3 ">
            <i class="far fa-file-export text-exp text-lg"></i>
            <h4 class="text-zen text-2xl">{{ 'btnExport' | translate:language:ModuleNames.Wellbeing }}
            </h4>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm relative">
            <z-toggle *DemoAccount="'Button'; let isDemo=isDemo" [disable]="isDemo"
              (ngModelChange)="filterTableOfContent($event)"
              [label]="'txt_report' | translate:language:ModuleNames.Wellbeing " [(ngModel)]="exportReport"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm relative">
            <z-toggle *DemoAccount="'Button'; let isDemo=isDemo" [disable]="isDemo"
              (ngModelChange)="filterTableOfContent($event)"
              [label]="'txtOverview' | translate:language:ModuleNames.Wellbeing "
              [(ngModel)]="exportOverview"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm relative">
            <z-toggle *DemoAccount="'Button'; let isDemo=isDemo" [disable]="isDemo"
              (ngModelChange)="filterTableOfContent($event)"
              [label]="'txtResponses' | translate:language:ModuleNames.Wellbeing"
              [(ngModel)]="exportAnalysis"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm relative">
            <z-toggle *DemoAccount="'Button'; let isDemo=isDemo" [disable]="isDemo"
              (ngModelChange)="filterTableOfContent($event)"
              [label]="'txt_questions' | translate:language:ModuleNames.Wellbeing "
              [(ngModel)]="exportQuestions"></z-toggle>
          </div>
          <!-- <div *ngIf="sectionCounts?.questionCount.length"
                      class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
                      <z-toggle (ngModelChange)="filterTableOfContent($event); getCommentsSection();"
                          [label]="'txtComments' | translate:language:ModuleNames.Wellbeing "
                          [(ngModel)]="exportWithComments"></z-toggle>
                  </div>
                  <p class="flex flex-row items-center gap-1.5 text-zen-lite"
                      *ngIf="!sectionCounts?.questionCount.length">
                      <i class="far fa-info-circle "></i>{{
                      'txtExportCommentsUnavailable' |
                      translate:language:ModuleNames.Wellbeing }}
                  </p> -->

          <!--
                  <mat-slide-toggle class="comment-checkbox" [(ngModel)]="exportReport" name="report"
                      (change)="filterTableOfContent($event)">
                      {{ 'txt_report' | translate:language:ModuleNames.Wellbeing }}
                  </mat-slide-toggle>
                  <mat-slide-toggle class="comment-checkbox" [(ngModel)]="exportOverview" name="overview"
                      (change)="filterTableOfContent($event)">
                      {{ 'txtOverview' | translate:language:ModuleNames.Wellbeing }}
                  </mat-slide-toggle>
                  <mat-slide-toggle class="comment-checkbox" [(ngModel)]="exportAnalysis" name="responses"
                      (change)="filterTableOfContent($event)">
                      {{ 'txtjoinersAnalysis' | translate:language:ModuleNames.Wellbeing }}
                  </mat-slide-toggle>
                  <mat-slide-toggle class="comment-checkbox" [(ngModel)]="exportQuestions" name="questions"
                      (change)="filterTableOfContent($event)">
                      {{ 'txt_questions' | translate:language:ModuleNames.Wellbeing }}
                  </mat-slide-toggle>

                  <mat-slide-toggle *ngIf="sectionCounts?.questionCount.length" class="comment-checkbox"
                      [(ngModel)]="exportWithComments" name="comments"
                      (change)="filterTableOfContent($event); getCommentsSection();">
                      {{ 'txtExportCommentCheckbox' | translate:language:ModuleNames.Wellbeing }}
                  </mat-slide-toggle> -->


        </div>
        <div class="flex flex-row justify-between gap-5">
          <a (click)="close.emit(true)" class="btn cancel leading-12 rounded text-sm text-center px-5">{{"btn_cancel"|
            translate:language:ModuleNames.Wellbeing}}</a>

          <a (click)="exportDraft(language,true)"
            class="btn exp leading-12 rounded text-sm text-center px-5">{{"btnExport"|
            translate:language:ModuleNames.Wellbeing}}</a>

        </div>
      </div>
    </div>

  </ng-container>
</z-modal>