import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { AnalysisFilterKeys, AnalyticsFilter, AnalyticsService } from '../../../data-access/services/analytics.service';
import { map, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WellbeingScalesService } from '../../../utils/wellbeing-scales.service';
import { SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { AnalysisBaseComponentComponent } from '../analysis-base-component/analysis-base-component.component';
import { WbBaseComponent } from '../../../utils/wellbeing-base.component';

@Component({
  selector: 'wellbeing-index-by-dimensions',
  templateUrl: './wellbeing-index-by-dimensions.component.html',
  styleUrls: ['./wellbeing-index-by-dimensions.component.scss']
})
export class WellbeingIndexByDimensionsComponent extends WbBaseComponent implements OnInit {

  @Input()
  language = "EN"

  filters: AnalyticsFilter

  wellbeingIndexByDimension = [];

  wellbeingDimensions = [];

  selectedDimensionKey;

  dimensionChanged = new Subject();
  dimensionChanged$ = this.dimensionChanged.asObservable();


  constructor(private analysisService: AnalyticsService, public wellbeingScalesService: WellbeingScalesService, private surveyAnalysisService: SurveyAnalysisService) { super() }

  ngOnInit(): void {

    this.fetchAnalysisByDimension();


    this.addSubscription(this.analysisService.filters$
      .pipe(
        switchMap(filters => {

          // TODO: Fetch factor analysis
          return this.surveyAnalysisService.getOverallDimensionAnalysis({
            companyKey: filters.companyKey,
            tenantKey: filters.tenantKey,
            surveyKey: filters.survey.key,
            filters: [...this.analysisService.mapGlobalFilters(filters), ...filters.chartFilters || []]
          }).pipe(
            map(factors => ({ factors, filters }))
          )

        })
      )
      .subscribe(({ filters, factors }) => {
        this.filters = filters;
        this.wellbeingDimensions = filters.survey.dimensions.filter(dim => factors.find(x => x.key == dim.key)).map(dim => { return factors.find(x => x.key == dim.key) })
        this.selectedDimensionKey = this.wellbeingDimensions[0]?.key;
        this.dimensionChanged.next(this.selectedDimensionKey);
      }));

  }

  fetchAnalysisByDimension() {
    this.addSubscription(this.dimensionChanged$
      .pipe(
        switchMap((key: any) => {
          return this.surveyAnalysisService.getSectionRespondentsIndex({
            companyKey: this.filters.companyKey,
            surveyKey: this.filters.survey.key,
            tenantKey: this.filters.tenantKey,
            filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || [{ key: "SectionKey", value: key }]]
          }).pipe(
            map(scales => {
              return this.wellbeingScales.map(x => ({ mean: scales.find(y => y.scale == x.scale)?.mean || 0, scale: x.scale }));
            })
          )
        })
      ).subscribe(scales => {
        this.wellbeingIndexByDimension = scales
      }))
  }

  onDimensionChange(key: string) {
    this.dimensionChanged.next(key);
  }

}
