import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PlatformUser } from '../../../core/data-models/platform-user';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from '../../../core/services/tenant.service';
import { Tenant } from '../../../core/data-models/tenant';
import { PlatformUserService } from '../../../core/services/platform-user.service';
import { LogService } from '../../../core/services/log.service';
import { Observer, Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ResponseDialogService } from '../../../core/services/response-dialog.service';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../core/services/language.service';
import { ZenSnackbarService, ZenSnackBarTypes } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { CrashReportingService } from '../../../core/services/crash-reporting.service';
import { GeneralService } from '../../../core/services/general.service';
import { LocalService } from '../../../core/services/local.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MiscellaneousService } from '../../../core/services/miscellaneous.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { ChildrenAnimation, fadeUpDown } from 'src/app/shared/animations/allAnimations';
import { RespondentService } from 'src/app/core/services/respondent.service';
import { EngagementRespondent } from 'src/app/core/data-models/engagement-respondent';
declare var $: any;
@Component({
  selector: 'app-engagement-landing-page',
  templateUrl: './engagement-landing-page.component.html',
  styleUrls: ['./engagement-landing-page.component.scss'],
  animations:[fadeUpDown, ChildrenAnimation]
})
export class EngagementLandingPageComponent implements OnInit {
  userSubscription: Subscription;
  language: string = 'EN';
  logoLoading: boolean = false;
  tenantLogo: string;
  base64Image: string;
  logoURL: string;
  tenantKeyExists: boolean = false;
  section: string = 'login';
  hide = true;
  showLangage: boolean = false;
  returnUrl: string;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  ModuleNames = ModuleName

  constructor(private route: ActivatedRoute, private translationPipe: TranslatePipe,private languagePipe: LanguagePipe,
    private languageService: LanguageService,
    private localService: LocalService, private miscellanous: MiscellaneousService,
    private router: Router, public loaderService: LoaderService, private zenSnackBarService: ZenSnackbarService, private respondentService: RespondentService) {

  }



  form = {
    id: '',
    name: '',
    email: '',
  };
  valid = true;

  supportedLanguages: any = [];
  tenantKey: string;
  engagementKey: string;
  referenceKey: string;
  ngOnInit(): void {
    let tmdy = {
      hello: "hello",
      hi: "hi",
      bye: "bye"
    };

    this.referenceKey = this.route.snapshot.paramMap.get('refKey');
    if (this.referenceKey) {
      localStorage.setItem('LandingKey', this.referenceKey);
      this.supportedLanguages = this.languageService.supportedLanguages.filter(s => (s.key != 'FR'));

      this.logoURL = 'assets/img/svg/logo.svg'; // to be resolved to client's logo
      let localLanguage = this.localService.getJsonValue('language');
      let sessionLanguage = this.localService.getJsonValue('language');
      if (!localLanguage && !sessionLanguage) {
        this.language = 'EN';
      }
      else if (localLanguage) {
        this.language = localLanguage
      }
      else if (sessionLanguage) {
        this.language = sessionLanguage;

      }

      this.isArabic();

      this.languageService.language = this.language;
      // this.setUpLanguagePreference();
      // this.localService.removeItem('sectionID');
      // this.localService.removeItem('assessmentSectionID');
      // this.localService.removeItem('pageID');
      // this.localService.removeItem('selectedEngagement');
      // this.localService.removeItem('selectedEntity');
      // this.localService.removeItem('UserKey');
      // this.localService.removeItem('selectedAssessment');
      // this.localService.removeItem('timeout');
      // this.localService.removeItem('Access');
      // this.localService.removeItem('User');
      // this.localService.removeItem('pageReference');
      // this.localService.removeItem('localSettings');

      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    }
    this.canvasStart()

    this.loaderService.hideLoader();
  }
  closeDropdown(menu) {
    if (this[menu]) this[menu] = false;
  }
  selectedLanguageChange(event) {
    this.language = event;
    this.localService.setJsonValue('language', this.language);

    this.isArabic()
  }




  isArabic() {
    if (this.languagePipe.transform(this.language)) {
      $("body").removeClass('ltr')
      $("body").addClass('rtl').attr("dir", "rtl")

    }
    else {
      $("body").removeClass('rtl')
      $("body").addClass('ltr').attr("dir", "ltr")

    }
  }
  private subscriptions = new Subscription();




  waitingForResponse: boolean = false;
  onSubmit(event?) {
    if (this.form.id) {
      if (!this.form.name)
        this.form.name = this.form.id;

      console.log(this.form.name);

      let request = {
        name: this.form.name, employeeID: this.form.id, email: this.form.email,
        referenceKey: this.referenceKey
      };
      this.waitingForResponse = true;
      this.subscriptions.add(this.miscellanous.createRespondentForLandingPage(request).subscribe((response: any) => {
        if (response.response == 'SUCCESS') {
          let respondent = response.data.respondent;
          let companyName = response.data.companyName;
          let surveyInfo = response.data.surveyInfo;
          let reses: EngagementRespondent[] = [];
          reses.push(respondent);
          let request = {
            SurveyType: "ENG",
            SurveyKey: surveyInfo.key,
            CompanyKey: respondent.companyKey,
            TenantKey: respondent.tenantKey,
            fromLanding: true,
            respondents: reses
          }
          console.log(respondent);
          this.respondentService.updateSurveyRespondent(request).subscribe((response: any) => {
            if (response.response == 'SUCCESS') {
              localStorage.setItem('LandingPageRef', respondent.referenceKey);
              this.router.navigate([
                '/engagement-questionnaire/' + respondent.referenceKey,
              ]);
            } else if(response.response == 'ERROR_EXISTS'){
              this.waitingForResponse = false;
              this.zenSnackBarService.snackBar({message:this.translationPipe.transform('txt_Linkhasal_237', this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error});
            }
        });

        } else {
          if (response.message == 'INSUFFICIENT_FUNDS') {
            this.waitingForResponse = false;
            this.zenSnackBarService.snackBar({message:this.translationPipe.transform('txt_Insuffici_313', this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error});
          }
        }
      }));
    }
    else {
      this.valid = false;
      this.zenSnackBarService.snackBar({message:this.translationPipe.transform('txtEnterInformation', this.language, this.ModuleNames.Engagement), type: ZenSnackBarTypes.Error });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  canvasStart() {
    var canvas = <HTMLCanvasElement>document.getElementById('c');
    var ctx = canvas.getContext('2d');
    let colors = ['#fbfbfb', '#f7f7f8', '#f4f4f4', '#f0f0f1', '#ededee', '#e9e9ea']
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
    let speed = 1;

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let color = 0; color < colors.length; color++) {
        ctx.beginPath();
        ctx.moveTo(-200, (canvas.height / 10 + Math.sin(speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.bezierCurveTo(
          canvas.width / 2,
          (canvas.height / 4 + Math.cos(speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width / 4,
          (canvas.height / 4 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width + 200,
          (canvas.height / 3 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.lineWidth = 130 + color * 100 / 2;
        ctx.strokeStyle = colors[color];
        ctx.stroke();
      }
      speed += .5;
    }

    animate();
  }

}
