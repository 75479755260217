import { questionsBuilderTranslateText } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";
import { translateText } from "../../../features/survey/survey.component";

/**
 * Updates the HTML representation of an Employee Net Promoter Score (ENPS) question.
 * @param options - The options for updating the question HTML.
 */
export function updateENPSQuestionHTML(options: any, locale:any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  body.innerHTML = "";
  body.className = "question-body";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-5 text-start";
  inputTitle.innerHTML = translateText('Select an option:',locale);

  body.appendChild(inputTitle);

  // Constraints for ENPS values
  var constraints = [
    { min: 0, max: 6, text: questionsBuilderTranslateText("Unlikely", locale), color: "#FF45301A" },
    { min: 7, max: 8, text: questionsBuilderTranslateText("Neutral", locale), color: "#FF96001A" },
    { min: 9, max: 10, text: questionsBuilderTranslateText("Likely", locale), color: "#3EB75B1A" }
  ];

  var container = document.createElement("div");
  container.className = "flex w-full gap-1 justify-center";

  for (var i = 0; i < constraints.length; i++) {
    var constraint = constraints[i];

    var constraintContainer = document.createElement("div");
    constraintContainer.className = "flex flex-col items-center gap-2";

    var itemsDiv = document.createElement("div");
    itemsDiv.className = "flex w-full gap-1 justify-center";

    // Create ENPS items within the constraint range
    for (var j = constraint.min; j <= constraint.max; j++) {
      var item = document.createElement("button");
      item.className = "px-1 w-7 sm:w-12 md:w-16 py-3 nps-item";
      item.innerHTML = j.toString();
      item.style.backgroundColor = constraint.color;
      item.onclick = function () {
        var items = container.querySelectorAll('button');

        for (var m = 0; m < items.length; m++) {
          if (items[m] === this) {
            items[m].classList.add("nps-item-selected");
            options.question.value = items[m].innerHTML;
          } else {
            items[m].classList.remove("nps-item-selected");
          }
        }
      };

      itemsDiv.appendChild(item);
    }

    var constraintText = document.createElement("label");
    constraintText.className = "label-gray w-[60px] sm:w-20 sm:w-24 whitespace-normal";
    constraintText.innerHTML = constraint.text;

    constraintContainer.appendChild(itemsDiv);
    constraintContainer.appendChild(constraintText);

    container.appendChild(constraintContainer);
    body.appendChild(container);
  }

  // Highlight the selected item if a value is already set
  if (options.question.value != null && options.question.value !== "") {
    var items = container.querySelectorAll('button');

    for (var m = 0; m < items.length; m++) {
      if (items[m].innerHTML == options.question.value) {
        items[m].classList.add("nps-item-selected");
      } else {
        items[m].classList.remove("nps-item-selected");
      }
    }
  }
}

