import type { BulkDeleteRespondentDto, DeleteRespondentDto, ExcelUploadRequestDto, RespondentCreateUpdateDto, RespondentPoolDto, RespondentPoolsPagedRequestDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CustomPropertyDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RespondentPoolsService {
  apiName = 'Default';

  bulkDelete = (request: BulkDeleteRespondentDto) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/core/repondent-pools/bulk-delete',
      params: { companyKey: request.companyKey, tenantKey: request.tenantKey, emails: request.emails, isDeleteAll: request.isDeleteAll },
    },
    { apiName: this.apiName });

  create = (request: RespondentCreateUpdateDto) =>
    this.restService.request<any, RespondentPoolDto>({
      method: 'POST',
      url: '/api/core/repondent-pools',
      body: request,
    },
    { apiName: this.apiName });

  delete = (request: DeleteRespondentDto) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/core/repondent-pools',
      params: { tenantKey: request.tenantKey, companyKey: request.companyKey, email: request.email },
    },
    { apiName: this.apiName });

  excelUpload = (request: ExcelUploadRequestDto) =>
    this.restService.request<any, RespondentPoolDto[]>({
      method: 'POST',
      url: '/api/core/repondent-pools/excel-upload',
      body: request,
    },
    { apiName: this.apiName });

  get = (email: string, tenantKey: string, companyKey: string) =>
    this.restService.request<any, RespondentPoolDto>({
      method: 'GET',
      url: `/api/core/repondent-pools/${email}/${tenantKey}/${companyKey}`,
    },
    { apiName: this.apiName });

  getAll = (request: RespondentPoolsPagedRequestDto) =>
    this.restService.request<any, PagedResultDto<RespondentPoolDto>>({
      method: 'POST',
      url: '/api/core/repondent-pools/all',
      body: request,
    },
    { apiName: this.apiName });

  getCustomPropertiesByEmailAndTenantKeyAndCompanyKey = (email: string, tenantKey: string, companyKey: string) =>
    this.restService.request<any, CustomPropertyDto[]>({
      method: 'GET',
      url: `/api/core/repondent-pools/custom-properties/${email}/${tenantKey}/${companyKey}`,
    },
    { apiName: this.apiName });

  update = (email: string, request: RespondentCreateUpdateDto) =>
    this.restService.request<any, RespondentPoolDto>({
      method: 'PUT',
      url: `/api/core/repondent-pools/${email}`,
      body: request,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
