import { Directive, ElementRef, Input, TemplateRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[maxNumber]'
})
export class MaxNumberDirective {
  @Input() maxNumber;
  constructor(private ngControl: NgControl) {
    ngControl.control.valueChanges.pipe(debounceTime(100)).subscribe(x => {
      if (x > this.maxNumber && this.maxNumber )
        ngControl.control.setValue(this.maxNumber);
    })
  }

}
