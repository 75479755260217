<div class="flex flex-col bg-white rounded-lg items-center justify-center h-full gap-5 py-20">
  <h5 class="text-lg text-center px-5 bg-exp text-exp bg-opacity-10 py-1 rounded mt-2">{{ "txtThisDemoAccount" |
    translate:language:ModuleNames.Shared }}</h5>
  <h5 class="text-2xl text-center w-full text-zen-lite">{{ "txtDemoSection" | translate:language:ModuleNames.Shared }}
  </h5>

  <a (click)="upgrade()" zenButton styles="exp height-12 mt-5">
    <span>{{'txtUpgrade'| translate:language:ModuleNames.Shared }}</span>
  </a>
</div>
