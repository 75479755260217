import { Component, OnDestroy } from "@angular/core";
import { Observable, of, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AppInjector } from "src/app/app.module";
import { ModuleName } from "src/app/shared/enums/ModuleName";
import { ExtractPipe } from "src/app/shared/pipes/extract.pipe";
import { TranslatePipe } from "src/app/shared/pipes/translate.pipe";
import { ZenSnackbarService } from "src/app/shared/Theme/services/zen-snackbar.service";
import { EssentialObject, EssentialObjectService } from "../services/essential-object.service";
import { GeneralService } from "../services/general.service";
import { GlobalsService } from "../services/globals.service";
import { LanguageService } from "../services/language.service";
import { LoaderService } from "../services/loader.service";
import { LocalService } from "../services/local.service";
import { ZenCDKService } from "src/app/shared/Theme/services/zen-cdk.service";
import { Status } from "../services/global-status.service";

@Component({
  selector: 'base',
  template: ``
})
export abstract class BaseComponent implements OnDestroy {


  private _languageService = null;
  private _essentialObject = null;
  private _localService: LocalService = null;
  private _zenCDK = null;
  private _loaderService: LoaderService = null;
  private _translationPipe = AppInjector.get(TranslatePipe);
  private _generalService:GeneralService = null;
  private _subscriptions = new Subscription()
  private _snackbarService = null;
  private _extractPipe = null;

  public RespondentStatus = Status
  private _globalService = AppInjector.get(GlobalsService);

  public md2Count = this._globalService.md2Count;
  public mdCount = this._globalService.mdCount;
  public lgCount = this._globalService.lgCount;
  public avgCount = this._globalService.avgCount;
  public ModuleNames = ModuleName;

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    console.log(this._subscriptions.closed);
  }

  protected addSubscription(subscription: Subscription): Subscription {
    return this._subscriptions.add(subscription);
  }

  protected removeSubscription(subscription: Subscription) {
    const s = this._subscriptions.remove(subscription);
  }

  protected get GlobalService(): GlobalsService {
    return this._globalService;
  }

  protected get LanguageService() {
    if (this._languageService == null)
      this._languageService = AppInjector.get(LanguageService);

    return this._languageService;
  }

  protected get EssentialObject$(): Observable<EssentialObject> {
    if (this._essentialObject == null)
      this._essentialObject = AppInjector.get(EssentialObjectService);

    return this._essentialObject.essentialObject;
  }

  protected get CurrentUser$() {

    const currentUser$ = this.EssentialObject$.pipe(
      switchMap((obj: EssentialObject) => {
        const tenantKey = obj?.tenant?.key;
        const companyKey = obj?.selectedEntity?.key;
        const user = obj?.user;

        if (!tenantKey || !companyKey)
          return of();

        return of(({ tenantKey: tenantKey, companyKey: companyKey, uid: user.uid, name: `${user.firstName} ${user.lastName}`, email: user.email }))
      })
    )

    return currentUser$;
  }

  protected get LocalService() {
    if (this._localService == null)
      this._localService = AppInjector.get(LocalService);

    return this._localService;
  }
  protected get SnackbarService(): ZenSnackbarService {
    if (this._snackbarService == null)
      this._snackbarService = AppInjector.get(ZenSnackbarService);

    return this._snackbarService;
  }
  protected get ZenCDKService(): ZenCDKService {
    if (this._zenCDK == null)
      this._zenCDK = AppInjector.get(ZenCDKService);

    return this._zenCDK;
  }

  protected get LoaderService() {
    if (this._loaderService == null)
      this._loaderService = AppInjector.get(LoaderService);

    return this._loaderService;
  }

  protected get ExtractPipe(): ExtractPipe {
    if (this._extractPipe == null)
      this._extractPipe = AppInjector.get(ExtractPipe);

    return this._extractPipe;
  }
  protected get GeneralService() {
    if (this._generalService == null)
      this._generalService = AppInjector.get(GeneralService);

    return this._generalService;
  }

  protected localize(value: string, language: string, module: string) {
    return this._translationPipe.transform(value, language, module);
  }

  logData(e) {
    console.log(e);
  }

  toFixed(number, digits) {
    return parseFloat(number).toFixed(digits);
  }
}
