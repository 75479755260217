import { Component, ContentChild, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { KeyNameDto } from '@ex/onboarding/common';
import { AiTranslationService } from 'src/app/core/services/ai-translation.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ChildrenAnimation, fadeUpDown } from 'src/app/shared/animations/allAnimations';
import { ModuleName } from 'src/app/shared/enums/ModuleName';

@Component({
  selector: 'z-translation-input',
  templateUrl: './zen-translation-input.component.html',
  styleUrls: ['./zen-translation-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZenTranslationInputComponent,
    },
  ],
  animations: [fadeUpDown, ChildrenAnimation],
})

export class ZenTranslationInputComponent implements ControlValueAccessor {
  constructor(private languageService: LanguageService, private aiTranslation: AiTranslationService) { }
  @ContentChild(TemplateRef) labelTemplate:TemplateRef<any>;
  @ViewChild('mainInput') input: ElementRef<HTMLInputElement>;
  @ViewChild('translationInput') translationInput: ElementRef<HTMLInputElement>;
  @Input() label;
  @Input() classList: string = "w-full text-sm rounded text-zen border bg-white border-zen border-opacity-25 py-1 px-3 h-9 gap-3 leading-9 truncate flex flex-row justify-between items-center";
  @Input() invalid;
  @Input() required: boolean = false;
  @Input() language = 'EN';
  @Input() placeholder;
  supportedLanguages;
  @Input('supportedLanguages') set languages(langs) {
    this.supportedLanguages = langs;
  };

  @Input('focus') set focus(focus) {
    if(focus)
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 100);
  };
  isOpen = false;
  @Input() maxCount;
  translations = [];
  inTranslation:boolean = false;
  selected = null;
  @Input() disable: boolean = false;
  onChange: any = () => { };
  onTouch: any = () => { };

  writeValue(translations: any) {
    if (translations !== undefined && this.supportedLanguages.length) {
      this.translations.length ? this.translations = translations : this.translations = [];

      this.supportedLanguages?.forEach(element => {
        if (this.translations?.filter(s => s.key == element).length == 0)
          this.translations?.push({ key: element, name: '' });
      });

      this.selected = this.translations?.find(s => s.key == this.language);
    }

  }
  validateName(translations) {
    return translations?.find(s => s.name.length > 1 && s.name != '') ? false : true;
  }
  ModuleNames = ModuleName
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  handleChange(option?) {
    this.onChange(this.translations);
  }

  getLanguage(langISO: string) {

    return this.languageService.getLongLanguageName(langISO, this.language);
  }

  logData(e) {
    // console.log(e);

  }

  aiTranslate(key: string, index: number) {
    this.inTranslation = true;
    let sourceTranslation: KeyNameDto = this.selected;
    if (!sourceTranslation?.name?.length)
      sourceTranslation = this.translations.find(translation => translation.name.length);
    this.aiTranslation.translate(sourceTranslation.name, sourceTranslation.key, key).subscribe((res: any) => {
      this.inTranslation = false;

      if (res)
        this.translations[index] = res;
    });
  }
  focusTranslationInput(){
    setTimeout(() => {
      this.translationInput?.nativeElement.focus();
    }, 100);
  }
}
