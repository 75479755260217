<!-- Overall Screen -->
<div class="flex flex-col justify-between items-stretch gap-5">

    <div class="flex flex-row gap-5 items-stretch w-full">
        <div class="flex flex-col rounded gap-5 bg-white w-1/3 p-5 shadow-sm">
            <div class="flex flex-row justify-center gap-3 items-center">
                <i [@child10] class="text-4xl fal text-exp fa-reply"></i>
                <h5 [@child11] class="text-4xl text-zen">
                    {{responseRate| number:'1.1-1'}}%
                </h5>
            </div>
            <p [@child12] class="text-zen text-opacity-80 uppercase font-medium text-center">
                {{"txt_response_rate"| translate:data?.language:ModuleNames.Wellbeing}}
                ({{generalService.numberWithCommas(totalCompleted)}} {{"txtof"|
                translate:data?.language:ModuleNames.Wellbeing}}
                {{generalService.numberWithCommas(totalRespondents)}})
            </p>
        </div>
        <div [ngClass]="{'w-1/2': !isCompanySelected,
        'w-1/3': isCompanySelected}" class="flex flex-col rounded gap-5 bg-white w-1/3 p-5 shadow-sm">
            <div class="flex flex-row justify-center gap-3 items-center">
                <i [@child13] class="text-4xl fal text-exp fa-check"></i>
                <h5 [@child14] class="text-4xl text-zen">
                    {{generalService.numberWithCommas(totalCompleted)}}
                </h5>
            </div>
            <p [@child15] class="text-zen text-opacity-80 uppercase font-medium text-center">
                {{"txt_completed"|
                translate:data?.language:ModuleNames.Wellbeing}}
            </p>
        </div>
        <div [ngClass]="{'w-1/2': !isCompanySelected,
        'w-1/3': isCompanySelected}" class="flex flex-col rounded gap-5 bg-white w-1/3 p-5 shadow-sm">
            <div class="flex flex-row justify-center gap-3 items-center">
                <i [@child14] class="text-4xl fal text-exp fa-comment-dots"></i>
                <h5 [@child15] class="text-4xl text-zen">
                    {{generalService.numberWithCommas(totalComments)}}
                </h5>
            </div>
            <p [@child16] class="text-zen text-opacity-80 uppercase font-medium text-center">
                {{"txtComments"|
                translate:data?.language:ModuleNames.Wellbeing}}
            </p>
        </div>
    </div>



    <overall-dimension-analysis [language]="data?.language"></overall-dimension-analysis>

    <wellbeing-index-by-scales [language]="data?.language"></wellbeing-index-by-scales>

    <wellbeing-index-by-dimensions [language]="data?.language"></wellbeing-index-by-dimensions>

    <wellbeing-by-criteria [language]="data?.language"></wellbeing-by-criteria>

    <heatmap-analysis [language]="data?.language"></heatmap-analysis>

</div>
