<zen-main-container *ngIf="filters?.tenantKey">
  <sidebar-container>
    <section-sidebar>
      <div class="flex flex-col gap-12">
        <div class="flex flex-col items-stretch">
          <div class="flex h-28 flex-col justify-end pb-7">
            <h6 [@zero] class="uppercase text-xxs text-zen font-medium px-7">{{'txtSwitchTemplate' |
              translate:language:ModuleNames.Exit}}</h6>
          </div>
          <app-dual-dropdown-selection *ngIf="templateMenu" [data]="templateMenu" [(ngModel)]="selectedTemplate"
            (ngModelChange)="onTemplateChange($event)">
          </app-dual-dropdown-selection>
        </div>
        <div class="flex flex-col gap-4" *ngIf="!viewDisabled">
          <h6 [@zero] class="uppercase text-xxs text-zen font-medium px-7">{{ 'txt_reports' |
            translate:language:ModuleNames.Exit }}</h6>

          <div class="flex flex-col items-stretch">
            <menu-button *ngFor="let button of sideBarNavigationTabs" (click)="tabClick(button)"
              [icon]="button.selected ? 'text-exp ' + button.icon: 'opacity-50 '+ button.icon"
              [active]="button.selected"
              [TourStep]="{title:button.title | translate:language:ModuleNames.Exit ,text: button.description | translate:language:ModuleNames.Exit,order: 1,tourKey:button.tourKey,on:(language|language)?'left':'right'}">
              {{ button.title | translate:language:ModuleNames.Exit }}
            </menu-button>
          </div>
        </div>
      </div>
    </section-sidebar>
  </sidebar-container>
  <content-container class="flex flex-col items-stretch w-full">
    <div class="flex flex-col items-stretch h-28 z-10">
      <section-head>
        <ng-container left>
          <div class="flex flex-col gap-1">
            <h5 class="text-zen-lite text-xxs font-medium">{{ survey?.name | extract:language}}</h5>
            <h4 [@first] class="text-zen font-semibold text-2xl">{{
              'txtAnalysisSection'|translate:language:ModuleNames.Wellbeing}} - {{ activeTab?.title |
              translate:language:ModuleNames.Exit }}
            </h4>
          </div>
        </ng-container>
        <ng-container right>
          <div class="flex flex-row justify-end w-full items-center gap-5">
            <a [@child4] (click)="openTree()" *ngIf="selectedChildEntity?.entity.name"
              class="btn cancel uppercase px-5 leading-12 rounded-md"
              [TourStep]="{title:'txtViewBy' | translate:language:ModuleNames.Exit,text: 'txtViewBy_desc' | translate:language:ModuleNames.Exit,order:2,tourKey:'exit-analysis-overview',on:(language|language)?'right':'left'}">
              {{'txtViewBy'|translate:language:ModuleNames.Exit}}: {{ selectedChildEntity?.entity.name |
              extract:language }}</a>
            <div class="flex" *ngIf="!viewDisabled">
              <a *DemoAccount="activeTab.id==4?'Button':'Default'; let isDemo=isDemo" (click)="export(isDemo)" zenButton styles="exp height-12"
                [TourStep]=" activeTab.id==1?{title:'btnExport' | translate:language:ModuleNames.Exit,text: 'txtExport_desc' | translate:language:ModuleNames.Exit,order:3,tourKey:'exit-analysis-overview',on:(language|language)?'right':'left'}:
            activeTab.id==4?{title:'txt_download_comments' | translate:language:ModuleNames.Exit,text: 'txt_download_comments_desc' | translate:language:ModuleNames.Exit,order:2,tourKey:'exit-analysis-comments',on:(language|language)?'right':'left'}:undefined">
                <i class="fa-regular fa-file-alt"></i>
                <span>{{ ( activeTab.id != 4 ?'btnExport': 'txt_download_comments') |
                  translate:language:ModuleNames.Exit
                  }}</span></a>
            </div>


          </div>
        </ng-container>
      </section-head>
    </div>

    <div class="flex flex-row items-center justify-between h-20 bg-zen bg-opacity-5 px-10 gap-5">

      <div class="flex flex-row items-center justify-center gap-5"
        [TourStep]="{title:'txt_filter_leaver_seperation_types' | translate:language:ModuleNames.Exit,text: 'txt_filter_leaver_seperation_types_desc' | translate:language:ModuleNames.Exit,order:4,tourKey:'exit-analysis-overview'}">
        <h5 class="text-xs text-zen-lite">{{'txt_leaver_seperation_types' |
          translate:language:ModuleNames.Exit}}:
        </h5>

        <div class="flex flex-row gap-2 items-center relative" *DemoAccount="'Button'; let isDemo=isDemo">

          <div (click)="isDemo?'':onSeperationChange(seperationButton)"
            *ngFor="let seperationButton of seperationTypesButtons"
            [ngClass]="seperationButton.selected ? seperationButton.border : 'border-zen-grey'"
            class="btn flex flex-row items-center rounded-sm px-3 py-2 bg-white gap-2 border text-xs">
            <i [ngClass]="seperationButton.selected ? seperationButton.text : 'text-zen-grey'"
              class="fa-solid fa-circle"></i>
            <span [ngClass]="seperationButton.selected ? 'text-zen' : 'text-zen-grey'">{{
              seperationButton.key |
              translate:language:ModuleNames.Exit }}</span>
          </div>

        </div>


      </div>

      <div class="flex flex-row items-stretch gap-5"
        [TourStep]="{title:'txt_filter_timeframe' | translate:language:ModuleNames.Exit,text: 'txt_filter_timeframe_desc' | translate:language:ModuleNames.Exit,order:5,tourKey:'exit-analysis-overview'}">
        <div class="flex flex-row items-center gap-2.5 justify-end">
          <h5 class="text-xs text-zen-lite">{{'txt_timeframe' | translate:language:ModuleNames.Exit}}:
          </h5>
          <div class="flex flex-row gap-1 ">
            <z-datepicker class="relative" *DemoAccount="'Button'; let isDemo=isDemo" [disabled]="isDemo"
              [color]="'exp'" [language]="language" [max]="timeframeEndDate" [(ngModel)]="timeframeStartDate"
              (ngModelChange)="isDemo?'':onTimeframeChanged()"
              [placeholder]="'txt_start_date' | translate:language:ModuleNames.Exit">
            </z-datepicker>
            <z-datepicker class="relative" *DemoAccount="'Button'; let isDemo=isDemo" [disabled]="isDemo"
              [color]="'exp'" [language]="language" [min]="timeframeStartDate" [(ngModel)]="timeframeEndDate"
              (ngModelChange)="isDemo?'':onTimeframeChanged()"
              [placeholder]="'txt_end_date' | translate:language:ModuleNames.Exit">
            </z-datepicker>
          </div>
          <i [@child1] *ngIf="timeframeStartDate && timeframeEndDate"
            [matTooltip]="'txtClearTimeFrame' | translate:language:ModuleNames.Exit" (click)="clearFilters()"
            class="fal fa-times btn cancel leading-9 rounded-sm w-9 text-center text-sm"></i>
        </div>
        <span *ngIf="hasExtraFactors" class="w-0 h-auto border-r border-zen border-opacity-20"></span>

        <div [@child8] *ngIf="hasExtraFactors" class="flex flex-row items-center">
          <z-toggle [label]="'txtExtraDriver' | translate:language:ModuleNames.Exit"
            [(ngModel)]="filters.showExtraFactors" (ngModelChange)="toggleHasExtraFactors()"></z-toggle>
        </div>
      </div>
    </div>


    <!-- Main Content Area -->
    <div class="flex flex-col items-stretch p-10 overflow-x-hidden" *ngIf="!viewDisabled">
      <div class="flex flex-col justify-between items-stretch gap-5">
        <ng-template #tabContentHolder></ng-template>
      </div>
    </div>
    <EmptySection *ngIf="viewDisabled"></EmptySection>

  </content-container>
  <ng-container *ngIf="!LoaderService.showLoader">
    <product-tour *ngIf="activeTab.id==1" [key]="'exit-analysis-overview'" [offset]="122"></product-tour>
    <product-tour *ngIf="activeTab.id==2" [key]="'exit-analysis-leaver'" [offset]="122"></product-tour>
    <product-tour *ngIf="activeTab.id==3" [key]="'exit-analysis-statements'" [offset]="122"></product-tour>
    <product-tour *ngIf="activeTab.id==4" [key]="'exit-analysis-comments'" [offset]="122"></product-tour>
  </ng-container>
</zen-main-container>