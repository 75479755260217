import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[minNumber]'
})
export class MinNumberDirective {

  @Input() minNumber;
  constructor(private ngControl: NgControl) {
    ngControl.control.valueChanges.pipe(debounceTime(100)).subscribe(x => {
      if (x < this.minNumber && this.minNumber )
        ngControl.control.setValue(this.minNumber);
    })
  }
}
