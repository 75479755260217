import { ComponentPortal, DomPortal, DomPortalOutlet, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, EventEmitter, Inject, Injectable, Injector, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { interval, Observable, of, Subject, Subscription } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { AppInjector } from 'src/app/app.module';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
type Portal = TemplatePortal | ComponentPortal<any> | DomPortal;
export const DATA_TOKEN = new InjectionToken<string>('portal-data');
export interface PortalDataResult {
  data?: any,
  manualClose?: boolean,
  type?: any
}
export interface CDKPortalComponent {
  close: EventEmitter<any>,
  results: EventEmitter<PortalDataResult | any>,
}
export interface ICDKPortalComponent {
  close: EventEmitter<any>,
  results: EventEmitter<PortalDataResult | any>,
}
@Injectable({
  providedIn: 'root'
})
export class ZenCDKService {
  private modalOverlay: OverlayRef = this.overlay.create({});

  private componentRef: any;

  subscribtion: Subscription;
  constructor(private overlay: Overlay) {
    this.modalOverlay = this.overlay.create();
  }
  openComponent(component, data?) {
    const portalData = Injector.create({
      providers: [{ provide: DATA_TOKEN, useValue: data }],
    });

    this.componentRef = this.modalOverlay.attach(new ComponentPortal(component, null, portalData));
    this.componentRef.instance.myInput = data;
    const subscribtion: Subscription = this.componentRef.instance.close.subscribe((val: any) => {
      this.modalOverlay.detach();
      subscribtion.unsubscribe();
    });
    return this.componentRef.instance.results.pipe(delay(300), tap((val: any) => {
      if (!val?.manualClose) {
        this.modalOverlay.detach();
      }
    }), map(x => x));
  }

  closeAll() {
    this.modalOverlay.detach();
  }
}
