import { QuestionComment } from './../data-models/question-comments';
import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { HttpClient } from '@angular/common/http';
import { Section } from '../data-models/section';
import { Question } from '../data-models/question';
import * as _ from 'lodash';
import currenciesList from '../../../assets/Translations/Currencies.json';
import countryList from '../../../assets/Translations/Countries.json';
import { CompanyPermission, products } from '../data-models/company-permission';
import { Router } from '@angular/router';
import { LocalService } from './local.service';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { ResponseDialogService } from './response-dialog.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { GlobalsService } from './globals.service';
import QuestionOptions from '../../../assets/Translations/QuestionOptions.json';
import { EssentialObjectService } from './essential-object.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  platformLink: string = 'https://ex.zenithr.net/';
  questionOps = QuestionOptions;
  zenLogo = 'https://s3.me-south-1.amazonaws.com/corporatematerials/Logos/ZENITHR%20Logo.svg';
  defaultLogoUrl: string =
    'https://firebasestorage.googleapis.com/v0/b/exitanalysis-a6325.appspot.com/o/no-logo.png?alt=media&token=3a2e7d05-8393-4c8f-9c64-f17b3bded1b9';
  readonly leaverAnswerCombination = [
    ['half', 'half'],
    ['half', 'quarter', 'quarter'],
    ['quarter', 'quarter', 'quarter', 'quarter'],
  ];
  isDev: boolean = false;
  imageToBase64(request) {
    return this.http.post(
      this.fbFunctionURL + 'getBase64FromURL',
      request
    );
  }

  getQuestionOptions(subquestionKey: string, key: string, language: string) {
    let q: any;
    if (subquestionKey == 'single-answer-rating') {
      q = this.questionOps.find(q => q.key == 'single-answer-confidence');
    }
    if (subquestionKey == 'single-answer-confidence-with-na') {
      q = this.questionOps.find(q => q.key == 'single-answer-confidence-with-na');
    }

    if (q) {
      let qOptions = q.Options;
      let option = qOptions.find(o => o.key == key);
      if (option && option?.name)
        return this.languageService.extractNameLanguage(option.name, language);
    }

    // if (subquestionKey == 'sub-questions-confidence-rating')
    //   return this.generalService.confidenceRating.find(cf => { return (cf.key == key && cf.lang == language) }).name;
    // if (subquestionKey == 'sub-questions-likeness-rating')
    //   return this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == language) }).name;
    // if (subquestionKey == 'sub-questions-likeness-na-rating')
    //   return this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == language) }).name;
    // if (subquestionKey == 'sub-questions-binary-likeness-rating')
    //   return this.generalService.binaryLikeness.find(cf => { return (cf.key == key && cf.lang == language) }).name;
    // if (subquestionKey == 'single-answer-rating')
    //   return this.generalService.singleAnswerConfidenceRating.find(cf => { return (cf.key == key && cf.lang == language) }).name;
    // if (subquestionKey == 'single-answer-out-10')
    //   return this.generalService.singleAnswerOutof10.find(cf => { return (cf.key == key) }).name;
    // if (subquestionKey == 'single-answer-confidence-no-neutral')
    //   return this.generalService.confidenceRatingWithoutNA.find(cf => { return (cf.key == key && cf.lang == language) }).name;
    return q;
  }

  hasPermission(entityKey: string, product: string, permissions: CompanyPermission[], superUser?: boolean) {
    if (superUser)
      return true;
    let entityPermission = permissions?.find(e => e.key == entityKey);
    if (entityPermission) {
      if (entityPermission.products.find(p => p == product))
        return true;
      else
        return false;
    }
    return false;
  }
  ModuleNames = ModuleName;

  resolveRoute(entityKey, permissions: CompanyPermission[]) {
    let permission = permissions.find(e => e.key == entityKey);
    if (permission && permission.products.length > 0) {
      if (!this.localService.getJsonValue('FIRSTROUTE'))
        this.localService.setJsonValue('PermissionAlert', 'TRUE');
      else
        this.localService.removeItem('FIRSTROUTE');
      let product = permission.products.find((p) => p != '');
      if (product == products.Engagement) return '/engagement/home';
      if (product == products.Assessment) return '/assessment360/home';
      if (product == products.Happiness) return '/happiness/home';
      if (product == products.Exit) return '/exit-v2/home';
      if (product == products.Overview) return '/accountoverview';
      if (product == products.Settings) return '/settings';
      if (product == products.Assessment180) return '/assessment180/home';
      if (product == products.OnBoarding) return '/onboarding/home';

    } else {
      return '/login';
    }
    return '';
  }

  resolvePermissionAlert(language: string) {
    let alertPermission = this.localService.getJsonValue('PermissionAlert');
    if (alertPermission != null && alertPermission == 'TRUE') {
      this.responseDialogService.confirmAllocation(this.translationPipe.transform("txtNoPermission", language, this.ModuleNames.Common), "Permissions", this.translationPipe.transform("txt_okay_action", language, this.ModuleNames.Common), '');
      this.localService.removeItem('PermissionAlert');
    }
  }

  getAgeGroup(dateOfBirth: Date) {
    let timeDiff = Math.abs(Date.now() - dateOfBirth.getTime());
    let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    if (age < 20) return '<20';
    if (age >= 20 && age < 30) return '20-29';
    if (age >= 30 && age < 40) return '30-39';
    if (age >= 40 && age < 50) return '40-49';
    if (age >= 50 && age < 60) return '50-59';
    if (age >= 60) return '>=60';
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getTenureGroup(joiningDate: Date) {
    let timeDiff = Math.abs(Date.now() - joiningDate.getTime());
    let tenure = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    if (tenure < 1) return '<1';
    if (tenure >= 1 && tenure < 3) return '1-3';
    if (tenure >= 3 && tenure < 5) return '3-5';
    if (tenure >= 5 && tenure < 10) return '5-10';
    if (tenure >= 10) return '>=10';
  }

  getGeneration(dateOfBirth: Date) {
    let year = dateOfBirth.getFullYear();

    if (year < 1946) return 'maturists';
    if (year >= 1946 && year < 1961) return 'babyboomers';
    if (year >= 1961 && year < 1981) return 'genx';
    if (year >= 1981 && year < 1996) return 'geny';
    if (year >= 1996 && year < 2011) return 'genz';
    if (year >= 2011) return 'gena';
  }

  // localGetItem(value: string) {
  //   if (value) {
  //     if (value != '') {
  //       let val = localStorage.getItem(this.simpleCrypto.encrypt(value));
  //       if (val) {
  //         return this.simpleCrypto.decrypt(val).toString();
  //       } else {
  //         return val
  //       }
  //     }
  //   }
  //   return null;
  // }

  // localSetItem(key: string, value: string) {
  //   if (key) {
  //     if (key != '')
  //       localStorage.setItem(this.simpleCrypto.encrypt(key), this.simpleCrypto.encrypt(value));
  //   }
  // }
  // localRemoveItem(value: string) {
  //   if (value) {
  //     if (value != '')
  //       return localStorage.removeItem(this.simpleCrypto.encrypt(value));
  //   }
  //   return '';
  // }

  // sessionSetItem(key: string, value: string) {
  //   if (key) {
  //     if (key != '')
  //     localService.setJsonValue(this.simpleCrypto.encrypt(key), this.simpleCrypto.encrypt(value));
  //   }
  // }

  // sessionGetItem(value: string) {
  //   if (value) {
  //     if (value != '') {
  //       let val = sessionStorage.getItem(this.simpleCrypto.encrypt(value));
  //       if (val) {
  //         return this.simpleCrypto.decrypt(val).toString();
  //       } else {
  //         return val;
  //       }
  //     }
  //   }
  //   return null;
  // }

  exportPDF(request) {
    return this.http.post(
      'http://localhost:5000/exitanalysis-a6325/us-central1/exportReport',
      request
    );
  }

  evaluatorLimit: number = 20;

  performanceIconColors = [
    { key: 0, value: '#0398fc' },
    { key: 1, value: '#18fc03' },
    { key: 2, value: '#fc9803' },
    { key: 3, value: '#fcf403' },
    { key: 4, value: '#fc1403' },
  ];

  fixedGroupingOptions: any[] = [
   
    {
      key: 'BandLevel',
      name: [
        { key: 'EN', name: 'Band Level' },
        { key: 'AR', name: 'المستوى الوظيفي' },
      ],
    },
    {
      key: 'PerformanceRate',
      name: [
        { key: 'EN', name: 'Performance Rate' },
        { key: 'AR', name: 'الأداء' },
      ],
    },
    {
      key: 'JobGrade',
      name: [
        { key: 'EN', name: 'Job Grade' },
        { key: 'AR', name: 'الدرجة الوظيفية' },
      ],
    },

    {
      key: 'AgeGroup',
      name: [
        { key: 'EN', name: 'Age Group' },
        { key: 'AR', name: 'المجموعة العمرية' },
      ],
    },
    {
      key: 'SeperationType',
      name: [
        { key: 'EN', name: 'Seperation Type' },
        { key: 'AR', name: 'نوع الفصل' },
      ],
    },
    {
      key: 'TenureGroup',
      name: [
        { key: 'EN', name: 'Tenure Group' },
        { key: 'AR', name: 'مجموعة سنوات الخدمة' },
      ],
    },
    {
      key: 'Generation',
      name: [
        { key: 'EN', name: 'Generation' },
        { key: 'AR', name: 'نوع الجيل' },
      ],
    },
    {
      key: 'Yearly',
      name: [
        { key: 'EN', name: 'Yearly' },
        { key: 'AR', name: 'سنوي' },
      ],
    },
    {
      key: 'Quarterly',
      name: [
        { key: 'EN', name: 'Quarterly' },
        { key: 'AR', name: 'ربع سنوي' },
      ],
    },
    {
      key: 'Monthly',
      name: [
        { key: 'EN', name: 'Monthly' },
        { key: 'AR', name: 'شهري' },
      ],
    },
    {
      key: 'Gender',
      name: [
        { key: 'EN', name: 'Gender' },
        { key: 'AR', name: 'الجنس' },
        { key: 'HI', name: 'लिंग' },
        { key: 'TA', name: 'Kasarian' },
        { key: 'UR', name: 'جنس' }
      ],
    },
    {
      key: 'Nationality',
      name: [
        { key: 'EN', name: 'Nationality' },
        { key: 'AR', name: 'الجنسية' },
        { key: "HI", name: "राष्ट्रीयता" },
        { key: "TA", name: "Nasyonalidad" },
        { key: "UR", name: "قومیت" }
      ],
    },
  ];

  // Language Related Fields
  quarters: any[] = [
    { key: 1, lang: 'EN', name: 'Q1' },
    { key: 2, lang: 'EN', name: 'Q2' },
    { key: 3, lang: 'EN', name: 'Q3' },
    { key: 4, lang: 'EN', name: 'Q4' },
  ];

  // lang Related Fields
  industries: any[] = [
    { key: 'Agriculture', language: 'EN', name: 'Agricultural Services & Products Sector' },
    { key: 'Agriculture', language: 'AR', name: 'قطاع الخدمات والمنتجات الزراعية' },

    { key: 'Automotive', language: 'EN', name: 'Automotive Sector' },
    { key: 'Automotive', language: 'AR', name: 'قطاع السيارات' },

    { key: 'Aviation', language: 'EN', name: 'Aviation Sector' },
    { key: 'Aviation', language: 'AR', name: 'قطاع الطيران' },

    { key: 'Banking', language: 'EN', name: 'Banking Sector' },
    { key: 'Banking', language: 'AR', name: 'قطاع البنوك' },

    { key: 'Construction', language: 'EN', name: 'Construction Sector' },
    { key: 'Construction', language: 'AR', name: 'قطاع أعمال البناء' },

    { key: 'Consulting', language: 'EN', name: 'Consulting Services Sector' },
    { key: 'Consulting', language: 'AR', name: 'قطاع الخدمات الاستشارية' },

    { key: 'E-Commerce', language: 'EN', name: 'E-Commerce' },
    { key: 'E-Commerce', language: 'AR', name: 'قطاع التجارة الإلكترونية' },

    { key: 'Education', language: 'EN', name: 'Education' },
    { key: 'Education', language: 'AR', name: 'قطاع التعليم' },

    { key: 'Engineering', language: 'EN', name: 'Engineering Consulting/Architecture Sector' },
    { key: 'Engineering', language: 'AR', name: 'قطاع  الاستشارات الهندسية / العمارة' },

    { key: 'FandB', language: 'EN', name: 'Retail - F & B Sector' },
    { key: 'FandB', language: 'AR', name: 'قطاع البيع بالتجزئة - الأغذية' },

    { key: 'FashionandApparel', language: 'EN', name: 'Retail - Fashion Sector' },
    { key: 'FashionandApparel', language: 'AR', name: 'قطاع البيع بالتجزئة - الأزياء' },

    { key: 'EventsManagement', language: 'EN', name: 'Events Management Sector' },
    { key: 'EventsManagement', language: 'AR', name: 'قطاع إدارة المناسبات' },

    { key: 'Exhibition', language: 'EN', name: 'Exhibitions Sector' },
    { key: 'Exhibition', language: 'AR', name: 'قطاع المعارض' },

    {
      key: 'GovernmentPublicServices',
      language: 'EN',
      name: 'Government/ Public Services Sector',
    },
    {
      key: 'GovernmentPublicServices',
      language: 'AR',
      name: 'قطاع حكومي / الخدمات العامة',
    },

    { key: 'OilGas', language: 'EN', name: 'Oil & Gas Sector' },
    { key: 'OilGas', language: 'AR', name: 'قطاع النفط والغاز' },

    { key: 'Healthcare', language: 'EN', name: 'Healthcare Sector' },
    { key: 'Healthcare', language: 'AR', name: 'قطاع الرعاىة الصحية' },

    { key: 'Hospitality', language: 'EN', name: 'Hospitality Sector' },
    { key: 'Hospitality', language: 'AR', name: 'قطاع الضيافة' },

    { key: 'Insurance', language: 'EN', name: 'Insurance Sector' },
    { key: 'Insurance', language: 'AR', name: 'قطاع التأمين' },

    { key: 'IT', language: 'EN', name: 'Information Technology Sector' },
    { key: 'IT', language: 'AR', name: 'قطاع تكنولوجيا المعلومات' },

    { key: 'FMCG', language: 'EN', name: 'FMCG Sector' },
    { key: 'FMCG', language: 'AR', name: 'قطاع السلع الاستهلاكية' },

    { key: 'InvestmentFSI', language: 'EN', name: 'Financial Services and Investment Sector' },
    { key: 'InvestmentFSI', language: 'AR', name: 'قطاع  الخدمات المالية والاستثمار' },

    { key: 'Manufacturing', language: 'EN', name: 'Manufacturing Sector' },
    { key: 'Manufacturing', language: 'AR', name: 'قطاع التصنيع' },

    { key: 'Media', language: 'EN', name: 'Media and Advertising Sector' },
    { key: 'Media', language: 'AR', name: 'قطاع  الإعلام والإعلان' },

    { key: 'LeisureTourism', language: 'EN', name: 'Travel & Tourism Sector' },
    { key: 'LeisureTourism', language: 'AR', name: 'قطاع السياحة والسفر ' },

    { key: 'Logistics', language: 'EN', name: 'Shipping and Logistics' },
    { key: 'Logistics', language: 'AR', name: 'قطاع الشحن والخدمات اللوجستية' },

    {
      key: 'NonGovernment Organization',
      language: 'EN',
      name: 'Non-Governmental Organization',
    },
    {
      key: 'NonGovernment Organization',
      language: 'AR',
      name: 'منظمة غير حكومية',
    },

    { key: 'Trading', language: 'EN', name: 'Trading and Contracting Sector' },
    { key: 'Trading', language: 'AR', name: 'قطاع  التجارة والمقاولات' },

    { key: 'Retail', language: 'EN', name: 'Retail Industry Sector' },
    { key: 'Retail', language: 'AR', name: 'قطاع التجزئه' },

    { key: 'RealEstate', language: 'EN', name: 'Real Estate Sector' },
    { key: 'RealEstate', language: 'AR', name: 'قطاع العقار' },

    { key: 'Other', language: 'EN', name: 'Other' },
    { key: 'Other', language: 'AR', name: 'أخرى' },

    { key: 'Wholesale', language: 'EN', name: 'Wholesale Sector' },
    { key: 'Wholesale', language: 'AR', name: 'قطاع البيع بالجملة' },

    { key: 'Utilities', language: 'EN', name: 'Utilities Sector' },
    { key: 'Utilities', language: 'AR', name: 'قطاع المرافق' },

    { key: 'Conglomerate', language: 'EN', name: 'Conglomerate Sector' },
    { key: 'Conglomerate', language: 'AR', name: 'قطاع الشركات متعددة الأنشطة' },

    { key: 'Consumer Services', language: 'EN', name: 'Consumer Services Sector' },
    { key: 'Consumer Services', language: 'AR', name: 'قطاع خدمات استهلاكية' },

    { key: 'Facilities Services', language: 'EN', name: 'Facilities Services Sector' },
    { key: 'Facilities Services', language: 'AR', name: 'قطاع خدمات المرافق' },

    { key: 'NonProfit', language: 'EN', name: 'Non-Profit Sector' },
    { key: 'NonProfit', language: 'AR', name: 'القطاع الغير ربحي' },

    { key: 'Pharmaceuticals', language: 'EN', name: 'Pharmaceuticals/Medical Equipment Sector' },
    { key: 'Pharmaceuticals', language: 'AR', name: 'قطاع  الأدوية / المعدات الطبية' },

    { key: 'ProfessionalServices', language: 'EN', name: 'Professional Services Sector' },
    { key: 'ProfessionalServices', language: 'AR', name: 'قطاع الخدمات الاحترافية' },

    { key: 'Industrial', language: 'EN', name: 'Industrial Sector' },
    { key: 'Industrial', language: 'AR', name: 'قطاع الصناعة' },

    { key: 'LawFirm', language: 'EN', name: 'Law Sector' },
    { key: 'LawFirm', language: 'AR', name: 'قطاع القانون والمحاماة' },

    { key: 'Services', language: 'EN', name: 'Services Sector' },
    { key: 'Services', language: 'AR', name: 'قطاع الخدمات' },

    { key: 'Telecommunication', language: 'EN', name: 'Telecommunication Sector' },
    { key: 'Telecommunication', language: 'AR', name: 'قطاع الاتصالات' },

    { key: 'Transportation', language: 'EN', name: 'Transportation Sector' },
    { key: 'Transportation', language: 'AR', name: 'قطاع النقل والمواصلات' },

    { key: 'Holding', language: 'EN', name: 'Holding Sector' },
    { key: 'Holding', language: 'AR', name: 'قطاع الشركات القابضة' },
  ];
  companySizes: any[] = [
    { key: '<50', language: 'EN', name: 'Less than 50' },
    { key: '>50<100', language: 'EN', name: '50 - 100' },
    { key: '>100', language: 'EN', name: '101 - 500' },
    { key: '>500', language: 'EN', name: '501 - 1000' },
    { key: '>1000', language: 'EN', name: '1001 - 2500' },
    { key: '>2500', language: 'EN', name: '2501 - 5000' },
    { key: '>5000', language: 'EN', name: 'More than 5000' },

    { key: '<50', language: 'AR', name: 'أقل من 50' },
    { key: '>50<100', language: 'AR', name: '50 - 100' },
    { key: '>100', language: 'AR', name: '101 - 500' },
    { key: '>500', language: 'AR', name: '501 - 1000' },
    { key: '>1000', language: 'AR', name: '1001 - 2500' },
    { key: '>2500', language: 'AR', name: '2501 - 5000' },
    { key: '>5000', language: 'AR', name: 'أكثر من 5000' },
  ];
  countries: any[] = [
    { key: 'KWT', language: 'EN', name: 'Kuwait', countryCode: '+965' },
    { key: 'JOR', language: 'EN', name: 'Jordan', countryCode: '+962' },
    { key: 'KSA', language: 'EN', name: 'Saudi Arabia', countryCode: '+966' },
    { key: 'AFG', language: 'EN', name: 'Afghanistan', countryCode: '+93' },

    { key: 'KWT', language: 'AR', name: 'Kuwait', countryCode: '+965' },
    { key: 'JOR', language: 'AR', name: 'Jordan', countryCode: '+962' },
    { key: 'KSA', language: 'AR', name: 'Saudi Arabia', countryCode: '+966' },
    { key: 'AFG', language: 'AR', name: 'Afghanistan', countryCode: '+93' },

    { key: 'AFG', language: 'FR', name: 'Afghanistan', countryCode: '+93' },
  ];

  engagementMetric: any[] = [
    {
      key: 'PERFORMANCE',
      name: [
        { key: 'EN', name: 'Performance' },
        { key: 'AR', name: 'الأداء' },
        { key: 'FR', name: 'Performance' },
        {
          key: 'HI',
          name: 'कार्य-निष्पादन'
        },
        {
          key: 'TA',
          name: 'Pagganap'
        },
        {
          key: 'UR',
          name: 'کارکردگی'
        }
      ],
    },
    {
      key: 'JOBGRADE',
      name: [
        { key: 'EN', name: 'Job Grade' },
        { key: 'AR', name: 'الدرجة الوظيفية' },
        { key: 'FR', name: 'Job Grade' },
      ],
    },
    {
      key: 'BANDLEVEL',
      name: [
        { key: 'EN', name: 'Band Level' },
        { key: 'AR', name: 'المستوى الوظيفي' },
        { key: 'FR', name: 'Band Level' },
      ],
    },
    {
      key: 'GENDER',
      name: [
        { key: 'EN', name: 'Gender' },
        { key: 'AR', name: 'الجنس' },
        { key: 'FR', name: 'Gender' },
        { key: 'HI', name: 'लिंग' },
        { key: 'TA', name: 'Kasarian' },
        { key: 'UR', name: 'جنس' }
      ],
    },
    {
      key: 'NATIONALITY',
      name: [
        { key: 'EN', name: 'Nationality' },
        { key: 'AR', name: 'الجنسية' },
        { key: 'FR', name: 'Nationality' },
        { key: 'HI', name: 'राष्ट्रीयता' },
        { key: 'TA', name: 'Nasyonalidad' },
        { key: 'UR', name: 'قومیت' }
      ],
    },
    {
      key: 'JOD',
      name: [
        { key: 'EN', name: 'Joining Date' },
        { key: 'AR', name: 'تاريخ الانضمام للعمل' },
        { key: 'FR', name: 'Joining Date' }
        ,
        {
          key: 'HI',
          name: 'कार्यग्रहण तिथि'
        },
        {
          key: 'TA',
          name: 'Petsa ng Pagsali'
        },
        {
          key: 'UR',
          name: 'شامل ہونے کی تاریخ'
        }
      ],
    },
    {
      key: 'DOB',
      name: [
        { key: 'EN', name: 'Date of Birth' },
        { key: 'AR', name: 'تاريخ الميلاد' },
        { key: 'FR', name: 'Date of Birth' }, {
          key: 'HI',
          name: 'जन्म की तारीख'
        },
        {
          key: 'TA',
          name: 'Araw ng kapanganakan'
        },
        {
          key: 'UR',
          name: 'تاریخ پیدائش'
        }
      ],
    },
  ];

  evaluatorTypes = [
    {
      key: 'MANAGER',
      name: [
        { key: 'EN', name: 'Manager' },
        { key: 'AR', name: 'مدير' },
      ],
    },
    {
      key: 'PEER',
      name: [
        { key: 'EN', name: 'Peer' },
        { key: 'AR', name: 'زميل' },
      ],
    },
    // { key: 'DIRECTREPORT', name: [{ key: 'EN', name: 'Direct Report' }, { key: 'AR', name: 'موظف تابع' }] },
    {
      key: 'TEAM',
      name: [
        { key: 'EN', name: 'Team' },
        { key: 'AR', name: 'فريق' },
      ],
    },
    {
      key: 'CUSTOMER',
      name: [
        { key: 'EN', name: 'Customer' },
        { key: 'AR', name: 'عميل' },
      ],
    },
  ];
  evaluatorTypes180 = [
    {
      key: 'TEAM',
      name: [
        { key: 'EN', name: 'Team' },
        { key: 'AR', name: 'فريق' },
      ],
    },
    {
      key: 'PEER',
      name: [
        { key: 'EN', name: 'Peer' },
        { key: 'AR', name: 'زميل' },
      ],
    }
  ];

  get countries_new() {
    return this.countries_new_1;
  }

  get currencies() {
    return this.currenciesList
  }

  currenciesList: any[] = currenciesList;

  countries_new_1: any[] = countryList
  countryCodes: any[] = countryList

  questionTypes: any[] = [
    {
      key: 'text',
      qAndSub: 0,
      name: [{ key: 'EN', name: 'Text Input' }],
      pageSectionSize: 'quarter',
    },
    // {
    //   key: 'rating-confidence', qAndSub: 0, name:
    //     [
    //       { key: 'EN', name: 'Confidence Rating' },
    //     ]
    // },
    {
      key: 'rating-liked',
      qAndSub: 0,
      name: [{ key: 'EN', name: 'Liking Rating' }],
      pageSectionSize: 'quarter',
    },
    {
      key: 'multi-options-multi-answer',
      qAndSub: 0,
      name: [{ key: 'EN', name: 'Multiple Options, Multi Answer' }],
      pageSectionSize: 'half',
    },
    {
      key: 'multi-options-single-answer',
      qAndSub: 0,
      name: [{ key: 'EN', name: 'Multiple Options, Single Answer' }],
      pageSectionSize: 'quarter',
    },

    {
      key: 'single-answer-confidence',
      qAndSub: 0,
      name: [{ key: 'EN', name: 'Confidence Single Answer ' }],
      pageSectionSize: 'quarter',
    },
    {
      key: 'single-answer-confidence-no-neutral',
      qAndSub: 0,
      name: [{ key: 'EN', name: 'Confidence Without Neutral Single Answer ' }],
      pageSectionSize: 'quarter',
    },
    {
      key: 'single-answer-out-10',
      qAndSub: 0,
      name: [{ key: 'EN', name: 'Out of 10 rating ' }],
      pageSectionSize: 'quarter',
    },
    // {
    //   key: 'binary-options', qAndSub: 0, name:
    //     [{ key: 'EN', name: 'Binary Options' },
    //     ]
    // },
    {
      key: 'sub-questions-confidence-rating',
      qAndSub: 2,
      name: [{ key: 'EN', name: 'Sub Questions Confidence Rating' }],
      pageSectionSize: 'half',
    },
    {
      key: 'sub-questions-likeness-rating',
      qAndSub: 2,
      name: [{ key: 'EN', name: 'Sub Questions Likeness Rating' }],
      pageSectionSize: 'half',
    },
    {
      key: 'sub-questions-likeness-na-rating',
      qAndSub: 2,
      name: [{ key: 'EN', name: 'Sub Questions Likeness with NA Rating' }],
      pageSectionSize: 'half',
    },
    // {
    //   key: 'sub-questions-binary-rating', qAndSub: 2, name:
    //     [{ key: 'EN', name: 'Sub Questions Binary Rating' },
    //     ]
    // },
    {
      key: 'sub-questions-binary-likeness-rating',
      qAndSub: 2,
      name: [{ key: 'EN', name: 'Sub Questions Binary Likeness Rating' }],
      pageSectionSize: 'half',
    },
    {
      key: 'single-answer-confidence-with-na',
      qAndSub: 2,
      name: [{ key: 'EN', name: 'Single Answer Confidence With NA' }],
      pageSectionSize: 'quarter'
    }
  ];

  darkGreen: string = '#3eb75b';
  lighterGreen: string = '#73b504';
  veryLightGreen: string = '#9dc62b';
  darkOrange: string = '#f0750f';
  lightOrange: string = '#ff9000';
  yellow: string = '#ff9000';
  darkRed: string = '#b70000';
  lighterRed: string = '#e64e4e';
  veryLightRed: string = '#ff0000';
  grey: string = '#808080';

  singleAnswerConfidenceRating: any[] = [
    { key: '5', lang: 'BN', name: 'দৃঢ়ভাবে একমত', color: this.darkGreen, },
    { key: '4', lang: 'BN', name: 'একমত', color: this.veryLightGreen, },
    { key: '3', lang: 'BN', name: 'নিরপেক্ষ', color: this.yellow, },
    { key: '2', lang: 'BN', name: 'অসম্মত', color: this.lightOrange, },
    { key: '1', lang: 'BN', name: 'দৃঢ়ভাবে অসম্মত', color: this.lighterRed, },
    { key: '0', lang: 'BN', name: 'N/A', color: this.grey, },


    { key: '5', lang: 'HI', name: 'बिलकुल सहमत', color: this.darkGreen, },
    { key: '4', lang: 'HI', name: 'सहमत', color: this.veryLightGreen, },
    { key: '3', lang: 'HI', name: 'निरपेक्ष', color: this.yellow, },
    { key: '2', lang: 'HI', name: 'असहमत', color: this.lightOrange, },
    { key: '1', lang: 'HI', name: 'बिलकुल असहमत', color: this.lighterRed, },
    { key: '0', lang: 'HI', name: 'N/A', color: this.grey, },
    { key: '5', lang: 'TA', name: 'Lubos na Sumasang-ayon', color: this.darkGreen, },
    { key: '4', lang: 'TA', name: 'Sumasang-ayon', color: this.veryLightGreen, },
    { key: '3', lang: 'TA', name: 'Neutral', color: this.yellow, },
    { key: '2', lang: 'TA', name: 'Hindi Sumasang-ayon', color: this.lightOrange, },
    { key: '1', lang: 'TA', name: 'Lubos na Hindi Sumasang-ayon', color: this.lighterRed, },
    { key: '0', lang: 'TA', name: 'N/A', color: this.grey, },
    { key: '5', lang: 'UR', name: 'انتہائی متفق', color: this.darkGreen, },
    { key: '4', lang: 'UR', name: 'متفق', color: this.veryLightGreen, },
    { key: '3', lang: 'UR', name: 'غیر جانبدار', color: this.yellow, },
    { key: '2', lang: 'UR', name: 'غیر متفق', color: this.lightOrange, },
    { key: '1', lang: 'UR', name: 'انتہائی غیر متفق', color: this.lighterRed, },
    { key: '0', lang: 'UR', name: 'N/A', color: this.grey, },
    { key: '5', lang: 'EN', name: 'Strongly Agree', color: this.darkGreen, },
    { key: '4', lang: 'EN', name: 'Agree', color: this.veryLightGreen, },
    { key: '3', lang: 'EN', name: 'Neutral', color: this.yellow, },
    { key: '2', lang: 'EN', name: 'Disagree', color: this.lightOrange, },
    { key: '1', lang: 'EN', name: 'Strongly Disagree', color: this.lighterRed, },
    { key: '0', lang: 'EN', name: 'N/A', color: this.grey, },
    { key: '5', lang: 'AR', name: 'أوافق بشدة', color: this.darkGreen, },
    { key: '4', lang: 'AR', name: 'أوافق', color: this.veryLightGreen, },
    { key: '3', lang: 'AR', name: 'محايد', color: this.yellow, },
    { key: '2', lang: 'AR', name: 'لا أوافق', color: this.lightOrange, },
    { key: '1', lang: 'AR', name: 'لا أوافق بشدة', color: this.lighterRed, },
    { key: '0', lang: 'AR', name: 'لا ينطبق', color: this.grey, },
  ];

  singleAnswerOutof10: any[] = [
    {
      key: '0',
      lang: 'EN',
      name: '0',
    },
    {
      key: '1',
      lang: 'EN',
      name: '1',
    },
    {
      key: '2',
      lang: 'EN',
      name: '2',
    },
    {
      key: '3',
      lang: 'EN',
      name: '3',
    },
    {
      key: '4',
      lang: 'EN',
      name: '4',
    },
    {
      key: '5',
      lang: 'EN',
      name: '5',
    },
    {
      key: '6',
      lang: 'EN',
      name: '6',
    },
    {
      key: '7',
      lang: 'EN',
      name: '7',
    },
    {
      key: '8',
      lang: 'EN',
      name: '8',
    },
    {
      key: '9',
      lang: 'EN',
      name: '9',
    },
    {
      key: '10',
      lang: 'EN',
      name: '10',
    },
  ];

  confidenceRatingWithoutNA: any[] = [
    {
      key: '4',
      lang: 'EN',
      name: 'Strongly Agree',
      color: this.darkGreen,
    },
    {
      key: '3',
      lang: 'EN',
      name: 'Agree',
      color: this.veryLightGreen,
    },
    {
      key: '2',
      lang: 'EN',
      name: 'Disagree',
      color: this.lightOrange,
    },
    {
      key: '1',
      lang: 'EN',
      name: 'Strongly Disagree',
      color: this.lighterRed,
    },
    {
      key: '4',
      lang: 'AR',
      name: 'أوافق بشدة',
      color: this.darkGreen,
    },
    {
      key: '3',
      lang: 'AR',
      name: 'أوافق  ',
      color: this.veryLightGreen,
    },
    {
      key: '2',
      lang: 'AR',
      name: 'لا أوافق',
      color: this.lightOrange,
    },
    {
      key: '1',
      lang: 'AR',
      name: 'لا أوافق بشدة',
      color: this.lighterRed,
    },
  ];

  confidenceRatingWithNA: any[] = [
    {
      key: '5',
      lang: 'EN',
      name: 'Strongly Agree',
      color: this.darkGreen,
    },
    {
      key: '4',
      lang: 'EN',
      name: 'Agree',
      color: this.veryLightGreen,
    },
    {
      key: '3',
      lang: 'EN',
      name: 'Neutral',
      color: this.yellow,
    },
    {
      key: '2',
      lang: 'EN',
      name: 'Disagree',
      color: this.lightOrange,
    },
    {
      key: '1',
      lang: 'EN',
      name: 'Strongly Disagree',
      color: this.lighterRed,
    },
    {
      key: '0',
      lang: 'EN',
      name: 'N/A',
      color: this.grey,
    },
    {
      key: '5',
      lang: 'AR',
      name: 'أوافق بشدة',
      color: this.darkGreen,
    },
    {
      key: '4',
      lang: 'AR',
      name: 'أوافق  ',
      color: this.veryLightGreen,
    },
    {
      key: '3',
      lang: 'AR',
      name: 'معتدل',
      color: this.yellow,
    },
    {
      key: '2',
      lang: 'AR',
      name: 'لا أوافق',
      color: this.lightOrange,
    },
    {
      key: '1',
      lang: 'AR',
      name: 'لا أوافق بشدة',
      color: this.lighterRed,
    },
    {
      key: '0',
      lang: 'AR',
      name: 'لا ينطبق',
      color: this.grey,
    },
  ];
  // STEP 1
  moderateFairnessQuestion: any[] = [
    {
      key: 'EF',
      lang: 'EN',
      name: 'Extremely Fair',
      color: this.darkGreen,
    },
    {
      key: 'MF',
      lang: 'EN',
      name: 'Moderately Fair',
      color: this.lighterGreen,
    },
    {
      key: 'MUF',
      lang: 'EN',
      name: 'Moderately Unfair',
      color: this.lighterRed,
    },
    {
      key: 'EUF',
      lang: 'EN',
      name: 'Extremely Unfair',
      color: this.grey,
    },

    {
      key: 'EX',
      lang: 'AR',
      name: 'ممتاز',
      color: this.darkGreen,
    },
    {
      key: 'G',
      lang: 'AR',
      name: 'جيد',
      color: this.lighterGreen,
    },
    {
      key: 'F',
      lang: 'AR',
      name: 'مقبول',
      color: this.yellow,
    },
    {
      key: 'P',
      lang: 'AR',
      name: 'ضعيف',
      color: this.lighterRed,
    },
  ];
  moderateFairnessQuestionWithNA: any[] = [
    {
      key: 'EF',
      lang: 'EN',
      name: 'Extremely Fair',
      color: this.darkGreen,
    },
    {
      key: 'MF',
      lang: 'EN',
      name: 'Moderately Fair',
      color: this.lighterGreen,
    },
    {
      key: 'MUF',
      lang: 'EN',
      name: 'Moderately Unfair',
      color: this.lighterRed,
    },
    {
      key: 'EUF',
      lang: 'EN',
      name: 'Extremely Unfair',
      color: this.grey,
    },
    {
      key: 'NA',
      lang: 'EN',
      name: 'N/A',
      color: this.grey,
    },
    {
      key: 'EF',
      lang: 'AR',
      name: 'ممتاز',
      color: this.darkGreen,
    },
    {
      key: 'MF',
      lang: 'AR',
      name: 'جيد',
      color: this.lighterGreen,
    },
    {
      key: 'MUF',
      lang: 'AR',
      name: 'مقبول',
      color: this.yellow,
    },
    {
      key: 'EUF',
      lang: 'AR',
      name: 'ضعيف',
      color: this.lighterRed,
    },
    {
      key: 'NA',
      lang: 'AR',
      name: 'ضعيف',
      color: this.lighterRed,
    },
  ];
  likingRatingWithoutNA: any[] = [
    {
      key: 'EX',
      lang: 'EN',
      name: 'Excellent',
      color: this.darkGreen,
    },
    {
      key: 'G',
      lang: 'EN',
      name: 'Good',
      color: this.lighterGreen,
    },
    {
      key: 'F',
      lang: 'EN',
      name: 'Fair',
      color: this.yellow,
    },
    {
      key: 'P',
      lang: 'EN',
      name: 'Poor',
      color: this.lighterRed,
    },

    {
      key: 'EX',
      lang: 'AR',
      name: 'ممتاز',
      color: this.darkGreen,
    },
    {
      key: 'G',
      lang: 'AR',
      name: 'جيد',
      color: this.lighterGreen,
    },
    {
      key: 'F',
      lang: 'AR',
      name: 'مقبول',
      color: this.yellow,
    },
    {
      key: 'P',
      lang: 'AR',
      name: 'ضعيف',
      color: this.lighterRed,
    },
  ];
  confidenceRating: any[] = [
    {
      key: 'STA',
      lang: 'EN',
      name: 'Strongly Agree',
      color: this.darkGreen,
    },
    {
      key: 'A',
      lang: 'EN',
      name: 'Agree',
      color: this.veryLightGreen,
    },
    {
      key: 'DA',
      lang: 'EN',
      name: 'Disagree',
      color: this.lightOrange,
    },
    {
      key: 'SDA',
      lang: 'EN',
      name: 'Strongly Disagree',
      color: this.lighterRed,
    },
    {
      key: 'STA',
      lang: 'AR',
      name: 'أوافق بشدة',
      color: this.darkGreen,
    },
    {
      key: 'A',
      lang: 'AR',
      name: 'أوافق  ',
      color: this.veryLightGreen,
    },
    {
      key: 'DA',
      lang: 'AR',
      name: 'لا أوافق',
      color: this.lightOrange,
    },
    {
      key: 'SDA',
      lang: 'AR',
      name: 'لا أوافق بشدة',
      color: this.lighterRed,
    },
  ];
  confidenceHappinessRating: any[] = [
    {
      key: 'STA',
      lang: 'EN',
      name: 'Extremely Happy',
      color: this.darkGreen,
    },
    {
      key: 'A',
      lang: 'EN',
      name: 'Happy',
      color: this.veryLightGreen,
    },
    {
      key: 'DA',
      lang: 'EN',
      name: 'Unhappy',
      color: this.lightOrange,
    },
    {
      key: 'SDA',
      lang: 'EN',
      name: 'Extremely Unhappy',
      color: this.lighterRed,
    },
    {
      key: 'STA',
      lang: 'AR',
      name: 'أوافق بشدة',
      color: this.darkGreen,
    },
    {
      key: 'A',
      lang: 'AR',
      name: 'أوافق  ',
      color: this.veryLightGreen,
    },
    {
      key: 'DA',
      lang: 'AR',
      name: 'لا أوافق',
      color: this.lightOrange,
    },
    {
      key: 'SDA',
      lang: 'AR',
      name: 'لا أوافق بشدة',
      color: this.lighterRed,
    },
  ]


  likingRating: any[] = [
    {
      key: 'EX',
      lang: 'EN',
      name: 'Excellent',
      color: this.darkGreen,
    },
    {
      key: 'G',
      lang: 'EN',
      name: 'Good',
      color: this.lighterGreen,
    },
    {
      key: 'F',
      lang: 'EN',
      name: 'Fair',
      color: this.yellow,
    },
    {
      key: 'P',
      lang: 'EN',
      name: 'Poor',
      color: this.lighterRed,
    },
    {
      key: 'NA',
      lang: 'EN',
      name: 'N/A',
      color: this.grey,
    },

    {
      key: 'EX',
      lang: 'AR',
      name: 'ممتاز',
      color: this.darkGreen,
    },
    {
      key: 'G',
      lang: 'AR',
      name: 'جيد',
      color: this.lighterGreen,
    },
    {
      key: 'F',
      lang: 'AR',
      name: 'مقبول',
      color: this.yellow,
    },
    {
      key: 'P',
      lang: 'AR',
      name: 'ضعيف',
      color: this.lighterRed,
    },
    {
      key: 'NA',
      lang: 'AR',
      name: 'لا ينطبق',
      color: this.grey,
    },
  ];

  binaryLikeness: any[] = [
    {
      key: 'ML',
      lang: 'EN',
      name: 'Most Liked',
      color: this.lighterGreen,
    },
    {
      key: 'LL',
      lang: 'EN',
      name: 'Least Liked',
      color: this.lighterRed,
    },
    {
      key: 'ML',
      lang: 'AR',
      name: 'الأكثر تفضيلًا',
      color: this.lighterGreen,
    },
    {
      key: 'LL',
      lang: 'AR',
      name: 'الأقل تفضيلًا',
      color: this.lighterRed,
    },
  ];

  genders: any[] = [
    {
      key: 'male',
      lang: 'EN',
      name: 'Male',
    },
    {
      key: 'female',
      lang: 'EN',
      name: 'Female',
    },
    {
      key: 'male',
      lang: 'AR',
      name: 'ذكر',
    },
    {
      key: 'female',
      lang: 'AR',
      name: 'أنثى',
    },
  ];

  get genders_new() {
    return this.genders_new_1;
  }

  genders_new_1: any[] = [
    {
      key: 'male',
      name: [
        { key: 'EN', name: 'Male' },
        { key: 'AR', name: 'ذكر' },
        { key: 'HI', name: 'पुरुष' },
        { key: 'TA', name: 'Lalaki' },
        { key: 'UR', name: 'مرد' },
        { key: 'BN', name: 'পুরুষ' },
      ],
    },
    {
      key: 'female',
      name: [
        { key: 'EN', name: 'Female' },
        { key: 'AR', name: 'أنثى' },
        { key: 'HI', name: 'महिला' },
        { key: 'TA', name: 'Babae' },
        { key: 'UR', name: 'عورت' },
        { key: 'BN', name: 'মহিলা' },
      ],
    },
  ];

  genders_list: any[] = [
    {
      key: 'male',
      name: [
        { key: 'EN', name: 'Male' },
        { key: 'AR', name: 'ذكر' },
        { key: 'HI', name: 'पुरुष' },
        { key: 'TA', name: 'Lalaki' },
        { key: 'UR', name: 'مرد' },
        { key: 'BN', name: 'পুরুষ' },
      ],
    },
    {
      key: 'female',
      name: [
        { key: 'EN', name: 'Female' },
        { key: 'AR', name: 'أنثى' },
        { key: 'HI', name: 'महिला' },
        { key: 'TA', name: 'Babae' },
        { key: 'UR', name: 'عورت' },
        { key: 'BN', name: 'মহিলা' },
      ],
    },
  ];

  seperationTypes: any[] = [
    {
      key: 'resignation',
      lang: 'EN',
      name: 'Resignation',
    },
    {
      key: 'termination',
      lang: 'EN',
      name: 'Termination',
    },
    {
      key: 'retirement',
      lang: 'EN',
      name: 'Retirement',
    },
    {
      key: 'resignation',
      lang: 'AR',
      name: 'استقالة',
    },
    {
      key: 'termination',
      lang: 'AR',
      name: 'إنهاء الخدمات',
    },
    {
      key: 'retirement',
      lang: 'AR',
      name: 'تقاعد',
    },
  ];

  dashboardAnalysisNames: any[] = [
    {
      key: '1',
      name: [
        { key: 'EN', name: 'Leaver Analysis' },
        { key: 'AR', name: 'تحليل الموظف المغادر' },
      ],
    },
    {
      key: '2',
      name: [
        { key: 'EN', name: 'Seperation Types' },
        { key: 'AR', name: 'نوع الفصل' },
      ],
    },
    {
      key: '3',
      name: [
        { key: 'EN', name: 'Total Leavers' },
        { key: 'AR', name: 'العدد الكلي للموظفين المغادرين' },
      ],
    },
  ];

  days = [
    { short: "Sun", long: "Sunday", languge: "EN" },
    { short: "الأحد", long: "الأحد", languge: "AR" },
    { short: "Mon", long: "Monday", languge: "EN" },
    { short: "الإثنين", long: "الإثنين", languge: "AR" },
    { short: "Tue", long: "Tuesday", languge: "EN" },
    { short: "الثلاثاء", long: "الثلاثاء", languge: "AR" },
    { short: "Wed", long: "Wednsday", languge: "EN" },
    { short: "الأربعاء", long: "الأربعاء", languge: "AR" },
    { short: "Thu", long: "Thursday", languge: "EN" },
    { short: "الخميس", long: "الخميس", languge: "AR" },
    { short: "Fri", long: "Friday", languge: "EN" },
    { short: "الجمعة", long: "الجمعة", languge: "AR" },
    { short: "Sat", long: "Saturday", languge: "EN" },
    { short: "السبت", long: "السبت", languge: "AR" }
  ]
  monthNames: any[] = [
    {
      key: 1,
      Gregorian: [
        { key: 'EN', name: 'January' },
        { key: 'AR', name: 'يناير' },
        { key: 'HI', name: 'जनवरी' },
        { key: 'TA', name: 'Enero' },
        { key: 'UR', name: 'جنوری' },
        { key: 'BN', name: 'জানুয়ারি' },
      ],
      Hijri: [
        { key: 'AR', name: 'محرّم' },
        { key: 'HI', name: 'मुहर्रम' },
        { key: 'TA', name: 'Muharram' },
        { key: 'EN', name: 'Muharram' },
        { key: 'UR', name: 'محرم' },
        { key: 'BN', name: 'মহররম' },
      ]
    },
    {
      key: 2,
      Gregorian: [
        { key: 'EN', name: 'February' },
        { key: 'AR', name: 'فبراير' },
        { key: 'HI', name: 'फरवरी' },
        { key: 'TA', name: 'Pebrero' },
        { key: 'UR', name: 'فروری' },
        { key: 'BN', name: 'ফেব্রুয়ারি' },
      ],
      Hijri: [
        { key: 'AR', name: 'صفر' },
        { key: 'HI', name: 'सफ़र' },
        { key: 'TA', name: 'Safar' },
        { key: 'EN', name: 'Safar' },
        { key: 'UR', name: 'صفر' },
        { key: 'BN', name: 'সফর' },
      ]
    },
    {
      key: 3,
      Gregorian: [
        { key: 'EN', name: 'March' },
        { key: 'AR', name: 'مارس' },
        { key: 'HI', name: 'मार्च' },
        { key: 'TA', name: 'Marso' },
        { key: 'UR', name: 'مارچ' },
        { key: 'BN', name: 'মার্চ' },
      ],
      Hijri: [
        { key: 'AR', name: 'ربيع الأول' },
        { key: 'HI', name: 'रबीअलअव्वल' },
        { key: 'TA', name: 'Rabi\' al-Awwal' },
        { key: 'EN', name: 'Rabi\' al-Awwal' },
        { key: 'UR', name: 'ربیع الاول' },
        { key: 'BN', name: 'রবিউল আউয়াল' },
      ]
    },
    {
      key: 4,
      Gregorian: [
        { key: 'EN', name: 'April' },
        { key: 'AR', name: 'أبريل' },
        { key: 'HI', name: 'अप्रैल' },
        { key: 'TA', name: 'Abril' },
        { key: 'UR', name: 'اپریل' },
        { key: 'BN', name: 'এপ্রিল' },
      ],
      Hijri: [
        { key: 'AR', name: 'ربيع الثاني' },
        { key: 'HI', name: 'रबीअलआखिर' },
        { key: 'TA', name: 'Rabi\' al-Thani' },
        { key: 'EN', name: 'Rabi\' al-Thani' },
        { key: 'UR', name: 'ربیع الثانی' },
        { key: 'BN', name: 'রবিউস সানি' },
      ]
    },
    {
      key: 5,
      Gregorian: [
        { key: 'EN', name: 'May' },
        { key: 'AR', name: 'مايو' },
        { key: 'HI', name: 'मई' },
        { key: 'TA', name: 'Mayo' },
        { key: 'UR', name: 'مئی' },
        { key: 'BN', name: 'মে' },
      ],
      Hijri: [
        { key: 'AR', name: 'جمادى الأولى' },
        { key: 'HI', name: 'जुमादअलअव्वल' },
        { key: 'TA', name: 'Jumada al-Awwal' },
        { key: 'EN', name: 'Jumada al-Awwal' },
        { key: 'UR', name: 'جمادی الاول' },
        { key: 'BN', name: 'জুমাদিউল আউয়াল' },
      ]
    },
    {
      key: 6,
      Gregorian: [
        { key: 'EN', name: 'June' },
        { key: 'AR', name: 'يونيو' },
        { key: 'HI', name: 'जून' },
        { key: 'TA', name: 'Hunyo' },
        { key: 'UR', name: 'جون' },
        { key: 'BN', name: 'জুন' },
      ],
      Hijri: [
        { key: 'AR', name: 'جمادى الآخرة' },
        { key: 'HI', name: 'जुमादअलआखिर' },
        { key: 'TA', name: 'Jumada al-Thani' },
        { key: 'EN', name: 'Jumada al-Thani' },
        { key: 'UR', name: 'جمادی الثانی' },
        { key: 'BN', name: 'জুমাদিউস সানি' },
      ]
    },
    {
      key: 7,
      Gregorian: [
        { key: 'EN', name: 'July' },
        { key: 'AR', name: 'يوليو' },
        { key: 'HI', name: 'जुलाई' },
        { key: 'TA', name: 'Hulyo' },
        { key: 'UR', name: 'جولائی' },
        { key: 'BN', name: 'জুলাই' },
      ],
      Hijri: [
        { key: 'AR', name: 'رجب' },
        { key: 'HI', name: 'रजब' },
        { key: 'TA', name: 'Rajab' },
        { key: 'EN', name: 'Rajab' },
        { key: 'UR', name: 'رجب' },
        { key: 'BN', name: 'রজব' },
      ]
    },

    {
      key: 8,
      Gregorian: [
        { key: 'EN', name: 'August' },
        { key: 'AR', name: 'أغسطس' },
        { key: 'HI', name: 'अगस्त' },
        { key: 'TA', name: 'Agosto' },
        { key: 'UR', name: 'اگست' },
        { key: 'BN', name: 'আগস্ট' },
      ],
      Hijri: [
        { key: 'AR', name: 'شعبان' },
        { key: 'HI', name: 'शाबान' },
        { key: 'TA', name: 'Sha\'ban' },
        { key: 'EN', name: 'Sha\'ban' },
        { key: 'UR', name: 'شعبان' },
        { key: 'BN', name: 'শাবান' },
      ]
    },
    {
      key: 9,
      Gregorian: [
        { key: 'EN', name: 'September' },
        { key: 'AR', name: 'سبتمبر' },
        { key: 'HI', name: 'सितंबर' },
        { key: 'TA', name: 'Setyembre' },
        { key: 'UR', name: 'ستمبر' },
        { key: 'BN', name: 'সেপ্টেম্বর' },
      ],
      Hijri: [
        { key: 'AR', name: 'رمضان' },
        { key: 'HI', name: 'रमज़ान' },
        { key: 'TA', name: 'Ramadan' },
        { key: 'EN', name: 'Ramadan' },
        { key: 'UR', name: 'رمضان' },
        { key: 'BN', name: 'রমজান' },
      ]
    },
    {
      key: 10,
      Gregorian: [
        { key: 'EN', name: 'October' },
        { key: 'AR', name: 'أكتوبر' },
        { key: 'HI', name: 'अक्टूबर' },
        { key: 'TA', name: 'Oktubre' },
        { key: 'UR', name: 'اکتوبر' },
        { key: 'BN', name: 'অক্টোবর' },
      ],
      Hijri: [
        { key: 'AR', name: 'شوّال' },
        { key: 'HI', name: 'शव्वाल' },
        { key: 'TA', name: 'Shawwal' },
        { key: 'EN', name: 'Shawwal' },
        { key: 'UR', name: 'شوال' },
        { key: 'BN', name: 'শাওয়াল' },
      ]
    },
    {
      key: 11,
      Gregorian: [
        { key: 'EN', name: 'November' },
        { key: 'AR', name: 'نوفمبر' },
        { key: 'HI', name: 'नवंबर' },
        { key: 'TA', name: 'Nobyembre' },
        { key: 'UR', name: 'نومبر' },
        { key: 'BN', name: 'নভেম্বর' },
      ],
      Hijri: [
        { key: 'AR', name: 'ذو القعدة' },
        { key: 'HI', name: 'धुआलक़आदह' },
        { key: 'TA', name: 'Dhu al-Qi\'dah' },
        { key: 'EN', name: 'Dhu al-Qi\'dah' },
        { key: 'UR', name: 'ذو القعدہ' },
        { key: 'BN', name: 'জিলকদ' },
      ]
    },
    {
      key: 12,
      Gregorian: [
        { key: 'EN', name: 'December' },
        { key: 'AR', name: 'ديسمبر' },
        { key: 'HI', name: 'दिसंबर' },
        { key: 'TA', name: 'Disyembre' },
        { key: 'UR', name: 'دسمبر' },
        { key: 'BN', name: 'ডিসেম্বর' },
      ],
      Hijri: [
        { key: 'AR', name: 'ذو الحجة' },
        { key: 'HI', name: 'धुअलहिज़ज़ा' },
        { key: 'TA', name: 'Dhu al-Hijjah' },
        { key: 'EN', name: 'Dhu al-Hijjah' },
        { key: 'UR', name: 'ذوالحجہ' },
        { key: 'BN', name: 'জিলহজ' },
      ]
    }

  ];


  leaverStatuses: any[] = [
    {
      key: 'POSTED',
      lang: 'EN',
      name: 'Pending Invitation',
    },
    {
      key: 'PENDING',
      lang: 'EN',
      name: 'Pending Answers',
    },
    {
      key: 'COMPLETED',
      lang: 'EN',
      name: 'Completed',
    },
    {
      key: 'INACTIVE',
      lang: 'EN',
      name: 'Inactive',
    },
    {
      key: 'EXPIRED',
      lang: 'EN',
      name: 'Expired',
    },

    {
      key: 'POSTED',
      lang: 'AR',
      name: 'الدعوات المعلقة',
    },
    {
      key: 'PENDING',
      lang: 'AR',
      name: 'الرد قيد الانتظار',
    },
    {
      key: 'COMPLETED',
      lang: 'AR',
      name: 'مكتمل',
    },
    {
      key: 'INACTIVE',
      lang: 'AR',
      name: 'غير فعال',
    },
    {
      key: 'EXPIRED',
      lang: 'AR',
      name: 'منتهي الصلاحية',
    },
  ];

  ageGroups: any[] = [
    { key: '<20', lang: 'EN', name: 'Less than 20' },
    { key: '20-29', lang: 'EN', name: '20 - 29' },
    { key: '30-39', lang: 'EN', name: '30 - 39' },
    { key: '40-49', lang: 'EN', name: '40 - 49' },
    { key: '50-59', lang: 'EN', name: '50 - 59' },
    { key: '>=60', lang: 'EN', name: '60 and above' },

    { key: '<20', lang: 'AR', name: 'أقل من 20 عاماً' },
    { key: '20-29', lang: 'AR', name: 'من 20 - 29 عاماً' },
    { key: '30-39', lang: 'AR', name: 'من 30 - 39 عاماً' },
    { key: '40-49', lang: 'AR', name: 'من 40 - 49 عاماً' },
    { key: '50-59', lang: 'AR', name: 'من 50 - 59 عاماً' },
    { key: '>=60', lang: 'AR', name: 'فوق 60 عاماً' },
  ];

  ageGroups_new: any[] = [
    {
      key: '<20',
      name: [
        { key: 'EN', name: 'Less than 20' },
        { key: 'AR', name: 'أقل من 20 عاماً' },
      ],
    },
    {
      key: '20-29',
      name: [
        { key: 'EN', name: '20 - 29' },
        { key: 'AR', name: 'من 20 - 29 عاماً' },
      ],
    },
    {
      key: '30-39',
      name: [
        { key: 'EN', name: '30 - 39' },
        { key: 'AR', name: 'من 30 - 39 عاماً' },
      ],
    },
    {
      key: '40-49',
      name: [
        { key: 'EN', name: '40 - 49' },
        { key: 'AR', name: 'من 40 - 49 عاماً' },
      ],
    },
    {
      key: '50-59',
      name: [
        { key: 'EN', name: '50 - 59' },
        { key: 'AR', name: 'من 50 - 59 عاماً' },
      ],
    },
    {
      key: '>=60',
      name: [
        { key: 'EN', name: '60 and above' },
        { key: 'AR', name: 'فوق 60 عاماً' },
      ],
    },
  ];

  tenureGroups: any[] = [
    { key: '<1', lang: 'EN', name: 'Less than 1' },
    { key: '1-3', lang: 'EN', name: '1 - 3' },
    { key: '3-5', lang: 'EN', name: '3 - 5' },
    { key: '5-10', lang: 'EN', name: '5 - 10' },
    { key: '>=10', lang: 'EN', name: 'Above 10' },

    { key: '<1', lang: 'AR', name: 'أقل من 1 سنة' },
    { key: '1-3', lang: 'AR', name: 'من 1 - 3 سنوات' },
    { key: '3-5', lang: 'AR', name: 'من 3 - 5 سنوات' },
    { key: '5-10', lang: 'AR', name: 'من 5 - 10 سنوات' },
    { key: '>=10', lang: 'AR', name: 'أكثر من 10 سنوات' },
  ];

  tenureGroups_new: any[] = [
    {
      key: '<1',
      name: [
        { key: 'EN', name: 'Less than 1' },
        { key: 'AR', name: 'أقل من 1 سنة' },
      ],
    },
    {
      key: '1-3',
      name: [
        { key: 'EN', name: '1 - 3' },
        { key: 'AR', name: 'من 1 - 3 سنوات' },
      ],
    },
    {
      key: '3-5',
      name: [
        { key: 'EN', name: '3 - 5' },
        { key: 'AR', name: 'من 3 - 5 سنوات' },
      ],
    },
    {
      key: '5-10',
      name: [
        { key: 'EN', name: '5 - 10' },
        { key: 'AR', name: 'من 5 - 10 سنوات' },
      ],
    },
    {
      key: '>=10',
      name: [
        { key: 'EN', name: 'Above 10' },
        { key: 'AR', name: 'أكثر من 10 سنوات' },
      ],
    },
  ];

  generations_new: any[] = [
    {
      key: 'maturists',
      name: [
        { key: 'EN', name: 'Maturists' },
        { key: 'AR', name: 'جيل الناضجين' },
      ],
    },
    {
      key: 'babyboomers',
      name: [
        { key: 'EN', name: 'Baby Boomers' },
        { key: 'AR', name: 'جيل الطفرة السكانية' },
      ],
    },
    {
      key: 'genx',
      name: [
        { key: 'EN', name: 'Generation X' },
        { key: 'AR', name: 'جيل "X"' },
      ],
    },
    {
      key: 'geny',
      name: [
        { key: 'EN', name: 'Generation Y' },
        { key: 'AR', name: 'جيل الألفية Y' },
      ],
    },
    {
      key: 'genz',
      name: [
        { key: 'EN', name: 'Generation Z' },
        { key: 'AR', name: 'جيل Z' },
      ],
    },
    {
      key: 'gena',
      name: [
        { key: 'EN', name: 'Alpha Generation' },
        { key: 'AR', name: 'جيل ألفا' },
      ],
    },
  ];

  generations: any[] = [
    { key: 'maturists', lang: 'EN', name: 'Maturists' },
    { key: 'babyboomers', lang: 'EN', name: 'Baby Boomers' },
    { key: 'genx', lang: 'EN', name: 'Generation X' },
    { key: 'geny', lang: 'EN', name: 'Generation Y' },
    { key: 'genz', lang: 'EN', name: 'Generation Z' },
    { key: 'gena', lang: 'EN', name: 'Alpha Generation' },

    { key: 'maturists', lang: 'AR', name: 'جيل الناضجين' },
    { key: 'babyboomers', lang: 'AR', name: 'جيل الطفرة السكانية' },
    { key: 'genx', lang: 'AR', name: 'جيل "X"' },
    { key: 'geny', lang: 'AR', name: 'جيل الألفية "Y"' },
    { key: 'genz', lang: 'AR', name: 'جيل "Z"' },
    { key: 'gena', lang: 'AR', name: 'جيل ألفا' },
  ];

  chartTypes: any[] = [
    {
      DashletType: 'barH',
      name: [
        { key: 'EN', name: 'Bar Horizontal' },
        { key: 'AR', name: 'رسم بياني بالأعمدة (أفقي)' },
      ],
    },
    {
      DashletType: 'barV',
      name: [
        { key: 'EN', name: 'Bar Vertical' },
        { key: 'AR', name: 'رسم بياني بالأعمدة (عمودي)' },
      ],
    },
    {
      DashletType: 'stackV',
      name: [
        { key: 'EN', name: 'Stacked Vertical' },
        { key: 'AR', name: 'رسم بياني شريطي (عمودي)' },
      ],
    },
    {
      DashletType: 'stackH',
      name: [
        { key: 'EN', name: 'Stacked Horizontal' },
        { key: 'AR', name: 'رسم بياني شريطي (أفقي)' },
      ],
    },
    {
      DashletType: 'pie',
      name: [
        { key: 'EN', name: 'Pie' },
        { key: 'AR', name: 'رسم بياني دائري' },
      ],
    },
    // { DashletType: 'radarArea', name: [{ key: 'EN', name: 'Radar Area' }, { key: 'AR', name: 'رسم بياني راداري' }] },
    // { DashletType: 'radarColumn', name: [{ key: 'EN', name: 'Radar Column' }, { key: 'AR', name: 'رسم بياني راداري بالأعمدة' }] },
  ];

  engagementMetrics: any[] = [
    {
      key: 'workplace',
      name: [
        { key: 'EN', name: 'Workplace Engagement Score' },
        { key: 'AR', name: 'نتيجة الاندماج الوظيفي في بيئة العمل' },
      ],
    },
    {
      key: 'innovation',
      name: [
        { key: 'EN', name: 'Innovation' },
        { key: 'AR', name: 'الابتكار' },
      ],
    },
    {
      key: 'motivation',
      name: [
        { key: 'EN', name: 'Motivation' },
        { key: 'AR', name: 'التحفيز' },
      ],
    },
    {
      key: 'performance',
      name: [
        { key: 'EN', name: 'Performance' },
        { key: 'AR', name: 'الأداء' },
      ],
    },
    {
      key: 'autonomy',
      name: [
        { key: 'EN', name: 'Autonomy' },
        { key: 'AR', name: 'الاستقلالية' },
      ],
    },
    {
      key: 'connection',
      name: [
        { key: 'EN', name: 'Connection' },
        { key: 'AR', name: 'الاتصال' },
      ],
    },
    {
      key: 'transformational',
      name: [
        { key: 'EN', name: 'Transformational Leadership' },
        { key: 'AR', name: 'القيادة التحويلية' },
      ],
    },
  ];

  happinessMetrics: any[] = [
    {
      key: 'workplace',
      name: [
        { key: 'EN', name: 'Workplace Happiness Score' },
        { key: 'AR', name: 'درجة السعادة في مكان العمل' },
      ],
    },
    {
      key: 'conditions',
      name: [
        { key: 'EN', name: 'Conditions' },
        { key: 'AR', name: 'ظروف العمل ' },
      ],
    },
    {
      key: 'compensation',
      name: [
        { key: 'EN', name: 'Compensation' },
        { key: 'AR', name: 'التعويضات' },
      ],
    },
    {
      key: 'communication',
      name: [
        { key: 'EN', name: 'Communication' },
        { key: 'AR', name: 'التواصل' },
      ],
    },
    {
      key: 'culture',
      name: [
        { key: 'EN', name: 'Culture' },
        { key: 'AR', name: 'الثقافة' },
      ],
    },
    {
      key: 'career Advancement',
      name: [
        { key: 'EN', name: 'Career Advancement' },
        { key: 'AR', name: 'التطور الوظيفي' },
      ],
    },
    {
      key: 'continuity',
      name: [
        { key: 'EN', name: 'Continuity' },
        { key: 'AR', name: 'الاستمرارية في العمل' },
      ],
    },
  ];

  wellbeingMetrics: any[] = [
    {
      key: 'physical',
      name: [
        { key: 'EN', name: 'Physical Wellbeing' },
        { key: 'AR', name: 'Physical Wellbeing' }
      ],
    },
    {
      key: 'psychological',
      name: [
        { key: 'EN', name: 'Psychological Wellbeing' },
        { key: 'AR', name: 'Psychological Wellbeing' },
      ],
    },
    {
      key: 'social',
      name: [
        { key: 'EN', name: 'Social Wellbeing' },
        { key: 'AR', name: 'Social Wellbeing' },
      ],
    },
    {
      key: 'occupational',
      name: [
        { key: 'EN', name: 'Occupational Wellbeing' },
        { key: 'AR', name: 'Occupational Wellbeing' },
      ],
    },
    {
      key: 'financial',
      name: [
        { key: 'EN', name: 'Financial Wellbeing' },
        { key: 'AR', name: 'Financial Wellbeing' },
      ],
    }
  ];
  constructTimeFrameGrouping(
    startDate: Date,
    endDate: Date,
    grouping,
    language: string
  ) {
    let timeFrameGrouping: any[] = [];
    if (grouping == 'Yearly') {
      let startYear = startDate.getFullYear();
      let endYear = endDate.getFullYear();

      for (let i = startYear; i <= endYear; i++) {
        let yearStartDate = Number(
          (((new Date(i, 0, 1).getTime() / 1000) | 0) + 10800).toFixed(0)
        );
        let yearEndDate = Number(
          (new Date(i + '-' + 12 + '-' + 31).getTime() / 1000).toFixed(0)
        );
        let timeFrame = {
          key: '' + i,
          startDate: yearStartDate,
          endDate: yearEndDate,
        };
        timeFrameGrouping.push(timeFrame);
      }
    }

    if (grouping == 'Quarterly') {
      let quarters = this.quarters.filter((q) => q.lang == language);
      let startYear = startDate.getFullYear();
      let endYear = endDate.getFullYear();
      let startMonth = startDate.getMonth();
      let endMonth = endDate.getMonth();

      if (startMonth >= 0 && startMonth <= 2) {
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 1).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 1).name,
        });
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 2).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 2).name,
        });
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 3).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 3).name,
        });
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 4).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 4).name,
        });
      }

      if (startMonth >= 3 && startMonth <= 5) {
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 2).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 2).name,
        });
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 3).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 3).name,
        });
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 4).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 4).name,
        });
      }

      if (startMonth >= 6 && startMonth <= 8) {
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 3).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 3).name,
        });
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 4).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 4).name,
        });
      }

      if (startMonth >= 9 && startMonth <= 11) {
        timeFrameGrouping.push({
          key: startYear + ' - ' + quarters.find((q) => q.key == 4).name,
          name: startYear + ' - ' + quarters.find((q) => q.key == 4).name,
        });
      }

      for (let i = startYear; i <= endYear; i++) {
        if (i != startYear && i != endYear) {
          timeFrameGrouping.push({
            key: i + ' - ' + quarters.find((q) => q.key == 1).name,
            name: i + ' - ' + quarters.find((q) => q.key == 1).name,
          });
          timeFrameGrouping.push({
            key: i + ' - ' + quarters.find((q) => q.key == 2).name,
            name: i + ' - ' + quarters.find((q) => q.key == 2).name,
          });
          timeFrameGrouping.push({
            key: i + ' - ' + quarters.find((q) => q.key == 3).name,
            name: i + ' - ' + quarters.find((q) => q.key == 3).name,
          });
          timeFrameGrouping.push({
            key: i + ' - ' + quarters.find((q) => q.key == 4).name,
            name: i + ' - ' + quarters.find((q) => q.key == 4).name,
          });
        }
      }

      if (startYear != endYear) {
        if (endMonth >= 0 && endMonth <= 2) {
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 1).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 1).name,
          });
        }

        if (endMonth >= 3 && endMonth <= 5) {
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 1).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 1).name,
          });
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 2).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 2).name,
          });
        }

        if (endMonth >= 6 && endMonth <= 8) {
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 1).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 1).name,
          });
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 2).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 2).name,
          });
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 3).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 3).name,
          });
        }

        if (endMonth >= 9 && endMonth <= 11) {
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 1).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 1).name,
          });
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 2).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 2).name,
          });
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 3).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 3).name,
          });
          timeFrameGrouping.push({
            key: endYear + ' - ' + quarters.find((q) => q.key == 4).name,
            name: endYear + ' - ' + quarters.find((q) => q.key == 4).name,
          });
        }
      }
    }

    if (grouping == 'Monthly') {
      let startYear = startDate.getFullYear();
      let endYear = endDate.getFullYear();
      let startMonth = startDate.getMonth();
      let endMonth = endDate.getMonth();

      if (startYear != endYear) {
        for (let i = startMonth; i <= 11; i++) {
          let monthName = this.languageService.extractNameLanguage(
            this.monthNames.find((m) => m.key == i + 1).name,
            language
          );
          timeFrameGrouping.push({
            key: startYear + ' - ' + monthName,
            name: startYear + ' - ' + monthName,
          });
        }
      } else {
        for (let i = startMonth; i <= endMonth; i++) {
          let monthName = this.languageService.extractNameLanguage(
            this.monthNames.find((m) => m.key == i + 1).name,
            language
          );
          timeFrameGrouping.push({
            key: startYear + ' - ' + monthName,
            name: startYear + ' - ' + monthName,
          });
        }
      }

      for (let i = startYear; i <= endYear; i++) {
        if (i != startYear && i != endYear) {
          for (let x = 0; x <= 11; x++) {
            let monthName = this.languageService.extractNameLanguage(
              this.monthNames.find((m) => m.key == x + 1).name,
              language
            );
            timeFrameGrouping.push({
              key: i + ' - ' + monthName,
              name: i + ' - ' + monthName,
            });
          }
        }
      }

      if (startYear != endYear) {
        for (let i = 0; i <= endMonth; i++) {
          let monthName = this.languageService.extractNameLanguage(
            this.monthNames.find((m) => m.key == i + 1).name,
            language
          );
          timeFrameGrouping.push({
            key: endYear + ' - ' + monthName,
            name: endYear + ' - ' + monthName,
          });
        }
      }
    }

    return timeFrameGrouping;
  }

  fbFunctionURL: string = '';

  private excludeExtraCriteria = ['GENDER', 'NATIONALITY'];
  private _extraCriterias = [];

  constructor(
    private languageService: LanguageService,
    private http: HttpClient,
    private router: Router,
    private localService: LocalService,
    private translationPipe: TranslatePipe,
    private responseDialogService: ResponseDialogService
    , private globals: GlobalsService
    , private essentialObjectService: EssentialObjectService) {
    this.fbFunctionURL = this.globals.fbFunctionURL;

    this.essentialObjectService.essentialObject
      .subscribe(obj => {

        if (obj.selectedEntity == null)
          return;

        let genders = obj.selectedEntity?.metrics?.find(x => x.key == "GENDER");
        let nationality = obj.selectedEntity?.metrics?.find(x => x.key == "NATIONALITY");
        this._extraCriterias = obj.selectedEntity?.metrics?.filter((x => !this.excludeExtraCriteria.includes(x.key))) || [];

        if (genders != null) {
          this.genders_new_1 = genders.options;
        } else {
          this.genders_new_1 = this.genders_list;
        }

        if (nationality != null) {
          this.countries_new_1 = nationality.options;
        } else {
          this.countries_new_1 = countryList;
        }

      })

  }

  get extraCriterias() {
    return this._extraCriterias;
  }

  getReadableDate(date: string) {
    let myDate = new Date(date);
    var m = myDate.getMonth() + 1;
    var d = myDate.getDate();
    var y = myDate.getFullYear();

    return d + '-' + m + '-' + y;
  }

  // ENGAGEMENT RELATED

  assessment360AllCompetencies: any[] = [
    {
      key: 'ALL',
      name: [
        { key: 'EN', name: 'All Competencies' },
        { key: 'AR', name: 'جميع الجدارات والمهارات' },
      ],
    },
  ];
  assessment180AllCompetencies: any[] = [
    {
      key: 'ALL',
      name: [
        { key: 'EN', name: 'All Competencies' },
        { key: 'AR', name: 'جميع الجدارات والمهارات' },
      ],
    },
  ];

  assessmentChartResponseRate: any[] = [
    {
      key: 'CANDIDATE',
      name: [
        { key: 'EN', name: 'Candidates' },
        { key: 'AR', name: 'المرشحين' },
      ],
    },
    {
      key: 'EVALUATOR',
      name: [
        { key: 'EN', name: 'Evaluators' },
        { key: 'AR', name: 'المقيمون' },
      ],
    },
    {
      key: 'SCORE',
      name: [
        { key: 'EN', name: 'Score' },
        { key: 'AR', name: 'المعدل' },
      ],
    },
  ];

  assessment360TopPickCompetencies: any[] = [
    {
      key: 'TOP5',
      name: [
        { key: 'EN', name: 'Top 5' },
        { key: 'AR', name: 'أعلى 5' },
      ],
    },
    {
      key: 'TOP10',
      name: [
        { key: 'EN', name: 'Top 10' },
        { key: 'AR', name: 'أعلى 10' },
      ],
    },
    {
      key: 'ALL',
      name: [
        { key: 'EN', name: 'All' },
        { key: 'AR', name: 'الكل' },
      ],
    },
  ];

  assessment360OrderCompetencies: any[] = [
    {
      key: 'ASC',
      name: [
        { key: 'EN', name: 'Ascending' },
        { key: 'AR', name: 'تصاعدي' },
      ],
    },
    {
      key: 'DESC',
      name: [
        { key: 'EN', name: 'Descending' },
        { key: 'AR', name: 'تنازلي' },
      ],
    },
  ];

  assessment180TopPickCompetencies: any[] = [
    {
      key: 'TOP5',
      name: [
        { key: 'EN', name: 'Top 5' },
        { key: 'AR', name: 'أعلى 5' },
      ],
    },
    {
      key: 'TOP10',
      name: [
        { key: 'EN', name: 'Top 10' },
        { key: 'AR', name: 'أعلى 10' },
      ],
    },
    {
      key: 'ALL',
      name: [
        { key: 'EN', name: 'All' },
        { key: 'AR', name: 'الكل' },
      ],
    },
  ];

  assessment180OrderCompetencies: any[] = [
    {
      key: 'ASC',
      name: [
        { key: 'EN', name: 'Ascending' },
        { key: 'AR', name: 'تصاعدي' },
      ],
    },
    {
      key: 'DESC',
      name: [
        { key: 'EN', name: 'Descending' },
        { key: 'AR', name: 'تنازلي' },
      ],
    },
  ];


  engagementStatuses: any[] = [
    {
      key: 'DRAFT',
      name: [
        { key: 'EN', name: 'Draft' },
        { key: 'AR', name: 'مسودة' },
      ],
    },
    {
      key: 'LIVE',
      name: [
        { key: 'EN', name: 'Live' },
        { key: 'AR', name: 'مباشر' },
      ],
    },
    {
      key: 'CLOSED',
      name: [
        { key: 'EN', name: 'Closed' },
        { key: 'AR', name: 'انتهى' },
      ],
    },
  ];

  engagementActionButton: any[] = [
    {
      key: 'DRAFT',
      name: [
        { key: 'EN', name: 'Continue Setup' },
        { key: 'AR', name: 'متابعة الإعداد' },
      ],
    },
    {
      key: 'LIVE',
      name: [
        { key: 'EN', name: 'View' },
        { key: 'AR', name: 'عرض' },
      ],
    },
    {
      key: 'CLOSED',
      name: [
        { key: 'EN', name: 'Open' },
        { key: 'AR', name: 'فتح' },
      ],
    },
  ];
  products: any[] = [
    {
      key: 'psychometric',
      name: [
        { key: 'EN', name: 'Psychometric Assessments' },
        { key: 'AR', name: ' تقييمات القياس النفسي' },
      ],
    },
    {
      key: 'engagement',
      name: [
        { key: 'EN', name: 'Employee Engagement' },
        { key: 'AR', name: 'الاندماج الوظيفي' },
      ],
    },
    {
      key: 'assessment',
      name: [
        { key: 'EN', name: '360° Feedback Assessment' },
        { key: 'AR', name: ' تقييم أداء 360° ' },
      ],
    },
    {
      key: 'exit',
      name: [
        { key: 'EN', name: 'Employee Exit' },
        { key: 'AR', name: ' خروج الموظفين' },
      ],
    },
    {
      key: 'happiness',
      name: [
        { key: 'EN', name: ' Employee Happiness' },
        { key: 'AR', name: ' سعادة الموظف' },
      ],
    },
    {
      key: 'others',
      name: [
        { key: 'EN', name: 'Others' },
        { key: 'AR', name: 'غير ذلك' },
      ],
    }
  ];
  TechnicalIssueCategories = [
    {
      key: 'Bug',
      name: [
        { key: 'EN', name: 'Bug' },
        { key: 'AR', name: 'علة' },
      ]
    },
    {
      key: 'Feature Request',
      name: [
        { key: 'EN', name: 'Feature Request' },
        { key: 'AR', name: 'طلب المواصفات' },
      ]
    },
    {
      key: 'How To',
      name: [
        { key: 'EN', name: 'How To' },
        { key: 'AR', name: 'كيف' },
      ]
    },
    {
      key: 'Cancellation',
      name: [
        { key: 'EN', name: 'Cancellation' },
        { key: 'AR', name: 'إلغاء' },
      ]
    },
    {
      key: 'Website',
      name: [
        { key: 'EN', name: 'Website' },
        { key: 'AR', name: 'موقع الكتروني' },
      ]
    }
    , {
      key: 'Others',
      name: [
        { key: 'EN', name: 'Others' },
        { key: 'AR', name: 'غير ذلك' },
      ]
    }]

  HRAdvisoryCategories = [{
    key: 'PayRoll', name: [
      { key: 'EN', name: 'PayRoll' },
      { key: 'AR', name: 'كشف رواتب' },
    ]
  }, {
    key: 'Attendance', name: [
      { key: 'EN', name: 'Attendance' },
      { key: 'AR', name: 'حضور' },
    ]
  }
    , {
    key: 'Leaves', name: [
      { key: 'EN', name: 'Leaves' },
      { key: 'AR', name: 'الإجازات' },
    ]
  }, {
    key: 'Functional', name: [
      { key: 'EN', name: 'Functional' },
      { key: 'AR', name: 'وظيفي' },
    ]
  }, {
    key: 'Others', name: [
      { key: 'EN', name: 'Others' },
      { key: 'AR', name: 'غير ذلك' },
    ]
  }]
  convertGroupedToPercentages(data: any) {
    let dData = data;
    let totals: any[] = [];
    for (let i = 0; i < dData.length; i++) {
      let d = dData[i];
      let groupExists = totals.find((t) => t.Category == d.Category);
      if (!groupExists) totals.push({ Category: d.Category, Value: 0 });
    }
    for (let i = 0; i < dData.length; i++) {
      let d = dData[i];
      let group = totals.find((t) => t.Category == d.Category);
      group.Value = group.Value + d.Value;
    }
    for (let i = 0; i < dData.length; i++) {
      let d = dData[i];
      let group = totals.find((t) => t.Category == d.Category);
      d.Value = Number(((d.Value / group.Value) * 100).toFixed(1));
    }
    return dData;
  }

  convertToPercentages(data: any) {
    let dData = data;
    let isGrouped: boolean = false;
    if (dData.length > 0) {
      if (dData[0].Group != null) isGrouped = true;
    }

    if (!isGrouped) {
      let total: number = 0;
      for (let i = 0; i < dData.length; i++) {
        let d = dData[i];
        total = total + d.Value;
      }
      for (let i = 0; i < dData.length; i++) {
        let d = dData[i];
        if (d.Value != null && d.Value != 0)
          d.Value = Number(((d.Value / total) * 100).toFixed(1));
        else d.Value = 0;
      }
    } else {
      let totals: any[] = [];
      for (let i = 0; i < dData.length; i++) {
        let d = dData[i];
        let groupExists = totals.find((t) => t.Group == d.Group);
        if (!groupExists) totals.push({ Group: d.Group, Value: 0 });
      }
      for (let i = 0; i < dData.length; i++) {
        let d = dData[i];
        let group = totals.find((t) => t.Group == d.Group);
        group.Value = group.Value + d.Value;
      }
      for (let i = 0; i < dData.length; i++) {
        let d = dData[i];
        let group = totals.find((t) => t.Group == d.Group);
        d.Value = Number(((d.Value / group.Value) * 100).toFixed(1));
      }
    }

    return dData;
  }

  convertToPercentagesForMultiOptions(data: any, total: number) {
    let dData = data;
    let isGrouped: boolean = false;
    if (dData.length > 0) {
      if (dData[0].Group != null) isGrouped = true;
    }

    if (!isGrouped) {
      for (let i = 0; i < dData.length; i++) {
        let d = dData[i];
        if (d.Value != null && d.Value != 0)
          d.Value = Number(((d.Value / total) * 100).toFixed(1));
        else d.Value = 0;
      }
    }

    return dData;
  }

  flattenQuestionsFromSections(sections: Section[]) {
    return sections.flatMap((section) => {
      return section.questions;
    });
  }

  getSpaceRequiredForTheSet(set) {
    if (!set) {
      return 0;
    }

    let questionSpace = 0;
    let answerSpace = 0;
    if (set.question) {
      questionSpace = 8;
    }

    if (set.answer) {
      const answerCharLimit = 120;
      let answerLength = set.answer.length;
      answerSpace = 10;
      if (answerLength > answerCharLimit) {
        let multiplier = Math.ceil(
          (answerLength - answerCharLimit) / answerCharLimit
        );
        answerSpace += multiplier * 4;
      }
    }

    return questionSpace + answerSpace;
  }

  canPageAccomodateQASet(page, set) {
    let spaceOccupied = page
      .map(this.getSpaceRequiredForTheSet)
      .reduce((a, b) => a + b, 0);
    let spaceNeeded = this.getSpaceRequiredForTheSet(set);

    return spaceOccupied + spaceNeeded <= 100;
  }

  groupQuestionsAnswers = (questionComment: QuestionComment) => {
    let questionAnswerSet = [
      {
        question: questionComment.name,
        answer: questionComment.comments.pop(),
        isLast: false,
      },
    ];
    if (questionComment.comments) {
      let answerSet: any = questionComment.comments.map((comment, index) => ({
        question: null,
        answer: comment,
        isLast: questionComment.comments.length - 1 === index,
      }));
      Array.prototype.push.apply(questionAnswerSet, answerSet);
    }
    return questionAnswerSet;
  };

  groupQuestionAnswerByPages(questionComments: QuestionComment[]) {
    let groupedAnswerByPages = [];
    let page = [];

    questionComments
      .map(this.groupQuestionsAnswers)
      .flat()
      .forEach((set) => {
        if (!this.canPageAccomodateQASet(page, set)) {
          groupedAnswerByPages.push(page);
          page = [];
        }
        page.push(set);
      });

    if (page) {
      groupedAnswerByPages.push(page);
    }

    return groupedAnswerByPages;
  }

  groupLeaversAnswerByPages(sections: Section[]) {
    let questions: Question[][] = [];
    let questionList = this.flattenQuestionsFromSections(sections);
    let page = [];

    questionList.forEach((question) => {
      if (
        this.isPageSectionsMatchingCombination(page) ||
        this.checkEdgeCase(page)
      ) {
        questions.push(page);
        page = [];
      }

      page.push(question);
    });

    questions.push(page);

    return questions;
  }

  checkEdgeCase(questions: Question[]) {
    let pageSections = this.getPageSections(questions);
    return _.isEqual(
      _.sortBy(pageSections),
      _.sortBy(['half', 'quarter', 'half'])
    );
  }

  getPageSections(questions: Question[]) {
    return questions.map((question) => {
      let questionType = this.questionTypes.find(
        (q) => q.key == question.questionType
      );
      return questionType?.pageSectionSize;
    });
  }

  isPageSectionsMatchingCombination(questions: Question[]) {
    let pageSections = this.getPageSections(questions);
    let matches = false;
    this.leaverAnswerCombination.forEach((comb) => {
      // Has to be this way. Set flag to true, escape if condition evaluates to false need one truthy case
      // to satisfy the logic.
      matches = _.isEqual(_.sortBy(pageSections), _.sortBy(comb))
        ? true
        : matches;
    });

    return matches;
  }

  gccCountries_new: any[] = [
    {
      key: 'Bahrain',
      countryCode: '973',
      name: [
        {
          key: 'EN',
          name: 'Bahrain',
        },
        {
          key: 'AR',
          name: 'البحرين',
        },
      ],
    },
    {
      key: 'Kuwait',
      countryCode: '965',
      name: [
        {
          key: 'EN',
          name: 'Kuwait',
        },
        {
          key: 'AR',
          name: 'الكويت',
        },
      ],
    },
    {
      key: 'Oman',
      countryCode: '968',
      name: [
        {
          key: 'EN',
          name: 'Oman',
        },
        {
          key: 'AR',
          name: 'عُمان',
        },
      ],
    },
    {
      key: 'Qatar',
      countryCode: '974',
      name: [
        {
          key: 'EN',
          name: 'Qatar',
        },
        {
          key: 'AR',
          name: 'قطر',
        },
      ],
    },
    {
      key: 'Saudi Arabia',
      countryCode: '966',
      name: [
        {
          key: 'EN',
          name: 'Saudi Arabia',
        },
        {
          key: 'AR',
          name: 'المملكة العربية السعودية',
        },
      ],
    },
    {
      key: 'United Arab Emirates',
      countryCode: '971',
      name: [
        {
          key: 'EN',
          name: 'United Arab Emirates',
        },
        {
          key: 'AR',
          name: 'الامارات العربية المتحدة',
        },
      ],
    },
  ];



  shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

}
