import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KeyNameDto } from '@ex/core/common';
import { Entity } from '@ex/module/core/data-models/Entity';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { DATA_TOKEN, ICDKPortalComponent } from '@ex/module/shared/Theme/services/zen-cdk.service';
import { EmailTemplateService } from '@ex/survey-x/index';

@Component({
  selector: 'app-add-email-template',
  templateUrl: './add-email-template.component.html',
  styleUrls: ['./add-email-template.component.scss']
})
export class AddEmailTemplateComponent extends BaseComponent implements ICDKPortalComponent, OnInit {
  title: FormControl = new FormControl();
  language: any;
  selectedEntity: Entity;
  templatesList: any;
  type: any;
  constructor(@Inject(DATA_TOKEN) private data: any, private emailTemplateService: EmailTemplateService,) {
    super();
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.language = this.data.language;
    this.type = this.data.type;
    this.selectedEntity = this.data.selectedEntity;
    const sub =  this.emailTemplateService.getByType(this.selectedEntity.tenantKey, this.selectedEntity.key, this.type).subscribe((response: any) => {
      response.forEach(element => {

        this.templatesList.push({
          key: element.id,
          name: element.name
        })
      });
    });
    this.addSubscription(sub);
  }


  saveTemplate() {
    if (this.title.invalid)
      return;
    if (this.templatesList?.find(x => x.name == this.title.value))
      return this.SnackbarService.error(this.localize('txtTemplateExists', this.language, this.ModuleNames.SurveyX))
    this.results.emit(this.title.value)

  }
}
