import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './components/main/main.component';
import { TopicAddComponent } from './components/topic-add/topic-add.component';
import { TopicCommentsComponent } from './components/topic-comments/topic-comments.component';
import { CommentsChartComponent } from './components/chart/chart.component';
import { SharedModule } from '../shared.module';
import { CommentItemComponent } from './components/comment-item/comment-item.component';
import { CommentsSectionComponent } from './components/comments-section/comments-section.component';
import { CommentsQuestionComponent } from './components/comments-question/comments-question.component';
import { AiBaseComponent } from './components/ai-base.component';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ProductTourModule } from '../product-tour/product-tour.module';



@NgModule({
  declarations: [
    MainComponent,
    TopicCommentsComponent,
    TopicAddComponent,
    CommentsChartComponent,
    CommentItemComponent,
    CommentsSectionComponent,
    CommentsQuestionComponent,
    AiBaseComponent,
    StopPropagationDirective,
  ],
  imports: [
    SharedModule,
    CommonModule,
    ScrollingModule,
    ProductTourModule
  ],
  exports: [
    MainComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AiCommentModule { }
