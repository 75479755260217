import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourStepDirective } from './directives/tour-step.directive';
import { MainTourComponent } from './components/main/main-tour.component';



@NgModule({
  declarations: [
    MainTourComponent,
    TourStepDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MainTourComponent,
    TourStepDirective
  ]
})
export class ProductTourModule { }
