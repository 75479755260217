<div [style.direction]="dir" (click)="close()" (click)="logData(footer?.childNodes.length > 1)" [@inOutAnimation] *ngIf="open" class="w-screen h-screen bg-zen bg-opacity-50 flex flex-row items-center justify-center fixed top-0 left-0 z-50 py-10 px-5">
    <div (click)="$event.stopPropagation(); maintainChanges(50)" class="flex flex-col bg-white bg-opacity-95 py-10 rounded max-h-screen-90">
        <div #header [ngClass]="{'-mb-10':header?.childNodes.length < 2}" [hidden]="header.childNodes.length < 2" class="flex flex-row items-center justify-between px-10 pb-5 gap-10">
            <ng-content  select="[header]"></ng-content>
        </div>
        <div #body [hidden]="body.childNodes.length > 1" class="relative transition-all duration-300 ease-expo  overflow-hidden innerListScroll">
            <div #innerBody class="absolute flex flex-col items-stretch justify-center gap-1 px-10 maxh-40">
                <ng-content select="[body]"></ng-content>
            </div>
        </div>
        <div #footer  [hidden]="footer.childNodes.length < 2" [ngClass]="footer.childNodes.length < 2?'-mt-10':''" class="flex flex-row justify-between pt-10 items-center px-10">
            <ng-content  select="[footer]"></ng-content>
        </div>
    </div>
</div>

