import { Injectable } from '@angular/core';

@Injectable()
export class GlobalsService {
  // color control for tables
  //  sEndpoint: string = 'http://localhost:52619/api/';//http://localhost:52619/api/
  // sEndpoint: string = 'https://devapis.zenithr.net/api/';//https://apis.zenithr.net/api   DEV
  // sEndpoint: string = 'https://apis.zenithr.net/api/';//https://apis.zenithr.net/api/   PROD
  sEndpoint: string = 'https://sa.ex.apis.zenithr.net/api/';//https://apis.zenithr.net/api/   PROD

  // coreAPI: string = 'https://localhost:44385/api/';
  coreAPI: string = 'https://core.zenithr.net/api/';



  fbFunctionURL: string = 'https://us-central1-exitanalysis-a6325.cloudfunctions.net/'; //PROD
  // fbFunctionURL: string = 'https://us-central1-zendev-9e3f0.cloudfunctions.net/'; //DEV



  mainButton: string = 'exp';
  progress: string = 'bg-orange';
  white: string = 'white-bg';
  basic: string = 'bg-zen';
  completed: string = 'bg-zen-green';
  deleted: string = 'bg-zen-red';
  darkRed: string = 'bg-red-800';
  dark: string = 'bg-zen-dark';
  readonly hugeCount: number = 15000;
  readonly lgCount: number = 300;
  readonly xlCount: number = 1000;

  readonly md2Count: number = 100;
  readonly mdCount: number = 100;
  readonly avgCount: number = 60;
  readonly smCount: number = 20;

  readonly basicColors = { button: this.mainButton, default: 'bg-white' };
  readonly tableColors = {
    button: this.mainButton,
    STARTED: this.progress,
    COMPLETED: this.completed,
    DELETED: this.deleted,
    PENDING: this.basic,
    DRAFT: this.basic,
    LIVE: this.progress,
    CLOSED: this.completed,
    EXPIRED: this.darkRed,
    INACTIVE: this.dark
  };

  tenantSetup: boolean = false;

  innerRoute: boolean = false;

  constructor() { }
}
