import { Injectable } from '@angular/core';
import { EngagementMetric } from 'src/app/core/data-models/engagement-metric';
import { Entity } from 'src/app/core/data-models/Entity';
import { GeneralService } from 'src/app/core/services/general.service';

@Injectable({
  providedIn: 'root'
})
export class PredefinedMetricsService {

  constructor(private generalService: GeneralService) { }
  extractMetrics(entity: Entity, allEntities: Entity[]) {
    let metrics = [];
    let genderMetric = new EngagementMetric;
    genderMetric.key = 'GENDER';
    genderMetric.isCustom = false;
    genderMetric.isEntity = false;
    genderMetric.name = this.generalService.engagementMetric.find(m => m.key == genderMetric.key).name;
    genderMetric.options = this.generalService.genders_new;
    genderMetric.required = false;
    genderMetric.type = 'DROPDOWN';
    genderMetric.value = '';

    let nationalityMetric = new EngagementMetric;
    nationalityMetric.key = 'NATIONALITY';
    nationalityMetric.isCustom = false;
    nationalityMetric.isEntity = false;
    nationalityMetric.name = this.generalService.engagementMetric.find(m => m.key == nationalityMetric.key).name;
    nationalityMetric.options = this.generalService.countries_new;
    nationalityMetric.required = false;
    nationalityMetric.type = 'DROPDOWN';
    nationalityMetric.value = '';

    let dateOfBirthMetric = new EngagementMetric;
    dateOfBirthMetric.key = 'DOB';
    dateOfBirthMetric.isCustom = false;
    dateOfBirthMetric.isEntity = false;
    dateOfBirthMetric.name = this.generalService.engagementMetric.find(m => m.key == dateOfBirthMetric.key).name;
    dateOfBirthMetric.required = false;
    dateOfBirthMetric.type = 'DATE';
    dateOfBirthMetric.value = '';

    let joiningDateMetric = new EngagementMetric;
    joiningDateMetric.key = 'JOD';
    joiningDateMetric.isCustom = false;
    joiningDateMetric.isEntity = false;
    joiningDateMetric.name = this.generalService.engagementMetric.find(m => m.key == joiningDateMetric.key).name;
    joiningDateMetric.required = false;
    joiningDateMetric.type = 'DATE';
    joiningDateMetric.value = '';

    metrics.push(genderMetric);
    metrics.push(nationalityMetric);
    metrics.push(dateOfBirthMetric);
    metrics.push(joiningDateMetric);

    for (let i = 0; i < entity.EntityGroups.length; i++) {
      let entityGroup = entity.EntityGroups[i];
      if (!entityGroup.isCompanyGroup && allEntities.find(e => e.entityGroupKey == entityGroup.groupKey)) {
        let metric = new EngagementMetric();
        metric.key = entityGroup.groupKey;
        metric.isEntity = true;
        metric.isCustom = false;
        metric.name = entityGroup.name;
        metric.options = allEntities.filter(e => e.entityGroupKey == entityGroup.groupKey);
        metrics.push(metric);
      }
    }

    if (entity.PerformanceRates?.length > 0) {
      let metric = new EngagementMetric();
      metric.key = 'PERFORMANCE';
      metric.isEntity = false;
      metric.isCustom = false;
      metric.name = this.generalService.engagementMetric.find(m => m.key == metric.key).name;

      for (let i = 0; i < entity.PerformanceRates.length; i++) {
        let option = { key: entity.PerformanceRates[i].key, name: [{ key: '', name: entity.PerformanceRates[i].value }] };
        metric.options.push(option);
      }

      metrics.push(metric);
    }

    if (entity.BandLevels?.length > 0) {
      let metric = new EngagementMetric();
      metric.key = 'BANDLEVEL';
      metric.isEntity = false;
      metric.isCustom = false;
      metric.name = this.generalService.engagementMetric.find(m => m.key == metric.key).name;
      metric.options = entity.BandLevels;
      metrics.push(metric);
    }

    if (entity.JobGrades?.length > 0) {
      let metric = new EngagementMetric();
      metric.key = 'JOBGRADE';
      metric.isEntity = false;
      metric.isCustom = false;
      metric.name = this.generalService.engagementMetric.find(m => m.key == metric.key).name;
      metric.options = entity.JobGrades;
      metrics.push(metric);
    }
    for (let x = 0; x < entity?.metrics?.length; x++) {
      let metric: EngagementMetric = entity?.metrics[x];
      if (metric.key == 'GENDER') {
        genderMetric.options = metric.options;
        // genderMetric.isCustom = true;
      } else if (metric.key == 'NATIONALITY') {
        nationalityMetric.options = metric.options;
        // nationalityMetric.isCustom = true;
      } else {
        metrics.push(metric);
      }
    }

    return metrics;
  }

}
