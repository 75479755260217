<z-modal [open]="true" [autoWidth]="true">
  <ng-container header>
    <h2 class="text-zen text-2xl font-semibold">{{ "txtSelectTopic" | translate:language:ModuleNames.Shared }}
    </h2>
    <i (click)="closeModal()"
      class="fa-regular fa-times leading-12 btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col gap-4 items-stretch w-screen max-w-lg">
      <h5 class="text-sm text-zen-lite">{{ "txtSelectTopicDesc" | translate:language:ModuleNames.Shared }}</h5>
      <div class="flex flex-col items-stretch innerListScroll gap-2">
        <div *ngIf="data.topics?.length > 5"
          class="sticky -top-1 px-2 bg-white z-10 rounded overflow-hidden pt-2 pb-1 -mt-1 border border-zen border-opacity-10">
          <div class="flex flex-row h-8 justify-between items-center bg-white bg-opacity-10  w-full px-2">
            <input [formControl]="searchText" [placeholder]="'txt_search'| translate:language:ModuleNames.Shared"
              class="w-full leading-8 bg-transparent text-zen" />
          </div>
        </div>
        <div class="flex flex-col items-stretch gap-px innerListScroll max-h-60">
          <div (click)="toggleTopic(topic)"
            class="far5a flex flex-row items-center gap-2 text-sm rounded-sm text-zen px-2 h-10 hover:bg-opacity-10 hover:bg-zen btn"
            *ngFor="let topic of topics">
           <i  [ngClass]="isTopicSelected(topic)?'fa-regular fa-check-square text-exp':'fa-light fa-square'"></i>
            <h5 class="text-sm font-normal text-zen-lite leading-10 firstUpper">{{topic.name | extract:language}}</h5>
          </div>
        </div>
      </div>
      <a (click)="newTopic()" zenButton styles="ghost height-10" class="justify-center"> <i class="fa-regular fa-plus"></i> <span>{{ "txtNewTopic" | translate:language:ModuleNames.Shared }}</span></a>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="flex flex-col items-stretch w-full gap-5">
      <div class="flex w-full flex-row justify-between">
        <a (click)="closeModal()" zenButton styles="secondary height-10">{{'btn_cancel'|
          translate:language:module }}</a>
        <button [ngClass]="{'opacity-60 pointer-events-none':!selectedTopics?.length}" zenButton styles="exp height-10"
          (click)="apply(selectedTopics)">
          {{'txtApply'| translate:language:ModuleNames.Exit }}
        </button>
      </div>
    </div>
  </ng-container>
</z-modal>


<z-modal [open]="extraTopic" [autoWidth]="true">
  <ng-container header>
    <h2 class="text-zen text-2xl font-semibold">{{ "txtNewTopic" | translate:language:ModuleNames.Shared }}
    </h2>
    <i (click)="closeNewTopic()"
      class="fa-regular fa-times leading-12 btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col gap-4 items-stretch w-screen max-w-sm">
      <z-translation-input #name [label]="'txtTopicName' | translate:language:ModuleNames.Shared"
      [maxCount]="mdCount" [required]="true" [language]="language"
      [supportedLanguages]="data.contentLanguages" [(ngModel)]="topicName">
    </z-translation-input>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="flex flex-col items-stretch w-full gap-5">
      <div class="flex w-full flex-row justify-between">
        <a (click)="closeNewTopic()" zenButton styles="secondary height-10">{{'btn_cancel'|
          translate:language:module }}</a>
        <button [ngClass]="{'opacity-60 pointer-events-none': name.errors?.required}" zenButton styles="exp height-10"
          (click)="addNewTopic(topicName)">
          {{ "txtCreateTopic" | translate:language:ModuleNames.Shared }}
        </button>
      </div>
    </div>
  </ng-container>
</z-modal>

