import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '@ex/module/core/services/language.service';
import { LocalService } from '@ex/module/core/services/local.service';

@Component({
  selector: 'app-form-bank',
  templateUrl: './form-bank.component.html',
  styleUrls: ['./form-bank.component.scss']
})
export class FormBankComponent implements OnInit {

  constructor(private localService:LocalService,private languageService:LanguageService, private router :Router) { }
language:any
  ngOnInit(): void {
    this.router.navigate(['survey-x/form-bank']);



    let localLanguage = this.localService.getJsonValue('language');
    if (!localLanguage) {
      this.language = 'EN';
      this.localService.setJsonValue('language', this.language);
    } else {
      this.language = localLanguage;
      // this.language = 'FR';
    }
    this.languageService.language = this.language;
  }

}
