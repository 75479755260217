import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { EngagementRespondent } from 'src/app/core/data-models/engagement-respondent';
import { RespondentService } from 'src/app/core/services/respondent.service';
import { GlobalsService } from '../../core/services/globals.service';
import { ChildrenAnimation } from '../animations/allAnimations';
import { ModuleName } from '../enums/ModuleName';
import { LanguagePipe } from '../pipes/language.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { TableModule } from '../temp-table/temp-table.component';

@Component({
  selector: 'respondents-pool',
  templateUrl: './respondents-pool.component.html',
  styleUrls: ['./respondents-pool.component.scss'],
  animations: [ChildrenAnimation],
})
export class RespondentsPoolComponent implements OnInit {
  @Input() tourKey;
  ModuleNames = ModuleName
  filteredCompanyRespondentsList;
  @Input('filteredCompanyRespondentsList') set filteredList(list: any) {
    this.filteredCompanyRespondentsList = list;
    this.selectedRespondents = [];
  };

  @ViewChild('table') table;
  @Input('essentialObject') essentialObject: any;
  @Input() displayedColumns2;
  @Input() language
  @Input() hasSingleDelete: boolean = true;
  @Input() hasMassDelete: boolean = true;
  @Input() hasAdd: boolean = true;
  @Input() hasDownload: boolean = true;
  @Input('headingName') headingName: string = '';
  private subscriptions = new Subscription();

  options;
  selectedRespondents;
  @Output() respondentAddFunction = new EventEmitter();
  @Output() deleteRespondent = new EventEmitter();

  @Output() restableSelectionChanged = new EventEmitter();

  searchText2;
  displayedColumns: any[] = [];
  constructor(private globals: GlobalsService, private respondentService: RespondentService, private translationPipe: TranslatePipe, private languagePipe: LanguagePipe, private _globals: GlobalsService) { }
  colors = this.globals.basicColors;
  ngOnInit(): void {

    this.displayedColumns = [
      {
        field: 'employeeId',
        title: this.translationPipe.transform('txt_employee_ID', this.language, this.ModuleNames.Shared)
        ,
      },
      {
        field: 'name',
        title: this.translationPipe.transform('txt_full_name', this.language, this.ModuleNames.Shared)
        ,
      },
      {
        field: 'email',
        title: this.translationPipe.transform('txt_user_email', this.language, this.ModuleNames.Shared)
        ,
      },
    ];

    if (this.hasSingleDelete)
      this.displayedColumns.push({
        field: 'action',
        title: '',
      });
    this.options = {
      columns: this.displayedColumns,
      searchText: this.translationPipe.transform('txt_search', this.language, this.ModuleNames.Shared),
      exportText: this.translationPipe.transform('btnExport', this.language, this.ModuleNames.Shared),
      exportName: this.translationPipe.transform('txtSurveyRespondentList', this.language, this.ModuleNames.Shared),
      colors: this._globals.basicColors,
      whiteBackground: true,
      export: this.hasDownload,
      sticky: true,
      serverSide: true,
      selection: true,
      selectionRemove: [],
      request: {
        module: TableModule.Thomas,
        dataApi: this.respondentService.sEndpoint + 'Respondent/FetchRespondentPool',
        TenantKey: this.essentialObject.tenant.key,
        CompanyKey: this.essentialObject.selectedEntity.key,
        SurveyKey: "",
        searchText: "",
        pageSize: 10,
        pageNumber: 1,
        FilterByMetric: []
      }
    }
    this.fetchPoolUpdates();
  }

  isFirstFetch: boolean = true;

  fetchPoolUpdates() {
    this.subscriptions.add(this.respondentService.fetchPoolUpdates(this.essentialObject.tenant.key, this.essentialObject.selectedEntity.key).subscribe((response: any) => {
      if (this.isFirstFetch)
        this.isFirstFetch = false;
      else
        this.fetchPool();
    }));
  }


  deleteRespondents(respondent, single: boolean = true) {
    let respondents: EngagementRespondent[] = [];
    if (single)
      respondents.push(respondent);
    else
      respondents = respondent;

    let respRequest = {
      SurveyType: "",
      SurveyKey: "",
      TenantKey: this.essentialObject.tenant.key,
      CompanyKey: this.essentialObject.selectedEntity.key,
      UserKey: this.essentialObject.user.key,
      respondents: respondents
    }
    this.subscriptions.add(this.respondentService.deletePoolRespondent(respRequest).subscribe((response: any) => {
      this.fetchPool();
    }));
  }

  reloadResList: boolean = false;
  fetchPool() {
    this.reloadResList = true;
    setTimeout(() => {
      this.reloadResList = false;
    }, 1000);
  }


  actionClicked(selectedAction: any) {
    let request = selectedAction.request;
    let action = selectedAction.action;
    if (action == 'BULK_REMOVE') {
      request.UserKey = this.essentialObject.user.key;
      request.SurveyType = "";
      request.SurveyKey = "",
        request.TenantKey = this.essentialObject.tenant.key,
        request.CompanyKey = this.essentialObject.selectedEntity.key
    }
    console.log('Selected Action');
    console.log(selectedAction);
    if (action == 'REMOVE')
      this.deleteRespondents(request);
    if (action == 'BULK_REMOVE')
      this.bulkRemove(request);
    if (action == 'SELECTION')
      this.deleteRespondents(request.respondents, false);
  }

  reloadTable() {
    this.options = JSON.parse(JSON.stringify(this.options));
  }

  bulkRemove(request) {
    if (request.type == 'ALL' && request?.exclude?.length > 0)
      request.respondents = request?.exclude;
    this.subscriptions.add(this.respondentService.bulkDeletePoolRespondent(request).subscribe((response: any) => {
      this.fetchPool();
    }));
  }

  tableSelectionChanged(event) {
    this.restableSelectionChanged.emit(event);
  }

  fetchRespondentPool() {


  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  bulkRemoveRespondents(list) {
    list.forEach(element => {
      this.deleteRespondent.emit(element)
    });
    this.selectedRespondents = []
  }
}
