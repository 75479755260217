import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../core/services/language.service';
import { LocalService } from '../../../core/services/local.service';
import { MiscellaneousService } from '../../../core/services/miscellaneous.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ZenSnackbarService, ZenSnackBarTypes } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { EngagementRespondent } from 'src/app/core/data-models/engagement-respondent';
import { RespondentService } from 'src/app/core/services/respondent.service';
import { AssessmentService } from '@ex/module/core/services/assessment.service';
declare var $: any;
@Component({
  selector: 'app-assessment-landing-page',
  templateUrl: './assessment-landing-page.component.html',
  styleUrls: ['./assessment-landing-page.component.scss']
})
export class AssessmentLandingPageComponent implements OnInit {
  private subscriptions = new Subscription();
  language: string = 'EN';
  logoLoading: boolean = false;
  tenantLogo: string;
  base64Image: string;
  logoURL: string;
  tenantKeyExists: boolean = false;
  section: string = 'login';
  hide = true;
  showLangage: boolean = false;
  returnUrl: string;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  ModuleNames = ModuleName

  constructor(private route: ActivatedRoute,
    private languageService: LanguageService, private languagePipe: LanguagePipe,
    private localService: LocalService, private miscellanous: MiscellaneousService,
    private router: Router, public loaderService: LoaderService, private zenSnackBarService: ZenSnackbarService, private translationPipe: TranslatePipe,
    private respondentService: RespondentService, private assessmentService: AssessmentService) {

  }


  form = {
    id: '',
    name: '',
    email: '',
  };
  valid = true;

  supportedLanguages: any = [];
  tenantKey: string;
  companyKey: string;

  engagementKey: string;
  candidateKey: string;
  candidateName: string;

  referenceKey: string;
  ngOnInit(): void {
    let currentLocation: string = window.location.href;
    console.log(currentLocation);
    this.referenceKey = currentLocation.replace('https://ex.zenithr.net/assessment-landing/', '');
    if (this.referenceKey) {
      localStorage.setItem('LandingKey', this.referenceKey);
      this.supportedLanguages = this.languageService.supportedLanguages;

      this.logoURL = 'assets/img/svg/logo.svg'; // to be resolved to client's logo
      let localLanguage = this.localService.getJsonValue('language');
      let sessionLanguage = this.localService.getJsonValue('language');
      if (!localLanguage && !sessionLanguage) {
        this.language = 'EN';
      }
      else if (localLanguage) {
        this.language = localLanguage
      }
      else if (sessionLanguage) {
        this.language = sessionLanguage;

      }
      this.referenceKey = this.referenceKey.replace('ZENEC-', '');
      this.referenceKey = this.decodeBase64(this.referenceKey);
      console.log(this.referenceKey);

      let arr: string[] = this.referenceKey.split('#SP#');
      if (arr.length == 5) {
        this.tenantKey = arr[0];
        this.companyKey = arr[1];
        this.engagementKey = arr[2];
        this.candidateKey = arr[3];
        this.candidateName = arr[4];
        console.log(this.candidateName);
      }
      
      this.isArabic();

      this.languageService.language = this.language;
      // this.setUpLanguagePreference();
      // this.localService.removeItem('sectionID');
      // this.localService.removeItem('assessmentSectionID');
      // this.localService.removeItem('pageID');
      // this.localService.removeItem('selectedEngagement');
      // this.localService.removeItem('selectedEntity');
      // this.localService.removeItem('UserKey');
      // this.localService.removeItem('selectedAssessment');
      // this.localService.removeItem('timeout');
      // this.localService.removeItem('Access');
      // this.localService.removeItem('User');
      // this.localService.removeItem('pageReference');
      // this.localService.removeItem('localSettings');

      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    }
    this.canvasStart();
    this.loaderService.hideLoader();

  }

  decodeBase64(base64) {
    const text = atob(base64);
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    const decoder = new TextDecoder(); // default is utf-8
    return decoder.decode(bytes);
  }
  // setUpLanguagePreference() {
  //   this.setUpTextFields();
  // }
  // txtWelcomeAboard: string;
  // txtwelcomedesc: string;
  // txtResetyourpassword: string;
  // txtResetPassworddesc: string;
  // txtUsername: string;
  // txtContinue: string;
  // txtLogin: string;
  // txtPassword: string;
  // txtForgotYourPassword: string;
  // txtBacktoLogin: string;
  // txtCheckyourEmaildesc: string;
  // txtCheckyourEmail: string;
  // txtHidepassword: string;
  // txtCheckEmails: string;
  // txtEnterEmail: string;
  // txtWrongInfo: string;
  // txtEmailPssword: string;
  // txtUserEmail: string;
  // txtEnterInformation: string;
  // txtID: string;
  // txt_required_field: string;
  // txtWrongPattern: string;
  // txt_okay_action: string;
  // setUpTextFields() {
  //   this.txt_okay_action = this.languageService.getUILanguage("txt_okay_action", this.language);
  //   this.txtWrongPattern = this.languageService.getUILanguage("txtWrongPattern", this.language);
  //   this.txt_required_field = this.languageService.getUILanguage("txt_required_field", this.language);
  //   this.txtID = this.languageService.getUILanguage("txtID", this.language);
  //   this.txtEnterInformation = this.languageService.getUILanguage("txtEnterInformation", this.language);
  //   this.txtWrongInfo = this.languageService.getUILanguage("txtWrongInfo", this.language);
  //   this.txtEmailPssword = this.languageService.getUILanguage("txtEmailPssword", this.language);
  //   this.txtCheckEmails = this.languageService.getUILanguage("txtCheckEmails", this.language);
  //   this.txtEnterEmail = this.languageService.getUILanguage("txtEnterEmail", this.language);
  //   this.txtWelcomeAboard = this.languageService.getUILanguage("txtWelcomeAboard", this.language);
  //   this.txtwelcomedesc = this.languageService.getUILanguage("txtwelcomedesc", this.language);
  //   this.txtResetyourpassword = this.languageService.getUILanguage("txtResetyourpassword", this.language);
  //   this.txtResetPassworddesc = this.languageService.getUILanguage("txtResetPassworddesc", this.language);
  //   this.txtUsername = this.languageService.getUILanguage("txtUsername", this.language);
  //   this.txtContinue = this.languageService.getUILanguage("txtContinue", this.language);
  //   this.txtLogin = this.languageService.getUILanguage("txtLogin", this.language);
  //   this.txtPassword = this.languageService.getUILanguage("txtPassword", this.language);
  //   this.txtForgotYourPassword = this.languageService.getUILanguage("txtForgotYourPassword", this.language);
  //   this.txtBacktoLogin = this.languageService.getUILanguage("txtBacktoLogin", this.language);
  //   this.txtCheckyourEmaildesc = this.languageService.getUILanguage("txtCheckyourEmaildesc", this.language);
  //   this.txtCheckyourEmail = this.languageService.getUILanguage("txtCheckyourEmail", this.language);
  //   this.txtHidepassword = this.languageService.getUILanguage("txtHidepassword", this.language);
  //   this.txtUserEmail = this.languageService.getUILanguage("txt_user_email", this.language);
  // }



  closeDropdown(menu) {
    if (this[menu]) this[menu] = false;
  }
  selectedLanguageChange(event) {
    this.language = event;
    this.localService.setJsonValue('language', this.language);

    this.isArabic()
  }




  isArabic() {
    if (this.languagePipe.transform(this.language)) {
      $("body").removeClass('ltr')
      $("body").addClass('rtl').attr("dir", "rtl");

    }
    else {
      $("body").removeClass('rtl')
      $("body").addClass('ltr').attr("dir", "ltr")

    }
  }





  waitingForResponse: boolean = false;
  onSubmit(event?) {
    if (this.form.name && this.form.email) {
      let respondents: EngagementRespondent[] = [];
      let respondent: EngagementRespondent = new EngagementRespondent();
      respondent.name = this.form.name;
      respondent.email = this.form.email;
      respondent.respondentType = 'EVALUATOR';
      respondent.candidateName = this.candidateName;
      respondent.Metrics = [];
      respondent.actualStatus = 'PENDING';
      respondent.status = 'PENDING';
      respondent.candidateKey = this.candidateKey;
      respondent.evaluatorEvaluatorsMerged = false;
      respondent.evaluatorLimit = 20;
      respondent.evaluatorType = 'CUSTOMER';
      respondent.isClosed = false;
      respondents.push(respondent);
      let request = {
        CompanyKey: this.companyKey,
        SurveyKey: this.engagementKey,
        SurveyType: 'ASM',
        TenantKey: this.tenantKey,
        sendEmail: false,
        respondents: respondents
      };
      this.waitingForResponse = true;
      this.subscriptions.add(this.assessmentService.addRespondentFromLandingPage(request).subscribe((response: any) => {
        if (response.response == 'SUCCESS') {
          let respondentID = response.Data;
          respondent.referenceKey = "ZENEC-" + btoa(this.tenantKey + '#SP#' + this.companyKey + '#SP#' + this.engagementKey + '#SP#' + respondentID);
          localStorage.setItem('LandingPageRef', respondent.referenceKey);
          this.router.navigate([
            '/assessment-questionnaire/' + respondent.referenceKey,
          ]);


        } else {
            this.waitingForResponse = false;
            this.zenSnackBarService.snackBar({message:this.translationPipe.transform('txt_user_exists_message', this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error});          
        }
      }));
    }
    else {
      this.valid = false;
      this.zenSnackBarService.snackBar({message:this.translationPipe.transform('txt_fill_fields', this.language, this.ModuleNames.Assessment), type: ZenSnackBarTypes.Error });
    }
  }


  canvasStart() {
    var canvas = <HTMLCanvasElement>document.getElementById('c');
    var ctx = canvas.getContext('2d');
    let colors = ['#fbfbfb', '#f7f7f8', '#f4f4f4', '#f0f0f1', '#ededee', '#e9e9ea']
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
    let speed = 1;

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let color = 0; color < colors.length; color++) {
        ctx.beginPath();
        ctx.moveTo(-200, (canvas.height / 10 + Math.sin(speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.bezierCurveTo(
          canvas.width / 2,
          (canvas.height / 4 + Math.cos(speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width / 4,
          (canvas.height / 4 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width + 200,
          (canvas.height / 3 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.lineWidth = 130 + color * 100 / 2;
        ctx.strokeStyle = colors[color];
        ctx.stroke();
      }
      speed += .5;
    }

    animate();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
