<div class="flex flex-col px-10 py-14">
  <div class="flex flex-col w-full items-stretch gap-5">
    <div class="flex flex-row justify-between items-center w-full">
      <h2 [@child5] class="text-zen font-normal text-3xl">{{
        'txt_respondents_pool' |translate:language:ModuleNames.Shared }}</h2>
      <div class="flex flex-row gap-3 items-center">

        <a [@child7] (click)="OpenModal()" class="btn exp uppercase leading-12 rounded px-5">
          {{ 'txt_new_respondent'| translate:language:ModuleNames.Shared }}
        </a>
      </div>
    </div>
     <wb-respondent-pool [resetSelection]="resetSelection" [currentPage]="currentPage" [language]="language" (export)="downloadExcell()" (allSelected)="selectedAll($event)" (searchTextChange)="searchTextUpdate($event)" (pageChanged)="onPageChange($event)" [pageSize]="pageSize" [totalRecords]="totalRecords" [exportable]="true"   [options]="options" (singleDelete)="deleteRespondent($event)" (bulkDelete)="deleteRespondents($event)"></wb-respondent-pool>
  </div>

</div>

<!-- <respondents-modal [key]="'RESPOOL'" [surveyInfo]="engagementData?.engagement" [essentialObject]="essentialObject"
  *ngIf="respondentAdd" [language]="language" [noTable]='true' (singleRespondent)="updateRespondentPool($event)"
  [(respondentAdd)]="respondentAdd" (uploadRequest)="uploadRespondents($event)" [dataSource]="companyRespondentsList"
  [newRespondent]="newRespondent" (downloadCSV)="downloadExcel($event)"
  (uploadCSV)="changeListener($event)"></respondents-modal> -->
