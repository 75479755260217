<div (click)="exportDraft(language)" *ngIf="leaverAnswers?.sections?.length" class="flex flex-col items-center "
  [ngClass]="{'rtl': (language | language)}">
  <div class="overflow-x-hidden" id="pdf">
    <div class="block  relative bg-white innerListScroll w-export overflow-x-hidden">
      <!-- front page -->
      <div class="innerPage relative">
        <div
          class="absolute bottom-0 top-0 left-0 z-50  flex flex-col w-full items-stretch justify-between bg-white p-10">
          <div class="flex flex-col items-stretch justify-between">
            <div class="flex flex-row justify-between gap-10">
              <h6 class="text-zen text-sm uppercase font-semibold text-opacity-50">
                {{"txtConfidential" | translate:language:ModuleNames.Exit}}
              </h6>
              <img class="h-28" [src]="entity?.logoURL? entity?.logoURL: 'assets/img/no-logo.png'" alt="">
            </div>

            <div class="flex flex-col gap-20">

              <div class="mt-12 flex flex-col gap-2 uppercase">
                <h1 class="text-zen text-5xl font-medium" [innerHTML]="'txtLeaverAnswerReport' |
                        translate:language:ModuleNames.Exit"></h1>
              </div>

              <div class="flex flex-col gap-2">
                <h6 class="text-zen-lite text-xl">{{"txtfor" |
                  translate:language:ModuleNames.Exit}}</h6>
                <h1 class="text-zen text-xl font-semibold">{{leaverName}}
                </h1>

              </div>
            </div>

          </div>

          <div class="flex flex-col gap-1">
            <p class="text-xs text-zen text-opacity-50">{{"txtPoweredby" |
              translate:language:ModuleNames.Exit}}</p>
            <img class="h-10 w-36 object-contain" src="assets/img/logo.png" alt="">
          </div>
        </div>
      </div>

      <div class="innerPage block overflow-x-hidden px-10">
        <div class="block pt-16 pb-5 overflow-x-hidden" [ngClass]="{'breakBefore':gi != 0}"
          *ngFor="let section of leaverAnswers.sections; let gi = index">
          <h3 class="text-zen text-lg font-semibold pb-6"><span class="text-exp">{{gi + 1}}.</span> {{section.name|
            extract:language}}
          </h3>
          <ng-container *ngFor="let question of section.questions; let qI = index">
            <div [ngClass]="{'pt-16':qI != 0, 'mt-20 breakBefore':qI == 2 && gi == 0}" class="avoidBreak pb-6"
              *ngIf="(resignationCheck(question) && !question.dependsOnQuestionKey) || dependsOnQuestionCheck(question, section) || !question.conditionallySkippable">
              <div class="flex flex-row justify-between items-center gap-5 relative pb-3 text-sm">
                <div class="flex flex-row items-start gap-1.5 font-semibold">
                  <span class="text-exp">{{
                    "txtQ"| translate:language:ModuleNames.Exit }}{{qI + 1}}. </span>
                  <h3 class="text-zen "> {{extractNameLanguage(question.name)}}</h3>
                </div>


                <div class="flex flex-row items-center justify-start w-36 minw36 rounded gap-2 text-zen bg-opacity-80 px-3"
                  *ngIf="question.questionType == 'single-answer-confidence-with-na'"
                  [style.background]="returnAnswerData(question.answer, 'color') + '7a'">
                  <i [class]="returnAnswerData(question.answer, 'icon')" class="fa-regular text-xl"></i>
                  <h5 class=" text-opacity-80 text-xs  text-center whitespace-nowrap leading-9">
                    {{getConfidence('single-answer-confidence-with-na', question.answer)}}</h5>
                </div>
              </div>

              <div class="block relative">
                <div class="flex flex-col gap-3 items-stretch">
                  <app-single-answer-ten [export]="true" *ngIf="question.questionType == 'single-answer-out-10'"
                    [question]="question" [legends]="false">
                    <ng-container legends>
                      <div class="flex flex-row justify-between px-3 pb-3" *ngIf="question.customKey =='satisfaction'">
                        <span class="text-xs text-zen-lite transform  upperFirstLetter">{{'txtVeryPoor'|
                          translate:language:ModuleNames.Exit}}</span>
                        <span class="text-xs text-zen-lite transform  upperFirstLetter">{{'txtVeryGood'|
                          translate:language:ModuleNames.Exit}}</span>
                      </div>
                      <div *ngIf="question.customKey =='enps'"
                        class="flex flex-row  items-center justify-between  h-2.5 mb-5 px-2">
                        <div class="px-1 " [style.width.%]="legend.steps / 11 * 100"
                          *ngFor="let legend of legends; let n = index">
                          <div
                            class="h-2.5 border-r border-l border-b border-zen border-opacity-20 flex flex-row items-end justify-center">
                            <span
                              class="text-xs text-zen-lite transform translate-y-2 px-3 upperFirstLetter bg-white">{{legend.name
                              | translate:language:ModuleNames.Exit
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </app-single-answer-ten>
                </div>
                <div class="flex flex-col fullWidthflex gap-1"
                  *ngIf="question.questionType == 'single-answer-confidence-with-na'">
                  <div *ngIf="question?.comment?.length"
                    class="flex flex-row items-start  rounded border gap-2.5 text-zen border-zen border-opacity-10 p-2 overflow-hidden bg-white text-xs">
                    <h5 class="text-xs text-zen-lite text-opacity-70 font-medium uppercase">{{"txtComment" |
                      translate:language:ModuleNames.Exit}}: </h5>
                    <span>{{question?.comment}}</span>

                  </div>
                </div>
              </div>
              <ng-container
                *ngIf="question.questionType == 'multi-options-multi-answer' ||  question.questionType == 'multi-options-single-answer'">
                <div class="block relative" *ngIf="question.questionType == 'multi-options-multi-answer'">
                  <div class="inline-block min-h-8"
                    [ngClass]="question.otherAnswer1 && n == question.questionOptions.length-1?'w-full':'w-1/2'"
                    *ngFor="let option of question.questionOptions; let n = index">
                    <div class="flex flex-col px-1 pb-2">
                      <div class="rounded h-full px-2"
                        [ngClass]="checkValue(question.answer, option.key)?'bg-exp bg-opacity-20':'bg-white border border-zen border-opacity-10'">
                        <div class="flex flex-row gap-3 items-center">
                          <i [ngClass]="checkValue(question.answer, option.key)?'fa-solid fa-check-square text-exp':'fas fa-square opacity-20 text-zen'"
                            class="text-2xl leading-9"></i>
                          <h5 class="text-zen leading-5">
                            <span class="text-xs font-normal  leading-5">{{extractNameLanguage(option.name)}}</span>
                          </h5>
                        </div>

                        <p class="text-zen font-normal"
                          *ngIf="question.otherAnswer1 && n == question.questionOptions.length-1">
                          {{question.otherAnswer1}}
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="block relative"
                  *ngIf="question.questionType == 'multi-options-single-answer' && question.answer">
                  <ng-container *ngIf="!question.hideOptionsInPreview">
                    <div *ngFor="let option of question.questionOptions; let i = index"
                      class="rounded block px-5 w-full mb-2"
                      [ngClass]="{'bg-exp bg-opacity-20':question.answer == option.key, 'bg-zen bg-opacity-5':question.answer != option.key, 'mt-20 breakBefore': i != 0 && i%21 == 0}">
                      <div class="block">
                        <div class="flex flex-row gap-3 items-center h-9">
                          <i [ngClass]="question.answer == option.key?'bg-exp bg-opacity-20':'bg-white border border-zen border-opacity-10'"
                            class="text-2xl leading-9"></i>
                          <h5 class="text-zen leading-9">
                            <span class="text-xs font-normal">{{extractNameLanguage(option.name)}}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="question.hideOptionsInPreview">

                    <ng-container *ngFor="let option of question.questionOptions; let i = index">

                      <div *ngIf="question.answer == option.key" class="rounded  px-5 h-full"
                        [ngClass]="question.answer == option.key?'bg-exp bg-opacity-20':'bg-zen bg-opacity-5'">
                        <div class="flex flex-row gap-3 items-center">
                          <i [ngClass]="question.answer == option.key?'fa-solid fa-check-square text-exp':'fas fa-square opacity-20 text-zen'"
                            class="text-2xl leading-9"></i>
                          <h5 class="text-zen leading-9">
                            <span class="font8 font-normal">{{extractNameLanguage(option.name)}}</span>
                          </h5>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                  <div *ngIf="checkOther(question)" class="px-3 w-full">
                    <div class="bg-zen bg-opacity-10 rounded-md px-5 pt-1 overflow-hidden w-full">
                      <mat-form-field class="w-full -mt-3" appearance="none">
                        <textarea disabled rows="3" maxlength="300"
                          [placeholder]="'txtSpecifyHere'| translate:language:ModuleNames.Exit"
                          [ngModel]="question.otherAnswer1" matInput></textarea>
                      </mat-form-field>

                    </div>

                  </div>


                </div>
              </ng-container>
              <div class="flex-row container" *ngIf="question.questionType == 'text'">
                <div class="rounded border text-zen font8 border-zen border-opacity-10 p-2 overflow-hidden bg-white">
                  <span>{{question?.comment}}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

      </div>
      <div class="innerPage overflow-x-hidden px-10 breakBefore" *ngIf="notes?.length">
        <div class="exportContainer pt-20 overflow-x-hidden relative">
          <h5 class="text-zen font10 semibold pb-5">{{"Notes"
            |translate:language:ModuleNames.Shared}}</h5>
          <div *ngFor="let note of notes; let i = index" [ngClass]="{'pt-20 breakBefore':i != 0 && (i+1) % 13 == 0}"
            class="block ">
            <div
              class="flex flex-col gap-1.5 items-stretch rounded border text-zen border-zen border-opacity-10 overflow-hidden bg-white">
              <span class="border-t text-zen border-zen border-opacity-10 h-0" *ngIf="n != 0"></span>
              <div class="p-2">
                <div class="flex flex-row justify-between items-center text-3xs">
                  <h5 class="font-medium text-zen-lite uppercase">{{note.userName}}</h5>
                  <h5 class="font-normal text-zen-grey">{{note.creationTime|date:'dd/MM/yyyy, h:mm a'}}</h5>
                </div>
                <h5 class="font-normal text-zen font10">{{note.note}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="header w-full px-10 flex flex-row items-center justify-between overflow-x-hidden">
      <h5 class="text-xs text-zen text-opacity-60">{{"txtLeaverAnswerReport"| translate:language:ModuleNames.Shared
        }}
      </h5>
      <h5 class="text-xs text-zen "><span class="text-opacity-60">{{ "txt_survey_questions"|
          translate:language:ModuleNames.Shared }} </span> <span class="text-zen font-medium">{{leaverName}}</span>
      </h5>
    </div>
    <div class="footer w-full px-10 flex flex-row items-center justify-between overflow-x-hidden">
      <div class="flex flex-col gap-1.5">
        <p class="text-opacity-50 text-zen text-3xs"> {{"txtPoweredby"|translate:language:ModuleNames.Shared}}</p>
        <img class="w-25 object-cover" src="assets/img/logo.png" alt="">
      </div>

      <div class="flex flex-col items-end gap-1">
        <h5 class="text-xs text-zen text-opacity-60">
          {{"txtGenerated"|translate:language:ModuleNames.Shared}}: {{date | date: 'dd/MM/yyyy'}}
        </h5>
        <h5 class="text-xs text-zen text-opacity-60">
          {{"txtAllRights"|translate:language:ModuleNames.Shared}} © {{date | date: 'yyyy'}} -
          www.zenithr.com</h5>

      </div>
    </div>


  </div>
</div>
