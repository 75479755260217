<z-modal [ngClass]="{'RTL':language == 'AR'}" [open]="open">
    <ng-container body>
        <div class="flex flex-wrap flex-col items-center">

            <div class="check-wrapper mb-5">
                <i class="fa-solid fa-check"></i>
            </div>

            <p class="title-bold text-center mb-3">
                {{"The survey has been published successfully"| translate:language:ModuleNames.SurveyX}}
            </p>
        </div>
    </ng-container>

    <ng-container footer>
        <div class="flex flex-row justify-between w-full">
            <a [routerLink]="'/survey-x/form-library'"
                class="btn cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
                {{"Back to Forms"| translate:language:ModuleNames.SurveyX}}
            </a>
            <a class="btn exp text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1"
                [routerLink]="['/survey-x/form-library/live-form/',surveyId]">
                {{"Live View"| translate:language:ModuleNames.SurveyX}}
            </a>
        </div>
    </ng-container>
</z-modal>
