import { Component, OnInit } from '@angular/core';
import { CommentCountDto, CommentsService } from '@ex/exit/comments';
import { SectionDto } from '@ex/exit/survey-templates';
import { AnalyticsFilter, AnalyticsService } from '@ex/module/exit/data-access/services/analytics.service';
import { ExitAiCommentsService } from '@ex/module/exit/data-access/services/exit-ai-comments.service';
import { ExitBaseComponent } from '@ex/module/exit/ui/exit-base/exit-base.component';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CommentExcelConfig, CommentExcelDownloadService, ExcelComment, ExcelQuestion, ExcelSection } from 'src/app/core/services/comment-excel-download.service';
import { AI_COMMENTS_SERVICE } from 'src/app/shared/ai-comments/components/main/main.component';

@Component({
  selector: 'app-comments-analysis',
  templateUrl: './comments-analysis.component.html',
  styleUrls: ['./comments-analysis.component.scss'],

})
export class CommentsAnalysisComponent extends ExitBaseComponent implements OnInit {

  constructor(private commentsService: CommentsService
    , private analyticsService: AnalyticsService
    , private commentsExcelDownloadService: CommentExcelDownloadService) {
    super();
  }


  ngOnInit(): void {

    this.language = this.AnalysisComponentData.language;
    // this.fetchComments()

    // const subscription = this.analyticsService.filters$
    //   .pipe(
    //     filter(filters => filters != null),
    //   )
    //   .subscribe(filters => {
    //     this.filtersLoaded.next(filters);
    //   })
    // this.addSubscription(subscription);
  }

  fetchComments() {

    this.LoaderService.displayLoader();

    const subscription = this.filtersLoaded$.pipe(switchMap(filters => {
      return this.commentsService.getSectionsCommentCount(filters.survey.key, [...this.analyticsService.mapGlobalFilters(filters)]).pipe(map(counts => ({ filters, counts })))
    })).subscribe(({ filters, counts }) => {
      this.counts = counts
      this.sections = filters.survey.sections.filter(x => this.getSectionCount(x.key));
      this.filters = filters;
      this.language = this.AnalysisComponentData.language;

      this.LoaderService.hideLoader();
    });

    this.addSubscription(subscription);
  }
  filters: AnalyticsFilter;
  language: string;
  counts: CommentCountDto;
  sections: SectionDto[];

  getSectionCount(key) {
    return this.counts.sectionCount.find(x => x.key == key)?.count ?? 0;
  }

  getQuestionCount(questionKey: string) {
    return this.counts?.questionCount.find(x => x.key == questionKey)?.count || 0;
  }

  downloadComments() {

    const commentsConfig = {
      filename: "comments",
      sections: [],
      skipSentiment: true
    } as CommentExcelConfig


    this.analyticsService.filters$.pipe(
      map(filters => {
        const sections = filters.survey.sections
          .filter(x => this.getSectionCount(x.key))
          .map(section => {
            return {
              key: section.key,
              name: this.extractNameLanguage(section.name),
              questions: section.questions
                .filter(x => this.getQuestionCount(x.key))
                .map(question => {
                  return {
                    key: question.key,
                    comments: [],
                    totalComments: 0,
                    name: this.extractNameLanguage(question.name)
                  } as ExcelQuestion
                }),
            } as ExcelSection
          })

        return sections;
      }),
      switchMap(sections => {

        let questions = sections.flatMap(x => x.questions);
        let comments$ = questions.map(questions => {
          return this.commentsService.getList({
            companyKey: this.filters.companyKey,
            surveyKey: this.filters.survey.key,
            tenantKey: this.filters.tenantKey,
            questionKey: questions.key,
            maxResultCount: 10,
            skipCount: 0,
            isAllResults: true,
            filters: [... this.analyticsService.mapGlobalFilters(this.filters), { key: "Question", value: questions.key }]
          })
        })

        return combineLatest([...comments$]).pipe(
          map(results => ({ results, sections }))
        );
      })
    ).subscribe(({ results, sections }) => {
      
      sections.flatMap(x => x.questions).forEach((q, i) => {
        q.comments = results[i].items.map(c => {
          return {
            name: c.comment
          } as ExcelComment
        })
      })

      commentsConfig.sections = sections;

      this.commentsExcelDownloadService.generate(commentsConfig);
    })

  }
}
