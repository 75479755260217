<div [ngClass]="{'dirRTL':(language | language )}">
  <z-modal *ngIf="(entityGroupNameInput?.name ||'txt_entity_group_name'| translate:language:ModuleNames.Exit) && modalInterval" [open]="open">
    <ng-container header>
      <div class="flex flex-row items-center gap-2" >
        <i class="fal fa-building text-white bg-exp shadow-xl rounded-full h-10 w-10 text-xl text-center leading-10 "></i>
        <h4 class="text-2xl font-bold text-zen truncate max-w-lg">{{ entityGroupNameInput?.name ||'txt_entity_group_name'| translate:language:ModuleNames.Exit }}
        </h4>
      </div>
    </ng-container>
    <ng-container body>
      <div class="flex flex-col gap-6">

        <z-translation-input [invalid]="invalid" [maxCount]="mdCount" [required]="true" [label]="'txt_group_name'| translate:language:ModuleNames.Exit" [language]="language"
          [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="entityGroup.name"></z-translation-input>

        <div class="flex flex-col gap-5">
          <div class="w-full flex flex-col ">
            <z-select [invalid]="invalid" [required]="true" [color]="'exp'" [(ngModel)]="entityGroup.parentKey"
              [label]="'txt_parent_entity_group'| translate:language:ModuleNames.Exit" [key]="'groupKey'" [options]="entityGroups" [language]="language" (selectionChange)="onParentStructureChange($event)">
              <ng-template #valueTemplate let-value>
                {{getEntityName(value?.name)}}
              </ng-template>
            </z-select>

            <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
              <mat-form-field class="w-full" appearance="none">
                <mat-label>{{ txtParentStructureGroup }}</mat-label>
                <mat-select #parent="ngModel" [(ngModel)]="entityGroup.parentKey"
                  (selectionChange)="onParentStructureChange($event)">
                  <mat-option *ngFor="let eG of entityGroups" [value]="eG.groupKey">{{ getEntityName(eG.name) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="parent.invalid && (parent.dirty || parent.touched)"
              class="alert alert-danger transform translate-y-1">
              <div class="text-red-600 text-xs" *ngIf="parent.errors.required">
                {{ txtFieldIsRequired }}
              </div>
            </div> -->
          </div>

          <div *ngIf="entityGroup.parentKey" class="flex flex-row justify-between items-center h-15">
            <div class="flex flex-row">
              <p class="text-zen">{{ 'txt_define_entities_reports'| translate:language:ModuleNames.Exit }}</p>
              <p class="truncate px-1 max-w-xs text-zen">
                {{ entityGroupNameInput?.name ||'txt_entity_group_name'| translate:language:ModuleNames.Exit }}
              </p>
            </div>

            <a [disabled]="!entityGroup.parentKey" class="btn exp truncate max-w-xs px-5 py-3 rounded"
              (click)="addNewEntity()">{{entityGroupNameInput?.name?('btn_add'| translate:language:ModuleNames.Exit) + ' '+ entityGroupNameInput?.name :
              'btn_add_structure_entity'| translate:language:ModuleNames.Exit }}</a>
          </div>

          <div *ngIf="entityGroup.parentKey && entitiesInParentEntityGroup.length > 0" class="w-full flex flex-col gap-6">
            <!-- <div class="flex flex-row justify-between items-center mb-1">
              <div class="w-1/2 text-zen text-opacity-70">{{'txtEntity'| translate:language:ModuleNames.Exit}}</div>
              <div class="w-1/2 text-zen text-opacity-70">{{'txtReportsto'| translate:language:ModuleNames.Exit}}</div>
            </div> -->
            <ng-container *ngFor="let e of entitiesInEntityGroup; let i = index">
              <div class="flex flex-row justify-between items-center gap-5 w-full py-1">
                <z-translation-input [invalid]="invalid" [maxCount]="mdCount" [required]="true" [label]="'txt_entity_name'| translate:language:ModuleNames.Exit" [language]="language"
                  [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="e.name"></z-translation-input>



                <div class="flex flex-row w-full gap-2 items-stretch">
                  <div class="flex flex-col items-stretch w-full">
                    <z-select [invalid]="invalid" [required]="true" [color]="'exp'" (selectionChanged)="parentSelected($event, e)" [(ngModel)]="e.parentKey"
                      [label]="'txt_parent_entity_group'| translate:language:ModuleNames.Exit" [key]="'key'" [options]="entitiesInParentEntityGroup" [language]="language">
                      <ng-template #valueTemplate let-value>
                        {{ getParentEntityName(value?.parentKey) + '' + getEntityName(value?.name) }}
                      </ng-template>

                    </z-select>
                  </div>
                  <div class="flex flex-row items-end">
                    <a class="btn delete rounded" [matTooltip]="'btn_leaver_list_delete'| translate:language:ModuleNames.Exit" (click)="deleteEntityInGroup(e, i)"><i
                      class=" fal fa-trash leading-9 px-2"></i></a>
                  </div>

                </div>

              </div>
            </ng-container>
          </div>

        </div>


      </div>
    </ng-container>
    <ng-container footer>
      <a mat-dialog-close (click)="closeModal()"
        class="btn cancel  text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{'btn_cancel'| translate:language:ModuleNames.Exit}}</a>
      <button (click)="updateEntityGroup()" class="btn exp  w-auto text-center  align-middle rounded-md px-8 py-4">
        <span class="mx-1">{{( newEntityGroup ? 'btn_add_structure_entity' : 'btn_update')| translate:language:ModuleNames.Exit}}</span>
      </button>
    </ng-container>
  </z-modal>
</div>
