<div class="flex flex-row justify-between items-center bg-white h-full rounded px-7 w-full shadow-sm">
    <div class="flex flex-row items-center gap-7">
        <i [ngClass]="icon" class="fa-regular text-zen text-opacity-60 text-4xl"></i>
        <div>
            <h5 class="text-zen font-medium text-lg">{{ title }}</h5>
            <h5 class="text-zen-lite font-normal text-xs" *ngIf="subTitle">{{ subTitle }}</h5>
        </div>
        
    </div>
    <h5 class="text-zen font-medium text-4xl">{{ value }}</h5>
</div>