import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class LocalService {
  constructor() { }
  // Set the json data to local storage
  setJsonValue(key: string, value: any) {
    if (key) {
      if (key != '') {
        if (key == 'selectedEngagement' || key == 'selectedAssessment' || key == 'selectedHappiness') {
          localStorage.setItem(key, value);
        } else {
          localStorage.setItem(key, this.b64EncodeUnicode(value));
        }
      }
    }
  }
  // Get the json value from local storage
  getJsonValue(key: string) {
    if (key) {
      if (key != '') {
        let val = localStorage.getItem(key);
        if (val) {
          if (key == 'selectedEngagement' || key == 'selectedAssessment' || key == 'selectedHappiness')
            return val;
          else
            return atob(val);
        } else {
          return val;
        }
      }
    }
  }

  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(Number('0x' + p1));
    }));
  }
  removeItem(key: string) {
    localStorage.removeItem(key);
  }


}
