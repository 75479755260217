<zen-modal-stepper [(open)]="open">
    <ng-container header>
        <div class="flex flex-col">
            <h2 class="text-zen text-2xl font-semibold"> {{leaver?.key?.length?('txt_edit_leaver' |
                translate:language:ModuleNames.Exit): ('txt_new_leaver' |
                translate:language:ModuleNames.Exit)}} <span *ngIf="mode == modes.Manual && !leaver?.key"
                    class="font-normal">
                    - {{'btn_add_manually' | translate:language:ModuleNames.Exit}}</span>
                <span class="font-normal" *ngIf="mode == modes.Excel"> - {{'txtUseSpreadsheet' |
                    translate:language:ModuleNames.Exit}}</span>
            </h2>
            <p class="text-zen text-sm font-normal" *ngIf="step == 0">{{'txtAddNewleaverText' |
                translate:language:ModuleNames.Exit}}
            </p>
        </div>
        <i class="fa-regular fa-times   btn text-center text-xl text-zen text-opacity-70" (click)="closeModal()"></i>
    </ng-container>
    <ng-container body>
        <div [@biDirectionalState300]="tab"
            class="flex flex-col items-stretch pt-5 w-800 overflow-x-hidden innerListScroll">
            <div *ngIf="tab == 0" class="flex flex-col items-center">
                <div class="flex flex-row justify-center items-stretch gap-5 pb-2">
                    <div (click)="openManualForm()"
                        class="flex flex-col items-center justify-center gap-4 bg-white border hover:text-exp hover:border-exp border-inset text-zen rounded shadow-2x6 p-5 w-32 btn text-center text-sm">
                        <i class="fa-light fa-pen text-5xl"></i>
                        <h5 class="text-xs">{{'btn_add_manually' | translate:language:ModuleNames.Exit}}</h5>
                    </div>
                    <div  *DemoAccount="'Button';let isDemo=isDemo" (click)="isDemo?'':openExcelUploadForm()"
                        class="flex  flex-col items-center justify-center gap-4 bg-white border hover:text-exp hover:border-exp border-inset text-zen rounded shadow-2x6 p-5 w-32 btn text-center text-sm">
                        <i class="fa-light fa-file-spreadsheet text-5xl"></i>
                        <h5 class="text-xs">{{'txtUseSpreadsheet' | translate:language:ModuleNames.Exit}}</h5>
                    </div>
                </div>
            </div>
            <div *ngIf="tab == 1">
                <ng-container *ngIf="mode == modes.Manual">
                    <leaver-manual [language]="language" [allEntities]="allEntities" [templates]="templates" [surveyKey]="surveyKey"
                        (leaverObject)="leaverUpdated($event)" [selectedEntity]="selectedEntity" [leaver]="leaver"
                        [step]="step" (reset)="closeHandler($event)" ></leaver-manual>
                </ng-container>
                <ng-container *ngIf="mode == modes.Excel">
                    <leaver-excel [language]="language" [selectedEntity]="selectedEntity" [surveyKey]="surveyKey"
                        [tenantKey]="tenantKey" [companyKey]="companyKey" [(step)]="step" (reset)="reset()"
                        (bulkUpdate)="bulkUpdateCompleted($event)">

                    </leaver-excel>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="step == 0" footer>
        <div class="flex flex-row justify-center w-full">
            <a class="btn cancel leading-9 px-4 text-xs rounded" (click)="closeModal()">{{'btn_cancel' |
                translate:language:ModuleNames.Exit}}</a>
        </div>
    </ng-container>
</zen-modal-stepper>
