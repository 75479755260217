import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExitBaseComponent } from '../exit-base/exit-base.component';
import { CommentsAiService } from 'src/app/shared/comments-ai/comments-ai.service';
import { SentimentAnalysis } from 'src/app/shared/comments-ai/sentiment-analysis';
import { sumBy } from 'lodash';
import { CommentsService, SectionSentimentDto } from '@ex/exit/comments';
import { filter, switchMap } from 'rxjs/operators';
import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'exit-comments-ai',
  templateUrl: './comments-ai.component.html',
  styleUrls: ['./comments-ai.component.scss']
})
export class CommentsAiComponent extends ExitBaseComponent implements OnInit {

  tenantKey: string
  companyKey: string
  surveyKey: string
  filters: AnalyticsFilter

  sections: SectionSentimentDto[] = [];

  /**
   *
   */
  constructor(private analyticsService: AnalyticsService, private commentsService: CommentsService) {
    super();
  }

  ngOnInit(): void {

    this.fetchCommentSentimentAnalysis();

    const subscription = this.analyticsService.filters$.pipe(
      filter(filters => filters != null)
    ).subscribe(filters => {
      this.filters = filters;

      this.filtersLoaded.next(filters);
    })

    this.addSubscription(subscription);
  }


  fetchCommentSentimentAnalysis() {

    const subscription = this.filtersLoaded$.pipe(
      switchMap(filters => {

        return this.commentsService.getCommentsAIAnalysis({
          companyKey: filters.companyKey,
          tenantKey: filters.tenantKey,
          surveyKey: filters.survey.key,
          filters: []
        })

      })
    ).subscribe(sections => {
      this.sections = sections;
    })

    this.addSubscription(subscription);
  }


  // @Input() essentialObject;
  // @Input() surveyType;
  // @Input() language;
  // @Input() engagementAnalysis: any;
  // @Input() selectedEntity;
  // @Output() topicChanged = new EventEmitter();
  // @Input('topics') set setTopics(topics: any[]) {
  //   if (topics) {
  //     this.topics = topics;
  //     if (this.topics.length > 0) {
  //       this.topics = JSON.parse(JSON.stringify(this.topics));
  //       this.reloadBubble();
  //     }
  //   }
  // }

  // @Input('driverComments') set setDriverComments(driverComments: EngagementDriver[]) {
  //   if (driverComments) {
  //     this.driverComments = driverComments;
  //   }
  // }

  // topics: any[] = [];
  // driverComments: EngagementDriver[];
  // originalDriverComments: EngagementDriver[] = [];

  // showComment;
  // rtl: boolean = false;
  // selectedTopic: string = '';
  // selectedTopicData: any;
  // topicSelected: boolean = false;
  // originalGroupedData: any[] = [];
  // public groupedData: any[] = [];
  // reloadBBle: boolean = false;
  // sentimentAnalysislst = []

  // constructor(private commentsAiService: CommentsAiService) {
  //   super();
  //   this.sentimentAnalysislst = this.commentsAiService.sentimentAnalysislst;
  // }

  // public get SentimentAnalysis() {
  //   return SentimentAnalysis;
  // }

  // ngOnInit(): void {
  //   if (document.body.dir == 'rtl') {
  //     this.rtl = true;
  //   }
  // }
  // calculateDriverSentiment(driverKey: string) {
  //   let driverSelected = this.driverComments.find(d => d.key == driverKey);
  //   if (driverSelected) {
  //     let driver = driverSelected;
  //     driver.positiveCount = 0;
  //     driver.negativeCount = 0;
  //     driver.neutralCount = 0;
  //     for (let x = 0; x < driver?.questionComments?.length; x++) {
  //       let questionComment: QuestionComment = driver.questionComments[x];
  //       driver.positiveCount = driver.positiveCount + questionComment.positiveCount;
  //       driver.negativeCount = driver.negativeCount + questionComment.negativeCount;
  //       driver.neutralCount = driver.neutralCount + questionComment.neutralCount;
  //     }
  //     if (driver.totalComments > 0) {
  //       driver.positive = Math.floor((driver.positiveCount / driver.totalComments) * 100);
  //       driver.negative = Math.floor((driver.negativeCount / driver.totalComments) * 100);
  //       driver.neutral = Math.floor((driver.neutralCount / driver.totalComments) * 100);
  //     }
  //     if (!this.topicSelected)
  //       this.driverComments = JSON.parse(JSON.stringify(this.driverComments));
  //   }
  // }
  // extractNameLanguage(eG: any) {
  //   return this.LanguageService.extractNameLanguage(eG, this.language);
  // }
  // getCommentslength(comments) {
  //   if (comments) {
  //     return sumBy(
  //       comments.map((a) => a.comments),
  //       'length'
  //     );
  //   } else {
  //     return 0;
  //   }
  // }
  // logData(data) {

  // }

  // loadMoreComments(questionComment: QuestionComment, filters?: EngagementMetric[], filterRemoved?: boolean) {
  //   questionComment.localFetching = true;
  //   let request = { SurveyKey: this.engagementAnalysis.SurveyKey, TenantKey: this.engagementAnalysis.TenantKey, CompanyKey: this.engagementAnalysis.CompanyKey, DisplayName: [questionComment.key], OffSet: (filters || filterRemoved) ? 0 : questionComment.comments.length, PageSize: 10, FilterByMetrics: filters != null ? filters : this.engagementAnalysis.FilterByMetrics };
  //   this.addSubscription(this.engagementService.GetEngagementCommentsForQuestion(request).subscribe((response: any) => {
  //     if (response.response == 'SUCCESS') {
  //       if (response.Data != null) {
  //         if (response.Data?.comments?.length > 0) {
  //           if (filters || filterRemoved)
  //             questionComment.comments = [];
  //           for (let i = 0; i < response.Data?.comments.length; i++) {
  //             questionComment.comments.push(response?.Data.comments[i]);
  //           }
  //           if (response?.Data?.totalComments > 0) {
  //             questionComment.totalComments = response.Data.totalComments;
  //             if (this.topicSelected == true) {
  //               let qC = this.driverComments[0].questionComments.find(q => q.key == questionComment.key);
  //               if (!qC) {
  //                 this.driverComments[0].questionComments.push(questionComment);
  //                 this.fetchSentimentPerQuestion(questionComment);
  //               } else {
  //                 this.fetchSentimentPerQuestion(questionComment);
  //               }
  //             }
  //           }
  //         } else {
  //           questionComment.comments = [];
  //           questionComment.totalComments = response.Data.totalComments
  //         }
  //         console.log('Question Comment After Fetching: ', questionComment);
  //       }
  //     }
  //     questionComment.localFetching = false;
  //   }));
  // }

  // fetchSentimentPerQuestion(questionComment: QuestionComment) {
  //   let request = {
  //     TenantKey: this.essentialObject.tenant.key,
  //     CompanyKey: this.essentialObject.selectedEntity.key,
  //     SurveyKey: this.essentialObject.engagement.key,
  //     SurveyType: "ENG",
  //     QuestionKey: questionComment.key,
  //     FilterByMetrics: questionComment.filterByMetrics
  //   }
  //   questionComment.sentimentFetching = true;
  //   this.subscriptions.add(this.engagementService.fetchQuestionAnalysis(request).subscribe((response: any) => {
  //     if (response.response == 'SUCCESS') {
  //       if (response.Data != null) {
  //         questionComment.negative = response.Data.negative;
  //         questionComment.positive = response.Data.positive;
  //         questionComment.neutral = response.Data.neutral;
  //         questionComment.positiveCount = response.Data.positiveCount;
  //         questionComment.neutralCount = response.Data.neutralCount;
  //         questionComment.negativeCount = response.Data.negativeCount;
  //         if (this.topicSelected) {
  //           let qC = this.driverComments[0].questionComments.find(q => q.key == questionComment.key);
  //           if (qC) {
  //             qC.negative = response.Data.negative;
  //             qC.positive = response.Data.positive;
  //             qC.neutral = response.Data.neutral;
  //             qC.positiveCount = response.Data.positiveCount;
  //             qC.neutralCount = response.Data.neutralCount;
  //             qC.negativeCount = response.Data.negativeCount;

  //             if (this.topicSelected)
  //               this.calculateDriverSentiment(this.driverComments[0].key);
  //           }
  //         }
  //       }
  //     }
  //     questionComment.localFetching = false;
  //     questionComment.sentimentFetching = false;
  //   }));
  // }
  // filterQuestionComments(sentiment: SentimentAnalysis, questionComment: QuestionComment) {
  //   let filterByMetrics: EngagementMetric[] = [];
  //   if (this.engagementAnalysis?.FilterByMetrics?.length > 0)
  //     filterByMetrics = JSON.parse(JSON.stringify(this.engagementAnalysis.FilterByMetrics));
  //   let newMetric = new EngagementMetric();
  //   newMetric.key = questionComment.key;
  //   newMetric.value = MetricsComments.SENTIMENT;
  //   newMetric.placeholderValue = sentiment;
  //   filterByMetrics.push(newMetric);
  //   questionComment.filterByMetrics = filterByMetrics;
  //   this.loadMoreComments(questionComment, questionComment.filterByMetrics);
  //   questionComment['activeFilter'] = true;
  //   questionComment['selectedSentiment'] = sentiment;
  // }
  // removeQuestionFilter(questionComment: QuestionComment) {
  //   questionComment['activeFilter'] = false;
  //   questionComment.filterByMetrics = null;
  //   this.loadMoreComments(questionComment, null, true);
  // }
  // filterDriverComments(sentiment: SentimentAnalysis, driver: EngagementDriver) {
  //   driver['selectedSentiment'] = sentiment;
  //   driver['activeFilter'] = true;
  //   driver.questionComments.forEach(ques => {
  //     this.filterQuestionComments(sentiment, ques)
  //   })
  // }
  // removeFilterDriver(driver: EngagementDriver) {
  //   driver['activeFilter'] = false;
  //   driver.questionComments.forEach(ques => {
  //     this.removeQuestionFilter(ques)
  //   })
  // }
  // stopPropagation(event: Event) {
  //   event.stopPropagation();
  // }
  // //Bubble chart
  // reloadBubble() {
  //   this.reloadBBle = true;
  //   this.groupedData = [];
  //   for (let i = 0; i < this.topics.length; i++) {
  //     let topic = this.topics[i];
  //     if (topic.neutralMentionPerc > 0 && topic.neutralMentionPerc > topic.negativeMentionPerc && topic.neutralMentionPerc > topic.positiveMentionPerc) {
  //       let xy: number[] = this.getCoordinates(SentimentAnalysis.neutral, topic, topic.mentionPercentage);
  //       this.groupedData.push({ name: SentimentAnalysis.neutral + i, group: SentimentAnalysis.neutral, color: SentimentColors.neutral, data: [{ x: xy[0], y: xy[1], size: topic.mentionPercentage, category: topic, topic: this.extractNameLanguage(topic.name) }] })
  //     }
  //     if (topic.negativeMentionPerc > 0 && topic.negativeMentionPerc >= topic.neutralMentionPerc && topic.negativeMentionPerc >= topic.positiveMentionPerc) {
  //       let xy: number[] = this.getCoordinates(SentimentAnalysis.negative, topic, topic.mentionPercentage);
  //       this.groupedData.push({ name: SentimentAnalysis.negative + i, group: SentimentAnalysis.negative, color: this.getColor(SentimentAnalysis.negative, topic), data: [{ x: xy[0], y: xy[1], size: topic.mentionPercentage, category: topic, topic: this.extractNameLanguage(topic.name) }] })
  //     }
  //     if (topic.positiveMentionPerc > 0 && topic.positiveMentionPerc >= topic.neutralMentionPerc && topic.positiveMentionPerc > topic.negativeMentionPerc) {
  //       let xy: number[] = this.getCoordinates(SentimentAnalysis.positive, topic, topic.mentionPercentage);
  //       this.groupedData.push({ name: SentimentAnalysis.positive + i, group: SentimentAnalysis.positive, color: this.getColor(SentimentAnalysis.positive, topic), data: [{ x: xy[0], y: xy[1], size: topic.mentionPercentage, category: topic, topic: this.extractNameLanguage(topic.name) }] })
  //     }
  //   }
  //   this.groupedData = this.adjustCoordinates(this.groupedData);
  //   this.groupedData = JSON.parse(JSON.stringify(this.groupedData));
  //   console.log(JSON.stringify(this.groupedData));
  //   this.reloadBBle = false
  // }
  // adjustCoordinates(data: any[]): any[] {
  //   const adjustedData = [...data];
  //   const proximityThreshold = 10; // Define the proximity threshold for adjusting y-values
  //   for (let i = 0; i < adjustedData.length; i++) {
  //     const currentData = adjustedData[i];
  //     let currentX = currentData.data[0].x;
  //     let currentY = currentData.data[0].y;
  //     const previousData = adjustedData.slice(0, i);

  //     // Check for overlapping y-values
  //     const previousYValues = previousData.map((d) => d.data[0].y);
  //     if (previousYValues.includes(currentY)) {
  //       const overlapCount = previousYValues.filter((y) => y === currentY).length;
  //       const newY = currentY - overlapCount * 0.1; // Adjust the y-value to avoid overlap
  //       currentY = newY;
  //     }
  //     // Check for proximity to other coordinates
  //     let hasProximity = true;
  //     while (hasProximity) {
  //       hasProximity = false;

  //       for (let j = 0; j < previousData.length; j++) {
  //         const previousX = previousData[j].data[0].x;
  //         const previousY = previousData[j].data[0].y;

  //         if (
  //           Math.abs(previousX - currentX) < proximityThreshold &&
  //           Math.abs(previousY - currentY) < proximityThreshold
  //         ) {
  //           currentY += 0.1; // Adjust the y-value to maintain proximity
  //           hasProximity = true;
  //           break;
  //         }
  //       }
  //     }
  //     // Update the adjusted x and y values in the adjustedData array
  //     adjustedData[i].data[0].x = currentX;
  //     adjustedData[i].data[0].y = currentY;
  //   }
  //   return adjustedData;
  // }
  // getCoordinates(group: string, category: any, size: number): number[] {
  //   const y = 30 + (size % 46) * 1.3; // Adjust y based on the size property
  //   if (group === SentimentAnalysis.negative) {
  //     let x = 40 - (category.negativeMentionPerc / 100) * 40; // Adjust x based on negativeMentionPerc
  //     if (x < 10)
  //       x = 10;
  //     return [x, y];
  //   } else if (group === SentimentAnalysis.neutral) {
  //     const x = 40 + (size % 21) * 1.5; // Adjust x based on the size property
  //     return [x, y];
  //   } else if (group === SentimentAnalysis.positive) {
  //     let x = 60 + (category.positiveMentionPerc / 100) * 40; // Adjust x based on positiveMentionPerc
  //     if (x > 90)
  //       x = 90;
  //     return [x, y];
  //   } else {
  //     return [0, 0]; // Default coordinates if group is not recognized
  //   }
  // }
  // getColor(group: string, category: any): string {
  //   let addPerc: number = 20;
  //   if (group === SentimentAnalysis.neutral) {
  //     return SentimentColors.neutral;
  //   } else if (group === SentimentAnalysis.negative) {
  //     const opacity = ((category.negativeMentionPerc + addPerc) > 100 ? 100 : (category.negativeMentionPerc + addPerc)) / 100;
  //     const redHex = Math.round(opacity * 255).toString(16).padStart(2, '0');
  //     return `${SentimentColors.negative}${redHex}`;
  //   } else if (group === SentimentAnalysis.positive) {
  //     const opacity = ((category.positiveMentionPerc + addPerc) > 100 ? 100 : (category.positiveMentionPerc + addPerc)) / 100;
  //     const greenHex = Math.round(opacity * 255).toString(16).padStart(2, '0');
  //     return `${SentimentColors.positive}${greenHex}`;
  //   } else {
  //     return SentimentColors.default; // Default color if group is not recognized
  //   }
  // }
  // getLegendColor(sentiment: SentimentAnalysis) {
  //   return SentimentColors[sentiment as keyof typeof SentimentColors]
  // }
  // public labelContent(e: any): string {
  //   return e.dataItem.topic + '\n' + e.dataItem.size + '%';
  // }
  // // selection Topics
  // OnselectedTopicChange(topicKey: string) {
  //   if (!this.topicSelected)
  //     this.originalDriverComments = JSON.parse(JSON.stringify(this.driverComments));
  //   let topic = this.topics.find(f => f.key == topicKey)
  //   if (topic) {
  //     this.selectedTopicData = { x: undefined, y: undefined, size: topic.mentionPercentage, category: topic, topic: this.extractNameLanguage(topic.name) };
  //     if (this.selectedTopic != topic.key) {
  //       this.selectedTopic = topic.key;
  //       this.topicSelected = true;
  //       this.processBubbleTopic();
  //     }
  //   }
  // }
  // removeTopicsFilter() {
  //   this.selectedTopic = '';
  //   this.topicSelected = false;
  //   this.processBubbleTopic();
  // }
  // public onBubbleClick(event: any): void {
  //   const clickedBubble = event.dataItem;
  //   if (!this.topicSelected)
  //     this.originalDriverComments = JSON.parse(JSON.stringify(this.driverComments));
  //   if (clickedBubble?.category?.key) {
  //     this.selectedTopicData = clickedBubble;
  //     if (this.selectedTopic != clickedBubble?.category?.key) {
  //       this.selectedTopic = clickedBubble.category.key;
  //       this.topicSelected = true;
  //       this.processBubbleTopic();
  //     } else {
  //       this.selectedTopic = '';
  //       this.topicSelected = false;
  //       this.processBubbleTopic();
  //     }
  //   } else {
  //     this.selectedTopic = '';
  //     this.topicSelected = false;
  //     this.processBubbleTopic();
  //   }
  // }
  // processBubbleTopic() {
  //   if (this.selectedTopic != '') {
  //     let selectedTopic = this.topics.find(t => t.key == this.selectedTopic);
  //     this.reloadBubble();
  //     this.reloadBBle = true;
  //     for (let i = 0; i < this.groupedData.length; i++) {
  //       let data = this.groupedData[i];
  //       if (data?.data[0]?.category?.key != this.selectedTopic) {
  //         data.color = '#D3D3D3';
  //       }
  //     }
  //     this.reloadBBle = false;
  //     this.driverComments = [];
  //     let topicDriver = new EngagementDriver;
  //     topicDriver = JSON.parse(JSON.stringify(this.originalDriverComments[0]));
  //     topicDriver.name = selectedTopic.name;
  //     topicDriver.questionComments = [];
  //     topicDriver.isTopic = true;
  //     topicDriver.totalComments = this.selectedTopicData.category.mentionCount;
  //     topicDriver.commentsPercentage = this.selectedTopicData.size;
  //     topicDriver.isTopic = true;
  //     for (let i = 0; i < this.originalDriverComments.length; i++) {
  //       let driver = this.originalDriverComments[i];
  //       driver = JSON.parse(JSON.stringify(driver));
  //       for (let x = 0; x < driver.questionComments.length; x++) {
  //         let questionComment = driver.questionComments[x];

  //         let filters: EngagementMetric[] = [];
  //         if (this.engagementAnalysis.FilterByMetrics.length > 0)
  //           filters = JSON.parse(JSON.stringify(this.engagementAnalysis.FilterByMetrics));
  //         let filter = new EngagementMetric;
  //         filter.key = questionComment.key;
  //         filter.value = MetricsComments.TOPIC;
  //         filter.placeholderValue = this.selectedTopic;
  //         filters.push(filter);
  //         questionComment.displayDriverName = true;
  //         questionComment.driverName = driver.name;
  //         console.log('Question Comment: ', questionComment);
  //         questionComment.filterByMetrics = filters;
  //         this.loadMoreComments(questionComment, filters);
  //       }
  //     }
  //     this.driverComments.push(topicDriver);
  //     this.showComment = 0;
  //   } else {
  //     this.reloadBubble();
  //     this.driverComments = JSON.parse(JSON.stringify(this.originalDriverComments));
  //   }
  // }
  // //legend classes
  // getPositiveClass(object) {
  //   if (object.negative == 0 && object.neutral == 0) {
  //     return 'rounded';
  //   } else {
  //     if (this.rtl)
  //       return 'rounded-r';
  //     else
  //       return 'rounded-l';
  //   }
  // }
  // getNegativeClass(object) {
  //   if (object.positive == 0 && object.neutral == 0) {
  //     return 'rounded';
  //   } else {
  //     if (object.positive == 0) {
  //       if (this.rtl)
  //         return 'rounded-r';
  //       else
  //         return 'rounded-l';
  //     } else {
  //       if (object.neutral == 0) {
  //         if (this.rtl)
  //           return 'rounded-l';
  //         else
  //           return 'rounded-r';
  //       } else
  //         return '';
  //     }
  //   }
  // }
  // getNeutralClass(object) {
  //   if (object.negative == 0 && object.positive == 0) {
  //     return 'rounded';
  //   } else {
  //     if (this.rtl)
  //       return 'rounded-l';
  //     else
  //       return 'rounded-r';
  //   }
  // }
  // //Bubble chart legend classes
  // getChartPositiveClass(object) {
  //   if (object.negativeMentionPerc == 0 && object.neutralMentionPerc == 0) {
  //     return 'rounded';
  //   } else {
  //     if (this.rtl)
  //       return 'rounded-r';
  //     else
  //       return 'rounded-l';
  //   }
  // }
  // getChartNegativeClass(object) {
  //   if (object.positiveMentionPerc == 0 && object.neutralMentionPerc == 0) {
  //     return 'rounded';
  //   } else {
  //     if (object.positiveMentionPerc == 0) {
  //       if (this.rtl)
  //         return 'rounded-r';
  //       else
  //         return 'rounded-l';
  //     } else {
  //       if (object.neutralMentionPerc == 0) {
  //         if (this.rtl)
  //           return 'rounded-l';
  //         else
  //           return 'rounded-r';
  //       } else
  //         return '';
  //     }
  //   }
  // }
  // getChartNeutralClass(object) {
  //   if (object.negativeMentionPerc == 0 && object.positiveMentionPerc == 0) {
  //     return 'rounded';
  //   } else {
  //     if (this.rtl)
  //       return 'rounded-l';
  //     else
  //       return 'rounded-r';
  //   }
  // }

}
