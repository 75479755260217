<div class="flex flex-col gap-5 w-full " (click)="logData([wellbeingDimensions, selectedDimensionKey])">
    <div class="py-5">
        <mat-divider></mat-divider>
    </div>
    <h5 class="text-zen text-2xl font-medium">
        {{'txtWellbeingIndexByScale'| translate:language:ModuleNames.Wellbeing}}
    </h5>
    <div class="flex flex-row justify-between items-center relative"  >

        <h6 class="text-opacity-80 text-zen uppercase">
            {{'txtbydimensions'| translate:language:ModuleNames.Wellbeing}}
        </h6>

        <z-select *ngIf="selectedDimensionKey" [color]="'exp'" [DemoLimit]="2"
            [label]="'txtBreakout'| translate:language:ModuleNames.Wellbeing" [(ngModel)]="selectedDimensionKey"
            [options]="wellbeingDimensions" [language]="language"
            (ngModelChange)="onDimensionChange($event);selectedDimensionKey = $event">
            <ng-template #valueTemplate let-value>
                <span [matTooltip]="value?.name|extract:language">{{value?.name|extract:language}}</span>
            </ng-template>
            <ng-template #optionTemplate let-value>
                {{value?.name|extract:language}}
            </ng-template>
        </z-select>

    </div>
    <div class="flex flex-col bg-white rounded shadow-sm">
        <div class="flex flex-row justify-evenly p-5">
            <div *ngFor="let wellbeingIndex of wellbeingIndexByDimension"
                class="flex flex-col items-center w-1/5">
                <kendo-arcgauge [color]="wellbeingScalesService.getColorByCode(wellbeingIndex.scale)"
                    [value]="wellbeingIndex.mean" style="min-width:11rem" renderAs="canvas">
                    <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450"
                        [rangePlaceholderColor]="wellbeingScalesService.getColorByCode(wellbeingIndex.scale) + '60'"
                        [rangeSize]="20" [rangeLineCap]="'butt'">
                        <ng-template kendoArcGaugeCenterTemplate let-value="value">
                            <span class="font-medium text-zen text-3xl">
                                {{(wellbeingIndex.mean | number:'1.1-1')}}%
                            </span>
                        </ng-template>
                    </kendo-arcgauge-scale>
                </kendo-arcgauge>
                <h5 [innerHtml]='wellbeingIndex.scale| translate:language:ModuleNames.Wellbeing'
                    class="font-medium text-sm text-zen">
                </h5>
            </div>

        </div>
    </div>
</div>
