import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { TranslatePipe } from '../pipes/translate.pipe';

@Directive({
  selector: '[NoDataOverlay]'
})
export class NoDataOverlayDirective {
  @Input('NoDataOverlay') set data(data: any) {
    if (!data) {
      console.log(this.el);
      this.el.nativeElement.insertAdjacentHTML('beforeend', `
      <div class="overlay flex flex-col items-center justify-center h-full w-full z-10 text-center bg-white bg-opacity-40 absolute left-0 top-0">
          <img src="assets/img/charts/no-data.svg" />
      </div>
      `)
    }
    else {
      if (this.el.nativeElement.querySelector('.overlay'))
        this.el.nativeElement.querySelector('.overlay').remove();

    }

  }
  constructor(private el: ElementRef, private renderer: Renderer2, private translate: TranslatePipe) {
    this.renderer.setStyle(el.nativeElement, 'position', 'relative')

  }

  // buttonHandle(ai?) {
  //   this.viewContainerRef.clear();
  //   const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
  //   view.rootNodes[0].insertAdjacentHTML('beforeend', ` <span class="h-0 flex  text-center absolute -top-1 -left-1 -right-1 flex-row items-start justify-end"><i class="fa-solid fa-star text-white bg-zen-yellow rounded-full leading-4 text-xxs text-center w-4 min-w-[4]" style="color:#fff"></i></span> `)

  //   view.context = this.context;
  // }

}
