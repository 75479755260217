import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RespondentDto } from '@ex/wellbeing/respondents';
import { pipe } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { GlobalStatusService, Status } from 'src/app/core/services/global-status.service';

@Component({
  selector: 'wb-respondent-pool',
  templateUrl: './wb-respondent-pool.component.html',
  styleUrls: ['./wb-respondent-pool.component.scss']
})
export class WbRespondentPoolComponent extends WbBaseComponent implements OnInit {

  constructor(public globalStatus: GlobalStatusService) {
    super();
  }

  ngOnInit(): void { }
  @ContentChild(TemplateRef) optionsTemplate: TemplateRef<any>;
  @Output('singleDelete') singleDelete = new EventEmitter();
  @Output('bulkDelete') bulkDelete = new EventEmitter();
  @Output('pageChanged') pageChanged = new EventEmitter();
  @Output('selectedRespondents') selectedRespondents = new EventEmitter();
  @Output() searchTextChange = new EventEmitter();
  @Input() searchText;
  @Output('allSelected') allSelected = new EventEmitter();
  @Output('export') export = new EventEmitter();
  @Input() canDelete: boolean = true;
  @Input() pageSize;
  @Input() totalRecords;
  @Input() deleteCount;
  @Input() exportable: boolean = false;
  @Input() options: TableOptions;
  @Input() currentPage;
  @Input() language;
  @Input() rowHeight: number = 20;
  @Input() customHeader: boolean = false;
  selectedRespondentsUpdate(respondents: Partial<RespondentDto>[]) {
    let uniqueRespondents = [
      ...new Map(respondents.map((item) => [item["email"], item])).values(),
    ];

    this.selectedRespondents.emit(uniqueRespondents);
    this.currentRespondents = uniqueRespondents;
    this.allSelected.emit(false);
    this.customSelectedAll = false;
    this.tableSelectAll = false;
  }
  filterText;
  @Input('resetSelection') set resetSelection(reset: boolean) {
    if (reset) {
      this.currentRespondents = [];
      this.clear();
    }
  }
  currentRespondents;
  customSelectedAll: boolean = false;
  tableSelectAll: boolean = false;
  rStatus = Status;
  selectAllRespondents() {
    this.allSelected.emit(true);
    this.customSelectedAll = true;
  }
  clear() {
    this.selectedRespondents.emit([])
    this.allSelected.emit(false);
    this.customSelectedAll = false;
    this.currentRespondents = [];
    this.tableSelectAll = false;
    this.options = JSON.parse(JSON.stringify(this.options));
  }
  selectAllFromTable(event) {

    this.tableSelectAll = event;
    if (!event) {
      this.allSelected.emit(false);
      this.customSelectedAll = false;
    }

  }
  replaceCount(text, count) {
    return this.localize(text, this.language, this.ModuleNames.Wellbeing).replace('#count', (count)?.toString() || 0);
  }

  emailCheck(value: string) {
    if (value.indexOf('sl_') != -1 && value.indexOf('_no_email@zenithr.com') != -1)
      return this.localize('txt_Generated_142', this.language, this.ModuleNames.Wellbeing);
    if (value.indexOf('sl_') === -1 && value.indexOf('_no_email@zenithr.com') != -1)
      return value.split('_')[0];
    return value;
  }

  statusReplace(status) {
    if (status == this.rStatus.Posted)
      return this.rStatus.Pending
    return status;
  }

  bulkDeleteSelected() {
    this.bulkDelete.emit(this.currentRespondents);
  }

}
