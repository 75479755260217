<zen-main-container>
  <sidebar-container>
    <exit-sidebar *ngIf="tenantKey" (buttonChange)="onStatusChange($event)" (selectedTemplateChange)="onTemplateChange($event)" [selectedTemplate]="selectedTemplate" [buttonList]="statusList" [language]="language" [tenantKet]="tenantKey" [companyKey]="companyKey" [templates]="templates" [tourKey]="'exit-leavers-list'"></exit-sidebar>
  </sidebar-container>
  <content-container>
    <div class="flex flex-col items-stretch h-28 z-10 sticky top-0" sticky>
      <section-head>
        <ng-container left>
          <div class="flex flex-col gap-1">
            <h5 class="text-zen-lite text-xxs font-medium">{{selectedTemplate?.name}}</h5>
            <h4 [@first] class="text-zen font-semibold text-2xl">{{ 'txt_leavers_list' |
              translate:language:ModuleNames.Exit }}</h4>
          </div>
        </ng-container>
        <ng-container right>
          <div class="flex flex-row w-full items-center gap-5 justify-end">
            <a [@child4] (click)="openTree()" *ngIf="selectedChildEntity?.entity.name" zenButton styles="secondary height-12">{{'txtViewBy'|
              translate:language:ModuleNames.Exit}}: {{ selectedChildEntity?.entity.name |
              extract:language
              }}</a>
            <div class="flex flex-row justify-between items-center gap-5">
              <a *DemoAccount="totalRecords < 10?'Default':'Button'; let isDemo=isDemo" [@child4] zenButton styles="exp height-12" (click)="totalRecords >= 10 && isDemo?'':unitDeductionCheck()" [TourStep]="{title:'txt_AddLeaver_425' | translate:language:ModuleNames.Exit,text: 'txt_Tostartad_645' | translate:language:ModuleNames.Exit,order: 3,tourKey:'exit-leavers-list'}">
                <i class="fa-regular fa-plus"></i>
                <span>{{ 'txt_new_leaver' | translate:language:ModuleNames.Exit }}</span>
                <!-- <span *ngIf="!hasCompanyPermission">({{ selectedChildEntity?.entity?.name | extract:language }})</span> -->
              </a>
            </div>
          </div>
        </ng-container>
      </section-head>
    </div>
    <leavers-list *ngIf="essentialObject" [entities]="allEntities" [selectedEntity]="selectedEntity" [language]="language" [currentPage]="currentPage" [pageSize]="maxResultCount" (pageChange)="onPageChange($event)" [page]="pageChanged$ | async" [leavers]="leavers" [totalRecords]="totalRecords" (filterChange)="onFilterChange($event)" [tenantKey]="tenantKey" [companyKey]="companyKey" [surveyKey]="surveyKey" [allSendReminderCount]="allSendReminderCount" (onEditLeaver)="editLeaver($event)" [tourKey]="'exit-leavers-list'"></leavers-list>
  </content-container>
  <product-tour *ngIf="showTour" [key]="'exit-leavers-list'" [offset]="122" [destroy]="true"></product-tour>
</zen-main-container>