import { updateQuestionHeaderHTML } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";

/**
 * Updates the HTML for an icon choice question.
 * @param options - The options for updating the icon choice question HTML.
 */
export function updateIconChoiceQuestionHTML(options: any) {
  // Update the question header
  updateQuestionHeaderHTML(options, "Icon Choice", "fa-smile");

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // body.className = body.className + " mt-5";
  body.innerHTML = "";

  var iconsContainer = document.createElement("div");
  iconsContainer.className = "flex flex-row flex-wrap items-center justify-center gap-3";

  for (var i = 0; i < options.question.choices.length; i++) {
    var choice = options.question.choices[i];

    const itemContainer = document.createElement("div");
    itemContainer.className = "flex flex-col items-center gap-1 icon-choice-container cursor-pointer";

    var iconContainer = document.createElement("div");
    iconContainer.className = "flex items-center choice-icon px-10 py-3";

    var icon = document.createElement("i");
    icon.className = "fa-light fa-" + choice.imageLink;

    iconContainer.appendChild(icon);

    var iconText = document.createElement("label");
    iconText.className = "icon-choice-text";
    iconText.innerHTML = choice.text;

    itemContainer.appendChild(iconContainer);
    itemContainer.appendChild(iconText);

    iconsContainer.appendChild(itemContainer);
  }

  // Randomly select an item (must select only one)
  var randomIndex = Math.floor(Math.random() * options.question.choices.length);
  var randomItem = iconsContainer.children[randomIndex] as HTMLDivElement;
  randomItem.className = randomItem.className + " icon-choice-selected";

  body.appendChild(iconsContainer);
}

/**
 * Updates the HTML for an image picker question.
 * @param options - The options for updating the image picker question HTML.
 */
export function updateImagePickerQuestionHTML(options: any) {
  // Update the question header
  updateQuestionHeaderHTML(options, "Image Choice", "fa-image");

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // body.className = body.className + " mt-5";

  body.innerHTML = "";

  // Create a new image choice question content with a div that has multiple items centered
  var newImageChoiceQuestionContent = document.createElement("div");
  newImageChoiceQuestionContent.className = "flex flex-wrap gap-3 py-4 w-full rank-items-container";

  body.appendChild(newImageChoiceQuestionContent);

  // Add the choices
  addImageChoices(options);
}

/**
 * Adds image choices to the image picker question HTML.
 * @param options - The options for adding image choices.
 */
export function addImageChoices(options: any) {
  var newRankingQuestionContent = options.htmlElement.querySelector(".rank-items-container");
  newRankingQuestionContent.innerHTML = "";

  for (let i = 0; i < options.question.choices.length; i++) {
    var item = document.createElement("div");
    item.className = "flex flex-col items-center justify-center gap-3 h-1/4 relative";
    item.style.width = "calc(25% - 1rem)";

    var itemImage = document.createElement("img");
    itemImage.className = "w-full h-full object-cover rounded-lg";
    itemImage.setAttribute("src", options.question.choices[i].imageLink || "http://placehold.it/180");

    item.appendChild(itemImage);

    newRankingQuestionContent.appendChild(item);
  }
}
