import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SentimentAnalysis, SentimentColors } from '../../enums/sentiment-analysis';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { CommentsTopicsDto } from '../../interfaces/ai-models';

@Component({
  selector: 'comment-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class CommentsChartComponent extends BaseComponent implements OnInit {
  @Input('data') set chartData(data: CommentsTopicsDto[]) {
    if (data?.length)
      this.reloadBubble(data);
  }
  @Input() sentiments;
  @Input() language = 'EN';
  @Input() module;
  @Input() bubble: CommentsTopicsDto;
  @Output() bubbleChange = new EventEmitter();
  groupedData = [];
  private sizeBreakPercentage: number;
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  onBubbleClick(e) {
    this.bubbleChange.emit(e.category);
  }


  reloadBubble(data: any[]) {
    this.groupedData = [];
    let groupedData = [];
    let maxSize = 0, minSize = 100;
    var factor = data.map((x: CommentsTopicsDto) => x.totalMentionPercentage).sort((x, y) => x - y)[0]

    factor = 20 / factor;

    for (let i = 0; i < data.length; i++) {
      let topic: CommentsTopicsDto = data[i];
      let name: string, position: number;
      if ((topic.positiveMentionCount * 1 + topic.negativeMentionCount * -1) < 0) {
        name = SentimentAnalysis.negative;
        position = 1;
      }
      else if ((topic.positiveMentionCount * 1 + topic.negativeMentionCount * -1) > 0) {
        name = SentimentAnalysis.positive;
        position = 3;
      }
      else {
        name = SentimentAnalysis.neutral;
        position = 2;
      }
      if (maxSize < topic.totalMentionPercentage)
        maxSize = topic.totalMentionPercentage;
      if (minSize > topic.totalMentionPercentage)
        minSize = topic.totalMentionPercentage;

      groupedData.push({ name: name + i, group: name, color: this.getColor(name, topic), data: [{ x: 0, y: 0, size: topic.totalMentionPercentage, category: topic, topic: this.ExtractPipe.transform(topic.name, this.language) }] })
    }

    let sizeRange = maxSize - minSize;
    if (groupedData?.length > 5)
      this.sizeBreakPercentage = Math.ceil((sizeRange * 0.08) + minSize);
    this.groupedData = groupedData;
    let i = 0;
    this.groupedData = [...groupedData].sort((a, b) => a.data[0].size - b.data[0].size);
    for (let sentiment of [SentimentAnalysis.negative, SentimentAnalysis.neutral, SentimentAnalysis.positive]) {
      let bubbles = this.groupedData.filter(x => x.group == sentiment);
      bubbles = this.generateBubbleCoordinates(bubbles, i * 33);
      i++;
    }
    this.groupedData = JSON.parse(JSON.stringify(this.groupedData));
  }


  generateBubbleCoordinates(
    bubblesArray: any,
    startX: number,
  ) {

    for (let i = 0; i < bubblesArray.length; i++) {
      let newStartX = startX + (i * 33 / (bubblesArray.length + 2));
      const x = newStartX? newStartX : 5;
      let n = i % 5;
      let y = Math.random() * ((20 + (n * 20)) - (n * 20)) + n * 20;
      while (y > 92 || y < 8) {
        y = Math.random() * ((20 + (n * 20)) - (n * 20)) + n * 20;
      }
      bubblesArray[i].data[0].x = x;
      bubblesArray[i].data[0].y = y;

    }

    return bubblesArray;
  }



  getColor(group: string, category: CommentsTopicsDto) {
    let color: string;
    Object.keys(SentimentColors).forEach(x => {

      if (group.toLowerCase() == x.toLowerCase()) {
        if (this.bubble && category.key != this.bubble.key) {
          color = SentimentColors.neutral + '30';
        }
        else {
          color = SentimentColors[x];
          if (group != SentimentAnalysis.neutral) {
            let hexedNumber = (category.totalMentionPercentage * 255 / 100)
            if (hexedNumber < 20)
              hexedNumber = 20;
            hexedNumber += 20;

            if (hexedNumber > 255)
              hexedNumber = 255;
            color += (Math.floor(hexedNumber)).toString(16);
          }
        }
      }
    })
    return color;
  }
  getLegendColor(sentiment: SentimentAnalysis) {
    return SentimentColors[sentiment.toLowerCase() as keyof typeof SentimentColors]
  }
  public labelContent = (e: any): string => {

    let label = e.dataItem.topic.charAt(0).toUpperCase() + e.dataItem.topic.slice(1);
    let val = parseFloat(e.dataItem.size).toFixed(1);
    if (e.dataItem.size < this.sizeBreakPercentage)
      return '';
    return label + '\n' + val + '%';
  }




}
