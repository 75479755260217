import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Entity } from '../../core/data-models/Entity';
import { LanguageService } from '../../core/services/language.service';
import { EntityGroup } from '../../core/data-models/entity-group';
import { MatDialog } from '@angular/material/dialog';
import { EntityStructureDialogComponent } from '../../exit/components/entity-structure-dialog/entity-structure-dialog.component';
import { Tenant } from '../../core/data-models/tenant';

import { PlatformUser } from '../../core/data-models/platform-user';
import { PerformanceRate } from '../../core/data-models/performance-rate';
import { Notification } from '../../core/data-models/notification';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { GeneralService } from '../../core/services/general.service';
import { EntityService } from '../../core/services/entity.service';
import { LogService } from '../../core/services/log.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BandLevel } from '../../core/data-models/band-level';
import { JobGrade } from '../../core/data-models/job-grade';
import { PlatformUserService } from '../../core/services/platform-user.service';
import { AuthenticationService } from '../../core/services/authentication.service';
declare var $: any;
import { ResponseDialogService } from '../../core/services/response-dialog.service';
import { ChildrenAnimation, inOutAnimation, slidesAniamtion } from '../../shared/animations/allAnimations'
import { Leaver } from '../../core/data-models/leaver';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { EmailNotificationService } from '../../core/services/email-notification.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FileUpload } from '../../core/data-models/file-upload';
import { finalize, take } from 'rxjs/operators';
import { UnitBalanceService } from '../../core/services/unit-balance.service';
import { LoaderService } from '../../core/services/loader.service';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { GlobalsService } from '../../core/services/globals.service';
import { CrashReportingService } from '../../core/services/crash-reporting.service';
import { LocalService } from '../../core/services/local.service';
import { IntegrationService } from '../../core/services/integration.service';
import { BehaviorSubject, pipe, Subscription } from 'rxjs';
import { TenantService } from '../../core/services/tenant.service';
import { EssentialObject, EssentialObjectService } from '../../core/services/essential-object.service';
import { NotificationsService } from '../../core/services/notifications.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { ModuleName } from 'src/app/shared/enums/ModuleName';

import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { ZenSnackbarService, ZenSnackBarTypes } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { relativeTimeRounding } from 'moment';
import { ThomasService } from 'src/app/psychometric/services/thomas.service';
import { CompanyPermission, products, productsList } from 'src/app/core/data-models/company-permission';
import { ClientesService } from 'src/app/psychometric/services/clientes.service';
import { Router } from '@angular/router';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { EngagementMetric } from 'src/app/core/data-models/engagement-metric';
import { ThomasCompany } from 'src/app/psychometric/models/clients/new-thomas-client';
import { NewEntityColumn } from 'src/app/psychometric/models/clients/new-entity-column';

interface MenuButton {
  index?: number
  icon?: string,
  name: string
}

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [
    inOutAnimation,
    slidesAniamtion,
    ChildrenAnimation
  ],
})
export class SettingsComponent extends ModalComponent implements OnInit {

  excelErrors = [];

  // frontEnd
  ModuleNames = ModuleName
  private subscriptions = new Subscription();
  menu: MenuButton[] = [{
    index: 0,
    icon: 'fa-building',
    name: 'txt_companies'
  },
  {
    index: 1,
    icon: 'fa-users',
    name: 'txt_users'
  }, {
    index: 2,
    icon: 'fa-sitemap',
    name: 'txt_company_structure'
  },
  {
    index: 3,
    icon: 'fa-tachometer',
    name: 'txt_performance_rate'
  },
  {
    index: 4,
    icon: 'fa-signal',
    name: 'txt_bandlevel'
  },
  {
    index: 5,
    icon: 'fa-id-card',
    name: 'txt_job_grade'
  },
  {
    index: 6,
    icon: 'fa-plus',
    name: 'txtExtraCriteria'
  },
  {
    index: 7,
    icon: 'fa-plug',
    name: 'txtIntegration'
  },
  {
    index: 8,
    icon: 'fa-bell',
    name: 'txt_notification_settings'
  },

  ];
  optionalMatrixList: EngagementMetric[] = [];
  criteriaModal: boolean = false;
  notifications: Notification[] = [];
  essentialObject: EssentialObject;
  allEntities;
  hide = true;
  surveys;
  integrationModal: boolean = false;
  // Company Validations
  vCompanyName: boolean = false;
  vCompanyIndustry: boolean = false;
  vCompanySize: boolean = false;
  vCompanyHeadcount: boolean = false;
  vCompanyBranches: boolean = false;
  vSupportedLanguages: boolean = false;
  vCountryCode: boolean = false;
  vContactNumber: boolean = false;
  vWebsite: boolean = false;
  vLogo: boolean = false;
  @Output() displayLoader = new EventEmitter();
  @Output() hideLoader = new EventEmitter();

  products = [];

  vECompanyName: boolean = false;
  vECompanyIndustry: boolean = false;
  vECompanySize: boolean = false;
  vECompanyHeadcount: boolean = false;
  vECompanyBranches: boolean = false;
  vESupportedLanguages: boolean = false;
  vECountryCode: boolean = false;
  vEContactNumber: boolean = false;
  // ---------------------------------------------



  // Users Validations
  vUserFirstName: boolean = false;
  vUserLastName: boolean = false;
  vUserEmail: boolean = false;
  vUserPassword: boolean = false;
  vUserCompanies: boolean = false;

  vEUserFirstName: boolean = false;
  vEUserLastName: boolean = false;
  vEUserEmail: boolean = false;
  vEUserPassword: boolean = false;
  vEUserCompanies: boolean = false;
  // ------------------


  // Performance Validations
  vPerformanceValue: boolean = false;
  vPerformanceDescription: boolean = false;
  vPerformanceIcon: boolean = false;

  vEPerformanceValue: boolean = false;
  vEPerformanceDescription: boolean = false;
  vEPerformanceIcon: boolean = false;
  // ------------------



  // Band Level
  vBandLevelName: boolean = false;
  vEBandLevelName: boolean = false;


  // Band Level
  vJobGradeName: boolean = false;
  vEJobGradeName: boolean = false;
  vJobGradeLevel: boolean = false;
  vEJobGradeLevel: boolean = false;


  // Operational Fields
  selectedEntity: Entity;
  newPerformanceRate: PerformanceRate;
  editPerformanceRate: PerformanceRate;
  performanceAdd: boolean = false;
  performanceEditIndex: number = -1;
  performanceColors = this.generalService.performanceIconColors;
  industriesList: any[] = [];
  companySizesList: any[] = [];
  countriesList: any[] = [];
  faStar = faStar;

  newBandLevel: BandLevel;
  editBandLevel: BandLevel;

  bandLevelAdd: boolean = false;
  bandLevelEditIndex: number = -1;

  newJobGrade: JobGrade;
  editJobGrade: JobGrade;

  jobGradeAdd: boolean = false;
  jobGradeEditIndex: number = -1;
  entityAdd: boolean = false;

  newCompany: Entity;
  editCompany: Entity;

  companyAdd: boolean = false;
  editMode: boolean = false;

  newUser: PlatformUser;
  editUserCopy: PlatformUser;

  userAdd: boolean = false;
  userEditIndex: number = -1;

  excelIntegration: boolean = false;

  // Language Related Fields
  componentTextList: any[] = [];

  thomasClientID: string;
  thomasClientSecret: string;

  isSuperUser: boolean = false;
  // btnAddNewCompany: string;
  industries: any[] = [
    { key: 'auto', language: 'EN', name: 'Automotive' },
    { key: 'ret', language: 'EN', name: 'Retail - F&B' },
    { key: 'retailFashion', language: 'EN', name: 'Retail - Fashion' },
  ];
  companySizes: any[] = [
    { key: '<50', language: 'EN', name: 'Less than 50' },
    { key: '>50<100', language: 'EN', name: '50 - 100' },
    { key: '>100', language: 'EN', name: '101 - 500' },
    { key: '>100', language: 'EN', name: '501 - 1000' },
  ];
  countries: any[] = [
    { key: 'KWT', language: 'EN', name: 'Kuwait', countryCode: '+965' },
    { key: 'JOR', language: 'EN', name: 'Jordan', countryCode: '+962' },
    { key: 'KSA', language: 'EN', name: 'Saudi Arabia', countryCode: '+966' },
    { key: 'AFG', language: 'EN', name: 'Afghanistan', countryCode: '+93' }
  ];

  leavers: Leaver[] = [];

  // Inputs
  @Input('tenant') tenant: Tenant;

  @Input('essentialsObject') set essentialProperties(essentials) {
    if (essentials?.leavers) this.leavers = essentials.leavers;
  }

  entities: Entity[] = [];
  companyEntities: Entity[] = [];
  user: PlatformUser;



  companies: any[];
  users;
  usersOptions: TableOptions;
  CompaniesOptions: TableOptions;
  entitiesOptions: TableOptions;
  performanceOptions: TableOptions;
  bandOptions: TableOptions;
  jobOptions: TableOptions;
  twoColumns = [
    {
      field: 'first',
      title: '',
    },
    {
      field: 'second',
      title: '',
    },
  ];
  threeColumns = [
    {
      field: 'first',
      title: '',
    },
    {
      field: 'second',
      title: '',
    }, {
      field: 'third',
      title: '',
    },
  ];




  usersSource: MatTableDataSource<any>;
  @ViewChild('userPaginator', { static: false }) set userPaginator(value: MatPaginator) {
    if (this.usersSource) {
      this.usersSource.paginator = value;
    }
  }


  supportedLanguages: any[] = [];

  entityGroupSource: MatTableDataSource<any>;
  performanceSource: MatTableDataSource<any>;
  bandSource: MatTableDataSource<any>;
  gradeSource: MatTableDataSource<any>;
  performanceColumns: string[] = [
    'rate',
    'description',
    'icon',
    'action'
  ];


  txtHidepassword: string = '';

  newCompanyPhone;
  selectedTab: number = 0;
  language: string = 'EN';
  hugeCount;
  lgCount;
  mdCount;
  smCount;
  colors;
  constructor(private languageService: LanguageService, public entityStructureDialog: MatDialog, private unitBalanceService: UnitBalanceService,
    private generalService: GeneralService, private entityService: EntityService,
    private logService: LogService, private db: AngularFireDatabase, private platformUserService: PlatformUserService,
    private zenSnackBarService: ZenSnackbarService, private _globals: GlobalsService,
    private authenticationService: AuthenticationService, private responseDialogService: ResponseDialogService, public matConfirmDialog: MatDialog,
    private emailNotificationService: EmailNotificationService, private storage: AngularFireStorage,
    private matDDialog: MatDialog, private errorHandlerService: ErrorHandlerService, private crashReportingService: CrashReportingService,
    private entitiesService: EntityService,
    private tenantService: TenantService,
    private notificationsService: NotificationsService,
    private essentialDataService: EssentialObjectService,
    private translationPipe: TranslatePipe,
    private localService: LocalService, private integrationService: IntegrationService, private thomasService: ThomasService, private clientsSservice: ClientesService, private router: Router) {
    super();
    this.hugeCount = _globals.hugeCount;
    this.lgCount = _globals.lgCount;
    this.mdCount = _globals.mdCount;
    this.smCount = _globals.smCount;
    this.colors = _globals.basicColors;
    //permission tree
    this.entitiesNavigation$.subscribe((data: Entity[]) => {
      //data = data.filter(e => e.isCompany == true);
      this.entitiesNavigation = data;
      this.permissionDataSource.data = data.filter(x => x.parentKey == "");
      this.permissionTreeControl.dataNodes = data.filter(x => x.parentKey == "");
      this.permissionTreeControl.collapseAll();

    })
    //end
  }



  downloadIntegrationExcel() {
    //   this.downloadFile([{
    //   "Employee ID": '10001',
    //   "Name": 'Jane Doe',
    //   "Email": 'example@example.com',
    //   "Phone Number": '96562345678',
    //   "Gender": 'female',
    //   "Nationality": 'Kuwait',
    //   "Date of Birth (DD-MM-YYYY)": '22-01-1977',
    //   "Joining Date (DD-MM-YYYY)": '22-01-2002',
    //  }], 'Engagement Respondent Template');


    let request = {
      tenantKey: this.tenant.key, companyKey: this.selectedEntity.key, allEntites: this.allEntities, Company: this.selectedEntity
    }
    this.integrationService.downloadIntegrationExcelFile(request).subscribe((response: any) => {
      if (response.response == 'SUCCESS') {
        this.downloadFile(response?.Data, "Respondents")
      }
    });
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        line += array[i][head] + ',';
      }
      str += line + '\r\n';
    }
    return str;
  }
  downloadFile(data, filename = 'data') {

    // let headerNames = ['Employee ID', 'Name', 'Email', "Phone Number", 'Gender', 'Nationality', 'Date of Birth (DD-MM-YYYY)'];
    // if(this.selectedEntity?.PerformanceRates?.length > 0){
    //   headerNames.push('Last Performance');
    // }
    // if(this.selectedEntity?.BandLevels?.length > 0){
    //   headerNames.push('Band/Job Level');
    // }
    // if(this.selectedEntity?.JobGrades?.length > 0){
    //   headerNames.push('Job Grade');
    // }

    // if(this.selectedEntity?.EntityGroups?.length > 0){
    //   for(let i = 0; i < this.selectedEntity?.EntityGroups?.length; i++){
    //     let eg = this.selectedEntity?.EntityGroups[i];
    //     headerNames.push(this.extractNameLanguage(eg.name));
    //   }
    // }


    let dwldLink = document.createElement("a");
    let url = `data:application/pdf;base64,${data}`;
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".xlsx");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  uploadIntegrationFile(event) {
    let files = event.srcElement.files;
    if (this.isValidFile(files[0])) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {

        this.integrate(reader.result);



      };
      let input = event.target;

    } else {
      alert("Please import valid .xlsx file.");

    }
  }

  async integrate(fileBase: any) {
    this.excelErrors = [];
    let request = {
      FileBase: fileBase,
      TenantKey: this.tenant.key,
      CompanyKey: this.selectedEntity.key,
      Company: this.selectedEntity
    }
    let response: any = await this.integrationService.integrate(request).toPromise();
debugger;
    if (response.Errors.length > 0) {
      this.excelErrors = response.Errors;
    }

    if (response.response == 'ERROR') {
      this.zenSnackBarService.error(response.Data, 5000);
    } else if (response.response == 'SUCCESS') {
      this.zenSnackBarService.success("Successfully Integrated", 5000);
      this.excelIntegration = false;
      this.ngOnInit();

    }
  }


  isValidFile(file: any) {
    return file.name.endsWith(".xlsx");
  }
  logData(e) {
    console.log(e);
  }

  async updateEngagementMetrics(engagementMetric, removeIndex?: number) {
    if (removeIndex || removeIndex == 0) {
      let indexOf = this.selectedEntity.metrics.findIndex(mt => mt.key == engagementMetric.key);
      this.selectedEntity.metrics.splice(indexOf, 1);
    } else {
      if (!this.optionalMatrixList?.find((mt) => mt.key == engagementMetric.key)) {
        if (!this.selectedEntity.metrics) this.selectedEntity.metrics = [];
        this.selectedEntity.metrics?.push(engagementMetric);
        this.optionalMatrixList = this.selectedEntity.metrics;
        await this.entitiesService.updateCompany({
          TenantKey: this.tenant.key,
          CompanyKey: this.selectedEntity.key,
          GroupKey: engagementMetric.key
        }).toPromise();
        if (this.tenant.thomasIntegrated) {
          let newEntityColumn = new NewEntityColumn();
          newEntityColumn.companyKey = this.selectedEntity.key;
          newEntityColumn.tenantKey = this.tenant.key;
          newEntityColumn.modifiedby = this.user.firstName + ' ' + this.user.lastName;
          newEntityColumn.entityKey = engagementMetric.key;
          let response: any = await this.clientsSservice.newThomasEntityColumn(newEntityColumn).toPromise();
        }
      }
    }
    this.criteriaModal = false;
    this.updateEntity('UPDATE');
  }
  selectFile(event, entity): void {
    console.log(event.target.files)
    this.selectedFiles = event.target.files;
    this.upload(entity);
  }
  imageLink: string;
  uploading: boolean = false;
  tab;
  selectedFiles: FileList;
  currentFileUpload: FileUpload;
  private basePath = '/uploads';


  activateUser(user) {
    this.newUser = JSON.parse(JSON.stringify(user));
    // this.newUser.companyReferences = [...this.newUser.EntityReferences];
    this.newUser.isActive = true;
    this.updatePlatformUser(this.newUser)
    this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtsuccess", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Success });

    // this.newUser.password = Math.random().toString(36).slice(-8);
    // this.createNewUser(true);
  }


  upload(entity: Entity): void {
    console.log(this.selectedFiles[0].type);
    if (["image/jpeg", "image/jpg", "image/png", "image/gif"].indexOf(this.selectedFiles[0].type) < 0) {
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtImageFormat", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

      return;
    }

    try {
      const file = this.selectedFiles.item(0);
      this.selectedFiles = undefined;

      this.currentFileUpload = new FileUpload(file);
      let fileName = (file.name + ' - ' + Number((new Date().getTime() / 100).toFixed(0)));
      const filePath = `${this.basePath}/${fileName}`;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, this.currentFileUpload.file);
      this.uploading = true;
      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          this.subscriptions.add(storageRef.getDownloadURL().subscribe(downloadURL => {

            entity.logoURL = downloadURL;
            this.uploading = false;
          }));
        })
      ).subscribe();
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1001, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  async fetchPlatformUser() {
    try {


      this.logService.submitLog(
        0,
        'HomeComponent.fetchPlatformUser()',
        'Task Assigned',
        {}
      );
      this.subscriptions.add(this.platformUserService
        .fetchPlatformUser(this.localService.getJsonValue('UserKey'))
        .subscribe(async (user: PlatformUser) => {
          this.logService.submitLog(
            0,
            'HomeComponent.fetchPlatformUser()',
            'Task Completed',
            { user }
          );
          this.user = user;
          this.defineTab();

          // this.essentialDataService.essentialObject.user = user;
          let permissionResponse: any = await this.platformUserService.fetchUserPermissions(this.user).toPromise();
          if (permissionResponse.success == true) {

            if (permissionResponse?.Data != 'Super Admin') {
              let permissions: CompanyPermission[] = permissionResponse?.Data;
              this.user.CompanyPermissions = permissions;
              this.user.EntityReferences = [];
              for (let i = 0; i < permissions.length; i++) {
                let permission = permissions[i];
                this.user.EntityReferences.push(permission.key);
              }
            } else if (permissionResponse?.Data == 'Super Admin') {
              this.user.role = permissionResponse?.Data;
            }

          }
          this.essentialObject.user = user;

          if (!this.tenant.key) {

            this.tenant.key = this.user.tenantKey;
            this.fetchTenant();
            this.fetchIntegrations();
            // this.platformUserService.fetchRelease();
          }
        }));

    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(502, ex.toString(), 'NOT YET INTIATED', 'NOT YET INTIATED', 'NOT YET INTIATED',
        'NOT YET INTIATED', 'NOT YET INITIATED');
    }
  }
  ngOnInit(): void {
    // this.openModal('performanceAdd')

    this.initUserProductPermission()
    this.authenticationService.getTimoutAndEvaluate();
    try {
      this.crashReportingService.submitLogCode(20);
      this.supportedLanguages = this.languageService.supportedLanguages;//.filter(s => (s.key == 'EN' || s.key == 'AR'));
      this.user = new PlatformUser();
      this.tenant = new Tenant();

      let localLanguage = this.localService.getJsonValue('language');
      if (!localLanguage) {
        this.language = 'EN';
        this.localService.setJsonValue('language', this.language);

      } else {
        (this.language != 'AR') && (this.language != 'EN') ? this.language = 'EN' : this.language = localLanguage;
        // this.language = localLanguage;
        // this.language = 'FR';
      }
      this.languageService.language = this.language;
      // Fetch Tenant Info
      this.essentialObject.language = this.language;
      this.setUpLanguagePreference();

      this.subscriptions.add(this.unitBalanceService.fetchSurveys().subscribe((surveys: any) => {
        this.surveys = surveys;
        this.surveys = JSON.parse(JSON.stringify(this.surveys));
        this.essentialObject.surveys = surveys;
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(501, ex.toString(), 'NOT YET INTIATED', 'NOT YET INTIATED', 'NOT YET INTIATED',
        'NOT YET INTIATED', 'NOT YET INITIATED');
    }

    this.crashReportingService.submitLogCode(6);
    this.subscriptions.add(this.essentialDataService.essentialObject.subscribe(async object => {
      this.essentialObject = object;
      this.selectedEntity = object.selectedEntity;
      if (this.selectedEntity?.key) {
        this.tenant.key = null;
        this.fetchPlatformUser();
        this.setUpLanguagePreference();
        console.log('SELECTED ENTITY CHANGED');
      }
    }));
    // let alertPermission = this.localService.getJsonValue('PermissionAlert');
    // if(alertPermission != null && alertPermission == 'TRUE'){
    //   this.responseDialogService.confirmAllocation(this.translationPipe.transform("txtNoPermission", this.language, this.ModuleNames.Common), "Permissions",this.translationPipe.transform("txt_okay_action", this.language, this.ModuleNames.Common), '');
    //   this.localService.removeItem('PermissionAlert');
    // }

    console.log(this.selectedEntity);
    //this.newUserInitiate();
  }
  // handleClickAll(user, permission) {
  //   let tPermission: CompanyPermission = user.CompanyPermissions.find((perm: CompanyPermission) => perm.key == permission.key);
  //   if (tPermission?.products?.length != this.products?.length) {
  //     tPermission.products = JSON.parse(JSON.stringify(this.products));
  //   }
  //   else tPermission.products = [];
  // }

  // permissionHandle(product, permission, entityKey?: string) {
  //   if (!permission?.products)
  //     permission.products = [];
  //   if (!permission?.products?.find(prod => product == prod))
  //     permission.products?.push(product);
  //   else permission?.products.splice(permission?.products.indexOf(product), 1);

  //   console.log(permission);
  // }

  // permissionCheck(product, products) {
  //   if (products?.length) {
  //     // console.log(products);
  //     return products.find(prod => product == prod)
  //   }
  // }


  getCompanyName(key) {
    return this.companies.find(com => com.key == key).name;
  }

  newCompanyInitiate() {
    this.newCompany = new Entity();
    this.newCompany.key = this.db.createPushId();
    this.newCompany.isCompany = true;
    this.newCompany.tenantKey = this.tenant.key;
    this.newCompany.companyEntityKey = this.newCompany.key;
    let entityGroup = new EntityGroup();
    entityGroup.groupKey = this.db.createPushId();
    entityGroup.isCompanyGroup = true;
    entityGroup.name = this.languageService.companyLanguages;
    this.newCompany.EntityGroups.push(entityGroup);
    this.newCompany.entityGroupKey = entityGroup.groupKey;
    this.openModal('companyAdd');
  }
  newUserInitiate() {
    this.newUser = new PlatformUser;
    this.newUser.tenantKey = this.tenant.key;
    this.newUser.isActive = true;
    this.newUser.EntityReferences = [];
    console.log(this.newUser);
    this.companies?.forEach((com: Entity, index) => {
      this.newUser.CompanyPermissions.push({ key: com.key, products: [] });
    });
    console.log(this.newUser);

    this.openModal('userAdd');
  }
  newPerformanceInitiate() {
    this.performanceColors = this.generalService.performanceIconColors;
    this.newPerformanceRate = new PerformanceRate;
    this.newPerformanceRate.key = this.db.createPushId();
    this.openModal('performanceAdd');
  }
  newBandInitiate() {
    this.newBandLevel = new BandLevel;
    this.newBandLevel.key = this.db.createPushId();
    this.openModal('bandLevelAdd');
  }
  newJobGradeInitiate() {
    this.newJobGrade = new JobGrade;
    this.newJobGrade.key = this.db.createPushId();
    this.openModal('jobGradeAdd');
  }
  tabChange(tab) {
    this.tab = tab;
    sessionStorage.setItem('tab', this.tab);
  }
  defineTab() {
    let storedTab = sessionStorage.getItem('tab');
    if (storedTab)
      (storedTab == '0' || storedTab == '1') && this.user.role != 'Super Admin' ? this.tab = 2 : this.tab = storedTab;
    else if (!storedTab) {
      if (this.user.role == 'Super Admin') {
        this.tab = 0;
      } else {
        this.tab = 2;
      }
      sessionStorage.setItem('tab', this.tab);
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  entityAccountBalances: any;
  fetchTenantAccountBalances() {
    try {
      let request = { tenantKey: this.tenant.key };
      this.subscriptions.add(this.unitBalanceService.fetchTenantCompanyBalance(request).subscribe((response: any) => {
        if (response.response == 'SUCCESS') {
          this.entityAccountBalances = response.data;

          this.entityAccountBalances.entities = this.entityAccountBalances.entities.filter(e => {
            if (this.companies.find(c => c.key == e.key))
              return true;

            return false;
          });

        }
      }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1002, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }
  originalCountriesList: any[] = [];
  setUpLanguagePreference() {
    // this.componentTextList = this.languageService.componentText.filter(country => country.language == this.language);
    this.industries = this.generalService.industries;
    this.companySizes = this.generalService.companySizes;

    this.industriesList = this.industries.filter(industry => industry.language == this.language);
    this.companySizesList = this.companySizes.filter(compsize => compsize.language == this.language);
    this.countriesList = this.generalService.countries_new;
    this.originalCountriesList = this.generalService.countryCodes;
    this.setUpTextFields();
  }

  setUpTextFields() {


    //------------------------------ Content Setup -----------------------------------------------------------


    this.companyColumns = [
      {
        field: 'first',
        title: this.translationPipe.transform("txt_company_name", this.language, this.ModuleNames.Common),
      },
      {
        field: 'second',
        title: this.translationPipe.transform("txt_company_size", this.language, this.ModuleNames.Common),
      },
      {
        field: 'third',
        title: this.translationPipe.transform("txt_branches", this.language, this.ModuleNames.Common),
      },
      {
        field: 'options',
        title: '',
      },
    ];
    this.userColumns = [
      {
        field: 'first',
        title: this.translationPipe.transform("txt_full_name", this.language, this.ModuleNames.Common),
      },
      {
        field: 'second',
        title: this.translationPipe.transform("txt_companies", this.language, this.ModuleNames.Common),
      },
      {
        field: 'third',
        title: this.translationPipe.transform("txt_status", this.language, this.ModuleNames.Common),
      },
      {
        field: 'options',
        title: '',
      },
    ]


  }



  fetchTenant() {
    try {


      this.tenant.key = this.languageService.getTenant(this.tenant.key);

      this.logService.submitLog(
        0,
        'HomeComponent.fetchTenant()',
        'Task Assigned',
        {}
      );
      this.subscriptions.add(this.tenantService
        .fetchTenant(this.tenant.key)
        .subscribe((tenant: Tenant) => {
          if (tenant) {
            this.tenant = tenant;
            // this.essentialDataService.essentialObject.tenant = tenant;
            this.essentialObject.tenant = tenant;

            if (this.user.role == 'Super Admin') {
              this.fetchAllTenantUsers();
            }
            this.evaluateTenant();
            this.logService.submitLog(
              0,
              'HomeComponent.fetchTenant()',
              'Task Completed',
              { tenant }
            );
          } else {
            this.logService.submitLog(
              0,
              'HomeComponent.fetchTenant()',
              'Task Failed',
              {}
            );
          }
        }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(503, ex.toString(), 'NOT YET INTIATED', 'NOT YET INTIATED', 'NOT YET INTIATED',
        'NOT YET INTIATED', this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  fetchAllTenantUsers() {
    try {


      this.subscriptions.add(this.platformUserService
        .fetchAllTenantUsers(this.tenant.key)
        .subscribe((users: PlatformUser[]) => {
          this.users = users;

          this.usersOptions = {
            data: this.users,
            columns: this.userColumns,
            searchText: this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Common),
            colors: this._globals.basicColors,
            whiteBackground: true,
            sticky: true,
          }

        }));
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(504, ex.toString(), this.tenant.key, this.tenant.name, 'NOT YET INTIATED',
        'NOT YET INTIATED', this.user.firstName + ' ' + this.user?.lastName + ' (' + this.user.email + ')');
    }
  }

  evaluateTenant() {

    this.fetchAllEntities();

  }

  companyColumns: any[];
  userColumns: any[];

  fetchAllEntities() {
    try {

      this.logService.submitLog(
        0,
        'HomeComponent.fetchAllEntities()',
        'Task Assigned',
        {}
      );
      this.subscriptions.add(this.entitiesService
        .fetchEntities(this.tenant.key)
        .subscribe((entities: Entity[]) => {
          if (entities) {
            this.logService.submitLog(
              0,
              'HomeComponent.fetchAllEntities()',
              'Task Completed',
              { entities }
            );
            this.entitiesNavigation$.next(entities);
            this.allEntities = entities;
            this.essentialObject.allEntities = entities;

            if (this.essentialObject.user.role != 'Super Admin') {
              this.allEntities = this.allEntities.filter((e) => this.essentialObject.user.CompanyPermissions.find((p) => p.key == e.key));
              if (this.user?.CompanyPermissions?.length > 0) {
                this.user.ModulePermissions = JSON.parse(JSON.stringify(this.user.CompanyPermissions));
                for (let i = 0; i < this.user.ModulePermissions.length; i++) {
                  let permission = this.user.ModulePermissions[i];
                  let entity = entities.find(e => e.key == permission.key);
                  if (entity?.isCompany) {
                    let subEntities = entities.filter(e => e.parentKey == entity.key);
                    let subEntityPermittedList = subEntities.filter(e => this.user.ModulePermissions.find(p => p.key == e.key));
                    let subEntityPermissions = this.user.ModulePermissions.filter(p => subEntityPermittedList.find(ep => ep.key == p.key));
                    if (subEntities.length == subEntityPermittedList.length) {
                      permission.products = [];
                      for (let x = 0; x < productsList.length; x++) {
                        let product = productsList[x];
                        let productPermissionEntities = subEntityPermissions.filter(ep => ep.products.find(p => p == product.value));
                        if (productPermissionEntities.length == subEntityPermittedList.length) {
                          permission.products.push(product.value);
                        }
                      }
                    } else {
                      permission.products = [];
                    }
                  }
                }
              }
            }

            if (this.user.role == 'Super Admin') {
              this.entities = entities;
            } else {
              for (let i = 0; i < entities.length; i++) {
                let entity = entities[i];
                if (this.essentialObject.user.CompanyPermissions.find(e => e.key == entity.key))
                  this.entities.push(entity);
                // if (this.user.EntityReferences.find((eR) => eR == entity.key)) {
                //   this.entities.push(entity);
                // } else {
                //   if (!entity.isCompany) this.entities.push(entity);
                // }
              }
            }
            if (!this.selectedEntity) {
              let localEntity: any = JSON.parse(
                this.localService.getJsonValue('selectedEntity')
              );
              if (localEntity) {
                if (localEntity.userKey != this.user.key) {
                  localEntity = null;
                }
              }
              if (!localEntity) {
                if (this.entities.length > 0) {
                  let companyEntity = this.entities.find(
                    (e) => e.isCompany == true
                  );
                  this.selectedEntity = companyEntity;

                  // this.essentialDataService.essentialObject.selectedEntity = companyEntity;
                  this.essentialObject.selectedEntity = companyEntity;

                }
              } else {
                if (this.entities.length > 0) {
                  this.selectedEntity = this.entities.find(
                    (et) => et.key == localEntity.entityKey
                  );
                  if (!this.selectedEntity) {
                    if (this.entities.length > 0) {
                      let companyEntity = this.entities.find(
                        (e) => e.isCompany == true
                      );
                      this.selectedEntity = companyEntity;
                      let localStorageEntity = {
                        entityKey: this.selectedEntity.key,
                        userKey: this.user.key,
                      };




                    }
                  }
                  // this.essentialDataService.essentialObject.selectedEntity =  this.selectedEntity;
                  this.essentialObject.selectedEntity = this.selectedEntity;
                  this.essentialDataService.updateObject(this.essentialObject);
                }
              }
            } else {
              this.selectedEntity = this.entities.find(
                (e) => e.key == this.selectedEntity.key
              );
            }
            this.companyEntities = this.entitiesService.structureEntities(
              this.entities,
              this.selectedEntity.key
            );
            this.companies = this.entities.filter((e) => e.isCompany == true);


            this.tableOptions();

            // let x = new Engagement;
            // x.key = '-MMu1dibvjEgR3jOK7LT';
            // this.openEngagement(x);
            //        this.updateExistingEngagement();

            // let engagementRespondent = new EngagementRespondent();
            // engagementRespondent.key = '-556789098765';
            // engagementRespondent.email = 'mhdoabuzaid@gmail.com';
            // engagementRespondent.name = 'Momd';

            // this.updateEngagementRespondent(engagementRespondent);

            // this.updateEngagementMetrics();

            // this.fetchEngagementSurvey();

            // this.fetchEngagement();
            // this.fetchRespondents();

            // let engagementRefList: EngagementReference[] = [];
            // let engagementRef = new EngagementReference();
            // engagementRef.key = '1234';
            // engagementRef.tenantKey = '-MFj5Li4SCWLpNsBCqDd';
            // engagementRef.companyKey = '-MGIysuiSpsB_8Xk52_7';
            // engagementRef.respondentKey = '1234567890';
            // engagementRef.engagementKey = '-MNZ8NryxfAcObxi4rtz';

            // this.engagementService
            //   .updateEngagementReference(engagementRef)
            //   .then((_) => {})
            //   .catch((error) => {});

            if (!this.selectedEntity.EntityGroups)
              this.selectedEntity.EntityGroups = [];

            let hasPermission = this.generalService.hasPermission(this.selectedEntity.key, products.Settings, this.user.CompanyPermissions, this.user.role == 'Super Admin');
            if (!hasPermission) {
              let route = this.generalService.resolveRoute(this.selectedEntity.key, this.user.CompanyPermissions);
              this.router.navigateByUrl(route);
            }

            this.fetchNotifications();
            this.initiateMetrics();
          } else {
            this.logService.submitLog(
              0,
              'HomeComponent.fetchAllEntities()',
              'Task Failed',
              {}
            );
          }
        }));


    } catch (ex) {

      this.errorHandlerService.submitErrorMessage(505, ex.toString(), this.tenant.key, this.tenant.name, 'NOT YET INTIATED',
        'NOT YET INTIATED', this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }
  initiateMetrics() {
    this.optionalMatrixList = this.selectedEntity.metrics;
  }
  tableOptions() {
    this.CompaniesOptions = {
      data: this.companies,
      columns: this.companyColumns,
      searchText: this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Common),
      colors: this._globals.basicColors,
      whiteBackground: true,
      sticky: true,
      request: {
        pageNumber: 8
      }
    }

    this.entitiesOptions = {
      data: this.selectedEntity.EntityGroups,
      columns: [{ field: 'first', title: this.translationPipe.transform("txt_structure_name", this.language, this.ModuleNames.Common) }, { field: 'options', title: '' }],
      searchText: this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Common),
      colors: this._globals.basicColors,
      whiteBackground: true,
      sticky: true,
    }
    this.performanceOptions = {
      data: this.selectedEntity.PerformanceRates,
      columns: [{ field: 'first', title: this.translationPipe.transform("txt_performance_rate_value", this.language, this.ModuleNames.Common) }, { field: 'second', title: this.translationPipe.transform("txt_performance_rate_description", this.language, this.ModuleNames.Common) }, { field: 'third', title: this.translationPipe.transform("txt_performance_icon", this.language, this.ModuleNames.Common), }, { field: 'options', title: '' }],
      searchText: this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Common),
      colors: this._globals.basicColors,
      whiteBackground: true,
      sticky: true,
    };
    this.bandOptions = {
      data: this.selectedEntity.BandLevels,
      columns: [{ field: 'first', title: this.translationPipe.transform("txt_band_level_name", this.language, this.ModuleNames.Common) }, { field: 'options', title: '' }],
      searchText: this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Common),
      colors: this._globals.basicColors,
      whiteBackground: true,
      selectionKey: null,
      sticky: true,
    };
    this.jobOptions = {
      data: this.selectedEntity.JobGrades,
      columns: [{ field: 'first', title: this.translationPipe.transform("txt_job_grade", this.language, this.ModuleNames.Common) }, { field: 'second', title: this.translationPipe.transform("txt_bandlevel", this.language, this.ModuleNames.Common) }, { field: 'options', title: '' }],
      searchText: this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Common),
      colors: this._globals.basicColors,
      selectionKey: null,
      whiteBackground: true,
      sticky: true,
    };
  }
  fetchNotifications() {
    try {

      this.subscriptions.add(this.notificationsService
        .fetchUserNotifications(this.user)
        .subscribe((notificatins: Notification[]) => {
          this.notifications = notificatins;
        }));
    } catch (ex) {

      this.errorHandlerService.submitErrorMessage(506, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  startingAuthorization: boolean = false;
  connectionEstablished: boolean = false;
  connectionWorked: string = '';
  fetchIntegrations() {
    this.subscriptions.add(this.integrationService.fetchIntegrations(this.tenant.key).subscribe((res: any[]) => {
      console.log(res);
      if (res) {
        let thomasIntegration = res.find(i => i.key == 'THOMAS');
        if (thomasIntegration) {
          this.thomasClientID = thomasIntegration.thomasClientID;
          this.thomasClientSecret = thomasIntegration.thomasClientSecret;
          console.log('SWITCHED TO TRUE');

        }
      }
    }));
  }

  testThomasConnection() {
    this.startingAuthorization = true;
    this.connectionEstablished = false;
    this.connectionWorked = '';
    this.thomasService.authorize(this.thomasClientID, this.thomasClientSecret).subscribe((res) => {
      if (res) {
        if (res?.access_token) {
          this.localService.setJsonValue('thomasTimout', Date.now());
          this.localService.setJsonValue('thomas', res.access_token);
          this.connectionEstablished = true;
          this.connectionWorked = 'SUCCESS';
        }
      }
    }, (error) => {
      console.log('error');
      this.connectionEstablished = true;
      this.connectionWorked = 'ERROR';
    });
  }
  integrateThomas() {
    this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtLoader", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Info });
    let companies: ThomasCompany[] = []
    this.allEntities.forEach((entity: Entity) => {
      if (entity.isCompany) {
        let entities = [];
        if (entity.EntityGroups)
          entities = entity.EntityGroups.map((g) => { return g.groupKey });
        let metrics = [];
        if (entity.metrics)
          metrics = this.generalService.extraCriterias.map((m) => { return m.key });
        companies.push({ key: entity.key, entitiesKeys: entities, metricsKeys: metrics });
      }
    })
    this.clientsSservice.createThomasIntegrationTables(
      {
        tenantKey: this.tenant.key,
        companies: companies,
        createdBy: this.user?.firstName + ' ' + this.user?.lastName,
        clientId: this.thomasClientID,
        clientSecret: this.thomasClientSecret
      }).subscribe((response: any) => {
        //
        if (response.success) {
          this.integrationModal = false;
          this.tenant.thomasIntegrated = true;
          this.tenantService.updateTenant(this.tenant);
          this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtsuccess", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Success });
          this.ngOnInit();
        } else {
          this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtSthWrong", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
        }
      });
  }
  editPerformanceObject(i, performance: PerformanceRate) {
    this.editMode = true;
    this.openModal('performanceAdd');
    this.newPerformanceRate = performance;
  }

  cancelPerformanceEdit(i) {
    this.selectedEntity.PerformanceRates[i] = JSON.parse(JSON.stringify(this.editPerformanceRate));
    this.performanceEditIndex = -1;
  }


  editBandLevelObject(i, bandLevel: BandLevel) {
    this.editMode = true;
    this.openModal('bandLevelAdd');
    this.newBandLevel = bandLevel;
  }


  cancelBandLevelEdit(i) {
    this.selectedEntity.BandLevels[i] = JSON.parse(JSON.stringify(this.editBandLevel));
    this.bandLevelEditIndex = -1;
  }



  editJobGradeObject(i, jobGrade: JobGrade) {
    this.editMode = true;
    this.openModal('jobGradeAdd');
    this.newJobGrade = jobGrade;
  }

  cancelJobGradeEdit(i) {
    this.selectedEntity.JobGrades[i] = JSON.parse(JSON.stringify(this.editJobGrade));
    this.jobGradeEditIndex = -1;
  }

  editCompanyObject(i, company: Entity) {
    this.editMode = true;
    this.openModal('companyAdd');
    this.newCompany = company;
    console.log(company);

  }

  returnCompanySize(size) {
    return this.companySizesList?.find(key => key.key == size)?.name;
  }
  cancelCompanyEdit(i) {
    this.companies[i] = JSON.parse(JSON.stringify(this.editCompany));
  }

  superUserChanged(event) {

  }


  getEntityGroupName(entityGroup: EntityGroup) {
    var groupName = entityGroup.name.find(name => name.key == this.language)?.name
    if (!groupName)
      return entityGroup.name.find(name => name.name != "")?.name
    return groupName;
  }

  entityGroup;
  editEntityGroup(entityGroupToEdit?: EntityGroup) {

    let entityGroup = new EntityGroup;
    if (entityGroupToEdit)
      entityGroup = JSON.parse(JSON.stringify(entityGroupToEdit));
    this.entityGroup = entityGroup;
    const structureDialogRef = this.entityStructureDialog.open(EntityStructureDialogComponent,
      {
        data: {
          entityGroup: entityGroup, language: this.language,
          supportedLanguages: this.selectedEntity.ContentLanguages, selectedEntity: this.selectedEntity,
          tenant: this.tenant, entities: this.entities, companyEntities: this.companyEntities, user: this.user, leavers: this.leavers, thomasIntegrated: this.tenant.thomasIntegrated
        },
        panelClass: 'hideInnerContainer',

        disableClose: true
      });

    this.subscriptions.add(structureDialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let snackbarMessage: string;
        if (result.actionType == 'UPDATE') {
          snackbarMessage = this.translationPipe.transform("txt_update_entity_group", this.language, this.ModuleNames.Common) + " " + result.entityGroup;
        } else {
          snackbarMessage = this.translationPipe.transform("txt_add_entity_group", this.language, this.ModuleNames.Common) + " " + result.entityGroup;
          this.crashReportingService.submitLogCode(9);
        }
        this.entitiesOptions = {
          data: this.selectedEntity.EntityGroups,
          columns: [{ field: 'first', title: this.translationPipe.transform("txt_structure_name", this.language, this.ModuleNames.Common), }, { field: 'options', title: '' }],
          searchText: this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Common),
          colors: this._globals.basicColors,
          whiteBackground: true,
          sticky: true,
        }
        this.essentialObject.selectedEntity = this.selectedEntity;
        this.essentialDataService.updateObject(this.essentialObject);
        window.location.reload();
        // this.ngOnInit();
        this.zenSnackBarService.snackBar({ message: snackbarMessage, type: ZenSnackBarTypes.Success });
      }
    }));

  }

  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }

  async updateEntity(actionType?: string) {
    try {
      let snackbarMessage: string;
      if (actionType == 'UPDATE')
        snackbarMessage = this.translationPipe.transform("txt_update_entity_group", this.language, this.ModuleNames.Common);
      else if (actionType == 'CREATE')
        snackbarMessage = this.translationPipe.transform("txt_add_entity_group", this.language, this.ModuleNames.Common);
      else
        snackbarMessage = this.translationPipe.transform("txt_delete_entity_group", this.language, this.ModuleNames.Common);

      this.zenSnackBarService.snackBar({ message: snackbarMessage, type: ZenSnackBarTypes.Success });
      let response: any = await this.entityService.coreUpdateCompany(this.selectedEntity).toPromise();

      this.entityService.updateEntity(this.selectedEntity).then((_) => {
        this.logService.submitLog(4, 'SettingsComponent.updateEntity()', 'Task Completed', { selectedEntity: this.selectedEntity });
        // this.entityGroupSource = new MatTableDataSource(this.selectedEntity.EntityGroups);
        this.essentialObject.selectedEntity = this.selectedEntity;
        this.essentialDataService.updateObject(this.essentialObject);
        this.entitiesOptions = {};
        // this.ngOnInit();

      }).catch((error) => {
        this.logService.submitLog(4, 'SettingsComponent.updateEntity()', 'Task Failed', { selectedEntity: this.selectedEntity });

      });
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1003, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  performanceInvalid: boolean = false;
  validateEditPerformance(performance: PerformanceRate) {

    let isValid: boolean = true;

    if (!performance.value) {
      this.vEPerformanceValue = true;
      isValid = false;
    } else {
      this.vEPerformanceValue = false;
    }


    if (performance.description.filter(d => d.name == '').length == performance.description.length) {
      this.vEPerformanceDescription = true;
      isValid = false;
    } else {
      this.vEPerformanceDescription = false;
    }


    // if (!performance.icon) {
    //   this.vEPerformanceIcon = true;
    //   isValid = false;
    // } else {
    //   this.vEPerformanceIcon = false;
    // }

    this.performanceInvalid = !isValid
    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

    if (!this.validateCount(performance.description, this.mdCount || !this.validateCount(performance.value, this.mdCount, 'x')))
      return;



    return isValid;
  }

  updatePerformance(performance: PerformanceRate) {

    let isValid = this.validateEditPerformance(performance);
    if (isValid) {
      this.performanceEditIndex = -1;
      let updatedPerformacne = this.selectedEntity.PerformanceRates.findIndex(p => p.key == performance.key);
      this.selectedEntity.PerformanceRates[updatedPerformacne] = performance;
      this.updateEntity('UPDATE');
      this.performanceAdd = false;
    }
  }



  validateEditBandLevel(bandLevel: BandLevel) {

    let isValid: boolean = true;

    if (bandLevel.name.filter(d => d.name == '').length == bandLevel.name.length) {
      this.vEBandLevelName = true;
      isValid = false;
    } else {
      this.vEBandLevelName = false;
    }

    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

    if (!this.validateCount(bandLevel.name, this.mdCount))
      return;

    return isValid;
  }

  updateBandLevel(bandLevel: BandLevel) {
    let isValid = this.validateEditBandLevel(bandLevel);
    if (isValid) {
      let updatedBand = this.selectedEntity.BandLevels.findIndex(b => b.key == bandLevel.key);
      this.selectedEntity.BandLevels[updatedBand] = bandLevel;
      this.bandLevelAdd = false;
      this.bandLevelEditIndex = -1;
      this.updateEntity('UPDATE');
    }
  }


  validateEditJobLevel(jobGrade: JobGrade) {

    let isValid: boolean = true;

    if (jobGrade.name.filter(d => d.name == '').length == jobGrade.name.length) {
      this.vEJobGradeName = true;
      isValid = false;
    } else {
      this.vEJobGradeName = false;
    }

    // if(!jobGrade.bandLevelKey){
    //   this.vEJobGradeLevel = true;
    //   isValid = false;
    // } else {
    //   this.vEJobGradeLevel = false;
    // }
    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });


    if (!this.validateCount(jobGrade.name, this.mdCount))
      return;


    return isValid;
  }


  validateCount(value, count, ready?) {
    let validatedCount;
    if (ready == 'x') {
      validatedCount = this.countWord(value, count)
    }
    else
      validatedCount = this.countWord(this.extractNameLanguage(value), count)
    if (validatedCount > count) {
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_wrong_text_count", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
      return false;
    }
    if (validatedCount <= count) {
      return true;
    }
  }
  countWord(word, count) {
    if (word?.length <= count)
      return word.length;
    if (word?.length > count) {

      return word.length;
    }
  }


  updateJobGrade(jobgrade) {
    let isValid = this.validateEditJobLevel(jobgrade);
    if (isValid) {
      let updatedGrade = this.selectedEntity.JobGrades.findIndex(b => b.key == jobgrade.key);
      this.selectedEntity.JobGrades[updatedGrade] = jobgrade;
      this.jobGradeAdd = false;

      this.jobGradeEditIndex = -1;
      this.updateEntity('UPDATE');
    }
  }



  validatePerformance() {

    let isValid: boolean = true;

    if (!this.newPerformanceRate.value) {
      this.vPerformanceValue = true;
      isValid = false;
    } else {
      this.vPerformanceValue = false;
    }


    if (this.newPerformanceRate.description.filter(d => d.name == '').length == this.newPerformanceRate.description.length) {
      this.vPerformanceDescription = true;
      isValid = false;
    } else {
      this.vPerformanceDescription = false;
    }


    // if (!this.newPerformanceRate.icon) {
    //   this.vPerformanceIcon = true;
    //   isValid = false;
    // } else {
    //   this.vPerformanceIcon = false;
    // }

    this.performanceInvalid = !isValid;
    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

    if (!this.validateCount(this.newPerformanceRate.description, this.mdCount || !this.validateCount(this.newPerformanceRate.value, this.mdCount, 'x')))
      return;

    return isValid;
  }


  deletePerformance(performance: PerformanceRate, i) {


    if (performance?.usedByExit || performance?.usedByEngagement || performance?.usedByAssessment) {
      let message = this.translationPipe.transform("used_by_products", this.language, this.ModuleNames.Common);

      if (performance?.usedByExit)
        message = message + this.translationPipe.transform("used_by_exit_leaver", this.language, this.ModuleNames.Common);

      if (performance?.usedByEngagement)
        message = message + this.translationPipe.transform("used_by_engagement_survey", this.language, this.ModuleNames.Common);

      if (performance?.usedByAssessment)
        message = message + this.translationPipe.transform("used_by_assessment_survey", this.language, this.ModuleNames.Common);

      this.responseDialogService.openDialog(message, this.translationPipe.transform("txt_delete_denied", this.language, this.ModuleNames.Common), 'error', this.translationPipe.transform("txt_okay_action", this.language, this.ModuleNames.Common), this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common), true);

    } else {


      let message = this.translationPipe.transform("txt_delete_performance", this.language, this.ModuleNames.Common)
      let subject = ''
      let actionButtonText = this.translationPipe.transform("txt_yes", this.language, this.ModuleNames.Common)
      let cancelButtonText = this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common)

      let confirmationDialog = this.matConfirmDialog.open(ConfirmationDialogComponent, {
        data:
          { message: message, subject: subject, dialogType: 'warning', actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false
      });

      this.subscriptions.add(confirmationDialog.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 'YES') {
            let index = this.selectedEntity.PerformanceRates.indexOf(performance);
            this.selectedEntity.PerformanceRates.splice(index, 1);
            this.updateEntity('DELETE');
          }
        }
      }))
    }
  }


  deleteBandLevel(bandLevel: BandLevel, i) {
    if (bandLevel?.usedByExit || bandLevel?.usedByEngagement || bandLevel?.usedByAssessment) {
      let message = this.translationPipe.transform("used_by_products", this.language, this.ModuleNames.Common);

      if (bandLevel?.usedByExit)
        message = message + this.translationPipe.transform("used_by_exit_leaver", this.language, this.ModuleNames.Common);

      if (bandLevel?.usedByEngagement)
        message = message + this.translationPipe.transform("used_by_engagement_survey", this.language, this.ModuleNames.Common);

      if (bandLevel?.usedByAssessment)
        message = message + this.translationPipe.transform("used_by_assessment_survey", this.language, this.ModuleNames.Common);

      this.responseDialogService.openDialog(message, this.translationPipe.transform("txt_delete_denied", this.language, this.ModuleNames.Common), 'error', this.translationPipe.transform("txt_okay_action", this.language, this.ModuleNames.Common), this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common), true);

    } else {

      let message = this.translationPipe.transform("txt_delete_band_level", this.language, this.ModuleNames.Common)
      let subject = ''
      let actionButtonText = this.translationPipe.transform("txt_yes", this.language, this.ModuleNames.Common)
      let cancelButtonText = this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common)


      let confirmationDialog = this.matConfirmDialog.open(ConfirmationDialogComponent, {
        data:
          { message: message, subject: subject, dialogType: 'warning', actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false
      });

      this.subscriptions.add(confirmationDialog.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 'YES') {
            let index = this.selectedEntity.BandLevels.indexOf(bandLevel);
            this.selectedEntity.BandLevels.splice(index, 1);
            this.updateEntity('DELETE');
          }
        }
      }))
    }
  }



  deleteJobGrade(jobGrade: JobGrade, i) {
    if (jobGrade?.usedByExit || jobGrade?.usedByEngagement || jobGrade?.usedByAssessment) {

      let message = this.translationPipe.transform("used_by_products", this.language, this.ModuleNames.Common);
      if (jobGrade?.usedByExit)
        message = message + this.translationPipe.transform("used_by_exit_leaver", this.language, this.ModuleNames.Common);

      if (jobGrade?.usedByEngagement)
        message = message + this.translationPipe.transform("used_by_engagement_survey", this.language, this.ModuleNames.Common);

      if (jobGrade?.usedByAssessment)
        message = message + this.translationPipe.transform("used_by_assessment_survey", this.language, this.ModuleNames.Common);

      this.responseDialogService.openDialog(message, this.translationPipe.transform("txt_delete_denied", this.language, this.ModuleNames.Common), 'error', this.translationPipe.transform("txt_okay_action", this.language, this.ModuleNames.Common), this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common), true);

    } else {

      let message = this.translationPipe.transform("txt_delete_job_grade", this.language, this.ModuleNames.Common)
      let subject = ''
      let actionButtonText = this.translationPipe.transform("txt_yes", this.language, this.ModuleNames.Common)
      let cancelButtonText = this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common)



      let confirmationDialog = this.matConfirmDialog.open(ConfirmationDialogComponent, {
        data:
          { message: message, subject: subject, dialogType: 'warning', actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false
      });

      this.subscriptions.add(confirmationDialog.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 'YES') {
            let index = this.selectedEntity.JobGrades.indexOf(jobGrade);
            this.selectedEntity.JobGrades.splice(index, 1);
            this.updateEntity('DELETE');
          }
        }
      }))
    }
  }

  deleteStructure(structure: EntityGroup, i) {
    if (structure?.usedByExit || structure?.usedByEngagement || structure?.usedByAssessment) {
      let message = this.translationPipe.transform("used_by_products", this.language, this.ModuleNames.Common);
      if (structure?.usedByExit)
        message = message + this.translationPipe.transform("used_by_exit_leaver", this.language, this.ModuleNames.Common);

      if (structure?.usedByEngagement)
        message = message + this.translationPipe.transform("used_by_engagement_survey", this.language, this.ModuleNames.Common);

      if (structure?.usedByAssessment)
        message = message + this.translationPipe.transform("used_by_assessment_survey", this.language, this.ModuleNames.Common);

      this.responseDialogService.openDialog(message, this.translationPipe.transform("txt_delete_denied", this.language, this.ModuleNames.Common), 'error', this.translationPipe.transform("txt_okay_action", this.language, this.ModuleNames.Common), this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common), true);
    } else {


      let message = this.translationPipe.transform("txt_delete_structure", this.language, this.ModuleNames.Common)
      let subject = ''
      let actionButtonText = this.translationPipe.transform("txt_yes", this.language, this.ModuleNames.Common)
      let cancelButtonText = this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common)



      let confirmationDialog = this.matConfirmDialog.open(ConfirmationDialogComponent, {
        data:
          { message: message, subject: subject, dialogType: 'warning', actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false
      });

      this.subscriptions.add(confirmationDialog.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 'YES') {
            let index = this.selectedEntity.EntityGroups.indexOf(structure);
            this.selectedEntity.EntityGroups.splice(index, 1);
            let entitiesToDelete = this.allEntities.filter((e: Entity) => e.entityGroupKey == structure.groupKey);
            for (let i = 0; i < entitiesToDelete?.length; i++) {
              let e = entitiesToDelete[i];
              this.entityService.deleteEntity(e).then((_) => {
              }).catch((ex) => {

              });
            }
            this.updateEntity('DELETE');
          }
        }
      }))
    }
  }

  deleteCompany(company: Entity) {

  }

  addNewPerformance() {

    let isValid: boolean = this.validatePerformance();

    if (isValid) {
      if (!this.selectedEntity.PerformanceRates)
        this.selectedEntity.PerformanceRates = [];
      this.selectedEntity.PerformanceRates.push(this.newPerformanceRate);
      this.newPerformanceRate = new PerformanceRate();
      this.newPerformanceRate.key = this.db.createPushId();
      this.newPerformanceRate = JSON.parse(JSON.stringify(this.newPerformanceRate));
      this.performanceAdd = false;
      this.crashReportingService.submitLogCode(10);
      this.updateEntity('CREATE');
    }
  }

  bandlevelInvalid: boolean = false;
  validateBandLevel() {

    let isValid: boolean = true;

    if (this.newBandLevel.name.filter(d => d.name == '').length == this.newBandLevel.name.length) {
      this.vBandLevelName = true;
      isValid = false;
    } else {
      this.vBandLevelName = false;
    }
    this.bandlevelInvalid = !isValid;
    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

    if (!this.validateCount(this.newBandLevel.name, this.mdCount))
      return;

    return isValid;
  }

  addNewBandLevel() {

    let isValid = this.validateBandLevel();

    if (isValid) {
      if (!this.selectedEntity.BandLevels)
        this.selectedEntity.BandLevels = [];
      this.selectedEntity.BandLevels.push(this.newBandLevel);
      this.newBandLevel = new BandLevel();
      this.newBandLevel.key = this.db.createPushId();
      this.newBandLevel = JSON.parse(JSON.stringify(this.newBandLevel));
      this.bandLevelAdd = false;
      this.updateEntity('CREATE');
      this.crashReportingService.submitLogCode(11);
    }
  }

  jobGradeInvalid: boolean = false;
  validateJobLevel() {

    let isValid: boolean = true;

    if (this.newJobGrade.name.filter(d => d.name == '').length == this.newJobGrade.name.length) {
      this.vJobGradeName = true;
      isValid = false;
    } else {
      this.vJobGradeName = false;
    }

    // if(!this.newJobGrade.bandLevelKey){
    //   this.vJobGradeLevel = true;
    //   isValid = false;
    // } else {
    //   this.vJobGradeLevel = false;
    // }
    this.jobGradeInvalid = !isValid;
    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });


    if (!this.validateCount(this.newJobGrade.name, this.mdCount))
      return;

    return isValid;
  }


  deleteUser(u: PlatformUser) {
    let message = this.translationPipe.transform("txt_delete_user", this.language, this.ModuleNames.Common)
    let subject = ''
    let actionButtonText = this.translationPipe.transform("txt_yes", this.language, this.ModuleNames.Common)
    let cancelButtonText = this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common)

    let confirmationDialog = this.matConfirmDialog.open(ConfirmationDialogComponent, {
      data:
        { message: message, subject: subject, dialogType: 'warning', actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false
    });

    this.subscriptions.add(confirmationDialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result == 'YES') {
          u.isActive = false;
          this.updatePlatformUser(u);
          // this.authenticationService.deleteUser({ uid: u.uid }).subscribe((response: any) => {
          //   if (response.response == 'SUCCESS') {

          //   } else {

          //   }
          // })
        }
      }
    }))

  }

  addNewJobGrade() {
    console.log(this.newJobGrade);

    let isValid = this.validateJobLevel();

    if (isValid) {
      if (!this.selectedEntity.JobGrades)
        this.selectedEntity.JobGrades = [];
      this.selectedEntity.JobGrades.push(this.newJobGrade);
      this.newJobGrade = new JobGrade();
      this.newJobGrade.key = this.db.createPushId();
      this.newJobGrade = JSON.parse(JSON.stringify(this.newJobGrade));
      this.jobGradeAdd = false;
      this.updateEntity('CREATE');
      this.crashReportingService.submitLogCode(12);
    }
  }


  resetDropdown: boolean = false;
  multiUseTimer;
  branchesSelectionChanged(event, entity: Entity) {
    let selectedKeys = event;
    if (selectedKeys.length == 0) {
      entity.Branches = [];
      this.resetDropdown = true;
      this.multiUseTimer = setTimeout(() => {
        this.resetDropdown = false;
      }, 50);
    } else {
      entity.Branches = selectedKeys;
    }

  }

  async updateCompany(entity: Entity) {
    let isValid = this.validateEditCompanyForm(entity);
    if (isValid) {
      let response: any = await this.entityService.coreUpdateCompany(entity).toPromise();
      this.updateCompanyEntity(entity);
      this.closeModal('companyAdd');

    }
  }


  updateCompanyEntity(entity: Entity) {
    try {
      let snackbarMessage: string;
      snackbarMessage = this.translationPipe.transform("txt_update_entity_group", this.language, this.ModuleNames.Common);
      this.zenSnackBarService.snackBar({ message: snackbarMessage, type: ZenSnackBarTypes.Success });
      this.entityService.updateEntity(entity).then((_) => {
        this.logService.submitLog(4, 'SettingsComponent.updateEntity()', 'Task Completed', { selectedEntity: entity });
        this.essentialDataService.updateHeader({ update: true });
      }).catch((error) => {
        this.logService.submitLog(4, 'SettingsComponent.updateEntity()', 'Task Failed', { selectedEntity: entity });
        this.tableOptions();
      });
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1004, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }








  validateUserForm() {
    let isValid: boolean = true;
    if (!this.newUser.firstName) {
      this.vUserFirstName = true;
      isValid = false;
    } else {
      this.vUserFirstName = false;
    }

    if (!this.newUser.lastName) {
      this.vUserLastName = true;
      isValid = false;
    } else {
      this.vUserLastName = false;
    }


    if (!this.newUser.email) {
      this.vUserEmail = true;
      isValid = false;
    } else {
      this.vUserEmail = false;
    }


    if (!this.newUser.password) {
      this.vUserPassword = true;
      isValid = false;
    } else {
      this.vUserPassword = false;
    }


    // if (!this.isSuperUser) {
    //   if (this.newUser.companyReferences?.length == 0 || !this.newUser.companyReferences) {
    //     this.vEUserCompanies = true;
    //     isValid = false;
    //   } else {
    //     this.vEUserCompanies = false;
    //   }
    // }
    if (!this.isSuperUser && this.newUser?.CompanyPermissions?.filter(c => c?.products?.length > 0).length == 0) {
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform('txt_Atleast1p_288', this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
      return false;
    }

    if (this.newUser.email && this.newUser.email?.indexOf("@") < 1) {
      this.vUserEmail = true;
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_user_email", this.language, this.ModuleNames.Common) + ' ' + this.translationPipe.transform("txtWrongFormat", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
      return false;
    } else {
      this.vUserEmail = false;
    }
    this.userInvalid = !isValid;

    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

    if (!this.validateCount(this.newUser.firstName, this.mdCount, 'x') || !this.validateCount(this.newUser.lastName, this.mdCount, 'x') || this.newUser.email && !this.validateCount(this.newUser.email, this.mdCount, 'x'))
      return;





    return isValid;
  }


  async createNewUser(reactivate?: boolean) {
    // try {
    // this.permissionTreeControl.collapseAll()
    let isValid = this.validateUserForm();

    if (isValid) {

      if (!this.newUser.EntityReferences)
        this.newUser.EntityReferences = [];

      this.newUser.EntityReferences = [...this.newUser?.companyReferences];
      if (this.newUser?.EntityReferences?.length == 0 && this.isSuperUser) {
        this.newUser.EntityReferences = [];
        for (let i = 0; i < this.companies.length; i++) {
          let c = this.companies[i];
          this.newUser.EntityReferences.push(c.key);
        }
      }
      this.newUser.companyReferences = null;
      let password = this.newUser.password;
      this.newUser.password = null;
      this.newUser.email = this.newUser.email.replace(/\s/g, '');
      this.newUser.email = this.newUser.email.toLocaleLowerCase();

      if (!reactivate) this.newUser.key = this.db.createPushId();
      if (this.isSuperUser) {
        this.newUser.role = 'Super Admin';
        this.newUser.CompanyPermissions = [];
      }
      else this.newUser.role = 'User';

      let companyEntities: Entity[] = this.entities.filter(e => e.isCompany);



      this.newUser.CreatedBy = this.user.firstName + ' ' + this.user.lastName;
      this.newUser.CompanyPermissions = this.newUser?.CompanyPermissions?.filter(c => c?.products?.length > 0);
      // this.newUser.CompanyPermissions = this.newUser.CompanyPermissions.filter(p => !companyEntities.find(e => e.key == p.key));    
      ;
      for (let i = 0; i < this.newUser.CompanyPermissions.length; i++) {
        let permission = this.newUser.CompanyPermissions[i];
        let permissionEntity: Entity = this.allEntities.find(e => e.key == permission.key);
        if (permissionEntity && !permissionEntity?.isCompany && !this.newUser.CompanyPermissions.find(c => c.key == permissionEntity?.companyEntityKey)) {
          let companyEntity = this.entities.find(e => e.key == permissionEntity?.companyEntityKey);
          let newPermission = new CompanyPermission();
          newPermission.key = companyEntity.key;
          newPermission.products = permission.products;
          this.newUser?.CompanyPermissions?.push(newPermission);
        }
        // else if (!permissionEntity.isCompany && this.newUser.CompanyPermissions.find(c => c.key == permissionEntity.companyEntityKey)){
        //   let companyPermission = this.newUser.CompanyPermissions.find(c => c.key == permissionEntity.companyEntityKey);
        //   for(let x = 0; x < permission?.products?.length; x++){
        //     let product = permission.products[x];
        //     if(!companyPermission.products.find(p => p == product))
        //       companyPermission.products.push(product);
        //   }
        // }
      }

      companyEntities = companyEntities.filter(e => this.newUser?.CompanyPermissions?.find(p => p.key == e.key));
      for (let i = 0; i < companyEntities.length; i++) {
        let company = companyEntities[i];
        let companyPermission = this.newUser?.CompanyPermissions?.find(p => p.key == company.key)
        let subEntities = this.allEntities.filter(e => e.companyEntityKey == company.key);
        subEntities = subEntities.filter(e => this.newUser?.CompanyPermissions?.find(p => p.key == e.key));
        if (subEntities.length > 0) {
          let products: string[] = [];
          for (let x = 0; x < subEntities.length; x++) {
            let subEntity = subEntities[x];
            let subEntityPermission = this.newUser?.CompanyPermissions?.find(p => p.key == subEntity.key);
            for (let j = 0; j < subEntityPermission?.products?.length; j++) {
              let product = subEntityPermission.products[j];
              if (!products.find(p => p == product))
                products.push(product);
            }
          }
          companyPermission.products = products;

        }
      }
      console.log(this.newUser.CompanyPermissions);

      let permissionResponse: any = await this.platformUserService.updateUserPermissions(this.newUser).toPromise();

      if (permissionResponse.success == true) {
        this.authenticationService
          .registerFirebaseUser(this.newUser, password)
          .then((result) => {
            this.newUser.uid = result.user.uid;


            this.platformUserService.createPlatformUser(this.newUser);
            result.user.updateProfile({ displayName: this.newUser.key });
            this.crashReportingService.submitLogCode(8);

            this.subscriptions.add(
              this.emailNotificationService
                .sendNotificationEmail({
                  language: this.language,
                  notificationType: 'NEW-ADMIN',
                  tenant: this.tenant,
                  toRecipient: this.newUser.email,
                  notificationBody: {
                    name:
                      this.newUser.firstName + ' ' + this.newUser.lastName,
                    companyName: this.extractUserCompanies(this.newUser),
                    adminPassword: password,
                  },
                })
                .subscribe((resp) => { })
            );

            this.newUser = new PlatformUser();
            this.newUser.tenantKey = this.tenant.key;
            this.newUser.isActive = true;
            this.newUser.EntityReferences = [];
            let snackbarMessage: string;
            snackbarMessage = this.translationPipe.transform(
              'txt_add_entity_group',
              this.language,
              this.ModuleNames.Common
            );
            this.zenSnackBarService.snackBar({
              message: snackbarMessage,
              type: ZenSnackBarTypes.Success,
            });
            this.userAdd = false;
            this.tableOptions();
          })
          .catch((error) => {
            if (error.code.indexOf('invalid-email') > 0)
              return this.zenSnackBarService.snackBar({
                message:
                  this.translationPipe.transform(
                    'txt_user_email',
                    this.language,
                    this.ModuleNames.Common
                  ) +
                  ' ' +
                  this.translationPipe.transform(
                    'txtWrongFormat',
                    this.language,
                    this.ModuleNames.Common
                  ),
                type: ZenSnackBarTypes.Error,
              });
            else
              this.responseDialogService.openDialog(
                'txt_user_exists_message',
                'txt_user_exists_subject',
                'error',
                'txt_okay_action',
                this.language
              );
            console.log(error);
          });
      } else {
        return this.zenSnackBarService.snackBar({
          message: "Something Went Wrong",
          type: ZenSnackBarTypes.Error,
        });
      }
    }
    // } catch (ex) {
    //   this.errorHandlerService.submitErrorMessage(1005, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
    //     this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    // }
  }

  companyInvalid: boolean = false;
  userInvalid: boolean = false;
  validateCompanyForm() {

    let isValid: boolean = true;
    if (this.newCompany.name.filter(n => n.name == '').length == this.newCompany.name.length) {
      this.vCompanyName = true;
      isValid = false;
    } else {
      this.vCompanyName = false;
    }

    if (!this.newCompany.industry) {
      this.vCompanyIndustry = true;
      isValid = false;
    } else {
      this.vCompanyIndustry = false;
    }


    // if(!this.newCompany.headcount){
    //   this.vCompanyHeadcount = true;
    //   isValid = false;
    // } else {
    //   this.vCompanyHeadcount = false;
    // }

    if (!this.newCompany.companySize) {
      this.vCompanySize = true;
      isValid = false;
    } else {
      this.vCompanySize = false;
    }

    if (this.newCompany.Branches.length == 0) {
      this.vCompanyBranches = true;
      isValid = false;
    } else {
      this.vCompanyBranches = false;
    }

    if (this.newCompany.ContentLanguages.length == 0) {
      this.vSupportedLanguages = true;
      isValid = false;
    } else {
      this.vSupportedLanguages = false;
    }

    if (!this.newCompany.contactCode) {
      this.vCountryCode = true;
      isValid = false;
    } else {
      this.vCountryCode = false;
    }

    if (!this.newCompany.contactNumber) {
      this.vContactNumber = true;
      isValid = false;
    } else {
      this.vContactNumber = false;
    }


    if (this.newCompany.website && this.newCompany.website?.indexOf(".") < 1) {
      this.vWebsite = true;
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtWebsite", this.language, this.ModuleNames.Common) + ' ' + this.translationPipe.transform("txtWrongFormat", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

      return false;
    } else {
      this.vWebsite = false;
    }

    this.companyInvalid = !isValid;
    if (!isValid) {
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
      return isValid;
    }

    if (!this.validateCount(this.newCompany.name, this.mdCount) ||
      !this.validateCount(this.newCompany.contactNumber, this.smCount, 'x') || (this.newCompany.website && !this.validateCount(this.newCompany.website, this.mdCount, 'x'))

    )
      return;


    return isValid;
  }


  validateEditCompanyForm(company: Entity) {

    let isValid: boolean = true;
    if (company.name.filter(n => n.name == '').length == company.name.length) {
      this.vECompanyName = true;
      isValid = false;
    } else {
      this.vECompanyName = false;
    }

    if (!company.industry) {
      this.vECompanyIndustry = true;
      isValid = false;
    } else {
      this.vECompanyIndustry = false;
    }


    // if(!company.headcount){
    //   this.vECompanyHeadcount = true;
    //   isValid = false;
    // } else {
    //   this.vECompanyHeadcount = false;
    // }

    if (!company.companySize) {
      this.vECompanySize = true;
      isValid = false;
    } else {
      this.vECompanySize = false;
    }

    if (company.Branches.length == 0) {
      this.vECompanyBranches = true;
      isValid = false;
    } else {
      this.vECompanyBranches = false;
    }

    if (company.ContentLanguages.length == 0) {
      this.vESupportedLanguages = true;
      isValid = false;
    } else {
      this.vESupportedLanguages = false;
    }

    if (!company.contactCode) {
      this.vECountryCode = true;
      isValid = false;
    } else {
      this.vECountryCode = false;
    }

    if (!company.contactNumber) {
      this.vEContactNumber = true;
      isValid = false;
    } else {
      this.vEContactNumber = false;
    }



    if (company.website && company.website?.indexOf(".") < 1) {
      this.vWebsite = true;
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtWebsite", this.language, this.ModuleNames.Common) + ' ' + this.translationPipe.transform("txtWrongFormat", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

      return false;
    } else {
      this.vWebsite = false;
    }

    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

    if (!this.validateCount(company.name, this.mdCount) ||
      !this.validateCount(company.contactNumber, this.smCount, 'x') || (company.website && !this.validateCount(company.website, this.mdCount, 'x'))

    )
      return;

    return isValid;
  }


  multiTimer;
  dropDownChanged(event, entity) {
    entity.headQuarter = event;
    if (event == '') {
      this.resetDropdown = true;
      this.multiTimer = setTimeout(() => {
        this.resetDropdown = false;
      }, 50);
    }
  }

  resetPassword(email) {
    try {
      let message = this.translationPipe.transform("txt_Password_resetConfirmation", this.language, this.ModuleNames.Common)
      let subject = this.translationPipe.transform("txt_subject_password_resetConfirmation", this.language, this.ModuleNames.Common)
      message = message.replace('#EMAIL#', email);
      let actionButtonText = this.translationPipe.transform("txt_yes", this.language, this.ModuleNames.Common)
      let cancelButtonText = this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Common)

      let confirmationDialog = this.matDDialog.open(ConfirmationDialogComponent, {
        data:
          { message: message, subject: subject, dialogType: 'warning', actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false
      });
      this.subscriptions.add(confirmationDialog.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 'YES') {
            this.authenticationService.sendResetPassword(email).then((_) => {
              this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtResetEmail", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Success })

            })
          }
        }
      }))
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1006, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  async createCompany() {

    let isValid = this.validateCompanyForm();
    if (isValid) {
      this.entityService.createCompany({ TenantKey: this.tenant.key, CompanyKey: this.newCompany.key, thomasIntegration: this.tenant.thomasIntegrated }).subscribe(async (response: any) => {
        if (this.tenant.thomasIntegrated) {
          let response: any = await this.clientsSservice.newThomasCompany(this.newCompany, this.user.firstName + ' ' + this.user.lastName).toPromise();

        }
        if (response.response == 'SUCCESS') {
          this.proceedWithCompanyAdd();
        } else {
          this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtSthWrong", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
        }

      });

    }
  }

  async proceedWithCompanyAdd() {
    this.companyAdd = false;
    if (!this.user.EntityReferences)
      this.user.EntityReferences = [];
    this.crashReportingService.submitLogCode(7);
    this.logService.submitLog(0, 'TenantSetupComponent.createCompanies()', 'Task Assigned', { company: this.newCompany });
    let response: any = await this.entityService.coreUpdateCompany(this.newCompany).toPromise();
    this.entityService.createEntity(this.newCompany);
    this.user.EntityReferences.push(this.newCompany.key);
    this.logService.submitLog(0, 'TenantSetupComponent.createCompanies()', 'Task Completed', { company: this.newCompany });
    this.newCompany = new Entity();
    this.newCompany.key = this.db.createPushId();
    this.newCompany.isCompany = true;
    this.newCompany.tenantKey = this.tenant.key;
    this.newCompany.companyEntityKey = this.newCompany.key;
    let entityGroup = new EntityGroup();
    entityGroup.groupKey = this.db.createPushId();
    entityGroup.isCompanyGroup = true;
    entityGroup.name = this.languageService.companyLanguages;
    this.newCompany.EntityGroups.push(entityGroup);
    this.newCompany.entityGroupKey = entityGroup.groupKey;
    // this.updateUserEntities(this.user);
    this.essentialDataService.updateHeader({ update: true });
    // let snackbarMessage: string;
    // snackbarMessage = this.translationPipe.transform("txt_add_entity_group", this.language, this.ModuleNames.Common);
    // this.zenSnackBarService.snackBar({ message: snackbarMessage, type: ZenSnackBarTypes.Success });
  }



  validateUserEditForm(newUser: PlatformUser) {
    let isValid: boolean = true;
    if (!newUser.firstName) {
      this.vEUserFirstName = true;
      isValid = false;
    } else {
      this.vEUserFirstName = false;
    }

    if (!newUser.lastName) {
      this.vEUserLastName = true;
      isValid = false;
    } else {
      this.vEUserLastName = false;
    }


    if (!newUser.email) {
      this.vEUserEmail = true;
      isValid = false;
    } else {
      this.vEUserEmail = false;
    }

    if (newUser.role != 'Super Admin' && newUser?.CompanyPermissions?.filter(c => c?.products?.length > 0).length == 0) {
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform('txt_Atleast1p_288', this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
      return false;
    }

    // if (!this.isSuperUser) {
    //   if (newUser.companyReferences?.length == 0 || !newUser.companyReferences) {
    //     this.vEUserCompanies = true;
    //     isValid = false;
    //   } else {
    //     this.vEUserCompanies = false;
    //   }
    // }
    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

    if (!this.validateCount(newUser.firstName, this.mdCount, 'x') || !this.validateCount(newUser.lastName, this.mdCount, 'x'))
      return;

    return isValid;
  }


  async updateUser(user: PlatformUser) {
    let isValid = this.validateUserEditForm(user);
    if (isValid) {
      console.log(user);
      let companyEntities: Entity[] = this.entities.filter(e => e.isCompany);

      this.newUser.CreatedBy = this.user.firstName + ' ' + this.user.lastName;
      this.newUser.CompanyPermissions = this.newUser?.CompanyPermissions?.filter(c => c?.products?.length > 0);
      // this.newUser.CompanyPermissions = this.newUser.CompanyPermissions.filter(p => !companyEntities.find(e => e.key == p.key));
      for (let i = 0; i < user?.CompanyPermissions?.length; i++) {
        let permission = user.CompanyPermissions[i];
        let permissionEntity: Entity = this.allEntities.find(e => e.key == permission.key);
        if (permissionEntity && !permissionEntity?.isCompany && !user.CompanyPermissions.find(c => c.key == permissionEntity?.companyEntityKey)) {
          let companyEntity = this.entities.find(e => e.key == permissionEntity?.companyEntityKey);
          let newPermission = new CompanyPermission();
          newPermission.key = companyEntity.key;
          newPermission.products = [];
          user?.CompanyPermissions?.push(newPermission)
        }
        // else if (!permissionEntity.isCompany && user.CompanyPermissions.find(c => c.key == permissionEntity.companyEntityKey)){
        //   let companyPermission = user.CompanyPermissions.find(c => c.key == permissionEntity.companyEntityKey);
        //   for(let x = 0; x < permission?.products?.length; x++){
        //     let product = permission.products[x];
        //     if(!companyPermission.products.find(p => p == product))
        //       companyPermission.products.push(product);
        //   }
        // }
      }

      companyEntities = companyEntities.filter(e => user?.CompanyPermissions?.find(p => p.key == e.key));
      for (let i = 0; i < companyEntities.length; i++) {
        let company = companyEntities[i];
        let companyPermission = user?.CompanyPermissions?.find(p => p.key == company.key)
        let subEntities = this.allEntities.filter(e => e.companyEntityKey == company.key);
        subEntities = subEntities.filter(e => user?.CompanyPermissions?.find(p => p.key == e.key));
        let products: string[] = [];
        for (let x = 0; x < subEntities.length; x++) {
          let subEntity = subEntities[x];
          let subEntityPermission = user?.CompanyPermissions?.find(p => p.key == subEntity.key);
          for (let j = 0; j < subEntityPermission?.products?.length; j++) {
            let product = subEntityPermission.products[j];
            if (!products.find(p => p == product))
              products.push(product);
          }
        }
        companyPermission.products = products;
      }


      let permissionResponse: any = await this.platformUserService.updateUserPermissions(user).toPromise();

      if (permissionResponse.success == true) {
        for (let i = 0; i < user?.companyReferences?.length; i++) {
          let companyRef = user.companyReferences[i];
          let cF = user?.EntityReferences?.find(eF => eF == companyRef);
          if (!user?.EntityReferences)
            user.EntityReferences = [];
          if (!cF)
            user.EntityReferences.push(companyRef);
        }
        for (let i = 0; i < user?.EntityReferences?.length; i++) {
          let eR = user.EntityReferences[i];
          let company = this.companies.find(c => c.key == eR);
          if (company) {
            let cF = user.companyReferences.find(c => c == company.key);
            if (!cF)
              user.EntityReferences.splice(i, 1);
          }
        }
        if (this.isSuperUser)
          user.role = 'Super Admin';
        else
          user.role = 'User';
        user.companyReferences = null;
        this.editMode = false;
        this.userAdd = false;
        if (user.role == 'Super Admin') {
          user.CompanyPermissions = [];
        }
        this.closeModal('userAdd');

        this.updateUserEntities(user);
      }
    }
  }

  updateUserEntities(user: PlatformUser) {
    try {
      let snackbarMessage: string;
      snackbarMessage = this.translationPipe.transform("txt_update_entity_group", this.language, this.ModuleNames.Common);
      this.zenSnackBarService.snackBar({ message: snackbarMessage, type: ZenSnackBarTypes.Success });




      this.logService.submitLog(0, 'SettingsComponent().updateUserEntities', 'Task Assiigned', { user: user });
      this.platformUserService.updatePlatformUser(user).then(_ => {
        this.logService.submitLog(0, 'SettingsComponent().updateUserEntities', 'Task Completed', { user: user });
        this.tableOptions();
      })
        .catch((error) => {
          this.logService.submitLog(0, 'SettingsComponent().updateUserEntities', 'Task Failed', { user: user });

        });
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1007, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }


  updatePlatformUser(user: PlatformUser) {
    try {
      this.platformUserService.updatePlatformUser(user).then(_ => {
        this.logService.submitLog(0, 'SettingsComponent().updateUserEntities', 'Task Completed', { user: user });
      })
        .catch((error) => {
          this.logService.submitLog(0, 'SettingsComponent().updateUserEntities', 'Task Failed', { user: user });

        });
    } catch (ex) {
      this.errorHandlerService.submitErrorMessage(1008, ex.toString(), this.tenant.key, this.tenant.name, this.selectedEntity.key,
        this.extractNameLanguage(this.selectedEntity.name), this.user?.firstName + ' ' + this.user?.lastName + ' (' + this.user?.email + ')');
    }
  }

  extractUserCompanies(u: PlatformUser) {
    let companyList: string = '';
    for (let i = 0; i < u.EntityReferences?.length; i++) {
      let company = this.companies?.find(c => c.key == u.EntityReferences[i]);
      if (company) {
        let entityName = this.extractNameLanguage(company.name);
        if (i == 0)
          companyList = companyList + entityName;
        else
          companyList = companyList + ', ' + entityName;
      }
    }
    return companyList;
  }

  editUser(user: PlatformUser, index: number) {
    this.permissionTreeControl.collapseAll()
    user.companyReferences = [];
    for (let i = 0; i < user.EntityReferences?.length; i++) {
      let company = this.companies.find(c => c.key == user.EntityReferences[i]);
      if (company)
        user.companyReferences.push(company.key);
    }
    if (user.role == 'Super Admin')
      this.isSuperUser = true;
    else
      this.isSuperUser = false;
    this.editUserCopy = JSON.parse(JSON.stringify(user));
    if (!user.CompanyPermissions)
      user.CompanyPermissions = [];
    this.companies.forEach((com: Entity, index) => {
      if (!user.CompanyPermissions?.find((persmission: CompanyPermission) => com.key == persmission.key))
        user.CompanyPermissions.push({ key: com.key, products: [] });
    });
    this.editMode = true;
    this.openModal('userAdd');
    this.newUser = JSON.parse(JSON.stringify(user));
  }
  getProductIcon(product) {
    if (product == 'ONBOARDING')
      product = 'onboarding';
    if (product == 'ENGAGEMENT')
      product = 'Engagement';
    if (product == 'HAPPINESS')
      product = 'Happiness';
    if (product == 'EXIT')
      product = 'Exit';
    if (product == products.Psychometric)
      product = 'Psychomatric';

    return '/assets/img/sub/' + product + '.svg';
  }



  cancelUserEdit(i) {
    this.users[i] = JSON.parse(JSON.stringify(this.editUserCopy));
    this.userEditIndex = -1;
  }

  extractBandLevelName(bandLevelKey: string) {
    let bndLevel = this.selectedEntity.BandLevels?.find(bnd => bnd?.key == bandLevelKey);
    if (bndLevel) {
      return this.extractNameLanguage(bndLevel.name);
    }
    return '';
  }

  setSelectedTab(tabIndex) {
    if (this.user.role != 'Super Admin') {
      this.selectedTab = (tabIndex - 2);
    } else {
      this.selectedTab = tabIndex;
    }
  }

  modalInterval = false;
  openModal(v) {
    this.modalInterval = true;
    setTimeout(() => {
      this[v] = true;

    }, 100);
  }
  closeModal(v) {
    this[v] = false;
    this.editMode = false;

    setTimeout(() => {
      this.modalInterval = false;
    }, 300);
  }

  entityAddFunc() {
    this.entityAdd = true;
  }
  currentIndex: number = -1;
  value1;
  resolveRoute(o) {
    if (o > this.currentIndex) {
      this.value1 = 'down';
    } else if (o < this.currentIndex) {
      this.value1 = 'up';
    }

    this.currentIndex = o;
    return this.value1;
  }
  //updated by bayan
  entitiesNavigation$ = new BehaviorSubject<Entity[]>([]);
  entitiesNavigation: Entity[] = [];
  permissionDataSource = new MatTreeNestedDataSource<Entity>();
  permissionTreeControl = new NestedTreeControl<Entity>(node => this.entitiesNavigation.filter(x => x.parentKey == node.key));
  @ViewChild('tree') tree;
  hasChild = (_: number, node: Entity) => {
    return this.entitiesNavigation.filter(x => x.parentKey == node.key).length > 0;
  }
  initUserProductPermission() {
    for (var prod in products) {
      this.products.push(products[prod])
    }
  }
  isProduct(prod) {
    if (prod.includes('settings') || prod.includes('overview')) {
      return false;
    }
    return true;
  }

  getCompanyEntities(key) {
    return this.companies.find(com => com.key == key).entityGroups;
  }
  getCompanyPermission(entityKey) {
    let permission = this.newUser.CompanyPermissions.find(f => f.key == entityKey);
    if (!permission) {
      permission = { key: entityKey, products: [] };
      this.newUser.CompanyPermissions.push(permission)
    }
    return permission;
  }
  handleClickAll(entityKey) {
    let permission: CompanyPermission = this.getCompanyPermission(entityKey)
    if (permission?.products?.length != this.products?.length) {
      permission.products = JSON.parse(JSON.stringify(this.products));
    }
    else permission.products = [];
  }
  permissionHandle(product, entityKey: string) {
    let permission: CompanyPermission = this.getCompanyPermission(entityKey)
    if (!permission?.products)
      permission.products = [];
    if (!permission?.products?.find(prod => product == prod)) {
      permission.products?.push(product);
    } else permission?.products.splice(permission?.products.indexOf(product), 1);
  }
  permissionCheck(product, entityKey) {
    let permission = this.getCompanyPermission(entityKey)
    if (permission?.products?.length) {
      return permission?.products?.find(prod => product == prod)
    }
  }
  handleClickAllNested(entityKey) {
    let Parentpermission: CompanyPermission = this.getCompanyPermission(entityKey)
    let childrens = this.entitiesNavigation.filter(x => x.parentKey == entityKey);
    childrens.forEach(child => {
      let childpermission: CompanyPermission = this.getCompanyPermission(child.key);
      childpermission.products = JSON.parse(JSON.stringify(Parentpermission.products));
      if (this.entitiesNavigation.filter(x => x.parentKey == child.key).length > 0)
        this.handleClickAllNested(child.key)
    })
  }
  permissionHandleNested(product, entityKey) {
    let Parentpermission: CompanyPermission = this.getCompanyPermission(entityKey)
    let checked = Parentpermission?.products?.find(f => f == product);
    let childrens = this.entitiesNavigation.filter(x => x.parentKey == entityKey);
    childrens.forEach(child => {
      let childpermission: CompanyPermission = this.getCompanyPermission(child.key);
      if (!childpermission?.products)
        childpermission.products = [];
      if (checked) {
        if (!childpermission?.products?.find(prod => product == prod))
          childpermission.products?.push(product);
      }
      else {
        if (childpermission?.products?.find(prod => product == prod))
          childpermission?.products.splice(childpermission?.products.indexOf(product), 1);
      }
      if (this.entitiesNavigation.filter(x => x.parentKey == child.key).length > 0)
        this.permissionHandleNested(product, child.key)
    })
  }
  filterPermission(value) {
    this.entitiesNavigation$.next([]);
    if (value) {
      let items = JSON.parse(JSON.stringify(this.allEntities));
      let filtered = items?.filter(x =>
        this.extractNameLanguage(x.name).toLowerCase().includes(value.toLowerCase())
      );
      let childToParentNodes = [];
      if (filtered.length > 0) {
        filtered.forEach(node => {
          node['highlight'] = true;
          childToParentNodes.push(node);
          childToParentNodes = this.findParents(node, childToParentNodes);
        });
      }
      this.entitiesNavigation$.next(childToParentNodes);
      this.permissionTreeControl.expandAll()
    } else {
      this.entitiesNavigation$.next(this.allEntities);
      this.permissionTreeControl.collapseAll()
    }
  }

  private findParents(node: Entity, nodes = []) {
    var parent = this.entities.find(x => x.key == node.parentKey);
    if (parent) {
      if (!nodes.find(x => x.key == parent.key)) {
        parent['highlight'] = false;
        nodes.push(parent);
      }
      return this.findParents(parent, nodes);
    }
    return nodes;
  }
  //end
}
