<z-modal [open]="open">
  <ng-container header>

  </ng-container>
  <ng-container body>
    <div class="flex flex-row justify-center min-w-500">
      <div class="flex flex-row w-16 h-16 custom-bg items-center justify-center">
        <i class="fa-regular fa-check text-[#00CCCC] text-50 font-black"></i>
      </div>
      </div>
    <div class="flex flex-row justify-center min-w-500 pt-4">
      <h5 class="text-2xl text-zen-primarydefault font-medium text-center">{{"txtForm Closed Successfully"| translate:language:ModuleNames.SurveyX}}</h5>
    </div>

  </ng-container>
  <ng-container footer>
    <div class="flex flex-row justify-between w-full">
        <a (click)="closeModal('open'); openChange.emit(false)" [routerLink]="['/survey-x/form-library']" class="btn cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
          <i [ngClass]="language == 'AR' ? 'fa-angle-right' : 'fa-angle-left'" class="fa-regular"></i>  {{"txtBack to Forms"| translate:language:ModuleNames.SurveyX}}
      </a>
        <a (click)="closeModal('open'); openChange.emit(false)" [routerLink]="['/survey-x/form-analysis',id]" class="btn exp text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1"  >
          {{"txtView Analytics"| translate:language:ModuleNames.SurveyX}} <i [ngClass]="language == 'AR' ? 'fa-angle-left' : 'fa-angle-right'" class="fa-regular"></i>
        </a>
    </div>
  </ng-container>


  </z-modal>
