

import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from "@angular/core";
import { AngularComponentFactory, QuestionAngular } from "survey-angular-ui";
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import { PropertyGridEditorCollection, localization } from "survey-creator-core";
const CUSTOM_TYPE = "datepicker";
@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss']
})
export class CustomDatepickerComponent extends QuestionAngular<QuestionDatePickerModel> {
  handleChange($event: Date) {
    this.model.value = $event;
  }
  get language() {
    if (this.model.survey['locale'] == 'default')
      return 'EN';
    return this.model.survey['locale'].toUpperCase() || 'EN'
  }

}

AngularComponentFactory.Instance.registerComponent(CUSTOM_TYPE + "-question", CustomDatepickerComponent);


export class QuestionDatePickerModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }

  // get SliderStart() {
  //   return this.getPropertyValue("SliderStart");
  // }
  // set SliderStart(val) {
  //   this.setPropertyValue("SliderStart", val);
  // }
  // get SliderEnd() {
  //   return this.getPropertyValue("SliderEnd");
  // }
  // set SliderEnd(val) {
  //   this.setPropertyValue("SliderEnd", val);
  // }

  // get OrangeFromValue() {
  //   return this.getPropertyValue("OrangeFromValue");
  // }
  // set OrangeFromValue(val) {
  //   this.setPropertyValue("OrangeFromValue", val);
  // }

  // get OrangeToValue() {
  //   return this.getPropertyValue("OrangeToValue");
  // }
  // set OrangeToValue(val) {
  //   this.setPropertyValue("OrangeToValue", val);
  // }

}
ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new QuestionDatePickerModel(name);
});



// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [
    // {
    //   name: "SliderStart:number",
    //   category: "general",
    //   visibleIndex: 2 // After the Name and Title
    // },
    // {
    //   name: "SliderEnd:number",
    //   category: "general",
    //   visibleIndex: 3 // After the Name and Title
    // }, {
    //   name: "OrangeFromValue:number",
    //   displayName: 'Orange From: (ex:6)',
    //   category: "general",
    //   visibleIndex: 4 // After the Name and Title
    // }, {
    //   name: "OrangeToValue:number",
    //   displayName: 'Orange To: (ex:7)',
    //   category: "general",
    //   visibleIndex: 5 // After the Name and Title
    // }
  ],
  function () {
    return new QuestionDatePickerModel("");
  },
  "question"
);
// Specify display names for the question type and its properties
const locale = localization.getLocale("");
locale.qt[CUSTOM_TYPE] = "datepicker";
// Register an SVG icon for the question type
SvgRegistry.registerIconFromSvg(
  CUSTOM_TYPE,
  '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 6.5H12C11.7188 6.5 11.5 6.75 11.5 7V13C11.5 13.2812 11.7188 13.5 12 13.5H13C13.25 13.5 13.5 13.2812 13.5 13V7C13.5 6.75 13.25 6.5 13 6.5ZM12 5H13C14.0938 5 15 5.90625 15 7V9.25H17.25C17.6562 9.25 18 9.59375 18 10C18 10.4375 17.6562 10.75 17.25 10.75H15V13C15 14.125 14.0938 15 13 15H12C10.875 15 10 14.125 10 13V7C10 5.90625 10.875 5 12 5ZM2 10C2 9.59375 2.3125 9.25 2.75 9.25H9V10.75H2.75C2.3125 10.75 2 10.4375 2 10Z" fill="#5D75A2"/></svg>'
);

// Register the `color-picker` as an editor for properties of the `color` type in the Survey Creator's Property Grid
PropertyGridEditorCollection.register({
  fit: function (prop) {
    return prop.type === "datepicker";
  },
  getJSON: function () {
    return {
      type: CUSTOM_TYPE,
    };
  }
});
