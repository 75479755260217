<ng-container *ngIf="creator != null && !creator.isCreatorDisposed">
  <sv-ng-modal-container></sv-ng-modal-container>
  <div class="svc-creator" [class.svc-creator--mobile]="creator.isMobileView" #container>
    <div>
      <svc-svg-bundle></svc-svg-bundle>
    </div>
    <div class="svc-full-container svc-creator__area svc-flex-column" [class.svc-creator__area--with-banner]="false">
      <div class="svc-flex-row svc-full-container"
        [class.svc-creator__side-bar--left]="creator.sidebarLocation == 'left'">
        <div class="svc-flex-column svc-flex-row__element svc-flex-row__element--growing">

          <div class="svc-top-bar" *ngIf="showTopBar">
            <div class="svc-tabbed-menu-wrapper" [visible]="creator.showTabs">
              <svc-tabbed-menu [model]="creator.tabbedMenu"></svc-tabbed-menu>
            </div>
            <div *ngIf="creator.showToolbar" class="svc-toolbar-wrapper" [visible]="creator.showToolbar">
              <!-- <sv-action-bar [model]="creator.toolbar"></sv-action-bar> -->
            </div>
          </div>

          <div class="svc-creator__content-wrapper svc-flex-row"
            [class.svc-creator__content-wrapper--footer-toolbar]="creator.isMobileView">
            <div class="svc-creator__content-holder svc-flex-column">
              <div class="svc-creator-tab" *ngIf="creator.viewType == creator.tabs[0].id && creator.tabs[0].visible"
                [attr.id]="'scrollableDiv-' + creator.tabs[0].id"
                [class.svc-creator__toolbox--right]="creator.toolboxLocation == 'right'">
                <app-questions-builder-tab [language]="language" [companyName]="companyName" [model]="creator.tabs[0].data.model" ></app-questions-builder-tab>
              </div>

              <!-- languages -->
              <div class="svc-creator-tab" *ngIf="creator.viewType == creator.tabs[4].id && creator.tabs[4].visible"
                [attr.id]="'scrollableDiv-' + creator.tabs[4].id"
                [class.svc-creator__toolbox--right]="creator.toolboxLocation == 'right'">
                <app-languages-tab [language]="language" [companyName]="companyName"  class="w-full" [model]="creator.tabs[4].data.model" [creator]="creator" ></app-languages-tab>
              </div>

              <div class="svc-creator-tab" *ngIf="creator.viewType == creator.tabs[3].id && creator.tabs[3].visible"
                [attr.id]="'scrollableDiv-' + creator.tabs[3].id"
                [class.svc-creator__toolbox--right]="creator.toolboxLocation == 'right'">
                <ng-template
                  [component]="{ name: creator.tabs[3].componentContent, data: { model: creator.tabs[3].data.model } }"></ng-template>
              </div>

              <!-- Preview -->
              <div class="svc-creator-tab" *ngIf="creator.viewType == creator.tabs[1].id && creator.tabs[1].visible"
                [attr.id]="'scrollableDiv-' + creator.tabs[1].id"
                [class.svc-creator__toolbox--right]="creator.toolboxLocation == 'right'">
                <ng-template
                  [component]="{ name: creator.tabs[1].componentContent, data: { model: creator.tabs[1].data.model } }"></ng-template>
              </div>
            </div>
          </div>
          <div *ngIf="creator.isMobileView" class="svc-footer-bar">
            <div class="svc-toolbar-wrapper" [visible]="creator.isMobileView">
              <!-- <sv-action-bar [model]="creator.footerToolbar"></sv-action-bar> -->
            </div>
          </div>
        </div>
        <!-- <div *ngIf="creator.sidebar" [class.sv-mobile-side-bar]="creator.isMobileView">
          <svc-side-bar [model]="creator.sidebar"></svc-side-bar>
        </div> -->
      </div>
      <div class="svc-creator__banner" *ngIf="false">
        <span class="svc-creator__non-commercial-text">
          <a href="https://surveyjs.io/buy">
            {{creator.licenseText}}
          </a>
        </span>
      </div>
      <!-- <sv-notifier [notifier]="creator.notifier"></sv-notifier> -->
    </div>
  </div>
</ng-container>

