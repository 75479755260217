import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { inOutAnimation, listAnimation } from '../animations/allAnimations';
import { NgForOf } from '@angular/common';

export interface HeatMapSourceModel {
  key: string,
  parentKey: string,
  isDriver: boolean,
  name: { key: string, name: string }[] | string,
  overAll: number,
  options: number[]
}

export interface HeatMapDataModel {
  heatColumns: string[],

  heatSource: HeatMapSourceModel[]
}


@Component({
  selector: 'heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
  animations: [inOutAnimation,
    listAnimation,
    trigger('smallAnimation', [
      transition(':enter', [
        style({ transform: 'scale(.00001)', maxHeight: '0rem' }),
        animate('.2s ease-in', style({ transform: 'scale(1)', maxHeight: '4rem' })),
      ]),
      transition(':leave', [
        style({
          transform: 'scale(1)',
          opacity: 1,
          maxHeight: '4rem'
        }),
        animate(
          '.2s ease-in',
          style({
            transform: 'scale(.0001)', maxHeight: '0rem'
          })
        ),
      ]),
    ]),
    trigger('heatAnimation', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ transform: 'translateY(-400px)', opacity: 0 }),
            stagger('100ms', animate('600ms ease-out', style({ transform: 'translateY(0px)', opacity: 1 }))),
          ],
          { optional: true }
        ),
        query(':leave', animate('200ms', style({ transform: 'translateY(400px)', opacity: 0 })), {
          optional: true,
        }),
      ]),
    ])
  ],
})
export class HeatmapComponent implements OnInit {
  @Input() filterHeatMap;
  heatColumns = [];
  @Input('heatColumns') set columns(hColumns) {
    console.log(hColumns);

    this.heatColumns = [];
    setTimeout(() => {
      if (hColumns.length) {
        for (let index = 0; index < hColumns.length; index++) {
          this.heatColumns.push({ field: 'field' + index, name: hColumns[index] })
        }
      }
    }, 100);
  };
  @Input() heatSource;
  @Input() language;
  @Output() filter = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }


  toFixed(number, digits) {
    return parseFloat(number).toFixed(digits);
  }
  heatColor(value) {
    if (value <= -8)
      return "darkred";
    if (value > -8 && value <= -4)
      return "red";
    if (value > -4 && value <= -1)
      return "lightred";
    if (value > -1 && value < 1)
      return "white";
    if (value >= 1 && value <= 4)
      return "lightblue";
    if (value > 4 && value < 8)
      return "blue";
    if (value >= 8)
      return "darkblue";
  }

  getRows(columns: any[]) {
    return columns?.map((x) => x['field']);
  }

  firstTwoDrivers(element: any, isDemo) {
    let selected = this.heatSource?.filteredData?.filter(x => x.isDriver).slice(0, 3) ||  this.heatSource.filter(x => x.isDriver).slice(0, 3);
    if (!isDemo || (selected.find(x=> x.key == element.key) && isDemo))
      this.filter.emit(element.key)

  }
}
