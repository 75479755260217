import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {
  constructor() { }
  transform(language) {
    if (language == 'AR' || language == 'UR')
      return true;
    else return false;
  }

}
