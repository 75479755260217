import { animate, query, stagger, style, transition, trigger } from "@angular/animations";
import { ChildrenAnimation, inOutAnimation, listAnimation, slidesAniamtion } from "src/app/shared/animations/allAnimations";

const animations = [
    inOutAnimation,
    slidesAniamtion,
    listAnimation,
    ChildrenAnimation,
    trigger('smallAnimation', [
        transition(':enter', [
            style({ transform: 'scale(.00001)', maxHeight: '0rem' }),
            animate(
                '.2s ease-in',
                style({ transform: 'scale(1)', maxHeight: '4rem' })
            ),
        ]),
        transition(':leave', [
            style({
                transform: 'scale(1)',
                opacity: 1,
                maxHeight: '4rem',
            }),
            animate(
                '.2s ease-in',
                style({
                    transform: 'scale(.0001)',
                    maxHeight: '0rem',
                })
            ),
        ]),
    ]),
    trigger('heatAnimation', [
        transition('* <=> *', [
            query(
                ':enter',
                [
                    style({ transform: 'translateX(-100px)' }),
                    stagger(
                        '60ms',
                        animate('600ms ease-out', style({ transform: 'translateX(0px)' }))
                    ),
                ],
                { optional: true }
            ),
            query(
                ':leave',
                animate('200ms', style({ transform: 'translateX(100px)' })),
                {
                    optional: true,
                }
            ),
        ]),
    ]),
]

export default animations;