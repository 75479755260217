import { Component, Input, OnInit } from '@angular/core';
import { ModuleName } from '../../enums/ModuleName';

@Component({
  selector: 'report-footer',
  templateUrl: './report-footer.component.html',
  styleUrls: ['./report-footer.component.scss']
})
export class ReportFooterComponent implements OnInit {

  @Input()
  footerData: any = {};
  zenithrSmallLogoUrl: string;

  constructor() { }
  ModuleNames = ModuleName
  ngOnInit(): void {
    this.zenithrSmallLogoUrl = 'assets/img/logo.png';
  }
}
