import { Component, OnInit } from '@angular/core';
import { AnalysisBaseComponentComponent } from '../../ui/closed-survey/analysis-base-component/analysis-base-component.component';
import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';
import { SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { WellbeingScalesService } from '../../utils/wellbeing-scales.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { CommentCountDto, CommentsService } from '@ex/wellbeing/comments';
import { SectionDto } from '@ex/wellbeing/survey-templates';
import { listAnimation } from 'src/app/shared/animations/allAnimations';
import { CommentExcelConfig, CommentExcelDownloadService, ExcelComment, ExcelQuestion, ExcelSection } from 'src/app/core/services/comment-excel-download.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-comment-analysis',
  templateUrl: './comment-analysis.component.html',
  styleUrls: ['./comment-analysis.component.scss'],
  animations: [listAnimation]
})
export class CommentAnalysisComponent extends AnalysisBaseComponentComponent implements OnInit {

  constructor(
    public wellbeingScalesService: WellbeingScalesService
    , private analysisService: AnalyticsService
    , private commentsService: CommentsService
    , private commentsExcelDownloadService: CommentExcelDownloadService
  ) { super() }

  ngOnInit(): void {
    this.analysisService.filters$.pipe(switchMap(filters => {

      return this.commentsService.getSectionsCommentCountBySurveyKeyAndFilters(filters.survey.key, []).pipe(map(counts => ({ filters, counts })))
    })).subscribe(({ filters, counts }) => {
      this.counts = counts
      this.dimensions = filters.survey.dimensions.filter(x => this.getSectionCount(x.key));
      this.filters = filters;
      this.language = this.data.language;
    });
  }
  filters: AnalyticsFilter;
  language: string;
  counts: CommentCountDto;
  dimensions: SectionDto[];

  getSectionCount(key) {
    return this.counts.sectionCount.find(x => x.key == key)?.count ?? 0;
  }

  getQuestionCount(questionKey: string) {
    return this.counts?.questionCount.find(x => x.key == questionKey)?.count || 0;
  }


  downloadComments() {

    const commentsConfig = {
      filename: "comments",
      sections: [],
      skipSentiment: true
    } as CommentExcelConfig


    this.analysisService.filters$.pipe(
      map(filters => {
        const sections = filters.survey.dimensions
          .filter(x => this.getSectionCount(x.key))
          .map(section => {
            return {
              key: section.key,
              name: this.extractNameLanguage(section.name),
              questions: section.questions
                .filter(x => this.getQuestionCount(x.key))
                .map(question => {
                  return {
                    key: question.key,
                    comments: [],
                    totalComments: 0,
                    name: this.extractNameLanguage(question.name)
                  } as ExcelQuestion
                }),
            } as ExcelSection
          })

        return sections;
      }),
      switchMap(sections => {
;
        let questions = sections.flatMap(x => x.questions);
        let comments$ = questions.map(questions => {
          return this.commentsService.getListAsycByRequest({
            companyKey: this.filters.companyKey,
            surveyKey: this.filters.survey.key,
            tenantKey: this.filters.tenantKey,
            questionKey: questions.key,
            maxResultCount: 10,
            skipCount: 0,
            isAllResults: true,
            filters: [... this.analysisService.mapGlobalFilters(this.filters)]
          })
        })

        return combineLatest([...comments$]).pipe(
          map(results => ({ results, sections }))
        );
      })
    ).subscribe(({ results, sections }) => {

      sections.flatMap(x => x.questions).forEach((q, i) => {
        q.comments = results[i].items.map(c => {
          return {
            name: c.comment
          } as ExcelComment
        })
      })

      commentsConfig.sections = sections;
      console.log(commentsConfig);
      this.commentsExcelDownloadService.generate(commentsConfig);
    })

  }
}
