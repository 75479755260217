import { Direction } from "@angular/cdk/bidi";
import { Location } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { GeneralService } from "@ex/module/core/services/general.service";
import { LocalService } from "@ex/module/core/services/local.service";
import { ChildrenAnimation } from "@ex/module/shared/animations/allAnimations";
import { ModuleName } from "@ex/module/shared/enums/ModuleName";
import { environment } from "src/environments/environment";
import { Model } from "survey-angular";
import { localization } from "survey-creator-core";
import { CriteriasService } from "../../data-access/services/criterias.service";
import { IndustriesService } from "../../data-access/services/industries.service";
import { LanguageFilterService } from "../../data-access/services/language-filter.service";
import { SurveysService } from "../../data-access/services/surveys.service";
import { DepartmentsService } from "../../data-access/services/departments.service";
import { RespondentsService } from "../../data-access/services/respondents.service";
import { ModalComponent } from "@ex/module/shared/modal/modal.component";
import { take } from "rxjs/operators";
import { QuestionnaireService } from "@ex/survey-x/index";


@Component({
  selector: 'app-respondent-information-page',
  templateUrl: './respondent-information-page.component.html',
  styleUrls: ['./respondent-information-page.component.scss'],
  animations: [ChildrenAnimation]
})
export class RespondentInformationPageComponent extends ModalComponent implements OnInit {

  language = 'EN';
  ModuleNames = ModuleName;

  endPoint = environment.endPoint;
  survey: any = {};

  ar = "ar";
  en = "en";

  respondentName = "";
  respondentEmail = "";
  respondentJobTitle = "";
  respondentPhoneNumber = "";
  respondentCompanyName = "";
  selectedGender = "";
  birthDate!: Date;
  joiningDate!: Date;
  selectedNationality = "";
  selectedPreferredCountryOfWork = "";
  selectedEmploymentStatus = "";
  selectedDepartment = "";
  selectedReligion = "";
  selectedMaritalStatus = "";
  selectedJobType = "";
  selectedContractType = "";
  numberOfChildren!: number;

  tenant: any;
  tenantKey: any;
  tenantName: any;

  isName: boolean = false;
  isEmail: boolean = false;
  isJobTitle: boolean = false;
  isPhoneNumber: boolean = false;
  isCompanyName: boolean = false;
  isCompanySize: boolean = false;
  isCompanyIndustry: boolean = false;
  isRespondentPreferredCountryOfWork: boolean = false;
  isRespondentEmploymentStatus: boolean = false;
  isRespondentJoiningDate: boolean = false;
  isRespondentDepartment: boolean = false;
  isLocation: boolean = false;
  isGender: boolean = false;
  isBirthDate: boolean = false;
  isNationality: boolean = false;
  isPreferredCountryOfWork: boolean = false;
  isEmploymentStatus: boolean = false;
  isDepartment: boolean = false;
  isJoiningDate: boolean = false;
  isReligion: boolean = false;
  isMaritalStatus: boolean = false;
  isJobType: boolean = false;
  isContractType: boolean = false;
  isWelcomeMessage: boolean = false;

  form: any;

  sub: any;
  logoImage: any;

  age: any;

  isGccCountries: boolean = false;

  validateFields = false;

  localesList: any[] = [];

  selectedLocaleFilter = "";
  selectedLocalFilterInStorage: string | null = "";


  companySizeList: any[] = [{ key: "Less than 50", name: [{ key: "EN", name: "Less than 50" }, { key: "AR", name: "أقل من 50" }] }
    , { key: "50-100", name: [{ key: "EN", name: "50-100" }, { key: "AR", name: "100-50" }] }
    , { key: "101-500", name: [{ key: "EN", name: "101-500" }, { key: "AR", name: "500-101" }] }
    , { key: "501-1000", name: [{ key: "EN", name: "501-1000" }, { key: "AR", name: "1000-501" }] }
    , { key: "1000+", name: [{ key: "EN", name: "1000+" }, { key: "AR", name: "+1000" }] }];

  companyIndustryList: any[] = [];
  departmentList: any[] = [];

  genderList: any[] = [{ key: "male", name: [{ key: "EN", name: "Male" }, { key: "AR", name: "ذكر" }] }, { key: "female", name: [{ key: "EN", name: "Female" }, { key: "AR", name: "انثى" }] }];

  maritalStatusList: any[] = [{ key: "single", name: [{ key: "EN", name: "Single" }, { key: "AR", name: "أعزب" }] }, { key: "married", name: [{ key: "EN", name: "Married" }, { key: "AR", name: "متزوج" }] },
  { key: "divorced", name: [{ key: "EN", name: "Divorced" }, { key: "AR", name: "مطلق" }] }, { key: "widower", name: [{ key: "EN", name: "Widower" }, { key: "AR", name: "ارمل" }] }];

  jobTypeList: any[] = [{ key: "fullTime", name: [{ key: "EN", name: "Full-Time" }, { key: "AR", name: "دوام كامل" }] }, { key: "partTime", name: [{ key: "EN", name: "Part-Time" }, { key: "AR", name: "دوام جزئي" }] },
  { key: "freelance", name: [{ key: "EN", name: "Freelance" }, { key: "AR", name: "دوام حر" }] }];

  contractTypeList: any[] = [{ key: "hybrid", name: [{ key: "EN", name: "Hybrid" }, { key: "AR", name: "هجين" }] }, { key: "onSite", name: [{ key: "EN", name: "On-Site" }, { key: "AR", name: "في الموقع" }] },
  { key: "remotely", name: [{ key: "EN", name: "Remotely" }, { key: "AR", name: "عن بعد" }] }];

  religionList: any[] = [{ key: "islam", name: [{ key: "EN", name: "Islam" }, { key: "AR", name: "الإسلام" }] }, { key: "christianity", name: [{ key: "EN", name: "Christianity" }, { key: "AR", name: "المسيحية" }] }, { key: "buddhism", name: [{ key: "EN", name: "Buddhism" }, { key: "AR", name: "البوذية" }] }, { key: "hinduism", name: [{ key: "EN", name: "Hinduism" }, { key: "AR", name: "الهندوسية" }] },
  { key: "other", name: [{ key: "EN", name: "Other" }, { key: "AR", name: "اخرى" }] }]

  employmentStatusList: any[] = [{ key: "employed", name: [{ key: "EN", name: "Employed" }, { key: "AR", name: "موظف" }] }, { key: "currently Unemployed", name: [{ key: "EN", name: "Currently Unemployed" }, { key: "AR", name: "عاطل عن العمل" }] }]


  locationList: any = this.generalService.countries_new;

  selectedCompanySize!: "";
  selectedCompanyIndustry!: "";
  selectedLocation!: "";
  direction: Direction = 'ltr';

  enabledCustomCriterias: any[] = [];

  dynamicNgModels: any = {};


  logoURL !: string;


  constructor(private languageFilterService: LanguageFilterService, private client: HttpClient, private router: Router, private questionnaireService: QuestionnaireService,
    private surveysService: SurveysService, private route: ActivatedRoute, private generalService: GeneralService, private location: Location,
    private industriesService: IndustriesService, private respondentsService: RespondentsService, private sanitizer: DomSanitizer,
    private criteriasService: CriteriasService, private departmentsService: DepartmentsService, private localService: LocalService) { super(); }



  onSelectLanguage(locale: any) {
    console.log("onSelectLanguage", locale);
    this.languageFilterService.setSurveyLanguage(locale);
    window.localStorage.setItem("localeLanguage", locale);
    this.language = locale.toUpperCase();
  }

  ngOnInit() {

    let token = this.route.snapshot.paramMap.get('refKey');
    this.questionnaireService.getQuestionnaire(token).subscribe(survey => {
      this.sub = survey;
      let surv = new Model(survey.jsonText);

      let surveyLanguages = surv.getUsedLocales();

      surveyLanguages.forEach((element) => {
        if (element == 'en') {
          this.localesList.push({ key: this.en, name: 'English' });
        } else {
          this.localesList.push({
            key: element,
            name: localization.getLocaleName(element),
          });
        }
      });

      this.isWelcomeMessage = this.sub.isWelcomeMessage;
      this.isGccCountries = this.sub.isGccCountries;
      this.tenantKey = this.sub.tenantKey;
      this.tenant = this.sub.tenant;
      this.tenantName = this.sub.tenantName;
    })
    // this.form = await this.surveysService.getSurveyWithoutResponses(surveyId);

    // this.sub = this.form.query;


    // let surv = new Model(this.sub.jsonText);

    // let surveyLanguages = surv.getUsedLocales();

    // surveyLanguages.forEach((element) => {
    //   if (element == 'en') {
    //     this.localesList.push({ key: this.en, name: 'English' });
    //   } else {
    //     this.localesList.push({
    //       key: element,
    //       name: localization.getLocaleName(element),
    //     });
    //   }
    // });



    // this.isName = this.sub.isRespondentName;
    // this.isEmail = this.sub.isEmail;
    // this.isJobTitle = this.sub.isJobTitle;
    // this.isPhoneNumber = this.sub.isRespondentPhoneNumber;
    // this.isCompanyName = this.sub.isRespondentCompanyName;
    // this.isCompanySize = this.sub.isRespondentCompanySize;
    // this.isPreferredCountryOfWork = this.sub.isRespondentPreferredCountryOfWork;
    // this.isEmploymentStatus = this.sub.isRespondentEmploymentStatus;
    // this.isJoiningDate = this.sub.isRespondentJoiningDate;
    // this.isDepartment = this.sub.isRespondentDepartment;
    // this.isCompanyIndustry = this.sub.isRespondentCompanyIndustry;
    // this.isLocation = this.sub.isRespondentLocation;
    // this.isGender = this.sub.isRespondentGender;
    // this.isBirthDate = this.sub.isRespondentBirthDate;
    // this.isNationality = this.sub.isRespondentNationality;
    // this.isReligion = this.sub.isRespondentReligion;
    // this.isMaritalStatus = this.sub.isRespondentMaritalStatus;
    // this.isJobType = this.sub.isRespondentJobType;
    // this.isContractType = this.sub.isRespondentContractType;
    // this.isWelcomeMessage = this.sub.isWelcomeMessage;
    // this.isGccCountries = this.sub.isGccCountries;





    // this.tenantKey = this.sub.tenantKey;
    // this.tenant = this.sub.tenant;
    // this.tenantName = this.sub.tenantName;




    // (await this.criteriasService.getAllCriteriasInFormByTenantAndForm(this.tenantKey, surveyId)).subscribe((response: any) => {


    //   response.forEach(element => {
    //     element.name = JSON.parse(element.name).find(x => x.key == this.language).name;

    //     this.enabledCustomCriterias.push(element);

    //   });
    //   console.log("Enabled Criterias", this.enabledCustomCriterias)
    // })

    // console.log("Enabled Custom Criterias", this.enabledCustomCriterias)



    // if (this.sub.logo) {
    //   this.logoImage = this.sanitizer.bypassSecurityTrustUrl(this.sub.logo);
    // }

    // if (this.isGccCountries) {
    //   this.locationList = this.generalService.gccCountries_new;
    // }



    // this.languageFilterService.selectedSurveyLanguage.subscribe((value) => {

    //   this.selectedLocaleFilter = value;
    //   this.selectedLocalFilterInStorage = window.localStorage.getItem("localeLanguage");

    //   if (this.selectedLocaleFilter == this.en || this.selectedLocalFilterInStorage == this.en) {
    //     this.language = "EN"
    //   }
    //   if (this.selectedLocaleFilter == this.ar || this.selectedLocalFilterInStorage == this.ar) {

    //     this.direction = "rtl";
    //     this.language = "AR"
    //   }
    //   else {
    //     this.direction = "ltr";
    //   }
    // })


    // this.industriesService.getAllIndustries().subscribe((value: any) => {
    //   value.forEach((element: any) => {

    //     this.companyIndustryList.push({
    //       key: element.key,
    //       name: element.name
    //     })

    //   })
    // });

    // this.departmentsService.getAllDepartments().subscribe((value: any) => {
    //   value.forEach((element: any) => {

    //     this.departmentList.push({
    //       key: element.key,
    //       name: element.name
    //     })
    //   })
    // });

  }


  onValueChanged(criteriaName: string, newValue: any) {
    // You can access the criteria name and value here
    this.dynamicNgModels[criteriaName] = newValue;
    console.log(`Value changed for ${criteriaName}: ${newValue}`);

    // Send the dynamicNgModels object to the backend as needed
    this.sendDataToBackend(this.dynamicNgModels);
    console.log("Dynamic ng models: ", this.dynamicNgModels);

  }

  sendDataToBackend(data: any) {
    // Implement your backend communication logic here
    // You can use HttpClient to send data to the backend
  }


  onCompanySizeChanged(selectedValue: any) {
    this.selectedCompanySize = selectedValue;


  }

  onCompanyIndustryChanged(selectedValue: any) {
    this.selectedCompanyIndustry = selectedValue;


  }
  onLocationChanged(selectedValue: any) {
    this.selectedLocation = selectedValue;


  }
  onNationalityChanged(selectedValue: any) {
    this.selectedNationality = selectedValue;


  }
  onReligionChanged(selectedValue: any) {
    this.selectedReligion = selectedValue;


  }
  onMaritalStatusChanged(selectedValue: any) {
    this.selectedMaritalStatus = selectedValue;


  }
  onJobTypeChanged(selectedValue: any) {
    this.selectedJobType = selectedValue;


  }
  onContractTypeChanged(selectedValue: any) {
    this.selectedContractType = selectedValue;


  }
  onPreferredCountryOfWorkChanged(selectedValue: any) {
    this.selectedPreferredCountryOfWork = selectedValue;
  }
  onEmploymentStatusChanged(selectedValue: any) {
    this.selectedEmploymentStatus = selectedValue;
  }
  onDepartmentChanged(selectedValue: any) {
    this.selectedDepartment = selectedValue;
  }
  onGenderChanged(selectedValue: any) {
    this.selectedGender = selectedValue;
  }


  async onNext() {

    if ((this.isName && this.respondentName == null) || (this.isName && this.respondentName == "") || (this.isName && this.respondentName == undefined)) {
      this.validateFields = true;
      return
    }
    if ((this.isEmail && this.respondentEmail == null) || (this.isEmail && this.respondentEmail == "") || (this.isEmail && this.respondentEmail == undefined)) {
      this.validateFields = true;
      return
    }
    if ((this.isPhoneNumber && this.respondentPhoneNumber == null) || (this.isPhoneNumber && this.respondentPhoneNumber == "") || (this.isPhoneNumber && this.respondentPhoneNumber == undefined)) {
      this.validateFields = true;
      return
    }
    if ((this.isLocation && this.selectedLocation == undefined) || (this.isLocation && this.selectedLocation == "") || (this.isLocation && this.selectedLocation == null)) {
      this.validateFields = true;
      return
    }
    if ((this.isCompanyIndustry && this.selectedCompanyIndustry == undefined && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isCompanyIndustry && this.selectedCompanyIndustry == null && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isCompanyIndustry && this.selectedCompanyIndustry == "" && this.selectedEmploymentStatus !== "currently Unemployed")) {
      this.validateFields = true;
      return
    }
    if ((this.isGender && this.selectedGender == "") || (this.isGender && this.selectedGender == null) || (this.isGender && this.selectedGender == undefined)) {
      this.validateFields = true;
      return
    }
    if ((this.isBirthDate && this.birthDate == undefined) || (this.isBirthDate && this.birthDate == null)) {
      this.validateFields = true;
      return
    }
    if ((this.isJoiningDate && this.joiningDate == undefined && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isJoiningDate && this.joiningDate == null && this.selectedEmploymentStatus !== "currently Unemployed")) {
      this.validateFields = true;
      return
    }
    if ((this.isNationality && this.selectedNationality == "") || (this.isNationality && this.selectedNationality == null)) {
      this.validateFields = true;
      return
    }
    if ((this.isPreferredCountryOfWork && this.selectedPreferredCountryOfWork == "") || (this.isPreferredCountryOfWork && this.selectedPreferredCountryOfWork == null)) {
      this.validateFields = true;
      return
    }
    if ((this.isDepartment && this.selectedDepartment == "" && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isDepartment && this.selectedDepartment == null && this.selectedEmploymentStatus !== "currently Unemployed")) {
      this.validateFields = true;
      return
    }
    if ((this.isEmploymentStatus && this.selectedEmploymentStatus == "") || (this.isEmploymentStatus && this.selectedEmploymentStatus == null)) {
      this.validateFields = true;
      return
    }
    if ((this.isReligion && this.selectedReligion == undefined) || (this.isReligion && this.selectedReligion == null) || (this.isReligion && this.selectedReligion == "")) {
      this.validateFields = true;
      return
    }
    if ((this.isMaritalStatus && this.selectedMaritalStatus == undefined) || (this.isMaritalStatus && this.selectedMaritalStatus == null) || (this.isMaritalStatus && this.selectedMaritalStatus == "")) {
      this.validateFields = true;
      return
    }
    if ((this.isJobType && this.selectedJobType == undefined && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isJobType && this.selectedJobType == null && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isJobType && this.selectedJobType == "" && this.selectedEmploymentStatus !== "currently Unemployed")) {
      this.validateFields = true;
      return
    }
    if ((this.isContractType && this.selectedContractType == undefined && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isContractType && this.selectedContractType == null && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isContractType && this.selectedContractType == "" && this.selectedEmploymentStatus !== "currently Unemployed")) {
      this.validateFields = true;
      return
    }
    if ((this.isJobTitle && this.respondentJobTitle == null && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isJobTitle && this.respondentJobTitle == "" && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isJobTitle && this.respondentJobTitle == undefined && this.selectedEmploymentStatus !== "currently Unemployed")) {
      this.validateFields = true;
      return
    }
    if ((this.isCompanySize && this.selectedCompanySize == undefined && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isCompanySize && this.selectedCompanySize == "" && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isCompanySize && this.selectedCompanySize == null && this.selectedEmploymentStatus !== "currently Unemployed")) {
      this.validateFields = true;
      return
    }
    if ((this.isCompanyName && this.respondentCompanyName == null && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isCompanyName && this.respondentCompanyName == "" && this.selectedEmploymentStatus !== "currently Unemployed") || (this.isCompanyName && this.respondentCompanyName == undefined && this.selectedEmploymentStatus !== "currently Unemployed")) {
      this.validateFields = true;
      return
    }

    if (this.birthDate) {

      var timeDiff = Math.abs(Date.now() - this.birthDate.getTime());
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    }


    // if(this.respondentName == null || this.respondentEmail == null || this.respondentPhoneNumber == null || this.selectedLocation == undefined || this.selectedCompanyIndustry == undefined)
    // {
    //  this.validateFields = true;
    //   return
    // }




    let surveyId = this.route.snapshot.paramMap.get('id');


    var respondentDetails = {
      tenantKey: this.tenantKey, tenantName: this.tenantName, name: this.respondentName, email: this.respondentEmail,
      companyName: this.respondentCompanyName, companyIndustry: this.selectedCompanyIndustry, companySize: this.selectedCompanySize,
      age: this.age, location: this.selectedLocation, jobTitle: this.respondentJobTitle, phoneNumber: this.respondentPhoneNumber,
      gender: this.selectedGender, nationality: this.selectedNationality, religion: this.selectedReligion, maritalStatus: this.selectedMaritalStatus,
      jobType: this.selectedJobType, contractType: this.selectedContractType, birthDate: this.birthDate, formId: surveyId,
      preferredCountryOfWork: this.selectedPreferredCountryOfWork, employmentStatus: this.selectedEmploymentStatus, joiningDate: this.joiningDate,
      department: this.selectedDepartment
    }


    var surveyForm = {
      formId: surveyId, respondentName: this.respondentName, respondentEmail: this.respondentEmail,
      respondentJobTitle: this.respondentJobTitle, respondentPhoneNumber: this.respondentPhoneNumber, respondentCompanyName: this.respondentCompanyName,
      respondentCompanySize: this.selectedCompanySize, respondentCompanyIndustry: this.selectedCompanyIndustry, respondentLocation: this.selectedLocation,
      tenantKey: this.tenantKey, tenantName: this.tenantName
    };

    await this.respondentsService.createRespondent(respondentDetails);


    this.client.post<any>(this.endPoint + "form-response/add-response", surveyForm).pipe(take(1)).subscribe(sub => {

      console.log(sub)




      let responseId = sub.id;


      if (surveyId != null && responseId != null && !this.isWelcomeMessage) {
        this.router.navigate(["survey-x/survey/" + surveyId + "/" + responseId]);
      }
      if (surveyId != null && responseId != null && this.isWelcomeMessage) {
        this.router.navigate(["survey-x/startpage/" + surveyId + "/" + responseId]);
      }
    });


  }
  async onBack() {
    (this.location as any).back();
  }
}


