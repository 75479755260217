<div (click)="close()" [@inOutAnimation] *ngIf="open" [style.direction]="isRtl" class="w-screen h-screen bg-zen bg-opacity-50 flex flex-row items-center justify-center fixed top-0 left-0 zIndex-max py-10 px-5">
    <div (click)="$event.stopPropagation()" [ngClass]="{'max-w-5xl w-full':!autoWidth}" class=" flex flex-col bg-white bg-opacity-95 py-10 rounded max-h-screen-90">
        <div #header [hidden]="header.innerHTML.length == 0" class="flex flex-row items-center justify-between px-10 pb-5 gap-10">
            <ng-content  select="[header]"></ng-content>
        </div>
        <div #body [hidden]="body.innerHTML.length == 0" class="flex flex-col items-stretch gap-1 px-10 py-10 innerListScroll  min-w-80">
            <ng-content select="[body]"></ng-content>
        </div>
        <div #footer [hidden]="footer.innerHTML.length == 0" class="flex flex-row justify-between items-center px-10 pt-5">
            <ng-content  select="[footer]"></ng-content>
        </div>
    </div>
</div>

