<div class="flex  flex-col gap-4">
    <!-- <div class="flex flex-col gap-6 bg-white p-6 rounded-lg	">
        <h4 class="text-zen text-lg font-medium ">{{ "txt_DriverCom_666" | translate:language:ModuleNames.Engagement }}
        </h4>
        <div class="flex flex-col">
            <div class="flex-row flex w-full rounded  justify-between p-5 bg-white">
                <div *ngFor="let setiment of sentimentAnalysislst"
                    class="items-center flex basis-auto grow-0 shrink-0 justify-center text-xs">
                    <i class="mx-1.5 h-4 w-4 inline-block rounded-full"
                        [ngStyle]="{'background-color': getLegendColor(setiment.key)}">
                    </i>
                    <h5>{{setiment.name|titlecase}} </h5>
                </div>
            </div>
            <kendo-chart *ngIf="!reloadBBle" (seriesClick)="onBubbleClick($event)" class="h-99">
                <kendo-chart-axis-defaults [line]="{visible: false}"
                    [majorGridLines]="{color: '#ECECEC',step:2,skip:1}"></kendo-chart-axis-defaults>
                <kendo-chart-y-axis>
                    <kendo-chart-y-axis-item max="105" min="0" [labels]="{visible: false}">
                    </kendo-chart-y-axis-item>
                </kendo-chart-y-axis>
                <kendo-chart-x-axis>
                    <kendo-chart-x-axis-item max="105" min="0" [labels]="{visible: false}">
                    </kendo-chart-x-axis-item>
                </kendo-chart-x-axis>
                <kendo-chart-legend [visible]="false">
                </kendo-chart-legend>
                <kendo-chart-series>
                    <kendo-chart-series-item *ngFor="let group of groupedData" [data]="group.data" [name]="group.name"
                        type="bubble" [xField]="'x'" [yField]="'y'" [sizeField]="'size'" [opacity]="0.7"
                        [marker]="{ type: 'circle' }" [color]="group.color" [opacity]="10">
                        <kendo-chart-series-item-labels [content]="labelContent">
                        </kendo-chart-series-item-labels>
                        <kendo-chart-series-item-tooltip background="white" [border]="{color:white,width:0}">
                            <ng-template let-value="value" let-category="category">
                                <div class="flex flex-col w-42 p-2 gap-2">
                                    <div class="flex flex-col gap-1">
                                        <h2 class="text-zen text-xs font-medium leading-4.5 h-4.5 text-center">
                                            {{category.key |titlecase}}</h2>
                                        <div
                                            class="flex flex-row text-zen text-xxs h-4.5 text-center gap-1 justify-center">
                                            <span class="font-medium">{{category.mentionCount}}</span>
                                            <span class="text-opacity-40 leading-3">{{'txtComments2'|
                                                translate:language:ModuleNames.Engagement}}</span>
                                        </div>
                                    </div>
                                    <div class="flex flex-col">
                                        <div class="flex flex-row h-4 bg-white w-full text-zen font-medium gap-px">
                                            <a *ngIf="category.positiveMentionPerc > 0"
                                                [style.width.%]="category.positiveMentionPerc"
                                                class="btn bg-zen-lmngreen  leading-4 h-4 text-xxs items-center text-center"
                                                [ngClass]="getChartPositiveClass(category)">
                                                {{category.positiveMentionPerc}}%</a>
                                            <a *ngIf="category.negativeMentionPerc > 0"
                                                [style.width.%]="category.negativeMentionPerc"
                                                class="btn bg-zen-watermelon  leading-4 h-4 text-xxs items-center text-center"
                                                [ngClass]="getChartNegativeClass(category)">
                                                {{category.negativeMentionPerc}}% </a>
                                            <a *ngIf="category.neutralMentionPerc > 0"
                                                [style.width.%]="category.neutralMentionPerc"
                                                class="btn bg-zen-titaniumwhite  leading-4 h-4 text-xxs items-center text-center"
                                                [ngClass]="getChartNeutralClass(category)">
                                                {{category.neutralMentionPerc}}%</a>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                </kendo-chart-series>
            </kendo-chart>
        </div>

    </div> -->
    <div class="flex flex-col gap-4">
        <ng-container *ngFor="let section of sections; let i = index">
            <!-- <div *ngIf="driver.totalComments > 0" class="flex flex-row flex-wrap items-center bg-white shadow-sm">
                <div class="flex flex-row justify-between items-center w-full px-8  cursor-pointer btn"
                    (click)="showComment == i? showComment = -1 : showComment = i">
                    <div class="flex flex-col gap-3 py-4">
                        <h4 *ngIf="!topicSelected" class="text-zen text-lg leading-6 font-medium uppercase">
                            {{extractNameLanguage(driver.name)}}</h4>
                        <div *ngIf="topicSelected" class="flex flex-row gap-4">
                            <z-select [ngModel]="selectedTopic" (ngModelChange)="OnselectedTopicChange($event)"
                                color="exp" [options]="topics" [language]="language">
                                <ng-template #valueTemplate let-value>
                                    {{value?.name |extract:language |titlecase}}
                                </ng-template>
                                <ng-template #optionTemplate let-option>
                                    {{option?.name|extract:language |titlecase}}
                                </ng-template>
                            </z-select>
                            <div class="flex flex-col justify-center cursor-pointer">
                                <i (click)="stopPropagation($event);removeTopicsFilter()"
                                    [matTooltip]="'txt_RemoveFil_850' | translate:language:ModuleNames.Engagement"
                                    matTooltipClass="sentiment-tooltip"
                                    class="fal fa-times text-xl font-medium btn min-w-max text-center  text-zen-red"></i>
                            </div>
                        </div>

                        <span class="text-zen text-2.5xs leading-3.5 uppercase">
                            {{driver.commentsPercentage}}%{{' - '}}
                            {{generalService.numberWithCommas(driver.totalComments)}}
                            {{'txtComments2'| translate:language:ModuleNames.Engagement}}
                        </span>
                    </div>
                    <div class="flex flex-row justify-end items-center gap-6">
                        <div class="flex flex-row items-center py-4" (click)="stopPropagation($event);">
                            <div class="flex flex-col w-1/12" *ngIf="driver['activeFilter']">
                                <i (click)="removeFilterDriver(driver)"
                                    [matTooltip]="'txt_RemoveFil_850' | translate:language:ModuleNames.Engagement"
                                    matTooltipClass="sentiment-tooltip"
                                    class="fal fa-times text-sm btn w-3 h-3 min-w-max text-center  text-zen-red leading-3 "></i>
                            </div>
                            <div *ngIf="!driver.sentimentFetching"
                                class="flex flex-row h-4 bg-white w-42 text-zen font-medium gap-px">
                                <a *ngIf="driver.positive > 0"
                                    class="btn bg-zen-lmngreen  leading-4 h-4 text-xxs items-center text-center"
                                    [ngClass]="[driver['activeFilter'] ?(driver['selectedSentiment'] == SentimentAnalysis.positive)?'opacity-100':'opacity-50' :'opacity-100',getPositiveClass(driver)]"
                                    [style.width.%]="driver.positive"
                                    [matTooltip]="SentimentAnalysis.positive + '&nbsp; &nbsp;&nbsp;' + driver.positiveCount | titlecase"
                                    matTooltipClass="sentiment-tooltip"
                                    (click)="filterDriverComments(SentimentAnalysis.positive,driver);showComment != i? showComment = i : ''">
                                    {{driver.positive}}%</a>
                                <a *ngIf="driver.negative > 0"
                                    class="btn bg-zen-watermelon  leading-4 h-4 text-xxs items-center text-center"
                                    [ngClass]="[driver['activeFilter'] ?(driver['selectedSentiment'] == SentimentAnalysis.negative)?'opacity-100':'opacity-50' :'opacity-100',getNegativeClass(driver)]"
                                    [style.width.%]="driver.negative"
                                    [matTooltip]="SentimentAnalysis.negative + '&nbsp; &nbsp;&nbsp;' + driver.negativeCount  | titlecase"
                                    matTooltipClass="sentiment-tooltip"
                                    (click)="filterDriverComments(SentimentAnalysis.negative,driver);showComment != i? showComment = i : ''">
                                    {{driver.negative}}% </a>
                                <a *ngIf="driver.neutral > 0"
                                    class="btn bg-zen-titaniumwhite  leading-4 h-4 text-xxs items-center text-center"
                                    [ngClass]="[driver['activeFilter'] ?(driver['selectedSentiment'] == SentimentAnalysis.neutral)?'opacity-100':'opacity-50' :'opacity-100',getNeutralClass(driver)]"
                                    [style.width.%]="driver.neutral"
                                    [matTooltip]="SentimentAnalysis.neutral +  '&nbsp; &nbsp;&nbsp;' + driver.neutralCount | titlecase"
                                    matTooltipClass="sentiment-tooltip"
                                    (click)="filterDriverComments(SentimentAnalysis.neutral,driver);showComment != i? showComment = i : ''">
                                    {{driver.neutral}}%</a>
                            </div>
                        </div>
                        <div class="flex flex-col w-4">
                            <i class="leading-4	text-base text-zen"
                                [class]="'fa ' + (showComment == i ? 'fa-angle-up' : 'fa-angle-down')"></i>
                        </div>
                    </div>
                </div>
                <div [@listAnimation]="driver.questionComments.length"
                    *ngIf="showComment == i && getCommentslength(driver?.questionComments) > 0"
                    class="flex flex-col w-full bg-zen bg-opacity-5">
                    <ng-container *ngFor="let questionComment of driver.questionComments">
                        <div class="flex flex-col bg-zen bg-opacity-5" *ngIf="questionComment?.comments?.length >0">
                            <div class="flex flex-row justify-between h-12 px-16 items-center">
                                <div class="flex flex-row text-zen-lite text-sm font-medium px-4 leading-5 gap-2">
                                    <h4 class="text-zen" *ngIf="topicSelected">
                                        {{extractNameLanguage(questionComment.driverName)+ ' :'}}</h4>
                                    <h4> {{extractNameLanguage(questionComment.name)}}</h4>
                                </div>
                                <div class="flex flex-col" [ngClass]="rtl?'pl-2':'pr-2'">
                                    <div class="flex flex-row items-center"
                                        [ngClass]="driver['activeFilter'] ? 'pointer-events-none' : ''">
                                        <div class="flex flex-col w-1/12"
                                            *ngIf="questionComment['activeFilter'] && !driver['activeFilter']">
                                            <i (click)="removeQuestionFilter(questionComment)"
                                                [matTooltip]="'txt_RemoveFil_850' | translate:language:ModuleNames.Engagement"
                                                matTooltipClass="sentiment-tooltip"
                                                class="fal fa-times text-sm btn w-3 h-3 min-w-max text-center  text-zen-red leading-3 "></i>
                                        </div>
                                        <div *ngIf="!questionComment.sentimentFetching"
                                            class="flex flex-row h-4 bg-white w-42 text-zen font-medium gap-px">
                                            <a *ngIf="questionComment.positive > 0"
                                                class="btn bg-zen-lmngreen  leading-4 h-4 text-xxs items-center text-center"
                                                [ngClass]="[questionComment['activeFilter'] ?(questionComment['selectedSentiment'] == SentimentAnalysis.positive)?'opacity-100':'opacity-50' :'opacity-100', getPositiveClass(questionComment)]"
                                                [style.width.%]="questionComment.positive"
                                                [matTooltip]="SentimentAnalysis.positive + '&nbsp; &nbsp;&nbsp;' + questionComment.positiveCount | titlecase"
                                                matTooltipClass="sentiment-tooltip"
                                                (click)="filterQuestionComments(SentimentAnalysis.positive,questionComment)">
                                                {{questionComment.positive}}%</a>
                                            <a *ngIf="questionComment.negative > 0"
                                                class="btn bg-zen-watermelon  leading-4 h-4 text-xxs items-center text-center"
                                                [ngClass]="[questionComment['activeFilter'] ?(questionComment['selectedSentiment'] == SentimentAnalysis.negative)?'opacity-100':'opacity-50' :'opacity-100',getNegativeClass(questionComment)]"
                                                [style.width.%]="questionComment.negative"
                                                [matTooltip]="SentimentAnalysis.negative + '&nbsp; &nbsp;&nbsp;' + questionComment.negativeCount  | titlecase"
                                                matTooltipClass="sentiment-tooltip"
                                                (click)="filterQuestionComments(SentimentAnalysis.negative,questionComment)">
                                                {{questionComment.negative}}% </a>
                                            <a *ngIf="questionComment.neutral > 0"
                                                class="btn bg-zen-titaniumwhite  leading-4 h-4 text-xxs items-center text-center"
                                                [ngClass]="[questionComment['activeFilter'] ?(questionComment['selectedSentiment'] == SentimentAnalysis.neutral)?'opacity-100':'opacity-50' :'opacity-100',getNeutralClass(questionComment)]"
                                                [style.width.%]="questionComment.neutral"
                                                [matTooltip]="SentimentAnalysis.neutral +  '&nbsp; &nbsp;&nbsp;' + questionComment.neutralCount | titlecase"
                                                matTooltipClass="sentiment-tooltip"
                                                (click)="filterQuestionComments(SentimentAnalysis.neutral,questionComment)">
                                                {{questionComment.neutral}}%</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div [@listAnimation]="questionComment?.comments?.length"
                                class="flex flex-col divide-y divide-zen-paleWhite px-16"
                                (click)="logData(questionComment)">
                                <ng-container *ngFor="let comment of questionComment?.comments; let j = index">
                                    <app-engagement-comment (recalculateDriver)="calculateDriverSentiment($event)"
                                        [essentialObject]="essentialObject" [driver]="driver"
                                        [selectedEntity]="selectedEntity" (topicChanged)="topicChanged.emit()"
                                        [topics]="topics" [language]="language" [surveyType]="surveyType"
                                        [engagementAnalysis]="engagementAnalysis" [comment]="comment"
                                        [questionComment]="questionComment"> </app-engagement-comment>
                                </ng-container>
                                <a class="leading-10 h-10 flex flex-col overflow-hidden justify-center items-center text-zen font-medium btn cancel rounded w-full text-center"
                                    *ngIf="questionComment.comments.length < questionComment.totalComments"
                                    (click)="loadMoreComments(questionComment)"
                                    [ngClass]="{'pointer-events-none opacity-70':questionComment.localFetching}">
                                    <lottie-player *ngIf="questionComment.localFetching"
                                        src="/assets/lottie/s-loader.json" background="transparent" speed="1"
                                        style="width: 12rem; height: 12rem;" loop autoplay="false">
                                    </lottie-player>
                                    <h4 class="absolute" *ngIf="!questionComment.localFetching">
                                        <span class=""> Load More Comments</span>
                                    </h4>
                                </a>
                            </div>
                        </div>
                    </ng-container>
                    <div class="flex flex-col bg-zen bg-opacity-5 h-4"></div>
                </div>
            </div> -->
        </ng-container>
    </div>
</div>