export class EngagementRespondent {
  ID: number;
  key: string;
  employeeID: string;
  status: string;
  actualStatus: string;
phoneNumber: string;
  name: string;
  email: string;
  referenceKey: string;
  tenantKey: string;
  companyKey: string;
  evaluatorEvaluatorsMerged = false;
  isClosed: boolean = false;
  engagementKey: string;
  createdBy: string;
  Metrics: any[] = [];
  respondentType: string;
  candidateKey: string;
  candidateName: string;
  candidate: EngagementRespondent;
  evaluatorType: string;
  evaluatorLimit: number = 20;
  engagementScale: string;
  markedAs: string = 'RANDOM';
  employeeId: string;
}
