<div  class="flex flex-col gap-5" (click)="logData(question)">
    <div class="flex flex-row w-full gap-1 items-stretch">
        <div class="flex flex-row items-center justify-center w-1/5">
            <div [ngClass]="question.answer == '0'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="question.answer = '0'"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                <i class="fa-light fa-ban text-4xl md:text-5xl btn leading-18 rounded-lg w-full text-center"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-confidence-with-na', '0')}}</h5>
            </div>
        </div>
        <div class="flex flex-row items-center justify-center w-1/5">
            <div [ngClass]="question.answer == '1'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="question.answer = '1'"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                <i
                    class="fa-light fa-face-pouting text-4xl md:text-5xl btn leading-18 rounded-lg w-full text-center"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-confidence-with-na', '1')}}</h5>
            </div>
        </div>

        <div class="flex flex-row items-center justify-center w-1/5">
            <div [ngClass]="question.answer == '2'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="question.answer = '2'"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                <i
                    class="fa-light fa-face-worried text-4xl md:text-5xl btn leading-18 rounded-lg w-full text-center"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-confidence-with-na', '2')}}</h5>
            </div>
        </div>
        <div class="flex flex-row items-center justify-center w-1/5">
            <div [ngClass]="question.answer == '3'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="question.answer = '3'"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                <i
                    class="fa-light fa-meh text-4xl md:text-5xl btn leading-18 rounded-lg w-full text-center"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-confidence-with-na', '3')}}</h5>
            </div>
        </div>
        <div class="flex flex-row items-center justify-center w-1/5">
            <div [ngClass]="question.answer == '4'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="question.answer = '4'"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                <i
                    class="fa-light  text-4xl md:text-5xl btn leading-18 rounded-lg w-full text-center fa-face-smile-relaxed"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-confidence-with-na','4')}}</h5>
            </div>
        </div>
        <div class="flex flex-row items-center justify-center w-1/5">
            <div [ngClass]="question.answer == '5'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="question.answer = '5'"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                <i
                    class="fa-light  text-4xl md:text-5xl btn leading-18 rounded-lg w-full text-center fa-face-laugh-beam"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-confidence-with-na','5')}}</h5>
            </div>
        </div>

    </div>
    <text-question [question]="question" [language]="language" [comment]="true">
    </text-question>
</div>