import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import {
  QuestionTextModel,
  QuestionDropdownModel,
  QuestionRatingModel,
  QuestionCommentModel,
  QuestionRankingModel,
  QuestionImagePickerModel,
  QuestionBooleanModel,
  QuestionFileModel,
  QuestionMatrixModel,
  QuestionCheckboxModel
} from "survey-core";
import { CreatorBase } from "survey-creator-core";
import { PageModel } from 'survey-core';
import { AfterRenderQuestionEvent } from 'survey-core/typings/survey-events-api';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { ZenMenuComponent } from '@ex/module/shared/Theme/controls/zen-menu/zen-menu.component';
import { ZenSnackbarService, ZenSnackBarTypes } from '@ex/module/shared/Theme/services/zen-snackbar.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { SharedService } from '@ex/module/survey-x/data-access/services/shared-service.service';
import { QuestionDatePickerModel } from '../../custom-datepicker/custom-datepicker.component';

@Component({
  selector: 'app-questions-sidebar',
  templateUrl: './questions-sidebar.component.html',
  styleUrls: ['./questions-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionsSidebarComponent implements OnInit {

  @Input() model!: CreatorBase;
  @Input() companyName: string;
  @Input() language: string = "EN";
  ModuleNames = ModuleName;

  isAddQuestionModalOpen: boolean = false;
  searchText = "";

  questionModels = {
    'short-text': QuestionTextModel,
    'long-text': QuestionCommentModel,
    'multiple-choices': QuestionCheckboxModel,
    'dropdown': QuestionDropdownModel,
    'start-rating': QuestionRatingModel,
    'rating-scale': QuestionRatingModel,
    'enps': QuestionRatingModel,
    'nps': QuestionRatingModel,
    'number-input': QuestionTextModel,
    'ranking': QuestionRankingModel,
    'image-choice': QuestionImagePickerModel,
    'icon-choice': QuestionImagePickerModel,
    'binary': QuestionBooleanModel,
    'matrix': QuestionMatrixModel,
    'slider': QuestionTextModel,
    'email-input': QuestionTextModel,
    'file-upload': QuestionFileModel,
    'date-input': QuestionDatePickerModel,
  };

  constructor(private changeDetectorRef: ChangeDetectorRef, private translationPipe: TranslatePipe, private zenSnackBarService: ZenSnackbarService, private sharedService: SharedService) { }

  ngOnInit(): void {


    this.sharedService.getSelectedType.subscribe((type: any) => {
      this.showAddQuestionModal();
    })

    //Select the first question by default
    if (this.model.survey.pages[0] && this.model.survey.pages[0].elements[0])
      this.model.selectElement(this.model.survey.pages[0].elements[0]);
  }

  showAddQuestionModal() {
    this.isAddQuestionModalOpen = true;
    //  this.changeDetectorRef.detectChanges();
  }
  showAddQuestionModalWithIndex(index: any) {
    this.model.selectElement(this.model.survey.pages[index]);
    this.isAddQuestionModalOpen = true;
    // this.changeDetectorRef.detectChanges();
  }

  getQuestionTitle() {

    const title = this.translationPipe.transform("txtNew Question", this.language, this.ModuleNames.SurveyX)
    return title;
  }

  handleTypeSelected(type: string) {
    const QuestionModel = this.questionModels[type];

    if (QuestionModel) {
      const question = new QuestionModel("");
      question.title = this.getQuestionTitle();

      // make question required by default
      question.isRequired = true;

      // Set additional properties if needed
      if (type === 'start-rating')
        question.rateType = "stars";

      if (type === 'number-input' || type === 'email-input' || type === 'date-input')
        question.inputType = type.split('-')[0];

      if (type === 'ranking' || type === 'multiple-choices' || type === 'dropdown')
        question.choices = [this.translationPipe.transform("txtItem", this.language, this.ModuleNames.SurveyX) + "1",
        this.translationPipe.transform("txtItem", this.language, this.ModuleNames.SurveyX) + "2",
        this.translationPipe.transform("txtItem", this.language, this.ModuleNames.SurveyX) + "3"];

      if (type === 'image-choice') {
        question.choices = [
          { value: "Lion", imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/lion.jpg" },
          { value: "Giraffe", imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/giraffe.jpg" },
          { value: "Panda", imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/panda.jpg" },
          { value: "Camel", imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/camel.jpg" },
        ];
        question["isIconChoice"] = false;
      }

      if (type === 'icon-choice') {
        question.choices = [
          { value: this.translationPipe.transform("Strongly Disagree", this.language, this.ModuleNames.SurveyX), imageLink: "face-sad-cry" },
          { value: this.translationPipe.transform("Disagree", this.language, this.ModuleNames.SurveyX), imageLink: "face-worried" },
          { value: this.translationPipe.transform("Neutral", this.language, this.ModuleNames.SurveyX), imageLink: "face-thinking" },
          { value: this.translationPipe.transform("Agree", this.language, this.ModuleNames.SurveyX), imageLink: "face-smile-relaxed" },
          { value: this.translationPipe.transform("Strongly Agree", this.language, this.ModuleNames.SurveyX), imageLink: "face-laugh-beam" },
        ];
        question["isIconChoice"] = true;
      }

      if (type === 'matrix') {
        question.rows = [this.translationPipe.transform("txtRow ", this.language, this.ModuleNames.SurveyX) + "1",
        this.translationPipe.transform("txtRow ", this.language, this.ModuleNames.SurveyX) + "2",
        this.translationPipe.transform("txtRow ", this.language, this.ModuleNames.SurveyX) + "3"];

        question.columns = [this.translationPipe.transform("txtColumn ", this.language, this.ModuleNames.SurveyX) + "1",
        this.translationPipe.transform("txtColumn ", this.language, this.ModuleNames.SurveyX) + "2",
        this.translationPipe.transform("txtColumn ", this.language, this.ModuleNames.SurveyX) + "3"];
      }

      if (type === 'slider') {
        question.inputType = "range";
      }

      if (type === 'enps') {
        question.rateMin = 0;
        question.rateMax = 10;
        question.rateStep = 1;
        question["rateType"] = 'enps';
        question.title = this.translationPipe.transform("How likely are you to recommend", this.language, this.ModuleNames.SurveyX) + ' ' + this.companyName + ' ' +
          this.translationPipe.transform("to your friends and relatives?", this.language, this.ModuleNames.SurveyX);
        //question.locTitle.setLocaleText("ar", "ما مدى احتمالية أن توصي [اسم الشركة] لصديق لك أو قريب؟");
      }

      if (type === 'nps') {
        question.rateMin = 0;
        question.rateMax = 10;
        question.rateStep = 1;
        question["rateType"] = 'nps';
        question.title = this.translationPipe.transform("How likely are you to recommend our", this.language, this.ModuleNames.SurveyX) + ' ' + this.translationPipe.transform("[Product(s)/Service(s)]", this.language, this.ModuleNames.SurveyX) + ' ' +
          this.translationPipe.transform("to your friends and relatives?", this.language, this.ModuleNames.SurveyX);
        //question.locTitle.setLocaleText("ar", "ما مدى احتمالية أن توصي [المنتجات/الخدمات] لصديق لك أو قريب؟");
      }

      if (type === 'rating-scale') {
        question["rateType"] = 'labels';
      }

      if (type === 'file-upload') {
        //Set the maxSize to 1MB
        question.maxSize = 1024 * 1024;
      }

      //Create a new page if there is no page
      if (!this.model.survey.pages[0]) {
        var page = new PageModel();
        page.name = this.translationPipe.transform("txtSection", this.language, this.ModuleNames.SurveyX);
        page.title = this.translationPipe.transform("txtSection", this.language, this.ModuleNames.SurveyX);

        console.log("page name", page.name)
        console.log("page title", page.title)

        this.model.survey.addPage(page, 0);

        this.model.survey.pages[0].addElement(question);
      }

      else {
        //Add the question to the last page
        // this.model.survey.pages[this.model.survey.pages.length - 1].addElement(question);



        this.model.survey.activePage.addElement(question);
      }

      //Add the question to the survey and select it
      this.model.selectElement(question);
    }

    //Close the modal
    this.isAddQuestionModalOpen = false;

    // this.changeDetectorRef.detectChanges();
  }

  getQuestionNumber(question: any) {
    let number = question["no"].split(".")[0]

    return number;
  }

  selectQuestion(question: any) {
    this.model.selectElement(question);
    //  this.changeDetectorRef.detectChanges();
  }


  selectPage(page: any) {
    this.model.selectElement(page);
    //   this.changeDetectorRef.detectChanges();
  }


  getTranslatedTitle(page): string {
    let together = "";
    let word = "";
    let number = 0;

    if (page.title) {
      word = this.translationPipe.transform(page.title.split(' ')[0], this.language, this.ModuleNames.SurveyX);
      number = page.title.split(' ')[1];
      if (number == undefined) {
        together = word
      }
      else {
        together = word + " " + number
      }
    }
    else {
      const splitResult = page.title.match(/(\D+)(\d+)/);
      if (splitResult) {
        word = this.translationPipe.transform(splitResult[1], this.language, this.ModuleNames.SurveyX);
        number = parseInt(splitResult[2]);
        if (number == undefined) {
          together = word
        }
        else {
          together = word + number
        }
      }
    }
    return together;

  }

  getTranslatedName(page): string {
    let together = "";
    let word = "";
    let number = 0;


    const splitResult = page.name.match(/(\D+)(\d+)/);
    if (splitResult) {
      word = this.translationPipe.transform(splitResult[1], this.language, this.ModuleNames.SurveyX);
      number = parseInt(splitResult[2]);
      if (number == undefined) {
        together = word
      }
      else {
        together = word + number
      }
    }
    return together;
  }

  addSectionAfter(index: number) {
    var page = new PageModel();
    console.log(this.language)
    page.name = this.translationPipe.transform("txtSection", this.language, this.ModuleNames.SurveyX);
    page.name = this.getTranslatedName(page);

    page.title = this.translationPipe.transform("txtSection", this.language, this.ModuleNames.SurveyX);
    page.title = this.getTranslatedTitle(page);


    this.model.survey.addPage(page, index + 1);
  }

  editQuestion(question: any) {
    this.model.selectElement(question);
    this.model.showSidebar = true;
    //   this.changeDetectorRef.detectChanges();
  }

  deleteQuestion(question: any, menu: ZenMenuComponent) {
    this.model.survey.currentPage.removeElement(question);
    menu.close();
    //  this.changeDetectorRef.detectChanges();
  }

  getQuestionIcon(question: any) {
    let icon = "";
    switch (question.getType()) {
      case "text": //short-text
        if (question['inputType'] === "number")
          icon = "fa-hashtag";
        else if (question['inputType'] === "email")
          icon = "fa-at";
        else if (question['inputType'] === "datepicker")
          icon = "fa-calendar";
        else if (question['inputType'] === "range")
          icon = "fa-sliders";
        else
          icon = "fa-message-minus";
        break;

      case "comment": //long-text
        icon = "fa-message-lines";
        break;

      case "radiogroup": //multiple-choices
        icon = "fa-list";
        break;

      case "checkbox": //multiple-choices
        icon = "fa-list";
        break;

      case "dropdown": //dropdown
        icon = "fa-list-dropdown";
        break;

      case "tagbox": //dropdown
        icon = "fa-list-dropdown";
        break;

      case "rating": //start-rating
        switch (question['rateType']) {
          case "labels":
            icon = "fa-chart-simple"; //rating-scale
            break;

          case "enps":
            icon = "fa-bars-progress"; //enps
            break;

          case "nps":
            icon = "fa-bars-progress"; //nps
            break;

          default:
            icon = "fa-star"; //star-rating
            break;
        }
        break;

      case "ranking": //ranking
        icon = "fa-list-ol";
        break;

      case "imagepicker": //image-choice
        if (question["isIconChoice"])
          icon = "fa-smile";
        else
          icon = "fa-image";
        break;

      case "boolean": //binary
        icon = "fa-circle-half-stroke";
        break;

      case "matrix": //matrix
        icon = "fa-table-list";
        break;

      case "matrixdropdown": //matrix
        icon = "fa-table-list";
        break;

      case "slider":
        icon = "fa-sliders";
        break;

      case "file": //file-upload
        icon = "fa-upload";
        break;

      case "datepicker": //file-upload
        question.disable = true;
        icon = "fa-calendar";
        break;
    }

    return icon;
  }

  /**
  * Handles the drag and drop event for questions.
  * @param event The drag and drop event.
  * @param page The page model.
  */
  questionDragNDrop(event: any, page: any) {
    const previousContainer = event.previousContainer;
    const currentContainer = event.container;
    const previousIndex = event.previousIndex;
    const currentIndex = event.currentIndex;



    if (previousContainer === currentContainer) {
      let previousQuestion = currentContainer.data[previousIndex];
      let currentQuestion = currentContainer.data[currentIndex];

      if ((currentQuestion == undefined || currentQuestion.visibleIf == "") && previousQuestion.visibleIf == "") {

        let currentPage = this.model.survey.getPageByQuestion(currentQuestion);
        let currentPageSurveyIndex = this.model.survey.pages.indexOf(currentPage);

        let previousQuestionIndexInPage = currentPage.elements.indexOf(previousQuestion);
        let currentQuestionIndexInPage = currentPage.elements.indexOf(currentQuestion);

        this.model.survey.pages[currentPageSurveyIndex]
          .elements.splice(currentQuestionIndexInPage, 0, this.model.survey.pages[currentPageSurveyIndex].elements.splice(previousQuestionIndexInPage, 1)[0]);

      }
      else {
        this.zenSnackBarService.snackBar({ message: "Question can't be moved, because there is logic applied", type: ZenSnackBarTypes.Error });
      }
    } else {
      let previousQuestion = previousContainer.data[previousIndex];
      let currentQuestion = currentContainer.data[currentIndex];

      if ((currentQuestion == undefined || currentQuestion.visibleIf == "") && previousQuestion.visibleIf == "") {


        if (currentQuestion == null) {
          let previousPage = this.model.survey.getPageByQuestion(previousQuestion);
          let previousPageSurveyIndex = this.model.survey.pages.indexOf(previousPage);
          let previousQuestionIndexInPage = previousPage.elements.indexOf(previousQuestion);
          this.model.survey.pages[previousPageSurveyIndex].elements.splice(previousQuestionIndexInPage, 1);

          page.addElement(previousQuestion);
        } else {
          let previousQuestion = previousContainer.data[previousIndex];
          let currentQuestion = currentContainer.data[currentIndex];

          let previousPage = this.model.survey.getPageByQuestion(previousQuestion);
          let currentPage = this.model.survey.getPageByQuestion(currentQuestion);

          let previousQuestionIndexInPage = previousPage.elements.indexOf(previousQuestion);
          let currentQuestionIndexInPage = currentPage.elements.indexOf(currentQuestion);

          let previousPageSurveyIndex = this.model.survey.pages.indexOf(previousPage);
          let currentPageSurveyIndex = this.model.survey.pages.indexOf(currentPage);

          this.model.survey.pages[currentPageSurveyIndex]
            .elements.splice(currentQuestionIndexInPage, 0, this.model.survey.pages[previousPageSurveyIndex].elements.splice(previousQuestionIndexInPage, 1)[0]);
        }

      }
      else {
        this.zenSnackBarService.snackBar({ message: "Question can't be moved, because there is logic applied", type: ZenSnackBarTypes.Error });
      }
    }


    this.model.survey.getAllQuestions().forEach((question: any) => {
      question.name = 'question' + question["no"].split(".")[0];
    });

    //  this.changeDetectorRef.detectChanges();
  }
}
