<div *ngIf="language" class="header">
  <!-- Main Header -->
  <div class="main-header ">
    <div class="main-header-left ">
      <div class="level is-mobile">
        <div [dir]="(language | language )? 'rtl': 'ltr'" class="zen-logo is-relative level-item level-left">
          <a class="megaMenu" [matMenuTriggerFor]="navMenu">
            <img src="../../../assets/img/svg/z-logo.svg" alt="ZENITHR Logo" />
          </a>
          <!--
          <img *ngIf="identifier == 'Exit'" src="../../assets/img/svg/Exit.svg" alt="Employee Exit Logo" />
          <a></a>
          <a><img  (click)="pageSelected(1)" *ngIf="identifier == 'Assessment'" src="../../assets/img/360.svg" alt="Employee Engagement Logo" /></a> -->
          <mat-menu #navMenu="matMenu" class="mt-3">
            <div class="headMenu px-0 py-1 ">
              <div class="container mx-4 my-0">
                <h3 class=" has-text-left uppercase is-size-65  font-medium op-80">
                  {{'txtEmployeeExperience'|translate:language:ModuleNames.Shared}}
                </h3>
              </div>
              <div class="container p-0 m-0">
                <div class="subLinks px-0 pt-2">
                  <a (mouseenter)="subTrigger.closeMenu()" class="my-2 mx-0 is-flex px-5" href="/engagement/home">
                    <img src="/assets/img/sub/Engagement.svg" alt="Employee Engagement Logo" />
                    <h4>{{'txtEngagement'|translate:language:ModuleNames.Shared}}</h4>
                  </a>
                  <a (mouseenter)="subTrigger.closeMenu()" class="my-2 mx-0 is-flex px-5" href="/happiness/home">
                    <img src="/assets/img/sub/Happiness.svg" alt="Employee Happiness Logo" />
                    <h4>{{'txtEmployeeHappiness'|translate:language:ModuleNames.Shared}}</h4>
                  </a>
                  <a (mouseenter)="subTrigger.closeMenu()" class="my-2 mx-0 is-flex px-5" href="/assessment360/home">
                    <img src="/assets/img/sub/360.svg" alt="Employee Engagement Logo" />
                    <h4>{{'txtAssessmentSurvey2'|translate:language:ModuleNames.Shared}}</h4>
                  </a>
                  <a #subTrigger="matMenuTrigger" (mouseenter)="subTrigger.openMenu()" mat-menu-item [matMenuTriggerFor]="subExit" class="my-2 mx-0 px-5 is-flex" href="/home">
                    <img src="/assets/img/sub/Exit.svg" alt="Employee Engagement Logo" />
                    <h4>{{'txt_employee_exit2'|translate:language:ModuleNames.Shared}}</h4>
                  </a>
                  <a (mouseenter)="subTrigger.closeMenu()" class="my-2 mx-0 is-flex px-5" href="/psychometric/home">
                    <img src="/assets/img/sub/Engagement.svg" alt="Employee Engagement Logo" />
                    <h4>Psychometric Assessments</h4>
                  </a>
                  <mat-menu #subExit="matMenu">
                    <div class="container" (mouseleave)="subTrigger.closeMenu()">
                      <a class="px-6" routerLink="/home" mat-menu-item [ngClass]="pageID === 1 ? 'active' : ''" href="/home" (click)="pageSelected(1);">
                        <i class="fal fa-analytics mx-2"></i>{{'txtDashboard'|translate:language:ModuleNames.Shared}}</a>
                      <a class="px-6" routerLink="/home" mat-menu-item [ngClass]="pageID === 3 ? 'active' : ''" href="/home" (click)="pageSelected(3)">
                        <i class="fal fa-users mx-2"></i>{{'txtLeavers'|translate:language:ModuleNames.Shared}}</a>
                      <a class="px-6" routerLink="/home" mat-menu-item [ngClass]="pageID === 4 ? 'active' : ''" href="/home" (click)="pageSelected(4)">
                        <i class="fal fa-chart-pie mx-2"></i>{{'txtReports'|translate:language:ModuleNames.Shared}}</a>
                    </div>

                  </mat-menu>
                  <div class="level mt-5 mb-4 px-2">
                    <div class="level-item px-1">
                      <a href="/accountoverview" class="btn cancel-btn text-sm5 uppercase py-2 px-2 m-0"><i class="far fa-user px-1"></i> {{'txtAccountOverview'|translate:language:ModuleNames.Shared}}</a>
                    </div>
                    <div class="level-item px-1">
                      <a href="/home" (click)="pageSelected(0)" class="btn cancel-btn text-sm5 uppercase py-2 px-2 m-0"> <i class="far fa-cog px-1   "></i>
                        {{'txtControlPanel'|translate:language:ModuleNames.Shared}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>

          <img class="secondLogo" *ngIf="!customLogo" src="../../../assets/img/svg/zenithr-wordmark.svg" alt="ZENITHR Logo" />
          <img class="secondLogo" *ngIf="customLogo" [src]="customLogoURL" alt="ZENITHR Logo" />


        </div>
        <div class="currentSection level-left mx-2 py-2">
          <h5 *ngIf="identifier == 'AccountOverview'" class="font-normal is-size-55 px-4 c-white my-0 c-80">
            {{'txtAccountOverview'|translate:language:ModuleNames.Shared}}</h5>
          <h5 *ngIf="identifier == 'Exit' && pageID  != 0" class="font-normal is-size-55 px-4 c-white my-0 c-80">{{'txt_employee_exit'|translate:language:ModuleNames.Shared}}</h5>
          <h5 *ngIf="identifier == 'Engagement' && pageID  != 0" class="font-normal is-size-55 px-4 c-white my-0 c-80">{{'txtEngagement'|translate:language:ModuleNames.Shared}}</h5>
          <h5 *ngIf="identifier == 'Assessment' && pageID  != 0" class="font-normal is-size-55 px-4 c-white my-0 c-80">{{'txtAssessmentSurvey'|translate:language:ModuleNames.Shared}}</h5>
          <h5 *ngIf="identifier == 'Happiness' && pageID  != 0" class="font-normal is-size-55 px-4 c-white my-0 c-80">{{'txtEmployeeHappiness'|translate:language:ModuleNames.Shared}}</h5>
          <h5 *ngIf="identifier == 'Exit' && pageID  == 0 " class="font-normal is-size-55 px-4 c-white my-0 c-80">{{'txtControlPanel'|translate:language:ModuleNames.Shared}}</h5>
          <h5 *ngIf="identifier == 'Psychometric' && pageID  != 0" class="font-normal is-size-55 px-4 c-white my-0 c-80">Psychometric Assessments</h5>
        </div>
      </div>


    </div>

    <div class="main-header-right level-right">
      <div class="container myCol px-3">
        <div class="level noLabel companySelect" *ngIf="!reloadSelectedEntity && identifier != 'AccountOverview'">
          <mat-form-field floatPlaceholder="never" appearance="fill" [floatLabel]="'never'">
            <mat-select class="pt-0 certainPadding" floatPlaceholder="never" [(ngModel)]="selectedEntityKey" (selectionChange)="selectEntityChange($event)">
              <mat-option *ngFor="let company of companies " [value]="company.key">
                {{ extractNameLanguage(company.name) }}
              </mat-option>
            </mat-select>

          </mat-form-field>
        </div>

      </div>

      <div class="level is-marginless is-mobile">
        <div class="rightMenu is-relative">
          <a [ngClass]="menuOpened === 1 ? 'active' : ''" [matMenuTriggerFor]="menu" (menuOpened)="menuOpened = 1" (menuClosed)="menuOpened = 0" href="javascript:;" class="notificationToggle">
            <i class="fal text-2xl fa-bell c-white"></i>
            <span *ngIf="notificationMessages.length != 0" class="notificationNum c-white">{{notificationMessages.length}}</span>
          </a>
          <mat-menu #menu="matMenu">
            <div class="level sticky">
              <div (click)=" navigateToNotificationSettings(); " class="level-item px-2 level-left">
                <a href="/home"><i class="fal fa-cog"></i></a>
              </div>
              <div class="level-item px-6 ">
                <p class="text-sm font-medium ">{{'txtNotifications'|translate:language:ModuleNames.Shared}} <span class="circle">{{notificationMessages.length}}</span></p>
              </div>
              <div class="level-item px-2  level-right cursor" (click)="removeAllNotifications()">
                <svg width="24px" height="24px" viewBox="0 0 24 24">
                  <path d="M5 13h14v-2H5v2zm-2 4h14v-2H3v2zM7 7v2h14V7H7z"></path>
                </svg>
              </div>
            </div>
            <div style="padding: 20px;" [dir]="(language | language )? 'rtl': 'ltr'">
              <ng-container *ngFor="let message of notificationMessages">
                <div class="shadowed flex flex-row is-paddingless is-vcentered">
                  <div class="flex flex-col is-10">
                    <h5 class="text-sm">
                      {{message.title}}
                    </h5>
                    <h6 class="is-size-8">{{message.message}}</h6>

                  </div>
                  <div class="flex flex-col is-2" style="align-items: center; display: flex; flex-direction: column;">
                    <a (click)="removeNotification(message.key)"><i class=" fal fa-times"></i></a>
                  </div>
                </div>
              </ng-container>
            </div>
          </mat-menu>
        </div>

        <div class="user-menu is-relative">
          <div class="userContainer p-4" id="userClick" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="userMenu">

            <i class="fal fa-user-circle c-white is-size-2"></i>
          </div>
          <mat-menu #userMenu="matMenu">
            <div class="userSubMenu headMenu px-4 py-1 ">
              <div class="container my-3">
                <h3 class=" text-center is-size-9 c-grey font-medium">{{'txtLoggedinas'|translate:language:ModuleNames.Shared}}</h3>
                <h3 class=" text-center is-size-6 blackTxt font-medium">{{user.firstName + ' '+
                  user.lastName}} </h3>
              </div>
              <div class="container">
                <div class="container is-paddingless is-marginless grey-back">
                  <mat-select (selectionChange)="selectedLanguageChange($event.value); menuTrigger.closeMenu()" [(ngModel)]="language" (click)="$event.stopPropagation()">
                    <mat-option [value]="ln.key" *ngFor="let ln of supportedLanguages" class="level my-2 mx-2  btn bg-white " value="option1">
                      <div class="level-item level-left">
                        <i class="fal fa-language mx-1"></i>
                        <div class=" has-text-left mx-1">
                          <h4 class="is-marginless text-sm"> {{ ln.name }}</h4>
                        </div>
                      </div>
                    </mat-option>
                  </mat-select>

                  <!-- <a id="languageMenu"
                    class="flex flex-row  headMenu is-paddingless is-vcentered my-1 py-3 btn cancel-btn mx-0 ">
                    <div class="flex flex-col is-paddingless is-half">
                      <div class=" has-text-left mx-1">
                        <h4 class="is-marginless text-sm"><i class="fal fa-language px-1 mx-1"></i>{{getLongLanguageName(language)}}</h4>
                      </div>
                    </div>
                    <div class="flex flex-col is-paddingless has-text-right is-half">
                      <i class="fas fa-chevron-down px-3"></i>
                    </div>
                  </a> -->
                  <div class="language-menu ">
                  </div>
                </div>
                <a (click)="resetPassword()" class="level my-1 btn cancel-btn ">
                  <div class="level-item level-left">
                    <i class="fal  fa-unlock-alt mx-1" aria-hidden="true"></i>
                    <div class=" has-text-left mx-1">
                      <h4 class="is-marginless text-sm">{{'txtResetPassword'|translate:language:ModuleNames.Shared}}</h4>
                    </div>
                  </div>
                </a>
                <a href="/home" (click)="pageSelected(0)" class="level my-1 btn cancel-btn ">
                  <div class="level-item level-left">
                    <i class="fal fa-cog mx-1"></i>
                    <div class=" has-text-left mx-1">
                      <h4 class="is-marginless text-sm">{{'txtControlPanel'|translate:language:ModuleNames.Shared}}</h4>
                    </div>
                  </div>
                </a>


                <a href="/accountoverview" class="level my-1 btn cancel-btn ">
                  <div class="level-item level-left">
                    <i class="fal fa-book-user mx-1"></i>
                    <div class=" has-text-left mx-1">
                      <h4 class="is-marginless text-sm">{{'txtAccountOverview'|translate:language:ModuleNames.Shared}}</h4>
                    </div>
                  </div>
                </a>
                <a (click)="logout()" class="level my-1 btn delete-btn  ">
                  <div class="level-item level-left">
                    <i class="fal fa-power-off mx-1 text-zen-red"></i>
                    <div class=" has-text-left mx-1">
                      <h4 class="is-marginless text-sm text-zen-red">{{'txtLogout'|translate:language:ModuleNames.Shared}}</h4>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </mat-menu>


        </div>
      </div>
    </div>
  </div>
</div>

<!-- <header class="sticky top-0 z-50" [companies]="companies"  [language]= "language" (selectedLanguageC)= "selectedLanguageC($event)"  ></header> -->
<!-- Sub Header -->
<!-- <div  (click)="$event.stopPropagation();" class="sub-header">
   <div class="sub-header-left">
     <div class="sub-module-logo">
       <img *ngIf="identifier == 'Exit'" src="../../assets/img/svg/Exit.svg" alt="Employee Exit Logo" />
       <a><img  (click)="pageSelected(1)" *ngIf="identifier == 'Engagement'" src="../../assets/img/svg/Engagement.svg" alt="Employee Engagement Logo" /></a>
       <a><img  (click)="pageSelected(1)" *ngIf="identifier == 'Assessment'" src="../../assets/img/360.svg" alt="Employee Engagement Logo" /></a>
       <h3 *ngIf="identifier == 'AccountOverview'">Account Overview</h3>

     </div>
   </div>
   <div class="sub-header-right">
     <div class="sub-module-nav-left">
      <ng-container *ngIf="identifier == 'Exit'">
          <a [ngClass]="pageID === 1 ? 'active' : ''" href="javascript:;" (click)="pageSelected(1)">
            <i class="fal fa-analytics"></i>{{txtDashboard}}</a>
          <a [ngClass]="pageID === 3 ? 'active' : ''" href="javascript:;" (click)="pageSelected(3)">
            <i class="fal fa-users"></i>{{txtLeavers}}</a>
          <a [ngClass]="pageID === 4 ? 'active' : ''" href="javascript:;" (click)="pageSelected(4)">
            <i class="fal fa-chart-pie"></i>{{txtReports}}</a>
          <a [ngClass]="pageID === 2 ? 'active' : ''" href="javascript:;" (click)="pageSelected(2)">
            <i class="fal fa-question-circle"></i>{{txtQuestionnaireBuilder}}</a>
      </ng-container>

    </div>
  </div>
  <div class="sub-header-right">
    <div class="sub-module-nav-right">
      <select *ngIf="!reloadSelectedEntity && identifier != 'AccountOverview'" [(ngModel)]="selectedEntityKey" (change)="selectEntityChange($event)">
        <option *ngFor="let company of companies" [value]="company.key">
          {{ extractNameLanguage(company.name) }}
        </option>
      </select>
      <a [ngClass]="menuOpened === 1 ? 'active' : ''" [matMenuTriggerFor]="menu" (menuOpened)="menuOpened = 1"
        (menuClosed)="menuOpened = 0" href="javascript:;" class="notificationToggle">
        <i class="fal fa-bell"></i>
        <span *ngIf="notificationMessages.length != 0">{{notificationMessages.length}}</span>
      </a>

       <mat-menu #menu="matMenu">
         <div class="level ">
           <div (click)="navigateToNotificationSettings()" class="level-item px-2 level-left">
             <a href="javascript:;"><i class="fal fa-cog"></i></a>
           </div>
           <div class="level-item px-6 ">
             <p class="text-sm font-medium ">{{txtNotifications}}  <span class="circle">{{notificationMessages.length}}</span></p>


           </div>
           <div class="level-item px-2  level-right" (click)="removeAllNotifications()">
            <svg width="24px" height="24px" viewBox="0 0 24 24">
              <path d="M5 13h14v-2H5v2zm-2 4h14v-2H3v2zM7 7v2h14V7H7z"></path>
          </svg>
           </div>
         </div>
         <div style="padding: 20px;">
           <ng-container *ngFor="let message of notificationMessages">
             <div class="shadowed flex flex-row is-paddingless is-vcentered">
               <div class="flex flex-col is-10">
                 <h5 class="text-sm">
                  {{message.title}}
                </h5>
                <h6 class="is-size-8">{{message.message}}</h6>

              </div>
              <div class="flex flex-col is-2">
                <a (click)="removeNotification(message.key)"><i class=" fal fa-times"></i></a>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-menu>
      <a [ngClass]="pageID === 0 ? 'active menuBtn' : 'menuBtn'" href="javascript:;" (click)="pageSelected(0)"><i
          class="fal fa-cog"></i></a>
    </div>
  </div>
</div> -->
