export class ErrorHandler {
  key: string;
  originIdentifier: string = 'Client';
  name: string;
  identifierCode: number;
  pageName: string;
  componentName: string;
  functionName: string;
  timeStamp: number;
  resolved: boolean = false;
  errorMessage: string;
  tenantKey: string;
  tenantName: string;
  companyKey: string;
  companyName: string;
  engagementKey: string;
  assessmentKey: string;
  assessmentTitle: string;
  engagementTitle: string;
  caughtBy: string;
  leaverKey: string;
  leaverName: string;
  isExit: boolean = false;
  isEngagement: boolean = false;
  isAssessment: boolean = false;
  clientVersion: string;
}
