import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcelUploadService, IExcelUploadService } from './core/services/excel-upload.service';
import { IExcelParser } from './core/parsers/iexcel-parser';
import { DefaultParser } from './core/parsers/default-parser';
import { IExcelBuilder } from './core/builders/iexcel-builder';
import { DefaultBuilder } from './core/builders/default-builder';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: IExcelUploadService,
      useClass: ExcelUploadService
    },
    {
      provide: IExcelParser,
      useClass: DefaultParser
    },
    {
      provide: IExcelBuilder,
      useClass: DefaultBuilder
    }
  ]
})
export class ExcelUploadModule { }
