/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ExcelFile } from '../models/ExcelFile';
import type { ExportFilter } from '../models/ExportFilter';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param id 
     * @param requestBody 
     * @returns ExcelFile Success
     * @throws ApiError
     */
    public generateExcel(
id: string,
requestBody?: ExportFilter,
): Observable<ExcelFile> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/excel-export/export/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
