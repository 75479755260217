import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExitBaseComponent } from '../../exit-base/exit-base.component';
import { EmailTemplateService } from '@ex/module/exit/data-access/services/email-template.service';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { Hierarchy } from 'src/app/core/data-models/hierarchy';
import { LeaverDto } from '@ex/exit/leavers';
import { GeneralService } from 'src/app/core/services/general.service';
import { CustomPropertyDto } from '@ex/core';
import { NgForm } from '@angular/forms';
import { EmailTemplateDto } from '@ex/core/common';
import { biDirectionalState300, listAnimation } from 'src/app/shared/animations/allAnimations';
import { EntityGroup } from 'src/app/core/data-models/entity-group';
import { EntityGroupDto, EntityHierarchyDto } from '@ex/core/entities';
import { Entity } from 'src/app/core/data-models/Entity';
import { Status } from 'src/app/core/services/global-status.service';

@Component({
  selector: 'leaver-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss'],
  animations: [biDirectionalState300, listAnimation]
})
export class ManualComponent extends ExitBaseComponent implements OnInit {

  @Input()
  surveyKey

  @Input() language;

  @Input() step = 1;

  @Input()
  selectedEntity: Entity;

  leaver: LeaverDto = {} as LeaverDto

  @Input("leaver")
  set leaverObj(leaver: LeaverDto) {

    if (leaver?.key) {
      this.leaver = leaver;
      if ( this.leaver.status == this.status.Pending || this.leaver.status ==  this.status.Started ||  this.leaver.status == this.status.Progress) {
        this.navButtons = this.allButtons.filter(x => x.form != "thirdForm");
        this.invitation = false;
      }
      else if (this.leaver.status == this.status.Completed)
        this.navButtons = this.allButtons.splice(0, 2);
      else this.navButtons = this.allButtons;

      this.invitation = false;
    }
    else  this.navButtons = this.allButtons;

  }

  @Input()
  templates;

  @Input()
  allEntities: Entity[] = []

  @Output() reset = new EventEmitter();

  @Output() leaverObject = new EventEmitter();

  status = Status

  mdCount;
  hugeCount;
  submitted: boolean = false;;

  extraCriterias = [];

  entityHierarchy = [];

  countriesList;
  countriesCodes;
  genders;

  hasCompanyPermission = false;

  navButtons = [];

  allButtons = [
    {
      name: "txt_PersonalInfo",
      form: "firstForm"
    },
    {
      name: "txt_PositionInfo",
      form: "secondForm"
    },
    {
      name:"txt_SurveyOptions",
      form: "thirdForm"
    },
    {
      name: "txt_leaver_time_frame",
      form: "fourthForm"
    },
  ]

  seperationTypes = []

  today: Date = new Date();
  maxDateOfBirth: Date = new Date();
  invitation: boolean = true;

  companyGroupKey


  constructor(private emailTemplateService: EmailTemplateService, private generalService: GeneralService, private cdRef: ChangeDetectorRef) { super() }

  ngOnInit(): void {

    this.maxDateOfBirth.setFullYear(this.today.getFullYear() - 18);

    this.seperationTypes = this.SeperationTypes.get()


    this.mdCount = this.GlobalService.mdCount;
    this.hugeCount = this.GlobalService.hugeCount;

    this.initalize()


  }

  initalize() {
    this.countriesList = this.generalService.countries_new;
    this.countriesCodes = this.generalService.countryCodes;
    this.genders = this.generalService.genders_new;
    this.extraCriterias = this.generalService.extraCriterias;
    this.extraCriterias.forEach((criteria: CustomPropertyDto) => {
      if (!this.leaver.extraCriterias)
        this.leaver.extraCriterias = [];
      if (!this.leaver.extraCriterias?.find(c => c.key == criteria.key)) {
        this.leaver.extraCriterias.push({ key: criteria.key, value: '' as String });
      }
    });

    this.companyGroupKey = this.selectedEntity.EntityGroups.find(x => x.isCompanyGroup)?.groupKey;

    this.leaver.entityHierarchy = this.constructEntityHierarchy(this.leaver.entityHierarchy || [], this.allEntities);

    // Set the email template if the leaver is new
    if (!this.leaver?.key) {
      this.leaver.invitationEmailTemplate = this.emailTemplateService.getInvitationEmail();
      this.leaver.reminderEmailTemplate = this.emailTemplateService.getReminderEmail();

      this.leaver.reminders = [];
    }


  }

  resetAll(closeAll?) {
    if (closeAll)
      this.reset.emit(true);
    else this.reset.emit(false);
    this.step = 0;
  }

  isLastSection() {

    if (this.step === this.navButtons.length)
      return true;

    return false;
  }

  validateForm(form: NgForm) {

    console.log(form);

    if (form.form.status == "INVALID")
      return this.SnackbarService.error(this.localize("txt_FillFormCorrectly", this.language, this.ModuleNames.Exit));

    if (this.step == 3 && !this.leaver.surveyKey)
      return;

    if (this.isLastSection()
      && (!this.validateEmailTemplate(this.leaver?.invitationEmailTemplate) || !this.validateEmailTemplate(this.leaver?.reminderEmailTemplate)))
      return;


    if (!this.isLastSection()) {
      this.step++;
      return;
    }

    this.leaver.companyKey = this.selectedEntity.key;
    this.leaver.tenantKey = this.selectedEntity.tenantKey

    this.submitted = true;

    this.leaver.entityHierarchy = this.leaver.entityHierarchy.filter(x => x.selectedEntityKey);
    this.logData(this.leaver);
    this.leaverObject.emit(this.leaver);
  }

  validateEmailTemplate(template: EmailTemplateDto) {
    let isValid: boolean = true;
    if (!template
      || !template.body
      || !template.subject
    ) {
      isValid = false;
    }

    if (template.body.indexOf("[SurveyURL]") < 1) {
      this.SnackbarService.error(this.localize("txtInvitationMissingURL", this.language, this.ModuleNames.Exit))
      return isValid = false;
    }

    if (!isValid)
      this.SnackbarService.error(this.localize("txt_fill_fields", this.language, this.ModuleNames.Exit));

    // TODO: Word count on subject (Required clarification)

    return isValid;
  }

  getForm() {
    return this.navButtons[this.step - 1]?.form;
  }

  onTemplateClick(template) {
    // if (template.isDraft || this.leaver.key)
    //   return;

    this.leaver.surveyKey = template.key;
  }

  disableStartDate() {
    if (this.leaver.key)
      return true;
  }

  plusOneDay(tDate: Date, minToday?) {

    let date = new Date(tDate);
    if (minToday) {
      if (new Date(date?.setHours(0, 0, 0, 0)).getTime() < new Date(this.today.setHours(0, 0, 0, 0)).getTime())
        date = new Date(this.today)
    }
    return date.setDate(date.getDate() + 1)
  }

  minusOneDay(tDate: Date) {
    let date = new Date(tDate);
    return date.setDate(date.getDate() - 1)
  }
  minDateForEndDate() {
    let referenceDate = new Date(this.today)
    if (!this.leaver?.startDate)
      return this.plusOneDay(referenceDate);
    if (new Date(new Date(this.leaver?.startDate)?.setHours(0, 0, 0, 0)).getTime() < new Date(this.today.setHours(0, 0, 0, 0)).getTime())
      referenceDate = new Date(this.today)
    else referenceDate = new Date(this.leaver.startDate);
    return this.plusOneDay(referenceDate);
  }

  getReminders(reminders, ignoreIndex) {
    if (reminders?.length) {
      let newReminders = reminders.filter((reminder, index) => index != ignoreIndex);
      if (newReminders?.length)
        return newReminders.map(date => date.date);
    }
  }

  disablePreviousDate(date) {
    if (!date)
      return false;
    date = new Date(date);
    if (new Date(date?.setHours(0, 0, 0, 0)).getTime() < new Date(this.today.setHours(0, 0, 0, 0)).getTime())
      return true;
  }

  addReminder(reminders) {
    let date = { date: null, isSent: false };

    if (!this.leaver.startDate || !this.leaver.endDate)
      return this.SnackbarService.error(this.localize("txt_Start&CompletionDates", this.language, this.ModuleNames.Exit));

    if (reminders.length < 3)
      reminders.push(date);
  }

  deleteReminder(reminders, reminder) {
    reminders = reminders.splice(reminders.indexOf(reminder), 1);
  }
  setReminder(event: Date, i: number) {
    let tDate = new Date().toISOString();
    this.leaver.reminders[i].date = new Date(new Date(event).setHours(new Date(tDate).getHours(), new Date(tDate).getMinutes(), 0, 0)).toISOString();
    console.log(this.leaver.reminders[i].date);
  }

  resetEmailTemplate(type) {
    // 1 - Invitation
    if (type == 1)
      this.leaver.invitationEmailTemplate = this.emailTemplateService.getInvitationEmail()

    // 2 - Reminder
    if (type == 2)
      this.leaver.reminderEmailTemplate = this.emailTemplateService.getReminderEmail()
  }

  private getEntityGroupsInOrder(root: EntityGroup, allGroups: EntityGroup[]) {
    var children = this.selectedEntity.EntityGroups.filter(x => x.parentKey == root.groupKey);

    if (children.length > 0) {

      children.forEach(child => {

        allGroups.push(child);
        this.getEntityGroupsInOrder(child, allGroups);

      })
    }
  }

  constructEntityHierarchy(existing: EntityHierarchyDto[], allEntities: Entity[], current = null): EntityHierarchyDto[] {

    var entityGroups: EntityGroup[] = [];
    var result: EntityHierarchyDto[] = [];
    var root = this.selectedEntity?.EntityGroups.find(x => x.isCompanyGroup == true);

    // Get all the entity groups in order
    this.getEntityGroupsInOrder(root, entityGroups);

    for (let group of entityGroups) {

      let i = entityGroups.indexOf(group);

      const hierarchy = {
        entities: allEntities.filter(x => x.entityGroupKey == group.groupKey),
        name: group.name,
        key: group.groupKey,
        parentKey: group.parentKey,
        selectedEntityKey: existing.find(x => x.key == group.groupKey)?.selectedEntityKey || null
      } as EntityHierarchyDto

      if (i > 0) {
        var prev = result.find(x => x.key == group.parentKey)

        if (prev && prev.selectedEntityKey) {
          hierarchy.entities = hierarchy.entities.filter(e => e.parentKey == prev.selectedEntityKey)
        }

      }

      result.push(hierarchy);
    }

    if (current) {
      this.leaver.entityHierarchy = result;

      // Clear Child Selected Keys
      this.clearEntityHierarchySelection(current);
    }


    return result;
  }

  clearEntityHierarchySelection(entityHierarchy: EntityHierarchyDto) {

    let children = this.leaver.entityHierarchy.filter(x => x.parentKey == entityHierarchy.key);

    for (let child of children) {
      child.selectedEntityKey = null;
      this.clearEntityHierarchySelection(child)
    }

  }

  getEntityHierarchy() {
    let entities = this.leaver.entityHierarchy.filter(x => x.selectedEntityKey);

    const keys = entities.map(x => x.key);

    entities = [...entities, ... this.leaver.entityHierarchy.filter(x => keys.includes(x.parentKey) && !keys.includes(x.key))]

    return entities.length ? entities : this.leaver.entityHierarchy.filter(x => x.parentKey == this.companyGroupKey);
  }

  returnButtons(navButtons) {
    return navButtons;
  }
  showTemplateSection() {
    return this.step == 3 && (!this.leaver?.key || (this.leaver.key && (this.leaver.status == this.status.Posted || this.leaver.status == this.status.Expired)))
  }


  invitationClick() {
    this.leaver.status != this.status.Posted && this.leaver.key ? '' : this.invitation = true
  }
}
