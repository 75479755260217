import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';

@Component({
  selector: 'app-change-category-modal',
  templateUrl: './change-category-modal.component.html',
  styleUrls: ['./change-category-modal.component.scss']
})
export class ChangeCategoryModalComponent extends ModalComponent implements OnInit, OnChanges {

  @Input() language = 'EN';
  ModuleNames = ModuleName;

  @Input() open: boolean = true;
  @Input() categoriesList: any[] = [];
  @Input() selectedCategory: any;
  filteredCategoriesList: any[] = [];

  @Output() openChange = new EventEmitter();
  @Output() selectedCategoryChange = new EventEmitter<any>();
  @Output() openNewCategoryModal = new EventEmitter<any>();
  @Output() editCategoryClicked = new EventEmitter<any>();
  @Output() deleteCategoryClicked = new EventEmitter<any>();

  searchValue = '';

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.filteredCategoriesList = this.categoriesList;


  }

  ngOnChanges() {
    this.filteredCategoriesList = this.categoriesList;
  }

  /**
   * Function for when the form is submitted.
   * Emits the selected category and closes the modal.
   */
  onSubmit() {
    this.selectedCategoryChange.emit(this.selectedCategory);
    this.close();
  }

  /**
   * Function for closing the modal.
   * Emits that the modal is closed and detects changes.
   */
  close() {
    this.openChange.emit(false);
    this.cd.detectChanges();
  }

  /**
   * Function for when the "New Category" button is clicked.
   * Emits that the "New Category" modal should be opened and closes the current modal.
   */
  newCategoryModalButtonClick() {
    this.openNewCategoryModal.emit();
    this.close();
  }

  /**
   * Function for searching categories.
   * Filters the categories list based on the search value.
   * @param $event The search value.
   */
  searchCategories($event: any) {
    this.filteredCategoriesList = this.categoriesList.filter((x) => x.name.toLowerCase().includes($event));
  }

  /**
   * Function for when the "Edit Category" button is clicked.
   * Closes the menu and emits that the "Edit Category" modal should be opened.
   * @param category The category to edit.
   * @param menu The menu to close.
   */
  editCategory(category: any, menu: any) {
    menu.close();
    this.editCategoryClicked.emit(category);
  }

  /**
   * Function for when the "Delete Category" button is clicked.
   * Closes the menu and emits that the category should be deleted.
   * @param category The category to delete.
   * @param menu The menu to close.
   */
  deleteCategory(category: any, menu: any) {
    menu.close();
    this.deleteCategoryClicked.emit(category);
  }

  /**
   * Function for getting the category name.
   * Parses the category title and returns the name for the given language.
   * @param categoryTitle The title of the category.
   * @param language The language to get the name for.
   * @returns The name of the category for the given language.
   */
  getCategoryName(categoryTitle: any, language: string) {
    if (!categoryTitle) return;

    try {
      let title = JSON.parse(categoryTitle);
      let foundTitle = title.find((f: any) => f.key == language).name;

      if(foundTitle.trim() !== '')
        {
          return foundTitle
        }
        else
        {
          return title.find((f: any) => f.key != language).name;
        }
    } catch (e) {
      return categoryTitle;
    }
  }
}
