<div class="flex flex-col gap-5">
    <div class="py-5">
        <mat-divider></mat-divider>
    </div>
    <h5 [@child40] class="text-zen text-2xl font-medium">
        {{'txtWellbeingIndexByScale'|
        translate:language:ModuleNames.Wellbeing}}
    </h5>

    <div class="flex flex-col rounded border border-zen border-opacity-10 shadow-sm bg-white">
        <div class="flex flex-row p-5">
            <div class="flex flex-col items-center gap-2 w-1/3" *ngFor="let wellbeingIndex of wellbeingIndexByScales">
                <kendo-arcgauge [color]="wellbeingScalesService.getColorByCode(wellbeingIndex.scale)"
                    [value]="wellbeingIndex.mean" style="min-width:11rem" renderAs="canvas">
                    <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450"
                        [rangePlaceholderColor]="wellbeingScalesService.getColorByCode(wellbeingIndex.scale) + '60'"
                        [rangeSize]="20" [rangeLineCap]="'butt'">
                        <ng-template kendoArcGaugeCenterTemplate let-value="value">
                            <span class="font-medium text-zen text-3xl">
                                {{(wellbeingIndex.mean | number:'1.1-1')}}%
                            </span>
                        </ng-template>
                    </kendo-arcgauge-scale>
                </kendo-arcgauge>
                <h5 [innerHtml]='wellbeingIndex.scale | translate:language:ModuleNames.Wellbeing'
                    class="font-medium text-sm text-zen">
                </h5>
            </div>
        </div>
    </div>
</div>
