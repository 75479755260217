<div class="page-footer">
  <ng-container *ngIf="!footerData.hidePoweredBy">
    <div class="powered-by-inner">
      <p class="fw-regular">{{ 'txtPoweredby'|translate:footerData.language:ModuleNames.Shared}}</p>
      <img [src]="zenithrSmallLogoUrl" alt="Client Logo" />
    </div>
  </ng-container>
  <div class="copy-date">
    <p class="fw-regular">{{ 'txtGenerated'|translate:footerData.language:ModuleNames.Shared }}: {{
      footerData.currentDate }}</p>
    <p class="fw-regular">{{ 'txtAllRights'|translate:footerData.language:ModuleNames.Shared }} <i
        class="far fa-copyright"></i>
      {{ footerData.currentYear }} - zenithr.com
    </p>
  </div>
</div>
