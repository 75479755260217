<z-modal [open]="true" [autoWidth]="true">
  <ng-container header>
    <h2 class="text-zen text-2xl font-semibold w-90">{{'New Template'| translate:language:ModuleNames.SurveyX}}</h2>
    <i (click)="close.emit(true)"
      class="fa-regular fa-times leading-12 btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>

    <z-input [label]="'txtNewTemplate'| translate:language:ModuleNames.SurveyX" [maxCount]="mdCount" [required]="true"
      [language]="language"  [formControl]="title">
    </z-input>

  </ng-container>

  <ng-container footer class="flex w-full flex-row justify-between">
    <a (click)="close.emit(true)"
      class="btn leading-10 rounded-sm cancel px-5 text-sm font-medium">{{'btn_cancel'| translate:language:ModuleNames.Shared}}</a>
    <a [ngClass]="{'opacity-70': title.invalid}" (click)="title.invalid ?'':saveTemplate()"
      class="btn leading-10 rounded-sm exp px-5 text-sm font-medium">{{'Save'| translate:language:ModuleNames.SurveyX}}</a>
  </ng-container>
</z-modal>
