<div class="flex flex-col gap-10" *ngIf="surveyData?.survey?.sections?.length" (click)="logData(currentSelectedSection)">
  <h5 [@first] class="text-xl font-medium text-zen">
    {{ "txt_survey_info" | translate : language : ModuleNames.Wellbeing }}
  </h5>
  <div class="flex flex-row gap-5" [TourStep]="{
      title: 'txt_SurveyDet_371' | translate : language : ModuleNames.Wellbeing,
      text: 'txt_Fillinyou_655' | translate : language : ModuleNames.Wellbeing,
      order: 2,
      tourKey: 'wellbeing-setup-0'
    }">
    <div class="flex flex-col w-90" [@child10]>
      <z-translation-input [invalid]="submitted" [maxCount]="mdCount" [required]="true" [label]="
          'txt_questionnaire_name'
            | translate : language : ModuleNames.Wellbeing
        " [language]="language" [supportedLanguages]="selectedEntity?.ContentLanguages" [(ngModel)]="surveyData.title" [TourStep]="{
          title:
            'txt_Translati_697' | translate : language : ModuleNames.Wellbeing,
          text:
            'txt_translation_desc'
            | translate : language : ModuleNames.Wellbeing,
          order: 3,
          tourKey: 'wellbeing-setup-0',
          on: (language | language) ? 'left' : 'right'
        }">
      </z-translation-input>
    </div>
    <div class="flex flex-col w-full" [@child15]>
      <z-translation-input [maxCount]="mdCount" [language]="language" [supportedLanguages]="selectedEntity?.ContentLanguages" [(ngModel)]="surveyData.description">
        <ng-template #labelTemplate>
          {{
          "txt_Descripti_202" | translate : language : ModuleNames.Wellbeing
          }}
          <span class="text-zen-blueGrey">({{
            "txt_Optional_264" | translate : language : ModuleNames.Wellbeing
            }})</span>
        </ng-template>
      </z-translation-input>
    </div>
  </div>

  <div class="flex flex-row rounded overflow-hidden bg-white items-stretch" *DemoAccount="'Default'; let isDemo = isDemo">
    <div class="flex flex-col w-90 pb-7">
      <div class="flex flex-row items-center justify-start gap-1.5 px-7 text-zen-lite text-xs h-20">
        <i class="fa-regular fa-info-circle text-zen-ghost"></i>
        <span>{{
          "txt_Selectupt_657" | translate : language : ModuleNames.Wellbeing
          }}</span>
      </div>
      <div class="flex flex-col items-stretch gap-3" (click)="logData(sectionsSetup)">
        <zen-accordions>
          <ng-container *ngFor="let sectionContainer of sectionsSetup; let s = index">
            <span *ngIf="s != 0" class="h-0 border-t border-zen border-opacity-20 w-full block"></span>
            <zen-accordion #accordion>
              <ng-container head>
                <div class="flex flex-row justify-between items-center leading-15 min-h-15 w-full btn px-7" [TourStep]="{
                    title:
                      'txtDimensions_name'
                      | translate : language : ModuleNames.Wellbeing,
                    text:
                      'txtDimensions_desc'
                      | translate : language : ModuleNames.Wellbeing,
                    order: 4,
                    tourKey: 'wellbeing-setup-0',
                    on: (language | language) ? 'left' : 'right',
                    isRows: true
                  }">
                  <span class="uppercase text-exp text-2.5xs font-medium">{{
                    sectionContainer.name
                    | translate : language : ModuleNames.Wellbeing
                    }}</span>
                  <div class="flex flex-row items-center justify-end gap-2.5">
                    <span zenButton styles="icon-7" class="text-exp bg-exp bg-opacity-10">{{
                      returnSelectedFactors(sectionContainer.sections) || 0
                      }}</span>
                    <i [ngClass]="{
                        'rotate-90': !accordion.active && (language | language),
                        '-rotate-90':
                          !accordion.active && !(language | language),
                        cancel: accordion.active
                      }" zenButton styles="icon-7" class="fa-regular fa-angle-down text-zen-blueGrey transform"></i>
                  </div>
                </div>
              </ng-container>
              <ng-container body>
                <div [@listAnimation]="sectionContainer.sections?.length">
                  <div class="flex flex-col items-stretch px-7" *ngIf="sectionContainer.key == 'extraFactors'" [TourStep]="{
                    title:
                      'txtCustomDimensions'
                      | translate : language : ModuleNames.Wellbeing,
                    text:
                      'txtCustomDimensions_desc'
                      | translate : language : ModuleNames.Wellbeing,
                    order: 5,
                    tourKey: 'wellbeing-setup-0',
                    on: (language | language) ? 'left' : 'right'
                  }">
                    <a [ngClass]="{'pointer-events-none opacity-60':!canAddNewFactor() }" (click)="addNewFactor()" zenButton styles="height-9" *ngIf="!isDemo || isDemo && sectionContainer.sections?.length < 1" class="border border-zen border-opacity-20 text-xs justify-center text-zen-lite my-3">
                      <i class="fa-regular fa-plus"></i>
                      {{
                      "txt_NewCustom_150"
                      | translate : language : ModuleNames.Wellbeing
                      }}
                    </a>
                  </div>

                  <ng-container *ngFor="
                      let section of sectionContainer.sections;
                      let n = index
                    ">
                    <ng-container *ngTemplateOutlet="
                        sectionInputTemplate;
                        context: { $implicit: section, index: n }
                      ">
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </zen-accordion>
          </ng-container>
        </zen-accordions>
      </div>
    </div>
    <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
    <div class="flex flex-col w-full items-stretch">
      <div class="flex flex-col w-full px-7 pb-7" *DemoAccount="showDemoSection(currentSelectedSection)">
        <div class="flex flex-row items-center justify-between h-20">
          <div class="flex flex-row items-center gap-2">
            <h4 class=" text-zen-lite text-xs  truncate"> {{'txt_STATEMENT_772'|
              translate:language:ModuleNames.Wellbeing}}
              ({{extractNameLanguage(currentSelectedSection?.name)}}) </h4>
            <product-tour *ngIf="!currentSelectedSection.isPredefined" [key]="'wellbeing-extraFactor'" [offset]="112" [subTour]="true"></product-tour>
          </div>

          <div class="flex flex-row items-center justify-end gap-5">
            <ng-container>
              <div *ngIf="!currentSelectedSection.isPredefined" class="flex flex-row items-center justify-start gap-1.5  text-zen-lite text-xs h-20">
                <i class="fa-regular fa-info-circle text-zen-ghost"></i>
                <span>{{ "txt_Addupto4s_862" | translate:language:ModuleNames.Wellbeing }}</span>
              </div>
              <a *ngIf="currentSelectedSection.questions?.length < 4" (click)="addNewQuestion()" zenButton styles="height-9" [TourStep]="{
                  title:
                    'txt_AddStatem_838'
                    | translate : language : ModuleNames.Wellbeing,
                  text:
                    'txt_ClickOnStatement'
                    | translate : language : ModuleNames.Wellbeing,
                  order: 2,
                  tourKey: 'wellbeing-extraFactor',
                  on: (language | language) ? 'right' : 'left'
                }" class="border border-zen border-opacity-20 text-xs justify-center px-4 text-zen-lite my-3">
                <i class="fa-regular fa-plus"></i>
                {{ "txt_newstatem_408" | translate:language:ModuleNames.Wellbeing }}
              </a>
            </ng-container>
            <i (click)="sampleQuestion = true" zenButton styles="secondary icon-9" class="fa-regular fa-eye" [matTooltip]="'txt_Sample_Question'| translate:language:ModuleNames.Shared"></i>
          </div>

        </div>
        <div class="flex flex-col items-stretch">
          <ng-container *ngFor="let question of currentSelectedSection?.questions;let i = index">
            <ng-container *ngTemplateOutlet="questionTemplate; context:{$implicit:question, factor:currentSelectedSection, index:i}">
            </ng-container>

          </ng-container>


          <!-- ai-generation button -->
          <div class="flex flex-col items-center py-10" *ngIf="!currentSelectedSection?.isPredefined">
            <div class="flex flex-col items-center gap-10" [TourStep]="{
              title:
                'txt_GenerateS_831'
                | translate : language : ModuleNames.Wellbeing,
              text:
                'txt_Useouradv_513'
                | translate : language : ModuleNames.Wellbeing,
              order: 3,
              tourKey: 'wellbeing-extraFactor',
              on: (language | language) ? 'left' : 'right'
            }">
              <!-- <div class="flex flex-col items-center gap-10 py-10"> -->
              <a zenButton *ngIf="currentSelectedSection?.questions?.length" [AiStatementGeneration]="{component:this, module: ModuleNames.Wellbeing, section:currentSelectedSection, addFunction:'addNewQuestion', maxRecords:4, property:'questions'}" styles="secondary height-10"> <img class="object-fill" src="/assets/img/svg/ZenAi-icon.svg" /> {{
                "txtRegenerateWithAI" | translate:language:ModuleNames.Shared }}</a>
              <ng-container *ngIf="!currentSelectedSection?.questions?.length">

                <img zenButton class="h-20 object-cover opacity-50 pointer-events-none" src="/assets/img/svg/ZenAi-icon.svg" />
                <a zenButton [AiStatementGeneration]="{component:this, module: ModuleNames.Wellbeing, section:currentSelectedSection, addFunction:'addNewQuestion', maxRecords:4}" styles="secondary height-10">{{ "txtGenerateByAi" | translate:language:ModuleNames.Shared }}</a>
              </ng-container>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #sectionInputTemplate let-section>
  <z-edit-container #container EditContainer [auto]="false">
    <ng-template #editTemplate>
      <z-translation-input [focus]="true" (ngModelChange)="currentSelectedSection = section" [classList]="
          validNameClass(section.name)
            ? 'bg-transparent w-full flex flex-row justify-between items-center leading-9 min-h-15 truncate px-7'
            : 'bg-zen-red bg-opacity-10 w-full flex flex-row justify-between items-center leading-9 min-h-15 truncate px-7 text-zen-red'
        " [maxCount]="avgCount" [required]="true" [language]="language" [supportedLanguages]="selectedEntity?.ContentLanguages" [(ngModel)]="section.name" id="section" name="section"></z-translation-input>
    </ng-template>
    <ng-template #viewTemplate>
      <a (click)="sectionClicked(section, $event)" [ngClass]="{
          'bg-zen bg-opacity-10': section.key == currentSelectedSection.key
        }" class="btn flex flex-row justify-between items-center gap-2 min-h-15 px-7 text-sm hover:bg-zen hover:bg-opacity-10 breakAll">
        <span class="flex flex-row gap-2 items-center" [matTooltip]="section.name | extractNameLanguage : language">
          <i (click)="selectionClick(section)" [ngClass]="
              section.isSelected
                ? 'fa-solid fa-square-check text-exp'
                : 'fa-regular fa-square text-zen'
            " class="text-lg"></i>
          <span>{{ section.name | extractNameLanguage : language }}</span>
        </span>
        <i [ZMenuTriggerFor]="menu" *ngIf="!section.isPredefined" class="fa-regular fa-ellipsis-v w-8 h-8 leading-8 text-opacity-60 text-base btn text-center btn" [TourStep]="{
            title:
              'txt_EditCusto_165'
              | translate : language : ModuleNames.Wellbeing,
            text:
              'txt_Editordel_801'
              | translate : language : ModuleNames.Wellbeing,
            order: 1,
            tourKey: 'wellbeing-extraFactor',
            on: (language | language) ? 'left' : 'right'
          }"></i>
      </a>
      <z-menu #menu>
        <a MenuItem (click)="$event.stopPropagation(); container.active = true">
          <i class="fal fa-pen"></i>{{ "btn_edit" | translate : language : ModuleNames.Wellbeing }}
        </a>
        <a MenuItem delete (click)="$event.stopPropagation(); removeSection(section)">
          <i class="fal fa-trash"></i>{{ "btn_delete" | translate : language : ModuleNames.Wellbeing }}
        </a>
      </z-menu>
    </ng-template>
  </z-edit-container>
</ng-template>

<ng-template #questionTemplate let-question let-factor="factor" let-index="index">
  <z-edit-container EditContainer [auto]="!currentSelectedSection.isPredefined">
    <ng-template #editTemplate>
      <div class="flex flex-col items-stretch justify-center min-h-15 text-zen text-sm">
        <z-translation-input [focus]="true" [classList]="
            validNameClass(question.name)
              ? 'bg-transparent w-full flex flex-row justify-between items-center leading-9 truncate px-7'
              : 'bg-zen-red bg-opacity-10 w-full flex flex-row justify-between items-center leading-9 min-h-15 truncate px-7 text-zen-red'
          " [maxCount]="lgCount" [required]="true" [language]="language" [supportedLanguages]="selectedEntity?.ContentLanguages" [(ngModel)]="question.name">
        </z-translation-input>
      </div>
    </ng-template>
    <ng-template #viewTemplate>
      <div [ngClass]="{
          'text-zen-red': !validNameClass(question.name),
          'text-zen': validNameClass(question.name)
        }" class="flex flex-row items-center justify-between min-h-15 text-sm cursor-text">
        <div (click)="logData(question)" class="flex flex-row gap-2.5">
          <span class="font-medium">{{ "txtQ" | translate : language : ModuleNames.Shared
            }}{{ index + 1 }}</span>
          <h5 class="breakAll">{{ extractNameLanguage(question.name) }}</h5>
        </div>
        <div class="flex flex-row gap-1 items-center justify-end" *ngIf="!currentSelectedSection.isPredefined" (click)="$event.stopPropagation()">
          <a zenButton [AiStatementGeneration]="{component:this, module: ModuleNames.Engagement, section:currentSelectedSection, statement:question}" [matTooltip]="'txtRegenerateWithAI'|translate:language:ModuleNames.Shared" styles="icon-9" class="transition-all ease-expo">
            <img class="object-fill" src="/assets/img/svg/ZenAi-icon.svg" />
          </a>

          <i matTooltip="{{
              'txt_DeleteQue_565' | translate : language : ModuleNames.Wellbeing
            }}" (click)="removeQuestion(question, factor)" class="fa-regular fa-trash h-6 w-6 leading-6 text-center text-zen-red rounded-sm bg-zen-red bg-opacity-5 cursor-pointer"></i>
        </div>
      </div>
    </ng-template>
  </z-edit-container>
</ng-template>
<z-modal [(open)]="sampleQuestion" [autoWidth]="true">
  <ng-container header>
    <div class="flex flex-row gap-2 justify-start items-center">
      <h4 class="text-2xl font-bold text-zen">
        {{
        "txtExtraQuestionPreview"
        | translate : language : ModuleNames.Wellbeing
        }}
      </h4>
      <!-- <p class="text-sm text-zen">Please reupload the data file after fixing the validation errors.</p> -->
    </div>
    <i (click)="sampleQuestion = false" zenButton styles="icon-10" class="fa-regular fa-times text-zen-lite"></i>
  </ng-container>

  <ng-container body>
    <div class="flex flex-col items-stretch gap-7">
      <h5 class="text-zen-lite text-sm">
        {{
        "txt_preview_sample_desc"
        | translate : language : ModuleNames.Wellbeing
        }}
      </h5>
      <div class="flex flex-col items-stretch gap-3 bg-white bg-opacity-40 rounded-md -mb-10">
        <h4 class="text-zen font-medium text-xs text-opacity-80 text-center">
          {{
          "txt_Sample_Question" | translate : language : ModuleNames.Wellbeing
          }}
        </h4>
        <h4 class="text-zen font-thin text-xs text-center">
          {{
          "txtExtraQuestion" | translate : language : ModuleNames.Wellbeing
          }}
        </h4>
        <div class="flex flex-col items-stretch bg-zen bg-opacity-5 border-zen-blueGrey rounded-md gap-9 px-6 py-8">
          <div class="flex flex-row w-full gap-1 items-stretch">
            <div class="flex flex-row w-full gap-1 items-stretch">
              <div class="flex flex-col items-center w-1/5 gap-2.5">
                <i class="fa-light fa-face-pouting text-2xl rounded text-zen bg-white border-2 border-transparent text-opacity-70 py-3 px-4"></i>
                <h5 class="text-opacity-80 text-3xs font-medium text-center text-zen-lite">
                  {{ getConfidence("single-answer-rating", "1") }}
                </h5>
              </div>
              <div class="flex flex-col items-center w-1/5 gap-2.5">
                <i class="fa-light fa-face-worried text-2xl rounded text-zen bg-white border-2 border-transparent text-opacity-70 py-3 px-4"></i>
                <h5 class="text-opacity-80 text-3xs font-medium text-center text-zen-lite">
                  {{ getConfidence("single-answer-rating", "2") }}
                </h5>
              </div>
              <div class="flex flex-col items-center w-1/5 gap-2.5">
                <i class="fa-light fa-face-thinking text-2xl rounded text-zen bg-white border-2 border-transparent text-opacity-70 py-3 px-4"></i>
                <h5 class="text-opacity-80 text-3xs font-medium text-center text-zen-lite">
                  {{ getConfidence("single-answer-rating", "3") }}
                </h5>
              </div>
              <div class="flex flex-col items-center w-1/5 gap-2.5">
                <i class="fa-light fa-face-smile-relaxed text-2xl rounded text-zen bg-white border-2 border-transparent text-opacity-70 py-3 px-4"></i>
                <h5 class="text-opacity-80 text-3xs font-medium text-center text-zen-lite">
                  {{ getConfidence("single-answer-rating", "4") }}
                </h5>
              </div>
              <div class="flex flex-col items-center w-1/5 gap-2.5">
                <i class="fa-light fa-face-laugh-beam text-2xl rounded text-exp bg-white border-2 border-exp text-opacity-70 py-3 px-4"></i>
                <h5 class="text-opacity-80 text-3xs font-medium text-center text-zen-lite">
                  {{ getConfidence("single-answer-rating", "5") }}
                </h5>
              </div>
            </div>
          </div>

          <div class="flex flex-col mt-2 w-full items-stretch gap-1">
            <h5 class="text-zen text-xxs">
              {{
              "txtHavemorecomments"
              | translate : language : ModuleNames.Wellbeing
              }}
            </h5>
            <div class="bg-white duration-300 ease-expo rounded border border-zen border-opacity-10 px-5 py-1 overflow-hidden h-14 text-opacity-80 text-zen text-xxs"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <a (click)="sampleQuestion = false" class="btn cancel text-center align-middle w-full rounded leading-10 uppercase text-xs font-medium">{{ "btn_close" | translate : language : ModuleNames.Wellbeing }}</a>
  </ng-container>
</z-modal>