import { Component, Input, OnInit } from '@angular/core';
import { ChildrenAnimation, fadeUpDown } from '../../animations/allAnimations';
import { ModuleName } from '../../enums/ModuleName';

@Component({
  selector: 'validation-required',
  templateUrl: './required.component.html',
  styleUrls: ['./required.component.scss'],
})
export class RequiredComponent implements OnInit {
 @Input() language;
  constructor() { }

  ngOnInit(): void {
  }
  ModuleNames=ModuleName
}
