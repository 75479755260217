<z-modal  [open]="open">
    <ng-container body>
        <div class="flex flex-wrap flex-col items-center">

            <div class="check-wrapper mb-5">
                <i [ngClass]="{'rotateY180':(language|language)}" class="fa-light fa-paper-plane-top"></i>
            </div>

            <p class="title-bold text-center mb-3">{{"Are you sure you want to Submit the form?" | translate:language:ModuleNames.SurveyX}}</p>
        </div>
    </ng-container>

    <ng-container footer>
        <div class="flex flex-row justify-between w-full">
            <button (click)="close()"
                class="btn cancel text-center flex flex-row items-center rounded px-4 leading-12 text-md font-medium gap-1">
                {{"Go back" | translate:language:ModuleNames.SurveyX}}
            </button>
            <button
                class="btn text-center flex flex-row items-center rounded px-8 leading-12 text-md font-medium gap-1 submit-button"
                (click)="onSubmit()">
                {{"Submit Now" | translate:language:ModuleNames.SurveyX}}
            </button>
        </div>
    </ng-container>
</z-modal>
