
  <div *ngIf="surveyData" class="flex flex-row justify-center p-8 w-full">
    <div class="flex flex-col md:flex-row p-10 gap-10 bg-white rounded-md box-border shadow-024 max-w-3xl">
      <div class="flex flex-col flex-1 justify-start">

        <h5 [style.backgroundColor]="surveyData.colorTheme" class="font-medium text-xs capitalize text-white rounded-sm w-min  px-2 py-1 mb-4">
          {{surveyData.typeName | translate:language:ModuleNames.SurveyX}}
        </h5>


        <h4 class="pre-line leading-6 text-2xl text-zenMain font-medium">
          {{surveyData.title | sjsTranslate:language}}</h4>


        <h5 class="pre-line leading-6 pt-4 text-sm text-zenMain overflow-hidden h230px "
          [innerHTML]="parseMessage(surveyData?.welcomeMessage) | extract:language">

        </h5>

        <div class="flex flex-row justify-start gap-5 mt-6 text-zen">
          <div class="flex flex-row w-max gap-1.5">
            <div class="flex flex-col">
              <i class="fa-regular fa-comments-question-check text-base h-8"></i>
            </div>
            <div class="flex flex-col">
              <!-- <h6 class="text-zenMain text-xs">{{"txt_Includes" | translate:language:ModuleNames.SurveyX}}</h6> -->
              <h4 class=" text-xs font-normal mt-1"> {{questions}} {{"Questions" |
                translate:language:ModuleNames.SurveyX}}</h4>
            </div>

          </div>
          <div class="flex flex-row w-max gap-1.5">
            <div class="flex flex-col">
              <i class="fa-regular fa-clock text-base h-8 "></i>
            </div>
            <div class="flex flex-col">
              <!-- <h6 class="text-zenMain text-xs">{{"Time to Complete"| translate:language:ModuleNames.SurveyX}}</h6> -->
              <h4 class="text-xs font-normal mt-1 ">{{"Around"|
                translate:language:ModuleNames.SurveyX}} {{minute}} {{"minutes" |
                translate:language:ModuleNames.SurveyX}}</h4>

            </div>
          </div>
        </div>

      </div>

      <div class="flex-col flex-1 justify-start items-end gap-5 hidden md:flex">
        <img src="assets/img/svg/Visual.svg" alt="" class="w-72">
      </div>
    </div>
  </div>



  <div class=" flex flex-row box-border justify-center w-full h-110 pt-14">
    <div *ngIf="surveyData" class="flex flex-col w-48 h-16">
      <button [style.backgroundColor]="surveyData.colorTheme"
        class="flex  items-center justify-between text-white text-lg font-medium h-full w-full py-2 px-7 rounded"
        (click)="onStart()">
        {{'txt_start_survey'|translate:language:ModuleNames.Surveys}}
        <i class="fa-regular "
        [ngClass]="(language | language )?'fa-arrow-left-long':'fa-arrow-right-long'"></i>

      </button>
    </div>
  </div>






<!-- <z-footer [dir]="direction"></z-footer> -->
