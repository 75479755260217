<div class="flex flex-col border-opacity-20 zen-main-container w-full Page">
    <app-form-builder-header [surveyId]="surveyId" [surveyTitle]="survey.title" [currentPage]="'assign-weights'"
        [showAssignWeights]="true" (nextStep)="nextStep()" (previousStep)="previousStep()"></app-form-builder-header>

    <div class="w-full h-full">
        <div class="flex justify-center">
            <div class="w-6/12 py-8 mt-5">
                <div class="flex justify-between mb-4 w-full">
                    <div class="w-max">
                        <h3 class="page-title mb-1">
                            {{'Edit Weights'| translate:language:ModuleNames.SurveyX }}
                        </h3>

                        <p class="page-sub-title">
                            {{'Assign weight to questions and sections to emphasize important metrics in the analytics'|
                            translate:language:ModuleNames.SurveyX }}
                        </p>
                    </div>
                </div>

                <div
                    class="flex flex-row flex-wrap bg-white items-center px-8 pt-5 pb-10 mb-8 gap-3 justify-center rounded-md main-border">
                    <div class="w-full mb-5"
                        *ngFor="let section of surveyCreatorModel.survey.pages; let section_index = index">
                        <div class="section-header w-full flex justify-between px-8 py-3 mb-2">
                            <h5 class="section-header-title mt-1">
                                {{ section.locTitle['values']['en'] || section.name || '' }}
                            </h5>

                            <div class="w-max flex justify-end gap-5">
                                <input class="w-20 text-center weight-input" type="text"
                                    [value]="(1 / surveyCreatorModel.survey.pages.length * 100 | number: '1.0-1') + '%'">

                                <button class="btn btn-secondary btn-sm w-max">
                                    <i class="fas fa-angle-up"></i>
                                </button>
                            </div>
                        </div>

                        <div class="question-item w-full flex justify-between py-3 pr-8 pl-2 mb-1"
                            *ngFor="let question of section.questions">
                            <p class="question-header-title mt-1">
                                {{ question.locTitle['values']['en'] || question.title || '' }}
                            </p>

                            <div class="w-max flex justify-end gap-5">
                                <input class="w-20 text-center weight-input" type="text"
                                    [value]="(1 / section.questions.length * 100 | number: '1.0-1') + '%'">

                                <span class="w-max invisible">
                                    <i class="fas fa-angle-up"></i>
                                </span>
                            </div>
                        </div>

                        <div class="flex pr-16 w-full justify-end gap-5">
                            <p class="total-weight-title">
                                {{'Total Weight'| translate:language:ModuleNames.SurveyX }}
                            </p>

                            <p class="total-weight-value w-1/12 text-center">100%</p>
                        </div>
                    </div>

                    <div class="flex pr-16 w-full justify-end gap-5">
                        <p class="overall-weight-title">{{'Total Weight'| translate:language:ModuleNames.SurveyX }}</p>
                        <p class="overall-weight-value w-1/12 text-center">100%</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>