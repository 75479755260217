import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exit-new',
  templateUrl: './exit-new.component.html',
  styleUrls: ['./exit-new.component.scss']
})
export class ExitNewComponent implements OnInit {

  routes = [
    {
      name: 'txt_leavers',
      link: '/exit-v2/home',
      img: null,
      icon: 'person-to-door',
      title: 'leavers-list'
    },
    {
      name: 'txtTemplate',
      link: '/exit-v2/templates',
      img: null,
      icon: 'rectangle-history',
      title: 'survey-templates'
    },
    {
      name: 'txtAnalysis',
      link: '/exit-v2/analysis',
      img: null,
      icon: 'analytics',
      title: 'analysis-overview'
    },
  ]


  constructor() { }

  ngOnInit(): void {
  }

}
