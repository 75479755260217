<!-- modal -->
<div *ngIf="(entityAccountBalances)
|| notificationType == 'UNIT_CREDIT_COMPANY_SURVEY_BALANCE'"
  class="w-screen h-screen bg-zen bg-opacity-50 flex flex-row items-center justify-center fixed top-0 left-0 z-50 py-10">
  <div class="max-w-xl w-full flex flex-col bg-white bg-opacity-90 py-10 rounded">
    <div class="flex flex-row items-center justify-between px-10">
      <h2 class="text-zen text-2xl font-semibold">{{ title }}</h2>
      <i mat-dialog-close class="fa-regular fa-times   btn text-center text-xl text-zen text-opacity-70"></i>
    </div>
    <div class="flex flex-col gap-1 px-10 py-10 innerListScroll max-h-screen-50">
      <h5 class="font-normal opacity-80 text-center"> {{ message }} {{ notificationType ===
        'UNIT_CREDIT_MAIN_BALANCE' ? '' :'' }}</h5>
      <h5 class="text-lg text-center"> <span class="text-orange">{{"txt_unitsAdded"| translate:language:ModuleNames.Shared }} </span> {{ units }} - {{
        surveyName }}
      </h5>

      <!-- <h4 class="text-center">{{"txt_totalUnits"| translate:language:ModuleNames.Shared }} <span>{{
          surveyTotalUnits }}</span></h4> -->
    </div>
    <div class="flex flex-row justify-between items-center px-10">
      <a class="btn exp text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1"
        [mat-dialog-close]> {{"txt_close"| translate:language:ModuleNames.Shared }}</a>
    </div>
  </div>
</div>