import type { AuditedEntityDto, PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import type { EntityHierarchyDto } from '../../core/entities/models';
import type { CustomPropertyDto } from '../../core/models';

export interface BulkAssignToSurveyDto {
  emails: string[];
  isSelectAll: boolean;
}

export interface BulkDeleteRespondentDto {
  emails: string[];
  surveyKey: string;
  isDeleteAll: boolean;
}

export interface ExcelUploadRequestDto {
  surveyKey?: string;
  companyKey?: string;
  tenantKey?: string;
  excelFile?: string;
}

export interface ExcelUploadValidationDto {
  totalRecords: number;
  errors: ExcelUploadValidationErrorDto[];
}

export interface ExcelUploadValidationErrorDto {
  key?: string;
  value?: string;
}

export interface GetRespondentsDto extends PagedResultRequestDto {
  surveyKey?: string;
  filterText?: string;
  isAllResults: boolean;
  status?: string;
}

export interface RespondentDto extends AuditedEntityDto {
  name?: string;
  email?: string;
  phone?: string;
  surveyKey?: string;
  companyKey?: string;
  tenantKey?: string;
  employeeId?: string;
  results?: string;
  status?: string;
  entityHierarchy: EntityHierarchyDto[];
  surveyLink?: string;
}

export interface RespondentListPagedDto extends PagedResultDto<RespondentDto> {
  allSendReminderCount: number;
  allDeleteCount: number;
}

export interface UpdateRespondentDto {
  tenantKey?: string;
  companyKey: string;
  surveyKey?: string;
  employeeId?: string;
  email?: string;
  phone?: string;
  name?: string;
  dateOfBirth?: string;
  customProperties: CustomPropertyDto[];
}

export interface UpdateRespondentWithCriteriaRequestDto {
  respondent: UpdateRespondentDto;
}
