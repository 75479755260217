import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../core/services/language.service';
import { LocalService } from '../../../core/services/local.service';
import { MiscellaneousService } from '../../../core/services/miscellaneous.service';
import { LoaderService } from '../../../core/services/loader.service';
import { ZenSnackbarService, ZenSnackBarTypes } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { EngagementRespondent } from 'src/app/core/data-models/engagement-respondent';
import { RespondentService } from 'src/app/core/services/respondent.service';
declare var $: any;
@Component({
  selector: 'app-happiness-landing-page',
  templateUrl: './happiness-landing-page.component.html',
  styleUrls: ['./happiness-landing-page.component.scss']
})
export class HappinessLandingPageComponent implements OnInit {
  private subscriptions = new Subscription();
  language: string = 'EN';
  logoLoading: boolean = false;
  tenantLogo: string;
  base64Image: string;
  logoURL: string;
  tenantKeyExists: boolean = false;
  section: string = 'login';
  hide = true;
  showLangage: boolean = false;
  returnUrl: string;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  ModuleNames = ModuleName

  constructor(private route: ActivatedRoute,
    private languageService: LanguageService, private languagePipe: LanguagePipe,
    private localService: LocalService, private miscellanous: MiscellaneousService,
    private router: Router, public loaderService: LoaderService, private zenSnackBarService: ZenSnackbarService, private translationPipe: TranslatePipe, 
    private respondentService: RespondentService) {

  }


  form = {
    id: '',
    name: '',
    email: '',
  };
  valid = true;

  supportedLanguages: any = [];
  tenantKey: string;
  engagementKey: string;
  referenceKey: string;
  ngOnInit(): void {
    let tmdy = {
      hello: "hello",
      hi: "hi",
      bye: "bye"
    };

    this.referenceKey = this.route.snapshot.paramMap.get('refKey');
    if (this.referenceKey) {
      localStorage.setItem('LandingKey', this.referenceKey);
      this.supportedLanguages = this.languageService.supportedLanguages;

      this.logoURL = 'assets/img/svg/logo.svg'; // to be resolved to client's logo
      let localLanguage = this.localService.getJsonValue('language');
      let sessionLanguage = this.localService.getJsonValue('language');
      if (!localLanguage && !sessionLanguage) {
        this.language = 'EN';
      }
      else if (localLanguage) {
        this.language = localLanguage
      }
      else if (sessionLanguage) {
        this.language = sessionLanguage;

      }

      this.isArabic();

      this.languageService.language = this.language;
      // this.setUpLanguagePreference();
      // this.localService.removeItem('sectionID');
      // this.localService.removeItem('assessmentSectionID');
      // this.localService.removeItem('pageID');
      // this.localService.removeItem('selectedEngagement');
      // this.localService.removeItem('selectedEntity');
      // this.localService.removeItem('UserKey');
      // this.localService.removeItem('selectedAssessment');
      // this.localService.removeItem('timeout');
      // this.localService.removeItem('Access');
      // this.localService.removeItem('User');
      // this.localService.removeItem('pageReference');
      // this.localService.removeItem('localSettings');

      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    }
    this.canvasStart();
    this.loaderService.hideLoader();

  }
  // setUpLanguagePreference() {
  //   this.setUpTextFields();
  // }
  // txtWelcomeAboard: string;
  // txtwelcomedesc: string;
  // txtResetyourpassword: string;
  // txtResetPassworddesc: string;
  // txtUsername: string;
  // txtContinue: string;
  // txtLogin: string;
  // txtPassword: string;
  // txtForgotYourPassword: string;
  // txtBacktoLogin: string;
  // txtCheckyourEmaildesc: string;
  // txtCheckyourEmail: string;
  // txtHidepassword: string;
  // txtCheckEmails: string;
  // txtEnterEmail: string;
  // txtWrongInfo: string;
  // txtEmailPssword: string;
  // txtUserEmail: string;
  // txtEnterInformation: string;
  // txtID: string;
  // txt_required_field: string;
  // txtWrongPattern: string;
  // txt_okay_action: string;
  // setUpTextFields() {
  //   this.txt_okay_action = this.languageService.getUILanguage("txt_okay_action", this.language);
  //   this.txtWrongPattern = this.languageService.getUILanguage("txtWrongPattern", this.language);
  //   this.txt_required_field = this.languageService.getUILanguage("txt_required_field", this.language);
  //   this.txtID = this.languageService.getUILanguage("txtID", this.language);
  //   this.txtEnterInformation = this.languageService.getUILanguage("txtEnterInformation", this.language);
  //   this.txtWrongInfo = this.languageService.getUILanguage("txtWrongInfo", this.language);
  //   this.txtEmailPssword = this.languageService.getUILanguage("txtEmailPssword", this.language);
  //   this.txtCheckEmails = this.languageService.getUILanguage("txtCheckEmails", this.language);
  //   this.txtEnterEmail = this.languageService.getUILanguage("txtEnterEmail", this.language);
  //   this.txtWelcomeAboard = this.languageService.getUILanguage("txtWelcomeAboard", this.language);
  //   this.txtwelcomedesc = this.languageService.getUILanguage("txtwelcomedesc", this.language);
  //   this.txtResetyourpassword = this.languageService.getUILanguage("txtResetyourpassword", this.language);
  //   this.txtResetPassworddesc = this.languageService.getUILanguage("txtResetPassworddesc", this.language);
  //   this.txtUsername = this.languageService.getUILanguage("txtUsername", this.language);
  //   this.txtContinue = this.languageService.getUILanguage("txtContinue", this.language);
  //   this.txtLogin = this.languageService.getUILanguage("txtLogin", this.language);
  //   this.txtPassword = this.languageService.getUILanguage("txtPassword", this.language);
  //   this.txtForgotYourPassword = this.languageService.getUILanguage("txtForgotYourPassword", this.language);
  //   this.txtBacktoLogin = this.languageService.getUILanguage("txtBacktoLogin", this.language);
  //   this.txtCheckyourEmaildesc = this.languageService.getUILanguage("txtCheckyourEmaildesc", this.language);
  //   this.txtCheckyourEmail = this.languageService.getUILanguage("txtCheckyourEmail", this.language);
  //   this.txtHidepassword = this.languageService.getUILanguage("txtHidepassword", this.language);
  //   this.txtUserEmail = this.languageService.getUILanguage("txt_user_email", this.language);
  // }



  closeDropdown(menu) {
    if (this[menu]) this[menu] = false;
  }
  selectedLanguageChange(event) {
    this.language = event;
    this.localService.setJsonValue('language', this.language);

    this.isArabic()
  }




  isArabic() {
    if (this.languagePipe.transform(this.language)) {
      $("body").removeClass('ltr')
      $("body").addClass('rtl').attr("dir", "rtl");

    }
    else {
      $("body").removeClass('rtl')
      $("body").addClass('ltr').attr("dir", "ltr")

    }
  }





  waitingForResponse: boolean = false;
  onSubmit(event?) {
    if (this.form.id) {
      if (!this.form.name)
        this.form.name = this.form.id;

      console.log(this.form.name);

      let request = {
        name: this.form.name, employeeID: this.form.id, email: this.form.email,
        referenceKey: this.referenceKey
      };
      this.waitingForResponse = true;
      this.subscriptions.add(this.miscellanous.createHappinessRespondentFromLandingPage(request).subscribe((response: any) => {
        if (response.response == 'SUCCESS') {
          let respondent = response.data.respondent;
          let companyName = response.data.companyName;
          let surveyInfo = response.data.surveyInfo;
          let reses: EngagementRespondent[] = [];
          reses.push(respondent);
          let request = {
            SurveyType: "HAP",
            SurveyKey: surveyInfo.key,
            CompanyKey: respondent.companyKey,
            TenantKey: respondent.tenantKey,
            fromLanding: true,
            respondents: reses
          }
          console.log(respondent);
          this.respondentService.updateSurveyRespondent(request).subscribe((response: any) => {
            if (response.response == 'SUCCESS') {
              localStorage.setItem('LandingPageRef', respondent.referenceKey);
              this.router.navigate([
                '/happiness-questionnaire/' + respondent.referenceKey,
              ]);
            } else if(response.response == 'ERROR_EXISTS'){
              this.waitingForResponse = false;
              this.zenSnackBarService.snackBar({message:this.translationPipe.transform('txt_Linkhasal_237', this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error});
            }
        });

        } else {
          if (response.message == 'INSUFFICIENT_FUNDS') {
            this.waitingForResponse = false;
            this.zenSnackBarService.snackBar({message:this.translationPipe.transform('txt_Insuffici_313', this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error});
          }
        }
      }));
    }
    else {
      this.valid = false;
      this.zenSnackBarService.snackBar({message:this.translationPipe.transform('txtEnterInformation', this.language, this.ModuleNames.Happiness), type: ZenSnackBarTypes.Error });
    }
  }


  canvasStart() {
    var canvas = <HTMLCanvasElement>document.getElementById('c');
    var ctx = canvas.getContext('2d');
    let colors = ['#fbfbfb', '#f7f7f8', '#f4f4f4', '#f0f0f1', '#ededee', '#e9e9ea']
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
    let speed = 1;

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let color = 0; color < colors.length; color++) {
        ctx.beginPath();
        ctx.moveTo(-200, (canvas.height / 10 + Math.sin(speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.bezierCurveTo(
          canvas.width / 2,
          (canvas.height / 4 + Math.cos(speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width / 4,
          (canvas.height / 4 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width + 200,
          (canvas.height / 3 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.lineWidth = 130 + color * 100 / 2;
        ctx.strokeStyle = colors[color];
        ctx.stroke();
      }
      speed += .5;
    }

    animate();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
