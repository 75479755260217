import { Injectable } from '@angular/core';

@Injectable({
  providedIn: null
})
export class WellbeingScalesService {

  static VeryPoorExperience = "VPOOREXP";
  static PoorExperience = "POOREXP";
  static FairExperience = "FAIREXP";
  static GoodExperience = "GOODEXP";
  static VeryGoodExperience = "VGOODEXP";

  config = [{
    key: WellbeingScalesService.VeryPoorExperience,
    color: "#FF4530",
  },
  {
    key: WellbeingScalesService.PoorExperience,
    color: "#FF9600"
  },
  {
    key: WellbeingScalesService.FairExperience,
    color: "#FFC51C"
  },
  {
    key: WellbeingScalesService.GoodExperience,
    color: "#B3DD37"
  },
  {
    key: WellbeingScalesService.VeryGoodExperience,
    color: "#3EB75B"
  }]

  constructor() { }

  resolveChartColor(value: any) {

    if (value >= 20 && value < 40) {
      return this.getColorByCode(WellbeingScalesService.VeryPoorExperience);
    }
    else if (value >= 40 && value < 60) {
      return this.getColorByCode(WellbeingScalesService.PoorExperience);
    }
    else if (value >= 60 && value < 80) {
      return this.getColorByCode(WellbeingScalesService.FairExperience);
    }
    else if (value >= 80 && value < 90) {
      return this.getColorByCode(WellbeingScalesService.GoodExperience);
    }
    else if (value >= 90 && value <= 100) {
      return this.getColorByCode(WellbeingScalesService.VeryGoodExperience);
    }

    return "N/A";
  }

  resolveAnswerColor(value: any) {

    const colors = {
      1: 'bg-zen-red',
      2: 'bg-zen-orange',
      3: 'bg-zen-yellow',
      4: 'bg-zen-lightgreen',
      5: 'bg-zen-green'
    }

    return colors[value];
  }

  getExperienceCode(percentage: number) {

    if (percentage >= 20 && percentage < 40) {
      return WellbeingScalesService.VeryPoorExperience;
    }
    else if (percentage >= 40 && percentage < 60) {
      return WellbeingScalesService.PoorExperience;
    }
    else if (percentage >= 60 && percentage < 80) {
      return WellbeingScalesService.FairExperience;
    }
    else if (percentage >= 80 && percentage < 90) {
      return WellbeingScalesService.GoodExperience;
    }
    else if (percentage >= 90 && percentage <= 100) {
      return WellbeingScalesService.VeryGoodExperience;
    }

    return "N/A";
  }

  getColorByCode(code: string) {

    switch (code) {
      case WellbeingScalesService.VeryPoorExperience:
        return this.config.find(x => x.key == WellbeingScalesService.VeryPoorExperience)?.color;
      case WellbeingScalesService.PoorExperience:
        return this.config.find(x => x.key == WellbeingScalesService.PoorExperience)?.color;
      case WellbeingScalesService.FairExperience:
        return this.config.find(x => x.key == WellbeingScalesService.FairExperience)?.color;
      case WellbeingScalesService.GoodExperience:
        return this.config.find(x => x.key == WellbeingScalesService.GoodExperience)?.color;
      case WellbeingScalesService.VeryGoodExperience:
        return this.config.find(x => x.key == WellbeingScalesService.VeryGoodExperience)?.color;
    }

  }


}
