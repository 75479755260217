import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showExtraFactor'
})
export class ShowExtraFactorPipe implements PipeTransform {

  transform(values: any[], show: boolean): unknown {
    if (show)
      return values;

    return values.filter(x => x.isPredefined == true);
  }

}
