import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { map } from 'rxjs/operators';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class RespondentService {
  sEndpoint: string = 'https://apis.zenithr.net/api/';//http://localhost:52619/api/'https://apis.zenithr.net/api/';//

  constructor(private db: AngularFireDatabase, public afAuth: AngularFireAuth, private http: HttpClient, private globals: GlobalsService) {
    this.sEndpoint = this.globals.sEndpoint;
  }

  smsAuthTemplate(tenantKey: string, companyKey: string, surveyKey: string) {
    let authTemplate = {
      sender: 'Almarai',
      message: '#SENDER# has invited you to complete the Employee Engagement Survey below before #DEADLINE#. #SURVEYLINK#',
    }
    return this.db.object<any>('TenantData/' + tenantKey + '/SMSAuthorization/' + companyKey).update(authTemplate);
  }

  deleteSurveyRespondent(request) {
    return this.http.post(this.sEndpoint + 'Respondent/DeleteSurveyRespondent', request);
  }


  bulkDeleteRespondents(request) {
    return this.http.post(this.sEndpoint + 'Respondent/BulkDeleteSurveyRespondent', request);
  }


  bulkAddSurveyRespondent(request) {
    if (request.SurveyType == 'ASM')
      return this.http.post(this.sEndpoint + 'Assessment/BulkInsertSurveyRespondents', request);
    else if (request.SurveyType == 'ASM180')
      return this.http.post(this.sEndpoint + 'Assessment180/BulkInsertSurveyRespondents', request);
    else
      return this.http.post(this.sEndpoint + 'Respondent/BulkInsertSurveyRespondents', request);
  }

  getNumberOfRecordsToAdd(request) {
    return this.http.post(this.sEndpoint + 'Respondent/GetBulkSurveyRespondents', request);
  }


  deletePoolRespondent(request) {
    return this.http.post(this.sEndpoint + 'Respondent/DeletePoolRespondent', request);
  }


  bulkDeletePoolRespondent(request) {
    return this.http.post(this.sEndpoint + 'Respondent/BulkDeletePoolRespondent', request);
  }
  downloadHeatmap(request) {
    return this.http.post(this.sEndpoint + 'Respondent/DownloadHeatmap', request);
  }

  updateRespondentPool(request) {
    return this.http.post(this.sEndpoint + 'Respondent/UpdateRespondentPool', request);
  }

  fetchRespondentCount(request) {
    return this.http.post(this.sEndpoint + 'Respondent/GetNumberOfRespondents', request);
  }

  fetchPoolRespondent(request) {
    return this.http.post(this.sEndpoint + 'Respondent/FetchPoolRespondent', request);

  }


  updloadRespondents(request) {
    if (request.SurveyType == 'ASM')
      return this.http.post(this.sEndpoint + 'Assessment/UploadRespondents', request);
    else if (request.SurveyType == 'ASM180')
      return this.http.post(this.sEndpoint + 'Assessment180/UploadRespondents', request);
    else
      return this.http.post(this.sEndpoint + 'Respondent/UploadRespondents', request);

  }

  updloadRespondentsCount(request) {
    return this.http.post(this.sEndpoint + 'Respondent/GetUploadRespondents', request);
  }

  downloadSurveyRespondents(request) {
    return this.http.post(this.sEndpoint + 'Respondent/DownloadSurveyRespondents', request);
  }


  fetchSurveyRespondets(request) {
    return this.http.post(this.sEndpoint + 'Respondent/FetchSurveyRespondets', request);
  }

  fetchPoolUpdates(tenantKey: string, companyKey: string) {
    return this.db.object<object>('/TenantData/' + tenantKey + '/RespondentListUpdates/' + companyKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        )
      );
  }


  fetchSurveyStat(tenantKey: string, companyKey: string, engagementKey: string) {
    return this.db.object<object>('/TenantData/' + tenantKey + '/SurveyStats/' + companyKey + "/" + engagementKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        )
      );
  }

  fetchRespondentSurveyStats(request) {
    return this.http.post(this.sEndpoint + 'Respondent/FetchRespondentStats', request);
  }

  updateSurveyRespondent(request) {
    return this.http.post(this.sEndpoint + 'Respondent/UpdateSurveyRespondent', request);
  }



}
