import { Injectable } from '@angular/core';
import { SurveyCriteriaDto } from '@ex/wellbeing/surveys';
import { AnalysisFilterKeys } from './analytics.service';
import { KeyNameDto } from '@ex/core/common';
import { GeneralService } from 'src/app/core/services/general.service';

@Injectable({
  providedIn: null
})
export class SurveyCriteriaService {

  constructor(private generalService: GeneralService) { }

  process(criterias: { key: string, name: KeyNameDto[], isCustom: boolean }[]) {
    const criteriaList = [];
    for (let criteria of criterias) {

      switch (criteria.key) {

        case AnalysisFilterKeys.DateOfBirth:

          const ageGroups = this.generalService.ageGroups_new;
          const generation = this.generalService.generations_new

          criteriaList.push({
            key: AnalysisFilterKeys.AgeGroups,
            name: this.generalService.fixedGroupingOptions.find(group=> group.key == AnalysisFilterKeys.AgeGroups).name,
            options: ageGroups
          });
          criteriaList.push({
            key: AnalysisFilterKeys.Generation,
            name: this.generalService.fixedGroupingOptions.find(group=> group.key == AnalysisFilterKeys.Generation).name,
            options: generation
          });
          break;

        case AnalysisFilterKeys.DateOfJoining:

          const tenureGroups = this.generalService.tenureGroups_new;

          criteriaList.push({
            key: AnalysisFilterKeys.TenureGroups,
            name: this.generalService.fixedGroupingOptions.find(group=> group.key == AnalysisFilterKeys.TenureGroups).name,
            options: tenureGroups
          });
          break;

        default:
          criteriaList.push(criteria);
      }

    }

    return criteriaList;
  }

}
