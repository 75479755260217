export class CompanyPermission {
    key: string; // Company Key
    products: string[] = []; // EXIT, ENGAGEMENT, 360, HAPPINESS
}

export enum products {
    OnBoarding = "ONBOARDING",
    Engagement = "ENGAGEMENT",
    Happiness = "HAPPINESS",
    Wellbeing = "Wellbeing",
    Assessment = "360",
    Assessment180 = "180",
    Psychometric = "PSYCHOMETRIC",
    Exit = "EXIT",
    Settings = "settings",
    Overview = "overview",    
}
export const productsList: {
    value: string;
}[] = Object.values(products)
    .filter((value) => typeof value === "string")
    .map((value) => ({ value: value as string }));