import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { EngagementMetric } from 'src/app/core/data-models/engagement-metric';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ModalComponent } from '../modal/modal.component';
import {
  ChildrenAnimation,
  listAnimation,
} from '../../shared/animations/allAnimations';
import { ModuleName } from '../enums/ModuleName';
import {
  ZenSnackbarService,
  ZenSnackBarTypes,
} from '../Theme/services/zen-snackbar.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { Engagement } from 'src/app/core/data-models/engagement';

@Component({
  selector: 'select-criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria.component.scss'],
  animations: [ChildrenAnimation, listAnimation],
})
export class CriteriaComponent extends ModalComponent implements OnInit {
  constructor(
    private languageService: LanguageService,
    private db: AngularFireDatabase,
    private _globals: GlobalsService,
    private zenSnackBarService: ZenSnackbarService,
    private translationPipe: TranslatePipe
  ) {
    super();
    this.hugeCount = _globals.hugeCount;
    this.lgCount = _globals.lgCount;
    this.mdCount = _globals.mdCount;
    this.smCount = _globals.smCount;
  }
  @ContentChild(TemplateRef) header: TemplateRef<any>;
  ModuleNames = ModuleName;
  criteriaList;
  items = [];
  criteria: boolean = true;
  invalid: boolean = false;
  loading: boolean = false;
  criteriaAdd;
  newMetric: EngagementMetric;
  vMetricName;
  vMetricOptions;
  hugeCount;
  lgCount;
  mdCount;
  smCount;

  @Input('engagement') survey: Engagement;

  @Input('language') language;
  @Input() selection: boolean = true;
  @Input('selectedEntity') selectedEntity;
  @Input('matrixList') set list(criterias: EngagementMetric) {
    this.criteriaList = criterias;
    this.txtWrongTextCount = this.translationPipe.transform(
      'txt_wrong_text_count',
      this.language,
      this.ModuleNames.Shared
    );
    this.txtFillFields = this.translationPipe.transform(
      'txt_fill_fields',
      this.language,
      this.ModuleNames.Shared
    );
    this.txtMinimumTwoOptions = this.translationPipe.transform(
      'txtMinimumTwoOptions',
      this.language,
      this.ModuleNames.Shared
    );
    if (this.criteriaList?.length) this.animateList(criterias);

    this.loading = false;
    this.survey.predefinedMetrics = true;
  }
  txtMinimumTwoOptions;
  txtFillFields;
  txtWrongTextCount;
  @Input('modal') set modal(open) {
    if (open) this.criteriaAddFunction();
    else this.closeModal('criteriaAdd');
  }
  @Output() modalChange = new EventEmitter();
  @Input() engagementMetrics;
  @Output('updateEngagementMetrics') updateEngagementMetrics =
    new EventEmitter();

  @Input() tourKey;
  @Input() tourRespondednt;
  answerIncreaseClick() {
    if (!this.newMetric.options) this.newMetric.options = [];

    let option = { key: this.db.createPushId(), name: [] };
    this.newMetric.options.push(option);
  }
  deleteOption(option) {
    const index = this.newMetric.options.indexOf(option);
    this.newMetric.options.splice(index, 1);
  }
  deleteCriteria(criteria) {
    this.updateEngagementMetrics.emit({
      metric: criteria,
      tKey: this.criteriaList.indexOf(criteria),
    });
  }

  ngOnInit(): void {
    console.log(this.tourKey);
  }

  // localize(key: string) {
  //   return this.languageService.getUILanguage(key, this.language);
  // }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  criteriaIsSelected(key) {
    if (this.engagementMetrics?.find((mt) => mt.key == key)) return true;
    else return false;
  }
  logData(e) {
    console.log(e);
  }
  edited;
  criteriaAddFunction(criteria?) {
    this.criteriaAdd = true;
    this.invalid = false;
    if (!criteria) {
      this.edited = false;
      this.newMetric = new EngagementMetric();
      this.newMetric.key = this.db.createPushId();
      this.newMetric.isCustom = true;
      this.answerIncreaseClick();
    } else {
      this.edited = true;
      this.newMetric = criteria;
    }
  }
  criteriaAddExisting(key, criteria) {
    this.newMetric = new EngagementMetric();
    this.newMetric = criteria;
    this.newMetric.key = key;
    this.newMetric.isEntity = true;

    this.updateEngagementMetrics.emit(this.newMetric);
  }
  duplicatedCriteria(criteria) {
    this.engagementMetrics.forEach((element) => {
      return element.name[0].name != criteria.name[0].name;
    });
    //
  }

  countWord(word, count) {
    if (word?.length <= count) return word.length;
    if (word?.length > count) {
      return word.length;
    }
  }
  updateSelectedMetrics(engagementMetric, x) {
    this.loading = true;
    if (!this.engagementMetrics?.find((mt) => mt.key == engagementMetric.key)) {
      this.updateEngagementMetrics.emit(engagementMetric);
    } else {
      this.updateEngagementMetrics.emit({ metric: engagementMetric, tKey: x });
    }
  }

  validateCriteria(engagementMetric: EngagementMetric) {
    let isValid: boolean = true;
    if (
      engagementMetric.name.filter((d) => d.name == '').length ==
      engagementMetric.name.length
    ) {
      this.vMetricName = true;
      isValid = false;
    } else {
      this.vMetricName = false;
    }
    console.log(engagementMetric.options?.length);

    if (
      engagementMetric.options.length < 2 ||
      !engagementMetric.options?.length
    ) {
      this.zenSnackBarService.snackBar({
        message: this.txtMinimumTwoOptions,
        type: ZenSnackBarTypes.Error,
      });
      return (isValid = false);
    }

    for (let i = 0; i < engagementMetric.options?.length; i++) {
      let option = engagementMetric.options[i];
      if (this.extractNameLanguage(option.name) == '') {
        isValid = false;
      }
      if (!this.validateCount(option.name, this.mdCount)) return;
    }

    if (!isValid)
      this.zenSnackBarService.snackBar({
        message: this.txtFillFields,
        type: ZenSnackBarTypes.Error,
      });

    if (!this.validateCount(engagementMetric.name, this.mdCount, 'x')) return;

    return isValid;
  }

  validateCount(value, count, ready?) {
    let validatedCount;
    if (ready == 'x') {
      validatedCount = this.countWord(value, count);
    } else
      validatedCount = this.countWord(this.extractNameLanguage(value), count);
    if (validatedCount > count) {
      this.zenSnackBarService.snackBar({
        message: this.txtWrongTextCount,
        type: ZenSnackBarTypes.Error,
      });
      return false;
    }
    if (validatedCount <= count) {
      return true;
    }
  }
  updateMetric(newMetric) {
    this.invalid = true;
    if (this.validateCriteria(newMetric)) {
      this.updateEngagementMetrics.emit(newMetric);
      this.closeModal('criteriaAdd');
      this.modalChange.emit(false);
    }
  }

  animateList(list) {
    for (let index = 0; index < list.length; index++) {
      this.items.push(list[index]);
    }
  }
}
