import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../core/domain/base-component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-surveyx',
  templateUrl: './surveyx.component.html',
  styleUrls: ['./surveyx.component.scss']
})
export class SurveyxComponent extends BaseComponent implements OnInit {


  routes = [{
    name: 'Form Library',
    link: '/survey-x/form-library',
    img: null,
    icon: 'clipboard-list'
  },
  {
    name: 'Respondents',
    link: '/survey-x/respondents',
    img: null,
    icon: 'users'
  },
    // {
    //   name: 'Form Bank',
    //   link: '/survey-x/form-bank',
    //   img: null,
    //   icon: 'building-columns'
    // },
  ]

  constructor() {
    super();
    this.addSubscription(this.EssentialObject$.pipe(filter((x:any)=> x.tenant?.key)).subscribe(x => {
      localStorage.setItem("tenant", JSON.stringify(x.tenant))
      localStorage.setItem("user", JSON.stringify(x.user));
      localStorage.setItem("TenantKey", x.tenant.key)
    }));
  }

  ngOnInit(): void {
  }

}
