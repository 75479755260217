import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService {

  private questionCharts = new BehaviorSubject([]);
  private respondentCharts = new BehaviorSubject([]);

  questionCharts$ = this.questionCharts.asObservable();
  respondentCharts$ = this.respondentCharts.asObservable();

  constructor() { }

  setQuestionCharts(charts) {
    this.questionCharts.next(charts);
  }

  setRespondentCharts(charts) {
    this.respondentCharts.next(charts);
  }

}
