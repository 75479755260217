<z-modal [open]="open" [autoWidth]="true">
  <ng-container header>
    <h2 class="text-zen text-2xl font-semibold">{{ "txtShareSurvey" | translate:language:ModuleNames.SurveyX }}
    </h2>
    <i (click)="closeModal()"
      class="fa-regular fa-times leading-12 btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col gap-5 items-center w-screen max-w-lg">
      <qr-code [value]="link" size="200" errorCorrectionLevel="M" margin="0" centerImageSize="35" centerImageSrc="assets/img/icon.png"></qr-code>
      <div class="flex flex-row gap-5 w-full">
        <div class="flex flex-col w-full items-stretch">
          <z-input [language]="language" disabled [ngModel]="link" ></z-input>
        </div>
        <div class="flex flex-row items-center gap-2 w-1/12">
          <i [matTooltip]="'Copy'| translate:language:ModuleNames.SurveyX" (click)="copyToClipboard(link)"
            [ngClass]="copied?'text-zen-green':'text-zen'" class="fal fa-copy btn text-2xl"></i>
          <a target="_blank" [href]="link"><i class="fad fa-external-link btn text-2xl text-zen-blueaccent"></i></a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container footer class="flex w-full flex-col items-stretch">
    <a (click)="closeModal()"
      class="btn leading-10 rounded-sm cancel px-5 text-sm font-medium justify-center text-center w-full">{{'btn_cancel'|
      translate:language:ModuleNames.SurveyX}}</a>

  </ng-container>
</z-modal>
