import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../core/services/auth-guard.service';
import { WbListComponent } from './features/list/list.component';
import { WbLiveSurveyComponent } from './features/live-survey/live-survey.component';
import { WbSetupComponent } from './features/setup/setup.component';
import { WbPeopleComponent } from './features/wb-people/wb-people.component';
import { WellbeingComponent } from './wellbeing.component';
import { ClosedSurveyComponent } from './features/closed-survey/closed-survey.component';
import { WellbeingComparisonComponent } from './features/wellbeing-comparison/wellbeing-comparison.component';
import { ComparisonExportComponent } from './features/wellbeing-comparison/comparison-export/comparison-export.component';
import { PeopleComponent } from '../shared/people/people.component';

const routes: Routes = [{
  path: '', component: WellbeingComponent, children: [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: WbListComponent, canActivate: [AuthGuardService], data: { animation: 1, title: " Employee Wellbeing" } },
    { path: 'set-up/:id', component: WbSetupComponent, canActivate: [AuthGuardService], data: { animation: 2, title: " Employee Wellbeing" } },
    { path: 'live/:id', component: WbLiveSurveyComponent, canActivate: [AuthGuardService], data: { animation: 2, title: " Employee Wellbeing" } },
    { path: 'closed/:id', component: ClosedSurveyComponent, canActivate: [AuthGuardService], data: { animation: 2, title: " Employee Wellbeing" } },
    // { path: 'people', component: WbPeopleComponent, canActivate: [AuthGuardService], data: { animation: 3, title: " Employee Wellbeing" } },
    { path: 'people', component: PeopleComponent, canActivate: [AuthGuardService], data: {animation: 3, title: " Employee Wellbeing" }},
    { path: 'comparison', component: WellbeingComparisonComponent, canActivate: [AuthGuardService], data: { animation: 3, title: " Employee Wellbeing" } },
    { path: 'comparison-export', component: ComparisonExportComponent, canActivate: [AuthGuardService], data: { animation: 3, title: " Comparison Export" } }
  ]
},
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WellbeingRoutingModule { }
