<div class="flex flex-col w-full">
  <div class="flex flex-col w-full gap-5">
    <!-- *ngIf="selectAll && totalRecords /pageSize > 1" -->

    <t-table #table (selectAll)="selectAllFromTable($event)" (selectionChanges)="selectedRespondentsUpdate($event)"
      [options]="options" selectionKey="id">

      <ng-template #cellTempate let-element let-column='column' let-colIndex='colIndex'>
        <div *ngIf="column.field != 'action' && column.field != 'status' && column.field != 'view'"
          [ngClass]="colIndex != 0?'h-'+ rowHeight:''" class=" flex flex-col justify-center">
          <h5 class="max-w-xs truncate text-sm text-zen">
            {{ element[column.field] == 'Anonymous'?('Anonymous'| translate:language:ModuleNames.SurveyX): column.field
            == 'respondentEmail'? emailCheck(element[column.field]):element[column.field] }}
          </h5>
        </div>
        <div *ngIf="column.field == 'status'">
          <div class="w-max flex flex-col gap-3 justify-start items-start px-0">
            <div class=" truncate flex flex-row gap-2 items-center  text-sm text-zen">
              <i [class]="globalStatus.returnClassColor(statusReplace(element.status))"
                class="fa-regular leading-5"></i>
              <span class="">{{globalStatus.returnClassName(statusReplace(element.status), language)}}</span>
            </div>
          </div>
        </div>

        <div class="w-full flex flex-col gap-3 justify-start items-end px-0"
          *ngIf="column.field == 'view' && element.status == rStatus.Completed && element.respondentEmail!='Anonymous'">
          <a [routerLink]="'/survey-x/form-library/answers/'+ element.id"
            class="btn openPath whitespace-nowrap leading-9 px-4 rounded text-zen font-medium text-xs bg-zen bg-opacity-5 opacity-0 pointer-events-none">
            {{'Open'| translate:language:ModuleNames.SurveyX}}<i class="fas  leading-9"
              [ngClass]="(language | language )?'fa-caret-left':'fa-caret-right'"></i>
          </a>
        </div>


        <div class="flex flex-row justify-end items-center" *ngIf="column.field == 'action'">
          <i *ngIf="canDelete && !optionsTemplate" (click)="singleDelete.emit(element)"
            class="fa-regular text-sm fa-trash btn text-zen-red p-2"></i>
          <ng-template *ngTemplateOutlet="optionsTemplate; context:{$implicit:element}"></ng-template>
        </div>
      </ng-template>

      <ng-container headerOptions>
        <div class="flex flex-col items-stretch w-full gap-5">
          <div class="flex flex-row justify-between items-stretch w-full" *ngIf="!customHeader">
            <div class="flex flex-row justify-start items-center gap-2.5">
              <div class="flex flex-row items-stretch">
                <div
                  class="text-sm rounded text-zen bg-white border-zen border border-opacity-10 px-3 h-9 gap-2 leading-9 truncate flex flex-row justify-start items-center">
                  <i [ngClass]="{'rotate-h-45':(language|language)}" class="fal fa-search text-zen transform"></i>
                  <input [(ngModel)]="searchText" (ngModelChange)="searchTextChange.emit($event)"
                    [placeholder]="'txt_search' | translate:language:ModuleNames.Shared"
                    class="bg-transparent overflow-hidden h-12 leading-12 text-sm  w-80" type="text">
                </div>
              </div>
              <span class="w-0 h-auto border-r border-zen border-opacity-20" *ngIf="search"></span>
              <ng-container *ngIf="currentRespondents?.length && options?.data?.length">
                <a *ngIf="canDelete" (click)="bulkDeleteAll()" class="btn text-zen font-medium text-sm">
                  <i class="fa-regular fa-trash text-zen-red"></i>
                  {{ "txt_DeleteSel_329" | translate:language:ModuleNames.Wellbeing }}
                </a>
              </ng-container>
            </div>
            <div class="flex flex-row items-stretch justify-end">
              <a *ngIf="exportable && totalRecords" (click)="export.emit(true)" zenButton styles="secondary height-9">
                <i class="far fa-file-export text-exp text-sm"></i>
                <span class="text-xs">{{ 'btnExport' | translate:language:ModuleNames.Wellbeing }}</span>
              </a>
            </div>
          </div>
          <!-- <div *ngIf="tableSelectAll && totalRecords /pageSize > 1 && currentRespondents?.length"
            class="flex flex-row items-center justify-center h-15 bg-zen bg-opacity-10 rounded-md">
            <div *ngIf="customSelectedAll" class="text-zen text-xs flex flex-row items-center gap-4">
              {{replaceCount('txtAllRespondentsFromPages', deleteCount?deleteCount:totalRecords)}} <a zenButton
                (click)="clear()" styles="secondary height-9">{{ "txt_ClearSele_284" |
                translate:language:ModuleNames.Wellbeing }}</a></div>
            <h5 *ngIf="!customSelectedAll" class="text-zen text-xs">{{replaceCount('txtSelectedRespondentsOnPage',
              currentRespondents?.length)}} <span (click)="selectAllRespondents()"
                class="text-exp btn">{{replaceCount('txtSelectAllRespondentsFromPages',
                deleteCount?deleteCount:totalRecords)}}</span> </h5>
          </div> -->
        </div>
      </ng-container>
    </t-table>
  </div>

  <t-paginator *ngIf="options?.data?.length" [page]="currentPage" [language]="language" [options]="options"
    [pageSize]="pageSize" [totalRecords]="totalRecords || 0" (pageChanged)="pageChanged.emit($event)"></t-paginator>

</div>
