<div class="px-5 flex flex-col items-stretch max-w-4xl minw-96" [style.direction]="(language|language)?'rtl':'ltr'">
    <div [@fadeUpDown] *ngIf="show"
        class="flex flex-row justify-between items-stretch px-5 py-4 rounded-md z-max bottom-0 height-fit "
        [ngClass]="{'bg-zen-red':type == 'error','bg-int':type == 'info','bg-zen-orange':type == 'warning','bg-zen-green':type == 'success'}">
        <div class="flex flex-row gap-3">
            <i [ngClass]="{'fa-times-circle':type == 'error','fa-circle-exclamation':type == 'info','fa-triangle-exclamation':type == 'warning','fa-check-circle':type == 'success'}"
                class="fa-regular text-5xl text-white"></i>
            <div class="flex flex-col gap-2 w-96">
                <h5 class="text-lg text-white firstLetterUpper">{{ type|translate:language:ModuleNames.Shared }}</h5>
                <p class="text-sm text-white whitespace-normal textStart">{{ message }}</p>
            </div>
            <!-- <i class="fa-light fa-times btn bg-transparent text-white" (click)="data.preClose();"></i> -->
        </div>

    </div>
</div>