import type { KeyNameDto } from '../../core/common/models';
import type { CustomPropertyDto } from '../../core/models';
import type { TimeFrameDto } from '../reports/models';

export interface AnalysisFilterDto {
  key?: string;
  value: object;
}

export interface AnalysisFilterRequestDto {
  tenantKey: string;
  companyKey: string;
  surveyKey: string;
  type?: string;
  filters: AnalysisFilterDto[];
}

export interface AnswerScaleDto {
  value: number;
  percentage: number;
}

export interface CriteriaAnalysisResultDto {
  category?: string;
  value: object;
  group?: string;
  value1: object;
  value2: object;
}

export interface DimensionOverallAnalysisDto {
  name: KeyNameDto[];
  key?: string;
  sortOrder: number;
  score: number;
  scale?: string;
}

export interface EntityCountDto {
  entityKey?: string;
  totalCount: number;
}

export interface HeatSource {
  key?: string;
  parentKey?: string;
  isDriver: boolean;
  name: KeyNameDto[];
  overall: number;
  options: number[];
}

export interface HeatmapDto {
  heatColumns: string[];
  heatSource: HeatSource[];
}

export interface QuestionMeanDto {
  name: KeyNameDto[];
  key?: string;
  mean: number;
  answers: AnswerScaleDto[];
}

export interface RespondentAnalysisFilterRequestDto extends AnalysisFilterRequestDto {
  searchKey?: string;
  skipCount: number;
  maxResultCount: number;
}

export interface RespondentsListWithCriteriaDto {
  employeeId?: string;
  email?: string;
  phone?: string;
  name?: string;
  status?: string;
  customProperties: CustomPropertyDto[];
}

export interface SectionMeanDto {
  name: KeyNameDto[];
  key?: string;
  scale?: string;
  mean: number;
  isFactor: boolean;
  isPredefined: boolean;
  questions: QuestionMeanDto[];
}

export interface SectionRespondentsIndexDto {
  mean: number;
  scale?: string;
}

export interface StatusCountDto {
  all: number;
  pending: number;
  inProgress: number;
  completed: number;
  inActive: number;
  expired: number;
  responseRate: number;
  total: number;
}

export interface ExcludeFromAverageDto extends OverallComparisonDto {
  excludedCompanyFilter: ExcludedCompanyFilterDto[];
  dimensionKey?: string;
}

export interface ExcludedCompanyFilterDto {
  companyKey?: string;
  year?: string;
}

export interface OverallComparisonByCompanyDto extends OverallComparisonDto {
}

export interface OverallComparisonDto {
  filters: AnalysisFilterDto[];
  selectedCompanies?: string;
  timeframe: TimeFrameDto;
  tenantKey?: string;
}
