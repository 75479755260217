<div class="px-5 pt-5 pb-3 mb-5">
    <div class="flex justify-between items-center mb-4">
        <h6 class="languages-sidebar-header-title">
            {{'Languages'| translate:language:ModuleNames.SurveyX }}
        </h6>

        <button class="flex btn exp text-white text-sm font-medium py-4 px-4 rounded" (click)="showAddLocaleModal()">
            <i class="fa-regular fa-plus w-4 pt-3px fill-current" fill="currentColor"></i>
        </button>
    </div>
</div>

<div class="px-5 mb-5">
    <div *ngFor="let locale of selectedLocales">
        <div *ngIf="locale.isDefault"
            class="sidebar-locale-item sidebar-locale-item-default rounded-2 shadow-sm flex gap-3 items-center px-4 py-4 mb-3">
            <div class="flex justify-between items-center w-full">
                <p class="sidebar-locale-item-title mb-0">{{locale.title}}</p>

                <span class="sidebar-locale-item-badge px-2 py-1">
                    {{'Default'| translate:language:ModuleNames.SurveyX }}
                </span>
            </div>
        </div>

        <div *ngIf="!locale.isDefault && !locale.title.includes('Default')" [ngClass]="{'sidebar-locale-item-active': locale.id === targetLocale}"
            class="sidebar-locale-item rounded-2 shadow-sm flex gap-3 items-center px-4 py-4 mb-3 cursor-pointer"
            (click)="selectLocale(locale.id)">
            <div class="flex items-center">
                <p class="sidebar-locale-item-title mb-0">{{locale.title}}</p>
            </div>
        </div>
    </div>

</div>

<app-add-locale-modal *ngIf="isAddLocaleModalVisible"
    [(open)]="isAddLocaleModalVisible"
    [selectedLocales]="selectedLocales"
    [language]="language"
    (openChange)="onOpenChange($event)"
    (selectedLocalesChange)="onSelectedLocalesChanged($event)">
</app-add-locale-modal>
