import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-server-options',
  template: ''
})
export class TableServerOptionsComponent {

  requests = {
    Experience: [
      'searchText',
      'TenantKey',
      'CompanyKey',
      'SurveyKey',
      'pageNumber',
      'pageSize',
      'FilterByMetrics'],
    Thomas: [
      'TenantKey',
      'CompanyKey',
      'AssessmentTypes',
      'Filter',
      'searchText',
      'pageNumber',
      'pageSize']
  };
  APIFetch = {
    Experience: 'dynamicTableQuery',
    Thomas: 'dynamicTableQuery'
  };
}
