import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Entity } from '../data-models/Entity';
import { PlatformUser } from '../data-models/platform-user';
import { Tenant } from '../data-models/tenant';

export interface EssentialObject{
  selectedEntity:Entity;
  companies:Entity[];
  language:any;
  surveys: any;
  user: PlatformUser;
  tenant:Tenant;
  allEntities:Entity[];
}

@Injectable({
  providedIn: 'root'
})



export class EssentialObjectService {
  constructor() { }
  public essentialObject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public headerForceUpdate: BehaviorSubject<any> = new BehaviorSubject<any>({});
  updateObject = (object: any) => this.essentialObject.next(object);
  updateHeader = (object: any) => this.headerForceUpdate.next(object);

}
