<ng-template #template>
    <div [class]="model.designerCss" [style.maxWidth]="survey.renderedWidth">
        <div *ngIf="creator.allowEditSurveyTitle" class="svc-designer-header">
            <div *ngIf="survey.renderedHasHeader" [class]="survey.css.header" [survey]="survey" sv-ng-survey-header>
            </div>
        </div>

        <ng-container *ngIf="creator.pageEditMode !== 'bypage'">
            <ng-container *ngFor="let page of survey.pages">
                <ng-container *ngIf="!model.showNewPage || page !== model.newPage">
                    <ng-container *ngTemplateOutlet="pageContainer; context: { page, isGhost: false }"></ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="model.showNewPage && model.newPage">
                <ng-container
                    *ngTemplateOutlet="pageContainer; context: { page: model.newPage, isGhost: true }"></ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="model.pagesController.page2Display && creator.pageEditMode === 'bypage'">
            <svc-designer-pages [model]="model"></svc-designer-pages>
        </ng-container>
    </div>

    <div *ngIf="creator.showPageNavigator" class="svc-tab-designer__page-navigator">
        <svc-page-navigator [pagesController]="model.pagesController"
            [pageEditMode]="creator.pageEditMode"></svc-page-navigator>
    </div>
</ng-template>

<ng-template let-page="page" let-isGhost="isGhost" #pageContainer>
    <app-zen-svc-page class="svc-page" [model]="page" [creator]="creator" [survey]="survey"
        [isGhost]="isGhost"></app-zen-svc-page>
</ng-template>
<!-- <ng-template let-page="page" let-isGhost="isGhost" #pageContainer>
    <div class="svc-page" [attr.data-sv-drop-target-survey-element]="isGhost ? 'newGhostPage' : page.name"
        [attr.data-sv-drop-target-page]="page.name">
        <ng-template [component]="{ name: 'zen-svc-page', data: { model: page, creator, survey, isGhost }}"></ng-template>
    </div>
</ng-template> -->

{{render()}}