<ng-template #template>
    <div class="svc-flex-column svc-side-bar__wrapper">
        <!-- New questions sidebar -->
        <div class="svc-side-bar__container sidebar overflow-auto">
            <app-questions-sidebar [model]="model.creator" [companyName]="companyName" [language]="language"></app-questions-sidebar>
            <app-question-properties-drawer id="drawer" [hidden]="!open" [(open)]="open" [model]="model.creator"
                [language]="language"></app-question-properties-drawer>
        </div>
    </div>

    <div class="svc-tab-designer pt-5 bg-[#F6F7F9]" [class]="model.getRootCss()" (click)="model.clickDesigner()">
        <!-- Main window -->
        <div class="svc-tab-designer_content">
            <ng-container *ngIf="model.showPlaceholder">
                <div *ngIf="creator.showHeaderInEmptySurvey && creator.allowEditSurveyTitle"
                    class="svc-designer-header">
                    <div *ngIf="survey.renderedHasHeader" [class]="survey.css.header" [survey]="survey"
                        sv-ng-survey-header></div>
                </div>

                <div class="svc-designer__placeholder-container"
                    [attr.data-sv-drop-target-survey-element]="'newGhostPage'">
                    <span class="svc-designer-placeholder-text svc-text svc-text--normal">
                        {{ model.placeholderText }}
                    </span>
                    <svc-page class="svc-designer-placeholder-page" [hidden]="true" [model]="model.newPage"
                        [survey]="survey" [creator]="creator"></svc-page>
                </div>
            </ng-container>

            <ng-container *ngIf="!model.showPlaceholder">
                <!-- <svc-designer-survey [model]="model"></svc-designer-survey> -->
                <app-zen-designer-survey [model]="model"></app-zen-designer-survey>
            </ng-container>
        </div>
    </div>
</ng-template>

<app-change-question-type-modal *ngIf="isAddQuestionModalOpen" [(open)]="isAddQuestionModalOpen" [language]="language"
    (typeSelected)="handleTypeSelected($event)"></app-change-question-type-modal>
