<div class="flex flex-col max-w-4xl w-full rounded-xl gap-14">
  <h2 class="text-zen md:text-5xl  text-3xl text-center font-semibold">{{title}}</h2>
  <div class="flex flex-col md:flex-row justify-between gap-10 bg-white  rounded-lg md:p-10 p-5 items-stretch">
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="flex flex-row justify-center">
    <a class="btn exp px-10 leading-15 text-lg flex flex-row gap-5 items-center md:w-auto w-full justify-center rounded"
      (click)="startSurvey.emit()"><span>{{startButton}}</span> <i class="fa-regular "
        [ngClass]="(language | language )?'fa-arrow-left-long':'fa-arrow-right-long'"></i></a>
  </div>
</div>
