import { LocalizableString } from 'survey-core';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { environment } from 'src/environments/environment';
import { BaseAngular } from 'survey-angular-ui';
import { Translation, localization } from 'survey-creator-core';
import { questionsBuilderTranslateText } from '@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component';
import { ExtractPipe } from '@ex/module/shared/pipes/extract.pipe';
import { AppInjector } from '@ex/module/app.module';

@Component({
  selector: 'app-languages-tab',
  templateUrl: './languages-tab.component.html',
  styleUrls: ['./languages-tab.component.scss'],
})
export class LanguagesTabComponent extends BaseAngular<Translation> {
  extractPipe = AppInjector.get(ExtractPipe);
  @Input() companyName: any;
  @Input() language: string = 'EN';
  ModuleNames = ModuleName;

  @Input() model!: Translation;
  @Input() creator: any;

  @Input() surveyId: any;
  endPoint = environment.endPoint;

  targetLocale: string;
  sourceLocale: string;
  defaultLanguage: string;

  maxCharactersTitle = 100;
  maxCharactersDescription = 300;


  isAddLocaleModalVisible: boolean = false;

  override ngOnInit() {
    // this.defaultLanguage = localization.getLocaleName(this.model.survey.locale);
    // this.defaultLanguage = this.model.survey.locale;

    // if (this.defaultLanguage == "") {
    //   this.defaultLanguage = "English";
    // }

    //The default language has to be english




    this.sourceLocale = this.language.toLowerCase();

    if (this.language == "EN")
      this.sourceLocale = "default"




    super.ngOnInit();
  }

  protected getModel(): Translation {
    return this.model;
  }

  // Used to update the survey locales {Page Title - Form Title - Form Description}
  onSurveyLocaleChanged(
    localizableString: LocalizableString,
    locale: any,
    value: any
  ) {
    localizableString.setLocaleText(locale, value);
  }

  // Used to update the questions locales {Question Title - Choice Title - Row Title - Column Title}
  onItemValueChange(item: any, locale: any, value: any) {
    item.locTitle.setLocaleText(locale, value);

    if (locale != 'default' && item.locTitle.getLocaleText('default') == '')
      item.locTitle.setLocaleText('default', item.value);
  }

  onLabelTextChange(item: any, locale: any, value: any) {

    console.log('item before: ', item);
    if (item.text.default) {
      item.text.default = value;
    } else {
      item.text = { [locale]: value };
    }


    this.creator.setState('modified');
  }

  onTargetLabelTextChange(item: any, locale, value: any) {

    console.log('item : ', item);
    if (item.text.default) {
      if (value != '') {
        item.text = { default: item.text.default };
      } else {
        item.text = item.text.default;
      }
    } else {
      item.text = { default: item.text };
    }
    if (value != '')
      item.text = Object.assign({}, item.text, { [locale]: value });

    this.creator.setState('modified');
  }

  onLocalizableStringTextChange(item: any, locale: any, value: any, type: any) {
    console.log('item before: ', item);



    item.localizableStrings[type].setLocaleText(locale, value);


    this.creator.setState('modified');
  }
  onBinaryLabelTextChange(item: any, locale: any, value: any, bool: any) {
    console.log('item before: ', item);
    if (bool) {

      item.locLabelTrue.setLocaleText(locale, value);
    } else {
      item.locLabelFalse.setLocaleText(locale, value);
    }
    this.creator.setState('modified');
  }

  onBinaryTargetLabelTextChange(item: any, locale, value: any, bool: any) {
    console.log('item : ', item);
    if (bool) {

      item.locLabelTrue.setLocaleText(locale, value);
      if (locale != 'default' && item.locLabelTrue.getLocaleText('default') == '')
        item.locLabelTrue.setLocaleText('default', item['labelTrue']);
    } else {
      item.locLabelFalse.setLocaleText(locale, value);
      if (locale != 'default' && item.locLabelFalse.getLocaleText('default') == '')
        item.locLabelFalse.setLocaleText('default', item['labelFalse']);
    }

    this.creator.setState('modified');
  }

  onTargetLocaleChanged(locale: string) {
    if (locale == "en") {
      this.targetLocale = "default"
    }
    else {
      this.targetLocale = locale;
    }
    this.changeDetectorRef.detectChanges();
  }

  getQuestionNumber(question: any) {
    let number = question['no'].split('.')[0];
    return number;
  }

  onScroll(scrollOne: HTMLElement, scrollTwo: HTMLElement) {
    scrollTwo.scrollTop = scrollOne.scrollTop;
  }

  returnWithLocale(survey: any, locale) {
    survey.locale = 'ar';
    return survey.locTitle;
  }
  getLocalName(locale: string) {

    if (locale == "default") {
      return "English"
    }
    else {
      return localization.getLocaleName(locale);
    }
  }
  logData(e) {
    console.log(e);

  }


  returnQuestionsAfterNPSCheck(questions) {
    questions = questions.map(question => {
      if (question.rateType == 'enps' || question.rateType == 'nps') {
        if (this.language != 'EN' && !question.locTitle['values'][this.sourceLocale])
          this.onItemValueChange(question, this.sourceLocale, question.title);
        question.locTitle['values'][this.sourceLocale] = question.title;
      }
      return question;
    })

    return questions;
  }


  returnNPSValue(question, locale) {
    if (question.rateType != 'enps' && question.rateType != 'nps')
      return question.locTitle['values'][locale] || question.title;
    let value, translation, valueToReplace;
    if (question.uniqueNPSText.length)
      value = this.extractPipe.transform(question.uniqueNPSText, locale.toUpperCase());
    else if (question.rateType == 'enps')
      value = this.extractPipe.transform(this.companyName, locale.toUpperCase());
    else value = questionsBuilderTranslateText('[Product(s)/Service(s)]', locale.toUpperCase());
    valueToReplace = question.rateType == 'enps' ? '[CompanyName]' : '[Product(s)/Service(s)]';
    translation = questionsBuilderTranslateText(question.rateType + 'Question', locale.toUpperCase()).replace(valueToReplace, value + ' ');
    if (!question.manualUpdate)
      this.onItemValueChange(question, locale, translation);
    question.manualUpdate = true;


    return translation;


  }

  updateNPSText(value, question, locale) {
    let NPSText = question.uniqueNPSText.find(x => x.key == locale.toUpperCase());
    if (!NPSText)
      question.uniqueNPSText.push({ key: locale.toUpperCase(), name: value });
    else NPSText.name = value;
    let translation = questionsBuilderTranslateText(question.rateType + 'Question', locale.toUpperCase()).replace('[CompanyName]', value + ' ');
    this.onItemValueChange(question, locale, translation);
  }


}
