import type { CompaniesListWithSurveysDto } from './models';
import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CriteriaAnalysisResultDto, ExcludeFromAverageDto, OverallComparisonByCompanyDto } from '../analysis/models';

@Injectable({
  providedIn: 'root',
})
export class ComparisonService {
  apiName = 'Default';

  getCompaniesWithSuryes = (filter: OverallComparisonByCompanyDto) =>
    this.restService.request<any, CompaniesListWithSurveysDto>({
      method: 'POST',
      url: '/api/wellbeing/resports-analysis/companylist-with-surveys',
      body: filter,
    },
    { apiName: this.apiName });

  getComparisonByDimensionKeyWithoutAverage = (filters: ExcludeFromAverageDto) =>
    this.restService.request<any, CriteriaAnalysisResultDto[]>({
      method: 'POST',
      url: '/api/wellbeing/resports-analysis/companylist-with-surveys-bydimension-without-avg',
      body: filters,
    },
    { apiName: this.apiName });

  getOverallComparison = (filters: ExcludeFromAverageDto) =>
    this.restService.request<any, CriteriaAnalysisResultDto[]>({
      method: 'POST',
      url: '/api/wellbeing/resports-analysis/overall-comparison',
      body: filters,
    },
    { apiName: this.apiName });

  getOverallComparisonByCompanyKey = (filters: OverallComparisonByCompanyDto) =>
    this.restService.request<any, CriteriaAnalysisResultDto[]>({
      method: 'POST',
      url: '/api/wellbeing/resports-analysis/comparison-by-company',
      body: filters,
    },
    { apiName: this.apiName });

  getOverallComparisonByDimensionKey = (filters: ExcludeFromAverageDto) =>
    this.restService.request<any, CriteriaAnalysisResultDto[]>({
      method: 'POST',
      url: '/api/wellbeing/resports-analysis/comparison-by-dimension',
      body: filters,
    },
    { apiName: this.apiName });

  getOverallComparisonWithoutAverage = (filters: ExcludeFromAverageDto) =>
    this.restService.request<any, CriteriaAnalysisResultDto[]>({
      method: 'POST',
      url: '/api/wellbeing/resports-analysis/companylist-with-surveys-overall-without-avg',
      body: filters,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
