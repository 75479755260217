import {
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import { RouterModule } from '@angular/router';
import { CustomTreeChecklistComponent } from '../shared/custom-tree-checklist/custom-tree-checklist.component';
import { RespondentEditComponent } from '../shared/respondent-edit/respondent-edit.component';
import { RespondentsModalComponent } from '../shared/respondents-modal/respondents-modal.component';
import { UnitsNotificationDialogComponent } from '../shared/units-notification-dialog/units-notification-dialog.component';
import { CreditBalanceDialogComponent } from '../shared/credit-balance-dialog/credit-balance-dialog.component';
import { ErrorComponent } from '../shared/error/error.component';

import { DrillDownDialogComponent } from '../shared/drill-down-dialog/drill-down-dialog.component';
import { LpHeaderComponent } from '../shared/lp-header/lp-header.component';
import { HeaderComponent } from '../shared/header/header.component';
import { FooterComponent } from '../shared/footer/footer.component';
import { ResponseDialogComponent } from '../shared/response-dialog/response-dialog.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { NavigationBarComponent } from '../shared/navigation-bar/navigation-bar.component';
import { CustomDropdownComponent } from '../shared/custom-dropdown/custom-dropdown.component';
import { TranslationInputComponent } from '../shared/translation-input/translation-input.component';
import { MasterSnackBarComponent } from '../shared/master-snack-bar/master-snack-bar.component';
import { ChartComponent } from '../shared/chart/chart.component';
import { MatTreeModule } from '@angular/material/tree';

import { SettingsComponent } from '../common/settings/settings.component';
import { CdkTreeModule } from '@angular/cdk/tree';

import { HttpClientModule } from '@angular/common/http';

import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { EditorModule } from '@progress/kendo-angular-editor';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { MatChipsModule } from '@angular/material/chips';
import { ExportArabicPreviewDialogComponent } from './export-arabic-preview-dialog/export-arabic-preview-dialog.component';
import { ReportFooterComponent } from './export-components/report-footer/report-footer.component';
import { TableComponent } from './chart/table/table.component';
import { DataGridComponent } from './chart/data-grid/data-grid.component';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { ReportHeaderComponent } from './export-components/report-header/report-header.component';
import { ModalComponent } from './modal/modal.component';
import { EmptySectionComponent } from './empty-section/empty-section.component';
import { TranslatePipe } from './pipes/translate.pipe';
import { ExtractNameLanguagePipe } from './pipes/extract-name-language.pipe';
import { ToFixedPipe } from './pipes/to-fixed.pipe';
import { RespondentsPoolComponent } from './respondents-pool/respondents-pool.component';
import { CriteriaComponent } from './criteria/criteria.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { QHeaderComponent } from './questionnaire/q-header/q-header.component';
import { StartPageComponent } from './questionnaire/start-page/start-page.component';
import { MainPageComponent } from './questionnaire/main-page/main-page.component';
import { MultiOptionsMultiAnswerComponent } from './questionnaire/questions/multi-options-multi-answer/multi-options-multi-answer.component';
import { ProgressComponent } from './questionnaire/progress/progress.component';
import { SubQuestionsBinaryLikenessRatingComponent } from './questionnaire/questions/sub-questions-binary-likeness-rating/sub-questions-binary-likeness-rating.component';
import { TextComponent } from './questionnaire/questions/text/text.component';
import { RatingLikedComponent } from './questionnaire/questions/rating-liked/rating-liked.component';
import { SubQuestionsRatingComponent } from './questionnaire/questions/sub-questions-rating/sub-questions-rating.component';
import { EndPageComponent } from './questionnaire/end-page/end-page.component';
import { QMetricsComponent } from './questionnaire/q-metrics/q-metrics.component';
import { ExtractPipe } from './pipes/extract.pipe';
import { SingleAnswerConfidenceComponent } from './questionnaire/questions/single-answer-confidence/single-answer-confidence.component';
import { SingleAnswerOutTenComponent } from './questionnaire/questions/single-answer-out-ten/single-answer-out-ten.component';
import { RightPreviewComponent } from './export-components/right-preview/right-preview.component';
import { LoaderComponent } from './loader/loader.component';
import { MiniLoaderComponent } from './mini-loader/mini-loader.component';
import { TempTableComponent } from './temp-table/temp-table.component';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { ReportPageComponent } from './export-components/report-page/report-page.component';
import { AngularFireModule } from '@angular/fire/compat';
import { LeftPreviewComponent } from './export-components/left-preview/left-preview.component';
import { ZenSliderComponent } from './questionnaire/questions/zen-slider/zen-slider.component';
import { QFooterComponent } from './questionnaire/q-footer/q-footer.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import "@lottiefiles/lottie-player";
import { NewModalComponent } from './new-modal/new-modal.component';

import { ZenRadioComponent } from './Theme/controls/zen-radio/zen-radio.component';
import { RequiredComponent } from './validation/required/required.component';
import { EmailComponent } from './validation/email/email.component';
import { HomeContainerComponent } from './home-container/home-container.component';
import { PeopleComponent } from './people/people.component';
import { ZenModalComponent } from './Theme/components/zen-modal/zen-modal.component';
import { ZenMenuComponent } from './Theme/controls/zen-menu/zen-menu.component';
import { ZenMenuTriggerDirective } from './Theme/directives/zen-menu-trigger.directive';
import { MenuItemDirective } from './Theme/directives/menu-item.directive';
import { ZenTranslationInputComponent } from './Theme/controls/zen-translation-input/zen-translation-input.component';
import { ZenInputComponent } from './Theme/controls/zen-input/zen-input.component';
import { ZenDatepickerComponent } from './Theme/controls/zen-datepicker/zen-datepicker.component';
import { ZenEditorComponent } from './zen-editor/zen-editor.component';
import { ZenSelectComponent } from './Theme/controls/zen-select/zen-select.component';
import { SingleAnswerConfidenceWithNaComponent } from './questionnaire/questions/single-answer-confidence-with-na/single-answer-confidence-with-na.component';
import { SectionLoaderComponent } from './section-loader/section-loader.component';
import { ZenToggleComponent } from './Theme/controls/zen-toggle/zen-toggle.component';
import { NumberCommaPipe } from './pipes/number-comma.pipe';
import { HelpCenterComponent } from './help-center/help-center.component';
import { TicketDialogComponent } from './help-center/ticket-dialog/ticket-dialog.component';
import { ZenSnackbarComponent } from './Theme/components/zen-snackbar/zen-snackbar.component';
import { SnackbarInstanceComponent } from './Theme/components/zen-snackbar/snackbar-instance/snackbar-instance.component';
import { LanguagePipe } from './pipes/language.pipe';
import { ZDrawerComponent } from './Theme/components/zen-drawer/zen-drawer.component';
import { MatPaginatorIntlLocalize } from '../core/services/CustomPaginatorConfiguration';
import { ZenInputImageComponent } from './Theme/controls/zen-input-image/zen-input-image.component';
import { StatusButtonComponent } from './status-button/status-button.component';
import { DualDropdownSelectionComponent } from './Theme/controls/dual-dropdown-selection/dual-dropdown-selection.component';
import { ZenModalStepperComponent } from './Theme/components/zen-modal-stepper/zen-modal-stepper.component';
import { TableServerOptionsComponent } from './temp-table/table-server-options/table-server-options.component';
import { NativeExportBaseComponent } from './native-export-base/native-export-base.component';
import { ZenTextareaComponent } from './Theme/controls/zen-textarea/zen-textarea.component';
import { ZenFileuploadComponent } from './Theme/controls/zen-fileupload/zen-fileupload.component';
import { PaginatorComponent } from './temp-table/paginator/paginator.component';
import { PortalModule } from '@angular/cdk/portal';
import { StickyDirective } from './Theme/directives/sticky.directive';
import { SingleAnswerTenComponent } from './data-display/single-answer-ten/single-answer-ten.component';
import { MdDashletComponent } from './Theme/components/md-dashlet/md-dashlet.component';
import { SidebarContainerComponent } from './Theme/components/containers/sidebar-container/sidebar-container.component';
import { ContentContainerComponent } from './Theme/components/containers/content-container/content-container.component';
import { ZenMainContainerComponent } from './Theme/components/containers/zen-main-container/zen-main-container.component';
import { BackButtonComponent } from './Theme/components/common/back-button/back-button.component';
import { SectionSidebarComponent } from './Theme/components/common/section-sidebar/section-sidebar.component';
import { ZenEditContainerComponent } from './Theme/components/zen-edit-container/zen-edit-container.component';
import { EditContainerDirective } from './Theme/components/zen-edit-container/edit-container.directive';
import { ButtonStyleDirective } from './Theme/directives/button-style.directive';
import { SectionHeadComponent } from './Theme/components/common/section-head/section-head.component';
import { MenuButtonComponent } from './Theme/components/common/menu-button/menu-button.component';
import { ZenTreeComponent } from './Theme/components/zen-tree/zen-tree.component';
import { ExportChartVerticalComponent } from './export-components/export-chart-vertical/export-chart-vertical.component';
import { AnalysisQuestionComponent } from './components/analysis-question/analysis-question.component';
import { ExportQuestionComponent } from './components/export-question/export-question.component';
import { AccordionsComponent } from './Theme/components/accordions/accordions.component';
import { AccordionComponent } from './Theme/components/accordions/accordion/accordion.component';
import { JsonToExcelComponent } from './export-components/json-to-excel/json-to-excel.component';
import { QrCodeModule } from 'ng-qrcode';
import { AiStatementGenerationDirective, AiTextValidationDirective } from './directives/ai-text-handling.directive';
import { NaPipe } from './pipes/na.pipe';
import { ChartWrapperComponent } from './chart/chart-wrapper/chart-wrapper.component';
import { LiveSurveyTimeChangesComponent } from './components/live-survey-time-changes/live-survey-time-changes.component';
import { FilterTopicsPipe } from './comments-ai/filter-topics.pipe';
import { ZenCdkDetachDirective } from './Theme/services/zen-cdk-detach.directive';
import { SecondaryTableComponent } from './Theme/components/s-table/s-table.component';
import { SurveyCriteriaComponent } from './components/survey-criteria/survey-criteria.component';
import { ZenInputV2Component } from './Theme/controls/zen-new-input/zen-new-input.component';
import { ZenTranslationTextareeaComponent } from './Theme/controls/zen-translation-textarea/zen-translation-textarea.component';
import { ZenTranslationEditorComponent } from './Theme/controls/zen-translation-editor/zen-translation-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ZenTranslationModalComponent } from './Theme/controls/zen-translation-modal/zen-translation-modal.component';
import { DemoAccountDirective } from './demo-account/demo-account.directive';
import { DemoSectionComponent } from './demo-account/demo-section/demo-section.component';
import { DemoPopupComponent } from './demo-account/demo-popup/demo-popup.component';
import { DemoReportPageComponent } from './demo-account/demo-report-page/demo-report-page.component';

import { ProductTourModule } from './product-tour/product-tour.module';
import { NoDataOverlayDirective } from './directives/no-data-overlay.directive';
import { OverlayModule } from '@angular/cdk/overlay';
@NgModule({
  declarations: [
    JsonToExcelComponent,
    EmptySectionComponent,
    CustomDropdownComponent,
    NavigationBarComponent,
    TranslationInputComponent,
    MasterSnackBarComponent,
    ChartComponent,
    ZenInputImageComponent,
    HomeContainerComponent,
    DrillDownDialogComponent,
    LpHeaderComponent,
    HeaderComponent,
    FooterComponent,
    ResponseDialogComponent,
    ConfirmationDialogComponent,
    ErrorComponent,
    CreditBalanceDialogComponent,
    UnitsNotificationDialogComponent,
    RespondentEditComponent,
    RespondentsModalComponent,
    CustomTreeChecklistComponent,
    SettingsComponent,
    ExportArabicPreviewDialogComponent,
    ReportFooterComponent,
    TableComponent,
    DataGridComponent,
    ReportHeaderComponent,
    ModalComponent,
    TranslatePipe,
    ExtractNameLanguagePipe,
    ToFixedPipe,
    RespondentsPoolComponent,
    CriteriaComponent,
    ClickOutsideDirective,
    QHeaderComponent,
    StartPageComponent,
    MainPageComponent,
    MultiOptionsMultiAnswerComponent,
    ProgressComponent,
    SubQuestionsBinaryLikenessRatingComponent,
    TextComponent,
    RatingLikedComponent,
    SubQuestionsRatingComponent,
    EndPageComponent,
    QMetricsComponent,
    ExtractPipe,
    SingleAnswerConfidenceComponent,
    SingleAnswerOutTenComponent,
    RightPreviewComponent,
    LoaderComponent,
    MiniLoaderComponent,
    TempTableComponent,
    HeatmapComponent,
    ReportPageComponent,
    LeftPreviewComponent,
    ZenSliderComponent,
    QFooterComponent,
    ZenToggleComponent,
    NewModalComponent,
    ZenInputComponent,
    ZenRadioComponent,
    ZenSelectComponent,
    RequiredComponent,
    EmailComponent,
    PeopleComponent,
    ZenModalComponent,
    ZenMenuComponent,
    ZenMenuTriggerDirective,
    MenuItemDirective,
    ZenCdkDetachDirective,
    ZenTranslationInputComponent,
    ZenDatepickerComponent,
    ZenEditorComponent,
    SingleAnswerConfidenceWithNaComponent,
    SectionLoaderComponent,
    NumberCommaPipe,
    HelpCenterComponent,
    TicketDialogComponent,
    ZenSnackbarComponent,
    SnackbarInstanceComponent,
    LanguagePipe,
    ZDrawerComponent,
    DualDropdownSelectionComponent,
    StatusButtonComponent,
    ZenModalStepperComponent,
    TableServerOptionsComponent,
    NativeExportBaseComponent,
    ZenTextareaComponent,
    MdDashletComponent,
    ZenFileuploadComponent,
    PaginatorComponent,
    StickyDirective,
    SingleAnswerTenComponent,
    SectionHeadComponent,
    SectionSidebarComponent,
    MenuButtonComponent,
    SidebarContainerComponent,
    ContentContainerComponent,
    ZenMainContainerComponent,
    BackButtonComponent,
    ZenEditContainerComponent,
    EditContainerDirective,
    ZenTreeComponent,
    ButtonStyleDirective,
    MenuButtonComponent,
    ZenTreeComponent,
    SectionHeadComponent,
    ExportChartVerticalComponent,
    MenuButtonComponent,
    SectionSidebarComponent,
    SidebarContainerComponent,
    ContentContainerComponent,
    ZenMainContainerComponent,
    BackButtonComponent,
    ZenEditContainerComponent,
    EditContainerDirective,
    ZenTreeComponent,
    ButtonStyleDirective,
    MenuButtonComponent,
    ZenTreeComponent,
    SectionHeadComponent,
    ExportChartVerticalComponent,
    AnalysisQuestionComponent,
    ExportQuestionComponent,
    AccordionsComponent,
    AccordionComponent,
    NaPipe,
    ChartWrapperComponent,
    FilterTopicsPipe,
    LiveSurveyTimeChangesComponent,
    AiTextValidationDirective,
    AiStatementGenerationDirective,
    SecondaryTableComponent,
    SurveyCriteriaComponent,
    ZenInputV2Component,
    ZenTranslationTextareeaComponent,
    ZenTranslationEditorComponent,
    ZenTranslationModalComponent,
    DemoAccountDirective,
    DemoSectionComponent,
    DemoPopupComponent,
    DemoReportPageComponent,
    NoDataOverlayDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    MatTooltipModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    MatCardModule,
    MatExpansionModule,
    MatButtonModule,
    MatInputModule,
    MatSliderModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    PortalModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatListModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDialogModule,
    EditorModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTabsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatTreeModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatSortModule,
    ChartsModule,
    IntlModule,
    MatSlideToggleModule,
    HttpClientModule,
    DateInputsModule,
    DateInputsModule,
    InputsModule,
    PDFExportModule,
    GaugesModule,
    AngularFireStorageModule,
    GridModule,
    PDFModule,
    ExcelModule,
    MatTableExporterModule,
    CdkTreeModule,
    QrCodeModule,
    ProductTourModule,
    AngularEditorModule,
    OverlayModule,
  ],
  exports: [
    ZenInputImageComponent,
    CustomDropdownComponent,
    NavigationBarComponent,
    TranslationInputComponent,
    MasterSnackBarComponent,
    ChartComponent,
    DrillDownDialogComponent,
    LpHeaderComponent,
    HeaderComponent,
    FooterComponent,
    ResponseDialogComponent,
    ConfirmationDialogComponent,
    ErrorComponent,
    CreditBalanceDialogComponent,
    UnitsNotificationDialogComponent,
    RespondentEditComponent,
    RespondentsModalComponent,
    CustomTreeChecklistComponent,
    SettingsComponent,
    EmptySectionComponent,
    MatTooltipModule,
    AngularFireModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    MatCardModule,
    MatExpansionModule,
    MatButtonModule,
    MatInputModule,
    MatSliderModule,
    MatChipsModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatListModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDialogModule,
    EditorModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTabsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatSortModule,
    ChartsModule,
    IntlModule,
    MatSlideToggleModule,
    HttpClientModule,
    DateInputsModule,
    DateInputsModule,
    InputsModule,
    PDFExportModule,
    GaugesModule,
    AngularFireStorageModule,
    ExportArabicPreviewDialogComponent,
    ReportFooterComponent,
    ReportHeaderComponent,
    DataGridComponent,
    GridModule,
    PDFModule,
    ExcelModule,
    MatTreeModule,
    TranslatePipe,
    ExtractNameLanguagePipe,
    ToFixedPipe,
    LanguagePipe,
    RespondentsPoolComponent,
    CriteriaComponent,
    QHeaderComponent,
    StartPageComponent,
    MainPageComponent,
    MultiOptionsMultiAnswerComponent,
    ProgressComponent,
    SubQuestionsBinaryLikenessRatingComponent,
    TextComponent,
    RatingLikedComponent,
    SubQuestionsRatingComponent,
    EndPageComponent,
    QMetricsComponent,
    ExtractPipe,
    NumberCommaPipe,
    SingleAnswerConfidenceComponent,
    SingleAnswerOutTenComponent,
    RightPreviewComponent,
    LoaderComponent,
    MiniLoaderComponent,
    TempTableComponent,
    HeatmapComponent,
    ReportPageComponent,
    LeftPreviewComponent,
    ZenSliderComponent,
    QFooterComponent,
    MatTableExporterModule,
    ZenToggleComponent,
    NewModalComponent,
    ZenInputComponent,
    ZenRadioComponent,
    ZenSelectComponent,
    RequiredComponent,
    EmailComponent,
    HomeContainerComponent,
    PeopleComponent,
    ZenModalComponent,
    ZenMenuTriggerDirective,
    ZenMenuComponent,
    MenuItemDirective,
    ZenCdkDetachDirective,
    StickyDirective,
    ZenTranslationInputComponent,
    ZenDatepickerComponent,
    ZenEditorComponent,
    SingleAnswerConfidenceWithNaComponent,
    SectionLoaderComponent,
    ZenSnackbarComponent,
    SnackbarInstanceComponent,
    ZDrawerComponent,
    DualDropdownSelectionComponent,
    StatusButtonComponent,
    ZenModalStepperComponent,
    TableServerOptionsComponent,
    NativeExportBaseComponent,
    ZenTextareaComponent,
    MdDashletComponent,
    ZenFileuploadComponent,
    PaginatorComponent,
    PortalModule,
    CdkTreeModule,
    SingleAnswerTenComponent,
    SectionHeadComponent,
    SectionSidebarComponent,
    MenuButtonComponent,
    SidebarContainerComponent,
    ContentContainerComponent,
    ZenMainContainerComponent,
    BackButtonComponent,
    ZenEditContainerComponent,
    EditContainerDirective,
    ZenTreeComponent,
    ButtonStyleDirective,
    MenuButtonComponent,
    ZenTreeComponent,
    SectionHeadComponent,
    ExportChartVerticalComponent,
    MenuButtonComponent,
    SidebarContainerComponent,
    ContentContainerComponent,
    ZenMainContainerComponent,
    BackButtonComponent,
    SectionSidebarComponent,
    ZenEditContainerComponent,
    EditContainerDirective,
    ZenTreeComponent,
    ButtonStyleDirective,
    MenuButtonComponent,
    ZenTreeComponent,
    SectionHeadComponent,
    ExportChartVerticalComponent,
    AnalysisQuestionComponent,
    ExportQuestionComponent,
    AccordionsComponent,
    AccordionComponent,
    JsonToExcelComponent,
    QrCodeModule,
    NaPipe,
    ChartWrapperComponent,
    FilterTopicsPipe,
    LiveSurveyTimeChangesComponent,
    AiTextValidationDirective,
    AiStatementGenerationDirective,
    SecondaryTableComponent,
    SurveyCriteriaComponent,
    ZenInputV2Component,
    ZenTranslationTextareeaComponent,
    ZenTranslationEditorComponent,
    ZenTranslationModalComponent,
    DemoAccountDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [TranslatePipe, { provide: MatPaginatorIntl, useClass: MatPaginatorIntlLocalize }],
})
export class SharedModule { }
