import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-zen-table',
  templateUrl: './zen-table.component.html',
  styleUrls: ['./zen-table.component.scss']
})
export class ZenTableComponent implements OnInit {
  ngOnInit(): void {
  }

  @ContentChild(TemplateRef) rowTemplate;
  @Input() size: number = 5;
  @Input() page: number = 1;
  @Input('data') set tData(tableData: any) {
    if (!tableData.length) return;
    this.originalData = JSON.parse(JSON.stringify(tableData));
    this.paginationConfig();
    this.goTo(this.page);
  };
  originalData = [];
  pageData = [];
  pageNumbers;
  currentPage = 1;
  logData(x) {
    console.log(x);
  }

  paginationConfig() {
    let pages = Math.ceil(this.originalData.length / this.size);
    this.pageNumbers = Array(pages);
  }
  goTo(newIndex) {
    let start = (newIndex * this.size) - this.size;
    this.pageData = this.originalData.slice(start, start +this.size);
    this.currentPage = newIndex;
  }
}
