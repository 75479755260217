import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BYPASS_LOG } from '../../utils/_interceptors/loader-interceptor.interceptor';



@Injectable({
  providedIn:'root'
})

export class RespondentsService {
  errorMessage: any;
  postId: any;

  URL = environment.endPoint;

  httpOptions={headers: new HttpHeaders({
    'Access-Control-Allow-Origin': 'https://assessments.zenithr.net',
    'Content-Type': 'application/json;'
  })}

  constructor(private httpClient: HttpClient) { }

  getAllRespondents(){

    return this.httpClient.get(this.URL+'respondents/get-all-respondents');
  }

  getAllRespondentsByForm(formId : any){

    return this.httpClient.get(this.URL+`respondents/get-all-respondents-by-form/${formId}`);
  }

  getAllRespondentsByTenantWithPagination(tenantId : any, pageNumber:any , pageSize:any, filter: any){

    return this.httpClient.post(this.URL+`respondents/get-all-respondents-by-tenant-with-pagination/${tenantId}/${pageNumber}/${pageSize}`, filter, this.httpOptions);
  }
  getAllRespondentsByTenant(tenantId : any){

    return this.httpClient.post(this.URL+`respondents/get-all-respondents-by-tenant/${tenantId}`, this.httpOptions);
  }

  getAllRespondentsByTenant2(tenantId : any, pageNumber:any , pageSize:any){

    return this.httpClient.get(this.URL+`respondents/get-all-respondents-by-tenant/${tenantId}/${pageNumber}/${pageSize}`,{ context: new HttpContext().set(BYPASS_LOG, true) });
  }

   async getRespondent(id : any)
  {
    return this.httpClient.get(this.URL+`respondents/get-respondent/${id}`);
  }


  async getRespondentForm(id : any, tenantId : any)
  {
    return this.httpClient.get(this.URL+`form-table-respondents/get-form-table-respondent-by-respondent-id/${id}/${tenantId}`);
  }

   getAllRespondentsByFormId(formId : any, tenantId : any)
  {
    return this.httpClient.get(this.URL+`form-table-respondents/get-all-form-table-respondents-by-form/${formId}/${tenantId}`);
  }
   getAllRespondentsByFormIdWithPagination(formId : any, tenantId : any, pageNumber : any, pageSize:any, filter :any)
  {
    return this.httpClient.post(this.URL+`form-table-respondents/get-all-form-table-respondents-by-form-with-pagination/${formId}/${tenantId}/${pageNumber}/${pageSize}`, filter);
  }


   deleteRespondentFromForm(formId : any,respondentId :any, tenantId : any)
  {
    return this.httpClient.delete(this.URL+`form-table-respondents/delete-respondent-from-form/${formId}/${respondentId}/${tenantId}`);
  }


  async createRespondent(body : any)
  {
    return  this.httpClient.post(this.URL+`respondents/add-respondent`, body);
  }

  updateRespondent(data : {}, id : any)
  {
    return this.httpClient.put(this.URL+`respondents/update-respondent/${id}`,data,this.httpOptions);
  }
  deleteRespondent(id : any)
  {
    return this.httpClient.delete(this.URL+`respondents/delete-form/${id}`,this.httpOptions);
  }


  async getRespondentAnswers(formId : any , respondentId :any, tenantId:any)
  {
    return this.httpClient.get(this.URL+`respondents/respondent-answers/${formId}/${respondentId}/${tenantId}`);
  }



  sendBase64ExcelFileToBackEnd(tenantId : any , tenantName : any, base64Data){

    return this.httpClient.post(this.URL+`respondents/base64-file/${tenantId}/${tenantName}` , base64Data,this.httpOptions );
  }

  sendBase64ExcelFileToBackEndWithFormId(tenantId : any , tenantName : any, requestData:any , formId:any,)
  {
    return this.httpClient.post(this.URL+`send-survey-email/base64-file-with-form-id/${tenantId}/${tenantName}/${formId}` , requestData,this.httpOptions );
  }


}
