import { Component, OnInit, Inject, ViewChild, ViewEncapsulation, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PDFExportComponent } from "@progress/kendo-angular-pdf-export";
import { DatePipe } from '@angular/common';
import { LanguageService } from "../../core/services/language.service";
import { LoaderService } from "../../core/services/loader.service";
import { ModuleName } from "../enums/ModuleName";

@Component({
  selector: 'export-preview-dialog',
  templateUrl: './export-preview-dialog.component.html',
  styleUrls: ['./export-preview-dialog.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
})

export class ExportPreviewDialogComponent implements OnInit {
  @ViewChild("pdf", { static: true }) pdfExportContainer: PDFExportComponent;
  exportOption: string;
  fileType: string;
  searchText: string = '';
  subject: string;
  message: string;
  reportTitle: string;
  currentUser: any;
  logoURL: string;
  smallLogoUrl: string;
  language: string;
  // txtCreatedBy: string;
  // txtGeneratedon: string;
  // txtPoweredby: string;
  exportMargin: any;
  // txtExportReport: string;
  // btn_cancel: string;
  // btnExport: string;
  applyPreviewCanvas: boolean = true;
  displaySpinner: boolean = false;

  constructor(
    private languageService: LanguageService,
    public loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any, private datePipe: DatePipe
  ) { }

  getDateTime() {
    return this.datePipe.transform(new Date(), 'HH:mm dd-MM-yyyy');
  }
ModuleNames=ModuleName
  ngOnInit() {
    this.language = this.data.language;
    this.reportTitle = this.data.reportTitle;
    this.currentUser = this.data.currentUser;
    this.exportMargin = this.data.exportMargin;
    this.applyPreviewCanvas = this.data.applyPreviewCanvas;
    this.logoURL = 'assets/img/logo.png'; // to be resolved to client's logo
    this.smallLogoUrl = 'assets/img/logo-small.png';

    this.data.dashlets.forEach(dashlet => {
      var div = document.createElement("div");
      var title = document.createElement("h4");
      var caption = document.createElement("P");
      var linebreak = document.createElement("br");
      title.innerText = dashlet.title;
      div.appendChild(title);
      div.appendChild(linebreak);
      if (this.applyPreviewCanvas) { // disable the css for engagement dashboards
        div.classList.add('preview-canvas');
      }
      div.appendChild(dashlet.URI);
      caption.innerText = dashlet.caption;
      div.appendChild(caption);
      document.getElementById("chartCanvas").appendChild(div);
      div.appendChild(linebreak);
    });
    // this.setUpLanguagePreference();
  }

  // setUpLanguagePreference() {
  //   this.setUpTextFields();
  // }

  // setUpTextFields() {
  //   this.txtExportReport = this.languageService.getUILanguage('txtExportReport', this.language);
  //   this.btn_cancel = this.languageService.getUILanguage('btn_leaver_list_cancel', this.language);
  //   this.btnExport = this.languageService.getUILanguage('btnExport', this.language);
  //   this.txtCreatedBy = this.languageService.getUILanguage('txtCreatedBy', this.language);
  //   this.txtGeneratedon = this.languageService.getUILanguage('txtGeneratedon', this.language);
  //   this.txtPoweredby = this.languageService.getUILanguage('txtPoweredby', this.language);


  // }

  async export() {
    this.displaySpinner = true;
    let temp = await this.pdfExport();
    this.displaySpinner = false;
  }

  async pdfExport(share?: boolean) {
    this.pdfExportContainer.paperSize = "A4";
    this.pdfExportContainer.landscape = true;
    this.pdfExportContainer.scale = 1;
    this.pdfExportContainer.author = "ZENITHR";
    this.pdfExportContainer.keepTogether = ".preview-canvas";
    this.pdfExportContainer.forcePageBreak = ".page-break";
    this.pdfExportContainer.margin = this.exportMargin;

    return this.pdfExportContainer.saveAs(this.reportTitle + '.pdf');
  }

}
