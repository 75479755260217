<div class="flex flex-col gap-5">
  <div class="py-5">
    <mat-divider></mat-divider>
  </div>
  <ng-container>
    <h5 class="text-zen text-2xl font-medium">
      {{
      "txt_Workplace_Wellbeing_Score"
      | translate : language : ModuleNames.Wellbeing
      }}
    </h5>
    <div class="flex flex-row justify-between items-center relative" *DemoAccount="'Button'; let isDemo=isDemo">
      <h6 class="text-opacity-80 text-zen uppercase">
        {{'txtByCriteria'|
        translate:language:ModuleNames.Wellbeing}}
      </h6>

      <z-select [DemoLimit]="2"  *ngIf="selectedCriteriaKey" [color]="'exp'"
        [label]="'txtBreakout'| translate:language:ModuleNames.Wellbeing" [(ngModel)]="selectedCriteriaKey"
        (ngModelChange)="onCriteriaChange($event)" [options]="surveyCriterias"
        [language]="language" [TourStep]="{
              title:
                'txt_Workplace_851' | translate : language : ModuleNames.Wellbeing,
              text:
                'txt_Unlockthe_743' | translate : language : ModuleNames.Wellbeing,
              order: 4,
              tourKey: 'wellbeing-closed-0',
              on: (language | language) ? 'right' : 'left'
            }">
        <ng-template #valueTemplate let-value>
          {{ 'txtRespondentsBy' | translate:language:ModuleNames.Wellbeing }} {{value?.name | extract:language}}
        </ng-template>
      </z-select>

    </div>
    <div *ngIf="criteriaChartData">
      <chart class="dashlet response-chart pt-6 pb-4" [dashlet]="criteriaChart" [chartColors]="chartColors"
        [dashletData]="criteriaChartData" [currencySymbol]="'$'" [language]="language" [dataSymbol]="' %'"
        [hideHeader]="true"></chart>
    </div>
  </ng-container>
</div>