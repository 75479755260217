<div class="block relative" *ngIf="data" [ngSwitch]="chart?.group" [id]="'pdf' + this.chart?.group">

  <ng-container *ngSwitchCase="'GENDER'">

    <div (click)="logData(data)" class="flex flex-row flex-wrap w-full bg-white rounded-md borderLight main-box genderBox mb-4"
      #gendersPrintArea>
      <div class="sd-header__text pl-6 pr-4 py-2 h56 justify-center">
        <div class="flex items-center">
          <div class="flex-auto">
            <h3 class="sd-title ">{{chart.Title}}</h3>
          </div>
          <div class="flex-none flex flex-row print:hidden">
            <!-- <div class="pl-3 items-center justify-end">
                            <a [ZMenuTriggerFor]="menuG" class="btn h-9 px-2 py-2 font-normal text-zen text-center rounded leading-9 text-sm bg-zen hover:bg-opacity-10 bg-opacity-0">
                                <i class="fa-regular fa-chart-bar pt-1 text-lg text-blueGray600"></i></a>

                            <z-menu #menuG>
                                <menuItems>
                                    <ng-container [ngTemplateOutlet]="moreMenuGItems"></ng-container>
                                </menuItems>
                            </z-menu>

                            <ng-template #moreMenuGItems>
                                <a MenuItem><i class="fa-regular fa-chart-column mx-1"></i>{{'Column'}}</a>
                                <a MenuItem><i class="fa-regular fa-chart-bar mx-1"></i>{{'Horizontal Bars'}}</a>
                                <a MenuItem><i class="fa-regular fa-chart-pie-simple mx-1"></i>{{'Pie'}}</a>
                                <a MenuItem><i class="fa-regular fa-chart-line mx-1"></i>{{'Line'}}</a>
                                <a MenuItem><i class="fa-regular fa-chart-area mx-1"></i>{{'Area'}}</a>
                            </ng-template>
                        </div> -->
            <div class=" btn pl-3 items-start justify-end">
              <div [ZMenuTriggerFor]="menu"
                class=" flex h-8 w-8 justify-center items-center font-normal text-zen text-center rounded leading-9 text-sm bg-zen-blueStone hover:bg-opacity-10 bg-opacity-0">
                <div class="flex h-4 items-center gap-2">
                  <a class="flex w-5 h-5 justify-center items-center">
                    <i class="fa-regular fa-ellipsis text-lg text-blueGray600"></i>
                  </a>

                </div>
              </div>

              <z-menu #menu>
                <menuItems>
                  <ng-container [ngTemplateOutlet]="moreMenuItems"></ng-container>
                </menuItems>
              </z-menu>

              <ng-template #moreMenuItems>
                <!-- <a MenuItem><i class="fa-regular fa-table mx-1"></i>{{'txtRaw Data' | translate:language:ModuleNames.SurveyX}}</a> -->
                <a MenuItem (click)="exportDraft()"><i
                    class="fa-regular fa-download mx-1"></i>{{'Export'|
                  translate:language:ModuleNames.SurveyX}}</a>
              </ng-template>
            </div>


          </div>
        </div>
      </div>
      <div class="w-full pt-6 pb-8">
        <div class="flex flex-row flex-wrap w-full pb-8 gap-3">
          <div class="flex px-6 pt-2 w-full item" *ngFor="let gender of data.pie">
            <div class="flex flex-row items-center gap-2 w-2/6 text-sm text-zen ">
              <i
                [ngClass]="gender.key === 'male' ? 'fa-regular fa-user-tie-hair text-zen-blue' :  'fa-regular fa-user-tie-hair-long text-zen-pink relative top-0.5'"></i>
              {{ gender.name }}
            </div>
            <div (click)="openIndividualResponsesModal(gender.name)" class="btn flex w-3/6">
              <progress [ngClass]="gender.key === 'male' ? 'bg-zen-blue':'bg-zen-pink'" [value]="gender.value" max="100"
                class="custom-progress"></progress>
            </div>
            <div
              class="flex flex-row items-center  w-1/6 justify-end text-sm text-zen-primarydefault capitalize font-medium">
              {{gender.value1}} ({{ gender.value |
              number:'1.1-1' }}%)

            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

  <!-- <ng-container *ngSwitchCase="'Generation'"> -->

    <!-- <div class="flex flex-row flex-wrap w-full bg-white items-center justify-center rounded-md borderLight main-box mb-4"
      #generationsPrintArea>
      <div class="sd-header__text pl-6 pr-4 py-2 h56 justify-center ">
        <div class="flex items-center">
          <div class="flex-auto">
            <h3 class="sd-title ">{{chart.Title}}</h3>
          </div>
          <div class="flex-none flex flex-row print:hidden">

            <div class=" btn pl-3 items-start justify-end">
              <div [ZMenuTriggerFor]="menu"
                class=" flex h-8 w-8 justify-center items-center font-normal text-zen text-center rounded leading-9 text-sm bg-zen-blueStone hover:bg-opacity-10 bg-opacity-0">
                <div class="flex h-4 items-center gap-2">
                  <a class="flex w-5 h-5 justify-center items-center">
                    <i class="fa-regular fa-ellipsis text-lg text-blueGray600"></i>
                  </a>

                </div>
              </div>

              <z-menu #menu>
                <menuItems>
                  <ng-container [ngTemplateOutlet]="moreMenuItems"></ng-container>
                </menuItems>
              </z-menu>

              <ng-template #moreMenuItems>
                <a MenuItem (click)="exportDraft()"><i
                    class="fa-regular fa-download mx-1"></i>{{'Export'|
                  translate:language:ModuleNames.SurveyX}}</a>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row flex-wrap w-full pt-6 pb-8 gap-3 items-center justify-center">
        <div class="flex px-6 pt-2 w-full item" *ngFor="let generation of getCriteriaOptions(chart.group)">
          <div class="flex w-1/3 text-sm text-zen">
            {{generation.name | extract:language}}
          </div>
          <div (click)="openIndividualResponsesModal(extract(generation.name))" class="btn flex w-2/3">
            <progress [value]="getCount(data,generation.key,chart.group)?.percentage" max="100"
              class="custom-progress"></progress>
          </div>
          <div class="flex w-3/3 w-12 justify-end text-sm text-zen-primarydefault capitalize font-medium">
            {{ getCount(data,generation.key,chart.group)?.count }}
          </div>
        </div>
      </div>
    </div> -->

  <!-- </ng-container> -->


  <ng-container *ngSwitchDefault>

    <div class="block w-full bg-white   main-box mb-4" #industriesPrintArea (click)="logData(data)">
      <div class="block sd-header__text pl-6 pr-4 py-2 h56 justify-center border-r rounded-t-md border-l border-t border-zen border-opacity-10">
        <div class="flex flex-row h-full justify-between items-center">
          <div class="flex-auto">
            <h3 class="sd-title ">{{chart.Title}}</h3>
          </div>
          <div class="flex-none flex flex-row print:hidden">

            <div *ngIf="!customChart" class=" btn pl-3 items-start justify-end">
              <div [ZMenuTriggerFor]="menuG"
                class=" flex h-8 w-8 justify-center items-center font-normal text-zen text-center rounded leading-9 text-sm bg-zen hover:bg-opacity-10 bg-opacity-0">
                <div class="flex h-4 items-center gap-2">
                  <a class="flex w-5 h-5 justify-center items-center">
                    <i class="fa-regular fa-chart-bar text-lg text-blueGray600"></i>
                  </a>
                </div>
              </div>

              <z-menu #menuG>
                <menuItems>
                  <ng-container [ngTemplateOutlet]="moreMenuGItems"></ng-container>
                </menuItems>
              </z-menu>

              <ng-template #moreMenuGItems>
                <a MenuItem *ngIf="chartType != 'column'" (click)="changeChartType('column')"><i
                    class="fa-regular fa-chart-column mx-1"></i>{{'Column'| translate:language:ModuleNames.SurveyX}}</a>
                <a MenuItem *ngIf="chartType != 'bar'" (click)="changeChartType('bar')"><i
                    class="fa-regular fa-chart-bar mx-1"></i>{{'Horizontal Bars'| translate:language:ModuleNames.SurveyX}}</a>
                <a MenuItem *ngIf="chartType != 'pie'" (click)="changeChartType('pie')"><i
                    class="fa-regular fa-chart-pie-simple mx-1"></i>{{'Pie'| translate:language:ModuleNames.SurveyX}}</a>
                <!-- <a MenuItem (click)="changeChartType('line')"><i class="fa-regular fa-chart-line mx-1"></i>{{'Line'}}</a>
                              <a MenuItem (click)="changeChartType('area')"><i class="fa-regular fa-chart-area mx-1"></i>{{'Area'}}</a> -->
              </ng-template>
            </div>

            <div class=" btn pl-3 items-start justify-end">

              <div [ZMenuTriggerFor]="menu"
                class=" flex h-8 w-8 justify-center items-center font-normal text-zen text-center rounded leading-9 text-sm bg-zen-blueStone hover:bg-opacity-10 bg-opacity-0">
                <div class="flex h-4 items-center gap-2">
                  <a class="flex w-5 h-5 justify-center items-center">
                    <i class="fa-regular fa-ellipsis text-lg text-blueGray600"></i>
                  </a>
                </div>
              </div>

              <z-menu #menu>
                <menuItems>
                  <ng-container [ngTemplateOutlet]="moreMenuItems"></ng-container>
                </menuItems>
              </z-menu>

              <ng-template #moreMenuItems>
                <!-- <a MenuItem><i class="fa-regular fa-table mx-1"></i>{{'txtRaw Data'| translate:language:ModuleNames.SurveyX}}</a> -->
                <a MenuItem (click)="exportDraft()"><i class="fa-regular fa-download mx-1"></i>{{'Export'|
                  translate:language:ModuleNames.SurveyX}}</a>
              </ng-template>

            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!customChart" class="borderLight flex flex-row flex-wrap w-full pt-6 pb-8 gap-3 items-center justify-center">
        <div class="flex px-6 pt-2 w-full item">
          <div class="chart-container" style="display: inline-block; position: relative; width: 100%;">
            <kendo-chart (render)="attachSurfaceHandlers($event)" (seriesClick)="onSeriesClick($event, chart.Title)"
              [k-text-ellipsis] class="kendo-chart k-text-ellipsis">
              <kendo-chart-series>
                <kendo-chart-series-item type="{{chartType }}" [data]="data.pie" [autoFit]="true" [color]="chartColor"
                  field="value" categoryField="name">
                  <kendo-chart-series-item-tooltip>
                    <ng-template let-respondentCount="dataItem.value1">
                      Respondents: {{ respondentCount }}
                    </ng-template>
                  </kendo-chart-series-item-tooltip>
                  <kendo-chart-series-item-labels [align]="column" [content]="onTextContent" position="outsideEnd"
                    font="0.788em Rubik,Tajawal, sans-serif" [rotation]="auto">
                  </kendo-chart-series-item-labels>
                </kendo-chart-series-item>
              </kendo-chart-series><kendo-chart-value-axis>
                <kendo-chart-axis-defaults [majorGridLines]="{ visible : false}">
                </kendo-chart-axis-defaults>
                <kendo-chart-value-axis-item [labels]="{ format: '# \\%', rotation: 'auto' }">
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
              <kendo-chart-category-axis>
                <kendo-chart-axis-defaults [majorGridLines]="{ visible : false}">
                </kendo-chart-axis-defaults>
                <kendo-chart-category-axis-item>
                  <kendo-chart-category-axis-item-labels [content]="content" [visual]="visual"
                    font="0.788em Rubik,Tajawal, sans-serif" class="truncate" style="align-items:center" [rotation]="0">
                  </kendo-chart-category-axis-item-labels>
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
            </kendo-chart>
            <ng-template #popupTemplate>
              <div class="chart-popup k-tooltip custom-popup-style">
                {{ popupContent }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="block" *ngIf="customChart">
        <ng-container *ngFor="let category of data.pie ; let c = index">
          <div [ngClass]="{'break-before mt20 border-t border-zen border-opacity-10': (c %  20 == 0 && c)}">
            <div class="chart-row px-6 border-b border-r border-l border-zen border-opacity-10  bg-white">
              <div class="chart-head">
                <div class="myRow middle">
                  <p class="font-normal text-sm text-zen">
                    <!-- <i  [ngClass]="{'turn90':showDetails == c}" class="fa fa-chevron-right text-sm icon40"></i> -->
                    {{category.name?? dashlet.Title }}
                  </p>
                </div>
                <div class="myRow middle justify-end">
                  <div class="chart400">
                    <div (click)="openIndividualResponsesModal(category.name)"
                      class="chartLine bg-exp bg-opacity-5 rounded-sm btn">
                      <div [style.width.%]="category.value" class="percentage bg-exp rounded-sm"></div>
                    </div>
                  </div>
                  <div class="chart40 mx-3">
                    <p class="font-normal text-sm text-zen ">
                    </p>
                  </div>
                  <div class="chart40">
                    <p class="font-normal text-sm text-zen ">
                      {{category.value | number:'1.1-1'}} %</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
