import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { CommentsTopicsDto, SectionSentimentDto } from '../interfaces/ai-models';
import { SentimentAnalysis } from '../enums/sentiment-analysis';

@Component({
  selector: 'app-ai-base',
  template: `
    <p>
      ai-base works!
    </p>
  `,
  styles: [
  ],
})
export class AiBaseComponent extends BaseComponent implements OnInit {
  topicsList: CommentsTopicsDto[]
  sections: SectionSentimentDto[] | any;
  SentimentAnalysis = SentimentAnalysis;
  percentageColors = [
    { key: this.SentimentAnalysis.noSentiment, value: 'noSentimentPercentage', color: 'bg-zen-shaded' },
    { key: this.SentimentAnalysis.negative, value: 'negativePercentage', color: 'bg-zen-watermelon' },
    { key: this.SentimentAnalysis.neutral, value: 'neutralPercentage', color: 'bg-zen-titaniumwhite' },
    { key: this.SentimentAnalysis.positive, value: 'positivePercentage', color: 'bg-zen-lmngreen' },
  ]
  constructor() {
    super();
  }


  sentimentPipe(sentiment: string) {
    return  sentiment == SentimentAnalysis.noSentiment? "no_sentiment" :  sentiment;
  }

  ngOnInit(): void {
  }

}
