import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PageModel, SurveyModel } from "survey-core";
import { AngularComponentFactory } from "survey-angular-ui";
import { PageAdorner, CreatorBase } from "survey-creator-core";
import { Direction } from "@angular/cdk/bidi";
import { LocalService } from "@ex/module/core/services/local.service";
import { CreatorModelComponent } from "../../creator-model.component";

@Component({
  selector: 'app-zen-svc-page',
  templateUrl: './zen-svc-page.component.html',
  styleUrls: ['./zen-svc-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZenSvcPageComponent extends CreatorModelComponent<PageAdorner> implements OnInit {



  localService: LocalService;

  @Input() model!: PageModel;
  @Input() survey!: SurveyModel;
  @Input() creator!: CreatorBase;
  @Input() isGhost?: boolean;

  language = "EN";
  direction: Direction = "ltr"

  public adorner!: PageAdorner;

  OnInit() {
    this.language = this.localService.getJsonValue('language')

    if (this.language == "AR")
      this.direction = "rtl"
    else
      this.direction = "ltr"

  }

  protected createModel(): void {
    if (this.model) {
      this.previousModel?.dispose();
      this.adorner = new PageAdorner(this.creator, this.model);


    }
  }

  protected getModel(): PageAdorner {
    return this.adorner;
  }

  protected getPropertiesToTrack(): string[] {
    return ["creator", "model"];
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.adorner.dispose();
  }
}

AngularComponentFactory.Instance.registerComponent("zen-svc-page", ZenSvcPageComponent);
