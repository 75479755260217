export class PerformanceRate {
  key: string;
  value: string;
  description: any[] = [];
  icon: string;
  usedByExit: boolean = false;
  usedByEngagement: boolean = false;
  usedByAssessment: boolean = false;

}
