import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general.service';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';

@Component({
  selector: 'single-answer-confidence-with-na',
  templateUrl: './single-answer-confidence-with-na.component.html',
  styleUrls: ['./single-answer-confidence-with-na.component.scss']
})
export class SingleAnswerConfidenceWithNaComponent implements OnInit {
  ModuleNames = ModuleName
  lgCount;
  @Input() happiness = false;
  @Input() assessment = false;
  @Input() engagement = false;

  @Input() question;

  @Input() single = false;


  changingLanguage: boolean = false;
  language: string;
  @Input('language') set currentLanguage(lang: string) {
    // if (lang != null && lang != this.language) {
    //   this.changingLanguage = true;
      this.language = lang;
      // setTimeout(() => {
      //   this.changingLanguage = false;

      // }, 50);
    // }
  }
  constructor(private _globals: GlobalsService, private languageService: LanguageService, private generalService: GeneralService) {
    this.lgCount = _globals.lgCount;
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  ngOnInit(): void {
  }
  countWord(word, count) {
    if (word?.length <= count)
      return word.length;
    if (word?.length > count) {

      return word.length;
    }
  }
  logData(e) {
    console.log(e);
  }

  getConfidence(subquestionKey: string, key: string) {
    return this.generalService.getQuestionOptions(subquestionKey, key, this.language);
    if (subquestionKey == 'sub-questions-confidence-rating')
      return this.generalService.confidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-confidence-na-rating')
      return this.generalService.confidenceRatingWithNA.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-likeness-rating')
      return this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-likeness-na-rating')
      return this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-binary-likeness-rating')
      return this.generalService.binaryLikeness.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-fairness-degree')
      return this.generalService.moderateFairnessQuestion.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-fairness-degree-na-rating')
      return this.generalService.moderateFairnessQuestionWithNA.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'single-answer-rating')
      return this.generalService.singleAnswerConfidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'single-answer-out-10')
      return this.generalService.singleAnswerOutof10.find(cf => { return (cf.key == key) }).name;
    if (subquestionKey == 'single-answer-confidence-no-neutral')
      return this.generalService.confidenceRatingWithoutNA.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;

    if (subquestionKey == 'single-answer-confidence-with-na')
      return this.generalService.confidenceRatingWithNA.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
  }
}
