import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';

@Component({
  selector: 'zen-slider',
  templateUrl: './zen-slider.component.html',
  styleUrls: ['./zen-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZenSliderComponent,
    },
  ],
})
export class ZenSliderComponent  implements ControlValueAccessor {

  @Input() language = 'EN';
  constructor(private languagePipe:LanguagePipe,) { }
  selected = false;
  innerWidthValue;
  startX: number = 0;
  rate:number = 1;
  @ViewChild('parentContainer') parentContainer: ElementRef;
  @ViewChild('dragButton') dragButton: ElementRef;
  @ViewChild('innerContainer') innerContainer: ElementRef;
  @Output() valueChange = new EventEmitter();
  @Input() steps: any[] = [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  @Input() rating: boolean = true;
  @Input() disable: boolean = false;
  onChange: any = () => { }
  onTouch: any = () => { }
  toggle = false;

  writeValue(rate: any) {
    if (rate !== undefined) {
      this.rate = rate;
    }
    
    this.roundValue(this.rate);
    
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }
  handleChange(value) {
    this.rate = value;
    this.onChange(value);
  }



  mouseMove(e) {
    let event = e;
    if (e.type == "touchmove")
      event = e.touches[0];

    if (!this.startX)
      this.startX = event.clientX;
    this.innerContainer.nativeElement.classList.remove('ease-expo', 'transition-all', 'duration-500');
    if (event.clientX > this.parentContainer.nativeElement.getBoundingClientRect().left && event.clientX < this.parentContainer.nativeElement.getBoundingClientRect().left + this.parentContainer.nativeElement.clientWidth) {
      if (this.languagePipe.transform(this.language))
        this.innerWidthValue = this.parentContainer.nativeElement.clientWidth - (event.clientX - this.parentContainer.nativeElement.getBoundingClientRect().left);

      else this.innerWidthValue = event.clientX - this.parentContainer.nativeElement.getBoundingClientRect().left;
    }
  }


  handleClick(event) {
    this.innerContainer.nativeElement.classList.add('ease-expo', 'transition-all', 'duration-500');
    this.startX = this.dragButton.nativeElement.getBoundingClientRect().left + (this.dragButton.nativeElement.clientWidth / 2);
    if (this.languagePipe.transform(this.language))
      this.innerWidthValue = this.parentContainer.nativeElement.clientWidth - (event.clientX - this.parentContainer.nativeElement.getBoundingClientRect().left);

    else this.innerWidthValue = event.clientX - this.parentContainer.nativeElement.getBoundingClientRect().left;

    this.roundValue();
  }


  roundValue(value?) {
    let stepWidth = this.parentContainer?.nativeElement.clientWidth / (this.steps.length - 1);

    let newWidth;
    if (!value) {
      this.innerWidthValue ? newWidth = Math.round(this.innerWidthValue / stepWidth) * stepWidth : newWidth = 0;

      let rate = this.innerWidthValue ? this.steps[Math.round(this.innerWidthValue / stepWidth)] : this.steps[0];
      this.handleChange(rate);
    }
    else newWidth = value * stepWidth;

    this.innerWidthValue = newWidth;
  }
  resolveColor() {
    switch (this.rate) {
      case 10:
      case 9:
        return 'zen-green';

      case 7:
      case 8:
        return 'orange';

      default:
        return 'zen-red';

    }
  }
  logData(e) {
    console.log(e);
  }
}
