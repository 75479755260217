
  <z-modal [open]="open" >
    <ng-container header>
      <h2 class="text-zen text-2xl font-semibold">{{'txtEditSurvey'| translate:language:ModuleNames.Wellbeing}}</h2>
      <i (click)="closeModal()"
        class="fa-regular fa-times leading-12 btn text-center text-xl text-zen text-opacity-70"></i>
    </ng-container>
    <ng-container body>

      <z-translation-input [maxCount]="mdCount"  [label]="'txtTitle'| translate:language:ModuleNames.Wellbeing"  [required]="true"
        [language]="language" [supportedLanguages]="selectedEntity?.ContentLanguages" [(ngModel)]="selectedToEdit.title">
      </z-translation-input>

    </ng-container>

    <ng-container footer class="flex w-full flex-row justify-between">
      <a (click)="closeModal()"
        class="btn leading-10 rounded-sm cancel px-5 text-sm font-medium">{{'btn_cancel'| translate:language:ModuleNames.Wellbeing}}</a>
      <a (click)="results.emit(selectedToEdit)"
        class="btn leading-10 rounded-sm exp px-5 text-sm font-medium">{{'btn_update'| translate:language:ModuleNames.Wellbeing}}</a>
    </ng-container>
  </z-modal>
