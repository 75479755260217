import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';

@Component({
  selector: 'app-add-question-modal',
  templateUrl: './add-question-modal.component.html',
  styleUrls: ['./add-question-modal.component.scss']
})
export class AddQuestionModalComponent extends ModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Input() language: string = "EN";
  ModuleNames = ModuleName;

  @Output() openChange = new EventEmitter();
  @Output() typeSelected = new EventEmitter<string>();

  ngOnInit(): void {}

  close() {
    this.openChange.emit(false);
  }

  selectType(type: string) {
    this.typeSelected.emit(type);
  }
}
