<div class="flex flex-row px-7 items-center justify-between h-20 btn "
    [ngClass]="{'bg-zen bg-opacity-10 hover:bg-opacity-20 font-medium':active, 'hover:bg-zen hover:bg-opacity-10':!active}">
    <div class="flex flex-row gap-3 items-center">
        <i *ngIf="status" class="fa-regular text-2xl w-8 text-center" [class]=" globalStatus.returnClassColor(status)"></i>
        <p class="text-sm customUpper" [ngClass]="active?'text-zen':'text-zen-lite'">
            <ng-content select="[text]"></ng-content>
        </p>
    </div>
    <div class="flex flex-row items-center justify-end">
        <h5 *ngIf="data || data == 0" class="text-lg" [ngClass]="active?'text-zen':'text-zen-lite'">{{data}}</h5>
        <ng-content select="[data]"></ng-content>
    </div>
</div>