import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { DemoSectionComponent } from './demo-section/demo-section.component';
import { ZenCDKService } from '../Theme/services/zen-cdk.service';
import { DemoPopupComponent } from './demo-popup/demo-popup.component';
import { DemoAccountService } from './demo-account.service';
import { DemoReportPageComponent } from './demo-report-page/demo-report-page.component';
enum DemoBehavior {
  Section = 'Section',
  Button = 'Button',
  AIButton = 'AIButton',
  Report = 'Report',
  Default = 'Default'
}

@Directive({
  selector: '[DemoAccount]'
})
export class DemoAccountDirective {
  templateRef: TemplateRef<any>;
  currentType;
  context: { isDemo: boolean } = {
    isDemo: false
  };
  @Output() dClick = new EventEmitter();
  @Input('DemoAccount') set DemoType(type: DemoBehavior) {
    this.currentType = type;
    if (this.context.isDemo) {
      if (type == DemoBehavior.Section)
        this.sectionHandle();
      else if (type == DemoBehavior.Report)
        this.reportHandle();
      else if (type == DemoBehavior.AIButton)
        this.buttonHandle(true);
      else if (type == DemoBehavior.Button)
        this.buttonHandle();
      else this.defaultHandle();

    }
    else this.defaultHandle();
  };



  constructor(private viewContainerRef: ViewContainerRef, private el: ElementRef, templateRef: TemplateRef<any>, private zenCdk: ZenCDKService, private demoService: DemoAccountService) {
    this.templateRef = templateRef;
    this.demoService.isDemo$.subscribe(x => {
      this.context.isDemo = x;
    })
  }

  buttonHandle(ai?) {
    this.viewContainerRef.clear();
    const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
    view.rootNodes[0].insertAdjacentHTML('beforeend', ` <span class="h-0 flex w-full text-center absolute -top-1 -left-1 -right-1 flex-row items-start justify-end"><i class="fa-solid fa-star text-white bg-zen-yellow rounded-full leading-4 text-xxs text-center w-4 min-w-[4]" style="color:#fff"></i></span> `)
    if (!ai)
      view.rootNodes[0].addEventListener("click", (e) => {
        this.zenCdk.openComponent(DemoPopupComponent)
      });
    view.context = this.context;
  }
  reportHandle() {
    this.viewContainerRef.clear();
    const component = this.viewContainerRef.createComponent(DemoReportPageComponent)
    this.viewContainerRef.insert(component.hostView)
    const view = this.viewContainerRef.insert(component.hostView)
  }

  sectionHandle() {
    this.viewContainerRef.clear();
    const component = this.viewContainerRef.createComponent(DemoSectionComponent)
    this.viewContainerRef.insert(component.hostView)
    const view = this.viewContainerRef.insert(component.hostView)
  }

  defaultHandle() {
    this.viewContainerRef.clear();
    const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
    view.context = this.context;
  }
}


