import { Injectable } from "@angular/core";
import { IKeyResolver } from "src/app/core/services/key-resolver.service";
import { TranslatePipe } from "src/app/shared/pipes/translate.pipe";


@Injectable({
    providedIn: 'root',
})
export class LeaverStatusResolver implements IKeyResolver {
    name: string = "LeaverStatus";

    constructor(private translatePipe: TranslatePipe) { }

    resolve(key: string, language: string, options?: any): string {

        if (key == null)
            return;

        const txtKey = `txt_${key.toLowerCase()}`

        return this.translatePipe.transform(txtKey, language, options);
    }

}