import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ChildrenAnimation } from '../../animations/allAnimations';

@Component({
  selector: 'q-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations:[ChildrenAnimation]
})
export class MainPageComponent implements OnInit {
  @ViewChild('body') body: ElementRef;
  @ViewChild('innerBody') innerBody: ElementRef;
  @Input() autoClose: boolean = false;
  @Input() open: boolean = false;
  @HostListener('window:resize', ['$event'])
  @HostListener('window:click', ['$event'])
  @HostListener('document:keyup', ['$event'])
  onResize(event) {
    this.maintainChanges(100)
  }
  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) { };
  @Input() title;
  @Input() showSectionIntro = false;
  ngOnInit(): void {
    this.maintainChanges(200);
  } 

  ngOnChanges(changes: SimpleChanges): void {
    this.maintainChanges(200);
  }
  logData(e) {
    console.log(e);

  }
  maintainChanges(time) {
    this.cdr.detectChanges();
    setTimeout(() => {
      this.renderer.setStyle(this.body.nativeElement, 'height', this.innerBody.nativeElement.offsetHeight + 'px');
      this.renderer.setStyle(this.body.nativeElement, 'max-width', '90vw');
    }, time);

  }
}
