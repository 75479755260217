import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { DATA_TOKEN } from '../../Theme/services/zen-cdk.service';

@Component({
  selector: 'app-demo-popup',
  templateUrl: './demo-popup.component.html',
  styleUrls: ['./demo-popup.component.scss']
})
export class DemoPopupComponent extends BaseComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();
  open: boolean = false;

  language;
  constructor(@Inject(DATA_TOKEN) private data: any) {
    super();
    this.addSubscription(this.EssentialObject$.subscribe(x => {
      this.language = x.language;
    }));
  }

  ngOnInit(): void {
    this.open = true;
  }


  upgrade() {
    this.SnackbarService.info(this.localize('txtUpgradeRequestSent', this.language, this.ModuleNames.Shared));
    this.open = false;
    setTimeout(() => {
      this.close.emit(true);
    }, 300);
  }
}
