<div *ngIf="!changingLanguage" class="flex flex-row w-full gap-1 items-stretch">
    <div *ngIf="assessment" class="flex flex-row items-center justify-center w-2/12">
        <div [ngClass]="question.answer == '0'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
            (click)="questionAnswer(0)"
            class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
            <i class="fa-light fa-ban text-4xl md:text-5xl leading-18 rounded-lg w-full text-center"></i>
            <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                getConfidence('single-answer-rating', '0')}}</h5>
        </div>
    </div>
    <div class="flex flex-row  w-full gap-1  items-stretch">
        <div class="flex flex-row items-center justify-center" [ngClass]="happiness?'w-1/4':'w-1/5'">
            <div [ngClass]="question.answer == '1'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="questionAnswer(1)"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                <i
                    class="fa-light fa-face-pouting text-4xl md:text-5xl leading-18 rounded-lg w-full text-center"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-rating', '1')}}</h5>
            </div>
        </div>

        <div class="flex flex-row items-center justify-center" [ngClass]="happiness?'w-1/4':'w-1/5'">
            <div [ngClass]="question.answer == '2'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="questionAnswer(2)"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset gap-2 md:gap-4 rounded h-full">
                <i
                    class="fa-light fa-face-worried text-4xl md:text-5xl leading-18 rounded-lg w-full text-center"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-rating', '2')}}</h5>
            </div>
        </div>
        <div class="flex flex-row items-center justify-center" [ngClass]="happiness?'w-1/4':'w-1/5'">
            <div [ngClass]="question.answer == '3'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="questionAnswer(3)"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset  gap-2 md:gap-4 rounded h-full">
                <i [ngClass]="happiness?'fa-face-smile-relaxed':'fa-face-thinking'"
                    class="fa-light  text-4xl md:text-5xl leading-18 rounded-lg w-full text-center"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-rating', happiness? '4':'3')}}</h5>
            </div>
        </div>
        <div class="flex flex-row items-center justify-center" [ngClass]="happiness?'w-1/4':'w-1/5'">
            <div [ngClass]="question.answer == '4'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="questionAnswer(4)"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset rounded gap-2 md:gap-4 h-full">
                <i [ngClass]="happiness?'fa-face-laugh-beam':'fa-face-smile-relaxed'"
                    class="fa-light  text-4xl md:text-5xl leading-18 rounded-lg w-full text-center"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-rating', happiness? '5':'4')}}</h5>
            </div>
        </div>
        <div *ngIf="!happiness" class="flex flex-row items-center justify-center w-1/5">
            <div [ngClass]="question.answer == '5'?'bg-exp text-white':'border-transparent text-zen text-opacity-90'"
                (click)="questionAnswer(5)"
                class="flex flex-col items-center justify-start py-3 w-full btn  bg-white border-2 border-inset rounded gap-2 md:gap-4 h-full">
                <i class="fa-light fa-face-laugh-beam text-4xl md:text-5xl leading-18 rounded-lg w-full text-center"></i>
                <h5 class=" text-opacity-80 text-xs font-medium  text-center"> {{
                    getConfidence('single-answer-rating', '5')}}</h5>
            </div>
        </div>
    </div>
</div>