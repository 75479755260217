import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'export-question-template',
  templateUrl: './export-question.component.html',
  styleUrls: ['./export-question.component.scss']
})
export class ExportQuestionComponent implements OnInit {
  constructor() { }
  @Input() answers;
  @Input() name;
  @Input() score;

  ngOnInit(): void {
  }

}
