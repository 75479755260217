import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ZipDownloadService {

  constructor() { }

  // Create a function to decode base64
  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async generateZipContent(files: { [fileName: string]: string }) {
    const zip = new JSZip();

    // Iterate over each file
    for (const [fileName, base64Content] of Object.entries(files)) {
      // Decode base64 content
      const decodedContent = this.base64ToArrayBuffer(base64Content);

      // Add file to zip
      zip.file(fileName, decodedContent);
    }

    // Generate zip content
    const content = await zip.generateAsync({ type: 'blob' });

    saveAs(content, 'download.zip');
  }
}
