import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { GlobalStatusService, Status } from 'src/app/core/services/global-status.service';
import { ChildrenAnimation } from '@ex/module/shared/animations/allAnimations';
import { BaseComponent } from '@ex/module/core/domain/base-component';

@Component({
  selector: 'sx-respondent-pool',
  templateUrl: './respondent-pool.component.html',
  styleUrls: ['./respondent-pool.component.scss'],
  animations: [ChildrenAnimation],
})
export class SxRespondentPoolComponent extends BaseComponent implements OnInit {

  constructor(public globalStatus: GlobalStatusService) {
    super();
  }

  ngOnInit(): void { }
  @ContentChild(TemplateRef) optionsTemplate: TemplateRef<any>;
  @Output('singleDelete') singleDelete = new EventEmitter();
  @Output('bulkDelete') bulkDelete = new EventEmitter();
  @Output('pageChanged') pageChanged = new EventEmitter();
  @Output('selectedRespondents') selectedRespondents = new EventEmitter();
  @Output() searchTextChange = new EventEmitter();
  @Input() searchText;
  @Output('allSelected') allSelected = new EventEmitter();
  @Output('export') export = new EventEmitter();
  @Input() canDelete: boolean = true;
  @Input() pageSize;
  @Input() totalRecords;
  @Input() deleteCount;
  @Input() exportable: boolean = false;
  options: TableOptions;
  @Input('options') set opt(tableoptions: TableOptions) {
    if (!tableoptions)
      return;
    tableoptions.selectAllReverse = true;
    this.options = tableoptions;
    if (!this.options?.data?.length)
      this.currentRespondents = [];
  };
  @Input() currentPage;
  @Input() language;
  @Input() rowHeight: number = 20;
  @Input() customHeader: boolean = false;
  selectedRespondentsUpdate(respondents: Partial<any>[]) {
    if (this.customSelectedAll && this.options.data.length == respondents.length)
      return this.selectedRespondents.emit([]);
    this.selectedRespondents.emit(respondents);
    this.currentRespondents = respondents;
  }
  filterText;
  currentRespondents;
  customSelectedAll: boolean = false;
  tableSelectAll: boolean = false;
  rStatus = Status;

  clear() {
    this.options = JSON.parse(JSON.stringify(this.options));
  }
  selectAllFromTable(event) {
    this.customSelectedAll = event;
    this.allSelected.emit(event);
  }
  replaceCount(text, count) {
    return this.localize(text, this.language, this.ModuleNames.SurveyX).replace('#count', (count).toString());
  }

  emailCheck(value: string) {
    if (value.indexOf('sl_') != -1 && value.indexOf('_no_email@zenithr.com') != -1)
      return this.localize('txt_Generated_142', this.language, this.ModuleNames.SurveyX);
    if (value.indexOf('sl_') === -1 && value.indexOf('_no_email@zenithr.com') != -1)
      return value.split('_')[0];
    return value;
  }

  statusReplace(status) {
    if (status == this.rStatus.Posted)
      return this.rStatus.Pending
    return status;
  }

  bulkDeleteAll() {
    if (this.customSelectedAll && this.currentRespondents.length == this.options.data.length)
      this.currentRespondents = [];
    this.bulkDelete.emit(this.currentRespondents)
  }
}
