<div class="fixed flex flex-col items-stretch top-0 left-0 Page h-screen w-screen z-50">
    <app-form-builder-header #header
        [surveyId]="surveyId"
        [surveyTitle]="getCreator()?.survey.title  | sjsTranslate:language"
        [currentPage]="'questions-builder'"
        [showAssignWeights]="showAssignWeights"
        [language]="currentLocale?.toUpperCase()"
        [dir]="direction"
        [isUpdating]="(updatedCreator?.notifier.active && updatedCreator?.notifier.isDisplayed)"
        (nextStep)="nextStep()"
        (previousStep)="previousStep()"
        (pageChanged)="pageChanged($event)">
    </app-form-builder-header>

    <div [dir]="direction" class="w-full h-100vh-110px" *ngIf="currentLocale">
        <app-custom-form-builder [companyName]="(companyName | extract:language)" [creator]="getCreator()" (creatorChange)="updatedCreator = $event" [language]="currentLocale?.toUpperCase()" ></app-custom-form-builder>
    </div>

</div>
