<zen-main-container *ngIf="filters?.tenantKey">
  <sidebar-container>
    <back-button [routerLink]="['/wellbeing/']">
      <i [ngClass]="(language | language) ? 'fa-arrow-right' : 'fa-arrow-left'" class="fa-regular"></i>
      <span>{{
        "txt_BACKTOSUR_422" | translate : language : ModuleNames.Wellbeing
        }}</span>
    </back-button>
    <section-sidebar>
      <menu-button *ngFor="let button of sideBarNavigationTabs; let i = index" (click)="tabClick(button)" [icon]="
          button.selected
            ? 'text-exp ' + button.icon
            : 'opacity-50 ' + button.icon
        " [active]="button.selected" [TourStep]="{
          title: button.title | translate : language : ModuleNames.Wellbeing,
          text:
            button.title + '_desc'
            | translate : language : ModuleNames.Wellbeing,
          order: 1,
          tourKey: 'wellbeing-closed-' + i,
          on: (language | language) ? 'right' : 'left'
        }">
        {{ button.title | translate : language : ModuleNames.Wellbeing }}
      </menu-button>
    </section-sidebar>
  </sidebar-container>
  <content-container class="flex flex-col items-stretch w-full">
    <div class="flex flex-col items-stretch h-28 z-10">
      <section-head>
        <ng-container left>
          <div class="flex flex-col gap-1">
            <h5 class="text-zen-lite text-xxs font-medium">
              {{ survey?.title | extract : language }}
            </h5>
            <h4 [@first] class="text-zen font-semibold text-2xl">
              {{
              "txtAnalysisSection"
              | translate : language : ModuleNames.Wellbeing
              }}
              -
              {{
              activeTab?.title | translate : language : ModuleNames.Wellbeing
              }}
            </h4>
          </div>
        </ng-container>
        <ng-container right>
          <div class="flex flex-row justify-end items-center gap-5">
            <a [@child4] (click)="openTree()" *ngIf="selectedChildEntity?.entity.name" class="btn cancel uppercase px-5 leading-12 rounded-md" [TourStep]="{
                title:
                  'txtViewBy' | translate : language : ModuleNames.Wellbeing,
                text:
                  'txt_Clickhere_662'
                  | translate : language : ModuleNames.Wellbeing,
                order: 2,
                tourKey: 'wellbeing-closed-0',
                on: (language | language) ? 'right' : 'left'
              }">{{ "txtViewBy" | translate : language : ModuleNames.Wellbeing }}:
              {{ selectedChildEntity?.entity.name | extract : language }}</a>

            <a *ngIf="!viewDisabled" (click)="export()" class="btn exp uppercase px-5 leading-12 rounded-md flex flex-row gap-2 items-center" [TourStep]="{
                title:
                  (activeTab.id != 4 ? 'txt_export_tb' : 'txt_download_comments')
                  | translate : language : ModuleNames.Wellbeing,
                text:
                  (activeTab.id != 4
                    ? 'txt_Clickhere_702'
                    : 'txt_Clickhere_159'
                  ) | translate : language : ModuleNames.Wellbeing,
                order: activeTab.id != 4 ? 3 : 2,
                tourKey: activeTab.id != 4? 'wellbeing-closed-0': 'wellbeing-closed-3',
                on: (language | language) ? 'right' : 'left'
              }">
              <i class="fa-regular fa-file-alt"></i>
              <span>{{
                (activeTab.id != 4 ? "btnExport" : "txt_download_comments")
                | translate : language : ModuleNames.Wellbeing
                }}</span></a>
          </div>
        </ng-container>
      </section-head>
    </div>

    <div class="flex flex-row items-center justify-end h-20 bg-zen bg-opacity-5 px-10 gap-5">
      <div class="flex flex-row items-stretch gap-5">
        <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
        <div [@child8] *ngIf="hasExtraDimensions" class="flex flex-row items-center">
          <z-toggle [label]="
              'txtExtraDriver' | translate : language : ModuleNames.Wellbeing
            " [(ngModel)]="filters.showExtraFactors" (ngModelChange)="toggleHasExtraFactors()"></z-toggle>
        </div>
      </div>
    </div>
    <wellbeing-scales *ngIf="activeTab.id != 4" [analysis]="activeTab.id == 3" [language]="language"></wellbeing-scales>
    <!-- Main Content Area -->
    <div class="flex flex-col items-stretch p-10 overflow-x-hidden">
      <div class="flex flex-col justify-between items-stretch gap-5">
        <ng-template #tabContentHolder></ng-template>
      </div>
    </div>
  </content-container>
  <ng-container *ngIf="language">
    <product-tour *ngIf="activeTabIndex==0" [key]="'wellbeing-closed-0'" [offset]="60" [destroy]="true"></product-tour>
    <product-tour *ngIf="activeTabIndex==1" [key]="'wellbeing-closed-1'" [offset]="60" [destroy]="true"></product-tour>
    <product-tour *ngIf="activeTabIndex==2" [key]="'wellbeing-closed-2'" [offset]="60" [destroy]="true"></product-tour>
    <product-tour *ngIf="activeTabIndex==3" [key]="'wellbeing-closed-3'" [offset]="60" [destroy]="true"></product-tour></ng-container>

</zen-main-container>