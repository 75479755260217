<z-modal [open]="true">
  <ng-container header>
    <div class="flex flex-row gap-2 items-center">
      <i class="fal fa-user text-white bg-exp  shadow-xl rounded-full h-10 w-10 text-xl text-center leading-10 "></i>
      <h4 class="text-2xl font-bold text-zen">{{'txtEditRespondent'|translate:language:ModuleNames.Shared}}</h4>
    </div>
  </ng-container>
  <ng-container body>
    <div class="flex flex-row flex-wrap -mr-3 -ml-3">
      <div class="flex flex-col w-1/3 px-3 pb-3">
        <z-input [label]="'txtRespondentID'|translate:language:ModuleNames.Shared" [maxCount]='smCount'[language]="language" [(ngModel)]="user.employeeID"></z-input>
      </div>
      <div class="flex flex-col w-1/3 px-3 pb-3">
        <z-input [label]="'txt_respondent_name'|translate:language:ModuleNames.Shared" [maxCount]='mdCount'[language]="language" [(ngModel)]="user.name"></z-input>
      </div>
      <div class="flex flex-col w-1/3 px-3 pb-3">
        <z-input [disabled]="true" [label]="'txt_email_address'|translate:language:ModuleNames.Shared" [maxCount]='mdCount'[language]="language" [ngModel]="user.email"></z-input>
      </div>
      <ng-container *ngFor="let metric of user.Metrics;let d = index">
        <ng-container >
            <div class="flex flex-col gap-3 md:w-1/2 w-full md px-3 items-stretch pb-6" *ngIf="metric.type != 'DATE' && metric.key != 'NATIONALITY' && metric.options?.length">
                <z-select [color]="'exp'"  [label]="metric.name|extract:language"  [(ngModel)]="metric.value" (ngModelChange)="metric.isEntity === true ? entityGroupChanged($event, metric.key) : doNothing()" [options]="metric.options" [language]="language">
                  <ng-template #valueTemplate let-value>
                    {{value?.name|extract:language}}
                  </ng-template>
                </z-select>
            </div>
            <ng-container *ngIf="metric.type != 'DATE' && metric.key == 'NATIONALITY'">
                <div class="flex flex-col gap-3 md:w-1/2 w-full px-3 items-stretch pb-6" *ngIf="!resetDropdown">
                    <!-- <custom-dropdown class="customDropDown" [txtBranches]="metric.name|extract:language"
                        [selectionMode]="'Single'" [language]="language" [dropDownData]="metric.options"
                        [selectedKey]="metric.value" (selectionChanged)="dropDownChanged($event, metric)">
                    </custom-dropdown> -->

                    <z-select [color]="'exp'"  [label]="metric.name|extract:language"  [(ngModel)]="metric.value" (ngModelChange)="dropDownChanged($event, metric)" [options]="metric.options" [language]="language">
                      <ng-template #valueTemplate let-value>
                        {{value?.name|extract:language}}
                      </ng-template>
                    </z-select>
                </div>

            </ng-container>
            <div class="flex flex-col gap-3 md:w-1/2 w-full px-3 items-stretch pb-6" *ngIf="metric.type == 'DATE'">
             
                <z-datepicker [color]="'exp'" [label]=" metric.name|extract:language" [language]="language" (ngModelChange)="formateExistingDate($event,metric, true)" [ngModel]="metric.value"></z-datepicker>
            </div>
        </ng-container >
    </ng-container>
    </div>
  </ng-container>
  <ng-container footer>
    <a (click)="closeModal('respondentEdit'); respondentEditChange.emit(false)"
      class="btn cancel  text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{'btn_cancel'|translate:language:ModuleNames.Shared}}</a>
    <a (click)="update()"
      class="btn exp  w-auto text-center  align-middle rounded-md px-8 py-4">{{'btn_save_questionnaire'|translate:language:ModuleNames.Shared}}</a>
  </ng-container>