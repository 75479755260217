import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResponseDialogComponent } from '../../shared/response-dialog/response-dialog.component';
import { LanguageService } from './language.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class ResponseDialogService {
  responseDialog;
  confirmationDialog;

  constructor(private localService: LocalService, public matDialog: MatDialog, public languageService: LanguageService, public matConfirmDialog: MatDialog, private translationPipe: TranslatePipe) { }
  ModuleNames = ModuleName
  openDialog(message: string, subject: string, dialogType: string, actionButtonText: string, language: string, messageIncluded?: boolean) {
    if (!messageIncluded)
      message = this.translationPipe.transform(message, language, this.ModuleNames.Shared);

    subject = this.translationPipe.transform(subject, language, this.ModuleNames.Shared);
    actionButtonText = this.translationPipe.transform(actionButtonText, language, this.ModuleNames.Shared);

    this.responseDialog = this.matDialog.open(ResponseDialogComponent, {
      data:
        { message: message, subject: subject, dialogType: dialogType, actionButtonText: actionButtonText }, autoFocus: false, panelClass: 'customModal'
    });

  }

  openConfirmationDialog(message: string, subject: string, dialogType: string, actionButtonText: string, cancelButtonText: string, language: string) {
    message = this.translationPipe.transform(message, language, this.ModuleNames.Common)
    subject = this.translationPipe.transform(subject, language, this.ModuleNames.Common)
    actionButtonText = this.translationPipe.transform(actionButtonText, language, this.ModuleNames.Common)
    cancelButtonText = this.translationPipe.transform(cancelButtonText, language, this.ModuleNames.Common)

    this.confirmationDialog = this.matDialog.open(ConfirmationDialogComponent, {
      data:
        { message: message, subject: subject, dialogType: dialogType, actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false, panelClass: 'customModal'
    });

  }

  language = ""
  async confirmAllocation(message, subject, actionButtonText, cancelButtonText): Promise<boolean> {
    this.language = this.localService.getJsonValue('language');
    let confirmationDialog = this.matConfirmDialog.open(ConfirmationDialogComponent, {
      data:
        { message: message, subject: subject, dialogType: 'info', checkboxText: this.translationPipe?.transform("txtDontShow", this.language, this.ModuleNames.Common), actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false, panelClass: 'customModal'
    });
    let res: boolean = false;
    confirmationDialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result == 'YES') {
          res = true;
        } else {
          res = false;
        }
      } else {
        res = false;
      }
    });
    await confirmationDialog.afterClosed().toPromise();
    return res;
  }

  async confirmation(message, subject, actionButtonText, cancelButtonText, moduleIdentifier, language): Promise<boolean> {
    if (moduleIdentifier == 'EXIT') {
      message = this.translationPipe.transform(message, language, this.ModuleNames.Common)
      subject = this.translationPipe.transform(subject, language, this.ModuleNames.Common)
      actionButtonText = this.translationPipe.transform(actionButtonText, language, this.ModuleNames.Common)
      cancelButtonText = this.translationPipe.transform(cancelButtonText, language, this.ModuleNames.Common)
    } else if (moduleIdentifier == 'ENGAGEMENT') {
      message = this.translationPipe.transform(message, language, this.ModuleNames.Common)
      subject = this.translationPipe.transform(subject, language, this.ModuleNames.Common)
      actionButtonText = this.translationPipe.transform(actionButtonText, language, this.ModuleNames.Common)
      cancelButtonText = this.translationPipe.transform(cancelButtonText, language, this.ModuleNames.Common)
    } else if (moduleIdentifier == this.ModuleNames.Wellbeing) {
      message = this.translationPipe.transform(message, language, moduleIdentifier)
      subject = this.translationPipe.transform(subject, language, moduleIdentifier)
      actionButtonText = this.translationPipe.transform(actionButtonText, language, moduleIdentifier)
      cancelButtonText = this.translationPipe.transform(cancelButtonText, language, moduleIdentifier)
    } else if (moduleIdentifier == 'ASSESSMENT') {
      message = this.translationPipe.transform(message, language, this.ModuleNames.Common)
      subject = this.translationPipe.transform(subject, language, this.ModuleNames.Common)
      actionButtonText = this.translationPipe.transform(actionButtonText, language, this.ModuleNames.Common)
      cancelButtonText = this.translationPipe.transform(cancelButtonText, language, this.ModuleNames.Common)
    }
    else if (moduleIdentifier == 'ONBOARDING') {
      message = this.translationPipe.transform(message, language, this.ModuleNames.Onboarding)
      subject = this.translationPipe.transform(subject, language, this.ModuleNames.Onboarding)
      actionButtonText = this.translationPipe.transform(actionButtonText, language, this.ModuleNames.Onboarding)
      cancelButtonText = this.translationPipe.transform(cancelButtonText, language, this.ModuleNames.Onboarding)
    }
    else {
      message = this.translationPipe.transform(message, language, moduleIdentifier)
      subject = this.translationPipe.transform(subject, language, moduleIdentifier)
      actionButtonText = this.translationPipe.transform(actionButtonText, language, moduleIdentifier)
      cancelButtonText = this.translationPipe.transform(cancelButtonText, language, moduleIdentifier)
    }


    let confirmationDialog = this.matConfirmDialog.open(ConfirmationDialogComponent, {
      data:
        { message: message, subject: subject, dialogType: 'warning', actionButtonText: actionButtonText, cancelButtonText: cancelButtonText }, autoFocus: false, panelClass: 'customModal'
    });
    let res: boolean = false;
    confirmationDialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result == 'YES') {
          res = true;
        } else {
          res = false;
        }
      } else {
        res = false;
      }
    });
    await confirmationDialog.afterClosed().toPromise();
    return res;
  }

}
