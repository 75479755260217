import type { BulkAssignToSurveyDto, BulkDeleteRespondentDto, ExcelUploadRequestDto, ExcelUploadValidationDto, GetRespondentsDto, RespondentDto, RespondentListPagedDto, UpdateRespondentWithCriteriaRequestDto } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { RespondentPoolDto, RespondentPoolsPagedRequestDto } from '../../core/respondent-pools/models';

@Injectable({
  providedIn: 'root',
})
export class RespondentService {
  apiName = 'Default';

  bulkAssignToSurvey = (surveyKey: string, request: BulkAssignToSurveyDto) =>
    this.restService.request<any, RespondentDto[]>({
      method: 'POST',
      url: `/api/wellbeing/respondents/bulk-assign-to-survey/${surveyKey}`,
      body: request,
    },
    { apiName: this.apiName });

  bulkDelete = (request: BulkDeleteRespondentDto) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/wellbeing/respondents/bulk-delete',
      params: { emails: request.emails, surveyKey: request.surveyKey, isDeleteAll: request.isDeleteAll },
    },
    { apiName: this.apiName });

  create = (request: RespondentDto) =>
    this.restService.request<any, RespondentDto>({
      method: 'POST',
      url: '/api/wellbeing/respondents',
      body: request,
    },
    { apiName: this.apiName });

  delete = (email: string, surveyKey: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/wellbeing/respondents',
      params: { email, surveyKey },
    },
    { apiName: this.apiName });

  excelUpload = (request: ExcelUploadRequestDto) =>
    this.restService.request<any, RespondentDto[]>({
      method: 'POST',
      url: '/api/wellbeing/respondents/excel-upload',
      body: request,
    },
    { apiName: this.apiName });

  excelUploadValidation = (request: ExcelUploadRequestDto) =>
    this.restService.request<any, ExcelUploadValidationDto>({
      method: 'POST',
      url: '/api/wellbeing/respondents/excel-upload-validation',
      body: request,
    },
    { apiName: this.apiName });

  getCompanyRespondentsList = (input: RespondentPoolsPagedRequestDto) =>
    this.restService.request<any, PagedResultDto<RespondentPoolDto>>({
      method: 'POST',
      url: '/api/wellbeing/respondents/company-respondents',
      body: input,
    },
    { apiName: this.apiName });

  getList = (request: GetRespondentsDto) =>
    this.restService.request<any, RespondentListPagedDto>({
      method: 'POST',
      url: '/api/wellbeing/respondents/all',
      body: request,
    },
    { apiName: this.apiName });

  getUnitsToDeduct = (surveyKey: string, request: BulkAssignToSurveyDto) =>
    this.restService.request<any, number>({
      method: 'POST',
      url: `/api/wellbeing/respondents/get-units-to-deduct/${surveyKey}`,
      body: request,
    },
    { apiName: this.apiName });

  updateRespondentWithCriteriaByRequest = (request: UpdateRespondentWithCriteriaRequestDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/wellbeing/respondents/update-respondent-with-criteria',
      body: request,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
