<ai-comments [tourKey]="'wellbeing-closed-3'" [isDimension]="true"></ai-comments>

<!-- <div class="flex flex-row justify-end items-center pb-2" *ngIf="dimensions.length">
  <i (click)="downloadComments()" class="fa-regular fa-download" zenButton styles="ghost icon-11"
    [title]="'txt_download_comments' | translate:language:ModuleNames.Wellbeing"></i>
</div>
<zen-accordions *ngIf="dimensions.length;else empty">
  <ng-container *ngFor="let dimension of  dimensions; let s = index">
    <zen-accordion #accordion [active]="s == 0" extraClasses="rounded mt-2">
      <ng-container head>
        <div class="flex flex-row justify-between items-center px-5 cursor-pointer py-2" (click)="logData(dimension)">
          <h4 class="text-zen text-lg font-medium uppercase ">{{extractNameLanguage(dimension.name)}}</h4>
          <div class="flex flex-row justify-end items-center gap-2">
            <div class="flex flex-row gap-1 px-3 rounded-md border-zen border-opacity-10 border h-12">
              <h5 class="text-zen font-medium uppercase leading-12">
                {{ getSectionCount(dimension?.key) }}</h5>
              <span class="leading-12">
                {{'txtComments2'| translate:language:ModuleNames.Onboarding }}
              </span>
            </div>
            <div class="btn cancel leading-12 w-12 rounded-md relative h-12">
              <span class="absolute h-0 border-b border-zen w-6 right-3 left-3 top-2/4"></span>
              <span class="absolute h-0 border-b border-zen w-6 right-3 left-3 top-2/4 transform transition ease-expo"
                [ngClass]="{'rotate-90': !accordion.active}"></span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container body>
        <div class="innerListScroll max-h-screen-60 px-5 mb-5">
          <div [@listAnimation]="dimension.questions?.length"
            class="flex flex-col py-8 px-5 bg-zen bg-opacity-5 w-full rounded gap-5 ">
            <ng-container *ngFor="let question of dimension.questions">
              <ng-container *ngIf="getQuestionCount(question.key)">
                <div class="flex flex-col gap-2">
                  <h4 class="text-zen font-medium">
                    {{extractNameLanguage(question.name) }}
                  </h4>
                  <wb-comment-list [filters]="filters" [questionKey]="question.key" [surveyKey]="filters.survey.key"
                    [companyKey]="filters.companyKey" [tenantKey]="filters.tenantKey"
                    [fetch]="getQuestionCount(question.key) != 0" [language]="language">
                  </wb-comment-list>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </zen-accordion>
  </ng-container>
</zen-accordions>
<ng-template #empty>
  <EmptySection></EmptySection>
</ng-template> -->