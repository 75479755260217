import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Entity } from 'src/app/core/data-models/Entity';
import { biDirectionalState } from '../../animations/allAnimations';
import { ModuleName } from '../../enums/ModuleName';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { Form, NgForm } from '@angular/forms';
import { CriteriaType } from '../../enums/CriteriaType.enum';

@Component({
  selector: 'q-metrics',
  templateUrl: './q-metrics.component.html',
  styleUrls: ['./q-metrics.component.scss'],
  animations: [biDirectionalState]
})
export class QMetricsComponent extends BaseComponent implements OnInit {
  @Input() title;
  @Input() colorTheme;
  @Input() predefinedMetricsDone;
  @Input() resetDropdown;
  @Input() language;
  @Input() companyKey;
  @Input() entities;
  @Input()
  set metricsList(value: any) {
    this.metrics = value;
    this.selectedCompany = this.entities?.find(s => s.key == this.companyKey);
  }
  ModuleNames = ModuleName
  get metricsList(): any {
    return this.metrics;
  }
  @Output() proceed = new EventEmitter();
  metrics;
  submitted: boolean = false;
  proceeded: boolean = false;
  step: number = 0;
  childStep: number = 0;
  today = new Date();
  searchValue;
  tempList;
  maxDateOfBirth: Date = new Date();
  constructor() {
    super();
  }
  ngOnInit(): void {
    this.maxDateOfBirth.setFullYear(this.today.getFullYear() - 18);
  }
  selectedCompany: Entity;

  searchList(e, array) {
    this.tempList = array.filter(s => s.name.find(n => n.name.toLowerCase().indexOf(e.toLowerCase()) > -1));
  }
  resolveTempList(metric) {
    if (!this.tempList || !this.tempList?.length)
      this.tempList = metric.options;

    return metric.options.length > 6 ? true : false;
  }

  logData(e) {
    console.log(e);
  }

  multiUseTimer;
  dropDownChanged(event, metric) {
    console.log(event);

    metric.value = event;
    if (event == '') {
      this.resetDropdown = true;
      this.multiUseTimer = setTimeout(() => {
        this.resetDropdown = false;
      }, 50);
    }
  }

  formateExistingDate(date, metric, f?) {
    let newDate = new Date(date)
    if (date) {
      let formattedDate: string;
      if (f)
        formattedDate = (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' + newDate.getFullYear();
      if (!f)
        formattedDate = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();

      metric.value = formattedDate;
    }



    if (!date)
      return ""
  }

  addAsteriskToLabel(label: string): string {
    return `${label} *`;
  }

  setReminderDate(e, metric) {
    if (e instanceof Date) {
      let d = new Date(e);
      metric.value = new Date(d).toISOString();
    }
    else metric.value = null;
  }

  sharedParentEntities;
  updateComplexMetric(option, metric, index) {
    if (!this.selectedCompany)
      this.selectedCompany = this.entities?.find(s => s.key == this.companyKey);

    metric.value = option?.key;
    if (metric.isEntity === true) this.entityGroupChanged(option?.key, metric.key)
    let escape = false;
    for (let index = 0; index < this.metrics.length; index++) {
      if (this.metrics[index + 1].options.length && !escape) {
        this.step++;
      }
      else escape = true
    }
    this.step == this.metrics.length ? this.proceed.emit() : ''
    this.tempList = [];
  }

  checkIfSkippable(metric) {
    let currentEntitySelectionKey = this.selectedCompany?.EntityGroups.find(s => s?.groupKey == metric.key)?.parentKey;
    this.sharedParentEntities = this.selectedCompany?.EntityGroups.filter(s => s.parentKey == currentEntitySelectionKey && this.metrics.find(m => m.key == s.groupKey));
    console.log(this.sharedParentEntities);

    if (this.sharedParentEntities?.length < 2)
      return false;
    else {

      let entityFound = this.sharedParentEntities?.find(e => e.groupKey == metric.key);

      if (entityFound && (this.sharedParentEntities.indexOf(entityFound) != this.sharedParentEntities.length || this.sharedParentEntities.find(s => s.value != ""))) {
        console.log('true');

        return true;
      }
      else if (this.sharedParentEntities?.indexOf(entityFound) == this.sharedParentEntities?.length && this.sharedParentEntities?.find(s => s.value == "")) {
        console.log('false');

        return false;
      }
      else return false;
    }
  }



  eraseValuesForEntityGroups(groupKey) {
    let selectedEntity = this.entities.find(e => e.key == this.companyKey);
    let entityGroups = selectedEntity?.EntityGroups;
    let reportingEntityGroups = entityGroups.filter(eg => eg.parentKey == groupKey);
    for (let i = 0; i < reportingEntityGroups.length; i++) {
      let eG = reportingEntityGroups[i];
      let eM = this.metrics.find(m => m.key == eG.groupKey);
      if (eM) {
        eM.value = "";
        this.entityGroupChanged('', eG.groupKey);
      }
    }
  }
  doNothing() {

  }
  entityGroupChanged(event, groupKey) {
    let selectedEntity = this.entities?.find(e => e.key == this.companyKey);
    let entityGroups = selectedEntity?.EntityGroups;
    let reportingEntityGroups = entityGroups.filter(eg => eg.parentKey == groupKey);
    this.eraseValuesForEntityGroups(groupKey);
    for (let x = 0; x < reportingEntityGroups.length; x++) {

      let eG = reportingEntityGroups[x];
      let eM = this.metrics.find(m => m.key == eG.groupKey);

      if (eM) {
        eM.options = [];
        let entitiesInEntityGroup = this.entities.filter(e => e.entityGroupKey == eG.groupKey && e.parentKey == event);
        // console.log(this.entities.filter(e => e.entityGroupKey == eG.groupKey));

        if (entitiesInEntityGroup.length > 0) {
          for (let i = 0; i < entitiesInEntityGroup.length; i++) {
            let entity = entitiesInEntityGroup[i];

            let parentEntityName: any[] = [];
            if (entity.parentKey) {
              let parentObject = this.entities.find(e => e.key == entity.parentKey);
              if (parentObject)
                parentEntityName = parentObject.name;
            }
            let option = { key: entity.key, name: entity.name, parentEntityName: parentEntityName };
            eM.options.push(option);
          }
          eM.required = true;
        } else {
          eM.required = false;
        }

      }
    }
  }

  updateCharacterCount(metric: any)
   {
    console.log("metric", metric)
   }



  submit(form: NgForm) {
    this.submitted = true;
    if (!form.valid)
      return this.SnackbarService.error(this.localize('txtFillAllCriteriaFields', this.language, this.ModuleNames.Surveys));
    this.metrics = this.metrics.map(x => {
      if (!x.type)
        x.type = CriteriaType.DropDown;
      return x;
    })
    this.proceed.emit();
  }
}
