<z-modal [open]="open" [autoWidth]="true">
  <ng-container header>
    <div class="flex flex-row w-full gap-2 items-center justify-between">
      <div class="flex flex-row w-full gap-2 items-center">
        <i class="fal fa-th text-zen-neutral font-light text-xl text-center"></i>
        <h4 class="text-2xl font-medium text-zen">Template Library</h4>
      </div>

      <div class="flex">
        <div (click)="close()" class="btn flex w-10 h-10 justify-center items-center border bg-zen-littlegray">
          <div class="flex h-4 items-center gap-3">
            <div class="flex w-5 h-5 justify-center items-center">
              <i
                class="w-4 flex-shrink-0 text-zen-primarylight text-center font-normal text-base fa-regular fa-times"></i>
            </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
  <ng-container body>

    <div class="flex flex-col w-ful gap-6 items-start self-stretch ">


      <div class="flex flex-row w-97">
        <z-select [color]="'exp'" class="placeholder:fixed w-full"
          [label]="'Select Template'| translate:language:ModuleNames.SurveyX" [options]="templatesList"
          [placeholder]="'Template'| translate:language:ModuleNames.SurveyX" [language]="language"
          [(ngModel)]="selectedTemplate" (ngModelChange)="onTemplateChanged($event)">

          <ng-template #valueTemplate let-value>

            {{value?.name | translate:language:ModuleNames.SurveyX}}

          </ng-template>

          <ng-template #optionTemplate let-option>

            {{option?.name | translate:language:ModuleNames.SurveyX }}

          </ng-template>

        </z-select>
      </div>

      <div *ngIf="selectedTemplateBodySanitized" class="flex flex-col items-start gap-2 self-stretch w-screen max-w-4xl">
        <div class="flex flex-col items-start justify-center gap-1 self-stretch">
          <h5 class="self-stretch text-zen-primarylight font-normal text-xs">Template Preview</h5>

          <div class="flex flex-col py-2 px-2.5 items-start gap-2 self-stretch custom-style">
            <div class="flex items-start gap-2 self-stretch">
              <div class="flex items-start gap-1.5 flex-1">
                <div [innerHTML]="selectedTemplateBodySanitized"
                  class="flex-1 text-zen-primarydefault font-normal text-sm">

                </div>
              </div>
            </div>
          </div>


        </div>
      </div>


    </div>



  </ng-container>
  <ng-container footer>
    <a (click)="close()" class="btn cancel  text-center  rounded-md px-8 py-4  text-sm font-medium">Cancel</a>
    <a (click)="onSelectTemplate()" class="btn exp rounded-md px-8 py-4  text-sm font-medium">Select Template</a>
  </ng-container>
</z-modal>
