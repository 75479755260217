<div class="zen-main-container flex flex-row items-stretch">
  <div *ngIf="data?.selectedCriterias.length"
    class="flex flex-col min-w-80 w-80 border-r border-zen-light border-opacity-20 zen-main-container bg-zen-background  pt-8 gap-5 relative">
    <div class="fixed top-0 h-screen w-inherit min-w-inherit px-10 flex flex-col gap-5 max-h-screen innerListScroll">
      <div class="flex flex-col items-stretch bg-zen-background pt-28 pb-4   top-0">
        <a [@first] (click)="location.back()"
          class="btn cancel rounded leading-12 text-sm text-zen font-medium px-10 text-center flex flex-row justify-between items-center"><i
            class="fas" [ngClass]="(language | language )?'fa-chevron-right':'fa-chevron-left'"></i>
          <span>{{'txtBack'| translate:language:ModuleNames.SurveyX}}</span>
          <span></span>
        </a>
      </div>
      <h6 [@child3] class="text-xxs font-medium text-zen  uppercase mt-5">{{'txtRespondentDetails'|
        translate:language:ModuleNames.SurveyX}}</h6>
      <div class="flex flex-col gap-5 pb-10">
        <div class="flex flex-col px-2.5 overflow-x-hidden">
          <div class="flex flex-col" *ngIf="criterias?.length" (click)="logData(criterias)">
            <div class="pb-3" *ngFor="let criteria of criterias">
              <h6 class="text-xs text-zen">{{criteria.name | extract:language}}</h6>
              <h6 class="text-sm font-medium text-zen">{{criteria.value}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col items-center w-full border-opacity-20 zen-main-container gap-5 ">
    <div class="flex flex-col items-stretch h-28 z-10 w-full" sticky>
      <section-head>
        <ng-container full>
          <div class="flex flex-row justify-end w-2/12">
            <a [@first] (click)="location.back()" [hidden]="criterias?.length"
              class="btn cancel rounded gap-2 leading-12 text-sm text-zen font-medium px-10 text-center flex flex-row justify-between items-center"><i
                class="fas" [ngClass]="(language | language )?'fa-chevron-right':'fa-chevron-left'"></i>
              <span>{{'txtBack'| translate:language:ModuleNames.SurveyX}}</span>
              <span></span>
            </a>
          </div>
          <div class="flex flex-col items-center gap-1 max-w-screen-md w-full relative">
            <h2 class="text-2xl font-semibold text-zen"> {{data?.respondentName != 'N/A'? data?.respondentName:
              data?.respondentEmail}}</h2>
            <h2 class="text-xxs font-medium text-zen"> {{"txtRespondent Answers" |
              translate:language:ModuleNames.SurveyX}}
            </h2>
            <div class="customShadowBottom absolute -bottom-8 h-8 -left-10 -right-10"></div>
          </div>
          <div class="flex flex-row justify-end w-2/12">
            <a class="btn leading-12 rounded text-center text-sm cancel flex flex-row items-center gap-2 px-5"
              (click)="export()">
              <i class="text-sm text-zen fal fa-download"></i>
              <span>{{'btnExport'| translate:language:ModuleNames.SurveyX}}</span>
            </a>
          </div>
        </ng-container>
      </section-head>

    </div>


    <div class="flex flex-col items-stretch w-screen max-w-4xl px-10" #printArea>
      <div *ngFor="let section of sections; let s = index" class="flex flex-col pt-12 items-stretch">
        <div class=" flex flex-col  items-stretch">
          <div class="flex flex-row text-zen-primarydefault">
            <div class="text-lg-custom font-normal tracking-tight"> {{"txtSection" |
              translate:language:ModuleNames.SurveyX}} {{s+1}} : <span class="font-semibold">{{section.name|
                sjsTranslate:language}}</span>
            </div>
          </div>

          <div class="pt-6 break-before-avoid w-full">
            <div class="flex flex-col justify-center bg-white custom-border w-full">


              <!-- -----------------------------------------Questions------------------------------------------------------------------------------------------------- -->


              <div *ngFor="let question of filterQuestionOfSection(section.key); let q = index;"
                class="w-full single_Qs">
                <div class="flex flex-row text-sm-custom text-zen-primarydefault font-medium gap-2">
                  <div class="flex flex-col w-max">
                    {{"Q" | translate:language:ModuleNames.SurveyX}}{{getPreviousSectionCount(section.key - 1 ,
                    sections)
                    + q + 1}}
                  </div>
                  <div class="flex flex-col w-max" (click)="logData(question)">
                    {{question.name | sjsTranslate : language}}
                  </div>
                </div>

                <!-- -----------------------------------------Choices-------------------------------------------------------------------------------------------------- -->

                <div class="flex flex-col text-sm-custom text-zen-primarydefault pt-4 mb-51">


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------DROP DOWN (SINGLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'dropdown'">
                    <mat-radio-group aria-label="Select an option" class="custom-radio-group">
                      <mat-radio-button *ngFor="let c of question.choices"
                        [checked]="isValueChecked(question.key, c.key)"
                        [disabled]="!isValueChecked(question.key, c.key)" [value]="c.key">
                        {{c.value}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------DROP DOWN (MULTIPLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'tagbox'">
                    <mat-checkbox-group aria-label="Select an option" class="custom-checkbox-group">
                      <div class="grid grid-cols-1 gap-4">
                        <mat-checkbox *ngFor="let c of question.choices"
                          [checked]="!isChoiceDisabled(question.key, c.key)" [disabled]="true" [value]="c.key"
                          class="no-margin">{{c.value}}</mat-checkbox>
                      </div>
                    </mat-checkbox-group>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MULTIPLE CHOICE (MULTIPLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'checkbox'">
                    <mat-checkbox-group aria-label="Select an option" class="custom-checkbox-group">
                      <div class="grid grid-cols-1 gap-4">
                        <mat-checkbox *ngFor="let c of question.choices"
                          [checked]="!isChoiceDisabled(question.key, c.key)" [disabled]="true" [value]="c.key"
                          class="no-margin"><span class="whitespace-pre-line">{{c.value}}</span></mat-checkbox>
                      </div>
                    </mat-checkbox-group>
                  </div>

                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MULTIPLE CHOICE (SINGLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'radiogroup'">
                    <mat-radio-group aria-label="Select an option" class="custom-radio-group">
                      <mat-radio-button *ngFor="let c of question.choices"
                        [checked]="!isChoiceDisabled(question.key, c.key)" [disabled]="true" [value]="c.key"><span
                          class="whitespace-pre-line">{{c.value}}</span></mat-radio-button>
                    </mat-radio-group>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------UNKNOWN------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'Single Selection (2 in a row)'">
                    <mat-radio-group aria-label="Select an option" class="custom-radio-group flex space-x-4">
                      <mat-radio-button *ngFor="let c of question.choices"
                        [checked]="isChoiceDisabled(question.key, c.key)" [disabled]="true" [value]="c.key"
                        class="flex-1 flex-wrap"><span class="whitespace-pre-line">{{c.value}}</span></mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------TEXT INPUT------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'datepicker'">
                    <z-datepicker [language]="language"  [disabled]="true" [ngModel]="getTextAreaVal(question.key)"></z-datepicker>
                    <!-- {{getTextAreaVal(question.key)}} -->
                  </div>
                  <div *ngIf="question.type == 'text'">
                    <ng-container *ngIf="question.dateType != 'range'">
                      <div class="w-full flex flex-row">
                        <h5
                          class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20 break-words  w-full rounded-lg px-4 py-2">
                          {{getTextAreaVal(question.key)}}</h5>
                      </div>
                    </ng-container>



                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------DATE RANGE------------------------------------------------------------------------------------------------------------ -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <ng-container *ngIf="question.dateType == 'range'">
                      <div class="flex space-x-4">

                        <div class="w-full flex flex-row">
                          <div class="relative">
                            <i
                              class="fa-regular fa-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-zen-primarydefault text-base"></i>
                          </div>
                          <input type="text" class="text-zen-primarydefault w-full rounded-lg pl-10 py-2 1"
                            [value]="getDate(question.key,'index1')" [disabled]="true">
                        </div>

                        <div class="w-full flex flex-row">
                          <div class="relative">
                            <i
                              class="fa-regular fa-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-zen-primarydefault text-base"></i>
                          </div>
                          <input type="text" class="text-zen-primarydefault w-full rounded-lg pl-10 py-2 1"
                            [value]="getDate(question.key,'index2')" [disabled]="true">
                        </div>
                      </div>

                    </ng-container>



                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------UNKNOWN------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <ng-container *ngIf="question.name == 'single line input Date - time'">
                      <div class="w-full flex flex-row">
                        <div class="relative">
                          <i
                            class="fa-regular fa-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-zen-primarydefault text-base"></i>
                        </div>

                        <h5
                          class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20 break-words  w-full rounded-lg px-4 py-2">
                          {{getTextAreaVal(question.key) |date:'medium'}}</h5>

                      </div>
                    </ng-container>



                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------PHONE NUMBER------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <ng-container *ngIf="question.name == 'Phone number'">
                      <div class="w-full flex flex-row">
                        <input type="text" class="text-zen-primarydefault w-full rounded-lg px-4 py-2 text-sm"
                          [value]="getTextAreaVal(question.key)" [disabled]="true">
                      </div>
                    </ng-container>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------RANKING------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'ranking'">
                    <div class="w-full flex flex-col">
                      <div class="rank-item" *ngFor="let c of getSortedChoices(question); let i = index"
                        [ngClass]="!isChoiceDisabled(question?.key, c?.key) ? 'rank-active' : ''">
                        <span
                          class="whl26 inline-block bg-white font-medium text-zen-primarylight text-sm text-center rounded-full mr-2 align-middle">
                          {{i+1}}
                        </span>
                        <span class="whitespace-pre-line">{{c?.value}}</span>
                      </div>

                    </div>
                  </div>




                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------RATING------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'rating'">
                    <div class="w-full flex flex-row">
                      <div class="flex space-x-4 w-full">


                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------STARS RATING------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container *ngIf="question.rateType === 'stars'">
                          <div
                            class="rate_box flex flex-row flex-wrap w-full rounded-md px-4 min-h-15 justify-center items-center gap-2">
                            <div *ngFor="let rateValue of question.rateValues; let i = index"
                              class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                              [ngClass]="setActiveRate(rateValue, question.key, question)">

                              <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                                [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-star text-3xl text-exp' :
                 'fa-light fa-star text-3xl text-zen-neutral'"></i>
                            </div>

                            <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                              <span
                                class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                            </div>
                          </div>
                        </ng-container>


                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------HEARTS RAITNG------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container *ngIf="question.rateType === 'fa-heart'">
                          <div
                            class="rate_box flex flex-row w-full rounded-md px-4 h-14 justify-center items-center gap-2">
                            <div *ngFor="let rateValue of question.rateValues; let i = index"
                              class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                              [ngClass]="setActiveRate(rateValue, question.key, question)">

                              <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                                [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-heart text-3xl text-exp' :
                'fa-light fa-heart text-3xl text-zen-neutral'"></i>
                            </div>

                            <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                              <span
                                class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                            </div>
                          </div>
                        </ng-container>




                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------THUMBS UP RATING------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container *ngIf="question.rateType === 'fa-thumbs-up'">
                          <div
                            class="rate_box flex flex-row w-full rounded-md px-4 h-14 justify-center items-center gap-2">
                            <div *ngFor="let rateValue of question.rateValues; let i = index"
                              class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                              [ngClass]="setActiveRate(rateValue, question.key, question)">

                              <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                                [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-thumbs-up text-3xl text-exp' :
     'fa-light fa-thumbs-up text-3xl text-zen-neutral'"></i>
                            </div>

                            <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                              <span
                                class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                            </div>
                          </div>
                        </ng-container>




                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------SMILEYS RATING------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container *ngIf="question.rateType === 'smileys'">
                          <div
                            class="rate_box flex flex-row w-full rounded-md px-4 h-14 justify-center items-center gap-2">
                            <div *ngFor="let rateValue of question.rateValues; let i = index"
                              class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                              [ngClass]="setActiveRate(rateValue, question.key, question)">

                              <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                                [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-face-smile text-3xl text-exp' :
               'fa-light fa-face-smile text-3xl text-zen-neutral'"></i>
                            </div>

                            <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                              <span
                                class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                            </div>
                          </div>
                        </ng-container>


                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------NPS AND ENPS RATING------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container *ngIf="question.rateType == 'enps' || question.rateType == 'nps'">
                          <div class="rate_box enps_clr_scale flex flex-col w-full rounded-md px-2 py-2 items-center">
                            <div class="flex flex-row w-full">
                              <div class="gap-2  grid-cols-11 flex flex-row max-w-[600px] m-auto all_colrs">
                                <div class="flex flex-col items-center"
                                  *ngFor="let groupedValues of groupedSection(question.rateValues)">
                                  <div class="flex flex-row gap-2">
                                    <div
                                      [style.backgroundColor]="rateValue.value == isNumber(returnQuestionAnswer(question.key))?groupedValues.color: (groupedValues.color + '22')"
                                      *ngFor="let rateValue of groupedValues.rates; let i = index"
                                      [ngClass]="rateValue.value == isNumber(returnQuestionAnswer(question.key))?'text-white':'text-zen-primarydefault'"
                                      class="flex flex-1 flex-row flex-wrap rounded-md justify-center items-center px-2 py-2 font-medium text-xs ratinglabel bg-zen-littlegray w-12 h-12">
                                      {{ rateValue.value }}
                                    </div>
                                  </div>
                                  <span
                                    class="text-zen-primarydefault font-normal text-xs text-center">{{groupedValues.name
                                    | translate:language:ModuleNames.SurveyX}}</span>
                                </div>
                              </div>
                            </div>

                          </div>
                        </ng-container>


                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------RATING SCALE WITH LABELS AND COLORS------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container
                          *ngIf="question.type == 'rating' && !question.rateType && question.rateType == null && question.ratingLabels "
                          [ngClass]="filterLabels(question)">
                          <div class="w-full flex justify-center padding-custom rounded gap-1">
                            <div class="py-2 gap-2 px-4 flex">
                              <div *ngFor="let group of filteredGroups; let i = index"
                                class="flex flex-col rounded gap-2 self-stretch items-center ">
                                <div class="flex justify-center items-center gap-2 rounded"
                                  [id]="'firstDiv_' + group.groupText">
                                  <div class="flex flex-row items-start gap-2">
                                    <div *ngFor="let rateValue of group.items; let i = index"
                                      class="flex flex-row rounded justify-center items-center w-12 h-12"
                                      [ngClass]="setActiveRate(rateValue, question.key, question)" [ngStyle]="{'background-color': isNumber(rateValue.value) == isNumber(activeRating)  ? '#0082C6' :
                          getColor(rateValue.value, question)? getColor(rateValue.value, question):'#EFF1F6'}">
                                      <h5
                                        [ngClass]="isNumber(rateValue.value) == isNumber(activeRating) ? 'text-white' : 'text-zen-primarylight'"
                                        class="text-sm text-center">
                                        {{ rateValue.value }}
                                      </h5>
                                    </div>

                                  </div>
                                </div>
                                <div class="flex flex-row gap-1 justify-center" [id]="'secondDiv_' + group.groupText">
                                  <h5 class="label-gray whitespace-normal">{{group.groupText}}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------RATING SCALE WITHOUT LABELS OR COLORS------------------------------------------------------------------------------------------------------------- -->
                        <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <ng-container
                          *ngIf="question.type == 'rating' && !question.rateType && question.rateType == null && !question.ratingLabels ">
                          <div class="flex flex-col w-full rounded padding-custom gap-2 self-stretch items-center ">
                            <div class="py-2 gap-2 px-4 flex">
                              <div class="flex justify-center items-center gap-2 rounded">
                                <div class="flex flex-row items-start gap-2">
                                  <div *ngFor="let rateValue of question.rateValues; let i = index"
                                    class="flex flex-row rounded justify-center items-center w-12 h-12"
                                    [ngClass]="setActiveRate(rateValue, question.key, question)"
                                    [ngClass]="isNumber(rateValue.value) == isNumber(activeRating)  ? 'bg-exp' : 'bg-zen-littlegray'"
                                    [ngStyle]="{'background-color':getColor(rateValue.value, question) }">
                                    <h5
                                      [ngClass]="isNumber(rateValue.value) == isNumber(activeRating) ? 'text-white' : 'text-zen-primarylight'"
                                      class="text-sm text-center">
                                      {{ rateValue.value }}
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              <div *ngFor="let rateLabel of question.ratingLabels; let i = index"
                                class="flex flex-row gap-1">
                                <h5 class="label-gray whitespace-normal"> {{rateLabel.text}}</h5>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                      </div>
                    </div>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------BOOLEAN------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'boolean'">
                    <mat-radio-group aria-label="Select an option" class="custom-radio-group flex space-x-4">
                      <mat-radio-button class="gap-1 items-center" *ngFor="let c of question.choices"
                        [checked]="isValueChecked(question.key, c.key)"
                        [disabled]="!isValueChecked(question.key, c.key)" [value]="c.key">
                        <i *ngIf="c.key == 'True'" class="fa-regular fa-thumbs-up"></i>
                        <i *ngIf="c.key == 'False'" class="fa-regular fa-thumbs-down"></i>
                        {{c.value}}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>



                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------FILE UPLOAD------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'file'">
                    <div class="w-full flex flex-row">
                      <div class="rate_box flex flex-row w-full rounded-md px-2 py-2 items-center">
                        <div class="flex-1">
                          <i class="fa-regular fa-image text-base mr-2 ml-2"></i>
                          {{getFileDetails(question.key)?.name}}
                        </div>

                        <div class="flex-auto justify-end flex pr-2">
                          <button (click)="downloadImage(getFileDetails(question.key)?.content, 'image.png')"><i
                              class="fa-regular fa-download text-[#7D92B5]"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MULTIPLE TEXT------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'multipletext'">
                    <div class="w-full flex flex-row space-x-4">
                      <h5 *ngFor="let c of question.items"
                        class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20 break-words  w-full rounded-lg px-4 py-2">
                        {{getValue(question.key, c.name)}}</h5>
                    </div>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------LONG TEXT------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'comment'">
                    <div class="w-full flex flex-row">
                      <h5
                        class="text-zen-primarydefault bg-zen bg-opacity-5 border border-zen border-opacity-20 break-words  w-full rounded-lg px-4 py-2 min-h-20">
                        {{getTextAreaVal(question.key)}}</h5>
                    </div>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------IMAGE PICKER (ICON CHOICE)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type === 'imagepicker'">
                    <ng-container *ngIf="question.isIconChoice">
                      <div class="w-full flex flex-row">
                        <div class="flex space-x-4 w-full">
                          <div *ngFor="let choice of question.choices; let i = index"
                            class="flex flex-1 flex-col flex-wrap justify-center items-center text-xs font-normal emogy-box"
                            [ngClass]="setActiveIcon(choice, question.key, question.multiSelect) ? 'active-emgy' : ''">
                            <div
                              class="flex flex-1 justify-center items-center mb-2 w-full rounded-md px-2 py-2 bg-white emj-box-inside">
                              <i class="fa-light fa-{{choice.value}} text-3xl"></i>
                            </div>
                            {{choice.key}}
                          </div>
                        </div>
                      </div>
                    </ng-container>


                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------IMAGE PICKER(IMAGE CHOICE)------------------------------------------------------------------------------------------------------------- -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->

                    <ng-container *ngIf="!question.isIconChoice && !question.multiSelect">
                      <div class="w-full flex flex-row">
                        <div class="grid grid-cols-2 gap-4 w-full">
                          <div *ngFor="let choice of question.choices; let i = index"
                            class="flex flex-1 flex-col flex-wrap rounded-md justify-center items-start text-zen-primarydefault font-medium text-xs bg-white imgChoose-box"
                            [ngClass]="setActiveImage(choice.key, question.key) ? 'active-ImgSel' : ''">

                            <div class="flex items-center text-sm text-zen-primarylight font-normal innerT">
                              <img [src]="choice.imageLink" class="rounded-md mr-4 w-36 p-[0.1rem]">
                              <i
                                [class]="setActiveImage(choice.key, question.key) ? 'fa-regular fa-circle-dot text-2xl mr-2 text-zen-paleWhite' : 'fa-light fa-circle text-2xl mr-2 text-zen-paleWhite'"></i>
                              {{ choice.key }}
                            </div>
                          </div>

                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!question.isIconChoice && question.multiSelect">
                      <div class="w-full flex flex-row">
                        <div class="grid grid-cols-2 gap-4 w-full">
                          <div *ngFor="let choice of question.choices; let i = index"
                            class="flex flex-1 flex-col flex-wrap rounded-md justify-center items-start text-zen-primarydefault font-medium text-xs bg-white cursor-pointer imgChoose-box"
                            [ngClass]="setActiveImages(choice.key, question.key) ? 'active-ImgSel' : ''">

                            <div class="flex items-center text-sm text-zen-primarylight font-normal innerT">
                              <img [src]="choice.imageLink" class="rounded-md mr-4 w-36 p-[0.1rem]">
                              <i
                                [class]="setActiveImages(choice.key, question.key) ? 'fa-solid fa-square-check text-2xl mr-2 text-zen-paleWhite' : 'fa-light fa-square text-2xl mr-2 text-zen-paleWhite'"></i>
                              {{ choice.key }}
                            </div>
                          </div>

                        </div>
                      </div>
                    </ng-container>

                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MATRIX (SINGLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'matrix' && !question.isMultiSelect">
                    <div class="w-full flex flex-row">
                      <table class="bordered w-full table-matrix">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4 text-center"
                              *ngFor="let column of question.columns">
                              {{ column.text | sjsTranslate:language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of question.rows">
                            <td class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4">{{ row.text |
                              sjsTranslate:language}}
                            </td>
                            <td class="text-center px-2 py-4" *ngFor="let column of question.columns">
                              <i
                                [ngClass]="validateAns(row.key,question.key, column.key)? 'fa-regular fa-circle-dot' : 'fa-regular fa-circle'"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------MATRIX (MULTIPLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <div *ngIf="question.type == 'matrix' && question.isMultiSelect">
                    <div class="w-full flex flex-row">
                      <table class="bordered w-full table-matrix">
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4 text-center"
                              *ngFor="let column of question.columns">
                              {{ column.text | sjsTranslate:language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let row of question.rows">
                            <td class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4">{{ row.text |
                              sjsTranslate:language}}
                            </td>
                            <td class="text-center px-2 py-4" *ngFor="let column of question.columns">
                              <i class="fa-regular fa-square"
                                [ngClass]="validateAns(row.key,question.key, column.key)"></i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>




                  <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
                </div>
              </div>


              <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->


            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</div>


<respondent-answers-report *ngIf="openReport" [language]="language" [(open)]="openReport" [questions]="questions"
  [answers]="answers" [sections]="sections" [headerTitle]="'txtRespondent Answers'" [essentialObject]="essentialObject"
  [respondentName]="data.respondentName">
</respondent-answers-report>
