<form id="currentForm" #currentForm="ngForm" (keyup)="updateForm()" class="flex flex-col items-stretch gap-10 w-full h-full">
  <div class="flex flex-row rounded overflow-hidden bg-white items-stretch h-full">
    <div class="flex flex-col w-90 pb-7">
      <div class="flex flex-row items-center justify-start gap-1.5 px-7 text-zen-lite text-xs h-20">
        <i class="fa-regular fa-info-circle text-zen-ghost"></i>
        <span>{{ "txt_editor_Selectupto_factor" | translate:language:ModuleNames.Exit }}</span>
      </div>
      <div class="flex flex-col items-stretch gap-3">
        <zen-accordions>
          <ng-container *ngFor="let sectionContainer of sectionsSetup; let s = index">
            <span *ngIf="s != 0" class="h-0 border-t border-zen border-opacity-20 w-full block"></span>
            <zen-accordion #accordion [TourStep]="!tourKey?undefined:((template.isPredefined || template.isLocked) && sectionContainer.tourOrder)?{title: sectionContainer.name | translate:language:ModuleNames.Exit ,text: sectionContainer.description | translate:language:ModuleNames.Exit,order: sectionContainer.tourOrder,tourKey:tourKey,on:(language | language )?'left':'right',triggerId: sectionContainer.name}:
            {title: sectionContainer.name | translate:language:ModuleNames.Exit ,text: sectionContainer.description | translate:language:ModuleNames.Exit,order: sectionContainer.tourOrder,tourKey:tourKey,on:(language | language )?'left':'right',triggerId: sectionContainer.name}">
              <ng-container head>
                <div class="flex flex-row justify-between items-center leading-15 h-15  w-full btn px-7" [id]="sectionContainer.name">
                  <span class="uppercase text-exp text-2.5xs font-medium">{{sectionContainer.name
                    | translate:language:ModuleNames.Exit}}</span>
                  <div class="flex flex-row items-center justify-end gap-2.5">
                    <span zenButton styles="icon-7" class="text-exp bg-exp bg-opacity-10">{{returnSelectedFactors(sectionContainer.sections) ||
                      0}}</span>
                    <i [ngClass]="{'rotate-90': !accordion.active &&( language | language ), '-rotate-90': !accordion.active && !( language | language ), 'cancel':accordion.active}" zenButton styles="icon-7" class="fa-regular fa-angle-down text-zen-blueGrey transform"></i>
                  </div>
                </div>
              </ng-container>
              <ng-container body>
                <div >
                  <div class="flex flex-col items-stretch px-7" *ngIf="sectionContainer.isCustomFactors && !template.isPredefined && !template.isLocked">
                    <a *DemoAccount="sectionContainer.sections.length?'Button':'Default'; let isDemo=isDemo" (click)="isDemo && sectionContainer.sections.length?'':addNewFactor()" zenButton styles="height-9" class="border border-zen border-opacity-20 text-xs justify-center text-zen-lite my-3">
                      <i class="fa-regular fa-plus"></i>
                      {{ "txt_editor_NewCustom_Factor" | translate:language:ModuleNames.Exit }}
                    </a>
                  </div>

                  <ng-container *ngFor="let section of sectionContainer.sections; let n = index">
                    <ng-container *ngTemplateOutlet="sectionInputTemplate; context:{$implicit:section, isSelectable:sectionContainer.isSelectable}">
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </zen-accordion>

          </ng-container>
        </zen-accordions>
      </div>
    </div>
    <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
    <div class="flex flex-col w-full items-stretch">
      <div class="flex flex-col w-full px-7 pb-7" *DemoAccount="showDemoSection(currentSelectedSection); let isDemo=isDemo">
        <div class="flex flex-col w-full px-7 maxh65 innerListScroll">
          <div class="flex flex-row items-center justify-between h-20 sticky top-0 bg-white -mr-7 -ml-7 px-7 z-20">
            <div class="flex flex-row items-center gap-2">
              <h4 class=" text-zen-lite text-xs truncate max-w-xs leading-20"> <span *ngIf="currentSelectedSection.isFactor">{{'txt_editor_statements_of'|
                  translate:language:ModuleNames.Exit}}</span>
                <span [matTooltip]="extractNameLanguage(currentSelectedSection?.name)"><span *ngIf="currentSelectedSection.isFactor">(</span>
                  {{extractNameLanguage(currentSelectedSection?.name)}}<span *ngIf="currentSelectedSection.isFactor">)</span></span>
              </h4>
              <product-tour *ngIf="currentSelectedSection.isFactor && !currentSelectedSection.isPredefined && !template.isLocked" [key]="'exit-extraFactor'" [offset]="112" [subTour]="true"></product-tour>
            </div>
            <div class="flex flex-row items-center justify-end gap-5 h-20" *ngIf="!template.isPredefined && !template.isLocked">
              <ng-container *ngIf="!currentSelectedSection?.isPredefined || currentSelectedSection.isAllowNewQuestions">
                <div *ngIf="!currentSelectedSection.isAllowNewQuestions" class="flex flex-row items-center justify-start gap-1.5  text-zen-lite text-xs h-20">
                  <i class="fa-regular fa-info-circle text-zen-ghost"></i>
                  <span>{{ "txt_Addupto4s_862" | translate:language:ModuleNames.Exit }}</span>
                </div>
                <a *ngIf="(currentSelectedSection.questions.length < 3 || currentSelectedSection.isAllowNewQuestions) && (!isDemo || !currentSelectedSection.isPredefined)" (click)="addNewQuestion(false, isDemo)" zenButton styles="height-9" class="border border-zen border-opacity-20 text-xs justify-center px-4 text-zen-lite my-3" [TourStep]="!tourKey?undefined:(!template.isPredefined && !currentSelectedSection.isFactor)?
                 {title: 'txt_editor_NewQuestion'| translate:language:ModuleNames.Exit ,text: 'txt_Tostartad_702' | translate:language:ModuleNames.Exit,order: 2,tourKey:tourKey,on:(language|language)?'right':'left'}:
                 {title: 'txt_editor_newstatement' | translate:language:ModuleNames.Exit,text: 'txt_ClickonnewStatement_917' | translate:language:ModuleNames.Exit,order: 2,tourKey:'exit-extraFactor',on:(language | language )?'right':'left'}">
                  <i class="fa-regular fa-plus"></i>
                  {{ (currentSelectedSection.isFactor? "txt_editor_newstatement":'txt_editor_NewQuestion' )|
                  translate:language:ModuleNames.Exit }}
                </a>
              </ng-container>
              <i (click)="sampleQuestion = true" zenButton styles="secondary icon-9" class="fa-regular fa-eye" matTooltip="{{ 'txt_editor_sample_question' | translate:language:ModuleNames.Exit }}"></i>

            </div>

          </div>
          <div class="flex flex-col items-stretch " >
            <ng-container *ngFor="let question of currentSelectedSection?.questions;let i = index">
              <ng-container *ngTemplateOutlet="questionTemplate; context:{$implicit:question, factor:currentSelectedSection, index:i}">
              </ng-container>
            </ng-container>

            <!-- ai-generation button -->
            <div class="flex flex-col items-center gap-10 py-10" *ngIf="currentSelectedSection.isFactor && !currentSelectedSection.isPredefined && !template.isLocked" [TourStep]="{title: 'txt_GenerateS_856' | translate:language:ModuleNames.Exit,text: 'txt_Useouradv_732' | translate:language:ModuleNames.Exit,order: 3,tourKey:'exit-extraFactor'}">
              <!-- <div class="flex flex-col items-center gap-10 py-10"> -->
              <a zenButton *ngIf="currentSelectedSection?.questions?.length" [AiStatementGeneration]="{component:this, module: ModuleNames.Exit, section:currentSelectedSection, addFunction:'addNewQuestion', maxRecords:3, property:'questions'}" styles="secondary height-10"> <img class="object-fill" src="/assets/img/svg/ZenAi-icon.svg" /> {{
                "txtRegenerateWithAI" | translate:language:ModuleNames.Shared }}</a>
              <ng-container *ngIf="!currentSelectedSection?.questions?.length">

                <img zenButton class="h-20 object-cover opacity-50 pointer-events-none" src="/assets/img/svg/ZenAi-icon.svg" />
                <a zenButton [AiStatementGeneration]="{component:this, module: ModuleNames.Exit, section:currentSelectedSection, addFunction:'addNewQuestion', maxRecords:3}" styles="secondary height-10">{{ "txtGenerateByAi" | translate:language:ModuleNames.Shared }}</a>
              </ng-container>
            </div>

          </div>
          <div class="flex flex-row items-center justify-end bg-white h-15 gap-5 sticky -bottom-px -mr-7 -ml-7 px-7" [TourStep]="(tourKey && (template.isPredefined || template.isLocked)) ?{title: 'txt_leaver_seperation_type'| translate:language:ModuleNames.Exit ,text: 'txt_Separatio_105' | translate:language:ModuleNames.Exit,order: 5,tourKey:tourKey}:undefined">
            <h5 class="text-xs text-zen font-normal leading-15">{{'txtLegend' | translate:language:ModuleNames.Exit}}:</h5>
            <div class="flex flex-row items-center justify-end gap-1.5">
              <div class="flex flex-row items-center gap-1 text-xs h-6 px font-normal rounded-sm px-2 bg-opacity-5" [ngClass]="legend.bgColor + ' ' + legend.color" *ngFor="let legend of seperationLegend">
                <i class="fa-solid fa-circle text-3xs  bg-opacity-5"></i>
                <span>{{legend.key == 'all'? ('txtAll'| translate:language:ModuleNames.Exit): legend.name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>
<ng-template #sectionInputTemplate let-section let-isSelectable="isSelectable">
  <z-edit-container #sectionContainer EditContainer [auto]="!template.isPredefined || !template.isLocked">
    <ng-template #editTemplate>
      <z-translation-input [focus]="true" [classList]="isValidName(section.name)? 'bg-transparent w-full h-15 flex flex-row justify-between items-center leading-9 truncate px-7':'bg-zen-red bg-opacity-10 w-full flex flex-row justify-between items-center leading-9 h-15 truncate px-7 text-zen-red'" [maxCount]="avgCount" [required]="true" [language]="language" [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="section.name" id="section" name="section"></z-translation-input>

    </ng-template>
    <ng-template #viewTemplate>
      <a (click)="sectionClicked(section, $event)" [ngClass]="{'bg-zen-lightGrey': section?.key == currentSelectedSection?.key && isValidSection(section),'text-zen-red bg-zen-red bg-opacity-5': section?.key == currentSelectedSection?.key && !isValidSection(section), 'text-zen-red border-b-2 border-zen-red': !isValidSection(section)}" class="btn flex flex-row justify-between items-center gap-2 h-15 px-7 text-sm hover:bg-zen hover:bg-opacity-10">
        <span class="flex flex-row gap-2 items-center">
          <i *ngIf="isSelectable" (click)="selectionClick(section)" [ngClass]="section.isSelected?'fa-solid fa-square-check text-exp':'fa-regular fa-square text-zen'" class="text-lg"></i>
          <span [matTooltip]="section.name | extractNameLanguage:language">{{ section.name |
            extractNameLanguage:language }}</span>
        </span>
        <i [ZMenuTriggerFor]="menu" *ngIf="!section.isPredefined && !template.isLocked" class="fa-regular fa-ellipsis-v w-8 h-8 leading-8 text-opacity-60 text-base btn text-center btn" [TourStep]="{title: 'txt_EditCusto_448' | translate:language:ModuleNames.Exit,text: 'txt_EditCusto_desc' | translate:language:ModuleNames.Exit,order: 1,tourKey:'exit-extraFactor',on:(language | language )?'left':'right'}"></i>
      </a>
      <z-menu #menu>
        <a MenuItem (click)="$event.stopPropagation();sectionContainer.active = true">
          <i class="fal fa-pen"></i>{{'btn_edit' | translate:language:ModuleNames.Exit}}
        </a>
        <a MenuItem delete (click)="$event.stopPropagation(); removeSection(section)">
          <i class="fal fa-trash"></i>{{'btn_delete' | translate:language:ModuleNames.Exit}}
        </a>
      </z-menu>
    </ng-template>
  </z-edit-container>
</ng-template>

<ng-template #questionTemplate let-question let-factor="factor" let-index="index">
  <z-edit-container #container EditContainer [autoClose]="!currentSelectedSection.isAllowNewQuestions" [auto]="!question.isPredefined && !template.isLocked" [styles]="currentSelectedSection.isAllowNewQuestions?'injected':'basic'">
    <ng-template #editTemplate>
      <template-new-questions [questionName]="question.name" (cancel)="container.active = false" (save)="container.active = false; updateQuestion($event)" [selectedEntity]="selectedEntity" [question]="question" [language]="language" *ngIf="currentSelectedSection.isAllowNewQuestions; else defaultQuestion">
        <ng-container *ngTemplateOutlet="questionEditInput; context:{$implicit:question}"></ng-container>
      </template-new-questions>
      <ng-template #defaultQuestion>
        <ng-container *ngTemplateOutlet="questionEditInput; context:{$implicit:question}"></ng-container>
      </ng-template>

    </ng-template>
    <ng-template #viewTemplate>
      <div [ngClass]="{'text-zen-red':!isValidQuestion(question, factor),'text-zen':isValidQuestion(question, factor)}" class="flex flex-row items-center justify-between h-15 text-sm  cursor-text">
        <div class="flex flex-row gap-2.5">
          <span class="font-medium">Q{{ (index + 1) }}</span>
          <h5>{{ extractNameLanguage(question.name) }}</h5>
        </div>
        <div class="flex flex-row gap-3 items-center justify-end" (click)="$event.stopPropagation()">
          <div class="flex flex-row items-center gap-1">
            <ng-container *ngFor="let legend of seperationLegend">
              <i [matTooltip]="legend.key == 'all'? ('txtAll'| translate:language:ModuleNames.Exit): legend.name" *ngIf="question.seperationType == legend.key || (!question.seperationType && legend.key == 'all')" class="fa-solid fa-circle text-3xs bg-opacity-5" [ngClass]="legend.bgColor + ' ' + legend.color" zenButton styles="icon-6"></i>
            </ng-container>
            <div *ngIf="question.questionType" class="flex flex-row items-center gap-1 text-xs h-6 px font-normal rounded-sm px-2 bg-opacity-5 text-zen bg-zen" [TourStep]="(tourKey && (template.isPredefined || template.isLocked))?{title: 'txt_QuestionT_164'| translate:language:ModuleNames.Exit ,text: 'txt_Eachquest_776' | translate:language:ModuleNames.Exit,order: 4,tourKey:tourKey,isRows:true,on:(language | language ) ?'right':'left'}:undefined">
              <i [ngClass]="returnTypeIcon(question.questionType)" class="fa-regular text-xs"></i>
              <span class="whitespace-nowrap">{{'preview_'+question.questionType | translate:language:ModuleNames.Exit
                }}</span>
            </div>

          </div>
          <!-- <i *ngIf="!currentSelectedSection?.isPredefined || (factor?.isAllowNewQuestions && !question.isPredefined)"
            matTooltip="{{'txt_DeleteQue_569' | translate:language:ModuleNames.Exit}}"
            (click)="removeQuestion(question, factor)" zenButton styles="delete icon-6" class="fa-regular fa-trash"></i> -->
          <ng-container *ngIf="isQuestionEditable(currentSelectedSection, factor, question) ">
            <a zenButton *ngIf="factor.isFactor" [AiStatementGeneration]="{component:this, module: ModuleNames.Exit, section:currentSelectedSection, statement:question}" [matTooltip]="'txtRegenerateWithAI'|translate:language:ModuleNames.Shared" styles="icon-9" class="transition-all ease-expo">
              <img class="object-fill" src="/assets/img/svg/ZenAi-icon.svg" />
            </a>

            <i [ZMenuTriggerFor]="menu" class="fa-regular fa-ellipsis-v w-8 h-8 leading-8 text-opacity-60 text-base btn text-center btn"></i>
            <z-menu #menu>
              <a MenuItem delete (click)="removeQuestion(question, factor)">
                <i class="fal fa-trash"></i>{{ (currentSelectedSection.isFactor?
                'txt_DeleteQue_569':'txt_editor_delete_question' ) | translate:language:ModuleNames.Exit}}
              </a>
            </z-menu>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </z-edit-container>
</ng-template>
<ng-template #questionEditInput let-question>
  <div class="flex flex-col items-stretch justify-center h-15 text-zen text-sm">
    <z-translation-input [classList]="isValidName(question.name)? 'bg-transparent w-full flex flex-row justify-between items-center leading-9 truncate px-7':'bg-zen-red bg-opacity-10 w-full flex flex-row justify-between items-center leading-9 h-15 truncate px-7 text-zen-red'" [maxCount]="mdCount" [required]="true" [language]="language" [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="question.name">
    </z-translation-input>
  </div>
</ng-template>

<z-modal [(open)]="sampleQuestion" [autoWidth]="true">
  <ng-container header>
    <div class="flex flex-row gap-2 justify-start items-center">
      <h4 class="text-2xl font-bold text-zen">{{"txtExtraQuestionPreview"|
        translate:language:ModuleNames.Shared }}
      </h4>
      <!-- <p class="text-sm text-zen">Please reupload the data file after fixing the validation errors.</p> -->
    </div>
    <i (click)="sampleQuestion = false" zenButton styles="icon-10" class="fa-regular fa-times text-zen-lite"></i>
  </ng-container>

  <ng-container body>
    <div class="flex flex-col items-stretch gap-7">
      <h5 class="text-zen-lite text-sm">{{"txt_preview_sample_desc"| translate:language:ModuleNames.Shared}}
      </h5>
      <div class="flex flex-col items-stretch gap-3 bg-white bg-opacity-40 rounded-md -mb-10">
        <h4 class="text-zen font-medium text-xs text-opacity-80 text-center">
          {{'txt_Sample_Question'|translate:language:ModuleNames.Shared }}
        </h4>
        <h4 class="text-zen font-thin text-xs text-center">
          {{'txtExtraQuestion'|translate:language:ModuleNames.Shared}} </h4>
        <div class="flex flex-col items-stretch bg-zen bg-opacity-5 border-zen-blueGrey rounded-md gap-9 px-6 py-8">
          <div class="flex flex-row w-full gap-1 items-stretch">

            <div class="flex flex-row  w-full gap-1  items-stretch">
              <div class="flex flex-col items-center w-1/6 gap-2.5">
                <i class="fa-light fa-ban text-2xl rounded  text-zen bg-white border-2 border-transparent text-opacity-70 py-3 px-4"></i>
                <h5 class=" text-opacity-80 text-3xs font-medium  text-center text-zen-lite"> {{
                  getConfidence('single-answer-rating', '0')}}</h5>
              </div>
              <div class="flex flex-col items-center w-1/6 gap-2.5">
                <i class="fa-light fa-face-pouting text-2xl rounded  text-zen bg-white border-2 border-transparent text-opacity-70 py-3 px-4"></i>
                <h5 class=" text-opacity-80 text-3xs font-medium  text-center text-zen-lite"> {{
                  getConfidence('single-answer-rating', '1')}}</h5>
              </div>
              <div class="flex flex-col items-center w-1/6 gap-2.5">
                <i class="fa-light fa-face-worried text-2xl rounded  text-zen bg-white border-2 border-transparent text-opacity-70 py-3 px-4"></i>
                <h5 class=" text-opacity-80 text-3xs font-medium  text-center text-zen-lite"> {{
                  getConfidence('single-answer-rating', '2')}}</h5>
              </div>
              <div class="flex flex-col items-center w-1/6 gap-2.5">
                <i class="fa-light fa-face-thinking text-2xl rounded  text-zen bg-white border-2 border-transparent text-opacity-70 py-3 px-4"></i>
                <h5 class=" text-opacity-80 text-3xs font-medium  text-center text-zen-lite"> {{
                  getConfidence('single-answer-rating', '3')}}</h5>
              </div>
              <div class="flex flex-col items-center w-1/6 gap-2.5">
                <i class="fa-light fa-face-smile-relaxed text-2xl rounded  text-zen bg-white border-2 border-transparent text-opacity-70 py-3 px-4"></i>
                <h5 class=" text-opacity-80 text-3xs font-medium  text-center text-zen-lite"> {{
                  getConfidence('single-answer-rating', '4')}}</h5>
              </div>
              <div class="flex flex-col items-center w-1/6 gap-2.5">
                <i class="fa-light fa-face-laugh-beam text-2xl rounded  text-exp bg-white border-2 border-exp text-opacity-70 py-3 px-4"></i>
                <h5 class=" text-opacity-80 text-3xs font-medium  text-center text-zen-lite"> {{
                  getConfidence('single-answer-rating', '5')}}</h5>
              </div>

            </div>
          </div>

          <div class="flex flex-col mt-2 w-full items-stretch gap-1">
            <h5 class="text-zen text-xxs">
              {{'txtHavemorecomments'| translate:language:ModuleNames.Shared}}
            </h5>
            <div class=" bg-white duration-300 ease-expo rounded border border-zen border-opacity-10 px-5 py-1 overflow-hidden h-14 text-opacity-80 text-zen text-xxs">
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <a (click)="sampleQuestion = false" class="btn cancel text-center align-middle w-full rounded leading-10 uppercase text-xs font-medium">{{"btn_close"|
      translate:language:ModuleNames.Shared }}</a>

  </ng-container>
</z-modal>
