import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SjsTranslatePipe } from '@ex/module/survey-x/utils/pipes/sjs-translate.pipe';
import { SurveyPreviewComponent } from './features/survey-preview/survey-preview.component';
import { SurveyModule } from 'survey-angular-ui';
import { SurveyClosedComponent } from './features/survey-closed/survey-closed.component';
import { SurveyFinishPageComponent } from './features/survey-finish-page/survey-finish-page.component';
import { SurveyStartPageComponent } from './features/survey-start-page/survey-start-page.component';
import { ConfirmSubmissionModalComponent } from './ui/confirm-submission-modal/confirm-submission-modal.component';
import { SurveyActionsbarComponent } from './ui/survey-actionsbar/survey-actionsbar.component';
import { SurveyNavbarComponent } from './ui/survey-navbar/survey-navbar.component';
import { SharedModule } from "../../shared/shared.module";
import "survey-core/survey.i18n";


@NgModule({
    declarations: [
        SjsTranslatePipe,
        SurveyPreviewComponent,
        SurveyClosedComponent,
        SurveyFinishPageComponent,
        SurveyStartPageComponent,
        SurveyActionsbarComponent,
        SurveyNavbarComponent,
        ConfirmSubmissionModalComponent,
    ],
    exports: [
        SjsTranslatePipe,
        SurveyPreviewComponent,
        SurveyClosedComponent,
        SurveyFinishPageComponent,
        SurveyStartPageComponent,
        SurveyActionsbarComponent,
        SurveyNavbarComponent,
        ConfirmSubmissionModalComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    imports: [
        CommonModule,
        SharedModule,
        SurveyModule,
    ],
    providers: []
})
export class SurveyXSharedModule { }
