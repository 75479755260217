import { Injectable } from '@angular/core';
import { LoggingRecord } from '../data-models/LoggingRecord';

@Injectable({
  providedIn: 'root'
})
export class LogService {


  trackingIndex: number = 0; // Tracks the queue of logging


  // Create Console Log with Correct Format
  submitLog(type: number, header: string, status: string, payload: any) {

    let loggingRecord = new LoggingRecord();
    loggingRecord.class = type;
    loggingRecord.header = header;
    loggingRecord.status = status;
    loggingRecord.payload = '';

    loggingRecord.payload = payload;

    this.trackingIndex++;
    let consoleMessage: string = '%c ';
    consoleMessage = consoleMessage + '(' + this.trackingIndex + ') ' + this.getTime() + ' - ' + loggingRecord.header + ' [' + loggingRecord.status + ']';
    console.log(consoleMessage + '\n', this.getClassHex(loggingRecord.class), loggingRecord.payload);
  }

  // Test Log temporary events
  print(value: any) {
    let consoleMessage: string = '%c ';
    consoleMessage = consoleMessage + this.getTime();
    console.log(consoleMessage + ' (PRINT) => \n', this.getClassHex(1), value);
  }

  // Helper Function: Returns the log message background color
  getClassHex(classIndex: number): string {
    let style: string = 'color: ';
    if (classIndex == 0) // Normal
      style = style + '#00bfff';
    else if (classIndex == 1) // Warning
      style = style + '#ffff00';
    else if (classIndex == 2) // Non-Critical Error
      style = style + '#ffbf00';
    else if (classIndex == 3) // Critical Error
      style = style + '#ff0000';
    else // Critical Error
      style = style + '#BCED91';
    return style;
  }

  // Returns the current time as a string
  getTime(): string {
    let currentTime = new Date();
    let dayNight: string;
    if (currentTime.getHours() >= 12)
      dayNight = 'PM';
    else
      dayNight = 'AM';
    return ((currentTime.getHours() + 24) % 12 || 12) + ':' + currentTime.getMinutes() + ':' + currentTime.getSeconds() + '.' + currentTime.getMilliseconds() + ' ' + dayNight;
  }


}
