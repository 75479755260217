import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar,MatSnackBarConfig  } from '@angular/material/snack-bar';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';
import {Clipboard} from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { Direction } from '@angular/cdk/bidi';
import { LocalService } from '@ex/module/core/services/local.service';



@Component({
  selector: 'app-share-form-by-link',
  templateUrl: './share-form-by-link.component.html',
  styleUrls: ['./share-form-by-link.component.scss']
})
export class ShareFormByLinkComponent extends ModalComponent implements OnInit {
  language = 'EN';
  ModuleNames = ModuleName;

  link : string = "";
  emailURL = environment.emailURL;



  @Input() open: boolean = true;
  @Input() selectedKey: any;
  @Output() openChange = new EventEmitter();

  constructor(public snackBar: MatSnackBar, private clipboard : Clipboard,private localService:LocalService, private translationPipe: TranslatePipe, ) {super(); }

  direction : Direction = "ltr"
  textPosition : any = "left";
  ngOnInit(): void {

    this.language = this.localService.getJsonValue('language')
    this.textPosition = "left"
    if (this.language == "AR")
    {
      this.direction = "rtl"
      this.textPosition = "right"
    }
    else
    {
      this.direction = "ltr"
      this.textPosition = "left"
    }


    this.link = this.emailURL + '/survey-x/language/' + this.selectedKey;

  }

  copyLink(){
    this.clipboard.copy(this.link);
  }


  openSnackBar() {

    const config = new MatSnackBarConfig();
    config.duration = 4000
    if(this.language == 'AR')
    {
      config.direction ='rtl';
    }
    else
    {
      config.direction ="ltr";
    }

    this.snackBar.open(this.translationPipe.transform("txtLink Copied!", this.language, this.ModuleNames.SurveyX),
    this.translationPipe.transform("txtNow You can paste it and send it", this.language, this.ModuleNames.SurveyX),
     config);
    this.closeModal('open')
    this.openChange.emit(false);
  }
}
