import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExcelUploadService, ExcelValidationResponseDto } from '@ex/exit/excel-uploader';
import { ExcelConfigService } from '@ex/module/exit/data-access/services/excel-config.service';
import { ExitBaseComponent } from '../../exit-base/exit-base.component';
import { CDKPortalComponent } from 'src/app/shared/Theme/services/zen-cdk.service';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { of } from 'rxjs';
import { EmailTemplateDto } from '@ex/core/common';
import { biDirectionalState300, listAnimation } from 'src/app/shared/animations/allAnimations';
import { EmailTemplateService } from '@ex/module/exit/data-access/services/email-template.service';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { CreditBalanceDialogComponent } from 'src/app/shared/credit-balance-dialog/credit-balance-dialog.component';
import { products } from 'src/app/core/data-models/company-permission';
import { MatDialog } from '@angular/material/dialog';
import { ExitUnitDeductionDto, ExitUnitService } from '@ex/module/exit/data-access/services/exit-unit.service';
import { EssentialObject } from 'src/app/core/services/essential-object.service';

@Component({
  selector: 'leaver-excel',
  templateUrl: './excel.component.html',
  styleUrls: ['./excel.component.scss'],
  animations: [listAnimation, biDirectionalState300]

})
export class ExcelComponent extends ExitBaseComponent implements OnInit, CDKPortalComponent {
  constructor(private excelConfigService: ExcelConfigService
    , private excelUploadService: ExcelUploadService
    , private emailTemplateService: EmailTemplateService
    , private responseDialogService: ResponseDialogService
    , private matDialog: MatDialog
    , private exitUnitService: ExitUnitService) {
    super()

    this.EssentialObject$.pipe(
      filter(x => x?.selectedEntity?.key != null),
      distinctUntilChanged((a, b) => a == b, c => c.selectedEntity.key)
    ).subscribe(obj => {
      this.essentialObject = obj;
    })

  }
  ngOnInit(): void {
    this.navButtons = [
      { name: this.localize('txt_TemplateDownload', this.language, this.ModuleNames.Exit) },
      { name: this.localize('txt_PopulateTemplate', this.language, this.ModuleNames.Exit) },
      { name: this.localize('txt_TemplateUpload', this.language, this.ModuleNames.Exit) },
      { name: this.localize("txt_invitationEmail", this.language, this.ModuleNames.Exit) }
    ];
    // this.downloadExcelTemplate();

    this.invitationEmailTemplate = this.emailTemplateService.getInvitationEmail();
    this.reminderEmailTemplate = this.emailTemplateService.getReminderEmail();
  }

  @Input() tenantKey;
  @Input() surveyKey;
  @Input() companyKey;
  @Input() language;
  @Input() selectedEntity;
  @Input() step;
  @Output() stepChange = new EventEmitter()
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();
  @Output() bulkUpdate = new EventEmitter();
  @Output() reset = new EventEmitter();
  entityHierarchy = [];
  navButtons: { name: any; }[];
  filename: any = "leaver.xlsx";
  submitted;
  excelUploadJob: ExcelValidationResponseDto;
  validationErrorMessages: string[] = [];
  invitation: boolean = true;
  essentialObject: EssentialObject;

  fileBase64;
  columnConfigs;

  invitationEmailTemplate: EmailTemplateDto;
  reminderEmailTemplate: EmailTemplateDto;

  downloadExcelSheet() {
    const subscription = this.excelConfigService.generateConfigAsync(this.tenantKey, this.companyKey)
      .pipe(
        tap(_ => this.LoaderService.hideLoader()),
        switchMap(config => {

          return this.excelUploadService.getExcelSheetDownloadWithConfig(
            this.tenantKey, this.companyKey,
            {
              columns: config,
              entityHierarchy: this.entityHierarchy
            }
          );

        })
      )
      .subscribe(response => {
        this.LoaderService.hideLoader();
        this.saveAsXlsxFile(response.excelFileBase64);
      });

    this.addSubscription(subscription);
  }

  saveAsXlsxFile(base64) {

    var mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var userInp = base64;
    FileSaver.saveAs(mediaType + userInp, this.filename);

  }

  async fileUploaded(file) {
    this.validationErrorMessages = [];
    this.excelUploadJob = null;
    const uploadRequest = this.excelConfigService.generateConfigAsync(this.selectedEntity.tenantKey, this.selectedEntity.key)
      .pipe(
        switchMap((c) => {

          this.fileBase64 = file.base64.split(",")[1];
          this.columnConfigs = c;

          return this.excelUploadService.validateExcelSheet({
            excelFile: this.fileBase64,
            companyKey: this.selectedEntity.key,
            tenantKey: this.selectedEntity.tenantKey,
            surveyKey: this.surveyKey,
            options: {
              columns: this.columnConfigs,
              fileName: this.filename,
              entityHierarchy: this.entityHierarchy
            }
          })

        }),
        catchError(err => {
          const error = err.error.error;

          var messages = error.message.split(",");

          messages.forEach((message: string) => {
            this.validationErrorMessages.push(this.localize(message, this.language, this.ModuleNames.Onboarding) as string)
            this.step--;
            this.step++;
          })
          // TODO: Disable the next button
          // TODO: Inform the user to reupload the excel file with corrections to the data

          throw err;
        })
      ).subscribe(response => {
        this.excelUploadJob = response;
      })

    this.addSubscription(uploadRequest);
  }

  resetAll() {
    this.reset.emit();
    this.step = 0;
  }

  nextStep() {
    if ((!this.excelUploadJob && this.step == 3) || !this.validateTempalte())
      return
    this.step == 4 ? this.submitForm() : this.step++;
    this.validationErrorMessages = [];
  }

  validateTempalte() {
    if ((this.step >= 4) && (!this.validateEmailInvitation() || !this.validateEmailReminder()))
      return false;

    else return true;
  }
  validateEmailInvitation() {
    let isValid: boolean = true;
    if (!this.invitationEmailTemplate.subject || !this.invitationEmailTemplate.body) {
      this.SnackbarService.error(this.localize("txt_fill_fields", this.language, this.ModuleNames.Exit));
      return isValid = false;
    }
    else if (this.invitationEmailTemplate.body.indexOf("[SurveyURL]") < 1) {
      this.SnackbarService.error(this.localize("txtInvitationMissingURL", this.language, this.ModuleNames.Exit));
      return isValid = false;
    }
    if (!this.validateStringLength(this.invitationEmailTemplate.subject, this.GlobalService.mdCount) || !this.validateStringLength(this.invitationEmailTemplate.body, this.GlobalService.hugeCount))
      return false
    else
      return isValid;
  }



  validateEmailReminder() {

    let isValid: boolean = true;
    if (!this.reminderEmailTemplate.subject || !this.reminderEmailTemplate.body) {
      this.SnackbarService.error(this.localize("txt_fill_fields", this.language, this.ModuleNames.Exit));
      return isValid = false;
    }
    if (this.reminderEmailTemplate.body.indexOf("[SurveyURL]") < 1) {
      this.SnackbarService.error(this.localize("txtReminderMissingURL", this.language, this.ModuleNames.Exit));
      return isValid = false;
    }
    if (!this.validateStringLength(this.reminderEmailTemplate.subject, this.GlobalService.mdCount) || !this.validateStringLength(this.reminderEmailTemplate.body, this.GlobalService.hugeCount)) {
      return false
    }
    else
      return isValid;
  }




  async submitForm() {
    if (!this.validateTempalte())
      return;

    let confirmationResult: boolean = true;
    let message = this.localize("txt_unit_deduction", this.language, this.ModuleNames.Onboarding);
    let subject = this.localize("txt_allocation_confirmation", this.language, this.ModuleNames.Onboarding);
    let actionButtonText = this.localize("txt_okay_action", this.language, this.ModuleNames.Onboarding);
    let cancelButtonText = this.localize("btn_cancel", this.language, this.ModuleNames.Onboarding);

    message = message.replace('#UNITS#', this.excelUploadJob.totalRecords);
    confirmationResult = await this.responseDialogService.confirmAllocation(message, subject, actionButtonText, cancelButtonText);

    if (confirmationResult) {
      this.LoaderService.displayLoader(0, true);
      const request: any = {
        noOfRespondees: this.excelUploadJob.totalRecords,
        userKey: this.essentialObject.user.key,
        tenantKey: this.selectedEntity.tenantKey,
        companyKey: this.selectedEntity.key,
      };
      this.addSubscription(this.exitUnitService.deduct(request).pipe(switchMap((response: any) => {
        if (response.response == "ERROR") {
          // TODO: Possible translation required for the response message
          //this.snackbar.warning(this.localize(response?.message, this.language, this.ModuleNames.Onboarding));

          return of(response);
        }

        this.submitted = true;
        return this.excelUploadService.initiateBulkUpload({
          excelFile: this.fileBase64,
          companyKey: this.selectedEntity.key,
          tenantKey: this.selectedEntity.tenantKey,
          surveyKey: this.surveyKey,
          options: {
            columns: this.columnConfigs,
            fileName: "excel.xlsx",
            entityHierarchy: this.entityHierarchy
          },
          invitationEmailTemplate: this.invitationEmailTemplate,
          reminderEmailTemplate: this.reminderEmailTemplate,
        }).pipe(map(res => ({ res })))

      })).subscribe((response: any) => {
        if (response?.response == "ERROR") {
          this.bulkUpdate.emit(false);

          const searchDialogRef = this.matDialog.open(
            CreditBalanceDialogComponent,
            {
              data: {
                tenantKey: this.tenantKey,
                entity: this.selectedEntity,
                creditType: products.Exit,
                language: this.language,
                minUnits: response?.data.requiredCredits,
                currentBalance: response?.data.currentUnits,
                surveyRequires: response?.data.minUnits,
                user: this.essentialObject.user,
                tenant: this.essentialObject.tenant,
              },
              autoFocus: false,
            }
          );


          return;
        }

        this.bulkUpdate.emit(true);

        this.LoaderService.hideLoader();
      }));

    }
  }

  returnBody(no: number) {
    switch (no) {
      case 1:
        this.invitationEmailTemplate = this.emailTemplateService.getInvitationEmail();
        break;
      case 2:
        this.reminderEmailTemplate = this.emailTemplateService.getReminderEmail();
        break;
    }
  }

}
