import type { EntityHierarchyDto } from '../entities/models';
import type { CustomPropertyDto } from '../models';
import type { AuditedEntityDto, PagedResultRequestDto } from '@abp/ng.core';

export interface BulkDeleteRespondentDto {
  companyKey: string;
  tenantKey: string;
  emails: string[];
  isDeleteAll: boolean;
}

export interface DeleteRespondentDto {
  tenantKey: string;
  companyKey: string;
  email: string;
}

export interface ExcelUploadRequestDto {
  tenantKey: string;
  companyKey: string;
  excelFile: string;
}

export interface FilterDto {
  key?: string;
  value: object;
  type?: string;
}

export interface RespondentCreateUpdateDto {
  tenantKey: string;
  companyKey: string;
  employeeId?: string;
  email?: string;
  phone?: string;
  name?: string;
  gender?: string;
  bandLevel?: string;
  jobGrade?: string;
  dateOfJoining?: string;
  dateOfBirth?: string;
  entityHierarchy: EntityHierarchyDto[];
  customProperties: CustomPropertyDto[];
}

export interface RespondentPoolDto extends AuditedEntityDto<number> {
  employeeId?: string;
  email?: string;
  phone?: string;
  name?: string;
  gender?: string;
  bandLevel?: string;
  jobGrade?: string;
  performance?: string;
  generation?: string;
  ageGroup?: string;
  tenantKey?: string;
  companyKey?: string;
  dateOfJoining?: string;
  dateOfBirth?: string;
  tenureGroup?: string;
  entityHierarchy: EntityHierarchyDto[];
  customProperties: CustomPropertyDto[];
}

export interface RespondentPoolsPagedRequestDto extends PagedResultRequestDto {
  tenantKey: string;
  companyKey: string;
  employeeId?: string;
  surveyKey?: string;
  filters: FilterDto[];
  filterText?: string;
  isAllResults?: boolean;
}
