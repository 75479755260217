import { Component, Input, OnInit } from '@angular/core';
import { Question } from 'src/app/core/data-models/question';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ModuleName } from 'src/app/shared/enums/ModuleName';

@Component({
  selector: 'multi-options-multi-answer',
  templateUrl: './multi-options-multi-answer.component.html',
  styleUrls: ['./multi-options-multi-answer.component.scss']
})
export class MultiOptionsMultiAnswerComponent extends BaseComponent implements OnInit {
  lgCount;
  question;
  @Input('question') set q(question) {
    this.question = question;
  };
  @Input() language;
  @Input() single = false;
  maxAnswers: boolean = false;
  constructor(private _globals: GlobalsService, private languageService: LanguageService) {
    super();
    this.lgCount = _globals.lgCount;
  }

  ngOnInit(): void {
  }
  ModuleNames = ModuleName
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  checkOther(q) {
    if (q.answer instanceof Array) {
      let option = q.questionOptions?.find(option => option.otherAnswer == true);
      if (option) {
        let optionKey = option.key;
        if (optionKey)
          return (q.answer.includes(optionKey))
      }
    }
  }
  addQuestionAnswer(question, option) {
    if (!question.answer?.length)
      question.answer = [];

    if (question.answer.includes(option.key)) {
      question.answer.splice(question.answer.indexOf(option.key), 1);
      this.maxAnswers = false;
    }
    else {
      question.answer.push(option.key);
      if (question.max && question.answer.length == question.max)
        this.maxAnswers = true;
    }
    if (!option.checked && question.otherAnswer1)
      question.otherAnswer1 = null;
  }

  countWord(word, count) {
    if (word?.length <= count)
      return word.length;
    if (word?.length > count) {

      return word.length;
    }
  }


  returnAnswerCountText(question: Question) {
    if (question?.min > 1 && question?.max == question.questionOptions.length)
      return this.localize("minQuestionsLength", this.language, this.ModuleNames.Shared).replace('[minCount]', question.min);
    else if (question?.min == 0 && question?.max < question.questionOptions.length)
      return this.localize("maxQuestionsLength", this.language, this.ModuleNames.Shared).replace('[maxCount]', question.max);
    else if (question?.min >= 1 && question?.max <= question.questionOptions.length)
      return this.localize("minMaxQuestionsLength", this.language, this.ModuleNames.Shared).replace('[minCount]', question.min).replace('[maxCount]', question.max);
    else this.localize("'Select all that apply'", this.language, this.ModuleNames.Shared);
  }

}
