import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ChildrenAnimation,
  listAnimation,
  routeInnerAnimations,
} from '../animations/allAnimations';
import { LoaderService } from '../../core/services/loader.service';
import { Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { EssentialObjectService } from 'src/app/core/services/essential-object.service';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { ModuleName } from '../enums/ModuleName';
import { LanguagePipe } from '../pipes/language.pipe';
import { ZenCDKService } from 'src/app/shared/Theme/services/zen-cdk.service';
import { OnboardingTemplatePreviewComponent } from 'src/app/onboarding/components/templates/components/onboarding-template-preview/onboarding-template-preview.component';

@Component({
  selector: 'home-container',
  templateUrl: './home-container.component.html',
  styleUrls: ['./home-container.component.scss'],
  animations: [ChildrenAnimation, routeInnerAnimations, listAnimation],
})
export class HomeContainerComponent implements OnInit, AfterContentChecked {
  constructor(
    public loaderService: LoaderService,
    public global: GlobalsService,
    private languagePipe: LanguagePipe,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public essentialObjectService: EssentialObjectService,
    private portalPridge: ZenCDKService
  ) { }
  @Input() routes;
  subscriptions = new Subscription();
  language;
  activatedLink: any = 0;

  @Input() tourKey = '';
  @Input() index;
  ngOnInit(): void {
    this.subscriptions.add(
      this.essentialObjectService.essentialObject.subscribe((object: any) => {
        this.language = object.language;
      })
    );
    // this.portalPridge.openComponent(OnboardingTemplatePreviewComponent, {
    //   id: 3234
    // });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  timerSet;
  collapse: boolean = true;
  collapseColumn() {
    this.collapse = !this.collapse;
    let timer = 5000;
    clearTimeout(this.timerSet);
    if (!this.collapse) {
      this.timerSet = setTimeout(() => {
        this.collapse = true;
      }, timer);
    }
  }
  logData(e) {
    console.log(e);
  }
  currentIndex: number = 0;
  value;
  resolveRoute(o: RouterOutlet) {
    let path = o.activatedRouteData['animation'];
    if (o.isActivated) {
      if (path > this.currentIndex) {
        this.languagePipe.transform(this.language)
          ? (this.value = 'back')
          : (this.value = 'forward');
      } else if (path < this.currentIndex) {
        this.languagePipe.transform(this.language)
          ? (this.value = 'forward')
          : (this.value = 'back');
      }

      this.currentIndex = path;
      return this.value;
    }
  }

  ngAfterContentChecked() {
    // this.cdr.detectChanges();
  }
  ModuleNames = ModuleName;
}
