import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AnalysisFilterDto, BasicRequest, CommentSentimentDto, CommentsTopicsDto, QuestionCommentPagedRequest, QuestionSentitmentDto, SectionSentimentDto, SentimentTopic, UpdateSentimentDto } from '../../interfaces/ai-models';
import { AiBaseComponent } from '../ai-base.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AI_COMMENTS_SERVICE } from '../main/main.component';
import { IAiCommentsApi } from '../../interfaces/ai-comments-api';
import { filter, map, switchMap } from 'rxjs/operators';
import { SentimentAnalysis, SentimentColors } from '../../enums/sentiment-analysis';
import { TopicAddComponent } from '../topic-add/topic-add.component';
import { FilterKeys } from '../../enums/filterKeys';
import { Entity } from 'src/app/core/data-models/Entity';

@Component({
  selector: 'comments-question',
  templateUrl: './comments-question.component.html',
  styleUrls: ['./comments-question.component.scss'],
})
export class CommentsQuestionComponent extends AiBaseComponent implements OnInit {
  sentimentAnalysis = SentimentAnalysis;
  open: boolean = false;
  @Input('question') set s(question: QuestionSentitmentDto) {
    this.currentQuestion = question;
    this.questionCopy = JSON.parse(JSON.stringify(question));
    this.filterComments(null);
  };
  @Input('currentSentiment') set sent(sentiment) {
    this.parentSentiment = sentiment;
    this.filterComments(sentiment);
  };
  @Input() sectionKey;
  @Input() request: BasicRequest;
  @Input() topics: CommentsTopicsDto[];
  currentCommentSubject: Subject<{ comment: CommentSentimentDto, request?: BasicRequest }> = new Subject();
  questionFilter = new BehaviorSubject([]);
  questionFilter$ = this.questionFilter.asObservable();
  loadNextComments = new BehaviorSubject(1);
  loadNextComments$ = this.loadNextComments.asObservable();

  currentQuestion: QuestionSentitmentDto;
  questionCopy: QuestionSentitmentDto;
  currentSentiment: string = null;
  parentSentiment: string = null;
  sentiments;
  module;
  selectedEntity: Entity;
  comments: CommentSentimentDto[] = [];
  totalComments: number = 10;
  currentPage: number;
  @Output() commentCount = new EventEmitter();
  @Input() language;
  @Output() sectionAiUpdated = new EventEmitter<SectionSentimentDto>()

  constructor(@Inject(AI_COMMENTS_SERVICE) public commentsService: IAiCommentsApi) {
    super();
  }
  ngOnInit(): void {
    this.getComments();
    this.sentiments = this.commentsService.sentiments;
    this.module = this.commentsService.module;
    this.selectedEntity = this.commentsService.selectedEntity;
    this.addSubscription(this.currentCommentSubject.pipe(switchMap(({ comment, request }) => {
      return this.updateComment(comment).pipe(map(x => request));
    })).subscribe(request => {
      if (request) {
        request.isUpdate = true;
        this.commentsService.mainRequest.next(request);
        this.getQstCommentsAIAnalysis(request)
      }
    }));

    // if (this.openedQuestions?.find(x => x == this.currentQuestion.key))
    //   this.open = true;
  }




  filterComments(filter: any = null) {
    this.totalComments = null;
    this.currentSentiment = this.sentimentPipe(filter);
    let filters: any = [{ key: FilterKeys.Question, value: this.currentQuestion.key }, { key: FilterKeys.Section, value: this.sectionKey }, { key: FilterKeys.Sentiment, value: this.currentSentiment }];
    this.questionFilter.next(filters);
  }

  getComments() {
    this.addSubscription(this.commentsService.request$.pipe(filter(x => x?.tenantKey != null)).pipe(switchMap(request => {
      let newRequest = JSON.parse(JSON.stringify(request));
      return this.questionFilter$.pipe(switchMap(filters => {
        filters.map(x => {
          let filter = newRequest.filters.find(f => f.key == x.key);
          if (filter) {
            filter.value = x.value;
          }
          else newRequest.filters.push(x);
        })
        // this.comments = [];
        let questionCommentsRequest: QuestionCommentPagedRequest = {
          surveyKey: newRequest.surveyKey,
          tenantKey: newRequest.tenantKey,
          companyKey: newRequest.companyKey,
          filters: newRequest.filters.filter(filter => filter.value != null && filter.value != ""),
          maxResultCount: 5,
        }
        this.currentPage = 0;
        return this.loadNextComments$.pipe(switchMap(x => {
          this.currentPage = !this.currentPage ? 1 : x;
          questionCommentsRequest.skipCount = this.currentPage;
          return this.commentsService.getPaginatedCommentsList(questionCommentsRequest);
        }))

      }))
    })).subscribe(x => {
      this.comments = this.currentPage > 1 ? this.comments.concat(x.items) : x.items;

      this.totalComments = x.totalCount;
      this.commentCount.emit(this.totalComments);
    }))

  }
  fetchMoreComments() {
    this.loadNextComments.next(this.currentPage + 1);
  }

  // openQuestion() {
  //   if (this.openedQuestions?.find(x => x == this.currentQuestion.key)) {
  //     this.openedQuestions = this.openedQuestions.filter(x => x != this.currentQuestion.key);
  //     this.open = false;
  //   } else {
  //     this.openedQuestions.push(this.currentQuestion.key);
  //     this.open = true;
  //   }
  //   this.openedQuestionsChange.emit(this.openedQuestions);
  // }


  getSentimentName(sentimentKey: string) {
    return this.sentiments.find(x => x.key == sentimentKey.toLowerCase())?.name || null;
  }

  getLegendColor(sentiment: SentimentAnalysis) {
    return SentimentColors[(sentiment.toLowerCase()) as keyof typeof SentimentColors]
  }

  addTopic(comment: CommentSentimentDto) {
    let topics = this.topics.filter(x => !comment.commentsAI.topics.find(t => t == x.key));
    this.addSubscription(this.ZenCDKService.openComponent(TopicAddComponent,
      { contentLanguages: this.selectedEntity.ContentLanguages, language: this.language, module: this.module, comment: comment, topics: topics }).subscribe(comment => {
        this.currentCommentSubject.next({ comment: comment, request: this.request });
      }))
  }

  removeTopic(comment: CommentSentimentDto, topic: SentimentTopic, topicIndex: number) {
    comment.commentsAI.topic.splice(topicIndex, 1);
    comment.commentsAI.topics.splice(comment.commentsAI.topics.indexOf(topic.key), 1);
    this.currentCommentSubject.next({ comment: comment, request: this.request });
  }
  updateSentiment(comment: CommentSentimentDto, sentiment: string) {
    comment.sentiment = this.sentimentPipe(sentiment);
    this.currentCommentSubject.next({ comment: comment, request: this.request });

  }
  updateComment(comment: CommentSentimentDto): Observable<CommentSentimentDto> {
    let request: UpdateSentimentDto = {
      questionKey: comment.commentsAI.questionKey,
      respondentKey: comment.respondentKey,
      sentiment: comment.sentiment,
      topics: comment.commentsAI.topics,
      topic: comment.commentsAI.topic
    }
    return this.commentsService.updateCommentsAIByRequest(request).pipe(map(x => comment))

  }

  returnSentimentName(key: string) {
    return this.sentiments.find(x => x.key == key)?.name;
  }

  trackBy(index, item) {
    return item.respondentKey;
  }

  getQstCommentsAIAnalysis(request) {
    let newRequest: BasicRequest = {
      companyKey: request.companyKey,
      tenantKey: request.tenantKey,
      surveyKey: request.surveyKey,
      filters: request.filters
    }
    this.commentsService.getCommentsAIAnalysis(newRequest).subscribe(sections => {
      let section = sections.find(f => f.key == this.sectionKey);
      this.sectionAiUpdated.emit(section);
      this.currentQuestion = section.questions.find(f => f.key == this.currentQuestion.key);
    })
  }
}



// filters.map(x => {
//   let filter = request.filters.find(f => f.key == x.key);
//   if (filter) {
//     if (filter.value)
//       filter.value = x.value;
//     else {
//       request.filters.splice(request.filters.indexOf(filter), 1);
//       
//     }

//   }
//   else request.filters.push(x);
// })

// let questionCommentsRequest: QuestionCommentPagedRequest = {
//   surveyKey: request.surveyKey,
//   tenantKey: request.tenantKey,
//   companyKey: request.companyKey,
//   filters: request.filters,
//   maxResultCount: 10,
// }
// return this.commentsService.getPaginatedCommentsList(questionCommentsRequest);
