<z-modal [open]="open" [autoWidth]="true">
  <ng-container header>
    <h2 class="text-zen-primarydefault text-lg font-medium flex gap-2 items-center">
      {{'txtTranslations' | translate:language:ModuleNames.Shared}}
    </h2>
    <i (click)="close()"
      class="fa-regular fa-times leading-4 btn cancel text-center text-base text-zen-primarylight p-2 rounded"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-row gap-6">
      <div class="flex flex-col w-51 gap-2 innerListScroll">
        <div ب
          class="flex flex-row w-full h-12 py-2 px-4 items-center rounded gap-2 cursor-pointer bg-zen-neutralPNinety justify-between pointer-events-none">
          <h5 class="text-sm text-zen">{{ getLanguage(defaultLanguage?.key) }}</h5>
          <a class="btn cancel p-1 rounded-sm text-xxs text-zen-primarylight font-medium">{{'txtDefault' | translate:language:ModuleNames.Shared}}</a>
        </div>
        <ng-container *ngFor="let language of _translations">
          <div *ngIf="language.key!=defaultLanguage.key"
            [ngClass]="{'border-2 border-exp':modalSelected?.key==language.key}"
            class="flex flex-row w-full h-12 py-2 px-4 items-center rounded gap-2 cursor-pointer"
            (click)="modalSelected=language">
            <i *ngIf="modalSelected.key == language.key && language.name.length>0"
              class="fa-regular fa-circle-check text-base text-exp"></i>
            <h5 class="text-sm text-zen">{{ getLanguage(language?.key) }}</h5>
          </div>
        </ng-container>
      </div>
      <div class="flex flex-col   bg-white border rounded-lg gap-6 border-zen-neutralPNinety py-4 px-6"
        [ngClass]="editor?'w-122':'w-112'">
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-2 items-center">
            <h5 class="text-xs text-zen-primarylight font-medium tracking-wide uppercase">{{'txtOriginLanguage' |
              translate:language:ModuleNames.Shared}}:</h5>
            <h5 class="text-sm leading-6 text-exp font-medium"> {{ getLanguage(defaultLanguage.key) }}</h5>
          </div>
          <div
            class="flex flex-row justify-between h-28 py-2 px-2.5 border rounded gap-2 border-zen-neutralPNinety bg-zen-solitude innerListScroll">
            <h6 class="text-sm leading-6 text-zen" [innerHTML]="defaultLanguage.name"> </h6>
            <!-- <a class="text-base text-zen-secondaryLight cursor-pointer" [cdkCopyToClipboard]="defaultLanguage.name"><i class="fa-duotone fa-copy"></i></a> -->
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-2 items-center">
            <h5 class="text-xs text-zen-primarylight font-medium tracking-wide uppercase">{{'txtTargetLanguage' |
              translate:language:ModuleNames.Shared}}:</h5>
            <h5 class="text-sm leading-6 text-exp font-medium"> {{ getLanguage(modalSelected?.key) }}</h5>
          </div>
          <div *ngIf="!editor" class="flex flex-row  h-40 py-2 px-2.5 border rounded gap-2 border-zen-neutralPNinety">
            <textarea class="w-full bg-transparent text-sm leading-6 text-zen innerListScroll" rows="3"
              [(ngModel)]="modalSelected.name"></textarea>
            <a *ngIf="defaultLanguage?.name?.length" zenButton (click)="inTranslation?'':aiTranslate(modalSelected.key)"
              [matTooltip]="'Generate with ZENITHR AI'" styles="icon-8" class="transition-all ease-expo"
              [ngClass]="{'pointer-events-none':inTranslation}">
              <img *ngIf="!inTranslation" class="object-fill" src="/assets/img/svg/ZenAi-icon.svg" />
              <lottie-player *ngIf="inTranslation" src="/assets/lottie/circleLoader.json" background="transparent"
                speed="2" style="width: 3rem; height: 3rem;" loop autoplay="false"></lottie-player>
            </a>
          </div>
          <div *ngIf="editor" [class]="classList">
            <angular-editor #field="ngModel" [config]="editorConfig" name="'translationEditor'"
              class=" w-full  ModifiedClass" [(ngModel)]="modalSelected.name">
            </angular-editor>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <div class="flex flex-col items-stretch w-full gap-5">
      <div class="flex w-full flex-row justify-between">
        <button (click)="close()"
          class="btn cancel text-zen-primarylight rounded  text-sm h-10 px-4 font-medium">{{'btn_cancel'|
          translate:language:ModuleNames.Shared}}</button>
        <button (click)="save()" class="btn exp text-sm h-10 px-4 font-medium rounded">
          {{'btn_save_changes'| translate:language:ModuleNames.Shared}} </button>
      </div>
    </div>
  </ng-container>
</z-modal>
