import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'response-dialog',
  templateUrl: './response-dialog.component.html',
  styleUrls: ['./response-dialog.component.scss']
})
export class ResponseDialogComponent implements OnInit {
  subject: string;
  message: string;
  action: string;
  type: string;
  faCheckCircle = faCheckCircle;
  faTimes = faTimes;
  rtl= false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (document.body.dir == 'rtl')
      this.rtl = true;
    this.subject = this.data.subject;
    this.message = this.data.message;
    this.action = this.data.actionButtonText;
    this.type = this.data.dialogType;

  }

}
