import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { SurveyCriteriaDto, SurveyDto } from '@ex/wellbeing/surveys';
import { EngagementMetric } from 'src/app/core/data-models/engagement-metric';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { CriteriaType } from '../../enums/CriteriaType.enum';

@Component({
  selector: 'survey-criteria',
  templateUrl: './survey-criteria.component.html',
  styleUrls: ['./survey-criteria.component.scss'],
  animations: [ChildrenAnimation]
})
export class SurveyCriteriaComponent extends BaseComponent implements OnInit {
  constructor(
    private languageService: LanguageService,
    private db: AngularFireDatabase,
    private _globals: GlobalsService,
    private responseDialogService: ResponseDialogService,
  ) {
    super();
    this.hugeCount = _globals.hugeCount;
    this.lgCount = _globals.lgCount;
    this.mdCount = _globals.mdCount;
    this.smCount = _globals.smCount;

  }
  @ContentChild(TemplateRef) header: TemplateRef<any>;
  criteria: boolean = true;
  invalid: boolean = false;
  criteriaAdd;
  newMetric: SurveyCriteriaDto;
  vMetricName;
  vMetricOptions;
  hugeCount;
  lgCount;
  mdCount;
  smCount;
  edited: boolean;
  types = CriteriaType
  surveyData: SurveyDto | any;
  metricTypes: any[] = Object.values(CriteriaType).map(x => { return { key: x, name: 'txt' + x } })
  @Input('language') language;
  @Input('selectedEntity') selectedEntity;
  @Input('surveyData') set data(surveyData: SurveyDto | any) {
    if (!surveyData?.surveyCriterias?.length)
      return;
    this.surveyData = surveyData
    this.surveyData.surveyCriterias.forEach(element => {
      if (this.surveyData.selectedCriterias?.find(c => c.key == element.key))
        element.isSelected = true;
    });
  }

  @Output() surveyDataChange = new EventEmitter();


  answerIncreaseClick() {
    if (!this.newMetric.options) this.newMetric.options = [];

    let option = { key: this.db.createPushId(), name: [] };
    this.newMetric.options.push(option);
  }
  deleteOption(option) {
    const index = this.newMetric.options.indexOf(option);
    this.newMetric.options.splice(index, 1);
  }
  async deleteCriteria(criteria: SurveyCriteriaDto) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_criteria_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {
        this.surveyData.surveyCriterias.splice(this.surveyData.surveyCriterias.indexOf(criteria), 1)
        this.surveyData.selectedCriterias.splice(this.surveyData.selectedCriterias.indexOf(criteria), 1)
        this.SnackbarService.success(this.localize("txt_removed_success", this.language, this.ModuleNames.Wellbeing));
      }
    }
    catch {

    }
  }

  ngOnInit(): void { }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  criteriaIsSelected(key) {
    if (this.surveyData.selectedCriterias?.find((mt) => mt.key == key)) return true;
    else return false;
  }
  logData(e) {
    console.log(e);

  }
  criteriaAddFunction(criteria?) {
    this.criteriaAdd = true;
    this.invalid = false;
    if (!criteria) {
      this.edited = false;
      this.newMetric = {} as SurveyCriteriaDto;
      this.newMetric.key = this.db.createPushId();
      this.newMetric.isCustom = true;
      this.newMetric.isEntity = false;
      this.newMetric.name = [];
      this.newMetric.required = false;
      this.newMetric.options = [];
      this.newMetric.value = [];
      this.newMetric.type = this.types.DropDown;

      this.answerIncreaseClick();
    } else {
      this.edited = true;
      this.newMetric = criteria;
    }
  }

  fetchCriteriaName(criteriaKey: any) {
    if (!criteriaKey)
      criteriaKey = 'DROPDOWN'
    return this.metricTypes.find(x => x?.key == criteriaKey)?.name;
  }

  countWord(word, count) {
    if (word?.length <= count) return word.length;
    if (word?.length > count) {
      return word.length;
    }
  }
  updateSelectedMetrics(criteria: SurveyCriteriaDto | any) {
    // if (this.surveyData.selectedCriterias.includes(criteria))
    //   this.surveyData.selectedCriterias.splice(this.surveyData.selectedCriterias.indexOf(criteria), 1)
    // else this.surveyData.selectedCriterias.push(criteria);
    // if (criteria.isSelected)

    criteria.isSelected = !criteria.isSelected;
    this.surveyData.selectedCriterias = this.surveyData.surveyCriterias.filter(criteria => criteria.isSelected);
  }

  validateCriteria(engagementMetric: EngagementMetric) {
    let isValid: boolean = true;
    if (
      engagementMetric.name.filter((d) => d.name == '').length ==
      engagementMetric.name.length
    ) {
      this.vMetricName = true;
      isValid = false;
    } else {
      this.vMetricName = false;
    }
    if (engagementMetric.type != this.types.DropDown)
    return isValid;
      if (
        (engagementMetric.options.length < 2 ||
          !engagementMetric.options?.length) && engagementMetric.type == this.types.DropDown
      ) {
        this.SnackbarService.error(this.localize('txtMinimumTwoOptions', this.language, this.ModuleNames.Shared))
        return (isValid = false);
      }

    if (engagementMetric.options.length)
      for (let i = 0; i < engagementMetric.options?.length; i++) {
        let option = engagementMetric.options[i];
        if (this.extractNameLanguage(option.name) == '') {
          isValid = false;
        }
        if (!this.validateCount(option.name, this.mdCount)) return;
      }

    if (!isValid)
      this.SnackbarService.error(this.localize('txt_fill_fields', this.language, this.ModuleNames.Shared));

    if (!this.validateCount(engagementMetric.name, this.mdCount, 'x')) return;

    return isValid;
  }

  validateCount(value, count, ready?) {
    let validatedCount;
    if (ready == 'x') {
      validatedCount = this.countWord(value, count);
    } else
      validatedCount = this.countWord(this.extractNameLanguage(value), count);
    if (validatedCount > count) {
      this.SnackbarService.error(this.localize('txt_wrong_text_count', this.language, this.ModuleNames.Shared));
      return false;
    }
    if (validatedCount <= count) {
      return true;
    }
  }
  addMetric(newMetric) {
    this.invalid = true;
    if (this.validateCriteria(newMetric)) {
      if (!this.surveyData.surveyCriterias.includes(newMetric)) {
        this.surveyData.selectedCriterias.push(newMetric);
        this.surveyData.surveyCriterias.push(newMetric);
      }
      else {

      }


      this.criteriaAdd = false;
    }
  }

}
