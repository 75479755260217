import { Component, OnInit } from '@angular/core';
import { ExitBaseComponent } from '../../ui/exit-base/exit-base.component';
import { DualSelectItem, DualSelectList } from 'src/app/shared/Theme/controls/dual-dropdown-selection/dual-dropdown-selection.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { CreateSurveyTemplateDto, QuestionDto, SectionDto, SurveyTemplateDto, SurveyTemplateLookupDto, SurveyTemplatesService } from '@ex/exit/survey-templates';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { TemplateManagementService } from '../../data-access/services/template-management.service';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { QuestionTypes } from 'src/app/shared/questionnaire/questions/question-types';
import { ExitTemplatePreviewComponent } from '../../ui/exit-template-preview/exit-template-preview.component';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent extends ExitBaseComponent implements OnInit {
  constructor(private surveyTemplateService: SurveyTemplatesService, private global: GlobalsService, private templateManagementService: TemplateManagementService, private responseDialogService: ResponseDialogService) {
    super()

    this.mdCount = global.mdCount;
  }

  ngOnInit(): void {
    this.addSubscription(this.EssentialObject$.pipe(
      filter(obj => obj?.selectedEntity?.key != null),
      distinctUntilChanged((x, y) => x != y, obj => obj.selectedEntity.key)
    ).pipe(switchMap(obj => {
      this.tenantKey = obj.selectedEntity.tenantKey;
      this.companyKey = obj.selectedEntity.key;
      this.selectedEntity = obj.selectedEntity;
      this.language = obj.language;
      return this.templateManagementService.fetchTemplates(this.tenantKey, this.companyKey, this.language)
    })).subscribe(data => {
      this.selectedTemplate = data.selectedTemplate;
      this.templateLookup = data.templateMenu;
      //add tour to custom template
      this.templates = data.templates;
      this.currentTemplate = this.templates.find(x => x.key == this.selectedTemplate.key)
      this.LoaderService.hideLoader();
      this.settingsLoaded.next(true);
    }));

    this.getUpdatedTemplate();
  }
  templates: SurveyTemplateDto[] = [];
  templateLookup: DualSelectList[];

  selectedTemplate: any;

  currentTemplate: SurveyTemplateDto;

  settingsLoaded = new Subject();
  settingsLoaded$ = this.settingsLoaded.asObservable();


  isAddTemplate = false;

  templateChangeAction = new Subject<any>();
  templateChangeAction$ = this.templateChangeAction.asObservable();

  tenantKey: string
  companyKey: string
  language: string = "EN"

  selectedEntity;

  topValue;
  mdCount = 10
  loading: boolean = false;
  createNewTemplateForm = new FormGroup({
    key: new FormControl(""),
    name: new FormControl([], [Validators.required])
  })
  newTemplate: Partial<SurveyTemplateDto> | CreateSurveyTemplateDto;
  onCreateNewTemplate(template?: SurveyTemplateDto) {
    if (!template)
      this.newTemplate = {
        name: [],
        companyKey: this.companyKey,
        tenantKey: this.tenantKey

      };
    else this.newTemplate = JSON.parse(JSON.stringify(template));
    this.isAddTemplate = true;
  }

  /**
   *
   * Save new template to database
   *
   */
  createTemplate(template: any) {
    ;
    if (!this.isValidName(template?.name))
      return;
    if (!template?.key)
      this.surveyTemplateService.create({
        companyKey: this.companyKey,
        name: template.name,
        tenantKey: this.tenantKey
      }).subscribe(response => {
        // TODO: Close the Modal
        this.templates.push(response);
        this.selectedTemplate = this.templateManagementService.populateTemplateData(response, this.language);
        this.templateLookup[1].list.push(this.selectedTemplate);
        this.currentTemplate = this.templates.find(x => x.key == this.selectedTemplate.key)
        // TODO: Reload the Template List
        this.isAddTemplate = false;
        this.SnackbarService.success(this.localize("txt_TemplateCreated", this.language, this.ModuleNames.Exit));

      })
    else
      this.surveyTemplateService.update(this.tenantKey, this.companyKey, template).subscribe(data => {
        this.currentTemplate.name = data.name;
        this.selectedTemplate = this.templateManagementService.populateTemplateData(data, this.language);
        this.SnackbarService.success(this.localize("txt_TemplateUpdated", this.language, this.ModuleNames.Exit));
        let temTemplate = this.templateLookup[1].list.find(item => item.key == template.key);
        let index = this.templateLookup[1].list.indexOf(temTemplate)
        this.templateLookup[1].list[index] = this.selectedTemplate;
        this.isAddTemplate = false;
      });
  }

  onTemplateChange(template) {
    this.selectedTemplate = template;
    this.currentTemplate = this.templates.find(x => x.key == template.key)
  }



  async deleteTemplate(template: any) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_delete_item_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Shared,
        this.language
      );
      if (confirmed) {
        this.surveyTemplateService.delete(this.tenantKey, this.companyKey, template.key).subscribe(x => {
          this.templateLookup[1].list.splice(this.templateLookup[1].list.indexOf(this.selectedTemplate), 1);
          this.selectedTemplate = this.templateManagementService.defaultMenuTemplate;
          this.currentTemplate = this.templates.find(x => x.key == this.selectedTemplate.key)
        });
      }
    } catch {

    }
  }







  templateObservable: BehaviorSubject<any> = new BehaviorSubject<any>({});
  updateObject = (object: any) => this.templateObservable.next(object);
  updateTemplate(stemplate) {
    this.loading = true;
    this.updateObject(stemplate);

  }

  getUpdatedTemplate() {
    this.addSubscription(this.templateObservable.pipe(debounceTime(5000), filter(s => s.key != null), switchMap((t: SurveyTemplateDto) => {
      t.isDraft = t.sections.some(section => {
        if (!this.isValidSection(section))
          console.log(section);


        return !this.isValidSection(section)
      });
      return this.surveyTemplateService.update(this.selectedEntity.tenantKey, this.selectedEntity.key, t).pipe(map(temp => ({ temp, t })));
    })).subscribe(({ temp, t }) => {
      this.templateChangeAction.next(temp);
      this.loading = false
    }));
  }

  isValidSection(factor: SectionDto) {
    return this.isValidName(factor.name) && factor.questions.length && factor.questions.every(question => this.isValidQuestion(question, factor));
  }
  isValidQuestion(question: QuestionDto, factor: SectionDto) {
    if (!factor?.isAllowNewQuestions || (factor?.isAllowNewQuestions && question.isPredefined))
      return this.isValidName(question.name);
    if (question.questionType == QuestionTypes.OpenText)
      return true;
    let isValid = true;
    if (!question.questionOptions?.length || question.questionOptions?.some(option => !this.isValidName(option.name)))
      isValid = false;
    else if (((question.max && question.max > question.questionOptions.length) || (question.min && (question.min < 1 || question.min > question.max))))
      isValid = false;

    return isValid
  }

  isValidName(name) {
    return name?.find(s => s.name.length >= 2) ? true : false;
  }

  openPreviewExport() {
    this.addSubscription(this.ZenCDKService.openComponent(ExitTemplatePreviewComponent, {
      language: this.language, entity: this.selectedEntity, template: this.currentTemplate
    }));
  }

}
