<div *ngIf="language" class="fixed flex flex-col items-stretch top-0 left-0 Page h-screen w-screen z-50 bg-zen-background">
    <app-form-builder-header
        [surveyId]="surveyId"
        [surveyTitle]="survey.title  | sjsTranslate:language"
        [currentPage]="'analytical-criteria'"
        [showAssignWeights]="showAssignWeights"
        [language]="language"
        [dir]="direction"
        (nextStep)="nextStep()"
        (previousStep)="previousStep()"
        (pageChanged)="pageChanged($event)">
    </app-form-builder-header>

    <div  class="flex flex-col items-center w-full h-100vh-110px pt-10 overflow-scroll">
      <survey-criteria *ngIf="survey?.surveyCriterias?.length" class="max-w-5xl w-full" [selectedEntity]="selectedEntity" [language]="language" #criteria  [(surveyData)]="survey" ></survey-criteria>
    </div>
</div>
