import { Injectable } from '@angular/core';

export enum SeperationTypes {
  Resignation = "resignation",
  Termination = "termination",
  Retirement = "retirement"
}


@Injectable({
  providedIn: null
})
export class SeperationTypesService {

  private seperationTypes = [
    {
      key: SeperationTypes.Resignation,
      name: [{
        key: "EN",
        name: "Resignation"
      }, {
        key: "AR",
        name: "استقالة"
      }]
    },
    {
      key: SeperationTypes.Termination,
      name: [{
        key: "EN",
        name: "Termination"
      }, {
        key: "AR",
        name: "إنهاء الخدمات"
      }]
    },
    {
      key: SeperationTypes.Retirement,
      name: [{
        key: "EN",
        name: "Retirement"
      },
      {
        key: "AR",
        name: "تقاعد"
      }]
    }

  ]


  constructor() { }

  get() {
    return this.seperationTypes;
  }

  getByKey(key: string) {
    return this.seperationTypes.find(x => x.key == key);
  }

}
