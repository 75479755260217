import { DimensionKeys } from "./dimension-keys";
import { WellbeingScalesService } from "./wellbeing-scales.service";
const wellbeingScalesService = new WellbeingScalesService;
export const dimensionDataByScore = function (dimensionKey: string, scale: string): {} {
  const dimensionString = Object.keys(DimensionKeys).find(key => DimensionKeys[key] == dimensionKey);
  return {
    dimensionKey: dimensionKey,
    scaleKey: scale,
    translationKeys: {
      description: 'txt' + dimensionString + 'VPOOREXPDescription',
      recommendations: [...new Array(5)].map((value, index) => {
        return 'txt' + dimensionString + 'VPOOREXPRecomendations' + (index + 1)
      })
    }
  };

}

/* *******  Notice ********:

because report only has one default value, first scale value VPOOREXP will be created and served for all,
currently there is no scale preference so fist will be added, the original code is:


  translationKeys: {
    description: 'txt' + dimensionString + scale + 'Description',
    recommendations: [...new Array(5)].map((value, index) => {
      return 'txt' + dimensionString + scale + 'Recomendations' + (index + 1)
    })
  }

    to be used once scaled are working



example of object naming
max 5 recommendations are added by default and loop will be in place in case it exists
{
  dimensionKey: "-NQt3UB5BE9E3LIXRS_n",
    scales: [{
      scaleKey: "VPOOREXP",
      translationKeys: {
        description: "txtPhysicalWellbeingVPOOREXPDescription",
        recommendations: [
          "txtPhysicalWellbeingVPOOREXPRecomendations1"
          ,
          "txtPhysicalWellbeingVPOOREXPRecomendations2"
          ,
          "txtPhysicalWellbeingVPOOREXPRecomendations3"
          ,
          "txtPhysicalWellbeingVPOOREXPRecomendations4"
          ,
          "txtPhysicalWellbeingVPOOREXPRecomendations5"]
      }

    }]
}

*/


