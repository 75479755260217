<div [@first] class="flex flex-row justify-between h-header items-center customHShadow px-10 fixed top-0 z-40 backdrop-filter backdrop-blur-2xl bg-white bg-opacity-90 w-screen">
    <div [@first] class="flex flex-row justify-start items-center gap-5 w-4/12">
        <div class="flex flex-row items-center">
            <img class="h-8 w-" src="/assets/img/svg/logo.svg" alt="">
        </div>
        <span [@child1] class="h-10 w-0 border-r border-zen border-opacity-20"></span>
        <h5 [@child2] #moduleName [@first] class="text-lg text-exp font-normal">
            {{"Survey "| translate:language:ModuleNames.SurveyX}}
            <span class="font-extrabold">{{"X"| translate:language:ModuleNames.SurveyX}}</span>
        </h5>
    </div>
</div>

<!-- <div class=" flex flex-row items-center w-full pt-28 px-10 ">
      <div class="flex flex-row gap-8 items-center">
          <button class="flex bg-zenMain bg-opacity-10  text-zenMain text-xs font-medium py-2 px-4 rounded items-center" routerLink="/surveyx/form-library">
              <i class="fa-solid fa-arrow-left-long mr-2 w-4  fill-current" fill="currentColor"></i>
              {{"Back to Forms"| translate:language:ModuleNames.SurveyX}}
          </button>
      </div>
</div> -->

<div class="pt-48  text-center justify-center">
    <h2 class="text-4xl font-extrabold">Survey Closed</h2>
    <div class="pt-10 text-red-600 text-9xl"><i class="fa-solid fa-ban"></i></div>
    <div class="pt-10  text-lg">
        <h5>The survey has been closed. Keep an eye for the next one.</h5>
    </div>
    <div class="text-lg">
        <h5>Thank you!</h5>
    </div>
</div>

