import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'md-dashlet-card',
  templateUrl: './md-dashlet.component.html',
  styleUrls: ['./md-dashlet.component.scss']
})
export class MdDashletComponent implements OnInit {
  @Input() icon;
  @Input() title;
  @Input() subTitle;
  @Input() value;
  constructor() { }

  ngOnInit(): void {
  }

}
