import { Component, ContentChild, ElementRef, HostListener, Input, OnInit, TemplateRef } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'z-edit-container',
  templateUrl: './zen-edit-container.component.html',
  styleUrls: ['./zen-edit-container.component.scss'],

})
export class ZenEditContainerComponent implements OnInit {
  constructor(private el: ElementRef) { }
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.el.nativeElement.contains(target);

    if (clickedInside) {
      this.el.nativeElement.focus();
      setTimeout(() => {
        this.el.nativeElement.scrollIntoView({ behavior: 'auto', block: 'center' });
      }, 500)

    }
    else if (!clickedInside && this.active && this.autoClose) {
      this.active = false;
    }
  }
  active;
  @Input('active') set tActive(active) {
    console.log(active);

    this.active = active
    this.el.nativeElement.focus();

  };
  // @Input('auto') auto = true;
  @Input('autoClose') autoClose = true;

  ngOnInit(): void { }
  @ContentChild('editTemplate') editTemplate: TemplateRef<any>;
  @ContentChild('viewTemplate') viewTemplate: TemplateRef<any>;
}
