import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'left-preview',
  templateUrl: './left-preview.component.html',
  styleUrls: ['./left-preview.component.scss']
})
export class LeftPreviewComponent implements OnInit {

  @Input() classList: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  getClasses() {
    let pageClasses = ['print-preview-left'].concat(this.classList);
    return pageClasses;
  }

}
