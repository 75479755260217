import { Component, OnInit } from '@angular/core';
import { CHART_DATA_RESPONSE_STRATEGY } from '@ex/module/core/services/chart-data.service';
import { ChartOptionsService } from '@ex/module/shared/chart/chart-options.service';
import { AnalyticsFilter, AnalyticsService } from '@ex/module/survey-x/data-access/services/analytics.service';
import { CriteriaResolverService } from '@ex/module/survey-x/data-access/services/criteria-resolver.service';
import { ReportDataService } from '@ex/module/survey-x/data-access/services/report-data.service';
import { SxBaseComponent } from '@ex/module/survey-x/ui/sx-base/sx-base.component';
import { SurveyAnalysisService, SurveyCriteria } from '@ex/survey-x/index';
import { BehaviorSubject, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

interface chart {
  title: string,
  Title: string,
  DashletType: string,
  group: string,
  canGroup: boolean,
  language: string
  halfWidth: boolean,
  isEntity: boolean,
  type: string,
  data?: any,
  data$: any
}

@Component({
  selector: 'app-respondent-analysis',
  templateUrl: './respondent-analysis.component.html',
  styleUrls: ['./respondent-analysis.component.scss']
})
export class RespondentAnalysisComponent extends SxBaseComponent implements OnInit {

  tenantKey;
  companyKey;
  formId;

  surveyCriterias: Partial<SurveyCriteria>[] | any;
  charts: Partial<chart>[];
  language;

  pageChanged = new BehaviorSubject(0);
  pageChanged$ = this.pageChanged.asObservable();

  maxResultCount = 5;

  respondentsData: any;
  searchFilter = new BehaviorSubject("");

  filters: AnalyticsFilter;



  constructor(private analyticsService: AnalyticsService
    , private surveyAnalysisService: SurveyAnalysisService
    , private criteriaResolverService: CriteriaResolverService
    , private chartOptionsService: ChartOptionsService
    , private reportDataService: ReportDataService) { super() }

  ngOnInit(): void {

    const sub = this.analyticsService.filters$.pipe(
      filter(x => x != null),
      switchMap(filters => {
        // Fetch the List of Dimensions for the dropdown based on the filter

        return of({ filters, criterias: filters.survey.surveyCriterias || [] });

      })
    ).subscribe(({ filters, criterias }) => {

      this.filters = filters;
      this.tenantKey = filters.tenantKey;
      this.companyKey = filters.companyKey;
      this.formId = filters.survey.key;
      this.language = filters.language;
      this.surveyCriterias = criterias;

      this.criteriaResolverService.setSurveyCriterias(this.surveyCriterias);

      this.prepareCriteria();

      this.filtersLoaded.next(filters);
    })

    this.addSubscription(sub);
  }

  prepareCriteria() {
    this.charts = []
    let criteriaCharts = this.surveyCriterias.filter(x => x.options.length > 0).map(criteria => {
       
      return {
        Title: this.localize('txtRespby', this.language, this.ModuleNames.SurveyX) + ' ' + this.ExtractPipe.transform(criteria.name, this.language),
        DashletType: 'barV',
        group: criteria.key,
        canGroup: false,
        language: this.language,
        halfWidth: false,
        isEntity: criteria.isEntity,
      }
    })

    this.charts = this.charts.concat(criteriaCharts);

    this.charts.forEach(chart => {
      chart.data$ = this.chartData(chart)
    })

    this.reportDataService.setRespondentCharts(this.charts);

  }

  chartData(chart) {


    return this.surveyAnalysisService.getGroupedRespondentsByCriteria(chart.group,
      {
        tenantKey: this.filters.tenantKey,
        companyKey: this.filters.companyKey,
        formId: this.filters.survey.key,
        type: chart.group != 'status' ? 'ec' : '',
        filters: [...this.analyticsService.mapGlobalFilters(this.filters)]
      }
    ).pipe(map((data: any) => {

      data.data.forEach(x => {
        x.key = x.category;
        x.category = this.criteriaResolverService.extract(chart.group, x.category, this.language);
      });

      let chartData = CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data.data).process();

      chartData.drillDown = data.drillDown.map(x => {
        x.category = this.criteriaResolverService.extract(chart.group, x.category, this.language)
        return x;
      });

      chart.DashletChartTypes = this.chartOptionsService.getSupportedChartTypes(chart, chartData.categories.length, chartData.groupedChart);

      console.log(chart);
      console.log(chartData);

      return chartData;
    }))
  }



}
