import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SurveyTemplateDto } from '@ex/exit/survey-templates';
import { DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
import { NativeExportBaseComponent } from 'src/app/shared/native-export-base/native-export-base.component';
import { ExitExperienceCodesService } from '../../data-access/services/exit-experience-codes.service';

@Component({
  selector: 'app-leaver-answers-preview-report',
  templateUrl: './leaver-answers-preview-report.component.html',
  styleUrls: ['./leaver-answers-preview-report.component.scss']
})
export class LeaverAnswersPreviewReportComponent extends NativeExportBaseComponent implements OnInit {
  constructor(@Inject(DATA_TOKEN) public data: any, public exitExperienceCodesService: ExitExperienceCodesService) {
    super();
  }
  @Output() close = new EventEmitter()
  @Output() results = new EventEmitter()
  isArabic: boolean = false;
  reportTitle: string;
  exportMargin: any;
  leaver: any;
  language: string;
  currentUser: any;
  companyName: string;
  zenithrSmallLogoUrl: string;
  zenithrLogo: string;
  leaverAnswers: SurveyTemplateDto;
  notes = [];
  leaverName: string;
  date: Date = new Date();
  currentDate: string = `${new Date().getDate().toString()}/${new Date().getMonth() + 1}/${new Date().getFullYear().toString()}`;
  currentYear: string = `${new Date().getFullYear().toString()}`;
  displaySpinner: boolean = false;
  ten = Array(11);

  steps = [
    { key: 0, color: 'bg-zen-red' },
    { key: 1, color: 'bg-zen-red' },
    { key: 2, color: 'bg-zen-red' },
    { key: 3, color: 'bg-zen-red' },
    { key: 4, color: 'bg-zen-red' },
    { key: 5, color: 'bg-zen-red' },
    { key: 6, color: 'bg-zen-red' },
    { key: 7, color: 'bg-zen-orange' },
    { key: 8, color: 'bg-zen-orange' },
    { key: 9, color: 'bg-zen-green' },
    { key: 10, color: 'bg-zen-green' },
  ]
  legends = [
    { steps: 7, name: 'txtUnLikely' },
    { steps: 2, name: 'txtNeutralLikely' },
    { steps: 2, name: 'txtLikely' },
  ];

  ngOnInit(): void {
;
    this.language = this.data.language;
    this.currentUser = this.data.currentUser;
    this.exportMargin = this.data.exportMargin;
    this.companyName = this.data.companyName;
    this.reportTitle = this.data.reportTitle;
    this.leaver = this.data.leaver;
    this.leaverAnswers = this.data.leaverAnswers;
    this.leaverName = this.data.leaverName;
    this.leaverName = this.data.leaverName;
    this.notes = this.data.notes;
    this.isArabic = this.language === 'AR';
    this.zenithrSmallLogoUrl = 'assets/img/logo.png';
    this.zenithrLogo = 'assets/img/logo.png';
    this.LoaderService.hideLoader();
  }

  ngOnDestroy() {
    this.isArabic = false;
  }


  extractNameLanguage(eG: any) {
    ;
    return this.languageService.extractNameLanguage(eG, this.language).replaceAll('[CompanyName]', this.languageService.extractNameLanguage(this.companyName, this.language));
  }

  getConfidence(subquestionKey: string, key: string) {
    return this.GeneralService.getQuestionOptions(subquestionKey, key, this.language);
  }


  checkOther(q) {
    if (q.answer) {
      let optionKey = q.questionOptions?.find(option => option.otherAnswer == true)?.key;
      if (optionKey)
        return (q.answer.indexOf(optionKey) > -1)
    }
    if (q.subQuestions) {
      let subKey = q.subQuestions?.find(option => option.otherAnswer == true)?.key;
      return subKey ? true : false;
    }

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.exportDraft(this.language);
      this.LoaderService.hideLoader();
    }, 2000)
    setTimeout(() => {
      this.close.emit(true);
    }, 5000)
  }
  checkValue(array, value) {
    return array?.find(v => v == value) ? true : false;
  }


  resignationCheck(question) {
    return this.leaver.seperationType == 'resignation' && !question.skipped;
  }
  dependsOnQuestionCheck(question, section) {
    let dependOnQuestionAnswers = section.questions.find(q => q.key == question.dependsOnQuestionKey)?.answer;
    if (!question.dependsOnQuestionKey || !dependOnQuestionAnswers)
      return false;

    if (dependOnQuestionAnswers || dependOnQuestionAnswers?.length) {
      if (Array.isArray(dependOnQuestionAnswers))
        return dependOnQuestionAnswers?.find(key => question.skipIfConditionIsKeys.find(x => x == key)) && this.resignationCheck(section.questions.find(q => q.key == question.dependsOnQuestionKey))
      else if (typeof dependOnQuestionAnswers === 'string' || dependOnQuestionAnswers instanceof String) {
        return question.skipIfConditionIsKeys.find(x => x == dependOnQuestionAnswers)
      }
    }
    else
      return false;
  }

  returnAnswerData(value: number, property:string) {
    // property is either 'icon' or 'color'
   return this.exitExperienceCodesService.config[Number(value)][property]
  }

}
