<z-modal [ngClass]="{'RTL':language == 'AR'}" [open]="open">
    <ng-container header>
        <h6 class="modal-title">
            {{"Confirm Publish"| translate:language:ModuleNames.SurveyX}}
        </h6>

        <button class="text-center rounded close-button py-2 px-3" (click)="close()">
            <i class="fa-solid fa-xmark"></i>
        </button>
    </ng-container>

    <ng-container body>
        <div class="flex flex-wrap flex-col items-center">
            <p class="title-bold text-center mb-3">
                {{"Are you sure you want to Publish the survey?"| translate:language:ModuleNames.SurveyX}}
            </p>

            <p class="subtitle text-center mb-3">
                {{"Make sure you've configured any settings necessary before sharing the survey with the respondents."| translate:language:ModuleNames.SurveyX}}
            </p>
            <p class="subtitle text-center mb-3">
                <span class="hint">{{"Hint:"| translate:language:ModuleNames.SurveyX}} </span>
                {{"Certain configurations can not be updated after the survey is published."| translate:language:ModuleNames.SurveyX}}
            </p>
        </div>
    </ng-container>

    <ng-container footer>
        <div class="flex flex-row justify-between w-full">
            <a (click)="close()"
                class="btn cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
                {{"Cancel"| translate:language:ModuleNames.SurveyX}}
            </a>
            <a class="btn exp text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1"
                (click)="onSubmit()">
                {{"Yes, Publish now"| translate:language:ModuleNames.SurveyX}}
            </a>
        </div>
    </ng-container>
</z-modal>
