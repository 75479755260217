import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { EssentialObject } from '@ex/module/core/services/essential-object.service';
import { LoaderService } from '@ex/module/core/services/loader.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { NativeExportBaseComponent } from '@ex/module/shared/native-export-base/native-export-base.component';

import { Question } from 'survey-angular';
import { Model } from 'survey-core';

@Component({
  selector: 'app-survey-preview-export',
  templateUrl: './survey-preview-export.component.html',
  styleUrls: ['./survey-preview-export.component.scss']
})
export class SurveyPreviewExportComponent extends NativeExportBaseComponent implements OnInit {

  essentialObject: EssentialObject;
  @Input() survey;
  @Input() open;
  @Output() openChange = new EventEmitter;
  @Input('essentialObject') set esObj(obj: EssentialObject) {
    this.essentialObject = obj;
    this.language = this.essentialObject.language;
    this.companyName = this.ExtractPipe.transform(this.essentialObject.selectedEntity.name, this.language)
    this.surveyModel = new Model(this.survey.jsonText);
    this.surveyModel.questionsOnPageMode = 'standard';
    console.log(this.surveyModel.pages);


  };
  surveyModel: Model;
  companyName: any;
  date: Date = new Date();
  language = 'EN';
  ModuleNames = ModuleName;
  constructor() {
    super();
  }

  ngOnInit() {

  }
  closeModal() {
    setTimeout(() => {
      this.openChange.emit(false)
    }, 1000);
  }

  getPreviousSectionCount(s: number, q: number) {
    return this.surveyModel.pages.slice(0, s)?.map(x => x['elementsValue']?.length)?.reduce((a, b) => a + b, 0) + q + 1;
  }
  returnRates(question: Question) {

    let arr = Array(question.rateCount);
    for (let index = 0; index < arr.length; index++) {
      arr[index] = question['rateMin'] + (question['rateStep'] * index)

    }
    return arr;

  }
  returnRateLabels(rateLabel: any, question) {
    if (!rateLabel?.min)
      return [rateLabel]

    let arr = new Array(rateLabel?.max - rateLabel?.min + 1);
    for (let index = 0; index < arr?.length; index++) {
      arr[index] = rateLabel.min + (index * question['rateStep'])

    }
    return arr;
  }

  groupedSection(rateValues?: any) {
    let values = [{ color: '#ff4530', rates: new Array(6).fill(0), name: 'Unlikely', min: 0, max: 6 }, { color: '#FF9600', rates: Array(2).fill(0), name: 'Neutral', min: 7, max: 8 }, { color: '#3eb75b', rates: new Array(2).fill(0), name: 'Likely', min: 9, max: 10 }]
    return values;
  }

  returnPreviousIndex(parentIndex, array:any[]){
    if (!parentIndex)
      return 0;
    return array.slice(0, parentIndex).map(x => x.rates).flat().length
  }


}
