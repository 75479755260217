<div class="flex flex-col gap-5" *ngIf="!single">
    <h6 class="text-zen text-opacity-60 text-xs text-center">{{returnAnswerCountText(question)}}</h6>
    <div class="flex flex-row flex-wrap items-stretch -ml-2 -mr-2">
        <div class="flex flex-row  md:w-1/2 w-full px-1 py-1"
            [ngClass]="{'md:w-full py-1':option.otherAnswer, 'opacity-80':!option.checked,  'opacity-100 font-medium':option.checked}"
            *ngFor="let option of question.questionOptions">
            <div class="flex flex-row items-center bg-white rounded w-full px-5 exp minh12 gap-6 my-1">
                <mat-checkbox [(ngModel)]="option.checked" (ngModelChange)="addQuestionAnswer(question, option)"
                    [disabled]="question?.max <= question.answer?.length && !option.checked">
                    <h5 class="text-zen text-base leading-12 whitespace-normal">{{extractNameLanguage(option.name)}}
                    </h5>
                </mat-checkbox>
                <div [ngClass]="{'opacity-40':!option.checked}"
                    class="border-zen border border-opacity-10 flex flex-col justify-center flex-grow rounded-sm h-11  overflow-y-hidden relative"
                    *ngIf="option.otherAnswer">
                    <textarea class="h-10 text-sm resize-none  px-2" rows="1"
                        [attr.disabled]="countWord(question.otherAnswer1, lgCount) > lgCount || !option.checked ? true : null"
                        maxlength="300" (keyup)="countWord(question.otherAnswer1, lgCount)"
                        [placeholder]="'txtSpecifyHere'| translate:language:ModuleNames.Shared"
                        [(ngModel)]="question.otherAnswer1"></textarea>
                    <h4 *ngIf=" countWord(question.otherAnswer1, lgCount)> 0"
                        [ngClass]="{'text-zen-red': countWord(question.otherAnswer1, lgCount)>= lgCount, 'left-0':(language | language ),'right-0':!(language | language )}"
                        class="text-xxs absolute bottom-0 opacity-50">
                        {{countWord(question.otherAnswer1, lgCount)}} {{'txtof'| translate:language:ModuleNames.Shared}}
                        {{lgCount}}
                    </h4>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="checkOther(question)" class="px-3 w-full">
            <div *ngIf="checkOther(question)" class="bg-white  rounded px-5 pt-1 overflow-hidden w-full ">
                <mat-form-field class="w-full -mt-3" appearance="none">
                    <textarea rows="3" [attr.disabled]="countWord(question.otherAnswer1, lgCount) > lgCount ? true : null"
                        maxlength="300" (keyup)="countWord(question.otherAnswer1, lgCount)"
                        [placeholder]="'txtSpecifyHere'| translate:language:ModuleNames.Shared" [(ngModel)]="question.otherAnswer1"
                        matInput></textarea>
                </mat-form-field>

            </div>

        </div> -->
    </div>
</div>

<div *ngIf="single" class="flex flex-col -ml-2 -mr-2">
    <div class="flex flex-row justify-center " *ngFor="let option of question.questionOptions; let i = index">
        <div class="flex flex-row items-center bg-white rounded w-full px-5 minh12 gap-6 my-1 btn" (click)="question.answer = option.key">
            <div [ngClass]="{'text-opacity-60':question.answer != option.key}" class="flex flex-row items-center rounded-lg w-full px-5 gap-2 text-zen " >
                <i *ngIf="question.answer != option.key" class="fa-regular fa-circle "></i>
                <i *ngIf="question.answer == option.key" class="fa-solid fa-circle-dot text-exp"></i>
                <h5 class="text-zen text-base leading-12 whitespace-normal">{{extractNameLanguage(option.name)}}  </h5>
            </div>
        </div>
    </div>

    <div *ngIf="checkOther(question)" class="px-3 w-full">
        <div class="bg-zen bg-opacity-10 rounded-md px-5 pt-1 overflow-hidden w-full">
            <mat-form-field class="w-full -mt-3" appearance="none">
                <textarea rows="3" [attr.disabled]="countWord(question.otherAnswer1, lgCount) > lgCount ? true : null"
                    maxlength="300" (keyup)="countWord(question.otherAnswer1, lgCount)"
                    [placeholder]="'txtSpecifyHere'| translate:language:ModuleNames.Shared"
                    [(ngModel)]="question.otherAnswer1" matInput></textarea>
            </mat-form-field>

        </div>
        <h4 *ngIf=" countWord(question.otherAnswer1, lgCount)> 0"
            [ngClass]="{'text-zen-red': countWord(question.otherAnswer1, lgCount)>= lgCount}" class="text-xxs">
            {{countWord(question.otherAnswer1, lgCount)}} {{'txtof'| translate:language:ModuleNames.Shared}}
            {{lgCount}}
        </h4>
    </div>
</div>




<!-- <div *ngIf="question.questionType == 'multi-options-single-answer'" style="width: 100%;">
    <div class="flex flex-row flex-wrap exit-half question-answers">
        <div class="flex flex-col is-offset-one-quarter is-half is-offset-one-quarter"
            *ngFor="let option of question.questionOptions; let n = index">
            <div class="answer-option">
                <label class="zen-toggle" [for]="n">
                    <input (change)="checkOther(question)" [id]="n" type="radio" [(ngModel)]="question.answer"
                        [name]="question.key" [value]="option.key" />
                    <span class="px-5 mx-2"> {{extractNameLanguage(option.name)}}</span>

                </label>
            </div>
            <div *ngIf="checkOther(question) && option.key == question.answer">
                <div class="container ">
                    <mat-form-field class=" px-0 py-0" appearance="fill">
                        <textarea rows="3"
                            [attr.disabled]="countWord(question.otherAnswer1, lgCount) > lgCount ? true : null"
                            maxlength="300" (keyup)="countWord(question.otherAnswer1, lgCount)"
                            [placeholder]="'txtSpecifyHere'| translate:language:ModuleNames.Shared" [(ngModel)]="question.otherAnswer1"
                            matInput></textarea>
                    </mat-form-field>
                    <h4 *ngIf=" countWord(question.otherAnswer1, lgCount)> 0"
                        [ngClass]="{'text-zen-red': countWord(question.otherAnswer1, lgCount)>= lgCount}"
                        class="text-sm c-80"> {{countWord(question.otherAnswer1, lgCount)}} {{'txtof'|
                        translate:language}}
                        {{lgCount}}</h4>
                </div>
            </div>

        </div>
    </div>
</div> -->
