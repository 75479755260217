import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@ex/module/survey-x/data-access/services/analytics.service';
import { SurveyjsDataService } from '@ex/module/survey-x/data-access/services/surveyjs-data.service';
import { SxBaseComponent } from '@ex/module/survey-x/ui/sx-base/sx-base.component';
import { CommentAnalysisService } from '@ex/survey-x/index';
import { count } from 'console';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ExcelComment, ExcelQuestion } from '../../../../exit-new/data-access/services/comment-excel-download.service';
import { SjsTranslatePipe } from '@ex/module/survey-x/utils/pipes/sjs-translate.pipe';
import { ExcelCommentDownloadService,ExcelSection } from '@ex/module/survey-x/data-access/services/excel-comment-download.service';
import { AnswersParsingService } from '@ex/module/survey-x/data-access/services/answers-parsing-service';

@Component({
  selector: 'app-comments-analysis',
  templateUrl: './comments-analysis.component.html',
  styleUrls: ['./comments-analysis.component.scss'],
  providers: [SjsTranslatePipe]
})
export class CommentsAnalysisComponent extends SxBaseComponent implements OnInit {

  formId: string
  sections: { key: string, title: any }[] = [];
  questions = [];

  language: string = "EN";

  selectedSectionKey: string = "ALL";

  counts = [];

  filters;

  constructor(private analyticsService: AnalyticsService
    , private surveyjsDataService: SurveyjsDataService
    , private commentAnalysisService: CommentAnalysisService, private sjsTranslatePipe: SjsTranslatePipe
    , private excelCommentDownloadService: ExcelCommentDownloadService
    , private answersParsingService : AnswersParsingService) { super() }

  ngOnInit(): void {

    const sub1 = this.EssentialObject$.pipe(
      filter(obj => obj.selectedEntity?.key != null)
    ).subscribe(obj => {
      this.language = obj.language;
    });
    this.addSubscription(sub1);

    this.addSubscription(this.analyticsService.downloadComments$.subscribe(r => {
      this.downloadExcel();
    }))

    this.fetchSectionCounts();

    const sub = this.analyticsService.filters$.pipe(
      filter(x => x != null),
    ).subscribe(filters => {
      this.formId = filters.survey.key;

      this.filters = filters;

      this.filtersLoaded.next(filters);
    })

  }
  fetchSectionCounts() {
    this.filtersLoaded$
      .pipe(
        switchMap(filters => {
          return this.commentAnalysisService.getSectionAndQuestionCommentCount({
            tenantKey: filters.tenantKey,
            companyKey: filters.companyKey,
            formId: this.formId,
            filters: [...this.analyticsService.mapGlobalFilters(filters)]
          })
        })
      )
      .subscribe(counts => {
        this.counts = counts;
        const questionKeys = [... new Set(counts.map(x => x.questionKey))];

        this.sections = this.surveyjsDataService.getSections();
        this.questions = this.surveyjsDataService.getQuestions().filter(x => questionKeys.includes(x.name));;

        this.selectedSectionKey = this.sections[0]?.key ?? "";
      })

    // you have an api that gets comments per section



  }

  private downloadExcel() {
    const commentsPerQuestion = combineLatest([...this.questions.map(question => {

      return this.commentAnalysisService.getListAsyc({
        tenantKey: this.filters.tenantKey,
        companyKey: this.filters.companyKey,
        formId: this.filters.survey.key,
        type: "",
        questionKey: question.name,
        isAllResults: true,
        filters: [...this.analyticsService.mapGlobalFilters(this.filters)]
      }).pipe(
        map(q => {
          return {
            key: question.name,
            name: this.sjsTranslatePipe.transform(question.title ?? question.name, this.language),
            number: question.questionNo,
            totalComments: question.totalComments,
            comments: q.items.map(c => {

              return {
                name: c.comment,
                answer: this.answersParsingService.getAnswer(c.answer, question, this.language),
                sentiment: 'neutral',
              } as ExcelComment;
            })
          } as ExcelQuestion;
        })
      );

    })]);

    commentsPerQuestion.subscribe((response) => {

      const sections = this.sections.filter(x => x.key != "ALL").map(section => {

        const ids = this.questions.filter(x => x.sectionKey == section.key).map(x => x.name);

        return {
          key: section.key,
          name: this.sjsTranslatePipe.transform(section.title, this.language),
          questions: response.filter(x => ids.includes(x.key))
        } as ExcelSection;
      });
      this.excelCommentDownloadService.generate({
        sections: sections,
        language: this.language,
        skipSentiment: true,
        filename: "test"
      });



    });
  }

  getQuestionNumber(questionKey: any) {
    return this.questions.indexOf(this.questions.find(x => x.name == questionKey)) + 1
  }

}
