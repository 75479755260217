import { Injectable } from '@angular/core';
import { SurveyCriteriaDto } from '@ex/wellbeing/surveys';
import { BehaviorSubject, Subject } from 'rxjs';
import { SurveyCriteriaDropdown } from './criteria-resolver.service';
import { SectionDto } from '@ex/wellbeing/survey-templates';

export interface AnalyticsFilter {
  survey: {
    name: string,
    key: string,
    surveyCriterias?: SurveyCriteriaDropdown[],
    dimensions: SectionDto[];
  };
  tenantKey: string;
  companyKey: string;
  showExtraFactors?: boolean;
  chartFilters?: ChartFilter[];
  entityId?: string[];
  language?:string
}

export interface ChartFilter {
  key: string;
  value: any;
}

export enum AnalysisFilterKeys {
  EntityId = "Entity",
  Gender = "Gender",
  PerformanceRating = "PerformanceRate",
  Nationality = "Nationality",
  Generation = "Generation",
  AgeGroups = "AgeGroup",
  TenureGroups = "TenureGroup",
  BandLevel = "BandLevel",
  JobGrade = "JobGrade",
  Criteria = "criteria",
  IncludeExtraDrivers = "ExtraDimentions",
  DateOfBirth = "DOB",
  DateOfJoining = "JOD"
}


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private filters = new BehaviorSubject<AnalyticsFilter>(null);
  filters$ = this.filters.asObservable();

  private dymmyFilters = new Subject<AnalyticsFilter>();
  dummyFilters$ = this.dymmyFilters.asObservable();

  constructor() { }

  setFilters(filters: AnalyticsFilter, isDummy = true) {

    if (isDummy)
      this.dymmyFilters.next(filters);
    else
      this.filters.next(filters);
  }

  mapGlobalFilters(filters: AnalyticsFilter) {
    let globalFilters: ChartFilter[] = [{ key: AnalysisFilterKeys.IncludeExtraDrivers, value: filters.showExtraFactors || false }];

    if (filters.entityId?.length) {
      globalFilters.push({
        key: "Entity",
        value: filters.entityId
      })
    }

    return globalFilters;
  }


}
