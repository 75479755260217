import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Notification } from '../data-models/notification';
import { PlatformUser } from '../data-models/platform-user';
import { map, take } from 'rxjs/operators';
import { NotificationMessage } from '../data-models/notification-message';
import { LanguageService } from './language.service';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { ModuleName } from 'src/app/shared/enums/ModuleName';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(public db: AngularFireDatabase, private logService: LogService,
    private languageService: LanguageService, private translationPipe: TranslatePipe) { }
  ModuleNames = ModuleName
  updateUserNotificationList(platformUserKey: string, notification: Notification) {
    let prefix: string = '';
    if (notification.notiificationType == 'Q-STARTED') {
      prefix = '-QST';
    }
    if (notification.notiificationType == 'Q-SUBMITTED') {
      prefix = '-QSB';
    }
    let ref: string = notification.leaverKey + prefix;
    notification.key = ref;
    return this.db.object('/NotificationList/' + notification.tenantKey + '/' + platformUserKey + '/' + ref).update(notification);
  }


  fetchUserNotifications(user: PlatformUser) {
    return this.db.list<Notification>('/NotificationList/' + user.tenantKey + '/' + user.key).snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        )
      );
  }

  removeNotification(notificationKey: string, user: PlatformUser) {

    return this.db.object<Notification>('/NotificationList/' + user.tenantKey + '/' + user.key + '/' + notificationKey).remove();
  }

  evaluateNotification(notification: Notification, language: string) {

    let message = new NotificationMessage;
    message.key = notification.key;
    message.type = notification.notiificationType;
    message.title = this.translationPipe.transform('txt_'+ notification.notiificationType, language, this.ModuleNames.Common);

    if (message.type == 'Q-STARTED') {
      let leaverName = notification.name;
      let questionnaireName = this.languageService.extractNameLanguage(notification.questionnaireName, language);
      let messageTemplate: string =this.translationPipe.transform('txt_notification_questionnaire_started', language, this.ModuleNames.Common) ;
      messageTemplate = JSON.parse(JSON.stringify(messageTemplate));
      messageTemplate = messageTemplate.replace("#LEAVERNAME#", leaverName);
      messageTemplate = messageTemplate.replace("#QUESTIONNAIRE#", questionnaireName);
      message.message = messageTemplate;
    }
    if (message.type == 'Q-SUBMITTED') {
      let leaverName = notification.name;
      let questionnaireName = this.languageService.extractNameLanguage(notification.questionnaireName, language);
      let messageTemplate: string =this.translationPipe.transform('txt_notification_questionnaire_completed', language, this.ModuleNames.Common) ;
      messageTemplate = JSON.parse(JSON.stringify(messageTemplate));
      messageTemplate = messageTemplate.replace("#LEAVERNAME#", leaverName);
      messageTemplate = messageTemplate.replace("#QUESTIONNAIRE#", questionnaireName);
      message.message = messageTemplate;
    }

    if (message.type == 'Q-EXPIRING') {
      let leaverName = notification.name;
      let questionnaireName = this.languageService.extractNameLanguage(notification.questionnaireName, language);
      let messageTemplate: string =this.translationPipe.transform('txt_notification_questionnaire_expiring', language, this.ModuleNames.Common) ;
      messageTemplate = JSON.parse(JSON.stringify(messageTemplate));
      messageTemplate = messageTemplate.replace("#LEAVERNAME#", leaverName);
      messageTemplate = messageTemplate.replace("#QUESTIONNAIRE#", questionnaireName);
      message.message = messageTemplate;
    }

    if (message.type == 'Q-EXPIRED') {
      let leaverName = notification.name;
      let questionnaireName = this.languageService.extractNameLanguage(notification.questionnaireName, language);
      let messageTemplate: string =this.translationPipe.transform('txt_notification_questionnaire_expired', language, this.ModuleNames.Common) ;
      messageTemplate = JSON.parse(JSON.stringify(messageTemplate));
      messageTemplate = messageTemplate.replace("#LEAVERNAME#", leaverName);
      messageTemplate = messageTemplate.replace("#QUESTIONNAIRE#", questionnaireName);
      message.message = messageTemplate;
    }

    return message;
  }


}
