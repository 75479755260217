<div class="flex flex-col gap-2 w-full relative minw-44">
    <h5 *ngIf="label" class="text-xs text-zen font-medium truncate whitespace-nowrap">{{ label }} <i *ngIf="ngControl.control.hasValidator(Validators?.required)"
            class="fa-regular fa-asterisk text-xxs"></i></h5>
    <div [ngClass]="{'opacity-50 pointer-events-none':ngControl.control.disabled,'border-zen-red': ngControl.control.errors && ngControl.control.touched, 'border-zen border-opacity-25': !ngControl.control.errors || !ngControl.control.touched}"
        class="text-sm rounded text-zen border bg-white  p-3  gap-3 leading-9 truncate flex flex-row justify-between items-center">
        <div class="flex flex-row items-center w-full">
            <textarea  [maxlength]="maxCount"  type="text" [placeholder]="placeholder?placeholder:''"
                class="text-sm text-zen w-full h-full border-none bg-transparent" [formControl]="ngControl.control"
                (ngModelChange)="handleChange($event)"
                ></textarea>
        </div>


    </div>
    <h4 *ngIf="maxCount <= ngControl.control.value?.length && maxCount"
        [ngClass]="{'text-zen-red':  ngControl.control.value?.length >= ngControl.control.errors?.maxlength?.requiredLength}" class="text-xxs">
        {{maxCount}}/{{ngControl.control.value?.length}}</h4>
</div>
