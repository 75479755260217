/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Entity } from '../models/Entity';
import type { Tenant } from '../models/Tenant';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class FirebaseMappingService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns Tenant Success
     * @throws ApiError
     */
    public mappingTenants(): Observable<Array<Tenant>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/firebase-mapping/mapping-tenants',
        });
    }

    /**
     * @returns Entity Success
     * @throws ApiError
     */
    public mappingTenantEntities(): Observable<Array<Entity>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/firebase-mapping/mapping-tenant-entities',
        });
    }

}
