import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DataBindingDirective, GridComponent } from "@progress/kendo-angular-grid";
import { LanguageService } from "../../../core/services/language.service";
import { process } from "@progress/kendo-data-query";
import { inOutAnimation } from "../../animations/allAnimations";
import { images } from "./images";
import { MatTableDataSource } from "@angular/material/table";
import { ExportService } from "../../../core/services/export.service";
import { ModuleName } from "../../enums/ModuleName";
import { TranslatePipe } from "../../pipes/translate.pipe";

@Component({
  selector: 'data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  animations: [
    inOutAnimation
  ]
})
export class DataGridComponent implements OnInit {

  constructor(private languageService: LanguageService, private excelExportService: ExportService, private translationPipe: TranslatePipe) {

  }


  @ViewChild('tableList') tableList: ElementRef;


  // btnSave:string;
  // btn_cancel:string;
  // txttableofcontents:string;
  // txt_answers:string;

  data;
  columns;
  dataSource;
  open;
  @Input('language') language: string;
  @Input('currentSelection') currentSelection: string;
  @Input('data') set dataArray(data: any) {
    this.data = data;
  }

  @Input('open') set openValue(val) {
    this.open = val;

    this.columns = [];
    this.columns.push(this.translationPipe.transform("txt_answers", this.language, this.ModuleNames.Shared));

    if (this.data?.bar.series[0].name) {
      let columnHeads = this.data.bar.series?.map(function (element) {
        return element.name
      })
      this.columns = this.columns.concat(columnHeads);
    }

    this.columns.push(this.translationPipe.transform("txtValue", this.language, this.ModuleNames.Shared) + '#');
    this.columns.push(this.translationPipe.transform("txtValue", this.language, this.ModuleNames.Shared) + '%');
    this.dataSource = new MatTableDataSource(this.data?.categories);
    // this.log(this.data);
    // this.log(this.currentSelection);
  }
  @Output() openChange = new EventEmitter();

  ModuleNames = ModuleName

  // setUpLanguagePreference() {

  //   this.setUpTextFields();
  // }

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }

  checkIndexOf(value, text) {
    let nValue = value.toLowerCase();
    if (nValue.includes(text) > 0)
      return true;
    else return false;
  }

  returnDate(date) {
    return new Date(date).toLocaleDateString('en-GB');
  }

  public ngOnInit(): void {
    this.gridView = this.data;
    // this.setUpTextFields();
  }
  public onFilter(inputValue: string): void {
    this.gridView = process(this.data, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "full_name",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "job_title",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "budget",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "phone",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "address",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }


  public mySelection: string[] = [];
  public gridView: any[];


  exportTable() {
    let name;
    if (this.currentSelection)
      name = this.translationPipe.transform("txtAnswersBy", this.language, this.ModuleNames.Shared) + this.currentSelection
    else name = this.translationPipe.transform("txtAllAnswers", this.language, this.ModuleNames.Shared);

    this.excelExportService.exportTableElmToExcel(this.tableList['_elementRef'], name);
  }


  photoURL(dataItem: any): string {
    const code: string = dataItem.img_id + dataItem.gender;
    const image: any = images;

    return image[code];
  }

  flagURL(dataItem: any): string {
    const code: string = dataItem.country;
    const image: any = images;

    return image[code];
  }
  log(data) {
    console.log(data);

  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }


  getPercentage(data, value) {
    let filteredData = data.filter(f => f.Group == value).length;
    if (!filteredData)
      filteredData = data.filter(f => f.Group.trim() == value).length;

    return filteredData * 100 / data.length;
  }
  getGroup(category, list) {
    let groupedList = list.filter(d => d.Category == category);
    return groupedList;
  }


  toFixed(number, digits) {
    return parseFloat(number).toFixed(digits);
  }
  getQuantityValue(category, data) {
    let newDill = data;
    let count = newDill.filter(d => d.Category == category).length;
    return count;

  }
  percentageValue(category, data) {
    let count = data.filter(d => d.Category == category).length * 100 / data.length;
    return count;

  }

}