import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExitNewComponent } from '@ex/module/exit/exit-new.component';
import { AnalysisComponent } from '@ex/module/exit/features/analysis/analysis.component'
import { ListComponent } from '@ex/module/exit/features/list/list.component';
import { TemplatesComponent } from '@ex/module/exit/features/templates/templates.component';
import { LeaverDetailsComponent } from './features/leaver-details/leaver-details.component';



const routes: Routes = [
  {
    path: '', component: ExitNewComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: "analysis", component: AnalysisComponent, data: { title: "Employee Exit" }
      },
      {
        path: "templates", component: TemplatesComponent, data: { title: "Employee Exit" }
      },
      { path: 'leaver/:id', component: LeaverDetailsComponent, data: { animation: 3, title: "Employee Exit" } },
      {
        path: "home", component: ListComponent, data: { title: "Employee Exit" }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExitNewRoutingModule { }
