import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExitBaseComponent } from '../exit-base/exit-base.component';
import { LeaverDto, LeaverListFilterKeys, LeaversService } from '@ex/exit/leavers';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { Router } from '@angular/router';
import animations from '../../utils/animations';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { NewLeaverComponent, NewLeaverModalData } from '../../features/new-leaver/new-leaver.component';
import { EntityGroup } from 'src/app/core/data-models/entity-group';
import { Entity } from 'src/app/core/data-models/Entity';
import { JsonToExcelComponent } from 'src/app/shared/export-components/json-to-excel/json-to-excel.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { GlobalStatusService, Status } from 'src/app/core/services/global-status.service';


@Component({
  selector: 'leavers-list',
  templateUrl: './leavers-list.component.html',
  styleUrls: ['./leavers-list.component.scss'],
  animations: animations,
  providers: [DatePipe, DecimalPipe]
})
export class LeaversListComponent extends ExitBaseComponent implements OnInit {
  constructor(private router: Router
    , public globalStatus: GlobalStatusService
    , private leaversService: LeaversService
    , private responseDialogService: ResponseDialogService
    , private datePipe: DatePipe, private decimalPipe: DecimalPipe) {
    super()
  }

  ngOnInit(): void {

  }
  @Input() surveyKey;

  @Input() companyKey;

  @Input() tenantKey;
  @Input() entities;
  @Input() currentPage;

  leavers: LeaverDto[] = []

  @Input("leavers")
  set leaversList(leavers) {

    if (!leavers)
      return;


    this.leavers = leavers;
    if (!this.firstInitiation)
      this.initiateTable(leavers);
    else
      this.reloadTable(leavers);
  }

  @Input() selectedEntity: Entity;
  @Input() allSendReminderCount

  @Input() totalRecords = 0

  @Input() language = "EN"

  @Input() page = 1

  @Input() pageSize = 5

  @Output() filterChange = new EventEmitter();

  @Output() pageChange = new EventEmitter();

  @Output() onEditLeaver = new EventEmitter();

  @Input() tourKey;

  filterText = ""
  tableEntityGroups: EntityGroup[] = [];
  selectedLeavers: any[] = [];

  activeColumns = [];
  allColumns = [];
  firstInitiation: boolean = false;
  options: TableOptions



  onFilterChange(filter) {
    if (filter.length > 3 || filter.length == 0) {
      this.filterChange.emit(filter);
    }
  }

  onPageChange(page) {
    this.page = page;
    this.pageChange.emit(this.page);
  }

  async bulkSendReminder() {

    let confirmed = await this.responseDialogService.confirmation(
      'txt_bulk_reminders',
      'txt_delete_confirmation_subject',
      'txt_send',
      'btn_cancel',
      this.ModuleNames.Exit,
      this.language
    );

    if (confirmed) {
      this.LoaderService.displayLoader()
      const subscription = this.leaversService.sendReminders({
        isSendAll: true,
        surveyKey: this.surveyKey,
        tenantKey: this.tenantKey,
        companyKey: this.companyKey
      }).subscribe(_ => {
        this.LoaderService.hideLoader()
        this.SnackbarService.success(this.localize("txt_ReminderSent", this.language, this.ModuleNames.Exit))
      });

      this.addSubscription(subscription)
    }
  }

  sendReminder(leaver) {
    this.LoaderService.displayLoader()
    const subscription = this.leaversService.sendReminders({
      isSendAll: false,
      leaverKey: leaver.key,
      surveyKey: this.surveyKey,
      tenantKey: this.tenantKey,
      companyKey: this.companyKey
    }).subscribe(_ => {
      this.LoaderService.hideLoader()
      this.SnackbarService.success(this.localize("txt_ReminderSent", this.language, this.ModuleNames.Exit))
    });

    this.addSubscription(subscription)
  }

  async deleteLeaver(leaver: LeaverDto) {
    let confirmed = await this.responseDialogService.confirmation(
      'txt_delete_leaver',
      'txt_delete_confirmation_subject',
      'txt_okay_action',
      'btn_cancel',
      this.ModuleNames.Exit,
      this.language
    );

    if (confirmed) {
      this.LoaderService.displayLoader()

      const subscription = this.leaversService.delete(leaver.key).subscribe(_ => {
        this.LoaderService.hideLoader()
      })

      this.addSubscription(subscription);
    }
  }

  downloadExcell() {
    const subscription = this.leaversService.getList({
      companyKey: this.companyKey,
      surveyKey: this.surveyKey,
      tenantKey: this.tenantKey,
      isAllResults: true,
      skipCount: 0,
      maxResultCount: 10,
      filters: [{
        key: LeaverListFilterKeys.Status,
        value: Status.All,
      }]
    }).subscribe(response => {

      const selectedColumns = this.allColumns.filter(x => x.isSelected && x.field != "empty");

      let records = response.items.map(record => {
        let newRecord = {};
        selectedColumns.forEach(column => {
          if (column.field == 'status') {
            newRecord['status'] = this.localize(`txt_${record['status'].toUpperCase()}`, this.language, this.ModuleNames.Shared)
          }
          else if (column.field == 'seperationType') {
            newRecord['seperationType'] = this.localize(`${record['seperationType'].toLowerCase()}`, this.language, this.ModuleNames.Exit)
          }
          else if (column.field == 'tenure') {
            newRecord['tenure'] = this.toFixed(record['tenure'], 1)
          }
          else if (column.field.includes('Date')) {
            newRecord[column.field] = this.datePipe.transform(record[column.field], 'dd/MM/yyyy');
          } else if (column.metric) {
            newRecord[column.title] = ""
            const metric = record?.extraCriterias.find(x => x.key == column.key);

            if (metric)
              newRecord[column.title] = this.ExtractPipe.transform(this.returnMetricName(metric, this.selectedEntity.metrics), this.language);
          }
          else {
            newRecord[column.grouped ? column.title : column.field] = column.grouped ? this.extractHierarchyEntityName(record.entityHierarchy, column.groupkey) : record[column.field];
          }
        })
        return newRecord;
      });



      this.ZenCDKService.openComponent(JsonToExcelComponent, {
        data: records,
        name: this.localize('txt_respondents_list', this.language, this.ModuleNames.Exit)
      })
    })

    this.addSubscription(subscription);
  }

  leaversSelected(leavers) {

  }

  initiateTable(leavers: LeaverDto[]) {

    this.allColumns = [
      { field: 'employeeId', title: this.localize("txtID", this.language, this.ModuleNames.Exit), isSelected: true },
      { field: 'name', title: this.localize("txt_leaver_employee_name", this.language, this.ModuleNames.Exit), isSelected: true },
      { field: 'tenure', title: this.localize("txtTenure", this.language, this.ModuleNames.Exit), isSelected: true },
      { field: 'seperationDate', title: this.localize("txt_leaver_seperation_date", this.language, this.ModuleNames.Exit), isSelected: true },
      { field: 'seperationType', title: this.localize("txt_leaver_seperation_type", this.language, this.ModuleNames.Exit), isSelected: true },
      { field: 'status', title: this.localize("txt_status", this.language, this.ModuleNames.Exit), isSelected: true },
      { field: 'email', title: this.localize("txt_leaver_email", this.language, this.ModuleNames.Exit), isSelected: false },
    ];

    if (this.selectedEntity?.EntityGroups) {
      this.tableEntityGroups = [];
      for (let i = 0; i < this.selectedEntity.EntityGroups.length; i++) {
        let eg = this.selectedEntity.EntityGroups[i];
        if (i <= 3 && !eg.isCompanyGroup) {
          this.tableEntityGroups.push(eg);
          this.allColumns.push({
            grouped: true,
            groupkey: eg.groupKey,
            field: 'entity' + i,
            title: this.ExtractPipe.transform(eg.name, this.language),
          });
        }
      }
      for (let i = 0; i < this.selectedEntity.metrics?.length; i++) {
        this.allColumns.push({
          metric: true,
          key: this.selectedEntity.metrics[i].key,
          field: 'metric' + i,
          title: this.ExtractPipe.transform(this.selectedEntity.metrics[i].name, this.language),
        });
      }

    }

    this.allColumns = this.allColumns.concat([{ field: 'empty', title: '', isSelected: true, nonSelectable: true }]);

    this.activeColumns = this.allColumns.filter(x => x.isSelected);
    this.firstInitiation = true;
    this.options = {
      data: leavers,
      columns: this.activeColumns,
      searchText: this.localize("txtFindleavers", this.language, this.ModuleNames.Exit),
      colors: this.GlobalService.basicColors,
      exportText: this.localize("btnExport", this.language, this.ModuleNames.Exit),
      exportName: this.localize("txt_activeleaverlist_export", this.language, this.ModuleNames.Exit),
      export: false,
      sticky: true,
      customize: true,
      disableSearch: true,
      customizeText: this.localize("txtCustomize", this.language, this.ModuleNames.Exit),
      whiteBackground: true,
      hidePagination: true,
      serverSide: false,
    }
  }

  reloadTable(leavers: LeaverDto[]) {
    if (this.selectedEntity?.EntityGroups) {
      this.tableEntityGroups = [];
      for (let i = 0; i < this.selectedEntity.EntityGroups.length; i++) {
        let eg = this.selectedEntity.EntityGroups[i];
        if (i <= 3 && !eg.isCompanyGroup) {
          this.tableEntityGroups.push(eg);
          if (!this.allColumns.find(x => x.field == 'entity' + i)) {
            this.allColumns.push({
              grouped: true,
              groupkey: eg.groupKey,
              field: 'entity' + i,
              title: this.ExtractPipe.transform(eg.name, this.language),
            });
          }
        }
      }
      for (let i = 0; i < this.selectedEntity.metrics?.length; i++) {
        if (!this.allColumns.find(x => x.field == 'metric' + i)) {
          this.allColumns.push({
            metric: true,
            key: this.selectedEntity.metrics[i].key,
            field: 'metric' + i,
            title: this.ExtractPipe.transform(this.selectedEntity.metrics[i].name, this.language),
          });
        }
      }
    }
    this.options.columns = this.allColumns.filter(x => x.isSelected);
    this.options.data = leavers
    this.options = JSON.parse(JSON.stringify(this.options));
  }


  updateTable() {
    let lastColumn = this.allColumns.find(x => x.field == 'empty');
    this.allColumns.push(this.allColumns.splice(this.allColumns.indexOf(lastColumn), 1)[0]);
    this.options.columns = this.allColumns.filter(x => x.isSelected);
    this.options = JSON.parse(JSON.stringify(this.options));
  }

  editLeaver(leaver) {
    this.onEditLeaver.emit(leaver);
  }

  viewAnswers(leaver) {
    this.router.navigateByUrl('/exit-v2/leaver/' + leaver.key)
  }

  isShowSendReminder(leaver: LeaverDto) {
    return leaver.status == this.RespondentStatus.Pending || leaver.status == this.RespondentStatus.Progress
  }

  isShowDelete(leaver: LeaverDto) {
    return leaver.status != this.RespondentStatus.Completed;
  }

  isShowViewAnswers(leaver: LeaverDto) {
    return leaver.status == this.RespondentStatus.Completed;
  }

  extractHierarchyEntityName(hierarchy: any[], groupKey: string) {
    let group: any = hierarchy?.find((eg) => eg.key == groupKey);
    if (group) {

      let entity: Entity = group.entities.find(
        (e) => e.key == group.selectedEntityKey
      );
      if (entity) {
        return this.ExtractPipe.transform(entity.name, this.language);
      }
    }

    return "-";
  }

  returnMetricName(metric, metrics) {
    return metrics?.find((e) => e.key == metric.key).options?.find(option => option.key == metric.value)?.name;
  }

  returnTenureField(value: number) {
    if (value >= 1)
      return this.decimalPipe.transform(value, '1.0-0') + ' ' + this.localize('txt_years', this.language, this.ModuleNames.Exit);
    value = (value * 365) / 30;
    if (value >= 1)
      return this.decimalPipe.transform(value, '1.0-0') + ' ' + this.localize('txtMonths', this.language, this.ModuleNames.Exit);
    value = (value * 30);
    if (value >= 1)
      return this.decimalPipe.transform(value, '1.0-0') + ' ' + this.localize('txtDays', this.language, this.ModuleNames.Exit);
    return  this.decimalPipe.transform(value, '1.0-0');
  }
}
