import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CreatorBase, editorLocalization, localization } from "survey-creator-core";
import { BaseAngular } from "survey-angular-ui";
import { Direction } from '@angular/cdk/bidi';
import { LocalService } from '@ex/module/core/services/local.service';
import "survey-core/survey.i18n";
import "survey-creator-core/survey-creator-core.i18n";


const translations = localization.getLocale("");
translations.ed.surveyPlaceHolder = "This form is empty. Please, add some questions.";
translations.ed.newPageName = "Section";
translations.pe.pageTitlePlaceholder = "Section {num}";



const curStrings = editorLocalization.getLocale("");
curStrings.ed.newPageName = "Section";
curStrings.pe.pageTitlePlaceholder = "Section {num}";


@Component({
  selector: 'app-custom-form-builder',
  templateUrl: './custom-form-builder.component.html',
  styleUrls: ['./custom-form-builder.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFormBuilderComponent extends BaseAngular<CreatorBase> implements AfterContentChecked, OnInit {

  @Input() creator !: CreatorBase;
  @Input() companyName;
  @Output() creatorChange = new EventEmitter();
  language: any;
  @Input('language') set lang(lang: any) {
    this.language = lang;
    //this.changeDetectorRef.detectChanges();
  }
  textPosition: any;
  direction: Direction = "ltr"

  constructor(
    private localService: LocalService,
    protected override changeDetectorRef: ChangeDetectorRef // Make sure to include this line
  ) {
    super(changeDetectorRef);
  }

  container!: ElementRef<HTMLDivElement>;
  showTopBar: boolean = false;

  protected override getModel(): CreatorBase {
    this.creatorChange.emit(this.creator);
    return this.creator;
  }

  protected override  onModelChanged(): void { }

  override ngOnInit(): void {

    if (this.language == "AR") {
      translations.survey.duplicate = "نسخة طبق الأصل";
      translations.pe.delete = "حذف";
    }


  }

  ngAfterContentChecked() {
  //  this.changeDetectorRef.detectChanges();
  }
}
