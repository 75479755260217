import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'z-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  dir;
  constructor() { }

  ngOnInit(): void {
    if(document.body.dir == "rtl"){
      this.dir = 'rtl';
      console.log(this.dir)
      }
   
  }
  displayYear() {
    return new Date().getFullYear();
  }
}
