import { Injectable } from '@angular/core';
import { SectionDto } from '@ex/exit/survey-templates';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface AnalyticsFilter {
  survey: {
    name: string,
    key: string,
    sections: SectionDto[];
  };
  tenantKey: string;
  companyKey: string;
  showExtraFactors?: boolean,
  seperationType: string[],
  timeframe?: TimeFrameFilter,
  chartFilters?: ChartFilter[];
  entityId?: string[];
}

export interface ChartFilter {
  key: string;
  value: any;
}

export interface TimeFrameFilter {
  startDate: Date;
  endDate: Date;
}


export enum AnalysisFilterKeys {
  TimeFrame = "Timeframe",
  EntityId = "Entity",
  Gender = "Gender",
  PerformanceRating = "PerformanceRate",
  Nationality = "Nationality",
  Generation = "Generation",
  AgeGroups = "AgeGroup",
  TenureGroups = "TenureGroup",
  BandLevel = "BandLevel",
  JobGrade = "JobGrade",
  Criteria = "criteria",
  IncludeExtraDrivers = "ExtraDriver",
  DateOfBirth = "DOB",
  DateOfJoining = "JOD",
  SeperationType = "SeperationType",
  SeperationDate = "SeperationDate"
}


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private filters = new BehaviorSubject<AnalyticsFilter>(null);
  filters$ = this.filters.asObservable();

  private dymmyFilters = new Subject<AnalyticsFilter>();
  dummyFilters$ = this.dymmyFilters.asObservable();

  constructor() { }

  setFilters(filters: AnalyticsFilter, isDummy = true) {

    if (isDummy)
      this.dymmyFilters.next(filters);
    else
      this.filters.next(filters);
  }

  mapGlobalFilters(filters: AnalyticsFilter) {
    let globalFilters: ChartFilter[] = [{ key: AnalysisFilterKeys.IncludeExtraDrivers, value: filters.showExtraFactors || false }];


    if (filters.timeframe
      && (filters.timeframe.startDate != null && filters.timeframe.endDate != null)
      && (filters.timeframe.startDate < filters.timeframe.endDate)) {
      globalFilters.push({
        key: AnalysisFilterKeys.TimeFrame,
        value: filters.timeframe
      })
    }

    if (filters.seperationType && filters.seperationType.length) {
      globalFilters.push({
        key: AnalysisFilterKeys.SeperationType,
        value: filters.seperationType
      })
    }

    if (filters.entityId?.length) {
      globalFilters.push({
        key: AnalysisFilterKeys.EntityId,
        value: filters.entityId
      })
    }

    return globalFilters;
  }


}