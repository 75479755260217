import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Dashlet } from '../../core/data-models/dashlet';
import { isNumber, isNull } from 'util';
import { LegendLabelsContentArgs } from '@progress/kendo-angular-charts';
import { QuestionAnalysis } from '../../core/data-models/question-analysis';
import { LanguageService } from '../../core/services/language.service';
import { DashletDataService } from '../../core/services/dashlet-data.service';
import { MatDialog } from '@angular/material/dialog';
import { DrillDownDialogComponent } from '../drill-down-dialog/drill-down-dialog.component';
import { ChildrenAnimation, exitAnimation, fadeUpDown, listAnimationFast } from '../animations/allAnimations';
import { GeneralService } from '../../core/services/general.service';
import { LocalService } from '../../core/services/local.service';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { ModuleName } from '../enums/ModuleName';
import { IKeyName } from 'src/app/core/data-models/ikey-name';

enum ExportMode {
  Landscape = 'landscape',
  Portrait = 'portrait'
}

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  animations: [
    fadeUpDown,
    listAnimationFast,
    ChildrenAnimation,
    trigger('category', [
      transition(':enter', [
        style({ transform: 'translateX(-100px)', opacity: 0 }),
        animate('0.7s cubic-bezier(0.87, 0, 0.13, 1)', style({ transform: 'translateX(0px)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({
          transform: 'translateX(0px)',
          opacity: 1,

        }),
        animate(
          '0.7s cubic-bezier(0.87, 0, 0.13, 1)',
          style({ transform: 'translateX(-100px)', opacity: 0 })
        ),
      ]),
    ]),

    trigger('list', [
      transition('* => *', [ // each time the binding value changes
        query(':enter', [
          style({ opacity: 0, transform: 'scale(0.8)', height: '0px' }),
          stagger(50, [
            animate('0.7s cubic-bezier(0.87, 0, 0.13, 1)', style({ opacity: 1, transform: 'scale(1)', height: '13.875rem' }))
          ])
        ], { optional: true }),
        query(':leave', [
          stagger(50, [
            animate('0.7s cubic-bezier(0.87, 0, 0.13, 1)', style({ opacity: 0, transform: 'scale(0.8)', height: '0px' }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})

export class ChartComponent implements OnInit, AfterViewInit {
  @Input() chartTypeTour;
  @Input() DemoGroupingLimit: number = 0;
  ModuleNames = ModuleName
  group = 'nationality';
  criteriaSelection;
  showDetails = -1;
  chartType = false;
  @Input() colors = ['#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be',
    '#ff6358', '#ffd246', '#78d237', '#28b4c8', '#2d73f5', '#aa46be'];

  @Input() hasCustomQuantity = true;
  @Input('wrapRows') wrapRows = false
  @Input('title') title;
  @Input() export: boolean = false;
  @Input('exportMode') set mode(mode) {
    if (mode == ExportMode.Portrait) {
      this.exportMode = mode;
      this.exportPageRows = 20;
    }
  }
  exportPageRows: number = 13;
  exportMode;
  tableData;
  currentSelection;
  tableView;
  columns = [];
  reverse: boolean = false;
  dashlet: Dashlet;
  data: any;
  drillDown;
  filteredCategories: any = [];
  allTableData;
  selectedData: any = [];
  selectionDetailView = false;
  selectionValue;
  dataCopy: any;
  dataSecond: any;
  customData: any;
  renderChart: boolean = true;
  currencySymbol: string = '$';
  majorGridLines: { color: string; visible: boolean; }[];
  majorGridLinesY: { color: string; visible: boolean; }[];
  majorGridLinesX: { color: string; visible: boolean; }[];
  selectedTab: number = 0;
  fixedGroupingOptions: any[] = [
    { key: 'BandLevel', name: [{ key: 'EN', name: 'Band Level' }, { key: 'AR', name: 'المستوى الوظيفي' }] },
    { key: 'PerformanceRate', name: [{ key: 'EN', name: 'Performance Rate' }, { key: 'AR', name: 'الأداء' }] },
    { key: 'JobGrade', name: [{ key: 'EN', name: 'Job Grade' }, { key: 'AR', name: 'الدرجة الوظيفية' }] },
    { key: 'Gender', name: [{ key: 'EN', name: 'Gender' }, { key: 'AR', name: 'الجنس' }] },
    { key: 'Nationality', name: [{ key: 'EN', name: 'Nationality' }, { key: 'AR', name: 'الجنسية' }] },
    { key: 'AgeGroup', name: [{ key: 'EN', name: 'Age Group' }, { key: 'AR', name: 'المجموعة العمرية' }] },
    { key: 'SeperationType', name: [{ key: 'EN', name: 'Seperation Type' }, { key: 'AR', name: 'نوع الفصل' }] },
    { key: 'TenureGroup', name: [{ key: 'EN', name: 'Tenure Group' }, { key: 'AR', name: 'مجموعة سنوات الخدمة' }] },
    { key: 'Generation', name: [{ key: 'EN', name: 'Generation' }, { key: 'AR', name: 'الجيل' }] },
    { key: 'Yearly', name: [{ key: 'EN', name: 'Yearly' }, { key: 'AR', name: 'سنوي' }] },
    { key: 'Quarterly', name: [{ key: 'EN', name: 'Quarterly' }, { key: 'AR', name: 'ربع سنوي' }] },
    { key: 'Monthly', name: [{ key: 'EN', name: 'Monthly' }, { key: 'AR', name: 'شهري' }] },

  ];

  chartTypes = ['barH', 'barV', 'pie', 'stackH', 'stackV', 'radarArea', 'radarColumn', 'line', 'table'];
  // txtGroupBy: string;
  // txtChartType: string;
  // txtClearGrouping: string;
  emptyColor: string;
  @Input('language') language: string;
  @Input() showDrillDown: boolean = false;
  @Input('customChart') customChart: boolean = false;
  @Input('basicColors') basicColors: boolean = false;

  @Input()
  includeCriteriaKeys = [];

  groupingOptions: any[] = [];
  dataFilters: any;

  @Output() exportPageLength: EventEmitter<any> = new EventEmitter();
  @Input('filterYear') set filterYear(year: any) {

  }

  splitChart: boolean = false;
  splitChartIndex: number = -1;
  decimalPlaces: number;
  dataType: string;
  defaultColors = [];
  @Input('chartColors') set givenColors(colors: any) {
    if (colors)
      this.chartColors = colors;
  };
  chartColors;
  disableChart: boolean = false;
  @Input('entityGroups') entityGroups: any[] = [];
  @Input('hideHeader') hideHeader: boolean = false;
  @Input('disableChartTypes') disableChartTypes: boolean = false;
  @Input('disableChartTitle') disableChartTitle: boolean = false;
  @Input('showValues') showValues: boolean = false;
  @Input('decimals') decimals: number = 1;

  @Input('currencySymbol') set currency(symbol: string) {
    this.currencySymbol = symbol;
  }
  @Input('dataSymbol') dataSymbol: string = '';
  @Input('chartColor') chartColor: string;
  @Input('disableGrouping') disableGrouping: boolean = false;
  @Input('reportsPage') reportsPage: boolean;
  @Input('fullView') fullView: boolean;
  @Input('question') question: QuestionAnalysis;
  @Input('dashletConfigData') dashletConfigData: any;
  @Input('dashlet') set dashletConfig(dashlet: Dashlet) {
    this.dashlet = dashlet;
    if (this.dashlet?.DataType) {
      let extractDecimals = this.dashlet.DataType.split('.');
      if (extractDecimals.length > 1) {
        this.dataType = extractDecimals[0];
        this.decimalPlaces = Number(extractDecimals[1]);
        this.dataFormat = "{0:n" + this.decimalPlaces + "}";
      } else {
        this.dataType = this.dashlet.DashletType;
        this.dataFormat = "{0:n0}";
      }
    }
    if (this.dashlet?.DashletType == 'Custom-JobGrade-Salary')
      this.prepareCustomDashlet();
  }
  @Output() drillDownIntiated = new EventEmitter();
  @Output() empProfileInitiated = new EventEmitter();
  @Output() groupChanged = new EventEmitter();
  @Output() clearGroup = new EventEmitter();
  @Output() dashletChartTypeOverride = new EventEmitter();

  @ViewChild('divToMeasure', { static: false }) divToMeasureElement: ElementRef;
  componentTextList: any[] = [];

  exportInitiated: boolean = false;
  chartWidth: number;
  @Input('exportInitiated') set exportChart(exportCharts: boolean) {
    if (exportCharts)
      this.exportInitiated = exportCharts;
  }

  @Input()
  additionalGroupingOptions: IKeyName[] = [];

  chartTypeOverride(event) {
    this.dashletChartTypeOverride.emit(event);
  }

  isEven(n) {
    return n % 2 == 0;
  }

  reverseTable(array) {
    console.log(array);

    if (this.reverse)
      this.reverse = false;
    else {
      array.sort((a, b) => parseFloat(a.count) - parseFloat(b.count));
      this.reverse = true;

    }
    array.reverse();
  }
  applyMajorUnity: boolean = false;
  majorUnit: number;
  emptyUnit: number;
  setUpLanguagePreference() {
    // this.componentTextList = this.languageService.componentText.filter(country => country.language == this.language);

    // this.setUpTextFields();
  }

  @Input('dashletData') set dashletData(data: any) {
    if (!data)
      return;
    if (this.dashlet?.DashletType == 'barH' && !this.customChart && data.groupedChart)
      this.dashlet.DashletType = 'stackH';

    let maxNumber: number = 0;
    for (let i = 0; i < data?.bar?.series.length; i++) {
      let serie = data.bar?.series[i];
      let serieData = serie?.data;
      for (let x = 0; x < serieData.length; x++) {
        let d = serieData[x];
        if (d > maxNumber)
          maxNumber = d;
      }
    }

    if (maxNumber < 6)
      this.majorUnit = 1;
    else
      this.majorUnit = this.emptyUnit;

    this.disableChart = false;
    this.data = data;
    let forceCustom = false;
    if (data?.categories.length > 5) {
      for (let index = 0; index < data?.categories.length && !forceCustom; index++) {
        const element = data?.categories[index];
        if (element.length > 25) {
          forceCustom = true;
        }
      }
    }
    if (this.customChart)
      this.filteredCategories = this.filterFilteredCategories(data);

    if (data?.categories?.length > 10 || forceCustom) {
      this.customChart = true;
      this.disableChart = true;
      this.dashlet.DashletType = data.bar.series.length > 1 ? 'stackH' : 'barH';

      this.filteredCategories = this.filterFilteredCategories(data);
      let pageCount = Math.ceil((this.filteredCategories.length + 3) / this.exportPageRows);
      this.exportPageLength.emit(pageCount);
    }


    if (!this.selectionDetailView)
      this.drillDown = this.data?.drillDown;


    this.columns = [];
    if (this.data?.radar) {
      for (const key in this.data?.radar?.data[0]) {
        this.columns.push({
          field: key,
          title: key,
        })
      }
      this.tableData = this.data?.radar?.data;
    }

    this.allTableData = this.data?.drillDown?.map(x => x?.leaver);

    // this.columns = Object.keys(this.data?.radar?.data[0]);
    // this.tableData = this.data?.radar?.data;
    // console.log(this.columns);
    // console.log(this.tableData);


  }

  filterFilteredCategories(data) {
    let filteredCategories = [...data?.categories];

    for (let index = 0; index < filteredCategories.length; index++) {
      filteredCategories[index] = {
        value: filteredCategories[index], count: this.getQuantityValue(filteredCategories[index],
          this.data.drillDown), data: { value: data.bar.series.map(s => s.data[index]), name: data.bar.series.map(s => s.name) }
      };
    }

    if (!data?.groupedChart)
      filteredCategories.sort((a, b) => parseFloat(b.data.value[0]) - parseFloat(a.data.value[0]));
    console.log(filteredCategories);
    let newArr = filteredCategories.filter(category => category.data.value[0] > 0);
    return data.bar.series.length == 1 ? newArr : filteredCategories;
  }

  // Governmental Grid Related
  actions: any = [{ ID: 1, name: 'Issue' }, { ID: 2, name: 'Renew' },
  { ID: 3, name: 'Modify' }, { ID: 4, name: 'Cancel' }];
  docs: any = [{ ID: 1, name: 'Passport' }, { ID: 2, name: 'Civil ID' }, { ID: 3, name: 'Driving License' },
  { ID: 4, name: 'Entity License' }, { ID: 5, name: 'Vehicle License' }, { ID: 6, name: 'Bank Card' }, { ID: 7, name: 'Credit Card' }];
  statuses: any = [{ ID: 1, name: 'Not Started' }, { ID: 2, name: 'In Progress' },
  { ID: 3, name: 'Complete' }, { ID: 4, name: 'Overdue' }];
  priorities: any = [{ ID: 1, name: 'Low' }, { ID: 2, name: 'Normal' }, { ID: 3, name: 'High' }]
  criterias: any = [{ ID: -1, name: 'Select Criteria' }, { ID: 1, name: 'Document' }, { ID: 2, name: 'Progress' }, { ID: 3, name: 'Activity Type' }, { ID: 4, name: 'Priority' }, { ID: 5, name: 'Expiry' }];
  notificationValues: any[];
  filterType: string;
  filterValue: string;
  selectedFilterType: string;
  reRenderGovGrid: boolean = false;
  dateRange: string = 'Day';
  dateValue: string;
  dataReady: boolean = false;
  dateRanges: any[] = [{ ID: 1, name: 'Day' }, { ID: 2, name: 'Month' }, { ID: 3, name: 'Year' }];
  filterReady: boolean = true;
  transitionLeft: number = -1;
  transitionUp: number = -1;
  cardWidth: number;
  dataFormat: string = "{{ (dashlet.DataType === 'currency' ? currencySymbol : '' ) }} {0:n0} {{ dashlet.DataType === 'number' ? '' : (dashlet.DataType === 'percentage' ? ' %' : '' ) }}";
  seriesFormat: string;
  // ------------------------
  chartTimer;
  constructor(private dashletDataService: DashletDataService, public matDialog: MatDialog,
    private languageService: LanguageService, private generalService: GeneralService, private localService: LocalService) {
    this.pieLabelContent = this.pieLabelContent.bind(this);
    this.labelContent = this.labelContent.bind(this);

  }
  ngAfterViewInit(): void {
    this.chartTimer = setTimeout(() => {
      this.cardWidth = this.divToMeasureElement?.nativeElement.offsetWidth;
    }, 500);

  }




  secondPage() {
    this.transitionLeft = 1;
    this.transitionUp = 1;
  }

  firstPage() {
    this.transitionLeft = 0;
    this.transitionUp = 0;
  }

  setupDecimalPlaces(data: string, format?: boolean) {

    let isnum = Number(data);
    if (!isNaN(isnum)) {

      let returnValue: string;
      returnValue = Number(data).toFixed(this.decimalPlaces);
      if (format)
        returnValue = formatNumber(returnValue);
      return returnValue
    }
    return data;
  }

  clearGrouping() {
    this.dashlet.SelectedGroupKey = '';
    this.dashlet.CategoryAxisTitle = '';
    this.selectionDetailView = false;
    this.showDetails = -1;
    this.clearGroup.emit();
    this.drillDown = this.data?.drillDown;
  }

  selectedGroupingChanged(event) {
    console.log(event);

    this.selectionDetailView = false;
    //  this.data  = this.selectedData
    // this.dashlet.CategoryAxisTitle = this.extractNameLanguage(this.fixedGroupingOptions.find(g => g.key == event.value).name);
    this.showDetails = -1;
    this.groupChanged.emit(event);
  }

  filterTypeSelected(event) {
    if (event.value == 'Document') {
      this.selectedFilterType = 'Document';
      this.notificationValues = this.docs;
    }
    if (event.value == 'Progress') {
      this.selectedFilterType = 'Progress';
      this.notificationValues = this.statuses;

    }
    if (event.value == 'Activity Type') {
      this.selectedFilterType = 'Activity Type';
      this.notificationValues = this.actions;

    }
    if (event.value == 'Priority') {
      this.selectedFilterType = 'Priority';
      this.notificationValues = this.priorities;
    }
    if (event.value == 'Expiry') {
      this.selectedFilterType = 'Expiry';
    }


  }

  dateRangeChanged(event) {
    let today = new Date();


    let day = today.getUTCDate();
    let month = (today.getUTCMonth() + 1);
    let year = today.getUTCFullYear();
    this.reRenderGovGrid = true;

    this.rowData = [];
    for (let i = 0; i < this.filteredRowData.length; i++) {
      let row = this.filteredRowData[i];
      let rowDate = new Date(row.ExpiryDate);
      let rowDay = rowDate.getDate();
      let rowMonth = (rowDate.getMonth() + 1);
      let rowYear = rowDate.getFullYear();

      let subtractedDate = new Date();
      if (this.dateRange == 'Day') {
        subtractedDate.setDate(subtractedDate.getDate() + Number(this.dateValue));
      }
      if (this.dateRange == 'Month') {
        subtractedDate.setDate(subtractedDate.getDate() + (Number(this.dateValue) * 30));
      }
      if (this.dateRange == 'Year') {
        subtractedDate.setDate(subtractedDate.getDate() + (Number(this.dateValue) * 365));
      }



      if (rowDate <= subtractedDate) {
        this.rowData.push(row);
      }


    }
    this.setUpGovRows();

    setTimeout(() => {
      this.reRenderGovGrid = false;
    }, 200);

  }

  clearFilters() {
    this.rowData = JSON.parse(JSON.stringify(this.filteredRowData));
    this.setUpGovRows();
    this.drillDown = this.data?.drillDown;
    this.filterType = 'Select Criteria';
    this.selectionDetailView = false;
    this.selectedFilterType = null;
  }

  filterSelected(event) {
    this.filterValue = event.value;
    this.reRenderGovGrid = true;
    this.rowData = [];
    for (let i = 0; i < this.filteredRowData.length; i++) {
      let row = this.filteredRowData[i];
      if (this.selectedFilterType == 'Document') {
        if (row.DocumentType.includes(this.filterValue)) {
          this.rowData.push(row);
        }
      }
      if (this.selectedFilterType == 'Progress') {
        if (row.Progress.includes(this.filterValue)) {
          this.rowData.push(row);
        }
      }
      if (this.selectedFilterType == 'Activity Type') {
        if (row.ActivityType.includes(this.filterValue)) {
          this.rowData.push(row);
        }
      }
      if (this.selectedFilterType == 'Priority') {
        if (row.Priority.includes(this.filterValue)) {
          this.rowData.push(row);
        }
      }
    }
    setTimeout(() => {
      this.reRenderGovGrid = false;
    }, 200);
  }


  getSerieColor(name, index?) {
    // console.log(name);
    // console.log(this.chartColors);

    if (this.chartColors?.length > 0) {
      let sColor = this.chartColors.find(c => c.key == name);

      if (sColor)
        return sColor.color;
    }
    return this.colors[index];
  }

  returnMaxValue(series) {
    let value = 0;
    for (let index = 0; index < series.length; index++) {
      for (let i = 0; i < series[index].data.length; i++) {
        let max = Math.max(series[index].data[i]);
        if (max > value)
          value = max;
      }
    }

    return value > 60 ? 100 : null;
  }

  getToolTip(value) {
  }

  public pointColor = (point): string => {
    // this is what i want to return ...
    return point.dataItem.performanceColor;
  }

  // public onRender = (args: any): void => {
  //   const chart = args.sender;
  //   const geom = geometry;
  //   const draw = drawing;

  //   // get the axes
  //   const yAxis = chart.findAxisByName("yAxis");
  //   const xAxis = chart.findAxisByName("xAxis");

  //   // get the coordinates of the value at which the plot band will be rendered
  //   const valueRange = yAxis.range();
  //   const ySlot = yAxis.slot(valueRange.min, valueRange.max);

  //   // get the coordinates of the entire category axis range
  //   const range = xAxis.range();
  //   const xSlot = xAxis.slot(range.min, range.max);

  //   const difference = xAxis.options.majorUnit * this.data?.AdditionalStep;

  //   var min = range.min + difference;
  //   var max = range.max - difference;

  //   var negativeSlot = xAxis.slot(range.min, min);
  //   var positiveSlot = xAxis.slot(max, range.max);
  //   var withinSlot = xAxis.slot(min, max);


  //   var rectBottomBelowSalaryScale = new geom.Rect([
  //     xSlot.origin.x, xSlot.origin.y - 40

  //   ], [
  //     negativeSlot.width(), 40 + ySlot.origin.y
  //   ]);

  //   var rectBottomWithinSalaryScale = new geom.Rect([
  //     (xSlot.origin.x + negativeSlot.width()), xSlot.origin.y - 40

  //   ], [
  //     withinSlot.width(), 40 + ySlot.origin.y
  //   ]);

  //   var rectBottomBeyondSalaryScale = new geom.Rect([
  //     (xSlot.origin.x + negativeSlot.width() + withinSlot.width()), xSlot.origin.y - 40

  //   ], [
  //     positiveSlot.width(), 40 + ySlot.origin.y
  //   ]);

  //   var rectBelowSalaryScale = new geom.Rect([
  //     xSlot.origin.x, ySlot.origin.y
  //   ], [
  //     negativeSlot.width(), negativeSlot.origin.y
  //   ]);

  //   var rectWithinSalaryScale = new geom.Rect([
  //     (xSlot.origin.x + negativeSlot.width()), ySlot.origin.y
  //   ], [
  //     withinSlot.width(), withinSlot.origin.y
  //   ]);

  //   var rectBeyondSalaryScale = new geom.Rect([
  //     (xSlot.origin.x + negativeSlot.width() + withinSlot.width()), ySlot.origin.y
  //   ], [
  //     positiveSlot.width(), positiveSlot.origin.y
  //   ]);



  //   var pathBelowSalaryScale = draw.Path.fromRect(rectBelowSalaryScale, {
  //     stroke: null,
  //     fill: new LinearGradient({
  //       stops: [
  //         new GradientStop(1, "#DF7878", 0.15)
  //       ]
  //     }),
  //   });

  //   var pathWithinSalaryScale = draw.Path.fromRect(rectWithinSalaryScale, {
  //     stroke: null,
  //     fill: new LinearGradient({
  //       stops: [
  //         new GradientStop(1, "#7AD171", 0.15),
  //       ]
  //     })
  //   });

  //   var pathBeyondSalaryScale = draw.Path.fromRect(rectBeyondSalaryScale, {
  //     stroke: null,
  //     fill: new LinearGradient({
  //       stops: [
  //         new GradientStop(1, "#66ACE1", 0.15)]
  //     })
  //   });

  //   var pathBottomBelowSalaryScale = draw.Path.fromRect(rectBottomBelowSalaryScale, {
  //     stroke: null,
  //     fill: new LinearGradient({
  //       stops: [
  //         new GradientStop(1, "#DF7878", 1)
  //       ]
  //     })
  //   });

  //   var pathBottomWithinSalaryScale = draw.Path.fromRect(rectBottomWithinSalaryScale, {
  //     stroke: null,
  //     fill: new LinearGradient({
  //       stops: [
  //         new GradientStop(1, "#7AD171", 1),
  //       ]
  //     })
  //   });

  //   var pathBottomBeyondSalaryScale = draw.Path.fromRect(rectBottomBeyondSalaryScale, {
  //     stroke: null,
  //     fill: new LinearGradient({
  //       stops: [
  //         new GradientStop(1, "#66ACE1", 1)]
  //     })
  //   });

  //   var group = new draw.Group();
  //   group.append(pathBelowSalaryScale, pathWithinSalaryScale, pathBeyondSalaryScale);

  //   var rectBottomGroup = new draw.Group();
  //   rectBottomGroup.append(pathBottomBelowSalaryScale, pathBottomWithinSalaryScale, pathBottomBeyondSalaryScale);

  //   chart.surface.draw(rectBottomGroup);
  //   chart.surface.draw(group);

  // }

  // PieClick() {
  //   this.dashlet.DashletType = 'Pie';
  //   this.data = this.analysisService.mapServiceCaller(this.dashlet.DashletService);
  // }

  // BarClick(){
  //   this.dashlet.DashletType = 'Bar';
  // }

  // StackClick(){
  //   this.dashlet.DashletType = 'Stack';
  // }

  // LineClick(){
  //   this.dashlet.DashletType = 'Line';
  // }

  // AreaClick(){
  //   this.dashlet.DashletType = 'Area';
  // }

  // ScatterClick(){
  //   this.dashlet.DashletType = 'Scatter';
  // }


  pieSeriesClick(event) {
    if (!this.disableGrouping || this.showDrillDown) {
      // let information = this.data?.drillDown.find(x => x.name == event.category).drillDown;
      // let drillDownInfo = new DrillDownInformation();
      // drillDownInfo.information = information;
      // drillDownInfo.name = this.dashlet.Title + ' - ' + event.category;
      let drillDown = this.dashletDataService.extractDrillDown({ category: event.category }, this.dashlet.Title, this.data);
      const searchDialogRef = this.matDialog.open(DrillDownDialogComponent, { data: { data: drillDown, language: this.language }, autoFocus: false, panelClass: 'customModal' });
    }
  }

  seriesClicked(event) {
    if (!this.disableGrouping || this.showDrillDown) {
      console.log(event)
      // let information: any;
      // let drillDownInfo = new DrillDownInformation();

      // if (this.data?.bar.series.length == 1) {
      //   information = this.data?.drillDown.find(x => x.name == event.category).drillDown;
      //   drillDownInfo.name = this.dashlet.Title + ': ' + event.category;
      // } else {
      //   information = this.data?.drillDown.find(x => x.name == event.category);
      //   information = information.seriesDrillDown.find(s => s.name == event.series.name).drillDown;
      //   drillDownInfo.name = this.dashlet.Title + ': ' + event.category + ' - ' + event.series.name;
      // }

      // drillDownInfo.information = information;

      let drillDown = this.dashletDataService.extractDrillDown({ category: event.category, group: event.series.name }, this.dashlet.Title, this.data);
      const searchDialogRef = this.matDialog.open(DrillDownDialogComponent, { data: { data: drillDown, language: this.language }, autoFocus: false, panelClass: 'customModal' });
    }
  }
  showDrill(item, drillD) {
    let currentDrill;
    if (item != 'item')
      currentDrill = drillD.filter(i => i.Group == item);
    else currentDrill = drillD;

    console.log(currentDrill);
    let drillDown = this.dashletDataService.extractDrillDown({ category: currentDrill[0].Category, group: currentDrill[0].Group }, this.dashlet.Title, this.data);
    const searchDialogRef = this.matDialog.open(DrillDownDialogComponent, { data: { data: drillDown, language: this.language }, autoFocus: false, panelClass: 'customModal' });


  }
  getConfidence(subquestionKey: string, key: string) {
    let value;
    if (subquestionKey == 'sub-questions-confidence-rating')
      value = this.generalService.confidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-likeness-rating')
      value = this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-likeness-na-rating')
      value = this.generalService.likingRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'sub-questions-binary-likeness-rating')
      value = this.generalService.binaryLikeness.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'single-answer-rating')
      value = this.generalService.singleAnswerConfidenceRating.find(cf => { return (cf.key == key && cf.lang == this.language) }).name;
    if (subquestionKey == 'single-answer-out-10')
      value = this.generalService.singleAnswerOutof10.find(cf => { return (cf.key == key) }).name;

    return value;
  }
  getConfidenceUnresolved(name, requested) {
    let value;
    if (this.generalService.confidenceRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })) {
      value = this.generalService.confidenceRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })[requested];
    }
    else if (this.generalService.confidenceHappinessRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })) {
      value = this.generalService.confidenceHappinessRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })[requested];
    }

    else if (this.generalService.confidenceRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })) {
      value = this.generalService.confidenceRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })[requested];
    }
    else if (this.generalService.likingRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })) {
      value = this.generalService.likingRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })[requested];
    }
    else if (this.generalService.likingRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })) {
      value = this.generalService.likingRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })[requested];
    }
    else if (this.generalService.binaryLikeness.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })) {
      value = this.generalService.binaryLikeness.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })[requested];
    }
    else if (this.generalService.singleAnswerConfidenceRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })) {
      value = this.generalService.singleAnswerConfidenceRating.find(cf => { return (cf.name.trim() == name?.trim() && cf.lang == this.language) })[requested];
    }
    else if (this.generalService.singleAnswerOutof10.find(cf => { return (cf.name.trim() == name?.trim()) })) {
      value = this.generalService.singleAnswerOutof10.find(cf => { return (cf.name.trim() == name?.trim()) })[requested];
    }



    return value;

  }

  public weatherData = [
    { windSpeed: 16.4, rainfall: 5.4 },
    { windSpeed: 21.7, rainfall: 2 },
    { windSpeed: 25.4, rainfall: 3 },
    { windSpeed: 19, rainfall: 2 },
    { windSpeed: 10.9, rainfall: 1 },
    { windSpeed: 13.6, rainfall: 3.2 },
    { windSpeed: 10.9, rainfall: 7.4 },
    { windSpeed: 10.9, rainfall: 0 },
    { windSpeed: 10.9, rainfall: 8.2 },
    { windSpeed: 16.4, rainfall: 0 },
    { windSpeed: 16.4, rainfall: 1.8 },
    { windSpeed: 13.6, rainfall: 0.3 },
    { windSpeed: 29.9, rainfall: 0 },
    { windSpeed: 27.1, rainfall: 2.3 },
    { windSpeed: 16.4, rainfall: 0 },
  ];

  //public categoryPlotBands: any[] = [{
  //  from: 280,
  //  to: 320,
  //  color: '#ffe5e5',
  //  opacity: 1
  //}, {
  //  from: 3,
  //  to: 6,
  //  color: '#e7efe5',
  //  opacity: 1
  //}, {
  //  from: 6,
  //  to: 9,
  //  color: '#ebfafa',
  //  opacity: 1
  //}];

  public categories: any[] = [];

  public valuePlotBands: any[] = [{
    from: 2,
    to: 3,
    color: '#78d237',
    opacity: 0.3
  }];

  public categoryPlotBands: any[] = [{

  }];

  prepareCustomDashlet() {
    if (this.data) {
      if (this.data?.Series.length > 0) {
        let employeeData: any[] = [];
        for (let i = 0; i < this.data?.Series.length; i++) {
          let empData = this.data?.Series[i];
          employeeData.push({
            value: Number(empData.BasicSalary.toFixed(0)), placement: i + 1, performanceColor: '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6), empID: empData.EmployeeId, nationality: empData.Nationality, jobGrade: empData.JobGradeName,
            basicSalary: empData.BasicSalary.toFixed(0), jobStatus: empData.JobStatus, payZone: empData.PayZone, fullName: empData.FullName, jobTitle: empData.JobTitleName, performanceRating: '3',
            tenure: empData.Tenure.toFixed(1) + ' Years', joiningDate: '01 January 2018', fromMed: empData.DifferenceFromMedian.toFixed(0) + ' (' + empData.PercentageFromMedian.toFixed(0) + '%)',
            fromMax: empData.DifferenceFromMax.toFixed(0) + ' (' + empData.PercentageFromMax.toFixed(0) + '%)', imgURL: '120002.jpg', entities: empData.EntitiesHierarchyList, entity: empData.EntityName, entityGroups: this.entityGroups
          });
        }
        this.customData = employeeData;

        this.majorGridLinesY = [{
          color: 'black',
          visible: false
        }];

        this.categoryPlotBands = [{
          from: this.data?.Min,
          to: this.data?.Max
        }];
        //this.categoryPlotBands = [{
        //  from: this.data?.Min,
        //  to: this.data?.Labels.find(l => l.Label == '0 %').Value,
        //  color: '#e64e4e',
        //  opacity: 0.2
        //}, {
        //  from: this.data?.Labels.find(l => l.Label == '0 %').Value,
        //  to: this.data?.Labels.find(l => l.Label == '100 %').Value,
        //  color: '#3eb75b',
        //  opacity: 0.2
        //}, {
        //  from: this.data?.Labels.find(l => l.Label == '100 %').Value,
        //  to: this.data?.Max,
        //  color: '#1678c2',
        //  opacity: 0.2
        //}];
      }
    }
  }

  getDashletValue1Title(dashletValue1Title: string) {
    if (dashletValue1Title != "" && dashletValue1Title != null) {

      let title = dashletValue1Title.replace("{Year}", this.dashlet.dateFilter.year);
      title = title.replace("{Month}", this.dashlet.dateFilter.month);

      return title;
    } else {
      return "";
    }
  }


  public pieLabelContent(args: LegendLabelsContentArgs): string {
    let x = args.dataItem.value.toFixed(1).toString();
    let dataType = this.dataType;
    if (dataType == 'percentage')
      dataType = ' %';
    else
      dataType = '';
    x = x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return args.dataItem.name + ": " + x + this.dataSymbol;
  }


  numberWithCommas(x) {
    if (Number(x) < 1000)
      return x.toString();
    return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  customSalaryScaleClick(event) {
    this.empProfileInitiated.emit(event.dataItem);
  }

  public toolTipContent(e) {
    let empData = this.data?.Series[e.y - 1];
    let stringFormat = `
      <div class="k-tooltip-content salary-analysis-tooltip">
        <h6>ID: ${empData.EmployeeId}</h6>
        <h4>${empData.FullName}</h4>
        <span>Basic Salary: ${empData.BasicSalary.toFixed(0)}</span>
        <span>From Max: ${empData.PercentageFromMax.toFixed(0)} % - ${empData.DifferenceFromMax.toFixed(0)}</span>
        <span>From Median: ${empData.PercentageFromMedian.toFixed(0)} % - ${empData.DifferenceFromMedian.toFixed(0)}</span>
      </div>`;
    return stringFormat;
  }

  public labelContent = (e: any) => {

    let lbl = this.data?.Labels.find(l => {
      let value = (Number(e.value) + 2);
      let value2 = (Number(e.value) - 2);
      let labelNumber = Number(l.Value);
      if (labelNumber < value && labelNumber > value2)
        return true;
      else
        return false;
    });

    if (lbl) {
      let label = lbl.Label;
      if (label == "0 %") {
        return "Min \n" + this.numberWithCommas(e.value.toFixed(0));
      } else if (label == '50 %') {
        return "Median \n" + this.numberWithCommas(e.value.toFixed(0));
      } else if (label == "100 %") {
        return "Max \n" + this.numberWithCommas(e.value.toFixed(0));
      } else {
        return label + "\n" + this.numberWithCommas(e.value.toFixed(0));
      }
    } else {

    }
  }

  getFormat(event) {
  }

  columnDefs: any = [];
  defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true
  };

  public gridApi;
  public gridColumnApi;
  rowData: any[];
  filteredRowData: any[];
  autoGroupColumnDef: any;

  initializeGrid() {
    this.data?.Columns.forEach(config => {
      var properties = Object.getOwnPropertyNames(config);
      if (properties.includes("Columns") && !isNull(config.Columns) && config.Columns.length > 0) {
        var innerNode: any = [];
        config.Columns.forEach(subColumns => {
          if (this.dashlet.DashletType == 'governmental-grid' && (subColumns.Title == "Progress" || subColumns.Title == "Priority")) {
            innerNode.push({
              headerName: subColumns.Title,
              field: subColumns.Field,
              cellRenderer: (cell) => {
                var progress = cell.value
                if (progress == 'Complete') {
                  return '<i class="fas fa-check-circle" style="color: #3eb75b; font-size: 16px;"></i>'
                }
                if (progress == 'Overdue') {
                  return '<i class="fas fa-times-circle" style="color: #e64e4e; font-size: 16px;"></i>'
                }
                if (progress == 'In Progress') {
                  return '<i class="fas fa-clock" style="color: #ff9000; font-size: 16px;"></i>'
                }
                if (progress == 'Not Started') {
                  return '<i class="fas fa-hourglass-start" style="color: #858494; font-size: 16px;"></i>'
                }
                if (progress == 'High') {
                  return '<span style="color: #e64e4e; font-weight: 500;"><i class="fas fa-circle"></i> High</span>'
                }
                if (progress == 'Normal') {
                  return '<span style="color: #ff9000; font-weight: 500;"><i class="fas fa-circle"></i> Normal</span>'
                }
                if (progress == 'Low') {
                  return '<span style="color: #1678c2; font-weight: 500;"><i class="fas fa-circle"></i> Low</span>'
                }
              }
            });
          } else {
            innerNode.push({
              headerName: subColumns.Title,
              field: subColumns.Field
            });
          }
        });
        this.columnDefs.push({
          headerName: config.Title,
          children: innerNode
        });
      } else {
        var found = false;
        this.data?.Aggregates.forEach(aggregation => {
          if (aggregation.Field == config.Field) {
            found = true;
            var dataSymbol = "";
            if (aggregation.DataType) {
              if (aggregation.DataType == "currency")
                dataSymbol = this.currencySymbol;
              if (aggregation.DataType == "percentage") dataSymbol = "%";
            }
            this.columnDefs.push({
              headerName: config.Title,
              field: config.Field,
              valueFormatter: function (params) {
                return dataSymbol + formatNumber(params.value);
              },
              aggFunc: aggregation.Aggregate.toLowerCase(),
              filter: "agNumberColumnFilter",
              cellRenderer: "agGroupCellRenderer",
              cellRendererParams: {
                footerValueGetter: function (params) {
                  return aggregation.Aggregate == "avg"
                    ? "Average " + dataSymbol + params.value
                    : "Total " +
                    aggregation.Aggregate +
                    " " +
                    dataSymbol +
                    params.value;
                }
              },
              cellClass: "grid-numbers-alignment",
            });
          }
        });

        var currencySymbol = this.currencySymbol;
        if (!found) {
          this.columnDefs.push({
            headerName: (config.Title === '{Entity}' ? (this.dashlet.SelectedEntityGroupID ? (this.entityGroups.find(x => x.ID == this.dashlet.SelectedEntityGroupID).Name) : config.Title) : config.Title),
            field: config.Field,
            valueFormatter: function (params) {
              if (isNumber(params.value) && config.Format.includes("amount")) {
                if (config.Format.split('.').length == 1)
                  return currencySymbol + ' ' + formatNumber(params.value.toFixed(0));
                else
                  return currencySymbol + ' ' + formatNumber(params.value.toFixed(config.Format.split('.')[1]));
              }
              else if (isNumber(params.value) && config.Format.includes("percentage")) {
                if (config.Format.split('.').length == 1)
                  return formatNumber(params.value.toFixed(0)) + ' %';
                else
                  return formatNumber(params.value.toFixed(config.Format.split('.')[1])) + ' %';
              }
              else if (isNumber(params.value) && config.Format.includes("number")) {
                if (config.Format.split('.').length == 1)
                  return formatNumber(params.value.toFixed(0));
                else
                  return formatNumber(params.value.toFixed(config.Format.split('.')[1]));
              }
              else { return params.value };
            },
          });
        }
      }
    });

    this.filteredRowData = this.data?.Data;
    this.rowData = JSON.parse(JSON.stringify(this.filteredRowData));

    if (this.dashlet.DashletType == 'governmental-grid') {
      this.setUpGovRows();
    }

    this.dataReady = true;
  }

  dashletInitiated = false;

  ngOnInit() {

    this.fixedGroupingOptions = JSON.parse(JSON.stringify(this.generalService.fixedGroupingOptions.filter(x => this.includeCriteriaKeys.includes(x.key))));
    this.fixedGroupingOptions = this.fixedGroupingOptions.concat(this.additionalGroupingOptions);

    for (let i = 0; i < this.entityGroups.length; i++) {
      let group = this.entityGroups[i];
      this.groupingOptions.push({ key: group.groupKey, name: group.name });
    }
    for (let i = 0; i < this.fixedGroupingOptions.length; i++) {
      let group = this.fixedGroupingOptions[i];
      this.groupingOptions.unshift({ key: group.key, name: group.name });
    }
    this.language = this.localService.getJsonValue('language');
    this.setUpLanguagePreference();
    this.dashletInitiated = true;
  }

  setUpGovRows() {
    for (let i = 0; i < this.rowData.length; i++) {
      let issueDate = new Date(this.rowData[i].IssueDate);
      let expiryDate = new Date(this.rowData[i].ExpiryDate);
      this.rowData[i].IssueDate = issueDate.getDate() + '-' + (issueDate.getMonth() + 1) + '-' + issueDate.getFullYear();
      this.rowData[i].ExpiryDate = expiryDate.getDate() + '-' + (expiryDate.getMonth() + 1) + '-' + expiryDate.getFullYear();

    }
  }

  getPercentage(data, value) {
    let filteredData = data.filter(f => f.Group == value).length;
    if (!filteredData)
      filteredData = data.filter(f => f.Group.trim() == value).length;

    return filteredData * 100 / data.length;
  }

  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }

  getContextMenuItems = (params) => {
    var result = [
      {
        name: 'Set Notification',
        action: () => {
          this.openNotificationSettingDialog(params.node.data.DocID, params.node.data.Name, params.node.data.DocumentType);
        },
        cssClasses: ['redFont', 'bold'],
      }
    ];
    return result;
  }

  openNotificationSettingDialog(docID: number, docOwner: string, docType: string) {
    // const governmentalNotificationSettingsDialog = this.matDialog.open(GNotificationSettingComponent, { data: { isDashlet: true, docID: docID, title: docOwner + "'s " + docType }, autoFocus: false });
    // governmentalNotificationSettingsDialog.afterClosed().subscribe((response: any) => {
    //   if (response) {

    //     if (response.Result == 'SUCCESS') {
    //       let snackBarMessage: string;
    //       snackBarMessage = 'Notification set on ' + docOwner + "'s " + docType;
    //       this.openSnackBar(snackBarMessage);

    //     } else {

    //       let subject: string = 'Oops!';
    //       let message: string = 'Something went wrong. Please try again.';
    //       let action: string = 'Okay';
    //       let type: string = 'error';
    //       this.matDialog.open(MessageDialogComponent, { data: { message: message, subject: subject, action: action, type: type }, autoFocus: false });
    //     }
    //   }
    // });
  }

  getRadarSeriesName(event) {
    // let x = Describer.describe(a);
    let seriesNames = Object.getOwnPropertyNames(event);
    let indexOfSeries = this.data?.radar.abbrvSeries.findIndex(x => x == seriesNames[0]);
    return this.data?.bar.series[indexOfSeries].name;
  }

  getRadarSeriesClicked(event) {
    let seriesNames = Object.getOwnPropertyNames(event.dataItem);
    let indexOfSeries = this.data?.radar.abbrvSeries.findIndex(x => x == seriesNames[0]);

    // this.drillDownIntiated.emit({ category: event.category, group: this.data?.bar.series[indexOfSeries].name });

    let drillDown = this.dashletDataService.extractDrillDown({ category: event.category, group: this.data?.bar.series[indexOfSeries].name }, this.dashlet.Title, this.data);
    const searchDialogRef = this.matDialog.open(DrillDownDialogComponent, { data: { data: drillDown, language: this.language }, autoFocus: false, panelClass: 'customModal' });
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  }
  onRowDoubleClicked(event: any) {
    alert(event.data.EmployeeID);
  }

  exportRowsCSV() {
    var params = {
      suppressQuotes: "\t",
      columnSeparator: "\t"
    };
    this.gridApi.exportDataAsCsv(params);
  }
  exportRowsExcel() {
    var columnWidth = 150;
    var params = {
      columnWidth: columnWidth,
      sheetName: "Report export",
      exportMode: undefined,
      suppressTextAsCDATA: false,
      rowHeight: undefined,
      headerRowHeight: undefined,
      customHeader: [
        [],
        [
          {
            styleId: "bigHeader",
            data: {
              type: "String",
              value: "content appended with customHeader"
            },
            mergeAcross: 3
          }
        ],
        [
          {
            data: {
              type: "String",
              value: "Numeric value"
            },
            mergeAcross: 2
          },
          {
            data: {
              type: "Number",
              value: "3695.36"
            }
          }
        ],
        []
      ],
      customFooter: [
        [],
        [
          {
            styleId: "bigHeader",
            data: {
              type: "String",
              value: "content appended with customFooter"
            }
          }
        ]
      ]
    };
    this.gridApi.exportDataAsExcel(params);
  }


  formatNumber(num) {

    return num
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  logData(data) {
    console.log(data);

  }
  getQuantityValue(category, data) {
    let newDill = data;
    let count = newDill?.filter(d => d.Category == category)?.length;
    return count;

  }
  percentageValue(category, data) {
    let count = data?.filter(d => d.Category == category)?.length * 100 / data?.length;
    return count;

  }
  returnGroupName(groupNameKey) {
    let group = this.groupingOptions.find(q => q.key == groupNameKey);
    return this.extractNameLanguage(group.name);

  }
  getGroup(category, list) {
    let groupedList = list.filter(d => d.Category == category);
    return groupedList;
  }


  firstOfKind(group, item?) {
    let firstItem = [...new Set(group.map(obj => obj.Group))]
    return firstItem;
  }

  items;
  showRowDetails(items, n) {
    this.items = [];
    this.showDetails == n ? this.showDetails = -1 : this.showDetails = n;
    items.forEach(element => {
      this.items.push(element);
    });
  }

  filterData(data, category) {
    this.drillDown = this.data.drillDown.filter(d => d.Group == data);
    this.selectionValue = data;
    this.selectionDetailView = true;
    this.showDetails = -1;
  }
  removeSelectionDetailView(data) {
    console.log(this.selectedData.drillDown);
    this.drillDown = this.data?.drillDown;
    this.selectionDetailView = false;
  }


  itemNumber(item, getGroup, t?) {
    if (t)
      return (getGroup.filter(g => g.Group == item).length * 100) / getGroup.length;
    else
      return getGroup.filter(g => g.Group == item).length;

  }

  toFixed(number, digits) {
    return parseFloat(number).toFixed(digits);
  }


  closeDropdown(menu) {
    if (this[menu]) this[menu] = false;
  }

  returnCommentBreak(commentIndex, questionIndex?) {
    let rowsPerPage = this.exportMode == ''
    if (commentIndex == 0)
      return false;
    else if (commentIndex == this.exportPageRows - 3)
      return true;
    else if ((commentIndex > this.exportPageRows - 3) && ((commentIndex - 10) % this.exportPageRows) == 0)
      return true;
  }
  returnBackgroundColor(name, index) {
    //
    if (this.getConfidenceUnresolved(name[index], 'color'))
      return this.getConfidenceUnresolved(name[index], 'color')
    else if (this.chartColors?.length) {
      return this.chartColors.find(x => x.key == name).color || this.chartColors[index];
    }
    else return this.colors[index]
  }

}



function formatNumber(number) {
  return number
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
