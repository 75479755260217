<div class="flex flex-col gap-5 border-opacity-20 zen-main-container px-10 w-full pt-14 Page"
  (click)="logData(options)">
  <div class="flex flex-row justify-between items-center">
    <h2 [@first] class="text-zen font-normal text-3xl first">
      {{ "txt_SurveyLis_133" | translate : language : ModuleNames.Wellbeing }}
    </h2>
    <div class="flex flex-row gap-3 items-center">
      <a [@child2] (click)="createSurvey()" class="btn exp uppercase leading-12 rounded-sm px-5" [TourStep]="{
          title:
            'txt_NewSetup_163' | translate : language : ModuleNames.Wellbeing,
          text:
            'txt_Tocreatea_600' | translate : language : ModuleNames.Wellbeing,
          order: 1,
          tourKey: 'wellbeing-list',
          on: (language | language) ? 'right' : 'left'
        }">
        {{ "txt_NewSetup_163" | translate : language : ModuleNames.Wellbeing }}
      </a>
    </div>
  </div>
  <div class="flex flex-col items-stretch">
    <div class="flex flex-col justify-start min-h-screen-50">
      <div class="flex flex-col items-stretch w-full -mb-10">
        <div class="flex flex-row -mr-10 -ml-10 justify-between items-center h-20 px-10">
          <div class="flex flex-row items-stretch gap-6">
            <div class="flex flex-row items-stretch">
              <div
                class="text-sm rounded text-zen bg-white border-zen border border-opacity-10 px-3 h-9 gap-2 leading-9 truncate flex flex-row justify-start items-center">
                <i [ngClass]="{ 'rotate-h-45': (language | language) }" class="fal fa-search text-zen transform"></i>
                <input [formControl]="filterText" [placeholder]="
                    'txt_Search_185'
                      | translate : language : ModuleNames.Wellbeing
                  " class="bg-transparent overflow-hidden h-12 leading-12 text-sm w-80" type="text" />
              </div>
            </div>
            <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
            <a (click)="bulkRemove(selectedSurveys)" *ngIf="selectedSurveys?.length"
              class="btn text-zen font-medium text-sm flex flex-row gap-2 items-center">
              <i class="fa-regular fa-trash text-zen-red"></i>
              {{
              "txt_DeleteSel_329"
              | translate : language : ModuleNames.Wellbeing
              }}
            </a>
          </div>
          <div class="flex flex-col items-stretch justify-end w-48">
            <z-select [formControl]="statusFilter" [options]="statusList">
              <ng-template #valueTemplate let-option>
                {{
                option?.key == "ALL"
                ? option?.name
                : (option?.name | extract : language)
                }}
              </ng-template>
            </z-select>
          </div>
        </div>
        <div *ngIf="
            tableSelectAll &&
            totalRecords / pageSize > 1 &&
            selectedSurveys?.length
          " class="flex flex-row items-center justify-center h-15 bg-zen bg-opacity-10 rounded-md">
          <div *ngIf="allSelected" class="text-zen text-xs flex flex-row items-center gap-4">
            {{ replaceCount("txtAllSurveysSelected", canDeleteCount)
            }}<a zenButton (click)="clear()" styles="secondary height-9">{{
              "txt_ClearSele_284" | translate : language : ModuleNames.Wellbeing
              }}</a>
          </div>
          <h5 *ngIf="!allSelected" class="text-zen text-xs">
            {{
            replaceCount("txtSelectedSurveysOnPage", selectedSurveys?.length)
            }}
            <span (click)="selectAllSurveys()" class="text-exp btn">{{
              replaceCount("txtSelectAllFromPages", canDeleteCount)
              }}</span>
          </h5>
        </div>
      </div>

      <div class="flex flex-col items-stretch gap-5" *ngIf="surveyList?.length">
        <t-table (selectionChanges)="selectionChange($event)" [options]="options"
          (selectAll)="selectAllFromTable($event)">
          <ng-template #cellTempate let-element let-column="column" let-colIndex="colIndex">
            <ng-container *ngIf="column.field == 'status'; else title">
              <div (click)="logData(element)" class="flex flex-row gap-1 items-center text-sm" [ngClass]="{
                  'text-zen opacity-50': element.status == status.Draft,
                  'text-zen-green': element.status == status.Closed,
                  ' text-zen-red': element.status == status.Live
                }">
                <i class="" [ngClass]="{
                    'fa-regular fa-clock opacity-50':
                      element.status == status.Draft,
                    'far fa-check-circle  text-zen-green':
                      element.status == status.Closed,
                    'fas fa-circle  text-zen-red': element.status == status.Live
                  }"></i>
                <span>
                  {{
                  (getWellbeingStatus(element?.status)
                  | extract : language) || ""
                  }}</span>
              </div>
            </ng-container>
            <ng-template #title>
              <h5 (click)="logData(getWellbeingActionButton(element?.status))"
                *ngIf="column.field == 'title'; else score" class="max-w-xs truncate text-sm text-zen leading-20">
                {{ (element.title | extract : language) || "-" }}
              </h5>
            </ng-template>
            <ng-template #score>
              <ng-container *ngIf="column.field == 'score'; else creationTime">
                <h5 [ngClass]="
                    !element.score
                      ? 'empty'
                      : WbUtilities.returnColor(element.score)
                  " class="text-xs workscore rounded leading-7 w-15 text-center text-white font-normal">
                  {{
                  element.score
                  ? (element.score | number : "1.1-2") + "%"
                  : "-"
                  }}
                </h5>
              </ng-container>
            </ng-template>

            <ng-template #creationTime>
              <div *ngIf="column.field == 'creationTime'" class="h-18 flex flex-col justify-center">
                <h5 class="text-sm text-zen font-normal">
                  {{ element.creationTime | date : "dd/MM/yyyy" }}
                </h5>
              </div>
            </ng-template>
            <ng-container *ngIf="column.field == 'options'">
              <div class="flex flex-row justify-end items-center gap-2 h-full">
                <div class="flex flex-col gap-3 justify-center items-end px-5">
                  <a class="btn openPath whitespace-nowrap leading-9 px-4 rounded text-zen font-medium text-xs bg-zen bg-opacity-5 opacity-0 pointer-events-none"
                    [routerLink]="[returnRoute(element?.status), element.key]" [TourStep]="{
                      title:
                        'txt_OpenSurve_592'
                        | translate : language : ModuleNames.Wellbeing,
                      text:
                        'txt_Pointthem_434'
                        | translate : language : ModuleNames.Wellbeing,
                      order: 2,
                      tourKey: 'wellbeing-list',
                      on: (language | language) ? 'right' : 'left',
                      isRows: true
                    }">
                    <span>{{
                      (getWellbeingActionButton(element?.status)
                      | extract : language) || ""
                      }}
                      <i class="fas leading-9" [ngClass]="
                          (language | language)
                            ? 'fa-caret-left'
                            : 'fa-caret-right'
                        "></i></span>
                  </a>
                </div>
                <i [ZMenuTriggerFor]="menu"
                  class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full btn text-center"
                  [TourStep]="{
                    title:
                      'txt_DeleteSur_232'
                      | translate : language : ModuleNames.Wellbeing,
                    text:
                      'txt_Clickhere_208'
                      | translate : language : ModuleNames.Wellbeing,
                    order: 3,
                    tourKey: 'wellbeing-list',
                    on: (language | language) ? 'right' : 'left',
                    isRows: true
                  }"></i>
                <z-menu #menu>
                  <menuItems>
                    <a MenuItem (click)="editSurvey(element)">
                      <i class="fal fa-pen mx-1"></i>
                      {{
                      "txtRename"
                      | translate : language : ModuleNames.Wellbeing
                      }}
                    </a>
                    <a MenuItem delete *ngIf="
                        element.status != status.Closed &&
                        element.status != status.Live
                      " (click)="deleteSurvey(element)">
                      <i class="fal fa-trash mx-1"></i>{{
                      "btn_delete"
                      | translate : language : ModuleNames.Wellbeing
                      }}
                    </a>
                    <ng-container *ngIf="element.status == status.Closed">
                      <a MenuItem *DemoAccount="'Button'; let isDemo=isDemo"
                        (click)="isDemo?'':openWellbeingSetupPreviewDialog(element)">
                        <i class="fal fa-download mx-1"></i>
                        {{"btnExport"| translate:language:ModuleNames.Wellbeing }}
                      </a>
                    </ng-container>
                  </menuItems>
                </z-menu>
              </div>
            </ng-container>
          </ng-template>
        </t-table>
        <t-paginator *ngIf="options?.data?.length" [language]="language" [page]="page" [options]="options"
          [pageSize]="pageSize" [totalRecords]="totalRecords || 0" (pageChanged)="onPageChange($event)"></t-paginator>
      </div>
      <mini-loader *ngIf="!surveyList && surveyList?.length != 0"></mini-loader>
      <EmptySection [@child5] *ngIf="surveyList?.length == 0"></EmptySection>
    </div>
  </div>
</div>
<product-tour *ngIf="options" [key]="'wellbeing-list'" [offset]="60" [destroy]="true"></product-tour>