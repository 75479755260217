import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BulkAssignToSurveyDto, RespondentDto, RespondentService } from '@ex/wellbeing/respondents';
import { SurveyDto, SurveysService, SurveyUpdateDto } from '@ex/wellbeing/surveys';
import { filter, map, switchMap } from 'rxjs/operators';
import { Entity } from 'src/app/core/data-models/Entity';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { PortalDataResult } from 'src/app/shared/Theme/services/zen-cdk.service';
import { ChildrenAnimation, inOutAnimation, slidesAniamtion } from 'src/app/shared/animations/allAnimations';
import { MenuButton } from 'src/app/shared/Theme/components/common/menu-button/menu-button.component';
import { PredefinedMetricsService } from '../../data-access/services/predefined-metrics.service';
import { RespondentTableFetchObject, RespondentsTableService } from '../../data-access/services/respondents-table.service';
import { WbEmailTemplatesService } from '../../data-access/services/wb-email-templates.service';
import { WbReminderModalComponent } from '../../ui/wb-reminder-modal/wb-reminder-modal.component';
import { WbRespondentsModalComponent } from '../../ui/wb-respondents-modal/wb-respondents-modal.component';
import { WbSurveyPreviewComponent } from '../../ui/wb-survey-preview/wb-survey-preview.component';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { WellbeingService } from '../../data-access/services/wellbeing.service';
import { CreditBalanceDialogComponent } from 'src/app/shared/credit-balance-dialog/credit-balance-dialog.component';
import { GenerateEntityHierarchyService } from '../../data-access/services/generate-entity-hierarchy.service';
import { EntityGroup } from 'src/app/core/data-models/entity-group';
import { EmailTemplateDto, SurveyReminderDto } from '@ex/core/common';
import { EngagementRespondent } from '@ex/module/core/data-models/engagement-respondent';
import { RespondentService as RespondentPoolService } from 'src/app/core/services/respondent.service';
import { debug } from 'console';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
  animations: [inOutAnimation, ChildrenAnimation, slidesAniamtion],
  providers: [RespondentsTableService, WellbeingService, GenerateEntityHierarchyService]
})
export class WbSetupComponent extends WbBaseComponent implements OnInit {
  resetSelection;
  respondentAdd: boolean = false;
  constructor(private generateEntityHierarchyService: GenerateEntityHierarchyService
    , private respondentsTableService: RespondentsTableService
    , private responseDialogService: ResponseDialogService
    , public matDialog: MatDialog, private route: ActivatedRoute
    , private emailTemplatesService: WbEmailTemplatesService
    , private wellbeingService: WellbeingService
    , private router: Router
    , private surveysService: SurveysService
    , private respondentService: RespondentService
    , private defaultMetrics: PredefinedMetricsService
    , private respondentPoolService: RespondentPoolService) {
    super()
    this.addSubscription(this.EssentialObject$.pipe(filter((x: EssentialObject) => x.selectedEntity != null), switchMap((essentialObject: EssentialObject) => {

      return this.surveysService.get(this.route.snapshot.params?.id).pipe(map(survey => { return { essentialObject, survey } }))
    })).subscribe((data) => {
      this.essentialObject = data.essentialObject;
      this.allEntities = data.essentialObject.allEntities;
      this.selectedEntity = data.essentialObject.selectedEntity;
      this.language = data.essentialObject.language;
      this.surveyData = data.survey;
      this.user = data.essentialObject.user;
      this.tenant = data.essentialObject.tenant;
      this.tenantKey = this.tenant.key;
      this.companyKey = this.selectedEntity.key;
      this.entityGroups = data.essentialObject.selectedEntity.EntityGroups;
      this.setDates();
      this.LoaderService.hideLoader();
      this.fetchMetrics();
      this.populateTempalte();
      this.initTable();
    }));
  }
  initTable() {
    this.addSubscription(this.respondentsTableService.init({
      language: this.language,
      companyKey: this.selectedEntity.key,
      tenantKey: this.tenant.key,
      options: { disableSearch: true },
      survey: { key: this.surveyData.key, title: this.surveyData.title }
    }).subscribe((obj: RespondentTableFetchObject) => {
      this.options = obj.options;
      this.totalRecords = obj.totalRecords;
      this.currentPage = obj.pageNumber;
      this.pageSize = obj.pageSize;

      this.LoaderService.hideLoader();
    }))
  }
  setDates() {
    this.surveyData.startDate = new Date().toISOString();
    if (this.surveyData.reminders.length) {
      for (let index = 0; index < this.surveyData.reminders.length; index++) {
        if (new Date(new Date(this.surveyData.reminders[index].date).setHours(0, 0, 0, 0)).getTime() < new Date(new Date().setHours(0, 0, 0, 0)).getTime()) {
          this.surveyData.reminders.splice(this.surveyData.reminders.indexOf(this.surveyData.reminders[index]), 1);
          index--;
        }
      }
    }
    var startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    this.startReminderDate = startDate.toISOString();
    this.startReminderDate = this.surveyData.startDate.split('T')[0] <= new Date().toISOString().split('T')[0] ? this.startReminderDate : this.surveyData.startDate;
  }

  ngOnInit(): void {
    this.LoaderService.displayLoader();
    this.GlobalService.innerRoute = true;
    this.topValue = document.querySelector('.h-header')?.clientHeight / 16;

  }
  surveyData: SurveyDto;
  essentialObject: EssentialObject;
  topValue;
  submitted: boolean = false;
  selectedEntity: Entity;
  allEntities: Entity[];
  optionalMatrixList;
  searchText;
  language;
  tab = 0;
  user;
  tenant;
  totalRecords;
  options: TableOptions;
  displayedColumns;
  tenantKey;
  companyKey;
  pageSize = 5;
  currentPage = 1;
  today: Date = new Date();
  respondents: RespondentDto[] = [];
  reminder2: boolean = true;
  startReminderDate: string;
  entityGroups: EntityGroup[];


  menu: MenuButton[] = [{
    index: 0,
    icon: 'fa-building',
    name: 'txt_survey_info'
  },
  {
    index: 1,
    icon: 'fa-users',
    name: 'txt_analytical_criteria'
  }, {
    index: 2,
    icon: 'fa-sitemap',
    name: 'txt_respondents'
  },
  {
    index: 3,
    icon: 'fa-tachometer',
    name: 'txt_timeframe'
  },
  {
    index: 4,
    icon: 'fa-signal',
    name: 'txt_invitation_email'
  }
  ];

  populateTempalte() {
    if (!this.surveyData.invitationEmail) {
      this.surveyData.invitationEmail = {
        body: this.emailTemplatesService.getInvitationEmail(),
        subject: 'Employee Well-Being - Invitation'
      } as EmailTemplateDto;
    }
    if (!this.surveyData.reminderEmail) {
      this.surveyData.reminderEmail = {
        body: this.emailTemplatesService.getReminderEmail(),
        subject: 'Employee Well-Being - Reminder'
      } as EmailTemplateDto;
    }

  }
  // fetch data
  fetchMetrics() {
    let defaultMetrics: any[] = this.defaultMetrics.extractMetrics(this.selectedEntity, this.allEntities).map(x => {
      x.isCustom = false;
      return x;
    });
    if (!this.surveyData.surveyCriterias?.length)
      this.surveyData.surveyCriterias = defaultMetrics;
    else {
      let predefinedMetrics = this.surveyData.surveyCriterias.filter(x => !x.isCustom || defaultMetrics.find(m => m.key == x.key));
      if (defaultMetrics.length > predefinedMetrics.length) {
        let customMetrics = this.surveyData.surveyCriterias.filter(x => x.isCustom && !defaultMetrics.find(m => m.key == x.key))
        this.surveyData.surveyCriterias = defaultMetrics.concat(customMetrics);
      }
    }

    this.surveyData.entityHierarchy = this.generateEntityHierarchyService.getEnityGroupWithEntities(this.allEntities, this.entityGroups);
  }


  surveyUpdate: SurveyUpdateDto;
  updateSurvey(survey, index) {
    this.tab = index;
    this.surveyUpdate = {
      key: this.surveyData.key,
      companyKey: this.surveyData.companyKey,
      tenantKey: this.surveyData.tenantKey,
      title: this.surveyData.title,
      description: this.surveyData.description,
      startDate: this.surveyData.startDate,
      endDate: this.surveyData.endDate,
      noOfRespondents: this.surveyData.noOfRespondents,
      score: this.surveyData.score,
      status: this.surveyData.status,
      reminders: this.surveyData.reminders,
      selectedCriterias: this.surveyData.selectedCriterias,
      surveyCriterias: this.surveyData.surveyCriterias,
      invitationEmail: this.surveyData.invitationEmail,
      reminderEmail: this.surveyData.reminderEmail,
      surveyTemplate: this.surveyData.survey,
      entityHierarchy: this.surveyData.entityHierarchy,
      predefinedMetrics: this.surveyData.predefinedMetrics
    }
    this.surveysService.update(survey.key, this.surveyUpdate).subscribe(x => {
      console.log(x);

    })

  }

  currentIndex: number = -1;
  value1;
  resolveRoute(o) {
    if (o > this.currentIndex) {
      this.value1 = 'down';
    } else if (o < this.currentIndex) {
      this.value1 = 'up';
    }

    this.currentIndex = o;
    return this.value1;
  }

  addReminder() {

    if (!this.surveyData.startDate || !this.surveyData.endDate)
      return this.SnackbarService.error(this.localize("txt_start_and_endDate", this.language, this.ModuleNames.Engagement));
    let index = 0;
    if (!this.surveyData.reminders)
      this.surveyData.reminders = []
    if (this.surveyData.reminders)
      index = this.surveyData.reminders.length;
    let reminder: SurveyReminderDto = {
      date: '',
      isSent: false
    }

    if (this.surveyData.reminders.length < 3) {
      this.surveyData.reminders.push(reminder)
    }
  }

  getReminders(reminders, ignoreIndex) {
    if (reminders?.length) {
      let newReminders = reminders.filter((reminder, index) => index != ignoreIndex);
      if (newReminders?.length)
        return [...[this.surveyData.startDate, this.surveyData.endDate], ...newReminders.map(date => date.date)];
    }

  }
  onPageChange(e) {
    this.currentPage = e + 1;
    this.respondentsTableService.pageNumber.next(this.currentPage);
  }
  removeReminder(reminder) {
    const index = this.surveyData.reminders.indexOf(reminder);
    this.surveyData.reminders.splice(index, 1);

    this.SnackbarService.success(this.localize("txt_removed_success", this.language, this.ModuleNames.Engagement));
  }
  // SurveyReminderDto

  respondentAddFunction() {
    this.respondentAdd = true; return;
    this.ZenCDKService.openComponent(WbRespondentsModalComponent, { multiSelect: true, manualClose: false, surveyPool: true, surveyKey: this.surveyData.key }).subscribe((result: PortalDataResult) => {
      switch (result.type) {
        case 'single':
          this.LoaderService.displayLoader();
          this.createSingleRespondent(result.data);
          break;
        case 'bulk':
          this.createMultipleRespondent(result.data);
          this.LoaderService.displayLoader();
          break;
        case 'file':
          this.LoaderService.displayLoader();
          this.insertUsingExcel(result.data)
          break;
      }
    })
  }
  searchTextUpdate(text) {
    this.respondentsTableService.searchText.next(text);
  }

  createMultipleRespondent(data: any) { ;

    let reData: BulkAssignToSurveyDto = {
      emails: data.type == "ALL" ? data.exclude.map(x => x.email) : data.respondents.map(x => x.email),
      isSelectAll: data.type == "ALL" ? true : false
    }

    // let reData: BulkAssignToSurveyDto = {
    //   emails: data.respondents.map(x => x.email),
    //   isSelectAll: data.allSelected
    // };
    this.respondentService.bulkAssignToSurvey(this.surveyData.key, reData).subscribe(respondentData => {
      this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
      this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
      this.respondentAdd = false;

      this.LoaderService.hideLoader();
    })
  }
  insertUsingExcel(data: any) {
    ;
    let request = {
      FileKey: 'RESPOOL',
      TenantKey: this.tenant.key,
      CompanyKey: this.selectedEntity.key,
      UserKey: this.user.key,
      FileBase: data
    };

    this.respondentPoolService
      .updloadRespondents(request)
      .pipe(
        switchMap(response => {
          return this.respondentService.excelUpload({
            surveyKey: this.surveyData.key,
            companyKey: this.companyKey,
            tenantKey: this.tenantKey,
            excelFile: data.split(",")[1]
          })
        })
      )
      .subscribe(respondentData => {
        this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
        this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
        this.respondentAdd = false;

        this.LoaderService.hideLoader();
      })
  }

  // uploadRespondents(fileBase) {
  //   let request = {
  //     FileKey: 'RESPOOL',
  //     TenantKey: this.tenant.key,
  //     CompanyKey: this.selectedEntity.key,
  //     UserKey: this.user.key,
  //     FileBase: fileBase
  //   };
  //   this.subscriptions.add(
  //     this.respondentService
  //       .updloadRespondents(request)
  //       .subscribe((response: any) => {
  //         if (response.response == 'SUCCESS')
  //           this.respondentAdd = false;
  //       }));
  // }

  createSingleRespondent(data: any) {
    // Request to upload respondent to the company pool
    let respondents: EngagementRespondent[] = []
    respondents.push(data);

    let request = {
      TenantKey: this.tenant.key,
      CompanyKey: this.selectedEntity.key,
      UserKey: this.user.key,
      respondents: respondents
    }

    // Request to upload respondent to the wellbeing respondents
    let respondentData = {
      name: data.name,
      email: data.email,
      surveyKey: this.surveyData.key,
      companyKey: this.surveyData.companyKey,
      tenantKey: this.surveyData.tenantKey,
      employeeId: data.employeeID,
    } as RespondentDto;

    this.respondentPoolService.updateRespondentPool(request).pipe(
      switchMap(data => {
        return this.respondentService.create(respondentData)
      })
    ).subscribe(respondentData => {
      this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
      this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
      // this.ZenCDKService.removePortal();
      this.LoaderService.hideLoader();
    })
  }



  async deleteRespondent(respondent: RespondentDto) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondent_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {

        this.addSubscription(this.respondentService.delete(respondent.email, this.surveyData.key).subscribe(x => {
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.Wellbeing));
          this.respondentsTableService.removeRespondent(respondent);
          // this.totalRecords--;
        }))
      }
    } catch (ex) {

    }
  }
  async deleteRespondents(respondents: RespondentDto[]) {
    this.resetSelection = false;
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondents_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {

        let emails = respondents.map(x => x.email);
        if (this.respondentsTableService.selectAll)
          emails = emails.filter(x => !this.options.data.find(d => d.email == x));
        this.addSubscription(this.respondentService.bulkDelete({
          emails: emails.length ? emails : [''],
          surveyKey: this.surveyData.key,
          isDeleteAll: this.respondentsTableService.selectAll
        }).subscribe(x => {
          this.resetSelection = true;
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.Wellbeing));
          this.respondentsTableService.pageNumber.next(1);
          emails.forEach(function (mail) {
            this.respondentsTableService.removeRespondent(mail);
          });
        }))
      }
    } catch (ex) {

    }
  }

  downloadExcel() {
    window.open('/assets/downloads/Respondents Template.xlsx', '_blank');
  }

  resetTemplate() {
    this.surveyData.invitationEmail.body = this.emailTemplatesService.getInvitationEmail();
    this.surveyData.invitationEmail = JSON.parse(JSON.stringify(this.surveyData.invitationEmail));
  }

  reminderTemplate() {
    this.ZenCDKService.openComponent(WbReminderModalComponent, { survey: this.surveyData, language: this.language }).subscribe((result: any) => {
      this.surveyData = result;
    })
  }
  openPreviewSurvey() {
    this.ZenCDKService.openComponent(WbSurveyPreviewComponent,
      { survey: this.surveyData, essentialObject: this.essentialObject });
  }

  selectedAll(event) {
    this.respondentsTableService.selectAll = event;
  }

  validateEmailInvitation(SurveyDto: SurveyDto) {
    let isValid: boolean = true;
    if (!SurveyDto.invitationEmail.subject) {
      isValid = false;
    }
    if (!SurveyDto.invitationEmail.body) {
      isValid = false;
    }
    if (SurveyDto.invitationEmail.body.indexOf("[SurveyURL]") < 1) {

      this.SnackbarService.error(this.localize("txtInvitationMissingURL", this.language, this.ModuleNames.Engagement));

      return isValid = false;
    }
    if (!isValid)
      this.SnackbarService.error(this.localize("txt_fill_fields", this.language, this.ModuleNames.Engagement));
    if (!this.validateCount(SurveyDto.invitationEmail.subject, this.GlobalService.mdCount, 'x') || !this.validateCount(SurveyDto.invitationEmail.body, this.GlobalService.hugeCount, 'x')) {
      return false
    }
    else {
      return isValid;
    }
  }



  validateCount(value, count, ready?) {
    let validatedCount;
    if (ready == 'x') {
      validatedCount = this.countWord(value, count)
    }
    else
      validatedCount = this.countWord(this.ExtractPipe.transform(value, this.language), count)
    if (validatedCount > count) {
      this.SnackbarService.error(this.localize("txt_wrong_text_count", this.language, this.ModuleNames.Engagement));
      return false;
    }
    if (validatedCount <= count) {
      return true;
    }
  }
  countWord(word, count) {
    if (word?.length <= count)
      return word.length;
    if (word?.length > count) {

      return word.length;
    }
  }

  async sendInvitations() {
    this.submitted = true;
    if (!this.surveyData.title.find(x => x.name.length > 3))
      return this.updateSurvey(this.surveyData, 0);

    if (!this.options.data.length) {
      this.SnackbarService.error(this.localize('txtAtLeastRespondentsToStart', this.language, this.ModuleNames.Wellbeing));
      return this.tab = 2;
    }

    if (!this.surveyData.endDate) {
      this.SnackbarService.error(this.localize('txtProvideEndDate', this.language, this.ModuleNames.Wellbeing));
      return this.tab = 3;
    }

    if (!this.validateEmailInvitation(this.surveyData))
      return;

    let message = this.localize("txt_unit_deduction", this.language, this.ModuleNames.Engagement);
    let subject = this.localize("txt_allocation_confirmation", this.language, this.ModuleNames.Engagement);
    let actionButtonText = this.localize("txt_okay_action", this.language, this.ModuleNames.Engagement);
    let cancelButtonText = this.localize("btn_cancel", this.language, this.ModuleNames.Engagement);
    message = message.replace('#UNITS#', (this.respondentsTableService.totalRecords * 1).toString());

    let confirmationResult = await this.responseDialogService.confirmAllocation(message, subject, actionButtonText, cancelButtonText);
    if (confirmationResult) {
      this.LoaderService.displayLoader();
      let request = { noOfRespondees: this.respondentsTableService.totalRecords, userKey: this.user.key, tenantKey: this.tenantKey, entityKey: this.selectedEntity.key, engagementKey: this.surveyData.key };

      this.addSubscription(this.wellbeingService.checkBalanceAndCreditWellbeing(request).subscribe((response: any) => {
        if (response.response == 'SUCCESS') {
          this.surveyUpdate = {
            key: this.surveyData.key,
            companyKey: this.surveyData.companyKey,
            tenantKey: this.surveyData.tenantKey,
            title: this.surveyData.title,
            description: this.surveyData.description,
            startDate: this.surveyData.startDate,
            endDate: this.surveyData.endDate,
            noOfRespondents: this.surveyData.noOfRespondents,
            score: this.surveyData.score,
            status: this.surveyData.status,
            reminders: this.surveyData.reminders,
            selectedCriterias: this.surveyData.selectedCriterias,
            surveyCriterias: this.surveyData.surveyCriterias,
            invitationEmail: this.surveyData.invitationEmail,
            reminderEmail: this.surveyData.reminderEmail,
            surveyTemplate: this.surveyData.survey,
            entityHierarchy: this.surveyData.entityHierarchy,
            predefinedMetrics: this.surveyData.predefinedMetrics
          }


          this.addSubscription(this.surveysService.sendInvitations(this.surveyData.key, this.surveyUpdate).subscribe(x => {
            this.LoaderService.hideLoader();
            this.router.navigate(['/wellbeing/live/', this.surveyData.key]);
          }))

        }
        else if (response.message == 'Insufficient Credits') {
          this.LoaderService.hideLoader();
          const searchDialogRef = this.matDialog.open(CreditBalanceDialogComponent, {
            data: {
              tenantKey: this.tenantKey, entity: this.selectedEntity, creditType: 'ENGAGEMENT',
              language: this.language, minUnits: response.data.requiredCredits,
              currentBalance: response.data.currentUnits, surveyRequires: response.data.minUnits, user: this.user, tenant: this.tenant
            },
            autoFocus: false,
          });
        }

      }))


    }
  }

  ngOnDestroy() {
    this.GlobalService.innerRoute = false;
  }
}
