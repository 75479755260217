import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Entity } from '../../core/data-models/Entity';

@Component({
  selector: 'master-snack-bar',
  templateUrl: './master-snack-bar.component.html',
  styleUrls: ['./master-snack-bar.component.scss']
})
export class MasterSnackBarComponent implements OnInit {


  message: string;
  language: string;
  action: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit() {
    this.message = this.data.message;
    this.language = this.data.language;
    this.action = this.data.action
  }



}
