import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  constructor() { }
  @Input() routerLink;
  @Output() click: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {
  }

}
