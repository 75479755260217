import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


export default interface RawDataExcel {
  category: string,
  value: string
}

@Injectable({
  providedIn: 'root'
})
export class SpecialQuestionExcelDownloadService {
  currentRow: number = 0;

  constructor() { }

  generate(data: RawDataExcel[], fileName = "download") {
    this.currentRow = 1;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');

    // Add headers
    const headers = [fileName,"Respondent Name", "Filtered by"]
    worksheet.insertRow(this.currentRow, headers);

    const fileNameCell = worksheet.getCell(`A${this.currentRow}`);
    fileNameCell.font = { bold: true };
    fileNameCell.alignment = { wrapText: true };



    this.currentRow += 1;

        //    worksheet.getCell(`C${this.currentRow}`)  <<< if there is any fitlers add them here

    this.currentRow += 1;

    // Set column widths
    worksheet.getColumn('A').width = 100
    worksheet.getColumn('B').width = 50
    worksheet.getColumn('C').width = 100


    data.forEach(r => {
      var data :any[] = [];

      // if(r.group)
      // {
      //   row = [r.category + "," + r.group, r.count, r.percentage]
      // }
      // else
      // {
      // row = [r.category, r.count, r.percentage]
      // }

      data = [r.value, r.category];

      worksheet.insertRow(this.currentRow, data);

      this.currentRow += 1;
    })

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      if(fileName.length > 207)
        {
          fileName = fileName.substring(0, 150);
        }
      saveAs(blob, `${fileName}.xlsx`);
    });
  }
}
