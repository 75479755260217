  <div class="flex flex-col Page h-screen" *ngIf="surveyModel && !exportMode">
    <app-survey-navbar  [language]="language" [surveyModel]="surveyModel" [survey]="survey" (languageChanged)="onlanguageChanged($event)"></app-survey-navbar>

    <div class="bg-[#F5F7F9] flex-grow">
        <div class="flex flex-row justify-center">
            <div class="w-full max-w-4xl py-8 mt-5">
                <div>
                    <survey [model]="surveyModel"></survey>
                </div>
            </div>
        </div>
    </div>

    <app-survey-actionsbar [surveyModel]="surveyModel" [language]="language"
        (showConfirmSubmissionModal)="showConfirmSubmissionModal()"></app-survey-actionsbar>
</div>

<app-confirm-submission-modal *ngIf="isConfirmSubmissionModalOpen" (submitSurvey)="onSubmitSurvey($event)"
    [(open)]="isConfirmSubmissionModalOpen" [language]="language"></app-confirm-submission-modal>

<loader *ngIf="loader.showLoader"></loader>

<div *ngIf="exportMode">
    <survey class="mt-8 pt-8" [model]="surveyModel"></survey>
</div>
