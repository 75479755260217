import { Injectable } from '@angular/core';
import { WbStatus } from '../../utils/wb-status';

@Injectable({
  providedIn: 'root'
})
export class WbUtilitiesService {

  private status = WbStatus
  constructor() { }
  returnColor(value: any) {
    value = parseFloat(value).toFixed(1);
    if (value < 40) return 'red';
    if (value >= 40 && value < 60) return 'lightRed';
    if (value >= 60 && value < 80) return 'orange';
    if (value >= 80 && value < 90) return 'lightGreen';
    if (value >= 90) return 'green';
  }




  wellbeingStatuses: any[] = [
    {
      key: this.status.Draft,
      name: [
        { key: 'EN', name: 'Draft' },
        { key: 'AR', name: 'مسودة' },
      ],
    },
    {
      key: this.status.Live,
      name: [
        { key: 'EN', name: 'Live' },
        { key: 'AR', name: 'مباشر' },
      ],
    },
    {
      key: this.status.Closed,
      name: [
        { key: 'EN', name: 'Closed' },
        { key: 'AR', name: 'انتهى' },
      ],
    },
  ];

  wellbeingActionButton: any[] = [
    {
      key: this.status.Draft,
      name: [
        { key: 'EN', name: 'Continue Setup' },
        { key: 'AR', name: 'متابعة الإعداد' },
      ],
    },
    {
      key: this.status.Live,
      name: [
        { key: 'EN', name: 'View' },
        { key: 'AR', name: 'عرض' },
      ],
    },
    {
      key: this.status.Closed,
      name: [
        { key: 'EN', name: 'Open' },
        { key: 'AR', name: 'فتح' },
      ],
    },
  ];

}
