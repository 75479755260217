<z-modal [open]="open" [autoWidth]="true">
  <ng-container header>
    <h2 class="text-zen text-2xl font-semibold">{{ "txtUpdateSurveyDates" | translate:language:ModuleNames.Shared }}
    </h2>
    <i (click)="closeModal()"
      class="fa-regular fa-times leading-12 btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col gap-5 items-stretch w-screen max-w-lg">
      <h5 [@first] class="text-xl font-medium text-zen">{{ "txtUpdateTimeFrame" | translate:language:ModuleNames.Shared
        }}</h5>
      <div class="flex flex-row gap-5 w-full">
        <div class="flex flex-col w-1/2 items-stretch">
          <z-datepicker [color]="'exp'" [label]="'txt_start_date'| translate:language:ModuleNames.Engagement"
            [language]="language" [max]="surveyDates.endDate" [ngModel]="surveyDates.startDate" [disabled]="true">
          </z-datepicker>
        </div>
        <div class="flex flex-col w-1/2 items-stretch">
          <z-datepicker [color]="'exp'" [label]="'txt_completion_date'| translate:language:ModuleNames.Engagement"
            [language]="language" [min]="plusOneDay(today, true)" [(ngModel)]="surveyDates.endDate" (ngModelChange)="endDateChange()">
          </z-datepicker>
        </div>
      </div>
      <div class="-mr-10 -ml-10">
        <mat-divider></mat-divider>
      </div>
      <div class="flex flex-row items-center justify-between">
        <h5 [@child6] class="text-xl font-medium text-zen">{{ "txtUpdateReminders" |
          translate:language:ModuleNames.Shared }}</h5>
        <div class="">
          <a [@child8] *ngIf="surveyDates.reminders?.length < 3"
            (click)="addReminder()"
            [ngClass]="{'opacity-50 pointer-events-none': reminders?.length  == 3}"
            class="btn cancel leading-10 h-10 block px-4 rounded">{{'txt_add_reminder' |
            translate:language:ModuleNames.Engagement }}</a>
        </div>
      </div>
      <div *ngFor="let reminder of surveyDates.reminders | slice:0:3; let z =index"
        class="flex flex-row justify-between gap-2 items-center ">
        <div class="flex flex-row gap-2 items-center w-full">
          <div class="leading-8 w-8 h-8 block  rounded-full btn exp text-center pointer-events-none">
            {{z+1}}</div>
          <div class="w-full" [@child7]>
            <z-datepicker [placeholder]="'txt_reminder_date'|translate:language:ModuleNames.Shared" [color]="'exp'"
              [language]="language"
              [min]="plusOneDay(today, true)" #reminder="ngModel"
              [max]="minusOneDay(surveyDates.endDate)"
              [ignoreDays]="getReminders(surveyDates.reminders, z)"
              [(ngModel)]="reminder.date" [disabled]="disabledDayCheck(reminder.date)">
            </z-datepicker>
          </div>
        </div>
        <a zenButton styles="delete icon-10" (click)="removeReminder(reminder)">
          <i class="fa-regular fa-trash"></i>
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container footer >
    <a zenButton styles="secondary height-10" (click)="closeModal()" >{{'btn_cancel'|
      translate:language:ModuleNames.Shared}}</a>
      <a zenButton styles="exp height-10" (click)="submitDates()" >{{'txtUpdateDates'|
        translate:language:ModuleNames.Shared}}</a>
  </ng-container>
</z-modal>
