import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';
import { EmailTemplateService, EmailTemplateTypes } from '@ex/survey-x/index';



@Component({
  selector: 'app-template-library-modal',
  templateUrl: './template-library-modal.component.html',
  styleUrls: ['./template-library-modal.component.scss'],
  animations: [ChildrenAnimation],
  encapsulation: ViewEncapsulation.None,

})
export class TemplateLibraryModalComponent extends ModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Input() language: string = 'EN';
  @Input() tenantId: any;
  @Input() companyId: any
  @Input() templateType: any = EmailTemplateTypes.All;
  @Output() openChange = new EventEmitter();
  @Output() template: EventEmitter<{ subject: any, body: any }> = new EventEmitter<{ subject: any, body: any; }>();


  ModuleNames = ModuleName;

  templatesList: any[] = [];
  selectedTemplate: any;

  selectedTemplateBody: any;
  selectedTemplateBodySanitized: any;
  selectedTemplateSubject: any;

  constructor(private emailTemplateService: EmailTemplateService, private sanitizer: DomSanitizer) { super(); }

  ngOnInit(): void {

    this.emailTemplateService.getByType(this.tenantId, this.companyId, this.templateType).subscribe((response: any) => {

      response.forEach(element => {

        this.templatesList.push({
          key: element.id,
          name: element.name
        })
      });
    });


  }

  onTemplateChanged(value: any) {
    this.selectedTemplate = value;

    this.emailTemplateService.getEmailTemplate(this.selectedTemplate).subscribe((res: any) => {

      this.selectedTemplateBodySanitized = this.sanitizer.bypassSecurityTrustHtml(res.body);
      this.selectedTemplateBody = res.body;
      this.selectedTemplateSubject = res.subject;

    })

  }
  onSelectTemplate() {


    let template = { subject: this.selectedTemplateSubject, body: this.selectedTemplateBody, templateType: this.templateType }
    this.template.emit(template);

    this.close();
  }
  onSubmit() {

  }

  close() {
    this.openChange.emit(false);
  }
}
