<zen-main-container>
  <sidebar-container>
    <div class="flex flex-col justify-center h-28 px-8">
      <h5 class="text-zen uppercase text-sm font-medium">{{ "txtOverallBalance" | translate:language:ModuleNames.Common }}</h5>
    </div>
    <div class="flex flex-col gap-6" *ngIf="entityAccountBalances?.entities.length">
      <ng-container *ngFor="let product of productsPreview">
        <ng-template *ngTemplateOutlet="unitOverview; context:{$implicit:product}"></ng-template>
      </ng-container>
    </div>

  </sidebar-container>
  <content-container class="flex flex-col items-stretch w-full">
    <div class="flex flex-col items-stretch h-28 z-10" sticky>
      <section-head>
        <ng-container left>
          <h5 class="text-zen font-medium text-3xl">{{'txtAccountOverview'| translate:language:ModuleNames.Common }}
            {{'- ' +
            extractNameLanguage(selected?.entityName) }}</h5>
        </ng-container>
        <ng-container right>
          <button (click)="requestMoreUnits(null)" class="btn exp uppercase leading-12 rounded px-5">{{
            "btnRequestUnits"| translate:language:ModuleNames.Common }}</button>
        </ng-container>
      </section-head>
    </div>
    <div class="flex flex-col mt-10" *ngIf="selected?.key">
      <div class="w-full overflow-x-auto max-w-full bg-transparent">
        <table class="w-full  bg-transparent py-2 relative">
          <tr class="mat-row cdk-row h-auto px-10">
            <th>
              <h5 class="text-xxs text-zen uppercase font-medium whitespace-nowrap text-start px-10 pb-5">{{"txtProduct"|
                translate:language:ModuleNames.Common }}</h5>
            </th>
            <th class="px-5 pb-5">
              <h5 class="text-xxs text-zen uppercase font-medium whitespace-nowrap text-start"> {{'txtCurrentBalance'|
                translate:language:ModuleNames.Common
                }}
              </h5>
            </th>
            <th class=" pb-5">
              <h5 class="text-xxs text-zen uppercase font-medium whitespace-nowrap text-start">{{'txtUsedUnits'|
                translate:language:ModuleNames.Common }} </h5>
            </th>
            <th *ngIf="user.role == 'Super Admin'">
              <h5 class="text-xxs text-zen uppercase font-medium whitespace-nowrap text-start  px-5 pb-5">
                {{'txtAssignUnits'|
                translate:language:ModuleNames.Common
                }} </h5>
            </th>
            <th>
            </th>
          </tr>

          <ng-container *ngFor="let product of productTable">
            <ng-template *ngTemplateOutlet="productRow; context:{$implicit:product}"></ng-template>
          </ng-container>
        </table>

      </div>
    </div>
  </content-container>
</zen-main-container>
<loader *ngIf="loader" [language]="language"></loader>


<z-drawer [(open)]="historyModal" *ngIf="historyModal">
  <ng-container header>
    <div class="flex flex-row  items-center">
      <i
        class="fal fa-coins bg-exp text-white rounded-full text-center text-lg leading-10 h-10 w-10  pointer-events-none"></i>
      <h4 class="text-2xl leading-12 font-bold px-5 text-zen">{{'txtPurchaseHistory'|
        translate:language:ModuleNames.Common }}</h4>
    </div>
    <div class="flex flex-row gap-5 items-center">
      <a (click)="historyModal = false"
        class="btn cancel block leading-12 h-12  text-center rounded-md px-3 ">{{'txt_close'|
        translate:language:ModuleNames.Common }}</a>

    </div>
  </ng-container>

  <ng-container body>
    <div class="flex flex-col gap-5 px-7">
      <div class="flex flex-row justify-between">
        <div *ngIf="historyType == 'Entity'" class="flex flex-col w-1/2 gap-2">
          <h5 class="text-sm text-zen text-opacity-40 ">{{"txt_company_name"| translate:language:ModuleNames.Common }}
          </h5>
          <h5 class="text-2xl text-zen">{{ extractNameLanguage(selected.entityName) }}</h5>
        </div>
        <div class="flex flex-col w-1/2 gap-2">
          <h5 class="text-sm text-zen text-opacity-40 ">{{"txtProduct"| translate:language:ModuleNames.Common }}</h5>

          <div class="flex flex-row items-center gap-5">
            <img class="w-8" [src]="selectedProduct.image" alt="">
              <h2 class="text-zen  font-medium ">{{selectedProduct.name| translate:language:ModuleNames.Common }}</h2>
          </div>
        </div>
      </div>
      <t-table [options]="options" *ngIf="options?.data">
        <ng-template #cellTempate let-element let-column='column' let-index="index">
          <div *ngIf="column.field == 'first'" class="flex flex-col justify-between items-start py-5">
            <h5 class="text-base text-zen font-medium ">{{ element.actualUnits}}</h5>
          </div>
          <div *ngIf="column.field == 'second'" class="flex flex-col justify-between items-start py-5">
            <h5 class="text-base text-zen font-medium ">{{ getReadableDate(element.createdDate)}}</h5>
          </div>
          <div *ngIf="column.field == 'third'" class="flex flex-col justify-between items-start py-5">
            <h5 class="text-base text-zen font-medium ">{{ getReadableDate(element.expiryUnitDate)}}</h5>
          </div>
        </ng-template>
      </t-table>
    </div>
  </ng-container>

</z-drawer>


<ng-template #unitOverview let-product>
  <div class="flex flex-row justify-between items-center px-8">
    <div class="flex flex-col  gap-1.5">
      <h2 class="text-sm text-zen font-normal">{{product.name | translate:language:ModuleNames.Common }} </h2>
      <h2 class="text-zen"> <span class="text-lg font-semibold">{{ entityAccountBalances[product.unitsName] | number:'1.0':'en-US' }}</span>
        <span class="text-xs font-normal px-1">{{'txt_units'| translate:language:ModuleNames.Common }}</span>
      </h2>
    </div>
    <button [matTooltip]="'txtPurchaseHistory'|translate:language:ModuleNames.Common"
    (click)="purchaseHistory('Tenant', product)" class="btn cancel h-8 w-8 text-center leading-8 rounded"><i
      class="fal fa-history mx-1"></i></button>
  </div>
</ng-template>

<ng-template #productRow let-product>
  <tr
    class="mat-row cdk-row h-auto border-b border-zen border-opacity-10  transition-all bg-opacity-5 bg-white hover:bg-opacity-100">
    <td class="border-b-0 border-l-0 py-5 border-r-0 px-10">
      <div class="flex flex-row items-center gap-5">
        <img class="h-15" [src]="product.image" alt="">
        <div>
          <h6 class="text-xxs text-zen uppercase font-medium whitespace-nowrap text-start">ZENITHR</h6>
          <h5 class="text-lg text-zen font-medium -mb-1">{{product.name| translate:language:ModuleNames.Common }}</h5>
        </div>
      </div>
    </td>
    <td class="border-b-0 border-l-0 p-5 border-r-0">

      <h5 class="text-lg text-zen font-medium ">
        {{selected.balanceDistribution[product.currentBalance]}}</h5>
    </td>

    <td class="border-b-0 border-l-0 py-5 border-r-0">

      <h5 class="text-lg text-zen font-medium ">
        {{selected.balanceDistribution[product.usedBalance]}}</h5>
    </td>


    <td *ngIf="user.role == 'Super Admin'" class="border-b-0 border-l-0 border-r-0  px-5">
      <div class="flex flex-row justify-start items-center gap-5">
        <z-input [number]="true" [(ngModel)]="selected.balanceDistribution[product.unitAddCount]" placeholder="0">
        </z-input>
        <a (click)="addUnits(selected, product.key, selected.balanceDistribution[product.unitAddCount], selected.balanceDistribution[product.surveyAddCount], selected.balanceDistribution[product.unitAddCount])"
          class="btn cancel leading-10 rounded px-5">{{'txtAssign'| translate:language:ModuleNames.Common }}</a>
      </div>
    </td>
    <td class="border-b-0 border-l-0 py-5 border-r-0  px-10">

      <a (click)="purchaseHistory('Entity', product)"
        class="btn cancel h-10 leading-10 whitespace-nowrap block text-center truncate rounded px-5">{{'txtAllocationHistory'|
        translate:language:ModuleNames.Common }}</a>
    </td>
  </tr>
</ng-template>
