import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DepartmentsService
{
    URL = environment.endPoint;
    httpOptions={headers: new HttpHeaders({
        'Access-Control-Allow-Origin': 'https://assessments.zenithr.net',
        'Content-Type': 'application/json;'
      })}

      constructor(private httpClient: HttpClient) { }

      getAllDepartments(){
        return this.httpClient.get(this.URL+'department/get-all-departments');
      }


}
