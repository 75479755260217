<div class="flex flex-col items-stretch cMaxH">
    <div class="flex flex-row items-stretch gap-10 innerListScroll pb-16">
        <div class="flex flex-col gap-2 fixedW200  justify-between items-start  overflow-hidden">
            <div class="flex flex-col gap-2 items-stretch w-full whitespace-nowrap"
                [@listAnimation]="navButtons.length">
                <ng-container *ngFor="let button of  navButtons; let n = index">
                    <ng-container *ngTemplateOutlet="buttonTemplate;context:{$implicit:button, index:n}"></ng-container>
                </ng-container>
            </div>
        </div>
        <div [@biDirectionalState300]="step" class="flex flex-col items-stretch fixedW200Less">
            <div class="flex flex-col  items-stretch gap-7" *ngIf="step == 1">
                <p class="text-sm text-zen font-normal">{{'txtaddmultipleleaverstotheplatform' |
                    translate:language:ModuleNames.Exit}}</p>
                <!-- <z-fileupload [columns]="['Employee ID', 'Name', 'Email']" fileName="joiners.csv">
              </z-fileupload> -->
                <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center gap-2.5 w-full">
                        <i class="fa-light fa-file-spreadsheet text-4xl text-zen"></i>
                        <div>
                            <h5 class="text-xs text-zen-lite font-normal" *ngIf="!url">{{ 'txt_Downloadthepremade' |
                                translate:language:ModuleNames.Exit }}</h5>
                            <h5 class="text-xs text-zen-lite font-normal" *ngIf="url">{{ 'txt_uploadthepremade' |
                                translate:language:ModuleNames.Exit }}</h5>
                            <h5 class="text-xs text-zen-lite font-normal"> <span
                                    class="font-medium ">({{filename}})</span>
                            </h5>
                        </div>
                    </div>
                    <a (click)="downloadExcelSheet()"
                        class="btn exp leading-14 rounded px-6 font-medium flex flex-row items-center gap-2.5 w-1/2 whitespace-nowrap">
                        <i class="fa-regular fa-download"></i>
                        {{ 'txt_TemplateDownload' | translate:language:ModuleNames.Exit }}
                    </a>
                </div>
                <div
                    class="flex flex-row gap-5 justify-between  rounded bg-zen-blue bg-opacity-5 text-zen-blue border border-zen-blue border-opacity-20">
                    <p class="px-5 py-3">{{'txtClickon' |
                        translate:language:ModuleNames.Exit}} <span class="font-medium">{{'txtDownloadTemplate' |
                            translate:language:ModuleNames.Exit}}</span>
                        {{'txtSaveaSpreadsheet' | translate:language:ModuleNames.Exit}}</p>

                </div>
            </div>
            <div class="flex flex-col  items-stretch gap-7" *ngIf="step == 2">
                <div class="flex flex-row gap-10">
                    <div class="flex flex-col gap-7">
                        <p class="text-sm text-zen font-normal"><span class="font-medium">{{'txtOpen' |
                                translate:language:ModuleNames.Exit}}</span>
                            {{'txtSpreadsheetEditor' | translate:language:ModuleNames.Exit}}
                        </p>
                        <p class="text-sm text-zen font-normal"><span class="font-medium">{{'txtFill' |
                                translate:language:ModuleNames.Exit}}</span>
                            {{'txtPremadeStructure' |
                            translate:language:ModuleNames.Exit}}<br> <span class="text-zen-blue">{{'txtDeleteExample' |
                                translate:language:ModuleNames.Exit}}</span></p>
                        <p class="text-sm text-zen font-normal">
                            <span class="font-medium">{{'txtSave' |
                                translate:language:ModuleNames.Exit}}</span>
                            {{'txtUploadNextStep' | translate:language:ModuleNames.Exit}}
                        </p>
                    </div>
                    <i class="fa-light fa-pen-to-square text-7xl text-zen"></i>
                </div>


                <div
                    class="flex flex-row gap-5 justify-between  rounded bg-zen-blue bg-opacity-5 text-zen-blue border border-zen-blue border-opacity-20">
                    <p class="px-5 py-3">{{'txtClick' | translate:language:ModuleNames.Exit}}
                        <span class="font-medium">{{'Next Step' |
                            translate:language:ModuleNames.Exit}}</span>
                        {{'txtFilledDownloadedTemplates' |
                        translate:language:ModuleNames.Exit}}
                    </p>

                </div>
            </div>
            <div class="flex flex-col  items-stretch gap-7" [hidden]="step != 3">
                <div class="flex flex-row justify-start">
                    <p class="text-sm text-zen font-normal">{{'txtFilledTemplates' |
                        translate:language:ModuleNames.Exit}}:</p>
                </div>
                <z-fileupload [reset]="validationErrorMessages?.length" (fileUploadChange)="fileUploaded($event)"
                    [noURL]="true" mode="upload" [fileName]="filename"></z-fileupload>
                <div class="flex flex-col gap-1" *ngIf="validationErrorMessages.length">
                    <p class="text-sm text-zen font-normal">{{ 'txtErrorExcelUpload' |
                        translate:language:ModuleNames.Exit }}</p>
                    <div
                        class="flex flex-col gap-2 px-5 py-3 justify-between  rounded bg-zen-red bg-opacity-5 text-zen-red border border-zen-red border-opacity-20">
                        <ul>
                            <li class="text-sm" *ngFor="let validation of validationErrorMessages">
                                {{('txt_'+ validation) | translate:language:ModuleNames.Exit}}
                            </li>
                        </ul>


                    </div>
                </div>
                <!-- <div
                    class="flex flex-row gap-5 justify-between  rounded bg-zen-blue bg-opacity-5 text-zen-blue border border-zen-blue border-opacity-20">
                    <p class="px-5 py-3">{{'txtClick' | translate:language:ModuleNames.Exit}}
                        <span class="font-medium">{{'txtReviewData' |
                            translate:language:ModuleNames.Exit}}</span>
                        {{'txtSpreadUploadOnce' | translate:language:ModuleNames.Exit}}
                    </p>
                    <i class="fa-regular fa-times leading-9 h-9 w-9 text-center m-2.5 btn"></i>
                </div> -->
            </div>
            <div class="flex flex-col gap-8  innerListScroll overflow-x-hidden pb-5 items-stretch" *ngIf="step == 4">
                <div class="flex flex-col items-stretch gap-8">
                    <div class="flex flex-col gap-5">
                        <div>
                            <h5 class="text-xs uppercase text-zen font-medium">{{'txtEmailTemplates' |
                                translate:language:ModuleNames.Exit}}</h5>
                            <!-- <h6 class="text-xs text-zen-lite font-regular">Create or edit the invitation/reminder
                          template
                          below, or
                          use one of the premade templates:</h6> -->
                        </div>
                        <div class="flex flex-col items-stretch">
                            <div class="flex flex-row gap-2.5 text-xs">
                                <div (click)="invitation = true"
                                    [ngClass]="invitation?'text-zen font-medium bg-white':'text-zen-lite font-normal bg-zen bg-opacity-10'"
                                    class="flex flex-row items-center justify-center btn gap-2.5 w-1/2 rounded-t border-l border-r border-t border-zen border-opacity-10 leading-14">
                                    <i class="fa-regular fa-envelope-open-text"></i>
                                    <span>{{'txtInvitationEmailTemplate' |
                                        translate:language:ModuleNames.Exit}}</span>
                                </div>
                                <div (click)="invitation = false"
                                    [ngClass]="!invitation?'text-zen font-medium bg-white':'text-zen-lite font-normal bg-zen bg-opacity-10'"
                                    class="flex flex-row items-center justify-center btn gap-2.5 w-1/2 rounded-t border-l border-r border-t border-zen border-opacity-10 leading-14">
                                    <i class="fa-regular fa-bell"></i>
                                    <span>{{'txtReminderEmailTemplate' |
                                        translate:language:ModuleNames.Exit}}</span>
                                </div>
                            </div>
                            <div
                                class="flex pb-2.5 px-2.5 bg-white rounded-b border-l border-r border-zen border-opacity-10 pt-5">
                                <div [hidden]="!invitation" (click)="logData(joiner)">
                                    <zen-editor (reset)="returnBody(1)" [language]="language"
                                        [(emailObject)]="invitationEmailTemplate">
                                    </zen-editor>
                                </div>
                                <div [hidden]="invitation">
                                    <zen-editor (reset)="returnBody(2)" [language]="language"
                                        [(emailObject)]="reminderEmailTemplate">
                                    </zen-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-8  innerListScroll overflow-x-hidden pb-5 items-stretch" *ngIf="step == 5">
                <div class="flex flex-col items-stretch gap-8">
                    <div class="flex flex-col gap-5">
                        <div>
                            <h5 class="text-xs uppercase text-zen font-medium">{{'txtEmailTemplates' |
                                translate:language:ModuleNames.Exit}}</h5>
                            <!-- <h6 class="text-xs text-zen-lite font-regular">Create or edit the invitation/reminder
                      template
                      below, or
                      use one of the premade templates:</h6> -->
                        </div>
                        <div class="flex flex-col items-stretch">
                            <div class="flex flex-row gap-2.5 text-xs">
                                <div (click)="invitation = true"
                                    [ngClass]="invitation?'text-zen font-medium bg-white':'text-zen-lite font-normal bg-zen bg-opacity-10'"
                                    class="flex flex-row items-center justify-center btn gap-2.5 w-1/2 rounded-t border-l border-r border-t border-zen border-opacity-10 leading-14">
                                    <i class="fa-regular fa-envelope-open-text"></i>
                                    <span>{{'txtInvitationEmailTemplate' |
                                        translate:language:ModuleNames.Exit}}</span>
                                </div>
                                <div (click)="invitation = false"
                                    [ngClass]="!invitation?'text-zen font-medium bg-white':'text-zen-lite font-normal bg-zen bg-opacity-10'"
                                    class="flex flex-row items-center justify-center btn gap-2.5 w-1/2 rounded-t border-l border-r border-t border-zen border-opacity-10 leading-14">
                                    <i class="fa-regular fa-bell"></i>
                                    <span>{{'txtReminderEmailTemplate' |
                                        translate:language:ModuleNames.Exit}}</span>
                                </div>
                            </div>
                            <div
                                class="flex pb-2.5 px-2.5 bg-white rounded-b border-l border-r border-zen border-opacity-10 pt-5">
                                <div *ngIf="invitation" (click)="logData(joiner)">
                                    <zen-editor (reset)="returnBody(1)" [language]="language"
                                        [(emailObject)]="invitationEmailTemplate">
                                    </zen-editor>
                                </div>
                                <div *ngIf="!invitation" (click)="logData(joiner)">
                                    <zen-editor (reset)="returnBody(2)" [language]="language"
                                        [(emailObject)]="reminderEmailTemplate">
                                    </zen-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="flex flex-row justify-between items-center">
        <div>
            <a (click)="step == 1? resetAll() : step = step - 1;"
                class="btn cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1 z-50">
                <i class="fa-regular" [ngClass]="(language | language)?'fa-chevron-right':'fa-chevron-left'"></i>
                <span>{{'btn_back' | translate:language:ModuleNames.Exit}}</span>
            </a>
        </div>

        <a [ngClass]="{'exp': step != 4, 'green':  step == 4, 'opacity-50 pointer-events-none': submitted, 'opacity-60 pointer-events-none':validationErrorMessages.length && step == 3 || (!this.excelUploadJob && this.step == 3)}"
            (click)="nextStep()"
            class="btn  text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
            <span>{{(step != 4 ? 'txt_Next' : 'txt_Submit') | translate:language:ModuleNames.Exit }}</span>
            <i class="fa-regular" [ngClass]="(language | language)?'fa-chevron-left':'fa-chevron-right'"></i>

        </a>


    </div>
</div>
<ng-template #buttonTemplate let-button let-index="index">
    <a [ngClass]="step == index+1?'bg-exp text-exp font-medium':'text-zen'"
        class="flex flex-row items-center gap-2 leading-12 rounded text-sm px-4 text-zen bg-opacity-10">
        <span [ngClass]="step == index+1?'bg-exp text-white':'bg-zen-lite text-zen-lite bg-opacity-20'"
            class="w-5 h-5 leading-5 text-center rounded-sm font-medium px-1">{{index+1}}</span>
        <h5>{{button.name}}</h5>
    </a>
</ng-template>
