import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { combineLatest, Observable, of, pipe } from 'rxjs';
import { TokenService } from './core/services/token.service';
import { delay, map, switchMap, take } from 'rxjs/operators';
import { EssentialObjectService } from './core/services/essential-object.service';
import { LocalService } from './core/services/local.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return this.tokenService.getToken().pipe(
      take(1),
      switchMap((token: any) => {

        if (token && !request.headers.has("Authorization")) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
          });
        }

        return next.handle(request);
      }));

  }
}
