import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';


@Component({
  selector: 'app-change-question-type-modal',
  templateUrl: './change-question-type-modal.component.html',
  styleUrls: ['./change-question-type-modal.component.scss']
})
export class ChangeQuestionTypeModalComponent extends ModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Output() openChange = new EventEmitter();
  @Output() typeSelected = new EventEmitter<string>();

  @Input() language: string = "EN";
  ModuleNames = ModuleName;

  constructor(private changeDetectorRef: ChangeDetectorRef) { super(); }

  ngOnInit(): void { 
  }

  close() {
    this.openChange.emit(false);
    this.open = false;
    this.changeDetectorRef.detectChanges();
  }

  selectType(type: string) {
    this.typeSelected.emit(type);
  }
}
