<z-modal  [open]="open" [autoWidth]="true">
    <ng-container header>
        <div class="flex text-center items-center gap-3">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.625 1.5V8.375H16.5C16.8125 8.375 17.125 8.6875 17.125 9C17.125 9.35156 16.8125 9.625 16.5 9.625H9.625V16.5C9.625 16.8516 9.3125 17.125 9 17.125C8.64844 17.125 8.375 16.8516 8.375 16.5V9.625H1.5C1.14844 9.625 0.875 9.35156 0.875 9C0.875 8.6875 1.14844 8.375 1.5 8.375H8.375V1.5C8.375 1.1875 8.64844 0.875 9 0.875C9.3125 0.875 9.625 1.1875 9.625 1.5Z"
                    fill="#7D92B5" />
            </svg>

            <h6 class="modal-title">
                {{'New Category'| translate:language:ModuleNames.SurveyX }}
            </h6>
        </div>

        <button class="text-center rounded close-button py-2 px-3" (click)="close()">
            <i class="fa-solid fa-xmark"></i>
        </button>
    </ng-container>

    <ng-container body>
        <div class="flex flex-col gap-3 flex-wrap w-96 -mt-5 -mb-5">
            <div *ngIf="showErrors" class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
                <div>
                    <ul class="mt-1.5 ml-4 list-disc list-inside">
                        <li> {{error | translate:language:ModuleNames.SurveyX }}</li>
                    </ul>
                </div>
            </div>

            <label>{{'Category Name'| translate:language:ModuleNames.SurveyX }}</label>

            <z-translation-input class="pre w-full"
                [placeholder]="'txtPleaseEnterCategoryName' | translate:language:ModuleNames.SurveyX"
                [supportedLanguages]="supportedLanguages" [language]="language" [(ngModel)]="categoryNameText">
            </z-translation-input>
        </div>
    </ng-container>

    <ng-container footer>
        <div class="flex flex-row justify-between w-full">
            <a (click)="close()"
                class="btn cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
                {{"Cancel"| translate:language:ModuleNames.SurveyX}}
            </a>

            <a class="btn exp text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1"
                (click)="onSubmit()">
                {{'Create Category'| translate:language:ModuleNames.SurveyX }}
            </a>
        </div>
    </ng-container>
</z-modal>
