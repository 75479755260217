import { Component, ContentChildren, OnInit, QueryList } from '@angular/core';
import { delay } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { AccordionComponent } from './accordion/accordion.component';

@Component({
  selector: 'zen-accordions',
  templateUrl: './accordions.component.html',
  styleUrls: ['./accordions.component.scss']
})
export class AccordionsComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
   }
  @ContentChildren(AccordionComponent) accordions: QueryList<AccordionComponent>;
  ngOnInit(): void {
  }
  ngAfterViewInit(): void {

    this.setActiveTabOnInitialization();

   this.addSubscription(this.accordions.changes.pipe(delay(0)).subscribe(x => {
      this.setActiveTabOnInitialization();
    }));
  }




  setActiveTabOnInitialization() {
    if (this.accordions.length === 0) {
      return;
    }

    const activeTabs = this.accordions.filter(accordion => accordion.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.accordions.first);
    }
  }

  selectTab(accordion: AccordionComponent) {
    this.accordions.toArray().forEach(accordion => {
      accordion.active = false;
    });

    accordion.active = true;
  }

  deselectTab(accordion: AccordionComponent) {
    accordion.active = false;
  }
}

