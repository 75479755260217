import { Injectable } from '@angular/core';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { GeneralService } from './general.service';
import { LanguageService } from './language.service';
import { LocalService } from './local.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  showLoader: boolean = false;
  multiTimer;
  // txtLoader: string;
  language: string;
  ModuleNames = ModuleName
  constructor(private languageService: LanguageService, private localService: LocalService, translationPipe: TranslatePipe) {
    this.language = this.localService.getJsonValue('language');
    // this.txtLoader =translationPipe.transform("txtLoader",this.language,this.ModuleNames.Common) ;
  }

  displayLoader(timeout: number = 10000, awaitStop?: boolean) {
    this.showLoader = true;
    if (this.multiTimer)
      clearTimeout(this.multiTimer);
    if (!awaitStop)
      this.multiTimer = setTimeout(() => {
        this.showLoader = false;
      }, timeout);
  }

  hideLoader() {
    if (this.multiTimer)
      clearTimeout(this.multiTimer);
    this.showLoader = false;
  }

}
