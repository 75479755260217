import { updateNPSQuestionHeaderHTML, questionsBuilderTranslateText } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";

/**
 * Updates the HTML for an NPS (Net Promoter Score) question.
 * @param options - The options for updating the NPS question HTML.
 */
export function updateNPSQuestionHTML(options: any) {
  updateNPSQuestionHeaderHTML(options, "NPS", "fa-bars-progress");

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;
  body.innerHTML = "";
  body.className = "question-body mt-5";

  var constraints = [
    { min: 0, max: 6, text: questionsBuilderTranslateText("Unlikely"), color: "#FF45301A" },
    { min: 7, max: 8, text: questionsBuilderTranslateText("Neutral"), color: "#FF96001A" },
    { min: 9, max: 10, text: questionsBuilderTranslateText("Likely"), color: "#3EB75B1A" }
  ];

  var container = document.createElement("div");
  container.className = "flex w-full gap-1 justify-center";

  for (var i = 0; i < constraints.length; i++) {
    var constraint = constraints[i];

    var constraintContainer = document.createElement("div");
    constraintContainer.className = "flex flex-col items-center gap-2";

    var itemsDiv = document.createElement("div");
    itemsDiv.className = "flex w-full gap-1 justify-center";

    for (var j = constraint.min; j <= constraint.max; j++) {
      var item = document.createElement("button");
      item.className = "px-1 w-auto px-4 py-3 nps-item";
      item.innerHTML = j.toString();
      item.style.backgroundColor = constraint.color;
      itemsDiv.appendChild(item);
    }

    var constraintText = document.createElement("label");
    constraintText.className = "label-gray whitespace-normal";
    constraintText.innerHTML = constraint.text;

    constraintContainer.appendChild(itemsDiv);
    constraintContainer.appendChild(constraintText);

    container.appendChild(constraintContainer);
    body.appendChild(container);
  }
}

/**
 * Updates the HTML for an eNPS (Employee Net Promoter Score) question.
 * @param options - The options for updating the eNPS question HTML.
 */
export function updateENPSQuestionHTML(options: any) {
  updateNPSQuestionHeaderHTML(options, "eNPS", "fa-bars-progress");

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;
  body.innerHTML = "";
  body.className = "question-body mt-5";

  var constraints = [
    { min: 0, max: 6, text: questionsBuilderTranslateText("Unlikely"), color: "#FF45301A" },
    { min: 7, max: 8, text: questionsBuilderTranslateText("Neutral"), color: "#FF96001A" },
    { min: 9, max: 10, text: questionsBuilderTranslateText("Likely"), color: "#3EB75B1A" }
  ];

  var container = document.createElement("div");
  container.className = "flex w-full gap-1 justify-center";

  for (var i = 0; i < constraints.length; i++) {
    var constraint = constraints[i];

    var constraintContainer = document.createElement("div");
    constraintContainer.className = "flex flex-col items-center gap-2";

    var itemsDiv = document.createElement("div");
    itemsDiv.className = "flex w-full gap-1 justify-center";

    for (var j = constraint.min; j <= constraint.max; j++) {
      var item = document.createElement("button");
      item.className = "px-1 w-auto px-4 py-3 nps-item";
      item.innerHTML = j.toString();
      item.style.backgroundColor = constraint.color;
      itemsDiv.appendChild(item);
    }

    var constraintText = document.createElement("label");
    constraintText.className = "label-gray whitespace-normal";
    constraintText.innerHTML = constraint.text;

    constraintContainer.appendChild(itemsDiv);
    constraintContainer.appendChild(constraintText);

    container.appendChild(constraintContainer);
    body.appendChild(container);
  }
}
