import { Component, OnInit } from '@angular/core';
import { listAnimation } from '@ex/module/shared/animations/allAnimations';
import { AnalyticsService } from '@ex/module/survey-x/data-access/services/analytics.service';
import { ExcelFeedbackDownloadService, Feedback } from '@ex/module/survey-x/data-access/services/excel-feedback-download.service';
import { SxBaseComponent } from '@ex/module/survey-x/ui/sx-base/sx-base.component';
import { FeedbackAnalysisService } from '@ex/survey-x/index';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-feedback-analysis',
  templateUrl: './feedback-analysis.component.html',
  styleUrls: ['./feedback-analysis.component.scss'],
  animations: [listAnimation]
})
export class FeedbackAnalysisComponent extends SxBaseComponent implements OnInit {

  showMore$ = new BehaviorSubject(0);
  maxResultCount = 10;

  searchTerm$ = new BehaviorSubject("");

  feedbackList = [];
  totalCount = 0;

  language = "EN"

  filters: any;

  constructor(private analyticsService: AnalyticsService
    , private feedbackAnalysisService: FeedbackAnalysisService
    , private excelFeedbackDownloadService: ExcelFeedbackDownloadService) { super() }

  ngOnInit(): void {

    this.addSubscription(this.analyticsService.downloadFeedback$.subscribe(f => {
      this.downloadFeedbackExcel();
    }))

    this.fetchFeedback();

    const sub = this.analyticsService.filters$.pipe(
      filter(x => x != null)
    ).subscribe(filters => {

      this.language = filters.language;

      this.filters = filters;

      this.feedbackList = [];

      this.filtersLoaded.next(filters);
    })

    this.addSubscription(sub);

  }


  fetchFeedback() {

    const sub = combineLatest([this.filtersLoaded$, this.showMore$, this.searchTerm$]).pipe(
      switchMap(([filters, page, search]) => {

        return this.feedbackAnalysisService.getList({
          tenantKey: filters.tenantKey,
          companyKey: filters.companyKey,
          formId: filters.survey.key,
          skipCount: page,
          maxResultCount: this.maxResultCount,
          isAllResults: false,
          searchTerm: search,
          filters: this.analyticsService.mapGlobalFilters(filters)
        });
      })
    ).subscribe(response => {
      this.totalCount = response.totalCount;
      this.feedbackList = this.feedbackList.concat(response.items);
    })

    this.addSubscription(sub);

  }


  private downloadFeedbackExcel(): void {
    this.feedbackAnalysisService.getList({
      tenantKey: this.filters.tenantKey,
      companyKey: this.filters.companyKey,
      formId: this.filters.survey.key,
      maxResultCount: this.maxResultCount,
      isAllResults: true,
    }).subscribe(values => {
      const feedbackData = values.items.map((element: any) => ({
        name: element.name,
        email: element.email,
        respondentImageURL: element.respondentImageURL,
        rating: element.rating,
        comment: element.comment,
        customProperties: element.customProperties
      }));

      this.excelFeedbackDownloadService.exportToExcel(feedbackData, 'feedback');
    })
  }


  exportToExcelButtonClick(): void {
    this.downloadFeedbackExcel();
  }


  onKeyUp(event) {
    this.feedbackList = [];
    this.searchTerm$.next(event.target.value);
    this.showMore$.next(0);
  }

  get isShowMore() {
    return this.feedbackList.length < this.totalCount;
  }

  showMoreComments(): void {
    this.showMore$.next(this.showMore$.value + 1)
  }


}
