import { HttpClient } from "@angular/common/http";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChildrenAnimation } from "src/app/shared/animations/allAnimations";
import { environment } from "src/environments/environment";
import { CreatorSurveyPageComponent, SurveyCreator } from "survey-creator-knockout";
import { surveyLocalization } from "survey-core";
import { SurveyCreatorModel, localization } from "survey-creator-core";
// import "survey-core/survey.i18n";
// import "survey-creator-core/survey-creator-core.i18n";
import { Serializer } from "survey-core";
import { Direction } from "@angular/cdk/bidi";
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { CustomDatepickerComponent } from "@ex/module/survey-x/ui/custom-datepicker/custom-datepicker.component";




const loc = localization.getLocale("en");
loc.ed.newPanelName = "Sub-Page";



//  surveyLocalization.supportedLocales = ["en", "de", "es", "fr", "ar"];

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  haveCommercialLicense: true,
  showTranslationTab: true,
  showJSONEditorTab: false,
  showSaveButton: true,
  showSearch: true
};

Serializer.getProperty("selectbase", "choicesByUrl").visible = false;

@Component({
  selector: 'app-form-creator',
  templateUrl: './form-creator.component.html',
  styleUrls: ['./form-creator.component.scss'],
  animations: [ChildrenAnimation]
})
export class FormCreatorComponent implements OnInit {
  surveyCreatorModel: SurveyCreatorModel;

  endPoint = environment.endPoint
  survey: any = {}
  direction: Direction = 'ltr';
  currentLocale: any;

  tenant: any;
  tenantName: any;
  tenantKey: any;
  currentUser: any;
  currentUserName: any;
  static declaration = [CustomDatepickerComponent];
  constructor(private client: HttpClient, private route: ActivatedRoute, private router: Router, private surveysService: SurveysService) { }

  async ngOnInit() {


    this.tenant = JSON.parse(localStorage.getItem("tenant")!)
    this.tenantName = this.tenant.name
    this.tenantKey = this.tenant.key
    this.currentUser = JSON.parse(localStorage.getItem("user")!);
    this.currentUserName = this.currentUser.firstName + " " + this.currentUser.lastName;

    // const creator = new SurveyCreator(creatorOptions);
    const creator = new SurveyCreatorModel(creatorOptions);

    creator.onGetPageActions.add((_, opt) => {
      opt.actions.push({
        title: "Add Sub-Page",
        action: () => {
          opt.addNewQuestion("panel");
        }
      });
    });

    let surveyId = this.route.snapshot.paramMap.get('id');
    var sub : any = await this.surveysService.getSurveyWithoutResponses(surveyId);

    this.survey = sub.query;

    console.log(this.survey)

    creator.toolbox.getItemByName("checkbox").title = "Checkboxes"
    creator.toolbox.getItemByName("image").title = "Image Choice"
    creator.toolbox.getItemByName("radiogroup").title = "Multiple Choice"
    creator.toolbox.getItemByName("boolean").title = "Binary (Yes/No)"
    creator.toolbox.getItemByName("imagepicker").title = "Image Choice"
    creator.toolbox.getItemByName("panel").title = "Sub-Page"
    // creator.toolbar.getPropertyByName("choices from a restful service").isVisible = 'false';

    creator.onPanelAdded.add((sender, options) => {
      const panel = options.panel;
      panel.title = panel.name;
      panel.description = "Description";
    });

    creator.text = this.survey.jsonText;

    this.currentLocale = this.survey.locale;
    if (this.currentLocale == "ar") {
      this.direction = "rtl"
    }

    creator.saveSurveyFunc = (saveNo: number, callback: Function) => {
      callback(saveNo, true);
      this.survey.jsonText = creator.text

      var jsonT = this.survey.jsonText;
      var jsonObject = JSON.parse(jsonT)

      var arrData: { key: string; value: any; }[] = [];
      Object.keys(jsonObject).forEach((itemK) => {
        arrData.push({ key: itemK, value: jsonObject[itemK] })
      })

      this.survey.jsonTextPair = JSON.stringify(arrData);
      this.survey.modifiedBy = this.currentUserName;

      saveSurveyJson(
        this.endPoint + "form-table/update-form/" + this.survey.id,
        this.survey,
        saveNo,
        callback
      );
    };

    this.surveyCreatorModel = creator;
    // creator.render("surveyCreator");
  }
}


function saveSurveyJson(url: string | URL, json: object, saveNo: number, callback: Function) {
  const request = new XMLHttpRequest();
  request.open('PUT', url);
  request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
  request.addEventListener('load', () => {
    callback(saveNo, true);
  });
  request.addEventListener('error', () => {
    callback(saveNo, false);
  });
  request.send(JSON.stringify(json));
}

