<div class="flex flex-col items-stretch gap-px">
  <ng-container *ngFor="let row of pageData">
    <div
      class="flex flex-row items-center justify-between h-14 gap-6 p-6 rounded-sm shadow-small shadow-primary-10 bg-white group hover:shadow-large mt-px">
      <ng-container *ngTemplateOutlet="rowTemplate; context:{$implicit:row}"></ng-container>
    </div>
  </ng-container>
</div>

<div class="flex flex-row justify-end items-center pt-6 gap-2" >
  <i *ngIf="pageNumbers?.length > 3 && currentPage > 2" (click)="goTo(currentPage - 1)"
    class="fa-regular fa-angle-right w-10 h-10 leading-10 rounded justify-center btn bg-grey bg-opacity-20 text-dark hover:text-secondary hover:bg-secondary-10 text-center"></i>
  <ng-container >
    <span (click)="goTo(currentPage -1)" *ngIf="currentPage != 1" class="w-10 h-10 leading-10 rounded justify-center btn grey hover:text-secondary hover:bg-secondary-10">{{currentPage -1}}</span>
    <span  class="w-10 h-10 leading-10 rounded justify-center btn primary">{{currentPage}}</span>
    <span (click)="goTo(currentPage +1)" *ngIf="pageNumbers?.length > currentPage && currentPage != pageNumbers?.length" class="w-10 h-10 leading-10 rounded justify-center btn grey hover:text-secondary hover:bg-secondary-10">{{currentPage+1}}</span>
  </ng-container>
  <i *ngIf="pageNumbers?.length > 3 && currentPage < pageNumbers?.length  -1" (click)="goTo(currentPage +1)"
    class="fa-regular fa-angle-left w-10 h-10 leading-10 rounded justify-center btn bg-grey bg-opacity-20 text-dark hover:text-secondary hover:bg-secondary-10 text-center"></i>

</div>
