import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ModuleName } from "@ex/module/shared/enums/ModuleName";
import { ModalComponent } from "@ex/module/shared/modal/modal.component";
import { LanguageFilterService } from "@ex/module/survey-x/data-access/services/language-filter.service";

;

@Component({
  selector: 'app-confirm-submission-modal',
  templateUrl: './confirm-submission-modal.component.html',
  styleUrls: ['./confirm-submission-modal.component.scss']
})
export class ConfirmSubmissionModalComponent extends ModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Output() openChange = new EventEmitter();
  @Output() submitSurvey = new EventEmitter();

  @Input() language = 'EN';
  ModuleNames = ModuleName;
  selectedLocaleFilter = "";
  selectedLocalFilterInStorage: string | null = "";
  survey: any = {};
  direction: Direction = "ltr"


  constructor(private languageFilterService: LanguageFilterService) {
    super();

  }

  ngOnInit(): void {



  }

  onSubmit() {
    this.submitSurvey.emit();
    this.close();
  }

  close() {
    this.openChange.emit(false);
  }
}
