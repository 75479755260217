<div class="fixed flex flex-col items-stretch top-0 left-0 Page h-screen w-screen z-50">
    <app-form-builder-header
    [surveyId]="surveyId"
    [surveyTitle]="survey.title  | sjsTranslate:language"
    [currentPage]="'languages'"
    [showAssignWeights]="showAssignWeights"
    [language]="language"
    [dir]="direction"
    [isUpdating]="(updatedCreator?.notifier.active && updatedCreator?.notifier.isDisplayed)"
    (nextStep)="nextStep()"
    (previousStep)="previousStep()"
    (pageChanged)="pageChanged($event)">
    </app-form-builder-header>

    <div [dir]="direction" class="w-full h-100vh-110px">
        <app-custom-form-builder [companyName]="companyName"  [creator]="surveyCreatorModel" (creatorChange)="updatedCreator = $event" [surveyId]="surveyId" [language]="language"></app-custom-form-builder>
    </div>

    <loader *ngIf="loader.showLoader"></loader>
</div>
