<div class="zen-main-container flex flex-row items-stretch min-h-screen" *ngIf="tenant.setup" (click)="logData(selectedEntity)">
  <div class="flex flex-col w-80 min-w-80 min-h-screen-70  gap-5  shadow-inner overflow-x-hidden relative">
    <div class="fixed w-inherit h-inherit flex flex-col gap-12 max-h-screen-70 innerListScroll min-h-screen-70 bg-opacity-5 bg-zen pt-20">
      <h6 [@child10] class="text-xs text-zen text-opacity-50 uppercase px-8">
        {{ "txt_emp_ex_settings" | translate: language:ModuleNames.Common }}
      </h6>
      <div class="flex flex-col">
        <ng-container *ngFor="let button of (user.role == 'Super Admin'? menu: (menu|slice:2:10))">
          <menu-button (click)="tabChange(button.index)" [icon]="tab == button.index? 'text-exp ' + button.icon: 'opacity-50 '+ button.icon" [active]="tab == button.index">
            {{ button.name | translate: language:ModuleNames.Common }}
          </menu-button>
        </ng-container>

        <!-- <div [@child1] *ngIf="user.role == 'Super Admin'" [ngClass]="{
            'bg-zenSub font-medium border-zen-light border-opacity-20 border-t border-b':
              tab == 0,
            'rounded-l-md border-l -mr-10 translate-x-px':
              tab == 0 && !(language | language ),
            'rounded-r-md border-r -ml-10 -translate-x-px':
              tab == 0 && (language | language ),
            ' opacity-50 filter grayscale border border-transparent': tab != 0
          }" (click)="tabChange(0)" class="flex flex-row gap-3 h-24 cursor-pointer transform px-6 items-center group">
          <i class="far fa-building text-exp filter group-hover:grayscale"></i>
          <h5 class="text-zen">
            {{ "txt_companies" | translate: language:ModuleNames.Common }}
          </h5>
        </div>
        <div [@child2] *ngIf="user.role == 'Super Admin'" [ngClass]="{
            'bg-zenSub font-medium border-zen-light border-opacity-20 border-t border-b':
              tab == 1,
            'rounded-l-md border-l -mr-10 translate-x-px':
              tab == 1 && !(language | language ),
            'rounded-r-md border-r -ml-10 -translate-x-px':
              tab == 1 && (language | language ),
            ' opacity-50 filter grayscale border border-transparent': tab != 1
          }" (click)="tabChange(1)" class="flex flex-row gap-3 h-24 cursor-pointer transform px-6 items-center group">
          <i class="far fa-users text-exp"></i>
          <h5 class="text-zen">
            {{ "txt_users" | translate: language:ModuleNames.Common }}
          </h5>
        </div>

        <div [@child12] [ngClass]="{
            'bg-zenSub font-medium border-zen-light border-opacity-20 border-t border-b':
              tab == 2,
            'rounded-l-md border-l -mr-10 translate-x-px':
              tab == 2 && !(language | language ),
            'rounded-r-md border-r -ml-10 -translate-x-px':
              tab == 2 && (language | language ),
            ' opacity-50 filter grayscale border border-transparent': tab != 2
          }" (click)="tabChange(2)" class="flex flex-row gap-3 h-24 cursor-pointer transform px-6 items-center group">
          <i class="far fa-sitemap text-exp"></i>
          <h5 class="text-zen">
            {{
            "txt_company_structure" | translate: language:ModuleNames.Common
            }}
          </h5>
        </div>

        <div [@child11] [ngClass]="{
            'bg-zenSub font-medium border-zen-light border-opacity-20 border-t border-b':
              tab == 3,
            'rounded-l-md border-l -mr-10 translate-x-px':
              tab == 3 && !(language | language ),
            'rounded-r-md border-r -ml-10 -translate-x-px':
              tab == 3 && (language | language ),
            ' opacity-50 filter grayscale border border-transparent': tab != 3
          }" (click)="tabChange(3)" class="flex flex-row gap-3 h-24 cursor-pointer transform px-6 items-center group">
          <i class="far fa-tachometer text-exp"></i>
          <h5 class="text-zen">
            {{
            "txt_performance_rate" | translate: language:ModuleNames.Common
            }}
          </h5>
        </div>

        <div [@child12] [ngClass]="{
            'bg-zenSub font-medium border-zen-light border-opacity-20 border-t border-b':
              tab == 4,
            'rounded-l-md border-l -mr-10 translate-x-px':
              tab == 4 && !(language | language ),
            'rounded-r-md border-r -ml-10 -translate-x-px':
              tab == 4 && (language | language ),
            ' opacity-50 filter grayscale border border-transparent': tab != 4
          }" (click)="tabChange(4)" class="flex flex-row gap-3 h-24 cursor-pointer transform px-6 items-center group">
          <i class="far fa-signal text-exp"></i>
          <h5 class="text-zen">
            {{ "txt_bandlevel" | translate: language:ModuleNames.Common }}
          </h5>
        </div>

        <div [@child13] [ngClass]="{
            'bg-zenSub font-medium border-zen-light border-opacity-20 border-t border-b':
              tab == 5,
            'rounded-l-md border-l -mr-10 translate-x-px':
              tab == 5 && !(language | language ),
            'rounded-r-md border-r -ml-10 -translate-x-px':
              tab == 5 && (language | language ),
            ' opacity-50 filter grayscale border border-transparent': tab != 5
          }" (click)="tabChange(5)" class="flex flex-row gap-3 h-24 cursor-pointer transform px-6 items-center group">
          <i class="far fa-id-card text-exp"></i>
          <h5 class="text-zen">
            {{ "txt_job_grade" | translate: language:ModuleNames.Common }}
          </h5>
        </div>
        <div [@child13] [ngClass]="{
          'bg-zenSub font-medium border-zen-light border-opacity-20 border-t border-b':
            tab == 7,
          'rounded-l-md border-l -mr-10 translate-x-px':
            tab == 7 && !(language | language),
          'rounded-r-md border-r -ml-10 -translate-x-px':
            tab == 7 && (language | language),
          ' opacity-50 filter grayscale border border-transparent': tab != 7
        }" (click)="tabChange(7)" class="flex flex-row gap-3 h-24 cursor-pointer transform px-6 items-center group">
          <i class="far fa-plug text-exp"></i>
          <h5 class="text-zen">
            {{ "txtExtraCriteria" | translate: language:ModuleNames.Common }}
          </h5>
        </div>
        <div [@child13] [ngClass]="{
            'bg-zenSub font-medium border-zen-light border-opacity-20 border-t border-b':
              tab == 7,
            'rounded-l-md border-l -mr-10 translate-x-px':
              tab == 7 && !(language | language ),
            'rounded-r-md border-r -ml-10 -translate-x-px':
              tab == 7 && (language | language ),
            ' opacity-50 filter grayscale border border-transparent': tab != 7
          }" (click)="tabChange(7)" class="flex flex-row gap-3 h-24 cursor-pointer transform px-6 items-center group">
          <i class="far fa-plug text-exp"></i>
          <h5 class="text-zen">
            {{ "txtIntegration" | translate: language:ModuleNames.Common }}
          </h5>
        </div>
        <div [@child14] [ngClass]="{
            'bg-zenSub font-medium border-zen-light border-opacity-20 border-t border-b':
              tab == 6,
            'rounded-l-md border-l -mr-10 translate-x-px':
              tab == 6 && !(language | language ),
            'rounded-r-md border-r -ml-10 -translate-x-px':
              tab == 6 && (language | language ),
            ' opacity-50 filter grayscale border border-transparent': tab != 6
          }" (click)="tabChange(6)" class="flex flex-row gap-3 h-24 cursor-pointer transform px-6 items-center group">
          <i class="far fa-bell text-exp"></i>
          <h5 class="text-zen">
            {{
            "txt_notification_settings"
            | translate: language:ModuleNames.Common
            }}
          </h5>
        </div> -->
      </div>
    </div>
  </div>
  <div class="flex flex-col w-full border-zen-light border-opacity-20 zen-main-container px-10 pt-14">
    <ng-container *ngIf="user.role == 'Super Admin'">
      <div [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 0">
        <div class="flex flex-row justify-between items-center">
          <h5 [@first] class="text-zen font-medium text-3xl">
            {{ "txt_companies" | translate: language:ModuleNames.Common }}
          </h5>
          <button (click)="newCompanyInitiate()" class="btn exp uppercase leading-12 rounded px-5">
            {{ "txt_new_Company" | translate: language:ModuleNames.Common }}
          </button>
        </div>
        <div class="my-8 py-5">
          <t-table *ngIf="companies" [options]="CompaniesOptions">
            <ng-template #cellTempate let-element let-column="column" let-colIndex="colIndex">
              <ng-container *ngIf="column.field == 'first'; else second">
                <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                  {{ extractNameLanguage(element.name) }}
                </h5>
              </ng-container>
              <ng-template #second>
                <ng-container *ngIf="column.field == 'second'; else third">
                  <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                    {{ returnCompanySize(element.companySize) }}
                  </h5>
                </ng-container>
              </ng-template>
              <ng-template #third>
                <ng-container *ngIf="column.field == 'third'; else options">
                  <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                    {{ element.Branches?.length }}
                  </h5>
                </ng-container>
              </ng-template>
              <ng-template #options>
                <ng-container *ngIf="column.field == 'options'">
                  <div class="flex flex-row justify-end items-center gap-2 h-full">
                    <i [ZMenuTriggerFor]="menu" class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full btn text-center"></i>
                    <z-menu #menu>
                      <menuItems>
                        <a MenuItem (click)="editCompanyObject(index, element)">{{
                          "btn_leaver_list_edit"
                          | translate: language:ModuleNames.Common
                          }}</a>
                      </menuItems>
                    </z-menu>
                  </div>
                </ng-container>
              </ng-template>
            </ng-template>
          </t-table>

          <EmptySection *ngIf="companies && companies.length == 0"></EmptySection>
        </div>
      </div>

      <!-- users -->
      <div [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 1">
        <div class="flex flex-row justify-between items-center">
          <h5 [@first] class="text-zen font-medium text-3xl">
            {{ "txt_users" | translate: language:ModuleNames.Common }}
          </h5>
          <button class="btn exp uppercase leading-12 rounded px-5" (click)="newUserInitiate(); isSuperUser = false">
            {{ "btn_add_user" | translate: language:ModuleNames.Common }}
          </button>
        </div>
        <div class="my-8 py-5">
          <t-table *ngIf="users" [options]="usersOptions">
            <ng-template #cellTempate let-element let-column="column" let-colIndex="colIndex">
              <ng-container *ngIf="column.field == 'first'; else second">
                <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                  {{ element.firstName + " " + element.lastName }}
                </h5>
              </ng-container>
              <ng-template #second>
                <ng-container *ngIf="column.field == 'second'; else third">
                  <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                    {{
                    element.role == "Super Admin"
                    ? ("All registered companies"
                    | translate: language:ModuleNames.Common)
                    : extractUserCompanies(element)
                    }}
                  </h5>
                </ng-container>
              </ng-template>
              <ng-template #third>
                <ng-container *ngIf="column.field == 'third'; else options">
                  <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                    {{
                    (element.isActive ? "txt_active" : "txt_inactive")
                    | translate: language:ModuleNames.Common
                    }}
                  </h5>
                </ng-container>
              </ng-template>
              <ng-template #options>
                <ng-container *ngIf="column.field == 'options'">
                  <div class="flex flex-row justify-end items-center gap-2 h-full">
                    <i [ZMenuTriggerFor]="menu" class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full btn text-center"></i>
                    <z-menu #menu>
                      <menuItems>
                        <ng-container *ngIf="element.isActive">
                          <a MenuItem (click)="editUser(element, index)"><i class="fal fa-pen mx-1"></i>{{
                            "btn_leaver_list_edit"
                            | translate: language:ModuleNames.Common
                            }}</a>
                          <a MenuItem [disabled]="!element.isActive" (click)="resetPassword(element.email)"><i class="fal fa-undo mx-1"></i>{{
                            "txtResetPassword"
                            | translate: language:ModuleNames.Common
                            }}</a>
                          <a MenuItem delete *ngIf="user.key != element.key && element.isActive" (click)="deleteUser(element)"><i class="fal fa-trash mx-1"></i>{{
                            "txt_deactivate"
                            | translate: language:ModuleNames.Common
                            }}</a>
                        </ng-container>
                        <ng-container *ngIf="!element.isActive">
                          <a MenuItem (click)="activateUser(element)"><i class="fal fa-undo mx-1"></i>{{
                            "txt_activate"
                            | translate: language:ModuleNames.Common
                            }}</a>
                        </ng-container>
                      </menuItems>
                    </z-menu>
                  </div>
                </ng-container>
              </ng-template>
            </ng-template>
          </t-table>

          <!-- <zen-table *ngIf="users"  [columns]="usersColumns" >
            <ng-template #cellTempate let-element let-column='column' let-index>
              <div *ngIf="column.field == 'first'">
                <div class="flex flex-col justify-between px-5 pt-4 pb-7">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{txtFullName}}</h5>
                  <h5 class="max-w-xs truncate text-sm text-zen leading-20 ">{{ element.firstName + ' ' +
                    element.lastName}}
                  </h5>

                </div>
              </div>

              <div [matTooltip]="extractUserCompanies(element)? extractUserCompanies(element) : 'NA'"
                *ngIf="column.field == 'second'" class="flex flex-col justify-between px-5 pt-4 pb-7 w-60">
                <h5 class="text-sm text-zen text-opacity-40 ">{{txtCompanies}}</h5>
                <h5 class="max-w-xs truncate text-sm text-zen leading-20  truncate">{{
                  extractUserCompanies(element)? extractUserCompanies(element) : 'NA' }}</h5>
              </div>
              <div *ngIf="column.field == 'third'" class="flex flex-col justify-between px-5 pt-4 pb-7">
                <h5 class="text-sm text-zen text-opacity-40 ">{{txt_status}}</h5>
                <h5 class="max-w-xs truncate text-sm text-zen leading-20 ">{{ (element.isActive ?"txt_active"
                  :"txt_inactive")| translate:language:ModuleNames.Common}}
                </h5>
              </div>


              <div *ngIf="column.field == 'fourth'" class="flex flex-col justify-center items-end px-4">
                <button mat-icon-button [matMenuTriggerFor]="menu2"><i
                    class="fal font-medium text-xl fa-ellipsis-v"></i></button>
                <mat-menu class="custom-table-menu" #menu2="matMenu">

                  <div class="flex flex-col items-stretch px-2 gap-2">
                    <ng-container *ngIf="element.isActive">
                      <button class="text-sm font-medium btn cancel leading-10 rounded px-5 w-full"
                        data-mod="userEditIndex" (click)="editUser(element, index)"
                        [matTooltip]="btn_leaver_list_edit"><i class=" fal fa-pen mx-1"></i>{{'btn_leaver_list_edit'|
                        translate:language:ModuleNames.Common }}</button>
                      <button class="text-sm font-medium btn cancel leading-10 rounded px-5 w-full"
                        [matTooltip]="'txtResetPassword'| translate:language:ModuleNames.Common "
                        (click)="resetPassword(element.email)" [disabled]="(!element.isActive)"><i
                          class="fal fa-undo mx-1"></i>{{'txtResetPassword'| translate:language:ModuleNames.Common
                        }}</button>
                      <button class="text-sm font-medium btn cancel leading-10 rounded px-5 w-full"
                        [matTooltip]="'btn_leaver_list_delete'| translate:language:ModuleNames.Common "
                        (click)="deleteUser(element)" [disabled]="((user.key == element.key) || !element.isActive)"><i
                          class=" fal fa-trash mx-1"></i>{{'txt_deactivate'| translate:language:ModuleNames.Common
                        }}</button>
                    </ng-container>
                    <ng-container *ngIf="!element.isActive">

                      <button class="text-sm font-medium btn cancel leading-10 rounded px-5 w-full"
                        [matTooltip]="'Activate'" (click)="activateUser(element)"><i
                          class="fal fa-undo mx-1"></i>{{'txt_activate'| translate:language:ModuleNames.Common
                        }}</button>

                    </ng-container>
                  </div>


                </mat-menu>

              </div>

            </ng-template>
          </zen-table> -->
          <EmptySection *ngIf="users && users.length == 0"></EmptySection>
        </div>
      </div>
    </ng-container>

    <div [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 2">
      <div class="flex flex-row justify-between items-center">
        <h5 [@first] class="text-zen font-medium text-3xl">
          {{ "txt_company_structure" | translate: language:ModuleNames.Common }}
        </h5>
        <button class="btn exp uppercase leading-12 rounded px-5" (click)="editEntityGroup()">
          {{
          "btn_add_structure_entity" | translate: language:ModuleNames.Common
          }}
        </button>
      </div>
      <div class="my-8 py-5">
        <t-table *ngIf="entitiesOptions?.data?.length" [options]="entitiesOptions">
          <ng-template #cellTempate let-element let-column="column" let-colIndex="colIndex">
            <ng-container *ngIf="column.field == 'first'; else options">
              <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                {{ getEntityGroupName(element) }}
              </h5>
            </ng-container>

            <ng-template #options>
              <ng-container *ngIf="column.field == 'options'">
                <div class="flex flex-row justify-end items-center gap-2 h-full">
                  <i *ngIf="element.isCompanyGroup != true" [ZMenuTriggerFor]="menu" class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full btn text-center"></i>
                  <z-menu #menu>
                    <menuItems>
                      <a [disabled]="element.isCompanyGroup" MenuItem (click)="editEntityGroup(element)"><i class="fal fa-pen"></i>{{
                        "btn_leaver_list_edit"
                        | translate: language:ModuleNames.Common
                        }}</a>
                      <a [disabled]="element.isCompanyGroup" MenuItem delete [disabled]="!element.isActive" (click)="deleteStructure(element, index)"><i class="fal fa-trash"></i>{{
                        "btn_leaver_list_delete"
                        | translate: language:ModuleNames.Common
                        }}</a>
                    </menuItems>
                  </z-menu>
                </div>
              </ng-container>
            </ng-template>
          </ng-template>
        </t-table>

        <EmptySection *ngIf="
            selectedEntity?.EntityGroups &&
            selectedEntity?.EntityGroups.length == 0
          "></EmptySection>
      </div>
    </div>

    <div [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 3">
      <div class="flex flex-row justify-between items-center">
        <h5 [@first] class="text-zen font-medium text-3xl">
          {{ "txt_performance_rate" | translate: language:ModuleNames.Common }}
        </h5>
        <button class="btn exp uppercase leading-12 rounded px-5" (click)="newPerformanceInitiate()">
          {{ "btn_performance_add" | translate: language:ModuleNames.Common }}
        </button>
      </div>
      <div class="my-8 py-5">
        <t-table *ngIf="selectedEntity?.PerformanceRates" [options]="performanceOptions">
          <ng-template #cellTempate let-element let-column="column" let-colIndex="colIndex">
            <ng-container *ngIf="column.field == 'first'; else second">
              <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                {{ element.value }}
              </h5>
            </ng-container>
            <ng-template #second>
              <ng-container *ngIf="column.field == 'second'; else third">
                <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                  {{ extractNameLanguage(element.description) }}
                </h5>
              </ng-container>
            </ng-template>
            <ng-template #third>
              <ng-container *ngIf="column.field == 'third'; else options">
                <fa-icon class="" [icon]="faStar" [ngStyle]="{ color: element.icon }"></fa-icon>
              </ng-container>
            </ng-template>
            <ng-template #options>
              <ng-container *ngIf="column.field == 'options'">
                <div class="flex flex-row justify-end items-center gap-2 h-full">
                  <i [ZMenuTriggerFor]="menu" class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full btn text-center"></i>
                  <z-menu #menu>
                    <menuItems>
                      <a MenuItem (click)="editPerformanceObject(index, element)"><i class="fal fa-pen mx-1"></i>{{
                        "btn_leaver_list_edit"
                        | translate: language:ModuleNames.Common
                        }}</a>

                      <a MenuItem delete *ngIf="user.key != element.key && !element.isActive" (click)="deletePerformance(element, index)"><i class="fal fa-trash mx-1"></i>{{
                        "btn_leaver_list_delete"
                        | translate: language:ModuleNames.Common
                        }}</a>
                    </menuItems>
                  </z-menu>
                </div>
              </ng-container>
            </ng-template>
          </ng-template>
        </t-table>

        <EmptySection *ngIf="
            selectedEntity?.PerformanceRates &&
            selectedEntity?.PerformanceRates.length == 0
          ">
        </EmptySection>
      </div>
    </div>
    <div [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 4">
      <div class="flex flex-row justify-between items-center">
        <h5 [@first] class="text-zen font-medium text-3xl">
          {{ "txt_bandlevel" | translate: language:ModuleNames.Common }}
        </h5>
        <button class="btn exp uppercase leading-12 rounded px-5" (click)="newBandInitiate()">
          {{ "btn_add_band_level" | translate: language:ModuleNames.Common }}
        </button>
      </div>
      <div class="my-8 py-5">
        <t-table *ngIf="selectedEntity?.BandLevels" [options]="bandOptions">
          <ng-template #cellTempate let-element let-column="column" let-colIndex="colIndex">
            <ng-container *ngIf="column.field == 'first'; else options">
              <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                {{ extractNameLanguage(element?.name) }}
              </h5>
            </ng-container>

            <ng-template #options>
              <ng-container *ngIf="column.field == 'options'">
                <div class="flex flex-row justify-end items-center gap-2 h-full">
                  <i [ZMenuTriggerFor]="menu" class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full btn text-center"></i>
                  <z-menu #menu>
                    <menuItems>
                      <a MenuItem (click)="editBandLevelObject(index, element)"><i class="fal fa-pen mx-1"></i>{{
                        "btn_leaver_list_edit"
                        | translate: language:ModuleNames.Common
                        }}</a>

                      <a MenuItem delete (click)="deleteBandLevel(element, index)"><i class="fal fa-trash mx-1"></i>{{
                        "btn_leaver_list_delete"
                        | translate: language:ModuleNames.Common
                        }}</a>
                    </menuItems>
                  </z-menu>
                </div>
              </ng-container>
            </ng-template>
          </ng-template>
        </t-table>

        <EmptySection *ngIf="
            selectedEntity?.BandLevels && selectedEntity?.BandLevels.length == 0
          "></EmptySection>
      </div>
    </div>
    <div [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 5">
      <div class="flex flex-row justify-between items-center">
        <h5 [@first] class="text-zen font-medium text-3xl">
          {{ "txt_job_grade" | translate: language:ModuleNames.Common }}
        </h5>
        <button class="btn exp uppercase leading-12 rounded px-5" (click)="newJobGradeInitiate()">
          {{ "btn_add_job_grade" | translate: language:ModuleNames.Common }}
        </button>
      </div>
      <div class="my-8 py-5">
        <t-table *ngIf="selectedEntity?.JobGrades" [options]="jobOptions">
          <ng-template #cellTempate let-element let-column="column" let-colIndex="colIndex">
            <ng-container *ngIf="column.field == 'first'; else second">
              <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                {{ extractNameLanguage(element?.name) }}
              </h5>
            </ng-container>
            <ng-template #second>
              <ng-container *ngIf="column.field == 'second'; else options">
                <h5 class="max-w-xs truncate text-sm text-zen leading-20">
                  {{
                  extractBandLevelName(element.bandLevelKey)
                  ? extractBandLevelName(element.bandLevelKey)
                  : "NA"
                  }}
                </h5>
              </ng-container>
            </ng-template>
            <ng-template #options>
              <ng-container *ngIf="column.field == 'options'">
                <div class="flex flex-row justify-end items-center gap-2 h-full">
                  <i [ZMenuTriggerFor]="menu" class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full btn text-center"></i>
                  <z-menu #menu>
                    <menuItems>
                      <a MenuItem (click)="editJobGradeObject(index, element)"><i class="fal fa-pen mx-1"></i>{{
                        "btn_leaver_list_edit"
                        | translate: language:ModuleNames.Common
                        }}</a>

                      <a MenuItem delete (click)="deleteJobGrade(element, index)"><i class="fal fa-trash mx-1"></i>{{
                        "btn_leaver_list_delete"
                        | translate: language:ModuleNames.Common
                        }}</a>
                    </menuItems>
                  </z-menu>
                </div>
              </ng-container>
            </ng-template>
          </ng-template>
        </t-table>

        <EmptySection *ngIf="
            selectedEntity?.JobGrades && selectedEntity?.JobGrades.length == 0
          "></EmptySection>
      </div>
    </div>
    <div [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 6">
      <select-criteria [selection]="false" [(modal)]="criteriaModal" [language]="language" [selectedEntity]="selectedEntity"
        [matrixList]="selectedEntity.metrics" [engagementMetrics]="selectedEntity.metrics"
        (updateEngagementMetrics)="$event.tKey >= 0?updateEngagementMetrics($event.metric, $event.tKey):updateEngagementMetrics($event)">
        <ng-container head>
          <div class="flex flex-row items-center justify-between pb-8">
            <h5 [@first] class="text-zen font-medium text-3xl">
              {{"txtExtraCriteria"|
              translate:language:ModuleNames.Common}}
            </h5>
            <a (click)="criteriaModal = true" class="btn exp uppercase leading-12 h-12 block rounded px-5">
              {{"txt_new_criteria"| translate:language:ModuleNames.Shared}}</a>
          </div>

        </ng-container>

      </select-criteria>

    </div>
    <div [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 7">
      <h5 [@first] class="text-zen font-medium text-3xl">
        {{ "txtIntegration" | translate: language:ModuleNames.Common }}
      </h5>
      <div class="flex flex-row flex-wrap my-8 py-5">
        <div class="p-7 mb-2 rounded-md group hover:bg-white hover:shadow-md transition-all 2xl:w-1/3 w-1/2 cursor-pointer" (click)="integrationModal = true">
          <div class="flex flex-row gap-5 items-center">
            <img [@child3] class="h-20 w-20 block rounded bg-white shadow group-hover:shadow-none object-center object-none transition-all" src="assets/img/svg/thomas.svg" alt="" />
            <div>
              <h5 [@child4] class="text-xl font-bold text-zen">
                {{
                "Thomas International"| translate: language:ModuleNames.Common
                }}
              </h5>
              <h5 [@child5] class="text-xxs font-normal text-zen mt-1">
                {{
                "TALENT ASSESSMENT" | translate: language:ModuleNames.Common
                }}
              </h5>
            </div>
          </div>
          <p [@child6] class="text-xs text-zen my-5">
            {{
            "Harness the power of psychology and machine learning with the Thomas talent assessment platform. Make smarter recruitment and development decisions." | translate: language:ModuleNames.Common }}
          </p>
          <div class="flex flex-row justify-end" *ngIf="tenant.thomasIntegrated">
            <p [@child4] class="text-zen-green text-xs font-medium">
              <i class="fal fa-check-circle"></i>
              {{ "Integrated" | translate: language:ModuleNames.Common }}
            </p>
          </div>
        </div>

        <div class="p-7 mb-2 rounded-md group hover:bg-white hover:shadow-md transition-all 2xl:w-1/3 w-1/2 cursor-pointer" (click)="excelIntegration = true">
          <div class="flex flex-row gap-5 items-center">
            <img [@child3] class="h-20 w-20 block rounded bg-white shadow group-hover:shadow-none object-center object-none transition-all" src="assets/img/excel.png" alt="" style="object-fit: cover" />
            <div>
              <h5 [@child4] class="text-xl font-bold text-zen">
                {{
                "Excel Integration"
                | translate: language:ModuleNames.Common
                }}
              </h5>
              <h5 [@child5] class="text-xxs font-normal text-zen mt-1">
                Zenithr
              </h5>
            </div>
          </div>
          <p [@child6] class="text-xs text-zen my-5">
            {{
            "Harness the simplicity of integrating your data using Microsoft  Excel"
            | translate: language:ModuleNames.Common
            }}
          </p>
          <div class="flex flex-row justify-end" *ngIf="selectedEntity.DataIntegrated">
            <p [@child4] class="text-zen-green text-xs font-medium">
              <i class="fal fa-check-circle"></i>
              {{ "Integrated" | translate: language:ModuleNames.Common }}
            </p>
          </div>
        </div>

        <!-- <div
      class="p-7 mb-2 rounded-md group hover:bg-white hover:shadow-md transition-all 2xl:w-1/3 w-1/2 cursor-pointer"
      (click)="excelIntegration = true">
      <div class="flex flex-row gap-5 items-center">
        <img [@child3]
          class="h-20 w-20 block rounded bg-white shadow group-hover:shadow-none object-center object-none transition-all"
          src="assets/img/WhatsappB.png" alt="" style="object-fit: cover;">
        <div>
          <h5 [@child4] class="text-xl font-bold text-zen">WhatsApp Integration</h5>
          <h5 [@child5] class="text-xxs font-normal text-zen mt-1">WhatsApp By Meta</h5>
        </div>
      </div>
      <p [@child6] class="text-xs text-zen my-5">Integrate your WhatsApp API and Secret and send your surveys on Whatsapp</p>
    </div> -->
      </div>
    </div>
    <div [@slidesAniamtion]="resolveRoute(tab)" *ngIf="tab == 8">
      <!-- Notifications -->
      <!-- <h5 class="text-zen">{{ txtNotificationSettings }}</h5> -->
      <h5 [@first] class="text-zen font-medium text-3xl">
        {{
        "txt_notification_settings" | translate: language:ModuleNames.Common
        }}
      </h5>
      <div class="my-8 py-5">
        <!-- <div class="flex flex-row gap-4 w-full exp">
          <div class="flex flex-row md:w-1/2 w-full justify-between items-center bg-white rounded py-3 px-4 mb-4">
            <h5 [@child3]>{{ 'txt_notification_notification_started'| translate:language:ModuleNames.Common }}</h5>
            <mat-slide-toggle [@child5] (change)="updateUserEntities(user)" [(ngModel)]="user.startedNotification">
            </mat-slide-toggle>
          </div>
          <div class="flex flex-row md:w-1/2 w-full justify-between items-center bg-white rounded py-3 px-4 mb-4">
            <h5 [@child5] class="m-0">{{ 'txt_notification_completed_questionnaire'|
              translate:language:ModuleNames.Common }}</h5>
            <mat-slide-toggle [@child7] (change)="updateUserEntities(user)" [(ngModel)]="user.completedNotification">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="flex flex-row gap-4 w-full exp">
          <div class="flex flex-row md:w-1/2 w-full justify-between items-center bg-white rounded py-3 px-4 mb-4">
            <h5 class="m-0" [@child6]>{{ 'txt_notification_questionnaire_expiring'|
              translate:language:ModuleNames.Common }}</h5>
            <mat-slide-toggle [@child8] (change)="updateUserEntities(user)" [(ngModel)]="user.expiringNotification">
            </mat-slide-toggle>

          </div>
          <div class="flex flex-row md:w-1/2 w-full justify-between items-center bg-white rounded py-3 px-4 mb-4">
            <h5 [@child8] class="m-0">{{ 'txt_notification_questionnaire_expired'| translate:language:ModuleNames.Common
              }}</h5>
            <mat-slide-toggle [@child10] (change)="updateUserEntities(user)" [(ngModel)]="user.expiredNotification">
            </mat-slide-toggle>

          </div>
        </div> -->
        <div class="flex flex-row gap-4 w-full exp">
          <div class="flex flex-row md:w-1/2 w-full justify-between items-center bg-white rounded py-3 px-4 mb-4">
            <h5 [@child8] class="m-0">
              {{
              "txt_notification_remaining_units"
              | translate: language:ModuleNames.Common
              }}
            </h5>
            <mat-slide-toggle [@child10] (change)="updateUserEntities(user)" [(ngModel)]="user.remainingUnits">
            </mat-slide-toggle>
          </div>
          <div class="flex flex-row md:w-1/2 w-full justify-between items-center bg-white rounded py-3 px-4 mb-4">
            <h5 [@child10] class="m-0">
              {{
              "txt_notification_subscription_expiring"
              | translate: language:ModuleNames.Common
              }}
            </h5>
            <mat-slide-toggle [@child12] (change)="updateUserEntities(user)" [(ngModel)]="user.subscriptionExpiring">
            </mat-slide-toggle>
          </div>
        </div>
        <div class="flex flex-row gap-4 w-full exp">
          <div class="flex flex-row md:w-1/2 w-full justify-between items-center bg-white rounded py-3 px-4 mb-4">
            <h5 [@child10] class="m-0">
              {{
              "txt_notification_subscription_expired"
              | translate: language:ModuleNames.Common
              }}
            </h5>
            <mat-slide-toggle [@child12] (change)="updateUserEntities(user)" [(ngModel)]="user.subscriptionExpired">
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<z-modal *ngIf="companyAdd" [(open)]="companyAdd">
  <ng-container header>
    <div class="flex flex-row gap-2 items-center">
      <i class="fal fa-book text-white bg-exp shadow-xl rounded-full h-10 w-10 text-xl text-center leading-10"></i>
      <h4 class="text-2xl font-bold text-zen">
        {{
        editMode
        ? extractNameLanguage(newCompany.name)
        : ("txt_modal_company" | translate: language:ModuleNames.Common)
        }}
      </h4>
    </div>
  </ng-container>

  <ng-container body>
    <div class="flex flex-col gap-5">
      <z-translation-input [invalid]="companyInvalid" [maxCount]="mdCount" [required]="true" [label]="'txt_company_name' | translate: language:ModuleNames.Common" [language]="language" [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="newCompany.name"></z-translation-input>

      <div class="flex flex-row justify-between items-center gap-2 w-full">
        <div class="w-full flex flex-col">
          <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [(ngModel)]="newCompany.companySize" [label]="
              'txt_company_size' | translate: language:ModuleNames.Common
            " [options]="companySizesList" [language]="language">
            <ng-template #valueTemplate let-value>
              {{ value?.name }}
            </ng-template>
          </z-select>
        </div>
        <div class="w-full flex flex-col">
          <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [(ngModel)]="newCompany.industry" [label]="
              'txt_company_industry' | translate: language:ModuleNames.Common
            " [options]="industriesList" [language]="language">
            <ng-template #valueTemplate let-value>
              {{ value?.name }}
            </ng-template>
          </z-select>
        </div>
      </div>
      <div class="flex flex-row justify-between items-center gap-2 w-full mb-2">
        <div class="w-1/2">
          <!-- <custom-dropdown *ngIf="!resetDropdown" class="customDropDown" [txtBranches]="txtHeadQuarter"
            [selectionMode]="'Single'" [dropDownData]="countriesList" [selectedKey]="newCompany.headQuarter"
            (selectionChanged)="dropDownChanged($event, newCompany)">
          </custom-dropdown> -->

          <z-select [color]="'exp'" [(ngModel)]="newCompany.headQuarter" (selectionChanged)="dropDownChanged($event, newCompany)" [label]="'txtHeadQuarter' | translate: language:ModuleNames.Common" [options]="originalCountriesList" [language]="language">
            <ng-template #valueTemplate let-value>
              {{ value?.name | extract: language }}
            </ng-template>
          </z-select>
        </div>
        <div class="w-1/2">
          <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [multiple]="true" [(ngModel)]="newCompany.Branches" (selectionChanged)="branchesSelectionChanged($event, newCompany)" [label]="'txt_branches' | translate: language:ModuleNames.Common" [options]="originalCountriesList" [language]="language">
            <ng-template #valueTemplate let-value>
              {{ value?.name | extract: language }}
            </ng-template>
          </z-select>

          <!-- <span *ngIf="!resetDropdown" [matTooltip]="txtFieldIsRequired" [matTooltipClass]="'toolTipAlert'"
            [matTooltipDisabled]="!vCompanyBranches" [className]="vCompanyBranches? ' alertLine' : '' ">
            <custom-dropdown class="customDropDown" [txtBranches]="txtBranches" [selectionMode]="'Multi'"
              [dropDownData]="countriesList" [selectedOptions]="newCompany.Branches"
              (selectionChanged)="branchesSelectionChanged($event, newCompany)">
            </custom-dropdown>
          </span> -->
        </div>
      </div>
      <div class="flex flex-row justify-between items-center gap-2 w-full mb-2">
        <div class="w-1/2">
          <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [multiple]="true" [(ngModel)]="newCompany.ContentLanguages" [label]="
              'txt_content_languages' | translate: language:ModuleNames.Common
            " [options]="supportedLanguages" [language]="language">
            <ng-template #valueTemplate let-value>
              {{ value?.name }}
            </ng-template>
          </z-select>
        </div>

        <div class="w-1/2">
          <z-select [invalid]="companyInvalid" [required]="true" [color]="'exp'" [(ngModel)]="newCompany.contactCode" [label]="
              'txt_country_code' | translate: language:ModuleNames.Common
            " [options]="originalCountriesList" [language]="language">
            <ng-template #valueTemplate let-value>
              {{ value?.countryCode }}
            </ng-template>
            <ng-template #optionTemplate let-option>
              {{ option?.name | extract: language }}
              {{ "(" + option?.countryCode + ")" }}
            </ng-template>
          </z-select>
        </div>
      </div>
      <div class="flex flex-row justify-between items-center gap-2 w-full">
        <div class="w-full flex flex-col">
          <z-input [invalid]="companyInvalid" [required]="true" [language]="language" [label]="
              'txt_contact_number' | translate: language:ModuleNames.Common
            " [disabled]="countWord(newCompany.contactNumber, mdCount) > mdCount" [maxlength]="mdCount" [number]="true" [(ngModel)]="newCompany.contactNumber"></z-input>

          <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
            <mat-form-field class="w-full" appearance="none">
              <mat-label>{{ txtContactNumber }}</mat-label>
              <input #number="ngModel" matInput [maxlength]="mdCount" [disabled]="countWord(newCompany.contactNumber,
          mdCount) > mdCount " [(ngModel)]="newCompany.contactNumber">
            </mat-form-field>
          </div>
          <div *ngIf="number.invalid && (number.dirty || number.touched)"
            class="alert alert-danger transform translate-y-1">
            <div class="text-red-600 text-xs" *ngIf="number.errors.required">
              {{ txtFieldIsRequired }}
            </div>
          </div> -->
        </div>
        <div class="w-full flex flex-col">
          <z-input [language]="language" [label]="'txtWebsite' | translate: language:ModuleNames.Common" [maxlength]="mdCount" [(ngModel)]="newCompany.website"></z-input>
        </div>
      </div>

      <span [matTooltip]="
          'txt_required_field' | translate: language:ModuleNames.Common
        " [matTooltipClass]="'toolTipAlert'" [matTooltipDisabled]="!vLogo" [className]="vLogo ? ' alertLine' : ''">
        <div class="flex flex-row justify-between items-center w-full bg-zen bg-opacity-5 h-18 px-5 rounded">
          <img *ngIf="newCompany.logoURL" [src]="newCompany.logoURL" style="height: 45px; object-fit: cover; object-position: left" />
          <h5 *ngIf="!newCompany.logoURL" class="opacity-50 text-2xl uppercase">
            {{ "txtLogo" | translate: language:ModuleNames.Common }}
          </h5>
          <a class="btn cancel px-5 py-2 rounded-md cursor-pointer">
            {{ "btn_upload" | translate: language:ModuleNames.Common
            }}<input class="uploadInput cursor-pointer" id="fileselect" accept="image/x-png,image/gif,image/jpeg, image/jpg" (change)="selectFile($event, newCompany)" type="file" /></a>
        </div>
      </span>
    </div>
  </ng-container>
  <ng-container footer>
    <a (click)="closeModal('companyAdd')" class="btn cancel text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{ "btn_cancel" |
      translate: language:ModuleNames.Common }}</a>
    <button [disabled]="uploading" (click)="editMode ? updateCompany(newCompany) : createCompany()" class="btn exp w-auto text-center align-middle rounded-md px-8 py-4">
      <span class="mx-1">{{
        (editMode ? "btn_update" : "btn_company_add")
        | translate: language:ModuleNames.Common
        }}</span>
    </button>
  </ng-container>
</z-modal>

<!-- user Modal -->

<z-modal *ngIf="userAdd" [open]="userAdd">
  <ng-container header>
    <div class="flex flex-row gap-2 items-center">
      <i class="fal fa-user text-white bg-exp shadow-xl rounded-full h-110 w-10 text-xl text-center leading-10"></i>
      <h4 class="text-2xl font-bold text-zen">
        {{
        editMode
        ? newUser.firstName + " " + newUser.lastName
        : ("txt_modal_user" | translate: language:ModuleNames.Common)
        }}
      </h4>
      <!-- <p class="text-sm text-zen">Please reupload the data file after fixing the validation errors.</p> -->
    </div>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col gap-6">
      <div class="flex flex-row gap-2">
        <div class="w-full flex flex-col">
          <z-input [invalid]="userInvalid" [required]="true" [language]="language" [label]="
              'txt_user_firstName' | translate: language:ModuleNames.Common
            " [disabled]="countWord(newUser.firstName, mdCount) > mdCount" [maxlength]="mdCount" [(ngModel)]="newUser.firstName"></z-input>
        </div>
        <div class="w-full flex flex-col">
          <z-input [invalid]="userInvalid" [required]="true" [language]="language" [label]="
              'txt_user_lastName' | translate: language:ModuleNames.Common
            " [disabled]="countWord(newUser.lastName, mdCount) > mdCount" [maxlength]="mdCount" [(ngModel)]="newUser.lastName"></z-input>
        </div>
      </div>
      <div class="flex flex-row gap-2">
        <div class="w-full flex flex-col">
          <z-input [email]="true" [invalid]="userInvalid" [required]="true" [language]="language" [label]="'txt_user_email' | translate: language:ModuleNames.Common" [disabled]="countWord(newUser.email, mdCount) > mdCount || editMode" [maxlength]="mdCount" [(ngModel)]="newUser.email">
          </z-input>

          <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
            <mat-form-field class="w-full" appearance="none">
              <mat-label>{{ 'txt_user_email'| translate:language:ModuleNames.Common }}</mat-label>
              <input required type="email" #email="ngModel" [maxlength]="mdCount" [disabled]="countWord(newUser.email,
                mdCount) > mdCount " [disabled] matInput [(ngModel)]="newUser.email">
            </mat-form-field>
          </div>
          <div *ngIf="email.invalid && (email.dirty || email.touched)"
            class="alert alert-danger transform translate-y-1">
            <div class="text-red-600 text-xs" *ngIf="email.errors.required">
              {{ 'txt_required_field'| translate:language:ModuleNames.Common }}
            </div>
          </div> -->
        </div>
        <div class="w-full flex flex-col" *ngIf="!editMode">
          <z-input [password]="true" [invalid]="userInvalid" [required]="true" [language]="language" [label]="'txtPassword' | translate: language:ModuleNames.Common" [disabled]="countWord(newUser.email, mdCount) > mdCount" [maxlength]="mdCount" [(ngModel)]="newUser.password"></z-input>
          <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
            <mat-form-field class="w-full" appearance="none">
              <mat-label>{{"txtPassword"| translate:language:ModuleNames.Common }}</mat-label>
              <input required [disabled] matInput #password="ngModel" [(ngModel)]="newUser.password"
                [type]="hide ? 'password' : 'text'">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <i [className]="hide? 'is-size-5 iconBlackBack fas fa-eye-slash' : 'is-size-5 fas fa-eye' "></i>
              </button>
            </mat-form-field>
          </div>
          <div *ngIf="password.invalid && (password.dirty || password.touched)"
            class="alert alert-danger transform translate-y-1">
            <div class="text-red-600 text-xs" *ngIf="password.errors.required">
              {{ 'txt_required_field'| translate:language:ModuleNames.Common }}
            </div>
          </div> -->
        </div>
      </div>

      <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
        <z-toggle [disable]="newUser.key == user.key" [label]="'txt_is_super_user' | translate: language:ModuleNames.Common" [(ngModel)]="isSuperUser"></z-toggle>
      </div>
    </div>
    <div *ngIf="!isSuperUser" class="w-full flex flex-col mt-2 gap-4 pt-5">
      <h6 class="text-sm text-zen font-medium text-center">{{"Employee Experience Products Permission"| translate:
        language:ModuleNames.Common}} *</h6>
      <!-- <table style="border-collapse: separate;border-spacing: 0px 3px;">
        <th class="text-xs text-zen font-medium px-5 pb-3 flex flex-row justify-start">{{"txt_companies"| translate: language:ModuleNames.Common}} *</th>
        <th class="text-xs text-zen font-normal px-5 pb-3" *ngFor="let product of products">
          <img *ngIf="isProduct(product)" [src]="'/assets/img/icons/permission-products/'+product+'.svg'" alt="" class="m-auto" [matTooltip]="product | translate: language:ModuleNames.Common">
          <span *ngIf="!isProduct(product)">{{product| translate: language:ModuleNames.Common | uppercase}}</span>
        </th> -->
      <!-- <tbody [@listAnimation]="newUser.CompanyPermissions.length">
          <tr *ngFor="let permission of newUser.CompanyPermissions" class="bg-white px-4 rounded ">
            <td class="text-xs text-zen font-medium px-5 py-3">
              <div class=" flex flex-row items-center gap-2 btn" (click)="handleClickAll(newUser,permission)">
                <i [ngClass]="permission?.products?.length == products?.length ? 'text-exp fa-square-check font-semibold': permission?.products?.length?'text-exp fa-square-check':'fa-square'" class="fa-regular text-lg"></i>
                {{getCompanyName(permission?.key) | extract: language}}
              </div>
            </td>
            <td class="text-center text-zen" *ngFor="let product of products">
              <i [ngClass]="permissionCheck(product, permission.products)?'text-exp fa-square-check':'fa-square'" (click)="permissionHandle(product, permission, permission.key)" class="fa-regular btn w-9 text-center text-lg "></i>
            </td>
          </tr>
        </tbody>
      </table> -->

      <div class="flex flex-row items-start">
          <div class="flex flex-col  w-1/3 text-xs text-zen font-medium pb-3">
            <input class="leading-10 max-h-10 rounded-lg px-5" (input)="filterPermission($event.target.value)" [placeholder]="'txt_companies'| translate: language:ModuleNames.Common" />
            <div class="flex max-w-xs overflow-scroll">
              <mat-tree #tree [dataSource]="permissionDataSource" [treeControl]="permissionTreeControl" class="permission-tree1 bg-transparent overflow-scroll">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                  <div class="flex flex-row w-full divide-x maxMin10 items-center justify-between hover:bg-exp hover:bg-opacity-5 width-fit" [ngClass]="node.highlight?'bg-exp bg-opacity-5':''">
                    <div class="flex flex-col  h-10 justify-center" (click)="handleClickAll(node.key)">
                      <div class="flex flex-row items-center btn text-xs text-zen">
                        <span class="px-4"></span>
                        <i [ngClass]="getCompanyPermission(node.key)?.products?.length == products?.length ? 'text-exp fa-square-check font-semibold': getCompanyPermission(node.key)?.products?.length?'text-exp fa-square-check':'fa-square'" class="fa-regular text-lg"></i>
                        <h5 class="whitespace-nowrap px-3">{{node.name| extract: language}}</h5>
                      </div>
                    </div>
                    <!-- <div class="flex flex-col">
                      <div class="flex flex-row divide-x items-center">
                        <div  [matTooltip]="product | translate: language:ModuleNames.Common" class="flex btn flex-row items-center h-10 justify-center px-5" *ngFor="let product of products" (click)="permissionHandle(product, node.key)">
                          <img *ngIf="isProduct(product)" [src]="getProductIcon(product)" alt="" class="h-5 object-contain" >
                            <span class="text-3xs text-zen whitespace-nowrap" *ngIf="!isProduct(product)">{{product| translate:
                              language:ModuleNames.Common |
                              uppercase}}</span>
                          <i class="fa-regular btn w-9 text-center text-zen text-lg" [ngClass]="permissionCheck(product, node.key)?'text-exp fa-square-check':'fa-square'" ></i>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </mat-tree-node>
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                  <div>
                    <div class="flex flex-row w-full divide-x maxMin10 items-center justify-between hover:bg-exp hover:bg-opacity-5 width-fit" [ngClass]="node.highlight?'bg-exp bg-opacity-5':''">
                      <div class="flex flex-col  justify-center ">
                        <div class="flex flex-row items-center text-xs text-zen w-40">
                          <button  class="w-8" matTreeNodeToggle [attr.aria-label]="'Toggle ' +node.name| extract: language">
                            <mat-icon class="mat-icon-rtl-mirror">
                              {{ permissionTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                            </mat-icon>
                          </button>
                          <div class="flex flex-row items-center btn text-xs text-zen" (click)="handleClickAll(node.key);handleClickAllNested(node.key)">
                            <i [ngClass]="getCompanyPermission(node.key)?.products?.length == products?.length ? 'text-exp fa-square-check font-semibold': getCompanyPermission(node.key)?.products?.length?'text-exp fa-square-check':'fa-square'" class="fa-regular text-lg"></i>
                              <h5 class="whitespace-nowrap px-3">{{node.name| extract: language}}</h5>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="flex flex-col">
                        <div class="flex flex-row divide-x items-center">
                          <div [matTooltip]="product | translate: language:ModuleNames.Common" (click)="permissionHandle(product, node.key); permissionHandleNested(product, node.key);" class="flex flex-row h-10 items-center justify-center px-5 btn" *ngFor="let product of products">
                            <img *ngIf="isProduct(product)" [src]="getProductIcon(product)" alt="" class="h-5 object-contain"
                              >
                            <span class="text-3xs text-zen whitespace-nowrap" *ngIf="!isProduct(product)">{{product| translate: language:ModuleNames.Common |
                                            uppercase}}</span>
                            <i class="fa-regular  w-9 text-center text-zen text-lg" [ngClass]="permissionCheck(product, node.key)?'text-exp fa-square-check':'fa-square'" ></i>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <div [class.permission-tree-invisible]="!permissionTreeControl.isExpanded(node)" role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                  </div>
                </mat-nested-tree-node>
              </mat-tree>
            </div>
          </div>
        <div class="flex flex-col w-2/3 overflow-scroll">
            <div class="flex flex-row divide-x items-center ">
              <div class="flex flex-col  maxw20	max-h-10  pb-3" *ngFor="let product of products">
                <img *ngIf="isProduct(product)" [src]="getProductIcon(product)" alt="" class="max-h-10" [matTooltip]="product | translate: language:ModuleNames.Common">
                <span class="text-xs text-center text-zen" *ngIf="!isProduct(product)">{{product| translate: language:ModuleNames.Common | uppercase}}</span>
              </div>
            </div>
          <mat-tree #tree [dataSource]="permissionDataSource" [treeControl]="permissionTreeControl"
            class="permission-tree bg-transparent">
            <mat-tree-node class="h-10" *matTreeNodeDef="let node" matTreeNodeToggle>
              <div class="flex flex-row w-full divide-x maxMin10 items-center justify-between hover:bg-exp hover:bg-opacity-5 width-fit"
                [ngClass]="node.highlight?'bg-exp bg-opacity-5':''">
                  <div class="flex flex-row divide-x items-center">
                    <div 
                      class="flex flex-row h-10 items-center w-20 justify-center btn" *ngFor="let product of products"
                      (click)="permissionHandle(product, node.key)">
                      <i class="fa-regular btn w-9 text-center text-zen text-lg"
                        [ngClass]="permissionCheck(product, node.key)?'text-exp fa-square-check':'fa-square'"></i>
                    </div>
                  </div>
              </div>
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div
                  class="flex flex-row w-full divide-x maxMin10 items-center justify-between hover:bg-exp hover:bg-opacity-5 width-fit"
                  [ngClass]="node.highlight?'bg-exp bg-opacity-5':''">
              
                  <div class="flex flex-col">
                    <div class="flex flex-row divide-x items-center">
                      <div (click)="permissionHandle(product, node.key); permissionHandleNested(product, node.key);"
                        class="flex flex-row h-10 items-center w-20 justify-center btn" *ngFor="let product of products">
                        <i class="fa-regular  w-9 text-center text-zen text-lg"
                          [ngClass]="permissionCheck(product, node.key)?'text-exp fa-square-check':'fa-square'"></i>
                      </div>
                    </div>
                  </div>
                </div>
              <div [class.permission-tree-invisible]="!permissionTreeControl.isExpanded(node)" role="group">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>

          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </div>
      <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
        <mat-form-field class="w-full" appearance="none">
          <mat-label>{{'txt_user_companies'| translate:language:ModuleNames.Common}}</mat-label>
          <mat-select #comps="ngModel" required [(ngModel)]="newUser.companyReferences" multiple>
            <mat-option *ngFor="let company of companies" [value]="company.key">
              {{ extractNameLanguage(company.name) }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="comps.invalid && (comps.dirty || comps.touched)" class="alert alert-danger transform translate-y-1">
        <div class="text-red-600 text-xs" *ngIf="comps.errors.required">
          {{ 'txt_required_field'| translate:language:ModuleNames.Common }}
        </div>
      </div> -->
    </div>
  </ng-container>
  <ng-container footer>
    <a (click)="closeModal('userAdd');" class="btn cancel text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{ "btn_cancel" |
      translate: language:ModuleNames.Common }}</a>
    <button (click)="editMode ? updateUser(newUser) : createNewUser()" class="btn exp w-auto text-center align-middle rounded-md px-8 py-4">
      <span class="mx-1">{{
        (editMode ? "btn_update" : "btn_add_user")
        | translate: language:ModuleNames.Common
        }}</span>
    </button>
  </ng-container>
</z-modal>

<!-- add performance -->

<z-modal *ngIf="performanceAdd" [open]="performanceAdd">
  <ng-container header>
    <div class="flex flex-row gap-2 items-center">
      <i class="fal fa-analytics text-white bg-exp shadow-xl rounded-full h-110 w-10 text-xl text-center leading-10"></i>
      <h4 class="text-2xl font-bold text-zen">
        {{ "btn_performance_add" | translate: language:ModuleNames.Common }}
      </h4>
      <!-- <p class="text-sm text-zen">Please reupload the data file after fixing the validation errors.</p> -->
    </div>
  </ng-container>
  <ng-container body>
    <div class="flex flex-row gap-2 mb-5">
      <div class="w-9/12 flex flex-col">
        <z-input [invalid]="performanceInvalid" [required]="true" [language]="language" [label]="
            'txt_performance_rate_value'
              | translate: language:ModuleNames.Common
          " [disabled]="countWord(newPerformanceRate.value, mdCount) > mdCount" [maxlength]="mdCount" [(ngModel)]="newPerformanceRate.value"></z-input>

        <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
          <mat-form-field class="w-full" appearance="none">
            <mat-label>{{"txt_performance_rate_value"| translate:language:ModuleNames.Common }}</mat-label>
            <input #rate="ngModel" required matInput type="text" [maxlength]="mdCount" [disabled]="countWord(newPerformanceRate.value,
              mdCount) > mdCount " [(ngModel)]="newPerformanceRate.value">
          </mat-form-field>
        </div>
        <div *ngIf="rate.invalid && (rate.dirty || rate.touched)" class="alert alert-danger transform translate-y-1">
          <div class="text-red-600 text-xs" *ngIf="rate.errors.required">
            {{ 'txt_required_field'| translate:language:ModuleNames.Common }}
          </div>
        </div> -->
      </div>
      <div class="w-3/12 flex flex-col">
        <z-select [color]="'exp'" [(ngModel)]="newPerformanceRate.icon" [label]="
            'txt_performance_icon' | translate: language:ModuleNames.Common
          " [key]="'value'" [options]="performanceColors" [language]="language">
          <ng-template #valueTemplate let-value>
            <fa-icon [icon]="faStar" [ngStyle]="{ color: newPerformanceRate.icon }"></fa-icon>
          </ng-template>
          <ng-template #optionTemplate let-value>
            <fa-icon [icon]="faStar" [ngStyle]="{ color: value?.value }"></fa-icon>
          </ng-template>
        </z-select>

        <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
          <mat-form-field class="w-full" appearance="none">
            <mat-label>{{"txt_performance_icon"| translate:language:ModuleNames.Common }}</mat-label>
            <mat-select-trigger *ngIf="newPerformanceRate.icon">
              <fa-icon class="h-15" [icon]="faStar" [ngStyle]="{ color: newPerformanceRate.icon }"></fa-icon>
            </mat-select-trigger>
            <mat-select #icon="ngModel" [(ngModel)]="newPerformanceRate.icon">
              <mat-option *ngFor="let icon of performanceColors" [value]="icon">
                <fa-icon [icon]="faStar" [ngStyle]="{ color: icon }"></fa-icon>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="icon.invalid && (icon.dirty || icon.touched)" class="alert alert-danger transform translate-y-1">
          <div class="text-red-600 text-xs" *ngIf="icon.errors.required">
            {{ 'txt_required_field'| translate:language:ModuleNames.Common }}
          </div>
        </div> -->
      </div>
    </div>
    <z-translation-input [invalid]="performanceInvalid" [maxCount]="mdCount" [required]="true" [label]="
        'txt_performance_rate_description'
          | translate: language:ModuleNames.Common
      " [language]="language" [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="newPerformanceRate.description">
    </z-translation-input>
  </ng-container>
  <ng-container footer>
    <a (click)="closeModal('performanceAdd')" class="btn cancel text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{ "btn_cancel" |
      translate: language:ModuleNames.Common }}</a>
    <button (click)="
        editMode ? updatePerformance(newPerformanceRate) : addNewPerformance()
      " class="btn exp w-auto text-center align-middle rounded-md px-8 py-4">
      <span class="mx-1">{{
        (editMode ? "btn_update" : "btn_performance_add")
        | translate: language:ModuleNames.Common
        }}</span>
    </button>
  </ng-container>
</z-modal>

<!-- add band -->
<z-modal *ngIf="bandLevelAdd" [open]="bandLevelAdd">
  <ng-container header>
    <div class="flex flex-row gap-2 items-center">
      <i class="fal fa-user-chart text-white bg-exp shadow-xl rounded-full h-110 w-10 text-xl text-center leading-10"></i>
      <h4 class="text-2xl font-bold text-zen">
        {{
        (editMode ? "txt_bandlevel" : "txt_modal_band")
        | translate: language:ModuleNames.Common
        }}
      </h4>
      <!-- <p class="text-sm text-zen">Please reupload the data file after fixing the validation errors.</p> -->
    </div>
  </ng-container>
  <ng-container body>
    <z-translation-input [invalid]="bandlevelInvalid" [maxCount]="mdCount" [required]="true" [label]="'txt_band_level_name' | translate: language:ModuleNames.Common" [language]="language" [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="newBandLevel.name"></z-translation-input>
  </ng-container>
  <ng-container footer>
    <a (click)="closeModal('bandLevelAdd')" class="btn cancel text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{ "btn_cancel" |
      translate: language:ModuleNames.Common }}</a>
    <button (click)="editMode ? updateBandLevel(newBandLevel) : addNewBandLevel()" class="btn exp w-auto text-center align-middle rounded-md px-8 py-4">
      <span class="mx-1">{{
        (editMode ? "btn_update" : "btn_add_band_level")
        | translate: language:ModuleNames.Common
        }}</span>
    </button>
  </ng-container>
</z-modal>

<!-- add grade -->
<z-modal *ngIf="jobGradeAdd" [open]="jobGradeAdd">
  <ng-container header>
    <div class="flex flex-row gap-2 items-center">
      <i class="fal fa-award text-white bg-exp shadow-xl rounded-full h-110 w-10 text-xl text-center leading-10"></i>
      <h4 class="text-2xl font-bold text-zen">
        {{
        editMode
        ? extractNameLanguage(newJobGrade.name)
        : ("btn_add_job_grade" | translate: language:ModuleNames.Common)
        }}
      </h4>
      <!-- <p class="text-sm text-zen">Please reupload the data file after fixing the validation errors.</p> -->
    </div>
  </ng-container>
  <ng-container body>
    <div class="flex flex-row justify-between gap-2">
      <div class="flex flex-col w-1/2">
        <z-translation-input [invalid]="jobGradeInvalid" [maxCount]="mdCount" [required]="true" [label]="'txt_job_grade' | translate: language:ModuleNames.Common" [language]="language" [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="newJobGrade.name"></z-translation-input>
      </div>
      <div class="flex flex-col w-1/2">
        <z-select [invalid]="jobGradeInvalid" [required]="true" [color]="'exp'" [(ngModel)]="newJobGrade.bandLevelKey" [label]="'txt_bandlevel' | translate: language:ModuleNames.Common" [options]="selectedEntity.BandLevels" [language]="language">
          <ng-template #valueTemplate let-value>
            {{ value?.name | extract: language }}
          </ng-template>
        </z-select>

        <!-- <div class="bg-white rounded-md px-5 pt-1 overflow-hidden h-15">
          <mat-form-field class="w-full" appearance="none">
            <mat-label>{{ 'txt_bandlevel'| translate:language:ModuleNames.Common }}</mat-label>
            <mat-select required #level="ngModel" [(ngModel)]="newJobGrade.bandLevelKey">
              <mat-option *ngFor="let bandLevel of selectedEntity.BandLevels" [value]="bandLevel.key">
                {{ extractNameLanguage(bandLevel.name) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="level.invalid && (level.dirty || level.touched)" class="alert alert-danger transform translate-y-1">
          <div class="text-red-600 text-xs" *ngIf="level.errors.required">
            {{ 'txt_required_field'| translate:language:ModuleNames.Common }}
          </div>
        </div> -->
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <a (click)="closeModal('jobGradeAdd')" class="btn cancel text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{ "btn_cancel" |
      translate: language:ModuleNames.Common }}</a>
    <button (click)="editMode ? updateJobGrade(newJobGrade) : addNewJobGrade()" class="btn exp w-auto text-center align-middle rounded-md px-8 py-4">
      <span class="mx-1">{{
        (editMode ? "btn_update" : "btn_add_job_grade")
        | translate: language:ModuleNames.Common
        }}</span>
    </button>
  </ng-container>
</z-modal>

<z-modal *ngIf="integrationModal" [open]="integrationModal" [color]="'bg-exp'">
  <ng-container header>
    <div class="flex flex-row gap-5 items-center">
      <img class="h-18 w-18 block rounded bg-white shadow group-hover:shadow-none object-center object-none transition-all" src="assets/img/svg/thomas.svg" alt="" />
      <div>
        <h5 class="text-xl font-bold text-zen">
          {{ "Thomas International" | translate: language:ModuleNames.Common }}
        </h5>
        <h5 class="text-xxs font-normal text-zen mt-1">
          {{ "TALENT ASSESSMENT" | translate: language:ModuleNames.Common }}
        </h5>
      </div>
    </div>
    <p class="text-xs text-zen my-5">
      {{
      "Harness the power of psychology and machine learning with the Thomas talent assessment platform.Make smarter
      recruitment and development decisions."
      | translate: language:ModuleNames.Common
      }}
    </p>
  </ng-container>
  <ng-container body>
    <div class="px125">
      <h5 class="c-60 is-size-55 font-normal">
        {{ "txtAccountDetails" | translate: language:ModuleNames.Common }}
      </h5>
      <div class="flex flex-row gap-5">
        <div class="w-1/2">
          <mat-form-field class="w-full" appearance="outline">
            <mat-label>{{
              "txtClientID" | translate: language:ModuleNames.Common
              }}</mat-label>
            <input [(ngModel)]="thomasClientID" matInput type="text" />
          </mat-form-field>
        </div>
        <div class="w-1/2">
          <mat-form-field class="w-full" appearance="outline">
            <mat-label>{{
              "txtPassword" | translate: language:ModuleNames.Common
              }}</mat-label>
            <input matInput [(ngModel)]="thomasClientSecret" [type]="hide ? 'password' : 'text'" />
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="txtHidepassword" [attr.aria-pressed]="hide">
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-row justify-center mb-5">
        <a (click)="testThomasConnection()" class="btn cancel leading-10 px-7 rounded font-medium text-sm text-zen">{{
          "Test Connection" | translate: language:ModuleNames.Common }}</a>
      </div>
      <div *ngIf="startingAuthorization" class="bg-zen bg-opacity-10 rounded p-5 logBox">
        <h5 *ngIf="startingAuthorization" class="text-xxs text-zen">
          {{ "Testing connection..." | translate: language:ModuleNames.Common }}
        </h5>
        <h5 *ngIf="startingAuthorization" class="text-xxs text-zen">
          {{
          "Starting user authentication"
          | translate: language:ModuleNames.Common
          }}
        </h5>
        <h5 *ngIf="connectionWorked == 'SUCCESS'" class="text-xxs text-zen-green">
          {{ "User authenticated!" | translate: language:ModuleNames.Common }}
        </h5>
        <h5 *ngIf="connectionWorked == 'SUCCESS'" class="text-xxs text-zen-green">
          {{
          "Connection established!" | translate: language:ModuleNames.Common
          }}
        </h5>
        <h5 *ngIf="connectionWorked == 'ERROR'" class="text-xxs text-red-600 font-medium">
          {{ "Connection Failed!" | translate: language:ModuleNames.Common }}
        </h5>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <a (click)="closeModal('integrationModal')" class="btn cancel text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{ "btn_cancel" |
      translate: language:ModuleNames.Common }}</a>
    <a *ngIf="connectionWorked == 'SUCCESS'" (click)="integrateThomas()" class="btn exp text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">
      {{ "INTEGRATE NOW" | translate: language:ModuleNames.Common }}
    </a>
  </ng-container>
</z-modal>

<z-modal *ngIf="excelIntegration" [open]="excelIntegration" [color]="'bg-exp'">
  <ng-container header>
    <div class="flex flex-row gap-5 items-center">
      <img class="h-18 w-18 block rounded bg-white shadow group-hover:shadow-none object-center object-none transition-all" src="assets/img/excel.png" alt="" style="object-fit: cover" />
      <div>
        <h5 class="text-xl font-bold text-zen">
          {{ "Excel Integration" | translate: language:ModuleNames.Common }}
        </h5>
        <h5 class="text-xxs font-normal text-zen mt-1 capitalize">Zenithr</h5>
      </div>
    </div>
    <p class="text-xs text-zen my-5">
      {{
      "Harness the simplicity of integrating your data using Microsoft Excel"
      | translate: language:ModuleNames.Common
      }}
    </p>
  </ng-container>
  <ng-container body>
    <ng-container>
      <div class="flex flex-row">
        <div class="flex flex-col w-2/3 gap-2">
          <h4 class="max-w-xs truncate text-sm text-zen">
            {{ "txt_step" | translate: language:ModuleNames.Shared }} 1:
          </h4>
          <p class="text-base">
            {{
            'Click "Download file" to download the premade spreadsheet (.xlsx).'
            | translate: language:ModuleNames.Common
            }}
          </p>
        </div>
        <div class="flex flex-col w-1/3 gap-2">
          <a (click)="downloadIntegrationExcel()" class="btn cancel px-5 rounded-md leading-12 text-center">
            <i class="fal leading-12 px-1 fa-download"></i>{{ "btn_download" | translate: language:ModuleNames.Shared
            }}</a>
        </div>
      </div>
      <br>
      <div class="flex flex-row">
        <div class="flex flex-col w-2/3 gap-2">
          <h4 class="max-w-xs truncate text-sm text-zen">
            {{ "txt_step" | translate: language:ModuleNames.Shared }} 2:
          </h4>
          <p class="text-base">
            {{
            "Fill out the premade template and share it with your Account Manager."
            | translate: language:ModuleNames.Common
            }}
          </p>
        </div>
        <div class="flex flex-col w-1/3 gap-2">
          <a class="btn exp px-5 rounded-md leading-12 text-center">
            <i class="fal leading-12 px-1 fa-upload"></i>{{'btn_upload_file'|
            translate:language:ModuleNames.Shared}}<input (change)="uploadIntegrationFile($event)" type="file"></a>
        </div>
      </div>


      <span class="text-zen font-medium text-lg  mt-5"  *ngIf="excelErrors.length">Errors</span>
      <div class="flex flex-col max-h-32 overflow-auto w-full gap-1" *ngIf="excelErrors.length">
        
        <ng-container *ngFor="let excelError of excelErrors">
            <span class="text-zen-red" *ngIf="excelError.row != 0">Error at Column: {{ excelError.column }}, Row: {{ excelError.row }} - {{ excelError.message }}</span>
            <span class="text-zen-red" *ngIf="excelError.row == 0">Error at Column: {{ excelError.column }} - {{ excelError.message }}</span>
        </ng-container>
        

      </div>
      <!-- <div class="flex flex-row">
          <div class="flex flex-col w-2/3 gap-2">
              <h4 class="max-w-xs truncate text-sm text-zen">{{'txt_step'| translate:language:ModuleNames.Shared}} 3:
              </h4>
              <p class="text-base">{{'txt_step3'| translate:language:ModuleNames.Shared}}</p>
          </div>
          <div class="flex flex-col w-1/3 gap-2">
              <a class="btn exp px-5 rounded-md leading-12 text-center">
                  <i class="fal leading-12 px-1 fa-upload"></i>{{'btn_upload_file'| translate:language:ModuleNames.Shared}}<input
                      (change)="uploadFile($event)" type="file"></a>
          </div>
      </div> -->
    </ng-container>
  </ng-container>
  <ng-container footer>
    <a (click)="closeModal('excelIntegration')" class="btn cancel text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">{{ "btn_cancel" |
      translate: language:ModuleNames.Common }}</a>
    <a *ngIf="connectionWorked == 'SUCCESS'" (click)="integrateThomas()" class="btn exp text-center align-middle rounded-md px-8 py-4 uppercase text-lg font-medium">
      {{ "INTEGRATE NOW" | translate: language:ModuleNames.Common }}
    </a>
  </ng-container>
</z-modal>
