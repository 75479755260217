import { Pipe, PipeTransform } from '@angular/core';
import { ModuleName } from '../enums/ModuleName';
import engagementText from '../../../assets/Translations/Engagement.json';
import happinessText from '../../../assets/Translations/Happiness.json';
import psychometricText from '../../../assets/Translations/Psychometric.json';
import surveysText from '../../../assets/Translations/Surveys.json';
import exitText from '../../../assets/Translations/Exit.json';
import commonText from '../../../assets/Translations/Common.json';
import assessmentText from '../../../assets/Translations/Assessment.json';
import assessment180Text from '../../../assets/Translations/Assessment180.json';
import sharedText from '../../../assets/Translations/Shared.json';
import onboardingText from '../../../assets/Translations/Onboarding.json';
import wellBeingText from '../../../assets/Translations/Wellbeing.json';
import surveyXText from '../../../assets/Translations/Survey-X.json';
import tooltipsText from '../../../assets/Translations/Tooltips.json';
import defaultText from '../../../assets/Translations/Default.json';
import { GeneralService } from 'src/app/core/services/general.service';
import { environment } from 'src/environments/environment';
@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  isDev: boolean = false;
  constructor() {

  }
  transform(value: string, language: string, module: string): any {
    try {
      var lngO = null
      if (!environment.jsonTranslation) {
        switch (module) {
          case ModuleName.Engagement:
            var engagement = JSON.parse(localStorage.getItem(ModuleName.Engagement));
            lngO = engagement.find(i => i.key == value)
            break;
          case ModuleName.Happiness:
            var happiness = JSON.parse(localStorage.getItem(ModuleName.Happiness))
            lngO = happiness.find(i => i.key == value)
            break;
          case ModuleName.Exit:
            var exit = JSON.parse(localStorage.getItem(ModuleName.Exit))
            lngO = exit.find(i => i.key == value)
            break;
          case ModuleName.Assessment:
            var assessment = JSON.parse(localStorage.getItem(ModuleName.Assessment))
            lngO = assessment.find(i => i.key == value)
            break;
          case ModuleName.Assessment180:
            var assessmen180 = JSON.parse(localStorage.getItem(ModuleName.Assessment180))
            lngO = assessmen180.find(i => i.key == value)
            break;
          case ModuleName.Psychometric:
            var psychometric = JSON.parse(localStorage.getItem(ModuleName.Psychometric));
            lngO = psychometric.find(i => i.key == value)
            break;
          case ModuleName.Common:
            var common = JSON.parse(localStorage.getItem(ModuleName.Common))
            lngO = common.find(i => i.key == value)
            break;
          case ModuleName.Shared:
            var shared = JSON.parse(localStorage.getItem(ModuleName.Shared))
            lngO = shared.find(i => i.key == value)
            break;
          case ModuleName.Surveys:
            var surveys = JSON.parse(localStorage.getItem(ModuleName.Surveys))
            lngO = surveys.find(i => i.key == value)
            break;
          case ModuleName.Onboarding:
            var surveys = JSON.parse(localStorage.getItem(ModuleName.Surveys));
            var onBoarding = JSON.parse(localStorage.getItem(ModuleName.Onboarding))
            var surveysOnB = [...onBoarding, ...surveys]
            lngO = surveysOnB.find(i => i.key == value)
            break;
          case ModuleName.Tooltips:
            var tooltips = JSON.parse(localStorage.getItem(ModuleName.Tooltips))
            lngO = tooltips.find(i => i.key == value)
            break;
          case ModuleName.Wellbeing:
            var surveys = JSON.parse(localStorage.getItem(ModuleName.Surveys));
            var Wellbeing = JSON.parse(localStorage.getItem(ModuleName.Wellbeing))
            var surveysOnB = [...Wellbeing, ...surveys]
            lngO = surveysOnB.find(i => i.key == value)
            break;
          case ModuleName.SurveyX:
            var surveys = JSON.parse(localStorage.getItem(ModuleName.Surveys));
            var SurveyX = JSON.parse(localStorage.getItem(ModuleName.SurveyX))
            var surveysOnB = [...SurveyX, ...surveys]
            lngO = surveysOnB.find(i => i.key == value)
            break;
          default:
            var defaultT = JSON.parse(localStorage.getItem("Default"))
            lngO = defaultT.find(i => i.key == value)
        }
      } else {
        switch (module) {
          case ModuleName.Engagement:
            lngO = engagementText?.find(i => i.key == value)
            break;
          case ModuleName.Happiness:
            lngO = happinessText?.find(i => i.key == value)
            break;
          case ModuleName.Exit:
            lngO = exitText?.find(i => i.key == value)
            break;
          case ModuleName.Assessment:
            lngO = assessmentText?.find(i => i.key == value)
            break;
          case ModuleName.Assessment180:
            lngO = assessment180Text?.find(i => i.key == value)
            break;
          case ModuleName.Psychometric:
            lngO = psychometricText?.find(i => i.key == value)
            break;
          case ModuleName.Common:
            lngO = commonText.find(i => i.key == value)
            break;
          case ModuleName.Shared:
            lngO = sharedText?.find(i => i.key == value)
            break;
          case ModuleName.Surveys:
            lngO = surveysText?.find(i => i.key == value)
            break;
          case ModuleName.Wellbeing:

            let surveysWB = [...wellBeingText, ...surveysText]

            lngO = surveysWB?.find(i => i.key == value)

            break;
          case ModuleName.Onboarding:
            let surveysOnB = [...onboardingText, ...surveysText]
            lngO = surveysOnB?.find(i => i.key == value)
            break;
          case ModuleName.SurveyX:
            let SurveyX = [...surveyXText, ...surveysText]
            lngO = SurveyX?.find(i => i.key == value)
            break;
          case ModuleName.Tooltips:
            lngO = tooltipsText?.find(i => i.key == value)
            break;
          default:
            lngO = defaultText?.find(i => i.key == value)
        }
      }

      var text = this.getUILanguage(lngO, language)
      var textEN = this.getUILanguage(lngO, "EN")
      if (text != "")
        return text
      else if (textEN != "")
        return textEN
      return value
    } catch (err: any) {
      return "";
    }
  }
  getUILanguage(lngO, language: string) {
    if (language == "EN") {
      if (lngO) {
        return lngO.text;
      }
    } else {
      if (lngO) {
        let translation = lngO.translations.find(cT => (cT.language == language));
        if (translation) {
          return translation.text;
        }
      }
    }
    return ""
  }
}
