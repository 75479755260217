import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';

@Component({
  selector: 'app-new-category-modal',
  templateUrl: './new-category-modal.component.html',
  styleUrls: ['./new-category-modal.component.scss']
})
export class NewCategoryModalComponent extends ModalComponent implements OnInit {

  showErrors: boolean = false;
  error: string = "";

  categoryNameText = [{ key: 'EN', name: "" }, { key: 'AR', name: "" }];
  supportedLanguages = ['EN', 'AR'];

  @Input() open: boolean = true;
  @Output() openChange = new EventEmitter();
  @Output() createNewCategory = new EventEmitter<string>();

  @Input() language = 'EN';
  @Input() direction = 'ltr';
  ModuleNames = ModuleName;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
  }

  onSubmit() {
    var englishName = this.categoryNameText.find(x => x.key == 'EN').name;
    var arabicName = this.categoryNameText.find(x => x.key == 'AR').name;

    //English input min 1 char max 50 char or show error
    if (englishName.length < 1 || englishName.length > 50) {
      this.showErrors = true;
      this.error = "English Category Name must be between 1 and 50 characters";
      return;
    }

    //arabic input is optional but can't be more than 50 char
    if (arabicName.length > 50) {
      this.showErrors = true;
      this.error = "Arabic Category Name must be between 1 and 50 characters";
      return;
    }

    this.showErrors = false;

    var categoryName = JSON.stringify(this.categoryNameText);
    this.createNewCategory.emit(categoryName);
    this.close();
  }

  close() {
    this.openChange.emit(false);
    // this.cd.detectChanges();
  }
}
