<!-- <div [@first] class="flex flex-row justify-between h-header items-center customHShadow px-10 fixed top-0 z-40 backdrop-filter backdrop-blur-2xl bg-white bg-opacity-90 w-screen">
    <div [@first] class="flex flex-row justify-start items-center gap-5 w-4/12">
        <div class="flex flex-row items-center">
            <img class="h-8 w-" src="/assets/img/svg/logo.svg" alt="">
        </div>
        <span [@child1] *ngIf="moduleName.innerHTML.length > 1" class="h-10 w-0 border-r border-zen border-opacity-20"></span>
        <h5 [@child2] #moduleName [@first] class="text-lg text-exp font-normal">
            {{"Survey "| translate:language:ModuleNames.SurveyX}}
            <span class="font-extrabold">{{"X"| translate:language:ModuleNames.SurveyX}}</span>
        </h5>
    </div>

</div> -->

<!-- <div [dir]="direction" class=" flex flex-row box-border bg-white items-center w-full h-110 pt-2 px-10 justify-between">
    <div class="flex flex-col w-max">
        <h5 [@first] class="text-zen font-medium text-2xl">{{"Respondent Information"| translate:language:ModuleNames.SurveyX}}
        </h5>
    </div>

</div> -->
<div class="flex flex-col h-screen">
    <div class="flex flex-row w-full shrink-0 startup-header sh-shadow px-8 h-16">
        <div class="flex-1">&nbsp;</div>
        <div class="flex-1 flex items-center justify-center">
            <img class="h-12" [src]=logoImage />
        </div>
        <div class="flex-1 flex items-center justify-end gap-4">
          <button [ZMenuTriggerFor]="menu" class="bg-zen-littlegray w-10 h-10 flex items-center justify-center rounded text-zenMain">
            <i class="fa-regular fa-language"></i>
        </button>

        <z-menu #menu >
          <menuItems>
              <a MenuItem class="py-2 flex gap-3" *ngFor="let language of localesList"
                  (click)="onSelectLanguage(language.key)">
                  <span class="icon-label">{{language.name}}</span>
              </a>
          </menuItems>
      </z-menu>
        </div>
    </div>

    <div class="flex flex-col justify-between flex-grow">
        <div [dir]="direction" class="flex flex-col justify-center pt-10 w-full sm:w-[480px] mx-auto px-8 sm:px-12">
            <div class="flex flex-col flex-wrap justify-center items-center w-full m-auto mb-8">
              <h5 [@first] class="text-zen font-medium text-2xl">{{"Tell us more about yourself!"| translate:language:ModuleNames.SurveyX}}
              </h5>
              <p class="pre-line leading-6 pt-1 text-sm text-zenMain">{{"Please fill the form below to continue:" | translate:language:ModuleNames.SurveyX}}</p>
            </div>

            <div class="flex flex-col flex-wrap bg-white rounded-md box-border shadow-024 justify-center items-center w-full m-auto">
                <div *ngIf="validateFields" class="alert">
                    <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
                    <strong>{{"Please Fill All Mandatory Fields."| translate:language:ModuleNames.SurveyX}}</strong>
                </div>
                <div *ngIf="isName" class="flex flex-row px-8 pt-8 w-full">
                    <z-input [required]="true" [language]="language"
                        [label]="'txtFull Name'| translate:language:ModuleNames.SurveyX"
                        [placeholder]="'Enter your name ...'| translate:language:ModuleNames.SurveyX" class="w-full"
                        [(ngModel)]="respondentName">
                    </z-input>
                </div>

                <div *ngIf="isEmail" class="flex flex-row px-8 pt-8 w-full">
                    <z-input [required]="true" [email]="true" [language]="language"
                        [label]="'Email'| translate:language:ModuleNames.SurveyX"
                        [placeholder]="'Enter your email address ...'| translate:language:ModuleNames.SurveyX"
                        class="w-full" [(ngModel)]="respondentEmail">
                    </z-input>
                </div>

                <div *ngFor="let enabledCriteria of enabledCustomCriterias; let i = index"
                    class="flex flex-col px-8 pt-8 w-full">

                    <z-input *ngIf="enabledCriteria.type == 1" maxlength="300" [required]="true" [language]="language"
                        [label]="enabledCriteria.name| translate:language:ModuleNames.SurveyX + '*'"
                        [placeholder]="'Enter your ' + enabledCriteria.name +' ...'| translate:language:ModuleNames.SurveyX"
                        class="w-full" [(ngModel)]="dynamicNgModels[enabledCriteria.name]">
                    </z-input>

                    <z-datepicker [direction]="direction" *ngIf="enabledCriteria.type == 2"
                        [label]="enabledCriteria.name | translate:language:ModuleNames.SurveyX + '*'" [language]="language" [color]="'exp'"
                        [(ngModel)]="dynamicNgModels[enabledCriteria.name]" [required]="true"></z-datepicker>

                    <z-select *ngIf="enabledCriteria.type == 3" [color]="'exp'" [key]="'id'" [required]="true"
                        [label]="enabledCriteria.name| translate:language:ModuleNames.SurveyX + '*'" [language]="language"
                        [options]="enabledCriteria.options"
                        [placeholder]="'Select your ' + enabledCriteria.name + ' ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="dynamicNgModels[enabledCriteria.name]"
                        (ngModelChange)="onValueChanged(enabledCriteria.name, $event)">
                        <ng-template #valueTemplate let-value>

                            {{value?.name | translate:language:ModuleNames.SurveyX}}

                        </ng-template>

                        <ng-template #optionTemplate let-option>

                            {{option?.name | translate:language:ModuleNames.SurveyX}}

                        </ng-template>
                    </z-select>
                </div>



                <div *ngIf="isGccCountries && isPhoneNumber" class="flex flex-col px-8 pt-8 w-full">
                    <mat-form-field appearance="fill">
                        <mat-label>{{"Phone number" | translate:language:ModuleNames.SurveyX}}</mat-label>
                        <ngx-mat-intl-tel-input [onlyCountries]="['kw', 'bh', 'om','qa','sa','ae']" [required]="true"
                            [enableSearch]="true" [(ngModel)]="respondentPhoneNumber" name="phone" #phone>
                        </ngx-mat-intl-tel-input>
                        <mat-icon matSuffix>phone</mat-icon>
                        <mat-hint>eg : {{phone.selectedCountry?.placeHolder}}</mat-hint>
                    </mat-form-field>
                </div>

                <div *ngIf="isGccCountries == false && isPhoneNumber" class="flex flex-col px-8 pt-8 w-full">
                    <mat-form-field appearance="fill">
                        <mat-label>{{"Phone number" | translate:language:ModuleNames.SurveyX}}</mat-label>
                        <ngx-mat-intl-tel-input [required]="true" [enableSearch]="true"
                            [(ngModel)]="respondentPhoneNumber" name="phone" #phone>
                        </ngx-mat-intl-tel-input>
                        <mat-icon matSuffix>phone</mat-icon>
                        <mat-hint>eg : {{phone.selectedCountry?.placeHolder}}</mat-hint>
                    </mat-form-field>
                </div>

                <!-- <div *ngIf="isGender" class="flex flex-col px-8 pt-8 w-full genderSelect">
            <z-radio [label]="'Gender'| translate:language:ModuleNames.SurveyX" [color]="'exp'" [options]="genderList" [required]="true" [(ngModel)]="selectedGender" (ngModelChange)="onGenderChanged($event)">
                <ng-template #valueTemplate let-value>

                    {{value?.name | extract:language}}

                </ng-template>

                <ng-template #optionTemplate let-option>

                    {{option?.name | extract:language}}

                </ng-template>
            </z-radio>
        </div> -->

                <div *ngIf="isGender" class="flex flex-col px-8 pt-8 w-full genderSelect">
                    <z-radio [label]="'Gender'| translate:language:ModuleNames.SurveyX" [color]="'exp'"
                        [options]="genderList" [required]="true" [(ngModel)]="selectedGender"
                        (ngModelChange)="onGenderChanged($event)">
                        <ng-template #valueTemplate let-value>
                            <span class="gender-icon">
                                <i
                                    [class]="'fa-regular fa-' + (value?.key === 'male' ? 'user-tie-hair' : 'user-tie-hair-long')"></i>
                                {{ value?.name | extract:language }}
                            </span>
                        </ng-template>
                        <ng-template #optionTemplate let-option>
                            <span class="gender-icon">
                                <i
                                    [class]="'fa-regular fa-' + (option?.key === 'male' ? 'user-tie-hair' : 'user-tie-hair-long')"></i>
                                {{ option?.name | extract:language }}
                            </span>
                        </ng-template>
                    </z-radio>
                </div>

                <div *ngIf="isReligion" class="flex flex-col px-8 pt-8 w-full">
                    <z-select [color]="'exp'" [required]="true"
                        [label]="'Religion'| translate:language:ModuleNames.SurveyX" [language]="language"
                        [options]="religionList"
                        [placeholder]="'Select your Religion ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="selectedReligion" (ngModelChange)="onReligionChanged($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | extract:language}}
                        </ng-template>
                        <ng-template #optionTemplate let-option>
                            {{option?.name | extract:language}}
                        </ng-template>
                    </z-select>
                </div>

                <div *ngIf="isMaritalStatus" class="flex flex-row px-8 pt-8 w-full">

                    <z-select class="w-full" [color]="'exp'" [required]="true"
                        [label]="'Marital Status'| translate:language:ModuleNames.SurveyX" [language]="language"
                        [options]="maritalStatusList"
                        [placeholder]="'Select your Marital Status ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="selectedMaritalStatus" (ngModelChange)="onMaritalStatusChanged($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | extract:language}}
                        </ng-template>

                        <ng-template #optionTemplate let-option>
                            {{option?.name | extract:language}}
                        </ng-template>
                    </z-select>

                    <div *ngIf="selectedMaritalStatus == 'married' || selectedMaritalStatus == 'divorced' || selectedMaritalStatus == 'widower'"
                        class="flex flex-col pl-12 w-full">
                        <z-input [number]="true" [required]="true" [language]="language"
                            [label]="'Number Of Children'| translate:language:ModuleNames.SurveyX"
                            [placeholder]="'Enter the number of children ...'| translate:language:ModuleNames.SurveyX"
                            class="w-full" [(ngModel)]="numberOfChildren">
                        </z-input>
                    </div>
                </div>




                <div *ngIf="isBirthDate" class="flex flex-col px-8 pt-8 w-full">
                    <z-datepicker [direction]="direction" [label]="'Date Of Birth' | translate:language:ModuleNames.SurveyX" [language]="language" [color]="'exp'"
                        [(ngModel)]="birthDate" [required]="true"></z-datepicker>
                </div>

                <div *ngIf="isNationality" class="flex flex-col px-8 pt-8 w-full">
                    <z-select [color]="'exp'" [required]="true"
                        [label]="'Nationality'| translate:language:ModuleNames.SurveyX" [language]="language"
                        [options]="locationList"
                        [placeholder]="'Select your Nationality ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="selectedNationality" (ngModelChange)="onNationalityChanged($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | extract:language}}
                        </ng-template>

                        <ng-template #optionTemplate let-option>
                            {{option?.name | extract:language}}
                        </ng-template>
                    </z-select>
                </div>



                <div *ngIf="isEmploymentStatus" class="flex flex-col px-8 pt-8 w-full">
                    <z-select [color]="'exp'" [required]="true"
                        [label]="'Employment Status'| translate:language:ModuleNames.SurveyX" [language]="language"
                        [options]="employmentStatusList"
                        [placeholder]="'Select your Employment Status ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="selectedEmploymentStatus" (ngModelChange)="onEmploymentStatusChanged($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | extract:language}}
                        </ng-template>

                        <ng-template #optionTemplate let-option>
                            {{option?.name | extract:language}}
                        </ng-template>
                    </z-select>
                </div>

                <div *ngIf="isJoiningDate && selectedEmploymentStatus !== 'currently Unemployed'"
                    class="flex flex-col px-8 pt-8 w-full">
                    <z-datepicker [direction]="direction" [label]="'Joining Date' | translate:language:ModuleNames.SurveyX" [language]="language" [color]="'exp'"
                        [(ngModel)]="joiningDate" [required]="true"></z-datepicker>
                </div>

                <div *ngIf="isJobTitle && selectedEmploymentStatus !== 'currently Unemployed'" class="flex flex-row px-8 pt-8 w-full">
                  <z-input [required]="true" [language]="language"
                      [label]="'Job Title'| translate:language:ModuleNames.SurveyX"
                      [placeholder]="'Enter your job title ...'| translate:language:ModuleNames.SurveyX"
                      class="w-full" [(ngModel)]="respondentJobTitle">
                  </z-input>
              </div>

                <div *ngIf="isJobType && selectedEmploymentStatus !== 'currently Unemployed'" class="flex flex-col px-8 pt-8 w-full">
                  <z-select [color]="'exp'" [required]="true"
                      [label]="'Job Type'| translate:language:ModuleNames.SurveyX" [language]="language"
                      [options]="jobTypeList"
                      [placeholder]="'Select your Job Type ...'| translate:language:ModuleNames.SurveyX"
                      [(ngModel)]="selectedJobType" (ngModelChange)="onJobTypeChanged($event)">
                      <ng-template #valueTemplate let-value>
                          {{value?.name | extract:language}}
                      </ng-template>

                      <ng-template #optionTemplate let-option>
                          {{option?.name | extract:language}}
                      </ng-template>
                  </z-select>
              </div>

                <div *ngIf="isContractType && selectedEmploymentStatus !== 'currently Unemployed'" class="flex flex-col px-8 pt-8 w-full">
                    <z-select [color]="'exp'" [required]="true"
                        [label]="'Contract Type'| translate:language:ModuleNames.SurveyX" [language]="language"
                        [options]="contractTypeList"
                        [placeholder]="'Select your Contract Type ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="selectedContractType" (ngModelChange)="onContractTypeChanged($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | extract:language}}
                        </ng-template>

                        <ng-template #optionTemplate let-option>
                            {{option?.name | extract:language}}
                        </ng-template>
                    </z-select>
                </div>

                <div *ngIf="isPreferredCountryOfWork" class="flex flex-col px-8 pt-8 w-full">
                  <z-select [color]="'exp'" [required]="true"
                      [label]="'Preferred Country Of Work'| translate:language:ModuleNames.SurveyX"
                      [language]="language" [options]="locationList"
                      [placeholder]="'Select your Preferred Country Of Work ...'| translate:language:ModuleNames.SurveyX"
                      [(ngModel)]="selectedPreferredCountryOfWork"
                      (ngModelChange)="onPreferredCountryOfWorkChanged($event)">
                      <ng-template #valueTemplate let-value>
                          {{value?.name | extract:language}}
                      </ng-template>

                      <ng-template #optionTemplate let-option>
                          {{option?.name | extract:language}}
                      </ng-template>
                  </z-select>
              </div>

                <div *ngIf="isDepartment && selectedEmploymentStatus !== 'currently Unemployed'" class="flex flex-col px-8 pt-8 w-full">
                    <z-select [color]="'exp'" [required]="true"
                        [label]="'Department'| translate:language:ModuleNames.SurveyX" [language]="language"
                        [options]="departmentList"
                        [placeholder]="'Select your Department ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="selectedDepartment" (ngModelChange)="onDepartmentChanged($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | translate:language:ModuleNames.SurveyX}}
                        </ng-template>

                        <ng-template #optionTemplate let-option>
                            {{option?.name | translate:language:ModuleNames.SurveyX}}
                        </ng-template>
                    </z-select>
                </div>


                <div *ngIf="isCompanyName && selectedEmploymentStatus !== 'currently Unemployed'" class="flex flex-row px-8 pt-8 w-full">
                    <z-input [required]="true" [language]="language"
                        [label]="'Company Name'| translate:language:ModuleNames.SurveyX"
                        [placeholder]="'Enter your company name ...'| translate:language:ModuleNames.SurveyX"
                        class="w-full" [(ngModel)]="respondentCompanyName">
                    </z-input>
                </div>

                <div *ngIf="isCompanySize && selectedEmploymentStatus !== 'currently Unemployed'" class="flex flex-col px-8 pt-8 w-full">
                    <z-select [color]="'exp'" [required]="true"
                        [label]="'Company Size'| translate:language:ModuleNames.SurveyX" [language]="language"
                        [options]="companySizeList"
                        [placeholder]="'Select a Company Size ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="selectedCompanySize" (ngModelChange)="onCompanySizeChanged($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | extract:language }}
                        </ng-template>

                        <ng-template #optionTemplate let-option>
                            {{option?.name | extract:language }}
                        </ng-template>
                    </z-select>
                </div>

                <div *ngIf="isCompanyIndustry && selectedEmploymentStatus !== 'currently Unemployed'" class="flex flex-col px-8 pt-8 w-full">
                    <z-select [color]="'exp'" [required]="true"
                        [label]="'Company Industry'| translate:language:ModuleNames.SurveyX" [language]="language"
                        [options]="companyIndustryList"
                        [placeholder]="'Select a Company Industry ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="selectedCompanyIndustry" (ngModelChange)="onCompanyIndustryChanged($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | translate:language:ModuleNames.SurveyX }}
                        </ng-template>

                        <ng-template #optionTemplate let-option>
                            {{option?.name | translate:language:ModuleNames.SurveyX}}
                        </ng-template>
                    </z-select>
                </div>


                <div *ngIf="isLocation" class="flex flex-col px-8 pt-8 w-full">
                    <z-select [color]="'exp'" [required]="true"
                        [label]="'Location'| translate:language:ModuleNames.SurveyX" [language]="language"
                        [options]="locationList"
                        [placeholder]="'Select a Country ...'| translate:language:ModuleNames.SurveyX"
                        [(ngModel)]="selectedLocation" (ngModelChange)="onLocationChanged($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | extract:language}}
                        </ng-template>

                        <ng-template #optionTemplate let-option>
                            {{option?.name | extract:language}}
                        </ng-template>
                    </z-select>
                </div>

                <div [dir]="direction" class="flex w-full justify-between p-8">
                    <button
                        class="flex bg-zen-lightgray hover:bg-light  text-zen justify-center text-sm font-medium py-4 px-10 rounded"
                        (click)="onBack()">

                        {{"txt_Back"| translate:language:ModuleNames.SurveyX}}

                        <!-- <i class="fa-regular fa-chevron-right w-4 pt-3px fill-current" fill="currentColor"></i> -->
                    </button>
                    <button
                        class="flex bg-exp hover:bg-exp text-white justify-center text-sm font-medium py-4 px-10 rounded"
                        (click)="onNext()">

                        {{"Continue"| translate:language:ModuleNames.SurveyX}}

                        <!-- <i class="fa-regular fa-chevron-right w-4 pt-3px fill-current" fill="currentColor"></i> -->
                    </button>
                </div>

            </div>
        </div>
    </div>
    <!-- <z-footer></z-footer> -->
    <z-footer [dir]="direction" [ngClass]="language == 'AR' ? 'right-0' : 'left-0'"
    class="shrink-0"></z-footer>
</div>
