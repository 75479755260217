<zen-main-container (click)="logData(options)">
  <sidebar-container>
    <back-button [routerLink]="'/wellbeing/home'">
      <i [ngClass]="(language | language) ? 'fa-arrow-right' : 'fa-arrow-left'" class="fa-regular"></i>
      <span>{{
        "txt_BACKTOSUR_422" | translate : language : ModuleNames.Wellbeing
        }}</span>
    </back-button>
    <div class="flex flex-col items-stretch gap-12">
      <response-rate-card [language]="language" [completed]="completed" [total]="total" [precentage]="precentage"></response-rate-card>
      <section-sidebar [title]="
          'txtRespondentsByStatus'
            | translate : language : ModuleNames.Wellbeing
        " [TourStep]="{
          title:
            'txt_response_rate' | translate: language : ModuleNames.Wellbeing,
          text:
            'txt_Trackyour_168' | translate : language : ModuleNames.Wellbeing,
          order: 1,
          tourKey: 'wellbeing-live',on:(language|language)?'left':'right'
        }">
        <status-button *ngFor="let item of buttonList" (click)="onStatusChange(item)" [active]="(currentStatus.key | uppercase) == (item?.key | uppercase)" [status]="item?.key" [data]="item?.value">
          <ng-container text>{{
            resolveSurveyStatus(item?.key, language)
            }}</ng-container>
        </status-button>
      </section-sidebar>
    </div>
  </sidebar-container>
  <content-container class="flex flex-col items-stretch w-full">
    <div class="flex flex-col items-stretch h-28 z-10" sticky [top]="topValue">
      <section-head>
        <ng-container left>
          <div class="flex flex-row items-center gap-4">
            <h5 class="text-zen font-semibold text-2xl max-w-lg truncate">
              {{ surveyData?.title | extract : language }}
            </h5>
            <div class="flex flex-row gap-1 bg-zen-red h-5 rounded-l-full rounded-r-full px-1.5 items-center">
              <i class="fa-solid fa-circle text-white text-3xs"></i>
              <span class="text-white text-xs uppercase">{{
                "txtLive" | translate : language : ModuleNames.Wellbeing
                }}</span>
            </div>
          </div>
          <h5 class="text-zen-lite text-sm max-w-lg truncate">
            {{ surveyData?.description | extract : language }}
          </h5>
        </ng-container>
        <ng-container right>
          <div class="flex flex-row items-stretch gap-5">
            <a [matTooltip]="
                'txtUpdateSurveyEnddateAndReminders'
                  | translate : language : ModuleNames.Shared
              " zenButton styles="secondary icon-11"  *DemoAccount="'Button'; let isDemo = isDemo" (click)="isDemo?'':openLiveDateChanges()" [TourStep]="{
                title:
                  'txt_UpdateDat_941'
                  | translate : language : ModuleNames.Wellbeing,
                text:
                  'txt_Diditgobe_889'
                  | translate : language : ModuleNames.Wellbeing,
                order: 6,
                tourKey: 'wellbeing-live',
                on: (language | language) ? 'right' : 'left'
              }">
              <i class="fa-regular fa-bell"></i>
            </a>
            <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
            <a zenButton styles="secondary height-11" *DemoAccount="'Button'; let isDemo = isDemo" (click)="isDemo?'':shareSurveyLink()" [TourStep]="{
                title:
                  'txt_ShareSurv_665'
                  | translate : language : ModuleNames.Wellbeing,
                text:
                  'txt_Unlocksea_518'
                  | translate : language : ModuleNames.Wellbeing,
                order: 7,
                tourKey: 'wellbeing-live',
                on: (language | language) ? 'right' : 'left'
              }">{{
              "txt_Share_429" | translate : language : ModuleNames.Wellbeing
              }}</a>
            <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
            <a zenButton styles="red height-11" (click)="closeSurvey()" [TourStep]="{
                title:
                  'txt_CloseSurv_416'
                  | translate : language : ModuleNames.Wellbeing,
                text:
                  'txt_ClickOn_528'
                  | translate : language : ModuleNames.Wellbeing,
                order: 8,
                tourKey: 'wellbeing-live',
                on: (language | language) ? 'right' : 'left'
              }"><i class="fa-regular fa-do-not-enter"></i>
              {{
              "txt_CloseNow_473"
              | translate : language : ModuleNames.Wellbeing
              }}</a>
          </div>
        </ng-container>
      </section-head>
    </div>
    <div class="flex flex-row justify-between items-center h-20 bg-white px-10">
      <div class="flex flex-row items-stretch gap-6">
        <div class="flex flex-row items-stretch">
          <div class="text-sm rounded text-zen bg-white px-3 h-9 gap-2 leading-9 truncate flex flex-row justify-start items-center">
            <i [ngClass]="{ 'rotate-h-45': (language | language) }" class="fal fa-search text-zen transform"></i>
            <input [(ngModel)]="filterText" (ngModelChange)="searchTextUpdate($event)" [placeholder]="
                'txt_search' | translate : language : ModuleNames.Shared
              " class="bg-transparent overflow-hidden h-12 leading-12 text-sm w-80" type="text" />
          </div>
        </div>
        <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
        <a *DemoAccount="'Button'; let isDemo = isDemo" (click)="isDemo?'': bulkSendReminder()" zenButton styles="ghost height-11" [TourStep]="{
            title:
              'txt_send_reminders'
              | translate : language : ModuleNames.Wellbeing,
            text:
              'txt_Clickhere_145'
              | translate : language : ModuleNames.Wellbeing,
            order: 2,
            tourKey: 'wellbeing-live',
            on: (language | language) ? 'right' : 'left'
          }">
          <i class="fa-regular fa-bell"></i>
          <h5>
            {{
            "txt_send_reminders"
            | translate : language : ModuleNames.Wellbeing
            }}
          </h5>
        </a>
        <a (click)="deleteRespondents(selectedRespondents)" *ngIf="
            selectedRespondents?.length || respondentsTableService.selectAll
          " zenButton styles="ghost height-11">
          <i class="fa-regular fa-trash text-zen-red"></i>
          <h5>
            {{
            "txt_DeleteSel_329" | translate : language : ModuleNames.Wellbeing
            }}
          </h5>
        </a>
      </div>
      <div class="flex flex-row items-stretch justify-end gap-2.5">
        <i (click)="downloadExcell()" class="fa-regular fa-download" zenButton styles="ghost icon-11" [TourStep]="{
            title:'txt_ExportRes_128'| translate : language : ModuleNames.Wellbeing,
            text:'txt_Youcandow_532' | translate : language : ModuleNames.Wellbeing,
            order: 5,
            tourKey: 'wellbeing-live',
            on: (language | language) ? 'right' : 'left'
          }"></i>
        <span class="w-0 h-auto border-r border-zen border-opacity-20"></span>
        <a *DemoAccount="'Button'; let isDemo = isDemo" (click)="isDemo?'': openAddRespondentModal()" zenButton styles="ghost height-11" [TourStep]="{
            title: 'txt_NewRespon_215' | translate:language:ModuleNames.Wellbeing,
            text: 'txt_Forgottoa_219' | translate:language:ModuleNames.Wellbeing,
            order: 4,
            tourKey: 'wellbeing-live',
            on: (language | language) ? 'right' : 'left'
          }"><i class="fa-regular fa-plus"></i>
          {{
          "txt_NewRespon_215" | translate:language:ModuleNames.Wellbeing
          }}</a>
      </div>
    </div>
    <div class="flex flex-col px-7">
      <wb-respondent-pool [resetSelection]="resetSelection" [currentPage]="currentPage" [deleteCount]="respondentsPagedResult?.allDeleteCount" [language]="language" [customHeader]="true" (allSelected)="selectedAll($event)" (pageChanged)="onPageChange($event)" [pageSize]="pageSize" [totalRecords]="totalRecords" [options]="options" (selectedRespondents)="selectRespondents($event)">
        <ng-template let-element>
          <span *ngIf="showRespondentMenu(element)">
            <i [ZMenuTriggerFor]="menu" class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full btn text-center" [TourStep]="{
                title:
                  'txt_MoreActio_120'
                  | translate : language : ModuleNames.Wellbeing,
                text:
                  'txt_Sendmanua_129'
                  | translate : language : ModuleNames.Wellbeing,
                order: 3,
                tourKey: 'wellbeing-live',
                on: (language | language) ? 'right' : 'left',
                isRows: true
              }"></i>
            <z-menu #menu>
              <menuItems>
                <a MenuItem *DemoAccount="'Button'; let isDemo=isDemo" (click)="isDemo?'':sendSingleReminder(element)"><i class="fal fa-bell px-1 w-4"></i>
                  {{
                  "txt_send_reminder"
                  | translate : language : ModuleNames.Wellbeing
                  }}</a>
              <div *ngIf="element.status == wbStatus.Pending">
                <a MenuItem delete *DemoAccount="'Button'; let isDemo=isDemo" (click)="isDemo?'':deleteRespondent(element)"><i
                    class="fal fa-times-circle px-1 w-4"></i>
                  {{"btn_delete"|
                  translate:language:ModuleNames.Wellbeing}}</a>
              </div>
              </menuItems>
            </z-menu>
          </span>
        </ng-template>
      </wb-respondent-pool>
    </div>
  </content-container>

  <product-tour *ngIf="language" [key]="'wellbeing-live'" [offset]="60" [destroy]="true"></product-tour>
</zen-main-container>

<respondents-modal (closeDialog)="respondentAdd = false" [essentialObject]="essentialObject" *ngIf="respondentAdd" (uploadRequest)="insertUsingExcel($event)" (multipleRespondents)="createMultipleRespondent($event)" [language]="language" (singleRespondent)="createSingleRespondent($event)" [(respondentAdd)]="respondentAdd" [newRespondent]="newRespondent" (downloadCSV)="downloadExcel($event)"></respondents-modal>
