import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { DashletData } from '../data-models/dashlet-data';
import { catchError } from 'rxjs/operators';
import { DrillDownInformation } from '../data-models/drill-down-information';

@Injectable({
  providedIn: 'root'
})
export class DashletDataService {

  constructor() { }

  constructDashletData(data) {

    let dashletData = new DashletData();

    dashletData.categories = this.getUniqueCategory(data);
    let groups: any[] = [];
    groups = this.getUniqueGroup(data);
    dashletData.bar.series = [];
    if (groups.length > 0) {
      this.populateGroupedDashlet(dashletData, groups, data);
    } else {
      this.populateUnGroupedDashlet(dashletData, data);
    }
    dashletData.drillDown = data.DrillDown;

    return dashletData;

  }


  extractDrillDown(event, title, data) {
    let drillDown = new DrillDownInformation;
    if (event.group) {
      drillDown.name = title;
      drillDown.group = event.group;
      drillDown.category = event.category;

    } else {
      drillDown.name = title;
      drillDown.category = event.categoy;
    }



    drillDown.information = [];
    for (let i = 0; i < data.drillDown.length; i++) {
      let record = data.drillDown[i];
      if (record.Group) {
        if (record.Category == event.category && record.Group == event.group) {
          drillDown.information.push(record);
        }
      } else {
        if (record.Category == event.category) {
          drillDown.information.push(record);
        }
      }
    }
    return drillDown;
  }


  constructData(dataEndpoint: Observable<Object>) {

  }


  populateGroupedDashlet(dashletData, groups, data) {
    dashletData.groupedChart = true;
    for (let i = 0; i < groups.length; i++) {
      dashletData.bar.series.push({
        name: groups[i],
        data: []
      })
    }

    for (let c = 0; c < dashletData.categories.length; c++) {
      for (let i = 0; i < groups.length; i++) {
        let series = dashletData.bar.series.find(s => s.name == groups[i]);
        let groupCategoryData = data.Data.find(d => {
          if (d.Category == dashletData.categories[c] && d.Group == groups[i])
            return true
          else
            return false
        });
        if (groupCategoryData)
          series.data.push(groupCategoryData.Value)
        else
          series.data.push(0);
      }
    }

    dashletData.radar = { abbrvSeries: [], data: [] }

    for (let x = 0; x < dashletData.bar.series.length; x++) {
      let seriesName: string = dashletData.bar.series[x].name.replace(' ', '_');
      dashletData.radar.abbrvSeries.push(seriesName);
    }

    for (let i = 0; i < dashletData.categories.length; i++) {
      let cat: { [k: string]: any } = {};
      cat.name = dashletData.categories[i]
      for (let x = 0; x < dashletData.bar.series.length; x++) {
        let seriesName: string = dashletData.bar.series[x].name.replace(' ', '_');
        cat[seriesName] = dashletData.bar.series[x].data[i];
      }
      dashletData.radar.data.push(cat);
    }

    dashletData.Result = data.Result;
  }

  populateUnGroupedDashlet(dashletData, data) {
    dashletData.groupedChart = false;
    dashletData.bar.series = [{ data: [] }];
    dashletData.pie = [];
    let series = dashletData.bar.series;
    for (let c = 0; c < dashletData.categories.length; c++) {
      let groupCategoryData = data.Data.find(d => d.Category == dashletData.categories[c]);
      if (groupCategoryData) {
        series[0].data.push(groupCategoryData.Value)
        dashletData.pie.push({ name: dashletData.categories[c], value: (groupCategoryData.Value), group: groupCategoryData.group || '',  color: groupCategoryData.color || '' })
      }
    }
    dashletData.Result = data.Result;
  }


  getUniqueCategory(data) {
    let categories: any[] = [];
    for (let i = 0; i < data.Data.length; i++) {
      if (!categories.find(c => c == data.Data[i].Category))
        categories.push(data.Data[i].Category);
    }
    return categories;
  }

  getUniqueGroup(data) {
    let groups: any[] = [];
    if (data.Data.length > 0) {
      if (data.Data[0].Group) {
        for (let i = 0; i < data.Data.length; i++) {
          if (!groups.find(g => g == data.Data[i].Group))
            groups.push(data.Data[i].Group);
        }
      }
    }
    return groups;
  }



}
