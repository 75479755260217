import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ex-chart-wrapper',
  templateUrl: './chart-wrapper.component.html',
  styleUrls: ['./chart-wrapper.component.scss']
})
export class ChartWrapperComponent implements OnInit {

  @Input() title;
  @Input() export: boolean = false;
  @Input() bigData: boolean = false;

  @Input()
  subTitle;
  constructor() { }

  ngOnInit(): void {
  }

}
