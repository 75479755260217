<div class="flex flex-col items-stretch rounded bg-white bg-opacity-50">
  <div class="flex flex-col items-stretch  bg-white h-12 sticky top-0" (click)="open = !open" [id]="currentQuestion.key">
    <div class="flex flex-row items-center justify-between px-7 gap-2 bg-zen bg-opacity-10 h-12  btn rounded-t-sm">
      <div class="flex flex-col w-full items-stretch">
        <div class="flex flex-row items-center justify-between">
          <div class="flex flex-row items-center gap-2">
            <span class="bg-zen bg-opacity-5 text-zen leading-7 w-7 max-h-7 text-center text-lg rounded-full">{{'txtQ'|translate:language:ModuleNames.Shared}}</span>
            <h5 class="text-sm text-zen-lite font-normal">{{currentQuestion?.name | extract:language}}</h5>
          </div>
          <div class="flex flex-row justify-end gap-4 items-center" StopPropagation>
            <i class="fa-regular fa-times text-zen-red w-5 leading-5" *ngIf="currentSentiment && !parentSentiment" (click)="filterComments(null)"></i>
            <div class="flex flex-row justify-between items-center rounded gap-px overflow-hidden w-44 text-xxs text-zen" [ngClass]="{'pointer-events-none':parentSentiment}">
              <ng-container *ngFor="let percentage of percentageColors">
                <h5 [matTooltip]="returnSentimentName(percentage.key) | translate:language:module" *ngIf="currentQuestion[percentage.value]" (click)="!parentSentiment? filterComments(percentage.key):''" class="leading-5 text-center" [class]="percentage.color" [ngClass]="{'bg-opacity-30': percentage.key != currentSentiment && currentSentiment}" [style.width.%]="currentQuestion[percentage.value]">
                  {{currentQuestion[percentage.value] |
                  number:'1.0-0'}}%</h5>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <i [ngClass]="{'rotate-180':open}" class="fa-regular fa-angle-up transform transition-all ease-expo"></i>
    </div>
  </div>
  <div [ngClass]="{'pb-4 pt-4':open}">
    <div *ngIf="open" class="flex flex-col items-stretch rounded-sm overflow-hidden max-h-0 innerListScroll transition-all ease-expo gap-px" [ngClass]="{'max-h-96':open}">
      <ng-container *ngFor="let comment of comments; let n = index;trackBy:trackBy">
        <span class="border-t border-zen border-opacity-20 h-0" *ngIf="n != 0"></span>

        <comment-item [comment]="comment" [module]="module" [language]="language">
          <div class="flex flex-row items-stretch gap-4 pt-2" >
            <h5 *DemoAccount="'Button'; let isDemo=isDemo" class="text-xs font-medium leading-5 px-2 rounded-r-full rounded-l-full bg-opacity-10 text-zen btn"
              [ngStyle]="{'background-color':getLegendColor(comment.sentiment) + '66'}" [ZMenuTriggerFor]="isDemo?'':menu">
              <span class="px-1 firstUpper">{{(getSentimentName(comment.sentiment)|translate:language:module) ||
                ('txtNoSentiment'| translate:language:ModuleNames.Shared)}}</span> <i
                class="fa-regular fa-angle-down px-1"></i>
            </h5>
            <span class="border-r border-zen border-opacity-10 w-0"></span>
            <div *ngFor="let topic of comment.commentsAI.topic; let n = index">
              <div *DemoAccount="'Button'; let isDemo=isDemo"
                class="text-xs flex flex-row items-center font-medium leading-5 h px-2 rounded-r-full rounded-l-full bg-opacity-10 bg-zen text-zen maxw25 relative">
                <h5 class="px-1 maxw20 truncate firstUpper">{{topic.name | extract:language}}</h5>
                <i class="fa-regular fa-times px-1 btn" (click)="isDemo?'':removeTopic(comment, topic, n)"></i>
              </div>
            </div>
            <ng-container *ngIf="comment.commentsAI.topic.length < 3">
              <a *DemoAccount="'Button'; let isDemo=isDemo" class="text-exp text-xs font-normal leading-5 btn"
                (click)="isDemo?'':addTopic(comment)">{{ "txtAddTopic" |
                translate:language:ModuleNames.Shared }}</a>
            </ng-container>
          </div>
          <z-menu #menu>
            <ng-container *ngFor="let sentiment of sentiments">
              <a (click)="updateSentiment(comment, sentiment.key)" class="firstUpper text-zen" MenuItem>{{sentiment.name
                |
                translate:language:module}}</a>
            </ng-container>
          </z-menu>
        </comment-item>
      </ng-container>
      <a (click)="fetchMoreComments()" *ngIf="comments.length < totalComments" zenButton styles="ghost height-10" class="justify-center text-exp">{{ "txtShowMore" | translate:language:ModuleNames.Shared }}</a>
    </div>
  </div>
</div>