import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { fadeUpDown, ChildrenAnimation } from '@ex/module/shared/animations/allAnimations';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { config } from 'process';
import { LanguageService } from '@ex/module/core/services/language.service';

@Component({
  selector: 'zen-translation-editor',
  templateUrl: './zen-translation-editor.component.html',
  styleUrls: ['./zen-translation-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZenTranslationEditorComponent,
    },
  ],
  animations: [fadeUpDown, ChildrenAnimation],
})
export class ZenTranslationEditorComponent implements ControlValueAccessor, OnInit {
  openModal: boolean = false;
  translationsBK;

  @Input() additionalToolbarHiddenButtons :any[] = [];
  defaultToolbarHiddenButtons: any[] =
  [
      'strikeThrough',
      'subscript',
      'superscript',
      'fontName',
      'fontSize',
      'heading',
      'backgroundColor',
      'unlink',
      'insertHorizontalRule',
      'removeFormat',
      'toggleEditorMode',
    ];
    combinedButtons:any[];
    editorConfig: AngularEditorConfig ;

  ngOnInit(): void {
   this.combinedButtons = [...this.defaultToolbarHiddenButtons, ...this.additionalToolbarHiddenButtons];
   this.editorConfig = {
    editable: true,
    outline: false,
    showToolbar: true,
    // minHeight: '300px',
    toolbarHiddenButtons: [this.combinedButtons]

  };
  }




  constructor(private languageService: LanguageService) { }


  @ContentChild(TemplateRef) labelTemplate: TemplateRef<any>;
  @Input() label;
  @Input() classList: string = "text-sm text-zen truncate flex flex-row justify-between items-center  whitespace-normal w-full relative";
  @Input() invalid;
  @Input() required: boolean = false;
  @Input() language = 'EN';
  @Input() placeholder;
  supportedLanguages;
  @Input('supportedLanguages') set languages(langs) {
    this.supportedLanguages = langs;
  };
  @Input() maxCount;
  translations = [];
  selected = null;
  onChange: any = () => { };
  onTouch: any = () => { };
  writeValue(translations: any) {
    if (translations !== undefined && this.supportedLanguages.length) {
      Array.isArray(translations) ? this.translations = translations : this.translations = [];

      this.supportedLanguages?.forEach(element => {
        if (this.translations?.filter(s => s.key == element).length == 0)
          this.translations?.push({ key: element, name: '' });
      });

    }
    this.selected = this.translations?.find(s => s.key == this.language);
  }
  validateName(translations) {
    return translations?.find(s => s.name.length > 1 && s.name != '') ? false : true;
  }
  ModuleNames = ModuleName
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  handleChange(option?) {
    this.onChange(this.translations);
  }

  getLanguage(langISO: string) {
    return this.languageService.getLongLanguageName(langISO, this.language);
  }
  logData(e) {
    // console.log(e);
  }
  getJsonSting(val) {
    return JSON.stringify(val);
  }
  getTranslationsChanges(evt) {
    this.translations = evt;
    this.onChange(this.translations);
    this.selected = this.translations?.find(s => s.key == this.language);
  }
}
