import { Location, LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { GenerateLinkDto } from '@ex/wellbeing/surveys';
import { CDKPortalComponent, DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
@Component({
  selector: 'app-share-survey',
  templateUrl: './share-survey.component.html',
  styleUrls: ['./share-survey.component.scss']
})
export class ShareSurveyComponent extends BaseComponent implements OnInit, CDKPortalComponent {

  link;
  language;
  open: boolean;
  copied = false;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  constructor(@Inject(DATA_TOKEN) private data: { link: GenerateLinkDto, language: string }, private router: Router, private location: LocationStrategy) {
    super();

  }

  ngOnInit(): void {
    this.link = window.location.origin+ '/survey-x-landing/' + this.data.link.token;
    this.language = this.data.language;
    this.open = true;
  }


  closeModal() {
    this.open = false;
    this.close.emit(true);
  }

  copyToClipboard(item) {
    navigator.clipboard.writeText(this.link).then().catch(e => console.error(e));
    this.copied = true;
  }
}
