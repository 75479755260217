<z-modal [open]="true" [autoWidth]="true">
  <ng-container header>
    <div class="flex flex-row gap-2 items-center">
      <i class="fal fa-bell text-white bg-exp  shadow-xl rounded-full h-10 w-10 text-xl text-center leading-10 "></i>
      <h4 class="text-2xl font-bold text-zen">Email Template</h4>
    </div>
  </ng-container>
  <ng-container body>
    <!-- <div class="flex flex-row gap-2 items-center mb-5">
      <a (click)="invitationTemplate = true" class="btn leading-12 w-1/2 rounded-lg text-center uppercase" [ngClass]="!invitationTemplate?'cancel':'exp'">Email Template</a>
    </div> -->

    <zen-editor *ngIf="invitationTemplate" (reset)="resetTemplate()" [language]="language" [emailObject]="invitation"></zen-editor>

  </ng-container>
  <ng-container footer>
    <a (click)="close.emit(true)" class="btn cancel  text-center  rounded-md px-8 py-4 uppercase text-lg font-medium">Close</a>
    <a (click)="saveTemplate(); close.emit(true)"  class="btn exp rounded-md px-8 py-4 uppercase text-lg font-medium">Save</a>
  </ng-container>
</z-modal>
