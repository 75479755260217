import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sx-small-modal-header',
  templateUrl: './sx-small-modal-header.component.html',
  styleUrls: ['./sx-small-modal-header.component.scss'],
  animations: [trigger('inOutAnimation', [
    transition(':enter', [
      style({ transform: 'scale(1.05)', opacity: 0, filter: 'blur(6px)' }),
      animate('.2s ease-in', style({ transform: 'scale(1)', opacity: 1, filter: 'blur(0px)' })),
    ]),
    transition(':leave', [
      style({
        transform: 'scale(1) translateY(0px)',
        opacity: 1,
        filter: 'blur(0px)',
      }),
      animate(
        '.2s ease-in',
        style({ transform: 'scale(.95)', opacity: 0, filter: 'blur(6px)' })
      ),
    ]),
  ])]
})
export class SxSmallModalHeaderComponent implements OnInit {
  constructor() { }
  @Input() open: boolean = false;
  @Input() color: any;
  @Input() centerIcon = null;
  ngOnInit(): void {
  }
  get dir(){
    return document.body.dir;
  }
}
