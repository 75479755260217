import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewContainerRef } from "@angular/core";
import { BaseAngular } from "survey-angular-ui";
import { Serializer } from "survey-core";
import { TabDesignerViewModel } from "survey-creator-core";
import {
  QuestionTextModel,
  QuestionDropdownModel,
  QuestionRatingModel,
  QuestionCommentModel,
  QuestionRankingModel,
  QuestionImagePickerModel,
  QuestionBooleanModel,
  QuestionFileModel,
  QuestionMatrixModel,
  QuestionCheckboxModel
} from "survey-core";
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { ModuleName } from "@ex/module/shared/enums/ModuleName";
import { SharedService } from "@ex/module/survey-x/data-access/services/shared-service.service";
import { QuestionDatePickerModel } from "../../custom-datepicker/custom-datepicker.component";

// Serializer.findProperty("page", "title").maxLength = 100;
// Serializer.findProperty("question", "title").maxLength = 100;

// Serializer.findProperty("survey", "title").maxLength = 100;
// Serializer.findProperty("survey", "description").maxLength = 300;

const maxLengthProp = Serializer.getProperty('text', 'maxLength');
maxLengthProp.minValue = 0;
maxLengthProp.defaultValue = 20;

Serializer.addProperty("question", { name: "uniqueNPSText", type: "multiplevalues", default: [], category: "general" });
Serializer.addProperty("question", { name: "isIconChoice:boolean", default: false, category: "general" });
Serializer.addProperty("question", { name: "updateView:boolean", default: false, category: "general" });
Serializer.addProperty("question", { name: "isMultiSelect:boolean", default: false, category: "general" });

Serializer.addProperty("question", { name: "dateFormat", default: 'dd/mm/yyyy', category: "general" });
Serializer.addProperty("question", { name: "dateType", default: 'birthday', category: "general" });
Serializer.addProperty("question", { name: "min", default: null, category: "general" });
Serializer.addProperty("question", { name: "max", default: null, category: "general" });
Serializer.addProperty("question", { name: "forceTodayDate", default: true, category: "general" });

Serializer.addProperty("rating", { name: "ratingLabels", type: "multiplevalues", isLocalizible: true, category: "general", default: [] });

// Serializer.addProperty("question", { name: "labelTrue", type: "itemValues", isLocalizible:true , category: "general", default: "Yes, indeed!" });
// Serializer.addProperty("question", { name: "labelFalse", type: "itemValues", isLocalizible:true , category: "general", default: "Not at all" });

Serializer.addProperty("text", { name: "numberInputType", type: "string", category: "general", default: "number" });
Serializer.addProperty("text", { name: "numberInputCurrency", type: "string", category: "general", default: "usd" });
Serializer.addProperty("text", { name: "use1000Separator", type: "boolean", category: "general", default: false });
Serializer.addProperty("text", { name: "decimalPlacesValue", type: "number", category: "general", default: 0 });

Serializer.addProperty("survey", { name: "shuffleSections", default: false });
Serializer.addProperty("survey", { name: "shuffleQuestions", default: false });

Serializer.addProperty("question", { name: "includeNoneOption", type: "boolean", default: true, category: "general" });

Serializer.addProperty("survey", { name: "showQuestionTypeModal", type: "boolean", default: false, category: "general" });

Serializer.addProperty("question", { name: "dependencyQuestions", type: "multiplevalues", default: [], category: "general" });

@Component({
  selector: 'app-questions-builder-tab',
  templateUrl: './questions-builder-tab.component.html',
  styleUrls: ['./questions-builder-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionsBuilderTabComponent extends BaseAngular<TabDesignerViewModel> implements OnInit, AfterContentChecked {

  @Input() model!: TabDesignerViewModel;
  @Input() language: string;
  @Input() companyName: string;

  ModuleNames = ModuleName;

  open: boolean = false;
  isAddQuestionModalOpen: boolean = false;

  questionModels = {
    'short-text': QuestionTextModel,
    'long-text': QuestionCommentModel,
    'multiple-choices': QuestionCheckboxModel,
    'dropdown': QuestionDropdownModel,
    'start-rating': QuestionRatingModel,
    'rating-scale': QuestionRatingModel,
    'enps': QuestionRatingModel,
    'nps': QuestionRatingModel,
    'number-input': QuestionTextModel,
    'ranking': QuestionRankingModel,
    'image-choice': QuestionImagePickerModel,
    'icon-choice': QuestionImagePickerModel,
    'binary': QuestionBooleanModel,
    'matrix': QuestionMatrixModel,
    'slider': QuestionTextModel,
    'email-input': QuestionTextModel,
    'file-upload': QuestionFileModel,
    'date-input': QuestionDatePickerModel,
  };

  constructor(private translationPipe: TranslatePipe, private sharedService: SharedService, changeDetectorRef: ChangeDetectorRef,
    viewContainerRef?: ViewContainerRef) {
    super(changeDetectorRef, viewContainerRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    //Hide page navigator
    this.model.creator.showPageNavigator = false;
    this.model.creator.showSurveyTitle = false;

    //To show the new question properties drawer when a question edit button is clicked
    //This will hide the default question properties panel
    this.model.creator.onShowSidebarVisibilityChanged.add((sender, options) => {
      if (options.show == true) {
        this.open = true;
      } else {
        this.open = false;
      }

      //    this.changeDetectorRef.detectChanges();
      document.getElementById("drawer")?.click();
      //    this.changeDetectorRef.detectChanges();
    });

    this.sharedService.selectedQuestionContent.subscribe((question) => {
      if (question != null && question != undefined && question['question'] != null)
        this.open = true;
      else
        this.open = false;

      console.log('open: ', this.open);
      // this.changeDetectorRef.detectChanges();
    });

    this.model.survey.onPropertyChanged.add((sender, options) => {
      if (options.name == "showQuestionTypeModal")
        if (options.newValue == true)
          this.isAddQuestionModalOpen = true;
        else
          this.isAddQuestionModalOpen = false;

      this.changeDetectorRef.detectChanges();
    });

    // this.model.survey.onQuestionAdded.add((sender, options) => { });
    // this.model.survey.onPageAdded.add((sender, options) => { });
  }

  handleTypeSelected(type: string) {
    const QuestionModel = this.questionModels[type];
    var question = new QuestionModel("");
    console.log('type: ', type);

    this.model.creator.convertCurrentQuestion(question.getType());

    question = this.model.creator.selectedElement as any;

    if (QuestionModel) {
      // Set additional properties if needed
      if (type === 'start-rating')
        question.rateType = "stars";

      if (type === 'short-text')
        question.inputType = "text";

      if (type === 'number-input' || type === 'email-input' || type === 'date-input') {
        question.inputType = type.split('-')[0];
      }

      // if (type === 'ranking' || type === 'multiple-choices' || type === 'dropdown')
      //   question.choices = ["Item 1", "Item 2", "Item 3"];
      if (type === 'image-choice') {
        question.choices = [
          { value: "Lion", imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/lion.jpg" },
          { value: "Giraffe", imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/giraffe.jpg" },
          { value: "Panda", imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/panda.jpg" },
          { value: "Camel", imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/camel.jpg" },
        ];
        question["isIconChoice"] = false;
      }

      if (type === 'icon-choice') {
        question.choices = [
          { value: this.translationPipe.transform("Strongly Disagree", this.language, this.ModuleNames.SurveyX), imageLink: "face-sad-cry" },
          { value: this.translationPipe.transform("Disagree", this.language, this.ModuleNames.SurveyX), imageLink: "face-worried" },
          { value: this.translationPipe.transform("Neutral", this.language, this.ModuleNames.SurveyX), imageLink: "face-thinking" },
          { value: this.translationPipe.transform("Agree", this.language, this.ModuleNames.SurveyX), imageLink: "face-smile-relaxed" },
          { value: this.translationPipe.transform("Strongly Agree", this.language, this.ModuleNames.SurveyX), imageLink: "face-laugh-beam" },
        ];
        question["isIconChoice"] = true;
      }

      if (type === 'matrix') {
        // question.rows = ["Row 1", "Row 2", "Row 3"];
        // question.columns = ["Column 1", "Column 2", "Column 3"];
      }

      if (type === 'enps') {
        // question.rateMin = 0;
        // question.rateMax = 10;
        // question.rateStep = 1;
        question["rateType"] = 'enps';
        question.title = this.translationPipe.transform("How likely are you to recommend", this.language, this.ModuleNames.SurveyX) + " " + this.companyName + " " + this.translationPipe.transform("to your friends and relatives?", this.language, this.ModuleNames.SurveyX);
      }

      if (type === 'nps') {
        // question.rateMin = 0;
        // question.rateMax = 10;
        // question.rateStep = 1;
        question["rateType"] = 'nps';
        question.title = this.translationPipe.transform("How likely are you to recommend our", this.language, this.ModuleNames.SurveyX) + " " + this.translationPipe.transform("[Product(s)/Service(s)]", this.language, this.ModuleNames.SurveyX) + " " + this.translationPipe.transform("to your friends and relatives?", this.language, this.ModuleNames.SurveyX);
      }

      if (type === 'rating-scale') {
        question["rateType"] = 'labels';
      }

      if (type === 'file-upload') {
        //Set the maxSize to 1MB
        question.maxSize = 1024 * 1024;
      }
    }

    //This will force the survey to update the UI as soon as the property is changed
    var x = { name: 'testprop', newValue: 'newValue', oldValue: 'oldValue' }
    question.onPropertyChanged.fire(question, x);

    //Close the modal
    this.isAddQuestionModalOpen = false;

    // this.changeDetectorRef.detectChanges();
  }

  ngAfterContentChecked() {
    //this.changeDetectorRef.detectChanges();
  }

  public get survey() {
    return this.creator.survey;
  }

  public get creator() {
    return this.model.creator;
  }

  protected getModel(): TabDesignerViewModel {
    return this.model;
  }

  showQuestionPropertiesDrawer() {
    this.open = true;
    // this.changeDetectorRef.detectChanges();
  }
  ngOnDestroy(): void {
      this.sharedService.setSelectedQuestion(null);
  }
}
