import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  Self,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { ChildrenAnimation, fadeUpDown } from 'src/app/shared/animations/allAnimations';
import { ModuleName } from 'src/app/shared/enums/ModuleName';

@Component({
  selector: 'z-select',
  templateUrl: './zen-select.component.html',
  styleUrls: ['./zen-select.component.scss'],

  animations: [fadeUpDown, ChildrenAnimation],
})
export class ZenSelectComponent implements ControlValueAccessor {
  @Input() DemoLimit:number;
  showOptions = false;
  openOptions() {
    this.showOptions = !this.showOptions;
  }

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private el: ElementRef
    , @Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }
  @ContentChild('valueTemplate') valueTemplate: TemplateRef<any>;
  @ContentChild('optionTemplate') optionTemplate: TemplateRef<any>;
  open: boolean = false;
  searchValue: string;
  selected;
  isDisabled: boolean = false;
  list;
  rtl: boolean = false;
  tempList;
  @Input() invalid = false;
  @Input() submitted = false;
  @Input('trigger') triggeAarea: boolean = true;
  @Input('menuOpen') set menuOpen(condition) {
    if (condition)
      this.initDrop();
  }

  @Input() searchText = 'Search...';
  @Input() multiple = false;
  @Input() nullable = false;
  @Input() labelPosition;
  @Input() label;
  @Input() required: boolean = false;
  @Input() placeholder;
  @Input() key = 'key';
  @Input() color = 'exp';
  @Input() language = 'EN';
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() openChange: EventEmitter<any> = new EventEmitter<any>();
  @Input('options') set ops(options) {
    this.list = options;
    this.tempList = options;
    if (document.body.dir == 'rtl') {
      this.rtl = true;
    }
    console.log(options);
  }
  ModuleNames = ModuleName
  disable: boolean = false;
  onChange: any = () => { };
  onTouch: any = () => { };

  writeValue(key: any) {
    this.selected = key;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    console.log(this.isDisabled);

    this.isDisabled = isDisabled;
  }
  clear(e) {
    e.stopPropagation();
    this.multiple ? this.selected = [] : this.selected = '';
    this.onChange(this.selected);
    this.selectionChange.emit(this.selected);
  }

  handleSelection(option) {
    console.log(option);

    if (!this.multiple) {
      this.selected = option;
      this.onChange(option);
      this.close();

    }
    else {
      console.log(this.selected);

      if (this.selected?.find(s => s == option))
        this.selected.splice(this.selected.indexOf(option), 1);
      else this.selected.push(option);

      this.onChange(this.selected);

    }
    this.selectionChange.emit(this.selected);
  }
  selectionIncluded(key) {
    if (this.multiple)
      return this.selected?.find(s => s == key) ? true : false;
    else return key == this.selected;
  }

  close() {
    this.ngControl.control.markAsTouched();
    this.open = false;

    this.openChange.emit(false);
  }


  searchList(e, array) {
    this.tempList = this.list.filter(s => JSON.stringify(s).toLowerCase().includes(e.toLowerCase()));
  }

  logData(e) {
    console.log(e);

  }
  initDrop(e?) {
    if (this.isDisabled)
      return;
    document.getElementById("inputSearch")?.focus();
  }
  getValue(value) {
    return this.list.find(s => s[this.key] == value);
  }


}
