<div class="fixed z-50 flex flex-row justify-start h-0 left-0  top-0  bottom-0 right-0" (click)="logData(model.selectedElement)">
  <div class="drawer fixed h-screen z-50 bottom-0 flex flex-row justify-start"
    [ngClass]="{'bg-zenMain bg-opacity-30 w-screen left-0':showBackDrop, 'right-0':dir == 'ltr' && !showBackDrop, 'left-0':dir == 'rtl' && !showBackDrop}">
    <div (click)="$event.stopPropagation()"
      class="min-w-33 max-w-full min-h-full bg-white overflow-overlay overflow-y-auto shadow-sm"
      [ngClass]="dir == 'rtl'?'rtl':'ltr'">



      <div class="flex flex-col justify-start bg-zen-blueWhite min-h-full">
        <div class="flex flex-col pb-24 relative pt-10" *ngIf="open">
          <div class="flex flex-col px-10 mb-8">
            <h3 class="header-text">
              {{'Properties'| translate:language:ModuleNames.SurveyX }}
            </h3>
          </div>
          <div>
            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'Required Question'| translate:language:ModuleNames.SurveyX" [(ngModel)]="isQuestionRequired"
                (ngModelChange)="onChanges('isQuestionRequired: '+ isQuestionRequired)"></z-toggle>
            </div>
          </div>



          <div *ngIf="questionType == 'short-text'">
            <hr class="w-full mb-8">



            <div>
              <div class="flex flex-row w-full unded bg-opacity-10 px-10 mb-5">
                <label class="form-label w-full">
                  {{'txtMax Characters'|
                  translate:language:ModuleNames.SurveyX }}
                </label>
              </div>



              <div class="flex justify-between gap-5 px-10 mb-8">
                <div>
                  <label for="shortTextMinCharacters" class="form-label block mb-2">
                    {{'Min'| translate:language:ModuleNames.SurveyX }}
                  </label>



                  <input type="number" id="shortTextMinCharacters" class="form-input block w-full p-2.5"
                    [(ngModel)]="shortTextMinCharacters"
                    (ngModelChange)="onChanges('shortTextMinCharacters: '+ shortTextMinCharacters)" required>
                </div>



                <div>
                  <label for="shortTextMaxCharacters" class="form-label block mb-2">
                    {{'Max'| translate:language:ModuleNames.SurveyX }}
                  </label>
                  <input type="number" id="shortTextMaxCharacters" class="form-input block w-full p-2.5"
                    [maxNumber]="300" [(ngModel)]="shortTextMaxCharacters"
                    (ngModelChange)="onChanges('shortTextMaxCharacters: '+ shortTextMaxCharacters);" required>
                </div>
              </div>
            </div>
            <div class="w-full px-10 mb-8">
              <label for="shortTextPlaceholder" class="form-label block mb-2">
                {{"Placeholder text" | translate:language:ModuleNames.SurveyX}}
              </label>
              <input type="text" id="shortTextPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="textPlaceholder" (ngModelChange)="onChanges('placeholderText: '+ textPlaceholder)"
                placeholder="Enter placeholder text here...">
            </div>
          </div>



          <div *ngIf="questionType == 'long-text'">
            <hr class="w-full mb-8">



            <div>
              <div class="flex flex-row w-full unded bg-opacity-10 px-10 mb-5">
                <label class="form-label w-full">
                  {{'txtMax Characters'| translate:language:ModuleNames.SurveyX }}
                </label>
              </div>



              <div class="flex justify-between gap-5 px-10 mb-8">
                <div>
                  <label for="longTextMinCharacters" class="form-label block mb-2">
                    {{'Min'| translate:language:ModuleNames.SurveyX }}
                  </label>
                  <input type="number" id="longTextMinCharacters" class="form-input block w-full p-2.5"
                    [(ngModel)]="longTextMinCharacters"
                    (ngModelChange)="onChanges('longTextMinCharacters: '+ longTextMinCharacters)" required>
                </div>



                <div>
                  <label for="longTextMaxCharacters" class="form-label block mb-2">
                    {{'Max'| translate:language:ModuleNames.SurveyX }}
                  </label>
                  <input type="number" id="longTextMaxCharacters" class="form-input block w-full p-2.5"
                    [maxNumber]="5000" [(ngModel)]="longTextMaxCharacters"
                    (ngModelChange)="onChanges('longTextMaxCharacters: '+ longTextMaxCharacters)" required>
                </div>
              </div>
            </div>
            <div class="w-full px-10 mb-8">
              <label for="longTextPlaceholder" class="form-label block mb-2">
                {{"Placeholder text" | translate:language:ModuleNames.SurveyX}}
              </label>
              <input type="text" id="longTextPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="textPlaceholder" (ngModelChange)="onChanges('placeholderText: '+ textPlaceholder)"
                placeholder="Enter placeholder text here...">
            </div>
          </div>



          <div *ngIf="questionType == 'multiple-choices'">
            <div>
              <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
                <z-toggle class="w-full" [disable]="false"
                  [label]="'Multiple Selection' | translate:language:ModuleNames.SurveyX"
                  [(ngModel)]="allowMultipleSelection"
                  (ngModelChange)="onChanges('allowMultipleSelection: '+ allowMultipleSelection);  model.selectedElement['isMultiSelect'] = $event; changeType()"></z-toggle>
              </div>



              <div class="flex justify-between gap-5 px-10 mb-8" *ngIf="allowMultipleSelection">
                <div class="w-full">
                  <select class="form-input block w-full p-2.5" [(ngModel)]="multipleSelectionType"
                    (ngModelChange)="onChanges('multipleSelectionType: '+ multipleSelectionType);">
                    <option [ngValue]="'select-only'" selected>
                      {{'txtSelect only...'|
                      translate:language:ModuleNames.SurveyX }}
                    </option>
                    <option [ngValue]="'select-range'">
                      {{'txtSelect range...'|
                      translate:language:ModuleNames.SurveyX }}
                    </option>
                  </select>
                </div>



                <div class="flex items-end" *ngIf="multipleSelectionType == 'select-only'">
                  <input type="number" class="form-input block px-10 p-2.5" min="2"
                    [max]="this.model.selectedElement['choices'].length" [(ngModel)]="multipleSelectionCount"
                    style="text-align:center;" required>
                </div>
              </div>



              <div class="flex justify-between gap-5 px-10 mb-8" *ngIf="multipleSelectionType == 'select-range'">
                <div class="w-full">
                  <label for="longTextMinCharacters" class="form-label block mb-2">
                    {{'Min'|
                    translate:language:ModuleNames.SurveyX }}
                  </label>
                  <input type="number" id="longTextMinCharacters" min="1" [max]="multipleMaxSelectionCount-1"
                    class="form-input block w-full p-2.5"
                    (ngModelChange)="onChanges('multipleMinSelectionCount: '+ multipleMinSelectionCount)"
                    [(ngModel)]="multipleMinSelectionCount" required>
                </div>



                <div class="w-full">
                  <label for="longTextMaxCharacters" class="form-label block mb-2">
                    {{'Max'|
                    translate:language:ModuleNames.SurveyX }}
                  </label>
                  <input type="number" id="longTextMaxCharacters" class="form-input block w-full p-2.5"
                    [(ngModel)]="multipleMaxSelectionCount"
                    (ngModelChange)="onChanges('multipleMaxSelectionCount: '+ multipleMaxSelectionCount); $event > model.selectedElement['choices'].length? multipleMaxSelectionCount = model.selectedElement['choices'].length:''"
                    [min]="multipleMinSelectionCount+1" [max]="model.selectedElement['choices'].length"
                    [maxLength]="model.selectedElement['choices'].length" required>
                </div>
              </div>
            </div>



            <hr class="w-full mb-8">



            <div>
              <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
                <z-toggle class="w-full" [disable]="false"
                  [label]="'txtAdd `Other Answer` option' | translate:language:ModuleNames.SurveyX"
                  [(ngModel)]="addOtherAnswerOption"
                  (ngModelChange)="onChanges('addOtherAnswerOption: '+  addOtherAnswerOption)"></z-toggle>
              </div>



              <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
                <z-toggle class="w-full" [disable]="false"
                  [label]="'txtAdd `None of the above` option' | translate:language:ModuleNames.SurveyX"
                  [(ngModel)]="addNoneAnswerOption"
                  (ngModelChange)="onChanges('addNoneAnswerOption: '+  addNoneAnswerOption)"></z-toggle>
              </div>



              <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="addNoneAnswerOption">
                <mat-checkbox [(ngModel)]="includeNoneOption" class="w-full h-5 custom-frame"
                  (ngModelChange)="onChanges('includeNoneOption: '+  includeNoneOption)">
                  <span *ngIf="language != 'AR'" class="text-zen-primarydark text-sm">
                    Include
                    <span class="font-semibold">None of the above</span>
                    option in the analysis?
                  </span>
                  <span *ngIf="language == 'AR'" class="text-zen-primarydark text-sm">
                    احتساب خيار
                    <span class="font-semibold">لا شيء</span>
                    في التحليل؟
                  </span>
                </mat-checkbox>
              </div>



              <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="allowMultipleSelection">
                <z-toggle class="w-full" [disable]="false"
                  [label]="'txtAdd `All of the above` option' | translate:language:ModuleNames.SurveyX"
                  [(ngModel)]="addAllAnswerOption"
                  (ngModelChange)="onChanges('addAllAnswerOption: '+  addAllAnswerOption)"></z-toggle>
              </div>
            </div>



            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtAdd `Comment` box' | translate:language:ModuleNames.SurveyX" [(ngModel)]="addCommentBox"
                (ngModelChange)="onChanges('addCommentBox: '+  addCommentBox)"></z-toggle>
            </div>



            <div class="flex flex-col w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="addCommentBox">
              <label for="commentBoxLabel" class="form-label block w-full mb-2">
                {{'txtComment Box Label'|
                translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxLabel" class="form-input block w-full p-2.5 mb-3"
                [(ngModel)]="commentBoxLabel">



              <label for="commentBoxPlaceholder" class="form-label block w-full mb-2">
                {{'txtComment Box Placeholder'| translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="commentBoxPlaceholder">
            </div>



            <!-- <div class="flex flex-row w-full items-center rounded bg-opacity-10 px-10 mb-8">
                            <label
                                for="columnsCount"
                                class="form-label block w-full mb-2"
                            >
                                {{'Number of Columns'|
                                translate:language:ModuleNames.SurveyX }}
                            </label>
                            <input
                                type="number"
                                id="columnsCount"
                                class="form-input block w-full p-2.5"
                                [(ngModel)]="columnsCount"
                            >
                        </div> -->



            <div class="w-full px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtRandomize Answers'| translate:language:ModuleNames.SurveyX" [(ngModel)]="randomizeAnswers"
                (ngModelChange)="onChanges('randomizeAnswers: '+ randomizeAnswers)">
              </z-toggle>
            </div>
          </div>



          <div *ngIf="questionType == 'dropdown'">
            <div class="px-10 mb-8" *ngIf="questionType == 'dropdown'">
              <label class="form-label block mb-2">
                {{'Selection Type'|
                translate:language:ModuleNames.SurveyX }}
              </label>



              <select class="form-input block w-full p-2.5" [(ngModel)]="dropDownSelectionType"
                (ngModelChange)="onChanges('dropDownSelectionType: '+ dropDownSelectionType)">
                <option selected [ngValue]="'single-selection'">
                  {{'txtSingle Selection'| translate:language:ModuleNames.SurveyX }}
                </option>
                <option [ngValue]="'multiple-selection'">
                  {{'txtMultiple Selection'| translate:language:ModuleNames.SurveyX }}
                </option>
                <option [ngValue]="'multiple-selection-Search'">
                  {{'txtMultiple Selection with Search bar'| translate:language:ModuleNames.SurveyX }}
                </option>
              </select>
            </div>



            <hr class="w-full mb-8">



            <div>
              <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
                <z-toggle class="w-full" [disable]="false"
                  [label]="'txtSorting' | translate:language:ModuleNames.SurveyX" [(ngModel)]="allowSorting"
                  (ngModelChange)="onChanges('allowSorting: '+ allowSorting)"></z-toggle>
              </div>



              <div class="px-10 mb-8" *ngIf="allowSorting">
                <select class="form-input block w-full p-2.5" [(ngModel)]="sortingType"
                  (ngModelChange)="onChanges('sortingType: '+ sortingType)">
                  <option selected [ngValue]="'random'">
                    {{'Random'| translate:language:ModuleNames.SurveyX }}
                  </option>
                  <option [ngValue]="'alphabetical'">
                    {{'txtAlphabetical'| translate:language:ModuleNames.SurveyX }}
                  </option>
                </select>
              </div>
            </div>



            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtAdd `Comment` box' | translate:language:ModuleNames.SurveyX" [(ngModel)]="addCommentBox"
                (ngModelChange)="onChanges('addCommentBox: '+ addCommentBox)"></z-toggle>
            </div>



            <div class="flex flex-col w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="addCommentBox">
              <label for="commentBoxLabel" class="form-label block w-full mb-2">
                {{'txtComment Box Label'|
                translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxLabel" class="form-input block w-full p-2.5 mb-3"
                [(ngModel)]="commentBoxLabel">



              <label for="commentBoxPlaceholder" class="form-label block w-full mb-2">
                {{'txtComment Box Placeholder'| translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="commentBoxPlaceholder">
            </div>
          </div>



          <div *ngIf="questionType == 'number-input'">
            <div class="px-10 mb-8">
              <label class="form-label block mb-2">
                {{'Type' | translate:language:ModuleNames.SurveyX}}
              </label>



              <select class="form-input block w-full p-2.5" [(ngModel)]="numberInputType"
                (ngModelChange)="onChanges('numberInputType: '+ numberInputType)">
                <option selected [ngValue]="'number'">
                  {{'Number'| translate:language:ModuleNames.SurveyX}}
                </option>
                <option [ngValue]="'percentage'">
                  {{'Percentage'| translate:language:ModuleNames.SurveyX}}
                </option>
                <option [ngValue]="'currency'">
                  {{'Currency'| translate:language:ModuleNames.SurveyX}}
                </option>
                <option [ngValue]="'phone-number'">
                  {{'Phone Number'| translate:language:ModuleNames.SurveyX}}
                </option>
              </select>
            </div>



            <div class="flex justify-between gap-5 px-10 mb-8" *ngIf="numberInputType == 'currency'">
              <!-- <app-currency-dropdown
                                class="form-input block w-full"
                                [(selectedCurrency)]="numberInputCurrency"
                                (selectedCurrencyChange)="numberInputCurrency"
                            ></app-currency-dropdown> -->

              <z-select class="w-full" [color]="'exp'" [required]="true"
                [label]="'Currency'| translate:language:ModuleNames.SurveyX" [language]="language"
                [options]="currenciesList" [placeholder]="'Select currency ...'| translate:language:ModuleNames.SurveyX"
                [(ngModel)]="numberInputCurrency" (ngModelChange)="onCurrencyChanged($event)">
                <ng-template #valueTemplate let-value>
                  ({{value?.symbol}}){{value?.name | extract:language}}
                </ng-template>

                <ng-template #optionTemplate let-option>
                  ({{option?.symbol}}){{option?.name | extract:language}}
                </ng-template>
              </z-select>



            </div>



            <!-- <div>
                            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
                                <z-toggle class="w-full" [disable]="false" [label]="'Decimal Places' | translate:language:ModuleNames.SurveyX"
                                    [(ngModel)]="allowDecimalPlaces"
                                    (ngModelChange)="onChanges('allowDecimalPlaces: '+ allowDecimalPlaces)"></z-toggle>
                            </div>

                            <div class="w-full px-10 mb-8" *ngIf="allowDecimalPlaces">
                                <input type="number" id="decimalPlacesValue" class="form-input block w-full p-2.5"
                                    [(ngModel)]="decimalPlacesValue">
                            </div>
                        </div> -->



            <!-- <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
                            <z-toggle class="w-full" [disable]="false" [label]="Use 1000 Separator (,)"
                                [(ngModel)]="use1000Separator"
                                (ngModelChange)="onChanges('use1000Separator: '+ use1000Separator)"></z-toggle>
                        </div> -->



            <hr class="w-full mb-8">



            <div class="w-full px-10 mb-8">
              <label for="visitors" class="form-label block mb-2">
                {{"Placeholder text" | translate:language:ModuleNames.SurveyX}}
              </label>
              <input type="text" id="visitors" class="form-input block w-full p-2.5" [(ngModel)]="placeholderText"
                (ngModelChange)="onChanges('placeholderText: '+ placeholderText)"
                placeholder="Enter placeholder text here...">
            </div>



            <div class="mb-8">
              <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
                <z-toggle class="w-full" [disable]="false"
                  [label]="'txtMax Characters' | translate:language:ModuleNames.SurveyX"
                  [(ngModel)]="setNumberInputMaxCharacters"
                  (ngModelChange)="onChanges('setNumberInputMaxCharacters: '+ setNumberInputMaxCharacters)"></z-toggle>
              </div>



              <div class="w-full px-10 mb-5" *ngIf="setNumberInputMaxCharacters">
                <input type="number" id="numberInputMaxValue" min="0" max="100" step="1"
                  class="form-input block w-full p-2.5" [(ngModel)]="numberInputMaxValue"
                  (keypress)="allowOnlyNumbers($event)">
              </div>
            </div>



            <hr class="w-full mb-8">
          </div>



          <div *ngIf="questionType == 'ranking'">
            <div>
              <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
                <z-toggle class="w-full" [disable]="false" [label]="'Sorting' | translate:language:ModuleNames.SurveyX"
                  [(ngModel)]="allowSorting" (ngModelChange)="onChanges('allowSorting: '+ allowSorting)"></z-toggle>
              </div>



              <div class="px-10 mb-8" *ngIf="allowSorting">
                <select class="form-input block w-full p-2.5" [(ngModel)]="sortingType"
                  (ngModelChange)="onChanges('sortingType: '+ sortingType)">
                  <option selected [ngValue]="'random'">
                    {{'Random'|
                    translate:language:ModuleNames.SurveyX }}
                  </option>
                  <option [ngValue]="'alphabetical'">
                    {{'txtAlphabetical'|
                    translate:language:ModuleNames.SurveyX }}
                  </option>
                </select>
              </div>
            </div>



            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtAdd `Comment` box' | translate:language:ModuleNames.SurveyX" [(ngModel)]="addCommentBox"
                (ngModelChange)="onChanges('addCommentBox: '+ addCommentBox)"></z-toggle>
            </div>



            <div class="flex flex-col w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="addCommentBox">
              <label for="commentBoxLabel" class="form-label block w-full mb-2">
                {{'txtComment Box Label'|
                translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxLabel" class="form-input block w-full p-2.5 mb-3"
                [(ngModel)]="commentBoxLabel">



              <label for="commentBoxPlaceholder" class="form-label block w-full mb-2">
                {{'txtComment Box Placeholder'| translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="commentBoxPlaceholder">
            </div>
          </div>



          <div *ngIf="questionType == 'binary'">
            <hr class="w-full mb-8">
            <div>
              <div class="w-full px-10 mb-5">
                <label for="visitors" class="form-label block mb-2">
                  {{'Positive Value'|translate:language:ModuleNames.SurveyX }}
                </label>
                <input type="text" id="visitors" class="form-input block w-full p-2.5"
                  [(ngModel)]="booleanPositiveValue"
                  (ngModelChange)="onChanges('booleanPositiveValue: '+ booleanPositiveValue)">
              </div>



              <div class="w-full px-10 mb-5">
                <label for="visitors" class="form-label block mb-2">
                  {{'Negative Value'|
                  translate:language:ModuleNames.SurveyX }}
                </label>
                <input type="text" id="visitors" class="form-input block w-full p-2.5"
                  [(ngModel)]="booleanNegativeValue"
                  (ngModelChange)="onChanges('booleanNegativeValue: '+ booleanNegativeValue)">
              </div>
            </div>
          </div>



          <div *ngIf="questionType == 'datepicker'">
            <hr class="w-full mb-8">



            <div>
              <!-- <div class="w-full px-10 mb-5">
                                <label class="form-label block mb-2">
                                    {{'Date Format'|
                                    translate:language:ModuleNames.SurveyX }}
                                </label>
                                <select
                                    class="form-input block w-full p-2.5"
                                    [(ngModel)]="dateFormat"
                                    (ngModelChange)="onChanges('dateFormat: '+ dateFormat)"
                                >
                                    <option
                                        selected
                                        [ngValue]="'dd/mm/yyyy'"
                                    >
                                        DD/MM/YYYY
                                    </option>
                                </select>
                            </div> -->



              <!-- <div class="w-full px-10 mb-5">
                                <label class="form-label block mb-2">
                                    {{'Date Type'|
                                    translate:language:ModuleNames.SurveyX }}
                                </label>
                                <select
                                    class="form-input block w-full p-2.5"
                                    [(ngModel)]="datetype"
                                    (ngModelChange)="onChanges('datetype: '+ datetype)"
                                >
                                    <option
                                        selected
                                        [ngValue]="'birthday'"
                                    >
                                        {{'txtBirthday'| translate:language:ModuleNames.SurveyX }}
                                    </option>

                                </select>
                            </div> -->



              <!-- <div
                                class="flex justify-between gap-5 px-10 mb-8"
                                *ngIf="datetype == 'birthday'"
                            >
                                <div>
                                    <label class="form-label block mb-2">
                                        {{'txtFrom birth year'| translate:language:ModuleNames.SurveyX }}
                                    </label>
                                    <z-datepicker
                                        [direction]="direction"
                                        [language]="language"
                                        class="form-input block w-full"
                                        (ngModelChange)="changeBirthdayFromYearDate($event)"
                                        [ngModel]="birthdayFromYear"
                                    >
                                    </z-datepicker>
                                </div>



                                <div>
                                    <label class="form-label block mb-2">
                                        {{'txtUp to birth year'| translate:language:ModuleNames.SurveyX }}
                                    </label>
                                    <z-datepicker
                                        [direction]="direction"
                                        [language]="language"
                                        class="form-input block w-full"
                                        (ngModelChange)="changeBirthdayToYearDate($event)"
                                        [ngModel]="birthdayToYear"
                                    >
                                  </z-datepicker>
                                </div>
                            </div> -->

              <div class="flex flex-row justify-between gap-5 px-10 mb-8" *ngIf="datetype == 'birthday'">

                <z-datepicker [color]="'exp'" [max]="model.selectedElement['max']" [language]="language"
                  [label]="'txtFrom birth year'| translate:language:ModuleNames.SurveyX"
                  [(ngModel)]="model.selectedElement['min']"></z-datepicker>

                <z-datepicker [color]="'exp'" [min]="model.selectedElement['min']" [language]="language"
                  [label]="'txtUp to birth year'| translate:language:ModuleNames.SurveyX"
                  [(ngModel)]="model.selectedElement['max']"></z-datepicker>
              </div>



              <div *ngIf="datetype == 'range'">
                <!-- <div class="mb-8 px-10">
                                    <div class="flex flex-row w-full rounded bg-opacity-10">
                                        <z-toggle class="w-full" [disable]="false" [label]="'Force today's date'"
                                            [(ngModel)]="forceTodayDate"
                                            (ngModelChange)="onChanges('forceTodayDate: '+ forceTodayDate)"></z-toggle>

                                    </div>
                                    <p>The date picker will start at the same day when the respondent fills the survey.
                                    </p>
                                </div> -->



                <div class="mb-8 px-10">
                  <div class="flex flex-row w-full rounded bg-opacity-10">
                    <z-toggle class="w-full" [disable]="false" (ngModelChange)="onChanges('setMaxDate: '+ setMaxDate)"
                      [label]="'txtSet max date' | translate:language:ModuleNames.SurveyX"
                      [(ngModel)]="setMaxDate"></z-toggle>
                  </div>
                  <p class="text-sm text-zen">
                    {{'txtThe date picker will accept dates up to a set maximum date.'|
                    translate:language:ModuleNames.SurveyX }}
                  </p>
                </div>



                <div class="px-10 mb-8" *ngIf="setMaxDate">
                  <z-datepicker [direction]="direction" [language]="language" class="form-input block w-full"
                    [(ngModel)]="maxDateValue"></z-datepicker>
                </div>
              </div>
            </div>



            <hr class="w-full mb-8">
          </div>



          <div *ngIf="questionType == 'file-upload'">
            <div class="flex flex-row w-full rounded bg-opacity-10 mb-8 px-10">
              <z-toggle class="w-full" [disable]="false"
                [label]="'Allow Multiple Upload' | translate:language:ModuleNames.SurveyX"
                [(ngModel)]="allowMultipleUplaods"
                (ngModelChange)="onChanges('allowMultipleUplaods: '+ allowMultipleUplaods)"></z-toggle>
            </div>



            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 mb-8 px-10">
              <z-toggle class="w-full" [disable]="false"
                [label]="'Force specific file type' | translate:language:ModuleNames.SurveyX"
                [(ngModel)]="forceSpecificFileTypes"
                (ngModelChange)="onChanges('forceSpecificFileTypes: '+ forceSpecificFileTypes)"></z-toggle>
            </div>



            <div class="w-full px-10 mb-5" *ngIf="forceSpecificFileTypes">
              <label class="form-label block mb-2">
                {{'Allowed File Types'|
                translate:language:ModuleNames.SurveyX }}
              </label>
              <select class="form-input block w-full p-2.5" [(ngModel)]="allowedFileTypes"
                (ngModelChange)="onChanges('allowedFileTypes: '+ allowedFileTypes)" [multiple]="true">
                <option [ngValue]="'.jpg'">jpg</option>
                <option [ngValue]="'.png'">png</option>
                <option [ngValue]="'.pdf'">pdf</option>
              </select>
            </div>



            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtAdd `Comment` box' | translate:language:ModuleNames.SurveyX" [(ngModel)]="addCommentBox"
                (ngModelChange)="onChanges('addCommentBox: '+ addCommentBox)"></z-toggle>
            </div>



            <div class="flex flex-col w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="addCommentBox">
              <label for="commentBoxLabel" class="form-label block w-full mb-2">
                {{'txtComment Box Label'|
                translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxLabel" class="form-input block w-full p-2.5 mb-3"
                [(ngModel)]="commentBoxLabel">



              <label for="commentBoxPlaceholder" class="form-label block w-full mb-2">
                {{'txtComment Box Placeholder'| translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="commentBoxPlaceholder">
            </div>
          </div>



          <div *ngIf="questionType == 'start-rating'">
            <div class="flex justify-between gap-5 px-10 mb-8">
              <div class="flex items-end relative">
                <input type="number" class="form-input block w-32 p-2.5" style="text-align:center;"
                  [(ngModel)]="starRatingCount" max="10" min="3">
                <span *ngIf="starRatingCount > 10"
                  class="-bottom-5 absolute w-full textstart text-zen-red text-xxs">{{'txtMaxNumbers'|translate:language:ModuleNames.SurveyX}}</span>
              </div>



              <div class="w-full">
                <select class="form-input block w-full p-2.5" [(ngModel)]="starRatingIcon"
                  (ngModelChange)="onChanges('starRatingIcon: '+ starRatingIcon)">
                  <option [ngValue]="'fa-star'" selected>
                    {{'Star'|
                    translate:language:ModuleNames.SurveyX }}
                  </option>
                  <option [ngValue]="'fa-heart'">
                    {{'Heart'| translate:language:ModuleNames.SurveyX }}
                  </option>
                  <option [ngValue]="'fa-thumbs-up'">
                    {{'Thumbs Up'|
                    translate:language:ModuleNames.SurveyX }}
                  </option>
                  <option [ngValue]="'fa-face-smile'">
                    {{'Smiley Face'|
                    translate:language:ModuleNames.SurveyX }}
                  </option>
                </select>
              </div>
            </div>



            <div class="flex flex-row w-full rounded bg-opacity-10 mb-8 px-10">
              <z-toggle class="w-full" [disable]="false" [label]="'txtLabels' | translate:language:ModuleNames.SurveyX"
                [(ngModel)]="allowLabels" (ngModelChange)="onChanges('allowlabels: '+ allowLabels)"></z-toggle>
            </div>



            <div class="w-full mb-8 px-10" *ngIf="allowLabels">
              <div>
                <div class="flex flex-col w-full px-5 py-4 mb-5" *ngFor="let label of ratingLabels;let i = index"
                  [ngStyle]="{'background-color': labelToBackgroundColor(label.color)}">
                  <div class="flex justify-between gap-1">
                    <div class="flex justify-between gap-2 label-title">
                      <button class="px-1.5" (click)="label.open = !label.open">
                        <i [ngClass]="label.open ? 'fa-regular fa-minus' : 'fa-regular fa-plus'"></i>
                      </button>



                      <label class="label-title">{{label.text.default? label.text.default : label.text}}</label>
                    </div>



                    <button (click)="deleteLabel(label)" style="color: red;">
                      <i class="fa-regular fa-trash"></i>
                    </button>
                  </div>



                  <div class="flex flex-col gap-3 transition-all ease-expo overflow-hidden"
                    [ngClass]="label.open?'max-h-80':'max-h-0'">
                    <div>
                      <label class="form-label block mb-2">
                        {{'txtLabel'|
                        translate:language:ModuleNames.SurveyX }}
                      </label>
                      <input type="text" class="form-input block w-full p-2.5"
                        [(ngModel)]="label.text.default? label.text.default : label.text">
                    </div>



                    <div class="flex justify-between gap-1">
                      <div>
                        <label for="shortTextMinCharacters" class="form-label block mb-2">
                          {{'From'|
                          translate:language:ModuleNames.SurveyX }}
                        </label>
                        <input type="number" [max]="label.max" class="form-input block w-full p-2.5"
                          [maxNumber]="ratingLabels[i - 1]?.max + 1 || 1" [(ngModel)]="label.min">
                      </div>



                      <div>
                        <label for="shortTextMaxCharacters" class="form-label block mb-2">
                          {{'To'|
                          translate:language:ModuleNames.SurveyX }}
                        </label>
                        <input type="number" [max]="starRatingCount"
                          [maxNumber]="ratingLabels[i + 1]?.min?ratingLabels[i + 1]?.min - 1 : starRatingCount"
                          class="form-input block w-full p-2.5" [(ngModel)]="label.max">
                      </div>
                    </div>



                    <div>
                      <label for="shortTextMaxCharacters" class="form-label block mb-2">
                        {{'txtColorcoding'| translate:language:ModuleNames.SurveyX }}
                      </label>



                      <select class="form-input block w-full p-2.5" [(ngModel)]="label.color"
                        (ngModelChange)="onChanges('label.color: '+ label.color)">
                        <option [ngValue]="'#AAB7CC'" selected>
                          {{'txtNone'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#FF4530'">
                          {{'Red'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#FF9600'">
                          {{'Orange'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#FFC51C'">
                          {{'Yellow'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#B3DD37'">
                          {{'Light Green'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#3EB75B'">
                          {{'Green'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>



              <div class="flex justify-between items-center gap-3">
                <button style="color: #0082C6; font-size: 12px; font-weight: 600;" (click)="addStarRatingLabel()">
                  <i class="fa-regular fa-plus"></i>
                  {{'Add Label'| translate:language:ModuleNames.SurveyX }}
                </button>



                <p class="max-label" *ngIf="ratingLabels.length == 10">
                  {{'Maximum Labels reached!'|
                  translate:language:ModuleNames.SurveyX }}
                </p>
              </div>
            </div>



            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtAdd `Comment` box' | translate:language:ModuleNames.SurveyX" [(ngModel)]="addCommentBox"
                (ngModelChange)="onChanges('addCommentBox: '+ addCommentBox)"></z-toggle>
            </div>



            <div class="flex flex-col w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="addCommentBox">
              <label for="commentBoxLabel" class="form-label block w-full mb-2">
                {{'txtComment Box Label'|
                translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxLabel" class="form-input block w-full p-2.5 mb-3"
                [(ngModel)]="commentBoxLabel">



              <label for="commentBoxPlaceholder" class="form-label block w-full mb-2">
                {{'txtComment Box Placeholder'| translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="commentBoxPlaceholder">
            </div>
          </div>



          <div *ngIf="questionType == 'rating-scale'">
            <hr class="w-full mb-8">



            <div class="flex justify-between gap-5 px-10 mb-8 w-full">
              <div class="w-full">
                <label for="longTextMinCharacters" class="form-label block mb-2">
                  {{'Scale Type'|
                  translate:language:ModuleNames.SurveyX }}
                </label>
                <select class="form-input block w-full p-2.5" [(ngModel)]="scaleType"
                  (ngModelChange)="onChanges('scaleType: '+ scaleType)">
                  <option [ngValue]="'number'" selected>
                    {{'Number'|
                    translate:language:ModuleNames.SurveyX }}
                  </option>
                </select>
              </div>



              <div class="w-max">
                <label for="longTextMaxCharacters" class="form-label block mb-2">
                  {{'Step'|
                  translate:language:ModuleNames.SurveyX }}
                </label>
                <input type="number" class="form-input block w-full p-2.5" style="text-align:center;"
                  [(ngModel)]="scaleStepValue" disabled>
              </div>
            </div>



            <div class="flex justify-between gap-5 px-10 mb-8">
              <div class="w-full">
                <label for="shortTextMinCharacters" class="form-label block mb-2">
                  {{'Min'|
                  translate:language:ModuleNames.SurveyX }}
                </label>
                <input type="number" id="shortTextMinCharacters"
                  class="form-input block w-full p-2.5 text-center-important"
                  (change)="onChanges('scaleType: '+ scaleType)" [(ngModel)]="scaleMinValue" min="1"
                  [max]="scaleMaxValue-1">
              </div>



              <div class="w-full">
                <label for="shortTextMaxCharacters" class="form-label block mb-2">
                  {{'Max'|
                  translate:language:ModuleNames.SurveyX }}
                </label>
                <input type="number" id="shortTextMaxCharacters"
                  class="form-input block w-full p-2.5 text-center-important" maxNumber="10"
                  (change)="onChanges('scaleType: '+ scaleType)" [min]="scaleMinValue+1" max="10"
                  [(ngModel)]="scaleMaxValue">
              </div>
            </div>



            <div class="flex flex-row w-full rounded bg-opacity-10 mb-8 px-10">
              <z-toggle class="w-full" [disable]="false" [label]="'txtLabels' | translate:language:ModuleNames.SurveyX"
                [(ngModel)]="allowLabels" (ngModelChange)="onChanges('allowlabels: '+ allowLabels)"></z-toggle>
            </div>



            <div class="w-full mb-8 px-10" *ngIf="allowLabels">
              <div>

                <div class="flex flex-col w-full px-8 py-5 mb-5" *ngFor="let label of ratingLabels;let i = index"
                  [ngStyle]="{'background-color': label.color}">

                  <div class="flex justify-between gap-1">
                    <div class="flex justify-between gap-2 label-title">
                      <button class="px-1.5" (click)=" label.open = !label.open">
                        <i [ngClass]="label.open ? 'fa-regular fa-minus' : 'fa-regular fa-plus'"></i>
                      </button>



                      <label class="label-title">{{label.text.default? label.text.default : label.text}}</label>
                    </div>



                    <button (click)="deleteLabel(label)" style="color: red;">
                      <i class="fa-regular fa-trash"></i>
                    </button>
                  </div>



                  <div class="flex flex-col gap-3 transition-all ease-expo overflow-hidden"
                    [ngClass]="label.open?'max-h-80':'max-h-0'">
                    <div class="pt-5">
                      <label class="form-label block mb-2">
                        {{'Label'|
                        translate:language:ModuleNames.SurveyX }}
                      </label>
                      <input type="text" class="form-input block w-full p-2.5"
                        [(ngModel)]="label.text.default? label.text.default : label.text">
                    </div>



                    <div class="flex justify-between gap-1">
                      <div>
                        <label for="shortTextMinCharacters" class="form-label block mb-2">
                          {{'From'|
                          translate:language:ModuleNames.SurveyX }}
                        </label>
                        <input type="number" [max]="label.max" class="form-input block w-full p-2.5"
                          [minNumber]="ratingLabels[i - 1]?.max + 1 || 1" [maxNumber]="label.max -1"
                          [(ngModel)]="label.min" (ngModelChange)="!label.min?ratingLabels[i - 1]?.max +1:1">
                      </div>



                      <div>
                        <label for="shortTextMaxCharacters" class="form-label block mb-2">
                          {{'To'|
                          translate:language:ModuleNames.SurveyX }}
                        </label>
                        <input type="number"
                          [maxNumber]="ratingLabels[i + 1]?.min?ratingLabels[i + 1]?.min - 1 : scaleMaxValue"
                          [max]="scaleMaxValue" class="form-input block w-full p-2.5" [(ngModel)]="label.max"
                          (ngModelChange)="!label.max?ratingLabels[i + 1]?.min -1:10">
                      </div>
                    </div>



                    <div>
                      <label for="shortTextMaxCharacters" class="form-label block mb-2">
                        {{'txtColorcoding'| translate:language:ModuleNames.SurveyX }}
                      </label>



                      <select class="form-input block w-full p-2.5" [(ngModel)]="label.color"
                        (ngModelChange)="onChanges('label.color: '+ label.color)">
                        <option [ngValue]="'#ECEEF4'" selected>
                          {{'txtNone'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#FDEFF0'" selected>
                          {{'Red'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#FDF2E4'" selected>
                          {{'Orange'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#FDF9E4'" selected>
                          {{'Yellow'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#EFF9EB'" selected>
                          {{'Light Green'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                        <option [ngValue]="'#E6F7E4'" selected>
                          {{'Green'|
                          translate:language:ModuleNames.SurveyX }}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>



              <div class="flex justify-between items-center gap-3">
                <button style="color: #0082C6; font-size: 12px; font-weight: 600;" (click)="addLabel()">
                  <i class="fa-regular fa-plus"></i>
                  {{'txtAddLabel'| translate:language:ModuleNames.SurveyX }}
                </button>



                <p class="max-label" *ngIf="ratingLabels.length == 5">
                  {{'Maximum Labels reached!'|
                  translate:language:ModuleNames.SurveyX }}
                </p>
              </div>
            </div>
          </div>



          <div *ngIf="questionType == 'image-choice'">
            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'Multiple Selection' | translate:language:ModuleNames.SurveyX"
                [(ngModel)]="allowMultipleImageSelection"
                (ngModelChange)="onChanges('allowMultipleImageSelection: '+ allowMultipleImageSelection)"></z-toggle>
            </div>



            <div class="px-10 mb-8">
              <label for="shortTextMinCharacters" class="form-label block mb-2">
                {{'Options'|
                translate:language:ModuleNames.SurveyX }}
              </label>



              <div cdkDropList (cdkDropListDropped)="choiceDragNDrop($event)">
                <div class="flex items-center gap-5 mb-2"
                  *ngFor="let choice of this.model.selectedElement['choices']; index as choiceIndex;" cdkDrag>
                  <i class="fa-regular fa-grip-dots-vertical" style="color: #586F99;" cdkDragHandle></i>



                  <input type="file" style="display: none;" accept="image/*"
                    (change)="handleUpload($event.target.files,choice)"
                    (ngModelChange)="handleUpload($event.target.files,choice)" placeholder="Select image...">



                  <label class="file-upload flex gap-2 items-center w-full p-1">
                    <img [src]="choice.imageLink || 'http://placehold.it/180'" (click)="fileInput.click()">



                    <input type="text" style="width: inherit;" [(ngModel)]="choice.value" placeholder="Image Label">



                    <input type="file" style="display: none;" accept="image/*" #fileInput
                      (ngModelChange)="handleUpload($event.target.files,choice)"
                      (change)="handleUpload($event.target.files,choice)" placeholder="Select image...">
                  </label>



                  <button (click)="deleteImageChoice(choice)" style="color: #586F99;">
                    <i class="fa-regular fa-trash"></i>
                  </button>
                </div>
              </div>



              <div class="flex justify-between text-center items-center mb-8 mt-3">
                <button class="btn" style="color: #0082C6; font-size: 12px; font-weight: 600;"
                  (click)="addNewImageChoice()">
                  <i class="fa-regular fa-plus"></i>
                  {{'Add Image'| translate:language:ModuleNames.SurveyX }}
                </button>



                <p class="max-label" *ngIf="this.model.selectedElement['choices'].length >= 10">
                  {{'Maximum Images reached!'|
                  translate:language:ModuleNames.SurveyX }}
                </p>
              </div>
            </div>



            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtAdd `Comment` box' | translate:language:ModuleNames.SurveyX" [(ngModel)]="addCommentBox"
                (ngModelChange)="onChanges('addCommentBox: '+ addCommentBox)"></z-toggle>
            </div>



            <div class="flex flex-col w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="addCommentBox">
              <label for="commentBoxLabel" class="form-label block w-full mb-2">
                {{'txtComment Box Label'|
                translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxLabel" class="form-input block w-full p-2.5 mb-3"
                [(ngModel)]="commentBoxLabel">



              <label for="commentBoxPlaceholder" class="form-label block w-full mb-2">
                {{'txtComment Box Placeholder'| translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="commentBoxPlaceholder">
            </div>
          </div>



          <div *ngIf="questionType == 'icon-choice'">
            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'Multiple Selection' | translate:language:ModuleNames.SurveyX"
                [(ngModel)]="allowMultipleImageSelection"
                (ngModelChange)="onChanges('allowMultipleImageSelection: '+ allowMultipleImageSelection)"></z-toggle>
            </div>



            <div class="px-10 mb-8">
              <label for="shortTextMinCharacters" class="form-label block mb-2">
                {{'txtIcon/Label'| translate:language:ModuleNames.SurveyX }}
              </label>



              <div cdkDropList (cdkDropListDropped)="choiceDragNDrop($event)">
                <div class="flex items-center gap-5 mb-2"
                  *ngFor="let choice of this.model.selectedElement['choices']; index as choiceIndex;" cdkDrag>
                  <i class="fa-regular fa-grip-dots-vertical" style="color: #586F99;" cdkDragHandle></i>



                  <label class="file-upload flex gap-2 items-center w-full p-1">



                    <button [ZMenuTriggerFor]="menu" (click)="onChanges('cc')">
                      <i class="fa-light fa-diamond" *ngIf="choice.imageLink == null || choice.imageLink == ''"></i>
                      <i [class]="'fa-light fa-'+choice.imageLink"></i>
                    </button>



                    <input type="text" style="width: inherit;" [(ngModel)]="choice.value" placeholder="Icon Label">



                    <button [ZMenuTriggerFor]="menu" (click)="onChanges('cc')">
                      <div class="flex flex-col z-10 w-1/12 items-end">
                        <i class="fa-regular text-xxs fa-chevron-up"></i>
                        <i class="fa-regular text-xxs fa-chevron-down"></i>
                      </div>
                    </button>
                  </label>



                  <z-menu #menu>
                    <menuItems>
                      <a MenuItem class="py-2 flex gap-3" *ngFor="let iconItem of filteredIcons"
                        (click)="selectChoiceIcon(iconItem,choice)">
                        <i [class]="'fa-light fa-'+iconItem"></i>
                        <span class="icon-label">{{iconItem}}</span>
                      </a>
                    </menuItems>
                  </z-menu>



                  <ng-template #moreMenuItems></ng-template>



                  <button (click)="deleteImageChoice(choice)" style="color: #586F99;">
                    <i class="fa-regular fa-trash"></i>
                  </button>
                </div>



                <div class="flex justify-between text-center items-center mb-8 mt-3">
                  <button class="btn" style="color: #0082C6; font-size: 12px; font-weight: 600;"
                    (click)="addNewIconChoice()">
                    <i class="fa-regular fa-plus"></i>
                    {{'Add Icon'| translate:language:ModuleNames.SurveyX }}
                  </button>



                  <p class="max-label" *ngIf="this.model.selectedElement['choices'].length >= 10">
                    {{'Maximum Icons reached!'|
                    translate:language:ModuleNames.SurveyX }}
                  </p>
                </div>
              </div>
            </div>



            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtAdd `Comment` box' | translate:language:ModuleNames.SurveyX" [(ngModel)]="addCommentBox"
                (ngModelChange)="onChanges('addCommentBox: '+ addCommentBox)"></z-toggle>
            </div>



            <div class="flex flex-col w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="addCommentBox">
              <label for="commentBoxLabel" class="form-label block w-full mb-2">
                {{'txtComment Box Label'|
                translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxLabel" class="form-input block w-full p-2.5 mb-3"
                [(ngModel)]="commentBoxLabel">



              <label for="commentBoxPlaceholder" class="form-label block w-full mb-2">
                {{'txtComment Box Placeholder'| translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="commentBoxPlaceholder">
            </div>
          </div>



          <div *ngIf="questionType == 'matrix'">
            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtMultiple selections per row' | translate:language:ModuleNames.SurveyX"
                [(ngModel)]="allowMultipleSelectionPerRow"
                (ngModelChange)="onChanges('allowMultipleSelectionPerRow: '+ allowMultipleSelectionPerRow)"></z-toggle>
            </div>



            <hr class="w-full mb-8">



            <div class="px-10 mb-8">
              <label for="shortTextMinCharacters" class="form-label block mb-2">
                {{'Rows'|
                translate:language:ModuleNames.SurveyX }}
              </label>



              <div class="flex items-center gap-5 mb-2"
                *ngFor="let row of this.model.selectedElement['rows']; index as rowIndex;">
                <i class="fa-regular fa-grip-dots-vertical" style="color: #586F99;"></i>



                <input type="text" class="form-input block w-full p-2.5" [(ngModel)]="row.text"
                  (ngModelChange)="onChanges('row.text: '+ row.text)">



                <button (click)="deleteMatrixRow(row)" style="color: #586F99;">
                  <i class="fa-regular fa-trash"></i>
                </button>
              </div>



              <button class="mb-8 mt-3" style="color: #0082C6; font-size: 12px; font-weight: 600;"
                (click)="addMatrixRow()">
                <i class="fa-regular fa-plus"></i>
                {{'Add Row'| translate:language:ModuleNames.SurveyX }}
              </button>



              <hr class="w-full mb-8">



              <label for="shortTextMinCharacters" class="form-label block mb-2">
                {{'Columns'|
                translate:language:ModuleNames.SurveyX }}
              </label>



              <div *ngIf="this.model.selectedElement.getType() == 'matrix'">
                <div class="flex items-center gap-5 mb-2"
                  *ngFor="let column of this.model.selectedElement['columns']; index as columnIndex;">
                  <i class="fa-regular fa-grip-dots-vertical" style="color: #586F99;"></i>



                  <input type="text" class="form-input block w-full p-2.5" [(ngModel)]="column.text"
                    (ngModelChange)="onChanges('column.text: '+ column.text)">



                  <button (click)="deleteMatrixColumn(column)" style="color: #586F99;">
                    <i class="fa-regular fa-trash"></i>
                  </button>
                </div>



              </div>



              <div *ngIf="this.model.selectedElement.getType() != 'matrix'">
                <div class="flex items-center gap-5 mb-2"
                  *ngFor="let column of this.model.selectedElement['columns']; index as columnIndex;">
                  <i class="fa-regular fa-grip-dots-vertical" style="color: #586F99;"></i>



                  <input type="text" class="form-input block w-full p-2.5" [(ngModel)]="column.title"
                    (ngModelChange)="onChanges('column.title: '+ column.title)">



                  <button (click)="deleteMatrixColumn(column)" style="color: #586F99;">
                    <i class="fa-regular fa-trash"></i>
                  </button>
                </div>
              </div>



              <button class="mb-8 mt-3" style="color: #0082C6; font-size: 12px; font-weight: 600;"
                (click)="addMatrixColumn()">
                <i class="fa-regular fa-plus"></i>
                {{'Add Column'| translate:language:ModuleNames.SurveyX }}
              </button>
            </div>



            <hr class="w-full mb-8">



            <div class="flex flex-row w-full rounded bg-opacity-10 px-10 mb-8">
              <z-toggle class="w-full" [disable]="false"
                [label]="'txtAdd `Comment` box' | translate:language:ModuleNames.SurveyX" [(ngModel)]="addCommentBox"
                (ngModelChange)="onChanges('addCommentBox: '+ addCommentBox)">
              </z-toggle>
            </div>



            <div class="flex flex-col w-full rounded bg-opacity-10 px-10 mb-8" *ngIf="addCommentBox">
              <label for="commentBoxLabel" class="form-label block w-full mb-2">
                {{'txtComment Box Label'|
                translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxLabel" class="form-input block w-full p-2.5 mb-3"
                [(ngModel)]="commentBoxLabel">



              <label for="commentBoxPlaceholder" class="form-label block w-full mb-2">
                {{'txtComment Box Placeholder'| translate:language:ModuleNames.SurveyX }}
              </label>
              <input type="text" id="commentBoxPlaceholder" class="form-input block w-full p-2.5"
                [(ngModel)]="commentBoxPlaceholder">
            </div>
          </div>



          <div *ngIf="questionType == 'email-input'">
            <hr class="w-full mb-8">
          </div>



          <div *ngIf="questionType == 'slider'">
            <hr class="w-full mb-8">
          </div>



          <div *ngIf="questionType == 'enps' || questionType == 'nps'"></div>



          <!-- LOGIC -->
          <app-logic-properties [(model)]="model" [(language)]="language" [(showQuestionLogic)]="showQuestionLogic"
            [(showQuestionLogicConditions)]="showQuestionLogicConditions">
          </app-logic-properties>



          <div *ngIf="hasChoices()">
            <hr class="w-full my-8">



            <!-- <app-choices-logic-properties [(model)]="model" [(language)]="language"
              [(showChoicesLogic)]="showChoicesLogic"
              [(showChoicesLogicConditions)]="showChoicesLogicConditions"></app-choices-logic-properties> -->
          </div>
          <!-- LOGIC -->
        </div>
      </div>
    </div>



    <div class="flex h-full items-center py-12 justify-center shadow-sm w-full overflow-hidden px-10" id="backdrop">
      <div class="custom-question-width flex items-center" id="question" #question></div>
    </div>
  </div>
</div>