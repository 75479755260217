<div class="flex flex-col items-stretch bg-zen bg-opacity-10 -mr-7 -ml-7 pb-7 gap-7 mt-5" (click)="logData(questionTypes)" (keyup)="$event.stopPropagation()">
  <div class="flex flex-row items-stretch border-b border-zen border-opacity-10">
    <div class="flex flex-col w-full pb-7 gap-5" [TourStep]="{title:'txt_QuestionD_182' | translate: language:ModuleNames.Exit,text: 'txt_Fillinyou_413' | translate:language:ModuleNames.Exit,order:3,tourKey:'exit-new-question'}">
      <div class="flex flex-col items-stretch justify-center h-15 text-zen text-sm">
        <ng-content></ng-content>
      </div>
      <div class="flex flex-col gap-2.5 px-7">
        <div class="flex flex-row items-center justify-between" *ngIf="question.questionType != QuestionTypes.OpenText">
          <h5 class="text-xs text-zen opacity-70"><span *ngIf="question.questionType == QuestionTypes.MultiOptionMultiAnswer">{{ "txt_Choose4_247" |
              translate:language:ModuleNames.Exit }} {{question.max}}</span> </h5>
          <a class="text-exp text-xs btn  leading-7" (click)="addQuestionOption()">
            <i class="fa-regular fa-plus"></i>
            {{ "txt_AddChoice_507" | translate:language:ModuleNames.Exit }}
          </a>
        </div>
        <div *ngIf="question.questionType != QuestionTypes.OpenText; else textTemplate" class="flex flex-wrap -mr-1.5 -ml-1.5 max-h-48 innerListScroll">
          <div [ngClass]="question.questionType != QuestionTypes.MultiOptionMultiAnswer?'w-full':'w-1/2'" class="flex flex-col px-1.5 pb-5" *ngFor="let option of question.questionOptions">
            <div class="flex flex-row justify-end items-start h-0">
              <i (click)="removeOption(option)" class="fa-regular fa-times" zenButton styles="delete icon-4"></i>
            </div>
            <div class="flex flex-row items-center w-full gap-2.5 px-4 rounded-sm bg-zen bg-opacity-5">
              <i [ngClass]="question.questionType != QuestionTypes.MultiOptionMultiAnswer?'fa-circle':'fa-square'" class="fa-solid  text-white text-xl leading-12"></i>
              <div class="flex flex-col items-stretch w-full">
                <z-translation-input [invalid]="saveQuestion" classList="bg-transparent w-full flex flex-row justify-between items-center leading-9 truncate" [maxCount]="mdCount" [required]="true" [language]="language" [supportedLanguages]="selectedEntity.ContentLanguages" [(ngModel)]="option.name">
                </z-translation-input>
              </div>
            </div>
          </div>
        </div>
        <ng-template #textTemplate>
          <z-textarea [label]="'txtComment'| translate:language:ModuleNames.Exit" [ngModel]="question.comment" disabled></z-textarea>
        </ng-template>
      </div>
    </div>
    <span class="border-r w-0 border-zen border-opacity-10"></span>
    <div class="flex flex-col px-7 gap-5 w64 pb-7">
      <div class="flex flex-row justify-between items-center">
        <h5 class="text-zen text-xxs font-medium leading-15 truncate">{{ "txt_QUESTIONP_258" |
          translate:language:ModuleNames.Exit
          }}</h5>
        <product-tour [key]="'exit-new-question'" [offset]="122" [subTour]="true"></product-tour>
      </div>

      <div class="flex flex-col items-stretch gap-5">
        <z-toggle [label]="'txt_required' | translate: language:ModuleNames.Shared" [(ngModel)]="question.skippable" [opposite]="true" [TourStep]="{title:'txt_required' | translate: language:ModuleNames.Shared,text: 'txt_Enablethi_448' | translate:language:ModuleNames.Exit,order: 1,tourKey:'exit-new-question',on:(language|language)?'right':'left'}"></z-toggle>
        <div class="flex flex-col items-stretch gap-5" [TourStep]="{title:'txt_QuestionS_313' | translate: language:ModuleNames.Exit,text: 'txt_Settheque_136' | translate:language:ModuleNames.Exit,order: 2,tourKey:'exit-new-question',on:(language|language)?'right':'left'}">
          <z-select [label]="'txt_QuestionT_164' | translate:language:ModuleNames.Exit" [(ngModel)]="question.questionType" [options]="questionTypes">
            <ng-template #valueTemplate let-value>
              <h5 class="truncate"> {{ value?.name | translate:language:ModuleNames.Exit}}</h5>
            </ng-template>
          </z-select>

          <div class="flex flex-row gap-1 items-center" *ngIf="question.questionType == QuestionTypes.MultiOptionMultiAnswer ">
            <div class="flex flex-col items-stretch" [ngClass]="selectionType == 'range'?'w-3/5':'w-2/3'">
              <z-select [(ngModel)]="selectionType" [options]="selectionTypes">
                <ng-template #valueTemplate let-value>
                  <h5 class="truncate"> {{ value?.name | translate:language:ModuleNames.Exit}}</h5>
                </ng-template>
              </z-select>
            </div>
            <div class="flex flex-row items-center gap-1" [ngClass]="selectionType == 'range'?'w-2/5':'w-1/3'">
              <div class="flex flex-col items-stretch full" *ngIf="selectionType == 'min' ||selectionType == 'range'">
                <z-input [(ngModel)]="question.min" [number]="true" [max]="question.questionOptions.length -1"> </z-input>
              </div>
              <div class="flex flex-col items-stretch full" *ngIf="selectionType == 'max' ||selectionType == 'range'">
                <z-input [(ngModel)]="question.max" [number]="true" [max]="question.questionOptions.length"> </z-input>
              </div>
            </div>
          </div>


          <z-select [label]="'txt_leaver_seperation_type' | translate:language:ModuleNames.Exit" [(ngModel)]="seperationType" [options]="seperationTypes" (ngModelChange)="seperationTypeChanged($event)">
            <ng-template #valueTemplate let-value>
              <h5 class="truncate"> {{value?.key != 'all'? ( value?.name | extract:language) : (value?.name |
                translate:language:ModuleNames.Exit) }}</h5>
            </ng-template>
          </z-select>
        </div>

      </div>
    </div>
  </div>
  <div class="flex flex-row justify-between items-center px-7">
    <a zenButton styles="secondary height-9" class="border border-zen border-opacity-20" (click)="cancel.emit()">{{'btn_cancel'| translate:language:ModuleNames.Exit}}</a>
    <a zenButton styles="exp height-9" class="border border-zen border-opacity-20" (click)="saveChanges()" [TourStep]="{title:'txt_SaveChang_405' | translate: language:ModuleNames.Exit,text: 'txt_Clicksave_286' | translate:language:ModuleNames.Exit,order:4,tourKey:'exit-new-question',on:(language|language)?'right':'left'}">
      {{'txt_SaveChang_405'| translate:language:ModuleNames.Exit}}</a>
  </div>
</div>