import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountOverviewComponent } from './common/account-overview/account-overview.component';
import { LoginComponent } from './common/authentication/login/login.component';
import { EngagementLandingPageComponent } from './engagement/components/engagement-landing-page/engagement-landing-page.component';
import { HappinessLandingPageComponent } from './happiness/components/happiness-landing-page/happiness-landing-page.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { ErrorComponent } from './shared/error/error.component';
import { SettingsComponent } from './common/settings/settings.component';
import { TenantSetupComponent } from './common/tenant-setup/tenant-setup.component';
import { MainFrameComponent } from './common/main-frame/main-frame.component';
import { CallBackComponent } from './psychometric/components/shared/call-back/call-back.component';
import { WellbeingLandingPageComponent } from './wellbeing/features/wellbeing-landing-page/wellbeing-landing-page.component';
import { AssessmentLandingPageComponent } from './assessment/components/assessment-landing-page/assessment-landing-page.component';
import { SurveyxLandingComponent } from './survey-x/features/surveyx-landing/surveyx-landing.component';
import { RespondentInformationPageComponent } from './survey-x/features/respondent-information-page/respondent-information-page.component';
// import { EngagementModule } from './engagement/engagement.module';

const routes: Routes = [
  { path: 'login', component: LoginComponent  , data: { title: "Login" }},
  { path: '', redirectTo: '/settings', pathMatch: 'full', data: { title: "Settings" } },
  {
    path: '', component: MainFrameComponent, children: [
      { path: 'home', redirectTo: 'settings', data: { animation: 0, title: "Settings" } },


      { path: 'login/:tenantKey', component: LoginComponent, data: { title: "Login"  }},
      {
        path: 'new-tenant',
        component: TenantSetupComponent,
        canActivate: [AuthGuardService], data: { title: "New Tenant" }
      },
      {
        path: 'engagement',
        loadChildren: () =>
          import('./engagement/engagement.module').then((m) => m.EngagementModule),
        data: { animation: 3, name: 'txtEngagement' },
      },
      {
        path: 'happiness',
        loadChildren: () =>
          import('./happiness/happiness.module').then((m) => m.HappinessModule),
        data: { animation: 6, name: 'txtEmployeeHappiness' },
      },
      {
        path: 'assessment360',
        loadChildren: () =>
          import('./assessment/assessment.module').then((m) => m.AssessmentModule),
        data: { animation: 4, name: 'txt360DegreeFeedback' },
      },
      {
        path: 'assessment180',
        loadChildren: () =>
          import('./assessment180/assessment180.module').then((m) => m.Assessment180Module),
        data: { animation: 4, name: 'txt180DegreeFeedback' },
      },
      {
        path: 'exit',
        loadChildren: () => import('./exit/exit.module').then((m) => m.ExitModule),
        data: { animation: 5, name: 'txtEmployeeExitAnalysis' },
      },
      {
        path: 'psychometric',
        loadChildren: () =>
          import('./psychometric/psychometric.module').then(
            (m) => m.PsychometricModule
          ),
        data: { animation: 2, name: 'txtPsychometricAssessments' },
      },
      {
        path: 'accountoverview',
        component: AccountOverviewComponent,
        canActivate: [AuthGuardService], data: { animation: 0, name: 'txtAccountOverview', title: "Account Overview" }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuardService], data: { animation: -1, name: 'txtControlPanel', title: "Settings" }
      },
      {
        path: 'onboarding', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
        canActivate: [AuthGuardService], data: { animation: -1, name: 'txtOnboarding' }
      },
      {
        path: 'wellbeing', loadChildren: () => import('./wellbeing/wellbeing.module').then(m => m.WellbeingModule),
        canActivate: [AuthGuardService], data: { animation: -1, name: 'txtWellbeing' }
      },
      { path: 'exit-v2', loadChildren: () => import('./exit-new/exit-new.module').then(m => m.ExitNewModule),  data: { animation: 5, name: 'txtEmployeeExitAnalysis' },},
      { path: 'survey-x', loadChildren: () => import('./survey-x/surveyx.module').then(m => m.SurveyxModule),   data: { animation: -1, name: 'txtSurveyx', title: "Survey X" }},
    ]
  },
  {
    path: 'engagement-questionnaire/:refKey',
    loadChildren: () =>
      import('./surveys/engagement-survey/engagement-survey.module').then(
        (m) => m.EngagementSurveyModule
      ),
  },
  {
    path: 'engagement-landing/:refKey',
    component: EngagementLandingPageComponent,
  },
  {
    path: 'wellbeing-questionnaire/:refKey',
    loadChildren: () =>
      import('./surveys/wellbeing-survey/wellbeing-survey.module').then(
        (m) => m.WellbeingSurveyModule
      ),
  },
  {
    path: 'wellbeing-landing/:refKey',
    component: WellbeingLandingPageComponent,
  },

  {
    path: 'happiness-landing/:refKey',
    component: HappinessLandingPageComponent,
  },
  {
    path: 'assessment-landing', component: AssessmentLandingPageComponent,
    children: [ { path: '**', component: AssessmentLandingPageComponent } ]
  },
  {
    path: 'survey-x-landing/:refKey',
    component: SurveyxLandingComponent, data: { animation: 0, title: "Survey x Landing" }
  },
  {
  path: 'survey-x-questionnaire',  loadChildren: () =>
    import('./surveys/survey-x-questionnaire/survey-x-questionnaire.module').then(
      (m) => m.SurveyXQuestionnaireModule
    ),

  },
  {
    path: 'assessment-questionnaire/:refKey',
    loadChildren: () =>
      import('./surveys/assessment-survey/assessment-survey.module').then(
        (m) => m.AssessmentSurveyModule
      ),
  },
  {
    path: 'assessment-180-questionnaire/:refKey',
    loadChildren: () =>
      import('./surveys/assessment-180-survey/assessment-180-survey.module').then(
        (m) => m.Assessment180SurveyModule
      ),
  },
  {
    path: 'happiness-questionnaire/:refKey',
    loadChildren: () =>
      import('./surveys/happiness-survey/happiness-survey.module').then(
        (m) => m.HappinessSurveyModule
      ),
  },
  {
    path: 'questionnaire/:questionnaireKey',
    loadChildren: () =>
      import('./surveys/leaver-survey/leaver-survey.module').then(
        (m) => m.LeaverSurveyModule
      ),
  },
  { path: 'onboarding-questionnaire/:refKey', loadChildren: () => import('./surveys/onboarding-survey/onboarding-survey.module').then(m => m.OnboardingSurveyModule) },
  {
    path: 'setup',
    component: TenantSetupComponent,
    canActivate: [AuthGuardService], data: { animation: -2 }
  },
  { path: 'exit-questionnaire/:refKey', loadChildren: () => import('./surveys/exit-survey/exit-survey.module').then(m => m.ExitSurveyModule) },


  { path: 'psychometric-assessment/:tenantKey/:companyKey/:baseKey', component: CallBackComponent },
  { path: '**', redirectTo: '/settings' },
  { path: 'error', component: ErrorComponent },




  // { path: '**', component: HomeComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
