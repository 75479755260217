import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ChildrenAnimation, inOutAnimation } from 'src/app/shared/animations/allAnimations';

@Component({
  selector: 'zen-modal-stepper',
  templateUrl: './zen-modal-stepper.component.html',
  styleUrls: ['./zen-modal-stepper.component.scss'],
  animations: [ChildrenAnimation, inOutAnimation]
})
export class ZenModalStepperComponent implements OnInit {
  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) { }

  @ViewChild('body') body: ElementRef;
  @ViewChild('innerBody') innerBody: ElementRef;
  @Input() autoClose: boolean = false;
  @Input() open: boolean = false;
  @Input() stepper;
  @Output() openChange = new EventEmitter();
  @HostListener('document:click', ['$event'])
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.maintainChanges(200)
  }
  // @HostListener('document:click', ['$event'])
  // onClick(event) {
  //   console.log('clicked');

  //   this.maintainChanges(0)
  // }
  ngOnInit(): void {
    this.maintainChanges(200);
  }
  close() {
    if (this.autoClose) {
      this.open = false;
      setTimeout(() => {
        this.openChange.emit(false);
      }, 300);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.maintainChanges(500);
  }
  logData(e) {
    console.log(e);

  }

  maintainChanges(time) {
    this.cdr.detectChanges();
    setTimeout(() => {
      this.renderer.setStyle(this.body.nativeElement, 'height', this.innerBody.nativeElement.offsetHeight + 'px');
      this.renderer.setStyle(this.body.nativeElement, 'min-width', this.innerBody.nativeElement.offsetWidth + 'px');
      this.renderer.setStyle(this.body.nativeElement, 'max-height', '80vh');
      this.renderer.setStyle(this.body.nativeElement, 'max-width', '90vw');
    }, time);

  }

  get dir(){
    return document.body.dir;
  }
}
