import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PlatformUser } from '../../../core/data-models/platform-user';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from '../../../core/services/tenant.service';
import { Tenant } from '../../../core/data-models/tenant';
import { PlatformUserService } from '../../../core/services/platform-user.service';
import { LogService } from '../../../core/services/log.service';
import { Observer, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../core/services/language.service';
import { ZenSnackbarService, ZenSnackBarTypes } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { CrashReportingService } from '../../../core/services/crash-reporting.service';
import { LocalService } from '../../../core/services/local.service';
import { ChildrenAnimation, fadeUpDown, inOutAnimation } from '../../../shared/animations/allAnimations';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { EntityService } from 'src/app/core/services/entity.service';
import { CompanyPermission, products } from 'src/app/core/data-models/company-permission';
import { GeneralService } from 'src/app/core/services/general.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';




declare var $: any;
declare const window: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [inOutAnimation, fadeUpDown, ChildrenAnimation]
})
export class LoginComponent implements OnInit, OnDestroy {
  email: string;
  ModuleNames = ModuleName
  password: string;
  userSubscription: Subscription;
  language: string = 'EN';
  logoLoading: boolean = false;
  tenantLogo: string;
  base64Image: string;
  logoURL: string;
  tenantKeyExists: boolean = false;
  section: string = 'login';
  hide = true;
  returnUrl: string;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router, private languagePipe: LanguagePipe,
    private zenSnackBarService: ZenSnackbarService,
    private tenantService: TenantService,
    private platformUserService: PlatformUserService,
    private logService: LogService,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private crashReportingService: CrashReportingService,
    private localService: LocalService, private translationPipe: TranslatePipe,
    private entityService: EntityService,
    private generalService: GeneralService,
    private db: AngularFireDatabase
  ) { }
  private subscriptions = new Subscription();
  supportedLanguages: any = [];
  showLangage: boolean = false;
  isThomas: boolean = false;
  allUsers: PlatformUser[] = [];
  async ngOnInit() {


    sessionStorage.clear();

    //this.migrateData();

    //   let formattedIndustries: any[] = [];
    //   for(let i = 0; i < this.generalService.industries.length; i++){
    //     let industry = this.generalService.industries[i];
    //     if (!formattedIndustries.find((ind) => ind.key == industry.key)) {
    //       let allIndustries = this.generalService.industries.filter(ind => ind.key == industry.key);
    //       let names: any[] = [];
    //       for(let x = 0; x < allIndustries.length; x++){
    //         let indLang = allIndustries[x];
    //         let name = { key: indLang.language, name: indLang.name };
    //         names.push(name);
    //       }
    //       formattedIndustries.push({ key: industry.key, name: names, otherAnswer: false });
    //     }
    //   }
    //   formattedIndustries.sort(function(a, b){
    //     if(a.key < b.key) { return -1; }
    //     if(a.key > b.key) { return 1; }
    //     return 0;
    // })
    //   console.log(JSON.stringify(formattedIndustries));

    this.canvasStart();
    this.supportedLanguages = this.languageService.supportedLanguages.filter(s => s.key == 'AR' || s.key == 'EN');
    if (window.location.href.includes("thomas-hub"))
      this.isThomas = true;

    if (!this.isThomas)
      this.logoURL = 'assets/img/svg/logo.svg'; // to be resolved to client's logo
    else
      this.logoURL = 'assets/img/svg/thomas-icon.svg'
    let localLanguage = this.localService.getJsonValue('language');
    let sessionLanguage = this.localService.getJsonValue('language');
    if (!localLanguage && !sessionLanguage) {
      this.language = 'EN';
    } else if (localLanguage) {
      this.language = localLanguage;
    } else if (sessionLanguage) {
      this.language = sessionLanguage;
    }

    this.isArabic();

    
    this.languageService.language = this.language;
    this.localService.removeItem('sectionID');
    this.localService.removeItem('assessmentSectionID');
    this.localService.removeItem('pageID');
    this.localService.removeItem('selectedEngagement');
    this.localService.removeItem('selectedEntity');
    this.localService.removeItem('UserEmail');
    this.localService.removeItem('UserKey');
    this.localService.removeItem('UserEmail');
    this.localService.removeItem('selectedAssessment');
    this.localService.removeItem('timeout');
    this.localService.removeItem('Access');
    this.localService.removeItem('User');
    this.localService.removeItem('pageReference');
    this.localService.removeItem('localSettings');

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    let referenceKey = this.route.snapshot.paramMap.get('tenantKey');
    if (referenceKey) {
      this.tenantKeyExists = true;
      this.logoLoading = true;
      this.subscriptions.add(this.tenantService
        .fetchTenant(referenceKey)
        .subscribe((tenant: Tenant) => {
          this.tenantLogo = tenant.logoURL;
          this.logoLoading = false;
          this.localService.setJsonValue('logoURL', this.tenantLogo);
          this.subscriptions.add(this.getBase64ImageFromURL(this.tenantLogo).subscribe(
            (base64data) => {
              // this is the image as dataUrl
              this.base64Image = 'data:image/jpg;base64,' + base64data;
            }
          ));
        }));
    } else {
      let localLogoURL = this.localService.getJsonValue('logoURL');
      if (localLogoURL) {
        this.tenantKeyExists = true;
        this.logoLoading = true;
        this.tenantLogo = localLogoURL;
        this.logoLoading = false;
      }
    }
  }


  // async migrateUserLogs() {
  //   console.log('Fetching Users..... ');
  //   let response: any = await this.platformUserService.fetchAllUsers().toPromise();
  //   this.allUsers = response;
  //   console.log('Users Fetched..... ', this.allUsers);
  //   console.log('Migrating..... ');
  //   for (let i = 0; i < this.allUsers.length; i++) {
  //     console.log('STARTED ' + (i+1));
  //     let user: PlatformUser = this.allUsers[i];
  //     let userLog: any = await this.platformUserService.fetchUserLog(user.key).toPromise();
  //     console.log(userLog);
  //     await this.crashReportingService.updateCoreUserLog({ logCode: 1, DateCreated: userLog.LastActivity, email: user.email }).toPromise();
  //     console.log('UPDATED ' + (i + 1) + '/' + (this.allUsers.length - 1));
  //   }

  // }


  products = [products.Engagement, products.Happiness, products.Assessment, products.Exit, products.Settings, products.Overview];

  // async migrateData() {
  //   let tenantResponse = await this.tenantService.fetchAllTenants().toPromise();    
  //   let tenants: Tenant[] = [];
  //   for(let i = 0; i < tenantResponse.length; i++){
  //     let tenant = tenantResponse[i];
  //     tenant.Entities = await this.entityService.fetchEntitiesOnce(tenant.key).toPromise();      
  //     tenant.Entities = tenant.Entities.filter(e => e.isCompany == true);
  //     tenants.push(tenant);
  //   }
  //   console.log(tenants);
  //   let allUsers = await this.platformUserService.fetchAllUsers().toPromise();
  //   for(let i = 0; i < allUsers.length; i++){
  //     let user = allUsers[i];
  //     let tenant = tenants.find(t => t.key == user.tenantKey);
  //     if(user?.EntityReferences?.length > 0){
  //       user.EntityReferences = user.EntityReferences.filter(e => tenant?.Entities.find(ee => ee.key == e));
  //     }
  //     user.CompanyPermissions = [];
  //     for(let x = 0; x < user?.EntityReferences?.length; x++){
  //       let entityKey = user.EntityReferences[x];
  //       let companyPermission = new CompanyPermission;
  //       companyPermission.key = entityKey;
  //       companyPermission.products = this.products;
  //       user.CompanyPermissions.push(companyPermission);
  //     }
  //   }

  //   for(let i = 0; i < allUsers.length; i++){
  //     let user = allUsers[i];
  //     // await this.platformUserService.updateUserPermissions(user).toPromise();      
  //     this.platformUserService.updatePlatformUser(user).then(() => {});
  //   }
  //   console.log('DONE TRANSFERRING ==========');
  // }



  selectedLanguageChange(event) {
    this.language = event;
    this.localService.setJsonValue('language', this.language);
    this.isArabic();
    this.languageService.language = this.language;
    // this.isArabic();
    // this.selectedLanguageChanged.emit(this.language);
    // this.isArabic();
  }

  forgotPassword() {
    this.section = 'forgot';
  }
  multiTimer;
  sendResetPasword() {
    if (this.email) {
      this.section = 'sent';
      this.authenticationService.sendResetPassword(this.email).then((_) => {
        this.zenSnackBarService.snackBar({
          message:
            this.translationPipe.transform("txtCheckEmails", this.language, this.ModuleNames.Common),
          type: ZenSnackBarTypes.Success
        }
        );
        this.crashReportingService.submitLogCode(13);
        // this.multiTimer = setTimeout(() => {
        //   this.section = 'login';
        // }, 1000);
      });
    } else {
      this.zenSnackBarService.snackBar({
        message:
          this.translationPipe.transform("txtEnterEmail", this.language, this.ModuleNames.Common),
        type: ZenSnackBarTypes.Error
      }
      );
    }
  }

  isArabic() {
    if (this.languagePipe.transform(this.language)) {
      $('body').removeClass('ltr');
      $('body').addClass('rtl').attr('dir', 'rtl');
    } else {
      $('body').removeClass('rtl');
      $('body').addClass('ltr').attr('dir', 'ltr');
    }
  }
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var c = canvas.getContext('2d');
    // This will draw image
    c.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  signIn(event?) {
    // Assert credentials provided
    this.email = this.email.replace(/\s/g, '');
    this.email = this.email.toLocaleLowerCase();
    if (this.email && this.password) {
      // this.showSpinner = true;
      this.authenticationService
        .signInUser(this.email, this.password)
        .then((_) => {
          // this.showSpinner = false;
          if (this.authenticationService.isSignedIn) {
            this.fetchPlatformUser(this.authenticationService.platformUserKey);
          } else
            this.zenSnackBarService.snackBar({
              message:
                this.translationPipe.transform("txtWrongInfo", this.language, this.ModuleNames.Common),
              type: ZenSnackBarTypes.Error
            }
            );
        });
    }
    //Else show error message, need to provide credentials
    else {
      this.zenSnackBarService.snackBar({
        message:
          this.translationPipe.transform("txtEmailPssword", this.language, this.ModuleNames.Common),
        type: ZenSnackBarTypes.Error
      }
      );
    }
  }

  fetchPlatformUser(platformUserKey: string) {
    let returnUrl = this.returnUrl;
    this.subscriptions.add(this.platformUserService
      .fetchPlatformUser(platformUserKey)
      .subscribe((user: PlatformUser) => {
        if (user && user.isActive) {
          this.logService.print(user);
          this.authenticationService.setTimoutOnSignIn();
          this.localService.setJsonValue('UserKey', user.key);
          this.localService.setJsonValue('UserEmail', user.email);
          if (returnUrl == '/home')
            this.localService.setJsonValue('FIRSTROUTE', 'TRUE');
          this.crashReportingService.updateLastLogginInInformation(user.key);
          this.router.navigateByUrl(returnUrl);
        } else {
          this.router.navigate(['error']);
        }
      }));
  }
  closeDropdown(menu) {
    if (this[menu]) this[menu] = false;
  }

  canvasStart() {
    var canvas = <HTMLCanvasElement>document.getElementById('c');
    var ctx = canvas.getContext('2d');
    let colors = ['#fbfbfb', '#f7f7f8', '#f4f4f4', '#f0f0f1', '#ededee', '#e9e9ea']
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
    let speed = 1;

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let color = 0; color < colors.length; color++) {
        ctx.beginPath();
        ctx.moveTo(-200, (canvas.height / 10 + Math.sin(speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.bezierCurveTo(
          canvas.width / 2,
          (canvas.height / 4 + Math.cos(speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width / 4,
          (canvas.height / 4 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width + 200,
          (canvas.height / 3 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.lineWidth = 130 + color * 100 / 2;
        ctx.strokeStyle = colors[color];
        ctx.stroke();
      }
      speed += .5;
    }

    animate();
  }
}
