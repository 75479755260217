<div class="print-preview">
  <left-preview>
    <kendo-pdf-export #pdf>
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <div id="exit-report">
          <div class="rp-header">
            <img [src]="logoURL" alt="Client Logo" />
            <div class="rp-names">
              <h6 class="rp-created-by">{{'txtCreatedBy'|translate:language:ModuleNames.Shared}} <span>{{ currentUser.firstName }} {{ currentUser.lastName
                  }}</span></h6>
            </div>
          </div>

          <div class="rp-footer">
            <div class="rp-date">
              <p>
                <span><i class="fas fa-clock"></i>{{'txtGeneratedon'|translate:language:ModuleNames.Shared}} </span>
                {{ getDateTime() }}
              </p>
            </div>
            <div class="rp-copyrights">
              <img [src]="smallLogoUrl" alt="Zenithr" />
              <p>{{'txtPoweredby'|translate:language:ModuleNames.Shared}} <strong>ZENITHR</strong></p>
            </div>
          </div>
        </div>
      </ng-template>

      <div id="chartCanvas" class="rp-body"></div>

    </kendo-pdf-export>
  </left-preview>

  <right-preview [title]="'txtExportReport'|translate:language:ModuleNames.Shared" [language]="language" [displayExportButton]="true" (export)="export()">
  </right-preview>
  <loader [language]="language" *ngIf="displaySpinner"></loader>

</div>
