import { Injectable } from "@angular/core";
import { IKeyResolver } from "src/app/core/services/key-resolver.service";
import { TranslatePipe } from "src/app/shared/pipes/translate.pipe";
import { AnalysisFilterKeys } from "../data-access/services/analytics.service";

@Injectable({
    providedIn: null,
})
export class SeperationTypeResolver implements IKeyResolver {
    name: string = AnalysisFilterKeys.SeperationType;

    constructor(private translatePipe: TranslatePipe) { }

    resolve(key: string, language: string, options?: any): string {
        return this.translatePipe.transform(key, language, options);
    }
}
