<div class="flex flex-row w-full items-center gap-2 mb-4">
    <h2 class="text-lg font-normal text-zen-primarydefault">
        {{"txtDemographicAnalysis"| translate:language:ModuleNames.SurveyX}}
    </h2>
</div>


<div class="container mx-auto">
    <div class=" grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">

        <ng-container *ngFor="let chart of charts">
            <sx-respondent-chart [language]="language" [chart]="chart" [data]="chart.data$ | async"
                [surveyCriterias]="surveyCriterias"></sx-respondent-chart>
        </ng-container>

        <ng-container *ngIf="charts.length == 0">
          <div class="flex py-6 px-4 justify-center items-center gap-2 self-stretch custom-st">

            <div class="flex h-8 justify-center items-center">
              <i class="text-zen-neutral text-center txt-32 font-light fa-regular fa-info-circle"></i>
            </div>

            <div class="flex flex-col justify-center items-center gap-1">
              <h5 class="self-stretch text-zen-primarylight text-center text-sm font-normal"> {{"txtDemographic analysis is not available for this Form." | translate:language:ModuleNames.SurveyX}} </h5>
            </div>

          </div>
        </ng-container>

        <sx-respondents-table></sx-respondents-table>
    </div>
</div>
