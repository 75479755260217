<z-modal [open]="surveyModel" autoWidth="true" *ngIf="surveyModel">
  <ng-container header>
    <div class="flex flex-row items-start gap-2 justify-start pb-3">
      <i class="far fa-file-export text-exp text-lg"></i>
      <h4 class="text-zen text-2xl">{{ 'btnExport' | translate:language:ModuleNames.SurveyX }}
      </h4>
    </div>
  </ng-container>
  <ng-container body>
    <div class="flex flex-row gap-5 -mb-10 -mt-10 max-h-screen-90 h-screen overflow-hidden max-w-7xl">
      <div class="flex flex-col items-stretch">
        <div class="max-h-screen-90 block bg-white innerListScroll w-export portrait" id="pdf">

          <!-- First Page -->
          <div class="innerPage  relative">

            <div class="bg-white absolute -top-10 py-10 left-0 w-max z-20 px-10 w-export">

              <div class="flex flex-col items-start gap-4 ">
                <div class="flex flex-row w-full justify-between pt-10">
                  <h4 class="text-zen-primarylight font-semibold text-sm opacity-50 uppercase">
                      {{"txtCONFIDENTIAL"| translate:language:ModuleNames.SurveyX}}
                  </h4>

                  <img class="w-32  object-contain" [src]="survey.logo" alt="">
              </div>
                <div class="flex pt-32" [ngClass]="(language|language)?'flex-col-reverse':'flex-col'">
                  <h1 class="text-zen-primarydefault text-5xl	font-semibold uppercase">
                    {{survey.title | sjsTranslate:language}}
                  </h1>

                  <h1 class="text-zen-primarydefault text-5xl  uppercase">
                    {{"txtPreview"| translate:language:ModuleNames.SurveyX}}
                  </h1>
                </div>

                <p class="text-zen-primarylight text-sm">
                  {{survey.description | sjsTranslate:language}}
                </p>
              </div>

              <div class="pt-16">
                <h4 class="text-zen-primarylight text-lg">{{"txtFor"| translate:language:ModuleNames.SurveyX}}:</h4>

                <h4 class="text-zen-primarydefault font-semibold text-2xl">
                  {{essentialObject?.selectedEntity?.name | extract:language}}
                </h4>

                <h4 class="text-zen-primarydefault text-lg">
                  {{date | date: 'dd/MM/yyyy'}}
                </h4>
              </div>

            </div>
          </div>

          <!-- First Page -->

          <ng-container *ngFor="let section of surveyModel.pages; let s = index">
            <div *ngIf="section?.elementsValue.length" class="innerPage break-before px-10 pt-20">
              <div class="flex flex-row text-zen-primarydefault pb-10">
                <div class="text-lg-custom font-normal tracking-tight"> {{"txtSection" |
                  translate:language:ModuleNames.SurveyX}} {{s+1}} : <span
                    class="font-semibold">{{section.jsonObj['title']| sjsTranslate:language}}</span>
                </div>
              </div>
              <div *ngFor="let question of section.elementsValue ; let q = index;" [ngClass]="q?'py-20 ':'pt-0'"
                class="block avoidBreak">
                <div class="inline-block text-sm-custom text-zen-primarydefault font-medium gap-2 pb-5"
                  (click)="logData(question)">
                  {{"Q" | translate:language:ModuleNames.SurveyX}}{{getPreviousSectionCount(s, q)}}
                  {{question.jsonObj['title'] | sjsTranslate : language}}
                </div>

                <!-- -----------------------------------------Choices-------------------------------------------------------------------------------------------------- -->


                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------DROP DOWN (SINGLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- <div *ngIf="question.jsonObj.type === 'dropdown'">
                  <mat-radio-group aria-label="Select an option" class="custom-radio-group">
                    <mat-radio-button *ngFor="let c of question.choices"
                     [value]="c.key">
                      {{c.text | sjsTranslate:language}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div> -->
                <ng-container *ngIf="question.jsonObj.type == 'dropdown'">
                  <div class="inline-block rank-item" [ngClass]="{'break-before mt-20':n % 15 == 0 && n}"
                    *ngFor="let c of question.choices; let n = index">
                    <i class="fa-regular fa-circle"></i>
                    {{c.text | sjsTranslate:language}}
                  </div>
                </ng-container>

                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------DROP DOWN (MULTIPLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <ng-container *ngIf="question.jsonObj.type == 'tagbox'">
                  <div class="inline-block rank-item" [ngClass]="{'break-before mt-20':n % 15 == 0 && n}"
                    *ngFor="let c of question.choices; let n = index">
                    <i class="fa-regular fa-square"></i>
                    {{c.text | sjsTranslate:language}}
                  </div>
                </ng-container>


                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------MULTIPLE CHOICE (MULTIPLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <ng-container *ngIf="question.jsonObj.type == 'checkbox'">
                  <div class="inline-block rank-item" [ngClass]="{'break-before mt-20':n % 15 == 0 && n}"
                    *ngFor="let c of question.choices; let n = index">
                    <i class="fa-regular fa-square"></i>
                    {{c.text | sjsTranslate:language}}
                  </div>
                </ng-container>

                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------MULTIPLE CHOICE (SINGLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <ng-container *ngIf="question.jsonObj.type == 'radiogroup'">
                  <div class="inline-block rank-item" [ngClass]="{'break-before mt-20':n % 15 == 0 && n}"
                    *ngFor="let c of question.choices; let n = index">
                    <i [ngClass]="question?.allowMultipleSelection?'fa-square':'fa-circle'" class="fa-regular "></i>
                    {{c.text | sjsTranslate:language}}
                  </div>
                </ng-container>


                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------UNKNOWN------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <div *ngIf="question.jsonObj.type == 'Single Selection (2 in a row)'">
                  <mat-radio-group aria-label="Select an option" class="custom-radio-group flex space-x-4">
                    <mat-radio-button *ngFor="let c of question.choices"
                      [checked]="isChoiceDisabled(question.key, c.key)" [disabled]="true" [value]="c.key"
                      class="flex-1 flex-wrap"><span class="whitespace-pre-line">{{c.text | sjsTranslate:language}}</span></mat-radio-button>
                  </mat-radio-group>
                </div>


                <ng-container *ngIf="question.jsonObj.type == 'datepicker'">
                  <z-datepicker [language]="language" [disabled]="true" [ngModel]="question.value"></z-datepicker>
                </ng-container>

                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------TEXT INPUT------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <div *ngIf="question.jsonObj.type == 'text'">
                  <ng-container *ngIf="question.dateType != 'range'">
                    <div class="w-full flex flex-row">
                      <input type="text" [ngModel]="question.value"
                        [placeholder]="question?.jsonObj?.placeholder | sjsTranslate:language"
                        class="text-zen-primarydefault w-full rounded-lg px-4 py-2" [disabled]="true">
                    </div>
                  </ng-container>



                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------DATE RANGE------------------------------------------------------------------------------------------------------------ -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <ng-container *ngIf="question.dateType == 'range'">
                    <div class="flex space-x-4">

                      <div class="w-full flex flex-row">
                        <div class="relative">
                          <i
                            class="fa-regular fa-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-zen-primarydefault text-base"></i>
                        </div>
                        <input type="text" class="text-zen-primarydefault w-full rounded-lg pl-10 py-2 1"
                          [value]="getDate(question.key,'index1')" [disabled]="true">
                      </div>

                      <div class="w-full flex flex-row">
                        <div class="relative">
                          <i
                            class="fa-regular fa-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-zen-primarydefault text-base"></i>
                        </div>
                        <input type="text" class="text-zen-primarydefault w-full rounded-lg pl-10 py-2 1"
                          [value]="getDate(question.key,'index2')" [disabled]="true">
                      </div>
                    </div>

                  </ng-container>



                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------UNKNOWN------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <ng-container *ngIf="question.name == 'single line input Date - time'">
                    <div class="w-full flex flex-row">
                      <div class="relative">
                        <i
                          class="fa-regular fa-calendar absolute top-1/2 left-3 transform -translate-y-1/2 text-zen-primarydefault text-base"></i>
                      </div>
                      <input type="text" [ngModel]="question.value"
                        class="text-zen-primarydefault w-full rounded-lg pl-10 py-2" [disabled]="true">
                    </div>
                  </ng-container>



                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------PHONE NUMBER------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <ng-container *ngIf="question.name == 'Phone number'">
                    <div class="w-full flex flex-row">
                      <input type="text" [ngModel]="question.value"
                        class="text-zen-primarydefault w-full rounded-lg px-4 py-2 text-sm" [disabled]="true">
                    </div>
                  </ng-container>
                </div>


                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------RANKING------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <ng-container *ngIf="question.jsonObj.type == 'ranking'">

                  <div class="inline-block rank-item" [ngClass]="{'break-before mt-20':i % 15 == 0 && i}"
                    *ngFor="let c of question.choices; let i = index">
                    <span
                      class="whl26 inline-block bg-white font-medium text-zen-primarylight text-sm text-center rounded-full mr-2 align-middle">
                      {{i+1}}
                    </span>
                    <sv-ng-string-editor class="whitespace-pre-line" [model]="c.locTitle"></sv-ng-string-editor>
                  </div>
                </ng-container>




                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------RATING------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <div *ngIf="question.jsonObj.type == 'rating'">
                  <div class="w-full flex flex-row">
                    <div class="flex space-x-4 w-full">


                      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                      <!-- ----------------------------------------STARS RATING------------------------------------------------------------------------------------------------------------- -->
                      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                      <ng-container
                        *ngIf="question.rateType === 'stars' || question.rateType === 'fa-heart' || question.rateType === 'fa-thumbs-up' || question.rateType === 'smileys'">
                        <div class="flex flex-col w-full rounded padding-custom gap-2 self-stretch items-center ">
                          <div class="py-2 gap-2 px-4 flex flex-wrap flex-row justify-center items-center">
                            <div
                              *ngFor="let rateLabel of (question.jsonObj.ratingLabels || returnRates(question)); let i = index"
                              class="flex flex-col items-center gap-1">
                              <div class="py-2 gap-2 flex flex-row">
                                <i *ngFor="let rate of returnRateLabels(rateLabel, question); let i = index"
                                  [style.color]="rateLabel?.color"
                                  [ngClass]="{'fa-star':question.rateType === 'stars','fa-heart':question.rateType === 'fa-heart','fa-thumbs-up':question.rateType === 'fa-thumbs-up','fa-face-smile ':question.rateType === 'smileys'}"
                                  class="fa-light text-5xl text-zen-neutral"></i>
                              </div>
                              <h5 *ngIf="rateLabel?.text " class="label-gray whitespace-normal text-sm">
                                {{rateLabel.text |sjsTranslate:language}}</h5>
                            </div>
                          </div>
                        </div>
                      </ng-container>


                      <!-- <ng-container *ngIf="question.rateType === 'fa-heart'">


                        <div class="flex flex-col w-full rounded padding-custom gap-2 self-stretch items-center ">
                          <div class="py-2 gap-2 px-4 flex flex-row items-center">
                            <div *ngFor="let rateLabel of question.jsonObj.ratingLabels; let i = index"
                              class="flex flex-col items-center gap-1">
                              <div class="py-2 gap-2 flex flex-row">
                                <i *ngFor="let rate of returnRateLabels(rateLabel, question); let i = index" [style.color]="rateLabel.color"
                                     class="fa-light fa-heart text-4xl text-zen-neutral" ></i>
                              </div>
                              <h5 class="label-gray whitespace-normal text-sm" > {{rateLabel.text |sjsTranslate:language}}</h5>
                            </div>
                          </div>
                        </div>

                      </ng-container>

                      <ng-container *ngIf="question.rateType === 'fa-thumbs-up'">
                        <div
                          class="rate_box flex flex-row w-full rounded-md px-4 h-14 justify-center items-center gap-2">
                          <div *ngFor="let rateValue of question.rateValues; let i = index"
                            class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                            [ngClass]="setActiveRate(rateValue, question.key, question)">

                            <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                              [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-thumbs-up text-3xl text-exp' :
           'fa-light fa-thumbs-up text-3xl text-zen-neutral'"></i>
                          </div>

                          <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                            <span
                              class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                          </div>
                        </div>
                      </ng-container>




                      <ng-container *ngIf="question.rateType === 'smileys'">
                        <div
                          class="rate_box flex flex-row w-full rounded-md px-4 h-14 justify-center items-center gap-2">
                          <div *ngFor="let rateValue of question.rateValues; let i = index"
                            class="flex flex-row rounded-md  items-center font-medium text-xs bg-white"
                            [ngClass]="setActiveRate(rateValue, question.key, question)">

                            <i [ngStyle]="{'color': isNumber(rateValue.value) <= isNumber(activeRating)  ? getColor(activeRating, question):'#AEBBD1'}"
                              [ngClass]="isNumber(rateValue.value) <= isNumber(activeRating)  ? 'fa-solid fa-face-smile text-3xl text-exp' :
                     'fa-light fa-face-smile text-3xl text-zen-neutral'"></i>
                          </div>

                          <div *ngIf="question.ratingLabels" class="flex flex-row items-center pl-4">
                            <span
                              class="text-zen-primarydefault font-normal text-xs">{{this.getTranslatedTitle(label)}}</span>
                          </div>
                        </div>
                      </ng-container> -->


                      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                      <!-- ----------------------------------------NPS AND ENPS RATING------------------------------------------------------------------------------------------------------------- -->
                      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->

                      <ng-container *ngIf="question.rateType == 'enps' || question.rateType == 'nps'">
                        <div class="rate_box enps_clr_scale flex flex-col w-full rounded-md px-2 py-2 items-center">
                          <div class="flex flex-row w-full">
                            <div class="gap-2  grid-cols-11 flex flex-row max-w-[600px] m-auto all_colrs">
                              <div class="flex flex-col items-center"
                                *ngFor="let groupedValues of groupedSection() as values; let s = index">
                                <div class="flex flex-row gap-2">
                                  <div
                                    [style.backgroundColor]="groupedValues.color + '22'"
                                    *ngFor="let rateValue of groupedValues.rates; let i = index"
                                    class="flex flex-1 flex-row flex-wrap rounded-md justify-center text-zen-primarydefault items-center px-2 py-2 font-medium text-xs ratinglabel bg-zen-littlegray w-12 h-12">
                                    {{returnPreviousIndex(s, values) + i + 1 }}
                                  </div>
                                </div>
                                <span
                                  class="text-zen-primarydefault font-normal text-xs text-center">{{groupedValues.name
                                  | translate:language:ModuleNames.SurveyX}}</span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </ng-container>


                      <!-- <ng-container *ngIf="question.rateType == 'enps' || question.rateType == 'nps'">
                        <div class="rate_box enps_clr_scale flex flex-col w-full rounded-md px-2 py-2 items-center">
                          <div class="flex flex-row w-full">
                            <div class="gap-2  grid-cols-11 flex flex-row max-w-[600px] m-auto all_colrs">

                              <div *ngFor="let rateValue of returnRates(question); let i = index" class="flex flex-1 flex-row flex-wrap rounded-md justify-center items-center px-2 py-2 text-zen-primarydefault
                                font-medium text-xs ratinglabel bg-zen-littlegray w-12 h-12">
                                {{ i }}
                              </div>
                            </div>
                          </div>

                          <div class="flex flex-row items-center space-x-4 max-w-[560px] m-auto mt-2 w-full">
                            <span
                              class="text-zen-primarydefault font-normal text-xs flex-initial pl-9 w-[300px] flex justify-center">{{"Unlikely"
                              | translate:language:ModuleNames.SurveyX}}</span>
                            <span
                              class="text-zen-primarydefault font-normal text-xs flex-1 flex pl-6 justify-center">{{"Neutral"
                              | translate:language:ModuleNames.SurveyX}}</span>
                            <span
                              class="text-zen-primarydefault font-normal text-xs flex-1 flex pr-[5px] justify-center">{{"Likely"
                              | translate:language:ModuleNames.SurveyX}}</span>
                          </div>
                        </div>
                      </ng-container> -->


                      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                      <!-- ----------------------------------------RATING SCALE WITH LABELS AND COLORS------------------------------------------------------------------------------------------------------------- -->
                      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                      <ng-container
                        *ngIf="question.jsonObj.type == 'rating' && question.rateType  == 'labels' && !question.jsonObj?.ratingLabels?.length ">
                        <div class="w-full flex justify-center padding-custom rounded gap-1">
                          <div class="py-2 gap-2 px-4 flex">
                            <div *ngFor="let rateValue of returnRates(question); let i = index" class="flex flex-1 flex-row flex-wrap rounded-md justify-center items-center px-2 py-2 text-zen-primarydefault
                            font-medium text-xs ratinglabel bg-zen-littlegray w-12 h-12">
                              {{rateValue}}
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                      <!-- ----------------------------------------RATING SCALE WITHOUT LABELS OR COLORS------------------------------------------------------------------------------------------------------------- -->
                      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                      <ng-container
                        *ngIf="question.jsonObj.type == 'rating' && question.rateType  == 'labels' && question.jsonObj?.ratingLabels?.length ">
                        <div class="flex flex-col w-full rounded padding-custom gap-2 self-stretch items-center ">
                          <div class="py-2 gap-1 px-4 flex flex-row items-center">
                            <!-- <div class="w-full flex justify-center padding-custom rounded gap-1">
                              <div class="py-2 gap-2 px-4 flex">
                                <div *ngFor="let rateValue of returnRateLabels(question); let i = index" class="flex flex-1 flex-row flex-wrap rounded-md justify-center items-center px-2 py-2 text-zen-primarydefault
                                font-medium text-xs ratinglabel bg-zen-littlegray w-12 h-12">
                                {{question['rateMin'] +( question['rateStep'] * i) }}
                              </div>
                              </div>
                            </div> -->
                            <div *ngFor="let rateLabel of question.jsonObj.ratingLabels; let i = index"
                              class="flex flex-col items-center gap-1">
                              <div class="py-2 gap-1 flex flex-row">
                                <div [style.background]="rateLabel.color"
                                  *ngFor="let rate of returnRateLabels(rateLabel, question); let i = index" class="flex flex-1 flex-row flex-wrap rounded-md justify-center items-center px-2 py-2 text-zen-primarydefault
                                font-medium text-xs ratinglabel  w-12 h-12">
                                  {{rate}}
                                </div>
                              </div>
                              <h5 class="label-gray whitespace-normal"> {{rateLabel.text | sjsTranslate:language}}</h5>
                            </div>

                          </div>
                        </div>
                      </ng-container>

                    </div>
                  </div>
                </div>


                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------BOOLEAN------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <ng-container *ngIf="question.jsonObj.type == 'boolean'">
                  <div class="flex flex-row justify-center items-center gap-10 w-full">
                    <div
                      class="gap-1 items-center flex flex-row p-3 border border-zen-lite rounded w-60 justify-center">
                      <i class="fa-regular fa-thumbs-up"></i>
                      {{question.jsonObj.labelTrue?(question.jsonObj.labelTrue | sjsTranslate:language):('txtYes'| translate:language:ModuleNames.SurveyX)}}
                    </div>
                    <div
                      class="gap-1 items-center flex flex-row p-3 border border-zen-lite rounded w-60 justify-center">
                      <i class="fa-regular fa-thumbs-down"></i>
                      {{question.jsonObj.labelFalse?(question.jsonObj.labelFalse | sjsTranslate:language):('txtNo'| translate:language:ModuleNames.SurveyX)}}
                    </div>
                  </div>

                </ng-container>



                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------FILE UPLOAD------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <div *ngIf="question.jsonObj.type === 'file'">
                  <div class="w-full flex flex-row">
                    <div class="rate_box flex flex-row w-full rounded-md px-2 py-2 items-center">
                      <div class="flex-1">
                        <i class="fa-regular fa-image text-base mr-2 ml-2"></i>
                        <sv-ng-string-editor class="whitespace-pre-line"
                          [model]="question?.locTitle"></sv-ng-string-editor>
                      </div>

                      <div class="flex-auto justify-end flex pr-2">
                        <button (click)="downloadImage(getFileDetails(question.key)?.content, 'image.png')"><i
                            class="fa-regular fa-download text-[#7D92B5]"></i></button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------MULTIPLE TEXT------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <div *ngIf="question.jsonObj.type === 'multipletext'">
                  <div class="w-full flex flex-row space-x-4">
                    <input type="text" *ngFor="let c of question.items" name="{{c.name}}"
                      class="text-zen-primarydefault w-full rounded-lg h-10 px-4 py-2 text-sm"
                      [value]="getValue(question.key, c.name)" [disabled]="true">
                  </div>
                </div>


                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------LONG TEXT------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <div *ngIf="question.jsonObj.type === 'comment'">
                  <div class="w-full flex flex-row">
                    <textarea [ngModel]="question.value"
                      [placeholder]="question?.jsonObj?.placeholder | sjsTranslate:language"
                      class="text-zen-primarydefault w-full rounded-lg h-24 px-4 py-2.5 text-sm"
                      [disabled]="true"></textarea>
                  </div>
                </div>


                <!-- -----------------------  ------------------------------------------------------------------------------------------------------------------------------ -->
                <!-- ----------------------------------------IMAGE PICKER (ICON CHOICE)------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <ng-container *ngIf="question.jsonObj.type === 'imagepicker'">
                  <ng-container *ngIf="question.jsonObj.isIconChoice">
                    <div class="w-full flex flex-row">
                      <div class="flex flex-row flex-wrap -mr-1 -ml-1">
                        <div *ngFor="let choice of question.choices; let i = index"
                          class="flex flex-col px-1 items-center gap-2 mb-5">
                          <div
                            class="flex flex-col items-center py-4 rounded-md bg-white emj-box-inside border w-full minw100px">
                            <i class="fa-light fa-{{choice.imageLink}} text-3xl"></i>
                          </div>
                          {{ (choice.text| sjsTranslate:language)?? choice.value }}
                        </div>
                      </div>
                    </div>
                  </ng-container>


                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------IMAGE PICKER(IMAGE CHOICE)------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->

                  <ng-container *ngIf="!question.jsonObj.isIconChoice && !question.multiSelect">
                    <div class="block relative">
                      <ng-container *ngFor="let choice of question.choices; let i = index">
                        <span class="w-full h-20 block break-before" *ngIf="i % 8 == 0 && i"></span>
                        <div class="inline-block w-1/2 text-zen-primarydefault font-medium text-xs bg-white mb-2"
                          [ngClass]="question.value == choice.value ? 'active-ImgSel' : ''">

                          <div
                            class="flex flex-row w-full items-center gap-2 text-sm text-zen-primarylight font-normal innerT overflow-hidden">
                            <img [src]="choice.imageLink"
                              class="rounded-md p-[0.1rem] max-h-52 h-screen maxw16 w-screen  object-contain">
                            <i
                              [class]="question.value == choice.value ? 'fa-regular fa-circle-dot text-2xl mr-2 text-zen-paleWhite' : 'fa-light fa-circle text-2xl mr-2 text-zen-paleWhite'"></i>
                            {{ (choice.text| sjsTranslate:language)?? choice.value }}
                          </div>
                        </div>
                      </ng-container>

                    </div>
                  </ng-container>

                  <ng-container *ngIf="!question.jsonObj.isIconChoice && question.multiSelect">
                    <div class="block relative">
                      <ng-container *ngFor="let choice of question.choices; let i = index">
                        <span class="w-full h-20 block break-before" *ngIf="i % 8 == 0 && i"></span>
                        <div *ngFor="let choice of question.choices; let i = index"
                          class="inline-block w-1/2 text-zen-primarydefault font-medium text-xs bg-white mb-2"
                          [ngClass]="question.value == choice.value ? 'active-ImgSel' : ''">

                          <div class="flex items-center gap-2 text-sm text-zen-primarylight font-normal innerT">
                            <img [src]="choice.imageLink" class="rounded-md p-[0.1rem] max-h-52 h-52 object-cover">
                            <i
                              [class]="question.value == choice.value ? 'fa-solid fa-square-check text-2xl mr-2 text-zen-paleWhite' : 'fa-light fa-square text-2xl mr-2 text-zen-paleWhite'"></i>
                            {{ (choice.text| sjsTranslate:language)?? choice.value }}
                          </div>
                        </div>
                      </ng-container>

                    </div>
                  </ng-container>

                </ng-container>


                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------MATRIX (SINGLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <div *ngIf="question.jsonObj.type == 'matrix' && !question.isMultiSelect">
                  <div class="w-full flex flex-row">
                    <table class="bordered w-full table-matrix">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4 text-center"
                            *ngFor="let column of question.columns">
                            {{ column.text | sjsTranslate:language }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of question.rows">
                          <td class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4">{{
                            row.text | sjsTranslate:language}}
                          </td>
                          <td class="text-center px-2 py-4" *ngFor="let column of question.columns">
                            <i
                              [ngClass]="question.value == row.value? 'fa-regular fa-circle-dot' : 'fa-regular fa-circle'"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------MATRIX (MULTIPLE SELECTION)------------------------------------------------------------------------------------------------------------- -->
                <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <div *ngIf="question.jsonObj.type == 'matrix' && question.isMultiSelect">
                  <div class="w-full flex flex-row">
                    <table class="bordered w-full table-matrix">
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4 text-center"
                            *ngFor="let column of question.columns">
                            {{ column.text | sjsTranslate:language }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of question.rows">
                          <td class="uppercase text-zen-primarydefault font-medium text-xs px-2 py-4">{{
                            row.text | sjsTranslate:language}}
                          </td>
                          <td class="text-center px-2 py-4" *ngFor="let column of question.columns">
                            <i class="fa-regular fa-square" [ngClass]="column.value == row.value"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>




                <!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
              </div>
            </div>
          </ng-container>

          <div class="header w-full px-10 flex flex-row items-start justify-between">
            <h5 class="text-3xs text-zen text-opacity-60">{{survey.title | sjsTranslate:language}}</h5>
            <h5 class="text-3xs text-zen "><span class="text-opacity-60">{{
                "txtPreparedFor"|translate:language:ModuleNames.SurveyX }}
              </span> <span class="text-zen font-medium">{{companyName}}</span> </h5>
          </div>
          <div class="footer w-full px-10 flex flex-row items-end justify-between">
            <div class="flex flex-col gap-1">
              <p class="text-xxs text-zen text-opacity-50">{{ "txtPoweredby" |
                translate:language:ModuleNames.SurveyX }}</p>
              <img class="h-8 w-auto object-cover" src="assets/img/logo.png" alt="">
            </div>
            <div class="flex flex-col items-end gap-1">
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtGenerated"|translate:language:ModuleNames.SurveyX }}: {{date | date:
                'dd/MM/yyyy'}}
              </h5>
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtAllRights"|translate:language:ModuleNames.SurveyX }} © {{date | date: 'yyyy'}}
                -
                ex.zenithr.com</h5>

            </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
  <ng-container footer>
    <a (click)="openChange.emit(false)" class="btn cancel leading-12 rounded text-sm text-center px-5">{{"btn_cancel"|
      translate:language:ModuleNames.SurveyX}}</a>

    <a (click)="exportDraft(language,true); closeModal()"
      class="btn exp leading-12 rounded text-sm text-center px-5">{{"btnExport"|
      translate:language:ModuleNames.SurveyX}}</a>
  </ng-container>
</z-modal>
