import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { AppInjector } from 'src/app/app.module';
import { GeneralService } from 'src/app/core/services/general.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ModuleName } from '../enums/ModuleName';
import { LanguagePipe } from '../pipes/language.pipe';
import { TranslatePipe } from '../pipes/translate.pipe';
import { ExtractPipe } from '../pipes/extract.pipe';

@Component({
  selector: 'app-native-export-base',
  templateUrl: './native-export-base.component.html',
  styleUrls: ['./native-export-base.component.scss'],
})
export class NativeExportBaseComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ModuleNames = ModuleName
  languagePipe = AppInjector.get(LanguagePipe);
  extractPipe = AppInjector.get(ExtractPipe);
  generalService = AppInjector.get(GeneralService);
  translationPipe = AppInjector.get(TranslatePipe);
  languageService = this.LanguageService;
  title: string;
  ngOnInit(): void {
  }

  dir = 'ltr';
  exportDraft(language, white?) {
    if (this.languagePipe.transform(language))
      this.dir = 'rtl';
    else this.dir = 'ltr';

    let newElement = document.getElementById('pdf');
    var mywindow = window.open('', ' ', 'left=0,top=0,width=950,height=600,toolbar=0,scrollbars=0,status=0,addressbar=0');
    const agent = window.navigator.userAgent.toLowerCase()
    mywindow.document.write(document.head.innerHTML);

    mywindow.document.write(`<body dir='${this.dir}' style='background-color:${white ? 'white' : 'transparent'}'>`);
    mywindow.document.write(newElement.innerHTML);

    mywindow.document.write('</body>');
    mywindow.document.close(); // necessary for IE >= 10 and necessary before onload for chrome
    if (this.title)
      mywindow.document.title = this.title;

    if (agent.indexOf('chrome') > -1) {
      mywindow.onload = function () { // wait until all resources loaded
        mywindow.focus(); // necessary for IE >= 10
        setTimeout(() => {
          mywindow.print();  // change window to mywindow
          mywindow.close();// change window to mywindow
        }, 2000);
      };

    }
    else {
      mywindow.document.close(); // necessary for IE >= 10
      setTimeout(() => {
        mywindow.focus(); // necessary for IE >= 10
        mywindow.print();
        mywindow.close();
      }, 2000);
    }
    return true;
  }

}
