import { Component, Input, OnInit } from '@angular/core';
import { CommentAnalysisService } from '@ex/survey-x/index';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { SxBaseComponent } from '../../sx-base/sx-base.component';
import { delay, switchMap } from 'rxjs/operators';
import { AnalyticsFilter, AnalyticsService } from '@ex/module/survey-x/data-access/services/analytics.service';
import { QuestionTypes } from '@ex/module/survey-x/utils/question-types';
import { SjsTranslatePipe } from '@ex/module/survey-x/utils/pipes/sjs-translate.pipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { AnswersParsingService } from '@ex/module/survey-x/data-access/services/answers-parsing-service';


@Component({
  selector: 'sx-question-comments',
  templateUrl: './question-comments.component.html',
  styleUrls: ['./question-comments.component.scss'],
  providers: [SjsTranslatePipe]
})
export class QuestionCommentsComponent extends SxBaseComponent implements OnInit {

  @Input()
  question;

  @Input()
  language = "EN";

  @Input()
  filters: AnalyticsFilter;

  @Input()
  questionNo: number = 0;

  maxResultCount = 2;

  showMore$ = new BehaviorSubject(0);

  totalCount = 0;

  comments = [];

  constructor(private commentAnalysisService: CommentAnalysisService
    , private analyticsService: AnalyticsService
    , private sjsTranslatePipe: SjsTranslatePipe
    , private sanitizer: DomSanitizer
    , private answersParsingService : AnswersParsingService) { super() }

  ngOnInit(): void {

    setTimeout(() => {

      const sub = combineLatest([this.showMore$]).pipe(
        switchMap(([page]) => {
          return this.commentAnalysisService.getListAsyc(
            {
              tenantKey: this.filters.tenantKey,
              companyKey: this.filters.companyKey,
              formId: this.filters.survey.key,
              skipCount: page,
              maxResultCount: this.maxResultCount,
              type: "",
              questionKey: this.question.name,
              isAllResults: false,
              filters: [...this.analyticsService.mapGlobalFilters(this.filters)]
            })
        })
      ).subscribe(response => {

        this.totalCount = response.totalCount;
        this.comments = this.comments.concat(response.items);
      })

      this.addSubscription(sub);

    }, 1000);
  }

  get isShowMore() {
    return this.comments.length < this.totalCount;
  }

  showMoreComments(): void {
    this.showMore$.next(this.showMore$.value + 1)
  }

  getAnswer(answer)
  {
    return this.answersParsingService.getAnswer(answer,this.question,this.language);
  }

  isEllipsisActive(e: HTMLElement): boolean {
    return e ? (e.offsetWidth < e.scrollWidth) : false;
  }

  downloadFile(file) {
    this.downloadBase64File(file.content.split(",")[1], file.name);
  }

  private downloadBase64File(base64String: string, fileName: string) {
    // Convert the base64 string to a Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    // Save the Blob to the file system using FileSaver.js
    saveAs(blob, fileName);
  }
}
