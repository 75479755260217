import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { LanguageService } from 'src/app/core/services/language.service';
import { ModuleName } from '../enums/ModuleName';

@Component({
  selector: 'translation-input',
  templateUrl: './translation-input.component.html',
  styleUrls: ['./translation-input.component.scss']
})
export class TranslationInputComponent implements OnInit {

  @Input('language') language: string;
  translationField;

  @Input('supportedLanguages') supportedLanguages;
  @Input('inputName') inputName: string;
  @Input('translationField')  set langField(transField:any){
    this.translationField = transField;
    this.txtInputName = this.inputName;
    for (let i = 0; i < this.supportedLanguages?.length; i++) {
      let supportedLanguage = this.supportedLanguages[i];
      if (!transField.find(nm => nm.key == supportedLanguage)) {
        transField.push({ key: supportedLanguage, name: ' ' });
      }
    }
    for (let i = 0; i < transField.length; i++) {
      let field = transField[i];
      if (field.key == this.language)
        this.translationInput = field;
    }
    if (!this.translationInput) {
      if (transField.length > 0)
        this.translationInput = transField[0];
    }
  }
  @Input('showTranslation') showTranslation: boolean = true;
  @Input('showHeading') showHeading: boolean = true;
  @Input('floatLabel') floatLabel: boolean = true;
  @Input('maxCount') maxCount: number;
  @Input('disabled') disabled: boolean = false;
  @Input('required') required: boolean = true;
  @Input('placeholder') placeholder: boolean = false;

  @Output() translationChange = new EventEmitter();

  translationInput;

  faGlobe = faGlobe;
  ModuleNames=ModuleName

  // Language Related Fields
  txtInputName: string;
  // txtTranslation: string;
  // txt_type_description: string;
  // txtDescription: string;
  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {
    this.txtInputName = this.inputName;
    for (let i = 0; i < this.supportedLanguages.length; i++) {
      let supportedLanguage = this.supportedLanguages[i];
      if (!this.translationField.find(nm => nm.key == supportedLanguage)) {
        this.translationField.push({ key: supportedLanguage, name: '' });
      }
    }
    for (let i = 0; i < this.translationField.length; i++) {
      let field = this.translationField[i];
      if (field.key == this.language)
        this.translationInput = field;
    }
    if (!this.translationInput) {
      if (this.translationField.length > 0)
        this.translationInput = this.translationField[0];
    }
  


    // INCASE
    // if(!this.translationInput){
    //   let field = {key: this.language, name: ''};
    //   this.translationField.push(field);
    //   this.translationInput = field;
    // }
    this.translationChange.emit();


    // this.setUpLanguagePreference();
  }


  // setUpLanguagePreference() {
  //   this.setUpTextFields();
  // }
  // txtFieldIsRequired
  // txt_validation_max
  // txt_validation_min
  

  // setUpTextFields() {
  //   // UI Fields Setup
  //   this.txtDescription = this.languageService.getUILanguage('txtDescription', this.language);
  //   this.txtTranslation = this.languageService.getUILanguage('txt_custom_input_translation', this.language);
  //   this.txt_type_description = this.languageService.getUILanguage('txt_type_description', this.language);
  //   this.txtFieldIsRequired = this.languageService.getUILanguage('txt_required_field', this.language);
  //   this.txt_validation_max = this.languageService.getUILanguage('txt_validation_max', this.language);
  //   this.txt_validation_min = this.languageService.getUILanguage('txt_validation_min', this.language);
  // }

  getLanguage(langISO: string) {

    return this.languageService.getLongLanguageName(langISO, this.language);
  }
  countWord(word, count) {
    if (word?.length <= count)
      return word.length;
    if (word?.length > count) {

      return word.length;
    }
  }

  translationChanged() {
    
    this.translationChange.emit();
  }
}
