import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { WellbeingRoutingModule } from './wellbeing-routing.module';
import { WellbeingComponent } from './wellbeing.component';
import { WbListComponent } from './features/list/list.component';
import { WbSetupComponent } from './features/setup/setup.component';
import { WbLiveSurveyComponent } from './features/live-survey/live-survey.component';
import { SharedModule } from '../shared/shared.module';
import { WbPeopleComponent } from './features/wb-people/wb-people.component';
import { ToNumberPipe } from './utils/to-number.pipe';
import { RenameSurveyComponent } from './ui/rename-survey/rename-survey.component';
import { WbSurveyPreviewComponent } from './ui/wb-survey-preview/wb-survey-preview.component';
import { WbReminderModalComponent } from './ui/wb-reminder-modal/wb-reminder-modal.component';
import { WbSurveyInfoComponent } from './ui/wb-survey-info/wb-survey-info.component';
import { ZenTableComponent } from './ui/zen-table/zen-table.component';
import { WbCriteriaComponent } from './ui/wb-criteria/wb-criteria.component';
import { WbRespondentsModalComponent } from './ui/wb-respondents-modal/wb-respondents-modal.component';
import { ZenNewInputComponent } from './ui/zen-new-input/zen-new-input.component';
import { WbRespondentPoolComponent } from './ui/wb-respondent-pool/wb-respondent-pool.component';
import { ResponseRateCardComponent } from './ui/response-rate-card/response-rate-card.component';
import { ShareSurveyComponent } from './ui/share-survey/share-survey.component';
import { ClosedSurveyComponent } from './features/closed-survey/closed-survey.component';
import { OverallAnalysisComponent } from './features/overall-analysis/overall-analysis.component';
import { RespondentsAnalysisComponent } from './features/respondents-analysis/respondents-analysis.component';
import { QuestionAnalysisComponent } from './features/question-analysis/question-analysis.component';
import { CommentAnalysisComponent } from './features/comment-analysis/comment-analysis.component';
import { AnalysisBaseComponentComponent } from './ui/closed-survey/analysis-base-component/analysis-base-component.component';
import { SurveyCriteriaKeyResolver } from './utils/survey-criteria-key-resolver';
import { WellbeingScaleKeyResolver } from './utils/wellbeing-scale-key-resolver';

import { CriteriaResolverService } from './data-access/services/criteria-resolver.service';
import { SurveyCriteriaService } from './data-access/services/survey-criteria.service';
import { WbAnalysisReportComponent } from './features/wb-analysis-report/wb-analysis-report.component';
import { HeatmapAnalysisComponent } from './ui/closed-survey/heatmap-analysis/heatmap-analysis.component';
import { HeatmapFilterPipe } from './ui/closed-survey/heatmap-analysis/heatmap-filter.pipe';
import { OverallDimensionAnalysisComponent } from './ui/closed-survey/overall-dimension-analysis/overall-dimension-analysis.component';
import { WellbeingByCriteriaComponent } from './ui/closed-survey/wellbeing-by-criteria/wellbeing-by-criteria.component';
import { WellbeingIndexByDimensionsComponent } from './ui/closed-survey/wellbeing-index-by-dimensions/wellbeing-index-by-dimensions.component';
import { WellbeingIndexByScalesComponent } from './ui/closed-survey/wellbeing-index-by-scales/wellbeing-index-by-scales.component';
import { WellbeingScalesComponent } from './ui/closed-survey/wellbeing-scales/wellbeing-scales.component';
import { CommentListComponent } from './ui/wb-comment-list/comment-list.component';
import { WbTreeOptionsComponent } from './ui/wb-tree-options/wb-tree-options.component';
import { WellbeingScalesService } from './utils/wellbeing-scales.service';
import { WellbeingComparisonComponent } from './features/wellbeing-comparison/wellbeing-comparison.component';
import { KEY_RESOLVER_TOKEN } from '../core/services/key-resolver.service';
import { ShowExtraFactorPipe } from './utils/show-extra-factor.pipe';
import { ComparisonExportComponent } from './features/wellbeing-comparison/comparison-export/comparison-export.component';
import { AiCommentModule } from '../shared/ai-comments/ai-comment.module';
import { AI_COMMENTS_SERVICE } from '../shared/ai-comments/components/main/main.component';
import { WellbeingAiCommentsService } from './data-access/services/wellbeing-ai-comments.service';
import { RespondentsTableComponent } from './ui/respondents-table/respondents-table.component';
import { ProductTourModule } from '../shared/product-tour/product-tour.module';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlLocalize } from '../core/services/CustomPaginatorConfiguration';
import { TranslatePipe } from '../shared/pipes/translate.pipe';

@NgModule({
  declarations: [
    WellbeingComponent,
    WbListComponent,
    WbSetupComponent,
    WbLiveSurveyComponent,
    WbPeopleComponent,
    ToNumberPipe,
    RenameSurveyComponent,
    WbSurveyPreviewComponent,
    WbReminderModalComponent,
    WbSurveyInfoComponent,
    ZenTableComponent,
    WbCriteriaComponent,
    WbRespondentsModalComponent,
    ZenNewInputComponent,
    WbRespondentPoolComponent,
    ResponseRateCardComponent,
    ShareSurveyComponent,
    ClosedSurveyComponent,
    OverallAnalysisComponent,
    RespondentsAnalysisComponent,
    QuestionAnalysisComponent,
    CommentAnalysisComponent,
    AnalysisBaseComponentComponent,
    WellbeingScalesComponent,
    OverallDimensionAnalysisComponent,
    WellbeingIndexByScalesComponent,
    WellbeingIndexByDimensionsComponent,
    WellbeingByCriteriaComponent,
    HeatmapAnalysisComponent,
    HeatmapFilterPipe,
    WbTreeOptionsComponent,
    WbAnalysisReportComponent,
    CommentListComponent,
    WellbeingComparisonComponent,
    ShowExtraFactorPipe,
    ComparisonExportComponent,
    RespondentsTableComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    WellbeingRoutingModule,
    AiCommentModule,
    ProductTourModule,
  ],
  providers: [
    WellbeingScalesService,
    SurveyCriteriaService,
    TranslatePipe, { provide: MatPaginatorIntl, useClass: MatPaginatorIntlLocalize },
    { provide: AI_COMMENTS_SERVICE, useClass: WellbeingAiCommentsService },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class WellbeingModule {
  static forRoot(): ModuleWithProviders<WellbeingModule> {
    return {
      ngModule: WellbeingModule,
      providers: [
        CriteriaResolverService,
        {
          provide: KEY_RESOLVER_TOKEN,
          useClass: SurveyCriteriaKeyResolver,
          multi: true,
        },
        {
          provide: KEY_RESOLVER_TOKEN,
          useClass: WellbeingScaleKeyResolver,
          multi: true,
        },
      ],
    };
  }
}
