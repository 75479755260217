<sx-drawer (openChange)="closeModal()" [(open)]="open">

    <div (click)="closeModal()" class=" btn flex py-3 px-2 justify-center items-center">
        <div class="flex items-center gap-5">
            <div class="flex w-13 h-13 justify-center items-center">
                <i class="w-10 shrink-0 text-white text-center t40 font-normal fa-regular fa-xmark"></i>
            </div>
        </div>
    </div>

    <ng-container header>

        <div class="flex w1066 items-end gap-2">

            <div class="flex flex-row h120 pb-6 px-10 items-end gap-4 flex-1 c-s">

                <div class="flex flex-col pb-0 items-start gap-2 flex-1">

                    <h5 class="text-exp text-xs font-medium tracking-[0.24px] uppercase">
                        {{ 'txtQuestion' | translate:data?.language:ModuleNames.SurveyX }} {{ data?.questionNo }}</h5>
                    <h5 class="self-stretch text-zen-primarydefault text-xl font-semibold tracking-[-0.4px]">
                        {{data?.question?.title | sjsTranslate:data?.language }}</h5>
                </div>

                <div [ZMenuTriggerFor]="menu" class=" btn flex justify-end items-center gap-4">
                    <div class="flex items-start">
                        <div class="flex w-10 h-10 justify-center items-center rounded c-bg">
                            <div class="flex h-4 items-center gap-3">
                                <div class="flex w-6 h-6 justify-center items-center">
                                    <i
                                        class="flex flex-col w-5 h-5 justify-center shrink-0 text-zen-blueStone1 text-center text-xl font-normal fa-regular fa-ellipsis-vertical"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>

        <z-menu #menu>
            <menuItems>
                <ng-container [ngTemplateOutlet]="moreMenuItems"></ng-container>
            </menuItems>
        </z-menu>

        <ng-template #moreMenuItems>
            <a MenuItem (click)="onExport()"><i class="btn fa-regular fa-download mx-1"></i>{{'Export' |
                translate:language:ModuleNames.SurveyX}}</a>
        </ng-template>



    </ng-container>





    <ng-container body>
        <div class="flex flex-col gap-10 h-screen relative">
            <div class="flex flex-row items-stretch -mx-10 h-screen">

                <div class="flex h-screen items-start self-stretch">
                    <div class="flex flex-col w340 h-screen shrink-0 bg-c">

                        <div class="flex flex-col w340 px-10 pt-10 pb-4 items-start gap-4 bg-c-c">
                            <h5 class="text-zen-primarylight text-xs font-medium tracking024 uppercase">
                                {{"txtSelect_Respondent" | translate:data?.language:ModuleNames.SurveyX}}</h5>
                            <div class="flex flex-col justify-center items-start gap-1 self-stretch">
                                <z-input [search]="true" class=" w-full" [formControl]="searchTerm"
                                    [placeholder]="'Search...'| translate:language:ModuleNames.SurveyX"> </z-input>
                            </div>
                        </div>

                        <div class="flex flex-col w-h-custom items-start gap-2 shrink-0">
                            <div *ngFor="let respondent of filteredRespondents; let i = index "
                                class="flex flex-col items-start self-stretch">
                                <div [ngClass]="activeTabs === respondent ? 'active' : ''"
                                    (click)="setSelectedRespondent(respondent);changeTabs(respondent)"
                                    class=" btn text-zen-primarylight flex h-12 py-4 px-10 font-normal items-center gap-2 self-stretch bg-zen-littlegray hover:bg-zen-greyselect hover:text-zen-primarydefault ">
                                    <div class="flex w-5 h-5 justify-center items-center">
                                        <i [ngClass]="activeTabs === respondent ? 'active1' : ''"
                                            class="w-4 shrink-0 text-zen-neutral text-center text-base  fa-regular fa-user">
                                        </i>
                                    </div>
                                    <h5 class=" text-sm"> {{respondent?.category}}</h5>
                                </div>
                            </div>

                            <div *ngIf="isShowMore"
                                class="flex flex-row w-full text-xs text-zen-primarydefault items-center justify-center">
                                <a class="btn" (click)="showMore()">
                                    <i class="fa-regular fa-chevrons-down"></i>
                                    {{ "txtshowmore" | translate : language : ModuleNames.SurveyX }}</a>
                            </div>
                        </div>

                    </div>
                </div>

                <div *ngIf="selectedRespondent"
                    class="flex flex-col p-10 items-start gap-4 flex-1 self-stretch w-max726 overflow-y-auto ">
                    <h5 *ngIf="language != 'AR'"
                        class="flex text-zen-primarylight text-xs font-medium tracking024 uppercase">
                        {{selectedRespondent.category? selectedRespondent.category + "'s answer" :
                        selectedRespondent.value1 + "'s answer" }}</h5>
                    <h5 *ngIf="language == 'AR'"
                        class="flex text-zen-primarylight text-xs font-medium tracking024 uppercase">{{
                        selectedRespondent.category? "إجابة " + selectedRespondent.category : "إجابة " +
                        selectedRespondent.value1 }}</h5>
                    <div *ngIf="data.question.type != 'file'"
                        class="flex flex-col justify-center items-center gap-1 self-stretch box-border">
                        <div class="flex flex-col py-2 px-2.5 items-start gap-2 self-stretch max-h-700 innerListScroll rounded c-c-c-s">
                            <div class="flex items-start gap-2 self-stretch">
                                <div class="flex items-start gap-1.5 flex-1">
                                    <h5 *ngIf="data.question.numberInputType != 'currency' && selectedRespondent?.value"
                                        class="flex flex-1 flex-wrap text-zen-primarydefault text-sm font-normal">
                                        {{data.question.type == 'datepicker'? (selectedRespondent?.value | date:'dd/MM/yyyy') : selectedRespondent?.value}}
                                    </h5>
                                    <h5 *ngIf="data.question.numberInputType == 'currency' && selectedRespondent?.value"
                                        class="flex flex-1 flex-wrap text-zen-primarydefault text-sm font-normal">
                                        {{selectedRespondent?.value + " " + data.question.numberInputCurrency+" 's"}}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="data.question.type == 'file'" class="flex flex-col justify-center items-center gap-1 self-stretch h-max">
                      <div *ngIf="selectedRespondent" class="flex flex-col py-2 px-2.5 items-start gap-2 self-stretch rounded h-max">
                        <div class="flex items-start gap-2 self-stretch h-max">
                          <div class="flex flex-col items-center gap-1.5 flex-1 h-max">
                            <ng-container *ngIf="checkFile(selectedRespondent?.value)">
                            <span class="btn" (click)="download(selectedRespondent?.value)"><i class="fa fa-download" aria-hidden="true"></i></span>
                              <ng-container *ngFor="let file of modifiedAnswer">

                                <div class="flex flex-row h-12 pr-2 pl-4 items-center gap-2 self-stretch b-bg">

                                  <div class="flex flex-row items-center gap-2 flex-1">
                                    <h5 class="text-exp text-sm font-normal">{{"txtAttachment:" | translate : language : ModuleNames.SurveyX}}</h5>
                                    <h5 class="text-zen-primarydark text-sm font-medium">{{file.name}}</h5>
                                  </div>

                                  <div class="flex flex-row items-center gap-2">
                                    <div class="flex items-start">
                                      <div class="flex flex-row w-8 h-8 justify-center items-center rounded">
                                        <div class="flex flex-row h-4 items-center gap-2">
                                          <div class="flex w-5 h-5 justify-center items-center">
                                            <i (click)="togglePreview(file)" [ngClass]="{'fa-angle-up': isFileExpanded(file), 'fa-angle-down': !isFileExpanded(file)}"
                                             class="btn w-4 flex-shrink-0 text-zen-primarydark text-center text-base font-normal fa-regular fa-angle-up"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>


                                <div *ngIf="isFileExpanded(file)" class="flex w-full">
                                <ng-container *ngIf="file.isImageFile">
                                  <img [src]="file.url" alt="Preview" />
                                </ng-container>

                                <ng-container *ngIf="!file.isImageFile">
                                  <iframe [src]="file.url" class="h455 w-full" frameborder="0" id="cit-iframe" hspace="0" vspace="0"
                                    allowtransparency="true" style="text-align: center; overflow: auto;" *ngIf="file.isPDFFile"></iframe>

                                  <a [href]="file.url" target="_blank" download="{{file.name}}"
                                    *ngIf="!file.isPDFFile">{{ "txtDownload_File" | translate:data?.language:ModuleNames.SurveyX }}</a>
                                </ng-container>
                              </div>



                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>




                </div>



            </div>
        </div>

    </ng-container>

</sx-drawer>
