import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportPageComponent implements OnInit {

  @Input() pageNum: number;
  @Input() classList: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  getClasses() {
    let pageClasses = ['page'].concat(this.classList);
    return pageClasses;
  }
}
