<div class="flex flex-row flex-wrap w-full bg-white items-center justify-center rounded-md main-box mb-8">
    <div *ngIf="question" class="sd-header__text px-6 p-4">
        <div class="flex">
            <div class="flex-auto">
                <h3 class="sd-title">
                    <span class="text-zen-primarylight mr-2">{{ "txtQ" | translate : language : ModuleNames.SurveyX
                        }}{{ question.questionNo }}.</span>
                    {{ question.title | sjsTranslate : language }}
                </h3>
            </div>
            <div class="flex-none text-xs text-zen-primarydefault">
                <span class="text-sm text-exp mr-1 font-medium">{{ totalCount }}</span>
                {{ "txtComment(s)" | translate : language : ModuleNames.SurveyX }}
            </div>
        </div>
    </div>

    <div class="flex flex-col w-full p-6 items-start gap-3 self-stretch">
        <div class="flex flex-row items-center gap-6 self-stretch">
            <div class="flex items-center gap-1.5 custom-w">
                <h5 *ngIf="question.type != 'file'" class="txt11 font-medium uppercase text-zen-primarylight">
                    {{ "txtselected answer" | translate : language : ModuleNames.SurveyX }}
                </h5>
                <h5 *ngIf="question.type == 'file'" class="txt11 font-medium uppercase text-zen-primarylight">
                    {{ "txtuploaded file" | translate : language : ModuleNames.SurveyX }}
                </h5>
            </div>
            <div class="flex-1 flex items-center gap-1.5">
                <h5 class="txt11 font-medium uppercase text-zen-primarylight">
                    {{ "txtcomment" | translate : language : ModuleNames.SurveyX }}
                </h5>
            </div>
        </div>

        <div *ngFor="let comment of comments" class="flex flex-row items-center gap-6 self-stretch">
            <div class="flex flex-row items-center gap-2 custom-w">
                <div class="flex flex-row p1 items-start">
                    <div class="flex justify-center items-center">
                        <i class="fa-regular fa-circle text-center font-black text-base text-exp"></i>
                    </div>
                </div>
                <div class="flex flex-col items-start">
                  <ng-container [ngSwitch]="question.type">

                      <ng-container *ngSwitchCase="'file'">
                          <ng-container *ngFor="let file of getAnswer(comment.answer)" class="gap-1">
                              <a class="flex flex-row cursor-pointer" (click)="downloadFile(file)">
                                  <h5 #h55 [matTooltip]="file.name" [matTooltipDisabled]="!isEllipsisActive(h55)"
                                      class="flex flex-1 truncate flex-col text-xs text-zen-linklike underline hover:text-zen-linklikehover btn">
                                      {{ file.name }}
                                  </h5>
                              </a>
                          </ng-container>
                      </ng-container>

                      <ng-container *ngSwitchCase="'imagepicker'">
                          <h5 #h51 [matTooltip]="getAnswer(comment.answer)"
                              [matTooltipDisabled]="!isEllipsisActive(h51)"
                              class="flex flex-1 text-wrap flex-col text-xs text-zen-primarydefault">
                              {{ getAnswer(comment.answer) }}
                          </h5>
                      </ng-container>

                      <ng-container *ngSwitchDefault="">

                          <h5 #h5 [matTooltip]="getAnswer(comment.answer)"
                              [matTooltipDisabled]="!isEllipsisActive(h5)"
                              class="flex flex-1  text-wrap flex-col text-xs text-zen-primarydefault ">
                              {{ getAnswer(comment.answer) }}
                          </h5>

                      </ng-container>

                  </ng-container>
              </div>

            </div>

            <div class="flex flex-1 flex-row items-center gap-2">
                <div class="flex flex-row p1 items-start">
                    <div class="flex justify-center items-center">
                        <i class="fa-regular fa-comment-lines text-center text-base text-exp"></i>
                    </div>
                </div>
                <h5 #52 class="flex flex-1 flex-col maxw630 text-xs text-zen-primarydefault">
                    {{ comment.comment }}
                </h5>
            </div>
        </div>
        <div *ngIf="isShowMore"
            class="flex flex-row w-full text-xs text-zen-primarydefault items-center justify-center">
            <a class="btn" (click)="showMoreComments()">
                <i class="fa-regular fa-chevrons-down"></i>
                {{ "txtshowmore" | translate : language : ModuleNames.SurveyX }}</a>
        </div>
    </div>
</div>
