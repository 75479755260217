import { Component, Input, OnInit } from '@angular/core';
import { SxBaseComponent } from '../../sx-base/sx-base.component';

@Component({
  selector: 'sx-dependent-question',
  templateUrl: './dependent-question.component.html',
  styleUrls: ['./dependent-question.component.scss']
})
export class DependentQuestionComponent extends SxBaseComponent implements OnInit {

  @Input()
  language

  @Input()
  question;

  @Input() 
  questions;

  operations: any[] = [
    { key: "empty", value: "Empty" },
    { key: "notempty", value: "Not empty" },
    { key: "=", value: "Equal to" },
    { key: "<>", value: "Does not equal to" },
    { key: "contains", value: "Contains" },
    { key: "notcontains", value: "Does not contain" },
    { key: ">", value: "Greater than" },
    { key: "<", value: "Less than" },
    { key: ">=", value: "Greater than or equal to" },
    { key: "<=", value: "Less than or equal to" }
  ];


  constructor() { super() }

  ngOnInit(): void {
  }

  getOperation(operator: any) {
    return this.operations.find(x => x.key == operator).value
  }

  getQuestionNumber(questionKey) {
    return this.questions.indexOf(this.questions.find(x => x.name == questionKey)) + 1
  }
}
