import { ExcelOptions } from "../models/excel-options";
import { IExcelBuilder } from "./iexcel-builder";
import { Workbook } from 'exceljs'

export class DefaultBuilder implements IExcelBuilder {

    private workbook: Workbook;
    private readonly MainWorkSheet = "Main";

    constructor() {
        this.workbook = new Workbook("My WorkBook");
    }


    build(config: ExcelOptions, sampleData: any[]) {
        this.workbook = new Workbook(config.filename);

        const sheet = this.createSheet(this.MainWorkSheet);

        const headerRow = config.columns.map(column => column.key);

        // Create Header Row
        sheet.addRow(headerRow);

    }

    createSheet(title: string) {
        return this.workbook.addWorksheet(title);
    }
}
