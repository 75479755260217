import type { SentimentDataDto, SentimentTopic } from '../../comments-ai/sentiment-analysis/models';
import type { AnalysisFilterDto } from '../analysis/models';
import type { KeyNameDto } from '../../core/common/models';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';

export interface CommentCountDto {
  sectionCount: CountDto[];
  questionCount: CountDto[];
}

export interface CommentSentimentDto {
  respondentKey?: string;
  comment?: string;
  answer: object;
  sentiment?: string;
  commentsAI: SentimentDataDto;
}

export interface CommentsAnalysisRequest {
  surveyKey: string;
  tenantKey: string;
  companyKey: string;
  filters: AnalysisFilterDto[];
}

export interface CommentsTopicsDto {
  key?: string;
  name: KeyNameDto[];
  positiveMentionCount: number;
  negativeMentionCount: number;
  neutralMentionCount: number;
  positiveMentionPercentage: number;
  negativeMentionPercentage: number;
  neutralMentionPercentage: number;
  noSentimentMentionCount: number;
  noSentimentMentionPercentage: number;
  sentiment?: string;
  totalMentionCount: number;
  totalMentionPercentage: number;
}

export interface CountDto {
  count: number;
  key?: string;
}

export interface QuestionCommentDto {
  questionKey?: string;
  comment?: string;
  leaverKey?: string;
  commentAI: SentimentDataDto;
}

export interface QuestionCommentFilterPagedDto extends PagedResultDto<CommentSentimentDto> {
}

export interface QuestionCommentPagedDto extends PagedResultDto<QuestionCommentDto> {
}

export interface QuestionCommentPagedRequest extends PagedResultRequestDto {
  surveyKey: string;
  tenantKey: string;
  companyKey: string;
  questionKey?: string;
  isAllResults?: boolean;
  filters: AnalysisFilterDto[];
}

export interface QuestionCommentPagedRequest2 extends PagedResultRequestDto {
  surveyKey: string;
  tenantKey: string;
  companyKey: string;
  isAllResults?: boolean;
  filters: AnalysisFilterDto[];
}

export interface QuestionSentitmentDto {
  key?: string;
  questionType?: string;
  name: KeyNameDto[];
  positivePercentage: number;
  negativePercentage: number;
  neutralPercentage: number;
  positiveCount: number;
  negativeCount: number;
  neutralCount: number;
  noSentimentCount: number;
  noSentimentPercentage: number;
}

export interface SectionSentimentDto {
  key?: string;
  name: KeyNameDto[];
  questions: QuestionSentitmentDto[];
  totalComments: number;
  commentsPercentage: number;
  positivePercentage: number;
  negativePercentage: number;
  neutralPercentage: number;
  positiveCount: number;
  negativeCount: number;
  neutralCount: number;
  noSentimentCount: number;
  noSentimentPercentage: number;
}

export interface UpdateSentimentDto {
  questionKey?: string;
  respondentKey?: string;
  sentiment?: string;
  topic: SentimentTopic[];
  topics: string[];
}
