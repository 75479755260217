<z-modal [open]="true" autoWidth="true">
  <ng-container body>

    <div class="flex flex-row gap-5 -mb-10 -mt-10 max-h-screen-90 h-screen overflow-hidden max-w-7xl">
      <div class="flex flex-col items-stretch  w-4/5">
        <div class="max-h-screen-90 block  innerListScroll w-export portrait" id="pdf">
          <div class="flex flex-col w-full-20rem border-zen-light border-opacity-20 zen-main-container px-10 pt-14"
           >
            <div class="innerPage">
              <div class="flex flex-row justify-between items-center gap-5">
                <h3 class="text-2xl text-zen font-medium uppercase">{{'txtWellbeingComparison'|
                  translate:language:ModuleNames.Wellbeing}}
                </h3>

              </div>
              <div class="pt-5 maxhchart" *ngIf="obTermCharts[0]?.data$ as data">
                <div class="maxwInherent px-px">
                  <h1 class="text-zen font-semibold text-xl pb-4"><span class="text-exp">1. </span>
                    {{'txtWorkplaceWellbeingScore'|
                    translate:language:ModuleNames.Wellbeing }} </h1>
                  <chart [wrapRows]="true" [export]="true" [reportsPage]="false" [exportInitiated]="exportInitiated"
                    [dashlet]="overAllDashlet" [dashletData]="data" [currencySymbol]="'$'" [language]="language"
                    [dataSymbol]="' %'" [disableGrouping]="true" [showDrillDown]="true" [chartColors]="[]">
                  </chart>
                </div>
              </div>
              <div class="maxhchart" [ngClass]="(n +1 )% 2 == 0?'break-before pt-20':'pt-10'"
                *ngFor="let chart of returnCombined([dimensionChart, companyChart]) ; let n = index">
                <div class="maxwInherent px-px" *ngIf="chart?.data$ as data">
                  <h1 class="text-zen font-semibold text-xl pb-4"><span class="text-exp">{{n +2}}. </span>
                   <span *ngIf="chart.isDimension">  {{'txtDriversofWorkplaceWellbeing'|
                    translate:language:ModuleNames.Wellbeing }} -</span> {{chart.Title}} </h1>
                  <chart [wrapRows]="true" [export]="true" [reportsPage]="false" [exportInitiated]="exportInitiated"
                    [dashlet]="chart" [dashletData]="data" [currencySymbol]="'$'" [language]="language"
                    [dataSymbol]="' %'" [disableGrouping]="true" [showDrillDown]="true" [chartColors]="[]">
                  </chart>
                </div>
              </div>


            </div>
          </div>
          <div class="header w-full px-10 flex flex-row items-start justify-between">
            <h5 class="text-3xs text-zen text-opacity-60">{{ "txtWellbeingReport"
              |translate:language:ModuleNames.Wellbeing }}</h5>
            <h5 class="text-3xs text-zen "><span class="text-opacity-60">{{
                "txtPreparedFor"|translate:language:ModuleNames.Shared }}
              </span> <span class="text-zen font-medium">{{selectedEntity.name | extract:language}}</span> </h5>
          </div>
          <div class="footer w-full px-10 flex flex-row items-end justify-between">
            <div class="flex flex-col gap-1">
              <p class="text-xxs text-zen text-opacity-50">{{ "txtPoweredby" |
                translate:language:ModuleNames.Shared }}</p>
              <img class="h-8 w-auto object-cover" src="assets/img/logo.png" alt="">
            </div>
            <div class="flex flex-col items-end gap-1">
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtGenerated"|translate:language:ModuleNames.Shared }}: {{date | date:
                'dd/MM/yyyy'}}
              </h5>
              <h5 class="text-3xs text-zen text-opacity-60">
                {{ "txtAllRights"|translate:language:ModuleNames.Shared }} © {{date | date: 'yyyy'}}
                -
                www.zenithr.com</h5>

            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-stretch justify-between w-1/5">
        <div class="exp flex flex-col gap-3">
          <div class="flex flex-row items-start gap-2 justify-start pb-3">
            <i class="far fa-file-export text-exp text-lg"></i>
            <h4 class="text-zen text-2xl">{{ 'btnExport' | translate:language:ModuleNames.Wellbeing }}
            </h4>
          </div>
          <!-- <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
            <z-toggle (ngModelChange)="filterTableOfContent($event)"
              [label]="'txt_report' | translate:language:ModuleNames.Wellbeing " [(ngModel)]="exportReport"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
            <z-toggle (ngModelChange)="filterTableOfContent($event)"
              [label]="'txtOverview' | translate:language:ModuleNames.Wellbeing "
              [(ngModel)]="exportOverview"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
            <z-toggle (ngModelChange)="filterTableOfContent($event)"
              [label]="'txtResponses' | translate:language:ModuleNames.Wellbeing"
              [(ngModel)]="exportAnalysis"></z-toggle>
          </div>
          <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
            <z-toggle (ngModelChange)="filterTableOfContent($event)"
              [label]="'txt_questions' | translate:language:ModuleNames.Wellbeing "
              [(ngModel)]="exportQuestions"></z-toggle>
          </div> -->



        </div>
        <div class="flex flex-row justify-between gap-5">
          <a (click)="close.emit(true)" class="btn cancel leading-12 rounded text-sm text-center px-5">{{"btn_cancel"|
            translate:language:ModuleNames.Wellbeing}}</a>

          <a (click)="exportDraft(language,true)"
            class="btn exp leading-12 rounded text-sm text-center px-5">{{"btnExport"|
            translate:language:ModuleNames.Wellbeing}}</a>

        </div>
      </div>
    </div>
  </ng-container>
</z-modal>
