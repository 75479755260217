<div class="w-full h-32 bg-white border-t border-gray-200 overflow-hidden">
  <div class="w-full h-2.5 bg-zen-paleWhite">
    <div class="h-2.5 navigation-progress" [ngStyle]="{'width.%': (surveyModel?.currentPageNo + 1)*100/surveyModel?.visiblePages?.length}"></div>
  </div>

  <div class="flex justify-between items-center w-full h-full pb-4">
    <div class="flex w-full justify-between items-center px-8">
      <div [ngClass]="(!surveyModel?.isLastPage && surveyModel?.isFirstPage)? 'justify-end' : 'justify-between' "
        class="flex w-full max-w-2xl mx-auto">
        <div>
          <button *ngIf="!surveyModel?.isFirstPage" [disabled]="surveyModel?.isFirstPage"
            (click)="prevPage()" class="btn previous-button">
            <i class="fa-regular" [ngClass]="(language | language )?'fa-arrow-right-long':'fa-arrow-left-long'"></i>{{
            'btn_leaver_q_back' | translate:language:ModuleNames.Surveys
            }}
          </button>
        </div>

        <button *ngIf="!surveyModel?.isLastPage" (click)="surveyModel?.nextPage()" class="btn next-button">
          {{'btn_leaver_q_next' | translate:language:ModuleNames.Surveys}}<i class="fa-regular"
            [ngClass]="(language | language )?'fa-arrow-left-long':'fa-arrow-right-long'"></i>
        </button>

        <button *ngIf="surveyModel?.isLastPage" (click)="showConfirmSubmissionModal.emit()" class="btn next-button">
          {{'btn_leaver_q_submit' | translate:language:ModuleNames.Surveys}}<i class="fa-regular"
            [ngClass]="(language | language )?'fa-arrow-left-long':'fa-arrow-right-long'"></i>

        </button>
      </div>
    </div>
  </div>
</div>
