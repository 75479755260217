import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class TemplatesService {

  private emailTemplate = new BehaviorSubject<any>({});
selectedEmailTemplate= this.emailTemplate.asObservable();

private reminderTemplate = new BehaviorSubject<any>({});
selectedReminderTemplate = this.reminderTemplate.asObservable();


constructor(){}

setEmailTemplate(EmailTemplate:any){
  this.emailTemplate.next(EmailTemplate);
}
setReminderTemplate(ReminderTemplate:any){
  this.reminderTemplate.next(ReminderTemplate);
}

}
