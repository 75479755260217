<div *ngIf="tenant.setup" [@first]
  class="flex flex-row justify-between h-header items-center customHShadow px-10 fixed top-0 z-40 backdrop-filter backdrop-blur-2xl bg-white bg-opacity-90 w-screen">
  <div [@first] class="flex flex-row justify-start items-center gap-5 w-4/12">
    <div (click)="logData(moduleName.innerHTML)" class="flex flex-row items-center">
      <img *ngIf="!isThomas" class="h-9" src="/assets/img/svg/logo.svg" alt>
      <img *ngIf="isThomas" class="h-8" src="/assets/img/svg/thomas-icon.svg" alt>
    </div>
    <span *ngIf="moduleName?.innerHTML?.length > 1" [@child1]
      class="h-10 w-0 border-r border-zen border-opacity-20"></span>
    <h5 #moduleName [@child2] [@first] class="text-lg text-exp font-normal">
      {{title|translate:language:ModuleNames.Shared}}
    </h5>
  </div>
  <div [ngClass]="(language | language)?'-ml-10':'-mr-10'"
    class="flex flex-row items-center gap-5 relative w-4/12 justify-end">
    <div [ngClass]="(language | language)?'rtl':'ltr'" class="flex flex-row justify-end items-center gap-2 px-10">
      <!-- <div [@child7] *ngIf="showCompanies && companies.length"
                class="bg-zen bg-opacity-10 rounded-md px-5 pt-1 overflow-hidden h-12 w-40 xl::w-max">
                <mat-form-field class="w-full -mt-3" appearance="none">
                    <mat-select (selectionChange)="selectEntityChange($event)" [(ngModel)]="selectedEntityKey">
                        <mat-option *ngFor="let company of companies " [value]="company.key">

                            {{ extractNameLanguage(company.name) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
      <!-- <div [@child9] class="bg-zen bg-opacity-10 rounded-md px-5 pt-1 overflow-hidden h-12 w-32">
                <mat-form-field class="w-full -mt-3" appearance="none">
                    <mat-select (selectionChange)="selectedLanguageChange($event.value)" [(ngModel)]="language"
                        (click)="$event.stopPropagation()">
                        <mat-option [value]="ln.key" *ngFor="let ln of supportedLanguages">
                            <div class="flex flex-row h-15 gap-2 items-center text-base ">
                                <i class="fal fa-language"></i>
                                <h4 class="text-base text-zen"> {{ ln.name }}</h4>
                            </div>

                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
      <div [@child11] [ngClass]="(language | language)?'rtl':'ltr'" (clickOutside)="closeDropdown('showMenu')"
        appClickOutside>
        <div [ngClass]="!showMenu?'opacity-50':'topArrow'" (click)="showMenu = !showMenu"
          class="flex flex-col justify-center items-center w-12 h-15 btn arrow">
          <img class="h-6 w-6" src="/assets/img/svg/apps-icon.svg" alt>
        </div>
        <div class="flex left-0 flex-row justify-end absolute w-full h-0 px-5">
          <div *ngIf="showMenu" [@fadeUpDown]
            class="flex flex-col gap-9 top-22 p-5 rounded-lg shadow-lg bg-white  mt-5 relative h-max">
            <div class="flex flex-row justify-between items-center gap-10">
              <p class="text-zen text-xs text-opacity-70 uppercase">
                {{'txtEmployeeProducts'|
                translate:language:ModuleNames.Shared}}
              </p>
              <a (click)="showMenu = false" routerLink="/settings"
                class="text-zen text-xs text-opacity-70 flex flex-row items-center gap-1 btn">
                <i class="fal fa-cog"></i>
                <p class="whitespace-nowrap">
                  {{'txtControlPanel'|
                  translate:language:ModuleNames.Shared}}
                </p>
              </a>
            </div>
            <div class="flex flex-col gap-1 max-h-screen-60 innerListScroll">

              <ng-container *ngFor="let route of routes">
                <ng-container *ngTemplateOutlet="routeTemplate; context:{$implicit:route}"></ng-container>
              </ng-container>

              <!-- *ngIf="isThomas" -->
              <!-- <a [routerLinkActive]="'bg-zen bg-opacity-5'" (click)="showMenu = false" routerLink="/psychometric"
                class="h-20 px-5 flex flex-row justify-between items-center gap-10 btn rounded-md hover:bg-zen hover:bg-opacity-10">
                <div class="flex flex-row items-center gap-5">
                  <img class="w-8" src="/assets/img/svg/psychomatric.svg" alt>
                  <h5 class="text-base text-zen font-normal">
                    {{'txtPsychometricAssessments'|
                    translate:language:ModuleNames.Shared}}
                  </h5>
                </div>
                <img class="w-14" src="/assets/img/svg/thomas-logo.svg" alt>
              </a> -->



            </div>
          </div>
        </div>
      </div>
      <div [@child13] [ngClass]="(language | language)?'rtl':'ltr'" (clickOutside)="closeDropdown('showNotifications')"
        appClickOutside>
        <div [ngClass]="showNotifications?'topArrow':''" (click)="showNotifications = !showNotifications"
          class="flex flex-col justify-center items-center w-12 h-15 btn  showNotifications arrow">
          <img [ngClass]="showNotifications?'topArrow':'opacity-50 '" class="h-6 w-6"
            src="/assets/img/svg/bell-icon.svg" alt>
          <span *ngIf="!showNotifications && notificationMessages.length" [@fadeUpDown]
            [ngClass]="(language | language)?'right-1/2':'left-1/2'"
            class="btn exp rounded-r-full rounded-l-full font-thing text-xxs absolute text-center p-1 top-1 mini105">
            {{notificationMessages.length}}
          </span>




        </div>
        <div class="flex left-0 flex-row justify-end absolute w-full h-0 px-5">
          <div *ngIf="showNotifications" [@fadeUpDown]
            class="flex flex-col gap-2 top-22 p-5 rounded-lg shadow-lg bg-white  mt-5 relative h-max items-center min-w-80">
            <div class="flex flex-row justify-between w-full gap-5">



              <a routerLink="/settings" class="text-zen text-xs text-opacity-70 flex flex-row items-center gap-1 btn">
                <i class="fal fa-cog"></i>
                <p class="whitespace-nowrap">
                  {{'txtControlPanel'|
                  translate:language:ModuleNames.Shared}}
                </p>
              </a>
              <div class="flex flex-row justify-center w-full items-center gap-2">
                <p class="text-zen text-xs text-opacity-70 flex flex-row items-center gap-1 btn">
                  {{'txt_notifications'| translate:language:ModuleNames.Shared}}
                </p>
                <span class="btn exp rounded-r-full rounded-l-full px-3 py-1 font-medium text-xs">
                  {{notificationMessages.length}}
                </span>



              </div>




              <div [matTooltip]="'txtClearAllNotifications'|translate:language:ModuleNames.Shared"
                (click)="removeAllNotifications()"
                class="text-zen text-xs text-opacity-70 flex flex-row items-center gap-1 btn">
                <svg width="24px" height="24px" viewBox="0 0 24 24">
                  <path d="M5 13h14v-2H5v2zm-2 4h14v-2H3v2zM7 7v2h14V7H7z"></path>
                </svg>
              </div>
            </div>



            <div class="flex flex-col innerListScroll gap-2 max-h-screen-50">
              <div *ngFor="let message of notificationMessages"
                class="flex flex-row justify-between items-center bg-exp bg-opacity-5 p-5 rounded-sm gap-5 group">
                <div class="flex flex-col gap-1">
                  <h5 class="text-zen text-sm font-medium">{{message.title}}</h5>
                  <h6 class="text-zen text-sm font-normal">{{message.message}}</h6>
                </div>
                <i (click)="removeNotification(message.key)"
                  class="fal fa-times text-sm btn cancel rounded-full w-5 h-5 leading-5 text-center group-hover:opacity-100 opacity-0"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [@child15] [ngClass]="(language | language)?'rtl':'ltr'" (clickOutside)="closeDropdown('showUser')"
        appClickOutside>
        <div class="transition duration-100  h-15 flex flex-row gap-2 select-none showUser  items-center">
          <span></span>
          <div class="flex flex-col items-end">
            <h5 *ngIf="user" class="text-sm text-zen font-medium">
              {{user.firstName + ' ' + user.lastName}}
            </h5>
            <h5 (click)="openOptions()" class="text-xs text-zen text-opacity-60 btn truncate w-18">
              {{extractNameLanguage(selectedEntity?.name)}}
              <i class="fa-solid fa-caret-down"></i>
            </h5>
          </div>
          <div [ngClass]="showUser?'opacity-100 topArrow':'opacity-60'" class="arrow">
            <img (click)="showUser = !showUser" class="object-contain duration-100 transform  h-15 w-12 btn  "
              src="assets/img/user.png" alt>
          </div>



        </div>
        <div class="flex left-0 flex-row justify-end absolute w-full h-0 px-5">
          <div *ngIf="showUser" [@fadeUpDown]
            class="flex flex-col gap-2 top-22 p-5 rounded-lg shadow-lg bg-white  mt-5  relative h-max items-center">
            <p class="text-zen text-xs text-opacity-70 font-medium text-center">
              {{'txtLoggedinas'|
              translate:language:ModuleNames.Shared}}
            </p>
            <h3 class="text-zen font-medium text-lg">
              {{user.firstName + ' '+
              user.lastName}}
            </h3>
            <div (click)="closeDropdown('showUser')" class="flex flex-col gap-1">
              <a (click)="resetPassword()"
                class="flex flex-row gap-2 items-center btn cancel px-5 rounded leading-10 h-10">
                <i class="fal  fa-unlock-alt" aria-hidden="true"></i>
                <h4 class="text-sm">
                  {{'txtResetPassword'| translate:language:ModuleNames.Shared}}
                </h4>
              </a>
              <a routerLink="/settings"
                class="flex flex-row gap-2 items-center btn cancel px-5 rounded leading-10 h-10">
                <i class="fal fa-cog "></i>
                <h4 class="text-sm whitespace-nowrap">
                  {{'txtControlPanel'|translate:language:ModuleNames.Shared}}
                </h4>
              </a>
              <a (click)="loader.displayLoader()" routerLink="/accountoverview"
                class="flex flex-row gap-2 items-center btn cancel px-5 rounded leading-10 h-10">
                <i class="fal fa-book-user"></i>
                <h4 class="text-sm">
                  {{'txtAccountOverview'|translate:language:ModuleNames.Shared}}
                </h4>
              </a>
              <a (click)="openOptions()"
                class="flex flex-row gap-2 items-center btn cancel px-5 rounded leading-10 h-10">
                <i class="fa-light fa-screwdriver-wrench"></i>
                <h4 class="text-sm">
                  {{'txtDisplayOptions'|translate:language:ModuleNames.Shared}}
                </h4>
              </a>
              <a href="https://zenithr.atlassian.net/servicedesk" target="_blank"
                class="flex flex-row gap-2 items-center btn cancel px-5 rounded leading-10 h-10">
                <i class="fa-light fa-life-ring"></i>
                <h4 class="text-sm">
                  {{'txtHelpCenter'|translate:language:ModuleNames.Shared}}
                </h4>
              </a>

              <a (click)="logout()" class="flex flex-row gap-2 items-center btn delete px-5 rounded leading-10 h-10">
                <i class="fal fa-power-off "></i>
                <h4 class="text-sm">
                  {{'txtLogout'|translate:language:ModuleNames.Shared}}
                </h4>
              </a>



            </div>
          </div>
        </div>
      </div>



    </div>
  </div>


</div>
<!-- <app-help-center></app-help-center> -->




<z-modal [(open)]="displayOptions">
  <ng-container header>
    <h2 class="text-zen text-2xl font-semibold">
      {{'txtDisplayOptions'| translate:language:ModuleNames.Shared}}
    </h2>
    <i (click)="displayOptions = false"
      class="fa-regular fa-times leading-12 btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col gap-5 px-10 pb-5">
      <h6 class="text-xxs font-medium uppercase text-zen">
        {{"txt_DISPLAYLANGUAGE"|
        translate:language:ModuleNames.Shared}}
      </h6>
      <div class="flex flex-row gap-10 text-zen">
        <ng-container *ngFor="let ln of supportedLanguages">
          <div (click)="tLanguage = ln.key" class="flex flex-row gap-2 items-center text-base btn">
            <i [ngClass]="tLanguage == ln.key?'fa-solid fa-circle-dot':'fa-thin fa-circle'" class></i>
            <h4 class="text-sm text-zen">{{ ln.nativeName || ln.name }}</h4>
          </div>
        </ng-container>



      </div>
    </div>
    <mat-divider></mat-divider>
    <div (click)="scroll(tSelectedEntityKey)" class="flex flex-col gap-5 px-10 pt-5">
      <h6 class="text-xxs font-medium uppercase text-zen">
        {{"txt_ACTIVEENTITY"|
        translate:language:ModuleNames.Shared}}
      </h6>
      <input [(ngModel)]="searchValue" [placeholder]="'txtSearchEntities'| translate:language:ModuleNames.Shared"
        (ngModelChange)="searchList(searchValue, companies)" type="text"
        class="bg-white border-zen border border-opacity-40 rounded leading-9 px-3 ">
      <div class="flex flex-col innerListScroll max-h-52">
        <div *ngFor="let company of tempList " [id]="company.key"
          [ngClass]="tSelectedEntityKey == company.key?'bg-exp text-exp font-medium':'hover:bg-zen hover:bg-opacity-10 text-zen'"
          (click)="tSelectedEntityKey = company.key"
          class="flex flex-row justify-between items-center h-10 px-5 text-sm leading-10 rounded  bg-opacity-10 transition-all btn">
          <h5>
            {{ extractNameLanguage(company.name) }}
          </h5>



          <i *ngIf="tSelectedEntityKey == company.key" class="text-exp fa-regular fa-check"></i>
        </div>



      </div>
    </div>
  </ng-container>



  <ng-container footer class="flex w-full flex-row justify-between">
    <a (click)="displayOptions = false" class="btn leading-10 rounded-sm cancel px-5 text-sm font-medium">
      {{"btn_cancel"|
      translate:language:ModuleNames.Shared}}
    </a>
    <a (click)="displayChanges()" class="btn leading-10 rounded-sm exp px-5 text-sm font-medium">
      {{'txtApplyChanges'|
      translate:language:ModuleNames.Shared}}
    </a>
  </ng-container>
</z-modal>





<ng-template #routeTemplate let-route>
  <a [routerLinkActive]="'bg-zen bg-opacity-5'" [routerLink]="route.url" (click)="showMenu = false"
    class="h-20 min-h-20 px-5 flex flex-row justify-between items-center gap-10 btn rounded-md hover:bg-zen hover:bg-opacity-10">
    <div class="flex flex-row items-center gap-5">
      <img [src]="route.image" class="w-8" alt>
      <h5 class="text-base text-zen font-normal">
        {{ route.name | translate:language:ModuleNames.Common }}
      </h5>
    </div>
  </a>
</ng-template>
