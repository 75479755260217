import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';
import { environment } from 'src/environments/environment';
import { SurveyCreator } from "survey-creator-knockout";
import { SurveysService } from '@ex/module/survey-x/data-access/services/surveys.service';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { DatePipe } from '@angular/common';
import { LanguageService } from '@ex/module/core/services/language.service';
import { LocalService } from '@ex/module/core/services/local.service';
import { ZenCDKService } from '@ex/module/shared/Theme/services/zen-cdk.service';
import { TemplatesService } from '@ex/module/survey-x/data-access/services/templates.service';
import { EmailTemplateComponent } from '@ex/module/survey-x/ui/templates/email-template/email-template.component';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-main-info',
  templateUrl: './main-info.component.html',
  styleUrls: ['./main-info.component.scss'],
  animations: [ChildrenAnimation]
})
export class MainInfoComponent extends BaseComponent implements OnInit, OnDestroy {

  endPoint = environment.endPoint;
  survey: any = {};

  subscriptions = new Subscription();

  language = 'EN';
  ModuleNames = ModuleName;


  supportedLanguages = ['EN', 'AR'];

  welcomeMessageText = [{ key: 'EN', name: "" }, { key: 'AR', name: "" }];

  createdBy: string = "";
  currentUser: any;
  currentUserName: any;

  emailTemplate: any;

  isName: boolean = false;
  isEmail: boolean = true;
  isJobTitle: boolean = false;
  isPhoneNumber: boolean = false;
  isCompanyName: boolean = false;
  isCompanySize: boolean = false;
  isCompanyIndustry: boolean = false;
  isLocation: boolean = false;
  isGender: boolean = false;
  isBirthDate: boolean = false;
  isNationality: boolean = false;
  isReligion: boolean = false;
  isMaritalStatus: boolean = false;
  isJobType: boolean = false;
  isContractType: boolean = false;
  isGccCountries: boolean = false;
  isWelcomeMessage: boolean = false;

  companyKey: any
  tenantKey: any;
  tenant: any;
  tenantName: any
  title = "";
  description = "";
  activateWeights: boolean = false;
  respondentInformationPage: boolean = true;
  timeFrame: boolean = false;

  validateFields = false;



  minimumDate: Date = new Date();
  selectedStartDate: Date = new Date();
  selectedEndDate !: Date;

  formTypeId = null;

  categoriesList: any[] = [];
  typesList: any[] = [];
  selectedType!: "";
  selectedCategory!: "";
  surveyId!: string;
  logoURL !: string;

  expColor = "#0082C6";

  settingsLoaded$ = new Subject();

  constructor(private client: HttpClient, private router: Router, private surveysService: SurveysService, private localService: LocalService,
    private languageService: LanguageService, private zenCdk: ZenCDKService, private templatesService: TemplatesService, private datePipe: DatePipe) { super(); }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  ngOnInit(): void {

    const sub = this.EssentialObject$.pipe(
      filter(x => x.selectedEntity?.key != null),
    ).subscribe(obj => {
      this.tenantKey = obj.tenant.key;
      this.companyKey = obj.selectedEntity.key;
      this.language = obj.language;

      this.settingsLoaded$.next();
    })

    this.addSubscription(sub);

    // this.templatesService.selectedEmailTemplate.subscribe((response:any)=>{
    //   this.emailTemplate = response;
    // })

    this.tenant = JSON.parse(localStorage.getItem("tenant")!)
    this.tenant.lastUpdated = new Date(this.tenant.lastUpdated * 1000);
    //moment.unix(this.tenant.lastUpdated).format("DD/MM/YYYY");
    this.tenantName = this.tenant.name
    this.tenantKey = this.tenant.key

    this.currentUser = JSON.parse(localStorage.getItem("user")!);

    this.currentUserName = this.currentUser.firstName + " " + this.currentUser.lastName;


    //------------------------------------------------------------------------
    //------------------------------------------------------------------------
    this.surveysService.getAllFormTypes().subscribe((response: any) => {
      response.forEach((element: any) => {

        this.typesList.push({
          key: element.id,
          name: element.name
        })
      })
    })
    //------------------------------------------------------------------------
    //------------------------------------------------------------------------
    let localLanguage = this.localService.getJsonValue('language');
    if (!localLanguage) {
      this.language = 'EN';
      this.localService.setJsonValue('language', this.language);
    } else {
      this.language = localLanguage;
      // this.language = 'FR';
    }
    this.languageService.language = this.language;

    //------------------------------------------------------------------------
    //------------------------------------------------------------------------

    combineLatest([this.settingsLoaded$, this.surveysService.getAllCategories(this.formTypeId, this.tenantKey, this.companyKey)])
      .subscribe(([_, response]: any) => {
        response.forEach((element: any) => {

          this.categoriesList.push({
            key: element.id,
            name: element.name,
            formType: element.formTypeId
          })
        })
      })

  }

  onTypeChanged(selectedValue: any) {
    this.selectedType = selectedValue;

    this.formTypeId = selectedValue;
    this.categoriesList = [];
    this.surveysService.getAllCategories(this.formTypeId, this.tenantKey, this.companyKey).subscribe((response: any) => {

      response.forEach((element: any) => {

        this.categoriesList.push({
          key: element.id,
          name: element.name,
          formType: element.formTypeId
        })
      })
    })
  }
  onCategoryChanged(selectedValue: any) {
    this.selectedCategory = selectedValue;
  }

  onFormStartDateChanged(selectedDate: any) {
    this.selectedStartDate = selectedDate;
  }
  onFormEndDateChanged(selectedDate: any) {
    this.selectedEndDate = selectedDate;
  }
  async editEmailTemplate(key: any) {
    await this.zenCdk.openComponent(EmailTemplateComponent, key);
  }

  getLogoUrl(url: string) {
    this.logoURL = url;
  }

  async onNext() {


    if (this.title == null || this.title == "" || this.title == undefined) {
      this.validateFields = true;
      return
    }
    if (this.selectedType == undefined || this.selectedType == "" || this.selectedType == null) {
      this.validateFields = true;
      return
    }
    if (this.isWelcomeMessage && this.welcomeMessageText == null || this.isWelcomeMessage && this.welcomeMessageText == undefined) {
      this.validateFields = true;
      return
    }



    var Title = this.title;
    var category = this.selectedCategory;
    var type = this.selectedType;
    var Description = this.description;
    var RespondentInformationPage = this.respondentInformationPage;
    var WelcomeMessageText = JSON.stringify(this.welcomeMessageText);

    if (!this.isWelcomeMessage) {
      WelcomeMessageText = "";
    }


    var surveyForm = {
      title: Title, categoryId: category, typeId: type, description: Description, respondentInformationPage: RespondentInformationPage,
      isRespondentName: this.isName, isEmail: true, isJobTitle: this.isJobTitle, isRespondentPhoneNumber: this.isPhoneNumber, isRespondentCompanyName: this.isCompanyName,
      isRespondentCompanySize: this.isCompanySize, isRespondentCompanyIndustry: this.isCompanyIndustry, isRespondentLocation: this.isLocation, isRespondentGender: this.isGender,
      isRespondentBirthDate: this.isBirthDate, isRespondentNationality: this.isNationality, isRespondentReligion: this.isReligion, isRespondentMaritalStatus: this.isMaritalStatus,
      isRespondentJobType: this.isJobType, isRespondentContractType: this.isContractType, welcomeMessage: WelcomeMessageText, isGccCountries: this.isGccCountries,
      isWelcomeMessage: this.isWelcomeMessage, tenantKey: this.tenantKey, tenantName: this.tenantName, startDate: this.selectedStartDate,
      endDate: this.selectedEndDate, createdBy: this.currentUserName
    };

    var sub = await this.surveysService.createSurvey(surveyForm);

    console.log(sub)

    this.surveyId = sub.id;


    await this.editEmailTemplate(this.surveyId);

    //  .subscribe(resp => {
    //   this.survey = resp;
    //   console.log(this.survey)
    //   this.surveyId = this.survey.id;
    //  });
    //  this.subscriptions.add(sub)

    if (this.surveyId != null) {
      this.router.navigate(["survey-x/form-library/form-creator/" + this.surveyId]);
    }
  }

}
