import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LanguageService } from './language.service';
import { ZenCDKService } from '@ex/module/shared/Theme/services/zen-cdk.service';
import { DemoPopupComponent } from '@ex/module/shared/demo-account/demo-popup/demo-popup.component';
import { switchMap } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { DemoAccountDirective } from '@ex/module/shared/demo-account/demo-account.directive';
import { DemoAccountService } from '@ex/module/shared/demo-account/demo-account.service';
interface IKeyName {
  key?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AiTranslationService {
  private translationApi = 'https://ai.zenithr.net/'  //environment.translationAPI;
  constructor(private http: HttpClient, private languageService: LanguageService, private zencdk: ZenCDKService, private loaderService: LoaderService, private demoService: DemoAccountService) { }

  translate(text: string, sourceLanguage: string, targetLanguage: string): Observable<IKeyName | any> {
    if (this.demoService.isDemo)
      return this.demoAccountOverride();
    return this.http.post(this.translationApi + "translation/get-ai-translation", { text: text, sourceLanguage: sourceLanguage, targetLanguage: targetLanguage });
  }
  validateText(text: string, sourceLanguage: string): Observable<boolean | any> {
    if (this.demoService.isDemo)
      return this.demoAccountOverride();
    let language = this.languageService.supportedLanguages.find(x => x.key == sourceLanguage)?.name;
    return this.http.post(this.translationApi + "surveys/is-valid-source", { source: text, sourceLanguage: language });
  }
  generateText(module: string, text: string, sourceLanguage: string, targetLanguages: IKeyName[], count: number): Observable<any> {
    if (this.demoService.isDemo)
      return this.demoAccountOverride();
    let language = this.languageService.supportedLanguages.find(x => x.key == sourceLanguage)?.name;
    let targetLanguageList = this.languageService.supportedLanguages.filter(x => targetLanguages.find(t => t == x.key));
    return this.http.post(this.translationApi + "surveys/get-ai-generated-statements", { module, source: text, sourceLanguage: language, targetLanguages: targetLanguageList, count });
  }



  demoAccountOverride() {
    this.loaderService.hideLoader();
    return this.zencdk.openComponent(DemoPopupComponent).pipe(switchMap(x => {
      return of(x);
    }))

  }
}
