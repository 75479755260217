import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'section-head',
  templateUrl: './section-head.component.html',
  styleUrls: ['./section-head.component.scss']
})
export class SectionHeadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
