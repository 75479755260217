/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CriteriaType {
    OpenText = 1,
    DateInput = 2,
    DropDown = 3,
}
