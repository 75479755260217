import { Component, ContentChild, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AiBaseComponent } from '../ai-base.component';
import { BasicRequest, CommentsTopicsDto, SectionSentimentDto } from '../../interfaces/ai-models';
import { SentimentAnalysis } from '../../enums/sentiment-analysis';

@Component({
  selector: 'comments-section',
  templateUrl: './comments-section.component.html',
  styleUrls: ['./comments-section.component.scss']
})
export class CommentsSectionComponent extends AiBaseComponent implements OnInit {
  @Input() tourKey;
  @Input() isFactor = false;
  @Input() isDimension = false;
  open: boolean;
  @Input() language;
  @Input() disableClick: boolean = false;
  @Input() topics: CommentsTopicsDto[];

  @Input('section') set s(section: SectionSentimentDto) {
    this.currentSection = section;
    this.sectionCopy = JSON.parse(JSON.stringify(section));
    this.sectionCommentsCount = this.currentSection.questions.map(x => ({ key: x.key, count: 0 }));

  };
  @Input() request: BasicRequest;
  @Input() sentiments;
  @Input() module;

  currentSection: SectionSentimentDto;
  sectionCopy: SectionSentimentDto;
  sectionCommentsCount: { key: string, count: number }[] = [];
  @Input('currentSentiment') set sent(sentiment) {
    this.parentSentiment = sentiment;
    this.filterSection(sentiment);
  };
  parentSentiment: any;
  currentSentiment: any;
  openedQuestions: any = [];
  constructor() {
    super();
  }
  ngOnInit(): void {

  }
  filterSection(filter: any) {
    
    this.currentSentiment = filter;
    filter == 'no_sentiment' ? filter = SentimentAnalysis.noSentiment : '';
    if (filter)
      this.currentSection.questions = this.sectionCopy.questions.filter(x => x[filter + 'Percentage']);
    else this.currentSection.questions = this.sectionCopy.questions;
  }


  returnSentimentName(key: string) {
    return this.sentiments.find(x => x.key == key)?.name;
  }

  assignCommentCount(count: number, keyL: string) {
    this.sectionCommentsCount.find(x => x.key == keyL).count = count;
  }
  filteredCount() {
    return this.sectionCommentsCount.filter(x => this.currentSection.questions.find(f => f.key == x.key)).map(x => x.count).reduce((sum, current) => sum + current, 0);
  }

  trackBy(index, item) {
    return item.key;
  }

  updateSectionAI(event: SectionSentimentDto) {
    this.sectionCopy = JSON.parse(JSON.stringify(event));
    this.currentSection = { ...event, questions: this.currentSection.questions };
  }

}
