<z-modal [open]="true" [autoWidth]="true">
  <ng-container header>
    <h2 class="text-zen text-2xl font-semibold">{{ "txtChartSettings" | translate:language:ModuleNames.SurveyX }}
    </h2>
    <i (click)="close.emit(true)"
      class="fa-regular fa-times leading-12 btn text-center text-xl text-zen text-opacity-70"></i>
  </ng-container>
  <ng-container body>
    <div class="flex flex-col  items-stretch gap-4 max-w-md w-screen">
      <h5 class="text-xs text-zen-primarylight font-medium uppercase">{{ "txtSelectColorVisualization" |
        translate:language:ModuleNames.SurveyX }}</h5>
      <div class="flex flex-col items-stretch gap-4">

        <div class="flex flex-row justify-between items-stretch gap-2"
          (click)="chartColors.colorType = ChartColorType.Solid">
          <div class="flex flex-row items-center w-1/3 gap-2 btn">
            <i [ngClass]="chartColors.colorType == ChartColorType.Solid?'fa-circle-dot text-exp':'fa-circle text-zen'"
              class="fa-regular  btn text-xl"></i>
            <h5 class="text-zen-primarydefault text-sm">{{'txtSolid' | translate:language:ModuleNames.SurveyX}}</h5>
          </div>
          <div class="flex flex-row items-stretch bg-white rounded-sm p-1 gap-px w-2/3">
            <div class="flex flex-col items-stretch w-full relative">
              <input #solid type="color" [(ngModel)]="chartColors.color" class="absolute opacity-0 w-0">
              <span class="h-full btn rounded-sm" (click)="solid.click()" [style.background]="chartColors.color"></span>
            </div>
          </div>
        </div>


        <div class="flex flex-row justify-between items-stretch gap-2"
          (click)="chartColors.colorType =  ChartColorType.Dynamic">
          <div class="flex flex-row items-center w-1/3 gap-2 btn">
            <i [ngClass]="chartColors.colorType ==  ChartColorType.Dynamic?'fa-circle-dot text-exp':'fa-circle text-zen'"
              class="fa-regular btn text-xl"></i>
            <h5 class="text-zen-primarydefault text-sm">{{'txtDynamic' | translate:language:ModuleNames.SurveyX}}</h5>
          </div>
          <div class="flex flex-row items-stretch bg-white rounded-sm p-1 gap-px w-2/3">
            <div class="flex flex-col items-stretch w-full relative"
              *ngFor="let color of chartColors.dynamicColors; let n = index">
              <input #dynamic type="color" [(ngModel)]="color.color" class="absolute opacity-0 w-0">
              <span class="h-full btn rounded-sm" (click)="dynamic.click()" [style.background]="color.color"></span>
            </div>

          </div>
        </div>




        <div class="flex flex-row justify-between items-stretch gap-2"
          (click)="chartColors.colorType =  ChartColorType.Linear">
          <div class="flex flex-row items-center w-1/3 gap-2 btn">
            <i [ngClass]="chartColors.colorType ==  ChartColorType.Linear?'fa-circle-dot text-exp':'fa-circle text-zen'"
              class="fa-regular btn text-xl"></i>
            <h5 class="text-zen-primarydefault text-sm">{{'txtLinear' | translate:language:ModuleNames.SurveyX}}</h5>
          </div>
          <div class="flex flex-row items-stretch bg-white rounded-sm p-1 gap-px w-2/3">
            <div class="w-1/2 flex flex-row items-stretch gap-px relative btn">
              <input #firstLinear type="color" [(ngModel)]="chartColors.linearColors[0]" class="absolute opacity-0 w-0">
              <span (click)="firstLinear.click()" [style.background]="color"
                *ngFor="let color of (linearColors | slice:0:5)" class="w-1/5"></span>
            </div>
            <div class="w-1/2 flex flex-row items-stretch gap-px relative btn">
              <input #secondLinear type="color" [(ngModel)]="chartColors.linearColors[1]"
                class="absolute opacity-0 w-0">
              <span (click)="secondLinear.click()" [style.background]="color"
                *ngFor="let color of (linearColors | slice:-5)" class="w-1/5"></span>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-between items-stretch gap-2"
          (click)="chartColors.colorType =  ChartColorType.LinearNegative">
          <div class="flex flex-row items-center w-1/3 gap-2 btn">
            <i [ngClass]="chartColors.colorType ==  ChartColorType.LinearNegative?'fa-circle-dot text-exp':'fa-circle text-zen'"
              class="fa-regular btn text-xl"></i>
            <h5 class="text-zen-primarydefault text-sm">{{'txtLinear(Negative)' | translate:language:ModuleNames.SurveyX}}</h5>
          </div>
          <div class="flex flex-row items-stretch bg-white rounded-sm p-1 gap-px w-2/3">
            <div class="w-full flex flex-row items-stretch gap-px relative btn">
              <input #firstColor type="color" [(ngModel)]="chartColors.linearColors[0]"
                class="absolute opacity-0 w-0">
              <span (click)="firstColor.click()" [style.background]="color" *ngFor="let color of negativeColors"
                class="w-1/5"></span>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-between items-stretch gap-2"
        (click)="chartColors.colorType =  ChartColorType.LinearPositive">
        <div class="flex flex-row items-center w-1/3 gap-2 btn">
          <i [ngClass]="chartColors.colorType ==  ChartColorType.LinearPositive?'fa-circle-dot text-exp':'fa-circle text-zen'"
            class="fa-regular btn text-xl"></i>
          <h5 class="text-zen-primarydefault text-sm">{{'txtLinear(Positive)' | translate:language:ModuleNames.SurveyX}}</h5>
        </div>
        <div class="flex flex-row items-stretch bg-white rounded-sm p-1 gap-px w-2/3">
          <div class="w-full flex flex-row items-stretch gap-px relative btn">
            <input #secondLinear type="color" [(ngModel)]="chartColors.linearColors[1]"
              class="absolute opacity-0 w-0">
            <span (click)="secondLinear.click()" [style.background]="color" *ngFor="let color of positiveColors"
              class="w-1/5"></span>
          </div>
        </div>
      </div>
        <!--
        <div class="flex flex-row justify-between items-stretch gap-2">
          <div class="flex flex-row items-center w-1/3 gap-2 btn">
            <i class="fa-regular fa-circle-dot text-exp text-xl"></i>
            <h5 class="text-zen-primarydefault text-sm">{{'txtLinear(Positive)' |
              translate:language:ModuleNames.SurveyX}}</h5>
          </div>
          <div class="flex flex-row items-stretch bg-white rounded-sm p-1 gap-px w-2/3">

            <input *ngFor="let item of dynamicColors" type="color" [(ngModel)]="solidColor"
              class="w-full rounded-sm btn overflow-hidden bg-transparent">
          </div>
        </div>
        <div class="flex flex-row justify-between items-stretch gap-2">
          <div class="flex flex-row items-center w-1/3 gap-2 btn">
            <i class="fa-regular fa-circle-dot text-exp text-xl"></i>
            <h5 class="text-zen-primarydefault text-sm">{{'txtLinear(Negative)' |
              translate:language:ModuleNames.SurveyX}}</h5>
          </div>
          <div class="flex flex-row items-stretch bg-white rounded-sm p-1 gap-px w-2/3">
            <input *ngFor="let item of dynamicColors" type="color" [(ngModel)]="solidColor"
              class="w-full rounded-sm btn overflow-hidden bg-transparent">
          </div>
        </div> -->







      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <a (click)="close.emit(true)" zenButton styles="secondary height-10">{{'btn_cancel'|
      translate:language:ModuleNames.SurveyX}}</a>
    <a (click)="applyChanges()" zenButton styles="exp height-10">{{'txtApplyChanges'|
      translate:language:ModuleNames.SurveyX}}</a>

  </ng-container>
</z-modal>
