import { Component, Input, OnInit } from '@angular/core';
import { AccordionsComponent } from '../accordions.component';

@Component({
  selector: 'zen-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input()
  active: boolean = false;
  @Input() extraClasses;
  @Input()
  title: string;

  constructor(private accordions: AccordionsComponent) { }

  ngOnInit(): void {
  }

  clicked() {
    if (this.active)
      this.accordions.deselectTab(this);
    else
      this.accordions.selectTab(this);
  }

}
