import { updateQuestionHeaderHTML } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";

/**
 * Updates the HTML for a Matrix question.
 * @param options - The options for updating the Matrix question HTML.
 */
export function updateMatrixQuestionHTML(options: any) {
  updateQuestionHeaderHTML(options, "Matrix", "fa-table-list");

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // body.className = body.className + " mt-5";

  //Remove the default question content
  body.innerHTML = "";

  // Create the matrix question content
  var newMatrixQuestionContent = document.createElement("div");
  newMatrixQuestionContent.className = "flex flex-col gap-3 py-4 w-full rank-items-container";

  // Create the matrix question table
  var matrixQuestionTable = document.createElement("table");

  // Create the table header
  var matrixQuestionTableHeader = document.createElement("thead");
  var matrixQuestionTableHeaderRow = document.createElement("tr");

  // Create an empty cell in the header row
  var matrixQuestionTableHeaderRowCell = document.createElement("th");
  matrixQuestionTableHeaderRowCell.className = "matrix-table-header";

  matrixQuestionTableHeaderRow.appendChild(matrixQuestionTableHeaderRowCell);

  // Create the header cells for each column
  for (var i = 0; i < options.question.columns.length; i++) {
    var matrixQuestionTableHeaderRowCell = document.createElement("th");
    matrixQuestionTableHeaderRowCell.className = "sd-table__cell sd-table__cell--header sd-table__cell--header--empty matrix-header-text";
    matrixQuestionTableHeaderRowCell.innerHTML = options.question.columns[i].text;

    matrixQuestionTableHeaderRow.appendChild(matrixQuestionTableHeaderRowCell);
  }

  matrixQuestionTableHeader.appendChild(matrixQuestionTableHeaderRow);

  // Create the table body
  var matrixQuestionTableBody = document.createElement("tbody");

  // Check if it's not a multi-select question
  if (!options.question['isMultiSelect']) {
    // Create rows with radio button cells
    for (var i = 0; i < options.question.rows.length; i++) {
      var matrixQuestionTableBodyRow = document.createElement("tr");
      // Make the table striped
      matrixQuestionTableBodyRow.className = i % 2 == 0 ? "sd-table__row sd-table__row--striped" : "sd-table__row";

      var matrixQuestionTableBodyRowCell = document.createElement("th");
      matrixQuestionTableBodyRowCell.className = "matrix-header-text flex pt-5 pl-3";
      matrixQuestionTableBodyRowCell.innerHTML = options.question.rows[i].text;

      matrixQuestionTableBodyRow.appendChild(matrixQuestionTableBodyRowCell);

      // Create radio button cells for each column
      for (var j = 0; j < options.question.columns.length; j++) {
        var matrixQuestionTableBodyRowCell = document.createElement("td");
        matrixQuestionTableBodyRowCell.className = "sd-table__cell sd-table__cell--header sd-table__cell--header--empty";

        var matrixQuestionTableBodyRowCellInput = document.createElement("input");
        matrixQuestionTableBodyRowCellInput.className = "radio-group-item-radio-button";
        matrixQuestionTableBodyRowCellInput.setAttribute("type", "radio");
        matrixQuestionTableBodyRowCellInput.setAttribute("name", "matrix-" + options.question.id + "-" + i);
        matrixQuestionTableBodyRowCellInput.setAttribute("value", options.question.columns[j].value);
        matrixQuestionTableBodyRowCellInput.disabled = true;

        matrixQuestionTableBodyRowCell.appendChild(matrixQuestionTableBodyRowCellInput);

        matrixQuestionTableBodyRow.appendChild(matrixQuestionTableBodyRowCell);
      }

      matrixQuestionTableBody.appendChild(matrixQuestionTableBodyRow);
    }
  } else {
    // If it's a multi-select question, create checkboxes instead of radio buttons
    for (var i = 0; i < options.question.rows.length; i++) {
      var matrixQuestionTableBodyRow = document.createElement("tr");
      // Make the table striped
      matrixQuestionTableBodyRow.className = i % 2 == 0 ? "sd-table__row sd-table__row--striped" : "sd-table__row";

      var matrixQuestionTableBodyRowCell = document.createElement("th");
      matrixQuestionTableBodyRowCell.className = "matrix-header-text flex pt-5 pl-3";
      matrixQuestionTableBodyRowCell.innerHTML = options.question.rows[i].text;

      matrixQuestionTableBodyRow.appendChild(matrixQuestionTableBodyRowCell);

      // Create checkbox cells for each column
      for (var j = 0; j < options.question.columns.length; j++) {
        var matrixQuestionTableBodyRowCell = document.createElement("td");
        matrixQuestionTableBodyRowCell.className = "sd-table__cell sd-table__cell--header sd-table__cell--header--empty";

        var matrixQuestionTableBodyRowCellInput = document.createElement("input");
        matrixQuestionTableBodyRowCellInput.className = "checkbox";
        matrixQuestionTableBodyRowCellInput.setAttribute("type", "checkbox");
        matrixQuestionTableBodyRowCellInput.setAttribute("name", "matrix-" + options.question.id + "-" + i);
        matrixQuestionTableBodyRowCellInput.setAttribute("value", options.question.columns[j].value);
        matrixQuestionTableBodyRowCellInput.disabled = true;

        matrixQuestionTableBodyRowCell.appendChild(matrixQuestionTableBodyRowCellInput);

        matrixQuestionTableBodyRow.appendChild(matrixQuestionTableBodyRowCell);
      }

      matrixQuestionTableBody.appendChild(matrixQuestionTableBodyRow);
    }
  }

  matrixQuestionTable.appendChild(matrixQuestionTableHeader);
  matrixQuestionTable.appendChild(matrixQuestionTableBody);

  newMatrixQuestionContent.appendChild(matrixQuestionTable);

  body.appendChild(newMatrixQuestionContent);
}
