<div class=" flex flex-row box-border items-center w-full h-110 pt-10 px-10 border-b">
  <div class="flex flex-row w-full justify-between">
      <div class="flex flex-row gap-8 items-center">
          <button class="flex bg-zenMain bg-opacity-10  text-zenMain text-xs font-medium py-2 px-4 rounded items-center" routerLink="/survey-x/respondents">
              <i [ngClass]="language == 'AR' ? 'fa-solid fa-arrow-right-long ml-2' : 'fa-solid fa-arrow-left-long mr-2' " class="fa-solid fa-arrow-left-long mr-2 w-4  fill-current" fill="currentColor"></i>
              {{"txtBacktoRespondents"| translate:language:ModuleNames.SurveyX}}
          </button>


          <h5 [@first] class="text-zen font-medium text-2xl">
              {{respondent?.respondentGeneralInfo?.name}}</h5>
      </div>
      <!-- <div class="flex flex-col">
          <button (click)="openManageSb=true;" class="flex bg-zenMain bg-opacity-10  text-zenMain text-xs font-medium py-2 px-4 rounded items-center">
              <i class="fa-regular fa-gear mr-2 w-3 fill-current" fill="currentColor"></i>
              {{"Manage Active Subscriptions"| translate:language:ModuleNames.SurveyX}}
          </button>
      </div> -->
  </div>

</div>
<div class="zen-main-container flex flex-row items-stretch ">
  <div class="flex flex-col w-full  zen-main-container">
      <div *ngIf="selectedRespondent" class="flex flex-col gap-10 pb-24 relative px-10 pt-10">
          <div class="flex flex-col">
              <div class="flex flex-row gap-5">
                  <div *ngIf="respondent.respondentGeneralInfo.name || respondent.respondentGeneralInfo.email || respondent.respondentGeneralInfo.age ||
                              respondent.respondentGeneralInfo.nationality || respondent.respondentGeneralInfo.gender || respondent.respondentGeneralInfo.jobTitle ||
                              respondent.respondentGeneralInfo.maritalStatus || respondent.respondentGeneralInfo.jobType || respondent.respondentGeneralInfo.phoneNumber"
                              class="flex flex-col w-1/4 h-275 rounded-md	shadow-md bg-white p-6">

                      <div class="flex flex-row justify-between">
                          <h5 class="font-medium text-lg text-zen	">
                              {{"txtRespondentGeneralInfo"| translate:language:ModuleNames.SurveyX}}
                          </h5>
                          <!-- <a class="text-exp btn font-medium text-xs" (click)="openEditGI = true">
                              <i class="fa-regular fa-pen px-1"></i> {{"Edit"| translate:language:ModuleNames.SurveyX}}
                          </a> -->
                      </div>
                      <div class="flex flex-row py-2 px-0.5 gap-3">
                          <div class="flex flex-col gap-1.5">
                              <div *ngIf="respondent.respondentGeneralInfo.name" class="flex flex-row justify-end">
                                  <h6 class="text-zen-blueGray500 text-xs">
                                      {{"txtName:"| translate:language:ModuleNames.SurveyX}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentGeneralInfo.email" class="flex flex-row justify-end">
                                  <h6 class="text-zen-blueGray500 text-xs">
                                      {{"txtEmail:"| translate:language:ModuleNames.SurveyX}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentGeneralInfo.age" class="flex flex-row justify-end">
                                  <h6 class="text-zen-blueGray500 text-xs">
                                      {{"txtAge:"| translate:language:ModuleNames.SurveyX}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentGeneralInfo.nationality" class="flex flex-row justify-end">
                                  <h6 class="text-zen-blueGray500 text-xs">
                                      {{"txtNationality:"| translate:language:ModuleNames.SurveyX}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentGeneralInfo.gender" class="flex flex-row justify-end">
                                  <h6 class="text-zen-blueGray500 text-xs">
                                      {{"txtGender:"| translate:language:ModuleNames.SurveyX}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentGeneralInfo.jobTitle" class="flex flex-row justify-end">
                                <h6 class="text-zen-blueGray500 text-xs">
                                    {{"txtJobTitle:"| translate:language:ModuleNames.SurveyX}}</h6>
                            </div>
                            <div *ngIf="respondent.respondentGeneralInfo.maritalStatus" class="flex flex-row justify-end">
                              <h6 class="text-zen-blueGray500 text-xs">
                                  {{"txtMaritalStatus:"| translate:language:ModuleNames.SurveyX}}</h6>
                          </div>
                          <div *ngIf="respondent.respondentGeneralInfo.jobType" class="flex flex-row justify-end">
                            <h6 class="text-zen-blueGray500 text-xs">
                                {{"txtJobType:"| translate:language:ModuleNames.SurveyX}}</h6>
                        </div>
                          <div *ngIf="respondent.respondentGeneralInfo.department" class="flex flex-row justify-end">
                            <h6 class="text-zen-blueGray500 text-xs">
                                {{"txtDepartment:"| translate:language:ModuleNames.SurveyX}}</h6>
                        </div>
                        <!-- <div class="flex flex-row justify-end">
                          <h6 class="text-zen-blueGray500 text-xs">
                              {{"Contract Type:"| translate:language:ModuleNames.SurveyX}}</h6>
                      </div> -->
                      <div *ngIf="respondent.respondentGeneralInfo.phoneNumber" class="flex flex-row justify-end">
                        <h6 class="text-zen-blueGray500 text-xs">
                            {{"txtPhoneNumber:"| translate:language:ModuleNames.SurveyX}}</h6>
                    </div>
                          </div>
                          <div class="flex flex-col gap-1.5">
                              <div *ngIf="respondent.respondentGeneralInfo.name" class="flex flex-row ">
                                  <h6 class="text-zen-blueGray500 text-xs">{{respondent?.respondentGeneralInfo?.name}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentGeneralInfo.email" class="flex flex-row ">
                                  <h6 class="text-zen-blueGray500 text-xs font-medium">
                                      {{respondent?.respondentGeneralInfo?.email}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentGeneralInfo.age" class="flex flex-row ">
                                  <h6 class="text-zen-blueGray500 text-xs font-medium">
                                      {{respondent?.respondentGeneralInfo?.age}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentGeneralInfo.nationality" class="flex flex-row ">
                                  <h6 class="text-zen-blueGray500 text-xs">{{respondent?.respondentGeneralInfo?.nationality}}
                                  </h6>
                              </div>
                              <div *ngIf="respondent.respondentGeneralInfo.gender" class="flex flex-row ">
                                <h6 class="text-zen-blueGray500 text-xs">{{respondent?.respondentGeneralInfo?.gender}}
                                </h6>
                            </div>
                            <div *ngIf="respondent.respondentGeneralInfo.jobTitle" class="flex flex-row ">
                              <h6 class="text-zen-blueGray500 text-xs">{{respondent?.respondentGeneralInfo?.jobTitle}}
                              </h6>
                          </div>
                          <div *ngIf="respondent.respondentGeneralInfo.maritalStatus" class="flex flex-row ">
                            <h6 class="text-zen-blueGray500 text-xs">{{respondent?.respondentGeneralInfo?.maritalStatus}}
                            </h6>
                        </div>
                        <div *ngIf="respondent.respondentGeneralInfo.jobType" class="flex flex-row ">
                          <h6 class="text-zen-blueGray500 text-xs">{{respondent?.respondentGeneralInfo?.jobType}}
                          </h6>
                      </div>
                        <div *ngIf="respondent.respondentGeneralInfo.department" class="flex flex-row ">
                          <h6 class="text-zen-blueGray500 text-xs">{{respondent?.respondentGeneralInfo?.department}}
                          </h6>
                      </div>
                      <!-- <div class="flex flex-row ">
                        <h6 class="text-zen-blueGray500 text-xs">{{respondent?.respondentGeneralInfo?.contractType}}
                        </h6>
                    </div> -->
                      <div *ngIf="respondent.respondentGeneralInfo.phoneNumber" class="flex flex-row">
                        <h6 class="text-zen-blueGray500 text-xs">
                          {{respondent?.respondentGeneralInfo?.phoneNumber}}
                        </h6>
                    </div>
                    </div>
                    </div>
                    </div>

                  <div *ngIf="respondent.respondentCompanyInfo.companyName || respondent.respondentCompanyInfo.companyIndustry
                  || respondent.respondentCompanyInfo.companySize" class="flex flex-col w-1/4 h-275 rounded-md shadow-md bg-white p-6">
                      <div class="flex flex-row justify-between">
                          <h5 class="font-medium text-lg text-zen	">
                              {{"txtRespondentCompanyInfo"| translate:language:ModuleNames.SurveyX}}
                          </h5>
                          <!-- <a class="text-exp btn font-medium text-xs" (click)="openEditCI = true">
                              <i class="fa-regular fa-pen px-1"></i> {{"Edit"| translate:language:ModuleNames.SurveyX}}
                          </a> -->
                      </div>
                      <div class="flex flex-row py-5 px-0.5 gap-3">
                          <div class="flex flex-col gap-1.5">
                              <div *ngIf="respondent.respondentCompanyInfo.companyName" class="flex flex-row justify-end">
                                  <h6 class="text-zen-blueGray500 text-xs">
                                      {{"txtCompanyName:"| translate:language:ModuleNames.SurveyX}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentCompanyInfo.companyIndustry" class="flex flex-row content-center text-center justify-end">
                                  <h6 class="text-zen-blueGray500 text-xs">
                                      {{"txtCompanyIndustry:"| translate:language:ModuleNames.SurveyX}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentCompanyInfo.companySize" class="flex flex-row justify-end">
                                  <h6 class="text-zen-blueGray500 text-xs">
                                      {{"txtCompanySize:"| translate:language:ModuleNames.SurveyX}}</h6>
                              </div>
                          </div>
                          <div class="flex flex-col gap-1.5">
                              <div *ngIf="respondent.respondentCompanyInfo.companyName" class="flex flex-row ">
                                  <h6 class="text-exp text-xs">{{respondent?.respondentCompanyInfo?.companyName}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentCompanyInfo.companyIndustry" class="flex flex-row ">
                                  <h6 class="text-exp text-xs">
                                      {{respondent?.respondentCompanyInfo?.companyIndustry}}</h6>
                              </div>
                              <div *ngIf="respondent.respondentCompanyInfo.companySize" class="flex flex-row ">
                                  <h6 class="text-exp text-xs">
                                      {{respondent?.respondentCompanyInfo?.companySize}}</h6>
                              </div>
                          </div>
                      </div>
                  </div>


              </div>

          </div>
          <div class="flex flex-col" *ngIf="respondent?.subscriptions">
              <app-respondent-forms-table [type]="'active'" [initiateTableData]="activeForms" [selectedRespondent]="selectedRespondent">
              </app-respondent-forms-table>
          </div>
          <div class="flex flex-col" *ngIf="respondent?.subscriptions">
              <app-respondent-forms-table [type]="'inactive'" [initiateTableData]="inactiveForms" [selectedRespondent]="selectedRespondent">
              </app-respondent-forms-table>
          </div>
      </div>
  </div>
</div>

<!--edit general information-->
<!-- <app-edit-tenant-general-info-view *ngIf="openEditGI" [(open)]="openEditGI" [data]="tenant?.generalInfo" [selectedTenant] = "selectedTenant">
</app-edit-tenant-general-info-view> -->

<!--edit contact information-->
<!-- <app-edit-tenant-contact-info-view *ngIf="openEditCI" [(open)]="openEditCI" [data]="tenant?.contactInfo" [selectedTenant] = "selectedTenant">
</app-edit-tenant-contact-info-view> -->


