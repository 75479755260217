<div *ngIf="question && question.dependencyQuestions?.length" class="flex flex-col w18 h-12 justify-center items-center"
  [ngClass]="{'rotateY180':(language|language)}">
  <svg class="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="18" height="48" viewBox="0 0 18 48" fill="none">
    <path d="M7.15256e-07 24.5H12V23.5H1L1 0H0L7.15256e-07 24.5Z" fill="#AEBBD1" />
    <path d="M18 24L12 30V17.9586L18 24Z" fill="#AEBBD1" />
  </svg>
</div>

<div [ngClass]="{'custom-styles w-full':!export, 'exportChartWidth':export}" class="block bg-white  relative"
  *ngIf="question">

  <!-- Question Header -->
  <div [ngClass]="{'border-t border-r border-l border-zen border-opacity-30 rounded-t-md':export}"
    class="inline-block w-full ">
    <div (click)="logData([question, data])" class="flex flex-row items-center w-full justify-between custom-styles2">

      <!-- Question title -->
      <div class="flex flex-col justify-center items-start gap-1">
        <div class="flex flex-row items-start gap-2 text-sm font-medium">

          <span class="text-zen-primarylight">{{"txtQ" | translate:language:ModuleNames.SurveyX}}{{
            questionNo
            }}.
          </span>

          <div class="flex flex-col">
            <h3 class="text-zen-primarydefault " [matTooltip]="question.title | sjsTranslate:language">
              {{question.title | sjsTranslate:language}}
            </h3>
          </div>
        </div>
      </div>

      <div class="flex flex-row items-start gap-2">


        <!-- <ng-container *ngIf="criteria == '' && surveyCriterias?.length && showGroupBy">
          <div class="flex flex-col h-full justify-center" (click)="logData(question)">
            <z-select [options]="surveyCriterias" (ngModelChange)="onCriteriaChanged($event)"
              [(ngModel)]="criteria" [language]="language">
              <ng-template #valueTemplate let-value>
                {{ ('txtGroupBy'| translate:language:ModuleNames.SurveyX) }}
              </ng-template>
              <ng-template #optionTemplate let-value>
                {{ (value?.name | extract:language) }}
              </ng-template>
            </z-select>
          </div>
        </ng-container> -->
        <!--
        <div class="flex flex-col h-full justify-center relative" *ngIf="surveyCriterias?.length && showGroupBy">
            <z-select  class="w-0" #selector [options]="surveyCriterias"  [trigger]="false"  [multiple]="true"
            [(ngModel)]="exportedCriterias" [language]="language">
            <ng-template #valueTemplate let-value>
             <i class="fa-regular fa-gear text-zen"></i>
            </ng-template>
            <ng-template #optionTemplate let-value>
              {{ (value?.name | extract:language) }}
            </ng-template>
          </z-select>
          <i class="fa-regular fa-gear text-zen btn" (click)="selector.menuOpen = true"></i>
        </div> -->





        <!-- Question Type -->
        <div class="flex flex-row gap-2 items-center justify-center rounded h-8 bg-exp bg-opacity-10 whitespace-nowrap">
          <div [ngClass]="language == 'AR' ? 'pr-2':'pl-2'" class="flex flex-row justify-center items-center">
            <i class="flex fa-regular text-exp text-16 font-normal items-center text-center"
              [ngClass]="getType(question).icon"></i>
          </div>
          <p [ngClass]="language == 'AR' ? 'pl-2':'pr-2'" class="text-12 font-normal text-exp">{{"txt"
            +
            getType(question).text | translate:language:ModuleNames.SurveyX}}</p>
        </div>

        <!-- Filters Dropdown -->


        <i *ngIf="question.type!='datepicker' && question.type!='text' && question.type!='comment' && question.type !='file' && !export"
          (click)="chartOptions = !chartOptions" class="fa-regular fa-filter btn" zenButton
          styles="icon-8 secondary"></i>


        <!-- Actions -->
        <div *ngIf="!export" class="flex flex-row items-start justify-center print:hidden">
          <div [ZMenuTriggerFor]="menu" class="btn flex flex-row justify-center items-center rounded">
            <div class="flex flex-row items-center gap-2">
              <div class="flex flex-row items-center justify-center">
                <i
                  class="flex fa-regular fa-ellipsis text-zen-primarylight pt5 text-base font-normal items-center text-center"></i>

                <z-menu #menu>
                  <menuItems>
                    <ng-container [ngTemplateOutlet]="moreMenuItems"></ng-container>
                  </menuItems>
                </z-menu>

                <ng-template #moreMenuItems>
                  <a MenuItem (click)="downloadExcelFile(question)"><i class="fa-regular fa-table mx-1"></i>{{'txtRaw
                    Data' |
                    translate:language:ModuleNames.SurveyX}}</a>
                  <!-- <a MenuItem (click)="exportItem(printArea)"><i
                                          class="fa-regular fa-download mx-1"></i>{{'Export'|
                                      translate:language:ModuleNames.SurveyX}}</a> -->
                </ng-template>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>


  <!-- Question Body -->

  <ng-container *ngIf="data?.bar?.series.length > 10 && export && criteria != ''; else normalView">
    <div [ngClass]="{'border-r border-l border-zen border-opacity-30 border-b pb-5 rounded-b': export}"
      class="flex flex-col w-full p-6 gap-2 items-stretch justify-center parent_item">
      <div class="flex items-center w-full item">
        <div class="flex flex-row gap-2 items-center">
          <div class="flex flex-row justify-center items-center">
            <i class="fa-regular fa-comment-lines custom-text items-center text-center text-zen-neutral"></i>
          </div>
          <h5 class="text-sm font-normal text-zen-primarylight">
            {{'txtDataComplexityText' |
            translate:language:ModuleNames.SurveyX}}
          </h5>

        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #normalView>
    <div [ngClass]="export?'block max-w-full  relative':'max-w-full block overflow-auto'" class="w-full pb-2">

      <!-- questions with too mucn data -->
      <ng-container *ngIf="export && (question.type == 'ranking'  || question.type == 'matrix')">
        <div [ngClass]="{'border-r border-l border-zen border-opacity-30 border-b pb-5 rounded-b': export}"
          class="flex flex-col w-full p-6 gap-2 items-stretch justify-center parent_item">
          <div class="flex items-center w-full item">
            <div class="flex flex-row gap-2 items-center">
              <div class="flex flex-row justify-center items-center">
                <i class="fa-regular fa-comment-lines custom-text items-center text-center text-zen-neutral"></i>
              </div>
              <h5 class="text-sm font-normal text-zen-primarylight">
                {{"txtThis question contains unique answers for each respondent." |
                translate:language:ModuleNames.SurveyX}}
              </h5>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------UNIQUE QUESTIONS------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <ng-container
        *ngIf="question.type=='text' || question.type=='comment' || question.type =='file' || question.type=='datepicker'">
        <div [ngClass]="{'border-r border-l border-zen border-opacity-30 border-b pb-5 rounded-b': export}"
          class="flex flex-col w-full p-6 gap-2 items-stretch justify-center parent_item">
          <div class="flex items-center w-full item">
            <div class="flex flex-row gap-2 items-center">
              <div class="flex flex-row justify-center items-center">
                <i class="fa-regular fa-comment-lines custom-text items-center text-center text-zen-neutral"></i>
              </div>
              <h5 class="text-sm font-normal text-zen-primarylight">
                {{"txtThis question contains unique answers for each respondent." |
                translate:language:ModuleNames.SurveyX}}
              </h5>
              <div *ngIf="!export" (click)="openSpecialQuestion(question)" class="btn flex flex-col items-start gap-2">
                <div class="flex flex-row items-center p-2 gap-2 rounded">
                  <div class="flex flex-row items-center gap-2">
                    <h5 class="text-sm font-medium items-center text-exp">
                      {{"txtView Answers" | translate:language:ModuleNames.SurveyX}}
                    </h5>
                    <div class="flex flex-row justify-center items-center">
                      <i
                        class="fa-regular fa-external-link text-base font-normal items-center text-center text-exp"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->


      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------RATING------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <ng-container *ngIf="question.type=='rating'">
        <ng-container *ngIf="data?.categories?.length">
          <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
          <!-- ----------------------------------------RATING SCALE (NORMAL WITH COLORS AND LABELS)------------------------------------------------------------------------------------------------------------- -->
          <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
          <ng-container
            *ngIf="question.type == 'rating' && !question.rateType && question.rateType == null && question.ratingLabels">

            <ng-container *ngTemplateOutlet="criteriaTemplate"></ng-container>
            <div class="w-full flex flex-col items-stretch justify-center rounded gap-1"
              [ngClass]="{'border-r border-l border-b border-zen border-opacity-30 rounded-b':export}">
              <div class="gap-1 p-6 flex  flex-row items-center justify-center w-full" *ngIf="criteria  == ''">
                <div *ngFor="let group of question.ratingLabels; let x = index" [style.width.%]="group.min == group.max?  1/question.ratingLabels.length:''"
                  class="flex flex-col rounded gap-2 self-stretch items-center ">
                  <div class="flex flex-1 justify-center items-center gap-2 rounded h-10 max-h-10">
                    <div class="flex flex-row items-start gap-1">
                      <div (click)="openIndividualResponsesModal(question,rateValue, i + 1, group.text)"
                        *ngFor="let rateValue of getRange(group.min,group.max); let x = index"
                        class=" btn flex  flex-row hover:cursor-pointer rounded justify-center gap-1 items-center px-2 h-10"
                        [ngStyle]="{'background-color':group.color? group.color:'#EFF1F6'}">
                        <h5 class="text-sm text-center text-zen-primarylight">
                          {{ rateValue }}
                        </h5>
                        <h5 class=" text-sm text-center hover:cursor-pointer font-medium text-zen-primarylight">
                          ({{getCount(rateValue)?.count}})
                        </h5>
                      </div>

                    </div>
                  </div>
                  <div class="flex flex-row items-center w-max-436 gap-1 text-center">
                    <div class="flex flex-1 flex-row justify-center items-center gap-2 ">
                      <h5 class="text-xs text-zen-primarylight"> {{group.text | sjsTranslate:language}}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngTemplateOutlet="groupingTemplate"></ng-container>
            </div>
          </ng-container>

          <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
          <!-- ----------------------------------------RATING SCALE (WITHOUT LABELS OR COLORS)------------------------------------------------------------------------------------------------------------- -->
          <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
          <ng-container
            *ngIf="question.type == 'rating' && !question.rateType && question.rateType == null && !question.ratingLabels">

            <ng-container *ngTemplateOutlet="criteriaTemplate"></ng-container>
            <div class="flex flex-col w-full rounded gap-2 items-stretch "
              [ngClass]="{'border-r border-l border-b border-zen border-opacity-30 rounded-b':export}">
              <div class="gap-1 p-6 flex  flex-row items-center justify-center w-full" *ngIf="criteria  == ''">
                <div class="flex justify-center items-center gap-2 rounded">
                  <div class="flex flex-row flex-wrap items-start gap-1">
                    <div (click)="openIndividualResponsesModal(question,rateValue, i + 1, '')"
                      *ngFor="let rateValue of getRange(1,(question?.rateMax ?? 5)); let x = index"
                      class="btn flex flex-row rounded hover:cursor-pointer bg-zen-littlegray gap-1 justify-center items-center px-2 h-10">
                      <h5 class="text-sm text-center text-zen-primarylight">
                        {{ rateValue }}
                      </h5>
                      <h5 class="text-sm text-center hover:cursor-pointer font-medium text-zen-primarylight">
                        ({{getCount(rateValue)?.count}})
                      </h5>
                    </div>
                  </div>
                </div>


              </div>

              <ng-container *ngTemplateOutlet="groupingTemplate"></ng-container>
            </div>
          </ng-container>

          <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
          <!-- ----------------------------------------RATING (STARS, HEARTS, THUMBS UP, SMILEYS)------------------------------------------------------------------------------------------------------------- -->
          <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
          <ng-container *ngIf="question.rateType && question.rateType != 'nps' && question.rateType != 'enps'">
            <ng-container *ngTemplateOutlet="criteriaTemplate"></ng-container>
            <div *ngIf="criteria  == ''">
              <ng-container *ngFor="let category of data?.categories;count as c; let chIndex = index">
                <span *ngIf="chIndex % pageLines == 0 && chIndex && export"
                  class="block break-before w-full h-20"></span>
                <div
                  [ngClass]="!export?'':('border-r border-l border-zen border-opacity-30 ') + (chIndex == 0 && data.categories?.length == 1?'border-b pb-5 rounded-b':'') + (!chIndex?'':chIndex % pageLines == 0?' border-t pt-2 rounded-t':(chIndex + 1) % pageLines == 0 || chIndex == data.categories?.length -1?' border-b pb-5 rounded-b':'')">
                  <span *ngIf="!chIndex" class="pt-4 pb-6"></span>


                  <ng-container
                    *ngTemplateOutlet="rowTemplate; context:{$implicit:category, chIndex:chIndex}"></ng-container>


                </div>
              </ng-container>
            </div>

            <ng-container *ngTemplateOutlet="groupingTemplate"></ng-container>

          </ng-container>
        </ng-container>
        <div [ngClass]="{'border-r border-l border-b border-zen border-opacity-30 rounded-b':export}" class="flex flex-row items-center justify-center py-5" *ngIf="!data?.categories?.length">
          <h5 class="text-xs text-zenMain">{{ "txtDataForChart" | translate:language:ModuleNames.SurveyX }}</h5> 
        </div>
      </ng-container>
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <!-- --------------------------------------RATING (NPS & ENPS)------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <ng-container *ngIf="question.rateType == 'enps' || question.rateType == 'nps'">
        <ng-container *ngTemplateOutlet="criteriaTemplate"></ng-container>

        <div class="rate_box flex flex-col w-full rounded gap-2 items-stretch "
          [ngClass]="{'border-r border-l border-b border-zen border-opacity-30 rounded-b':export}">




          <div class="gap-1 p-6 flex  flex-row items-center justify-center w-full" *ngIf="criteria  == ''">
            <div *ngFor="let constraint of constraints" class="flex flex-col gap-2">
              <div class="flex justify-center items-center gap-2 rounded">
                <div class="flex flex-row items-start gap-1">
                  <div (click)="openIndividualResponsesModal(question,rateValue, i + 1, constraint.text)"
                    *ngFor="let rateValue of getRange(constraint.min,constraint.max); let x = index"
                    class="btn flex flex-row rounded hover:cursor-pointer  bg-zen-littlegray gap-1 justify-center items-center px-2 h-10"
                    [ngStyle]="{ 'background-color': constraint.color }">
                    <h5 class="text-sm text-center text-zen-primarylight">
                      {{ rateValue }}
                    </h5>
                    <h5 class="text-sm text-center font-medium hover:cursor-pointer text-zen-primarylight">
                      ({{ getCount(rateValue)?.count}})
                    </h5>
                  </div>
                </div>
              </div>

              <div class="flex flex-row items-center max-w-md gap-1">
                <div class="flex flex-1 flex-row justify-center items-center gap-2">
                  <h5 class="text-xs text-zen-primarylight">{{ constraint.text | translate:language:ModuleNames.SurveyX
                    }}</h5>
                </div>
              </div>

            </div>
          </div>


        </div>
        <ng-container *ngTemplateOutlet="groupingTemplate"></ng-container>

      </ng-container>



      <ng-container
        *ngIf="question.type!='matrix' && question.type != 'rating' && question.type !='ranking' && question.type!='text' && question.type!='datepicker' && question.type!='comment' && question.type !='file' ">
        <div (click)="logData(data)" class="block w-full  parent_item">
          <ng-container *ngIf="data?.categories?.length">
            <ng-container *ngTemplateOutlet="criteriaTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="groupingTemplate"></ng-container>
            <ng-container *ngIf="criteria == ''">
              <ng-container *ngFor="let category of data?.categories;count as c; let chIndex = index">
                <span *ngIf="chIndex % pageLines == 0 && chIndex && export"
                  class="block break-before w-full h-20"></span>
                <div
                  [ngClass]="!export?'':('border-r border-l border-zen border-opacity-30 ') + (chIndex == 0 && data.categories?.length == 1?'border-b pb-5 rounded-b':'') + (!chIndex?'':chIndex % pageLines == 0?' border-t pt-2 rounded-t':(chIndex + 1) % pageLines == 0 || chIndex == data.categories?.length -1?' border-b pb-5 rounded-b':'')">
                  <span *ngIf="!chIndex" class="pt-4 pb-6"></span>
                  <div [ngClass]="{'inline-block':export, 'flex':!export}" class=" w-full item">

                    <ng-container
                      *ngTemplateOutlet="rowTemplate; context:{$implicit:category, chIndex:chIndex}"></ng-container>


                  </div>
                </div>
              </ng-container>
            </ng-container>
            <!-- <div *ngIf="question.otherText" class="flex px-8 pt-2 w-full item">
          <div class="flex w-[20%] text-sm text-zen-primarydefault">
            {{ question.otherText.default }}
          </div>
        </div> -->
          </ng-container>
          <div [ngClass]="{'border-r border-l border-b border-zen border-opacity-30 rounded-b':export}" class="flex flex-row items-center justify-center py-5" *ngIf="!data?.categories?.length">
            <h5 class="text-xs text-zenMain">{{ "txtDataForChart" | translate:language:ModuleNames.SurveyX }}</h5> 
          </div>
        </div>
      </ng-container>

      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------MATRIX------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <div [ngClass]="{'border-r border-l border-zen border-opacity-30 border-b pb-5 rounded-b': export}" class="table"
        *ngIf="question.type == 'matrix' && !export">
        <div class="flex flex-row flex-wrap w-full items-center justify-center  p-6">
          <div class="flex w-full">
            <div [style.width.%]="100/(question.columns.length + 1)"
              class="flex items-center capitalize text-sm text-zen-primarydefault border border-solid border-zen border-opacity-30  px-4">
            </div>
            <div [style.width.%]="100/(question.columns.length + 1)"
              class="flex items-center capitalize text-sm text-center justify-center text-zen-primarydefault border border-solid border-zen border-opacity-30  px-4"
              *ngFor="let col of question.columns">{{col?.text ?? col | sjsTranslate:language}}

            </div>
          </div>
          <div *ngFor="let row of question.rows ;count as c" class="flex w-full">
            <div [style.width.%]="100/(question.columns.length + 1)"
              class="flex items-center text-sm text-zen-primarydefault border border-solid border-zen border-opacity-30 px-4 capitalize">
              {{ row?.text ?? row | sjsTranslate:language }}
            </div>
            <div [style.width.%]="100/(question.columns.length + 1)"
              (click)="openIndividualResponsesModal(question, row, innerCol)"
              class="btn flex  hover:cursor-pointer items-center text-sm text-zen-primarydefault border border-solid border-zen border-opacity-30 justify-center px-2.5 py-2"
              *ngFor="let innerCol of question.columns">
              <ng-container *ngIf="getMatrixData(row?.value ?? row,innerCol?.value ?? innerCol) as matrixData">
                {{matrixData?.count}} ({{
                matrixData?.percentage | number:'1.1-1'
                }}%)
              </ng-container>
            </div>
          </div>
          <!-- <div *ngIf="question.otherText" class="flex px-8 pt-2 w-full item">
        <div class="flex w-[20%] text-sm text-zen-primarydefault">
          {{ question.otherText.default }}
        </div>
      </div> -->
        </div>
      </div>


      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------RANKING------------------------------------------------------------------------------------------------------------- -->
      <!-- ----------------------------------------------------------------------------------------------------------------------------------------------------- -->
      <div [ngClass]="{'border-r border-l border-zen border-opacity-30 border-b pb-5 rounded-b': export}" class="table"
        *ngIf="question.type == 'ranking' &&  !export ">
        <div class="flex flex-row flex-wrap w-full items-center justify-center  p-6">
          <div class="flex w-full">
            <div
              class="flex w-72 items-center capitalize text-sm text-zen-primarydefault border border-solid border-zen border-opacity-30  px-4">
            </div>
            <div class="flex w-full">
              <span
                class="flex w-full items-center text-sm text-zen-primarydefault border border-solid border-zen border-opacity-30 justify-center px-2.5 py-2"
                *ngFor="let choice of question.choices; let x= index;"
                [ngClass]="choice.isNoneItem? getNoneCount(choice.count) : ''">{{"Rank "|
                translate:language:ModuleNames.SurveyX }} {{(x +1)}}</span>
            </div>
          </div>
          <div *ngFor="let choice of question.choices ;count as c" class="flex w-full">
            <div
              class="flex w-72 items-center text-sm text-zen-primarydefault border border-solid border-zen border-opacity-30 px-4 capitalize">
              {{ choice.text ?? choice | sjsTranslate:language }}
            </div>
            <div class="flex w-full">
              <span (click)="openIndividualResponsesModal(question,choice,(r+1).toString())"
                class="btn flex w-full items-center text-sm text-zen-primarydefault border border-solid border-zen border-opacity-30 justify-center px-2.5 py-2"
                *ngFor="let rank of question.choices;let r=index">
                <ng-container *ngIf="getMatrixData(choice.value ?? choice,(r+1).toString()) as rankingData">
                  {{rankingData?.count}} ({{ rankingData?.percentage |
                  number:'1.1-1' }}%)
                </ng-container>
              </span>
            </div>
          </div>

        </div>
      </div>

    </div>

  </ng-template>


</div>


<ng-template #criteriaTemplate>
  <div [@inOutAnimation] *ngIf="chartOptions && !question['disableGrouping']"
    class="flex gap-2 flex-row items-center justify-between py-1.5 px-6 border-zen border-opacity-30 border-b"
    [ngClass]="{'border-r border-l':export}">
    <div class="flex flex-row items-center gap-2">
      <h5 *ngIf="exporting" class="text-zen text-sm">
        <span class="font-semibold">{{'txtGroupBy'| translate:language:ModuleNames.SurveyX}} : </span>
        <span>{{currentCriteria(criteria) | extract:language}}</span>
      </h5>
      <div class="flex flex-col h-full justify-center" (click)="logData(question)" *ngIf="!exporting">
        <z-select [options]="surveyCriterias" (ngModelChange)="onCriteriaChanged($event)" [(ngModel)]="criteria"
          [language]="language">
          <ng-template #valueTemplate let-value>
            {{ criteria? (value?.name | extract:language) : ('txtGroupBy'| translate:language:ModuleNames.SurveyX) }}
          </ng-template>
          <ng-template #optionTemplate let-value>
            {{value?.name | extract:language}}
          </ng-template>
        </z-select>
      </div>
      <i *ngIf="criteria && !exporting" class="fa-regular fa-times minw8" zenButton styles="delete icon-9"
        (click)="onCriteriaChanged('')"></i>
      <ng-container *ngIf="data?.bar?.series.length  <= 10">
        <div [@rowlistAnimation]="data?.bar?.series?.length" class="flex flex-row flex-wrap items-center gap-2 px-2"
          [hidden]="!criteria">
          <h5 (click)="category.hide = !category.hide" [ngClass]="{'opacity-70':category.hide}"
            [style.backgroundColor]="currentColors[cIndex]"
            class="whitespace-nowrap py-1 bg-opacity-10 rounded-r-full rounded-l-full px-2 text-xxs btn text-white"
            *ngFor="let category of data?.bar?.series; let cIndex = index">{{category.name |
            translate:language:ModuleNames.SurveyX}}</h5>
        </div>
      </ng-container>
      <h5 *ngIf="data?.bar?.series.length > 10" class="text-zen text-sm font-medium p-5">{{'txtDataComplexityText' |
        translate:language:ModuleNames.SurveyX}}
      </h5>
    </div>

    <i *ngIf="!exporting" (click)="openChartColors()" class="fa-regular fa-sliders btn minw8" zenButton
      styles="icon-8 secondary"></i>
  </div>
</ng-template>



<ng-template #groupingTemplate>
  <ng-container *ngIf="criteria != ''">
    <ng-container *ngIf="data?.bar?.series.length  <= 10">
      <ng-container *ngFor="let category of data.categories;count as c; let chIndex = index; trackBy:identify">
        <span *ngIf="chIndex % pageLines == 0 && chIndex && export" class="block break-before w-full h-20"></span>
        <div
          [ngClass]="!export?'':('border-r border-l border-zen border-opacity-30 ') + (chIndex == 0 && data.categories?.length == 1?'border-b pb-5 rounded-b':'') + (!chIndex?'':chIndex % pageLines == 0?' border-t pt-2 rounded-t':(chIndex + 1) % pageLines == 0 || chIndex == data.categories?.length -1?' border-b pb-5 rounded-b':'')">
          <span *ngIf="!chIndex" class="pt-4 pb-6"></span>
          <div [ngClass]="{'inline-block':export, 'flex':!export}" class=" w-full item">

            <ng-container *ngTemplateOutlet="rowTemplate; context:{$implicit:category, chIndex:chIndex}"></ng-container>


          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="data?.bar?.series.length > 10">
      <div class="wrapper border-4 border-white">
        <table>
          <thead>
            <tr>
              <th></th>
              <th *ngFor="let col of data.bar.series">
                <h5 class="whitespace-nowrap text-zen text-sm px-1">{{col.name | sjsTranslate:language}}</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let category of data.categories; let i = index">
              <th>
                <h5 class="whitespace-nowrap text-zen text-sm px-1">{{category | sjsTranslate:language}}</h5>
              </th>
              <td *ngFor="let col of data.bar.series">
                <h5 (click)="openIndividualResponsesModal(question, category, col.name)"
                  class="whitespace-nowrap text-zen text-sm px-1 text-center btn">{{col?.counts[i]}} /{{col.data[i] |
                  number:'1.1-1'}}%</h5>
              </td>
            </tr>
          </tbody>
        </table>

      </div>



    </ng-container>
  </ng-container>

</ng-template>

<ng-template #rowTemplate let-category let-chIndex="chIndex">
  <div [ngClass]="{'inline-block':export, 'flex flex-col items-stretch ':!export}" class="w-full my-2 px-6 pt-2 ">
    <div class="flex flex-row  gap-3">
      <div class="w-2/6">
        <h5 *ngIf="(!question.rateType || question.rateType =='nps' || question.rateType =='enps'); else otherView"
          class="text-sm text-zen-primarydefault firstUpper">
          {{category}}</h5>
        <ng-template #otherView>
          <div *ngIf="!question.ratingLabels?.length">
            <div class="flex flex-row gap-1">
              <i *ngFor="let rate of returnRates(1, question?.rateMax ?? 5)"
                [ngClass]="(category | textToNumber) < rate?'fa-regular opacity-60 text-zen':'fa-solid text-exp'"
                class=" {{ getRateIcon(question.rateType) }}  text-lg"></i>
            </div>

          </div>
          <div class="flex flex-col text-zen-primarylight" *ngIf="question.ratingLabels?.length">
            <div class="flex flex-row ">
              <div class="flex flex-row " *ngFor="let ratingLabel of question.ratingLabels">
                <div *ngFor="let rate of returnRates(ratingLabel.min, ratingLabel.max)">
                  <i [style.color]="returnColor(category, rate)"
                    [ngClass]="(category | textToNumber) < rate?'fa-regular opacity-60':'fa-solid'"
                    class=" {{ getRateIcon(question.rateType) }} text-base mr1"></i>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let ratingLabel of question.ratingLabels">
              <h5 *ngIf="(category | textToNumber) >= ratingLabel.min && (category | textToNumber) <= ratingLabel.max">
                {{ratingLabel.text | sjsTranslate:language}}</h5>
            </ng-container>
          </div>
        </ng-template>
      </div>
      <div class="flex-row items-center hover:cursor-pointer truncate"
        [ngClass]="!export?'w-full':data.groupedChart?'w-4/6':'w-3/6'">
        <div
          class="flex flex-row items-stretch w-full bg-zen-background rounded-r-full rounded-l-full overflow-hidden h-5">
          <div [@fullWidthAnimation] (click)="openIndividualResponsesModal(question, category)"
            [style.background]="chartColors.color" class="btn" *ngIf="!data?.groupedChart"
            [style.width.%]="data?.bar?.series[0]?.data[chIndex]">
          </div>
          <ng-container *ngIf="data.groupedChart">
            <ng-container *ngFor="let categorySeries of data?.bar?.series; let catSeriesIndex = index;">
              <span *ngIf="!categorySeries?.hide && catSeriesIndex && categorySeries.data[chIndex]"
                class="border-r border-white"></span>
              <div
                (click)="openIndividualResponsesModal(question, category,categorySeries.name, currentColors[catSeriesIndex])"
                *ngIf="!categorySeries?.hide" [@fullWidthAnimation]
                class="text-white text-center text-xs flex flex-row items-center justify-center w-0 transition-all ease-expo btn"
                [style.background]="currentColors[catSeriesIndex]" [style.width.%]="categorySeries.data[chIndex]">
                <span *ngIf="categorySeries.counts[chIndex]">{{categorySeries.counts[chIndex]}}
                  ({{categorySeries.data[chIndex]
                  | number:'1.1-1' }}%)</span>
              </div>

            </ng-container>
          </ng-container>

        </div>
      </div>
      <div *ngIf="!data.groupedChart" class="text-sm textend text-zen-primarydefault w-1/6 flex flex-row justify-end">
        {{data.pie[chIndex].value1}} ({{ data.pie[chIndex].value |
        number:'1.1-1' }}%)
      </div>
    </div>
  </div>
</ng-template>