import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Entity } from 'src/app/core/data-models/Entity';
import { AnalysisFilterKeys, AnalyticsFilter, AnalyticsService } from '../../../data-access/services/analytics.service';
import { DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
import { CHART_EXPORT_CONFIGURATION, ChartsExportConfigResult, NativeChartExport } from 'src/app/shared/export-components/chart-export-helpers.service';
import { EngagementDriver } from 'src/app/core/data-models/engagement-driver';
import { ExitReportDriverLabelService } from 'src/app/core/services/exit-report-driver-label-service';
import { EmployeeExitLevels } from 'src/app/shared/enums/employee-exit-levels';
import { NativeExportBaseComponent } from 'src/app/shared/native-export-base/native-export-base.component';
import { HierarchyEntity } from 'src/app/core/data-models/hierarcy-entity';
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin } from 'rxjs';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { CommentsService } from '@ex/exit/comments';
import { ChartOptionsService } from 'src/app/shared/chart/chart-options.service';

import { AnalysisService, ChartResponseDto, EnpsResultDto, LeaversAnalysisService, QuestionAnalysisService, SectionAnalysisDto, SectionMeanDto } from '@ex/exit/analysis';
import { CHART_DATA_RESPONSE_STRATEGY, CommonChartFunctions } from 'src/app/core/services/chart-data.service';
import { KeyResolverService } from 'src/app/core/services/key-resolver.service';
import { IKeyName } from 'src/app/core/data-models/ikey-name';
import { distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { SectionDto } from '@ex/exit/survey-templates';
import { EnpsTypes } from '@ex/module/exit/utils/enps-types';
import { QuestionTypes } from 'src/app/shared/questionnaire/questions/question-types';
import { ExitExperienceCodesService } from '@ex/module/exit/data-access/services/exit-experience-codes.service';
import { GlobalStatusService, Status } from 'src/app/core/services/global-status.service';
enum ChartGroup {
  Gender = 'Gender',
  Nationality = 'Nationality',
  Generation = 'Generation',
  AgeGroup = 'AgeGroup',
  TenureGroup = 'TenureGroup',
  Performance = 'PerformanceRate',
  BandLevel = 'BandLevel',
  JobGrade = 'JobGrade',
  SurveyStatus = 'SurveyStatus',
  SeperationType = "SeperationType"
}

export interface ExitReportExportData {
  langauge: string,
  entity: Entity,
  currentViewdEntity: Entity,
  filters: AnalyticsFilter
}

@Component({
  selector: 'app-exit-analysis-report',
  templateUrl: './exit-analysis-report.component.html',
  styleUrls: ['./exit-analysis-report.component.scss']
})
export class ExitAnalysisReportComponent extends NativeExportBaseComponent implements OnInit {
  colors = ['#FF4530', '#FF9600', '#FFC107', "#CDDC39", '#36B37E'];
  secondColors = ['#0082C6', "#009999", '#AAB7CC'];
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();
  breakIndex = 42;
  date: Date = new Date();
  filteredTableOfContent = [];
  exportableCharts: ChartsExportConfigResult;
  tableOfContent;
  EnpsTypes = EnpsTypes;
  sections: SectionMeanDto[];
  filters: AnalyticsFilter;
  exportReport: boolean = true;
  exportOverview: boolean = false;
  exportAnalysis: boolean = false;
  exportQuestions: boolean = false;
  exportWithComments: boolean = false;
  questionAnalysisData;
  selectedSeperationType;
  entity
  dashboardStartDate
  dashboardEndDate
  currentViewdEntity;
  language;
  template;
  sectionCounts;
  questions: SectionMeanDto[]
  factors: SectionAnalysisDto[] | any[];
  enpsScores: EnpsResultDto[] = [];
  leaverExperienceScore;
  netEnpsScore;
  questionAllAnalysis = []
  charts: {
    title: string,
    Title: string,
    DashletType: string,
    type: string,
    group: string,
    data$?: Observable<any>,
    data?: any,
    halfWidth?: boolean,
  }[] = [

    ];

  entityChartColors: { key: any; color: any; }[] = [];


  // chart declaration
  constructor(@Inject(DATA_TOKEN) private data: any, private commentsService: CommentsService, private questionAnalysis: QuestionAnalysisService, private commonChartFunctions: CommonChartFunctions, private analyticsService: AnalyticsService, private analysisService: AnalysisService, private exitReportDriverLabelService: ExitReportDriverLabelService, private exitExperienceCodesService: ExitExperienceCodesService, private leaversAnalysisService: LeaversAnalysisService, private keyResolverService: KeyResolverService, private globalStatusService: GlobalStatusService) {
    super();
  }

  ngOnInit(): void {
    this.entity = this.data.entity;
    this.currentViewdEntity = this.data.currentViewdEntity.entity;
    this.language = this.data.langauge;
    this.template = this.data.template;
    this.filters = this.data.filters;
    // this.filters.survey.sections = this.commonChartFunctions.arrangeFactorOrder(this.filters.survey.sections);
    this.prepareChartConfig();
    this.loadChartData();
    this.fetchQuestionAnalysis();
    this.fetchLeaversExperience();
    this.fetchEnps();
    this.fetchOverallFactorAnalysis();
    this.fetchQuestions();
    // this.getCommentsSection();

  }


  fetchQuestionAnalysis() {
    this.LoaderService.displayLoader();


    const questions = this.filters.survey.sections.filter(x => !x.isFactor).flatMap(x => x.questions).filter(q => [QuestionTypes.MultiOptionMultiAnswer as string, QuestionTypes.MultiOptionSingleAnswer as string].includes(q.questionType));

    questions.forEach(q => {
      q['data$'] = this.analysisService.getQuestionAnalysis(q.key, q.questionType, {
        companyKey: this.filters.companyKey,
        tenantKey: this.filters.tenantKey,
        surveyKey: this.filters.survey.key,
        filters: [...this.analyticsService.mapGlobalFilters(this.filters)],
      })
    })

    combineLatest([...questions.map(x => x['data$'])]).pipe(
      map(data => ({ data, questions }))
    ).subscribe(({ data, questions }) => {

      this.questionAllAnalysis = questions.map((x, i) => {

        data[i]['data'].forEach(item => {
          item.category = this.ExtractPipe.transform(x.questionOptions.find(x => x.key == item.category)?.name, this.language)
        })

        data[i]['drillDown'].forEach(item => {
          item.category = this.ExtractPipe.transform(x.questionOptions.find(x => x.key == item.category)?.name, this.language)
        })

        this.filterTableOfContent();
        const question = {
          Title: this.replaceCompanyToken(this.ExtractPipe.transform(x.name, this.language), this.entity),
          DashletType: 'barH',
          canGroup: false,
          question: {
            canGroup: true,
          },
          DashletChartTypes: [],
          data: CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data[i]['data']).process()
        }

        question.data.drillDown = data[i]['drillDown'].map(x => ({ "ID": x.id, "Name": x.name, "Category": x.category, "Group": x.group, }));

        // question.DashletChartTypes = this.chartOptionsService.getSupportedChartTypes(question, question.data.categories.length, question.data.groupedChart)

        return question;
      })



    })

  }


  fetchLeaversExperience() {
    const subscription = this.analysisService.getLeaverExperience({
      companyKey: this.filters.companyKey,
      tenantKey: this.filters.tenantKey,
      surveyKey: this.filters.survey.key,
      filters: [...this.analyticsService.mapGlobalFilters(this.filters)],
    }).subscribe(response => {
      this.leaverExperienceScore = response;
    })

    this.addSubscription(subscription);
  }

  fetchEnps() {
    const subscription =
      this.analysisService.getEnps({
        companyKey: this.filters.companyKey,
        tenantKey: this.filters.tenantKey,
        surveyKey: this.filters.survey.key,
        filters: [...this.analyticsService.mapGlobalFilters(this.filters)],
      })
        .subscribe(response => {
          const categories = [EnpsTypes.Promoters, EnpsTypes.Passives, EnpsTypes.Detractors];

          this.enpsScores = categories.map(c => {

            return response.find(x => x.category == c) || {
              category: c,
              count: 0,
              percentage: 0
            } as EnpsResultDto

          })

          let scores = [EnpsTypes.Promoters, EnpsTypes.Passives, EnpsTypes.Detractors];

          scores.map(c => {

            response.find(x => x.category == c) || {
              category: c,
              count: 0,
              percentage: 0
            } as EnpsResultDto


            const promoters = this.enpsScores.find(x => x.category == EnpsTypes.Promoters)?.percentage || 0;
            const detractors = this.enpsScores.find(x => x.category == EnpsTypes.Detractors)?.percentage || 0;

            this.netEnpsScore = promoters - detractors;
          })
        })



  }


  fetchOverallFactorAnalysis() {
    this.analysisService.getSectionAnalysis({
      companyKey: this.filters.companyKey,
      tenantKey: this.filters.tenantKey,
      surveyKey: this.filters.survey.key,
      filters: [...this.analyticsService.mapGlobalFilters(this.filters)],
    }
    ).subscribe((response: SectionAnalysisDto[]) => {
      console.log( this.filters.survey);
      this.factors = this.filters.survey.sections.filter(x=> response.find(f=> f.sectionKey == x.key)).map(factor => {
        let innerFactor = response.find(x => x.sectionKey == factor.key);
        innerFactor['name'] = factor?.name;
        return innerFactor;
      });
      this.setExitFactorsLabelByScore();
      this.LoaderService.hideLoader()
    })
  }



  loadChartData() {

    this.charts.forEach(chart => {

      switch (chart.group) {

        case 'overall':
          chart.data$ = this.analysisService.getOverallAnalysis({
            companyKey: this.filters.companyKey,
            tenantKey: this.filters.tenantKey,
            surveyKey: this.filters.survey.key,
            filters: [...this.analyticsService.mapGlobalFilters(this.filters)],
            type: chart.type,
          })
          break;
        case 'SurveyStatus':
          chart.data$ = this.leaversAnalysisService.getStatusCounts({
            companyKey: this.filters.companyKey,
            tenantKey: this.filters.tenantKey,
            surveyKey: this.filters.survey.key,
            filters: [...this.analyticsService.mapGlobalFilters(this.filters)],
            type: chart.type,
          })

          break;
        default:
          chart.data$ = this.leaversAnalysisService.getByGroup(chart.group, {
            companyKey: this.filters.companyKey,
            tenantKey: this.filters.tenantKey,
            surveyKey: this.filters.survey.key,
            filters: [...this.analyticsService.mapGlobalFilters(this.filters)],
            type: chart.type,
          })
      }



    })

    combineLatest([... this.charts.map(x => x.data$)]).subscribe((charts) => {

      this.charts.forEach((chart, i) => {

        if (chart.group == "overall") {
          chart.data = charts[i]
          return;
        }

        this.processChartData(chart, charts[i]);
      })

      this.exportableCharts = CHART_EXPORT_CONFIGURATION.configureExitCharts(this.charts, { chartMax: this.breakIndex, chartsPerPage: 5 }).configure();
      console.log(this.exportableCharts);

      this.LoaderService.hideLoader();
    })

  }

  fullWidthChartCheck(chart, categories) {
    let rowsPerLine = 2;
    if (!chart.fullWidth || categories.find(x => x.length > 15))
      rowsPerLine = 1;
    return rowsPerLine;
  }
  processChartData(chart: { type: string; group: string; data$?: Observable<any>; data?: any; }, data: ChartResponseDto) {

    switch (chart.type) {


      case "ec":



        const extraCriteriaResolver = this.keyResolverService.getResolver("EC");
        this.keyResolverService.setKeyResolver(extraCriteriaResolver);

        data.data = data.data?.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language, chart.group)
          return x;
        })




        break;
      case "entity":
        const entityResolver = this.keyResolverService.getResolver("Entity");
        this.keyResolverService.setKeyResolver(entityResolver);

        data.data = data.data?.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language)
          return x;
        })

        data.drillDown = data.drillDown.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language)
          return x;
        })


        break;
      default:
        const resolver = this.keyResolverService.getResolver(chart.group);
        this.keyResolverService.setKeyResolver(resolver);

        let module = this.ModuleNames.Exit;

        if (chart.group == "SurveyStatus") {
          module = this.ModuleNames.Shared;
          data.data = this.commonChartFunctions.reArrangeArray(data.data, [Status.Completed, Status.Progress, Status.Pending, Status.Expired, Status.Inactive], 'category');
        }


        data.data = data.data.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language, module)
          return x;
        })

        data.drillDown = data.drillDown.map(x => {
          x.category = this.keyResolverService.resolve(x.category, this.language, module)
          return x;
        })


    }

    chart.data = CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data.data).process();
    chart.data.drillDown = data.drillDown.map(x => ({ "ID": x.id, "Name": x.name, "Category": x.category, "Group": x.group, }));
  }


  private prepareChartConfig() {

    this.charts = [
      {
        title: "",
        Title: `${this.localize("txtOverallResponseRate", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: "overall",
        type: "",
        halfWidth: true
      },
      {
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.localize("txt_status", this.language, this.ModuleNames.Exit)}`,
        DashletType: "pie",
        group: "SurveyStatus",
        type: "",
        halfWidth: true
      },
      {
        title: "",
        Title: `${this.localize("txt_resp_by_age_group", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.AgeGroups,
        type: "",
        halfWidth: true
      },
      {
        title: "",
        Title: `${this.localize("txt_resp_by_tenure_group", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.TenureGroups,
        type: "",
        halfWidth: true
      },
      {
        title: "",
        Title: `${this.localize("txt_resp_by_generation", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.Generation,
        type: "",
        halfWidth: true
      },
      {
        title: "",
        Title: `${this.localize("txtRespbygender", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.Gender,
        type: "",
        halfWidth: true
      },

      {
        title: "",
        Title: `${this.localize("txtRespbynationality", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.Nationality,
        type: "",
        halfWidth: true
      },
      {
        title: "",
        Title: `${this.localize("txtRespBySepType", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.SeperationType,
        type: "",
        halfWidth: true
      }
    ]


    if (this.entity?.JobGrades?.length > 0)
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.localize("txt_leaver_job_grade", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.JobGrade,
        type: "",
        halfWidth: true
      });
    if (this.entity?.BandLevels?.length > 0)
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.localize("txt_leaver_job_level", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.BandLevel,
        type: "",
        halfWidth: true
      });
    if (this.entity?.PerformanceRates?.length > 0)
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.localize("txt_leaver_performance_rate", this.language, this.ModuleNames.Exit)}`,
        DashletType: "barV",
        group: AnalysisFilterKeys.PerformanceRating,
        type: "",
        halfWidth: true
      });
    // Get all the Entity Groups
    this.entity?.EntityGroups.filter(x => !x.isCompanyGroup).forEach(entityGroup => {
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.ExtractPipe.transform(entityGroup.name, this.language)}`,
        DashletType: "barV",
        type: "entity",
        group: entityGroup.groupKey,
        halfWidth: true
      });
    });


    // Get all the Metrics
    this.entity?.metrics?.forEach(metric => {
      this.charts.push({
        title: "",
        Title: `${this.localize("txtRespby", this.language, this.ModuleNames.Exit)} ${this.ExtractPipe.transform(metric.name, this.language)}`,
        DashletType: "barV",
        type: "ec",
        group: metric.key,
        halfWidth: true
      });
    });
    console.log(this.charts);

  }
  returnIcon(chart) {
    switch (chart.group) {
      case ChartGroup.Gender:
        return chart.icon = 'fa-regular fa-venus-mars';
      case ChartGroup.Nationality:
        return chart.icon = 'fa-regular fa-flag';
      case ChartGroup.Generation:
        return chart.icon = 'fa-regular fa-people-group';
      case ChartGroup.AgeGroup:
        return chart.image = '/assets/img/svg/age-group.svg';
      case ChartGroup.TenureGroup:
        return chart.image = '/assets/img/svg/tenure.svg';
      case ChartGroup.Performance:
        return chart.icon = 'fa-regular fa-gauge-high';
      case ChartGroup.BandLevel:
        return chart.icon = 'fa-regular fa-sitemap';
      case ChartGroup.JobGrade:
        return chart.image = '/assets/img/svg/Rectangle43.svg';
      case ChartGroup.SurveyStatus:
        return chart.icon = 'fa-regular fa-wave-pulse';

      default:
        return '';
    }

  }


  statusColors(data) {
    return data.map(x => { return this.globalStatusService.returnHexColor(x) });
  }

  setChartColors() {
    this.entityChartColors = [
      { key: this.localize(`txt_${Status.Posted}`, this.language, this.ModuleNames.Exit), color: this.globalStatusService.returnClassColor(Status.Posted) },
      { key: this.localize(`txt_${Status.Pending}`, this.language, this.ModuleNames.Exit), color: this.globalStatusService.returnClassColor(Status.Pending) },
      { key: this.localize(`txt_${Status.Progress}`, this.language, this.ModuleNames.Exit), color: this.globalStatusService.returnClassColor(Status.Progress) },
      { key: this.localize(`txt_${Status.Expired}`, this.language, this.ModuleNames.Exit), color: this.globalStatusService.returnClassColor(Status.Expired) },
      { key: this.localize(`txt_${Status.Inactive}`, this.language, this.ModuleNames.Exit), color: this.globalStatusService.returnClassColor(Status.Inactive) },
      { key: this.localize(`txt_${Status.Completed}`, this.language, this.ModuleNames.Exit), color: this.globalStatusService.returnClassColor(Status.Completed) },
    ]
  }







  fetchQuestions() {
    this.LoaderService.displayLoader();

    const subscription = this.questionAnalysis.get({ companyKey: this.filters.companyKey, surveyKey: this.filters.survey.key, tenantKey: this.filters.tenantKey, filters: [...this.analyticsService.mapGlobalFilters(this.filters)] })
      .subscribe((sections) => {
        this.questions = sections

        this.LoaderService.hideLoader();
      });
    this.addSubscription(subscription);

  }

  resolveChartColor(value: any) {
    return this.exitExperienceCodesService.resolveChartColor(value);
  }

  filterTableOfContent() {
    this.tableOfContent = [];
    this.filteredTableOfContent = [];
    setTimeout(() => {
      this.tableOfContent = [
        {
          dispaly: true,
          title: 'txtIntroduction',
          content: 1,
        },
        {
          id: 'reportReading',
          dispaly: this.exportReport,
          title: 'txtHowtousethisreport',
          content: 1,
        },
        {
          id: 'overview',
          overview: true,
          dispaly: this.exportOverview,
          title: 'txtEmployeeExitGeneralAnalysis',
          content: 1,
          children: [
            {
              title: "Leavers' satisfaction",
              content: 0,
            },
            {
              title: "txtENPS",
              content: 0,
            },
            {
              title: "txtFactorsInfluenced",
              content: this.questionAllAnalysis[0]?.data.categories?.length ? 1 : 0,
              hideContent: this.questionAllAnalysis[0]?.data.categories?.length ? false : true
            },
            {
              title: "txtIndustriesLeftFor",
              content: this.questionAnalysisIndustriesLeftFoPages(),
              hideContent: this.questionAllAnalysis[1]?.data.categories ? false : true
            },

          ]
        },
        {
          id: 'factors',
          dispaly: this.exportReport,
          title: 'txtEmployeeExitFactors',
          content: 1,
          children: [

          ]
        },
        {
          id: 'analysis',
          respondentAnalysis: true,
          dispaly: this.exportAnalysis,
          title: 'txtLeaversAnalysis',
          content: 0,
        },
        {
          id: 'questions',
          dispaly: this.exportQuestions,
          title: 'txt_questions',
          content: 3,
        },

      ];
      let analysisIndex = this.tableOfContent.indexOf(this.tableOfContent.find(x => x.id == "analysis"))
      this.tableOfContent[analysisIndex].content = this.exportableCharts?.pages; // status chart custom add
      let factorsContainer = this.tableOfContent.find(s => s.id == 'factors');
      this.factors.forEach((factor: any, index) => {
        if (!factor?.driverLabel)
          return;
        factorsContainer.children.push({
          title: this.ExtractPipe.transform(factor.name, this.language),
          content: 1,
        },);
      })

      this.filteredTableOfContent = this.tableOfContent.filter(row => row.dispaly);
      let page = 2;
      for (let index = 0; index < this.filteredTableOfContent.length; index++) {
        page = page + this.filteredTableOfContent[index].content;
        this.filteredTableOfContent[index].page = page;
        if (this.filteredTableOfContent[index]?.children?.length) {
          for (let num = 0; num < this.filteredTableOfContent[index].children.length; num++) {
            page = page + this.filteredTableOfContent[index].children[num].content;
            this.filteredTableOfContent[index].children[num].page = page;
          }
        }

      }
      console.log(this.filteredTableOfContent);
    }, 200);
  }

  sectionIndex(e) {
    let index = this.filteredTableOfContent?.find(s => s.id == e);
    return this.filteredTableOfContent?.indexOf(index) + 1;
  }

  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language).replaceAll('[Company]', this.languageService.extractNameLanguage(this.currentViewdEntity?.name, this.language));
  }


  returnColor(i) {
    return this.exitExperienceCodesService.resolveChartClass(i)
  }
  // returnComments(sections) {
  //   return sections.filter(s => this.getSectionCount(s.key));
  // }

  getSectionCount(sectionKey: string) {
    return this.sectionCounts?.sectionCount.find(x => x.key == sectionKey)?.count || 0;
  }

  getQuestionCount(questionKey: string) {
    return this.sectionCounts?.questionCount.find(x => x.key == questionKey)?.count || 0;
  }

  // returnCommentsLength(comments) {
  //   this.sectionCounts?.sectionCount.find(x => x.count)

  // }
  resolveEngagementDriverScoreCategoryName(score) {
    return this.translationPipe.transform(this.exitExperienceCodesService.getExperienceCode(score), this.language, this.ModuleNames.Exit)
  }

  setExitFactorsLabelByScore() {
    this.factors = this.factors.map((driver) => {
      let engagementDriverLabel = this.exitReportDriverLabelService.ExitFactorLabelByScore.find(
        (driverLabel) => {
          return driverLabel.driverKey === driver.sectionKey
        }
      )
      let engagmentDriverScoreCategory = this.exitExperienceCodesService.getExperienceCode(driver.score)

      if (engagementDriverLabel) {
        driver.driverLabel = engagementDriverLabel
        driver.driverLabel.scoreCategory = engagmentDriverScoreCategory
      }


      return driver
    })
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  replaceCompanyToken(text: string, entity: Entity) {
    return text.replace("[CompanyName]", this.ExtractPipe.transform(entity.name, this.language))
  }
  getGenderKey(value) {
    return this.GeneralService.genders_new.find(s => s.name.find(x => x.name == value)).key;
  }
  industriesSectionBreak(c) {
    if (c % 13 == 0 && c <= 13 && c != 0) return 'break-before pt-20';
    else if ((c - 13) % 21 == 0 && c > 13) return 'break-before pt-20';
    else return '';
  }


  questionAnalysisIndustriesLeftFoPages() {
    let pages = this.questionAllAnalysis[1]?.data.categories?.length  ? 1 : 0;
    if (pages && this.questionAllAnalysis[2]?.data.categories.length > 13) {
      let remaining = this.questionAllAnalysis[2]?.data.categories.length - 13;
      pages += Math.ceil(remaining / 21)
    }

    return pages;
  }
}

