<div class="flex flex-col Page">
  <div class="flex flex-row justify-between items-center w-full py-4 rounded-sm gap-5"
    [ngClass]="headerOptionsSelector?.innerHTML.length || options.export?' mb-5':''">
    <div *ngIf="!options?.disableSearch" class="flex flex-col  w-5/12">
      <div
        class="text-sm rounded text-zen border bg-white border-zen border-opacity-25 px-3 h-9 gap-2 leading-9 truncate flex flex-row justify-start items-center">
        <div class="flex flex-row w-full items-center gap-2">
          <i [ngClass]="{'rotate-h-45':dir == 'rtl'}" class="fal fa-search text-zen transform"></i>
          <input [placeholder]="options?.searchText"
            class="bg-transparent overflow-hidden h-12 leading-12 text-sm w-full " [formControl]="searchText"
             type="text">
        </div>
        <!-- <a (click)="fetchSearch(options.request.searchText)"
          *ngIf="options.serverSide && options.request.searchText.length"
          class="text-xs px-1 py-px btn leading-5 rounded-r-full rounded-l-full"
          [ngClass]="options.colors.button">{{options?.searchText}}</a> -->
      </div>
    </div>
    <div class="flex flex-row justify-between items-stretch w-full gap-5">
      <div *ngIf="hasMassDelete && options?.serverSide && data?.length && options.selection"
        class="flex flex-row w-full justify-between items-center gap-5">

        <lottie-player (click)="deleteFromServer(options.request)" *ngIf="currentSelection?.length"
          src="/assets/lottie/delete2.json" background="transparent" speed="1" style="width: 2rem; height: 2rem;"
          class="btn transform scale-75" hover="false" autoplay="false">
        </lottie-player>
      </div>
      <div class="h-12 minw1px bg-zen bg-opacity-20 rounded mx-2"
        *ngIf="headerOptionsSelector?.innerHTML?.length && !options?.disableSearch ? true: false"></div>
      <div class="flex flex-row items-center w-full" #headerOptionsSelector>
        <ng-content select="[headerOptions]"></ng-content>
      </div>
      <div *ngIf="(options?.export || options?.serverExport) && dataSource?.filteredData?.length"
        class="btn flex flex-row gap-2 items-center cancel leading-10 px-3 rounded"
        (click)="options?.serverExport ? serverExportClicked.emit() : exporter.exportTable('xlsx', {fileName:options?.exportName})">
        <i class="fal fa-download"></i>
        <h5 class="">{{options.exportText}}</h5>
      </div>

    </div>

  </div>
  <!-- <button class="hidden" (click)="exporter.exportTable('xlsx')" #exportButton></button> -->
  <div class="overflow-auto max-w-full  bg-transparent  rounded-lg">
    <section-loader *ngIf="showLoader && (!data)"></section-loader>
    <EmptySection
      *ngIf="(data?.length == 0 && !showLoader && options.serverSide) || (options?.data?.length == 0 && !options.serverSide)">
    </EmptySection>
    <table [hidden]="showLoader || data?.length == 0" mat-table matTableExporter #exporter="matTableExporter"
      [ngClass]="options?.colors.button"
      [hiddenColumns]="options.selection?options.customize? [0,displayedColumns.length -1]:[0] :''"
      class="w-full  bg-transparent py-2 relative" [dataSource]="dataSource" style="border-spacing: 0px 2px;">
      <ng-container [matColumnDef]="column.field" *ngFor="let column of displayedColumns; let n = index"
        [stickyEnd]="n == displayedColumns.length-1 && options.sticky">
        <th class="border-b-0  border-transparent border-l-0 border-r-0"
          [ngClass]="{'w-14 minw-14' :options.selection && n == 0 , 'px5':n != 0 && n < displayedColumns.length}"
          mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="options.selection && n == 0">
            <mat-checkbox #checkbox  (change)="$event ? masterToggle(selectedTable, dataSource) : null; selectionChange(); allSelectedUpdate(checkbox.checked)"
              [checked]="masterChecked()"
              [indeterminate]="indeterminateChecked()"
              [aria-label]="checkboxLabel(selectedTable)">
            </mat-checkbox>
          </ng-container>
          <h5 *ngIf="n != displayedColumns.length -1 || (n == displayedColumns.length -1 && !options.customize)"
            class="text-xxs text-zen uppercase font-medium whitespace-nowrap " [ngClass]="{'text-start':n <= 2}">
            {{column.title }}</h5>
          <div *ngIf="n == displayedColumns.length -1 && options.customize"
            class="flex flex-row justify-end items-center">
            <a (click)="customization = true"
              class="text-xxs text-zen uppercase font-medium whitespace-nowrap rounded-sm px-2 btn bg-zen bg-opacity-10 backdrop-filter backdrop-blur-xl">
              <i class="fa-regular fa-cog px-1 leading-6 "></i> <span
                class="px-1 font-medium leading-6 ">{{options.customizeText}}</span></a>
          </div>
        </th>
        <td
          [ngClass]="{ 'w-14' :options.selection && n == 0 , 'px5':n != 0 && n < displayedColumns.length,'text-start':n <= 2 && n != 0}"
          class="border-b-0 border-l-0 border-r-0" mat-cell *matCellDef=" let element; let i = index">
          <mat-checkbox (click)="$event.stopPropagation()"
            *ngIf="options.selection && n == 0  && checkCondition(element)"
            (change)="$event ? handleSingleClick(element):''" [checked]="selectedTable.isSelected(element) || manualSingleCheck(element)"
            [aria-label]="checkboxLabel(selectedTable, element)">
          </mat-checkbox>
          <ng-container *ngTemplateOutlet="mytemplate; context:{$implicit: element, column: column, colIndex:n}">
          </ng-container>
        </td>
      </ng-container>
      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="dataSource?.filteredData?.length == 0" class="mat-cell border-l-0 border-r-0" colspan="999">
          <div class="flex flex-row justify-center items-center w-full">
            <lottie-player [src]="search" background="transparent" speed="1" style="width: 18rem; height: 18rem;" loop
              autoplay></lottie-player>
          </div>

        </td>
      </tr>
      <tr [hidden]="!options.header" class="bg-transparent border-b border-zen border-opacity-10" mat-header-row
        *matHeaderRowDef="getRows(displayedColumns)"></tr>
      <tr class="h-auto border-b border-zen border-opacity-10 transition-all bg-opacity-5 " mat-row
        *matRowDef="let row; columns: getRows(displayedColumns);"
        [ngClass]="(options?.selectionKey && row[options?.selectionKey]) && !options.whiteBackground? options.colors[row[options?.selectionKey]] + ' '+ 'hover:bg-opacity-10' :options.colors.default + ' '+ 'hover:bg-opacity-100' ">
      </tr>
    </table>
  </div>
  <div *ngIf="!options.hidePagination && !options.serverSide"
    class="flex flex-row bg-transparent rounded-md h-14 self-center relative w-full">
    <mat-paginator [ngClass]="dir == 'rtl'?'rtl':''" class="newPagination bg-transparent absolute left-0 " #paginator
      [hidePageSize]="true" [pageSizeOptions]="[pageSize]">
    </mat-paginator>
    <div class=" h-14 flex flex-row bg-transparent justify-center w-full pag-buttons">
      <div (click)="goToChange(0)" class="h-full flex flex-col  justify-center cursor-pointer" *ngIf="pageIndex > 2">
        <a class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
            [ngClass]="dir == 'ltr'?'fa-angle-double-left ':'fa-angle-double-right'"
            class="fal text-zen  text-xl  leading-10 "></i></a>
      </div>
      <div (click)="goToChange(pageIndex - 1)" class="h-full flex flex-col  justify-center cursor-pointer"
        *ngIf="pageIndex > 1">
        <a class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
            [ngClass]="dir == 'ltr'?'fa-angle-left':'fa-angle-right'"
            class="fal  text-zen  text-xl  leading-10 "></i></a>
      </div>
      <div class="h-full flex flex-col  justify-center" *ngFor="let pageNumber of pageNumbers; let i = index">

        <a *ngIf="i == pageIndex ||i+1 == pageIndex ||i-1 == pageIndex  " (click)=" goToChange(i)"
          [ngClass]="i == pageIndex? options.colors.button:'cancel'"
          class="btn  leading-10 w-10 text-center mx-1 rounded-md cursor-pointer text-white">{{pageNumber}}
        </a>

      </div>
      <div (click)="goToChange(pageIndex + 1)" class="h-full flex flex-col  justify-center cursor-pointer"
        *ngIf="pageIndex < pageNumbers?.length-2 ">
        <a class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
            [ngClass]="dir == 'ltr'?'fa-angle-right':'fa-angle-left'"
            class="fal  text-zen  text-xl  leading-10 "></i></a>
      </div>
      <div (click)="goToChange(pageNumbers?.length-1)" class="h-full flex flex-col  justify-center cursor-pointer"
        *ngIf="pageIndex < pageNumbers?.length -3">
        <a class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
            [ngClass]="dir == 'ltr'?'fa-angle-double-right':'fa-angle-double-left'"
            class="fal  text-zen  text-xl  leading-10 "></i></a>
      </div>
    </div>
  </div>
  <div class="flex flex-row" *ngIf="options?.serverSide && data?.length && totalRecords != 0">
    <div class="flex flex-row justify-start w-2/12 items-center">
      <h5 class="text-zen text-xs text-opacity-80"><span>{{(options.request.pageNumber * options.request.pageSize)
          - options.request.pageSize +1| numberComma}}</span> -{{options.request.pageNumber *
        options.request.pageSize > totalRecords? (totalRecords | numberComma): (options.request.pageNumber *
        options.request.pageSize | numberComma)}} {{"txtof" | translate:language:ModuleNames.Shared}}
        {{totalRecords | numberComma}}</h5>
    </div>
    <div class="w-8/12 h-14 flex flex-row bg-transparent justify-center pag-buttons">
      <div class="h-full flex flex-row  justify-center items-center cursor-pointer">

        <a *ngIf="options.request.pageNumber > 2" (click)="fetchIndex(1)"
          class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
            [ngClass]="dir == 'ltr'?'fa-angle-double-left':'fa-angle-double-right'"
            class="fal  text-zen  text-xl  leading-10 "></i></a>

        <a (click)="fetchIndex(options.request.pageNumber - 1)" *ngIf="options.request.pageNumber > 1"
          class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
            [ngClass]="dir == 'ltr'?'fa-angle-left':'fa-angle-right'"
            class="fal  text-zen  text-xl  leading-10 "></i></a>
      </div>
      <div class="h-full flex flex-row gap-1 justify-center items-center">
        <a *ngIf="options.request.pageNumber > 1" (click)="fetchIndex(options.request.pageNumber - 1)"
          class="btn cancel leading-10 px-3  text-center mx-1 rounded-md cursor-pointer text-white">{{options.request.pageNumber
          -1}}
        </a>
        <a (click)="fetchIndex(options.request.pageNumber)" [ngClass]="options.colors.button"
          class="btn  leading-10 px-3  text-center mx-1 rounded-md cursor-pointer text-white">{{options.request.pageNumber}}
        </a>
        <a *ngIf="(options.request.pageSize * (options.request.pageNumber +1)) <  totalRecords + options.request.pageSize "
          (click)="fetchIndex(options.request.pageNumber + 1)"
          class="btn cancel leading-10  px-3 text-center mx-1 rounded-md cursor-pointer text-white">{{options.request.pageNumber
          +1}}
        </a>
      </div>
      <div class="h-full flex flex-row items-center justify-center cursor-pointer">
        <a *ngIf="(options.request.pageSize * (options.request.pageNumber +1)) <  totalRecords + options.request.pageSize  "
          (click)="fetchIndex(options.request.pageNumber + 1)"
          class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
            [ngClass]="dir == 'ltr'?'fa-angle-right':'fa-angle-left'"
            class="fal  text-zen  text-xl  leading-10 "></i></a>

        <a *ngIf="(options.request.pageSize * (options.request.pageNumber +2)) <  totalRecords + options.request.pageSize  "
          (click)="fetchIndex('last')" class="btn  leading-10 w-10 text-center mx-1 rounded-md "> <i
            [ngClass]="dir == 'ltr'?'fa-angle-double-right':'fa-angle-double-left'"
            class="fal  text-zen  text-xl  leading-10 "></i></a>
      </div>

    </div>
    <div class="flex flex-row justify-end w-2/12 items-center">
      <z-select [color]="'exp'" [(ngModel)]="options.request.pageSize" (selectionChange)="fetchNewSize($event)"
        [options]="pageLengths" [language]="language">
        <ng-template #valueTemplate let-value>
          {{value?.name}}
        </ng-template>
      </z-select>
    </div>
  </div>
</div>

<div *ngIf="customization"
  class="w-screen h-screen bg-zen bg-opacity-50 flex flex-row items-center justify-center fixed top-0 left-0 z-50">
  <div class="max-w-lg w-full flex flex-col bg-white bg-opacity-90 py-10 rounded shadow-2x6 gap-12">
    <div class="flex flex-row items-center justify-between px-10">
      <h2 class="text-zen text-2xl font-semibold">{{'txtCustomizeTable'|
        translate:language:ModuleNames.Shared}}</h2>
      <i (click)="customization = false"
        class="fa-regular fa-times leading-12  btn text-center text-xl text-zen text-opacity-70"></i>
    </div>
    <div class="flex flex-col gap-1 px-10">
      <ng-content select="[customization]"></ng-content>
    </div>
    <div class="flex flex-row justify-between items-center px-10">
      <a (click)="customization = false"
        class="btn leading-10 rounded-sm cancel px-5 text-sm font-medium">{{'btn_cancel'|
        translate:language:ModuleNames.Shared}}</a>
      <a (click)="columnSelection.emit(); customization = false"
        class="btn leading-10 rounded-sm exp px-5 text-sm font-medium">{{'txtApplyChanges'|
        translate:language:ModuleNames.Shared}}</a>
    </div>
  </div>
</div>
