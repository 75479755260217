import { Direction } from "@angular/cdk/bidi";
import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoaderService } from "@ex/module/core/services/loader.service";
import { ChildrenAnimation } from "@ex/module/shared/animations/allAnimations";
import { ModuleName } from "@ex/module/shared/enums/ModuleName";
import { TranslatePipe } from "@ex/module/shared/pipes/translate.pipe";
import { LanguageFilterService } from "@ex/module/survey-x/data-access/services/language-filter.service";
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";


import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { Model, Question, Serializer } from 'survey-core';
import { CustomProperty, QuestionnaireDto, QuestionnaireService, StatusTypes, SurveyCriteria } from "@ex/survey-x/index";
import { SurveyStages } from "@ex/module/core/data-models/survey-stages";
import { Status } from "@ex/module/core/services/global-status.service";
import { catchError, map, switchMap } from "rxjs/operators";
import { EntityService } from "@ex/module/core/services/entity.service";
import { Entity } from "@ex/module/core/data-models/Entity";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EngagementService } from "@ex/module/core/services/engagement.service";
import { RespondentService } from "@ex/module/core/services/respondent.service";
import { EngagementRespondent } from "@ex/module/core/data-models/engagement-respondent";
import { CustomPropertyTypes } from "@ex/module/core/domain/custom-property-types";
import { LanguagePipe } from "@ex/module/shared/pipes/language.pipe";
import { BaseComponent } from "@ex/module/core/domain/base-component";
import { CriteriasService } from "@ex/module/survey-x/data-access/services/criterias.service";
import { updateDropdownQuestionHTML, updateTagboxQuestionHTML } from "../../ui/questions/dropdown-operations/dropdown-question-operations";
import { updateFileUploadQuestionHTML } from "../../ui/questions/file-upload-operations/file-upload-question-operations";
import { updateIconChoiceQuestionHTML, updateImagePickerQuestionHTML } from "../../ui/questions/image-choice-operations/image-choice-question-operations";
import { updateMatrixQuestionHTML, updateMatrixCheckboxsQuestionHTML } from "../../ui/questions/matrix-operations/matrix-question-operations";
import { updateRadioGroupQuestionHTML, updateCheckBoxsQuestionHTML } from "../../ui/questions/multiple-choices-operations/multiple-choices-questions-operations";
import { updateENPSQuestionHTML } from "../../ui/questions/nps-operations/nps-questions-operations";
import { updateRankingQuestionHTML } from "../../ui/questions/ranking-operations/ranking-question-operations";
import { updateRatingScaleQuestionHTML } from "../../ui/questions/rating-scale-operations/rating-scale-question-operations";
import { updateLongTextQuestionHTML, updateBooleanQuestionHTML, updateNumberInputQuestionHTML, updateEmailInputQuestionHTML, updateDateInputQuestionHTML, updateSliderQuestionHTML, updateShortTextQuestionHTML } from "../../ui/questions/simple-questions-operations/simple-questions-operations";
import { updateRatingStarsQuestionHTML } from "../../ui/questions/start-rating-operations/star-rating-question-operations";


var locale: any;
var ogPages = [];
const translationPipe: TranslatePipe = new TranslatePipe();
Serializer.addProperty("survey", "progressTitle");

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [ChildrenAnimation]
})
export class SurveyComponent extends BaseComponent implements OnInit {

  survey: QuestionnaireDto = {};
  surveyModel: Model;
  model: any;

  form: any;

  sections: any;

  tenant: any;
  tenantKey: any;
  companyName: any;
  currentOptions: any;

  isConfirmSubmissionModalOpen: boolean = false;
  loading: boolean = false;

  subscriptions = new Subscription();
  textPosition = "";
  language = 'EN';
  ModuleNames = ModuleName;
  endPoint = environment.endPoint;
  currentAnswers: any
  currentPage: any;
  SurveyStages = SurveyStages;
  stage = null;
  status = Status;
  entities: Entity[];
  companyKey: string;
  surveyCriterias: any;
  isPredefinedMetric: any;
  respondent: any;
  predefinedMetrics: any;
  dob: any;
  jod: any;
  predefinedMetricsDone: boolean;
  token: string;
  state: any;
  customProperties: CustomProperty[];

  constructor(
    private surveysService: SurveysService,
    private route: ActivatedRoute, private questionnaireService: QuestionnaireService
    , private router: Router
    , private entityService: EntityService
    , public loader: LoaderService
    , private _snackBar: MatSnackBar
    , private translationPipe: TranslatePipe
    , private engagementService: EngagementService
    , private respondentService: RespondentService
    , private languagePipe: LanguagePipe
  ) { super() }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('refKey');
    let localLanguage = this.LocalService.getJsonValue('language');
    let sessionLanguage = sessionStorage.getItem('sessionLanguage');
    if (!sessionLanguage)
      if (localLanguage) {
        this.language = localLanguage;
      } else {
        this.language = 'EN';
        sessionStorage.setItem('sessionLanguage', this.language);

      }
    else this.language = sessionLanguage;

    this.questionnaireService.getQuestionnaire(this.token).pipe(
      switchMap((form: QuestionnaireDto) => { debugger

        return this.entityService.fetchEntitiesOnce(form.tenantKey).pipe(
          map(entities => { return ({ form, entities }) })
        )

      }),
      switchMap(data => {
        debugger
        return this.respondentService.fetchPoolRespondent({
          TenantKey: data.form.tenantKey,
          CompanyKey: data.form.companyKey,
          FilterByMetrics: [{ key: "Email", value: data.form.respondentEmail }],
        }).pipe(map(respondent => {
          return ({ ...data, resp: respondent })
        }))
      }),

    ).subscribe(({ form, entities, resp }) => {
      this.survey = form;
      this.entities = entities;
      this.tenantKey = this.survey.tenantKey;
      this.companyKey = this.survey.companyKey;

      this.respondent = resp['Data'] && resp['Data'].length ? resp['Data'][0] : null;
      this.customProperties = form.customProperties;
      if (this.survey.isGccCountries) {
        form.selectedCriterias = form.selectedCriterias.map((x: any) => {
          if (x.key == 'location' || x.key == 'preferred-country-of-work')
            x.options = this.GeneralService.gccCountries_new.map(c => {
              return {
                key: c.key,
                name: c.name
              }
            })
          return x;
        }) as any
      }
      this.surveyCriterias = form.selectedCriterias;
      this.isPredefinedMetric = form?.predefinedMetrics
      this.predefinedMetrics = this.respondent?.Metrics;
      this.changeColor(this.survey.colorTheme);

      // Populate the survey criterias is existing values in custom properties
      if (this.customProperties.length) {
        this.prefillExistingSurveyCriteriaValues(this.customProperties);
      }

      if (this.respondent && this.customProperties.length == 0)
        this.setUpMetrics();

      if (form.statusType != StatusTypes.Live) {
        this.router.navigate(['/survey-x-questionnaire/survey-closed']);
      }
      if (form.respondentStatus != this.status.Pending && form.respondentStatus != this.status.Progress){
        this.router.navigate(['/survey-x-questionnaire/survey-closed']);

      }



      let modelToShuffle = new Model(this.survey.jsonText)
      if (modelToShuffle['shuffleQuestions'] == true) {
        modelToShuffle.questionsOrder = "random";
        this.survey.jsonText = JSON.stringify(modelToShuffle);
      }

      let companyName = JSON.parse(this.survey.companyName)
      this.companyName = this.ExtractPipe.transform(companyName, this.language);
      //  this.surveyCriterias = this.survey.selectedCriterias;
      this.surveyModel = new Model(this.survey.jsonText);
      let surveyModelCopy = new Model(this.survey.jsonText);
      if (this.survey.isWelcomeMessage)
        this.stage = SurveyStages.Initial;
      else this.startSurvey();

      locale = this.language;
      this.surveyModel.onValidatedErrorsOnCurrentPage.add((sender, options) => {
        if (options['errors'][0]) {
          let textError = options['errors'][0]?.text || options['errors'][0]['locTextValue'].text;
          if (textError.slice(0, 1) === '.')
            textError = textError.slice(1);
          if (textError.slice(textError.length - 1, textError.length) === '.')
            textError = textError.slice(0, textError.length - 1);
          if ((locale == 'en' && options['errors'][0]['locTextValue'].locale != 'default') || (options['errors'][0]['locTextValue'].locale != locale))
            return this.SnackbarService.error(this.localize(textError, this.language, this.ModuleNames.SurveyX));
          return this.SnackbarService.error(textError);
        }
      })
      this.currentAnswers = this.surveyModel?.data;
      this.currentPage = this.surveyModel?.currentPageNo;
      this.sections = surveyModelCopy.pages;
      this.setupSurveyModel(this.currentAnswers, this.currentPage, this.sections, this.language.toLowerCase());

    }, err => {
      this.router.navigate(['/survey-x-questionnaire/survey-closed']);
    })









    // this.form = await this.surveysService.getSurveyWithoutResponses(surveyId);
    // this.survey = this.form.query;

    // this.surveyModel = new Model(this.survey.jsonText);

    // this.sections = this.form.sections;

    // this.tenant = this.survey.tenant;
    // this.tenantKey = this.survey.tenantKey;
    // this.companyName = this.survey.tenantName;

    // if (this.survey.statusType == 2) {
    //   this.changeColor(this.survey.colorTheme);

    //   this.languageInStorage = window.localStorage.getItem("localeLanguage")
    //   if (this.languageInStorage == this.ar) {
    //     this.languageFilterService.setSurveyLanguage(this.ar);
    //   }
    //   else {
    //     this.languageFilterService.setSurveyLanguage(this.languageInStorage);
    //   }

    //   // this.setupSurveyModel();

    //   this.languageFilterService.selectedSurveyLanguage.subscribe((value: any) => {

    //     this.languageInStorage = value;
    //     this.language = value;
    //     this.survey.locale = value;
    //     if (this.language == this.ar) {
    //       this.direction = "rtl";
    //       this.language = "AR";
    //       this.textPosition = "right"
    //       this.survey.locale = this.languageInStorage;
    //     }
    //     else {
    //       this.direction = "ltr";
    //       this.textPosition = "left"

    //       this.survey.jsonText = this.survey.jsonText.replace('"locale": "ar",', '"locale": "default",');

    //       if (this.language == "en") {
    //         this.language = "EN";
    //       }
    //       this.survey.locale = this.languageInStorage;

    //     }
    //     this.currentAnswers = this.surveyModel?.data;
    //     this.currentPage = this.surveyModel?.currentPageNo;

    //   })
    //   this.setupSurveyModel(this.currentAnswers, this.currentPage, this.sections, this.languageInStorage);

    //   // this.languageFilterService.selectedSurveyLanguage.subscribe((value) => {
    //   //   this.language = value;
    //   //   this.survey.locale = value;

    //   //   this.surveyLanguage = this.language;
    //   //   this.survey.locale = this.language;

    //   //   this.textPosition = "left"
    //   //   if (this.surveyLanguage == "ar") {
    //   //     this.direction = "rtl"
    //   //     this.textPosition = "right"
    //   //   }
    //   //   else {
    //   //     this.direction = "ltr"
    //   //     this.textPosition = "left"
    //   //     this.survey.jsonText = this.survey.jsonText.replace('"locale": "ar",', '"locale": "default",');
    //   //   }

    //   //   var currentAnswers = this.surveyModel.data;
    //   //   var currentPage = this.surveyModel.currentPageNo;
    //   //   this.setupSurveyModel(currentAnswers, currentPage);
    //   // });

    // }

    // if (this.survey.statusType != 2) {
    //   this.subscriptions.unsubscribe();
    //   this.router.navigate(['survey-closed']);
    // }

    // this.subscriptions.add(this.survey)

    // this.loader.hideLoader();
  }

  setUpMetrics() {


    // this.engagementMetrics.unshift(genderMetric);
    // this.engagementMetrics.unshift(nationalityMetric);
    // this.engagementMetrics.unshift(dateOfBirthMetric);
    // this.engagementMetrics.unshift(joiningDateMetric);



    this.surveyCriterias.find(m => m.required = true);

    for (let i = 0; i < this.surveyCriterias.length; i++) {
      let metric = this.surveyCriterias[i];
      // if (!metric.required) {
      let newMetric = {} as SurveyCriteria;
      newMetric.value = '' as any;
      newMetric.isEntity = metric.isEntity;
      newMetric.key = metric.key;
      newMetric.name = metric.name;
      newMetric.isCustom = metric.isCustom;
      if (metric.key != 'JOD' && metric.key != 'DOB')
        newMetric.type = 'DROPDOWN';
      else
        newMetric.type = 'DATE';
      newMetric.required = true;
      if (metric.isEntity) {
        let entitiesInEntityGroup = this.entities.filter(e => e.entityGroupKey == metric.key);
        for (let x = 0; x < entitiesInEntityGroup.length; x++) {
          let entity = entitiesInEntityGroup[x];
          let parentEntityName: any[] = [];
          if (entity.parentKey) {
            let parentObject = this.entities.find(e => e.key == entity.parentKey);
            if (parentObject)
              parentEntityName = parentObject.name;
          }
          let option = { key: entity.key, name: entity.name, parentEntityName: parentEntityName };
          if (!newMetric.options)
            newMetric.options = [];
          newMetric.options.push(option);
        }
        // } else {
        //   newMetric.options = metric.options;
        // }
        this.surveyCriterias[i] = newMetric;
      } else {
        this.surveyCriterias[i].required = true;
      }
      if (!this.surveyCriterias[i].options)
        this.surveyCriterias[i].options = null;
    }

    for (let i = 0; i < this.surveyCriterias.length; i++) {
      let metric = this.surveyCriterias[i];
      let predefinedMetric = this.predefinedMetrics.find(mt => mt.key == metric.key);
      if (predefinedMetric) {
        if (metric.isEntity)
          this.entityGroupChanged(predefinedMetric.value, metric.key);
        metric.value = predefinedMetric.value;

        if (predefinedMetric.key == 'DOB')
          metric.placeholderValue = this.dob;
        if (predefinedMetric.key == 'JOD')
          metric.placeholderValue = this.jod;
      }
    }
    console.log('METRIC: ')
    console.log(this.surveyCriterias);
    this.predefinedMetricsDone = true;

  }

  eraseValuesForEntityGroups(groupKey) {


    let selectedEntity = this.entities.find(e => e.key == this.companyKey);
    let entityGroups = selectedEntity?.EntityGroups;
    let reportingEntityGroups = entityGroups.filter(eg => eg.parentKey == groupKey);
    for (let i = 0; i < reportingEntityGroups.length; i++) {
      let eG = reportingEntityGroups[i];
      let eM = this.surveyCriterias.find(m => m.key == eG.groupKey);
      if (eM) {
        eM.value = "";
        this.entityGroupChanged('', eG.groupKey);
      }
    }
  }

  entityGroupChanged(event, groupKey) {
    let selectedEntity = this.entities.find(e => e.key == this.companyKey);
    let entityGroups = selectedEntity?.EntityGroups;
    let reportingEntityGroups = entityGroups.filter(eg => eg.parentKey == groupKey);

    this.eraseValuesForEntityGroups(groupKey);
    for (let x = 0; x < reportingEntityGroups.length; x++) {

      let eG = reportingEntityGroups[x];
      let eM = this.surveyCriterias.find(m => m.key == eG.groupKey);
      if (eM) {
        eM.options = [];
        let entitiesInEntityGroup = this.entities.filter(e => e.entityGroupKey == eG.groupKey && e.parentKey == event);
        if (entitiesInEntityGroup.length > 0) {
          for (let i = 0; i < entitiesInEntityGroup.length; i++) {
            let entity = entitiesInEntityGroup[i];

            let parentEntityName: any[] = [];
            if (entity.parentKey) {
              let parentObject = this.entities.find(e => e.key == entity.parentKey);
              if (parentObject)
                parentEntityName = parentObject.name;
            }
            let option = { key: entity.key, name: entity.name, parentEntityName: parentEntityName };
            eM.options.push(option);
          }
          eM.required = true;
        } else {
          eM.required = false;
        }

      }
    }


  }

  prefillExistingSurveyCriteriaValues(customProperties: CustomProperty[]) {

    for (let criteria of this.surveyCriterias) {

      switch (criteria.key) {
        case 'DOB':
        case 'JOD':
          criteria.value = customProperties.find(x => x.key == criteria.key)?.value
          break;
        default:
          criteria.value = customProperties.find(x => x.key == criteria.key)?.value ?? ''
      }

    }

  }

  dataCollected() {
    try {
      console.log(this.surveyCriterias);

      let isValid = true;
      let filteredMetrics = this.surveyCriterias.filter(m => m.required == true);

      for (let i = 0; i < filteredMetrics.length; i++) {
        let fm = filteredMetrics[i];

        if (!fm.isEntity) {
          if (!fm.value || fm.value == undefined || fm.value == null) {
            if (!this.isPredefinedMetric) {
              isValid = false
              return this._snackBar.open(this.translationPipe.transform("txt_all_questions_are_required", this.language, this.ModuleNames.Surveys),
                this.translationPipe.transform("txt_close", this.language, this.ModuleNames.Surveys), {
                duration: 5000
              });
            }
          }
        } else {
          if (!fm.value) {

            let canSkip: boolean = false;
            if (fm.options.length > 0) {

              let selectedEntity = this.entities.find(e => e.key == this.companyKey);
              let entityGroups = selectedEntity?.EntityGroups;
              let currentGroup = entityGroups.find(eg => eg.groupKey == fm.key);
              if (currentGroup) {
                let parentKey = currentGroup.parentKey;
                let childEntityGroups = entityGroups.filter(eg => eg.parentKey == parentKey);
                for (let x = 0; x < childEntityGroups.length; x++) {
                  let childGroup = childEntityGroups[x];
                  let metricObject = this.surveyCriterias.find(m => m.key == childGroup.groupKey);
                  if (metricObject) {
                    if (metricObject.value && metricObject.value != "")
                      canSkip = true;
                  }
                }

              }
            } else {
              canSkip = true;
            }

            if (!canSkip && !this.isPredefinedMetric) {
              isValid = false
              return this._snackBar.open(this.translationPipe.transform("txt_all_questions_are_required", this.language, this.ModuleNames.Surveys),
                this.translationPipe.transform("txt_close", this.language, this.ModuleNames.Surveys), {
                duration: 5000
              });
            }

          }


        }
      }

      let dobPlcHolder = this.surveyCriterias.find((mt) => mt.key == 'DOB');
      if (dobPlcHolder)
        dobPlcHolder.placeholderValue = null;

      let jodPlcHolder = this.surveyCriterias.find((mt) => mt.key == 'JOD');
      if (jodPlcHolder)
        jodPlcHolder.placeholderValue = null;

      if (this.surveyCriterias.length > 0 && this.respondent) {
        if (this.surveyCriterias.length > 0)
          this.respondent.Metrics = JSON.parse(JSON.stringify(this.surveyCriterias));
        this.respondent.Metrics.find(m => {
          if (m?.options)
            m.options = null;
        });
        this.respondent.Metrics.map(x => {
          x.required = true;
          return x;
        });

      }



      console.log(this.surveyCriterias);
      // console.log(this.respondentPoolKey);

      // this.updateCompanyStructureUsage();
      let resps: EngagementRespondent[] = [];

      if (this.respondent)
        resps.push(this.respondent)

      this.engagementService.updateWellbeingRespondent(this.tenantKey, this.companyKey, '', resps)
        .pipe(switchMap(resp => {
          return this.questionnaireService.updateAnalyticalCriteria(
            this.token,
            this.surveyCriterias.filter(c => c.value != "" && c.value != null).map(x => ({ key: x.key, value: x.value, type: x.isEntity ? CustomPropertyTypes.Entity : x.type } as CustomProperty))
          )
        }))
        .subscribe((response: any) => {
          this.languagePipe.transform(this.language) ? this.state-- : this.state++;
          this.stage = SurveyStages.Started;
          this.fetchCompany();
        });

    } catch (ex) {
      // this.errorHandlerService.submitErrorMessage(1810, ex.toString(), 'NOT AVAILABLE HERE', 'NOT AVAILABLE HERE', 'NOT AVAILABLE HERE',
      //   'NOT AVAILABLE HERE', 'Questionnaire Reference Key: ' + this.referenceKey);
    }
  }

  company: Entity;
  fetchCompany() {
    this.subscriptions.add(this.entityService.fetchEntity(this.tenantKey, this.companyKey).subscribe((entity: Entity) => {
      this.company = entity;
      //this.fetchQuestionnaire();
    }));
  }

  currentQuestion: any;
  currentQuestionOptions: any;
  setupSurveyModel(currentAnswers: any = null, currentPage: any = null, sections: any = null, currentLocale: any = null) {
    this.surveyModel.getAllQuestions().forEach((question) => {
      // if (question.getType() === "rating" && (question['rateType'] == "enps" || question['rateType'] == "nps")) {
      //   this.updateNPSCompanyName(question);
      // }
    });


    // this.surveyModel.onValueChanging.add((sender, options) => {
    //   if (options.question['errors'][0]?.visible)
    //     this.SnackbarService.error(options.question.errors[0].text);
    // })

    this.surveyModel.showCompletedPage = false;
    this.surveyModel.questionsOnPageMode = "standard";
    this.surveyModel.showNavigationButtons = false;
    this.surveyModel.showTitle = false;
    this.surveyModel.locale = currentLocale;
    this.surveyModel.logoPosition = "none"
    this.surveyModel.showPageNumbers = false;
    this.surveyModel.showPageTitles = false;
    this.surveyModel.storeOthersAsComment = true;
    this.surveyModel.questionsOnPageMode = this.survey['questionsOnPageMode'];
    this.surveyModel.questionsOnPageMode = this.survey.isQuestionPerPage ? "questionPerPage" : "standard";
    ogPages = this.surveyModel.visiblePages;

    this.surveyModel.onComplete.add((sender, options) => {
      console.log(JSON.stringify(sender.data, null, 3))
      this.submitResults(sender);
    });


    let self = this;
    this.surveyModel.onAfterRenderQuestion.add(function (survey, options) {

      self.currentQuestionOptions = options;
      self.currentQuestion = options.question;
      self.surveyModel.focusQuestion(self.currentQuestion.name);
      updateQuestionHeaderHTML(options, sections, survey.locale || locale.toLowerCase());
      updateQuestionBodyHTML(options, survey.locale || locale.toLowerCase());
      validateCommentBoxLength(options, survey.locale || locale.toLowerCase());

    });

    // this.onlanguageChanged(currentLocale);







  }

  startSurvey() {
    const sub = this.questionnaireService.markRespondentInProgress(this.token).subscribe(_ => {
      if (this.survey.selectedCriterias.length && !this.survey.predefinedMetrics)
        this.stage = SurveyStages.CollectData;
      else this.stage = SurveyStages.Started;
    });

    this.addSubscription(sub);
  }

  submitResults(sender: any) {
    this.loader.displayLoader();


    var arrData: { key: string; value: any; comment: any }[] = [];
    Object.keys(sender.data).forEach((itemK) => {
      arrData.push({ key: itemK, value: sender.data[itemK], comment: sender.data[`${itemK}-Comment`] })
    })

    const results = JSON.stringify(sender.data);
    const resultsPair = JSON.stringify(arrData);

    var answers = {
      jsonText: results, jsonTextPair: resultsPair,
      tenantKey: this.tenantKey, tenantName: this.companyName
    }

    this.addSubscription(
      this.questionnaireService.submit(this.token, {
        results: answers.jsonText,
        jsonTextPair: answers.jsonTextPair
      }).subscribe(_ => {
        this.router.navigate(['/survey-x-questionnaire/survey-finished', this.token]);
      })
    );

    // if (responseId) {
    //   this.updateSurveyResults(environment.endPoint + `form-response/update-form-response/${responseId}`, answers, surveyId)
    // }
    // else {
    //   this.saveSurveyResults(environment.endPoint + `form-response/add-response`, answers, surveyId)
    // }

  }

  onSubmitSurvey() {
    this.isConfirmSubmissionModalOpen = false;

    this.surveyModel.completeLastPage();
  }

  showConfirmSubmissionModal() {
    this.surveyModel.validateCurrentPage();

    if (this.surveyModel.isLastPage && !this.surveyModel.isCurrentPageHasErrors) {
      this.isConfirmSubmissionModalOpen = true;
    }
  }

  changeColor(color: string) {
    document.documentElement.style.setProperty('--main-primary-color', color);
    document.documentElement.style.setProperty('--sjs-primary-backcolor', color);
    document.documentElement.style.setProperty('--primary', color);
    document.documentElement.style.setProperty('--background-dim-light', "#FFF");
  }

  updateSurveyResults(url: string, json: Object, surveyId: string) {
    let responseId = window.localStorage.getItem("responseId");

    const request = new XMLHttpRequest();
    request.open('PUT', url);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.addEventListener('load', () => {
      // Handle "load"
      //console.log('load: ', request.responseText);

      this.loader.hideLoader();
      this.router.navigate(['/survey-x-questionnaire/survey-finished/' + surveyId + '/' + responseId]);
    });
    request.addEventListener('error', () => {
      // Handle "error"
      //console.log('error: ', request.responseText);

      this.loader.hideLoader();
      this.router.navigate(['/survey-x-questionnaire/survey-finished', surveyId]);
    });
    request.send(JSON.stringify(json));
  }

  saveSurveyResults(url: string, json: Object, surveyId: string) {
    const request = new XMLHttpRequest();
    request.open('POST', url);
    request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    request.addEventListener('load', () => {
      // Handle "load"
      //console.log('load: ', request.responseText);

      this.loader.hideLoader();
      this.router.navigate(['/survey-x-questionnaire/survey-finished', surveyId]);
    });
    request.addEventListener('error', () => {
      // Handle "error"
      //console.log('error: ', request.responseText);

      this.loader.hideLoader();
      this.router.navigate(['/survey-x-questionnaire/survey-finished', surveyId]);
    });
    request.send(JSON.stringify(json));
  }

  onlanguageChanged(event: string) {
    this.language = event.toUpperCase();
    locale = this.language;
    // this.surveyModel.locale = this.language;


    updateQuestionHeaderHTML(this.currentQuestionOptions, this.sections, event.toLowerCase());
    updateQuestionBodyHTML(this.currentQuestionOptions, event.toLowerCase());


  }

  updateNPSCompanyName(question: any) {
    const input = question.locTitle['values']['default'];

    var part1 = "";
    if (question.rateType === "enps")
      part1 = "How likely are you to recommend";
    else
      part1 = "How likely are you to recommend our";

    const part2 = "to your friends and relatives?";

    const index1 = input.indexOf(part1);
    const index2 = input.indexOf(part2);

    const locale = question.surveyValue.locale.toUpperCase();

    if (index1 !== -1 && index2 !== -1) {
      const companyName = input.substring(index1 + part1.length, index2).trim();

      if (companyName == '[CompanyName]') {
        question.title = translateText(part1, locale) + " " + this.companyName + " " + translateText(part2, locale);
      } else if (companyName == '[Product(s)/Service(s)]') {
        question.title = translateText(part1, locale) + " " + translateText("[Product(s)/Service(s)]", locale) + " " + translateText(part2, locale);
      } else {
        question.title = translateText(part1, locale) + " " + companyName + " " + translateText(part2, locale);
      }
    }
  }
}

export function translateText(text: string, loc: string) {
  let localLocale = loc
  if (!localLocale)
    localLocale = locale;
  return translationPipe.transform(text, localLocale.toUpperCase(), "Survey-X");
}

// export function getSectionByQuestion(question: any) {
//   var result = { section: null, index: 0 };
//

//   for (var i = 0; i < ogPages.length; i++) {
//     var page = ogPages[i];
//     page.questions.forEach((q) => {
//       if (q.name == question.name) {
//         result.section = page;
//         result.index = i;
//       }
//     });
//   }

//   return result;
// }

export function getSectionByQuestion(question: Question, sections: any, currentLocale: any): { section: any, sectionNumber: number } {
  const section = sections.find(q=> q.jsonObj?.elements?.find(x=> x?.name == question['jsonObj']?.name))
  const result = { section: null, sectionNumber: -1 }; // Initialize with section as null and index as -1
  result.section = question.parent['jsonObj'].title[currentLocale] || question.parent['jsonObj'].title['default'] || question.parent['jsonObj'].title;
  result.sectionNumber = sections.indexOf(section) || 0;
  return result;
}


export function validateCommentBoxLength(options: any, currentLocale) {
  var question = options?.question;

  //Validation for comment length (Max 300 characters)
  if (question['hasComment'] || question['showCommentArea']) {
    question.surveyValue.onValidateQuestion.add(function (sender, options) {
      if (options.name == question.name) {
        if (options.question.comment && options.question.comment.length > 300) {
          options.error = translateText("Please enter less than 300 characters in the comment area", currentLocale);
        }
      }
    });
  }

  //Validation for other item length (Max 300 characters)
  if (question['showOtherItem'] || question['hasOther']) {
    question.surveyValue.onValidateQuestion.add(function (sender, options) {
      if (options.name == question.name) {
        var isValueInChoices = false;

        options.question.choices.forEach((choice) => {
          if (choice.value == options.value) {
            isValueInChoices = true;
          }
        });

        if (!isValueInChoices) {
          if (options.question.value[0].length > 300) {
            options.error = translateText("Other item should be less than 300 characters", currentLocale);
          }
        }
      }
    });
  }
}

export function updateQuestionHeaderHTML(options: any, sections: any, currentLocale: any) {
  var question = options.question;

  //Question Header
  var header = options.htmlElement.querySelector(".sd-element__title.sd-question__title.sd-title");
  if (header == null) {
    header = options.htmlElement.querySelector(".question-header");
    header.innerHTML = "";

    var sectionHeader = options.htmlElement.querySelector(".section-header");
    sectionHeader.innerHTML = "";

    var body = options.htmlElement.querySelector(".input-title");
    if (body)
      body.innerHTML = "";

  }
  header.innerHTML = "";
  header.className = "question-header flex gap-2 mb-3";

  var index = document.createElement("div");
  index.className = "question-index";
  index.innerHTML = translateText("Q", currentLocale) + question["no"].split(".")[0];

  var title = document.createElement("p");
  title.className = "question-title whitespace-normal	break-words w-full";
  title.innerHTML = question.title;

  header.appendChild(index);
  header.appendChild(title);

  //Page Header
  var result = getSectionByQuestion(question, sections, currentLocale);
  if (!sectionHeader) {
    sectionHeader = document.createElement("div");
  }
  sectionHeader.className = "section-header flex gap-3 mb-5";

  var sectionIndex = document.createElement("div");
  sectionIndex.className = "section-index flex gap-1";
  sectionIndex.innerHTML = "<span>" + translateText("Section", currentLocale) + "</span> <span>" + (result.sectionNumber + 1) + "</span>";

  var sectionTitle = document.createElement("div");
  sectionTitle.className = "section-title";

  sectionTitle.innerHTML = result.section

  sectionHeader.appendChild(sectionIndex);
  sectionHeader.appendChild(sectionTitle);

  //inset before header
  header.parentNode.insertBefore(sectionHeader, header);
}

/**
 * Updates the HTML body of the question based on the provided options.
 * @param options - The options for updating the question HTML body.
 */
export function updateQuestionBodyHTML(options: any, locale?) {
  const questionType = options.question.getType();

  switch (questionType) {
    case 'text':
      handleTextQuestion(options, locale); // Update HTML for text question
      break;

    case 'comment':
      updateLongTextQuestionHTML(options, locale); // Update HTML for comment question
      break;

    case 'radiogroup':
      updateRadioGroupQuestionHTML(options, locale); // Update HTML for radio group question
      break;

    case 'checkbox':
      updateCheckBoxsQuestionHTML(options, locale); // Update HTML for checkbox question
      break;

    case 'dropdown':
      updateDropdownQuestionHTML(options, locale); // Update HTML for dropdown question
      break;

    case 'tagbox':
      updateTagboxQuestionHTML(options, locale); // Update HTML for tagbox question
      break;

    case 'rating':
      handleRatingQuestion(options, locale); // Handle different types of rating questions
      break;

    case 'ranking':
      updateRankingQuestionHTML(options, locale); // Update HTML for ranking question
      break;

    case 'imagepicker':
      if (options.question['isIconChoice'])
        updateIconChoiceQuestionHTML(options, locale); // Update HTML for image picker question with icon choices
      else
        updateImagePickerQuestionHTML(options, locale); // Update HTML for image picker question

      break;

    case 'boolean':
      updateBooleanQuestionHTML(options, locale); // Update HTML for boolean question
      break;

    case 'matrix': // Update HTML for Matrix questions
      if (!options.question['isMultiSelect'])
        updateMatrixQuestionHTML(options, locale);
      else
        updateMatrixCheckboxsQuestionHTML(options, locale);
      break;

    case 'file':
      updateFileUploadQuestionHTML(options, locale); // Update HTML for file upload question
      break;

      case 'datepicker':
        // updateFileUploadQuestionHTML(options); // Update HTML for file upload question
        updateQuestionHeaderHTML(options, "Date Picker", "fa-message-minus");
        break;

    default:
      throw new Error('Invalid question type'); // Throw error for invalid question type
  }
}

/**
 * Handles the HTML update for text-based questions based on the input type.
 * @param options - The options for updating the question HTML body.
 */
function handleTextQuestion(options: any, locale) {
  const inputType = options.question['inputType'];

  switch (inputType) {
    case 'number':
      updateNumberInputQuestionHTML(options, locale); // Update HTML for number input question
      break;

    case 'email':
      updateEmailInputQuestionHTML(options, locale); // Update HTML for email input question
      break;

    case 'date':
      updateDateInputQuestionHTML(options, locale); // Update HTML for date input question
      break;

    case 'range':
      updateSliderQuestionHTML(options, locale); // Update HTML for range slider question
      break;

    default:
      updateShortTextQuestionHTML(options, locale); // Update HTML for short text question
      break;
  }
}

/**
 * Handles the HTML update for rating questions based on the rate type.
 * @param options - The options for updating the question HTML body.
 */
function handleRatingQuestion(options: any, locale) {
  const rateType = options.question['rateType'];

  switch (rateType) {
    case 'labels':
      updateRatingScaleQuestionHTML(options, locale); // Update HTML for rating scale question with labels
      break;

    case 'enps':
      updateENPSQuestionHTML(options, locale); // Update HTML for eNPS (Employee Net Promoter Score) question
      break;

    case 'nps':
      updateENPSQuestionHTML(options, locale); // Update HTML for NPS (Net Promoter Score) question
      break;

    default:
      updateRatingStarsQuestionHTML(options, locale); // Update HTML for rating stars question
      break;
  }
}
