<div class="block relative w-full  border-zen border-opacity-10 rounded " [ngClass]="rows == 1 && !fullWidthChartCheck(data.categories)?'px-6 border pt-5 pb-6':'border-t border-b'">
  <div class="flex flex-row items-center gap-2 pb-6 leading-10" [ngClass]="{'border-l border-r border-zen border-opacity-10 px-6 pt-5':rows == 2 || fullWidthChartCheck(data.categories)}">
      <ng-container *ngIf="!header.hasChildNodes()">
          <i *ngIf="chart?.icon;" [class]="'fa-'+ chart.icon" class="fa-regular text-exp text-xl"></i>
          <img *ngIf="chart?.image" [src]="chart.image" alt="">
          <h4 class="text-sm font-normal text-zen">{{chart.title}}</h4>
      </ng-container>
      <div #header [hidden]="!header.hasChildNodes()">
          <ng-content select='[header]'></ng-content>
      </div>
  </div>
  <div class="table relative w-full">
      <ng-container *ngIf="rows == 1">
          <div class="table align-top w-full" *ngFor="let category of data.categories; let t = index">
              <span *ngIf="breakCondition(t)" class="block break-before w-full h-20"></span>
              <ng-container *ngIf="categoryTemplate">
                  <ng-container
                      *ngTemplateOutlet="categoryTemplate; context:{$implicit:category, value:data.bar.series[0].data[t], index : t}"></ng-container>
              </ng-container>
              <ng-container *ngIf="!categoryTemplate">

                  <div [ngClass]="{'border-t pt-3':breakCondition(t), 'border-b pb-6':breakCondition(t + 1), 'px-6 border-r border-l':fullWidthChartCheck(data.categories), 'pb-6': t == data.categories.length -1 && fullWidthChartCheck(data.categories)}" class="inline-block align-top w-full py-2">
                      <div class="flex flex-row justify-between w-full pb-1">
                          <h5 class="text-zen text-sm inline-block align-top truncate max-w-lg">{{category || '-'}}</h5>
                          <h5 class="text-exp text-sm inline-block align-top font-semibold">
                              ({{data.bar.series[0].data[t] | number:'1.1-1'}}%)
                          </h5>
                      </div>
                      <div class="flex flex-row h-2.5 rounded-sm bg-exp bg-opacity-10 items-stretch overflow-hidden">
                          <div class="bg-exp" [style.width.%]="data.bar.series[0].data[t]">
                          </div>
                      </div>
                  </div>

              </ng-container>

          </div>
      </ng-container>

      <div class="block relative w-full" *ngIf="rows == 2">
          <ng-container *ngFor="let category of data.categories; let i = index" >
              <span *ngIf="breakCondition(i)" class="block break-before w-full h-20"></span>
              <div class="inline-block w-1/2   border-zen border-opacity-10 px-6" [ngClass]="(language | language)? (i % 2 != 0?'border-l': 'border-r'): (i % 2 != 0?'border-r': 'border-l')" [class]="data.categories?.length -1 == i || data.categories?.length -2 == i?'pb-6':''">
                  <div class="flex flex-row gap-4 text-sm">
                      <div class="flex flex-col w-7/12">
                          <h5 class="text-zen-lite leading-10 truncate">{{category || '-'}}</h5>
                      </div>
                      <div class="flex flex-row items-center w-5/12 gap-4"
                          *ngIf="data.bar.series[0].data[i] as value">
                          <div
                              class="flex flex-row justify-start h-2 w-full rounded-sm bg-exp bg-opacity-10 items-stretch overflow-hidden">
                              <div class="bg-exp" [style.width.%]="value">
                              </div>
                          </div>
                          <div class="flex flex-row justify-end w-11 ">
                              <h5 class="text-zen font-medium leading-10">{{value |
                                  number:'1.1-1'}}%
                              </h5>
                          </div>
                      </div>
                  </div>
              </div>
          </ng-container>
      </div>
  </div>

</div>
