import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { delay, filter } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { GlobalsService } from 'src/app/core/services/globals.service';
import { ModuleName } from '../enums/ModuleName';
interface EmailObject {
  body: string,
  subject?: string
}
@Component({
  selector: 'zen-editor',
  templateUrl: './zen-editor.component.html',
  styleUrls: ['./zen-editor.component.scss']
})
export class ZenEditorComponent extends BaseComponent implements OnInit {
  logoURL;
  @Input() emailObject: EmailObject;
  @Input() language;
  @Input() list: any[] = [];
  @Input() single;
  @Output() reset = new EventEmitter();
  @Output() emailObjectChange = new EventEmitter();
  hugeCount;
  lgCount;
  mdCount;
  smCount;
  constructor(public globals: GlobalsService,) {
    super();
    this.hugeCount = globals.hugeCount;
    this.lgCount = globals.lgCount;
    this.mdCount = globals.mdCount;
    this.smCount = globals.smCount;
    this.addSubscription(this.EssentialObject$.pipe(filter((obj: EssentialObject) => obj.selectedEntity.logoURL != null), delay(500)).subscribe((obj) => {
      this.logoURL = obj.selectedEntity.logoURL;
      this.emailObject.body = this.emailObject.body.replace('https://zensystemsassets.s3.me-south-1.amazonaws.com/emailicons/ex-logo.png', this.logoURL);
    }));
  }

  resetClicked() {
    this.reset.emit();
    setTimeout(() => {
      if (this.logoURL)
        this.emailObject.body = this.emailObject.body.replace('https://zensystemsassets.s3.me-south-1.amazonaws.com/emailicons/ex-logo.png', this.logoURL);
    }, 100);
  }


  ModuleNames = ModuleName
  ngOnInit(): void {
  }

}
