import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TourStepsService } from '../services/tour-steps.service';
import { StepOptions, TourStep } from '../models/tour-step';

@Directive({
  selector: '[TourStep]',
})
export class TourStepDirective {
  _stepData;
  @Input('TourStep') set stepData(stepData: TourStep) {
    this._stepData = stepData;
    if (this._stepData) {
      this.newStep(this._stepData);
    }
  }

  constructor(
    private tourStepsService: TourStepsService,
    private elementRef: ElementRef
  ) {}

  newStep(step) {
    let stepOptions: StepOptions = {
      options: {
        title: step.title,
        text: step.text,
        attachTo: {
          element: this.elementRef.nativeElement,
          on: step.on ? step.on : 'bottom',
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        id: step.triggerId,
      },
      order: step.order,
      isRows: step.isRows,
    };
    stepOptions.options.classes += stepOptions.options.attachTo.on.includes(
      'right'
    )
      ? ' mx-5'
      : stepOptions.options.attachTo.on.includes('left')
      ? ' -mx-5'
      : ' my-5';
    this.tourStepsService.addStep(step.tourKey, stepOptions);
  }
}
