import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: null
})
export class WellbeingFirebaseUpdateService {

  constructor(private afd: AngularFireDatabase) { }
  getListUpdate(tenantKey: string, companyKey: string, surveyKey: string) {
    let trackValue = 1;
    const subject = new BehaviorSubject(trackValue);

    this.afd.database.ref(`TenantData/${tenantKey}/Wellbeing/${companyKey}/SurveyStatus/${surveyKey}/lastUpdated`).on("value", (snapshot) => {
      subject.next(++trackValue);

    })

    return subject.asObservable();

  }
}
