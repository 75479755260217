import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Entity } from '../../../core/data-models/Entity';
import { EntityGroup } from '../../../core/data-models/entity-group';
import { LanguageService } from '../../../core/services/language.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Tenant } from '../../../core/data-models/tenant';
import { EntityService } from '../../../core/services/entity.service';
import { LogService } from '../../../core/services/log.service';
import { PlatformUser } from '../../../core/data-models/platform-user';
import { PlatformUserService } from '../../../core/services/platform-user.service';
import { ResponseDialogService } from '../../../core/services/response-dialog.service';
import { Leaver } from '../../../core/data-models/leaver';
import { ZenSnackbarService, ZenSnackBarTypes } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { GlobalsService } from '../../../core/services/globals.service';
import { IntegrationService } from 'src/app/core/services/integration.service';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { ClientesService } from 'src/app/psychometric/services/clientes.service';
import { NewEntityColumn } from 'src/app/psychometric/models/clients/new-entity-column';
declare var $: any;

@Component({
  selector: 'entity-structure-dialog',
  templateUrl: './entity-structure-dialog.component.html',
  styleUrls: ['./entity-structure-dialog.component.scss']
})
export class EntityStructureDialogComponent implements OnInit {
  open: boolean = false;
  invalid: boolean = false;
  entityGroup: EntityGroup;
  language: string;
  entities: Entity[] = [];
  selectedEntity: Entity;
  entityGroups: EntityGroup[] = []
  entitiesInEntityGroup: Entity[] = [];
  leavers: Leaver[] = [];
  entitiesInParentEntityGroup: Entity[] = [];
  tenant: Tenant;
  companyEntities: Entity[] = [];
  entityGroupName: string = '';
  supportedLanguages: any[] = []
  entityGroupNameInput;
  newEntityGroup: boolean = false;
  user: PlatformUser;
  loading


  vStructureName: boolean = false;
  vStructureParent: boolean = false;






  // Language Related Fields
  componentTextList: any[] = [];
  // txtEntityGroupName: string;
  // txtStructureName: string;
  // txtParentStructureGroup: string;
  // txtEntitiesDefinition: string;
  // btnAddNewEntity: string;
  // txtEntityName: string;
  // txtParentEntity: string;
  // btnUpdate: string;
  // btn_cancel: string;
  // btn_leaver_list_delete: string;
  // btn_add;
  // txtEntity: string;
  // txtReportsto: string;
  // txtTypeComment: string;
  // txtFieldIsRequired: string;
  // txtWrongTextCount;
  // txtDescription: string;

  thomasIntegrated: boolean = false;

  mdCount;
  smCount;
  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<EntityStructureDialogComponent>,
    private languageService: LanguageService, private db: AngularFireDatabase, private entityService: EntityService,
    private zenSnackBarService: ZenSnackbarService, private _globals: GlobalsService,
    private logService: LogService, private platformUserService: PlatformUserService,
    private responseDialogService: ResponseDialogService, private integrationService: IntegrationService, private translationPipe: TranslatePipe,
    private thomasClientService: ClientesService) {
    this.mdCount = _globals.mdCount;
    this.smCount = _globals.smCount;
  }

  ModuleNames = ModuleName
  countWord(word, count) {
    if (word?.length <= count) return word.length;
    if (word?.length > count) {
      return word.length;
    }
  }
  validateCount(value, count, ready?) {
    let validatedCount;
    if (ready == 'x') {
      validatedCount = this.countWord(value, count);
    } else
      validatedCount = this.countWord(this.extractNameLanguage(value), count);
    if (validatedCount > count) {
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform('txt_wrong_text_count', this.language, this.ModuleNames.Exit), type: ZenSnackBarTypes.Error });
      return false;
    }
    if (validatedCount <= count) {
      return true;
    }
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }

  ngOnInit(): void {
    this.entityGroup = this.data.entityGroup;
    this.language = this.data.language;
    this.entities = this.data.entities;
    this.selectedEntity = this.data.selectedEntity;
    this.entityGroups = this.selectedEntity.EntityGroups;
    this.supportedLanguages = this.data.supportedLanguages;
    this.tenant = this.data.tenant;
    this.companyEntities = this.data.companyEntities;
    this.thomasIntegrated = this.data.thomasIntegrated;

    this.user = this.data.user;
    this.leavers = this.data.leavers;


    if (this.entityGroup.groupKey) {
      let langGroupName = this.entityGroup.name.find(nm => nm.key == this.language);
      if (langGroupName)
        this.entityGroupName = langGroupName.name;

    } else {
      this.entityGroup.groupKey = this.db.createPushId();
      this.newEntityGroup = true;
    }



    for (let i = 0; i < this.supportedLanguages.length; i++) {
      let supportedLanguage = this.supportedLanguages[i];
      if (!this.entityGroup.name.find(nm => nm.key == supportedLanguage)) {
        this.entityGroup.name.push({ key: supportedLanguage, name: '' });
      }
    }
    for (let i = 0; i < this.entityGroup.name.length; i++) {
      let group = this.entityGroup.name[i];
      if (group.key == this.language)
        this.entityGroupNameInput = group;
    }

    this.groupChildEntities();
    this.groupEntitiesInParentEntityGroup();
    this.setUpLanguagePreference();


    this.openModal();
  }

  translationChanged() {

  }

  parentSelected(event, e: Entity) {
    console.log(event);
    console.log(e);
    e.parentKey = event;
  }

  validateForm() {
    let isValid: boolean = true;

    if (this.entityGroup.name.filter(n => n.name == '').length == this.entityGroup.name.length) {
      this.vStructureName = true;
      console.log('No Valid Name');
      isValid = false;
    } else {
      this.vStructureName = false;
    }

    if (!this.entityGroup.parentKey) {
      this.vStructureParent = true;
      isValid = false;
      console.log('No Parent');
    } else {
      this.vStructureParent = false;
    }
    // if (this.entitiesInEntityGroup.length == 0)
    //   isValid = false;

    let eGsValidation = false;
    for (let i = 0; i < this.entitiesInEntityGroup.length; i++) {
      let e = this.entitiesInEntityGroup[i];
      if (e.name.filter(n => n.name == '').length == e.name.length) {
        e.requiresNameValidation = true;
        console.log('Names Not Inserted');
        eGsValidation = true;
      } else {
        e.requiresNameValidation = false;
      }
      if (!e.parentKey) {
        e.requiresReportingValidation = true;
        console.log('Parent For Child not inserted');
        eGsValidation = true;
      } else {
        e.requiresReportingValidation = false;
      }

      if (!this.validateCount(e.name, this.mdCount))
        return;
    }

    if (eGsValidation) {
      isValid = false;
    }
    this.invalid = !isValid;
    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
    if (!this.validateCount(this.entityGroup.name, this.mdCount))
      return;

    return isValid;
  }

  deleteEntityInGroup(entity: Entity, i) {
    console.log('Finding Entity....');
    let found = this.leavers?.find(l => {
      let entityGroupFound = l.entityHierarchy?.find(eH => eH.selectedEntityKey == entity.key);
      if (entityGroupFound)
        return true;
      return false;
    });
    console.log(this.leavers);
    if (found) {

      this.responseDialogService.openDialog('txt_delete_entity_denied', 'txt_delete_denied', 'error', 'txt_okay_action', 'btn_cancel');
    } else {

      this.entityService.deleteEntity(entity).then((_) => {
        this.entitiesInEntityGroup.splice(i, 1);

      }).catch((ex) => {

      });

    }
  }

  async updateEntityGroup() {
    console.log('Method Called');
    let isValid = this.validateForm();
    if (isValid) {
      console.log('Method Value');
      if (this.newEntityGroup) {
        this.entityService.updateCompany({ TenantKey: this.tenant.key, CompanyKey: this.selectedEntity.key, GroupKey: this.entityGroup.groupKey, thomasIntegration: this.thomasIntegrated }).subscribe(async (response: any) => {
          if (response.response == 'SUCCESS') {
            if (this.tenant.thomasIntegrated) {
              let newEntityColumn = new NewEntityColumn();
              newEntityColumn.companyKey = this.selectedEntity.key;
              newEntityColumn.tenantKey = this.tenant.key;
              newEntityColumn.modifiedby = this.user.firstName + ' ' + this.user.lastName;
              newEntityColumn.entityKey = this.entityGroup.groupKey;
              let response: any = await this.thomasClientService.newThomasEntityColumn(newEntityColumn).toPromise();
            }
            this.proceedWithAction();
          } else {
            this.zenSnackBarService.snackBar({ message: 'Something went wrong', type: ZenSnackBarTypes.Error });
          }
        });
      } else {
        // if(this.newEntityGroup)
        this.proceedWithAction();
      }
    } else {
      console.log('Method INVALID');
    }
  }


  async proceedWithAction() {
    if (this.newEntityGroup) {
      this.selectedEntity.EntityGroups.push(this.entityGroup);
    } else {
      let indexOfEntityGroup = this.selectedEntity.EntityGroups.findIndex(indexE => indexE.groupKey == this.entityGroup.groupKey);
      this.selectedEntity.EntityGroups[indexOfEntityGroup] = this.entityGroup;
    }

    let newEntityReferences: boolean = false;
    let response: any;

    if (this.newEntityGroup) {
      response = {
        actionType: 'ADD',
        entityGroup: this.getEntityName(this.entityGroup.name),
        status: 'SUCCESS'
      }
    } else {
      response = {
        actionType: 'UPDATE',
        entityGroup: this.getEntityName(this.entityGroup.name),
        status: 'SUCCESS'
      }
    }
    let coreUpdateResponse: any = await this.entityService.coreUpdateCompany(this.selectedEntity).toPromise();
    this.entityService.updateEntity(this.selectedEntity).then(async (_) => {
      this.logService.submitLog(4, 'EntityStructureDialog.updateEntityGroup()', 'Task Completed', { entity: this.selectedEntity });
      if (this.entitiesInEntityGroup.length > 0) {
        for (let i = 0; i < this.entitiesInEntityGroup.length; i++) {

          let entity: Entity = this.entitiesInEntityGroup[i];
          let coreUpdateEntityResponse: any = await this.entityService.coreUpdateCompany(entity).toPromise();

          console.log('should be closed');

          this.entityService.updateEntity(entity).then((_) => {
            this.logService.submitLog(4, 'EntityStructureDialog.updateEntityGroup()', 'Task Completed', { entity: entity });

          }).catch((error) => {
            this.logService.submitLog(4, 'EntityStructureDialog.updateEntityGroup()', 'Task Failed', { entity: entity });
          });
          console.log('should be closed');
          if (!this.user.EntityReferences.find(ef => ef.key == entity.key)) {
            this.user.EntityReferences.push(entity.key);
            newEntityReferences = true;

          }
        }
        if (newEntityReferences) {
          this.platformUserService.updatePlatformUser(this.user).then((_) => {
            this.logService.submitLog(4, 'EntityStructureDialog.updateEntityGroup()', 'Task Completed', { user: this.user });
            console.log('should be closed');
          }).catch((error) => {
            this.logService.submitLog(4, 'EntityStructureDialog.updateEntityGroup()', 'Task Failed', { user: this.user });
          });
        }
        this.dialogRef.close(response);

      }
      console.log('should be closed');
      this.dialogRef.close(response);

      // this.entityService.updateEntity(this.selectedEntity).then((_) => {
      //   this.logService.submitLog(4, 'EntityStructureDialog.updateEntityGroup()', 'Task Completed', { entity: entity });
      // }).catch((error) => {
      //   this.logService.submitLog(4, 'EntityStructureDialog.updateEntityGroup()', 'Task Failed', { entity: entity });
      // });




    }).catch((error) => {
      this.logService.submitLog(4, 'EntityStructureDialog.updateEntityGroup()', 'Task Failed', { entity: this.selectedEntity });
    });
  }




  groupEntitiesInParentEntityGroup() {
    this.entitiesInParentEntityGroup = this.entities.filter(e => e.entityGroupKey == this.entityGroup.parentKey);
    if (this.entitiesInEntityGroup.length == 0)
      this.addNewEntity();
  }

  groupChildEntities() {
    this.entitiesInEntityGroup = this.companyEntities.filter(e => e.entityGroupKey == this.entityGroup.groupKey);
  }

  setUpLanguagePreference() {
    // this.componentTextList = this.languageService.componentText.filter(country => country.language == this.language);
  }

  getEntityName(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
    // let eGName: string = eG.find(nm => nm.key == this.language).name;
    // if(eGName && eGName != ''){
    //   return eGName;
    // } else {
    //   eGName = eG.find(nm => nm.name != '').name;
    // }
    // return eGName;
  }

  getParentEntityName(key: string) {
    let parentEntity = this.entities.find(e => e.key == key);
    if (parentEntity) {
      return this.getEntityName(parentEntity.name) + ' - ';
    }
    return '';
  }

  addNewEntity() {
    let entity = new Entity;
    entity.isCompany = false;
    entity.tenantKey = this.tenant.key;
    entity.isRoot = false;
    entity.key = this.db.createPushId();
    entity.name = [];
    entity.companyEntityKey = this.selectedEntity.key;
    entity.entityGroupKey = this.entityGroup.groupKey;
    this.entitiesInEntityGroup.push(entity);
  }

  onParentStructureChange(event) {
    this.groupEntitiesInParentEntityGroup();
  }


  logChange() {

  }




  modalInterval = false;
  openModal() {
    this.modalInterval = true;
    setTimeout(() => {
      this.open = true;
    }, 100);
  }
  closeModal() {
    this.open = false;
    setTimeout(() => {
      this.modalInterval = false;
    }, 300);
  }


}
