import { PagedResultDto } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RespondentsListWithCriteriaDto } from '@ex/wellbeing/analysis';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { CriteriaResolverService } from '../../data-access/services/criteria-resolver.service';
import { Entity } from '@ex/module/core/data-models/Entity';
import { RespondentService } from '@ex/wellbeing/respondents';

@Component({
  selector: 'wb-respondents-table',
  templateUrl: './respondents-table.component.html',
  styleUrls: ['./respondents-table.component.scss']
})
export class RespondentsTableComponent extends WbBaseComponent implements OnInit {

  @Input()
  tenantKey;

  @Input()
  companyKey

  @Input()
  surveyKey


  data: PagedResultDto<RespondentsListWithCriteriaDto>

  @Input('data')
  set setData(data: PagedResultDto<RespondentsListWithCriteriaDto>) {

    this.data = data;

    setTimeout(() => {
      this.renderTable();
    }, 1000);
  }

  @Input()
  pageSize = 10;

  options: TableOptions;
  @Input()
  Entities: Entity[]

  @Input()
  surveyCriterias: any

  @Input()
  language = "EN"

  @Output()
  pageChanged = new EventEmitter();

  @Output()
  searchFilterChanged = new EventEmitter();

  displayedColumns = [];

  searchBox = new FormControl("");

  dir = 'ltr';
  respondentEdit: boolean = false;
  currentRespondent: RespondentsListWithCriteriaDto | any;
  allEntitiesSnapshot;
  selectedEntity: Entity;
  constructor(private criteriaResolverService: CriteriaResolverService, private respondenService: RespondentService) {
    super()
  }

  ngOnInit(): void {

    this.addSubscription(this.EssentialObject$.subscribe(x => {
      this.selectedEntity = x.selectedEntity;
      this.allEntitiesSnapshot = x.allEntities;
    }));
    if (document.body.dir == 'rtl') {
      this.dir = 'rtl';
    }

    this.searchBox.valueChanges
      .pipe(
        map(searchTerm => {
          if (searchTerm.length > 2)
            return searchTerm;

          return "";

        }),
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.searchFilterChanged.next(value);
      })

  }

  renderTable() {
    this.displayedColumns = [
      {
        field: 'employeeId',
        title: this.localize('txt_employee_ID', this.language, this.ModuleNames.Wellbeing)
        ,
      },
      {
        field: 'name',
        title: this.localize('txt_full_name', this.language, this.ModuleNames.Wellbeing)
        ,
      },
      {
        field: 'email',
        title: this.localize('txt_user_email', this.language, this.ModuleNames.Wellbeing)
        ,
      },
      {
        field: 'status',
        title: this.localize('txt_progress', this.language, this.ModuleNames.Wellbeing)
        ,
      },
    ];

    this.surveyCriterias?.forEach(criteria => {
      this.displayedColumns.push({
        field: `criteria_${criteria.key}`,
        title: this.ExtractPipe.transform(criteria.name, this.language),
        type: 'criteria'
      })
    })

    this.displayedColumns.push({
      field: 'action',
      title: '',
    });

    this.options = {
      columns: this.displayedColumns,
      searchText: this.localize('txt_search', this.language, this.ModuleNames.Wellbeing),
      exportText: this.localize('btnExport', this.language, this.ModuleNames.Wellbeing),
      exportName: this.localize('txtSurveyRespondentList', this.language, this.ModuleNames.Wellbeing),
      whiteBackground: true,
      colors: this.GlobalService.basicColors,
      selection: false,
      selectionKey: '',
      selectionRemove: [],
      sticky: true,
      serverSide: false,
      data: this.data.items,
      hidePagination: true,
      disableSearch: true,
    }
  }

  validEmail(e) {
    return e?.indexOf('@') < 1 ? '' : e;
  }

  onPageChange(page) {
    this.pageChanged.emit(page);
  }

  extractCustomProps(record: any, key: string) {
    let propKey = key.replace("criteria_", "");

    let prop = record?.customProperties?.find(x => x.key == propKey)

    return prop ? this.criteriaResolverService.extract(propKey, prop.value, this.language) : ""
  }

  editRespondent(element) {
    this.currentRespondent = element;
    this.currentRespondent.Metrics = this.currentRespondent.customProperties;

    for (let i = 0; i < this.currentRespondent.Metrics.length; i++) {
      let m = this.currentRespondent.Metrics[i];
      if (m.key == 'NATIONALITY') {
        if (!m?.options)
          m.options = JSON.parse(
            JSON.stringify(this.GeneralService.countries_new)
          );
      } else if (m.key == 'GENDER') {
        if (!m?.options)
          m.options = JSON.parse(
            JSON.stringify(this.GeneralService.genders_new)
          );
      } else {
        if (m.type != "Entity") {
          let optionMetric = this.surveyCriterias.find(
            (o) => o.key == m.key
          );
          if (optionMetric) {
            if (!m?.options) {
              if (optionMetric?.options?.length > 0)
                m.options = JSON.parse(JSON.stringify(optionMetric.options));
            }
          }
        } else {
          let entitiesInOptionGroup = this.allEntitiesSnapshot.filter(
            (e) => e.entityGroupKey == m.key
          );
          let options: any[] = [];
          for (let x = 0; x < entitiesInOptionGroup.length; x++) {
            let entity = entitiesInOptionGroup[x];
            let parentEntityName: any[] = [];
            if (entity.parentKey) {
              let parentObject = this.allEntitiesSnapshot.find(
                (e) => e.key == entity.parentKey
              );
              if (parentObject) parentEntityName = parentObject.name;
            }
            let option = {
              key: entity.key,
              name: entity.name,
              parentEntityName: parentEntityName,
            };
            options.push(option);
          }
          if (!m?.options) m.options = JSON.parse(JSON.stringify(options));
        }
      }
      m.name = this.surveyCriterias.find(x => x.key == m.key)?.name;
      if (m.type == "Entity")
        m.isEntity = true;
    }

    this.respondentEdit = true;

  }


  updateModalFunc(event: any) {
    ;
    this.currentRespondent = event;
    this.currentRespondent.customProperties = event.Metrics;
    let changedRespondent = this.options.data.find(x => x.email == this.currentRespondent.email);
    this.options.data[this.options.data.indexOf(changedRespondent)] = this.currentRespondent
    this.options = JSON.parse(JSON.stringify(this.options));
    this.currentRespondent.companyKey = this.companyKey;
    this.currentRespondent.surveyKey = this.surveyKey;
    this.currentRespondent.tenantKey = this.tenantKey;
    this.respondenService.updateRespondentWithCriteriaByRequest({ respondent: this.currentRespondent }).subscribe(res => {
      this.currentRespondent = null;
      this.respondentEdit = false;
    })
  }
}
