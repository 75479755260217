import { animate, group, query, sequence, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sx-drawer',
  templateUrl: './sx-drawer.component.html',
  styleUrls: ['./sx-drawer.component.scss'],
  animations: [
    trigger('animation', [
      transition(':enter', [
        group([
          query('.drawer', [
            style({ backgroundColor: 'transparent' }),
            animate('.35s ease-in', style({
              backgroundColor: 'rgba(68, 61, 105,.3)',
            }))
          ]),
          query('.ltr', [
            style({ transform: 'translateX(100%)' }),
            animate('.35s cubic-bezier(.19,.24,.03,.98)', style({
              transform: 'translateX(0%)'
            }))
          ], { optional: true }),
          query('.rtl', [
            style({ transform: 'translateX(-100%)' }),
            animate('.35s cubic-bezier(.19,.24,.03,.98)', style({
              transform: 'translateX(0%)'
            }))
          ], { optional: true })
        ])
      ]),

      transition(':leave', [
        sequence([
          query('.rtl', [
            style({ transform: 'translateX(0%)' }),
            animate('.35s cubic-bezier(.19,.24,.03,.98)', style({
              transform: 'translateX(-100%)'
            }))
          ], { optional: true }),
          query('.ltr', [
            style({ transform: 'translateX(0%)' }),
            animate('.35s cubic-bezier(.19,.24,.03,.98)', style({
              transform: 'translateX(100%)'
            }))
          ], { optional: true }),

          query('.drawer', [
            animate('.35s ease-out', style({
              opacity: '0',
            }))
          ]),

        ])
      ])
    ]),

  ],
})
export class SxDrawerComponent implements OnInit {
  constructor() { }
  open: boolean = false;
  dir = 'ltr';
  @Input('dir')
  direction!: string;
  @Input('showBackDrop') showBackDrop = true;
  @Input('reverse') reverse: any;
  @Input('autoClose') autoClose = true;
  @Input('open') set givenState(state: any) {
    this.open = state;
    if (this.reverse)
      document.body.dir == "rtl" || this.direction == "rtl" ? this.dir = 'ltr' : this.dir = 'rtl';
    else if (document.body.dir == "rtl" || this.direction == "rtl")
      this.dir = 'rtl'
  }
  @Output() openChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {

  }
  close() {
    this.open = false;
    this.openChange.emit(false);
  }
  aClose() {
    if (this.autoClose) {
      this.open = false;
      this.openChange.emit(false);
    }
  }
}
