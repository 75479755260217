<div class="zen-main-container flex flex-row items-stretch">
  <div class="flex flex-col w-80 min-w-80 zen-main-container gap-5 overflow-x-hidden relative">
    <div class="fixed w-inherit h-screen innerListScroll pt-14 bg-zen bg-opacity-5 shadow-inner">
      <div class="flex-col w-full gap-5 px-5">
        <div class="flex flex-col gap-2">
          <div [TourStep]="{
              title:
                'txt_timeframe' | translate : language : ModuleNames.Wellbeing,
              text:
                'txt_Selectthe_681'
                | translate : language : ModuleNames.Wellbeing,
              order: 2,
              tourKey: 'wellbeing-comparison',
               on: (language | language) ? 'left' : 'right'
            }">
            <z-datepicker [color]="'exp'" [label]="
                'txt_start_date' | translate : language : ModuleNames.Wellbeing
              " [language]="language" (ngModelChange)="startDateChanged($event)" [(ngModel)]="timeFrameStart"></z-datepicker>
            <z-datepicker [color]="'exp'" [label]="
                'txt_end_date' | translate : language : ModuleNames.Wellbeing
              " [language]="language" (ngModelChange)="endDateChanged($event)" [(ngModel)]="timeFrameEnd"></z-datepicker>
          </div>
          <h4 class="text-xs text-zen text-opacity-50 uppercase mt-5">
            {{
            "txtSurveysByCompany"
            | translate : language : ModuleNames.Wellbeing
            }}
          </h4>
          <h4 class="text-lg text-zen uppercase">
            {{ countList() }}
            {{ "txtSelected" | translate : language : ModuleNames.Wellbeing }}
          </h4>

          <ng-container *ngIf="checklists?.length > 0">
            <custom-tree-checklist (getExcludedCompaniesResults)="getExcludedCompanies($event)" (checklistChanged)="checklistChanged($event)" [checklist]="checklists" [isOptionalAveraged]="true" [language]="language" [TourStep]="{
                title:
                  'txt_Companies_799'
                  | translate : language : ModuleNames.Wellbeing,
                text:
                  'txt_Selectthe_788'
                  | translate : language : ModuleNames.Wellbeing,
                order: 3,
                tourKey: 'wellbeing-comparison',
                on: (language | language) ? 'left' : 'right'
              }"></custom-tree-checklist>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col w-full-20rem border-zen-light border-opacity-20 zen-main-container px-10 pt-14" *ngIf="wellbeingData?.length > 0">
    <div class="flex flex-row justify-between items-center gap-5 pb-10">
      <h3 class="text-2xl text-zen font-medium uppercase">
        {{
        "txtWellbeingComparison"
        | translate : language : ModuleNames.Wellbeing
        }}
      </h3>

      <i (click)="openExportPreviewDialog()" class="far fa-file-export btn cancel leading-12 w-12 rounded text-center" [TourStep]="{
          title: 'txtExport' | translate : language : ModuleNames.Wellbeing,
          text:
            'txt_Youcanexp_908' | translate : language : ModuleNames.Wellbeing,
          order: 4,
          tourKey: 'wellbeing-comparison',
          on: (language | language) ? 'right' : 'left'
        }"></i>
    </div>
    <ng-template #overall let-exportFlag>
      <ng-container *ngFor="let chart of obTermCharts; let i = index">
        <div class="innerPage break-before bg-white" *ngIf="chart?.data$ as data">
          <chart [wrapRows]="true" [reportsPage]="false" [exportInitiated]="exportInitiated" [dashlet]="overAllDashlet" [dashletData]="data" [currencySymbol]="'$'" [language]="language" [dataSymbol]="' %'" [disableGrouping]="true" [showDrillDown]="true" [chartColors]="[]" [chartTypeTour]="
              i == 0
                ? {
                    title:
                      'txt_ChartType_839'
                      | translate : language : ModuleNames.Wellbeing,
                    text:
                      'txt_Changethe_415'
                      | translate : language : ModuleNames.Wellbeing,
                    order: 5,
                    tourKey: 'wellbeing-comparison',
                    on: (language | language) ? 'right' : 'left'
                  }
                : undefined
            ">
          </chart>
        </div>
      </ng-container>
    </ng-template>

    <div class="flex flex-col gap-5">
      <div class="w-full">
        <ng-container *ngTemplateOutlet="overall"></ng-container>
      </div>
      <div class="w-full -mt-14">
        <div class="flex flex-row justify-end px-16 transform translate-y-1" id="engagement-score-by-driver">
          <div class="bg-opacity-5 transform translate-y-16 pt-3 rounded-md px-5 pb-5 h-12 overflow-hidden">
            <z-select [color]="'exp'" [(ngModel)]="selectedDriver" (selectionChange)="selectedDriverChanged($event)" [options]="driverList" [language]="language">
              <ng-template #valueTemplate let-value>
                {{ value?.name | extract : language }}
              </ng-template>
            </z-select>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="
            driverTemplate;
            context: { $implicit: selectedDriver, exportFlag: false }
          ">
        </ng-container>
      </div>

      <ng-container *ngTemplateOutlet="company"></ng-container>
    </div>
    <ng-template #driverTemplate let-exportFlag>
      <ng-container *ngFor="let chart of dimensionChart">
        <div class="innerPage break-before bg-white" *ngIf="chart?.data$ as data">
          <chart [wrapRows]="true" [reportsPage]="false" [exportInitiated]="exportInitiated" [dashlet]="byDriverDashlet" [dashletData]="data" [currencySymbol]="'$'" [language]="language" [dataSymbol]="' %'" [disableGrouping]="true" [showDrillDown]="true" [chartColors]="[]">
          </chart>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #company let-exportFlag>
      <ng-container *ngFor="let chart of companyChart; let c = index">
        <div class="innerPage break-before bg-white" *ngIf="chart?.data$ as data">
          <chart [wrapRows]="true" [disableChartTitle]="false" [reportsPage]="false" [exportInitiated]="exportInitiated" [dashlet]="chart" [dashletData]="data" [currencySymbol]="'$'" [language]="language" [dataSymbol]="' %'" [disableGrouping]="true" [showDrillDown]="true" [chartColors]="[]">
          </chart>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
<product-tour *ngIf="wellbeingData?.length > 0" [key]="'wellbeing-comparison'" [offset]="60" [destroy]="true"></product-tour>