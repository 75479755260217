import { updateQuestionHeaderHTML } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";


/**
 * Updates the HTML representation of a star rating question.
 *
 * @param options - The options for updating the star rating question HTML.
 *   - htmlElement: HTMLElement - The HTML element to update with the star rating question.
 *   - question: Object - The configuration object for the star rating question.
 *     - rateCount: number - The number of rating stars to display.
 *     - rateType: string - The type of rating stars to display (e.g., "stars", "fa-heart", "fa-thumbs-up", "smileys").
 */
export function updateRatingStarsQuestionHTML(options: any) {
  // Update question header
  updateQuestionHeaderHTML(options, "Star Rating", "fa-star");

  // Get the question container element
  let body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body)
    body = options.htmlElement as HTMLDivElement;

  body.classList.add("mt-5");
  body.innerHTML = "";

  var constraints = [];

  constraints = options.question['ratingLabels'] || [];

  var filteredConstraints = filterRange(constraints, options.question['rateMin'], options.question['rateMax']);
  var filledConstraints = fillMissingConstraints(filteredConstraints, options.question['rateMin'], options.question['rateMax']);
  var uniqueConstraints = getUniqueConstraints(filledConstraints);

  // // Create a new container for the rating question elements
  // const newRatingQuestionContent = document.createElement("div");
  // newRatingQuestionContent.className = "flex flex-col gap-3 py-4 w-full rank-items-container";

  // // Add the rating question container to the main container
  // body.appendChild(newRatingQuestionContent);

  var container = document.createElement("div");
  container.className = "flex w-full gap-1 justify-center";




  // // Create a container for the rating stars
  // const starsContainer = document.createElement("div");
  // starsContainer.className = "flex flex-row flex-wrap items-center justify-center gap-2";

  var iconType = "fa-star";

  switch (options.question.rateType) {
    case "stars":
      iconType = "fa-star";
      break;

    case "fa-heart":
      iconType = "fa-heart";
      break;

    case "fa-thumbs-up":
      iconType = "fa-thumbs-up";
      break;

    case "smileys":
      iconType = "fa-face-smile";
      break;

    default:
      iconType = "fa-star";
      break;
  }



  for (var i = 0; i < uniqueConstraints.length; i++) {
    var constraint = uniqueConstraints[i];

    var constraintContainer = document.createElement("div");
    constraintContainer.className = "flex flex-col items-center gap-2";

    var itemsDiv = document.createElement("div");
    itemsDiv.className = "flex w-full gap-1 justify-center";

    for (var j = constraint.min; j <= constraint.max; j++) {


      var star = document.createElement("i");

      star.className = iconType;
      star.classList.add("fa-light");
      star.style.color = constraint.color? constraint.color : "#AEBBD1";
      star.style.fontSize = "42px";

      itemsDiv.appendChild(star);
    }

    var constraintText = document.createElement("label");
    constraintText.className = "label-gray whitespace-normal";
    constraintText.innerHTML = constraint.text.default? constraint.text.default : constraint.text;


    constraintContainer.appendChild(itemsDiv);
    constraintContainer.appendChild(constraintText);

    container.appendChild(constraintContainer);
    body.appendChild(container);
  }

  // // Loop through the rateCount to create rating stars
  // for (let i = 0; i < options.question.rateCount; i++) {
  //   const star = document.createElement("i");

  //   // Set the class of the star based on the rateType
  //   star.className = iconType;
  //   // Set the appropriate class and color for the star
  //   star.classList.add(i === 0 ? "fa-solid" : "fa-light");
  //   star.style.color = i === 0 ? "#0082C6" : (i === 1 ? "#586F99" : "#AAB7CC");
  //   star.style.fontSize = "42px";

  //   // Append the star to the stars container
  //   starsContainer.appendChild(star);
  // }

  // newRatingQuestionContent.appendChild(starsContainer);
}


/**
 * Filters the range of constraints based on the given minimum and maximum values.
 * @param constraints - The constraints to filter.
 * @param min - The minimum value.
 * @param max - The maximum value.
 * @returns The updated constraints within the range.
 */
export function filterRange(constraints: any, min: number, max: number) {
  const updatedConstraints = constraints.map(constraint => {
    // If the constraint's minimum value is less than the minimum range, update it to the minimum range
    if (constraint.min < min) {
      constraint.min = min;
    }

    // If the constraint's maximum value is greater than the maximum range, update it to the maximum range
    if (constraint.max > max) {
      constraint.max = max;
    }

    return constraint;
  });

  return updatedConstraints;
}

/**
 * Retrieves unique constraints from the given list of constraints.
 * @param constraints - The list of constraints.
 * @returns The unique constraints.
 */
export function getUniqueConstraints(constraints: any) {
  const uniqueConstraints = [];

  for (let i = 0; i < constraints.length; i++) {
    const foundConstraint = uniqueConstraints.find(c => c.min === constraints[i].min && c.max === constraints[i].max);

    // If the constraint is not found in the unique constraints list, add it
    if (!foundConstraint) {
      uniqueConstraints.push(constraints[i]);
    }
  }

  return uniqueConstraints;
}

/**
 * Fills in the missing constraints within the given range.
 * @param constraints - The list of constraints.
 * @param min - The minimum value of the range.
 * @param max - The maximum value of the range.
 * @returns The filled constraints.
 */
export function fillMissingConstraints(constraints: any, min: number, max: number) {
  const defaultColor = "#AAB7CC";
  const defaultLabel = "";

  const filledConstraints = [];
  let currentMin = null;
  let currentMax = null;
  let currentText = null;
  let currentColor = null;

  for (let i = min; i <= max; i++) {
    const foundConstraint = constraints.find(c => i >= c.min && i <= c.max);

    if (foundConstraint) {
      // If a constraint is found, push any pending current constraint and add the found constraint
      if (currentMin !== null && currentMax !== null) {
        filledConstraints.push({ min: currentMin, max: currentMax, text: currentText, color: currentColor });
        currentMin = null;
        currentMax = null;
        currentText = null;
        currentColor = null;
      }
      filledConstraints.push(foundConstraint);
    } else {
      // If no constraint is found, update the current constraint values
      if (currentMin === null) {
        currentMin = i;
        currentText = defaultLabel;
        currentColor = defaultColor;
      }
      currentMax = i;
    }
  }

  // Push the last pending current constraint if there is one
  if (currentMin !== null && currentMax !== null) {
    filledConstraints.push({ min: currentMin, max: currentMax, text: currentText, color: currentColor });
  }

  return filledConstraints;
}
