<div class="flex flex-col gap-2 w-full relative minw-44" (focus)="!disabled ? initDateDrop():''" tabindex="0">
  <h5 *ngIf="label" class="text-xs text-zen font-medium">{{ label }} <i *ngIf="required"
      class="fa-regular fa-asterisk text-xxs"></i></h5>
  <div #trigger (click)="!disabled ? initDateDrop($event) : ''"
    [ngClass]="{ 'opacity-70 pointer-events-none' : disabled, 'border-zen-red text-zen-red':ngControl.control.errors && (ngControl.touched || submitted), 'border-zen border-opacity-25 text-zen':!ngControl.control.errors || (!ngControl.touched && !submitted) }"
    class="text-sm rounded  border bg-white px-3 h-9 gap-3 leading-9 truncate cursor-pointer flex flex-row justify-between items-center">
    <div class="flex flex-row justify-start gap-2 items-center">
      <span class="text-sm text-zen select-none"
        [ngClass]="{'text-opacity-50':!date?.Gregorian}">{{!date?.Gregorian?(placeholder?placeholder:'01/01/1999'):calendarType != Calendar.Hijri?(date?.Gregorian | date:'dd/MM/yyyy'): hijriDate(date.Hijri)}}</span>
    </div>
    <i *ngIf="!disabled" class="fa-light fa-calendar-days"></i>
  </div>
</div>
<div #outerBody *ngIf="open"
  class="flex z-max flex-col justify-center items-center select-none top-0 left-0 h-0 w-0 fixed bg-zen bg-opacity-5 main-overlay"
  [ngClass]="{ 'h-full w-full': open }" (click)="close()">
  <div #body class="top-0 left-0" [ngClass]="{'h-full justify-center items-center':mobileView,'fixed': !mobileView}">
    <div class="flex flex-col relative " [ngClass]="{'h-full justify-center items-center':mobileView}">
      <div (click)="$event.stopPropagation()" [@fadeUpDown] *ngIf="innerOpen" class="flex flex-row  pt-1"
        [ngClass]="{'absolute w-0' :!mobileView,'bottom-0':reverse} "
        [style.marginLeft.px]="oppositeRight?triggerWidth:oppositeLeft? -triggerWidth:''">
        <div class="flex flex-col bg-white min-w-max rounded z-50" (click)="yearList = monthList = false">
          <div class="flex flex-row items-center justify-between gap-5 h-14 px-2">
            <div class="flex flex-row items-center gap-1">
              <div class="relative">
                <div (click)="monthList = !monthList; scroll(tDate.Gregorian,'m'); yearList = false; $event.stopPropagation()"
                  class="btn flex flex-row items-center gap-1 leading-9 rounded font-medium  px-3 hover:bg-zen hover:bg-opacity-5 relative">
                  <h5 class="text-zen maxw4rem truncate whitespace-nowrap">{{ getMonthName(tDate)}}</h5>
                  <i class="far fa-chevron-down text-xs text-zen font-semibold"></i>
                </div>
                <ng-container *ngTemplateOutlet="list, context:{$implicit: months, controller:monthList, type:'m'}">
                </ng-container>

              </div>

              <div class="relative">
                <div (click)="yearList = !yearList; scroll(tDate.Gregorian,'y'); monthList = false; $event.stopPropagation()"
                  class="btn flex flex-row items-center gap-1 leading-9 rounded font-medium  px-3 hover:bg-zen hover:bg-opacity-5 relative">
                  <h5 class="text-zen">{{calendarType == Calendar.Hijri?tDate.Hijri.year: (tDate.Gregorian|
                    date:'YYYY')}}</h5>
                  <i class="far fa-chevron-down text-xs text-zen font-semibold"></i>

                </div>
                <ng-container *ngTemplateOutlet="list, context:{$implicit: years, controller:yearList, type:'y'}">
                </ng-container>
              </div>

            </div>
            <a zenButton styles="secondary height-6" (click)="changeCalendarType();" >{{(calendarType == Calendar.Gregorian? "txtHijriChar" :"txtCharGregorian") | translate:language:ModuleNames.Shared }}</a>

          </div>
          <mat-divider></mat-divider>
          <div class="flex flex-row items-center cellH">
            <h5 *ngFor="let day of shortDays" class="cellW cellH text-xs text-center leadingH text-zen">{{day}}
            </h5>
          </div>
          <div class="flex flex-row flex-wrap text-zen fixedWidth">
            <div (click)="chosenDay(day)" *ngFor="let day of days; let n = index"
              [ngClass]="disabledDays(tDate.Gregorian,day.Gregorian)?'opacity-50 pointer-events-none':''"
              class="cellH cellW leadingH text-center text-sm border border-zen border-opacity-10 cursor-pointer font-semibold p-px">

              <h5
                [ngClass]="sameDay(tDate.Gregorian,day.Gregorian) && !todayCheck(day.Gregorian) ?'text-white bg-'+ color:'hover:bg-zen hover:text-zen hover:bg-opacity-10'"
                class="h-full w-full text-center leading-10 "><span
                  class="h-full min-w-full block text-center leading-10 "
                  [ngClass]="todayCheck(day.Gregorian) && !sameDay(tDate.Gregorian,day.Gregorian)?'bg-opacity-10 text-'+ color +'  bg-'+ color +' text-white':todayCheck(day.Gregorian) && sameDay(tDate.Gregorian,day.Gregorian)?'text-white bg-'+ color:'hover:bg-zen hover:bg-opacity-20'">
                  {{ calendarType == Calendar.Hijri? day.Hijri.date : ((day.Gregorian|date:'d') || '')}}</span></h5>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="invalid && !date" class="alert alert-danger transform translate-y-1 h-0">
  <validation-required [language]="language"></validation-required>
</div>


<ng-template #list let-list let-controller="controller" let-type="type">

  <div *ngIf="controller"
    class="flex flex-col bg-white rounded-md shadow-2x6 py-2 absolute top-10 max-h-44 innerListScroll transform -translate-x-1/2 left-1/2 z-10"
    (scroll)=" scrollControl(type, view)" #view>
    <ng-container *ngFor="let item of list; let n = index">
      <h5 [id]="'a'+item[calendarType].toString()" (click)="getYearMonth(item[calendarType],type, n);"
        class="text-sm leading-8 text-zen hover:bg-zen hover:bg-opacity-20 cursor-pointer whitespace-nowrap"
        [ngClass]="rtl?'pl-6 pr-4':'pr-6 pl-4'">
        {{item[calendarType]}}
      </h5>
    </ng-container>
  </div>

</ng-template>
