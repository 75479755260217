import { Component, Input } from '@angular/core';
import { AnalysisComponentModel } from '../../../utils/analysis-component-model';
import { WbBaseComponent } from '../../../utils/wellbeing-base.component';
import { listAnimation } from 'src/app/shared/animations/allAnimations';

@Component({
  selector: 'app-analysis-base-component',
  templateUrl: './analysis-base-component.component.html',
  styleUrls: ['./analysis-base-component.component.scss'],
  animations: [listAnimation]
})
export class AnalysisBaseComponentComponent extends WbBaseComponent {

  @Input()
  data: AnalysisComponentModel;

  constructor() {
    super()
  }

  extractNameLanguage(eG: any) {
    return this.LanguageService.extractNameLanguage(eG, this.data.language).replaceAll('[Company]', this.LanguageService.extractNameLanguage(this.data.selectedEntity?.name, this.data.language));
  }

}
