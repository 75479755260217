import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../core/services/language.service';

@Pipe({
  name: 'extract'
})
export class ExtractPipe implements PipeTransform {
  constructor(private languageService: LanguageService) { }
  transform(value: any, language) {
    if (this.languageService.extractNameLanguage(value, language)?.length)
      return this.languageService.extractNameLanguage(value, language)
    else
      return (value instanceof Array)? (value?.find(s => s?.name)?.name):'';
  }
}
