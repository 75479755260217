/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AnalyticalCriteria } from '../models/AnalyticalCriteria';
import type { FormTableCriteria } from '../models/FormTableCriteria';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class AnalyticalCriteriaService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param id 
     * @returns AnalyticalCriteria Success
     * @throws ApiError
     */
    public getCriteria(
id: string,
): Observable<AnalyticalCriteria> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analytical-criteria/get-criteria/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param formId 
     * @param tenantId 
     * @param companyId 
     * @returns AnalyticalCriteria Success
     * @throws ApiError
     */
    public getAllCriteriasByTenantAndForm(
formId: string,
tenantId: string,
companyId: string,
): Observable<Array<AnalyticalCriteria>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analytical-criteria/get-all-criterias-by-tenant-and-form/{formId}/{tenantId}/{companyId}',
            path: {
                'formId': formId,
                'tenantId': tenantId,
                'companyId': companyId,
            },
        });
    }

    /**
     * @param tenantId 
     * @param companyId 
     * @returns AnalyticalCriteria Success
     * @throws ApiError
     */
    public getAllCriteriasByTenant(
tenantId: string,
companyId: string,
): Observable<Array<AnalyticalCriteria>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analytical-criteria/get-all-criterias-by-tenant/{tenantId}/{companyId}',
            path: {
                'tenantId': tenantId,
                'companyId': companyId,
            },
        });
    }

    /**
     * @param tenantId 
     * @param companyId 
     * @param formId 
     * @returns AnalyticalCriteria Success
     * @throws ApiError
     */
    public getAllCriteriasInFormByTenantAndForm(
tenantId: string,
companyId: string,
formId: string,
): Observable<Array<AnalyticalCriteria>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analytical-criteria/get-all-criterias-in-form-by-tenant-and-form/{tenantId}/{companyId}/{formId}',
            path: {
                'tenantId': tenantId,
                'companyId': companyId,
                'formId': formId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns AnalyticalCriteria Success
     * @throws ApiError
     */
    public addCriteria(
requestBody?: AnalyticalCriteria,
): Observable<AnalyticalCriteria> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/analytical-criteria/add-criteria',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteCriteria(
id: string,
): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/analytical-criteria/delete-criteria/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns AnalyticalCriteria Success
     * @throws ApiError
     */
    public updateCriteria(
id: string,
requestBody?: AnalyticalCriteria,
): Observable<AnalyticalCriteria> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/analytical-criteria/update-criteria/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns boolean Success
     * @throws ApiError
     */
    public updateCriteriaInForm(
requestBody?: FormTableCriteria,
): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/analytical-criteria/update-criteria-in-form',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
