export class NewThomasClient {
    tenantKey: string;
    companies: ThomasCompany[];
    createdBy: string;
    clientId: string;
    clientSecret: string;
}
export class ThomasCompany {

    key: string;
    entitiesKeys: string[];
    metricsKeys: string[];
}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
