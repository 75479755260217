import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Entity } from '@ex/module/core/data-models/Entity';
import { EngagementRespondent } from '@ex/module/core/data-models/engagement-respondent';
import { PlatformUser } from '@ex/module/core/data-models/platform-user';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { LoaderService } from '@ex/module/core/services/loader.service';
import { LocalService } from '@ex/module/core/services/local.service';
import { ResponseDialogService } from '@ex/module/core/services/response-dialog.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { RespondentTableFetchObject, RespondentsTableService } from '@ex/module/survey-x/data-access/services/respondents-table.service';
import { ResponsesService } from '@ex/module/survey-x/data-access/services/responses.service';
import { SurveysService } from "@ex/module/survey-x/data-access/services/surveys.service";
import { FormResponseService, FormService, RespondentService } from '@ex/survey-x/index';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { RespondentService as RespondentPoolService } from 'src/app/core/services/respondent.service';
@Component({
  selector: 'app-respondents-page',
  templateUrl: './respondents-page.component.html',
  styleUrls: ['./respondents-page.component.scss'],
  providers: [RespondentsTableService]
})
export class RespondentsPageComponent extends BaseComponent implements OnInit {

  language;
  ModuleNames = ModuleName;

  formId = '';
  showAssignWeights = false;
  form: any = {};
  sub: any;
  tenant: any;
  tenantName: any;
  tenantKey: any;
  currentUser: any;
  currentUserName: any;
  respondents;
  filteredTableData: any[] = [];
  addRespondent = { tenantKey: '', open: false };

  selectedSortBy: any = "name";

  sortByList: any[] = [{ key: "name", name: "Name" }, { key: "email", name: "Email" }];
  options
  totalRecords
  currentPage
  pageSize
  selectedEntity: Entity;
  surveyData;
  companyKey: string;
  respondentAdd: boolean = false;
  user: PlatformUser;
  settingsLoaded$ = new Subject();
  pageChange$ = new BehaviorSubject(0);
  maxResultCount = 10;
  totalItems = 0;
  essentialObject;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private surveysService: FormService,
    public loader: LoaderService,
    private respondentsTableService: RespondentsTableService,
    public snackBar: MatSnackBar,
    private localService: LocalService,
    private respondentPoolService: RespondentPoolService,
    private formResponseService: FormResponseService,
    private responseDialogService: ResponseDialogService,
    private respondentService: RespondentService
  ) {
    super();

  }

  /**
   * Initializes the component.
   */
  ngOnInit() {
    this.formId = this.route.snapshot.paramMap.get('id');

    this.initTable();

    this.addSubscription(this.EssentialObject$.pipe(filter(x => x.selectedEntity?.key != null)).pipe(switchMap(obj => {
      return this.surveysService.getFormWithoutResponses(this.formId).pipe(map(data => ({ obj, data })))
    })).subscribe(({ obj, data }) => {
      this.selectedEntity = obj.selectedEntity;
      this.companyKey = obj.selectedEntity.key;
      this.tenantKey = obj.tenant.key;
      this.user = obj.user;
      this.essentialObject = obj;
      this.surveyData = data.query;
      this.language = obj.language;
      this.settingsLoaded$.next();
    }))

    // Get the ID of the form being managed.




    // this.language = this.form.locale.toUpperCase();

    // if (this.form.locale == "ar")
    //   this.direction = "rtl"
    // else
    //   this.direction = "ltr"

  }

  // getRespondents() {
  //   const sub = combineLatest([this.settingsLoaded$, this.pageChange$]).pipe(
  //     switchMap(([_, page]) => {
  //       return this.formResponseService.getPagedFormResponsesById(this.formId, this.tenantKey, this.companyKey, page, this.maxResultCount);
  //     })
  //   ).subscribe((results: any) => {
  //     this.respondents = results.items
  //     this.totalItems = results.totalCount;
  //   })

  //   this.addSubscription(sub);
  // }


  initTable() {
    this.addSubscription(this.settingsLoaded$.pipe(switchMap(_ => {
      return this.respondentsTableService.init({
        language: this.language,
        companyKey: this.selectedEntity.key,
        tenantKey: this.tenantKey,
        options: { disableSearch: true },
        survey: { key: this.formId, title: this.form.title }
      })
    })).subscribe((obj: RespondentTableFetchObject) => {
      this.options = obj.options;
      this.totalRecords = obj.totalRecords;
      this.currentPage = obj.pageNumber;
      this.pageSize = obj.pageSize;
      this.LoaderService.hideLoader();
    }))

  }

  searchTextUpdate(text) {
    this.respondentsTableService.searchText.next(text);
    this.respondentsTableService.pageNumber.next(0);
  }

  onPageChange(e) {
    this.currentPage = e;
    this.respondentsTableService.pageNumber.next(this.currentPage);
  }
  selectedAll(event) {
    this.respondentsTableService.selectAll = event;
  }
  downloadExcel(data?: any) {
    window.open('/assets/downloads/Respondents Template.xlsx', '_blank');
  }
  createMultipleRespondent(data: any) {
    let reData = {
      tenantKey: this.tenantKey,
      companyKey: this.companyKey,
      formId: this.formId,
      emails: data.type == "ALL" ? data.exclude.map(x => x.email) : data.respondents.map(x => x.email),
      isSelectAll: data.type == "ALL" ? true : false
    }

    // let reData: BulkAssignToSurveyDto = {
    //   emails: data.respondents.map(x => x.email),
    //   isSelectAll: data.allSelected
    // };
    this.formResponseService.bulkAssignToForm(reData).subscribe(respondentData => {
      this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
      this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
      this.respondentAdd = false;
      this.LoaderService.hideLoader();
    })
  }
  insertUsingExcel(data: any) {
    let request = {
      FileKey: 'RESPOOL',
      TenantKey: this.tenantKey,
      CompanyKey: this.companyKey,
      UserKey: this.user.key,
      FileBase: data
    };

    this.respondentPoolService
      .updloadRespondents(request)
      .pipe(
        switchMap(response => {
          return this.formResponseService.excelUpload({
            formId: this.formId,
            companyKey: this.companyKey,
            tenantKey: this.tenantKey,
            excelFile: data.split(",")[1]
          })
        })
      )
      .subscribe(respondentData => {
        this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
        this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
        this.ZenCDKService.closeAll();
        this.respondentAdd = false;
        this.LoaderService.hideLoader();
      })
  }
  createSingleRespondent(data: any) {
    // Request to upload respondent to the company pool
    let respondents: EngagementRespondent[] = []
    respondents.push(data);

    let request = {
      TenantKey: this.tenantKey,
      CompanyKey: this.companyKey,
      UserKey: this.user.key,
      respondents: respondents
    }

    // Request to upload respondent to the wellbeing respondents
    let respondentData = {
      respondentName: data.name,
      respondentEmail: data.email,
      formId: this.formId,
      companyKey: this.companyKey,
      tenantKey: this.tenantKey,
    };

    this.respondentPoolService.updateRespondentPool(request).pipe(
      switchMap(data => {
        return this.formResponseService.addFormResponse(respondentData)
      })
    ).subscribe(respondentData => {
      this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
      this.SnackbarService.success(this.localize('txt_added_success', this.language, this.ModuleNames.Wellbeing));
      // this.ZenCDKService.removePortal();
      this.LoaderService.hideLoader();
    }, error => {
      this.SnackbarService.error(this.localize("respondent already exists", this.language, this.ModuleNames.SurveyX))
    })
  }

  async deleteRespondent(respondent: any) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondent_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.SurveyX,
        this.language
      );
      if (confirmed) {

        this.addSubscription(this.formResponseService.deleteFormResponse(respondent.id).subscribe(x => {
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.SurveyX));

          if (this.totalRecords % this.pageSize == 1 && this.totalRecords > 1 && this.options.data.length == 1)
            this.onPageChange(this.currentPage - 1)
          else
            this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement);
          // this.totalRecords--;

        }))
      }
    } catch (ex) {

    }
  }
  async deleteRespondents(respondents: any[]) {
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondents_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.SurveyX,
        this.language
      );
      if (confirmed) {
        let emails = respondents.map(x => x.respondentEmail);
        this.formResponseService.bulkDelete({ formId: this.formId, emails: emails, isDeleteAll: this.respondentsTableService.selectAll }).subscribe(x => {
          this.options = {};

          if (this.totalRecords % this.pageSize == respondents.length && this.totalRecords > 1)
            this.onPageChange(this.currentPage - 1)
          else
            this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement);
          // this.totalRecords--;

        });
      }
    } catch (ex) {

    }
  }

  async uploadRequest(fileBase) {
    // let request = {
    //   FileKey: 'RESLIST',
    //   TenantKey: this.tenant.key,
    //   CompanyKey: this.selectedEntity.key,
    //   SurveyKey: this.formId,
    //   UserKey: this.user.key,
    //   SurveyType: 'ENG',
    //   FileBase: fileBase,
    //   sendEmail: false,
    //   surveyInfo: this.engagementData.engagement,
    //   CompanyName: this.selectedEntity.name
    // }

    // this.addSubscription(
    //   this.respondentService
    //     .updloadRespondents(request)
    //     .subscribe((response: any) => {
    //       if (response.response == 'SUCCESS')
    //         this.respondentAdd = false;
    //     }));

  }
  bulkUpdateRespondents(request) {
    try {
      if (request.type == 'ALL' && request?.exclude?.length > 0)
        request.respondents = request?.exclude;

      // this.addSubscription(this.respondentService.bulkAddSurveyRespondent(request).subscribe((response: any) => {
      //   //this.fetchEngagementRespondents();
      //   this.respondentAdd = false;
      // }));
    } catch (ex) {

    }
  }
  updateEngagementRespondent(e) { }
  downloadCSV(e) {
    window.open('/assets/downloads/Respondents Template.xlsx', '_blank');
  }
  changeListener(e) { }





  saveChanges() {
    // this.sharedService.setRespondents(this.tableData);
    // this.sharedService.setFormId(this.formId);
    // this.sharedService.setForm(this.form);
  }

  /**
   * Navigates to the next step in the form builder and sets the selected respondents and form ID in the shared service.
   */
  nextStep() {
    this.router.navigateByUrl(`/survey-x/form-builder/share/${this.formId}`);
    // if (this.totalRecords > 0) {
    // }
    // else {
    //   this.openSnackBar();
    // }
  }

  openSnackBar() {
    this.SnackbarService.error(this.localize("Please add respondents to continue", this.language, this.ModuleNames.SurveyX))

  }

  /**
   * Navigates to the previous step in the form builder.
   */
  previousStep() {
    this.router.navigateByUrl(`/survey-x/form-builder/analytical-criteria/${this.formId}`);
    this.saveChanges();
  }

  pageChanged(event: any) {
    this.router.navigateByUrl(`/survey-x/form-builder/${event}/${this.formId}`);
    this.saveChanges();
  }

  /**
   * Gets the respondents for the form and sets the table data and filtered table data.
   */

  /**
   * Opens the add respondent view.
   */




}
