import { LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CDKPortalComponent, DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
import { Hierarchy } from 'src/app/core/data-models/hierarchy';
import { BaseComponent } from '@ex/module/core/domain/base-component';
export interface TreeListData {
  treeList: any,
  tselectedChildEntity: Hierarchy,
}
@Component({
  selector: 'app-sx-tree-options',
  templateUrl: './sx-tree-options.component.html',
  styleUrls: ['./sx-tree-options.component.scss']
})
export class SXTreeOptionsComponent extends BaseComponent implements OnInit, CDKPortalComponent {


  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  constructor(@Inject(DATA_TOKEN) private data: { componentData: TreeListData, language: string }, private router: Router, private location: LocationStrategy) {
    super();

  }

  ngOnInit(): void {
    this.language = this.data.language;
    this.treeList = this.data.componentData.treeList;
    this.tselectedChildEntity = this.data.componentData.tselectedChildEntity;
    this.open = true;
  }

  language;
  open: boolean;
  selectedChildEntity: Hierarchy;
  tselectedChildEntity: Hierarchy;
  treeList: any;
  closeModal() {
    this.open = false;
    this.close.emit(true);
  }



  tselectedChildEntityChanged(entity: Hierarchy) {
    if (!this.tselectedChildEntity['hasPermission'])
      return;

    if (entity.entity.count < 3)
      return this.SnackbarService.error(this.localize("txtAtleastRespondentsRequired", this.language, this.ModuleNames.Wellbeing));
  }

  entitySelected() {
    if (!this.tselectedChildEntity['hasPermission'])
      return;

    if (this.tselectedChildEntity.entity.count < 3)
      return this.SnackbarService.error(this.localize("txtAtleastRespondentsRequired", this.language, this.ModuleNames.Wellbeing));

    this.results.emit(this.tselectedChildEntity)
  }
}
