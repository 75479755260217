<div  class="w-screen h-screen bg-zen bg-opacity-50 flex flex-row items-center justify-center fixed top-0 left-0 z-50 py-10 px-5" [ngClass]="{'rtl':
(language | language )}">
  <div class="max-w-5xl w-full flex flex-col bg-white bg-opacity-95 py-10 rounded max-h-screen-90">
    <div class="flex flex-row items-center justify-between px-10 pb-5">
      <div class="flex flex-row items-center gap-2">
        <i class="fal fa-users text-white bg-exp shadow-xl rounded-full h-10 w-10 text-xl text-center leading-10 mnw10"></i>
        <h4 class="text-2xl font-bold text-zen">{{drillDownInfo.name}}</h4>
      </div>
    </div>

    <!-- end header -->
    <!-- body -->
    <div class="flex flex-col gap-1 px-10 py-10 innerListScroll  min-w-80">
      <div
        class="flex flex-row w-full justify-start items-center rounded p-5 bg-exp bg-opacity-5 btn transition transform hover:-translate-y-px"
        *ngFor="let info of drillDownInfo.information">
        <span class="text-zen font-medium w-36">{{info.ID}}</span>
        <p><span class="text-zen font-medium">{{ info.Name }}</span></p>
      </div>
    </div>
    <!-- footer -->
    <div class="flex flex-row justify-center items-center px-10 pt-5">
      <a [mat-dialog-close]="true"
        class="btn cancel  text-center  rounded-md px-8 py-4 uppercase text-lg font-medium">{{'btn_close'|translate:language:ModuleNames.Shared}}</a>
    </div>
    <!-- end footer -->
    <!-- end body -->
  </div>


</div>
