import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { environment } from 'src/environments/environment';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})

export class TranslationsService {
  constructor(private http: HttpClient, private generalService: GeneralService) {
    this.isDev = !environment.jsonTranslation;
  }

  translationAPI = environment.translationAPI;
  isDev;
  getTranslations(): Promise<any> {

    if (this.isDev) {
      return this.getTranslationsModuleData();
    }
  }

  async getTranslationsModuleData(): Promise<any> {

    let response = await this.getTranslationsModule().toPromise();
    // this.getTranslationsModule().subscribe((res: any) => {
    //   localStorage.setItem(ModuleName.Assessment, JSON.stringify(res[ModuleName.Assessment]));
    //   localStorage.setItem(ModuleName.Assessment180, JSON.stringify(res[ModuleName.Assessment180]));
    //   localStorage.setItem(ModuleName.Engagement, JSON.stringify(res[ModuleName.Engagement]));
    //   localStorage.setItem(ModuleName.Happiness, JSON.stringify(res[ModuleName.Happiness]));
    //   localStorage.setItem(ModuleName.Psychometric, JSON.stringify(res[ModuleName.Psychometric]));
    //   localStorage.setItem(ModuleName.Surveys, JSON.stringify(res[ModuleName.Surveys]));
    //   localStorage.setItem(ModuleName.Exit, JSON.stringify(res[ModuleName.Exit]));
    //   localStorage.setItem(ModuleName.Common, JSON.stringify(res[ModuleName.Common]));
    //   localStorage.setItem(ModuleName.Shared, JSON.stringify(res[ModuleName.Shared]));
    //   localStorage.setItem(ModuleName.Onboarding, JSON.stringify(res[ModuleName.Onboarding]));
    //   localStorage.setItem(ModuleName.Tooltips, JSON.stringify(res[ModuleName.Tooltips]));
    //   localStorage.setItem(ModuleName.Wellbeing, JSON.stringify(res[ModuleName.Wellbeing]));
    //   localStorage.setItem("Default", JSON.stringify(res["Default"]));
    //   response = res;
    // }, (err) => { response = [] })
    // await this.getTranslationsModule().toPromise()

    if (response) {
      localStorage.setItem(ModuleName.Assessment, JSON.stringify(response[ModuleName.Assessment]));
      localStorage.setItem(ModuleName.Assessment180, JSON.stringify(response[ModuleName.Assessment180]));
      localStorage.setItem(ModuleName.Engagement, JSON.stringify(response[ModuleName.Engagement]));
      localStorage.setItem(ModuleName.Happiness, JSON.stringify(response[ModuleName.Happiness]));
      localStorage.setItem(ModuleName.Psychometric, JSON.stringify(response[ModuleName.Psychometric]));
      localStorage.setItem(ModuleName.Surveys, JSON.stringify(response[ModuleName.Surveys]));
      localStorage.setItem(ModuleName.Exit, JSON.stringify(response[ModuleName.Exit]));
      localStorage.setItem(ModuleName.Common, JSON.stringify(response[ModuleName.Common]));
      localStorage.setItem(ModuleName.Shared, JSON.stringify(response[ModuleName.Shared]));
      localStorage.setItem(ModuleName.Onboarding, JSON.stringify(response[ModuleName.Onboarding]));
      localStorage.setItem(ModuleName.Tooltips, JSON.stringify(response[ModuleName.Tooltips]));
      localStorage.setItem(ModuleName.Wellbeing, JSON.stringify(response[ModuleName.Wellbeing]));
      localStorage.setItem(ModuleName.SurveyX, JSON.stringify(response[ModuleName.SurveyX]));
      localStorage.setItem("Default", JSON.stringify(response["Default"]));
    }

    return new Promise(() => {
      return response;
    })
  }

  getTranslationsModule() {
    return this.http.get(this.translationAPI + 'Translations/GetTranslations?path=EX');
  }
}
