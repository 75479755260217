<div *DemoAccount="'Default'; let isDemo=isDemo" class="w-full heatTable max-w-full overflow-auto" [ngClass]="(language|language)?'rtl':'ltr'">
    <table style="background-color: transparent; border-collapse: separate; border-spacing: 2px;" class="min-w-full sameWidth bg-transparent" mat-table [dataSource]="heatSource">
      <ng-container [sticky]="n <= 1"  [matColumnDef]="column.field" *ngFor="let column of heatColumns; let n = index">
        <th [ngClass]="{'left22':n == 1 ,'maxMinw10':n != 0, 'maxMinw22 ':n == 0}" class="border-b-0 firstHead" [matTooltip]="column.name" [attr.rowspan]="n == 0? 8 : '' " mat-header-cell *matHeaderCellDef>
          <p class="uppercase  text-zen opacity-60 px-5">{{ column.name }}</p>
        </th>
        <td class="border-b-0 bg-white px-0 relative " [ngClass]="{'maxMinw22':n == 0,'left22':n == 1 ,'maxMinw10':n != 0,'rounded-r-sm':(n == 0 || n == heatColumns.length) && (language | language), 'rounded-l-sm':(n == 0 || n == heatColumns.length) && !(language | language), 'max-w-xl':n == 0, 'firstCell cursor-pointer':n == 0 && element.isDriver}"  (click)="element.isDriver ? firstTwoDrivers(element, isDemo): ''"  mat-cell *matCellDef="let element">
            <p *ngIf=" n == 0" class="truncate text-zen px-5" [ngClass]="(language | language)? 'text-right':'text-left'"
              [matTooltip]="element.isDriver? '': element.name">{{ element.name}}</p>

          <span *ngIf=" n == 1">
            <p class="px-5 text-center">{{ toFixed(element.overAll, 1) + '%'}}</p>

          </span>
          <div class="heatScore" *ngIf="n > 1">
            <div *ngFor="let op of element.options; let m = index"  >
              <h5 *ngIf="m+2 == n"  [ngClass]=" heatColor(op <= 0 ? op : '+' + op)" class="px-5 absolute w-full h-full top-0 left-0">
                {{ op <= 0 ? op : '+' + op }} %</h5>
              </div>
            </div>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getRows(heatColumns)"></tr>
      <tr [@heatAnimation] [ngClass]="row.isDriver? 'border-t-4 border-b-4 border-transparent': ''" mat-row *matRowDef="let row; columns: getRows(heatColumns);"></tr>
    </table>
  </div>

  <div class="flex flex-row justify-between gap-1 items-center h-10 rounded-sm overflow-hidden mt-5">
    <div class="heatScore darkred">
    </div>
    <div class="heatScore red">
    </div>
    <div class="heatScore lightred">
    </div>
    <div class="heatScore white">
    </div>
    <div class="heatScore lightblue">
    </div>
    <div class="heatScore blue">
    </div>
    <div class="heatScore darkblue">
    </div>
  </div>
