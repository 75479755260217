<zen-small-modal-header-start [dir]="direction" [ngClass]="{'RTL':language == 'AR'}" [open]="open">
  <ng-container header>

<div class="flex flex-row w-full justify-between">
    <div class="flex flex-row items-start w-full justify-start gap-2 ">

      <div class="flex h-10 pcc items-center justify-start  gap-2">
<div class="flex h-5 justify-center items-center">
<i class="flex w-5 h-5 flex-col justify-center text-[#7D92B5] text-center text-xl font-light fa-regular fa-share-alt"></i>
</div>
      </div>

      <div class="flex flex-col h-10 pr-0 justify-center items-start gap-1 ">
       <h2 class="flex flex-col shrink-0 self-stretch text-zen-primarydefault text-lg font-medium tracking[-0.36px]">
        {{"txtShareSurvey" |translate:language:ModuleNames.SurveyX}}
      </h2>
      </div>
    </div>

    <div class="flex items-start justify-end ">
      <div (click)="closeModal('open'); openChange.emit(false)" class=" btn flex w-10 h-10 justify-center items-center rounded bg-zen-littlegray">
      <div class="flex h-4 items-center gap-3">
      <div class="flex w-5 h-5 justify-center items-center">
      <i class="flex flex-col w-4 justify-center shrink-0 text-zen-primarylight text-center text-base font-normal fa-regular fa-times"></i>
      </div>
      </div>
      </div>
            </div>
          </div>
  </ng-container>

  <ng-container body>
<div class="flex flex-col items-start gap-6 self-stretch cp">
    <div class="flex flex-col items-start gap-2">

                  <label class="flex self-stretch text-sm font-medium text-zen-primarydefault">{{"txtUsealink" |translate:language:ModuleNames.SurveyX}}</label>
                  <h5 class="flex self-stretch text-xs font-normal text-zen-primarylight">{{"txtAnyonewiththelinkbelowcantakethesurvey" |translate:language:ModuleNames.SurveyX}}</h5>

<div class="flex flex-row items-end gap-2 w-[436px]">

  <div class="flex flex-col justify-center items-start gap-1 flex-1">
    <div class="flex flex-col h-10 pc justify-center items-start gap-2 self-stretch">
<div class="flex items-center gap-2 self-stretch">
<div class="flex items-center gap-1.5 flex-1">

<div class="flex w-5 h-5 justify-center items-center">
<i class="fa-regular fa-link flex flex-col justify-center shrink-0 text-[#7D92B5] text-center text-base font-normal"></i>
</div>
<h5 class="flex h-6 flex-col justify-center flex-1 text-zen-primarydefault truncate text-sm font-normal max-w-[342px] ">
{{link}}
</h5>
</div>
</div>
    </div>
  </div>

<div [matTooltip]="'txtCopyLink' | translate:language:ModuleNames.SurveyX" matTooltipPosition="above" matTooltipClass="zen-tooltip" (click)="copyLink(); openSnackBar();" class="btn flex items-start">
<div class="flex w-10 h-10 justify-center items-center rounded bg-[#EFF1F6]">
<div class="flex items-center gap-3">
<div class="flex w-5 h-5 justify-center items-center">
<i class="flex flex-col justify-center shrink-0 text-zen-primarylight text-center text-base font-normal fa-regular fa-copy"> </i>
</div>
</div>
</div>
</div>

</div>
        </div>
      </div>
  </ng-container>
  <ng-container footer>
      <div class="flex flex-row justify-center w-full">
          <a (click)="closeModal('open'); openChange.emit(false)" class="btn cancel w-full justify-center text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
            {{"close"| translate:language:ModuleNames.SurveyX}}
        </a>

      </div>
  </ng-container>


</zen-small-modal-header-start>
