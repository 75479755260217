import { Component, Input, OnInit } from '@angular/core';
import { ChildrenAnimation } from '../../animations/allAnimations';
import { ModuleName } from '../../enums/ModuleName';

@Component({
  selector: 'q-end-page',
  templateUrl: './end-page.component.html',
  styleUrls: ['./end-page.component.scss'],
  animations:[ChildrenAnimation]
})
export class EndPageComponent implements OnInit {
  @Input() status;
  @Input() stage;
  @Input() language;
  @Input() title;
  @Input() endTitle;
  @Input() endText;

  constructor() { }
  ModuleNames=ModuleName
  ngOnInit(): void {
  }

}
