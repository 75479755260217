<ng-container *ngIf="checklists?.length > 0" >
  <div class="customTree pb-20"  *DemoAccount="'Default'; let isDemo=isDemo" >
    <ng-container *ngFor="let ch of checklists" [tourStep]="tourStep">
      <mat-expansion-panel class="my-5 shadow-none exp" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header  class="expansion-header">
          <mat-panel-title *DemoAccount="i > 2?'Button':'Default';" >
           <mat-checkbox  (click)="$event.stopPropagation();" [disabled]="isDemo && i > 2" class="comment-checkbox" [ngModel]="ch.isChecked" (change)="checklistCheckedChanged($event,ch)" (ngModelChange)="i < 2?'':ch.isChecked = $event">
              {{ extractNameLanguage(ch.name) }}
            </mat-checkbox>
          </mat-panel-title>

        </mat-expansion-panel-header>
        <div class="container inner bg-white bg-opacity-5 rounded p-3" [ngClass]="{'pointer-events-none':isDemo && i > 2}">
          <mat-accordion *ngFor="let cG of ch.checklistGroups">
            <mat-expansion-panel
              class="my-5 shadow-none bg-white"
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header class="expansion-header-inner">
                <mat-panel-title>
                  {{ extractNameLanguage(cG.name) }}
                  {{
                    cG.isAveraged
                      ? " - " +
                        ("txtAverage"
                          | translate : language : ModuleNames.Shared)
                      : ""
                  }}
                </mat-panel-title>
                <mat-slide-toggle  *ngIf="isOptionalAveraged == true && cG.isAveraged === true" class="comment-checkbox mat-slide-toggle-label" [checked]="true" (change)="isAveragedChanged($event,ch, cG)">
                  <h3 class="maxw200 truncate" ></h3>
                </mat-slide-toggle>
              </mat-expansion-panel-header>

              <div class="container inner bg-zen bg-opacity-5 rounded p-3">
                <ng-container *ngFor="let cI of cG.checklistItems">
                  <div class="py-2 w-full" >
                    <mat-checkbox class="comment-checkbox" [ngModel]="cI.isChecked" (change)="checklistItemsCheckedChanged($event,ch, cG,cI); cI.isChecked = $event">
                      <h3 class="maxw200 truncate" >{{ extractNameLanguage(cI.name, true) }}</h3>
                    </mat-checkbox>

                  </div>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </div>
</ng-container>

<!-- <ng-container *ngIf="checklists?.length > 0">

  <ng-container *ngFor="let ch of checklists">

    <button (click)="ch.expanded = !ch.expanded;">{{ch.expanded ? 'collapse' : 'expand'}}</button><mat-checkbox class="comment-checkbox" [(ngModel)]="ch.isChecked" (change)="checklistCheckedChanged($event,ch)">
      {{ extractNameLanguage(ch.name) }}
    </mat-checkbox>
    <br>
    <ng-container *ngIf="ch.expanded">
    <ng-container *ngFor="let cG of ch.checklistGroups">
      <h6 style="margin-left: 70px;">{{ extractNameLanguage(cG.name) }} {{ cG.isAveraged ? ' - Average' : '' }}</h6>
      <br>
      <ng-container *ngFor="let cI of cG.checklistItems">
        <div style="margin-left: 100px;">
          <mat-checkbox class="comment-checkbox" [(ngModel)]="cI.isChecked" (change)="checklistItemsCheckedChanged($event,ch, cG,cI)">
            {{ extractNameLanguage(cI.name) }}
          </mat-checkbox>
        </div>
        <br>
      </ng-container>
    </ng-container>
  </ng-container>


  </ng-container>

</ng-container> -->
