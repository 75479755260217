import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[sticky]'
})
export class StickyDirective {
  @Input() top;
  @Input() bottom;
  @Output() sticky = new EventEmitter();
  constructor(private el: ElementRef,
    private renderer: Renderer2) {
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:scroll', ['$event'])
  @HostListener('document:mousewheel', ['$event'])
  onDocumentMousewheelEvent(event) {

    if (!this.top)
      this.top = document.querySelector('.h-header')?.clientHeight / 16;
    if (this.top) {
      //  
      if (this.el.nativeElement.parentNode?.getBoundingClientRect().top <= this.top * 16) {
        this.renderer.setStyle(this.el.nativeElement.children[0], 'top', this.top * 16 + 'px');
        this.renderer.setStyle(this.el.nativeElement.children[0], 'width', this.el.nativeElement.parentNode.getBoundingClientRect().width + 'px')
        this.renderer.setStyle(this.el.nativeElement.children[0], 'position', 'fixed');
        this.sticky.emit(true);
      }
      else if (this.el.nativeElement?.parentNode?.getBoundingClientRect().top > this.top * 16) {
        this.renderer.setStyle(this.el.nativeElement.children[0], 'position', 'static');
        this.sticky.emit(false);
      }
      if (this.el?.nativeElement?.children?.length)
        this.renderer.setStyle(this.el?.nativeElement?.children[0], 'width', this.el?.nativeElement?.parentNode.getBoundingClientRect().width + 'px')
    }

    else
      this.sticky.emit(false);
    // let footerValues = document.querySelector<HTMLElement>('footer').getBoundingClientRect();
    // if (this.bottom && window.innerHeight - (footerValues.bottom - footerValues.height) >= 0)
    //   this.bottom =
    //     window.innerHeight -
    //     (footerValues.bottom - footerValues.height) +
    //     footerValues.height / 4;
    // else this.bottom = footerValues.height / 4;
  }

}
