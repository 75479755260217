import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { isatty } from 'tty';




@Injectable({
  providedIn: 'root'
})

export class ResponsesService {
  errorMessage: any;
  postId: any;

  URL = environment.endPoint;

  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': 'https://assessments.zenithr.net',
      'Content-Type': 'application/json;'
    })
  }

  constructor(private httpClient: HttpClient) { }


  async getFormResponse(id: any) {
    return this.httpClient.get(this.URL + `form-response/get-form-response/${id}`);
  }


  createResponse(request: any) {
    return this.httpClient.post(this.URL + `form-response/add-response`, request);
  }

  getPagedFormResponsesById(formId: any, tenantKey: string, companyKey: string, skip: number, maxResultCount: number, isAllResults = false) {

    var request = {
      isAllResults: isAllResults,
      maxResultCount: maxResultCount,
      skipCount: skip,
      tenantKey: tenantKey,
      companyKey: companyKey
    }

    return this.httpClient.get(this.URL + `form-response/get-paged-list/${formId}`, { params: request });
  }

  delete(id) {
    return this.httpClient.delete(this.URL + `form-response/delete-form-response/${id}`);
  }

  excelUpload(request: { excelFile: string, formId: string, tenantKey: string, companyKey: string }) {
    return this.httpClient.post(`${this.URL}form-response/excel-upload`, request);
  }

  bulkAssign(request: { companyKey: string, tenantKey: string, formId: string, isSelectAll?: boolean, emails: string[] }) {
    return this.httpClient.post(`${this.URL}form-response/bulk-assign`, request)
  }
}
