/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StatusTypes {
    Draft = 1,
    Live = 2,
    Closed = 3,
}
