import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'heatmapFilter'
})
export class HeatmapFilterPipe implements PipeTransform {

  transform(values: any[], key: string): any[] {
    return values.filter(x => x.isDriver || x.parentKey == key);
  }

}
