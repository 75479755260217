<div *ngIf="question.dependencyQuestions" (click)="logData(question)"
    class="flex flex-row w-full py-1 items-center content-center gap-2 self-stretch flex-wrap ">

    <div class="flex flex-row w-full flex-wrap">

        <div class="flex w180 items-center">
            <h5 class="text-zen-primarydefault text-xs font-medium tracking-wide uppercase ">
                {{"txtCONDITIONALLOGICANSWER" | translate:language:ModuleNames.SurveyX}}:
            </h5>
        </div>

        <div *ngFor="let dQuestion of question.dependencyQuestions; let x = index" class="flex flex-row flex-nowrap">

            <div class="flex flex-row py-0.5 px-1 items-center gap-1.5 rounded bg-zen-paleWhite flex-nowrap">
                <h5 class="text-zen-primarydark text-xs font-medium">{{'txtQ' |
                    translate:language:ModuleNames.SurveyX}}{{getQuestionNumber(dQuestion.key)}}:</h5>

                <h5 class="text-exp text-xs font-normal">{{getOperation(dQuestion.operator) | translate:language:ModuleNames.SurveyX}}</h5>
                <h5 class="text-zen-primarydefault text-xs font-normal">({{dQuestion.answer}})</h5>

                <!-- <div *ngIf="sameDependencyQuestionMultiOperations?.length > 1" class="flex flex-row gap-1.5">

          <div *ngFor="let sDQuestion of sameDependencyQuestionMultiOperations; let s = index" class="flex flex-row gap-1.5">
            <h5 class="text-exp text-xs font-normal">{{sameDependencyQuestionMultiOperations[s+1].order}}</h5>  if multiple answers same quesiton

            <h5 class="text-exp text-xs font-normal">{{getOperation(sDQuestion.operator)}}</h5>  if multiple answers same quesiton

            <h5 class="text-zen-primarydefault text-xs font-normal">{{sDQuestion.answer[0]?sDQuestion.answer[0] : sDQuestion.answer }}</h5> if multiple answers same quesiton
          </div>
        </div> -->

            </div>

            <div *ngIf="question.dependencyQuestions.length > 1 &&  question.dependencyQuestions[x+1] "
                class="flex flex-row items-center px-2">
                <h5 class="text-exp text-xs font-medium uppercase">{{question.dependencyQuestions[x + 1].order}}</h5>
                <!-- if multiple questions  -->
            </div>

        </div>

    </div>

</div>
