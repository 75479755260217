/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { FilterDropdownLookupDto } from '../models/FilterDropdownLookupDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class FilterDropdownLookupService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param tenantKey 
     * @param companyKey 
     * @param formId 
     * @returns FilterDropdownLookupDto Success
     * @throws ApiError
     */
    public getLookups(
tenantKey: string,
companyKey: string,
formId: string,
): Observable<Array<FilterDropdownLookupDto>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/surveyx/filter-lookups/{tenantKey}/{companyKey}/{formId}',
            path: {
                'tenantKey': tenantKey,
                'companyKey': companyKey,
                'formId': formId,
            },
        });
    }

}
