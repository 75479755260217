import { Component, Inject, InjectionToken, Input, OnInit } from '@angular/core';
import { IAiCommentsApi } from '../../interfaces/ai-comments-api';
import { BasicRequest, CommentCountDto, CommentSentimentDto, CommentsTopicsDto, KeyNameDto, QuestionCommentFilterPagedDto, QuestionCommentPagedRequest, SectionSentimentDto } from '../../interfaces/ai-models';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { AiBaseComponent } from '../ai-base.component';
import { CommentExcelConfig, CommentExcelDownloadService, ExcelComment, ExcelQuestion, ExcelSection } from 'src/app/core/services/comment-excel-download.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { FilterKeys } from '../../enums/filterKeys';
import { FormControl } from '@angular/forms';
import { DemoAccountService } from '@ex/module/shared/demo-account/demo-account.service';
import { DemoPopupComponent } from '@ex/module/shared/demo-account/demo-popup/demo-popup.component';
export const AI_COMMENTS_SERVICE = new InjectionToken<IAiCommentsApi>('');
@Component({
  selector: 'ai-comments',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends AiBaseComponent implements OnInit {
  @Input() tourKey;
  @Input() isFactor = false;
  @Input() isDimension = false;
  topicsList: CommentsTopicsDto[];
  sections: SectionSentimentDto[] | any;
  counts: CommentCountDto;
  sentiments: KeyNameDto[] | any[];
  chartSentiments: KeyNameDto[];
  selectedSentiment = new FormControl(); isDemo: boolean;
  language: string;
  module: string;
  currentBubble: any = '';
  basicRequest: BasicRequest;
  currentSentiment: string;
  innerRequest = new BehaviorSubject<BasicRequest>(null);
  innerRequest$ = this.innerRequest.asObservable();
  constructor(@Inject(AI_COMMENTS_SERVICE) public commentsService: IAiCommentsApi, private commentsExcelDownloadService: CommentExcelDownloadService, private demoService: DemoAccountService) {
    super();
    this.sentiments = [...[{ key: null, name: 'txtAll', module: 'Shared' }], ...this.commentsService.sentiments];
    this.selectedSentiment.setValue(this.sentiments[0].key);
    this.chartSentiments = this.commentsService.sentiments.filter(x => x.key != this.SentimentAnalysis.noSentiment);
    this.module = this.commentsService.module;
  }

  ngOnInit(): void {
    combineLatest([this.commentsService.mainRequest$, this.demoService.isDemo$]).subscribe(([request, isDemo]) => {
      this.isDemo = isDemo;
      request.filters = request.filters.filter(x => !Object.keys(FilterKeys).find(f => x.key == f));
      this.currentBubble = null;
      if (!request.isUpdate)
        this.commentsService.request.next(request);
    })
    this.getCommentsTopicsList();
    this.getAllSections();
    this.downloadComments();
  }


  getCommentsTopicsList() {
    this.addSubscription(this.commentsService.mainRequest$.pipe(filter(x => x?.tenantKey != null), switchMap(request => {
      return this.commentsService.getCommentsTopicsList(request.surveyKey, request.filters).pipe(map(topicsList => ({ topicsList, request })))
    })).subscribe(({ topicsList, request }) => {
      this.topicsList = topicsList;
      this.basicRequest = request;
      this.language = this.commentsService.language;
    }));
  }


  getAllSections() {
    this.addSubscription(combineLatest([this.commentsService.request$.pipe(filter(x => x?.tenantKey != null)), this.selectedSentiment.valueChanges.pipe(startWith(''), map(x => this.sentimentPipe(x)))]).pipe(switchMap(([request, sentiment]) => {
      let newRequest: BasicRequest | any = {
        companyKey: request.companyKey,
        tenantKey: request.tenantKey,
        surveyKey: request.surveyKey,
        filters: sentiment && sentiment != "" ? [{ key: "Sentiment", value: sentiment }] : []
      }
      this.filterSection(sentiment);
      newRequest.filters = [...newRequest.filters, ...request.filters];
      return this.commentsService.getCommentsAIAnalysis(newRequest).pipe(switchMap(sections => {
        return this.commentsService.getSectionsCommentCount(newRequest.surveyKey, newRequest.filters).pipe(map(counts => ({ sections, counts })))
      }))
    })).subscribe(({ sections, counts }) => {
      if (this.commentsService.sectionOrderList?.length)
        sections = sections.sort((a, b) => this.commentsService.sectionOrderList.indexOf(a.key) - this.commentsService.sectionOrderList.indexOf(b.key));
      this.sections = sections;
      this.counts = counts;
    }));
  }
  onBubbleClick(comment: CommentsTopicsDto | any) {
    if (this.isDemo)
      return this.ZenCDKService.openComponent(DemoPopupComponent)
    this.selectedSentiment.setValue(null);
    this.currentBubble = comment;
    this.updateFilter('Topic', this.currentBubble.key);
  }
  resetBubble() {
    this.currentBubble = null;
    this.updateFilter('Topic', null);
  }
  updateFilter(key: string | any, value: any) {
    let filter = this.basicRequest.filters.find(filter => filter.key == key);
    if (filter)
      filter.value = value;
    else
      this.basicRequest.filters.push({ key: key, value: value });

    this.basicRequest.filters = this.basicRequest.filters.filter(filter => filter.value != null);
    this.commentsService.request.next(this.basicRequest);
    this.topicsList = JSON.parse(JSON.stringify(this.topicsList));
  }
  filterSection(filter: any) {
    this.currentSentiment = filter;
  }

  // getSectionsCommentCount() {
  //   this.addSubscription(this.commentsService.getSectionsCommentCount(this.basicRequest.surveyKey, this.basicRequest.filters).subscribe(x => {
  //   }));
  // }
  // getPaginatedCommentsList(questionKey: string) {

  //   let questionCommentsRequest: QuestionCommentPagedRequest = {
  //     surveyKey: this.basicRequest.surveyKey,
  //     tenantKey: this.basicRequest.tenantKey,
  //     companyKey: this.basicRequest.companyKey,
  //     filters: [],
  //     maxResultCount: 10,
  //     questionKey: questionKey,
  //   }
  //   return this.commentsService.getPaginatedCommentsList(questionCommentsRequest);
  // }


  returnSentimentName(key: string) {
    return this.sentiments.find(x => x.key == key)?.name;
  }


  getSectionCount(key) {
    return this.counts.sectionCount.find(x => x.key == key)?.count ?? 0;
  }
  getQuestionCount(questionKey: string) {
    return this.counts?.questionCount.find(x => x.key == questionKey)?.count || 0;
  }

  downloadComments() {

    const commentsConfig = {
      filename: "comments",
      sections: [],
      skipSentiment: false
    } as CommentExcelConfig


    const subscription = this.commentsService.downloadAllComments.pipe(switchMap(download => {
      return this.commentsService.request$.pipe(take(1)).pipe(switchMap(request => {
        return this.demoService.isDemo$.pipe(map(demo => ({ demo, request })))
      }));
    }),
      filter(({ demo, request }) => {
        if (demo) {

          this.ZenCDKService.openComponent(DemoPopupComponent)
          this.LoaderService.hideLoader();
        }
        return !demo
      }),
      map(({ demo, request }) => {
        const sections = this.sections
          .filter(x => this.getSectionCount(x.key))
          .map(section => {
            return {
              key: section.key,
              name: this.ExtractPipe.transform(section.name, this.language),
              questions: section.questions
                .filter(x => this.getQuestionCount(x.key))
                .map(question => {
                  return {
                    key: question.key,
                    comments: [],
                    totalComments: 0,
                    name: this.ExtractPipe.transform(question.name, this.language),
                    questionType: question.questionType
                  } as ExcelQuestion
                }),
            } as ExcelSection
          })

        return ({ sections, request });
      }),

      switchMap(({ sections, request }) => {
        let questions = sections.flatMap(x => x.questions);
        let comments$ = questions.map(questions => {
          return this.commentsService.getPaginatedCommentsList({
            companyKey: request.companyKey,
            surveyKey: request.surveyKey,
            tenantKey: request.tenantKey,
            maxResultCount: 10,
            skipCount: 0,
            isAllResults: true,
            filters: [...request.filters, { key: "Question", value: questions.key }]
          })
        })

        return combineLatest([...comments$]).pipe(take(1),
          map((results: any) => ({ results, sections }))
        );
      })

    ).subscribe(({ results, sections }) => {
      sections.flatMap(x => x.questions).forEach((q, i) => {
        q.comments = results[i].items.map((c: CommentSentimentDto) => {
          let translatedTopic = c.commentsAI.topic.map(c => c.name.find(name => { return name.key == this.language }).name);
          let topics = JSON.stringify(translatedTopic).replace(/["[\]]/g, '');

          return {
            name: c.comment,
            sentiment: this.sentimentPipe(c.sentiment),
            topics: topics,
            answer: q.questionType == 'text' ? c.answer.toString() : this.getConfidence('single-answer-confidence-with-na', c.answer.toString())
          } as ExcelComment
        })
      })

      commentsConfig.sections = sections;
      console.log(commentsConfig);
      this.commentsExcelDownloadService.generate(commentsConfig);
      this.LoaderService.hideLoader();
    })



  }

  getConfidence(subquestionKey: string, key: string) {
    return this.GeneralService.getQuestionOptions(subquestionKey, key, this.language);
  }


  trackBy(index, item) {
    return item.key;
  }
}
