import Step from 'shepherd.js/src/types/step';
import { shift, limitShift } from '@floating-ui/dom';

export interface TourStep {
    title: string;
    text: string;
    order: number;
    tourKey: string;
    on?: PopperPlacement;
    triggerId?: string;
    isRows?: boolean
}
export interface StepOptions {
    options: Step.StepOptions,
    order: number;
    isRows?: boolean
}

export const defaultStepOptions: Step.StepOptions = {
    classes: 'shepherd-theme-arrows custom-default-class',
    scrollTo: true,
    cancelIcon: {
        enabled: true
    },
    highlightClass: 'tour-highlight',
    modalOverlayOpeningRadius: 5,
    modalOverlayOpeningPadding: 10,

};


type PopperPlacement = 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end';


