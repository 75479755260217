/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AnalysisFilterRequestDto } from '../models/AnalysisFilterRequestDto';
import type { ChartResponseResult } from '../models/ChartResponseResult';
import type { PagedRespondentsListWithCriteriaDto } from '../models/PagedRespondentsListWithCriteriaDto';
import type { RespondentAnalysisFilterRequestDto } from '../models/RespondentAnalysisFilterRequestDto';
import type { ResponseRateModel } from '../models/ResponseRateModel';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class SurveyAnalysisService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param criteria 
     * @param requestBody 
     * @returns ChartResponseResult Success
     * @throws ApiError
     */
    public getGroupedRespondentsByCriteria(
criteria: string,
requestBody?: AnalysisFilterRequestDto,
): Observable<ChartResponseResult> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/survey-analysis/respondent-analysis/{criteria}',
            path: {
                'criteria': criteria,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns ResponseRateModel Success
     * @throws ApiError
     */
    public getOverallAnalysis(
requestBody?: AnalysisFilterRequestDto,
): Observable<ResponseRateModel> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/survey-analysis/overall',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param questionKey 
     * @param questionType 
     * @param requestBody 
     * @returns ChartResponseResult Success
     * @throws ApiError
     */
    public getQuestionAnalysis(
questionKey: string,
questionType: string,
requestBody?: AnalysisFilterRequestDto,
): Observable<ChartResponseResult> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/survey-analysis/question-analysis/{questionKey}/{questionType}',
            path: {
                'questionKey': questionKey,
                'questionType': questionType,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns PagedRespondentsListWithCriteriaDto Success
     * @throws ApiError
     */
    public getRespondentsListWithCriteria(
requestBody?: RespondentAnalysisFilterRequestDto,
): Observable<PagedRespondentsListWithCriteriaDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/survey-analysis/respondent-analysis-list',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
