import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalService } from '@ex/module/core/services/local.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { Translation, localization } from 'survey-creator-core';

@Component({
  selector: 'app-languages-tab-sidebar',
  templateUrl: './languages-tab-sidebar.component.html',
  styleUrls: ['./languages-tab-sidebar.component.scss']
})
export class LanguagesTabSidebarComponent implements OnInit {

  @Input() language: string = "EN";
  ModuleNames = ModuleName;

  surveyId:any;

  @Input() model!: Translation;
  @Output() targetLocaleChange = new EventEmitter<string>();

  avilableLocales:any;

  targetLocale: string;
  isAddLocaleModalVisible: boolean = false;

  selectedLocales: { id: string, title: string, isDone: boolean, isDefault: boolean }[];

  constructor(private cd: ChangeDetectorRef, private localService : LocalService, private route : ActivatedRoute) { }

  ngOnInit(): void {
    this.surveyId = this.route.snapshot.paramMap.get('id');

    this.language = this.localService.getJsonValue('language')
    //this.selectedLocales.push({ id: this.model.survey.locale, title: localization.getLocaleName(this.model.survey.locale), isDone: true, isDefault: true });


this.selectedLocales = [
  { id: this.language.toLowerCase(), title: localization.getLocaleName(this.language.toLowerCase()), isDone: true, isDefault: true }
];


    this.model.survey.getUsedLocales().forEach((locale) => {
      if (!this.selectedLocales.find((x) => x.id === locale))
        this.selectedLocales.push({ id: locale, title: localization.getLocaleName(locale), isDone: false, isDefault: false });
    });

    //Add the initial setup selected local if its not already there
    if (this.selectedLocales.find((x) => x.id === this.model.survey.locale) == null) {
      this.selectedLocales.push({ id: this.model.survey.locale, title: localization.getLocaleName(this.model.survey.locale), isDone: false, isDefault: false });
    }
  }


  showAddLocaleModal() {
    this.isAddLocaleModalVisible = true;
    this.cd.detectChanges();
  }

  selectLocale(locale: string) {
    this.targetLocale = locale;
    this.targetLocaleChange.emit(locale);
    this.cd.detectChanges();
  }

  onOpenChange(open: boolean) {
    this.cd.detectChanges();
  }

  onSelectedLocalesChanged(locales: { id: string, title: string }[]) {
    locales.forEach((locale) => {
      if (!this.selectedLocales.find((x) => x.id === locale.id))
        this.selectedLocales.push({ id: locale.id, title: locale.title, isDone: false, isDefault: false });
    });

    this.cd.detectChanges();
  }
}
