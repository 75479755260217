import { questionsBuilderTranslateText, updateQuestionHeaderHTML } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";

/**
 * Updates the HTML for a ranking question.
 * @param options - The options for updating the ranking question HTML.
 */
export function updateRankingQuestionHTML(options: any) {
  updateQuestionHeaderHTML(options, "Ranking", "fa-list-ol");

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // body.className = body.className + " mt-5";

  // Remove the default question content
  body.innerHTML = "";

  // Create a new ranking question content
  // Each item has a drag icon and a text at the left and trash icon at the right with gray background
  var newRankingQuestionContent = document.createElement("div");
  newRankingQuestionContent.className = "flex flex-col gap-3 py-4 w-full rank-items-container";

  body.appendChild(newRankingQuestionContent);

  // Add the choices
  addRankingChoices(options);

  // Footer with add new item button and bulk add button and on the right side the save button
  var footer = document.createElement("div");
  footer.className = "flex flex-row items-center justify-between gap-5 w-full mt-3";

  var footerLeft = document.createElement("div");
  footerLeft.className = "flex flex-row items-center justify-between gap-5";

  var addNewItemButton = document.createElement("button");
  addNewItemButton.className = "btn text-center flex flex-row items-center rounded gap-2 text-zen-primarylight text-sm font-medium w-full bg-zen-littlegray px-4 py-2 rounded";
  addNewItemButton.innerHTML = '<i class="far fa-plus"></i> ' + questionsBuilderTranslateText("Add Choice");
  addNewItemButton.onclick = function () {
    options.question.choices = options.question.choices.concat([questionsBuilderTranslateText("txtItem") + (options.question.choices.length + 1)]);
    addRankingChoices(options);
  };

  var bulkAddButton = document.createElement("button");
  bulkAddButton.className = "btn text-center flex flex-row items-center rounded px-2 py-2 gap-3 bulk-answers-button";
  bulkAddButton.innerHTML = '<i class="fa-light fa-layer-plus"></i> ' + questionsBuilderTranslateText("Bulk Answers");

  footerLeft.appendChild(addNewItemButton);
  // footerLeft.appendChild(bulkAddButton);

  footer.appendChild(footerLeft);

  body.appendChild(footer);
}

/**
 * Adds ranking choices to the ranking question HTML.
 * @param options - The options for adding ranking choices.
 */
function addRankingChoices(options: any) {
  var newRankingQuestionContent = options.htmlElement.querySelector(".rank-items-container");
  newRankingQuestionContent.innerHTML = "";

  var dragDropText = document.createElement("p");
  dragDropText.className = "dragDropText";
  dragDropText.innerHTML = questionsBuilderTranslateText("txtDrag & Drop to Rank options");

  newRankingQuestionContent.appendChild(dragDropText);

  for (let i = 0; i < options.question.choices.length; i++) {
    var item = document.createElement("div");
    item.className = "flex flex-row items-center justify-between w-full pl-4 pr-2 py-1 ranking-item";

    var itemLeft = document.createElement("div");
    itemLeft.className = "flex items-center px-4 gap-2 w-full border border-solid border-zen-paleWhite hover:border-[#7D92B5] focus:border-[#7D92B5] rounded";

    var dragIcon = document.createElement("i");
    dragIcon.className = "fa-light fa-grip-dots-vertical ranking-item-drag-icon";
    dragIcon.style.paddingInlineEnd = "1.25rem";

    var itemIndex = document.createElement("p");
    itemIndex.className = "ranking-item-index";
    itemIndex.innerHTML = (i + 1) + "";

    var itemText = document.createElement("input");
    itemText.className = "px-3 w-full block py-2";
    itemText.setAttribute("type", "text");
    itemText.setAttribute("placeholder", questionsBuilderTranslateText("Enter a choice here..."));
    itemText.setAttribute("value", options.question.choices[i].title);

    (function (index) {
      itemText.addEventListener("keydown", function (e) {
        e.stopPropagation();

        // if (this.value.length > 50) {
        //   this.value = this.value.substring(0, 50);
        // }
      });

      itemText.addEventListener("change", function () {
        options.question.choices[index].title = this.value;
        options.question.choices[index].value = this.value;
        options.question.choices[index].text = this.value;
      });
    })(i);

    var deleteButton = document.createElement("button");
    deleteButton.className = "text-center flex flex-row items-center rounded p-3 mx-2 leading-9 text-xs font-medium gap-3 ranking-item-delete-button";
    deleteButton.innerHTML = '<i class="fa-light fa-trash"></i>';
    deleteButton.onclick = function () {
      options.question.choices.splice(i, 1);

      // Update the question choices
      addRankingChoices(options);
    };

    itemLeft.appendChild(itemIndex);
    itemLeft.appendChild(itemText);

    item.appendChild(dragIcon);
    item.appendChild(itemLeft);
    item.appendChild(deleteButton);

    newRankingQuestionContent.appendChild(item);
  }
}
