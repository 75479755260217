<!-- Overall Screen -->
<div class="flex flex-col justify-between items-stretch gap-5"  *DemoAccount="'Default'; let isDemo=isDemo">
  <!-- Scale -->
  <ng-container>
    <div class="flex flex-col items-stretch h-20 relative z-10 " sticky (sticky)="container.active = $event">
      <div #container [ngClass]="{'shadow-2x2 backdrop-filter backdrop-blur-3xl bg-white bg-opacity-10':container.active}" class="flex flex-row items-center justify-center h-20  gap-16 uppercase z-50">
        <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
          <span class="w-4 h-3 rounded-sm bg-zen-red"></span>
          <span>{{ scales.VeryPoorExperience | translate:language:ModuleNames.Exit }}</span>
        </div>
        <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
          <span class="w-4 h-3 rounded-sm bg-zen-orange"></span>
          <span>{{scales.PoorExperience | translate:language:ModuleNames.Exit}}</span>
        </div>
        <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
          <span class="w-4 h-3 rounded-sm bg-zen-yellow"></span>
          <span>{{ scales.FairExperience| translate:language:ModuleNames.Exit }}</span>
        </div>
        <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
          <span class="w-4 h-3 rounded-sm bg-zen-lightgreen"></span>
          <span>{{ scales.GoodExperience| translate:language:ModuleNames.Exit }}</span>
        </div>
        <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
          <span class="w-4 h-3 rounded-sm bg-zen-green"></span>
          <span>{{ scales.VeryGoodExperience| translate:language:ModuleNames.Exit }}</span>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="flex flex-row gap-7 h-28">

    <!-- Response Rate -->
    <md-dashlet-card class="w-1/3 h-28" subTitle="{{ totalCompleted }} {{'txtof' | translate:language:ModuleNames.Exit}} {{ totalRespondents }}" [title]=" 'txtOverallResponseRate' | translate:language:ModuleNames.Exit" [value]="toFixed(responseRate,0) + '%'" icon="fa-reply"></md-dashlet-card>

    <!-- Total Completed -->
    <md-dashlet-card class="w-1/3" [title]=" 'txt_completed' | translate:language:ModuleNames.Exit" [value]="totalCompleted" icon="fa-circle-check"></md-dashlet-card>

    <!-- Total Comments -->
    <md-dashlet-card class="w-1/3" [title]=" 'txtComments' | translate:language:ModuleNames.Exit" [value]="totalComments" icon="fa-comment-dots"></md-dashlet-card>


  </div>

  <!-- Enps & Leaver Experience -->
  <div class="flex flex-row items-stretch -mr-3 -ml-3" id="engagementScoreChart">
    <div class="flex flex-col items-stretch w-1/2 px-3">
      <div class="flex flex-col items-center rounded border border-zen border-opacity-10 shadow-sm bg-white h-full p-7 gap-5">
        <h5 class="text-zen font-medium text-xl"> {{'txtEmployeeSatisfactionRate'|
          translate:language:ModuleNames.Exit}} </h5>
        <div class="h-full flex flex-col items-center justify-center">
          <kendo-arcgauge [color]="
                              resolveChartColor(
                                leaverExperienceScore
                              )
                            " [value]="leaverExperienceScore" style="display: block; width: 100%" renderAs="canvas">
            <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450" [rangeSize]="23" [rangeLineCap]="'butt'">
              <ng-template kendoArcGaugeCenterTemplate let-value="value">
                <span class="text-2xl font-medium text-zen">
                  {{
                  toFixed(
                  leaverExperienceScore,
                  1
                  ) + "%"
                  }}</span>
              </ng-template>
            </kendo-arcgauge-scale>
          </kendo-arcgauge>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-stretch w-1/2 px-3 ">
      <div class="flex flex-col rounded border border-zen border-opacity-10 shadow-sm bg-white p-7 h-full" [TourStep]="{title:'txtENPS_tour' | translate:language:ModuleNames.Exit,text: 'txtENPS_tour_desc' | translate:language:ModuleNames.Exit,order:6,tourKey:'exit-analysis-overview'}">
        <h5 class="text-zen font-medium text-xl"> {{'txtENPS'| translate:language:ModuleNames.Exit}}
        </h5>
        <div class="h-full flex flex-row items-center justify-center" id="engagementScaleChart">
          <div class="flex flex-row w-5/12 items-center justify-center p-5 gap-5">
            <i class="fas fa-th-list text-5xl text-exp"></i>
            <h5 class="text-5xl text-exp"> {{ netEnpsScore | number:'1.0-0' }}% </h5>
          </div>
          <div class="flex flex-col w-7/12 p-5 gap-4">
            <div class="flex flex-col items-stretch" *ngFor="let enpsScore of enpsScores">
              <div class="flex flex-row justify-between items-center">
                <h5 class="text-zen text-sm">{{ 'txt' + capitalizeFirstLetter(enpsScore.category) |
                  translate:language:ModuleNames.Exit }}
                </h5>
                <h5 class="text-zen text-sm"> {{ enpsScore.count }}({{ enpsScore.percentage |
                  number:'1.0-0'
                  }}%) </h5>
              </div>
              <div class="flex flex-row justify-start h-7 rounded-md bg-zen-green bg-opacity-10 items-stretch overflow-hidden">
                <div [ngClass]="{'bg-zen-green': enpsScore.category == EnpsTypes.Promoters,'bg-yellow-500': enpsScore.category == EnpsTypes.Passives,'bg-zen-red': enpsScore.category == EnpsTypes.Detractors }" class="bg-zen-green" [style.width.%]="enpsScore.percentage | number:'1.0-0'"></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Factor Analysis -->
  <div class="flex flex-row">
    <!-- Chart container -->
    <div class="flex flex-col w-full bg-white rounded shadow-2x2 px-8 pt-6 pb-14 gap-9 border border-zen border-opacity-10 h-full">

      <!-- Chart header -->
      <div class="flex flex-col gap-2.5">
        <div class="flex flex-row justify-between">

          <div class="flex flex-col">
            <h5 class="text-zen text-lg font-medium">{{ 'txtFactors' | translate:language:ModuleNames.Exit
              }}</h5>
            <!-- <div class="flex flex-row items-center  text-zen-lite text-xs gap-1.5">
              <i class="fa-regular fa-info-circle text-sm  text-opacity-50"></i>
              <h5 class="font-normal text-xs">{{ "" || "" }}</h5>
            </div> -->
          </div>

          <div class="flex flex-row gap-3">
            <ng-content select="[right-header]"></ng-content>
          </div>

        </div>

      </div>


      <!-- Chart body -->
      <div class="flex flex-col items-center">
        <div class="flex flex-row items-stretch w-full flex-wrap">
          <ng-container *ngFor="let factor of factors; let i=index">
            <div class="flex flex-col items-center py-1" [ngClass]="'w-1/3 -mx-px'">
              <kendo-arcgauge class="w-56" [value]="toFixed(factor.score,1)" [color]="resolveChartColor(factor.score)" [transitions]="true" renderAs="canvas">
                <kendo-arcgauge-scale [startAngle]="0" [endAngle]="180" [rangeSize]="25" [rangeLineCap]="'butt'">
                  <kendo-arcgauge-scale-labels class="custom-centered-label" format="c" [visible]="false">
                    <ng-template kendoArcGaugeCenterTemplate let-value="value">
                      <div class="-top-10 text-center w-48 -left-24 flex flex-col gap-2.5 absolute justify-start items-center">
                        <span class="text-3xl font-medium text-zen">{{factor.score? toFixed(factor.score,1) + '%':('txtNA'| translate:language:ModuleNames.Exit)}}</span>
                        <h5 class="font-medium text-lg text-zen ">{{factor.name |
                          extract:language}}</h5>
                        <!-- <span class="text-xs text-zen-lite text-center">{{'txtN' |
                                                translate:language:ModuleNames.Onboarding}} = {{
                                                factor.totalCount }} {{ 'txtOf' |
                                                translate:language:ModuleNames.Onboarding }} {{
                                                factor.totalSent }}</span> -->
                      </div>
                    </ng-template>
                  </kendo-arcgauge-scale-labels>
                </kendo-arcgauge-scale>
              </kendo-arcgauge>
            </div>

            <span class="border-r w-0 border-zen border-opacity-10 my-1" *ngIf="i != (factors.length - 1) &&  (i+ 1) % 3 != 0"></span>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Chart container End-->
  </div>

  <!-- Question Analysis -->
  <div class="flex flex-col" *DemoAccount="'Default'; let isDemo=isDemo">
    <ng-container *ngFor="let question of questionAnalysis; let i = index">
      <div class="-mt-5" id="analysis-report-{{ i }}" [TourStep]="i==0?{title:'txt_Drilldown_245' | translate:language:ModuleNames.Exit,text: 'txt_Drilldown_desc' | translate:language:ModuleNames.Exit,order:7,tourKey:'exit-analysis-overview',on:top}:undefined">
        <!-- <chart [customChart]="true" [disableGrouping]="false" [chartColors]="chartColors"
                    *ngIf="question.dashlet" class="is-fullheight mx-1" [reportsPage]="true"
                    [exportInitiated]="exportInitiated" (clearGroup)="evaluateQuestion(question.question, question)"
                    [dashlet]="question.dashlet" [dataSymbol]="' %'" [dashletData]="question.dashletData"
                    [currencySymbol]="'$'" [language]="language" [entityGroups]="entityGroups" [question]="question"
                    (groupChanged)="groupData(question, $event)">
                </chart> -->

        <chart [disableChartTypes]="isDemo" [customChart]="true" [disableGrouping]="false" *ngIf="question" class="is-fullheight mx-1" [reportsPage]="true" [dashlet]="question" [dataSymbol]="' %'" [dashletData]="question.data" [currencySymbol]="'$'" [language]="language" [entityGroups]="[]" [question]="question">
        </chart>

      </div>
    </ng-container>
  </div>

</div>
