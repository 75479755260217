import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DATA_TOKEN, ICDKPortalComponent } from 'src/app/shared/Theme/services/zen-cdk.service';
import { CommentSentimentDto, CommentsTopicsDto, KeyNameDto, SentimentTopic } from '../../interfaces/ai-models';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-topic-add',
  templateUrl: './topic-add.component.html',
  styleUrls: ['./topic-add.component.scss']
})
export class TopicAddComponent extends BaseComponent implements OnInit, ICDKPortalComponent {
  extraTopic: boolean = false;
  language: string;
  module: string;
  topicName: KeyNameDto[];
  comment: CommentSentimentDto;
  topics: CommentsTopicsDto[] | any;
  topicsCopy: CommentsTopicsDto[];
  searchText: FormControl = new FormControl();
  constructor(@Inject(DATA_TOKEN) public data: { contentLanguages: [], language: string, module: string, comment: CommentSentimentDto, topics: CommentsTopicsDto[] }) {
    super();
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();
  selectedTopics: CommentsTopicsDto[] = [];
  ngOnInit(): void {
    this.language = this.data.language;
    this.module = this.data.module;
    this.comment = this.data.comment;
    this.topics = JSON.parse(JSON.stringify(this.data.topics));
    this.topicsCopy = JSON.parse(JSON.stringify(this.data.topics));
    this.searchText.valueChanges.pipe(filter((x: string) => x.length >= 3 || x.length == 0), debounceTime(300), switchMap(x => {
      return of(x)
    })).subscribe(text => {
      this.topics = this.topicsCopy.filter(x => JSON.stringify(x.name).toLowerCase().indexOf(text.toLowerCase()) != -1)
    })

  }



  closeModal() {
    this.close.emit(true)
  }
  apply(selectedTopics: CommentsTopicsDto[]) {
    for (let index = 0; index < selectedTopics.length; index++) {
      let selectedTopic = selectedTopics[index];
      let newTopic: SentimentTopic = {
        name: selectedTopic.name,
        key: selectedTopic.key
      };
      this.comment.commentsAI.topic.push(newTopic);
      this.comment.commentsAI.topics.push(newTopic.key);
    }

    this.results.emit(this.comment);
  }

  newTopic() {
    this.extraTopic = true;
    this.topicName = [];
  }
  closeNewTopic() {
    this.extraTopic = false;
  }
  toggleTopic(topic: CommentsTopicsDto) {
    if (this.selectedTopics.includes(topic))
      this.selectedTopics.splice(this.selectedTopics.indexOf(topic), 1);
    else if ((this.comment.commentsAI.topic.length + this.selectedTopics.length) < 3)
      this.selectedTopics.push(topic);
  }
  isTopicSelected(topic: CommentsTopicsDto) {
    return this.selectedTopics.includes(topic);
  }
  addNewTopic(name: KeyNameDto[]) {
    let newTopic: SentimentTopic = {
      name: name,
      key: name.find(x => x.key == 'EN').name || ('topic' + new Date().getTime())
    };
    this.comment.commentsAI.topic.push(newTopic);
    this.comment.commentsAI.topics.push(newTopic.key);
    this.results.emit(this.comment);
  }
}
