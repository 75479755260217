<div class="flex flex-row py-6 px-8 items-start gap-4 self-stretch" *ngIf="feedback">

    <div class="flex w-72 items-center gap-4">
        <div *ngIf="feedback?.respondentImageURL" class="flex shrink-0 w-13 h-13 rounded-full ss-c">
            {{feedback.respondentImageURL}}
        </div>

        <div *ngIf="!feedback?.respondentImageURL && feedback.name"
            class="flex shrink-0 w-13 h-13 rounded-full justify-center text-zen-blueTouch text-lg tracking-tight font-medium items-center bg-zen-littlegray ss-c">
            {{getInitials(feedback.name ?? feedback.email)}}
        </div>

        <div class="flex flex-col w180 py-1 px-0 items-start shrink-0">
            <div class="flex w-52 items-center gap-2">
                <h5 class="w232 shrink-0 text-zen-primarylight text-sm font-medium">{{feedback.name?
                    feedback.name : feedback.email}}</h5>
            </div>
            <h5 class="text-zen-primarylight text-xs font-normal w-52">{{getJobTitle()}}</h5>
        </div>
    </div>

    <div class="flex flex-col items-start gap-2 flex-1">

        <div class="flex items-center gap-2 self-stretch">
            <div class="flex items-start flex-1">
                <div class="flex flex-col items-center gap-2">
                    <div class="flex items-start gap-2">

                        <div class="w-full flex flex-row">
                            <div class="flex space-x-4 w-full">

                                <ng-container>
                                    <div class="rate_box flex flex-row w-full rounded-md items-center gap-2">
                                        <div *ngFor="let rateValue of getRange(1,5); let d = index"
                                            class="flex flex-row rounded-md  items-center font-medium text-xs bg-white">

                                            <i [ngStyle]="{'color': rateValue <= isNumber(feedback.rating) ? 'text-exp':'#AEBBD1'}"
                                                [ngClass]="rateValue <= isNumber(feedback.rating)  ? 'fa-solid fa-star text-xl text-exp' : 'fa-light fa-star text-xl text-zen-neutral'"></i>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-start gap-2 w-full flex-wrap self-stretch">
            <h5 class="flex flex-1 text-xs font-normal text-zen-primarylight">
                {{feedback?.comment}}
            </h5>
        </div>

    </div>
</div>
<div class="flex flex-row justify-center items-center self-stretch w-full b-g-c">
    <div class="h1 self-stretch b-g-c"></div>
</div>