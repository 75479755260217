import { Injectable } from '@angular/core';
import { IExcelBuilder } from '../builders/iexcel-builder';
import { ExcelOptions } from '../models/excel-options';
import { IExcelParser } from '../parsers/iexcel-parser';


export abstract class IExcelUploadService {
  abstract generate(config: ExcelOptions, sampleData?: any[]);
  abstract parse(rows: any[], config: ExcelOptions)
}


@Injectable()
export class ExcelUploadService implements IExcelUploadService {

  constructor(private builder: IExcelBuilder, private parser: IExcelParser) { }

  generate(config: ExcelOptions, sampleData: any[] = []) {
    return this.builder.build(config, sampleData);
  }

  parse(rows: any[], config: ExcelOptions) {
    return this.parser.parse(rows, config);
  }

  setParser(parser: IExcelParser) {
    this.parser = parser;
  }

  setBuilder(builder: IExcelBuilder) {
    this.builder = builder;
  }


}

