export class TenantEngagementData {
  key: string;
  title: any[] = [];
  description: any[] = [];
  unixStart: number;
  companyKey: string;
  companyName: any[] = [];
  workplaceEngagementScore: number;
  engagementDrivers: any[] = [];
  lowlyEngaged: number;
  moderatlyEngaged: number;
  highlyEngaged: number;
}
