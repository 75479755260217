import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';
import { WellbeingScalesService } from '../../../utils/wellbeing-scales.service';
import { WbBaseComponent } from '../../../utils/wellbeing-base.component';

@Component({
  selector: 'wellbeing-scales',
  templateUrl: './wellbeing-scales.component.html',
  styleUrls: ['./wellbeing-scales.component.scss']
})
export class WellbeingScalesComponent extends WbBaseComponent implements OnInit {

  @Input()
  analysis:boolean;
  @Input()
  language;
  topValue
  Scales = WellbeingScalesService;

  constructor(public wellbeingScalesService: WellbeingScalesService) {
    super()
  }

  ngOnInit(): void {
    this.topValue = document.querySelector('.h-header')?.clientHeight / 16;
  }

}
