import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalsService } from '@ex/module/core/services/globals.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';

@Component({
  selector: 'zen-x-editor',
  templateUrl: './zen-editor.component.html',
  styleUrls: ['./zen-editor.component.scss']
})
export class ZenXEditorComponent implements OnInit {
  @Input('emailObject') emailObject: any;
  @Input() language: any;
  @Input() list: any[] = [];
  @Input() single: any;
  @Output() reset = new EventEmitter();
  hugeCount;
  lgCount;
  mdCount;
  smCount;
  constructor(public globals: GlobalsService,) {
    this.hugeCount = globals.hugeCount;
    this.lgCount = globals.lgCount;
    this.mdCount = globals.mdCount;
    this.smCount = globals.smCount;
  }
  ModuleNames = ModuleName
  ngOnInit(): void {
  }

}
