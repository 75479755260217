<div class="flex flex-col gap-5 items-stretch w-full">

    <!-- Header of Factor Analysis -->
    <div class="flex flex-row gap-5 justify-between w-full items-center mt-5">
        <h5 [@child22] class="text-zen text-2xl font-medium">
            {{'txt_Workplace_Wellbeing_Score' |
            translate:language:ModuleNames.Wellbeing }}
        </h5>
    </div>

    <!-- Factor Analysis -->
    <div class="flex flex-row gap-5">

        <!-- Overall Chart -->
        <div [ngClass]="hasExtraDimensions ? 'w-1/4':'w-1/3' " class="flex flex-col bg-white shadow-sm p-5 rounded">
            <h5 [@child28] class="text-zen">
                {{"txtOverall"| translate:language:ModuleNames.Wellbeing}}
            </h5>
            <div class="flex flex-col justify-center items-center gap-2 h-full">
                <kendo-arcgauge [@child29] [color]="wellbeingScalesService.resolveChartColor(overallFactorScore)"
                    [value]="overallFactorScore" style="display:block; width:100%" renderAs="canvas">
                    <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450" [rangeSize]="23" [rangeLineCap]="'butt'">
                        <ng-template kendoArcGaugeCenterTemplate let-value="value">
                            <span class="text-4xl font-medium text-zen">
                                {{(overallFactorScore |number:'1.1-1') + '%'}}
                            </span>
                        </ng-template>
                    </kendo-arcgauge-scale>
                </kendo-arcgauge>
            </div>
        </div>

        <!-- Factor Charts -->
        <div [@child30] [ngClass]="hasExtraDimensions? 'w-3/4':'w-2/3' " class="flex flex-col bg-white shadow-sm p-5 rounded">
            <h5 class="text-zen">
                {{'txt_dimensions_score'| translate:language:ModuleNames.Wellbeing}}
            </h5>
            <div class="flex flex-row flex-wrap">
                <div *ngFor="let dimension of factors; let i = index" [ngClass]="hasExtraDimensions? 'w-1/4':'w-1/3' "
                    class="flex flex-col justify-center items-center drivers py-5">
                    <kendo-arcgauge [value]="dimension.score"
                        [color]="wellbeingScalesService.resolveChartColor(dimension.score)" [transitions]="true"
                        style="display:block;width:11rem; height: 9.25rem;" renderAs="canvas">
                        <kendo-arcgauge-scale [startAngle]="0" [endAngle]="180" [rangeSize]="17"
                            [rangeLineCap]="'butt'">
                            <kendo-arcgauge-scale-labels [visible]="false" class="custom-centered-label" format="c">
                                <ng-template kendoArcGaugeCenterTemplate let-value="value">
                                    <div class>
                                        <span class="font-medium text-2xl text-zen ">
                                            {{(dimension.score|number:'1.1-1')+'%'}}
                                        </span>
                                        <h5 class="font-medium text-sm text-zen ">
                                            {{dimension.name | extract:language }}
                                        </h5>
                                    </div>
                                </ng-template>
                            </kendo-arcgauge-scale-labels>
                        </kendo-arcgauge-scale>
                    </kendo-arcgauge>

                </div>
            </div>
        </div>

    </div>

</div>
