import { Direction } from '@angular/cdk/bidi';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalService } from '@ex/module/core/services/local.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';

@Component({
  selector: 'app-share-success-modal',
  templateUrl: './share-success-modal.component.html',
  styleUrls: ['./share-success-modal.component.scss']
})
export class ShareSuccessModalComponent extends ModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Input() surveyId: string = '';
  @Output() openChange = new EventEmitter();

  language = 'EN';
  ModuleNames = ModuleName;
  direction: Direction = "ltr"

  constructor(private localService: LocalService) {
    super();
  }
  ngOnInit(): void {

    this.language = this.localService.getJsonValue('language')

    if (this.language == "AR")
      this.direction = "rtl"
    else
      this.direction = "ltr"
  }

  onSubmit() {
    this.close();
  }

  close() {
    this.openChange.emit(false);
  }
}
