import { Injectable } from '@angular/core';
import { CriteriaType } from '../../../shared/enums/CriteriaType.enum';
import { SurveyCriteriaDto } from '../models/models';
import { GeneralService } from '@ex/module/core/services/general.service';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TranslatePipe } from '@ex/module/shared/pipes/translate.pipe';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { DepartmentService, IndustryService } from '@ex/survey-x/index';

@Injectable({
  providedIn: null
})
export class AdditionalCriteriasService {
  modules = ModuleName;
  private companySizes: any[];
  private countries: any[];
  private religions: any[];
  private employmentStatuses: any[]
  private departments: any[];
  private companyIndustries: any[];
  private contractTypes: any[];
  private jobTypes: any[];
  private maritalStatus: any[];
  constructor(private generalService: GeneralService, private industriesService: IndustryService,
    private departmentsService: DepartmentService, private translate: TranslatePipe) {
    const companySizeKeys = Array.from(new Set(this.generalService.companySizes.map(x => x.key)));
    this.companySizes = companySizeKeys.map(x => {
      return {
        key: x,
        name: this.generalService.companySizes.filter(c => c.key == x).map(c => {
          return {
            key: c.language,
            name: c.name
          }
        })
      }
    })
    this.countries = this.generalService.countries_new.map(x => {
      return {
        key: x.key,
        name: x.name
      }
    });

    this.religions = [
      { key: 'islam' },
      { key: 'christianity' },
      { key: 'buddhism' },
      { key: 'hinduism' },
      { key: 'other' }
    ];
    this.employmentStatuses = [
      { key: 'employed' },
      { key: 'currently-unemployed' }
    ]
    this.maritalStatus = [
      { key: 'single' },
      { key: 'married' },
      { key: 'divorced' },
      { key: 'widower' }
    ];
    this.jobTypes = [
      { key: 'full-time' },
      { key: 'part-time' },
      { key: 'freelance' }
    ];
    this.contractTypes = [
      { key: 'hybrid' },
      { key: 'on-site' },
      { key: 'remotely' }
    ];
  }

  public additionalCriterias(languages: string[]) {
    return combineLatest([this.industriesService.getAllIndustries(),
    this.departmentsService.getAllDepartments()]).pipe(map(([industries, deps]: any) => {
      this.departments = deps.map(x => {
        return {
          key: x.key
        }
      });
      this.companyIndustries = industries.map(x => {
        return {
          key: x.key
        }
      });
      return [
        { isCustom: false, isEntity: false, key: 'name', type: CriteriaType.OpenText },
        { isCustom: false, isEntity: false, key: 'job-title', type: CriteriaType.OpenText },
        { isCustom: false, isEntity: false, key: 'phone-number', type: CriteriaType.OpenText },
        { isCustom: false, isEntity: false, key: 'company-name', type: CriteriaType.OpenText },
        { isCustom: false, isEntity: false, key: 'company-size', type: CriteriaType.DropDown, options: this.companySizes },
        { isCustom: false, isEntity: false, key: 'company-industry', type: CriteriaType.DropDown, options: this.companyIndustries },
        { isCustom: false, isEntity: false, key: 'location', type: CriteriaType.DropDown, options: this.countries },
        { isCustom: false, isEntity: false, key: 'preferred-country-of-work', type: CriteriaType.DropDown, options: this.countries },
        { isCustom: false, isEntity: false, key: 'employment-status', type: CriteriaType.DropDown, options: this.employmentStatuses },
        { isCustom: false, isEntity: false, key: 'department', type: CriteriaType.DropDown, options: this.departments },
        { isCustom: false, isEntity: false, key: 'religion', type: CriteriaType.DropDown, options: this.religions },
        { isCustom: false, isEntity: false, key: 'marital-status', type: CriteriaType.DropDown, options: this.maritalStatus },
        { isCustom: false, isEntity: false, key: 'job-type', type: CriteriaType.DropDown, options: this.jobTypes },
        { isCustom: false, isEntity: false, key: 'contract-type', type: CriteriaType.DropDown, options: this.contractTypes },
      ].map((x: any) => {
        x.name = languages.map(language => { return { key: language, name: this.translate.transform(x.key, language, this.modules.SurveyX) } })
        if (x.type == CriteriaType.DropDown)
          x.options.map(o => {
            if (!o?.name)
              o.name = languages.map(language => { return { key: language, name: this.translate.transform(o.key, language, this.modules.SurveyX) } })
          })
        return x;
      });

    }))
  }

}
