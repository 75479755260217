<!-- Chart container -->
<div
    class="flex flex-col w-full bg-white rounded shadow-2x2 px-8 pt-6 pb-14 gap-9 border border-zen border-opacity-10 h-full">
    
    <!-- Chart header -->
    <div class="flex flex-col gap-2.5">
        <div class="flex flex-row justify-between">

            <div class="flex flex-col">
                <h5 class="text-zen text-lg font-medium">{{ title }}</h5>
                <div class="flex flex-row items-center  text-zen-lite text-xs gap-1.5" *ngIf="subTitle">
                    <i class="fa-regular fa-info-circle text-sm  text-opacity-50"></i>
                    <h5 class="font-normal text-xs">{{ subTitle || "" }}</h5>
                </div>
            </div>

            <div class="flex flex-row gap-3">
                <ng-content select="[right-header]"></ng-content>
            </div>

        </div>

    </div>


    <!-- Chart body -->
    <div class="flex flex-col items-center">
        <ng-content select="[body]"></ng-content>
    </div>
</div>