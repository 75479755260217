import { Component, ElementRef, ViewChild, Output, EventEmitter, OnInit, Input, AfterContentInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { inOutAnimation, pageAnimation } from '../animations/allAnimations'
import { LanguageService } from '../../core/services/language.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Tenant } from '../../core/data-models/tenant';
import { Entity } from '../../core/data-models/Entity';
import { EngagementService } from '../../core/services/engagement.service';
import { EngagementData } from '../../core/data-models/engagement-data';
import { Engagement } from '../../core/data-models/engagement';
import { GeneralService } from '../../core/services/general.service';
import { JsonPipe } from '@angular/common';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { EngagementMetric } from '../../core/data-models/engagement-metric';
import { EngagementRespondent } from '../../core/data-models/engagement-respondent';
import { Respondent } from '../../core/data-models/respondent';

import { EmailNotificationService } from '../../core/services/email-notification.service';
import { RespondentReference } from '../../core/data-models/respondent-reference';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { GlobalsService } from '../../core/services/globals.service';
import { CreditBalanceDialogComponent } from '../credit-balance-dialog/credit-balance-dialog.component';
import { Questionnaire } from '../../core/data-models/questionnaire';
import { PlatformUser } from '../../core/data-models/platform-user';
import { AssessmentService } from '../../core/services/assessment.service';
import { ZenSnackbarService, ZenSnackBarTypes } from '../Theme/services/zen-snackbar.service';
import { LocalService } from '../../core/services/local.service';
import { ModalComponent } from '../modal/modal.component';
import { TranslatePipe } from '../pipes/translate.pipe';
import { ModuleName } from '../enums/ModuleName';

@Component({
  selector: 'app-respondent-edit',
  templateUrl: './respondent-edit.component.html',
  styleUrls: ['./respondent-edit.component.scss'],
  animations: [inOutAnimation],

})
export class RespondentEditComponent extends ModalComponent implements OnInit {
  supportedLanguages: any[];

  hugeCount;
  lgCount;
  mdCount;
  smCount;
  user;
  @Input('entities') entities;
  @Input('selectedEntity') selectedEntity;
  @Input('respondentEdit') respondentEdit;
  @Output('respondentEditChange') respondentEditChange = new EventEmitter();
  @Input('respondent') set respondent(user: any) {
    console.log(user);

    this.user = JSON.parse(JSON.stringify(user));
  }

  @Output() updateModal = new EventEmitter();
  constructor(
    private engagementService: EngagementService, private _globals: GlobalsService,
    private languageService: LanguageService, private _snackBar: MatSnackBar,
    private zenSnackBarService: ZenSnackbarService,
    private db: AngularFireDatabase,
    private localService: LocalService, private translationPipe: TranslatePipe
  ) {
    super();
    this.hugeCount = _globals.hugeCount;
    this.lgCount = _globals.lgCount;
    this.mdCount = _globals.mdCount;
    this.smCount = _globals.smCount;
  }

  @Input('language') set currentLanguage(lang: string) {
    this.language = lang;
  }

  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  countWord(word, count) {
    if (word?.length <= count)
      return word.length;
    if (word?.length > count) {

      return word.length;
    }
  }


  entityGroupChanged(event, groupKey) {


    let selectedEntity = this.entities?.find(e => e.key == this.selectedEntity.key);

    let entityGroups = selectedEntity?.EntityGroups;

    let reportingEntityGroups = entityGroups?.filter(eg => eg.parentKey == groupKey);
    this.eraseValuesForEntityGroups(groupKey);
    for (let x = 0; x < reportingEntityGroups.length; x++) {

      let eG = reportingEntityGroups[x];
      let eM = this.user?.Metrics?.find(m => m.key == eG.groupKey);
      if (eM) {
        eM.options = [];
        let entitiesInEntityGroup = this.entities.filter(e => e.entityGroupKey == eG.groupKey && e.parentKey == event);
        // console.log(this.entities.filter(e => e.entityGroupKey == eG.groupKey));

        if (entitiesInEntityGroup.length > 0) {
          for (let i = 0; i < entitiesInEntityGroup.length; i++) {
            let entity = entitiesInEntityGroup[i];

            let parentEntityName: any[] = [];
            if (entity.parentKey) {
              let parentObject = this.entities.find(e => e.key == entity.parentKey);
              if (parentObject)
                parentEntityName = parentObject.name;
            }
            let option = { key: entity.key, name: entity.name, parentEntityName: parentEntityName };
            eM.options.push(option);
          }
          eM.required = true;
        } else {
          eM.required = false;
        }

      }
    }

    console.log(this.user.Metrics);

  }


  eraseValuesForEntityGroups(groupKey) {
    let selectedEntity = this.entities?.find(e => e.key == this.selectedEntity.key);
    let entityGroups = selectedEntity?.EntityGroups;
    let reportingEntityGroups = entityGroups.filter(eg => eg.parentKey == groupKey);
    for (let i = 0; i < reportingEntityGroups.length; i++) {
      let eG = reportingEntityGroups[i];
      let eM = this.user.Metrics?.find(m => m.key == eG.groupKey);
      if (eM) {
        eM.value = "";
        this.entityGroupChanged('', eG.groupKey);
      }
    }
  }

  update() {
    if (this.validateRespondent(this.user)) {
      this.updateModal.emit(this.user);

    }
  }
  ModuleNames = ModuleName
  vRespondentName;
  validateRespondent(respondent: Respondent) {
    let isValid: boolean = true;

    if (!respondent.name) {
      this.vRespondentName = true;
      isValid = false;
    } else {
      this.vRespondentName = false;
    }

    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error });

    // this._snackBar.open(this.txtFillFields, this.txtClose, {
    //   duration: 2000,
    // });
    if (!this.validateCount(respondent.employeeID, this.smCount, 'x') || !this.validateCount(respondent.email, this.mdCount, 'x') || !this.validateCount(respondent.name, this.mdCount, 'x')) {
      return;
    }
    else {
      return isValid;
    }
  }
  validateCount(value, count, ready?) {
    if (!value)
      return true;
    let validatedCount;
    if (ready == 'x') {
      validatedCount = this.countWord(value, count)
    }
    else
      validatedCount = this.countWord(this.extractNameLanguage(value), count)
    if (validatedCount > count) {
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_wrong_text_count", this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error });
      return false;
    }
    if (validatedCount <= count) {
      return true;
    }
  }

  language;
  ngOnInit(): void {
    this.language = this.localService.getJsonValue('language');
  }
  vName;
  resetDropdown: boolean = false;
  multiUseTimer;
  dropDownChanged(event, metric) {
    metric.value = event;
    if (event == '') {
      this.resetDropdown = true;
      this.multiUseTimer = setTimeout(() => {
        this.resetDropdown = false;
      }, 50);
    }
  }
  dateChanged(metric: EngagementMetric, event) {
    metric.value = event.value._d;
  }


  formateExistingDate(date, metric, f?) {

    let newDate = new Date(date)


    if (date) {
      let formattedDate: string;
      if (f)
        formattedDate = (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' + newDate.getFullYear();
      if (!f)
        formattedDate = newDate.getDate() + '/' + (newDate.getMonth() + 1) + '/' + newDate.getFullYear();

      this.user.Metrics.find(met => met == metric).value = formattedDate;
    }
  }



}
