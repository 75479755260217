<div class="overflow-scroll">

    <div class="flex mb-8">
        <h2 class="text-lg tracking-tight mt-1 text-zen-primarydefault">
            {{"txtRespondents"| translate:language:ModuleNames.SurveyX}}
        </h2>
    </div>

<div class="flex flex-col w-full bg-white justify-center rounded-md mb-8">
  <div class="px-10 py-6">
    <div class="flex flex-row justify-between">
        <div class="flex flex-col  w-5/12">
            <div
                class="text-sm rounded text-zen border bg-white border-zen border-opacity-25 px-3 h-9 w-60 gap-2 leading-9 truncate flex flex-row justify-start items-center">
                <div class="flex flex-row w-full items-center gap-2">
                    <i [ngClass]="{'rotate-h-45':dir == 'rtl'}" class="fal fa-search text-zen transform"></i>
                    <input [placeholder]="options?.searchText"
                        class="bg-transparent overflow-hidden h-12 leading-12 text-sm w-full " [formControl]="searchBox"
                        type="text">
                </div>
            </div>
        </div>

        <div class="flex">
            <z-select [color]="'exp'" [label]="'txtStatus'| translate:language:ModuleNames.SurveyX"
                labelPosition="before" class="placeholder:fixed" (ngModelChange)="onStatusChange($event)" [ngModel]="status$ | async" [options]="statusList"
                [language]="language">
                <ng-template #valueTemplate let-value>
                    {{value?.name | translate:language:ModuleNames.SurveyX}}
                </ng-template>

            </z-select>
        </div>

    </div>

    <t-table *ngIf="options?.data?.length" [options]="options" [@child16]>
        <ng-template #cellTempate let-element let-column='column' let-colIndex='colIndex'>

            <div>
                <ng-container [ngSwitch]="column.field">
                    <ng-container *ngSwitchCase="true">

                    </ng-container>
                    <ng-container *ngSwitchCase="'name'">
                      <div class="flex flex-row" >
                        <i class=" fa-regular text-sm w-7 text-center" [ngClass]="getStatusIconClasses(element)"></i>
                        <h5 class="text-sm text-zen font-normal">
                            {{element[column.field] }}
                        </h5>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'email'">
                        <h5 class="text-sm text-zen font-normal">
                            {{validEmail(element[column.field]) }}
                        </h5>
                    </ng-container>

                    <!-- <ng-container *ngSwitchCase="'status'">
                        <h4 *ngIf="element[column.field] === 'PENDING'" class=" whitespace-nowrap ">
                            <i class="far opacity-50 text-sm  fa-circle    "></i>
                            <span class="px-1 font-normal text-sm">
                                {{'txt_not_started'|
                                translate:language:ModuleNames.SurveyX}}
                            </span>
                        </h4>
                        <h4 *ngIf="element[column.field] === 'STARTED'"
                            class="uppercase  whitespace-nowrap text-orange ">
                            <i class="far fa-adjust text-sm  text-orange"></i>
                            <span class="px-1 font-normal text-sm">
                                {{'txt_in_progress'|
                                translate:language:ModuleNames.Shared}}
                            </span>
                        </h4>
                        <h4 *ngIf="element[column.field] === 'COMPLETED'"
                            class="uppercase  whitespace-nowrap text-zen-red  ">
                            <i class="far fa-circle-check text-sm  text-zen-green"></i>
                            <span class="px-1 font-normal text-sm text-zen-green">
                                {{'txt_completed'|
                                translate:language:ModuleNames.SurveyX}}
                            </span>
                        </h4>
                        <h4 *ngIf="element[column.field] === 'DELETED'"
                            class="uppercase  whitespace-nowrap text-zen-red  ">
                            <i class="far fa-circle  text-sm  text-zen-red"></i>
                            <span class="px-1 font-normal text-sm text-zen-red">
                                {{'txt_deleted'|
                                translate:language:ModuleNames.SurveyX}}
                            </span>
                        </h4>
                    </ng-container> -->

                    <ng-container *ngSwitchCase="'invitationDate'">
                      <h5 class="text-sm text-zen font-normal">
                          {{element[column.field] | date: 'dd/MM/yyyy'}}
                      </h5>
                  </ng-container>
                  <ng-container *ngSwitchCase="'completionDate'">
                    <h5 class="text-sm text-zen font-normal">
                        {{element[column.field] | date: 'dd/MM/yyyy' }}
                    </h5>
                </ng-container>


                      <ng-container *ngSwitchCase="'action'">
                          <i *ngIf="element['status'] === 'COMPLETED' && element['name'] != 'Anonymous'" [matMenuTriggerFor]="menu2"
                              class="fal openPath font-medium fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full  btn text-center"></i>
                          <mat-menu #menu2="matMenu" class="custom-table-menu">
                              <div class="flex flex-row items-stretch px-2">
                                  <a [routerLink]="'/survey-x/form-library/answers/'+ element.id"
                                      class="text-sm font-medium btn cancel leading-10 rounded px-5 w-full">
                                      {{'btn_open' | translate:language:ModuleNames.SurveyX}}
                                      <i class="fas" [ngClass]="(language | language )?'fa-caret-left':'fa-caret-right'"></i>
                                  </a>
                              </div>
                          </mat-menu>
                      </ng-container>

                    <!-- <ng-container *ngSwitchDefault>
                        <ng-container *ngIf="column?.type == 'criteria' else default">
                            <h5 class="text-sm text-zen font-normal">
                                {{ element[column.field] == ''? ("txtNA"|
                                translate:language:ModuleNames.SurveyX) : extractCustomProps(element,column.field)}}
                            </h5>
                        </ng-container>


                        <ng-template #default class="text-sm text-zen font-normal">
                            <h5 class="text-sm text-zen font-normal">
                                {{ element[column.field] == ''? ("txtNA"|
                                translate:language:ModuleNames.SurveyX) : element[column.field]}}
                            </h5>
                        </ng-template>
                    </ng-container> -->
                </ng-container>
            </div>




        </ng-template>
    </t-table>

    <t-paginator *ngIf="options?.data?.length" [language]="language" [options]="options" [pageSize]="maxResultCount"
        [totalRecords]="totalCount || 0" (pageChanged)="onPageChange($event)"></t-paginator>
</div>
</div>
</div>
