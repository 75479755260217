<new-modal [ngClass]="{'RTL':(language | language )}" [(open)]="open">
    <ng-container header>
        <h2 class="text-zen text-2xl font-semibold">
            {{ type.text | translate:language:ModuleNames.Psychometric}}</h2>
        <i (click)="closeModal('open'); openChange.emit(false)"
            class="fa-regular fa-times   btn text-center text-xl text-zen text-opacity-70"></i>
    </ng-container>
    <ng-container body>
        <div class="flex flex-col gap-5 ">
            <div class="flex flex-row gap-5">
                <div class="flex flex-col gap-2 w-full">
                    <z-select [color]="'exp'" [invalid]="!valid" [required]="true" [options]="ticketForm.product.list"
                        [language]="language" [label]="'Product'| translate:language:ModuleNames.Psychometric"
                        [(ngModel)]="ticket.product" (ngModelChange)="changeProduct($event)">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | extract:language }}
                        </ng-template>
                        <ng-template #optionTemplate let-option>
                            {{option?.name | extract:language}}
                        </ng-template>
                    </z-select>
                </div>
            </div>
            <div *ngIf="ticketForm.category.visible" class="flex flex-row gap-5">
                <div class="flex flex-col gap-2 w-full">
                    <z-select [color]="'exp'" [invalid]="!valid" [required]="true" [options]="ticketForm.category.list"
                        [language]="language" [label]="'Category'| translate:language:ModuleNames.Psychometric"
                        [(ngModel)]="ticket.name">
                        <ng-template #valueTemplate let-value>
                            {{value?.name | extract:language }}
                        </ng-template>
                        <ng-template #optionTemplate let-option>
                            {{option?.name | extract:language}}
                        </ng-template>
                    </z-select>
                </div>
            </div>
            <div class="flex flex-row gap-5 min-w-30">
                <div class="flex flex-col w-full">
                    <div class="flex flex-col gap-2 w-full relative minw-44">
                        <h5 class="text-xs text-zen font-medium">{{ "Description" |
                            translate:language:ModuleNames.Psychometric}}</h5>
                        <div
                            class="text-sm rounded text-zen border bg-white border-zen border-opacity-25 px-3 gap-3 leading-9 truncate flex flex-row justify-between items-center">
                            <div class="flex flex-row items-center w-full">
                                <textarea required maxlength="500" type="text" #field="ngModel"
                                    class="text-sm text-zen w-full h-full border-none bg-transparent"
                                    [(ngModel)]="ticket.desc"
                                    placeholder="{{'Ex. My screen is stuck on loading ...'| translate:language:ModuleNames.Psychometric}}"
                                    rows="4"
                                    [ngClass]="field.invalid && (field.dirty || field.touched)|| (field.invalid && !valid)?'border-zen-red':''">
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row justify-between items-center">
                        <div class="alert alert-danger transform translate-y-1">
                            <div *ngIf="field.invalid && (field.dirty || field.touched || !valid) ">
                                <validation-required [language]="language">
                                </validation-required>
                            </div>
                            <div class="text-xxs flex flex-row items-center gap-1 leading-5 opacity-50">
                                <span>
                                    {{field.value?.length || 0}}/500
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row gap-5">
                <div class="flex flex-col w-full">
                    <span [matTooltip]="'txt_required_field'| translate:language:ModuleNames.Common"
                        [matTooltipClass]="'toolTipAlert'">
                        <div
                            class="flex flex-row justify-between items-center w-full bg-zen bg-opacity-5 h-18 px-5 rounded">
                            <div class="flex flex-row gap-2 items-center">
                                <img *ngIf="ticketForm.file.src" [src]="ticketForm.file.src"
                                    style=" height: 45px; object-fit: cover;object-position: left;" />
                                <span *ngIf="ticketForm.file.src" class="text-xs opacity-50">
                                    {{ticketForm.file.selectedFiles[0].name }}
                                </span>
                                <h5 *ngIf="!ticketForm.file.src" class="opacity-50 text-xl uppercase ">{{'Choose file'|
                                    translate:language:ModuleNames.Common}}</h5>
                            </div>
                            <a class="btn cancel px-5 py-2 rounded-md cursor-pointer">
                                {{'btn_upload'| translate:language:ModuleNames.Common}}<input
                                    class="uploadInput cursor-pointer" id="fileselect"
                                    accept="image/jpeg,image/jpg,image/png" (change)="selectFile($event)" type="file"
                                    [(ngModel)]="ticket.urlSource"></a>
                        </div>
                    </span>
                    <div class="flex flex-row justify-between items-center">
                        <div class="alert alert-danger transform translate-y-1">
                            <span>
                                <div *ngIf="ticketForm.file.uploadLink?.file?.size <= ticketForm.file.maxSize"
                                    class="text-xxs flex flex-row items-center gap-1 leading-5 opacity-50">
                                    <span>
                                        {{ "5MB Max File Size"| translate:language:ModuleNames.Shared }}
                                    </span>
                                </div>
                                <div *ngIf="ticketForm.file.uploadLink?.file?.size > ticketForm.file.maxSize"
                                    class="text-red-600 text-xxs flex flex-row items-center gap-1 leading-5">
                                    <i class="fa-solid fa-exclamation-circle"></i>
                                    <span>
                                        {{ "File size exceeded the limit.(5MB Max File Size)"|
                                        translate:language:ModuleNames.Shared }}
                                    </span>
                                </div>
                                <div *ngIf="ticketForm.file.uploadLink?.file?.size > ticketForm.file.maxSize"
                                    class="text-xxs flex flex-row items-center gap-1 leading-5 opacity-50">
                                    <span>
                                        {{uploadLink?.file?.size || 0}}/{{maxSize}} {{ "Bytes"|
                                        translate:language:ModuleNames.Shared }}
                                    </span>
                                </div>
                                <div *ngIf="ticketForm.file.uploadLink?.file?.size> ticketForm.file.maxSize"
                                    class="text-red-600 text-xxs flex flex-row items-center gap-1 leading-5">
                                    <i class="fa-solid fa-exclamation-circle"></i>
                                    <span>
                                        {{uploadLink?.file?.size || 0}}/{{maxSize}} {{ "Bytes"|
                                        translate:language:ModuleNames.Shared }}
                                    </span>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-5">
                <div class="flex flex-col gap-2 w-full">
                    <mat-checkbox [(ngModel)]="ticket.callForUpdates" class="text-xs text-zen font-medium" #checkbox>
                        {{ "Call me for updates on this matter"| translate:language:ModuleNames.Shared }}
                    </mat-checkbox>
                    <div class="flex flex-row gap-5">
                        <div class="flex flex-col  w-1/4 max-w-1/4" *ngIf="ticket.callForUpdates">

                            <z-select [color]="'exp'" [invalid]="!valid" [required]="true" [number]="true"
                                [options]="ticketForm.mobile.list" [language]="language" [label]="'txtCountryCode'| translate:language:ModuleNames.Shared"
                                [(ngModel)]="ticketForm.mobile.code" [key]="'countryCode'">
                                <ng-template #valueTemplate let-value>
                                    {{value?.countryCode }}
                                </ng-template>
                                <ng-template #optionTemplate let-option>
                                    {{option?.name | extract:language}} {{'('+ option?.countryCode +')' }}
                                </ng-template>
                            </z-select>
                        </div>
                        <div class="flex flex-col  w-3/4" *ngIf="ticket.callForUpdates">
                            <z-input [number]="true" [language]="language" [label]="'txtMobileNumber'| translate:language:ModuleNames.Shared" [invalid]="!valid"
                                [required]="true" [(ngModel)]="ticketForm.mobile.number"
                                [placeholder]="'Mobile number'| translate:language:ModuleNames.Shared"></z-input>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </ng-container>
    <ng-container footer>
        <div class="flex flex-row justify-between w-full gap-3">
            <a class="btn cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1"
                (click)="openChange.emit(false);">
                {{'Cancel'| translate:language:ModuleNames.Psychometric}}
            </a>
            <a class="btn exp text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1"
                (click)="submit()">
                {{'Send'| translate:language:ModuleNames.Psychometric}}
                <i class="fa-regular"></i>
            </a>
        </div>
    </ng-container>
</new-modal>
