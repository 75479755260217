import {
  trigger,
  state,
  style,
  query,
  stagger,
  animate,
  transition,
  keyframes,
  sequence,
  group,
  animateChild,
} from '@angular/animations';
export const chartAnimation = trigger('chartAnimation', [
  transition(':enter', [
    query('.chart', style({ opacity: 0 })),
    query('.chart', [
      stagger(300, [animate('.4s  ease-out', style({ opacity: 1 }))]),
    ]),

    transition(':leave', [
      query('.chart', style({ opacity: 1 })),
      query('.chart', [
        stagger(300, [animate('.4s   ease-out', style({ opacity: 0 }))]),
      ]),
    ]),
  ]),
]);

export const chartSelection = trigger('chartSelection', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({
          opacity: 0,
          transform: 'scale(0.9)',
          transformOrigin: 'center',
        }),
        stagger(
          '300ms',
          animate(
            '300ms ease-out',
            style({
              opacity: 1,
              transform: 'scale(1)',
              transformOrigin: 'center',
            })
          )
        ),
      ],
      { optional: true }
    ),
    query(
      ':leave',
      animate(
        '300ms',
        style({
          opacity: 0,
          transform: 'translateY(700px)',
          transformOrigin: 'center',
        })
      ),
      { optional: true }
    ),
  ]),
]);

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':leave', [
      style({ opacity: 0, transform: 'translateY(40px) rotateX(90deg)' }),
      stagger(
        '-100ms',
        animate(
          '600ms cubic-bezier(0,.81,.18,1)',
          style({ opacity: 0, transform: 'translateY(50px) rotateX(90deg)' })
        )),
    ], { optional: true }
    ),
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'translateY(40px) rotateX(90deg)' }),
        stagger(
          '50ms',
          animate(
            '600ms cubic-bezier(0,.81,.18,1)',
            style({ opacity: 1, transform: 'translateY(0px) rotateX(0deg)' })
          )
        ),
      ],
      { optional: true }
    ),
  ]),
]);
export const listAnimationFast = trigger('listAnimationFast', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'translateY(40px) rotateX(90deg)' }),
        stagger(
          '20ms',
          animate(
            '600ms cubic-bezier(0,.81,.18,1)',
            style({ opacity: 1, transform: 'translateY(0px) rotateX(0deg)' })
          )
        ),
      ],
      { optional: true }
    ),
    query('@*', animateChild(), { optional: true }),
    query(
      ':leave',
      animate(
        '300ms cubic-bezier(0,.81,.18,1)',
        style({ opacity: 0, transform: 'translateY(50px) rotateX(90deg)' })
      ),
      { optional: true }
    ),
  ]),
]);

export const rowlistAnimation = trigger('rowlistAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'translateY(50px) perspective(1000px) rotateX(90deg)', maxHeight: '0px' }),
        stagger(
          '50ms',
          animate(
            '600ms 10ms cubic-bezier(0,.81,.18,1)',
            style({ opacity: 1, transform: 'translateY(0px) perspective(1000px) rotateX(0deg)', maxHeight: '100px' })
          )
        ),
      ],
      { optional: true }
    ),
    query('@*', animateChild(), { optional: true }),
    query(
      ':leave', [
      style({ opacity: 1, transform: 'translateY(0px) perspective(1000px) rotateX(0deg)', maxHeight: '100px' }),
      stagger(
        '-50ms',
        animate(
          '600ms cubic-bezier(0,.81,.18,1)',
          style({ opacity: 0, transform: 'translateY(-50px) perspective(1000px) rotateX(-90deg)', maxHeight: '0px' })
        )),
    ], { optional: true }),

  ]),
]);


export const inOutAnimation = trigger('inOutAnimation', [
  transition(':enter', [
    style({ transform: 'scale(1.2)', opacity: 0, filter: 'blur(6px)' }),
    animate(
      '.2s ease-in',
      style({ transform: 'scale(1)', opacity: 1, filter: 'blur(0px)' })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'scale(1) translateY(0px)',
      opacity: 1,
      filter: 'blur(0px)',
    }),
    animate(
      '.2s ease-in',
      style({ transform: 'scale(.80)', opacity: 0, filter: 'blur(6px)' })
    ),
  ]),
]);

export const pageAnimation = trigger('pageAnimation', [
  transition('* <=> *', [
    // Set a default  style for enter and leave
    query(
      ':enter',
      [
        style({
          opacity: 0,
          transform: 'scale(1.05)',
        }),
        animate(
          '.3s 5s ease-in-out',
          style({
            transform: 'scale(1)',
            opacity: 1,
          })
        ),
      ],
      { optional: true }
    ),
    // Animate the new page in
    query(
      ':leave',
      [
        animate(
          '.3s  ease-in-out',
          style({
            transform: 'scale(0.5)',
            opacity: 0,
          })
        ),
      ],
      { optional: true }
    ),
  ]),
]);

// export const pageAnimation = trigger('pageAnimation', [
//   transition('enter', [
//     query(
//       '.main',
//       style({
//         transformOrigin: 'center',
//         opacity: 0,
//         minHeight: '100vh',
//         position: 'absolute',
//         minWidth: '100%',
//         transform: 'scale(1.05)',
//         filter: 'blur(4px)',
//       }),
//       { optional: true }
//     ),
//     query(
//       '.main',
//       [
//         stagger(200, [
//           animate(
//             '.3s .3s ease-in-out',
//             style({
//               transformOrigin: 'center',
//               minHeight: '100vh',
//               position: 'absolute',
//               minWidth: '100%',
//               transform: 'scale(1)',
//               opacity: 1,
//               filter: 'blur(0px)',
//             })
//           ),
//         ]),
//       ],
//       { optional: true }
//     ),
//   ]),
//   transition(':leave', [
//     query(
//       '.main',
//       style({
//         transformOrigin: 'center',
//         opacity: 1,
//         minHeight: '100vh',
//         position: 'absolute',
//         minWidth: '100%',
//         transform: 'scale(1)',
//         filter: 'blur(0px)',
//       })
//     ),
//     query('.main', [
//       animate(
//         '.3s  ease-in-out',
//         style({
//           transformOrigin: 'center',
//           transform: 'scale(0.95)',
//           minHeight: '100vh',
//           position: 'absolute',
//           minWidth: '100%',
//           opacity: 0,
//           filter: 'blur(4px)',
//         })
//       ),
//     ]),
//   ]),
// ]);

export const smallAnimation = trigger('smallAnimation', [
  transition(':enter', [
    style({
      transform: 'scale(.00001)',
      width: '0px',
      opacity: 0,
      filter: 'blur(6px)',
    }),
    animate(
      '.2s ease-in',
      style({
        transform: 'scale(1)',
        width: '50px',
        opacity: 1,
        filter: 'blur(0px)',
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'scale(1)',
      opacity: 1,
      filter: 'blur(0px)',
    }),
    animate(
      '.2s ease-in',
      style({
        transform: 'scale(.05)',
        opacity: 0,
        filter: 'blur(6px)',
        width: '0px',
      })
    ),
  ]),
]);

export const exitAnimation = trigger('exitAnimation', [
  transition(':enter', [
    style({ minHeight: '0px' }),
    animate(
      '.5s cubic-bezier(0.87, 0, 0.13, 1)',
      style({ minHeight: '300px' })
    ),
  ]),
  transition(':leave', [
    style({
      minHeight: '300px',
    }),
    animate('.5s cubic-bezier(0.87, 0, 0.13, 1)', style({ minHeight: '0px' })),
  ]),
]);
export const fullWidthAnimation = trigger('fullWidthAnimation', [
  transition(':enter', [
    style({ maxWidth: '0px', opacity: 0 }),
    animate(
      '.8s  cubic-bezier(0.87, 0, 0.13, 1)',
      style({ maxWidth: '100%', opacity: 1 })
    ),
  ]),
  transition(':leave', [
    style({
      maxWidth: '100%',
    }),
    animate('.8s cubic-bezier(0.87, 0, 0.13, 1)', style({ maxWidth: '0px', opacity: 0 })),
  ]),
]);


export const fadeUpDown = trigger('fadeUpDown', [
  transition(':enter', [
    style({
      transform: 'translateY(30px)  perspective(1000px) rotateX(-70deg)',
      opacity: 0,
    }),
    animate(
      '.3s cubic-bezier(0.87, 0, 0.13, 1)',
      style({
        transform: 'translateY(0px) perspective(1000px) rotateX(0deg)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateY(0px)',
      opacity: 1,
    }),
    animate(
      '.3s cubic-bezier(0.87, 0, 0.13, 1)',
      style({ transform: 'translateY(30px)', opacity: 0 })
    ),
  ]),
]);

export const rowAnimation = trigger('rowAnimation', [
  transition(':enter', [
    style({
      transform: 'translateY(30px)  perspective(1000px) rotateX(-70deg)',
      opacity: 0,
    }),
    animate(
      '.3s cubic-bezier(0.87, 0, 0.13, 1)',
      style({
        transform: 'translateY(0px) perspective(1000px) rotateX(0deg)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateY(0px)',
      opacity: 1,
    }),
    animate(
      '.3s cubic-bezier(0.87, 0, 0.13, 1)',
      style({ transform: 'translateY(30px)', opacity: 0 })
    ),
  ]),
]);


export const flyRight = trigger('flyRight', [
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1 }),
    sequence([
      animate(
        '.5s cubic-bezier(.19,.24,.03,.98)',
        keyframes([style({ transform: 'scale(0.75)' })])
      ),
      animate(
        '1s cubic-bezier(.19,.24,.03,.98)',
        keyframes([
          style({
            transform: 'scale(0.75) translateX(-150%)',
            position: 'absolute',
            top: 0,
            opacity: 0,
          }),
        ])
      ),
    ]),
  ]),
  transition(':enter', [
    style({
      transform: 'scale(0.75) translateX(150%)',
      position: 'absolute',
      top: 0,
      opacity: 0,
    }),
    sequence([
      animate(
        '.5s .5s cubic-bezier(.19,.24,.03,.98)',
        keyframes([
          style({
            transform: 'scale(0.75) translateX(0%)',
            position: 'static',
            opacity: 1,
          }),
        ])
      ),
      animate(
        '1s  cubic-bezier(.19,.24,.03,.98)',
        keyframes([style({ transform: 'scale(1)', opacity: 1 })])
      ),
    ]),
  ]),
  transition('* => *', animate(300)),
]);

export const biDirectionalState = trigger('biDirectionalState', [
  transition(
    ':increment',
    group([
      query(
        '.title',
        [
          style({
            transform: 'translateX(50%)',
            opacity: 0,
          }),
          group([
            animate(
              '.5s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 1,
                }),
              ])
            ),
            animate(
              '1s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(0%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),

      query(
        ':leave',
        [
          style({
            transform: 'translateX(0%)',
            opacity: 1,
            position: 'absolute'
          }),
          group([
            animate(
              '1s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 0,
                }),
              ])
            ),
            animate(
              '1.2s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(-100%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),
      query('@*', animateChild(), { optional: true }),
      query(
        ':enter',
        [
          style({
            transform: 'translateX(100%)',
            opacity: 0,
          }),
          group([
            animate(
              '.5s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 1,
                }),
              ])
            ),
            animate(
              '1.2s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(0%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),

    ])
  ),
  transition(
    ':decrement',
    group([
      query(
        '.title',
        [
          style({
            transform: 'translateX(-50%)',
            opacity: 0,
          }),
          group([
            animate(
              '.5s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 1,
                }),
              ])
            ),
            animate(
              '1s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(0%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),
      query('@*', animateChild(), { optional: true }),
      query(
        ':enter',
        [
          style({
            transform: 'translateX(-100%)',
            opacity: 0,
          }),
          group([
            animate(
              '.5s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 1,
                }),
              ])
            ),
            animate(
              '1.2s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(0%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),
      query(
        ':leave',
        [
          style({
            transform: 'translateX(0%)',
            opacity: 1,
            position: 'absolute'

          }),
          group([
            animate(
              '1s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 0,
                }),
              ])
            ),
            animate(
              '1.2s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(100%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),
    ])
  ),
]);
export const biDirectionalState300 = trigger('biDirectionalState300', [
  transition(
    ':increment',
    group([
      query(
        '.title',
        [
          style({
            transform: 'translateX(50%)',
            opacity: 0,
          }),
          group([
            animate(
              '.02s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 1,
                }),
              ])
            ),
            animate(
              '.5s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(0%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({
            transform: 'translateX(100%)',
            opacity: 0,
          }),
          group([
            animate(
              '.2s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 1,
                }),
              ])
            ),
            animate(
              '.3s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(0%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),
      query('@*', animateChild(), { optional: true }),
      query(
        ':leave',
        [
          style({
            transform: 'translateX(0%)',
            opacity: 1,
            position: 'absolute'
          }),
          group([
            animate(
              '.3s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(-100%)' })])
            ), ,
            animate(
              '.6s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 0,
                }),
              ])
            ),
          ]),
        ],
        { optional: true }
      ),
    ])
  ),
  transition(
    ':decrement',
    group([
      query(
        '.title',
        [
          style({
            transform: 'translateX(-50%)',
            opacity: 0,
          }),
          group([
            animate(
              '.2s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 1,
                }),
              ])
            ),
            animate(
              '.3s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(0%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),
      query('@*', animateChild(), { optional: true }),
      query(
        ':enter',
        [
          style({
            transform: 'translateX(-100%)',
            opacity: 0,
          }),
          group([
            animate(
              '.2s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 1,
                }),
              ])
            ),
            animate(
              '.3s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(0%)' })])
            ),
          ]),
        ],
        { optional: true }
      ),
      query(
        ':leave',
        [
          style({
            transform: 'translateX(0%)',
            opacity: 1,
            position: 'absolute'
          }),
          group([
            animate(
              '.6s  cubic-bezier(0.16, 1, 0.3, 1)',
              keyframes([style({ transform: 'translateX(100%)' })])
            ), ,
            animate(
              '.6s cubic-bezier(.19,.24,.03,.98)',
              keyframes([
                style({
                  opacity: 0,
                }),
              ])
            ),

          ]),
        ],
        { optional: true }
      ),
    ])
  ),
]);

export const routeInnerAnimations = trigger('routeInnerAnimations', [
  // transition('* => back', [
  //   query(':enter, :leave', style({ position: 'absolute', width: '100%' }), {
  //     optional: true,
  //   }),

  //   group([
  //     query(
  //       ':enter',
  //       [
  //         style({ transform: 'translateX(-100%)' }),
  //         animate(
  //           '1.5s cubic-bezier(0,.81,.18,1)',
  //           style({ transform: 'translateX(0%)' })
  //         ),
  //       ],
  //       { optional: true }
  //     ),
  //     query('@*', animateChild(), { optional: true }),
  //     query(
  //       ':leave',
  //       [
  //         style({ transform: 'translateX(0%)' }),
  //         animate(
  //           '1.5s   cubic-bezier(0,.81,.18,1)',
  //           style({ transform: 'translateX(100%)' })
  //         ),
  //       ],
  //       { optional: true }
  //     ),
  //   ]),
  // ]),
  // transition('* => forward', [
  //   query(':enter, :leave', style({ position: 'absolute', width: '100%' }), {
  //     optional: true,
  //   }),

  //   group([
  //     query(
  //       ':enter',
  //       [
  //         style({ transform: 'translateX(100%)' }),
  //         animate(
  //           '1.5s  cubic-bezier(0,.81,.18,1)',
  //           style({ transform: 'translateX(0%)' })
  //         ),
  //       ],
  //       { optional: true }
  //     ),
  //     query('@*', animateChild(), { optional: true }),

  //     query(
  //       ':leave',
  //       [
  //         style({ transform: 'translateX(0%)' }),
  //         animate(
  //           '1.5s    cubic-bezier(0,.81,.18,1)',
  //           style({ transform: 'translateX(-100%)' })
  //         ),
  //       ],
  //       { optional: true }
  //     ),
  //   ]),
  // ]),
]);

export const routeOuterAnimations = trigger('routeOuterAnimations', [
  // transition('* => up', [
  //   query(':enter, :leave', style({ position: 'absolute', width: '100%' }), {
  //     optional: true,
  //   }),
  //   group([
  //     query(
  //       ':enter',
  //       [
  //         style({ transform: 'translateY(-100%)' }),
  //         animate(
  //           '1.5s  cubic-bezier(0,.81,.18,1)',
  //           style({ transform: 'translateY(0%)' })
  //         ),
  //       ],
  //       { optional: true }
  //     ),
  //     query('@*', animateChild(), { optional: true }),
  //     query(
  //       ':leave',
  //       [
  //         style({ transform: 'translateY(0%)' }),
  //         animate(
  //           '1.5s .1s cubic-bezier(0,.81,.18,1)',
  //           style({ transform: 'translateY(100%)' })
  //         ),
  //       ],
  //       { optional: true }
  //     ),
  //   ]),
  // ]),
  // transition('* => down', [
  //   query(':enter, :leave', style({ position: 'absolute', width: '100%' }), {
  //     optional: true,
  //   }),
  //   group([
  //     query(
  //       ':enter',
  //       [
  //         style({ transform: 'translateY(100%)' }),
  //         animate(
  //           '1.5s  cubic-bezier(0,.81,.18,1)',
  //           style({ transform: 'translateY(0%)' })
  //         ),
  //       ],
  //       { optional: true }
  //     ),
  //     query('@*', animateChild(), { optional: true }),
  //     query(
  //       ':leave',
  //       [
  //         style({ transform: 'translateY(0%)' }),
  //         animate(
  //           '1.5s .1s  cubic-bezier(0,.81,.18,1)',
  //           style({ transform: 'translateY(-100%)' })
  //         ),
  //       ],
  //       { optional: true }
  //     ),
  //   ]),
  // ]),
]);
export const upDownAnimation = trigger('upDownAnimation', [
  transition(':increment', [
    query(':enter, :leave', style({ position: 'absolute', width: '100%' }), {
      optional: true,
    }),
    group([
      query(
        ':enter',
        [
          style({ transform: 'translateY(-100%)' }),
          animate(
            '1.5s  cubic-bezier(0,.81,.18,1)',
            style({ transform: 'translateY(0%)' })
          ),
        ],
        { optional: true }
      ),
      query('@*', animateChild(), { optional: true }),
      query(
        ':leave',
        [
          style({ transform: 'translateY(0%)' }),
          animate(
            '1.5s .1s cubic-bezier(0,.81,.18,1)',
            style({ transform: 'translateY(100%)' })
          ),
        ],
        { optional: true }
      ),
    ]),
  ]),

  transition(':decrement', [
    query(':enter, :leave', style({ position: 'absolute', width: '100%' }), {
      optional: true,
    }),
    group([
      query(
        ':enter',
        [
          style({ transform: 'translateY(-100%)' }),
          animate(
            '1.5s  cubic-bezier(0,.81,.18,1)',
            style({ transform: 'translateY(0%)' })
          ),
        ],
        { optional: true }
      ),
      query('@*', animateChild(), { optional: true }),
      query(
        ':leave',
        [
          style({ transform: 'translateY(0%)' }),
          animate(
            '1.5s .1s cubic-bezier(0,.81,.18,1)',
            style({ transform: 'translateY(100%)' })
          ),
        ],
        { optional: true }
      ),
    ]),
  ]),

]);

export const ChildrenAnimation = [
  trigger('zero', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateY(-20px)' }),
      animate(
        '200ms  ease-in',
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      query('@*', animateChild(), { optional: true }),
    ]),
    transition(':leave', [
      query('@*', animateChild(), { optional: true }),
      animate(
        '200ms ease-in',
        style({ opacity: 0, transform: 'translateY(-20px)' })
      ),
    ])]),
  trigger('first', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateY(-20px)' }),
      animate(
        '200ms .3s ease-in',
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      query('@*', animateChild(), { optional: true }),
    ]),
    transition(':leave', [
      query('@*', animateChild(), { optional: true }),
      animate(
        '200ms ease-in',
        style({ opacity: 0, transform: 'translateY(-20px)' })
      ),
    ]),

  ]),
];
for (let index = 0; index < 50; index++) {
  ChildrenAnimation.push(
    trigger('child' + index, [
      transition(':enter', [
        query('@*', animateChild(), { optional: true }),
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate(
          `200ms ${0.1 + index * 0.02}s ease-in`,
          style({ opacity: 1, transform: 'translateY(0)' })
        ),
      ]),
      transition(':leave', [
        query('@*', animateChild(), { optional: true }),
        animate(
          `200ms ${0.1 + index * 0.02}s ease-in`,
          style({ opacity: 0, transform: 'translateY(20px)' })
        ),
      ]),
    ])
  );
}



export const slidesAniamtion = trigger('slidesAniamtion', [
  transition('* => up', [
    query(':enter, :leave', style({}), {
      optional: true,
    }),
    group([
      query(
        ':leave',
        [
          style({ transform: 'translateY(0%)' }),
          animate(
            '1.5s cubic-bezier(0,.81,.18,1)',
            style({ transform: 'translateY(100%)' })
          ),
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({ transform: 'translateY(-100%)' }),
          animate(
            '1.5s  cubic-bezier(0,.81,.18,1)',
            style({ transform: 'translateY(0%)' })
          ),
        ],
        { optional: true }
      ),
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
  transition('* => down', [
    query(':enter, :leave', style({}), {
      optional: true,
    }),
    group([
      query(
        ':leave',
        [
          style({ transform: 'translateY(0%)' }),
          animate(
            '1.5s  cubic-bezier(0,.81,.18,1)',
            style({ transform: 'translateY(-100%)' })
          ),
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({ transform: 'translateY(100%)' }),
          animate(
            '1.5s cubic-bezier(0,.81,.18,1)',
            style({ transform: 'translateY(0%)' })
          ),
        ],
        { optional: true }
      ),
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
]);
