import { translateText } from "../../../features/survey/survey.component";

export function updateRadioGroupQuestionHTML(options: any, locale?: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  var commentArea = body.querySelector(".sd-question__comment-area");
  var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
  if (!commentBox)
    commentBox = body?.querySelector(".sd-comment.sd-input");

  body.className = "question-body";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-1 text-start";
  inputTitle.innerHTML = translateText('Select an option:', locale);

  body.insertBefore(inputTitle, body.childNodes[0]);

  var max = 300;


  if (commentBox != null) {
    commentBox.setAttribute("maxLength", max);
    var counter = body?.querySelector('.comment-counter');
    if (!counter) {
      counter = document.createElement("span");
      counter.className = "text-end text-gray-500 text-sm comment-counter";
      counter.style.textAlign = "end";
      counter.innerHTML = commentBox.value.length + "/" + max;
      body.appendChild(counter);
    }
    body.insertBefore(commentBox, counter);
    commentBox.addEventListener("input", function () {
      // Update character count on input
      counter.innerHTML = commentBox.value.length + "/" + max;
    });
  }
}

export function updateCheckBoxsQuestionHTML(options: any, locale?: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  var commentArea = body.querySelector(".sd-question__comment-area");
  var commentBox = commentArea?.querySelector(".sd-comment.sd-input");
  if (!commentBox)
    commentBox = body?.querySelector(".sd-comment.sd-input");

  body.className = "question-body";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-1 text-start";
  inputTitle.innerHTML = translateText('Check all that apply:', locale);

  body.insertBefore(inputTitle, body.childNodes[0]);

  var max = 300;


  if (commentBox != null) {
    commentBox.setAttribute("maxLength", max);

    var counter = body?.querySelector('.comment-counter');
    if (!counter) {
      counter = document.createElement("span");
      counter.className = "text-end text-gray-500 text-sm comment-counter";
      counter.style.textAlign = "end";
      counter.innerHTML = commentBox.value.length + "/" + max;
      body.appendChild(counter);
    }
    body.insertBefore(commentBox, counter);
    commentBox.addEventListener("input", function () {
      // Update character count on input
      counter.innerHTML = commentBox.value.length + "/" + max;
    });
  }
}
