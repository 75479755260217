import { questionsBuilderTranslateText, updateQuestionHeaderHTML } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";

/**
 * Updates the HTML for a dropdown question.
 * @param options - The options for updating the question HTML.
 */
export function updateDropdownQuestionHTML(options: any) {
  // Update the question header HTML
  updateQuestionHeaderHTML(options, "Dropdown", "fa-list-dropdown");

  // Get the question body element
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // Add a margin-top to the question body
  // body.className = body.className + " mt-5";

  // Remove the default question content
  body.innerHTML = "";

  // Create a new dropdown question content container
  var newDropdownQuestionContent = document.createElement("div");
  newDropdownQuestionContent.className = "flex flex-col gap-3 py-4 w-full rank-items-container";
  body.appendChild(newDropdownQuestionContent);

  // Add the dropdown choices
  addDropdownChoices(options);

  // Create the footer container
  var footer = document.createElement("div");
  footer.className = "flex flex-row items-center justify-between gap-5 w-full mt-3";

  // Create the left part of the footer
  var footerLeft = document.createElement("div");
  footerLeft.className = "flex flex-row items-center justify-between gap-5";

  var addNewItemButton = document.createElement("button");
  addNewItemButton.className = "btn text-center flex flex-row items-center rounded gap-2 text-zen-primarylight text-sm font-medium w-full bg-zen-littlegray px-4 py-2 rounded";
  addNewItemButton.innerHTML = '<i class="far fa-plus"></i> ' + questionsBuilderTranslateText('Add Choice');
  addNewItemButton.onclick = function () {
    options.question.choices = options.question.choices.concat([ questionsBuilderTranslateText("txtItem") + (options.question.choices.length + 1)]);
    addDropdownChoices(options);
  };

  // Create the "Bulk Answers" button
  var bulkAddButton = document.createElement("button");
  bulkAddButton.className = "btn text-center flex flex-row items-center rounded px-2 py-2 gap-3 bulk-answers-button";
  bulkAddButton.innerHTML = '<i class="fa-light fa-layer-plus"></i> ' + questionsBuilderTranslateText('Bulk Answers');

  // Append the buttons to the footer's left part
  footerLeft.appendChild(addNewItemButton);
  // footerLeft.appendChild(bulkAddButton);

  // Append the left part of the footer to the footer
  footer.appendChild(footerLeft);

  // Append the footer to the question body
  body.appendChild(footer);
}

/**
 * Updates the HTML for a tagbox question.
 * @param options - The options for updating the question HTML.
 */
export function updateTagboxQuestionHTML(options: any) {
  // Update the question header HTML
  updateQuestionHeaderHTML(options, "Dropdown", "fa-list-dropdown");

  // Get the question body element
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // Add a margin-top to the question body
  // body.className = body.className + " mt-5";

  // Remove the default question content
  body.innerHTML = "";

  // Create a new tagbox question content container
  var newTagboxQuestionContent = document.createElement("div");
  newTagboxQuestionContent.className = "flex flex-col gap-3 py-4 w-full rank-items-container";
  body.appendChild(newTagboxQuestionContent);

  // Add the tagbox choices
  addDropdownChoices(options);

  // Create the footer container
  var footer = document.createElement("div");
  footer.className = "flex flex-row items-center justify-between gap-5 w-full mt-3";

  // Create the left part of the footer
  var footerLeft = document.createElement("div");
  footerLeft.className = "flex flex-row items-center justify-between gap-5";

  var addNewItemButton = document.createElement("button");
  addNewItemButton.className = "btn text-center flex flex-row items-center rounded gap-2 text-zen-primarylight text-sm font-medium w-full bg-zen-littlegray px-4 py-2 rounded";
  addNewItemButton.innerHTML = '<i class="far fa-plus"></i> ' + questionsBuilderTranslateText('Add Choice');
  addNewItemButton.onclick = function () {
    options.question.choices = options.question.choices.concat([questionsBuilderTranslateText("txtItem") + (options.question.choices.length + 1)]);
    addDropdownChoices(options);
  };

  // Create the "Bulk Answers" button
  var bulkAddButton = document.createElement("button");
  bulkAddButton.className = "btn text-center flex flex-row items-center rounded px-2 py-2 gap-3 bulk-answers-button";
  bulkAddButton.innerHTML = '<i class="fa-light fa-layer-plus"></i> ' + questionsBuilderTranslateText('Bulk Answers');

  // Append the buttons to the footer's left part
  footerLeft.appendChild(addNewItemButton);
  // footerLeft.appendChild(bulkAddButton);

  // Append the left part of the footer to the footer
  footer.appendChild(footerLeft);

  // Append the footer to the question body
  body.appendChild(footer);
}

/**
 * Adds the choices for a dropdown question to the HTML.
 * @param options - The options for adding the choices.
 */
function addDropdownChoices(options: any) {
  // Get the container for the dropdown choices
  var newDropdownQuestionContent = options.htmlElement.querySelector(".rank-items-container");
  if (!newDropdownQuestionContent) return;

  // Clear the existing choices
  newDropdownQuestionContent.innerHTML = "";

  // Loop through each choice and create the corresponding HTML elements
  for (let i = 0; i < options.question.choices.length; i++) {
    var item = document.createElement("div");
    item.className = "flex flex-row items-center justify-between w-full pl-4 pr-2 py-1 ranking-item";

    var itemLeft = document.createElement("div");
    itemLeft.className = "flex items-center gap-5 w-full";

    var dragIcon = document.createElement("i");
    dragIcon.className = "fa-light fa-grip-dots-vertical ranking-item-drag-icon";

    var itemText = document.createElement("input");
    itemText.className = "border border-solid border-zen-paleWhite hover:border-[#7D92B5] focus:border-[#7D92B5] px-3 rounded w-full block py-2";
    // itemText.className = "ranking-item-text-input w-full block py-1";
    itemText.setAttribute("type", "text");
    itemText.setAttribute("placeholder", questionsBuilderTranslateText("Enter a choice here..."));
    itemText.setAttribute("value", options.question.choices[i].title);

    // Add event listeners to update the choice's values when changed
    (function (index) {
      itemText.addEventListener("keydown", function (e) {
        e.stopPropagation();

        // if (this.value.length > 50) {
        //   this.value = this.value.substring(0, 50);
        // }
      });

      itemText.addEventListener("change", function () {
        options.question.choices[index].title = this.value;
        options.question.choices[index].value = this.value;
        options.question.choices[index].text = this.value;
      });
    })(i);

    var deleteButton = document.createElement("button");
    deleteButton.className = "text-center flex flex-row items-center rounded p-3 mx-2 leading-9 text-xs font-medium gap-3 ranking-item-delete-button";
    deleteButton.innerHTML = '<i class="fa-light fa-trash"></i>';

    // Add an onclick event listener to delete the choice
    deleteButton.onclick = function () {
      options.question.choices.splice(i, 1);

      // Update the question choices
      addDropdownChoices(options);
    };

    itemLeft.appendChild(dragIcon);
    itemLeft.appendChild(itemText);

    item.appendChild(itemLeft);
    item.appendChild(deleteButton);

    newDropdownQuestionContent.appendChild(item);
  }
}
