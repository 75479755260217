import { Component, OnInit } from '@angular/core';
import { AnalysisBaseComponentComponent } from '../../ui/closed-survey/analysis-base-component/analysis-base-component.component';
import { GeneralService } from 'src/app/core/services/general.service';
import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';
import { Subject, combineLatest } from 'rxjs';
import wellbeingClosedSurveyAnimation from '../../utils/closed-survey-animations';
import { switchMap } from 'rxjs/operators';
import { SurveyAnalysisService } from '@ex/wellbeing/analysis';

@Component({
  selector: 'app-overall-analysis',
  templateUrl: './overall-analysis.component.html',
  styleUrls: ['./overall-analysis.component.scss'],
  animations: wellbeingClosedSurveyAnimation,
})
export class OverallAnalysisComponent extends AnalysisBaseComponentComponent implements OnInit {

  responseRate: number = 10;
  totalRespondents: number = 100;
  totalCompleted: number = 10;
  totalComments: number = 100;

  isCompanySelected = false;

  filters: AnalyticsFilter;

  filtersLoaded = new Subject();
  filtersLoaded$ = this.filtersLoaded.asObservable();


  constructor(public generalService: GeneralService, private analysisService: AnalyticsService, private surveyAnalysisService: SurveyAnalysisService) {
    super();
  }

  ngOnInit(): void {

    this.fetchOverallAnalysis();
    this.fetchFactorAnalysis()

    this.analysisService.filters$
      .subscribe(filters => {
        this.filters = filters;
        this.filtersLoaded.next(true);
      });

    if (this.data?.companyKey) {
      this.isCompanySelected = true;
    }
  }

  fetchOverallAnalysis() {
    var subscription = this.filtersLoaded$.pipe(
      switchMap(filters => {

        const request = {
          companyKey: this.data.companyKey,
          surveyKey: this.data.surveyKey,
          tenantKey: this.data.tenantKey,
          filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []]
        }


        const obs$ = combineLatest([
          this.surveyAnalysisService.getOverallAnalysis(request),
          this.surveyAnalysisService.getTotalCommentsCount(request),
        ]) 
        return obs$;
      })
    ).subscribe(([overall, comments]: [any, any]) => {

      this.totalRespondents = overall.totalCount;
      this.responseRate = overall.responseRate;
      this.totalCompleted = overall.completedCount; 
      this.totalComments = comments

    })

    this.addSubscription(subscription);
  }

  fetchFactorAnalysis() {
    this.filtersLoaded$.subscribe(factors => {

    });
  }

}
