<div *ngIf="!changingLanguage" class="flex flex-col gap-5  w-full">
  <div class="md:flex flex-row justify-end items-center gap-3 hidden">
    <!-- <h4 class="text-zen text-opacity-80 text-base font-medium"><i class="far fa-chevron-circle-right"
        [ngClass]="(language | language )?'fa-chevron-circle-left':'fa-chevron-circle-right'"></i>
      {{extractNameLanguage(question.headingName)}}</h4> -->

    <div class="flex flex-row w-2/12 justify-evenly gap-1">
      <h5 class="text-zen text-opacity-80 text-xs font-medium text-center">{{
        getConfidence('sub-questions-binary-likeness-rating',
        'LL')
        }}</h5>
      <h5 class="text-zen text-opacity-80 text-xs font-medium text-center">
        {{ getConfidence('sub-questions-binary-likeness-rating', 'ML') }}</h5>
    </div>

  </div>

  <div class="flex flex-row flex-wrap">
    <div class="md:flex flex-row justify-between items-center w-full py-1"
      *ngFor="let subQuestion of question.subQuestions; let ssq = index">
      <div class="flex flex-col w-full">
        <div class="flex flex-row w-full gap-0 md:gap-1 flex-wrap md:flex-nowrap  p-4 md:p-0">
          <div class="flex  md:bg-white  rounded px-5 md:leading-12 exp w-full"
            [ngClass]="subQuestion.otherAnswer?'w-full':'md:w-10/12'">
            <mat-checkbox *ngIf="subQuestion.otherAnswer" [(ngModel)]="subQuestion.otherAnswerChecked">
              <h5 class="text-zen md:text-base text-sm md:leading-12">{{extractNameLanguage(subQuestion.name)}}</h5>
            </mat-checkbox>
            <p class="text-zen text-base md:leading-12" *ngIf="!subQuestion.otherAnswer">
              {{extractNameLanguage(subQuestion.name)}}</p>
          </div>

          <div *ngIf="!subQuestion.otherAnswer" class="flex flex-row md:w-2/12 w-full md:gap-1 gap-2 md:pt-0 pt-2">
            <div class="flex flex-col items-center gap-2 justify-center w-6/12">
              <div class="flex flex-col items-center justify-center w-full btn h-12 bg-white border-2 border-inset rounded"  (click)="subQuestion.answer = 'LL'"  [ngClass]="subQuestion.answer == 'LL'?'border-exp text-exp':'border-transparent'">
                <i class="fal fa-thumbs-down text-2xl rounded w-full text-center"></i>
              </div>
              <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
                {{ getConfidence('sub-questions-binary-likeness-rating', 'LL') }}</h5>
            </div>
            <div class="flex flex-col items-center gap-2 justify-center w-6/12">
            <div class="flex flex-col items-center justify-center w-full btn h-12 bg-white border-2 border-inset rounded"
              (click)="subQuestion.answer = 'ML'"
              [ngClass]="subQuestion.answer == 'ML'?'border-exp text-exp':'border-transparent'">
              <i class="fal fa-thumbs-up text-2xl rounded w-full text-center"></i>

            </div>
            <h5 class="text-zen text-opacity-80 md:text-xs text-xxs font-medium text-center md:hidden">
              {{ getConfidence('sub-questions-binary-likeness-rating', 'ML') }}</h5>
          </div>

          </div>

        </div>
        <div class="flex flex-row flex-wrap md:flex-nowrap gap-2 mt-2 w-full"
          *ngIf="subQuestion.otherAnswerChecked && subQuestion.otherAnswer">
          <div [@child1] class="flex flex-col md:w-1/2 w-full">
            <div class="bg-zen-green bg-opacity-5 rounded-md px-5 pt-1 overflow-hidden ">
              <mat-form-field class="w-full -mt-3" appearance="none">
                <textarea rows="3" [attr.disabled]="countWord(question.otherAnswer1, lgCount) > lgCount ? true : null"
                  maxlength="300" (keyup)="countWord(question.otherAnswer1, lgCount)"
                  [placeholder]="'txtMostLiked'| translate:language:ModuleNames.Shared" [(ngModel)]="question.otherAnswer1"
                  matInput></textarea>
              </mat-form-field>
            </div>
            <h4 *ngIf=" countWord(question.otherAnswer1, lgCount)> 0"
              [ngClass]="{'text-zen-red': countWord(question.otherAnswer1, lgCount)>= lgCount}" class="text-xxs">
              {{countWord(question.otherAnswer1, lgCount)}}
              {{'txtof'| translate:language:ModuleNames.Shared}} {{lgCount}}</h4>
          </div>
          <div [@child2] class="flex flex-col md:w-1/2 w-full">
            <div class="bg-zen-red bg-opacity-5 rounded-md px-5 pt-1 overflow-hidden ">
              <mat-form-field class="w-full -mt-3" appearance="none">
                <textarea rows="3" [attr.disabled]="countWord(question.otherAnswer2, lgCount) > lgCount ? true : null"
                  maxlength="300" (keyup)="countWord(question.otherAnswer2, lgCount)"
                  [placeholder]="'txtLeastLiked'| translate:language:ModuleNames.Shared" [(ngModel)]="question.otherAnswer2"
                  matInput></textarea>
              </mat-form-field>

            </div>
            <h4 *ngIf=" countWord(question.otherAnswer2, lgCount)> 0"
              [ngClass]="{'text-zen-red': countWord(question.otherAnswer2, lgCount)>= lgCount}" class="text-xxs">
              {{countWord(question.otherAnswer2, lgCount)}}
              {{'txtof'| translate:language:ModuleNames.Shared}} {{lgCount}}</h4>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
