import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LanguagePageComponent } from './features/language-page/language-page.component';
import { RespondentInformationPageComponent } from './features/respondent-information-page/respondent-information-page.component';
import { RespondentAnswersViewComponent } from './features/respondent-answers-view/respondent-answers-view.component';
import { FormAnalysisComponent } from './features/form-analysis/form-analysis.component';

import { RespondentViewComponent } from './features/respondent-view/respondent-view.component';
import { RespondentsComponent } from './features/respondents/respondents.component';
import { FormBankComponent } from './features/form-bank/form-bank.component';
import { AnalyticalCriteriaPageComponent } from './features/builder/analytical-criteria-page/analytical-criteria-page.component';
import { AssignWeightsComponent } from './features/builder/assign-weights/assign-weights.component';
import { InitialSetupComponent } from './features/builder/initial-setup/initial-setup.component';
import { LanguagesPageComponent } from './features/builder/languages-page/languages-page.component';
import { QuestionsBuilderPageComponent } from './features/builder/questions-builder-page/questions-builder-page.component';
import { RespondentsPageComponent } from './features/builder/respondents-page/respondents-page.component';
import { SharePageComponent } from './features/builder/share-page/share-page.component';
import { FormCreatorComponent } from './features/library/form-creator/form-creator.component';
import { LiveFormViewComponent } from './features/library/live-form-view/live-form-view.component';
import { MainInfoComponent } from './features/library/main-info/main-info.component';
import { SurveyComponent } from 'survey-angular-ui';
import { AuthGuardService } from '../core/services/auth-guard.service';
import { SurveyxComponent } from './surveyx.component';
import { PeopleComponent } from '../shared/people/people.component';
import { FormLibraryTableComponent } from './features/library/form-library-table/form-library-table.component';


const routes: Routes = [{
  path: '', component: SurveyxComponent,canActivate: [AuthGuardService], children: [
    { path: '', redirectTo: 'form-library', pathMatch: 'full' },

    { path: 'language/:id', component: LanguagePageComponent, data: { animation: 1, title: "Survey X" } },

    { path: 'form-analysis/:id', component: FormAnalysisComponent, canActivate: [AuthGuardService], data: { animation: 2, title: "Survey X" } },
    {
      path: 'form-builder', children: [
        { path: 'initial-setup', component: InitialSetupComponent, data: { animation: 2, title: "Survey X" } },
        { path: 'initial-setup/:id', component: InitialSetupComponent, data: { animation: 2, title: "Survey X" } },
        { path: 'questions-builder/:id', component: QuestionsBuilderPageComponent, data: { animation: 3, title: "Survey X" } },
        { path: 'languages/:id', component: LanguagesPageComponent, data: { animation: 4, title: "Survey X" } },
        { path: 'analytical-criteria/:id', component: AnalyticalCriteriaPageComponent, data: { animation: 5, title: "Survey X" } },
        { path: 'respondents/:id', component: RespondentsPageComponent, data: { animation: 6, title: "Survey X" } },
        { path: 'share/:id', component: SharePageComponent, data: { animation: 7, title: "Survey X" } },
        { path: 'assign-weights/:id', component: AssignWeightsComponent, data: { animation: 2, title: "Survey X" } },
      ]
    },
    { path: 'form-library', component: FormLibraryTableComponent, canActivate: [AuthGuardService], data: { animation: 1, title: "Survey X" } },
    { path: 'form-library/form-creator/:id', component: FormCreatorComponent, canActivate: [AuthGuardService], data: { animation: 2, title: "Survey X" } },
    { path: 'form-library/main-info', component: MainInfoComponent, canActivate: [AuthGuardService], data: { animation: 2, title: "Survey X" } },
    { path: 'form-library/answers/:respondentid', component: RespondentAnswersViewComponent, canActivate: [AuthGuardService], data: { animation: 2, title: "Survey X" } },
    { path: 'form-library/live-form/:id', component: LiveFormViewComponent, canActivate: [AuthGuardService], data: { animation: 2, title: "Survey X" } },
    { path: 'respondents', component: PeopleComponent, canActivate: [AuthGuardService], data: { animation: 1, title: "Survey X" } },
    // { path: 'respondents/:id', component: RespondentViewComponent, canActivate: [AuthGuardService], pathMatch: "full", data: { animation: 2, title: "Survey X" } },
    { path: 'form-bank', component: FormBankComponent, canActivate: [AuthGuardService], data: { animation: 1, title: "Survey X" } },

  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SurveyxRoutingModule { }
