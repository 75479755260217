/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CustomProperty } from '../models/CustomProperty';
import type { FeedbackRatingDto } from '../models/FeedbackRatingDto';
import type { QuestionnaireDto } from '../models/QuestionnaireDto';
import type { QuestionnaireSubmissionDto } from '../models/QuestionnaireSubmissionDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param token 
     * @returns QuestionnaireDto Success
     * @throws ApiError
     */
    public getQuestionnaire(
token: string,
): Observable<QuestionnaireDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/questionnaire/get/{token}',
            path: {
                'token': token,
            },
        });
    }

    /**
     * @param token 
     * @returns any Success
     * @throws ApiError
     */
    public markRespondentInProgress(
token: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/questionnaire/mark-started/{token}',
            path: {
                'token': token,
            },
        });
    }

    /**
     * @param token 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public submit(
token: string,
requestBody?: QuestionnaireSubmissionDto,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/questionnaire/submit/{token}',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param token 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public submitFeedback(
token: string,
requestBody?: FeedbackRatingDto,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/questionnaire/submit-feedback/{token}',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param token 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public updateAnalyticalCriteria(
token: string,
requestBody?: Array<CustomProperty>,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/questionnaire/update-criterias/{token}',
            path: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
