<div class="file-container flex flex-row relative h-20">
    <input #fileInput type="file" class="hidden z-0" [accept]="accept" (change)="selectLogo($event)">
    <div class="innerContainer flex flex-col items-center h-full z-10 relative cursor-pointer">
        <div [@first] *ngIf="!file || invalid"
            class="h-full w-full flex flex-row items-center justify-center text-sm  cursor-pointer z-10 gap-1"
            (click)="fileInput.click()">
           <span>{{ "txtClearHere" | translate:language:ModuleNames.Shared }}</span><span class="text-int">{{ "txtBrowse" | translate:language:ModuleNames.Shared }}</span>
        </div>
        <div [ngClass]="{'z-20 opacity-100':fileOver}" class="flex flex-col z-0 opacity-0 items-center justify-center absolute w-full h-full bg-white bg-opacity-90 transition-all">
            <i class="fa-regular fa-box-open-full text-4xl text-zen"></i>
        </div>
        <div *ngIf="file && !invalid" class="flex flex-row w-full h-full justify-between gap-10">
            <div class="flex flex-row p-2 gap-1 items-center w-3/4 max-w-xs">
                <img [@child1] #img [src]="imgSrc?imgSrc: file" class="h-full maxw10 object-contain rounded-md" />
                <span *ngIf="file?.name; else checkmark" [@first] class="text-zen text-xs px-2">{{file?.name}}</span>
                <ng-template #checkmark>
                    <lottie-player [@child2] *ngIf="file" src="/assets/lottie/checkmark.json" background="transparent"
                        speed="1" style="width: 3rem; height: 3rem;" hover="false" autoplay="false">
                    </lottie-player>
                </ng-template>
            </div>
            <div class="text-int flex flex-row items-center justify-end gap-2 cursor-pointer" (click)="$event.stopPropagation()">
                <div [@child3] class="px-2 cursor-pointer flex flex-row gap-1 items-center relative whitespace-nowrap">
                  <input type="file" class="opacity-0 absolute h-full w-full btn " [accept]="accept" (change)="selectLogo($event)">
                    <i class="fa-solid fa-arrow-rotate-left"></i>
                    <span>{{"txtReupload" | translate:language:ModuleNames.Shared}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
