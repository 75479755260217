<div (click)="logData(metrics)"
  class="flex flex-colitems-center  w-full  rounded-xl gap-5  transition-all min-h-full zen-main-container justify-center relative">
  <div [@biDirectionalState]="(language | language )? -step: step"
    class="flex flex-col justify-center items-center gap-10 w-full py-20">
    <div class="flex flex-col gap-5 items-center">
      <h2 class="text-zen text-base">{{'txtBeforeStarting'| translate:language:ModuleNames.Shared}}</h2>
      <ng-container *ngIf="predefinedMetricsDone">
        <form #form="ngForm" class="flex flex-row md:w-3xl flex-wrap -ml-3 -mr-3 w-full">
          <ng-container *ngFor="let metric of metrics;let d = index">
            <ng-container
              *ngIf="(!metric.type ||  metric.type  == 'DROPDOWN') && (!resetDropdown && metric.options.length)">
              <div [ngClass]="d == metrics.length -1 && !(d % 2)?'w-full':'md:w-1/2'"
                class="flex flex-col gap-3 w-full md px-3 items-stretch pb-6">
                <div class="flex flex-col w-full items-stretch">
                  <z-select [submitted]="submitted" required="true" [color]="'exp'"
                    [label]="metric.name|extract:language" [name]="metric.key" [(ngModel)]="metric.value"
                    (ngModelChange)="metric.isEntity === true ? entityGroupChanged($event, metric.key) : doNothing()"
                    [options]="metric.options" [language]="language">
                    <ng-template #valueTemplate let-value>
                      {{value?.name|extract:language}}
                    </ng-template>
                  </z-select>

                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="metric.type == 'TEXT' || metric.type == 'DATE'">
              <div [ngClass]="(d == metrics.length -1 && !(d % 2)) || metric.type == 'TEXT'?'w-full':'md:w-1/2'"
                class="flex flex-col gap-3 w-full md px-3 items-stretch pb-6">

                <div (click)="logData(metric)" *ngIf="metric.type == 'DATE'">
                  <z-datepicker [name]="metric.key" [max]="metric.key == 'DOB'? maxDateOfBirth:null" [submitted]="submitted" required="true"
                    [color]="'exp'" [label]=" metric.name|extract:language" [language]="language"
                    (ngModelChange)="formateExistingDate($event,metric, true)" [ngModel]="metric.value"></z-datepicker>
                </div>
                <div class="flex flex-col items-stretch" *ngIf="metric.type == 'TEXT'">
                  <z-textarea [name]="metric.key" [maxlength]="300" [maxCount]="300" [submitted]="submitted" required="true" [label]="addAsteriskToLabel(metric.name | extract:language)"
                    [(ngModel)]="metric.value"></z-textarea>
                </div>
              </div>
                <!-- <div class="flex flex-col items-stretch" *ngIf="metric.type == 'TEXT'">
                        <label class="text-xs text-zen font-medium truncate whitespace-nowrap">{{addAsteriskToLabel(metric.name | extract:language) }}</label>
                        <textarea type="text" class="form-control flex flex-col items-stretch" [required]="true" [ngModel]="metric.value"
                                  [maxlength]="300" rows="3" (ngModelChange)="updateCharacterCount(metric.value)"></textarea>
                            <div [ngClass]="metric.value?.length >= 30 ? 'text-red-600' : 'text-zen'"
                              class="text-xxs textend">
                              {{ metric.value.length}} / {{ 300 }}
                            </div>
                </div>
              </div> -->

            </ng-container>
          </ng-container>


        </form>
        <div class="flex flex-row justify-center">
          <a [style.background]="colorTheme" class="btn exp rounded px-10 leading-15 text-lg"
            (click)="submit(form)">{{'txtContinue'|translate:language:ModuleNames.Shared}}</a>
        </div>
      </ng-container>
    </div>
    <!-- <ng-container *ngFor="let metric of metrics;let d = index">
            <div   class="flex flex-col px-5 md:w-1/2 w-full py-1 h-full gap-16 justify-center items-center ">
                <h2 *ngIf="step == 0" class="text-center text-zen text-lg">{{'txtBeforeStarting'| translate:language:ModuleNames.Shared}}
                </h2>
                <div class="flex flex-col gap-5 items-center w-full" *ngIf="metric.type != 'DATE'">
                    <h3  class="text-zen text-lg font-semibold title">{{(metric.name |
                        extract:language)}}
                    </h3>
                    <ng-container *ngIf="metric.options.length <= 6">
                        <div class="flex flex-row gap-1">
                            <ng-container *ngIf="metric.key != 'GENDER'">
                                <ng-container  *ngFor="let option of metric.options">
                                    <ng-container *ngIf="option.parentEntityName">

                                    </ng-container>

                                    <div (click)="updateComplexMetric(option, metric, d)"
                                    class="flex flex-col bg-white border  hover:text-exp hover:border-exp  border-inset text-zen rounded shadow-2x6 p-5 w-32 btn text-center text-sm"
                                   >
                                    {{ (metric.isEntity === true ? ((option.parentEntityName | extract:language) + ' - '
                                    +
                                    ( option.name | extract:language)) : (option.name | extract:language)) }}
                                </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="metric.key == 'GENDER'">
                                <div (click)="updateComplexMetric(option, metric, d)"
                                    class="flex flex-col items-center justify-center gap-4 bg-white border hover:text-exp hover:border-exp border-inset text-zen rounded shadow-2x6 p-5 w-32 btn text-center text-sm"
                                    *ngFor="let option of metric.options">
                                    <i [ngClass]="{'fa-user-tie-hair': option.key == 'male', 'fa-user-tie-hair-long': option.key == 'female'}"
                                        class="fa-light  text-5xl"></i>
                                    <h5 class="text-xxs">{{ option.name | extract:language }}</h5>

                                </div>
                            </ng-container>

                        </div>

                    </ng-container>
                    <ng-container *ngIf="resolveTempList(metric)">
                        <div class="flex flex-col justify-center bg-white w-full pt-2 rounded px-5  h-15 max-w-lg border border-zen border-opacity-20">
                            <mat-form-field class="w-full" appearance="none">
                                <mat-select panelClass="relative" [placeholder]="metric.name | extract:language"
                                    #general="ngModel" required general
                                    (openedChange)="tempList = metric.options" (closed)="tempList = metric.options; searchValue = null"
                                    (selectionChange)="metric.isEntity === true ? entityGroupChanged(metric.value,metric.key) : doNothing()"
                                    [(ngModel)]="metric.value">
                                    <div class="sticky top-3 px-2 bg-white z-10 py-2 transform -translate-y-3">
                                        <div
                                            class="flex flex-row h-10 justify-between items-center bg-zen bg-opacity-10 rounded w-full my-3 px-5">
                                            <input #input [(ngModel)]="searchValue"
                                                (keyup)="searchList(searchValue, metric.options)"
                                                [placeholder]="'txt_search'|translate:language:ModuleNames.Shared"
                                                class="w-full leading-10 bg-transparent" />

                                            <i *ngIf="searchValue"
                                                (click)="searchValue = null; tempList = metric.options"
                                                class="fal fa-times w-4 h-4 block rounded-full text-center leading-4 bg-zen bg-opacity-10 shadow-sm text-zen-red cursor-pointer text-xs"></i>
                                        </div>
                                    </div>

                                    <mat-option *ngFor="let o of tempList" [value]="o.key">
                                        {{ (metric.isEntity === true ? ((o.parentEntityName | extract:language) + '
                                        - ' +
                                        ( o.name | extract:language)) : (o.name | extract:language)) }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </div>
                <div class="flex flex-col gap-5 items-center w-full" *ngIf="metric.type == 'DATE'"
                    [ngClass]="{'border border-zen-red rounded-md':(!metric.value && proceeded)}">
                    <h3 class="text-zen text-lg font-semibold title">{{metric.name| extract:language}}</h3>
                    <z-datepicker [color]="'exp'" class="w-full" [format]="'U'"
                        [customClass]="'flex flex-row justify-center bg-white w-full rounded px-5  h-15 border border-zen border-opacity-20'"
                        [placeholder]=" metric.name| extract:language" [max]="today" [language]="language"
                        [date]="metric.value" (dateChange)="setReminderDate($event, metric)">
                    </z-datepicker>
                </div>
                <div class="flex flex-row justify-center" *ngIf="metric?.options?.length > 6 || metric.type == 'DATE'">
                    <a [ngClass]=" metric.value ?'opacity-100 pointer-events-auto':'opacity-50 pointer-events-none'"
                        class="btn exp rounded px-10 leading-15 text-lg"
                        (click)="d == metrics.length -1 && !(d % 2) ? proceed.emit(): step = step +1">{{'txtContinue'|
                        translate:language}}</a>
                </div>

            </div>
        </ng-container> -->
  </div>
</div>
