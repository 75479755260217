<div class="flex flex-col gap-5 border-opacity-20 zen-main-container px-10 w-full Page">
  <div class=" flex flex-row box-border  items-center w-full h-110 pt-5 justify-between">
    <div class="flex flex-col w-max">
      <h5 [@first] class="text-zen font-medium text-2xl">
        {{"Form Library"| translate:language:ModuleNames.SurveyX}}</h5>
    </div>


    <div class="flex flex-row w-max items-center text-center">

      <!-- <a (click)="openNewFormCategory()" class="btn block h-9 leading-9 rounded text-zenMain text-center font-medium text-sm w-max px-5"><span
          class="fa-regular fa-plus pr-1"></span> {{'New Form Category'| translate:language:ModuleNames.SurveyX}}</a> -->

      <app-new-form-btn></app-new-form-btn>
    </div>
  </div>


  <div class="zen-main-container flex flex-row items-stretch ">
    <div class="flex flex-col w-full  zen-main-container">
      <div class="flex flex-row justify-between">
        <div
          class="text-sm rounded border border-zen border-opacity-30 text-zen bg-white px-3 h-9 gap-2 leading-9 truncate flex flex-row justify-start items-center">
          <i [ngClass]="{'rotate-h-45':(language|language)}" class="fal fa-search text-zen transform"></i>
          <input [(ngModel)]="searchText" (ngModelChange)="onServerSearchText($event)"
            [placeholder]="'txt_search' | translate:language:ModuleNames.Shared"
            class="bg-transparent overflow-hidden h-12 leading-12 text-sm  w-80" type="text">
        </div>

        <div class="flex flex-row w-full items-center justify-end gap-2">
          <div class="inline-flex">
            <div class="flex flex-row w-full items-center gap-2">
              <div class="w-[180px] max-w-[260px] {{categoryFilterBorderColor}}">
                <z-select labelPosition="before" [label]="'Category' | translate:language:ModuleNames.SurveyX" [color]="'exp'" class="placeholder:fixed"
                  [options]="categoriesList" [placeholder]="'Category'| translate:language:ModuleNames.SurveyX"
                  [language]="language" [(ngModel)]="selectedCategoryFilter"
                  (ngModelChange)="onCategoryFilterChanged($event)">
                  <ng-template #valueTemplate let-value>

                    {{getCategoryName(value?.name,language) | translate:language:ModuleNames.SurveyX }}

                  </ng-template>

                  <ng-template #optionTemplate let-option>

                    {{getCategoryName(option?.name,language) | translate:language:ModuleNames.SurveyX}}

                  </ng-template>
                </z-select>
              </div>


              <div (click)="onRemoveFilters()" *ngIf="selectedTypeFilter != 'All' || selectedCategoryFilter != 'All'"
                [matTooltip]="'Clear Filters'" matTooltipPosition="above" matTooltipClass="zen-tooltip"
                class="btn flex items-start  hover:bg-[#0082C61A]">
                <div class="flex h-10 w-10 justify-center items-center rounded">
                  <div class="flex h-4 items-center gap-3">
                    <div class="flex w-5 h-5 justify-center items-center">
                      <i class="w-4 shrink-0 text-zen-primarylight text-center text-base font-normal fa-regular fa-times">

                      </i>

                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
      <t-table #table *ngIf="tableOptions" [options]="tableOptions">
        <ng-template #cellTempate let-element let-column='column'>
          <ng-container *ngIf="column.field == 'key'">
            <h5 class="text-sm text-zen-blueGray500 font-normal">{{ element.key }}</h5>
          </ng-container>
          <ng-container *ngIf="column.field == 'formType'">
            <h5 class="text-sm text-zen-blueGray500  font-normal">{{ element.formType |
              translate:language:ModuleNames.SurveyX }}</h5>
          </ng-container>
          <ng-container *ngIf="column.field == 'title'">
            <h5 class="text-sm text-zen-blueGray500 min-w-[280px] font-medium">{{ element.title | sjsTranslate:language }}
            </h5>
          </ng-container>
          <ng-container *ngIf="column.field == 'status'">
            <h5 *ngIf="element.status == 'Live'" class="text-sm text-zen-redSelect ">
              <i [ngClass]="language == 'AR' ? 'pl-1.5' : ''" class="fa-solid text-xs fa-circle text-zen-red pr-1.5"></i>{{
              element.status| translate:language:ModuleNames.SurveyX }}
            </h5>
            <h5 *ngIf="element.status == 'Closed'" class="text-sm text-zen-confirm ">
              <i [ngClass]="language == 'AR' ? 'pl-1.5' : ''" class="fa-regular text-base fa-check-circle text-zen-medgreen pr-1.5 "></i>{{
              element.status| translate:language:ModuleNames.SurveyX }}
            </h5>
            <h5 *ngIf="element.status == 'Draft'" class="text-sm  text-zen-blueGray500 ">
              <i [ngClass]="language == 'AR' ? 'pl-1.5' : ''"
                class="fa-regular opacity-50 text-base fa-clock pr-1.5 "></i>{{ element.status |
              translate:language:ModuleNames.SurveyX }}
            </h5>
          </ng-container>

          <ng-container *ngIf="column.field == 'category'">
            <h5 class="text-sm text-zen-blueGray500 font-normal">{{ getCategoryName(element.category,language) }}</h5>
          </ng-container>

          <ng-container *ngIf="column.field == 'dateCreated'">
            <h5 class="text-sm text-zen-blueGray500  font-normal">{{ element.dateCreated }}</h5>
          </ng-container>
          <ng-container *ngIf="column.field == 'dateModified'">
            <h5 class="text-sm text-zen-blueGray500  font-normal">{{ element.dateModified }}</h5>
          </ng-container>



          <ng-container *ngIf="column.field == 'action'">
            <div class="flex flex-row items-center gap-1  justify-end">

              <a *ngIf="element.status == 'Draft'" routerLink="/survey-x/form-builder/questions-builder/{{element.key}}"
                class="btn openPath whitespace-nowrap leading-9 px-4 rounded text-zen font-medium text-xs bg-zen bg-opacity-5 opacity-0 pointer-events-none">
                {{'Open'| translate:language:ModuleNames.SurveyX}}<i class="fas  leading-9"
                  [ngClass]="(language | language )?'fa-caret-left':'fa-caret-right'"></i></a>

              <a *ngIf="element.status == 'Closed'" [routerLink]="'/survey-x/form-analysis/'+element.key"
                class="btn openPath whitespace-nowrap leading-9 px-4 rounded text-zen font-medium text-xs bg-zen bg-opacity-5 opacity-0 pointer-events-none">
                {{'Open'| translate:language:ModuleNames.SurveyX}}<i class="fas  leading-9"
                  [ngClass]="(language | language )?'fa-caret-left':'fa-caret-right'"></i></a>

              <a *ngIf="element.status == 'Live'" routerLink="/survey-x/form-library/live-form/{{element.key}}"
                class="btn openPath whitespace-nowrap leading-9 px-4 rounded text-zen font-medium text-xs bg-zen bg-opacity-5 opacity-0 pointer-events-none">
                {{'Open'| translate:language:ModuleNames.SurveyX}} <i class="fas  leading-9"
                  [ngClass]="(language | language )?'fa-caret-left':'fa-caret-right'"></i></a>

              <div class="flex flex-row py-4 pr-10 pl-3  items-center justify-end">
                <i [ZMenuTriggerFor]="menu"
                  class="fal font-normal fa-ellipsis-h h-9 w-9 leading-9 bg-white text-zen rounded-full  btn  text-center"></i>

                <z-menu #menu>
                  <ng-container [ngTemplateOutlet]="moreMenuItems"></ng-container>
                </z-menu>

                <ng-template #moreMenuItems>
                  <!-- <a *ngIf="element.status == 'Live'" MenuItem (click)="openShare(element)" class="text-zen-primarylight">
                    <i class="fa-regular fa-share mx-1"></i>

                    {{'txtShare'| translate:language:ModuleNames.SurveyX}}</a> -->
                  <!-- <a *ngIf="element.status != 'Draft'" MenuItem (click)="onPreviewForm(element)"
                    class="text-zen-primarylight">
                    <i class="fa-regular fa-eye mx-1"></i>
                    {{'txtPreview Form'| translate:language:ModuleNames.SurveyX}}</a> -->
                  <!-- <a *ngIf="element.status == 'Draft'" MenuItem (click)="onLaunchForm(element)"
                    class="text-zen-primarylight">
                    <i class="fa-regular fa-rocket-launch mx-1"></i>
                    {{'txtLaunch Form'| translate:language:ModuleNames.SurveyX}}</a> -->
                  <a *ngIf="element.status == 'Closed'" MenuItem (click)="onRelaunchForm(element)"
                    class="text-zen-primarylight">
                    <i class="fa-regular fa-rocket mx-1"></i>
                    {{'txtRelaunch Form'| translate:language:ModuleNames.SurveyX}}</a>
                  <a MenuItem (click)="onCloneForm(element)" class="text-zen-primarylight">
                    <i class="fa-regular fa-clone mx-1"></i>{{'Clone Form'| translate:language:ModuleNames.SurveyX}}</a>
                  <!-- <a *ngIf="element.status == 'Live'" MenuItem (click)="onCloseForm(element)"
                    class="text-zen-primarylight">
                    <i class="fa-regular fa-lock mx-1"></i>
                    {{'txtClose Form'| translate:language:ModuleNames.SurveyX}}</a> -->
                  <!-- <a *ngIf="element.status != 'Draft'" MenuItem (click)="onDraftForm(element)"
                    class="text-zen-primarylight">
                    <i class="fa-regular fa-turn-down-right mx-1"></i>
                    {{'txtChange Status To Draft'| translate:language:ModuleNames.SurveyX}}</a> -->
                  <!-- <a MenuItem (click)="onExportData(element)" class="text-zen-primarylight">
                    <i class="fa-regular fa-file-export mx-1"></i>{{'txtExport Data'|
                    translate:language:ModuleNames.SurveyX}}</a> -->
                  <a *ngIf="element.status == 'Draft'" MenuItem (click)="onDeleteForm(element)"
                    class="text-zen-primarylight">
                    <i class="fa-duotone fa-trash mx-1"></i>
                    {{'txtDelete Form'| translate:language:ModuleNames.SurveyX}}</a>
                  <!-- <a *ngIf="element.status == 'Draft'" MenuItem (click)="onEditEmailTemplate(element)"
                    class="text-zen-primarylight">
                    <i class="fa-regular fa-pen-to-square mx-1"></i>
                    {{'txtEdit Email Template'| translate:language:ModuleNames.SurveyX}}</a> -->
                </ng-template>

              </div>
            </div>
          </ng-container>
        </ng-template>

        <ng-container customization>
          <div class="flex flex-row justify-between pb-2">
            <h5 class="text-zen text-xxs font-medium uppercase">{{'txtColumnName' |
              translate:language:ModuleNames.Common}}</h5>
            <h5 class="text-zen text-xxs font-medium uppercase">{{'txt_status' | translate:language:ModuleNames.Common}}
            </h5>
          </div>
          <div [@listAnimation]="allColumns?.length" class="innerListScroll max-h-screen-50 flex flex-col gap-1">

            <ng-container *ngFor="let column of allColumns; let n = index" [language]="language">
              <div class="flex flex-col items-stretch bg-white px-5 py-2 rounded-sm">
                <z-toggle [disable]="n <= 3" [label]="column.title | translate:language:ModuleNames.Common"
                  [(ngModel)]="column.isSelected"></z-toggle>
              </div>
            </ng-container>

          </div>
        </ng-container>

      </t-table>

      <t-paginator *ngIf="tableOptions?.data?.length" [language]="language" [page]="pageNumber"
        [options]="tableOptions" [pageSize]="pageSize" [totalRecords]="totalCount || 0"
        (pageChanged)="onPageChange($event)"></t-paginator>

    </div>
  </div>




  <app-share-link *ngIf="share.open" [(open)]="share.open" [selectedKey]="share.key"></app-share-link>
  <!-- <app-new-form-category *ngIf="newFormCategory.open" [(open)]="newFormCategory.open" [selectedKey]="tenantKey"></app-new-form-category> -->
  <app-export-data *ngIf="exportData.open" [(open)]="exportData.open" [selectedKey]="exportData.key"> </app-export-data>
  <app-edit-form-details-view *ngIf="editFormDetails.open" [(open)]="editFormDetails.open"
    [selectedKey]="editFormDetails.key"> </app-edit-form-details-view>

</div>
