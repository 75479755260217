<div class="flex flex-row justify-between items-center">

  <h2 class="text-lg font-normal text-zen-primarydefault">
    {{"txtQuestionsperSection"| translate:language:ModuleNames.SurveyX}}
  </h2>

  <z-select [color]="'exp'" [label]="'Section'| translate:language:ModuleNames.SurveyX" labelPosition="before"
    class="placeholder:fixed" [(ngModel)]="selectedSectionKey" [options]="sections" [language]="language">
    <ng-template #valueTemplate let-value>
      {{value?.title |sjsTranslate:language}}
    </ng-template>
  </z-select>
</div>

<div class="flex flex-col items-stretch w-full pt-4 gap-4">

  <ng-container *ngFor="let section of sections | sjSectionFilter:selectedSectionKey">

    <h1 class="text-xl"><span class="text-exp">{{'txtSection' | translate:language:ModuleNames.SurveyX}} {{ sections.indexOf(section) }}: </span> <span
        class="text-zen-dark">{{
        (section?.title | sjsTranslate:language)
        }}</span></h1>

    <ng-container *ngFor="let question of questions | sjQuestionFilter:section.key; let i = index">

      <ng-container [ngSwitch]="question.type">
        <ng-container *ngSwitchDefault>

          <sx-dependent-question *ngIf="question.dependencyQuestions" [question]="question" [questions]="questions"
            [language]="language"></sx-dependent-question>

          <sx-question-chart [filters]="filtersLoaded$ | async" class="flex flex-row gap-4" [surveyCriterias]="surveyCriterias" [data]="question?.data$ | async" [question]="question"
            [questionNo]="(question.questionNo)" [language]="language"></sx-question-chart>

        </ng-container>

      </ng-container>

    </ng-container>
  </ng-container>
</div>
