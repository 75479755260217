<div *ngIf="surveyModel && language" class="flex flex-col border-opacity-20 w-full Page">
  <app-survey-navbar [language]="language" [surveyModel]="surveyModel" [survey]="survey"
    (languageChanged)="onlanguageChanged($event)"></app-survey-navbar>

  <div class="w-full flex flex-col items-center" style="height: calc(100vh - 110px)">
    <div class="flex flex-col items-center gap-10 px-5 max-w-3xl py-10">
      <div class="flex flex-col justify-between items-center gap-4 self-stretch">
        <i class="fa-regular fa-circle-check text-5xl text-exp"></i>

        <h1 class="text-zen text-2xl font-medium self-stretch text-center">
          {{'Thank You!' | translate:language:ModuleNames.SurveyX }}
        </h1>

        <p class="text-zen text-base text-center max-w-sm">
          {{"You have completed the" | translate:language:ModuleNames.SurveyX}}
          {{' ' + (survey?.title | sjsTranslate:language) + ' '}}
          {{survey?.typeName | translate:language:ModuleNames.SurveyX}}.
        </p>
      </div>

      <div class="flex flex-col justify-center items-center self-stretch gap-6 p-8 pb-10 bg-white rounded-xl"
        style="box-shadow: 4px 4px 8px 0px rgba(74, 95, 130, 0.10);" *ngIf="showFeedbackForm;else closeTab">

        <div class="flex flex-col items-center gap-2">
          <p class="text-zen text-sm text-center">
            {{"Please rate your experience" | translate:language:ModuleNames.SurveyX}}
          </p>

          <div class="flex items-start gap-2" #stars>
            <i class="fa-solid fa-star text-4xl text-zen-neutral" *ngFor="let item of [].constructor(5); let i = index"
              (click)="starClicked(i,stars)"></i>
          </div>

          <p [ngClass]="selectedStarLabelAndColor.label ? selectedStarLabelAndColor.color : 'text-exp'"
            class="text-xs text-center max-w-sm">{{selectedStarLabelAndColor.label |
            translate:language:ModuleNames.SurveyX}}</p>
        </div>

        <div class="flex flex-col items-start self-stretch gap-2">
          <p class="text-zen-primarylight text-xs text-center">
            {{"Do you have any comments/suggestion to improve this survey?" |
            translate:language:ModuleNames.SurveyX}}
          </p>

          <input type="text" [(ngModel)]="feedback"
            class="w-full h-10 py-2 px-3 rounded border border-solid border-zen-paleWhite bg-white"
            [placeholder]="'Write any comments/suggestions...'| translate:language:ModuleNames.SurveyX" />
        </div>

        <button (click)="submitFeedback()"
          class="btn exp flex h-12 py-2 px-5 items-center gap-2 rounded text-white text-lg font-medium">
          {{"txtSubmit Feedback"| translate:language:ModuleNames.SurveyX}}
        </button>
      </div>

      <ng-template #closeTab>
        <div class="flex flex-col justify-center items-center self-stretch gap-6 p-8 pb-10 bg-white rounded-xl"
          style="box-shadow: 4px 4px 8px 0px rgba(74, 95, 130, 0.10);">

          <div class="flex flex-col items-center gap-6">
            <p class="text-zen-primarydefault text-sm text-center">
              {{"txtYour feedback has been submitted." | translate:language:ModuleNames.SurveyX}}
            </p>

            <p class="text-zen-primarylight text-xs text-center">
              {{"txtYou may close this tab now." | translate:language:ModuleNames.SurveyX}}
            </p>
          </div>

          <!-- <button (click)="closeWindow()"
            class="btn flex h-12 py-2 px-5 items-center gap-2 rounded bg-zen-littlegray text-zen-primarylight text-sm font-medium">
            {{"txtClose Tab"| translate:language:ModuleNames.SurveyX}}
          </button> -->
        </div>
      </ng-template>
    </div>
  </div>
</div>
