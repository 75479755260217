import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { ModuleName } from '../../enums/ModuleName';

@Component({
  selector: 'q-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  @Input() currentQuestion;
  @Input() language;
  @Input() flatted = false;
  @Input() allQuestions;

  constructor(eventManager: EventManager, private cdr: ChangeDetectorRef) {
    eventManager.addGlobalEventListener('window', 'resize',
      e => e.target.innerWidth < 768 ? this.appendDiv() : '');
  }

  ngOnInit(): void {
    window.innerWidth < 768 ? this.appendDiv() : '';
  }
  ModuleNames=ModuleName
  appendDiv() {
    this.cdr.detectChanges();
    console.log('append in progress');
    document.querySelector('.stickyFooter').appendChild(document.querySelector('.progressComponent'))
  }

}
