import { Environment } from "@abp/ng.core";

export const environment = {
  endPoint: "https://sa.ex.surveyx.zenithr.net",
  emailURL: "http://localhost:4200",
  jsonTranslation: true,
  production: true,
  firebase: {
    apiKey: "AIzaSyB0PPhI7BG-qPCXSrOChaK0j_fMYvTCpRs",
    authDomain: "exitanalysis-a6325.firebaseapp.com",
    databaseURL: "https://exitanalysis-a6325.firebaseio.com",
    projectId: "exitanalysis-a6325",
    storageBucket: "exitanalysis-a6325.appspot.com",
    messagingSenderId: "34494496853",
    appId: "1:34494496853:web:fd9a3a532ef7d0470594dc",
    measurementId: "G-38ZFW3WM5P"
  },
  translationAPI: "https://translationsapi.zenithr.net/api/"
};

export const abpEnvironment = {
  production: true,
  application: {
    baseUrl: '',
    name: 'ex',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: '',
    redirectUri: '',
    clientId: '',
    responseType: '',
    showDebugInformation: true,
    scope: '',
    requireHttps: true,
  },
  apis: {
    default: {
      // url: 'https://ob-api.zenithr.net',
      // url: 'https://gt-dev.zenithr.net',
      url: 'https://sa.gt.zenithr.net',
      rootNamespace: 'Zenithr',
    },
  },
  cloudfunctionsUrl: 'https://us-central1-exitanalysis-a6325.cloudfunctions.net/'// PROD
  // cloudfunctionsUrl: 'https://us-central1-zendev-9e3f0.cloudfunctions.net' // DEV
} as Environment;
