import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';




@Injectable({
  providedIn:'root'
})
export class CriteriasService {
  errorMessage: any;
  postId: any;

  URL = environment.endPoint;

  httpOptions={headers: new HttpHeaders({
    'Access-Control-Allow-Origin': 'https://assessments.zenithr.net',
    'Content-Type': 'application/json;'
  })}

  constructor(private httpClient: HttpClient) { }



  // async getAllCriteriasByTenantAndFormId(formId : any, tenantId:any)
  // {
  //   return await this.httpClient.get(this.URL+`analytical-criteria/get-all-criterias-by-tenant-and-form/${formId}/${tenantId}`);
  // }

  async getAllCriteriasByTenant(tenantId:any)
  {
    return await this.httpClient.get(this.URL+`analytical-criteria/get-all-criterias-by-tenant/${tenantId}`);
  }

  async getAllCriteriasInFormByTenantAndForm(tenantId:any, formId :any)
  {
    return await this.httpClient.get(this.URL+`analytical-criteria/get-all-criterias-in-form-by-tenant-and-form/${tenantId}/${formId}`);
  }

  createCriteria(criteria : any)
  {
    return this.httpClient.post(this.URL+'analytical-criteria/add-criteria' , criteria);
  }

  deleteCriteria(id : any)
  {
    return this.httpClient.delete(this.URL+`analytical-criteria/delete-criteria/${id}`);
  }

  updateCriteriaInForm(body : any)
  {
    return this.httpClient.post(this.URL+'analytical-criteria/update-criteria-in-form' , body);
  }

  getCriteria(criteriaId : any)
  {
    return this.httpClient.get(this.URL+'analytical-criteria/get-criteria/' + criteriaId );
  }

  updateCriteria(criteriaId : any, criteria:any)
  {
    return this.httpClient.put(this.URL+'analytical-criteria/update-criteria/' + criteriaId, criteria );
  }

}

