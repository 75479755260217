import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';

@Component({
  selector: 'tour-base',
  template: ``,
  styles: [
  ],
})
export class TourBaseComponent extends BaseComponent implements OnInit {
  language;
  currentUser;
  builtInButtons;
  constructor() {
    super();
    this.EssentialObject$.pipe().subscribe(obj => {
      if (obj.language) {
        this.language = obj.language;
      }
      if (obj.user) {
        this.currentUser = obj.user;
      }
    });
  }

  ngOnInit(): void {
    this.builtInButtons = {
      skip: {
        classes: 'btn ghost py-2 px-4 rounded text-sm leading-6	font-medium',
        text: this.extractTranslate('txt_skip'),
      },
      endTour: {
        classes: 'btn exp py-2 px-4 rounded text-sm leading-6 font-medium',
        text: this.extractTranslate('txt_end_tour'),
      },
      startTour: {
        classes: 'btn exp py-2 px-4 rounded text-sm leading-6 font-medium',
        text: this.extractTranslate('txt_start_tour'),
        type: 'next'
      },
      next: {
        classes: 'btn exp py-2 px-4 rounded text-sm leading-6 font-medium',
        text: `<div class="flex gap-3 items-center"><h3>${this.extractTranslate('txt_next')}</h3><span>${this.extractTranslate('txt_next_arrow')}</span></div>`,
        type: 'next'
      },
      back: {
        classes: 'btn cancel py-2 px-4 rounded text-sm leading-6 font-medium',
        text: this.extractTranslate('txt_back_arrow'),
        type: 'back'
      },
      progressBtn: {
        classes: 'btn ghost pointer-events-none py-2 px-0 w-full-60 flex justify-start items-center',
        text: '',
      }
    };
  }


  bodyText = `<div class="text-sm	leading-6 text-zen">[text]</div>`;

  extractTranslate(text: string) {
    return this.localize(text, this.language, this.ModuleNames.Shared)
  }
}
