import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken
} from '@angular/common/http';
import { delay, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoaderService } from '@ex/module/core/services/loader.service';
export const BYPASS_LOG = new HttpContextToken(() => false);
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) { }
  requests: number = 0;
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_LOG) === false)
    {
    this.loaderService.displayLoader();
  }
  else{
    this.loaderService.hideLoader()

  }
    this.requests++;
    return next.handle(request).pipe(
      finalize(() => {
        this.requests--;
        if (this.requests == 0)
          this.loaderService.hideLoader()
      }),
    );

    // else
    // {
    // return next.handle(request).pipe(
    //   finalize(() => {
    //     this.requests--;
    //     if (this.requests == 0)
    //       this.loaderService.hideLoader()
    //   }),
    // );
    // }
  }
}
