import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModuleName } from '../../enums/ModuleName';

@Component({
  selector: 'right-preview',
  templateUrl: './right-preview.component.html',
  styleUrls: ['./right-preview.component.scss']
})
export class RightPreviewComponent implements OnInit {
  @Input() language;
  @Input() displayExportButton;
  @Input() title;
  constructor() { }
  @Output() export = new EventEmitter();
  ModuleNames=ModuleName
  ngOnInit(): void {
  }

}
