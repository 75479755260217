<z-drawer [(open)]="open" (openChange)="openChange.emit(open)">
    <ng-container header>
        <div class="flex flex-row  items-center  gap-2">
            <h4 class="text-2xl leading-10 font-bold text-zen">
                {{ "txt_Filters_432" | translate:language:ModuleNames.Common }}</h4>
        </div>
        <div class="flex flex-row gap-5 items-center">
            <a class="btn block ghost h-10 leading-10 rounded text-center text-sm w-max px-5" (click)="resetAll()">
                {{ "Reset" | translate:language:ModuleNames.Common }}</a>
            <a class="btn block exp h-10 leading-10 rounded text-center text-sm w-max px-5" (click)="apply()">
                {{ "Apply" | translate:language:ModuleNames.Common }}</a>
        </div>
    </ng-container>
    <ng-container body>
        <div class="flex flex-col gap-10 py-10 relative w-max px-10 bg-zen-blueWhite min-w-38 max-w-38">
            <div *ngIf="!selectedFilters.BusinessLevel.exclude && BusinessLevelDataSource.data.length>0" class="flex flex-col gap-5">
                <div class="flex flex-row justify-between">
                    <h3 class="text-sm text-zen font-medium text-opacity-50">
                        {{ "Business Level" | translate:language:ModuleNames.SurveyX }}</h3>
                    <h5 class="text-xs text-zen text-opacity-50 cursor-pointer" (click)="selectedFilters.BusinessLevel.selectedValues=[];resetBandLevels()">
                        {{ "Reset" | translate:language:ModuleNames.Common }}</h5>
                </div>
                <mat-tree #tree [dataSource]="BusinessLevelDataSource" [treeControl]="BusinessLevelTreeControl" class="permission-tree bg-transparent">
                    <mat-tree-node class="h-10" *matTreeNodeDef="let node" matTreeNodeToggle>
                        <div class="flex flex-row w-full divide-x items-center justify-between hover:bg-exp hover:bg-opacity-5" [ngClass]="node.highlight?'bg-exp bg-opacity-5':''">
                            <div class="flex flex-col  h-10 justify-center">
                                <div class="flex flex-row items-center gap-2 btn text-sm text-zen">
                                    <i *ngIf="!node.restricted" (click)="handleClickAll(node,node.key)" [ngClass]="node.checked? 'text-exp fa-solid  fa-square-check ':'fa-light fa-square '"></i>
                                    <i *ngIf="node.restricted" class="fa-regular fa-lock"></i>
                                    {{node.name| extract: language}}
                                </div>
                            </div>
                        </div>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <div class="mat-tree-node h-10">
                            <div class="flex flex-row w-full divide-x items-center justify-between hover:bg-exp hover:bg-opacity-5" [ngClass]="node.highlight?'bg-exp bg-opacity-5':''">
                                <div class="flex flex-col h-10 justify-center ">
                                    <div class="flex flex-row items-center text-sm text-zen gap-1.5">
                                        <button matTreeNodeToggle [attr.aria-label]="'Toggle ' +node.name| extract: language">
                                            <mat-icon class="mat-icon-rtl-mirror text-sm ">
                                                <i [ngClass]="BusinessLevelTreeControl.isExpanded(node)? ' fa-chevron-down':'fa-chevron-right '" class="fa-light text-sm"></i>
                                            </mat-icon>
                                        </button>
                                        <div class="flex flex-row items-center gap-2 btn text-sm text-zen">
                                            <i *ngIf="!node.restricted" (click)="handleClickAll(node,node.key);handleClickAllNested(node,node.key) " [ngClass]="node.checked? 'text-exp fa-solid  fa-square-check ':'fa-light fa-square '"></i>
                                            <i *ngIf="node.restricted" class="fa-regular fa-lock"></i>
                                            {{node.name| extract: language}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div [class.permission-tree-invisible]="!BusinessLevelTreeControl.isExpanded(node)" role="group">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </div>
                    </mat-nested-tree-node>
                </mat-tree>
            </div>
            <hr />
            <div *ngIf="selectedFilters.Others.length>0" class="flex flex-col gap-5">
                <h3 class="text-sm text-zen font-medium text-opacity-50">
                    {{ "txtCriterias" | translate:language:ModuleNames.SurveyX }}</h3>
                <div class="flex flex-col gap-2">
                    <div class="flex flex-row" *ngFor="let Category of selectedFilters.Others;let i=index">
                        <div class="flex flex-col gap-1 w-full">
                            <div class="flex flex-row justify-between">
                                <h5 class="text-xs text-zen font-medium">{{Category.name}}
                                </h5>
                                <div class="text-xs text-zen text-opacity-50 cursor-pointer" (click)="Category.selectedValues=[];">
                                    {{ "Reset" | translate:language:ModuleNames.Common }}</div>
                            </div>
                            <div class="flex flex-col justify-center bg-opacity-5 rounded">
                                <z-select [color]="'exp'" [options]="ConfigSelectList(Category.list)" [(ngModel)]="Category.selectedValues" [multiple]="true" [language]="language">
                                    <ng-template #valueTemplate let-value>
                                        {{resolver(Category.key,value.key)}} </ng-template>
                                    <ng-template #optionTemplate let-option>
                                        {{resolver(Category.key,option.key)}}

                                    </ng-template>
                                </z-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</z-drawer>
