<div *ngIf="language" class="fixed flex flex-col items-stretch top-0 left-0 Page h-screen w-screen z-50 bg-zen-background">

  <app-form-builder-header [surveyId]="surveyId" [surveyTitle]="currentForm?.title  | sjsTranslate:language" [currentPage]="'share'"
    [showAssignWeights]="showAssignWeights" [language]="language" (previousStep)="previousStep()"
    (share)="showShareModal()" (pageChanged)="pageChanged($event)">
  </app-form-builder-header>

  <div class="flex flex-col items-center w-full h-100vh-110px pt-10 overflow-scroll" *ngIf="currentForm">
    <div class="max-w-5xl w-full flex flex-col items-stretch">
      <div class="flex justify-between mb-4 w-full">
        <div class="w-max">
          <h3 class="page-title mb-1">
            {{"Sharing Options" | translate:language:ModuleNames.SurveyX}}
          </h3>

          <p class="page-sub-title">
            {{"Customize form settings, timeframe, and sharing templates of the form" |
            translate:language:ModuleNames.SurveyX}}
          </p>
        </div>

        <div class="flex justify-center items-center gap-3">
          <button class="btn preview-button px-3 py-2" (click)="export()">
            <i class="fa-light fa-download"></i>
          </button>
          <a class="btn preview-button px-3 py-2" target="_blank"
            [routerLink]="'/survey-x-questionnaire/preview/'+surveyId">
            <i class="fa-regular fa-eye mr-2"></i>
            {{"Preview Survey" | translate:language:ModuleNames.SurveyX}}
          </a>
        </div>
      </div>

      <div class="flex flex-row flex-wrap main-bg items-center pt-5 pb-10 mb-8 gap-3 justify-center rounded-md">
        <div class="w-full">
          <div class="section-header w-full px-8 py-3 mb-8">
            <h5 class="section-header-title">
              {{"Shuffle Options" | translate:language:ModuleNames.SurveyX}}
            </h5>
          </div>
<!--
          <div class="w-full px-8 pb-8 mb-1">
            <z-toggle class="w-full" [disable]="false"
              [label]="'Shuffle Sections' | translate:language:ModuleNames.SurveyX"
              [(ngModel)]="shuffleSections"></z-toggle>

            <p class="input-description">
              {{"Randomize the order of the sections when presented to the form taker." |
              translate:language:ModuleNames.SurveyX}}
            </p>

            <p class="input-description">
              {{"Leave turned off to keep the default order of the sections." |
              translate:language:ModuleNames.SurveyX}}
            </p>
          </div> -->

          <div class="w-full px-8 pb-8 mb-1">
            <z-toggle class="w-full" [disable]="false"
              [label]="'Shuffle Questions' | translate:language:ModuleNames.SurveyX"
              [(ngModel)]="shuffleQuestions"></z-toggle>
            <p class="input-description">
              {{"Randomize the order of questions within each section when presented to the form
              taker." |
              translate:language:ModuleNames.SurveyX}}
            </p>
          </div>
        </div>

        <div class="w-full">
          <div class="section-header w-full px-8 py-3 mt-3 mb-8">
            <h5 class="section-header-title">
              {{"Survey Options" | translate:language:ModuleNames.SurveyX}}
            </h5>
          </div>

          <div class="w-full px-8 pb-8 mb-1">
            <z-toggle class="w-full" [disable]="false"
              [label]="'Anonymous Form' | translate:language:ModuleNames.SurveyX"
              [(ngModel)]="isAnonymousForm"></z-toggle>
            <p class="input-description">
              {{"Keep respondent's answers anonymous." | translate:language:ModuleNames.SurveyX}}

            </p>
          </div>

          <!-- <div class="w-full px-8 pb-8 mb-1">
            <z-toggle class="w-full" [disable]="false"
              [label]="'Question Per Page' | translate:language:ModuleNames.SurveyX"
              [(ngModel)]="questionPerPage"></z-toggle>
            <p class="input-description">
              {{"Show only one question per page" | translate:language:ModuleNames.SurveyX}}
            </p>
          </div> -->

          <div class="w-full px-8 pb-8 mb-1">
            <z-toggle class="w-full" [disable]="false"
              [label]="'GCC Countries' | translate:language:ModuleNames.SurveyX"
              [(ngModel)]="isGccCountries"></z-toggle>
            <p class="input-description">
              {{"GCC Countries Only" | translate:language:ModuleNames.SurveyX}}
            </p>
          </div>


          <div class="w-full px-8 pb-8 mb-1">
            <div class="flex flex-row items-end gap-5">
              <z-input class="w-24" [number]="true" [label]="'txtSurveyDuration'| translate:language:ModuleNames.SurveyX" [(ngModel)]="currentForm.duration"></z-input>
              <span class="text-zen pb-2 firstUpper">{{'minutes'| translate:language:ModuleNames.SurveyX}}</span>
            </div>
            <p class="input-description">
              {{"txtSurveyDurationDesc" | translate:language:ModuleNames.SurveyX}}
            </p>
          </div>

        </div>

        <div class="w-full">
          <div class="section-header w-full px-8 py-3 mt-3 mb-8">
            <h5 class="section-header-title">
              {{"Timeframe" | translate:language:ModuleNames.SurveyX}}
            </h5>
          </div>

          <div class="w-full px-8 pb-8 mb-1">
            <z-toggle class="w-full" [disable]="false"
              [label]="'Definite Form' | translate:language:ModuleNames.SurveyX" [(ngModel)]="isDefeniteForm"
              (ngModelChange)="initTemplates(true); resetEndDate($event)"></z-toggle>
            <p class="input-description">
              {{"Set a definite time window for this form to be active. Uncheck this option to set as an indefinite form." | translate:language:ModuleNames.SurveyX}}
            </p>

            <div class="flex gap-3 w-max" *ngIf="isDefeniteForm">
              <div class="w-full mt-2">
                <div class="flex items-center justify-between section-input px-2">
                  <span class="whitespace-nowrap">
                    {{"Start Date" | translate:language:ModuleNames.SurveyX}}
                  </span>
                  <z-datepicker [disabled]="true" [color]="'exp'" class="form-input w-full pl-1" [language]="language"
                    (ngModelChange)="startDate=$event" [ngModel]="startDate" [min]="today">
                  </z-datepicker>
                </div>
              </div>

              <div class="w-full mt-2">
                <div class="flex items-center justify-between section-input px-2">
                  <span class="whitespace-nowrap">
                    {{"End Date" | translate:language:ModuleNames.SurveyX}}
                  </span>
                <z-datepicker   [color]="'exp'" [min]="startDate"
                    [language]="language" class="form-input w-full pl-1" (ngModelChange)="endDate=$event"
                    [ngModel]="endDate"></z-datepicker>
                </div>
              </div>

            </div>
          </div>

          <div class="w-full px-8 pb-8 mb-1">
            <z-toggle class="w-full" [disable]="false"
              [label]="'Set Reminders' | translate:language:ModuleNames.SurveyX" [(ngModel)]="setReminders"></z-toggle>
            <p class="input-description">
              {{"Set a maximum of 3 Reminders to remind your respondents at preset times to fill
              the form before the completion date." |
              translate:language:ModuleNames.SurveyX}}
            </p>

            <div *ngIf="setReminders">
              <div class="flex flex-col gap-1 mt-2">
                <div class="w-1/3 mt-1 flex items-center gap-5" *ngFor="let reminder of remindersList;let i = index">
                  <div class="flex items-center justify-between section-input px-2">
                    <span class="whitespace-nowrap">
                      {{"txtReminder" | translate:language:ModuleNames.SurveyX}} {{i+1}}
                    </span>

                    <z-datepicker [color]="'exp'" [language]="language" class="form-input w-full pl-1"
                      (ngModelChange)="setReminder($event, i)" [min]="plusOneDay(startDate, true)"
                      [max]="minusOneDay(endDate)" [ignoreDays]="getReminders(remindersList, i)"
                      [disabled]="disablePreviousDate(reminder.date)" [ngModel]="reminder.date"></z-datepicker>


                  </div>

                  <button (click)="deleteReminder(i)"
                    class="text-center flex flex-row items-center rounded px-4 py-4 gap-3 ellipsis-button">
                    <i class="fa-light fa-trash"></i>
                  </button>
                </div>
              </div>

              <button *ngIf="remindersList.length < 3" class="button new-reminder-button flex items-center justify-between gap-2 px-3 mt-5"
                (click)="addReminder()">
                <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.75 1.25V6.25H12.75C13.1562 6.25 13.5 6.59375 13.5 7C13.5 7.4375 13.1562 7.75 12.75 7.75H7.75V12.75C7.75 13.1875 7.40625 13.5 7 13.5C6.5625 13.5 6.25 13.1875 6.25 12.75V7.75H1.25C0.8125 7.75 0.5 7.4375 0.5 7C0.5 6.59375 0.8125 6.25 1.25 6.25H6.25V1.25C6.25 0.84375 6.5625 0.5 7 0.5C7.40625 0.5 7.75 0.84375 7.75 1.25Z"
                    fill="#5D75A2" />
                </svg>

                {{"Add Reminder" | translate:language:ModuleNames.SurveyX}}
              </button>
            </div>
          </div>
        </div>

        <div class="w-full">
          <div class="section-header w-full px-8 py-3 mt-3 mb-8">
            <h5 class="section-header-title">
              {{"Welcome Message" | translate:language:ModuleNames.SurveyX}}
            </h5>
          </div>

          <div class="w-full px-8">
            <z-toggle class="w-full" [disable]="false"
              [label]="'Welcome Message' | translate:language:ModuleNames.SurveyX"
              [(ngModel)]="currentForm.isWelcomeMessage"></z-toggle>
            <p class="input-description mb-5">
              {{"Set a welcome message for the respondent to see as a disclaimer page" |
              translate:language:ModuleNames.SurveyX}}
            </p>
          </div>

          <div class="w-full px-8 flex flex-col items-stretch  pb-8 mb-1" *ngIf="currentForm.isWelcomeMessage">
            <zen-translation-editor id="welcomeMessage" name="welcomeMessage" [additionalToolbarHiddenButtons]="['insertImage']" [placeholder]="'txtTextHere' | translate:language:ModuleNames.SurveyX" [language]="language" [(ngModel)]="currentForm.welcomeMessage"
              [supportedLanguages]="selectedEntity.ContentLanguages"></zen-translation-editor>
            <!-- <z-translation-input-textarea class="pre w-full" [language]="language"
                          [placeholder]="'Please Enter Your Welcome Message' | translate:language:ModuleNames.SurveyX"
                          [supportedLanguages]="supportedLanguages" [(ngModel)]="currentForm.welcomeMessage">

                      </z-translation-input-textarea> -->
          </div>

        </div>

        <div class="w-full">
          <div class="section-header w-full px-8 py-3 mt-3 mb-8">
            <h5 class="section-header-title">
              {{"Email Templates" | translate:language:ModuleNames.SurveyX}}
            </h5>
          </div>

          <div class="mb-8">
            <div class="w-full px-8 pb-8 flex justify-between">
              <p class="template-title">
                {{"Invitation Template" | translate:language:ModuleNames.SurveyX}}
              </p>

              <div class="flex gap-3">
                <!-- <button (click)="onOpenTemplateLibrary(EmailTemplateTypes.Invitation)"
                  class="btn flex items-center gap-3 px-3 py-2 template-button">
                  <i class="fa-solid fa-th"></i>
                  {{"Template Library" | translate:language:ModuleNames.SurveyX}}
                </button> -->
              </div>
            </div>

            <div class="w-full px-8 pb-8">
              <zen-x-editor *ngIf="invitationTemplate" (reset)="resetInvitationTemplate($event)" [language]="language"
                [emailObject]="invitation">


                <ng-container footerOptions>
                  <div class="flex flex-row gap-4">
<!--
                    <div (click)="saveEmailTemplate(invitation,  EmailTemplateTypes.Invitation)" class="btn flex flex-col items-start gap-2">
                      <div class="flex h-10 items-center gap-2 rounded py-2 px-4">
                        <div class="flex h-4 items-center gap-3">
                          <a class="text-zen-primarylight text-sm font-medium btn">{{"Save as Template" |
                            translate:language:ModuleNames.SurveyX}}</a>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div (click)="saveChange()" class="btn exp flex flex-col items-start gap-2 rounded">
                      <div class="flex h-10 py-2 px-4 items-center gap-2 ">
                        <div class="flex h-4 items-center gap-3">
                          <a class="text-sm font-medium">{{"Save Changes"| translate:language:ModuleNames.SurveyX}}</a>
                        </div>
                      </div>
                    </div> -->

                  </div>
                </ng-container>
              </zen-x-editor>
            </div>
          </div>

          <div>
            <div class="w-full px-8 pb-8 flex justify-between">
              <p class="template-title">
                {{"Reminder Template" | translate:language:ModuleNames.SurveyX}}
              </p>

              <div class="flex gap-3">
                <!-- <button (click)="onOpenTemplateLibrary(EmailTemplateTypes.Reminder)"
                  class="btn flex items-center gap-3 px-3 py-2 template-button">
                  <i class="fa-solid fa-th"></i>
                  {{"Template Library" | translate:language:ModuleNames.SurveyX}}
                </button> -->
              </div>
            </div>

            <div class="w-full px-8 pb-8">
              <zen-x-editor *ngIf="reminderTemplate" (reset)="resetReminderTemplate($event)" [language]="language"
                [emailObject]="reminder">
                <ng-container footerOptions>
                  <div class="flex flex-row gap-4">

                    <!-- <div (click)="saveEmailTemplate(reminder, EmailTemplateTypes.Reminder)" class="btn flex flex-col items-start gap-2">
                      <div class="flex h-10 items-center gap-2 rounded py-2 px-4">
                        <div class="flex h-4 items-center gap-3">
                          <a class="text-zen-primarylight text-sm font-medium">{{"Save as Template"|
                            translate:language:ModuleNames.SurveyX}}</a>
                        </div>
                      </div>
                    </div> -->

                    <!-- <div (click)="saveChange()" class="btn exp flex flex-col items-start gap-2 rounded">
                      <div class="flex h-10 py-2 px-4 items-center gap-2 ">
                        <div class="flex h-4 items-center gap-3">
                          <a class="text-sm font-medium">{{"Save Changes" | translate:language:ModuleNames.SurveyX}}</a>
                        </div>
                      </div>
                    </div> -->

                  </div>
                </ng-container></zen-x-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<app-share-conformation-modal *ngIf="isShareModalOpen" [(open)]="isShareModalOpen" (shareSurvey)="onShareSurvey($event)"
  [language]="language"></app-share-conformation-modal>

<app-share-success-modal *ngIf="isSuccessModalOpen" [(open)]="isSuccessModalOpen"
  [(surveyId)]="surveyId"></app-share-success-modal>

<app-template-library-modal [(templateType)]="templateType" [language]="language" *ngIf="isTemplateLibraryModalOpen"
  [(open)]="isTemplateLibraryModalOpen" (template)="onTemplateSelect($event)" [(tenantId)]="tenantKey" [companyId]="companyKey"
  ></app-template-library-modal>


<app-survey-preview-export *ngIf="showPrint" [(open)]="showPrint" [survey]="currentForm"
  [essentialObject]="essentialObject"></app-survey-preview-export>
