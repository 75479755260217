<div class="flex flex-col gap-2 w-full relative minw-44" *ngIf="ngControl.control">
  <h5 *ngIf="label" class="text-xs text-zen font-medium truncate whitespace-nowrap">{{ label }} <i
      *ngIf="ngControl.control.required" class="fa-regular fa-asterisk text-3xs transform -translate-y-1"></i></h5>
  <div [ngClass]="ngControl.control.errors && ngControl.touched?'border-zen-red text-zen-red':'border-zen border-opacity-25 text-zen'"
    class="text-sm rounded  border  px-3 h-9 gap-3 leading-9 truncate flex flex-row justify-between items-center">
    <input class="w-full bg-transparent" type="{{type}}" [formControl]="ngControl.control">
  </div>

</div>

<!-- <div class="text-xs text-red-600" *ngIf="ngControl.touched">
  <h5 *ngIf="ngControl.control.errors?.required">{{label}} is required</h5>
  <h5 *ngIf="ngControl.control.errors?.minlength">{{label}} must be at least
    {{ngControl.control.errors?.minlength['requiredLength']}}</h5>
  <h5 *ngIf="ngControl.control.errors?.maxlength">{{label}} must be at most
    {{ngControl.control.errors?.maxlength['requiredLength']}}</h5>

  <h5 *ngIf="ngControl.control.errors?.matchingError">Passwords do not match</h5>
</div> -->

<div class="flex flex-row justify-between items-start" *ngIf="ngControl.touched">
  <div class="flex flex-col">
    <validation-required [language]="language" *ngIf="ngControl.control.errors?.required"></validation-required>
    <validation-email [language]="language" *ngIf="ngControl.control.errors?.email"></validation-email>
  </div>
  <div *ngIf="ngControl.control.value?.length > ngControl.control.errors?.maxlength?.requiredLength -5 && ngControl.control.errors?.maxlength?.requiredLength"
    class="text-red-600 text-xxs flex flex-row items-center gap-1 leading-5">
    {{ngControl.control.errors?.maxlength?.requiredLength}}/{{ngControl.control.value?.length}}</div>
</div>

