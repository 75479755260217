import { Component, Directive, EventEmitter, HostListener, Input, OnInit, Optional, Output, Self, ViewContainerRef } from '@angular/core';
import { ZenTranslationInputComponent } from '../Theme/controls/zen-translation-input/zen-translation-input.component';
import { ZenInputComponent } from '../Theme/controls/zen-input/zen-input.component';
import { NgControl } from '@angular/forms';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { AiTranslationService } from 'src/app/core/services/ai-translation.service';
import { Subject, Subscription, of } from 'rxjs';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ZenSnackbarService } from '../Theme/services/zen-snackbar.service';
import { TranslationsService } from 'src/app/core/services/translations.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { ExtractPipe } from '../pipes/extract.pipe';

@Directive({
  selector: '[AiTextValidation]'
})
export class AiTextValidationDirective {
  @Output() AiTextValidationResult = this.ngControl.control.valueChanges.pipe(debounceTime(1000), switchMap((x) => {
    return this.aiTranslation.validateText(this.host.selected.name, this.host.language);
  }))
  constructor(@Optional() @Self() public ngControl: NgControl, private host: ZenTranslationInputComponent, private aiTranslation: AiTranslationService) { }
}


interface GenerationParameters {
  component: any,
  section: any;
  module: string;
  addFunction: string;
  maxRecords?: number;
  property?: any;
  statement?: any;
}

@Directive({
  selector: '[AiStatementGeneration]'
})
export class AiStatementGenerationDirective implements OnInit {
  subscription = new Subscription;
  private requestStatements: Subject<GenerationParameters> = new Subject();
  @Input('AiStatementGeneration') params: GenerationParameters;
  @HostListener('click', ['$event']) onClick($event) {
    console.log(this.params);

    this.requestStatements.next(this.params);
  }

  constructor(private aiTranslation: AiTranslationService, private loader: LoaderService, private zenSnackbar: ZenSnackbarService, private translatePipe: TranslatePipe, private extract: ExtractPipe) { }
  ngOnInit(): void {
    this.subscription.add(this.requestStatements.pipe(filter(x => x.section.name), switchMap((params: GenerationParameters) => {
      this.loader.displayLoader();
      let records = params.maxRecords;
      if (params.section && params.section[params.property]?.length < params.maxRecords)
        records = params.maxRecords - params.section[params.property].length;
      const sourceText = this.extract.transform(params.section.name, params.component.language);
      return this.aiTranslation.generateText(params.module, sourceText, params.component.language, params.component.selectedEntity.ContentLanguages, records).pipe(map(result => ({ result, params })));
    })).subscribe(({ result, params }) => {
      if (!result || !result?.length)
        this.zenSnackbar.error(this.translatePipe.transform('txtInvalidAiText', params.component.language, params.component.ModuleNames.Shared));
      else if (params.statement)
        params.statement.name = result[0];
      else if (params?.property && params.section[params.property].length == result.length) {
        for (let index = 0; index < result?.length; index++) {
          params.section[params.property][index].name = result[index];
        }
      }
      else
        for (let index = 0; index < result?.length; index++) {
          params.component[params.addFunction](result[index]);
        }
      this.loader.hideLoader();
    }))
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }


}
