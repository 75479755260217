<div class="flex flex-col h-screen">
    <div class="flex flex-row w-full shrink-0 startup-header sh-shadow px-8 h-16">
        <div class="flex-1">&nbsp;</div>
        <div class="flex-1 flex items-center justify-center">
            <img class="h-12" [src]=logoImage />
        </div>
        <div class="flex-1 flex items-center justify-end gap-4">

        </div>
    </div>
    <div *ngIf=" sub && sub.statusType == 2" class="flex flex-col flex-grow justify-between">
        <div [dir]="direction" class="flex flex-col justify-center pt-10 w-full sm:w-[480px] mx-auto px-8 sm:px-12">
            <div class="flex flex-col flex-wrap justify-center items-center w-full m-auto mb-8">
                <h5 [@first] class="text-zen font-medium text-2xl">{{"Please select the language"|
                    translate:language:ModuleNames.SurveyX}}</h5>
            </div>

            <div class="flex flex-col m-auto justify-center bg-white gap-6 rounded-md p-8 pt-8 w-full box-border shadow-024 items-center">
                <div class="flex w-full justify-center items-center text-center">
                    <div class="flex flex-col w-full">
                        <z-select [color]="'exp'" class="placeholder:fixed" [options]="localesList"
                            [language]="language" [(ngModel)]="selectedLocaleFilter"
                            [placeholder]="'Language'| translate:language:ModuleNames.SurveyX"
                            (ngModelChange)="onLocaleFilterChanged($event)">
                            <ng-template #valueTemplate let-value>
                                {{value?.name }}
                            </ng-template>
                            <ng-template #optionTemplate let-option>
                                {{option?.name }}
                            </ng-template>
                        </z-select>
                    </div>
                </div>
                <div class="flex w-full items-center justify-center">
                    <button
                        class="flex bg-exp hover:bg-exp text-white justify-center text-md font-medium w-full py-3 px-10 rounded"
                        (click)="onContinue()">
                        {{"Continue"| translate:language:ModuleNames.SurveyX}}
                        <!-- <i class="fa-regular fa-chevron-right w-4 pt-3px fill-current" fill="currentColor"></i> -->
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
