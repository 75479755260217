import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ExitReportDriverLabelService {
  readonly ExitFactorLabelByScore: any[] = [
    // ================== General Questions ===================
    {
      driverKey: '-N-Ea_N39qr1numSyVq6',
      driverName: 'GeneralQuestions',
      driverDesc: 'txtGeneralQuestionsFactorDesc',
      driverRecommendationDesc: 'txtGeneralQuestionsRecommendationDesc',
      driverRecommendations: [
        {
          sequence: 1,
          description: 'txtGeneralQuestionsRecommendation_1'
        },
        {
          sequence: 2,
          description: 'txtGeneralQuestionsRecommendation_2'
        },
        {
          sequence: 3,
          description: 'txtGeneralQuestionsRecommendation_3'
        },
        {
          sequence: 4,
          description: 'txtGeneralQuestionsRecommendation_4'
        },
      ]
    },
    // ===================== Position =======================
    {
      driverKey: '-N-ElEz8uoPhKQ-roU3u',
      driverName: 'Position',
      driverDesc: 'txtPositionFactorDesc',
      driverRecommendationDesc: 'txtPositionRecommendationDesc',
      driverRecommendations: [
        {
          sequence: 1,
          description: 'txtPositionRecommendation_1'
        },
        {
          sequence: 2,
          description: 'txtPositionRecommendation_2'
        },
        {
          sequence: 3,
          description: 'txtPositionRecommendation_3'
        },
        {
          sequence: 4,
          description: 'txtPositionRecommendation_4'
        },
      ]
    },
    // ===================== Rules & Policies =======================
    {
      driverKey: '-N-EmNrdQ0hKMohfGQrL',
      driverName: 'Rules&Policies',
      driverDesc: 'txtRulesPoliciesFactorDesc',
      driverRecommendationDesc: 'txtRulesPoliciesRecommendationDesc',
      driverRecommendations: [
        {
          sequence: 1,
          description: 'txtRulesPoliciesRecommendation_1'
        },
        {
          sequence: 2,
          description: 'txtRulesPoliciesRecommendation_2'
        },
        {
          sequence: 3,
          description: 'txtRulesPoliciesRecommendation_3'
        },
        {
          sequence: 4,
          description: 'txtRulesPoliciesRecommendation_4'
        },
      ]
    },
    // ===================== Compensation & Benefits =======================
    {
      driverKey: '-N-En8wm8HX9cyIOBncl',
      driverName: 'Compensation&Benefits',
      driverDesc: 'txtCompensationBenefitsFactorDesc',
      driverRecommendationDesc: 'txtCompensationBenefitsRecommendationDesc',
      driverRecommendations: [
        {
          sequence: 1,
          description: 'txtCompensationBenefitsRecommendation_1'
        },
        {
          sequence: 2,
          description: 'txtCompensationBenefitsRecommendation_2'
        },
        {
          sequence: 3,
          description: 'txtCompensationBenefitsRecommendation_3'
        },
        {
          sequence: 4,
          description: 'txtCompensationBenefitsRecommendation_4'
        },
      ]
    },
    // ===================== Career Advancement =======================
    {
      driverKey: '-N-F6NL4I8AIKWT6bqIF',
      driverName: 'CareerAdvancement',
      driverDesc: 'txtCareerAdvancementFactorDesc',
      driverRecommendationDesc: 'txtCareerAdvancementRecommendationDesc',
      driverRecommendations: [
        {
          sequence: 1,
          description: 'txtCareerAdvancementRecommendation_1'
        },
        {
          sequence: 2,
          description: 'txtCareerAdvancementRecommendation_2'
        },
        {
          sequence: 3,
          description: 'txtCareerAdvancementRecommendation_3'
        },
        {
          sequence: 4,
          description: 'txtCareerAdvancementRecommendation_4'
        },
      ]
    },
    // ===================== Communication =======================
    {
      driverKey: '-N-F6v1mH3Y2yKqxnn6r',
      driverName: 'Communication',
      driverDesc: 'txtCommunicationFactorDesc',
      driverRecommendationDesc: 'txtCommunicationRecommendationDesc',
      driverRecommendations: [
        {
          sequence: 1,
          description: 'txtCommunicationRecommendation_1'
        },
        {
          sequence: 2,
          description: 'txtCommunicationRecommendation_2'
        },
        {
          sequence: 3,
          description: 'txtCommunicationRecommendation_3'
        },
        {
          sequence: 4,
          description: 'txtCommunicationRecommendation_4'
        },
      ]
    },
    // ===================== Management Style =======================
    {
      driverKey: '-N-F7uUHNpvfTTpfuFuu',
      driverName: 'ManagementStyle',
      driverDesc: 'txtManagementStyleFactorDesc',
      driverRecommendationDesc: 'txtManagementStyleRecommendationDesc',
      driverRecommendations: [
        {
          sequence: 1,
          description: 'txtManagementStyleRecommendation_1'
        },
        {
          sequence: 2,
          description: 'txtManagementStyleRecommendation_2'
        },
        {
          sequence: 3,
          description: 'txtManagementStyleRecommendation_3'
        },
        {
          sequence: 4,
          description: 'txtManagementStyleRecommendation_4'
        },
      ]
    },
    // ===================== Working Conditions =======================
    {
      driverKey: '-N-F8ZtEgw-NlJV_6seY',
      driverName: 'WorkingConditions',
      driverDesc: 'txtWorkingConditionsFactorDesc',
      driverRecommendationDesc: 'txtWorkingConditionsRecommendationDesc',
      driverRecommendations: [
        {
          sequence: 1,
          description: 'txtWorkingConditionsRecommendation_1'
        },
        {
          sequence: 2,
          description: 'txtWorkingConditionsRecommendation_2'
        },
        {
          sequence: 3,
          description: 'txtWorkingConditionsRecommendation_3'
        },
        {
          sequence: 4,
          description: 'txtWorkingConditionsRecommendation_4'
        },
        {
          sequence: 5,
          description: 'txtWorkingConditionsRecommendation_5'
        },
      ]
    },
  ];
}
