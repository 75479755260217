import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { Model, surveyLocalization } from 'survey-core';

@Component({
  selector: 'app-survey-actionsbar',
  templateUrl: './survey-actionsbar.component.html',
  styleUrls: ['./survey-actionsbar.component.scss']
})
export class SurveyActionsbarComponent implements OnInit, OnChanges {

  @Input() language = 'EN';
  ModuleNames = ModuleName;
  surveyjsLocalization = surveyLocalization.locales["en"];

  @Input() surveyModel: Model;
  @Output() showConfirmSubmissionModal = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  prevPage() {
    // console.log(this.surveyModel.currentPageNo);
    // console.log(this.surveyModel.prevPage());
    const currentPageNo = this.surveyModel.currentPageNo;
    while (currentPageNo == this.surveyModel.currentPageNo)
      this.surveyModel.prevPage()

    // console.log(this.surveyModel.currentPageNo);

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['language']) {
      console.log('language changed to: ', this.language);
      this.surveyjsLocalization = surveyLocalization.locales[this.language.toLowerCase()];
    }
  }
}
