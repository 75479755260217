<section class="hero is-fullheight">
    <div class="hero-body py-0">
        
      <div class="flex flex-row is-vcentered">
        <div class="column">
            <h1 class="megaSize uppercase">{{'txtError'|translate:language:ModuleNames.Shared}}</h1>
            <h1 class="is-size-5 uppercase c-80 px-3">{{'txtErrorText'|translate:language:ModuleNames.Shared}}</h1>
            <a (click)="endSession()" class="btn orange-grd mt-5 mx-3 uppercase px-6">{{'txtHopon'|translate:language:ModuleNames.Shared}}</a>
        </div>
        <div class="column">
            <figure class="image  ">
                <img src="/assets/img/svg/Illustration.svg">
              </figure>
        </div>
      </div>
    </div>
  </section>