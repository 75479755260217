import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { ModalComponent } from '@ex/module/shared/modal/modal.component';
import { localization } from 'survey-creator-core';
import { surveyLocalization } from 'survey-core';





@Component({
  selector: 'app-add-locale-modal',
  templateUrl: './add-locale-modal.component.html',
  styleUrls: ['./add-locale-modal.component.scss']
})
export class AddLocaleModalComponent extends ModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Input() selectedLocales: { id: string, title: string, isDone: boolean, isDefault: boolean }[] = [];

  @Output() openChange = new EventEmitter();
  @Output() selectedLocalesChange = new EventEmitter<{ id: string, title: string }[]>();

  avilableLocales: { id: string, title: string, selected: boolean }[] = [];

  searchText = "";

  @Input() language: string = "EN";
  ModuleNames = ModuleName;

  constructor(private cd: ChangeDetectorRef, private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    let locales = surveyLocalization.getLocales();

    for (let index = 0; index < locales.length; index++) {
      this.avilableLocales.push({
        selected: this.isChecked(locales[index]),
        id: locales[index] || 'en',
        title: localization.getLocaleName(locales[index])
      })
    }

  }


  onSubmit() {
    let langs = this.avilableLocales.filter(x => x.selected).map(x => {
      return {
        id: x.id,
        title: x.title
      }
    })

    this.selectedLocalesChange.emit(langs);

    this.close();
  }

  close() {
    this.openChange.emit(false);
    this.cd.detectChanges();
  }

  isChecked(id: any) {
    return !!this.selectedLocales.find((x) => x.id == id);
  }

}
