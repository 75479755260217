<div class="flex flex-row justify-between items-center relative gap-4">
  <div [ngClass]="{'z-20 opacity-100':fileOver && mode == 'upload'}"
    class="flex flex-col z-0 opacity-0 items-center justify-center absolute w-full h-full bg-white bg-opacity-90 transition-all">
    <i class="fa-regular fa-box-open-full text-4xl text-zen"></i>
  </div>
  <div class="flex flex-row items-center gap-2.5 w-full">
    <i class="fa-light fa-file-spreadsheet text-4xl text-zen"></i>
    <div *ngIf="!uploadStarted">
      <h5 class="text-xs text-zen-lite font-normal" *ngIf="mode == 'download'">{{ 'txt_Downloadthepremade' |
        translate:language:ModuleName.Shared }}</h5>
      <h5 class="text-xs text-zen-lite font-normal" *ngIf="mode == 'upload'">{{ 'txt_uploadthepremade' |
        translate:language:ModuleName.Shared }}</h5>
      <h5 class="text-xs text-zen-lite font-normal"><span class="font-medium ">({{newFile?newFile:fileName}})</span>
      </h5>
    </div>
    <div class="flex flex-col items-stretch gap-1 w-full" *ngIf="uploadStarted">
      <div class="flex flex-row justify-between">
        <h5 class="text-xs text-zen-lite font-normal truncate w-1/3">{{ (uploadEnded?'txtUploaded':'txt_Uploading') |
          translate:language:ModuleName.Shared }} ({{newFile?newFile:fileName}})...</h5>
        <h5 class="text-xs text-zen-lite font-semibold w-1/3 text-center transition-all">{{percentage}}%</h5>
        <h5 class="text-xs text-zen-lite font-semibold w-1/3 text-end text-opacity-50">{{timeLeft}} {{
          'txtLeftSeconds' | translate:language:ModuleName.Shared }}</h5>
      </div>
      <div [ngClass]="uploadEnded?'bg-zen-green':'bg-exp'" class="h-2 rounded-r rounded-l transition-all ease-expo"
        [style.width.%]="percentage"> </div>
    </div>
  </div>
  <a *ngIf="!uploadStarted && !uploadEnded" (click)="mode == 'upload'?input.click() :downloadFile([], 'file')"
    class="btn exp leading-14 rounded px-6 font-medium flex flex-row items-center gap-2.5 w-1/2 whitespace-nowrap">
    <ng-container *ngIf="mode == 'download'">
      <i class="fa-regular fa-download"></i>
      <span>{{ 'txt_TemplateDownload' | translate:language:ModuleName.Shared }}</span>
    </ng-container>
    <ng-container *ngIf="mode == 'upload'">
      <i class="fa-regular fa-upload"></i>
      <span>{{ 'txt_TemplateUpload' | translate:language:ModuleName.Shared }}</span>
    </ng-container>
  </a>
  <a *ngIf="uploadStarted && !uploadEnded" (click)="cancelRequest()"
    class="btn delete leading-7 text-sm px-2.5 font-medium rounded-sm">{{ 'txtAbort' |
    translate:language:ModuleName.Shared }}</a>
  <div *ngIf="uploadEnded" class="flex flex-row items-center gap-2 justify-end">
    <i  class="fa-regular fa-check-circle text-zen-green"></i>
    <a zenButton (click)="input.click()" styles="height-8 secondary" class="whitespace-nowrap">{{ "txtReupload" | translate:language:ModuleNames.Shared }}</a>
  </div>

</div>
<input id="fileInput" #input class="hidden" type="file" class="hidden z-0" [accept]="accept"
  (change)="onFileSelect($event.target.files[0], $event)">
