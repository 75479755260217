<div class="flex flex-row items-center justify-between gap-5">
    <div class="flex flex-col gap-2 relative minw-44 w-1/2" *ngIf="list.length || single">
        <h5 class="text-xs text-zen font-medium">{{ 'txt_send_to'| translate:language:ModuleNames.Shared }}</h5>
        <div class="text-sm rounded text-zen border bg-white border-zen border-opacity-25 px-3 h-9 gap-1  truncate flex flex-row justify-start items-center">
            <ng-container *ngIf="list.length">
                <span class="bg-exp bg-opacity-10 rounded-r-full rounded-l-full py-1 px-2" *ngFor="let item of list | slice:0:4; let i=index">{{item.name}} </span>
                <div [@child4] *ngIf="list.length > 4"> + {{list.length - 4}}</div>
            </ng-container>
            <ng-container *ngIf="single">
                <span class="bg-exp bg-opacity-10 rounded-r-full rounded-l-full py-1 px-2">{{single.name}} </span>
            </ng-container>

        </div>
    </div>
    <div class="flex flex-col w-full items-stretch">
        <z-input [maxCount]="mdCount" [required]="true" [language]="language" [label]="'txt_subject_line'| translate:language:ModuleNames.Shared" [(ngModel)]="emailObject.subject">
        </z-input>
    </div>

</div>

<div class="mt-5 w-full">
    <kendo-editor [iframe]='false' [maxlength]='hugeCount' [(ngModel)]="emailObject.body">
    </kendo-editor>

</div>
<div class="flex flex-row justify-between items-center ">
    <a class="btn text-zen-primarylight break-normal leading-10 text-sm font-medium w-80 pt-4 " (click)="reset.emit()">
      {{'Reset Template'| translate:language:ModuleNames.SurveyX}} </a>

      <ng-content select="[footerOptions]"></ng-content>

    </div>
