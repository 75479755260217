import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/domain/base-component';
import wellbeingClosedSurveyAnimation from '../../utils/closed-survey-animations';
import getSidebarNavigationTabs, {
  SidebarNavigationModel,
} from './sidebar-tabs';
import { SurveyDto, SurveysService } from '@ex/wellbeing/surveys';
import { AnalysisComponentModel } from '../../utils/analysis-component-model';
import {
  AnalyticsFilter,
  AnalyticsService,
} from '../../data-access/services/analytics.service';
import { ExtractPipe } from 'src/app/shared/pipes/extract.pipe';
import { Hierarchy } from 'src/app/core/data-models/hierarchy';
import {
  EntityCount,
  EntityTreeService,
} from 'src/app/core/services/entity-tree.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import {
  CompanyPermission,
  products,
} from 'src/app/core/data-models/company-permission';
import { Entity } from 'src/app/core/data-models/Entity';
import { HierarchyEntity } from 'src/app/core/data-models/hierarcy-entity';
import { WbTreeOptionsComponent } from '../../ui/wb-tree-options/wb-tree-options.component';
import {
  WbAnalysisReportComponent,
  WbReportExportData,
} from '../wb-analysis-report/wb-analysis-report.component';
import { CriteriaResolverService } from '../../data-access/services/criteria-resolver.service';
import { SurveyCriteriaService } from '../../data-access/services/survey-criteria.service';
import { EntityCountDto, SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { AI_COMMENTS_SERVICE } from 'src/app/shared/ai-comments/components/main/main.component';
import { IAiCommentsApi } from 'src/app/shared/ai-comments/interfaces/ai-comments-api';

@Component({
  selector: 'app-closed-survey',
  templateUrl: './closed-survey.component.html',
  styleUrls: ['./closed-survey.component.scss'],
  animations: wellbeingClosedSurveyAnimation,
})
export class ClosedSurveyComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  filters: AnalyticsFilter;

  survey: SurveyDto;
  surveyKey: string;

  language: string = 'EN';

  viewByOption = false;

  showExtraDimensions = false;

  settingsLoaded = new Subject();
  settingsLoaded$ = this.settingsLoaded.asObservable();

  sideBarNavigationTabs = getSidebarNavigationTabs(this.language);

  topValue = 0;
  topValue1 = 0;

  selectedEntity: any;
  selectedChildEntity: Hierarchy;
  tselectedChildEntity: Hierarchy;

  hasCompanyPermission = false;
  allEntities;
  entityCounts: any[] = [];
  treeList;
  treeOptions: boolean = false;
  permissionList = [];
  companyKey: string;
  tenantKey: string;

  @ViewChild('tabContentHolder', { read: ViewContainerRef })
  tabContent: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveysService,
    private analyticsService: AnalyticsService,
    private extractPipe: ExtractPipe,
    @Inject(AI_COMMENTS_SERVICE) public commentsAiService: IAiCommentsApi,
    private entityTreeService: EntityTreeService,
    private permissionService: PermissionsService,
    private criteriaResolverService: CriteriaResolverService,
    private surveyCriteriaService: SurveyCriteriaService,
    private surveyAnalysisService: SurveyAnalysisService
  ) {
    super();
  }
  ngAfterViewInit(): void { }

  ngOnInit(): void {
    this.GlobalService.innerRoute = true;
    this.topValue = document.querySelector('.h-header')?.clientHeight / 16;

    this.surveyKey = this.route.snapshot.paramMap.get('id');

    this.fetchSurvey();

    this.EssentialObject$.pipe(
      filter((obj) => obj?.selectedEntity?.key != null)
    ).subscribe((obj) => {
      if (this.language != obj.language) {
        this.sideBarNavigationTabs = getSidebarNavigationTabs(obj.language);
      }

      this.language = obj.language;
      this.tenantKey = obj.selectedEntity.tenantKey;
      this.companyKey = obj.selectedEntity.key;
      this.selectedEntity = obj.selectedEntity;

      this.hasCompanyPermission =
        this.permissionService.isSuperAdmin() ||
        this.permissionService.hasPermission(
          this.companyKey,
          products.Wellbeing
        );

      this.permissionList = this.permissionService.isSuperAdmin()
        ? []
        : obj.user?.ModulePermissions?.filter((x) =>
          x.products.includes(products.Wellbeing)
        );

      this.filters = {
        companyKey: obj.selectedEntity.key,
        tenantKey: obj.selectedEntity.tenantKey,
        entityId: [],
        showExtraFactors: this.showExtraDimensions,
        survey: {
          key: this.surveyKey,
          name: '',
          surveyCriterias: [],
          dimensions: this.survey?.survey?.sections
        },
        language:this.language
      }
      this.settingsLoaded.next(true);
    });
  }

  fetchSurvey() {
    this.addSubscription(
      this.settingsLoaded$
        .pipe(
          switchMap((_) => {
            return this.surveyService.get(this.surveyKey);
          }),
          switchMap((survey) => {
            return this.surveyAnalysisService
              .getRespondentEntityCounts({
                companyKey: this.filters.companyKey,
                surveyKey: this.surveyKey,
                tenantKey: this.filters.tenantKey,
                filters: [],
              })
              .pipe(map((counts) => ({ counts, survey })));
          })
        )
        .subscribe(({ survey, counts }) => {
          this.survey = survey;
          this.entityCounts = counts;
          this.allEntities = this.survey.entityHierarchy
            .map((x) => x.entities)
            .reduce((p, c) => p.concat(...c), []);

          this.treeList = this.entityTreeService.get(
            this.allEntities as Entity[],
            this.selectedEntity,
            counts as EntityCount[]
          );

          if (this.permissionList && this.treeList) {
            this.populateEntityHierarchyWithPermissions(
              this.treeList,
              this.permissionList
            );
          }

          this.assignEntityIdsFilter();

          this.filters.survey = {
            key: this.surveyKey,
            surveyCriterias: this.surveyCriteriaService.process(
              survey.selectedCriterias.map((x) => ({
                key: x.key,
                name: x.name,
                options: x.options,
                isCustom: x.isCustom,
                isEntity: x.isEntity,
              }))
            ),
            name: this.extractPipe.transform(this.survey.title, this.language),
            dimensions: this.survey?.survey?.sections,
          };

          // Initial Assignments - Can be overridden per component
          this.criteriaResolverService.setSurveyCriterias(
            this.filters.survey.surveyCriterias
          );

          // Set Intial Filters
          this.analyticsService.setFilters(this.filters, false);

          this.loadTabComponent(this.activeTab);
        })
    );
  }

  openTree() {
    this.ZenCDKService.openComponent(WbTreeOptionsComponent, {
      componentData: {
        tselectedChildEntity: this.selectedChildEntity,
        treeList: this.treeList,
      },
      language: this.language,
    }).subscribe((tselectedChildEntity: Hierarchy) => {
      this.selectedChildEntity = tselectedChildEntity;
      this.assignEntityIdsFilter();
      this.analyticsService.setFilters(this.filters, false);
    });
  }

  getChildren(hierarchy: Hierarchy) {
    let children = this.allEntities.filter(
      (e) => e.parentKey == hierarchy.entity.key
    );
    let hierarchyChildren: Hierarchy[] = [];
    for (let i = 0; i < children.length; i++) {
      let child = JSON.parse(JSON.stringify(children[i]));
      let h = new Hierarchy();
      h.entity = new HierarchyEntity();
      h.entity.key = child.key;
      h.entity.parentKey = child.parentKey;
      h.entity.name = child.name;
      h.entity.count =
        this.entityCounts.find((x) => x.entityKey == child.key)?.totalCount ||
        0;
      h.entity.groupKey = child.entityGroupKey;
      h.children = this.getChildren(h);
      hierarchyChildren.push(h);

      hierarchy.entity.count += h.entity.count;
    }
    return hierarchyChildren;
  }

  tabClick(tab) {
    this.sideBarNavigationTabs.forEach((x) => (x.selected = false));
    tab.selected = true;

    this.loadTabComponent(tab);
  }

  openViewByModal() {
    this.viewByOption = true;
  }

  export() {
    if (this.activeTab?.id == 4) {
      this.downloadComments();
      return;
    }

    this.ZenCDKService.openComponent(WbAnalysisReportComponent, {
      langauge: this.language,
      selectedEntity: this.selectedEntity,
      currentViewdEntity: this.selectedChildEntity.entity,
      filters: this.filters,
    } as WbReportExportData);
  }

  downloadComments() {
    this.commentsAiService.downloadComments();
  }

  toggleHasExtraFactors() {
    this.filters.showExtraFactors = this.filters.showExtraFactors;
    this.analyticsService.setFilters(this.filters, false);
  }

  loadTabComponent(tab: SidebarNavigationModel) {
    if (!this.tabContent) return;

    this.tabContent.clear();
    const component = this.tabContent.createComponent<any>(tab.component);

    component.instance.data = {
      companyKey: this.filters.companyKey,
      tenantKey: this.filters.tenantKey,
      language: this.language,
      surveyKey: this.surveyKey,
      selectedEntity: this.selectedEntity,
    } as AnalysisComponentModel;

    this.tabContent.insert(component.hostView);
    // this.export();
  }

  get hasExtraDimensions() {
    if (this.survey) {
      const index = this.survey?.survey?.sections.findIndex(
        (x) => x.isPredefined == false
      );

      return index > 0 ? true : false;
    }

    return false;
  }

  get activeTab() {
    return this.sideBarNavigationTabs.find((x) => x.selected == true);
  }
  get activeTabIndex() {

    return this.sideBarNavigationTabs.findIndex((x) => x.selected == true);
  }

  getChildKeys(root, keys = []) {
    keys.push(root.entity.key);

    if (root.children.length) {
      root.children.forEach((x) => {
        keys = this.getChildKeys(x, keys);
      });
    }

    return keys;
  }

  private assignEntityIdsFilter() {
    if (
      this.companyKey == this.selectedChildEntity.entity.key &&
      this.hasCompanyPermission
    ) {
      this.filters.entityId = [];
    } else {
      this.filters.entityId = this.getChildKeys(this.selectedChildEntity, []);
    }

    this.analyticsService.setFilters(this.filters);
  }

  populateEntityHierarchyWithPermissions(
    treeList: Hierarchy[],
    permissionList: CompanyPermission[]
  ) {
    for (var treeEntity of treeList) {
      treeEntity['hasPermission'] = this.permissionService.isSuperAdmin()
        ? true
        : permissionList.find((x) => x.key == treeEntity.entity.key)
          ? true
          : false;

      if (
        this.selectedChildEntity == null &&
        treeEntity['hasPermission'] == true
      ) {
        this.selectedChildEntity = treeEntity;
      }

      if (treeEntity.children.length) {
        this.populateEntityHierarchyWithPermissions(
          treeEntity.children,
          permissionList
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.GlobalService.innerRoute = false;
  }
}
