import { questionsBuilderTranslateText, updateQuestionHeaderHTML } from "@ex/module/survey-x/features/builder/questions-builder-page/questions-builder-page.component";

/**
 * Updates the HTML for a file upload question.
 * @param options - The options for updating the file upload question HTML.
 */
export function updateFileUploadQuestionHTML(options: any) {
  // Update the question header
  updateQuestionHeaderHTML(options, "File Upload", "fa-upload");

  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  body.className = body.className + " mt-5 pb-10";

  // Remove the default question content
  body.innerHTML = "";

  // Create a new file upload container with text "Drag & Drop or Browse"
  var newFileUploadContainer = document.createElement("div");
  newFileUploadContainer.className = "w-full block mt-5 p-5 text-center file-upload-container";

  var fileUploadText = document.createElement("p");
  fileUploadText.className = "file-upload-container-text py-4";
  fileUploadText.innerHTML = questionsBuilderTranslateText("txtDrag & Drop or") + " <span>" + questionsBuilderTranslateText("txtBrowse1") + "</span>";

  newFileUploadContainer.appendChild(fileUploadText);

  // Add "Accepted file types: jpg, png, pdf, xps" at the bottom right under the file upload container
  var fileUploadAcceptedTypes = document.createElement("p");
  fileUploadAcceptedTypes.className = "file-upload-container-accepted-types w-full block text-right mt-1";
  fileUploadAcceptedTypes.innerHTML = questionsBuilderTranslateText("txtAccepted file types: ") + "jpg, png, pdf, xps";

  body.appendChild(newFileUploadContainer);
  body.appendChild(fileUploadAcceptedTypes);
}
