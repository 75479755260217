import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity } from 'src/app/core/data-models/Entity';
import { NewEntityColumn } from '../models/clients/new-entity-column';
import { NewThomasClient, ThomasCompany } from '../models/clients/new-thomas-client';
import { NewThomasCompany } from '../models/clients/new-thomas-company';
import { ThomasTenantDetails } from '../models/clients/thomasTenant-details';
import { PsychometricConfig } from '../PsychometricConfig';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  api: string = PsychometricConfig.thomasEndPoint;
  ThomasTenant: ThomasTenantDetails = {
    ClientId: '',
    ClientSecret: ''
  }
  constructor(private http: HttpClient) { }

  setTenantDetails(tenantDetails: ThomasTenantDetails) {
    this.ThomasTenant = tenantDetails;
  }
  getClientId() {
    return this.ThomasTenant.ClientId;
  }
  getSecretKey() {
    return this.ThomasTenant.ClientSecret;
  }
  createThomasIntegrationTables(request: NewThomasClient) {
    return this.http.post(PsychometricConfig.thomasEndPoint + 'clients/new-client', request);
  }
  newThomasCompany(company: Entity, createdBy: string) {
    let newThomasCompany = new NewThomasCompany;
    newThomasCompany.tenantKey = company.tenantKey;
    newThomasCompany.createdBy = createdBy;
    newThomasCompany.company = new ThomasCompany();
    newThomasCompany.company.key = company.key;
    newThomasCompany.company.entitiesKeys = [];
    newThomasCompany.company.metricsKeys = [];
    return this.http.post(PsychometricConfig.thomasEndPoint + 'clients/new-company', newThomasCompany);
  }
  newThomasEntityColumn(request: NewEntityColumn) {
    return this.http.post(PsychometricConfig.thomasEndPoint + 'clients/new-entity-column', request);
  }

  getTenantDetails(tenantKey: string) {
    return this.http.post(this.api + "clients/get-tenant-details?tenantKey=" + tenantKey, {})
  }
}
