import {
  Component,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
  Input,
  AfterContentInit,
  ChangeDetectorRef,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { inOutAnimation, pageAnimation } from '../animations/allAnimations';
import { LanguageService } from '../../core/services/language.service';
import { MatDialog } from '@angular/material/dialog';
import { Entity } from '../../core/data-models/Entity';
import { EngagementService } from '../../core/services/engagement.service';
import { EngagementData } from '../../core/data-models/engagement-data';
import { Engagement } from '../../core/data-models/engagement';
import { GeneralService } from '../../core/services/general.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { EngagementRespondent } from '../../core/data-models/engagement-respondent';
import { Respondent } from '../../core/data-models/respondent';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
declare var $: any;
import * as FileSaver from 'file-saver';
import { EmailNotificationService } from '../../core/services/email-notification.service';

import { GlobalsService } from '../../core/services/globals.service';
import { ZenSnackbarService, ZenSnackBarTypes } from '../Theme/services/zen-snackbar.service';
import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { ModuleName } from '../enums/ModuleName';
import { RespondentService } from 'src/app/core/services/respondent.service';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { TableOptions } from 'src/app/core/data-models/table-options';

@Component({
  selector: 'respondents-modal',
  templateUrl: './respondents-modal.component.html',
  styleUrls: ['./respondents-modal.component.scss'],

})
export class RespondentsModalComponent implements OnInit {
  hugeCount;
  ModuleNames = ModuleName
  lgCount;
  mdCount;
  smCount;
  // translation
  // txtTitle = 'Survey Title';
  txtQAnswer: string;
  txtCriteriaName: string;
  hideInfo: boolean = false;
  companyRespondentsList: Respondent[] = [];
  @Input('surveyInfo') surveyInfo: Engagement;
  constructor(
    private engagementService: EngagementService,
    public globals: GlobalsService,
    private languageService: LanguageService,
    private generalService: GeneralService,
    private db: AngularFireDatabase,
    private emailNotificationService: EmailNotificationService,
    private matConfirmDialog: MatDialog,
    public matDDialog: MatDialog,
    private respondentService: RespondentService,
    private zenSnackBarService: ZenSnackbarService,
    private cdr: ChangeDetectorRef,
    private responseDialogService: ResponseDialogService, private translationPipe: TranslatePipe
  ) {
    this.hugeCount = globals.hugeCount;
    this.lgCount = globals.lgCount;
    this.mdCount = globals.mdCount;
    this.smCount = globals.smCount;
  }
  colors = this.globals.basicColors;
  tab = 0;
  @Input('noTable') noTable = false;
  @Input('essentialObject') essentialObject;
  @Input('key') key: string;
  @Input('survey') survey: Engagement;

  @Input('surveyKey') surveyKey: string;
  @Input('surveyType') surveyType: string;
  @Input('notifyAddition') notifyAddition: boolean;
  @Input('type') addRespondentType;
  @Input('evaluatorTypes') evaluatorTypes;
  @Input('relationshipToCandidate') relationshipToCandidate;
  @Output('relationshipToCandidateChange') relationshipToCandidateChange = new EventEmitter();
  @Input('newRespondent') newRespondent;
  @Output('singleRespondent') singleRespondent = new EventEmitter();
  @Output('multipleRespondents') multipleRespondents = new EventEmitter();
  @Output('downloadCSV') downloadCSV = new EventEmitter();
  @Output('uploadCSV') uploadCSV = new EventEmitter();

  @Input('respondentAdd') respondentAdd;
  @Input('candidateKey') candidateKey;

  @Output('respondentAddChange') respondentAddChange = new EventEmitter();

  @Output() closeDialog = new EventEmitter();
  @Output() uploadRequest = new EventEmitter();
  dataSource: MatTableDataSource<Respondent>;
  displayedColumns2;
  selectedEntityTable;
  language;
  valid: boolean = true;
  searchText = '';
  vRespondentID;
  vRespondentName;
  vRespondentEmail;
  vRelationshipToCandidate;
  respondentsList;
  options: TableOptions;
  @Input('language') set currentLanguage(lang: string) {
    this.language = lang;
  }

  @Input('dataSource') set currentDataSource(data: any) {
    this.respondentsList = data.reverse();
    console.log(this.respondentsList);

    this.newRespondent = new EngagementRespondent();
    this.newRespondent.status = 'PENDING';
    this.newRespondent.actualStatus = 'PENDING';
    this.options = {
      data: this.respondentsList,
      columns: this.displayedColumns2,
      colors: this.colors,
      searchText: this.translationPipe.transform("txt_search", this.language, this.ModuleNames.Shared),
      selection: true,
      selectionKey: ""
    };
  }

  prepareTable(n) {
    this.tab = n;
    console.log(this.displayedColumns2);
    console.log(this.respondentsList);

  }

  updateRelation(relation) {
    this.relationshipToCandidateChange.emit(relation);
    if (this.addRespondentType == 'EVALUATOR')
      this.bulkAddRespondentsRequest.evaluatorType = (this.addRespondentType == 'CANDIDATE' ? null : relation);
  }
  bulkAddRespondentsRequest: any;
  tableSelectionChanged(event, relationshipToCandidate?) {
    this.bulkAddRespondentsRequest = event;
    this.bulkAddRespondentsRequest.SurveyType = this.surveyType;
    this.bulkAddRespondentsRequest.TenantKey = this.essentialObject.tenant.key;
    this.bulkAddRespondentsRequest.UserKey = this.essentialObject.user.key;
    this.bulkAddRespondentsRequest.CompanyKey = this.essentialObject.selectedEntity.key;
    this.bulkAddRespondentsRequest.CompanyName = this.essentialObject.selectedEntity.name;
    this.bulkAddRespondentsRequest.candidateName = null;
    this.bulkAddRespondentsRequest.SurveyKey = this.surveyInfo?.key;
    this.bulkAddRespondentsRequest.surveyInfo = this.surveyInfo;
    this.bulkAddRespondentsRequest.sendEmail = false;
    this.bulkAddRespondentsRequest.candidate = null;
    this.bulkAddRespondentsRequest.isCandidates = (this.addRespondentType == 'CANDIDATE' ? true : false);
    this.bulkAddRespondentsRequest.candidateKey = (this.addRespondentType == 'CANDIDATE' ? null : this.candidateKey);
    this.bulkAddRespondentsRequest.evaluatorType = (this.addRespondentType == 'CANDIDATE' ? null : relationshipToCandidate);
    this.bulkAddRespondentsRequest.respondentType = (this.addRespondentType == 'EVALUATOR' ? "EVALUATOR" : '');
  }

  addSingleRespondent(isDemo?:boolean) {

    if (
      !this.relationshipToCandidate &&
      this.addRespondentType != 'CANDIDATE' &&
      this.addRespondentType
    )
      return this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtEvaluatorTypeRequired", this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error });

    if (this.validateRespondent(this.newRespondent)) {
      if (this.addRespondentType)
        this.newRespondent.respondentType = this.addRespondentType;

      this.singleRespondent.emit(this.newRespondent);
      this.tab = -1;
      this.newRespondent = new EngagementRespondent();
      this.newRespondent.employeeID = '';
      this.newRespondent.name = '';
      this.newRespondent.email = '';
      this.newRespondent.status = 'PENDING';
      this.newRespondent.actualStatus = 'PENDING';
      setTimeout(() => {
        this.tab = 0;
        if(isDemo)
          this.respondentAddChange.emit(false)
      }, 10);

    }
  }
  bulkRespondent() {
    if (
      !this.bulkAddRespondentsRequest.evaluatorType && !this.relationshipToCandidate &&
      this.addRespondentType != 'CANDIDATE' &&
      this.addRespondentType
    ) {
      return this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtEvaluatorTypeRequired", this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error });
    }
    else if (!this.bulkAddRespondentsRequest.evaluatorType && this.relationshipToCandidate)
      this.bulkAddRespondentsRequest.evaluatorType = this.relationshipToCandidate;


    if (this.relationshipToCandidate)
      this.relationshipToCandidateChange.emit(this.relationshipToCandidate);


    this.multipleRespondents.emit(this.bulkAddRespondentsRequest);
  }

  downloadFile() {
    this.downloadCSV.emit(true);
  }
  uploadFile(event) {
    // this.relationshipToCandidateChange.emit(this.relationshipToCandidate);
    // this.uploadCSV.emit(event);

    let files = event.srcElement.files;
    if (this.isValidCSVFile(files[0])) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (this.notifyAddition)
          this.uploadRespondentFileAndCount(reader.result);
        else
          this.uploadRespondentFile(reader.result);
      };
      let input = event.target;

    } else {
      alert("Please import valid .xlsx file.");

    }

  }

  uploadRespondentFile(event) {
    let files = event.srcElement.files;
    if (this.isValidCSVFile(files[0])) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadRequest.emit(reader.result);

      };
      let input = event.target;

    } else {
      alert("Please import valid .xlsx file.");

    }
    // let request = {
    //   FileKey: this.key,
    //   TenantKey: this.essentialObject.tenant.key,
    //   CompanyKey: this.essentialObject.selectedEntity.key,
    //   SurveyKey: this.surveyKey,
    //   UserKey: this.essentialObject.user.key,
    //   SurveyType: this.surveyType,
    //   isCandidate: (this.addRespondentType === 'CANDIDATE' ? true : false),
    //   candidateKey: this.candidateKey,
    //   FileBase: fileBase
    // }
    // this.respondentService
    //   .updloadRespondents(request)
    //   .subscribe((response: any) => {
    //     this.closeDialog.emit();
    //    });
  }

  async uploadRespondentFileAndCount(fileBase) {
    let request = {
      FileKey: this.key,
      TenantKey: this.essentialObject.tenant.key,
      CompanyKey: this.essentialObject.selectedEntity.key,
      SurveyKey: this.surveyKey,
      UserKey: this.essentialObject.user.key,
      SurveyType: this.surveyType,
      FileBase: fileBase,
      sendEmail: this.notifyAddition,
      surveyInfo: this.survey,
      CompanyName: this.essentialObject.selectedEntity.name,
      candidateName: null
    }
    this.respondentService.updloadRespondentsCount(request).subscribe(async (response: any) => {
      let noOfRespondees = response.Data;
      let message = this.translationPipe.transform("txt_unit_deduction", this.language, this.ModuleNames.Shared)
      let subject = this.translationPipe.transform("txt_allocation_confirmation", this.language, this.ModuleNames.Shared)
      let actionButtonText = this.translationPipe.transform("txt_okay_action", this.language, this.ModuleNames.Shared)
      let cancelButtonText = this.translationPipe.transform("btn_cancel", this.language, this.ModuleNames.Shared)
      message = message.replace(
        '#UNITS#',
        (noOfRespondees * 1).toString()
      );

      let confirmationResult =
        await this.responseDialogService.confirmAllocation(
          message,
          subject,
          actionButtonText,
          cancelButtonText
        );
      if (confirmationResult) {
        this.respondentService
          .updloadRespondents(request)
          .subscribe((response: any) => {
            this.closeDialog.emit();
          });
      }
    });

  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".xlsx");
  }

  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  validateRespondent(respondent: Respondent) {
    let isValid: boolean = true;
    let pattern = new RegExp(this.emailPattern);
    if (!respondent.name) {
      this.vRespondentName = true;
      isValid = false;
    } else {
      this.vRespondentName = false;
    }
    if (!respondent.email || !pattern.test(respondent.email)) {
      this.vRespondentEmail = true;
      isValid = false;
    } else {
      this.vRespondentEmail = false;
    }
    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_fill_fields", this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error });
    this.valid = isValid;

    if (
      (!this.validateCount(respondent.employeeID, this.smCount, 'x') &&
        respondent.employeeID) ||
      !this.validateCount(respondent.email, this.mdCount, 'x') ||
      !this.validateCount(respondent.name, this.mdCount, 'x')
    ) {
      return;
    } else {
      return isValid;
    }
  }
  validateCount(value, count, ready?) {
    let validatedCount;
    if (ready == 'x') {
      validatedCount = this.countWord(value, count);
    } else
      validatedCount = this.countWord(this.extractNameLanguage(value), count);
    if (validatedCount > count) {
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txt_wrong_text_count", this.language, this.ModuleNames.Shared), type: ZenSnackBarTypes.Error });

      return false;
    }
    if (validatedCount <= count) {
      return true;
    }
  }
  extractNameLanguage(eG: any) {
    return this.languageService.extractNameLanguage(eG, this.language);
  }
  countWord(word, count) {
    if (word?.length <= count) return word.length;
    if (word?.length > count) {
      return word.length;
    }
  }
  ngOnInit(): void {
    this.newRespondent = new EngagementRespondent();
    this.newRespondent.status = 'PENDING';
    this.newRespondent.actualStatus = 'PENDING';
  }

  selectionChanged(e) {
    this.selectedEntityTable = e;
  }
}
