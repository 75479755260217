import { query } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AnalysisService, EnpsResultDto, ResponsesRateDto } from '@ex/exit/analysis';
import { AnalyticsFilter, AnalyticsService } from '@ex/module/exit/data-access/services/analytics.service';
import { ExitExperienceCodesService } from '@ex/module/exit/data-access/services/exit-experience-codes.service';
import { ExitBaseComponent } from '@ex/module/exit/ui/exit-base/exit-base.component';
import { EnpsTypes } from '@ex/module/exit/utils/enps-types';
import { Subject, combineLatest } from 'rxjs';
import { combineAll, filter, map, switchMap } from 'rxjs/operators';
import { QuestionAnalysis } from 'src/app/core/data-models/question-analysis';
import { CHART_DATA_RESPONSE_STRATEGY } from 'src/app/core/services/chart-data.service';
import { GeneralService } from 'src/app/core/services/general.service';
import { ChartOptionsService } from 'src/app/shared/chart/chart-options.service';
import { QuestionTypes } from 'src/app/shared/questionnaire/questions/question-types';

@Component({
  selector: 'app-overall-analysis',
  templateUrl: './overall-analysis.component.html',
  styleUrls: ['./overall-analysis.component.scss']
})
export class OverallAnalysisComponent extends ExitBaseComponent implements OnInit {

  responseRate = 0;
  totalComments = 0;
  totalCompleted = 0;
  totalRespondents = 0;

  isCompanySelected: boolean = false;

  filtersLoaded = new Subject<AnalyticsFilter>();
  filtersLoaded$ = this.filtersLoaded.asObservable();

  leaverExperienceScore = 0;
  enpsScores: EnpsResultDto[] = [];
  netEnpsScore = 0;
  language;
  EnpsTypes = EnpsTypes;

  factors = [];
  questionAnalysis = []

  constructor(public scales: ExitExperienceCodesService, public generalService: GeneralService, private analysisService: AnalysisService, private analyticsService: AnalyticsService, private chartOptionsService: ChartOptionsService) {
    super()
  }

  ngOnInit(): void {

    this.fetchOverallAnalysis()
    this.fetchEnps();
    this.fetchLeaversExperience();
    this.fetchOverallFactorAnalysis();
    this.fetchQuestionAnalysis();


    const subscription = this.analyticsService.filters$
      .pipe(
        filter(filters => filters != null),
      )
      .subscribe(filters => {
        this.filtersLoaded.next(filters);
      })

    this.addSubscription(subscription);
    this.language = this.AnalysisComponentData.language;
  }
  fetchQuestionAnalysis() {
    this.LoaderService.displayLoader();

    const subscription = this.filtersLoaded$.pipe(
      switchMap(filters => {
        const questions = filters.survey.sections.filter(x => !x.isFactor).flatMap(x => x.questions).filter(q => [QuestionTypes.MultiOptionMultiAnswer as string, QuestionTypes.MultiOptionSingleAnswer as string].includes(q.questionType));

        questions.forEach(q => {
          q['data$'] = this.analysisService.getQuestionAnalysis(q.key, q.questionType, {
            companyKey: filters.companyKey,
            tenantKey: filters.tenantKey,
            surveyKey: filters.survey.key,
            filters: [...this.analyticsService.mapGlobalFilters(filters)],
          })
        })

        return combineLatest([...questions.map(x => x['data$'])]).pipe(
          map(data => ({ data, questions }))
        )

      })
    ).subscribe(({ data, questions }) => {

      this.questionAnalysis = questions.map((x, i) => {

        data[i]['data'].forEach(item => {
          item.category = this.ExtractPipe.transform(x.questionOptions.find(x => x.key == item.category)?.name, this.language)
        })

        data[i]['drillDown'].forEach(item => {
          item.category = this.ExtractPipe.transform(x.questionOptions.find(x => x.key == item.category)?.name, this.language)
        })


        const question = {
          Title: this.replaceCompanyToken(this.ExtractPipe.transform(x.name, this.language), this.AnalysisComponentData.selectedEntity),
          DashletType: 'barH',
          canGroup: false,
          question: {
            canGroup: true,
          },
          DashletChartTypes: [],
          data: CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data[i]['data']).process()
        }

        question.data.drillDown = data[i]['drillDown'].map(x => ({ "ID": x.id, "Name": x.name, "Category": x.category, "Group": x.group, }));

        question.DashletChartTypes = this.chartOptionsService.getSupportedChartTypes(question, question.data.categories.length, question.data.groupedChart)

        return question;
      })

      this.LoaderService.hideLoader();

    })

    this.addSubscription(subscription);
  }

  fetchOverallFactorAnalysis() {
    this.LoaderService.displayLoader();

    const subscription = this.filtersLoaded$.pipe(
      switchMap(filters => {
        return this.analysisService.getSectionAnalysis({
          companyKey: filters.companyKey,
          tenantKey: filters.tenantKey,
          surveyKey: filters.survey.key,
          filters: [...this.analyticsService.mapGlobalFilters(filters)],
        }).pipe(
          map(response => ({ response, filters }))
        )
      })
    ).subscribe(({ response, filters }) => {
      // filter(x => response.find(f=> f.sectionKey == x.key)).
      this.factors = filters.survey.sections.filter(x => x.isFactor && x.isSelected && (!filters.showExtraFactors ? x.isPredefined == true : true)).map(section => {
        let currentFactor = response.find(x => x.sectionKey == section.key);

        return {
          name: section.name,
          sectionKey: section.key,
          score: currentFactor?.score || 0,
          questionKey: currentFactor?.questionKey,
        }
      })

      this.LoaderService.hideLoader()
    })
  }

  fetchLeaversExperience() {
    const subscription = this.filtersLoaded$.pipe(
      switchMap(filters => {
        return this.analysisService.getLeaverExperience({
          companyKey: filters.companyKey,
          tenantKey: filters.tenantKey,
          surveyKey: filters.survey.key,
          filters: [...this.analyticsService.mapGlobalFilters(filters)],
        })
      })
    ).subscribe(response => {
      this.leaverExperienceScore = response;
    })

    this.addSubscription(subscription);
  }

  fetchEnps() {
    const subscription = this.filtersLoaded$.pipe(
      switchMap(filters => {
        return this.analysisService.getEnps({
          companyKey: filters.companyKey,
          tenantKey: filters.tenantKey,
          surveyKey: filters.survey.key,
          filters: [...this.analyticsService.mapGlobalFilters(filters)],
        })
      }),
      map(scores => {

        const categories = [EnpsTypes.Promoters, EnpsTypes.Passives, EnpsTypes.Detractors];

        return categories.map(c => {

          return scores.find(x => x.category == c) || {
            category: c,
            count: 0,
            percentage: 0
          } as EnpsResultDto

        })


      })
    ).subscribe(response => {
      this.enpsScores = response;

      const promoters = this.enpsScores.find(x => x.category == EnpsTypes.Promoters)?.percentage || 0;
      const detractors = this.enpsScores.find(x => x.category == EnpsTypes.Detractors)?.percentage || 0;

      this.netEnpsScore = promoters - detractors;
    })
  }

  fetchOverallAnalysis() {

    const subscription = this.filtersLoaded$.pipe(
      switchMap(filters => {
        const request = {
          companyKey: filters.companyKey,
          tenantKey: filters.tenantKey,
          surveyKey: filters.survey.key,
          filters: [...this.analyticsService.mapGlobalFilters(filters)],
        }

        return combineLatest([this.analysisService.getOverallAnalysis(request),
        this.analysisService.getTotalCommentsCount(request)]).pipe(
          map(([overall, comments]: [ResponsesRateDto, number]) => ({ ...overall, commentCount: comments }))
        );

      })
    ).subscribe((response) => {
      this.totalCompleted = response.completedCount
      this.totalRespondents = response.totalCount
      this.responseRate = response.responseRate
      this.totalComments = response.commentCount
    })

    this.addSubscription(subscription);

  }

  resolveChartColor(percentage: number) {
    return this.ExperienceCodesSerivce.getColorByCode(this.ExperienceCodesSerivce.getExperienceCode(percentage));
  }

}
