/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ExcelUploadModel } from '../models/ExcelUploadModel';
import type { FormData } from '../models/FormData';
import type { FormFilter } from '../models/FormFilter';
import type { Respondent } from '../models/Respondent';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class RespondentService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param tenantId 
     * @param tenantName 
     * @param requestBody 
     * @returns Respondent Success
     * @throws ApiError
     */
    public excelBase64Uploaded(
tenantId: string,
tenantName: string,
requestBody?: ExcelUploadModel,
): Observable<Array<Respondent>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/respondents/base64-file/{tenantId}/{tenantName}',
            path: {
                'tenantId': tenantId,
                'tenantName': tenantName,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns Respondent Success
     * @throws ApiError
     */
    public getAllRespondents(): Observable<Array<Respondent>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/respondents/get-all-respondents',
        });
    }

    /**
     * @param formId 
     * @returns Respondent Success
     * @throws ApiError
     */
    public getAllRespondentsByForm(
formId: string,
): Observable<Array<Respondent>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/respondents/get-all-respondents-by-form/{formId}',
            path: {
                'formId': formId,
            },
        });
    }

    /**
     * @param tenantId 
     * @param pageNumber 
     * @param pageSize 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public getAllRespondentsByTenantWithPagination(
tenantId: string,
pageNumber: number,
pageSize: number,
requestBody?: FormFilter,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/respondents/get-all-respondents-by-tenant-with-pagination/{tenantId}/{pageNumber}/{pageSize}',
            path: {
                'tenantId': tenantId,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param tenantId 
     * @returns any Success
     * @throws ApiError
     */
    public getAllRespondentsByTenant(
tenantId: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/respondents/get-all-respondents-by-tenant/{tenantId}',
            path: {
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @param id 
     * @returns Respondent Success
     * @throws ApiError
     */
    public getRespondent(
id: string,
): Observable<Respondent> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/respondents/get-respondent/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Respondent Success
     * @throws ApiError
     */
    public addRespondent(
requestBody?: Respondent,
): Observable<Respondent> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/respondents/add-respondent',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Respondent Success
     * @throws ApiError
     */
    public updateRespondent(
id: string,
requestBody?: Respondent,
): Observable<Respondent> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/respondents/update-respondent/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteRespondent(
id: string,
): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/respondents/delete-respondent/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param formId 
     * @param respondentId 
     * @param tenantId 
     * @returns FormData Success
     * @throws ApiError
     */
    public getRespondentAnswers(
formId: string,
respondentId: string,
tenantId: string,
): Observable<FormData> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/respondents/respondent-answers/{formId}/{respondentId}/{tenantId}',
            path: {
                'formId': formId,
                'respondentId': respondentId,
                'tenantId': tenantId,
            },
        });
    }

}
