import { Component, Inject, OnInit } from '@angular/core';
import { ExitBaseComponent } from '../../ui/exit-base/exit-base.component';
import { delay, distinctUntilChanged, filter, map, skip, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { Hierarchy } from 'src/app/core/data-models/hierarchy';
import { EntityTreeModalComponent } from '../../ui/entity-tree-modal/entity-tree-modal.component';
import { CompanyPermission, products } from 'src/app/core/data-models/company-permission';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { EntityTreeService } from 'src/app/core/services/entity-tree.service';
import animations from '../../utils/animations';
import { SurveyTemplateDto, SurveyTemplateLookupDto, SurveyTemplatesService } from '@ex/exit/survey-templates';
import { DualSelectList } from 'src/app/shared/Theme/controls/dual-dropdown-selection/dual-dropdown-selection.component';
import { NewLeaverComponent, NewLeaverModalData } from '../new-leaver/new-leaver.component';
import { LeaverDto, LeaverListFilterDto, LeaverListFilterKeys, LeaversService } from '@ex/exit/leavers';
import { FIREBASE_UPDATE_TOKEN, IFirebaseUpdate } from 'src/app/core/domain/firebase-update';
import { TemplateManagementService } from '../../data-access/services/template-management.service';
import { AnalysisService } from '@ex/exit/analysis';
import { Status } from 'src/app/core/services/global-status.service';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { ExitUnitDeductionDto, ExitUnitService } from '../../data-access/services/exit-unit.service';
import { EssentialObject } from 'src/app/core/services/essential-object.service';
import { UnitBalanceService } from 'src/app/core/services/unit-balance.service';
import { MatDialog } from '@angular/material/dialog';
import { CreditBalanceDialogComponent } from 'src/app/shared/credit-balance-dialog/credit-balance-dialog.component';
import { PlatformUserService } from '@ex/module/core/services/platform-user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: animations
})
export class ListComponent extends ExitBaseComponent implements OnInit {

  language: string = "EN";

  tenantKey: string
  companyKey: string
  surveyKey: string
  currentPage: number = 0;
  settingsLoaded = new Subject();
  settingsLoaded$ = this.settingsLoaded.asObservable();

  selectedTemplate;
  templates = [];
  fullTemplates = [];

  allEntities = [];

  statusList = [
    {
      key: Status.All,
      name: "txtAll"
    },
    {
      key: Status.Completed,
      name: "txt_completed"
    },
    {
      key: Status.Progress,
      name: "txt_in_progress"
    },
    {
      key: Status.Pending,
      name: "txtNotStarted"
    },
    {
      key: Status.Posted,
      name: "txt_scheduled"
    },
    {
      key: Status.Expired,
      name: "txt_expired"
    },
    {
      key: Status.Inactive,
      name: "Inactive"
    }
  ]

  statusChanged = new BehaviorSubject(this.statusList[0].key);
  statusChanged$ = this.statusChanged.asObservable();

  templateChanged = new Subject();
  templateChanged$ = this.templateChanged.asObservable();

  entityChangeAction = new BehaviorSubject([]);
  entityChangeAction$ = this.entityChangeAction.asObservable();

  selectedChildEntity: Hierarchy

  treeList: Hierarchy[]
  permissionList: CompanyPermission[]

  hasCompanyPermission: boolean = true
  selectedEntity;
  essentialObject: EssentialObject
  firebaseUpdate = new BehaviorSubject(true);
  firebaseUpdate$ = this.firebaseUpdate.asObservable();

  filterText = new BehaviorSubject<string>("");
  filterText$ = this.filterText.asObservable();

  pageChanged = new BehaviorSubject<number>(0)
  pageChanged$ = this.pageChanged.asObservable()

  maxResultCount = 10;

  leavers: LeaverDto[] = [];
  totalRecords = 0
  allSendReminderCount: number;

  showTour: boolean = false


  constructor(private permissionService: PermissionsService
    , private entityTreeService: EntityTreeService, private unitBalanceSerive: UnitBalanceService
    , private surveyTemplateService: SurveyTemplatesService, public matDDialog: MatDialog
    , private leaversService: LeaversService, private responseDialogService: ResponseDialogService
    , @Inject(FIREBASE_UPDATE_TOKEN) private firebaseUpdateSerivce: IFirebaseUpdate
    , private templateManagementService: TemplateManagementService
    , private analysisService: AnalysisService
    , private platformUserService: PlatformUserService
    , private router: Router) {
    super()

    this.checkModulePermissions()
      this.GlobalService.innerRoute = false;
  }
  checkModulePermissions() {
    const subscription = this.EssentialObject$.pipe(
      filter(x => x.selectedEntity?.key != null)
    ).subscribe(async obj => {
      let permissionResponse: any = await this.platformUserService.fetchUserPermissions(obj.user).toPromise();
      if (permissionResponse.success == true) {
        let hasPermission = this.GeneralService.hasPermission(obj.selectedEntity.key, products.Exit, permissionResponse?.Data, permissionResponse?.Data == 'Super Admin');
        if (hasPermission) {
          this.GeneralService.resolvePermissionAlert(obj.language);
        } else {
          let route = this.GeneralService.resolveRoute(obj.selectedEntity.key, permissionResponse?.Data);
          this.router.navigateByUrl(route);
        }
      } else {
        this.router.navigateByUrl('/login');
      }
    })
  }

  ngOnInit(): void {

    this.loadTemplates();

    this.initializeFirebaseUpdate()

    this.fetchLeavers();

    this.updateStatusCounts();

    this.LoaderService.displayLoader()

    this.EssentialObject$.pipe(
      filter(obj => obj?.selectedEntity?.key != null),
      tap(_ => this.LoaderService.displayLoader())
    ).subscribe((obj) => {
      this.selectedChildEntity = null;
      this.tenantKey = obj.selectedEntity.tenantKey;
      this.companyKey = obj.selectedEntity.key;
      this.language = obj.language;
      this.selectedEntity = obj.selectedEntity;
      this.allEntities = obj.allEntities.filter(x => x.companyEntityKey == this.companyKey);
      this.surveyKey = '';
      this.permissionList = this.permissionService.isSuperAdmin() ? [] : obj.user?.ModulePermissions?.filter(x => x.products.includes(products.Exit));
      this.essentialObject = obj;
      this.settingsLoaded.next(true);
      this.LoaderService.hideLoader();
    })

  }

  /**
   *
   *
   * Load all the templates once the app settings are loaded
   *
   */
  loadTemplates() {
    this.LoaderService.displayLoader();
    // TODO: Load Survey Templates
    const subscription = this.settingsLoaded$.pipe(
      switchMap(_ => {
        return this.templateManagementService.fetchTemplates(this.tenantKey, this.companyKey, this.language, false)
      }),

      switchMap(templates => {

        return this.analysisService.getEntityLeaverCounts(this.tenantKey, this.companyKey, templates.selectedTemplate.key).pipe(
          map(counts => ({ counts, templates }))
        )

      })
    ).subscribe(({ counts, templates }) => {
      // this.treeList = this.entityTreeService.get(this.allEntities, this.selectedEntity, []);
      this.treeList = this.entityTreeService.get(this.allEntities, this.selectedEntity, counts as any);

      if (this.permissionList && this.treeList) {
        this.populateEntityHierarchyWithPermissions(this.treeList, this.permissionList);
      }

      this.assignEntityIdsFilter();

      // Select the 1st template from default
      if (!this.surveyKey) {
        this.onTemplateChange(templates.selectedTemplate);
      }

      this.templates = templates.templateMenu;
      this.fullTemplates = templates.templates;

      this.LoaderService.hideLoader();
    })

    this.addSubscription(subscription);
  }

  /**
   *
   * Subscribe to firebase updates for the selected template
   *
   */
  initializeFirebaseUpdate() {
    const subscription = this.templateChanged$.pipe(
      filter(template => template != ""),
      switchMap(_ => {
        return this.firebaseUpdateSerivce.getListUpdate(this.tenantKey, this.companyKey, this.surveyKey).pipe(
          skip(1),
          delay(3000)
        )
      }),
    ).subscribe(_ => {
      this.firebaseUpdate.next(true);
    })

    this.addSubscription(subscription);
  }


  updateStatusCounts() {
    const subscription = combineLatest([this.templateChanged$, this.filterText$, this.entityChangeAction$, this.firebaseUpdate$]).pipe(
      filter(obs => obs[0] != ""),
      switchMap(([template, filterText, entities, firebase]) => {

        let filters = [
          {
            key: LeaverListFilterKeys.FilterText,
            value: filterText
          },

        ] as LeaverListFilterDto[]

        if (entities.length) {
          filters.push({
            key: LeaverListFilterKeys.EntityIds,
            value: JSON.stringify(entities)
          })
        }

        return this.leaversService.getStatusCounts(this.tenantKey, this.companyKey, this.surveyKey, filters).pipe(
          map(counts => {
            return this.statusList.map((button: any) => {
              button.value = counts[Object.keys(counts).find((x, i) => x.toLowerCase() == button.key.toLowerCase())] || 0
              return button;
            })

          })
        )

      })
    ).subscribe((counts: any) => {
      this.statusList = counts;
    })

    this.addSubscription(subscription);
  }



  /**
   *
   * Fetch all the leavers based on filters changed
   * Template, Status, FilterText, Firebase Update, Page Change, Entity Selection Change
   *
   */
  fetchLeavers() {
    const obs$ = combineLatest([this.templateChanged$, this.statusChanged$, this.filterText$, this.firebaseUpdate$, this.entityChangeAction$])

    const subscription = obs$.pipe(
      filter(obs => obs[0] != ""),
      switchMap(([templateKey, status, filterText, firebase, entities]: [any, any, string, any, string[]]) => {
        this.onPageChange(0);

        let filters = this.getListFilters(status, filterText, entities);
        return this.pageChanged$.pipe(switchMap(page => {
          return this.leaversService.getList({
            companyKey: this.companyKey,
            tenantKey: this.tenantKey,
            surveyKey: templateKey,
            maxResultCount: this.maxResultCount,
            skipCount: page,
            filters: filters
          })
        }));


      })
    ).subscribe(leavers => {
      this.leavers = JSON.parse(JSON.stringify(leavers.items));
      this.totalRecords = leavers.totalCount;
      this.allSendReminderCount = leavers.allSendReminderCount
      this.showTour = true
    });

    this.addSubscription(subscription);
  }


  private getListFilters(status: any, filterText: string, entities: string[]) {
    let filters = [
      {
        key: LeaverListFilterKeys.Status,
        value: status
      },
      {
        key: LeaverListFilterKeys.FilterText,
        value: filterText
      }
    ];

    if (entities.length)
      filters.push({
        key: LeaverListFilterKeys.EntityIds,
        value: JSON.stringify(entities)
      });
    return filters;
  }


  onStatusChange(key) {
    this.pageChanged.next(0);
    this.statusChanged.next(key);
  }

  onTemplateChange(template) {
    this.selectedTemplate = template;
    this.surveyKey = template.key;
    this.templateChanged.next(template.key);

    // only used to maintain state accross platform.
    this.templateManagementService.templateChangeAction.next(template.key)
  }

  onPageChange(page) {
    this.currentPage = page;
    this.pageChanged.next(page);
  }

  onFilterChange(filter) {
    this.filterText.next(filter);
  }

  editLeaver(leaver) {

    const subscription = this.ZenCDKService.openComponent(NewLeaverComponent, {
      companyKey: this.companyKey,
      tenantKey: this.tenantKey,
      surveyKey: this.surveyKey,
      language: this.language,
      selectedEntity: this.selectedEntity,
      allEntities: this.allEntities,
      leaver: leaver,
      manualClose: true
    } as NewLeaverModalData)
      .subscribe(response => {
        this.ZenCDKService.closeAll();
        this.LoaderService.hideLoader();
      })

    this.addSubscription(subscription);

  }

  unitDeductionCheck() {
    this.LoaderService.displayLoader();

    let request = {
      entityKey: this.selectedEntity.key,
      tenantKey: this.essentialObject.tenant.key,
      creditType: 'EXIT',
    };
    this.addSubscription(
      this.unitBalanceSerive
        .creditBalanceCheck(request)
        .subscribe((response: any) => {
          this.LoaderService.hideLoader();
          if (response.response == 'SUCCESS') {
            this.addNewLeaver();
          } else {
            if (response.message == 'Insufficient Credits') {
              this.LoaderService.hideLoader();


              const searchDialogRef = this.matDDialog.open(
                CreditBalanceDialogComponent,
                {
                  data: {
                    tenantKey: this.essentialObject.tenant.key,
                    entity: this.selectedEntity,
                    creditType: 'EXIT',
                    language: this.language,
                    minUnits: response.data.requiredCredits,
                    currentBalance: response.data.currentUnits,
                    surveyRequires: response.data.minUnits,
                    user: this.essentialObject.user,
                    tenant: this.essentialObject.tenant,
                  },
                  autoFocus: false,
                }
              );
            }
          }
        })
    );

  }

  addNewLeaver() {
    const subscription = this.ZenCDKService.openComponent(NewLeaverComponent, {
      companyKey: this.companyKey,
      tenantKey: this.tenantKey,
      surveyKey: this.surveyKey,
      language: this.language,
      selectedEntity: this.selectedEntity,
      allEntities: this.allEntities,
      leaver: {} as LeaverDto,
      essentialObject: this.essentialObject,
      manualClose: true
    } as NewLeaverModalData)
      .subscribe((response: LeaverDto | any) => {
        this.ZenCDKService.closeAll();
        this.LoaderService.hideLoader();
        if (response.surveyKey && response.surveyKey != this.selectedTemplate.key)
          this.confirmSwitchTemplate(response.surveyKey);
      })

    this.addSubscription(subscription);
  }
  async confirmSwitchTemplate(surveyKey: string) {

    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_switch_template_text',
        'txt_switch_template_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Exit,
        this.language
      );
      if (confirmed) {
        let newTemplate = this.fullTemplates.find(x => x.key == surveyKey);
        let formatedTemplate = this.templateManagementService.populateTemplateData(newTemplate, this.language);
        this.onTemplateChange(formatedTemplate);
      }
    } catch {

    }

  }

  openTree() {
    this.ZenCDKService.openComponent(EntityTreeModalComponent, { componentData: { tselectedChildEntity: this.selectedChildEntity, treeList: this.treeList }, language: this.language, showLeaverCount: true }).subscribe((tselectedChildEntity: Hierarchy) => {
      this.selectedChildEntity = tselectedChildEntity;
      this.assignEntityIdsFilter()
    })
  }

  getChildKeys(root, keys = []) {
    keys.push(root.entity.key);

    if (root.children.length) {
      root.children.forEach(x => {
        keys = this.getChildKeys(x, keys);
      });

    }
    return keys;
  }

  private assignEntityIdsFilter() {
    if ((this.companyKey == this.selectedChildEntity.entity.key) && this.hasCompanyPermission) {
      // this.filters.entityId = [];
      this.entityChangeAction.next([]);
    } else {
      // this.filters.entityId = this.getChildKeys(this.selectedChildEntity, []);
      this.entityChangeAction.next(this.getChildKeys(this.selectedChildEntity, []));
    }
  }

  populateEntityHierarchyWithPermissions(treeList: Hierarchy[], permissionList: CompanyPermission[]) {

    for (var treeEntity of treeList) {
      treeEntity['hasPermission'] = this.permissionService.isSuperAdmin() ? true : permissionList.find(x => x.key == treeEntity.entity.key) ? true : false;

      if (this.selectedChildEntity == null && treeEntity['hasPermission'] == true) {
        this.selectedChildEntity = treeEntity;
      }

      if (treeEntity.children.length) {
        this.populateEntityHierarchyWithPermissions(treeEntity.children, permissionList);
      }
    }

  }

  populateTemplateDropdownData(templates: SurveyTemplateLookupDto[], language: string) {
    const defaultTemplates = templates.filter(x => x.isDefault && !x.isDraft);
    const customTemplates = templates.filter(x => !x.isDefault && !x.isDraft);

    // Default Templates
    const selectList: DualSelectList[] = [
      {
        list: defaultTemplates.map((s: SurveyTemplateDto) => {
          return {
            icon: 'fa-browser',
            key: s.key,
            isPredefined: s.isPredefined,
            name: this.ExtractPipe.transform(s.name, language)
          };
        })
      },

    ];

    // Custom Templates
    if (customTemplates.length) {
      selectList.push({
        title: this.localize("txt_CUSTOMTEMPLATES", this.language, this.ModuleNames.Exit),
        list: customTemplates.map((s: SurveyTemplateDto) => {
          return {
            icon: 'fa-browser',
            key: s.key,
            isPredefined: s.isPredefined,
            name: this.ExtractPipe.transform(s.name, language)
          };
        })
      })
    }

    return selectList;
  }

}
