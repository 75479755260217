import { Component, Input, OnInit } from '@angular/core';
import { GlobalStatusService } from 'src/app/core/services/global-status.service';

@Component({
  selector: 'status-button',
  templateUrl: './status-button.component.html',
  styleUrls: ['./status-button.component.scss']
})
export class StatusButtonComponent implements OnInit {
  @Input() status;
  @Input() data;
  @Input() active: boolean = false;

  constructor(public globalStatus: GlobalStatusService, ) { }

  ngOnInit(): void {
  }

}
