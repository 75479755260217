import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import type { EmailTemplateDto, KeyNameDto, SurveyReminderDto } from '../../core/common/models';
import type { AuditedEntityDto } from '../../core/auditing/models';
import type { SurveyTemplateDto } from '../survey-templates/models';
import type { EntityHierarchyDto } from '../../core/entities/models';

export interface GenerateLinkDto {
  url?: string;
  token?: string;
}

export interface GenerateLinkRespondentDto {
  employeeId: string;
  name?: string;
  email?: string;
}

export interface GetCompanySurveysDto extends PagedResultRequestDto {
  tenantKey?: string;
  companyKey?: string;
  filter?: string;
  status?: string;
}

export interface RespondentSurveyLinkDto {
  token?: string;
  url?: string;
}

export interface SendBulkRemindersDto {
  surveyKey: string;
  emails: string[];
  emailTemplate: EmailTemplateDto;
}

export interface SurveyBulkDeleteDto {
  companyKey: string;
  tenantKey: string;
  surveyKeys: string[];
  isDeleteAll: boolean;
}

export interface SurveyCreateDto {
  companyKey: string;
  tenantKey: string;
}

export interface SurveyCriteriaDto {
  key?: string;
  name: KeyNameDto[];
  type?: string;
  value: object;
  isCustom: boolean;
  isEntity: boolean;
  required: boolean;
  options: SurveyCriteriaOptionDto[];
}

export interface SurveyCriteriaOptionDto {
  key?: string;
  name: KeyNameDto[];
}

export interface SurveyDto extends AuditedEntityDto {
  key?: string;
  companyKey?: string;
  tenantKey?: string;
  title: KeyNameDto[];
  description: KeyNameDto[];
  startDate?: string;
  endDate?: string;
  noOfRespondents: number;
  score: number;
  status?: string;
  survey: SurveyTemplateDto;
  reminders: SurveyReminderDto[];
  invitationEmail: EmailTemplateDto;
  reminderEmail: EmailTemplateDto;
  selectedCriterias: SurveyCriteriaDto[];
  surveyCriterias: SurveyCriteriaDto[];
  entityHierarchy: EntityHierarchyDto[];
  predefinedMetrics: boolean;
}

export interface SurveyListDto extends AuditedEntityDto {
  key?: string;
  companyKey?: string;
  tenantKey?: string;
  title: KeyNameDto[];
  description: KeyNameDto[];
  noOfRespondents: number;
  score: number;
  status?: string;
}

export interface SurveyListPagedDto extends PagedResultDto<SurveyListDto> {
  allDeleteCount: number;
}

export interface SurveyRenameDto {
  key: string;
  title: KeyNameDto[];
}

export interface SurveyUpdateDto {
  key?: string;
  companyKey?: string;
  tenantKey?: string;
  title: KeyNameDto[];
  description: KeyNameDto[];
  startDate?: string;
  endDate?: string;
  noOfRespondents: number;
  score: number;
  status?: string;
  reminders: SurveyReminderDto[];
  invitationEmail: EmailTemplateDto;
  reminderEmail: EmailTemplateDto;
  selectedCriterias: SurveyCriteriaDto[];
  surveyCriterias: SurveyCriteriaDto[];
  surveyTemplate: SurveyTemplateDto;
  entityHierarchy: EntityHierarchyDto[];
  predefinedMetrics: boolean;
}

export interface UpdateSurveyDto {
  key?: string;
  endDate?: string;
  reminders: SurveyReminderDto[];
}
