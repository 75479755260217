import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Entity } from '../../core/data-models/Entity';
import { Tenant } from '../../core/data-models/tenant';
import { TenantService } from '../../core/services/tenant.service';
import { LogService } from '../../core/services/log.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { EntityService } from '../../core/services/entity.service';
import { PlatformUserService } from '../../core/services/platform-user.service';
import { PlatformUser } from '../../core/data-models/platform-user';
import { AuthenticationService } from '../../core/services/authentication.service';
import { LanguageService } from '../../core/services/language.service';
import { EntityGroup } from '../../core/data-models/entity-group';
import { GeneralService } from '../../core/services/general.service';
import { Subscription } from 'rxjs';
import { UnitBalanceService } from '../../core/services/unit-balance.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FileUpload } from '../../core/data-models/file-upload';
import { finalize } from 'rxjs/operators';
import { GlobalsService } from '../../core/services/globals.service';
import { LocalService } from '../../core/services/local.service';
import { Router } from '@angular/router';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { ZenSnackbarService, ZenSnackBarTypes } from 'src/app/shared/Theme/services/zen-snackbar.service';
import { LanguagePipe } from 'src/app/shared/pipes/language.pipe';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { LoaderService } from 'src/app/core/services/loader.service';
declare var $: any;

@Component({
  selector: 'tenant-setup',
  templateUrl: './tenant-setup.component.html',
  styleUrls: ['./tenant-setup.component.scss'],
  animations:[ChildrenAnimation]
})
export class TenantSetupComponent implements OnInit, OnDestroy {
  // Outputs
  @Output() entitySetupComplete = new EventEmitter();
  @Output() displayLoader = new EventEmitter();
  @Output() hideLoader = new EventEmitter();

  // Operational Fields
  panelOpenState = true;
  setupStep: string = 'company';
  entityKeys: string[] = [];
  loading: boolean = false;
  users: PlatformUser[] = [];
  entities: Entity[] = [];
  timer;
  lang;


  entity = new Entity();
  // List Fields
  entitiesList: Entity[] = [];
  industriesList: any[] = [];
  companySizesList: any[] = [];
  countriesList: any[] = [];

  // Language Related Fields
  industries: any[];

  companySizes: any[] = [];
  countries: any[] = [
    { key: 'KWT', language: 'EN', name: 'Kuwait', countryCode: '+965' },
    { key: 'JOR', language: 'EN', name: 'Jordan', countryCode: '+962' },
    { key: 'KSA', language: 'EN', name: 'Saudi Arabia', countryCode: '+966' },
    { key: 'AFG', language: 'EN', name: 'Afghanistan', countryCode: '+93' },
  ];
  ModuleNames = ModuleName

  language: string = 'EN';

  // Text Fields
  // txtInstruction: string;
  // txtNewCompany: string;
  // btnNewCompany: string;
  // txtCompanyName: string;
  // txtCompanyIndustry: string;
  // txtCompanySize: string;
  // txtCurrentHeadcount: string;
  // txtBranches: string;
  // txtCountryCode: string;
  // txtContactNumber: string;
  // txtWebsite: string;
  // btnNext: string;
  // txtUsers: string;
  // txtNewUser: string;
  // txtUserFirstName: string;
  // txtUserLastName: string;
  // txtUserEmail: string;
  // txtUserCompanies: string;
  // txtUserPassword: string;
  // btnNewUser: string;
  // txtSupportedLanguages: string;
  // btn_upload: string;
  // txtHeadQuarter: string;

  private subscriptions = new Subscription();
  // Input Fields

  tenant: Tenant;
  user: PlatformUser;
  supportedLanguages: any[] = [];
  contentLanguages: any[] = [];
  checkDone = true;

  companyLogoURL: string;
  hugeCount;
  lgCount;
  mdCount;
  smCount;
  constructor(
    private tenantService: TenantService,
    private logService: LogService,
    private db: AngularFireDatabase,
    private entityService: EntityService,
    private zenSnackBarService: ZenSnackbarService,
    private _globals: GlobalsService, private languagePipe: LanguagePipe,
    private platformUserService: PlatformUserService,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private generalService: GeneralService,
    private unitBalanceSerive: UnitBalanceService,
    private storage: AngularFireStorage,private loader: LoaderService,
    private localService: LocalService, private _router: Router, private translationPipe: TranslatePipe
  ) {
    this.hugeCount = _globals.hugeCount;
    this.lgCount = _globals.lgCount;
    this.mdCount = _globals.mdCount;
    this.smCount = _globals.smCount;
    this.fetchPlatformUser();
  }
  fetchTenant() {
    try {

      this.tenant.key = this.languageService.getTenant(this.tenant.key);
      this.subscriptions.add(this.tenantService
        .fetchTenant(this.tenant.key)
        .subscribe((tenant: Tenant) => {
          if (tenant.setup) {
            this._router.navigate(["/settings"]);
            // this.essentialDataService.essentialObject.tenant = tenant;
          }
          this.tenant = tenant;
        }));
    } catch (ex) {

    }
  }

  fetchPlatformUser() {
    try {


      this.logService.submitLog(
        0,
        'HomeComponent.fetchPlatformUser()',
        'Task Assigned',
        {}
      );
      this.subscriptions.add(this.platformUserService
        .fetchPlatformUser(this.localService.getJsonValue('UserKey'))
        .subscribe((user: PlatformUser) => {
          this.logService.submitLog(
            0,
            'HomeComponent.fetchPlatformUser()',
            'Task Completed',
            { user }
          );
          this.user = user;
          if (!this.tenant?.key) {
            this.tenant.key = this.user.tenantKey;
            this.fetchTenant();
            // this.platformUserService.fetchRelease();
          }
          this.checkDone = true;
        }));
    } catch (ex) {

    }
  }

  closeDropdown(menu) {
    if (this[menu]) this[menu] = false;
  }

  ngOnInit(): void {
    this.canvasStart();
    this.supportedLanguages = this.languageService.supportedLanguages.filter(s => (s.key == 'EN' || s.key == 'AR'));
    this.user = new PlatformUser();
    this.tenant = new Tenant();

    let localLanguage = this.localService.getJsonValue('language');
    if (!localLanguage) {
      this.language = 'EN';
      this.localService.setJsonValue('language', this.language);
    } else {
      this.language = localLanguage;
      // this.language = 'FR';
    }
    this.languageService.language = this.language;

    this.isArabic();
    this.countries = this.generalService.countries;

    for (let i = 0; i < this.supportedLanguages.length; i++) {
      this.contentLanguages.push(this.supportedLanguages[i].key);
    }
    this.setUpLanguagePreference();
    this.addNewCompany();
  }

  resetDropdown: boolean = false;
  multiTimer;
  dropDownChanged(event, entity) {
    entity.headQuarter = event;
    if (event == '') {
      this.resetDropdown = true;
      this.multiTimer = setTimeout(() => {
        this.resetDropdown = false;
      }, 50);
    }
  }

  selectFile(event, entity): void {
    this.selectedFiles = event.target.files;
    this.upload(entity);
  }
  imageLink: string;
  uploading: boolean = false;
  invalidImage: boolean = false;
  selectedFiles: FileList;
  selectedFile: File;
  currentFileUpload: FileUpload;
  private basePath = '/uploads';
  upload(entity: Entity, raw?): void {
    if (!raw)
      return;

    const file = raw;
    if (['image/jpeg', 'image/png', 'image/gif'].indexOf(file.type) < 0) {
      this.zenSnackBarService.snackBar({
        message:
          this.translationPipe.transform('txtImageFormat', this.language, this.ModuleNames.Common),
        type: ZenSnackBarTypes.Error
      }
      );
      this.invalidImage = true;
      return;
    }
    this.invalidImage = false;
    this.currentFileUpload = new FileUpload(file);
    let fileName =
      file.name + ' - ' + Number((new Date().getTime() / 100).toFixed(0));
    const filePath = `${this.basePath}/${fileName}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(
      filePath,
      this.currentFileUpload.file
    );
    this.uploading = true;
    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe((downloadURL) => {
            this.companyLogoURL = downloadURL;
            entity.logoURL = this.companyLogoURL;
            this.uploading = false;
          });
        })
      )
      .subscribe();
  }

  vName;
  vIndustry;
  vSize;
  vHeadQuarter;
  vBranches;
  vContentLanguages;
  vContactCode;
  vContactNumber;
  vWebsite;
  vLogo;
  numbers = new RegExp('^[0-9]*$');
  validateEntity(entity: Entity) {
    let isValid: boolean = true;
    if (entity.name.filter((d) => d.name == '').length == entity.name.length) {
      this.vName = true;
      isValid = false;
    } else {
      this.vName = false;
    }
    if (!entity.industry) {
      this.vIndustry = true;
      isValid = false;
    } else {
      this.vIndustry = false;
    }
    if (!entity.companySize) {
      this.vSize = true;
      isValid = false;
    } else {
      this.vSize = false;
    }

    if (!entity.headQuarter) {
      this.vHeadQuarter = true;
      isValid = false;
    } else {
      this.vHeadQuarter = false;
    }
    if (!entity.Branches.length) {
      this.vBranches = true;
      isValid = false;
    } else {
      this.vBranches = false;
    }
    if (!entity.ContentLanguages.length) {
      this.vContentLanguages = true;
      isValid = false;
    } else {
      this.vContentLanguages = false;
    }
    if (!entity.contactCode) {
      this.vContactCode = true;
      isValid = false;
    } else {
      this.vContactCode = false;
    }

    if (!entity.contactNumber) {
      this.vContactNumber = true;
      isValid = false;
    } else if (
      entity.contactNumber &&
      !this.numbers.test(entity.contactNumber)
    ) {
      this.zenSnackBarService.snackBar({
        message:
          this.translationPipe.transform('txt_contact_number', this.language, this.ModuleNames.Common) + ' ' + this.translationPipe.transform('txtNumbersOnly', this.language, this.ModuleNames.Common),
        type: ZenSnackBarTypes.Error
      }
      );
    } else {
      this.vContactNumber = false;
    }
    if (entity.website && entity.website?.indexOf('.') < 1) {
      this.vWebsite = true;
      this.zenSnackBarService.snackBar({
        message:
          this.translationPipe.transform('txtWebsite', this.language, this.ModuleNames.Common) + ' ' + this.translationPipe.transform('txtWrongFormat', this.language, this.ModuleNames.Common),
        type: ZenSnackBarTypes.Error
      }
      );

      return false;
    } else {
      this.vWebsite = false;
    }

    if (!isValid)
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform('txt_fill_fields', this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });

    if (
      !this.validateCount(entity.name, this.lgCount) ||
      !this.validateCount(entity.contactNumber, this.smCount, 'x') ||
      (entity.website && !this.validateCount(entity.website, this.mdCount, 'x'))
    )
      return;

    return isValid;
  }

  validateCount(value, count, ready?) {
    let validatedCount;
    if (ready == 'x') {
      validatedCount = this.countWord(value, count);
    } else
      validatedCount = this.countWord(this.extractNameLanguage(value), count);
    if (validatedCount > count) {
      this.zenSnackBarService.snackBar({ message: this.translationPipe.transform('txt_wrong_text_count', this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
      return false;
    }
    if (validatedCount <= count) {
      return true;
    }
  }
  countWord(word, count) {
    if (word?.length <= count) return word.length;
    if (word?.length > count) {
      return word.length;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  entityNameChanged(entity: Entity) {
    entity.nativeName = entity.name.find((et) => et.key == this.language).name;
  }

  setUpLanguagePreference() {
    this.industries = this.generalService.industries;
    this.companySizes = this.generalService.companySizes;
    this.industriesList = this.industries.filter(
      (industry) => industry.language == this.language
    );
    this.companySizesList = this.companySizes.filter(
      (compsize) => compsize.language == this.language
    );
    this.countriesList = this.generalService.countryCodes;
    // this.setUpTextFields();
  }

  extractNameLanguage(eg: any) {
    return this.languageService.extractNameLanguage(eg, this.language);
  }
  // txtCompanySetup: string;
  // txtCreateCompany: string;
  // txtWrongTextCount: string;
  // txtFillFields: string;
  // txtFieldIsRequired: string;
  // txtWrongFormat: string;
  // txtNumbersOnly: string;
  // txtImageFormat: string;
  // txtLogo: string;
  // setUpTextFields() {
  //   this.txtLogo = this.languageService.getUILanguage('txtLogo', this.language);
  //   this.txtImageFormat = this.languageService.getUILanguage(
  //     'txtImageFormat',
  //     this.language
  //   );
  //   this.txtNumbersOnly = this.languageService.getUILanguage(
  //     'txtNumbersOnly',
  //     this.language
  //   );
  //   this.txtWrongFormat = this.languageService.getUILanguage(
  //     'txtWrongFormat',
  //     this.language
  //   );
  //   this.txtWebsite = this.languageService.getUILanguage(
  //     'txtWebsite',
  //     this.language
  //   );
  //   this.txtFieldIsRequired = this.languageService.getUILanguage(
  //     'txt_required_field',
  //     this.language
  //   );
  //   this.txtFillFields = this.languageService.getUILanguage(
  //     'txt_fill_fields',
  //     this.language
  //   );
  //   this.txtWrongTextCount = this.languageService.getUILanguage(
  //     'txt_wrong_text_count',
  //     this.language
  //   );
  //   this.txtCreateCompany = this.languageService.getUILanguage(
  //     'txtCreateCompany',
  //     this.language
  //   );
  //   this.txtCompanySetup = this.languageService.getUILanguage(
  //     'txtCompanySetup',
  //     this.language
  //   );
  //   this.txtInstruction = this.languageService.getUILanguage(
  //     'tenant_intro',
  //     this.language
  //   );
  //   this.txtNewCompany = this.languageService.getUILanguage(
  //     'txt_new_Company',
  //     this.language
  //   );
  //   this.btnNewCompany = this.languageService.getUILanguage(
  //     'txt_new_Company',
  //     this.language
  //   );
  //   this.txtCompanyName = this.languageService.getUILanguage(
  //     'txt_company_name',
  //     this.language
  //   );
  //   this.txtCompanyIndustry = this.languageService.getUILanguage(
  //     'txt_company_industry',
  //     this.language
  //   );
  //   this.txtCompanySize = this.languageService.getUILanguage(
  //     'txt_company_size',
  //     this.language
  //   );
  //   this.txtBranches = this.languageService.getUILanguage(
  //     'txt_branches',
  //     this.language
  //   );
  //   this.txtCountryCode = this.languageService.getUILanguage(
  //     'txt_country_code',
  //     this.language
  //   );
  //   this.txtContactNumber = this.languageService.getUILanguage(
  //     'txt_contact_number',
  //     this.language
  //   );
  //   this.btnNext = this.languageService.getUILanguage(
  //     'btn_next',
  //     this.language
  //   );
  //   this.txtUsers = this.languageService.getUILanguage(
  //     'txt_users_setup',
  //     this.language
  //   );
  //   this.txtNewUser = this.languageService.getUILanguage(
  //     'txt_new_users',
  //     this.language
  //   );
  //   this.txtUserFirstName = this.languageService.getUILanguage(
  //     'txt_user_firstName',
  //     this.language
  //   );
  //   this.txtUserLastName = this.languageService.getUILanguage(
  //     'txt_user_lastName',
  //     this.language
  //   );
  //   this.txtUserEmail = this.languageService.getUILanguage(
  //     'txt_user_email',
  //     this.language
  //   );
  //   this.txtUserPassword = this.languageService.getUILanguage(
  //     'txt_user_password',
  //     this.language
  //   );
  //   this.txtUserCompanies = this.languageService.getUILanguage(
  //     'txt_user_companies',
  //     this.language
  //   );
  //   this.btnNewUser = this.languageService.getUILanguage(
  //     'txt_new_users',
  //     this.language
  //   );
  //   this.txtSupportedLanguages = this.languageService.getUILanguage(
  //     'txt_content_languages',
  //     this.language
  //   );
  //   this.btn_upload = this.languageService.getUILanguage(
  //     'btn_upload',
  //     this.language
  //   );
  //   this.txtHeadQuarter = this.languageService.getUILanguage(
  //     'txtHeadQuarter',
  //     this.language
  //   );
  // }

  addNewCompany() {
    this.entity = new Entity();
    this.entity.name.push({ key: 'EN', name: '' });
    let entityGroup = new EntityGroup();
    entityGroup.groupKey = this.db.createPushId();
    entityGroup.isCompanyGroup = true;
    entityGroup.name = this.languageService.companyLanguages;
    this.entity.EntityGroups.push(entityGroup);
    this.entity.entityGroupKey = entityGroup.groupKey;
  }

  addNewUser() {
    let platformUser = new PlatformUser();
    platformUser.role = 'User';
    platformUser.firstName = '';
    platformUser.lastName = '';
    platformUser.isActive = true;
    platformUser.tenantKey = this.tenant.key;
    platformUser.EntityReferences = [];
    this.users.push(platformUser);
  }
  selectedLanguageChange(event) {
    this.language = event;
    this.localService.setJsonValue('language', this.language);

    location.reload();
    this.isArabic();

    // this.isArabic();
    // this.selectedLanguageChanged.emit(this.language);
    // this.isArabic();
  }

  isArabic() {
    if (this.languagePipe.transform(this.language)) {
      $('body').removeClass('ltr');
      $('body').addClass('rtl').attr('dir', 'rtl');
    } else {
      $('body').removeClass('rtl');
      $('body').addClass('ltr').attr('dir', 'ltr');
    }
  }

  companyInvalid: boolean = false;
  async createCompanies() {
    this.companyInvalid = true;
    if (!this.validateEntity(this.entity))
      return 

    this.entities.push(this.entity);
    this.displayLoader.emit();
    if (!this.user.EntityReferences) this.user.EntityReferences = [];
    this.logService.submitLog(
      0,
      'TenantSetupComponent.createCompanies()',
      'Task Assigned',
      { tenant: this.tenant }
    );
    for (let i = 0; i < this.entities.length; i++) {
      let entity = this.entities[i];
      entity.isCompany = true;
      entity.key = this.db.createPushId();
      entity.tenantKey = this.tenant.key;
      entity.companyEntityKey = entity.key;
      let entityGroup = new EntityGroup();
      entityGroup.groupKey = this.db.createPushId();
      entityGroup.isCompanyGroup = true;
      entityGroup.name = this.languageService.companyLanguages;
      //entity.EntityGroups.push(entityGroup);
      //entity.entityGroupKey = entityGroup.groupKey;
      this.entityKeys.push(entity.key);
      await this.entityService.coreUpdateCompany(entity).toPromise();
      this.entityService.createCompany({ TenantKey: this.tenant.key, CompanyKey: this.entity.key, thomasIntegration: false }).subscribe((response: any) => {
        if (response.response == 'SUCCESS') {
          this.entityService.createEntity(entity);
          this.user.EntityReferences.push(entity.key);
        } else {
          this.zenSnackBarService.snackBar({ message: this.translationPipe.transform("txtSthWrong", this.language, this.ModuleNames.Common), type: ZenSnackBarTypes.Error });
        }
      });

    }
    this.subscriptions.add(this.unitBalanceSerive
      .createCompanyBalance({
        tenantKey: this.tenant.key,
        entityKeys: this.entityKeys,
      })
      .subscribe((response: any) => {
        if (response.response == 'SUCCESS') {
          this.logService.submitLog(
            0,
            'TenantSetupComponent.createCompanies()',
            'Task Completed',
            { entities: this.entityKeys }
          );
          this.updateUserEntities();
          this.loader.displayLoader();
        }
      }));
  }

  updateUserEntities() {
    this.logService.submitLog(
      0,
      'TenantSetupComponent().updateUserEntities',
      'Task Assiigned',
      { tenant: this.user }
    );
    this.platformUserService
      .updatePlatformUser(this.user)
      .then((_) => {
        this.logService.submitLog(
          0,
          'TenantSetupComponent().updateUserEntities',
          'Task Completed',
          { tenant: this.user }
        );
        this.tenant.setup = true;
        this.tenant.isSetup = true;
        this._router.navigate(["/settings"]);

        this.updateTenant();
      })
      .catch((error) => {
        this.logService.submitLog(
          0,
          'TenantSetupComponent().updateUserEntities',
          'Task Failed',
          { tenant: this.user }
        );
      });
  }

  async updateTenant() {
    await this.tenantService.updateCoreTenant(this.tenant).toPromise();
    this.logService.submitLog(
      0,
      'TenantSetupComponent().updateTenant',
      'Task Assiigned',
      { tenant: this.tenant }
    );
    this.tenantService
      .updateTenant(this.tenant)
      .then((_) => {
        this.logService.submitLog(
          0,
          'TenantSetupComponent().updateTenant',
          'Task Completed',
          { tenant: this.tenant }
        );
        this.timer = setTimeout(() => {
          this.entitySetupComplete.emit();
          this.displayLoader.emit();
        }, 400);
        //this.fetchPlatformUsers();
      })
      .catch((error) => {
        this.logService.submitLog(
          0,
          'TenantSetupComponent().updateTenant',
          'Task Failed',
          { tenant: this.tenant }
        );
      });
  }

  fetchPlatformUsers() {

    this.logService.submitLog(
      0,
      'TenantSetupComponent().fetchPlatformUsers',
      'Task Assiigned',
      {}
    );
    this.subscriptions.add(this.platformUserService
      .fetchTenantPlatformUsersOnce(this.tenant.key)
      .subscribe((users: PlatformUser[]) => {
        this.logService.submitLog(
          0,
          'TenantSetupComponent().fetchPlatformUsers',
          'Task Completed',
          { users }
        );
        this.users = users;
        this.fetchEntities();
      }));
  }

  fetchEntities() {

    this.logService.submitLog(
      0,
      'TenantSetupComponent().fetchPlatformUsers',
      'Task Assiigned',
      {}
    );
    this.subscriptions.add(this.entityService
      .fetchEntitiesOnce(this.tenant.key)
      .subscribe((entities: Entity[]) => {
        this.logService.submitLog(
          0,
          'TenantSetupComponent().fetchPlatformUsers',
          'Task Completed',
          { entities }
        );
        this.entitiesList = entities;
        this.loading = false;
      }));
  }

  createUsers() {
    for (let i = 0; i < this.users.length; i++) {
      let newUser = this.users[i];
      if (newUser.role != 'Super Admin') {
        let password = newUser.password;
        newUser.password = null;
        this.authenticationService
          .registerFirebaseUser(newUser, password)
          .then(() => { })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    this.loading = true;
    this.timer = setTimeout(() => {
      this.entitySetupComplete.emit();
      this.loading = false;
    }, 400);
  }

  getEntityName(entityNames: any[]) {
    return entityNames.find((et) => et.key == this.language).name;
  }

  branchesSelectionChanged(event, entity: Entity) {
    let selectedKeys = event;
    if (selectedKeys.length == 0) entity.Branches = [];

    if (selectedKeys.length > 0) {
      for (let i = 0; i < selectedKeys.length; i++) {
        if (!entity.Branches.find((br) => br.key == selectedKeys[i])) {
          let branch = { key: selectedKeys[i] };
          entity.Branches.push(branch);
        }
      }
    } else {
      entity.Branches = [];
    }
    for (let i = 0; i < entity.Branches.length; i++) {
      if (!selectedKeys.find((br) => br == entity.Branches[i].key)) {
        entity.Branches.splice(i, 1);
      }
    }
  }
  logData(e) {
    console.log(e);
  }


  canvasStart() {
    var canvas = <HTMLCanvasElement>document.getElementById('c');
    var ctx = canvas.getContext('2d');
    let colors = ['#fbfbfb', '#f7f7f8', '#f4f4f4', '#f0f0f1', '#ededee', '#e9e9ea']
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;
    let speed = 1;

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let color = 0; color < colors.length; color++) {
        ctx.beginPath();
        ctx.moveTo(-200, (canvas.height / 10 + Math.sin(speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.bezierCurveTo(
          canvas.width / 2,
          (canvas.height / 4 + Math.cos(speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width / 4,
          (canvas.height / 4 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2,
          canvas.width + 200,
          (canvas.height / 3 + Math.sin(-speed * .01) * 200) + color * 100 + color * 100 / 2);
        ctx.lineWidth = 130 + color * 100 / 2;
        ctx.strokeStyle = colors[color];
        ctx.stroke();
      }
      speed += .5;
    }

    animate();
  }
}