import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThomasIntegrationService } from 'src/app/psychometric/services/thomas-integration.service';

@Component({
  selector: 'app-call-back',
  templateUrl: './call-back.component.html',
  styleUrls: ['./call-back.component.scss']
})
export class CallBackComponent implements OnInit {
  constructor(private route: ActivatedRoute, private thomasIntegrationService: ThomasIntegrationService) { }
  ngOnInit(): void {
    let tenantKey = this.route.snapshot.paramMap.get('tenantKey');
    let companyKey = this.route.snapshot.paramMap.get('companyKey');
    let baseKey = this.route.snapshot.paramMap.get('baseKey');
    let integrationUrl = this.thomasIntegrationService.integrationOrderApi.replace("{tenantKey}", tenantKey);
    integrationUrl = integrationUrl.replace("{companyKey}", companyKey);
    integrationUrl = integrationUrl.replace("{baseKey}", baseKey);
    this.thomasIntegrationService.IntegrateOrderAssessment(integrationUrl).subscribe((res) => {

    }, (err) => {  })
  }
}
