<div
  class="flex flex-col max-w-4xl z-10 w-full  rounded-xl gap-5  transition-all min-h-full zen-main-container justify-between relative pt-10 lg:pt0">
  <div class="w-full">
    <ng-content select="[header]"></ng-content>
  </div>

  <div #body [hidden]="body.childNodes.length > 1"
    class="relative transition-all duration-300 ease-expo overflow-hidden">
    <div #innerBody
      class="absolute flex flex-col items-stretch justify-center gap-1  overflow-hidden maxh-40  max-w-4xl md:min-h-0 w-full pb-1">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
  <div [ngClass]="showSectionIntro?'max-h-0 overflow-y-hidden':'max-h-20'"
    class="flex flex-row items-center justify-between md:h-16  h-20 md:bg-transparent bg-white md:px-0 px-7 md:mr-0 md:ml-0 -mr-7 -ml-7 md:static sticky bottom-0 stickyFooter transition-all ease-expo">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
