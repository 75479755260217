import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'z-toggle',
  templateUrl: './zen-toggle.component.html',
  styleUrls: ['./zen-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZenToggleComponent),
      multi: true,
    },
  ],
})
export class ZenToggleComponent implements ControlValueAccessor {
  @Input() label;
  @Input() color: string = 'exp';
  @Input() opposite: boolean = false;
  constructor() {}
  @Input() disable: boolean = false;
  onChange: any = () => {};
  onTouch: any = () => {};
  toggle = false;

  writeValue(toggle: any) {
    console.log('value written');
    if (toggle !== undefined) {
      this.toggle = this.opposite ? !toggle : toggle;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  toggleControl() {
    this.toggle = !this.toggle;
    console.log(this.toggle);
    this.onChange(this.opposite ? !this.toggle : this.toggle);
  }
}
