<div class="flex flex-col items-stretch px-7 bg-white gap-2 py-2">
  <div class="flex flex-row justify-between items-stretch gap-4 text-zen-lite py-2">
    <div class="flex flex-row gap-4 items-center">
      <i class="fa-regular fa-comments-question"></i>
      <h5 class="text-xs font-normal ">{{comment.comment}}</h5>
    </div>
    <div class="flex flex-row justify-end items-stretch gap-2" *ngIf="returnColor(comment.answer) != null">
      <span class="border-r border-zen border-opacity-10 w-0"></span>
      <div class="flex flex-row items-center ">
        <!-- [ngStyle]="{'background-color': getLegendColor('positive')+'66', 'color': getLegendColor('positive')}" -->
        <h5 [ngStyle]="{'background-color':returnColor(comment.answer) + '0A', 'color':returnColor(comment.answer)}" class="whitespace-nowrap text-sm font-medium leading-5 px-2 rounded-r-full rounded-l-full">
          {{ getConfidence('single-answer-confidence-with-na', comment.answer.toString())}} </h5>
      </div>
    </div>
  </div>
  <span class="border-t border-zen border-opacity-10 h-0"></span>
  <ng-content></ng-content>
</div>
