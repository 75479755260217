import { Direction } from '@angular/cdk/bidi';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalService } from '@ex/module/core/services/local.service';
import { ChildrenAnimation, fadeUpDown } from 'src/app/shared/animations/allAnimations';

@Component({
  selector: 'app-respondents',
  templateUrl: './respondents.component.html',
  styleUrls: ['./respondents.component.scss'],
  animations: [fadeUpDown, ChildrenAnimation]
})
export class RespondentsComponent implements OnInit {


  language : any;
  direction : Direction = "ltr"
  textPosition : any = "left";

  constructor(private router: Router,private localService : LocalService){

  }

  ngOnInit() {


    this.language = this.localService.getJsonValue('language')
    this.textPosition = "left"
    if (this.language == "AR")
    {
      this.direction = "rtl"
      this.textPosition = "right"
    }
    else
    {
      this.direction = "ltr"
      this.textPosition = "left"
    }

    this.router.navigate(['survey-x/respondents']);

  }



}
