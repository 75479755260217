import { Injectable } from '@angular/core';
import { Dashlet } from 'src/app/core/data-models/dashlet';
import { EntityGroup } from 'src/app/core/data-models/entity-group';
import { GeneralService } from 'src/app/core/services/general.service';

@Injectable({
  providedIn: 'root'
})
export class ChartOptionsService {

  constructor(private generalService: GeneralService) {

  }

  getSupportedChartTypes(dashlet, categoriesLength: number = 0, isGrouped: boolean = false) {

    let chartTypes: any[] = JSON.parse(JSON.stringify(this.generalService.chartTypes));

    if (isGrouped) {
      let pieType = chartTypes.find(dT => dT.DashletType == 'pie');
      let radarA = chartTypes.find(dT => dT.DashletType == 'radarArea');
      let radarC = chartTypes.find(dT => dT.DashletType == 'radarColumn');
      let barH = chartTypes.find(dT => dT.DashletType == 'barH');

      let chartChange: boolean = false;
      if (radarA && (categoriesLength < 4)) {
        chartTypes.splice(chartTypes.indexOf(radarA), 1);
        chartChange = true;
      }
      if (radarC && (categoriesLength < 4)) {
        chartTypes.splice(chartTypes.indexOf(radarC), 1);
        chartChange = true;
      }

      if (barH) {
        chartTypes.splice(chartTypes.indexOf(barH), 1);
        chartChange = true;

      }
      if (pieType)
        chartTypes.splice(chartTypes.indexOf(pieType), 1);
      if (dashlet.DashletType == 'pie' || chartChange) {
        if (chartTypes.length > 0) {
          dashlet.DashletType = chartTypes[0].DashletType;
        }
      }
    } else {
      let stackV = chartTypes.find(dT => dT.DashletType == 'stackV');
      let stackH = chartTypes.find(dT => dT.DashletType == 'stackH');
      let radarA = chartTypes.find(dT => dT.DashletType == 'radarArea');
      let radarC = chartTypes.find(dT => dT.DashletType == 'radarColumn');
      let barH = chartTypes.find(dT => dT.DashletType == 'barH');

      // if (barH)
      // chartTypes.splice(chartTypes.indexOf(barH), 1);
      if (stackV)
        chartTypes.splice(chartTypes.indexOf(stackV), 1);
      if (stackH)
        chartTypes.splice(chartTypes.indexOf(stackH), 1);
      if (radarA)
        chartTypes.splice(chartTypes.indexOf(radarA), 1);
      if (radarC)
        chartTypes.splice(chartTypes.indexOf(radarC), 1);
      if (dashlet.DashletType == 'stackV' || dashlet.DashletType == 'stackH' ||
        dashlet.DashletType == 'radarArea' || dashlet.DashletType == 'radarColumn') {
        if (chartTypes.length > 0) {
          dashlet.DashletType = 'barV';

        }
      }
    }

    return chartTypes;

  }

  getDefaultFilterOptions(includeKeys: string[], entityGroups: EntityGroup[] = []) {

    let fixedGroupingOptions = JSON.parse(JSON.stringify(this.generalService.fixedGroupingOptions.filter(x => includeKeys.includes(x.key))));
    let groupingOptions: any[] = [];

    // Add Entity Groups to the Filter Options
    for (let i = 0; i < entityGroups.length; i++) {
      let group = entityGroups[i];
      groupingOptions.push({ key: group.groupKey, name: group.name, type: "entity" });
    }

    // Add Default Filter Options
    for (let i = 0; i < fixedGroupingOptions.length; i++) {
      let group = fixedGroupingOptions[i];
      groupingOptions.push({ key: group.key, name: group.name, type: "fixed" });
    }

    return groupingOptions;
  }

}
