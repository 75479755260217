<div [ngClass]="{'rtlDir':language|language}">
	<z-modal [open]="true">
		<ng-container header>
			<div class="flex flex-row gap-2 items-center">
				<i class="fal fa-coins text-white bg-exp shadow-xl rounded-full h-10 w-10 text-xl text-center leading-10 "></i>
				<h4 class="text-2xl font-bold text-zen">
					{{((submittedFrom == 'OTHER' && user.role == 'Super Admin')?
          "txtInsufficientUnits" :"btnRequestUnits")| translate:language:ModuleNames.Shared}}
				</h4>
			</div>
			<i [mat-dialog-close]
				class="fal fa-times btn cancel leading-10 w-10 text-center"></i>
		</ng-container>
		<ng-container body>
			<div *ngIf="user.role == 'User' || (submittedFrom == 'ACCOUNTOVERVIEW')"
				style="text-align: center;">
				<div *ngIf="!requestSent">
					<div *ngIf="requestType != 'MAINREQUEST'">
						<!-- <h5 class="text-center font-normal  text-2xl">{{txtInsufficientUnits}}</h5> -->
						<h4 class="text-center font-normal is-size-6 my-3">
							{{'txtYourcurrentbalanceforsurvey'| translate:language:ModuleNames.Shared}} {{
              currentBalance }} {{'txtthissurveyrequires'| translate:language:ModuleNames.Shared}} {{
              surveyRequires }} {{'txtunits'| translate:language:ModuleNames.Shared}}
						</h4>
					</div>
					<table *ngIf="requestType == 'MAINREQUEST'"
						class="w-full  bg-transparent py-2 relative">
						<tbody>
							<tr class="mat-row cdk-row h-auto px-10">
								<th></th>
								<th class=" pb-5">
									<h5 class="text-xxs text-zen uppercase font-medium whitespace-nowrap text-start">
										{{'txtCurrentBalance'|
                    translate:language:ModuleNames.Shared}}
									</h5>
								</th>
								<th class=" pb-5">
									<h5 class="text-xxs text-zen uppercase font-medium whitespace-nowrap text-start">
										{{'txtRequestAmount'|
                  translate:language:ModuleNames.Shared}}
									</h5>
								</th>
								<th class=" pb-5">
									<h5 class="text-xxs text-zen uppercase font-medium whitespace-nowrap text-start">
										{{'txtNewBalance'|
                    translate:language:ModuleNames.Shared}}
									</h5>
								</th>
							</tr>



							<ng-container *ngFor="let product of productTable">
								<ng-template *ngTemplateOutlet="productRow; context:{$implicit:product}"></ng-template>
							</ng-container>



							<tr *ngIf="user.role == 'Super Admin' ">
								<td></td>
								<td></td>
								<td></td>
								<td>
									<div class="flex flex-col items-end  gap-2 px-5 ">
										<h5 class="text-sm text-zen text-opacity-40 ">
											{{'txtTotal'|
                    translate:language:ModuleNames.Shared}}
										</h5>
										<h5 class="text-lg text-zen font-medium ">
											{{returnNumber()}}
										</h5>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<!-- <div *ngIf="requestType == 'MAINREQUEST'"
            class="w-full overflow-x-auto max-w-full bg-transparent bg-white pt-10 px-10">

            <div class="flex flex-row justify-between my-5 gap-4">
              <div *ngIf="user.role == 'Super Admin' ||( user.role == 'User' && creditType ==  'ONBOARDING')"
                class="flex flex-row items-center max-w-xs w-80">
                <div class="flex flex-row items-center gap-5">
                  <img class="h-15" src="/assets/img/sub/onboarding.svg" alt="">
                  <div class="flex flex-col items-start">
                    <h6 class="text-xxs text-zen font-normal opacity-75 uppercase">ZENITHR</h6>
                    <h5 class="text-lg text-zen font-medium -mb-1">{{'txtEmployeeOnboardingAnalysis'|
                      translate:language:ModuleNames.Onboarding }}</h5>
                  </div>
                </div>
              </div>
              <div class="flex flex-row  ">
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div class=" flex flex-col gap-2 px-5 items-start ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtCurrentBalance'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super Admin'?currentBalance.assessmentBalance:
                    currentBalance}}</h5>
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div *ngIf="user.role == 'Super Admin'" class="flex flex-col gap-2 px-5 ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtRequestAmount'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <input *ngIf="user.role == 'Super Admin'" min="0" type="number" numbersOnly
                    [(ngModel)]="allUnits.ONBOARDING" placeholder="0"
                    class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                  <input *ngIf="user.role  != 'Super Admin'" min="0" type="number" numbersOnly [(ngModel)]="units"
                    placeholder="0" class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
              </div>

              <div class="flex flex-col items-start  gap-2 px-5 ">
                <h5 class="text-sm text-zen text-opacity-40 ">{{'txtNewBalance'|
                  translate:language:ModuleNames.Shared}}</h5>
                <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super Admin'?allUnits.ONBOARDING + currentBalance.assessmentBalance: currentBalance + units}}</h5>
              </div>
            </div>

            <mat-divider></mat-divider>
            <div class="flex flex-row justify-between mb-5 gap-4">
              <div *ngIf="user.role == 'Super Admin' ||( user.role == 'User' && creditType ==  'ENGAGEMENT')"
                class="flex flex-row items-center max-w-xs w-80">
                <div class="flex flex-row items-center gap-5">
                  <img class="h-15" src="/assets/img/sub/Engagement.svg" alt="">
                  <div class="flex flex-col items-start">
                    <h6 class="text-xxs text-zen font-normal opacity-75 uppercase">ZENITHR</h6>
                    <h5 class="text-lg text-zen font-medium -mb-1">{{'txtEngagement'|
                      translate:language:ModuleNames.Shared}}</h5>
                  </div>
                </div>
              </div>
              <div class="flex flex-row ">
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div class=" flex flex-col items-start gap-2 px-5 ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtCurrentBalance'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super Admin'?currentBalance.engagementBalance:
                    currentBalance}}</h5>
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div *ngIf="user.role == 'Super Admin'" class="flex flex-col gap-2 px-5 ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtRequestAmount'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <input *ngIf="user.role == 'Super Admin'" min="0" type="number" numbersOnly
                    [(ngModel)]="allUnits.ENGAGEMENT" placeholder="0"
                    class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                  <input *ngIf="user.role  != 'Super Admin'" min="0" type="number" numbersOnly [(ngModel)]="units"
                    placeholder="0" class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
              </div>

              <div class="flex flex-col items-start  gap-2 px-5 ">
                <h5 class="text-sm text-zen text-opacity-40 ">{{'txtNewBalance'|
                  translate:language:ModuleNames.Shared}}</h5>
                <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super Admin'?allUnits.ENGAGEMENT +
                  currentBalance.engagementBalance: currentBalance + units}}</h5>
              </div>
            </div>
            <mat-divider></mat-divider>


            <div class="flex flex-row justify-between my-5 gap-4">
              <div *ngIf="user.role == 'Super Admin' ||( user.role == 'User' && creditType ==  'HAPPINESS')"
                class="flex flex-row items-center max-w-xs w-80">
                <div class="flex flex-row items-center gap-5">
                  <img class="h-15" src="/assets/img/sub/Happiness.svg" alt="">
                  <div class="flex flex-col items-start">
                    <h6 class="text-xxs text-zen font-normal opacity-75 uppercase">ZENITHR</h6>
                    <h5 class="text-lg text-zen font-medium -mb-1">{{'txtEmployeeHappiness'|
                      translate:language:ModuleNames.Shared}}</h5>
                  </div>
                </div>
              </div>
              <div class="flex flex-row  ">
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div class=" flex flex-col gap-2 px-5 items-start ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtCurrentBalance'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super
                    Admin'?currentBalance.happinessBalance:
                    currentBalance}}</h5>
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div *ngIf="user.role == 'Super Admin'" class="flex flex-col gap-2 px-5 ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtRequestAmount'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <input *ngIf="user.role == 'Super Admin'" min="0" type="number" numbersOnly
                    [(ngModel)]="allUnits.HAPPINESS" placeholder="0"
                    class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                  <input *ngIf="user.role  != 'Super Admin'" min="0" type="number" numbersOnly [(ngModel)]="units"
                    placeholder="0" class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
              </div>

              <div class="flex flex-col items-start  gap-2 px-5 ">
                <h5 class="text-sm text-zen text-opacity-40 ">{{'txtNewBalance'|
                  translate:language:ModuleNames.Shared}}</h5>
                <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super Admin'?allUnits.HAPPINESS +
                  currentBalance.happinessBalance: currentBalance + units}}</h5>
              </div>
            </div>
            <mat-divider></mat-divider>


            <div class="flex flex-row justify-between my-5 gap-4">
              <div *ngIf="user.role == 'Super Admin' ||( user.role == 'User' && creditType ==  'ASSESSMENT')"
                class="flex flex-row items-center max-w-xs w-80">
                <div class="flex flex-row items-center gap-5">
                  <img class="h-15" src="/assets/img/sub/360.svg" alt="">
                  <div class="flex flex-col items-start">
                    <h6 class="text-xxs text-zen font-normal opacity-75 uppercase">ZENITHR</h6>
                    <h5 class="text-lg text-zen font-medium -mb-1">{{'txt360DegreeFeedback'|
                      translate:language:ModuleNames.Shared}}</h5>
                  </div>
                </div>
              </div>
              <div class="flex flex-row  ">
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div class=" flex flex-col gap-2 px-5 items-start ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtCurrentBalance'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super
                    Admin'?currentBalance.assessmentBalance:
                    currentBalance}}</h5>
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div *ngIf="user.role == 'Super Admin'" class="flex flex-col gap-2 px-5 ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtRequestAmount'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <input *ngIf="user.role == 'Super Admin'" min="0" type="number" numbersOnly
                    [(ngModel)]="allUnits.ASSESSMENT" placeholder="0"
                    class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                  <input *ngIf="user.role  != 'Super Admin'" min="0" type="number" numbersOnly [(ngModel)]="units"
                    placeholder="0" class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
              </div>

              <div class="flex flex-col items-start  gap-2 px-5 ">
                <h5 class="text-sm text-zen text-opacity-40 ">{{'txtNewBalance'|
                  translate:language:ModuleNames.Shared}}</h5>
                <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super Admin'?allUnits.ASSESSMENT +
                  currentBalance.assessmentBalance: currentBalance + units}}</h5>
              </div>
            </div>
            <mat-divider></mat-divider>


            <div class="flex flex-row justify-between my-5 gap-4">
              <div *ngIf="user.role == 'Super Admin' ||( user.role == 'User' && creditType ==  'ASSESSMENT180')"
                class="flex flex-row items-center max-w-xs w-80">
                <div class="flex flex-row items-center gap-5">
                  <img class="h-15" src="/assets/img/sub/180.svg" alt="">
                  <div class="flex flex-col items-start">
                    <h6 class="text-xxs text-zen font-normal opacity-75 uppercase">ZENITHR</h6>
                    <h5 class="text-lg text-zen font-medium -mb-1">{{'txt180FeedbackAssessment'|
                      translate:language:ModuleNames.Common }}</h5>
                  </div>
                </div>
              </div>
              <div class="flex flex-row  ">
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div class=" flex flex-col gap-2 px-5 items-start ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtCurrentBalance'|  translate:language:ModuleNames.Shared}}</h5>
                  <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super Admin'?currentBalance.assessmentBalance: currentBalance}}</h5>
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div *ngIf="user.role == 'Super Admin'" class="flex flex-col gap-2 px-5 ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtRequestAmount'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <input *ngIf="user.role == 'Super Admin'" min="0" type="number" numbersOnly
                    [(ngModel)]="allUnits.ASSESSMENT180" placeholder="0"
                    class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                  <input *ngIf="user.role  != 'Super Admin'" min="0" type="number" numbersOnly [(ngModel)]="units"
                    placeholder="0" class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
              </div>

              <div class="flex flex-col items-start  gap-2 px-5 ">
                <h5 class="text-sm text-zen text-opacity-40 ">{{'txtNewBalance'|
                  translate:language:ModuleNames.Shared}}</h5>
                <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super Admin'?allUnits.ASSESSMENT180 +  currentBalance.assessmentBalance: currentBalance + units}}</h5>
              </div>
            </div>
            <mat-divider></mat-divider>

            <div class="flex flex-row justify-between my-5 gap-4">
              <div *ngIf="user.role == 'Super Admin' ||( user.role == 'User' && creditType ==  'EXIT')"
                class="flex flex-row items-center max-w-xs w-80">
                <div class="flex flex-row items-center gap-5">
                  <img class="h-15" src="/assets/img/sub/Exit.svg" alt="">
                  <div class="flex flex-col items-start">
                    <h6 class="text-xxs text-zen font-normal opacity-75 uppercase">ZENITHR</h6>
                    <h5 class="text-lg text-zen font-medium -mb-1">{{'txt_employee_exit'|
                      translate:language:ModuleNames.Shared}}</h5>
                  </div>
                </div>
              </div>
              <div class="flex flex-row  ">
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div class=" flex flex-col gap-2 px-5 items-start ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtCurrentBalance'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super
                    Admin'?currentBalance.exitBalance:
                    currentBalance}}</h5>
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
                <div *ngIf="user.role == 'Super Admin'" class="flex flex-col gap-2 px-5 ">
                  <h5 class="text-sm text-zen text-opacity-40 ">{{'txtRequestAmount'|
                    translate:language:ModuleNames.Shared}}</h5>
                  <input *ngIf="user.role == 'Super Admin'" min="0" type="number" numbersOnly
                    [(ngModel)]="allUnits.EXIT" placeholder="0"
                    class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                  <input *ngIf="user.role  != 'Super Admin'" min="0" type="number" numbersOnly [(ngModel)]="units"
                    placeholder="0" class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
                </div>
                <span class="block border-r border-zen border-opacity-20 h-15"></span>
              </div>

              <div class="flex flex-col items-start  gap-2 px-5 ">
                <h5 class="text-sm text-zen text-opacity-40 ">{{'txtNewBalance'|
                  translate:language:ModuleNames.Shared}}</h5>
                <h5 class="text-lg text-zen font-medium "> {{user.role == 'Super Admin'?allUnits.EXIT +
                  currentBalance.exitBalance: currentBalance + units}}</h5>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div *ngIf="user.role == 'Super Admin' " class="flex flex-row justify-end mt-5 gap-4">
              <div class="flex flex-col items-end  gap-2 px-5 ">
                <h5 class="text-sm text-zen text-opacity-40 ">{{'txtTotal'|
                  translate:language:ModuleNames.Shared}}</h5>
                <h5 class="text-lg text-zen font-medium "> {{returnNumber(allUnits.EXIT) +
                  returnNumber(allUnits.ASSESSMENT)
                  + returnNumber(allUnits.ENGAGEMENT) + returnNumber(allUnits.HAPPINESS)}}</h5>
              </div>
            </div>

          </div> -->
					<!-- <h5 class="text-center py-2">{{ equivelantSurveys === 0 ? '' : (equivelantSurveys + '
                    Surveys') }}</h5> -->





				</div>
				<div *ngIf="requestSent"
					class="flex flex-col items-center justify-center gap-4">
					<i class="fal fa-check-circle  text-9xl text-zen-green"></i>
					<h5 class="text-lg text-zen">
						{{'txtRequestSent'| translate:language:ModuleNames.Shared}}
					</h5>
					<h5 class="text-sm text-zen">
						{{'txtContactSoon'| translate:language:ModuleNames.Shared}}
					</h5>



					<a [mat-dialog-close]
						class="btn cancel leading-15 px-10 rounded mt-10">
						{{'txt_close'| translate:language:ModuleNames.Shared}}
					</a>
				</div>



			</div>



			<div *ngIf="(submittedFrom == 'OTHER' && user.role == 'Super Admin')"
				class="flex flex-col gap-10">
				<h4 [innerHtml]='"txtclicktogotoaccount"| translate:language:ModuleNames.Shared'
					class="text-center font-medium text-zen px-10">
				</h4>



				<!-- <h5 class="text-center font-normal  text-2xl">{{txtInsufficientUnits}}</h5> -->




			</div>
		</ng-container>
		<ng-container footer>
			<div *ngIf="(submittedFrom == 'OTHER' && user.role == 'Super Admin')"
				class="flex flex-row justify-between items-center w-full">
				<a [mat-dialog-close]
					class="btn cancel leading-15 px-10 rounded">
					{{'txt_close'| translate:language:ModuleNames.Shared}}
				</a>
				<a [mat-dialog-close]
					routerLink="/accountoverview"
					class="btn exp leading-15 px-10 rounded">
					{{'txtAccountOverview'| translate:language:ModuleNames.Shared}}
				</a>
			</div>



			<div *ngIf="requestType == 'MAINREQUEST' && !requestSent"
				class="w-full flex flex-row justify-between pt-5 sticky bottom-0  bg-zenSub-lightgrey">
				<a [mat-dialog-close]
					class="btn cancel leading-15 px-10 rounded">
					{{'txt_close'| translate:language:ModuleNames.Shared}}
				</a>
				<a (click)="user.role == 'Super Admin'? requestAllUnits(): requestUnits(creditType, units)"
					class="btn exp leading-15 px-10 rounded">
					{{'txtRequest'| translate:language:ModuleNames.Shared}}
				</a>
			</div>
		</ng-container>
	</z-modal>
</div>




<ng-template #productRow
	let-product>
	<tr *ngIf="user.role == 'Super Admin' ||( user.role == 'User' && creditType ==  product.allUnitsName)"
		class="mat-row cdk-row h-auto border-b border-zen border-opacity-10  transition-all bg-opacity-5 bg-white hover:bg-opacity-100">
		<td class="border-b-0 border-l-0 py-5 border-r-0 px-10">
			<div class="flex flex-row items-center gap-5">
				<img [src]="product.image"
					class="h-15"
					alt>
				<div class="flex flex-col items-start">
					<h6 class="text-xxs text-zen uppercase font-medium whitespace-nowrap text-start">ZENITHR</h6>
					<h5 class="text-lg text-zen font-medium -mb-1">
						{{product.name| translate:language:ModuleNames.Common }}
					</h5>
				</div>
			</div>
		</td>
		<td class="border-b-0 border-l-0 p-5 border-r-0">
			<h5 class="text-lg text-zen font-medium ">
				{{user.role == 'Super Admin'?currentBalance[product.balance]:currentBalance}}
			</h5>
		</td>
		<td class="border-b-0 border-l-0 py-5 border-r-0">
			<input *ngIf="user.role == 'Super Admin'"
				[(ngModel)]="allUnits[product.allUnitsName]"
				min="0"
				type="number"
				numbersOnly
				placeholder="0"
				class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
			<input *ngIf="user.role  != 'Super Admin'"
				[(ngModel)]="units"
				min="0"
				type="number"
				numbersOnly
				placeholder="0"
				class="bg-zen bg-opacity-10 rounded leading-10 w-20 text-center px-4">
		</td>




		<td class="border-b-0 border-l-0 border-r-0  px-5">
			<h5 class="text-lg text-zen font-medium ">
				{{user.role == 'Super Admin'?allUnits[product.allUnitsName] + currentBalance[product.balance]: currentBalance + units}}
			</h5>
		</td>
	</tr>
</ng-template>
