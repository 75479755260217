import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Entity } from 'src/app/core/data-models/Entity';
import { HierarchyEntity } from 'src/app/core/data-models/hierarcy-entity';
import { DATA_TOKEN, ICDKPortalComponent } from 'src/app/shared/Theme/services/zen-cdk.service';
import { NativeExportBaseComponent } from 'src/app/shared/native-export-base/native-export-base.component';
import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';
import { AnalysisFilterRequestDto, DimensionOverallAnalysisDto, QuestionAnalysisService, SectionMeanDto, SurveyAnalysisService } from '@ex/wellbeing/analysis';
import { WellbeingScalesService } from '../../utils/wellbeing-scales.service';
import { map, switchMap } from 'rxjs/operators';
import { KeyNameDto } from '@ex/core/common';
import { CriteriaResolverService } from '../../data-access/services/criteria-resolver.service';
import { KeyResolverService } from 'src/app/core/services/key-resolver.service';
import { WellbeingKeyResolverNames } from '../../utils/wellbeing-key-resolver-names';
import { CHART_DATA_RESPONSE_STRATEGY, CommonChartFunctions } from 'src/app/core/services/chart-data.service';
import { CommentCountDto, CommentsService } from '@ex/wellbeing/comments';
import { combineLatest } from 'rxjs';
import { CHART_EXPORT_CONFIGURATION, ChartsExportConfigResult } from 'src/app/shared/export-components/chart-export-helpers.service';
import { dimensionDataByScore } from '../../utils/report-dimension-data-repository';
import { DimensionKeys } from '../../utils/dimension-keys';
import { GlobalStatusService, Status } from 'src/app/core/services/global-status.service';
import { GeneralService } from 'src/app/core/services/general.service';

export interface WbReportExportData {
  langauge: string,
  selectedEntity: Entity,
  currentViewdEntity: HierarchyEntity,
  filters: AnalyticsFilter
}
enum ChartGroup {
  Gender = 'Gender',
  Nationality = 'Nationality',
  Generation = 'Generation',
  AgeGroup = 'AgeGroup',
  TenureGroup = 'TenureGroup',
  Performance = 'PerformanceRate',
  BandLevel = 'BandLevel',
  JobGrade = 'JobGrade',
  SurveyStatus = 'SurveyStatus',
  SeperationType = "SeperationType"
}

@Component({
  selector: 'app-wb-analysis-report',
  templateUrl: './wb-analysis-report.component.html',
  styleUrls: ['./wb-analysis-report.component.scss'],
  providers: [WellbeingScalesService]
})
export class WbAnalysisReportComponent extends NativeExportBaseComponent implements OnInit, ICDKPortalComponent {

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  wellbeingScales = [
    {
      scale: WellbeingScalesService.VeryPoorExperience
    },
    {
      scale: WellbeingScalesService.PoorExperience
    },
    {
      scale: WellbeingScalesService.FairExperience
    },
    {
      scale: WellbeingScalesService.GoodExperience
    },
    {
      scale: WellbeingScalesService.VeryGoodExperience
    }
  ]
  scaleList: any[] = this.wellbeingScalesService.config;
  exportReport: boolean = true;
  exportOverview: boolean = false;
  exportAnalysis: boolean = false;
  exportQuestions: boolean = false;
  language: string;
  filters: AnalyticsFilter;
  filteredTableOfContent;
  tableOfContent;
  dimensions;
  breakIndex = 42;
  request: AnalysisFilterRequestDto;
  exportableCharts: ChartsExportConfigResult;
  factors: { key: string, factor: DimensionOverallAnalysisDto, scales: any, textData: {}, isPredefined: boolean }[];
  overallFactorScore: number;
  wellbeingOverallIndexByScales: { mean: number; scale: string; }[];
  wellbeingIndexByDimensions: {
    dimension: { key: string, name: KeyNameDto[], isPredefined: boolean, mean?: number },
    data: { mean: number; scale: string; }[]
  }[] = [];
  wellbeingIndexByCriterias: any;
  commentCounts: CommentCountDto;
  selectedEntity: Entity;
  dimensionQuestions: SectionMeanDto[];
  wellbeingDimensions: string;
  date: Date = new Date();
  calculationProcess: any[] = new Array(4);
  totalRespondents;
  responseRate;
  totalCompleted;
  totalComments;

  dummy = {
    countries: [],

    // Add other data here
  }
  overviewCharts: any[];

  constructor(@Inject(DATA_TOKEN) private data: WbReportExportData
    , private surveyAnalysisService: SurveyAnalysisService
    , private analysisService: AnalyticsService, public wellbeingScalesService: WellbeingScalesService
    , private keyResolverService: KeyResolverService
    , private commentsAppService: CommentsService
    , private questionAnalysisService: QuestionAnalysisService
    , private globalStatusService: GlobalStatusService
    , private commonChartFunctions: CommonChartFunctions
    , private criteriaResolverService: CriteriaResolverService
  ) {
    super();

    this.filters = data.filters;
    this.language = data.langauge;
    this.selectedEntity = data.selectedEntity;
    this.request = {
      companyKey: this.filters.companyKey,
      surveyKey: this.filters.survey.key,
      tenantKey: this.filters.tenantKey,
      filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []]
    }

    this.dummy.countries = this.generalService.countries_new.map(x => x.key).slice(0, 100);
    this.fetchAnalysisByCriteria();
  }


  ngOnInit(): void {
    this.fetchOverallDimensionAnalysis();
    this.fetchOverallWellbeingIndexByDimension();
    this.fetchOverallAnalysis();
    this.fetchQuestionAnalysis();
    // this.fetchCommentAnalysis();
    this.fetchWellbeingIndexByCriteria();

  }

  /**
   *
   * 3.  Overall Workplace Well-Being
   * 4.  Employee Well-Being Dimensions
   * Fetches Overall Wellbeing
   * Wellbeing for each dimension
   *
   */
  fetchOverallDimensionAnalysis() {


    const subscription = this.surveyAnalysisService.getOverallDimensionAnalysis(this.request).pipe(
      switchMap((factors) => {


        const factors$ = factors.map(factor => {
          return this.surveyAnalysisService.getSectionRespondentsIndex({
            companyKey: this.filters.companyKey,
            surveyKey: this.filters.survey.key,
            tenantKey: this.filters.tenantKey,
            filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || [{ key: "SectionKey", value: factor.key }]]
          })
            .pipe(
              map(scales => {
                return this.wellbeingScales.map(x => ({ mean: scales.find(y => y.scale == x.scale)?.mean || 0, scale: x.scale }));
              })
            )
        })
        return combineLatest(factors$).pipe(map(factorScales => ({ factors, factorScales })))
      })).subscribe(({ factors, factorScales }) => {
        this.overallFactorScore = factors.reduce((p, c) => p + c.score, 0) / factors.length;
        this.factors = factors.map((factor, index) => {
          return {
            key: factor.key,
            factor: factor,
            scales: this.wellbeingScales.map(x => ({ mean: factorScales[index].find(y => y.scale == x.scale)?.mean || 0, scale: x.scale })),
            textData: dimensionDataByScore(factor.key, factor.scale),
            isPredefined: this.filters.survey.dimensions.find(x => x.key == factor.key).isPredefined,
          }
        })
        this.factors = this.filters.survey.dimensions.filter(x => this.factors.find(f => f.key == x.key)).map(dimension => {
          return this.factors.find(factor => factor.key == dimension.key)
        });
        this.filterTableOfContent();
      })

    // this.surveyAnalysisService.getSectionRespondentsIndex({
    //   companyKey: this.filters.companyKey,
    //   surveyKey: this.filters.survey.key,
    //   tenantKey: this.filters.tenantKey,
    //   filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || [{ key: "SectionKey", value: dimension.key }]]
    // })
    //   .pipe(
    //     map(scales => {
    //       return this.wellbeingScales.map(x => ({ mean: scales.find(y => y.scale == x.scale)?.mean || 0, scale: x.scale }));
    //     })
    //   )
    this.addSubscription(subscription);
  }

  /**
   *
   * 3.2. Employee Well-being Index
   *
   */

  fetchOverallWellbeingIndexByDimension() {

    const subscription = this.surveyAnalysisService.getSectionRespondentsIndex(this.request)
      .pipe(
        map(scales => {

          return this.wellbeingScales.map(x => ({ mean: scales.find(y => y.scale == x.scale)?.mean || 0, scale: x.scale }));

        })
      ).subscribe(scales => {
        this.wellbeingOverallIndexByScales = scales;
      })

    this.addSubscription(subscription);
  }


  fetchOverallAnalysis() {
    const request = {
      surveyKey: this.filters.survey.key,
      tenantKey: this.filters.tenantKey,
      companyKey: this.filters.companyKey,
      filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []]
    }


    const obs$ = combineLatest([
      this.surveyAnalysisService.getOverallAnalysis(request),
      this.surveyAnalysisService.getTotalCommentsCount(request),
    ])
    const subscription = obs$.subscribe(([overall, comments]: [any, any]) => {

      this.totalRespondents = overall.totalCount;
      this.responseRate = overall.responseRate;
      this.totalCompleted = overall.completedCount;
      this.totalComments = comments

    })

    this.addSubscription(subscription);
  }



  fetchWellbeingIndexByCriteria() {

    // Make a copy of the surveyCriterias so that it doesn't update by ref
    const criterias: any = this.filters.survey.surveyCriterias.map(x => ({ ...x }));

    criterias.push({
      name: this.localize('txtStatusAnalysis', this.language, this.ModuleNames.Wellbeing),
      key: "status",
      canGroup: false,
      DashletType: 'pie',
      fullWidth: true
    })

    let criteriaList$ = criterias.map(criteria => {
      return this.surveyAnalysisService
        .getGroupedRespondentsByCriteria(criteria.key, {
          companyKey: this.filters.companyKey,
          surveyKey: this.filters.survey.key,
          tenantKey: this.filters.tenantKey,
          type: criteria.key != 'status' ? 'ec' : '',
          filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []]
        })
        .pipe(
          // map(data => {

          //   if (criteria.key == "NATIONALITY") {

          //     const dataLen = data.length;

          //     for (let c of this.dummy.countries) {
          //       let item = JSON.parse(JSON.stringify(data[Math.floor(Math.random() * dataLen)]))
          //       item.category = c;
          //       data.push(item);
          //     }



          //   }

          //   return data;
          // }),
          map(data => {

            if (criteria.key == 'status') {
              const resolver = this.keyResolverService.getResolver('SurveyStatus');

              if (resolver) {
                this.keyResolverService.setKeyResolver(resolver);
                data = this.commonChartFunctions.reArrangeArray(data, [Status.Completed, Status.Progress, Status.Pending, Status.Expired, Status.Inactive], 'category');
                data.forEach((x: any) => {
                  x.key = x.category;
                  x.group = this.keyResolverService.resolve(x.key, this.language, this.ModuleNames.Shared);
                })
              }
            }
            else {
              const scaleResolver = this.keyResolverService.getResolver(WellbeingKeyResolverNames.ScaleResolver);
              const surveyCriteriaResolver = this.keyResolverService.getResolver(WellbeingKeyResolverNames.SurveyCriterias);

              data.forEach((x: any) => {

                x.key = x.group

                this.keyResolverService.setKeyResolver(surveyCriteriaResolver);
                x.category = this.keyResolverService.resolve(x.category, this.data.langauge, criteria.key)

                this.keyResolverService.setKeyResolver(scaleResolver);
                x.group = this.keyResolverService.resolve(x.group, this.data.langauge, this.ModuleNames.Wellbeing)

              })
            }
            return CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(data).process();
          })
        )
    })
    combineLatest([...criteriaList$]).subscribe(criteriaData => {
      let criteriasList = criterias.map((criteria, index) => {
        let chart: any = criteria;
        chart.data = criteriaData[index]
        return chart;
      })
      this.exportableCharts = CHART_EXPORT_CONFIGURATION.configureWellbeingCharts(criteriasList, { chartMax: this.breakIndex, chartMin: 17, chartsPerPage: 4 }).configure();
      console.log(this.exportableCharts);
    });

  }

  // fetchCommentAnalysis() {

  //   const obs$ = combineLatest([this.commentsAppService.getSectionsCommentCountBySurveyKeyAndFilters(this.filters.survey.key, this.request.filters)])

  //   const subscription = obs$
  //     .pipe(
  //       switchMap(([counts]) => {
  //         this.commentCounts = counts;
  //         const dimensions = this.factors.filter(x => this.getSectionCount(x.key));

  //         // get the questions whose count is good
  //         const questions = dimensions
  //           .map(x => x.questions)
  //           .reduce((p, c) => p.concat(...c), [])
  //           .filter(x => this.getQuestionCount(x.key))

  //         const obs$ = questions.map(x => {
  //           return this.commentsAppService.getListAsycByRequest({
  //             questionKey: x.key,
  //             surveyKey: this.filters.survey.key,
  //             tenantKey: this.filters.tenantKey,
  //             companyKey: this.filters.companyKey,
  //             maxResultCount: 10,
  //             skipCount: 0,
  //             isAllResults: true,
  //             filters: this.request.filters,
  //           })

  //         })

  //         return combineLatest(obs$).pipe(
  //           map((questions) => {
  //             return { questions, dimensions, counts };
  //           })
  //         )

  //       })
  //     )
  //     .subscribe(({ questions, dimensions, counts }) => {
  //       // TODO:

  //     })

  //   this.addSubscription(subscription);

  // }

  fetchQuestionAnalysis() {
    // throw new Error('Method not implemented.');
    const subscription = this.questionAnalysisService.get({
      companyKey: this.filters.companyKey,
      surveyKey: this.filters.survey.key,
      tenantKey: this.filters.tenantKey,
      filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []]
    }).subscribe(dimensions => {
      this.dimensionQuestions = dimensions
    })
    this.addSubscription(subscription);
  }


  getSectionCount(key) {
    return this.commentCounts.sectionCount.find(x => x.key == key)?.count ?? 0;
  }

  getQuestionCount(questionKey: string) {
    return this.commentCounts?.questionCount.find(x => x.key == questionKey)?.count || 0;
  }



  fetchAnalysisByCriteria() {
    this.overviewCharts = [];
    for (let index = 0; index < this.filters.survey.surveyCriterias.length; index++) {
      const criteria = this.filters.survey.surveyCriterias[index];
      let criteriaChart = {
        DashletType: 'stackV',
        canGroup: false,
        question: {
          canGroup: true
        },
        DashletChartTypes: [],
        Title: this.extractPipe.transform(criteria.name, this.language)
      }
      criteriaChart['data$'] = this.surveyAnalysisService.getWellbeingExperienceByCriteria(criteria.key, {
        companyKey: this.filters.companyKey,
        surveyKey: this.filters.survey.key,
        tenantKey: this.filters.tenantKey,
        filters: [...this.analysisService.mapGlobalFilters(this.filters), ...this.filters.chartFilters || []],
        type: 'ec'
      }).pipe(map(data => {
        let tdata = this.commonChartFunctions.reArrangeArray(data, this.wellbeingScales.map(x => x.scale), 'group');
        const scaleResolver = this.keyResolverService.getResolver(WellbeingKeyResolverNames.ScaleResolver);
        this.keyResolverService.setKeyResolver(scaleResolver);

        tdata.forEach((x: any) => {
          x.key = x.group;
          x.group = this.keyResolverService.resolve(x.group, this.language, this.ModuleNames.Wellbeing);
          x.category = this.criteriaResolverService.extract(criteria.key, x.category, this.language);
        });


        return CHART_DATA_RESPONSE_STRATEGY.NormalDashlet(tdata).process();

      }))
      this.overviewCharts.push(criteriaChart);
    }
  }

  returnIndexColor(data) {
    return data.bar.series.map(x => {
      return this.wellbeingScalesService.config.find(n => n.key == x.key)?.color
    })
  }

  filterTableOfContent(event?) {
    this.tableOfContent = [];
    this.filteredTableOfContent = [];
    setTimeout(() => {
      this.tableOfContent = [
        {
          dispaly: this.exportReport,
          title: this.localize('txtIntroduction', this.language, this.ModuleNames.Wellbeing),
          content: 2,
        },
        {
          id: 'txtHowtousethisreport',
          dispaly: this.exportReport,
          title: this.localize('txtHowtousethisreport', this.language, this.ModuleNames.Wellbeing),
          content: 1,
        },
        {
          id: 'overallWorkplaceWellbeing',
          dispaly: this.exportReport,
          title: this.localize('txtOverallWorkplaceWellbeing', this.language, this.ModuleNames.Wellbeing),
          content: this.factors.length > 6 ? 3 : 2,
          children: [],
        },
        {
          id: 'driversofWorkplaceWellbeing',
          dispaly: this.exportReport,
          title: this.localize('txtDriversofWorkplaceWellbeing', this.language, this.ModuleNames.Wellbeing),
          content: 1,
          children: [],
        },
        {
          id: 'overview',
          dispaly: this.exportOverview,
          title: this.localize('txtOverview', this.language, this.ModuleNames.Wellbeing),
          content: this.factors.length > 6 ? 3 : 2,
          children: this.factors.map(driver => {
            return {
              content: 1,
            }
          }),
          hideChildren: true
        },
        {
          id: 'analysis',
          dispaly: this.exportAnalysis,
          title: this.localize('txtResponses', this.language, this.ModuleNames.Wellbeing),
        },
        {
          id: 'questions',
          dispaly: this.exportQuestions,
          title: this.localize('txtStatements', this.language, this.ModuleNames.Wellbeing),
          content: Math.ceil(this.factors?.length / 2)
        },
      ];



      let overallWorkplaceWellbeing = this.tableOfContent.find(s => s.id == 'driversofWorkplaceWellbeing');
      let overviewContainer = this.tableOfContent.find(s => s.id == 'overview');
      overviewContainer.children = this.factors.map(driver => {
        return {
          title: this.extractPipe.transform(driver.factor.name, this.language),
          content: 1,
        }
      })
      this.overviewCharts.forEach(driver => {
        overviewContainer.children.push({
          title: driver.name,
          titleExtract: true,
          hide: true,
          content: driver.page ? driver.page : 1,
        });
      });

      overallWorkplaceWellbeing.children = this.factors.filter(x => x.isPredefined).map(driver => {
        return {
          title: this.extractPipe.transform(driver.factor.name, this.language),
          content: 2,
        }
      })

      let analysisIndex = this.tableOfContent.indexOf(this.tableOfContent.find(x => x.id == "analysis"))
      this.tableOfContent[analysisIndex].content = this.exportableCharts.pages; // status chart custom add
      console.log(this.filteredTableOfContent);

      this.filteredTableOfContent = this.tableOfContent.filter(row => row.dispaly);
      let page = 2;
      for (let index = 0; index < this.filteredTableOfContent.length; index++) {
        if (this.filteredTableOfContent[index].content) {
          page += this.filteredTableOfContent[index].content;
          this.filteredTableOfContent[index].page = page;

        }
        else {
          page += 1;
          this.filteredTableOfContent[index].page = page--;
        }
        if (this.filteredTableOfContent[index]?.children?.length) {
          for (let num = 0; num < this.filteredTableOfContent[index].children.length; num++) {
            page = page + this.filteredTableOfContent[index].children[num].content;
            this.filteredTableOfContent[index].children[num].page = page;
          }
        }

      }
      console.log(this.filteredTableOfContent);

    }, 1000);
  }

  sectionIndex(e) {
    let index = this.filteredTableOfContent?.find(s => s.id == e);
    return this.filteredTableOfContent?.indexOf(index) + 1;
  }
  returnIcon(chart) {
    switch (chart.key.toLowerCase()) {
      case ChartGroup.Gender.toLowerCase():
        return chart.icon = 'fa-regular fa-venus-mars';
      case ChartGroup.Nationality.toLowerCase():
        return chart.icon = 'fa-regular fa-flag';
      case ChartGroup.Generation.toLowerCase():
        return chart.icon = 'fa-regular fa-people-group';
      case ChartGroup.AgeGroup.toLowerCase():
        return chart.image = '/assets/img/svg/age-group.svg';
      case ChartGroup.TenureGroup.toLowerCase():
        return chart.image = '/assets/img/svg/tenure.svg';
      case ChartGroup.Performance.toLowerCase():
        return chart.icon = 'fa-regular fa-gauge-high';
      case ChartGroup.BandLevel.toLowerCase():
        return chart.icon = 'fa-regular fa-sitemap';
      case ChartGroup.JobGrade.toLowerCase():
        return chart.image = '/assets/img/svg/Rectangle43.svg';
      case ChartGroup.SurveyStatus.toLowerCase():
        return chart.icon = 'fa-regular fa-wave-pulse';

      default:
        return '';
    }

  }


  statusColors(data) {
    return data.map(x => { return this.globalStatusService.returnHexColor(x) });
  }

  getGenderKey(value) {
    return this.GeneralService.genders_new.find(s => s.name.find(x => x.name == value)).key;
  }
  returnStatusColor(category) {
    return this.globalStatusService.returnClassColor(category, true)
  }

  fullWdithChartCheck(chart, categories) {
    let rowsPerLine = 2;
    if (!chart.fullWidth || categories.find(x => x.length > 15))
      rowsPerLine = 1;
    return rowsPerLine;
  }

  extractNameLanguage(eG: any) {
    return this.LanguageService.extractNameLanguage(eG, this.language).replaceAll('[Company]', this.LanguageService.extractNameLanguage(this.selectedEntity?.name, this.language));
  }
  returnAnswers(answers: any[]) {
    const r = answers.map(x => ({ percentage: x.percentage, color: this.wellbeingScalesService.resolveAnswerColor(x.value) }))
    return r;
  }

}
