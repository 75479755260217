import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '../../core/services/loader.service';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() language;
  @Input() showLoader: boolean = false;

  constructor(public loaderService: LoaderService) { }

  ngOnInit(): void {
  }

}
