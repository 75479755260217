import { Injectable } from '@angular/core';
import { SurveyTemplateDto, SurveyTemplateLookupDto, SurveyTemplatesService } from '@ex/exit/survey-templates';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppInjector } from 'src/app/app.module';
import { DualSelectList } from 'src/app/shared/Theme/controls/dual-dropdown-selection/dual-dropdown-selection.component';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { ExtractPipe } from 'src/app/shared/pipes/extract.pipe';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';

interface TemplateControlModel {
  templates: SurveyTemplateDto[],
  templateMenu: DualSelectList[],
  selectedTemplate: Partial<SurveyTemplateDto>
}
@Injectable({
  providedIn: null
})
export class TemplateManagementService {

  private ExtractPipe = AppInjector.get(ExtractPipe);
  private TranslatePipe = AppInjector.get(TranslatePipe);
  ModuleNames = ModuleName

  constructor(private surveyTemplateService: SurveyTemplatesService) {
    if (localStorage.getItem("ExitTemplateKey"))
      this.templateChangeAction.next(localStorage.getItem("ExitTemplateKey"));
  }

  defaultMenuTemplate;
  selectedTemplate = '';
  templateChangeAction = new BehaviorSubject<string>('');
  templateChangeAction$ = this.templateChangeAction.asObservable();

  refresh = new BehaviorSubject<number>(1);
  refresh$ = this.refresh.asObservable();

  fetchTemplates(tenantKey: string, companyKey: string, language: string, createMode?: boolean): Observable<TemplateControlModel> {
    return this.refresh$.pipe(switchMap(x => {
      return this.surveyTemplateService.getAll(tenantKey, companyKey).pipe(switchMap(templates => {
        return this.templateChangeAction$.pipe(map(key => {
          let templateMenu = this.populateTemplateDropdownData(templates, language, createMode);
          let tTemplate = templates.find(template => template.key === key);
          let selectedTemplate = tTemplate ? this.populateTemplateData(tTemplate, language) : this.defaultMenuTemplate;
          localStorage.setItem("ExitTemplateKey", selectedTemplate.key)
          return ({
            templates,
            templateMenu,
            selectedTemplate
          })
        }));
      }));
    }))
  }

  populateTemplateDropdownData(templates: SurveyTemplateLookupDto[], language: string, createMode = true) {
    const defaultTemplates = templates.filter(x => x.isDefault);
    const customTemplates = createMode ? templates.filter(x => !x.isDefault) : templates.filter(x => !x.isDefault && !x.isDraft);

    // Default Templates
    const selectList: DualSelectList[] = [
      {
        title: createMode ? this.localize("txt_PREDEFINEDTEMPLATES", language, this.ModuleNames.Exit) : '',
        list: defaultTemplates.map((s: SurveyTemplateDto) => {
          return {
            icon: 'fa-browser',
            key: s.key,
            isPredefined: s.isPredefined,
            name: this.ExtractPipe.transform(s.name, language)
          };
        })
      },
      {
        title: this.localize("txt_CUSTOMTEMPLATES", language, this.ModuleNames.Exit),
        list: customTemplates.map((s: SurveyTemplateDto) => {
          return {
            icon: 'fa-browser',
            key: s.key,
            isPredefined: s.isPredefined,
            name: this.ExtractPipe.transform(s.name, language)
          };
        }),
        button: createMode ? 'fa-plus' : ''
      }
    ];

    this.defaultMenuTemplate = selectList[0].list[0];
    return selectList;
  }

  populateTemplateData(template: SurveyTemplateLookupDto, language: string) {
    return {
      icon: 'fa-browser',
      key: template.key,
      isPredefined: template.isPredefined,
      name: this.ExtractPipe.transform(template.name, language)
    };
  }

  localize(key: string, language: string, module: string) {
    return this.TranslatePipe.transform(key, language, module);
  }

}
