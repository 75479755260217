import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExitBaseComponent } from '../exit-base/exit-base.component';
import { KeyResolverService } from 'src/app/core/services/key-resolver.service';
import { AnalysisFilterKeys } from 'src/app/core/domain/analysis-filter-keys';
import animations from '../../utils/animations';
import { DualSelectItem } from 'src/app/shared/Theme/controls/dual-dropdown-selection/dual-dropdown-selection.component';
import { ChildrenAnimation, listAnimation } from 'src/app/shared/animations/allAnimations';

@Component({
  selector: 'exit-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],

})
export class SidebarComponent extends ExitBaseComponent implements OnInit {

  @Input()
  language = "EN"

  @Input()
  tenantKey

  @Input()
  companyKey

  @Input()
  templates

  @Output()
  buttonChange = new EventEmitter();

  @Input()
  selectedTemplate: DualSelectItem

  @Output()
  selectedTemplateChange = new EventEmitter();

  @Input() buttonList: { key: string, name: string, isSelected?: boolean }[] = [{
    key: "1",
    name: "txtOverview",
  }, {
    key: "2",
    name: "txtLeaversAnalysis",
  }, {
    key: "3",
    name: "txt_questions",
  }, {
    key: "4",
    name: "txtComments"
  }];
  @Input()
  tourKey;
  currentStatus

  selectedTemplateKey;

  constructor(private keyResolverService: KeyResolverService) { super() }

  ngOnInit(): void {
    this.keyResolverService.setKeyResolver(this.keyResolverService.getResolver(AnalysisFilterKeys.SurveyStatus))
    this.currentStatus = this.buttonList[0].key;
    // TODO: Fetch templates
  }

  onTemplateChange(key) {
    this.selectedTemplateKey = key;
    this.selectedTemplateChange.emit(this.selectedTemplateKey);
  }

  resolveSurveyStatus(key: string) {
    return this.keyResolverService.resolve(`${key?.toUpperCase()}`, this.language, this.ModuleNames.Shared);
  }

  onButtonChange(key) {
    this.currentStatus = key;
    this.buttonChange.emit(key)
  }

}
