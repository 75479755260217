import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { LogService } from './log.service';
import { Entity } from '../data-models/Entity';
import { take, map } from 'rxjs/operators';
import { EntityHierarchy } from '../data-models/entity-hierarchy';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  sEndpoint: string = 'https://apis.zenithr.net/api/';//'http://localhost:52619/api/';https://apis.zenithr.net/api/
  coreAPI: string = 'https://localhost:44385/api/';

  constructor(private db: AngularFireDatabase, private logService: LogService, private http: HttpClient, private globals: GlobalsService) {
    this.sEndpoint = this.globals.sEndpoint;
    this.coreAPI = this.globals.coreAPI;

  }

  createEntity(entity: Entity) {
    this.db.object<Entity>('/TenantData/' + entity.tenantKey + '/Entities/' + entity.key).set(entity)
      .then((_) => {
        this.logService.submitLog(4, 'EntityService.createEntity()', 'Task Completed', { entity });
      }).catch((error) => {
        this.logService.submitLog(4, 'EntityService.createEntity()', 'Task Failed', { entity });
      });
  }

  createNewEntity(entity: Entity){
    return this.http.post(this.coreAPI + 'Entity/CreateEntity', entity);
  }

  coreUpdateCompany(entity: Entity){
    return this.http.post(this.coreAPI + 'Entity/UpdateEntity', entity);
  }

  updateEntity(entity: Entity) {
    return this.db.object<Entity>('/TenantData/' + entity.tenantKey + '/Entities/' + entity.key).update(entity);
  }

  deleteEntity(entity: Entity) {
    return this.db.object<Entity>('/TenantData/' + entity.tenantKey + '/Entities/' + entity.key).remove();
  }


  createCompany(request) {
    return this.http.post(this.sEndpoint + 'Entity/CreateCompany', request);
  }


  updateCompany(request) {
    return this.http.post(this.sEndpoint + 'Entity/UpdateCompany', request);
  }




  fetchEntitiesOnce(tenantKey: string) {
    return this.db.list<Entity>('/TenantData/' + tenantKey + '/Entities/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchEntities(tenantKey: string) {
    return this.db.list<Entity>('/TenantData/' + tenantKey + '/Entities/').snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
        ), take(1)
      );
  }

  fetchEntity(tenantKey: string, companyKey: string) {
    return this.db.object<Entity>('/TenantData/' + tenantKey + '/Entities/' + companyKey).snapshotChanges()
      .pipe(
        map(a => ({ key: a.key, ...a.payload.val() })
        ), take(1)
      );
  }

  getSelectedEntityChildren(entity: Entity, allEntities: Entity[]) {
    let selectedEntity: Entity = JSON.parse(JSON.stringify(entity));
    let entities: Entity[] = JSON.parse(JSON.stringify(allEntities));
  }

  structureEntities(entities: Entity[], rootCompanyKey: string) {
    let companyEntites: Entity[] = [];
    for (let i = 0; i < entities.length; i++) {
      let entity = entities[i];
      if (entity.companyEntityKey == rootCompanyKey) {
        companyEntites.push(entity);
      }
    }
    return companyEntites;
  }

}
