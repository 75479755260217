import { translateText } from "../../../features/survey/survey.component";
import { Expression } from "@angular/compiler";
import { getCurrencySymbol } from "@ex/module/survey-x/ui/builder/currency-dropdown/currency-dropdown.component";
import { ExpressionValidator } from "survey-core";

/**
 * Updates the HTML representation of a long text question.
 * @param options - The options for updating the question HTML.
 */
export function updateLongTextQuestionHTML(options: any, locale?: any) {
  var question = options.question;

  var presentation = options.htmlElement.querySelector('[role="presentation"]');
  presentation.innerHTML = "";
  presentation.className = "question";

  // Question Body
  var body = document.createElement("div");
  body.className = "question-body flex flex-col";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-3 text-start";
  inputTitle.innerHTML = translateText('Write an answer:', locale);

  var min = 0;
  var max = 5000;

  if (question['validators'] != null && question['validators'].length > 0) {
    min = question['validators'][0]['minLength'] || 0;
    max = question['validators'][0]['maxLength'] || 5000;
  }

  // Question Input
  var input = document.createElement("textarea");
  input.className = "question-input w-full mb-2";
  input.rows = 5;
  input.maxLength = max;
  input.placeholder = options.question.placeholder;

  input.oninput = function () {
    question.value = input.value;
  };

  if (options.question.value != null && options.question.value !== "") {
    input.value = options.question.value;
  }

  //Add character counter
  var counter = document.createElement("span");
  counter.className = "text-end text-gray-500 text-sm";
  counter.style.textAlign = "end";
  counter.innerHTML = input.value.length + "/" + max;
  input.addEventListener("input", function () {
    counter.innerHTML = input.value.length + "/" + max;
  });

  body.appendChild(inputTitle);
  body.appendChild(input);
  body.appendChild(counter);

  presentation.appendChild(body);


}

/**
 * Updates the HTML representation of a boolean question.
 * @param options - The options for updating the question HTML.
 */
export function updateBooleanQuestionHTML(options: any, locale?: any) {
  var question = options.question;

  var presentation = options.htmlElement.querySelector('[role="presentation"]');
  presentation.innerHTML = "";
  presentation.className = "question";

  // Question Body
  var body = document.createElement("div");
  body.className = "question-body";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-3 text-start";
  inputTitle.innerHTML = translateText('Select an option:', locale);

  var container = document.createElement("div");
  container.className = "flex justify-center items-center gap-5 py-4";

  var trueContainer = document.createElement("div");
  trueContainer.className = "flex flex-col items-center gap-4 py-3 w-full boolean-question cursor-pointer";
  trueContainer.innerHTML = "<i class='fa-light fa-thumbs-up text-4xl'></i>";

  var trueText = document.createElement("p");
  trueText.innerHTML = question.labelTrue;

  var falseContainer = document.createElement("div");
  falseContainer.className = "flex flex-col items-center gap-4 py-3 w-full boolean-question cursor-pointer";
  falseContainer.innerHTML = "<i class='fa-light fa-thumbs-down text-4xl'></i>";

  var falseText = document.createElement("p");
  falseText.innerHTML = question.labelFalse;

  trueContainer.appendChild(trueText);
  falseContainer.appendChild(falseText);

  trueContainer.onclick = function () {
    question.value = true;
    trueContainer.classList.add("boolean-question-selected");
    falseContainer.classList.remove("boolean-question-selected");
  };

  falseContainer.onclick = function () {
    question.value = false;
    falseContainer.classList.add("boolean-question-selected");
    trueContainer.classList.remove("boolean-question-selected");
  };

  container.appendChild(trueContainer);
  container.appendChild(falseContainer);

  body.appendChild(inputTitle);
  body.appendChild(container);

  presentation.appendChild(body);

  if (options.question.value !== undefined) {
    if (options.question.value === true) {
      trueContainer.classList.add("boolean-question-selected");
    } else {
      falseContainer.classList.add("boolean-question-selected");
    }
  }
}

/**
 * Updates the HTML for a date input question.
 * @param options - The options for updating the question HTML.
 */
export function updateDateInputQuestionHTML(options: any, locale?: any) {
  var question = options.question;

  // Clear the existing presentation HTML
  var presentation = options.htmlElement.querySelector('[role="presentation"]');
  // presentation.innerHTML = "";
  presentation.innerHTML = "";
  presentation.className = "question";

  // Create the question body container
  var body = document.createElement("div");
  body.className = "question-body";

  // Create the input title element
  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-3 text-start";
  inputTitle.innerHTML = translateText('Enter date:', locale);
  body.appendChild(inputTitle);

  // Create the date input element(s)
  if (question['dateType'] !== "range") {
    // Single date input
    var inputFrom = document.createElement("div");
    inputFrom.className = "block relative";

    var spanText = document.createElement("span");
    spanText.className = "px-4 bg-white h-10 z-10 text-zen text-lg leading-10";
    spanText.innerHTML = question.value ? new Date(question.value).toLocaleDateString("en-GB") : "DD/MM/YYYY";
    var spanContainer = document.createElement("div");
    spanContainer.className = "absolute flex flex-row items-center justify-start px-1 left-0 top-1/2 bg-white h-0 z-10 ";
    spanContainer.appendChild(spanText)
    var input = document.createElement("input") as any;
    input.className = "question-input w-full p-3";
    input.type = "date";
    if (question.min) {
      input.min = new Date(question.min).toLocaleDateString('fr-CA');
    }
    if (question.max) {
      input.max = new Date(question.max).toLocaleDateString('fr-CA');
    }

    input.placeholder = translateText('Enter date', locale);
    input.oninput = function () {
      spanText.innerHTML = !input.value ? "DD/MM/YYYY" : new Date(input.value).toLocaleDateString("en-GB");
      question.value = input.value;
    };



    inputFrom.onclick = function () { input.showPicker(); };
    inputFrom.append(input)
    inputFrom.append(spanContainer)
    body.appendChild(inputFrom);

    if (options.question.value != null && options.question.value != "") {
      input.value = options.question.value;
    }
  } else {
    // Date range input
    const inputContainer = document.createElement("div");
    inputContainer.className = "flex gap-3";

    // Create "From" input
    const inputFrom = createInput("From", question.max);
    inputContainer.appendChild(inputFrom.container);

    // Create "To" input
    const inputTo = createInput("To", question.max);
    inputContainer.appendChild(inputTo.container);

    // Append the container to the body
    body.appendChild(inputContainer);

    // Function to create a date picker input
    function createInput(label: string, maxDate: string | undefined): { container: HTMLDivElement, input: HTMLInputElement, spanText: HTMLSpanElement } {
      const inputContainer = document.createElement("div");
      inputContainer.className = "flex items-center justify-between question-input w-full p-3 gap-5";

      const inputInnerContainer = document.createElement("div");
      inputInnerContainer.className = "block relative w-full";

      const inputSpan = document.createElement("span");
      inputSpan.className = "text-zen text-sm";
      inputSpan.innerHTML = translateText(label, locale);

      const spanText = document.createElement("span");
      spanText.className = "bg-white z-10 text-zen text-sm w-4/5";
      spanText.innerHTML = label === "From" ? (question.value ? new Date(question.value[0]).toLocaleDateString("en-GB") : "DD/MM/YYYY") : (question.value ? new Date(question.value[1]).toLocaleDateString("en-GB") : "DD/MM/YYYY");


      const spanContainer = document.createElement("div");
      spanContainer.className = "absolute flex flex-row items-center justify-start w-full left-0 top-1/2 bg-white h-0 z-10";

      const input = document.createElement("input") as HTMLInputElement;
      input.className = "w-full";
      input.type = "date";
      input.style.textTransform = "uppercase";

      if (maxDate) {
        input.max = new Date(maxDate).toLocaleDateString('fr-CA');
      }

      input.oninput = function () {
        const fromDate = label === "From" ? input.value : inputFrom.input.value;
        const toDate = label === "To" ? input.value : inputTo.input.value;

        // Validate From date not to exceed To date
        if (label === "From" && toDate && fromDate > toDate) {
          inputTo.input.min = fromDate;
          return;
        }

        // Validate To date not to be less than From date
        if (label === "To" && fromDate && toDate < fromDate) {
          inputFrom.input.max = toDate;
          return;
        }

        spanText.innerHTML = !input.value ? "DD/MM/YYYY" : new Date(input.value).toLocaleDateString("en-GB");

        // Reset the min and max attributes if no violation
        inputFrom.input.max = question.max;
        inputTo.input.min = question.min;

        question.value = [fromDate, toDate];
      };

      spanText.onclick = function () {
        input.click();
      };

      spanContainer.appendChild(spanText);
      inputInnerContainer.appendChild(spanContainer);
      inputInnerContainer.appendChild(input);
      inputContainer.appendChild(inputSpan);
      inputContainer.appendChild(inputInnerContainer);

      return { container: inputContainer, input, spanText };
    }

    // If there is initial values for the range
    if (options.question.value != null && options.question.value != "") {
      inputFrom.input.value = options.question.value[0];
      inputFrom.spanText.innerHTML = options.question.value[0] ? new Date(options.question.value[0]).toLocaleDateString("en-GB") : "DD/MM/YYYY";
      inputTo.input.value = options.question.value[1];
      inputTo.spanText.innerHTML = options.question.value[1] ? new Date(options.question.value[1]).toLocaleDateString("en-GB") : "DD/MM/YYYY";
    }



    // options.question.surveyValue.onValidateQuestion.add((survey, options) => {
    //   if (options.name === question.name) {
    //     //Both dates are required
    //     if (options.question.isRequired == true && (options.value[0] === "" || options.value[1] === "")) {
    //       options.error = translateText('Both dates are required', locale);
    //     }

    //     //From date should be less than To date
    //     if (options.value != null && options.value[0] != null && options.value[1] != null && options.value[0] !== "" && options.value[1] !== "") {
    //       if (options.value[0] > options.value[1]) {
    //         options.error = translateText('From date should be less than To date', locale);
    //       }
    //     }
    //   }
    // });
  }

  // Append the question body to the presentation container
  presentation.appendChild(body);
}

/**
 * Updates the HTML for a number input question.
 * @param options - The options for updating the question HTML.
 */
export function updateNumberInputQuestionHTML(options: any, locale?: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // Set the question body class
  body.className = "question-body";

  // Create the input title element
  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-1 text-start";
  inputTitle.innerHTML = translateText('Enter number:', locale);

  // Clear the existing HTML and append the input title
  body.innerHTML = "";
  body.appendChild(inputTitle);

  // Create the container for the number input
  var container = document.createElement("div");
  container.className = "flex items-center gap-3 py-4";

  // Create the input element
  var input = document.createElement("input");
  input.className = "question-input text-zen-primarydefault text-2xl w-full";
  input.type = "number";
   
  if (options.question.maxLength && options.question.maxLength != "-1") {
    input.addEventListener('input', function () {
      if (input.value.length > options.question.maxLength) {
        input.value = input.value.slice(0, options.question.maxLength);
      }
    });

  }
  input.placeholder = translateText('Enter number', locale);

  var invalidChars = ["-", "+", "e"];

  input.addEventListener("keydown", function (e) {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  });

  // Set the input value to the question value on input
  input.oninput = function () {
    options.question.value = input.value;
  };

  // Add specific elements based on the number input type
  switch (options.question['numberInputType']) {
    case "currency":
      // Create the currency label
      var currencyLabel = document.createElement("label");
      currencyLabel.className = "w-6 text-[#7D92B5] text-2xl";
      currencyLabel.innerHTML = getCurrencySymbol(options.question['numberInputCurrency'] || "USD");

      // Append the currency label and input to the container
      container.appendChild(currencyLabel);
      container.appendChild(input);
      break;

    case "percentage":
      // Create the percentage sign element
      var percentageSign = document.createElement("i");
      percentageSign.className = "fa-regular fa-percent w-6 text-[#7D92B5] text-2xl";

      // Append the input and percentage sign to the container
      container.appendChild(input);
      container.appendChild(percentageSign);
      break;

    case "number":
      // Append the input to the container
      container.appendChild(input);
      break;

    case "phone-number":
      inputTitle.innerHTML = translateText('Enter phone number:', locale);
      input.placeholder = translateText('Enter phone number', locale);
      container.appendChild(input);
      break;
  }

  // Append the container to the question body
  body.appendChild(container);

  // Set the input value if it exists
  if (options.question.value != null && options.question.value != "") {
    input.value = options.question.value;
  }
}

/**
 * Updates the HTML for an email input question.
 * @param options - The options for updating the question HTML.
 */
export function updateEmailInputQuestionHTML(options: any, locale?: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  // Set the question body class
  body.className = "question-body";

  // Create the input title element
  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-1 text-start";
  inputTitle.innerHTML = translateText('Enter email:', locale);

  // Insert the input title as the first child of the question body
  body.insertBefore(inputTitle, body.childNodes[0]);
}

export function updateSliderQuestionHTML(options: any, locale?: any) {
  var body = options.htmlElement.querySelector('[role="presentation"]');
  if (!body) body = options.htmlElement as HTMLDivElement;

  body.className = "question-body";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-1 text-start";
  inputTitle.innerHTML = translateText('Range', locale);

  body.insertBefore(inputTitle, body.childNodes[0]);
}

export function updateShortTextQuestionHTML(options: any, locale?: any) {
  var question = options.question;

  var presentation = options.htmlElement.querySelector('[role="presentation"]');
  presentation.innerHTML = "";
  presentation.className = "question";

  //Question Body
  var body = document.createElement("div");
  body.className = "question-body flex flex-col";

  var inputTitle = document.createElement("p");
  inputTitle.className = "input-title mb-3 text-start";
  inputTitle.innerHTML = translateText('Write an answer:', locale);

  var min = 0;
  var max = 300;

  if (question['validators'] != null && question['validators'].length > 0) {
    min = question['validators'][0]['minLength'] || 0;
    max = question['validators'][0]['maxLength'] || 300;
  }

  //Question Input
  var input = document.createElement("textarea");
  input.className = "question-input w-full mb-2";
  input.rows = 5;
  input.maxLength = max;
  input.placeholder = options.question.placeholder;
  input.oninput = function () {
    question.value = input.value;
  }


  if (options.question.value != null && options.question.value != "")
    input.value = options.question.value;

  //Add character counter
  var counter = document.createElement("span");
  counter.className = "text-end text-gray-500 text-sm";
  counter.style.textAlign = "end";
  counter.innerHTML = input.value.length + "/" + max;
  input.addEventListener("input", function () {
    counter.innerHTML = input.value.length + "/" + max;
  });

  body.appendChild(inputTitle);
  body.appendChild(input);
  body.appendChild(counter);

  presentation.appendChild(body);

}
