import { Injectable } from '@angular/core';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { TranslatePipe } from '@ex/module/shared/pipes/translate.pipe';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export interface ExcelSection {
  key: string,
  name: string,
  questions: ExcelQuestion[],
}

export interface ExcelQuestion {
  key: string,
  name: string,
  number: number,
  totalComments: number,
  comments: ExcelComment[],
  questionType: string,
}

export interface ExcelComment {
  name: string,
  sentiment: string,
  topics: string,
  flagged: string,
  answer: string
}

export interface CommentExcelConfig {
  filename?: string
  sections: ExcelSection[],
  skipSentiment?: boolean,
  language?: string,
}


@Injectable({
  providedIn: 'root'
})
export class ExcelCommentDownloadService {

  language = "EN";
  readonly refs = ['C', 'D', 'E', 'F'];
  readonly columnNames = [
    { title: 'Sentiment', field: 'sentiment' },
    { title: 'Topics', field: 'topics' },
    { title: 'Flagged', field: "flagged" },
    { title: 'Respondent Answer', field: 'answer' }
  ];
  readonly serialColumn = "A"
  readonly mainColumn = "B"
  readonly secondaryColumn = "C"
  currentRow = 1;
  commentCount = 1;
  skipSentiment = false;

  colors = {
    neutral: "FFC51C",
    positive: "3EB75B",
    negative: "FF4530",
    no_sentiment: "F6F8FA",
    sectionHeader: "DFE4EC",
    sectionTitle: "46597A",
    sentimentHeader: "009999",
    questionBg: "F2F2F2"
  }


  testConfig = {
    sections: [
      {
        name: "Innovation",
        questions: [
          {
            name: "I am surrounded by a supportive environment that allows me to unleash my creativity",
            comments: [
              {
                name: "Lots of information but not presented in a productive way",
                sentiment: "Neutral",
                topics: "Neutral	Innovation, Management",
                flagged: "Yes",
                answer: "Agree"
              },
              {
                name: "My manager expresses public thanks whenever I achieve a new accomplishment which makes me feel appreciated",
                sentiment: "Positive",
                topics: "Learning & Development",
                flagged: "No",
                answer: "Strongly Agree"
              },
              {
                name: "My peers don’t always support me whenever I succeed in my work which makes me feel discouraged and un-recognized by them",
                sentiment: "Negative",
                topics: "Work Life",
                flagged: "No",
                answer: "Disagree"
              },
              {
                name: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec.",
                sentiment: "Negative",
                topics: "Recognition, Feedback",
                flagged: "Yes",
                answer: "Strongly Disagree"
              }
            ]
          },
          {
            name: "My company encourages employees to adopt new creative methods at work",
            comments: [
              {
                name: "i learned a lot of creative ways to think outside of the box in my company and that's the most thing that makes me engaged with the work i do!",
                sentiment: "Neutral",
                topics: "Communication, Innovation",
                flagged: "Yes",
                answer: "Agree"
              },
              {
                name: "usually they give us unique tools to implement our work and think out of the box",
                sentiment: "Positive",
                topics: "Autonomy, Management",
                flagged: "Yes",
                answer: "Neutral"
              }
            ]
          },
          {
            name: "I am inspired to transform knowledge and ideas into new products and processes",
            comments: [
              {
                name: "Management always inspire us to think of new methods to apply and enhance our work",
                sentiment: "Positive",
                topics: "Systems & Resources",
                flagged: "No",
                answer: "Agree"
              }
            ]
          },
          {
            name: "My company's innovative spirit drives employees to think out of the box",
            comments: [
              {
                name: "the creativity my company has is impossible !",
                sentiment: "Neutral",
                topics: "Recognition, Feedback",
                flagged: "No",
                answer: "Neutral"
              },
              {
                name: "I am inspired and challenged to learn and make new items and products",
                sentiment: "Negative",
                topics: "Leadership",
                flagged: "No",
                answer: "Strongly Agree"
              }
            ]
          }
        ]

      } as ExcelSection,
      {
        name: "Motivation",
        questions: [
          {
            name: "I am surrounded by a supportive environment that allows me to unleash my creativity",
            comments: [
              {
                name: "Lots of information but not presented in a productive way",
                sentiment: "Neutral",
                topics: "Neutral	Innovation, Management",
                flagged: "Yes",
                answer: "Agree"
              },
              {
                name: "My manager expresses public thanks whenever I achieve a new accomplishment which makes me feel appreciated",
                sentiment: "Positive",
                topics: "Learning & Development",
                flagged: "No",
                answer: "Strongly Agree"
              },
              {
                name: "My peers don’t always support me whenever I succeed in my work which makes me feel discouraged and un-recognized by them",
                sentiment: "Negative",
                topics: "Work Life",
                flagged: "No",
                answer: "Disagree"
              },
              {
                name: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec.",
                sentiment: "Negative",
                topics: "Recognition, Feedback",
                flagged: "Yes",
                answer: "Strongly Disagree"
              }
            ]
          },
          {
            name: "My company encourages employees to adopt new creative methods at work",
            comments: [
              {
                name: "i learned a lot of creative ways to think outside of the box in my company and that's the most thing that makes me engaged with the work i do!",
                sentiment: "Neutral",
                topics: "Communication, Innovation",
                flagged: "Yes",
                answer: "Agree"
              },
              {
                name: "usually they give us unique tools to implement our work and think out of the box",
                sentiment: "Positive",
                topics: "Autonomy, Management",
                flagged: "Yes",
                answer: "Neutral"
              }
            ]
          },
          {
            name: "I am inspired to transform knowledge and ideas into new products and processes",
            comments: [
              {
                name: "Management always inspire us to think of new methods to apply and enhance our work",
                sentiment: "Positive",
                topics: "Systems & Resources",
                flagged: "No",
                answer: "Agree"
              }
            ]
          },
          {
            name: "My company's innovative spirit drives employees to think out of the box",
            comments: [
              {
                name: "the creativity my company has is impossible !",
                sentiment: "Neutral",
                topics: "Recognition, Feedback",
                flagged: "No",
                answer: "Neutral"
              },
              {
                name: "I am inspired and challenged to learn and make new items and products",
                sentiment: "Negative",
                topics: "Leadership",
                flagged: "No",
                answer: "Strongly Agree"
              }
            ]
          }
        ]

      } as ExcelSection
    ]

  } as CommentExcelConfig


  constructor(private translatePipe: TranslatePipe) {

  }


  generate(request: CommentExcelConfig = null) {
    this.currentRow = 1;
    this.commentCount = 1;
    this.language = request.language ?? this.language;

    request = request || {
      sections: this.testConfig.sections,
      filename: "comments"
    } as CommentExcelConfig

    this.skipSentiment = request.skipSentiment;

    let fileName = request.filename?.replace(".xlsx", "") || "comments";
    ;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');

    // Add headers
    const headers = ["Comments"];
    worksheet.insertRow(this.currentRow, headers);
    const commentsHeaderCell = worksheet.getCell(`A${this.currentRow}`);
    commentsHeaderCell.font = {
      name: 'Calibri',
      color: { argb: '000000' },
      bold: true,
      family: 2,
      size: 16, // Adjust the size as needed
    };
    this.currentRow += 2;

    // Set column widths
    worksheet.getColumn('A').width = 15
    worksheet.getColumn('B').width = 120

    if (!this.skipSentiment) {
      worksheet.getColumn('C').width = 20
      worksheet.getColumn('D').width = 30
      worksheet.getColumn('E').width = 10
      worksheet.getColumn('F').width = 25
    }

    for (let section of request.sections) {

      if(section.questions.length)
      {

      // Create section / Factor header
      worksheet.mergeCells(`A${this.currentRow}:B${(this.currentRow + 1)}`);
      let sectionHeaderCell = worksheet.getCell(`B${(this.currentRow + 1)}`)

      this.styleFactorHeader(sectionHeaderCell, section);

      if (!this.skipSentiment) {
        // Total Comments
        worksheet.mergeCells(`C${this.currentRow}:F${(this.currentRow)}`);
        let totalCommentsHeader = worksheet.getCell(`F${(this.currentRow)}`);

        this.styleTotalCommentsHeader(totalCommentsHeader, section);

        // Comments Metrics

        this.generateSentimentColumnHeaders(worksheet, this.currentRow);
      }

      // +2 because of the row span applied with merge cells for factor header.
      this.currentRow += 2;

      // Loop over the question

      this.generateQuestions(section, worksheet);

    }

    }

    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }


private generateQuestions(section: ExcelSection, worksheet) {
  for (let question of section.questions) {
    // Add Question Header
    this.addQuestionHeader(worksheet, question);

    // Loop over comments
    for (let comment of question.comments) {
      // Add Comment Header
      this.addCommentHeader(worksheet, comment);

      // Add Comment Data
      this.addCommentData(worksheet, comment);

      this.currentRow += 1;
    }
  }
}

private addQuestionHeader(worksheet, question: ExcelQuestion) {
  // Add Question Serial Column
  this.addCell(worksheet, this.serialColumn, `Question ${question.number}`, true, true);

  // Add Question Main Column
  this.addCell(worksheet, this.mainColumn, question.name, true, true);

  this.currentRow += 1;
}

private addCommentHeader(worksheet, comment: ExcelComment) {
  // Add Comment Serial Column
  this.addCell(worksheet, this.serialColumn, `Comment #${this.commentCount++}`, true, false);

  // Add Comment Main Column
  this.addCell(worksheet, this.mainColumn, 'Answer', true, false);

  this.currentRow += 1;
}

private addCommentData(worksheet, comment: ExcelComment) {
  // Add Comment Data Serial Column
  this.addCell(worksheet, this.serialColumn, comment.name, false, false);

  // Add Comment Data Main Column (with maximum of 500 characters)
  var limitedAnswer="";
  if (typeof comment.answer === 'object')
  {
    const elementNamesList = Object.values(comment.answer)
    .filter((element: any) => element && element.name) // Use 'any' type here
    .map((element: any, index: number) => `${index + 1}. ${element.name}`)
    .join('\n');

      limitedAnswer = elementNamesList;
  }
  else
  {
    limitedAnswer = comment.answer.substring(0, 500);
  }
  this.addCell(worksheet, this.mainColumn, limitedAnswer, false, false);

  // Add Sentiment Data if not skipped
  if (!this.skipSentiment) {
    for (let i = 0; i < this.refs.length; i++) {
      this.addCell(worksheet, this.refs[i], comment[this.columnNames[i].field], false, true);
    }
  }

  this.currentRow += 1;
}

private addCell(worksheet, column, value, isHeader, isSentiment) {
  let cell = worksheet.getCell(`${column}${this.currentRow}`);
  cell.value = value;
  cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true };

  if (isHeader) {
    cell.font = {
      name: 'Calibri',
      color: { argb: isSentiment ? 'FFFFFF' : '000000' },
      bold: true,
      family: 2,
      size: 11,
    };

    if (isSentiment) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.colors.sentimentHeader },
      };
    } else {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.colors.questionBg },
      };
    }
  } else {
    cell.border = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    };
  }
}

  getSentimentValue(sentiment: any): any {

    // TODO: Apply Sentiment localisation here
    const translation = this.translatePipe.transform(`sentiment_${sentiment}`, this.language, ModuleName.Shared);
    return translation;

    switch (sentiment) {
      case 'no_sentiment':
        return 'No Sentiment';
      default:
        return sentiment;
    }

  }

  private generateSentimentColumnHeaders(worksheet: any, currentRow: number) {
    for (let i = 0; i < this.refs.length; i++) {
      let sentimentHeader = worksheet.getCell(`${this.refs[i]}${(currentRow + 1)}`);
      sentimentHeader.value = this.columnNames[i].title;
      sentimentHeader.alignment = { vertical: 'middle', horizontal: 'left' };
      sentimentHeader.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: this.colors.sentimentHeader },
      };
      sentimentHeader.font = {
        name: 'Calibri',
        color: { argb: 'FFFFFF' },
        family: 2,
        bold: true,
        size: 11,
      };
    }
  }

  private styleTotalCommentsHeader(totalCommentsHeader: any, section: ExcelSection) {
    totalCommentsHeader.value = `${section.questions.flatMap(x => x.comments?.length || 0).reduce((p, c) => p + c, 0)} Comments`;
    totalCommentsHeader.alignment = { vertical: 'middle', horizontal: 'center' };
    totalCommentsHeader.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: this.colors.sectionHeader },
    };
    totalCommentsHeader.font = {
      name: 'Calibri',
      bold: false,
      color: { argb: this.colors.sentimentHeader },
      family: 2,
      size: 11,
    };
  }

  private styleFactorHeader(sectionHeaderCell: any, section: ExcelSection) {
    sectionHeaderCell.value = section.name;
    sectionHeaderCell.alignment = { vertical: 'middle', horizontal: 'left' };
    sectionHeaderCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: this.colors.sectionHeader },
    };
    sectionHeaderCell.font = {
      name: 'Calibri',
      color: { argb: this.colors.sectionTitle },
      bold: true,
      family: 2,
      size: 14,
    };
  }
}
