import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { DrilldownComponent } from '../drilldown/drilldown.component';
import { SpecialQuestionAnswersDrawerComponent } from '../special-question-answers-drawer/special-question-answers-drawer.component';
import { IndividualResponsesModalComponent } from '../individual-responses-modal/individual-responses-modal.component';
import { ExcelDownloadService } from '@ex/module/survey-x/data-access/services/excel-download.service';
import { SjsTranslatePipe } from '@ex/module/survey-x/utils/pipes/sjs-translate.pipe';
import { SpecialQuestionExcelDownloadService } from '@ex/module/survey-x/data-access/services/special-question-excel-download.service';
import { getCurrencySymbol } from "@ex/module/survey-x/ui/builder/currency-dropdown/currency-dropdown.component";
import { QuestionTypes } from '@ex/module/survey-x/utils/question-types';
import { CriteriaResolverService } from '@ex/module/survey-x/data-access/services/criteria-resolver.service';
import { fullWidthAnimation, inOutAnimation, rowlistAnimation } from '@ex/module/shared/animations/allAnimations';
import { ChartColorType, ChartColorsAssignComponent } from '../chart-colors-assign/chart-colors-assign.component';
import { AnalyticsService } from '@ex/module/survey-x/data-access/services/analytics.service';
import { ChartColorsGenerationService } from '@ex/module/survey-x/data-access/services/chart-colors-generation.service';
import { CriteriaType } from '@ex/module/shared/enums/CriteriaType.enum';


@Component({
  selector: 'sx-question-chart',
  templateUrl: './question-chart.component.html',
  styleUrls: ['./question-chart.component.scss'],
  providers: [SjsTranslatePipe, ChartColorsGenerationService],
  animations: [rowlistAnimation, fullWidthAnimation, inOutAnimation]
})
export class QuestionChartComponent extends BaseComponent implements OnInit {

  @Input()
  question;

  @Input()
  pageLines;

  @Input()
  export: boolean = false;

  @Input()
  data: any

  @Input()
  questionNo;

  @Input()
  language = "EN";
  surveyCriterias;
  chartOptions: boolean = false;
  openColors: boolean = false;
  chartColors: { color: string, dynamicColors: any[], linearColors: any[], colorType: ChartColorType, } = {
    color: '#0082C6',
    dynamicColors: [],
    colorType: ChartColorType.Solid,
    linearColors: ['#3F9DB0', '#B96C7F']
  };
  @Input('surveyCriterias') set criterias(criterias) {
    this.surveyCriterias = criterias.filter(x=> x.type != CriteriaType.OpenText);
  }

  @Input()
  exporting: boolean = false;
  @Input() colors = ['#3249F6', '#FE501B', '#00C1D3', '#9125D2', '#3DA342', '#D21B6B', '#338EF7', '#FFB53C', '#A9A800', '#81B3B3'];
  noneItemCount: any;
  @Input()
  filters;







  criteria = '';
  exportedCriterias = [];
  constraints = [
    { min: 0, max: 6, text: 'Unlikely', color: '#FF45301A' },
    { min: 7, max: 8, text: 'Neutral', color: '#FF96001A' },
    { min: 9, max: 10, text: 'Likely', color: '#3EB75B1A' }
  ];

  constructor(private excelDownloadSerivce: ExcelDownloadService
    , private specialQuestionExcelDownloadSerivce: SpecialQuestionExcelDownloadService
    , private sjsTranslatePipe: SjsTranslatePipe, private chartColorsGenerationService: ChartColorsGenerationService
    , private analyticsService: AnalyticsService) { super() }

  ngOnInit(): void {
    console.log("question:", this.question)
    // this.openChartColors();
    this.addSubscription(this.question['breakOutChange'].subscribe(x => {
      if (x)
        this.criteria = x;
    }));
    this.chartColors.dynamicColors = this.colors;
  }

  getCount(category: string, group: string = "") {
    if (!this.data?.groupedChart) {
      const item = this.data?.pie?.find(x => x.name.toLowerCase() == (typeof category === 'string' ? category.toLowerCase() : category));
      return {
        percentage: item?.value ?? 0,
        count: item?.value1 ?? 0,
      }
    }
    // let counts = this.data.bar.series[0].counts[this.data.categories.indexOf(category)];
    // let percentages = this.data.bar.series[0].data[this.data.categories.indexOf(category)];

  }


  getType(question: any) {

    if (question.type == "comment") {
      return { text: "Long Text", icon: "fa-comment-lines" }
    }
    if (question.type == 'datepicker') {

      return { text: "Date", icon: "fa-calendar" }
    }

     if (question.type == "text") {
      if (question.inputType) {
        if (question.inputType == 'number') {
          if (question.numberInputType) {
            if (question.numberInputType == 'percentage') {
              return { text: "Percentage", icon: "fa-percent" }
            }
            if (question.numberInputType == 'currency') {
              return { text: "Currency", icon: "fa-dollar-sign" }
            }
            if (question.numberInputType == 'phone-number') {
              return { text: "Phone Number", icon: "fa-phone" }
            }
          }
          else {
            return { text: "Number", icon: "fa-hashtag" }
          }
        }
        if (question.inputType == 'email') {
          return { text: "Email", icon: "fa-at" }
        }
        if (question.inputType == 'date') {
          if (question.dateType == 'range') {
            return { text: "Date Range", icon: "fa-calendar-range" }
          }
          else {
            return { text: "Date", icon: "fa-calendar" }
          }
        }
      }
      else {
        return { text: "Short Text", icon: "fa-comment-minus" }
      }
    }


    if (question.type == "rating") {
      if (question.rateType == "stars") {
        return { text: "Star Rating", icon: "fa-star" }
      }
      if (question.rateType == "fa-heart") {
        return { text: "Heart Rating", icon: "fa-heart" }
      }
      if (question.rateType == "fa-thumbs-up") {
        return { text: "Thumbs Up Rating", icon: "fa-thumbs-up" }
      }
      if (question.rateType == "smileys") {
        return { text: "Smiley Rating", icon: "fa-face-grin-wide" }
      }
      if (question.rateType == "nps") {
        return { text: "NPS Rating", icon: "fa-bars-progress" }
      }
      if (question.rateType == "enps") {
        return { text: "eNPS Rating", icon: "fa-bars-progress" }
      }
      else {
        return { text: "Rating Scale", icon: "fa-chart-simple" }
      }
    }

    if (question.type == "checkbox") {
      return { text: "Multiple Choices (Multiple Selection)", icon: "fa-list" }
    }
    if (question.type == "radiogroup") {
      return { text: "Multiple Choices (Single Selection)", icon: "fa-list" }
    }
    if (question.type == "tagbox") {
      return { text: "Dropdown (Multiple)", icon: "fa-list-dropdown" }
    }
    if (question.type == "dropdown") {
      return { text: "Dropdown (Single)", icon: "fa-list-dropdown" }
    }
    if (question.type == "file") {
      return { text: "File Upload", icon: "fa-file-arrow-up" }
    }
    if (question.type == "ranking") {
      return { text: "Ranking", icon: "fa-list-ol" }
    }
    if (question.type == "imagepicker") {
      if (question.isIconChoice) {
        if (question.multiSelect) {
          return { text: "Icon Choice (Multiple Selection)", icon: "fa-smile" }
        }
        else {
          return { text: "Icon Choice (Single Selection)", icon: "fa-smile" }
        }
      }
      else {
        if (question.multiSelect) {
          return { text: "Image Choice (Multiple Selection)", icon: "fa-image" }
        }
        else {
          return { text: "Image Choice (Single Selection)", icon: "fa-image" }
        }
      }
    }
    if (question.type == "boolean") {
      return { text: "Binary(Yes/No)", icon: "fa-circle-half-stroke" }
    }
    if (question.type == "matrix") {
      if (question.isMultiSelect == true) {
        return { text: "Matrix (Multiple Selection)", icon: "fa-table" }
      }
      else {
        return { text: "Matrix (Single Selection)", icon: "fa-table" }
      }
    }
    return { text: null, icon: null }

  }

  downloadExcelFile(question) {

    if (question.type == 'text' || question.type == 'comment' || question.type == 'datepicker') {
      var data: any[] = [];
      var respondents = this.data?.data;
      switch (question.numberInputType) {
        case "percentage":
          respondents.forEach(element => {
            data.push({
              category: element.category,
              value: element.value + "%"
            })
          });
          break;

        case "currency":
          respondents.forEach(element => {
            data.push({
              category: element.category,
              value: element.value + getCurrencySymbol(question.numberInputCurrency || "USD")
            })
          });
          break;

        default:
          respondents.forEach(element => {
            data.push({
              category: element.category,
              value: element.value
            })
          });
          break;
      }
      this.specialQuestionExcelDownloadSerivce.generate(data, this.sjsTranslatePipe.transform(question.title ?? question.name, this.language));
    }

    else {
      var data: any[] = [];
      switch (question.type) {

        case "matrix":
          this.data.groupedChart = false;
          question.rows.forEach(row => {
            question.columns.forEach(col => {
              const matrixData = this.getMatrixData(row?.value ?? row, col?.value ?? col);
              data.push({
                category: row.text ? this.sjsTranslatePipe.transform(row.text, this.language) : row.value ? row.value : row,
                group: col.text ? this.sjsTranslatePipe.transform(col.text, this.language) : col.value ? col.value : col,
                count: matrixData.count,
                percentage: matrixData.percentage.toFixed(1) + "%",
              })
            });
          });
          break;

        case "ranking":
          this.data.groupedChart = false;
          question.choices.forEach((rank) => {
            question.choices.forEach((element, index) => {
              const matrixData = this.getMatrixData(rank?.value ?? rank, index + 1);
              data.push({
                category: rank.text ? this.sjsTranslatePipe.transform(rank.text, this.language) : rank.value ? rank.value : rank,
                group: "Rank " + (index + 1),
                count: matrixData.count,
                percentage: matrixData.percentage.toFixed(1) + "%",
              })
            });
          });
          break;

        case "rating":
          var range: any[] = [];
          switch (question.rateType) {
            case "":
            case null:
            case undefined:
              if (question.ratingLabels) {
                question.ratingLabels.forEach(group => {
                  range = this.getRange(group.min, group.max);
                  range.forEach(rateValue => {
                    const getCount = this.getCount(rateValue.toString());
                    data.push({
                      category: rateValue,
                      group: this.sjsTranslatePipe.transform(group.text, this.language),
                      count: getCount.count,
                      percentage: getCount.percentage.toFixed(1) + "%"
                    })
                  })
                });
              }
              else {
                range = this.getRange(1, (question?.rateMax ?? 5));
                range.forEach(rateValue => {
                  const getCount = this.getCount(rateValue.toString());
                  data.push({
                    category: rateValue,
                    group: "",
                    count: getCount.count,
                    percentage: getCount.percentage.toFixed(1) + "%"
                  })
                })
              }

              break;

            case "nps":
            case "enps":
              this.constraints.forEach(constraint => {
                range = this.getRange(constraint.min, constraint.max);
                range.forEach(rateValue => {
                  const getCount = this.getCount(rateValue.toString());
                  data.push({
                    category: rateValue,
                    group: constraint.text,
                    count: getCount.count,
                    percentage: getCount.percentage.toFixed(1) + "%"
                  })
                })
              })
              break;

            default:
              range = this.getRange(1, question?.rateMax ?? 5, true);
              range.forEach(rateValue => {
                const getCount = this.getCount(rateValue.toString());
                data.push({
                  category: rateValue,
                  group: this.sjsTranslatePipe.transform(this.getLabel(rateValue, question), this.language),
                  count: getCount.count,
                  percentage: getCount.percentage.toFixed(1) + "%"
                })
              })
              break;
          }
          break;

        default:
          question.choices.forEach((element) => {

            if (question.criteria != null) {
              data.push({
                category: element.text ? this.sjsTranslatePipe.transform(element.text, this.language) : element.value ? element.value : element,
                group: question.criteria ?? "",
                exportData: question.exportData
              })
            }
            else {
              const getCount = this.getCount(element.value ?? element);
              data.push({
                category: element.text ? this.sjsTranslatePipe.transform(element.text, this.language) : element.value ? element.value : element,
                group: element.group ?? "",
                count: getCount.count,
                percentage: getCount.percentage.toFixed(1) + "%",
              });
            }

          });
      }

      let groupedBy = null;

      if (this.criteria)
        groupedBy = this.ExtractPipe.transform(this.surveyCriterias.find(x => x.key == this.criteria)?.name, this.language);

      this.excelDownloadSerivce.generate(data, this.sjsTranslatePipe.transform(question.title ?? question.name, this.language), this.analyticsService.mapGlobalFilters(this.filters), this.data?.groupedChart, groupedBy);
    }

  }

  getBooleanText(question, choice) {
    const isTrueChoice = choice.value === 'true';
    const label = isTrueChoice ? question.labelTrue : question.labelFalse;

    return label || this.localize('txt_' + choice.value, this.language, this.ModuleNames.SurveyX);
  }

  onFilterClick(question) {

  }

  openSpecialQuestion(question) {
    const sub = this.ZenCDKService.openComponent(SpecialQuestionAnswersDrawerComponent, { question: question, data: this.data, language: this.language, questionNo: this.questionNo }).subscribe(results => { });
    this.addSubscription(sub);
  }

  reverseRatingValues(ratingValues: any) {

    const reversedItems = ratingValues?.slice().reverse();

    return reversedItems;
  }

  getNoneCount(count: any) {
    this.noneItemCount = count;
  }

  getTotalCount() {
    if (!this.data?.groupedChart)
      return this.data.pie.reduce((a, c) => a + c.value, 0)

    return this.data?.bar.series
      .map(s => s.data.reduce((a, c) => a + c, 0))
      .reduce((a, c) => a + c, 0);
  }

  getRange(min = 0, max, reverse = false) {
    let values = [...Array(max + 1).keys()].filter(x => x >= min);

    if (reverse)
      return values.reverse();

    return values;
  }

  getRateIcon(icon) {
    if (icon == 'smileys')
      return 'fa-face-grin-wide';

    if (icon == 'stars')
      return 'fa-star'

    return icon;
  }

  getRateTillIndexes(rateValue: any, question: any): number[] {
    const rating = rateValue;
    return Array.from({ length: question.rateMax ?? 5 }, (_, i) => i).filter(index => index < rating);
  }

  getEmptyStarIndexes(rateValue: any, question: any): number[] {
    const rating = rateValue;
    return Array.from({ length: question.rateMax ?? 5 }, (_, i) => i).filter(index => index >= rating);
  }

  getColor(rateValue: any, question: any) {
    let color = question.ratingLabels?.find(x => x.min <= (rateValue) && x.max >= (rateValue))?.color
    if (color)
      return color;
    else
      return
  }

  getLabel(rateValue: any, question: any) {

    let label = question?.ratingLabels?.find(x => x.min <= (rateValue) && x.max >= (rateValue))?.text
    if (label)
      return label;
    else
      return rateValue
  }

  openIndividualResponsesModal(question, category, group?, groupColor?) {

    switch(question.type)
    {
      case "matrix":
      case "ranking":
           var categoryValue = (typeof category === 'string') ? category : category.value;
           var drillDown = this.data?.drillDown.filter(x => x.categoryKey == categoryValue);
           var sub = this.ZenCDKService.openComponent(IndividualResponsesModalComponent, { question: question, category: category, drillDown, language: this.language, group: group, groupColor }).subscribe(r => {
       
           });
           this.addSubscription(sub);
           break;

           default:
           var drillDown = this.data?.drillDown.filter(x => x.categoryKey == category);
           if (group)
             drillDown = drillDown.filter(x => x.groupKey == group)
           var sub = this.ZenCDKService.openComponent(IndividualResponsesModalComponent, { question: question, category: category, drillDown, language: this.language, group, groupColor }).subscribe(r => {
       
           });
           this.addSubscription(sub);

    }

    

  }

  getMatrixData(category, group) {
    return {
      count: this.data?.bar.series.find(x => x.name == group)?.counts[this.data?.categories.indexOf(category)] ?? 0,
      percentage: this.data?.bar.series.find(x => x.name == group)?.data[this.data?.categories.indexOf(category)] ?? 0
    };
  }

  onCriteriaChanged(criteria) {
    this.criteria = criteria;
    this.data.bar.series = [];
    this.question['breakOutChange'].next(criteria);
  }

  get showGroupBy() {
    return [QuestionTypes.Boolean,
    QuestionTypes.Checkbox,
    QuestionTypes.Dropdown,
    QuestionTypes.TagBox,
    QuestionTypes.RadioGroup,
    QuestionTypes.Rating,
    QuestionTypes.ImagePicker,
    QuestionTypes.Enps,
    ].includes(this.question.type);
  }
  returnLabel(category) {
    let label = this.question.ratingLabels.find(x => parseInt(category) >= x.min && parseInt(category) <= x.max);
    label.array = new Array(label.max - label.min + 1).map((x, index) => label.min + index)
    return label;
  }

  returnRates(min, max) {
    return new Array((max - min) + 1).fill(0).map((x, index) => { return (min + index) });
  }
  returnColor(category, rate) {
    const mainRatingLabel = this.question.ratingLabels?.find(x => parseInt(category) >= x.min && parseInt(category) <= x.max);
    if (parseInt(category) >= rate)
      return mainRatingLabel.color;
    else return '#000'
  }

  identify(index, item) {
    return item;
  }

  openChartColors() {
    this.ZenCDKService.openComponent(ChartColorsAssignComponent, { colors: this.chartColors }).subscribe(x => {
      this.chartColors = x;
    });
  }

  get currentColors() {
    if (this.chartColors.colorType == ChartColorType.Linear)
      return this.chartColorsGenerationService.generateColorsByNumberAndHex(this.chartColors.linearColors[0], this.chartColors.linearColors[1], this.data?.bar.series.length)
    if (this.chartColors.colorType == ChartColorType.LinearNegative)
      return this.chartColorsGenerationService.generateNegativeColorsByHex(this.chartColors.linearColors[0], this.data?.bar.series.length)
    if (this.chartColors.colorType == ChartColorType.LinearPositive)
      return this.chartColorsGenerationService.generatePositiveColorsByHex(this.chartColors.linearColors[1], this.data?.bar.series.length)
    return this.chartColors.dynamicColors;
  }

  currentCriteria(criteria: string) {
    return this.surveyCriterias.find(x => x.key == criteria)?.name;
  }
}
