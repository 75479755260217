<div class="flex flex-row items-center justify-center h-20 px-10 gap-16 uppercase">
  <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
    <span class="w-4 h-3 rounded-sm bg-zen-grey"></span>
    <span>{{'txtNA' | translate:language:ModuleNames.Exit}}</span>
  </div>
  <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
    <span class="w-4 h-3 rounded-sm bg-zen-red"></span>
    <span>{{'txtStronglyDisagree' | translate:language:ModuleNames.Exit}}</span>
  </div>
  <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
    <span class="w-4 h-3 rounded-sm bg-zen-orange"></span>
    <span>{{'txtDisagree' | translate:language:ModuleNames.Exit}}</span>
  </div>
  <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
    <span class="w-4 h-3 rounded-sm bg-zen-yellow"></span>
    <span>{{'txtNeutral' | translate:language:ModuleNames.Exit}}</span>
  </div>
  <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
    <span class="w-4 h-3 rounded-sm bg-zen-lightgreen"></span>
    <span>{{'txtAgree' | translate:language:ModuleNames.Exit}}</span>
  </div>
  <div class="flex flex-row items-center text-xxs text-zen-lite font-medium gap-2.5">
    <span class="w-4 h-3 rounded-sm bg-zen-green"></span>
    <span>{{'txtStronglyAgree' | translate:language:ModuleNames.Exit}}</span>
  </div>
</div>
<ng-container *DemoAccount="'Default'; let isDemo=isDemo">

  <zen-accordions *ngIf="sections?.length">
    <ng-container *ngFor="let section of sections; let s = index">
      <zen-accordion #accordion extraClasses="rounded mt-2">
        <ng-container head>
          <div class="flex flex-col items-stretch w-full relative">
            <div *DemoAccount=" s > 1 ?'Button':'Default'" class="flex flex-row justify-between items-center px-5 cursor-pointer py-2 border-b">
              <h4 class="text-zen text-lg font-medium uppercase ">{{section.name | extract:language}}</h4>
              <div class="flex flex-row justify-end items-center gap-2">
                <div class="flex flex-row gap-1 px-3 rounded-md border-zen border-opacity-10 border h-12 min-w-input">
                  <h5 class="text-zen font-medium uppercase leading-12">{{'txtMean'|
                    translate:language:ModuleNames.Wellbeing}}:</h5>
                  <span class="leading-12" [style.color]="exitExperienceCodesService.resolveChartColor(section.mean)">
                    <i [ngClass]="section.mean > 80 ? 'fa-caret-up' : 'fa-caret-down'"
                      class="fa text-base px-1 leading-12"></i>
                    {{ section.mean > 0 ? toFixed(section.mean,1) + '%' : 'N/A'}}
                  </span>
                </div>
  
                <div class="btn cancel leading-12 w-12 rounded-md relative h-12"
                  [TourStep]="s ==0 ? {title:'txt_Statement_419' | translate:language:ModuleNames.Exit,text: 'txt_statementsscore_desc' | translate:language:ModuleNames.Exit,order:2,tourKey:'exit-analysis-statements',on:(language | language )?'right':'left'} :undefined">
                  <span class="absolute h-0 border-b border-zen w-6 right-3 left-3 top-2/4"></span>
                  <span class="absolute h-0 border-b border-zen w-6 right-3 left-3 top-2/4 transform transition ease-expo"
                    [ngClass]="{'rotate-90': !accordion.active}"></span>
                </div>
                <span>
  
                </span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container body *ngIf="!isDemo || isDemo && s <=1">
          <div [@listAnimation]="section.questions.length" class="flex flex-col items-stretch gap-2.5 p-5">
            <div class=" flex flex-col items-stretch gap-1">
              <div class="flex flex-row gap-1 items-center pb-1.5">
                <div class="w-8/12">
                  <h5 class="text-zen font-medium text-opacity-50 text-sm">{{'txt_question' |
                    translate:language:ModuleNames.Exit}}
                  </h5>

                </div>
                <div class="w-4/12 flex flex-row items-center justify-between gap-2">
                  <h5 class="text-zen font-medium text-opacity-50 text-sm w-20">{{'txtMean' |
                    translate:language:ModuleNames.Exit}}</h5>
                  <h5 class="text-zen font-medium text-opacity-50 text-sm">{{'txtDistribution' |
                    translate:language:ModuleNames.Exit}} (%)</h5>
                </div>
              </div>
              <ng-container *ngFor="let question of section.questions; let n = index">
                <mat-divider *ngIf="n != 0"></mat-divider>
                <analysis-question-template [answers]="returnAnswers(question.answers)"
                  [name]="extractNameLanguage(question.name)" [score]="question.mean"></analysis-question-template>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </zen-accordion>
    </ng-container>
  </zen-accordions>
</ng-container>
