import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Entity } from '@ex/module/core/data-models/Entity';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { GeneralService } from '@ex/module/core/services/general.service';
import { LoaderService } from '@ex/module/core/services/loader.service';
import { LocalService } from '@ex/module/core/services/local.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';
import { AdditionalCriteriasService } from '@ex/module/survey-x/data-access/services/additional-criterias.service';
import { CriteriasService } from '@ex/module/survey-x/data-access/services/criterias.service';
import { DepartmentsService } from '@ex/module/survey-x/data-access/services/departments.service';
import { IndustriesService } from '@ex/module/survey-x/data-access/services/industries.service';
import { SurveysService } from '@ex/module/survey-x/data-access/services/surveys.service';
import { CriteriaType } from '@ex/module/shared/enums/CriteriaType.enum';
import { GenerateEntityHierarchyService } from '@ex/module/wellbeing/data-access/services/generate-entity-hierarchy.service';
import { PredefinedMetricsService } from '@ex/module/wellbeing/data-access/services/predefined-metrics.service';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { FormService } from '@ex/survey-x/index';
import { Subject, of } from 'rxjs';


@Component({
  selector: 'app-analytical-criteria-page',
  templateUrl: './analytical-criteria-page.component.html',
  styleUrls: ['./analytical-criteria-page.component.scss'],
  providers: [GenerateEntityHierarchyService, AdditionalCriteriasService]
})
export class AnalyticalCriteriaPageComponent extends BaseComponent implements OnInit {

  language;
  direction: Direction = 'ltr';
  ModuleNames = ModuleName;

  isNewCriterionModalVisible: boolean = false;
  isEditCriterionModalVisible: boolean = false;
  selectedCriterion = null;



  endPoint = environment.endPoint;
  survey: any = {};
  createdBy: string = "";
  currentUser: any;
  currentUserName: any;
  settingsLoaded$ = new Subject();

  result: any;
  form: any;
  tenantKey: any;
  tenant: any;
  tenantName: any
  respondentInformationPage: boolean = true;
  surveyId!: string;
  surveyForm!: any;
  showAssignWeights = false;
  textPosition = "left";
  selectedEntity: Entity;
  allEntities: Entity[];
  companyKey: string;
  companyName: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private surveysService: FormService,
    public loader: LoaderService,
    private localService: LocalService,
    private defaultMetrics: PredefinedMetricsService,
    private generateEntityHierarchyService: GenerateEntityHierarchyService,
    private additionalCriteriasService: AdditionalCriteriasService
  ) {
    super();

  }

  ngOnInit() {
    // this.loader.displayLoader();
    this.surveyId = this.route.snapshot.paramMap.get('id');
    this.fetchMetrics();

    this.addSubscription(this.EssentialObject$.pipe(filter(x => x.selectedEntity?.key != null)).subscribe(obj => {
      this.tenantKey = obj.tenant.key;
      this.tenantName = obj.tenant.name
      this.companyKey = obj.selectedEntity.key;
      this.language = obj.language;
      this.selectedEntity = obj.selectedEntity;
      this.allEntities = obj.allEntities;
      this.companyName = obj.selectedEntity.name.find(x => x.key == "EN")?.name;
      this.settingsLoaded$.next();

    }))

    // this.form = await this.surveysService.getFormWithoutResponses(this.surveyId).toPromise();

    // this.survey = this.form.query;

    // this.language = this.survey.locale.toUpperCase();



    this.loader.hideLoader();
  }
  fetchMetrics() {
    this.settingsLoaded$.pipe(switchMap(x => {

      let defaultMetrics: any[] = this.defaultMetrics.extractMetrics(this.selectedEntity, this.allEntities).map(x => {
        x.isCustom = false;
        return x;
      });
      return this.surveysService.getFormWithoutResponses(this.surveyId).pipe(map(x => x.query)).pipe(switchMap(survey => {
        if (!survey.surveyCriterias?.length)
          return this.additionalCriteriasService.additionalCriterias(this.selectedEntity.ContentLanguages).pipe(map(criterias => {
            survey.surveyCriterias = [...criterias, ...defaultMetrics];
            return survey;
          }))
          let predefinedMetrics = survey.surveyCriterias.filter(x => !x.isCustom || defaultMetrics.find(m => m.key == x.key));
          if (defaultMetrics.length > predefinedMetrics.length) {
            let customMetrics = survey.surveyCriterias.filter(x => x.isCustom && !defaultMetrics.find(m => m.key == x.key))
            survey.surveyCriterias = defaultMetrics.concat(customMetrics);
          }
          return of(survey)
      }))
    })).subscribe(survey => {
      console.log(survey);
      this.survey = survey

      this.survey.entityHierarchy = this.generateEntityHierarchyService.getEnityGroupWithEntities(this.allEntities, this.selectedEntity.EntityGroups);
    })



  }







  showEditCriteriaModal(selectedCriterion: any) {
    this.selectedCriterion = selectedCriterion;
    this.isEditCriterionModalVisible = true;
  }




  async saveChanges() {
    console.log(this.survey);


    // this.loader.displayLoader();

    // this.result = await this.surveysService.getSurveyWithoutResponses(this.surveyId);

    // var surveyForm: any = this.result.query;
    // surveyForm.isEmail = true;


    // surveyForm.tenantKey = this.tenantKey;
    // surveyForm.tenantName = this.tenantName;
    // surveyForm.createdBy = this.currentUserName;

    // this.criteriasList.forEach(async (criteria) => {
    //   if (criteria.isStandardCriteria == false) {
    //     var formCriteria = { formTablesId: this.surveyId, criteriaId: criteria.id, tenantKey: this.tenantKey, isEnabled: criteria.isEnabled }
    //     await this.criteriasService.updateCriteriaInForm(formCriteria).subscribe((response: any) => {
    //       console.log(response);
    //     });
    //   }
    // });


    await this.surveysService.updateForm(this.surveyId, this.survey).toPromise();

    this.loader.hideLoader();
  }

  getValueName(valueTitle: any, language: string) {
    if (!valueTitle) return;

    try {
      let title = JSON.parse(valueTitle);
      return title.find((f: any) => f.key == language).name
    } catch (e) {
      return valueTitle;
    }
  }


  async nextStep() {
    await this.saveChanges();

    this.router.navigateByUrl("/survey-x/form-builder/respondents/" + this.surveyId);
  }

  async previousStep() {
    await this.saveChanges();

    this.router.navigateByUrl("/survey-x/form-builder/languages/" + this.surveyId);
  }

  async pageChanged(event: any) {
    await this.saveChanges();

    this.router.navigateByUrl(`/survey-x/form-builder/${event}/${this.surveyId}`);
  }
}
