import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SurveyDto } from '@ex/wellbeing/surveys';
import { CDKPortalComponent, DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
import { WbEmailTemplatesService } from '../../data-access/services/wb-email-templates.service';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';

@Component({
  selector: 'app-wb-reminder-modal',
  templateUrl: './wb-reminder-modal.component.html',
  styleUrls: ['./wb-reminder-modal.component.scss']
})
export class WbReminderModalComponent extends WbBaseComponent implements OnInit, CDKPortalComponent {

  constructor(@Inject(DATA_TOKEN) private data: any, private emailTemplatesService: WbEmailTemplatesService) {
    super();
  }
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() results: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.surveyData = JSON.parse(JSON.stringify(this.data.survey));
    this.reminderCount = this.data['reminderCount'];
    this.isSendEmails = this.data['isSendEmails'];
    this.language = this.data.language;
    this.open = true;
  }
  surveyData: SurveyDto;
  reminderCount;
  isSendEmails: boolean = false;
  language;
  open: boolean;
  resetTemplate() {
    this.surveyData.reminderEmail.body = this.emailTemplatesService.getReminderEmail();
    this.surveyData.reminderEmail = JSON.parse(JSON.stringify(this.surveyData.reminderEmail));
  }
  update() {
    if (this.validateEmailReminder(this.surveyData))
      this.results.emit(this.surveyData);
  }

  replaceCount(text, count) {
    return this.localize(text, this.language, this.ModuleNames.Wellbeing).replace('#count', (count).toString());
  }


  validateEmailReminder(SurveyDto: SurveyDto) {

    let isValid: boolean = true;
    if (!this.surveyData.reminderEmail.subject) {
      isValid = false;
    }
    if (!this.surveyData.reminderEmail.body) {
      isValid = false;
    }
    if (this.surveyData.reminderEmail.body.indexOf("[SurveyURL]") < 1) {
      this.SnackbarService.error(this.localize("txtReminderMissingURL", this.language, this.ModuleNames.Engagement));

      return isValid = false;
    }
    if (!isValid)
      this.SnackbarService.error(this.localize("txt_fill_fields", this.language, this.ModuleNames.Engagement));

    if (!this.validateCount(this.surveyData.reminderEmail.subject, this.GlobalService.mdCount, 'x') || !this.validateCount(this.surveyData.reminderEmail.body, this.GlobalService.hugeCount, 'x')) {
      return false
    }
    else {
      return isValid;
    }
  }


  validateCount(value, count, ready?) {
    let validatedCount;
    if (ready == 'x') {
      validatedCount = this.countWord(value, count)
    }
    else
      validatedCount = this.countWord(this.ExtractPipe.transform(value, this.language), count)
    if (validatedCount > count) {
      this.SnackbarService.error(this.localize("txt_wrong_text_count", this.language, this.ModuleNames.Engagement));
      return false;
    }
    if (validatedCount <= count) {
      return true;
    }
  }
  countWord(word, count) {
    if (word?.length <= count)
      return word.length;
    if (word?.length > count) {

      return word.length;
    }
  }


}
