import { ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ExitBaseComponent } from '../exit-base/exit-base.component';
import { QuestionTypes } from 'src/app/shared/questionnaire/questions/question-types';
import { SeperationTypesService } from '../../data-access/services/seperation-types.service';
import { Entity } from 'src/app/core/data-models/Entity';
import { QuestionDto, QuestionOptionDto } from '@ex/exit/survey-templates';
import { AngularFireDatabase } from '@angular/fire/compat/database';

interface IKeyNameType {
  key: string;
  name: string | any;
}

@Component({
  selector: 'template-new-questions',
  templateUrl: './template-new-questions.component.html',
  styleUrls: ['./template-new-questions.component.scss'],
})
export class TemplateNewQuestionsComponent extends ExitBaseComponent implements OnInit {

  constructor(private seperationTypesService: SeperationTypesService, private db: AngularFireDatabase, private changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.seperationTypes = [...this.seperationTypes, ...this.seperationTypesService.get()]
  }
  @Input() language: string;
  @Input() questionName;
  @Input() selectedEntity: Entity;
  @Input('question') set q(q: QuestionDto) {
    this.tQuestion = q;
    this.question = JSON.parse(JSON.stringify(this.tQuestion));
    if (!this.question.questionType)
      this.question.questionType = this.questionTypes[0].key;

    this.seperationType = !this.question.seperationType ? this.seperationTypes[0].key : this.question.seperationType;

    if (!this.question?.questionOptions?.length) {
      this.question.questionOptions = [];

      this.addQuestionOption();
      this.addQuestionOption();
    }
  };
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  QuestionTypes = QuestionTypes;
  question: QuestionDto;
  tQuestion: QuestionDto;
  seperationTypes: IKeyNameType[] = [{ key: 'all', name: 'txtAll' }];
  seperationType = 'all';
  mdCount = this.GlobalService.mdCount;
  questionTypes: IKeyNameType[] = [
    { key: QuestionTypes.MultiOptionMultiAnswer, name: QuestionTypes.MultiOptionMultiAnswer },
    { key: QuestionTypes.MultiOptionSingleAnswer, name: QuestionTypes.MultiOptionSingleAnswer },
    { key: QuestionTypes.OpenText, name: 'txtText' }
  ]
  selectionTypes: IKeyNameType[] = [
    { key: 'max', name: 'txtSelectMax' },
    { key: 'min', name: 'txtSelectMin' },
    { key: 'range', name: 'txtSelectRange' },
  ]
  selectionType: any = this.selectionTypes[0].key;
  saveQuestion: boolean = false;


  addQuestionOption() {
    let option: QuestionOptionDto = {
      key: this.db.createPushId(),
      name: [],
      otherAnswer: false,
      answer: []
    }
    this.question.questionOptions.push(option);

    this.question.max = this.question.questionOptions.length;
  }
  seperationTypeChanged(event) {
    this.question.seperationType = event == 'all' ? null : event;
  }


  saveChanges() {
    this.saveQuestion = true;
    if (!this.isValidName(this.questionName)) {
      return
    }
    if (this.question.questionType != QuestionTypes.OpenText) {

      if (this.question.questionOptions?.length < 2) {
        return this.SnackbarService.error(this.localize("txtQuestionOptionsLength", this.language, this.ModuleNames.Exit));
      }

      if (this.selectionType == 'max') {
        this.question.min = 0;
        if (this.question.max > this.question.questionOptions?.length)
          return this.SnackbarService.warning(this.localize("txtMaxErrorOptions", this.language, this.ModuleNames.Exit));

        if (this.question.max < 1)
          return this.SnackbarService.warning(this.localize("txtMaxErrorOptions", this.language, this.ModuleNames.Exit));

      }
      else if (this.selectionType == 'min') {
        this.question.max = this.question.questionOptions.length;
        if (!this.question.min || this.question.min > this.question.questionOptions?.length)
          return this.SnackbarService.warning(this.localize("txtMinErrorOptions", this.language, this.ModuleNames.Exit));

      }
      else {
        if (!this.question.min || !this.question.max)
          return this.SnackbarService.warning(this.localize("txtMinAndMaxDatesRequired", this.language, this.ModuleNames.Exit));

        if (this.question.max > this.question.questionOptions?.length)
          return this.SnackbarService.warning(this.localize("txtMaxErrorOptions", this.language, this.ModuleNames.Exit));

        if (this.question.min > this.question.max)
          return this.SnackbarService.warning(this.localize("txtMinErrorOptions", this.language, this.ModuleNames.Exit));

      }



      if (this.question.questionOptions.some(option => !this.isValidName(option.name)))
        return this.SnackbarService.error(this.localize("invalidQuestionLength", this.language, this.ModuleNames.Exit));
    }

    this.question.name = this.tQuestion.name
    this.tQuestion = this.question
    if (this.tQuestion.seperationType == 'all')
      this.tQuestion.seperationType = null;
    this.save.emit(this.tQuestion);
  }

  isValidName(name) {
    return name?.find(s => s.name.length >= 2) ? true : false;
  }

  removeOption(option) {
    this.question.questionOptions.splice(this.question.questionOptions.indexOf(option), 1);
  }

}
