import { KeyNameDto } from "@ex/onboarding/common";
import { OnboardingPhases } from "@ex/onboarding/survey-templates";

export interface AnalyticsFilter {
    survey: {
        name: string,
        key: string,
        isMultiPhaseOnboarding: boolean
        dimensions?: any[],
    };
    tenantKey: string;
    companyKey: string;
    timeframe?: TimeFrameFilter,
    phase?: OnboardingPhases,
    showExtraFactors?: boolean;
    chartFilters?: ChartFilter[];
    entityId?: string[];
}

export interface TimeFrameFilter {
    startDate: Date;
    endDate: Date;
}

export interface ChartFilter {
    key: string;
    value: any;
}

export enum AnalysisFilterKeys {
    TimeFrame = "Timeframe",
    EntityId = "Entity",
    Phase = "Phase",
    Gender = "Gender",
    PerformanceRating = "PerformanceRate",
    Nationality = "Nationality",
    Generation = "Generation",
    AgeGroups = "AgeGroup",
    TenureGroups = "TenureGroup",
    BandLevel = "BandLevel",
    JobGrade = "JobGrade",
    Criteria = "criteria",
    IncludeExtraDrivers = "ExtraDriver",
    JobRole = "JobRole"
}
