import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/domain/base-component';

@Component({
  selector: 'response-rate-card',
  templateUrl: './response-rate-card.component.html',
  styleUrls: ['./response-rate-card.component.scss']
})
export class ResponseRateCardComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }
  @Input() language;
  @Input() completed;
  @Input() total;
  @Input() precentage;

}
