<div class="flex flex-row items-center w-full gap-4 mb-4">
    <div class="flex flex-row w-full items-center gap-2">
        <h2 class="text-lg font-normal text-zen-primarydefault">
            {{"txtRespondents'Feedback"| translate:language:ModuleNames.SurveyX}}
        </h2>
    </div>

    <div class="flex flex-row items-center gap-2">
        <z-input [search]="true" (keyup)="onKeyUp($event)" class="w-60"
            [placeholder]="'Search...' | translate:language:ModuleNames.SurveyX">

        </z-input>
    </div>
</div>

<div class="flex flex-col items-start gap-4 w-full rounded-lg  cc-s">
    <div class="flex flex-col items-start self-stretch rounded-lg" >
        <div [@listAnimation]="feedbackList.length" *ngFor="let feedback of feedbackList; let i = index" class="flex flex-col w-full"
            >
            <sx-feedback-card [feedback]="feedback"></sx-feedback-card>
        </div>

        <div *ngIf="isShowMore"
            class="flex flex-row w-full text-xs text-zen-primarydefault items-center justify-center">
            <a class="btn" (click)="showMoreComments()">
                <i class="fa-regular fa-chevrons-down"></i>
                {{ "txtshowmore" | translate : language : ModuleNames.SurveyX }}</a>
        </div>
    </div>
</div>