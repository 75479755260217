/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateLandingRespondentDto } from '../models/CreateLandingRespondentDto';
import type { GenerateLinkDto } from '../models/GenerateLinkDto';
import type { RespondentSurveyLinkDto } from '../models/RespondentSurveyLinkDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class GenerateLinkService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param key 
     * @param requestBody 
     * @returns RespondentSurveyLinkDto Success
     * @throws ApiError
     */
    public createRespondent(
key: string,
requestBody?: CreateLandingRespondentDto,
): Observable<RespondentSurveyLinkDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form/live/generate-link/create-respondent/{key}',
            path: {
                'key': key,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param formId 
     * @param tenantId 
     * @param companyId 
     * @returns GenerateLinkDto Success
     * @throws ApiError
     */
    public generateLink(
formId: string,
tenantId: string,
companyId: string,
): Observable<GenerateLinkDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form/live/generate-link/generate/{formId}/{tenantId}/{companyId}',
            path: {
                'formId': formId,
                'tenantId': tenantId,
                'companyId': companyId,
            },
        });
    }

}
