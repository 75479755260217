<div class="flex flex-col mt-10" *DemoAccount="'Default'; let isDemo=isDemo">

  <div class="flex flex-row items-stretch flex-wrap -mr-3 -ml-3">
    <ng-container *ngFor="let chart of charts; let c= index">

      <div (click)="logData(charts, 'chart')" [ngClass]="chart?.halfWidth?'w-1/2':'w-full'" class="flex flex-col items-stretch px-3 pb-6" *ngIf="chart?.data?.categories?.length || chart.group == 'overall'">

        <!-- <ob-chart-wrapper [title]="chart.title"> -->

        <!-- <ng-container body> -->
        <ng-container [ngSwitch]="chart.group">

          <ng-container *ngSwitchCase="'overall'">
            <div *ngIf="chart?.data as data" class="flex flex-col items-center justify-between h-full py-10 gap-10 w-full rounded-sm border border-zen border-opacity-10 shadow-sm bg-white">
              <h5 class="text-zen text-lg font-medium  px-5">{{ chart.Title }}</h5>
              <kendo-arcgauge class="w-56" [color]="'#0082C6'" [value]="toFixed(
                                            data.responseRate,0)" renderAs="canvas">
                <kendo-arcgauge-scale [startAngle]="90" [endAngle]="450" [rangeSize]="25" [rangeLineCap]="'butt'">
                  <ng-template kendoArcGaugeCenterTemplate let-value="value">
                    <span class="text-4xl font-medium text-zen">{{toFixed(
                      data.responseRate,0)}}%</span>
                  </ng-template>
                </kendo-arcgauge-scale>
              </kendo-arcgauge>
              <span></span>
            </div>

          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container  >
            <chart  [disableChartTypes]="isDemo" [wrapRows]="true" [dashlet]="chart" [dashletData]="chart?.data" [currencySymbol]="'$'" [language]="language" [dataSymbol]="' %'" [disableGrouping]="true" [showDrillDown]="true" [reportsPage]="false" [chartTypeTour]="!chart?.halfWidth?{title:'txt_ChartType_628'| translate:language:ModuleNames.Exit,text: 'txt_Changethe_779' | translate:language:ModuleNames.Exit,order: 2,tourKey:'exit-analysis-leaver',on:(language | language )?'right':'left',isRows:true}:undefined">
            </chart>
          </ng-container>
        </ng-container>

        </ng-container>

        <!-- </ng-container> -->

        <!-- </ob-chart-wrapper> -->

      </div>

    </ng-container>

  </div>

</div>
