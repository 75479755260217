import { Component, Input, OnInit } from '@angular/core';
import { ModuleName } from '../../enums/ModuleName';

@Component({
  selector: 'q-footer',
  templateUrl: './q-footer.component.html',
  styleUrls: ['./q-footer.component.scss']
})
export class QFooterComponent implements OnInit {
  @Input()language;
  constructor() { }

  ngOnInit(): void {
  }
  ModuleNames=ModuleName
}
