import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ExitBaseComponent } from '../exit-base/exit-base.component';
import { CDKPortalComponent, DATA_TOKEN } from 'src/app/shared/Theme/services/zen-cdk.service';
import { TreeListData } from '@ex/module/wellbeing/ui/wb-tree-options/wb-tree-options.component';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Hierarchy } from 'src/app/core/data-models/hierarchy';
import animations from '../../utils/animations';

@Component({
  selector: 'app-entity-tree-modal',
  templateUrl: './entity-tree-modal.component.html',
  styleUrls: ['./entity-tree-modal.component.scss'],
  animations: animations
})
export class EntityTreeModalComponent extends ExitBaseComponent implements OnInit, CDKPortalComponent {

  close: EventEmitter<any> = new EventEmitter();
  results: EventEmitter<any> = new EventEmitter();

  language;
  open: boolean;
  selectedChildEntity: Hierarchy;
  tselectedChildEntity: Hierarchy;
  treeList: any;
  showLeaverCount: boolean = false;

  constructor(@Inject(DATA_TOKEN) private data: { componentData: TreeListData, language: string, showLeaverCount: boolean }, private router: Router, private location: LocationStrategy) {
    super();

  }

  ngOnInit(): void {
    this.language = this.data.language;
    this.treeList = this.data.componentData.treeList;
    this.tselectedChildEntity = this.data.componentData.tselectedChildEntity;
    this.open = true;
    this.showLeaverCount = this.data.showLeaverCount;
  }

  closeModal() {
    this.open = false;
    this.close.emit(true);
  }

  tselectedChildEntityChanged(entity: Hierarchy) {
    if (!this.tselectedChildEntity['hasPermission'])
      return;

    if (!entity.entity.count && this.showLeaverCount)
      return this.SnackbarService.error(this.localize("txtAtleastOneLeaverRequired", this.language, this.ModuleNames.Exit));
  }

  entitySelected() {
    if (!this.tselectedChildEntity['hasPermission'])
      return;

    if (!this.tselectedChildEntity.entity.count && this.showLeaverCount)
      return this.SnackbarService.error(this.localize("txtAtleastOneLeaverRequired", this.language, this.ModuleNames.Exit));

    this.results.emit(this.tselectedChildEntity)
  }

  get disableApplyButton() {

    if (this.showLeaverCount && (!this.tselectedChildEntity?.entity.count || !this.tselectedChildEntity['hasPermission']))
      return true;

    if (!this.showLeaverCount && (!this.tselectedChildEntity['hasPermission']))
      return true;

    return false;
  }

}
