<div class="sidebar-header px-8 pt-10 py-1 mb-4">
    <div class="flex justify-between items-center mb-8 pb-3">
        <h6 class="sidebar-header-title">
            {{'Questions'| translate:language:ModuleNames.SurveyX }}
        </h6>

        <button class="flex btn exp text-white text-sm font-medium py-4 px-4 rounded" (click)="showAddQuestionModal()">
            <i class="fa-solid fa-plus w-4 pt-3px fill-current" fill="currentColor"></i>
        </button>
    </div>

    <div class="relative w-full mb-5 input-group-search sidebar-search">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg class="w-5 h-5" aria-hidden="true" fill="#7D92B5" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"></path>
            </svg>
        </div>
        <input type="search" class="block w-full pl-10 p-2.5 rounded"
            [placeholder]="'Search...'| translate:language:ModuleNames.SurveyX" [(ngModel)]="searchText">
    </div>
</div>
<div cdkDropListGroup>


  <div class="mb-4">
    <div class="button-flex-container">
        <span class="divider"></span>

        <button class="button new-section-button flex items-center justify-between gap-2 px-3"
            (click)="addSectionAfter(-1)">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.75 1.25V6.25H12.75C13.1562 6.25 13.5 6.59375 13.5 7C13.5 7.4375 13.1562 7.75 12.75 7.75H7.75V12.75C7.75 13.1875 7.40625 13.5 7 13.5C6.5625 13.5 6.25 13.1875 6.25 12.75V7.75H1.25C0.8125 7.75 0.5 7.4375 0.5 7C0.5 6.59375 0.8125 6.25 1.25 6.25H6.25V1.25C6.25 0.84375 6.5625 0.5 7 0.5C7.40625 0.5 7.75 0.84375 7.75 1.25Z"
                    fill="#5D75A2" />
            </svg>

            {{'New Section'| translate:language:ModuleNames.SurveyX }}
        </button>

        <span class="divider"></span>
    </div>
</div>



    <div *ngFor="let page of model.survey.pages; index as pageIndex;" cdkDropList
        (cdkDropListDropped)="questionDragNDrop($event,page)" [cdkDropListData]="page.questions">
        <div class="px-8 pb-8">
            <h6 class="sidebar-section-title mb-4 pt-2" (click)="selectPage(page)">{{ !page.title.toLowerCase().includes("section") ? page.title :  getTranslatedTitle(page)  | translate:language:ModuleNames.SurveyX}}</h6>

            <div *ngFor="let question of page.questions; index as questionIndex;" cdkDrag>
                <div *ngIf="question.title.toLowerCase().includes(searchText.toLowerCase())"
                    [class.sidebar-question-active]="model.selectedElement === question"
                    (click)="selectQuestion(question)"
                    class="sidebar-question rounded-1 shadow-sm flex gap-3 items-center justify-between p-6 pe-5 mb-2 cursor-pointer">
                    <div class="flex flex-row gap-3 w-11/12" [matTooltip]="question.title | translate:language:ModuleNames.SurveyX">
                        <button class="btn flex flex-col items-center text-center h-max py-2 px-2  rounded-md w-1/12">
                            <i class="fa-solid" [ngClass]="getQuestionIcon(question)"></i>
                        </button>

                        <div class="flex flex-row w-11/12 align-items-top gap-1">
                            <p class="text-[#8085a4] mb-0 whitespace-nowrap">{{getQuestionNumber(question)}}. </p>
                            <p class="sidebar-question-title mb-0 truncate">{{question.title | translate:language:ModuleNames.SurveyX}}</p>
                        </div>
                    </div>

                    <div class="w-1/12">
                        <button
                            class="text-center flex flex-row items-center rounded px-4 py-4 leading-9 text-xs font-medium gap-3 ellipsis-button"
                            [ZMenuTriggerFor]="menu">
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                        </button>

                        <z-menu #menu>
                            <menuItems>
                                <ng-container [ngTemplateOutlet]="moreMenuItems"></ng-container>
                            </menuItems>
                        </z-menu>

                        <ng-template #moreMenuItems>
                            <!-- <a MenuItem (click)="editQuestion(question)">
                                <i class="fa-regular fa-pen mx-1"></i>
                                {{'Edit'| translate:language:ModuleNames.SurveyX }}
                            </a> -->

                            <a MenuItem (click)="deleteQuestion(question,menu)" class="text-[red]">
                                <i class="fa-regular fa-trash mx-1"></i>
                                {{'Delete'| translate:language:ModuleNames.SurveyX }}
                            </a>
                        </ng-template>
                    </div>
                </div>
            </div>


            <div class="pt-3">
              <div class="button-flex-container justify-center">
                  <!-- <span class="divider"></span> -->

                  <button class="button new-q-button flex items-baseline justify-between gap-1"
                  (click)="showAddQuestionModalWithIndex(pageIndex)">
                     <i class="w-4 h-4 text-exp fa-solid fa-plus"></i>

                      {{'New Question'| translate:language:ModuleNames.SurveyX }}
                  </button>

                  <!-- <span class="divider"></span> -->
              </div>
          </div>


        </div>

        <div class="mb-4">
            <div class="button-flex-container">
                <span class="divider"></span>

                <button class="button new-section-button flex items-center justify-between gap-2 px-3"
                    (click)="addSectionAfter(pageIndex)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.75 1.25V6.25H12.75C13.1562 6.25 13.5 6.59375 13.5 7C13.5 7.4375 13.1562 7.75 12.75 7.75H7.75V12.75C7.75 13.1875 7.40625 13.5 7 13.5C6.5625 13.5 6.25 13.1875 6.25 12.75V7.75H1.25C0.8125 7.75 0.5 7.4375 0.5 7C0.5 6.59375 0.8125 6.25 1.25 6.25H6.25V1.25C6.25 0.84375 6.5625 0.5 7 0.5C7.40625 0.5 7.75 0.84375 7.75 1.25Z"
                            fill="#5D75A2" />
                    </svg>

                    {{'New Section'| translate:language:ModuleNames.SurveyX }}
                </button>

                <span class="divider"></span>
            </div>
        </div>
    </div>
</div>
<app-add-question-modal *ngIf="isAddQuestionModalOpen" [(open)]="isAddQuestionModalOpen" [language]="language"
    (typeSelected)="handleTypeSelected($event)"></app-add-question-modal>
