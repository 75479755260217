import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RespondentPoolDto, RespondentPoolsService } from '@ex/core/respondent-pools';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { ResponseDialogService } from 'src/app/core/services/response-dialog.service';
import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { JsonToExcelComponent } from 'src/app/shared/export-components/json-to-excel/json-to-excel.component';
import { RespondentTableFetchObject, RespondentsTableService } from '../../data-access/services/respondents-table.service';
import { WbRespondentsModalComponent } from '../../ui/wb-respondents-modal/wb-respondents-modal.component';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';

@Component({
  selector: 'app-wb-people',
  templateUrl: './wb-people.component.html',
  styleUrls: ['./wb-people.component.scss'],
  animations: [ChildrenAnimation],
  providers: [RespondentsTableService]
})
export class WbPeopleComponent extends WbBaseComponent implements OnInit {
  resetSelection;
  constructor(private respondentPoolService: RespondentPoolsService, private respondentsTableService: RespondentsTableService, private responseDialogService: ResponseDialogService,) {
    super();
  }
  ngOnInit() {
    this.LoaderService.displayLoader();
    this.addSubscription(this.EssentialObject$.pipe(
      filter(obj => obj?.selectedEntity?.key != null),
      distinctUntilChanged((x, y) => x != y, x => x.selectedEntity.key),
      switchMap(obj => {
        this.language = obj.language;
        this.tenantKey = obj.tenant.key;
        this.companyKey = obj.selectedEntity.key;

        return this.respondentsTableService.init({
          language: this.language,
          companyKey: this.companyKey,
          tenantKey: this.tenantKey
        })
      })).subscribe((obj: RespondentTableFetchObject) => {
        this.options = obj.options;
        this.totalRecords = obj.totalRecords;
        this.currentPage = obj.pageNumber;
        this.pageSize = obj.pageSize;
        this.LoaderService.hideLoader();
      }));
  }

  searchTextUpdate(text) {
    this.respondentsTableService.searchText.next(text);
  }

  @Output() restableSelectionChanged = new EventEmitter();
  selectedRespondents;
  language = "EN";
  options: TableOptions;
  tenantKey;
  companyKey;
  pageSize = 5;
  currentPage = 1;
  totalRecords;

  onPageChange(e) {
    this.currentPage = e + 1;
    this.respondentsTableService.pageNumber.next(this.currentPage);
  }

  downloadExcel() {
    window.open('/assets/downloads/Respondents Template.xlsx', '_blank');
  }

  OpenModal() {
    this.ZenCDKService.openComponent(WbRespondentsModalComponent).subscribe(result => {
      switch (result.type) {
        case 'single':
          this.LoaderService.displayLoader();
          this.createSingleRespondent(result.data);
          break;
        case 'file':
          this.LoaderService.displayLoader();
          this.insertUsingExcel(result.data)
          break;
      }
    });
  }

  insertUsingExcel(data: any) {
    this.respondentPoolService.excelUpload({
      companyKey: this.companyKey,
      tenantKey: this.tenantKey,
      excelFile: data.split(",")[1]
    }).subscribe(result => {
      this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
    })
  }


  private createSingleRespondent(data: any) {
    this.respondentPoolService.create({
      companyKey: this.companyKey,
      tenantKey: this.tenantKey,
      email: data.email,
      name: data.name,
      employeeId: data.employeeId,
      entityHierarchy: [],
      customProperties: [],
    }).subscribe(result => {
      this.respondentsTableService.addRespondent(result);
      // this.ZenCDKService.removePortal();
      this.LoaderService.hideLoader();
    });
  }

  async deleteRespondent(respondent: RespondentPoolDto) {
    this.resetSelection = false;
    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondent_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {
        this.addSubscription(this.respondentPoolService.delete({
          email: respondent.email,
          tenantKey: this.tenantKey,
          companyKey: this.companyKey
        }).subscribe(x => {
          this.resetSelection = true;
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.Wellbeing));
          this.respondentsTableService.removeRespondent(respondent);
        }))
      }
    } catch (ex) {

    }
  }
  async deleteRespondents(respondents: RespondentPoolDto[]) {
    this.resetSelection = false;

    var emails = respondents.map(x => x.email);

    try {
      let confirmed = await this.responseDialogService.confirmation(
        'txt_remove_respondents_confirmation_message',
        'txt_delete_confirmation_subject',
        'txt_okay_action',
        'btn_cancel',
        this.ModuleNames.Wellbeing,
        this.language
      );
      if (confirmed) {
        this.respondentPoolService.bulkDelete({
          companyKey: this.companyKey,
          tenantKey: this.tenantKey,
          emails: emails, /* list of all the emails */
          isDeleteAll: this.respondentsTableService.selectAll // true if all respondents need to be deleted
        }).subscribe(x => {
          this.resetSelection = true;
          this.SnackbarService.success(this.localize('txt_removed_success', this.language, this.ModuleNames.Wellbeing));
          this.respondentsTableService.refresh.next(this.respondentsTableService.refreshIncrement++)
        })
      }
    } catch (ex) {

    }
  }
  selectedAll(event) {
    this.respondentsTableService.selectAll = event;
  }
  downloadExcell() {
    // this.respondentsTableService.fetchFullList().pipe(filter)
    this.addSubscription(this.respondentPoolService.getAll({
      companyKey: this.companyKey,
      tenantKey: this.tenantKey,
      maxResultCount: 5,
      skipCount: 5,
      filters: [],
      filterText: '',
      isAllResults: true
    }).subscribe(x => {
      let data = x.items.map(x => {
        let item = {
          id: x.id,
          name: x.name,
          email: x.email
        }
        return item;
      })

      this.ZenCDKService.openComponent(JsonToExcelComponent, {
        data: data,
        name: this.localize('txt_respondents_list', this.language, this.ModuleNames.Wellbeing)
      })

    }))
  }
}
