import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { TourStepsService } from '../../services/tour-steps.service';
import { defaultStepOptions } from '../../models/tour-step';
import { TourBaseComponent } from '../tour-base.component';
import { PlatformUserService } from 'src/app/core/services/platform-user.service';

@Component({
  selector: 'product-tour',
  templateUrl: './main-tour.component.html',
  styleUrls: ['./main-tour.component.scss'],
})
export class MainTourComponent
  extends TourBaseComponent
  implements AfterViewInit, OnDestroy
{
  @Input() key: string;
  @Input() offset: number = 0;
  @Input() destroy: boolean = false;
  @Input() subTour: boolean = false;
  constructor(
    private shepherdService: ShepherdService,
    private tourStepsService: TourStepsService,
    private platformUserService: PlatformUserService
  ) {
    super();
  }
  rtl: boolean = false;
  cancel: boolean = false;
  ngAfterViewInit() {
    console.log('hi');

    if (document.body.dir == 'rtl') {
      this.rtl = true;
    }
    setTimeout(() => {
      if (
        this.currentUser &&
        this.currentUser.ProductTours &&
        this.currentUser.ProductTours[this.key] &&
        !this.cancel
      ) {
        this.createTour();
      } else {
        if (
          !this.currentUser.ProductTours ||
          !Object.keys(this.currentUser.ProductTours).find((f) => f == this.key)
        )
          this.activateTour();
      }
    }, 2000);
  }

  createTour() {
    if (this.shepherdService.isActive) {
      this.shepherdService?.hide();
    }
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.defaultStepOptions = defaultStepOptions;
    const steps = this.tourStepsService.getSteps(this.key);
    if (!steps) return;
    steps.sort((a, b) => a.order - b.order);
    const stepsOptions = steps.map((item, i) => {
      //buttons
      if (i == 0) {
        item.options.title = item.options.title.toString().includes('👋🏻')
          ? item.options.title
          : '👋🏻 ' + item.options.title;
        if (steps.length > 1) {
          item.options.buttons = [
            {
              classes: this.builtInButtons.skip.classes,
              text: this.builtInButtons.skip.text,
              action: () => {
                this.shepherdService.complete();
              },
            },
            this.builtInButtons.startTour,
          ];
        }
      } else {
        let progressBtn = {
          classes: this.builtInButtons.progressBtn.classes,
          text: this.returnPointsList(steps.length, i),
        };
        if (i > 0 && i < steps.length - 1) {
          item.options.buttons = [
            progressBtn,
            this.builtInButtons.back,
            this.builtInButtons.next,
          ];
        }
        if (i == steps.length - 1) {
          item.options.buttons = [
            progressBtn,
            this.builtInButtons.back,
            {
              classes: this.builtInButtons.endTour.classes,
              text: this.builtInButtons.endTour.text,
              action: () => {
                this.shepherdService.complete();
              },
            },
          ];
        }
      }
      item.options.text = this.bodyText.replace(
        '[text]',
        item.options.text?.toString()
      );
      //scroll handler
      item.options.scrollToHandler = (element: HTMLElement) => {
        const headerHeight = 80 + this.offset;
        const targetElementTop =
          element.getBoundingClientRect().top + window.pageYOffset;
        const newScrollPosition = targetElementTop - headerHeight;
        // Scroll to the adjusted position
        window.scrollTo({ top: newScrollPosition, behavior: 'smooth' });
      };
      //show on element visible
      item.options.showOn = () =>
        this.isContentVisible(item.options.attachTo.element);
      //handle triggerid
      item.options.when = {
        show: () => {
          if (item.options.id) {
            const element: any = document.getElementById(
              item.options.id
            ) as HTMLElement | null;
            let attachElement: any = item.options.attachTo.element;
            if (!attachElement.outerHTML.includes('active')) element.click();
          }
        },
      };
      return item.options;
    });
    //add steps tour
    this.shepherdService.addSteps(stepsOptions);
    //on complete the tour
    this.shepherdService.tourObject.on('complete', () => {
      if (this.currentUser.ProductTours[this.key]) {
        this.currentUser.ProductTours[this.key] = false;
        this.platformUserService.updatePlatformUser(this.currentUser);
      }
    });
    //start tour tour
    this.shepherdService.start();
  }
  ngOnDestroy() {
    if (this.destroy) {
      if (this.shepherdService.tourObject) this.shepherdService?.hide();
      this.tourStepsService.removeTour(this.key);
    } else {
      this.cancel = true;
      if (this.shepherdService.tourObject) this.shepherdService?.hide();
    }
  }
  private returnPointsList(length, index) {
    var points = `<ul class="progress-points">`;
    for (var i = 1; i < length; i++) {
      if (i == index) points += "<li class='active'></li>";
      else points += '<li></li>';
    }
    points += '</ul>';
    return points;
  }
  private activateTour() {
    if (!this.currentUser.ProductTours) this.currentUser.ProductTours = {};
    if (!this.currentUser.ProductTours[this.key]) {
      this.currentUser.ProductTours[this.key] = true;
      this.platformUserService.updatePlatformUser(this.currentUser);
    }
    this.createTour();
  }
  private isContentVisible(element: any): boolean {
    return element && element.offsetHeight > 0 && element.offsetWidth > 0;
  }
}
