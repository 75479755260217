import { Injectable, Input } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { RespondentPoolDto, RespondentPoolsService } from '@ex/core/respondent-pools';
import { WbBaseComponent } from '../../utils/wellbeing-base.component';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { TableOptions } from 'src/app/core/data-models/table-options';
import { RespondentDto, RespondentService } from '@ex/wellbeing/respondents';
import { KeyNameDto } from '@ex/onboarding/common';
import { Status } from 'src/app/core/services/global-status.service';
export interface RespondentTableFetchObject {
  options: TableOptions,
  totalRecords: number,
  pageSize: number,
  pageNumber: number,
  result?: any
}

export interface RespondentTableConfig {
  options?: Partial<TableOptions>,
  survey?: {
    title?: KeyNameDto[],
    key: string,
    excludeExisting?: boolean
  },
  tenantKey: string,
  companyKey: string,
  language: string
}
@Injectable({
  providedIn: null
})
export class RespondentsTableService extends WbBaseComponent {

  constructor(private respondentPoolService: RespondentPoolsService, private respondentService: RespondentService) {
    super();
  }


  init(config: RespondentTableConfig): Observable<RespondentTableFetchObject> {

    return combineLatest([this.pageSize$, this.pageNumber$, this.searchText$.pipe(debounceTime(300),
      distinctUntilChanged()), this.currentStatus$, this.refresh$, this.respondents$]).pipe(switchMap(([pageSize, pageNumber, searchText, currentStatus, refresh, respondents]) => {
        this.refreshIncrement = refresh;
        this.localPageSize = pageSize;
        this.localPageNumber = pageNumber;
        this.companyKey = config.companyKey;
        this.tenantKey = config.tenantKey;
        this.language = config.language;
        this.updateColumns();
        if (respondents.length && this.updateFromCache) {
          return of({
            items: respondents,
            totalCount: this.totalRecords
          })
        }
        if (config.survey != null && !config.survey?.excludeExisting)
          return this.respondentService.getList({
            surveyKey: config.survey.key,
            maxResultCount: pageSize,
            skipCount: pageNumber,
            filterText: searchText,
            isAllResults: false,
            status: currentStatus
          });

        else if (config?.survey?.excludeExisting)
          return this.respondentService.getCompanyRespondentsList({
            companyKey: config.companyKey,
            tenantKey: config.tenantKey,
            maxResultCount: pageSize,
            skipCount: pageNumber,
            surveyKey: config.survey.key,
            filters: [],
            filterText: searchText
          });
        else {
          return this.respondentPoolService.getAll({
            companyKey: config.companyKey,
            tenantKey: config.tenantKey,
            maxResultCount: pageSize,
            skipCount: pageNumber,
            filters: [],
            filterText: searchText
          });
        }
      })).pipe(map((x) => {
        this.updateFromCache = false;
        this.localRespondentList = x.items;
        this.totalRecords = x.totalCount;
        this.localAllSendReminderCount = x['allSendReminderCount'];
        this.localAllDeleteCount = x['allDeleteCount']
        this.options = {
          data: x.items,
          columns: this.displayedColumns,
          searchText: this.localize('txt_search', this.language, this.ModuleNames.Shared),
          colors: this.GlobalService.basicColors,
          sticky: true,
          selection: true,
          hidePagination: true,
          selectionRemove: [Status.Expired, Status.Completed, Status.Progress, Status.Inactive],
          disableSearch: true,
          whiteBackground: true
        };
        this.options = { ...this.options, ...config.options };
        let data: RespondentTableFetchObject = {
          options: this.options,
          totalRecords: this.totalRecords,
          pageSize: this.localPageSize,
          pageNumber: this.localPageNumber,
          result: x
        }
        return data;
      }));
  }

  respondents = new BehaviorSubject<RespondentPoolDto[] | RespondentDto[]>([]);
  respondents$ = this.respondents.asObservable();

  tableOptions = new Subject<TableOptions>();
  tableOptions$ = this.tableOptions.asObservable();



  pageNumber = new BehaviorSubject<number>(1);
  pageNumber$ = this.pageNumber.asObservable();

  pageSize = new BehaviorSubject<number>(5);
  pageSize$ = this.pageSize.asObservable();

  searchText = new BehaviorSubject<string>('');
  searchText$ = this.searchText.asObservable();


  currentStatus = new BehaviorSubject<any>(Status.All);
  currentStatus$ = this.currentStatus.asObservable();

  refresh = new BehaviorSubject<number>(1);
  refresh$ = this.refresh.asObservable();

  refreshIncrement: number;
  tenantKey;
  companyKey;
  options: TableOptions;
  public Status: Status;
  totalRecords
  displayedColumns
  selectAll: boolean = false;
  updateFromCache: boolean = false;
  language = 'EN';
  private localRespondentList;
  private localAllDeleteCount;
  private localAllSendReminderCount;
  private localPageSize;
  private localPageNumber;




  fetchFullList(surveyKey?) {
    if (surveyKey)
      return this.respondentService.getList({
        surveyKey: surveyKey,
        maxResultCount: 5,
        skipCount: 5,
        filterText: '',
        isAllResults: false,
      })
    else
      return this.respondentPoolService.getAll({
        companyKey: this.companyKey,
        tenantKey: this.tenantKey,
        maxResultCount: 5,
        skipCount: 5,
        filters: [],
        filterText: '',
        isAllResults: true
      })
  }
  updateColumns() {
    this.displayedColumns = [
      {
        field: 'employeeId',
        title: this.localize('txt_employee_ID', this.language, this.ModuleNames.Shared),
      },
      {
        field: 'name',
        title: this.localize('txt_respondent_name', this.language, this.ModuleNames.Shared),
      },
      {
        field: 'email',
        title: this.localize('txt_email_address', this.language, this.ModuleNames.Shared),
      },
      {
        field: 'action',
        title: '',
      },
    ];

  }
  updateOptions() {
    this.addSubscription(this.respondents$.subscribe(respondents => {
      this.localRespondentList = respondents;
      this.options = {
        data: respondents,
        columns: this.displayedColumns,
        searchText: this.localize('txt_search', this.language, this.ModuleNames.Shared),
        colors: this.GlobalService.basicColors,
        sticky: true,
        selection: true,
        hidePagination: true,
        disableSearch: true,
      }
      this.tableOptions.next(this.options);
    }))
  }

  addRespondent(respondent: any) {
    if (this.localPageNumber != 1)
      return this.pageNumber.next(1);
    this.updateFromCache = true;
    this.localRespondentList.unshift(respondent);
    if (this.localRespondentList.length > this.localPageSize) {
      this.localRespondentList.pop();
    }
    this.totalRecords++;
    this.respondents.next(this.localRespondentList);
  }
  removeRespondent(respondent: any) {
    this.updateFromCache = true;
    this.totalRecords--;
    // return this.refresh.next(this.refreshIncrement++)
    let deletedRespondent = this.localRespondentList.find(x => x.email == respondent.email);
    this.localRespondentList.splice(this.localRespondentList.indexOf(deletedRespondent), 1);
    if (!this.localRespondentList.length && this.totalRecords > 0)
      this.pageNumber.next(this.localPageNumber - 1);
    this.refresh.next(this.refreshIncrement++)
  }
}
