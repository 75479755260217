import { Direction } from '@angular/cdk/bidi';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from '@ex/module/core/services/general.service';
import { LocalService } from '@ex/module/core/services/local.service';
import { ZenSnackbarService, ZenSnackBarTypes } from '@ex/module/shared/Theme/services/zen-snackbar.service';

import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { environment } from 'src/environments/environment';
import { Model } from 'survey-angular';
import { localization } from 'survey-creator-core';
import { LanguageFilterService } from '../../data-access/services/language-filter.service';
import { SurveysService } from '../../data-access/services/surveys.service';
import { take } from 'rxjs/operators';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';

@Component({
  selector: 'app-language-page',
  templateUrl: './language-page.component.html',
  styleUrls: ['./language-page.component.scss'],
})
export class LanguagePageComponent implements OnInit {
  language = 'EN';
  ModuleNames = ModuleName;

  endPoint = environment.endPoint;
  survey: any = {};
  logoImage: any;

  ar = 'ar';
  en = 'en';
  fr = 'fr';
  es = 'es';
  de = 'de';
  it = 'it';
  ja = 'ja';
  ko = 'ko';
  zh = 'zh';
  ru = 'ru';
  hi = 'hi';
  pt = 'pt';
  nl = 'nl';
  sv = 'sv';
  pl = 'pl';
  tr = 'tr';
  vi = 'vi';
  th = 'th';
  fa = 'fa';
  ur = 'ur';

  form: any;

  sub: any;
  surveyId: any;

  direction: Direction = 'ltr';

  selectedLocaleFilter = '';
  selectedLocaleInStorage: string | null = '';

  localesList: any[] = [];
  sanitizer: any;

  constructor(
    private client: HttpClient,
    private languageFilterService: LanguageFilterService,
    private generalService: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private localService: LocalService,
    private surveysService: SurveysService,
    private zenSnackBarService : ZenSnackbarService,
    private translationPipe : TranslatePipe,
  ) {}

  async ngOnInit() {

    window.localStorage.setItem('localeLanguage', this.language.toLowerCase());
    this.languageFilterService.setSurveyLanguage(this.language.toLowerCase());


    this.surveyId = this.route.snapshot.paramMap.get('id');
      this.client.get<any>(
        this.endPoint + 'form-table/get-form-no-responses/' + this.surveyId
      ).pipe(take(1)).subscribe(x=>{
        this.sub = x.query;

        let surv = new Model(this.sub.jsonText);

        let surveyLanguages = surv.getUsedLocales();

        surveyLanguages.forEach((element) => {
          if (element == 'en') {
            this.localesList.push({ key: this.en, name: 'English' });
          } else {
            this.localesList.push({
              key: element,
              name: localization.getLocaleName(element),
            });
          }
        });

        if (this.sub.statusType != 2) {
          this.router.navigate(['survey-closed']);
        }

        if (this.sub.logo) {
          this.logoImage = this.sanitizer.bypassSecurityTrustUrl(this.sub.logo);
        }

        this.selectedLocaleInStorage =
          window.localStorage.getItem('localeLanguage');
        if (this.selectedLocaleInStorage) {
          this.selectedLocaleFilter = this.selectedLocaleInStorage;
        }

        if (this.selectedLocaleFilter == this.ar) {
          this.language = 'AR';
          this.direction = 'rtl';
        } else {
          this.direction = 'ltr';
          if (this.selectedLocaleFilter == this.en) {
            this.language = 'EN';
          }
        }
      })



  }

  async onLocaleFilterChanged(selectedValue: any) {
    this.selectedLocaleFilter = selectedValue;
    window.localStorage.setItem('localeLanguage', this.selectedLocaleFilter);
    this.languageFilterService.setSurveyLanguage(this.selectedLocaleFilter);
    if (selectedValue == this.ar) {
      this.language = 'AR';
      this.direction = 'rtl';
    } else {
      this.direction = 'ltr';
      if (selectedValue == this.en) {
        this.language = 'EN';
      }
    }

    this.localService.setJsonValue('language', this.language);
  }

  async onContinue() {
    if(this.selectedLocaleFilter && this.selectedLocaleFilter != '' && this.selectedLocaleFilter != null)
  {
    if (this.surveyId != null) {
      if (this.sub.categoryId == '9df42375-f351-4d48-be31-44994cb7b8c3') {
        this.router.navigate(['survey-x/survey/' + this.surveyId]);
      } else {
        if (this.sub.respondentInformationPage == true) {
          this.router.navigate(['survey-x/information/' + this.surveyId]);
        } else {
          if (this.sub.isWelcomeMessage == true) {
            this.router.navigate(['survey-x/startpage/' + this.surveyId]);
          } else {
            this.router.navigate(['survey-x/survey/' + this.surveyId]);
          }
        }
      }
    }

  }
else{
  this.showSnackBarMessage(this.translationPipe.transform("Please select a language!", this.language, this.ModuleNames.SurveyX), ZenSnackBarTypes.Error);
}


  }




  showSnackBarMessage(message: string, type: ZenSnackBarTypes) {
    this.zenSnackBarService.snackBar({
      message: message,
      type: ZenSnackBarTypes.Error
    });
  }
}
