import { ABP, CORE_OPTIONS, EnvironmentService } from "@abp/ng.core";
import { registerLocale } from "@abp/ng.core/locale";
import { APP_INITIALIZER, Injector } from "@angular/core";
import { abpEnvironment } from "src/environments/environment";

function coreOptionsFactory({ ...options }: ABP.Root) {
    return {
        ...options,
    } as ABP.Root;
}

export const AbpProviders = [
    {
        provide: 'CORE_OPTIONS',
        useValue: {
            environment: abpEnvironment,
            registerLocaleFn: registerLocale(),
        } as ABP.Root,
    },
    {
        provide: CORE_OPTIONS,
        useFactory: coreOptionsFactory,
        deps: ['CORE_OPTIONS']
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (injector: Injector) => {
            return () => {
                const envService = injector.get(EnvironmentService);
                envService.setState(abpEnvironment);
                return Promise.resolve();
            }
        },
        multi: true,
        deps: [Injector]
    },
]