import { Component, EventEmitter, Renderer2, Input, Output, ViewChild, ElementRef, ChangeDetectorRef, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { GeneralService } from 'src/app/core/services/general.service';
import { ChildrenAnimation, fadeUpDown } from 'src/app/shared/animations/allAnimations';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import HijriDate, { toHijri } from 'hijri-date/lib/safe';

enum Calendar {
  Gregorian = 'Gregorian',
  Hijri = 'Hijri'
}
interface DateObject {
  Gregorian: Date,
  Hijri: any
}

@Component({
  selector: 'z-datepicker',
  templateUrl: './zen-datepicker.component.html',
  styleUrls: ['./zen-datepicker.component.scss'],
  animations: [
    fadeUpDown, ChildrenAnimation
  ]
})
export class ZenDatepickerComponent implements ControlValueAccessor {
  Calendar = Calendar;
  calendarType: Calendar = Calendar.Gregorian;
  @ViewChild('trigger') trigger: ElementRef;
  @ViewChild('body') body: ElementRef;
  @ViewChild('outerBody') outerBody: ElementRef;
  ModuleNames = ModuleName

  //local variables
  @Input() rtl: boolean = false;
  @Input() required: boolean = false;

  triggerWidth;
  open: boolean = false;
  dropDown: boolean = false;
  date: DateObject = {} as DateObject;
  innerOpen: boolean = false;
  tDate: DateObject = {} as DateObject;
  minDate: DateObject = {} as DateObject;
  maxDate: DateObject = {} as DateObject;
  months: any[] = []

  // months = ["January", "February", "March", "April", "May", "June",
  //   "July", "August", "September", "October", "November", "December"];

  years: { Gregorian: any, Hijri: any }[] = [];
  days: DateObject[] = [];
  month = 'October';
  year = '2003';
  monthList: boolean = false;
  yearList: boolean = false;
  mobileView: boolean = false;
  reverse: boolean = false;
  // scroll events variables
  // inputs
  scollOn: boolean = true;
  @Input() ignoreDays = [];
  @Input() language: string = 'EN';
  @Input() shortDays = [];
  @Input('min') set givenMin(date: any) {
    if (date) {
      this.minDate.Gregorian = new Date(date);
      this.minDate.Hijri = toHijri(this.minDate?.Gregorian);
    }
    else this.minDate = null;
  }
  @Input('max') set givenMax(date: any) {
    if (date) {
      this.maxDate.Gregorian = new Date(date);
      this.maxDate.Hijri = toHijri(this.maxDate?.Gregorian);
    }
    else this.maxDate = null;
  }
  @Input() color = 'int';
  @Input() placeholder: any = 'DD/MM/YYYY';

  @Input() invalid = false;
  @Input() submitted = false;
  @Input() label = false;
  upper: boolean = false;
  goLeft: boolean = false;
  goRight: boolean = false;
  oppositeRight: boolean = false;
  oppositeLeft: boolean = false;
  @Input() disabled = false;
  // outputs

  disable: boolean = false;
  onChange: any = () => { };
  onTouch: any = () => { };


  constructor(private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef, private generalService: GeneralService, @Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }
  writeValue(selected: any) {
    console.log(selected);
    if (selected !== undefined && new Date(selected)?.getTime()) {
      this.date.Gregorian = new Date(selected);

      this.date.Hijri = this.toLocalHijriDate(this.date.Gregorian);
      this.tDate.Gregorian = new Date(this.date.Gregorian);
      this.tDate.Hijri = this.toLocalHijriDate(this.date.Gregorian);
    }
    else {
      this.date = {} as DateObject;
      this.tDate.Gregorian = new Date();
      this.tDate.Hijri = this.toLocalHijriDate(this.tDate.Gregorian);
    }
  }
  getMonthName(date: any) {
    if (this.calendarType == Calendar.Hijri)
      return this.months[date.Hijri.month - 1].Hijri
    return this.months[new Date(date.Gregorian).getMonth()].Gregorian
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  handleChange(option) {
    this.onChange(option);
  }
  close() {
    this.open = false;
    this.cdr.detectChanges();
  }


  // functions
  initDateDrop(e?) {
    this.months = [];
    this.shortDays = [];
    var m = this.generalService.monthNames
    m.forEach(x => {
      var mm = x.Gregorian.find(i => i.key == this.language).name
      var mh = x.Hijri.find(i => i.key == this.language).name
      this.months.push({ Gregorian: mm, Hijri: mh })

    })
    var d = this.generalService.days.filter(x => x.languge == this.language)
    if (d.length == 0)
      d = this.generalService.days.filter(x => x.languge == "EN")
    d.forEach(x => {
      this.shortDays.push(x.short)
    })

    this.reverse = false;
    this.mobileView = false;
    this.oppositeRight = false;
    this.oppositeLeft = false;
    this.open = true;
    this.innerOpen = true;

    let xValue = this.trigger.nativeElement.getBoundingClientRect().left;
    let yValue = this.trigger.nativeElement.getBoundingClientRect().top;
    let triggerHeight = this.trigger.nativeElement.getBoundingClientRect().height;
    this.triggerWidth = this.trigger.nativeElement.getBoundingClientRect().width;
    console.log(this.trigger.nativeElement.getBoundingClientRect());

    this.cdr.detectChanges();

    document.body.appendChild(this.outerBody.nativeElement);
    document.body.clientWidth < 768 ? this.mobileView = true : this.mobileView = false;
    document.body.dir == 'rtl' ? this.rtl = true : this.rtl = false;
    yValue + 50 > window.innerHeight / 2 ? this.reverse = true : this.reverse = false;

    console.log(this.reverse);

    if (!this.mobileView)
      if (this.rtl) {
        if (xValue < 400)
          this.oppositeLeft = true;

        this.renderer.setStyle(this?.body.nativeElement, 'transform', 'translate(' + (xValue + this.triggerWidth) + 'px, ' + (yValue + triggerHeight) + 'px)')
      }
      else {
        if (xValue > document.body.clientWidth - 400)
          this.oppositeRight = true;

        this.renderer.setStyle(this?.body.nativeElement, 'transform', 'translate(' + (xValue) + 'px, ' + (yValue + triggerHeight) + 'px)');
      }
    this.yearList = this.monthList = false;
    this.getYearList();
    this.getWholeMonth(this.tDate);
  }

  getWholeMonth(tdate: DateObject) {
    let date: DateObject = JSON.parse(JSON.stringify(tdate));
    date.Gregorian = new Date(date.Gregorian);
    date.Hijri = this.toLocalHijriDate(date.Gregorian);

    this.days = [];
    let firstMonthDay, firstSunday;
    if (this.calendarType != Calendar.Hijri) {
      firstMonthDay = new Date(date.Gregorian.setDate(1));
    }
    else {
      firstMonthDay = new HijriDate(date.Hijri.year, date.Hijri.month, 1)
      firstMonthDay = firstMonthDay.toGregorian();
    }


    firstSunday = new Date(firstMonthDay.setDate(firstMonthDay.getDate() - firstMonthDay.getDay()));

    for (let index = 0; index < 42; index++) {
      this.days.push({ Gregorian: new Date(firstSunday), Hijri: this.toLocalHijriDate(firstSunday) })
      firstSunday = new Date(firstSunday.setDate(new Date(firstSunday).getDate() + 1));
    }
    this.cdr.detectChanges();
  }


  hijriDate(dateHijri: any) {
    return (dateHijri.date + ' ' + this.months[dateHijri.month - 1].Hijri + ' ' + dateHijri.year);
  }


  getYearMonth(value, type, index) {
    this.yearList = this.monthList = false;

    if (this.calendarType == Calendar.Hijri) {
      if (type == 'm') {
        this.tDate.Hijri = new HijriDate(this.tDate.Hijri.year, index + 1, this.tDate.Hijri.date)
      }
      if (type == 'y') {
        this.tDate.Hijri = new HijriDate(value, this.tDate.Hijri.month, this.tDate.Hijri.date)
      }

      this.tDate.Gregorian = this.tDate?.Hijri.toGregorian();
    }
    else {
      if (type == 'm') {
        this.tDate.Gregorian = new Date(this.tDate.Gregorian.setMonth(index));
      }
      if (type == 'y') {
        this.tDate.Gregorian = new Date(this.tDate.Gregorian.setFullYear(value));
      }
      this.tDate.Hijri = this.toLocalHijriDate(this.tDate.Gregorian);
    }
    this.getWholeMonth(this.tDate)
  }
  getYearList() {
    this.years = [];
    let min, hijriMin;
    let max, hijriMax;
    if (this.maxDate && new Date(this.maxDate?.Gregorian).getTime() < new Date(this.tDate.Gregorian).getTime())
      this.tDate = this.maxDate;
    if (this.minDate && new Date(this.minDate?.Gregorian).getTime() > new Date(this.tDate.Gregorian).getTime())
      this.tDate = this.minDate


    min = this.minDate?.Gregorian?.getFullYear() ?? this.tDate.Gregorian?.getFullYear() - 20;
    max = this.maxDate?.Gregorian?.getFullYear() ? this.maxDate?.Gregorian?.getFullYear() + 1 : max = this.tDate.Gregorian?.getFullYear() + 20;
    hijriMin = this.minDate?.Hijri?.year ?? this.tDate.Hijri?.year - 20;
    hijriMax = this.maxDate?.Hijri?.year ? this.maxDate.Hijri?.year + 1 : this.tDate.Hijri?.year + 20;

    setTimeout(() => {
      for (let index = 0; index < (max - min); index++) {
        this.years.push({ Gregorian: min + index, Hijri: hijriMin + index });
      }

    }, 100);
  }

  clear(e?) {
    if (e)
      e.stopPropagation();
    this.date = null;
    this.tDate.Gregorian = new Date();
    this.tDate.Hijri = toHijri(this.tDate.Gregorian);
    this.open = false;
    this.onChange(null);
  }



  scroll(date, type) {
    let id;
    this.cdr.detectChanges();
    if (type == 'm')
      id = this.months[date.getMonth()];

    if (type == 'y')
      id = date.getFullYear();

    console.log(document.getElementById('a' + id.toString()));
    if (document.getElementById('a' + id.toString()))
      document.getElementById('a' + id.toString()).scrollIntoView({ behavior: 'auto', block: 'center' });
  }

  scrollControl(type, div) {
    if (type == 'y') {
      if (div.scrollTop < 100 && !this.minDate) {
        let lastYear = this.years.slice()[0];
        for (let index = 1; index < 10; index++) {
          this.years.unshift({ Gregorian: lastYear.Gregorian - index, Hijri: lastYear.Hijri - index });
        }
      }
      else if (div.scrollTop > div.scrollHeight - 150 && !this.maxDate) {
        console.log(this.years.slice(-1)[0]);
        let lastYear = this.years.slice(-1)[0];
        for (let index = 1; index < 10; index++) {
          this.years.push({ Gregorian: lastYear.Gregorian + index, Hijri: lastYear.Hijri + index })
        }
      }
      this.cdr.detectChanges();
    }
  }


  changeCalendarType() {
    if (this.calendarType == Calendar.Gregorian)
      this.calendarType = Calendar.Hijri;
    else
      this.calendarType = Calendar.Gregorian;
    this.initDateDrop()
  }
  chosenDay(day: DateObject) {
    this.date = { Gregorian: new Date(day.Gregorian), Hijri: this.toLocalHijriDate(day.Gregorian) }
    this.tDate = day;
    this.open = false;
    this.cdr.detectChanges();
    this.onChange(day.Gregorian);
  }

  sameDay(one, two) {
    if (new Date(one)?.toDateString() === new Date(two)?.toDateString() && new Date(this.date?.Gregorian)?.getMonth() === new Date(two)?.getMonth())
      return true;
    else return false;
  }
  todayCheck(date) {
    if (date?.toDateString() === new Date().toDateString())
      return true;
    else return false;
  }
  disabledDays(one, two) {
    let disable = false;
    // check if same day
    // if (new Date(one)?.getMonth() !== new Date(two)?.getMonth()) // if the two dates not in the same month
    //   return disable = true;
    if (this.ignoreDays?.length > 0)
      if (this.ignoreDays?.find(date => new Date(new Date(date)?.setHours(0, 0, 0, 0)).getTime() == new Date(new Date(two).setHours(0, 0, 0, 0)).getTime())) {
        disable = true;
      }

    if (new Date(one)?.getFullYear() !== new Date(two)?.getFullYear()) // same year check
      disable = true;

    if (this.minDate?.Gregorian instanceof Date && !isNaN(this.minDate?.Gregorian.getTime())) {
      if (new Date(this.minDate?.Gregorian?.setHours(0, 0, 0, 0)).getTime() > new Date(new Date(two).setHours(0, 0, 0, 0)).getTime())
        disable = true;
    }
    if (this.maxDate?.Gregorian instanceof Date && !isNaN(this.maxDate?.Gregorian.getTime())) {
      if (new Date(this.maxDate?.Gregorian?.setHours(0, 0, 0, 0)).getTime() < new Date(new Date(two).setHours(0, 0, 0, 0)).getTime())
        disable = true;
    }

    return disable;
  }

  logSDate(one, two) {
    if (new Date(two).getTime() + 86400000 < new Date(this.minDate?.Gregorian).getTime())
      console.log('its bigger date');

  }

  // othe functions


  logData(e) {
    console.log(e);

  }
  showValidation() {
    if (this.invalid && (!this.date?.Gregorian?.getTime() || !this.tDate?.Gregorian?.getTime()))
      return 'border-zen-red';
    else return 'border-transparent';
  }

  toLocalHijriDate(date: Date) {
    let year = date.toLocaleDateString('en-SA-u-ca-islamic-umalqura', { year: 'numeric' }).split(' ')[0];
    let month = date.toLocaleDateString('en-SA-u-ca-islamic-umalqura', { month: 'numeric' });
    let day = date.toLocaleDateString('en-SA-u-ca-islamic-umalqura', { day: 'numeric' });
    return new HijriDate(Number(year), Number(month), Number(day))
  }
}
