import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PsychometricConfig } from '../PsychometricConfig';

@Injectable({
  providedIn: 'root'
})
export class ThomasIntegrationService {
  api: string = PsychometricConfig.thomasEndPoint;
  integrationOrderApi: string = this.api + "thomas-integration/{tenantKey}/{companyKey}/{baseKey}";
  constructor(private http: HttpClient) { }
  IntegrateExcelData(file, tenantKey, companyKey) {
    const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream');

    return this.http.post(this.api + "thomas-integration/integrate-excel-data/" + tenantKey + "/" + companyKey, file, { headers: headers })
  }
  IntegrateOrderAssessment(integrateapi: string) {
    return this.http.get(integrateapi);
  }
}
