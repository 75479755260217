import { Injectable } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { EssentialObject, EssentialObjectService } from 'src/app/core/services/essential-object.service';
import { AnalyticsFilter, AnalyticsService } from './analytics.service';
import { AnalysisFilterDto, BasicRequest, CommentAnswerFormat, CommentCountDto, CommentsAnalysisRequest, CommentsTopicsDto, QuestionCommentFilterPagedDto, QuestionCommentPagedRequest, SectionSentimentDto, UpdateSentimentDto } from 'src/app/shared/ai-comments/interfaces/ai-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ModuleName } from 'src/app/shared/enums/ModuleName';
import { Entity } from 'src/app/core/data-models/Entity';
import { KeyNameDto } from '@ex/core/common';
import { IAiCommentsApi } from 'src/app/shared/ai-comments/interfaces/ai-comments-api';
import { CommentsService } from '@ex/wellbeing/comments';

@Injectable({
  providedIn: 'root'
})
export class WellbeingAiCommentsService implements IAiCommentsApi {

  sentiments: KeyNameDto[] = [{ key: 'negative', name: 'txtNegative' }, { key: 'neutral', name: 'txtNeutral' }, { key: 'positive', name: 'txtPositive' }, { key: 'noSentiment', name: 'txtNoSentiment' }]
  request = new BehaviorSubject<BasicRequest>(null);
  request$ = this.request.asObservable();
  mainRequest = new BehaviorSubject<BasicRequest>(null);
  mainRequest$ = this.mainRequest.asObservable();
  downloadAllComments = new Subject<boolean>();
  module = ModuleName.Wellbeing;
  selectedEntity: Entity;
  language: string;
  sectionOrderList?: string[]; // provide section keys order list for comments
  filters: AnalyticsFilter
  commentAnswers: CommentAnswerFormat[] = [{
    key: '0',
    color: "#FF4530",
  }, {
    key: '1',
    color: "#FF4530",
  },
  {
    key: '2',
    color: "#FF9600",
  },
  {
    key: '3',
    color: "#FFC51C",
  },
  {
    key: '4',
    color: "#B3DD37",
  },
  {
    key: '5',
    color: "#3EB75B",
  }]

  constructor(private commentsService: CommentsService, private analyticsService: AnalyticsService, private essentialService: EssentialObjectService) {
    this.essentialService.essentialObject.pipe(switchMap((obj: EssentialObject) => {
      return this.analyticsService.filters$.pipe(filter(x => x?.tenantKey != null)).pipe(map(filters => ({ filters, obj })))
    })).subscribe(({ filters, obj }) => {
      this.selectedEntity = obj.selectedEntity;
      this.language = obj.language;

      this.sectionOrderList = filters.survey.dimensions.map(x => x.key);
      this.filters = filters;
      this.mainRequest.next({
        companyKey: filters.companyKey,
        tenantKey: filters.tenantKey,
        surveyKey: filters.survey.key,
        filters: [...this.analyticsService.mapGlobalFilters(filters)]
      });
    })
  }


  downloadComments() {
    this.downloadAllComments.next(true);
  }

  getCommentsAIAnalysis(request: CommentsAnalysisRequest): Observable<SectionSentimentDto[]> {
    return this.commentsService.getCommentsAIAnalysis(request);
  }

  getCommentsTopicsList(surveyKey: string, filters: AnalysisFilterDto[]): Observable<CommentsTopicsDto[]> {
    return this.commentsService.getCommentsTopicsList(surveyKey, filters);
  }

  getPaginatedCommentsList(request: QuestionCommentPagedRequest): Observable<QuestionCommentFilterPagedDto> {
    return this.commentsService.getPaginatedCommentsList(request);
  }
  getSectionsCommentCount(surveyKey: string, Filters: AnalysisFilterDto[]): Observable<CommentCountDto> {
    return this.commentsService.getSectionsCommentCountBySurveyKeyAndFilters(surveyKey, Filters);
  }
  updateCommentsAIByRequest(request: UpdateSentimentDto): Observable<any> {
    return this.commentsService.updateCommentsAI({ ...request, companyKey: this.filters.companyKey, surveyKey: this.filters.survey.key, tenantKey: this.filters.tenantKey });
  }
}
