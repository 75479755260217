import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  modalInterval = false;
  openModal(v){
    this.modalInterval = true;
    setTimeout(() => {
      this[v] = true;
    }, 100);
  }
  closeModal(v){
    this[v] = false;
    setTimeout(() => {
    this.modalInterval = false;
    }, 300);
  }

}
