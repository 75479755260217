<div class="flex flex-col items-stretch cMaxH innerListScroll" *ngIf="surveyKey">
  <div class="flex flex-row items-stretch gap-10 innerListScroll pb-16">
    <div class="flex flex-col gap-2 fixedW200  justify-between items-start  overflow-hidden"
      (click)="logData(navButtons)">
      <div class="flex flex-col gap-2 items-stretch w-full whitespace-nowrap" [@listAnimation]="navButtons.length">
        <ng-container *ngFor="let button of navButtons; let n = index">
          <ng-container *ngTemplateOutlet="buttonTemplate;context:{$implicit:button, index:n}"></ng-container>
        </ng-container>
      </div>
    </div>
    <div [@biDirectionalState300]="step" class="flex flex-col items-stretch fixedW200Less overflow-x-hidden">
      <ng-container *ngIf="selectedEntity?.key">
        <form id="firstForm" #firstForm="ngForm"
          class="flex flex-col gap-8  innerListScroll overflow-x-hidden pb-5 items-stretch"
          (ngSubmit)="validateForm(firstForm)" *ngIf="step == 1">
          <div class="flex flex-col items-stretch gap-6">
            <div class="flex flex-row gap-2.5">
              <z-input [maxCount]="mdCount" [invalid]="id?.errors && (id.touched || firstForm.submitted)" class="w-1/3"
                [label]="'txt_leaver_employee_ID' | translate:language:ModuleNames.Exit" #id="ngModel" name="id"
                [(ngModel)]="leaver.employeeId"  [language]="language">
              </z-input>
              <z-input [maxCount]="mdCount" [invalid]="name?.errors && (name.touched || firstForm.submitted)"
                #name="ngModel" required="true" class="w-2/3"
                [label]="'txt_leaver_employee_name' | translate:language:ModuleNames.Exit" name="name"
                [(ngModel)]="leaver.name"  [language]="language">
              </z-input>
            </div>
            <z-input [maxCount]="mdCount" [invalid]="email?.errors && (email.touched || firstForm.submitted)"
              #email="ngModel" class="w-full" required="true"
              [label]="'txt_leaver_email' | translate:language:ModuleNames.Exit" name="email" [email]="true"
              [(ngModel)]="leaver.email" [disabled]="leaver.key" [language]="language"></z-input>
            <div class="flex flex-row  gap-2.5">
              <div class="w-1/2">
                <z-select [invalid]="countryCode?.errors && (countryCode.touched || firstForm.submitted)"
                  name="countryCode" [color]="'exp'" [(ngModel)]="leaver.countryCode" [label]="
                                'txt_country_code' | translate: language:ModuleNames.Common" [options]="countriesCodes"
                  [language]="language" #countryCode="ngModel">
                  <ng-template #valueTemplate let-value>
                    {{ value?.countryCode }}
                  </ng-template>
                  <ng-template #optionTemplate let-option>
                    {{ option?.name | extract: language }}
                    {{ "(" + option?.countryCode + ")" }}
                  </ng-template>
                </z-select>
              </div>
              <div class="flex flex-col  w-3/4">
                <z-input [maxCount]="mdCount" #phoneNumber="ngModel"
                  [invalid]="phoneNumber?.errors && (phoneNumber.touched || firstForm.submitted)" [number]="true"
                  name="phoneNumber" [language]="language"
                  [label]="'txt_PhoneNumber' | translate:language:ModuleNames.Exit" [(ngModel)]="leaver.phoneNumber">
                </z-input>
              </div>
            </div>
            <div class="flex flex-row  gap-2.5">
              <div class="flex flex-col gap-2 w-2/4">
                <z-radio *ngIf="genders.length < 3" #genderKey="ngModel"
                  [invalid]="genderKey?.errors && (genderKey.touched || firstForm.submitted)" [color]="'exp'"
                  required="true" [options]="genders" name="genderKey" [language]="language"
                  [label]="'txt_leaver_gender' | translate:language:ModuleNames.Exit" [(ngModel)]="leaver.genderKey">
                  <ng-template #optionTemplate let-option>
                    {{option?.name | extract:language}}
                  </ng-template>

                </z-radio>
                <z-select *ngIf="genders.length > 2" [color]="'exp'" required="true" name="genderKey"
                  [options]="genders" [language]="language"
                  [label]="'txt_leaver_gender' | translate:language:ModuleNames.Exit" [(ngModel)]="leaver.genderKey"
                  #genderKey="ngModel" [invalid]="genderKey?.errors && (genderKey.touched || firstForm.submitted)">
                  <ng-template #valueTemplate let-value>
                    {{value?.name | extract:language}}
                  </ng-template>
                  <ng-template #optionTemplate let-option>
                    {{option?.name | extract:language}}
                  </ng-template>
                </z-select>

              </div>
              <div class="flex flex-col gap-2 w-2/4">
                <z-datepicker [language]="language" [invalid]="firstForm.submitted" #dateOfBirth="ngModel"
                  required="true" [color]="'exp'" name="dateOfBirth"
                  [label]="'txt_leaver_date_of_birth' | translate:language:ModuleNames.Exit" [max]="maxDateOfBirth"
                  [language]="language" [(ngModel)]="leaver.dateOfBirth">
                </z-datepicker>
              </div>

            </div>
            <div class="flex flex-col gap-2 w-full">
              <z-select [color]="'exp'" required="true" name="nationalityKey" [options]="countriesList"
                [language]="language" [label]="'txt_leaver_nationality' | translate:language:ModuleNames.Exit"
                [(ngModel)]="leaver.nationalityKey" #nationalityKey="ngModel"
                [invalid]="nationalityKey?.errors && (nationalityKey.touched || firstForm.submitted)">
                <ng-template #valueTemplate let-value>
                  {{value?.name | extract:language}}
                </ng-template>
                <ng-template #optionTemplate let-option>
                  {{option?.name | extract:language}}
                </ng-template>
              </z-select>
            </div>
          </div>
        </form>
        <form id="secondForm" #secondForm="ngForm"
          class="flex flex-col gap-8  innerListScroll overflow-x-hidden pb-5 items-stretch"
          (ngSubmit)="validateForm(secondForm)" *ngIf="step == 2" (click)="logData({extraCriterias, leaver})">
          <div class="flex flex-col items-stretch gap-6">
            <div class="flex flex-row gap-5">
              <z-input class="w-1/2" [label]="'txt_leaver_job_title' | translate:language:ModuleNames.Exit"
                name="jobTitle" [invalid]="jobTitle?.errors && (jobTitle.touched || secondForm.submitted)"
                [maxCount]="mdCount" #jobTitle="ngModel" [(ngModel)]="leaver.jobTitle"></z-input>

              <div class="w-1/2">
                <z-select [disabled]="leaver?.key" required="true" name="SeperationType" [color]="'exp'"
                  [(ngModel)]="leaver.seperationType"
                  [label]="'txt_leaver_seperation_type' | translate:language:ModuleNames.Exit"
                  [options]="seperationTypes" [language]="language"
                  [invalid]="seperationType?.errors && (seperationType.touched || secondForm.submitted)"
                  [maxCount]="mdCount" #seperationType="ngModel">
                  <ng-template #valueTemplate let-value>
                    {{ value?.name | extract:language }}
                  </ng-template>

                </z-select>
              </div>

            </div>


            <div class="flex flex-row gap-5">
              <z-input [invalid]="directManagerId?.errors && (directManagerId.touched || secondForm.submitted)"
                class="w-1/3" [maxCount]="mdCount"
                [label]="'txt_direct_manager_ID' | translate:language:ModuleNames.Exit" #directManagerId="ngModel"
                name="directManagerId" [(ngModel)]="leaver.directManagerId">
              </z-input>
              <z-input class="w-2/3" [maxCount]="mdCount"
                [label]="'txt_leaver_direct_manager_name' | translate:language:ModuleNames.Exit"
                name="directManagerName" [(ngModel)]="leaver.directManagerName">
              </z-input>
            </div>
            <div (click)="logData(selectedEntity?.BandLevels)" class="flex flex-row flex-wrap -mr-2.5 -ml-2.5">
              <div class="flex flex-col gap-2 w-2/4 px-2.5 pt-6" *ngIf="selectedEntity?.BandLevels?.length">
                <z-select [invalid]="bandLevel?.errors && (bandLevel.touched || secondForm.submitted)" [color]="'exp'"
                  name="bandLevel" (ngModelChange)="logData(leaver)" [options]="selectedEntity.BandLevels"
                  [(ngModel)]="leaver.bandLevel" [language]="language"
                  [label]="'txt_leaver_job_level' | translate:language:ModuleNames.Exit" #bandLevel="ngModel">
                  <ng-template #valueTemplate let-value>
                    {{value?.name | extract:language}}
                  </ng-template>

                </z-select>
              </div>
              <div *ngIf="selectedEntity?.JobGrades?.length" class="flex flex-col gap-2 w-2/4 px-2.5 pt-6">
                <z-select [invalid]="jobGrade?.errors && (jobGrade.touched || secondForm.submitted)" [color]="'exp'"
                  name="jobGrade" [options]="selectedEntity.JobGrades" [(ngModel)]="leaver.jobGrade"
                  [language]="language" [label]="'txt_leaver_job_grade' | translate:language:ModuleNames.Exit"
                  #jobGrade="ngModel">
                  <ng-template #valueTemplate let-value>
                    {{value?.name | extract:language}}
                  </ng-template>

                </z-select>
              </div>
              <div *ngIf="selectedEntity?.PerformanceRates?.length" class="flex flex-col gap-2 w-2/4 px-2.5 pt-6">
                <z-select [invalid]="performanceRate?.errors && (performanceRate.touched || secondForm.submitted)" [color]="'exp'"
                  name="performanceRate" [options]="selectedEntity.PerformanceRates" [(ngModel)]="leaver.performanceRating"
                  [language]="language" [label]="'txt_leaver_performance_rate' | translate:language:ModuleNames.Exit"
                  #performanceRate="ngModel">
                  <ng-template #valueTemplate let-value>
                    {{value?.value}}
                  </ng-template>

                </z-select>
              </div>

              <!-- Dynamic Criterias -->
              <ng-container *ngFor="let extra of extraCriterias">
                <ng-container *ngFor="let leaverCriteria of leaver.extraCriterias">
                  <div class="flex flex-col gap-2 w-2/4 px-2.5 pt-6" *ngIf="leaverCriteria.key == extra.key">
                    <z-select [id]="extra.key" [name]="extra.key" [color]="'exp'" [(ngModel)]="leaverCriteria.value"
                      [label]="extra.name | extract:language" [options]="extra.options" [language]="language"
                      [maxCount]="mdCount">
                      <ng-template #valueTemplate let-value>
                        {{ value?.name | extract:language }}
                      </ng-template>

                    </z-select>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="leaver?.entityHierarchy?.length">
                <ng-container *ngFor="let eH of getEntityHierarchy(); let i = index">
                  <div *ngIf="eH.entities.length" class="flex flex-col gap-2 w-2/4 px-2.5 pt-6">
                    <z-select [color]="'exp'" [options]="eH.entities" [disabled]="eH.readOnly"
                      [(ngModel)]="eH.selectedEntityKey"
                      (selectionChange)="constructEntityHierarchy(leaver?.entityHierarchy, allEntities,eH)"
                      [language]="language" [label]="eH.name | extract:language" [name]="'entity' + i">
                      <ng-template #valueTemplate let-value>
                        {{value?.name | extract:language}}
                      </ng-template>
                      <ng-template #optionTemplate let-option>
                        {{option?.name | extract:language}}
                      </ng-template>
                    </z-select>
                  </div>
                </ng-container>
              </ng-container>

              <div class="flex flex-col gap-2 w-2/4 px-2.5 pt-6">
                <z-datepicker [language]="language" required="true" [color]="'exp'" name="joiningDate"
                  [label]="'txt_leaver_joining_date' | translate:language:ModuleNames.Exit" [max]="today"
                  [language]="language" [(ngModel)]="leaver.joiningDate"
                  [invalid]="joiningDate?.errors && (joiningDate.touched || secondForm.submitted)" [maxCount]="mdCount"
                  #joiningDate="ngModel">
                </z-datepicker>
              </div>

              <div class="flex flex-col gap-2 w-2/4 px-2.5 pt-6">
                <z-datepicker [language]="language" required="true" [min]="leaver.joiningDate" [color]="'exp'" name="seperationDate"
                  [label]="'txt_leaver_seperation_date' | translate:language:ModuleNames.Exit" [language]="language"
                  [(ngModel)]="leaver.seperationDate" [invalid]="secondForm.submitted" [maxCount]="mdCount"
                  #seperationDate="ngModel">
                </z-datepicker>
              </div>
              <!-- <div class="flex flex-col gap-2 w-2/4 px-2.5 pt-5">
                                <z-select [invalid]="template?.errors && (template.touched || secondForm.submitted)"
                                    required="true" [color]="'exp'" [options]="templates"
                                    (ngModelChange)="leaverTemplate($event)" [(ngModel)]="leaver.surveyKey"
                                    [language]="language" label="Survey Template" #template="ngModel">
                                    <ng-template #valueTemplate let-value>
                                        {{value?.name | extract:language}}
                                    </ng-template>

                                </z-select>
                            </div> -->
            </div>
          </div>
        </form>
        <form id="thirdForm" #thirdForm="ngForm"
          class="flex flex-col gap-8  innerListScroll overflow-x-hidden pb-5 items-stretch"
          (ngSubmit)="validateForm(thirdForm)" *ngIf="showTemplateSection()">
          <div class="flex flex-col items-stretch gap-8">
            <div class="flex flex-col gap-2.5 pb-7">
              <h5 class="text-xs uppercase text-zen font-medium">{{'Template' |
                translate:language:ModuleNames.Exit}}</h5>
              <div class="flex flex-col gap-5">
                <div class="flex flex-col items-stretch bg-white p-2.5 rounded max-h-56 innerListScroll">
                  <div (click)="onTemplateClick(template)"
                    [ngClass]="template.isDraft?'opacity-60 text-zen pointer-events-none': leaver.surveyKey == template.key?'text-exp bg-exp bg-opacity-10 font-medium':'hover:bg-zen hover:bg-opacity-5 text-zen'"
                    class="flex flex-row items-center justify-between  rounded px-5 btn"
                    *ngFor="let template of templates">
                    <div class="flex flex-row items-center gap-2.5">
                      <i [ngClass]="'fa-browser'" class="fa-regular"></i>
                      <p class="text-sm h-12 leading-12">{{template.name | extract:language}}</p>

                      <i *ngIf="template.isPredefined" class="opacity-90 text-xs fa-regular fa-lock"></i>
                    </div>
                    <i *ngIf="template.isDraft" class="fa-regular fa-cloud-exclamation"></i>
                    <i *ngIf="leaver.surveyKey == template.key" class="fa-regular fa-check-circle"></i>
                  </div>
                </div>

                <validation-required *ngIf="!leaver.surveyKey && thirdForm.submitted" [language]="language">
                </validation-required>

              </div>
            </div>
          </div>
        </form>
        <form id="fourthForm" #fourthForm="ngForm"
          class="flex flex-col gap-8  innerListScroll overflow-x-hidden pb-5 items-stretch"
          (ngSubmit)="validateForm(fourthForm)" *ngIf="step > 2 && step == navButtons.length">
          <div class="flex flex-col items-stretch gap-8">
            <div class="flex flex-col gap-5 pb-7">
              <h5 class="text-xs uppercase text-zen font-medium">{{'txt_timeframe' |
                translate:language:ModuleNames.Exit}}</h5>
              <div class="flex flex-row gap-5">
                <div class="flex flex-row items-end gap-2.5 w-1/2">
                  <i class="fa-light fa-calendar-pen text-zen text-4xl"></i>
                  <div class="flex flex-col items-stretch w-full">
                    <z-datepicker [language]="language" [disabled]="disableStartDate()" [invalid]="fourthForm.submitted"
                      [required]="true" [color]="'exp'" name="startDate"
                      [label]="'txt_start_date' | translate:language:ModuleNames.Exit" [max]="leaver.endDate"
                      [min]="today" [language]="language" [(ngModel)]="leaver.startDate" #startDate="ngModel">
                    </z-datepicker>
                  </div>
                </div>
                <div class="flex flex-row items-end gap-2.5 w-1/2">
                  <i class="fa-light fa-calendar-exclamation text-zen text-4xl"></i>
                  <div class="flex flex-col items-stretch w-full">
                    <z-datepicker [language]="language" [disabled]="leaver.status == status.Completed"
                      [invalid]="fourthForm.submitted" required="true" [color]="'exp'" #endDate="ngModel" name="endDate"
                      [label]="'txt_end_date' | translate:language:ModuleNames.Exit" [min]="minDateForEndDate()"
                      [language]="language" [(ngModel)]="leaver.endDate">
                    </z-datepicker>

                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col gap-5">
              <div class="flex flex-row items-center justify-between">
                <h5 class="text-xs uppercase text-zen font-medium">{{'txtAutomatedReminders' |
                  translate:language:ModuleNames.Exit}}</h5>

                <a *ngIf="leaver.status != status.Completed" (click)="addReminder(leaver.reminders)"
                  class="btn flex flex-row gap-1 items-center text-exp font-medium text-xs">
                  <i class="fa-regular fa-plus"></i>
                  <span>{{'txt_add_reminder' | translate:language:ModuleNames.Exit}}</span>
                </a>
              </div>
              <div class="flex flex-row flex-wrap -mr-2.5 -ml-2.5">
                <div class="flex flex-row items-end gap-2.5 w-1/2 px-2.5 pb-7"
                  *ngFor="let reminder of leaver.reminders; let i = index">
                  <i class="fa-light fa-bell text-zen text-4xl relative"><span
                      class="absolute text-sm left-0 text-center w-full leading-10 font-semibold">{{i
                      + 1}}</span></i>
                  <div class="flex flex-col items-stretch relative w-full">
                    <div class="flex flex-row w-full justify-end h-0 px-2">
                      <i class="fa-regular fa-times btn textred text-xs delete text-center z-20 deleteBtn"
                        (click)="deleteReminder(leaver.reminders, reminder)"></i>
                    </div>
                    <z-datepicker [language]="language" [disabled]="disablePreviousDate(reminder.date)"
                      [invalid]="fourthForm.submitted" [required]="true" [color]="'exp'"
                      [label]="'txt_SendReminderOn' | translate:language:ModuleNames.Exit"
                      [min]="plusOneDay(leaver.startDate, true)" #reminder="ngModel" [max]="minusOneDay(leaver.endDate)"
                      [ignoreDays]="getReminders(leaver.reminders, i)" [language]="language" [ngModel]="reminder.date"
                      (ngModelChange)="setReminder($event, i)" [name]="'reminder-'+ i" [id]="'reminder-'+ i">
                    </z-datepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col gap-5">
              <div>
                <h5 class="text-xs uppercase text-zen font-medium">{{'txtEmailTemplates' |
                  translate:language:ModuleNames.Exit}}</h5>
                <!-- <h6 class="text-xs text-zen-lite font-regular">Create or edit the invitation/reminder
                                    template
                                    below, or
                                    use one of the premade templates:</h6> -->
              </div>
              <div class="flex flex-col items-stretch">
                <div class="flex flex-row gap-2.5 text-xs">
                  <div (click)="invitationClick()"
                    [ngClass]="{'text-zen font-medium bg-white':invitation,'text-zen-lite font-normal bg-zen bg-opacity-10':!invitation, 'pointer-events-none opacity-70': leaver.status != status.Posted && leaver.key}"
                    class="flex flex-row items-center justify-center btn gap-2.5 w-1/2 rounded-t border-l border-r border-t border-zen border-opacity-10 leading-14">
                    <i class="fa-regular fa-envelope-open-text"></i>
                    <span>{{'txtInvitationEmailTemplate' |
                      translate:language:ModuleNames.Exit}}</span>
                  </div>
                  <div (click)="invitation = false"
                    [ngClass]="!invitation?'text-zen font-medium bg-white':'text-zen-lite font-normal bg-zen bg-opacity-10'"
                    class="flex flex-row items-center justify-center btn gap-2.5 w-1/2 rounded-t border-l border-r border-t border-zen border-opacity-10 leading-14">
                    <i class="fa-regular fa-bell"></i>
                    <span>{{'txtReminderEmailTemplate' |
                      translate:language:ModuleNames.Exit}}</span>
                  </div>
                </div>
                <div class="flex pb-2.5 px-2.5 bg-white rounded-b border-l border-r border-zen border-opacity-10 pt-5">
                  <div *ngIf="invitation == true" (click)="logData(leaver)">
                    <zen-editor (reset)="resetEmailTemplate(1)" [language]="language"
                      [(emailObject)]="leaver.invitationEmailTemplate">
                    </zen-editor>
                  </div>
                  <div *ngIf="invitation == false" (click)="logData(leaver)">
                    <zen-editor (reset)="resetEmailTemplate(2)" [language]="language"
                      [(emailObject)]="leaver.reminderEmailTemplate">
                    </zen-editor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

      </ng-container>
    </div>
  </div>
  <div class="flex flex-row justify-between items-center">
    <div>
      <a *ngIf="(leaver?.key && step > 1) || !leaver?.key" (click)="step == 1? resetAll() : step = step - 1;"
        class="btn cancel text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1 z-50">
        <i class="fa-regular" [ngClass]="(language | language)?'fa-chevron-right':'fa-chevron-left'"></i>
        <span>{{'btn_back' | translate:language:ModuleNames.Exit}}</span>
      </a>
    </div>

    <button type="submit" [ngClass]="!isLastSection() ?'exp':'green'" [attr.form]="getForm()"
      class="btn text-center flex flex-row items-center rounded px-4 leading-9 text-xs font-medium gap-1">
      <span>{{(!isLastSection() ? 'txt_Next' : 'txt_Submit') | translate:language:ModuleNames.Exit }}</span>
      <i class="fa-regular" [ngClass]="(language | language)?'fa-chevron-left':'fa-chevron-right'"></i>

    </button>
  </div>
</div>


<ng-template #buttonTemplate let-button let-index="index">

  <a [ngClass]="step == index+1?'bg-exp text-exp font-medium':'text-zen'"
    class="flex flex-row items-center gap-2 leading-12 rounded text-sm px-4 text-zen bg-opacity-10">
    <span [ngClass]="step == index+1?'bg-exp text-white':'bg-zen-lite text-zen-lite bg-opacity-20'"
      class="w-5 h-5 leading-5 text-center rounded-sm font-medium px-1">{{index+1}}</span>
    <h5>{{button.name | translate:language:ModuleNames.Exit}}</h5>
  </a>
</ng-template>
