<nav class="px-10 pt-10 pb-4 bg-white top-0 left-0 right-0 z-50" [ngClass]="{'rtl': (language | language)}"
  style="box-shadow: 2px 2px 4px 0px rgba(74, 95, 130, 0.10);">
  <div class="flex  gap-6 justify-between items-center self-stretch">
    <div class="flex items-center w-max gap-4 h-10">
      <a class="flex h-8 p-2 items-center gap-2 rounded text-zen-primarylight font-medium text-xs"
        [routerLink]="'/survey-x/form-library'">
        <i [ngClass]="(language | language)?'fa-angle-right':'fa-angle-left'" class="fa-solid "></i>
        {{'txt_Back'| translate:language:ModuleNames.SurveyX }}
      </a>
      <h5 class="text-zen-primarydefault text-lg font-medium max-w-xs truncate">{{surveyTitle}}</h5>
    </div>

    <div class="items-center justify-between hidden w-full md:flex md:w-auto" id="navbar-sticky">
      <ol class="flex gap-1">
        <li>
          <div class="flex gap-1 items-center breadcrumb-item text-zen-primarylight btn"
            [ngClass]="getRouterLinkCssClass('initial-setup')">
            <a (click)="redirctTo('initial-setup')"
              class="inline-flex items-center text-sm font-medium hover:cursor-pointer">
              {{'Initial Setup'| translate:language:ModuleNames.SurveyX }}
            </a>

            <i [ngClass]="(language | language)?'fa-angle-left':'fa-angle-right'"
              class="far flex justify-center items-center w-5 h-5"></i>
          </div>
        </li>

        <li>
          <div class="flex gap-1 items-center breadcrumb-item text-zen-primarylight btn"
            [ngClass]="getRouterLinkCssClass('questions-builder')">
            <a (click)="redirctTo('questions-builder')"
              class="inline-flex items-center text-sm font-medium hover:cursor-pointer">
              {{'Question Builder'| translate:language:ModuleNames.SurveyX }}
            </a>

            <i [ngClass]="(language | language)?'fa-angle-left':'fa-angle-right'"
              class="far flex justify-center items-center w-5 h-5"></i>
          </div>
        </li>

        <li *ngIf="false">
          <div class="flex gap-1 items-center breadcrumb-item text-zen-primarylight btn"
            [ngClass]="getRouterLinkCssClass('assign-weights')">
            <a (click)="redirctTo('assign-weights')"
              class="inline-flex items-center text-sm font-medium hover:cursor-pointer">
              {{'Assign Weights'| translate:language:ModuleNames.SurveyX }}
            </a>

            <i [ngClass]="(language | language)?'fa-angle-left':'fa-angle-right'"
              class="far flex justify-center items-center w-5 h-5"></i>
          </div>
        </li>

        <li>
          <div class="flex gap-1 items-center breadcrumb-item text-zen-primarylight btn"
            [ngClass]="getRouterLinkCssClass('languages')">
            <a (click)="redirctTo('languages')"
              class="inline-flex items-center text-sm font-medium hover:cursor-pointer">
              {{'Languages'| translate:language:ModuleNames.SurveyX }}
            </a>

            <i [ngClass]="(language | language)?'fa-angle-left':'fa-angle-right'"
              class="far flex justify-center items-center w-5 h-5"></i>
          </div>
        </li>

        <li>
          <div class="flex gap-1 items-center breadcrumb-item text-zen-primarylight btn"
            [ngClass]="getRouterLinkCssClass('analytical-criteria')">
            <a (click)="redirctTo('analytical-criteria')"
              class="inline-flex items-center text-sm font-medium hover:cursor-pointer">
              {{'Analytical Criteria'| translate:language:ModuleNames.SurveyX }}
            </a>

            <i [ngClass]="(language | language)?'fa-angle-left':'fa-angle-right'"
              class="far flex justify-center items-center w-5 h-5"></i>
          </div>
        </li>

        <li>
          <div class="flex gap-1 items-center breadcrumb-item text-zen-primarylight btn"
            [ngClass]="getRouterLinkCssClass('respondents')">
            <a (click)="redirctTo('respondents')"
              class="inline-flex items-center text-sm font-medium hover:cursor-pointer">
              {{'Respondents'| translate:language:ModuleNames.SurveyX }}
            </a>

            <i [ngClass]="(language | language)?'fa-angle-left':'fa-angle-right'"
              class="far flex justify-center items-center w-5 h-5"></i>
          </div>
        </li>

        <li>
          <div class="flex gap-1 items-center breadcrumb-item text-zen-primarylight btn"
            [ngClass]="getRouterLinkCssClass('share')">
            <a (click)="redirctTo('share')" class="inline-flex items-center text-sm font-medium hover:cursor-pointer">
              {{'txtShare'| translate:language:ModuleNames.SurveyX }}
            </a>
          </div>
        </li>
      </ol>
    </div>

    <div class="flex w-max items-center text-center gap-4">
      <button class="flex w-12 h-12 justify-center items-center text-zen-primarylight text-xl">
        <i [ngClass]="isUpdating?'fa-cloud-arrow-up text-zen-green pulse':'fa-cloud-check'"
          class="far rounded-full  w-6 h-6"
          [matTooltip]="'Progress is saved automatically' | translate:language:ModuleNames.SurveyX"
          matTooltipPosition="above" matTooltipClass="zen-tooltip">
        </i>
      </button>
      <div class="flex items-center gap-4">
        <button *ngIf="currentPage != 'initial-setup'"
          class="btn cancel h-12 w-12 flex justify-center items-center rounded bg-zen-littlegray text-zen-primarylight text-sm font-medium"
          (click)="previousStep.emit()">
          <i [ngClass]="!(language | language)?'fa-chevron-left':'fa-chevron-right'" class="fa-regular w-4"></i>
        </button>

        <button *ngIf="currentPage !='share' ;else shareButton"
          class="btn exp h-12 w-12 flex justify-center items-center rounded bg-exp text-white text-sm font-medium"
          (click)="nextStep.emit()">
          <i [ngClass]="(language | language)?'fa-chevron-left':'fa-chevron-right'" class="fa-regular w-4"></i>
        </button>

        <ng-template #shareButton>
          <button
            class="btn exp h-12 px-5 flex justify-center items-center rounded bg-exp text-white text-sm font-medium"
            (click)="share.emit()">
            {{'Publish'| translate:language:ModuleNames.SurveyX }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</nav>
