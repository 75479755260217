<div class="flex flex-row justify-between items-center">

    <h2 class="text-lg font-normal text-zen-primarydefault">
        {{"txtCommentsperSection"| translate:language:ModuleNames.SurveyX}}
    </h2>

    <z-select [color]="'exp'" [label]="'Section'| translate:language:ModuleNames.SurveyX" labelPosition="before"
        class="placeholder:fixed" [(ngModel)]="selectedSectionKey" [options]="sections" [language]="language">
        <ng-template #valueTemplate let-value>
            {{value?.title |sjsTranslate:language}}
        </ng-template>
    </z-select>
</div>

<div class="flex flex-col items-stretch w-full pt-4 gap-4">
    <ng-container *ngFor="let question of questions | sjQuestionFilter:selectedSectionKey; let i = index">

        <sx-question-comments [question]="question" [filters]="filters" [language]="language"
            [questionNo]="getQuestionNumber(question.name)" [sectionKey]="selectedSectionKey"></sx-question-comments>

    </ng-container>
</div>