/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { FormTableRespondent } from '../models/FormTableRespondent';
import type { LiveFormFilter } from '../models/LiveFormFilter';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class FormTableRespondentService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns FormTableRespondent Success
     * @throws ApiError
     */
    public getAllFormTableRespondents(): Observable<Array<FormTableRespondent>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-table-respondents/get-all-form-table-respondents',
        });
    }

    /**
     * @param formId 
     * @param tenantId 
     * @param companyId 
     * @returns any Success
     * @throws ApiError
     */
    public getAllFormTableRespondentsByForm(
formId: string,
tenantId: string,
companyId: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-table-respondents/get-all-form-table-respondents-by-form/{formId}/{tenantId}/{companyId}',
            path: {
                'formId': formId,
                'tenantId': tenantId,
                'companyId': companyId,
            },
        });
    }

    /**
     * @param formId 
     * @param tenantId 
     * @param companyId 
     * @param pageNumber 
     * @param pageSize 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public getAllFormTableRespondentsByFormWithPagination(
formId: string,
tenantId: string,
companyId: string,
pageNumber: number,
pageSize: number,
requestBody?: LiveFormFilter,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-table-respondents/get-all-form-table-respondents-by-form-with-pagination/{formId}/{tenantId}/{companyId}/{pageNumber}/{pageSize}',
            path: {
                'formId': formId,
                'tenantId': tenantId,
                'companyId': companyId,
                'pageNumber': pageNumber,
                'pageSize': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @param tenantId 
     * @param companyId 
     * @returns FormTableRespondent Success
     * @throws ApiError
     */
    public getFormTableRespondent(
id: string,
tenantId: string,
companyId: string,
): Observable<Array<FormTableRespondent>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-table-respondents/get-form-table-respondent-by-respondent-id/{id}/{tenantId}/{companyId}',
            path: {
                'id': id,
                'tenantId': tenantId,
                'companyId': companyId,
            },
        });
    }

    /**
     * @param formId 
     * @returns FormTableRespondent Success
     * @throws ApiError
     */
    public getFormTableRespondentByFormId(
formId: string,
): Observable<FormTableRespondent> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-table-respondents/get-form-table-respondent-by-form-id/{formId}',
            path: {
                'formId': formId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FormTableRespondent Success
     * @throws ApiError
     */
    public addFormTableRespondent(
requestBody?: FormTableRespondent,
): Observable<FormTableRespondent> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-table-respondents/add-form-table-respondent',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param formId 
     * @param respondentId 
     * @param tenantId 
     * @param companyId 
     * @param requestBody 
     * @returns FormTableRespondent Success
     * @throws ApiError
     */
    public updateFormTableRespondentFeedback(
formId: string,
respondentId: string,
tenantId: string,
companyId: string,
requestBody?: FormTableRespondent,
): Observable<FormTableRespondent> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/form-table-respondents/update-form-table-respondent-feedback/{formId}/{respondentId}/{tenantId}/{companyId}',
            path: {
                'formId': formId,
                'respondentId': respondentId,
                'tenantId': tenantId,
                'companyId': companyId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param formId 
     * @param respondentId 
     * @param tenantId 
     * @param companyId 
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteRespondentFromForm(
formId: string,
respondentId: string,
tenantId: string,
companyId: string,
): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/form-table-respondents/delete-respondent-from-form/{formId}/{respondentId}/{tenantId}/{companyId}',
            path: {
                'formId': formId,
                'respondentId': respondentId,
                'tenantId': tenantId,
                'companyId': companyId,
            },
        });
    }

}
