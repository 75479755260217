<div class="flex flex-col gap-12">
    <div class="flex flex-col items-stretch">
        <div class="flex h-28 flex-col justify-end pb-7">
            <h6 [@zero] class="uppercase text-xxs text-zen font-medium px-7">{{'txtSwitchTemplate' |
                translate:language:ModuleNames.Exit}}</h6>
        </div>
        <app-dual-dropdown-selection *ngIf="templates" [data]="templates" [(ngModel)]="selectedTemplateKey" [(ngModel)]="selectedTemplate" (ngModelChange)="onTemplateChange($event)" [TourStep]="tourKey?{title:'txt_SelectTem_385' | translate:language:ModuleNames.Exit,text: 'txt_Clickhere_611' | translate:language:ModuleNames.Exit,order: 2,tourKey:tourKey,on:(language|language)?'left':'right'}:undefined">
        </app-dual-dropdown-selection>
    </div>

    <div class="flex flex-col gap-4" [TourStep]="tourKey?{title:'txtSurveyStatus' | translate:language:ModuleNames.Exit,text: 'txt_Clickhere_234' | translate:language:ModuleNames.Exit,order: 4,tourKey:tourKey,on:(language|language)?'left':'right'}:undefined">
        <h6 [@zero] class="uppercase text-xxs text-zen font-medium px-7">{{'txtSurveyStatus' |
            translate:language:ModuleNames.Exit}}</h6>
        <div class="flex flex-col items-stretch">
            <status-button *ngFor="let item of buttonList" (click)="onButtonChange(item.key)" [active]="currentStatus == item?.key" [status]="item.key?.length > 1? item?.key:''" [data]="item?.value">
                <ng-container text>{{item.key?.length > 1? resolveSurveyStatus(item?.key,language) : (item.name|
                    translate:language:ModuleNames.Exit)}}</ng-container>
            </status-button>
        </div>
    </div>
</div>