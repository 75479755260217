<div *ngIf="language" class="fixed flex flex-col items-stretch top-0 left-0 Page h-screen w-screen z-50 bg-zen-background">

  <app-form-builder-header [surveyId]="surveyId" [surveyTitle]="surveyForm?.title | sjsTranslate:language" [currentPage]="'initial-setup'"
    [showAssignWeights]="false" [language]="language" [disableLinks]="!isEditing" (nextStep)="onNext()"
    (pageChanged)="pageChanged($event)">
  </app-form-builder-header>

  <div class="w-full h-100vh-110px innerListScroll">
    <div class="flex justify-center" *ngIf="surveyForm">
      <div class="w-1/2 py-8 mt-5">
        <div class="contact-form">
          <div class="sd-header__text mb-4">
            <h3 class=" text-zen">
              {{'Basic Information'| translate:language:ModuleNames.SurveyX }}
            </h3>
          </div>
          <div *ngIf="surveyForm"
            class="flex flex-row flex-wrap w-660 bg-white items-center pt-5 pb-10 mb-8 gap-1 justify-center rounded-md">


            <!-- <div class="flex flex-col px-10 pt-2 w-full">
              <div class="form-group">
                <label>{{'Form Title'| translate:language:ModuleNames.SurveyX }}</label>
                <div class="form-control flex flex-col items-stretch">
                  <sv-ng-string-editor class="w-full flex flex-col items-stretch"
                    [model]="surveyCreatorModel?.survey.locTitle"></sv-ng-string-editor>
                </div>
              </div>
              <div *ngIf="surveyCreatorModel?.survey.locTitle.text?.length > mdCount -5"
                class="text-red-600 text-xxs flex flex-row items-center gap-1 leading-5">
                {{mdCount}}/{{surveyCreatorModel?.survey.locTitle.text?.length}}</div>
            </div> -->

            <div class="flex flex-col px-10 pt-2 w-full">
              <div class="form-group">
                  <label>{{'Form Title'| translate:language:ModuleNames.SurveyX }}</label>
                  <input type="text"  class="form-control flex flex-col items-stretch" (click)="logData(surveyCreatorModel.survey.locTitle['values'])" [value]="surveyCreatorModel.survey.locTitle['values'][sourceLocale] || ''"
                  (input)="onSurveyLocaleChanged(surveyCreatorModel.survey.locTitle,sourceLocale, $event.target.value)"
                      [maxlength]="mdCount"
                      [placeholder]="'txtEnter Form Title'| translate:language:ModuleNames.SurveyX">
                      <div [ngClass]="surveyCreatorModel?.survey.locTitle.text?.length >= mdCount ? 'text-red-600' : 'text-zen'"
                        class="text-xxs textend">
                        {{ surveyCreatorModel.survey.locTitle.text.length}} / {{ mdCount }}
                      </div>
              </div>
          </div>

            <!-- <div class="flex flex-col px-10 pt-2 w-full">
              <div class="form-group">
                <label>{{'Description (Optional)'| translate:language:ModuleNames.SurveyX }}</label>
                <div class="form-control min-h-20 flex flex-row items-stretch">
                  <sv-ng-string-editor class="w-full  flex flex-col items-stretch"
                    [model]="surveyCreatorModel?.survey.locDescription"></sv-ng-string-editor>
                </div>
              </div>
              <div *ngIf="surveyCreatorModel?.survey.locDescription.text?.length > lgCount -5"
                class="text-red-600 text-xxs flex flex-row items-center gap-1 leading-5">
                {{lgCount}}/{{surveyCreatorModel?.survey.locDescription.text?.length}}</div>
            </div> -->


            <div class="flex flex-col px-10 pt-2 w-full">
              <div class="form-group">
                  <label>{{'Description (Optional)'| translate:language:ModuleNames.SurveyX }}</label>
                  <textarea type="text" class="form-control flex flex-col items-stretch" [value]="surveyCreatorModel.survey.locDescription['values'][sourceLocale] || ''"
                  (input)="onSurveyLocaleChanged(surveyCreatorModel.survey.locDescription,sourceLocale, $event.target.value)"
                      [maxlength]="lgCount" rows="3"
                      [placeholder]="'txtEnter Description'| translate:language:ModuleNames.SurveyX"></textarea>
                      <div [ngClass]="surveyCreatorModel?.survey.locDescription.text?.length >= lgCount ? 'text-red-600' : 'text-zen'"
                        class="text-xxs textend">
                        {{ surveyCreatorModel.survey.locDescription.text.length}} / {{ lgCount }}
                      </div>
              </div>
          </div>

            <div class="flex flex-col px-10 pt-2 w-full">
              <div class="flex flex-wrap gap-6">

                <div class="flex flex-1">
                  <div class="form-group flex justify-between items-end w-full">
                    <div>
                      <label>{{'Form Category'| translate:language:ModuleNames.SurveyX }}</label>
                      <h6 style="font-size: 14px; margin: 6px 0; color: #586f99;"
                        *ngIf="surveyForm?.categoryId != null">
                        {{getCategoryName(surveyForm?.categoryId) | extract:language}}
                      </h6>

                      <h6 style="font-size: 14px; margin: 6px 0; color: #586f99;"
                        *ngIf="surveyForm?.categoryId == null">
                        {{'None'| translate:language:ModuleNames.SurveyX }}
                      </h6>
                    </div>

                    <button class="btn cancel change-category-button h-max" *ngIf="categoriesList.length > 0"
                      (click)="showChangeCategoryModal()">
                      {{'Change Category'| translate:language:ModuleNames.SurveyX }}
                    </button>

                    <button class="change-category-button h-max" *ngIf="categoriesList.length == 0"
                      (click)="showNewCategoryModal()">
                      {{'New Category'| translate:language:ModuleNames.SurveyX }}
                    </button>
                  </div>
                </div>

              </div>
            </div>

            <div class="flex flex-col px-10 pt-2 w-full">
              <z-select [key]="'locale'" [(ngModel)]="surveyForm.locale"
                [label]="'Default Language'| translate:language:ModuleNames.SurveyX" [options]="supportedLanguagesList">
                <ng-template #valueTemplate let-value>
                  {{value?.title | translate:language:ModuleNames.SurveyX }}
                </ng-template>
              </z-select>
            </div>
          </div>

          <div class="sd-header__text mb-4 mt-10">
            <h3 class="  text-zen">{{'Styling Options'| translate:language:ModuleNames.SurveyX }}</h3>
          </div>

          <div class="flex flex-row flex-wrap w-660  bg-white items-center pb-6 pt-4 gap-5 justify-center rounded-md">
            <div class="flex flex-col px-10 pt-2 w-full">
              <div class="flex flex-wrap gap-6">
                <div class="flex-1">
                  <div class="form-group">
                    <label>{{'Color Theme'| translate:language:ModuleNames.SurveyX }}</label>

                    <label class="color-picker flex gap-2 justify-between items-center w-full py-2 px-3 mb-1">
                      <div class="flex gap-2 items-center">
                        <i class="fa-regular fa-droplet" [ngStyle]="{ 'color': surveyForm.colorTheme }"></i>
                        {{surveyForm?.colorTheme}}
                      </div>

                      <input type="color" [(ngModel)]="surveyForm.colorTheme">

                      <i class="fa-regular fa-eye-dropper"></i>
                    </label>

                    <p class="text-xs note-gray mb-3">
                      {{'This will be used as the accent color for actions and selections across the form' |
                      translate:language:ModuleNames.SurveyX }}
                    </p>
                    <p class="text-xs note hover:cursor-pointer btn" (click)="resetColorPicker()">
                      {{'Use Default Color theme' | translate:language:ModuleNames.SurveyX }}
                    </p>
                  </div>
                </div>

                <div class="flex-2 ml-2" style="flex: 2 1 0%;">
                  <div class="form-group">
                    <label class="mb-3"> {{'Custom Logo' | translate:language:ModuleNames.SurveyX }}
                    </label>
                    <zen-input-image storagePath="surveyx" [language]="language" [accept]="['image/jpeg', 'image/jpg', 'image/png', 'image/gif']" [(ngModel)]="surveyForm.logo" ></zen-input-image>
                    <p class="text-xs note hover:cursor-pointer mt-3 btn" (click)="resetLogo()">
                      {{'Use Default Logo' | translate:language:ModuleNames.SurveyX }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-change-category-modal *ngIf="isChangeCategoryModalVisible" [language]="language" [direction]="direction"
  [(open)]="isChangeCategoryModalVisible" [categoriesList]="categoriesList"
  [selectedCategory]="surveyForm?.selectedCategory" (openNewCategoryModal)="onOpenNewCategoryModal($event)"
  (editCategoryClicked)="onEditCategoryClicked($event)" (deleteCategoryClicked)="onDeleteCategoryClicked($event)"
  (selectedCategoryChange)="onSelectedCategoryChange($event)">
</app-change-category-modal>

<app-new-category-modal *ngIf="isNewCategoryModalVisible" [language]="language" [direction]="direction"
  [(open)]="isNewCategoryModalVisible" (createNewCategory)="onCreateNewCategory($event)">
</app-new-category-modal>

<app-edit-category-modal *ngIf="editCategoryModal.open" [language]="language" [direction]="direction"
  [(open)]="editCategoryModal.open" [(categroy)]="editCategoryModal.category" (editCategory)="onEditCategory($event)">
</app-edit-category-modal>

<loader *ngIf="loader.showLoader"></loader>
