/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Answers } from '../models/Answers';
import type { ExportFilter } from '../models/ExportFilter';
import type { QuestionAnalysisData } from '../models/QuestionAnalysisData';
import type { Respondent } from '../models/Respondent';
import type { StatusCountDto } from '../models/StatusCountDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class AnalysisService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param formId 
     * @param tenantId 
     * @param category 
     * @param chartType 
     * @returns Respondent Success
     * @throws ApiError
     */
    public getIndividualsForCategory(
formId: string,
tenantId: string,
category: string,
chartType: string,
): Observable<Array<Respondent>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analysis/get-individuals-for-category/{formId}/{tenantId}/{category}/{chartType}',
            path: {
                'formId': formId,
                'tenantId': tenantId,
                'category': category,
                'chartType': chartType,
            },
        });
    }

    /**
     * @param formId 
     * @param tenantId 
     * @param questionKey 
     * @param choiceKey 
     * @returns Respondent Success
     * @throws ApiError
     */
    public getIndividualResponses(
formId: string,
tenantId: string,
questionKey: string,
choiceKey: string,
): Observable<Array<Respondent>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analysis/get-individual-responses/{formId}/{tenantId}/{questionKey}/{choiceKey}',
            path: {
                'formId': formId,
                'tenantId': tenantId,
                'questionKey': questionKey,
                'choiceKey': choiceKey,
            },
        });
    }

    /**
     * @param formId 
     * @param tenantId 
     * @param questionKey 
     * @param choiceKey 
     * @param choiceRank 
     * @returns Respondent Success
     * @throws ApiError
     */
    public getIndividualRankingResponses(
formId: string,
tenantId: string,
questionKey: string,
choiceKey: string,
choiceRank: string,
): Observable<Array<Respondent>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analysis/get-individual-ranking-responses/{formId}/{tenantId}/{questionKey}/{choiceKey}/{choiceRank}',
            path: {
                'formId': formId,
                'tenantId': tenantId,
                'questionKey': questionKey,
                'choiceKey': choiceKey,
                'choiceRank': choiceRank,
            },
        });
    }

    /**
     * @param formId 
     * @param tenantId 
     * @param questionKey 
     * @param choiceCol 
     * @param choiceRow 
     * @returns Respondent Success
     * @throws ApiError
     */
    public getIndividualMatrixResponses(
formId: string,
tenantId: string,
questionKey: string,
choiceCol: string,
choiceRow: string,
): Observable<Array<Respondent>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analysis/get-individual-matrix-responses/{formId}/{tenantId}/{questionKey}/{choiceCol}/{choiceRow}',
            path: {
                'formId': formId,
                'tenantId': tenantId,
                'questionKey': questionKey,
                'choiceCol': choiceCol,
                'choiceRow': choiceRow,
            },
        });
    }

    /**
     * @param formId 
     * @param respondentId 
     * @param tenantId 
     * @param questionKey 
     * @returns Answers Success
     * @throws ApiError
     */
    public getSpecialQuestionResponse(
formId: string,
respondentId: string,
tenantId: string,
questionKey: string,
): Observable<Answers> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analysis/get-special-question-response/{formId}/{respondentId}/{tenantId}/{questionKey}',
            path: {
                'formId': formId,
                'respondentId': respondentId,
                'tenantId': tenantId,
                'questionKey': questionKey,
            },
        });
    }

    /**
     * @param formId 
     * @returns any Success
     * @throws ApiError
     */
    public getRespondentAnalysis(
formId: string,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analysis/get-respondent-analysis/{formId}',
            path: {
                'formId': formId,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns QuestionAnalysisData Success
     * @throws ApiError
     */
    public getQuestionAnalysis(
id: string,
requestBody?: ExportFilter,
): Observable<QuestionAnalysisData> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/analysis/question-analysis/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param tenantId 
     * @param companyId 
     * @param formId 
     * @returns StatusCountDto Success
     * @throws ApiError
     */
    public getStatusCounts(
tenantId: string,
companyId: string,
formId: string,
): Observable<StatusCountDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/analysis/status-counts/{tenantId}/{companyId}/{formId}',
            path: {
                'tenantId': tenantId,
                'companyId': companyId,
                'formId': formId,
            },
        });
    }

}
