import { formatPercent } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ChildrenAnimation } from 'src/app/shared/animations/allAnimations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Direction } from '@angular/cdk/bidi';
import { LocalService } from '@ex/module/core/services/local.service';
import { ZenCDKService } from '@ex/module/shared/Theme/services/zen-cdk.service';
import { ModuleName } from '@ex/module/shared/enums/ModuleName';

import { Hierarchy } from '@ex/module/core/data-models/hierarchy';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { PermissionsService } from '@ex/module/core/services/permissions.service';
import { SXTreeOptionsComponent } from '../../ui/sx-tree-options/sx-tree-options.component';
import { CompanyPermission } from '@ex/module/core/data-models/company-permission';
import { EntityTreeService } from '@ex/module/core/services/entity-tree.service';
import { AnalysisService, FilterDropdownLookupDto, FilterDropdownLookupService, FormService, ResponseRateModel, SurveyAnalysisService } from '@ex/survey-x/index';
import { AnalysisComponentModel } from '../../utils/analysis-component-model';
import { QuestionAnalysisComponent } from './question-analysis/question-analysis.component';
import { RespondentAnalysisComponent } from './respondent-analysis/respondent-analysis.component';
import { CommentsAnalysisComponent } from './comments-analysis/comments-analysis.component';
import { FeedbackAnalysisComponent } from './feedback-analysis/feedback-analysis.component';
import { filter, switchMap, take } from 'rxjs/operators';
import { SxBaseComponent } from '../../ui/sx-base/sx-base.component';
import { Subject, combineLatest } from 'rxjs';
import { AnalyticsFilter, AnalyticsService } from '../../data-access/services/analytics.service';
import { SjsTranslatePipe } from '../../utils/pipes/sjs-translate.pipe';
import { SurveyCriteriaService } from '../../data-access/services/survey-criteria.service';
import { CriteriaResolverService } from '../../data-access/services/criteria-resolver.service';
import { SurveyjsDataService } from '../../data-access/services/surveyjs-data.service';
import { ExportReportComponent } from './export-report/export-report.component';
import { ChartColorsAssignComponent } from '../../ui/analysis/chart-colors-assign/chart-colors-assign.component';



@Component({
  selector: 'app-form-analysis',
  templateUrl: './form-analysis.component.html',
  styleUrls: ['./form-analysis.component.scss'],
  animations: [ChildrenAnimation],
  providers: [SjsTranslatePipe]
})
export class FormAnalysisComponent extends SxBaseComponent implements OnInit, AfterViewInit {
  selectedEntity: any;
  selectedChildEntity: Hierarchy;
  tselectedChildEntity: Hierarchy;
  filters: AnalyticsFilter;
  hasCompanyPermission = false;
  permissionList: CompanyPermission[] = [];

  filterOptions: FilterDropdownLookupDto[] = [];

  treeList: any = [];
  //activeTab;
  pdfData: any;
  sideBarNavigationTabs = [
    {
      id: 1,
      title: 'txtQuestionAnalysis',
      icon: 'fas fa-chart-mixed',
      selected: true,
      component: QuestionAnalysisComponent,
      enabled: true
    },
    {
      id: 2,
      title: 'txtRespondentAnalysis',
      icon: 'fas fa-user-group',
      selected: false,
      component: RespondentAnalysisComponent,
      enabled: true
    },
    {
      id: 3,
      title: 'txtComments',
      icon: 'fas fa-comment-lines',
      selected: false,
      component: CommentsAnalysisComponent,
      enabled: false
    },
    {
      id: 4,
      title: 'txtFeedback',
      icon: 'fas fa-star',
      selected: false,
      component: FeedbackAnalysisComponent,
      enabled: false
    }
  ]
  editable: boolean = false;

  supportedLanguages = ['EN', 'AR'];
  showPrint = false;
  language = 'EN';
  dLanguage: any;
  ModuleNames = ModuleName;
  direction: Direction = 'ltr';
  textPosition = "left";
  activeTabs: string = 'question';
  rCAnalysis: any;
  qAnalysis: any;
  formLocale: any;
  formId: any;
  systemLocale: any;
  responseRate: any
  val: any;
  completedRespondents: any;
  totalCompletedRespondents: any;
  totalInactiveRespondents: any;
  sections: any;
  filter = {
    country: 'All',
    industry: 'All',
    language: 'default',
  };

  form: any;

  specialQuestion = { selectedQuestion: {}, respondents: {}, open: false };
  individualsForCategory = { category: {}, chartType: {}, chartName: {}, open: false };
  individualResponses = { selectedQuestion: {}, selectedChoice: {}, respondents: {}, selectedQuestionNumber: 0, open: false };
  individualRatingResponses = { selectedQuestion: {}, selectedChoice: {}, respondents: {}, selectedQuestionNumber: 0, open: false, selectedLabel: {} };
  individualRankingResponses = { selectedQuestion: {}, selectedChoice: {}, respondents: {}, selectedQuestionNumber: 0, open: false, selectedRank: {} };
  individualMatrixResponses = { selectedQuestion: {}, respondents: {}, selectedQuestionNumber: 0, open: false, selectedCol: {}, selectedRow: {} };
  allEntities: any;
  companyKey: string;

  @ViewChild('tabContentHolder', { read: ViewContainerRef, static: false })
  tabContent: ViewContainerRef

  viewDisabled = false;

  tenantKey: string;

  settingsLoaded$ = new Subject();

  overall: ResponseRateModel = {}

  totalFeedbacks = 0;


  constructor(@Inject(LOCALE_ID) public locale: string, private router: Router, private route: ActivatedRoute, private entityTreeService: EntityTreeService,
    private analysisService: AnalysisService, private zenCdk: ZenCDKService, private permissionService: PermissionsService,
    private surveysService: FormService, private localService: LocalService, public snackBar: MatSnackBar
    , private sjsTranslatePipe: SjsTranslatePipe
    , private surveyCriteriaService: SurveyCriteriaService
    , private analyticsService: AnalyticsService
    , private criteriaResolverService: CriteriaResolverService
    , private surveyAnalysisService: SurveyAnalysisService
    , private surveyjsDataService: SurveyjsDataService
    , private filterDropdownLookupService: FilterDropdownLookupService) {
    super();
  }

  ngAfterViewInit(): void {
    // const sub = this.analyticsService.filters$.pipe(
    //   filter(x => x != null),
    //   take(1)
    // ).subscribe(_ => {
    //   this.loadTabComponent(this.activeTab);
    // })

    // this.addSubscription(sub);
  }


  async ngOnInit() {
    this.GlobalService.innerRoute = true;
    this.formId = this.route.snapshot.paramMap.get('id');

    this.fetchFilterOptions();

    this.getSurvey();

    this.fetchOverallAnalysis();

    const sub = this.EssentialObject$.pipe(
      filter(obj => obj.selectedEntity?.key != null)
    ).subscribe(obj => {
      this.tenantKey = obj.tenant.key;
      this.companyKey = obj.selectedEntity.key;
      this.language = obj.language;
      this.selectedEntity = obj.selectedEntity;
      this.settingsLoaded$.next(true);
      // this.export();
    });

    this.addSubscription(sub);



    this.getRespondentAnalysis(this.formId);
    this.getQuestionssAnalysis(this.formId, this.filter);


    // Load the permissions
    // this.treeList = this.entityTreeService.get(this.allEntities, this.selectedEntity, [] as any);

    // if (this.permissionList && this.treeList) {
    //   this.populateEntityHierarchyWithPermissions(this.treeList, this.permissionList);
    // }

    // this.assignEntityIdsFilter();

    // this.ZenCDKService.openComponent(ChartColorsAssignComponent);


  }

  fetchFilterOptions() {
    const subscription = this.settingsLoaded$.pipe(
      switchMap(_ => {
        return this.filterDropdownLookupService.getLookups(this.tenantKey, this.companyKey, this.formId)
      })
    ).subscribe(lookups => {
      this.filterOptions = lookups;
    })

    this.addSubscription(subscription);

  }

  fetchOverallAnalysis() {

    const sub = combineLatest([this.settingsLoaded$, this.analyticsService.filters$]).pipe(
      filter(([_, filters]) => filters != null),
      switchMap(([_, filters]) => {
        return this.surveyAnalysisService.getOverallAnalysis(
          {
            tenantKey: this.tenantKey,
            companyKey: this.companyKey,
            formId: this.formId,
            filters: this.analyticsService.mapGlobalFilters(filters)
          });
      })
    ).subscribe(response => {
      this.overall = response;

      this.sideBarNavigationTabs.find(x => x.id == 3).enabled = this.overall.totalComments > 0;
      this.sideBarNavigationTabs.find(x => x.id == 4).enabled = this.overall.totalFeedbacks > 0;

    })

    this.addSubscription(sub);

  }

  getSurvey() {
    const sub = this.settingsLoaded$.pipe(
      switchMap(_ => {
        return this.surveysService.getFormWithoutResponses(this.formId)
      })
    ).subscribe(survey => {
      this.form = survey.query;
      this.filters = {
        companyKey: this.companyKey,
        tenantKey: this.tenantKey,
        language: this.language,
        survey: {
          json: JSON.parse(this.form.jsonText),
          isAnonymous:this.form.isAnonymous,
          key: this.formId,
          name: this.sjsTranslatePipe.transform(this.form.title, this.language),
          surveyCriterias: this.surveyCriteriaService.process(this.form.selectedCriterias.map(x => ({ key: x.key, name: x.name, options: x.options, isCustom: x.isCustom, isEntity: x.isEntity, type: x.type })), this.selectedEntity.ContentLanguages)
        }
      } as AnalyticsFilter

      // Initial Assignments - Can be overridden per component
      this.criteriaResolverService.setSurveyCriterias(this.filters.survey.surveyCriterias);

      // set the json for surveyjs template manipulation
      this.surveyjsDataService.setJson(this.filters.survey.json, this.language);

      // Set Intial Filters
      this.analyticsService.setFilters(this.filters, false);

      this.loadTabComponent(this.activeTab);
    })
  }

  getRespondentAnalysis(formId: string) {
    this.analysisService.getRespondentAnalysis(formId).subscribe((res) => {
      this.rCAnalysis = res;
      this.totalCompletedRespondents = this.rCAnalysis.totalCompletedRespondents;
      this.totalInactiveRespondents = this.rCAnalysis.totalInactiveRespondents;
      this.completedRespondents = this.rCAnalysis.completedRespondents;
      this.formLocale = this.rCAnalysis.formLocale;
      this.systemLocale = this.rCAnalysis.systemLocale;
      this.responseRate = formatPercent((this.rCAnalysis.totalCompletedRespondents) / (this.rCAnalysis.totalRespondents - this.totalInactiveRespondents), this.locale, '1.1-1');
      console.log("respondent data", this.rCAnalysis)

    })
  }

  getQuestionssAnalysis(formId: string, filter: any) {
    this.analysisService.getQuestionAnalysis(formId, filter).subscribe((res) => {
      // this.val = res;
      // this.qAnalysis = this.val.questionAnalysis;
      this.qAnalysis = res;
      console.log("Questions data", this.qAnalysis)
    })
  }
  changeTabs(tabs: string) {
    this.activeTabs = tabs;
  }

  get activeTab() {
    return this.sideBarNavigationTabs.find(x => x.selected == true) || this.sideBarNavigationTabs[0];
  }

  export() {
    // this.pdfData = {
    //   language: this.language,
    //   surveyID: this.formId,
    //   rCAnalysis: this.rCAnalysis,
    //   qAnalysis: this.qAnalysis,
    //   formLocale: this.formLocale,
    //   systemLocale: this.systemLocale,
    //   responseRate: this.responseRate,
    //   val: this.val,
    //   completedRespondents: this.completedRespondents,
    //   totalCompletedRespondents: this.totalCompletedRespondents,
    //   filter: { country: 'All', industry: 'All', language: 'default', },
    //   ModuleNames: this.ModuleNames
    // }

    // this.showPrint = true;

    if (this.activeTab.id == 4)
      return this.analyticsService.setDownloadFeedback();


    if (this.activeTab.id == 3)
      return this.analyticsService.setDownloadComments();

    this.addSubscription(this.ZenCDKService.openComponent(ExportReportComponent, { selectedEntity: this.selectedEntity, overall: this.overall }).subscribe());
  }

  openSpecialQuestion(selectedQuestion: any) {
    this.specialQuestion.open = true;
    this.specialQuestion.selectedQuestion = selectedQuestion;
    this.specialQuestion.respondents = this.completedRespondents;
  }

  onOpenSpecialQuestion(value) {
    this.openSpecialQuestion(value)
  }


  openIndividualsForCategoryModal(category: any, chartType: any, chartName: any) {

    this.individualsForCategory.open = true;
    this.individualsForCategory.category = category;
    this.individualsForCategory.chartType = chartType;
    this.individualsForCategory.chartName = chartName;
  }

  openIndividualResponsesModal(selectedQuestion: any, selectedChioice: any, selectedQuestionNumber: any) {
    this.individualResponses.open = true;
    this.individualResponses.selectedQuestion = selectedQuestion;
    this.individualResponses.selectedChoice = selectedChioice;
    this.individualResponses.respondents = this.completedRespondents;
    this.individualResponses.selectedQuestionNumber = selectedQuestionNumber;
  }

  openIndividualRatingResponsesModal(selectedQuestion: any, selectedChioice: any, selectedQuestionNumber: any, selectedLabel: any) {
    this.individualRatingResponses.open = true;
    this.individualRatingResponses.selectedQuestion = selectedQuestion;
    this.individualRatingResponses.selectedChoice = selectedChioice;
    this.individualRatingResponses.respondents = this.completedRespondents;
    this.individualRatingResponses.selectedQuestionNumber = selectedQuestionNumber;
    this.individualRatingResponses.selectedLabel = selectedLabel;
  }
  openIndividualRankingResponsesModal(selectedQuestion: any, selectedChioice: any, selectedQuestionNumber: any, selectedRank: any) {
    this.individualRankingResponses.open = true;
    this.individualRankingResponses.selectedQuestion = selectedQuestion;
    this.individualRankingResponses.selectedChoice = selectedChioice;
    this.individualRankingResponses.respondents = this.completedRespondents;
    this.individualRankingResponses.selectedQuestionNumber = selectedQuestionNumber;
    this.individualRankingResponses.selectedRank = selectedRank;
  }

  openIndividualMatrixResponsesModal(selectedQuestion: any, selectedQuestionNumber: any, selectedCol: any, selectedRow: any) {
    this.individualMatrixResponses.open = true;
    this.individualMatrixResponses.selectedQuestion = selectedQuestion;
    this.individualMatrixResponses.respondents = this.completedRespondents;
    this.individualMatrixResponses.selectedQuestionNumber = selectedQuestionNumber;
    this.individualMatrixResponses.selectedCol = selectedCol;
    this.individualMatrixResponses.selectedRow = selectedRow;
  }

  onOpenIndividualsForCategoryModal(values) {

    this.openIndividualsForCategoryModal(values.category, values.chartType, values.chartName)
  }

  openTree() {
    this.ZenCDKService.openComponent(SXTreeOptionsComponent, { componentData: { tselectedChildEntity: this.selectedChildEntity, treeList: this.treeList }, language: this.language }).subscribe((tselectedChildEntity: Hierarchy) => {
      this.selectedChildEntity = tselectedChildEntity;
      this.assignEntityIdsFilter()
      // this.analyticsService.setFilters(this.filters, false);
    })
  }

  private assignEntityIdsFilter() {
    if ((this.companyKey == this.selectedChildEntity.entity.key) && this.hasCompanyPermission) {
      this.filters.entityId = [];
    } else {
      this.filters.entityId = this.getChildKeys(this.selectedChildEntity, []);
    }

    // this.analyticsService.setFilters(this.filters);
  }

  getChildKeys(root, keys = []) {

    keys.push(root.entity.key);

    if (root.children.length) {

      root.children.forEach(x => {
        keys = this.getChildKeys(x, keys);
      });

    }

    return keys;

  }

  populateEntityHierarchyWithPermissions(treeList: Hierarchy[], permissionList: CompanyPermission[]) {

    for (var treeEntity of treeList) {
      treeEntity['hasPermission'] = this.permissionService.isSuperAdmin() ? true : permissionList.find(x => x.key == treeEntity.entity.key) ? true : false;

      if (this.selectedChildEntity == null && treeEntity['hasPermission'] == true) {
        this.selectedChildEntity = treeEntity;
      }

      if (treeEntity.children.length) {
        this.populateEntityHierarchyWithPermissions(treeEntity.children, permissionList);
      }
    }

  }


  onOpenIndividualResponsesModal(values) {
    this.openIndividualResponsesModal(values.selectedQuestion, values.selectedChoice, values.selectedQuestionNumber)
  }

  onOpenIndividualRatingResponsesModal(values) {
    this.openIndividualRatingResponsesModal(values.selectedQuestion, values.selectedChoice, values.selectedQuestionNumber, values.selectedLabel)

  }

  onOpenIndividualRankingResponsesModal(values) {
    this.openIndividualRankingResponsesModal(values.selectedQuestion, values.selectedChoice, values.selectedQuestionNumber, values.selectedRank)

  }

  onOpenIndividualMatrixResponsesModal(values) {
    this.openIndividualMatrixResponsesModal(values.selectedQuestion, values.selectedQuestionNumber, values.selectedCol, values.selectedRow)

  }

  onRename() {
    this.editable = true;
  }

  async onDoneEditing() {
    let title = this.rCAnalysis.formTitle;
    await this.surveysService.updateFormTitle(this.formId, title).toPromise().finally(() => {

      this.editable = false;

    })

  }

  onClone() {
    this.surveysService.cloneForm(this.formId).subscribe((response) => {

    })
    this.snackBar.open("Form Cloned Successfully!", "Now You can find it in form library page.", {
      duration: 6000,
    });
  }
  getTranslatedTitle(title: any) {
    let title1 = JSON.parse(title)
    if (typeof title1 == 'string') {
      return title1;
    }
    else {
      return title1[this.dLanguage]
    }
  }

  tabClick(tab) {
    this.sideBarNavigationTabs.forEach(x => x.selected = false);
    tab.selected = true;

    this.loadTabComponent(tab);
  }

  loadTabComponent(tab: any) {

    if (!this.tabContent)
      return;

    this.tabContent.clear();
    const component = this.tabContent.createComponent<any>(tab.component);

    component.instance.AnalysisComponentData = {
      companyKey: this.filters.companyKey,
      tenantKey: this.filters.tenantKey,
      language: this.language,
      formId: this.formId,
      selectedEntity: this.selectedEntity
    } as AnalysisComponentModel

    this.tabContent.insert(component.hostView);
  }

  getFilterItems(event) {
    if(!event || !event?.length)
      return;
    this.filters.chartFilters = event;
    this.analyticsService.setFilters(this.filters, false);
  }

  getDisplayedFilters(filters: any[]) {
    this.analyticsService.setFiltersForReport(filters);
  }
}
