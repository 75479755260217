/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { BulkAssignRequest } from '../models/BulkAssignRequest';
import type { BulkDeleteRespondentDto } from '../models/BulkDeleteRespondentDto';
import type { ExcelUploadDto } from '../models/ExcelUploadDto';
import type { ExcelUploadRequestDto } from '../models/ExcelUploadRequestDto';
import type { ExcelUploadValidationDto } from '../models/ExcelUploadValidationDto';
import type { FormData } from '../models/FormData';
import type { FormResponse } from '../models/FormResponse';
import type { FormResponsePagedResult } from '../models/FormResponsePagedResult';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class FormResponseService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns FormResponse Success
     * @throws ApiError
     */
    public getAllFormResponses(): Observable<Array<FormResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-response/get-all-form-responses',
        });
    }

    /**
     * @param formId 
     * @returns FormResponse Success
     * @throws ApiError
     */
    public getAllFormResponsesByForm(
formId: string,
): Observable<Array<FormResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-response/get-all-form-responses-by-form/{formId}',
            path: {
                'formId': formId,
            },
        });
    }

    /**
     * @param id 
     * @returns FormResponse Success
     * @throws ApiError
     */
    public getFormResponse(
id: string,
): Observable<FormResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-response/get-form-response/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param formId 
     * @returns FormResponse Success
     * @throws ApiError
     */
    public getFormResponsesByFormId(
formId: string,
): Observable<Array<FormResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-response/get-form-response-byformid/{formId}',
            path: {
                'formId': formId,
            },
        });
    }

    /**
     * @param formId 
     * @param respondentId 
     * @param tenantId 
     * @param companyId 
     * @returns FormResponse Success
     * @throws ApiError
     */
    public getFormResponseByFormIdAndRespondentId(
formId: string,
respondentId: string,
tenantId: string,
companyId: string,
): Observable<FormResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-response/get-form-response-by-formid-and-respondentid/{formId}/{respondentId}/{tenantId}/{companyId}',
            path: {
                'formId': formId,
                'respondentId': respondentId,
                'tenantId': tenantId,
                'companyId': companyId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FormResponse Success
     * @throws ApiError
     */
    public addFormResponse(
requestBody?: FormResponse,
): Observable<FormResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-response/add-response',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns FormResponse Success
     * @throws ApiError
     */
    public updateFormResponse(
id: string,
requestBody?: FormResponse,
): Observable<FormResponse> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/form-response/update-form-response/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteFormResponse(
id: string,
): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/form-response/delete-form-response/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param formId 
     * @param tenantKey 
     * @param companyKey 
     * @param isAllResults 
     * @param skipCount 
     * @param maxResultCount 
     * @param searchText 
     * @param status 
     * @returns FormResponsePagedResult Success
     * @throws ApiError
     */
    public getPagedList(
formId: string,
tenantKey: string,
companyKey: string,
isAllResults: boolean,
skipCount?: number,
maxResultCount?: number,
searchText?: string,
status?: string,
): Observable<FormResponsePagedResult> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-response/get-paged-list/{formId}',
            path: {
                'formId': formId,
            },
            query: {
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
                'TenantKey': tenantKey,
                'CompanyKey': companyKey,
                'IsAllResults': isAllResults,
                'SearchText': searchText,
                'Status': status,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns FormResponse Success
     * @throws ApiError
     */
    public excelUpload(
requestBody?: ExcelUploadDto,
): Observable<Array<FormResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-response/excel-upload',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns FormResponse Success
     * @throws ApiError
     */
    public bulkAssignToForm(
requestBody?: BulkAssignRequest,
): Observable<Array<FormResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-response/bulk-assign',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public bulkDelete(
requestBody?: BulkDeleteRespondentDto,
): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-response/bulk-delete',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param formId 
     * @param requestBody 
     * @returns number Success
     * @throws ApiError
     */
    public getUnitsToDeduct(
formId: string,
requestBody?: BulkAssignRequest,
): Observable<number> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-response/get-units/{formId}',
            path: {
                'formId': formId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns ExcelUploadValidationDto Success
     * @throws ApiError
     */
    public excelUploadValidation(
requestBody?: ExcelUploadRequestDto,
): Observable<ExcelUploadValidationDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/form-response/excel-validation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param respondentId 
     * @param tenantKey 
     * @param companyKey 
     * @returns FormData Success
     * @throws ApiError
     */
    public getAnswers(
respondentId: string,
tenantKey: string,
companyKey: string,
): Observable<FormData> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-response/get-answers/{respondentId}/{tenantKey}/{companyKey}',
            path: {
                'respondentId': respondentId,
                'tenantKey': tenantKey,
                'companyKey': companyKey,
            },
        });
    }

    /**
     * @param formId 
     * @param tenantKey 
     * @param companyKey 
     * @returns number Success
     * @throws ApiError
     */
    public getCount(
formId: string,
tenantKey: string,
companyKey: string,
): Observable<number> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-response/total-respondents/{formId}/{tenantKey}/{companyKey}',
            path: {
                'formId': formId,
                'tenantKey': tenantKey,
                'companyKey': companyKey,
            },
        });
    }

}
