/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { FormTable } from '../models/FormTable';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
  providedIn: 'root',
})
export class FormBankService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param formId 
     * @param tenantId 
     * @returns FormTable Success
     * @throws ApiError
     */
    public cloneToFormLibrary(
formId: string,
tenantId: string,
): Observable<FormTable> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/form-bank/clone-to-form-library/{formId}/{tenantId}',
            path: {
                'formId': formId,
                'tenantId': tenantId,
            },
        });
    }

}
