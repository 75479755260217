import { Direction } from '@angular/cdk/bidi';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@ex/module/core/domain/base-component';
import { LanguagePipe } from '@ex/module/shared/pipes/language.pipe';
import { localization } from 'survey-creator-core';

@Component({
  selector: 'app-survey-navbar',
  templateUrl: './survey-navbar.component.html',
  styleUrls: ['./survey-navbar.component.scss']
})
export class SurveyNavbarComponent extends BaseComponent implements OnInit {

  surveyModel: any;
  @Input() survey: any;
  surveyLanguages: any[];
  @Input() language;
  @Output() languageChanged = new EventEmitter();

  @Input('surveyModel') set model(model: any) {
    this.surveyModel = model;
    if (this.surveyModel) {
      this.surveyLanguages = [];
      this.surveyModel.getUsedLocales().forEach((locale) => {
        if (!this.surveyLanguages.find(loc => loc.locale.toLowerCase() == locale.toLowerCase()))
          this.surveyLanguages.push({ locale: locale, title: localization.getLocaleName(locale) });
      });

      this.isArabic(this.surveyModel.getLocale())
    }
  };

  constructor(private languagePipe: LanguagePipe) {
    super();
  }

  ngOnInit(): void {
  }


  onSelectLanguage(locale: any) {
    this.surveyModel.locale = locale.toLowerCase();
    if (!this.LocalService.getJsonValue('language'))
      this.LocalService.setJsonValue('language', locale.toUpperCase());
    else sessionStorage.setItem('sessionLanguage', locale.toUpperCase());
    this.isArabic(locale);
    this.languageChanged.emit(locale.toUpperCase());
  }

  isArabic(lang?: string) {
    if (this.languagePipe.transform(lang.toUpperCase())) {
      document.body.classList.remove('ltr');
      document.body.classList.add('rtl');
      document.body.setAttribute("dir", "rtl");
    }
    else {
      document.body.classList.remove('rtl')
      document.body.classList.add('ltr');
      document.body.setAttribute("dir", "ltr");

    }
  }

}
