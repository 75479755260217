import { Component, Input, OnInit } from '@angular/core';
import { ticketLabels } from './models/ticket-labels.enum';
import { fabAnimations } from './models/fab.animations';
import { Subscription } from 'rxjs';
import { EssentialObjectService } from 'src/app/core/services/essential-object.service';
import { ModuleName } from '../enums/ModuleName';
import { ticketTitles } from './models/ticket-titles.enum';
@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
  animations: fabAnimations

})
export class HelpCenterComponent implements OnInit {
  private subscriptions = new Subscription();
  essentialObject;
  helpcenter: boolean = false;
  fabButtons = [{ text: ticketTitles.TechnicalIssue, value: ticketLabels.TechnicalIssue }, { text: ticketTitles.HRAdvisory, value: ticketLabels.HRAdvisory }];
  buttons: any = [];
  fabTogglerState = 'inactive';
  openTicket: boolean = false;
  ticketType: string = '';
  language;
  ModuleNames = ModuleName;
  constructor(public essentialObjectService: EssentialObjectService) {
    this.subscriptions.add(this.essentialObjectService.essentialObject
      .subscribe((object: any) => {
        if (object.selectedEntity && object.user) {
          this.essentialObject = object;
          this.language = object.language;
        }
      }));
  }
  ngOnInit(): void {
  }
  showItems() {
    this.helpcenter = true;
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }
  hideItems() {
    this.helpcenter = false;
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }
  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }
  openDialog(btn: any) {
    this.ticketType = btn;
    this.openTicket = true;
    this.hideItems();
    //  let dialogRef = this.dialog.open(TicketDialogComponent, {
    //     data:{name:btn.text}
    //   });

    // dialogRef.afterClosed().subscribe(result=>{
    //   console.log('Dialog Result:', result)
    //   })
  }
}
